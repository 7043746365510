import { View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { InspirationStationHeaderIcon } from './src/inspiration-station-header-icon'
import { InspirationStationIcon } from './src/inspiration-station-icon'
import { InspirationStationLineDrawing } from './src/inspiration-station-line-drawing'
import { DefaultButton } from '../../../common/default-button'
import { useState } from 'react'
import { DecidaColors } from '../../../../common/decida-colors'
import { Worksheets } from '../../../common/worksheets-imports'

const quotesArray = [
    {
        quote: "Life is like a box of chocolates, you never know what you're gonna get.",
        author: "Forrest Gump"
    },
    {
        quote: "Love is putting someone else's needs before yours.",
        author: "Olaf, Frozen"
    },
    {
        quote: "I have not failed. I've just found 10,000 ways that won't work.",
        author: "Thomas A. Edison"
    },
    {
        quote: "Why fit in when you were born to stand out?",
        author: "Dr. Seuss"
    },
    {
        quote: "An eye for an eye only ends up making the whole world blind.",
        author: "Mahatma Gandhi"
    },
    {
        quote: "Oh yes, the past can hurt. But you can either run from it or learn from it.",
        author: "Rafiki, Lion King"
    },
    {
        quote: "It’s not until you lose everything that you truly appreciate everything.",
        author: "Belle, Beauty and the Beast"
    },
    {
        quote: "You never really understand a person until you consider things from his point of view… until you climb in his skin and walk around in it.",
        author: "Atticus Finch, To Kill a Mockingbird"
    },
    {
        quote: "If my mind can conceive it, if my heart can believe it, then I can achieve it.",
        author: "Muhammad Ali"
    },
    {
        quote: "Not all of us can do great things. But we can do small things with great love.",
        author: "Mother Teresa"
    },
    {
        quote: "Darkness cannot drive out darkness; only light can do that. Hate cannot drive out hate; only love can do that.",
        author: "Martin Luther King Jr."
    },
    {
        quote: "The beautiful thing about learning is that no one can take it away from you.",
        author: "B.B. King"
    },
    {
        quote: "You can fail at what you don't want, so you might as well take a chance on doing what you love.",
        author: "Jim Carrey"
    },
    {
        quote: "Unforgiveness is like drinking poison yourself and waiting for the other person to die.",
        author: "Marianne Williamson"
    },
    {
        quote: "I've searched all the parks in all the cities and found no statues of committees.",
        author: "Gilbert K. Chesterton"
    },
    {
        quote: "Success is stumbling from failure to failure with no loss of enthusiasm.",
        author: "Winston Churchill"
    },
    {
        quote: "Keep your eyes on the stars, and your feet on the ground.",
        author: "Theodore Roosevelt"
    },
    {
        quote: "Do not stop thinking of life as an adventure. You have no security unless you can live bravely, excitingly, imaginatively; unless you can choose a challenge instead of competence.",
        author: "Eleanor Roosevelt"
    },
    {
        quote: "Perfection is not attainable. But if we chase perfection, we can catch excellence.",
        author: "Vince Lombardi"
    },
    {
        quote: "Optimism is the faith that leads to achievement. Nothing can be done without hope and confidence.",
        author: "Helen Keller"
    },
    {
        quote: "Learn as if you will live forever, live like you will die tomorrow.",
        author: "Mahatma Gandhi"
    },
    {
        quote: "Stay away from those people who try to disparage your ambitions. Small minds will always do that, but great minds will give you a feeling that you can become great too.",
        author: "Mark Twain"
    },
    {
        quote: "When you give joy to other people, you get more joy in return. You should give a good thought to happiness that you can give out.",
        author: "Eleanor Roosevelt"
    },
    {
        quote: "It is only when we take chances, when our lives improve. The initial and the most difficult risk that we need to take is to become honest.",
        author: "Walter Anderson"
    },
    {
        quote: "Nature has given us all the pieces required to achieve exceptional wellness and health, but has left it to us to put these pieces together.",
        author: "Diane McLaren"
    },
    {
        quote: "Success is not final; failure is not fatal: It is the courage to continue that counts.",
        author: "Winston S. Churchill"
    },
    {
        quote: "The road to success and the road to failure are almost exactly the same.",
        author: "Colin R. Davis"
    },
    {
        quote: "Develop success from failures. Discouragement and failure are two of the surest stepping stones to success.",
        author: "Dale Carnegie"
    },
    {
        quote: "There are three ways to ultimate success: The first way is to be kind. The second way is to be kind. The third way is to be kind.",
        author: "Mister Rogers"
    },
    {
        quote: "Success is peace of mind, which is a direct result of self-satisfaction in knowing you made the effort to become the best of which you are capable.",
        author: "John Wooden"
    },
    {
        quote: "Success is getting what you want, happiness is wanting what you get.",
        author: "W. P. Kinsella"
    },
    {
        quote: "The pessimist sees difficulty in every opportunity. The optimist sees opportunity in every difficulty.",
        author: "Winston Churchill"
    },
    {
        quote: "Don’t let yesterday take up too much of today.",
        author: "Will Rogers"
    },
    {
        quote: "If you are working on something that you really care about, you don’t have to be pushed. The vision pulls you.",
        author: "Steve Jobs"
    },
    {
        quote: "Experience is a hard teacher because she gives the test first, the lesson afterwards.",
        author: "Vernon Sanders Law"
    },
    {
        quote: "Goal setting is the secret to a compelling future.",
        author: "Tony Robbins"
    },
    {
        quote: "Either you run the day or the day runs you.",
        author: "Jim Rohn"
    },
    {
        quote: "I’m a greater believer in luck, and I find the harder I work the more I have of it.",
        author: "Thomas Jefferson"
    },
    {
        quote: "When we strive to become better than we are, everything around us becomes better too.",
        author: "Paulo Coelho"
    },
    {
        quote: "Opportunity is missed by most people because it is dressed in overalls and looks like work.",
        author: "Thomas Edison"
    },
    {
        quote: "Setting goals is the first step in turning the invisible into the visible.",
        author: "Tony Robbins"
    },
    {
        quote: "It’s not about better time management. It’s about better life management.",
        author: "Alexandra of The Productivity Zone"
    },
    {
        quote: "You've got to get up every morning with determination if you're going to go to bed with satisfaction.",
        author: "George Lorimer"
    },
    {
        quote: "Education is the most powerful weapon which you can use to change the world.",
        author: "Nelson Mandela"
    },
    {
        quote: "The most difficult thing is the decision to act, the rest is merely tenacity.",
        author: "Amelia Earhart"
    },
    {
        quote: "You’ll find that education is just about the only thing lying around loose in this world, and it’s about the only thing a fellow can have as much of it as [they’re] willing to haul away.",
        author: "John Graham"
    },
    {
        quote: "Take the attitude of a student, never be too big to ask questions, never know too much to learn something new.",
        author: "Augustine Og Mandino"
    },
    {
        quote: "The elevator to success is out of order. You’ll have to use the stairs, one step at a time.",
        author: "Joe Girard"
    },
    {
        quote: "Be a positive energy trampoline – absorb what you need and rebound more back.",
        author: "Dave Carolan"
    },
    {
        quote: "People often say that motivation doesn’t last. Well, neither does bathing – that’s why we recommend it daily.",
        author: "Zig Ziglar"
    },
    {
        quote: "I always wanted to be somebody, but now I realize I should have been more specific.",
        author: "Lily Tomlin"
    },
    {
        quote: "Individual commitment to a group effort - that is what makes a team work, a company work, a society work, a civilization work.",
        author: "Vince Lombardi"
    },
    {
        quote: "Teamwork is the ability to work together toward a common vision. The ability to direct individual accomplishments toward organizational objectives. It is the fuel that allows common people to attain uncommon results.",
        author: "Andrew Carnegie"
    },
    {
        quote: "Coming together is a beginning. Keeping together is progress. Working together is success.",
        author: "Henry Ford"
    },
    {
        quote: "Remember, teamwork begins by building trust. And the only way to do that is to overcome our need for invulnerability.",
        author: "Patrick Lencioni"
    },
    {
        quote: "I invite everyone to choose forgiveness rather than division, teamwork over personal ambition.",
        author: "Jean-Francois Cope"
    },
    {
        quote: "Just one small positive thought in the morning can change your whole day.",
        author: "Dalai Lama"
    },
    {
        quote: "It is never too late to be what you might have been.",
        author: "George Eliot"
    },
    {
        quote: "Don't let someone else's opinion of you become your reality.",
        author: "Les Brown"
    },
    {
        quote: "Do the best you can. No one can do more than that.",
        author: "John Wooden"
    },
    {
        quote: "If you can dream it, you can do it.",
        author: "Walt Disney"
    },
    {
        quote: "Do what you can, with what you have, where you are.",
        author: "Theodore Roosevelt"
    },
    {
        quote: "Your Monday morning thoughts set the tone for your whole week. See yourself getting stronger, and living a fulfilling, happier & healthier life.",
        author: "Germany Kent"
    },
    {
        quote: "The greatest discovery of my generation is that a human being can alter [their] life by altering [their] attitudes.",
        author: "William James"
    },
    {
        quote: "You cannot plough a field by turning it over in your mind. To begin, begin.",
        author: "Gordon B. Hinckley"
    },
    {
        quote: "When you arise in the morning think of what a privilege it is to be alive, to think, to enjoy, to love…",
        author: "Marcus Aurelius"
    },
    {
        quote: "Mondays are the start of the work week which offer new beginnings 52 times a year!",
        author: "David Dweck"
    },
    {
        quote: "Be miserable. Or motivate yourself. Whatever has to be done, it's always your choice.",
        author: "Wayne Dyer"
    },
    {
        quote: "Your Monday morning thoughts set the tone for your whole week. See yourself getting stronger, and living a fulfilling, happier & healthier life.",
        author: "Germany Kent"
    },
    {
        quote: "You can get everything in life you want if you will just help enough other people get what they want.",
        author: "Zig Ziglar"
    },
    {
        quote: "Inspiration does exist, but it must find you working.",
        author: "Pablo Picasso"
    },
    {
        quote: "Don't settle for average. Bring your best to the moment. Then, whether it fails or succeeds, at least you know you gave all you had.",
        author: "Angela Bassett"
    },
    {
        quote: "I have stood on a mountain of no’s for one yes.",
        author: "Barbara Elaine Smith"
    },
    {
        quote: "Don't look at your feet to see if you are doing it right. Just dance.",
        author: "Anne Lamott"
    },
    {
        quote: "Someone's sitting in the shade today because someone planted a tree a long time ago.",
        author: "Warren Buffet"
    },
    {
        quote: "True freedom is impossible without a mind made free by discipline.",
        author: "Mortimer J. Adler"
    },
    {
        quote: "Rivers know this: there is no hurry. We shall get there some day.",
        author: "A.A. Milne"
    },
    {
        quote: "He that can have patience can have what he will.",
        author: "Benjamin Franklin"
    },
    {
        quote: "The only one who can tell you; you can’t win is you and you don’t have to listen.",
        author: "Jessica Ennis"
    },
    {
        quote: "Take your victories, whatever they may be, cherish them, use them, but don’t settle for them.",
        author: "Mia Hamm"
    },
    {
        quote: "Life can be much broader once you discover one simple fact: Everything around you that you call life was made up by people that were no smarter than you. And you can change it, you can influence it… Once you learn that, you'll never be the same again.",
        author: "Steve Jobs"
    },
    {
        quote: "Life is like riding a bicycle. To keep your balance you must keep moving.",
        author: "Albert Einstein"
    },
    {
        quote: "I have never let my schooling interfere with my education.",
        author: "Mark Twain"
    },
    {
        quote: "If you can't yet do great things, do small things in a great way.",
        author: "Napoleon Hill"
    },
    {
        quote: "If you really want to do something, you'll find a way. If you don't, you'll find an excuse.",
        author: "Jim Rohn"
    },
    {
        quote: "Be sure you put your feet in the right place, then stand firm.",
        author: "Abraham Lincoln"
    },
    {
        quote: "Live out of your imagination, not your history.",
        author: "Stephen Covey"
    },
    {
        quote: "Courage doesn't always roar. Sometimes courage is a quiet voice at the end of the day saying, I will try again tomorrow.",
        author: "Mary Anne Radmacher"
    },
    {
        quote: "If the decisions you make about where you invest your blood, sweat, and tears are not consistent with the person you aspire to be, you’ll never become that person.",
        author: "Clayton M. Christensen"
    },
    {
        quote: "The only way of discovering the limits of the possible is to venture a little way past them into the impossible.",
        author: "Arthur C. Clarke"
    },
    {
        quote: "Worry is a misuse of imagination.",
        author: "Unknown"
    },
    {
        quote: "Courage is the most important of all the virtues because without courage, you can't practice any other virtue consistently.",
        author: "Maya Angelou"
    },
    {
        quote: "A year from now you will wish you had started today.",
        author: "Unknown"
    },
    {
        quote: "The reason we struggle with insecurity is because we compare our behind-the-scenes with everyone else’s highlight reel.",
        author: "Steve Furtick"
    },
    {
        quote: "Somewhere, something incredible is waiting to be known.",
        author: "Carl Sagan"
    },
    {
        quote: "I do not try to dance better than anyone else. I only try to dance better than myself.",
        author: "Arianna Huffington"
    },
    {
        quote: "If you don’t risk anything, you risk even more.",
        author: "Erica Jong"
    },
    {
        quote: "Failure is simply the opportunity to begin again, this time more intelligently.",
        author: "Henry Ford"
    },
    {
        quote: "Our greatest glory is not in never falling, but in rising every time we fall.",
        author: "Confucius"
    },
    {
        quote: "We must reach out our hand in friendship and dignity both to those who would befriend us and those who would be our enemy.",
        author: "Arthur Ashe"
    },
    {
        quote: "It's fine to celebrate success but it is more important to heed the lessons of failure.",
        author: "Bill Gates"
    },
    {
        quote: "I can’t tell you how many times I’ve been given a no. Only to find that a better, brighter, bigger yes was right around the corner.",
        author: "Arlan Hamilton"
    },
    {
        quote: "We need to accept that we won’t always make the right decisions, that we’ll screw up royally sometimes – understanding that failure is not the opposite of success, it’s part of success.",
        author: "Ariana Huffington"
    },
    {
        quote: "When everything seems to be going against you, remember that the airplane takes off against the wind, not with it.",
        author: "Henry Ford"
    },
    {
        quote: "You cannot always control what goes on outside. But you can always control what goes on inside.",
        author: "Wayne Dyer"
    },
    {
        quote: "We are what we repeatedly do. Excellence, then, is not an act, but a habit.",
        author: "Aristotle"
    },
    {
        quote: "Start where you are. Use what you have. Do what you can.",
        author: "Arthur Ashe"
    },
    {
        quote: "Hustle beats talent when talent doesn’t hustle.",
        author: "Ross Simmonds"
    },
    {
        quote: "Don’t worry about failure; you only have to be right once.",
        author: "Drew Houston"
    },
    {
        quote: "Never let success get to your head and never let failure get to your heart.",
        author: "Drake"
    },
    {
        quote: "Make sure your worst enemy doesn’t live between your own two ears.",
        author: "Laird Hamilton"
    },
    {
        quote: "It is a rough road that leads to the heights of greatness.",
        author: "Lucius Annaeus Seneca"
    },
    {
        quote: "For the great doesn’t happen through impulse alone and is a succession of little things that are brought together.",
        author: "Vincent van Gogh"
    },
    {
        quote: "If we take care of the moments, the years will take care of themselves.",
        author: "Maria Edgeworth"
    },
    {
        quote: "Resilience is when you address uncertainty with flexibility.",
        author: "Unknown"
    },
    {
        quote: "Sometimes magic is just someone spending more time on something than anyone else might reasonably expect.",
        author: "Raymond Joseph Teller"
    },
    {
        quote: "It’s not the will to win that matters - everyone has that. It’s the will to prepare to win that matters.",
        author: "Paul Bryant"
    },
    {
        quote: "Never give up on a dream just because of the time it will take to accomplish it. The time will pass anyway.",
        author: "Earl Nightingale"
    },
    {
        quote: "True humility is not thinking less of yourself; it is thinking of yourself less.",
        author: "C.S. Lewis"
    },
    {
        quote: "The two most important days in your life are the day you’re born and the day you find out why.",
        author: "Mark Twain"
    },
    {
        quote: "Nothing ever goes away until it teaches us what we need to know.",
        author: "Pema Chodron"
    },
    {
        quote: "We can see through others only when we can see through ourselves.",
        author: "Bruce Lee"
    },
    {
        quote: "The battles that count aren't the ones for gold medals. The struggles within yourself - the invisible, inevitable battles inside all of us - that's where it's at.",
        author: "Jesse Owens"
    },
    {
        quote: "If there is no struggle, there is no progress.",
        author: "Frederick Douglass"
    },
    {
        quote: "Someone will declare, I am the leader! and expect everyone to get in line and follow him or her to the gates of heaven or hell. My experience is that it doesn’t happen that way. Others follow you based on the quality of your actions rather than the magnitude of your declarations.",
        author: "Bill Walsh"
    },
    {
        quote: "Courage is like a muscle. We strengthen it by use.",
        author: "Ruth Gordo"
    },
    {
        quote: "More is lost by indecision than wrong decision.",
        author: "Marcus Tullius Cicero"
    },
    {
        quote: "If the highest aim of a captain were to preserve his ship, he would keep it in port forever.",
        author: "Thomas Aquinas"
    },
    {
        quote: "You’ll never get bored when you try something new. There’s really no limit to what you can do.",
        author: "Dr. Seuss"
    },
    {
        quote: "You can never leave footprints that last if you are always walking on tiptoe.",
        author: "Leymah Gbowee"
    },
    {
        quote: "If you don’t like the road you’re walking, start paving another one.",
        author: "Dolly Parton"
    },
    {
        quote: "What you do makes a difference, and you have to decide what kind of difference you want to make.",
        author: "Jane Goodall"
    },
    {
        quote: "I choose to make the rest of my life the best of my life.",
        author: "Louise Hay"
    },
    {
        quote: "People's passion and desire for authenticity is strong.",
        author: "Constance Wu"
    },
    {
        quote: "A surplus of effort could overcome a deficit of confidence.",
        author: "Sonia Sotomayor"
    },
    {
        quote: "Doubt is a killer. You just have to know who you are and what you stand for.",
        author: "Jennifer Lopez"
    },
    {
        quote: "I learned a long time ago that there is something worse than missing the goal, and that’s not pulling the trigger.",
        author: "Mia Hamm"
    },
    {
        quote: "Some people want it to happen, some wish it would happen, others make it happen.",
        author: "Michael Jordan"
    },
    {
        quote: "When you have a dream, you've got to grab it and never let go.",
        author: "Carol Burnett"
    },
    {
        quote: "Nothing is impossible. The word itself says 'I'm possible!'",
        author: "Audrey Hepburn"
    },
    {
        quote: "There is nothing impossible to they who will try.",
        author: "Alexander the Great"
    },
    {
        quote: "The bad news is time flies. The good news is you're the pilot.",
        author: "Michael Altshuler"
    },
    {
        quote: "Life has got all those twists and turns. You've got to hold on tight and off you go.",
        author: "Nicole Kidman"
    },
    {
        quote: "Keep your face always toward the sunshine, and shadows will fall behind you.",
        author: "Walt Whitman"
    },
    {
        quote: "Be courageous. Challenge orthodoxy. Stand up for what you believe in. When you are in your rocking chair talking to your grandchildren many years from now, be sure you have a good story to tell.",
        author: "Amal Clooney"
    },
    {
        quote: "You define your own life. Don't let other people write your script.",
        author: "Oprah Winfrey"
    },
    {
        quote: "You are never too old to set another goal or to dream a new dream.",
        author: "Malala Yousafzai"
    },
    {
        quote: "For me, becoming isn’t about arriving somewhere or achieving a certain aim. I see it instead as forward motion, a means of evolving, a way to reach continuously toward a better self. The journey doesn't end.",
        author: "Michelle Obama"
    },
    {
        quote: "Spread love everywhere you go.",
        author: "Mother Teresa"
    },
    {
        quote: "Do not allow people to dim your shine because they are blinded. Tell them to put some sunglasses on.",
        author: "Lady Gaga"
    },
    {
        quote: "If you make your internal life a priority, then everything else you need on the outside will be given to you and it will be extremely clear what the next step is.",
        author: "Gabrielle Bernstein"
    },
    {
        quote: "You don't always need a plan. Sometimes you just need to breathe, trust, let go and see what happens.",
        author: "Mandy Hale"
    },
    {
        quote: "What lies behind you and what lies in front of you, pales in comparison to what lies inside of you.",
        author: "Ralph Waldo Emerson"
    },
    {
        quote: "I'm going to be gone one day, and I have to accept that tomorrow isn't promised. Am I okay with how I’m living today? It's the only thing I can help. If I didn't have another one, what have I done with all my todays? Am I doing a good job?",
        author: "Hayley Williams"
    },
    {
        quote: "No matter what people tell you, words and ideas can change the world.",
        author: "Robin Williams as John Keating in Dead Poets Society"
    },
    {
        quote: "I'm not going to continue knocking that old door that doesn't open for me. I'm going to create my own door and walk through that.",
        author: "Ava DuVernay"
    },
    {
        quote: "It is during our darkest moments that we must focus to see the light.",
        author: "Aristotle"
    },
    {
        quote: "Not having the best situation, but seeing the best in your situation is the key to happiness.",
        author: "Marie Forleo"
    },
    {
        quote: "Weaknesses are just strengths in the wrong environment.",
        author: "Marianne Cantwell"
    },
    {
        quote: "Just don't give up trying to do what you really want to do. Where there is love and inspiration, I don't think you can go wrong.",
        author: "Ella Fitzgerald"
    },
    {
        quote: "Silence is the last thing the world will ever hear from me.",
        author: "Marlee Matlin"
    },
    {
        quote: "In a gentle way, you can shake the world.",
        author: "Mahatma Gandhi"
    },
    {
        quote: "Learning how to be still, to really be still and let life happen - that stillness becomes a radiance.",
        author: "Morgan Freeman"
    },
    {
        quote: "Everyone has inside of him a piece of good news. The good news is that you don't know how great you can be! How much you can love! What you can accomplish! And what your potential is!",
        author: "Anne Frank"
    },
    {
        quote: "All you need is the plan, the road map, and the courage to press on to your destination.",
        author: "Earl Nightingale"
    },
    {
        quote: "I care about decency and humanity and kindness. Kindness today is an act of rebellion.",
        author: "Pink"
    },
    {
        quote: "If you have good thoughts they will shine out of your face like sunbeams and you will always look lovely.",
        author: "Roald Dahl"
    },
    {
        quote: "Try to be a rainbow in someone's cloud.",
        author: "Maya Angelou"
    },
    {
        quote: "We must let go of the life we have planned, so as to accept the one that is waiting for us.",
        author: "Joseph Campbell"
    },
    {
        quote: "Find out who you are and be that person. That's what your soul was put on this earth to be. Find that truth, live that truth, and everything else will come.",
        author: "Ellen DeGeneres"
    },
    {
        quote: "Real change, enduring change, happens one step at a time.",
        author: "Ruth Bader Ginsburg"
    },
    {
        quote: "I tell myself, ‘You've been through so much, you've endured so much, time will allow me to heal, and soon this will be just another memory that made me the strong woman, athlete, and mother I am today.’",
        author: "Serena Williams"
    },
    {
        quote: "Live your beliefs and you can turn the world around.",
        author: "Henry David Thoreau"
    },
    {
        quote: "Our lives are stories in which we write, direct and star in the leading role. Some chapters are happy while others bring lessons to learn, but we always have the power to be the heroes of our own adventures.",
        author: "Joelle Speranza"
    },
    {
        quote: "I have learned over the years that when one's mind is made up, this diminishes fear; knowing what must be done does away with fear.",
        author: "Rosa Parks"
    },
    {
        quote: "The moral of my story is the sun always comes out after the storm. Being optimistic and surrounding yourself with positive loving people is for me, living life on the sunny side of the street.",
        author: "Janice Dean"
    },
    {
        quote: "We generate fears while we sit. We overcome them by action.",
        author: "Dr. Henry Link"
    },
    {
        quote: "We are not our best intentions. We are what we do.",
        author: "Amy Dickinson"
    },
    {
        quote: "I've noticed when I fear something, if I just end up doing it, I'm grateful in the end.",
        author: "Colleen Hoover"
    },
    {
        quote: "When you've seen beyond yourself, then you may find, peace of mind is waiting there.",
        author: "George Harrison"
    },
    {
        quote: "What you get by achieving your goals is not as important as what you become by achieving your goals.",
        author: "Zig Ziglar"
    },
    {
        quote: "You are never too old to set another goal or to dream a new dream.",
        author: "C.S. Lewis"
    },
    {
        quote: "I believe that if you'll just stand up and go, life will open up for you. Something just motivates you to keep moving.",
        author: "Tina Turner"
    },
    {
        quote: "The simple act of listening to someone and making them feel as if they have truly been heard is a most treasured gift.",
        author: "L. A. Villafane"
    },
    {
        quote: "Don't be afraid. Because you're going to be afraid. But remember when you become afraid, just don't be afraid.",
        author: "Joan Jett"
    },
    {
        quote: "The only limit to our realization of tomorrow will be our doubts today.",
        author: "Franklin Delano Roosevelt"
    },
    {
        quote: "We all have problems. But it's not what happens to us, [it 's] the choices we make after.",
        author: "Elizabeth Smart"
    },
    {
        quote: "You don’t have to be defined or confined by your environment, by your family circumstances, and certainly not by your race or gender.",
        author: "Mariah Carey"
    },
    {
        quote: "When we let fear be our master, we cannot be happy and free as a butterfly. But when we choose to trust the journey and embrace love and joy, we are free to fly.",
        author: "Annicken R. Day"
    },
    {
        quote: "A lot of people are afraid to say what they want. That's why they don't get what they want.",
        author: "Madonna"
    },
    {
        quote: "Being vulnerable is a strength, not a weakness.",
        author: "Selena Gomez"
    },
    {
        quote: "In order for the light to shine so brightly, the darkness must be present.",
        author: "Sir Francis Bacon"
    },
    {
        quote: "We have to be better. We have to love more, hate less. We've gotta listen more and talk less. We've gotta know that this is everybody's responsibility.",
        author: "Meghan Rapinoe"
    },
    {
        quote: "Trying to grow up is hurting. You make mistakes. You try to learn from them, and when you don't, it hurts even more.",
        author: "Aretha Franklin"
    },
    {
        quote: "Never bend your head. Always hold it high. Look the world straight in the eye.",
        author: "Helen Keller"
    },
    {
        quote: "Let love rule.",
        author: "Lenny Kravitz"
    },
    {
        quote: "The only journey is the one within.",
        author: "Rainer Maria Rilke"
    },
    {
        quote: "Embrace the glorious mess that you are.",
        author: "Elizabeth Gilbert"
    },
    {
        quote: "You are never too young to lead, and you should never doubt your capacity to triumph where others have not.",
        author: "Kofi Annan"
    },
    {
        quote: "The secret to getting ahead is getting started",
        author: "Mark Twain"
    },
    {
        quote: "All our dreams can come true, if we have the courage to pursue them.",
        author: "Walt Disney"
    }
];

export const SwitchesEmotionsHappyInspirationStation: CardTemplate = {
    cardId: "d955867d-4293-4dce-abae-5b54807c2a4a",
    title: 'Inspiration station',
    Header: InspirationStationHeaderIcon,
    Icon: InspirationStationIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <View style={{ flex: 1 }}>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata='Gaining new perspectives and wisdom' />
            </View>
        </>
    ),
    Images: InspirationStationLineDrawing,
    CardContent: () => (
        <>

            <DefaulttextWithRightArrow textdata="Select the ‘New quote’ button." />
            <DefaulttextWithRightArrow textdata="Find a quote that you like." />
            <DefaulttextWithRightArrow textdata="Pause and understand how it could apply to you." />
            <DefaulttextWithRightArrow textdata="Take a screenshot or write it down somewhere you will frequently see it and use it as inspiration as you go day to day." />
            <DefaulttextWithRightArrow textdata="Repeat with as many quotes as you like." />

        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Inspiration+station.m4a",
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: `This activity assists in managing anxiety by refocusing attention on the present moment. Engaging with the senses and acknowledging physical sensations through the questions provided helps alleviate tension and enhances self awareness. Regular practice encourages emotional regulation and mindfulness, contributing to a calmer, more centred state of mind.`,
    BottomRow: () => {
        const [quote, setQuote] = useState("")
        const handleRandomizeQuote = () => {
            const randomIndex = Math.floor(Math.random() * quotesArray.length);
            const currentQuote = quotesArray[randomIndex]
            setQuote(`${currentQuote.quote} - ${currentQuote.author}`);
        }

        return (
            <>
                <View style={{ alignItems: 'center', justifyContent: 'center', width: '100%', marginVertical: 20 }}>
                    <DefaultButton onPress={handleRandomizeQuote}>New quote</DefaultButton>
                </View>
                <View>
                    <DefaultText style={{ color: DecidaColors.Black, fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', marginBottom: 40 }}>{quote}</DefaultText>
                </View>

                <DefaulTextWithTipsIcon textdata="If you find a quote that you think someone you know needs to hear, you can share it with them using the share button." />
            </>
        )
    },
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Explore the effects of anxiety, how it often pushes the mind into the future, and learn strategies for staying in the present to manage anxiety effectively.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by discussing the concept of inspiration and its importance in personal growth and development.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that today's focus is on learning how to draw inspiration from others, highlighting the positive impact it can have on creativity, motivation, and goal setting.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Inspiration Station Activity (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Access the <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Inspiration station worksheet' link={Worksheets.inspirationStationWorksheet.uri} /> and print a copy for each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the students to complete the quote matching task.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ When all students are completed then read out the correct answers available in the additional materials section. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Personal Connection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students continue with the worksheet, completing the reflections section.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Sharing and Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Create a supportive and respectful environment for students to share their reflections and insights.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite volunteers to share the quotes they chose and the specific inspirations they drew from them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ oFacilitate a class discussion, allowing students to discuss common themes, different perspectives, and the potential impact of incorporating inspiration into their lives.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforcement of Key Messages: Remind students of the vital role inspiration plays in personal growth, creativity, and motivation. Encourage them to actively seek and harness inspiration from diverse sources, emphasising its role in personal development and aspirations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ oInstruct students to navigate to the Inspiration Station Switch and challenge the students to regularly find new inspirations and discuss their insights with peers.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Writing materials </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Projector or screensharing (optional)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={Worksheets.inspirationStationWorksheet.label} link={Worksheets.inspirationStationWorksheet.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={Worksheets.inspirationStationAnswersWorksheet.label} link={Worksheets.inspirationStationAnswersWorksheet.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Motivational Positive Quotes – 11 Reasons to Read Them</DefaultText>
                        <DefaultTextWithLink text='https://www.successconsciousness.com/blog/motivation/motivational-quotes/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Why do Inspirational Quotes Matter?</DefaultText>
                        <DefaultTextWithLink text='https://inventionland.com/blog/why-do-inspirational-quotes-matter/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>90 Wise Life Lesson Quotes to Keep You Going</DefaultText>
                        <DefaultTextWithLink text='https://www.lovetoknow.com/quotes-quips/daily-life/life-lesson-quotes' />
                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Short, CardTimeType.Medium],
    people: [CardPeopleType.Individual, CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Happy]
}


