import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { TenseAndRelaxIcon } from '../../explore/src/tense-and-relax-icon'
import { TenseAndRelaxIconInverted } from '../../explore/src/tense-and-relax-icon-inverted'
import { TenseAndRelaxImage1 } from './src/tense-and-relax-image1'
import { CommonStyles } from '../../../common/const'
import DefaultTextWithLink from '../../common/default-text-with-link'

export const SwitchesEmotionsAnxiousTenseAndRelax: CardTemplate = {
    cardId: "7c923c6d-1994-4101-acdb-5cda8350a77b",
    title: 'Tense and relax',
    Header: TenseAndRelaxIconInverted,
    lessonPlan: true,
    Icon: TenseAndRelaxIcon,
    Images: () => (
        <>
            <TenseAndRelaxImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Calming a busy mind, reducing tension. Can be a good group activity."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Sit in a comfortable position."} />
                <DefaultTextWithDownArrow textdata={"Breathe in three times slowly and deeply, holding for a few seconds each time before exhaling."} />
                <DefaultTextWithDownArrow textdata={"Next, as you slowly inhale and hold your breath, tense all the muscles in your face (eyes, forehead, ears and jaw) and then relax as you breathe out."} />
                <DefaultTextWithDownArrow textdata={"Repeat again, but this time tense muscles in the neck and shoulder."} />
                <DefaultTextWithDownArrow textdata={"Repeat the process for hands, belly, legs and finally feet."} />
                <DefaultTextWithoutArrows textdata={"Stretch out your whole body, like you are waking up in the morning, and enjoy the feeling of calmness and relaxation."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: 'When we become angry or anxious our bodies will often respond with muscle tension. This tension can cause irritability which will affect how we relax and make it more difficult to focus. By doing the Tense and Relax exercise it reduces the tension in the body that often builds up without us even noticing it, making us feel better and increasing focus.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice a controlled breathing meditative exercise to manage anxious thoughts and raise awareness toward parts of the body that are holding tension.
                        </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 40 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Briefly explain that high levels of stress and anxiety can lead to muscle tension, resulting in stiffness and pain, particularly in the neck and shoulders.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the Tense and Relax process, emphasising the connection between emotional stress and physical tension.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to sit comfortably and either close their eyes or keep them open, depending on their preference.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead students through the following steps: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Take three deep breaths, holding each for a few seconds before exhaling.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- On the fourth breath, tense all the muscles in the face, holding for a few seconds, then relax while exhaling.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Repeat the process for hands, belly, hips, legs, and finally, feet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Open eyes and stretch out the whole body as if waking up in the morning.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Meditation Session (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide a quiet space and dim the lighting (if possible).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Direct students through the Tense and Relax process, offering guidance for each step to set the pace.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allow students a moment to readjust before turning the lights back up.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Debrief (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students how the exercise made them feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the benefits of the Tense and Relax technique for releasing tension in the body and promoting relaxation.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minute):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the practicality of the Tense and Relax method as a tool for managing stress.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to find and use the Tense and Relax switch in the Switch4Schools app whenever needed.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Quiet space, ideally with dim lighting </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Can Stress Cause Shoulder Pain? -  </DefaultText>
                        <DefaultTextWithLink text='https://www.ivyrehab.com/news/can-stress-cause-shoulder-pain/' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"Sitting comfortably doesn’t mean slouching or sitting in a way that bends people forward or inward. It can help to do this exercise lying on the ground to ensure an ‘open’ posture."} />
            <DefaulTextWithTipsIcon textdata={"After you have gone through the exercise from head to feet, you can extend this exercise by repeating the process in reverse (from feet to head) before stretching out."} />
            <DefaulTextWithTipsIcon textdata={"This can be a great post-lunch exercise to reset in the afternoon and improve concentration."} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Tense+and+Relax.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Anxious]
}

