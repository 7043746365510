import Svg, { Path, Text, TSpan } from 'react-native-svg'
import { SvgEmotionIconProps } from './icon-emotion-types'

export const IconHappy = ({ width, height, withoutLabel }: SvgEmotionIconProps) =>
    <Svg width={width} height={height} viewBox="0 0 100.8 108.758">
        <Path d="M0,0A41.5,41.5,0,0,0,41.5-41.5,41.5,41.5,0,0,0,0-83,41.5,41.5,0,0,0-41.5-41.5,41.5,41.5,0,0,0,0,0" transform="translate(50.4 85.5)" fill="#fff" />
        <Path d="M0,0A41.5,41.5,0,0,0,41.5-41.5,41.5,41.5,0,0,0,0-83,41.5,41.5,0,0,0-41.5-41.5,41.5,41.5,0,0,0,0,0Z" transform="translate(50.4 85.5)" fill="none" stroke="#0e8413" strokeWidth="5" />
        <Path d="M0,0A6.532,6.532,0,0,0,6.532-6.532,6.532,6.532,0,0,0,0-13.063,6.532,6.532,0,0,0-6.532-6.532,6.532,6.532,0,0,0,0,0" transform="translate(33.772 38.549)" fill="#0e8413" />
        <Path d="M0,0A6.532,6.532,0,0,0,6.532-6.532,6.532,6.532,0,0,0,0-13.063,6.532,6.532,0,0,0-6.532-6.532,6.532,6.532,0,0,0,0,0" transform="translate(66.412 38.549)" fill="#0e8413" />
        <Path d="M0,0S16.68,21.132,39.6.492" transform="translate(30.115 53.646)" fill="none" stroke="#0e8413" strokeWidth="5" />
        <Path d="M0,0S-1.213-4.927,3.9-3.137" transform="translate(28.176 55.241)" fill="#0e8413" />
        <Path d="M0,0S4.857-1.47,3.337,3.735" transform="translate(68.035 52.287)" fill="#0e8413" />
        {withoutLabel ? (null) : (
            <>
                <Path d="M0,0A9.941,9.941,0,0,0-9.941,9.941,9.941,9.941,0,0,0,0,19.882H77.918A9.941,9.941,0,1,0,77.918,0Z" transform="translate(11.441 87.376)" fill="#fff" />
                <Text transform="translate(22.933 102.899)" fill="#0e8413" fontSize="15" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">HAPPY</TSpan></Text>
                <Path d="M0,0A9.941,9.941,0,0,0-9.941,9.941,9.941,9.941,0,0,0,0,19.882H77.918A9.941,9.941,0,1,0,77.918,0Z" transform="translate(11.441 87.376)" fill="none" stroke="#0e8413" strokeWidth="3" />
            </>
        )}
    </Svg>
