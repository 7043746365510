import { Activities } from '../../common/activities'
import { ActivityGroupTemplate } from '../../common/activity-group-template'
import { CreativityIcon } from '../icons/creativity-icon'

export const SwitchesActivityGroupCreativity: ActivityGroupTemplate = {
    title: 'Creativity',
    Icon: CreativityIcon,
    activity: Activities.CREATIVITY,
    subtitle: 'Games and activities that inspire creativity, problem solving and abstract thought.'
}
