import { View, StyleSheet, StyleProp, TextStyle, ViewStyle, Text, Dimensions } from "react-native";
import { DefaultText } from "../../common/default-text";
import { rvCard } from "./card-color-scheme";
import { useReactiveVar } from "@apollo/client";
import { DecidaColors } from "../../../common/decida-colors";

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600
interface Props {
    textdata: string
    style?: StyleProp<TextStyle>
    textStyle?: StyleProp<TextStyle>
    itallics?: string

}


export const DefaultTextNoArrowLeftAlignedDoubleIndentation = ({ textdata, style, itallics, textStyle }: Props) => {
    const color = useReactiveVar(rvCard)?.colorScheme
    return (
        <View style={[styles.content, style]}>
            <View style={styles.containingView}>
                <View style={styles.itallicsOffset}>
                    <Text style={styles.itallics}>{itallics}</Text>
                    <DefaultText style={[styles.text, textStyle]}>
                        {textdata}
                    </DefaultText>
                </View>
            </View>
        </View>
    )

}



const styles = StyleSheet.create({


    content: {
        justifyContent: 'center',
        marginVertical: 0,
        marginBottom: 10,
        paddingRight: windowWidthcondition ? 50 : 0,
        paddingLeft: windowWidthcondition ? 0 : 0,


    } as ViewStyle,
    text: {
        textAlign: 'auto',
        color: DecidaColors.Gray,


    } as TextStyle,

    containingView: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        marginLeft: windowWidthcondition ? 100 : 0
    },
    itallics: {
        fontSize: 20,
        fontStyle: 'italic',
        color: DecidaColors.Gray,
    },
    itallicsOffset: {
        marginLeft: windowWidthcondition ? 0 : 0,
        flex: 1,
    }
})

