import { API, graphqlOperation } from "aws-amplify"
import { MutableRefObject } from "react"
import { WorkSheet } from "xlsx"
import { GetTeacherByEmailQueryVariables, GetTeacherByEmailQuery, CreateClassInput } from "../../../common/API"
import { getTeacherByEmail } from "../../../common/graphql/queries"
import { ALL_CLASSES, Message, OtherTeachers, Sheet, TeacherClass, TeacherToCreate, TeacherToUpdate } from "./common-import-school-data-const-and-types"
import { convertSpreadsheetToRows } from "./convert-spreadsheet-to-rows"
import { ListClassTeachersAndStudentsBySchoolIDQueryItem } from "./list-class-teachers-and-students-by-school-id"
import { validateFieldNotUsedInPreviousRows } from "./validate-field-not-used-in-previou-rows"
import { GraphQLResult } from '@aws-amplify/api-graphql'
import _ from "lodash"
import { emailValidator } from "../../common/email-validator"

type Params = {
    sheet: WorkSheet,
    schoolID: string,
    errors: MutableRefObject<Message[]>,
    warnings: MutableRefObject<Message[]>,
    info: MutableRefObject<Message[]>,
    teacherToCreate: MutableRefObject<TeacherToCreate[]>,
    teacherToUpdate: MutableRefObject<TeacherToUpdate[]>,
    otherTeachers: MutableRefObject<OtherTeachers[]>,
    allClassesFromTeacherSheet: MutableRefObject<string[]>,
    existingClasses: MutableRefObject<ListClassTeachersAndStudentsBySchoolIDQueryItem[]>,
    classToCreate: MutableRefObject<CreateClassInput[]>,
}

export const validateTeacherSheet = async ({
    sheet,
    schoolID,
    errors,
    warnings,
    info,
    teacherToCreate,
    teacherToUpdate,
    allClassesFromTeacherSheet,
    existingClasses,
    classToCreate,
    otherTeachers,
}: Params) => {
    if (sheet == null) {
        return
    }

    if (sheet['A6']?.v !== 'First Name') {
        errors.current.push({ sheet: Sheet.Teacher, row: 6, message: 'header column expected, please do not modify row 1-6 of template file.' })
        return
    }

    const rows = convertSpreadsheetToRows({
        startingRow: 7,
        columnOrder: ['firstName', 'lastName', 'email', 'assignToAllllClasses'],
        sheet,
        filter: row => row.firstName != null || row.lastName != null || row.email != null,
        hasDynamicClasses: true,
    }).map(row => ({ ...row, email: row.email?.toLowerCase() }))

    rows.forEach(({ firstName, lastName, email, row, classes }, index) => {
        if (firstName == null || lastName == null || email == null) {
            errors.current.push({ sheet: Sheet.Teacher, row, message: `missing mandatory field ${firstName == null ? '"First Name"' : ''}${lastName == null ? (firstName == null ? ', ' : '') + '"Last Name"' : ''}${email == null ? (firstName == null || lastName == null ? ', ' : '') + '"Email address"' : ''}` })
        }

        if (email && emailValidator(email) === "Email is not valid.") {
            errors.current.push({ sheet: Sheet.Teacher, row, message: 'teacher email is not valid' })
        }

        validateFieldNotUsedInPreviousRows(rows, 'email', index, Sheet.Teacher, errors)

        allClassesFromTeacherSheet.current = allClassesFromTeacherSheet.current.concat(classes.filter(c => c != null))
    })
    allClassesFromTeacherSheet.current = _.uniq(allClassesFromTeacherSheet.current)

    allClassesFromTeacherSheet.current.forEach(async name => {
        const clazz = existingClasses.current.find(c => c?.name === name)

        if (!clazz) {
            classToCreate.current.push({
                name,
                schoolID,
                toggleRequestForChat: true,
                archived: false,
                emotionIntensityLevel: 3,
            })
        }
    })

    await Promise.all(
        rows.map(async ({ firstName, lastName, email, assignToAllllClasses, row, classes }) => {
            if (email == null) {
                return
            }

            if (emailValidator(email) !== "Email is valid.") {
                return
            }

            const response = await API.graphql(graphqlOperation(
                getTeacherByEmail,
                {
                    email
                } as GetTeacherByEmailQueryVariables
            )) as GraphQLResult<GetTeacherByEmailQuery>

            const items = response.data?.getTeacherByEmail?.items.filter(i => i?._deleted !== true) || []
            let teacher: Exclude<GetTeacherByEmailQuery['getTeacherByEmail'], undefined | null>['items'][0] | undefined

            if (items.length > 1) {
                teacher = items.find(i => i?.schoolID === schoolID)

                if (teacher == null) {
                    errors.current.push({ sheet: Sheet.Teacher, row, message: 'teacher email found in multiple schools and none is assigned to current school, please process this line manually' })
                    return
                }
            } else {
                teacher = items[0]
            }

            const effectiveClass: TeacherClass[] = assignToAllllClasses != null ? [ALL_CLASSES] : classes

            if (teacher) {
                if (schoolID !== teacher.schoolID) {
                    warnings.current.push({ sheet: Sheet.Teacher, row, message: `${teacher.firstName} ${teacher.lastName} will be moved from "${teacher.schoolID}" to the current school` })
                }

                if (firstName !== teacher.firstName || lastName !== teacher.lastName || email !== teacher.email || schoolID !== teacher.schoolID) {
                    teacherToUpdate.current.push({
                        id: teacher.id,
                        email: email,
                        schoolID,
                        firstName: firstName,
                        lastName: lastName,
                        cognitoUsername: email,
                        _version: teacher._version,
                        classes: effectiveClass,
                    })
                } else {
                    otherTeachers.current.push({ id: teacher.id, classes })
                }
            } else {
                teacherToCreate.current.push({
                    email: email,
                    schoolID,
                    firstName: firstName,
                    lastName: lastName,
                    cognitoUsername: email,
                    classes: effectiveClass,
                })
            }
        }),
    )

    info.current.push({ sheet: Sheet.Teacher, message: `${teacherToCreate.current.length} teacher(s) to be created and ${teacherToUpdate.current.length} to be updated` })
    info.current.push({ sheet: Sheet.Teacher, message: `${classToCreate.current.length} class(es) to be created` })
}
