import { gql, useMutation, useQuery } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { API } from 'aws-amplify'
import * as Clipboard from 'expo-clipboard'
import { useState } from 'react'
import { View } from 'react-native'
import uuid from 'react-native-uuid'
import { CreateSchoolAdminMutation, CreateSchoolAdminMutationVariables, DeleteSchoolAdminMutation, DeleteSchoolAdminMutationVariables, GetSchoolAdminQuery, GetSchoolAdminQueryVariables, GetSchoolQuery, GetSchoolQueryVariables, SchoolAdmin, UpdateSchoolAdminMutation, UpdateSchoolAdminMutationVariables, UpdateSchoolMutation, UpdateSchoolMutationVariables } from '../../common/API'
import { PrimitiveOnly } from '../../common/common-types'
import { UserGroup } from '../../common/constants'
import { generatePassword } from '../../common/generate-password'
import { updateSchool } from '../../common/graphql/mutations'
import { CheckBox } from '../common/checkbox'
import { DefaultButton } from '../common/default-button'
import { CustomComponent, CustomSetter, DeleteFunction, Form, FormPropState, SaveFunction } from '../common/form'
import { getApiHeaders } from '../common/get-api-header'
import { ImpersonateButton } from '../common/impersonate-button'
import { InputCognitoUsername } from '../common/input-cognito-username'
import { Loading, rvIsLoading } from '../common/loading'
import { ScreenNames } from '../common/screen-names'
import { showAlert } from '../common/universal-alert'
import { adminGetSchoolQuery } from '../custom-graphql/queries/admin-custom-queries/admin-get-school-query'
import { AdminStackNavigatorParamList } from './admin-route-param-types'
import { createSchoolAdminMutation, deleteSchoolAdminMutation, mutateSchoolAdminRefetchQueries as refetchQueries, updateSchoolAdminMutation } from './graphql-scripts'
import { adminGetSchoolAdminQuery } from '../custom-graphql/queries/admin-custom-queries/admin-get-school-admin-query'

export const AdminSchoolAdmin = ({ route: { params: { id, schoolID } }, navigation: { navigate } }: StackScreenProps<AdminStackNavigatorParamList, 'AdminSchoolAdmin'>) => {
    const { data, loading } = useQuery<GetSchoolAdminQuery, GetSchoolAdminQueryVariables>(gql`${adminGetSchoolAdminQuery}`, { variables: { id: id || '' }, skip: id == undefined })

    const [createSchoolAdmin] = useMutation<CreateSchoolAdminMutation, CreateSchoolAdminMutationVariables>(gql`${createSchoolAdminMutation}`, { refetchQueries })
    const [updateSchoolAdmin] = useMutation<UpdateSchoolAdminMutation, UpdateSchoolAdminMutationVariables>(gql`${updateSchoolAdminMutation}`, { refetchQueries })
    const [deleteSchoolAdmin] = useMutation<DeleteSchoolAdminMutation, DeleteSchoolAdminMutationVariables>(gql`${deleteSchoolAdminMutation}`, { refetchQueries })

    if (loading) {
        return <Loading isLoading />
    }

    const goToAdminSchool = () => navigate(ScreenNames.AdminSchool, { id: schoolID })

    const onSave: SaveFunction<PrimitiveOnly<SchoolAdmin>> = async (state, modelInstance) => {
        await createSchoolAdminLogin(modelInstance, state)

        if (modelInstance?.id == undefined || modelInstance.id === '') {
            return (await createSchoolAdmin({
                variables: {
                    input: {
                        id: uuid.v4().toString(),
                        cognitoUsername: state.cognitoUsername?.value || '',
                        email: state.email?.value || '',
                        schoolID,
                        _version: 1,
                        firstName: state.firstName?.value,
                        lastName: state.lastName?.value,
                        preferredUsername: state.preferredUsername?.value,
                    }
                }
            })).data?.createSchoolAdmin!
        } else {
            return (await updateSchoolAdmin({
                variables: {
                    input: {
                        id: modelInstance.id,
                        cognitoUsername: state.cognitoUsername?.value || '',
                        email: state.email?.value || '',
                        schoolID: modelInstance.schoolID,
                        _version: modelInstance._version,
                        firstName: state.firstName?.value,
                        lastName: state.lastName?.value,
                        preferredUsername: state.preferredUsername?.value,
                    }
                }
            })).data?.updateSchoolAdmin!
        }
    }

    const onDelete: DeleteFunction<SchoolAdmin> = async (modelInstance) => {
        if (modelInstance.id) {
            await API.post(
                'AdminQueries',
                '/deleteUser',
                {
                    body: {
                        username: modelInstance?.cognitoUsername,
                    },
                    headers: await getApiHeaders(),
                }
            )

            await deleteSchoolAdmin({
                variables: {
                    input: {
                        id: modelInstance.id,
                        _version: modelInstance._version,
                    }
                }
            })
        }
    }

    return (
        <Form
            model={data?.getSchoolAdmin as SchoolAdmin | undefined}
            goBack={goToAdminSchool}
            name='school admin'
            onSave={onSave}
            onDelete={onDelete}
            config={{
                cognitoUsername: {
                    isOptional: true,
                    component: InputCognitoUsernameWrapper,
                    customSetter: toLowerCase,
                },
                showIntroduction: {
                    hide: true,
                    isOptional: true,
                },
                schoolID: {
                    default: schoolID,
                    hide: true,
                },
                firstName: {},
                lastName: {},
                email: {
                    customSetter: toLowerCase,
                    textInputProps: {
                        keyboardType: 'email-address',
                    },
                },
                preferredUsername: {
                    isOptional: true
                },
            }}
            additionalComponents={AdminSchoolAdminAdditionalComponents}
        />
    )
}

const InputCognitoUsernameWrapper: CustomComponent<SchoolAdmin> = ({ modelInstance, state }) => {
    const [userExists, setUserExists] = useState<boolean | null>(null)

    return (
        <InputCognitoUsername
            userExists={userExists}
            setUserExists={setUserExists}
            value={state.cognitoUsername?.value || ''}
            onChangeText={state.cognitoUsername!.setter}
            title='none'
            editable={modelInstance ? false : true}
        />
    )
}

const AdminSchoolAdminAdditionalComponents = (schoolAdmin: SchoolAdmin | undefined) => {

    if (!schoolAdmin) return

    return (
        <View>
            <CoordinatorKeyComponent {...schoolAdmin} />
            <ResendTeacherInviteEmail {...schoolAdmin} />
            <ImpersonateButton cognitoUsername={schoolAdmin.cognitoUsername} userGroup={UserGroup.SchoolAdmin} email={schoolAdmin.email} />
        </View>
    )
}

const CoordinatorKeyComponent = (schoolAdmin: SchoolAdmin) => {
    const { data } = useQuery<GetSchoolQuery, GetSchoolQueryVariables>(gql`${adminGetSchoolQuery}`, { variables: { id: schoolAdmin?.schoolID } })

    const school = data?.getSchool

    const [updateSchoolMutation] = useMutation<UpdateSchoolMutation, UpdateSchoolMutationVariables>(gql`${updateSchool}`)

    const handleChange = (value: boolean) => {

        updateSchoolMutation({
            variables: {
                input: {
                    id: school?.id || "",
                    coordinatorID: value ? schoolAdmin?.id : null,
                    _version: school?._version
                }
            }
        })

    }

    return (
        <CheckBox type="rounded" checked={data?.getSchool?.coordinatorID === schoolAdmin.id} labelStyle={{ fontSize: 22 }} containerStyle={{ alignItems: 'flex-start', width: '100%' }} label="Key coordinator" onChange={handleChange} />
    )
}

const copyToClipboard = (password: string) => {
    Clipboard.setStringAsync(password);
};

const toLowerCase: CustomSetter<string | undefined> = (value, setter) => {
    setter(value?.toLowerCase())
}

const updatePreferredUsername = async (schoolAdmin: SchoolAdmin | undefined, state: FormPropState<SchoolAdmin>) => {

    const post = await API.post(
        'AdminQueries',
        '/updatePreferredUsername',
        {
            body: {
                username: state.preferredUsername?.value,
                cognitoUsername: schoolAdmin?.cognitoUsername
            },
            headers: await getApiHeaders(),
        }
    )
    console.log('updatePreferredUsername', post)
}

const createSchoolAdminLogin = async (schoolAdmin: SchoolAdmin | undefined, state: FormPropState<SchoolAdmin>) => {
    if (schoolAdmin && schoolAdmin.cognitoUsername && state.preferredUsername?.value) {
        state.cognitoUsername = {
            value: state.cognitoUsername?.value || '',
            setter: () => { }
        }
        updatePreferredUsername(schoolAdmin, state)
        return
    }

    const tempPassword = generatePassword()
    const message = `Password: ${tempPassword}`

    state.cognitoUsername = {
        value: state.cognitoUsername?.value || state.email?.value || '',
        setter: () => { }
    }

    if (schoolAdmin === undefined) {

        await API.post(
            'AdminQueries',
            '/createSchoolAdmin',
            {
                headers: await getApiHeaders(),
                body: {
                    cognitoUsername: state.cognitoUsername?.value,
                    email: state.email?.value,
                    password: tempPassword,
                },
            }
        )
        copyToClipboard(tempPassword)
        showAlert({
            title: 'Login Details',
            message: message,
            leftButtonText: 'Ok',
        })
    }
}

const ResendTeacherInviteEmail = (schoolAdmin?: SchoolAdmin) => {

    const resendInviteEmail = async () => {
        const tmpPassword = 'Switch_' + Math.floor(Math.random() * 1000000)
        const message = `${schoolAdmin?.email} has been sent new Password: ${tmpPassword} please confirm that have recieved email otherwise use temp password provided.`
        try {
            rvIsLoading(true)
            await API.post(
                'AdminQueries',
                '/createSchoolAdmin',
                {
                    body: {
                        cognitoUsername: schoolAdmin?.cognitoUsername,
                        email: schoolAdmin?.email,
                        password: tmpPassword,
                        messageAction: 'RESEND'
                    },
                    headers: await getApiHeaders(),
                }
            )
            copyToClipboard(tmpPassword)
            showAlert({
                title: 'Login Details',
                message: message,
                leftButtonText: 'Ok',
            })
        } catch (e: any) {
            const errorMessage = e?.response?.data?.message

            showAlert({
                title: 'An error has occured.',
                message: errorMessage || String(e.message),
                leftButtonText: 'Ok',
            })
        } finally {
            rvIsLoading(false)
        }
    }

    return <DefaultButton onPress={resendInviteEmail}>Resend Invitation Email</DefaultButton>
}
