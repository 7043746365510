import React, { useState } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Pressable, ViewStyle, DimensionValue } from 'react-native';
import { DecidaColors } from '../../common/decida-colors';
import { DefaultText } from './default-text';

interface StepperBoxProps<V> {
    options: { value: V, label: string }[];
    activeIndex: V;
    onPress: (index: V) => void;
    containerStyle?: ViewStyle
    height?: DimensionValue
}

export const StepperBox = <V,>({ options, activeIndex, onPress, containerStyle, height = 50 }: StepperBoxProps<V>) => {
    return (
        <View style={[styles.container, containerStyle]}>
            {options.map(({ value, label }) => (
                <TouchableOpacity
                    key={String(value)}
                    onPress={() => onPress(value)}
                    activeOpacity={0.8}
                    style={[
                        styles.stepperBox,
                        activeIndex === value && styles.activeStepper,
                        { height }
                    ]}
                >
                    <View style={[styles.topLine, { backgroundColor: activeIndex === value ? DecidaColors.Green : DecidaColors.GrayBorder, }]} />
                    <DefaultText textProps={{ adjustsFontSizeToFit: true, numberOfLines: 2 }} style={styles.label}>{label}</DefaultText>
                </TouchableOpacity>

            ))}
        </View>
    );
};

const styles = StyleSheet.create({
    label: {
        textAlign: 'center',
        fontSize: 16,
        color: DecidaColors.GrayText,
        paddingHorizontal: 5,
    },
    topLine: {
        position: 'absolute',
        top: -6,
        marginHorizontal: 2,
        width: '100%',
        height: 4,
    },
    appContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        flexDirection: 'row',
        gap: 1
    },
    stepperBox: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: DecidaColors.White,
        borderBottomWidth: 5,
        borderColor: '#fff',
    },
    activeStepper: {
        position: 'relative',
        boxShadow: 'inset 3px 12px 36px -25px rgba(0, 0, 0, 0.5)', // Inner shadow
    },
});
