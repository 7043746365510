import * as Linking from 'expo-linking'
import { getRouteNameAndParamsFromUrl } from './helper-functions';


type CallbackFunction<T extends Linking.QueryParams> = (params: T) => void;

export const fetchParamsFromUrl = async <T extends Linking.QueryParams>(callback: CallbackFunction<T>) => {
    try {
        const initialUrl = await Linking.getInitialURL();
        const { params } = getRouteNameAndParamsFromUrl(initialUrl);

        callback(params as T);
    } catch (error) {
        console.error('Error fetching params from URL:', error);
    }
};
