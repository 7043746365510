import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import DefaultTextWithCustomLink from '../../../common/default-text-with-custom-link'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { ShowTheBunnyIcon } from '../../icons/show-the-bunny-icon'
import { ShowTheBunnyIconInverted } from '../../icons/show-the-bunny-icon-inverted'
import { Worksheets } from '../../../../common/worksheets-imports'

export const SwitchesActivitiesFunAndPositiveShowMeTheBunny: CardTemplate = {
    cardId: "dcc48a81-f5ec-428f-9489-05c36e23e552",
    title: 'Show me the bunny!',
    Header: ShowTheBunnyIconInverted,
    Icon: ShowTheBunnyIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Keeping students engaged and creating a fun environment in your classroom."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"You will need some sort of small toy for each student that resembles a bunny. Alternatively, use the template in the additional resources to laminate and hand out."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"A prize for the winner."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Each student has an item that represents their bunny."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Get the students to come up with a name for their bunny and write it on the bunny."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Encourage students to keep their bunnies safe because at any point you may yell the phrase “Show me the bunny!”. At which point they will need to find their bunny and raise it above their heads."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Establish with your class that there is a reward system with this game called ‘bunny points’. The first person you see to raise their bunny when you say “Show me the bunny” gets 1 bunny point."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"If two or more people tied each of those people get 1 bunny point."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"At the end of the week or term the student or students with the most bunny points wins a prize of your choosing (handball, sticker, food, etc.)."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.FUN_AND_POSITIVE,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Additional Resources:"} />
            <DefaultTextWithCustomLink text={Worksheets.showMetheBunny.label} link={Worksheets.showMetheBunny.uri} />
        </>

    ),
    audioUrl: "",
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.FUN_AND_POSITIVE],
    action: [CardActionType.Action],
    level: []

}

