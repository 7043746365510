import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ColorValue, DimensionValue, Dimensions, Pressable, StyleSheet, Text, View } from 'react-native';
import { DefaultText } from '../../../common/default-text';
import { ScreenNames } from '../../../common/screen-names';
import { useResponsive } from '../../../common/use-responsive';
import { TeacherDrawerNavigatorPamarList } from '../../../teacher/teacher-route-param-types';
import { CardColorScheme } from '../../common/card-color-scheme';
import { CardTemplate } from '../../common/card-template';
import { commonCardStyles } from '../../common/emotion-card-image-sizes';
import { LessonPlansIcon } from '../icons/lesson-plans-icon';
import _ from 'lodash';
import { cards } from '../../emotion-cards/cards';
import EmotionHappyIcon from '../images/emotion-icon-happy';
import EmotionSadIcon from '../images/emotion-icon-sad';
import EmotionScaredIcon from '../images/emotion-icon-scared';
import EmotionAnxiousIcon from '../images/emotion-icon-anxious';
import EmotionAngryIcon from '../images/emotion-icon-angry';
import EmotionExcitedIcon from '../images/emotion-icon-excited';
import { IconSwitch4SchoolsEW } from '../../../icon/icon-switch-4-schools-ew';
import { Emotion } from '../../../../common/API';
import { SwitchesEmotionsAngryDrawBreath } from '../../emotion-cards/angry/breath-draw';
import { SwitchesEmotionsAngryPalmPush } from '../../emotion-cards/angry/palm-push';
import { SwitchesEmotionsAngryRespond } from '../../emotion-cards/angry/respond';
import { SwitchesEmotionsAngrySafeRant } from '../../emotion-cards/angry/safe-rant';
import { SwitchesLessonPlansMyEmotionsLessonPlans } from '../../lesson-plan-cards/my-emotions-leasson-plans';
import { SwitchesLessonPlansMyEmotionsLessonPlansLevel1 } from '../../lesson-plan-cards/my-emotions-leasson-plans-level-1';
import { SwitchesLessonPlansEmphaty } from '../../lesson-plan-cards/emphaty';
import { SwitchesLessonPlansGettingStarted } from '../../lesson-plan-cards/getting-started';
import { SwitchesLessonPlansIntroductionToAngry } from '../../lesson-plan-cards/introduction-to-angry';
import { SwitchesLessonPlansIntroductionToAnxious } from '../../lesson-plan-cards/introduction-to-anxious';
import { SwitchesLessonPlansIntroductionToExcited } from '../../lesson-plan-cards/introduction-to-excited';
import { SwitchesLessonPlansIntroductionToHappy } from '../../lesson-plan-cards/introduction-to-happy';
import { SwitchesLessonPlansIntroductionToSad } from '../../lesson-plan-cards/introduction-to-sad';
import { SwitchesLessonPlansIntroductionToScared } from '../../lesson-plan-cards/introduction-to-scared';
import { SwitchesLessonPlansIntroducingHappy } from '../../lesson-plan-cards/introducing-happy';
import { SwitchesLessonPlansIntroducingAnxiety } from '../../lesson-plan-cards/introducing-anxiety';
import { SwitchesLessonPlansIntroducingAngry } from '../../lesson-plan-cards/introducing-angry';
import { SwitchesLessonPlansIntroducingSad } from '../../lesson-plan-cards/introducing-sad';
import { SwitchesLessonPlansIntroducingScared } from '../../lesson-plan-cards/introducing-scared';
import { SwitchesLessonPlansIntroducingExcited } from '../../lesson-plan-cards/introducing-excited';
import { SwitchesLessonPlansUnderstandingEmotions } from '../../lesson-plan-cards/understanding-emotions';
import { SwitchesLessonPlansEmotionalRegulation } from '../../lesson-plan-cards/emotional-regulations';
import { SwitchesLessonPlansPerspectiveTaking } from '../../lesson-plan-cards/perespective-taking';
import { SwitchesLessonPlansEmotionalExpression } from '../../lesson-plan-cards/emotional-expression';
import { SwitchesLessonPlansEmotionalResilience } from '../../lesson-plan-cards/emotional-resilience';
import { SwitchesLessonPlansEmotionsVersusFeelings } from '../../lesson-plan-cards/emotions-versus-feelings';
import { SwitchesLessonPlansConflictResolution } from '../../lesson-plan-cards/conflict-resolution';
import { SwitchesLessonPlansSelfAwarenessAndEmotionalAwareness } from '../../lesson-plan-cards/self-awareness-and-emotional-awareness';
import { SwitchesLessonPlansUnderstandingAndManagingHapiness } from '../../lesson-plan-cards/understanding-and-managing-hapiness';
import { SwitchesLessonPlansUnderstandingAndManagingAnxiety } from '../../lesson-plan-cards/understanding-and-managing-anxiety';
import { SwitchesLessonPlansUnderstandingAndManagingAnger } from '../../lesson-plan-cards/understanding-and-managing-anger';
import { SwitchesLessonPlansUnderstandingAndManagingSadness } from '../../lesson-plan-cards/understanding-and-managing-sadness';
import { SwitchesLessonPlansUnderstandingAndManagingScared } from '../../lesson-plan-cards/understanding-and-managing-scared';
import { SwitchesLessonPlansUnderstandingAndManagingExcitement } from '../../lesson-plan-cards/understanding-and-managing-excitement';
import { SwitchesLessonPlansSocialIntelligence } from '../../lesson-plan-cards/social-intelligence';
import { SwitchesLessonPlansEmotionalIntelligenceInRelationships } from '../../lesson-plan-cards/emotional-intelligence-in-relationship';
import { SwitchesLessonPlansAdvancedEmpathy } from '../../lesson-plan-cards/advanced-emphaty';
import { SwitchesLessonPlansEmotionalManagement } from '../../lesson-plan-cards/emotional-management';
import { SwitchesLessonPlansAdvancedConflictManagement } from '../../lesson-plan-cards/advanced-conflict-management';
import { SwitchesLessonPlansEmotionalIntelligenceInLeadership } from '../../lesson-plan-cards/emotional-intelligence-in-leadership';
import { SwitchesEmotionsAngryFiveWhys } from '../../emotion-cards/angry/five-whys';
import { SwitchesEmotionsAngryMotivationMap } from '../../emotion-cards/angry/motivation-map';
import { SwitchesEmotionsAngryCalmingBreath } from '../../emotion-cards/angry/calming-breath';
import { SwitchesEmotionsAngryScrunchie } from '../../emotion-cards/angry/scrunchie';
import { SwitchesEmotionsAngryStopSteps } from '../../emotion-cards/angry/stop-steps';
import { SwitchesEmotionsAngrySignificance } from '../../emotion-cards/angry/significance';
import { SwitchesEmotionsAngryStoryTime } from '../../emotion-cards/angry/story-time';
import { SwitchesEmotionsAngryTenBreaths } from '../../emotion-cards/angry/ten-breaths';
import { SwitchesEmotionsAngryTheDoOver } from '../../emotion-cards/angry/the-do-over';
import { SwitchesEmotionsAngryTime } from '../../emotion-cards/angry/time';
import { SwitchesEmotionsAnxiousBoxBreathing } from '../../emotion-cards/anxious/box-breathing';
import { SwitchesEmotionsAnxiousBreathFloat } from '../../emotion-cards/anxious/breath-float';
import { SwitchesEmotionsAnxiousBubbleOfControl } from '../../emotion-cards/anxious/bubble-of-control';
import { SwitchesEmotionsAnxiousBucketOfSafeThoughts } from '../../emotion-cards/anxious/buckets-of-safe-thoughts';
import { SwitchesEmotionsAnxiousCircleOfInfluence } from '../../emotion-cards/anxious/circle-of-influence';
import { SwitchesEmotionsAnxiousStoryboarding } from '../../emotion-cards/anxious/storyboarding';
import { SwitchesEmotionsAnxiousTenseAndRelax } from '../../emotion-cards/anxious/tense-and-relax';
import { SwitchesEmotionsAnxiousTheCalmerFarmer } from '../../emotion-cards/anxious/the-calmer-farmer';
import { SwitchesEmotionsAnxiousTheInteruptor } from '../../emotion-cards/anxious/the-interuptor';
import { SwitchesEmotionsAnxiousWorryMonster } from '../../emotion-cards/anxious/worry-monster';
import { SwitchesEmotionsExcitedBuild } from '../../emotion-cards/excited/build';
import { SwitchesEmotionsExcitedCalmingMazeGames } from '../../emotion-cards/excited/calming-maze-games';
import { SwitchesEmotionsExcitedColorHunt } from '../../emotion-cards/excited/color-hunt';
import { SwitchesEmotionsExcitedExerciseOrDance } from '../../emotion-cards/excited/exercise-or-dance';
import { SwitchesEmotionsExcitedListenOut } from '../../emotion-cards/excited/listen-out';
import { SwitchesEmotionsExcitedRead } from '../../emotion-cards/excited/read';
import { SwitchesEmotionsExcitedRewardDream } from '../../emotion-cards/excited/reward-dream';
import { SwitchesEmotionsExcitedSketchIt } from '../../emotion-cards/excited/sketch-it';
import { SwitchesEmotionsExcitedTheTwister } from '../../emotion-cards/excited/the-twister';
import { SwitchesEmotionsExcitedThreeSketches } from '../../emotion-cards/excited/three-sketches';
import { SwitchesEmotionsExcitedTraceBreathing } from '../../emotion-cards/excited/trace-breathing';
import { SwitchesEmotionsExcitedTreasureHunt } from '../../emotion-cards/excited/treasure-hunt';
import { SwitchesEmotionsExcitedWeatherIt } from '../../emotion-cards/excited/weather-it';
import { SwitchesEmotionsHappyBeCreative } from '../../emotion-cards/happy/be-creative';
import { SwitchesEmotionsHappyRandomActOfKindness } from '../../emotion-cards/happy/random-act-of-kindness';
import { SwitchesEmotionsHappyGratefulness } from '../../emotion-cards/happy/gratefulness';
import { SwitchesEmotionsHappyJournaling } from '../../emotion-cards/happy/journaling';
import { SwitchesEmotionsHappyLaugh } from '../../emotion-cards/happy/laugh';
import { SwitchesEmotionsHappyLimerick } from '../../emotion-cards/happy/limerick';
import { SwitchesEmotionsHappyMakeANewFriend } from '../../emotion-cards/happy/make-a-new-friend';
import { SwitchesEmotionsHappyNewConnection } from '../../emotion-cards/happy/new-connection';
import { SwitchesEmotionsHappySmartGoals } from '../../emotion-cards/happy/smart-goals';
import { SwitchesEmotionsHappyStarsAndWishes } from '../../emotion-cards/happy/stars-and-wishes';
import { SwitchesEmotionsHappyValues } from '../../emotion-cards/happy/values';
import { SwitchesEmotionsHappyWhyYouLike } from '../../emotion-cards/happy/why-you-like';
import { SwitchesEmotionsSadBeSocial } from '../../emotion-cards/sad/be-social';
import { SwitchesEmotionsSadFutureLetter } from '../../emotion-cards/sad/future-letter';
import { SwitchesEmotionsScaredFunnyVoice } from '../../emotion-cards/scared/funny-voice';
import { SwitchesEmotionsSadGrowthMindset } from '../../emotion-cards/sad/growth-mindset';
import { SwitchesEmotionsSadHug } from '../../emotion-cards/sad/hug';
import { SwitchesEmotionsSadLikePage } from '../../emotion-cards/sad/like-page';
import { SwitchesEmotionsSadMuisc } from '../../emotion-cards/sad/music';
import { SwitchesEmotionsSadReachOut } from '../../emotion-cards/sad/reach-out';
import { SwitchesEmotionsSadSmallSteps } from '../../emotion-cards/sad/small-steps';
import { SwitchesEmotionsSadSmile } from '../../emotion-cards/sad/smile';
import { SwitchesEmotionsSadStretch } from '../../emotion-cards/sad/stretch';
import { SwitchesEmotionsSadStretching } from '../../emotion-cards/sad/stretching';
import { SwitchesEmotionsSadWalk } from '../../emotion-cards/sad/walk';
import { SwitchesEmotionsScaredBreathCycle } from '../../emotion-cards/scared/breath-cycle';
import { SwitchesEmotionsScaredCartoonIt } from '../../emotion-cards/scared/cartoon-it';
import { SwitchesEmotionsScaredCountDown } from '../../emotion-cards/scared/countdown';
import { SwitchesEmotionsScaredFaceIt } from '../../emotion-cards/scared/face-it';
import { SwitchesEmotionsScaredMantra } from '../../emotion-cards/scared/mantra';
import { SwitchesEmotionsScaredIdentify5 } from '../../emotion-cards/scared/identify-5';
import { SwitchesEmotionsScaredMySafePlace } from '../../emotion-cards/scared/my-safe-place';
import { SwitchesEmotionsScaredRoleModel } from '../../emotion-cards/scared/role-model';
import { SwitchesEmotionsScaredSeeIt } from '../../emotion-cards/scared/see-it';
import { SwitchesEmotionsScaredTalkAboutIt } from '../../emotion-cards/scared/talk-about-it';
import { SwitchesEmotionsScaredVisualise } from '../../emotion-cards/scared/visualise';
import { SwitchesEmotionsScaredTheAnticipator } from '../../emotion-cards/scared/the-anticipator';
import { SwitchesEmotionsSadAnimalMashup } from '../../emotion-cards/sad/animal-mashup';
import { SwitchesEmotionsHappyDrawHappiness } from '../../emotion-cards/happy/draw-happiness';
import { SwitchesEmotionsAnxiousPinwheel } from '../../emotion-cards/anxious/pinwheel';
import { SwitchesEmotionsAnxiousReframe } from '../../emotion-cards/anxious/reframe';
import { SwitchesEmotionsAnxiousSleepChalk } from '../../emotion-cards/anxious/sleep-chalk';
import { SwitchesEmotionsAnxiousDearDiaryAnxious } from '../../emotion-cards/anxious/dear-diary-anxious';
import { SwitchesEmotionsHappyStorySentence } from '../../emotion-cards/happy/story-sentence';
import { SwitchesEmotionsHappySongLines } from '../../emotion-cards/happy/song-lines';
import { SwitchesEmotionsScaredBalonBreathing } from '../../emotion-cards/scared/balon-breathing';
import { SwitchesEmotionsAngryCalmDownCorner } from '../../emotion-cards/angry/calm-down-corner';
import { SwitchesEmotionsAnxiousDrinkOfWater } from '../../emotion-cards/anxious/drink-of-water';
import { SwitchesEmotionsExcitedWallsPushUps } from '../../emotion-cards/excited/wall-push-ups';
import { SwitchesEmotionsAngryTakeABreak } from '../../emotion-cards/angry/take-a-break';
import { SwitchesEmotionsSadSereneGarden } from '../../emotion-cards/sad/serene-garden';
import { SwitchesEmotionsHappyFriendFinder } from '../../emotion-cards/happy/friend-finder';
import { DecidaColors } from '../../../../common/decida-colors';
import { SwitchesLessonPlansFeelingsVolcano } from '../../lesson-plan-cards/feelings-volcano';
import { SwitchesEmotionsScaredExposeIt } from '../../emotion-cards/scared/expose-it';
import { SwitchesEmotionsScaredHelpOrHurt } from '../../emotion-cards/scared/help-or-hurt';
import { SwitchesEmotionsAngryBodyScan } from '../../emotion-cards/angry/body-scan';
import { SwitchesEmotionsAngryFindYourCalm } from '../../emotion-cards/angry/find-your-calm';
import { SwitchesEmotionsAngryHeadRolls } from '../../emotion-cards/angry/head-rolls';
import { SwitchesEmotionsScaredBraveFace } from '../../emotion-cards/scared/brave-face';
import { SwitchesEmotionsAnxiousRealityCheck } from '../../emotion-cards/anxious/reality-check';
import { SwitchesEmotionsAnxiousHotCold } from '../../emotion-cards/anxious/hot-cold';
import { SwitchesEmotionsSadTheOrganizer } from '../../emotion-cards/sad/the-organizer';
import { SwitchesEmotionsSadBucketList } from '../../emotion-cards/sad/bucket-list';
import { SwitchesEmotionsSadTheScribe } from '../../emotion-cards/sad/the-scribe';
import { SwitchesEmotionsExcitedFingerTipRub } from '../../emotion-cards/excited/finger-tip-rub';
import { SwitchesEmotionsExcitedTheRiddler } from '../../emotion-cards/excited/the-riddler';
import { SwitchesEmotionsHappyInspirationStation } from '../../emotion-cards/happy/inspiration-station';
import { SwitchesEmotionsHappyTheInquisitor } from '../../emotion-cards/happy/the-inquisitor';
import { SwitchesEmotionsHappyTheHappyMonk } from '../../emotion-cards/happy/the-happy-monk';
import { SwitchesLessonPlansM1ExploringAndEmbracingEmotion } from '../../lesson-plan-cards/m1-exploring-and-embracing-emotions';
import { SwitchesLessonPlansM2EmotionalIntellegenceInDecissionMaking } from '../../lesson-plan-cards/m2-emotional-intellegence-in-decission-making';
import { SwitchesLessonPlansM3ThePersuitOfHappiness } from '../../lesson-plan-cards/m3-the-persuit-of-happiness';
import { SwitchesLessonPlansM4UnderstandingAnxiety } from '../../lesson-plan-cards/m4-understanding-anxiety';
import { SwitchesLessonPlansM5InvestigatingAnger } from '../../lesson-plan-cards/m5-investigating-anger';
import { SwitchesLessonPlansM6NavigatingSadness } from '../../lesson-plan-cards/m6-navigating-sadness';
import { SwitchesLessonPlansM7ConqueringFear } from '../../lesson-plan-cards/m7-conquering fear';
import { SwitchesLessonPlansM9EmotionalAgility } from '../../lesson-plan-cards/m9-emotional-agility';
import { SwitchesLessonPlansM10AdvancedResilience } from '../../lesson-plan-cards/m10-advanced-resilience';
import { SwitchesLessonPlansM11UnderstandingChangeAndAdaptability } from '../../lesson-plan-cards/m11-understanding-change-and-adaptability';
import { SwitchesLessonPlansM8HarnessingExcitement } from '../../lesson-plan-cards/m8-harnessing-excitement';
import { SwitchesLessonPlansM12SelfAwarenessAndWellbeing } from '../../lesson-plan-cards/m12-self-awareness-and-wellbeing';
import { LessonPlanWheelLevel1 } from '../images/lesson-plan-wheel-level1';
import { LessonPlanPlantLevel1 } from '../images/lesson-plan-plant-level1';
import { LessonPlanWheelLevel3 } from '../images/lesson-plan-wheel-level3';
import { LessonPlanPlantlevel3 } from '../images/lesson-plan-plant-level3';
import { LessonPlanWheelLevel5 } from '../images/lesson-plan-wheel-level5';
import { LessonPlanPlantlevel5 } from '../images/lesson-plan-plant-level5';
import { LessonPlanWheelLevel7 } from '../images/lesson-plan-wheel-level7';
import { LessonPlanPlantlevel7 } from '../images/lesson-plan-plant-level7';
import { Entypo } from '@expo/vector-icons';
import { IconHappy } from '../../../icon/icon-happy';
import { IconAngry } from '../../../icon/icon-angry';
import { IconExcited } from '../../../icon/icon-excited';
import { IconSad } from '../../../icon/icon-sad';
import { IconScared } from '../../../icon/icon-scared';
import { IconAnxious } from '../../../icon/icon-anxious';

enum SwitchLessonPlansEmotionGroup {
    GettingStarted = "Getting started"
}

enum SwitchLessonPlansGroups {
    Level1 = "Foundation",
    Level3 = "Proficient",
    Level5 = "Advanced",
    Level7 = "Mastery"
}

type LessonPlansItem = {
    cardId: string
    title: string
    emotion: Emotion | SwitchLessonPlansEmotionGroup
    description?: string
}

const emotionIcon = {
    [Emotion.ANGRY]: () => <IconAngry withoutLabel={true} />,
    [Emotion.ANXIOUS]: () => <IconAnxious withoutLabel={true} />,
    [Emotion.EXCITED]: () => <IconExcited withoutLabel={true} />,
    [Emotion.HAPPY]: () => <IconHappy withoutLabel={true} />,
    [Emotion.SAD]: () => <IconSad withoutLabel={true} />,
    [Emotion.SCARED]: () => <IconScared withoutLabel={true} />,
}

const ALL_LESSON_PLANS: LessonPlansItem[] = [
    {
        cardId: SwitchesEmotionsAngryDrawBreath.cardId,
        title: SwitchesEmotionsAngryDrawBreath.title,
        emotion: Emotion.ANGRY,
        description: "Focusing, visualising and recreating your breath"
    },
    {
        cardId: SwitchesEmotionsAngryPalmPush.cardId,
        title: SwitchesEmotionsAngryPalmPush.title,
        emotion: Emotion.ANGRY,
        description: "Redirecting and releasing tension and frustration"
    },
    {
        cardId: SwitchesEmotionsAngryRespond.cardId,
        title: SwitchesEmotionsAngryRespond.title,
        emotion: Emotion.ANGRY,
        description: "Ask yourself, “Am I reacting or responding?”"
    },
    {
        cardId: SwitchesEmotionsAngrySafeRant.cardId,
        title: SwitchesEmotionsAngrySafeRant.title,
        emotion: Emotion.ANGRY,
        description: "Secret space to express true feelings"
    },
    {
        cardId: SwitchesEmotionsAngrySignificance.cardId,
        title: SwitchesEmotionsAngrySignificance.title,
        emotion: Emotion.ANGRY,
        description: "Ask yourself, “Will this matter in the future?”"
    },
    {
        cardId: SwitchesEmotionsAngryStopSteps.cardId,
        title: SwitchesEmotionsAngryStopSteps.title,
        emotion: Emotion.ANGRY,
        description: "Stop. Take a breath. Observe. Proceed."
    },
    {
        cardId: SwitchesEmotionsAngryTime.cardId,
        title: SwitchesEmotionsAngryTime.title,
        emotion: Emotion.ANGRY,
        description: "Time-out based on red, yellow or green zones"
    },
    {
        cardId: SwitchesEmotionsAnxiousBoxBreathing.cardId,
        title: SwitchesEmotionsAnxiousBoxBreathing.title,
        emotion: Emotion.ANXIOUS,
        description: "Simple breathing activity"
    },
    {
        cardId: SwitchesEmotionsAnxiousBucketOfSafeThoughts.cardId,
        title: SwitchesEmotionsAnxiousBucketOfSafeThoughts.title,
        emotion: Emotion.ANXIOUS,
        description: "Safe thoughts and dreams to stop rumination"
    },
    {
        cardId: SwitchesEmotionsAnxiousDearDiaryAnxious.cardId,
        title: SwitchesEmotionsAnxiousDearDiaryAnxious.title,
        emotion: Emotion.ANXIOUS,
        description: "Guided diary entry practice"
    },
    {
        cardId: SwitchesEmotionsAnxiousPinwheel.cardId,
        title: SwitchesEmotionsAnxiousPinwheel.title,
        emotion: Emotion.ANXIOUS,
    },
    {
        cardId: SwitchesEmotionsAnxiousReframe.cardId,
        title: SwitchesEmotionsAnxiousReframe.title,
        emotion: Emotion.ANXIOUS,
        description: "Exploring alternate possibilities and narratives"
    },
    {
        cardId: SwitchesEmotionsAnxiousSleepChalk.cardId,
        title: SwitchesEmotionsAnxiousSleepChalk.title,
        emotion: Emotion.ANXIOUS,
    },
    {
        cardId: SwitchesEmotionsAnxiousTenseAndRelax.cardId,
        title: SwitchesEmotionsAnxiousTenseAndRelax.title,
        emotion: Emotion.ANXIOUS,
        description: "Simple mindful relaxation activity"
    },
    {
        cardId: SwitchesEmotionsAnxiousTheInteruptor.cardId,
        title: SwitchesEmotionsAnxiousTheInteruptor.title,
        emotion: Emotion.ANXIOUS,
        description: "Exploring alternate possibilities and narratives"
    },
    {
        cardId: SwitchesEmotionsExcitedBuild.cardId,
        title: SwitchesEmotionsExcitedBuild.title,
        emotion: Emotion.EXCITED,
    },
    {
        cardId: SwitchesEmotionsExcitedColorHunt.cardId,
        title: SwitchesEmotionsExcitedColorHunt.title,
        emotion: Emotion.EXCITED,
        description: "Identify specific coloured items within a time limit"
    },
    {
        cardId: SwitchesEmotionsExcitedExerciseOrDance.cardId,
        title: SwitchesEmotionsExcitedExerciseOrDance.title,
        emotion: Emotion.EXCITED,
        description: "Find a sport or activity you like and can do with a friend"
    },
    {
        cardId: SwitchesEmotionsExcitedListenOut.cardId,
        title: SwitchesEmotionsExcitedListenOut.title,
        emotion: Emotion.EXCITED,
        description: "Mindfully listen to sounds until they disappear"
    },
    {
        cardId: SwitchesEmotionsExcitedRead.cardId,
        title: SwitchesEmotionsExcitedRead.title,
        emotion: Emotion.EXCITED,
        description: "Find a good book and sink into it!"
    },
    {
        cardId: SwitchesEmotionsExcitedRewardDream.cardId,
        title: SwitchesEmotionsExcitedRewardDream.title,
        emotion: Emotion.EXCITED,
        description: "Think about future rewards for motivation"
    },
    {
        cardId: SwitchesEmotionsExcitedSketchIt.cardId,
        title: SwitchesEmotionsExcitedSketchIt.title,
        emotion: Emotion.EXCITED,
        description: "Draw something you can see within a time limit"
    },
    {
        cardId: SwitchesEmotionsExcitedTraceBreathing.cardId,
        title: SwitchesEmotionsExcitedTraceBreathing.title,
        emotion: Emotion.EXCITED,
        description: "Breathe while tracing pictures with your finger "
    },
    {
        cardId: SwitchesEmotionsHappyRandomActOfKindness.cardId,
        title: SwitchesEmotionsHappyRandomActOfKindness.title,
        emotion: Emotion.HAPPY,
        description: "Practicing random acts of kindness toward others"
    },
    {
        cardId: SwitchesEmotionsHappyGratefulness.cardId,
        title: SwitchesEmotionsHappyGratefulness.title,
        emotion: Emotion.HAPPY,
        description: "Name a person, place, food and thing you are thankful for"
    },
    {
        cardId: SwitchesEmotionsHappyJournaling.cardId,
        title: SwitchesEmotionsHappyJournaling.title,
        emotion: Emotion.HAPPY,
        description: "Create a habit of Journaling"
    },
    {
        cardId: SwitchesEmotionsHappyLimerick.cardId,
        title: SwitchesEmotionsHappyLimerick.title,
        emotion: Emotion.HAPPY,
    },
    {
        cardId: SwitchesEmotionsHappyMakeANewFriend.cardId,
        title: SwitchesEmotionsHappyMakeANewFriend.title,
        emotion: Emotion.HAPPY,
        description: "Steps to practice making new friends"
    },
    {
        cardId: SwitchesEmotionsHappyNewConnection.cardId,
        title: SwitchesEmotionsHappyNewConnection.title,
        emotion: Emotion.HAPPY,
        description: "Steps to make new friends"
    },
    {
        cardId: SwitchesEmotionsHappySongLines.cardId,
        title: SwitchesEmotionsHappySongLines.title,
        emotion: Emotion.HAPPY,
    },
    {
        cardId: SwitchesEmotionsHappyStarsAndWishes.cardId,
        title: SwitchesEmotionsHappyStarsAndWishes.title,
        emotion: Emotion.HAPPY,
        description: "Simple goal setting and poster creation"
    },
    {
        cardId: SwitchesEmotionsHappyStorySentence.cardId,
        title: SwitchesEmotionsHappyStorySentence.title,
        emotion: Emotion.HAPPY,
        description: "Taking turns to add a sentence and build a group story"
    },
    {
        cardId: SwitchesEmotionsHappyValues.cardId,
        title: SwitchesEmotionsHappyValues.title,
        emotion: Emotion.HAPPY,
        description: "Find out what your driving values are"
    },
    {
        cardId: SwitchesEmotionsSadBeSocial.cardId,
        title: SwitchesEmotionsSadBeSocial.title,
        emotion: Emotion.SAD,
        description: "It’s good for you to be around others"
    },
    {
        cardId: SwitchesEmotionsSadFutureLetter.cardId,
        title: SwitchesEmotionsSadFutureLetter.title,
        emotion: Emotion.SAD,
        description: "A letter to your future self"
    },
    {
        cardId: SwitchesEmotionsSadLikePage.cardId,
        title: SwitchesEmotionsSadLikePage.title,
        emotion: Emotion.SAD,
        description: "Listing your likable characteristics"
    },
    {
        cardId: SwitchesEmotionsSadMuisc.cardId,
        title: SwitchesEmotionsSadMuisc.title,
        emotion: Emotion.SAD,
        description: "Linking music memories to trigger emotions"
    },
    {
        cardId: SwitchesEmotionsSadSmallSteps.cardId,
        title: SwitchesEmotionsSadSmallSteps.title,
        emotion: Emotion.SAD,
        description: "Breaking tasks into smaller chunks to overcome procrastination"
    },
    {
        cardId: SwitchesEmotionsSadStretching.cardId,
        title: SwitchesEmotionsSadStretching.title,
        emotion: Emotion.SAD,
        description: "Activating your body to help motivation"
    },
    {
        cardId: SwitchesEmotionsSadWalk.cardId,
        title: SwitchesEmotionsSadWalk.title,
        emotion: Emotion.SAD,
    },
    {
        cardId: SwitchesEmotionsScaredFunnyVoice.cardId,
        title: SwitchesEmotionsScaredFunnyVoice.title,
        emotion: Emotion.SCARED,
        description: "Clear your head of anxious thoughts"
    },
    {
        cardId: SwitchesEmotionsScaredCountDown.cardId,
        title: SwitchesEmotionsScaredCountDown.title,
        emotion: Emotion.SCARED,
        description: "Count-down and go!"
    },
    {
        cardId: SwitchesEmotionsScaredFaceIt.cardId,
        title: SwitchesEmotionsScaredFaceIt.title,
        emotion: Emotion.SCARED,
        description: "Visualising what being braver looks and feels like"
    },
    {
        cardId: SwitchesEmotionsScaredIdentify5.cardId,
        title: SwitchesEmotionsScaredIdentify5.title,
        emotion: Emotion.SCARED,
        description: "Identify things you can see, hear, feel, smell and taste"
    },
    {
        cardId: SwitchesEmotionsScaredRoleModel.cardId,
        title: SwitchesEmotionsScaredRoleModel.title,
        emotion: Emotion.SCARED,
        description: "What would ________ do?"
    },
    {
        cardId: SwitchesEmotionsScaredTalkAboutIt.cardId,
        title: SwitchesEmotionsScaredTalkAboutIt.title,
        emotion: Emotion.SCARED,
        description: "Encouraging sharing and mindful reflection"
    },
    {
        cardId: SwitchesEmotionsScaredVisualise.cardId,
        title: SwitchesEmotionsScaredVisualise.title,
        emotion: Emotion.SCARED,
        description: "Visualising challenges, and overcome them in your imagination"
    },
    {
        cardId: SwitchesEmotionsAngryScrunchie.cardId,
        title: SwitchesEmotionsAngryScrunchie.title,
        emotion: Emotion.ANGRY,
        description: "Tense and relax your muscles"
    },
    {
        cardId: SwitchesEmotionsAngryTenBreaths.cardId,
        title: SwitchesEmotionsAngryTenBreaths.title,
        emotion: Emotion.ANGRY,
        description: "10 breathes to lower anger"
    },
    {
        cardId: SwitchesEmotionsSadStretch.cardId,
        title: SwitchesEmotionsSadStretch.title,
        emotion: Emotion.SAD,
        description: "A simple stretching activity"
    },
    {
        cardId: SwitchesEmotionsSadHug.cardId,
        title: SwitchesEmotionsSadHug.title,
        emotion: Emotion.SAD,
        description: "Find comfort in something you love"
    },
    {
        cardId: SwitchesEmotionsScaredCartoonIt.cardId,
        title: SwitchesEmotionsScaredCartoonIt.title,
        emotion: Emotion.SCARED,
        description: "Draw what scares you. Make it silly"
    },
    {
        cardId: SwitchesEmotionsScaredMySafePlace.cardId,
        title: SwitchesEmotionsScaredMySafePlace.title,
        emotion: Emotion.SCARED,
        description: "Find your safe space with your imagination"
    },
    {
        cardId: SwitchesEmotionsExcitedCalmingMazeGames.cardId,
        title: SwitchesEmotionsExcitedCalmingMazeGames.title,
        emotion: Emotion.EXCITED,
        description: "Help the hen find her chick!"
    },
    {
        cardId: SwitchesEmotionsExcitedTreasureHunt.cardId,
        title: SwitchesEmotionsExcitedTreasureHunt.title,
        emotion: Emotion.EXCITED,
        description: "Follow a path to find the treasure"
    },
    {
        cardId: SwitchesEmotionsHappyWhyYouLike.cardId,
        title: SwitchesEmotionsHappyWhyYouLike.title,
        emotion: Emotion.HAPPY,
        description: "Share your mood with people who care for you"
    },
    {
        cardId: SwitchesEmotionsHappyLaugh.cardId,
        title: SwitchesEmotionsHappyLaugh.title,
        emotion: Emotion.HAPPY,
        description: "Improve your mood with a laugh"
    },
    {
        cardId: SwitchesEmotionsHappyDrawHappiness.cardId,
        title: SwitchesEmotionsHappyDrawHappiness.title,
        emotion: Emotion.HAPPY,
        description: "Create pictures of your mood"
    },
    {
        cardId: SwitchesEmotionsHappyBeCreative.cardId,
        title: SwitchesEmotionsHappyBeCreative.title,
        emotion: Emotion.HAPPY,
        description: "Experiment and quick sketch new ideas and creations"
    },
    {
        cardId: SwitchesEmotionsAnxiousBreathFloat.cardId,
        title: SwitchesEmotionsAnxiousBreathFloat.title,
        emotion: Emotion.ANXIOUS,
        description: "Breathing with imagination"
    },
    {
        cardId: SwitchesEmotionsAnxiousTheCalmerFarmer.cardId,
        title: SwitchesEmotionsAnxiousTheCalmerFarmer.title,
        emotion: Emotion.ANXIOUS,
        description: "The story of the calmer farmer"
    },
    {
        cardId: SwitchesEmotionsAnxiousWorryMonster.cardId,
        title: SwitchesEmotionsAnxiousWorryMonster.title,
        emotion: Emotion.ANXIOUS,
        description: "Draw and slay your worry monster "
    },
    {
        cardId: SwitchesEmotionsAnxiousBubbleOfControl.cardId,
        title: SwitchesEmotionsAnxiousBubbleOfControl.title,
        emotion: Emotion.ANXIOUS,
        description: "Focus on controlling the "
    },
    {
        cardId: SwitchesEmotionsAnxiousStoryboarding.cardId,
        title: SwitchesEmotionsAnxiousStoryboarding.title,
        emotion: Emotion.ANXIOUS,
        description: "Create a story for a scenario you’re worried about"
    },
    {
        cardId: SwitchesEmotionsAngryCalmingBreath.cardId,
        title: SwitchesEmotionsAngryCalmingBreath.title,
        emotion: Emotion.ANGRY,
        description: "Release anger with a deep breath"
    },
    {
        cardId: SwitchesEmotionsAngryStoryTime.cardId,
        title: SwitchesEmotionsAngryStoryTime.title,
        emotion: Emotion.ANGRY,
        description: "Create clarity and calm with a story"
    },
    {
        cardId: SwitchesEmotionsSadSmile.cardId,
        title: SwitchesEmotionsSadSmile.title,
        emotion: Emotion.SAD,
        description: "Changing your perspective to feel better"
    },
    {
        cardId: SwitchesEmotionsSadAnimalMashup.cardId,
        title: SwitchesEmotionsSadAnimalMashup.title,
        emotion: Emotion.SAD,
        description: "Refocus your energy with fun creativity"
    },
    {
        cardId: SwitchesEmotionsScaredBreathCycle.cardId,
        title: SwitchesEmotionsScaredBreathCycle.title,
        emotion: Emotion.SCARED,
        description: "Simple nose breathing exercise to calm down"
    },
    {
        cardId: SwitchesEmotionsScaredTheAnticipator.cardId,
        title: SwitchesEmotionsScaredTheAnticipator.title,
        emotion: Emotion.SCARED,
        description: "Re-framing nerves as excited anticipation"
    },
    {
        cardId: SwitchesEmotionsExcitedWeatherIt.cardId,
        title: SwitchesEmotionsExcitedWeatherIt.title,
        emotion: Emotion.EXCITED,
        description: "Thinking of strong emotions like a storm and its impact"
    },
    {
        cardId: SwitchesEmotionsExcitedThreeSketches.cardId,
        title: SwitchesEmotionsExcitedThreeSketches.title,
        emotion: Emotion.EXCITED,
        description: "Turn simple shapes into creative beings"
    },
    {
        cardId: SwitchesEmotionsExcitedTheTwister.cardId,
        title: SwitchesEmotionsExcitedTheTwister.title,
        emotion: Emotion.EXCITED,
        description: "Fun left/right finger movement distractor"
    },
    {
        cardId: SwitchesEmotionsHappySmartGoals.cardId,
        title: SwitchesEmotionsHappySmartGoals.title,
        emotion: Emotion.HAPPY,
        description: "Creating goals you’ll be motivated to achieve"
    },
    {
        cardId: SwitchesEmotionsAnxiousCircleOfInfluence.cardId,
        title: SwitchesEmotionsAnxiousCircleOfInfluence.title,
        emotion: Emotion.ANXIOUS,
        description: "List your worries to see what you can influence"
    },
    {
        cardId: SwitchesEmotionsAngryMotivationMap.cardId,
        title: SwitchesEmotionsAngryMotivationMap.title,
        emotion: Emotion.ANGRY,
        description: "Shedule your motivation"
    },
    {
        cardId: SwitchesEmotionsAngryTheDoOver.cardId,
        title: SwitchesEmotionsAngryTheDoOver.title,
        emotion: Emotion.ANGRY,
        description: "re-do difficult conversations"
    },
    {
        cardId: SwitchesEmotionsAngryFiveWhys.cardId,
        title: SwitchesEmotionsAngryFiveWhys.title,
        emotion: Emotion.ANGRY,
        description: "Understand emotions and the cause"
    },
    {
        cardId: SwitchesEmotionsSadGrowthMindset.cardId,
        title: SwitchesEmotionsSadGrowthMindset.title,
        emotion: Emotion.SAD,
        description: "Embracing success and failure"
    },
    {
        cardId: SwitchesEmotionsSadReachOut.cardId,
        title: SwitchesEmotionsSadReachOut.title,
        emotion: Emotion.SAD,
        description: "Building confidence connecting with someone"
    },
    {
        cardId: SwitchesEmotionsScaredMantra.cardId,
        title: SwitchesEmotionsScaredMantra.title,
        emotion: Emotion.SCARED,
        description: "Creating motivational statements"
    },
    {
        cardId: SwitchesEmotionsScaredSeeIt.cardId,
        title: SwitchesEmotionsScaredSeeIt.title,
        emotion: Emotion.SCARED,
        description: "Distract the mind by picturing in great detail"
    },
    {
        cardId: SwitchesEmotionsScaredBalonBreathing.cardId,
        title: SwitchesEmotionsScaredBalonBreathing.title,
        emotion: Emotion.SCARED,
        description: "Breath in like a big balloon"
    },
    {
        cardId: SwitchesEmotionsAngryCalmDownCorner.cardId,
        title: SwitchesEmotionsAngryCalmDownCorner.title,
        emotion: Emotion.ANGRY,
        description: "A familiar place to wind down"
    },
    {
        cardId: SwitchesEmotionsAnxiousDrinkOfWater.cardId,
        title: SwitchesEmotionsAnxiousDrinkOfWater.title,
        emotion: Emotion.ANXIOUS,
        description: "Hydrate to fuel your brain"
    },
    {
        cardId: SwitchesEmotionsExcitedWallsPushUps.cardId,
        title: SwitchesEmotionsExcitedWallsPushUps.title,
        emotion: Emotion.EXCITED,
        description: "Light push up exercise"
    },
    {
        cardId: SwitchesEmotionsAngryTakeABreak.cardId,
        title: SwitchesEmotionsAngryTakeABreak.title,
        emotion: Emotion.ANGRY,
        description: "Asking to take a break"
    },
    {
        cardId: SwitchesEmotionsSadSereneGarden.cardId,
        title: SwitchesEmotionsSadSereneGarden.title,
        emotion: Emotion.SAD,
        description: "Experience the floral surrounds "
    },
    {
        cardId: SwitchesEmotionsHappyFriendFinder.cardId,
        title: SwitchesEmotionsHappyFriendFinder.title,
        emotion: Emotion.HAPPY,
        description: "Connect with new people"
    },
    {
        cardId: SwitchesEmotionsScaredExposeIt.cardId,
        title: SwitchesEmotionsScaredExposeIt.title,
        emotion: Emotion.SCARED,
        description: "Gradually expose yourself to overcome your fears"
    },
    {
        cardId: SwitchesEmotionsScaredHelpOrHurt.cardId,
        title: SwitchesEmotionsScaredHelpOrHurt.title,
        emotion: Emotion.SCARED,
        description: "Explore whether your feelings help or hurt you"
    },
    {
        cardId: SwitchesEmotionsAngryBodyScan.cardId,
        title: SwitchesEmotionsAngryBodyScan.title,
        emotion: Emotion.ANGRY,
        description: "Scan and relieve your body of all its tension"
    },
    {
        cardId: SwitchesEmotionsAngryFindYourCalm.cardId,
        title: SwitchesEmotionsAngryFindYourCalm.title,
        emotion: Emotion.ANGRY,
        description: "Create your own bank of calming strategies"
    },
    {
        cardId: SwitchesEmotionsAngryHeadRolls.cardId,
        title: SwitchesEmotionsAngryHeadRolls.title,
        emotion: Emotion.ANGRY,
        description: "Take some breaths and stretch your neck"
    },
    {
        cardId: SwitchesEmotionsAnxiousRealityCheck.cardId,
        title: SwitchesEmotionsAnxiousRealityCheck.title,
        emotion: Emotion.ANXIOUS,
        description: "Reorient yourself to reality"
    },
    {
        cardId: SwitchesEmotionsAnxiousHotCold.cardId,
        title: SwitchesEmotionsAnxiousHotCold.title,
        emotion: Emotion.ANXIOUS,
        description: "Change the temperature of your hands"
    },
    {
        cardId: SwitchesEmotionsSadTheOrganizer.cardId,
        title: SwitchesEmotionsSadTheOrganizer.title,
        emotion: Emotion.SAD,
        description: "Organise your week and clear your mind"
    },
    {
        cardId: SwitchesEmotionsSadBucketList.cardId,
        title: SwitchesEmotionsSadBucketList.title,
        emotion: Emotion.SAD,
        description: "Brainstorm and share your bucket list"
    },
    {
        cardId: SwitchesEmotionsSadTheScribe.cardId,
        title: SwitchesEmotionsSadTheScribe.title,
        emotion: Emotion.SAD,
        description: "Keep a daily record of what was good and bad"
    },
    {
        cardId: SwitchesEmotionsExcitedTheRiddler.cardId,
        title: SwitchesEmotionsExcitedTheRiddler.title,
        emotion: Emotion.EXCITED,
        description: "Focus your mind and solve some riddles"
    },
    {
        cardId: SwitchesEmotionsExcitedFingerTipRub.cardId,
        title: SwitchesEmotionsExcitedFingerTipRub.title,
        emotion: Emotion.EXCITED,
        description: "Slowly rub your fingertips together"
    },
    {
        cardId: SwitchesEmotionsHappyInspirationStation.cardId,
        title: SwitchesEmotionsHappyInspirationStation.title,
        emotion: Emotion.HAPPY,
        description: "Be inspired by inspirational quotes"
    },
    {
        cardId: SwitchesEmotionsHappyTheInquisitor.cardId,
        title: SwitchesEmotionsHappyTheInquisitor.title,
        emotion: Emotion.HAPPY,
        description: "Ask your friends curious questions"
    },
    {
        cardId: SwitchesEmotionsHappyTheHappyMonk.cardId,
        title: SwitchesEmotionsHappyTheHappyMonk.title,
        emotion: Emotion.HAPPY,
        description: "Let the smile meditation fill your body"
    },
]

const SWITCHES_LESSON_PLANS = [
    {
        cardId: SwitchesLessonPlansGettingStarted.cardId,
        title: SwitchesLessonPlansGettingStarted.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level5,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
    },
    {
        cardId: SwitchesLessonPlansSelfAwarenessAndEmotionalAwareness.cardId,
        title: SwitchesLessonPlansSelfAwarenessAndEmotionalAwareness.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level5,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "A1",
        description: "Recognise and label a wider range of emotions, understand their impact on personal wellbeing, and develop skills for deepening emotional awareness.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansUnderstandingAndManagingHapiness.cardId,
        title: SwitchesLessonPlansUnderstandingAndManagingHapiness.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level5,
        icon: EmotionHappyIcon,
        code: "A2",
        description: "Understand the fundamentals of peace and happiness to enhance self-awareness.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansUnderstandingAndManagingAnxiety.cardId,
        title: SwitchesLessonPlansUnderstandingAndManagingAnxiety.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level5,
        icon: EmotionAnxiousIcon,
        code: "A3",
        description: "Recognise, understand, manage, and use anxiety constructively to enhance self-awareness.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansUnderstandingAndManagingAnger.cardId,
        title: SwitchesLessonPlansUnderstandingAndManagingAnger.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level5,
        icon: EmotionAngryIcon,
        code: "A4",
        description: "Recognise, understand, manage, and use anger constructively to enhance self-awareness.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansUnderstandingAndManagingSadness.cardId,
        title: SwitchesLessonPlansUnderstandingAndManagingSadness.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level5,
        icon: EmotionSadIcon,
        code: "A5",
        description: "Recognise, understand, and manage sadness constructively to enhance self-awareness.",
        duration: "45mins"

    },
    {
        cardId: SwitchesLessonPlansUnderstandingAndManagingScared.cardId,
        title: SwitchesLessonPlansUnderstandingAndManagingScared.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level5,
        icon: EmotionScaredIcon,
        code: "A6",
        description: "Recognise, understand, manage, and constructively use feeling scared to enhance self awareness.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansUnderstandingAndManagingExcitement.cardId,
        title: SwitchesLessonPlansUnderstandingAndManagingExcitement.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level5,
        icon: EmotionExcitedIcon,
        code: "A7",
        description: "Recognise, understand, manage, and use excitement constructively to enhance self awareness.",
        duration: "45mins"

    },
    {
        cardId: SwitchesLessonPlansSocialIntelligence.cardId,
        title: SwitchesLessonPlansSocialIntelligence.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level5,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "A8",
        description: "Define social intelligence, develop skills in observing and assisting others in coping with emotions, and apply these skills in real life scenarios.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansEmotionalIntelligenceInRelationships.cardId,
        title: SwitchesLessonPlansEmotionalIntelligenceInRelationships.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level5,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "A9",
        description: "Understand the impact of emotions on relationships, and develop skills for building and maintaining positive connections.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansAdvancedEmpathy.cardId,
        title: SwitchesLessonPlansAdvancedEmpathy.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level5,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "A10",
        description: "Recognising, practicing and cultivating empathy and compassion. Apply cognitive empathy.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansEmotionalManagement.cardId,
        title: SwitchesLessonPlansEmotionalManagement.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level5,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "A11",
        description: "Enhance emotional management skills and make proactive choices to better regulate emotions.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansAdvancedConflictManagement.cardId,
        title: SwitchesLessonPlansAdvancedConflictManagement.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level5,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "A12",
        description: "Recognise types of conflict in everyday life, the role emotions play in escalating conflict, and how to use emotion to drive better outcomes.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansEmotionalIntelligenceInLeadership.cardId,
        title: SwitchesLessonPlansEmotionalIntelligenceInLeadership.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level5,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "A13",
        description: "Understanding and managing emotions within a group or team setting, fostering collaboration and motivation.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansMyEmotionsLessonPlans.cardId,
        title: SwitchesLessonPlansMyEmotionsLessonPlans.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level3,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "P1",
        description: "Identify and name different emotions, understand that everyone experiences emotions, and recognise that it is okay to have a range of emotions.",
        duration: "30-45mins"
    },
    {
        cardId: SwitchesLessonPlansIntroductionToHappy.cardId,
        title: SwitchesLessonPlansIntroductionToHappy.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level3,
        icon: EmotionHappyIcon,
        code: "P2",
        description: "Define happiness and understand its importance, identify activities and behaviours that contribute to their own happiness.",
        duration: "45-60mins"
    },
    {
        cardId: SwitchesLessonPlansIntroductionToAnxious.cardId,
        title: SwitchesLessonPlansIntroductionToAnxious.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level3,
        icon: EmotionAnxiousIcon,
        code: "P3",
        description: "Define anxiety and understand its role in the body, identify strategies for managing anxiety in a healthy way",
        duration: "45-60mins"
    },
    {
        cardId: SwitchesLessonPlansIntroductionToAngry.cardId,
        title: SwitchesLessonPlansIntroductionToAngry.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level3,
        icon: EmotionAngryIcon,
        code: "P4",
        description: "Define anger and understand its role in the body, identify strategies for managing anger in a healthy way",
        duration: "45-60mins"
    },
    {
        cardId: SwitchesLessonPlansIntroductionToSad.cardId,
        title: SwitchesLessonPlansIntroductionToSad.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level3,
        icon: EmotionSadIcon,
        code: "P5",
        description: "Define sadness and understand its role in the body, identify strategies for managing sadness in a healthy way.",
        duration: "45-60mins"
    },
    {
        cardId: SwitchesLessonPlansIntroductionToScared.cardId,
        title: SwitchesLessonPlansIntroductionToScared.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level3,
        icon: EmotionScaredIcon,
        code: "P6",
        description: "Define fear, differentiate anxiety and feeling scared, identify the physical and emotional signs of scared, and learn strategies for managing and coping.",
        duration: "45-60mins"
    },
    {
        cardId: SwitchesLessonPlansIntroductionToExcited.cardId,
        title: SwitchesLessonPlansIntroductionToExcited.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level3,
        icon: EmotionExcitedIcon,
        code: "P7",
        description: "Define excitement and identify the physical and emotional signs of excitement, as well as learn ways to express excitement in appropriate ways.",
        duration: "45-60mins"
    },
    {
        cardId: SwitchesLessonPlansEmotionalRegulation.cardId,
        title: SwitchesLessonPlansEmotionalRegulation.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level3,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "P8",
        description: "Develop strategies to manage and regulate emotions more effectively.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansPerspectiveTaking.cardId,
        title: SwitchesLessonPlansPerspectiveTaking.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level3,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "P9",
        description: "Practice perspective taking and consider how others may feel in specific situations.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansEmotionalExpression.cardId,
        title: SwitchesLessonPlansEmotionalExpression.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level3,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "P10",
        description: "Explore strategies to express emotions assertively and constructively.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansEmotionalResilience.cardId,
        title: SwitchesLessonPlansEmotionalResilience.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level3,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "P11",
        description: "Define emotional resilience, develop strategies to cope with challenging situations, and demonstrate resilience through various activities.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansEmotionsVersusFeelings.cardId,
        title: SwitchesLessonPlansEmotionsVersusFeelings.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level3,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "P12",
        description: "Differentiate between emotions and feelings, understand the importance of emotional awareness, and express emotion healthily and constructively.",
        duration: "35mins"
    },
    {
        cardId: SwitchesLessonPlansConflictResolution.cardId,
        title: SwitchesLessonPlansConflictResolution.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level3,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "P13",
        description: "Resolve conflict constructively by managing emotion, active listening, finding win-win solutions, and enhancing emotional expression skills.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansMyEmotionsLessonPlansLevel1.cardId,
        title: SwitchesLessonPlansMyEmotionsLessonPlansLevel1.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level1,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "F1",
        description: "Identify and express a range of emotions and understand that emotions are normal and it’s important to express and manage appropriately",
        duration: "30-45mins"
    },
    {
        cardId: SwitchesLessonPlansIntroducingHappy.cardId,
        title: SwitchesLessonPlansIntroducingHappy.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level1,
        icon: EmotionHappyIcon,
        code: "F2",
        description: "Deepen understanding of “happy”, identify its characteristics, express what it looks and feels like, and explore activities that bring joy.",
        duration: "30-45mins",
    },
    {
        cardId: SwitchesLessonPlansIntroducingAnxiety.cardId,
        title: SwitchesLessonPlansIntroducingAnxiety.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level1,
        icon: EmotionAnxiousIcon,
        code: "F3",
        description: "Deepen understanding of “anxiety”, identify its characteristics, expressing what it looks and feels like, and explore strategies to manage it.",
        duration: "45-60mins"
    },
    {
        cardId: SwitchesLessonPlansIntroducingAngry.cardId,
        title: SwitchesLessonPlansIntroducingAngry.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level1,
        icon: EmotionAngryIcon,
        code: "F4",
        description: "Deepen understanding of “angry”, identify its characteristics, express what it looks and feels like, and explore strategies for healthy management.",
        duration: "45-60mins"
    },
    {
        cardId: SwitchesLessonPlansIntroducingSad.cardId,
        title: SwitchesLessonPlansIntroducingSad.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level1,
        icon: EmotionSadIcon,
        code: "F5",
        description: "Deepen understanding of “sad”, identify its characteristics, express what it looks and feels like, and explore ways cope and support others. ",
        duration: "45-60mins"
    },
    {
        cardId: SwitchesLessonPlansIntroducingScared.cardId,
        title: SwitchesLessonPlansIntroducingScared.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level1,
        icon: EmotionScaredIcon,
        code: "F6",
        description: "Deepen understanding of “scared”, identify its characteristics, express what it looks and feels like, and explore strategies to overcoming fear.",
        duration: "35-45mins"
    },
    {
        cardId: SwitchesLessonPlansIntroducingExcited.cardId,
        title: SwitchesLessonPlansIntroducingExcited.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level1,
        icon: EmotionExcitedIcon,
        code: "F7",
        description: "Deepen understanding emotion “excited”, identify characteristics, express what it looks and feels like, and explore ways to channel and manage energy.",
        duration: "45-60mins"
    },
    {
        cardId: SwitchesLessonPlansUnderstandingEmotions.cardId,
        title: SwitchesLessonPlansUnderstandingEmotions.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level1,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "F8",
        description: "Identify and label basic emotions in themselves and others, and express their emotions effectively. ",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansEmphaty.cardId,
        title: SwitchesLessonPlansEmphaty.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level1,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "F9",
        description: "Define the concept of empathy, develop the ability to identify and understand others’ feelings, and demonstrate empathy through various activities",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansFeelingsVolcano.cardId,
        title: SwitchesLessonPlansFeelingsVolcano.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level1,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "F10",
        description: "Understand the concept of overreacting and the importance of managing their emotions appropriately.",
        duration: "30mins"
    },
    {
        cardId: SwitchesLessonPlansM1ExploringAndEmbracingEmotion.cardId,
        title: SwitchesLessonPlansM1ExploringAndEmbracingEmotion.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level7,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "M1",
        description: "Increase self awareness, understand the concept of primary and secondary emotions and consider how energy and emotion are correlated through data.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansM2EmotionalIntellegenceInDecissionMaking.cardId,
        title: SwitchesLessonPlansM2EmotionalIntellegenceInDecissionMaking.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level7,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "M2",
        description: "Utilise emotional intelligence in decision making by considering both emotional and logical factors.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansM3ThePersuitOfHappiness.cardId,
        title: SwitchesLessonPlansM3ThePersuitOfHappiness.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level7,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "M3",
        description: "Define happiness and connection between wellbeing. Explore contribute factors, and explore how to cultivate happiness and foster positive relationship",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansM4UnderstandingAnxiety.cardId,
        title: SwitchesLessonPlansM4UnderstandingAnxiety.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level7,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "M4",
        description: "Understand impact on emotional wellbeing, identify trigers and coping strategies, and manage anxiety by fostering empathy and self-regulation",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansM5InvestigatingAnger.cardId,
        title: SwitchesLessonPlansM5InvestigatingAnger.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level7,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "M5",
        description: "Define happiness and connection between wellbeing. Explore contribute factors, and explore how to cultivate happiness and foster positive relationship",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansM6NavigatingSadness.cardId,
        title: SwitchesLessonPlansM6NavigatingSadness.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level7,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "M6",
        description: "Define sadness and impact on wellbeing, explore causes and manifestations of sadness, and foster self-awareness, empathy, and coping mechanisms.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansM7ConqueringFear.cardId,
        title: SwitchesLessonPlansM7ConqueringFear.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level7,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "M7",
        description: "Define being scared and impact on wellbeing, explore causes and manifestations, and foster self awareness, resilience, and coping mechanisms.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansM8HarnessingExcitement.cardId,
        title: SwitchesLessonPlansM8HarnessingExcitement.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level7,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "M8",
        description: "Enhance self awareness and emotional regulation skills, and deepen understanding of excitement, and how to better embrace and manage it.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansM9EmotionalAgility.cardId,
        title: SwitchesLessonPlansM9EmotionalAgility.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level7,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "M9",
        description: "define flexibillity and adaptabillity in emotional responses, and develop self reflection, positive coping strategies, and seeking support when needed.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansM10AdvancedResilience.cardId,
        title: SwitchesLessonPlansM10AdvancedResilience.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level7,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "M10",
        description: "Be equipped with advanced resilience skills to navigate challenges, setbacks, and transitions effectively",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansM11UnderstandingChangeAndAdaptability.cardId,
        title: SwitchesLessonPlansM11UnderstandingChangeAndAdaptability.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level7,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "M11",
        description: "Understand the stages of the Change Cycle, recognise responses to change, and develop strategies for navigating through each stage.",
        duration: "45mins"
    },
    {
        cardId: SwitchesLessonPlansM12SelfAwarenessAndWellbeing.cardId,
        title: SwitchesLessonPlansM12SelfAwarenessAndWellbeing.title,
        emotion: SwitchLessonPlansEmotionGroup.GettingStarted,
        groups: SwitchLessonPlansGroups.Level7,
        icon: () => <IconSwitch4SchoolsEW width={40} height={40} />,
        code: "M12",
        description: "Enhance self awareness and promote overall wellbeing through advanced self reflection and self care practices.",
        duration: "45mins"
    },
]

type SwitchLessonPlansLevelType = {
    label: SwitchLessonPlansGroups,
    wheelImage: () => JSX.Element
    plantImage: (props: { width?: DimensionValue, height?: DimensionValue }) => JSX.Element
    subLabel: string
    colorSchema: ColorValue
}

const emotions: string[] = [SwitchLessonPlansEmotionGroup.GettingStarted, Emotion.ANGRY, Emotion.ANXIOUS, Emotion.EXCITED, Emotion.HAPPY, Emotion.SAD, Emotion.SCARED]
const levelLabels: SwitchLessonPlansGroups[] = [SwitchLessonPlansGroups.Level1, SwitchLessonPlansGroups.Level3, SwitchLessonPlansGroups.Level5, SwitchLessonPlansGroups.Level7]
const switchLessonPlanHeadersData: SwitchLessonPlansLevelType[] = [
    {
        label: SwitchLessonPlansGroups.Level1,
        wheelImage: LessonPlanWheelLevel1,
        plantImage: () => <LessonPlanPlantLevel1 width={50} height={50} />,
        subLabel: "Maps to ACARA level 1a & 1b",
        colorSchema: DecidaColors.LessonPlanFoudation
    },
    {
        label: SwitchLessonPlansGroups.Level3,
        wheelImage: LessonPlanWheelLevel3,
        plantImage: () => <LessonPlanPlantlevel3 width={60} height={80} />,
        subLabel: "Maps to ACARA level 2 & 3",
        colorSchema: DecidaColors.LessonPlanProficient
    },
    {
        label: SwitchLessonPlansGroups.Level5,
        wheelImage: LessonPlanWheelLevel5,
        plantImage: () => <LessonPlanPlantlevel5 width={75} height={95} />,
        subLabel: "Maps to ACARA level 4 & 5",
        colorSchema: DecidaColors.LessonPlanAdvanced
    },
    {
        label: SwitchLessonPlansGroups.Level7,
        wheelImage: LessonPlanWheelLevel7,
        plantImage: () => <LessonPlanPlantlevel7 width={140} height={129} />,
        subLabel: "Maps to ACARA level 6",
        colorSchema: DecidaColors.LessonPlanMastery
    },
]

const SWITCH_LESSON_PLANS_WRAPPER_WIDTH = 350
const DESCRIPTION_TEXT_SIZE = 14
const TITLE_TEXT_SIZE = 20

export const TOOLS_LESSON_PLAN_CARD_ID = "2d55ba38-acc3-4509-b0a5-a2c65fbdaf28"

export const ToolsLessonPlans: CardTemplate = {
    cardId: TOOLS_LESSON_PLAN_CARD_ID,
    title: 'Lesson plans',
    Header: LessonPlansIcon,
    Icon: LessonPlansIcon,
    HeaderTitle: 'Lesson plans',
    PerfectFor: () => (
        <>

        </>
    ),
    noRating: true,
    mainContainerStyle: {
        maxWidth: '100%',
        paddingHorizontal: 10
    },
    BottomRow: () => {
        const { width, isLargeScreen, isNarrowScreen } = useResponsive()

        return (
            <>
                <View style={{ width: '100%', }}>

                    {emotions.map((emotion) => {
                        const { navigate } = useNavigation<StackNavigationProp<TeacherDrawerNavigatorPamarList>>()
                        const handleCardPress = (cardId: string) => () => {
                            navigate(ScreenNames.Card, { id: cardId, previousScreen: 'lessons', previousCardId: TOOLS_LESSON_PLAN_CARD_ID })
                        }

                        const switchLessonPlans = emotion === SwitchLessonPlansEmotionGroup.GettingStarted
                        const EmotionIcon = !switchLessonPlans ? emotionIcon[emotion as Emotion] : null

                        return (
                            <View key={emotion} style={[styles.emotionContainer, { backgroundColor: !switchLessonPlans ? DecidaColors.White : undefined }]}>
                                {!switchLessonPlans && (
                                    <View style={styles.emotionIconAndTitleContainer}>
                                        <DefaultText style={[styles.emotionLabelTitle, { color: CardColorScheme[emotion as Emotion] }]}>
                                            {_.upperFirst(emotion.toLowerCase())}
                                        </DefaultText>
                                        {EmotionIcon && (
                                            <View style={styles.emotionIcon}>
                                                <EmotionIcon />
                                            </View>
                                        )}
                                    </View>
                                )}

                                {switchLessonPlans && (
                                    <View style={[styles.lessonPlanContainer, { justifyContent: 'space-evenly' }]}>
                                        {switchLessonPlanHeadersData.map((header, index) => {
                                            const headerLabel = header.label
                                            const WheelImage = header.wheelImage
                                            const PlantImage = header.plantImage
                                            const lessonPlanColorScheme = header.colorSchema

                                            return (
                                                <View key={header.label} style={styles.levelsLabelView}>
                                                    <View style={[styles.headerContainer, { backgroundColor: lessonPlanColorScheme }]}>
                                                        <View style={styles.headerLabelContainer}>
                                                            <DefaultText style={styles.headerLabelText}>{headerLabel[0]}</DefaultText>
                                                            <View style={styles.wheelImage}>
                                                                <WheelImage />
                                                            </View>
                                                        </View>
                                                        <DefaultText style={styles.textLabel}>{headerLabel}</DefaultText>
                                                        <DefaultText style={styles.textSubLabel}>{header.subLabel}</DefaultText>
                                                        <View style={[styles.plantImage, { left: index === 3 ? '75%' : '85%' }]}>
                                                            <PlantImage />
                                                        </View>
                                                    </View>
                                                    <View>
                                                        {SWITCHES_LESSON_PLANS.map((item) => {
                                                            if (item.groups !== header.label) {
                                                                return null
                                                            }
                                                            return (
                                                                <Pressable key={item.cardId} onPress={handleCardPress(item.cardId)} style={[styles.itemSwitchLessonPlanContainer, { width: '100%' }]}>
                                                                    {item.duration && (
                                                                        <View style={styles.durationContainer}>
                                                                            <Entypo name="stopwatch" size={14} color={DecidaColors.GrayText} />
                                                                            <DefaultText style={styles.textDuration}>
                                                                                ({item.duration})
                                                                            </DefaultText>
                                                                        </View>
                                                                    )}
                                                                    <View style={styles.itemCodeAndTitleContainer}>
                                                                        <View style={styles.itemCodeContainer}>
                                                                            <DefaultText style={[styles.textCode, { color: lessonPlanColorScheme }]} >
                                                                                {item.code}
                                                                            </DefaultText>
                                                                        </View>
                                                                        <View style={[styles.switchLessonPlansItemTextContainer, {}]}>
                                                                            <DefaultText style={[styles.textHeadertTitle, { color: lessonPlanColorScheme, }]}>
                                                                                {item.title}
                                                                            </DefaultText>
                                                                        </View>
                                                                    </View>
                                                                    <DefaultText style={styles.textDescription}>
                                                                        {item.description}
                                                                    </DefaultText>
                                                                </Pressable>
                                                            )
                                                        })}
                                                    </View>
                                                </View>
                                            )
                                        })}
                                    </View>
                                )}

                                <View style={styles.lessonPlanContainer}>
                                    {_.sortBy(ALL_LESSON_PLANS, "title").filter(lesson => lesson.emotion === emotion).map(item => {

                                        let switchCard = cards.find((card) => card.title.toLowerCase() === item.title.toLowerCase())
                                        let introductionLessonPlans = !switchCard && item.emotion === SwitchLessonPlansEmotionGroup.GettingStarted

                                        if (!switchCard && !introductionLessonPlans) {
                                            return null
                                        }

                                        return (
                                            <Pressable key={item.cardId} onPress={handleCardPress(item.cardId)} style={[styles.itemContainer, { width: isLargeScreen ? '40%' : '100%', maxWidth: isNarrowScreen ? undefined : SWITCH_LESSON_PLANS_WRAPPER_WIDTH }]}>
                                                <View style={styles.switchIcon}>
                                                    {switchCard?.Icon()}
                                                </View>
                                                <View style={styles.switchLessonPlansItemTextContainer}>
                                                    <DefaultText style={styles.textEmotionTitle}>
                                                        {item.title}
                                                    </DefaultText>
                                                    <DefaultText style={styles.textEmotionDescription}>
                                                        {item.description}
                                                    </DefaultText>
                                                </View>
                                            </Pressable>
                                        )
                                    })}
                                </View>
                            </View>
                        )
                    })}
                </View>
            </>

        )
    },
    CardContent: () => (
        <>

        </>
    ),
    colorScheme: CardColorScheme.TOOL,
    whyDoesItWorkText: '',
    action: [],
    category: [],
    level: [],
    people: [],
    place: [],
    time: [],
    type: []
}

const headerPadding = 15

const styles = StyleSheet.create({
    itemCodeContainer: {
        padding: 0,
        margin: 0,
        justifyContent: 'flex-start',
    },
    itemCodeAndTitleContainer: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    wheelImage: {
        width: 90,
        height: 90,
    },
    plantImage: {
        position: 'absolute',
        bottom: 0
    },
    headerLabelText: {
        width: '100%',
        fontSize: 80,
        fontWeight: 'bold',
        color: DecidaColors.White,
    },
    headerLabelContainer: {
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
    },
    emotionIcon: {
        width: 35,
        height: 35,
        marginTop: 10,
    },
    emotionLabelTitle: {
        fontSize: 30,
        fontWeight: '800',
        marginRight: 10
    },
    emotionIconAndTitleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    durationContainer: {
        flexDirection: 'row',
        position: 'absolute',
        top: 5,
        right: 30,
        alignItems: 'center',
    },
    textLabel: {
        fontSize: 40,
        color: DecidaColors.White,
    },
    textSubLabel: {
        fontSize: 14,
        color: DecidaColors.White,
        fontStyle: 'italic'
    },
    headerContainer: {
        width: '100%',
        padding: headerPadding,
        borderRadius: 20,
        marginBottom: 50,
    },
    textEmotionDescription: {
        fontSize: DESCRIPTION_TEXT_SIZE,
        width: '100%'
    },
    textEmotionTitle: {
        textDecorationLine: "underline",
        color: "blue",
        fontSize: TITLE_TEXT_SIZE,
    },
    switchLessonPlansItemTextContainer: {
        flex: 1,
        flexWrap: 'wrap',
        width: "100%",
    },
    textDescription: {
        fontSize: DESCRIPTION_TEXT_SIZE,
        padding: 5,
    },
    textDuration: {
        color: DecidaColors.GrayText,
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: DESCRIPTION_TEXT_SIZE,
    },
    textHeadertTitle: {
        width: '100%',
        fontSize: TITLE_TEXT_SIZE,
        fontWeight: 'bold',
    },
    textCode: {
        fontSize: 46,
        color: DecidaColors.White,
        fontWeight: '800',
        lineHeight: 45,
        marginRight: 10
    },
    emotionContainer: {
        marginBottom: 40,
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'column',
        paddingHorizontal: 30,
        paddingVertical: 15,
        borderRadius: 20
    },
    lessonPlanContainer: {
        flexDirection: 'row',
        marginTop: 20,
        flexWrap: 'wrap',
    },
    itemContainer: {
        flexDirection: 'row',
        paddingHorizontal: 0,
        marginBottom: 20,
    },
    itemSwitchLessonPlanContainer: {
        marginVertical: 5,
        alignSelf: 'flex-start',
        width: SWITCH_LESSON_PLANS_WRAPPER_WIDTH,
        backgroundColor: DecidaColors.White,
        padding: 15,
        paddingTop: 25,
        borderRadius: 20,

        // borderWidth: 1,
        // borderColor: DecidaColors.GrayBackground,
    },
    trapezium: {
        width: 100,
        height: 0,
        borderRightWidth: 25,
        borderBottomWidth: 40,
        borderStyle: 'solid',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: DecidaColors.LightGreen,
        transform: [{ scaleY: -1 }]
    },
    switchIcon: {
        width: 40,
        height: 40,
        marginRight: 10
    },
    levelsLabelView: {
        marginTop: 10,
        alignItems: 'center',
        maxWidth: SWITCH_LESSON_PLANS_WRAPPER_WIDTH,
        marginHorizontal: 5
    },

})