import { GraphQLResult } from '@aws-amplify/api';
import { makeVar, useReactiveVar } from '@apollo/client'
import { API, graphqlOperation } from 'aws-amplify'
import { useEffect } from 'react'
import { rvClassLoginCurrentCheckIn } from '../class-login/class-login-state'
import { currentClassKey, rvCurrentClass } from '../common/common-state'
import { getStudentByCognitoUsername } from '../../common/graphql/queries'
import { rvCurrentUser } from '../login/login-state'
import { Student, StudentClass, Class, StudentCheckIn, GetStudentByCognitoUsernameQuery } from '../../common/API'
import AsyncStorage from '@react-native-async-storage/async-storage';

export const rvStudentClasses = makeVar<Class[] | undefined>(undefined)
export const rvStudentSelectCurrentClass = makeVar<Class | undefined>(undefined)
export const rvCurrentStudent = makeVar<Student | undefined | null>(undefined)
export const rvStudentClassClasses = makeVar<StudentClass[] | undefined>(undefined)
export const rvStudentSelectCurrentClassId = makeVar<string | undefined>(undefined)
export const rvSuggestionRatingRequired = makeVar<boolean>(false)
export const rvCurrentStudentLastStudentCheckIn = makeVar<StudentCheckIn | undefined>(undefined)
export const rvStudentJoinedClass = makeVar('')

export const useStudentState = () => {

    const user = useReactiveVar(rvCurrentUser)
    const currentClass = useReactiveVar(rvCurrentClass)
    const currentStudent = useReactiveVar(rvCurrentStudent)
    const currentActiveCheckin = useReactiveVar(rvClassLoginCurrentCheckIn)

    // TODO: Double check this in meantime if still caused sync problems
    // const currentStudent = useItemSubscription(
    //     Student,
    //     {
    //         criteria: filterBy => filterBy.cognitoUsername('eq', user?.username || ''),
    //         dependencies: [user]
    //     }
    // )

    useEffect(() => {
        if (!currentStudent) {
            getCurrentStudent(user?.username || "")
        }
    }, [user])


    return {
        currentStudent,
        currentCheckIn: currentActiveCheckin
    }
}

export const getCurrentStudent = async (cognitoUsername: string) => {
    if (cognitoUsername) {
        // Caching data placeholder
        // const cacheKey = `listStudentClasses-${cognitoUsername}`
        // const cacheData = await getCacheDataByKey(cacheKey)

        // if (cacheData && !refetch?.status) {
        //     const parsedData = JSON.parse(cacheData)
        //     return parsedData
        // }

        // rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(getStudentByCognitoUsername,
                {
                    cognitoUsername
                })) as GraphQLResult<GetStudentByCognitoUsernameQuery>
        const studentResponse = response?.data?.getStudentByCognitoUsername?.items.filter((sc) => sc?._deleted !== true)
        // rvIsLoading(false)
        if (studentResponse) {
            rvCurrentStudent(studentResponse[0] as unknown as Student)
        }
    }
}

export const setCurrentClassIdStudent = async (classId: string | undefined) => {

    try {
        rvStudentSelectCurrentClassId(classId)
        const jsonValue = JSON.stringify(classId)
        await AsyncStorage.setItem(currentClassKey, jsonValue)
    } catch (e) {
        console.log(e)
    }
}

export const getCurrentClassIdFromStorageStudent = async () => {

    try {
        const classId = JSON.parse((await AsyncStorage.getItem(currentClassKey)) || '')

        // on slow machine data from backend could have came back before local storage return data, so we need to check the rv

        const studentClasses = rvStudentClasses()
        const studentSelectCurrentClassId = rvStudentSelectCurrentClassId()
        rvStudentSelectCurrentClassId(studentSelectCurrentClassId || (studentClasses ? studentClasses[0]?.id : classId || undefined))
    } catch (e) {
        // error reading value
    }
}

