import { View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { RewardDreamIcon } from '../../explore/src/reward-dream-icon'
import { RewardDreamIconInverted } from '../../explore/src/reward-dream-icon-inverted'
import { RewardImage1 } from './src/reward-dream1'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import SwitchCardVideo from '../../../common/switch-card-video'

const rewardDreamVideo = {
    id: '1',
    url: 'https://www.youtube.com/embed/QX_oy9614HQ'
}

export const SwitchesEmotionsExcitedRewardDream: CardTemplate = {
    cardId: "79d4db9a-8415-4342-8fd2-c8049a26e7dc",
    title: 'Reward dream',
    Header: RewardDreamIcon,
    Icon: RewardDreamIconInverted,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Teaching emotional control and focussing energy to start a task."} />
        </>
    ),
    Images: () => (
        <>
            <RewardImage1 />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Think of something you would really love right now (like an ice-cream or to go and play)."} />
                <DefaultTextWithDownArrow textdata={"Think of how much more enjoyable that will be at the end of the activity you need to do right now, when you have the added good feeling of completing the task, and enjoying the reward."} />
                <DefaultTextWithoutArrows textdata={"Now, focus on what you need to do right now and finish it."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: '\n \n A big social experiment in New Zealand, called the Dunedin Multidisciplinary Health and Development Study, which tracked almost 1000 people for more than 60 years proved that the ability to delay gratification, to control your emotional impulses, was the only thing that predicted success in life. \n \n Thinking about a reward you will get after doing an activity that you don’t think you will enjoy, increases motivation and reduces the resistance we feel when deciding to start a task. ',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Describe the concept of delayed gratification.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice the feeling of delaying immediate reward for better rewards in the future.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by asking students if they know what "delayed gratification" means. Encourage brief responses and explanations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept by explaining that delayed gratification is the ability to wait for something you want rather than immediately indulging in it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Share the rules of the marshmallow experiment, highlighting the choice students will have and the potential reward for delayed gratification.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Marshmallow Experiment (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute one marshmallow to each student or an alternative treat suitable for the group.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the rules of the "test" and set a visible countdown timer for 10 minutes (or adjust for a time limit suitable to the student’s age).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students that they can either eat the marshmallow immediately or wait for the allotted time to receive a second marshmallow.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Observe their behaviour during the experiment, avoiding comments or interference. Those children that can resist will usually do one of two things:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Distract themselves – squirm, hum, make faces, doodle, anything to keep from eating that treat!</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Remove the temptation – avoid eye contact with the marshmallow, cover it with a tissue, or hide it somewhere. Out of sight, out of mind!</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Debrief and Discussion (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After the allotted time, reward students who resisted with a second marshmallow. Acknowledge and sympathise with those who ate the marshmallow early (or you may like to give them the treat anyway).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conduct a debriefing discussion with the following questions:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- What helped you resist eating the marshmallow?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Do you think it would be easier or harder to resist if you were by yourself?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Did your classmates influence your decision to eat the marshmallow?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Why did you decide to eat the marshmallow before the time was  up?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- What would have helped you wait longer?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- How long do you think you could wait?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- What are some other times we have to make a choice that involves self control?</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflecting on Delayed Gratification (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show a short video for additional reinforcement (and a bit of fun): <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text="The Marshmallow Test - 1972 Experiment." link='https://www.youtube.com/watch?v=QX_oy9614HQ' /></DefaultText>

                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the concept of delayed gratification and its importance in decision making.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how the experiment relates to real life situations where waiting for a reward can lead to better outcomes.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, ask them to find the Reward Dream Switch in the Switch4Schools app.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to explore the switch and see if it provides additional insights or tips on delayed gratification.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key concepts learned during the experiment and discussion.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the idea that making choices involving self control can lead to more significant rewards in the future.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to reflect on how they can apply the concept of delayed gratification in their daily lives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Homework (Optional): Ask students to reflect on a personal experience where they had to exercise delayed gratification and write a short paragraph about it. Discuss their reflections in the next class.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Marshmallows (enough for each child to have 2)</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Motivational Aspect of Children’s Delayed Gratification: Values and Decision Making in Middle Childhood - </DefaultText>
                        <DefaultTextWithLink text="https://www.frontiersin.org/articles/10.3389/fpsyg.2019.01649/full#:~:text=Delayed%20gratification%20is%20the%20ability,of%20greater%20and%20later%20reward" />

                        <DefaultText style={CommonStyles.lessonPlanText}>The Marshmallow Test - 1972 Experiment.-</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.youtube.com/watch?v=QX_oy9614HQ' link='https://www.youtube.com/watch?v=QX_oy9614HQ' />
                        <SwitchCardVideo video={rewardDreamVideo} />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrows textdata='' />
            <DefaultTextWithoutArrows textdata='' />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Reward+Dream.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Excited]
}

