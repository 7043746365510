import { Ionicons } from '@expo/vector-icons'
import { ColorValue, TouchableOpacity, ViewStyle } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'

type Props = {
    color?: ColorValue
    onPress?: () => void
    style?: ViewStyle
}

const InformationIcon = ({ onPress, color, style }: Props) => {
    return (
        <TouchableOpacity onPress={onPress} style={style}>
            <Ionicons name="information-circle" size={30} color={color || DecidaColors.White} />
        </TouchableOpacity>
    )
}
export default InformationIcon