import Svg, { G, Path, Circle } from 'react-native-svg'

export const IconTirednessDrowsy = () => (
    <Svg viewBox="0 0 110 110">
        <G transform="translate(-546.69 -973.062)">
            <G transform="translate(-5994.602 -1676.843)">
                <Path d="M6585.292,2735.405a41.5,41.5,0,1,0-41.5-41.5,41.5,41.5,0,0,0,41.5,41.5" fill="#fff" />
                <Circle cx="41.5" cy="41.5" r="41.5" transform="translate(6543.792 2652.405)" fill="none" stroke="#5a5b5e" strokeWidth="5" />
                <Path d="M6568.664,2692.453a6.531,6.531,0,1,0-6.532-6.532,6.532,6.532,0,0,0,6.532,6.532" fill="#5a5b5e" />
                <Path d="M6601.3,2692.453a6.531,6.531,0,1,0-6.532-6.532,6.532,6.532,0,0,0,6.532,6.532" fill="#5a5b5e" />
                <Path d="M6560.392,2692.453s10.107,8.449,15.8,0" fill="none" stroke="#5a5b5e" strokeLinecap="round" strokeWidth="3" />
                <Path d="M6609.206,2692.027s-10.107,8.449-15.8,0" fill="none" stroke="#5a5b5e" strokeLinecap="round" strokeWidth="3" />
                <Path d="M6561.553,2713.58s26.4-14.324,43.8,0" fill="none" stroke="#5a5b5e" strokeLinecap="round" strokeWidth="5" />
            </G>
        </G>
    </Svg>
)