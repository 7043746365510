import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { Br } from '../common/br'
import { DefaultFontSize } from '../common/const'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { useResponsive } from '../common/use-responsive'
import ViewZoomableForIphone from '../common/view-zoomable'
import { DashboardInfoImage1 } from './assets/dashboard-info-image1'
import { DashboardInfoImage2 } from './assets/dashboard-info-image2'
import { DashboardInfoImage3 } from './assets/dashboard-info-image3'
import { DashboardInfoImage4 } from './assets/dashboard-info-image4'
import { DashboardInfoImage5 } from './assets/dashboard-info-image5'

type Props = {
    handleNext: () => void
    isLastStep?: boolean
    handlePrevious?: () => void
}

const TeacherDashboardInfoSteps1 = ({ handleNext, handlePrevious }: Props) => {
    const { responsiveFontSize, isNarrowScreen } = useResponsive()
    return (
        <View style={styles.content}>
            <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                This is your dashboard. Here you can navigate each of your classes and monitor any students who may need additional attention.
            </DefaultText>
            <Br />
            <DefaultText style={[styles.text, { fontSize: responsiveFontSize(), fontWeight: 'bold' }]}>
                Additional attention
            </DefaultText>
            <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                The data from the students’ last check-in appear next to their avatar. The coloured stacks represent their emotion, the higher the stack, the more extreme the emotion.
            </DefaultText>

            <View style={[styles.imageContainer, { height: 150 }]}>
                <DashboardInfoImage1 />
            </View>

            <Br />

            <DefaultText style={[styles.text, { fontSize: responsiveFontSize(), fontWeight: 'bold' }]}>
                Clearing requests and seeing data
            </DefaultText>
            <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                When you select a student, you will have the option to open the student’s individual stats or clear any chat requests.
            </DefaultText>

            <View style={{ width: '100%', height: isNarrowScreen ? 70 : 120, justifyContent: 'flex-start' }}>
                <DashboardInfoImage2 />
            </View>

            <View style={styles.buttonsContainer}>
                {handlePrevious !== undefined && (
                    <DefaultButton style={styles.nextButton} buttonTextStyle={[styles.nextText, { fontSize: responsiveFontSize(24) }]} onPress={handlePrevious}>
                        {"PREVIOUS"}
                    </DefaultButton>
                )}
                <DefaultButton style={styles.nextButton} buttonTextStyle={[styles.nextText, { fontSize: responsiveFontSize(24) }]} onPress={handleNext}>
                    {"NEXT"}
                </DefaultButton>
            </View>
        </View>
    )
}


const TeacherDashboardInfoSteps2 = ({ handleNext, handlePrevious }: Props) => {
    const { responsiveFontSize, isNarrowScreen } = useResponsive()
    return (
        <View style={styles.content}>
            <View style={{ flex: 1 }}>
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize(), fontWeight: 'bold' }]}>
                    Warning icons
                </DefaultText>
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                    Students may have one or more of any of the following icons attached to their avatars, each indicates something different.
                </DefaultText>
                <Br />

                <ViewZoomableForIphone style={styles.image3Container}>
                    <DashboardInfoImage3 />
                </ViewZoomableForIphone>
            </View>


            <View style={styles.buttonsContainer}>
                <DefaultButton style={styles.nextButton} buttonTextStyle={[styles.nextText, { fontSize: responsiveFontSize(24) }]} onPress={handlePrevious}>
                    {"PREVIOUS"}
                </DefaultButton>
                <DefaultButton style={styles.nextButton} buttonTextStyle={[styles.nextText, { fontSize: responsiveFontSize(24) }]} onPress={handleNext}>
                    {"NEXT"}
                </DefaultButton>
            </View>
        </View>
    )
}

const TeacherDashboardInfoSteps3 = ({ handleNext, handlePrevious }: Props) => {
    const { responsiveFontSize } = useResponsive()

    return (
        <View style={styles.content}>
            <View style={{ flex: 1 }}>
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize(), fontWeight: 'bold' }]}>
                    Accessing your classes
                </DefaultText>
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                    Beneath the name of each of your classes you will be able to access your admin page, stats page and check-in service.
                </DefaultText>
                <Br />
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize(), fontWeight: 'bold' }]}>
                    Go Groups:
                </DefaultText>
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                    GO Groups facilitate individual check-ins as opposed to class group check-in sessions. They will be signified with an asterisk and will not indicate the date and time of the last session.
                </DefaultText>
                <Br />

                <DefaultText style={[styles.text, { fontSize: responsiveFontSize(), fontStyle: 'italic' }]}>
                    examples:
                </DefaultText>

                <View style={styles.imageContainer}>
                    <DashboardInfoImage4 />
                </View>

            </View>

            <View style={styles.buttonsContainer}>
                <DefaultButton style={styles.nextButton} buttonTextStyle={[styles.nextText, { fontSize: responsiveFontSize(24) }]} onPress={handlePrevious}>
                    {"PREVIOUS"}
                </DefaultButton>
                <DefaultButton style={styles.nextButton} buttonTextStyle={[styles.nextText, { fontSize: responsiveFontSize(24) }]} onPress={handleNext}>
                    {"NEXT"}
                </DefaultButton>
            </View>

        </View>
    )
}

const TeacherDashboardInfoSteps4 = ({ handleNext, handlePrevious }: Props) => {
    const { responsiveFontSize } = useResponsive()
    return (
        <View style={styles.content}>
            <View style={{ flex: 1 }}>
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize(), fontWeight: 'bold' }]}>
                    Statistics
                </DefaultText>
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                    Summary graphs will be presented for the most recent class group check-in session.  This includes the average energy level and the count of checks-ins for each of the emotions.
                </DefaultText>
                <View style={[styles.imageContainer, { height: '100%', maxHeight: 300, padding: 20 }]}>
                    <DashboardInfoImage5 />
                </View>
            </View>

            <View style={styles.buttonsContainer}>
                <DefaultButton style={styles.nextButton} buttonTextStyle={[styles.nextText, { fontSize: responsiveFontSize(24) }]} onPress={handlePrevious}>
                    {"PREVIOUS"}
                </DefaultButton>
                <DefaultButton style={styles.nextButton} buttonTextStyle={[styles.nextText, { fontSize: responsiveFontSize(24) }]} onPress={handleNext}>
                    {"CLOSE"}
                </DefaultButton>
            </View>
        </View>
    )
}


export {
    TeacherDashboardInfoSteps1,
    TeacherDashboardInfoSteps2,
    TeacherDashboardInfoSteps3,
    TeacherDashboardInfoSteps4
}
const styles = StyleSheet.create({
    buttonContainer: {
        alignItems: 'center',
        width: '100%',
    },
    image3Container: {
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
        maxHeight: 450,
        backgroundColor: 'white',
        marginBottom: 10
    },
    bottomContainer: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    content: {
        padding: 20,
        height: '100%',
        justifyContent: 'space-between'
    },
    contentContainer: {
        flex: 1,
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-evenly'
    },
    contentContainerLeft: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%'
    },
    buttonsContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 20,
        gap: 10
    },
    step3ButtonContainer: {
        flex: 1,
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-between'
    },
    nextButton: {
        width: 150,
        borderRadius: 20,
    },
    nextText: {
        fontSize: 24,
        fontWeight: 'bold'
    },
    imageContainer: {
        width: '100%',
        height: 200,
        backgroundColor: DecidaColors.White,
        marginBottom: 10
    },
    stepText: {
        textAlign: 'center',
    },
    text: {
        textAlign: 'center',
        fontSize: DefaultFontSize - 2,
    },
})