import { View } from 'react-native'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { CardTemplate, CardType } from '../../common/card-template'
import { CardColorScheme } from '../../common/card-color-scheme'
import { HandBrainIcon } from '../icons/hand-brain-icon'
import { HandBrainImage1 } from '../images/hand-brain-image1'
import { HandBrainImage2 } from '../images/hand-brain-image2'
import { HandBrainImage3 } from '../images/hand-brain-image3'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { Dimensions } from 'react-native';
import { HandBrainIconCard } from '../icons/hand-brain-icon-card'
import ViewZoomableForIphone from '../../../common/view-zoomable'

const windowWidth = Dimensions.get('window').width;

export const ToolsHandBrain: CardTemplate = {
    cardId: "7a013e31-2f3d-46ff-962d-e53c78854f81",
    title: 'Hand brain',
    Header: HandBrainIcon,
    Icon: HandBrainIconCard,
    HeaderTitle: 'Hand brain',
    PerfectFor: () => (
        <>
            <View style={commonCardStyles.container}>
                <View style={commonCardStyles.imagecontainer}>
                    <ViewZoomableForIphone style={windowWidth < 600 ? { width: 360, height: 400, } : { width: 800, height: 600, }}>
                        <HandBrainImage1 />
                    </ViewZoomableForIphone>
                    <ViewZoomableForIphone style={windowWidth < 600 ? { width: 360, height: 400, } : { width: 800, height: 600, }}>
                        <HandBrainImage2 />
                    </ViewZoomableForIphone>
                    <ViewZoomableForIphone style={windowWidth < 600 ? { width: 360, height: 400, } : { width: 800, height: 600, }}>
                        <HandBrainImage3 />
                    </ViewZoomableForIphone>

                </View>
            </View>
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>

        </>
    ),
    colorScheme: CardColorScheme.TOOL,
    whyDoesItWorkText: '',
    type: [CardType.Tool],
    time: [],
    people: [],
    place: [],
    category: [],
    action: [],
    level: []
}

