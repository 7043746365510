import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const EmotionAngryIcon = (props?: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 92.491 92.489"
        {...props}
    >
        <Path
            id="Path_3851"
            data-name="Path 3851"
            d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465Z"
            transform="translate(26.513 51.518)"
            fill="none"
            stroke="#e31e25"
            strokeWidth={3.013}
        />
        <Path
            id="Path_3852"
            data-name="Path 3852"
            d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211"
            transform="translate(21.98 36.312)"
            fill="#ff2d16"
        />
        <Path
            id="Path_3853"
            data-name="Path 3853"
            d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211"
            transform="translate(57.167 36.312)"
            fill="#ff2d16"
        />
        <Path
            id="Path_3854"
            data-name="Path 3854"
            d="M0,4.536s17.981-22.781,42.69-.53"
            transform="translate(21.143 59.767)"
            fill="none"
            stroke="#ff2d16"
            strokeWidth={3.013}
        />
        <Path
            id="Path_3858"
            data-name="Path 3858"
            d="M0,0,15.648,6.468"
            transform="translate(17.263 19.617)"
            fill="none"
            stroke="#ff2d16"
            strokeWidth={3.013}
        />
        <Path
            id="Path_3862"
            data-name="Path 3862"
            d="M0,2.853,15.648-3.615"
            transform="translate(52.439 23.232)"
            fill="none"
            stroke="#ff2d16"
            strokeWidth={3.013}
        />
    </Svg>
);
export default EmotionAngryIcon;
