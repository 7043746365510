export enum HappyLegends {
    Peaceful = "Peaceful",
    Pleased = 'Pleased',
    Optimistic = 'Optimistic',
    Cheerful = 'Cheerful',
    Delighted = 'Delighted',
    Sensational = 'Sensational',
    Ecstatic = 'Ecstatic',
    Buoyant = 'Buoyant'
}

export enum AngryLegends {
    Indignant = 'Indignant',
    Annoyed = 'Annoyed',
    Irritated = 'Irritated',
    Frustrated = 'Frustrated',
    Fuming = 'Fuming',
    Furious = 'Furious',
    Seething = 'Seething',
}

export enum SadLegends {
    Disappointed = 'Disappointed',
    Discouraged = 'Discouraged',
    Vulnerable = 'Vulnerable',
    Dejected = 'Dejected',
    Hopeless = 'Hopeless',
    Grieving = 'Grieving',
    Despair = 'Despair',
}

export enum ScaredLegends {
    Hesitant = 'Hesitant',
    Unsure = 'Unsure',
    Nervous = 'Nervous',
    Alarmed = 'Alarmed',
    Threatened = 'Threatened',
    Afraid = 'Afraid',
    Terrified = 'Terrified',
}

export enum AnxiousLegends {
    Attentive = 'Attentive',
    Focused = 'Focused',
    Concerned = 'Concerned',
    Apprehensive = 'Apprehensive',
    Worried = 'Worried',
    Overwhelmed = 'Overwhelmed',
    Panicked = 'Panicked'
}

export enum ExcitedLegends {
    Expectant = 'Expectant',
    Inspired = 'Inspired',
    Eager = 'Eager',
    Energetic = 'Energetic',
    Passionate = 'Passionate',
    Pumped = 'Pumped',
    Hyper = 'Hyper',
}