import { View } from 'react-native'
import { DefaultTextWithDownArrow } from '../../../common/default-text-with-down-arrow'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { BlindDrawingIcon } from '../../icons/blind-drawing-icon'
import { DefaultTextWithExtraTipIcon } from '../../../common/default-text-with-extra-tip-icon'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { BlindDrawingIconInverted } from '../../icons/blind-drawing-icon-inverted'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'

export const SwitchesActivitiesHealthyHabitsBlindDrawing: CardTemplate = {
    cardId: "e5b0cbf4-bd31-4f5a-81fd-c5301a7425ab",
    title: 'Blind drawing',
    Header: BlindDrawingIconInverted,
    Icon: BlindDrawingIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Testing communication abilities, thinking about communication, and learning how to be more descriptive and articulate."} />
            <DefaultTextWithDownArrow textdata={"Find a partner."} />
            <DefaultTextWithDownArrow textdata={"Choose one person to be the speaker and one to be the drawer."} />
            <DefaultTextWithDownArrow textdata={"The speaker chooses an object to describe (without telling the drawer), then describes the object while the other person or people must draw the object from the description alone."} />
            <DefaultTextWithoutArrows textdata={"The person describing the object cannot say anything which clearly gives away what the object is or is closely related. For example, if describing a tree, you might say, “It’s a tall cylinder with lightning coming out of each end, one set of lightning spreading out into the air, the other going into the ground. It has rough, weathered skin, and at the ends of each fork of lightning there are hundreds of children that grow until they’re ready to fall.”"} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.CREATIVITY,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"Separate people into breakout rooms or have them communicate their descriptions over text chat."} />
            <DefaulTextWithTipsIcon textdata={"Once the drawing is complete, give your group the opportunity to reflect."} />
            <DefaulTextWithTipsIcon textdata={"While debriefing the exercise, ask your participants to think about how they communicated, what was difficult, and how they might communicate better next time."} />
            <DefaulTextWithTipsIcon textdata={" If you want to go a step further, mix up your groups, and have another round of blind drawing. Challenge your participants to try and communicate more clearly while still following the rules of the challenge!"} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Blind+Drawing.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Short, CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.CREATIVITY],
    action: [CardActionType.Action],
    level: []
}

