import { View } from 'react-native'
import { AudioHandlerOutlined } from '../../../common/audio-handler-outlined'
import { audio } from '../../../common/audio-imports'
import { Br } from '../../../common/br'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { Link } from '../../../common/link'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles, commonStyles, windowWidth } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { ExerciseOrDanceIcon } from '../../explore/src/exercise-or-dance-icon'
import { ExerciseOrDanceIconInverted } from '../../explore/src/exercise-or-dance-icon-inverted'
import { ExerciseOrDanceImage1 } from './src/exercise-or-dance-image1'
import { ExerciseOrDanceImage2 } from './src/exercise-or-dance-image2'
import { Worksheets } from '../../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'

export const SwitchesEmotionsExcitedExerciseOrDance: CardTemplate = {
    cardId: "6a639395-2044-4061-9b44-531376ea9306",
    title: 'Exercise or dance',
    Header: ExerciseOrDanceIconInverted,
    Icon: ExerciseOrDanceIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <ExerciseOrDanceImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Releasing general energy levels and promoting better long term focus."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Find an activity that you enjoy, e.g. dancing, running, walking or sport."} />
                <DefaultTextWithDownArrow textdata={"Find a friend to do it with."} />
                <DefaultTextWithoutArrows textdata={"Set a timer for an agreed period of time and get moving. "} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: '\n \n As you exercise, the brain thinks you are either fighting or fleeing from an enemy. \n \n To protect from this stress, you release a protein called BDNF (Brain-Derived Neurotrophic Factor). This helps protect and repare our brain and acts as a reset switch.\n \n At the same time, endorphins are released to minimise the discomfort of exercise and block the feeling of pain. \n \n So, BDNF and endorphins are the reasons exercise makes us feel so good.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Develop a simple dance routine to release or create positive energy.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 60 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin with a brief discussion about the concept of excitement and how it can manifest in our bodies.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of expressing excitement in a positive and constructive way.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the activity by explaining that students will be creating dance routines to release their excitement.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conduct a quick warm-up session, including stretches and light movements to prepare the body for dance and exercise.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity (25 minutes):</DefaultText>
                        <DefaultText style={[CommonStyles.lessonPlanText, { fontWeight: 'bold', color: DecidaColors.Excited }]}>◦ Option 1: Dance Routine Creation</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Group students into pairs or small groups.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Provide each group with dance move cards.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Instruct students to choose a specific number of moves (depending on time and age) to create a dance routine.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Students place or stick the chosen moves onto their choreography sheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Once the choreography sheet is complete, students select a backing track from the app.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Encourage students to refine and rehearse their routines.</DefaultText>
                        <DefaultText style={[CommonStyles.lessonPlanText,]}>◦ Option 2: Snap Game for Dance Routine</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Group students into pairs.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Provide each pair with a set of snapping cards and a choreography sheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Students play snap, and when two cards match, they call out snap and note that action onto their dance routine choreography sheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Continue playing snap until the recording sheet is complete.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Once the choreography sheet is complete, students select a backing track from the app.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Encourage students to refine and rehearse their routines.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Performance (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite each group or pair to share their dance routines with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ensure there is applause and positive feedback for each performance.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Debrief (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss with students how fun and effective it was to release excited energy through movement.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Talk about the positive impact of dance and exercise on mood and energy levels.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to reflect on the experience and share their thoughts.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reiterate the connection between physical activity, emotional expression, and positive wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to incorporate dance and exercise as a tool for managing excitement and maintaining a positive mindset. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, remind them to explore the Switch4Schools app for additional activities related to emotions and wellbeing.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Dance Cards' link={Worksheets.danceCards.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Dance Routine worksheet' link={Worksheets.danceRoutine.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Speaker for playing music from the app</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>How Dancing Helps Your Mental Health -</DefaultText>
                        <DefaultTextWithLink text="https://www.verywellmind.com/how-dancing-helps-your-mental-health-5206963" />
                        <Br />
                        <DefaultText style={CommonStyles.lessonPlanText}>Get healthy Healthy body, healthy mind -</DefaultText>
                        <DefaultTextWithLink text='https://mentalwellbeing.initiatives.qld.gov.au/index.php/get-healthy?gclid=EAIaIQobChMI_9DXtJje9wIV3pNmAh07Ig_dEAAYASAAEgJl2_D_BwE' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata="Introduce more movement in your day, eg. take the stairs, do some push-ups at night or offer to walk the dog." />
            <DefaultText style={{ paddingLeft: 10, paddingTop: 10, color: DecidaColors.Gray }}>
                Some music to dance to:
            </DefaultText>
            <View style={{
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                maxWidth: "100%",
            }}>
                <View style={{ flexDirection: 'column', paddingRight: 5, }}>
                    <AudioHandlerOutlined audioFile={audio.DanceAnthem} audioTitle={"Dance anthem"} />
                </View>
                <View style={{ flexDirection: 'column', paddingLeft: 5, }}>
                    <AudioHandlerOutlined audioFile={audio.OldSkoolClub} audioTitle={"Retro club mix"} />
                </View>
            </View>
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Exercise+or+dance.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Excited]
}

