import { TouchableOpacity, StyleSheet } from 'react-native'
import { Class } from '../models'
import { DefaultText } from './default-text'
import { RadioButton } from './radio-button'
import { useReactiveVar } from "@apollo/client"
import { rvCurrentClass } from "../common/common-state";
import { setCurrentClassIdStudent } from '../student/use-student-state'

interface Props {
    clazz: Class
}

export const StudentClassSelectorItem = ({ clazz }: Props) => {
    const currentClass = useReactiveVar(rvCurrentClass)

    const setCurrentClass = () => {
        setCurrentClassIdStudent(clazz.id)
    }

    return (
        <TouchableOpacity style={styles.selection} onPress={setCurrentClass}>
            <RadioButton checked={clazz.id === currentClass?.id} />
            <DefaultText> {clazz?.name || ''} </DefaultText>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    selection: {
        paddingVertical: 10,
        flexDirection: 'row'
    }
})
