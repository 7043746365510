import { StyleSheet, View, useWindowDimensions } from 'react-native'

import { BreakPoints, CommonStyles } from '../../common/const'
import { DefaultTextWithRvColor } from './default-text-with-rv-color'
import { LessonPlanContainer } from './lesson-plan-container'
import { useResponsive } from '../../common/use-responsive'
import { useReactiveVar } from '@apollo/client'
import { rvPrintMode } from '../../common/export-to-pdf-button'

type Props = {
    learningObjectiveContent: () => JSX.Element,
    summaryAndTaskActionsContent: () => JSX.Element,
    materialEquipmentContent: () => JSX.Element,
    additionalReferencesContent: () => JSX.Element
}

const LESSON_PLAN_CONTAINER_WIDTH = 550
const LESSON_PLAN_MULTI_COLUMN_MIN_WIDTH = 1215

const LessonPlan = ({ learningObjectiveContent, summaryAndTaskActionsContent, materialEquipmentContent, additionalReferencesContent }: Props) => {

    const { width } = useWindowDimensions()
    const isPrintMode = useReactiveVar(rvPrintMode)

    const LearningObjectives = () => <LessonPlanContainer
        LessonPlanHeading={'Learning objective'}
        LessonContent={learningObjectiveContent} />

    const SummaryAndTaks = () => <LessonPlanContainer
        LessonPlanHeading={'Summary of tasks/actions'}
        LessonContent={summaryAndTaskActionsContent} />

    const MaterialEquipment = () => <LessonPlanContainer
        LessonPlanHeading={'Materials/equipment'}
        LessonContent={materialEquipmentContent} />

    const AdditionalReferences = () => <LessonPlanContainer
        LessonPlanHeading={'Additional references/resources'}
        LessonContent={additionalReferencesContent} />


    return (
        <View>
            <View style={{ marginTop: isPrintMode ? 40 : undefined }}>
                <DefaultTextWithRvColor textdata={"Lesson plan"} />
            </View>
            <View style={[CommonStyles.lessonPlanFlexWrapper]}>
                <View style={[CommonStyles.lessonPlanFlexContainer, width < BreakPoints.S ? styles.lessonPlanContainerMobileWidth : styles.lessonPlanContainerWidth]}>
                    <LearningObjectives />
                    {width < LESSON_PLAN_MULTI_COLUMN_MIN_WIDTH ? (
                        <SummaryAndTaks />
                    ) : (
                        <>
                            <MaterialEquipment />
                            <AdditionalReferences />
                        </>
                    )}

                </View>
                <View style={[CommonStyles.lessonPlanFlexContainer, width < BreakPoints.S ? styles.lessonPlanContainerMobileWidth : styles.lessonPlanContainerWidth]}>
                    {width < LESSON_PLAN_MULTI_COLUMN_MIN_WIDTH ? (
                        <>
                            <MaterialEquipment />
                            <AdditionalReferences />
                        </>
                    ) : (
                        <SummaryAndTaks />
                    )}
                </View>

            </View>
        </View>
    )
}
export default LessonPlan
const styles = StyleSheet.create({
  lessonPlanContainerMobileWidth: {
    width: '100%',
    maxWidth: LESSON_PLAN_CONTAINER_WIDTH,
  },
  lessonPlanContainerWidth: {
    width: LESSON_PLAN_CONTAINER_WIDTH
  }
})