import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { SignificanceIcon } from '../../explore/src/significance-icon'
import { SignificanceIconInverted } from '../../explore/src/significance-icon-inverted'
import { SignificanceImage1 } from '../sad/src/significance-image1'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import SwitchCardVideo from '../../../common/switch-card-video'

export const SwitchesEmotionsAngrySignificance: CardTemplate = {
    cardId: "b16aff2e-6dc6-4cfb-8de1-9b61e1cdc5cc",
    title: 'Significance',
    Header: SignificanceIconInverted,
    Icon: SignificanceIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <SignificanceImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Snapping you out of any negative mindsets, but particularly when angry at a person or situation."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"How will you feel about your current situation in one week, one month, or in a year’s time?"} />
                <DefaultTextWithDownArrow textdata={"Think about your future self, how you will judge how you are feeling and acting right now."} />
                <DefaultTextWithDownArrow textdata={"What are the most important things or people in your life?"} />
                <DefaultTextWithoutArrows textdata={"Think about how your anger is impacting your future and those you love."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: 'Our brain’s survival instinct and automatic system (our reactive self) only considers the short term and immediate impacts. When we are controlled by our emotion, it’s difficult to see issues with perspective of the impact on valued relationships, but doing so can help us get back in control of ourselves.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Visualise and articulate future state goal/s.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Apply objective thinking to a personal anger triggering event to learn perspective.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin with a brief discussion about the concept of anger and triggers. Recap any previous activities related to managing anger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that the Significance Switch is a tool to help manage anger by putting things into perspective. Discuss the idea that asking, "Will this matter in the future?" can help reduce the intensity of the emotion.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students think about something currently making them angry. Instruct them to write/draw a "current state" picture on their paper, considering what's happening, their emotions, and the impact on others.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the idea of future vision. Ask students to think about where they want to be in one year. Have them write/draw a "future state" picture, including how they will feel and the positive impact on themselves and others.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Students identify three actions they can take to move from their current reality to the desired future state. Encourage creativity and personal reflection.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Debrief (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Group Discussion: Have students share their drawings and discuss how envisioning their future helps put current challenges in perspective. Emphasise the significance of long term goals and values.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to keep their worksheets in a visible place as a reminder of what truly matters. Discuss how this visual representation can act as a tool during challenging times.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have devices, guide them to find and explore the Significance Switch in the Switch4Schools app. Encourage them to use it as a resource for managing anger and gaining perspective.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Homework (Optional):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on a recent challenging situation and apply the Significance Switch by drawing their current and future states. Encourage them to share their reflections in the next class.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Draw Your Future Self worksheet' link={Worksheets.drawYourFutureSelf.uri} /> </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>How Thinking About the Future Makes Life More Meaningful - </DefaultText>
                        <DefaultTextWithLink text='https://greatergood.berkeley.edu/article/item/how_thinking_about_the_future_makes_life_more_meaningful' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Draw your future | Patti Dobrowolski | TEDxRainier -  </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=zESeeaFDVSw' />
                        <SwitchCardVideo video={{
                            id: "1",
                            url: "https://www.youtube.com/embed/zESeeaFDVSw"
                        }} />

                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Being clear on values and goals when in a good frame of mind will make it easier to assess the significance of a situation in the heat of the moment.  ' />
            <DefaulTextWithTipsIcon textdata='Areas to consider goals and priorities include: ' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Physical and mental health"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Education, school and potential careers"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Relationships at home, school and beyond."} />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Significance.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Angry]
}
