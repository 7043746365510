import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { GiveIcon } from '../../explore/src/give-icon'
import { GiveIconInverted } from '../../explore/src/give-icon-inverted'
import { GiveImage1 } from './src/give-image1'
import { RAKImage1 } from './src/rax-image1'

export const SwitchesEmotionsHappyRandomActOfKindness: CardTemplate = {
    cardId: "f70db593-c6db-45ba-8ccf-57b36490be04",
    title: 'Random act of kindness',
    Header: GiveIconInverted,
    Icon: GiveIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Encouraging feelings of competency and connectedness."} />
        </>
    ),
    Images: () => (
        <>
            <GiveImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Choose someone that you think would like a random act of kindness."} />
                <DefaulttextWithRightArrow textdata={"Take some time to sit and plan what you would like to do."} />
                <DefaulttextWithRightArrow textdata={"It can be helpful to draw a picture of what you are going to do to help work it out - Who is involved? - What needs to happen?"} />
                <DefaulttextWithRightArrow textdata={"After you have completed your act of kindness, reflect on what you have done, and the positive impact you have had on someone elses day. "} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: 'Researchers have found a connection between happiness and the performance of selfless acts. Giving to others, they say, activates an area of the brain linked with joy, contentment and the reward cycle.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Articulate what an act of kindness is, and </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice, planning for and delivering an act of kindness to experience the positive energy altruism provides. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 35 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that kindness provides value to both the giver and receiver. Research shows that the happiness people get from giving to others creates a ‘positive feedback loop’. Examples:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Pay it forward – sharing some homemade biscuits, or holding the door open for someone.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Sending notes of gratitude – write a thank you note to someone you admire or who has helped you out, or tell someone what makes them awesome.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Donating to a charity – contribute money or give away things that you no longer want or need.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Being kind – smiling and being friendly, help someone before they ask.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Brainstorm ideas for random acts of kindness with the class that they could do.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity (10 minutes initially and a further 20 minutes later):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Create an ‘acts of kindness’ tree or similar, and set a challenge for the students to document the acts of kindness they have done that day/previous day on sticky notes/hand shapes/leaf shapes that are then stuck on the branches for an agreed period of time (such as a week) for example:</DefaultText>
                        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <View style={commonCardStyles.image}>
                                <RAKImage1 />
                            </View>
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief with students at the end of the challenge/activity to talk about how they felt performing an act of kindness, and about the impact they observed both to their mood and the receiver’s mood. Did they observe a ripple effect, in that the receiver of a random act of kindness then did something kind for someone else?</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>* Extension activity - Hand out the Random Acts of Kindness Board activity sheet and encourage students to complete all 16 activities within a week:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own device, ask them to find this switch in the Switch4Schools app.</DefaultText>
                    
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 10, color: DecidaColors.Gray }}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Coloured poster paper</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Sticky notes/cards with glue</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Random act of kindness worksheet' link={Worksheets.randomActOfKindness.uri}/></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanText}>Acts of kindness and compassion-</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.healthdirect.gov.au/acts-of-kindness-and-compassion' link='https://www.healthdirect.gov.au/acts-of-kindness-and-compassion' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Kindness Video: The World We Make -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.youtube.com/watch?v=4AKNW9fGLig' link='https://www.youtube.com/watch?v=4AKNW9fGLig' />

                        <DefaultText style={CommonStyles.lessonPlanText}>You’re your own tree collage -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.nca.gov.au/sites/default/files/NCE_LG_CbrsTrees_Instructions_FA_0.pdf' link='https://www.nca.gov.au/sites/default/files/NCE_LG_CbrsTrees_Instructions_FA_0.pdf' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"It doesn’t always have to be complex - here are some simple ideas to choose from:"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Clean your desk without being asked"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Let someone have a turn before you"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Ask someone playing alone to play with you"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Cheer up a friend in need of cheering up"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Compliment 5 people today"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Write a thank-you note to someone"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Help someone before they ask you"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Share a smile with someone who needs it"} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Random+Act+of+Kindness.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Happy]
}

