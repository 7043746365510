import gql from "graphql-tag"

export const schoolAdminClassFields = `
    id
    name
    schoolID
    classGroupID
    emotionIntensityLevel
    toggleRequestForChat
    toggleRequestForChatNotifications
    toggleIndividualStudentStats
    bulkSignUpId
    createdAt
    updatedAt
    primaryContact
    archived
    emailReceivers
    goMode
    lastCheckInTime
    wellbeing
    classClassLoginId
    toggleEating
    _version
    _deleted
    _lastChangedAt
    __typename
`

export const schoolAdminGetClassQuery = gql(`query GetClass($id: ID!) {
    getClass(id: $id) {
      ${schoolAdminClassFields}
    }
  }
`)

export type SchoolAdminGetClassQueryResponse = {
    getClass?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
  };