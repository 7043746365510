import { useReactiveVar } from '@apollo/client'
import { StyleSheet, View, ViewStyle, useWindowDimensions } from 'react-native'
import { DefaultText } from '../common/default-text'
import { lowRes, useResponsive, windowWidthCondition } from '../common/use-responsive'
import { avatars } from '../icon/avatar/avatars'
import { DecidaColors } from "../../common/decida-colors"
import { useNonTeachingStaffState } from '../student/use-non-teaching-staff-state'
import AvatarImage from '../common/avatar-image'

const NonTeachingStaffProfileCard = () => {
    const { currentNonTeachingStaff } = useNonTeachingStaffState()
    const { scaleRatio } = useResponsive()
    const transformByRatioStyle: ViewStyle = { transform: [{ scale: lowRes ? scaleRatio : 1 }] }

    return (
        <View style={styles.container}>
            <DefaultText style={[styles.welcomeText, transformByRatioStyle, { marginTop: lowRes ? 100 : undefined, marginBottom: lowRes ? 0 : undefined }]}>Hello,{currentNonTeachingStaff?.cognitoUsername && ` ${currentNonTeachingStaff.cognitoUsername}!` || ''}</DefaultText>

            <AvatarImage avatar={currentNonTeachingStaff?.avatar as keyof typeof avatars} />

            <View style={styles.studentNameContainer}>
                {currentNonTeachingStaff?.email && (
                    <DefaultText style={styles.emailText}>{currentNonTeachingStaff?.email || ""}</DefaultText>
                )}
                <DefaultText style={styles.fullNameText}>{`${currentNonTeachingStaff?.firstName} ${currentNonTeachingStaff?.lastName}`}</DefaultText>
            </View>
        </View>
    )
}
export default NonTeachingStaffProfileCard
const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        marginTop: 20
    },
    welcomeText: {
        fontSize: 35,
        fontWeight: 'bold',
        color: DecidaColors.Gray
    },
    studentNameContainer: {
        alignItems: 'center',
        marginBottom: 20,
    },
    emailText: {
        color: DecidaColors.Gray,
    },
    fullNameText: {
        fontSize: 14,
        fontStyle: 'italic',
        color: DecidaColors.Gray,
        opacity: 0.8

    }
})