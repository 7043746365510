import { View, ViewStyle } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'

interface Props {
    completedStep?: boolean
}


export const ProgressDot = ({ completedStep = false }: Props) => {

    const backgroundStyle = {
        backgroundColor: completedStep ? DecidaColors.Green : DecidaColors.White,
        width: 10,
        height: 10,
        justifyContent: 'center',
        alignContent: 'center',
        borderRadius: 100,
        marginHorizontal: 3,
        borderColor: DecidaColors.Green,
        borderWidth: 2,
        marginTop: 15,

    } as ViewStyle

    return (
        <>
            <View style={backgroundStyle} />
            <View style={backgroundStyle} />
            <View style={backgroundStyle} />
            <View style={backgroundStyle} />
            <View style={backgroundStyle} />
            <View style={backgroundStyle} />
        </>)
}





