import { gql, useMutation } from '@apollo/client'
import { MaterialIcons } from '@expo/vector-icons'
import { useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { DeleteInvoiceMutation, DeleteInvoiceMutationVariables, Invoice } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { deleteInvoice } from '../../common/graphql/mutations'
import { showAlert } from '../common/universal-alert'
import { IconEdit } from '../icon/icon-edit'
import DefaultTextWithCustomLink from '../switches/common/default-text-with-custom-link'
import AdminSchoolInvoiceForm from './admin-school-invoice-form'

type Props = {
    invoice: Invoice
}

const AdminSchoolInvoice = ({ invoice }: Props) => {
    const [editMode, setEditMode] = useState(false)

    const [deleteInvoiceMutation] = useMutation<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>(gql`${deleteInvoice}`);

    const toggleEditMode = () => setEditMode(prev => !prev)

    const handleRemove = (invoice: Invoice) => () => {
        showAlert({
            title: "Remove invoice",
            message: `Are you sure you want to remove this invoice:  ${invoice.name}`,
            leftButtonText: "Cancel",
            rightButtonText: "Yes, please",
            onRightButtonPress: () => {
                deleteInvoiceMutation({
                    variables: {
                        input: {
                            id: invoice.id,
                            _version: invoice._version
                        }
                    }
                })
            }
        })
    }

    return (
        <View key={invoice?.id} style={styles.container}>
            {editMode ? (
                <AdminSchoolInvoiceForm invoice={invoice} schoolID={invoice.schoolID} onCancel={toggleEditMode} />
            ) : (
                <>
                    <DefaultTextWithCustomLink link={invoice?.url || ""} text={invoice?.name || ""} />
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Pressable onPress={toggleEditMode} style={styles.editIcon}>
                            <IconEdit />
                        </Pressable>
                        <MaterialIcons onPress={handleRemove(invoice)} name="delete-forever" size={30} color={DecidaColors.Red} />
                    </View>
                </>
            )}
        </View>
    )
}
export default AdminSchoolInvoice


const styles = StyleSheet.create({
    editIcon: {
        width: 24,
        height: 24,
    },
    container: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: 10
    },
})