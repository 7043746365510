import { StyleSheet, View } from 'react-native'
import { DefaultText } from '../../../common/default-text'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { HugHeader } from './src/hug-header'
import { HugIcon } from './src/hug-icon'
import { HugImage1 } from './src/hug-image1'
import { HugImage2 } from './src/hug-image2'
import { HugImage3 } from './src/hug-image3'
import { HugImage4 } from './src/hug-image4'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { useResponsive } from '../../../common/use-responsive'
import SwitchCardJuniorContentTwoColumns from '../../common/switch-card-junior-two-columns'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import VideoCard from '../../../common/video-card'

export const SwitchesEmotionsSadHug: CardTemplate = {
    cardId: "952d7c6d-524f-458c-9ce2-69ea8395e0eb",
    title: 'Hug',
    Header: HugHeader,
    Icon: HugIcon,
    lessonPlan: true,
    mainContainerStyle: {
        paddingHorizontal: 20,
    },
    PerfectFor: () => {
        const { isNarrowScreen } = useResponsive()
        const Step1 = () => {
            return (
                <View style={{ flex: 1 }}>
                    <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                    <DefaultTextWithoutArrowsItallic textdata='Feeling comfort and safety when sad' />


                    <View style={styles.rowContainer}>
                        <DefaultText style={styles.contentText}>Find someone (like a parent or friend) or</DefaultText>
                        <DefaultText style={styles.contentText}>something (like a teddy bear or pillow) that</DefaultText>
                        <DefaultText style={styles.contentText}>you love and feels comfortable.</DefaultText>

                        <View style={styles.imageContainerScaled}>
                            <HugImage1 />
                        </View>

                    </View>

                </View>

            )
        }

        const Step2 = () => {
            return (
                <View style={styles.step1Container}>
                    <SwitchCardJuniorContentTwoColumns
                        LeftContent={() => <HugImage2 />}
                        RightContent={() => (
                            <DefaultText style={styles.contentText}>If it is a person, you may need to ask them if it’s ok that they give you a hug.</DefaultText>
                        )}
                    />
                </View>
            )
        }

        const Step3 = () => {
            return (
                <>
                    <SwitchCardJuniorContentTwoColumns
                        LeftContent={() => (
                            <>
                                <DefaultText style={styles.contentText}>Allow yourself time to simply rest in the hug.</DefaultText>
                                <DefaultTextWithoutArrows textdata='' />
                                <DefaultText style={styles.contentText}>Take notice of how you feel. </DefaultText>
                                <DefaultTextWithoutArrows textdata='' />
                                <DefaultText style={styles.contentText}>Enjoy the safety and warmth you feel through the hug.</DefaultText>
                            </>
                        )}
                        RightContent={() => <HugImage3 />}
                    />
                </>
            )
        }

        const Step4 = () => {
            return (
                <View style={styles.step1Container}>
                    <SwitchCardJuniorContentTwoColumns
                        LeftContent={() => <HugImage4 />}
                        RightContent={() => (
                            <>
                                <DefaultText>
                                    When you feel ok again, think about something that you need to do and set off to complete the task with energy.
                                </DefaultText>
                                <WhyDoesItWorkComponent message={"Hugs can feel good for a number of reasons. One reason is that they can release oxytocin, a hormone that is often referred to as the “love hormone” or the “cuddle hormone.” Oxytocin is produced in the brain and is involved in bonding, social attachment, and feelings of trust and love. \n \n When we hug someone or are hugged by someone, our brains release oxytocin, which can help to reduce stress and anxiety, lower blood pressure, and increase feelings of happiness and well-being. Additionally, hugs can stimulate the production of endorphins, which are chemicals that help to reduce pain and increase feelings of pleasure. Finally, hugs can simply be a comforting and enjoyable physical sensation."} />
                            </>
                        )}
                    />
                </View>
            )
        }

        const contents = [
            { Component: Step1, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/hug-pg1.mp3' },
            { Component: Step2, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/hug-pg2.mp3' },
            { Component: Step3, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/hug-pg3.mp3' },
            { Component: Step4, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/hug-pg4.mp3' },
        ]

        return (
            <>
                <SwitchCardJuniorContent contents={contents} scheme={Emotion.SAD} />
            </>
        )
    },
    Images: undefined,
    CardContent: () => (
        <>
        </>
    )
    ,
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: "",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify and describe the benefits of hugging.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Demonstrate appropriate ways to hug.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 25 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by asking the students if they like to hug.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a discussion on what a hug is and why people might hug.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Instruction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show the students pictures (see link in the materials section) of people hugging and discuss the different ways that people hug (e.g. a hug from the side, a hug from behind, a hug with a pat on the back).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Call on a volunteer or use a toy to demonstrate appropriate ways to hug and encourage the students to listen to their own comfort level and personal space.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students practice asking for permission and giving and receiving hugs with a partner or using the stuffed animals or dolls.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Review the benefits of hugging and encourage the students to hug their friends and family members when they need a little extra love and support.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Space to move/stretch</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Picture of hugs' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Hug.pptx' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Soft toys/dolls</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What Are the Benefits of Hugging? -</DefaultText>
                        <DefaultTextWithLink text='https://www.healthline.com/health/hugging-benefits' />

                        <DefaultText style={CommonStyles.lessonPlanText}>20 Amazing Benefits Of Hugging According To Science - </DefaultText>
                        <DefaultTextWithLink text='https://www.generationnext.com.au/2017/05/20-amazing-benefits-hugging-according-science/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>• Different Types Of Hugs -</DefaultText>
                        <View style={CommonStyles.lessonPlanResourceVideoContainer}>
                            <VideoCard video={{
                                id: "1",
                                url: "https://www.youtube.com/embed/njkm4700qm8"
                            }} />
                        </View>
                        
                        <DefaultText style={CommonStyles.lessonPlanText}>• Babies hugging babies -</DefaultText>
                        <View style={CommonStyles.lessonPlanResourceVideoContainer}>
                            <VideoCard video={{
                                id: "1",
                                url: "https://www.youtube.com/embed/YMmzc_WhUmQ"
                            }} />
                        </View>

                        

                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual, CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Sad]
}

const styles = StyleSheet.create({
    contentText: {
        // color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    imageContainerScaled: {
        width: '100%',
        height: 300,
        marginVertical: 20,
        paddingHorizontal: 20
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        marginHorizontal: 20
    },
    step1Container: {
        width: '100%',
        paddingHorizontal: 20,
        marginLeft: 5,
    },
})

