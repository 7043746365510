import { View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { RoleModelIcon } from '../../explore/src/role-model-icon'
import { RoleModelIconInverted } from '../../explore/src/role-model-icon-inverted'
import { RoleModelImage1 } from './src/role-model-image1'

export const SwitchesEmotionsScaredRoleModel: CardTemplate = {
    cardId: "acd6b401-40be-4784-ad7a-de883d4c685f",
    title: 'Role model',
    lessonPlan: true,
    Header: RoleModelIconInverted,
    Icon: RoleModelIcon,
    Images: () => (
        <>
            <RoleModelImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"In-the-moment overcoming of fear or delaying short-term benefit in order to receive longer term gain."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Bring to mind a mentor, coach or respected role model."} />
                <DefaultTextWithDownArrow textdata={"Think about what they would do in this circumstance or what their advice would be?"} />
                <DefaultTextWithoutArrows textdata={"Be the person you respect. Do what you think they would do."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: 'Creating cognitive distance by externalising gives perspective that can be clouded by emotion. Identifying with a role model focuses your attention on constructive problem solving that is driven more by context rather than emotion.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define the characteristics of a positive role model.
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify a personal positive role model for future reference and better decision making.
                        </DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Define the term "role model" as someone who serves as a good example of positive thoughts and behaviours to imitate.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Brainstorm with students different types of inspirational people, such as teachers, parents, characters from books or movies, famous individuals, and sports coaches.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Identifying Role Models (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the positive qualities that make someone a good role model.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the My Role Model worksheet and ask students to choose and list qualities of a person they consider a role model.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to think about both personal connections and public figures.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students complete the worksheet. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their work with the class. </DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Optional - Activity 2: Drawing Courage (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Being Courageous worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to draw something that requires courage and write about why courage is needed. Examples could include public speaking, making a new friend, or excelling in sports.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their drawings and explanations with the class.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: How Role Models Inspire Courage (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on how their chosen role models can inspire courage in challenging situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the ways in which observing positive qualities in role models can impact their own thoughts and behaviours when facing fears.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the importance of having positive role models.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to seek inspiration from their role models, especially in moments when courage is needed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students are using the Switch4Schools app independently, direct students to explore the relevant switch.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='My Role Model worksheet' link={Worksheets.myRoleModel.uri} /> </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Being Courageous worksheet' link={Worksheets.beingCouregous.uri} /> </DefaultText>


                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>We All Need Role Models to Motivate and Inspire Us -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.psychologytoday.com/au/blog/fulfillment-any-age/201311/we-all-need-role-models-motivate-and-inspire-us' link='https://www.psychologytoday.com/au/blog/fulfillment-any-age/201311/we-all-need-role-models-motivate-and-inspire-us' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Who inspires you? Why heroes, role models, and mentors matter | Dyan deNapoli | TEDxDrewMiddleSchool -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.youtube.com/watch?v=kZfEFzf4Jec' link='https://www.youtube.com/watch?v=kZfEFzf4Jec' />


                        <DefaultText style={CommonStyles.lessonPlanText}>What is a Role Model? Five Qualities that Matter to Youth -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.rootsofaction.com/role-model/' link='https://www.rootsofaction.com/role-model/' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='A role model is someone that is inspirational that behaves in a way that is preferred with qualities that are admired.' />
            <DefaulTextWithTipsIcon textdata='Examples of a mentor could be a:' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation style={{ marginTop: -20 }} filledArrow={true} textdata={"parent or family member. "} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"teacher or someone at school."} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"person from church or community group. "} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"successful athlete, actor or artist."} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"sports coach or music tutor."} />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Role+Model.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Scared]
}

