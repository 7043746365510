import gql from "graphql-tag";
import { SchoolStatus } from "../../../../common/API";

export const schoolFields = `
  id
  name
  licenseTotal
  trialEndDate
  renewalDate
  notes
  billingAddress
  billingNotes
  invoiceUrl
  coordinatorID
  abn
  schoolAdminUI
  wholeSchoolSubscription
  createdAt
  updatedAt
  wellbeingFeature
  __typename
  _version
  _deleted
  _lastChangedAt
`

export const getSchoolQuery = /* GraphQL */ gql`
query GetSchool($id: ID!) {
  getSchool(id: $id) {
    ${schoolFields}
  }
}
`;

export type GetSchoolQueryResponse = {
  getSchool: {
    id: string,
    name: string,
    licenseTotal?: string | null,
    timezone?: string | null,
    status?: SchoolStatus | null,
    activeStudents?: number | null,
    trialEndDate?: string | null,
    renewalDate?: string | null,
    notes?: string | null,
    billingAddress?: string | null,
    billingNotes?: string | null,
    invoiceUrl?: string | null,
    principalName?: string | null,
    schoolAdminUI?: string | null,
    abn?: string | null,
    wholeSchoolSubscription?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  }
}