import { useReactiveVar } from '@apollo/client'
import { StyleSheet, View } from 'react-native'
import { rvFilterSwitchesData } from './common-state'
import { CommonStyles } from './const'
import { DefaultText } from './default-text'
import { SwitchCardFilterMethods } from './switch-card-filter-methods'
import { DecidaColors } from '../../common/decida-colors'

type Props = {
    title: string
    onSelectAll: () => void
    filterType: "video" | "explore" | "activity_sheets"
}

const SwitchCardFilterGroupTitle = ({ title, onSelectAll, filterType }: Props) => {
    const filters = useReactiveVar(rvFilterSwitchesData)
    const { isAllParameterVideoChecked, isAllParameterExploreChecked, isAllCategoryExploreChecked, isAllCategoryVideoChecked, isAllTypeChecked, isAllExploreEmotionChecked, isAllVideoEmotionChecked} = SwitchCardFilterMethods.isAllFilterGroupChecked(filters)
    const isAllParameterChecked = filterType === "video" ? isAllParameterVideoChecked : isAllParameterExploreChecked
    const isAllCategoryChecked = filterType === "video" ? isAllCategoryVideoChecked : isAllCategoryExploreChecked
    const isAllEmotionChecked = filterType === "video" ? isAllVideoEmotionChecked : isAllExploreEmotionChecked

    const checkedSelectAllByGroupTitle = () => {
        switch (title) {
            case "Switch type":
                return Boolean(isAllTypeChecked)
            case "Parameters":
                return Boolean(isAllParameterChecked)
            case "Emotion":
                return Boolean(isAllEmotionChecked)
            case "Category":
                return Boolean(isAllCategoryChecked)
            default:
                return false
        }
    }

    return (
        <View style={[CommonStyles.flexRowSpaceBetween, styles.filterGroupTitle]}>
            <DefaultText style={styles.filterGroupTitle}>{title}</DefaultText>
        </View>
    )
}
export default SwitchCardFilterGroupTitle
const styles = StyleSheet.create({
    filterGroupTitle: {
        marginBottom: 5,
        alignItems: 'center',
        fontSize: 24,
        fontWeight: 'bold',
        color: DecidaColors.Gray
    },
    row: {
        alignItems: 'center',
        flexDirection: 'row'
    }, 
    selectAllText: {
        color: DecidaColors.Gray
    }
})