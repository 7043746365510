import { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { DefaultText } from '../common/default-text'
import { StudentJoinClassBreadCrumbs } from './student-join-class-breadcrumbs'
import { BackButton } from '../common/back-button'
import { StackScreenProps } from '@react-navigation/stack'
import { StudentJoinClassStackNavigatorParamList } from './login-route-param-types'
import { ScreenNames } from '../common/screen-names'
import { CommonStyles } from '../common/const'
import { avatars } from '../icon/avatar/avatars'
import { Dimensions } from 'react-native';
import { showAlert } from '../common/universal-alert'
import _ from 'lodash'
import { InputCognitoUsername } from '../common/input-cognito-username'
import InputEmailStudents from '../common/input-email-students'
import { ScrollView } from 'react-native'
import { FirstNameInput } from '../common/first-name-input'
import { LastNameInput } from '../common/last-name-input'
import { DecidaColors } from '../../common/decida-colors'

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600

export const StudentJoinClassSetDetails = ({ route: { params: { avatarName, bulkSignUpId, className } }, navigation: { navigate } }: StackScreenProps<StudentJoinClassStackNavigatorParamList, 'studentJoinClassSetDetails'>,) => {
    const goToStudentJoinClassPickAvatar = () => {
        navigate(ScreenNames.studentJoinClassPickAvatar, { bulkSignUpId, className })
    }

    const [nickname, setNickname] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [usernameUnavalible, setUsernameUnavalible] = useState<boolean>(false)
    const [emailExists, setEmailExists] = useState(false)
    const [checkingUsername, setcheckingUsername] = useState(false)
    const isNextButtonDisabled = emailExists || checkingUsername || nickname.length < 3 || usernameUnavalible

    const navigateToReviewDetails = () => {
        if (nickname === '') {
            showAlert({
                title: `Nickname field is empty.`,
                message: 'Please enter data for nickname.',
                leftButtonText: 'ok',
            })
        }
        else if (firstName === '') {
            showAlert({
                title: `First Name field is empty.`,
                message: 'Please enter data for firstname.',
                leftButtonText: 'ok',
            })
        }
        else if (lastName === '') {
            showAlert({
                title: `Last Name field is empty`,
                message: 'Please enter data for lastname.',
                leftButtonText: 'ok',
            })
        }
        else
            navigate(ScreenNames.studentJoinClassReviewDetails, { bulkSignUpId, avatarName, nickname, firstName, lastName, email, className })
    }

    const emailValidation = (value: string) => {
        if (value.search(/[^0-9a-zA-Z_.@]/) < 0) {
            setEmail(value)
        }
    }

    const Avatar = avatars[avatarName]

    return (
        <View style={styles.container}>
            <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollView} >
                <View>
                    <BackButton onPress={goToStudentJoinClassPickAvatar} />
                    <DefaultText style={styles.title}>Add Details</DefaultText>
                </View>
                <StudentJoinClassBreadCrumbs step={2} />
                <ScrollView style={CommonStyles.inputScrollView} contentContainerStyle={CommonStyles.flexContainer}>
                    <View style={styles.inputContainer}>
                        <View style={styles.avatarContainer}>
                            <Avatar />
                        </View>
                    </View>
                    <View style={styles.inputContainer}>
                        <InputCognitoUsername
                            userExists={usernameUnavalible}
                            setUserExists={setUsernameUnavalible}
                            value={nickname || ""}
                            onChangeText={setNickname}
                            title="Nickname"
                            onFetching={setcheckingUsername}
                        />
                        <FirstNameInput value={firstName} onChangeText={setFirstName} />
                        <LastNameInput value={lastName} onChangeText={setLastName}/>
                        <InputEmailStudents
                            value={email}
                            onChangeText={emailValidation}
                            bulkSignUpId={bulkSignUpId}
                            emailExists={emailExists}
                            setEmailExists={setEmailExists}
                        />
                        <TouchableOpacity disabled={isNextButtonDisabled} testID='button-submit-student' style={[styles.joinButton, { backgroundColor: isNextButtonDisabled ? DecidaColors.Gray : undefined }]} onPress={navigateToReviewDetails}>
                            <DefaultText style={styles.buttonText}>Next</DefaultText>
                        </TouchableOpacity>
                    </View>
                </ScrollView>
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },

    backButtonContainer: {
        flex: 1,
        padding: 5,
    },
    title: {
        fontSize: 30,
        padding: 20,
        alignSelf: 'center',
    },
    avatarContainer: {
        marginVertical: 50,
        width: windowWidthcondition ? 200 : 150,
        height: windowWidthcondition ? 200 : 150,
    },
    inputContainer: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    joinButton: {
        backgroundColor: DecidaColors.Green,
        padding: 20,
        height: 50,
        width: 150,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
    },
    buttonText: {
        color: DecidaColors.White

    },
    heading: {
        fontSize: 17,
        fontWeight: 'bold',
        paddingTop: 20,

    },
    listScrollViewPending: {
        maxWidth: '100%',
        borderColor: DecidaColors.Orange,
        borderWidth: 1,
        borderRadius: 20,
        minHeight: 100,
        marginBottom: 10,

    },
    scrollView: {
        height: '100%'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',

    },
    iconContainer: {
        position: 'absolute',
        right: 10,
    },
    usernameTakenBox: {

    }
})