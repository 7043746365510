import { View } from 'react-native'
import { CardTemplate, CardType } from '../../common/card-template'
import { CardColorScheme } from '../../common/card-color-scheme'
import { Dimensions } from 'react-native';
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { TheLittleBookOfBigEmotionsIcon } from '../icons/the-little-book-of-big-emotions-icon';

const windowWidth = Dimensions.get('window').width;

export const ToolsTheLittleBookOfBigEmotions: CardTemplate = {
    cardId: "4b5182e0-db9d-4cc6-95c4-3462737e9edb",
    title: 'The Little Book of Big Emotions',
    Header: TheLittleBookOfBigEmotionsIcon,
    Icon: TheLittleBookOfBigEmotionsIcon,
    HeaderTitle: 'Emotion cards',
    PerfectFor: () => <View />,
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>

        </>
    ),
    openUrl: "https://simpleflipbook.hflip.co/c928e6eae6.html#page/1",
    colorScheme: CardColorScheme.TOOL,
    whyDoesItWorkText: '',
    type: [CardType.Tool],
    time: [],
    people: [],
    place: [],
    category: [],
    action: [],
    level: []
}

