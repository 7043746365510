
import { Platform } from 'react-native'
import Svg, { G, Path, Text, TSpan } from 'react-native-svg'
import { Emotion } from '../../models'

interface Props {
    onPress: (emotion: Emotion) => void
}

export const EmotionCircle3Point = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressHappy = {
        [prop]: () => onPress(Emotion.HAPPY)
    }
    const onPressExcited = {
        [prop]: () => onPress(Emotion.EXCITED)
    }
    const onPressScared = {
        [prop]: () => onPress(Emotion.SCARED)
    }
    const onPressSad = {
        [prop]: () => onPress(Emotion.SAD)
    }
    const onPressAngry = {
        [prop]: () => onPress(Emotion.ANGRY)
    }
    const onPressAnxious = {
        [prop]: () => onPress(Emotion.ANXIOUS)
    }

    return (
        <Svg viewBox="0 0 661.21 826.33">
            <G {...onPressAngry} transform="translate(-42.39 -109.292)">
                <Path d="M70.863,121.933-96.4-163.53l314.2-2.351S227.534,25.463,70.863,121.933" transform="translate(469.405 684.126)" fill="#f6bbbd" />
                <Path d="M53.726,92.445-73.091-123.983l238.219-1.782s7.383,145.07-111.4,218.21" transform="translate(446.089 644.36)" fill="#ee787c" />
                <Path d="M33.353,57.391-45.375-76.969l147.886-1.107S107.1,11.986,33.353,57.391" transform="translate(418.372 597.086)" fill="#e31e25" />
                <Text transform="translate(419.245 561.301)" fill="#fff" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Furious</TSpan></Text>
                <Text transform="translate(579.457 662.318)" fill="#595b60" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Annoyed</TSpan></Text>
                <Text transform="translate(499.333 617.024)" fill="#2b2e34" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Frustrated</TSpan></Text>
                <Path d="M18.381,36.761A43.387,43.387,0,0,0,61.768-6.626,43.386,43.386,0,0,0,18.381-50.011,43.386,43.386,0,0,0-25.006-6.626,43.387,43.387,0,0,0,18.381,36.761" transform="translate(624.98 732.709)" fill="#fff" />
                <Path d="M18.381,36.761A43.387,43.387,0,0,0,61.768-6.626,43.386,43.386,0,0,0,18.381-50.011,43.386,43.386,0,0,0-25.006-6.626,43.387,43.387,0,0,0,18.381,36.761Z" transform="translate(624.98 732.709)" fill="none" stroke="#e31e25" strokeWidth="3.013" />
                <Path d="M2.893,5.786A6.829,6.829,0,0,0,9.722-1.044,6.828,6.828,0,0,0,2.893-7.871,6.828,6.828,0,0,0-3.936-1.044,6.829,6.829,0,0,0,2.893,5.786" transform="translate(619.948 716.69)" fill="#ff2d16" />
                <Path d="M2.893,5.786A6.829,6.829,0,0,0,9.722-1.044,6.828,6.828,0,0,0,2.893-7.871,6.828,6.828,0,0,0-3.936-1.044,6.829,6.829,0,0,0,2.893,5.786" transform="translate(654.071 716.69)" fill="#ff2d16" />
                <Path d="M0,4.225s17.437-22.093,41.4-.514" transform="translate(619.018 739.372)" fill="none" stroke="#ff2d16" strokeWidth="3.013" />
                <Path d="M.056,0S-1.213,5.151,4.138,3.279" transform="translate(616.935 741.929)" fill="#ff2d16" />
                <Path d="M0,1.655S5.077,3.192,3.489-2.251" transform="translate(658.661 743.362)" fill="#ff2d16" />
                <Path d="M0,0,15.175,6.272" transform="translate(615.254 700.261)" fill="none" stroke="#ff2d16" strokeWidth="3.013" />
                <Path d="M1.3,0S-4,.246-.682,4.846" transform="translate(614.969 697.846)" fill="#ff2d16" />
                <Path d="M0,2.053s5.3-.246,1.985-4.846" transform="translate(629.43 706.896)" fill="#ff2d16" />
                <Path d="M0,2.657,15.175-3.615" transform="translate(649.368 703.877)" fill="none" stroke="#ff2d16" strokeWidth="3.013" />
                <Path d="M1.3,2.053S-4,1.807-.682-2.793" transform="translate(649.083 706.896)" fill="#ff2d16" />
                <Path d="M0,0S5.3.246,1.985,4.846" transform="translate(663.544 697.846)" fill="#ff2d16" />
                <Path d="M4.4,0a10.393,10.393,0,0,0,0,20.786H85.862A10.393,10.393,0,0,0,85.862,0Z" transform="translate(598.229 771.352)" fill="#fff" />
                <Text transform="translate(613.44 786.966)" fill="#e31e25" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">ANGRY</TSpan></Text>
                <Path d="M4.4,0a10.393,10.393,0,0,0,0,20.786H85.862A10.393,10.393,0,0,0,85.862,0Z" transform="translate(598.229 771.352)" fill="none" stroke="#e31e25" strokeWidth="1.808" />
            </G>
            <G {...onPressSad} transform="translate(-42.39 -109.292)">
                <Path d="M0,121.838l163.583-287.59L322.721,105.181S161.881,209.285,0,121.838" transform="translate(209.77 683.999)" fill="#c2daee" />
                <Path d="M0,92.373l124.024-218.04L244.676,79.745S122.733,158.673,0,92.373" transform="translate(249.243 644.263)" fill="#86b6dd" />
                <Path d="M0,57.346,77-78.015,151.9,49.507S76.193,98.5,0,57.346" transform="translate(296.169 597.026)" fill="#3685c6" />
                <Text transform="translate(306.971 807.749)" fill="#595b60" fontSize="21" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Disappointed</TSpan></Text>
                <Text transform="translate(338.368 631.652)" fill="#fff" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Despair</TSpan></Text>
                <Text transform="translate(330.162 719.846)" fill="#42454a" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Hopeless</TSpan></Text>
                <Path d="M18.381,36.761A43.387,43.387,0,0,0,61.768-6.626,43.386,43.386,0,0,0,18.381-50.011,43.386,43.386,0,0,0-25.006-6.626,43.387,43.387,0,0,0,18.381,36.761" transform="translate(354.907 875.289)" fill="#fff" />
                <Path d="M18.381,36.761A43.387,43.387,0,0,0,61.768-6.626,43.386,43.386,0,0,0,18.381-50.011,43.386,43.386,0,0,0-25.006-6.626,43.387,43.387,0,0,0,18.381,36.761Z" transform="translate(354.907 875.289)" fill="none" stroke="#3685c6" strokeWidth="3.013" />
                <Path d="M2.893,5.786A6.829,6.829,0,0,0,9.722-1.044,6.828,6.828,0,0,0,2.893-7.871,6.828,6.828,0,0,0-3.936-1.044,6.829,6.829,0,0,0,2.893,5.786" transform="translate(350.92 865.542)" fill="#3685c6" />
                <Path d="M2.893,5.786A6.829,6.829,0,0,0,9.722-1.044,6.828,6.828,0,0,0,2.893-7.871,6.828,6.828,0,0,0-3.936-1.044,6.829,6.829,0,0,0,2.893,5.786" transform="translate(385.043 865.542)" fill="#3685c6" />
                <Path d="M0,4.225s17.437-22.093,41.4-.514" transform="translate(349.99 888.225)" fill="none" stroke="#3685c6" strokeWidth="3.013" />
                <Path d="M.056,0S-1.213,5.151,4.138,3.279" transform="translate(347.908 890.781)" fill="#3685c6" />
                <Path d="M0,1.655S5.077,3.192,3.489-2.251" transform="translate(389.633 892.215)" fill="#3685c6" />
                <Path d="M4.4,0a10.393,10.393,0,0,0,0,20.786H85.862A10.393,10.393,0,0,0,85.862,0Z" transform="translate(328.155 913.932)" fill="#fff" />
                <Text transform="translate(355.01 929.545)" fill="#3685c6" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.033em"><TSpan x="0" y="0">SAD</TSpan></Text>
                <Path d="M4.4,0a10.393,10.393,0,0,0,0,20.786H85.862A10.393,10.393,0,0,0,85.862,0Z" transform="translate(328.155 913.932)" fill="none" stroke="#3685c6" strokeWidth="1.808" />
            </G>
            <G {...onPressScared} transform="translate(-42.39 -109.292)">
                <Path d="M0,1.268,330.845-1.725,176.5,271.963S5.691,185.17,0,1.268" transform="translate(42.39 519.972)" fill="#efd9c2" />
                <Path d="M0,.961,250.836-1.308l-117.023,207.5S4.315,140.389,0,.961" transform="translate(122.342 519.904)" fill="#e0b486" />
                <Path d="M0,.6,155.72-.812,83.071,128.006S2.679,87.154,0,.6" transform="translate(217.388 519.823)" fill="#cc8236" />
                <Text transform="translate(150.063 616.322)" fill="#42454a" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Threatened</TSpan></Text>
                <Text transform="translate(242.489 559.917)" fill="#fff" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Terrified</TSpan></Text>
                <Text transform="translate(84.231 532.226)" fill="#42454a" fontSize="8" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">©2022 Decida Group Pty. Ltd</TSpan></Text>
                <Text transform="translate(86.569 662.326)" fill="#42454a" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Hesitant</TSpan></Text>
                <Path d="M18.381,36.761A43.387,43.387,0,0,0,61.768-6.626,43.386,43.386,0,0,0,18.381-50.011,43.386,43.386,0,0,0-25.006-6.626,43.387,43.387,0,0,0,18.381,36.761" transform="translate(84.219 732.212)" fill="#fff" />
                <Path d="M18.381,36.761A43.387,43.387,0,0,0,61.768-6.626,43.386,43.386,0,0,0,18.381-50.011,43.386,43.386,0,0,0-25.006-6.626,43.387,43.387,0,0,0,18.381,36.761Z" transform="translate(84.219 732.212)" fill="none" stroke="#cc8236" strokeWidth="3.013" />
                <Path d="M2.893,5.786A6.829,6.829,0,0,0,9.722-1.044,6.828,6.828,0,0,0,2.893-7.871,6.828,6.828,0,0,0-3.936-1.044,6.829,6.829,0,0,0,2.893,5.786" transform="translate(86.505 715.147)" fill="#cc8236" />
                <Path d="M2.893,5.786A6.829,6.829,0,0,0,9.722-1.044,6.828,6.828,0,0,0,2.893-7.871,6.828,6.828,0,0,0-3.936-1.044,6.829,6.829,0,0,0,2.893,5.786" transform="translate(120.629 715.147)" fill="#cc8236" />
                <Path d="M7.945,0s-6.2,6.7-18.753,10.946" transform="translate(86.912 701.199)" fill="none" stroke="#cc8236" strokeWidth="3.013" />
                <Path d="M.541,0s-3.8,3.7,1.725,4.945" transform="translate(74.733 709.676)" fill="#cc8236" />
                <Path d="M1.628,1.624S3.252-3.427-2.215-1.933" transform="translate(95.132 701.329)" fill="#cc8236" />
                <Path d="M0,0S6.2,6.7,18.753,10.946" transform="translate(116.965 701.199)" fill="none" stroke="#cc8236" strokeWidth="3.013" />
                <Path d="M.731,0s3.8,3.7-1.725,4.945" transform="translate(135.816 709.676)" fill="#cc8236" />
                <Path d="M.1,1.624S-1.528-3.427,3.939-1.933" transform="translate(114.965 701.329)" fill="#cc8236" />
                <Path d="M0,1.061s.753-2.5,5.018-2.5S9.786,2.064,14.8,2.064s4.768-3.507,9.033-3.507,5.018,3.507,9.786,3.507,4.516-3.507,9.033-3.507,4.893,2.5,5.519,2.5" transform="translate(82.133 733.538)" fill="none" stroke="#cc8236" strokeWidth="3.013" />
                <Path d="M0,0S.569,5.275,4.959,1.686" transform="translate(79.63 733.848)" fill="#cc8236" />
                <Path d="M.877.872c1.3.46,2.49.448,2.658-.028S2.785-.389,1.486-.849-1-1.3-1.173-.821-.423.412.877.872" transform="translate(81.033 734.656)" fill="#cc8236" />
                <Path d="M.256,1.146a1.66,1.66,0,0,0,2.32.191A1.659,1.659,0,0,0,2.345-.979,1.66,1.66,0,0,0,.025-1.17,1.659,1.659,0,0,0,.256,1.146" transform="translate(129.445 734.129)" fill="#cc8236" />
                <Path d="M2.1,0S1.532,5.275-2.858,1.686" transform="translate(131.076 734.646)" fill="#cc8236" />
                <Path d="M.656,0-.892.968.477,3.16l1.549-.966Z" transform="translate(131.151 732.491)" fill="#cc8236" />
                <Path d="M4.4,0a10.393,10.393,0,0,0,0,20.786H85.862A10.393,10.393,0,0,0,85.862,0Z" transform="translate(57.468 771.352)" fill="#fff" />
                <Text transform="translate(68.045 786.966)" fill="#cc8236" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">SCARED</TSpan></Text>
                <Path d="M4.4,0a10.393,10.393,0,0,0,0,20.786H85.862A10.393,10.393,0,0,0,85.862,0Z" transform="translate(57.468 771.352)" fill="none" stroke="#cc8236" strokeWidth="1.808" />
            </G>
            <G {...onPressExcited} transform="translate(-42.39 -109.292)">
                <Path d="M62.3,0,229.568,285.463l-314.2,2.351S-94.371,96.469,62.3,0" transform="translate(142.553 233.322)" fill="#c3ddd7" />
                <Path d="M47.234,0,174.051,216.428-64.168,218.21S-71.55,73.14,47.234,0" transform="translate(198.282 302.576)" fill="#86bbb0" />
                <Path d="M29.323,0l78.728,134.36-147.886,1.107S-44.419,45.4,29.323,0" transform="translate(264.532 384.904)" fill="#378f7b" />
                <Text transform="translate(261.409 497.46)" fill="#fff" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Hyper</TSpan></Text>
                <Text transform="translate(153.372 452.703)" fill="#42454a" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Energetic</TSpan></Text>
                <Text transform="translate(73.466 407.355)" fill="#595b60" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Expectant</TSpan></Text>
                <Path d="M18.381,36.761A43.387,43.387,0,0,0,61.768-6.626,43.386,43.386,0,0,0,18.381-50.011,43.386,43.386,0,0,0-25.006-6.626,43.387,43.387,0,0,0,18.381,36.761" transform="translate(84.219 314.457)" fill="#fff" />
                <Path d="M18.381,36.761A43.387,43.387,0,0,0,61.768-6.626,43.386,43.386,0,0,0,18.381-50.011,43.386,43.386,0,0,0-25.006-6.626,43.387,43.387,0,0,0,18.381,36.761Z" transform="translate(84.219 314.457)" fill="none" stroke="#378f7b" strokeWidth="3.013" />
                <Path d="M2.893,5.786A6.829,6.829,0,0,0,9.722-1.044,6.828,6.828,0,0,0,2.893-7.871,6.828,6.828,0,0,0-3.936-1.044,6.829,6.829,0,0,0,2.893,5.786" transform="translate(82.324 296.346)" fill="#378f7b" />
                <Path d="M2.893,5.786A6.829,6.829,0,0,0,9.722-1.044,6.828,6.828,0,0,0,2.893-7.871,6.828,6.828,0,0,0-3.936-1.044,6.829,6.829,0,0,0,2.893,5.786" transform="translate(116.447 296.346)" fill="#378f7b" />
                <Path d="M0,0S17.63,21.94,41.4.153" transform="translate(81.348 318.095)" fill="none" stroke="#378f7b" strokeWidth="3.013" />
                <Path d="M.06,1.571S-1.253-3.569,4.113-1.745" transform="translate(79.275 318.211)" fill="#378f7b" />
                <Path d="M0,.09S5.065-1.49,3.522,3.965" transform="translate(120.978 316.239)" fill="#378f7b" />
                <Path d="M7.945,0s-6.2,6.7-18.753,10.946" transform="translate(83.602 273.709)" fill="none" stroke="#378f7b" strokeWidth="3.013" />
                <Path d="M.541,0s-3.8,3.7,1.725,4.945" transform="translate(71.424 282.186)" fill="#378f7b" />
                <Path d="M1.628,1.624S3.252-3.427-2.215-1.933" transform="translate(91.822 273.839)" fill="#378f7b" />
                <Path d="M0,0S6.2,6.7,18.753,10.946" transform="translate(113.655 273.709)" fill="none" stroke="#378f7b" strokeWidth="3.013" />
                <Path d="M.731,0s3.8,3.7-1.725,4.945" transform="translate(132.506 282.186)" fill="#378f7b" />
                <Path d="M.1,1.624S-1.528-3.427,3.939-1.933" transform="translate(111.655 273.839)" fill="#378f7b" />
                <Path d="M0,0H42.153" transform="translate(80.917 318.165)" fill="none" stroke="#378f7b" strokeWidth="2.41" />
                <Path d="M4.4,0a10.393,10.393,0,0,0,0,20.786H85.862A10.393,10.393,0,0,0,85.862,0Z" transform="translate(57.468 352.306)" fill="#fff" />
                <Text transform="translate(67.316 367.919)" fill="#378f7b" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">EXCITED</TSpan></Text>
                <Path d="M4.4,0a10.393,10.393,0,0,0,0,20.786H85.862A10.393,10.393,0,0,0,85.862,0Z" transform="translate(57.468 352.306)" fill="none" stroke="#378f7b" strokeWidth="1.808" />
            </G>
            <G {...onPressAnxious} transform="translate(-42.39 -109.292)">
                <Path d="M140.163,114.68l-330.845,2.993L-36.333-156.015s170.8,86.793,176.5,270.7" transform="translate(563.437 403.46)" fill="#ece6c0" />
                <Path d="M106.267,86.947l-250.836,2.269,117.023-207.5s129.5,65.8,133.813,205.232" transform="translate(517.38 431.569)" fill="#dbcd82" />
                <Path d="M65.971,53.977l-155.72,1.409L-17.1-73.432S63.292-32.58,65.971,53.977" transform="translate(462.63 464.984)" fill="#c3ac2e" />
                <Text transform="translate(388.822 497.79)" fill="#fff" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="5" y="0">Overwhelmed</TSpan></Text>
                <Text transform="translate(586.747 407.347)" fill="#42454a" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Concerned</TSpan></Text>
                <Text transform="translate(513.463 449.592)" fill="#42454a" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Worried</TSpan></Text>
                <Path d="M18.381,36.761A43.387,43.387,0,0,0,61.768-6.626,43.386,43.386,0,0,0,18.381-50.011,43.386,43.386,0,0,0-25.006-6.626,43.387,43.387,0,0,0,18.381,36.761" transform="translate(624.98 314.457)" fill="#fff" />
                <Path d="M18.381,36.761A43.387,43.387,0,0,0,61.768-6.626,43.386,43.386,0,0,0,18.381-50.011,43.386,43.386,0,0,0-25.006-6.626,43.387,43.387,0,0,0,18.381,36.761Z" transform="translate(624.98 314.457)" fill="none" stroke="#c3ac2e" strokeWidth="3.013" />
                <Path d="M2.6,5.207A6.146,6.146,0,0,0,8.749-.938,6.146,6.146,0,0,0,2.6-7.084,6.146,6.146,0,0,0-3.542-.938,6.146,6.146,0,0,0,2.6,5.207" transform="translate(624.057 299.741)" fill="#c3ac2e" />
                <Path d="M2.6,5.207A6.146,6.146,0,0,0,8.749-.938,6.146,6.146,0,0,0,2.6-7.084,6.146,6.146,0,0,0-3.542-.938,6.146,6.146,0,0,0,2.6,5.207" transform="translate(658.18 299.741)" fill="#c3ac2e" />
                <Path d="M2.6,0a6.147,6.147,0,0,0,0,12.295H19.667A6.147,6.147,0,1,0,19.667,0Z" transform="translate(632.226 318.788)" fill="none" stroke="#c3ac2e" strokeWidth="3.013" />
                <Path d="M4.4,0a10.393,10.393,0,0,0,0,20.786H85.862A10.393,10.393,0,0,0,85.862,0Z" transform="translate(598.229 353.727)" fill="#fff" />
                <Text transform="translate(604.564 368.34)" fill="#c3ac2e" fontSize="14" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">ANXIOUS</TSpan></Text>
                <Path d="M4.4,0a10.393,10.393,0,0,0,0,20.786H85.862A10.393,10.393,0,0,0,85.862,0Z" transform="translate(598.229 353.727)" fill="none" stroke="#c3ac2e" strokeWidth="1.808" />
            </G>
            <G {...onPressHappy} transform="translate(-42.39 -109.292)">
                <Path d="M136.721,14.816-26.862,302.406-186,31.472s160.84-104.1,322.721-16.657" transform="translate(399.498 218.729)" fill="#bed4c3" />
                <Path d="M103.657,11.233-20.366,229.273-141.019,23.861S-19.075-55.067,103.657,11.233" transform="translate(393.088 291.512)" fill="#7fab88" />
                <Path d="M64.351,6.974l-77,135.361-74.9-127.522s75.7-49,151.9-7.839" transform="translate(385.469 378.036)" fill="#2a7339" />
                <Text transform="translate(332.086 257.731)" fill="#595b60" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Peaceful</TSpan></Text>
                <Text transform="translate(339.618 422.569)" fill="#fff" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Ecstatic</TSpan></Text>
                <Text transform="translate(330.821 332.753)" fill="#2b2e34" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Cheerful</TSpan></Text>
                <Path d="M18.381,36.761A43.387,43.387,0,0,0,61.768-6.626,43.386,43.386,0,0,0,18.381-50.011,43.386,43.386,0,0,0-25.006-6.626,43.387,43.387,0,0,0,18.381,36.761" transform="translate(354.907 160.81)" fill="#fff" />
                <Path d="M18.381,36.761A43.387,43.387,0,0,0,61.768-6.626,43.386,43.386,0,0,0,18.381-50.011,43.386,43.386,0,0,0-25.006-6.626,43.387,43.387,0,0,0,18.381,36.761Z" transform="translate(354.907 160.81)" fill="none" stroke="#2a7339" strokeWidth="3.013" />
                <Path d="M2.893,5.786A6.829,6.829,0,0,0,9.722-1.044,6.828,6.828,0,0,0,2.893-7.871,6.828,6.828,0,0,0-3.936-1.044,6.829,6.829,0,0,0,2.893,5.786" transform="translate(353.011 142.699)" fill="#2a7339" />
                <Path d="M2.893,5.786A6.829,6.829,0,0,0,9.722-1.044,6.828,6.828,0,0,0,2.893-7.871,6.828,6.828,0,0,0-3.936-1.044,6.829,6.829,0,0,0,2.893,5.786" transform="translate(387.135 142.699)" fill="#2a7339" />
                <Path d="M0,0S17.439,22.093,41.4.514" transform="translate(352.081 164.268)" fill="none" stroke="#2a7339" strokeWidth="3.013" />
                <Path d="M.056,1.563S-1.213-3.589,4.138-1.716" transform="translate(349.999 164.373)" fill="#2a7339" />
                <Path d="M0,.085S5.077-1.452,3.489,3.99" transform="translate(391.724 162.763)" fill="#2a7339" />
                <Path d="M4.4,0a10.393,10.393,0,0,0,0,20.786H85.862A10.393,10.393,0,0,0,85.862,0Z" transform="translate(328.155 199.531)" fill="#fff" />
                <Text transform="translate(344.018 215.145)" fill="#2a7339" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">HAPPY</TSpan></Text>
                <Path d="M4.4,0a10.393,10.393,0,0,0,0,20.786H85.862A10.393,10.393,0,0,0,85.862,0Z" transform="translate(328.155 199.531)" fill="none" stroke="#2a7339" strokeWidth="1.808" />
            </G>
        </Svg>
    )
}