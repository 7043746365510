import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { ReframeIcon } from '../../explore/src/reframe-icon'
import { ReframeIconInverted } from '../../explore/src/reframe-icon-inverted'
import { ReframeheaderActionsImage1 } from './src/reframe-header-actions-image1'
import { ReframeheaderActionsImage2 } from './src/reframe-header-actions-image2'
import { ReframeheaderActionsImage3 } from './src/reframe-header-actions-image3'
import { ReframeSideImage1 } from './src/reframe-side-image1'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithLink from '../../common/default-text-with-link'
import SwitchCardVideo from '../../../common/switch-card-video'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'

export const SwitchesEmotionsAnxiousReframe: CardTemplate = {
    cardId: "7fc44e01-58a0-4af2-83cf-7245fae3ce98",
    title: 'Reframe',
    Header: ReframeIconInverted,
    Icon: ReframeIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Turning a negative mindset into a positive one - helping realise that your thoughts and perceptions are often not the truth."} />
            <View style={{ height: 100, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <ReframeheaderActionsImage1 />
            </View>
        </>
    ),
    Images: () => (
        <>
            <ReframeSideImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

                <DefaultTextWithDownArrow textdata={"Thoughts lead to feelings, that lead to actions, that lead to beliefs. Anxious thoughts can lead to incorrect and self limiting beliefs. "} />

                <DefaultTextWithDownArrow textdata={"Reflect on a situation that is making you upset."} />
                <DefaultTextWithDownArrow textdata={"Write down the story you are telling yourself, or the meaning you are making from the situation (e.g., Henry was mean to me because he doesn't think I'm very good / doesn't like me / because I'm not cool). Write as much as you need to get all your thoughts, fears and feelings down."} />
                <DefaultTextWithDownArrow textdata={"Read your story, cross out any words that are judgmental or negative."} />
                <DefaultTextWithDownArrow textdata={"Rewrite the words to say the same thing but in a nicer way (for example, change “I am useless” to, “I haven’t learnt how to do it yet”)."} />
                <DefaultTextWithoutArrows textdata={"Reflect on the story and think of how you can change your behaviour to align to this new story."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: 'Our brain is like a computer that is programmed to ‘jump to conclusions’ with little or no relevant information. Changing the story allows us to see other possibilities and motivations, which changes our immediate emotional response. Research shows that cognitive reframing: • minimized anxiety and depression and enhanced quality of life during the COVID-19 pandemic • helped reduce burnout • reduced caregiver anxiety, depression, and stress and enhanced communication and overall quality of life. • reduced symptoms and improved functioning in people with mental illness and PTSD • reduced post-event processing (PEP), or the reflective thoughts you have after a social situation, for individuals with social anxiety disorder. Sourse - https://www.verywellmind.com/reframing-defined-2610419',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>

                        <DefaultText style={CommonStyles.lessonPlanText}>• Define the concepts of reframing, judgmental language and self limiting beliefs.
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice changing personal narrative to be inspirational in moments of self doubt.
                        </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the concept of self limiting beliefs and how they can impact thoughts, feelings, actions, and personal stories.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide an example scenario: "What if no one at the party talks to me?" Break down the anxious thought, feeling, action, and belief associated with it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Anxious thought: What is no one at the party talks to me?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Feeling: Scared or nervous</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Action: I stand alone in the corner or I don’t go to the party at all</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Belief: No-one wants to talk to me because I’m stupid. </DefaultText>
                        <View style={[CommonStyles.lessonPlanImageContainer, { height: 120 }]}>
                            <ReframeheaderActionsImage2 />
                        </View>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Reframe Worksheet and ask students to identify an anxious thought they have experienced. Have them break it down into the thought, feeling, action and belief.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Individually or in small groups, have students discuss and fill out the worksheet, identifying feelings, actions, and personal stories (beliefs) associated with their anxious thoughts.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of recognising negative words or generalisations in their personal stories.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Demonstration and Reframing (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that reframing is finding an alternative thought that is enabling you instead of hurting you.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Demonstrate the process of looking at the personal story to identify those words that are generalisations, negative or judgmental that need to be changed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Example:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- No one wants to talk to me because I’m stupid.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- How can you know someone won’t love talking to you because they think you are clever and funny? You don’t KNOW this, you a creating a self limiting belief that isn’t based on fact, it’s based on a feeling of fear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to cross out generalisations, negative or judgmental words that need to be changed from their personal story (refer worksheet).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide example of reframed thought pattern:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Reframed thought: I can find someone to talk to</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Feeling: Confidence</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Action: I talk to a person about the sports carnival last week  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Personal Story: I don’t need to wait for people to talk to me, I can start conversations myself</DefaultText>
                        <View style={[CommonStyles.lessonPlanImageContainer, { height: 120 }]}>
                            <ReframeheaderActionsImage2 />
                        </View>

                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how the reframed thought generates confidence, leading to a positive action and an empowering personal story.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that goal is not to just be more positive, it needs to be believable and realistic. It’s not about, “Everything will be fine and nothing will go wrong” it’s shifting the mindset to, “Maybe things will go wrong, but that’s okay, I have prepared as best I can, and I will try my best”.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to apply the reframing process to their own anxious thoughts using the worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to cross out negative words or generalisations and rewrite a believable and realistic alternative.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Debrief and Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students share their experiences with reframing and discuss how it changed their feelings, actions, and personal stories.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that reframing is not about unrealistic positivity but about creating a mindset that acknowledges challenges and focuses on personal preparation and effort.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key concepts of the lesson: understanding the impact of anxious thoughts and the power of reframing for personal growth.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to find the related switch in the Switch4Schools app for additional practice.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude with a positive and empowering message about the potential for personal growth through changing thought patterns.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Reframe worksheet' link={Worksheets.reframe.uri} /> </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The reframing switch - </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=0fSKRezcUmc' />
                        <SwitchCardVideo video={{
                            id: '1',
                            url: 'https://www.youtube.com/embed/0fSKRezcUmc'
                        }} />

                        <DefaultText style={CommonStyles.lessonPlanText}>Can reframing really make the happiest kids in the world?  </DefaultText>
                        <DefaultTextWithLink text='http://thedanishway.com/reframing-happy-kids/' />

                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Reframe.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Anxious]
}

