import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import { DefaultText } from '../../common/default-text'
import { Worksheets } from '../../common/worksheets-imports'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'


export const SwitchesLessonPlansM7ConqueringFear: CardTemplate = {
    cardId: "642ce986-9f15-4994-8578-c95da163b3e6",
    title: 'Conquering fear: Developing EI',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define being scared and its impact on emotional wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Explore the causes and manifestations of being scared in individuals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Enhance emotional intelligence skills by fostering self awareness, resilience, and effective coping strategies for dealing with being scared.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>• Duration 45 minutes </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by engaging students in a brief discussion about fear. Ask questions such as, "What is being scared?", "When do you experience fear?", and "How does being scared affect you?"</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present the level 7 emotion wheel with an emphasis on the scared quadrant.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to or distribute the Conquering Being Scared  workbook to each student.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Scared (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that being scared is a natural human response to situations that we perceive as threatening, dangerous, or unfamiliar. It is a way our bodies and minds react to protect us from harm and help us stay safe.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Purpose of Being Scared:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- The purpose of being scared is to activate our body's fight or flight response, which is an automatic reaction that prepares us to either confront the threat or run away from it. When we feel scared, our body is trying to keep us alert and ready to respond to potential dangers.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students brainstorm the physical responses to being scared. Look for:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Increased Heart Rate: Our heart beats faster to pump more blood and oxygen to our muscles, preparing us for action.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Rapid Breathing: We may start breathing faster to take in more oxygen, supplying our body with the energy it needs.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Sweating: Sweating is our body's way of cooling down and preparing for physical exertion.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Muscle Tension: Our muscles may become tense and ready for action, preparing us to fight or flee.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Heightened Senses: Our senses, such as hearing and vision, may become more acute, allowing us to be more alert to potential threats in our surroundings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Have students brainstorm the emotional responses to being scared.  Look for:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Feeling Anxious or Nervous: We may feel a sense of unease, worry, or restlessness when we're scared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Sense of Dread: We might experience a feeling of impending danger or doom.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Increased Alertness: Our mind becomes more focused and alert, allowing us to assess the situation and respond accordingly.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Difficulty Concentrating: Fear can sometimes make it harder to concentrate on other tasks or thoughts as our attention is drawn to the perceived threat.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Urge to Escape or Avoid: When scared, we may feel a strong desire to escape or avoid the source of fear to protect ourselves.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that it's important to remember that being scared is a normal and instinctual response, and it serves to keep us safe. However, if fear becomes overwhelming or starts interfering with daily life, it's important to seek support from trusted adults or professionals who can help us manage and overcome our fears.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Identifying Personal Fears (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on their personal fears, what triggers that fear and document on the scared thermometer in their workbook where it sits.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to share their fears (if they feel comfortable) and discuss why those fears exist.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Regulating Fear Techniques (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce a variety of fear regulation techniques that can help individuals manage fear effectively. These techniques may include deep breathing exercises, mindfulness practices, cognitive reframing, self talk, visualisation, or seeking support from trusted individuals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students access the Switch4Schools program on their device and encourage them to explore the available switches assigned to scared on the emotion wheel to bookmark activities they’d like to try.  Alternatively provide a copy of the level 7 quick reference guide. This could also be a homework activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the benefits of some of the techniques selected by the group and when they might be most helpful.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Application and Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Engage students in a group discussion or journaling activity where they reflect on how they can apply the identified regulation techniques in their daily lives. Encourage them to assess the impact of the strategy in their workbook over the coming week.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the main points discussed during the lesson, emphasising the importance of identifying and regulating being scared for emotional wellbeing and resilience.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on the lesson and write down at least one thing they learnt and one thing they will do as a result of this lesson in their workbook.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss any challenges or insights they gained from the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In addition to checking in via the Switch program, encourage students to make use of the space in their workbook to journal their thoughts and feelings over the coming week to better understand their triggers and improve their fear management strategies.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Level 7 emotion wheel'} link={"https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/LV7+how+are+you+feeling+today+cut+out.pdf"} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Mastering the mind workbook, including section M7: Conquering Fear: Developing Emotional Intelligence'} link={Worksheets.masteryMasteringTheMindLevel7.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Fighting your fears -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.betterhealth.vic.gov.au/health/healthyliving/Fighting-your-fears' link='https://www.betterhealth.vic.gov.au/health/healthyliving/Fighting-your-fears' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to manage anxiety and fear -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.mentalhealth.org.uk/explore-mental-health/publications/how-overcome-anxiety-and-fear' link='https://www.mentalhealth.org.uk/explore-mental-health/publications/how-overcome-anxiety-and-fear' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The Difference Between Fear and Phobia -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.alliedpsychiatry.com/blog/the-difference-between-fear-and-phobia' link='https://www.alliedpsychiatry.com/blog/the-difference-between-fear-and-phobia' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Phobias in Children -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.stanfordchildrens.org/en/topic/default?id=phobias-in-children-and-adolescents-90-P01639' link='https://www.stanfordchildrens.org/en/topic/default?id=phobias-in-children-and-adolescents-90-P01639' />
                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}