import Svg, {
  G,
  Rect,
  Line
} from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

export const IconEmotionsLegend = () => {
  return (
    <Svg
      width={46}
      height={35.82}
      viewBox="0 0 46 35.82">
      <G>
        <G>
          <Rect fill={'#231f20'} x="22.41" width="6.15" height="6.15" />
          <Rect fill={'#231f20'} y="29.67" width="6.15" height="6.15" />
          <Rect fill={'#231f20'} x="39.85" y="17.43" width="6.15" height="6.15" />
          <G >
            <Line stroke={'#231f20'} strokeLinecap={'round'} x1="4.39" y1="31.79" x2="24.32" y2="3.15" />
          </G><Line stroke={'#231f20'} strokeLinecap={'round'} x1="26.81" y1="3.15" x2="42.28" y2="20.58" />
        </G>
      </G>
    </Svg>
  )
}

