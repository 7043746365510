import { View } from 'react-native'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { WordSearchIcon } from '../../icons/word-search-icon'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { WordSearchIconInverted } from '../../icons/word-search-icon-inverted'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'
import DefaultTextWithCustomLink from '../../../common/default-text-with-custom-link'

export const SwitchesActivitiesNumberAndWordGamesWordSearch: CardTemplate = {
    cardId: "7a346a84-4d2c-4a52-897a-23d7eaeef2ce",
    title: 'Word search',
    Header: WordSearchIconInverted,
    Icon: WordSearchIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Pattern matching and spelling."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Identify between 10-15 words that are relevant to the topic."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={``}>
                Go to <DefaultTextWithCustomLink style={{ paddingLeft: 0 }} text={`http://www.discoveryeducation.com/free-puzzlemaker/?CFID=369214&CFTOKEN=84297565`} link={`http://www.discoveryeducation.com/free-puzzlemaker/?CFID=369214&CFTOKEN=84297565`} /> to create a free puzzle maker. Select on the type of puzzle you want to create and follow the steps.
            </DefaulttextWithRightArrowLeftAlignedIndentation>
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Upload your words to the online puzzle maker and save the final puzzle file to your computer desktop."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Open a whiteboard in your web-conferencing program or create a blank presentation slide (Power Point, Key Note, etc.)"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Upload the word search puzzle to your presentation or whiteboard."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Create clear instructions that are easy to read and understand."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Practice your game break in your web-conferencing environment at least 3 days before your meeting/training to make sure your whiteboard or slide is easy to read and working properly. This also gives you time to practice your facilitation of this activity."} />

        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"To do this online:"} />
            <DefaulTextWithTipsIcon textdata={"1. Make sure you have access to a Web Conferencing System with Collaboration Whiteboard or access to PowerPoint / Keynote."} />
            <DefaulTextWithTipsIcon textdata={"2. Upload the word search puzzle to your presentation or whiteboard."} />
            <DefaulTextWithTipsIcon textdata={"3. Be sure to set your whiteboard up early, so you have time to practice how it looks and functions in your web-conference application. This gives you time to make modifications to font, colour, size of word search, etc."} />


        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.NUMBER_AND_WORD_GAMES,
    whyDoesItWorkText: '',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Word+search.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.NUMBER_AND_WORD_GAMES],
    action: [CardActionType.Action],
    level: []

}

