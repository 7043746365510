import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { TheTwisterHeader } from './src/the-twister-header'
import { TheTwisterIcon } from './src/the-twister-icon'
import { TheTwisterImage1 } from './src/the-twister-image1'
import { TheTwisterImage2 } from './src/the-twister-image2'
import { TheTwisterImage3 } from './src/the-twister-image3'
import { TheTwisterImage4 } from './src/the-twister-image4'
import { TheTwisterImage5 } from './src/the-twister-image5'
import { TheTwisterImage6 } from './src/the-twister-image6'
import { TheTwisterImage7 } from './src/the-twister-image7'
import { TheTwisterLessonPlan } from './src/the-twister-lesson-plan'

export const SwitchesEmotionsExcitedTheTwister: CardTemplate = {
    cardId: "4bdd4f1c-34d2-4181-9eaf-0de503c54f2a",
    title: 'The twister',
    Header: TheTwisterHeader,
    Icon: TheTwisterIcon,
    lessonPlan: true,
    PerfectFor: () => {

        const Step1 = () => {
            return (
                <View style={styles.stepContainer}>
                    <DefaultText style={styles.contentText}>Stretch out your arms, thumbs facing down.</DefaultText>
                    <View style={styles.rowContainer}>
                        <View style={styles.imageContainer}>
                            <TheTwisterImage1 />
                        </View>
                    </View>
                </View>

            )
        }

        const Step2 = () => {
            return (
                <View style={styles.stepContainer}>
                    <DefaultText style={styles.contentText}>Cross both of your arms over.</DefaultText>
                    <View style={styles.rowContainer}>
                        <View style={styles.imageContainer}>
                            <TheTwisterImage2 />
                        </View>
                    </View>
                </View>
            )
        }

        const Step3 = () => {
            return (
                <View style={styles.stepContainer}>
                    <DefaultText style={styles.contentText}>Clasp your hands together, interlocking your fingers.</DefaultText>
                    <View style={styles.rowContainer}>
                        <View style={styles.imageContainer}>
                            <TheTwisterImage3 />
                        </View>
                    </View>
                </View>
            )
        }

        const Step4 = () => {
            return (
                <View style={styles.stepContainer}>
                    <DefaultText style={styles.contentText}>Bring your hands down and around. </DefaultText>
                    <View style={styles.rowContainer}>
                        <View style={styles.imageContainer}>
                            <TheTwisterImage4 />
                        </View>
                    </View>
                </View>
            )
        }

        const Step5 = () => {
            return (
                <View style={styles.stepContainer}>
                    <DefaultText style={styles.contentText}>Pull them up close to your chin. </DefaultText>
                    <View style={styles.rowContainer}>
                        <View style={styles.imageContainer}>
                            <TheTwisterImage5 />
                        </View>
                    </View>
                </View>
            )
        }

        const Step6 = () => {
            return (
                <View style={styles.stepContainer}>
                    <DefaultText style={styles.contentText}>Look at one of your fingers and try to just raise the one you are focusing</DefaultText>
                    <View style={styles.rowContainer}>
                        <View style={styles.imageContainer}>
                            <TheTwisterImage6 />
                        </View>
                    </View>
                </View>
            )
        }

        const Step7 = () => {
            return (
                <View style={styles.stepContainer}>
                    <DefaultText style={styles.contentText}>Keep going until you have moved all of your fingers.</DefaultText>
                    <View style={styles.rowContainer}>
                        <View style={styles.imageContainer}>
                            <TheTwisterImage7 />
                        </View>
                    </View>
                </View>
            )
        }

        const Step8 = () => {
            return (
                <View style={styles.stepContainer}>

                    <DefaultText style={styles.contentText}>Relax your hands and shake them out.</DefaultText>
                    <View style={styles.rowContainer}>


                        <View style={CommonStyles.flexContainer}>
                            <View style={styles.rowContainer}>
                                <WhyDoesItWorkComponent message={"Finger dexterity exercises require the brain to process and coordinate information from various sources, including visual, tactile, and motor input. This process may help to stimulate brain activity and improve cognitive function. Additionally, finger dexterity exercises may help to improve blood flow to the brain, which may have a positive effect on brain function."} />
                            </View>
                        </View>

                    </View>
                    <DefaulTextWithTipsIcon textdata='Take it slow.' />
                    <DefaulTextWithTipsIcon textdata='Try to only move the finger you intend to.' />


                </View>

            )
        }

        return (
            <>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata={"Distracting and calming an overstimulated mind, and breaking the limbic cycle of negative thoughts."} />
                <Step1 />
                <Step2 />
                <Step3 />
                <Step4 />
                <Step5 />
                <Step6 />
                <Step7 />
                <Step8 />
            </>
        )
    },
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: "",
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify strategies for directing excited energy in a healthy and productive way.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 25 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think about a time when they felt really excited about something. How did they feel physically? What did they want to do?.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them brainstorm a list of things they might have done to express or channel their excitement.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct instruction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of directing excited energy. Explain that it's natural to feel excited about things, but sometimes it can be hard to know how to channel that excitement in a healthy and productive way.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide some examples of strategies for directing excited energy, such as:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Doing physical exercise or activity</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Drawing or creating art</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Writing in a journal</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Talking to a friend or trusted adult</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Taking deep breaths and focusing on the present moment</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Doing an activity such as The Twister switch</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Twister switch and demonstrate the movements. Hand out the Twister worksheet if this is easier than jumping online.</DefaultText>
                        <View style={CommonStyles.lessonPlanImageContainer}>
                            <TheTwisterLessonPlan />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students practice twisting their arms and directing their attention to lifting one finger at a time, by calling out to the students which finger to move, for example, right pink finger, left middle finger and so on. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students practice The Twister slowly lifting each finger one at a time. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that it's important to find healthy and productive ways to channel excited energy, rather than letting it build up or lash out in negative ways.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to try out different strategies including the Twister and find what works best for them.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Twister Sheet Handout' link={Worksheets.theTwister.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Using Distraction Techniques To Cope with Intense Emotions</DefaultText>
                        <DefaultTextWithLink text='https://everythingbutcrazy.com/using-distraction-techniques-to-cope-with-intense-emotions/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Avoidance versus Distraction: Which One Are You Doing?</DefaultText>
                        <DefaultTextWithLink text='https://www.torontopsychologists.com/avoidance-versus-distraction' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to Overcome Over-Excitement and Anxiety?</DefaultText>
                        <DefaultTextWithLink text='https://www.calmdownmind.com/the-resistance-of-over-excitement/' />
                    </>}
            />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/The+Twister+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Excited]
}

const styles = StyleSheet.create({
    contentText: {
        // color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        flexDirection: 'row',
        marginHorizontal: 20,
        justifyContent: 'center',
    },
    noIndent: {
        paddingLeft: 0,
    },
    stepContainer: {
        width: '100%'
    }
})
