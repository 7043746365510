import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import _ from "lodash";
import { useEffect, useState } from "react";
import { CheckInSession, Class, EndCurrentCheckInSessionMutation, EndCurrentCheckInSessionMutationVariables, GetClassQuery, GetClassQueryVariables, GetStudentClassByClassIdQueryVariables, GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables, ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables, OpenNewCheckInSessionMutation, OpenNewCheckInSessionMutationVariables, StudentClassApprovalStatus, Teacher } from "../../common/API";
import { endCurrentCheckInSession, openNewCheckInSession } from "../../common/graphql/mutations";
import { getClass, getTeacherByCognitoUsername, listCheckInSessionsByClassId } from "../../common/graphql/queries";
import { CheckInNavigatorParamList } from "../check-in/check-in-route-param-types";
import { rvCurrentUser } from "../login/login-state";
import { ListStudentClassAndStudentDataByClassIdCustomQuery, listStudentClassAndStudentDataByClassIdCustomQuery } from "../teacher/teacher-graphql-scripts";
import { TeacherDrawerNavigatorPamarList } from "../teacher/teacher-route-param-types";
import {
  rvTeacherCurrentCheckIn
} from "../teacher/teacher-state";
import { ButtonSessionCommon } from "./button-session-common";
import { rvCurrentClass, rvNetInfo, rvTeacherAbsenceMode } from "./common-state";
import { OfflineMessage } from "./const";
import { fetchParamsFromUrl } from "./fetchParamsFromUrl";
import { rvIsLoading } from "./loading";
import { CLIENT_EVENT, createClientLog } from "./log-client-event";
import { ScreenNames } from "./screen-names";
import { showAbsenceWarnAlert, showAlert, showOfflineAlert } from "./universal-alert";

export const ButtonSessionStudent = () => {
  const { params } = useRoute<RouteProp<CheckInNavigatorParamList, "CheckInStart">>()

  const [classID, setClassID] = useState(params.classID || "")

  useEffect(() => {
    if (!classID) {
      fetchParamsFromUrl<{ classID: string }>((params) => {
        setClassID(params.classID)
      })
    }
  }, [classID])

  const user = useReactiveVar(rvCurrentUser)

  const { data: teacherResponse } = useQuery<GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables>(gql`${getTeacherByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
  const currentTeacher = teacherResponse?.getTeacherByCognitoUsername?.items[0] as Teacher

  const { data: classResponse } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: classID || "" }, skip: !classID })
  const { data: studentClassResponse } = useQuery<ListStudentClassAndStudentDataByClassIdCustomQuery, GetStudentClassByClassIdQueryVariables>(gql`${listStudentClassAndStudentDataByClassIdCustomQuery}`, { variables: { classID: classID || "", limit: 5000 }, skip: !classID })

  const [openNewCheckInSessionMutation] = useMutation<OpenNewCheckInSessionMutation, OpenNewCheckInSessionMutationVariables>(gql`${openNewCheckInSession}`)

  const currentClass = classResponse?.getClass as Class

  const { data: checkInSessionsResponse } = useQuery<ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables>(gql`${listCheckInSessionsByClassId}`, { variables: { classID: classID || "", limit: 5000 }, skip: !classID })

  const activeCheckInSessions = checkInSessionsResponse?.listCheckInSessionsByClassId?.items.filter((item) => item?._deleted !== true && item?.isActive)
  const currentCheckIn = _.orderBy(activeCheckInSessions, "updatedAt", "desc")[0]

  const absenceMode = useReactiveVar(rvTeacherAbsenceMode);
  const netInfoState = useReactiveVar(rvNetInfo)
  const [submiting, setSubmitting] = useState<boolean>(false)
  const { navigate } =
    useNavigation<DrawerNavigationProp<TeacherDrawerNavigatorPamarList>>();
  const route = useRoute()
  const studentClasses = studentClassResponse?.getStudentClassByClassId?.items.filter((item) => item?._deleted !== true) || []

  const [endCurrentCheckInMutation] = useMutation<EndCurrentCheckInSessionMutation, EndCurrentCheckInSessionMutationVariables>(gql`${endCurrentCheckInSession}`)

  const isStatsPage = route.name === ScreenNames.TeacherStats
  const isCreate = !currentCheckIn;
  const titleButton = isCreate ? "Start check-in session" : "End current Check-in session";

  const startCheckIn = async () => {
    if (netInfoState?.isInternetReachable === false) return showOfflineAlert()

    const activeStudents = studentClasses.length > 0 ? studentClasses.filter((studentClass) => studentClass?.status === StudentClassApprovalStatus.APPROVED) : []

    if (currentClass && activeStudents?.length === 0) {
      showAlert({
        title: "Failed to start check-in",
        message: `There's no active student in this class.`,
        leftButtonText: "Ok",
        rightButtonText: "Add student",
        onRightButtonPress: () => navigate(ScreenNames.TeacherClassMain, { screen: ScreenNames.TeacherAddStudent, params: { classID: classID || "" } })
      })
      return
    }

    if (currentClass && !submiting) {
      rvIsLoading(true);
      setSubmitting(true);

      try {
        const response = await openNewCheckInSessionMutation({
          variables: {
            input: {
              classID: classID || ""
            }
          }
        })

        createClientLog({
          area: 'Button session',
          action: "Success start checkin, navigating to TeacherCheckInMain",
          event: CLIENT_EVENT.START_CHECKIN_SUCCESS,
          payload: {
            group: "teacher",
            nickname: currentTeacher?.cognitoUsername,
          }
        })

        rvTeacherCurrentCheckIn(response.data?.openNewCheckInSession as CheckInSession)
        rvIsLoading(false);
        setSubmitting(false)
        navigate(ScreenNames.TeacherClassMain, { screen: ScreenNames.CheckInStart, params: { classID: classID || "" } });
      } catch (e: any) {
        createClientLog({
          area: 'Button session',
          action: "Failed to start checkin",
          event: CLIENT_EVENT.START_CHECKIN_FAILED,
          payload: {
            group: "teacher",
            nickname: currentTeacher?.cognitoUsername,
            error: JSON.stringify(e)
          }
        })
        console.log(e)
        showAlert({
          leftButtonText: 'OK',
          message: OfflineMessage,
          rightButtonText: 'Retry',
          onRightButtonPress: startCheckIn
        })
      } finally {
        rvIsLoading(false);
        setSubmitting(false)
      }
    }
  };

  const endCheckInWithAbsenceModeWarning = () => {
    if (netInfoState?.isInternetReachable === false) return showOfflineAlert()

    if (absenceMode) {
      showAbsenceWarnAlert({
        onLeftButtonPress: () => {
          rvTeacherAbsenceMode(false)
          endCheckIn()
        }
      })
      return
    }

    endCheckIn()
  }

  const promptEndCheckInSession = () => {
    showAlert({
      title: "End current check-in session",
      message: "Are you sure?",
      leftButtonText: "Cancel",
      rightButtonText: "Yes",
      onRightButtonPress: endCheckInWithAbsenceModeWarning
    })
  }

  const endCheckIn = async () => {

    if (currentCheckIn && !submiting) {
      setSubmitting(true);
      rvIsLoading(true)
      try {
        await endCurrentCheckInMutation({
          variables: {
            input: {
              checkInSessionID: currentCheckIn?.id
            }
          }
        })

        createClientLog({
          area: 'Button session',
          action: "Success finish checkin, navigating to TeacherHome",
          event: CLIENT_EVENT.END_CHECKIN_SUCCESS,
          payload: {
            group: "teacher",
            nickname: currentTeacher?.cognitoUsername,
          }
        })
        rvTeacherCurrentCheckIn(undefined)
        rvIsLoading(false)
        setSubmitting(false);
        if (!isStatsPage) {
          navigate(ScreenNames.TeacherHome)
          rvCurrentClass(undefined)
        }
      } catch (e: any) {
        console.log(e)
        createClientLog({
          area: 'Button session',
          action: "",
          event: CLIENT_EVENT.END_CHECKIN_FAILED,
          payload: {
            group: "teacher",
            nickname: currentTeacher?.cognitoUsername,
            error: JSON.stringify(e),
          }
        })
        showAlert({
          leftButtonText: 'OK',
          message: OfflineMessage,
          rightButtonText: 'Retry',
          onRightButtonPress: endCheckIn
        })
      } finally {
        rvIsLoading(false);
        setSubmitting(false);
      }
    }
  };

  return (
    <ButtonSessionCommon
      closeCheckIn={promptEndCheckInSession}
      startCheckIn={startCheckIn}
      isCreate={isCreate}
      submiting={submiting}
      titleButton={titleButton}
    />
  );
};

export default ButtonSessionStudent;