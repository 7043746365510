import { StyleSheet, View } from 'react-native'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { TakeABreakHeaderIcon } from './src/take-a-break-header-icon'
import { TakeABreakIcon } from './src/take-a-break-icon'
import { TakeABreakImage1 } from './src/take-a-break-image1'
import { TakeABreakImage2 } from './src/take-a-break-image2'
import { TakeABreakImage3 } from './src/take-a-break-image3'

export const SwitchesEmotionsAngryTakeABreak: CardTemplate = {
    cardId: "d19ed028-a1db-4042-8afb-91b7955279c8",
    title: 'Take a break',
    Header: TakeABreakHeaderIcon,
    Icon: TakeABreakIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Pressing a pause button to give ourselves time to calm down and feel better."} />

            <DefaultTextWithoutArrows textdata={"When you start feeling overwhelmed or exhausted, take a moment to notice how you're feeling."} />
            <View style={styles.imageContainer}>
                <TakeABreakImage1 />
            </View>

            <DefaultTextWithoutArrows textdata={"Look for an adult you trust and ask, “Can I please have a break?”."} />
            <View style={styles.imageContainer}>
                <TakeABreakImage2 />
            </View>

            <DefaultTextWithoutArrows textdata={"Listen to their response and follow their instructions."} />
            <View style={styles.imageContainer}>
                <TakeABreakImage3 />
            </View>

        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    cardContentTemplate: 'column',
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: "Taking a break helps regulate emotions by providing a pause to reset and recharge. When we feel overwhelmed or upset, our minds and bodies can become overloaded with thoughts and feelings. Taking a break allows us to step away from the situation, giving us time to calm down, reflect, and regain control. It creates a space for self care and self soothing activities, promoting relaxation and reducing the intensity of emotions. By taking a break, we give ourselves the opportunity to regain clarity, process emotions more effectively, and approach challenges with a refreshed perspective.",
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Articulate the importance of taking a break when feeling overwhelmed and practice simple techniques to regulate their emotions during break time.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 25 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (2 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students and explain that today they will learn a switch called "Taking a Break" that can help them feel better when they're feeling overwhelmed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Engage the students by asking questions like, "Have you ever felt really overwhelmed or upset? Why do you think taking a break can help to feel better when we feel that way?"</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introducing Taking a Break (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that taking a break means finding a special quiet and cozy place where they can rest and feel calm.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how taking a break can help them relax, gather their thoughts, and feel better when they're feeling overwhelmed. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to brainstorm different places they like to go or things they like to do when they need to take a break.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Practicing Break Techniques (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Guide the students through various techniques they can practice during break time:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Deep Breathing: Show them how to take slow, deep breaths in through the nose and out through the mouth, emphasising the calming effect it can have. (refer to the Calming Breath switch)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Quiet Time: Encourage them to sit or lie down in the cozy corner and enjoy a few minutes of silence, allowing their minds to rest. (refer to the Calm Down Corner switch)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Visualisation: Lead them in a short visualisation exercise, where they imagine being in a peaceful place like a beach or a meadow. (refer to the My Safe Place switch)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allocate time for students to practice these techniques individually or in pairs, allowing them to experience the benefits of taking a break. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Closure (3 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students back into a circle and ask them to share their experiences during break time and how it made them feel. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the lesson by emphasising that taking a break is a switch they can use whenever they're feeling overwhelmed. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to remember to listen to their bodies and take a break whenever they need it, knowing that it can help them feel better and more in control of their emotions. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Quiet space </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Why Are We Teaching Students to Take a Break? -</DefaultText>
                        <DefaultTextWithLink text='https://autismclassroomresources.com/function-break/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Should Kids Take Mental Health Days? -</DefaultText>
                        <DefaultTextWithLink text='https://childmind.org/article/should-kids-take-mental-health-days/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Research-Tested Benefits of Breaks -</DefaultText>
                        <DefaultTextWithLink text='https://www.edutopia.org/article/research-tested-benefits-breaks/' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Take+a+break+voice+over.m4a",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Angry]
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
})
