import { StyleSheet, View } from 'react-native'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { DrawHappinessHeader } from './src/draw-happiness-header'
import { DrawHappinessIcon } from './src/draw-happiness-icon'
import { DrawHappinessImage1 } from './src/draw-happiness-image1'
import { DrawHappinessImage2 } from './src/draw-happiness-image2'
import { DrawHappinessImage3 } from './src/draw-happiness-image3'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'

export const SwitchesEmotionsHappyDrawHappiness: CardTemplate = {
    cardId: "ff6edc15-9b79-4a9e-b3d2-349dc1ba49c4",
    title: 'Draw happiness',
    Header: DrawHappinessHeader,
    Icon: DrawHappinessIcon,
    lessonPlan: true,
    PerfectFor: () => {
        const Step1 = () => {
            return (
                <View>
                    <DefaultText style={styles.contentText}>Think of three things that make you feel happy.</DefaultText>
                    <View style={styles.imageContainer}>
                        <DrawHappinessImage1 />
                    </View>
                </View>
            )
        }

        const Step2 = () => {
            return (
                <View style={CommonStyles.flexContainer}>
                    <View style={styles.rowContainer}>
                        <View style={styles.imageContainer}>
                            <DrawHappinessImage2 />
                        </View>
                    </View>
                    <View style={styles.rowContainer}>
                        <DefaultText style={styles.contentText}>Draw or paint all three things in one picture.</DefaultText>
                        <DefaultTextWithoutArrows textdata='' />
                        <DefaultText style={styles.contentText}>Title your picture:</DefaultText>
                        <DefaultTextWithoutArrows textdata='' />
                        <DefaultText style={styles.contentText}>“Things that make me happy”</DefaultText>
                    </View>
                </View>
            )
        }

        const Step3 = () => {
            return (
                <View>
                    <DefaultText style={styles.contentText}>Hang or stick your artwork in a place where it will remind </DefaultText>
                    <DefaultText style={styles.contentText}>you of things that make you happy.</DefaultText>
                    <View style={styles.imageContainer}>
                        <DrawHappinessImage3 />
                    </View>

                    <View>
                        <WhyDoesItWorkComponent message={'Research shows that drawing makes you happier and improves wellbeing. \n\n There are many studies showing that art can make you happier. This is because doing something you love releases endorphins – feel-good chemicals that combat stress and reduce pain and the perception of threat.'} />
                    </View>
                </View>
            )
        }

        const contents = [
            { Component: Step1, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/draw-happiness-pg1.mp3' },
            { Component: Step2, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/draw-happiness-pg2.mp3' },
            { Component: Step3, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/draw-happiness-pg3.mp3' },
        ]

        return (
            <>
                <DefaultTextWithoutArrowsBold textdata={'Perfect for:'} />
                <DefaultTextWithoutArrowsItallic textdata={'Creating a picture to remember all the things that make our hearts smile.'} />
                
                <SwitchCardJuniorContent contents={contents} scheme={Emotion.HAPPY} />
            </>
        )
    },
    CardContent: () => (
        <>
        </>
    ),
    cardContentTemplate: 'column',
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: "ß",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define what is happiness and what makes them happy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Have a record / reminder of what makes them happy.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 25 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to think about one thing that makes them happy and share it with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write the students’ responses on the board or on a piece of paper.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Demonstration (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Next, demonstrate to the students how to draw a simple object, such as a sun or a flower, using basic shapes (circles, squares, triangles).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to draw along with you and help them as needed.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Now it’s time for the students to practice drawing things that make them happy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide the students with paper and drawing materials, such as crayons, pens, or coloured pencils.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to look at the list of things that make them happy that you wrote on the board or on the paper.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students choose one thing from the list and try to draw it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ As they draw, encourage them to think about why that thing makes them happy and to talk about it with their classmates.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once the students have finished their drawings, have them share their artwork with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to talk about their drawings and explain why the thing they drew makes them happy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind the students that they can always draw things that make them happy when they are feeling sad or upset.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ End the lesson by thanking the students for participating and reminding them to practice drawing things that make them happy.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Art supplies (paper, drawing, pencils, paper)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard/flipchart and markers</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>100 Kids Tell Us What Makes Them Happy -</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=1Y5R8K7OKSo' />
                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Happy]
}

const styles = StyleSheet.create({
    contentText: {
        color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        width: '100%',
        height: 400,
        marginVertical: 20,
        alignSelf: 'center',
        alignItems: 'center',
    },
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        marginHorizontal: 20
    },
})

