import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { DrawableGames } from '../../../common/drawable-games'
import { useResponsive } from '../../../common/use-responsive'
import { Emotion, Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { TreasureHuntHeader } from './src/treasure-hunt-header'
import { TreasureHuntIcon } from './src/treasure-hunt-icon'
import { TreasureHuntImage1 } from './src/treasure-hunt-image1'
import { TreasureHuntImage2 } from './src/treasure-hunt-image2'
import { TreasureHuntImage3 } from './src/treasure-hunt-image3'
import { TreasureHuntLessonPlan } from './src/treasure-hunt-lesson-plan'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { Worksheets } from '../../../common/worksheets-imports'
import InfoIconWIthContent from '../../../common/info-icon-with-content'
import VideoCard from '../../../common/video-card'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'

enum TreasureHuntTab {
    HUNT1 = "Hunt 1",
    HUNT2 = "Hunt 2",
    HUNT3 = "Hunt 3",
}

const treasureHuntVideo1: Videos = {
    id: "",
    url: "https://player.vimeo.com/video/866209499",
    title: "Treasure hunt video",
    autoplay: true
}

export const SwitchesEmotionsExcitedTreasureHunt: CardTemplate = {
    cardId: "1cecd3eb-f6a0-4c2c-85c2-d7f8c343a4a8",
    title: 'Treasure hunt',
    Header: TreasureHuntHeader,
    Icon: TreasureHuntIcon,
    lessonPlan: true,
    mainContainerStyle: {
        maxWidth: '100%',
        marginLeft: -20,
    },
    audioUrl: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/treasure-hunt-pg1.mp3',
    PerfectFor: () => {
        const { isLargeScreen } = useResponsive()
        const [currentPath, setCurrentPath] = useState<string[]>([])

        const handleClearDraw = () => setCurrentPath([])

        const initialStep = () => {
            return <View>
                <WhyDoesItWorkComponent message={'Doing puzzles can calm the mind for three main reasons. First, it is a form of mindfulness or meditation, requiring focus and attention to the present moment. This can help to clear the mind and reduce feelings of anxiety or stress.\n\nSecond, puzzles can provide a sense of accomplishment and satisfaction when completed, which can also contribute to a sense of calm and well-being.\n\nThird, working on a puzzle can also help to distract from negative thoughts or worries, and help a person to relax.'} />
            </View>
        }

        const Step1 = () =>
            <DrawableGames>
                <TreasureHuntImage1 />
            </DrawableGames>

        const Step2 = () =>
            <DrawableGames>
                <TreasureHuntImage2 />
            </DrawableGames>

        const Step3 = () =>
            <DrawableGames>
                <TreasureHuntImage3 />
            </DrawableGames>

        const contents = [
            { Component: initialStep },
            { Component: Step1, isModal: true },
            { Component: Step2, isModal: true },
            { Component: Step3, isModal: true },
        ]
        const contentTabs = [
            {
                label: TreasureHuntTab.HUNT1,
                value: 1,
            },
            {
                label: TreasureHuntTab.HUNT2,
                value: 2,
            },
            {
                label: TreasureHuntTab.HUNT3,
                value: 3,
            },
        ]

        return (
            <>
                <DefaultTextWithoutArrowsBold textdata={'Perfect for:'} />
                <DefaultTextWithoutArrowsItallic textdata={'Using a focused and engaging activity to channel excess energy, promote concentration and create a feeling of calm.'} />

                <View style={styles.treasureHuntContainer}>
                    <DefaultText>Which path leads to the treasure?</DefaultText>
                    <SwitchCardJuniorContent contents={contents} scheme={Emotion.EXCITED} contentTabs={contentTabs} withInitialSteps />
                    <View style={styles.infoIconWrapper}>
                        <InfoIconWIthContent
                            Content={() => (
                                <View style={CommonStyles.flexContainer}>
                                    <VideoCard video={treasureHuntVideo1} />
                                </View>
                            )}
                            iconSize={40}
                            iconColor={CardColorScheme.EXCITED}
                        />
                    </View>
                </View>
            </>
        )
    },
    Images: undefined,
    CardContent: () => (
        <>
        </>
    )
    ,
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: "",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice using a line tracing puzzle to regulate emotion to build confidence, mindfulness and problem solving skills.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 25 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by explaining that today, the students will be learning how to do a line tracing puzzle.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students if they have ever done a line tracing before, and encourage them to share any experiences they have had.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show the students an example of a simple puzzle, possibly the one under the beginner tab in the Treasure Hunt switch, and explain that the goal is find the line that connects to the treasure chest.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Demonstration (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Choose a volunteer to come to the front of the class and try to solve the maze puzzle while the rest of the class watches.</DefaultText>
                        <View style={styles.rowContainerHalf}>
                            <DefaultText style={CommonStyles.lessonPlanTextSmallItalic}>If you have a smart board this could be done by
                                pulling the switch up and tracing over
                                different puzzles.
                            </DefaultText>
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ As the volunteer solves the puzzle, explain the strategies they are using and encourage the other students to watch and listen carefully.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once the volunteer has successfully completed the puzzle, ask the other students if they have any questions about the process.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Print out the puzzles on the Treasure Hutn worksheet (link found below in the materials section) and distribute copies to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ With a pencil or pen, encourage the students to work on the puzzles individually, but allow them to ask for help or guidance if needed.</DefaultText>
                        <View style={CommonStyles.lessonPlanImageContainer}>
                            <TreasureHuntLessonPlan />
                        </View>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once all of the students have completed the puzzle, ask them to share their strategies with the class. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to discuss any challenges they faced while solving the puzzle and how they overcame them. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ For an extension activity, you could introduce a new, more challenging puzzles for the students to try, or you could have the students create their own mase for their classmates to solve.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• OPTIONAL: Treasure Hunt Movement Activity</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the activity by telling the students that they are going to go on a treasure hunt. Show them the treasure chest and explain that it is filled with special treats or prizes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Pass out the map and give each student a card with the first set of directions. Explain that they need to follow the directions on their card in order to find the treasure.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ You can tailor the tasks and make them match the children’s ability while adding appropriate challenges or obstacles.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ You can adapt the task to outdoor or different locations like the library or the playground.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students work in small groups or pairs to follow the directions on their card and find the next set of directions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Repeat step three until the students reach the treasure chest.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ When the students have found the treasure, allow them to take turns opening the chest and choosing a treat or prize.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After the treasure hunt, debrief the activity by discussing what the students learned and how they used the directions to find the treasure.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pens / pencils</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Path Drawing Worksheet' link={Worksheets.pathDrawing.uri} /> </DefaultText>

                        <DefaultText style={CommonStyles.lessonPlanText}>For the Treasure Hunt Movement Activity</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• A map of the classroom or playground</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• A treasure chest or box filled with small trinkets or treats</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Directions written on cards (e.g., “Go to the book shelf, then turn left and walk to the play kitchen”)</DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>There are heaps of free mazes to be found at </DefaultText>
                        <DefaultTextWithLink text='https://krazydad.com/mazes/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Epic Pirate Adventure Music - Treasure Hunt - </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=CRVnIFK3GhE' />

                        <DefaultText style={CommonStyles.lessonPlanText}>We’re Going on a Treasure Hunt - Song for Kids | Lingokids - </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=SNc4XtRG63o' />

                    </>}
            />

        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Excited]
}

const styles = StyleSheet.create({
    infoIconWrapper: {
        position: 'absolute',
        right: 20,
        top: 20,
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanTextSmallItalic: {
        textAlign: 'center',
        fontSize: 16,
        fontStyle: 'italic',
        color: DecidaColors.AppleSystemGray2Dark,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        marginHorizontal: 20
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        alignSelf: 'center',
        marginVertical: 10
    },
    treasureHuntContainer: {
        position: 'relative',
        marginVertical: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
})

