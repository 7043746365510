import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Ionicons } from '@expo/vector-icons';
import * as Clipboard from 'expo-clipboard';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Class, CustomCreateTeacherAndAssignedToClassMutation, CustomCreateTeacherAndAssignedToClassMutationVariables, CustomUpdateTeacherMutation, CustomUpdateTeacherMutationVariables, GetClassQuery, GetClassQueryVariables, GetTeacherByEmailQuery, GetTeacherByEmailQueryVariables, TeacherRole } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { generatePassword } from '../../common/generate-password';
import { customCreateTeacherAndAssignedToClass, customUpdateTeacher } from '../../common/graphql/mutations';
import { getClass, getTeacherByEmail } from '../../common/graphql/queries';
import { DefaultButton } from '../common/default-button';
import { DefaultText } from '../common/default-text';
import { emailValidator } from '../common/email-validator';
import InputDefault from '../common/input-default';
import InputSearchTeacherRoleComponent, { getRoleByValue } from '../common/input-search-teacher-role-component';
import { rvIsLoading } from '../common/loading';
import { showAlert } from '../common/universal-alert';
import { rvSchoolAdminSidebarCurrentTeacher, rvShowTeacherForm, rvSidebarClassID } from './school-admin-state';
import { schoolAdminGetClassQuery, SchoolAdminGetClassQueryResponse } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-class-query';


const SchoolAdminFormUpsertTeacher = () => {

    const currentClassID = useReactiveVar(rvSidebarClassID)
    const showTeacherForm = useReactiveVar(rvShowTeacherForm)
    const currentTeacher = useReactiveVar(rvSchoolAdminSidebarCurrentTeacher)
    const [firstName, setFirstName] = useState(currentTeacher?.firstName || "")
    const [lastName, setLastName] = useState(currentTeacher?.lastName || "")
    const [email, setEmail] = useState(currentTeacher?.email || "")
    const [role, setRole] = useState({
        label: getRoleByValue(currentTeacher?.role) || "",
        value: currentTeacher?.role || '',
    })
    const [emailError, setEmailError] = useState("")
    const [roleError, setRoleError] = useState("")
    const [firstNameError, setFirstNameError] = useState("")

    const [customCreateTeacherMutation] = useMutation<CustomCreateTeacherAndAssignedToClassMutation, CustomCreateTeacherAndAssignedToClassMutationVariables>(gql`${customCreateTeacherAndAssignedToClass}`,)
    const [customUpdateTeacherMutation] = useMutation<CustomUpdateTeacherMutation, CustomUpdateTeacherMutationVariables>(gql`${customUpdateTeacher}`,)

    const { data } = useQuery<SchoolAdminGetClassQueryResponse, GetClassQueryVariables>(schoolAdminGetClassQuery, { variables: { id: currentClassID } })

    const currentClass = data?.getClass as Class

    const { data: getTeacherByEmailQuery, refetch: refetchGetTeachersByEmailQuery } = useQuery<GetTeacherByEmailQuery, GetTeacherByEmailQueryVariables>(gql`${getTeacherByEmail}`, { variables: { email }, skip: !email })

    const debouncedEmailSearch = useCallback(
        _.debounce((value: string) => {
            refetchGetTeachersByEmailQuery()
        }, 500),
        []
    );

    useEffect(() => {
        if (email.length > 0) {
            debouncedEmailSearch(email)
        }
    }, [email, debouncedEmailSearch])



    const handleConfirm = async () => {

        if (!firstName) {
            setFirstNameError("Please input firstName")
            return
        }

        const emailValidations = emailValidator(email)

        if (emailValidations === "Email is not valid.") {
            setEmailError("Email is not valid");
            return
        }

        if (!emailValidations) {
            setEmailError("Please input email")
            return
        }

        if (!role.value) {
            setRoleError("Please select role")
            return
        }


        try {

            rvIsLoading(true)
            setEmailError("")
            if (currentTeacher) {
                await customUpdateTeacherMutation({
                    variables: {
                        input: {
                            id: currentTeacher?.id,
                            firstName,
                            lastName,
                            email,
                            role: role.value as TeacherRole
                        }
                    }
                })
                rvSchoolAdminSidebarCurrentTeacher(null)
            } else {
                const tempPassword = generatePassword()

                await customCreateTeacherMutation({
                    variables: {
                        input: {
                            firstName,
                            lastName,
                            email,
                            role: role.value as TeacherRole,
                            cognitoUsername: email,
                            schoolID: currentClass?.schoolID,
                            classID: currentClassID,
                            password: tempPassword
                        }
                    }
                })
                await Clipboard.setStringAsync(tempPassword)
                showAlert({
                    title: 'Successfully created teacher.',
                    message: `Password: ${tempPassword}`,
                    leftButtonText: 'Ok',
                })
                toggleForm()
            }
            rvIsLoading(false)
        } catch (error) {
            console.error(error)
        }

    }

    const toggleForm = () => {
        rvShowTeacherForm(!showTeacherForm)
    }

    const handleEmailBlur = async () => {

        if (getTeacherByEmailQuery?.getTeacherByEmail?.items.length) {
            setEmailError("It looks like an account already exists with that email address.")
        }
    }

    const onChangeEmail = (text: string) => {
        setEmailError("")
        setEmail(text)
    }

    const onChangeFistName = (value: string) => {
        setFirstNameError("")
        setFirstName(value)
    }

    const onChangeRole = ({ label, value }: { label: string, value: string }) => {
        setRoleError("")
        setRole({
            label,
            value
        })
    }

    const onCancel = () => {
        rvShowTeacherForm(false)
        rvSchoolAdminSidebarCurrentTeacher(null)
    }

    return (
        <>
            <View style={styles.inputFormContainer}>
                <InputDefault value={firstName} onChangeText={onChangeFistName} placeholder='First name' customInputStyle={styles.input} error={firstNameError} />
                <InputDefault value={lastName} onChangeText={setLastName} placeholder='Last name' customInputStyle={styles.input} />
                <InputDefault value={email} onChangeText={onChangeEmail} placeholder='Email' customInputStyle={styles.input} error={emailError} onBlur={handleEmailBlur} />
                <InputSearchTeacherRoleComponent value={role.value} setSelected={onChangeRole} selected={role.label} placeholder='Role' inputStyle={styles.input} error={roleError} />
            </View>
            <View>
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    {currentTeacher && (
                        <DefaultButton style={[styles.confirmButton, { backgroundColor: DecidaColors.Red }]} onPress={onCancel}>
                            <DefaultText style={{ fontSize: 18, color: DecidaColors.White }}>
                                Cancel
                            </DefaultText>
                        </DefaultButton>
                    )}
                    <DefaultButton disabled={!!emailError} onPress={handleConfirm} style={styles.confirmButton}>
                        <Ionicons name="ios-checkmark-sharp" size={28} color={DecidaColors.White} style={{ fontWeight: '800' }} />
                        <DefaultText style={{ fontSize: 18, fontWeight: '800', color: DecidaColors.White }}>Confirm</DefaultText>
                    </DefaultButton>
                </View>
                {!currentTeacher && (
                    <DefaultText style={{ fontSize: 16, color: DecidaColors.AppleSystemGray4Light, fontStyle: 'italic', }}>*Confirmation email and log on details will be sent to teacher</DefaultText>
                )}
            </View>
        </>
    )
}
export default SchoolAdminFormUpsertTeacher

const styles = StyleSheet.create({
  inputFormContainer: {
    flex: 1,
    marginVertical: 20,
    width: '100%',
    zIndex: 99,
    flexWrap: 'wrap',
  },
    confirmButton: {
        flex: 1,
        flexDirection: 'row',
        maxWidth: 150,
        width: '60%',
        height: 30,
    },
    input: {
        borderRadius: 0,
    },
})