import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { HighFiveIcon } from '../../icons/high-five-icon'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { HighFiveIconInverted } from '../../icons/high-five-icon-inverted'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../../common/default-text-no-arrow-left-aligned-double-indentation'
import { View } from 'react-native'

export const SwitchesActivitiesEnergizersHighFive: CardTemplate = {
    cardId: "f0974e7e-9aac-478c-8747-2e12e4fafa1c",
    title: 'High-five',
    Header: HighFiveIconInverted,
    Icon: HighFiveIcon,
    PerfectFor: () => (
        <View style={{ width: '100%' }}>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Injecting energy into a room where people might be a bit fatigued."} />
            <DefaulttextWithRightArrow textdata={"Have everyone find a partner and then stand facing that partner at arms length. If there is one person left over they can form a group of three."} />
            <DefaulttextWithRightArrow textdata={"As you explain the rules of the game, get people to practice each instruction as you explain them:"} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• When you say “one” everyone gives each other a right hand high five (in a group of three everyone high fives to the right)"} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• When you say “two” everyone gives each other a left hand high five (in a group of three everyone high fives to the left)"} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• When you say “three” everyone gives each other high ten (in a group of three everyone high fives to both sides)"} />
            <DefaulttextWithRightArrow textdata={"Randomly call out “one”, “two” or “three” at a reasonably fast pace."} />
        </View>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"ADD A COMPLICATION:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Have everyone close their eyes and repeat the activity."} />
        </>

    ),
    CardContent: () => (
        <>

        </>
    ),
    colorScheme: CardColorScheme.ENERGIZERS,
    whyDoesItWorkText: '',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/High+Five.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.ENERGIZERS],
    action: [CardActionType.Action],
    level: []
}

