import { gql, useReactiveVar, useSubscription } from '@apollo/client'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables, OnMutateCheckInSessionByClassIdSubscription, OnMutateCheckInSessionByClassIdSubscriptionVariables } from '../../common/API'
import { listCheckInSessionsByClassId } from '../../common/graphql/queries'
import { onMutateCheckInSessionByClassId } from '../../common/graphql/subscriptions'
import { handleRestartApp } from '../common/handle-restart-app'
import { ClassLoginInitialLoad, ClassLoginsByUsernameSubscriptionListener, ClassSubscriptionByClassIdListener, StudentClassSubscriptionListenerByClassId } from '../common/helper-api-functions'
import NotFoundPage from '../common/not-found-page'
import { PlaceHolderComponent } from '../common/placeholder-component'
import { RootView } from '../common/root-view'
import { ScreenNames } from '../common/screen-names'
import { updateListQueryCache } from '../common/update-list-query-cache'
import useAppState from '../common/use-app-state'
import useConnectivity from '../common/use-connectivity'
import { useRedirectPages } from '../common/user-redirect-pages'
import { rvCurrentUser } from '../login/login-state'
import { SwitchesActiviesDetailView } from '../switches/activities/switches-activies-detail-view'
import { SwitchesActiviesDisplayAll } from '../switches/activities/switches-activies-display-all'
import { Card } from '../switches/common/card'
import { rvSwitchesScreenState } from '../switches/common/current-screen-state'
import { SwitchesAcitiviesMain } from '../switches/navigation/switches-activity-main'
import { SwitchesBottomNavigation } from '../switches/navigation/switches-bottom-navigation'
import { SwitchesExploreAllMain } from '../switches/navigation/switches-explore-all-main'
import { SwitchesHomeMain } from '../switches/navigation/switches-home-main'
import { SwitchesToolsMain } from '../switches/navigation/switches-tools-main'
import { SwitchesEmotionsDetailView } from '../switches/switches-emotions-detail-view'
import { SwitchesExploreAll } from '../switches/switches-explore-all'
import { SwitchesHome } from '../switches/switches-home'
import { SwitchesMemes } from '../switches/switches-memes'
import { SwitchesToolsDisplayAll } from '../switches/tools/switches-tools-display-all'
import { ClassLoginCheckInMain } from './class-login-check-in-main'
import { ClassLoginHeader } from './class-login-header'
import { ClassLoginNavigationParams } from './class-login-navigation-params'
import { rvCurrentClassLogin } from './class-login-state'


const SideMenu = createDrawerNavigator<ClassLoginNavigationParams>()


export const ClassLoginMain = () => {

    const currentScreen = useReactiveVar(rvSwitchesScreenState)
    const user = useReactiveVar(rvCurrentUser);
    const currentClassLogin = useReactiveVar(rvCurrentClassLogin)

    useRedirectPages()
    useAppState()
    useConnectivity()

    // todo: Need to changed all classLogin subscriptions
    ClassLoginsByUsernameSubscriptionListener(user?.username || "")
    ClassSubscriptionByClassIdListener(currentClassLogin?.classID || "")
    StudentClassSubscriptionListenerByClassId()
    ClassLoginInitialLoad()

    // 
    const { error: errorClassLoginSubscription } = useSubscription<
        OnMutateCheckInSessionByClassIdSubscription,
        OnMutateCheckInSessionByClassIdSubscriptionVariables
    >(
        gql`
        ${onMutateCheckInSessionByClassId}
      `,
        {
            variables: { classID: currentClassLogin?.classID },
            skip: !currentClassLogin,
            onData: updateListQueryCache<ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables, OnMutateCheckInSessionByClassIdSubscription>({ listQuery: listCheckInSessionsByClassId, listQueryName: 'listCheckInSessionsByClassId', subscriptionName: "onMutateCheckInSessionByClassId", variables: { classID: currentClassLogin?.classID || "", limit: 5000 } })
        }
    );

    if (errorClassLoginSubscription !== undefined) {
        handleRestartApp()
    }

    useEffect(() => {
        rvSwitchesScreenState(currentScreen)
    }, [useReactiveVar(rvSwitchesScreenState)])

    return (
        <RootView>
            <SideMenu.Navigator
                screenOptions={{
                    headerShown: true,
                    header: props => <ClassLoginHeader {...props} />,
                    swipeEdgeWidth: 0
                }}
                backBehavior="history"
                initialRouteName={ScreenNames.ClassLoginHome}
            >
                <SideMenu.Screen name={ScreenNames.Placeholder} component={PlaceHolderComponent} />

                <SideMenu.Screen name={ScreenNames.ClassLoginHome} component={ClassLoginCheckInMain} options={{ title: 'ClassLoginHome' }} />
                <SideMenu.Screen name={ScreenNames.NotFound} component={NotFoundPage} />


                {/* Switches Navigation */}
                <SideMenu.Screen name={ScreenNames.SwitchesHome} component={SwitchesHome} />
                <SideMenu.Screen name={ScreenNames.SwitchesExploreAll} component={SwitchesExploreAll} />
                <SideMenu.Screen name={ScreenNames.Card} component={Card} />
                <SideMenu.Screen name={ScreenNames.SwitchesMemes} component={SwitchesMemes} />
                <SideMenu.Screen name={ScreenNames.SwitchesEmotionsDetailView} component={SwitchesEmotionsDetailView} />
                <SideMenu.Screen name={ScreenNames.SwitchesActiviesDisplayAll} component={SwitchesActiviesDisplayAll} />
                <SideMenu.Screen name={ScreenNames.SwitchesActiviesDetailView} component={SwitchesActiviesDetailView} />
                <SideMenu.Screen name={ScreenNames.SwitchesToolsDisplayAll} component={SwitchesToolsDisplayAll} />
                <SideMenu.Screen name={ScreenNames.SwitchesExploreAllMain} component={SwitchesExploreAllMain} />
                <SideMenu.Screen name={ScreenNames.SwitchesToolsMain} component={SwitchesToolsMain} />
                <SideMenu.Screen name={ScreenNames.SwitchesHomeMain} component={SwitchesHomeMain} />
                <SideMenu.Screen name={ScreenNames.SwitchesActivityMain} component={SwitchesAcitiviesMain} />
            </SideMenu.Navigator>
            <View style={styles.bottomNavigation}>
                <SwitchesBottomNavigation currentScreen={currentScreen} />
            </View>
        </RootView>
    )
}

const styles = StyleSheet.create({


    bottomNavigation: {
        bottom: 0,
        position: 'relative',
    },

})
