import { Linking, StyleSheet, Text, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { MyEmotionsLessonPlan1 } from './src/my-emotions-lesson-plan1'
import { ToolsTheLittleBookOfBigEmotions } from '../tools/cards/the-little-book-of-big-emotions'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

const myEmotionsVideo1: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/3fTRWpf-eH4",
    title: ""
}

const myEmotionsVideo2: Videos = {
    id: "2",
    url: "https://www.youtube.com/embed/MeNY-RxDJig",
    title: ""
}

export const SwitchesLessonPlansMyEmotionsLessonPlans: CardTemplate = {
    cardId: "d01cc011-39d0-44f8-bdf6-a22e2f098434",
    title: 'Exploring emotions',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify and name different emotions </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand that everyone experiences emotions </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Recognise that it is okay to have a range of emotions </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Watch this video explaining feelings and emotions:<DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text={myEmotionsVideo1.url} link={myEmotionsVideo1.url} /> Why do we have feelings? - Feelings and Emotions for Kids and/or refer to the “So, why do we have Emotions?” section of the Little Book of Big Emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to look at the level 1 emotion wheel (slide 3) and ask them to pick an emotion reflective of the emotion they are currently feeling. Call on volunteers to share with the class and ask them to elaborate on how they know they feel that way.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Physiology of Emotion tool in the Switch4Schools app and explain to students how the different emotions present in the body.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Instruction (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that emotions are feelings that we experience inside our body. They can be positive, like happiness or excitement, or negative, like sadness or anger. Emotions help us understand and react to different situations in our lives. For example, if you see a cute puppy, you might feel happy and want to pet it. If someone is mean to you, you might feel angry or sad. Our brain, heart, and other parts of our body all work together to help us feel emotions. It's important to learn how to understand and express our emotions in healthy ways. (slide 4) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that some of these reactions happen inside the body (you can’t see) like the feeling of butterflies in the stomache. And some happen to the visible parts of our body, like clenching your fists or crying.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recognising how others are feeling (slide 5) Watch this video ask the students to guess the emotion or feeling <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text={myEmotionsVideo2.url} link={myEmotionsVideo2.url} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ OPTIONAL Movement Break: (slide 6) Name Chain </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Everybody stand up!</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Say the name of somebody else in the class then go and sit in their chair.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ That person then says the name of somebody else and goes and sits in their chair</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Keep going until everybody is sitting in a new spot.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present an extract from the Little Book of Big Emotions explaining how emotions came to be, what it means (slides 7 – 13)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that being tired is not an emotion (slide 14) but impacts the way we feel. Reinforce the importance of sleep for managing emotions and mental health overall. Ask students to indicate with a show of hands what their current energy level is based on the Switch4Schools five point scale:</DefaultText>
                        <View style={styles.imageContainer}>
                            <MyEmotionsLessonPlan1 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ OPTIONAL Movement Break: Shakedown (slide 15)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Everyone stands in a circle with at least an arm’s length of space on either side of them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ The group performs the “shake down” all together – 8 seconds to shake the right arm, 8 seconds to shake the left arm, 8 seconds to shake the right leg, and 8 seconds to shake the left leg. Each time, counting “1 - 2 - 3 - 4 - 5 - 6 - 7 - 8!”  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Next, 4 shakes of each limb. Then 2 shakes of each. Then 1 shake of each.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ The shake down ends with a loud cheer.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that emotions are an integral part of being human, and it’s our ability to effectively manage our emotions that make us emotionally intelligent (slides 16 – 18).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to review the emotion wheel (slides 18 and 19) and choose a word that best describes how they currently feel. Explain that the emotion wheel helps us to think about emotions in a different way, thinking about the intensity level of emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Advise students there will be follow up sessions to explore the meanings of each of the words in more detail over the coming weeks.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that you might notice when you ask people how they feel they often say “fine” or “good” which is code for “I don’t want to think about it or talk about it”. (slide 20).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Confirm and create excitement about learning more about identifying and managing emotions over the coming weeks supported by the Switch4Schools program.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Setting Expectations (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that they will be using the Switch4Schools program in the classroom and provide a demonstration of the key features available to them (slide 21 and 22): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Check in process.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Switches attached to the emotion wheel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Tools</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Activities</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Memes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Confirm and create excitement about learning more about identifying and managing emotions over the coming weeks supported by the Switch4Schools program.</DefaultText>

                        
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• The Switch4Schools emotion wheel (poster or app) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='The Little Book of Big Emotions' link={ToolsTheLittleBookOfBigEmotions.openUrl || ""} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Blank paper and pens/pencils</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Exploring Emotions Slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/P1+Exploring+Emotions.pdf' /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Why do we have feelings? - Feelings and Emotions for Kids -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='Why do we have feelings? - Feelings and Emotions for Kids | Educational Videos by Mocomi' link={myEmotionsVideo1.url} />

                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={myEmotionsVideo1} />
                        </View>

                        <DefaultText style={CommonStyles.lessonPlanText}>Guess the Feelings and Emotions | Teach Emotions to Kids | Facial Expressions for Kids </DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='Guess the Feelings and Emotions | Teach Emotions to Kids | Facial Expressions for Kids' link={myEmotionsVideo2.url} />

                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={myEmotionsVideo2} />
                        </View>

                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 150,
        marginVertical: 10
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        marginVertical: 10,
    },
    noIndent: {
        paddingLeft: 0,
    },
})
