import { View, StyleSheet, ViewStyle } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultButton } from './default-button'
import { DefaultText } from './default-text'

interface Props {
    onPress: () => void,
    Icon: () => JSX.Element
    buttonText: string

}

export const Switch4SchoolButton = ({ onPress, Icon, buttonText }: Props) => {
    return (
        <>
            <DefaultButton testID={`button-${buttonText}`} style={styles.button} onPress={onPress}>
                <Icon />
                <View style={styles.textContainer}>
                    <DefaultText style={styles.buttonText}>{buttonText}</DefaultText>
                </View>
            </DefaultButton>
        </>
    )

}

const styles = StyleSheet.create({
    button: {
        height: 80,
        borderRadius: 5,
        borderWidth: 2,
        backgroundColor: DecidaColors.White,
        borderColor: DecidaColors.Green,
        paddingLeft: 40,
        paddingRight: 40,
        margin: 10,

    } as ViewStyle,

    buttonText: {
        color: DecidaColors.Black,
        paddingLeft: 10,
        fontWeight: '700',
        textAlign: 'center',
    },
    textContainer: {
        width: 180,
    },
})
