import { useQuery, useReactiveVar } from '@apollo/client';
import { AntDesign } from '@expo/vector-icons';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { Class, GetClassQueryVariables, Student } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { isDefined } from '../../common/is-defined';
import { CommonStyles } from '../common/const';
import InputDefault from '../common/input-default';
import { navigationRef } from '../common/root-navigation';
import { ScreenNames } from '../common/screen-names';
import { StepperBox } from '../common/stepper-box';
import { schoolAdminGetClassQuery, SchoolAdminGetClassQueryResponse } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-class-query';
import { ResultItemType } from './school-admin-sidebar-search-result-item';
import SchoolAdminSidebarSearchTeacherOrStudentResults from './school-admin-sidebar-search-teacher-results';
import { rvCurrentSchoolID, rvSidebarClassID } from './school-admin-state';
import { SchoolAdminFormTab } from './school-admin-types';
import { searchStudentByKeywords } from '../common/helper-api-functions';

type Props = {
    onFinishSubmit: () => void
}

const schoolAdmiStudentFormTab: { value: SchoolAdminFormTab, label: string }[] = Object.values(SchoolAdminFormTab).map((value) => ({
    value,
    label: value,
}));

const SchoolAdminFormStudent = ({ onFinishSubmit }: Props) => {
    const currentSchoolID = useReactiveVar(rvCurrentSchoolID)
    const currentClassID = useReactiveVar(rvSidebarClassID)
    const [selectedTab, setSelectedTab] = useState<SchoolAdminFormTab>(SchoolAdminFormTab.SearchExisting)

    const [studentSearch, setStudentSearch] = useState("");
    const [currentStudents, setCurrentStudents] = useState<Student[]>([])

    const debouncedSearch = useCallback(
        _.debounce(async (searchText: string) => {
            if (searchText) {
                const students = (await searchStudentByKeywords(searchText, currentSchoolID || '')) || []
                setCurrentStudents(students.filter(isDefined).filter(s => s._deleted !== true).slice(0, 10))
            } else {
                setCurrentStudents([])
            }
        }, 500),
        []
    );

    useEffect(() => {
        console.log(studentSearch)
        if (studentSearch.length > 0) {
            debouncedSearch(studentSearch);
        }
    }, [studentSearch]);


    const { data } = useQuery<SchoolAdminGetClassQueryResponse, GetClassQueryVariables>(schoolAdminGetClassQuery, { variables: { id: currentClassID } })

    const currentClass = data?.getClass as Class


    const handlePressTab = (tab: SchoolAdminFormTab) => {
        if (tab === SchoolAdminFormTab.AddNew) {
            navigationRef.current?.navigate(ScreenNames.SchoolAdminClassMain, { screen: ScreenNames.SchoolAdminCreateStudentPickAvatar, params: { classID: currentClass?.id } })
            return
        }

        setSelectedTab(tab)
    }

    return (
        <View style={[styles.container, CommonStyles.innerShadow]}>
            <Pressable style={styles.absoluteTopRight} onPress={onFinishSubmit}>
                <AntDesign name='close' size={24} color={DecidaColors.Black} />
            </Pressable>
            <StepperBox
                options={schoolAdmiStudentFormTab}
                activeIndex={selectedTab}
                onPress={handlePressTab}
                containerStyle={{ width: '100%', paddingTop: 40 }}
                height={30}
            />

            {selectedTab === SchoolAdminFormTab.SearchExisting && (
                <View style={{ width: '100%', }}>
                    <View style={styles.searchContainer}>
                        <InputDefault
                            customInputStyle={styles.inputSearchStudents}
                            placeholder="Search students.."
                            value={studentSearch}
                            onChangeText={setStudentSearch}
                        />
                    </View>
                    <SchoolAdminSidebarSearchTeacherOrStudentResults data={currentStudents} type={ResultItemType.StudentSearch} />
                </View>
            )}
        </View>
    )
}
export default SchoolAdminFormStudent


const styles = StyleSheet.create({
    absoluteTopRight: {
        position: 'absolute',
        right: 20,
        top: 20,
        zIndex: 99
    },
    confirmButton: {
        flexDirection: 'row',
        minWidth: 150,
        width: '60%',
        height: 30,
    },
    input: {
        borderRadius: 0,
    },
    container: {
        width: '90%',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 20,
        borderWidth: 1,
        paddingVertical: 20,
        paddingHorizontal: 10,
        borderColor: DecidaColors.GrayBorder,
    },
    searchContainer: {
        paddingTop: 20,
        paddingBottom: 5,
        justifyContent: 'center',
        alignContent: 'center',
    },
    inputSearchStudents: {
        borderRadius: 0,
        paddingHorizontal: 0,
        marginHorizontal: 0,
        width: '100%',
        paddingLeft: 10
    }
})  
