import { useReactiveVar } from '@apollo/client'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import { useEffect } from 'react'
import { Dimensions, ScrollView, StyleSheet, View, ViewStyle } from 'react-native'
import { CheckInUserGroup } from '../../common/constants'
import { DecidaColors } from "../../common/decida-colors"
import { isDummyStudentCheckIn } from '../../common/is-dummy-student-check-in'
import { rvCheckInState } from '../check-in/check-in-state'
import { rvClassLoginCurrentCheckIn } from '../class-login/class-login-state'
import { rvCurrentClass } from '../common/common-state'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { getStudentCheckInByCheckinsessionIDAndStudentId } from '../common/helper-api-functions'
import { isGoModeCheckInOpen } from '../common/is-go-mode-check-in-open'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { showOfflineAlert } from '../common/universal-alert'
import useConnectivity from '../common/use-connectivity'
import { lowRes, useResponsive } from '../common/use-responsive'
import { IconCheckIn } from '../icon/icon-check-in'
import IconCheckInClosed from '../icon/icon-check-in-closed'
import { IconSwitch } from '../icon/icon-switch'
import { StudentClassApprovalStatus, StudentStatus } from '../models'
import { rvTecherCurrentStudentStudentCheckIn } from '../teacher/teacher-state'
import StudentProfileCard from './student-profile-card'
import { rvCurrentStudent, rvStudentClassClasses, rvStudentJoinedClass } from './use-student-state'

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600

export const StudentHome = () => {
    const { navigate } = useNavigation<NavigationProp<any>>()
    const currentStudent = useReactiveVar(rvCurrentStudent)
    const currentClass = useReactiveVar(rvCurrentClass)
    const studentClassClasses = useReactiveVar(rvStudentClassClasses)
    const currentActiveCheckin = useReactiveVar(rvClassLoginCurrentCheckIn)
    const currentStudentCheckIn = useReactiveVar(rvTecherCurrentStudentStudentCheckIn)

    const currentStudentClass = studentClassClasses?.find(s => s.classID === currentClass?.id)
    const { scaleRatio } = useResponsive()
    const transformByRatioStyle: ViewStyle = { transform: [{ scale: lowRes ? scaleRatio : 1 }] }
    const { onlineRef } = useConnectivity()


    const joinedClass = useReactiveVar(rvStudentJoinedClass)
    useEffect(() => {
        if (joinedClass) {
            Linking.openURL(`${ScreenNames.JoinClass}/${ScreenNames.studentJoinClass}/${joinedClass}`)
        }
    }, [joinedClass])

    useEffect(() => {
        if (currentActiveCheckin && currentStudent?.id) {
            getStudentCheckInByCheckinsessionIDAndStudentId(currentActiveCheckin?.id, currentStudent?.id)
        }

    }, [currentActiveCheckin?.id, currentStudent?.id])

    const navigateToTirednessScale = () => {
        rvCheckInState({
            studentID: currentStudent?.id,
            checkinsessionID: currentActiveCheckin?.id
        })
        navigate(ScreenNames.StudentCheckIn, { screen: ScreenNames.CheckInTirednessScale, params: { classID: currentClass?.id || "", user: CheckInUserGroup.Student } })
    }

    const navigateToSwitchesHome = () => {
        navigate(ScreenNames.SwitchesHomeMain)
    }

    const onPressCheckIn = async () => {
        try {
            if (!onlineRef.current) throw new Error("You're offline")
            navigateToTirednessScale()
        } catch (error) {
            showOfflineAlert(onPressCheckIn)
        }
    }

    if (!currentStudent) {
        return null
    }

    const checkInClosed = !currentActiveCheckin && currentClass?.goMode !== true || !!currentClass?.goMode && !isGoModeCheckInOpen()

    return (
        <Page style={styles.page}>
            {currentStudent?.status !== StudentStatus.INACTIVE ?
                <>
                    <ScrollView style={[styles.scrollView, { position: 'relative' }]} contentContainerStyle={[styles.mainContent, { position: 'relative' }]}>
                        <StudentProfileCard />
                        {currentStudentClass?.status === StudentClassApprovalStatus.APPROVED
                            ?
                            <View style={styles.buttonContainer}>
                                <DefaultButton disabled={checkInClosed} disabledStyle={styles.buttonDisabled} testID='button-student-checkin' style={[checkInClosed ? styles.buttonDisabled : styles.button, transformByRatioStyle, { marginVertical: lowRes ? 0 : undefined }]} onPress={onPressCheckIn}>
                                    <View>
                                        {checkInClosed ? (
                                            <View style={styles.checkInButton}>
                                                <IconCheckInClosed />
                                                <DefaultText style={styles.buttonText}>Check-in closed</DefaultText>
                                            </View>
                                        ) : (
                                            <View style={styles.checkInButton}>
                                                <IconCheckIn stylefill={DecidaColors.Green} />
                                                <DefaultText style={styles.buttonText}>{currentStudentCheckIn && !isDummyStudentCheckIn(currentStudentCheckIn) && currentClass?.goMode !== true && 'Update' || 'Start'} Check In</DefaultText>
                                            </View>
                                        )}
                                    </View>
                                </DefaultButton>
                                {checkInClosed && (
                                    <DefaultText style={styles.sessionNotOpenedText}>Please wait until your teacher has opened the check-in session</DefaultText>
                                )}
                                <DefaultButton style={[styles.buttonFill, transformByRatioStyle, { marginVertical: lowRes ? 0 : undefined }]} onPress={navigateToSwitchesHome}>
                                    <IconSwitch height={60} width={60} />
                                    <DefaultText style={styles.whiteText}>Explore switches</DefaultText>
                                </DefaultButton>

                            </View>
                            : currentStudentClass
                                ? <View style={[styles.pendingBox, transformByRatioStyle]}>
                                    <DefaultText style={styles.pendingText}>Pending approval to join class.</DefaultText>
                                    <DefaultText style={styles.subText}>Waiting for your teacher to let you in.</DefaultText>
                                </View>
                                :
                                <DefaultText style={styles.text}>Please wait while we get things ready…</DefaultText>
                        }
                    </ScrollView>
                </>
                :
                <View style={styles.mainContent}>
                    <View style={styles.declinedBox}>
                        <DefaultText style={styles.pendingTextDeliced}>You have been declined access to the class.</DefaultText>
                        <DefaultText style={styles.subText}>Please try again or contact your teacher for more information.</DefaultText>
                    </View>
                </View>
            }

        </Page>
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
    },
    welcome: {
        fontSize: 35,
    },
    mainBanner: {
        position: 'relative',
        top: 0,
    },
    avatarContainer: {
        marginVertical: 40,
    },
    buttonTextSwitch: {
        color: DecidaColors.Black,
        paddingLeft: 10,
        fontWeight: '700',
        textAlign: 'center',
    },
    button: {
        height: 80,
        borderRadius: 20,
        borderWidth: 2,
        backgroundColor: DecidaColors.White,
        borderColor: DecidaColors.Green,
        paddingLeft: 40,
        paddingRight: 40,
        margin: 10,

    } as ViewStyle,
    buttonDisabled: {
        height: 80,
        borderRadius: 20,
        borderWidth: 2,
        backgroundColor: DecidaColors.AppleSystemGray6SuperLight,
        borderColor: DecidaColors.Gray,
        margin: 10,

    } as ViewStyle,
    buttonFill: {
        height: 80,
        borderRadius: 20,
        borderWidth: 2,
        backgroundColor: DecidaColors.Green,
        borderColor: DecidaColors.Green,
        margin: 10,
        marginBottom: 40

    } as ViewStyle,
    buttonText: {
        fontSize: 24,
        color: DecidaColors.Gray,
        paddingLeft: 10,
    },
    avatarEditContainer: {
        width: 120,
        marginBottom: 20,
    },
    editIconContainer: {
        position: 'absolute',
        right: 0,
        top: 20,
        width: 63,
        height: 63,
    },
    pendingText: {
        fontSize: windowWidthcondition ? 25 : 17,
        color: DecidaColors.Orange,
        textAlign: 'center',
        paddingBottom: 10,
    },
    pendingBox: {
        height: 100,
        borderColor: DecidaColors.Orange,
        borderWidth: 2,
        padding: 20,
        borderRadius: 8,
    },
    subText: {
        fontSize: windowWidthcondition ? 17 : 15,
        textAlign: 'center',
    },
    text: {
        padding: 10,
        textAlign: "center",
    },
    exploreActivies: {
        marginTop: 10,
    },
    checkinSessionText: {
        textAlign: 'center',
        fontSize: 16,
        marginBottom: 20,
    },
    declinedBox: {

        height: 100,
        borderColor: DecidaColors.Red,
        borderWidth: 2,
        padding: 20,
        borderRadius: 8,
    },
    pendingTextDeliced: {
        color: DecidaColors.Red,
        textAlign: 'center',
        fontSize: 24,
    },
    mainContent: {
        alignItems: 'center',
        marginTop: 20
    },
    scrollView: {
        width: '100%'
    },
    whiteText: {
        fontSize: 24,
        color: DecidaColors.White
    },
    buttonContainer: {
        alignItems: 'center',
        maxWidth: 330
    },
    sessionNotOpenedText: {
        fontStyle: "italic",
        textAlign: 'center',
        color: DecidaColors.Gray,
        fontSize: 16
    },
    checkInButton: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row'
    }
})