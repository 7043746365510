import React from 'react';
import { StyleSheet, View, ViewStyle, TouchableOpacity } from 'react-native';
import { DecidaColors } from '../../common/decida-colors';
import { DefaultText } from './default-text';
import { PawnPrint } from './svg-files/paw-print';
import { KnifeAndFork } from './svg-files/knife-and-fork';
import { FontAwesome, MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';

interface Props {
    V1: boolean;
    V2: boolean;
    V3: boolean;
    V4: boolean;
    V5: boolean;
    V6: boolean;
    V1pressed: () => void;
    V2pressed: () => void;
    V3pressed: () => void;
    V4pressed: () => void;
    V5pressed: () => void;
    V6pressed: () => void;
}

const getItemStyle = (selected: boolean, type: string): ViewStyle => {
    const baseStyle: ViewStyle = {
        flexDirection: 'row',
        alignItems: 'center'
    };
    const paddingStyle = type === 'food' ? { paddingHorizontal: 5 } : {};
    const borderStyle = selected ? { borderBottomWidth: 4, borderColor: DecidaColors.Green } : {};

    return { ...baseStyle, ...paddingStyle, ...borderStyle };
};

export const Pagination = ({ V1, V2, V3, V4, V5, V6, V1pressed, V2pressed, V3pressed, V4pressed, V5pressed, V6pressed }: Props) => {
    const items = [
        { key: 'animal', selected: V1, onPress: V1pressed, Icon: <PawnPrint />, },
        { key: 'food', selected: V2, onPress: V2pressed, Icon: <KnifeAndFork /> },
        { key: 'monster', selected: V3, onPress: V3pressed, Icon: <FontAwesome name="optin-monster" size={24} color={DecidaColors.Gray} /> },
        { key: 'people', selected: V4, onPress: V4pressed, Icon: <MaterialIcons name="people" size={30} color={DecidaColors.Gray} /> },
        { key: 'origami', selected: V5, onPress: V5pressed, Icon: <FontAwesome name="paper-plane-o" size={24} color={DecidaColors.Gray} />},
        { key: 'ocean', selected: V6, onPress: V6pressed, Icon: <MaterialCommunityIcons name="jellyfish-outline" size={30} color={DecidaColors.Gray} /> }
    ];

    return (
        <View style={styles.container}>
            {items.map(item => (
                <TouchableOpacity key={item.key} style={styles.containingView} onPress={item.onPress}>
                    <View style={getItemStyle(item.selected, item.key)}>
                        <View style={styles.animalIcon}>{item.Icon}</View>
                        <View style={styles.animalIconTextContainer}>
                            <DefaultText style={styles.primaryHeading}>{item.key.charAt(0).toUpperCase() + item.key.slice(1)}</DefaultText>
                            <DefaultText style={styles.secondaryHeading}>Avatars</DefaultText>
                        </View>
                    </View>
                </TouchableOpacity>
            ))}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
        paddingBottom: 25
    } as ViewStyle,
    primaryHeading: {
        color: DecidaColors.Gray
    },
    secondaryHeading: {
        color: DecidaColors.Gray,
        fontSize: 12
    },
    containingView: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 20
    },
    animalIcon: {
        height: 25,
        width: 25,
        marginHorizontal: 10
    },
    animalIconTextContainer: {
        flexDirection: 'column'
    }
});
