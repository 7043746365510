import { GestureResponderEvent, Platform, StyleProp, StyleSheet, Text, TextProps, TextStyle } from 'react-native'
import { DefaultFontSize } from './const'

interface Props {
    children: string | string[] | undefined | null | React.ReactNode
    style?: StyleProp<TextStyle>
    onPress?: (event: GestureResponderEvent) => void
    textProps?: TextProps
}



export const DefaultText = ({ children, style, onPress, textProps }: Props) =>
    <Text selectable={Platform.OS === 'web'} onPress={onPress} style={StyleSheet.flatten([Platform.OS === 'web' ? { 'wordBreak': 'break-word' } as TextStyle
        :
        {},
    Platform.OS === 'windows' ? styles.windowstext :
        Platform.OS === 'android' ? styles.androidText
            : styles.text, style])}
        {...textProps}
    >{children}
    </Text>

const styles = StyleSheet.create({

    text: {
        fontSize: DefaultFontSize,
        fontFamily: "HelveticaNeue",
    } as TextStyle,
    androidText: {
        fontSize: DefaultFontSize,
        fontFamily: "HelveticaNeue",
    } as TextStyle,
    windowstext: {
        fontSize: DefaultFontSize,
        fontFamily: "HelveticaNeue",
    } as TextStyle
})