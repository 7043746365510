import { gql, useQuery, useSubscription } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { CheckInSession, Class, GetClassQuery, GetClassQueryVariables, ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables, ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables, OnMutateCheckInSessionByClassIdSubscription, OnMutateCheckInSessionByClassIdSubscriptionVariables, OnMutateClassByIdSubscription, OnMutateClassByIdSubscriptionVariables, OnMutateStudentCheckInByClassIdSubscription, OnMutateStudentCheckInByClassIdSubscriptionVariables, OnMutateStudentClassByClassIdSubscription, OnMutateStudentClassByClassIdSubscriptionVariables } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { getClass, listCheckInSessionsByClassId, listStudentCheckInsByCheckInSessionId } from '../../common/graphql/queries'
import { onMutateCheckInSessionByClassId, onMutateClassById, onMutateStudentCheckInByClassId, onMutateStudentClassByClassId } from '../../common/graphql/subscriptions'
import { BreakPoints, DefaultFontSize } from '../common/const'
import { DefaultText } from '../common/default-text'
import { handleRestartApp } from '../common/handle-restart-app'
import { insertNewItemIntoListStudentClassAndStudentDataByClassIdCustomQuery } from '../common/insert-new-item-into-list-student-class-and-student-data-by-class-id-custom-query'
import { updateListQueryCache } from '../common/update-list-query-cache'
import { useResponsive } from '../common/use-responsive'
import TeacherClassNavigationOption from './teacher-class-navigation-option'
import TeacherHomeClassGraphs from './teacher-home-class-graphs'
import TeacherHomeFlaggedStudents from './teacher-home-flagged-students'

type Props = {
    classID: string
}

export const TEACHER_HOME_PADDING = 20

const TeacherHomeClass = ({ classID }: Props) => {

    const [currentClassID, setCurrentClassID] = useState("")

    useEffect(() => {
        setCurrentClassID(classID)

        return () => {
            setCurrentClassID("")
        }
    }, [classID])

    const { data: classResponse, loading: loadingCLass } = useQuery<
        GetClassQuery,
        GetClassQueryVariables
    >(
        gql`
        ${getClass}
      `,
        {
            variables: { id: classID },
            skip: !classID,
        }
    );

    const { data: checkInSessionsResponse, loading: loadingChekInSessions } =
        useQuery<
            ListCheckInSessionsByClassIdQuery,
            ListCheckInSessionsByClassIdQueryVariables
        >(
            gql`
          ${listCheckInSessionsByClassId}
        `,
            {
                variables: { classID, limit: 5000 },
                skip: !classID,
            }
        );

    const { width } = useResponsive()

    const widthIsLessThanBreakpointsMedium = width < BreakPoints.M

    const clazz = classResponse?.getClass
    const classCheckInSessions = _.orderBy(checkInSessionsResponse?.listCheckInSessionsByClassId?.items.filter((item) => item?._deleted !== true), "updatedAt", "desc")
    const lastCheckInSession = _.first(classCheckInSessions)
    const isCheckInSessionActive = lastCheckInSession?.isActive

    const { error: errorClassSubscription } = useSubscription<OnMutateClassByIdSubscription, OnMutateClassByIdSubscriptionVariables>(gql`${onMutateClassById}`, { variables: { id: currentClassID }, skip: !currentClassID })

    const { error: errorStudentCheckInSubscription } = useSubscription<
        OnMutateStudentCheckInByClassIdSubscription,
        OnMutateStudentCheckInByClassIdSubscriptionVariables
    >(
        gql`
        ${onMutateStudentCheckInByClassId}
      `,
        {
            variables: { classID: clazz?.id || "" },
            onData: updateListQueryCache<
                ListStudentCheckInsByCheckInSessionIdQuery,
                ListStudentCheckInsByCheckInSessionIdQueryVariables,
                OnMutateStudentCheckInByClassIdSubscription
            >({
                listQuery: listStudentCheckInsByCheckInSessionId,
                listQueryName: 'listStudentCheckInsByCheckInSessionId',
                subscriptionName: 'onMutateStudentCheckInByClassId',
                variables: {
                    checkinsessionID: classCheckInSessions?.length
                        ? classCheckInSessions[0]?.id || ''
                        : '',
                    limit: 5000,
                },
            }),
            skip: currentClassID === ""
        }
    );

    const { error: errorCheckInSessions } = useSubscription<
        OnMutateCheckInSessionByClassIdSubscription,
        OnMutateCheckInSessionByClassIdSubscriptionVariables
    >(
        gql`
        ${onMutateCheckInSessionByClassId}
      `,
        {
            variables: { classID: clazz?.id || "" },
            onData: updateListQueryCache<
                ListCheckInSessionsByClassIdQuery,
                ListCheckInSessionsByClassIdQueryVariables,
                OnMutateCheckInSessionByClassIdSubscription
            >({
                listQuery: listCheckInSessionsByClassId,
                listQueryName: 'listCheckInSessionsByClassId',
                subscriptionName: 'onMutateCheckInSessionByClassId',
                variables: { classID, limit: 5000 },
            }),
            skip: currentClassID === ""
        }
    );

    const { error: errorStudentClassSubscription } = useSubscription<
        OnMutateStudentClassByClassIdSubscription,
        OnMutateStudentClassByClassIdSubscriptionVariables
    >(
        gql`
      ${onMutateStudentClassByClassId}
    `,
        {
            variables: { classID: currentClassID || '' },
            onData: insertNewItemIntoListStudentClassAndStudentDataByClassIdCustomQuery({ classID: currentClassID || "" }),
            skip: currentClassID === '',
        }
    );

    if (
        errorStudentCheckInSubscription !== undefined ||
        errorStudentClassSubscription !== undefined ||
        errorCheckInSessions !== undefined ||
        errorClassSubscription !== undefined
    ) {
        handleRestartApp()
    }

    if (!clazz || clazz?.archived || clazz?._deleted) {
        return null
    }

    return (
        <View style={[styles.classContainer]}>
            {(loadingCLass || loadingChekInSessions) ? (
                <View style={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <ActivityIndicator size={30} color={DecidaColors.Green} />
                </View>
            ) : (
                <View style={{ flex: 1, width: '100%' }}>
                    <DefaultText style={styles.classNameText}>{clazz?.goMode ? `*${clazz.name}` : clazz?.name}</DefaultText>
                    <View style={[styles.classContentWrapper, { flexDirection: widthIsLessThanBreakpointsMedium ? 'column' : 'row', }]}>
                        <View style={widthIsLessThanBreakpointsMedium ? { flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' } : {}}>
                            <View>
                                <TeacherClassNavigationOption clazz={clazz as Class} />
                            </View>
                            <View>
                                {!widthIsLessThanBreakpointsMedium && <View style={styles.separator} />}
                                <DefaultText style={styles.lastCheckInLabelText}>{isCheckInSessionActive ? "Current check-in" : "Last check-in"}</DefaultText>
                                <DefaultText style={styles.lastCheckInValueText}>{isCheckInSessionActive ? "In progress" : lastCheckInSession?.updatedAt ? moment(lastCheckInSession.updatedAt).format('DD/MM h:mm') : "No data as yet"}</DefaultText>
                            </View>
                        </View >

                        {/* FLAGGED STUDENTS */}
                        < View style={{ flex: 1, marginLeft: TEACHER_HOME_PADDING, marginBottom: TEACHER_HOME_PADDING }}>
                            <TeacherHomeFlaggedStudents clazz={clazz as Class} />
                        </ View>

                        {
                            !clazz?.goMode && (
                                <TeacherHomeClassGraphs classCheckInSessions={classCheckInSessions as CheckInSession[]} />
                            )
                        }
                    </View>
                </View>
            )}

        </View >
    )
}
export default TeacherHomeClass

const styles = StyleSheet.create({
    separator: {
        marginBottom: 5,
        borderBottomWidth: 0.5,
        borderBottomColor: DecidaColors.Black,
    },
    lastCheckInValueText: {
        fontSize: DefaultFontSize + 2,
        color: DecidaColors.GrayText,
        fontWeight: 'bold',
        paddingHorizontal: 10
    },
    lastCheckInLabelText: {
        fontSize: DefaultFontSize - 4,
        color: DecidaColors.GrayText,
        paddingHorizontal: 10
    },
    classNameText: {
        fontSize: 28,
        fontWeight: 'bold',
        color: DecidaColors.GrayText,
    },
    classContainer: {
        paddingVertical: TEACHER_HOME_PADDING,
        paddingStart: 20,
        borderWidth: 0.5,
        borderRadius: 20,
        borderColor: DecidaColors.GrayText,
        marginBottom: 40,
    },
    classContentWrapper: {
        alignItems: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        width: '100%',
    },
})