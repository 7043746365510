import { View } from 'react-native'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import LessonPlanTable from '../../common/lesson-plan-table'
import { Worksheets } from '../../common/worksheets-imports'

const tableData = [
    {
        title: 'Conflict with a Friend',
        description: `Scenario: Your best friend has been acting distant lately, and you suspect they are upset with you. During the role play, your friend expresses their frustration, but they don't provide specific reasons for their feelings. \n\n Your goal is to use empathy and effective communication to understand their perspective and resolve the conflict.`
    },
    {
        title: 'Group Project Disagreement',
        description: "Scenario: You're working on a group project with three classmates, and there's a difference of opinion on the project's direction. Two of your group members want to take it in one direction, while you and another classmate have a different vision. \n\n The role play involves discussing the project's direction, finding common ground, and ensuring everyone feels heard and valued. ",
    },
    {
        title: 'Parent Conflict',
        description: "Scenario: You have been staying out late with friends without letting your parents know. They've expressed concerns about your safety and responsibility. \n\n The role play involves a conversation with your parents, where you aim to empathise with their worries, express your own perspective, and work together to come up with a compromise that addresses both their concerns and your desire for independence. ",
    },
];

export const SwitchesLessonPlansM5InvestigatingAnger: CardTemplate = {
    cardId: "7dae66a5-d109-4ccc-9b20-215961ac26b8",
    title: 'Investigating anger: Building EI',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the concept of anger and its impact on emotional wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Develop awareness of personal anger triggers and learn strategies for anger management.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Enhance emotional intelligence skills by fostering empathy and effective communication during moments of anger.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>• Duration 45 minutes </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by asking students to share their thoughts on anger. What does anger mean to them? How does it affect their lives and relationships?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that anger is a natural emotion that everyone experiences, but it is important to understand and manage it effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to or distribute the Investigating Anger  workbook to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present the level 7 emotion wheel with an emphasis on the relevant quadrant.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the physical and emotional signs of anger and its potential consequences on personal wellbeing and relationships. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Triggers (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss common anger triggers, such as frustration, fear, injustice, or feeling unheard. Ask students to share their own triggers if they feel comfortable doing so.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide scenarios  depicting different anger triggers and ask students to identify the triggers present and discuss alternative ways to respond to these situations. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 1: Sibling Conflict </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Liam and his younger sister Lily often argue over sharing their video game console. One day, Liam is playing a game when Lily abruptly interrupts and demands her turn. Liam gets angry and starts yelling at her.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Trigger: The trigger in this scenario is the frustration of sharing the video game console and the interruption of Liam's gameplay.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Alternative Response: Instead of yelling, Liam could take a deep breath and calmly express his feelings, suggesting a compromise or setting a time limit for turns. They could also involve a parent or guardian to mediate and find a fair solution.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 2: Unfair Treatment</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Sophie is an active participant in her school's basketball team. During a game, she feels that the referee consistently makes unfair calls against her team. She becomes increasingly frustrated, and eventually, she throws her water bottle in anger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Trigger: The trigger in this scenario is the perception of unfair treatment by the referee, leading to frustration and anger. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Alternative Response: Instead of throwing her water bottle, Sophie could take a moment to calm down and approach the coach or captain to discuss her concerns. She could also channel her anger into more constructive actions, such as focusing on improving her performance or discussing the issue with the referee after the game in a respectful manner.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 3: Academic Pressure </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Jessica is preparing for a big exam, and she has been studying for weeks. On the day of the exam, she realises that she has forgotten her calculator at home. Frustrated and overwhelmed, she slams her hand on the desk and starts cursing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Trigger: The trigger in this scenario is the added stress of forgetting a necessary tool for the exam, leading to frustration and anger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Alternative Response: Instead of reacting with anger, Jessica could take a few deep breaths to calm herself down. She could ask the teacher for a spare calculator or find alternative problem solving methods that don't require a calculator. If she cannot find a solution, she could consider speaking to the teacher or seeking support after the exam to discuss the situation and explore possible solutions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a class discussion to explore strategies for identifying personal anger triggers and developing awareness of their impact on emotions and behaviour.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Anger Management Techniques (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce various anger management techniques designed to dial down the intensity of the emotion, such as deep breathing, counting to ten, taking a break, and expressing feelings through journaling or artwork. Explain how these techniques can help regulate emotions during moments of anger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students access the Switch4Schools program on their device and encourage them to explore the available switches assigned to angry on the emotion wheel to bookmark activities they’d like to try.  Alternatively provide a copy of the level 7 quick reference guide. This could also be a homework activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the benefits of some of the techniques selected by the group and when they might be most helpful.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Empathy and Effective Communication (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of empathy and effective communication in managing anger and resolving conflicts.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Empathy and effective communication are essential tools in managing anger and resolving conflicts. They play a pivotal role in maintaining healthy relationships, both in personal and professional settings. Here's why they are so crucial.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Active Listening: Active listening involves not just hearing the words someone is saying but truly understanding their perspective and emotions. When you actively listen, you demonstrate that you value the other person's viewpoint. This can help de-escalate anger and create a more open and receptive atmosphere for communication. When people feel heard, they are more likely to be cooperative and willing to find solutions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Expressing Emotions Constructively: Anger is a natural emotion, but how it's expressed makes all the difference. Effective communication involves expressing your emotions in a constructive and non-aggressive way. Instead of shouting or using hurtful language, express your feelings in a calm and respectful manner. This not only prevents escalation but also encourages the other party to do the same.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Seeking Understanding Rather Than Retaliation: Instead of trying to "win" an argument or retaliate when you're angry, focus on seeking understanding. Ask questions to clarify the other person's perspective and try to understand their motivations and concerns. This can help uncover common ground and shared goals, making conflict resolution more attainable.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Creating a Safe Space: Empathy and effective communication can establish a safe space for discussing difficult topics. When people feel safe and respected, they are more likely to open up and be honest about their feelings and needs. This safe space is conducive to problem solving and finding compromises.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Preserving Relationships: Healthy relationships are built on trust and understanding. When anger and conflict arise, empathetic and effective communication can prevent irreparable damage to relationships. By working through issues with these skills, you can strengthen your bonds with others and build more resilient connections.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Conflict Resolution: Conflict is a part of life, but it's how we manage it that makes all the difference. Empathy and effective communication are key tools in the conflict resolution process. They help identify the underlying issues, find common ground, and work towards mutually beneficial solutions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Personal Growth: Practicing empathy and effective communication can lead to personal growth. It helps you become more self aware and better equipped to handle challenging situations. It also fosters emotional intelligence, which is valuable not only in managing anger and conflicts but also in various aspects of life.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show video clips or provide real life scenarios depicting anger and conflict. Pause at key moments to analyse the communication skills displayed and discuss alternative approaches. For example Prince Charles & Diana </DefaultText>
                        <View style={CommonStyles.lessonPlanResourceVideoContainer}>
                            <VideoCard video={{
                                id: "1",
                                url: "https://www.youtube.com/embed/Tedqw0gMuCI"
                            }} />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide the class into pairs and provide them with conflict scenarios. Instruct each pair to role play the scenarios using effective communication and empathy skills. </DefaultText>
                        <LessonPlanTable tableData={tableData} />
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude the activity with a class discussion on the importance of empathy, active listening, and respectful communication in managing anger and building healthier relationships. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the main points discussed during the lesson, emphasising the importance of emotional intelligence in understanding and managing anger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on the lesson and write down at least one thing they learnt and one thing they will do as a result of this lesson in their workbook.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss any challenges or insights they gained from the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- Encourage students to apply the strategies learned in their daily lives, both inside and outside the classroom.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- In addition to checking in via the Switch program, encourage students to make use of the space in their workbook to journal their thoughts and feelings over the coming week to better understand their triggers and improve their coping strategies.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Relevant video clips (optional)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Level 7 emotion wheel'} link={"https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/LV7+how+are+you+feeling+today+cut+out.pdf"} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Level 7 quick reference guide' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/level+7+QRG.pdf' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Mastering the mind workbook, including section M5: Investigating Anger: Building Emotional Intelligence'} link={Worksheets.masteryMasteringTheMindLevel7.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Value of Anger: 16 Reasons It’s Good to Get Angry  -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.goodtherapy.org/blog/value-of-anger-16-reasons-its-good-to-get-angry-0313175' link='https://www.goodtherapy.org/blog/value-of-anger-16-reasons-its-good-to-get-angry-0313175' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Anger and teenagers-</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://parents.au.reachout.com/common-concerns/everyday-issues/anger-and-teenagers?gclid=EAIaIQobChMIoc23x9Ln_wIVTcGWCh2MBQ6DEAAYASAAEgKnifD_BwE' link='https://parents.au.reachout.com/common-concerns/everyday-issues/anger-and-teenagers?gclid=EAIaIQobChMIoc23x9Ln_wIVTcGWCh2MBQ6DEAAYASAAEgKnifD_BwE' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to stop a bully-</DefaultText>
                        <View style={CommonStyles.lessonPlanResourceVideoContainer}>
                            <VideoCard video={{
                                id: '',
                                url: 'https://www.youtube.com/embed/7oKjW1OIjuw'
                            }} />
                        </View>
                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}