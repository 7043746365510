import { StyleSheet, View, ViewStyle } from 'react-native'
import { CommonStyles } from '../../common/const'
import { useResponsive } from '../../common/use-responsive'

export type Props = {
    LeftContent: () => JSX.Element
    RightContent: () => JSX.Element
    isLeftImageContent?: boolean
    isRightImageContent?: boolean
}

const SwitchCardJuniorContentTwoColumns = ({ LeftContent, RightContent, isLeftImageContent, isRightImageContent }: Props) => {

    const { isNarrowScreen } = useResponsive()

    return (
        <View style={CommonStyles.flexContainer}>
            <View style={[
                isNarrowScreen ? styles.innerContainerMobile : styles.innerContainer,
                isLeftImageContent && isNarrowScreen ? { ...styles.imageContainerMobile } : undefined // apply image style if the content is image component
            ]}>
                <LeftContent />
            </View>
            <View style={[
                isNarrowScreen ? styles.innerContainerMobile : styles.innerContainer,
                isRightImageContent && isNarrowScreen ? { ...styles.imageContainerMobile } : undefined // apply image style if the content is image component
            ]}>
                <RightContent />
            </View>
        </View>
    )
}

export default SwitchCardJuniorContentTwoColumns

const styles = StyleSheet.create({
    innerContainer: {
        flex: 1,
        height: 350,
        marginVertical: 20,
        justifyContent: 'center'
    },
    innerContainerMobile: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    imageContainerMobile: {
        flex: 0,
        maxHeight: 300,
        flexGrow: 1,
    },
    contentText: {
        textAlign: 'center',
    },

})