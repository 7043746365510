import { AVATAR_SIZE } from "../teacher/teacher-consts";
import { AverageCharacterHeight, AverageCharacterWidth } from "./const";

export const calculateMaxAvatarContainerHeightWithUsername = (longestLetter: number, containerWidth: number,) => {
    const numberOfCharactersPerLine = Math.floor(containerWidth / AverageCharacterWidth);
    const numberOfLines = Math.ceil(longestLetter / numberOfCharactersPerLine);
    const cognitoUserNameHeight = numberOfLines * AverageCharacterHeight;

    return AVATAR_SIZE + // Avatar height
        cognitoUserNameHeight + // cognitoUserNameHeight
        AverageCharacterHeight * 4; // firstName, lastName, (day, month, and year)
}
