import { View } from 'react-native'
import { DefaultTextWithDownArrow } from '../../../common/default-text-with-down-arrow'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { JigsawIcon } from '../../icons/jigsaw-icon'
import { DefaultTextWithExtraTipIcon } from '../../../common/default-text-with-extra-tip-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { JigsawIconInverted } from '../../icons/jigsaw-icon-inverted'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'

export const SwitchesActivitiesHealthyHabitsJigsaw: CardTemplate = {
    cardId: "90335ad6-eeaa-4598-b917-e46726520bfa",
    title: 'Jigsaw',
    Header: JigsawIconInverted,
    Icon: JigsawIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Practicing focus, making order out of chaos, and helping quiet anxious or negative thought patterns."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={`Choose puzzles that are appropriate for the age and skill of the group. `} />
            <DefaulTextWithTipsIcon textdata={"Consider puzzles that reinforce content being learned in class, for example a map of the world can be a great addition to various geography lessions or assignments. "} />
            <DefaulTextWithTipsIcon textdata={"This can be a great suggestion for some non-screen based down-time at home."} />
            <DefaulTextWithTipsIcon textdata={`A lot of puzzles will take more that one session to be completed so a great way to get things going is to set a target number of pieces to be completed each day, and before you know it the puzzle is done!`} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Clear a space that you can dedicate to ‘jigsaw making’ for a number of weeks."} />
                <DefaultTextWithDownArrow textdata={"Set aside time in your day for ‘jigsaw time’."} />
                <DefaultTextWithoutArrows textdata={"Allow other friends or family members to contribute and be part of the hypnotic relaxation that is jigsaw completing!"} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.HEALTHY_HABITS,
    whyDoesItWorkText: 'Not only can relaxation and focus improve our mental health, it can improve our physical health too. Just as too much stress weakens the immune system, regular relaxation helps our immune system function better. Doing hypnotic relaxation activities also supports the cardiac autonomic nervous system, which regulates heart function and can even control pain and accelerate wound healing.',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Jigsaw.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.HEALTHY_HABITS],
    action: [CardActionType.Action],
    level: []
}

