import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Platform
} from 'react-native';
import { Polyline, Svg } from 'react-native-svg';
import CanvasContainerView, { IEventTypes } from './canvas-container-view';

type Props = {
  assignClearPathFunction: (func: () => void) => void
}

export default ({ assignClearPathFunction }: Props) => {
  const [paths, setPaths] = useState<string[]>([])

  const handleClear = () => {
    setPaths([])
  }

  useEffect(() => {
    assignClearPathFunction(handleClear)
  }, [assignClearPathFunction])

  const onTouchMove = (event: IEventTypes) => {
    event.preventDefault()
    event.stopPropagation()
    const locationX = Platform.OS === 'web' ? (event.nativeEvent.offsetX || event.layerX) : event.nativeEvent.locationX;
    const locationY = Platform.OS === 'web' ? (event.nativeEvent.offsetY || event.layerY) : event.nativeEvent.locationY;

    const newPath = `${locationX},${locationY}`

    if (locationX === undefined || locationY === undefined) {
      return
    }

    setPaths(prev => [...prev, newPath])
  }


  return (
    <CanvasContainerView onMoveStart={onTouchMove}>
      <Svg width={'100%'} height={'100%'} style={{ position: 'absolute', bottom: 0, top: 0, left: 0, right: 0 }}>
        <Polyline
          points={paths.join(' ')}
          fill="none"
          stroke={"red"}
          strokeWidth={3}
        />
      </Svg>
    </CanvasContainerView>
  );
};