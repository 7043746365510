import { StyleSheet } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

export const SwitchesLessonPlansIntroducingSad: CardTemplate = {
    cardId: "b9cf66c2-259c-4f90-96a9-6e70880b9ee9",
    title: 'Introducing sad',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Deepen their understanding of the emotion "sad" by identifying its characteristics, expressing what sadness looks and feels like, and exploring strategies for coping with and supporting others when they feel sad.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45-60 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Review of Emotions (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by briefly reviewing the emotions learned in the previous lessons, including "sad".</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to recall what they remember about the emotion "sad" and share examples of when they have felt sad.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Characteristics of Sadness (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the “Feeling sad” video [2:33].</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set up a large chart paper on an easel or wall.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write "Sadness" at the top of the chart and invite the students to brainstorm words or phrases that describe what sadness looks and feels like.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write their responses on the chart paper, encouraging them to explain their choices and expand on the ideas shared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss common characteristics, such as feeling teary, having a heavy heart, or wanting to be alone.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Identifying Sadness Triggers (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share situations that might make someone feel sad.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss their responses and encourage them to share personal experiences or observations of others feeling sad in similar situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that feeling sad is a normal emotion and that it's important to be understanding and supportive of others when they feel sad.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Expressing Sadness (10 minutes)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite the students to express what sadness looks like to them by drawing a picture or using art supplies to create a visual representation of sadness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to use colours, lines, and shapes that they associate with feeling sad.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide an opportunity for volunteers to share their artwork and describe what they depicted, emphasising that there are different ways sadness can be expressed.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Coping with Sadness (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce strategies for coping with sadness, such as talking to a trusted adult, engaging in comforting activities like listening to music or hugging a stuffed animal, or expressing their feelings through drawing or writing. Refer to the available switches for sadness for inspiration.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss each strategy and encourage the students to share any strategies they personally use when they feel sad.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of seeking support and engaging in activities that help them feel better when they are feeling sad.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Supporting Others (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of supporting others when they feel sad.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Brainstorm ideas together about how they can help a friend or family member who is feeling sad, such as offering a hug, listening, or doing something kind for them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage empathy and understanding, reminding them that everyone feels sad at times, and it's important to be there for one another.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflecting on Sadness (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students back in the circle.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to reflect on what they learned about sadness and how it feels to be sad.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to share their artwork or describe a situation that makes them feel sad.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss strategies for coping with sadness and supporting others, reminding them that it's okay to reach out for help when needed.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>Extension Activities:</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Read books or tell stories about sadness and discuss the characters' experiences and coping mechanisms.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Play a game of "Feeling Bingo" where students identify and express different emotions, including sadness.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Practice role-playing scenarios where students can act out comforting a friend who is feeling sad.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Complete lessons for the available ‘sad’ switches.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Feeling sad video' link='https://youtu.be/_vi7sL_dmVQ' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Large chart paper and markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Art supplies (crayons, coloured pencils, markers)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Blank paper or drawing sheets.</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Supporting a child through grief and loss -</DefaultText>
                        <DefaultTextWithLink text='https://kidshelpline.com.au/parents/issues/supporting-child-through-grief-and-loss' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Why am I so sad? -</DefaultText>
                        <DefaultTextWithLink text='https://kidshelpline.com.au/kids/issues/why-am-i-so-sad' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Working with the sad child:  tearfulness, sadness and depression in primary aged children -</DefaultText>
                        <DefaultTextWithLink text='https://developingminds.net.au/articles-for-professionals/2018/3/14/i862cw03tednjbdfnabjtojug037a3' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}
