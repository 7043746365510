import { View } from 'react-native'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { DoodlingIcon } from '../../icons/doodling-icon'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DoodlingIconInverted } from '../../icons/doodling-icon-inverted'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'

export const SwitchesActivitiesHealthyHabitsDoodling: CardTemplate = {
    cardId: "820d5f52-3993-4339-8de1-d52a3e4f173d",
    title: 'Doodling',
    Header: DoodlingIconInverted,
    Icon: DoodlingIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Establishing creative confidence, collaboration and building teamwork capabilities."} />
            <DefaulttextWithRightArrow textdata={"Sit around a table in a group with everyone having paper and something to draw with."} />
            <DefaulttextWithRightArrow textdata={"Each person draws a shape or some random lines, and then passes their drawing (doodling) to the person sitting on their left."} />
            <DefaulttextWithRightArrow textdata={"Use your creativity to build on what you have received from your neighbour, turning the shape or random lines into a more recognisable picture, and then pass you paper to the left again."} />
            <DefaulttextWithRightArrow textdata={"Repeat four times"} />
            <DefaulttextWithRightArrow textdata={"Each time, add more context and interesting detail to the pictures you get from your neighbour."} />
            <DefaulttextWithRightArrow textdata={"On the forth pass add action or drama to the paper you got (don’t use any text)."} />
            <DefaulttextWithRightArrow textdata={"Finally, on the fifth pass add a caption to the picture and share with the group."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.CREATIVITY,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"To do this as an online group activity:"} />
            <DefaulTextWithTipsIcon textdata={"1.	Pick an online whiteboard tool that allows you to use a large, zoomable canvas."} />
            <DefaulTextWithTipsIcon textdata={"2. Create a post-it note for each player and arrange these in a circle, with space underneath for an image (arrange people in groups of five where possible)."} />
            <DefaulTextWithTipsIcon textdata={"3.	Have everyone draw their first image and add it below their name tag in the whiteboard."} />
            <DefaulTextWithTipsIcon textdata={"4.	For the following steps each person will drag their image and pass it to the next person in the circle."} />
            <DefaulTextWithTipsIcon textdata={"5.	Repeat the process for five rotation as per the game instructions"} />
            <DefaulTextWithTipsIcon textdata={`6.	Facilitate full group discussion online, ideally using non-verbal means to communicate (e.g. reaction emojis, ‘hands up’ button to speak or general chat in the group chat)."`} />
            <DefaultTextWithoutArrowsBold textdata={"NOTE:"} />
            <DefaultTextWithoutArrowsItallic textdata={"If you play certain music during the doodling it will likely influence the outcome (e.g. Christmas songs often end up with holiday postcards)."} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Doodleing.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.CREATIVITY],
    action: [CardActionType.Action],
    level: []

}

