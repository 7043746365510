import { View, StyleSheet, StyleProp, TextStyle } from "react-native";
import { DefaultText } from "../../common/default-text";
import { TipsIcon } from "../image-source/tips";

interface Props {
    textdata: string | string[] | undefined | null | React.ReactNode
    style?: StyleProp<TextStyle>

}


export const DefaulTextWithTipsIcon = ({ textdata }: Props) => {
    return (
        <View style={styles.content}>
            <View style={styles.containingView}>
                <View style={styles.icon}>
                    <View style={styles.extraTipsIcon}>
                        <TipsIcon />
                    </View>
                </View>
                <DefaultText style={styles.text}>
                    {textdata}
                </DefaultText>
            </View>

        </View>
    )

}



const styles = StyleSheet.create({

    icon: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        paddingRight: 20,
    },
    content: {
        justifyContent: 'center',
        marginVertical: 15,
        minWidth: 300,
        maxWidth: 700,
    },
    text: {
        textAlign: 'auto',
        flex: 1,
        flexWrap: 'wrap'


    },
    extraTipsIcon: {
        height: 50,
        width: 50,

    },
    containingView: {
        flexDirection: 'row',
        alignItems: 'flex-start',

    }
})

