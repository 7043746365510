import { useReactiveVar } from "@apollo/client"
import { rvPrintMode } from "./export-to-pdf-button"

export const useScaleSizeForPrintMode = (size: number) => {
    const isPrintMode = useReactiveVar(rvPrintMode)
    if (isPrintMode) {
        return size * 0.7
    }

    return size
}
