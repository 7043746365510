import { StyleSheet } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

export const SwitchesLessonPlansIntroducingExcited: CardTemplate = {
    cardId: "c1938c38-b65b-46f8-a3c4-154df297663f",
    title: 'Introducing excited',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Deepen their understanding of the emotion "excited" by identifying its characteristics, expressing what excitement looks and feels like, and exploring ways to channel and manage their excitement.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45-60 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Review of Emotions (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by briefly reviewing the emotions learned in the previous lessons, including "excited".</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to recall what they remember about the emotion "excited" and share examples of when they have felt excited.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Characteristics of Excitement (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the “Feeling excited” video [2:55]</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set up a large chart paper on an easel or wall. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write "Excitement" at the top of the chart and invite the students to brainstorm words or phrases that describe what excitement looks and feels like.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write their responses on the chart paper, encouraging them to explain their choices and expand on the ideas shared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss common characteristics, such as feeling energetic, having butterflies in the stomach, or wanting to jump up and down.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Identifying Excitement Triggers (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share situations that might make someone feel excited.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss their responses and encourage them to share personal experiences or observations of others feeling excited in similar situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that feeling excited is a positive emotion and that everyone has different things that make them feel excited.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Expressing Excitement (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite the students to express what excitement looks like to them by drawing a picture or using art supplies to create a visual representation of excitement.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to use vibrant colours, bold lines, and dynamic shapes that they associate with feeling excited.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide an opportunity for volunteers to share their artwork and describe what they depicted, emphasising that there are different ways excitement can be expressed.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Channelling Excitement (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of channelling and managing excitement in appropriate ways.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Brainstorm together different activities or outlets that can help students channel their excitement, such as dancing, running, playing a musical instrument, doing a puzzle or engaging in a favourite hobby.  Refer to available switches for excited for inspiration</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to share their own ideas and experiences in managing their excitement. Emphasise the importance of balancing their excitement with following rules and considering others' feelings. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflecting on Excitement (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students back in the circle. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to reflect on what they learned about excitement and how it feels to be excited.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to share their artwork or describe a situation that makes them feel excited.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss ways they can manage their excitement and express it in positive ways.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>Extension Activities:</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Read books or tell stories about excitement and discuss the characters' experiences and how they manage their excitement.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Play a game of "Guess the Excitement" where students act out different scenarios or use charades to depict exciting moments.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Encourage the students to share their excitement with their peers by taking turns talking about something that recently made them feel excited.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Engage in a physical activity or dance session to help the students channel their excitement in a fun and energetic way.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Complete lessons for the available ‘excited’ switches.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Feeling excited video' link='https://youtu.be/QiWLwge7-Ek' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Large chart paper and markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Art supplies (crayons, coloured pencils, markers)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Blank paper or drawing sheets.</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>How to Overcome Over-Excitement and Anxiety? -</DefaultText>
                        <DefaultTextWithLink text='https://www.calmdownmind.com/the-resistance-of-over-excitement/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Why Do Children Experience Overexcitement? -</DefaultText>
                        <DefaultTextWithLink text='https://www.fitnesskid.com/blogs/news/why-do-children-experience-overexcitement' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}
