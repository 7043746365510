import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const EmotionHappyIcon = (props?: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 92.491 92.489"
    {...props}
  >
    <Path
      id="Path_3899"
      data-name="Path 3899"
      d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465Z"
      transform="translate(26.513 51.518)"
      fill="none"
      stroke="#2a7339"
      strokeWidth={3.013}
    />
    <Path
      id="Path_3900"
      data-name="Path 3900"
      d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211"
      transform="translate(25.214 34.156)"
      fill="#2a7339"
    />
    <Path
      id="Path_3901"
      data-name="Path 3901"
      d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211"
      transform="translate(60.401 34.156)"
      fill="#2a7339"
    />
    <Path
      id="Path_3902"
      data-name="Path 3902"
      d="M0,0S17.982,22.781,42.69.53"
      transform="translate(24.377 56.643)"
      fill="none"
      stroke="#2a7339"
      strokeWidth={3.013}
    />
  </Svg>
);
export default EmotionHappyIcon;
