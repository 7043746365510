import { View, StyleSheet, ViewStyle } from "react-native";
import { DefaultText } from "../../common/default-text";
import { Entypo } from '@expo/vector-icons';
import { rvCard } from "./card-color-scheme";
import { useReactiveVar } from "@apollo/client";

interface Props {
    textdata: string
    style?: ViewStyle
}


export const DefaultTextWithDownArrow = ({ textdata, style }: Props) => {
    const color = useReactiveVar(rvCard)?.colorScheme
    return (
        <View style={[styles.content, style]}>
            <DefaultText style={styles.text}>
                {textdata}
            </DefaultText>
            <View style={styles.icon}>
                <Entypo name="arrow-down" size={40} color={color} />
            </View>
        </View>
    )

}



const styles = StyleSheet.create({

    icon: {

        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 5,
    },
    content: {
        paddingVertical: 10,
    },
    text: {
        textAlign: 'center'
    }
})

