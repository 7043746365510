import { ScrollView, StyleSheet, View } from 'react-native'
import { DefaultFontSize } from '../common/const'
import DefaultModal from '../common/default-modal'
import { DefaultText } from '../common/default-text'
import { useResponsive } from '../common/use-responsive'
import ViewZoomableForIphone from '../common/view-zoomable'
import { RequestForEmailNotificationsInfoImage } from './assets/request-for-email-notifications-info-image'
import { rvTeacherRequestForEmailNotificationsInfoModal } from './teacher-state'

const TeacherRequestForEmailNotificationsInfoModal = () => {
    const toggleModal = () => rvTeacherRequestForEmailNotificationsInfoModal(false)
    const { responsiveFontSize, isNarrowScreen } = useResponsive()
    return (
        <DefaultModal onClose={toggleModal} contentStyle={{ ...styles.contentContainer, padding: isNarrowScreen ? 30 : 60 }}>
            <ScrollView>
                <DefaultText style={[styles.headerText, { fontSize: responsiveFontSize(DefaultFontSize + 2) }]}>
                    Request for email notifications
                </DefaultText>
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                    When the request teacher chat question is enabled, you have the option to turn on email notifications to be sent to any or all of the educators assigned to the class to notify that a student in this class has requested a follow up conversation.  The student details are not shared in the email to protect their privacy, but the list of students requesting a conversation are provided on the home page once logged in. The email is sent once the check in session is closed in the system.
                </DefaultText>
                <View style={styles.spacing} />
                <ViewZoomableForIphone style={{ width: '100%', height: isNarrowScreen ? 150 : 300 }}>
                    <RequestForEmailNotificationsInfoImage />
                </ViewZoomableForIphone>

            </ScrollView>
        </DefaultModal>
    )
}
export default TeacherRequestForEmailNotificationsInfoModal

const styles = StyleSheet.create({
    contentContainer: {
        padding: 60,
        maxHeight: 800,
    },
    text: {
        textAlign: 'justify',
    },
    headerText: {
        textAlign: 'center',
        fontSize: DefaultFontSize + 2,
        fontWeight: 'bold',
        marginBottom: 20
    },
    spacing: {
        marginVertical: 5,
    },
})  
