import { gql, useSubscription } from '@apollo/client';
import { StackScreenProps, createStackNavigator } from '@react-navigation/stack';
import { useEffect, useState } from 'react';
import { GetStudentClassByClassIdQueryVariables, OnMutateClassByIdSubscription, OnMutateClassByIdSubscriptionVariables, OnMutateStudentClassByClassIdSubscription, OnMutateStudentClassByClassIdSubscriptionVariables } from '../../common/API';
import { onMutateClassById, onMutateStudentClassByClassId } from '../../common/graphql/subscriptions';
import { RootView } from '../common/root-view';
import { ScreenNames } from '../common/screen-names';
import { TeacherAddStudent } from '../teacher/teacher-add-student';
import { TeacherCreateStudentNickname } from '../teacher/teacher-create-student-nickname';
import { TeacherCreateStudentPickAvatar } from '../teacher/teacher-create-student-pick-avatar';
import { TeacherEditStudent } from '../teacher/teacher-edit-student';
import { TeacherEditStudentAvatar } from '../teacher/teacher-edit-student-avatar';
import SchoolAdminManageClass from './school-admin-manage-class';
import { SchoolAdminClassNavigatorParamsList, SchoolAdminNavigationParams } from './school-admin-navigation-params';
import { updateListQueryCache } from '../common/update-list-query-cache';
import { ListStudentClassAndStudentDataByClassIdCustomQuery, listStudentClassAndStudentDataByClassIdCustomQuery } from '../teacher/teacher-graphql-scripts';
import { handleRestartApp } from '../common/handle-restart-app';


const Stack = createStackNavigator<SchoolAdminClassNavigatorParamsList>();

const SchoolAdminClassMain = ({ route: { params } }: StackScreenProps<SchoolAdminNavigationParams, "SchoolAdminClassMain">) => {

    const [currentClassID, setCurrentClassID] = useState('');

    useEffect(() => {
        setCurrentClassID(params?.params?.classID || '');
    }, [params?.params?.classID]);

    const { error: errorClassSubscription } = useSubscription<OnMutateClassByIdSubscription, OnMutateClassByIdSubscriptionVariables>(gql`${onMutateClassById}`, { variables: { id: currentClassID }, skip: !currentClassID })
    const { error: errorStudentClassSubscription } = useSubscription<
        OnMutateStudentClassByClassIdSubscription,
        OnMutateStudentClassByClassIdSubscriptionVariables
    >(
        gql`
  ${onMutateStudentClassByClassId}
`,
        {
            variables: { classID: currentClassID || '' },
            onData: updateListQueryCache<ListStudentClassAndStudentDataByClassIdCustomQuery, GetStudentClassByClassIdQueryVariables, OnMutateStudentClassByClassIdSubscription>({ listQuery: listStudentClassAndStudentDataByClassIdCustomQuery, listQueryName: 'getStudentClassByClassId', subscriptionName: 'onMutateStudentClassByClassId', variables: { classID: currentClassID, limit: 5000 } },),
            skip: currentClassID === '',
        }
    );

    if (
        errorStudentClassSubscription !== undefined ||
        errorClassSubscription !== undefined
    ) {
        handleRestartApp()
    }

    return (
        <RootView>
            <Stack.Navigator
                screenOptions={{ headerShown: false }}
                initialRouteName={ScreenNames.SchoolAdminManageClass}
            >
                <Stack.Screen name={ScreenNames.SchoolAdminManageClass} component={SchoolAdminManageClass} options={{ title: 'SchoolAdminManageClass' }} />
                <Stack.Screen name={ScreenNames.SchoolAdminEditStudent} component={TeacherEditStudent} options={{ title: 'SchoolAdminEditStudent' }} />
                <Stack.Screen name={ScreenNames.SchoolAdminAddStudent} component={TeacherAddStudent} options={{ title: 'SchoolAdminAddStudent' }} />
                <Stack.Screen name={ScreenNames.SchoolAdminCreateStudentPickAvatar} component={TeacherCreateStudentPickAvatar} options={{ title: 'SchoolAdminCreateStudentPickAvatar' }} />
                <Stack.Screen name={ScreenNames.SchoolAdminCreateStudentNickname} component={TeacherCreateStudentNickname} options={{ title: 'SchoolAdminCreateStudentNickname' }} />
                <Stack.Screen name={ScreenNames.SchoolAdminEditStudentAvatar} component={TeacherEditStudentAvatar} options={{ title: 'SchoolAdminEditStudentAvatar' }} />

            </Stack.Navigator>
        </RootView>
    )
}
export default SchoolAdminClassMain