import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native'
import { ListStaffCheckInsByStaffIDQuery, ListStaffCheckInsByStaffIDQueryVariables, ListTeacherCheckInsByTeacherIDQuery, ListTeacherCheckInsByTeacherIDQueryVariables, StaffCheckIn, TeacherCheckIn } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { listStaffCheckInsByStaffID, listTeacherCheckInsByTeacherID } from '../../common/graphql/queries'
import { AllStatsPeriodsGraphYAxis } from '../common/all-stats-period-graph'
import { DefaultText } from '../common/default-text'
import { groupStaffCheckInsByWeek } from '../common/group-staff-checkin-by-week'
import { useNonTeachingStaffState } from '../student/use-non-teaching-staff-state'
import { StaffWellbeingStatsAllPeriodsGraph } from '../common/staff-wellbeing-stats-all-periods-graph'


const NonTeachingStaffWellbeingStats = () => {

    const { currentNonTeachingStaff } = useNonTeachingStaffState()

    const { data, loading } = useQuery<ListStaffCheckInsByStaffIDQuery, ListStaffCheckInsByStaffIDQueryVariables>(gql`${listStaffCheckInsByStaffID}`, { variables: { staffID: currentNonTeachingStaff?.id || "", limit: 5000 }, skip: !currentNonTeachingStaff })
    const staffCheckInsData = data?.listStaffCheckInsByStaffID?.items as StaffCheckIn[] || []
    const staffCheckInsDataByWeek = groupStaffCheckInsByWeek(staffCheckInsData).reverse()

    return (
        <View style={styles.container}>
            {loading ? (
                <View style={styles.centered}>
                    <ActivityIndicator size={"large"} color={DecidaColors.Green} />
                </View>
            ) : staffCheckInsDataByWeek.length ? staffCheckInsDataByWeek.map((techerCheckInsWeek) => {
                return (
                    <View key={techerCheckInsWeek[0].createdAt} style={styles.weekGraph}>
                        <AllStatsPeriodsGraphYAxis />
                        <ScrollView style={styles.graphScroll} horizontal alwaysBounceHorizontal={false}>
                            <StaffWellbeingStatsAllPeriodsGraph staffCheckIns={techerCheckInsWeek.reverse()} />
                        </ScrollView>
                    </View>
                )
            }) : (
                <View style={styles.centered}>
                    <DefaultText>No data available</DefaultText>
                </View>
            )}
        </View>
    )
}
export default NonTeachingStaffWellbeingStats
const styles = StyleSheet.create({
    centered: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        marginVertical: 20,
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    weekGraph: {
        flexDirection: 'row',
        padding: 5,
        maxWidth: '100%'
    },
    graphScroll: {
        flexGrow: 0,
    },
})