import { Emotion } from "../models";
import { DecidaColors } from "../../common/decida-colors";

export const emotionColor = {
    [Emotion.HAPPY]: DecidaColors.Happy,
    [Emotion.EXCITED]: DecidaColors.Excited,
    [Emotion.SCARED]: DecidaColors.Scared,
    [Emotion.SAD]: DecidaColors.Sad,
    [Emotion.ANGRY]: DecidaColors.Angry,
    [Emotion.ANXIOUS]: DecidaColors.Anxious,
}