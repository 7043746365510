import { StyleSheet, TextInput } from "react-native"
import { CommonStyles } from "./const"
import { DefaultText } from "./default-text"

type Props = {
    value?: string
    onChangeText: React.Dispatch<React.SetStateAction<string>>
}
export const LastNameInput = ({ value, onChangeText }: Props) =>
    <>
        <DefaultText style={styles.heading}>Last Name</DefaultText>
        <TextInput
            testID='last-name-input'
            style={CommonStyles.textInput}
            placeholder='Last Name'
            autoCorrect={false}
            autoComplete='name'
            autoCapitalize='none'
            textContentType='familyName'
            value={value}
            onChangeText={onChangeText}
        />
    </>

const styles = StyleSheet.create({
    heading: {
        fontSize: 17,
        fontWeight: 'bold',
        paddingTop: 20,

    },
})