import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { StyleSheet, View } from 'react-native';
import { GetSchoolQuery, GetSchoolQueryVariables, GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables, ListTeacherClassesByTeacherIDQueryVariables } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { getSchool, getTeacherByCognitoUsername } from '../../common/graphql/queries';
import ButtonSessionTeacher from '../common/button-session-teacher';
import { filterAndSortActiveTeacherClassTeacherClasses } from '../common/filter-and-sort-active-teacher-classes';
import InformationIcon from '../common/information-icon';
import WithRefreshControl from '../common/with-refresh-control';
import { ListTeacherClassesAndClassDetailsByTeacherIDQuery, listTeacherClassesAndClassDetailsByTeacherID } from '../custom-graphql/queries/list-teacher-classes-and-class-details-by-teacher-id';
import { rvCurrentUser } from '../login/login-state';
import TeacherHomeClass from './teacher-home-class';
import { rvTeacherDashboardInfoModal } from './teacher-state';


const TeacherHomeClasses = () => {
    const user = useReactiveVar(rvCurrentUser);

    const { data: techerResponse } = useQuery<GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables>(gql`${getTeacherByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })

    const currentTeacher = techerResponse?.getTeacherByCognitoUsername?.items[0]
    const { data, refetch } = useQuery<ListTeacherClassesAndClassDetailsByTeacherIDQuery, ListTeacherClassesByTeacherIDQueryVariables>(gql`${listTeacherClassesAndClassDetailsByTeacherID}`, { variables: { teacherID: currentTeacher?.id || "" }, skip: !currentTeacher, })

    const teacherClasses = filterAndSortActiveTeacherClassTeacherClasses(data)

    const { data: schoolResponse } = useQuery<GetSchoolQuery, GetSchoolQueryVariables>(gql`${getSchool}`, { variables: { id: currentTeacher?.schoolID || "" }, skip: !currentTeacher?.schoolID })
    const currentSchool = schoolResponse?.getSchool

    const toggleDashboardInfoModal = () => {
        rvTeacherDashboardInfoModal(true)
    }


    return (
        <>
            <WithRefreshControl onRefresh={refetch} contentContainerStyle={{ paddingHorizontal: 40, paddingVertical: 0 }}>
                <View style={{ alignItems: 'flex-end' }}>
                    <InformationIcon onPress={toggleDashboardInfoModal} style={{ width: 30, height: 30 }} color={DecidaColors.Green} />
                </View>
                <View style={styles.classesContainer}>
                    {teacherClasses?.map((item) => <TeacherHomeClass key={item?.classID} classID={item?.classID || ""} />)}
                </View>
            </WithRefreshControl>
        </>
    )
}
export default TeacherHomeClasses


const styles = StyleSheet.create({
    classesContainer: {
        width: '100%',
        height: '100%',
        justifyContent: 'space-evenly'
    },
})