import { Entypo } from '@expo/vector-icons'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { useReactiveVar } from '@apollo/client'
import { rvTeacherAbsenceMode } from './common-state'
import { showAbsenceWarnAlert } from './universal-alert'
import { setLockDevice } from '../login/login-state'

type Props = {
    classID?: string
}

const ButtonSecureScreen = ({ classID }: Props) => {
    const absenceMode = useReactiveVar(rvTeacherAbsenceMode)

    const lockDevice = () => {
        if (absenceMode) {
            showAbsenceWarnAlert({
                onLeftButtonPress: () => {
                    rvTeacherAbsenceMode(false)
                    setLockDevice(true, classID || "")
                }
            })
            return
        }
        setLockDevice(true, classID || "")
    }


    return (
        <DefaultButton style={[styles.button, styles.secureScreenButton]} onPress={lockDevice}>
            <Entypo name="lock" size={44} color={DecidaColors.Red} />
            <View style={styles.textContainer}>
                <DefaultText style={styles.buttonText}>Secure screen</DefaultText>
            </View>
        </DefaultButton>
    )
}
export default ButtonSecureScreen

const styles = StyleSheet.create({
    button: {
        height: 80,
        borderRadius: 5,
        borderWidth: 2,
        backgroundColor: DecidaColors.White,
        borderColor: DecidaColors.Green,
        paddingLeft: 40,
        paddingRight: 40,
        margin: 10,

    } as ViewStyle,
    textContainer: {
        width: 180,
    },
    buttonText: {
        color: DecidaColors.Black,
        paddingLeft: 10,
        fontWeight: '700',
        textAlign: 'center',
    },

    secureScreenButton: {
        borderColor: DecidaColors.Red,
    } as ViewStyle,

})