import { View, StyleSheet, ViewStyle, TextProps, TextStyle } from "react-native";
import { DefaultText } from "../../common/default-text";
import { DefaultFontSize } from "../../common/const";


interface Props {
    textdata: string,
    align?: "left" | "center" | "right" | "justify"
    small?: boolean
    fontSize?: TextStyle["fontSize"]
}

export const DefaultTextWithoutArrows = ({ textdata, align = 'center', small, fontSize }: Props) => {
    return (
        <View style={styles.content}>
            <DefaultText style={[styles.text, { textAlign: align, fontSize: fontSize ? fontSize : small ? 16 : DefaultFontSize }]}>
                {textdata}
            </DefaultText>
        </View>
    )

}



const styles = StyleSheet.create({

    icon: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 5,
    },
    content: {
        paddingVertical: 10,
    },
    text: {
        textAlign: 'center'
    }
})

