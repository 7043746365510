
import { Path, Svg, TSpan, Text } from 'react-native-svg'
import { SvgEmotionIconProps } from './icon-emotion-types'

export const IconAngry = ({ width, height, withoutLabel }: SvgEmotionIconProps) =>
    <Svg width={width} height={height} viewBox="0 0 100.8 108.682">
        <Path d="M0,0A41.5,41.5,0,0,0,41.5-41.5,41.5,41.5,0,0,0,0-83,41.5,41.5,0,0,0-41.5-41.5,41.5,41.5,0,0,0,0,0" transform="translate(50.4 85.5)" fill="#fff" />
        <Path d="M0,0A41.5,41.5,0,0,0,41.5-41.5,41.5,41.5,0,0,0,0-83,41.5,41.5,0,0,0-41.5-41.5,41.5,41.5,0,0,0,0,0Z" transform="translate(50.4 85.5)" fill="none" stroke="#ff2d16" strokeWidth="5" />
        <Path d="M0,0A6.532,6.532,0,0,0,6.532-6.532,6.532,6.532,0,0,0,0-13.063,6.532,6.532,0,0,0-6.532-6.532,6.532,6.532,0,0,0,0,0" transform="translate(30.772 40.548)" fill="#ff2d16" />
        <Path d="M0,0A6.532,6.532,0,0,0,6.532-6.532,6.532,6.532,0,0,0,0-13.063,6.532,6.532,0,0,0-6.532-6.532,6.532,6.532,0,0,0,0,0" transform="translate(63.412 40.548)" fill="#ff2d16" />
        <Path d="M0,0S16.68-21.132,39.6-.492" transform="translate(27.115 60.752)" fill="none" stroke="#ff2d16" strokeWidth="5" />
        <Path d="M0,0S-1.213,4.927,3.9,3.137" transform="translate(25.176 59.156)" fill="#ff2d16" />
        <Path d="M0,0S4.857,1.47,3.337-3.735" transform="translate(65.035 62.11)" fill="#ff2d16" />
        <Path d="M0,0,14.515,6" transform="translate(23.515 19.3)" fill="none" stroke="#ff2d16" strokeWidth="5" />
        <Path d="M0,0S-5.069.236-1.9,4.635" transform="translate(24.488 16.99)" fill="#ff2d16" />
        <Path d="M0,0S5.069-.236,1.9-4.635" transform="translate(37.075 27.61)" fill="#ff2d16" />
        <Path d="M0,0,14.515-6" transform="translate(56.145 25.3)" fill="none" stroke="#ff2d16" strokeWidth="5" />
        <Path d="M0,0S-5.069-.236-1.9-4.635" transform="translate(57.119 27.61)" fill="#ff2d16" />
        <Path d="M0,0S5.069.236,1.9,4.635" transform="translate(69.705 16.99)" fill="#ff2d16" />
        {withoutLabel ? (null) : (
            <>
                <Path d="M0,0A9.941,9.941,0,0,0-9.941,9.941,9.941,9.941,0,0,0,0,19.882H77.918A9.941,9.941,0,1,0,77.918,0Z" transform="translate(11.441 87.3)" fill="#fff" />
                <Text transform="translate(22.31 102.824)" fill="#ff2d16" fontSize="15" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">ANGRY</TSpan></Text>
                <Path d="M0,0A9.941,9.941,0,0,0-9.941,9.941,9.941,9.941,0,0,0,0,19.882H77.918A9.941,9.941,0,1,0,77.918,0Z" transform="translate(11.441 87.3)" fill="none" stroke="#ff2d16" strokeWidth="3" />
            </>
        )}
    </Svg>
