import { PropsWithChildren } from 'react'
import { View, ViewStyle, StyleSheet, KeyboardAvoidingView, Platform, ScrollView } from 'react-native'
import { DefaultBackgroundColor } from './const'
import { useResponsive } from './use-responsive'
import { useReactiveVar } from '@apollo/client'
import { rvPrintMode } from './export-to-pdf-button'

interface Props {
    style?: ViewStyle,
    keyboardVerticalOffset?: number,
    scroll?: boolean
}
export const Page = ({ keyboardVerticalOffset, scroll, children, style }: PropsWithChildren<Props>) => {
    const { width, height } = useResponsive()
    const isLandscape = width > height;
    const isPrintMode = useReactiveVar(rvPrintMode)

    return (
        <KeyboardAvoidingView
            style={isPrintMode ? styles.printMode : styles.keyboardAvoidingView}
            keyboardVerticalOffset={keyboardVerticalOffset !== undefined ? 0 : isLandscape ? 100 : undefined}
            behavior={Platform.select({ ios: 'padding', android: 'height' })}
        >
            {
                scroll ?
                    <ScrollView contentContainerStyle={[styles.scrollContainer, { ...style }]}>
                        {children}
                    </ScrollView>
                    :
                    <View style={[styles.container, style]}>
                        {children}
                    </View>
            }
        </KeyboardAvoidingView>
    )
}

const styles = StyleSheet.create({
    keyboardAvoidingView: {
        flex: 1,
    },
    container: {
        height: '100%',
        backgroundColor: DefaultBackgroundColor,
        justifyContent: 'center',
        alignItems: 'center',
    } as ViewStyle,
    scrollContainer: {
        backgroundColor: DefaultBackgroundColor,
        justifyContent: 'center',
        alignItems: 'center',
    } as ViewStyle,
    printMode: {
        flex: 1,
        flexWrap: 'wrap'
    }
})
