import { View, Switch, StyleSheet, ViewStyle } from "react-native";
import { DecidaColors } from '../../common/decida-colors'

interface Props {
    value: boolean,
    onChange: (switchStatus: boolean) => void,
}

export const DefaultSwitch = ({ value, onChange }: Props) =>
    <View style={styles.container}>
        <Switch
            accessibilityLabel="Request Teacher Chat Toggle"
            trackColor={{ false: DecidaColors.Gray, true: DecidaColors.Green }}
            thumbColor={DecidaColors.White}
            ios_backgroundColor="#3e3e3e"
            onValueChange={onChange}
            value={value}
        />
    </View>

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        width: 50,
    } as ViewStyle,
    selected: {
        flex: 1,
        borderRadius: 0,
    },
    notSelected: {
        flex: 1,
        backgroundColor: DecidaColors.Gray,
        borderRadius: 0,
    }
})