import { gql, useQuery, useSubscription } from '@apollo/client'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { ListClassesByClassGroupIDQuery, ListClassesByClassGroupIDQueryVariables, OnMutateClassByClassGroupIDSubscription, OnMutateClassByClassGroupIDSubscriptionVariables } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { listClassesByClassGroupID } from '../../common/graphql/queries'
import { onMutateClassByClassGroupID } from '../../common/graphql/subscriptions'
import { isDefined } from '../../common/is-defined'
import { sortClassByGoModeAndName } from '../common/sort-class-by-goMode-and-name'
import { updateListQueryCache } from '../common/update-list-query-cache'
import SchoolAdminClassListItem from './school-admin-class-list-item'



const SchoolAdminClassList = ({ schoolID, classGroupID }: { schoolID: string, classGroupID: string }) => {

    const { data, loading } = useQuery<ListClassesByClassGroupIDQuery, ListClassesByClassGroupIDQueryVariables>(gql`${listClassesByClassGroupID}`, { variables: { classGroupID, limit: 5000 }, skip: !classGroupID })

    const classes = data?.listClassesByClassGroupID?.items.filter(c => c?._deleted !== true && !c?.archived).filter(isDefined) || []

    useSubscription<OnMutateClassByClassGroupIDSubscription, OnMutateClassByClassGroupIDSubscriptionVariables>(gql`${onMutateClassByClassGroupID}`,
        {
            variables: { classGroupID: classGroupID || "" },
            skip: !schoolID,
            onData: updateListQueryCache<ListClassesByClassGroupIDQuery, ListClassesByClassGroupIDQueryVariables, OnMutateClassByClassGroupIDSubscription>({ listQuery: listClassesByClassGroupID, listQueryName: 'listClassesByClassGroupID', variables: { classGroupID: classGroupID || "", limit: 5000 }, subscriptionName: 'onMutateClassByClassGroupID' })
        })

    const sortedClasses = sortClassByGoModeAndName(classes)

    if (loading) {
        return <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <ActivityIndicator size={36} color={DecidaColors.Green} />
        </View>
    }

    return (
        <>
            {sortedClasses.map((claz) => {
                return (
                    <SchoolAdminClassListItem key={claz.id} claz={claz} />
                )
            })}
        </>
    )
}
export default SchoolAdminClassList
const styles = StyleSheet.create({})