import { StyleSheet, Text, View } from 'react-native'
import { DefaultButton, DefaultButtonTypes } from '../common/default-button'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'
type Props = {
    title?: string
    onPress: () => void
}
const SchoolAdminButtonCancel = ({ title, onPress }: Props) => {
    return (
        <DefaultButton type={DefaultButtonTypes.Small} style={styles.cancelButton} onPress={onPress}>
            <DefaultText style={styles.confirmText}>
                {title || "Cancel"}
            </DefaultText>
        </DefaultButton>
    )
}
export default SchoolAdminButtonCancel
const styles = StyleSheet.create({
    cancelButton: {
        backgroundColor: DecidaColors.Red,
        marginRight: 10
    },
    confirmText: {
        fontSize: 12,
        fontWeight: '700',
        color: DecidaColors.White,
    },
})