import { fruitAvatarArray, animalAvatarArray, avatarArray, avatars } from "../../icon/avatar/avatars"

export const workOutAvatarName = (avatar: string | undefined, loadedAvatar?: string): keyof typeof avatars => {
    if (avatar == undefined) {
        if (loadedAvatar && avatarIsKeyOfAvatars(loadedAvatar)) {
            return loadedAvatar
        } else {
            return 'Default'
        }
    }

    if (avatar.toLowerCase().startsWith('any fruit')) {
        return fruitAvatarArray[Math.round(Math.random() * (fruitAvatarArray.length - 1))].avatarName
    }

    if (avatar.toLowerCase().startsWith('any animal')) {
        return animalAvatarArray[Math.round(Math.random() * (animalAvatarArray.length - 1))].avatarName
    }

    if (avatar.toLowerCase().startsWith('any')) {
        return avatarArray[Math.round(Math.random() * (avatarArray.length - 1))].avatarName
    }

    if (avatarIsKeyOfAvatars(avatar)) {
        return avatar
    }

    return 'Default'
}

const avatarIsKeyOfAvatars = (avatar: string): avatar is keyof typeof avatars =>
    avatars[avatar as keyof typeof avatars] != undefined
