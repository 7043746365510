import { View } from 'react-native'
import { Br } from '../../../common/br'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { BuildIcon } from '../../explore/src/build-icon'
import { BuildIconInverted } from '../../explore/src/build-icon-inverted'
import { BuildImage1 } from './src/build-image1'

export const SwitchesEmotionsExcitedBuild: CardTemplate = {
    cardId: "e93246cd-2514-4be6-b1da-91ece1e20cd6",
    title: 'Build',
    Header: BuildIconInverted,
    Icon: BuildIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <BuildImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Releasing energy related to a specific event or situation people are excited about."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"With a friend, pick a future event that you are both excited about."} />
                <DefaultTextWithDownArrow textdata={"Now build something that relates to what you are excited about with materials that you enjoy using - this could be a car made of lego bricks, a  chair made of wood, or even a tool made out of paper and craft materials."} />
                <DefaultTextWithDownArrow textdata={"Source all materials, and create some space and time (particularly if it’s something that will take multiple sessions)."} />
                <DefaultTextWithoutArrows textdata={"Share what you have created with others."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.EXTRA_SWITCHES,
    whyDoesItWorkText: 'Playing with building blocks promotes social interaction, turn-taking skills, sharing, collaborative problem-solving and the learning of concepts. It can be used to target goals around social skills, language and motor skills. Using a tool like LEGO® capitalises on existing motivation and supports self-esteem by allowing the participants to demonstrate their skills in a social situation. It also sets up a positive opportunity for guided social problem-solving to help develop social skills that can then be used in other situations.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 10, color: DecidaColors.Gray }}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Practice using a creative outlet for managing and communicating excitement.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Provide a space for students to play with building blocks eg Lego or other appropriate craft materials and invite them to create something that is representative of something they are excited about
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Encourage students to share and explain their creation with their peers, and if possible take a photo to remember the positive experience and emotion associated with the thing they are excited about.
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• As a possible extension, have student put there creation together in a large collage to express things the class is excited about .
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• If students have their own device, ask them to find the switch in the Switch4Schools app.
                        </DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>

                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Building and / or craft materials </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Notebook/paper </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>10 Incredible Benefits of Playing with Lego® -   </DefaultText>
                        <DefaultTextWithLink text="https://www.childrenswellnesscentre.co.uk/10-incredible-benefits-of-playing-with-lego-2/" />
                        <Br />
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>Here’s How Creativity Actually Improves Your Health -  </DefaultText>
                        <DefaultTextWithLink text='https://www.forbes.com/sites/ashleystahl/2018/07/25/heres-how-creativity-actually-improves-your-health/?sh=71d8672e13a6' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Build.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor,],
    category: [],
    action: [CardActionType.Action],
    level: [],
    emotion: [CardFilterEmotionType.Excited]
}

