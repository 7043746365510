import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { WalkIcon } from '../../explore/src/walk-icon'
import { WalkIconInverted } from '../../explore/src/walk-icon-inverted'
import { WalkImage1 } from './src/walk-image1'
import { WalkLessonPlanImage1 } from './src/walk-lessonplan-image1'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'

export const SwitchesEmotionsSadWalk: CardTemplate = {
    cardId: "071ff026-429d-4e9f-97e4-3d1fa2e64fe6",
    title: 'Walk',
    Header: WalkIconInverted,
    Icon: WalkIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <WalkImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Countering loneliness while providing a mood and energy boost to refresh your mind."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Arrange some time to quietly go for a walk, preferably outside in nature, such as a park or garden. "} />
                <DefaultTextWithDownArrow textdata={"Pick something to focus on while you stroll (eg. an animal, plant, art) and be intentionally curious."} />
                <DefaultTextWithoutArrows textdata={"Use the quiet time to think about what triggered you, and why it made you sad."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.EXTRA_SWITCHES,
    whyDoesItWorkText: 'Walking releases your ‘feel good’ hormones in the brain, making you feel better and allow for a broader perspective to be considered. Taking some time to yourself allows you to calm your body, separate yourself from the situation, and think clearly. Recent research also shows how walking in nature counters loneliness much more effectively than in busy urban areas like shopping malls or city centres.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 10, color: DecidaColors.Gray }}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Experience the positive impact of being in the outdoors on mood and wellbeing. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Ask the students to define “nature”. Look for:
                        </DefaultText>
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Green spaces (gardens, parks)' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Blue spaces (oceans, creeks, dams)' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• The great outdoors ' />
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Explain to the students that being in nature has been proven to improve both mental and physical wellbeing but we will conduct our own experiment.
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Ask students to check in using the Switch4Schools app and to write down or screenshot what they answered.
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Provide instructions for the activity:
                        </DefaultText>
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• “We will be going out into the grounds” (preferably somewhere with trees/shrubs). Be sure to make the boundaries clear. ' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• “You are required to complete the Scavenger Hunt_Find and Sketch worksheet”. ' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Variations could include:' />
                        <DefaultText style={{ paddingLeft: 120, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Use iPad to take a photo of items
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 120, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Draw the item you see
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 120, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Describe the item (colour smell etc)
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 120, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Tick it off
                        </DefaultText>
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Set a time limit' />
                        <View style={commonCardStyles.imagecontainer}>
                            <View style={{ width: 200, height: 200, }}>
                                <WalkLessonPlanImage1 />
                            </View>
                        </View>

                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Once students are back inside, ask them to debrief their experience completing the scavenger hunt.
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Now ask students to check in using the Switch4Schools app again and compare their emotions from prior to the activity to now.  Ask the students to describe what has happened to their state of mind and how nature helped to improve their mood.
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• If students have their own device, ask them to find the switch in the Switch4Schools app.
                        </DefaultText>




                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Pen/pencils </DefaultText>
                        <Text style={{ textDecorationLine: 'underline', color: 'blue', paddingLeft: 40, fontSize: 20, }} onPress={() => {
                            openUrlWebView(Worksheets.scavengerHuntFindAndSketch.uri);
                        }}>• Scavenger Hunt_Find and Sketch worksheet</Text>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Clipboards</DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Green space outdoors</DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>Nurtured by nature: </DefaultText>
                        <Text style={{ textDecorationLine: 'underline', color: 'blue', paddingLeft: 40, fontSize: 20, }} onPress={() => {
                            openUrlWebView('https://www.apa.org/monitor/2020/04/nurtured-nature');
                        }}>https://www.apa.org/monitor/2020/04/nurtured-nature</Text>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>Nature How connecting with nature benefits our mental health:  </DefaultText>
                        <Text style={{ textDecorationLine: 'underline', color: 'blue', paddingLeft: 40, fontSize: 20, }} onPress={() => {
                            openUrlWebView('https://www.mentalhealth.org.uk/sites/default/files/MHAW21_NATURE%20REPORT_ENG_web.pdf');
                        }}>https://www.mentalhealth.org.uk/sites/default/files/MHAW21_NATURE%20REPORT_ENG_web.pdf</Text>

                    </>}
            />

        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='While on your walk try to do activities that shift your focus outward, for example:' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Going to the bathroom to wash your face"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Listening to a particular bird call "} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Intentionally finding five new smells and identifying what they are."} />
            <DefaulTextWithTipsIcon textdata='You may prefer to walk alone or with a friend. Consider giving both options a go and see how it helps you switch out of sadness. ' />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Walk.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [],
    emotion: [CardFilterEmotionType.Sad]
}
