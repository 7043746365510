import moment from 'moment'
import { StyleSheet, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import SwitchCardVideo from '../../../common/switch-card-video'
import { Worksheets } from '../../../common/worksheets-imports'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { GrowthMindsetHeader } from './src/growth-mindset-header'
import { GrowthMindsetIcon } from './src/growth-mindset-icon'
import { GrowthMindsetImage1 } from './src/growth-mindset-image1'

const growthMindesetVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/DsHudsFCico",
    createdAt: moment('2022-01-01').toString(),
}

export const SwitchesEmotionsSadGrowthMindset: CardTemplate = {
    cardId: "08086317-6a10-4fba-882f-08aa95633e7e",
    title: 'Growth mindset',
    Header: GrowthMindsetHeader,
    Icon: GrowthMindsetIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Experiencing success and failure in a new way"} />


        </>
    ),
    Images: () => (
        <>
            <GrowthMindsetImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Look through and familiarise yourself with the ‘5 Growth Mindset Habits’ below."} />
                <DefaulttextWithRightArrow textdata={"Identify two of these strategies you are the best at, and two that you could improve on. "} />
                <DefaulttextWithRightArrow textdata={"Write down why you think you are good at the two you have chosen and why you could improve on the other two."} />
                <DefaulttextWithRightArrow textdata={"Make a strategy using the S.M.A.R.T model (referenced in the ‘SMART Goals’ Switch) with your goal being to improve on your chosen growth mindset strategies."} />
                <DefaulttextWithRightArrow textdata={"If you want, read to a friend your S.M.A.R.T goal and ask them to keep you accountable."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: "People with a growth mindset tend to approach challenges with a more positive attitude and are more likely to persevere in the face of setbacks. They are also more open to learning new things and seeking out new opportunities for growth. In contrast, those with a fixed mindset may be more resistant to change and may be less likely to take on new challenges because they believe their abilities are fixed and cannot be improved.",
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the concept of a growth mindset and the difference between a growth mindset and a fixed mindset </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify strategies for developing a growth mindset and overcoming challenges </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice setting and working towards specific, achievable goals </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Reflect on their own progress and accomplishments </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Define and explain the concept of a growth mindset. Differentiate between a growth mindset (belief in the ability to develop intelligence through effort) and a fixed mindset (belief that intelligence is static).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Share examples of famous individuals who demonstrated a growth mindset, such as:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Thomas Edison: Edison is known for his numerous inventions and contributions to the development of the modern electrical power system. Despite facing numerous failures and setbacks, he maintained a growth mindset, famously stating, "I have not failed. I've just found 10,000 ways that won't work." His perseverance and resilience showcase a strong growth mindset.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Albert Einstein: Einstein, one of the most influential physicists of the 20th century, is synonymous with intelligence. However, he attributed his success to perseverance and curiosity rather than innate genius. He once said, "It's not that I'm so smart, it's just that I stay with problems longer." Einstein's emphasis on the process of learning and exploring aligns with a growth mindset.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Michael Jordan: Considered one of the greatest basketball players of all time, Michael Jordan faced challenges early in his career. He was initially cut from his high school basketball team, but instead of giving up, he used it as motivation to improve. His work ethic, continuous practice, and commitment to improvement exemplify a growth mindset.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- J.K. Rowling: Before achieving immense success with the Harry Potter series, J.K. Rowling faced numerous rejections from publishers. Despite these setbacks, she persisted in pursuing her passion for writing. Rowling's journey reflects a growth mindset as she embraced challenges, learned from failures, and ultimately achieved remarkable success.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Oprah Winfrey: Oprah Winfrey, a media mogul and philanthropist, faced adversity and challenges in her early life. Overcoming personal and professional setbacks, she embraced continuous learning and resilience. Oprah's commitment to self improvement and her openness to learning from various experiences showcase a growth mindset.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Elon Musk: Known for founding and leading companies like Tesla and SpaceX, has demonstrated a growth mindset throughout his career. He tackles ambitious projects, learns from failures, and continues to pursue innovation. Musk's ability to adapt, learn from mistakes, and push boundaries reflects a growth mindset.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to add any other examples of people with a growth mindset.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Engage students in a discussion about their beliefs regarding intelligence and ability.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share their thoughts and experiences related to growth and fixed mindsets.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Optional: Provide a list of challenges or tasks for students to think about and discuss the strategies they would use to overcome these challenges. For example: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Academic Challenge: You have a difficult subject in school, and you're struggling to understand the concepts.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Strategies:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Seek help from the teacher or a classmate.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Break down the material into smaller, more manageable sections.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Use online resources or educational apps for additional support. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Form a study group to collaborate with peers.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Time Management Challenge: You have multiple assignments due on the same day, and you're finding it hard to manage your time effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Strategies:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Create a detailed schedule or to do list.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Prioritise tasks based on deadlines and importance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Break down larger tasks into smaller, actionable steps.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Avoid multitasking and focus on one task at a time.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Social Challenge: You're having difficulty making new friends or fitting in with a group at school.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Strategies:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Set realistic and achievable goals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Break down the learning process into smaller milestones.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Seek guidance from mentors or experts in the field.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Embrace the learning curve and view challenges as opportunities for growth.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Activity (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Growth Mindset Worksheet to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Review the growth mindset strategies within the Swith (Leap into it, Practice, Cheer, Pivot, Learn) with the students.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Read each scene and ask students to assign a growth mindset strategy to each character.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the correct answers and the rationale behind each strategy assignment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scene 1 -Harry has completed his homework on science for the day and he sees that his teacher has set some optional hard homework on the topic of plants. Harry doesn't know if he wants to do it because usually, he doesn't do well at hard things. Which growth mindset strategy should Harry use? (Answer: Leap into it) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scene 2 -Malakai got his English grades back at the end of last year and he didn’t do as well as he wanted to. He knows he could have put more effort into it but thinks that he isn’t very smart so even if he did try really hard, he still wouldn’t get a good mark. Which growth mindset strategy should Malakai use? (Answer: Practice) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scene 3 -Patrick loves to play tennis but knows his serve is not good enough yet. He has seen other people practice their serve and he knows that it will take a long-time and lots of dedication to get his serve to where he wants it to be. Which growth mindset strategy should Patrick use? (Answer: Practice)  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scene 4 -Nick likes his science class and often thinks to himself what the answers are. One time when the teacher asked the class a question Nick quickly raises his hand to answer which he does. The teacher tells him that he wasn’t correct and asks other people for the answer. Which growth mindset strategy should Nick use? (Answer: Learn)  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scene 5 -Byron plays football with his classmates at lunchtime and he decides to join the school team. This is when he realises that all of the other boys in the team also play for a club and they are really good and he wishes he could play like them. Which growth mindset strategy should Byron use? (Answer: Cheer) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scene 6 -Claire wants to learn how to play chess but there are so many other kids in her grade who are really good at chess. She isn’t jealous of them but she doesn't know that if she tried, she would be any good. Which growth mindset strategy should Claire use? (Answer: Leap into it) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scene 7 -Georgia has a science fair coming up and she was planning on making a bi-carb soda volcano but the mould that she had spent so much time on broke with only a week to go before the show. Which growth mindset strategy should Georgia use? (Answer: Pivot) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scene 8 -Ellie plays trumpet in the school band along with three other who also play trumpet. The others are really good and they have all been playing for longer than her and she knows that they are better than her. Which growth mindset strategy should Ellie use? (Answer: Cheer) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scene 9 -Lachlan cooks for his family once a week and he was planning this week to make a meat lovers Pizza but he forgot to ask his parents to buy the beef and the bacon and he thinks to himself that his dinner is ruined because he can’t make what he originally wanted to. Which growth mindset strategy should Lachlan use? (Answer: Pivot) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scene 10 -Bella has a passion of ice skating. She has been doing it since she was little and practices every day. She has a big competition coming up soon and she practices her routine every day. When the time comes to perform, she does a great run right up until the end when she falls over on the final move. Which growth mindset strategy should Bella use? (Answer: Learn)</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students reflect on their own experiences and challenges.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to think about how they can apply growth mindset strategies to their daily lives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Optional: Assign homework to write a short reflection on a personal experience where they can apply a growth mindset strategy.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points about growth mindset and its importance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the idea that intelligence and abilities can be developed through effort and learning.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Celebrate the power of a growth mindset in overcoming challenges.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- Review the quality of reflections on personal experiences and growth mindset applications.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- Encourage students to apply a growth mindset strategy to a challenge they are currently facing. Ask them to reflect on the experience and share their insights in the next class.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Examples of individuals with a growth mindset (e.g. Thomas Edison, Albert Einstein) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='The growth mindset worksheet, detailing the scenarios' link={Worksheets.theGrowthMindset.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='The Growth Mindset poster' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/growth+mindset+poster.pdf' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Growth+Mindset+Slides.pdf' /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What Having a “Growth Mindset” Actually Means </DefaultText>
                        <DefaultTextWithLink text='https://hbr.org/2016/01/what-having-a-growth-mindset-actually-means' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The 5 benefits of having a growth mindset </DefaultText>
                        <DefaultTextWithLink text='https://www.careerone.com.au/career-advice/career/the-5-benefits-of-having-a-growth-mindset-3036' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The Benefits of a Growth Mindset & Dangers of a Fixed Mindset </DefaultText>
                        <DefaultTextWithLink text='https://todayslearner.cengage.com/the-benefits-of-a-growth-mindset-dangers-of-a-fixed-mindset/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Mindset: How to Overcome Fear of Failure </DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='Growth Mindset: How to Overcome Fear of Failure ' link='https://www.youtube.com/watch?v=DsHudsFCico' />

                        <SwitchCardVideo video={growthMindesetVideo} />

                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultText style={styles.mainSwitchesText}>The 5 Growth Mindset Habits  </DefaultText>
            <DefaultText style={styles.secondarySwitchesText}>1. Learn</DefaultText>
            <DefaultText style={styles.secondarySubSwitchesText}>• Learn from your mistakes and don’t feel weak or embarrassed when they happen </DefaultText>
            <DefaultText style={styles.secondarySwitchesText}>2. Pivot</DefaultText>
            <DefaultText style={styles.secondarySubSwitchesText}>• When challenges come, persist and find new ways to approach your goal despite setbacks </DefaultText>
            <DefaultText style={styles.secondarySwitchesText}>3. Practice</DefaultText>
            <DefaultText style={styles.secondarySubSwitchesText}>• Believing that through hard work you can become excellent at anything </DefaultText>
            <DefaultText style={styles.secondarySwitchesText}>4. Leap into it! </DefaultText>
            <DefaultText style={styles.secondarySubSwitchesText}>• Embracing challenges by saying “Let’s do it!” when told that something is going to be hard </DefaultText>
            <DefaultText style={styles.secondarySwitchesText}>5. Cheer</DefaultText>
            <DefaultText style={styles.secondarySubSwitchesText}>• Learning from other people’s success rather than being jealous or envious  </DefaultText>
            <DefaultText style={styles.secondarySubSwitchesText}>{""}</DefaultText>
            <DefaulTextWithTipsIcon textdata="If you are having trouble setting a goal have a chat to your teacher or someone you trust to ask for help.  " />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Growth+Mindset+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Sad]
}

const styles = StyleSheet.create({
    mainSwitchesText: {
        paddingLeft: 10,
    },
    secondarySwitchesText: {
        paddingLeft: 40,
        paddingVertical: 5,
    },
    secondarySubSwitchesText: {
        paddingLeft: 80,
        paddingVertical: 5,
    },
})
