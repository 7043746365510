import { StackScreenProps } from '@react-navigation/stack'
import { useCallback, useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import { Page } from '../common/page'
import { Stepper } from '../common/stepper'
import { rvStudentJoinedClass } from '../student/use-student-state'
import JoinClassForm from './join-class-form'
import LoginForm from './login-form'
import { StudentJoinClassStackNavigatorParamList } from './login-route-param-types'
import { API } from 'aws-amplify'
import { Loading } from '../common/loading'
import { DefaultText } from '../common/default-text'

enum JoinClassTab {
    Login,
    SignUp
}

export const StudentJoinClass = ({ route: { params }, }: StackScreenProps<StudentJoinClassStackNavigatorParamList, 'studentJoinClass'>) => {
    const [tab, setTab] = useState(JoinClassTab.Login)
    const [isValidUrl, setIsValidUrl] = useState(null as boolean | null)
    const [isLoading, setIsLoading] = useState(true)

    const { bulkSignUpId } = params || {}

    useEffect(() => {
        if (bulkSignUpId && isValidUrl === null) {
            rvStudentJoinedClass(bulkSignUpId)
            console.log('student-join-class')
            API.post(
                'AdminQueries',
                '/graphQLGetClassName',
                {
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: {
                        bulkSignUpId: bulkSignUpId
                    },
                }
            ).then(result => {
                setIsValidUrl(!!result)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [bulkSignUpId])

    const studentJoinClass = useCallback(async () => {
        if (bulkSignUpId) {
            rvStudentJoinedClass(bulkSignUpId)
        }
    }, [bulkSignUpId])

    return (
        <Page style={styles.page}>
            <View style={styles.formContainer}>
                {
                    isLoading ? (
                        <Loading isLoading />
                    ) : isValidUrl ? (
                        <>
                            <Stepper
                                values={[
                                    { value: JoinClassTab.Login, label: 'Login' },
                                    { value: JoinClassTab.SignUp, label: 'Sign Up' }
                                ]}
                                currentValue={tab}
                                onChange={setTab}
                                width={350}
                                SelectedFontSize={12}
                                fontSize={12}
                            />
                            {tab === JoinClassTab.Login ? (
                                <LoginForm style={styles.loginFormContainer} onSuccessLogin={studentJoinClass} />
                            ) : (
                                <JoinClassForm bulkSignUpId={bulkSignUpId} style={styles.joinClassFormContainer} />
                            )}
                        </>
                    ) : (
                        <DefaultText>The URL provided is invalid</DefaultText>
                    )
                }
            </View>
        </Page>

    )
}


const styles = StyleSheet.create({

    page: {
        backgroundColor: DecidaColors.White,
    },
    loginFormContainer: {
        marginTop: 30
    },
    joinClassFormContainer: {
        marginTop: 40
    },
    formContainer: {
        justifyContent: 'flex-start',
        height: '50%',
        alignItems: 'center',
    },
    inputContainer: {
        flexDirection: 'column',
        width: 350,
        alignItems: 'center'
    },
    logostyle: {
        backgroundColor: DecidaColors.Black,
        borderRadius: 12,
        padding: 20,
        width: "95%"
    },
    title: {
        fontSize: 36,
        padding: 60,
        color: DecidaColors.Green
    },
    heading: {
        fontSize: 22,

    },
    subtitle: {
        color: DecidaColors.Gray,
        fontSize: 25,
        paddingBottom: 0,
    },
    miniTitle: {
        color: DecidaColors.Gray,
        fontSize: 12,
        paddingBottom: 0,
    },

    buttonSpacing: {
        marginVertical: 30,
        alignItems: 'center',
        justifyContent: 'center',
    },
    joinButton: {
        backgroundColor: DecidaColors.Green,
        padding: 20,
        height: 50,
        width: 150,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonText: {
        color: DecidaColors.White

    },
    existingLogin: {
        color: DecidaColors.Blue
    }
})

