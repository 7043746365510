export enum SchoolAdminFormTab {
    SearchExisting = "Search existing",
    AddNew = "Add new"
}


export enum ClassSettingsOptions {
    ClassSettings = "Class settings",
    ScheduleCheckIns = "Schedule check-ins",
    ClassLogin = "Class login"
}

export enum GoClassSettingsOptions {
    GroupSettings = "Group settings",
    GroupLogin = "Group login"
}


export enum ClazzType {
    ClassMode = "Class mode",
    GoMode = "Go mode",
}


export enum SchoolAdminHierarchyCurrentListView {
    Campus = "Campus",
    Teachers = "Teachers",
    NonTeachingStaffs = "NonTeachingStaffs",
    SchoolAdmins = "SchoolAdmins"
}