import { useState } from 'react'
import { Auth } from 'aws-amplify'
import { Page } from '../common/page'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { NewPassword } from '../login/new-password'
import { showAlert } from './universal-alert'
import { PasswordInput } from '../login/password-input'
import { View, StyleSheet } from 'react-native'
import { BackButton } from './back-button'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useNavigation } from '@react-navigation/native'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'
import { CLIENT_EVENT, createClientLog } from './log-client-event'
import { useReactiveVar } from '@apollo/client'
import { rvTeacherCurrentTeacher } from '../teacher/teacher-state'
import { useStudentState } from '../student/use-student-state'
import ButtonTextForgotPassword from './button-text-forgot-password'
import { DecidaColors } from '../../common/decida-colors'

export const ChangePasswordProfile = () => {

    const [password, setPassword] = useState('')
    const [currentPassword, setCurrentPassword] = useState('')
    const currentTeacher = useReactiveVar(rvTeacherCurrentTeacher)
    const { currentStudent } = useStudentState()
    const { goBack } = useNavigation<DrawerNavigationProp<CheckInNavigatorParamList>>()

    const passwordChangeSucess = (data: string) => {

        showAlert({
            title: 'Password Change',
            message: data,
            leftButtonText: 'Ok',
        })
        setCurrentPassword('')
        setPassword('')
        goBack()

    }

    const updatePassword = () => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, currentPassword, password);
            })
            .then(data => {
                createClientLog({
                    event: CLIENT_EVENT.RESET_PASSWORD_SUCCESS,
                    area: "Profile reset password screen",
                    action: "Clicking reset password, and success changed reset password",
                    payload: {
                        group: currentTeacher ? "teacher" : currentStudent ? "student" : "",
                        nickname: currentTeacher?.cognitoUsername || currentStudent?.cognitoUsername,
                    }
                })
                passwordChangeSucess(data)
            })
            .catch(err => {
                console.log({ err })
                let errorMessage = err.message || ""

                if (err.code === "InvalidParameterException") {
                    errorMessage = "The password provided is incorrect"
                }

                showAlert({
                    title: 'Password Change',
                    message: errorMessage,
                    leftButtonText: 'Ok',
                })
                createClientLog({
                    event: CLIENT_EVENT.RESET_PASSWORD_FAILED,
                    area: "Profile reset password screen",
                    action: "Clicking reset password, and failed to changed reset password",
                    payload: {
                        group: currentTeacher ? "teacher" : currentStudent ? "student" : "",
                        nickname: currentTeacher?.cognitoUsername || currentStudent?.cognitoUsername,
                        error: err.message
                    }
                })
            });
    }

    return (
        <Page style={styles.page}>
            <View style={styles.header}>
                <View style={styles.backButtonContainer}>
                    <BackButton />
                </View>
            </View>
            <View style={styles.container}>
                <DefaultText>Update your password.</DefaultText>
                <PasswordInput
                    onPasswordChange={setCurrentPassword}
                    placeholder="Current Password"
                    newPassword
                />
                <NewPassword onPasswordChange={setPassword} />
                <View style={styles.buttonContainer}>
                    <DefaultButton buttonTextStyle={styles.buttonText} style={styles.button} onPress={updatePassword}>Change Password</DefaultButton>
                    <DefaultButton buttonTextStyle={styles.buttonText} style={[styles.button, styles.cancelButton]} onPress={goBack}>Cancel</DefaultButton>
                </View>
                <ButtonTextForgotPassword />

            </View>
        </Page>
    )
}
const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
    },
    header: {
        flexDirection: 'row',
        width: '100%',
    },
    backButtonContainer: {
        flex: 1,
        paddingTop: 5,
    },
    container: {
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonContainer: {
        flexDirection: 'row',
        width: '100%',
        maxWidth: 330
    },
    buttonText: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: '600'
    },
    cancelButton: {
        backgroundColor: DecidaColors.Red,
        width: '100%',
        marginLeft: 5
    },
    button: {
        flex: 1,
        width: '100%',
    }
})