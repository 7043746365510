import { Entypo } from '@expo/vector-icons';
import _ from 'lodash';
import { useEffect, useMemo, useState } from "react";
import { ScrollView, StyleSheet, TextInput, View } from "react-native";
import { Class, Teacher, TeacherClass } from '../../common/API';
import { UserGroup } from '../../common/constants';
import { DecidaColors } from "../../common/decida-colors";
import { DefaultText } from "../common/default-text";
import { getClassByClassId, getSchoolBySchoolId, getTeacherClassDataByTeacherId, searchTeacherByKeywords } from '../common/helper-api-functions';
import { ImpersonateButton } from '../common/impersonate-button';
import { TakeMeThereButton } from '../common/take-me-there-button';

interface TeacherDetail extends Teacher {
  schoolName: string
  assignedClass?: Class[]
}

export const AdminSearchTeacher = () => {
  const [searchResult, setSearchResult] = useState<TeacherDetail[]>([])
  const [teacherSearch, setTeacherSearch] = useState('')

  const searchTeachers = async () => {
    if (teacherSearch) {
      const currentTeacher = await searchTeacherByKeywords(teacherSearch)

      const updatedResults: TeacherDetail[] = []
      await Promise.all(currentTeacher.map(async (teacher: TeacherDetail) => {
        const school = await getSchoolBySchoolId(teacher.schoolID || "")
        const teacherClass = await getTeacherClassDataByTeacherId(teacher.id)

        console.log('school', school)
        const teacherDetails: TeacherDetail = {
          ...teacher,
        }

        const currentTeacherClasses: Class[] = []

        if (school) {
          // get school name
          teacherDetails.schoolName = school.name
        }

        if (teacherClass?.length) {
          teacherDetails.assignedClass = await getTeacherClassDetails(teacherClass)
        }

        updatedResults.push(teacherDetails)

      }))
      setSearchResult(_.sortBy(updatedResults, 'firstName'))
    } else {
      setSearchResult([])
    }
  }

  const debouncedSearchStudent = useMemo(() => {
    return _.debounce(searchTeachers, 500);
  }, [teacherSearch]);

  useEffect(() => {
    debouncedSearchStudent()
    return () => {
      debouncedSearchStudent.cancel();
    };
  }, [teacherSearch])

  const getTeacherClassDetails = async (teacherClasses: TeacherClass[]) => {
    const results: Class[] = []
    await Promise.all(teacherClasses.map(async (tc) => {
      const teacherClassesDetails = await getClassByClassId(tc.classID)

      if (teacherClassesDetails) results.push(teacherClassesDetails)

    }))

    const sortedData = _.sortBy(results, "name")
    return _.uniqBy(sortedData, "id")
  }

  return (
    <View style={styles.container}>
      <View style={styles.teacherScrollContainer}>
        <View style={styles.searchContainer}>
          <TextInput
            style={styles.searchInput}
            placeholder="Search teachers.."
            value={teacherSearch}
            onChangeText={setTeacherSearch}
          />
        </View>
        <>
          {teacherSearch.length <= 0 ?
            <View style={styles.noResults}>
              <Entypo name="air" size={50} color={DecidaColors.AppleSystemGray2Dark} />
              <DefaultText>Start typing to see results...</DefaultText>
              <DefaultText>(only top 10 results shown)</DefaultText>
            </View>
            : (
              <ScrollView style={styles.teacherScroll} contentContainerStyle={styles.interalTeacherScroll}>
                {searchResult?.map((teacher: TeacherDetail) => {
                  return (
                    <View style={styles.teacherRowContainer} key={teacher.id}>
                      <View style={styles.rowContainer}>
                        <DefaultText>Name: </DefaultText>
                        <DefaultText style={styles.subText}>{`${teacher.firstName} ${teacher.lastName}`}</DefaultText>
                      </View>
                      <View style={styles.rowContainer}>
                        <DefaultText>Email: </DefaultText>
                        <DefaultText style={styles.subText}>{teacher.email}</DefaultText>
                      </View>
                      <View style={styles.rowContainer}>
                        <DefaultText>Cognito Username: </DefaultText>
                        <DefaultText style={styles.subText}>{teacher.cognitoUsername}</DefaultText>
                      </View>
                      <View style={styles.rowContainer}>
                        <DefaultText>School: </DefaultText>
                        <DefaultText style={styles.subText}>{teacher.schoolName}</DefaultText>
                      </View>
                      <DefaultText>Classes: </DefaultText>
                      <View>
                        {teacher?.assignedClass?.length && teacher?.assignedClass?.map((claz) => {
                          if (claz.archived) {
                            return null
                          }
                          return <DefaultText style={styles.listSubText}>• {claz.name}</DefaultText>
                        })}
                      </View>
                      <ImpersonateButton cognitoUsername={teacher.cognitoUsername} userGroup={UserGroup.Teacher} email={teacher.email} />
                      <TakeMeThereButton schoolID={teacher?.schoolID} userGroup={UserGroup.Teacher} />
                    </View>
                  )
                })}
              </ScrollView>
            )
          }
        </>

      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 20,
  },
  teacherScrollContainer: {
    flex: 1,
    backgroundColor: DecidaColors.AppleSystemGray6Light,
    padding: 15,
    borderRadius: 8,
    width: '95%',
    height: '100%',
    position: 'relative',
    paddingHorizontal: 5,
    margin: 10,
  },
  searchContainer: {
    marginHorizontal: 15,
    paddingVertical: 5,
    justifyContent: 'center',
    alignContent: 'center',
  },
  teacherRowContainer: {
    position: 'relative',
    backgroundColor: DecidaColors.AppleSystemGray5Light,
    borderRadius: 10,
    marginVertical: 5,
    padding: 15,

  },
  searchInput: {
    padding: 20,
    backgroundColor: DecidaColors.AppleSystemGray5Light,
    borderRadius: 8,
  },
  teacherScroll: {
    backgroundColor: DecidaColors.AppleSystemGray6Light,
    padding: 15,
    borderRadius: 8,
  },
  noResults: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  plusIconContainer: {
  },
  nameColumn: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  interalTeacherScroll: {
    flex: 1,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  subText: {
    fontSize: 16,
  },
  listSubText: {
    fontSize: 16,
    marginLeft: 10,
    marginVertical: 5
  }
});
