import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { useResponsive } from '../../../common/use-responsive'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { BreathFloatHeader } from './src/breath-float-header'
import { BreathFloatIcon } from './src/breath-float-icon'
import { BreathFloatImageBackground } from './src/breath-float-image-background'
import { BreathFloatImage1 } from './src/breath-float-image1'
import { BreathFloatImage2 } from './src/breath-float-image2'
import { BreathFloatImage3 } from './src/breath-float-image3'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { Worksheets } from '../../../common/worksheets-imports'

export const SwitchesEmotionsAnxiousBreathFloat: CardTemplate = {
    cardId: "7e0187a0-5523-4eb2-9d16-77f85381346b",
    title: 'Breath float',
    Header: BreathFloatHeader,
    Icon: BreathFloatIcon,
    lessonPlan: true,
    mainContainerStyle: {
        maxWidth: '100%',
    },
    PerfectFor: () => {
        const { isNarrowScreen } = useResponsive()
        const Step1 = () => {
            return (
                <View>
                    <View style={styles.rowContainer}>
                        <DefaultText style={styles.contentText}>Close your eyes and imagine yourself sitting</DefaultText>
                        <DefaultText style={styles.contentText}>comfortably on a cloud</DefaultText>

                        <View style={styles.imageContainer}>
                            <BreathFloatImage1 />
                        </View>
                    </View>
                </View>

            )
        }

        const Step2 = () => {
            return (
                <View>
                    <View style={styles.imageBackgroundCotainer}>
                        <BreathFloatImageBackground />
                    </View>

                    <View style={isNarrowScreen ? styles.flexContainerIphone : styles.flexContainer}>

                        <View style={isNarrowScreen ? styles.rowContainerIphone : styles.rowContainer}>
                            <DefaultText style={[styles.contentText, { marginBottom: 40 }]}>As you slowly breath in, imagine yourself floating high up over the ground.</DefaultText>
                            <DefaultText style={styles.contentText}>Hold for a while and take a look around you at the peaceful view from up there.</DefaultText>
                        </View>
                        <View style={isNarrowScreen ? styles.rowContainerImage : styles.rowContainer}>
                            <View style={styles.imageContainer}>
                                <BreathFloatImage2 />
                            </View>
                        </View>
                    </View>

                </View >
            )
        }

        const Step3 = () => {
            return (
                <View>
                    <View style={styles.imageBackgroundCotainer}>
                        <BreathFloatImageBackground />
                    </View>
                    <View style={styles.rowContainer}>
                        <DefaultText style={styles.contentText}>Slowly breathe out, lowering yourself</DefaultText>
                        <DefaultText style={styles.contentText}>to the ground.</DefaultText>
                        <DefaultTextWithoutArrows textdata='' />
                        <DefaultText style={styles.contentText}>Do this as many times as you need to</DefaultText>
                        <DefaultText style={styles.contentText}>feel more peaceful.</DefaultText>
                        <View style={isNarrowScreen ? styles.flexContainerStep3Iphone : styles.flexContainer}>
                            <View style={styles.breathFloatImage3Mobile}>
                                <WhyDoesItWorkComponent message={"Deliberately replicating a relaxed breathing pattern calms your nervous system that controls your body’s involuntary functions. Your body (physiology) basically tells your brain (psychology) that everything is okay so stop being anxious. Research shows that controlled breathing can lower blood pressure and reduce levels of the stress hormones in your blood."} />
                            </View>
                            <View style={[styles.breathFloatImage3Mobile, { marginVertical: isNarrowScreen ? 20 : 0 }]}>
                                <BreathFloatImage3 />
                            </View>
                        </View>

                    </View>

                </View>

            )
        }

        const contents = [
            { Component: Step1, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/breath-float-pg1.mp3' },
            { Component: Step2, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/breath-float-pg2.mp3' },
            { Component: Step3, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/breath-float-pg3.mp3' },
        ]

        return (
            <View style={styles.perfectForContainer}>
                <SwitchCardJuniorContent contents={contents} scheme={Emotion.ANXIOUS} />
            </View>
        )
    },
    Images: undefined,
    CardContent: () => (
        <>
        </>
    )
    ,
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: "",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify the importance of deep breathing as a tool to calm their bodies and minds when they are feeling anxious or stressed.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 25 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students if they have ever felt anxious or worried before.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to share any strategies they have used to calm themselves down.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Instruction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of deep breathing as a tool to help calm our bodies and minds.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Demonstrate how to take deep breaths by placing a hand on the stomach and breathing in through the nose and out through the mouth.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that when we take deep breaths, we are getting more oxygen to our brains, which helps us think more clearly and feel more relaxed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present the ‘Breath float’ switch and walk through the steps involved. Ask the students to follow along.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Pass out the butterfly worksheet and crayons or pencils.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that the butterfly represents their breath.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set a timer for one minute and have students colour in the butterfly as they take deep breaths in and out.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set a timer for three minutes and have students close their eyes and practice deep breathing on their own.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to focus on their breath and try to make the butterfly’s wings expand and contract with each breath.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to take slow, deep breaths in through the nose and out through the mouth.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind them to focus on their breath and try to clear their minds of any distracting thoughts.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students share their experiences with deep breathing and how it made them feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of deep breathing as a tool to help them calm down when they are feeling anxious or stressed.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Extension:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Practice deep breathing as a class before transitioning to a new activity or during a calm-down time in the classroom.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice deep breathing at home or whenever they are feeling overwhelmed.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Butterfly worksheet' link={Worksheets.butterflyBreathing.uri} /> </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Crayons or pencils</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• A timer</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Breathing to reduce stress</DefaultText>
                        <DefaultTextWithLink text='https://www.betterhealth.vic.gov.au/health/healthyliving/breathing-to-reduce-stress' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Two minutes Mindfulness: Balloon Breaths/Belly Breaths - Breathing exercise for Children -</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=2PcCmxEW5WA' />

                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action, CardActionType.Thought],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Anxious]
}

const styles = StyleSheet.create({
    contentText: {
        // color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    breathFloatImage3Mobile: {
        flex: 1,
        width: '100%',
        height: 300,
    },
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        marginHorizontal: 20,
    },
    rowContainerIphone: {
        flex: 1,
        width: '100%',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    rowContainerImage: {
        width: '100%',
    },
    imageBackgroundCotainer: {
        position: 'absolute',
        width: '100%',
        height: 200,
        top: 300,
        margin: 0,
        transform: [{ scale: 2 }]
    },
    flexContainer: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 800,
        alignSelf: 'center',
        gap: 50,
    },
    flexContainerIphone: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        height: 500,
        alignSelf: 'center',
        flexGrow: 1,
    },
    flexContainerStep3Iphone: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'column-reverse',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        alignSelf: 'center',
        flexGrow: 1,
    },
    perfectForContainer: {
        marginBottom: 120
    },
})

