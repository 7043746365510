import { gql, useQuery, useReactiveVar } from "@apollo/client"
import { StyleSheet, TouchableOpacity } from 'react-native'
import { Class, GetClassQuery, GetClassQueryVariables } from '../../common/API'
import { rvCurrentClass } from "../common/common-state"
import { setCurrentClassIdTeacher } from './common-state'
import { DefaultText } from './default-text'
import { RadioButton } from './radio-button'
import { navigate } from './root-navigation'
import { ScreenNames } from './screen-names'
import { getClass } from "../../common/graphql/queries"

interface Props {
    classID: string
}

export const TeacherClassSelectorItem = ({ classID }: Props) => {

    const currentClass = useReactiveVar(rvCurrentClass)
 
    const { data } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: classID } })
    const clazz = data?.getClass as Class

    const setCurrentClass = () => {
        navigate(ScreenNames.TeacherClassMain, { screen: ScreenNames.TeacherClassAdmin, params: { classID } })
        rvCurrentClass(clazz)
    }

    return (
        <TouchableOpacity testID={`class-selector-${clazz?.name}`} style={styles.selection} onPress={setCurrentClass}>
            <RadioButton checked={clazz?.id === currentClass?.id} />
            <DefaultText> {clazz?.goMode && '*' || ''}{clazz?.name || ''} </DefaultText>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    selection: {
        paddingVertical: 10,
        flexDirection: 'row'
    }
})
