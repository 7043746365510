import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { List } from '../common/list';
import { ScreenNames } from '../common/screen-names';
import { AdminStackNavigatorParamList } from './admin-route-param-types';
import { ActiveClassesStudentCount } from '../common/active-clases-students-count';
import { School } from '../../common/API';
import { isDefined } from '../../common/is-defined';

interface Props {
    school: School
}

export const AdminSchoolGoGroupsList = ({ school }: Props) => {
    const { navigate } = useNavigation<StackNavigationProp<AdminStackNavigatorParamList>>()

    const addClass = () => {
        navigate(ScreenNames.AdminClass, { id: undefined, schoolID: school.id, goMode: true })
    }

    const editClass = (id: string) => {
        navigate(ScreenNames.AdminClass, { id, schoolID: school.id })
    }

    return (
        <List
            type='accordion'
            title='Go Group'
            items={school.classes?.items.filter(isDefined).filter(c => c?.goMode === true) || []}
            add={addClass}
            edit={editClass}
            extraComponent={(clazz) => <ActiveClassesStudentCount clazz={clazz} />}
        />
    )
}