import { Linking, StyleSheet, Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { BreathCycleImage1 } from './src/breath-cycle-image1'
import { TheAnticipatorHeader } from './src/the-anticipator-header'
import { TheAnticipatorIcon } from './src/the-anticipator-icon'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { CommonStyles } from '../../../common/const'

export const SwitchesEmotionsScaredTheAnticipator: CardTemplate = {
    cardId: "c2c938c3-ac00-41f2-836d-a4bf2e4b84da",
    title: 'The anticipator',
    Header: TheAnticipatorHeader,
    Icon: TheAnticipatorIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"When you need to control your nervousness."} />
        </>
    ),
    Images: () => (
        <>
            <BreathCycleImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Write down what you are nervous about by saying “I am nervous about...”"} />
                <DefaulttextWithRightArrow textdata={"Now imagine someone asking you about it but instead of saying “I am nervous about...”, respond by saying “I am excited about...”."} />
                <DefaulttextWithRightArrow textdata={"Write down this new response next to the old one. "} />
                <DefaulttextWithRightArrow textdata={"Now repeat the words “I am excited about…, “I can’t wait for…”. "} />
                <DefaulttextWithRightArrow textdata={"Repeat this as much as you need until you feel like your nervous energy has transformed into anticipation. "} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: 'Nervousness and anticipation look exactly the same when we look at the brain, the only difference is the meaning we make about the feeling. When you feel nervous, your body reacts in ways that are designed to help you cope with the perceived threat or challenge, such as by releasing adrenaline and increasing your heart rate. These physical symptoms can be unpleasant and make you feel even more anxious. On the other hand, when you reframe your nervousness as excitement and anticipation, your body feels more positive and energised, and will help you to perform better. ',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn to understand that they have the power to change their emotional state by changing the way they think about a situation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice reframing their internal narrative from nervousness to excitement.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 35 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by asking students to brainstorm a list of situations that might make them feel nervous. These might include public speaking, taking a test, or trying something new. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once the students have generated a list of situations, have them share their thoughts with the class. Encourage them to explain why these situations make them feel nervous. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct instruction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Next, introduce the concept of reframing nervousness as excitement. Explain that nervousness and excitement are actually very similar emotions, and that it's possible to reframe nervousness as excitement by changing the way we think about a situation. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that reframing nervousness as excitement can be helpful because it can change the way you perceive and respond to a situation. When you feel nervous, your body reacts in ways that are designed to help you cope with the perceived threat or challenge, such as by releasing adrenaline and increasing your heart rate. These physical symptoms can be unpleasant and make you feel even more anxious.
                            On the other hand, when you reframe your nervousness as excitement, you are telling yourself that the situation is not threatening, but rather something that you are looking forward to and eager to be a part of. This can help you feel more positive and energized, and can also help you to perform better.
                            It's important to note that reframing nervousness as excitement is not about denying or suppressing your emotions, but rather about finding a more helpful and productive way to think about and respond to the situation. It's a mental strategy that can help you to refocus your attention and manage your emotions more effectively. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ To help students understand this concept, have them brainstorm ways to reframe nervousness as excitement. For example, instead of thinking "I'm so nervous about giving a presentation in front of the class," they might reframe the thought as "I'm so excited to share my ideas with the class!" </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Some examples include:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Starting a New School Year: Instead of feeling nervous about new classes and teachers, get excited about making new friends and discovering interesting subjects.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Public Speaking or Presenting: Rather than being nervous about speaking in front of the class, view it as a chance to showcase your knowledge and share something you're passionate about.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Making New Friends: Instead of worrying about fitting in, be excited about the chance to meet different people, learn about their interests, and build new connections.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Trying a New Activity or Sport: Rather than fearing the unknown, see it as an exciting opportunity to discover a new talent or hobby that you may really enjoy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Taking Tests or Exams: Instead of stressing about exams, think of them as a chance to demonstrate how much you've learned and to challenge yourself academically.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Participating in Competitions: Instead of feeling nervous about competition, embrace the chance to showcase your skills and learn from the experience, win or lose.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Going to the Doctor or Dentist: Instead of fearing check-ups, get excited about taking steps to keep your body healthy and strong.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Attending Sleepovers or Camps: Instead of worrying about being away from home, look forward to the adventure of spending time with friends, trying new activities, and making lasting memories.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Learning a New Skill: Rather than feeling anxious about not being good at something initially, get excited about the journey of learning and improving over time.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Dealing with Change: Instead of fearing change, view it as an opportunity for growth, new experiences, and the chance to adapt to different situations.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent practice (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once the students have come up with some examples of reframing, have them try it out for themselves. Have them choose one of the situations from the list they generated earlier, and have them try reframing it as excitement. Encourage them to use positive language and visualisation techniques to help them shift their mindset. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the key takeaway: the power of reframing nervousness as excitement. Encourage students to reflect on how our thoughts can shape our emotions and, ultimately, our experiences. Reiterate that reframing is not about denying or suppressing feelings but rather about adopting a positive and proactive mindset.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice reframing in their daily lives, particularly when faced with situations that typically make them nervous. Remind them that it's a skill that can be honed over time, and the more they consciously practice it, the more natural it will become.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Highlight the potential benefits of reframing, such as increased positivity, heightened energy, and improved performance. Emphasise that reframing empowers individuals to approach challenges with enthusiasm and confidence, turning potential stressors into exciting opportunities for growth and learning.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ End the lesson on a motivational note, encouraging students to experiment with reframing in various situations. Remind them that developing this skill can contribute not only to their emotional wellbeing but also to their overall resilience and success in navigating life's challenges.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students are using the Switch4Schools app independently, direct students to explore the relevant switch.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pencils and paper </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Relabelling Anxiety as Excitement</DefaultText>
                        <DefaultTextWithLink text='https://www.forbes.com/sites/forbescoachescouncil/2021/04/07/anxiety-vs-relaxationrelabeling-anxiety-as-excitement/?sh=675086da7afd' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to Turn Anxiety into Excitement</DefaultText>
                        <DefaultTextWithLink text='https://www.theatlantic.com/video/index/485297/turn-anxiety-into-excitement/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Reframing Anxiety: Don’t Fight It, Just Change the Label</DefaultText>
                        <DefaultTextWithLink text='https://www.wondriumdaily.com/reframing-anxiety-dont-fight-it-just-change-the-label/' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/The+Anticipator+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action, CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Scared]
}

const styles = StyleSheet.create({
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray
    },
})
