import { View, Text } from 'react-native'
import { RootView } from '../common/root-view'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { StudentOnboardDrawerNavigatorParamList } from './student-route-param-types'
import { CommonStyles } from '../common/const'
import { ScreenNames } from '../common/screen-names'
import { UserEditAvatar } from '../common/user-edit-avatar'

const StudentOnboardMenu = createDrawerNavigator<StudentOnboardDrawerNavigatorParamList>()

const StudentMainOnboardNavigator = () => {
    return (
        <RootView>
            <StudentOnboardMenu.Navigator
                screenOptions={{
                    headerShown: false,
                    unmountOnBlur: true,
                    drawerStyle: CommonStyles.drawer,
                    swipeEdgeWidth: 0
                }}
                backBehavior="history"
                initialRouteName={ScreenNames.LoginStudentEditAvatar}
            >
                <StudentOnboardMenu.Screen name={ScreenNames.LoginStudentEditAvatar} component={UserEditAvatar} />
            </StudentOnboardMenu.Navigator>
        </RootView>
    )
}
export default StudentMainOnboardNavigator