import { StyleSheet } from 'react-native'
import { DeleteFunction, Form, SaveFunction } from '../common/form'
import { gql, useMutation, useQuery } from '@apollo/client'
import { getCampus } from '../../common/graphql/queries'
import { Campus, CreateCampusMutation, CreateCampusMutationVariables, DeleteCampusMutation, DeleteCampusMutationVariables, GetCampusQuery, GetCampusQueryVariables, UpdateCampusMutation, UpdateCampusMutationVariables } from '../../common/API'
import { ScreenNames } from '../common/screen-names'
import { StackScreenProps } from '@react-navigation/stack'
import { AdminStackNavigatorParamList } from './admin-route-param-types'
import { createCampus, deleteCampus, updateCampus } from '../../common/graphql/mutations'
import { PrimitiveOnly } from '../../common/common-types'
import { mutateCampusRefetchQueries as refetchQueries } from './graphql-scripts'


type Props = {
    campusID: string
}

const AdminCampus = ({ route: { params: { campusID, schoolID } }, navigation: { navigate } }: StackScreenProps<AdminStackNavigatorParamList, 'AdminCampus'>) => {

    const { data } = useQuery<GetCampusQuery, GetCampusQueryVariables>(gql`${getCampus}`, { variables: { id: campusID || "" } })

    const [createCampusMutation] = useMutation<CreateCampusMutation, CreateCampusMutationVariables>(gql`${createCampus}`, { refetchQueries })
    const [updateCampusMutation] = useMutation<UpdateCampusMutation, UpdateCampusMutationVariables>(gql`${updateCampus}`, { refetchQueries })
    const [deleteCampusMutation] = useMutation<DeleteCampusMutation, DeleteCampusMutationVariables>(gql`${deleteCampus}`, { refetchQueries })

    const goToAdminSchool = () => navigate(ScreenNames.AdminSchool, { id: data?.getCampus?.schoolID })

    const onSave: SaveFunction<PrimitiveOnly<Campus>> = async (state, modelInstance) => {
        if (modelInstance?.id == undefined || modelInstance.id === '') {
            return (await createCampusMutation({
                variables: {
                    input: {
                        name: state.name?.value || '',
                        schoolID,
                        _version: 1,
                    }
                }
            })).data?.createCampus!
        } else {
            return (await updateCampusMutation({
                variables: {
                    input: {
                        id: modelInstance.id,
                        name: state.name?.value || '',
                        _version: modelInstance._version,
                    }
                }
            })).data?.updateCampus!
        }
    }

    const onDelete: DeleteFunction<Campus> = async (modelInstance) => {
        await deleteCampusMutation({
            variables: {
                input: {
                    id: modelInstance.id,
                    _version: modelInstance._version,
                }
            }
        })
    }

    return (

        <Form
            model={data?.getCampus as Campus | undefined}
            goBack={goToAdminSchool}
            name='Campus'
            onSave={onSave}
            onDelete={onDelete}
            config={{
                schoolID: {
                    default: schoolID,
                    hide: true
                },
                name: {},
            }}
        // additionalComponents={ClassAditionalComponents}
        />
    )
}
export default AdminCampus
const styles = StyleSheet.create({})