import { Activities } from '../../common/activities'
import { ActivityGroupTemplate } from '../../common/activity-group-template'
import { EnergizersIcon } from '../icons/energizers-icon'

export const SwitchesActivityGroupEnergizers: ActivityGroupTemplate = {
    title: 'Energisers',
    Icon: EnergizersIcon,
    activity: Activities.ENERGIZERS,
    subtitle: 'Activities to help insert some energy into the class'
}
