import { View } from 'react-native'
import { DefaultText } from '../../../../common/default-text'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import DefaultTextWithCustomLink from '../../../common/default-text-with-custom-link'
import { DefaultTextWithDownArrow } from '../../../common/default-text-with-down-arrow'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { StressBallIcon } from '../../icons/stress-balls-icon'
import { StressBallIconInverted } from '../../icons/stress-balls-icon-inverted'

export const SwitchesActivitiesResilienceBuildersStressBalls: CardTemplate = {
    cardId: "befd4e39-c785-4c66-9e25-785251dbe904",
    title: 'Stress Balls',
    Header: StressBallIconInverted,
    Icon: StressBallIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Creating energy through game play and laughter, and demonstrating how good teamwork, leadership and collaboration are key for being successful, while experiencing the pressure of stress to build personal resiliency."} />
            <DefaultTextWithoutArrows textdata={"Duration: 30 – 60 minutes"} />

            <DefaultText>Equipment required:</DefaultText>
            <DefaultText style={{ paddingLeft: 40 }}>• A set of 100 light plastic balls (similar to used in a ball pit) or an appropriate substitute for each team</DefaultText>
            <DefaultText style={{ paddingLeft: 40 }}>• Two plastic shopping bags or containers for each team</DefaultText>
            <DefaultText style={{ paddingLeft: 40 }}>• Timer</DefaultText>
            <DefaultText style={{ paddingLeft: 40 }}>• <DefaultTextWithCustomLink text='Download slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Stress+balls+activity.pptx' style={{ paddingLeft: 0 }} /></DefaultText>
            <DefaultText style={{ paddingLeft: 40 }}>• <DefaultTextWithCustomLink text='Print rules' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Stress+balls+rules.docx' style={{ paddingLeft: 0 }} /> for each group</DefaultText>

            <DefaultText style={{ paddingVertical: 5, marginTop: 10, textAlign: 'justify' }}>Advise groups they will be given time to work out the plan, then they will have a set time limit to “process” the balls – move them from the original container through the team and then into the shopping bag.  They must follow the listed rules. </DefaultText>
            <DefaultText style={{ paddingLeft: 40 }}>1. Each person in the team must touch each ball at least once. ​</DefaultText>
            <DefaultText style={{ paddingLeft: 40 }}>2. Balls cannot be passed to your direct neighbour to your immediate left or right.</DefaultText>
            <DefaultText style={{ paddingLeft: 40 }}>3. The person that starts the process (takes a ball from the container) must be the person that finishes the process (puts the ball in the bag).</DefaultText>
            <DefaultText style={{ paddingLeft: 40 }}>4. The person that starts the process (takes a ball from the container) must be the person that finishes the process (puts the ball in the bag).</DefaultText>

            <DefaultText style={{ paddingVertical: 5 }}>If found cheating they will have to return all balls to the original container and start again.  They will be given time to debrief and work out how to make things better before the next round.  There will be 3 rounds in total.  The team with the most balls processed at the end of the game will be the winner. </DefaultText>
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <View style={{ marginTop: 20 }}>
            <DefaultTextWithoutArrows textdata={"Round 1"} />
            <DefaultTextWithoutArrows textdata={"Set the timer and announce the planning stage (5 minutes) and start of the sprint (2 minutes). ​"} />
            <DefaultTextWithoutArrows textdata={"Observe the behaviours within the groups.  Look for good demonstrations of leadership, collaboration, listening, and focus. "} />
            <DefaultTextWithoutArrows textdata={"Keep a tally of the team’s results."} />
            <DefaultTextWithoutArrows textdata={"Ask the team to share their reflections on the experience."} />
            <DefaultTextWithDownArrow textdata={"Provide a shout out for those that handled the situation really well."} />

            <DefaultTextWithoutArrows textdata={"Round 2"} />
            <DefaultTextWithoutArrows textdata={"Announce the new rule - Team members can only use one hand to touch the ball."} />
            <DefaultTextWithoutArrows textdata={"Set the timer and announce the planning stage (5 minutes) and start of the sprint (2 minutes)."} />
            <DefaultTextWithoutArrows textdata={"Observe the behaviours within the groups.  This time, also look for good demonstrations of adaptability and communication."} />
            <DefaultTextWithoutArrows textdata={"Ask the team to share their reflections on the experience."} />
            <DefaultTextWithDownArrow textdata={"Provide a shout out for those that handled the situation really well."} />

            <DefaultTextWithoutArrows textdata={"Round 3"} />
            <DefaultTextWithoutArrows textdata={"Announce the new rule - Two members of each team must join a new team."} />
            <DefaultTextWithoutArrows textdata={"Set the timer and announce the planning stage (5 minutes) and start of the sprint (2 minutes)."} />
            <DefaultTextWithoutArrows textdata={"Ask the team to share their reflections on the experience."} />
            <DefaultTextWithoutArrows textdata={"Comment on the observations made (do this with good humour and respect when there was poor performance) highlighting that stress and pressure can impact our ability to work with calm and focus."} />
            <DefaultTextWithoutArrows textdata={"Ask the team to think about and discuss different strategies they can use to build resilience."} />
        </View>

    ),
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.RESILIENCE_BUILDERS],
    action: [CardActionType.Action],
    level: []
}

