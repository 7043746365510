import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { AntDesign } from '@expo/vector-icons'
import { Pressable, StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from '../common/default-text'
import { GetSchoolCampusesAndGroupsQueryResponse, getSchoolCampusesAndGroupsQuery } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query'
import DefaultTextWithCustomLink from '../switches/common/default-text-with-custom-link'
import SchoolAdmiGroupsAndClassListItem from './school-admin-groups-and-class-list-item'
import { rvCurrentSchoolID, rvSelectedCampusID } from './school-admin-state'
import _ from 'lodash'
import SchoolAdminFormAddGroup from './school-admin-form-add-group'
import { useState } from 'react'
import { StyleProp } from 'react-native'
import { ViewStyle } from 'react-native'
import { rvUserGroup } from '../login/login-state'
import { UserGroup } from '../../common/constants'
import { showAlert } from '../common/universal-alert'
import { Br } from '../common/br'
import { GetSchoolQueryVariables } from '../../common/API'

type Props = {
    schoolID?: string
    campusID?: string
    containerStyle?: StyleProp<ViewStyle>
}
const SchoolAdmiGroupsAndClassList = ({ schoolID, campusID, containerStyle }: Props) => {

    const userGroup = useReactiveVar(rvUserGroup)
    const [addGroup, setAddGroup] = useState(false)


    const currentSchoolID = schoolID || useReactiveVar(rvCurrentSchoolID)
    const curretCampusID = campusID || useReactiveVar(rvSelectedCampusID)

    const { data: schoolData } = useQuery<GetSchoolCampusesAndGroupsQueryResponse>(getSchoolCampusesAndGroupsQuery, { variables: { id: currentSchoolID || "" }, skip: !currentSchoolID })

    const currentCampusClassGroups = schoolData?.getSchool?.campuses?.items?.find(campus => campus.id === curretCampusID)?.classGroups.items.filter(g => g._deleted !== true) || []

    const totalClasses: number = _.sumBy(currentCampusClassGroups, (group) => group?.classes?.items.filter((claz) => claz._deleted !== true && !claz.archived).length || 0);

    const toggleAddClassGroup = () => {
        setAddGroup(prev => !prev)
    }

    const handleBulkUpload = () => {
        showAlert({
            leftButtonText: "Ok",
            title: "",
            message: <DefaultText style={styles.messageText}>Please download the following template/s as required:
                {'\n'}
                <DefaultTextWithCustomLink style={styles.linkText} text='Teachers template' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Teachers+bulk+upload+large+school.xlsx' /> {'\n'}
                <DefaultTextWithCustomLink style={styles.linkText} text='Students template' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Students+bulk+upload+large+school.xlsx' /> {'\n'}
                <DefaultTextWithCustomLink style={styles.linkText} text='Non-teaching staff template' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Non-teaching+staff+bulk+upload+large+school.xlsx' /> {'\n'}
                {'\n'}
                Use these templates to gather the necessary information for creating accounts for teachers, students and/or non-teaching staff. Once you’ve completed the template/s, please send a copy to the team at <DefaultTextWithCustomLink style={styles.linkText} text='support@switch4schools.com.au' link='mailto: support@switch4schools.com.au' /> and we will let you know once it has been processed.</DefaultText>
        })
    }

    return (
        <View style={[styles.mainContainer, containerStyle]}>
            {userGroup === UserGroup.SchoolAdmin && (
                <>
                    <DefaultText style={{ color: DecidaColors.AppleSystemGray2Dark }}>{currentCampusClassGroups.length || 0} Groups / {totalClasses} classes</DefaultText>
                    <Pressable onPress={handleBulkUpload} style={styles.bulkUploadButton}>
                        <DefaultText style={[styles.bulkUploadText, { color: DecidaColors.DarkBlue }]}>Bulk upload</DefaultText>
                    </Pressable>
                </>
            )}

            {_.sortBy(currentCampusClassGroups, "name")?.map((group) => {
                return <SchoolAdmiGroupsAndClassListItem key={group.id} group={group} />
            })}
            <View style={styles.formContainer}>
                {addGroup && (
                    <SchoolAdminFormAddGroup onFinishSubmit={toggleAddClassGroup} />
                )}
                <Pressable onPress={toggleAddClassGroup} style={styles.buttonAddGroupContainer}>
                    <DefaultText style={styles.text}><AntDesign name="plus" size={14} color={DecidaColors.AppleSystemGray2Dark} /> Add year level / group</DefaultText>
                </Pressable>
            </View>
        </View>
    )
}
export default SchoolAdmiGroupsAndClassList

const styles = StyleSheet.create({
    bulkUploadButton: {
        alignSelf: 'flex-start',
    },
    mainContainer: {
        flex: 1,
        padding: 40,
        maxWidth: '70%',
    },
    formContainer: {
        marginTop: 10,
    },
    bulkUploadText: {
        marginVertical: 10,
        fontSize: 14,
    },
    buttonAddGroupContainer: {
        marginVertical: 10
    },
    text: {
        fontSize: 16,
        color: DecidaColors.GrayText,
    },
    messageText: {
        textAlign: 'center'
    },
    linkText: {
        paddingLeft: 0
    },
})