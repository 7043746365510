import { View } from 'react-native'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { WordJumbleIcon } from '../../icons/word-jumble-icon'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'
import { WordJumbleIconInverted } from '../../icons/word-jumble-icon-inverted'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithIndentation } from '../../../common/default-text-with-indentation'
import { DefaultTextWithIndentationGrayDot } from '../../../common/default-text-with-indentation-gray-dot'

export const SwitchesActivitiesNumberAndWordGamesWordJumble: CardTemplate = {
    cardId: "eaaa2fa2-5b37-4ed0-b575-86865ceddbfc",
    title: 'Word jumble',
    Header: WordJumbleIconInverted,
    Icon: WordJumbleIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Practicing group social and emotional skills."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Seven large cards with these letters written in bold block letters: CDEEITX"} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Ask seven students to come to the front of the room and stand in a straight line."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Hand out a card to each student and have them hold their letter card so the audience can read it easily."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Ask members of the class to call out directions to the letter holders to rearrange them to in the correct order to spell the word."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Give some hints to the letter holders if necessary to speed up the process."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"When the word is correctly formed, ask everyone to read it aloud at the count of three."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Post activity de-brief:"} />
            <DefaultTextWithoutArrowsItallic textdata={"To extend this activity also raise EQ, have a short debriefing session, asking the class if they were really excited about the activity. Ask participants to identify reasons that made this an exciting activity. "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation style={{ marginVertical: 0, }} textdata={"Use appropriate probing questions to elicit these causes:"} />
            <DefaultTextWithIndentationGrayDot textdata={"The activity was brief."} />
            <DefaultTextWithIndentationGrayDot textdata={"The activity was unexpected."} />
            <DefaultTextWithIndentationGrayDot textdata={"Nobody was forced to participate."} />
            <DefaultTextWithIndentationGrayDot textdata={"The activity was intellectually stimulating."} />
            <DefaultTextWithIndentationGrayDot textdata={"People were not taking turns and behaving politely."} />
            <DefaultTextWithIndentationGrayDot textdata={"It was a group challenge rather than an individual challenge."} />
            <DefaultTextWithIndentationGrayDot textdata={"It was a cooperative activity."} />
            <DefaultTextWithIndentationGrayDot textdata={"The feedback was immediate."} />
            <DefaultTextWithIndentationGrayDot textdata={"The letter holders enjoyed being the center of attention."} />
            <DefaultTextWithIndentationGrayDot textdata={"Members of the audience enjoyed their power."} />
            <DefaultTextWithIndentationGrayDot textdata={"There was a sense of urgency."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Ask students to brainstorm how these ideas can be applied to increase the excitement level of everyday activities."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.NUMBER_AND_WORD_GAMES,
    whyDoesItWorkText: '',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Word+Jumble.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.NUMBER_AND_WORD_GAMES],
    action: [CardActionType.Action],
    level: []
}

