import { MutableRefObject } from "react"
import { CellObject, WorkSheet } from "xlsx"
import { CreateCampusInput, CreateClassGroupInput, CreateClassInput, CreateTeacherClassInput, GetTeacherByEmailQuery, GetTeacherByEmailQueryVariables } from "../../../common/API"
import { Message, TeacherToCreate, TeacherToUpdate, Sheet } from "./common-import-school-data-const-and-types"
import { convertSpreadsheetToRows } from "./convert-spreadsheet-to-rows"
import { emailValidator } from "../../common/email-validator"
import { validateFieldNotUsedInPreviousRows } from "./validate-field-not-used-in-previou-rows"
import _ from "lodash"
import { SchoolHierarchy } from "./list-school-hierarchy"
import { isDefined } from "../../../common/is-defined"
import { LazyQueryExecFunction } from "@apollo/client"
import { v4 as randomUUID } from "uuid"
import { asapPromiseQueue } from "../../../common/asap-promise-queue"

type Params = {
    sheet: WorkSheet,
    schoolID: string,
    errors: MutableRefObject<Message[]>,
    warnings: MutableRefObject<Message[]>,
    info: MutableRefObject<Message[]>,
    currentSchoolHierarchy: MutableRefObject<SchoolHierarchy>,
    campusesToCreate: MutableRefObject<CreateCampusInput[]>,
    classGroupsToCreate: MutableRefObject<CreateClassGroupInput[]>,
    classesToCreate: MutableRefObject<CreateClassInput[]>,
    teachersToCreate: MutableRefObject<TeacherToCreate[]>,
    teachersToUpdate: MutableRefObject<TeacherToUpdate[]>,
    teacherClassesToCreate: MutableRefObject<CreateTeacherClassInput[]>,
    getTeacherByEmailQuery: LazyQueryExecFunction<GetTeacherByEmailQuery, GetTeacherByEmailQueryVariables>,
    setProcessingMessage: React.Dispatch<React.SetStateAction<string>>,
}

export const validateHierarchyTeacherSheet = async ({
    sheet,
    schoolID,
    errors,
    warnings,
    info,
    currentSchoolHierarchy,
    campusesToCreate,
    classGroupsToCreate,
    classesToCreate,
    teachersToCreate,
    teachersToUpdate,
    teacherClassesToCreate,
    getTeacherByEmailQuery,
    setProcessingMessage,
}: Params) => {
    if (sheet == null) {
        errors.current.push({ sheet: Sheet.All, message: 'Cannot find "Add Teachers" tab' })
        return
    }

    if (!(sheet['B7'] as CellObject)?.v?.toString().startsWith('First Name')) {
        errors.current.push({ sheet: Sheet.Teacher, row: 7, message: 'header column expected, please do not modify row 1-8 of template file.' })
        return
    }

    const rows = convertSpreadsheetToRows({
        startingRow: 8,
        columnOrder: ['hasError', 'firstName', 'lastName', 'email', 'campusName', 'classGroupName', 'assignToAllClasses'],
        sheet,
        filter: row => row.firstName != null || row.lastName != null || row.email != null,
        hasDynamicClasses: true,
    }).map(row => ({ ...row, email: row.email?.toLowerCase() }))

    await asapPromiseQueue(rows, async ({ firstName, lastName, email, row, campusName, classGroupName, assignToAllClasses, classes }, index) => {
        setProcessingMessage(`Validating row ${index + 1}/${rows.length}`)
        await new Promise(r => setTimeout(() => r(undefined))) // an immediately resolved promise to give UI a chance to update

        const missingMandatoryFields: string[] = []
        const filteredClasses = classes.filter(isDefined).filter(c => c !== '')
        firstName == null && missingMandatoryFields.push('First Name')
        lastName == null && missingMandatoryFields.push('Last Name')
        email == null && missingMandatoryFields.push('Email address')
        campusName == null && missingMandatoryFields.push('Campus')
        classGroupName == null && missingMandatoryFields.push('Grouping')

        if (missingMandatoryFields.length > 0) {
            errors.current.push({ sheet: Sheet.Teacher, row, message: 'missing mandatory fields: ' + missingMandatoryFields.join(', ') })
        }

        if (assignToAllClasses !== undefined && assignToAllClasses !== 'No') {
            warnings.current.push({ sheet: Sheet.Teacher, row, message: 'assign to all classes not supported in hierarchy upload' })
        }

        if (filteredClasses.length === 0) {
            errors.current.push({ sheet: Sheet.Teacher, row, message: 'teacher must be assigned to at least 1 class' })
        }

        if (email && emailValidator(email) === "Email is not valid.") {
            errors.current.push({ sheet: Sheet.Teacher, row, message: 'teacher email is not valid' })
        }

        validateFieldNotUsedInPreviousRows(rows, 'email', index, Sheet.Teacher, errors)

        if (campusName != null && classGroupName != null && filteredClasses.length > 0) {
            // #region process teacher
            if (email == null || emailValidator(email) !== "Email is valid.") {
                return
            }

            const teachers = (await getTeacherByEmailQuery({ variables: { email } })).data?.getTeacherByEmail?.items.filter(isDefined).filter(t => t._deleted !== true) || []
            let teacher: (typeof teachers)[0] | undefined

            if (teachers.length > 1) {
                teacher = teachers.find(i => i?.schoolID === schoolID)

                if (teacher == null) {
                    errors.current.push({ sheet: Sheet.Teacher, row, message: 'teacher email found in multiple schools and none is assigned to current school, please process this line manually' })
                    return
                }
            } else {
                teacher = teachers[0]
            }

            if (teacher) {
                if (schoolID !== teacher.schoolID) {
                    warnings.current.push({ sheet: Sheet.Teacher, row, message: `${teacher.firstName} ${teacher.lastName} will be moved from "${teacher.schoolID}" to the current school` })
                }

                if (firstName !== teacher.firstName || lastName !== teacher.lastName || email !== teacher.email || schoolID !== teacher.schoolID) {
                    teachersToUpdate.current.push({
                        id: teacher.id,
                        email: email,
                        schoolID,
                        firstName: firstName,
                        lastName: lastName,
                        cognitoUsername: email,
                        _version: teacher._version,
                    })
                }
            } else {
                const teacherID = randomUUID()
                teachersToCreate.current.push({
                    id: teacherID,
                    email,
                    schoolID,
                    firstName: firstName,
                    lastName: lastName,
                    cognitoUsername: email,
                })
                teacher = {
                    __typename: 'Teacher',
                    id: teacherID,
                    schoolID,
                    email,
                    cognitoUsername: email,
                    _lastChangedAt: Date.now(),
                    _version: 1
                }
            }
            // #endregion

            if (currentSchoolHierarchy.current[campusName] == null) {
                const campusID = randomUUID()
                campusesToCreate.current.push({
                    id: campusID,
                    schoolID,
                    name: campusName,
                    _version: 1,
                })
                currentSchoolHierarchy.current[campusName] = {
                    id: campusID,
                    name: campusName,
                    classGroups: {},
                }
            }

            const campus = currentSchoolHierarchy.current[campusName]
            if (campus.classGroups[classGroupName] == null) {
                const classGroupID = randomUUID()
                classGroupsToCreate.current.push({
                    id: classGroupID,
                    schoolID,
                    campusID: campus.id,
                    name: classGroupName,
                    _version: 1,
                })
                campus.classGroups[classGroupName] = {
                    id: classGroupID,
                    name: classGroupName,
                    classes: {},
                }
            }

            const classGroup = campus.classGroups[classGroupName]
            classes.forEach(className => {
                if (classGroup.classes[className] == null) {
                    const classID = randomUUID()
                    classesToCreate.current.push({
                        id: classID,
                        name: className,
                        schoolID,
                        classGroupID: classGroup.id,
                        _version: 1,
                    })
                    classGroup.classes[className] = {
                        id: classID,
                        name: className,
                        teachers: [],
                        students: [],
                    }
                }

                const clazz = classGroup.classes[className]
                if (clazz.teachers.find(teacherID => teacherID === teacher!.id) == null) {
                    teacherClassesToCreate.current.push({
                        classID: clazz.id,
                        teacherID: teacher!.id,
                        _version: 1,
                    })
                }
            })

        }
    }, 10)

    info.current.push({ sheet: Sheet.Teacher, message: `${campusesToCreate.current.length} campus(es) to be created` })
    info.current.push({ sheet: Sheet.Teacher, message: `${classGroupsToCreate.current.length} classGroup(s) to be created` })
    info.current.push({ sheet: Sheet.Teacher, message: `${classesToCreate.current.length} class(es) to be created` })
    info.current.push({ sheet: Sheet.Teacher, message: `${teachersToCreate.current.length} teacher(s) to be created and ${teachersToUpdate.current.length} to be updated` })
    info.current.push({ sheet: Sheet.Teacher, message: `${teacherClassesToCreate.current.length} teacher class(es) to be created` })
}