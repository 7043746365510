import Svg, { Path } from 'react-native-svg'

interface Props {
    stylefill?: string,
}

export const IconDownArrow = ({ stylefill }: Props) =>
    <Svg width="14.796" height="8.957" viewBox="0 0 14.796 8.957">
        <Path d="M4748.69,3914.048a1.6,1.6,0,0,1,.451-1.106,1.57,1.57,0,0,1,2.211,0l4.734,4.718,4.733-4.733a1.564,1.564,0,0,1,2.211,2.211l-5.839,5.839a1.54,1.54,0,0,1-1.105.451,1.611,1.611,0,0,1-1.106-.451l-5.839-5.824a1.623,1.623,0,0,1-.451-1.1Z" transform="translate(-4748.69 -3912.472)" fill={stylefill || "#fff"} />
    </Svg>
