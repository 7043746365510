import { View } from 'react-native'
import { DefaultText } from '../../../../common/default-text'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import DefaultTextWithCustomLink from '../../../common/default-text-with-custom-link'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { TheSafeSpaceWallIcon } from '../../icons/the-safe-space-wall-icon'
import { TheSafeSpaceWallIconInverted } from '../../icons/the-safe-space-wall-icon-inverted'

export const SwitchesActivitiesSeparationAnxietyTheSafeSpaceWall: CardTemplate = {
    cardId: "91c60d0a-b731-43d3-89f9-ccbe0b898597",
    title: 'The safe space wall',
    Header: TheSafeSpaceWallIconInverted,
    Icon: TheSafeSpaceWallIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Helping students cope with separation anxiety by providing a sense of comfort and security through their artwork."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Give each student a blank piece of paper and ask them to draw a picture of something or someone that makes them feel happy and safe. It could be a family member, pet, favourite toy, or anything else that brings them comfort."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Assign a space on the wall of your classroom where these pictures can be displayed."} />

        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.SEPARATION_ANXIETY,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <>

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={""} />
            <DefaulTextWithTipsIcon textdata={<DefaultText>Tip - Refer to the <DefaultTextWithCustomLink indent={0} text='Safe sketch' link={`/Card?id=aebfba28-e817-4140-9f28-c686b2fa3377&openLessonPlan=false`} /> switching activity and lesson plan, including the ‘Special place’ worksheet, for additional guidance on creating safe space visualisations.</DefaultText>} />
        </>

    ),
    audioUrl: "",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.SEPARATION_ANXIETY],
    action: [CardActionType.Action],
    level: []

}

