import { Activities } from '../../common/activities'
import { ActivityGroupTemplate } from '../../common/activity-group-template'
import { ForRemoteClassesIcon } from '../icons/for-remote-classes-icon'

export const SwitchesActivityGroupForRemoteClasses: ActivityGroupTemplate = {
    title: 'For remote classes',
    Icon: ForRemoteClassesIcon,
    activity: Activities.FOR_REMOTE_CLASSES,
    subtitle: "Online activities that can help engage students when they are connected remotely."
}
