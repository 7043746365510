import { StackScreenProps } from '@react-navigation/stack'
import * as DocumentPicker from 'expo-document-picker'
import { ScrollView, StyleSheet, View } from 'react-native'
import { BackButton } from '../../common/back-button'
import { DefaultButton } from '../../common/default-button'
import { AdminStackNavigatorParamList } from '../admin-route-param-types'
import { read } from 'xlsx'
import { useRef, useState } from 'react'
import { DefaultText } from '../../common/default-text'
import { CognitoMessageAction } from '../../common/const'
import { Br } from '../../common/br'
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { CreateClassInput, CreateClassMutation, CreateClassMutationVariables, CreateSchoolAdminInput, CreateSchoolAdminMutation, CreateSchoolAdminMutationVariables, CreateTeacherMutation, CreateTeacherMutationVariables, CustomCreateStudentMutation, CustomCreateStudentMutationVariables, StudentStatus, UpdateSchoolAdminInput, UpdateSchoolAdminMutation, UpdateSchoolAdminMutationVariables, UpdateStudentMutation, UpdateStudentMutationVariables, UpdateTeacherMutation, UpdateTeacherMutationVariables } from '../../../common/API'
import _ from 'lodash'
import { getApiHeaders } from '../../common/get-api-header'
import { createClass, createSchoolAdmin, createTeacher, customCreateStudent, updateSchoolAdmin, updateStudent, updateTeacher } from '../../../common/graphql/mutations'
import { usernameTaken } from '../../login/username-taken-validator'
import { listClassTeachersAndStudentsBySchoolID, ListClassTeachersAndStudentsBySchoolIDQueryItem } from './list-class-teachers-and-students-by-school-id'
import { sanitiseNameForCognitoUsername } from './sanitise-name-for-conginto-username'
import { Message, ProcessingStatus, Sheet, TeacherToCreate, TeacherToUpdate, OtherTeachers, StudentToCreate, StudentToUpdate, OtherStudents } from './common-import-school-data-const-and-types'
import { validateAdministratorSheet } from './validate-administrator-sheet'
import { validateTeacherSheet } from './validate-teacher-sheet'
import { validateStudentSheet } from './validate-student-sheet'
import { Messages } from './messages'
import { assignStudentToClasses } from './assign-student-to-classes'
import { assignTeacherToClasses } from './assign-teacher-to-classes'
import { promiseQueue } from '../../../common/promise-queue'
import { CLIENT_EVENT, createClientLog } from '../../common/log-client-event'
import { DecidaColors } from '../../../common/decida-colors'
import { gql, useMutation } from '@apollo/client'

export const AdminSchoolImportData = ({ navigation: { goBack }, route: { params: { schoolID } } }: StackScreenProps<AdminStackNavigatorParamList, 'AdminSchoolImportTeachers'>) => {
    const errors = useRef([] as Message[])
    const warnings = useRef([] as Message[])
    const info = useRef([] as Message[])
    const [fileLoaded, setFileLoaded] = useState(false)
    const [processingStatus, setProcessingStatus] = useState(ProcessingStatus.NotProcessed)
    const [processingMessage, setProcessingMessage] = useState('')
    const schoolAdminToCreate = useRef([] as CreateSchoolAdminInput[])
    const schoolAdminToUpdate = useRef([] as UpdateSchoolAdminInput[])
    const teacherToCreate = useRef([] as TeacherToCreate[])
    const teacherToUpdate = useRef([] as TeacherToUpdate[])
    const otherTeachers = useRef([] as OtherTeachers[])
    const classToCreate = useRef([] as CreateClassInput[])
    const allClassesFromTeacherSheet = useRef([] as string[])
    const studentToCreate = useRef([] as StudentToCreate[])
    const studentToUpdate = useRef([] as StudentToUpdate[])
    const otherStudents = useRef([] as OtherStudents[])
    const existingClasses = useRef([] as ListClassTeachersAndStudentsBySchoolIDQueryItem[])
    const processedAdminCount = useRef(0)
    const processedClassCount = useRef(0)
    const processedTeacherCount = useRef(0)
    const processedStudentCount = useRef(0)

    const [customCreateStudentMutation] = useMutation<CustomCreateStudentMutation, CustomCreateStudentMutationVariables>(gql`${customCreateStudent}`, {})

    const handleImportExcel = async () => {
        errors.current = []
        warnings.current = []
        info.current = []
        setFileLoaded(false)
        setProcessingStatus(ProcessingStatus.NotProcessed)
        setProcessingMessage('')
        schoolAdminToCreate.current = []
        schoolAdminToUpdate.current = []
        teacherToCreate.current = []
        teacherToUpdate.current = []
        otherTeachers.current = []
        classToCreate.current = []
        allClassesFromTeacherSheet.current = []
        studentToCreate.current = []
        studentToUpdate.current = []
        otherStudents.current = []
        processedAdminCount.current = 0
        processedClassCount.current = 0
        processedTeacherCount.current = 0
        processedStudentCount.current = 0

        if (!schoolID) {
            errors.current = [{
                sheet: Sheet.All,
                message: 'Invalid schoolID. Please log out, log back in, and try again.'
            }]
            return
        }

        let result = await DocumentPicker.getDocumentAsync({
            type: "text/xlsx",
            copyToCacheDirectory: true
        });

        if (result && !result.canceled && result.output?.length) {
            const xlsx = read(await result.output[0].arrayBuffer())

            const administratorSheet = xlsx.Sheets[Sheet[Sheet.Administrator]]
            const teacherSheet = xlsx.Sheets[Sheet[Sheet.Teacher]]
            const studentSheet = xlsx.Sheets[Sheet[Sheet.Student]]

            if (administratorSheet == null && teacherSheet == null && studentSheet == null) {
                errors.current.push({ sheet: Sheet.All, message: `Must contain at least one of the following sheets: "${Sheet[Sheet.Administrator]}", "${Sheet[Sheet.Teacher]}", or "${Sheet[Sheet.Student]}"` })
            }

            if (teacherSheet != null || studentSheet != null) {
                existingClasses.current = await listClassTeachersAndStudentsBySchoolID(schoolID)
            }

            await Promise.all([
                validateAdministratorSheet({ sheet: administratorSheet, schoolID, errors, warnings, info, schoolAdminToCreate, schoolAdminToUpdate }),
                validateTeacherSheet({ sheet: teacherSheet, schoolID, errors, warnings, info, teacherToCreate, teacherToUpdate, otherTeachers, allClassesFromTeacherSheet, existingClasses, classToCreate }),
                validateStudentSheet({ sheet: studentSheet, schoolID, errors, warnings, info, studentToCreate, studentToUpdate, otherStudents, allClassesFromTeacherSheet, existingClasses }),
            ])

            errors.current = _.sortBy(errors.current, ['sheet', 'row'])
            warnings.current = _.sortBy(warnings.current, ['sheet', 'row'])
            info.current = _.sortBy(info.current, ['sheet', 'row'])

            setFileLoaded(true)
        }
    }

    const createAccountsAndClasses = async () => {
        setProcessingStatus(ProcessingStatus.Processing)

        const totalAdmin = schoolAdminToCreate.current.length + schoolAdminToUpdate.current.length
        await promiseQueue(schoolAdminToCreate.current, async admin => {
            try {
                await API.post(
                    'AdminQueries',
                    '/createSchoolAdmin',
                    {
                        headers: await getApiHeaders(),
                        body: {
                            cognitoUsername: admin.cognitoUsername,
                            email: admin.email,
                            messageAction: CognitoMessageAction.SUPPRESS
                        },
                    }
                )

                await API.graphql(graphqlOperation(
                    createSchoolAdmin,
                    {
                        input: admin
                    } as CreateSchoolAdminMutationVariables
                )) as GraphQLResult<CreateSchoolAdminMutation>

                processedAdminCount.current++
                setProcessingMessage(`Processed administrator ${processedAdminCount.current}/${totalAdmin}`)
            } catch (e) {
                console.log('error creating school admin')
                console.log(admin)
                console.log(e)
            }
        })

        await Promise.all(schoolAdminToUpdate.current.map(async admin => {
            try {
                await API.graphql(graphqlOperation(
                    updateSchoolAdmin,
                    {
                        input: admin
                    } as UpdateSchoolAdminMutationVariables
                )) as GraphQLResult<UpdateSchoolAdminMutation>

                processedAdminCount.current++
                setProcessingMessage(`Processed administrator ${processedAdminCount.current}/${totalAdmin}`)
            } catch (e) {
                console.log('error updating school admin')
                console.log(admin)
                console.log(e)
            }
        }))

        await Promise.all(classToCreate.current.map(async clazz => {
            try {
                await API.graphql(graphqlOperation(
                    createClass,
                    {
                        input: clazz
                    } as CreateClassMutationVariables
                )) as GraphQLResult<CreateClassMutation>

                processedClassCount.current++
                setProcessingMessage(`Processed class ${processedClassCount.current}/${classToCreate.current.length}`)
            } catch (e) {
                console.log('error creating class')
                console.log(clazz)
                console.log(e)
            }
        }))

        existingClasses.current = await listClassTeachersAndStudentsBySchoolID(schoolID!)

        const totalTeacher = teacherToCreate.current.length + teacherToUpdate.current.length

        await promiseQueue(teacherToCreate.current, async teacher => {
            try {
                let input = { ...teacher }
                delete input.classes

                const response = await API.post(
                    'AdminQueries',
                    '/createTeacher',
                    {
                        headers: await getApiHeaders(),
                        body: {
                            ...input,
                            messageAction: CognitoMessageAction.SUPPRESS,
                            createTeacherRecord: true,
                        },
                    }
                ) as GraphQLResult<CreateTeacherMutation>

                if (response.errors) {
                    throw errors
                } else if ((response as any).message) {
                    throw (response as any).message
                }

                const teacherID = response.data?.createTeacher?.id!
                await assignTeacherToClasses({ teacher, teacherID: teacherID, existingClasses, })

                processedTeacherCount.current++
                setProcessingMessage(`Processed teacher ${processedTeacherCount.current}/${totalTeacher}`)
            } catch (e) {
                console.log('error creating teacher')
                console.log(teacher)
                console.log(e)
            }
        })

        await Promise.all(teacherToUpdate.current.map(async teacher => {
            try {
                let input = { ...teacher }
                delete input.classes

                await API.graphql(graphqlOperation(
                    updateTeacher,
                    {
                        input
                    } as UpdateTeacherMutationVariables
                )) as GraphQLResult<UpdateTeacherMutation>

                await assignTeacherToClasses({ teacher, teacherID: teacher.id, existingClasses, })

                processedTeacherCount.current++
                setProcessingMessage(`Processed teacher ${processedTeacherCount.current}/${totalTeacher}`)
            } catch (e) {
                console.log('error updating teacher')
                console.log(teacher)
                console.log(e)
            }
        }))

        await Promise.all(otherTeachers.current.map(async teacher => {
            setProcessingMessage('Assigning teachers to classes')
            await assignTeacherToClasses({ teacher, teacherID: teacher.id, existingClasses, })
        }))

        const totalStudent = studentToCreate.current.length + studentToUpdate.current.length
        await promiseQueue(studentToCreate.current, async student => {
            let nickname: string = ''
            if (student.email && !(await usernameTaken(student.email))) {
                nickname = student.email
            } else {
                nickname = `${sanitiseNameForCognitoUsername(student.firstName || '')}.${sanitiseNameForCognitoUsername(student.lastName || '')}`.toLowerCase()
                let x = 1
                while (await usernameTaken(nickname + x.toString())) x++
                nickname = nickname + x.toString()
            }

            try {
                // create student also create database record
                const { data } = await customCreateStudentMutation({
                    variables: {
                        input: {
                            avatar: student.avatar,
                            nickname,
                            firstName: student.firstName,
                            lastName: student.lastName,
                            schoolID: schoolID || "",
                            status: StudentStatus.ACTIVE,
                            cognitoUsername: nickname,
                            email: student.email,
                            schoolStudentID: student.schoolStudentID,
                            yearLevel: student.yearLevel,
                            messageAction: CognitoMessageAction.SUPPRESS,
                            initialLoginSetup: true
                        }
                    }
                })

                const studentID = data?.customCreateStudent?.id

                if (!studentID) {
                    throw new Error("Student not found");
                }

                await assignStudentToClasses({ student, studentID, existingClasses, })

                processedStudentCount.current++
                setProcessingMessage(`Processed student ${processedStudentCount.current}/${totalStudent}`)
            } catch (e) {
                console.log('error creating student')
                console.log(student)
                console.log(e)
            }
        })

        await Promise.all(studentToUpdate.current.map(async student => {
            try {
                let input = { ...student }
                delete input.classes

                await API.graphql(graphqlOperation(
                    updateStudent,
                    {
                        input
                    } as UpdateStudentMutationVariables
                )) as GraphQLResult<UpdateStudentMutation>

                await assignStudentToClasses({ student, studentID: student.id, existingClasses, })

                processedStudentCount.current++
                setProcessingMessage(`Processed student ${processedStudentCount.current}/${totalStudent}`)
            } catch (e) {
                console.log('error updating student')
                console.log(student)
                console.log(e)
            }
        }))

        await Promise.all(otherStudents.current.map(async student => {
            setProcessingMessage('Assigning students to classes')
            await assignStudentToClasses({ student, studentID: student.id, existingClasses, })
        }))

        setProcessingMessage('done.')
        setProcessingStatus(ProcessingStatus.Processed)
    }

    return (
        <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContaier}>
            <View style={styles.container}>
                <BackButton displayName='Back' />
                <View style={styles.buttonContainer}>
                    <DefaultText>This can potentially take a while, only run this when you have stable internet connection.</DefaultText>
                    <DefaultButton onPress={handleImportExcel} disabled={processingStatus == ProcessingStatus.Processing}>
                        Select Excel File
                    </DefaultButton>
                </View>
                {
                    fileLoaded && (
                        <>
                            {errors.current.length > 0 && <Messages title='Please fix the following error(s):' messages={errors} styles={styles.error} />}
                            {warnings.current.length > 0 && <Messages title='Warning(s):' messages={warnings} styles={styles.warning} />}
                            {info.current.length > 0 && <Messages title='Info:' messages={info} styles={styles.info} />}
                            {
                                errors.current.length === 0 && (
                                    <View style={styles.buttonContainer}>
                                        <Br />
                                        <DefaultButton onPress={createAccountsAndClasses} disabled={processingStatus === ProcessingStatus.Processing || processingStatus === ProcessingStatus.Processed}>
                                            Proces Excel File
                                        </DefaultButton>
                                    </View>
                                )
                            }
                        </>
                    )
                }
                {
                    processingMessage.length > 0 && (
                        <>
                            <Br />
                            <DefaultText>{processingMessage}</DefaultText>
                        </>
                    )
                }
            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    scrollView: {
        flex: 1,
        width: '100%',
        height: '100%'
    },
    scrollViewContaier: {
        height: '100%'
    },
    container: {
        flex: 1,
        width: '100%',
        padding: 20,
    },
    buttonContainer: {
        // flex: 1,
        alignItems: 'center'
    },
    error: {
        color: DecidaColors.Red,
    },
    warning: {
        color: DecidaColors.Orange,
    },
    info: {
        color: DecidaColors.Blue,
    }
})
