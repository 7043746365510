import { StyleProp, StyleSheet, TextStyle } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from './default-text'

interface Props {
    children: string
    value?: string
    style?: StyleProp<TextStyle>
    onPress?: (value?: string) => void
}

export const Link = ({ children, onPress, value, style }: Props) => {
    const callOnPress = () => {
        onPress && onPress(value)
    }
    return (
        <DefaultText style={[styles.link, style]} onPress={callOnPress}>{children}</DefaultText>
    )
}

const styles = StyleSheet.create({
    link: {
        color: DecidaColors.Sad,
        textAlign: 'center',
    } as TextStyle
})