import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { TwoTruthsOneLieIcon } from '../../icons/2-truths-1-lie-icon'
import { TwoTruthsOneLieIconInverted } from '../../icons/2-truths-1-lie-icon-inverted'


export const SwitchesActivitiesCreativityTwoTruthsOneLie: CardTemplate = {
    cardId: "fcc1456e-cfd4-40b0-a892-4e62ab8c0b93",
    title: '2 truths 1 lie',
    Header: TwoTruthsOneLieIconInverted,
    Icon: TwoTruthsOneLieIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Getting to know new things about others and thinking outside the box"} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Each participant requires some paper and a pencil"} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Gather a group of two or more people (works with large groups as well)  "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Take some time at the beginning of the game to each write down two true facts about yourself – these can be anything as long as they are relevant to you."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Now each person writes one lie about themselves. Making sure it is creative and believable "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Each person takes a turn reading out their two truths and one lie in a random order and the rest of the group has to figure out which point is the lie."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Aim:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The aim is to trick the other participants into believing your lie"} />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.CREATIVITY,
    whyDoesItWorkText: '',
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.CREATIVITY],
    action: [CardActionType.Action],
    level: []
}

