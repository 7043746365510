import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { YoshiBushiIcon } from '../../icons/yoshi-bushi-icon'
import { YoshiBushiIconInverted } from '../../icons/yoshi-bushi-icon-inverted'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-double-indentation'


export const SwitchesActivitiesEnergizersYoshiBushi: CardTemplate = {
    cardId: "157a5364-6fd2-443c-b24e-73a21210f015",
    title: 'Ooshi bushi',
    Header: YoshiBushiIconInverted,
    Icon: YoshiBushiIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"When you need a boost of confidence in the form of energy."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Space in the classroom for a group of people to stand in a circle"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Rules:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"One person starts the ‘call’ and they have three options either to say, ‘Ooshi Bushi’, ‘Ya’ or ‘Fling’. Each option performs an action."} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Ooshi Bushi' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='• This means that the person is passing the call along to the next person (if you use this action while starting you can choose to send the call either left or right, signified by their hand actions)' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Ya' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='• Blocks the call and reverses it back the way it came' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Fling' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='• “Flings” the call to a completely different part of the circle (cannot be either of the people next to you, signified by hand actions)' />

            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Each action has hand actions to go with it "} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Ooshi Bushi' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='• Both arms by your side bent at the elbows pointing at the person you’re sending the call to ' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Ya' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='• Arms crossed (with each fist at the opposite shoulder) towards the person who sent the call to you  ' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Fling' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='• Both arms pointing towards the person you are flinging to  ' />

            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"As each round goes on every call should be louder than the last"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Elimination :"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Once a person is eliminated the round stops, the person who was eliminated sits down and the game keeps on going till there are two people left and they can either be combined winners or there is a tie breaker game (perhaps rock, paper, scissors)"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"These are things that anyone can be eliminated for... "} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"You cannot ‘Ya’ a ‘ya’"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"You cannot ‘Ya’ a ‘fling’"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"If someone takes too long to decide their action (you have to be quick)"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"If someone is too quiet when saying their action (you have to be loud if the person previous to you was loud)"} />
        
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Get a group of people together (between 7 and 30 usually works best)  "} />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.ENERGIZERS,
    whyDoesItWorkText: '',
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.ENERGIZERS],
    action: [CardActionType.Action],
    level: []
}

