import { StyleSheet, View } from 'react-native'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { DrinkOfWaterHeaderIcon } from './src/drink-of-water-header-icon'
import { DrinkOfWaterIcon } from './src/drink-of-water-icon'
import { DrinkOfWaterImage1 } from './src/drink-of-water-image1'
import { DrinkOfWaterImage2 } from './src/drink-of-water-image2'
import { DrinkOfWaterImage3 } from './src/drink-of-water-image3'

export const SwitchesEmotionsAnxiousDrinkOfWater: CardTemplate = {
    cardId: "6f9b475a-89c5-4610-ac16-e168324faf8c",
    title: 'Drink of water',
    Header: DrinkOfWaterHeaderIcon,
    Icon: DrinkOfWaterIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Creating a little break to help our bodies calm down and feel better."} />

            <DefaultTextWithoutArrows textdata={"Drinking water can give our bodies a little break and help us feel refreshed. It's like taking a deep breath for our insides! It cools us down and helps us think more clearly."} />
            <View style={styles.imageContainer}>
                <DrinkOfWaterImage1 />
            </View>

            <DefaultTextWithoutArrows textdata={"Water also helps our brains work better. When we're feeling really emotional, our thoughts might get all jumbled up. But drinking water can make our brains feel clearer and help us think more calmly."} />
            <View style={styles.imageContainer}>
                <DrinkOfWaterImage2 />
            </View>

            <DefaultTextWithoutArrows textdata={"So, the next time you're feeling upset or overwhelmed, try having a sip of water. "} />
            <View style={styles.imageContainer}>
                <DrinkOfWaterImage3 />
            </View>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    cardContentTemplate: 'column',
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: "Having a drink of water can help regulate emotions because it serves as a sensory and physiological intervention. When we are experiencing strong emotions, our bodies can become activated and tense. Drinking water provides a cooling and refreshing sensation, which can help reduce physiological arousal and promote a sense of calm. Additionally, the act of sipping water can serve as a mindful and grounding activity, redirecting attention away from overwhelming emotions and allowing for a moment of self soothing. Also, proper hydration supports optimal brain function, enhancing cognitive abilities and promoting clearer thinking, which can contribute to a more balanced emotional state.",
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Describe the connection between having a drink of water and regulating their emotions, and develop strategies to utilise water as a tool for emotional regulation. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 20 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (2 minutes):  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the topic by saying that today they will explore how something as simple as having a drink of water can help them manage their emotions. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• The Importance of Hydration (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of hydration and explain that our bodies need water to stay healthy and function properly.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the connection between our bodies and emotions, explaining that when we are feeling strong emotions, our bodies can become "thirsty" for water.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• How Water Helps with Emotions (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that when we have a drink of water, it helps our bodies feel refreshed and balanced. It can cool us down when we're feeling upset or calm us down when we're feeling excited. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how water helps us think more clearly and makes our bodies feel better overall. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Practical Application (5 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute cups or water bottles to each student. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the students to take a drink of water, emphasising that they should pay attention to how it feels in their bodies and how it affects their emotions. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allow a moment for students to experience the refreshing feeling of drinking water. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Closure (3 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students back into a circle and ask them to share their experiences after having a drink of water. Prompt them with questions like, "How did the water make you feel?" or "Did you notice any changes in your emotions?" </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the lesson by emphasising that having a drink of water is a simple and powerful way to regulate our emotions and take care of our bodies. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to remember the importance of staying hydrated and using water as a tool to help manage their emotions. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Cups or water bottles for each student</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Effects of Changes in Water Intake on Mood of High and Low Drinkers -</DefaultText>
                        <DefaultTextWithLink text='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3984246/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Water, Depression, and Anxiety -</DefaultText>
                        <DefaultTextWithLink text='https://solaramentalhealth.com/can-drinking-enough-water-help-my-depression-and-anxiety/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Water really can provide some relief from anxiety and help us see the glass half full -</DefaultText>
                        <DefaultTextWithLink text='https://impact.deakin.edu.au/2022/03/water-really-can-provide-some-relief-from-anxiety-and-help-us-see-the-glass-half-full/' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Drink+of+water+voice+over.m4a",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Anxious]
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
})
