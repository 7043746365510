import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { Class, GetListTeacherBySchoolIDQuery, GetListTeacherBySchoolIDQueryVariables, GetSchoolAdminByCognitoUsernameQuery, GetSchoolAdminByCognitoUsernameQueryVariables, ListClassesBySchoolIDQuery, ListClassesBySchoolIDQueryVariables, Teacher } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultButton, DefaultButtonTypes } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { Loading, rvIsLoading } from '../common/loading'
import { showAlert } from '../common/universal-alert'
import { useResponsive } from '../common/use-responsive'
import { GetSchoolCampusesAndGroupsQueryResponse } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query'
import { deleteClass, deleteTeacherClass, updateClass } from '../../common/graphql/mutations'
import { getListTeacherBySchoolID, getSchool, getSchoolAdminByCognitoUsername } from '../../common/graphql/queries'
import { rvCurrentUser } from '../login/login-state'
import DefaultTextWithCustomLink from '../switches/common/default-text-with-custom-link'
import { SchoolAdminAddNewClassAndTeacher } from './school-admin-add-new-class-and-teacher'
import { SchoolAdminClassRow } from './school-admin-class-row'
import { SCHOOL_ADMIN_ADD_CLASS, SCHOOL_ADMIN_ADD_GO_CLASS, SchoolAdminTableEditComponent } from './school-admin-const'
import { listClassesBySchoolIDCustomQuery } from './school-admin-graphql-scripts'
import { isDefined } from '../../common/is-defined'
import { getSchoolQuery } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-query'

const SchoolAdminTable = () => {
    const user = useReactiveVar(rvCurrentUser)
    const [editComponent, setEditComponent] = useState<SchoolAdminTableEditComponent>(null)
    const { isNarrowScreen } = useResponsive()

    const { loading: schoolAdminLoading, data: resultSchoolAdmin } = useQuery<GetSchoolAdminByCognitoUsernameQuery, GetSchoolAdminByCognitoUsernameQueryVariables>(gql`${getSchoolAdminByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "", limit: 5000 }, skip: !user })
    const currentSchoolAdmin = resultSchoolAdmin?.getSchoolAdminByCognitoUsername?.items[0]

    const { loading: schoolLoading, data: schoolData, refetch } = useQuery<GetSchoolCampusesAndGroupsQueryResponse>(getSchoolQuery, { variables: { id: currentSchoolAdmin?.schoolID } })
    const { data: classesResponseData } = useQuery<ListClassesBySchoolIDQuery, ListClassesBySchoolIDQueryVariables>(gql`${listClassesBySchoolIDCustomQuery}`, { variables: { schoolID: currentSchoolAdmin?.schoolID || "", limit: 5000 }, skip: !currentSchoolAdmin })
    const { data: teacherResponseData } = useQuery<GetListTeacherBySchoolIDQuery, GetListTeacherBySchoolIDQueryVariables>(gql`${getListTeacherBySchoolID}`, { variables: { schoolID: currentSchoolAdmin?.schoolID || "", limit: 5000 }, skip: !currentSchoolAdmin })
    
    const [, { loading: loadingOnRemoveTeacherClass }] = useMutation(gql`${deleteTeacherClass}`);
    const [, { loading: loadingOnRemoveClass }] = useMutation(gql`${deleteClass}`);
    const [, { loading: loadingUpdateClass }] = useMutation(gql`${updateClass}`);

    const schoolClasses = classesResponseData?.listClassesBySchoolID?.items.filter(isDefined) as Class[]
    const teachers = teacherResponseData?.getListTeacherBySchoolID?.items.filter(isDefined) as Teacher[]

    const isLoading = loadingOnRemoveTeacherClass || loadingOnRemoveClass || loadingUpdateClass

    const sortedClass = useMemo(
        () => _.sortBy(schoolClasses?.filter(data => !data._deleted && data.archived !== true), ['goMode', i => i.name.toLowerCase()]),
        [schoolClasses, teacherResponseData]
    )

    useEffect(() => {
        rvIsLoading(isLoading)
    }, [isLoading])

    const refetcNewhData = async () => {
        await refetch()
    }

    if (schoolLoading || schoolAdminLoading) {
        return (
            <Loading />
        )
    }

    const toggleAddClass = () => {
        setEditComponent(prev => prev?.class !== SCHOOL_ADMIN_ADD_CLASS ? ({ class: SCHOOL_ADMIN_ADD_CLASS }) : null)
    }

    const toggleAddGoGroup = () => {
        setEditComponent(prev => prev?.class !== SCHOOL_ADMIN_ADD_GO_CLASS ? ({ class: SCHOOL_ADMIN_ADD_GO_CLASS }) : null)
    }

    const handleBulkUpload = () => {
        showAlert({
            leftButtonText: "Ok",
            title: "",
            message: <DefaultText style={styles.messageText}>Download <DefaultTextWithCustomLink style={styles.linkText} text='this template guide' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/S4S+Class+Set+Up+Information+Template+v3.2.xlsx' /> to collate the required information to create accounts for administrators, teachers and/or students. Then send the details through to the Switch team at <DefaultTextWithCustomLink style={styles.linkText} text='support@switch4schools.com.au' link='mailto: support@switch4schools.com.au' /> and we will let you know once it has been processed.</DefaultText>
        })
    }

    const TableViewWrapper = isNarrowScreen ? ScrollView : View

    return (
        <ScrollView style={{ flex: 1, width: '100%' }} contentContainerStyle={[styles.scrollViewContainer, { paddingHorizontal: isNarrowScreen ? 20 : 40 }]} keyboardShouldPersistTaps='handled'>
            <View style={styles.buttonContainer}>
                <DefaultButton type={DefaultButtonTypes.Small} style={styles.topButtons} onPress={handleBulkUpload}>
                    Bulk upload
                </DefaultButton>
                <DefaultButton
                    type={DefaultButtonTypes.Small}
                    plusIcon
                    onPress={toggleAddClass}
                    style={styles.topButtons}
                >
                    Add new class
                </DefaultButton>
                <DefaultButton
                    type={DefaultButtonTypes.Small}
                    plusIcon
                    onPress={toggleAddGoGroup}
                    style={styles.topButtons}
                >
                    Add new GO group
                </DefaultButton>
            </View>

            <TableViewWrapper style={styles.tableScrollView} horizontal contentContainerStyle={isNarrowScreen ? styles.tableScrollViewContainer : undefined}>
                <View style={styles.tableHeader}>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        {/* header table row */}
                        <View style={{ flex: 6, flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <DefaultText style={styles.headerText}>Class Name</DefaultText>
                            <DefaultText style={[styles.headerText, { flex: 4 }]}>Teacher/s details</DefaultText>
                        </View>
                        <View style={{ flex: 2, flexDirection: 'row', marginLeft: 40 }}>
                            <DefaultText style={styles.headerText}>Class settings</DefaultText>
                        </View>
                        <DefaultText style={styles.headerText}>{""}</DefaultText>
                    </View>

                    {editComponent?.class === SCHOOL_ADMIN_ADD_CLASS && <SchoolAdminAddNewClassAndTeacher setEditComponent={setEditComponent} schoolID={currentSchoolAdmin?.schoolID || ""} />}
                    {editComponent?.class === SCHOOL_ADMIN_ADD_GO_CLASS && <SchoolAdminAddNewClassAndTeacher goMode setEditComponent={setEditComponent} schoolID={currentSchoolAdmin?.schoolID || ""} />}
                </View>

                <View style={{ paddingHorizontal: isNarrowScreen ? 0 : 10 }}>
                    {sortedClass.map((clazz, index) =>
                        <View key={clazz.id} style={{ zIndex: -index }}>
                            <SchoolAdminClassRow
                                key={clazz.id}
                                clazz={clazz}
                                teachers={teachers}
                                editComponent={editComponent}
                                setEditComponent={setEditComponent}
                            />
                        </View>
                    )}
                </View>
            </TableViewWrapper>

        </ScrollView>
    )
}

export default SchoolAdminTable

const styles = StyleSheet.create({
    scrollViewContainer: {
        overflow: 'visible',
        paddingTop: 50,
    },
    headerText: {
        flex: 1,
        fontSize: 16,
        color: DecidaColors.AppleSystemGray2Dark,
        fontWeight: '700',
        textAlign: 'left',
        alignSelf: 'flex-start',
        width: '100%',
        marginRight: 10
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        paddingLeft: 10,
        width: '100%'
    },
    topButtons: {
        minWidth: 200,
        maxWidth: 250,
        marginRight: 10
    },
    messageText: {
        textAlign: 'center'
    },
    linkText: {
        paddingLeft: 0
    },
    tableHeader: {
        padding: 10,
        backgroundColor: DecidaColors.White,
        zIndex: 99
    },
    tableScrollView: {
        flexGrow: 0
    },
    tableScrollViewContainer: {
        flexDirection: 'column'
    }
})