import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { PalmPushIcon } from '../../explore/src/palm-push-icon'
import { PalmPushIconInverted } from '../../explore/src/palm-push-icon-inverted'
import { PalmPushImage1 } from './src/palm-push-image1'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import SwitchCardVideo from '../../../common/switch-card-video'

export const SwitchesEmotionsAngryPalmPush: CardTemplate = {
    cardId: "a83190c1-6bf8-4fd5-8938-8707e7efce2e",
    title: 'Palm push',
    Header: PalmPushIconInverted,
    Icon: PalmPushIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Releasing pent up negative energy to help with calming down. "} />
        </>
    ),
    Images: () => (
        <>
            <PalmPushImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Push the palms of your hands together for 10 seconds as hard as you can (without hurting yourself of course)."} />
                <DefaultTextWithDownArrow textdata={"Relax your hands and arms and wait for five seconds (say in your head “one thousand, two thousand, three thousand, four thousand, five thousand)."} />
                <DefaultTextWithDownArrow textdata={"Repeat this process as many times as you need and notice when your anger starts to subside."} />
                <DefaultTextWithoutArrows textdata={"Now, focus on what you need to do."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: 'Palm push reduces the chances of angry, reactive and impulsive behaviours (like the impulse to break things). By pushing the hands together it helps release energy, calming their mind and allowing them to engage their prefrontal cortex to self-regulate and make better choices.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Use sensory play to experience releasing pent up negative energy in a controlled environment.  </DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 20 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (2 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain to students that when feeling stressed or angry, the body naturally tightens up, and having a physical way to release energy can help relax in the moment. It serves as a reminder for the body to unclench.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of the Palm Push Switch and explain that it is a simple physical technique to release stress and tension.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Practicing Palm Push Switch (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Palm Push Switch in the Switch4Schools app and demonstrate the steps.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to practice the Palm Push Switch, following the steps provided in the app.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set a timer for a brief practice session.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Debrief and Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief with the students by asking how the Palm Push Switch made them feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to share their experiences and discuss whether it helped in releasing stress or tension.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Prompt students to think about other things they could push or do (e.g., push a wall, use a stress ball) to achieve a similar outcome.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Optional Additional Activity: Creating Stress Balls (25 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Insert the funnel into the mouthpiece of the balloon.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Fill the balloon with cornflour, flour, sand, or rice to the desired size.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Squeese out the excess air and tie the balloon closed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Decorate the stress ball with markers.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (3 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points about using physical techniques like the Palm Push Switch to release stress.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the optional stress ball activity and encourage students to use their stress balls when needed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, ask them to find the Palm Push Switch in the Switch4Schools app for additional practice.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanText}>•
                            OPTIONAL ACITIVITY RESOURCES:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>Balloons</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>A funnel (the top of a plastic bottle will work)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>Pen</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>Cornflour/flour/sand/rice</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>Water</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>Permanent markers</DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanText}>Relieve Stress, Play With Toys -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.stress.org/relieve-stress-play-with-toys' link='https://www.stress.org/relieve-stress-play-with-toys' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to make a stress ball -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.youtube.com/watch?v=gHOp22_Sjqg' link='https://www.youtube.com/watch?v=gHOp22_Sjqg' />

                        <SwitchCardVideo video={{
                            id: '1',
                            url: 'https://www.youtube.com/embed/gHOp22_Sjqg'
                        }} />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Palm+Push.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Angry]
}
