import { gql, useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { ListStudentsQuery, Student, UpdateStudentMutation } from '../../common/API'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { updateStudent } from '../../common/graphql/mutations'
import { listStudents } from '../../common/graphql/queries'

const AdminOnboardingStudentScript = () => {
    const [nextToken, setNextToken] = useState('')
    const [students, setStudents] = useState<(Student | null)[]>([])

    const { data } = useQuery<ListStudentsQuery>(gql`${listStudents}`, { variables: { limit: 5000, nextToken: nextToken ? nextToken : undefined } })
    const [updateStudentMutation] = useMutation<UpdateStudentMutation>(gql`${updateStudent}`)

    useEffect(() => {
        const studentResponse = data?.listStudents?.items as Student[]

        if (studentResponse) {
            setStudents(studentResponse)
        }

    }, [data])

    const handleNext = () => {
        const currentNextToken = data?.listStudents?.nextToken
        if (currentNextToken) {
            setNextToken(currentNextToken)
        }
    }


    const handleProcess = async () => {
        try {
            await Promise.all(students.map(async (student) => {
                await updateStudentMutation({
                    variables: {
                        input: {
                            id: student?.id,
                            onboarded: true,
                            _version: student?._version
                        }
                    }

                })
            }))
        } catch (error) {
            console.log("Onboarding process failed")
            console.log({ error })
        }

    }

    return (
        <View style={styles.container}>
            <DefaultText>Onboarding {students.length} students</DefaultText>
            {/* <View>
                {students.map((student) => {
                    return (
                        <DefaultText key={student?.id}>{student?.nickname}</DefaultText>
                    )
                })}
            </View> */}

            <DefaultButton onPress={handleNext}>Next page</DefaultButton>
            <DefaultButton onPress={handleProcess}>Start onboarding process</DefaultButton>
        </View>
    )
}
export default AdminOnboardingStudentScript
const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        alignItems: 'center'
    }
})