import { StyleSheet, TextStyle, View } from 'react-native'

import { useMemo } from 'react'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { useResponsive } from '../../common/use-responsive'
import ViewResponsive from '../../common/view-responsive'
import { cards } from '../emotion-cards/cards'

const CardHeaderTitle = ({ cardId }: { cardId: string }) => {
  const Card = useMemo(() =>
    cards.find(card => card.cardId === cardId)
    , [cardId])

  const { isLargeScreen } = useResponsive()
  if (Card?.onlyLessonPlan && Card.title) {
    return (
      <View style={styles.absoluteCenter}>
        <DefaultText style={[styles.headings, { color: DecidaColors.GrayText }]}>{Card.title}</DefaultText>
      </View>
    )
  }

  return (
    <ViewResponsive style={styles.headerTitleContainer}>
      <View style={styles.imageheader}>
        <View style={styles.headersvgAndTitle}>
          <View style={styles.headersvg}>
            {Card?.Header && <Card.Header />}
          </View>
          {!Card?.HeaderTitle && Card?.title && <DefaultText style={[styles.headings, { fontSize: isLargeScreen ? 30 : 20 }]}>{Card?.title}</DefaultText>}
        </View>
        {Card?.HeaderTitle && <DefaultText style={[styles.headings, { fontSize: isLargeScreen ? 30 : 20 }]}>{Card?.HeaderTitle}</DefaultText>}
      </View>
    </ViewResponsive>
  )

}

export default CardHeaderTitle
const styles = StyleSheet.create({
  headerTitleContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10
  },
  imageheader: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  headersvgAndTitle: {
    minWidth: 100,
    maxWidth: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  headersvg: {
    width: 60,
    height: 60,
    resizeMode: 'cover',
  },
  headings: {
    fontWeight: '400',
    color: DecidaColors.GrayText,
    fontStyle: 'italic'
  } as TextStyle,
  absoluteCenter: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 0,
    zIndex: -1,
  },
})