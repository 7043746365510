import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import SwitchCardVideo from '../../../common/switch-card-video'
import { Worksheets } from '../../../common/worksheets-imports'
import { Videos } from '../../../models'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { TalkAboutItIcon } from '../../explore/src/talk-about-it-icon'
import { TalkAboutItIconInverted } from '../../explore/src/talk-about-it-icon-inverted'
import { TalkAboutItImagine1 } from './src/talk-about-it-image1'
import { TalkAboutItLessonPlanImage1 } from './src/talk-about-it-lessonplan-image1'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'

const talkAboutItVideo1: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/_-E-QDkbdE8",
    title: ""
}

const talkAboutItVideo2: Videos = {
    id: "2",
    url: "https://www.youtube.com/embed/7CBmKdip6ws",
    title: ""
}

export const SwitchesEmotionsScaredTalkAboutIt: CardTemplate = {
    cardId: "06e1ea44-fa85-43f9-9310-649008482183",
    title: 'Talk about it',
    Header: TalkAboutItIconInverted,
    Icon: TalkAboutItIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <TalkAboutItImagine1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Externalising fears that often become bigger when kept secret."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Find someone you trust and agree to share something that scares each of you."} />
                <DefaultTextWithDownArrow textdata={"When it is your turn to share, include what scares you, and if you’d like not to be scared by it."} />
                <DefaultTextWithDownArrow textdata={"When it’s your turn to listen, make sure you don’t make fun of the other person’s fears. Ask curious questions and show you are interested."} />
                <DefaultTextWithDownArrow textdata={"Notice how differently you feel after sharing your burden with someone else."} />
                <DefaultTextWithoutArrows textdata={"After the activity, never tell others what someone else’s fears are - that is their story to share only."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: 'Research has shown that it takes significant mental energy to suppress or ‘mask’ emotions, limiting your cognitive capacity by up to 30%. Talking about it not only frees cognitive energy for task completion, it also reduces the fear and anxiety attached to worry about what others might think. A shared problem is a problem halved.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Deepen understanding of the neuroscience behind fear.

                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice talking about fears with a peer.
                        </DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by discussing the importance of open communication and creating a safe space for sharing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that fear is a natural alert system and not something to be demonised.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the Talk About It Switch and its purpose: finding a trusted person to share fears with, listening without judgment, and creating a supportive environment.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Understanding Fears (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If not completed previously, distribute the My Fears worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to list and briefly describe a fear they have and whether they'd like not to be scared by it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage creativity and honesty in their responses.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Pairing Up for Talk About It Switch (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Pair students with a partner.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct them to take turns sharing their fears using the Talk About It Switch guidelines.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students to listen actively, ask curious questions, and show genuine interest.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- After sharing, have students reflect on how they feel differently after unburdening themselves.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Whole Class Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Bring the class back together and open the floor for voluntary sharing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to reflect on the experience of sharing fears and listening to their partner.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of maintaining confidentiality and creating a supportive environment.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the idea that talking about fears can be empowering and help build connections.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of respecting others' stories and maintaining a safe space.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage ongoing open communication and the use of the Talk About It Switch.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='My Fears worksheet' link={Worksheets.myFears.uri} /> </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Talk about your fears to overcome them, says a new study -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://theworld.org/stories/2012-09-05/talk-about-your-fears-overcome-them-says-new-study' link='https://theworld.org/stories/2012-09-05/talk-about-your-fears-overcome-them-says-new-study' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Phobias and Irrational Fears -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.helpguide.org/articles/anxiety/phobias-and-irrational-fears.htm' link='https://www.helpguide.org/articles/anxiety/phobias-and-irrational-fears.htm' />

                        <DefaultText style={CommonStyles.lessonPlanText}>What is Fear and Why You Feel Scared -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.youtube.com/watch?v=7CBmKdip6ws' link='https://www.youtube.com/watch?v=7CBmKdip6ws' />
                        <SwitchCardVideo video={talkAboutItVideo2} />

                        <DefaultText style={CommonStyles.lessonPlanText}>The Science of Being Scared -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.youtube.com/watch?v=_-E-QDkbdE8' link='https://www.youtube.com/watch?v=_-E-QDkbdE8' />
                        <SwitchCardVideo video={talkAboutItVideo1} />

                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Create a safe environment for others to share their fears and concerns.' />
            <DefaulTextWithTipsIcon textdata='Do not demonise fear, it can be a natural alert system to keep us safe. ' />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Talk+about+it.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Scared]
}

