import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import IconSwitch4SchoolLogo2023 from '../icon/icon-switch-4-school-logo-2023'

type Props = {
    onPress: () => void
    disabled?: boolean
}
const LeftHeaderLogo = ({ onPress, disabled = false }: Props) => {
    return (
        <View style={styles.container}>
            <TouchableOpacity disabled={disabled} onPress={onPress}>
                <IconSwitch4SchoolLogo2023 percent={0.6} />
            </TouchableOpacity>
        </View>
    )
}
export default LeftHeaderLogo
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'flex-end'
    }
})