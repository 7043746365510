export namespace ScreenNames {
    export const AdminCampus = 'AdminCampus'
    export const AdminClass = 'AdminClass'
    export const AdminClassDetails = 'AdminClassDetails'
    export const AdminClassMain = 'AdminClassMain'
    export const AdminConfigMFA = 'AdminConfigMFA'
    export const AdminManageClass = 'AdminManageClass'
    export const AdminNonTeachingStaff = 'AdminNonTeachingStaff'
    export const AdminResetPassword = 'AdminResetPassword'
    export const AdminSchool = 'AdminSchool'
    export const AdminSchoolAdmin = 'AdminSchoolAdmin'
    export const AdminSchoolImportTeachers = 'AdminSchoolImportTeachers'
    export const AdminSchoolImports = 'AdminSchoolImports'
    export const AdminSchoolStudentList = 'AdminSchoolStudentList'
    export const AdminSchools = 'AdminSchools'
    export const AdminSchoolsMain = 'AdminSchoolsMain'
    export const AdminSearchStudent = 'AdminSearchStudent'
    export const AdminSearchTeacher = 'AdminSearchTeacher'
    export const AdminStudentOnboarding = 'AdminStudentOnboarding'
    export const AdminSwitchCardsCsv = 'AdminSwitchCardsCsv'
    export const AdminTeacher = 'AdminTeacher'
    export const AdminTeacherOnboarding = 'AdminTeacherOnboarding'
    export const AdminViewClassStats = 'AdminViewClassStats'
    export const AdminWelcome = 'AdminWelcome'
    export const Card = 'Card'
    export const ChangePasswordProfile = 'ChangePasswordProfile'
    export const CheckInCardSuggestion = 'CheckInCardSuggestion'
    export const CheckInEmotionalIntensity = 'CheckInEmotionalIntensity'
    export const CheckInEmotionalWheel = 'CheckInEmotionalWheel'
    export const CheckInEating = 'CheckInEating'
    export const CheckInFinish = 'CheckInFinish'
    export const CheckInRequestChatWithTeacher = 'CheckInRequestChatWithTeacher'
    export const CheckInStart = 'CheckInStart'
    export const CheckInTirednessScale = 'CheckInTirednessScale'
    export const CheckInWellbeingQuestion = 'CheckInWellbeingQuestion'
    export const ClassLoginHome = 'ClassLoginHome'
    export const UserEditAvatar = 'UserEditAvatar'
    export const JoinClass = 'JoinClass'
    export const JoinClassExisting = 'JoinClassExisting'
    export const Login = 'Login'
    export const LoginChangePassword = 'LoginChangePassword'
    export const LoginClassLogin = 'LoginClassLogin'
    export const LoginForgotPasswordRequest = 'LoginForgotPasswordRequest'
    export const LoginForgotPasswordSubmit = 'LoginForgotPasswordSubmit'
    export const LoginMFASMS = 'LoginMFASMS'
    export const LoginStudentEditAvatar = 'LoginStudentEditAvatar'
    export const LoginStudentProfilePreview = 'LoginStudentProfilePreview'
    export const MigrationTools = 'MigrationTools'
    export const NonTeachingStaffCheckInMain = 'NonTeachingStaffCheckInMain'
    export const NonTeachingStaffEditAvatar = 'NonTeachingStaffEditAvatar'
    export const NonTeachingStaffHome = 'NonTeachingStaffHome'
    export const NonTeachingStaffPickAvatar = 'NonTeachingStaffPickAvatar'
    export const NonTeachingStaffProfile = 'NonTeachingStaffProfile'
    export const NonTeachingStaffProfileMain = 'NonTeachingStaffProfileMain'
    export const NonTeachingStaffWellbeing = 'NonTeachingStaffWellbeing'
    export const NotFound = 'NotFound'
    export const ParentHome = 'ParentHome'
    export const ParentMain = 'ParentMain'
    export const ParentSettings = 'ParentSettings'
    export const Placeholder = 'Placeholder'
    export const Profile = 'Profile'
    export const SchoolAdminAddStudent = 'SchoolAdminAddStudent'
    export const SchoolAdminBillingDetails = 'SchoolAdminBillingDetails'
    export const SchoolAdminCampussesHome = 'SchoolAdminCampussesHome'
    export const SchoolAdminClassMain = 'SchoolAdminClassMain'
    export const SchoolAdminCreateStudentNickname = 'SchoolAdminCreateStudentNickname'
    export const SchoolAdminCreateStudentPickAvatar = 'SchoolAdminCreateStudentPickAvatar'
    export const SchoolAdminEditStudent = 'SchoolAdminEditStudent'
    export const SchoolAdminEditStudentAvatar = 'SchoolAdminEditStudentAvatar'
    export const SchoolAdminHome = 'SchoolAdminHome'
    export const SchoolAdminManageClass = 'SchoolAdminManageClass'
    export const SchoolAdminManageClassMain = 'SchoolAdminManageClassMain'
    export const SchoolAdminProfile = 'SchoolAdminProfile'
    export const SchoolAdminProfileMain = 'SchoolAdminProfileMain'
    export const SchoolAdminStats = 'SchoolAdminStats'
    export const StudentCheckIn = 'StudentCheckIn'
    export const StudentHome = 'StudentHome'
    export const StudentProfile = 'StudentProfile'
    export const StudentProfileMain = 'StudentProfileMain'
    export const Switches5PointAngry = 'Switches5PointAngry'
    export const SwitchesActiviesDetailView = 'SwitchesActiviesDetailView'
    export const SwitchesActiviesDisplayAll = 'SwitchesActiviesDisplayAll'
    export const SwitchesActivityMain = 'SwitchesActivityMain'
    export const SwitchesEmotionsAngryDrawBreath = 'SwitchesEmotionsAngryDrawBreath'
    export const SwitchesEmotionsAngryStopSteps = 'SwitchesEmotionsAngryStopSteps'
    export const SwitchesEmotionsAnxiousReframe = 'SwitchesEmotionsAnxiousReframe'
    export const SwitchesEmotionsAnxiousSleepChalk = 'SwitchesEmotionsAnxiousSleepChalk'
    export const SwitchesEmotionsDetailPublicView = 'SwitchesEmotionsDetailPublicView'
    export const SwitchesEmotionsDetailView = 'SwitchesEmotionsDetailView'
    export const SwitchesEmotionsExcitedListenOut = 'SwitchesEmotionsExcitedListenOut'
    export const SwitchesExploreAll = 'SwitchesExploreAll'
    export const SwitchesExploreAllMain = 'SwitchesExploreAllMain'
    export const SwitchesHome = 'SwitchesHome'
    export const SwitchesHomeMain = 'SwitchesHomeMain'
    export const SwitchesMemes = 'SwitchesMemes'
    export const SwitchesToolsDisplayAll = 'SwitchesToolsDisplayAll'
    export const SwitchesToolsMain = 'SwitchesToolsMain'
    export const TeacherAddStudent = 'TeacherAddStudent'
    export const TeacherCheckIn = 'TeacherCheckIn'
    export const TeacherCheckInMain = 'TeacherCheckInMain'
    export const TeacherClassAdmin = 'TeacherClassAdmin'
    export const TeacherClassAdminCurrentClassLogin = 'TeacherClassAdminCurrentClassLogin'
    export const TeacherClassAdminMain = 'TeacherClassAdminMain'
    export const TeacherClassMain = 'TeacherClassMain'
    export const TeacherClassSchedule = 'TeacherClassSchedule'
    export const TeacherCreateStudentNickname = 'TeacherCreateStudentNickname'
    export const TeacherCreateStudentPassword = 'TeacherCreateStudePasswordar'
    export const TeacherCreateStudentPickAvatar = 'TeacherCreateStudentPickAvatar'
    export const TeacherEditStudent = 'TeacherEditStudent'
    export const TeacherEditStudentAvatar = 'TeacherEditStudentAvatar'
    export const TeacherHome = 'TeacherHome'
    export const TeacherStats = 'TeacherStats'
    export const TeacherStatsMain = 'TeacherStatsMain'
    export const TeacherStudentIndividualStats = 'TeacherStudentIndividualStats'
    export const TeacherWellbeing = 'TeacherWellbeing'
    export const TeachersProfile = 'TeachersProfile'
    export const TeachersProfileMain = 'TeachersProfileMain'
    export const UnlockTeacherDevice = 'UnlockTeacherDevice'
    export const studentJoinClass = 'studentJoinClass'
    export const studentJoinClassFinished = 'studentJoinClassFinished'
    export const studentJoinClassLogin = 'studentJoinClassLogin'
    export const studentJoinClassNewExistingStudent = 'studentJoinClassNewExistingStudent'
    export const studentJoinClassPickAvatar = 'studentJoinClassPickAvatar'
    export const studentJoinClassReviewDetails = 'studentJoinClassReviewDetails'
    export const studentJoinClassSetDetails = 'studentJoinClassSetDetails'
}