import { useReactiveVar } from '@apollo/client'
import { useRoute } from '@react-navigation/native'
import { useEffect, useState } from 'react'
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import { IconAngry } from '../icon/icon-angry'
import { IconAnxious } from '../icon/icon-anxious'
import { IconEmotionsLegend } from '../icon/icon-emotions-legend'
import { IconEmotionsLegendEmotionBlocks } from '../icon/icon-emotions-legend-emotion-blocks'
import { IconExcited } from '../icon/icon-excited'
import { IconHappy } from '../icon/icon-happy'
import { IconSad } from '../icon/icon-sad'
import { IconScared } from '../icon/icon-scared'
import { Emotion } from '../models'
import { TeacherStatsLegend } from '../teacher/teacher-stats-legend'
import { rvShowIndividualStudentHowTo } from '../teacher/teacher-student-individual-stats'
import { DefaultText } from './default-text'
import { rvPrintMode } from './export-to-pdf-button'
import { LinkText } from './link-text'
import { ScreenNames } from './screen-names'
import { StudentIndividualStatsHowToButton } from './svg-files/student-individual-stats-how-to-button'
import { lowRes, useResponsive } from './use-responsive'

interface Props {
    showAllCheckInLegend: boolean
}

export const StaffWellbeingStatsLegends = ({ showAllCheckInLegend }: Props) => {

    const { isLargeScreen } = useResponsive()
    const [showLegend, setShowLegend] = useState(isLargeScreen)
    const route = useRoute()
    const isIndividualStats = route.name === ScreenNames.TeacherStudentIndividualStats
    const showHowToModal = useReactiveVar(rvShowIndividualStudentHowTo)
    const isPrintMode = useReactiveVar(rvPrintMode)

    useEffect(() => {
        if (!isLargeScreen) {
            setShowLegend(false)
        } else {
            setShowLegend(!lowRes)
        }

    }, [lowRes, isLargeScreen])


    const toggleLegendVisibility = () => {
        setShowLegend(!showLegend)
    }

    const toggleIndividualStudentHowTo = () => {
        rvShowIndividualStudentHowTo(!showHowToModal)
    }

    return (
        <>
            <LinkText onPress={toggleLegendVisibility} style={[styles.legendVisibility, { alignSelf: lowRes && isIndividualStats ? 'flex-end' : 'flex-start' }]}>{showLegend ? 'hide legend' : 'show legend'}</LinkText>
            <View style={[styles.legendsContainer, showLegend ? {} : { display: 'none', height: isPrintMode ? 200 : undefined }]}>
                <View style={styles.legendRowsContainer}>
                    <View style={showAllCheckInLegend ? styles.tiredness : { display: 'none' }}>
                        <View style={styles.legendText}>
                            <DefaultText style={styles.legendText}>Emotions</DefaultText>
                        </View>
                        <IconEmotionsLegendEmotionBlocks />
                        <View style={styles.legendText}>
                            <DefaultText style={styles.legendText}>Energy</DefaultText>
                        </View>
                        <IconEmotionsLegend />
                        <TouchableOpacity style={styles.howToButton} onPress={toggleIndividualStudentHowTo}>
                            <StudentIndividualStatsHowToButton />
                        </TouchableOpacity>
                    </View>
                    <View style={[styles.legendRow, { width: isLargeScreen ? "auto" : '100%' }]}>
                        <TeacherStatsLegend
                            emotion={Emotion.ANGRY}
                            icon={<IconAngry />}
                        />
                        <TeacherStatsLegend
                            emotion={Emotion.SAD}
                            icon={<IconSad />}
                        />
                        <TeacherStatsLegend
                            emotion={Emotion.SCARED}
                            icon={<IconScared />}
                        />
                    </View>
                    <View style={[styles.legendRow, { width: isLargeScreen ? "auto" : '100%' }]}>
                        <TeacherStatsLegend
                            emotion={Emotion.ANXIOUS}
                            icon={<IconAnxious />}
                        />
                        <TeacherStatsLegend
                            emotion={Emotion.EXCITED}
                            icon={<IconExcited />}
                        />
                        <TeacherStatsLegend
                            emotion={Emotion.HAPPY}
                            icon={<IconHappy />}
                        />
                    </View>
                </View>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    legendVisibility: {
        fontSize: 12,
        alignSelf: 'flex-start',
        marginHorizontal: 10,
    },
    legendsContainer: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
    } as ViewStyle,
    legendRow: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
    },
    tiredness: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 5,
        marginRight: 20
    } as ViewStyle,
    legendRowsContainer: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: "auto",
    },
    legendText: {
        fontSize: 12,
        fontWeight: 'bold',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: 10,
    },
    howToButton: {
        width: 40,
        height: 40,
        marginLeft: 20
    }
})