import { Linking, StyleSheet, Text, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { IntroductionToAnxiousLessonPlan1 } from './src/introduction-to-anxious-lesson-plan1'
import { IntroductionToAnxiousLessonPlan2 } from './src/introduction-to-anxious-lesson-plan2'
import { IntroductionToAnxiousLessonPlan3 } from './src/introduction-to-anxious-lesson-plan3'
import DefaultTextWithLink from '../common/default-text-with-link'
import { Worksheets } from '../../common/worksheets-imports'
import { ToolsTheLittleBookOfBigEmotions } from '../tools/cards/the-little-book-of-big-emotions'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import { commonStyles } from '../common/emotion-card-image-sizes'

const introductionAnxiousVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/TDFEW3eAIlg",
    title: ""
}

export const SwitchesLessonPlansIntroductionToAnxious: CardTemplate = {
    cardId: "f979de52-b783-48a7-84bc-1129539f4541",
    title: 'Exploring anxious',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define anxiety and understand its importance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify strategies for managing anxiety in a healthy way.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Log into the Switch4Schools program and select an appropriate meme of the day to share, for example:</DefaultText>
                        <View style={styles.imageContainer}>
                            <IntroductionToAnxiousLessonPlan2 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play this video - Sesame Street: Zach Braff and Telly are Anxious</DefaultText>
                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={introductionAnxiousVideo} />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think about a time when they felt anxious or worried. What were they worried about? How did they feel physically? What did they do to try to feel better?</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Instruction (5 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Little Book of Big Emotion.  Introduce the concept of anxiety. Define anxiety as a feeling of worry, nervousness, or unease that can be accompanied by physical symptoms such as racing heart, sweating, or difficulty concentrating.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the role of anxiety in the body. Explain that anxiety is a natural response to stress or danger, and it can help us to stay alert and focused in challenging situations. However, when anxiety becomes excessive or chronic, it can interfere with our daily lives and well-being.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the relevant slide or draw a three column table on the board, and ask students to contribute what they see, feel (in the body), or do (behave) when they or someone else is anxious. Example: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Little Book of Big Emotion to explain that there are different levels of happiness and words we can use to describe those levels</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• OPTIONAL Movement Break:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Swirly Fingers. Have students point their index fingers at each other in front of them.  Ask them to draw small circles in a clockwise direction at the same time. Then ask them to reverse the order, before asking them to have one going backwards while the other goes forwards in a circular motion.</DefaultText>
                        <View style={[CommonStyles.lessonPlanText, CommonStyles.lessonPlanImageContainer, ]}>
                            <IntroductionToAnxiousLessonPlan3 />
                        </View>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (20 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give each student a worry cup worksheet and ask them to write down all the things they are worried about and draw a water drop around it to show how big or small the worry is.</DefaultText>
                        <View style={styles.imageContainer}>
                            <IntroductionToAnxiousLessonPlan1 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once the students have finished writing ask them to fold the paper up and put it away, and tell them that they are putting their worries aside for now and that they can come back to it later. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ At a later time, perhaps as homework, or the next day, ask the students to review their worry cup.  Instruct them to, “Ask yourself if there is anything you can do to address the worry, and if so, what action can you take. If there is nothing you can do, remind yourself that you cannot control everything and that it is okay to let go of the worry.”</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Advise students that you will be exploring different switches over the coming weeks to practice when feeling anxious.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If time permits and/or if student’s have access to the Switch4Schools program on their device encourage them to explore the available switches assigned to anxious on the emotion wheel. Could also be a homework activity.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap to emphasise that we have anxiety for a reason, and that’s okay. It is a really good idea to acknowledge and address worries as it will help you to feel in control and better equipped to cope with stress. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ dncourage students to seek additional help if their anxiety is persistent or severe, and to talk to a trusted adult if they are struggling to manage their anxiety.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Introducing Anxious Slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/P3+Exploring+Anxiety.pdf' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='The Little Book of Big Emotions' link={ToolsTheLittleBookOfBigEmotions.openUrl || ""} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Sesame Street: Zach Braff and Telly are Anxious <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='https://www.youtube.com/watch?v=TDFEW3eAIlg&t=1s' link='https://www.youtube.com/watch?v=TDFEW3eAIlg&t=1s' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Worry Cup worksheet' link={Worksheets.worryCup.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What to Do (and Not Do) When Children Are Anxious -</DefaultText>
                        <DefaultTextWithLink text='https://childmind.org/article/what-to-do-and-not-do-when-children-are-anxious/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Sesame Street: Zach Braff and Telly are Anxious</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=1Y5R8K7OKSo' />
                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={introductionAnxiousVideo} />
                        </View>

                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 10
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        flexDirection: 'row',
        marginHorizontal: 20,
        justifyContent: 'center',
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        marginVertical: 10,
    },
    noIndent: {
        paddingLeft: 0,
    },
})
