import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import * as Clipboard from 'expo-clipboard'
import { StyleSheet, View } from 'react-native'
import { CustomCreateStaffMutation, CustomCreateStaffMutationVariables, CustomDeleteStaffMutation, CustomDeleteStaffMutationVariables, CustomUpdateStaffMutation, CustomUpdateStaffMutationVariables, GetListStaffBySchoolIDQuery, GetListStaffBySchoolIDQueryVariables, GetSchoolAdminByCognitoUsernameQuery, GetSchoolAdminByCognitoUsernameQueryVariables, Staff } from '../../common/API'
import { generatePassword } from '../../common/generate-password'
import { customCreateStaff, customDeleteStaff, customUpdateStaff } from '../../common/graphql/mutations'
import { getListStaffBySchoolID, getSchoolAdminByCognitoUsername } from '../../common/graphql/queries'
import DefaultTable, { TableColumn } from '../common/default-table'
import { DefaultText } from '../common/default-text'
import { rvIsLoading } from '../common/loading'
import { showAlert } from '../common/universal-alert'
import { getSchoolCampusesAndGroupsQuery, GetSchoolCampusesAndGroupsQueryResponse } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query'
import { rvCurrentUser } from '../login/login-state'
import { copyTextToClipboard } from '../common/copy-text-to-clipboard'


const SchoolAdminHierarchyStaffsList = () => {
    const user = useReactiveVar(rvCurrentUser)

    const { data: resultSchoolAdmin } = useQuery<GetSchoolAdminByCognitoUsernameQuery, GetSchoolAdminByCognitoUsernameQueryVariables>(gql`${getSchoolAdminByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "", limit: 5000 }, skip: !user })
    const currentSchoolAdmin = resultSchoolAdmin?.getSchoolAdminByCognitoUsername?.items[0]

    const { data: schoolData } = useQuery<GetSchoolCampusesAndGroupsQueryResponse>(getSchoolCampusesAndGroupsQuery, { variables: { id: currentSchoolAdmin?.schoolID }, skip: !currentSchoolAdmin })

    const { data: nonTeachingStaffsData } = useQuery<GetListStaffBySchoolIDQuery, GetListStaffBySchoolIDQueryVariables>(gql`${getListStaffBySchoolID}`, { variables: { schoolID: schoolData?.getSchool.id || "", limit: 5000 }, skip: !schoolData?.getSchool })
    const nonTeachingStaffs = nonTeachingStaffsData?.getListStaffBySchoolID?.items.filter((staff) => staff?._deleted !== true) as Staff[]

    const [customCreateStaffMutation] = useMutation<CustomCreateStaffMutation, CustomCreateStaffMutationVariables>(gql`${customCreateStaff}`,)
    const [customUpdateStaffMutation] = useMutation<CustomUpdateStaffMutation, CustomUpdateStaffMutationVariables>(gql`${customUpdateStaff}`,)
    const [customDeleteStaffMutation] = useMutation<CustomDeleteStaffMutation, CustomDeleteStaffMutationVariables>(gql`${customDeleteStaff}`,)

    const columns = [
        { label: 'Username', key: 'cognitoUsername' },
        { label: 'First Name', key: 'firstName' },
        { label: 'Last Name', key: 'lastName' },
        { label: 'Email', key: 'email' },
        { label: 'Actions', key: 'action_buttons' },
    ] as TableColumn[];


    const handleCreateStaff = async (item: Staff) => {
        try {
            rvIsLoading(true)
            const password = generatePassword()
            await customCreateStaffMutation({
                variables: {
                    input: {
                        schoolID: currentSchoolAdmin?.schoolID || "",
                        cognitoUsername: item.email,
                        firstName: item?.firstName,
                        lastName: item?.lastName,
                        email: item?.email,
                        password,
                    }
                }
            })
            copyTextToClipboard(password)
            showAlert({
                title: 'Successfully created staff',
                message: `username: ${item.email} \n password: ${password}`,
                leftButtonText: 'Ok'
            })
        } catch (error: any) {
            showAlert({
                title: "Something went wrong",
                message: error?.message || "",
                leftButtonText: "Ok"
            })
        } finally {
            rvIsLoading(false)
        }
    }

    const handleEditStaff = async (data: Staff) => {
        try {
            rvIsLoading(true)
            await customUpdateStaffMutation({
                variables: {
                    input: {
                        id: data?.id,
                        email: data?.email,
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        preferredUsername: data?.preferredUsername
                    }
                }
            })
            showAlert({
                title: 'Update staff',
                message: `Successfully update staff`,
                leftButtonText: 'Ok'
            })
        } catch (error: any) {
            showAlert({
                title: "Something went wrong",
                message: error?.message || "",
                leftButtonText: "Ok"
            })
        } finally {
            rvIsLoading(false)
        }
    }

    const handleDeleteStaff = async (data: Staff) => {
        try {
            rvIsLoading(true)
            await customDeleteStaffMutation({
                variables: {
                    input: {
                        staffID: data?.id
                    }
                }
            })
            showAlert({
                title: 'Delete staff',
                message: 'Successfully deleted staff',
                leftButtonText: 'Ok'
            })
        } catch (error: any) {
            showAlert({
                title: "Something went wrong",
                message: error?.message || "",
                leftButtonText: "Ok"
            })
        } finally {
            rvIsLoading(false)
        }
    }

    return (
        <View style={styles.container}>
            <DefaultText style={styles.label}>Support staff / other</DefaultText>
            <DefaultTable
                data={nonTeachingStaffs}
                columns={columns}
                onCreate={handleCreateStaff}
                onEdit={handleEditStaff}
                onDelete={handleDeleteStaff}
            />
        </View>
    )
}
export default SchoolAdminHierarchyStaffsList


const styles = StyleSheet.create({
    label: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    container: {
        flex: 1,
        padding: 40,
        gap: 10,
    },
})