import { useNavigation } from '@react-navigation/core'
import { View, StyleSheet, TouchableOpacity, Dimensions, TextStyle } from 'react-native'
import { ScreenNames } from '../common/screen-names'
import { EmotionCircle3Point } from './image-source/emotion-circle-3-point'
import { EmotionCircle5Point } from './image-source/emotion-circle-5-point'
import { useReactiveVar } from '@apollo/client'
import { getHomeIntensityLevel } from '../common/get-intensity-level'
import { rvCurrentClass } from '../common/common-state'
import { TeacherDrawerNavigatorPamarList } from '../teacher/teacher-route-param-types'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { rvParentIntensityState } from '../parent/parent-state'
import { DefaultText } from '../common/default-text'
import { getLockDerviceRv, rvParentPortal, rvUserGroup } from '../login/login-state'
import { IconEmotionalWheel } from '../icon/icon-emotional-wheel'
import { Emotion } from '../models'
import { ExtraTipsIcon } from './common/extra-tips'
import { CardColorScheme } from './common/card-color-scheme'
import { useEffect } from 'react'
import StepperEmotionIntensityScale from '../common/stepper-emotion-intensity-scale'
import { SwitchesLessonPlansMyEmotionsLessonPlans } from './lesson-plan-cards/my-emotions-leasson-plans'
import { SwitchesLessonPlansMyEmotionsLessonPlansLevel1 } from './lesson-plan-cards/my-emotions-leasson-plans-level-1'
import { useResponsive } from '../common/use-responsive'
import { UserGroup } from '../../common/constants'
import { SwitchesLessonPlansSelfAwarenessAndEmotionalAwareness } from './lesson-plan-cards/self-awareness-and-emotional-awareness'
import { EmotionCircle7Point } from './image-source/emotion-circle-7-point'

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600

export const SwitchesHome = () => {

    const currentClass = useReactiveVar(rvCurrentClass)
    const emotionIntensityLevel = useReactiveVar(rvParentIntensityState)
    const intensityLevel = getHomeIntensityLevel(currentClass?.emotionIntensityLevel)
    const parentPortal = useReactiveVar(rvParentPortal)
    const userGroup = useReactiveVar(rvUserGroup)
    const denyAccess = useReactiveVar(getLockDerviceRv())
    const { isNarrowScreen } = useResponsive()

    let defaultEmotionIntensity = currentClass?.emotionIntensityLevel || 3

    if (userGroup === UserGroup.NonTeachingStaff) {
        defaultEmotionIntensity = 7
    }

    useEffect(() => {
        rvParentIntensityState(defaultEmotionIntensity)
    }, [currentClass?.emotionIntensityLevel])

    const changeEmotionalIntensity = (level: number) => {
        rvParentIntensityState(level)
    }

    const currentLessonPlansEmotionCard = emotionIntensityLevel === 1 ? SwitchesLessonPlansMyEmotionsLessonPlansLevel1 : intensityLevel === 5 ? SwitchesLessonPlansSelfAwarenessAndEmotionalAwareness : SwitchesLessonPlansMyEmotionsLessonPlans
    const { navigate } = useNavigation<DrawerNavigationProp<TeacherDrawerNavigatorPamarList>>()

    const handleNavigateToEmotionDetails = (emotion: Emotion) => {

        navigate(ScreenNames.SwitchesEmotionsDetailView, { emotion })
    }

    const handleNavigateToMyEmotionLessonPlans = () => {
        navigate(ScreenNames.Card, { id: currentLessonPlansEmotionCard.cardId, previousScreen: "switches-home" })
    }

    return (
        <View style={styles.container}>
            {!parentPortal && !denyAccess && userGroup === UserGroup.Teacher &&
                <View style={isNarrowScreen ? styles.emotionLessonPlanContainerMobile : styles.emotionLessonPlanContainer}>
                    <TouchableOpacity style={styles.imagecontainer} onPress={handleNavigateToMyEmotionLessonPlans}>
                        <View style={styles.extratips}>
                            <ExtraTipsIcon colorScheme={CardColorScheme.HAPPY} />
                        </View>
                        <DefaultText style={[styles.extraTipsText, { color: CardColorScheme.HAPPY }]}>
                            {currentLessonPlansEmotionCard.title}
                        </DefaultText>
                    </TouchableOpacity>
                </View>
            }
            {parentPortal || userGroup === UserGroup.Teacher || userGroup === UserGroup.Student || userGroup === UserGroup.ClassLogin ?
                <StepperEmotionIntensityScale value={emotionIntensityLevel} onChange={changeEmotionalIntensity} />
                :
                null
            }
            <View style={styles.emotionWheelContainer}>
                {
                    intensityLevel === 7 ? <EmotionCircle7Point onPress={handleNavigateToEmotionDetails} /> :
                        intensityLevel === 5 ? <EmotionCircle5Point onPress={handleNavigateToEmotionDetails} /> :
                            intensityLevel === 1 ? <IconEmotionalWheel onPress={handleNavigateToEmotionDetails} /> :
                                <EmotionCircle3Point onPress={handleNavigateToEmotionDetails} />
                }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
    },
    content: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        marginLeft: '10%',
        marginRight: '10%'
    },
    emotionWheelContainer: {
        flex: 1,
        padding: 10,
    },
    image: {
        width: 400,
        height: 350,

    },
    header: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    intensityContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
        paddingVertical: 10,
    },
    emotionText: {
        paddingHorizontal: 5,
    },
    extraTipsText: {
        fontWeight: '600',
        fontSize: windowWidthcondition ? 20 : 10,
        textAlign: 'center'

    } as TextStyle,
    imagecontainer: {
        padding: windowWidthcondition ? 10 : 2,
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 250,
        flexWrap: 'wrap'
    },
    extratips: {
        width: windowWidthcondition ? 50 : 25,
        height: windowWidthcondition ? 50 : 25,
    },
    emotionLessonPlanContainer: {
        position: 'absolute',
        right: 0,
        zIndex: 99
    },
    emotionLessonPlanContainerMobile: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        zIndex: 99,
    }
})
