import { TouchableOpacity } from 'react-native'
import { StyleSheet, Text, View } from 'react-native'
import { IconHeaderCheckIn } from '../icon/icon-header-check-in'
import { DefaultText } from './default-text'
import { DecidaColors } from '../../common/decida-colors'

type Props = {
    onPress: () => void
    isClassHeader?: boolean
}

const HeaderIconCheckIn = ({ onPress, isClassHeader }: Props) => {
    return (
        <TouchableOpacity onPress={onPress} testID='header-profile-checkin'>
            <View style={styles.icon}>
                <IconHeaderCheckIn color={isClassHeader ? DecidaColors.White : DecidaColors.Green} />
                <DefaultText style={[styles.iconText, { color: isClassHeader ? DecidaColors.White : DecidaColors.Gray }]}>Check-in</DefaultText>
            </View>
        </TouchableOpacity>
    )
}
export default HeaderIconCheckIn
const styles = StyleSheet.create({
    icon: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconText: {
        marginTop: 5,
        fontSize: 15,
        color: DecidaColors.Gray,
    },
})