import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { Pressable, StyleSheet, View } from 'react-native'
import { School } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'
import { Page } from '../common/page'
import { SendEmail } from '../common/send-email'
import { GetSchoolCampusesAndGroupsQueryResponse, getSchoolCampusesAndGroupsQuery } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query'
import { getSchoolAdminByCognitoUsername } from '../../common/graphql/queries'
import { rvCurrentUser } from '../login/login-state'
import DefaultTextWithCustomLink from '../switches/common/default-text-with-custom-link'
import SchoolAdminBillingAdministratorsDetails from './school-admin-billing-administrators-details'
import SchoolAdminBillingSchoolDetails from './school-admin-billing-school-details'

const SchoolAdminBillingDetails = () => {
    const user = useReactiveVar(rvCurrentUser)

    const { data: resultSchoolAdmin } = useQuery(gql`${getSchoolAdminByCognitoUsername}`, { variables: { cognitoUsername: user?.username } })
    const currentSchoolAdmin = resultSchoolAdmin?.getSchoolAdminByCognitoUsername?.items[0]
    const schoolID = currentSchoolAdmin?.schoolID
    const { data: resultSchool } = useQuery<GetSchoolCampusesAndGroupsQueryResponse>(getSchoolCampusesAndGroupsQuery, { variables: { id: schoolID }, skip: !schoolID })

    const schoolData = resultSchool?.getSchool

    return (
        <Page scroll style={styles.page}>
            <DefaultText style={styles.pageTitle}>Account and Billing</DefaultText>

            <View style={styles.contentContainer}>
                <SchoolAdminBillingSchoolDetails schoolData={schoolData as School} />
                <SchoolAdminBillingAdministratorsDetails schoolData={schoolData as School} />
            </View>

            <View style={styles.helpSectionContainer}>
                <DefaultText style={styles.needHelpText}>
                    Need help?
                </DefaultText>
                <DefaultText style={styles.text}>
                    Visit <DefaultTextWithCustomLink style={styles.text} link='https://www.switch4schools.com.au/faqs' text='FAQ’s ' /> or <Pressable onPress={SendEmail}><DefaultText style={styles.sendMailText}>contact us</DefaultText></Pressable> for additional support.
                </DefaultText>
            </View>

        </Page >
    )
}

export default SchoolAdminBillingDetails
const styles = StyleSheet.create({
    sendMailText: {
        fontSize: 16,
        textDecorationLine: 'underline',
        color: 'blue'
    },
    text: {
        fontSize: 16,
    },
    needHelpText: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    helpSectionContainer: {
        paddingHorizontal: 20,
        paddingBottom: 40,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    contentContainer: {
        width: '100%',
        height: '100%',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        flexDirection: 'row'
    },
    page: {
        paddingVertical: 40,
        paddingHorizontal: 80,
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },
    pageTitle: {
        fontSize: 30,
        fontWeight: '600',
        color: DecidaColors.Green,
        marginBottom: 40
    },
})