import { GestureResponderEvent, StyleProp, StyleSheet, Text, TextStyle } from 'react-native'
import { DefaultText } from './default-text'

interface Props {
    children: string | string[] | undefined
    style?: StyleProp<TextStyle>
    onPress?: (event: GestureResponderEvent) => void
}

export const PageTitleText = ({ children, style, onPress }: Props) =>
    <DefaultText onPress={onPress} style={[styles.text, style]}>{children}</DefaultText>

const styles = StyleSheet.create({
    text: {
        fontSize: 25,
        padding: 20,
    } as TextStyle
})