import { StudentCheckInCacheObject } from '../../common/student-check-in-cache'
import { showAlert } from '../common/universal-alert'
import { RequestForChatStatus } from '../models'
import { StudentAndStudentClassAndStudentCheckIn } from './teacher-stats-last-period-and-requested-chat'

type TeacherClearStudentRequestForChatProps = {
    onSubmit: () => void
}

const teacherClearStudentRequestForChatAlert = ({ onSubmit }: TeacherClearStudentRequestForChatProps) => {
    showAlert({
        title: 'Clear alert',
        message: 'Do you want to clear the alert/s?',
        leftButtonText: 'OK',
        onLeftButtonPress: onSubmit,
        rightButtonText: 'Cancel'
    })
}

export default teacherClearStudentRequestForChatAlert
