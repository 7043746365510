import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan, Line } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconHappy5Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 5)
    }

    const onPressLevel2 = {
        [prop]: () => onPress(2 / 5)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(3 / 5)
    }

    const onPressLevel4 = {
        [prop]: () => onPress(4 / 5)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg testID='svg-happy-emotion' viewBox="0 0 811.854 543.29">
            <Line y1="134.499" x2="203.091" transform="translate(1.933 397.238)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3628.267,465.947l-25.386,6.1,9.859,14.887Z" transform="translate(-3411.375 -76.569)" fill="#5c5d5d" />
            <Line y1="82.656" x2="124.912" transform="translate(229.994 297.825)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3778.152,366.539l-25.389,6.094,9.854,14.892Z" transform="translate(-3411.375 -76.569)" fill="#5c5d5d" />
            <Line y1="85.438" x2="126.888" transform="translate(375.565 199.442)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3925.637,268.06l-25.339,6.3,9.974,14.812Z" transform="translate(-3411.375 -76.569)" fill="#5c5d5d" />
            <Line y1="83.932" x2="126.848" transform="translate(524.987 100.95)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M4075.082,169.664l-25.389,6.093,9.854,14.892Z" transform="translate(-3411.375 -76.569)" fill="#5c5d5d" />
            <Line y1="78.084" x2="123.185" transform="translate(675.984 7.622)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M4197.065,82.164l9.559,15.083,15.943-20.678Z" transform="translate(-3411.375 -76.569)" fill="#5c5d5d" />
            <G {...onPressLevel1} transform="translate(-71 -194)">
                <Path d="M3418.971,619.352l208.958-.867.338-138.115Z" transform="translate(-3340.375 117.431)" fill="#acd1a7" />
                <Text transform="translate(164.685 720.069)" fill="#313133" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Peaceful</TSpan></Text>
            </G>
            <G {...onPressLevel2} transform="translate(-71 -194)">
                <Path d="M3639.149,474.369l138-91.8.164,235.622-139.277.111Z" transform="translate(-3340.375 117.431)" fill="#79b470" />
                <Text transform="translate(305.522 670.142)" fill="#313133" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Optimistic</TSpan></Text>
            </G>
            <G {...onPressLevel3} transform="translate(-71 -194)">
                <Path d="M3787.541,376.569l138.6-93.192L3925.3,618.69l-137.712.112Z" transform="translate(-3340.375 117.431)" fill="#4c973c" />
                <Text transform="translate(464.822 619.058)" fill="#313133" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Cheerful</TSpan></Text>
            </G>
            <G {...onPressLevel4} transform="translate(-71 -194)">
                <Path d="M3935.432,276.924l139.65-91.754-.335,433.522-139.277.11Z" transform="translate(-3340.375 117.431)" fill="#2e8334" />
                <Text transform="translate(599 569.13)" fill="#ffffff" fontSize="23" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Sensational</TSpan></Text>
            </G>
            <G {...onPressLevel5} transform="translate(-71 -194)">
                <Path d="M4084.486,179.169l138.082-90,.162,529.521-137.712.112Z" transform="translate(-3340.375 117.431)" fill="#136f30" />
                <Text transform="translate(764.488 519.204)" fill="#ffffff" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Ecstatic</TSpan></Text>
            </G>
        </Svg>
    )
}