import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { WhyDoesThisWorkIcon } from './why-does-this-work-icon'
import { DefaultText } from '../../common/default-text'
import { showAlert } from '../../common/universal-alert'

type Props = {
    message: string
}

const WhyDoesItWorkComponent = ({ message }: Props) => {

    const showWhyDoesItWorkPopup = () => {
        showAlert({ title: "Why does this work?", leftButtonText: "Close", message })
    }

    return (
        <View style={styles.whydoesthisworkContainer}>
            <TouchableOpacity style={styles.image} onPress={showWhyDoesItWorkPopup}>
                <View style={styles.whydoesthiswork}>
                    <WhyDoesThisWorkIcon />
                </View>
                <DefaultText>
                    Why does this work?
                </DefaultText>
            </TouchableOpacity>
        </View>
    )
}

export default WhyDoesItWorkComponent

const styles = StyleSheet.create({
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,

    },
    whydoesthiswork: {
        width: 100,
        height: 100,
    },
    whydoesthisworktext: {
        padding: 10,
    },
    whydoesthisworkContainer: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
})