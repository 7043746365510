import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import SchoolAdmiCampusesListItem from './school-admin-campuses-list-item'
import { DefaultText } from '../common/default-text'
import { DecidaColors } from '../../common/decida-colors'
import { Campus } from '../../common/API'
import { GetSchoolQueryResponseCampus } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query'
import _ from 'lodash'

type Props = {
    campuses: GetSchoolQueryResponseCampus[]
}

const SchoolAdminCampusesList = ({ campuses }: Props) => {

    return (
        <View>
            <View style={{ marginTop: 40, marginBottom: 20, paddingLeft: 20 }}>
                <DefaultText style={styles.mainText}>Campuses</DefaultText>
            </View>
            {_.sortBy(campuses, campus => campus.name?.toLowerCase()).map((campus) => {
                return (
                    <SchoolAdmiCampusesListItem key={campus.id} campus={campus} />
                )
            })}
        </View>
    )
}
export default SchoolAdminCampusesList
const styles = StyleSheet.create({
    mainText: {
        fontWeight: 'bold',
        color: DecidaColors.AppleSystemGray2Dark,
    }
})