import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconAngry3Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 3)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(2 / 3)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg viewBox="0 0 526.692 351.734">
            <Path d="M0,0,204.142-134.988" transform="translate(1.93 340.18)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.391,6.085l9.85,14.895Z" transform="translate(217.946 197.34)" fill="#657170" />
            <Path d="M0,0,124.9-82.472" transform="translate(229.056 189.368)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.394,6.069l9.839,14.9Z" transform="translate(365.831 99.052)" fill="#657170" />
            <Path d="M0,0,125.832-84.259" transform="translate(376.656 92.18)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.355,6.233l9.935,14.838L0,0Z" transform="translate(514.316 0.001)" fill="#657170" />
            <G {...onPressLevel1} transform="translate(-16.654 -111)">
                <Path d="M0,0,208.958-.868l.392-139.392Z" transform="translate(24.25 462.227)" fill="#ffbea9" />
                <Text transform="translate(101.605 450.918)" fill="#2b2e34" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Annoyed</TSpan></Text>
            </G>
            <G {...onPressLevel3} transform="translate(-16.654 -111)">
                <Path d="M0,0,137.5-90.534l.11,235.779-139.277.11Z" transform="translate(244.982 315.822)" fill="#ff6b4c" />
                <Text transform="translate(248.749 403.588)" fill="#2b2e34" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Frustrated</TSpan></Text>
            </G>
            <G {...onPressLevel5} transform="translate(-16.654 -111)">
                <Path d="M0,0,138.6-94.134,137.7,241.743l-137.713.112Z" transform="translate(392.874 219.822)" fill="#ff2d16" />
                <Text transform="translate(416.502 356.257)" fill="#2b2e34" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Furious</TSpan></Text>
            </G>
        </Svg>
    )
}