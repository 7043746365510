import moment from "moment";
import { StaffCheckIn, TeacherCheckIn } from "../../common/API";

export const groupStaffCheckInsByWeek = <T extends TeacherCheckIn | StaffCheckIn>(staffCheckIns: T[]): T[][] => {
    const groupedCheckIns: { [weekStartDate: string]: T[] } = {};

    staffCheckIns.forEach(checkIn => {
        const weekStartDate = moment(checkIn.createdAt).startOf('week').toISOString();
        if (!groupedCheckIns[weekStartDate]) {
            groupedCheckIns[weekStartDate] = [];
        }
        groupedCheckIns[weekStartDate].push(checkIn);
    });

    const groupedCheckInsArray: T[][] = Object.values(groupedCheckIns);

    return groupedCheckInsArray;
};

