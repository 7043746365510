import { Text } from 'react-native'
import { CardTemplate, CardType } from '../../common/card-template'
import { CardColorScheme } from '../../common/card-color-scheme'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { NeedHelpIcon } from '../icons/need-help-icon'
import { DefaultTextWithIndentationWithLinks } from '../../common/default-text-with-indentation-with-links'
import { NeedHelpIconCard } from '../icons/need-help-icon-card'
import { openUrlWebView } from '../../../web-view/open-url-web-view'

export const ToolsProfessionalHelp: CardTemplate = {
  cardId: "c9e876f1-772a-4030-8d49-ba3b20329259",
  title: 'Need help?',
  Header: NeedHelpIcon,
  Icon: NeedHelpIconCard,
  HeaderTitle: 'Need help?',
  PerfectFor: () => (
    <>
      <DefaultTextWithoutArrowsBold textdata={"Resources and support"} />
      <DefaultTextWithoutArrowsItallic textdata={`If you’d like to find out more, or talk to someone, here are some organisations that can help:`} />
      <DefaultTextWithIndentationWithLinks textdata={" 1800 55 1800 (telephone and online counselling for ages 5-25)"} Links={() => <Text style={{ textDecorationLine: 'underline', color: 'blue' }} onPress={() => {
        openUrlWebView('https://www.kidshelp.com.au/');
      }}>Kids Helpline</Text>} />
      <DefaultTextWithIndentationWithLinks textdata={" 13 11 14"} Links={() => <Text style={{ textDecorationLine: 'underline', color: 'blue' }} onPress={() => {
        openUrlWebView('https://www.lifeline.org.au/');
      }}>Lifeline</Text>} />
      <DefaultTextWithIndentationWithLinks textdata={" in your state or territory for counselling and support for parents and carers"} Links={() => <Text style={{ textDecorationLine: 'underline', color: 'blue' }} onPress={() => {
        openUrlWebView('https://www.parentline.org.au/parent-line-in-other-states/');
      }} >Call Parentline</Text>} />
      <DefaultTextWithIndentationWithLinks textdata={" to chat online"} Links={() => <Text style={{ textDecorationLine: 'underline', color: 'blue' }} onPress={() => {
        openUrlWebView('https://headspace.org.au/eheadspace/');
      }}>eheadspace</Text>} />
      <DefaultTextWithIndentationWithLinks textdata={" (people living with a mental illness and their carers) — call 1800 18 7263"} Links={() => <Text style={{ textDecorationLine: 'underline', color: 'blue' }} onPress={() => {
        openUrlWebView('https://www.sane.org/');
      }}>SANE Australia</Text>} />
      <DefaultTextWithIndentationWithLinks textdata={" (youth mental health service) — visit the website for info or use the online forum"} Links={() => <Text style={{ textDecorationLine: 'underline', color: 'blue' }} onPress={() => {
        openUrlWebView('https://au.reachout.com/');
      }}>ReachOut.com</Text>} />
      <DefaultTextWithIndentationWithLinks textdata={" is a free app with evidence-based information to help parents or carers with the wellbeing of their child"} Links={() => <Text style={{ textDecorationLine: 'underline', color: 'blue' }} onPress={() => {
        openUrlWebView('https://raisingchildren.net.au/guides/raising-healthy-minds');
      }}>Raising Healthy Minds app</Text>} />
      <DefaultTextWithIndentationWithLinks textdata={` 1300 22 4636 — call or chat online with a trained mental health professional`} Links={() => <Text style={{ textDecorationLine: 'underline', color: 'blue' }} onPress={() => {
        openUrlWebView('https://www.beyondblue.org.au/get-support/get-immediate-support');
      }}>Beyond Blue</Text>} />
        <DefaultTextWithIndentationWithLinks textdata={` - call, email or webchat to get advice and information. `} Links={() => <Text style={{ textDecorationLine: 'underline', color: 'blue' }} onPress={() => {
        openUrlWebView('https://www.amaze.org.au/autismconnect/?gclid=EAIaIQobChMIuaCvk-qC_wIV1cl8Ch3K_wYJEAEYASAAEgKudvD_BwE');
      }}>Autism Connect</Text>} />


      <DefaultTextWithoutArrowsItallic textdata={`You can also ask your family doctor for advice or consult a psychologist if you would like more information or mental health resources for kids.`} />
    </>
  ),

  BottomRow: () => (
    <>
      <DefaultTextWithoutArrowsBold textdata={""} />
      <DefaultTextWithoutArrowsItallic textdata={""} />
    </>

  ),
  CardContent: () => (
    <>

    </>
  ),
  colorScheme: CardColorScheme.TOOL,
  whyDoesItWorkText: '',
  type: [CardType.Tool],
  time: [],
  people: [],
  place: [],
  category: [],
  action: [],
  level: []
}

