import { useReactiveVar } from '@apollo/client';
import { Entypo } from '@expo/vector-icons';
import { Audio } from 'expo-av';
import { AVPlaybackSourceObject } from 'expo-av/build/AV.types';
import { useEffect, useRef, useState } from 'react';
import { Dimensions, StyleSheet, View, ViewStyle } from 'react-native';
import { DecidaColors } from '../../common/decida-colors';
import { rvCard } from '../switches/common/card-color-scheme';
import { IconSize } from './const';
import { DefaultButton } from './default-button';
import { DefaultText } from './default-text';
import SwitchesSoundsIcon from './svg-files/switches-sound-icon';

Audio.setAudioModeAsync({
    playsInSilentModeIOS: true
})

interface Props {
    audioFile: AVPlaybackSourceObject
    audioTitle?: string
    containerStyle?: ViewStyle
    hideText?: boolean
    customIcon?: () => JSX.Element
    accessibilityLabel?: string
    onPlay?: (playSound: () => Promise<void>, pauseSound: () => Promise<void>, playState: boolean) => void
}

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600

export const AudioHandlerSwitches = ({ audioFile, containerStyle, hideText, customIcon: CustomIcon, accessibilityLabel, onPlay }: Props) => {

    const soundRef = useRef<Audio.Sound>(new Audio.Sound)
    const [playState, setPlayState] = useState(false);
    const emotionColour = useReactiveVar(rvCard)?.colorScheme

    const loadSound = async () => {
        const sound = await Audio.Sound.createAsync(
            audioFile
        );

        soundRef.current = sound.sound;
    }
    async function playSound() {
        try {
            const result = await getSoundStatus();

            if (result?.isLoaded && result.isPlaying === false) {
                await soundRef.current.playAsync();
                setPlayState(true)
            }
        } catch (error) {
            console.log("Play sound error")
        }
    }

    async function stopSound() {
        try {
            setPlayState(false)
            await soundRef.current?.unloadAsync();
        } catch (error) {
            console.log("Stop sound error")
        }
    }

    async function pauseSound() {
        try {
            const result = await getSoundStatus();
            if (result.isLoaded && result.isPlaying) {
                setPlayState(false)
                await soundRef.current?.pauseAsync();
            }
        } catch (error) {
            console.log("Pause sound error")
        }
    }

    const getSoundStatus = async () => {
        return await soundRef.current.getStatusAsync();
    }

    const getPlaybackstatusUpdate = (playbackStatus: any) => {
        if (playbackStatus?.didJustFinish) {
            setPlayState(false)
        }
    }

    useEffect(() => {
        loadSound()
        return soundRef.current
            ? () => {
                console.log('Unloading Sound');
                stopSound()
            }
            : undefined;
    }, [audioFile]);

    useEffect(() => {
        soundRef.current.setOnPlaybackStatusUpdate(getPlaybackstatusUpdate)
    }, [soundRef.current])

    const handleClick = () => onPlay !== undefined ? onPlay(playSound, pauseSound, playState) : playState ? pauseSound() : playSound()


    return (
        <DefaultButton accessibilityLabel={accessibilityLabel} onPress={handleClick} style={[CustomIcon ? styles.containerControllerCustom : styles.containerController, containerStyle]}>
            {CustomIcon ? (
                <>
                    {!playState ? <CustomIcon /> : <Entypo name="controller-paus" size={IconSize} color={emotionColour} />}
                </>
            ) : (
                <View style={styles.iconContainer}>
                    {/* Default sound Icon */}
                    {!playState ? <SwitchesSoundsIcon preserveAspectRatio={'none'} width={IconSize} height={IconSize} style={[styles.playButtonStyle]} color={emotionColour} /> : <Entypo name="controller-paus" size={IconSize} color={emotionColour} />}
                </View>
            )}
            {!hideText &&
                <DefaultText style={[styles.buttonText, { color: DecidaColors.GrayText }]}>
                    {!playState ? "Listen" : "Pause"}
                </DefaultText>
            }
        </DefaultButton>
    )
}

const styles = StyleSheet.create({
    containerController: {
        width: windowWidthcondition ? 90 : 50,
        alignSelf: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 0,
        marginBottom: 0,
        borderRadius: 100,
        backgroundColor: 'transparent',
        paddingLeft: 5,
        paddingRight: 5,

    } as ViewStyle,
    containerControllerCustom: {
        width: windowWidthcondition ? 90 : 50,
        height: 80,
        alignSelf: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 0,
        marginBottom: 0,
        borderRadius: 100,
        backgroundColor: 'transparent',

    } as ViewStyle,

    playButtonStyle: {
        maxWidth: IconSize,
        maxHeight: IconSize,
        borderRadius: 50,
    },
    buttonText: {
        fontSize: windowWidthcondition ? 15 : 10,
        marginTop: 5,
        textAlign: 'center'
    },
    iconContainer: {
        borderRadius: 50,
        paddingHorizontal: 5,
        overflow: 'hidden',
    }
})
