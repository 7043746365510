import React, { useCallback, useEffect, useState } from 'react'
import { StyleSheet, TextInput, TouchableOpacity, View } from 'react-native'
import { CommonStyles } from '../common/const'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { TeacherClassSelector } from '../common/teacher-class-selector'
import { usernameTaken } from '../login/username-taken-validator'
import _ from 'lodash'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'
import { SchoolAdminNavigationParams } from '../school-admin/school-admin-navigation-params'
import { ScreenNames } from './screen-names'
import { InputCognitoUsername } from './input-cognito-username'
import { FirstNameInput } from './first-name-input'
import { LastNameInput } from './last-name-input'
import { DecidaColors } from '../../common/decida-colors'
import { UserGroup } from '../../common/constants'

export type ProfileFormData = {
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    cognitoUsername?: string,
    preferredUsername?: string | null,
}

type Props = {
    data?: ProfileFormData | null
    onSave: (form: ProfileFormData) => void
    allowEdit: boolean
    setAllowEdit: (value: boolean) => void
    profileType: UserGroup
}

const ProfileForm = ({ data, onSave, allowEdit, setAllowEdit, profileType }: Props) => {
    const [firstName, setFirstName] = useState(data?.firstName || '')
    const [lastName, setLastName] = useState(data?.lastName || '')
    const [email, setEmail] = useState(data?.email)
    const [currentUsername, setCurrentUsername] = useState("")
    const [usernameUnavalible, setUsernameUnavalible] = useState<boolean | null>(null)
    const { navigate } = useNavigation<DrawerNavigationProp<CheckInNavigatorParamList | SchoolAdminNavigationParams>>()

    useEffect(() => {
        setFirstName(data?.firstName || '')
        setLastName(data?.lastName || '')
        setEmail(data?.email)
        setCurrentUsername(data?.preferredUsername || data?.cognitoUsername || "")
    }, [data])

    const usernameAvailability = async (username: string) => {
        setUsernameUnavalible(await usernameTaken(username))
    }

    const debouncedUsernameAvailability = useCallback(_.debounce(usernameAvailability, 300), []);

    const handleEmail = (value: string) => {
        profileType === UserGroup.Teacher && debouncedUsernameAvailability(value)
        setEmail(value)
    }

    const handleUsername = (value: string) => {
        debouncedUsernameAvailability(value)
        setCurrentUsername(value)
    }


    const navigateToPasswordChange = () => {
        navigate(ScreenNames.ChangePasswordProfile)
    }

    const handleSubmit = () => {
        const form = {
            firstName: firstName || "",
            lastName: lastName || "",
            email: email || "",
            preferredUsername: currentUsername || ""
        }
        onSave(form)
    }

    return (
        <View style={[CommonStyles.flexContainer, { alignItems: 'flex-start', marginTop: '5%' }]}>
            {profileType === UserGroup.Teacher && (
                <View style={styles.inputContainer}>
                    <DefaultText style={styles.heading}>Class Name/s</DefaultText>
                    <TeacherClassSelector />
                </View>
            )}
            <View style={styles.inputContainer}>
                {allowEdit ?
                    <>
                        <FirstNameInput value={firstName} onChangeText={setFirstName} />
                        <LastNameInput value={lastName} onChangeText={setLastName} />
                        <DefaultText style={styles.heading}>Email</DefaultText>
                        <TextInput
                            style={CommonStyles.textInput}
                            autoCapitalize='none'
                            autoComplete='email'
                            autoCorrect={false}
                            placeholder='Email'
                            value={email || ''}
                            onChangeText={handleEmail}
                        />
                        <InputCognitoUsername title='Username' value={currentUsername} onChangeText={handleUsername} setUserExists={setUsernameUnavalible} userExists={usernameUnavalible} />
                        {profileType === UserGroup.Teacher && (
                            <DefaultText style={styles.emailNote}>Note: Username once changed becomes your new login username.</DefaultText>
                        )}

                    </>
                    :
                    <>
                        <DefaultText style={styles.heading}>First Name</DefaultText>
                        <DefaultText style={styles.subtitle}>{firstName}</DefaultText>
                        <DefaultText style={styles.heading}>Last Name</DefaultText>
                        <DefaultText style={styles.subtitle}>{lastName}</DefaultText>

                        <DefaultText style={styles.heading}>Email</DefaultText>
                        <DefaultText style={styles.subtitle}>{email}</DefaultText>
                        <DefaultText style={styles.heading}>Username</DefaultText>
                        <DefaultText style={styles.subtitle}>{data?.preferredUsername || data?.cognitoUsername || ''}</DefaultText>
                    </>}
                <View style={styles.buttonContainer}>
                    {allowEdit ?
                        <>
                            <TouchableOpacity style={styles.joinButton} onPress={handleSubmit} >
                                <DefaultText style={styles.buttonText}>Save</DefaultText>
                            </TouchableOpacity></> : <>

                            <TouchableOpacity style={styles.joinButton} onPress={() => setAllowEdit(!allowEdit)}>
                                <DefaultText style={styles.buttonText}>Edit</DefaultText>
                            </TouchableOpacity>

                        </>
                    }
                </View>
                <DefaultButton style={styles.updatePassword} onPress={navigateToPasswordChange}>
                    Update password
                </DefaultButton>
            </View>
        </View>
    )
}

export default ProfileForm

const styles = StyleSheet.create({
    heading: {
        fontSize: 17,
        fontWeight: 'bold',
        paddingTop: 20,

    },
    inputContainer: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    subtitle: {
        color: DecidaColors.Green,
        marginBottom: 15,
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    joinButton: {
        backgroundColor: DecidaColors.Green,
        height: 50,
        width: 150,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    buttonText: {
        color: DecidaColors.White

    },
    emailNote: {
        width: 330,
        color: DecidaColors.Black,
        fontSize: 15,
        textAlign: 'center',
    },
    updatePassword: {
        marginTop: 50,
    },


})