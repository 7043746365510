import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan, Line } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconSad7Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 7)
    }

    const onPressLevel2 = {
        [prop]: () => onPress(2 / 7)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(3 / 7)
    }

    const onPressLevel4 = {
        [prop]: () => onPress(4 / 7)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(5 / 7)
    }

    const onPressLevel6 = {
        [prop]: () => onPress(6 / 7)
    }

    const onPressLevel7 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg
            viewBox="0 0 277.135 153.505"
        >
            <G data-name="Group 9559">
                <G data-name="Group disappointed" {...onPressLevel1}>
                    <Path
                        data-name="Path 7758"
                        d="M0 0l52.6-.176.043-28.007z"
                        transform="translate(-183 -101.504) translate(183.711 254.884)"
                        fill="#e0ecf6"
                    />
                    <Path
                        data-name="Path 7759"
                        d="M183.711 254.884l52.641-28.183-.041 28.007z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-183 -101.504)"
                    />
                    <Path
                        data-name="Path 7793"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h42.606a2.834 2.834 0 002.834-2.837V2.835A2.835 2.835 0 0042.606 0z"
                        transform="translate(-183 -101.504) translate(192.951 244.614)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7794"
                        d="M192.951 244.614a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h42.606a2.834 2.834 0 002.834-2.837v-3.628a2.835 2.835 0 00-2.834-2.835z"
                        fill="none"
                        stroke="#3685c6"
                        strokeWidth={0.25}
                        transform="translate(-183 -101.504)"
                    />
                    <Text
                        transform="translate(-183 -101.504) translate(192.729 251.544)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Disappointed"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7799"
                        d="M183.236 250.764l47.913-25.76"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-183 -101.504)"
                    />
                    <Path
                        data-name="Path 7801"
                        d="M0 0l-7.385 1.073 2.417 4.495z"
                        transform="translate(-183 -101.504) translate(235.961 222.417)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group vulnerable" {...onPressLevel3}>
                    <Path
                        data-name="Path 7764"
                        d="M0 0l.177 49.336 34.423-.022.043-68.237z"
                        transform="translate(-183 -101.504) translate(276.256 205.434)"
                        fill="#a4c8e5"
                    />
                    <Path
                        data-name="Path 7765"
                        d="M276.256 205.434l34.649-18.923-.043 68.236-34.428.023z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-183 -101.504)"
                    />
                    <Path
                        data-name="Path 7781"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h31.357a2.835 2.835 0 002.835-2.834V2.835A2.836 2.836 0 0031.357 0z"
                        transform="translate(-183 -101.504) translate(276.714 218.989)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7782"
                        d="M276.714 218.989a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h31.357a2.835 2.835 0 002.835-2.834v-3.631a2.836 2.836 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#3685c6"
                        strokeWidth={0.25}
                        transform="translate(-183 -101.504)"
                    />
                    <Text
                        transform="translate(-185 -101.504) translate(275.172 226.342)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"V"}
                        </TSpan>
                    </Text>
                    <Text
                        transform="translate(-185 -101.504) translate(279.82 226.342)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"ulnerable"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7804"
                        d="M275.646 201.441l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-183 -101.504)"
                    />
                    <Path
                        data-name="Path 7805"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-183 -101.504) translate(310.749 182.292)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group discouraged" {...onPressLevel2}>
                    <Path
                        data-name="Path 7761"
                        d="M0 0l-.112 29.327 34.82-.022-.126-47.734z"
                        transform="translate(-183 -101.504) translate(238.921 225.341)"
                        fill="#c2daee"
                    />
                    <Path
                        data-name="Path 7762"
                        d="M238.921 225.341l34.583-18.429.125 47.734-34.819.022z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-183 -101.504)"
                    />
                    <Path
                        data-name="Path 7778"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h37.367A2.834 2.834 0 0040.2 6.463V2.835A2.835 2.835 0 0037.367 0z"
                        transform="translate(-183 -101.504) translate(237.886 231.796)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7779"
                        d="M237.886 231.796a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h37.367a2.834 2.834 0 002.833-2.837v-3.628a2.835 2.835 0 00-2.833-2.835z"
                        fill="none"
                        stroke="#3685c6"
                        strokeWidth={0.25}
                        transform="translate(-183 -101.504)"
                    />
                    <Text
                        transform="translate(-183 -101.504) translate(235.9 239.43)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Discouraged"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7802"
                        d="M238.229 221.283l30.053-16.126"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-183 -101.504)"
                    />
                    <Path
                        data-name="Path 7803"
                        d="M0 0l-7.385 1.066 2.413 4.5z"
                        transform="translate(-183 -101.504) translate(273.095 202.575)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group dejected" {...onPressLevel4}>
                    <Path
                        data-name="Path 7767"
                        d="M0 0l.05 69.611 34.82-.022-.082-87.9z"
                        transform="translate(-183 -101.504) translate(313.311 185.159)"
                        fill="#86b6dd"
                    />
                    <Path
                        data-name="Path 7768"
                        d="M313.311 185.159l34.787-18.315.083 87.9-34.819.026z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-183 -101.504)"
                    />
                    <Path
                        data-name="Path 7796"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h24.99a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0024.99 0z"
                        transform="translate(-183 -101.504) translate(318.251 206.183)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7797"
                        d="M318.251 206.183a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h24.99a2.834 2.834 0 002.834-2.834v-3.631a2.835 2.835 0 00-2.834-2.835z"
                        fill="none"
                        stroke="#3685c6"
                        strokeWidth={0.25}
                        transform="translate(-183 -101.504)"
                    />
                    <Text
                        transform="translate(-183 -101.504) translate(316.529 213.701)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Dejected"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7806"
                        d="M312.496 181.031l30.307-16.533"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-183 -101.504)"
                    />
                    <Path
                        data-name="Path 7807"
                        d="M0 0l-7.378 1.117L-4.934 5.6z"
                        transform="translate(-183 -101.504) translate(347.6 161.883)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group hopeless" {...onPressLevel5}>
                    <Path
                        data-name="Path 7770"
                        d="M0 0l-.076 89.5 34.428-.022-.082-107.69z"
                        transform="translate(-183 -101.504) translate(351.104 165.267)"
                        fill="#68a4d4"
                    />
                    <Path
                        data-name="Path 7771"
                        d="M351.104 165.266l34.27-18.212.082 107.694-34.428.018z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-183 -101.504)"
                    />
                    <Path
                        data-name="Path 7787"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h26.3a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0026.3 0z"
                        transform="translate(-183 -101.504) translate(355.092 193.376)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7788"
                        d="M355.092 193.376a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h26.3a2.834 2.834 0 002.835-2.834v-3.631a2.835 2.835 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#3685c6"
                        strokeWidth={0.25}
                        transform="translate(-183 -101.504)"
                    />
                    <Text
                        transform="translate(-183 -101.504) translate(353.445 200.327)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Hopeless"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7808"
                        d="M349.914 161.883l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-183 -101.504)"
                    />
                    <Path
                        data-name="Path 7809"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-183 -101.504) translate(385.017 142.733)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group grieving" {...onPressLevel6}>
                    <Path
                        data-name="Path 7773"
                        d="M0 0l.051 109.625 34.819-.036-.082-128.582z"
                        transform="translate(-183 -101.504) translate(387.808 145.146)"
                        fill="#5498cf"
                    />
                    <Path
                        data-name="Path 7774"
                        d="M387.808 145.146l34.788-18.992.082 128.582-34.819.035z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-183 -101.504)"
                    />
                    <Path
                        data-name="Path 7790"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h26.666A2.834 2.834 0 0029.5 6.463V2.835A2.835 2.835 0 0026.666 0z"
                        transform="translate(-183 -101.504) translate(391.91 180.569)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7791"
                        d="M391.91 180.569a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h26.666a2.834 2.834 0 002.834-2.837v-3.628a2.835 2.835 0 00-2.834-2.835z"
                        fill="none"
                        stroke="#3685c6"
                        strokeWidth={0.25}
                        transform="translate(-183 -101.504)"
                    />
                    <Text
                        transform="translate(-183 -101.504) translate(391.723 187.929)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Grieving"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7810"
                        d="M387.048 141.063l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-183 -101.504)"
                    />
                    <Path
                        data-name="Path 7811"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-183 -101.504) translate(422.15 121.914)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group despair" {...onPressLevel7}>
                    <Path
                        data-name="Path 7776"
                        d="M0 0l-.076 130.035L34.352 130l-.38-147.858z"
                        transform="translate(-183 -101.504) translate(425.657 124.735)"
                        fill="#3685c6"
                    />
                    <Path
                        data-name="Path 7777"
                        d="M425.657 124.736l33.973-17.858.379 147.858-34.428.035z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-183 -101.504)"
                    />
                    <Path
                        data-name="Path 7784"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h21.782a2.835 2.835 0 002.835-2.834V2.835A2.836 2.836 0 0021.782 0z"
                        transform="translate(-183 -101.504) translate(431.904 167.762)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7785"
                        d="M431.904 167.762a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h21.782a2.835 2.835 0 002.835-2.834v-3.631a2.836 2.836 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#3685c6"
                        strokeWidth={0.25}
                        transform="translate(-183 -101.504)"
                    />
                    <Text
                        transform="translate(-183 -101.504) translate(430.378 175.36)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Despair"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7812"
                        d="M425.032 120.653l30.307-16.533"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-183 -101.504)"
                    />
                    <Path
                        data-name="Path 7813"
                        d="M0 0l-7.377 1.118L-4.934 5.6 0 0z"
                        transform="translate(-183 -101.504) translate(460.135 101.504)"
                        fill="#6f7175"
                    />
                </G>
            </G>
        </Svg>
    )
}