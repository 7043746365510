import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import { Pressable, StyleSheet, View } from 'react-native';
import { CheckInSession, Class, ClearClassStudentCheckInAlertsMutation, ClearClassStudentCheckInAlertsMutationVariables, EatingStatus, GetStudentClassByClassIdQueryVariables, ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables, ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables, StudentCheckIn, StudentClassApprovalStatus } from '../../common/API';
import { listCheckInSessionsByClassId, listStudentCheckInsByCheckInSessionId } from '../../common/graphql/queries';
import { isDefined } from '../../common/is-defined';
import { convertStudentCheckInCacheCodeToCacheObject, StudentCheckInCacheObject } from '../../common/student-check-in-cache';
import { calculateMaxAvatarContainerHeightWithUsername } from '../common/calculate-max-avatar-container-height-with-username';
import { rvPrintMode } from '../common/export-to-pdf-button';
import { studentCheckInHasFlag } from '../../common/student-check-in-has-flag';
import { useCalculateExtraItemBasedOnScreenWidth } from '../common/use-calculate-extra-item-based-on-screen-width';
import { Emotion, RequestForChatStatus } from '../models';
import { AVATARS_WRAPPER_CONTANER_PADDING, AVATAR_CONTAINER_PADDING, AVATAR_WRAPPER_WIDTH } from './teacher-consts';
import { ListStudentClassAndStudentDataByClassIdCustomQuery, StudentClassAndStudentDataFieldsTypes, listStudentClassAndStudentDataByClassIdCustomQuery } from './teacher-graphql-scripts';
import { StudentAndStudentClassAndStudentCheckIn } from './teacher-stats-last-period-and-requested-chat';
import TeacherStatusStudentAvatarCheckInStatus from './teacher-stats-student-avatar-checkin-status';
import { DefaultText } from '../common/default-text';
import { CommonStyles } from '../common/const';
import { DecidaColors } from '../../common/decida-colors';
import { rvIsLoading } from '../common/loading';
import { showAlert } from '../common/universal-alert';
import { clearClassStudentCheckInAlerts } from '../../common/graphql/mutations';

type Props = {
    currentClass?: Class
    onAvatarPress?: (studentCheckIn: StudentAndStudentClassAndStudentCheckIn) => void;
    isDashboard?: boolean
}

const emotionSortOrder = {
    [Emotion.ANGRY]: 0,
    [Emotion.SAD]: 1,
    [Emotion.SCARED]: 2,
    [Emotion.ANXIOUS]: 3,
    [Emotion.EXCITED]: 4,
    [Emotion.HAPPY]: 5,
    undefined: 6,
};

const requestForChatStatusSortOrder = {
    null: 0,
    [RequestForChatStatus.NO]: 1,
    [RequestForChatStatus.NOT_YET]: 2,
    [RequestForChatStatus.YES]: 3,
}

const TeacherStatsLastPeriodAndRequestedChatItems = ({ currentClass, onAvatarPress, isDashboard }: Props) => {

    const { data: studentClassResponse } = useQuery<ListStudentClassAndStudentDataByClassIdCustomQuery, GetStudentClassByClassIdQueryVariables>(gql`${listStudentClassAndStudentDataByClassIdCustomQuery}`, { variables: { classID: currentClass?.id || "" }, skip: !currentClass })
    const activeStudentClasses = studentClassResponse?.getStudentClassByClassId?.items.filter((item) => item?._deleted !== true && item?.status === StudentClassApprovalStatus.APPROVED) || [] as StudentClassAndStudentDataFieldsTypes[]

    const { data: checkInSessionsResponse } = useQuery<ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables>(gql`${listCheckInSessionsByClassId}`, { variables: { classID: currentClass?.id || "", limit: 5000 }, skip: !currentClass })
    const checkInSessions = _.orderBy(checkInSessionsResponse?.listCheckInSessionsByClassId?.items.filter((item) => item?._deleted !== true), "updatedAt", "desc") as CheckInSession[]

    const lastCheckinSession = checkInSessions[0]

    const { data: studentCheckInsResponse } = useQuery<ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables>(gql`${listStudentCheckInsByCheckInSessionId}`, { variables: { checkinsessionID: lastCheckinSession?.id, limit: 5000 }, skip: !lastCheckinSession })
    const studentCheckIns = studentCheckInsResponse?.listStudentCheckInsByCheckInSessionId?.items

    const [clearAllStudentCheckInAlerts] = useMutation<ClearClassStudentCheckInAlertsMutation, ClearClassStudentCheckInAlertsMutationVariables>(gql`${clearClassStudentCheckInAlerts}`,)

    const isGoMode = currentClass?.goMode || false

    const activeStudentClassesWithStudetCheckInData = activeStudentClasses.filter(isDefined)
        .map((studentClassAndStudentData) => {
            const currentStudentCheckIn = isGoMode ?
                studentClassAndStudentData.studentCheckIns && studentClassAndStudentData.studentCheckIns.length > 0 && convertStudentCheckInCacheCodeToCacheObject(studentClassAndStudentData.studentCheckIns[studentClassAndStudentData.studentCheckIns.length - 1]) || undefined :
                studentCheckIns?.find((studentCheckIn) => studentCheckIn?.studentID === studentClassAndStudentData.studentID) as StudentCheckIn

            return {
                ...studentClassAndStudentData,
                studentCheckIn: currentStudentCheckIn
            }
        })

    let filteredData: StudentClassAndStudentDataFieldsTypes[] = []

    if (isDashboard) {

        // in the dashboard
        // need to shows any redflagged studentCheckIn
        // Request for chat status YES / Not Yet
        filteredData = _.orderBy(
            activeStudentClassesWithStudetCheckInData
                .filter((studentClassAndStudentData) => {
                    return studentClassAndStudentData?.studentCheckIn && !studentClassAndStudentData.studentCheckIn.chatCleared && (
                        studentClassAndStudentData.studentCheckIn.requestForChat === RequestForChatStatus.YES
                        || studentClassAndStudentData.studentCheckIn.requestForChat === RequestForChatStatus.NOT_YET
                        || studentCheckInHasFlag(studentClassAndStudentData.studentCheckIn)
                        || studentClassAndStudentData.studentCheckIn.eating === EatingStatus.NO
                    )
                }),
            [
                (item) => item?.student?.firstName?.toLocaleLowerCase(),
                (item) => item?.student?.lastName?.toLocaleLowerCase(),
            ],
            [
                "asc", // firstName order
                "asc", // lastName order
            ]
        )
    } else {
        filteredData = _.orderBy(activeStudentClassesWithStudetCheckInData,
            [
                (item) => emotionSortOrder[item.studentCheckIn?.emotion || "undefined"],
                (item) => item.studentCheckIn?.emotionIntensityPercentage || 0,
                (item) => requestForChatStatusSortOrder[item.studentCheckIn?.requestForChat || "null"],
                (item) => item.studentCheckIn?.absence,
                (item) => item?.student?.firstName?.toLocaleLowerCase(),
                (item) => item?.student?.lastName?.toLocaleLowerCase(),
            ],
            [
                "asc", // emotion order
                "desc", // intensity level
                "desc", // request for chat order
                "asc", // absence order
                "asc", // firstName order
                "asc", // lastName order
            ]
        )
    }

    const extraItems = useCalculateExtraItemBasedOnScreenWidth(
        AVATAR_WRAPPER_WIDTH,
        AVATARS_WRAPPER_CONTANER_PADDING,
        filteredData?.length
    );
    const longestCognitoUsernameLengthLetter = filteredData.reduce((maxLength, item) => {
        const usernameLength = item?.student?.cognitoUsername?.length || 0;
        return Math.max(maxLength, Number(usernameLength));
    }, 0);

    const isPrintMode = useReactiveVar(rvPrintMode)


    const toggleClearAllRequestChatPrompt = () => {
        showAlert({
            message: "Are you sure you want to clear all alert/s for this class?",
            title: "Clear alerts?",
            leftButtonText: "Ok",
            rightButtonText: "Cancel",
            onLeftButtonPress: handleClearAllAlertOnClasss
        })
    }

    const handleClearAllAlertOnClasss = async () => {
        try {

            rvIsLoading(true)
            await clearAllStudentCheckInAlerts({
                variables: {
                    input: {
                        classID: currentClass?.id || ""
                    }
                }
            })
            rvIsLoading(false)
        } catch (error) {

        }
    }


    return (
        <>
            {(filteredData.length > 0 && isDashboard) && (
                <View>
                    <Pressable onPress={toggleClearAllRequestChatPrompt} style={[CommonStyles.innerShadow, CommonStyles.buttonRounded, styles.button]}>
                        <DefaultText style={{ fontSize: 18, color: DecidaColors.AppleSystemGray2Dark }}>Clear all alerts</DefaultText>
                    </Pressable>
                </View>
            )}
            <View style={[isPrintMode ? styles.avatarsWrapperPrint : styles.avatarsWrapper, { justifyContent: !isDashboard && !isPrintMode ? "center" : "flex-start", margin: currentClass?.goMode ? 20 : undefined }]}>
                {filteredData?.map((studentClass, index) => {
                    return (
                        <View key={studentClass?.studentID} style={{ minHeight: calculateMaxAvatarContainerHeightWithUsername(longestCognitoUsernameLengthLetter, AVATAR_WRAPPER_WIDTH), zIndex: -index }}>
                            <TeacherStatusStudentAvatarCheckInStatus
                                checkInSessions={checkInSessions}
                                studentClass={studentClass}
                                isGoMode={isGoMode}
                                callOnAvatarPress={onAvatarPress}
                            />
                        </View>
                    );
                })}
                {extraItems > 0
                    ? Array(extraItems)
                        .fill(0)
                        .map((_, index) => (
                            <View key={index} style={styles.avatarContainer} />
                        ))
                    : null}
            </View>
        </>

    )

}
export default TeacherStatsLastPeriodAndRequestedChatItems

const styles = StyleSheet.create({
    avatarsWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    avatarsWrapperPrint: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        transform: [{ scale: 0.8 }],
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    avatarContainer: {
        padding: AVATAR_CONTAINER_PADDING,
        width: AVATAR_WRAPPER_WIDTH,
        alignItems: "center",
        marginBottom: 20,
        justifyContent: 'center'
    },
    button: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20,
        width: 'auto',
        alignSelf: 'flex-end',
        backgroundColor: DecidaColors.White,
        marginBottom: 20,
        marginRight: 10
    },
})

