import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { Entypo } from '@expo/vector-icons'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import _ from 'lodash'
import { useState } from 'react'
import { SafeAreaView, ScrollView, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native'
import { CreateStudentCheckInMutation, CreateStudentCheckInMutationVariables, GetClassQuery, GetClassQueryVariables, GetStudentClassByClassIdQueryVariables, ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables, ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables, UpdateStudentCheckInMutation, UpdateStudentCheckInMutationVariables } from '../../common/API'
import { CheckInUserGroup } from '../../common/constants'
import { DecidaColors } from "../../common/decida-colors"
import { createStudentCheckIn, updateStudentCheckIn } from '../../common/graphql/mutations'
import { getClass, listCheckInSessionsByClassId, listStudentCheckInsByCheckInSessionId } from '../../common/graphql/queries'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'
import { rvCheckInState } from '../check-in/check-in-state'
import ButtonSecureScreen from '../common/button-secure-screen'
import ButtonSessionStudent from '../common/button-session-student'
import { rvCurrentClass, rvTeacherAbsenceMode } from '../common/common-state'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { isGoModeCheckInOpen } from '../common/is-go-mode-check-in-open'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { showAbsenceWarnAlert, showOfflineAlert } from '../common/universal-alert'
import { useCalculateExtraItemBasedOnScreenWidth } from '../common/use-calculate-extra-item-based-on-screen-width'
import useConnectivity from '../common/use-connectivity'
import { useResponsive } from '../common/use-responsive'
import ViewResponsive from '../common/view-responsive'
import { avatars } from '../icon/avatar/avatars'
import IconAbsence from '../icon/icon-absence'
import IconAvatarAbsence from '../icon/icon-avatar-absence'
import { getLockDerviceRv, setLockDevice } from '../login/login-state'
import { StudentClassApprovalStatus, StudentStatus } from '../models'
import { ListStudentClassAndStudentDataByClassIdCustomQuery, listStudentClassAndStudentDataByClassIdCustomQuery } from './teacher-graphql-scripts'
import { TeacherDrawerNavigatorPamarList } from './teacher-route-param-types'

const itemSize = 100

type StudentAbsenceData = {
    studentID: string
    studentCheckInId: string
    absence: boolean
    checkinsessionID: string
    _version?: number
}

export const TeacherCheckInStart = () => {
    const { params } = useRoute<RouteProp<CheckInNavigatorParamList, "CheckInStart">>()

    const [createStudentCheckInMutation] = useMutation<CreateStudentCheckInMutation, CreateStudentCheckInMutationVariables>(gql`${createStudentCheckIn}`)
    const [updateStudentCheckInMutation] = useMutation<UpdateStudentCheckInMutation, UpdateStudentCheckInMutationVariables>(gql`${updateStudentCheckIn}`)
    const { data: classResponse } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: params?.classID || "" }, skip: !params?.classID })
    const currentClassFromRv = useReactiveVar(rvCurrentClass)

    const currentClass = params?.classID ? classResponse?.getClass : currentClassFromRv // no classID params it will be class login

    const { data: studentClassAndStudentDataResponse } = useQuery<ListStudentClassAndStudentDataByClassIdCustomQuery, GetStudentClassByClassIdQueryVariables>(gql`${listStudentClassAndStudentDataByClassIdCustomQuery}`, { variables: { classID: params?.classID || "", limit: 5000 }, skip: !params?.classID })
    const activeStudentClasses = studentClassAndStudentDataResponse?.getStudentClassByClassId?.items.filter((item) => item?._deleted !== true && item?.status === StudentClassApprovalStatus.APPROVED)

    const { data: checkInSessionsResponse, error } = useQuery<ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables>(gql`${listCheckInSessionsByClassId}`, { variables: { classID: params?.classID || "", limit: 5000 }, skip: !params?.classID })

    const activeCheckInSessions = checkInSessionsResponse?.listCheckInSessionsByClassId?.items.filter((item) => item?._deleted !== true && item?.isActive)
    const currentCheckIn = _.orderBy(activeCheckInSessions, "updatedAt", "desc")[0]

    const { data: studentCheckInResponse } = useQuery<ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables>(gql`${listStudentCheckInsByCheckInSessionId}`, { variables: { checkinsessionID: currentCheckIn?.id || "", limit: 5000 }, skip: !currentCheckIn })

    const studentCheckIns = studentCheckInResponse?.listStudentCheckInsByCheckInSessionId?.items.filter((item) => item?._deleted !== true) || []
    const absenceMode = useReactiveVar(rvTeacherAbsenceMode)

    const { onlineRef } = useConnectivity()
    const [selectedAbsence, setSelectedAbsence] = useState<StudentAbsenceData[]>([])

    const { scaleRatio, isLargeScreen, width, lowRes } = useResponsive();
    const transformByRatioStyle: ViewStyle = { transform: [{ scale: lowRes ? scaleRatio : isLargeScreen ? Math.min(1, width / 1100) : 1 }], height: lowRes ? '25%' : undefined, flexDirection: isLargeScreen ? 'row' : 'column' };

    const { navigate } = useNavigation<StackNavigationProp<CheckInNavigatorParamList & TeacherDrawerNavigatorPamarList>>()

    const activeStudentClassesAndStudentData = activeStudentClasses?.filter(item => item?.student !== undefined && item?.status === StudentClassApprovalStatus.APPROVED) || []

    const extraItems = useCalculateExtraItemBasedOnScreenWidth(itemSize, 0, activeStudentClassesAndStudentData?.filter(studentClassAndStudent => studentClassAndStudent !== undefined && studentClassAndStudent?.student?.status === StudentStatus.ACTIVE).length)


    const itemContainerStyle = [styles.student, { minWidth: itemSize, maxWidth: itemSize }]

    const lockDevice = () => {
        if (absenceMode) {
            showAbsenceWarnAlert({
                onLeftButtonPress: () => {
                    rvTeacherAbsenceMode(false)
                    setLockDevice(true, params?.classID || "")
                }
            })
            return
        }
        setLockDevice(true, params?.classID || "")
    }

    const unlockDevice = () => {
        navigate(ScreenNames.UnlockTeacherDevice, { classID: params?.classID })
    }

    const denyAccess = useReactiveVar(getLockDerviceRv())

    const sortedStudents = _.sortBy(activeStudentClassesAndStudentData.filter(studentClassAndStudent => studentClassAndStudent?.status === StudentClassApprovalStatus.APPROVED).map(studentClassAndStudent => ({
        ...studentClassAndStudent,
        checkedIn: studentCheckIns.some(studentCheckIn => studentCheckIn?.studentID === studentClassAndStudent?.student?.id && !studentCheckIn?.absence && studentCheckIn?.tiredness),
        studentCheckInId: studentCheckIns.find(studentCheckIn => studentCheckIn?.studentID === studentClassAndStudent?.student?.id)?.id,
        studentCheckInVersion: studentCheckIns.find(studentCheckIn => studentCheckIn?.studentID === studentClassAndStudent?.student?.id)?._version,
        absence: studentCheckIns.some(studentCheckIn => studentCheckIn?.studentID === studentClassAndStudent?.student?.id && studentCheckIn?.absence),

    })), sortBy => [sortBy.checkedIn, sortBy.absence, sortBy.student?.nickname])


    if (!currentClass) {
        return null
    }

    const handleAbsenceMode = async () => {
        rvTeacherAbsenceMode(!absenceMode)
        if (absenceMode && selectedAbsence.length) {
            await Promise.all(selectedAbsence.map(async (studentAbsence: StudentAbsenceData) => {
                if (studentAbsence.studentCheckInId) {
                    await updateStudentCheckInMutation({
                        variables: {
                            input: {
                                id: studentAbsence.studentCheckInId,
                                absence: !studentAbsence.absence,
                                _version: studentAbsence._version,
                            }
                        }
                    })
                } else {
                    await createStudentCheckInMutation({
                        variables: {
                            input: {
                                studentID: studentAbsence.studentID,
                                classID: params?.classID || "",
                                checkinsessionID: studentAbsence?.checkinsessionID || "",
                                absence: true,
                            }
                        }
                    })
                }
            }))
            setSelectedAbsence([])
            return
        }
    }

    return (
        <Page style={styles.page}>
            {(currentCheckIn || currentClass.goMode === true && isGoModeCheckInOpen()) &&
                <>
                    <ScrollView testID='student-avatar-container' contentContainerStyle={styles.studentList}>
                        {
                            sortedStudents.map(student => {
                                const Avatar = student.student ?
                                    avatars[student.student?.avatar as keyof typeof avatars] :
                                    avatars["Default"]
                                const handleAvatarPress = () => {

                                    if (!onlineRef.current) return showOfflineAlert(handleAvatarPress)

                                    if (absenceMode) {
                                        let newSelected = [...selectedAbsence]
                                        const studentSelected = selectedAbsence.find((data) => data.studentID === student.student?.id)
                                        if (studentSelected) {
                                            newSelected = newSelected.filter((data) => data.studentID !== student.student?.id)
                                        } else {
                                            newSelected.push({
                                                studentID: student.student?.id || "",
                                                studentCheckInId: student.studentCheckInId || "",
                                                absence: student.absence,
                                                checkinsessionID: currentCheckIn?.id || "",
                                                _version: student.studentCheckInVersion
                                            })
                                        }

                                        setSelectedAbsence(newSelected)
                                        return
                                    }
                                    rvCheckInState({
                                        studentID: student.student?.id,
                                        checkinsessionID: currentCheckIn?.id
                                    })
                                    navigate(ScreenNames.TeacherClassMain, { screen: ScreenNames.CheckInTirednessScale, params: { classID: currentClass?.id, user: CheckInUserGroup.Student } })
                                }
                                const isSelected = selectedAbsence.some((data) => data.studentID === student.student?.id)

                                return (
                                    <TouchableOpacity
                                        key={student.studentID}
                                        onPress={handleAvatarPress}
                                    >
                                        <View
                                            testID='student-avatar-checkin'
                                            style={
                                                currentClass.goMode ?
                                                    styles.student
                                                    : student.checkedIn || student.absence || absenceMode && isSelected ?
                                                        [
                                                            styles.student,
                                                            styles.checkedInStudent
                                                        ]
                                                        : absenceMode && !isSelected ?
                                                            [
                                                                styles.student,
                                                                styles.absenceMode
                                                            ]
                                                            : styles.student
                                            }
                                        >
                                            <View style={styles.avatar}>
                                                <Avatar />
                                                {((student.absence && !isSelected) ||
                                                    (!student.absence && isSelected)) && (
                                                        <View style={styles.avatarAbsence}>
                                                            <IconAvatarAbsence />
                                                        </View>
                                                    )}
                                            </View>
                                            <DefaultText style={styles.nickname}>
                                                {student.student?.nickname}
                                            </DefaultText>
                                        </View>
                                    </TouchableOpacity>
                                );
                            })
                        }
                        {
                            extraItems > 0 ? Array(extraItems).fill(0).map((_, index) => <View key={index} style={itemContainerStyle} />) : null
                        }
                    </ScrollView>
                    <SafeAreaView style={[transformByRatioStyle]}>
                        {denyAccess ?
                            <>
                                <DefaultText style={{ textAlign: 'center' }}>Device is currently locked.</DefaultText>
                                <DefaultButton style={[styles.button, styles.secureScreenButton]} onPress={unlockDevice}>
                                    <Entypo name="lock-open" size={44} color={DecidaColors.Red} />
                                    <View style={styles.textContainer}>
                                        <DefaultText style={styles.buttonText}>Unlock</DefaultText>
                                    </View>
                                </DefaultButton>

                            </>
                            :
                            <>
                                {currentClass.goMode !== true ?
                                    <>
                                        <ButtonSessionStudent />
                                        <ButtonSecureScreen classID={params?.classID} />
                                        <DefaultButton style={[styles.button, styles.absenceStudentButton]} onPress={handleAbsenceMode}>
                                            <IconAbsence />
                                            <View style={styles.textContainer}>
                                                <DefaultText style={styles.buttonText}>{absenceMode ? "Done" : "Select absent students"}</DefaultText>
                                            </View>
                                        </DefaultButton>
                                    </> : (
                                        <ButtonSecureScreen classID={params?.classID} />
                                    )
                                }
                            </>
                        }
                    </SafeAreaView>
                </> ||
                <ViewResponsive>
                    {currentClass.goMode ?
                        <View style={styles.noCheckin}>
                            <DefaultText style={styles.noCheckinText}>GO Group check in only available from 8AM to 4PM.</DefaultText>
                        </View>
                        : <>
                            <View style={styles.noCheckin}>
                                <DefaultText style={styles.noCheckinText}>There are no check in sessions currently active.</DefaultText>
                                <DefaultText style={styles.noCheckinText}>Please select the button below to start a check in session.</DefaultText>
                            </View>
                            <ButtonSessionStudent />
                        </>
                    }
                </ViewResponsive>
            }
        </Page>
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
    },
    studentList: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'flex-start',
    },
    student: {
        justifyContent: 'center',
        alignItems: 'center',
        width: itemSize,
        padding: 10,
    } as ViewStyle,
    checkedInStudent: {
        opacity: 0.5,
    },
    avatar: {
        width: 70,
        height: 70,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noCheckin: {
        borderWidth: 2,
        borderRadius: 5,
        borderColor: DecidaColors.Red,
        alignContent: 'center',
        justifyContent: 'center',
        padding: 5,
        margin: 5,
    },
    noCheckinText: {
        justifyContent: 'center',
        textAlign: 'center',
        padding: 5,
        color: DecidaColors.Black,
    },
    nickname: {
        width: itemSize - 20,
        textAlign: 'center',
    } as TextStyle,
    button: {
        height: 80,
        borderRadius: 5,
        borderWidth: 2,
        backgroundColor: DecidaColors.White,
        borderColor: DecidaColors.Green,
        paddingLeft: 40,
        paddingRight: 40,
        margin: 10,

    } as ViewStyle,
    textContainer: {
        width: 180,
    },
    buttonText: {
        color: DecidaColors.Black,
        paddingLeft: 10,
        fontWeight: '700',
        textAlign: 'center',
    },

    secureScreenButton: {
        borderColor: DecidaColors.Red,
    } as ViewStyle,

    absenceStudentButton: {
        borderColor: DecidaColors.Gray,
    } as ViewStyle,

    avatarAbsence: {
        position: 'absolute',
        bottom: 0,
        right: 0
    },

    absenceMode: {
        opacity: 0.8,
    } as ViewStyle,

})