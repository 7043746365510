import { Linking, StyleSheet, Text, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { DefaultTextWithoutArrowsItallic } from '../common/default-text-without-arrows-itallic'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { GettingStartedLessonPlan1 } from './src/getting-started-lesson-plan1'
import { GettingStartedLessonPlan2 } from './src/getting-started-lesson-plan2'
import { ToolsTheLittleBookOfBigEmotions } from '../tools/cards/the-little-book-of-big-emotions'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import SwitchCardVideo from '../../common/switch-card-video'

const emtotionalIntelegenceVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/Y7m9eNoB3NU",
    title: ""
}

export const SwitchesLessonPlansGettingStarted: CardTemplate = {
    cardId: "8121ff41-6a68-4608-becc-8d2f84bbd345",
    title: 'Introduction to Switch4Schools',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define emotional intelligence</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Describe the process of managing emotional reactivity</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• List six key emotions and their main characteristics</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice the Switch4Schools check in process</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Locate relevant switch activities</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>• History of emotion - Refer to the Little Book of Big emotions and explain that [motion] comes from the Latin “motere”, which means ‘to move’ or ‘energy in motion’. Roman troops used it to talk about troop movements. [e] simply implies that motion is away from something. So e-motion is to change your state, to be moved from the current situation to something else. In around 1579, French academics evolved motere into émouvoir, which was a catch-all phrase to talk about our internal energy states, passion, sentiments and affections (to move, to stir up, to affect, to disturb or to arouse). For the first time they had a way to conceptually look at what was going on inside us.</DefaultText>
                        <View style={CommonStyles.lessonPlanImageContainer}>
                            <GettingStartedLessonPlan1 />
                        </View>
                        <DefaultText style={CommonStyles.mainLessonText}>• What are the different types of emotion?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to and reinforce previous foundational work on different emotions/zones/feelings as relevant.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to brainstorm the different types of emotion. Looks for:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>i. Anger</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>ii. Fear</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>iii. Anxiety</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>iv. Excitement</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>v. Happy</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>vi. Sad</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>vii. Surprise</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>viii. Disgust</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Physiology of Emotion tool in the Switch4Schools app and explain to students how the different emotions present in the body.</DefaultText>
                        <View style={styles.notesContainer}>
                            <DefaultTextWithoutArrowsItallic textdata='Note that more specific activities that unpack each of the emotions can be found in the lesson plans attached to each of the switches. Feel free to leverage the available worksheets as appropriate.' />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦  Why energy isn’t an emotion: You do FEEL tired, but it’s important to separate tiredness from emotion. Tiredness is more like an amplifier of emotion because you have less energy to inhibit or manage the intensity of your emotions when you are exhausted. The more tired you are, the more likely you are to bounce between more extreme emotions. Sleep is a really, really good thing when it comes to managing your emotions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• What is emotional intelligence</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Hand brain tool in the Switch4Schools app</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>i. Start with the palm that controls their basic body functions. The thumb represents big feelings like anger or fear and lives within the palm in the emotive ape brain. The other fingers are the thinking brain, the ape tamer.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>ii. Show them how when they lift their fingers (“let the ape out of the cage”), their thinking brain is no longer connected to their emotions. That’s when they throw a tantrum or cry uncontrollably.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>iii. It’s as simple as curling their fingers over their thumb. Now they’ve caged their ape and their brain is fully engaged! They are embracing their emotions and listening to what their brain has to say about them. This is how we build healthier, more emotionally intelligent brains.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Dialing emotion up and down</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>i. Refer to the Emotion Wheel within the Little Book of Big Emotion, or Switch4Schools app.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>ii. Explain that within each of the big emotions are gradients of intensity. Ask students to articulate the difference between being slightly annoyed, compared to be being furious.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>iii. Depending on which level of the Emotion Wheel you intend to use, level 1, 3 or 5, display the relevant model and allow students to familiarise with the language and how to pronounce each of the emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>iv. Reinforce to students that mastering our emotions is the art of being able to dial up and down how we feel to stay in control of how we act (choosing the right intensity for the right context). Refer to the Little Book of Big Emotions for further detail.</DefaultText>

                        <View style={CommonStyles.lessonPlanImageContainer}>
                            <GettingStartedLessonPlan2 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Switches</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>i. Explain that switches are psychological interventions, strategies or activities that break the limbic cycle and “cage the ape”.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>ii. Refer to the Switch4Schools app and demonstrate how there are switches related to each of the emotions that provide instructions on activities you can do.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>iii. Choose a switch that is relevant and practice doing it together to demonstrate what to expect. Box Breathing might be a good one to start with.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>iv. Explain that over the coming weeks you will be going through each of the switches in more detail.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• How does Switch4Schools work?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ You will need to decide on your preferred rhythm and approach and then you can explain how it will work to the students. Demonstrate for students:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>i. That they will be able to select their avatar and nickname (incorporating any agreed rules or naming conventions you would like for your group).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>ii. When a session is open, they will be able to check in, answering the energy and emotion questions, and the follow up conversation request (as per your preferences).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>iii. That they can explore the switches, tools, activities and memes, even when a session isn’t open (if they have their own device).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Support the students to set up their account (reach out to the team at <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='support@switch4schools.com.au' link='mailto:support@switch4schools.com.au' /> if you have any issues).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to practice checking in. You may like to show the students the view you have for the stats page so they know you receive the information and will reference it to support them and manage the class appropriately.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allow students to explore the program and familiarise with the functionality either individually, in small groups or as a class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief with the students to ask what discoveries they made, or questions they might have.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students how and when the program will be used next.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Little Book of Big Emotions' link={ToolsTheLittleBookOfBigEmotions.openUrl || ""} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Switch4Schools app</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Daniel Goleman Introduces Emotional Intelligence -</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=Y7m9eNoB3NU' />
                        <SwitchCardVideo video={emtotionalIntelegenceVideo} />

                        <DefaultText style={CommonStyles.lessonPlanText}>The importance of emotional intelligence for student wellbeing -</DefaultText>
                        <DefaultTextWithLink text='https://www.switch4schools.com.au/post/the-importance-of-emotional-intelligence-forstudent-wellbeing' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Avoid losing it in the classroom -</DefaultText>
                        <DefaultTextWithLink text='https://www.switch4schools.com.au/post/avoid-going-apes-tin-the-classroom' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20,
    },
    mobileContainer: { width: '100%' },
    webContainer: {
        flex: 1,
        flexDirection: 'column',
        paddingBottom: "10%",
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    },
    textContaier: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    subText: {
        fontWeight: 'bold',
    },
    urlText: {
        color: DecidaColors.Sad,
        textDecorationLine: 'underline',
        fontWeight: 'bold',
        fontStyle: 'italic'
    },
    contentText: {
        // color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    nextButton: {
        marginTop: 20,
        width: 200,
        backgroundColor: CardColorScheme.SAD
    },
    imageContainer: {
        width: '100%',
        height: 150,
        marginVertical: 10
    },
    imagePosterContainer: {
        width: '100%',
        height: 550,
        marginVertical: 20,
    },
    whydoesthiswork: {
        width: 100,
        height: 100,
    },
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanTextSmallItalic: {
        textAlign: 'center',
        fontSize: 16,
        fontStyle: 'italic',
        color: DecidaColors.AppleSystemGray2Dark,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    mainSwitchesText: {
        paddingLeft: 10,
    },
    secondarySwitchesText: {
        paddingLeft: 40,
        paddingVertical: 5,
    },
    secondarySubSwitchesText: {
        paddingLeft: 80,
        paddingVertical: 5,
    },
    whydoesthisworktext: {
        padding: 10,
    },
    whydoesthisworkContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        flexDirection: 'row',
        marginHorizontal: 20,
        justifyContent: 'center',
    },
    notesContainer: {
        marginHorizontal: 20,
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        marginLeft: 20,
        marginBottom: 10,
        alignItems: 'flex-end'
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        marginVertical: 10,
    },
    lessonPlanCenteredTextContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 20,
        marginHorizontal: 10,
        width: '90%'
    },
    underLineText: {
        textDecorationLine: 'underline',
        color: 'blue',
        paddingLeft: 40,
        fontSize: 20,
    },
    tipsIconContainer: {
        marginTop: 20
    },
    imageBackgroundCotainer: {
        width: '100%',
        minWidth: 400,
        height: 300,
        marginVertical: 20,
        transform: [{ scale: 1.4 }],
        paddingTop: 40
    },
    imageBackgroundCotainerMobile: {
        width: '100%',
        height: 300,
        marginVertical: 20,
        paddingTop: 40
    },
    step1Container: {
        width: '100%',
        paddingHorizontal: 20
    },
    icon: {
        width: 80,
        height: 80,
        marginRight: 10
    },
    bottomContainer: {
        marginVertical: 20
    },
    noIndent: {
        paddingLeft: 0,
    },
    withIndent: {
        paddingLeft: 20
    }
})
