import { DimensionValue, StyleSheet, TextStyle, ViewStyle } from "react-native";
import { DefaultText } from "../../common/default-text";
import { openUrlWebView } from "../../web-view/open-url-web-view";
import { StyleProp } from "react-native";

interface Props {
  text: string
  link: string
  style?: StyleProp<ViewStyle | TextStyle>
  indent?: number
  fontSize?: number
}

const DefaultTextWithCustomLink = ({ text, link, style, indent, fontSize = 20 }: Props) => {
  const openURL = () => {
    openUrlWebView(link)
  }

  return (
    <DefaultText
      style={[styles.textStyle, { paddingLeft: indent, fontSize }, style]}
      onPress={openURL}
    >{text}
    </DefaultText>
  );
};

const styles = StyleSheet.create({
  textStyle: {
    textDecorationLine: "underline",
    color: "blue",
    paddingLeft: 40,
  },
});

export default DefaultTextWithCustomLink;
