import { gql, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { Entypo } from '@expo/vector-icons'
import { StackScreenProps } from '@react-navigation/stack'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { ScrollView, StyleSheet, TextInput, View } from 'react-native'
import { CreateStudentClassMutation, CreateStudentClassMutationVariables, GetClassQuery, GetClassQueryVariables, GetStudentClassByStudentIdQuery, GetStudentClassByStudentIdQueryVariables, Student, StudentClassApprovalStatus } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { createStudentClass } from '../../common/graphql/mutations'
import { getClass, getStudentClassByStudentId } from '../../common/graphql/queries'
import { BackButton } from '../common/back-button'
import { ButtonText } from '../common/button-text'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { searchStudentByKeywords } from '../common/helper-api-functions'
import { CLIENT_EVENT, createClientLog } from '../common/log-client-event'
import { ScreenNames } from '../common/screen-names'
import StudentSearchCardInfo from '../common/student-search-card-info'
import { showAlert } from '../common/universal-alert'
import { IconPlus } from '../icon/icon-plus'
import { rvCurrentUser } from '../login/login-state'
import { SchoolAdminClassNavigatorParamsList } from '../school-admin/school-admin-navigation-params'
import { TeacherClassNavigatorParamList } from './teacher-route-param-types'
import { isDefined } from '../../common/is-defined'


type TeacherAddStudentStackScreenProps = StackScreenProps<TeacherClassNavigatorParamList & SchoolAdminClassNavigatorParamsList, "TeacherAddStudent" | "SchoolAdminAddStudent">

export const TeacherAddStudent = ({ route: { params: { classID } }, navigation: { navigate } }: TeacherAddStudentStackScreenProps) => {

    const [currentStudents, setCurrentStudents] = useState<Student[]>([])

    const { data: classResponse } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: classID || "" } })

    const currentClass = classResponse?.getClass
    const user = useReactiveVar(rvCurrentUser)

    const [getStudentClassByStudentIdQuery] = useLazyQuery<GetStudentClassByStudentIdQuery, GetStudentClassByStudentIdQueryVariables>(gql`${getStudentClassByStudentId}`, { fetchPolicy: 'network-only' })

    const [createStudentClassMutation] = useMutation<CreateStudentClassMutation, CreateStudentClassMutationVariables>(gql`${createStudentClass}`)

    const [studentSearch, setStudentSearch] = useState('')

    const navigateToAddNewStudent = () => {
        navigate(ScreenNames.TeacherCreateStudentPickAvatar, { classID: currentClass?.id || "" })
    }
    const navigateToClassAdmin = () => {
        navigate(ScreenNames.TeacherClassAdmin, { classID: classID || "" })
    }

    const addStudentToClass = async (student: Student) => {

        const { data } = await getStudentClassByStudentIdQuery({ variables: { studentID: student.id } })
        const queryResults = data?.getStudentClassByStudentId?.items.filter((item) => item?._deleted !== true) || []
        const studentAssignedToClass = queryResults.some((item) => item?.classID === classID)

        if (!studentAssignedToClass) {

            await createStudentClassMutation({
                variables: {
                    input: {
                        classID: classID || "",
                        studentID: student?.id,
                        status: StudentClassApprovalStatus.APPROVED
                    }
                }
            })

            createClientLog({
                event: CLIENT_EVENT.CREATE_STUDENT_CLASS_SUCCESS,
                area: "TeacherAddStudent",
                action: `Teacher wants to add a student into class: ${student.id} - ${student.nickname}`,
                payload: {
                    group: "teacher",
                    nickname: user?.username,
                }
            })
            showAlert({
                title: `Student added to ${currentClass?.goMode === true ? 'GO group' : 'class'}`,
                message: `${student.nickname} added to ${currentClass?.goMode === true ? 'GO group' : 'class'}`,
                leftButtonText: 'Ok',
            })
        }
        else {
            createClientLog({
                event: CLIENT_EVENT.CREATE_STUDENT_CLASS_FAILED,
                area: "TeacherAddStudent",
                action: `Teacher wants to add a student into ${currentClass?.goMode === true ? 'GO group' : 'class'}: ${student.id} - ${student.nickname}`,
                payload: {
                    error: `${student.nickname} is already assigned to ${currentClass?.name}'s ${currentClass?.goMode === true ? 'GO group' : 'class'}`,
                    group: "teacher",
                    nickname: user?.username,
                }
            })
            showAlert({
                title: `Student is already assigned to this ${currentClass?.goMode === true ? 'GO group' : 'class'}.`,
                message: `${student.nickname} is already assigned to ${currentClass?.name}'s ${currentClass?.goMode === true ? 'GO group' : 'class'}`,
                leftButtonText: 'Ok',
            })
        }


    }

    const searchStudents = async () => {
        if (studentSearch) {
            const currentStudent = await searchStudentByKeywords(studentSearch, currentClass?.schoolID || '')
            setCurrentStudents(currentStudent.filter(isDefined))
        } else {
            setCurrentStudents([])
        }
    }

    const debouncedSearchStudent = useMemo(() => {
        return _.debounce(searchStudents, 500);
    }, [studentSearch]);

    useEffect(() => {
        debouncedSearchStudent()
        return () => {
            debouncedSearchStudent.cancel();
        };
    }, [studentSearch])

    return (
        <View style={styles.page}>
            <View style={styles.header}>
                <View style={styles.backButtonContainer}>
                    <BackButton onPress={navigateToClassAdmin} />
                </View>
                <View style={styles.spacer} />
            </View>
            <View style={styles.buttonContainer}>
                <DefaultButton onPress={navigateToAddNewStudent} >
                    <IconPlus />
                    <ButtonText >Add new student</ButtonText>
                </DefaultButton>
            </View>
            <DefaultText style={styles.Heading}>Add existing Student</DefaultText>
            <ScrollView style={styles.studentScrollContainer}>
                <View style={styles.searchContainer}>
                    <TextInput
                        style={styles.searchInput}
                        placeholder="Search students.."
                        value={studentSearch}
                        onChangeText={setStudentSearch}
                    />
                </View>
                <ScrollView style={styles.studentScroll} contentContainerStyle={styles.interalStudentScroll}>
                    {studentSearch.length <= 0 ?
                        <View style={styles.noResults}>
                            <Entypo name="air" size={50} color={DecidaColors.AppleSystemGray2Dark} />
                            <DefaultText>Start typing to see results...</DefaultText>
                            <DefaultText>(only top 10 results shown)</DefaultText>
                        </View>
                        :

                        currentStudents?.map((student) => {
                            return <StudentSearchCardInfo key={student.id} student={student} onAddStudent={() => addStudentToClass(student)} />
                        })
                    }
                </ScrollView>

            </ScrollView>
            <View style={styles.buttonContainer}>
                <DefaultButton onPress={navigateToClassAdmin} >
                    <ButtonText >Done</ButtonText>
                </DefaultButton>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    page: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-start',
    },
    studentRowContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginVertical: 5,
        backgroundColor: DecidaColors.AppleSystemGray5Light,
        padding: 15,
        borderRadius: 8,

    },
    studentScroll: {
        flex: 1,
        backgroundColor: DecidaColors.AppleSystemGray6Light,
        padding: 15,
        borderRadius: 8,
    },
    avatarContainer: {
        height: 50,
        width: 50,
    },
    classes: {
        backgroundColor: DecidaColors.AppleSystemGrayLight,
        borderRadius: 8,
        padding: 4,
    },
    classesContainer: {
        position: 'absolute',
        flexDirection: 'column',
        right: 50,
    },
    classText: {
        color: DecidaColors.White,
        fontSize: 14,
    },
    plusIconContainer: {
    },
    nameColumn: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    firstLastNameContainer: {
        flexDirection: 'row',
    },
    Heading: {
        textAlign: 'center',
        padding: 5,
    },
    searchInput: {
        padding: 20,
        backgroundColor: DecidaColors.AppleSystemGray5Light,
        borderRadius: 8,
    },
    studentScrollContainer: {
        flex: 1,
        backgroundColor: DecidaColors.AppleSystemGray6Light,
        padding: 15,
        borderRadius: 8,
        minHeight: 300,
        minWidth: 300,
        paddingHorizontal: 5,
        margin: 10,
    },
    searchContainer: {
        marginHorizontal: 15,
        paddingVertical: 5,
        justifyContent: 'center',
        alignContent: 'center',
    },
    noResults: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        flexDirection: 'row',
        width: '100%',
    },
    backButtonContainer: {
        flex: 1,
        paddingTop: 5,
    },
    spacer: {
        flex: 1,
    },
    interalStudentScroll: {
        flex: 1,
    },
    flexStudentContainer: {
        flex: 1,
    },
    flexStudentRow: {
        flex: 1,

    },
    button: {
        width: 330,
        height: 50,
    },
    buttonContainer: {
        alignItems: 'center',
        justifyContent: 'space-evenly',
    }
})
