import { FlexStyle, ViewStyle } from "react-native";
import { TCardColorScheme } from "./card-color-scheme";

export enum CardType {
    Activity = "Activity",
    Switch = "Emotion switch",
    Tool = "Tool",
    Video = "Video",
}

export enum CardTimeType {
    Short = "Less than 5 minutes",
    Medium = "5-15 minutes",
    Long = "More than 15 minutes"
}

export enum CardPeopleType {
    Individual = "Individual",
    Group = "Group",
}

export enum CardPlaceType {
    Indoor = "Indoor",
    Outdoor = "Outdoor"
}

export enum CardActionType {
    Thought = "Thought",
    Action = "Action"
}

export enum CardIntensitylevelType {
    One = '1',
    Three = '3',
    Five = '5',
    Seven = '7'
}

export enum CardCategoryType {
    // ACTIVITY_SHEETS = "Activity sheets",
    CREATIVITY = "Creativity",
    ENERGIZERS = "Energisers",
    FUN_AND_POSITIVE = "Fun and positive",
    HEALTHY_HABITS = "Healthy habits",
    NUMBER_AND_WORD_GAMES = "Number and word games",
    RESILIENCE_BUILDERS = "Resilience builders",
    SECRETS_FOR_SLEEP = "Secrets for sleep",
    SEPARATION_ANXIETY = "Separation anxiety",
    FOR_REMOTE_CLASSES = "Virtual classrooms",
}

export enum StudentCardCategoryType {
    CREATIVITY = "Creativity",
    HEALTHY_HABITS = "Healthy habits",
    RESILIENCE_BUILDERS = "Resilience builders",
    SECRETS_FOR_SLEEP = "Secrets for sleep",
}

export enum CardFilterEmotionType {
    Angry = "Angry",
    Anxious = "Anxious",
    Excited = "Excited",
    Happy = "Happy",
    Sad = "Sad",
    Scared = "Scared"
}

export type CardTemplate = {
    cardId: string
    title: string
    Header: () => JSX.Element
    HeaderTitle?: string | undefined
    Icon: () => JSX.Element
    CardContent: () => JSX.Element
    cardContentTemplate?: FlexStyle["flexDirection"],
    colorScheme: TCardColorScheme
    whyDoesItWorkText: string
    onlineTips?: string
    ExtraTips?: () => JSX.Element
    Images?: () => JSX.Element | null
    BottomRow: () => JSX.Element | null
    PerfectFor: () => JSX.Element
    lessonPlan?: boolean
    openUrl?: string
    noRating?: boolean
    mainContainerStyle?: ViewStyle
    audioUrl?: string
    favourite?: boolean
    onlyLessonPlan?: boolean
    type: CardType[]
    time: CardTimeType[]
    place: CardPlaceType[]
    people: CardPeopleType[]
    category: CardCategoryType[]
    action: CardActionType[]
    level?: CardIntensitylevelType[]
    emotion?: CardFilterEmotionType[],
    FilterComponent?: () => JSX.Element
}

