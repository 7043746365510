import { StyleSheet, View } from 'react-native'
import { Br } from '../../../common/br'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { useResponsive } from '../../../common/use-responsive'
import { Worksheets } from '../../../common/worksheets-imports'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import SwitchCardJuniorContentTwoColumns from '../../common/switch-card-junior-two-columns'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { StretchHeader } from './src/stretch-header'
import { StretchIcon } from './src/stretch-icon'
import { StretchImage1 } from './src/stretch-image1'
import { StretchImage2 } from './src/stretch-image2'
import { StretchImage3 } from './src/stretch-image3'
import { StretchImage4 } from './src/stretch-image4'
import { StretchImage5 } from './src/stretch-image5'
import { StretchingLessonplanImage1 } from './src/stretching-lessonplan-image1'

export const SwitchesEmotionsSadStretch: CardTemplate = {
    cardId: "3690d8ad-cf62-41ef-989b-4aba70017fa2",
    title: 'Stretch',
    Header: StretchHeader,
    Icon: StretchIcon,
    lessonPlan: true,
    mainContainerStyle: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 20,
    },
    PerfectFor: () => {
        const { isNarrowScreen } = useResponsive()
        const Step1 = () => {
            return (
                <View>

                    <SwitchCardJuniorContentTwoColumns
                        LeftContent={() => (
                            <StretchImage1 />
                        )}
                        isLeftImageContent
                        RightContent={() => (
                            <View>
                                <DefaultTextWithDownArrow textdata='Find a quiet space with room to spread out your arms wide.' />
                                <DefaultTextWithDownArrow textdata='Stand with you arms straight out to your side and your feet spread wide.' />
                                <DefaultTextWithDownArrow textdata='Slowly bend sideways to the left, keeping your legs straight and trying to touch your left hand to your left foot. Hold for ten breaths.' />
                                <DefaultText style={styles.contentText}>Repeat this stretch to the right, left and right again.</DefaultText>
                            </View>
                        )}
                    />

                    <View style={styles.tipsIconContainer}>
                        <DefaulTextWithTipsIcon textdata='You may like to put a towel or mat on the floor to make it more comfortable. ' />
                    </View>
                </View>
            )
        }

        const Step2 = () => {
            return (
                <View>

                    <DefaultText style={styles.contentText}>Now, lie on your belly, stretch backwards, lift your feet</DefaultText>
                    <DefaultText style={styles.contentText}>and grab your ankles from behind.</DefaultText>
                    <View style={styles.imageContainer}>
                        <StretchImage2 />
                    </View>
                    <DefaultText style={styles.contentText}>Hold for ten big breaths then release</DefaultText>
                    <DefaultText style={styles.contentText}>your feet to the ground.</DefaultText>
                </View>
            )
        }

        const Step3 = () => {
            return (
                <View style={styles.stepContainer}>
                    <DefaultTextWithDownArrow textdata='Bring your knees up under your hips in a kneeling position.' />
                    <DefaultText style={styles.contentText}>Now, stretch out your arms along the floor as far as you can,</DefaultText>
                    <DefaultText style={styles.contentText}>pushing your chest toward the floor.</DefaultText>
                    <View style={styles.imageContainer}>
                        <StretchImage3 />
                    </View>
                    <DefaultText style={styles.contentText}>Hold this pose for ten big breaths.</DefaultText>
                </View>
            )
        }

        const Step4 = () => {
            return (
                <View style={styles.stepContainer}>
                    <SwitchCardJuniorContentTwoColumns
                        LeftContent={() => <StretchImage4 />}
                        isLeftImageContent={true}
                        RightContent={() => (
                            <>
                                <DefaultTextWithDownArrow textdata='Roll onto your back and relax for a second.' />
                                <DefaultTextWithDownArrow textdata='Keeping your shoulders on the ground, reach your feet as high and straight into the air as you can. Use your arms to help keep you balanced and still.' />
                                <DefaultText style={styles.contentText}>Hold this pose for five big breaths.</DefaultText>
                            </>
                        )}
                    />
                </View>
            )
        }

        const Step5 = () => {
            return (
                <View>
                    <DefaultText style={styles.contentText}>Now stand up straight and reach for the stars. Then</DefaultText>
                    <DefaultText style={styles.contentText}>bend at the hips, keeping your legs straight, and see</DefaultText>
                    <DefaultText style={styles.contentText}>if you can touch your toes.</DefaultText>

                    <SwitchCardJuniorContentTwoColumns
                        LeftContent={() => (
                            <>
                                <DefaultText style={styles.contentText}>Go down as far as you can without it hurting and hold for ten breaths.</DefaultText>
                                <DefaultTextWithoutArrows textdata='' />
                                <DefaultText style={styles.contentText}>Finally, slowly stand up straight, close your eyes and take five big deep breaths</DefaultText>
                            </>
                        )}
                        RightContent={() => (
                            <StretchImage5 />
                        )}
                        isRightImageContent={true}
                    />

                    <View style={styles.lessonPlanCenteredTextContainer}>
                        <DefaultText style={styles.contentText}>To discover new stretches, take a look at each of the stretch poses listed <DefaultTextWithCustomLink style={styles.noIndent} text='HERE' link={Worksheets.stretching.uri} />, taking at least 10 deep breaths before moving to the next pose.</DefaultText>
                    </View>

                    <View>
                        <WhyDoesItWorkComponent message={"Stretching can lead to the release of endorphins, which are chemicals in the brain that act as natural painkillers and can also improve mood. \n\n Additionally, some research suggests that stretching can improve balance and proprioception (the sense of the position and movement of the body), and help with more prolonged focus and attention."} />
                    </View>
                </View>
            )
        }

        const contents = [
            { Component: Step1, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/stretch-pg1.mp3' },
            { Component: Step2, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/stretch-pg2.mp3' },
            { Component: Step3, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/stretch-pg3.mp3' },
            { Component: Step4, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/stretch-pg4.mp3' },
            { Component: Step5, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/stretch-pg5.mp3' },
        ]

        return (
            <View style={{ width: '100%' }}>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata='Reducing stress and feeling good.' />
                <Br />
                <SwitchCardJuniorContent contents={contents} scheme={Emotion.SAD} nextButtonText={isNarrowScreen ? 'NEXT' : 'NEXT STRETCH'} />
            </View>
        )
    },
    Images: undefined,
    CardContent: () => (
        <>
        </>
    )
    ,
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: "",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define stretching and its benefits.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Perform basic stretches safely.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 40 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by asking students if they know what stretching is.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a discussion on what stretching is and why it is important.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Instruction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show the students the stretching posters or pictures and demonstrate the stretches. If helpful, refer to the Stretching Guide Sheet or use the link in the switch in the Switch4Schools app. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the proper way to stretch and the benefits of stretching, such as increased flexibility and improved physical fitness.</DefaultText>
                        <View style={styles.lessonPlanCenteredTextContainer}>
                            <View style={styles.imageContainer}>
                                <StretchingLessonplanImage1 />
                            </View>
                        </View>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students follow along and stretch with you as you demonstrate the stretches.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to listen to their bodies and only stretch to their own comfort level.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students work in pairs or small groups and take turns leading each other through the stretches demonstrated.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Observe the students as they stretch and provide feedback on their form and technique.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Review the importance of stretching and the benefits it can have for our bodies.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to incorporate stretching into their daily routine.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Space to move/stretch</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='• Link to Stretching Guide Sheet' link={Worksheets.stretching.uri} />
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The simple act of stretching -</DefaultText>
                        <DefaultTextWithLink text='https://www.research.colostate.edu/healthyagingcenter/2021/06/23/the-simple-act-of-stretching/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The effects of Pilates and yoga participant’s on engagement in functional movement and individual health level -</DefaultText>
                        <DefaultTextWithLink text='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6732550/' />
                    </>}
            />

        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual, CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Sad]
}

const styles = StyleSheet.create({
    contentText: {
        // color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    stepContainer: {
        width: '100%',
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    lessonPlanCenteredTextContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center',
        marginVertical: 20,
        width: '90%'
    },
    noIndent: {
        paddingLeft: 0,
    },
    tipsIconContainer: {
        marginTop: 20,
        width: '100%',
    }
})

