import { StyleSheet } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

export const SwitchesLessonPlansIntroducingScared: CardTemplate = {
    cardId: "e49eb71c-c183-4692-b909-60f812b0180b",
    title: 'Introducing scared',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Deepen their understanding of the emotion "scared" by identifying its characteristics, expressing what fear looks and feels like, and exploring strategies for managing and overcoming fear.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 35-45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Review of Emotions (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by briefly reviewing the emotions learned in the previous lessons, including "scared".</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to recall what they remember about the emotion "scared" and share examples of when they have felt scared.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Characteristics of Fear (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the “When I am scared” video [2:33]</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set up a large chart paper on an easel or wall.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write "Scared" at the top of the chart and invite the students to brainstorm words or phrases that describe what fear looks and feels like.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write their responses on the chart paper, encouraging them to explain their choices and expand on the ideas shared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss common characteristics, such as feeling shaky, having a racing heart, or wanting to run away.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Identifying Fear Triggers (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share situations that might make someone feel scared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss their responses and encourage them to share personal experiences or observations of others feeling scared in similar situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that feeling scared is a natural response and that everyone has different fears.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Expressing Fear (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite the students to express what fear looks like to them by drawing a picture or using art supplies to create a visual representation of fear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to use colours, lines, and shapes that they associate with feeling scared. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide an opportunity for volunteers to share their artwork and describe what they depicted, emphasising that there are different ways fear can be expressed.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Strategies for Managing Fear (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce strategies for managing and overcoming fear, such as taking deep breaths, talking to a trusted adult, using positive self-talk, or facing fears gradually. Refer to the available switches for ‘scared’ for inspiration.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss each strategy and encourage the students to share any strategies they personally use when they feel scared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that fear can be managed and that it's important to seek support and take small steps to face and overcome fears.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflecting on Fear (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students back in the circle. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to reflect on what they learned about fear and how it feels to be scared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to share their artwork or describe a situation that makes them feel scared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss strategies for managing fear and remind them that they have the power to overcome.  </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>Extension Activities:</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Read books or tell stories about fear and discuss the characters' experiences and coping mechanisms.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Complete lessons for the available ‘scared switches. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='When I am scared video' link='https://youtu.be/8V3wrq-HciU' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Large chart paper and markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Art supplies (crayons, coloured pencils, markers)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Blank paper or drawing sheets.</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>How to Help Children Manage Fears -</DefaultText>
                        <DefaultTextWithLink text='https://childmind.org/article/help-children-manage-fears/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Tips For Helping Children Manage Fear and Anxiety -</DefaultText>
                        <DefaultTextWithLink text='https://betterkids.education/blog/tips-for-helping-children-manage-fear-and-anxiety' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}
