import { makeVar } from '@apollo/client'
import { DecidaColors } from '../../../common/decida-colors'
import { CardTemplate } from './card-template'

export namespace CardColorScheme {
    export const ANGRY = DecidaColors.Angry
    export const ANXIOUS = DecidaColors.Anxious
    export const EXCITED = DecidaColors.Excited
    export const HAPPY = DecidaColors.Happy
    export const SAD = DecidaColors.Sad
    export const SCARED = DecidaColors.Scared
    export const HEALTHY_HABITS = DecidaColors.HealthyHabits
    export const ENERGIZERS = DecidaColors.Energizers
    export const NUMBER_AND_WORD_GAMES = DecidaColors.NumbersAndWordGames
    export const CREATIVITY = DecidaColors.Creativity
    export const FOR_REMOTE_CLASSES = DecidaColors.ForRemoteClasses
    export const SECRETS_FOR_SLEEP = DecidaColors.SecretsForSleep
    export const ACTIVITY_SHEETS = DecidaColors.ActivitySheets
    export const POSTERS = DecidaColors.Posters
    export const EXTRA_SWITCHES = DecidaColors.ExtraSwitches
    export const TOOL = DecidaColors.Gray
    export const FUN_AND_POSITIVE = DecidaColors.Gray
    export const SEPARATION_ANXIETY = DecidaColors.SeparationAnxiety
}

export type TCardColorScheme =
    typeof CardColorScheme.ANGRY
    | typeof CardColorScheme.ANXIOUS
    | typeof CardColorScheme.EXCITED
    | typeof CardColorScheme.HAPPY
    | typeof CardColorScheme.SAD
    | typeof CardColorScheme.SCARED
    | typeof CardColorScheme.HEALTHY_HABITS
    | typeof CardColorScheme.ENERGIZERS
    | typeof CardColorScheme.NUMBER_AND_WORD_GAMES
    | typeof CardColorScheme.CREATIVITY
    | typeof CardColorScheme.FOR_REMOTE_CLASSES
    | typeof CardColorScheme.SECRETS_FOR_SLEEP
    | typeof CardColorScheme.TOOL
    | typeof CardColorScheme.ACTIVITY_SHEETS
    | typeof CardColorScheme.POSTERS
    | typeof CardColorScheme.EXTRA_SWITCHES
    | typeof CardColorScheme.FUN_AND_POSITIVE
    | typeof CardColorScheme.SEPARATION_ANXIETY


export const rvCard = makeVar<CardTemplate | undefined>(undefined)
