import { View } from 'react-native'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { VacationPinIcon } from '../../icons/vacation-pin-icon'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { VacationPinIconInverted } from '../../icons/vacation-pin-icon-inverted'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-double-indentation'

export const SwitchesActivitiesRemoteClassesVacationPin: CardTemplate = {
    cardId: "7cf3b406-c5f2-49e7-8c30-88b350720b00",
    title: 'Vacation pin',
    Header: VacationPinIconInverted,
    Icon: VacationPinIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Learning something new about other students and familiarising students with geography and search tool functionality."} />
            <DefaulttextWithRightArrow textdata={"Create a shared Google maps with the class."} />
            <DefaulttextWithRightArrow textdata={"Have the class spend 2 minutes researching where they would like to spend a holiday and place a pin on the map."} />
            <DefaulttextWithRightArrow textdata={"Pick some particularly unusual pins and have the students explain why they would like to go there."} />
            <DefaulttextWithRightArrow textdata={"Create some questions which are linked to a destination. First person to put a pin on that destination gets a point."} />
            <DefaulttextWithRightArrow textdata={"Example questions could include:"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Where were the 1964 Olympics held?"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Where was Nelson Mandela held captive?"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Where is the house of Sherlock Homes?"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Where is the capital of China?"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Where was Beethoven born?"} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.FOR_REMOTE_CLASSES,
    whyDoesItWorkText: '',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Vacation+Pin.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.FOR_REMOTE_CLASSES],
    action: [CardActionType.Action],
    level: []
}

