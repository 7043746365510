import { useReactiveVar } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { ScreenNames } from '../../common/screen-names'
import { getLockDerviceRv, rvUserGroup } from '../../login/login-state'
import { TeacherDrawerNavigatorPamarList } from '../../teacher/teacher-route-param-types'
import { ActivityGroupTemplate } from '../common/activity-group-template'
import { tileSizes } from '../common/title-sizes'
import { activityGroupCards, activityGroupPublicCards } from './activity-cards'
import { UserGroup } from '../../../common/constants'

export const SwitchesActiviesDisplayAll = ({ navigation: { navigate }, }: StackScreenProps<TeacherDrawerNavigatorPamarList, 'SwitchesActiviesDisplayAll'>,) => {

    const userGroup = useReactiveVar(rvUserGroup)
    const denyAccess = useReactiveVar(getLockDerviceRv())

    let activityCards = activityGroupCards;

    switch (userGroup) {
        case UserGroup.Student:
        case UserGroup.ClassLogin:
        case UserGroup.NonTeachingStaff:
        case undefined:
            activityCards = activityGroupPublicCards
            break
        default:
            activityCards = denyAccess ? activityGroupPublicCards : activityGroupCards
            break;
    }

    const handlePressCard = (activityGroup: ActivityGroupTemplate) => {
        if (activityGroup.navigateCardId) {
            navigate(ScreenNames.Card, { id: activityGroup.navigateCardId || "", previousScreen: 'activities', previousCardId: activityGroup.navigateCardId })
            return
        }

        navigate(ScreenNames.SwitchesActiviesDetailView, { activity: activityGroup.activity })
    }

    return (
        <>
            <ScrollView style={styles.scrollview} contentContainerStyle={styles.container} >
                {
                    activityCards ?
                        activityCards.map((activityGroup) => {
                            return (
                                <TouchableOpacity key={activityGroup.title} style={tileSizes.item} onPress={() => handlePressCard(activityGroup)}>
                                    <View style={tileSizes.tile}>
                                        <activityGroup.Icon />
                                    </View>
                                    <DefaultText style={styles.cardtext}>
                                        {activityGroup.title}
                                    </DefaultText>
                                </TouchableOpacity>
                            )
                        }
                        )
                        :
                        <>
                        </>
                }
            </ScrollView>
        </>

    )
}


const styles = StyleSheet.create({

    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: 10,
        paddingBottom: 20,
    },
    content: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: "100%"

    },
    image: {
        width: 350,
        height: 450,
    },
    header: {
        fontSize: 32,
        fontWeight: 'bold',
        color: DecidaColors.Gray,
        textAlign: 'center',
    },
    item: {
        margin: 30,
        width: 175,
        height: 225,
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'center'
    },
    scrollview: {
        flexGrow: 0,
        width: '100%',
        height: '100%',

    },
    cardtext: {
        fontSize: 20,
        textAlign: 'center'
    },
    cardicon: {
        width: 175,
        height: 175,
    },
    back: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    }

})


