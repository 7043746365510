import { useReactiveVar } from "@apollo/client";
import { View, StyleSheet } from "react-native";
import { DecidaColors } from "../../common/decida-colors";
import { DefaultText } from "../common/default-text";
import { rvParentIntensityState } from "./parent-state";
import StepperEmotionIntensityScale from "../common/stepper-emotion-intensity-scale";

export const ParentSettings = () => {


    const emotionIntensityLevel = useReactiveVar(rvParentIntensityState)

    const changeEmotionalIntensity = (level: number) => {
        rvParentIntensityState(level)
        // Note: 
        // Didn't sure why parent needs to update this rvCurrentClass states.
        // commented this out just in case it caused some unexpected bugs.
        // rvCurrentClass({
        //     id: '',
        //     name: '',
        //     emotionIntensityLevel: level
        // })

    }
    return (
        <View style={styles.main}>
            <DefaultText style={styles.heading}>
                Welcome to Switch4School
            </DefaultText>
            <View style={styles.settingsContainer}>
                <StepperEmotionIntensityScale value={emotionIntensityLevel} onChange={changeEmotionalIntensity} />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
    },
    settingsContainer: {
        justifyContent: 'flex-start',
        alignItems: "center",
        minWidth: 400,
        minHeight: 300,
        padding: 20,
        borderRadius: 8,
        backgroundColor: DecidaColors.AppleSystemGray6SuperLight
    },
    heading: {
        fontSize: 28,
        fontWeight: '600',
        paddingBottom: 60,
    }

})