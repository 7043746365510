import { View } from 'react-native'
import { DefaultTextWithDownArrow } from '../../../common/default-text-with-down-arrow'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { VideoCriticIcon } from '../../icons/video-critic-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { VideoCriticIconInverted } from '../../icons/video-critic-icon-inverted'

export const SwitchesActivitiesRemoteClassesVideoCritic: CardTemplate = {
    cardId: "24d45898-e2c5-45cc-951e-629fd9af0d3b",
    title: 'Video critic',
    Header: VideoCriticIconInverted,
    Icon: VideoCriticIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Expanding critical thinking and empathy skills"} />
            <DefaultTextWithDownArrow textdata={"Find a TED talk or interesting video online."} />
            <DefaultTextWithDownArrow textdata={"Have the class split into two groups, those that argue in favour of what the video is saying, and those who argue against."} />
            <DefaultTextWithDownArrow textdata={"Once the class has watched the video, have each student spend three minutes thinking about their argument."} />
            <DefaultTextWithDownArrow textdata={"Bouncing between for and against arguments, have each student present their argument in 30 second time slots."} />
            <DefaultTextWithDownArrow textdata={"have other class members give ‘thumbs up’ or 'thumbs down’ emoji’s in response to different elements of the arguments presented."} />
            <DefaultTextWithDownArrow textdata={"Keep a tally of the emoji’s. +1 for thumbs up and -1 for thumbs down."} />
            <DefaultTextWithoutArrows textdata={"Highest score at the end of the presentations wins."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.FOR_REMOTE_CLASSES,
    whyDoesItWorkText: '',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Video+Critic.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.FOR_REMOTE_CLASSES],
    action: [CardActionType.Thought],
    level: []
}

