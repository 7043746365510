import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { DearDiaryIcon } from '../../explore/src/dear-diary-icon'
import { DearDiaryIconInverted } from '../../explore/src/dear-diary-icon-inverted'
import { DearDiaryImage1 } from './src/dear-diary-image1'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { Br } from '../../../common/br'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'

export const SwitchesEmotionsHappyJournaling: CardTemplate = {
    cardId: "a6a85162-e61a-40ec-9e5f-01cecd376589",
    title: 'Journaling',
    Header: DearDiaryIconInverted,
    Icon: DearDiaryIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Clearing the mind, settling emotion and focusing attention."} />
        </>
    ),
    Images: () => (
        <>
            <DearDiaryImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Find a quiet space to write down your thoughts and feelings."} />
                <DefaulttextWithRightArrow textdata={"Grab a pen and some paper (or a notebook)."} />
                <DefaulttextWithRightArrow textdata={"Write about what happened the previous day, and how you felt about it. Include things that made you feel good, or proud, as well as things you found challenging."} />
                <DefaulttextWithRightArrow textdata={"Write everything down, don’t hold back."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: 'Research shows that journaling can help reduce depression, anxiety, and stress. Journaling can also help you cope with emotions, create more focus, develop insights, and reflect on behaviour or feelings. It can boost your happiness by helping you focus on the creativity, beauty, connection, fun, and love in your life. Journaling in a classroom context can also improve general rapport.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• demonstrate reflective story writing skills, and   </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• compose a narrative that describes recent events and feelings associated to that event. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by discussing the concept of journaling. Define reflection as the process of stepping back from an experience to think about its impact.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that journaling is a personal and creative way to express thoughts and feelings.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Activity (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present the slide or write thought starter questions on the board:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Something awesome that happened</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Something you are excited about</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Three things you are grateful for</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- A goal or challenge for the week</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Something you did that you are proud of</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- One thing that surprised you</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Something someone did to make you feel loved</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think about the previous day and choose at least three questions to answer in their journals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set a timer for at least 10 minutes and instruct students to write freely, focusing on personal expression rather than the quality of writing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage creativity and assure them that there are no right or wrong answers.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Debrief (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After the activity, ask students to reflect on how they felt during and after journaling.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the purpose of the activity and how journaling can be an effective outlet for capturing and creating positive energy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share their experiences, if they feel comfortable.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the option for students to indicate if they'd like you to read their journals. Respect their privacy and emphasise the personal nature of journaling.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, guide them to find the "Journaling" switch in the Switch4Schools app.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Paper/notebook</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Journaling Thought Starter Slide' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Journaling+lesson+plan+slide.pdf' /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Cultivating Reflection Skills: 13 Worksheets & Journal Templates - </DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/reflection-journal-worksheets/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Journaling for Mindfulness: 44 Prompts, Examples, and Exercises - </DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/journaling-for-mindfulness/' />

                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"You may prefer to draw your experiences and reflections rather than write a story, or even use a combination of both."} />
            <DefaulTextWithTipsIcon textdata={"Don’t focus on the quality of the story writing, bullet point lists or key words are fine, as long as it makes sense to you."} />
            <DefaulTextWithTipsIcon textdata={"Consider sharing your journal with your teacher or someone you trust. "} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Journalling.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Happy]
}

