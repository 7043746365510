import { View, Platform, Image, ViewStyle } from 'react-native'

interface Props {
    width?: number
    marginTop?: number
    height?: number
    marginBottom?: number
    style?: ViewStyle
}

const image = require('./icon-new.png')

export const NewIcon = ({ height, width, marginBottom, marginTop, style }: Props) => (
    <View style={[{ marginBottom, marginTop }, style]}>
        <Image style={{ width, height }} source={Platform.OS === 'web' ? { uri: image } : image} />
    </View >
)