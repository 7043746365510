import { useReactiveVar } from '@apollo/client'
import _ from 'lodash'
import { View } from 'react-native'
import { rvFilterSwitchesData } from '../../../common/common-state'
import { DefaultText } from '../../../common/default-text'
import SheetsAndPosterCardList from '../../../common/sheetsAndPosterList'
import SwitchCardFilter from '../../../common/switch-card-filter'
import { SwitchCardFilterMethods } from '../../../common/switch-card-filter-methods'
import SwitchCardNoResultFilter from '../../../common/switch-card-no-result-filter'
import { WorksheetActivity, WorksheetType, allWorksheets } from '../../../common/worksheets-imports'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import { ActivitySheetsIcon } from '../icons/activity-sheets-icon'
import { useEffect, useState } from 'react'
import InputSearchContent from '../../../common/input-search-content'

const DoingActivity = _.sortBy(allWorksheets.filter(worksheet => worksheet.activity === WorksheetActivity.Doing), "label")
const ThinkingActivity = _.sortBy(allWorksheets.filter(worksheet => worksheet.activity === WorksheetActivity.Thinking), "label")

export const SwitchesActivitiesActivitySheets: CardTemplate = {
    cardId: "df8a391c-b7fb-43e3-a8f7-2ba4fced76be",
    title: 'Activity Sheets',
    Header: ActivitySheetsIcon,
    Icon: ActivitySheetsIcon,
    PerfectFor: () => (
        <>
            {/* <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} /> */}
        </>
    ),
    BottomRow: () => {
        const [title, setTitle] = useState("")
        const filters = useReactiveVar(rvFilterSwitchesData)
        const isAnyFilterApplied = SwitchCardFilterMethods.isAnyFilterApplied(filters)

        const filteredActivitySheetsData = SwitchCardFilterMethods.getFilteredActivitySheets(allWorksheets, filters)
       

        useEffect(() => {
            return () => {
                // clear filter when navigates away
                SwitchCardFilterMethods.clearFilter()
            }
        }, [])

        const getFilterDataByTitle = (worksheets: WorksheetType[]) => {
            return worksheets.filter((sheets) => sheets?.label?.toLowerCase().indexOf(title.toLowerCase()) != -1)
        }
        
        return (
            <>
                <DefaultTextWithoutArrowsItallic textdata={"Worksheets that are attached to the lesson plans - but all in one place!"} />
                <InputSearchContent title={title} onChangeText={setTitle} placeholder='Search worksheets...'/>
                {isAnyFilterApplied && !filteredActivitySheetsData.length && (
                    <SwitchCardNoResultFilter />
                )}
                {isAnyFilterApplied ? (
                    <SheetsAndPosterCardList data={filteredActivitySheetsData} />
                ) : (
                    <>
                        <View style={[{ width: '100%' }]}>
                            {/* DOING */}
                            <DefaultText style={{ fontWeight: 'bold' }}>
                                Doing <DefaultText style={{ fontSize: 16, fontWeight: 'normal' }}>(activities that make you get up and move)</DefaultText>
                            </DefaultText>
                            <SheetsAndPosterCardList data={getFilterDataByTitle(DoingActivity)} />
                        </View>
                        <View style={[{ width: '100%', marginTop: 50 }]}>
                            {/* THINKING */}
                            <DefaultText style={{ fontWeight: 'bold' }}>
                                Thinking <DefaultText style={{ fontSize: 16, fontWeight: 'normal' }}>(reflective, sitting and/or writing activities)</DefaultText>
                            </DefaultText>
                            <SheetsAndPosterCardList data={getFilterDataByTitle(ThinkingActivity)} />
                        </View>
                    </>
                )}
            </>
        )
    },
    noRating: true,
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.ACTIVITY_SHEETS,
    whyDoesItWorkText: '',
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [],
    FilterComponent: () => {
        return <SwitchCardFilter filterType='activity_sheets' />
    }

}

