import { StyleSheet, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { SmartGoalsHeader } from './src/smart-goals-header'
import { SmartGoalsIcon } from './src/smart-goals-icon'
import { SmartGoalsImage1 } from './src/smart-goals-image1'
import { SmartGoalsLessonPlan } from './src/smart-goals-lesson-plan'

export const SwitchesEmotionsHappySmartGoals: CardTemplate = {
    cardId: "a8949a1a-96a2-491f-86bc-6d16426453d7",
    title: 'Smart goals',
    Header: SmartGoalsHeader,
    Icon: SmartGoalsIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Creating goals that you are more likely to be motivated to achieve."} />
        </>
    ),
    Images: () => (
        <>
            <SmartGoalsImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Find a seat and grab a pencil and paper."} />
                <DefaulttextWithRightArrow textdata={"Think of something that you might like to achieve."} />
                <DefaulttextWithRightArrow textdata={"Refine that goal to make it SMART:"} />
                <DefaultText style={styles.withIndent} >• S - Identify <DefaultText style={styles.subText}>specifically</DefaultText> what you want to achieve</DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaultText style={styles.withIndent} >• M - Come up with ways to <DefaultText style={styles.subText}>measure</DefaultText> your progress towards the goal</DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaultText style={styles.withIndent} >• A - Assess whether the goal is realistic and <DefaultText style={styles.subText}>achievable</DefaultText></DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaultText style={styles.withIndent} >• R - Consider whether your goal is <DefaultText style={styles.subText}>relevant</DefaultText> to your current values and interests</DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaultText style={styles.withIndent} >• T - set a <DefaultText style={styles.subText}>time</DefaultText> that you want to achieve your goal by </DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaulttextWithRightArrow textdata={"Put your SMART goal somewhere prominent you will see to stay motivated to get it done. "} />
            </View>
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Start with the end in mind: Consider what you want to achieve and why it is important to you. This will help you create a clear and specific goal.' />
            <DefaulTextWithTipsIcon textdata='Make your goals measurable: Use concrete, quantifiable terms to describe your goals. For example, instead of saying "I want to lose weight," say "I want to lose 2 kilograms.".' />
            <DefaulTextWithTipsIcon textdata='Make your goals achievable: Consider your resources and constraints when setting your goals. Make sure the goals you set are realistic and achievable given your current circumstances.' />
            <DefaulTextWithTipsIcon textdata='Make your goals relevant: Make sure your goals align with your values and objectives. This will help you stay motivated and committed to achieving your goals.' />
            <DefaulTextWithTipsIcon textdata='Make your goals time-bound: Set a specific deadline or time frame for achieving your goals. This will help you stay focused and motivated, and it will make it easier to track your progress.' />
            <DefaulTextWithTipsIcon textdata='Write your goals down: Putting your goals in writing can help you stay accountable and focused.' />
            <DefaulTextWithTipsIcon textdata='Break your goals down into smaller, more manageable steps: This can help you make progress and stay motivated.' />
            <DefaulTextWithTipsIcon textdata='Celebrate your progress and achievements: Recognise and reward yourself when you make progress towards your goals.' />
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: `Our brain loves to win, to be successful. Research has shown that setting specific, measurable, attainable, relevant, and time-bound goals can lead to better outcomes, compared to setting vague or general goals which can be hard to articulate or know when you have achieved them. Without knowing if, or how we can win, we lose motivation easily.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice setting specific, measurable, attainable, relevant, and time-bound goals.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by explaining the importance of setting goals for achieving success in various aspects of life.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of SMART goals and explain each component.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the introductory video on SMART goals: <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Khan Academy - SMART Goals' link='https://www.khanacademy.org/college-careers-more/learnstorm-growth-mindset-activities-us/elementary-and-middle-school-activities/setting-goals/v/learnstorm-growth-mindset-how-to-write-a-smart-goal' /></DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think about a personal or academic goal they have for themselves.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In pairs or small groups, have students write down their goals and analyse them using the SMART criteria.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage discussions with questions like:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Is the goal specific and clear?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Can the goal be measured?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Is the goal achievable with available resources?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Is the goal relevant to their values and objectives?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Is the goal time-bound with a specific deadline?</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Sharing and Discussion (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have each pair or group share their goals and the SMART analysis with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a class discussion on the importance of setting SMART goals and how it can enhance the likelihood of success.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide feedback and suggestions for refining goals to make them more SMART.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the SMART Goals Worksheet and ask students to revise their goals based on the feedback received.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to consider specific actions, measurements, and deadlines for their goals.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students to place their revised goals in a visible location for regular review.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of ongoing goal reflection and adjustments.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to celebrate their progress and achievements.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude the lesson with a positive and motivating note about the power of setting SMART goals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, guide them to find the "Smart goals" switch in the Switch4Schools app.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Paper</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pencil</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} text='SMART Goals worksheet' fontSize={DefaultLessonPlanContentFontsize} link={Worksheets.smartGoals.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Science & Psychology Of Goal-Setting 101 - </DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/goal-setting-psychology/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to write a SMART goal - </DefaultText>
                        <DefaultTextWithLink text='https://www.khanacademy.org/college-careers-more/learnstorm-growth-mindset-activities-us/elementary-and-middle-school-activities/setting-goals/v/learnstorm-growth-mindset-how-to-write-a-smart-goal' />
                    </>}
            />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Smart+Goals+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Happy]
}

const styles = StyleSheet.create({
    subText: {
        fontWeight: 'bold',
    },
    withIndent: {
        paddingLeft: 20
    }
})
