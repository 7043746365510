import { useNetInfo } from "@react-native-community/netinfo";
import { useEffect, useRef } from 'react';
import { rvNetInfo } from './common-state';
import { showOfflineAlert } from "./universal-alert";
import { rvIsLoading } from "./loading";
import { isWebView } from "../web-view/is-web-view";

const useConnectivity = () => {
    const onlineRefNetInfo = useRef(false)

    const netInfo = useNetInfo();

    useEffect(() => {

        if (netInfo.isInternetReachable === false) {
            rvIsLoading(false)
            onlineRefNetInfo.current = false
            if (!isWebView()) {
                showOfflineAlert()
            }
        } else if (netInfo.isInternetReachable === true) {
            onlineRefNetInfo.current = true
            console.log("You're online")
        }

        rvIsLoading(false)
        rvNetInfo(netInfo)
    }, [netInfo])

    return { onlineRef: onlineRefNetInfo }
}

export default useConnectivity