import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from '../common/default-text'
import SchoolAdmiGroupsListItem from './school-admin-group-list-item'
import { SchoolAdminHierarchyCurrentListView } from './school-admin-types'

type Props = {
    groups: Array<{ id: SchoolAdminHierarchyCurrentListView, name: string }>
}

const SchoolAdminGroupList = ({ groups }: Props) => {
    return (
        <View>
            <View style={{ marginTop: 40, marginBottom: 20, paddingLeft: 20 }}>
                <DefaultText style={styles.mainText}>Groups</DefaultText>
            </View>
            {groups.map((group) => {
                return (
                    <SchoolAdmiGroupsListItem key={group.id} group={group} />
                )
            })}
        </View>
    )
}
export default SchoolAdminGroupList

const styles = StyleSheet.create({
    mainText: {
        fontWeight: 'bold',
        color: DecidaColors.AppleSystemGray2Dark,
    }
})