import { View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaultTextWithIndentation } from '../../common/default-text-with-indentation'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { HelpOrHurtHeaderIcon } from './src/help-or-hurt-header-icon'
import { HelpOrHurtIcon } from './src/help-or-hurt-icon'
import { HelpOrHurtLineDrawing } from './src/help-or-hurt-line-drawing'
import { Worksheets } from '../../../common/worksheets-imports'

export const SwitchesEmotionsScaredHelpOrHurt: CardTemplate = {
    cardId: "b9b638d7-4272-47c3-ba9d-d6b2101ad4df",
    title: 'Help or hurt',
    Header: HelpOrHurtHeaderIcon,
    Icon: HelpOrHurtIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <View style={{ flex: 1 }}>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata='Becoming aware of your feelings and how they impact your decisions. ' />

            </View>
        </>
    ),
    Images: HelpOrHurtLineDrawing,
    CardContent: () => (
        <>
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <DefaulttextWithRightArrow textdata="" />
                <DefaultText>Open or print the <DefaultTextWithCustomLink text={Worksheets.helpOrHurtRecapWorksheet.label} link={Worksheets.helpOrHurtRecapWorksheet.uri} /> worksheet. Alternatively, find a piece of paper to write on.</DefaultText>
            </View>
            <DefaulttextWithRightArrow textdata="Work through the questions provided to explore your reaction to the triggering experience and assess how reframing your interpretation of experience could be helpful for future similar experiences. Specifically:" />
            <DefaultTextWithIndentation textdata='a. Trigger: What happened? Who, what, when, where?' />
            <DefaultTextWithIndentation textdata='b. Reaction: What did you do? How did you feel?' />
            <DefaultTextWithIndentation textdata='c. Thoughts: How did you interpret the experience? What did it mean to you?' />
            <DefaultTextWithIndentation textdata='d. Helpful or hurtful: How does holding on to this feeling affect you? Is it helpful or hurtful?' />
            <DefaultTextWithIndentation textdata='e. Alternate view: Is there a better way to think about the situation that creates more helpful emotions?' />

        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Help+or+hurt.m4a",
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: `By pinpointing specific triggers, individuals gain insight into the sources of their fear, facilitating targeted management. Recognising emotional and physical responses fosters self awareness and enables better control. Evaluating thoughts allows for cognitive restructuring, challenging irrational beliefs, while considering alternate perspectives reduces fear's intensity. Analysing emotion helpfulness distinguishes between protective and hindering responses, empowering individuals to regulate fear more effectively and promoting emotional wellbeing.`,
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata="-If the thing that is scaring you poses a real physical threat to you, we recommend talking to a trusted adult about it. " />
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn to reframe scared emotions by exploring how these emotions can both help and hinder them in various situations.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                       <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by asking students to think of examples of situations in which they have felt scared or anxious. Share a personal example. Invite the students to share an example with the person sitting next to them or the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss as a class how these emotions affected their behaviour and decision making in those situations. Were they helped or hindered by their fear?</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity (15 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of "reframing" emotions by explaining that it involves looking at emotions from a different perspective and considering how they can be helpful rather than detrimental.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide students with the <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={Worksheets.helpOrHurtFearsWorksheet.label} link={Worksheets.helpOrHurtFearsWorksheet.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In pairs or small groups, ask students to discuss and write down the ways in which fear might help them in each of these situations. Encourage them to think about potential positive aspects, such as increased focus, preparation, or caution.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After group discussions, have each group share one scenario and their findings with the class. Encourage discussion and debate about the potential benefits of these emotions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a class discussion by asking students the following questions:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- How did this activity change your perspective on scared emotions?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Can fear sometimes be a helpful emotion? Why or why not?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- What strategies can you use to reframe your scared emotions in situations where they may hinder you?</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Application (15 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students reflect individually by asking them to choose one upcoming situation in which they might feel scared or anxious e.g., a presentation, a challenging exam, a social event.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Help or Hurt Recap worksheet. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In their reflection, students should write about how they can reframe their scared emotions to use them as motivation or an advantage in that specific situation.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points of the lesson, emphasising the importance of understanding and harnessing scared emotions in a positive way.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice reframing their scared emotions in real life situations and share their experiences in future lessons.</DefaultText>



                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={Worksheets.helpOrHurtFearsWorksheet.label} link={Worksheets.helpOrHurtFearsWorksheet.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={Worksheets.helpOrHurtRecapWorksheet.label} link={Worksheets.helpOrHurtRecapWorksheet.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Writing materials for students</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>3 Ways To Harness Your Fear</DefaultText>
                        <DefaultTextWithLink text='https://maybusch.com/3-ways-to-harness-your-fear/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to Reframe Your Fear and Let It Work for You</DefaultText>
                        <DefaultTextWithLink text='https://fullfocus.co/reframe-fear/' />
                        
                        <DefaultText style={CommonStyles.lessonPlanText}>Reframing is Therapy’s Most Effective Tool, Here’s Why</DefaultText>
                        <DefaultTextWithLink text='https://www.talkspace.com/blog/reframing-therapy-effective-tool/' />
                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Scared]
}


