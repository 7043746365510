import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { PlayTimeIcon } from '../../icons/play-time-icon'
import { PlayTimeIconInverted } from '../../icons/play-time-icon-inverted'


export const SwitchesActivitiesSeparationAnxietyPlayTime: CardTemplate = {
    cardId: "c23847ae-bd59-475c-96d9-f652ba794567",
    title: 'Play time',
    Header: PlayTimeIconInverted,
    Icon: PlayTimeIcon,
    PerfectFor: () => (
        <>
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Give each child a doll or stuffed animal and ask them to pretend that the toy is going to school for the first time."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Allow the children to express their feelings through play and encourage them to talk about what the toy might be feeling. "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"After the playtime, discuss the children’s feelings and reassure them that it is normal to feel scared or sad when saying goodbye to someone they care about "} />
            <DefaultTextWithoutArrowsItallic textdata={"*Alternatively have the kids draw their own characters as opposed to giving each child a toy"} />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.SEPARATION_ANXIETY,
    whyDoesItWorkText: '',
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.SEPARATION_ANXIETY],
    action: [CardActionType.Action],
    level: []
}

