import { View } from 'react-native'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import { Worksheets } from '../../common/worksheets-imports'


const m2Video = {
    id: '1',
    url: 'https://www.youtube.com/embed/n0zV2ytrd7w',
    title: ''
}

export const SwitchesLessonPlansM2EmotionalIntellegenceInDecissionMaking: CardTemplate = {
    cardId: "56195232-b6e8-4e8f-81a1-5a367a133c3a",
    title: 'Emotional intelligence (EI) in decision making',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Utilise emotional intelligence in decision making by considering both emotional and logical factors.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>• Duration 45 minutes </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by engaging students in a discussion about the importance of self awareness in decision making.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to share examples of situations where emotions played a significant role in their decision making processes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to or distribute the Emotional Intelligence in Decision Making  workbook to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the Emotions and Decision Making video [5:22]</DefaultText>
                        <View style={CommonStyles.lessonPlanResourceVideoContainer}>
                            <VideoCard video={m2Video} />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief to ask students what insights they got from the video.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to brainstorm how emotional intelligence impacts our ability to make decisions. Look for:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Understanding Yourself: When you're self aware, you have a better understanding of who you are as a person. You know what you like, what you're good at, and what matters most to you. This knowledge helps you align your decisions with your true self, ensuring that your choices reflect your values and interests.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Identifying Goals and Priorities: Self awareness helps you set meaningful goals and prioritise them. By understanding your strengths and weaknesses, you can identify areas where you want to improve or excel. This clarity enables you to make decisions that support your long term aspirations and avoid getting distracted by short term impulses.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Assessing Consequences: Self awareness allows you to consider the potential outcomes and consequences of your decisions. You can reflect on how your choices might affect yourself and others, helping you make more thoughtful and responsible decisions. This helps you avoid impulsive actions that may have negative consequences in the long run.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Managing Emotions: Being self aware helps you recognise and understand your emotions. When faced with a decision, you can consider how you feel about different options. Emotions can provide valuable insights, but they can also cloud judgment. By acknowledging your emotions and reflecting on their influence, you can make decisions that are not solely based on fleeting feelings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Making Authentic Choices: Self awareness allows you to make decisions that are true to yourself. You can resist the pressure to conform to others' expectations or follow trends that don't align with your values. Instead, you can make choices that reflect who you are and what you genuinely want, leading to a more authentic and fulfilling life.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Emotional and logical factors (25 min):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of considering both emotional and logical factors when making decisions. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emotional Factors: Emotions are an essential part of being human. They provide valuable information about our desires, values, and personal experiences. Here's why considering emotional factors is important:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Gut Instinct: Emotions can provide valuable insights and instincts that guide us in decision making. Sometimes, a strong feeling or intuition can help us make decisions that align with our true selves and what feels right.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Personal Values: Emotions are closely tied to our personal values and beliefs. Considering emotions allows us to make decisions that are consistent with our core values, helping us maintain integrity and live authentically.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Motivation and Passion: Emotions can fuel motivation and passion, driving us to pursue goals and make choices that align with our aspirations. They provide the emotional energy needed to stay committed and enthusiastic about our decisions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Logical Factors: Logic refers to using reasoning, evidence, and critical thinking to evaluate options and outcomes. Logical thinking is important for the following reasons:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Objective Assessment: Logical thinking allows us to objectively evaluate pros and cons, analyse evidence, and consider the facts before making a decision. It helps us see the bigger picture and avoid being swayed solely by our emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Assessing Consequences: Logical thinking helps us consider the potential outcomes and consequences of our decisions. It enables us to weigh the risks and benefits, anticipate potential challenges, and make informed choices based on evidence and reasoning.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Problem Solving: Logical thinking is essential for problem solving. It helps break down complex situations into smaller, manageable parts, and systematically analyse different options to find the most effective solution.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ The Balance: The key to effective decision making is finding a balance between emotional and logical factors. Here's why:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Complementary Perspectives: Emotions and logic offer different perspectives. Emotions provide insights into personal values, desires, and motivations, while logic helps us assess the practicality and potential outcomes of our choices. By considering both, we gain a more comprehensive understanding of the situation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Rationality and Empathy: Combining logic and emotions allows us to make decisions that are both rational and considerate of others' feelings. It enables us to find solutions that not only make sense but also take into account the impact on ourselves and those around us.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Well rounded Decisions: Considering both emotional and logical factors ensures well rounded decisions that integrate our thoughts, feelings, and aspirations. This holistic approach helps us make choices that align with our values, while also considering the practicality and potential consequences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present scenarios (refer to the activity in their workbooks) that require students to analyse and evaluate the emotional and logical aspects before deciding. Allow students to work individually or in small groups to identify the emotional and logical aspects that would need to be taken into consideration.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 1: Choosing a University Course</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>Emily is starting to think about her future and university options. She has always been passionate about animals and nature and wants to make a positive impact on the environment. Emily is torn between pursuing a degree in Environmental Science or a degree in Indigenous Studies with a focus on land management.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Emotional Aspect: Emily needs to consider her emotional connection to nature and her passion for environmental conservation. She needs to assess how fulfilled she would feel studying and working in the field of environmental science.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Logical Aspect: Emily should evaluate the job prospects and practicality of each course. She needs to consider factors such as job opportunities in environmental science or land management, the demand for professionals in those fields in Australia, and the potential for contributing to environmental sustainability or Indigenous land management practices.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 2: Choosing a Social Activity</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>Alex has been invited to two different social activities happening on the same day. One is a movie night with close friends, and the other is a school fundraising event. Alex wants to spend time with friends but also wants to contribute to a good cause. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Emotional Aspect: Alex needs to consider their emotional connection to their friends and how important spending time with them is for their social wellbeing and happiness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Logical Aspect: Alex should evaluate the impact and importance of the school fundraising event. They need to assess the potential benefits of supporting the cause and how it aligns with their personal values.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 3: Buying a New Phone </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>Eddie wants to buy a new smartphone. She is torn between two options: a brand new, top of the line model with advanced features and a high price tag, or a slightly older model that is more affordable but still meets her basic needs.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Emotional Aspect: Sarah should consider her desire for the latest technology and how it makes her feel. She needs to assess her emotional attachment to having the newest and most advanced device.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Logical Aspect: Sarah should evaluate her budget, the practicality of the advanced features, and her actual needs. She needs to consider the long term value of the purchase and whether it aligns with her financial situation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to discuss their decision making process and explain how they utilised emotional intelligence. </DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Closure (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on the importance of self awareness and emotional intelligence in decision making and write down at least one thing they learnt and one thing they will do as a result of this lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss any challenges or insights they gained from the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the value of considering emotions in decision making and the impact it can have on personal wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In addition to checking in via the Switch program, encourage students to make use of the space in their workbook to journal their experiences making decisions and how emotional intelligence played a part.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Mastering the mind workbook, including section M2 emotional intelligence (EI) in decision making'} link={Worksheets.masteryMasteringTheMindLevel7.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Emotional and Logical Decision Making worksheet'} link={Worksheets.m2EmotionalAndLogicalDecisionMaking.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Emotions Aren't the Enemy of Good Decision Making -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://hbr.org/2022/09/emotions-arent-the-enemy-of-good-decision-making' link='https://hbr.org/2022/09/emotions-arent-the-enemy-of-good-decision-making' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The psychology behind irrational decisions -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.ted.com/talks/sara_garofalo_the_psychology_behind_irrational_decisions' link='https://www.ted.com/talks/sara_garofalo_the_psychology_behind_irrational_decisions' />


                        <DefaultText style={CommonStyles.lessonPlanText}>Emotions and Decision Making video -</DefaultText>
                        <View style={CommonStyles.lessonPlanResourceVideoContainer}>
                            <VideoCard video={m2Video} />
                        </View>
                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}