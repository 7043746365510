import { createStackNavigator } from '@react-navigation/stack'
import { ScreenNames } from '../common/screen-names'
import { StudentJoinClassStackNavigatorParamList } from './login-route-param-types'
import { StudentJoinClassNewExistingStudent } from './student-join-class-new-existing-student'
const Stack = createStackNavigator<StudentJoinClassStackNavigatorParamList>()

export const StudentExistingJoinClassMain = () => {

    return (
        <Stack.Navigator screenOptions={{ gestureEnabled: false, headerShown: false }}>
            <Stack.Screen name={ScreenNames.studentJoinClass} component={StudentJoinClassNewExistingStudent} />
        </Stack.Navigator>
    )

}
