import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconAnxious3Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 3)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(2 / 3)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(3 / 3)
    }

    return (
        <Svg viewBox="0 0 515.599 349.14">
            <Path d="M0,0,203.429-134.83" transform="translate(1.934 337.587)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.384,6.113-15.519,21Z" transform="translate(217.228 194.893)" fill="#657170" />
            <Path d="M0,0,127.921-85.016" transform="translate(225.663 190.706)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.376,6.145l9.884,14.872Z" transform="translate(365.44 97.811)" fill="#657170" />
            <Path d="M0,0,125.927-84.948" transform="translate(375.368 92.909)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.333,6.32l9.985,14.8L0,0Z" transform="translate(513.096)" fill="#657170" />
            <G {...onPressLevel1} transform="translate(-1338.582 -220.787)">
                <Path d="M0,0,208.958-.868,210.131-139.8Z" transform="translate(1346.178 569.42)" fill="#edeac0" />
                <Text transform="translate(1397.478 555.227)" fill="#42454a" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Concerned</TSpan></Text>
            </G>
            <G {...onPressLevel3} transform="translate(-1338.582 -220.787)">
                <Path d="M0,0,138-90l-.671,236.529-139.277.111Z" transform="translate(1567.191 421.73)" fill="#d7ce6d" />
                <Text transform="translate(1585.735 512.54)" fill="#42454a" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Worried</TSpan></Text>
            </G>
            <G {...onPressLevel5} transform="translate(-1338.582 -220.787)">
                <Path d="M0,0,138.1-93.6l-1.173,336.028L-.789,242.54Z" transform="translate(1715.582 326.33)" fill="#c5b92e" />
                <Text transform="translate(1752.187 449.289)" fill="#42454a" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Over-</TSpan></Text>
                <Text transform="translate(1731.184 475.289)" fill="#42454a" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">whelmed</TSpan></Text>
            </G>
        </Svg>
    )
}