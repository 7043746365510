import { useReactiveVar } from "@apollo/client";
import { DefaultText } from "../../common/default-text";
import { rvCard } from "./card-color-scheme";


interface Props {
    textdata: string
}

export const DefaultTextWithRvColor = ({ textdata }: Props) => {

    const color = useReactiveVar(rvCard)?.colorScheme

    return (
        <DefaultText style={{ color: color, fontWeight: '700', textAlign: 'center', fontSize: 24, paddingBottom: 10 }}>
            {textdata}
        </DefaultText>
    )

}


