import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { BucketListHeaderIcon } from './src/bucket-list-header-icon'
import { BucketListIcon } from './src/bucket-list-icon'
import { BucketListLineDrawing } from './src/bucket-list-line-drawing'
import { Worksheets } from '../../../common/worksheets-imports'

export const SwitchesEmotionsSadBucketList: CardTemplate = {
    cardId: "44ace9c5-a24c-4de7-8cb2-4bfb8206fad5",
    title: 'Bucket list',
    Header: BucketListHeaderIcon,
    Icon: BucketListIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <View style={{ flex: 1 }}>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata='Making memories and refocusing the mind to the future.' />

            </View>
        </>
    ),
    Images: BucketListLineDrawing,
    CardContent: () => (
        <>
            <DefaulttextWithRightArrow textdata="Find a piece of paper, notebook, or personal device." />
            <DefaulttextWithRightArrow textdata="Begin writing your bucket list." />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata="A bucket list is a list of things that you have not done yet but really want to do someday. Some ideas may be..." />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='See the Eifel Tower' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Be in the Olympics' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Learn a new language' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Write a book' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Swim with dolphins' />
            <DefaulttextWithRightArrow textdata="Challenge yourself to write down 5 things and brainstorm some new ideas you may not have thought of before." />
            <DefaulttextWithRightArrow textdata="Find a friend and share your top 5 bucket list with them. " />
        </>
    )
    ,
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: "Creating a bucket list can be a transformative approach to combatting sadness. This process engages the brain in future-oriented thinking, activating regions associated with motivation and goal setting. As individuals compile their list of aspirations and experiences they wish to accomplish, they stimulate the release of dopamine, the brain's reward neurotransmitter, fostering feelings of enthusiasm and anticipation that counteract sadness. Additionally, a bucket list provides a structured framework for personal growth and adventure, offering a sense of purpose and direction that can be particularly empowering during difficult times. Pursuing items on the list also promotes engagement with life's positive aspects, fostering a renewed sense of joy and fulfilment, ultimately aiding in overcoming sadness.",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to synthesise their interests and desires to create a comprehensive adventure bucket list.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>
                        
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reference the Bucket List Switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by discussing the concept of a bucket list, ie a list of things you want to accomplish before you die. Share some of the things that are on your bucket list.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students why creating a bucket can be an effective strategy for managing sadness. Look for: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Focusing on Positivity: A bucket list is a compilation of things you want to experience, achieve, or enjoy in your lifetime. By creating and reflecting on your bucket list, you shift your focus from negative emotions to positive aspirations and goals. This shift in focus can bring about feelings of hope, excitement, and anticipation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Setting Meaningful Goals: A bucket list typically includes activities or achievements that hold personal significance. Setting meaningful and achievable goals can give you a sense of purpose and direction. Accomplishing items on your bucket list provides a sense of achievement and fulfilment, counteracting feelings of sadness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Creating a Sense of Adventure: A bucket list often includes adventurous or novel activities. Engaging in new and exciting experiences can stimulate the brain, releasing dopamine and other neurotransmitters associated with pleasure. This sense of adventure can contribute to a more positive mood and outlook on life.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Encouraging Forward Thinking: A bucket list encourages you to think about the future and plan for positive experiences. This forward thinking approach can be powerful in breaking the cycle of negative thoughts that contribute to sadness. It promotes a mindset of looking ahead to better, more uplifting moments.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Promoting Self Discovery: Reflecting on what you want to achieve or experience in your life can lead to self discovery. Understanding your values, interests, and desires can provide insights into what truly matters to you. This self awareness can be a foundation for building resilience and coping with challenges.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Providing a Sense of Control: Creating a bucket list gives you a sense of control over your life and the ability to shape your future experiences. This feeling of control can be empowering, especially during times when sadness may make you feel helpless.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Facilitating Social Connection: Many bucket list items involve social activities or connections with others. Engaging in shared experiences can strengthen social bonds, provide emotional support, and contribute to a sense of belonging—all of which are crucial for managing sadness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Encouraging Action: A bucket list is not just a passive document; it's a call to action. Taking steps toward achieving the items on your list requires effort and engagement. Engaging in purposeful actions can be a powerful antidote to sadness, promoting a sense of accomplishment and self efficacy.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Brainstorming (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute 5 post-it notes to each student or one sheet of the Bucket List Cards handout. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ For those with the handout, instruct them to cut out the cards.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the students to write down their top 5 bucket list items and place them on their desk.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Gallery Walk (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once everyone has completed their bucket list items number each person 1 or 2.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Number 1’s will stand up first and walk around talking to the number 2’s (who will be seated at their desk) about their bucket list items and the number 2’s get to share what they have written.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After 5 minutes swap the two groups so everyone gets a turn sharing. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Debrief and Reflection: (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students back together and lead a brief discussion:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share any common themes they noticed among different bucket lists.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how envisioning these activities might affect their overall outlook and emotional wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to consider how they can take small steps toward realising the activities on their bucket lists.</DefaultText>



                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Post-it notes or <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={Worksheets.theBucketListCardHandouts.label} link={Worksheets.theBucketListCardHandouts.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What is Bucket List | Letter Project - Stanford Medicine</DefaultText>
                        <DefaultTextWithLink text='https://med.stanford.edu/letter/bucket-list/what-is-bucket-list' />
                    
                        <DefaultText style={CommonStyles.lessonPlanText}>How to create more meaning with bucket lists</DefaultText>
                        <DefaultTextWithLink text='https://web.frazerconsultants.com/2017/01/how-to-create-more-meaning-with-bucket-lists/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>10 Incredible Benefits of Having a Bucket List</DefaultText>
                        <DefaultTextWithLink text='https://bucketlistjourney.net/10-benefits-to-having-a-bucket-list/' />

                    </>}
            />
        </>
    ),
    audioUrl: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Bucket+list+v2.m4a',
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual, CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Sad]
}

const styles = StyleSheet.create({
})

