import { createStackNavigator } from '@react-navigation/stack'
import { RootView } from '../common/root-view'
import { ScreenNames } from '../common/screen-names'
import { ChangePasswordProfile } from '../common/change-password-profile'
import { TeacherDrawerNavigatorPamarList } from './teacher-route-param-types'
import { TeachersProfile } from '../common/teacher-profile'
import { ForgotPasswordRequest } from '../login/forgot-password-request'
import { ForgotPasswordSubmit } from '../login/forgot-password-submit'
import { UserEditAvatar } from '../common/user-edit-avatar'

const Stack = createStackNavigator<TeacherDrawerNavigatorPamarList>()

export const TeacherProfileMain = () => {

    return (
        <RootView>
            <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={ScreenNames.TeachersProfile}>
                <Stack.Screen name={ScreenNames.TeachersProfile} component={TeachersProfile} options={{ title: 'Profile' }} />
                <Stack.Screen name={ScreenNames.ChangePasswordProfile} component={ChangePasswordProfile} />
                <Stack.Screen name={ScreenNames.LoginForgotPasswordRequest} component={ForgotPasswordRequest} />
                <Stack.Screen name={ScreenNames.LoginForgotPasswordSubmit} component={ForgotPasswordSubmit} />
                <Stack.Screen name={ScreenNames.UserEditAvatar} component={UserEditAvatar} />
            </Stack.Navigator>
        </RootView>
    )
}
