import { DrawerItem } from '@react-navigation/drawer'
import { ReactNode } from 'react'
import { StyleSheet, ViewStyle } from 'react-native'
import { DefaultFontSize } from './const'
import { DecidaColors } from '../../common/decida-colors'

interface Props {
    name: string
    icon?: (props: { focused: boolean, size: number, color: string }) => ReactNode
    onPress?: () => void
}

export const MenuItem = ({ name, icon, onPress = () => { } }: Props) => (
    <DrawerItem
        label={name}
        icon={icon}
        onPress={onPress}
        style={styles.drawerItem}
        labelStyle={styles.labelStyle}
    />
)

const styles = StyleSheet.create({
    drawerItem: {
        fontSize: DefaultFontSize,
        fontWeight: 'bold',
        color: DecidaColors.White,
    } as ViewStyle,
    labelStyle: {
        color: DecidaColors.White
    }
})