import { useNavigation } from '@react-navigation/native'
import { Pressable, StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { ScreenNames } from '../../../common/screen-names'
import { useResponsive } from '../../../common/use-responsive'
import VideoCard from '../../../common/video-card'
import { Emotion, Videos, } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardTemplate, CardType } from '../../common/card-template'
import { ExcitedVideoIcon } from './src/excited-video-icon'

const VIDEOS: Videos[] = [
    // Example
    { id: "1", title: "I feel excited", url: "https://www.youtube.com/embed/0oFlCeyAkJo" },
    { id: "2", title: "Feeling excited", url: "https://www.youtube.com/embed/QiWLwge7-Ek" },
]

export const SwitchesEmotionsExcitedVideos: CardTemplate = {
    cardId: "4c72b805-1e25-486d-91b8-755fea04e077",
    title: 'Excited videos',
    Header: () => <View />,
    Icon: ExcitedVideoIcon,
    lessonPlan: true,
    mainContainerStyle: {
        maxWidth: '100%',
        paddingHorizontal: 20,
    },
    PerfectFor: () => {
        const { width } = useResponsive()
        const { navigate } = useNavigation<any>()
        const navigateToVideoLibrary = () => {
            navigate(ScreenNames.Card, { id: "86303abb-18ed-4b96-9d83-bbad7cb2fa7b", previousScreen: 'emotion', previousEmotion: Emotion.EXCITED })
        }
        return (
            <>
                <View style={[styles.mainContainer, width < 600 ? styles.mobileContainer : styles.webContainer]}>
                    {VIDEOS.map((data) => <VideoCard key={data.url} video={data} />)}
                </View>

                <View style={styles.textContaier}>
                    <DefaultText style={styles.subText}>
                        Find more videos in the
                    </DefaultText>
                    <Pressable onPress={navigateToVideoLibrary}>
                        <DefaultText style={styles.urlText}>
                            VIDEO LIBRARY
                        </DefaultText>
                    </Pressable>
                </View>
            </>
        )
    },
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: '',
    BottomRow: () => <View />,
    type: [CardType.Video],
    time: [],
    people: [],
    place: [],
    category: [],
    action: [],
    level: []
}

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20,
    },
    mobileContainer: { width: '100%' },
    webContainer: {
        paddingBottom: "10%"
    },
    textContaier: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    subText: {
        fontWeight: 'bold'
    },
    urlText: {
        color: DecidaColors.Blue,
        textDecorationLine: 'underline',
        fontWeight: 'bold',
        fontStyle: 'italic'
    }
})

