import { API, graphqlOperation } from "aws-amplify"
import { MutableRefObject } from "react"
import { WorkSheet } from "xlsx"
import { CreateStudentInput, UpdateStudentInput, ListStudentsBySchoolIDQueryVariables, ListStudentsBySchoolIDQuery, GetStudentByNicknameQueryVariables, GetStudentByNicknameQuery, ListStudentsByEmailQueryVariables, ListStudentsByEmailQuery, GetStudentByCognitoUsernameQuery, GetStudentByCognitoUsernameQueryVariables, GetSchoolQuery, GetSchoolQueryVariables, Student } from "../../../common/API"
import { listStudentsBySchoolID, getStudentByNickname, listStudentsByEmail, getStudentByCognitoUsername, getSchool } from "../../../common/graphql/queries"
import { StudentStatus } from "../../models"
import { Message, OtherStudents, Sheet, StudentToCreate, StudentToUpdate } from "./common-import-school-data-const-and-types"
import { convertSpreadsheetToRows } from "./convert-spreadsheet-to-rows"
import { importStudentHasChanged } from "./import-student-has-changed"
import { ListClassTeachersAndStudentsBySchoolIDQueryItem } from "./list-class-teachers-and-students-by-school-id"
import { validateFieldNotUsedInPreviousRows } from "./validate-field-not-used-in-previou-rows"
import { workOutAvatarName } from "./work-out-avatar-name"
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { emailValidator } from "../../common/email-validator"
import { apolloClient } from "../../common/common-state"
import gql from "graphql-tag"
import { adminGetSchoolQuery } from "../../custom-graphql/queries/admin-custom-queries/admin-get-school-query"

type Params = {
    sheet: WorkSheet,
    schoolID: string,
    errors: MutableRefObject<Message[]>,
    warnings: MutableRefObject<Message[]>,
    info: MutableRefObject<Message[]>,
    studentToCreate: MutableRefObject<StudentToCreate[]>,
    studentToUpdate: MutableRefObject<StudentToUpdate[]>,
    otherStudents: MutableRefObject<OtherStudents[]>,
    allClassesFromTeacherSheet: MutableRefObject<string[]>,
    existingClasses: MutableRefObject<ListClassTeachersAndStudentsBySchoolIDQueryItem[]>,
}

export const validateStudentSheet = async ({
    sheet,
    schoolID,
    errors,
    warnings,
    info,
    studentToCreate,
    studentToUpdate,
    otherStudents,
    allClassesFromTeacherSheet,
    existingClasses,
}: Params) => {
    if (sheet == null || schoolID == null) {
        return
    }

    if (!sheet['A5']?.v?.toString().startsWith('Student ID')) {
        errors.current.push({ sheet: Sheet.Student, row: 5, message: 'header column expected, please do not modify row 1-6 of template file.' })
        return
    }

    const schoolStudents = ((await API.graphql(graphqlOperation(
        listStudentsBySchoolID,
        {
            schoolID,
            limit: 10000
        } as ListStudentsBySchoolIDQueryVariables
    )) as GraphQLResult<ListStudentsBySchoolIDQuery>).data?.listStudentsBySchoolID?.items || []).filter(ss => ss?._deleted !== true)

    const rows = convertSpreadsheetToRows({
        startingRow: 7,
        columnOrder: ['studentID', 'firstName', 'lastName', 'email', 'nickname', 'avatar', 'yearLevel'],
        sheet,
        filter: row => row.studentID != null || row.firstName != null || row.lastName != null || row.email != null || row.nickname != null || row.avatar != null || row.yearLevel != null,
        hasDynamicClasses: true,
    }).map(row => ({ ...row, email: row.email?.toLowerCase() }))

    rows.forEach(({ studentID, firstName, lastName, email, nickname, row, classes }, index) => {
        if (firstName == null || lastName == null) {
            errors.current.push({ sheet: Sheet.Student, row, message: `missing mandatory field ${firstName == null ? '"First Name"' : ''}${lastName == null ? (firstName == null ? ', ' : '') + '"Last Name"' : ''}` })
        }

        if (studentID == null && email == null && nickname == null) {
            warnings.current.push({ sheet: Sheet.Student, row, message: ' of StudentID, email addres, or nickname field need to be provided' })
        }

        if (classes.length === 0) {
            errors.current.push({ sheet: Sheet.Student, row, message: 'student need to be assigned to at least 1 class' })
        }

        if (email && emailValidator(email) === "Email is not valid.") {
            errors.current.push({ sheet: Sheet.Student, row, message: 'student email is not valid' })
        }

        validateFieldNotUsedInPreviousRows(rows, 'nickname', index, Sheet.Student, errors)
        validateFieldNotUsedInPreviousRows(rows, 'email', index, Sheet.Student, errors)
        validateFieldNotUsedInPreviousRows(rows, 'studentID', index, Sheet.Student, errors)

        classes.forEach(className => {
            if (existingClasses.current.find(existingClass => existingClass?.name === className) == undefined && allClassesFromTeacherSheet.current.find(teacherSheetClass => teacherSheetClass === className) == undefined) {
                errors.current.push({ sheet: Sheet.Student, row, message: `class "${className}" doesn't exist, please double check the spelling` })
            }
        })
    })

    await Promise.all(
        rows.map(async (studentRow) => {
            const { studentID, firstName, lastName, email, nickname, avatar, yearLevel, row, classes } = studentRow
            if (studentID == null && email == null && nickname == null) {
                return
            }

            if (email && emailValidator(email) !== "Email is valid.") {
                return
            }

            const addMoveSchoolWarning = async (student: Student) => {
                const studentSchool = await apolloClient.query<GetSchoolQuery, GetSchoolQueryVariables>({
                    query: gql`${adminGetSchoolQuery}`,
                    variables: {
                        id: student.schoolID
                    }
                })

                warnings.current.push({ sheet: Sheet.Student, row, message: `${student.firstName} ${student.lastName} will be moved from "${studentSchool.data.getSchool?.name}" to the current school` })
            }

            if (nickname != undefined) {
                const nicknameResponse = await apolloClient.query<GetStudentByNicknameQuery, GetStudentByNicknameQueryVariables>({
                    query: gql`${getStudentByNickname}`,
                    variables: {
                        nickname
                    }
                })

                let students = nicknameResponse.data?.getStudentByNickname?.items.filter(i => i?._deleted !== true) || []

                if (students.length == 0) {
                    const usernameResponse = await apolloClient.query<GetStudentByCognitoUsernameQuery, GetStudentByCognitoUsernameQueryVariables>({
                        query: gql`${getStudentByCognitoUsername}`,
                        variables: {
                            cognitoUsername: nickname
                        }
                    })

                    students = usernameResponse.data.getStudentByCognitoUsername?.items.filter(i => i?._deleted !== true) || []
                }

                if (students.length > 1) {
                    errors.current.push({ sheet: Sheet.Student, row, message: `multiple accounts matched using nickname/username ${nickname}` })
                } else {
                    const student = students[0]
                    if (student == undefined) {
                        errors.current.push({ sheet: Sheet.Student, row, message: `Cannot find student with nickname/username "${nickname}"` })
                        return
                    } else {
                        if (importStudentHasChanged(schoolID, studentRow, student)) {
                            if (student.schoolID !== schoolID) {
                                await addMoveSchoolWarning(student)
                            }

                            studentToUpdate.current.push({
                                id: student.id,
                                _version: student._version,
                                avatar: workOutAvatarName(avatar, student.avatar),
                                cognitoUsername: student.cognitoUsername,
                                email: email || student.email,
                                firstName,
                                lastName,
                                nickname, // searched by nickname so use value from import
                                schoolID,
                                schoolStudentID: studentID || student.schoolStudentID,
                                status: StudentStatus.ACTIVE,
                                yearLevel: yearLevel || student.yearLevel,
                                classes,
                            })
                        } else {
                            otherStudents.current.push({ id: student.id, classes: studentRow.classes })
                        }
                        return
                    }
                }
            }

            if (studentID != undefined) {
                // only search within current school because student id is only guaranteed to be unique within current school
                const students = schoolStudents.filter(s => s?.schoolStudentID === studentID)

                if (students.length > 1) {
                    errors.current.push({ sheet: Sheet.Student, row, message: `multiple accounts matched using student ID ${studentID}` })
                } else {
                    let student = students[0]
                    if (student != undefined) {
                        if (importStudentHasChanged(schoolID, studentRow, student)) {
                            if (student.schoolID !== schoolID) {
                                await addMoveSchoolWarning(student)
                            }

                            studentToUpdate.current.push({
                                id: student.id,
                                _version: student._version,
                                avatar: workOutAvatarName(avatar, student.avatar),
                                cognitoUsername: student.cognitoUsername,
                                email: email || student.email,
                                firstName,
                                lastName,
                                nickname: student.nickname, // use database nickname because we don't alllow changing nickname using import
                                schoolID,
                                schoolStudentID: studentID.toString(), // searched by studentID so use value from import
                                status: StudentStatus.ACTIVE,
                                yearLevel: yearLevel || student.yearLevel,
                                classes,
                            })
                        } else {
                            otherStudents.current.push({ id: student.id, classes: studentRow.classes })
                        }
                        return
                    }
                }
            }

            if (email != undefined) {
                const response = await API.graphql(graphqlOperation(
                    listStudentsByEmail,
                    {
                        email
                    } as ListStudentsByEmailQueryVariables
                )) as GraphQLResult<ListStudentsByEmailQuery>

                const students = response.data?.listStudentsByEmail?.items.filter(i => i?._deleted !== true) || []
                if (students.length > 1) {
                    errors.current.push({ sheet: Sheet.Student, row, message: `multiple accounts matched using email ${email}` })
                } else {
                    const student = students[0]
                    if (student != undefined) {
                        if (importStudentHasChanged(schoolID, studentRow, student)) {
                            if (student.schoolID !== schoolID) {
                                await addMoveSchoolWarning(student)
                            }

                            studentToUpdate.current.push({
                                id: student.id,
                                _version: student._version,
                                avatar: workOutAvatarName(avatar, student.avatar),
                                cognitoUsername: student.cognitoUsername,
                                email: email, // searched by email so use value from import
                                firstName,
                                lastName,
                                nickname: student.nickname, // use database nickname because we don't alllow changing nickname using import
                                schoolID,
                                schoolStudentID: studentID || student.schoolStudentID,
                                status: StudentStatus.ACTIVE,
                                yearLevel: yearLevel || student.yearLevel,
                                classes,
                            })
                        } else {
                            otherStudents.current.push({ id: student.id, classes: studentRow.classes })
                        }
                        return
                    }
                }
            }

            // nickname, studentID, and email all failed to find a match, try to match based on name
            const matches = schoolStudents.filter(s => s?.firstName == firstName && s?.lastName == lastName)

            if (matches.length > 1) {
                errors.current.push({ sheet: Sheet.Student, row, message: `multiple accounts matched using name "${firstName} ${lastName}"` })
                return
            } else {
                const student = matches[0]
                if (student) {
                    if (importStudentHasChanged(schoolID, studentRow, student)) {
                        studentToUpdate.current.push({
                            id: student.id,
                            _version: student._version,
                            avatar: workOutAvatarName(avatar, student.avatar),
                            cognitoUsername: student.cognitoUsername,
                            email: email || student.email,
                            firstName,
                            lastName,
                            nickname: student.nickname, // use database nickname because we don't alllow changing nickname using import
                            schoolID,
                            schoolStudentID: studentID || student.schoolStudentID,
                            status: StudentStatus.ACTIVE,
                            yearLevel: yearLevel || student.yearLevel,
                            classes,
                        })
                    } else {
                        otherStudents.current.push({ id: student.id, classes: studentRow.classes })
                    }
                    return
                }
            }

            // nothing matched, create new account
            studentToCreate.current.push({
                avatar: workOutAvatarName(avatar),
                cognitoUsername: '',
                email,
                firstName,
                lastName,
                nickname: '',
                schoolID,
                schoolStudentID: studentID,
                status: StudentStatus.ACTIVE,
                yearLevel: yearLevel,
                classes,
            })
        })
    )

    info.current.push({ sheet: Sheet.Student, message: `${studentToCreate.current.length} student(s) to be created and ${studentToUpdate.current.length} to be updated` })
}

