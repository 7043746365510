import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { DefaultText } from '../common/default-text'
import { StudentJoinClassBreadCrumbs } from './student-join-class-breadcrumbs'
import { BackButton } from '../common/back-button'
import { StackScreenProps } from '@react-navigation/stack'
import { StudentJoinClassStackNavigatorParamList } from './login-route-param-types'
import { ScreenNames } from '../common/screen-names'
import { Dimensions } from 'react-native';
import { avatars } from '../icon/avatar/avatars'
import { LAST_ACCESSED_VERSION_KEY } from '../common/const'
import { API } from 'aws-amplify'
import { rvIsLoading } from '../common/loading'
import { showAlert } from '../common/universal-alert'
import { authSignIn } from './login-functions'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { versionNumber } from '../env'
import { generatePassword } from '../../common/generate-password'
import { DecidaColors } from '../../common/decida-colors'

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600

export const StudentJoinClassReviewDetails = ({ route: { params: { avatarName, nickname, firstName, lastName, email, bulkSignUpId, className } }, navigation: { navigate } }: StackScreenProps<StudentJoinClassStackNavigatorParamList, 'studentJoinClassReviewDetails'>,) => {
    const goToStudentJoinClassSetDatails = () => {
        navigate(ScreenNames.studentJoinClassSetDetails, { bulkSignUpId, className, avatarName })
    }

    const StudentJoinClass = async () => {
        const tempPassword = generatePassword()
        const verifiedEmail = email === '' ? undefined : email
        try {
            rvIsLoading(true)
            const message = await API.post(
                'AdminQueries',
                '/createBulkSignUpStudent',
                {
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: {
                        avatarName, 
                        nickname, 
                        firstName, 
                        lastName,
                        bulkSignUpId,
                        email: verifiedEmail, 
                        password: tempPassword, 
                        initialLoginSetup: false
                    },
                }
            )
            await AsyncStorage.setItem(LAST_ACCESSED_VERSION_KEY, versionNumber)
            if (message != "Student is created") {
                showAlert({
                    title: 'An error has occured.',
                    message: message.message,
                    leftButtonText: 'Ok',
                    rightButtonText: 'Retry',
                    onRightButtonPress: StudentJoinClass
                })
            } else {
                authSignIn(
                    nickname,
                    tempPassword,
                    navigate,

                )
            }
        } catch (e: any) {
            showAlert({
                title: 'An error has occured.',
                message: e,
                leftButtonText: 'Ok',
                rightButtonText: 'Retry',
                onRightButtonPress: StudentJoinClass
            })
        } finally {
            rvIsLoading(false)
        }

    }


    const Avatar = avatars[avatarName]

    return (
        <View style={styles.container}>
            <View>
                <BackButton />
                <DefaultText style={styles.title}>Review Details</DefaultText>
            </View>
            <StudentJoinClassBreadCrumbs step={3} />
            <View style={styles.inputContainer}>
                <View style={styles.avatarContainer}>
                    <Avatar />
                    <DefaultText style={styles.nickname}>{nickname}</DefaultText>
                </View>
                <DefaultText style={styles.heading}>Class Name</DefaultText>
                <DefaultText style={styles.subtitle}>{className}</DefaultText>
                <DefaultText style={styles.heading}>First Name</DefaultText>
                <DefaultText style={styles.subtitle}>{firstName}</DefaultText>
                <DefaultText style={styles.heading}>Last Name</DefaultText>
                <DefaultText style={styles.subtitle}>{lastName}</DefaultText>
                <DefaultText style={styles.heading}>Email</DefaultText>
                <DefaultText style={styles.subtitle}>{email}</DefaultText>
                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={styles.editButton} onPress={goToStudentJoinClassSetDatails}>
                        <DefaultText style={styles.editButtonText}>Edit</DefaultText>
                    </TouchableOpacity>
                    <TouchableOpacity testID='button-join-class-done' style={styles.joinButton} onPress={StudentJoinClass}>
                        <DefaultText style={styles.buttonText}>Done</DefaultText>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },

    backButtonContainer: {
        flex: 1,
        padding: 5,
    },
    title: {
        fontSize: 30,
        padding: 20,
        alignSelf: 'center',
    },
    avatarContainer: {
        marginBottom: 75,
        marginTop: 25,
        width: windowWidthcondition ? 200 : 150,
        height: windowWidthcondition ? 200 : 150,
    },
    inputContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        height: 650,
    },
    heading: {
        fontSize: 12,
        color: DecidaColors.Gray,

    },
    subtitle: {
        color: DecidaColors.Green,
        marginBottom: 15,
    },
    nickname: {
        fontSize: 30,
        fontWeight: 'bold',
        color: DecidaColors.Green,
        textAlign: 'center',

    },
    joinButton: {
        backgroundColor: DecidaColors.Green,
        padding: 20,
        height: 50,
        width: 150,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    buttonText: {
        color: DecidaColors.White

    },
    buttonContainer: {
        flexDirection: 'row'
    },
    editButton: {
        backgroundColor: DecidaColors.White,
        padding: 20,
        height: 50,
        width: 150,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    editButtonText: {
        color: DecidaColors.Green,
        paddingRight: 10,
        fontSize: 15,

    },
})
