import { View } from 'react-native'
import { Br } from '../../../common/br'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import SwitchCardVideo from '../../../common/switch-card-video'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { Timer } from '../../common/timer'
import { SketchItIcon } from '../../explore/src/sketch-it-icon'
import { SketchItIconInverted } from '../../explore/src/sketch-it-icon-inverted'
import { SketchItImage1 } from './src/sketch-it-image1'
import { SketchItImage2 } from './src/sketch-it-image2'

export const SwitchesEmotionsExcitedSketchIt: CardTemplate = {
    cardId: "540ef487-1c1e-4a1f-90ce-d02471759d9f",
    title: 'Sketch it',
    Header: SketchItIconInverted,
    Icon: SketchItIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <SketchItImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata={"Redirecting chaotic energy toward a focussed task."} />
            </View>
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Grab a pencil and a blank piece of paper."} />
                <DefaultTextWithDownArrow textdata={"Give yourself a time limit (eg. 5 minutes) to sketch something that is within your line of sight."} />
                <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Timer />
                </View>
                <DefaultTextWithoutArrows textdata={"Try again in the same timeframe and see if your drawing improves the second time round."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: '\n Sketching releases feel good chemicals (endorphins), and helps build new neurological connections and pathways by activating both the creative and logical sides of our brain. This builds mental resilience and the ability to focus and think more critically.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice the fundamental skills of drawing basic shapes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice sketching. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Discuss how sketching can be used to manage emotion.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by explaining to students that drawing has positive effects on our brains and emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Mention that drawing releases endorphins, chemicals that make us feel happy, and helps build new connections and pathways in the brain.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that drawing engages both sides of the brain—the right for creativity and the left for logical thinking.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Video Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the introductory video: <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='How to Draw for Beginners - Episode 1.' link='https://www.youtube.com/watch?v=Wz6DrQeQ5rI' /></DefaultText>
                        <SwitchCardVideo video={{
                            id: '1',
                            url: 'https://www.youtube.com/embed/Wz6DrQeQ5rI'
                        }} />
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to pay attention to the basics of drawing, such as lines, 2D shapes (square, triangle, circle), and 3D shapes (cube, sphere, cone, cylinder).</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Drawing Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students practice drawing lines on their own paper.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Progress to drawing basic 2D shapes and then move on to 3D shapes.</DefaultText>
                        <View style={CommonStyles.lessonPlanImageContainer}>
                            <SketchItImage2 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to focus on the process rather than the perfection of their drawings.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Sketching an Object (5-10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to find an object in the room they'd like to sketch or provide specific objects for focus.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct them to think about how the object can be broken down into shapes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set a time limit (3/5/10 minutes) for them to sketch the object as best as they can.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Sharing and Repeat Exercise (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their drawings with the class, emphasising the uniqueness of each interpretation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the variety of approaches and perspectives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Repeat the sketching exercise, challenging students to improve the quality of their drawings the second time.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Debrief and Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students how they felt during the exercise and if they enjoyed it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the potential benefits of sketching for managing emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explore how drawing could be used as a tool for self expression and stress relief.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points about the positive impact of drawing on emotions and cognitive development.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to reflect on how they can incorporate sketching into their lives for emotional wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, guide them to find the Sketch It Switch in the Switch4Schools app for further exploration.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to continue sketching at home and reflect on how it makes them feel. They can experiment with drawing different subjects and explore the Sketch It Switch further on their own.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Paper/scrapbook</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Science-Backed Ways that Sketch Drawing Improves Mood - </DefaultText>
                        <DefaultTextWithLink text="https://www.invaluable.com/blog/sketch-drawing/#:~:text=In%20addition%20to%20creativity%2C%20there,the%20left%20for%20logical%20thinking." />
                        <Br />
                        <DefaultText style={CommonStyles.lessonPlanText}>Feeling Artsy? Here’s How Making Art Helps Your Brain - </DefaultText>
                        <DefaultTextWithLink text='https://www.npr.org/sections/health-shots/2020/01/11/795010044/feeling-artsy-heres-how-making-art-helps-your-brain' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Keep a scrapbook of your sketches as a record of progress over time.' />
            <DefaulTextWithTipsIcon textdata='Create a calm, quiet space for this activity.' />
            <DefaulTextWithTipsIcon textdata='Thought provoking questions: ' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Could you shift the object a little to create more interesting light and shadows?"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"What if you turned the object upside down? "} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"What can you add to the picture to make it more complex or interesting? "} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"What colours would bring the picture to life?"} />


        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Sketch+it.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Excited]
}
