import { AntDesign } from '@expo/vector-icons';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { DecidaColors } from '../../common/decida-colors';
import { DefaultText } from './default-text';

type Props = {
    onPress: () => void
}

const HeaderWellbeingButton = ({ onPress }: Props) => {
    return (
        <TouchableOpacity onPress={onPress} testID='header-profile-wellbeing'>
            <View style={styles.icon}>
                <AntDesign color={DecidaColors.Green} name='heart' size={22} style={{ marginTop: 2 }} />
                <DefaultText style={[styles.iconText]}>My wellbeing</DefaultText>
            </View>
        </TouchableOpacity>
    )
}
export default HeaderWellbeingButton


const styles = StyleSheet.create({
    icon: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5
    },
    iconText: {
        fontSize: 15,
        color: DecidaColors.Gray,
    },
})