import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import { DefaultText } from '../../common/default-text'
import { Worksheets } from '../../common/worksheets-imports'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'


export const SwitchesLessonPlansM6NavigatingSadness: CardTemplate = {
    cardId: "b335cf10-88f8-44de-8b95-822025275158",
    title: 'Navigating sadness: Developing EI ',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define sadness and its impact on emotional wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Explore the causes and manifestations of sadness in individuals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Enhance emotional intelligence skills by fostering self awareness, empathy, and effective coping strategies for dealing with sadness.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>• Duration 45 minutes </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by asking students to share their thoughts on sadness. What does sadness mean to them? How does it affect their lives and emotions?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present the level 7 emotion wheel with an emphasis on the relevant quadrant.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to or distribute the Navigating Sadness workbook to each student.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Sadness (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that sadness is a natural human emotion characterised by feelings of sorrow, grief, or disappointment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the physical and emotional signs of sadness and its potential consequences on personal wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that the term depressed is often used to explain sadness but they are different things. Specifically:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Sadness is a normal human emotion that is usually triggered by a specific event or situation. It is a temporary state of feeling low, down, or unhappy. Sadness is a natural response to various life circumstances such as loss, disappointment, or a challenging situation. It often arises in response to a specific event or circumstance and tends to gradually fade over time as we process and adapt to the situation. Sadness may be accompanied by tears, a sense of heaviness, or a lack of motivation, but it does not typically interfere significantly with daily functioning.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Depression, on the other hand, is a mental health disorder characterised by persistent and intense feelings of sadness, hopelessness, and a lack of interest or pleasure in activities. It goes beyond the normal experience of sadness and is not always tied to a specific event or trigger. Depression can last for weeks, months, or even years if left untreated. It significantly affects a person's mood, thoughts, behaviours, and overall functioning in various areas of life, such as school, work, relationships, and self care. Depression involves a cluster of symptoms that may include prolonged feelings of sadness, loss of interest, changes in appetite or sleep patterns, fatigue, difficulty concentrating, feelings of guilt or worthlessness, and thoughts of self harm or suicide. It is a complex condition that can be caused by a combination of genetic, biological, environmental, and psychological factors.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- It's important to note that while sadness is a common emotion experienced by everyone, depression is a clinical condition that requires professional assessment and treatment. If someone is experiencing persistent and intense feelings of sadness, loss of interest, or other depressive symptoms that interfere with their daily life, it is essential to seek help from a mental health professional.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Differentiating between sadness and depression can be challenging, as they can overlap in some ways. If someone is unsure about their feelings or concerns, it is always recommended to consult a trusted adult or mental health professional for support.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity: Sadness Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Navigating Sadness worksheet and instruct them to write down a personal experience where they felt sad and what caused their sadness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the level 7 emotion wheel and assess the level of intensity of the emotion and label it appropriately.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share their thoughts and observations in pairs or small groups, focusing on the impact of sadness on the person and potential coping strategies.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Causes and Manifestations of Sadness (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss common causes of sadness, such as loss, failure, disappointment, or loneliness. Ask students to share their own experiences or examples related to these causes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explore the different ways in which individuals may manifest sadness, such as withdrawal, changes in mood or behaviour, or physical symptoms. Encourage students to consider both visible and less obvious signs of sadness in themselves and others.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a class discussion on the importance of recognising and acknowledging sadness in oneself and others, fostering empathy and understanding.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Coping Strategies for Sadness (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce various coping strategies for dealing with sadness, such as talking to a trusted person, engaging in self care activities, expressing emotions through art, or writing, and seeking professional help when necessary.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students access the Switch4Schools program on their device and encourage them to explore the available switches assigned to angry on the emotion wheel to bookmark activities they’d like to try.  Alternatively provide a copy of the level 7 quick reference guide. This could also be a homework activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the benefits of some of the techniques selected by the group and when they might be most helpful.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the main points discussed during the lesson, emphasising the importance of emotional intelligence in understanding and navigating sadness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on the lesson and write down at least one thing they learnt and one thing they will do as a result of this lesson in their workbook.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss any challenges or insights they gained from the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In addition to checking in via the Switch program, encourage students to make use of the space in their workbook to journal their thoughts and feelings over the coming week to better understand their triggers and improve their coping strategies.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to apply the coping strategies and empathy skills learned to effectively manage their own sadness and provide support to others in need.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>Extension:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the grief cycle and the normal responses to loss.  </DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Level 7 emotion wheel'} link={"https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/LV7+how+are+you+feeling+today+cut+out.pdf"} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Mastering the mind workbook, including section M6: Navigating Sadness: Developing Emotional Intelligence'} link={Worksheets.masteryMasteringTheMindLevel7.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Is It Depression or Sadness? Learn the Signs  -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.healthline.com/health/depression/depression-vs-sadness#takeaway' link='https://www.healthline.com/health/depression/depression-vs-sadness#takeaway' />

                        <DefaultText style={CommonStyles.lessonPlanText}>It's okay to feel sad -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.betterhealth.vic.gov.au/health/healthyliving/its-okay-to-feel-sad' link='https://www.betterhealth.vic.gov.au/health/healthyliving/its-okay-to-feel-sad' />
                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}