import { View, StyleSheet, Dimensions, ScrollView, useWindowDimensions } from 'react-native'
import { PieChart, PieChartData } from 'react-native-svg-charts'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'
import { Emotion } from '../../common/API'
import { G, Line, Text } from 'react-native-svg'
import { StudentCheckIn } from '../../common/API'
import { useResponsive } from '../common/use-responsive'

interface Props {
    studentCheckIn: StudentCheckIn[]
}

export const TeacherStatsPieChartLastCheckIn = ({ studentCheckIn }: Props) => {
    const { width } = useResponsive()

    const happyData = studentCheckIn
        .filter(m => m.emotion === Emotion.HAPPY)
    const sadData = studentCheckIn
        .filter(m => m.emotion === Emotion.SAD)
    const anxiousData = studentCheckIn
        .filter(m => m.emotion === Emotion.ANXIOUS)
    const angryData = studentCheckIn
        .filter(m => m.emotion === Emotion.ANGRY)
    const excitedData = studentCheckIn
        .filter(m => m.emotion === Emotion.EXCITED)
    const scaredData = studentCheckIn
        .filter(m => m.emotion === Emotion.SCARED)

    const calculatePercentage = (part: number) => {
        if (!studentCheckIn.length) {
            return "No data available"
        }
        var total = (part / sum) * 100
        return `${total.toFixed(2)} %`
    }

    const data: PieChartData[] = [
        {

            key: 'Happy',
            value: happyData.length,
            svg: {
                fill: DecidaColors.Happy,
            },
        },

        {
            key: 'Anxious',
            value: anxiousData.length,
            svg: {
                fill: DecidaColors.Anxious,
            },
        },
        {
            key: 'Angry',
            value: angryData.length,
            svg: {
                fill: DecidaColors.Angry
            },
        },
        {
            key: 'Excited',
            value: excitedData.length,
            svg: {
                fill: DecidaColors.Excited
            },
        },
        {
            key: 'Scared',
            value: scaredData.length,
            svg: {
                fill: DecidaColors.Scared
            },
        },
        {
            key: 'Sad',
            value: sadData.length,
            svg: {
                fill: DecidaColors.Sad
            },
        },

    ]

    const sum = sadData.length + scaredData.length + excitedData.length + angryData.length + anxiousData.length + happyData.length


    const Labels = ({ slices }: any) => {
        return slices.map((slice: any, index: any) => {
            const { labelCentroid, pieCentroid, data } = slice;

            return (
                <G key={index}>
                    <Line
                        x1={labelCentroid[0]}
                        y1={labelCentroid[1]}
                        x2={pieCentroid[0]}
                        y2={pieCentroid[1]}
                        stroke={data.svg.fill}
                        strokeWidth={2}
                        scale={0.75}
                    />


                    <Text
                        x={labelCentroid[0]}
                        y={labelCentroid[1]}
                        fontSize={12}
                        textAnchor={'middle'}
                        alignmentBaseline={'middle'}
                        fontWeight={'bold'}
                        fill={data.svg.fill}
                        fontFamily='HelveticaNeue'

                    >

                        {calculatePercentage(data.value)}
                    </Text>
                </G>
            )
        })
    }


    return (
        <View style={styles.page}>
            <View style={[styles.internalScroll, { flexDirection: width < 600 ? 'row' : 'column' }]}>
                <View style={styles.column}>
                    <DefaultText style={width < 600 ? styles.headerTextMobile : styles.headerText}>Distribution of Emotion</DefaultText>
                    <PieChart
                        style={{ height: 250, width: 300, transform: [{ scale: 0.8 }] }}
                        data={data}
                        innerRadius={0}
                        outerRadius={70}
                        padAngle={0}
                        labelRadius={110}
                    >
                        <Labels />
                    </PieChart>
                </View>
            </View>
        </View>

    )

}

const styles = StyleSheet.create({
    page: {
        maxHeight: 300
    },
    internalScroll: {

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    legendContainer: {
        marginTop: 40,
        marginLeft: -50,
    },
    legend: {

    },
    legendRow: {
        alignItems: 'center',
        flexDirection: 'row'

    },
    legendText: {
        fontSize: 16,
    },
    legendDot: {
        margin: -18,
    },
    legendHeading: {
        fontWeight: 'bold',
        fontSize: 18,
        opacity: 0.8,
    },
    column: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerTextMobile: {
        marginTop: 0,
        marginBottom: -20,
    },
    headerText: {
        marginTop: 20,
        marginBottom: 0,
    }
})