import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { ExceptionalAwardsIcon } from '../../icons/exceptional-awards-icon'
import { ExceptionalAwardsIconInverted } from '../../icons/exceptional-awards-icon-inverted'
import DefaultTextWithCustomLink from '../../../common/default-text-with-custom-link'
import { Worksheets } from '../../../../common/worksheets-imports'

export const SwitchesActivitiesFunAndPositiveExceptionalAwards: CardTemplate = {
    cardId: "97d83118-50de-423d-b746-28775db5eca4",
    title: 'Exceptional awards',
    Header: ExceptionalAwardsIconInverted,
    Icon: ExceptionalAwardsIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Creating a sense of achievement and value within the classroom "} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"‘Exceptional tokens’ (accessible via the additional resources section)"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"‘Exceptional awards’ (accessible via the additional resources section)"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"‘Goals and score sheet’ (accessible via the additional resources section)"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Decide on some behaviours that you would like to see students exhibit in your class. Such as manners, respect, curiosity, or engagement. Select behaviours that you are confident your students can accomplish."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Define these behaviours to your class and explain that by doing them they will have a chance at gaining an ‘exceptional token’ and if they are the person who has gained the most exceptional tokens then they will get the exceptional award (accessible via the additional resources section)."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Decide on a timeframe that you and your class will be running the activity and how many tokens the class aims to have received as a collective by that time. "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Maintain a record of how many tokens each student has earned and how many tokens the class aims to get by a certain time. A ‘goals and score sheet’ is available in the additional resources section."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Each time a student earns a token put it next to their name on the ‘goals and score sheet’."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Engage the students in the process of selecting the incentive. This will be the prize that the class receives if they achieve their token goal. Prizes for your class could be; watch a movie of their choice, free time, homework free week, arts and craft activities, and additional outdoor play time."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Additional resources:"} />
            <DefaultTextWithCustomLink text={"Exceptional tokens"} link={Worksheets.exceptionalAwards.uri} />
            <DefaultTextWithCustomLink text={"Exceptional awards"} link={Worksheets.exceptionalAwardsToken.uri} />
            <DefaultTextWithCustomLink text={"Goals and score sheet"} link={Worksheets.exceptionalAwardsGoals.uri} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.FUN_AND_POSITIVE,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Remember:"} />
            <DefaulTextWithTipsIcon textdata="You do not have to give out an award every week. Only recognise the class when someone has done something worth recognising and when there is more than one thing, feel free to give out multiple awards." />
        </>

    ),
    audioUrl: "",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.FUN_AND_POSITIVE],
    action: [CardActionType.Action, CardActionType.Thought],
    level: []

}

