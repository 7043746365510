import { Linking, StyleSheet, Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import VideoCard from '../../../common/video-card'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { BeCreativeHeader } from './src/be-creative-header'
import { BeCreativeIcon } from './src/be-creative-icon'
import { BeCreativeImage1 } from './src/be-creative-image1'
import { BeCreativeLessonPlan } from './src/be-creative-lesson-plan'
import { Worksheets } from '../../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import SwitchCardVideo from '../../../common/switch-card-video'

const beCreativeVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/kshboJxmU7k",
    title: "",
}

export const SwitchesEmotionsHappyBeCreative: CardTemplate = {
    cardId: "570dac54-f8e3-48a7-81b6-5dc30838e6ef",
    title: 'Be creative',
    Header: BeCreativeHeader,
    Icon: BeCreativeIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"When you feel happy and you want to experiment with new ideas"} />
        </>
    ),
    Images: () => (
        <>
            <BeCreativeImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Make sure you have some space and some drawing or painting materials."} />
                <DefaulttextWithRightArrow textdata={"Allow yourself to relax. "} />
                <DefaulttextWithRightArrow textdata={"Think about what has happened to you, and what you have seen. Pay attention to the world around you and consider how you might be able to express your observations, experiences, and feelings in a creative way through your artwork."} />
                <DefaulttextWithRightArrow textdata={"Use your imagination. Come up with as many quick sketches and ideas as possible, even if they seem silly or unrealistic. The more ideas you generate, the more likely you are to come up with a really good idea."} />
                <DefaulttextWithRightArrow textdata={"Choose one of your ideas and have fun turning it into a masterpiece!"} />
            </View>
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata="Don't be afraid to experiment and try new things. Creativity often involves stepping outside of your comfort zone and taking risks." />
            <DefaulTextWithTipsIcon textdata="Keep an open mind. Be receptive to new ideas and approaches, and try to stay flexible and adaptable." />
            <DefaulTextWithTipsIcon textdata="Practice regularly. The more you practice creativity, the more natural it will become." />
            <DefaulTextWithTipsIcon textdata="Seek inspiration from others. Look for inspiration in the work of other creative people, whether in your field or in a completely different area." />
            <DefaulTextWithTipsIcon textdata="Don't worry about perfection. Creativity is often about trial and error, so don't worry about producing perfect results. The process of creating and experimenting is often just as important as the final product." />
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: `Engaging in creative activities can increase levels of dopamine in the brain, which is associated with feelings of pleasure and reward. Additionally, creativity has been linked to increased levels of the hormone oxytocin, which is associated with feelings of bonding and social connection.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn that there are multiple different ways to be creative.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn the importance of creativity in everyday life.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Articulate how to apply creativity to their own work.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to brainstorm a list of things they like to do that involve creativity (e.g. drawing, singing, dancing).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write the list on the board and discuss with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Define creativity as the ability to generate new and innovative ideas.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of creativity in problem solving, communication, and personal expression.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Share examples of creativity in various fields (e.g. art, science, technology).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Art: Creativity is at the heart of many artistic endeavours, whether it's painting, sculpture, music, dance, or literature. Artists use their creativity to create original works of art and to express their ideas and emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Science: Creativity is also important in the field of science. Scientists use their creativity to come up with new hypotheses, theories, and methods of investigation. They also use their creativity to find innovative solutions to problems and to make new discoveries.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Business: Creativity is essential in the world of business, where it can be used to develop new products, services, and marketing strategies. Businesses that are able to tap into their creativity are often more successful and able to adapt to changing markets and consumer needs.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Education: Creativity is a valuable asset in the field of education, where it can be used to design engaging lesson plans, create interactive learning experiences, and find new ways to teach and assess student learning.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Social work: Creativity is important in the field of social work, where it can be used to develop new programs and services that meet the needs of communities and individuals. Social workers use their creativity to find innovative solutions to social problems and to help people improve their lives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Engineering: Engineers use their creativity to design and build new structures, systems, and products. They must think creatively to find solutions to complex problems and to design products that are both functional and aesthetically pleasing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Medicine: Doctors and other healthcare professionals use their creativity to develop new treatments and therapies. They must think creatively to find solutions to medical challenges and to use limited resources effectively.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students brainstorm a list of problems they have encountered in their daily lives and how they used creativity to solve them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss as a class how creativity can be applied to problem solving.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide examples of how to encourage creativity (e.g. asking open ended questions, creating a supportive environment).</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students work in pairs or small groups to generate creative solutions to a problem (e.g. "How can we make lunch time more fun for everyone?").</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to brainstorm and share their ideas with the group.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ As a class, discuss the different solutions and how they demonstrate creativity.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Be Creative switch and generate</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give each student the <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='My Dream Holiday worksheet.' link={Worksheets.myDreamHoliday.uri} /></DefaultText>
                        <View style={styles.imageContainer}>
                            <BeCreativeLessonPlan />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to use their imagination and be creative in their responses.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students share their creations with the class or with a partner.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Review the concept of creativity and its importance in problem solving and personal expression.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to continue practicing and developing their creativity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students are using their own device, refer students to the Be Creative switch in the Switch4Schools program.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Arts and craft materials</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pencils & Paper</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Be+creative+slides.pptx' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='My Dream Holiday worksheet.' link={Worksheets.myDreamHoliday.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Why is Creativity Important and What Does it Contribute?</DefaultText>
                        <DefaultTextWithLink text='https://www.youth.ie/articles/why-is-creativity-important-and-what-does-it-contribute/#:~:text=Creativity%20allows%20us%20to%20view,can%20help%20us%20overcome%20prejudices.' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Why Creativity Makes You Happier</DefaultText>
                        <DefaultTextWithLink text='https://deltadiscoverycenter.com/link-between-creativity-happiness-creativity/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to be a more creative person </DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='How to be a more creative person | Bill Stainton | TEDxStanleyPark<' link='https://www.youtube.com/watch?v=kshboJxmU7k' />

                        <SwitchCardVideo video={beCreativeVideo} />
                    </>}
            />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Be+Creative+Audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought, CardActionType.Action],
    level: [CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Happy]
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
})
