import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { AntDesign } from '@expo/vector-icons'
import { ActivityIndicator, Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native'
import { CreateFavouriteMutation, CreateFavouriteMutationVariables, DeleteFavouriteMutation, DeleteFavouriteMutationVariables, ListFavouriteByCognitoUsernameQuery, ListFavouriteByCognitoUsernameQueryVariables } from '../../../common/API'
import { DecidaColors } from '../../../common/decida-colors'
import { createFavourite, deleteFavourite } from '../../../common/graphql/mutations'
import { listFavouriteByCognitoUsername } from '../../../common/graphql/queries'
import { IconSize } from '../../common/const'
import { DefaultText } from '../../common/default-text'
import { rvCurrentUser } from '../../login/login-state'
import { TCardColorScheme } from './card-color-scheme'

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600

type Props = {
    cardID: string
    colorScheme: TCardColorScheme
}

const FavouriteButton = ({ cardID, colorScheme }: Props) => {


    const user = useReactiveVar(rvCurrentUser)
    const { data } = useQuery<ListFavouriteByCognitoUsernameQuery, ListFavouriteByCognitoUsernameQueryVariables>(gql`${listFavouriteByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
    const currentFavourites = data?.listFavouriteByCognitoUsername?.items || []
    const favouriteItem = currentFavourites.find((fav) => fav?.cardId === cardID && fav._deleted !== true)

    const [createFavouriteMutation, { loading: loadingOnCreate }] = useMutation<CreateFavouriteMutation, CreateFavouriteMutationVariables>(gql`${createFavourite}`)
    const [deleteFavouriteMutation, { loading: loadingOnDelete }] = useMutation<DeleteFavouriteMutation, DeleteFavouriteMutationVariables>(gql`${deleteFavourite}`)

    const handleFavourite = () => {
        if (favouriteItem) {
            deleteFavouriteMutation({
                variables: {
                    input: {
                        id: favouriteItem?.id,
                        _version: favouriteItem._version
                    }
                }
            })
            return
        }

        createFavouriteMutation({
            variables: {
                input: {
                    cardId: cardID,
                    cognitoUsername: user?.username || "",
                }
            }
        })
    }

    const isLoading = loadingOnCreate || loadingOnDelete


    return (
        <TouchableOpacity disabled={isLoading} style={styles.imagecontainer} onPress={handleFavourite}>
            <View style={styles.favIconContainer}>
                {(loadingOnCreate || loadingOnDelete) ? (
                    <ActivityIndicator color={colorScheme} />
                ) : (
                    <AntDesign name={favouriteItem ? "star" : "staro"} size={IconSize} color={colorScheme} />
                )}
            </View>
            <DefaultText style={[styles.extraTipsText, { color: DecidaColors.GrayText }]}>
                Favourite
            </DefaultText>
        </TouchableOpacity>
    )
}
export default FavouriteButton
const styles = StyleSheet.create({
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    favIconContainer: {
        width: IconSize,
        height: IconSize,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
    },
    extraTipsText: {
        fontSize: windowWidthcondition ? 15 : 10,
        marginTop: 5

    },
    imagecontainer: {
        padding: windowWidthcondition ? 10 : 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
})