import { View } from 'react-native'
import SheetsAndPosterCardList from '../../../common/sheetsAndPosterList'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardTemplate } from '../../common/card-template'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import { posters } from '../common/posters-import'
import { PostersHeader } from '../images/posters-header'
import { PostersIcon } from '../images/posters-icon'


export const ToolsPosters: CardTemplate = {
    cardId: "b2b369eb-0256-488b-94ba-2521e1c3e2fc",
    title: 'Printables',
    Header: PostersHeader,
    Icon: PostersIcon,
    PerfectFor: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
            <SheetsAndPosterCardList data={posters} />
        </>

    ),
    noRating: true,
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.POSTERS,
    whyDoesItWorkText: '',

}

