import { View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import VideoCard from '../../common/video-card'
import { Worksheets } from '../../common/worksheets-imports'


export const SwitchesLessonPlansM10AdvancedResilience: CardTemplate = {
    cardId: "1eb4486d-6281-49ad-8877-cde9de26d4cb",
    title: 'Advanced resilience',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be equipped with advanced resilience skills to navigate challenges, setbacks, and transitions effectively.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>• Duration 45 minutes </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by discussing the concept of resilience and its importance in navigating life's challenges.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share their understanding of resilience and any personal experiences where resilience played a role.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that this lesson will focus on advanced resilience skills that can enhance their ability to bounce back and thrive in the face of adversity.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Resilience (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the key components of resilience, such as adaptability, optimism, self regulation, and problem solving.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a class discussion on the benefits of developing resilience, both in personal and academic contexts. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide examples of resilient individuals or stories to inspire and illustrate the concept.</DefaultText>
                        <View style={CommonStyles.lessonPlanResourceVideoContainer}>
                            <VideoCard video={{
                                id: '1',
                                url: 'https://www.youtube.com/embed/kG5MKvTxVUI',
                                title: ''
                            }} />
                        </View>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reframing Challenges (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of cognitive reframing, which involves shifting perspectives and finding alternative ways to interpret challenges.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain different cognitive reframing techniques, such as finding opportunities in setbacks, focusing on strengths, and practicing positive self talk.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Cultivating Growth Mindset (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the difference between a fixed mindset and a growth mindset.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that a growth mindset helps individuals view challenges as opportunities for growth and learning.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide examples of how embracing a growth mindset can enhance resilience and lead to personal development.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflective activity (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the students to take a few minutes to reflect on their own experiences and think about a challenge they have faced or are currently facing. Encourage them to choose a challenge that they feel comfortable exploring and reflecting upon.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once they have chosen a challenge, ask the students to write it down at the top of their paper.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the students to reflect on the challenge and think about alternative perspectives or reframes that could help them view the challenge in a more positive or growth oriented way. Encourage them to consider different angles, opportunities for learning, or personal strengths that can be applied to the challenge.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give the students time to engage in this reflective process. Remind them to be open minded, creative, and honest with themselves.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After the reflection time, ask the students to write down their reframed perspective or alternative interpretation of the challenge. Encourage them to describe how this new perspective can contribute to their resilience and growth.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once the students have completed their written reflection, invite them to share their thoughts and reframes in a class discussion (if they feel comfortable doing so) or in smaller group discussions. Provide a supportive and non judgmental environment for sharing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate the discussion by asking questions that encourage students to explain their reframes, share insights, and discuss the potential positive outcomes of adopting these new perspectives.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Building Support Networks (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of social support in building resilience.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explore different types of support networks, such as friends, family, mentors, and community resources.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a small group discussion where students share the types of support they currently have and brainstorm ways to strengthen their support networks.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion and Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points covered in the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to reflect individually on one area of their life where they would like to enhance their resilience.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Close the lesson by emphasising the importance of practicing and applying the learned resilience skills in real life situations.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={"Mastering the mind workbook, including section M10: Advanced Resilience"} link={Worksheets.masteryMasteringTheMindLevel7.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>

                        <View style={CommonStyles.lessonPlanResourceVideoContainer}>
                            <VideoCard video={{
                                id: '1',
                                url: 'https://www.youtube.com/embed/kG5MKvTxVUI',
                                title: ''
                            }} />
                        </View>

                        <DefaultText style={CommonStyles.lessonPlanText}>45 Famous Failures Who Became Successful People -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.developgoodhabits.com/successful-people-failed/' link='https://www.developgoodhabits.com/successful-people-failed/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Building Resilience in Young People -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.worthit.org.uk/blog/building-resilience-young-people#:~:text=Resilience%20helps%20young%20people%20gain,or%20a%20range%20of%20stressors' link='https://www.worthit.org.uk/blog/building-resilience-young-people#:~:text=Resilience%20helps%20young%20people%20gain,or%20a%20range%20of%20stressors' />
                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}