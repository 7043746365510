import { useRef, useState } from 'react'
import { TouchableOpacity, View, StyleSheet, ViewStyle, TextStyle } from 'react-native'
import { DefaultFontSize } from '../../common/const'
import { DefaultText } from '../../common/default-text'
import { DecidaColors } from '../../../common/decida-colors'

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        width: 300,
        height: 75,
        paddingTop: 20,
    },
    timerContainer: {
        width: 300,
        height: 50,
        alignContent: 'flex-end',
        justifyContent: 'flex-end'


    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    button: {
        width: 70,
        height: 25,
        borderRadius: 8
    },
    start: {
        width: 70,
        height: 25,
        borderRadius: 8,
        backgroundColor: DecidaColors.Green
    },
    stop: {
        width: 70,
        height: 25,
        borderRadius: 8,
        backgroundColor: DecidaColors.Red

    },
    reset: {
        width: 70,
        height: 25,
        borderRadius: 8,
        backgroundColor: DecidaColors.Yellow

    },
    buttonText: {
        textAlign: 'center',
        color: DecidaColors.White
    },
    timer: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    timerText: {
        fontSize: 28,
        fontWeight: 'bold'

    },
    inputContainer: {

    },
    itemInput: {

    },
    mainContainer: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    row: {
        width: 500,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: DecidaColors.Gray,
        marginVertical: 20,
    } as ViewStyle,
    textInput: {
        width: 250,
        borderColor: DecidaColors.Gray,
        borderWidth: 1,
        fontSize: DefaultFontSize,
        padding: 8,
        paddingLeft: 7,
        paddingRight: 7,
        marginTop: 7,
        marginBottom: 7,
        borderRadius: 4,
        backgroundColor: DecidaColors.InputField

    } as TextStyle,
    itemText: {
        position: 'absolute',
        top: 15,
        left: 20,
        fontWeight: 'bold',
        color: 'white',
        fontSize: 22,

    },
    plusIcon: {
        alignItems: 'center',
        marginTop: 12,

    },
    header: {
        color: DecidaColors.White,
        fontWeight: 'bold',
        paddingLeft: -20,
        paddingTop: 15,
    },
    column: {
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
    },
    scrollViewContainer: {
        height: 450,

    },
    headingContainer: {
        position: 'absolute',
        left: 12,
    },
    deleteItemRow: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: "center",
    },
    deleteIcon: {
    },
    timePicker: {
        width: 70,
        height: 25,
        borderRadius: 8,
        backgroundColor: DecidaColors.Gray
    },
    timesUp: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    absolute: {
        position: 'absolute',
        right: -60
    },
    absolutePlus: {
        position: 'absolute',
        right: 20
    }

})


export const Timer = () => {
    const [timesUp, setTimesUp] = useState(false)
    const [time, setTime] = useState(0)
    const [timeLength, SetTimeLength] = useState(0)
    const [pickTime, setPickTime] = useState(true)


    const timer = useRef(time)
    const timerInterval = useRef<number | undefined>(undefined)

    const startTimer = () => {
        if (timerInterval.current === undefined) {
            timerInterval.current = setInterval(() => {
                timer.current--
                setTime(timer.current)
                if (timer.current === 0) {
                    stopTimer()
                    setTimesUp(true)
                }
            }, 1000)
        }
    }

    const stopTimer = () => {
        if (timerInterval.current !== undefined) {
            clearInterval(timerInterval.current)
            timerInterval.current = undefined
        }
    }

    const resetTimer = () => {
        timer.current = timeLength
        setTime(timeLength)
    }

    const add3Mins = () => {
        timer.current = 180
        setTime(180)
        SetTimeLength(180)
        setPickTime(false)
    }

    const add5Mins = () => {
        timer.current = 300
        setTime(300)
        SetTimeLength(300)
        setPickTime(false)
    }

    const add10Mins = () => {
        timer.current = 600
        setTime(600)
        SetTimeLength(600)
        setPickTime(false)
    }

    const newTimeLength = () => {
        stopTimer()
        setPickTime(true)
    }

    const resetTimeOut = () => {
        setTimesUp(false)
        resetTimer()
    }





    return (
        <View style={styles.container}>
            <View style={styles.mainContainer}>
                <View style={styles.timerContainer}>
                    <View style={styles.timer}>
                        <DefaultText style={styles.timerText}>
                            {time.toString()}
                        </DefaultText>
                    </View>
                    <View style={styles.buttonContainer}>
                        {!timesUp ?
                            !pickTime ?
                                <>
                                    <TouchableOpacity style={styles.start} onPress={startTimer}>
                                        <DefaultText style={styles.buttonText}>
                                            Start
                                        </DefaultText>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.stop} onPress={stopTimer}>
                                        <DefaultText style={styles.buttonText}>
                                            Stop
                                        </DefaultText>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.reset} onPress={resetTimer}>
                                        <DefaultText style={styles.buttonText}>
                                            Reset
                                        </DefaultText>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.timePicker} onPress={newTimeLength}>
                                        <DefaultText style={styles.buttonText}>
                                            New
                                        </DefaultText>
                                    </TouchableOpacity>
                                </>
                                :
                                <>
                                    <TouchableOpacity style={styles.timePicker} onPress={add3Mins}>
                                        <DefaultText style={styles.buttonText}>
                                            3 min
                                        </DefaultText>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.timePicker} onPress={add5Mins}>
                                        <DefaultText style={styles.buttonText}>
                                            5 min
                                        </DefaultText>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.timePicker} onPress={add10Mins}>
                                        <DefaultText style={styles.buttonText}>
                                            10 min
                                        </DefaultText>
                                    </TouchableOpacity>

                                </>

                            :
                            <View>
                                <DefaultText>
                                    Time is up!
                                </DefaultText>

                                <TouchableOpacity style={styles.reset} onPress={resetTimeOut}>
                                    <DefaultText style={styles.buttonText}>
                                        Reset
                                    </DefaultText>
                                </TouchableOpacity>
                            </View>
                        }


                    </View>
                </View>
            </View>
        </View>

    )
}

