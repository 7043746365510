import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { DrawerHeaderProps } from '@react-navigation/drawer/lib/typescript/src/types'
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import { SchoolAdminUI } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { getSchoolAdminByCognitoUsername } from '../../common/graphql/queries'
import { BackButton, BackButtonOnPress } from '../common/back-button'
import { DefaultText } from '../common/default-text'
import { Header } from '../common/header'
import { rvShowPopoverSchoolAdmin } from '../common/pop-over-menu-state'
import { ScreenNames } from '../common/screen-names'
import { useResponsive } from '../common/use-responsive'
import { GetSchoolCampusesAndGroupsQueryResponse, getSchoolCampusesAndGroupsQuery } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query'
import { envBackgroundColor } from '../env'
import { IconDownArrow } from '../icon/icon-down-arrow'
import { IconGraph } from '../icon/icon-graph'
import { IconHeaderUserLoginAvatar } from '../icon/icon-header-user-login-avatar'
import { IconHome } from '../icon/icon-home'
import IconResources from '../icon/icon-resources'
import IconSwitch4SchoolLogoNew from '../icon/icon-switch-4-school-logo-new'
import { rvCurrentUser } from '../login/login-state'
import { openUrlWebView } from '../web-view/open-url-web-view'
import { SchoolAdminNavigationParams } from './school-admin-navigation-params'

export const SchoolAdminHeader = (props: DrawerHeaderProps) => {
    const user = useReactiveVar(rvCurrentUser)
    const showPopover = useReactiveVar(rvShowPopoverSchoolAdmin)
    const { isNarrowScreen } = useResponsive()


    const { data: resultSchoolAdmin } = useQuery(gql`${getSchoolAdminByCognitoUsername}`, { variables: { cognitoUsername: user?.username }, skip: !user?.username })
    const currentSchoolAdmin = resultSchoolAdmin?.getSchoolAdminByCognitoUsername?.items[0]
    const { data: resultSchool, loading: isLoadingSchool } = useQuery<GetSchoolCampusesAndGroupsQueryResponse>(getSchoolCampusesAndGroupsQuery, { variables: { id: currentSchoolAdmin?.schoolID, skip: !currentSchoolAdmin } })

    const schoolData = resultSchool?.getSchool
    const isSchoolWithHierarchy = schoolData?.schoolAdminUI === SchoolAdminUI.HIERARCHY
    const route = useRoute<RouteProp<SchoolAdminNavigationParams>>()

    const { navigate } = useNavigation<NavigationProp<SchoolAdminNavigationParams>>();

    const goToStatsPage = () => {
        navigate(ScreenNames.SchoolAdminStats)
    }

    const goToHomePage = () => {
        navigate(ScreenNames.SchoolAdminHome)
    }


    const togglePopoverMenuVisibility = () => {
        rvShowPopoverSchoolAdmin(!showPopover)
    }

    const handleBack: BackButtonOnPress = (patchedGoBack) => {
        if (isSchoolWithHierarchy && route.name === ScreenNames.SchoolAdminEditStudent) {
            navigate(ScreenNames.SchoolAdminHome)
            return
        }
        
        history.back()
    }

    const goToGettingStartedPage = () => {
        openUrlWebView("https://www.switch4schools.com.au/gettingstarted")
    }

    return (
        <>
            <Header
                {...props}
                disableDrawer
                leftChildren={
                    <>
                        {!isNarrowScreen && (
                            <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                                <TouchableOpacity style={styles.logo} onPress={goToHomePage}>
                                    <IconSwitch4SchoolLogoNew />
                                </TouchableOpacity>
                                <DefaultText style={styles.adminText}>Administration Portal</DefaultText>
                            </View>
                        )}
                    </>
                }
                centerChildren={
                    <View />
                }
                rightChildren={

                    <>
                        <TouchableOpacity onPress={goToHomePage} testID='header-profile-home'>
                            <View style={styles.icon}>
                                <IconHome color={DecidaColors.Gray} />
                                <DefaultText style={styles.iconText}>Home</DefaultText>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={goToGettingStartedPage}>
                            <View style={styles.icon}>
                                <IconResources color={DecidaColors.Gray} />
                                <DefaultText style={styles.iconText}>Resources</DefaultText>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={goToStatsPage}>
                            <View style={styles.icon}>
                                <IconGraph color={DecidaColors.Gray} />
                                <DefaultText style={styles.iconText}>Reports</DefaultText>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity testID='header-profile-menu' onPress={togglePopoverMenuVisibility}>
                            <View style={styles.profileContainer}>
                                <View style={[styles.icon, styles.profileIcon]}>
                                    <IconHeaderUserLoginAvatar color={DecidaColors.Gray} />
                                    <DefaultText style={styles.iconText}>Profile</DefaultText>
                                </View>
                                <IconDownArrow stylefill={DecidaColors.Gray} />
                            </View>
                        </TouchableOpacity>
                    </>
                }
                headerStyle={{ backgroundColor: DecidaColors.White }}
            />
            {(isLoadingSchool || !(isSchoolWithHierarchy && route.name === ScreenNames.SchoolAdminHome)) && (
                <View style={styles.headerSpaces}>
                    {route.name !== ScreenNames.SchoolAdminHome && (
                        <View style={styles.backButtonContainer}>
                            <BackButton onPress={handleBack} style={styles.backButtonText} displayName='BACK' />
                        </View>
                    )}
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 100 }}>
                        <DefaultText style={[styles.headerText, { fontWeight: '700' }]}>
                            School
                        </DefaultText>
                        <IconDownArrow />
                        <DefaultText style={styles.headerText}>
                            {schoolData?.name}
                        </DefaultText>
                    </View>
                </View>
            )}
            {/* {schoolData && (
                <View style={styles.studentsCountContainer}>
                    <ActiveSchoolStudentsCount schoolID={schoolData?.id || ""} />
                </View>
            )} */}
        </>
    )
}

const styles = StyleSheet.create({
    icon: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
    } as ViewStyle,
    iconText: {
        marginTop: 5,
        fontSize: 15,
        color: DecidaColors.Gray,
    },
    profileIcon: {
        marginRight: 0,
    },
    profileContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    cog: {
        marginVertical: -4
    },
    moreInfomation: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    adminText: {
        color: DecidaColors.AppleSystemGray2Dark,
        fontSize: 14,
        fontWeight: '600'
    },
    logo: {
        width: 180,
        height: 35,
        marginHorizontal: 20,
    },
    headerText: {
        color: DecidaColors.White,
        fontSize: 16,
        marginHorizontal: 5
    },
    headerSpaces: {
        height: 40,
        backgroundColor: envBackgroundColor,
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center'
    },
    backButtonContainer: {
        position: 'absolute',
        left: 0,
        top: '25%',
        paddingLeft: 20
    },
    backButtonText: {
        fontSize: 16,
        fontWeight: '600',
        color: DecidaColors.White
    },
    studentsCountContainer: {
        alignItems: 'flex-start',
        paddingLeft: 50,
    }

})