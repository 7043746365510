import { Origami01 } from "./origami-01";
import { Origami02 } from "./origami-02";
import { Origami03 } from "./origami-03";
import { Origami04 } from "./origami-04";
import { Origami05 } from "./origami-05";
import { Origami06 } from "./origami-06";
import { Origami07 } from "./origami-07";
import { Origami08 } from "./origami-08";
import { Origami09 } from "./origami-09";
import { Origami10 } from "./origami-10";
import { Origami11 } from "./origami-11";
import { Origami12 } from "./origami-12";
import { Origami13 } from "./origami-13";
import { Origami14 } from "./origami-14";
import { Origami15 } from "./origami-15";
import { Origami16 } from "./origami-16";
import { Origami17 } from "./origami-17";
import { Origami18 } from "./origami-18";
import { Origami19 } from "./origami-19";
import { Origami20 } from "./origami-20";
import { Origami21 } from "./origami-21";
import { Origami22 } from "./origami-22";
import { Origami23 } from "./origami-23";
import { Origami24 } from "./origami-24";
import { Origami25 } from "./origami-25";
import { Origami26 } from "./origami-26";
import { Origami27 } from "./origami-27";
import { Origami28 } from "./origami-28";
import { Origami29 } from "./origami-29";
import { Origami30 } from "./origami-30";
import { Origami31 } from "./origami-31";
import { Origami32 } from "./origami-32";
import { Origami33 } from "./origami-33";
import { Origami34 } from "./origami-34";
import { Origami35 } from "./origami-35";
import { Origami36 } from "./origami-36";
import { Origami37 } from "./origami-37";
import { Origami38 } from "./origami-38";
import { Origami39 } from "./origami-39";
import { Origami40 } from "./origami-40";

export const origamiavatars = {
    Origami01,
    Origami02,
    Origami03,
    Origami04,
    Origami05,
    Origami06,
    Origami07,
    Origami08,
    Origami09,
    Origami10,
    Origami11,
    Origami12,
    Origami13,
    Origami14,
    Origami15,
    Origami16,
    Origami17,
    Origami18,
    Origami19,
    Origami20,
    Origami21,
    Origami22,
    Origami23,
    Origami24,
    Origami25,
    Origami26,
    Origami27,
    Origami28,
    Origami29,
    Origami30,
    Origami31,
    Origami32,
    Origami33,
    Origami34,
    Origami35,
    Origami36,
    Origami37,
    Origami38,
    Origami39,
    Origami40
}