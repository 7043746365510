import { ViewStyle } from 'react-native';
import Svg, { Circle, G, Line, Rect, Text, TextAnchor } from 'react-native-svg';
import { StaffCheckIn, StudentCheckIn, TeacherCheckIn } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import EmotionVerticalBar from '../common/emotion-vertical-bar';
import IconAvatarAbsence, { iconAvatarAbsenceWidth } from '../icon/icon-avatar-absence';
import { emotionColor } from './get-emotion-color';
import { DefaultBackgroundColor } from './const';

interface CheckInData {
    id: string;
    date: string;
    day: string;
    time: string;
    checkInData: TeacherCheckIn | StudentCheckIn | StaffCheckIn;
}

interface Props {
    checkInsData: CheckInData[];
    scaleStyle?: ViewStyle;
    isTeacher: boolean;
}

const fontStyles = {
    fontSize: 12,
    fontFamily: "Helvetica-Bold, Helvetica",
    textAnchor: 'middle' as TextAnchor,
    fill: DecidaColors.Gray,
}
const gridLineStyle = {
    stroke: DecidaColors.LightGray,
    strokeWidth: 1
}

export const viewBoxHeight = 125
const lineHeight = 10
const graphHeight = 80
const topOfGraph = 5
export const yAxisLabelWidth = 50
const yAxisLabelTextMargin = 5

const AllPeriodsGraphSvg = ({ checkInsData, scaleStyle, isTeacher }: Props) => {
    const checkInSessionWidth = 35;
    const rectangleSize = 10;
    const viewBoxHeight = 125;
    const fontStyles = {
        fontSize: 12,
        fontFamily: "Helvetica-Bold, Helvetica",
        textAnchor: 'middle' as TextAnchor,
        fill: DecidaColors.Gray,
    };
    const gridLineStyle = {
        stroke: DecidaColors.LightGray,
        strokeWidth: 1
    };

    const lineHeight = 10;
    const xAxisLabelOffset = 100;
    const graphHeight = 80;
    const topOfGraph = 5;

    const tirednessSpacing = graphHeight / 5;
    const tirednessY = [
        undefined,
        topOfGraph + tirednessSpacing * 4,
        topOfGraph + tirednessSpacing * 3,
        topOfGraph + tirednessSpacing * 2,
        topOfGraph + tirednessSpacing,
        topOfGraph,
    ];

    const emotionBarWidth = rectangleSize; // Adjust this based on the actual width of your EmotionVerticalBar
    const width = checkInsData?.length * checkInSessionWidth

    const renderPlots = (checkIn: TeacherCheckIn | StudentCheckIn | StaffCheckIn, previousTiredness: number | undefined, previousX: number, currentX: number) => {
        if (checkIn && checkIn.emotionIntensityPercentage && (isTeacher || "absence" in checkIn && checkIn.absence !== true)) {
            return (
                <>
                    <G y={topOfGraph} height={topOfGraph + graphHeight} x={-emotionBarWidth / 2}>
                        <EmotionVerticalBar
                            svgWidth={emotionBarWidth}
                            svgHeight={graphHeight}
                            heightPercentage={checkIn.emotionIntensityPercentage || 0}
                            fill={emotionColor[checkIn.emotion!]}
                            showBorder={false}
                        />
                    </G>
                    <Circle cy={tirednessY[checkIn.tiredness!]} r='3' fill='indigo' />
                    {
                        previousTiredness &&
                        <Line x1={previousX - currentX} y1={tirednessY[previousTiredness]} y2={tirednessY[checkIn.tiredness!]} strokeWidth='1' stroke='indigo' />
                    }
                </>
            );
        }
        return null;
    };

    return (
        <Svg width={width} height={viewBoxHeight} viewBox={`0 0 ${width} ${viewBoxHeight}`} style={scaleStyle}>
            {/* H Line */}
            <Line {...gridLineStyle} x2={width} y1={topOfGraph} y2={topOfGraph} />

            {/* M Line */}
            <Line {...gridLineStyle} x2={width} y1={topOfGraph + graphHeight / 2} y2={topOfGraph + graphHeight / 2} />

            {/* L Line */}
            <Line {...gridLineStyle} x2={width} y1={topOfGraph + graphHeight} y2={topOfGraph + graphHeight} />
            {
                checkInsData?.map(({ id, date, day, time, checkInData }, i) => {
                    const previousX = i * checkInSessionWidth - (checkInSessionWidth / 2);
                    const currentX = previousX + checkInSessionWidth;
                    const previousTiredness = i === 0 ? undefined : checkInsData?.[i - 1]?.checkInData?.tiredness;

                    return (
                        <G key={id} x={currentX}>

                            <Line {...gridLineStyle} y1={topOfGraph} y2={topOfGraph + graphHeight} />

                            <Text {...fontStyles} y={xAxisLabelOffset}>{day}</Text>
                            <Text {...fontStyles} y={xAxisLabelOffset + lineHeight * 1}>{date}</Text>
                            <Text {...fontStyles} y={xAxisLabelOffset + lineHeight * 2}>{time}</Text>
                            {checkInData && "absence" in checkInData && checkInData?.absence ? (
                                <G y="70" x={-iconAvatarAbsenceWidth / 2}>
                                    <IconAvatarAbsence />
                                </G>
                            ) : renderPlots(checkInData, previousTiredness || undefined, previousX, currentX)}
                        </G>
                    );
                })
            }
        </Svg>
    );
};


export const AllStatsPeriodsGraphYAxis = () => (
    <Svg width={yAxisLabelWidth} height={viewBoxHeight} viewBox={`0 0 ${yAxisLabelWidth} ${viewBoxHeight}`} >
        <Rect width={yAxisLabelWidth} height={viewBoxHeight} fill={DefaultBackgroundColor} />
        <Text {...fontStyles} textAnchor='end' x={yAxisLabelWidth - yAxisLabelTextMargin} y={topOfGraph + lineHeight / 2}>H</Text>
        <Text {...fontStyles} textAnchor='end' x={yAxisLabelWidth - yAxisLabelTextMargin} y={topOfGraph + graphHeight / 2 + lineHeight / 2}>M</Text>
        <Text {...fontStyles} textAnchor='end' x={yAxisLabelWidth - yAxisLabelTextMargin} y={topOfGraph + graphHeight + lineHeight / 2}>L</Text>
    </Svg>
)

export default AllPeriodsGraphSvg;
