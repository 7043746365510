import { StyleSheet } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles } from '../../common/const'


export const SwitchesLessonPlansEmotionsVersusFeelings: CardTemplate = {
    cardId: "5a7162a6-1bac-4c37-99ec-f5577cc9f92d",
    title: 'Emotions versus feelings',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Differentiate between emotions and feelings, understand the importance of emotional awareness, and express their emotions in a healthy and constructive manner.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 35 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Greet the students and create a positive and inclusive learning environment. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by asking the students if they have ever felt happy, sad, angry, or scared. Allow a brief discussion to encourage sharing of personal experiences. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• What’s the different between emotions and feelings (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write "Emotions" and "Feelings" on the whiteboard or chart paper.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that emotions and feelings are related but have distinct differences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Emotions: Discuss emotions as a broader category, explaining that they are natural and instinctual reactions to situations and they are universal across all people despite their culture or background.  Refer to the Little Book of Big Emotion for more information.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Write the emotions on the chart - happy, sad, scared, anxious, angry, excited, disgust and surprise.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Feelings: Explain that feelings are individual interpretations and responses to emotions. They are influenced by personal experiences, thoughts, and perceptions. Unlike emotions, feelings are completely conscious.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ For example, take arachnophobia, or the fear of spiders, as an example. Perhaps at some point in your life, you had a negative experience with a spider and ever since you feel afraid or threatened by them. At the same time, you are having emotional experiences, such as fear or disgust. You do not necessarily need to be exposed to a spider anymore, though, and just the mere thought of one can trigger an emotional response in the brain, which then automatically makes you feel discomfort because your mind has already made an association between the stimulus, emotions, and feelings.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Another example, you notice the emotion of discomfort while at a party as your stomach clenches and your breathing gets constricted. Then your mind labels that as feeling awkward because perhaps you don’t know many people there, or you just saw an ex-boyfriend. Yet another person with those same emotional bodily sensations, however, might label the experience as exciting because they get to meet new people or see their ex again. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to brainstorm a list of feelings to add to the board.  Examples to look for include;  bored, pain, tired, hungry, loved, grateful, proud, astonished, jealous, ashamed.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that emotions are the raw data, a reaction to the present reality, whereas feelings can be diluted by stories we’ve created in our head based on events of the past or fears of the future—not necessarily the truth of the situation. You can get a more truthful assessment of your feelings by first identifying your emotions.  </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Personal reflection (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students refer to the Switch4Schools emotion wheel and consider what emotion they are feeling, encourage them to reference the Little Book of Big Emotion to better understand the definition of any of the words.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ When they are ready ask them to complete a check in.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion and Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students together and review the key points discussed about emotions and feelings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage each student to share one thing they learned or found interesting during the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of understanding and expressing emotions in a healthy and respectful manner.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude the lesson with a positive note and express gratitude for their participation.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard or chart paper</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Markers</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Is feeling tired or bored an emotion? -</DefaultText>
                        <DefaultTextWithLink text='https://www.switch4schools.com.au/post/is-feeling-tired-or-bored-an-emotion#:~:text=Tiredness%20and%20boredom%20are%20not,nothing%20to%20abate%20your%20boredom%3F' />
                        <DefaultText style={CommonStyles.lessonPlanText}>The state of boredom: Frustrating or depressing? -</DefaultText>
                        <DefaultTextWithLink text='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6208645/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>The Important Difference Between Emotions and Feelings -</DefaultText>
                        <DefaultTextWithLink text='https://www.psychologytoday.com/au/blog/the-pleasure-is-all-yours/202202/the-important-difference-between-emotions-and-feelings' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Feelings, emotions, and experiences.</DefaultText>
                        <DefaultTextWithLink text='https://www.switch4schools.com.au/post/feelings-emotions-and-experiences' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    durationText: {
        marginBottom: 10,
        color: DecidaColors.Gray,
        alignSelf: 'center'
    },
})
