import { Linking } from "react-native"
import { isWebView } from "./is-web-view"
import { webViewMessageOpenUrl } from "./web-view-messages"

export const openUrlWebView = (url: string) => {
    if (isWebView()) {
        window.ReactNativeWebView?.postMessage(webViewMessageOpenUrl + url)
    } else {
        Linking.openURL(url)
    }
}
