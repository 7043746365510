import { StyleSheet, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import VideoCard from '../../../common/video-card'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { TheRiddlerHeaderIcon } from './src/the-riddler-header-icon'
import { TheRiddlerIcon } from './src/the-riddler-icon'
import { TheRiddlerLineDrawing } from './src/the-riddler-line-drawing'
import { DefaultButton } from '../../../common/default-button'
import { useState } from 'react'
import { Worksheets } from '../../../common/worksheets-imports'

const riddlesArray = [
    {
        riddle: "What goes up and down stairs without moving?",
        answer: "Carpet"
    },
    {
        riddle: "Give it food and it will live; give it water and it will die.",
        answer: "Fire"
    },
    {
        riddle: "What can you catch but not throw?",
        answer: "A cold"
    },
    {
        riddle: "Take one out and scratch my head, I am now black but once was red. What am I?",
        answer: "A match"
    },
    {
        riddle: "You remove the outside, cook the inside, eat the outside, throw away the inside. What am I?",
        answer: "Corn"
    },
    {
        riddle: "What goes around the world and stays in a corner?",
        answer: "A stamp"
    },
    {
        riddle: "What gets wetter the more it dries?",
        answer: "Towel"
    },
    {
        riddle: "The more there is, the less you see.",
        answer: "Darkness"
    },
    {
        riddle: "They come at night without being called and are lost in the day without being stolen.",
        answer: "Stars"
    },
    {
        riddle: "I have holes on the top and bottom. I have holes on my left and on my right. And I have holes in the middle, yet I still hold water. What am I?",
        answer: "A sponge"
    },
    {
        riddle: "The more you take the more you leave behind.",
        answer: "Footsteps"
    },
    {
        riddle: "Light as a feather, there is nothing in it; the strongest man can’t hold it for much more than a minute.",
        answer: "Breath"
    },
    {
        riddle: "What can run but never walks, has a mouth but never talks, has a head but never weeps, has a bed but never sleeps?",
        answer: "River"
    },
    {
        riddle: "I went into the woods and got it, I sat down to seek it, I brought it home with me because I couldn’t find it.",
        answer: "Splinter"
    },
    {
        riddle: "What can fill a room but takes up no space?",
        answer: "Light"
    },
    {
        riddle: "It is weightless, you can see it, and if you put it in a barrel it will make the barrel lighter?",
        answer: "A hole"
    },
    {
        riddle: "No sooner spoken than broken. What is it?",
        answer: "Silence"
    },
    {
        riddle: "Only two backbones and thousands of ribs.",
        answer: "Railroad"
    },
    {
        riddle: "A barrel of water weighs 20 kilos. What must you add to it to make it weigh 12 kilos?",
        answer: "Holes"
    },
    {
        riddle: "If a rooster laid a brown egg and a white egg, what kind of chicks would hatch?",
        answer: "None Roosters don’t lay eggs"
    },
    {
        riddle: "You can’t keep this until you have given it.",
        answer: "A promise"
    },
    {
        riddle: "What kind of coat can be put on only when wet?",
        answer: "A coat of paint"
    },
    {
        riddle: "You answer me, although I never ask you questions. What am I?",
        answer: "A telephone"
    },
    {
        riddle: "What comes once in a minute, twice in a moment, but never in a thousand years?",
        answer: "The letter 'M'"
    },
    {
        riddle: "What belongs to you, but other people use more?",
        answer: "Your name"
    },
    {
        riddle: "What begins with T, finishes with T, and has T in it?",
        answer: "A teapot"
    },
    {
        riddle: "I am full of keys but I can’t open any doors. What am I?",
        answer: "A piano"
    },
    {
        riddle: "I contain cities, but not any houses. I contain mountains, but not any trees. I contain water, but not any fish. What am I?",
        answer: "A map"
    },
    {
        riddle: "What starts with an e, but only has one letter?",
        answer: "An envelope"
    },
    {
        riddle: "What is a word with five letters that becomes shorter when you add two letters to it?",
        answer: "Short"
    },
    {
        riddle: "You can escape but cannot hide. You may enter, but you may not go outside. I have space, but no rooms. What am I?",
        answer: "A computer keyboard"
    },
    {
        riddle: "I am at the beginning of everything. Yet, I am also at the end of everywhere. I start eternity but end time and space. What am I?",
        answer: "The letter 'E'"
    },
    {
        riddle: "What can travel around the world without moving from its corner?",
        answer: "A stamp"
    },
    {
        riddle: "If 11 plus two equals one, what does nine plus five equal?",
        answer: "2 O'clock"
    },
    {
        riddle: "You go at red and stop at green. What am I?",
        answer: "A watermelon"
    },
    {
        riddle: "Two fathers and two sons are in a car, yet there are only three people in the car. How?",
        answer: "They are a grandfather, father, and son."
    },
    {
        riddle: "I move between the light and the dark. I have nights but no days. I am in many pieces, but I am not broken. What am I?",
        answer: "A chess game"
    }
];


export const SwitchesEmotionsExcitedTheRiddler: CardTemplate = {
    cardId: "6f426caf-c81e-4416-99c1-22bd5038daf5",
    title: 'The riddler',
    Header: TheRiddlerHeaderIcon,
    Icon: TheRiddlerIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <View style={{ flex: 1 }}>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata='Challenging the mind to carefully consider and think outside the box.' />

            </View>
        </>
    ),
    Images: TheRiddlerLineDrawing,
    CardContent: () => (
        <>

            <DefaulttextWithRightArrow textdata="Select the ‘new riddle’ button to generate a riddle." />
            <DefaulttextWithRightArrow textdata="When you think you know the answer select the ‘see answer’ button." />
            <DefaulttextWithRightArrow textdata="Feel free to compete with friends to see who can guess the correct answer" />

        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/The+riddler.m4a",
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: `This cognitive activity requires focused thinking and problem solving, directing cognitive resources away from excessive excitement and toward a structured mental challenge. As individuals immerse themselves in riddle solving, the brain's prefrontal cortex becomes activated, promoting cognitive control and reducing the intensity of excitement. The mental engagement with riddles redirects attention and offers a calming effect by encouraging mindfulness and concentration. In addition, successfully solving riddles often provides a sense of accomplishment and reward, further balancing heightened excitement with a sense of achievement and mental composure.`,
    BottomRow: () => {
        const [currentRiddle, setCurrentRiddle] = useState({
            riddle: "",
            answer: ""
        })
        const [showAnswer, setShowAnswer] = useState(false)

        const handleRandomizeRiddle = () => {
            setShowAnswer(false)
            const randomIndex = Math.floor(Math.random() * riddlesArray.length);
            setCurrentRiddle(riddlesArray[randomIndex])
        }

        const toggleSetShowAnswer = () => {
            setShowAnswer(prev => !prev)
        }

        return (
            <>
                <View style={styles.buttonsAndContentContainer}>
                    <View style={styles.buttonsRow}>
                        <DefaultButton style={{ backgroundColor: CardColorScheme.EXCITED }} onPress={handleRandomizeRiddle}>New riddle</DefaultButton>
                        <DefaultButton style={{ backgroundColor: CardColorScheme.EXCITED }} disabled={!currentRiddle.riddle} onPress={toggleSetShowAnswer}>Show answer</DefaultButton>
                    </View>

                    <DefaultText style={styles.textRiddle}>{currentRiddle.riddle}</DefaultText>
                    <DefaultText style={{ display: showAnswer ? 'flex' : 'none', textAlign: 'center', fontStyle: 'italic' }}>Answer: {currentRiddle.answer}</DefaultText>
                </View>
            </>
        )
    },
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the cognitive benefits of solving riddles and puzzles.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Engage in hands on activities solving riddles and puzzles.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Reflect on the importance of incorporating such activities into their lives for brain stimulation.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (7 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that today's lesson will focus on the cognitive benefits of solving riddles and puzzles.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the potential benefits of engaging in activities that stimulate the brain, such as improved problem solving skills, enhanced memory, increased creativity, and stimulating the brain during heightened emotional states.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity: Exploring Different Types of Puzzles (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce a variety of riddles and puzzles, explaining their types and how they can challenge different aspects of the brain.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Open the ‘puzzles and riddles’ slides to display examples and briefly explain the rules of each type.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Work through the riddles on the slides and explain the rules of each of the puzzles.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Solving Riddles and Puzzles (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the ‘Riddles and puzzles’ worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In pairs or small groups, have students work together to solve the provided puzzles.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to discuss their thought processes and strategies for finding solutions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 4: Reflection and Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a class discussion on the experience of solving riddles and puzzles.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to reflect on how these activities challenged their thinking and problem solving abilities.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to share any insights or strategies they discovered.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (3 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points discussed during the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of incorporating brain stimulating activities like riddles and puzzles into their daily lives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students navigate to The Riddler Switch for further inspiration.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to explore and enjoy these activities outside of class for mental exercise and fun.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Printed <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={Worksheets.theRiddlerWorksheets.label} link={Worksheets.theRiddlerWorksheets.uri} /> </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Puzzles and riddles slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Puzzles+and+Riddles.pptx' /> Puzzles and riddles slides</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Timer</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Encourage students to explore and enjoy these activities outside of class for mental exercise and fun.</DefaultText>
                        <DefaultTextWithLink text='https://medium.com/@alan-wareham/the-benefits-of-solving-puzzles-for-mental-health-and-happiness-fc6d5fbd78ba#:~:text=Puzzle%2Dsolving%20can%20have%20some,to%20improve%20your%20well%2Dbeing.' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The life changing magic of puzzling:</DefaultText>
                        <DefaultTextWithLink text='https://fortune.com/well/2022/10/02/mental-health-benefits-puzzles/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The benefit of puzzles for the brain</DefaultText>
                        <DefaultTextWithLink text='https://www.progresslifeline.org.uk/news/the-benefit-of-puzzles-for-the-brain' />
                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual, CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Excited]
}



const styles = StyleSheet.create({
  textRiddle: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  buttonsRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
  },
  buttonsAndContentContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 80,
    gap: 10,
  },
})