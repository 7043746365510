import { gql, useQuery, useReactiveVar, useSubscription } from '@apollo/client'
import { AntDesign, Ionicons } from '@expo/vector-icons'
import { Pressable, StyleSheet, View } from 'react-native'
import { Class, ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables, OnMutateCheckInSessionByClassIdSubscription, OnMutateCheckInSessionByClassIdSubscriptionVariables } from '../../common/API'
import { UserGroup } from '../../common/constants'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from '../common/default-text'
import { navigationRef } from '../common/root-navigation'
import { ScreenNames } from '../common/screen-names'
import { CircleWithNumberIcon } from '../common/svg-files/circle-with-number-icon'
import { ClassChatBarIcon } from '../common/svg-files/class-chart-bar-icon'
import { rvUserGroup } from '../login/login-state'
import SchoolAdminClassIcon from './school-admin-class-icon'
import { rvSidebarClassID } from './school-admin-state'
import { ClazzType } from './school-admin-types'
import { listCheckInSessionsByClassId } from '../../common/graphql/queries'
import _ from 'lodash'
import moment from 'moment'
import { onMutateCheckInSessionByClassId } from '../../common/graphql/subscriptions'
import { updateListQueryCache } from '../common/update-list-query-cache'
import { handleRestartApp } from '../common/handle-restart-app'



const classBackgroundColor = {
    [ClazzType.ClassMode]: DecidaColors.ClassMode,
    [ClazzType.GoMode]: DecidaColors.GoMode,
}

const SchoolAdminClassListItem = ({ claz }: { claz: Class }) => {

    const userGroup = useReactiveVar(rvUserGroup)
    const curretClassDetailsID = useReactiveVar(rvSidebarClassID)

    const isSelected = claz.id === curretClassDetailsID
    const clazzType = claz.goMode ? ClazzType.GoMode : ClazzType.ClassMode


    const { data: checkInSessionsResponse, } =
        useQuery<
            ListCheckInSessionsByClassIdQuery,
            ListCheckInSessionsByClassIdQueryVariables
        >(
            gql`
          ${listCheckInSessionsByClassId}
        `,
            {
                variables: { classID: claz?.id, limit: 5000 },
                skip: !claz?.id,
            }
        );

    const classCheckInSessions = _.orderBy(checkInSessionsResponse?.listCheckInSessionsByClassId?.items.filter((item) => item?._deleted !== true), "updatedAt", "desc")
    const lastCheckInSession = _.first(classCheckInSessions)

    const { error: checkInSessionSubscriptionError } = useSubscription<OnMutateCheckInSessionByClassIdSubscription, OnMutateCheckInSessionByClassIdSubscriptionVariables>(
        gql`${onMutateCheckInSessionByClassId}`,
        {
            variables: {
                classID: claz?.id || ""
            },
            skip: !claz.id,
            onData: updateListQueryCache({ listQuery: listCheckInSessionsByClassId, listQueryName: "listCheckInSessionsByClassId", subscriptionName: "onMutateCheckInSessionByClassId", variables: { classID: claz?.id, limit: 5000 } })
        })


    const handlePressClass = (classID: string) => () => {

        if (userGroup === UserGroup.Admin) {
            navigationRef.current?.navigate(ScreenNames.AdminClass, { id: claz.id, schoolID: claz.schoolID })
            return
        }

        if (curretClassDetailsID === classID) {
            rvSidebarClassID("")
            return
        }

        rvSidebarClassID(classID)
    }

    if (checkInSessionSubscriptionError !== undefined) {
        handleRestartApp()
    }

    return (
        <Pressable key={claz.id} onPress={handlePressClass(claz.id)} style={[styles.container, { backgroundColor: isSelected ? DecidaColors.YellowLight : DecidaColors.White, }]}>
            <View style={styles.classNameSection}>
                <SchoolAdminClassIcon clazzType={clazzType} size={16} />
                <DefaultText style={{ fontSize: 18, color: claz.goMode ? DecidaColors.Happy : DecidaColors.AppleSystemGray2Dark }}>{claz.name}</DefaultText>
            </View>
            <View style={styles.statsIcons}>
                <DefaultText style={[styles.classType, { backgroundColor: classBackgroundColor[clazzType] }]}>
                    {clazzType}
                </DefaultText>
                <View style={styles.iconsContainer}>
                    {userGroup === UserGroup.SchoolAdmin ? (
                        <>

                            <View style={styles.icon}>
                                <CircleWithNumberIcon number={Number(claz.emotionIntensityLevel)} />
                            </View>
                            <View style={styles.icon}>
                                {claz.toggleRequestForChat && (
                                    <Ionicons name="chatbubble-sharp" size={24} color={DecidaColors.AppleSystemGray2Dark} />
                                )}
                            </View>
                            <View style={styles.icon}>
                                {claz.wellbeing && (
                                    <AntDesign name="heart" size={24} style={{ width: '100%', maxWidth: 24 }} color={DecidaColors.AppleSystemGray2Dark} />
                                )}
                            </View>
                            <View style={styles.icon}>
                                {claz.toggleIndividualStudentStats && (
                                    <ClassChatBarIcon />
                                )}
                            </View>
                            {lastCheckInSession?.updatedAt && (
                                <View style={{ alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                                    <DefaultText textProps={{ numberOfLines: 1, ellipsizeMode: 'clip' }}>{moment(lastCheckInSession.updatedAt).format('DD/MM h:mm')}</DefaultText>
                                </View>
                            )}
                        </>
                    ) : (
                        <View style={{ flex: 1, flexWrap: 'wrap' }}>
                            <DefaultText style={styles.text}>Total checkins: <DefaultText style={[styles.text, { color: DecidaColors.Green }]}>{claz.checkInCountYTD || 0}</DefaultText></DefaultText>
                            <View style={{  flexDirection: 'row', alignItems: 'center', gap: 5, flexWrap: 'wrap' }}>
                                <DefaultText style={styles.text}>M:<DefaultText style={[styles.text, { color: DecidaColors.Green }]}>{claz.checkInCountRollingWeekMonday || 0}</DefaultText></DefaultText>
                                <DefaultText style={styles.text}>T:<DefaultText style={[styles.text, { color: DecidaColors.Green }]}>{claz.checkInCountRollingWeekTuesday || 0}</DefaultText></DefaultText>
                                <DefaultText style={styles.text}>W:<DefaultText style={[styles.text, { color: DecidaColors.Green }]}>{claz.checkInCountRollingWeekWednesday || 0}</DefaultText></DefaultText>
                                <DefaultText style={styles.text}>T:<DefaultText style={[styles.text, { color: DecidaColors.Green }]}>{claz.checkInCountRollingWeekThursday || 0}</DefaultText></DefaultText>
                                <DefaultText style={styles.text}>F:<DefaultText style={[styles.text, { color: DecidaColors.Green }]}>{claz.checkInCountRollingWeekFriday || 0}</DefaultText></DefaultText>
                                <DefaultText style={styles.text}>S:<DefaultText style={[styles.text, { color: DecidaColors.Green }]}>{claz.checkInCountRollingWeekSaturday || 0}</DefaultText></DefaultText>
                                <DefaultText style={styles.text}>S:<DefaultText style={[styles.text, { color: DecidaColors.Green }]}>{claz.checkInCountRollingWeekSunday || 0}</DefaultText></DefaultText>
                            </View>
                        </View>
                    )}
                </View>
            </View>
        </Pressable>
    )
}
export default SchoolAdminClassListItem
const styles = StyleSheet.create({
    text: {
        fontSize: 14,
        color: DecidaColors.AppleSystemGray2Dark,
    },
    icon: {
        width: 30,
        minHeight: 30,
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconsContainer: {
        flex: 0.6,
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    classType: {
        fontSize: 14,
        fontWeight: '700',
        height: 25,
        width: 100,
        textAlign: 'left',
        padding: 5,
        color: DecidaColors.GrayText,
        fontStyle: 'italic',
    },
    statsIcons: {
        flex: 0.6,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    classNameSection: {
        flex: 0.4,
        flexDirection: 'row',
        gap: 2,
        alignItems: 'center',
    },
    container: {
        flexDirection: 'row',
        paddingVertical: 5,
        paddingLeft: 10,
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: DecidaColors.GrayBorder,
    },


})  