import { View, Platform, Image } from 'react-native'
import { UniversalImage } from '../common/universal-image'

interface Props {
    width?: number
    marginTop?: number
    height?: number
    marginBottom?: number
}

const SvgImage = require('../icon/icon-switch.svg')

export const IconSwitch = ({ height, width, marginBottom, marginTop, }: Props) => (
    <View style={{ marginBottom, marginTop, width, height }}>
        <UniversalImage SvgImage={SvgImage} />
    </View >
)