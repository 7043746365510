import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { Class, GetClassQuery, GetClassQueryVariables, GetSchoolQuery, GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables, Teacher } from '../../common/API'
import { CheckInUserGroup } from '../../common/constants'
import { DecidaColors } from '../../common/decida-colors'
import { getClass, getSchool, getTeacherByCognitoUsername } from '../../common/graphql/queries'
import { rvCurrentClass } from '../common/common-state'
import { DefaultText } from '../common/default-text'
import { fetchParamsFromUrl } from '../common/fetchParamsFromUrl'
import HeaderIconCheckIn from '../common/header-icon-check-in'
import { ScreenNames } from '../common/screen-names'
import { useResponsive } from '../common/use-responsive'
import { envBackgroundColor } from '../env'
import { getLockDerviceRv, rvCurrentUser } from '../login/login-state'
import { rvSwitchesScreenState } from '../switches/common/current-screen-state'
import { SwitchesScreens } from '../switches/navigation/switches-bottom-navigation'
import TeacherClassNavigationOption from './teacher-class-navigation-option'
import { TeacherDrawerNavigatorPamarList } from './teacher-route-param-types'

type TeacherHeaderBottomRowRouteProp = RouteProp<TeacherDrawerNavigatorPamarList, "TeacherClassMain" | "TeacherWellbeing">
type TeacherHeaderBottomRowNavigationProp = NavigationProp<TeacherDrawerNavigatorPamarList, "TeacherWellbeing">

const TeacherHeaderBottomRow = () => {
    const user = useReactiveVar(rvCurrentUser)
    const denyAccess = useReactiveVar(getLockDerviceRv())

    const { data: teacherResponse } = useQuery<GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables>(gql`${getTeacherByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
    const currentTeacher = teacherResponse?.getTeacherByCognitoUsername?.items[0] as Teacher
    const { isNarrowScreen } = useResponsive()
    const { navigate } = useNavigation<TeacherHeaderBottomRowNavigationProp>()

    const { params, name: routeName } = useRoute<TeacherHeaderBottomRowRouteProp>()
    const currentClass = useReactiveVar(rvCurrentClass)

    const [classID, setclassID] = useState(params?.params?.classID || currentClass?.id)

    useEffect(() => {
        if ((routeName === "TeacherClassMain") && !classID) {
            fetchParamsFromUrl<{ classID: string }>((params) => {
                setclassID(params.classID)
            })
        } else if (routeName === ScreenNames.TeacherWellbeing) {
            setclassID("")
        }
    }, [classID, routeName])

    const { data: schoolResponse } = useQuery<GetSchoolQuery>(gql`${getSchool}`, { variables: { id: currentTeacher?.schoolID } })
    const { data: classResponse } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: classID || "" }, skip: !classID, })


    const classData = classResponse?.getClass


    const navigateToTeacherCheckIn = () => {
        navigate(ScreenNames.TeacherCheckInMain, { screen: ScreenNames.CheckInTirednessScale, params: { user: CheckInUserGroup.Teacher } })
        rvSwitchesScreenState(SwitchesScreens.HOME)
    }

    return (
        <View style={styles.headerSpaces}>
            {!isNarrowScreen && (
                <View style={styles.leftContainer}>
                    <DefaultText style={styles.name}>
                        {`${currentTeacher?.firstName || ""} ${currentTeacher?.lastName || ""}`}
                    </DefaultText>
                    <DefaultText style={styles.date}>
                        {moment().format('dddd, MMM DD')}
                    </DefaultText>
                </View>
            )}
            <View style={styles.middleContainer}>
                <DefaultText style={[styles.schoolText, { fontSize: isNarrowScreen ? 18 : 24 }]} textProps={Platform.OS === 'ios' ? { numberOfLines: 2, adjustsFontSizeToFit: true } : { numberOfLines: 2 }}>
                    {classID ? classData?.name : schoolResponse?.getSchool?.name}
                </DefaultText>
            </View>
            <View style={styles.rightContainer}>
                {(classID || denyAccess) && <TeacherClassNavigationOption clazz={classData as Class} isClassHeader />}
                {routeName === ScreenNames.TeacherWellbeing && (
                    <View style={{ alignSelf: 'flex-end' }}>
                        <HeaderIconCheckIn onPress={navigateToTeacherCheckIn} isClassHeader={true} />
                    </View>
                )}
            </View>
        </View>
    )
}
export default TeacherHeaderBottomRow
const styles = StyleSheet.create({
    leftContainer: {
        flex: 1,
    },
    headerSpaces: {
        width: '100%',
        minHeight: 40,
        maxHeight: 100,
        backgroundColor: envBackgroundColor,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
    },
    middleContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    schoolText: {
        color: DecidaColors.White
    },
    name: {
        fontWeight: 'bold',
        color: DecidaColors.White,
        marginLeft: 60
    },
    date: {
        color: DecidaColors.White,
        fontSize: 16,
        marginTop: 5,
        fontStyle: 'italic',
        marginLeft: 60
    },
    rightContainer: {
        flex: 1,
        width: 20,
        alignItems: 'center'
    }
})