import { gql, useQuery } from '@apollo/client'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { EatingStatus, GetClassQuery, GetClassQueryVariables } from '../../common/API'
import { getClass } from '../../common/graphql/queries'
import { IconTirednessAsleep } from '../icon/icon-tiredness-asleep'
import { IconTirednessDrowsy } from '../icon/icon-tiredness-drowsy'
import { Emotion } from '../models'
import EmotionVerticalBar from './emotion-vertical-bar'
import { emotionColor } from './get-emotion-color'
import IconEating from '../icon/icon-eating'

interface Props {
    classID?: string
    emotion?: Emotion | keyof typeof Emotion
    intensityPercentage?: number
    tiredness?: number
    containerStyle?: ViewStyle,
    eating?: EatingStatus
}

export const Stat = ({ classID, emotion, intensityPercentage, tiredness, containerStyle, eating }: Props) => {

    const { data: classResponse } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: classID || "" }, skip: !classID })
    const currentClass = classResponse?.getClass

    if (currentClass === undefined || emotion === undefined || intensityPercentage === undefined) {
        return <View style={styles.container} />
    }

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.tirednessIconContainer}>
                {
                    tiredness === 2 ?
                        <View style={[styles.tirednessIcon, { marginTop: 3 }]}>
                            <IconTirednessDrowsy />
                        </View>
                        : tiredness === 1 ?
                            <View style={styles.tirednessIcon}>
                                <IconTirednessAsleep />
                            </View>
                            :
                            null
                }
            </View>
            <View style={styles.eatingIconContainer}>
                {eating && eating === EatingStatus.NO && (
                    <View style={styles.eatingIcon}>
                        <IconEating />
                    </View>
                )}

            </View>
            <View style={{ height: '100%', width: '100%', paddingBottom: 10, paddingHorizontal: 5 }}>
                <EmotionVerticalBar heightPercentage={intensityPercentage} fill={emotionColor[emotion]} />
            </View>

        </View>
    )
}

export const statsWidth = 20

const styles = StyleSheet.create({
    tirednessIconContainer: {
        position: 'absolute',
        top: -20,
    },
    container: {
        width: statsWidth,
        height: 80,
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
    } as ViewStyle,
    tirednessIcon: {
        width: 20,
        height: 20,
        right: -2,
    },
    eatingIconContainer: {
        position: 'absolute',
        top: -20,
    },
    eatingIcon: {
        width: 15,
        height: 15,
        right: 20,
        top: 3

    },
})