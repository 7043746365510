
export const adminSchoolAdminFields = `
    id
    email
    schoolID
    firstName
    lastName
    cognitoUsername
    preferredUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
`

export const adminGetSchoolAdminQuery = `
  query GetSchoolAdmin($id: ID!) {
    getSchoolAdmin(id: $id) {
      ${adminSchoolAdminFields}
    }
  }
`