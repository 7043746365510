import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { MantraHeader } from './src/mantra-header'
import { MantraIcon } from './src/mantra-icon'
import { MantraImage1 } from './src/mantra-image1'

export const SwitchesEmotionsScaredMantra: CardTemplate = {
    cardId: "8399f833-1cf8-4d6c-b012-409791dfeef3",
    title: 'Mantra',
    Header: MantraHeader,
    Icon: MantraIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Overcoming your fears and building character and personal success."} />


        </>
    ),
    Images: () => (
        <>
            <MantraImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Grab a pen and paper."} />
                <DefaulttextWithRightArrow textdata={"Create your mantra:"} />
                <DefaultText style={styles.lessonPlanText}>a. Think to yourself about who the best version of yourself is, and what you would like to be known for.</DefaultText>
                <DefaultText style={styles.lessonPlanText}>b. Write down two helpful traits about yourself that you are good at, or want to be good at, starting with the phrase “I am” e.g. “I am motivated” (to help you achieve your goals)</DefaultText>
                <DefaultText style={styles.lessonPlanText}>c. Write down one unhelpful trait that you don’t believe you do, or don’t want to do anymore, starting with “I am not” e.g. “I am not stubborn” (to encourage open-mindedness) </DefaultText>
                <DefaultText style={styles.lessonPlanText}>d. Write down two short statements starting with the phrase “I will” e.g. “I will listen to others” (If you want to gain friends)</DefaultText>
                <DefaultText style={styles.lessonPlanText}>e. Finalise that thought by adding a “I will not” statement for something that you think is holding you back e.g. “I will not be jealous of others”  </DefaultText>
                <DefaultText style={styles.lessonPlanText}>f. Consolidate all your statements into one sentence e.g. “I am motivated, I am joyful and not stubborn, I will listen to others, I will be generous and not jealous of others.” </DefaultText>
                <DefaulttextWithRightArrow textdata={"Repeat your mantra in your head until you have memorised it, and repeat it to yourself whenever you are feeling unsure to motivate you to reach your goals and grow into a better person. "} />

            </View>
        </>
    ),
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: "A mantra can help with motivation by providing a simple, positive message that can serve as a source of inspiration and encouragement. When you are feeling discouraged or unsure of yourself, a mantra can help you refocus thoughts and feel more confident and motivated. Mantras can be particularly effective when they are personalised to reflect the individual's goals, values, or aspirations. For example, you might create a mantra that reflects their desire to be kind and compassionate, or to work hard and achieve goals. By repeating this mantra to yourself, you can remind yourself of what you want to achieve and why it is important to you. ",
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the importance of motivation in achieving success </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify different sources of motivation</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice using a mantra as a motivation technique to achieve a personal goal</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 40 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share an example of a time when they were motivated to do something. What motivated them, and how did it make them feel?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write the word "motivation" on the board and brainstorm a list of synonyms with the class e.g. drive, determination, ambition.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the concept of motivation, distinguishing between internal and external sources.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the idea of a personal mantra as a tool for maintaining motivation and focus.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide examples of positive personal mantras, explaining that these statements should be affirming, positive, and tailored to individual goals and values.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "Be the change you wish to see in the world." Attributed to Mahatma Gandhi, this mantra emphasises the importance of personal responsibility in creating positive change.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "Just do it." Nike's famous tagline has become a mantra for those seeking motivation to take action and overcome challenges.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "I think, therefore I am." René Descartes' philosophical statement reflects the idea that self awareness and consciousness are fundamental to existence.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "Stay hungry, stay foolish." Steve Jobs often used this mantra to encourage continuous learning, curiosity, and a willingness to take risks.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "Om Mani Padme Hum." This is a Buddhist mantra associated with compassion. Each syllable is thought to have a unique meaning and significance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "To be or not to be, that is the question." From Shakespeare's Hamlet, this famous line is often quoted as a contemplation of existential choices and the human condition.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "I am the master of my fate, I am the captain of my soul." From the poem "Invictus" by William Ernest Henley, this mantra expresses resilience and the power to control one's destiny.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "Carpe Diem" (Seize the Day) This Latin phrase encourages individuals to make the most of the present moment and opportunities.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "Peace begins with a smile." Mother Teresa is associated with this mantra, emphasising the positive impact of simple acts of kindness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "Know thyself." Attributed to ancient Greek philosophy, this mantra underscores the importance of self awareness and introspection.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to contribute any other mantras they have heard or used themselves.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Make a Mantra Worksheet to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Walk students through the steps outlined on the worksheet, guiding them to create their own personal mantra.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the "I am," "I am not," "I will," and "I will not" components.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to think about a personal goal they want to achieve and use it as a focal point for their mantra.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allow time for students to work individually or in pairs to develop their personal mantras.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite volunteers to share their personal mantras with the class, emphasising that sharing is optional.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the idea that personal mantras are powerful tools for self motivation and focus.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to repeat their personal mantras regularly, especially when they need motivation or focus.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind them that personal mantras can be adjusted as goals and values evolve.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share their goals and mantras with a trusted family member or friend for additional support and accountability.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If available, guide students to explore the Mantra Switch in the Switch4Schools app for additional resources and inspiration.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Paper and pens</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Mantra worksheet' link={Worksheets.theMantra.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What are Affirmations and Mantras for Mental Health? | HealthyPlace 51 Positive Affirmations & Mantras for Mental Health Help - Owls&Indigo (owlsandindigo.com) </DefaultText>
                        <DefaultTextWithLink text='https://owlsandindigo.com/51-positive-affirmations-mantras-for-mental-health-help/' />


                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='If you are struggling with choosing the statements right for you think of your goals and personal values and write your statements based on those.  ' />
            <DefaulTextWithTipsIcon textdata={<DefaultText>If it helps, use <DefaultTextWithCustomLink text='the mantra worksheet' link={Worksheets.theMantra.uri} style={styles.noIndent} /> to help stimulate thoughts and ideas</DefaultText>} />
            <DefaulTextWithTipsIcon textdata='You may like to compare your mantra with your friends and say it aloud to one another.' />
            <DefaulTextWithTipsIcon textdata='Sharing your mantra with your friends or family can also increase accountability.  ' />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Mantra+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Scared]
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    noIndent: {
        paddingLeft: 0,
    }
})
