import { useReactiveVar } from '@apollo/client'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { API } from 'aws-amplify'
import moment, { Moment } from 'moment'
import { useEffect, useRef, useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { rvUserGroup } from '../login/login-state'
import { commonCardStyles } from './common/emotion-card-image-sizes'
import { SwitchesParamList } from './navigation/switches-param-list'
import { envWebUrl } from '../env'
import ShareButton from '../common/share-button'
import { UserGroup } from '../../common/constants'

export const SwitchesMemes = () => {
    const navigation = useNavigation<NavigationProp<SwitchesParamList, "SwitchesMemes">>()
    const route = useRoute<RouteProp<SwitchesParamList, "SwitchesMemes">>()
    const userGroup = useReactiveVar(rvUserGroup)
    const [meme, setMeme] = useState(route?.params?.meme || 0)
    const [memeCount, setMemeCount] = useState<number>(0)
    const studentLogin = userGroup === UserGroup.ClassLogin || userGroup === UserGroup.Student
    const [accessCount, setAccessCount] = useState<number>(0)
    const [memeExpTime, setMemeExpTime] = useState<Moment>()
    const [timeLeft, setTimeLeft] = useState<number>(0)

    const memeCountKey = "memeCount"
    const memeExpTimeKey = "memeExpTime"

    useEffect(() => {
        if (meme) {
            navigation.setParams({ meme: meme || 0 })
        }
    }, [meme])

    useEffect(() => {
        if (studentLogin) {
            getMemeExpTimeCacheData()
        }
    }, [accessCount])

    const timerInterval = useRef<number | undefined | NodeJS.Timer>(undefined)

    useEffect(() => {
        if (accessCount > 10 && studentLogin) {
            handleExpired()
        }
    }, [accessCount])

    useEffect(() => {
        if (accessCount > 10 && studentLogin) {
            startTimer()
        }

        return () => {
            stopTimer()
        }
    }, [accessCount, memeExpTime])

    const startTimer = () => {
        if (timerInterval.current === undefined || memeExpTime) {
            timerInterval.current = setInterval(() => {
                const now = moment()
                const diff = moment(memeExpTime).diff(now, 'second')
                setTimeLeft(diff)
                if (memeExpTime && diff === 0) {
                    clearMemeTimerCache()
                }
            }, 1000)
        }
    }

    const stopTimer = () => {
        if (timerInterval.current !== undefined) {
            clearInterval(timerInterval.current)
            timerInterval.current = undefined
        }
    }

    const clearMemeTimerCache = () => {
        AsyncStorage.removeItem(memeCountKey)
        AsyncStorage.removeItem(memeExpTimeKey)
        setAccessCount(0)
        setMemeExpTime(undefined)
    }
    const setRandomMeme = async (total?: number) => {
        if (studentLogin) {

            const count = await AsyncStorage.getItem(memeCountKey) || '0'
            const parsedCount = JSON.parse(count)
            if (accessCount > 10) {
                await handleExpired()
                return
            }

            const newCount = Number(parsedCount) + 1
            await AsyncStorage.setItem(memeCountKey, JSON.stringify(newCount))
            setAccessCount(newCount)
        }

        setMeme(randomMeme(total || 0))

    }

    useEffect(() => {
        if (!route?.params?.meme) {
            getMemesData()
        }
    }, [])

    const handleExpired = async () => {

        const memeExpTimeCache = await getMemeExpTimeCacheData()
        let isLimited: string | boolean = false

        isLimited = memeExpTimeCache ? moment().isBefore(memeExpTimeCache) : false

        if (accessCount > 10 && !memeExpTime) {
            const expTime = moment().add(1, 'hour')

            await AsyncStorage.setItem(memeExpTimeKey, JSON.stringify(expTime))
            setMemeExpTime(expTime)
            return
        }

        if (!isLimited) {
            clearMemeTimerCache()
        }
    }

    const getMemeExpTimeCacheData = async () => {
        const memeExpTimeCache = await AsyncStorage.getItem(memeExpTimeKey) || ""
        const parsedExpTimeCache = memeExpTimeCache && JSON.parse(memeExpTimeCache)

        if (memeExpTimeCache) {
            setMemeExpTime(parsedExpTimeCache)
        }
        return parsedExpTimeCache
    }
    const randomMeme = (total?: number) => {
        return Math.floor(Math.random() * (total || memeCount)) + 1
    }

    const getMemesData = async () => {
        const response = await API.post("AdminQueries", "/getMemesCount", {})

        const { total } = response
        setMemeCount(total)
        setRandomMeme(total)
    }

    const handleNewMeme = async () => {
        if (!memeCount) {
            await getMemesData()
        }

        if (memeCount) {
            setRandomMeme()
        }
    }

    return (
        <>
            <View style={styles.iconShareContainer}>
                <ShareButton color={DecidaColors.Green} url={`${envWebUrl}/SwitchesMemes/${meme}`} />
            </View>
            <View style={styles.container}>
                {accessCount > 10 ? (
                    <>
                        <DefaultText>
                            Time to look at switches or focus on your work. Memes will be available again in: {timeLeft ? (
                                <DefaultText style={{ color: DecidaColors.Red }}>{moment.utc(timeLeft * 1000).format('mm:ss')}</DefaultText>
                            ) : null}
                        </DefaultText>

                    </>
                ) : meme ? (
                    <Image style={styles.image} resizeMode="contain" source={{ uri: `https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/memes/meme${meme}.png` }} />
                ) : null}
            </View>
            <View style={commonCardStyles.buttonstyle}>
                <DefaultButton onPress={handleNewMeme}>
                    Another Funny!
                </DefaultButton>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingLeft: 10,
        paddingRight: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: '90%',
        height: '90%',
    },
    shareText: {
        marginLeft: 10,
        color: DecidaColors.White
    },
    iconShareContainer: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 2
    },
})