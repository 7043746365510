/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createStudentCheckIn = /* GraphQL */ `mutation CreateStudentCheckIn(
  $input: CreateStudentCheckInInput!
  $condition: ModelStudentCheckInConditionInput
) {
  createStudentCheckIn(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStudentCheckInMutationVariables,
  APITypes.CreateStudentCheckInMutation
>;
export const updateStudentCheckIn = /* GraphQL */ `mutation UpdateStudentCheckIn(
  $input: UpdateStudentCheckInInput!
  $condition: ModelStudentCheckInConditionInput
) {
  updateStudentCheckIn(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStudentCheckInMutationVariables,
  APITypes.UpdateStudentCheckInMutation
>;
export const deleteStudentCheckIn = /* GraphQL */ `mutation DeleteStudentCheckIn(
  $input: DeleteStudentCheckInInput!
  $condition: ModelStudentCheckInConditionInput
) {
  deleteStudentCheckIn(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStudentCheckInMutationVariables,
  APITypes.DeleteStudentCheckInMutation
>;
export const createTeacherCheckIn = /* GraphQL */ `mutation CreateTeacherCheckIn(
  $input: CreateTeacherCheckInInput!
  $condition: ModelTeacherCheckInConditionInput
) {
  createTeacherCheckIn(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeacherCheckInMutationVariables,
  APITypes.CreateTeacherCheckInMutation
>;
export const updateTeacherCheckIn = /* GraphQL */ `mutation UpdateTeacherCheckIn(
  $input: UpdateTeacherCheckInInput!
  $condition: ModelTeacherCheckInConditionInput
) {
  updateTeacherCheckIn(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeacherCheckInMutationVariables,
  APITypes.UpdateTeacherCheckInMutation
>;
export const deleteTeacherCheckIn = /* GraphQL */ `mutation DeleteTeacherCheckIn(
  $input: DeleteTeacherCheckInInput!
  $condition: ModelTeacherCheckInConditionInput
) {
  deleteTeacherCheckIn(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTeacherCheckInMutationVariables,
  APITypes.DeleteTeacherCheckInMutation
>;
export const createStaffCheckIn = /* GraphQL */ `mutation CreateStaffCheckIn(
  $input: CreateStaffCheckInInput!
  $condition: ModelStaffCheckInConditionInput
) {
  createStaffCheckIn(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    staffID
    staff {
      id
      cognitoUsername
      email
      schoolID
      firstName
      lastName
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      nonTeachingStaffCheckInCount
      nonTeachingStaffCheckInSkipSuggestionDate
      onboarded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStaffCheckInMutationVariables,
  APITypes.CreateStaffCheckInMutation
>;
export const updateStaffCheckIn = /* GraphQL */ `mutation UpdateStaffCheckIn(
  $input: UpdateStaffCheckInInput!
  $condition: ModelStaffCheckInConditionInput
) {
  updateStaffCheckIn(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    staffID
    staff {
      id
      cognitoUsername
      email
      schoolID
      firstName
      lastName
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      nonTeachingStaffCheckInCount
      nonTeachingStaffCheckInSkipSuggestionDate
      onboarded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStaffCheckInMutationVariables,
  APITypes.UpdateStaffCheckInMutation
>;
export const deleteStaffCheckIn = /* GraphQL */ `mutation DeleteStaffCheckIn(
  $input: DeleteStaffCheckInInput!
  $condition: ModelStaffCheckInConditionInput
) {
  deleteStaffCheckIn(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    staffID
    staff {
      id
      cognitoUsername
      email
      schoolID
      firstName
      lastName
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      nonTeachingStaffCheckInCount
      nonTeachingStaffCheckInSkipSuggestionDate
      onboarded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStaffCheckInMutationVariables,
  APITypes.DeleteStaffCheckInMutation
>;
export const createCheckInSession = /* GraphQL */ `mutation CreateCheckInSession(
  $input: CreateCheckInSessionInput!
  $condition: ModelCheckInSessionConditionInput
) {
  createCheckInSession(input: $input, condition: $condition) {
    id
    checkInSessionClassSequenceNo
    createdAt
    updatedAt
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    openAt
    closeAt
    isActive
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCheckInSessionMutationVariables,
  APITypes.CreateCheckInSessionMutation
>;
export const updateCheckInSession = /* GraphQL */ `mutation UpdateCheckInSession(
  $input: UpdateCheckInSessionInput!
  $condition: ModelCheckInSessionConditionInput
) {
  updateCheckInSession(input: $input, condition: $condition) {
    id
    checkInSessionClassSequenceNo
    createdAt
    updatedAt
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    openAt
    closeAt
    isActive
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCheckInSessionMutationVariables,
  APITypes.UpdateCheckInSessionMutation
>;
export const deleteCheckInSession = /* GraphQL */ `mutation DeleteCheckInSession(
  $input: DeleteCheckInSessionInput!
  $condition: ModelCheckInSessionConditionInput
) {
  deleteCheckInSession(input: $input, condition: $condition) {
    id
    checkInSessionClassSequenceNo
    createdAt
    updatedAt
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    openAt
    closeAt
    isActive
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCheckInSessionMutationVariables,
  APITypes.DeleteCheckInSessionMutation
>;
export const createSchool = /* GraphQL */ `mutation CreateSchool(
  $input: CreateSchoolInput!
  $condition: ModelSchoolConditionInput
) {
  createSchool(input: $input, condition: $condition) {
    id
    name
    classes {
      nextToken
      startedAt
      __typename
    }
    campuses {
      nextToken
      startedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    teachers {
      nextToken
      startedAt
      __typename
    }
    students {
      nextToken
      startedAt
      __typename
    }
    staffs {
      nextToken
      startedAt
      __typename
    }
    schoolAdmins {
      nextToken
      startedAt
      __typename
    }
    schoolAdminUI
    utcOffset
    licenseTotal
    timezone
    status
    activeStudents
    trialEndDate
    renewalDate
    notes
    checkInCountAllTime
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    wholeSchoolSubscription
    billingAddress
    billingNotes
    invoiceUrl
    abn
    coordinatorID
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    staffCheckIns {
      nextToken
      startedAt
      __typename
    }
    teacherWellbeing
    staffWellbeing
    wellbeingFeature
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSchoolMutationVariables,
  APITypes.CreateSchoolMutation
>;
export const updateSchool = /* GraphQL */ `mutation UpdateSchool(
  $input: UpdateSchoolInput!
  $condition: ModelSchoolConditionInput
) {
  updateSchool(input: $input, condition: $condition) {
    id
    name
    classes {
      nextToken
      startedAt
      __typename
    }
    campuses {
      nextToken
      startedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    teachers {
      nextToken
      startedAt
      __typename
    }
    students {
      nextToken
      startedAt
      __typename
    }
    staffs {
      nextToken
      startedAt
      __typename
    }
    schoolAdmins {
      nextToken
      startedAt
      __typename
    }
    schoolAdminUI
    utcOffset
    licenseTotal
    timezone
    status
    activeStudents
    trialEndDate
    renewalDate
    notes
    checkInCountAllTime
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    wholeSchoolSubscription
    billingAddress
    billingNotes
    invoiceUrl
    abn
    coordinatorID
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    staffCheckIns {
      nextToken
      startedAt
      __typename
    }
    teacherWellbeing
    staffWellbeing
    wellbeingFeature
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSchoolMutationVariables,
  APITypes.UpdateSchoolMutation
>;
export const deleteSchool = /* GraphQL */ `mutation DeleteSchool(
  $input: DeleteSchoolInput!
  $condition: ModelSchoolConditionInput
) {
  deleteSchool(input: $input, condition: $condition) {
    id
    name
    classes {
      nextToken
      startedAt
      __typename
    }
    campuses {
      nextToken
      startedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    teachers {
      nextToken
      startedAt
      __typename
    }
    students {
      nextToken
      startedAt
      __typename
    }
    staffs {
      nextToken
      startedAt
      __typename
    }
    schoolAdmins {
      nextToken
      startedAt
      __typename
    }
    schoolAdminUI
    utcOffset
    licenseTotal
    timezone
    status
    activeStudents
    trialEndDate
    renewalDate
    notes
    checkInCountAllTime
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    wholeSchoolSubscription
    billingAddress
    billingNotes
    invoiceUrl
    abn
    coordinatorID
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    staffCheckIns {
      nextToken
      startedAt
      __typename
    }
    teacherWellbeing
    staffWellbeing
    wellbeingFeature
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSchoolMutationVariables,
  APITypes.DeleteSchoolMutation
>;
export const createCampus = /* GraphQL */ `mutation CreateCampus(
  $input: CreateCampusInput!
  $condition: ModelCampusConditionInput
) {
  createCampus(input: $input, condition: $condition) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCampusMutationVariables,
  APITypes.CreateCampusMutation
>;
export const updateCampus = /* GraphQL */ `mutation UpdateCampus(
  $input: UpdateCampusInput!
  $condition: ModelCampusConditionInput
) {
  updateCampus(input: $input, condition: $condition) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCampusMutationVariables,
  APITypes.UpdateCampusMutation
>;
export const deleteCampus = /* GraphQL */ `mutation DeleteCampus(
  $input: DeleteCampusInput!
  $condition: ModelCampusConditionInput
) {
  deleteCampus(input: $input, condition: $condition) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCampusMutationVariables,
  APITypes.DeleteCampusMutation
>;
export const createClassGroup = /* GraphQL */ `mutation CreateClassGroup(
  $input: CreateClassGroupInput!
  $condition: ModelClassGroupConditionInput
) {
  createClassGroup(input: $input, condition: $condition) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    campusID
    campus {
      id
      name
      schoolID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClassGroupMutationVariables,
  APITypes.CreateClassGroupMutation
>;
export const updateClassGroup = /* GraphQL */ `mutation UpdateClassGroup(
  $input: UpdateClassGroupInput!
  $condition: ModelClassGroupConditionInput
) {
  updateClassGroup(input: $input, condition: $condition) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    campusID
    campus {
      id
      name
      schoolID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClassGroupMutationVariables,
  APITypes.UpdateClassGroupMutation
>;
export const deleteClassGroup = /* GraphQL */ `mutation DeleteClassGroup(
  $input: DeleteClassGroupInput!
  $condition: ModelClassGroupConditionInput
) {
  deleteClassGroup(input: $input, condition: $condition) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    campusID
    campus {
      id
      name
      schoolID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClassGroupMutationVariables,
  APITypes.DeleteClassGroupMutation
>;
export const createClass = /* GraphQL */ `mutation CreateClass(
  $input: CreateClassInput!
  $condition: ModelClassConditionInput
) {
  createClass(input: $input, condition: $condition) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroupID
    students {
      nextToken
      startedAt
      __typename
    }
    classLogin {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    checkInSessions {
      nextToken
      startedAt
      __typename
    }
    supports {
      nextToken
      startedAt
      __typename
    }
    emotionIntensityLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    toggleRequestForChat
    toggleRequestForChatNotifications
    toggleIndividualStudentStats
    teachers {
      nextToken
      startedAt
      __typename
    }
    schedules {
      nextToken
      startedAt
      __typename
    }
    bulkSignUpId
    createdAt
    updatedAt
    primaryContact
    archived
    emailReceivers
    lastCheckInSessionClassSequenceNo
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    activeStudents
    goMode
    yearLevel
    lastCheckInTime
    wellbeing
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestionID
    wellbeingResponseCount
    toggleEating
    _version
    _deleted
    _lastChangedAt
    classClassLoginId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClassMutationVariables,
  APITypes.CreateClassMutation
>;
export const updateClass = /* GraphQL */ `mutation UpdateClass(
  $input: UpdateClassInput!
  $condition: ModelClassConditionInput
) {
  updateClass(input: $input, condition: $condition) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroupID
    students {
      nextToken
      startedAt
      __typename
    }
    classLogin {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    checkInSessions {
      nextToken
      startedAt
      __typename
    }
    supports {
      nextToken
      startedAt
      __typename
    }
    emotionIntensityLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    toggleRequestForChat
    toggleRequestForChatNotifications
    toggleIndividualStudentStats
    teachers {
      nextToken
      startedAt
      __typename
    }
    schedules {
      nextToken
      startedAt
      __typename
    }
    bulkSignUpId
    createdAt
    updatedAt
    primaryContact
    archived
    emailReceivers
    lastCheckInSessionClassSequenceNo
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    activeStudents
    goMode
    yearLevel
    lastCheckInTime
    wellbeing
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestionID
    wellbeingResponseCount
    toggleEating
    _version
    _deleted
    _lastChangedAt
    classClassLoginId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClassMutationVariables,
  APITypes.UpdateClassMutation
>;
export const deleteClass = /* GraphQL */ `mutation DeleteClass(
  $input: DeleteClassInput!
  $condition: ModelClassConditionInput
) {
  deleteClass(input: $input, condition: $condition) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroupID
    students {
      nextToken
      startedAt
      __typename
    }
    classLogin {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    checkInSessions {
      nextToken
      startedAt
      __typename
    }
    supports {
      nextToken
      startedAt
      __typename
    }
    emotionIntensityLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    toggleRequestForChat
    toggleRequestForChatNotifications
    toggleIndividualStudentStats
    teachers {
      nextToken
      startedAt
      __typename
    }
    schedules {
      nextToken
      startedAt
      __typename
    }
    bulkSignUpId
    createdAt
    updatedAt
    primaryContact
    archived
    emailReceivers
    lastCheckInSessionClassSequenceNo
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    activeStudents
    goMode
    yearLevel
    lastCheckInTime
    wellbeing
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestionID
    wellbeingResponseCount
    toggleEating
    _version
    _deleted
    _lastChangedAt
    classClassLoginId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClassMutationVariables,
  APITypes.DeleteClassMutation
>;
export const createTeacher = /* GraphQL */ `mutation CreateTeacher(
  $input: CreateTeacherInput!
  $condition: ModelTeacherConditionInput
) {
  createTeacher(input: $input, condition: $condition) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    classes {
      nextToken
      startedAt
      __typename
    }
    cognitoUsername
    showIntroduction
    createdAt
    updatedAt
    role
    onboarded
    devicePushTokens
    showNewFeatureInstructions
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeacherMutationVariables,
  APITypes.CreateTeacherMutation
>;
export const updateTeacher = /* GraphQL */ `mutation UpdateTeacher(
  $input: UpdateTeacherInput!
  $condition: ModelTeacherConditionInput
) {
  updateTeacher(input: $input, condition: $condition) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    classes {
      nextToken
      startedAt
      __typename
    }
    cognitoUsername
    showIntroduction
    createdAt
    updatedAt
    role
    onboarded
    devicePushTokens
    showNewFeatureInstructions
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeacherMutationVariables,
  APITypes.UpdateTeacherMutation
>;
export const deleteTeacher = /* GraphQL */ `mutation DeleteTeacher(
  $input: DeleteTeacherInput!
  $condition: ModelTeacherConditionInput
) {
  deleteTeacher(input: $input, condition: $condition) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    classes {
      nextToken
      startedAt
      __typename
    }
    cognitoUsername
    showIntroduction
    createdAt
    updatedAt
    role
    onboarded
    devicePushTokens
    showNewFeatureInstructions
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTeacherMutationVariables,
  APITypes.DeleteTeacherMutation
>;
export const createStaff = /* GraphQL */ `mutation CreateStaff(
  $input: CreateStaffInput!
  $condition: ModelStaffConditionInput
) {
  createStaff(input: $input, condition: $condition) {
    id
    cognitoUsername
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    nonTeachingStaffCheckInCount
    nonTeachingStaffCheckInSkipSuggestionDate
    onboarded
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStaffMutationVariables,
  APITypes.CreateStaffMutation
>;
export const updateStaff = /* GraphQL */ `mutation UpdateStaff(
  $input: UpdateStaffInput!
  $condition: ModelStaffConditionInput
) {
  updateStaff(input: $input, condition: $condition) {
    id
    cognitoUsername
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    nonTeachingStaffCheckInCount
    nonTeachingStaffCheckInSkipSuggestionDate
    onboarded
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStaffMutationVariables,
  APITypes.UpdateStaffMutation
>;
export const deleteStaff = /* GraphQL */ `mutation DeleteStaff(
  $input: DeleteStaffInput!
  $condition: ModelStaffConditionInput
) {
  deleteStaff(input: $input, condition: $condition) {
    id
    cognitoUsername
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    nonTeachingStaffCheckInCount
    nonTeachingStaffCheckInSkipSuggestionDate
    onboarded
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStaffMutationVariables,
  APITypes.DeleteStaffMutation
>;
export const createSchoolAdmin = /* GraphQL */ `mutation CreateSchoolAdmin(
  $input: CreateSchoolAdminInput!
  $condition: ModelSchoolAdminConditionInput
) {
  createSchoolAdmin(input: $input, condition: $condition) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    preferredUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSchoolAdminMutationVariables,
  APITypes.CreateSchoolAdminMutation
>;
export const updateSchoolAdmin = /* GraphQL */ `mutation UpdateSchoolAdmin(
  $input: UpdateSchoolAdminInput!
  $condition: ModelSchoolAdminConditionInput
) {
  updateSchoolAdmin(input: $input, condition: $condition) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    preferredUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSchoolAdminMutationVariables,
  APITypes.UpdateSchoolAdminMutation
>;
export const deleteSchoolAdmin = /* GraphQL */ `mutation DeleteSchoolAdmin(
  $input: DeleteSchoolAdminInput!
  $condition: ModelSchoolAdminConditionInput
) {
  deleteSchoolAdmin(input: $input, condition: $condition) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    preferredUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSchoolAdminMutationVariables,
  APITypes.DeleteSchoolAdminMutation
>;
export const createStudent = /* GraphQL */ `mutation CreateStudent(
  $input: CreateStudentInput!
  $condition: ModelStudentConditionInput
) {
  createStudent(input: $input, condition: $condition) {
    id
    schoolStudentID
    nickname
    avatar
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    yearLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    email
    status
    cognitoUsername
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    onboarded
    initialLoginSetup
    activeClasses
    studentCheckInCount
    studentCheckInSkipSuggestionDate
    devicePushTokens
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestion
    wellbeingResponseCount
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStudentMutationVariables,
  APITypes.CreateStudentMutation
>;
export const updateStudent = /* GraphQL */ `mutation UpdateStudent(
  $input: UpdateStudentInput!
  $condition: ModelStudentConditionInput
) {
  updateStudent(input: $input, condition: $condition) {
    id
    schoolStudentID
    nickname
    avatar
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    yearLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    email
    status
    cognitoUsername
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    onboarded
    initialLoginSetup
    activeClasses
    studentCheckInCount
    studentCheckInSkipSuggestionDate
    devicePushTokens
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestion
    wellbeingResponseCount
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStudentMutationVariables,
  APITypes.UpdateStudentMutation
>;
export const deleteStudent = /* GraphQL */ `mutation DeleteStudent(
  $input: DeleteStudentInput!
  $condition: ModelStudentConditionInput
) {
  deleteStudent(input: $input, condition: $condition) {
    id
    schoolStudentID
    nickname
    avatar
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    yearLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    email
    status
    cognitoUsername
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    onboarded
    initialLoginSetup
    activeClasses
    studentCheckInCount
    studentCheckInSkipSuggestionDate
    devicePushTokens
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestion
    wellbeingResponseCount
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStudentMutationVariables,
  APITypes.DeleteStudentMutation
>;
export const createSupport = /* GraphQL */ `mutation CreateSupport(
  $input: CreateSupportInput!
  $condition: ModelSupportConditionInput
) {
  createSupport(input: $input, condition: $condition) {
    id
    email
    classes {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSupportMutationVariables,
  APITypes.CreateSupportMutation
>;
export const updateSupport = /* GraphQL */ `mutation UpdateSupport(
  $input: UpdateSupportInput!
  $condition: ModelSupportConditionInput
) {
  updateSupport(input: $input, condition: $condition) {
    id
    email
    classes {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSupportMutationVariables,
  APITypes.UpdateSupportMutation
>;
export const deleteSupport = /* GraphQL */ `mutation DeleteSupport(
  $input: DeleteSupportInput!
  $condition: ModelSupportConditionInput
) {
  deleteSupport(input: $input, condition: $condition) {
    id
    email
    classes {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSupportMutationVariables,
  APITypes.DeleteSupportMutation
>;
export const createClassSupport = /* GraphQL */ `mutation CreateClassSupport(
  $input: CreateClassSupportInput!
  $condition: ModelClassSupportConditionInput
) {
  createClassSupport(input: $input, condition: $condition) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    supportID
    support {
      id
      email
      firstName
      lastName
      cognitoUsername
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClassSupportMutationVariables,
  APITypes.CreateClassSupportMutation
>;
export const updateClassSupport = /* GraphQL */ `mutation UpdateClassSupport(
  $input: UpdateClassSupportInput!
  $condition: ModelClassSupportConditionInput
) {
  updateClassSupport(input: $input, condition: $condition) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    supportID
    support {
      id
      email
      firstName
      lastName
      cognitoUsername
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClassSupportMutationVariables,
  APITypes.UpdateClassSupportMutation
>;
export const deleteClassSupport = /* GraphQL */ `mutation DeleteClassSupport(
  $input: DeleteClassSupportInput!
  $condition: ModelClassSupportConditionInput
) {
  deleteClassSupport(input: $input, condition: $condition) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    supportID
    support {
      id
      email
      firstName
      lastName
      cognitoUsername
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClassSupportMutationVariables,
  APITypes.DeleteClassSupportMutation
>;
export const createTeacherClass = /* GraphQL */ `mutation CreateTeacherClass(
  $input: CreateTeacherClassInput!
  $condition: ModelTeacherClassConditionInput
) {
  createTeacherClass(input: $input, condition: $condition) {
    id
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeacherClassMutationVariables,
  APITypes.CreateTeacherClassMutation
>;
export const updateTeacherClass = /* GraphQL */ `mutation UpdateTeacherClass(
  $input: UpdateTeacherClassInput!
  $condition: ModelTeacherClassConditionInput
) {
  updateTeacherClass(input: $input, condition: $condition) {
    id
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeacherClassMutationVariables,
  APITypes.UpdateTeacherClassMutation
>;
export const deleteTeacherClass = /* GraphQL */ `mutation DeleteTeacherClass(
  $input: DeleteTeacherClassInput!
  $condition: ModelTeacherClassConditionInput
) {
  deleteTeacherClass(input: $input, condition: $condition) {
    id
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTeacherClassMutationVariables,
  APITypes.DeleteTeacherClassMutation
>;
export const createScheduleClass = /* GraphQL */ `mutation CreateScheduleClass(
  $input: CreateScheduleClassInput!
  $condition: ModelScheduleClassConditionInput
) {
  createScheduleClass(input: $input, condition: $condition) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    schedule
    isActive
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateScheduleClassMutationVariables,
  APITypes.CreateScheduleClassMutation
>;
export const updateScheduleClass = /* GraphQL */ `mutation UpdateScheduleClass(
  $input: UpdateScheduleClassInput!
  $condition: ModelScheduleClassConditionInput
) {
  updateScheduleClass(input: $input, condition: $condition) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    schedule
    isActive
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateScheduleClassMutationVariables,
  APITypes.UpdateScheduleClassMutation
>;
export const deleteScheduleClass = /* GraphQL */ `mutation DeleteScheduleClass(
  $input: DeleteScheduleClassInput!
  $condition: ModelScheduleClassConditionInput
) {
  deleteScheduleClass(input: $input, condition: $condition) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    schedule
    isActive
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteScheduleClassMutationVariables,
  APITypes.DeleteScheduleClassMutation
>;
export const createStudentClass = /* GraphQL */ `mutation CreateStudentClass(
  $input: CreateStudentClassInput!
  $condition: ModelStudentClassConditionInput
) {
  createStudentClass(input: $input, condition: $condition) {
    id
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    status
    createdAt
    updatedAt
    requestForChat
    requestForChatStatus
    studentCheckIns
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStudentClassMutationVariables,
  APITypes.CreateStudentClassMutation
>;
export const updateStudentClass = /* GraphQL */ `mutation UpdateStudentClass(
  $input: UpdateStudentClassInput!
  $condition: ModelStudentClassConditionInput
) {
  updateStudentClass(input: $input, condition: $condition) {
    id
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    status
    createdAt
    updatedAt
    requestForChat
    requestForChatStatus
    studentCheckIns
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStudentClassMutationVariables,
  APITypes.UpdateStudentClassMutation
>;
export const deleteStudentClass = /* GraphQL */ `mutation DeleteStudentClass(
  $input: DeleteStudentClassInput!
  $condition: ModelStudentClassConditionInput
) {
  deleteStudentClass(input: $input, condition: $condition) {
    id
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    status
    createdAt
    updatedAt
    requestForChat
    requestForChatStatus
    studentCheckIns
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStudentClassMutationVariables,
  APITypes.DeleteStudentClassMutation
>;
export const createSchoolContact = /* GraphQL */ `mutation CreateSchoolContact(
  $input: CreateSchoolContactInput!
  $condition: ModelSchoolContactConditionInput
) {
  createSchoolContact(input: $input, condition: $condition) {
    id
    schoolID
    firstName
    lastName
    email
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSchoolContactMutationVariables,
  APITypes.CreateSchoolContactMutation
>;
export const updateSchoolContact = /* GraphQL */ `mutation UpdateSchoolContact(
  $input: UpdateSchoolContactInput!
  $condition: ModelSchoolContactConditionInput
) {
  updateSchoolContact(input: $input, condition: $condition) {
    id
    schoolID
    firstName
    lastName
    email
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSchoolContactMutationVariables,
  APITypes.UpdateSchoolContactMutation
>;
export const deleteSchoolContact = /* GraphQL */ `mutation DeleteSchoolContact(
  $input: DeleteSchoolContactInput!
  $condition: ModelSchoolContactConditionInput
) {
  deleteSchoolContact(input: $input, condition: $condition) {
    id
    schoolID
    firstName
    lastName
    email
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSchoolContactMutationVariables,
  APITypes.DeleteSchoolContactMutation
>;
export const createMinFEVersion = /* GraphQL */ `mutation CreateMinFEVersion(
  $input: CreateMinFEVersionInput!
  $condition: ModelMinFEVersionConditionInput
) {
  createMinFEVersion(input: $input, condition: $condition) {
    version
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMinFEVersionMutationVariables,
  APITypes.CreateMinFEVersionMutation
>;
export const updateMinFEVersion = /* GraphQL */ `mutation UpdateMinFEVersion(
  $input: UpdateMinFEVersionInput!
  $condition: ModelMinFEVersionConditionInput
) {
  updateMinFEVersion(input: $input, condition: $condition) {
    version
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMinFEVersionMutationVariables,
  APITypes.UpdateMinFEVersionMutation
>;
export const deleteMinFEVersion = /* GraphQL */ `mutation DeleteMinFEVersion(
  $input: DeleteMinFEVersionInput!
  $condition: ModelMinFEVersionConditionInput
) {
  deleteMinFEVersion(input: $input, condition: $condition) {
    version
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMinFEVersionMutationVariables,
  APITypes.DeleteMinFEVersionMutation
>;
export const createSwitchRating = /* GraphQL */ `mutation CreateSwitchRating(
  $input: CreateSwitchRatingInput!
  $condition: ModelSwitchRatingConditionInput
) {
  createSwitchRating(input: $input, condition: $condition) {
    id
    userId
    cardId
    userType
    rating
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSwitchRatingMutationVariables,
  APITypes.CreateSwitchRatingMutation
>;
export const updateSwitchRating = /* GraphQL */ `mutation UpdateSwitchRating(
  $input: UpdateSwitchRatingInput!
  $condition: ModelSwitchRatingConditionInput
) {
  updateSwitchRating(input: $input, condition: $condition) {
    id
    userId
    cardId
    userType
    rating
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSwitchRatingMutationVariables,
  APITypes.UpdateSwitchRatingMutation
>;
export const deleteSwitchRating = /* GraphQL */ `mutation DeleteSwitchRating(
  $input: DeleteSwitchRatingInput!
  $condition: ModelSwitchRatingConditionInput
) {
  deleteSwitchRating(input: $input, condition: $condition) {
    id
    userId
    cardId
    userType
    rating
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSwitchRatingMutationVariables,
  APITypes.DeleteSwitchRatingMutation
>;
export const createClassLogin = /* GraphQL */ `mutation CreateClassLogin(
  $input: CreateClassLoginInput!
  $condition: ModelClassLoginConditionInput
) {
  createClassLogin(input: $input, condition: $condition) {
    id
    nickname
    cognitoUsername
    classID
    classCode
    createdAt
    updatedAt
    devicePushTokens
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClassLoginMutationVariables,
  APITypes.CreateClassLoginMutation
>;
export const updateClassLogin = /* GraphQL */ `mutation UpdateClassLogin(
  $input: UpdateClassLoginInput!
  $condition: ModelClassLoginConditionInput
) {
  updateClassLogin(input: $input, condition: $condition) {
    id
    nickname
    cognitoUsername
    classID
    classCode
    createdAt
    updatedAt
    devicePushTokens
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClassLoginMutationVariables,
  APITypes.UpdateClassLoginMutation
>;
export const deleteClassLogin = /* GraphQL */ `mutation DeleteClassLogin(
  $input: DeleteClassLoginInput!
  $condition: ModelClassLoginConditionInput
) {
  deleteClassLogin(input: $input, condition: $condition) {
    id
    nickname
    cognitoUsername
    classID
    classCode
    createdAt
    updatedAt
    devicePushTokens
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClassLoginMutationVariables,
  APITypes.DeleteClassLoginMutation
>;
export const createFavourite = /* GraphQL */ `mutation CreateFavourite(
  $input: CreateFavouriteInput!
  $condition: ModelFavouriteConditionInput
) {
  createFavourite(input: $input, condition: $condition) {
    id
    cognitoUsername
    cardId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFavouriteMutationVariables,
  APITypes.CreateFavouriteMutation
>;
export const updateFavourite = /* GraphQL */ `mutation UpdateFavourite(
  $input: UpdateFavouriteInput!
  $condition: ModelFavouriteConditionInput
) {
  updateFavourite(input: $input, condition: $condition) {
    id
    cognitoUsername
    cardId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFavouriteMutationVariables,
  APITypes.UpdateFavouriteMutation
>;
export const deleteFavourite = /* GraphQL */ `mutation DeleteFavourite(
  $input: DeleteFavouriteInput!
  $condition: ModelFavouriteConditionInput
) {
  deleteFavourite(input: $input, condition: $condition) {
    id
    cognitoUsername
    cardId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFavouriteMutationVariables,
  APITypes.DeleteFavouriteMutation
>;
export const createFindYourCalmActivityRating = /* GraphQL */ `mutation CreateFindYourCalmActivityRating(
  $input: CreateFindYourCalmActivityRatingInput!
  $condition: ModelFindYourCalmActivityRatingConditionInput
) {
  createFindYourCalmActivityRating(input: $input, condition: $condition) {
    userId
    activityId
    rating
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFindYourCalmActivityRatingMutationVariables,
  APITypes.CreateFindYourCalmActivityRatingMutation
>;
export const updateFindYourCalmActivityRating = /* GraphQL */ `mutation UpdateFindYourCalmActivityRating(
  $input: UpdateFindYourCalmActivityRatingInput!
  $condition: ModelFindYourCalmActivityRatingConditionInput
) {
  updateFindYourCalmActivityRating(input: $input, condition: $condition) {
    userId
    activityId
    rating
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFindYourCalmActivityRatingMutationVariables,
  APITypes.UpdateFindYourCalmActivityRatingMutation
>;
export const deleteFindYourCalmActivityRating = /* GraphQL */ `mutation DeleteFindYourCalmActivityRating(
  $input: DeleteFindYourCalmActivityRatingInput!
  $condition: ModelFindYourCalmActivityRatingConditionInput
) {
  deleteFindYourCalmActivityRating(input: $input, condition: $condition) {
    userId
    activityId
    rating
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFindYourCalmActivityRatingMutationVariables,
  APITypes.DeleteFindYourCalmActivityRatingMutation
>;
export const createInvoice = /* GraphQL */ `mutation CreateInvoice(
  $input: CreateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  createInvoice(input: $input, condition: $condition) {
    id
    schoolID
    url
    name
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvoiceMutationVariables,
  APITypes.CreateInvoiceMutation
>;
export const updateInvoice = /* GraphQL */ `mutation UpdateInvoice(
  $input: UpdateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  updateInvoice(input: $input, condition: $condition) {
    id
    schoolID
    url
    name
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvoiceMutationVariables,
  APITypes.UpdateInvoiceMutation
>;
export const deleteInvoice = /* GraphQL */ `mutation DeleteInvoice(
  $input: DeleteInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  deleteInvoice(input: $input, condition: $condition) {
    id
    schoolID
    url
    name
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvoiceMutationVariables,
  APITypes.DeleteInvoiceMutation
>;
export const createVideos = /* GraphQL */ `mutation CreateVideos(
  $input: CreateVideosInput!
  $condition: ModelVideosConditionInput
) {
  createVideos(input: $input, condition: $condition) {
    id
    url
    image
    title
    createdAt
    updatedAt
    time
    level
    emotion
    ageGroup
    isExternal
    autoplay
    youtubePlaylistThumbnailUrlVideoId
    thumbnailUrl
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVideosMutationVariables,
  APITypes.CreateVideosMutation
>;
export const updateVideos = /* GraphQL */ `mutation UpdateVideos(
  $input: UpdateVideosInput!
  $condition: ModelVideosConditionInput
) {
  updateVideos(input: $input, condition: $condition) {
    id
    url
    image
    title
    createdAt
    updatedAt
    time
    level
    emotion
    ageGroup
    isExternal
    autoplay
    youtubePlaylistThumbnailUrlVideoId
    thumbnailUrl
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVideosMutationVariables,
  APITypes.UpdateVideosMutation
>;
export const deleteVideos = /* GraphQL */ `mutation DeleteVideos(
  $input: DeleteVideosInput!
  $condition: ModelVideosConditionInput
) {
  deleteVideos(input: $input, condition: $condition) {
    id
    url
    image
    title
    createdAt
    updatedAt
    time
    level
    emotion
    ageGroup
    isExternal
    autoplay
    youtubePlaylistThumbnailUrlVideoId
    thumbnailUrl
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVideosMutationVariables,
  APITypes.DeleteVideosMutation
>;
export const createWellbeingQuestion = /* GraphQL */ `mutation CreateWellbeingQuestion(
  $input: CreateWellbeingQuestionInput!
  $condition: ModelWellbeingQuestionConditionInput
) {
  createWellbeingQuestion(input: $input, condition: $condition) {
    id
    category
    type
    text
    options {
      text
      value
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWellbeingQuestionMutationVariables,
  APITypes.CreateWellbeingQuestionMutation
>;
export const updateWellbeingQuestion = /* GraphQL */ `mutation UpdateWellbeingQuestion(
  $input: UpdateWellbeingQuestionInput!
  $condition: ModelWellbeingQuestionConditionInput
) {
  updateWellbeingQuestion(input: $input, condition: $condition) {
    id
    category
    type
    text
    options {
      text
      value
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWellbeingQuestionMutationVariables,
  APITypes.UpdateWellbeingQuestionMutation
>;
export const deleteWellbeingQuestion = /* GraphQL */ `mutation DeleteWellbeingQuestion(
  $input: DeleteWellbeingQuestionInput!
  $condition: ModelWellbeingQuestionConditionInput
) {
  deleteWellbeingQuestion(input: $input, condition: $condition) {
    id
    category
    type
    text
    options {
      text
      value
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWellbeingQuestionMutationVariables,
  APITypes.DeleteWellbeingQuestionMutation
>;
export const createWellbeingResponse = /* GraphQL */ `mutation CreateWellbeingResponse(
  $input: CreateWellbeingResponseInput!
  $condition: ModelWellbeingResponseConditionInput
) {
  createWellbeingResponse(input: $input, condition: $condition) {
    id
    userID
    userType
    wellbeingQuestionID
    answer
    createdAt
    schoolID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWellbeingResponseMutationVariables,
  APITypes.CreateWellbeingResponseMutation
>;
export const updateWellbeingResponse = /* GraphQL */ `mutation UpdateWellbeingResponse(
  $input: UpdateWellbeingResponseInput!
  $condition: ModelWellbeingResponseConditionInput
) {
  updateWellbeingResponse(input: $input, condition: $condition) {
    id
    userID
    userType
    wellbeingQuestionID
    answer
    createdAt
    schoolID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWellbeingResponseMutationVariables,
  APITypes.UpdateWellbeingResponseMutation
>;
export const deleteWellbeingResponse = /* GraphQL */ `mutation DeleteWellbeingResponse(
  $input: DeleteWellbeingResponseInput!
  $condition: ModelWellbeingResponseConditionInput
) {
  deleteWellbeingResponse(input: $input, condition: $condition) {
    id
    userID
    userType
    wellbeingQuestionID
    answer
    createdAt
    schoolID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWellbeingResponseMutationVariables,
  APITypes.DeleteWellbeingResponseMutation
>;
export const addDevicePushToken = /* GraphQL */ `mutation AddDevicePushToken($input: DevicePushTokenInput!) {
  addDevicePushToken(input: $input)
}
` as GeneratedMutation<
  APITypes.AddDevicePushTokenMutationVariables,
  APITypes.AddDevicePushTokenMutation
>;
export const createGoClassStudentCheckIn = /* GraphQL */ `mutation CreateGoClassStudentCheckIn(
  $input: CreateGoClassStudentCheckInInput!
) {
  createGoClassStudentCheckIn(input: $input) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGoClassStudentCheckInMutationVariables,
  APITypes.CreateGoClassStudentCheckInMutation
>;
export const customCreateTeacherClass = /* GraphQL */ `mutation CustomCreateTeacherClass($input: CustomCreateTeacherClassInput!) {
  customCreateTeacherClass(input: $input) {
    id
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomCreateTeacherClassMutationVariables,
  APITypes.CustomCreateTeacherClassMutation
>;
export const customDeleteTeacherClass = /* GraphQL */ `mutation CustomDeleteTeacherClass($input: CustomDeleteTeacherCLassInput!) {
  customDeleteTeacherClass(input: $input) {
    id
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomDeleteTeacherClassMutationVariables,
  APITypes.CustomDeleteTeacherClassMutation
>;
export const customUpdateSchoolAdmin = /* GraphQL */ `mutation CustomUpdateSchoolAdmin(
  $input: UpdateSchoolAdminForBillingInfoInput!
) {
  customUpdateSchoolAdmin(input: $input) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    preferredUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomUpdateSchoolAdminMutationVariables,
  APITypes.CustomUpdateSchoolAdminMutation
>;
export const endCurrentCheckInSession = /* GraphQL */ `mutation EndCurrentCheckInSession($input: CloseCheckInSessionInput!) {
  endCurrentCheckInSession(input: $input) {
    id
    checkInSessionClassSequenceNo
    createdAt
    updatedAt
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    openAt
    closeAt
    isActive
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EndCurrentCheckInSessionMutationVariables,
  APITypes.EndCurrentCheckInSessionMutation
>;
export const openNewCheckInSession = /* GraphQL */ `mutation OpenNewCheckInSession($input: OpenCheckInSessionInput!) {
  openNewCheckInSession(input: $input) {
    id
    checkInSessionClassSequenceNo
    createdAt
    updatedAt
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    openAt
    closeAt
    isActive
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.OpenNewCheckInSessionMutationVariables,
  APITypes.OpenNewCheckInSessionMutation
>;
export const ping = /* GraphQL */ `mutation Ping($id: ID!) {
  ping(id: $id) {
    id
    __typename
  }
}
` as GeneratedMutation<APITypes.PingMutationVariables, APITypes.PingMutation>;
export const removeDevicePushToken = /* GraphQL */ `mutation RemoveDevicePushToken($input: DevicePushTokenInput!) {
  removeDevicePushToken(input: $input)
}
` as GeneratedMutation<
  APITypes.RemoveDevicePushTokenMutationVariables,
  APITypes.RemoveDevicePushTokenMutation
>;
export const resetAllStudentPasswordInClass = /* GraphQL */ `mutation ResetAllStudentPasswordInClass(
  $input: resetAllStudentPasswordInClass!
) {
  resetAllStudentPasswordInClass(input: $input)
}
` as GeneratedMutation<
  APITypes.ResetAllStudentPasswordInClassMutationVariables,
  APITypes.ResetAllStudentPasswordInClassMutation
>;
export const setRequestForChat = /* GraphQL */ `mutation SetRequestForChat($input: SetRequestForChatInput!) {
  setRequestForChat(input: $input) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetRequestForChatMutationVariables,
  APITypes.SetRequestForChatMutation
>;
export const updateSchoolCoordinatorForBillingInfo = /* GraphQL */ `mutation UpdateSchoolCoordinatorForBillingInfo(
  $input: UpdateSchoolCoordinatorForBillingInfo!
) {
  updateSchoolCoordinatorForBillingInfo(input: $input) {
    id
    name
    classes {
      nextToken
      startedAt
      __typename
    }
    campuses {
      nextToken
      startedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    teachers {
      nextToken
      startedAt
      __typename
    }
    students {
      nextToken
      startedAt
      __typename
    }
    staffs {
      nextToken
      startedAt
      __typename
    }
    schoolAdmins {
      nextToken
      startedAt
      __typename
    }
    schoolAdminUI
    utcOffset
    licenseTotal
    timezone
    status
    activeStudents
    trialEndDate
    renewalDate
    notes
    checkInCountAllTime
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    wholeSchoolSubscription
    billingAddress
    billingNotes
    invoiceUrl
    abn
    coordinatorID
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    staffCheckIns {
      nextToken
      startedAt
      __typename
    }
    teacherWellbeing
    staffWellbeing
    wellbeingFeature
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSchoolCoordinatorForBillingInfoMutationVariables,
  APITypes.UpdateSchoolCoordinatorForBillingInfoMutation
>;
export const updateSchoolForBillingInfo = /* GraphQL */ `mutation UpdateSchoolForBillingInfo($input: UpdateSchoolForBillingInfoInput!) {
  updateSchoolForBillingInfo(input: $input) {
    id
    name
    classes {
      nextToken
      startedAt
      __typename
    }
    campuses {
      nextToken
      startedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    teachers {
      nextToken
      startedAt
      __typename
    }
    students {
      nextToken
      startedAt
      __typename
    }
    staffs {
      nextToken
      startedAt
      __typename
    }
    schoolAdmins {
      nextToken
      startedAt
      __typename
    }
    schoolAdminUI
    utcOffset
    licenseTotal
    timezone
    status
    activeStudents
    trialEndDate
    renewalDate
    notes
    checkInCountAllTime
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    wholeSchoolSubscription
    billingAddress
    billingNotes
    invoiceUrl
    abn
    coordinatorID
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    staffCheckIns {
      nextToken
      startedAt
      __typename
    }
    teacherWellbeing
    staffWellbeing
    wellbeingFeature
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSchoolForBillingInfoMutationVariables,
  APITypes.UpdateSchoolForBillingInfoMutation
>;
export const upsertFindYourCalmActivityRating = /* GraphQL */ `mutation UpsertFindYourCalmActivityRating(
  $input: UpsertFindYourCalmActivityRatingInput!
) {
  upsertFindYourCalmActivityRating(input: $input) {
    userId
    activityId
    rating
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpsertFindYourCalmActivityRatingMutationVariables,
  APITypes.UpsertFindYourCalmActivityRatingMutation
>;
export const upsertStudentCheckIn = /* GraphQL */ `mutation UpsertStudentCheckIn($input: UpsertStudentCheckInInput!) {
  upsertStudentCheckIn(input: $input) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpsertStudentCheckInMutationVariables,
  APITypes.UpsertStudentCheckInMutation
>;
export const archivedClass = /* GraphQL */ `mutation ArchivedClass($input: ArchivedClassInput!) {
  archivedClass(input: $input) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroupID
    students {
      nextToken
      startedAt
      __typename
    }
    classLogin {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    checkInSessions {
      nextToken
      startedAt
      __typename
    }
    supports {
      nextToken
      startedAt
      __typename
    }
    emotionIntensityLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    toggleRequestForChat
    toggleRequestForChatNotifications
    toggleIndividualStudentStats
    teachers {
      nextToken
      startedAt
      __typename
    }
    schedules {
      nextToken
      startedAt
      __typename
    }
    bulkSignUpId
    createdAt
    updatedAt
    primaryContact
    archived
    emailReceivers
    lastCheckInSessionClassSequenceNo
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    activeStudents
    goMode
    yearLevel
    lastCheckInTime
    wellbeing
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestionID
    wellbeingResponseCount
    toggleEating
    _version
    _deleted
    _lastChangedAt
    classClassLoginId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ArchivedClassMutationVariables,
  APITypes.ArchivedClassMutation
>;
export const customCreateStaffCheckIn = /* GraphQL */ `mutation CustomCreateStaffCheckIn($input: CustomCreateStaffCheckInInput!) {
  customCreateStaffCheckIn(input: $input) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    staffID
    staff {
      id
      cognitoUsername
      email
      schoolID
      firstName
      lastName
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      nonTeachingStaffCheckInCount
      nonTeachingStaffCheckInSkipSuggestionDate
      onboarded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomCreateStaffCheckInMutationVariables,
  APITypes.CustomCreateStaffCheckInMutation
>;
export const setRequestForChatForGoClass = /* GraphQL */ `mutation SetRequestForChatForGoClass(
  $input: SetRequestForChatForGoClassInput!
) {
  setRequestForChatForGoClass(input: $input) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetRequestForChatForGoClassMutationVariables,
  APITypes.SetRequestForChatForGoClassMutation
>;
export const clearClassStudentCheckInAlerts = /* GraphQL */ `mutation ClearClassStudentCheckInAlerts(
  $input: ClearClassStudentCheckInAlertsInput!
) {
  clearClassStudentCheckInAlerts(input: $input) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ClearClassStudentCheckInAlertsMutationVariables,
  APITypes.ClearClassStudentCheckInAlertsMutation
>;
export const customCreateClassLogin = /* GraphQL */ `mutation CustomCreateClassLogin($input: CustomCreateClassLoginInput!) {
  customCreateClassLogin(input: $input) {
    id
    nickname
    cognitoUsername
    classID
    classCode
    createdAt
    updatedAt
    devicePushTokens
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomCreateClassLoginMutationVariables,
  APITypes.CustomCreateClassLoginMutation
>;
export const customCreateSchoolAdmin = /* GraphQL */ `mutation CustomCreateSchoolAdmin(
  $input: CreateSchoolAdminForBillingInfoInput!
) {
  customCreateSchoolAdmin(input: $input) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    preferredUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomCreateSchoolAdminMutationVariables,
  APITypes.CustomCreateSchoolAdminMutation
>;
export const customCreateStudent = /* GraphQL */ `mutation CustomCreateStudent($input: CustomCreateStudentInput!) {
  customCreateStudent(input: $input) {
    id
    schoolStudentID
    nickname
    avatar
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    yearLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    email
    status
    cognitoUsername
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    onboarded
    initialLoginSetup
    activeClasses
    studentCheckInCount
    studentCheckInSkipSuggestionDate
    devicePushTokens
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestion
    wellbeingResponseCount
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomCreateStudentMutationVariables,
  APITypes.CustomCreateStudentMutation
>;
export const customCreateStaff = /* GraphQL */ `mutation CustomCreateStaff($input: CustomCreateStaffInput!) {
  customCreateStaff(input: $input) {
    id
    cognitoUsername
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    nonTeachingStaffCheckInCount
    nonTeachingStaffCheckInSkipSuggestionDate
    onboarded
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomCreateStaffMutationVariables,
  APITypes.CustomCreateStaffMutation
>;
export const customCreateTeacherAndAssignedToClass = /* GraphQL */ `mutation CustomCreateTeacherAndAssignedToClass(
  $input: CustomCreateTeacherAndAssignedToClassInput!
) {
  customCreateTeacherAndAssignedToClass(input: $input) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    classes {
      nextToken
      startedAt
      __typename
    }
    cognitoUsername
    showIntroduction
    createdAt
    updatedAt
    role
    onboarded
    devicePushTokens
    showNewFeatureInstructions
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomCreateTeacherAndAssignedToClassMutationVariables,
  APITypes.CustomCreateTeacherAndAssignedToClassMutation
>;
export const customCreateTeacher = /* GraphQL */ `mutation CustomCreateTeacher($input: CustomCreateTeacherInput!) {
  customCreateTeacher(input: $input) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    classes {
      nextToken
      startedAt
      __typename
    }
    cognitoUsername
    showIntroduction
    createdAt
    updatedAt
    role
    onboarded
    devicePushTokens
    showNewFeatureInstructions
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomCreateTeacherMutationVariables,
  APITypes.CustomCreateTeacherMutation
>;
export const customDeleteTeacher = /* GraphQL */ `mutation CustomDeleteTeacher($input: CustomDeleteTeacherInput!) {
  customDeleteTeacher(input: $input) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    classes {
      nextToken
      startedAt
      __typename
    }
    cognitoUsername
    showIntroduction
    createdAt
    updatedAt
    role
    onboarded
    devicePushTokens
    showNewFeatureInstructions
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomDeleteTeacherMutationVariables,
  APITypes.CustomDeleteTeacherMutation
>;
export const customDeleteStaff = /* GraphQL */ `mutation CustomDeleteStaff($input: CustomDeleteStaffInput!) {
  customDeleteStaff(input: $input) {
    id
    cognitoUsername
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    nonTeachingStaffCheckInCount
    nonTeachingStaffCheckInSkipSuggestionDate
    onboarded
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomDeleteStaffMutationVariables,
  APITypes.CustomDeleteStaffMutation
>;
export const customRemoveSchoolAdmin = /* GraphQL */ `mutation CustomRemoveSchoolAdmin(
  $input: RemoveSchoolAdminForBillingInfoInput!
) {
  customRemoveSchoolAdmin(input: $input) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    preferredUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomRemoveSchoolAdminMutationVariables,
  APITypes.CustomRemoveSchoolAdminMutation
>;
export const customUpdateStaff = /* GraphQL */ `mutation CustomUpdateStaff($input: CustomUpdateStaffInput!) {
  customUpdateStaff(input: $input) {
    id
    cognitoUsername
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    nonTeachingStaffCheckInCount
    nonTeachingStaffCheckInSkipSuggestionDate
    onboarded
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomUpdateStaffMutationVariables,
  APITypes.CustomUpdateStaffMutation
>;
export const customUpdateTeacher = /* GraphQL */ `mutation CustomUpdateTeacher($input: CustomUpdateTeacherInput!) {
  customUpdateTeacher(input: $input) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    classes {
      nextToken
      startedAt
      __typename
    }
    cognitoUsername
    showIntroduction
    createdAt
    updatedAt
    role
    onboarded
    devicePushTokens
    showNewFeatureInstructions
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CustomUpdateTeacherMutationVariables,
  APITypes.CustomUpdateTeacherMutation
>;
export const updateClassLoginCode = /* GraphQL */ `mutation UpdateClassLoginCode($input: CustomUpdateClassLoginCodeInput!) {
  updateClassLoginCode(input: $input) {
    id
    nickname
    cognitoUsername
    classID
    classCode
    createdAt
    updatedAt
    devicePushTokens
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClassLoginCodeMutationVariables,
  APITypes.UpdateClassLoginCodeMutation
>;
export const submitWellbeingResponse = /* GraphQL */ `mutation SubmitWellbeingResponse($input: SubmitWellbeingResponseInput!) {
  submitWellbeingResponse(input: $input) {
    id
    userID
    userType
    wellbeingQuestionID
    answer
    createdAt
    schoolID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitWellbeingResponseMutationVariables,
  APITypes.SubmitWellbeingResponseMutation
>;
