import * as React from "react"
import Svg, { G, Path, SvgProps } from "react-native-svg"

export const iconAvatarAbsenceWidth = 16
export const IconAvatarAbsence = (props: SvgProps) => {
    return (
        <Svg
            width={iconAvatarAbsenceWidth}
            height={iconAvatarAbsenceWidth}
            viewBox="0 0 17.146 17.146"
            {...props}
        >
            <G data-name="Group 18128">
                <G
                    data-name="Group 18125"
                    transform="translate(-460 -396) translate(145 242)"
                >
                    <G data-name="Path 6054" fill="#fff">
                        <Path
                            d="M8.573 15.646A7.08 7.08 0 011.5 8.573 7.08 7.08 0 018.573 1.5a7.08 7.08 0 017.073 7.073 7.08 7.08 0 01-7.073 7.073z"
                            transform="translate(315 154)"
                        />
                        <Path
                            d="M8.573 3A5.58 5.58 0 003 8.573a5.58 5.58 0 005.573 5.573 5.58 5.58 0 005.573-5.573A5.58 5.58 0 008.573 3m0-3a8.573 8.573 0 110 17.146A8.573 8.573 0 018.573 0z"
                            fill="#707070"
                            transform="translate(315 154)"
                        />
                    </G>
                    <Path
                        data-name="Line 3071"
                        transform="translate(318.5 162.5)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth={3}
                        d="M0 0L10 0"
                    />
                </G>
            </G>
        </Svg>
    )
}

export default IconAvatarAbsence
