import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { AntDesign } from '@expo/vector-icons'
import { useState } from 'react'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables, UpdateTeacherMutation, UpdateTeacherMutationVariables } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { updateTeacher } from '../../common/graphql/mutations'
import { getTeacherByCognitoUsername } from '../../common/graphql/queries'
import { DefaultFontSize } from '../common/const'
import DefaultModal from '../common/default-modal'
import { DefaultText } from '../common/default-text'
import StepperDot from '../common/stepper-dot'
import { useResponsive } from '../common/use-responsive'
import { rvCurrentUser } from '../login/login-state'
import { TeacherDashboardInfoSteps1, TeacherDashboardInfoSteps2, TeacherDashboardInfoSteps3, TeacherDashboardInfoSteps4 } from './teacher-dashboard-info-steps'
import { TeacherDashboardNewFeatureInfoSteps1 } from './teacher-dashboard-new-feature-info-steps'


const TeacherDashboardNewFeatureInfoModal = () => {
    const [stepIndex, setStepIndex] = useState(0)
    const totalSteps = 5
    const lastStep = stepIndex + 1 === totalSteps
    const { responsiveFontSize } = useResponsive()

    const user = useReactiveVar(rvCurrentUser)
    const { data } = useQuery<GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables>(gql`${getTeacherByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
    const currentTeacher = data?.getTeacherByCognitoUsername?.items[0]

    const [updateTeacherMutation] = useMutation<UpdateTeacherMutation, UpdateTeacherMutationVariables>(gql`${updateTeacher}`)

    const handleNext = () => {
        if (lastStep) {
            toggleModal()
            return
        }

        if (stepIndex >= 0) {
            setStepIndex(prev => prev + 1)
        }
    }

    const handlePrevious = () => {
        if (stepIndex >= 0) {
            setStepIndex(prev => prev - 1)
        }
    }

    const renderContent = () => {
        switch (stepIndex) {
            case 0:
                return <TeacherDashboardNewFeatureInfoSteps1 handleNext={handleNext} />
            case 1:
                return <TeacherDashboardInfoSteps1 handleNext={handleNext} handlePrevious={handlePrevious} />
            case 2:
                return <TeacherDashboardInfoSteps2 handleNext={handleNext} handlePrevious={handlePrevious} />
            case 3:
                return <TeacherDashboardInfoSteps3 handleNext={handleNext} handlePrevious={handlePrevious} />
            case 4:
                return <TeacherDashboardInfoSteps4 handleNext={handleNext} handlePrevious={handlePrevious} />

            default:
                break;
        }
    }

    const handlePressDot = (index: number) => {
        setStepIndex(index)
    }

    const toggleModal = () => {

        if (currentTeacher) {
            updateTeacherMutation({
                variables: {
                    input: {
                        id: currentTeacher?.id,
                        _version: currentTeacher?._version,
                        showNewFeatureInstructions: false
                    }
                }
            })
        }
    }

    return (
        <View>
            <DefaultModal contentStyle={styles.modal}>
                <View style={styles.modalHeaderContainer}>
                    <View />
                    <StepperDot total={totalSteps} currentStepIndex={stepIndex} onPressDot={handlePressDot} />
                    <TouchableOpacity style={styles.closeIcon} onPress={toggleModal}>
                        <AntDesign name="close" size={28} color={DecidaColors.Gray} />
                    </TouchableOpacity>
                </View>
                <DefaultText style={[styles.headerText, { fontSize: responsiveFontSize(DefaultFontSize + 2) }]}>
                    {stepIndex === 0 ? "Things have had a little shuffle!" : "Dashboard"}
                </DefaultText>
                <ScrollView contentContainerStyle={{ paddingHorizontal: 10, height: '100%' }}>
                    {renderContent()}
                </ScrollView>
            </DefaultModal>
        </View >
    )
}
export default TeacherDashboardNewFeatureInfoModal


const styles = StyleSheet.create({
    modal: {
        height: '90%',
        maxHeight: 1000,
    },
    modalHeaderContainer: {
        alignItems: 'center',
        paddingBottom: 10,
        justifyContent: 'space-between',
        borderBottomColor: DecidaColors.Gray,
    },
    closeIcon: {
        position: 'absolute',
        right: 10,
        paddingHorizontal: 10,
    },
    headerText: {
        textAlign: 'center',
        fontSize: DefaultFontSize + 2,
        fontWeight: 'bold',
        marginTop: 20
    },
})