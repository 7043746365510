import { View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { ScreenNames } from '../../common/screen-names'
import { TeacherDrawerNavigatorPamarList } from '../../teacher/teacher-route-param-types'
import { StackScreenProps } from '@react-navigation/stack'
import { BackButton } from '../../common/back-button'
import { activityCardSelector, getCardId } from '../emotion-cards/cards'
import { tileSizes } from '../common/title-sizes'
import { rvSwitchesScreenState } from '../common/current-screen-state'
import { SwitchesScreens } from '../navigation/switches-bottom-navigation'
import { activityGroupCards } from './activity-cards'
import { useReactiveVar } from '@apollo/client'
import { rvUserGroup } from '../../login/login-state'
import { SwitchesActivitiesSeparationAxietyForTeachers } from './cards/separation-anxiety/note-for-teachers'
import { UserGroup } from '../../../common/constants'

export const SwitchesActiviesDetailView = ({ navigation: { navigate }, route: { params: { activity } }, }: StackScreenProps<TeacherDrawerNavigatorPamarList, 'SwitchesActiviesDetailView'>,) => {
    const activityCards = activityCardSelector[activity]
    const activityGroup = activityGroupCards.find(activityGroupCard => activityGroupCard.activity === activity)
    const userGroup = useReactiveVar(rvUserGroup)

    const navigateBack = () => {
        rvSwitchesScreenState(SwitchesScreens.ACTIVITIES)
        navigate(ScreenNames.SwitchesActiviesDisplayAll)
    }

    if (!activityGroup) {
        navigateBack()
        return <></>
    }

    return (
        <>
            <View style={styles.back}>
                <BackButton onPress={navigateBack} />
            </View>
            <DefaultText style={styles.header}>
                {activityGroup.subtitle}
            </DefaultText>
            <ScrollView style={styles.scrollview} contentContainerStyle={styles.container} >
                {
                    activityCards ?
                        activityCards.map((Card) => {
                            const navigateToCard = () => {
                                navigate(ScreenNames.Card, { id: Card.cardId, previousScreen: 'activities', previousScreenId: activity })
                            }

                            if (userGroup !== UserGroup.Teacher && Card.cardId === SwitchesActivitiesSeparationAxietyForTeachers.cardId) {
                                return null
                            }

                            
                            return (
                                <TouchableOpacity key={Card.title} style={tileSizes.item} onPress={navigateToCard}>
                                    <View style={tileSizes.tile}>
                                        <Card.Icon />
                                    </View>
                                    <DefaultText style={styles.cardtext}>
                                        {Card.title}
                                    </DefaultText>
                                </TouchableOpacity>
                            )
                        }
                        )
                        :
                        <></>
                }
            </ScrollView>
        </>

    )
}


const styles = StyleSheet.create({

    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: 10,
        paddingBottom: 20,
    },
    content: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: "100%"

    },
    image: {
        width: 350,
        height: 450,
    },
    header: {
        fontSize: 32,
        fontWeight: 'bold',
        color: DecidaColors.Gray,
        textAlign: 'center',
    },
    item: {
        margin: 30,
        width: 175,
        height: 225,
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'center'
    },
    scrollview: {
        flexGrow: 0,
        width: '100%',
        height: '100%',

    },
    cardtext: {
        fontSize: 20,
        textAlign: 'center'
    },
    cardicon: {
        width: 175,
        height: 175,
    },
    back: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    }

})


