import { MutableRefObject } from "react";
import { Br } from "../../common/br";
import { DefaultText } from "../../common/default-text";
import { Message, Sheet } from "./common-import-school-data-const-and-types";

type Props = {
    title: string,
    messages: MutableRefObject<Message[]>
    styles: { color: string }
}

const getMessage = (message: Message) => {
    if (message.sheet === Sheet.All) {
        return message.message
    }

    return `${Sheet[message.sheet]}${message.row === undefined ? '' : ` row ${message.row}`}: ${message.message}`
}

export const Messages = ({ title, messages, styles }: Props) =>
    <>
        <Br />
        <DefaultText style={styles}>
            {title}
        </DefaultText>
        {
            messages.current.map((e, i) => (
                <DefaultText key={i} style={styles}>
                    {getMessage(e)}
                </DefaultText>
            ))
        }
    </>
