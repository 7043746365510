import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import _ from 'lodash'
import { useEffect } from 'react'
import { ActivityIndicator, ScrollView, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native'
import { GetStudentClassByClassIdQueryVariables, ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables, ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables } from '../../common/API'
import { CheckInUserGroup } from '../../common/constants'
import { DecidaColors } from "../../common/decida-colors"
import { listCheckInSessionsByClassId, listStudentCheckInsByCheckInSessionId } from '../../common/graphql/queries'
import { isDefined } from '../../common/is-defined'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'
import { rvCheckInState } from '../check-in/check-in-state'
import { rvCurrentClass } from '../common/common-state'
import { DefaultText } from '../common/default-text'
import { isGoModeCheckInOpen } from '../common/is-go-mode-check-in-open'
import { rvIsLoading } from '../common/loading'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { showOfflineAlert } from '../common/universal-alert'
import { useCalculateExtraItemBasedOnScreenWidth } from '../common/use-calculate-extra-item-based-on-screen-width'
import useConnectivity from '../common/use-connectivity'
import { avatars } from '../icon/avatar/avatars'
import { StudentClassApprovalStatus, StudentStatus } from '../models'
import { listStudentClassAndStudentDataByClassIdCustomQuery, ListStudentClassAndStudentDataByClassIdCustomQuery } from '../teacher/teacher-graphql-scripts'
import { rvClassLoginCurrentCheckIn, rvClassLoginCurrentStudents, StudentAndStudentClass } from './class-login-state'



const itemSize = 100

export const ClassLoginHome = ({ navigation: { navigate } }: StackScreenProps<CheckInNavigatorParamList, "CheckInStart">) => {

    const currentClass = useReactiveVar(rvCurrentClass)
    const currentStudents = useReactiveVar(rvClassLoginCurrentStudents);

    const { data: checkInSessionsDataResponse } = useQuery<ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables>(gql`${listCheckInSessionsByClassId}`, { variables: { classID: currentClass?.id || "", limit: 5000 }, skip: !currentClass })
    const currentCheckIn = checkInSessionsDataResponse?.listCheckInSessionsByClassId?.items.find((item) => item?.isActive && item._deleted !== true)

    const { data: studentCheckInDataResponse } = useQuery<ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables>(gql`${listStudentCheckInsByCheckInSessionId}`, { variables: { checkinsessionID: currentCheckIn?.id || "", limit: 5000 }, skip: !currentCheckIn })

    const { data: studentClassAndStudentDataResponse } = useQuery<ListStudentClassAndStudentDataByClassIdCustomQuery, GetStudentClassByClassIdQueryVariables>(gql`${listStudentClassAndStudentDataByClassIdCustomQuery}`, { variables: { classID: currentClass?.id || "", limit: 5000 }, skip: !currentClass?.id })
    const activeStudentClasses = studentClassAndStudentDataResponse?.getStudentClassByClassId?.items.filter((item) => item?._deleted !== true && item?.status === StudentClassApprovalStatus.APPROVED)


    useEffect(() => {
        rvClassLoginCurrentCheckIn(currentCheckIn)
    }, [currentCheckIn])

    const studentCheckIns = studentCheckInDataResponse?.listStudentCheckInsByCheckInSessionId?.items.filter(isDefined) || []
    const { onlineRef } = useConnectivity()
    const isLoading = rvIsLoading()
    const isFetchCompleted = currentClass && !isLoading


    const activeStudents: StudentAndStudentClass[] = []
    currentStudents.forEach(currentStudent => {
        if (currentStudent.studentClass?.status === StudentClassApprovalStatus.APPROVED) {
            if (currentStudent.student) {
                activeStudents.push(currentStudent)
            }
        }
    })

    const extraItems = useCalculateExtraItemBasedOnScreenWidth(itemSize, 0, currentStudents.filter(student => student?.student?.status === StudentStatus.ACTIVE).length)


    const itemContainerStyle = [styles.student, { minWidth: itemSize, maxWidth: itemSize }]

    const activeStudentClassesAndStudentData = activeStudentClasses?.filter(item => item?.student !== undefined && item?.status === StudentClassApprovalStatus.APPROVED) || []

    const sortedStudents = _.sortBy(activeStudentClassesAndStudentData.filter(studentClassAndStudent => studentClassAndStudent?.status === StudentClassApprovalStatus.APPROVED).map(studentClassAndStudent => ({
        ...studentClassAndStudent,
        checkedIn: studentCheckIns.some(studentCheckIn => studentCheckIn?.studentID === studentClassAndStudent?.student?.id && !studentCheckIn?.absence && studentCheckIn?.tiredness),

    })), sortBy => [sortBy.checkedIn, sortBy.student?.nickname])



    if (!isFetchCompleted) {
        return (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color={DecidaColors.Green} />
                <DefaultText style={styles.text}>
                    Please wait while we get things ready…
                </DefaultText>
            </View>
        )
    }

    return (
        <Page style={styles.page}>
            {currentCheckIn || currentClass.goMode === true && isGoModeCheckInOpen() ?
                <ScrollView contentContainerStyle={styles.studentList}>
                    {
                        sortedStudents.map((student, index) => {

                            const Avatar = avatars[student.student?.avatar as keyof typeof avatars]
                            const navigateToTirednessScale = () => {
                                rvCheckInState({
                                    studentID: student.student?.id,
                                    checkinsessionID: currentCheckIn?.id
                                })
                                navigate(ScreenNames.CheckInTirednessScale, { classID: currentClass?.id, user: CheckInUserGroup.Student })
                            }

                            const onPressCheckIn = async () => {
                                try {
                                    if (!onlineRef.current) throw new Error("You're offline")
                                    navigateToTirednessScale()
                                } catch (error) {
                                    showOfflineAlert(onPressCheckIn)
                                }
                            }

                            return (
                                <TouchableOpacity key={student?.student?.nickname} onPress={onPressCheckIn}>
                                    <View style={student.checkedIn ? [styles.student, styles.checkedInStudent] : styles.student}>
                                        <View style={styles.avatar}>
                                            <Avatar />
                                        </View>
                                        <DefaultText style={styles.nickname}>{student.student?.nickname}</DefaultText>
                                    </View>
                                </TouchableOpacity>
                            )
                        })
                    }
                    {
                        extraItems > 0 ? Array(extraItems).fill(0).map((_, index) => <View key={index} style={itemContainerStyle} />) : null
                    }
                </ScrollView>
                :
                <View style={styles.noCheckin}>
                    {currentClass.goMode &&
                        <DefaultText style={styles.noCheckinText}>GO Group check in only available from 8AM to 4PM.</DefaultText>
                        || <DefaultText style={styles.noCheckinText}>There are no check in sessions currently active.</DefaultText>
                    }
                </View>
            }
        </Page>
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
    },
    studentList: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'flex-start',
    },
    student: {
        justifyContent: 'center',
        alignItems: 'center',
        width: itemSize,
        padding: 10,
    } as ViewStyle,
    checkedInStudent: {
        opacity: 0.5,
    },
    avatar: {
        width: 70,
        height: 70,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noCheckin: {
        borderWidth: 2,
        borderRadius: 5,
        borderColor: DecidaColors.Red,
        alignContent: 'center',
        justifyContent: 'center',
        padding: 5,
        margin: 5,
    },
    noCheckinText: {
        justifyContent: 'center',
        textAlign: 'center',
        padding: 5,
        color: DecidaColors.Black,
    },
    nickname: {
        width: itemSize - 20,
        textAlign: 'center',
    } as TextStyle,
    lockbutton: {
        backgroundColor: DecidaColors.Red
    },
    button: {
        height: 80,
        borderRadius: 5,
        borderWidth: 2,
        backgroundColor: DecidaColors.White,
        borderColor: DecidaColors.Green,
        paddingLeft: 40,
        paddingRight: 40,
        margin: 10,

    } as ViewStyle,
    textContainer: {
        width: 180,
    },
    buttonText: {
        color: DecidaColors.Black,
        paddingLeft: 10,
        fontWeight: '700',
        textAlign: 'center',
    },
    endCheckInButton: {
        borderColor: DecidaColors.Orange,
    } as ViewStyle,

    secureScreenButton: {
        borderColor: DecidaColors.Red,
    } as ViewStyle,
    text: {
        padding: 10,
        textAlign: "center",
    },
})