import _ from "lodash"
import { ActiveListeningWorksheetsIcon } from "../switches/activities/icons/worksheets/active-listening-worksheets-icon"
import { BeingCourageousWorksheetsIcon } from "../switches/activities/icons/worksheets/being-courageous-worksheets-icon"
import { BookReviewWorksheetsIcon } from "../switches/activities/icons/worksheets/book-review-worksheets-icon"
import { BrainStormBlitzWorksheetsIcon } from "../switches/activities/icons/worksheets/brainstorm-blitz-worksheets-icon"
import { BreathDrawWorkSheetsIcon } from "../switches/activities/icons/worksheets/breath-draw-worksheets-icon"
import { ColorHuntWorksheetsIcon } from "../switches/activities/icons/worksheets/color-hunt-worksheets-icon"
import { DanceCardWorksheetsIcon } from "../switches/activities/icons/worksheets/dance-card-worksheets-icon"
import { DanceRoutineWorksheetsIcon } from "../switches/activities/icons/worksheets/dance-routine-worksheets-icon"
import { DrawYourFutureSelfWorksheetsIcon } from "../switches/activities/icons/worksheets/draw-your-future-self-worksheets-icon"
import { FightFreezeFlightWorksheetsIcon } from "../switches/activities/icons/worksheets/fight-freeze-flight-worksheets-icon"
import { FindAFriendWorksheetsIcon } from "../switches/activities/icons/worksheets/find-a-friend-worksheets-icon"
import { IdentifyFiveWorksheetsIcon } from "../switches/activities/icons/worksheets/identify-5-worksheets-icon"
import { JournalingWorksheetsIcon } from "../switches/activities/icons/worksheets/journaling-worksheets-icon"
import { LetterFromFutureSelfWorksheetsIcon } from "../switches/activities/icons/worksheets/letter-from-future-self-worksheets-icon"
import { LimerickWorksheetsIcon } from "../switches/activities/icons/worksheets/limerick-worksheets-icon"
import { MakeANewFriendWorksheetsIcon } from "../switches/activities/icons/worksheets/make-a-new-friend-worksheets-icon"
import { ManagingMyAngerWorksheetsIcon } from "../switches/activities/icons/worksheets/managing-my-anger-worksheets-icon"
import { MusicEmotionsWorksheetsIcon } from "../switches/activities/icons/worksheets/music-emotions-worksheets-icon"
// import { MyAngerButtonsIcon } from "../switches/activities/icons/worksheets/my-anger-buttons-icon"
import { ExceptionalAwardsTokenWorksheet } from "../switches/activities/cards/fun-and-positive/src/exceptinal-awards-token-worksheets"
import { ExceptionalAwardsWorksheet } from "../switches/activities/cards/fun-and-positive/src/exceptinal-awards-worksheets"
import { ExceptionalAwardsGoalsWorksheet } from "../switches/activities/cards/fun-and-positive/src/exceptional-awards-goals-worksheet"
import { GratitudeCardsWorksheetIcon } from "../switches/activities/icons/worksheets/gratitude-cards-worksheet-icon"
import { MyAffirmationWorksheetsIcon } from "../switches/activities/icons/worksheets/my-affirmations-worksheets-icon"
import { MyFearsWorksheetsIcon } from "../switches/activities/icons/worksheets/my-fears-worksheets-icon"
import { MyRoleModelWorksheetsIcon } from "../switches/activities/icons/worksheets/my-role-model-worksheets-icon"
import { MySpecialPlaceWorksheetsIcon } from "../switches/activities/icons/worksheets/my-special-place-worksheets-icon"
import { NameThatSoundWorksheetsIcon } from "../switches/activities/icons/worksheets/name-that-sound-worksheets-icon"
import { RandomActsOfKindnessWorksheetsIcon } from "../switches/activities/icons/worksheets/random-acts-of-kindness-worksheets-icon"
import { ReactiveAndResponsiveWorksheetsIcon } from "../switches/activities/icons/worksheets/reactive-and-responsive-worksheets-icon"
import { ReframeWorksheetsIcon } from "../switches/activities/icons/worksheets/reframe-worksheets-icon"
import { RelationShipCircleWorksheetsIcon } from "../switches/activities/icons/worksheets/relationship-circle-worksheets-icon"
import { ScavengerFindAndSketchesWorksheetsIcon } from "../switches/activities/icons/worksheets/scavenger-find-and-sketches-worksheets-icon"
import { ScavengerWorksheetsIcon } from "../switches/activities/icons/worksheets/scavenger-worksheets-icon"
import { ShowMeTheBunnyWorksheetIcon } from "../switches/activities/icons/worksheets/show-me-the-bunny-worksheet-icon"
import { SmallStepsWorksheetsIcon } from "../switches/activities/icons/worksheets/small-steps-worksheet-icon"
import { StopStepsWorksheetIcon } from "../switches/activities/icons/worksheets/stop-steps-worksheets-icon"
import { StretchingWorksheetsIcon } from "../switches/activities/icons/worksheets/stretching-worksheets-icon"
import { TaleOfCourageWorksheetsIcon } from "../switches/activities/icons/worksheets/tale-of-courage-worksheets-icon"
import { ThankfullWorksheetsIcon } from "../switches/activities/icons/worksheets/thankfull-worksheets-icon"
import { TheAppreciatorWorksheetsIcon } from "../switches/activities/icons/worksheets/the-appreciator-worksheets-icon"
import { TheGreatShowdownWorksheetIcon } from "../switches/activities/icons/worksheets/the-great-showdown-worksheet"
import { ToughtsInterupterWorksheetsIcon } from "../switches/activities/icons/worksheets/thoughts-interupter-worksheets-icon"
import { TraceBreathingWorksheetsIcon } from "../switches/activities/icons/worksheets/trace-breathing-worksheets-icon"
import { ValueCardsWorksheetsIcon } from "../switches/activities/icons/worksheets/value-cards-worksheets-icon"
import { ValueIdentifierWorksheetsIcon } from "../switches/activities/icons/worksheets/value-identifier-worksheets-icon"
import { ValueListWorksheetsIcon } from "../switches/activities/icons/worksheets/value-list-worksheets-icon"
import { WarmAndFuzziesLessonPlan } from "../switches/activities/icons/worksheets/warm-fuzzies-lesson-plan"
import { WhatDoesAnxietyDoWorksheetsIcon } from "../switches/activities/icons/worksheets/what-does-anxiety-do-worksheets-icon"
import { FiveWhysLessonPlan } from "../switches/emotion-cards/angry/src/five-whys-lesson-plan"
import { TheDoOverLessonPlan } from "../switches/emotion-cards/angry/src/the-do-over-lesson-plan"
import { BubbleOfControlLessonPlan } from "../switches/emotion-cards/anxious/src/bubble-of-control-lesson-plan"
import { CircleOfInfluenceLessonPlan } from "../switches/emotion-cards/anxious/src/circle-of-influence-lesson-plan"
import { StoryboardLessonPlan1 } from "../switches/emotion-cards/anxious/src/storyboard-lesson-plan1"
import { WorryMonsterLessonPlan } from "../switches/emotion-cards/anxious/src/worry-monster-lesson-plan"
import { CalmingMazeGamesLessonPlan } from "../switches/emotion-cards/excited/src/calming-maze-games-image-lesson-plan"
import { TheTwisterLessonPlan } from "../switches/emotion-cards/excited/src/the-twister-lesson-plan"
import { TreasureHuntLessonPlan } from "../switches/emotion-cards/excited/src/treasure-hunt-lesson-plan"
import { WeatherItLessonPlan } from "../switches/emotion-cards/excited/src/weather-it-lesson-plan"
import { BeCreativeLessonPlan } from "../switches/emotion-cards/happy/src/be-creative-lesson-plan"
import { FriendFinderWorksheetImage } from "../switches/emotion-cards/happy/src/friend-finder-worksheet-image"
import { SmartGoalsLessonPlan } from "../switches/emotion-cards/happy/src/smart-goals-lesson-plan"
import { GrowthMindsetLessonPlan } from "../switches/emotion-cards/sad/src/growth-mindset-lesson-plan"
import { SmileLessonPlan } from "../switches/emotion-cards/sad/src/smile-lesson-plan"
import { MantraLessonPlan } from "../switches/emotion-cards/scared/src/mantra-lesson-plan"
import { AbcAnxietyWorksheet } from "../switches/lesson-plan-cards/src/abc-anxiety-worksheet"
import { AngerStretchesWorksheet } from "../switches/lesson-plan-cards/src/anger-stretches-worksheet"
import { BuildingResilienceWorksheet } from "../switches/lesson-plan-cards/src/building-resilience-worksheet"
import { ConflictCrusherWorksheet } from "../switches/lesson-plan-cards/src/conflict-crushers-worksheet"
import { ConflictManagementScenarioHandouts } from "../switches/lesson-plan-cards/src/conflict-management-scenario-handouts"
import { ConflictStylesWorksheet } from "../switches/lesson-plan-cards/src/conflict-styles-worksheet"
import { EiInLeadershipWorksheet } from "../switches/lesson-plan-cards/src/ei-in-leadership-worksheet"
import { EiInRelationshipsLessonPlan } from "../switches/lesson-plan-cards/src/ei-in-relationships-lesson-plan"
import { EmpathyWorksheet } from "../switches/lesson-plan-cards/src/empathy-worksheet"
import { ExcitedBaloonWorksheet } from "../switches/lesson-plan-cards/src/excited-baloon-worksheet"
import { ExpressWithConfidenceWorksheet } from "../switches/lesson-plan-cards/src/express-with-confidence-worksheet"
import { FindYourCalmStrategiesWorksheet } from "../switches/lesson-plan-cards/src/find-your-calm-strategies-worksheet"
import { FindYourCalmWorksheet } from "../switches/lesson-plan-cards/src/find-your-calm-worksheet"
import { GoodAndBadQuestionWorksheet } from "../switches/lesson-plan-cards/src/good-and-bad-question-worksheet"
import { GoodLeadersWorksheet } from "../switches/lesson-plan-cards/src/good-leaders-worksheet"
import { HelpOrHurtFearsWorksheet } from "../switches/lesson-plan-cards/src/help-or-hurt-fears-worksheet"
import { HelpOrHurtRecapWorksheet } from "../switches/lesson-plan-cards/src/help-or-hurt-recap-worksheet"
import { IcanCopeWithFeelingSadWorksheet } from "../switches/lesson-plan-cards/src/i-can-cope-with-feeling-sad-worksheet"
import { InspirationStationAnswersWorksheet } from "../switches/lesson-plan-cards/src/inspiration-station-answers-worksheet"
import { InspirationStationWorksheet } from "../switches/lesson-plan-cards/src/inspiration-station-worksheet"
import { IntroductionToAngryLessonPlan2 } from "../switches/lesson-plan-cards/src/introduction-to-angry-lesson-plan2"
import { IntroductionToAngryLessonPlan2Level3 } from "../switches/lesson-plan-cards/src/introduction-to-angry-lesson-plan2-level3"
import { IntroductionToAnxiousLessonPlan1 } from "../switches/lesson-plan-cards/src/introduction-to-anxious-lesson-plan1"
import { IntroductionToHappyLessonPlan2 } from "../switches/lesson-plan-cards/src/introduction-to-happy-lesson-plan2"
import { IntroductionToSadLessonPlan2 } from "../switches/lesson-plan-cards/src/introduction-to-sad-lesson-plan2"
import { IntroductionToScaredLessonPlan2 } from "../switches/lesson-plan-cards/src/introduction-to-scared-lesson-plan2"
import { ObservingEmotionWorksheets } from "../switches/lesson-plan-cards/src/observing-emotions-worksheets"
import { RolePerspectiveCards } from "../switches/lesson-plan-cards/src/role-perspective-cards"
import { RolePlayScenarioWorksheet } from "../switches/lesson-plan-cards/src/role-play-scenario-worksheet"
import { TheBucketListWorksheet } from "../switches/lesson-plan-cards/src/the-bucket-list-worksheet"
import { TheOrganizerWeeklyPlannerWorksheet } from "../switches/lesson-plan-cards/src/the-organizer-weekly-planner"
import { TheRiddlerWorksheet } from "../switches/lesson-plan-cards/src/the-riddler-worksheets"
import { TheScribeWorksheet } from "../switches/lesson-plan-cards/src/the-scribe-worksheets"
import { M1PrimaryAndSecondaryEmotionsWorksheet } from "../switches/lesson-plan-cards/src/m1-primary-and-secondary-emotions-worksheet"
import { MasteringTheMindWorksheetImage } from "../switches/lesson-plan-cards/src/mastering-the-mind-worksheet-image"
import { M2EmotionalAndLogicalDecisionMakingWorksheet } from "../switches/lesson-plan-cards/src/m2-emotional-and-logical-decision-making-worksheet"
import { ButterflyBreathingWorksheetImage } from "../switches/lesson-plan-cards/src/butterfly-breathing-worksheet-image"
import { EmotionMatchUpWorksheetImage } from "../switches/lesson-plan-cards/src/emotion-match-up-worksheet-image"
import { CardFilterEmotionType } from "../switches/common/card-template"
import { SimpleActOfKindessWorksheetImage } from "../switches/lesson-plan-cards/src/simple-act-of-kindess-worksheet-image"
import { GoToPodiumWorksheetImage } from "../switches/lesson-plan-cards/src/go-to-podium-worksheet-image"
import { SeekHelpCutoutsWorksheetImage } from "../switches/lesson-plan-cards/src/seek-help-cutouts-worksheet-image"

export enum CardActivitySheetsFilter {
    Current = "Current",
    ExtraSwitches = "ExtraSwitches",
    Archive = "Archive"
}

export enum CardActivitySheetsEmotionFilter {
    Angry = "Angry",
    Anxious = "Anxious",
    Sad = "Sad",
    Scared = "Scared",
    Happy = "Happy",
    Excited = "Excited",
    NonEmotionSpecific = "Non-emotion specific"
}

export enum CardActivitySheetsThoughtActionFilter {
    Thought = "Thought",
    Activity = "Activity"
}

export enum CardActivitySheetsTimeFilter {
    Short = "Less than 5 minutes",
    Medium = "5-15 minutes",
    Long = "More than 15 minutes"
}

export type WorksheetType = {
    label: string
    uri: string
    image: () => JSX.Element,
    activity: WorksheetActivity,
    emotion?: CardActivitySheetsEmotionFilter[],
    time?: CardActivitySheetsTimeFilter[],
    thoughtOrAction?: CardActivitySheetsThoughtActionFilter[]
}

export enum WorksheetActivity {
    Doing,
    Thinking
}

export const Worksheets = {
    breathDraw: {
        label: "Breath Draw",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Breath+Draw+Worksheet.pdf",
        image: BreathDrawWorkSheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Angry],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    reactiveAndResponsive: {
        label: "Reactive vs Responsive",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Reactive+vs+Responsive+Worksheet.pdf",
        image: ReactiveAndResponsiveWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Angry],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    showMetheBunny: {
        label: 'Show me the bunny cutouts',
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Show+me+the+bunny+cut-outs.pdf",
        image: ShowMeTheBunnyWorksheetIcon,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    drawYourFutureSelf: {
        label: "Draw Your Future Self",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Draw+Your+Future+Self+Worksheet.pdf",
        image: DrawYourFutureSelfWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Angry],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    stopSteps: {
        label: "Stop Steps",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Stop+Steps+Worksheets.pdf",
        image: StopStepsWorksheetIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Angry],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    scavengerHuntMyFiveSense: {
        label: "Scavenger Hunt - My Five Senses",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/My+5+Senses+Scavenger+hunt+Worksheet.pdf",
        image: ScavengerWorksheetsIcon,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Angry],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    fightFreezeFlight: {
        label: "Fight Freeze Flight",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Fight+Freeze+Flight+Worksheet.pdf",
        image: FightFreezeFlightWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Angry],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    managingMyAnger: {
        label: "Managing My Anger",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Managing+My+Anger+Worksheet.pdf",
        image: ManagingMyAngerWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Angry],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    journaling: {
        label: "Journaling",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Journaling+worksheet.pdf",
        image: JournalingWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Anxious],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    mySpecialPlace: {
        label: "My Special Place",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/My+special+place+Worksheet.pdf",
        image: MySpecialPlaceWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Anxious],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    reframe: {
        label: "Reframe",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Reframe+Worksheet.pdf",
        image: ReframeWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Anxious],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    whatDoesAnxietyDoToMyBody: {
        label: "What Does Anxiety Do To My Body?",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/What+Does+Anxiety+Do+To+My+Body+worksheets.pdf",
        image: WhatDoesAnxietyDoWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Anxious],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    thoughtInteruptedCards: {
        label: "Thought Interrupters Cards",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Thought+Interrupters+cards.pdf",
        image: ToughtsInterupterWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Anxious],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    colourHunt: {
        label: "Colour Hunt",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Colour+Hunt+Worksheet.pdf",
        image: ColorHuntWorksheetsIcon,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Short],
        emotion: [CardActivitySheetsEmotionFilter.Excited],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    danceCards: {
        label: "Dance Cards",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Dance+Cards+Worksheets.pdf",
        image: DanceCardWorksheetsIcon,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Excited],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    danceRoutine: {
        label: "Dance Routine",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Dance+Routine+Worksheet.pdf",
        image: DanceRoutineWorksheetsIcon,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Excited],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    nameThatSound: {
        label: "Name That Sound",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Name+that+sound+Worksheets.pdf",
        image: NameThatSoundWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Excited],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    bookReview: {
        label: "Book Review",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Book+Review+Worksheet.pdf",
        image: BookReviewWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Excited],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    traceBreathing: {
        label: "Trace Breathing",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Trace+Breathing+Worksheet.pdf",
        image: TraceBreathingWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Short, CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Excited],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    randomActOfKindness: {
        label: "Random Acts of Kindness",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Random+Act+of+Kindness_.pdf",
        image: RandomActsOfKindnessWorksheetsIcon,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    thankful: {
        label: "Thankful",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Thankful+wsheet.pdf",
        image: ThankfullWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    limerick: {
        label: "Limerick",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Limerick+wsheet.pdf",
        image: LimerickWorksheetsIcon,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    makeANewFriend: {
        label: "Make A New Friend",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Make+a+new+friend.pdf",
        image: MakeANewFriendWorksheetsIcon,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    findAFriend: {
        label: "Find A Friend",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Find+a+Friend.pdf",
        image: FindAFriendWorksheetsIcon,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    activeListening: {
        label: "Active Listening",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Active+Listening.pdf",
        image: ActiveListeningWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    valueIdentifier: {
        label: "Values Identifier",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Values+wsheet.pdf",
        image: ValueIdentifierWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    valueList: {
        label: "Values List",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Values+List.pdf",
        image: ValueListWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    valueCards: {
        label: "Values Cards",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Values+Cards.pdf",
        image: ValueCardsWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    relationShipCircle: {
        label: "Relationship Circles",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Who+is+in+my+relationship+circles+worksheet.pdf",
        image: RelationShipCircleWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Short, CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Sad],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    theAppreciator: {
        label: "The Appreciator",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/The+Appreciator+worksheet.pdf",
        image: TheAppreciatorWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Scared],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    aLetterFromMyFutureSelf: {
        label: "A Letter From My Future Self",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/A+Letter+from+my+Future+Self+worksheet.pdf",
        image: LetterFromFutureSelfWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Sad],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    myAffirmations: {
        label: "My Affirmations",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/My+Affirmations+Worksheets.pdf",
        image: MyAffirmationWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Sad],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    musicEmotions: {
        label: "Music Emotions",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Music+Emotions+Worksheet.pdf",
        image: MusicEmotionsWorksheetsIcon,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Sad],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    smallSteps: {
        label: "Small Steps",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Small+Steps+Worksheet.pdf",
        image: SmallStepsWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Sad],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    stretching: {
        label: "Stretching",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Stretching+Guide+Sheet.pdf",
        image: StretchingWorksheetsIcon,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Sad],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    scavengerHuntFindAndSketch: {
        label: "Scavenger Hunt - Find & Sketch",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Scavenger+Hunt_Find+and+Sketch+Worksheet.pdf",
        image: ScavengerFindAndSketchesWorksheetsIcon,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Sad],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    beingCouregous: {
        label: "Being Courageous",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Being+courageous.pdf",
        image: BeingCourageousWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Scared],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    myFears: {
        label: "My Fears",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/My+Fears+worksheet.pdf",
        image: MyFearsWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Scared],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    identify5: {
        label: "Identify 5!",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Identify+5+checklist.pdf",
        image: IdentifyFiveWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Scared],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    myRoleModel: {
        label: "My Role Model",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/My+Role+Model+worksheet.pdf",
        image: MyRoleModelWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Scared],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    aTaleOfCourage: {
        label: "A Tale of Courage",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/A+Tale+of+Courage.pdf",
        image: TaleOfCourageWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Scared],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    weatherSymbolsCards: {
        label: "Weather Symbols Cards",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Weather+Symbol+Cards.pdf",
        image: WeatherItLessonPlan,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Excited],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    differentTypeOfSmiles: {
        label: "Different types of smiles sheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Different+types+of+smiles+sheet.pdf",
        image: SmileLessonPlan,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Sad],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    worryMonster: {
        label: "Worry Monster Plan Worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Worry+Monster+Plan+Worksheet+Worksheet.pdf",
        image: WorryMonsterLessonPlan,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Anxious],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    circleOfControl: {
        label: "Circle of Control worksheet or Locus of Control worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Bubble+of+control+worksheet.pdf",
        image: BubbleOfControlLessonPlan,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Anxious],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    myDreamHoliday: {
        label: "My Dream Holiday worksheet.",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/My+Dream+Holiday+(Be+Creative)+worksheet.pdf",
        image: BeCreativeLessonPlan,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    circleOfConcern: {
        label: "Circle of Concern (influence) worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Circle+of+Concern+(influence)+worksheet.pdf",
        image: CircleOfInfluenceLessonPlan,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Scared],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    smartGoals: {
        label: "SMART Goals worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/SMART+Goals+worksheet.pdf",
        image: SmartGoalsLessonPlan,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    theDoOver: {
        label: "The Do-Over Worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/The+Do-Over+Worksheet.pdf",
        image: TheDoOverLessonPlan,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Angry],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    storyBoard: {
        label: "Storyboard Worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Storyboard+Worksheet.pdf",
        image: StoryboardLessonPlan1,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Anxious],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    theTwister: {
        label: "Twister Sheet Handout",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Twister+Photos+Worksheet.pdf",
        image: TheTwisterLessonPlan,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Short],
        emotion: [CardActivitySheetsEmotionFilter.Excited],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    fiveWhys: {
        label: "5 Whys technique worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/5+whys+Worksheets.pdf",
        image: FiveWhysLessonPlan,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought],
        emotion: [CardActivitySheetsEmotionFilter.Angry]
    },
    angerVolcano: {
        label: "Anger Volcano worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/The+Anger+Volcano+Worksheet_.pdf",
        image: IntroductionToAngryLessonPlan2,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Angry],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    angerVolcanoLevel3: {
        label: "Anger Volcano worksheet level 3",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Anger+volcano+worksheet+level+3.pdf",
        image: IntroductionToAngryLessonPlan2Level3,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Angry],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    worryCup: {
        label: "Worry Cup worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/My+worry+cup+Worksheet.pdf",
        image: IntroductionToAnxiousLessonPlan1,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Anxious],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    whatMakesYouHappy: {
        label: "What makes me happy worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/What+makes+me+happy.pdf",
        image: IntroductionToHappyLessonPlan2,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    managingSadness: {
        label: "Managing sadness worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Managing+my+sadness.pdf",
        image: IntroductionToSadLessonPlan2,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Sad],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    thisTakeCourtage: {
        label: "This Takes Courage Worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/This+Takes+Courage+Worksheet.pdf",
        image: IntroductionToScaredLessonPlan2,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Scared],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    mazePuzzle: {
        label: "Maze Puzzle Worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Maze+Games+Worksheets.pdf",
        image: CalmingMazeGamesLessonPlan,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Short, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    pathDrawing: {
        label: "Path Drawing Worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Path+Drawing+Worksheet.pdf",
        image: TreasureHuntLessonPlan,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Short, CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Excited],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    theGrowthMindset: {
        label: "The growth mindset worksheet, detailing the scenarios",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Growth+Mindset+worksheet.pdf",
        image: GrowthMindsetLessonPlan,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    theMantra: {
        label: "The mantra worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Make+a+Mantra+Worksheet.pdf",
        image: MantraLessonPlan,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Scared],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    warmFuzzies: {
        label: "Warm Fuzzies",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Warm+Fuzzies+handouts.pdf",
        image: WarmAndFuzziesLessonPlan,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.NonEmotionSpecific],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    brainstormBlitz: {
        label: "Brainstorm blitz",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Brainstorm+blitz+worksheet.pdf",
        image: BrainStormBlitzWorksheetsIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.NonEmotionSpecific],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    gratitudeCards: {
        label: "Gratitude card",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Gratitude+card+cut-outs.pdf",
        image: GratitudeCardsWorksheetIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    theGreatShowdown: {
        label: "The great showdown",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/The+great+showdown+cut-outs.pdf",
        image: TheGreatShowdownWorksheetIcon,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    friendFinder: {
        label: "Friend finder",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Friend+finder+lesson+plan+worksheet.pdf",
        image: FriendFinderWorksheetImage,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Short],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    exceptionalAwards: {
        label: "Exceptional awards worksheets",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Exceptional+awards+award.pdf",
        image: ExceptionalAwardsWorksheet,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Short],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    exceptionalAwardsToken: {
        label: "Exceptional awards token worksheets",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Exceptional+awards+tokens.pdf",
        image: ExceptionalAwardsTokenWorksheet,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Short],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    exceptionalAwardsGoals: {
        label: "Exceptional awards goals worksheets",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Exceptional+awards+goals+and+score+sheet.pdf",
        image: ExceptionalAwardsGoalsWorksheet,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Short],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    conflictManagementScenarioHandouts: {
        label: "Conflict management scenario handouts",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Conflict+management+scenario+handouts.pdf",
        image: ConflictManagementScenarioHandouts,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    perspectiveTakingCards: {
        label: "Perspective taking, role perspective cards",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/P9+Role+perspective+cards.pdf",
        image: RolePerspectiveCards,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    buildingResilience: {
        label: "Building resilience worksheets",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/P11+Emotional+Resilience+Worksheet.pdf",
        image: BuildingResilienceWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    conflictCrusher: {
        label: "Conflict resolution worksheets or handouts",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/P13+Conflict+Crushers+handout.pdf",
        image: ConflictCrusherWorksheet,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    abcAnxietyWorksheet: {
        label: "ABC anxiety worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/A3+ABC+anxiety+worksheet.pdf",
        image: AbcAnxietyWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    iCanCopeWithFeelingSadWorksheet: {
        label: "I can cope with feeling sad worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/A5+Understanding+and+Managing+Sadness+worksheet.pdf",
        image: IcanCopeWithFeelingSadWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    excitedBaloonWorksheets: {
        label: "Excited balloon worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/A7+Excited+balloon.pdf",
        image: ExcitedBaloonWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    observingEmotionWorksheets: {
        label: "Observing emotion worksheets",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Observing+emotions+worksheet.pdf",
        image: ObservingEmotionWorksheets,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    relationshipBuildingSkillWorksheet: {
        label: "Relationship building skill worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/A9+Relationship+Building+skills.pdf",
        image: EiInRelationshipsLessonPlan,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    empathyWorksheet: {
        label: "Empathy worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/A10+Advanced+Empathy.pdf",
        image: EmpathyWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Short, CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    copingStrategies: {
        label: "Coping strategies scenario",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Conflict+managment+worksheet.pdf",
        image: EmpathyWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    conflictStylesWorksheet: {
        label: "Conflict styles worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Conflict+styles+worksheet.pdf",
        image: ConflictStylesWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    goodLeadersWorksheet: {
        label: "Good leaders worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/A13+Good+leaders+worksheet.pdf",
        image: GoodLeadersWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Short],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    emotionalIntelligenceInleadership: {
        label: "Role-play scenarios for leadership activities",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/A13+Mastering+the+Art+of+EI+in+Leadership+worksheet.pdf",
        image: EiInLeadershipWorksheet,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    expressWithConfidenceWorksheet: {
        label: "Express with confidence worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/P10+Express+with+Confidence+worksheet.pdf",
        image: ExpressWithConfidenceWorksheet,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    rolePlayScenarioHandouts: {
        label: "Role play scenario handouts",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/P8+Adventures+with+Feelings+Role+Play+Scenarios+worskeet.pdf",
        image: RolePlayScenarioWorksheet,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    theBucketListCardHandouts: {
        label: "The bucket list Cards handout",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Bucket+list+cards+handout.pdf",
        image: TheBucketListWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardActivitySheetsEmotionFilter.Sad],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    findYourCalmStrategies: {
        label: "Find your calm strategies list",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Find+your+calm+strategies.pdf",
        image: FindYourCalmStrategiesWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Angry],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought, CardActivitySheetsThoughtActionFilter.Activity]
    },
    findYourCalm: {
        label: "Find your calm print outs",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Find+your+calm.pdf",
        image: FindYourCalmWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Angry],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought, CardActivitySheetsThoughtActionFilter.Activity]
    },
    angerStretchesWorksheet: {
        label: "Anger stretches worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Anger+stretches+worksheet.pdf",
        image: AngerStretchesWorksheet,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Short],
        emotion: [CardActivitySheetsEmotionFilter.Angry],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    helpOrHurtRecapWorksheet: {
        label: "Help or hurt recap",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Help+or+hurt+recap.pdf",
        image: HelpOrHurtRecapWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Scared],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    helpOrHurtFearsWorksheet: {
        label: "Fears worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Help+or+hurt+fears+worksheet.pdf",
        image: HelpOrHurtFearsWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Scared],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    inspirationStationAnswersWorksheet: {
        label: "Inspiration station answers.pdf",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Inspiration+station+worksheet+answers.pdf",
        image: InspirationStationAnswersWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Short],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    inspirationStationWorksheet: {
        label: "Inspiration station worksheet.pdf",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Inspiration+station+worksheet.pdf",
        image: InspirationStationWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Short],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    goodAndBadQuestionWorksheet: {
        label: "Good and bad questions worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Good+and+Bad+questions+worksheet.pdf",
        image: GoodAndBadQuestionWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Short],
        emotion: [CardActivitySheetsEmotionFilter.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    theOrganizerWeeklyPlanner: {
        label: "The organizer weekly planner",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/The+organiser+weekly+planner.pdf",
        image: TheOrganizerWeeklyPlannerWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Sad],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    theRiddlerWorksheets: {
        label: "Riddles and puzzles worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/The+riddler.pdf",
        image: TheRiddlerWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Excited],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    theScribeWorksheets: {
        label: "Daily Diary Worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Daily+Diary.pdf",
        image: TheScribeWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium, CardActivitySheetsTimeFilter.Long],
        emotion: [CardActivitySheetsEmotionFilter.Sad],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    m1PrimaryAndSecondaryWorksheets: {
        label: "Primary and secondary emotions worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/M1+Primary+and+secondary+emotions+worksheet.pdf",
        image: M1PrimaryAndSecondaryEmotionsWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    m2EmotionalAndLogicalDecisionMaking: {
        label: "Emotional and logical decision making worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/M2+Emotional+and+Logical+Decision+Making.pdf",
        image: M2EmotionalAndLogicalDecisionMakingWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    m4AnxietyTriggers: {
        label: "Anxiety triggers",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/M4+Anxiety+triggers.pdf",
        image: M2EmotionalAndLogicalDecisionMakingWorksheet,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    masteryMasteringTheMindLevel7: {
        label: "Mastering the Mind (level 7 PG).pdf",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Mastering+the+Mind+(level+7+PG)v1.1.pdf",
        image: MasteringTheMindWorksheetImage,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    butterflyBreathing: {
        label: "Butterfly breathing worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Butterfly+Breathing+Worksheet.pdf",
        image: ButterflyBreathingWorksheetImage,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    emotionMatchUpA1: {
        label: "Emotion match-up worksheet",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Emotion+Match-up+A1+Worksheet.pdf",
        image: EmotionMatchUpWorksheetImage,
        activity: WorksheetActivity.Thinking,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Thought]
    },
    simpleActOfKindnessCutOut: {
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Simple+act+of+kindness+cut+outs.pdf",
        label: "Simple act of kindness cut outs",
        image: SimpleActOfKindessWorksheetImage,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Medium],
        emotion: [CardFilterEmotionType.Happy],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    goToPodiumWorksheet: {
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Go+to+podium.pdf",
        label: '"Go-to podium" worksheet',
        image: GoToPodiumWorksheetImage,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardFilterEmotionType.Scared],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    },
    seekHelpCutOuts: {
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Seek+help+cut+outs.pdf",
        label: '"Seek help" cut outs',
        image: SeekHelpCutoutsWorksheetImage,
        activity: WorksheetActivity.Doing,
        time: [CardActivitySheetsTimeFilter.Long],
        emotion: [CardFilterEmotionType.Scared],
        thoughtOrAction: [CardActivitySheetsThoughtActionFilter.Activity]
    }

}

export const allWorksheets = _.map(Worksheets, (worksheet) => ({ ...worksheet }))