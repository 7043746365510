import { gql, useMutation } from '@apollo/client'
import { Class, SetRequestForChatForGoClassMutation, SetRequestForChatForGoClassMutationVariables, StudentCheckIn, UpdateStudentCheckInMutation, UpdateStudentCheckInMutationVariables } from '../../common/API'
import { setRequestForChatForGoClass, updateStudentCheckIn } from '../../common/graphql/mutations'
import teacherClearStudentRequestForChatAlert from './teacher-clear-student-request-for-chat-alert'
import { StudentAndStudentClassAndStudentCheckIn } from './teacher-stats-last-period-and-requested-chat'
import TeacherStatsLastPeriodAndRequestedChatItems from './teacher-stats-last-period-and-requested-chat-items'
import { useClearRequestForChat } from '../common/use-clear-request-for-chat'

type Props = {
  clazz: Class
}
const TeacherHomeFlaggedStudents = ({ clazz }: Props) => {
  const { handleClearRequestForChat } = useClearRequestForChat({ clazz })

  const onAvatarPress = (studentCheckInDataSet: StudentAndStudentClassAndStudentCheckIn) => {
    teacherClearStudentRequestForChatAlert({ onSubmit: () => handleClearRequestForChat(studentCheckInDataSet) })
  }

  return (
    <TeacherStatsLastPeriodAndRequestedChatItems isDashboard currentClass={clazz} onAvatarPress={onAvatarPress} />
  )
}
export default TeacherHomeFlaggedStudents
