import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { HumanKnotIcon } from '../../icons/human-knot-icon'
import { HumanKnotIconInverted } from '../../icons/human-knot-icon-inverted'


export const SwitchesActivitiesResilienceHumanKnot: CardTemplate = {
    cardId: "7451c3fb-ee7a-4a84-9a5d-b7b180d22f6f",
    title: 'Human knot',
    Header: HumanKnotIconInverted,
    Icon: HumanKnotIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Learning to problem-solve effectively through teamwork and communication"} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Assemble a group of at least 6 friends."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'Form a circle, standing shoulder to shoulder and facing inward.'} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Reach both hands into the centre and grab two different people's hands (not the person next to you). "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The group should now have a tangled knot of arms in the centre. "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'The objective is to untangle the knot, creating a new order to the circle'} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Adaptation:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"If there are 12 or more people wanting to play you can split into two equal teams and race each other to get your knots undone"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Remember:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Communication is key in this game"} />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: '',
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.RESILIENCE_BUILDERS],
    action: [CardActionType.Action],
    level: []
}
