import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { StandTogetherIcon } from '../../icons/stand-together-icon'
import { StandTogetherIconInverted } from '../../icons/stand-together-icon-inverted'


export const SwitchesActivitiesResilienceStandTogether: CardTemplate = {
    cardId: "1726aa92-ed24-4a83-95b5-dfa39c25625f",
    title: 'Stand together',
    Header: StandTogetherIconInverted,
    Icon: StandTogetherIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Using trust, cooperation, problem solving and leadership to build resilience"} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Grab a partner"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Sit on the floor face-to-face with your feet up against each other and holding hands"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Aim:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"For both participants to stand up at the same time"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Rules:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The pair cannot let go of their hands and their feet cannot move. They cannot use any outside forces to help them stand up."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Adaptation:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"If you have a lot of players this game can also be modified to be a race. Pair off and the team who stands up quickest wins."} />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: '',
    type: [CardType.Activity],
    time: [CardTimeType.Short, CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.RESILIENCE_BUILDERS],
    action: [CardActionType.Action],
    level: []
}
