import { View, StyleSheet } from "react-native";
import { DefaultText } from "../../common/default-text";


interface Props {
    textdata: string
}

export const DefaultTextWithoutArrowsItallic = ({ textdata }: Props) => {

    return (
        <View style={styles.content}>
            <DefaultText style={styles.text}>
                {textdata}
            </DefaultText>
        </View>
    )

}



const styles = StyleSheet.create({

    icon: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 5,
    },
    content: {
        paddingVertical: 10,
    },
    text: {
        textAlign: 'center',
        fontStyle: 'italic',
        fontSize: 16
    }
})

