import { StyleSheet, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import VideoCard from '../../common/video-card'
import { DecidaColors } from '../../../common/decida-colors'
import { M11GraphImage } from './src/m11-graph-image'
import { Worksheets } from '../../common/worksheets-imports'


export const SwitchesLessonPlansM11UnderstandingChangeAndAdaptability: CardTemplate = {
    cardId: "95c032f1-7d51-4bca-9147-0b0882c3088d",
    title: 'Understanding change and adaptability',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the stages of the Change Cycle, recognise their own responses to change, and develop strategies for navigating through each stage.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>• Duration 45 minutes </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Icebreaker Activity - "Change bingo" (5 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Distribute the change bingo card and give the students time to reflect on their experiences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Ask them to mark the box if they have experienced that situation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- If they get 5 in a row, they call out “bingo”.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Debrief to highlight that we all go through change at multiple times throughout our lives, and often in parallel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share their thoughts on how they typically respond to change.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>Mark the box if this change has happened to you in your lifetime:  </DefaultText>
                        <View style={styles.table}>
                            <DefaultText style={styles.boxContainer}>Changed schools</DefaultText>
                            <DefaultText style={styles.boxContainer}>Gained or lost a close friend</DefaultText>
                            <DefaultText style={styles.boxContainer}>Lost a pet </DefaultText>
                            <DefaultText style={styles.boxContainer}>Failed an important exam </DefaultText>
                            <DefaultText style={styles.boxContainer}>Won an award</DefaultText>
                            <DefaultText style={styles.boxContainer}>Someone new moved into your home</DefaultText>
                            <DefaultText style={styles.boxContainer}>Had a major injury or illness</DefaultText>
                            <DefaultText style={styles.boxContainer}>Got your heart broken</DefaultText>
                            <DefaultText style={styles.boxContainer}>Failed to get or lost a job</DefaultText>
                            <DefaultText style={styles.boxContainer}>Had a family holiday get cancelled</DefaultText>
                            <DefaultText style={styles.boxContainer}>Expected to wear a different uniform</DefaultText>
                            <DefaultText style={styles.boxContainer}>Experienced bullying</DefaultText>
                            <DefaultText style={styles.boxContainer}>Outgrew your favorite shoes</DefaultText>
                            <DefaultText style={styles.boxContainer}>Parents have split up or started new relationships</DefaultText>
                            <DefaultText style={styles.boxContainer}>Arrival of a younger sibling</DefaultText>
                            <DefaultText style={styles.boxContainer}>Moved to a new city/town</DefaultText>
                            <DefaultText style={styles.boxContainer}>Have been involved in a car accident</DefaultText>
                            <DefaultText style={styles.boxContainer}>Experienced a natural disaster, such as a flood</DefaultText>
                            <DefaultText style={styles.boxContainer}>Lost a significant sports competition</DefaultText>
                            <DefaultText style={styles.boxContainer}>Didn’t get into the class or group you wanted</DefaultText>
                            <DefaultText style={styles.boxContainer}>Didn’t get picked for the team you wanted to be in</DefaultText>
                            <DefaultText style={styles.boxContainer}>Body changed as a result of puberty</DefaultText>
                            <DefaultText style={styles.boxContainer}>Increased pressure to do well at school</DefaultText>
                            <DefaultText style={styles.boxContainer}>The family rules changed</DefaultText>
                            <DefaultText style={styles.boxContainer}>Your public transport route to school changed or canceled</DefaultText>

                        </View>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding the Change Cycle (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present the Change Cycle. Explain each stage: Shock and Denial, Anger and Resistance, Exploration, and Commitment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Shock and Denial: This is the initial reaction to the news or realisation that a significant change is happening. Individuals may feel a sense of disbelief and may deny the reality of the situation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Shame and Self-Blame:  As the change becomes more apparent, individuals may start to internalise the situation. They might feel a sense of shame or self-blame, wondering if they could have done something differently to prevent or mitigate the change.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Anger and Bargaining (Resistance Spike):  This stage is marked by the emergence of resistance. Individuals may express frustration, anger, or resentment towards the change. Bargaining behaviours might also appear as people seek ways to avoid or delay the change.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Depression, Loneliness, and Isolation: As the reality of the change sets in, individuals may experience a sense of loss. This can lead to feelings of depression, loneliness, and isolation as they grapple with the impact of the change on their lives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Turning the Corner: This is a critical point where individuals start to come to terms with the change. They begin to let go of the past and recognise that the change is inevitable. It marks the beginning of the journey toward acceptance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Reconstructing the Future: In this stage, individuals start to actively engage with the new reality. They may explore new possibilities, adapt their mindset, and begin to envision how the future could look in the changed circumstances.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Acceptance and Hope: As individuals fully embrace the change, they enter a stage of acceptance. They develop a positive outlook and a sense of hope for the future. They begin to see the benefits and opportunities that the change brings.</DefaultText>


                        <View style={CommonStyles.lessonPlanImageContainer}>
                            <M11GraphImage />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In small groups, ask them to discuss and identify examples of changes they've experienced that align with each stage of the Change Cycle.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Activity: Change Cycle Reflection (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Individual Reflection (5 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Distribute/refer to the workbook that provides a copy the Change Cycle diagram to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Instruct students to take a few minutes to reflect on a recent personal change they've encountered. Ask them to consider which stage of the Change Cycle they believe they are currently in.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Pair Share (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Pair students up and have them share their reflections with a partner. Encourage them to discuss their feelings and reactions to the change, as well as any challenges or positive aspects they've identified.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• OPTIONAL TIME PERMITTING - Debrief and Discussion (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Whole-Class Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Open the floor for a whole-class discussion. Ask students to share insights from their reflections and partner discussions. Discuss common themes and variations in responses to change.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Guided Questions (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Use guided questions to deepen the discussion:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ How do you think recognising the stages of the Change Cycle can help you navigate through a change?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ What strategies can be effective in moving from one stage to the next?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Can you think of a time when someone helped you navigate through a change? What did they do?</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Individual Goal Setting (5 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Ask each student to set a personal goal related to navigating through change. Encourage them to think about a specific change they anticipate and plan how they can approach it positively.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Homework (Optional):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Assign students to journal about their experiences with change over the next week, noting the stage of the Change Cycle they identify with and any strategies they employ.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Mastering the mind workbook, including section M11: Understanding Change and Adaptability'} link={Worksheets.masteryMasteringTheMindLevel7.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Change bingo'} link={"https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Change+Bingo.pdf"} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Slides'} link={"https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/M11+Change+Cycle.pptx"} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Two ways you can reduce change fatigue -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.switch4schools.com.au/post/two-ways-you-can-reduce-change-fatigue' link='https://www.switch4schools.com.au/post/two-ways-you-can-reduce-change-fatigue' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Understanding the Kubler-Ross Change Curve-</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.cleverism.com/understanding-kubler-ross-change-curve/' link='https://www.cleverism.com/understanding-kubler-ross-change-curve/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>On Death and Dying by Elisabeth Kubler-Ross -</DefaultText>
                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}
const styles = StyleSheet.create({
    table: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        borderWidth: 0.5,
        borderColor: DecidaColors.Gray,
    },
    boxContainer: {
        width: '20%',
        borderWidth: 0.5,
        borderColor: DecidaColors.Gray,
        padding: 5,
        fontSize: 15,
    },
})