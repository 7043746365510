import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconScared3Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 3)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(2 / 3)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(3 / 3)
    }

    return (
        <Svg viewBox="0 0 514.817 351.346">
            <Path d="M0,0,203.142-134.319" transform="translate(1.93 338.437)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.391,6.085l9.849,14.895Z" transform="translate(216.946 196.266)" fill="#657170" />
            <Path d="M0,0,124.892-82.342" transform="translate(230.054 186.813)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.4,6.052-15.57,20.96Z" transform="translate(366.831 96.634)" fill="#657170" />
            <Path d="M0,0,122.927-82.274" transform="translate(377.771 90.192)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.357,6.227l9.933,14.84L0,0Z" transform="translate(512.528)" fill="#657170" />
            <G {...onPressLevel1} transform="translate(-667.53 -540)">
                <Path d="M0,0,209.631-138.917,208.957-.867Z" transform="translate(675.126 889.484)" fill="#f2ddc4" />
                <Text transform="translate(745.475 882.346)" fill="#42454a" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Hesitant</TSpan></Text>
            </G>
            <G {...onPressLevel3} transform="translate(-667.53 -540)">
                <Path d="M0,0,138.5-91.134l.11,235.556-139.277.111Z" transform="translate(894.858 743.901)" fill="#e1b47e" />
                <Text transform="translate(908.858 825.42)" fill="#42454a" fontSize="20" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Threatened</TSpan></Text>
            </G>
            <G {...onPressLevel5} transform="translate(-667.53 -540)">
                <Path d="M0,0,138.1-92.058,137.7,243.255l-137.713.112Z" transform="translate(1043.749 645.567)" fill="#d69850" />
                <Text transform="translate(1060.249 768.495)" fill="#42454a" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Terrified</TSpan></Text>
            </G>
        </Svg>
    )
}