import { useReactiveVar } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { StyleSheet } from 'react-native'
import { DefaultText } from '../common/default-text'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { avatars } from '../icon/avatar/avatars'
import { rvUserGroup } from '../login/login-state'
import { SchoolAdminClassNavigatorParamsList, SchoolAdminNavigationParams } from '../school-admin/school-admin-navigation-params'
import { TeacherAvatarPicker } from './teacher-avatar-picker'
import { TeacherCreateStudentBreadcrumbs } from './teacher-create-student-breadcrumbs'
import { TeacherClassNavigatorParamList } from './teacher-route-param-types'
import { UserGroup } from '../../common/constants'
import { rvIsSchoolWithCampus } from '../school-admin/school-admin-state'

type TeacherCreateStudentPickAvatarStackScreenProps = StackScreenProps<TeacherClassNavigatorParamList & SchoolAdminClassNavigatorParamsList & SchoolAdminNavigationParams, "TeacherCreateStudentPickAvatar" | "SchoolAdminCreateStudentPickAvatar" | "SchoolAdminHome">

export const TeacherCreateStudentPickAvatar = ({ route: { params }, navigation: { navigate } }: TeacherCreateStudentPickAvatarStackScreenProps) => {
    const userGroup = useReactiveVar(rvUserGroup)
    const { classID } = params ?? {}
    const schoolAdminWithCampus = useReactiveVar(rvIsSchoolWithCampus)
    const isSchoolAdmin = userGroup === UserGroup.SchoolAdmin

    const navigateToClassAdmin = () => {
        if (isSchoolAdmin && schoolAdminWithCampus) {
            navigate(ScreenNames.SchoolAdminHome)
            return
        }
        navigate(ScreenNames.TeacherAddStudent, { classID: classID || "" })
    }

    const navigateToCreateStudentNickname = (avatarName: keyof typeof avatars) => {
        if (isSchoolAdmin) {
            if (schoolAdminWithCampus) {
                navigate(ScreenNames.SchoolAdminClassMain, { screen: ScreenNames.SchoolAdminCreateStudentNickname, params: { avatarName, classID } })
                return
            }

            navigate(ScreenNames.SchoolAdminCreateStudentNickname, { avatarName, classID })
        } else {
            navigate(ScreenNames.TeacherCreateStudentNickname, { avatarName, classID: classID || "" })
        }
    }

    return (
        <Page style={styles.page}>
            <TeacherCreateStudentBreadcrumbs back={navigateToClassAdmin} />
            <DefaultText style={styles.title}>Choose an avatar</DefaultText>
            <TeacherAvatarPicker onPress={navigateToCreateStudentNickname} />
        </Page>
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    title: {
        fontSize: 30,
        padding: 20,
        alignSelf: 'center',
    },
})
