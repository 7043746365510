import { createStackNavigator } from '@react-navigation/stack'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'

import { RootView } from '../common/root-view'
import { ScreenNames } from '../common/screen-names'
import { UserEditAvatar } from '../common/user-edit-avatar'
import { ChangePasswordProfile } from '../common/change-password-profile'
import { StudentProfile } from './student-profile'
import { ForgotPasswordRequest } from '../login/forgot-password-request'
import { ForgotPasswordSubmit } from '../login/forgot-password-submit'

const Stack = createStackNavigator<CheckInNavigatorParamList>()

export const StudentProfileMain = () => {

    return (
        <RootView>
            <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={ScreenNames.StudentProfile}>
                <Stack.Screen name={ScreenNames.StudentProfile} component={StudentProfile} options={{ title: 'Profile' }} />
                <Stack.Screen name={ScreenNames.ChangePasswordProfile} component={ChangePasswordProfile} />
                <Stack.Screen name={ScreenNames.LoginForgotPasswordRequest} component={ForgotPasswordRequest} />
                <Stack.Screen name={ScreenNames.LoginForgotPasswordSubmit} component={ForgotPasswordSubmit} />
                <Stack.Screen name={ScreenNames.UserEditAvatar} component={UserEditAvatar} />
            </Stack.Navigator>
        </RootView>
    )
}
