import { useReactiveVar } from '@apollo/client'
import { Auth } from '@aws-amplify/auth'
import { StackScreenProps } from '@react-navigation/stack'
import { useState } from 'react'
import { StyleSheet, TextInput, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { CommonStyles } from '../common/const'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { Page } from '../common/page'
import { rvBulkSignUpUrl } from '../login/login-state'
import { PasswordErrorText } from '../login/password-error-text'
import { PasswordInput } from '../login/password-input'
import { StudentJoinClassStackNavigatorParamList } from './login-route-param-types'
import { handleAuth } from './login-functions'
import { openUrlWebView } from '../web-view/open-url-web-view'

const wrongUserError = 'You must login as the teacher who locked this device.'

export const StudentJoinClassLogin = ({ route: { params: { bulkSignUpId } }, navigation: { navigate } }: StackScreenProps<StudentJoinClassStackNavigatorParamList, 'studentJoinClassLogin'>,) => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loggingIn, setLoggingIn] = useState(false)
    const [loginError, setLoginError] = useState('')
    const url = useReactiveVar(rvBulkSignUpUrl)


    const login = () => {
        if (username === '') {
            setLoginError('Username cannot be empty')
        } else if (password === '') {
            setLoginError('Password cannot be empty')
        } else {
            setLoggingIn(true)
            setLoginError('')

            Auth.signIn(username, password)
                .then(auth => {

                    openUrlWebView(url || '');
                    handleAuth(auth)
                })
                .catch(e => {
                    if (typeof e === 'string' && e === wrongUserError) {
                        setLoginError(e)
                    } else {
                        setLoginError('Incorrect username and/or password.')
                    }
                    setLoggingIn(false)
                })
        }
    }

    if (!bulkSignUpId) {
        return null
    }


    return (
        <Page style={styles.page}>

            <DefaultText>
                Please enter your login details.
            </DefaultText>
            <View style={styles.inputContainer}>
                <TextInput
                    style={CommonStyles.textInput}
                    placeholder='Username'
                    autoCorrect={false}
                    autoComplete='username'
                    autoCapitalize='none'
                    keyboardType='email-address'
                    textContentType='username'
                    onChangeText={setUsername}
                />
                <PasswordInput onPasswordChange={setPassword} onSubmit={login} />
            </View>
            <DefaultButton style={styles.loginButton} buttonTextColor={DecidaColors.White} onPress={login} disabled={loggingIn}>Submit</DefaultButton>
            <PasswordErrorText>{loginError}</PasswordErrorText>

        </Page>
    )
}


const styles = StyleSheet.create({
    keyboardAvoidingView: {
        flex: 1,
    },
    page: {
        backgroundColor: DecidaColors.White,
    },
    inputContainer: {
        flexDirection: 'column',
        width: 350,
        alignItems: 'center'
    },
    loginButton: {
        backgroundColor: DecidaColors.Green,
    },
    forgotPasswordContainer: {
        width: 350,
        alignItems: 'flex-end',
        paddingRight: 9,
    },
    forgotPasswordText: {
        color: DecidaColors.DarkBlue,
        height: 30,
    },
    logostyle: {
        backgroundColor: DecidaColors.Black,
        borderRadius: 12,
        padding: 20,
        width: "95%"
    },
    mainContainer: {

    }
})
