import { gql, useLazyQuery } from '@apollo/client';
import { Entypo } from '@expo/vector-icons';
import { useState } from "react";
import { ActivityIndicator, ScrollView, StyleSheet, TextInput, View } from "react-native";
import { SearchStudentByEmailQueryVariables } from '../../common/API';
import { UserGroup } from '../../common/constants';
import { DecidaColors } from "../../common/decida-colors";
import { DefaultButton } from '../common/default-button';
import { DefaultText } from "../common/default-text";
import { ImpersonateButton } from '../common/impersonate-button';
import { TakeMeThereButton } from '../common/take-me-there-button';
import { SearchStudentByEmailQuery, searchStudentByEmail } from '../custom-graphql/queries/search-student-by-email';
import { envBackgroundColor } from '../env';

export const AdminSearchStudent = () => {
  const [searchResult, setSearchResult] = useState<SearchStudentByEmailQuery['searchStudentByEmail']>([])
  const [keyword, setKeyword] = useState('')
  const [startSearchStudent, { loading }] = useLazyQuery<SearchStudentByEmailQuery, SearchStudentByEmailQueryVariables>(gql`${searchStudentByEmail}`,)

  const handleSearchStudent = async () => {
    const result = await startSearchStudent({
      variables: {
        input: {
          email: keyword
        }
      }
    })
    setSearchResult(result.data?.searchStudentByEmail || [])
  }

  return (

    <View style={styles.studentScrollContainer}>
      <View style={styles.searchContainer}>
        <TextInput
          style={styles.searchInput}
          placeholder="Search students.."
          value={keyword}
          onChangeText={setKeyword}
        />
        <DefaultButton style={styles.searchButton} onPress={() => handleSearchStudent()}>Search</DefaultButton>
      </View>
      <>
        {loading && <ActivityIndicator color={envBackgroundColor} size="large" />}
        {keyword.length <= 0 ?
          <View style={styles.noResults}>
            <Entypo name="air" size={50} color={DecidaColors.AppleSystemGray2Dark} />
            <DefaultText>Start typing to see results...</DefaultText>
            <DefaultText>(only top 10 results shown)</DefaultText>
          </View>
          : (
            <ScrollView style={styles.studentScroll} contentContainerStyle={styles.interalTeacherScroll}>
              {searchResult?.map((student) => {

                if (!student) {
                  return null
                }

                return (
                  <View style={styles.studentRowContainer} key={student.id}>
                    <View style={styles.rowContainer}>
                      <DefaultText>Name: </DefaultText>
                      <DefaultText style={styles.subText}>{`${student.firstName} ${student.lastName}`}</DefaultText>
                    </View>
                    <View style={styles.rowContainer}>
                      <DefaultText>Email: </DefaultText>
                      <DefaultText style={styles.subText}>{student.email}</DefaultText>
                    </View>
                    <View style={styles.rowContainer}>
                      <DefaultText>Cognito Username: </DefaultText>
                      <DefaultText style={styles.subText}>{student.cognitoUsername}</DefaultText>
                    </View>
                    <View style={styles.rowContainer}>
                      <DefaultText>School: </DefaultText>
                      <DefaultText style={styles.subText}>{student?.school?.name || ""}</DefaultText>
                    </View>
                    <DefaultText>Classes: </DefaultText>
                    <View>
                      {student?.classes?.items.length ? student?.classes?.items.filter(c => c && c._deleted !== true && c.class && c.class.archived !== true).map((studentClass) => {
                        return <View key={studentClass?.class?.id} style={{ flexDirection: 'row', gap: 20, alignItems: 'center' }} >
                          <DefaultText style={styles.listSubText}>• {studentClass?.class?.name}</DefaultText>
                          <TakeMeThereButton schoolID={student?.school?.id || ""} classID={studentClass?.class?.id || ""} userGroup={UserGroup.Student} />
                        </View>
                      }) : (
                        <DefaultText>-</DefaultText>
                      )}
                    </View>
                    <ImpersonateButton cognitoUsername={student.cognitoUsername} userGroup={UserGroup.Student} email={student.email} />
                  </View>
                )
              })}
            </ScrollView>
          )
        }
      </>

    </View>

  );
};

const styles = StyleSheet.create({
  studentScrollContainer: {
    flex: 1,
    backgroundColor: DecidaColors.AppleSystemGray6Light,
    borderRadius: 8,
    height: '100%',
    margin: 20,
    paddingVertical: 10
  },
  searchContainer: {
    marginHorizontal: 15,
    marginBottom: 15,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  studentRowContainer: {
    position: 'relative',
    backgroundColor: DecidaColors.AppleSystemGray5Light,
    borderRadius: 10,
    marginBottom: 10,
    padding: 15,

  },
  searchInput: {
    flex: 1,
    padding: 20,
    backgroundColor: DecidaColors.AppleSystemGray5Light,
    borderRadius: 8,
    height: 50
  },
  studentScroll: {
    backgroundColor: DecidaColors.AppleSystemGray6Light,
    padding: 15,
    borderRadius: 8,
  },
  noResults: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  plusIconContainer: {
  },
  nameColumn: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  interalTeacherScroll: {
    flex: 1,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  subText: {
    fontSize: 16,
  },
  listSubText: {
    fontSize: 16,
    marginLeft: 10,
    marginVertical: 5
  },
  searchButton: {
    marginLeft: 5,
    maxWidth: 100,
  },
  loadMorebutton: {
    alignSelf: 'center'
  }
});
