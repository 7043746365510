import { useReactiveVar } from '@apollo/client'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { Dimensions, ScrollView, StyleSheet, View, ViewStyle } from 'react-native'
import { CheckInUserGroup } from '../../common/constants'
import { DecidaColors } from "../../common/decida-colors"
import { rvCurrentClass } from '../common/common-state'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { showOfflineAlert } from '../common/universal-alert'
import useConnectivity from '../common/use-connectivity'
import { lowRes, useResponsive } from '../common/use-responsive'
import { IconCheckIn } from '../icon/icon-check-in'
import { IconSwitch } from '../icon/icon-switch'
import { useNonTeachingStaffState } from '../student/use-non-teaching-staff-state'
import { rvTecherCurrentStudentStudentCheckIn } from '../teacher/teacher-state'
import { NonTeachingStaffNavigationParams } from './non-teaching-staff-navigator-params-list'
import NonTeachingStaffProfileCard from './non-teaching-staff-profile-card'

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600

export const NonTeachingStaffHome = () => {
    const { navigate } = useNavigation<NavigationProp<NonTeachingStaffNavigationParams>>()
    const { currentNonTeachingStaff } = useNonTeachingStaffState()
    const currentClass = useReactiveVar(rvCurrentClass)
    const currentStudentCheckIn = useReactiveVar(rvTecherCurrentStudentStudentCheckIn)

    const { scaleRatio } = useResponsive()
    const transformByRatioStyle: ViewStyle = { transform: [{ scale: lowRes ? scaleRatio : 1 }] }
    const { onlineRef } = useConnectivity()

    const navigateToTirednessScale = () => {
        navigate(ScreenNames.NonTeachingStaffCheckInMain, { screen: ScreenNames.CheckInTirednessScale, params: { user: CheckInUserGroup.NonTeachingStaff, } })
    }

    const navigateToSwitchesHome = () => {
        navigate(ScreenNames.SwitchesHomeMain)
    }

    const onPressCheckIn = async () => {
        try {
            if (!onlineRef.current) throw new Error("You're offline")
            navigateToTirednessScale()
        } catch (error) {
            showOfflineAlert(onPressCheckIn)
        }
    }

    if (!currentNonTeachingStaff) {
        return null
    }
    return (
        <Page style={styles.page}>
            <>
                <ScrollView style={[styles.scrollView, { position: 'relative' }]} contentContainerStyle={[styles.mainContent, { position: 'relative' }]}>
                    <NonTeachingStaffProfileCard />
                    <View style={styles.buttonContainer}>
                        <DefaultButton disabledStyle={styles.buttonDisabled} testID='button-student-checkin' style={[styles.button, transformByRatioStyle, { marginVertical: lowRes ? 0 : undefined }]} onPress={onPressCheckIn}>
                            <View>
                                <View style={styles.checkInButton}>
                                    <IconCheckIn stylefill={DecidaColors.Green} />
                                    <DefaultText style={styles.buttonText}>{currentStudentCheckIn && currentClass?.goMode !== true && 'Update' || 'Start'} Check In</DefaultText>
                                </View>
                            </View>
                        </DefaultButton>
                        <DefaultButton style={[styles.buttonFill, transformByRatioStyle, { marginVertical: lowRes ? 0 : undefined }]} onPress={navigateToSwitchesHome}>
                            <IconSwitch height={60} width={60} />
                            <DefaultText style={styles.whiteText}>Explore switches</DefaultText>
                        </DefaultButton>

                    </View>
                </ScrollView>
            </>

        </Page>
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
    },
    button: {
        height: 80,
        borderRadius: 20,
        borderWidth: 2,
        backgroundColor: DecidaColors.White,
        borderColor: DecidaColors.Green,
        paddingLeft: 40,
        paddingRight: 40,
        margin: 10,

    } as ViewStyle,
    buttonDisabled: {
        height: 80,
        borderRadius: 20,
        borderWidth: 2,
        backgroundColor: DecidaColors.AppleSystemGray6SuperLight,
        borderColor: DecidaColors.Gray,
        margin: 10,

    } as ViewStyle,
    buttonFill: {
        height: 80,
        borderRadius: 20,
        borderWidth: 2,
        backgroundColor: DecidaColors.Green,
        borderColor: DecidaColors.Green,
        margin: 10,
        marginBottom: 40

    } as ViewStyle,
    buttonText: {
        fontSize: 24,
        color: DecidaColors.Gray,
        paddingLeft: 10,
    },
    mainContent: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20
    },
    scrollView: {
        width: '100%'
    },
    whiteText: {
        fontSize: 24,
        color: DecidaColors.White
    },
    buttonContainer: {
        alignItems: 'center',
        maxWidth: 330
    },
    checkInButton: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row'
    }
})