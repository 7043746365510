import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { StyleSheet, View } from 'react-native';
import { LoginStackNavigatorParamList } from '../login/login-route-param-types';
import { DefaultText } from './default-text';
import { ScreenNames } from './screen-names';
import { DecidaColors } from '../../common/decida-colors';
const ButtonTextForgotPassword = () => {
    const { navigate } = useNavigation<StackNavigationProp<LoginStackNavigatorParamList>>()

    const navigateToForgotPassword = () => {
        navigate(ScreenNames.LoginForgotPasswordRequest);
    };

    return (
        <View style={styles.forgotPasswordContainer}>
            <DefaultText
                onPress={navigateToForgotPassword}
                style={styles.forgotPasswordText}
            >
                Forgot Password?
            </DefaultText>
        </View>
    )
}
export default ButtonTextForgotPassword
const styles = StyleSheet.create({
    forgotPasswordContainer: {
        width: 350,
        alignItems: "flex-end",
        paddingRight: 9,
    },
    forgotPasswordText: {
        color: DecidaColors.DarkBlue,
        height: 30,
    },
})