import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { ThreeSketchesHeader } from './src/three-sketches-header'
import { ThreeSketchesIcon } from './src/three-sketches-icon'
import { WeatherItImage1 } from './src/weather-it-image1'
import { CommonStyles } from '../../../common/const'
import SwitchCardVideo from '../../../common/switch-card-video'

export const SwitchesEmotionsExcitedThreeSketches: CardTemplate = {
    cardId: "e1f6d617-c3b8-4d49-8935-5eb71c978b02",
    title: 'Three sketches',
    Header: ThreeSketchesHeader,
    Icon: ThreeSketchesIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Practicing focused attention and noticing detail. "} />
        </>
    ),
    Images: () => (
        <>
            <WeatherItImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Find a quiet comfortable seat. "} />
                <DefaulttextWithRightArrow textdata={"Look around where you are and find three simple objects e.g. a book, a tissue box, and a hat. "} />
                <DefaulttextWithRightArrow textdata={"List these three things down and have a go at drawing each of them as they are. "} />
                <DefaulttextWithRightArrow textdata={"Once you have drawn each of these objects give them a face, it can be anywhere on the object and make sure that on each of your objects the face is showing a different emotion. "} />
                <DefaulttextWithRightArrow textdata={"Be creative, give your characters life and use different colours and textures to bring them to life. "} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: 'Drawing activates various regions in the brain, including those associated with creativity, emotion, and the processing of visual information. When an individual draws, they are engaging in a task that requires focus and concentration, which can help to distract them from the source of their excitement.  ',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand how creativity can be used as a tool to calm excitement and manage emotions. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 60 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by discussing the importance of observation and creativity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the Three Sketches Switch and explain its purpose: to observe and draw three simple objects, then add faces to express different emotions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Observation and Drawing (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to find a quiet, comfortable seat</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to look around and choose three simple objects (e.g., a book, a tissue box, and a hat).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students list these three objects on their drawing paper or sketchbook.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- Set a timer for approximately 5 minutes for each sketch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- Instruct students to draw each object as realistically as possible.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Adding Faces and Emotions (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After drawing the objects, guide students to add faces to each one.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage creativity by allowing the faces to be placed anywhere on the object.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to express different emotions on each face (e.g., happy, sad, surprised).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the use of colours and textures to bring the characters to life.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Sharing and Reflection (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give students an opportunity to share their sketches with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to explain the emotions portrayed on each face and any creative choices made.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a brief discussion on the experience, focusing on the connection between observation, creativity, and emotional expression.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points of the lesson, highlighting the importance of creativity and expression.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to continue exploring the Three Sketches Switch as a tool for artistic expression.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind them that art is a unique way to convey emotions and ideas.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Art supplies e.g. paper, pencils, markers, paint </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Basic drawing lessons for beginners How to draw object drawing easy for beginners with BASIC SHAPES</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=FhVEJsZ1Sxk' />
                        <SwitchCardVideo video={{
                            id: '1',
                            url: 'https://www.youtube.com/embed/FhVEJsZ1Sxk'
                        }}/>

                        <DefaultText style={CommonStyles.lessonPlanText}>Sage Neuroscience – How art can help with stress and anger </DefaultText>
                        <DefaultTextWithLink text='https://sageclinic.org/blog/art-relieve-stress/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>More creative art tools to help reduce anger</DefaultText>
                        <DefaultTextWithLink text='https://www.creativecounseling101.com/art-therapy-anger-management-technique-crinkle.html' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Anger Management for Kids: 14 Best Activities & Worksheets</DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/anger-management-kids/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Doodling to Control Your Anger </DefaultText>
                        <DefaultTextWithLink text='https://uxbugs.wordpress.com/2013/09/18/doodling-to-control-your-anger/' />

                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Three+Sketches+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Excited]
}

const styles = StyleSheet.create({
})
