import { StyleSheet } from "react-native";
import { DefaultText } from "../../common/default-text";
import { openUrlWebView } from "../../web-view/open-url-web-view";

interface Props {
  text: string;
  fontSize?: number
}

const DefaultTextWithLink = ({ text, fontSize = 14 }: Props) => {

  return (
    <DefaultText
      style={[styles.textStyle, { fontSize }]}
      onPress={() => openUrlWebView(text)}
    >
      {text}
    </DefaultText>
  );
};

const styles = StyleSheet.create({
  textStyle: {
    textDecorationLine: "underline",
    color: "blue",
    paddingLeft: 40
  },
});

export default DefaultTextWithLink;
