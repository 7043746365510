import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { SafeSketchIcon } from '../../explore/src/safe-sketch-icon'
import { SafeSketchIconInverted } from '../../explore/src/safe-sketch-icon-inverted'
import { ValuesImage1 } from '../happy/src/values-image1'
import { SafeSketchImage1Lesson } from './src/safe-sketch-image-1'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'

export const SwitchesEmotionsAnxiousPinwheel: CardTemplate = {
    cardId: "aebfba28-e817-4140-9f28-c686b2fa3377",
    title: 'Safe sketch',
    Header: SafeSketchIconInverted,
    Icon: SafeSketchIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Refocussing and calming your mind when anxiety feels overwhelming or is making you nervous."} />
        </>
    ),
    Images: () => (
        <>
            <View style={{ height: '100%', width: '75%' }}>
                <ValuesImage1 />

            </View>
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Find a blank piece of paper and a pen or pencil."} />
                <DefaulttextWithRightArrow textdata={"Think of a place where you feel safe and secure, or a memory that brings you great joy."} />
                <DefaulttextWithRightArrow textdata={"Start sketching a picture that represents that place or experience. Don’t think about it too much, just start drawing."} />
                <DefaulttextWithRightArrow textdata={"Pick one object in that picture and spend a bit of time detailing it, or highlighting it in some way."} />

            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: 'Research shows that people who create art experienced significantly greater reductions in negative mood and anxiety, and better deal with trauma. First observed by famous psychologist Jung (who was a colleague of Sigmund Freud), creative artwork effectively engages the areas of the brain that are utilised when we are fearful or anxious, and safe sketching helps relink positive thought with memories (and the feelings associated with those memories). ',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice using a creative outlet for managing stress.
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify a positive thought that can be used as an anchor during periods of overwhelm.
                        </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 35 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin with a brief discussion about feelings and emotions. Ask students how they feel in different situations and if they ever experience anxiety or stress.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of a "special place" as a personal space that brings comfort and calmness.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Guided Visualisation (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Guide students through a short visualisation exercise. Encourage them to close their eyes, take deep breaths, and imagine a place where they feel completely at ease. Describe sensory details such as colours, sounds, and scents to enhance the visualisation.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Drawing Our Special Places (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the My Special Place worksheet or drawing paper and art supplies to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to draw their special place based on the guided visualisation. Encourage creativity and personal expression.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that there are no right or wrong ways to depict their special place; it's a reflection of their feelings and imagination.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Sharing and Articulating (7 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their drawings with the class. Ask volunteers to explain why their special place is meaningful to them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the common elements that make these places special, emphasising the positive emotions associated with them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Connect the idea that thinking about or visualising their special place can serve as a "negative thought interrupter" when feeling anxious.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 4: Reflection and Discussion (3 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a brief discussion on the importance of having a special place and how it can contribute to managing anxiety.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the idea that simply thinking about their special place and recalling the positive feelings associated with it can act as a tool to interrupt negative thoughts and promote relaxation.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points discussed during the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to keep their drawings in a safe place and use them as a resource when needed. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students that the simple act of thinking about their special place can be a helpful tool for managing anxiety. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own device, ask them to find the switch in the Switch4Schools app. </DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='My Special Place worksheet' link={Worksheets.mySpecialPlace.uri} /> </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>How to Use Drawing as a Coping Tool for Anxiety-</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.healthline.com/health/mental-health/anxiety-drawing' link='https://www.healthline.com/health/mental-health/anxiety-drawing' />

                        <DefaultText style={CommonStyles.lessonPlanText}>8 Ways How Drawing And Painting Can Reduce Stress -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.parblo.com/blogs/guides/8-ways-how-drawing-and-painting-can-reduce-stress' link='https://www.parblo.com/blogs/guides/8-ways-how-drawing-and-painting-can-reduce-stress' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Handy Hint"} />
            <DefaultTextWithoutArrowsItallic textdata={"It can be a good idea to keep a book of sketches. This will act as a kind of diary and help attach positive feelings to memories and situations."} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Safe+Sketch.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Anxious]
}

