import { Activities } from '../../common/activities'
import { ActivityGroupTemplate } from '../../common/activity-group-template'
import { NumberAndWordGamesIcon } from '../icons/number-and-word-games-icon'

export const SwitchesActivityGroupNumberAndWordGames: ActivityGroupTemplate = {
    title: 'Number and word games',
    Icon: NumberAndWordGamesIcon,
    activity: Activities.NUMBER_AND_WORD_GAMES,
    subtitle: 'Brain games that help build numeracy and language skills, and stretch your brain muscles'
}
