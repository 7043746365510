import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import DefaultTextWithCustomLink from '../../../common/default-text-with-custom-link'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { TheGreatShowdownIcon } from '../../icons/the-great-showdown-icon'
import { TheGreatShowdownIconInverted } from '../../icons/the-great-showdown-icon-inverted'

export const SwitchesActivitiesFunAndPositiveTheGreatShowdown: CardTemplate = {
    cardId: "47615b67-8255-480c-86a2-a636d4f80a99",
    title: 'The great showdown',
    Header: TheGreatShowdownIconInverted,
    Icon: TheGreatShowdownIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"What is it?"} />
            <DefaultTextWithoutArrowsItallic textdata={"The great showdown is a talent show run by the students for the students."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Encourage students to participate in the talent competition either individually or as a group. They can choose any act they prefer, such as magic, singing, dancing, drama, or juggling. "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Choose a specific date and time to host the talent show. This will be the event where participants will showcase their talents."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Select one student to be the host of the talent show. The host's role is to welcome each act on and off the stage and announce the winner once all the results have been collected. "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"To determine the winner, have the students and teachers, who are not participating as acts, anonymously write their vote for the winner on a piece of paper. This ensures fairness and impartiality in the voting process. Refer to the additional resources for a template. "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The winners will win the pre-determined prize! "} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Variations:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"If many students decide they would like to participate, then get each student who is performing to vote as well."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"If there are no students willing to be the host, then the teacher can host."} />

        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.CREATIVITY,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Additional Resources:"} />
            <DefaultTextWithCustomLink text='The great showdown voting cut outs.' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/The+great+showdown+cut-outs.pdf' />
        </>

    ),
    audioUrl: "",
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.FUN_AND_POSITIVE],
    action: [CardActionType.Action],
    level: []

}

