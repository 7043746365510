import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { AntDesign } from '@expo/vector-icons'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables, Teacher, UpdateTeacherMutation } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { updateTeacher } from '../../common/graphql/mutations'
import { getTeacherByCognitoUsername } from '../../common/graphql/queries'
import DefaultModal from '../common/default-modal'
import { ScreenNames } from '../common/screen-names'
import StepperDot from '../common/stepper-dot'
import { rvCurrentUser } from '../login/login-state'
import { TeacherOnBoardingStep1, TeacherOnBoardingStep2, TeacherOnBoardingStep3, TeacherOnBoardingStep4, TeacherOnBoardingStep5, TeacherOnBoardingStep6, TeacherOnBoardingStep7, TeacherOnBoardingStep8 } from './teacher-onboarding-steps'

const TeacherModalOnboarding = () => {
    const [stepIndex, setStepIndex] = useState(0)
    const totalSteps = 8
    const lastStep = stepIndex + 1 === totalSteps
    const user = useReactiveVar(rvCurrentUser)

  const { data: teacherResponse } = useQuery<GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables>(gql`${getTeacherByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
  const currentTeacher = teacherResponse?.getTeacherByCognitoUsername?.items[0] as Teacher
    const [updateTeacherMutation] = useMutation<UpdateTeacherMutation>(gql`${updateTeacher}`)
    const { navigate } = useNavigation<NavigationProp<any>>()

    const handleNext = async () => {
        if (lastStep) {
            await setTeacherOnboardingCompleteStatus()
            return
        }

        if (stepIndex >= 0) {
            setStepIndex(prev => prev + 1)
        }
    }

    const renderContent = () => {
        switch (stepIndex) {
            case 0:
                return <TeacherOnBoardingStep1 handleNext={handleNext} />
            case 1:
                return <TeacherOnBoardingStep2 handleNext={handleNext} />
            case 2:
                return <TeacherOnBoardingStep3 handleNext={handleNext} />
            case 3:
                return <TeacherOnBoardingStep4 handleNext={handleNext} />
            case 4:
                return <TeacherOnBoardingStep5 handleNext={handleNext} />
            case 5:
                return <TeacherOnBoardingStep6 handleNext={handleNext} />
            case 6:
                return <TeacherOnBoardingStep7 handleNext={handleNext} />
            case 7:
                return <TeacherOnBoardingStep8 handleNext={handleNext} />

            default:
                break;
        }
    }

    const setTeacherOnboardingCompleteStatus = async () => {
        const { data } = await updateTeacherMutation({
            variables: {
                input: {
                    id: currentTeacher?.id,
                    onboarded: true,
                    _version: currentTeacher?._version
                }
            }
        })
        if (data && data?.updateTeacher) {
            navigate(ScreenNames.TeacherHome)
        }
    }

    const handlePressDot = (index: number) => {
        setStepIndex(index)
    }

    return (
        <View>
            <DefaultModal>
                <View style={styles.modalHeaderContainer}>
                    <View />
                    <StepperDot total={totalSteps} currentStepIndex={stepIndex} onPressDot={handlePressDot} />
                    <TouchableOpacity style={styles.closeIcon} onPress={() => setTeacherOnboardingCompleteStatus()}>
                        <AntDesign name="close" size={28} color={DecidaColors.Gray} />
                    </TouchableOpacity>
                </View>
                <View>
                    {renderContent()}
                </View>
            </DefaultModal>
        </View >
    )
}
export default TeacherModalOnboarding

const styles = StyleSheet.create({
    modalHeaderContainer: {
        alignItems: 'center',
        paddingBottom: 10,
        justifyContent: 'space-between',
        borderBottomColor: DecidaColors.Gray
    },
    closeIcon: {
        position: 'absolute',
        right: 10,
        paddingHorizontal: 10,
    },
})