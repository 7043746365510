import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { FaceItIcon } from '../../explore/src/face-it-icon'
import { FaceItIconInverted } from '../../explore/src/face-it-icon-inverted'
import { FaceItImage1 } from './src/face-it-image1'
import { FaceItLessonPlanImage1 } from './src/face-it-lesson-plan-image1'
import { FaceItLessonPlanImage2 } from './src/face-it-lesson-plan-image2'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'


export const SwitchesEmotionsScaredFaceIt: CardTemplate = {
    cardId: "1af3c4de-212d-4351-937d-94958c1c37b7",
    title: 'Face it',
    lessonPlan: true,
    Header: FaceItIconInverted,
    Icon: FaceItIcon,
    Images: () => (
        <>
            <FaceItImage1 />
        </>

    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Times where the fear of something (particularly a future event) is causing people to panic."} />
        </>
    ),
    CardContent: () => {


        return (
            <>
                <View style={commonCardStyles.container}>
                    <DefaultTextWithDownArrow textdata={"Think about something that is scary."} />
                    <DefaultTextWithoutArrows textdata={"Allow yourself to sit with your fear for a couple of minutes at a time.  Consider:"} />
                    <DefaulttextWithRightArrow textdata={"What makes it scary?"} />
                    <DefaulttextWithRightArrow textdata={"Did something happen in the past that made it scary?"} />
                    <DefaulttextWithRightArrow textdata={"What does being brave look and feel like? "} />
                    <DefaultTextWithDownArrow textdata={"What are three things you can do to be braver in the face of the thing that scares you?"} />
                    <DefaultTextWithoutArrows textdata={"Now imagine yourself doing some of those braver things - what does that look and feel like?"} />


                </View>
            </>
        )
    },
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: 'Clinical experience shows that it is important to validate people’s fears to reduce the impact those fears have on behaviour. Fear often grows in the shadows, shining a light on the fear, not being ashamed of it, and then thinking of ways to be braver are all proven steps to overcoming fear.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Explore the concept of fear, identify what makes something scary, reflect on past experiences, and develop strategies to be braver with an emphasis on self-kindness as an essential coping tool for fear.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by discussing the concept of fear with students. Define fear as a natural emotion that alerts us to danger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the Face It Switch and explain its purpose in helping individuals face their fears.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of acknowledging fear rather than avoiding it.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Reflecting on Fear (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the My Fears worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think about something that scares them and fill out the worksheet, considering what makes it scary and whether past experiences contribute to the fear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them consider how fear feels in the body and complete the worksheet. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: What Does Being Brave Look Like? (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the question: "What does being brave look and feel like?"</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on their own experiences and perceptions of bravery.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to share their thoughts with the class.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Developing Strategies for Bravery (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Guide students through brainstorming three things they can do to be braver in the face of their fear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss and share ideas within small groups or pairs.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that these strategies should be achievable and gradual steps toward overcoming fear.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 4: Imagining Bravery (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to imagine themselves doing some of those braver things. What does it look and feel like?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to visualise the positive outcomes of facing their fears.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students to be kind to themselves as they confront fear. Discuss the importance of self kindness as a coping tool.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students are using the Switch4Schools app independently, direct students to explore the Face It Switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude by reinforcing the idea that being their own best friend is crucial in facing fears.</DefaultText>




                    </>}
                materialEquipmentContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='My Fears worksheet' link={Worksheets.myFears.uri} /></DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>How to Find and Practice Courage -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://hbr.org/2020/05/how-to-find-and-practice-courage' link='https://hbr.org/2020/05/how-to-find-and-practice-courage' />

                        <DefaultText style={CommonStyles.lessonPlanText}>What fear can teach us -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.ted.com/talks/karen_thompson_walker_what_fear_can_teach_us?referrer=playlist-how_to_overcome_your_fears&autoplay=true' link='https://www.ted.com/talks/karen_thompson_walker_what_fear_can_teach_us?referrer=playlist-how_to_overcome_your_fears&autoplay=true' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Fears and Phobias -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://kidshealth.org/en/teens/phobias.html' link='https://kidshealth.org/en/teens/phobias.html' />

                        <DefaultText style={CommonStyles.lessonPlanText}>8 Deep Breathing Exercises for Anxiety -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.verywellmind.com/abdominal-breathing-2584115' link='https://www.verywellmind.com/abdominal-breathing-2584115' />

                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Remember to be kind to yourself.  That is the best coping tool for fear that there is.  Be your OWN best friend. ' />
            <DefaulTextWithTipsIcon textdata='Some things that people are commonly scared of include:' />

            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Being alone"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"The dark"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Dogs or other big animals"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Swooping, flapping birds"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Bugs"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Heights"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Getting a needle from the doctor"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Unfamiliar or loud noises"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Speaking in front of people."} />


        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Face+It.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Scared]
}
