import gql from "graphql-tag";
import { StudentClassApprovalStatus } from "../../../models";
import { RequestForChatStatus, SchoolStatus, TeacherRole } from "../../../../common/API";
import { schoolFields } from "./school-admin-get-school-query";

const campusFields = `
  id
  name
  schoolID
  __typename
  _version
  _deleted
  _lastChangedAt
`

const classGroupsFields = `
  id
  name
  schoolID
  campusID
  __typename
  _version
  _deleted
  _lastChangedAt
`

export const getSchoolCampusesAndGroupsQuery = /* GraphQL */ gql`
query GetSchool($id: ID!) {
  getSchool(id: $id) {
    ${schoolFields}
    campuses {
      items {
          ${campusFields}
          classGroups {
            items {
              ${classGroupsFields}
            }
          }
      }
    }
  }
}
`;

export type GetSchoolCampusesAndGroupsQueryResponse = {
  getSchool: {
    id: string,
    name: string,
    campuses: {
      items: GetSchoolQueryResponseCampus[],
      nextToken?: string | null,
      startedAt?: number | null,
    },
    classGroups: {
      items: GetSchoolQueryResponseClassGroups[],
      nextToken?: string | null,
      startedAt?: number | null,
    },
    licenseTotal?: string | null,
    timezone?: string | null,
    status?: SchoolStatus | null,
    activeStudents?: number | null,
    trialEndDate?: string | null,
    renewalDate?: string | null,
    notes?: string | null,
    coordinatorID?: string | null,
    billingAddress?: string | null,
    billingNotes?: string | null,
    invoiceUrl?: string | null,
    principalName?: string | null,
    schoolAdminUI?: string | null,
    abn?: string | null,
    wholeSchoolSubscription?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  }
}

export type GetSchoolQueryResponseTeacherClass = {
  id: string,
  classID: string,
  teacherID: string,
  _version: number,
  _deleted?: boolean | null,
}

export type GetSchoolQueryResponseStudentClass = {
  id: string,
  studentID: string,
  classID: string,
  requestForChatStatus: RequestForChatStatus,
  status: StudentClassApprovalStatus | null,
  _deleted?: boolean | null,
}

export type GetSchoolQueryResponseClass = {
  id: string,
  name: string,
  schoolID?: string | null,
  emotionIntensityLevel?: number | null,
  toggleRequestForChat?: boolean | null,
  toggleRequestForChatNotifications?: boolean | null,
  toggleIndividualStudentStats?: boolean | null,
  bulkSignUpId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  primaryContact?: string | null,
  archived?: boolean | null,
  emailReceivers?: Array<string | null> | null,
  goMode?: boolean,
  checkInSessions?: {
    items: Array<{
      id: string,
      updatedAt?: string | null,
      classID?: string | null,
      isActive: boolean,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    }>,
  },
  students: {
    items: GetSchoolQueryResponseStudentClass[],
  },
  teachers: {
    items: GetSchoolQueryResponseTeacherClass[],
  },
  studentCheckIns: {
    items: {
      id: string,
      createdAt?: string | null,
    }[],
    nextToken?: string | null,
    startedAt?: number | null,
  },
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  classClassLoginId?: string | null,
}

export type GetSchoolQueryResponseTeacher = {
  id: string,
  email: string,
  schoolID?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  cognitoUsername: string,
  showIntroduction?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  role?: TeacherRole | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
}

export type GetSchoolQueryResponseCampus = {
  id: string,
  schoolID?: string | null,
  name?: string | null,
  classGroups: {
    items: GetSchoolQueryResponseClassGroups[]
  }
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
}

export type GetSchoolQueryResponseClassGroups = {
  id: string,
  schoolID?: string | null,
  campusID?: string | null,
  name?: string | null,
  classes?: {
    items: GetSchoolQueryResponseClass[]
  }
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
}