import { View } from 'react-native'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import { Worksheets } from '../../common/worksheets-imports'


export const SwitchesLessonPlansM4UnderstandingAnxiety: CardTemplate = {
    cardId: "db77ed26-7802-4b3f-90ff-8d2b672f8317",
    title: 'Understanding anxiety: Cultivating EI',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define anxiety and its impact on emotional wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Develop awareness of personal anxiety triggers and coping strategies.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Enhance emotional intelligence skills by fostering self regulation and empathy when dealing with anxiety.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>• Duration 45 minutes </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by engaging the students in a brief discussion about emotions and the importance of emotional regulation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Transition to the topic of anxiety and its impact on individuals' wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that the focus of the lesson is to enhance self awareness and develop strategies for managing anxiety effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present the level 7 emotion wheel with an emphasis on the relevant quadrant.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to or distribute the Understanding Anxiety workbook to each student.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Defining Anxiety (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to define anxiety in their own words and write their responses on the whiteboard or flipchart.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a class discussion to refine the definition, highlighting the physiological, emotional, and cognitive aspects of anxiety.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce common symptoms of anxiety, such as increased heart rate, racing thoughts, and feelings of fear or apprehension.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Anxiety Triggers (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the concept of anxiety triggers and how they vary among individuals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Anxiety Triggers worksheets /Workbook with a list of common anxiety triggers.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to identify and provide a score for the triggers that resonate with them personally.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allow time for individual reflection and encourage students to make notes about their own triggers and potential patterns they notice.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Emotional Regulation Strategies (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce various strategies for managing anxiety and enhancing emotional regulation skills, such as deep breathing exercises, mindfulness, positive self talk, and seeking support from trusted individuals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students access the Switch4Schools program on their device and encourage them to explore the available switches assigned to angry on the emotion wheel to bookmark activities they’d like to try.  Alternatively provide a copy of the level 7 quick reference guide. This could also be a homework activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the benefits of some of the techniques selected by the group and when they might be most helpful.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion and Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points discussed during the lesson, emphasising the importance of self awareness and developing effective strategies for managing anxiety.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allow students to briefly reflect on their personal takeaways from the lesson and any questions or thoughts they may have.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide additional resources, such as websites or books, for students who wish to explore the topic further.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In addition to checking in via the Switch program, encourage students to make use of the space in their workbook to journal their thoughts and feelings over the coming week to better understand their triggers and improve their coping strategies.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Anxiety triggers'} link={Worksheets.m4AnxietyTriggers.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Mastering the mind workbook, including section M4 Understanding Anxiety: Cultivating Emotional Intelligence'} link={Worksheets.masteryMasteringTheMindLevel7.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Can Anxiety Be a Blessing?-</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.psychologytoday.com/au/blog/thriving anxiety/202202/can anxiety be blessing' link='https://www.psychologytoday.com/au/blog/thriving anxiety/202202/can anxiety be blessing' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Signs of an anxiety disorder-</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.nhs.uk/mental-health/feelings-symptoms-behaviours/feelings-and-symptoms/anxiety-disorder-signs/#:~:text=Generalised%20anxiety%20disorder%20(GAD)&text=your%20worrying%20is%20uncontrollable%20and,concerns%2C%20such%20as%20household%20chores' link='https://www.nhs.uk/mental-health/feelings-symptoms-behaviours/feelings-and-symptoms/anxiety-disorder-signs/#:~:text=Generalised%20anxiety%20disorder%20(GAD)&text=your%20worrying%20is%20uncontrollable%20and,concerns%2C%20such%20as%20household%20chores' />
                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}