import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { IntroductionToExcitedLessonPlan1 } from './src/introduction-to-excited-lesson-plan1'
import { Videos } from '../../models'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

const exploringExcitedVideo1: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/0SUTInEaQ3Q",
    title: ""
}

export const SwitchesLessonPlansIntroductionToExcited: CardTemplate = {
    cardId: "44fe232a-02d1-4722-9063-ec9b357af867",
    title: 'Exploring excited',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define and identify the physical and emotional signs of excitement.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn ways to express excitement in healthy and appropriate ways.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Log into the Switch4Schools program and select an appropriate meme of the day to share, for example:</DefaultText>
                        <View style={styles.imageContainer}>
                            <IntroductionToExcitedLessonPlan1 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think of a time when they felt excited.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students discuss what they think excited means with the person sitting next to them. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the physical and emotional signs of excitement (e.g. racing heart, butterflies in the stomach, smiling, laughing, feeling energised).</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (20 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Little Book of Big Emotion. Define excitement as:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ A positive and energising emotion that we feel in response to something enjoyable or rewarding. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ It is a series of emotions that usually result from something in the future that amuses or interests you. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Excitement is related to happiness, but happiness is directed toward something happening in the present, whereas excitement is all about the anticipation of a future event.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ For example, Sarah was so excited about going on the excursion because she really wanted to go to the zoo.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students if excitement is good or bad.  You may like to put responses onto the board into two columns, examples of things to look for: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Good – Creates energy and motivation, can be used to reframe fear and anxiety</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Bad – Can be hard to concentrate if over excited</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students that emotions are not good or bad.  They just are.  Our super power is learning to dial up and down the intensity of excitement (and other emotions) to suit the context. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Activity – How Excited Would You Be? Explain that one side of the room is not excited at all, the middle of the room is somewhat excited, and the opposite side of the room is very excited.  Ask students to choose a spot in the room to represent how excited the following activities would make them feel:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Going to the beach</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Waking up on your birthday</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Getting ready for a maths exam</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Visiting a dinosaur museum</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Baking a cake</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Riding on a rollercoaster you’ve never been on before</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Little Book of Big Emotion to explain that there are words to describe the different levels of excitement. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide the students into small groups of 3-4.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have each group discuss ways to create or increase excitement and then ways to calm down or decrease excitement. Have the students write their ideas on sticky notes. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Collect the notes from the students and group them on the board to make a list of excitement levers.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to write down a list of appropriate ways to show excitement in the classroom.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students refer to the excitement levers on the board and choose one or two strategies that they would like to try to manage excitement. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Advise students that you will be exploring different switches over the coming weeks to practice when feeling over excited.  If time permits and/or if student’s have access to the Switch4Schools program on their device encourage them to explore the available switches assigned to angry on the emotion wheel and add activities they’d like to try to the worksheet. Could also be a homework activity.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share one thing that they learned about excitement during the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Review the importance of experiencing and expressing excitement, and the strategies that were discussed for expressing excitement in healthy and appropriate ways.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Introducing Excited Slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/P7+Exploring+Excited.pdf' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pens/pencils</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Index cards/Sticky notes</DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Four faces of overstimulation -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://autismspectrumnews.org/four-faces-of-overstimulation/' link='https://autismspectrumnews.org/four-faces-of-overstimulation/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Nervous vs excitement -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='Nervous vs. Excited' link='https://www.youtube.com/watch?v=0SUTInEaQ3Q' />

                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={exploringExcitedVideo1} />
                        </View>


                        <DefaultText style={CommonStyles.lessonPlanText}>Using excitement and arousal to persuade and influence -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.psychologytoday.com/intl/blog/persuasion-bias-and-choice/202209/using-arousal-and-excitement-persuade-and-influence' link='https://www.psychologytoday.com/intl/blog/persuasion-bias-and-choice/202209/using-arousal-and-excitement-persuade-and-influence' />

                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 10
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        flexDirection: 'row',
        marginHorizontal: 20,
        justifyContent: 'center',
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        marginVertical: 10,
    },
    noIndent: {
        paddingLeft: 0,
    },
})
