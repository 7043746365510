import { useEffect, useState } from "react";
import { useFonts } from "expo-font";
import { API, Amplify, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import { StatusBar, Text } from "react-native";
import { WithLogin } from "./login/with-login";
import { DefaultTheme, LinkingOptions, NavigationContainer } from "@react-navigation/native";
import { GroupSwitch } from "./common/group-switch";
import { TeacherMain } from "./teacher/teacher-main";
import { DefaultBackgroundColor, LAST_ACCESSED_VERSION_KEY } from "./common/const";
import { AdminMain } from "./admin/admin-main";
import { UniversalAlert } from "./common/universal-alert";
import { Loading } from "./common/loading";
import { StudentMain } from "./student/student-main";
import { StudentPopOverMenu } from "./student/student-pop-over-menu";
import { envWebUrl, versionNumber } from "./env";
import { ApplicationOutOfDate } from "./common/application-out-of-date";
import { lessThanMinVersionRequired } from "./common/min-version-update-required";
import { ClassLoginMain } from "./class-login/class-login-main";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { logout } from "./common/logout";
import { CLIENT_EVENT, createClientLog } from "./common/log-client-event";
import { ApolloProvider, useReactiveVar } from "@apollo/client";
import { rvTeacherCurrentTeacher } from "./teacher/teacher-state";
import { useStudentState } from "./student/use-student-state";
import { apolloClient, rvUpdateAvailable } from "./common/common-state";
import { rvParentPortal } from "./login/login-state";
import { SchoolAdminMain } from "./school-admin/school-admin-main";
import { SchoolAdminPopOverMenu } from "./school-admin/school-admin-pop-over-menu";
import { MinFEVersion } from "../common/API";
import * as Linking from "expo-linking"
import { ScreenNames } from "./common/screen-names";
import { navigationRef } from "./common/root-navigation";
import { ClassLoginPopoverMenu } from "./class-login/class-login-pop-over-menu";
import './web-view/handle-native-messages'
import { webViewRequestNativeVariables } from "./web-view/web-view-messages";
import { NonTeachingStaffHome } from "./non-teaching-staff/non-teaching-staff-home";
import { NonTeachingStaffMain } from "./non-teaching-staff/non-teaching-staff-main";
import { TeacherPopoverMenu } from "./common/teacher-popover-menu";
import { NonTeachingStaffPopoverMenu } from "./non-teaching-staff/non-teaching-staff-pop-over-menu";
import { initializeGA, logPageView } from "./common/analytics";

Amplify.configure({
  ...awsconfig,
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS" || "API_KEY",
  API: {
    graphql_headers: async () => {
      const isGuest = rvParentPortal()
      return {
        // if parent portal (browse without login)
        // remove Authorization from header, to automatically use appsync API_KEY
        Authorization: !isGuest ? (await Auth.currentSession()).getIdToken().getJwtToken() : undefined
      }
    }
  },
  maxRecordsToSync: 5000,
})

export const AppWeb = () => {
  const [minVersion, setminVersion] = useState("");
  const [updateRequired, setUpdateRequired] = useState(false);
  const currentTeacher = useReactiveVar(rvTeacherCurrentTeacher)
  const { currentStudent } = useStudentState()

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(webViewRequestNativeVariables)
  }, [])

  useEffect(() => {
    initializeGA();
    logPageView();

    window.addEventListener('popstate', logPageView);
    return () => {
      window.removeEventListener('popstate', logPageView);
    };
  }, []);
  
  useEffect(() => {
    API.post("AdminQueries", "/graphQLfindfindMinFEVersion", {
      headers: {
        "content-type": "application/json",
      },
      body: {},
    }).then((result: MinFEVersion) => {
      setminVersion(result.version);
      const isUpdateRequired = lessThanMinVersionRequired(
        result.version,
        versionNumber
      );
      if (isUpdateRequired === true) {
        console.log('isUpdateRequired', isUpdateRequired, result.version, versionNumber)
        rvUpdateAvailable(true)
        setUpdateRequired(true);
      }
      AsyncStorage.getItem(LAST_ACCESSED_VERSION_KEY)
        .then(async lastAccessedVersion => {
          if (lastAccessedVersion !== undefined && lastAccessedVersion !== null && lastAccessedVersion !== versionNumber) {
            console.log('isUpdateRequired below', lastAccessedVersion, result.version, versionNumber)
            // rvUpdateAvailable(true)
            // logout()
            await apolloClient.clearStore()
            await AsyncStorage.clear()
            await AsyncStorage.setItem(LAST_ACCESSED_VERSION_KEY, versionNumber)
            createClientLog({
              event: CLIENT_EVENT.USER_SIGNED_OUT,
              area: "Main app",
              action: "Auto refresh trigerred, there's new updated version of the app",
              payload: {
                group: currentTeacher ? "teacher" : currentStudent ? "student" : "",
                nickname: currentTeacher?.cognitoUsername || currentStudent?.cognitoUsername,
              }
            })
            location.reload()
          }
        })
    });
  }, []);

  useEffect(() => {
    // disable font scaling
    (Text as any).defaultProps = (Text as any).defaultProps || {};
    (Text as any).defaultProps.allowFontScaling = false;
  }, []);

  const [loaded] = useFonts({
    HelveticaNeue: require("../assets/fonts/HelveticaNeue.ttf"),
  });

  const universal = Linking.createURL("/")
  const parentPortalLink = Linking.createURL("ParentMain/")

  const linkingConfig: LinkingOptions<{ [K: string]: any }> = {
    prefixes: [universal, parentPortalLink, envWebUrl],
    config: {
      screens: {
        JoinClass: {
          path: 'JoinClass',
          screens: {
            studentJoinClass: {
              path: `${ScreenNames.studentJoinClass}/:bulkSignUpId`,
              parse: ((bulkSignUpId: string) => bulkSignUpId)
            }
          }
        },
        Login: "Login",
        SwitchesMemes: {
          path: `${ScreenNames.SwitchesMemes}/:meme`,
          parse: {
            meme: ((meme: string) => meme)
          }
        },
        SwitchesEmotionsDetailView: {
          path: `${ScreenNames.SwitchesEmotionsDetailView}/:emotion`,
          parse: {
            emotion: ((emotion: string) => emotion)
          }
        },
        SwitchesActiviesDetailView: {
          path: `${ScreenNames.SwitchesActiviesDetailView}/:activity`,
          parse: {
            activity: ((act: string) => act)
          }
        },
        Card: ScreenNames.Card,
        NotFound: ScreenNames.NotFound,
        TeacherHome: ScreenNames.TeacherHome,
        LoginClassLogin: ScreenNames.LoginClassLogin,
        LoginStudentEditAvatar: ScreenNames.LoginStudentEditAvatar,
        TeacherClassMain: {
          path: 'TeacherClassMain',
          screens: {
            CheckInStart: {
              path: `${ScreenNames.CheckInStart}`,
              parse: {
                classID: ((classID: string) => classID)
              },
            },
            TeacherStudentIndividualStats: {
              path: ScreenNames.TeacherStudentIndividualStats
            },
            TeacherStats: {
              path: ScreenNames.TeacherStats
            },
            TeacherClassAdmin: {
              path: ScreenNames.TeacherClassAdmin
            }
          }
        }
      }
    },
  }

  if (!loaded) {
    return null;
  }

  return (
    <ApolloProvider client={apolloClient}>
      {updateRequired ? (
        <ApplicationOutOfDate minVersion={minVersion} />
      ) : (
        <NavigationContainer
          ref={navigationRef}
          theme={{
            ...DefaultTheme,
            colors: {
              ...DefaultTheme.colors,
              background: DefaultBackgroundColor,
            },
          }}
          linking={linkingConfig}
        >
          <StatusBar barStyle="dark-content" />
          <UniversalAlert />
          <WithLogin>
            <Loading />
            <GroupSwitch
              Teacher={<>
                <TeacherPopoverMenu />
                <TeacherMain />
              </>
              }
              Admin={<AdminMain />}
              Student={<>
                <StudentPopOverMenu />
                <StudentMain />
              </>
              }
              ClassLogin={<>
                <ClassLoginPopoverMenu />
                <ClassLoginMain />
              </>
              }
              SchoolAdmin={<>
                <SchoolAdminPopOverMenu />
                <SchoolAdminMain />
              </>
              }
              NonTeachingStaff={<>
                <NonTeachingStaffPopoverMenu />
                <NonTeachingStaffMain />
              </>
              }
            />
          </WithLogin>
        </NavigationContainer>
      )
      }
    </ApolloProvider >
  );
};
