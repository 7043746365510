import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { useResponsive } from '../../../common/use-responsive'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import { TheCalmerFarmerHeader } from './src/the-calmer-farmer-header'
import { TheCalmerFarmerIcon } from './src/the-calmer-farmer-icon'
import { TheCalmerFarmerImage1 } from './src/the-calmer-farmer-image1'
import { TheCalmerFarmerImage10 } from './src/the-calmer-farmer-image10'
import { TheCalmerFarmerImage11 } from './src/the-calmer-farmer-image11'
import { TheCalmerFarmerImage12 } from './src/the-calmer-farmer-image12'
import { TheCalmerFarmerImage13 } from './src/the-calmer-farmer-image13'
import { TheCalmerFarmerImage14 } from './src/the-calmer-farmer-image14'
import { TheCalmerFarmerImage2 } from './src/the-calmer-farmer-image2'
import { TheCalmerFarmerImage3 } from './src/the-calmer-farmer-image3'
import { TheCalmerFarmerImage4 } from './src/the-calmer-farmer-image4'
import { TheCalmerFarmerImage5 } from './src/the-calmer-farmer-image5'
import { TheCalmerFarmerImage6 } from './src/the-calmer-farmer-image6'
import { TheCalmerFarmerImage7 } from './src/the-calmer-farmer-image7'
import { TheCalmerFarmerImage8 } from './src/the-calmer-farmer-image8'
import { TheCalmerFarmerImage9 } from './src/the-calmer-farmer-image9'
import { CommonStyles } from '../../../common/const'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { Br } from '../../../common/br'

export const SwitchesEmotionsAnxiousTheCalmerFarmer: CardTemplate = {
    cardId: "5c500029-6aee-40a7-8ed8-35da1474a800",
    title: 'The calmer farmer',
    Header: TheCalmerFarmerHeader,
    Icon: TheCalmerFarmerIcon,
    lessonPlan: true,
    mainContainerStyle: {
        width: '100%',
    },
    PerfectFor: () => {

        const { isLargeScreen } = useResponsive()
        const calmerImages = [
            { Component: TheCalmerFarmerImage1, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/the-calmer-farmer-pg1.mp3' },
            { Component: TheCalmerFarmerImage2, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/the-calmer-farmer-pg2.mp3' },
            { Component: TheCalmerFarmerImage3, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/the-calmer-farmer-pg3.mp3' },
            { Component: TheCalmerFarmerImage4, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/the-calmer-farmer-pg4.mp3' },
            { Component: TheCalmerFarmerImage5, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/the-calmer-farmer-pg5.mp3' },
            { Component: TheCalmerFarmerImage6, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/the-calmer-farmer-pg6.mp3' },
            { Component: TheCalmerFarmerImage7, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/the-calmer-farmer-pg7.mp3' },
            { Component: TheCalmerFarmerImage8, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/the-calmer-farmer-pg8.mp3' },
            { Component: TheCalmerFarmerImage9, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/the-calmer-farmer-pg9.mp3' },
            { Component: TheCalmerFarmerImage10, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/the-calmer-farmer-pg10.mp3' },
            { Component: TheCalmerFarmerImage11, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/the-calmer-farmer-pg11.mp3' },
            { Component: TheCalmerFarmerImage12, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/the-calmer-farmer-pg12.mp3' },
            { Component: TheCalmerFarmerImage13, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/the-calmer-farmer-pg13.mp3' },
            { Component: TheCalmerFarmerImage14, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/the-calmer-farmer-pg14.mp3' },
        ]
        const contents = calmerImages.map(({ Component, audio }, index) => ({
            Component: () => (
                <View key={`${index}`} style={isLargeScreen ? styles.imageContainerScaled : styles.imageContainerMobile}>
                    <Component />
                </View>
            ),
            audio
        }))

        return (
            <>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata={"Helping to reframe the way you think about things making it easier to handle big feelings."} />
                <Br />

                <SwitchCardJuniorContent contents={contents} scheme={Emotion.ANXIOUS} />
            </>
        )
    },
    Images: undefined,
    CardContent: () => (
        <>
        </>
    )
    ,
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: "",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify and discuss the concept of perspective.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Discuss the story of the calm farmer and understand how the farmer’s perspective was different to the villagers.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by asking students if they have ever had a bad day.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share examples of things that might make a day feel bad or good.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that sometimes, we can change our perspective on things and it can help us feel better.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Instruction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Read “The calmer farmer” story to the students.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After reading the story, discuss the concept of perspective with the students. Ask them if they can identify times when the farmer’s perspective changed.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Either as a class or in small groups provide a situation to discuss, for example: .</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- It’s raining on your birthday</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- You have to go to the doctor</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- A friend wants to play with the same toy</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Someone sat in the chair you wanted</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Losing a game</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Being served a new food to try</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Making a mistake while writing</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Waiting in a long slow line</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Having to go to bed when you want to stay up later</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Being asked to do a chore you don’t like</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Saying goodbye to a friend</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Learning to do something for the first time</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to brainstorm different ways that they could change their perspective on the situation to make it feel better.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Share ideas with the class and encourage discussion.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Individually have students draw a picture of a time when they had a bad day and then draw a picture of how they could change their perspective to make the day feel better.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students share their pictures with the class and explain their thought process.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Review the concept of perspective with the students and remind them that sometimes, changing our perspective can help us feel better about difficult situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to try to find a different perspective when they are feeling upset or unhappy.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pens and paper</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• The Calm Farmer switch in Switch4Schools app</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Why the Zen Farmer is such a powerful story, and lessons to learn from it -</DefaultText>
                        <DefaultTextWithLink text='https://isabella-michaels.medium.com/good-news-bad-news-lessons-from-a-zen-buddhist-fable-33041ef4ccda' />

                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Anxious]
}

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20,
    },
    mobileContainer: { width: '100%' },
    webContainer: {
        flex: 1,
        flexDirection: 'column',
        paddingBottom: "10%",
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    },
    textContaier: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    subText: {
        fontWeight: 'bold'
    },
    urlText: {
        color: DecidaColors.Sad,
        textDecorationLine: 'underline',
        fontWeight: 'bold',
        fontStyle: 'italic'
    },
    contentText: {
        // color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    nextButton: {
        marginTop: 20,
        width: 200,
        backgroundColor: CardColorScheme.SAD
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    imageContainerMobile: {
        width: '100%',
        height: 300,
        marginVertical: 20,
        paddingHorizontal: 10,
    },
    imageContainerScaled: {
        width: '100%',
        height: 300,
        marginVertical: 20,
        transform: [{ scale: 1.2 }]
    },
    whydoesthiswork: {
        width: 100,
        height: 100,
    },
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanTextBoldItalic: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
        fontStyle: 'italic',
        fontWeight: 'bold'
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray
    },
    whydoesthisworktext: {
        padding: 10,
    },
    whydoesthisworkContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        marginHorizontal: 20
    },
    rowContainerHalf: {
        flex: 0.5,
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%'
    },
    lessonPlanCenteredTextContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center',
        marginVertical: 20,
        width: '90%'
    },
    underLineText: {
        textDecorationLine: 'underline',
        color: 'blue',
        paddingLeft: 40,
        fontSize: 20,
    },
    tipsIconContainer: {
        marginTop: 20
    },
    imageBackgroundCotainer: {
        position: 'absolute',
        width: '100%',
        height: 300,
        top: 200,
        margi: 0,
        transform: [{ scale: 2 }]
    }
})

