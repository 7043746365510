import { StyleSheet, View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import DefaultTextWithCustomLink from '../../../common/default-text-with-custom-link'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { GetSomeSunIcon } from '../../icons/get-some-sun-icon'
import { GetSomeSunIconInverted } from '../../icons/get-some-sun-icon-inverted'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaultText } from '../../../../common/default-text'

export const SwitchesActivitiesHealthyHabitsGetSomeSun: CardTemplate = {
    cardId: "eab2d520-d283-4a60-b808-3befea29ea09",
    title: 'Get some sun',
    Header: GetSomeSunIconInverted,
    Icon: GetSomeSunIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Boosting vitamin D levels, improving mood and mental wellbeing."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Find time to spend at least 4-6 hours total over the course of a week doing something you love outside. Here are some ideas:"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Play some co-curricular sport with friends"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Create a morning exercise routine"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Plan to go for an afternoon walk, run or ride"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Take a good book and sit outside to read "} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Do some gardening "} />

        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.HEALTHY_HABITS,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Additional Resources:"} />
            <DefaultText>For more inspiration on how to manage your outdoor time, visit <DefaultTextWithCustomLink indent={0} text='this article.' link='https://extension.usu.edu/mentalhealth/articles/simple-ways-to-spend-more-time-in-nature' /></DefaultText>
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={""} />
        </>

    ),
    audioUrl: "",
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Outdoor],
    category: [CardCategoryType.HEALTHY_HABITS],
    action: [CardActionType.Action],
    level: []

}

