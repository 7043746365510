import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconExcited3Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 3)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(2 / 3)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg viewBox="0 0 514.596 349.844">
            <Path d="M0,0,203.422-134.507" transform="translate(1.93 338.289)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.391,6.085l9.849,14.895Z" transform="translate(217.227 195.931)" fill="#657170" />
            <Path d="M0,0,125.3-83.056" transform="translate(229.448 187.556)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.384,6.114-15.518,21Z" transform="translate(366.611 96.635)" fill="#657170" />
            <Path d="M0,0,124.892-81.938" transform="translate(377.302 89.747)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.412,5.994l9.8,14.931L0,0Z" transform="translate(514.096)" fill="#657170" />
            <G {...onPressLevel1} transform="translate(-689.061 -22.078)">
                <Path d="M0,0,208.958-.868l.673-139.033Z" transform="translate(696.657 371.415)" fill="#c5e2dd" />
                <Text transform="translate(770.382 355.13)" fill="#42454a" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Expectant</TSpan></Text>
            </G>
            <G {...onPressLevel3} transform="translate(-689.061 -22.078)">
                <Path d="M0,0,138.5-91.2l-.171,235.7-139.277.11Z" transform="translate(916.67 225.751)" fill="#9fcfc7" />
                <Text transform="translate(929.584 300.903)" fill="#42454a" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Energetic</TSpan></Text>
            </G>
            <G {...onPressLevel5} transform="translate(-689.061 -22.078)">
                <Path d="M0,0,138.1-92.4l-.173,334.6L.211,242.313Z" transform="translate(1065.061 128.552)" fill="#79bbb1" />
                <Text transform="translate(1070.02 246.485)" fill="#42454a" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="30" y="0">Hyper</TSpan></Text>
            </G>
        </Svg>
    )
}