import { StyleSheet, View } from 'react-native'
import { TeacherAvatarPicker } from '../teacher/teacher-avatar-picker'
import { DefaultText } from '../common/default-text'
import { StudentJoinClassBreadCrumbs } from './student-join-class-breadcrumbs'
import { BackButton } from '../common/back-button'
import { StackScreenProps } from '@react-navigation/stack'
import { StudentJoinClassStackNavigatorParamList } from './login-route-param-types'
import { ScreenNames } from '../common/screen-names'
import { avatars } from '../icon/avatar/avatars'


export const StudentJoinClassSelectAvatar = ({ route: { params: { bulkSignUpId, className } }, navigation: { navigate } }: StackScreenProps<StudentJoinClassStackNavigatorParamList, 'studentJoinClassPickAvatar'>,) => {
    const goToStudentJoinClass = () => {
        navigate(ScreenNames.studentJoinClass, { bulkSignUpId })
    }

    const setAvatarNavigateStep2 = (avatarName: keyof typeof avatars) => {
        navigate(ScreenNames.studentJoinClassSetDetails, { bulkSignUpId: bulkSignUpId, avatarName: avatarName, className: className })
    }

    return (
        <>
            <View>
                <BackButton onPress={goToStudentJoinClass} />
                <DefaultText style={styles.title}>Choose an avatar</DefaultText>
            </View>

            <StudentJoinClassBreadCrumbs step={1} />
            <TeacherAvatarPicker onPress={setAvatarNavigateStep2} />
        </>
    )
}

const styles = StyleSheet.create({
    page: {
    },

    backButtonContainer: {
        flex: 1,
        padding: 5,
    },
    title: {
        fontSize: 30,
        padding: 20,
        alignSelf: 'center',
    },
})