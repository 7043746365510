import { RequestForChatStatus, StudentClassApprovalStatus, StudentStatus } from "../models";

const classFields = `
  id
  name
  schoolID
  emotionIntensityLevel
  toggleRequestForChat
  toggleRequestForChatNotifications
  toggleIndividualStudentStats
  bulkSignUpId
  createdAt
  updatedAt
  primaryContact
  archived
  emailReceivers
  lastCheckInSessionClassSequenceNo
  checkInCountYTD
  checkInCountRollingWeekMonday
  checkInCountRollingWeekTuesday
  checkInCountRollingWeekWednesday
  checkInCountRollingWeekThursday
  checkInCountRollingWeekFriday
  checkInCountRollingWeekSaturday
  checkInCountRollingWeekSunday
  activeStudents
  goMode
  _version
  _deleted
  _lastChangedAt
  classClassLoginId
  __typename
`

const studentClassFields = `
  id
  studentID
  classID
  status
  createdAt
  updatedAt
  requestForChat
  requestForChatStatus
  studentCheckIns
  _version
  _deleted
  _lastChangedAt
  __typename
`

const studentCheckInsFields = `
  __typename,
  id
  createdAt
  updatedAt
  studentID
  emotion
  emotionIntensity
  tiredness
  checkinsessionID
  classID
  absence
  notYet
  _version
  _deleted
  _lastChangedAt
`

export type StudentClassAndStudentDataFieldsTypes = {
  __typename: "StudentClass",
  id: string,
  studentID: string,
  classID: string,
  status?: StudentClassApprovalStatus | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  requestForChat?: boolean | null,
  requestForChatStatus?: RequestForChatStatus | null,
  studentCheckIns?: Array<string> | null,
  student: StudentFieldsTypes,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
} | null

const teacherClassFields = `
    id
    teacherID
    classID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
`

const checkinSessionsFields = `
  id
  createdAt
  updatedAt
  _version
  _deleted
  _lastChangedAt
  __typename
`

const studentFields = `
  id
  schoolStudentID
  nickname
  avatar
  schoolID
  yearLevel
  firstName
  lastName
  email
  status
  cognitoUsername
  createdAt
  updatedAt
  onboarded
  initialLoginSetup
  activeClasses
  studentCheckInCount
  studentCheckInSkipSuggestionDate
  _version
  _deleted
  _lastChangedAt
  __typename
`

type StudentFieldsTypes = {
  __typename: "Student",
  id: string,
  schoolStudentID?: string | null,
  nickname: string,
  avatar: string,
  schoolID: string,
  yearLevel?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  status?: StudentStatus | null,
  cognitoUsername: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  onboarded?: boolean | null,
  initialLoginSetup?: boolean | null,
  activeClasses?: number | null,
  studentCheckInCount?: number | null,
  studentCheckInSkipSuggestionDate?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
}

export const listTeacherClassesByTeacherIDForTeacherHome = /* GraphQL */ `query ListTeacherClassesByTeacherID(
    $teacherID: ID!
    $classID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeacherClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeacherClassesByTeacherID(
      teacherID: $teacherID
      classID: $classID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${teacherClassFields}
        class {
            ${classFields}
            checkInSessions {
              items {
                ${checkinSessionsFields}
              }
            }
        }
      }
      nextToken
      startedAt
      __typename
    }
  }
  `;

export type ListStudentClassAndStudentDataByClassIdCustomQuery = {
  getStudentClassByClassId?: {
    __typename: "ModelStudentClassConnection",
    items: Array<StudentClassAndStudentDataFieldsTypes>,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListStudentClassAndStudentDataByStudentIDCustomQuery = {
  getStudentClassByStudentId?: {
    __typename: "ModelStudentClassConnection",
    items: Array<StudentClassAndStudentDataFieldsTypes>,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export const listStudentClassAndStudentDataByClassIdCustomQuery = /* GraphQL */ `query GetStudentClassByClassId(
    $classID: ID!
    $studentID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentClassByClassId(
      classID: $classID
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${studentClassFields}
        student {
          ${studentFields}
        }
      }
      nextToken
      startedAt
      __typename
    }
  }
  `

export const listStudentClassAndStudentDataByStudentIDCustomQuery = /* GraphQL */ `query getStudentClassByStudentId(
    $studentID: ID!
    $classID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentClassByStudentId(
      studentID: $studentID
      classID: $classID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${studentClassFields}
        class {
          ${classFields}
        }
      }
      nextToken
      startedAt
      __typename
    }
  }
  `

export type ListTeacherClassesByTeacherIDQueryClassData = {
  __typename: "Class",
  id: string,
  name: string,
  schoolID: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  archived?: boolean | null,
  lastCheckInSessionClassSequenceNo?: number | null,
  activeStudents?: number | null,
  goMode?: boolean | null,
  checkInSessions: {
    items: Array<{
      __typename: "CheckInSession",
      id: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    }>
  }
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  classClassLoginId?: string | null,
} | null

export type ListTeacherClassesByTeacherIDForTeacherHomeQuery = {
  listTeacherClassesByTeacherID?: {
    __typename: "ModelTeacherClassConnection",
    items: Array<{
      __typename: "TeacherClass",
      id: string,
      teacherID: string,
      classID: string,
      class?: ListTeacherClassesByTeacherIDQueryClassData,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null>,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};