import { gql, useMutation } from '@apollo/client'
import { Ionicons } from '@expo/vector-icons'
import { useRef, useState } from 'react'
import { Platform, StyleSheet, TextInput, View } from 'react-native'
import { CustomCreateSchoolAdminMutation, CustomCreateSchoolAdminMutationVariables, CustomRemoveSchoolAdminMutation, CustomRemoveSchoolAdminMutationVariables, CustomUpdateSchoolAdminMutation, CustomUpdateSchoolAdminMutationVariables, School, SchoolAdmin } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultButton, DefaultButtonTypes } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { emailValidator } from '../common/email-validator'
import { InputCognitoUsername } from '../common/input-cognito-username'
import InputDefault from '../common/input-default'
import { rvIsLoading } from '../common/loading'
import ToastAlert, { ToastAlertType } from '../common/toast-alert'
import { customCreateSchoolAdmin, customRemoveSchoolAdmin, customUpdateSchoolAdmin } from '../../common/graphql/mutations'
import SchoolAdminButtonCancel from './school-admin-button-cancel'
import { showAlert } from '../common/universal-alert'


type Props = {
    toggleEditMode: () => void
    schoolAdministrator?: SchoolAdmin
    schoolData: School
}

const SchoolAdminFormAdministrator = ({
    schoolAdministrator,
    toggleEditMode,
    schoolData
}: Props) => {
    const [adminFirstName, setCoordinatorFirstName] = useState(schoolAdministrator?.firstName || "")
    const [adminLastName, setCoordinatorLastName] = useState(schoolAdministrator?.lastName || "")
    const [adminEmail, setCoordinatorEmail] = useState(schoolAdministrator?.email || "")
    const [adminUsername, setAdminUsername] = useState(schoolAdministrator?.cognitoUsername || "")
    const [userNameExists, setUsernameExists] = useState(false)
    const isEditMode = schoolAdministrator !== undefined

    const [formError, setFormError] = useState(false)

    const [toast, setToast] = useState({
        status: false,
        message: '',
        type: ToastAlertType.success
    })
    const firstNameInputRef = useRef<TextInput>(null)
    const lastNameInputRef = useRef<TextInput>(null)
    const emailInputRef = useRef<TextInput>(null)

    const [updateSchoolAdminMutation] = useMutation<CustomUpdateSchoolAdminMutation, CustomUpdateSchoolAdminMutationVariables>(gql`${customUpdateSchoolAdmin}`)
    const [createSchoolAdminMutation] = useMutation<CustomCreateSchoolAdminMutation, CustomCreateSchoolAdminMutationVariables>(gql`${customCreateSchoolAdmin}`)
    const [deleteSchoolAdminMutation] = useMutation<CustomRemoveSchoolAdminMutation, CustomRemoveSchoolAdminMutationVariables>(gql`${customRemoveSchoolAdmin}`)

    const handleSubmit = async () => {
        // resetToast()
        // // validations
        const emailValidation = emailValidator(adminEmail)

        if (!adminFirstName) {
            setToast({ status: true, message: "Please input coordinator first name", type: ToastAlertType.error })
            return
        }
        if (!adminLastName) {
            setToast({ status: true, message: "Please input coordinator first name", type: ToastAlertType.error })
            return
        }

        if (emailValidation === "Email is not valid.") {
            setToast({ status: true, message: "Please input valid email", type: ToastAlertType.error })
            return
        }

        if (!isFormCompleted()) {
            setToast({ status: true, message: "Please fill all required form", type: ToastAlertType.error })
            return
        }

        try {
            rvIsLoading(true)
            if (schoolAdministrator) {
                await updateExistingAdministrator()
            } else {
                await createNewAdministrator()

            }
            rvIsLoading(false)
            toggleEditMode()
        } catch (error) {
            rvIsLoading(false)
            console.log({ error })
            setToast({ status: true, message: "Something went wrong, submit failed", type: ToastAlertType.error })
        }
    }

    const updateExistingAdministrator = async () => {
        if (!schoolAdministrator) {
            return
        }

        await updateSchoolAdminMutation({
            variables: {
                input: {
                    id: schoolAdministrator.id || "",
                    firstName: adminFirstName,
                    lastName: adminLastName,
                    email: adminEmail,
                    preferredUsername: schoolAdministrator.preferredUsername || ""
                }
            }
        })
    }

    const createNewAdministrator = async () => {
        await createSchoolAdminMutation({
            variables: {
                input: {
                    email: adminEmail,
                    firstName: adminFirstName,
                    lastName: adminLastName,
                    cognitoUsername: adminUsername,
                    schoolID: schoolData.id,

                }
            }
        })
    }

    const removeExistingAdministrator = async () => {
        try {
            await deleteSchoolAdminMutation({
                variables: {
                    input: {
                        id: schoolAdministrator?.id || ""
                    }
                }
            })
            showAlert({
                title: "Remove administrator",
                message: "Successfully remove administrator",
                leftButtonText: "Ok"
            })
        } catch (error) {

        }

    }

    const isFormCompleted = () => {
        return adminFirstName &&
            adminLastName &&
            adminEmail &&
            !formError
    }

    const handleEmail = (text: string) => {
        setFormError(false)
        setCoordinatorEmail(text.toLowerCase())
    }

    const showRemoveAlert = () => {
        showAlert({
            title: "Remove administrator",
            message: "Are you sure you want to remove this accout",
            onRightButtonPress: removeExistingAdministrator,
            leftButtonText: "Cancel",
            rightButtonText: "Yes",
        })
    }

    return (
        <View style={styles.mainContainer}>

            <InputCognitoUsername value={adminUsername} onChangeText={setAdminUsername} userExists={userNameExists} setUserExists={setUsernameExists} editable={false} title='Username' schoolAdminStyle isCurrentValue={adminUsername === schoolAdministrator?.cognitoUsername} />
            <InputDefault ref={firstNameInputRef} value={adminFirstName} onChangeText={setCoordinatorFirstName} label="First name" placeholder='Administrator first name' />
            <InputDefault ref={lastNameInputRef} value={adminLastName} onChangeText={setCoordinatorLastName} label="Last name" placeholder='Administrator last name' />
            <InputDefault ref={emailInputRef} value={adminEmail} label="Email" onChangeText={handleEmail} placeholder='Administrator email' />

            <View style={styles.buttonContainer}>
                <SchoolAdminButtonCancel title={isEditMode ? 'Remove' : 'Cancel'} onPress={isEditMode ? showRemoveAlert : toggleEditMode} />
                <DefaultButton type={DefaultButtonTypes.Small} disabled={!isFormCompleted()} style={Platform.OS === 'web' ? styles.confirmButton : styles.confirmButtonIos} onPress={handleSubmit}>
                    <Ionicons name="ios-checkmark-sharp" size={24} color={DecidaColors.White} />
                    <DefaultText style={styles.confirmText}>
                        Confirm
                    </DefaultText>
                </DefaultButton>
            </View>
            <View style={{ maxWidth: '100%', position: 'absolute', bottom: -30 }}>
                {toast.status && (
                    <ToastAlert visible={toast.status} setVisible={(status: boolean) => setToast({ status, message: "", type: toast.type })} message={toast.message} type={toast.type} />
                )}
            </View>
        </View>
    )
}

export default SchoolAdminFormAdministrator

const styles = StyleSheet.create({
    inputCognitoUsername: {
        padding: 5,
        marginBottom: 10,
        borderRadius: 20,
        borderWidth: 1,
        borderColor: DecidaColors.AppleSystemGrayLight,
    },
    mainContainer: {
        zIndex: 99,
        marginTop: 10,
        borderBottomWidth: 1,
        paddingBottom: 10,
        width: '100%'
    },

    formContainer: {
        flex: 1,
        paddingHorizontal: 5,
    },
    confirmText: {
        fontSize: 12,
        fontWeight: '700',
        color: DecidaColors.White,
    },
    confirmButton: {
        minWidth: 40,
        alignSelf: 'flex-end',
        zIndex: -1
    },
    confirmButtonIos: {
        width: 60,
        alignSelf: 'flex-end',
        zIndex: -1
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
})