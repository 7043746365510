import { OnDataOptions, gql } from "@apollo/client";
import { OnMutateCampusBySchoolIDSubscription } from "../../common/API";
import { GetSchoolCampusesAndGroupsQueryResponse, getSchoolCampusesAndGroupsQuery } from "../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query";

export const updateListCampusInSchoolQuery = ({ schoolID }: { schoolID: string }) => ({ data, client }: OnDataOptions<OnMutateCampusBySchoolIDSubscription>) => {
    // Exit early if there's no valid incoming campus data
    if (!data.data?.onMutateCampusBySchoolID) return;

    // Extract incoming campus id
    const { id: incomingCampusId } = data.data.onMutateCampusBySchoolID;

    // Read the current school query response
    const schoolQueryResponse = client.readQuery<GetSchoolCampusesAndGroupsQueryResponse>({
        query: getSchoolCampusesAndGroupsQuery,
        variables: { id: schoolID }
    });

    // Exit early if the incoming campus already exists in the query response
    if (schoolQueryResponse?.getSchool?.campuses?.items.some(campus => campus?.id === incomingCampusId)) return;

    // Update the list of campuses: filter out the incoming campus if it exists and add it to the list
    const updatedCampuses = [
        ...(schoolQueryResponse?.getSchool?.campuses?.items || []).filter(campus => campus?.id !== incomingCampusId),
        data.data.onMutateCampusBySchoolID
    ];

    // Write the updated query response
    client.writeQuery({
        query: getSchoolCampusesAndGroupsQuery,
        variables: { id: schoolID || "" },
        data: {
            ...schoolQueryResponse,
            getSchool: {
                ...schoolQueryResponse?.getSchool,
                campuses: {
                    ...schoolQueryResponse?.getSchool.campuses,
                    items: updatedCampuses
                }
            }
        }
    });
};