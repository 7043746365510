import { gql, useMutation } from '@apollo/client';

import { Ionicons } from '@expo/vector-icons';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { useState } from 'react';
import { Dimensions, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Teacher, UpdateTeacherMutation, UpdateTeacherMutationVariables } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { updateTeacher } from '../../common/graphql/mutations';
import { IconSwitch4SchoolsEW } from '../icon/icon-switch-4-schools-ew';
import { TeacherDrawerNavigatorPamarList } from '../teacher/teacher-route-param-types';
import { rvTeacherIntroduction } from '../teacher/teacher-state';
import { DefaultButton } from './default-button';
import { DefaultText } from './default-text';
import { ScreenNames } from './screen-names';
import { TeacherIntroductionScreen1 } from './svg-files/teacher-introduction-screen1';
import { TeacherIntroductionScreen2 } from './svg-files/teacher-introduction-screen2';
import { TeacherIntroductionScreen3 } from './svg-files/teacher-introduction-screen3';
import { TeacherIntroductionScreen4 } from './svg-files/teacher-introduction-screen4';
import { TeacherIntroductionScreen5 } from './svg-files/teacher-introduction-screen5';

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600

type Props = {
    teacher?: Teacher
}
export const IndroductionTeacher = ({ teacher }: Props) => {

    if (!teacher) return null

    const { navigate } = useNavigation<DrawerNavigationProp<TeacherDrawerNavigatorPamarList>>()

    const [updateTeacherMutation] = useMutation<UpdateTeacherMutation, UpdateTeacherMutationVariables>(gql`${updateTeacher}`)

    const [introPage, setIntroPage] = useState(1)
    const [opacity, setOpacity] = useState(0.9)

    const nextPage = (page: number, opacity: number) => {
        setIntroPage(page)
        setOpacity(opacity)
    }
    const finished = (page: number, opacity: number) => {
        nextPage(1, 0.9)
        rvTeacherIntroduction(false)
        navigateToClassAdmin()

    }

    const navigateToClassAdmin = () => {
        navigate(ScreenNames.TeacherClassMain, { screen: ScreenNames.TeacherClassAdmin })
    }

    const setIntroFalse = async () => {
        if (teacher) {
            const { id, _version } = teacher

            await updateTeacherMutation({
                variables: {
                    input: {
                        id,
                        _version,
                        showIntroduction: false
                    }
                }
            })
        }
    }


    return (
        <View style={styles.page}>

            <View style={[styles.background, { opacity: opacity }]} />

            <View style={styles.content}>

                {introPage === 1 ?
                    <>
                        <DefaultText style={styles.title}>Welcome to Switch4Schools!</DefaultText>
                        <IconSwitch4SchoolsEW height={160} width={165} marginTop={20} marginBottom={20} />
                        <TouchableOpacity style={styles.row} onPress={() => nextPage(2, 0.9)}>
                            <DefaultText style={styles.subtitle}>Get Started</DefaultText>
                            <Ionicons name="arrow-forward-circle" size={50} color={DecidaColors.White} />
                        </TouchableOpacity>
                    </> :
                    <>
                    </>}
                {introPage === 2 ?
                    <>
                        <TouchableOpacity style={styles.screenSize} onPress={() => nextPage(3, 0.9)}>
                            <TeacherIntroductionScreen1 />
                        </TouchableOpacity>

                    </>
                    : <></>
                }

                {introPage === 3 ?
                    <>
                        <TouchableOpacity style={styles.screenSize} onPress={() => nextPage(4, 0.9)}>
                            <TeacherIntroductionScreen2 />
                        </TouchableOpacity>
                    </>
                    :
                    <></>
                }
                {introPage === 4 ?
                    <>
                        <TouchableOpacity style={styles.screenSize} onPress={() => nextPage(5, 0.9)}>
                            <TeacherIntroductionScreen3 />
                        </TouchableOpacity>
                    </>
                    :
                    <></>
                }
                {introPage === 5 ?
                    <>
                        <TouchableOpacity style={styles.screenSize} onPress={() => nextPage(6, 0.9)}>
                            <TeacherIntroductionScreen4 />
                        </TouchableOpacity>
                    </>
                    :
                    <></>
                }
                {introPage === 6 ?
                    <>
                        <TouchableOpacity style={styles.screenSize} onPress={() => finished(1, 0.9)}>
                            <TeacherIntroductionScreen5 />
                        </TouchableOpacity>
                    </>
                    :
                    <></>
                }
                <DefaultButton onPress={setIntroFalse}>Don't show introduction again.</DefaultButton>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    page: {
        position: 'absolute',
        zIndex: 99,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: DecidaColors.Gray,
        opacity: 0.9
    } as ViewStyle,
    title: {
        color: DecidaColors.White,
        fontWeight: 'bold',
        fontSize: 36,
    },
    content: {
        shadowColor: DecidaColors.Gray,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99,
        width: '100%',
        height: '100%',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    subtitle: {
        color: DecidaColors.White,
        fontWeight: 'bold',
        fontSize: 26,
        marginRight: 10,
        textAlign: 'center'
    },
    header: {
        alignItems: 'center',
        flexDirection: 'row',
        position: 'absolute',
        top: 100,
        right: 15,
    },
    switch: {
        alignItems: 'center',
        position: 'absolute',
        bottom: 65,
    },
    checkinSession: {
        alignItems: 'center',
        position: 'absolute',
        bottom: 100,
    },
    screenSize: {
        width: windowWidthcondition ? 800 : 500,
        height: windowWidthcondition ? 800 : 500,
    }

})





