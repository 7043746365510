import { StyleSheet } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

export const SwitchesLessonPlansUnderstandingEmotions: CardTemplate = {
    cardId: "3a1f27f0-83de-47aa-90aa-4dd41ff19ff0",
    title: 'Understanding emotions',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to identify and label basic emotions in themselves and others, and express their emotions effectively.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Warm-up (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by reviewing the emotions covered in the previous year, or introduce the Switch level 1 emotion wheel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share situations that made them feel each emotion.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Emotion Identification (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show slides of different emotions to the students.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to identify and label the emotions they see.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the physiological cues they don’t see attached to the emotions, eg butterflies in the stomach, or a racing heart.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss examples of situations that might cause each emotion.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Expressing Emotions (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students sit in a circle.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show the students the level 1 emotion wheel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give each child a turn to choose an emotion and act out the corresponding emotion without using words.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ The other students should guess the emotion being portrayed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the child to announce what emotion they were portraying by saying, “I feel …”</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss appropriate ways to express each emotion verbally, “I feel … because …” and have some volunteers provide examples.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Closure (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share one thing they learned about emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Highlight the importance of understanding and expressing emotions can help others understand how they feel and be better positioned to support them.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>Extension Activities:</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Practice checking in using the Switch4Schools app.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Role-play props (optional)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Lesson+Plan+F8-+Understanding+Emotions+(1).pptx' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Emotion Wheel Poster (1 point scale)' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Emotion+Wheel+Poster+1-level.pdf' /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>How to Express Your Feelings: 30+ Emotional Expression Tips -</DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/express-emotions/' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}
