import { API } from "aws-amplify";
import { useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { DefaultText } from "../common/default-text";
import { getApiHeaders } from "../common/get-api-header";
import { InputCognitoUsername } from "../common/input-cognito-username";
import { rvIsLoading } from "../common/loading";
import { PasswordInput } from "../login/password-input";
import { DecidaColors } from "../../common/decida-colors";

export const AdminResetPassword = () => {
  const [password, setPassword] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");
  const [userExists, setUserExists] = useState<boolean | null>(null);

  const handlePassword = (value: string) => {
    setPassword(value);
  };

  const handleResetPassword = async () => {
    rvIsLoading(true);
    try {
      await API.post("AdminQueries", "/resetPasswordAdmin", {
        body: { nickname, password },
        headers: await getApiHeaders(),
      });
    } catch (e) {
      console.log(e);
    }
    rvIsLoading(false);
  }
  return (
    <View style={styles.container}>
      <InputCognitoUsername
        userExists={userExists}
        setUserExists={setUserExists}
        value={nickname}
        onChangeText={setNickname}
        isAdminResetPassword
      />
      <View style={styles.inputPassword}>
        <DefaultText children={"Password"} style={styles.heading} />
        {/* <TextInput
          style={CommonStyles.textInput}
          placeholder="Password"
          keyboardType="visible-password"
          onChangeText={handlePassword}
          secureTextEntry
        /> */}
        <PasswordInput 
          placeholder="password"
          onPasswordChange={handlePassword}
          newPassword
        />
      </View>
      <TouchableOpacity
        disabled={!userExists}
        style={[
          styles.button,
          {
            backgroundColor: userExists
              ? DecidaColors.Green
              : DecidaColors.Gray,
          },
        ]}
        onPress={handleResetPassword}
      >
        <DefaultText style={styles.buttonText}>Reset Password</DefaultText>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 20,
  },
  heading: {
    fontSize: 17,
    fontWeight: "bold",
    paddingTop: 20,
  },
  inputPassword: {
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    height: 50,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    paddingHorizontal: 10,
  },
  buttonText: {
    color: DecidaColors.White,
  },
});
