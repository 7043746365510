import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import { SafeAreaView, StyleSheet, TouchableOpacity, View } from "react-native";
import { GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables, Teacher } from '../../common/API';
import { versionNumberString } from "../env";
import { getTeacherByCognitoUsername } from '../../common/graphql/queries';
import { rvCurrentUser } from '../login/login-state';
import { rvSwitchesScreenState } from "../switches/common/current-screen-state";
import { SwitchesScreens } from "../switches/navigation/switches-bottom-navigation";
import { TeacherDrawerNavigatorPamarList } from "../teacher/teacher-route-param-types";
import { DefaultFontSize } from "./const";
import { DefaultText } from "./default-text";
import { headerHeight } from "./header";
import { linkingOpenUrl } from "./helper-functions";
import { CLIENT_EVENT, createClientLog } from "./log-client-event";
import { logout } from "./logout";
import PopoverMenuProfileUsernameAndEmailInfo from "./pop-over-menu-profile-username-and-email-info";
import { rvShowPopoverTeacher } from "./pop-over-menu-state";
import { EmailSupportPopoverButton, TroubleshootingPopoverButton } from "./popover-buttons";
import { ScreenNames } from "./screen-names";
import { DecidaColors } from '../../common/decida-colors';
import AvatarImage from './avatar-image';


export const TeacherPopoverMenu = () => {
    const user = useReactiveVar(rvCurrentUser)

    const { data: teacherResponse } = useQuery<GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables>(gql`${getTeacherByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
    const currentTeacher = teacherResponse?.getTeacherByCognitoUsername?.items[0] as Teacher
    const showPopover = useReactiveVar(rvShowPopoverTeacher)
    const closePopup = () => {
        rvShowPopoverTeacher(false)
    }
    const { navigate } = useNavigation<DrawerNavigationProp<TeacherDrawerNavigatorPamarList>>()

    const logoutHandler = async () => {
        rvShowPopoverTeacher(false)
        rvSwitchesScreenState(SwitchesScreens.HOME)
        await createClientLog({
            event: CLIENT_EVENT.USER_SIGNED_OUT,
            area: "Popover menu",
            action: "Clicking logout button, and sign out",
            payload: {
                group: "teacher",
                nickname: currentTeacher?.cognitoUsername,
            }
        })
        logout()
    }
    const navigateToProfile = () => {
        rvShowPopoverTeacher(false)
        rvSwitchesScreenState(SwitchesScreens.HOME)
        navigate(ScreenNames.TeachersProfileMain)
    }

    if (!currentTeacher) {
        null
    }

    return showPopover ? (
        <TouchableOpacity testID="popover-menu-backdrop" style={styles.overlay} onPress={closePopup}>
            <SafeAreaView />
            <View style={styles.avatarpopover}>
                <PopoverMenuProfileUsernameAndEmailInfo cognitoUsername={currentTeacher?.cognitoUsername || ""} email={currentTeacher?.email || ""} />
                <EmailSupportPopoverButton />
                <View style={styles.optionsContainer}>
                    <TouchableOpacity onPress={() => linkingOpenUrl("https://www.switch4schools.com.au/helphub")} style={styles.dividerButton}>
                        <View style={styles.profileContainer}>
                            <DefaultText style={styles.optionsText}>
                                Help Hub
                            </DefaultText>
                        </View>
                    </TouchableOpacity>
                </View>
                <TroubleshootingPopoverButton />
                <View style={styles.profile}>
                    <TouchableOpacity onPress={navigateToProfile} style={styles.dividerButton}>
                        <View style={styles.profileContainer}>
                            <View style={styles.avatarContainer}>
                                <AvatarImage avatar={currentTeacher.avatar} size={40} />
                            </View>
                            <DefaultText style={styles.logouttext}>
                                Profile
                            </DefaultText>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={styles.logout}>
                    <TouchableOpacity testID="logout-button" onPress={logoutHandler} style={styles.dividerButton}>
                        <DefaultText style={styles.logouttext}>
                            Logout
                        </DefaultText>
                    </TouchableOpacity>
                </View>
                <View style={styles.optionsContainer}>
                    <View style={styles.profileContainer}>
                        <DefaultText style={styles.buildVersion}>
                            {versionNumberString}
                        </DefaultText>
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    ) : null
}


const styles = StyleSheet.create({
    avatarContainer: {
        marginHorizontal: 10,
    },
    overlay: {
        position: 'absolute',
        zIndex: 99,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    avatarpopover: {
        alignItems: 'flex-end',
        width: 300,
        minHeight: 300,
        maxHeight: '100%',
        zIndex: 500,
        marginRight: 2,
        backgroundColor: DecidaColors.White,
        borderColor: 'grey',
        borderRadius: 5,
        borderWidth: 0.5,
        marginTop: headerHeight
    },
    logout: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    profile: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    optionsContainer: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    optionsText: {
        marginRight: 15,
        color: DecidaColors.Gray,
        alignItems: 'flex-end',
        justifyContent: 'center',
        fontSize: DefaultFontSize - 2,
    },
    buildVersion: {
        marginRight: 15,
        fontSize: DefaultFontSize - 2,
        color: DecidaColors.AppleSystemGray4Light,
    },
    logouttext: {
        marginRight: 15,
        color: DecidaColors.Gray,
        alignItems: 'flex-end',
        justifyContent: 'center',
        fontSize: DefaultFontSize - 2,

    },
    dividerButton: {
        width: "100%",
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    profileContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },

})
