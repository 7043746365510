import { API } from "aws-amplify"

import { getApiHeaders } from "../common/get-api-header"
import { showAlert } from "../common/universal-alert"
import { GetSchoolQueryResponseClass } from "../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query"
import SchoolAdminClassAndTeacherForm, { SchoolAdminClassAndTeacherFormOnSubmit, SchoolAdminClassAndTeacherFormOnSubmitParam } from "./school-admin-class-and-teacher-form"
import * as Clipboard from 'expo-clipboard'
import gql from "graphql-tag"
import { createTeacherClass, customCreateTeacherAndAssignedToClass, updateTeacher } from "../../common/graphql/mutations"
import { StyleSheet } from 'react-native'
import { SchoolAdminTableSetEditComponent } from "./school-admin-const"
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { createClientLog, CLIENT_EVENT } from "../common/log-client-event"
import { generatePassword } from "../../common/generate-password"
import { CustomCreateTeacherAndAssignedToClassMutation, CustomCreateTeacherAndAssignedToClassMutationVariables } from "../../common/API"
import { useMutation } from "@apollo/client"

type Props = {
    setEditComponent: SchoolAdminTableSetEditComponent,
    clazz: GetSchoolQueryResponseClass
}

export const SchoolAdminAddTeacherToClass = ({
    setEditComponent,
    clazz,
}: Props) => {
    const handleSubmit: SchoolAdminClassAndTeacherFormOnSubmit = async ({
        firstName,
        lastName,
        email,
        role,
        selectedTeacher,
        apolloClient
    }) => {
        createNewTeacherAndAssignToClass({
            schoolID: clazz.schoolID || '',
            classID: clazz.id,
            firstName,
            lastName,
            email,
            role,
            selectedTeacher,
            apolloClient
        })
    }

    return (
        <SchoolAdminClassAndTeacherForm setEditComponent={setEditComponent} schoolID={clazz.schoolID || ''} currentClass={clazz} onSubmit={handleSubmit} style={styles.schoolAdminClassForm} />
    )
}

const styles = StyleSheet.create({
    schoolAdminClassForm: {
        maxWidth: '100%'
    },
})

export const createNewTeacherAndAssignToClass = async ({
    schoolID,
    classID,
    firstName,
    lastName,
    email,
    role,
    selectedTeacher,
    apolloClient
}: Omit<SchoolAdminClassAndTeacherFormOnSubmitParam, 'className'> & { schoolID: string, classID: string }) => {
    const lowercaseEmail = email?.toLowerCase()

    try {
        let teacherID: string = ''

        if (selectedTeacher) {
            teacherID = selectedTeacher.id

            const isDataChanged = firstName !== selectedTeacher?.firstName || lastName !== selectedTeacher?.lastName || lowercaseEmail !== selectedTeacher?.email.toLowerCase() || role !== selectedTeacher?.role
            if (isDataChanged) {
                await apolloClient.mutate({
                    mutation: gql`${updateTeacher}`,
                    variables: {
                        input: {
                            id: selectedTeacher?.id,
                            firstName,
                            lastName,
                            role: role,
                            email: lowercaseEmail,
                            _version: selectedTeacher?._version
                        }
                    }
                })
            }
            await apolloClient.mutate({
                mutation: gql`${createTeacherClass}`,
                variables: {
                    input: {
                        teacherID,
                        classID,
                    }
                }
            })
        } else {
            const tempPassword = generatePassword()

            if (!lowercaseEmail) {
                throw new Error("Please input email address")
            }

            await apolloClient.mutate({
                mutation: gql`${customCreateTeacherAndAssignedToClass}`,
                variables: {
                    input: {
                        classID,
                        schoolID,
                        firstName,
                        lastName,
                        email: lowercaseEmail,
                        cognitoUsername: lowercaseEmail,
                        password: tempPassword,
                        role,
                        showIntroduction: true,
                        showNewFeatureInstructions: false
                    }
                }
            })

            
            await Clipboard.setStringAsync(tempPassword)
            console.log("finish all copy clipboard")

            showAlert({
                title: 'Successfully created teacher.',
                message: `Password: ${tempPassword}`,
                leftButtonText: 'Ok',
            })
        }

    } catch (error: any) {
        const errorMessage = String(error?.response?.data?.message) || ""
        await createClientLog({
            event: CLIENT_EVENT.GENERIC_ERROR,
            area: "school admin",
            action: "add teacher to class",
            payload: {
                errorMessage
            }
        })
        showAlert({
            title: 'An error occured',
            message: errorMessage || 'Please contact support@switch4schools.com.au for assistance.',
            leftButtonText: 'Ok',
        })
    }

}