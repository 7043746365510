import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { Platform, StyleSheet, View } from 'react-native'
import { GetSchoolQuery } from '../../common/API'
import { envBackgroundColor } from '../env'
import { getSchool } from '../../common/graphql/queries'
import { rvCurrentStudent } from '../student/use-student-state'
import { rvCurrentClass } from './common-state'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from './default-text'
import useDeviceTypes from './use-device-types'
import { useResponsive } from './use-responsive'

const HeaderBottomRow = () => {
    const currentClass = useReactiveVar(rvCurrentClass);
    const currentStudent = useReactiveVar(rvCurrentStudent)
    const { isNarrowScreen } = useResponsive()

    const { data } = useQuery<GetSchoolQuery>(gql`${getSchool}`, { variables: { id: currentStudent?.school?.id } })

    return (
        <View style={styles.headerSpaces}>
            <View style={styles.headerSchoolContainer}>
                <DefaultText style={[styles.headerText]}>
                    {data?.getSchool?.name}
                </DefaultText>
            </View>
            <View style={styles.headerClassNameContainer}>
                <DefaultText style={[styles.classText, { fontSize: isNarrowScreen ? 18 : 24 }]} textProps={Platform.OS === 'ios' ? { numberOfLines: 2, adjustsFontSizeToFit: true } : { numberOfLines: 2 }}>
                    {currentClass?.name}
                </DefaultText>
            </View>
            <View style={styles.placeholder} />
        </View>
    )
}
export default HeaderBottomRow
const styles = StyleSheet.create({
    headerSpaces: {
        width: '100%',
        minHeight: 40,
        maxHeight: 100,
        backgroundColor: envBackgroundColor,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
    },
    backButtonContainer: {
        position: 'absolute',
        left: 0,
        top: '25%',
        paddingLeft: 20
    },
    backButtonText: {
        fontSize: 16,
        fontWeight: '600',
        color: DecidaColors.White
    },
    studentsCountContainer: {
        alignItems: 'flex-start',
        paddingLeft: 50,
    },
    headerText: {
        color: DecidaColors.White,
        fontSize: 16,
        marginHorizontal: 5,
        textAlign: 'right',
    },
    headerSchoolContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    headerClassNameContainer: {
        flex: 1,
        alignItems: 'center',
    },
    classText: {
        fontWeight: 'bold',
        color: DecidaColors.White
    },
    placeholder: {
        flex: 1,
        width: 20,
        alignItems: 'center'
    }
})