import Svg, { Path } from 'react-native-svg'

interface Props {
    stylefill: string,
}

export const IconCheckIn = ({ stylefill }: Props) =>
    <Svg width="38.845" height="38.845" viewBox="0 0 38.845 38.845">
        <Path id="Path_1278" data-name="Path 1278" d="M2264.4,5159.572l2.655,2.428-14.566,16.122-7.511-7.511,2.542-2.541,4.741,4.74Zm-8.383-10.812a19.422,19.422,0,1,0,19.422,19.423A19.421,19.421,0,0,0,2256.012,5148.761Z" transform="translate(-2236.59 -5148.761)" fill={stylefill} />
    </Svg>
