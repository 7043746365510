import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { StoryboardHeader } from './src/storyboard-header'
import { StoryboardIcon } from './src/storyboard-icon'
import { StoryboardImage1 } from './src/storyboard-image1'
import { StoryboardLessonPlan2 } from './src/storyboard-lesson-plan2'
import { StoryboardLessonPlan3 } from './src/storyboard-lesson-plan3'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'

export const SwitchesEmotionsAnxiousStoryboarding: CardTemplate = {
    cardId: "64384a69-4f6d-40d9-a0ad-690336bfe406",
    title: 'Storyboarding',
    Header: StoryboardHeader,
    Icon: StoryboardIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Adjusting to a new routine, participating in conversations, or learning how to interact with others."} />
        </>
    ),
    Images: () => (
        <>
            <StoryboardImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Think of a social situation that makes you nervous, or where you don’t know what to do (i.e. asking to join a game at lunch time)."} />
                <DefaulttextWithRightArrow textdata={"Think about what you would like to be able to do in that situation, and how you would like it to end."} />
                <DefaulttextWithRightArrow textdata={"Write a step-by-step story, thinking about all the things you want to say or do and what others might say or do."} />
                <DefaulttextWithRightArrow textdata={"Break the story into key moments, and draw a picture of each of these key moments, writing the appropriate words under the picture (ask your teacher for the storyboard worksheet if it helps)."} />
                <DefaulttextWithRightArrow textdata={"Use your storyboard to practice the situation a few times until you become confident to try it out in real life."} />
                <DefaulttextWithRightArrow textdata={"Look for an opportunity to use your story in real life and reflect on how it went afterward."} />
            </View>
        </>
    ),
    BottomRow: () => (
        <>
            <View>
                <DefaultText>Example storyboard:</DefaultText>
                <View style={styles.imageContainer}>
                    <StoryboardLessonPlan2 />
                </View>
            </View>
            <DefaulTextWithTipsIcon textdata='If you aren’t sure what the story should say, talk to someone you trust and work it through together.' />
            <DefaulTextWithTipsIcon textdata='Re-read your storyboard often, ideally before the challenging situation happens again so you remember the steps discussed in the story.' />
            <DefaulTextWithTipsIcon textdata='Save your storyboards in a scrapbook or folder for easy reference.' />
            <DefaulTextWithTipsIcon textdata='Ideas for storyboards could include:' />
            <View style={styles.tipsIndent}>
                <DefaulTextWithTipsIcon textdata='Asking a teacher for help' />
                <DefaulTextWithTipsIcon textdata='Sharing and turn taking' />
                <DefaulTextWithTipsIcon textdata='Dealing with change' />
                <DefaulTextWithTipsIcon textdata='Overcoming the fear of talking to people' />
                <DefaulTextWithTipsIcon textdata='Being assertive without being pushy' />
                <DefaulTextWithTipsIcon textdata='Compromising and negotiating' />
                <DefaulTextWithTipsIcon textdata='Dealing with failure or being left out' />
                <DefaulTextWithTipsIcon textdata='Dealing with peer pressure' />
            </View>

        </>

    ),
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: `Storyboarding utilises the visual, imaginative and creative parts of our brain to help rewire our brain to be better in stressful or fearful situations. That is, teach the brain to have a different emotional and behavioural response to things that make you panic or fill you with terror. This can reduce the stress associated with social interactions, teaching specific behaviour patterns, and improving coping and socials skills.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify situations that require appropriate behaviour change. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define acceptable steps to respond appropriately in different situations using storytelling.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 40 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the concept of uncertainty and how it's normal to feel uncertain or anxious about new activities or challenges.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce storyboarding as a visual planning tool. Explain that just like planning a morning routine, storyboards can help organise thoughts and actions when facing uncertain situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to take out drawing paper and markers/coloured pencils.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct them to draw a simple storyboard depicting the steps of their typical morning routine. Each box in the storyboard represents a different step (e.g., waking up, brushing teeth, having breakfast).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After drawing, have students share their storyboards with a partner or in small groups.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss as a class: How did it feel to visually plan out your morning routine? Were there any surprises or challenges in the process?</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct instruction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that if you are having trouble with a particular situation, social interaction, or are expecting to face a new situation it can be helpful to think and talk through what could happen and how you should best respond in that situation.  Then if we write down the steps in the form of a story, you can imagine a new or different way to handle the situation and then practice applying that story in real life.  This is called a storyboard.”</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Advise that we will walk through the first storyboard together as a group. Choose a relatively simple fun situation that is appropriate for the group to practice together on, such as  walking down the school hallway.</DefaultText>
                        <View style={styles.imageContainer}>
                            <StoryboardLessonPlan3 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to brainstorm the story content facilitating a discussion about what is appropriate behaviour, and how they would write instructions for themselves in the form of a story.  Co-create the story or use the supplied example, and talk it through with the students to explain that setting expectations helps them to respond to situations appropriately with consideration for the impact they have on others. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer students to the Switch4Schools app and talk through the listed steps.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Now, refer students to the Storyboard worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to choose a situation they would like to write a story for and practice.  This can be done either in pairs, small groups or individually as appropriate for the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Take photos of the stories, or save them into a space the students can reference and build on (a binder, scrapbook or digital file storage) over time. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their stories (if they feel comfortable to).</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to try applying their story in real life and circle back after a few days/weeks to evaluate the effectiveness of the story and make adjustments as appropriate.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recommend asking students to practice writing stories for other situations as they need to develop this skill over time.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Writing materials</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Markers/coloured pencils</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Storyboard Worksheet' link={Worksheets.storyBoard.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Walking down the hallway' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Walking+down+the+hallway.pdf' /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Promoting Social Understanding – Social Scripts</DefaultText>
                        <DefaultTextWithLink text='https://suelarkey.com.au/promoting-social-understanding-social-scripts/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Social Stories for Adolescents and Young Adults -</DefaultText>
                        <DefaultTextWithLink text='https://lifeskillsadvocate.com/blog/social-stories-for-adolescents-and-young-adults/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Simple Social Scripts That Work -</DefaultText>
                        <DefaultTextWithLink text='https://starautismsupport.com/simple-social-scripts-work' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to write a social story -</DefaultText>
                        <DefaultTextWithLink text='https://www.speechtherapystore.com/how-to-write-a-social-story/' />
                    </>}
            />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Storyboard+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Anxious]
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    tipsIndent: {
        paddingLeft: 60
    }
})
