import { People01 } from "./people-01";
import { People02 } from "./people-02";
import { People03 } from "./people-03";
import { People04 } from "./people-04";
import { People05 } from "./people-05";
import { People06 } from "./people-06";
import { People07 } from "./people-07";
import { People08 } from "./people-08";
import { People09 } from "./people-09";
import { People10 } from "./people-10";
import { People11 } from "./people-11";
import { People12 } from "./people-12";
import { People13 } from "./people-13";
import { People14 } from "./people-14";
import { People15 } from "./people-15";
import { People16 } from "./people-16";
import { People17 } from "./people-17";
import { People18 } from "./people-18";
import { People19 } from "./people-19";
import { People20 } from "./people-20";
import { People21 } from "./people-21";
import { People22 } from "./people-22";
import { People23 } from "./people-23";
import { People24 } from "./people-24";
import { People25 } from "./people-25";
import { People26 } from "./people-26";
import { People27 } from "./people-27";
import { People28 } from "./people-28";
import { People29 } from "./people-29";
import { People30 } from "./people-30";
import { People31 } from "./people-31";
import { People32 } from "./people-32";
import { People33 } from "./people-33";
import { People34 } from "./people-34";
import { People35 } from "./people-35";
import { People36 } from "./people-36";
import { People37 } from "./people-37";
import { People38 } from "./people-38";
import { People39 } from "./people-39";
import { People40 } from "./people-40";

export const peopleavatars = {
    People01,
    People02,
    People03,
    People04,
    People05,
    People06,
    People07,
    People08,
    People09,
    People10,
    People11,
    People12,
    People13,
    People14,
    People15,
    People16,
    People17,
    People18,
    People19,
    People20,
    People21,
    People22,
    People23,
    People24,
    People25,
    People26,
    People27,
    People28,
    People29,
    People30,
    People31,
    People32,
    People33,
    People34,
    People35,
    People36,
    People37,
    People38,
    People39,
    People40
}