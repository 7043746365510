import { useRoute } from '@react-navigation/native'
import { useEffect, useState } from 'react'
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import { DefaultText } from '../common/default-text'
import { LinkText } from '../common/link-text'
import { ScreenNames } from '../common/screen-names'
import { StudentIndividualStatsHowToButton } from '../common/svg-files/student-individual-stats-how-to-button'
import { lowRes } from '../common/use-responsive'
import { IconAngry } from '../icon/icon-angry'
import { IconAnxious } from '../icon/icon-anxious'
import { IconEmotionsLegend } from '../icon/icon-emotions-legend'
import { IconEmotionsLegendEmotionBlocks } from '../icon/icon-emotions-legend-emotion-blocks'
import { IconExcited } from '../icon/icon-excited'
import { IconHappy } from '../icon/icon-happy'
import { IconSad } from '../icon/icon-sad'
import { IconScared } from '../icon/icon-scared'
import { Emotion } from '../models'
import { TeacherStatsLegend } from './teacher-stats-legend'
import { rvShowIndividualStudentHowTo } from './teacher-student-individual-stats'
import { useReactiveVar } from '@apollo/client'
import { getIntensityLevel } from '../common/get-intensity-level'
import { rvCurrentClass } from '../common/common-state'
import { rvPrintMode } from '../common/export-to-pdf-button'

interface Props {
    showAllCheckInLegend: boolean
}

export const TeacherStatsLegends = ({ showAllCheckInLegend }: Props) => {

    const [showLegend, setShowLegend] = useState(true)
    const route = useRoute()
    const isIndividualStats = route.name === ScreenNames.TeacherStudentIndividualStats
    const showHowToModal = useReactiveVar(rvShowIndividualStudentHowTo)
    const currentClass = useReactiveVar(rvCurrentClass)
    const isPrintMode = useReactiveVar(rvPrintMode)
    const intensityLevel = getIntensityLevel(currentClass?.emotionIntensityLevel)
    
    useEffect(() => {
        setShowLegend(!lowRes)

    }, [lowRes])


    const toggleLegendVisibility = () => {
        setShowLegend(!showLegend)
    }

    const toggleIndividualStudentHowTo = () => {
        rvShowIndividualStudentHowTo(!showHowToModal)
    }

    return (
        <>
            <LinkText onPress={toggleLegendVisibility} style={[styles.legendVisibility, { alignSelf: lowRes && isIndividualStats ? 'flex-end' : 'flex-start' }]}>{showLegend ? 'hide legend' : 'show legend'}</LinkText>
            <View style={[styles.legendsContainer, showLegend ? {} : { display: 'none', height: isPrintMode ? 200 : undefined }]}>
                <View style={styles.legendRow}>
                    <TeacherStatsLegend
                        emotion={Emotion.ANGRY}
                        icon={<IconAngry />}
                    />
                    <TeacherStatsLegend
                        emotion={Emotion.SAD}
                        icon={<IconSad />}
                    />
                    <TeacherStatsLegend
                        emotion={Emotion.SCARED}
                        icon={<IconScared />}
                    />
                </View>
                <View style={styles.legendRow}>
                    <TeacherStatsLegend
                        emotion={Emotion.ANXIOUS}
                        icon={<IconAnxious />}
                    />
                    <TeacherStatsLegend
                        emotion={Emotion.EXCITED}
                        icon={<IconExcited />}
                    />
                    <TeacherStatsLegend
                        emotion={Emotion.HAPPY}
                        icon={<IconHappy />}
                    />
                </View>
                <View style={showAllCheckInLegend ? styles.tiredness : { display: 'none' }}>
                    <View style={styles.legendText}>
                        <DefaultText style={styles.legendText}>Emotions</DefaultText>
                    </View>
                    <IconEmotionsLegendEmotionBlocks />
                    <View style={styles.legendText}>
                        <DefaultText style={styles.legendText}>Energy</DefaultText>
                    </View>
                    <IconEmotionsLegend />
                    <TouchableOpacity style={styles.howToButton} onPress={toggleIndividualStudentHowTo}>
                        <StudentIndividualStatsHowToButton />
                    </TouchableOpacity>
                </View>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    legendVisibility: {
        fontSize: 12,
        alignSelf: 'flex-start',
        marginHorizontal: 10,
    },
    legendsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignSelf: 'center',
    } as ViewStyle,
    legendRow: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        minWidth: 350,
    },
    tiredness: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 5,
    } as ViewStyle,
    legendText: {
        fontSize: 12,
        fontWeight: 'bold',
        alignItems: 'flex-end',
        padding: 10,
    },
    howToButton: {
        width: 40,
        height: 40,
        marginLeft: 20
    }
})