import { GestureResponderEvent, StyleProp, StyleSheet, Text, TextStyle } from 'react-native'
import { DefaultFontSize } from './const'
import { DefaultText } from './default-text'
import { DecidaColors } from '../../common/decida-colors'

interface Props {
    children: string | string[] | undefined
    style?: StyleProp<TextStyle>
    onPress?: (event: GestureResponderEvent) => void
}

export const ButtonText = ({ children, style, onPress }: Props) =>
    <DefaultText onPress={onPress} style={[styles.text, style]}>{children}</DefaultText>

const styles = StyleSheet.create({
    text: {
        fontSize: DefaultFontSize,
        color: DecidaColors.White,
    } as TextStyle
})