import _ from 'lodash'
import { Fragment, useEffect } from 'react'
import { useState } from 'react'
import { StyleSheet, View, TextInput, TextInputProps, ViewStyle } from 'react-native'
import { BackButton } from './back-button'
import { DefaultButton } from './default-button'
import { BreakPoints, CommonStyles } from './const'
import { DefaultText } from './default-text'
import { hasData } from './helper-functions'
import { Page } from './page'
import { RootView } from './root-view'
import { showAlert, showGenericErrorAlert } from './universal-alert'
import { rvIsLoading } from './loading'
import { CONTAINER_INPUT_WIDTH } from '../admin/admin-consts'
import { DecidaColors } from '../../common/decida-colors'

export type CustomSetter<T> = (value: T | undefined, setter: (value: NonNullable<T> | '' | undefined) => void) => void
export type CustomComponent<I extends {}> = (props: { modelInstance: I | undefined, state: FormPropState<I> }) => JSX.Element

type Model = {
    id?: string | null,
}

type Prop<I extends {}, K extends keyof Omit<I, 'id'>, T = I[K]> = {
    default?: T,
    component?: CustomComponent<I>,
    isOptional?: boolean,
    label?: string,
    customSetter?: CustomSetter<T>,
    textInputProps?: TextInputProps,
    hide?: boolean,
    allowEdit?: (modelInstance: I | undefined, state: FormPropState<I>) => boolean,
    containerStyle?: ViewStyle,
}

export type SaveFunction<T extends Model> = (state: FormPropState<T>, modelInstance: T | undefined) => (Promise<T> | T)
export type DeleteFunction<T extends Model> = (state: T) => (Promise<void> | void)

type Props<T extends Model> = {
    model: T | undefined,
    goBack: () => void,
    name: string,
    config: {
        [K in keyof Omit<T, 'id'>]?: Prop<T, K>
    } | {
        [K: string]: any
    },
    onSave: SaveFunction<T>,
    onDelete: DeleteFunction<T>,
    additionalComponents?: (modelInstance: T | undefined, state: FormPropState<T>) => (JSX.Element | null | undefined)
    multipleColumn?: boolean
}

export type FormPropState<T extends {}> = {
    -readonly [K in keyof T]?: {
        value: T[K] | string
        setter: (value: NonNullable<T[K]> | string) => void
    }
}

const getNonEditedValue = <T extends {}>(modelInstance: T | undefined, prop: Prop<any, any, any> | undefined, propName: keyof T) =>
    (modelInstance == undefined ? undefined : modelInstance[propName]) || prop?.default || '';

/**
 * Fields will appear in the order they're specified in the config.
 * TextInputProps will be ignored if component is specified in the config.
 */
export const Form = <T extends Model>({
    model,
    goBack,
    name,
    config,
    onSave,
    onDelete,
    additionalComponents,
    multipleColumn
}: Props<T>) => {
    const [modelInstance, setModelInstance] = useState<T | undefined>(model && { ...model })

    const state: FormPropState<T> = {}

    _.forOwn(config, (prop, propName) => {
        const [value, setter] = useState(prop?.default || '')
        state[propName as keyof typeof config] = {
            value,
            setter: prop?.customSetter ? (newValue: any) => prop.customSetter!(newValue, setter) : setter
        }
    })

    const disableDeleteButton = modelInstance === undefined
    const disableCancelButton = _.map(
        config,
        (prop, propName: keyof typeof config) => _.isEqual(getNonEditedValue(modelInstance, prop, propName), state[propName]?.value)
    ).reduce((result, valueIsDefault) => result && valueIsDefault)
    const disableSaveButton = disableCancelButton || _.map(
        config,
        (prop, propName: keyof typeof config) => !prop?.isOptional && !hasData(state[propName]?.value)
    ).reduce((result, hasNoValue) => result || hasNoValue)

    const updateModel = (updatedModel?: T) => {
        setModelInstance(updatedModel)

        if (updatedModel) {
            _.forOwn(config, (prop, propName) => {
                state[propName as keyof typeof config]?.setter(updatedModel[propName as keyof typeof config] || prop?.default || '')
            })
        }
    }

    useEffect(() => {
        updateModel(model)
    }, [model])

    const revertModel = () => {
        _.forOwn(config, (prop, propName) => {
            state[propName as keyof typeof config]?.setter(getNonEditedValue(modelInstance, prop, propName as keyof typeof config))
        })
    }

    const saveModel = async () => {
        try {
            rvIsLoading(true)
            updateModel(await onSave(state, modelInstance))
        } catch (e: any) {
            console.log(e)
            const errorMessage = e.response?.data?.message || (typeof e === 'string' ? e : undefined)

            if (errorMessage) {
                showAlert({
                    title: 'An error has occured.',
                    message: errorMessage,
                    leftButtonText: 'Ok',
                    rightButtonText: 'Retry',
                    onRightButtonPress: saveModel
                })
            } else {
                showGenericErrorAlert()
            }
        } finally {
            rvIsLoading(false)
        }
    }

    const deleteModel = async () => {
        if (!disableDeleteButton && modelInstance) {
            showAlert({
                title: `Are you sure you want to delete this ${name}`,
                message: 'This action cannot be undone.',
                leftButtonText: 'Cancel',
                rightButtonText: 'Delete',
                onRightButtonPress: async () => {
                    console.log(state)
                    try {
                        await onDelete(modelInstance)
                        goBack()
                    } catch (e: any) {
                        console.log(e)
                        if (e?.response?.data?.message === 'User does not exist.') {
                            goBack()
                        } else {
                            showGenericErrorAlert()
                        }
                    }
                }
            })
        }
    }

    return (
        <RootView>
            <View style={styles.headerRow}>
                <BackButton displayName='Back' />
                <DefaultButton style={styles.button} disabled={disableDeleteButton} onPress={deleteModel}>Delete</DefaultButton>

            </View>
            <Page style={styles.page} scroll>
                <View style={multipleColumn ? styles.formContainerMultipleColumn : {}}>
                    {
                        _.map(config, (prop, key) => prop?.hide ? null : (
                            <View key={key} style={{ flexDirection: 'column', marginHorizontal: 10, ...prop.containerStyle }}>
                                <DefaultText style={styles.inputLabel}>{prop?.label || _.startCase(key)}{prop?.isOptional ? '' : '*'}</DefaultText>
                                {
                                    prop?.component ? prop.component({ modelInstance, state }) :
                                        <TextInput
                                            style={[
                                                { ...CommonStyles.textInput, ...prop?.textInputProps?.multiline ? styles.multiline : {} },
                                                prop?.allowEdit && !prop.allowEdit(modelInstance, state) ? styles.disabled : {},
                                            ]}
                                            value={'' + state[key as keyof typeof config]?.value}
                                            onChangeText={state[key as keyof typeof config]?.setter}
                                            editable={prop?.allowEdit && prop.allowEdit(modelInstance, state)}
                                            {...prop?.textInputProps}
                                        />
                                }
                            </View>
                        ))
                    }
                </View>
                <View style={styles.buttonRow}>
                    <DefaultButton style={styles.button} disabled={disableCancelButton} onPress={revertModel}>Cancel</DefaultButton>
                    <DefaultButton style={styles.button} disabled={disableSaveButton} onPress={saveModel}>Save</DefaultButton>
                </View>
                {additionalComponents && additionalComponents(modelInstance, state)}
            </Page>
        </RootView>
    )
}

const styles = StyleSheet.create({
    formContainerMultipleColumn: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        maxWidth: 700,
    },
    page: {
        flex: 1,
        justifyContent: 'flex-start',
        paddingBottom: 20,
    },
    headerRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
    },
    buttonRow: {
        width: 330,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    inputLabel: {
        width: 330,
    },
    button: {
        width: 80,
        marginLeft: 10,
    },
    disabled: {
        color: DecidaColors.Gray
    },
    multiline: {
        minHeight: 120,
    }
})