import { View, StyleSheet, StyleProp, TextStyle, ViewStyle, Dimensions } from "react-native";
import { DefaultText } from "../../common/default-text";
import { ArrowOutline } from "../activities/icons/arrow-outline";
import { GhostArrow } from "./ghost-arrow";
import { Entypo } from '@expo/vector-icons';
import { useReactiveVar } from "@apollo/client";
import { rvCard } from "./card-color-scheme";


const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600

interface Props {
    textdata: string
    style?: StyleProp<TextStyle>
    filledArrow?: boolean

}


export const DefaulttextWithRightArrowLeftAlignedDoubleIndentation = ({ textdata, style, filledArrow }: Props) => {
    const color = useReactiveVar(rvCard)?.colorScheme

    return (
        <View style={[styles.content, style]}>
            <View style={styles.containingView}>
                <View style={styles.icon}>
                    <View style={styles.extraTipsIcon}>
                        <View style={styles.containerSvgIcon}>
                            {filledArrow
                                ?
                                <Entypo name="arrow-bold-right" size={24} color={color} />
                                :
                                <GhostArrow />
                            }
                        </View>
                    </View>
                </View>
                <DefaultText style={styles.text}>
                    {textdata}
                </DefaultText>
            </View>
        </View>
    )

}



const styles = StyleSheet.create({

    icon: {
    },
    content: {
        justifyContent: 'center',
        marginVertical: 5,
        marginTop: 20,


    } as ViewStyle,
    text: {
        textAlign: 'auto'

    },
    extraTipsIcon: {
        paddingTop: 2,
        paddingRight: 5,

    },
    containingView: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginLeft: windowWidthcondition ? 75 : 0
    },
    containerSvgIcon: {
        width: 20,
        height: 20,
    }
})

