import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import SwitchCardVideo from '../../common/switch-card-video'

const understandingAndManagingAnxietyVideo1 = {
    id: "1",
    url: "https://www.youtube.com/embed/rpolpKTWrp4",
    title: ""
}

export const SwitchesLessonPlansUnderstandingAndManagingAnxiety: CardTemplate = {
    cardId: "352575ae-5bfb-4863-8fc3-2d4b5dab99f2",
    title: 'Understanding and managing anxiety',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to recognise, understand, manage, and use anxiety constructively to enhance self awareness.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: Approximately 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Greet the students and explain the purpose of the lesson: to understand and manage anxiety effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share their understanding of anxiety and its effects on themselves and others.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Defining Anxiety (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Fight Flight or Freeze video' link='https://youtu.be/rpolpKTWrp4' />  [2:41]</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the definition of anxiety, (refer to the Little Book of Big Emotion for more information) emphasising that it is a normal and common emotion that everyone experiences. It's like a built-in alarm system in our bodies that helps us stay safe and prepare for challenging situations. Explain that anxiety is a feeling of worry, or unease about uncertain or future events.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Low levels of anxiety can actually be beneficial in several ways: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Alertness: When we experience a little bit of anxiety, it can make us more alert and focused. It's like our brain is saying, "Hey, pay attention! Something important is happening!" This can help us be more aware of our surroundings and make better decisions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Motivation: Sometimes, a small amount of anxiety can motivate us to take action and accomplish things. It can give us a little push to face our fears, try new things, and achieve our goals. So, a little bit of anxiety can be helpful in challenging ourselves and growing as individuals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Preparedness: Anxiety can also help us be prepared for certain situations. For example, if you feel a little anxious before a test, it can remind you to study and be ready. That way, when the test comes, you're more likely to do well because you've put in the effort to prepare.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Problem-solving: When we feel a little anxious, it can actually help us think more critically and come up with solutions to problems. It can make us more focused and creative, allowing us to find effective ways to overcome challenges.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ However, it's important to remember that too much anxiety can be overwhelming and have negative effects on our wellbeing. High levels of anxiety can interfere with our daily lives, make it difficult to concentrate, and cause unnecessary stress. It's important to learn how to manage and cope with anxiety effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the different ways people experience and express anxiety, such as physical symptoms (e.g., racing heart, sweating) and behavioural changes (e.g., avoidance, restlessness).</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding the Consequences of Uncontrolled Anxiety (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the negative consequences of uncontrolled anxiety, such as difficulty concentrating, disrupted sleep, and avoidance of enjoyable activities. Specifically:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Physical Effects: Unmanaged anxiety can have physical effects on our bodies. For example, you might start feeling tense muscles, headaches, stomach aches, or even have trouble sleeping. Anxiety can also make your heart beat faster and cause you to feel short of breath. These physical symptoms can make you feel uncomfortable and may affect your daily activities.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Emotional Impact: Anxiety can also have an impact on our emotions. If anxiety isn't managed, it can make us feel more irritable, easily frustrated, or even sad. You might find it harder to concentrate or make decisions. Over time, unmanaged anxiety can affect your self-confidence and make you doubt yourself or worry excessively about things.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Social Challenges: Anxiety that isn't managed can sometimes affect our social life. You might find it difficult to participate in activities or spend time with friends because you feel anxious or worried. It can make you avoid situations that trigger your anxiety, which may limit your experiences and make you feel isolated or lonely.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Impact on School Performance: When anxiety becomes overwhelming and isn't managed properly, it can affect your school performance. It might be harder to focus, concentrate, or complete assignments. Anxiety can also make you feel nervous about tests or speaking in front of the class, which can impact your academic success.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Everyday Life: Unmanaged anxiety can affect your overall quality of life. It might make you miss out on enjoyable experiences or prevent you from trying new things because of the fear and worry that comes with anxiety. It's important to learn how to manage anxiety so that it doesn't hold you back from doing the things you love or reaching your goals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Share real-life examples or stories that illustrate the negative effects of uncontrolled anxiety.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how anxiety can impact their own lives and the lives of others around them.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Developing Self awareness (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the concept of self awareness as the ability to recognise and understand one's own thoughts, feelings, and behaviours.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the connection between self awareness and anxiety management.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a guided reflection exercise, encouraging students to think about their own anxiety triggers and how they typically respond. Hand out the ABC Anxiety worksheet and explain that the ABC model of anxiety in is a framework that helps people understand how their thoughts, feelings, and behaviours are interconnected. It stands for:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- A – Activating event or situation. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- B – Beliefs or thoughts triggered by the event or situation. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- C – Consequences: Emotional and behavioural response to the event. </DefaultText>

                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Identify an event or situation that triggers anxious thoughts, feelings, and behaviours.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Write down the situation and location in the first column.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Write down the triggering event that happened in the “A” section of the worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Write down the thoughts and beliefs that you have about the event in the “B” section.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Write down the emotional and behavioural responses that result from the thoughts and beliefs in the “C” section. In other words, what did you do as a result of the anxious feelings? How did they make you feel?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Reflect and then challenge the belief.  Replace any negative thoughts with more balanced and rational thoughts. Write this different reframed belief on the sheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ NOTE – if required to assist students to learn to reframe their belief narrative, refer to the Reframe switch and associated lesson plan for an alternative way to change their way of thinking about the response to triggers.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Optional additional activity: Instruct student to repeat this exercise whenever they notice anxious thoughts over the coming week. Then review the worksheets and look for patterns of negative thoughts, feelings, or behaviours. Encourage students to challenge those thoughts and reconstruct a different more rational narrative.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite (but not force) students to share their reflections and discuss strategies for improving self awareness.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Anxiety Management Techniques (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce a variety of anxiety management techniques, such as deep breathing exercises, positive self-talk, progressive muscle relaxation, and mindfulness. Refer to the anxiety switches for inspiration.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students access the Switch4Schools program on their device and encourage them to explore the available switches assigned to anxiety on the emotion wheel to bookmark activities they’d like to try.  Alternatively provide a copy of the level 5 quick reference guide. Could also be a homework activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice switching techniques to manage their anxiety levels.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the key points discussed during the lesson, emphasising the importance of understanding, and managing anxiety.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice the strategies learned in their daily lives to enhance emotional intelligence and self awareness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Answer any questions or concerns the students may have.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='ABC Anxiety Activity Sheet' link={Worksheets.abcAnxietyWorksheet.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Level 5 quick reference guide' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/level+5+QRG.pdf' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Reframe worksheet' link={Worksheets.reframe.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What is anxiety & the effects on mental health -</DefaultText>
                        <DefaultTextWithLink text='https://headspace.org.au/explore-topics/for-young-people/anxiety/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Fight Flight Freeze – Anxiety Explained For Teens -</DefaultText>
                        <DefaultTextWithLink text='https://youtu.be/rpolpKTWrp4' />
                        <SwitchCardVideo  video={understandingAndManagingAnxietyVideo1}/>
                        <DefaultText style={CommonStyles.lessonPlanText}>Depression and anxiety threatened to kill my career. So I came clean about it -</DefaultText>
                        <DefaultTextWithLink text='https://www.theguardian.com/society/2019/sep/10/depression-and-anxiety-threatened-to-kill-my-career-so-i-came-clean-about-it' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        marginVertical: 10,
    },
    durationText: {
        marginBottom: 10,
        color: DecidaColors.Gray,
        alignSelf: 'center'
    },
})
