import { Entypo } from '@expo/vector-icons';
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { SafeAreaView, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { DefaultBackgroundColor } from "../common/const";
import { DefaultText } from "../common/default-text";
import { copywriteyearstring } from "../common/get-year-date";
import { Page } from "../common/page";
import { ScreenNames } from "../common/screen-names";
import { versionNumberString } from "../env";
import IconSwitch4SchoolLogo2023 from '../icon/icon-switch-4-school-logo-2023';
import LoginForm from "./login-form";
import { LoginStackNavigatorParamList } from "./login-route-param-types";
import { rvLoggedIn, rvParentPortal } from "./login-state";
import { DecidaColors } from '../../common/decida-colors';

export const Login = () => {
  const { navigate } =
    useNavigation<StackNavigationProp<LoginStackNavigatorParamList>>();

  const navigateToParentMain = () => {
    rvParentPortal(true)
    rvLoggedIn(true)
  };

  const navigateToClassLogin = () => {
    navigate(ScreenNames.LoginClassLogin);
  }

  return (
    <Page style={styles.page} keyboardVerticalOffset={0}>
      <SafeAreaView style={styles.fullWidth}>
        <ScrollView style={styles.fullWidth} contentContainerStyle={styles.scrollContainerStyle}>
          <View style={styles.inputContainer}>
            <IconSwitch4SchoolLogo2023 />
            <TouchableOpacity style={styles.parentPortalContainer} onPress={navigateToParentMain}>
              <DefaultText
                style={styles.forgotPasswordText}
              >
                Browse without login
              </DefaultText>
              <View style={styles.parentPortalIcon}>
                <Entypo name="arrow-with-circle-right" size={24} color={DecidaColors.DarkBlue} />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={styles.parentPortalContainer} onPress={navigateToClassLogin}>
              <DefaultText
                style={styles.forgotPasswordText}
              >
                Class login
              </DefaultText>
              <View style={styles.parentPortalIcon}>
                <Entypo name="arrow-with-circle-right" size={24} color={DecidaColors.DarkBlue} />
              </View>
            </TouchableOpacity>
            <LoginForm />
          </View>

          <View style={styles.copywrite}>
            <DefaultText style={styles.copywrite}>
              © Decida Digital Pty Ltd {copywriteyearstring}
            </DefaultText>
          </View>
          <DefaultText style={styles.buildVersion}>{versionNumberString}</DefaultText>
        </ScrollView>
      </SafeAreaView>
    </Page>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: DecidaColors.White,
  },
  fullWidth: {
    width: '100%',
  },
  scrollContainerStyle: {
    backgroundColor: DefaultBackgroundColor,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputContainer: {
    flexDirection: "column",
    width: 350,
    alignItems: "center",
  },
  loginButton: {
    backgroundColor: DecidaColors.Green,
  },
  touchIDButton: {
    backgroundColor: DecidaColors.Salmon,
  },
  forgotPasswordContainer: {
    width: 350,
    alignItems: "flex-end",
    paddingRight: 9,
  },
  parentPortalContainer: {
    width: 350,
    alignItems: "center",
    justifyContent: 'center',
    flexDirection: 'row',
  },
  forgotPasswordText: {
    color: DecidaColors.DarkBlue,
    height: 30,
  },
  logostyle: {
    backgroundColor: DecidaColors.Black,
    borderRadius: 12,
    padding: 20,
    width: "95%",
  },
  copywrite: {
    justifyContent: "flex-end",
    alignContent: "flex-end",
    fontSize: 14,
  },
  loginHeader: {
    alignItems: "flex-start",
    fontWeight: "bold",
    fontSize: 18,
  },
  leftAlign: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    marginLeft: 20,
    marginTop: 10,
  },
  buildVersion: {
    fontSize: 10,
    padding: 20,
  },
  parentPortalIcon: {
    paddingHorizontal: 5,
    paddingBottom: 5,
  }
});
