import { GraphQLResult } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import { ListClassesBySchoolIDQueryVariables } from "../../../common/API";

export const listClassTeachersAndStudentsBySchoolIDQueryString = /* GraphQL */ `
    query listClassTeachersAndStudentsBySchoolID(
        $schoolID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelClassFilterInput
        $nextToken: String
    ) {
        listClassesBySchoolID(
            schoolID: $schoolID
            sortDirection: $sortDirection
            filter: $filter
            limit: 5000
            nextToken: $nextToken
        ) {
            items {
                id
                name
                _deleted
                teachers (limit: 5000) {
                    items {
                        _deleted
                        teacherID
                    }
                }
                students (limit: 5000) {
                    items {
                        _deleted
                        studentID
                    }
                }
            }
        }
    }
`;

export type ListClassTeachersAndStudentsBySchoolIDQuery = {
    listClassesBySchoolID?: {
        __typename: "ModelClassConnection",
        items: ListClassTeachersAndStudentsBySchoolIDQueryItem[]
    }
}

export type ListClassTeachersAndStudentsBySchoolIDQueryVariables = {
    schoolID: string
}

export type ListClassTeachersAndStudentsBySchoolIDQueryItem = {
    id: string,
    name: string,
    _deleted: null | boolean,
    teachers: {
        items: {
            teacherID: string,
            _deleted: null | boolean,
        }[]
    },
    students: {
        items: {
            studentID: string,
            _deleted: null | boolean,
        }[]
    }
}

export const listClassTeachersAndStudentsBySchoolID = async (schoolID: string) =>
    (await API.graphql(graphqlOperation(
        listClassTeachersAndStudentsBySchoolIDQueryString,
        {
            schoolID,
            filter: {
                archived: {
                    ne: true
                },
                _deleted: {
                    ne: true
                }
            }
        } as ListClassesBySchoolIDQueryVariables
    )) as GraphQLResult<ListClassTeachersAndStudentsBySchoolIDQuery>).data?.listClassesBySchoolID?.items.filter(i => i._deleted !== true) || []