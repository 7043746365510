import { useReactiveVar } from '@apollo/client'
import { StyleSheet, View } from 'react-native'
import { rvFilterSwitchesData } from './common-state'
import { DecidaColors } from '../../common/decida-colors'
import { SwitchCardFilterType } from './switch-card-filter'
import SwitchCardFilterGroupByType from './switch-card-filter-group'
import SwitchCardFilterGroupTitle from './switch-card-filter-group-title'
import { SwitchCardFilterMethods } from './switch-card-filter-methods'
import { CardActivitySheetsEmotionFilter, CardActivitySheetsThoughtActionFilter, CardActivitySheetsTimeFilter } from './worksheets-imports'

const SwitchCardFilterExploreActivitySheets = () => {
    const filters: SwitchCardFilterType = useReactiveVar(rvFilterSwitchesData)

    const setFilters = (data: SwitchCardFilterType) => {
        rvFilterSwitchesData(data)
    }

    return (
        <View>
            <View style={styles.content}>
                <SwitchCardFilterGroupTitle onSelectAll={() => SwitchCardFilterMethods.selectAllParameterFilters(filters, "video")} title='Emotion' filterType="activity_sheets" />
                <SwitchCardFilterGroupByType label="" group={CardActivitySheetsEmotionFilter} filters={filters} setFilters={setFilters} groupType="emotion" />
            </View>
            <View style={styles.content}>
                <SwitchCardFilterGroupTitle onSelectAll={() => SwitchCardFilterMethods.selectAllParameterFilters(filters, "video")} title='Duration (minutes)' filterType="activity_sheets" />
                <SwitchCardFilterGroupByType label="" group={CardActivitySheetsTimeFilter} filters={filters} setFilters={setFilters} groupType="time" />
            </View>
            <View style={styles.content}>
            <SwitchCardFilterGroupTitle onSelectAll={() => SwitchCardFilterMethods.selectAllParameterFilters(filters, "video")} title='Thought / Action' filterType="activity_sheets" />
                <SwitchCardFilterGroupByType label="" group={CardActivitySheetsThoughtActionFilter} filters={filters} setFilters={setFilters} groupType="thoughtOrAction" />
            </View>
        </View>
    )
}
export default SwitchCardFilterExploreActivitySheets
const styles = StyleSheet.create({
    content: {
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: DecidaColors.Gray
    },
})