import { useReactiveVar } from '@apollo/client'
import { rvUserGroup } from '../login/login-state'
import { UserGroup } from '../../common/constants'

type Props = {
    [k in UserGroup]?: JSX.Element
}
export const GroupSwitch = (props: Props) => {
    const userGroup = useReactiveVar(rvUserGroup)
    console.log({ userGroup })

    switch (userGroup) {
        case UserGroup.Admin:
            return props.Admin || null
        case UserGroup.Student:
            return props.Student || null
        case UserGroup.Support:
            return props.Support || null
        case UserGroup.Teacher:
            return props.Teacher || null
        case UserGroup.ClassLogin:
            return props.ClassLogin || null
        case UserGroup.SchoolAdmin:
            return props.SchoolAdmin || null
        case UserGroup.NonTeachingStaff:
            return props.NonTeachingStaff || null
        default:
            return null
    }
}