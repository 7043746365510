import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { TheDoOverHeader } from './src/the-do-over-header'
import { TheDoOverIcon } from './src/the-do-over-icon'
import { TheDoOverImage1 } from './src/the-do-over-image1'

export const SwitchesEmotionsAngryTheDoOver: CardTemplate = {
    cardId: "fae38895-be03-4569-a243-38f9306c9d5d",
    title: 'The do over',
    Header: TheDoOverHeader,
    Icon: TheDoOverIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Getting better at having difficult conversations."} />
        </>
    ),
    Images: () => (
        <>
            <TheDoOverImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Take a few moments to think about a conversation that didn’t go very well, that probably ended in an argument."} />
                <DefaulttextWithRightArrow textdata={"Why didn’t it go well?"} />
                <DefaulttextWithRightArrow textdata={"Think about some things you might do differently if you had the chance to ‘Do-Over’ the conversation."} />
                <DefaulttextWithRightArrow textdata={"Go back and ask the person for a do-over of the conversation (ensuring they know that you promise it won’t turn into an argument again)."} />
                <DefaulttextWithRightArrow textdata={"If you feel yourself starting to argue again, pause the conversation, reflect on why and how you could do things differently, then ask for another ‘do-over’ till you get it right."} />
                <DefaulttextWithRightArrow textdata={"Once you get it right, take note of why you think it worked better with that different approach."} />
            </View>
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='When a conversation ends up in an argument, it usually means the problem isn’t resolved and you may have contributed to the non-constructive nature of the conversation in some way. Once you realise this, the temptation is to get swallowed up in self-pity, or to try and justify why you did what you did instead of addressing it head on asking for “The Do-Over". ' />
            <DefaulTextWithTipsIcon textdata='If you are having trouble thinking of different things to do in the Do-Over conversation, consider the following questions:' />
            <View style={styles.tipsIndent}>
                <DefaulTextWithTipsIcon textdata='What words can you use that will be less inflammatory?' />
                <DefaulTextWithTipsIcon textdata='What new perspectives or whose point of view can you better consider?' />
                <DefaulTextWithTipsIcon textdata='When might be a better time to have the do-over conversation, and what could your opening question be?' />
            </View>

        </>

    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: `Conflict is a normal and inevitable part of any relationship, whether it's between friends, family members, or colleagues. While some conflict can be healthy and even helpful in bringing about positive change, unresolved conflict can create tension, stress, and strain on relationships.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify underlying issues that cause conflict, especially arguments in relationships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• List the steps required to address an unresolved issue with a focus on regulating emotion.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Demonstrate self awareness and empathy.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin with a brief discussion about the nature of arguments. Ask students to reflect on a recent argument they had and consider what caused it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Share a personal example, such as, "I had an argument with my best friend because they shared a secret, and I felt betrayed." Emphasise that the root cause is often deeper than what is said during the argument.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to raise their hands if the reason for their argument falls into common categories:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Didn’t feel understood</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Said or did something wrong (hurt, betrayed, disrespected)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Feel unappreciated or excluded</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Feel jealous or competitive</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Have different values or interests</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss what happens after an argument and how it makes them feel. Look for feelings of resentment, stress, sleeplessness, etc.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that these after effects are common in unresolved arguments and introduce the idea of a "do over" as a strategy to address them.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Define an argument as "an exchange of diverging or opposite views, typically in a heated or angry tone."</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss why arguments happen and the impact they can have on individuals and relationships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of a "do over" and how it can help in resolving arguments more constructively.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer students to the Switch4Schools app and guide them through the listed steps of the To Do Over Switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of reflection on how they could have handled the situation differently.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the steps of asking for a do over and the significance of pausing the conversation if it starts escalating.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Brainstorm with students ways to reduce the risk of conversations escalating to arguments.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ List strategies on the board, such as being fair and respectful, understanding the other person's point of view, active listening, expressing feelings, and offering apologies.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Individual Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Do Over Worksheet and ask students to reflect on a recent argument.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct them to construct a do over plan for regulating their anger and ensuring a more constructive conversation.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their strategies from the Do Over Worksheet (if comfortable).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set a challenge for students to complete a do over within a specific time frame (e.g., a week).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the idea that some unresolved issues may need more than one do over, especially if the relationship is worth saving.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to reflect on their do over challenge and, if possible, share their experiences in the next class.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Writing materials</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='The Do-Over Worksheet' link={Worksheets.theDoOver.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Anger tips to resolve agruments - </DefaultText>
                        <DefaultTextWithLink text='https://www.betterhealth.vic.gov.au/health/healthyliving/anger-tips-to-resolve-arguments' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to Handle a Fright With a Friend - </DefaultText>
                        <DefaultTextWithLink text='https://lessonbee.com/blog/how-to-handle-a-fight-with-friends' />
                    </>}
            />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/The+Do-Over+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual, CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action, CardActionType.Thought],
    level: [CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Angry]
}

const styles = StyleSheet.create({
    tipsIndent: {
        paddingLeft: 60
    }
})
