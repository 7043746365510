import { gql, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Ionicons } from '@expo/vector-icons';
import { StyleSheet, View } from 'react-native';
import { CreateStudentClassMutation, CreateStudentClassMutationVariables, CustomCreateTeacherClassMutation, CustomCreateTeacherClassMutationVariables, GetClassQuery, GetClassQueryVariables, GetStudentClassByClassIdQuery, GetStudentClassByStudentIdQuery, GetStudentClassByStudentIdQueryVariables, ListTeacherClassesByTeacherIDQuery, ListTeacherClassesByTeacherIDQueryVariables, Student, StudentClassApprovalStatus, Teacher } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { createStudentClass, customCreateTeacherClass } from '../../common/graphql/mutations';
import { getClass, getStudentClassByStudentId, listTeacherClassesByTeacherID } from '../../common/graphql/queries';
import { DefaultText } from '../common/default-text';
import { rvIsLoading } from '../common/loading';
import StudentSearchCardInfo from '../common/student-search-card-info';
import { showAlert } from '../common/universal-alert';
import { rvShowTeacherForm, rvSidebarClassID } from './school-admin-state';
import { schoolAdminGetClassQuery, SchoolAdminGetClassQueryResponse } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-class-query';
import { listStudentClassAndStudentDataByClassIdCustomQuery } from '../teacher/teacher-graphql-scripts';
import { mutateStudentClassRefetchQueries } from '../admin/graphql-scripts';

export enum ResultItemType {
    StudentSearch,
    TeacherSearch
}
type Props = {
    user: Teacher | Student
    type: ResultItemType
}

const SchoolAdminSidebarSearchResultItem = ({ user, type }: Props) => {

    const currentSidebarClassID = useReactiveVar(rvSidebarClassID)

    const { data } = useQuery<SchoolAdminGetClassQueryResponse, GetClassQueryVariables>(schoolAdminGetClassQuery, { variables: { id: currentSidebarClassID }, skip: !currentSidebarClassID })
    const currentClass = data?.getClass

    const [listTeacherClassesQuery] = useLazyQuery<ListTeacherClassesByTeacherIDQuery, ListTeacherClassesByTeacherIDQueryVariables>(gql`${listTeacherClassesByTeacherID}`,)
    const [getStudentClassByStudentIdQuery] = useLazyQuery<GetStudentClassByStudentIdQuery, GetStudentClassByStudentIdQueryVariables>(gql`${getStudentClassByStudentId}`, { fetchPolicy: "network-only"})
    const [createTeacherClassMutation] = useMutation<CustomCreateTeacherClassMutation, CustomCreateTeacherClassMutationVariables>(gql`${customCreateTeacherClass}`,)
    const [createStudentClassMutation] = useMutation<CreateStudentClassMutation, CreateStudentClassMutationVariables>(gql`${createStudentClass}`)


    const handleAddUserToClass = async () => {

        if (type === ResultItemType.TeacherSearch) {
            addTeacherToClass()
            return
        }

        addStudentToClass()
    }

    const addStudentToClass = async () => {
        const { data } = await getStudentClassByStudentIdQuery({ variables: { studentID: user.id, }})
        const queryResults = data?.getStudentClassByStudentId?.items.filter((item) => item?._deleted !== true) || []
        const studentAssignedToClass = queryResults.some((item) => item?.classID === currentSidebarClassID)

        if (!studentAssignedToClass) {
            rvIsLoading(true)
            await createStudentClassMutation({
                variables: {
                    input: {
                        studentID: user.id,
                        classID: currentSidebarClassID,
                        status: StudentClassApprovalStatus.APPROVED
                    }
                }
            })
            rvIsLoading(false)
        } else {
            showAlert({
                title: `Student is already assigned to this ${currentClass?.goMode === true ? 'GO group' : 'class'}.`,
                message: `${user.firstName} ${user.lastName} is already assigned to this class`,
                leftButtonText: 'Ok',
            })
        }

    }

    const addTeacherToClass = async () => {
        const { data: teacherClasses, error } = await listTeacherClassesQuery({ variables: { teacherID: user.id } })

        if (error !== undefined) {
            throw new Error(error.message)
        }

        const teacherIsAssignedToClass = teacherClasses?.listTeacherClassesByTeacherID?.items.find((tc) => tc?.classID === currentSidebarClassID && tc._deleted !== true)

        if (!teacherIsAssignedToClass) {
            rvIsLoading(true)
            await createTeacherClassMutation({
                variables: {
                    input: {
                        classID: currentSidebarClassID,
                        teacherID: user.id
                    }
                }
            })
            rvShowTeacherForm(false)

        } else {
            showAlert({
                title: "Add to class",
                message: `${user.firstName} ${user.lastName} is already assigned to this class`,
                leftButtonText: "Ok"
            })
        }
        rvIsLoading(false)
    }

    if (type === ResultItemType.StudentSearch) {
        return <StudentSearchCardInfo fontSize='small' student={user as Student} onAddStudent={addStudentToClass} />
    }

    return (
        <View style={styles.container}>
            <View style={{ flex: 1 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>

                    <DefaultText style={styles.fullName}>{user?.firstName} {user?.lastName}</DefaultText>
                </View>
                {type === ResultItemType.TeacherSearch && (
                    <DefaultText style={{ fontSize: 14, color: DecidaColors.Blue }}>({user?.email})</DefaultText>
                )}

            </View>
            <View>
                <Ionicons onPress={handleAddUserToClass} name="add-circle" size={22} color={DecidaColors.GrayText} />
            </View>
        </View>
    )
}
export default SchoolAdminSidebarSearchResultItem
const styles = StyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'row',
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: DecidaColors.GrayBorder,
    },
    fullName: {
        fontSize: 16,
        color: DecidaColors.AppleSystemGray2Dark,
    },

})