import { useReactiveVar } from '@apollo/client'
import { ScrollView, StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import SchoolAdminClassDetailsSidebar from './school-admin-class-details-sidebar'
import SchoolAdmiGroupsAndClassList from './school-admin-groups-and-class-list'
import SchoolAdminHierarchySchoolAdminsList from './school-admin-hierarchy-school-admins-list'
import SchoolAdminHierarchyStaffsList from './school-admin-hierarchy-staffs-list'
import SchoolAdminHierarchyTeachersList from './school-admin-hierarchy-teachers-list'
import SchoolAdminSidebar from './school-admin-sidebar'
import { rvSchoolAdminCampusCurrentListView, rvSidebarClassID } from './school-admin-state'
import { SchoolAdminHierarchyCurrentListView } from './school-admin-types'

const SchoolAdmiCampusesDashboard = () => {
    const classDetails = useReactiveVar(rvSidebarClassID)
    const schoolAdminCurrentListView = useReactiveVar(rvSchoolAdminCampusCurrentListView)

    return (
        <View style={{ flexDirection: 'row', width: '100%', borderTopWidth: 1, borderColor: DecidaColors.GrayBorder, height: '100%' }}>
            <SchoolAdminSidebar />
            <ScrollView>
                {schoolAdminCurrentListView === SchoolAdminHierarchyCurrentListView.Campus ? (
                    <SchoolAdmiGroupsAndClassList />
                ) : schoolAdminCurrentListView === SchoolAdminHierarchyCurrentListView.SchoolAdmins ? (
                    <SchoolAdminHierarchySchoolAdminsList />
                ) : schoolAdminCurrentListView === SchoolAdminHierarchyCurrentListView.Teachers ? (
                    <SchoolAdminHierarchyTeachersList />
                ) : <SchoolAdminHierarchyStaffsList />}
            </ScrollView>

            {classDetails && (
                <SchoolAdminClassDetailsSidebar />
            )}

        </View>
    )
}
export default SchoolAdmiCampusesDashboard
const styles = StyleSheet.create({})