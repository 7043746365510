import { Text, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles, windowWidth } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { DrawBreathIcon } from '../../explore/src/draw-breath-icon'
import { DrawBreathIconInverted } from '../../explore/src/draw-breath-icon-inverted'
import { BreathDrawSideImage1 } from './src/breath-draw-side-image1'
import { BreathDrawSideImage2 } from './src/breath-draw-side-image2'
import { DrawBreathLessonPlanImage1 } from './src/drawbreath-lessonplan-image1'
import { DrawBreathLessonPlanImage2 } from './src/drawbreath-lessonplan-image2'
import { DrawBreathLessonPlanImage3 } from './src/drawbreath-lessonplan-image3'
import { Worksheets } from '../../../common/worksheets-imports'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DecidaColors } from '../../../../common/decida-colors'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'

export const SwitchesEmotionsAngryDrawBreath: CardTemplate = {
    cardId: "ea42b83c-f0f8-485b-a5f7-5bb2496d4358",
    title: 'Breath draw',
    Header: DrawBreathIconInverted,
    Icon: DrawBreathIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Quickly calming the mind and focussing attention internally."} />
        </>
    ),
    Images: () => (
        <>
            <BreathDrawSideImage1 />
            {windowWidth ?
                <></>
                : <BreathDrawSideImage2 />}

        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Get a blank piece of paper and a pencil."} />
                <DefaulttextWithRightArrow textdata={"Place the pencil on the paper and take a moment to notice your breathing."} />
                <DefaulttextWithRightArrow textdata={"Visualise your breath as a line and represent this on the paper."} />
                <DefaulttextWithRightArrow textdata={"Spend a few minutes playing with different types of lines as you notice your breath (try altering your breathing a bit faster or a bit slower and then notice how this changes your lines on the page)."} />
                <DefaulttextWithRightArrow textdata={"Try changing the quality of your lines and notice how you could change your breath."} />
                <View style={commonCardStyles.imagecontainer}>
                    <View style={commonCardStyles.image}>

                    </View>
                </View>
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: 'Drawing your breath is a tactile way of redirecting attention from the external thing that is frustrating, and turn your focus inward. When dealing with anger and stress, science has shown that turning your focus inward is a great way to calm your mind, reduce the flow of ‘fear’ chemicals that flood the brain, and regain control over your thoughts and actions.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Apply the process of mental visualisation to perform a controlled breathing meditative exercise to manage angry thoughts.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice using a creative outlet for managing frustration. </DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by discussing the concept of anger with the students. Ask them to share times when they have felt angry and to what degree (a little bit angry to extremely angry).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the Breath Draw switch and explain that it is a tool to help them manage their anger.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Exploring Anger Triggers (7 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Breath Draw worksheet to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to take a moment to think about times when they were angry and fill in the worksheet with examples of things that triggered their anger.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Body Response to Anger (8 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss as a class what happens to their bodies when they are angry. How does it feel? Mention the "fight or flight" response and the release of stress hormones.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that the purpose of the Breath Draw activity is to provide an outlet for the energy that comes with anger and to create focus to calm down.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Breath Draw Technique (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Breath Draw switch in the Switch4Schools app (if available). Alternatively, explain the concept using the whiteboard.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to grab a blank piece of paper and their favourite coloured pen or pencil.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to sit quietly, pay attention to their breathing, and draw lines that represent what their breath looks like. Encourage creativity and emphasise that there are no rules.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Breath Draw:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students draw for a minute with their normal breathing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to slow down their breathing and observe how it changes their drawing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students try drawing in a different way and notice how it affects their breathing.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 4: Sharing and Reflection (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their drawings and observations from the Breath Draw activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how altering their breathing patterns influenced their drawings and how the activity made them feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the connection between mindful breathing and managing emotions, specifically anger.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points about recognising and managing anger through Breath Draw.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the idea that taking a moment to focus on breathing can help calm down the "fight or flight" response.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to use the Breath Draw technique whenever they feel angry or need a moment to relax.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own device, ask them to find the switch in the Switch4Schools app.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Breath Draw worksheet' link={Worksheets.breathDraw.uri} /> </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Blank drawing paper </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Coloured pencils or markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Switch4Schools app (optional) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard and markers </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Timer or clock</DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Angry breathing: a prospective study of hostility and lung function in the Normative Aging Study -  </DefaultText>
                        <DefaultTextWithLink text='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2104760/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Effects of art therapy on anger and self-esteem in aggressive children -  </DefaultText>
                        <DefaultTextWithLink text='https://www.sciencedirect.com/science/article/pii/S1877042814000172/pdfft?md5=0c9da4d2fd4f69724df60d8f69cc71d9&pid=1-s2.0-S1877042814000172-main.pdf' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Breath draw pdf</DefaultText>
                        <DefaultTextWithLink text='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Breath+Draw+-+Switch+in+focus.pdf' />
                    </>
                }
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Have access to a variety of colours and textures to nuance drawing based on feelings. Let go of inhibition - let your inner artist shine!' />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Breath+Draw.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Angry]
}

