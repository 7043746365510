import { StyleSheet } from 'react-native'
import { DefaultText } from '../common/default-text'

interface Props {
    children: string
}
export const PasswordHintText = ({ children }: Props) =>
    <DefaultText style={styles.hintText}>{children}</DefaultText>

const styles = StyleSheet.create({
    hintText: {
        width: 330,
    },
})