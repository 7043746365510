import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { API } from 'aws-amplify';
import moment from 'moment';
import { useEffect } from 'react';
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { GetClassQuery, GetClassQueryVariables, GetSchoolQuery, GetSchoolQueryVariables, ListScheduleClassByClassIdQuery, ListScheduleClassByClassIdQueryVariables } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { getClass, getSchool, listScheduleClassByClassId } from '../../common/graphql/queries';
import { BackButton } from '../common/back-button';
import { rvCurrentClassSchedule } from '../common/common-state';
import { DefaultFontSize, ScheduleType, SchedulerPicker } from '../common/const';
import { DefaultSwitch } from '../common/default-switch';
import { DefaultText } from '../common/default-text';
import DropdownPickerTime from '../common/dropdown-picker-time';
import { getApiHeaders } from '../common/get-api-header';
import { rvIsLoading } from '../common/loading';
import { Page } from '../common/page';
import { PageTitleText } from '../common/page-title-text';
import { ScreenNames } from '../common/screen-names';
import { SendEmailScheduler } from '../common/send-email';
import { sessionOverlap } from '../common/session-overlap';
import { showAlert } from '../common/universal-alert';
import { IconPlus } from '../icon/icon-plus';
import { TeacherClassNavigatorParamList, TeacherDrawerNavigatorPamarList } from './teacher-route-param-types';
import { AdminClassNavigatorParamList } from '../admin/admin-route-param-types';
import { rvUserGroup } from '../login/login-state';
import { rvSidebarClassID } from '../school-admin/school-admin-state';
import { UserGroup } from '../../common/constants';

export const TeacherClassAdminSchedule = () => {
    const userGroup = useReactiveVar(rvUserGroup)
    const currentClassSchedule = useReactiveVar(rvCurrentClassSchedule)
    const weekDays = moment.weekdays();
    // const [school, setSchool] = useState<School | null>(null)
    let initialState: any = {}
    const { navigate } = useNavigation<NavigationProp<TeacherClassNavigatorParamList & AdminClassNavigatorParamList, "TeacherClassSchedule"> & NavigationProp<TeacherDrawerNavigatorPamarList, "TeacherClassMain">>()
    const { params } = useRoute<RouteProp<TeacherClassNavigatorParamList, "TeacherClassAdmin">>() || {}

    const currentClassID = useReactiveVar(rvSidebarClassID) || params?.classID

    const { data: classResponse } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: currentClassID || "" }, skip: !currentClassID })
    const currentClass = classResponse?.getClass

    const { data: schoolResponse } = useQuery<GetSchoolQuery, GetSchoolQueryVariables>(gql`${getSchool}`, { variables: { id: currentClass?.schoolID || "" }, skip: !currentClass })
    const school = schoolResponse?.getSchool

    const { data: scheduleClassResponse } = useQuery<ListScheduleClassByClassIdQuery, ListScheduleClassByClassIdQueryVariables>(gql`${listScheduleClassByClassId}`, { variables: { classID: currentClassID, limit: 5000 }, skip: !currentClassID })

    const schoolTimezone = school?.timezone ? moment.tz(school?.timezone).format('Z') : ""

    // const [schedulerTime, setSchedulerTime] = useState<Props>(initialState)

    const newSession: ScheduleType = {
        openAt: "",
        closeAt: "",
        isError: false
    }

    const getInitialStateData = () => {
        weekDays.forEach((day) => {
            const dayKey = `${day.toLowerCase()}`
            const schedulerData = {
                sessions: [{ ...newSession }],
                isActive: false,
            }

            initialState[dayKey] = schedulerData
        })
        rvCurrentClassSchedule(initialState)
    }

    const setClassScheduleData = async () => {

        if (scheduleClassResponse?.listScheduleClassByClassId?.items?.length) {
            rvCurrentClassSchedule(JSON.parse(scheduleClassResponse?.listScheduleClassByClassId?.items[0]?.schedule || ''))
            return
        }

        getInitialStateData()
    }

    useEffect(() => {
        if (scheduleClassResponse) {
            setClassScheduleData()
        }
    }, [scheduleClassResponse])

    const handleScheduleSwitchChanges = (value: boolean, key: string) => {
        const formattedKeys = `${key.toLowerCase()}`
        const newScheduler = { ...currentClassSchedule }
        if (newScheduler[formattedKeys]) {
            const currentSession = newScheduler[formattedKeys].sessions || []

            if (!currentSession.length) {
                delete newScheduler[formattedKeys]
                const newSchedule = {
                    isActive: true,
                    sessions: [newSession]
                }
                newScheduler[formattedKeys] = newSchedule
            } else {
                newScheduler[formattedKeys].isActive = !newScheduler[formattedKeys].isActive
            }
        } else {
            const newSchedule = {
                isActive: true,
                sessions: [newSession]
            }
            newScheduler[formattedKeys] = newSchedule
        }
        rvCurrentClassSchedule(newScheduler)
    }

    const handlePickerChange = (value: string, key: string, index: number, openOrClose: SchedulerPicker) => {
        if (!school?.timezone) {
            showAlert({
                title: 'Feature not available yet',
                message: SupportTextComp,
                leftButtonText: 'Ok',
            })
            return
        }

        if (value === '0') {
            return
        }

        const isOpen = openOrClose === SchedulerPicker.OpenAt
        const formattedKeys = `${key.toLowerCase()}`
        const newScheduler = { ...currentClassSchedule }
        const currentSessionData = newScheduler[formattedKeys].sessions[index]

        const allSessions = newScheduler[formattedKeys].sessions
        const isOverlap = sessionOverlap(allSessions, value, index, openOrClose, currentSessionData.openAt, currentSessionData.closeAt) || false


        const updatedData = {
            ...currentSessionData,
            [openOrClose]: value,
            isError: isOverlap,
            errorMessage: isOverlap ? 'Selected time should not overlap other sessions.' : ''
        }

        newScheduler[formattedKeys].sessions[index] = updatedData
        rvCurrentClassSchedule(newScheduler)

        if (!isOverlap) {
            handleSaveData()
        }
    }

    const validateOpenAndClosingTime = (openTime: string, closeTime: string) => {
        if (!openTime || !closeTime) {
            return { status: false, error: "Please select open and closing times, or clear to progress." }
        }

        let arr = [openTime, closeTime]
        let calcArray = []

        for (const time of arr) {
            if (time) {
                const timeSplit = time.split(/:| /)
                const ampm = timeSplit[2]
                const hour = timeSplit[0]
                const minute = timeSplit[1]
                const hour24Format =
                    hour === '12' && ampm === 'PM'
                        ? Number(hour)
                        : hour === '12'
                            ? Number(0)
                            : ampm === 'PM'
                                ? Number(hour) + 12
                                : Number(hour);
                const inMinutes = hour24Format * 60 + Number(minute)
                calcArray.push(inMinutes)
            }
        }

        if (!calcArray.length) {
            return { status: false, error: "Please select open and closing times, or clear to progress." }
        }

        if (calcArray.length === 1) {
            return { status: true, error: "" }
        }

        const isValid = calcArray[0] < calcArray[1]
        return { status: isValid, error: isValid ? "" : "The closing time needs to be after the opening time." }

    }

    const SupportTextComp = (
        <DefaultText style={[styles.message, { alignItems: 'center', justifyContent: 'center' }]}>
            Please contact

            <DefaultText style={[styles.message, { color: DecidaColors.DarkBlue }]} onPress={SendEmailScheduler}>
                {" "}support{" "}
            </DefaultText>
            to enable this feature
        </DefaultText>
    )
    const handleSaveData = async () => {
        const newSchedulerTime = { ...currentClassSchedule }
        const payload = newSchedulerTime


        for (const key in newSchedulerTime) {
            const isActive = currentClassSchedule[key].isActive
            const allSessions = currentClassSchedule[key].sessions
            for (const session of allSessions) {
                if (session) {
                    if (!isActive) {
                        // if class is not active
                        // removed it from payload
                        delete payload[key]
                        continue
                    }

                    session.isError = false
                    session.errorMessage = ""
                    const { status, error } = validateOpenAndClosingTime(session.openAt || "", session.closeAt || "")

                    if (!status) {
                        session.isError = true
                        session.errorMessage = error || ""
                        rvCurrentClassSchedule(newSchedulerTime)
                        return
                    }

                    if (isActive) {
                        if (
                            // if one of the open or closes is field but not the other ones
                            !session.openAt || !session.openAt && session.closeAt ||
                            // close at time is earlier than open at time
                            session.openAt && !status
                        ) {
                            session.isError = true
                            rvCurrentClassSchedule(newSchedulerTime)
                            return
                        }

                        session.isError = false
                        session.errorMessage = ""
                        rvCurrentClassSchedule(newSchedulerTime)
                    }
                }
            }
        }

        rvIsLoading(true)
        try {
            await API.post(
                'AdminQueries',
                '/createClassSchedule',
                {
                    headers: await getApiHeaders(),
                    body: {
                        classID: currentClass?.id,
                        schedule: JSON.stringify(payload)
                    },
                }
            )
        } catch (error) {
            console.log('error', error)
        } finally {
            rvIsLoading(false)
        }

    }

    const handleClearSession = (key: string, index: number) => () => {
        const day = key.toLowerCase()
        const newSchedulerTime = { ...currentClassSchedule }
        newSchedulerTime[day].sessions.splice(index, 1)
        if (!newSchedulerTime[day].sessions.length) {
            newSchedulerTime[day].isActive = false
        }
        rvCurrentClassSchedule(newSchedulerTime)
        handleSaveData()
    }

    const handleAddSession = (key: string) => () => {
        const day = key.toLowerCase()
        const newSchedulerTime = { ...currentClassSchedule }
        newSchedulerTime[day].sessions.push(newSession)
        rvCurrentClassSchedule(newSchedulerTime)
    }

    const navigateToClassAdmin = () => {
        if (userGroup === "Admin") {
            navigate(ScreenNames.TeacherClassAdmin, { classID: currentClassID })
            return
        }
        navigate(ScreenNames.TeacherClassMain, { screen: ScreenNames.TeacherClassAdmin, params: { classID: currentClassID || "" } })
    }

    const isSchoolAdmin = userGroup === UserGroup.SchoolAdmin

    const showBackButton = userGroup === UserGroup.Teacher || userGroup === UserGroup.Admin
    return (
        <Page style={styles.page}>
            {showBackButton && (
                <View style={styles.backButtonContainer}>
                    <BackButton onPress={navigateToClassAdmin} />
                </View>
            )}
            <ScrollView>
                {userGroup === UserGroup.Teacher && (
                    <PageTitleText>Class check-in Scheduler</PageTitleText>
                )}
                {school && (
                    <>
                        <DefaultText style={isSchoolAdmin ? styles.schoolAdminText : { alignSelf: 'center' }}>
                            School timezone: GMT {schoolTimezone}
                        </DefaultText>
                    </>
                )}

                {weekDays.map((day, index) => {
                    const dayKey = currentClassSchedule[`${day.toLowerCase()}`]
                    const isActive = dayKey?.isActive

                    return (
                        <View key={String(day)} >
                            <View style={styles.listContainer}>
                                <DefaultText style={isSchoolAdmin ? styles.schoolAdminText : { marginBottom: 20 }}>{day}</DefaultText>
                                <DefaultSwitch value={isActive} onChange={(val) => handleScheduleSwitchChanges(val, day)} />
                            </View>
                            {isActive && (


                                dayKey.sessions.map((session, index) => {
                                    const sessionOpenTime = dayKey?.sessions[index].openAt || "Opens At"
                                    const sessionCloseTime = dayKey?.sessions[index].closeAt || "Close At"

                                    return (
                                        <View key={`${index}-${dayKey}`} style={styles.sessionContainer}>
                                            <View style={[styles.timerInputContainer, { borderWidth: session.isError ? 3 : 0, borderColor: DecidaColors.Red }]}>
                                                <DropdownPickerTime style={styles.timerInput} value={sessionOpenTime} setValue={(val) => handlePickerChange(val, day, index, SchedulerPicker.OpenAt)} />
                                                <Text style={styles.spacing}> - </Text>
                                                <DropdownPickerTime style={styles.timerInput} value={sessionCloseTime} setValue={(val) => handlePickerChange(val, day, index, SchedulerPicker.CloseAt)} openAtValue={sessionOpenTime} />
                                                {index === dayKey.sessions.length - 1 ? (
                                                    <>
                                                        <Pressable onPress={handleClearSession(day, index)} style={[styles.spacing, styles.clearButton, { backgroundColor: DecidaColors.Red }]}>
                                                            <Text style={styles.clearButtonText}> Clear </Text>
                                                        </Pressable>
                                                        <Pressable onPress={handleAddSession(day)} style={[styles.spacing, styles.addButton]}>
                                                            <IconPlus onPress={handleAddSession(day)} />
                                                        </Pressable>
                                                    </>
                                                ) : (
                                                    <Pressable onPress={handleClearSession(day, index)} style={[styles.spacing, styles.clearButton, { backgroundColor: DecidaColors.Red }]}>
                                                        <Text style={styles.clearButtonText}> Clear </Text>
                                                    </Pressable>
                                                )}
                                            </View>
                                            {session?.errorMessage !== "" && (
                                                <DefaultText style={styles.errorMessageText}>{session.errorMessage}</DefaultText>
                                            )}
                                        </View>
                                    )
                                })
                            )}
                        </View>
                    );
                })}
            </ScrollView>
        </Page>
    );
};

const avatarSize = 40;
const DefautSecondaryFontSize = DefaultFontSize - 6;

const styles = StyleSheet.create({
    schoolAdminText: {
        fontSize: 14,
        color: DecidaColors.AppleSystemGray2Dark,
        marginBottom: 20
    },
    page: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    listContainer: {
        maxWidth: '100%',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingBottom: 0,
        marginBottom: 0,
    },
    spacing: {
        paddingHorizontal: 10,
    },
    timerInput: {
        flex: 1,
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: DecidaColors.Gray,
    },
    timerInputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginBottom: 10,
    },
    sessionContainer: {
        marginBottom: 20,
        flex: 1,
        width: '100%'
    },
    clearButton: {
        backgroundColor: DecidaColors.Red,
        marginLeft: 5,
        borderRadius: 5,
        width: 60,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    addButton: {
        backgroundColor: DecidaColors.Green,
        marginLeft: 5,
        borderRadius: 5,
        width: 60,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    clearButtonText: {
        color: DecidaColors.White
    },
    message: {
        fontSize: 16,
        textAlign: 'center',
    },
    errorMessageText: {
        fontSize: 14,
        color: DecidaColors.Red
    },
    backButtonContainer: {
        width: '100%',
        alignItems: 'flex-start'
    }
});
