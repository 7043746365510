import { StackScreenProps } from "@react-navigation/stack";
import { AdminStackNavigatorParamList } from "../admin-route-param-types";
import { ScrollView, View, StyleSheet } from "react-native";
import { DecidaColors } from "../../../common/decida-colors";
import { BackButton } from "../../common/back-button";
import { Br } from "../../common/br";
import { DefaultButton } from "../../common/default-button";
import { DefaultText } from "../../common/default-text";
import { Message, ProcessingStatus, Sheet, StudentToCreate, StudentToUpdate, TeacherToCreate, TeacherToUpdate } from "./common-import-school-data-const-and-types";
import { Messages } from "./messages";
import { useRef, useState } from "react";
import { CreateSchoolAdminInput, UpdateSchoolAdminInput, CreateClassInput, CreateCampusInput, CreateClassGroupInput, CreateTeacherClassInput, GetTeacherByEmailQuery, GetTeacherByEmailQueryVariables, CreateCampusMutation, CreateCampusMutationVariables, CreateClassGroupMutation, CreateClassGroupMutationVariables, CreateClassMutation, CreateClassMutationVariables, UpdateTeacherMutation, UpdateTeacherMutationVariables, CreateTeacherClassMutation, CreateTeacherClassMutationVariables, CreateStudentClassInput, CreateStudentClassMutationVariables, CreateStudentClassMutation, UpdateStudentMutation, UpdateStudentMutationVariables, CustomCreateStudentMutation, CustomCreateStudentMutationVariables, CustomCreateTeacherMutation, CustomCreateTeacherMutationVariables } from "../../../common/API";
import * as DocumentPicker from 'expo-document-picker'
import { read } from 'xlsx'
import { validateHierarchyTeacherSheet } from "./validate-hierarchy-teacher-sheet";
import _ from "lodash";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { SchoolHierarchy, convertListSchoolHierarchyToSchoolHierarchy, listSchoolHierarchyQuery, listSchoolHierarchyQueryString, listSchoolHierarchyQueryVariables } from "./list-school-hierarchy";
import { getTeacherByEmail } from "../../../common/graphql/queries";
import { asapPromiseQueue } from "../../../common/asap-promise-queue";
import { createCampus, createClass, createClassGroup, createStudentClass, createTeacherClass, customCreateStudent, customCreateTeacher, updateStudent, updateTeacher } from "../../../common/graphql/mutations";
import { mutateCampusRefetchQueries, mutateClassGroupRefetchQueries, mutateClassRefetchQueries, mutateStudentClassRefetchQueries, mutateTeacherClassRefetchQueries, mutateTeacherRefetchQueries } from '../graphql-scripts'
import { validateHierarchyStudentSheet } from "./validate-hierarchy-student-sheet";

enum FileToProcess {
    Admin,
    Teacher,
    Student,
    Staff,
    None,
}

export const AdminSchoolImports = ({ navigation: { goBack }, route: { params: { schoolID } } }: StackScreenProps<AdminStackNavigatorParamList, 'AdminSchoolImports'>) => {
    const errors = useRef([] as Message[])
    const warnings = useRef([] as Message[])
    const info = useRef([] as Message[])
    const [fileToProccess, setFileToProccess] = useState(FileToProcess.None)
    const [processingStatus, setProcessingStatus] = useState(ProcessingStatus.NotProcessed)
    const [processingMessage, setProcessingMessage] = useState('')
    const schoolAdminToCreate = useRef([] as CreateSchoolAdminInput[])
    const schoolAdminToUpdate = useRef([] as UpdateSchoolAdminInput[])
    const campusesToCreate = useRef([] as CreateCampusInput[])
    const [createCampusMutation] = useMutation<CreateCampusMutation, CreateCampusMutationVariables>(gql`${createCampus}`, { refetchQueries: mutateCampusRefetchQueries })
    const classGroupsToCreate = useRef([] as CreateClassGroupInput[])
    const [createClassGroupMutation] = useMutation<CreateClassGroupMutation, CreateClassGroupMutationVariables>(gql`${createClassGroup}`, { refetchQueries: mutateClassGroupRefetchQueries })
    const classesToCreate = useRef([] as CreateClassInput[])
    const [createClassMutation] = useMutation<CreateClassMutation, CreateClassMutationVariables>(gql`${createClass}`, { refetchQueries: mutateClassRefetchQueries })
    const [createTeacherMutation] = useMutation<CustomCreateTeacherMutation, CustomCreateTeacherMutationVariables>(gql`${customCreateTeacher}`, { refetchQueries: mutateTeacherRefetchQueries })
    const teachersToCreate = useRef([] as TeacherToCreate[])
    const [updateTeacherMutation] = useMutation<UpdateTeacherMutation, UpdateTeacherMutationVariables>(gql`${updateTeacher}`, { refetchQueries: mutateTeacherRefetchQueries })
    const teachersToUpdate = useRef([] as TeacherToUpdate[])
    const [createTeacherClassMutation] = useMutation<CreateTeacherClassMutation, CreateTeacherClassMutationVariables>(gql`${createTeacherClass}`, { refetchQueries: mutateTeacherClassRefetchQueries })
    const teacherClassesToCreate = useRef([] as CreateTeacherClassInput[])
    const [customCreateStudentMutation] = useMutation<CustomCreateStudentMutation, CustomCreateStudentMutationVariables>(gql`${customCreateStudent}`, {})
    const studentsToCreate = useRef([] as StudentToCreate[])
    const [updateStudentMutation] = useMutation<UpdateStudentMutation, UpdateStudentMutationVariables>(gql`${updateStudent}`)
    const studentsToUpdate = useRef([] as StudentToUpdate[])
    const [createStudentClassMutation] = useMutation<CreateStudentClassMutation, CreateStudentClassMutationVariables>(gql`${createStudentClass}`, { refetchQueries: mutateStudentClassRefetchQueries })
    const studentClassesToCreate = useRef([] as CreateStudentClassInput[])
    const [getCurrentSchoolHierarchyQuery] = useLazyQuery<listSchoolHierarchyQuery, listSchoolHierarchyQueryVariables>(gql`${listSchoolHierarchyQueryString}`, { fetchPolicy: 'network-only' })
    const [getTeacherByEmailQuery] = useLazyQuery<GetTeacherByEmailQuery, GetTeacherByEmailQueryVariables>(gql`${getTeacherByEmail}`, { fetchPolicy: 'network-only' })
    const currentSchoolHierarchy = useRef({} as SchoolHierarchy)
    const processedAdminCount = useRef(0)
    const processedClassCount = useRef(0)
    const processedTeacherCount = useRef(0)
    const processedStudentCount = useRef(0)

    const resetData = async () => {
        errors.current = []
        warnings.current = []
        info.current = []
        setFileToProccess(FileToProcess.None)
        setProcessingStatus(ProcessingStatus.NotProcessed)
        setProcessingMessage('')
        schoolAdminToCreate.current = []
        schoolAdminToUpdate.current = []
        teachersToCreate.current = []
        teachersToUpdate.current = []
        teacherClassesToCreate.current = []
        campusesToCreate.current = []
        classGroupsToCreate.current = []
        classesToCreate.current = []
        studentsToCreate.current = []
        studentsToUpdate.current = []
        studentClassesToCreate.current = []
        processedAdminCount.current = 0
        processedClassCount.current = 0
        processedTeacherCount.current = 0
        processedStudentCount.current = 0;
        if (schoolID != null && schoolID !== '') {
            const { data } = await getCurrentSchoolHierarchyQuery({ variables: { schoolID } })
            currentSchoolHierarchy.current = convertListSchoolHierarchyToSchoolHierarchy(data)
        }
    }

    const handleImportSchoolAdminExcel = async () => {
        await resetData()

        if (!schoolID) {
            errors.current = [{
                sheet: Sheet.All,
                message: 'Invalid schoolID. Please log out, log back in, and try again.'
            }]
            return
        }

        let result = await DocumentPicker.getDocumentAsync({
            type: "text/xlsx",
            copyToCacheDirectory: true
        });

        if (result && !result.canceled && result.output?.length) {
            const xlsx = read(await result.output[0].arrayBuffer())
        }
    }

    const handleImportTeacherExcel = async () => {
        if (!schoolID) {
            errors.current = [{
                sheet: Sheet.All,
                message: 'Invalid schoolID. Please log out, log back in, and try again.'
            }]
            return
        }

        const resetDataPromise = resetData()

        let result = await DocumentPicker.getDocumentAsync({
            type: "text/xlsx",
            copyToCacheDirectory: true
        });

        if (result && !result.canceled && result.output?.length) {
            const xlsx = read(await result.output[0].arrayBuffer())

            const teacherSheet = xlsx.Sheets["Add Teachers"]

            await resetDataPromise

            await validateHierarchyTeacherSheet({
                sheet: teacherSheet,
                schoolID,
                errors,
                warnings,
                info,
                currentSchoolHierarchy,
                campusesToCreate,
                classGroupsToCreate,
                classesToCreate,
                teachersToCreate,
                teachersToUpdate,
                teacherClassesToCreate,
                getTeacherByEmailQuery,
                setProcessingMessage,
            })

            errors.current = _.sortBy(errors.current, ['sheet', 'row'])
            warnings.current = _.sortBy(warnings.current, ['sheet', 'row'])
            info.current = _.sortBy(info.current, ['sheet', 'row'])

            setFileToProccess(FileToProcess.Teacher)
        }
    }

    const handleImportStudentExcel = async () => {
        if (!schoolID) {
            errors.current = [{
                sheet: Sheet.All,
                message: 'Invalid schoolID. Please log out, log back in, and try again.'
            }]
            return
        }

        const resetDataPromise = resetData()

        let result = await DocumentPicker.getDocumentAsync({
            type: "text/xlsx",
            copyToCacheDirectory: true
        });

        if (result && !result.canceled && result.output?.length) {
            const xlsx = read(await result.output[0].arrayBuffer())

            const studentSheet = xlsx.Sheets["Add Students"]

            await resetDataPromise

            await validateHierarchyStudentSheet({
                sheet: studentSheet,
                schoolID,
                errors,
                warnings,
                info,
                currentSchoolHierarchy,
                studentsToCreate,
                studentsToUpdate,
                studentClassesToCreate,
                setProcessingMessage,
            })

            errors.current = _.sortBy(errors.current, ['sheet', 'row'])
            warnings.current = _.sortBy(warnings.current, ['sheet', 'row'])
            info.current = _.sortBy(info.current, ['sheet', 'row'])

            setFileToProccess(FileToProcess.Student)
        }
    }

    const handleImportStaffExcel = async () => {
        await resetData()

        if (!schoolID) {
            errors.current = [{
                sheet: Sheet.All,
                message: 'Invalid schoolID. Please log out, log back in, and try again.'
            }]
            return
        }

        let result = await DocumentPicker.getDocumentAsync({
            type: "text/xlsx",
            copyToCacheDirectory: true
        });

        if (result && !result.canceled && result.output?.length) {
            const xlsx = read(await result.output[0].arrayBuffer())
        }
    }

    const processExcelFile = async () => {
        try {
            setProcessingStatus(ProcessingStatus.Processing)
            if (fileToProccess === FileToProcess.Admin) {
            } else if (fileToProccess === FileToProcess.Teacher) {
                await asapPromiseQueue(campusesToCreate.current, async (campusToCreate, index) => {
                    setProcessingMessage(`create campus(es): ${index + 1}/${campusesToCreate.current.length}`)
                    await createCampusMutation({
                        variables: {
                            input: campusToCreate
                        }
                    })
                }, 20)

                await asapPromiseQueue(classGroupsToCreate.current, async (classGroupToCreate, index) => {
                    setProcessingMessage(`create class group(s): ${index + 1}/${classGroupsToCreate.current.length}`)
                    await createClassGroupMutation({
                        variables: {
                            input: classGroupToCreate
                        }
                    })
                }, 20)

                await asapPromiseQueue(classesToCreate.current, async (classToCreate, index) => {
                    setProcessingMessage(`create class(es): ${index + 1}/${classesToCreate.current.length}`)
                    await createClassMutation({
                        variables: {
                            input: {
                                ...classToCreate,
                                emotionIntensityLevel: classToCreate?.emotionIntensityLevel || 3
                            }
                        }
                    })
                }, 20)

                await asapPromiseQueue(teachersToCreate.current, async (teacherToCreate, index) => {
                    setProcessingMessage(`create teacher(s): ${index + 1}/${teachersToCreate.current.length}`)
                    await createTeacherMutation({
                        variables: {
                            input: teacherToCreate
                        }
                    })
                }, 10)

                await asapPromiseQueue(teachersToUpdate.current, async (teacherToUpdate, index) => {
                    setProcessingMessage(`update teacher(s): ${index + 1}/${teachersToUpdate.current.length}`)
                    await updateTeacherMutation({
                        variables: {
                            input: teacherToUpdate
                        }
                    })
                }, 20)

                await asapPromiseQueue(teacherClassesToCreate.current, async (teacherClassToCreate, index) => {
                    setProcessingMessage(`assign teacher to class(es): ${index + 1}/${teacherClassesToCreate.current.length}`)
                    await createTeacherClassMutation({
                        variables: {
                            input: teacherClassToCreate
                        }
                    })
                }, 20)
            } else if (fileToProccess === FileToProcess.Student) {
                await asapPromiseQueue(studentsToCreate.current, async (studentToCreate, index) => {
                    setProcessingMessage(`create student(s): ${index + 1}/${studentsToCreate.current.length}`)
                    await customCreateStudentMutation({
                        variables: {
                            input: studentToCreate
                        }
                    })
                }, 10)

                await asapPromiseQueue(studentsToUpdate.current, async (studentToUpdate, index) => {
                    setProcessingMessage(`update student(s): ${index + 1}/${studentsToUpdate.current.length}`)
                    await updateStudentMutation({
                        variables: {
                            input: studentToUpdate
                        }
                    })
                }, 20)

                await asapPromiseQueue(studentClassesToCreate.current, async (studentClassToCreate, index) => {
                    setProcessingMessage(`assign student to class(es): ${index + 1}/${studentClassesToCreate.current.length}`)
                    await createStudentClassMutation({
                        variables: {
                            input: studentClassToCreate
                        }
                    })
                }, 20)
            } else if (fileToProccess === FileToProcess.Staff) {
            }
        } catch (e) {
            alert(e)
        }
        setProcessingMessage('done')
        setProcessingStatus(ProcessingStatus.Processed)
    }

    return (
        <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContaier}>
            <View style={styles.container}>
                <BackButton displayName='Back' />
                <View style={styles.buttonContainer}>
                    <DefaultText>This can potentially take a while, only run this when you have stable internet connection.</DefaultText>
                    {/* <DefaultButton onPress={handleImportSchoolAdminExcel} disabled={processingStatus == ProcessingStatus.Processing}>
                        Select school admin file
                    </DefaultButton> */}
                    <DefaultButton onPress={handleImportTeacherExcel} disabled={processingStatus == ProcessingStatus.Processing}>
                        Select teacher file
                    </DefaultButton>
                    <DefaultButton onPress={handleImportStudentExcel} disabled={processingStatus == ProcessingStatus.Processing}>
                        Select student file
                    </DefaultButton>
                    <DefaultButton onPress={handleImportStaffExcel} disabled={processingStatus == ProcessingStatus.Processing}>
                        Select staff file
                    </DefaultButton>
                </View>
                {
                    fileToProccess !== FileToProcess.None && (
                        <>
                            {errors.current.length > 0 && <Messages title='Please fix the following error(s):' messages={errors} styles={styles.error} />}
                            {warnings.current.length > 0 && <Messages title='Warning(s):' messages={warnings} styles={styles.warning} />}
                            {info.current.length > 0 && <Messages title='Info:' messages={info} styles={styles.info} />}
                            {
                                errors.current.length === 0 && (
                                    <View style={styles.buttonContainer}>
                                        <Br />
                                        <DefaultButton onPress={processExcelFile} disabled={processingStatus === ProcessingStatus.Processing || processingStatus === ProcessingStatus.Processed}>
                                            Proces Excel File
                                        </DefaultButton>
                                    </View>
                                )
                            }
                        </>
                    )
                }
                {
                    processingMessage.length > 0 && (
                        <>
                            <Br />
                            <DefaultText>{processingMessage}</DefaultText>
                        </>
                    )
                }
            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    scrollView: {
        flex: 1,
        width: '100%',
        height: '100%'
    },
    scrollViewContaier: {
        height: '100%'
    },
    container: {
        flex: 1,
        width: '100%',
        padding: 20,
    },
    buttonContainer: {
        // flex: 1,
        alignItems: 'center'
    },
    error: {
        color: DecidaColors.Red,
    },
    warning: {
        color: DecidaColors.Orange,
    },
    info: {
        color: DecidaColors.Blue,
    }
})
