import { StyleSheet, View } from 'react-native'
import { ProgressCircle } from 'react-native-svg-charts'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'
import { useResponsive } from '../common/use-responsive'
import { StudentCheckIn, StudentClass } from '../../common/API'
import { StudentAndStudentClass } from './teacher-state'
import { convertStudentCheckInCacheCodeToCacheObject } from '../../common/student-check-in-cache'

interface Props {
    studentCheckIn: StudentCheckIn[]
    activeStudentClasses: StudentClass[]
}

export const TeacherStatsProgressChartLastCheckin = ({ studentCheckIn, activeStudentClasses }: Props) => {

    const { isNarrowScreen } = useResponsive()
    const data = studentCheckIn.map((t => (
        t.tiredness
    )))

    const allTimeData = activeStudentClasses.flatMap((studentClass => (
        studentClass?.studentCheckIns?.map(studentCheckIn => convertStudentCheckInCacheCodeToCacheObject(studentCheckIn).tiredness)
    ))).filter(Number) as number[]

    const sum = data.reduce((a, b) => (a || 0) + (b || 0), 0) || 0
    const avg = (sum / data.length) || 0;
    const progress = avg / 5

    const sumAllTime = allTimeData.reduce((a, b) => a + b, 0);
    const avgAllTime = (sumAllTime / allTimeData.length) || 0;
    const avgStringAllTime = String(avgAllTime.toFixed(2))


    return (
        <>
            <View style={isNarrowScreen ? styles.processCircleMobile : styles.processCircle}>
                <DefaultText style={styles.headingText}>Average energy level</DefaultText>
                <View>
                    <ProgressCircle style={{ height: 200, width: 200 }} progress={progress} progressColor={DecidaColors.Gray} startAngle={-Math.PI * 0.5} endAngle={Math.PI * 0.5} strokeWidth={20} />
                    <DefaultText style={styles.lowLevel}>Low</DefaultText>
                    <DefaultText style={styles.highLevel}>High</DefaultText>
                    <View style={styles.processCircleTitle}>
                        <DefaultText style={styles.tiredText}>{String(avg.toFixed(2))}</DefaultText>
                        <View style={styles.row}>
                            <DefaultText style={styles.averageText}>All time average: </DefaultText>
                            <DefaultText style={styles.averageText}>{avgStringAllTime}</DefaultText>
                        </View>
                    </View>
                </View>
            </View>
        </>
    )


}





const styles = StyleSheet.create({
    processCircle: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 0,
        height: '100%'
    },
    processCircleMobile: {
        maxHeight: 250,
    },
    processCircleTitle: {
        position: 'relative',
        bottom: 200,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    headingText: {
        textAlign: 'center',
        marginBottom: 50,
        fontSize: 22,

    },
    lowLevel: {
        textAlign: 'left',
        position: 'relative',
        bottom: 100,
        fontWeight: '500',
        opacity: 0.7,
        right: 12,
        fontSize: 18,
    },
    highLevel: {
        textAlign: 'right',
        position: 'relative',
        bottom: 120,
        fontWeight: '500',
        opacity: 0.7,
        left: 15,
        fontSize: 18,
    },
    tiredText: {
        fontWeight: '500',
        fontSize: 28,
        opacity: 0.7
    },
    averageText: {
        fontSize: 14,
    },
    row: {
        flexDirection: 'row'
    }

})