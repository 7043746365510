import { StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native'
import { DefaultText } from '../common/default-text'
import { DecidaColors } from '../../common/decida-colors'
import { CheckboxRoundedFill } from './svg-files/checkbox-rounded-fill'

interface Props {
    label?: string
    labelStyle?: TextStyle
    containerStyle?: ViewStyle
    checked?: boolean
    type?: 'default' | 'rounded'
    onChange?: (value: boolean) => void
}

export const CheckBox = ({ label = '', checked = false, type = 'default', onChange, labelStyle, containerStyle }: Props) => {
    const handleOnPress = () => {
        console.log('handleOnPress: ' + !checked)
        onChange && onChange(!checked)
    }

    return (
        <TouchableOpacity style={containerStyle} onPress={handleOnPress}>
            <View style={styles.container}>
                    {type === 'default' && (
                        <View style={styles.checkBox}>
                            {checked && <View style={styles.checkboxChecked} />}
                            {/* // <AntDesign name="check" size={24} color="black" /> */}
                        </View>
                    )}
                    {type === 'rounded' && (
                        checked ? (
                            <View style={styles.checkboxRoundedChecked}>
                                <CheckboxRoundedFill />
                            </View>
                        ) : (
                            <View style={styles.checkboxRounded} />
                        )
                    )}
                <DefaultText style={labelStyle || styles.defaulttext}>{label}</DefaultText>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexShrink: 1,
    },
    checkBox: {
        width: 12,
        height: 12,
        borderColor: DecidaColors.Gray,
        borderWidth: 1,
        marginTop: 3,
        marginRight: 10,
        padding: 1,
    },
    checkboxRounded: {
        width: 16,
        height: 16,
        borderColor: DecidaColors.Gray,
        borderWidth: 1,
        padding: 1,
        marginTop: 3,
        marginRight: 10,
        borderRadius: 4,
        alignItems: 'center',
        justifyContent: 'center'
    },
    checkboxChecked: {
        flex: 2,
        backgroundColor: DecidaColors.Green,
    },
    checkboxRoundedChecked: {
        width: 16,
        height: 16,
        marginRight: 10,
        marginTop: 3,
    },
    defaulttext: {
        marginLeft: 5,
        fontSize: 14,
    }
})