import * as ExpoDevice from "expo-device"
import { useEffect, useState } from 'react'
import { Platform } from "react-native"
import { useResponsive } from "./use-responsive"

const useDeviceTypes = () => {
  const { width } = useResponsive()
  const [device, setdevice] = useState<ExpoDevice.DeviceType>(ExpoDevice.DeviceType.UNKNOWN)
  useEffect(() => {
    getDeviceTypes()
  }, [])

  const getDeviceTypes = async () => {
    const deviceType = await ExpoDevice.getDeviceTypeAsync();
    setdevice(deviceType)
  }

  return {
    device,
    isIphone: device === ExpoDevice.DeviceType.PHONE && Platform.OS === 'ios', // it's always return DeviceType.PHONE even we open the app through the mobile safari
  }
}
export default useDeviceTypes