import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack'
import { useRef, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { CheckInSession, Class, GetClassQuery, GetClassQueryVariables, ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { getClass, listCheckInSessionsByClassId } from '../../common/graphql/queries'
import { isDefined } from '../../common/is-defined'
import ButtonSessionStudent from '../common/button-session-student'
import { DayOptions } from '../common/const'
import { DefaultText } from '../common/default-text'
import DropdownPickerFilter, { rvTeacherStatsAllPeriodPickerOptions } from '../common/dropdown-picker-filter'
import ExportToPdfButton, { rvPrintMode } from '../common/export-to-pdf-button'
import { Page } from '../common/page'
import { PageTitleText } from '../common/page-title-text'
import ScrollViewPrint from '../common/scroll-view-print'
import { Stepper } from '../common/stepper'
import { TeacherStatsSortFilter } from '../common/teacher-stats-sort-filter'
import { useClearRequestForChat } from '../common/use-clear-request-for-chat'
import { useResponsive } from '../common/use-responsive'
import ViewResponsive from '../common/view-responsive'
import teacherClearStudentRequestForChatAlert from './teacher-clear-student-request-for-chat-alert'
import { TeacherClassNavigatorParamList, TeacherDrawerNavigatorPamarList } from './teacher-route-param-types'
import { TeacherStatsAllPeriods } from './teacher-stats-all-periods'
import { StudentAndStudentClassAndStudentCheckIn, TeacherStatsLastPeriodAndRequestedChat } from './teacher-stats-last-period-and-requested-chat'
import { TeacherStatsLegends } from './teacher-stats-legends'
import { ScreenNames } from '../common/screen-names'

export enum Period {
    Last = 'Last',
    All = 'All',
}

export const TeacherStats = ({ navigation, route: { params } }: StackScreenProps<TeacherClassNavigatorParamList, "TeacherStats">) => {

    const isPrintMode = useReactiveVar(rvPrintMode)
    const classID = params?.classID || ""
    const { navigate } = useNavigation<StackNavigationProp<TeacherDrawerNavigatorPamarList>>()
    const [period, setPeriod] = useState<Period>(params?.tab || Period.Last)
    const [checkstate, setCheckstate] = useState(false)
    const { lowRes } = useResponsive()

    const selectedStatsFilter = useReactiveVar(rvTeacherStatsAllPeriodPickerOptions)
    const tabRef = useRef(Period.Last)

    const { data: classResponse } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: classID }, skip: !classID })
    const currentClass = classResponse?.getClass as Class

    const { data: checkInSessionResponse } = useQuery<ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables>(gql`${listCheckInSessionsByClassId}`, { variables: { classID, limit: 5000 }, skip: !classID })
    const checkInSessions = checkInSessionResponse?.listCheckInSessionsByClassId?.items.filter((item) => item?._deleted !== true).filter(isDefined) || [] as CheckInSession[]

    const isAnyActiveSession = checkInSessions.some((checkInSession) => checkInSession?.isActive)

    const { handleClearRequestForChat } = useClearRequestForChat({ clazz: currentClass })

    const onAvatarPress = (studentCheckInDataSet: StudentAndStudentClassAndStudentCheckIn) => {
        teacherClearStudentRequestForChatAlert({ onSubmit: () => handleClearRequestForChat(studentCheckInDataSet) })
    }

    const navigateToIndividualStatsPage = async ({ student }: StudentAndStudentClassAndStudentCheckIn) => {
        navigate(ScreenNames.TeacherClassMain, { screen: ScreenNames.TeacherStudentIndividualStats, params: { classID, studentID: student.student?.id || '' } })
    }

    const handleStepper = async (value: Period) => {
        setPeriod(value)
        navigation.setParams({ tab: value })
        tabRef.current = value
    }

    const isExportPdfEnabled = () => {
        let pdfExportEnabled = false
        if (period === Period.Last) {
            pdfExportEnabled = true
        }

        if (period === Period.All && selectedStatsFilter === DayOptions.Week) {
            pdfExportEnabled = true
        }

        return pdfExportEnabled
    }

    if (!currentClass) {
        return null
    }

    return (
        <Page style={styles.page}>
            <ScrollViewPrint style={[styles.scrollViewMainContainer]} contentContainerStyle={[styles.scrollViewContainer, isPrintMode ? { ...styles.scrollViewContainer, flexDirection: 'row', flexWrap: 'wrap', width: '100%' } : undefined]}>

                {isExportPdfEnabled() && (
                    <View style={styles.exportButtonContainer}>
                        <ExportToPdfButton iconColor={DecidaColors.Green} />
                    </View>
                )}

                <PageTitleText>Stats</PageTitleText>
                <TeacherStatsLegends showAllCheckInLegend={period === Period.All} />

                {currentClass.goMode !== true &&
                    <ViewResponsive style={{ width: '100%' }}>
                        <View style={{
                            flexDirection: lowRes ? 'row' : 'column',
                            alignItems: lowRes && Period.All ? 'flex-start' : undefined,
                            justifyContent: lowRes ? 'space-evenly' : undefined,
                            width: lowRes ? '100%' : undefined,
                            height: lowRes ? 'auto' : undefined,
                            marginVertical: 10,
                        }}>
                            {!isPrintMode && (
                                <Stepper
                                    values={[
                                        { value: Period.Last, label: 'Last Check In' },
                                        { value: Period.All, label: 'All Check Ins' }
                                    ]}
                                    currentValue={period}
                                    onChange={handleStepper}
                                    width={200}
                                    SelectedFontSize={12}
                                    fontSize={12}
                                />
                            )}
                            {period === Period.All && <DropdownPickerFilter />}
                            {period === Period.All && lowRes && (
                                <TeacherStatsSortFilter visible={true} checkstate={checkstate} setCheckState={setCheckstate} />
                            )}
                        </View>
                    </ViewResponsive>
                }

                {
                    checkInSessions.length === 0 ? (
                        <>
                            <DefaultText>No data available</DefaultText>
                        </>
                    )
                        :
                        <>
                            <TeacherStatsLastPeriodAndRequestedChat showGraph={currentClass?.goMode !== true} visible={period === Period.Last} onAvatarPress={onAvatarPress} clazz={currentClass as Class} />
                            <TeacherStatsAllPeriods visible={period === Period.All} onAvatarPress={navigateToIndividualStatsPage} setCheckState={setCheckstate} checkstate={checkstate} />
                        </>
                }

                {isAnyActiveSession && (
                    <ViewResponsive style={{ marginTop: 10 }}>
                        <ButtonSessionStudent />
                    </ViewResponsive>
                )}

            </ScrollViewPrint>
        </Page>
    )
}

const styles = StyleSheet.create({
    exportButtonContainer: {
        position: 'absolute',
        right: 0,
        top: 10,
    },
    page: {
        justifyContent: 'flex-start',
    },
    scrollViewMainContainer: {
        width: '100%'
    },
    scrollViewContainer: {
        justifyContent: 'center',
        alignItems: 'center'
    },
})