import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { FunnyVoiceIcon } from '../../explore/src/funny-voice-icon'
import { FunnyVoiceIconInverted } from '../../explore/src/funny-voice-icon-inverted'
import { AppreciatorLessonPlanImage1 } from './src/appreciator-lessonplan-image1'
import { FunnyVoiceImage1 } from '../sad/src/funny-voice-image1'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'

export const SwitchesEmotionsScaredFunnyVoice: CardTemplate = {
    cardId: "8aa5b4f3-b238-463b-abf2-9957f4d34935",
    title: 'The appreciator',
    Header: FunnyVoiceIconInverted,
    Icon: FunnyVoiceIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <FunnyVoiceImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Clearing your head when overwhelmed with scary or anxious thoughts."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Listen to your thoughts and notice when your mind starts to ramble. "} />
                <DefaultTextWithDownArrow textdata={"Give your mind that is creating this chatter a name (eg. Barry)."} />
                <DefaultTextWithDownArrow textdata={"Answer your mind back with something like, “Thanks for that thought, Barry. Really — thank you.” (You need to be sincerely thankful, otherwise this won’t work). "} />
                <DefaultTextWithoutArrows textdata={"Now tell your mind you hear it’s concern, and that you are in control. Something like, “I really get that you’re trying to be of use, so thank you for that, Barry, but I’ve got this covered.” (Say this out loud if you’re alone, or internally if you’re with others)."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: 'Most of the time we fully believe everything we think, fully accepting what our thoughts tell us and letting them direct our actions – a process called cognitive fusion. Our brain is built to accept the world as structured by our thoughts, missing the fact that we are the ones thinking the thoughts. The Appreciator helps us change our relationship to our thoughts and emotions, rather than trying to change the content of the thought.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 10, color: DecidaColors.Gray }}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define self talk.

                        </DefaultText>

                        <DefaultText style={CommonStyles.lessonPlanText}>• Demonstrate how trying to suppress thoughts can make the thoughts worse.

                        </DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by explaining the concept of self talk as the internal dialogue we have with ourselves.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how self talk can be either supportive and motivating or negative and undermining.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the impact of self talk on emotions and behaviours. Explain that suppressing or trying to avoid negative thoughts have detrimental effects over time. They work against us rather for us, often making things bigger than they are —amplifying the psychological experience we’re trying to escape. Explain that mindfully being aware of your thoughts helps you to cope with unwanted thoughts, feelings, and memories.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce The Appreciator Switch and its steps: naming the inner voice, thanking it sincerely, and reassuring it that you're in control.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Thinking About Thinking Worksheet to students.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students recall or identify an undesired thought that has caused them to feel negative and write it on the worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to choose a name for their inner voice and write it on the worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Using the scale on the worksheet, have students indicate how often the thought has bothered them in the last week.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to spend two to three minutes trying to suppress the unwanted thought, “don’t think about it”.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share the strategies they used and reflect to observe that it’s almost impossible to just tell your brain not to think about something.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that the brain tends to struggle with processing negative instructions or commands that include words like "don't," "can't," or "won't." This phenomenon is often referred to as the "negativity bias" in cognitive psychology. The brain is more responsive to positive instructions or statements than negative ones. This can be explained by how the brain processes and organises information.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>When we hear or read a negative instruction, such as "don't," our brain first needs to process the concept being negated before understanding what to avoid. This extra step can lead to a delay in comprehension and may increase the likelihood of misunderstanding.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>For example, consider the instruction "Don't think about a pink elephant." Your brain first processes the concept of a pink elephant before attempting to negate it, which may result in thoughts of a pink elephant coming to mind.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>To enhance communication and understanding, positive framing is often recommended. Instead of saying what not to do, it's more effective to express what one should do. For instance, replacing "Don't run" with "Please walk" provides a clearer and more direct instruction.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>Understanding this aspect of cognitive processing is valuable in various contexts, including communication, education, and behaviour management, where clear and effective instructions are essential for successful outcomes.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, encourage them to find The Appreciator switch in the Switch4Schools app and read through it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students revisit the unwanted thought and this time spend the same amount of time (two to three minutes) thinking about something else, acknowledging and using The Appreciator Switch if the unwanted thought returns.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to estimate how many times the negative thought popped into their mind during the exercise.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how mindfully being aware of thoughts helps in lessening the impact of unwanted thoughts.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief with the students, asking for their insights and experiences during the exercise.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the counterintuitive nature of The Appreciator Switch and its effectiveness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to continue practicing this technique in their daily lives to manage negative self talk.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students reflect on how The Appreciator Switch can be applied in different situations and write a short paragraph about their experience.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='The Appreciator worksheet' link={Worksheets.theAppreciator.uri} /> </DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What is Acceptance Commitment Therapy?</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=ScwXgqO_d7Y' />

                        <DefaultText style={CommonStyles.lessonPlanText}>5 effective exercises to help you stop believing your unwanted automatic thoughts -   </DefaultText>
                        <DefaultTextWithLink text='https://ideas.ted.com/5-effective-exercises-to-help-you-stop-believing-your-unwanted-automatic-thoughts/' />
                    </>}
            />

        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='If your mind retorts back with thoughts like, “That’s silly — that won’t help!” Simply thank it again like, “Thanks for that thought, Barry. Thank you — I really do see how you are trying to be of use.” ' />
            <DefaulTextWithTipsIcon textdata='If it helps, write down what you are thinking on a piece of paper' />

        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/The+Appreciator.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Scared]
}

