export namespace DecidaColors {
    export const ActivitySheets = "#178541"
    export const Angry = '#FF2D18'
    export const Anxious = '#C6BA2F'
    export const AppleSystemGray2Dark = "#636366"
    export const AppleSystemGray4Light = "#d1d1d6"
    export const AppleSystemGray5Light = "#e5e6ea"
    export const AppleSystemGray6Light = "#f2f2f7"
    export const AppleSystemGray6SuperLight = "#f2f2f2"
    export const AppleSystemGrayLight = "#8e8e93"
    export const Banner = '#9347F5'
    export const Black = '#29292a'
    export const Blue = '#67b7e1'
    export const Brown = '#e9a825'
    export const ClassMode = '#f9d2c9'
    export const Creativity = "#4F78A6"
    export const DarkBlue = '#0068b3'
    export const DarkBrown = '#ab8b65'
    export const DarkGreen = '#9db668'
    export const Energizers = "#B04646"
    export const Excited = '#409F90'
    export const ExtraSwitches = '#D100C0'
    export const ForRemoteClasses = "#1F8084"
    export const FunAndPositive = '#348439'
    export const GoMode = '#A6FDAA'
    export const Gray = '#6c6c70'
    export const GrayBackground = '#f0eded'
    export const GrayBorder = '#BFBCBC'
    export const GrayText = '#575757'
    export const Green = '#2d7841'
    export const Happy = '#0E8413'
    export const HealthyHabits = "#C25600"
    export const Indigo = '#2F3F9E'
    export const InputField = "#EBECF0"
    export const LessonPlanAdvanced = '#D59850'
    export const LessonPlanFoudation = '#8DC750'
    export const LessonPlanMastery = '#168541'
    export const LessonPlanProficient = '#5497D1'
    export const LightGray = '#e0e0e0'
    export const LightGreen = "#9ac857"
    export const NumbersAndWordGames = "#9935BE"
    export const Orange = '#B16215'
    export const Pink = '#e67fb9'
    export const Posters = "#A56927"
    export const Red = '#B10707'
    export const Sad = '#2F9FFF'
    export const Salmon = '#f05858'
    export const Scared = '#CE9951'
    export const SecretsForSleep = "#4E05F2"
    export const SeparationAnxiety = '#947100'
    export const Violet = '#8A64A5'
    export const White = '#ffffff'
    export const Yellow = '#C6BA2F'
    export const YellowLight = '#f8fb98'

}
