import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { TheOrganizerHeaderIcon } from './src/the-organizer-header-icon'
import { TheOrganizerIcon } from './src/the-organizer-icon'
import { TheOrganizerLineDrawing } from './src/the-organizer-line-drawing'
import { Worksheets } from '../../../common/worksheets-imports'

export const SwitchesEmotionsSadTheOrganizer: CardTemplate = {
    cardId: "e10bacb0-f572-4fd6-984d-fcc0bf61928c",
    title: 'The organiser',
    Header: TheOrganizerHeaderIcon,
    Icon: TheOrganizerIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <View style={{ flex: 1 }}>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata='Making a plan of action for the day and prioritising self care.' />

            </View>
        </>
    ),
    Images: TheOrganizerLineDrawing,
    CardContent: () => (
        <>
            <View style={styles.centered}>
                <DefaulttextWithRightArrow textdata="" />
                <DefaultTextWithCustomLink text='If you don’t have a diary download or print this WEEKLY PLANNER.' link={Worksheets.theOrganizerWeeklyPlanner.uri} />
            </View>
            <DefaulttextWithRightArrow textdata="Think of some of the important things you have to do during the week and list them in the priorities section." />
            <DefaulttextWithRightArrow textdata="In the section provided for each day, plan out the key things in your schedule (e.g. school, deadlines, work, social events or other commitments)." />
            <DefaulttextWithRightArrow textdata="Treat yourself! Now schedule in some time for you to relax and make it a weekly occurrence. Whether it be hanging with friends, going for a walk, playing sport, or reading a book, just make sure that it is something you enjoy and gives you energy." />
            <DefaulttextWithRightArrow textdata="Stick to the plan that you have made, and every week enjoy the time you have relaxing." />
        </>
    )
    ,
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: "Engaging in daily task organisation and regular enjoyable relaxation activities can significantly alleviate sadness. This process triggers cognitive regulation by activating the prefrontal cortex, reducing mental clutter and enhancing decision making. Scheduling routines and predictability through planning helps stabilise mood by minimising anxiety associated with unpredictability. Enjoyable activities release dopamine, promoting pleasure and reward, while social interactions stimulate oxytocin, fostering emotional wellbeing. Physical activities also release endorphins, acting as natural mood enhancers and stress relievers, ultimately contributing to both short term and long term emotional resilience.",
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata="Plan around 15-30 minutes of ‘Me time’ per day. " />
            <DefaulTextWithTipsIcon textdata="Schedule in some additional healthy habits to help you increase your capacity to balance school and life, see the ‘Healthy habits’ activities section." />
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the importance of scheduling time for self care in their busy lives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn practical scheduling techniques to prioritise self care.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by discussing the concept of self care. Ask students to share their thoughts on what self care means to them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that today's lesson will focus on the importance of scheduling time for self care activities in their busy lives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students whether they think they have enough self care time in their schedules.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ On the whiteboard, create a mind map or list of common self care activities e.g., exercise, reading, spending time with friends, meditation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Also, create a mind map or list of common benefits of self care, such as reduced stress, improved mental health, and increased productivity. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Scheduling Techniques (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss scheduling techniques that can help students prioritise self care, such as time blocking, setting boundaries, and creating to do lists.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Time Blocking:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Time blocking is a time management method where individuals allocate specific blocks of time for different tasks or activities. It helps students create a structured schedule and ensures they allocate time for self care amidst academic and personal responsibilities.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Benefits:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Prioritisation: Time blocking helps students identify and prioritise important tasks, including self care activities.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Focus: Dedicated time blocks allow students to focus entirely on the task at hand, reducing distractions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Work Life Balance: It contributes to a healthier work life balance by preventing overworking and burnout.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Implementation:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Identify Priorities: Determine the most crucial tasks, including academic assignments, self care, and relaxation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Allocate Blocks: Assign specific time blocks for each activity, ensuring a balance between work and self care.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Be Realistic: Set realistic expectations to avoid overwhelming schedules.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Adjust as Needed: Be flexible to adjust the schedule as circumstances change.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Setting Boundaries:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Setting boundaries involves establishing limits on the demands of others and ensuring that personal needs are met. This is crucial for maintaining mental and emotional wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Benefits:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Stress Reduction: Clearly defined boundaries reduce stress by preventing overcommitment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Increased Focus: Students can concentrate on their priorities without constant interruptions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Improved Relationships: Healthy boundaries contribute to better interpersonal relationships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Implementation:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Learn to Say No: Understand personal limits and learn to say no when additional commitments may jeopardise wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Communicate Clearly: Communicate expectations and limits to friends, family, and colleagues.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Protect Downtime: Set specific times for self care and protect those periods from external demands.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Regular Check Ins: Regularly assess whether boundaries are being respected and adjust as needed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Creating To Do Lists:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- To do lists are a simple yet powerful tool for organising tasks and managing time effectively. They help students visualise their priorities and track their progress.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Benefits:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Task Organisation: To do lists help organise tasks, making it easier to tackle them systematically.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Motivation: Crossing off completed tasks provides a sense of accomplishment and motivation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Time Efficiency: Efficiently plan the day by breaking down larger tasks into manageable steps.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Implementation:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Prioritise Tasks: Identify the most critical tasks and prioritise them at the top of the list.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Break Down Tasks: Divide larger tasks into smaller, more manageable subtasks.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Set Realistic Goals: Ensure that the list is achievable within the available time.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Review and Adjust: Regularly review and adjust the to do list based on changing priorities.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ General Tips for Balancing Self Care and Academic Responsibilities:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Block Breaks: Schedule short breaks between study sessions to prevent burnout.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Incorporate Enjoyable Activities: Include activities that bring joy and relaxation into the schedule.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Quality Sleep: Prioritise sufficient and quality sleep for better cognitive function and overall wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Regular Exercise: Integrate regular physical activity, which positively impacts both physical and mental health</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of flexibility in scheduling and adapting to unexpected changes.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity: Scheduling self care (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to navigate to The Organiser Switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the weekly planner worksheet or have students access it online via The Organiser Switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students map out what a typical week would look like for them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the students to include any time they would typically spend relaxing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If required, challenge them to find some additional time for them to regularly practice self care using the brainstormed list of self care activities on the board as inspiration.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share insights learned from the activity.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the key points discussed during the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to follow the self care routines weekly.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind them that self care is essential for their emotional wellbeing and success.</DefaultText>




                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn practical scheduling techniques to prioritise self care.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Student diary or <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='printed handouts' link={Worksheets.theOrganizerWeeklyPlanner.uri} /> with sample schedules (one per student)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Sticky notes</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Timer</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Prizes for the game (optional)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/The+Organiser+Slides.pptx' /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Benefits of Alone Time and Signs You Need It</DefaultText>
                        <DefaultTextWithLink text='https://psychcentral.com/health/signs-you-need-a-little-me-time' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How To Press On With Your Studies When You're Depressed</DefaultText>
                        <DefaultTextWithLink text='https://www.riosalado.edu/news/2022/how-press-your-studies-when-youre-depressed' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How Constantly Being Busy Affects Your Well-Being</DefaultText>
                        <DefaultTextWithLink text='https://www.verywellmind.com/how-the-glorification-of-busyness-impacts-our-well-being-4175360' />

                    </>}
            />
        </>
    ),
    audioUrl: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/The+organiser.m4a',
    type: [CardType.Switch],
    time: [CardTimeType.Medium, CardTimeType.Long],
people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Sad]
}

const styles = StyleSheet.create({
    centered: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
})

