import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { CircleOfInfluenceHeader } from './src/circle-of-influence-header'
import { CircleOfInfluenceIcon } from './src/circle-of-influence-icon'
import { CircleOfInfluenceImage1 } from './src/circle-of-influence-image1'
import { CircleOfInfluenceImage2 } from './src/circle-of-influence-image2'
import { CircleOfInfluenceLessonPlan } from './src/circle-of-influence-lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'

export const SwitchesEmotionsAnxiousCircleOfInfluence: CardTemplate = {
    cardId: "bc8ad9ae-8608-427a-93b2-0a82d855b501",
    title: 'Circle of influence',
    Header: CircleOfInfluenceHeader,
    Icon: CircleOfInfluenceIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"When you are feeling powerless to deal with challenges in your life."} />
        </>
    ),
    Images: () => (
        <>
            <CircleOfInfluenceImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"List all of the things you are concerned about right now."} />
                <DefaulttextWithRightArrow textdata={"Go through each dot point and ask yourself “Is this something I can control or influence?”"} />
                <DefaulttextWithRightArrow textdata={"Now draw three circles inside each other on a page"} />
                <DefaultText style={styles.withIndent} >• Put the things that you have no control over in the outer circle</DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaultText style={styles.withIndent} >• The things that you have some influence over in the second circle</DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaultText style={styles.withIndent} >• And all the things that you have full control over in the inner circle.</DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaulttextWithRightArrow textdata={"With that clarity, work out what your plan should be and focus your energy where it will have the most impact."} />
            </View>
        </>
    ),
    BottomRow: () => (
        <>
            <View style={styles.imageContainer}>
                <CircleOfInfluenceImage2 />
            </View>
            <DefaulTextWithTipsIcon textdata="With that clarity, work out what your plan should be and focus your energy where it will have the most impact. " />
        </>

    ),
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: `Understanding the circle and influence is important because it helps to identify the things that can be controlled and what cannot. This understanding can improve time management while developing a sense of agency and empowerment, enabling insight into areas causing stress or frustration, to develop strategies for coping with these challenges. In turn, developing the skills and resilience needed to navigate the challenges of life.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand and apply the concepts of the Circle of Control and Circle of Influence to manage stress, increase personal responsibility, and make mindful decisions.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to take a few minutes to reflect on a recent situation that caused them stress or fear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them write down the situation on a sheet of paper without sharing it.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of the Circle of Control and Circle of Influence. The concepts are often associated with personal development and stress management. These ideas were popularised by Stephen Covey in his book "The 7 Habits of Highly Effective People." Specifically:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Circle of Control</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- <DefaultText style={styles.boldText}>Definition:</DefaultText> The Circle of Control represents the things over which you have direct control and influence.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- <DefaultText style={styles.boldText}>Characteristics:</DefaultText> These are aspects of your life where you have the power to make decisions, take actions, and affect outcomes. It includes your attitudes, behaviours, choices, and responses to situations. You have the ability to change or influence these elements without relying on external factors.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- <DefaultText style={styles.boldText}>Example:</DefaultText> Your reactions to situations, how you manage your time, your effort, and your attitude are within your Circle of Control.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Circle of Influence</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- <DefaultText style={styles.boldText}>Definition:</DefaultText> The Circle of Influence encompasses things that you may not have direct control over but can still influence through your actions and choices.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- <DefaultText style={styles.boldText}>Characteristics:</DefaultText> These are areas where you may not have complete control, but you can exert an impact or persuade others. It involves building relationships, communication, and collaboration to affect change. Expanding your Circle of Influence often involves personal development, effective communication, and relationship-building skills.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- <DefaultText style={styles.boldText}>Example:</DefaultText> Your ability to inspire and lead others, contributing ideas in a team setting, and fostering positive relationships are within your Circle of Influence.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Application</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- <DefaultText style={styles.boldText}>Personal Responsibility:</DefaultText> Understanding and focusing on your Circle of Control encourages personal responsibility. It promotes the idea that, regardless of external circumstances, you have the power to choose your responses and actions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- <DefaultText style={styles.boldText}>Stress Management:</DefaultText> By recognising the distinction between the Circle of Control and Circle of Influence, individuals can better manage stress. They learn to let go of concerns beyond their control, focusing their energy on aspects they can change or influence.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- <DefaultText style={styles.boldText}>Empowerment:</DefaultText> Embracing these concepts empowers individuals to take charge of their lives. It encourages proactive behaviour, problem solving, and a mindset that seeks solutions rather than dwelling on problems.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the idea that focusing on the Circle of Control and working to influence the Circle of Influence can reduce stress and increase personal responsibility.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students work in pairs or small groups.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Assign each group a relevant stress inducing situation (e.g., climate change, moving to a new home, natural disasters like fire or floods).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct groups to brainstorm and sort the situation into the Circle of Control and Circle of Influence categories.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a class discussion about the generated lists, asking for examples of how they can take control or influence situations.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the 'Circle of Concern' worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students complete the worksheet individually, considering what they can control, influence, and what is out of their control in their own lives.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key takeaways from the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students that understanding the Circle of Control and Influence empowers them to make mindful decisions, manage stress, and increase personal responsibility.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage ongoing reflection and application of the concepts in their daily lives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- Encourage students to refer to the Circle of Influence switch in the Switch4Schools app whenever needed.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Circle of Concern (influence) worksheet' link={Worksheets.circleOfConcern.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Flip chart or whiteboard and markers </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Examples of situations that fall within the Circle of Control and the Circle of Influence (optional)</DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What is in my control and what is not</DefaultText>
                        <DefaultTextWithLink text='https://thehappinesstrap.com/whats-control-whats-not/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Recognizing What You Can Control and Accepting What You Can't </DefaultText>
                        <DefaultTextWithLink text='https://psychcentral.com/blog/imperfect/2019/05/recognizing-what-you-can-control-and-accepting-what-you-cant' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Circle of Influence. Why you have more power than you think </DefaultText>
                        <DefaultTextWithLink text='https://www.thensomehow.com/circles-of-influence/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>What Can I do? The Circles of Concern and Influence </DefaultText>
                        <DefaultTextWithLink text='https://www.abrahampc.com/blog/2020/3/16/what-can-i-do-the-circles-of-concern-and-influence' />
                    </>}
            />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Circle+of+influence+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Anxious]
}

const styles = StyleSheet.create({
    boldText: {
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
        fontSize: 14, fontWeight: 'bold'
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    withIndent: {
        paddingLeft: 20
    }
})
