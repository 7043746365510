import { useState } from 'react'
import { TextInput } from 'react-native'
import { NewPassword } from './new-password'
import { Auth } from 'aws-amplify'
import { PasswordHintText } from './password-hint-text'
import { PasswordErrorText } from './password-error-text'
import { rvChangePasswordUsername } from './login-state'
import { DefaultButton } from '../common/default-button'
import { CommonStyles } from '../common/const'
import { Page } from '../common/page'
import { RootViewBackButton } from '../common/root-view-back-button'
import { HiddenUsername } from './hidden-username'
import { showAlert } from '../common/universal-alert'
import { customCognitoErrorMessages } from './custom-cognito-error-messages'
import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { LoginStackNavigatorParamList } from './login-route-param-types'
import { ScreenNames } from '../common/screen-names'
import { handleAuth } from './login-functions'

export const ForgotPasswordSubmit = () => {
    const { navigate } = useNavigation<StackNavigationProp<LoginStackNavigatorParamList>>()
    const goBack = () => {
        navigate(ScreenNames.LoginForgotPasswordRequest)
    }

    const [verificationCode, setVerificationCode] = useState('')
    const [password, setPassword] = useState('')
    const [loginError, setLoginError] = useState('')
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)

    const resetPasswordSubmit = () => {
        try {
            setDisableSubmitButton(true)
            Auth.forgotPasswordSubmit(rvChangePasswordUsername(), verificationCode, password)
                .then(() => {
                    Auth.signIn(rvChangePasswordUsername(), password)
                        .then(handleAuth)
                        .catch((e) => {
                            const response = customCognitoErrorMessages(e)
                            setLoginError('Something went wrong, please try again.')
                            showAlert({
                                title: response?.title || "An error has occured",
                                message: response?.message || String(e),
                                leftButtonText: 'Ok',
                            })
                            setDisableSubmitButton(false)
                        })
                })
                .catch((e) => {
                    const response = customCognitoErrorMessages(e)
                    setLoginError('Something went wrong, please try again.')
                    showAlert({
                        title: response?.title || "An error has occured",
                        message: response?.message || String(e),
                        leftButtonText: 'Ok',
                    })
                    setDisableSubmitButton(false)
                })
        }
        catch (e: any) {

        }
    }

    return (
        <RootViewBackButton onPress={goBack}>
            <Page>
                <PasswordHintText>If you didn't receive your verification within 2 minutes, please go back to the previous page, verify email is entered correctly, and resubmit.</PasswordHintText>
                <TextInput
                    style={CommonStyles.textInput}
                    placeholder="verification code"
                    keyboardType='number-pad'
                    onChangeText={setVerificationCode}
                    textContentType='oneTimeCode'
                />
                <HiddenUsername />
                <NewPassword onPasswordChange={setPassword} />
                <DefaultButton disabled={verificationCode === '' || password === '' || disableSubmitButton} onPress={resetPasswordSubmit}>Submit</DefaultButton>
                <PasswordErrorText>{loginError}</PasswordErrorText>
            </Page>
        </RootViewBackButton>
    )
}

