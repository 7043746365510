import { Activities } from '../../common/activities'
import { ActivityGroupTemplate } from '../../common/activity-group-template'
import { HealthyHabitsIcon } from '../icons/healthy-habits-icon'

export const SwitchesActivityGroupHealthyHabits: ActivityGroupTemplate = {
    title: 'Healthy habits',
    Icon: HealthyHabitsIcon,
    activity: Activities.HEALTHY_HABITS,
    subtitle: "Things you can do everyday to make your life happier, healthier, and more productive."
}
