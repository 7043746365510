import { Picker } from '@react-native-picker/picker'
import { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { SchoolStatus } from '../models'
import { DefaultText } from './default-text'

const SCHOOL_FILTER_OPTIONS = [
    {
        label: 'All',
        value: ""
    },
    {
        label: 'Archived',
        value: SchoolStatus.ARCHIVED
    },
    {
        label: 'On hold',
        value: SchoolStatus.ON_HOLD
    },
    {
        label: 'On trial',
        value: SchoolStatus.ON_TRIAL
    },
    {
        label: 'Subscribed',
        value: SchoolStatus.SUBSCRIBED
    },
    {
        label: 'Test',
        value: SchoolStatus.TEST
    },
] as const

type Props = {
    value: SchoolStatus | undefined
    setValue: (status: SchoolStatus) => void
}

const DropdownPickerSchoolsStatusFilterContainer = ({ value, setValue }: Props) => {
    return (
        <View style={styles.container}>
            <DefaultText>Status:</DefaultText>
            <Picker style={styles.pickerContainner} onValueChange={setValue} selectedValue={value}>
                {SCHOOL_FILTER_OPTIONS.map((option) => {
                    return (
                        <Picker.Item key={option.value} label={option.label} value={option.value} />
                    )
                })}
            </Picker>
        </View>
    )
}
export default DropdownPickerSchoolsStatusFilterContainer
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-end',
    },
    pickerContainner: {
        padding: 10,
        borderRadius: 5,
        marginLeft: 5
    }
})