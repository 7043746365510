import { View } from 'react-native'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { ScavengerHuntIcon } from '../../icons/scavenger-hunt-icon'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { ScavengerHuntIconInverted } from '../../icons/scavenger-hunt-icon-inverted'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-double-indentation'

export const SwitchesActivitiesRemoteClassesScavengerHunt: CardTemplate = {
    cardId: "e43e5393-a3df-4821-81d0-3bc303ec6760",
    title: 'Scavenger hunt',
    Header: ScavengerHuntIconInverted,
    Icon: ScavengerHuntIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Learning something new about other students and familiarising students with geography and search tool functionality."} />
            <DefaulttextWithRightArrow textdata={"Prepare a list of things you want students to find online. The list can be tailored to a specific learning outcome, or just purely fun. "} />
            <DefaulttextWithRightArrow textdata={"Some of the things on the list could include"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Find everyone in your group’s birth place, or favourite ‘fun’ place on Google Maps and take a picture. "} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Find an image for a celebrity lookalike for each member of your group."} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Each person finds an ingredient for a cake in their house and takes a photograph so collectively, you have all the ingredients. Each group chooses their cake, though the more complex or interesting, the better. "} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Recreate the name of your school with each person on your team recreating a letter with a photo. (They might spell the letter with their body or take a photograph of items on their desk.)"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Pick your favourite celebrity, and find photos of them in five different countries."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.FOR_REMOTE_CLASSES,
    whyDoesItWorkText: '',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Scavenger.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.FOR_REMOTE_CLASSES],
    action: [CardActionType.Action],
    level: []
}

