import { StyleSheet, Text, View } from 'react-native'
import { School } from '../../common/API'
import { List } from '../common/list'
import { isDefined } from '../../common/is-defined'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AdminStackNavigatorParamList } from './admin-route-param-types'
import { ScreenNames } from '../common/screen-names'

type Props = {
    school: School
}

const AdminSchoolNonTeachingStaffsList = ({ school }: Props) => {
    const { navigate } = useNavigation<StackNavigationProp<AdminStackNavigatorParamList>>()

    const addNonTeachingStaff = () => {
        navigate(ScreenNames.AdminNonTeachingStaff, { id: undefined, schoolID: school.id })
    }

    const editNonTeachingStaff = (id: string) => {
        navigate(ScreenNames.AdminNonTeachingStaff, { id, schoolID: school.id })
    }

    return (
        <>
            <List
                type='accordion'
                title='Non Teaching staffs'
                items={school.staffs?.items.filter(isDefined).map(a => {
                    return ({
                        id: a.id,
                        name: a.cognitoUsername
                    })
                }) || []}
                add={addNonTeachingStaff}
                edit={editNonTeachingStaff}
            />
        </>
    )
}
export default AdminSchoolNonTeachingStaffsList

const styles = StyleSheet.create({})