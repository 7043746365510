import { Dimensions, View } from 'react-native'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardTemplate } from '../../common/card-template'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import { HelpingHandsIcon } from '../icons/helping-hands-icon'
import { HelpingHandsImage1 } from '../images/helping-hands-image1'
import { HelpingHandsImage2 } from '../images/helping-hands-image2'
import { HelpingHandsImage3 } from '../images/helping-hands-image3'
import { HelpingHandsImage4 } from '../images/helping-hands-image4'
import { HelpingHandsImage5 } from '../images/helping-hands-image5'
import { HelpingHandsImage6 } from '../images/helping-hands-image6'

const windowWidth = Dimensions.get('window').width;
const windowWidthCondition = windowWidth < 600;
const windowHeight = Dimensions.get('window').height;
const switchImageHeight = windowHeight * 0.75


export const ToolsHelpingHands: CardTemplate = {
    cardId: "b8db4583-6120-4f9e-afeb-7f510ddd9e41",
    title: 'Helping Hands',
    Header: HelpingHandsIcon,
    Icon: HelpingHandsIcon,
    HeaderTitle: 'Helping Hands',
    PerfectFor: () => (
        <>
            <View style={{ position: 'relative', alignSelf: 'center', alignItems: 'center' }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: windowWidthCondition ? '20%' : '5%', }}>
                    <DefaultText>
                        A great resource from our Switch4School partners at:
                    </DefaultText>
                    <View style={{ width: 97, height: 122 }}>
                        <HelpingHandsImage1 />
                    </View>
                </View>
            </View>
            <View style={commonCardStyles.container}>
                <View style={commonCardStyles.imagecontainer}>
                    <DefaultTextWithoutArrowsItallic textdata={"Use your helping hands to refocus you thoughts and actions, and help everyone have a better day!"} />
                    <View style={{ width: windowWidth, height: switchImageHeight, }}>
                        <HelpingHandsImage2 />
                    </View>

                    <DefaultText style={{ marginTop: 10 }}>
                        List 5 things you are grateful for today.
                    </DefaultText>
                    <View style={{ width: windowWidth, height: switchImageHeight, }}>
                        <HelpingHandsImage3 />
                    </View>

                    <DefaultText style={{ marginTop: 10 }}>
                        Ask yourself these five important questions to reframe your thinking and solve problems with confidence.
                    </DefaultText>
                    <View style={{ width: windowWidth, height: switchImageHeight, }}>
                        <HelpingHandsImage4 />
                    </View>

                    <DefaultText style={{ marginTop: 10 }}>
                        Five healthy and social things you can choose to do that will ensure you respond (and not just react) to set you up for success!
                    </DefaultText>
                    <View style={{ width: windowWidth, height: switchImageHeight, }}>
                        <HelpingHandsImage5 />
                    </View>

                    <DefaultText style={{ marginTop: 10 }}>
                        Remember that we all have these five basic needs. Use these as a checklist to make sure you are giving your mind what it needs to be strong and courageous.
                    </DefaultText>
                    <View style={{ width: windowWidth, height: switchImageHeight, }}>
                        <HelpingHandsImage6 />
                    </View>

                </View>
            </View>


        </>
    ),
    BottomRow: () => (
        <>

        </>



    ),
    CardContent: () => (
        <>

        </>
    ),
    colorScheme: CardColorScheme.TOOL,
    whyDoesItWorkText: '',
    mainContainerStyle: {
        maxWidth: '100%',
        paddingBottom: 50
    }
}

