import { SwitchRating } from "../../common/API";
import { SwitchRatingType } from "../models";
import { CardTemplate } from "../switches/common/card-template";
import { 
    angry3pointcards, 
    angry5pointcards, 
    angry7pointscards, 
    anxious3pointcards, 
    anxious5pointcards, 
    anxious7pointscards, 
    excited3pointcards, 
    excited5pointcards, 
    excited7pointscards, 
    happy3pointcards, 
    happy5pointcards, 
    happy7pointscards, 
    sad3pointcards, 
    sad5pointcards, 
    sad7pointscards, 
    scared3pointcards, 
    scared5pointcards, 
    scared7pointscards 
} from "../switches/emotion-cards/cards";

const emotionCardData = {
    angry7: angry7pointscards,
    anxious7: anxious7pointscards,
    sad7: sad7pointscards,
    scared7: scared7pointscards,
    happy7: happy7pointscards,
    excited7: excited7pointscards,
    angry5: angry5pointcards,
    anxious5: anxious5pointcards,
    sad5: sad5pointcards,
    scared5: scared5pointcards,
    happy5: happy5pointcards,
    excited5: excited5pointcards,
    angry3: angry3pointcards,
    anxious3: anxious3pointcards,
    sad3: sad3pointcards,
    scared3: scared3pointcards,
    happy3: happy3pointcards,
    excited3: excited3pointcards
} as const;

type EmotionCardKey = keyof typeof emotionCardData;

const filterNegativeEmotionCards = (cards: CardTemplate[], cardRatings?: SwitchRating[]): CardTemplate[] => {
    const negativeCardRatings = cardRatings?.filter(rating => rating.rating === SwitchRatingType.DISLIKE);
    const negativeCardIds = negativeCardRatings?.map(rating => rating.cardId) || [];
    return cards.filter(card => !negativeCardIds.includes(card.cardId));
};

export const nudgesGetFilteredEmotionCards = (cardRatings: SwitchRating[] | undefined): { [K in EmotionCardKey]: CardTemplate[] } => {
    const filteredEmotionCardData: { [K in EmotionCardKey]?: CardTemplate[] } = {};
    for (const key in emotionCardData) {
        if (emotionCardData.hasOwnProperty(key)) {
            filteredEmotionCardData[key as EmotionCardKey] = filterNegativeEmotionCards(emotionCardData[key as EmotionCardKey], cardRatings);
        }
    }
    return filteredEmotionCardData as { [K in EmotionCardKey]: CardTemplate[] };
};
