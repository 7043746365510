import { GestureResponderEvent, Pressable, StyleSheet, Text, View } from 'react-native'
import { DefaultText } from '../common/default-text'
import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { ListTeacherClassesByClassIDQuery, ListTeacherClassesByClassIDQueryVariables, TeacherClass } from '../../common/API'
import { listTeacherClassesByClassID } from '../../common/graphql/queries'
import { RouteProp, useRoute } from '@react-navigation/native'

import { rvTeacherRequestForEmailNotificationsInfoModal } from './teacher-state'
import InformationIcon from '../common/information-icon'
import TeacherClassAdminRequestForChatTeacher from './teacher-class-admin-request-for-chat-teacher'
import { DecidaColors } from '../../common/decida-colors'
import { TeacherClassNavigatorParamList } from './teacher-route-param-types'
import { rvSidebarClassID } from '../school-admin/school-admin-state'
import { InfoIcon } from '../common/svg-files/info-icon'


const TeacherClassAdminRequestForChats = () => {

    const { params } = useRoute<RouteProp<TeacherClassNavigatorParamList, "TeacherClassAdmin">>()

    const currentSidebarClassID = useReactiveVar(rvSidebarClassID)
    const classID = params?.classID || currentSidebarClassID || ""
    const { data: teacherClassQuery } = useQuery<ListTeacherClassesByClassIDQuery, ListTeacherClassesByClassIDQueryVariables>(gql`${listTeacherClassesByClassID}`, { variables: { classID }, skip: !classID })
    const classTeachers = teacherClassQuery?.listTeacherClassesByClassID?.items.filter((item) => item?._deleted !== true) as TeacherClass[]

    const showInfoRequestForEmailNotifications = () => {
        rvTeacherRequestForEmailNotificationsInfoModal(true)
    }

    return (
        <View style={styles.emailNotificationsTextContainer}>
            <View style={styles.textInfoContainer}>
                <DefaultText style={currentSidebarClassID ? styles.schoolAdminSidebarEmailNotificationText : styles.emailNotificationsTextContainer}>Request Chat Email Notifications</DefaultText>
                <Pressable onPress={showInfoRequestForEmailNotifications} style={styles.infoIcon}>
                    {currentSidebarClassID ? <InfoIcon /> : <InformationIcon onPress={showInfoRequestForEmailNotifications} color={DecidaColors.Green} />}
                </Pressable>
                <DefaultText style={currentSidebarClassID ? styles.schoolAdminSidebarEmailNotificationText : styles.emailNotificationsTextContainer}> :</DefaultText>

            </View>
            {classTeachers?.map((teacherClass: TeacherClass) => <TeacherClassAdminRequestForChatTeacher key={teacherClass.id} teacherClass={teacherClass} />)}
        </View>
    )
}
export default TeacherClassAdminRequestForChats

const styles = StyleSheet.create({
    textInfoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    emailNotificationsTextContainer: {

    },
    schoolAdminSidebarEmailNotificationText: {
        fontSize: 14,
        color: DecidaColors.AppleSystemGray2Dark,
    },
    infoIcon: {
        width: 24,
        height: 24,
        marginBottom: 10,
        marginLeft: 5
    },

})