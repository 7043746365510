import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { StyleSheet, View } from 'react-native'
import { Class, CreateGoClassStudentCheckInMutation, CreateGoClassStudentCheckInMutationVariables, CreateTeacherCheckInMutation, CreateTeacherCheckInMutationVariables, GetClassQuery, GetClassQueryVariables, ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables, StudentCheckIn, UpsertStudentCheckInMutation, UpsertStudentCheckInMutationVariables } from '../../common/API'
import { CheckInUserGroup } from '../../common/constants'
import { convertToTwoDecimalPlaces } from '../../common/convert-to-two-decimal-places'
import { createGoClassStudentCheckIn, createTeacherCheckIn, upsertStudentCheckIn } from '../../common/graphql/mutations'
import { getClass, listStudentCheckInsByCheckInSessionId } from '../../common/graphql/queries'
import { rvNetInfo } from '../common/common-state'
import { getIntensityLevel } from '../common/get-intensity-level'
import { rvIsLoading } from '../common/loading'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { showGenericErrorAlert, showOfflineAlert } from '../common/universal-alert'
import { lowRes, useResponsive } from '../common/use-responsive'
import { IconEmotionalWheel } from '../icon/icon-emotional-wheel'
import { Emotion } from '../models'
import { rvCurrentStudentLastStudentCheckIn } from '../student/use-student-state'
import { rvTeacherCurrentTeacher } from '../teacher/teacher-state'
import { CheckInHeader } from './check-in-header'
import { CheckInNavigatorParamList } from './check-in-route-param-types'
import { rvCheckInState } from './check-in-state'



export const CheckInEmotionalWheel = ({ navigation: { navigate }, route: { params } }: StackScreenProps<CheckInNavigatorParamList, 'CheckInEmotionalWheel'>) => {

    const { isNarrowScreen } = useResponsive()
    const { studentID = "", checkinsessionID = "", tiredness, eating} = useReactiveVar(rvCheckInState)


    const [upsertStudentCheckIMutation] = useMutation<UpsertStudentCheckInMutation, UpsertStudentCheckInMutationVariables>(gql`${upsertStudentCheckIn}`,)
    const [createGoClassStudentCheckInMutation] = useMutation<CreateGoClassStudentCheckInMutation, CreateGoClassStudentCheckInMutationVariables>(gql`${createGoClassStudentCheckIn}`,)

    const { data: classResponse } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: params?.classID || "" }, skip: !params.classID })
    const currentClass = classResponse?.getClass as Class

    const { data: studentCheckInByCheckInSessionIdResponse } = useQuery<ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables>(gql`${listStudentCheckInsByCheckInSessionId}`, { variables: { checkinsessionID: checkinsessionID || '', limit: 5000 }, skip: !checkinsessionID })
    const currentStudentCheckInSession = studentCheckInByCheckInSessionIdResponse?.listStudentCheckInsByCheckInSessionId?.items.filter((item) => item?._deleted !== true).find((item) => item?.studentID === studentID) as StudentCheckIn
    const netInfoState = useReactiveVar(rvNetInfo)

    const back = () => {
        navigate(ScreenNames.CheckInTirednessScale, { classID: params?.classID, user: params?.user })
    }

    const onPressEmotion = async (emotion: Emotion) => {
        if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

        rvCheckInState({
            ...rvCheckInState(),
            emotion: emotion,
        })

        if (!currentClass && !params?.user) {
            showGenericErrorAlert()
            return null
        }

        if (params.user === CheckInUserGroup.Teacher) {
            await handleTeacherCheckIn(emotion)
        } else {
            await handleStudentCheckIn(emotion)
        }

    }

    const handleTeacherCheckIn = async (emotion: Emotion) => {
        navigate(ScreenNames.CheckInEmotionalIntensity, { classID: params?.classID, user: params?.user })
    }

    const handleStudentCheckIn = async (emotion: Emotion) => {
        const intensityLevel = getIntensityLevel(currentClass?.emotionIntensityLevel)

        if (intensityLevel === 1) {
            try {
                const emotionIntensityPercentage = 1
                rvIsLoading(true)
                if (currentClass?.goMode) {
                    const { data } = await createGoClassStudentCheckInMutation({
                        variables: {
                            input: {
                                emotion,
                                emotionIntensityPercentage: convertToTwoDecimalPlaces(emotionIntensityPercentage),
                                tiredness,
                                studentID,
                                classID: currentClass?.id,
                                eating: eating || null,
                            }
                        }
                    })
                    rvCurrentStudentLastStudentCheckIn(data?.createGoClassStudentCheckIn)
                } else {
                    const { data } = await upsertStudentCheckIMutation({
                        variables: {
                            input: {
                                emotion,
                                emotionIntensityPercentage,
                                tiredness,
                                classID: currentClass?.id,
                                checkInSessionID: checkinsessionID,
                                studentID: studentID,
                                studentCheckinID: currentStudentCheckInSession ? currentStudentCheckInSession?.id : undefined,
                                eating: eating || null,
                            }
                        }
                    })
                    rvCurrentStudentLastStudentCheckIn(data?.upsertStudentCheckIn)
                }
                rvIsLoading(false)
                currentClass?.toggleRequestForChat ? navigate(ScreenNames.CheckInRequestChatWithTeacher, { classID: params?.classID, user: params?.user }) : navigate(ScreenNames.CheckInFinish, { classID: params?.classID, user: params?.user })
            } catch (error) {
                console.log('error')
            }
        }
        else {
            navigate(ScreenNames.CheckInEmotionalIntensity, { classID: params?.classID, user: params?.user })
        }
    }

    return (
        <Page style={lowRes ? styles.pageLowres : styles.page}>
            <CheckInHeader
                back={back}
                headerText='How are you feeling?'
                studentId={studentID}
            />
            <View style={[lowRes ? styles.emotionalWheelLowRes : styles.emotionalWheel, { maxHeight: isNarrowScreen ? '100%' : undefined }]}>
                <IconEmotionalWheel onPress={onPressEmotion} />
            </View>
        </Page>
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
    },
    pageLowres: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
    },
    emotionalWheel: {
        flex: 1,
        minHeight: 150,
        width: '100%',
        padding: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    emotionalWheelLowRes: {
        flex: 1,
        minHeight: 150,
        width: '100%',
        padding: 30,
        justifyContent: 'center',
        alignSelf: 'flex-end',

        paddingTop: lowRes ? 0 : undefined,
        paddingBottom: lowRes ? 30 : undefined,
        height: lowRes ? '80%' : '100%',
    }
})