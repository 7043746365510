import { View } from 'react-native'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextWithIndentation } from '../../common/default-text-with-indentation'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { ExposeItHeaderIcon } from './src/expose-it-header-icon'
import { ExposeItIcon } from './src/expose-it-icon'
import { ExposeItLineDrawing } from './src/expose-it-line-drawing'

export const SwitchesEmotionsScaredExposeIt: CardTemplate = {
    cardId: "5b95ede8-2f9a-46d1-a545-8cdfd35e376c",
    title: 'Expose it',
    Header: ExposeItHeaderIcon,
    Icon: ExposeItIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <View style={{ flex: 1 }}>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata='Confronting your fears in a manageable way.' />

            </View>
        </>
    ),
    Images: ExposeItLineDrawing,
    CardContent: () => (
        <>
            <DefaulttextWithRightArrow textdata="Think about the thing that is really scaring you." />
            <DefaulttextWithRightArrow textdata="Find a way to slightly expose yourself to it. For example…" />
            <DefaultTextWithIndentation textdata='a. If your fear is social interaction, then attempt to meet new people with a few already trusted friends then gradually lessen the number of friends accompanying you. ' />
            <DefaultTextWithIndentation textdata='b. If your fear is public speaking, then practice your speech in front of a small group of friends and gradually increase to a larger group. ' />

        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Expose+it.m4a",
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: `Gradual exposure, such as being slightly uncomfortable with heights or practicing public speaking in front of progressively larger audiences, takes advantage of a psychological principle called habituation. This process involves repeated exposure to the fear in a safe and controlled manner, leading to a reduction in fear response over time. By challenging their fears incrementally, individuals build confidence and resilience. Regular application of this technique encourages the gradual weakening of fear's grip and fosters a sense of empowerment in the face of anxiety provoking situations.`,
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata="-If the thing that is scaring you poses a real physical threat to you, we recommend talking to a trusted adult about it. " />
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the concept of exposure therapy as a technique for gradually confronting and managing their fears</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn how to apply exposure therapy to specific fears in a safe and controlled manner.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                       <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: Approximately 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction: (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reference the Expose It Switch. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by discussing the idea of fear and how it affects people in various ways.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of exposure therapy as a method for managing and overcoming fears.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share things that might be appropriate for exposure therapy.  Look for: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Fear of specific animals such as dogs, snakes, spiders, etc.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Fear of specific situations like flying, heights, or enclosed spaces.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Fear of natural elements like storms, water, or darkness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Fear of social situations, scrutiny, or embarrassment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Fear of public speaking or performing in front of others.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Fear of contamination or germs.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Fear of making a mistake or forgetting something.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that whilst some therapists will use exposure therapy to treat some severe phobias and mental disorders, including post traumatic stress disorder or obsessive compulsive disorder, the intent of the Expose It Switch is to build the courage to overcome manageable level fears.  You will know your own limits and how far you can push yourself without causing harm. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Exposure Therapy: (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Define exposure therapy as a therapeutic technique involving gradual and controlled exposure to feared situations or objects.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that the goal of exposure therapy is to reduce fear and anxiety responses by increasing familiarity and comfort with the feared stimulus.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Mention that exposure therapy is most effective when done in a controlled and safe environment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide examples of how exposure therapy can be applied to common fears, such as social anxiety or public speaking. For example:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Step 1: Speaking in front of a mirror.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Step 2: Speaking in front of a close friend or family member.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Step 3: Speaking in a small group.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Step 4: Speaking in a larger group or to the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Step 5: Giving a short presentation to a larger audience.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ -Emphasise the importance of starting with small, manageable steps and gradually increasing the level of exposure.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Interactive Exercise - Exposure Plan (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Assign pairs or small groups for students to work in.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct each group to develop the stepped exposure plan for someone that has a fear of making phone calls. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to create a step by step exposure plan for confronting that fear in a gradual and controlled manner. Encourage them to consider realistic and safe ways to expose the person to the fear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Steps could include: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Texting a Familiar Friend: Start with the least anxiety provoking situation. In this case, it could involve texting a close friend or family member instead of making a call.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Calling a Family Member: Progress to making phone calls to family members who are supportive and understanding. This might include grandparents, cousins, or aunts/uncles.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Ordering Food for Delivery: A common anxiety provoking situation is ordering food for delivery. This involves interacting with a stranger over the phone but is a relatively low stakes situation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Calling a Friend for a Casual Chat: Move on to calling a friend for a casual conversation. This could be a friend they are comfortable talking to, and the goal is to build confidence in less structured interactions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Calling a Classmate for Homework Help: Increase the challenge by having them call a classmate for homework help. This introduces a slightly more formal and task oriented conversation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Making a Doctor's Appointment: Call a doctor's office to schedule an appointment. This introduces a more formal and potentially unfamiliar setting.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Inquiring About a Job Opening: For teenagers preparing for the workforce, making a phone call to inquire about a job opening is a more challenging exposure task.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Calling an Unfamiliar Organisation: Finally, challenge them to call an organisation or business they are unfamiliar with, perhaps to gather information or ask questions about a service.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Group Sharing and Discussion: (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite each group to share their exposure plans with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage discussion on the feasibility and effectiveness of these plans.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Highlight the importance of seeking support from trusted individuals when implementing exposure therapy.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief with the students, asking for their insights and experiences during the exercise.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the key points and encourage students to continue practicing this technique in their daily lives to manage fears.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students seek out Expose It switch on their device for future reference.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard and markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Projector or screen for displaying visuals</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Small groups or pairs for interactive exercises</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Exposure Therapy: Types, How It's Done, and More – Healthline</DefaultText>
                        <DefaultTextWithLink text='https://www.healthline.com/health/exposure-therapy' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to Beat Anxiety with Exposure Therapy</DefaultText>
                        <DefaultTextWithLink text='https://www.anxietyaustralia.com.au/resources/exposure-therapy/' />
                        
                        <DefaultText style={CommonStyles.lessonPlanText}>What Is Exposure Therapy?</DefaultText>
                        <DefaultTextWithLink text='https://www.webmd.com/mental-health/what-is-exposure-therapy' />
                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action, CardActionType.Thought],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Scared]
}


