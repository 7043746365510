import { View } from 'react-native'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { CardTemplate, CardType } from '../../common/card-template'
import { CardColorScheme } from '../../common/card-color-scheme'
import { Dimensions } from 'react-native';
import { YouAndYourApeIcon } from '../icons/you-and-your-ape-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { YouAndYourApeImage } from '../images/you-and-your-ape'
import { DefaultText } from '../../../common/default-text'
import { YouAndYourApeImage2 } from '../images/you-and-your-ape2'
import { YouAndYourApeIconCard } from '../icons/you-and-your-ape-icon-card'

const windowWidth = Dimensions.get('window').width;

export const ToolsYouAndYourApe: CardTemplate = {
    cardId: "5bfd0301-de2f-4343-a659-38e2272721f4",
    title: 'You and your ape',
    Header: YouAndYourApeIcon,
    HeaderTitle: 'You and your ape',
    Icon: YouAndYourApeIconCard,
    PerfectFor: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultText style={{ textAlign: "center", paddingVertical: 10 }}>Your brain has two ways of processing information.</DefaultText>
                <DefaultText style={{ textAlign: "center", paddingVertical: 10 }}>• A fast, automatic way (System 1), and </DefaultText>
                <DefaultText style={{ textAlign: "center", paddingVertical: 10 }}>• A slower, more considered way System 2). </DefaultText>
                <DefaultText style={{ textAlign: "center", paddingVertical: 10 }}>Your automatic system 1 draws on parts of the brain that control memories, experiences and emotion.</DefaultText>
                <View style={commonCardStyles.imagecontainer}>
                    <View style={windowWidth < 600 ? { width: 400, height: 200, } : { width: 700, height: 400, }}>
                        <YouAndYourApeImage />
                    </View>
                </View>
                <DefaultText style={{ textAlign: "center", paddingVertical: 10 }}>The parts of the brain most associated with System 1 thinking are also found in Chimpanzees, so we like to call this reactive system ‘Your Ape’.</DefaultText>
                <DefaultText style={{ textAlign: "center", paddingVertical: 10 }}> Your system 2 are full of things that make us humans truely unique. Having our system 2 allows us to plan ahead, problem solve, and control our emotional reactivity. It is the system we are more conscious of, so we call this bit ‘You. </DefaultText>
                <DefaultText style={{ textAlign: "center", paddingVertical: 10 }}>Each of us are made up of two ways of thinking. You, and Your Ape.</DefaultText>

                <View style={commonCardStyles.imagecontainer}>
                    <View style={windowWidth < 600 ? { width: 400, height: 100, } : { width: 700, height: 400, }}>
                        <YouAndYourApeImage2 />
                    </View>
                </View>
            </View>
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>

        </>
    ),
    colorScheme: CardColorScheme.TOOL,
    whyDoesItWorkText: '',
    type: [CardType.Tool],
    time: [],
    people: [],
    place: [],
    category: [],
    action: [],
    level: []
}

