import { useReactiveVar } from '@apollo/client'
import { StyleSheet, TextStyle, View } from 'react-native'
import { UserGroup } from '../../common/constants'
import { rvUserGroup } from '../login/login-state'
import { DefaultTextWithoutArrows } from '../switches/common/default-text-without-arrows'
import { DefaultButton } from './default-button'
import { DefaultText } from './default-text'
import { OnBoardingAndi } from './svg-files/onboarding-andi'
import { OnboardingStep2Image } from './svg-files/onboarding-step2-image'
import { OnboardingStep3Image } from './svg-files/onboarding-step3-image'
import { OnboardingStep4Image } from './svg-files/onboarding-step4-image'
import { OnboardingStep5Image } from './svg-files/onboarding-step5-image'
import { OnboardingStep6Image } from './svg-files/onboarding-step6-image'
import { OnboardingStep7Image } from './svg-files/onboarding-step7-image'
import { OnboardingStep7StaffImage } from './svg-files/onboarding-step7-staff-image'
import { useResponsive } from './use-responsive'

type Props = {
    handleNext: () => void
}

type DefaultProps = {
    handleNext: () => void
    image: () => JSX.Element
    text: string
    textStyle?: TextStyle
    nextButtonTitle?: string
}

const StudentAndNonTeachingStaffOnBoardingStep1 = ({ handleNext }: Props) => {
    const { responsiveFontSize } = useResponsive()
    return (
        <View style={styles.content}>
            <View style={styles.contentContainer}>
                <DefaultTextWithoutArrows align='left' textdata={"Hi, welcome to Switch! \n\nMy name is AnDi. I’m here to help you get started. \n\nI’m excited to show you around. \n\nBefore you get going I’ll show you a few tips."} small fontSize={responsiveFontSize()} />
                <View style={styles.buttonsContainer}>
                    <DefaultButton style={styles.nextButton} buttonTextStyle={[styles.nextText, { fontSize: responsiveFontSize(24) }]} onPress={handleNext}>
                        NEXT
                    </DefaultButton>
                </View>
            </View>
            <View style={styles.contentContainer}>
                <View style={styles.imageContainer}>
                    <OnBoardingAndi />
                </View>
            </View>
        </View>
    )
}
const StudentAndNonTeachingStaffOnBoardingStep2 = ({ handleNext }: Props) => <StudentAndNonTeachingStaffOnBoardingStepDefault handleNext={handleNext} text="This is your home page which you can access with this button at any time." image={OnboardingStep2Image} />
const StudentAndNonTeachingStaffOnBoardingStep3 = ({ handleNext }: Props) => <StudentAndNonTeachingStaffOnBoardingStepDefault handleNext={handleNext} text="Select this icon to access your emotion wheel." image={OnboardingStep3Image} />
const StudentAndNonTeachingStaffOnBoardingStep4 = ({ handleNext }: Props) => <StudentAndNonTeachingStaffOnBoardingStepDefault handleNext={handleNext} text="By selecting anywhere on an emotion, you can access its switches." image={OnboardingStep4Image} />
const StudentAndNonTeachingStaffOnBoardingStep5 = ({ handleNext }: Props) => <StudentAndNonTeachingStaffOnBoardingStepDefault handleNext={handleNext} text="These are your switches. Use them to dial up and down your emotions." image={OnboardingStep5Image} />
const StudentAndNonTeachingStaffOnBoardingStep6 = ({ handleNext }: Props) => <StudentAndNonTeachingStaffOnBoardingStepDefault handleNext={handleNext} text="When you find a switch that you like, select the star so you can build your favourite toolkit of switches!" image={OnboardingStep6Image} />
const StudentAndNonTeachingStaffOnBoardingStep7 = ({ handleNext }: Props) => {
    const userGroup = useReactiveVar(rvUserGroup)
    const step7Text = userGroup === UserGroup.NonTeachingStaff ? "By selecting ‘My wellbeing’ you can access your personal data and the check in function." : "When your teacher opens a check-in session, select this button to start your check-in."
    const step7Image = userGroup === UserGroup.NonTeachingStaff ? OnboardingStep7StaffImage : OnboardingStep7Image
    return <StudentAndNonTeachingStaffOnBoardingStepDefault handleNext={handleNext} text={step7Text} image={step7Image} nextButtonTitle='CLOSE' />
}

const StudentAndNonTeachingStaffOnBoardingStepDefault = ({ handleNext, text, image, textStyle, nextButtonTitle }: DefaultProps) => {
    const { responsiveFontSize } = useResponsive()
    const Image = image
    return (
        <View style={styles.content}>
            <View style={styles.contentContainerLeft}>
                <View style={styles.imageContainer}>
                    <Image />
                </View>
                <View style={styles.buttonsContainer}>
                    <DefaultButton style={styles.nextButton} buttonTextStyle={[styles.nextText, { fontSize: responsiveFontSize(24) }]} onPress={handleNext}>
                        {nextButtonTitle || "NEXT"}
                    </DefaultButton>
                </View>
            </View>
            <View style={styles.contentContainer}>
                <DefaultText style={[styles.step4Text, textStyle, { fontSize: responsiveFontSize() }]}>{text}</DefaultText>
                <View style={styles.imageContainer}>
                    <OnBoardingAndi />
                </View>
            </View>
        </View>
    )
}


export {
    StudentAndNonTeachingStaffOnBoardingStep1,
    StudentAndNonTeachingStaffOnBoardingStep2,
    StudentAndNonTeachingStaffOnBoardingStep3,
    StudentAndNonTeachingStaffOnBoardingStep4,
    StudentAndNonTeachingStaffOnBoardingStep5,
    StudentAndNonTeachingStaffOnBoardingStep6,
    StudentAndNonTeachingStaffOnBoardingStep7
}
const styles = StyleSheet.create({
    content: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 20,
    },
    contentContainer: {
        flex: 1,
        alignItems: 'center',
        height: 300,
        justifyContent: 'space-evenly'
    },
    contentContainerLeft: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 300
    },
    buttonsContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 20,
    },
    nextButton: {
        width: 150,
        borderRadius: 20,
    },
    nextText: {
        fontSize: 24,
        fontWeight: 'bold'
    },
    imageContainer: {
        width: '100%',
        height: 200,
    },
    step3Text: {
        textAlign: 'center',
    },
    step4Text: {
        textAlign: 'center',
    }
})