import { ColorValue } from 'react-native'
import Svg, { G, Path, Circle, Line, SvgProps } from 'react-native-svg'

export const IconTirednessAsleep = ({ stroke }: { stroke?: ColorValue | undefined }) => (
    <Svg viewBox="0 0 110 110">
        <G transform="translate(-699.277 -959.608)">
            <G transform="translate(-5994.602 -1676.843)">
                <Path d="M6737.879,2739a41.5,41.5,0,1,0-41.5-41.5,41.5,41.5,0,0,0,41.5,41.5" fill="#fff" />
                <Circle cx="41.5" cy="41.5" r="41.5" transform="translate(6696.379 2656)" fill="none" stroke={stroke || "#5a5b5e"} strokeWidth="5" />
                <Path d="M6727.719,2684.461s-6.625,15.084-13.24-.516" fill="none" stroke={stroke || "#5a5b5e"} strokeLinecap="round" strokeWidth="5" />
                <Path d="M6761.219,2684.461s-6.625,15.084-13.24-.516" fill="none" stroke={stroke || "#5a5b5e"} strokeLinecap="round" strokeWidth="5" />
            </G>
            <Line x2="12.28" transform="translate(734.897 1031.933)" fill="none" stroke={stroke || "#5a5b5e"} strokeLinecap="round" strokeWidth="5" />
            <G transform="translate(-5994.602 -1676.843)">
                <Path d="M6747.434,2712.842s-11.116-20.4-24.288,0" fill="none" stroke={stroke || "#5a5b5e"} strokeLinecap="round" strokeWidth="5" />
            </G>
            <Line x1="24.288" transform="translate(728.544 1036)" fill="none" stroke={stroke || "#5a5b5e"} strokeLinecap="round" strokeWidth="5" />
            <G transform="translate(-5994.602 -1676.843)">
                <Path d="M6763.078,2652.349h6l-8.9,12.1a3.536,3.536,0,0,0-.3,3.7,3.437,3.437,0,0,0,3.1,1.9h12.9a3.5,3.5,0,0,0,0-7h-6l8.9-12.2a3.541,3.541,0,0,0,.3-3.7,3.437,3.437,0,0,0-3.1-1.9h-12.9a3.546,3.546,0,0,0-3.5,3.5,3.441,3.441,0,0,0,3.5,3.6Z" fill={stroke || "#5a5b5e"} />
                <Path d="M6763.078,2652.349h6l-8.9,12.1a3.536,3.536,0,0,0-.3,3.7,3.437,3.437,0,0,0,3.1,1.9h12.9a3.5,3.5,0,0,0,0-7h-6l8.9-12.2a3.541,3.541,0,0,0,.3-3.7,3.437,3.437,0,0,0-3.1-1.9h-12.9a3.546,3.546,0,0,0-3.5,3.5,3.441,3.441,0,0,0,3.5,3.6Z" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                <Path d="M6800.68,2654.752h-6l8.9-12.2a3.537,3.537,0,0,0,.3-3.7,3.436,3.436,0,0,0-3.1-1.9l-13,0a3.5,3.5,0,0,0,0,7h6l-8.9,12.2a3.541,3.541,0,0,0-.3,3.7,3.437,3.437,0,0,0,3.1,1.9l13,0a3.546,3.546,0,0,0,3.5-3.5,3.479,3.479,0,0,0-3.5-3.5" fill={stroke || "#5a5b5e"} />
                <Path d="M6800.68,2654.752h-6l8.9-12.2a3.537,3.537,0,0,0,.3-3.7,3.436,3.436,0,0,0-3.1-1.9l-13,0a3.5,3.5,0,0,0,0,7h6l-8.9,12.2a3.541,3.541,0,0,0-.3,3.7,3.437,3.437,0,0,0,3.1,1.9l13,0a3.546,3.546,0,0,0,3.5-3.5A3.479,3.479,0,0,0,6800.68,2654.752Z" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
            </G>
        </G>
    </Svg>
)