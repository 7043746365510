import { View, StyleSheet, StyleProp, TextStyle } from "react-native";
import { DefaultText } from "../../common/default-text";
import { ExtraTipsIcon } from "./extra-tips";

interface Props {
    textdata: string
    style?: StyleProp<TextStyle>

}


export const DefaultTextWithExtraTipIcon = ({ textdata }: Props) => (
    <View style={styles.content}>
        <View style={styles.containingView}>
            <View style={styles.icon}>
                <View style={styles.extraTipsIcon}>
                    <ExtraTipsIcon />
                </View>
            </View>
            <DefaultText style={styles.text}>
                {textdata}
            </DefaultText>
        </View>

    </View>
)



const styles = StyleSheet.create({

    icon: {
    },
    content: {

        marginRight: 60,
        marginLeft: 20,
        justifyContent: 'center',
    },
    text: {
        marginLeft: 20,

    },
    extraTipsIcon: {
        width: 50,
        height: 50,

    },
    containingView: {
        marginTop: 40,
        flexDirection: 'row',
        alignItems: 'flex-start',
    }
})

