import { Emotion } from "../../models";
import { SwitchesActivitiesActivitySheets } from "../activities/cards/activity_sheets";
import { SwitchesActivitiesCreativityTwoTruthsOneLie } from "../activities/cards/creativity/2-truths-1-lie";
import { SwitchesActivitiesHealthyHabitsBlindDrawing } from "../activities/cards/creativity/blind-drawing";
import { SwitchesActivitiesCreativityBrainstormBlitz } from "../activities/cards/creativity/brainstorm-blitz";
import { SwitchesActivitiesCreativityCharades } from "../activities/cards/creativity/charades";
import { SwitchesActivitiesCreativityCreateNewWord } from "../activities/cards/creativity/create-new-word";
import { SwitchesActivitiesHealthyHabitsDoodling } from "../activities/cards/creativity/doodling";
import { SwitchesActivitiesCreativityWordShowdown } from "../activities/cards/creativity/word-showdown";
import { SwitchesActivitiesEnergizersHeadKneeToesHat } from "../activities/cards/energizers/head-knee-toes-hat";
import { SwitchesActivitiesEnergizersHelloKitty } from "../activities/cards/energizers/hello-kitty";
import { SwitchesActivitiesEnergizersHighFive } from "../activities/cards/energizers/high-five";
import { SwitchesActivitiesEnergizersLookAround } from "../activities/cards/energizers/look-around";
import { SwitchesActivitiesEnergizersNinjaDestruction } from "../activities/cards/energizers/ninja-destruction";
import { SwitchesActivitiesRockPaperScissors } from "../activities/cards/energizers/rock-paper-scissors";
import { SwitchesActivitiesEnergizersSecretIdentity } from "../activities/cards/energizers/secret-identity";
import { SwitchesActivitiesEnergizersShakeDown } from "../activities/cards/energizers/shake-down";
import { SwitchesActivitiesEnergizersSyncClaps } from "../activities/cards/energizers/sync-claps";
import { SwitchesActivitiesEnergizersYoshiBushi } from "../activities/cards/energizers/yoshi-bushi";
import { SwitchesActivitiesRemoteClassesHighFiveElimination } from "../activities/cards/for-remote-classes/high-five-elmination";
import { SwitchesActivitiesRemoteClassesPicasso } from "../activities/cards/for-remote-classes/picasso";
import { SwitchesActivitiesRemoteClassesScavengerHunt } from "../activities/cards/for-remote-classes/scavenger-hunt";
import { SwitchesActivitiesRemoteClassesTruthOrLie } from "../activities/cards/for-remote-classes/truth-or-lie";
import { SwitchesActivitiesRemoteClassesVacationPin } from "../activities/cards/for-remote-classes/vacation-pin";
import { SwitchesActivitiesRemoteClassesVideoCritic } from "../activities/cards/for-remote-classes/video-critic";
import { SwitchesActivitiesFunAndPositiveExceptionalAwards } from "../activities/cards/fun-and-positive/exceptional-awards";
import { SwitchesActivitiesFunAndPositiveGratitudeCards } from "../activities/cards/fun-and-positive/gratitude-cards";
import { SwitchesActivitiesFunAndPositiveShowMeTheBunny } from "../activities/cards/fun-and-positive/show-me-the-bunny";
import { SwitchesActivitiesFunAndPositiveTheGreatShowdown } from "../activities/cards/fun-and-positive/the-great-showdown";
import { SwitchesActivitiesFunAndPositiveWarmFuzzies } from "../activities/cards/fun-and-positive/warm-fuzzies";
import { SwitchesActivitiesHealthyHabitsColouringIn } from "../activities/cards/healthyhabits/colouring-in";
import { SwitchesActivitiesHealthyHabitsCompassion } from "../activities/cards/healthyhabits/compassion";
import { SwitchesActivitiesHealthyHabitsCraftTime } from "../activities/cards/healthyhabits/craft-time";
import { SwitchesActivitiesHealthyHabitsGetSomeSun } from "../activities/cards/healthyhabits/get-some-sun";
import { SwitchesActivitiesHealthyHabitsJigsaw } from "../activities/cards/healthyhabits/jigsaw";
import { SwitchesActivitiesHealthyHabitsSing } from "../activities/cards/healthyhabits/sing";
import { SwitchesActivitiesHealthyHabitsSleep } from "../activities/cards/healthyhabits/sleep";
import { SwitchesActivitiesNumberAndWordGamesFiveFul } from "../activities/cards/numbers-and-words/five-ful";
import { SwitchesActivitiesNumberAndWordsGuessTheWord } from "../activities/cards/numbers-and-words/guess-the-word";
import { SwitchesActivitiesNumberAndWordGamesNineNineNine } from "../activities/cards/numbers-and-words/nineninenine";
import { SwitchesActivitiesNumberAndWordGamesSpellingEliminationGame } from "../activities/cards/numbers-and-words/spelling-elimination";
import { SwitchesActivitiesNumberAndWordGamesWordJumble } from "../activities/cards/numbers-and-words/word-jumble";
import { SwitchesActivitiesNumberAndWordGamesWordSearch } from "../activities/cards/numbers-and-words/word-search";
import { SwitchesActivitiesResilienceDuckDuckSabotage } from "../activities/cards/resilience-builders/duck-duck-sabotage";
import { SwitchesActivitiesResilienceHumanKnot } from "../activities/cards/resilience-builders/human-knot";
import { SwitchesActivitiesResilienceBuildersPaperCastle } from "../activities/cards/resilience-builders/paper-castle";
import { SwitchesActivitiesResilienceStandTogether } from "../activities/cards/resilience-builders/stand-together";
import { SwitchesActivitiesResilienceBuildersStressBalls } from "../activities/cards/resilience-builders/stress-balls";
import { SwitchesActivitiesSecretsForSleepBrainTapping } from "../activities/cards/secrets-for-sleep/brain-tapping";
import { SwitchesActivitiesSecretsForSleepBucketOfSafeThoughtsBlue } from "../activities/cards/secrets-for-sleep/bucket-of-safe-thoughts-blue";
import { SwitchesEmotionsAnxiousDreamBuilder } from "../activities/cards/secrets-for-sleep/dream-builder";
import { SwitchesActivitiesSecretsForSleepMilitarySleepingHabbit } from "../activities/cards/secrets-for-sleep/military-sleep-habit";
import { SwitchesEmotionsAnxiousSleepChalkBlue } from "../activities/cards/secrets-for-sleep/sleep-chalk-blue";
import { SwitchesActivitiesSecretsForSleepSleepOasis } from "../activities/cards/secrets-for-sleep/sleep-oasis";
import { SwitchesActivitiesSecretsForSleepSleepTraining } from "../activities/cards/secrets-for-sleep/sleep-training";
import { SwitchesActivitiesSecretsForSleepTheHealthySleeper } from "../activities/cards/secrets-for-sleep/the-healthy-sleeper";
import { SwitchesActivitiesSeparationAnxietyFurryFrined } from "../activities/cards/separation-anxiety/furry-friend";
import { SwitchesActivitiesSeparationAxietyForTeachers } from "../activities/cards/separation-anxiety/note-for-teachers";
import { SwitchesActivitiesSeparationAnxietyPlayTime } from "../activities/cards/separation-anxiety/play-time";
import { SwitchesActivitiesSeparationAnxietyTheSafeSpaceWall } from "../activities/cards/separation-anxiety/the-safe-space-wall";
import { SwitchesActivitiesSeparationAnxietyTheVoyager } from "../activities/cards/separation-anxiety/the-voyager";
import { SwitchesActivitiesSeparationAnxietyWhatWouldThink } from "../activities/cards/separation-anxiety/what-would-think";
import { Activities } from "../common/activities";
import { CardColorScheme } from "../common/card-color-scheme";
import { CardFilterEmotionType, CardIntensitylevelType, CardTemplate } from "../common/card-template";
import { SwitchesLessonPlansAdvancedConflictManagement } from "../lesson-plan-cards/advanced-conflict-management";
import { SwitchesLessonPlansAdvancedEmpathy } from "../lesson-plan-cards/advanced-emphaty";
import { SwitchesLessonPlansConflictResolution } from "../lesson-plan-cards/conflict-resolution";
import { SwitchesLessonPlansEmotionalExpression } from "../lesson-plan-cards/emotional-expression";
import { SwitchesLessonPlansEmotionalIntelligenceInLeadership } from "../lesson-plan-cards/emotional-intelligence-in-leadership";
import { SwitchesLessonPlansEmotionalIntelligenceInRelationships } from "../lesson-plan-cards/emotional-intelligence-in-relationship";
import { SwitchesLessonPlansEmotionalManagement } from "../lesson-plan-cards/emotional-management";
import { SwitchesLessonPlansEmotionalRegulation } from "../lesson-plan-cards/emotional-regulations";
import { SwitchesLessonPlansEmotionalResilience } from "../lesson-plan-cards/emotional-resilience";
import { SwitchesLessonPlansEmotionsVersusFeelings } from "../lesson-plan-cards/emotions-versus-feelings";
import { SwitchesLessonPlansEmphaty } from "../lesson-plan-cards/emphaty";
import { SwitchesLessonPlansFeelingsVolcano } from "../lesson-plan-cards/feelings-volcano";
import { SwitchesLessonPlansGettingStarted } from "../lesson-plan-cards/getting-started";
import { SwitchesLessonPlansIntroducingAngry } from "../lesson-plan-cards/introducing-angry";
import { SwitchesLessonPlansIntroducingAnxiety } from "../lesson-plan-cards/introducing-anxiety";
import { SwitchesLessonPlansIntroducingExcited } from "../lesson-plan-cards/introducing-excited";
import { SwitchesLessonPlansIntroducingHappy } from "../lesson-plan-cards/introducing-happy";
import { SwitchesLessonPlansIntroducingSad } from "../lesson-plan-cards/introducing-sad";
import { SwitchesLessonPlansIntroducingScared } from "../lesson-plan-cards/introducing-scared";
import { SwitchesLessonPlansIntroductionToAngry } from "../lesson-plan-cards/introduction-to-angry";
import { SwitchesLessonPlansIntroductionToAnxious } from "../lesson-plan-cards/introduction-to-anxious";
import { SwitchesLessonPlansIntroductionToExcited } from "../lesson-plan-cards/introduction-to-excited";
import { SwitchesLessonPlansIntroductionToHappy } from "../lesson-plan-cards/introduction-to-happy";
import { SwitchesLessonPlansIntroductionToSad } from "../lesson-plan-cards/introduction-to-sad";
import { SwitchesLessonPlansIntroductionToScared } from "../lesson-plan-cards/introduction-to-scared";
import { SwitchesLessonPlansM1ExploringAndEmbracingEmotion } from '../lesson-plan-cards/m1-exploring-and-embracing-emotions';
import { SwitchesLessonPlansM10AdvancedResilience } from "../lesson-plan-cards/m10-advanced-resilience";
import { SwitchesLessonPlansM11UnderstandingChangeAndAdaptability } from "../lesson-plan-cards/m11-understanding-change-and-adaptability";
import { SwitchesLessonPlansM12SelfAwarenessAndWellbeing } from "../lesson-plan-cards/m12-self-awareness-and-wellbeing";
import { SwitchesLessonPlansM2EmotionalIntellegenceInDecissionMaking } from '../lesson-plan-cards/m2-emotional-intellegence-in-decission-making';
import { SwitchesLessonPlansM3ThePersuitOfHappiness } from '../lesson-plan-cards/m3-the-persuit-of-happiness';
import { SwitchesLessonPlansM4UnderstandingAnxiety } from '../lesson-plan-cards/m4-understanding-anxiety';
import { SwitchesLessonPlansM5InvestigatingAnger } from "../lesson-plan-cards/m5-investigating-anger";
import { SwitchesLessonPlansM6NavigatingSadness } from "../lesson-plan-cards/m6-navigating-sadness";
import { SwitchesLessonPlansM7ConqueringFear } from "../lesson-plan-cards/m7-conquering fear";
import { SwitchesLessonPlansM8HarnessingExcitement } from "../lesson-plan-cards/m8-harnessing-excitement";
import { SwitchesLessonPlansM9EmotionalAgility } from "../lesson-plan-cards/m9-emotional-agility";
import { SwitchesLessonPlansMyEmotionsLessonPlans } from "../lesson-plan-cards/my-emotions-leasson-plans";
import { SwitchesLessonPlansMyEmotionsLessonPlansLevel1 } from "../lesson-plan-cards/my-emotions-leasson-plans-level-1";
import { SwitchesLessonPlansPerspectiveTaking } from "../lesson-plan-cards/perespective-taking";
import { SwitchesLessonPlansSelfAwarenessAndEmotionalAwareness } from "../lesson-plan-cards/self-awareness-and-emotional-awareness";
import { SwitchesLessonPlansSocialIntelligence } from "../lesson-plan-cards/social-intelligence";
import { SwitchesLessonPlansUnderstandingAndManagingAnger } from "../lesson-plan-cards/understanding-and-managing-anger";
import { SwitchesLessonPlansUnderstandingAndManagingAnxiety } from "../lesson-plan-cards/understanding-and-managing-anxiety";
import { SwitchesLessonPlansUnderstandingAndManagingExcitement } from "../lesson-plan-cards/understanding-and-managing-excitement";
import { SwitchesLessonPlansUnderstandingAndManagingHapiness } from "../lesson-plan-cards/understanding-and-managing-hapiness";
import { SwitchesLessonPlansUnderstandingAndManagingSadness } from "../lesson-plan-cards/understanding-and-managing-sadness";
import { SwitchesLessonPlansUnderstandingAndManagingScared } from "../lesson-plan-cards/understanding-and-managing-scared";
import { SwitchesLessonPlansUnderstandingEmotions } from "../lesson-plan-cards/understanding-emotions";
import { ToolsAuslan } from "../tools/cards/auslan";
import { ToolsEmotionMeter } from "../tools/cards/emotion-meter";
import { ToolsFeelingDictionary } from "../tools/cards/feeling-dictionary";
import { ToolsHandBrain } from "../tools/cards/hand-brain";
import { ToolsHelpingHands } from "../tools/cards/helping-hands";
import { ToolsLessonPlans } from "../tools/cards/lesson-plans";
import { ToolsPhysiologyOfEmotion } from "../tools/cards/physiology-of-emotion";
import { ToolsPosters } from "../tools/cards/posters";
import { ToolsProfessionalHelp } from "../tools/cards/professional-help";
import { ToolsTheLittleBookOfBigEmotions } from "../tools/cards/the-little-book-of-big-emotions";
import { ToolsVideoLibrary } from "../tools/cards/video-library";
import { ToolsYouAndYourApe } from "../tools/cards/you-and-your-ape";
import { SwitchesEmotionsAngryBodyScan } from "./angry/body-scan";
import { SwitchesEmotionsAngryDrawBreath } from "./angry/breath-draw";
import { SwitchesEmotionsAngryCalmDownCorner } from "./angry/calm-down-corner";
import { SwitchesEmotionsAngryCalmingBreath } from "./angry/calming-breath";
import { SwitchesEmotionsAngryFindYourCalm } from "./angry/find-your-calm";
import { SwitchesEmotionsAngryFiveFingerBreathing } from "./angry/five-finger-breathing";
import { SwitchesEmotionsAngryFiveWhys } from "./angry/five-whys";
import { SwitchesEmotionsAngryHeadRolls } from "./angry/head-rolls";
import { SwitchesEmotionsAngryMotivationMap } from "./angry/motivation-map";
import { SwitchesEmotionsAngryPalmPush } from "./angry/palm-push";
import { SwitchesEmotionsAngryRespond } from "./angry/respond";
import { SwitchesEmotionsAngrySafeRant } from "./angry/safe-rant";
import { SwitchesEmotionsAngryScrunchie } from "./angry/scrunchie";
import { SwitchesEmotionsAngrySignificance } from "./angry/significance";
import { SwitchesEmotionsAngryStopSteps } from "./angry/stop-steps";
import { SwitchesEmotionsAngryStoryTime } from "./angry/story-time";
import { SwitchesEmotionsAngryTakeABreak } from "./angry/take-a-break";
import { SwitchesEmotionsAngryTenBreaths } from "./angry/ten-breaths";
import { SwitchesEmotionsAngryTheDoOver } from "./angry/the-do-over";
import { SwitchesEmotionsAngryTime } from "./angry/time";
import { SwitchesEmotionsAgryVideo } from "./angry/videos-angry";
import { SwitchesEmotionsAnxiousBoxBreathing } from "./anxious/box-breathing";
import { SwitchesEmotionsAnxiousBreathFloat } from "./anxious/breath-float";
import { SwitchesEmotionsAnxiousBubbleOfControl } from "./anxious/bubble-of-control";
import { SwitchesEmotionsAnxiousBucketOfSafeThoughts } from "./anxious/buckets-of-safe-thoughts";
import { SwitchesEmotionsAnxiousButterflyHug } from "./anxious/butterfly-hug";
import { SwitchesEmotionsAnxiousCircleOfInfluence } from "./anxious/circle-of-influence";
import { SwitchesEmotionsAnxiousDearDiaryAnxious } from "./anxious/dear-diary-anxious";
import { SwitchesEmotionsAnxiousDrinkOfWater } from "./anxious/drink-of-water";
import { SwitchesEmotionsAnxiousHotCold } from "./anxious/hot-cold";
import { SwitchesEmotionsAnxiousPinwheel } from "./anxious/pinwheel";
import { SwitchesEmotionsAnxiousRealityCheck } from "./anxious/reality-check";
import { SwitchesEmotionsAnxiousReframe } from "./anxious/reframe";
import { SwitchesEmotionsAnxiousSleepChalk } from "./anxious/sleep-chalk";
import { SwitchesEmotionsAnxiousStoryboarding } from "./anxious/storyboarding";
import { SwitchesEmotionsAnxiousTenseAndRelax } from "./anxious/tense-and-relax";
import { SwitchesEmotionsAnxiousTheCalmerFarmer } from "./anxious/the-calmer-farmer";
import { SwitchesEmotionsAnxiousTheInteruptor } from "./anxious/the-interuptor";
import { SwitchesEmotionsAnxiousVideos } from "./anxious/videos-anxious";
import { SwitchesEmotionsAnxiousWorryMonster } from "./anxious/worry-monster";
import { SwitchesEmotionsExcitedBuild } from "./excited/build";
import { SwitchesEmotionsExcitedCalmingMazeGames } from "./excited/calming-maze-games";
import { SwitchesEmotionsExcitedColorHunt } from "./excited/color-hunt";
import { SwitchesEmotionsExcitedVideos } from "./excited/excited-video";
import { SwitchesEmotionsExcitedExerciseOrDance } from "./excited/exercise-or-dance";
import { SwitchesEmotionsExcitedFingerTipRub } from "./excited/finger-tip-rub";
import { SwitchesEmotionsExcitedListenOut } from "./excited/listen-out";
import { SwitchesEmotionsExcitedPictureCompleter } from "./excited/picture-completer";
import { SwitchesEmotionsExcitedRead } from "./excited/read";
import { SwitchesEmotionsExcitedRewardDream } from "./excited/reward-dream";
import { SwitchesEmotionsExcitedSketchIt } from "./excited/sketch-it";
import { SwitchesEmotionsExcitedTheRiddler } from "./excited/the-riddler";
import { SwitchesEmotionsExcitedTheTwister } from "./excited/the-twister";
import { SwitchesEmotionsExcitedThreeSketches } from "./excited/three-sketches";
import { SwitchesEmotionsExcitedTraceBreathing } from "./excited/trace-breathing";
import { SwitchesEmotionsExcitedTreasureHunt } from "./excited/treasure-hunt";
import { SwitchesEmotionsExcitedWallsPushUps } from "./excited/wall-push-ups";
import { SwitchesEmotionsExcitedWeatherIt } from "./excited/weather-it";
import { SwitchesEmotionsHappyBeCreative } from "./happy/be-creative";
import { SwitchesEmotionsHappyDrawHappiness } from "./happy/draw-happiness";
import { SwitchesEmotionsHappyFriendFinder } from "./happy/friend-finder";
import { SwitchesEmotionsHappyGratefulness } from "./happy/gratefulness";
import { SwitchesEmotionsHappyInspirationStation } from "./happy/inspiration-station";
import { SwitchesEmotionsHappyJournaling } from "./happy/journaling";
import { SwitchesEmotionsHappyKindess } from "./happy/kindess";
import { SwitchesEmotionsHappyLaugh } from "./happy/laugh";
import { SwitchesEmotionsHappyLimerick } from "./happy/limerick";
import { SwitchesEmotionsHappyMakeANewFriend } from "./happy/make-a-new-friend";
import { SwitchesEmotionsHappyNewConnection } from "./happy/new-connection";
import { SwitchesEmotionsHappyRandomActOfKindness } from "./happy/random-act-of-kindness";
import { SwitchesEmotionsHappySmartGoals } from "./happy/smart-goals";
import { SwitchesEmotionsHappyStarsAndWishes } from "./happy/stars-and-wishes";
import { SwitchesEmotionsHappyStorySentence } from "./happy/story-sentence";
import { SwitchesEmotionsHappyTheHappyMonk } from "./happy/the-happy-monk";
import { SwitchesEmotionsHappyTheInquisitor } from "./happy/the-inquisitor";
import { SwitchesEmotionsHappyValues } from "./happy/values";
import { SwitchesEmotionsHappyVideos } from "./happy/videos-happy";
import { SwitchesEmotionsHappyWhyYouLike } from "./happy/why-you-like";
import { SwitchesEmotionsSadAnimalMashup } from "./sad/animal-mashup";
import { SwitchesEmotionsSadBeSocial } from "./sad/be-social";
import { SwitchesEmotionsSadBucketList } from "./sad/bucket-list";
import { SwitchesEmotionsSadFutureLetter } from "./sad/future-letter";
import { SwitchesEmotionsSadGrowthMindset } from "./sad/growth-mindset";
import { SwitchesEmotionsSadHug } from "./sad/hug";
import { SwitchesEmotionsSadLikePage } from "./sad/like-page";
import { SwitchesEmotionsSadMuisc } from "./sad/music";
import { SwitchesEmotionsSadReachOut } from "./sad/reach-out";
import { SwitchesEmotionsSadSereneGarden } from "./sad/serene-garden";
import { SwitchesEmotionsSadSmallSteps } from "./sad/small-steps";
import { SwitchesEmotionsSadSmile } from "./sad/smile";
import { SwitchesEmotionsSadStretch } from "./sad/stretch";
import { SwitchesEmotionsSadTalkToAFriend } from "./sad/talk-to-a-friend";
import { SwitchesEmotionsSadTheOrganizer } from "./sad/the-organizer";
import { SwitchesEmotionsSadTheScribe } from "./sad/the-scribe";
import { SwitchesEmotionsSadVideos } from "./sad/videos-sad";
import { SwitchesEmotionsSadWalk } from "./sad/walk";
import { SwitchesEmotionsScaredBalonBreathing } from "./scared/balon-breathing";
import { SwitchesEmotionsScaredBreathCycle } from "./scared/breath-cycle";
import { SwitchesEmotionsScaredCartoonIt } from "./scared/cartoon-it";
import { SwitchesEmotionsScaredCountDown } from "./scared/countdown";
import { SwitchesEmotionsScaredExposeIt } from "./scared/expose-it";
import { SwitchesEmotionsScaredFaceIt } from "./scared/face-it";
import { SwitchesEmotionsScaredFunnyVoice } from "./scared/funny-voice";
import { SwitchesEmotionsScaredHelpOrHurt } from "./scared/help-or-hurt";
import { SwitchesEmotionsScaredIdentify5 } from "./scared/identify-5";
import { SwitchesEmotionsScaredMantra } from "./scared/mantra";
import { SwitchesEmotionsScaredMySafePlace } from "./scared/my-safe-place";
import { SwitchesEmotionsScaredRoleModel } from "./scared/role-model";
import { SwitchesEmotionsScaredSeeIt } from "./scared/see-it";
import { SwitchesEmotionsScaredSeekHelp } from "./scared/seek-help";
import { SwitchesEmotionsScaredTalkAboutIt } from "./scared/talk-about-it";
import { SwitchesEmotionsScaredTheAnticipator } from "./scared/the-anticipator";
import { SwitchesEmotionsScaredVideos } from "./scared/videos-scared";
import { SwitchesEmotionsScaredVisualise } from "./scared/visualise";


export const cards = [
    SwitchesActivitiesActivitySheets,
    SwitchesActivitiesCreativityBrainstormBlitz,
    SwitchesActivitiesCreativityCharades,
    SwitchesActivitiesCreativityCreateNewWord,
    SwitchesActivitiesCreativityTwoTruthsOneLie,
    SwitchesActivitiesCreativityWordShowdown,
    SwitchesActivitiesEnergizersHeadKneeToesHat,
    SwitchesActivitiesEnergizersHelloKitty,
    SwitchesActivitiesEnergizersHighFive,
    SwitchesActivitiesEnergizersLookAround,
    SwitchesActivitiesEnergizersNinjaDestruction,
    SwitchesActivitiesEnergizersSecretIdentity,
    SwitchesActivitiesEnergizersShakeDown,
    SwitchesActivitiesEnergizersSyncClaps,
    SwitchesActivitiesEnergizersYoshiBushi,
    SwitchesActivitiesFunAndPositiveExceptionalAwards,
    SwitchesActivitiesFunAndPositiveGratitudeCards,
    SwitchesActivitiesFunAndPositiveShowMeTheBunny,
    SwitchesActivitiesFunAndPositiveTheGreatShowdown,
    SwitchesActivitiesFunAndPositiveWarmFuzzies,
    SwitchesActivitiesHealthyHabitsBlindDrawing,
    SwitchesActivitiesHealthyHabitsColouringIn,
    SwitchesActivitiesHealthyHabitsCompassion,
    SwitchesActivitiesHealthyHabitsCraftTime,
    SwitchesActivitiesHealthyHabitsDoodling,
    SwitchesActivitiesHealthyHabitsGetSomeSun,
    SwitchesActivitiesHealthyHabitsJigsaw,
    SwitchesActivitiesHealthyHabitsSing,
    SwitchesActivitiesHealthyHabitsSleep,
    SwitchesActivitiesNumberAndWordGamesFiveFul,
    SwitchesActivitiesNumberAndWordGamesNineNineNine,
    SwitchesActivitiesNumberAndWordGamesSpellingEliminationGame,
    SwitchesActivitiesNumberAndWordGamesWordJumble,
    SwitchesActivitiesNumberAndWordGamesWordSearch,
    SwitchesActivitiesNumberAndWordsGuessTheWord,
    SwitchesActivitiesRemoteClassesHighFiveElimination,
    SwitchesActivitiesRemoteClassesPicasso,
    SwitchesActivitiesRemoteClassesScavengerHunt,
    SwitchesActivitiesRemoteClassesTruthOrLie,
    SwitchesActivitiesRemoteClassesVacationPin,
    SwitchesActivitiesRemoteClassesVideoCritic,
    SwitchesActivitiesResilienceBuildersPaperCastle,
    SwitchesActivitiesResilienceBuildersStressBalls,
    SwitchesActivitiesResilienceDuckDuckSabotage,
    SwitchesActivitiesResilienceHumanKnot,
    SwitchesActivitiesResilienceStandTogether,
    SwitchesActivitiesRockPaperScissors,
    SwitchesActivitiesSecretsForSleepBrainTapping,
    SwitchesActivitiesSecretsForSleepBucketOfSafeThoughtsBlue,
    SwitchesActivitiesSecretsForSleepMilitarySleepingHabbit,
    SwitchesActivitiesSecretsForSleepSleepOasis,
    SwitchesActivitiesSecretsForSleepSleepTraining,
    SwitchesActivitiesSecretsForSleepTheHealthySleeper,
    SwitchesActivitiesSeparationAnxietyFurryFrined,
    SwitchesActivitiesSeparationAnxietyPlayTime,
    SwitchesActivitiesSeparationAnxietyTheSafeSpaceWall,
    SwitchesActivitiesSeparationAnxietyTheVoyager,
    SwitchesActivitiesSeparationAnxietyWhatWouldThink,
    SwitchesActivitiesSeparationAxietyForTeachers,
    SwitchesEmotionsAgryVideo,
    SwitchesEmotionsAngryBodyScan,
    SwitchesEmotionsAngryCalmDownCorner,
    SwitchesEmotionsAngryCalmingBreath,
    SwitchesEmotionsAngryDrawBreath,
    SwitchesEmotionsAngryFindYourCalm,
    SwitchesEmotionsAngryFiveFingerBreathing,
    SwitchesEmotionsAngryFiveWhys,
    SwitchesEmotionsAngryHeadRolls,
    SwitchesEmotionsAngryMotivationMap,
    SwitchesEmotionsAngryPalmPush,
    SwitchesEmotionsAngryRespond,
    SwitchesEmotionsAngrySafeRant,
    SwitchesEmotionsAngryScrunchie,
    SwitchesEmotionsAngrySignificance,
    SwitchesEmotionsAngryStopSteps,
    SwitchesEmotionsAngryStoryTime,
    SwitchesEmotionsAngryTakeABreak,
    SwitchesEmotionsAngryTenBreaths,
    SwitchesEmotionsAngryTheDoOver,
    SwitchesEmotionsAngryTime,
    SwitchesEmotionsAnxiousBoxBreathing,
    SwitchesEmotionsAnxiousBreathFloat,
    SwitchesEmotionsAnxiousBubbleOfControl,
    SwitchesEmotionsAnxiousButterflyHug,
    SwitchesEmotionsScaredSeekHelp,
    SwitchesEmotionsAnxiousBucketOfSafeThoughts,
    SwitchesEmotionsAnxiousCircleOfInfluence,
    SwitchesEmotionsAnxiousDearDiaryAnxious,
    SwitchesEmotionsAnxiousDreamBuilder,
    SwitchesEmotionsAnxiousDrinkOfWater,
    SwitchesEmotionsAnxiousHotCold,
    SwitchesEmotionsAnxiousPinwheel,
    SwitchesEmotionsAnxiousRealityCheck,
    SwitchesEmotionsAnxiousReframe,
    SwitchesEmotionsAnxiousSleepChalk,
    SwitchesEmotionsAnxiousSleepChalkBlue,
    SwitchesEmotionsAnxiousStoryboarding,
    SwitchesEmotionsAnxiousTenseAndRelax,
    SwitchesEmotionsAnxiousTheCalmerFarmer,
    SwitchesEmotionsAnxiousTheInteruptor,
    SwitchesEmotionsAnxiousVideos,
    SwitchesEmotionsAnxiousWorryMonster,
    SwitchesEmotionsExcitedBuild,
    SwitchesEmotionsExcitedCalmingMazeGames,
    SwitchesEmotionsExcitedColorHunt,
    SwitchesEmotionsExcitedExerciseOrDance,
    SwitchesEmotionsExcitedFingerTipRub,
    SwitchesEmotionsExcitedListenOut,
    SwitchesEmotionsExcitedPictureCompleter,
    SwitchesEmotionsExcitedRead,
    SwitchesEmotionsExcitedRewardDream,
    SwitchesEmotionsExcitedSketchIt,
    SwitchesEmotionsExcitedTheRiddler,
    SwitchesEmotionsExcitedTheTwister,
    SwitchesEmotionsExcitedThreeSketches,
    SwitchesEmotionsExcitedTraceBreathing,
    SwitchesEmotionsExcitedTreasureHunt,
    SwitchesEmotionsExcitedVideos,
    SwitchesEmotionsExcitedWallsPushUps,
    SwitchesEmotionsExcitedWeatherIt,
    SwitchesEmotionsHappyBeCreative,
    SwitchesEmotionsHappyDrawHappiness,
    SwitchesEmotionsHappyFriendFinder,
    SwitchesEmotionsHappyGratefulness,
    SwitchesEmotionsHappyInspirationStation,
    SwitchesEmotionsHappyJournaling,
    SwitchesEmotionsHappyLaugh,
    SwitchesEmotionsHappyLimerick,
    SwitchesEmotionsHappyMakeANewFriend,
    SwitchesEmotionsHappyNewConnection,
    SwitchesEmotionsHappyRandomActOfKindness,
    SwitchesEmotionsHappySmartGoals,
    SwitchesEmotionsHappyStarsAndWishes,
    SwitchesEmotionsHappyStorySentence,
    SwitchesEmotionsHappyTheHappyMonk,
    SwitchesEmotionsHappyTheInquisitor,
    SwitchesEmotionsHappyValues,
    SwitchesEmotionsHappyVideos,
    SwitchesEmotionsHappyWhyYouLike,
    SwitchesEmotionsSadAnimalMashup,
    SwitchesEmotionsSadBeSocial,
    SwitchesEmotionsSadBucketList,
    SwitchesEmotionsSadFutureLetter,
    SwitchesEmotionsSadGrowthMindset,
    SwitchesEmotionsSadHug,
    SwitchesEmotionsSadLikePage,
    SwitchesEmotionsSadMuisc,
    SwitchesEmotionsSadReachOut,
    SwitchesEmotionsSadSereneGarden,
    SwitchesEmotionsSadSmallSteps,
    SwitchesEmotionsSadSmile,
    SwitchesEmotionsSadStretch,
    SwitchesEmotionsSadTheOrganizer,
    SwitchesEmotionsSadTheScribe,
    SwitchesEmotionsSadVideos,
    SwitchesEmotionsSadWalk,
    SwitchesEmotionsScaredBalonBreathing,
    SwitchesEmotionsScaredBreathCycle,
    SwitchesEmotionsScaredCartoonIt,
    SwitchesEmotionsScaredCountDown,
    SwitchesEmotionsScaredExposeIt,
    SwitchesEmotionsScaredFaceIt,
    SwitchesEmotionsScaredFunnyVoice,
    SwitchesEmotionsScaredHelpOrHurt,
    SwitchesEmotionsScaredIdentify5,
    SwitchesEmotionsScaredMantra,
    SwitchesEmotionsScaredMySafePlace,
    SwitchesEmotionsScaredRoleModel,
    SwitchesEmotionsScaredSeeIt,
    SwitchesEmotionsScaredTalkAboutIt,
    SwitchesEmotionsScaredTheAnticipator,
    SwitchesEmotionsScaredVideos,
    SwitchesEmotionsScaredVisualise,
    SwitchesLessonPlansAdvancedConflictManagement,
    SwitchesLessonPlansAdvancedEmpathy,
    SwitchesLessonPlansConflictResolution,
    SwitchesLessonPlansEmotionalExpression,
    SwitchesLessonPlansEmotionalIntelligenceInLeadership,
    SwitchesLessonPlansEmotionalIntelligenceInRelationships,
    SwitchesLessonPlansEmotionalManagement,
    SwitchesLessonPlansEmotionalRegulation,
    SwitchesLessonPlansEmotionalResilience,
    SwitchesLessonPlansEmotionsVersusFeelings,
    SwitchesLessonPlansEmphaty,
    SwitchesLessonPlansFeelingsVolcano,
    SwitchesLessonPlansGettingStarted,
    SwitchesLessonPlansIntroducingAngry,
    SwitchesLessonPlansIntroducingAnxiety,
    SwitchesLessonPlansIntroducingExcited,
    SwitchesLessonPlansIntroducingHappy,
    SwitchesLessonPlansIntroducingSad,
    SwitchesLessonPlansIntroducingScared,
    SwitchesEmotionsSadTalkToAFriend,
    SwitchesLessonPlansIntroductionToAngry,
    SwitchesLessonPlansIntroductionToAnxious,
    SwitchesLessonPlansIntroductionToExcited,
    SwitchesLessonPlansIntroductionToHappy,
    SwitchesLessonPlansIntroductionToSad,
    SwitchesLessonPlansIntroductionToScared,
    SwitchesLessonPlansM1ExploringAndEmbracingEmotion,
    SwitchesLessonPlansM2EmotionalIntellegenceInDecissionMaking,
    SwitchesLessonPlansM3ThePersuitOfHappiness,
    SwitchesLessonPlansM4UnderstandingAnxiety,
    SwitchesLessonPlansM5InvestigatingAnger,
    SwitchesLessonPlansM6NavigatingSadness,
    SwitchesLessonPlansM7ConqueringFear,
    SwitchesLessonPlansM8HarnessingExcitement,
    SwitchesLessonPlansM9EmotionalAgility,
    SwitchesLessonPlansM10AdvancedResilience,
    SwitchesLessonPlansM11UnderstandingChangeAndAdaptability,
    SwitchesLessonPlansM12SelfAwarenessAndWellbeing,
    SwitchesLessonPlansMyEmotionsLessonPlans,
    SwitchesLessonPlansMyEmotionsLessonPlansLevel1,
    SwitchesLessonPlansPerspectiveTaking,
    SwitchesEmotionsHappyKindess,
    SwitchesLessonPlansSelfAwarenessAndEmotionalAwareness,
    SwitchesLessonPlansSocialIntelligence,
    SwitchesLessonPlansUnderstandingAndManagingAnger,
    SwitchesLessonPlansUnderstandingAndManagingAnxiety,
    SwitchesLessonPlansUnderstandingAndManagingExcitement,
    SwitchesLessonPlansUnderstandingAndManagingHapiness,
    SwitchesLessonPlansUnderstandingAndManagingSadness,
    SwitchesLessonPlansUnderstandingAndManagingScared,
    SwitchesLessonPlansUnderstandingEmotions,
    ToolsAuslan,
    ToolsEmotionMeter,
    ToolsFeelingDictionary,
    ToolsHandBrain,
    ToolsHelpingHands,
    ToolsLessonPlans,
    ToolsPhysiologyOfEmotion,
    ToolsPosters,
    ToolsProfessionalHelp,
    ToolsTheLittleBookOfBigEmotions,
    ToolsVideoLibrary,
    ToolsYouAndYourApe,
]

export const cardsPublic = [
    SwitchesActivitiesCreativityBrainstormBlitz,
    SwitchesActivitiesCreativityCharades,
    SwitchesActivitiesCreativityCreateNewWord,
    SwitchesActivitiesCreativityTwoTruthsOneLie,
    SwitchesActivitiesCreativityWordShowdown,
    SwitchesActivitiesHealthyHabitsBlindDrawing,
    SwitchesActivitiesHealthyHabitsColouringIn,
    SwitchesActivitiesHealthyHabitsCompassion,
    SwitchesActivitiesHealthyHabitsCraftTime,
    SwitchesActivitiesHealthyHabitsDoodling,
    SwitchesActivitiesHealthyHabitsGetSomeSun,
    SwitchesActivitiesHealthyHabitsJigsaw,
    SwitchesActivitiesHealthyHabitsSing,
    SwitchesActivitiesHealthyHabitsSleep,
    SwitchesActivitiesResilienceBuildersPaperCastle,
    SwitchesActivitiesResilienceBuildersStressBalls,
    SwitchesActivitiesResilienceDuckDuckSabotage,
    SwitchesActivitiesResilienceHumanKnot,
    SwitchesActivitiesResilienceStandTogether,
    SwitchesActivitiesRockPaperScissors,
    SwitchesActivitiesSecretsForSleepBrainTapping,
    SwitchesActivitiesSecretsForSleepBucketOfSafeThoughtsBlue,
    SwitchesActivitiesSecretsForSleepMilitarySleepingHabbit,
    SwitchesActivitiesSecretsForSleepSleepOasis,
    SwitchesActivitiesSecretsForSleepSleepTraining,
    SwitchesActivitiesSecretsForSleepTheHealthySleeper,
    SwitchesActivitiesSeparationAnxietyFurryFrined,
    SwitchesActivitiesSeparationAnxietyPlayTime,
    SwitchesActivitiesSeparationAnxietyTheSafeSpaceWall,
    SwitchesActivitiesSeparationAnxietyTheVoyager,
    SwitchesActivitiesSeparationAnxietyWhatWouldThink,
    SwitchesActivitiesSeparationAxietyForTeachers,
    SwitchesEmotionsAgryVideo,
    SwitchesEmotionsAngryBodyScan,
    SwitchesEmotionsAngryCalmDownCorner,
    SwitchesEmotionsAngryCalmingBreath,
    SwitchesEmotionsAngryDrawBreath,
    SwitchesEmotionsAngryFindYourCalm,
    SwitchesEmotionsAngryFiveFingerBreathing,
    SwitchesEmotionsAngryFiveWhys,
    SwitchesEmotionsAngryHeadRolls,
    SwitchesEmotionsAngryMotivationMap,
    SwitchesEmotionsAngryPalmPush,
    SwitchesEmotionsAngryRespond,
    SwitchesEmotionsAngrySafeRant,
    SwitchesEmotionsAngryScrunchie,
    SwitchesEmotionsAngrySignificance,
    SwitchesEmotionsAngryStopSteps,
    SwitchesEmotionsAngryStoryTime,
    SwitchesEmotionsAngryTakeABreak,
    SwitchesEmotionsAngryTenBreaths,
    SwitchesEmotionsAngryTheDoOver,
    SwitchesEmotionsAngryTime,
    SwitchesEmotionsAnxiousBoxBreathing,
    SwitchesEmotionsAnxiousBreathFloat,
    SwitchesEmotionsAnxiousBubbleOfControl,
    SwitchesEmotionsAnxiousBucketOfSafeThoughts,
    SwitchesEmotionsAnxiousButterflyHug,
    SwitchesEmotionsScaredSeekHelp,
    SwitchesEmotionsSadTalkToAFriend,
    SwitchesEmotionsAnxiousCircleOfInfluence,
    SwitchesEmotionsAnxiousDearDiaryAnxious,
    SwitchesEmotionsAnxiousDreamBuilder,
    SwitchesEmotionsAnxiousDrinkOfWater,
    SwitchesEmotionsAnxiousHotCold,
    SwitchesEmotionsAnxiousPinwheel,
    SwitchesEmotionsAnxiousRealityCheck,
    SwitchesEmotionsAnxiousReframe,
    SwitchesEmotionsAnxiousSleepChalk,
    SwitchesEmotionsAnxiousSleepChalkBlue,
    SwitchesEmotionsAnxiousStoryboarding,
    SwitchesEmotionsAnxiousTenseAndRelax,
    SwitchesEmotionsAnxiousTheCalmerFarmer,
    SwitchesEmotionsAnxiousTheInteruptor,
    SwitchesEmotionsAnxiousVideos,
    SwitchesEmotionsAnxiousWorryMonster,
    SwitchesEmotionsExcitedBuild,
    SwitchesEmotionsExcitedCalmingMazeGames,
    SwitchesEmotionsExcitedColorHunt,
    SwitchesEmotionsExcitedExerciseOrDance,
    SwitchesEmotionsExcitedFingerTipRub,
    SwitchesEmotionsExcitedListenOut,
    SwitchesEmotionsExcitedPictureCompleter,
    SwitchesEmotionsExcitedRead,
    SwitchesEmotionsExcitedRewardDream,
    SwitchesEmotionsExcitedSketchIt,
    SwitchesEmotionsExcitedTheRiddler,
    SwitchesEmotionsExcitedTheTwister,
    SwitchesEmotionsExcitedThreeSketches,
    SwitchesEmotionsExcitedTraceBreathing,
    SwitchesEmotionsExcitedTreasureHunt,
    SwitchesEmotionsExcitedVideos,
    SwitchesEmotionsExcitedWallsPushUps,
    SwitchesEmotionsExcitedWeatherIt,
    SwitchesEmotionsHappyBeCreative,
    SwitchesEmotionsHappyDrawHappiness,
    SwitchesEmotionsHappyFriendFinder,
    SwitchesEmotionsHappyGratefulness,
    SwitchesEmotionsHappyInspirationStation,
    SwitchesEmotionsHappyJournaling,
    SwitchesEmotionsHappyLaugh,
    SwitchesEmotionsHappyLimerick,
    SwitchesEmotionsHappyMakeANewFriend,
    SwitchesEmotionsHappyNewConnection,
    SwitchesEmotionsHappyRandomActOfKindness,
    SwitchesEmotionsHappySmartGoals,
    SwitchesEmotionsHappyStarsAndWishes,
    SwitchesEmotionsHappyStorySentence,
    SwitchesEmotionsHappyTheHappyMonk,
    SwitchesEmotionsHappyTheInquisitor,
    SwitchesEmotionsHappyValues,
    SwitchesEmotionsHappyVideos,
    SwitchesEmotionsHappyWhyYouLike,
    SwitchesEmotionsSadAnimalMashup,
    SwitchesEmotionsSadBeSocial,
    SwitchesEmotionsSadBucketList,
    SwitchesEmotionsSadFutureLetter,
    SwitchesEmotionsSadGrowthMindset,
    SwitchesEmotionsHappyKindess,
    SwitchesEmotionsSadHug,
    SwitchesEmotionsSadLikePage,
    SwitchesEmotionsSadMuisc,
    SwitchesEmotionsSadReachOut,
    SwitchesEmotionsSadSereneGarden,
    SwitchesEmotionsSadSmallSteps,
    SwitchesEmotionsSadSmile,
    SwitchesEmotionsSadTheOrganizer,
    SwitchesEmotionsSadTheScribe,
    SwitchesEmotionsSadWalk,
    SwitchesEmotionsScaredBalonBreathing,
    SwitchesEmotionsScaredBreathCycle,
    SwitchesEmotionsScaredCartoonIt,
    SwitchesEmotionsScaredCountDown,
    SwitchesEmotionsScaredExposeIt,
    SwitchesEmotionsScaredFaceIt,
    SwitchesEmotionsScaredFunnyVoice,
    SwitchesEmotionsScaredHelpOrHurt,
    SwitchesEmotionsScaredIdentify5,
    SwitchesEmotionsScaredMantra,
    SwitchesEmotionsScaredMySafePlace,
    SwitchesEmotionsScaredRoleModel,
    SwitchesEmotionsScaredSeeIt,
    SwitchesEmotionsScaredTalkAboutIt,
    SwitchesEmotionsScaredTheAnticipator,
    SwitchesEmotionsScaredVideos,
    SwitchesEmotionsScaredVisualise,
    ToolsAuslan,
    ToolsEmotionMeter,
    ToolsFeelingDictionary,
    ToolsHandBrain,
    ToolsPhysiologyOfEmotion,
    ToolsPosters,
    ToolsProfessionalHelp,
    ToolsTheLittleBookOfBigEmotions,
    ToolsVideoLibrary,
    ToolsYouAndYourApe,
]

export const archivedOrRemovedSwitches = [
    SwitchesEmotionsAnxiousSleepChalk,
    SwitchesEmotionsAnxiousTheInteruptor,
    SwitchesEmotionsExcitedBuild,
    SwitchesEmotionsHappyLimerick,
    SwitchesEmotionsSadWalk,
    SwitchesEmotionsExcitedPictureCompleter
]

export const switchesLessonPlans = [
    SwitchesLessonPlansAdvancedConflictManagement,
    SwitchesLessonPlansAdvancedEmpathy,
    SwitchesLessonPlansConflictResolution,
    SwitchesLessonPlansEmotionalExpression,
    SwitchesLessonPlansEmotionalIntelligenceInLeadership,
    SwitchesLessonPlansEmotionalIntelligenceInRelationships,
    SwitchesLessonPlansEmotionalManagement,
    SwitchesLessonPlansEmotionalRegulation,
    SwitchesLessonPlansEmotionalResilience,
    SwitchesLessonPlansEmotionsVersusFeelings,
    SwitchesLessonPlansEmphaty,
    SwitchesLessonPlansFeelingsVolcano,
    SwitchesLessonPlansGettingStarted,
    SwitchesLessonPlansIntroducingAngry,
    SwitchesLessonPlansIntroducingAnxiety,
    SwitchesLessonPlansIntroducingExcited,
    SwitchesLessonPlansIntroducingHappy,
    SwitchesLessonPlansIntroducingSad,
    SwitchesLessonPlansIntroducingScared,
    SwitchesLessonPlansIntroductionToAngry,
    SwitchesLessonPlansIntroductionToAnxious,
    SwitchesLessonPlansIntroductionToExcited,
    SwitchesLessonPlansIntroductionToHappy,
    SwitchesLessonPlansIntroductionToSad,
    SwitchesLessonPlansIntroductionToScared,
    SwitchesLessonPlansMyEmotionsLessonPlans,
    SwitchesLessonPlansMyEmotionsLessonPlansLevel1,
    SwitchesLessonPlansPerspectiveTaking,
    SwitchesLessonPlansSelfAwarenessAndEmotionalAwareness,
    SwitchesLessonPlansSocialIntelligence,
    SwitchesLessonPlansUnderstandingAndManagingAnger,
    SwitchesLessonPlansUnderstandingAndManagingAnxiety,
    SwitchesLessonPlansUnderstandingAndManagingExcitement,
    SwitchesLessonPlansUnderstandingAndManagingHapiness,
    SwitchesLessonPlansUnderstandingAndManagingSadness,
    SwitchesLessonPlansUnderstandingAndManagingScared,
    SwitchesLessonPlansUnderstandingEmotions,
    SwitchesLessonPlansM1ExploringAndEmbracingEmotion,
    SwitchesLessonPlansM2EmotionalIntellegenceInDecissionMaking,
    SwitchesLessonPlansM3ThePersuitOfHappiness,
    SwitchesLessonPlansM4UnderstandingAnxiety,
    SwitchesLessonPlansM5InvestigatingAnger,
    SwitchesLessonPlansM6NavigatingSadness,
    SwitchesLessonPlansM7ConqueringFear,
    SwitchesLessonPlansM8HarnessingExcitement,
    SwitchesLessonPlansM9EmotionalAgility,
    SwitchesLessonPlansM10AdvancedResilience,
    SwitchesLessonPlansM11UnderstandingChangeAndAdaptability,
    SwitchesLessonPlansM12SelfAwarenessAndWellbeing

]

const getCards = (level: CardIntensitylevelType, emotion: CardFilterEmotionType) =>
    cards.filter(c => c.level?.some(l => l === level) && c.emotion?.some(e => e === emotion))

export const angry7pointscards = getCards(CardIntensitylevelType.Seven, CardFilterEmotionType.Angry)
export const anxious7pointscards = getCards(CardIntensitylevelType.Seven, CardFilterEmotionType.Anxious)
export const excited7pointscards = getCards(CardIntensitylevelType.Seven, CardFilterEmotionType.Excited)
export const happy7pointscards = getCards(CardIntensitylevelType.Seven, CardFilterEmotionType.Happy)
export const sad7pointscards = getCards(CardIntensitylevelType.Seven, CardFilterEmotionType.Sad)
export const scared7pointscards = getCards(CardIntensitylevelType.Seven, CardFilterEmotionType.Scared)

export const angry5pointcards = getCards(CardIntensitylevelType.Five, CardFilterEmotionType.Angry)
export const anxious5pointcards = getCards(CardIntensitylevelType.Five, CardFilterEmotionType.Anxious)
export const excited5pointcards = getCards(CardIntensitylevelType.Five, CardFilterEmotionType.Excited)
export const happy5pointcards = getCards(CardIntensitylevelType.Five, CardFilterEmotionType.Happy)
export const sad5pointcards = getCards(CardIntensitylevelType.Five, CardFilterEmotionType.Sad)
export const scared5pointcards = getCards(CardIntensitylevelType.Five, CardFilterEmotionType.Scared)

export const angry3pointcards = getCards(CardIntensitylevelType.Three, CardFilterEmotionType.Angry)
export const anxious3pointcards = getCards(CardIntensitylevelType.Three, CardFilterEmotionType.Anxious)
export const excited3pointcards = getCards(CardIntensitylevelType.Three, CardFilterEmotionType.Excited)
export const happy3pointcards = getCards(CardIntensitylevelType.Three, CardFilterEmotionType.Happy)
export const sad3pointcards = getCards(CardIntensitylevelType.Three, CardFilterEmotionType.Sad)
export const scared3pointcards = getCards(CardIntensitylevelType.Three, CardFilterEmotionType.Scared)

export const angry1pointcards = getCards(CardIntensitylevelType.One, CardFilterEmotionType.Angry)
export const anxious1pointcards = getCards(CardIntensitylevelType.One, CardFilterEmotionType.Anxious)
export const excited1pointcards = getCards(CardIntensitylevelType.One, CardFilterEmotionType.Excited)
export const happy1pointcards = getCards(CardIntensitylevelType.One, CardFilterEmotionType.Happy)
export const sad1pointcards = getCards(CardIntensitylevelType.One, CardFilterEmotionType.Sad)
export const scared1pointcards = getCards(CardIntensitylevelType.One, CardFilterEmotionType.Scared)

export const healthyhabitscards = [
    SwitchesActivitiesHealthyHabitsColouringIn,
    SwitchesActivitiesHealthyHabitsCompassion,
    SwitchesActivitiesHealthyHabitsCraftTime,
    SwitchesActivitiesHealthyHabitsGetSomeSun,
    SwitchesActivitiesHealthyHabitsJigsaw,
    SwitchesActivitiesHealthyHabitsSing,
    SwitchesActivitiesHealthyHabitsSleep,
]

export const creativitycards = [
    SwitchesActivitiesCreativityBrainstormBlitz,
    SwitchesActivitiesCreativityCharades,
    SwitchesActivitiesCreativityCreateNewWord,
    SwitchesActivitiesCreativityTwoTruthsOneLie,
    SwitchesActivitiesCreativityWordShowdown,
    SwitchesActivitiesHealthyHabitsBlindDrawing,
    SwitchesActivitiesHealthyHabitsDoodling,
]

export const energizerscards = [
    SwitchesActivitiesEnergizersHeadKneeToesHat,
    SwitchesActivitiesEnergizersHelloKitty,
    SwitchesActivitiesEnergizersHighFive,
    SwitchesActivitiesEnergizersLookAround,
    SwitchesActivitiesEnergizersNinjaDestruction,
    SwitchesActivitiesEnergizersSecretIdentity,
    SwitchesActivitiesEnergizersShakeDown,
    SwitchesActivitiesEnergizersSyncClaps,
    SwitchesActivitiesEnergizersYoshiBushi,
    SwitchesActivitiesRockPaperScissors,
]

export const numbersandwordscards = [
    SwitchesActivitiesNumberAndWordGamesFiveFul,
    SwitchesActivitiesNumberAndWordGamesNineNineNine,
    SwitchesActivitiesNumberAndWordGamesSpellingEliminationGame,
    SwitchesActivitiesNumberAndWordGamesWordJumble,
    SwitchesActivitiesNumberAndWordGamesWordSearch,
    SwitchesActivitiesNumberAndWordsGuessTheWord,

]

export const resilienceBuildersCards = [
    SwitchesActivitiesResilienceBuildersPaperCastle,
    SwitchesActivitiesResilienceBuildersStressBalls,
    SwitchesActivitiesResilienceDuckDuckSabotage,
    SwitchesActivitiesResilienceHumanKnot,
    SwitchesActivitiesResilienceStandTogether,
]

export const secretsforsleepcards = [
    SwitchesActivitiesSecretsForSleepBrainTapping,
    SwitchesActivitiesSecretsForSleepBucketOfSafeThoughtsBlue,
    SwitchesActivitiesSecretsForSleepMilitarySleepingHabbit,
    SwitchesActivitiesSecretsForSleepSleepOasis,
    SwitchesActivitiesSecretsForSleepSleepTraining,
    SwitchesActivitiesSecretsForSleepTheHealthySleeper,
    SwitchesEmotionsAnxiousDreamBuilder,
    SwitchesEmotionsAnxiousSleepChalkBlue,
]

export const activitesSheetsCards = [
    SwitchesActivitiesActivitySheets
]

export const remotelearningcards = [
    SwitchesActivitiesRemoteClassesHighFiveElimination,
    SwitchesActivitiesRemoteClassesPicasso,
    SwitchesActivitiesRemoteClassesScavengerHunt,
    SwitchesActivitiesRemoteClassesTruthOrLie,
    SwitchesActivitiesRemoteClassesVacationPin,
    SwitchesActivitiesRemoteClassesVideoCritic,
]

export const funAndActiveCards = [
    SwitchesActivitiesFunAndPositiveExceptionalAwards,
    SwitchesActivitiesFunAndPositiveGratitudeCards,
    SwitchesActivitiesFunAndPositiveShowMeTheBunny,
    SwitchesActivitiesFunAndPositiveTheGreatShowdown,
    SwitchesActivitiesFunAndPositiveWarmFuzzies,
]

export const separationAnxietyCards = [
    SwitchesActivitiesSeparationAnxietyFurryFrined,
    SwitchesActivitiesSeparationAnxietyPlayTime,
    SwitchesActivitiesSeparationAnxietyTheSafeSpaceWall,
    SwitchesActivitiesSeparationAnxietyTheVoyager,
    SwitchesActivitiesSeparationAnxietyWhatWouldThink,
    SwitchesActivitiesSeparationAxietyForTeachers,
]

export const toolcards = [
    ToolsTheLittleBookOfBigEmotions,
    ToolsVideoLibrary,
    ToolsLessonPlans,
    ToolsFeelingDictionary,
    ToolsHandBrain,
    ToolsEmotionMeter,
    ToolsPhysiologyOfEmotion,
    ToolsProfessionalHelp,
    ToolsYouAndYourApe,
    ToolsHelpingHands,
    ToolsPosters,
    ToolsAuslan,
]

export const toolcardsPublic = [
    ToolsTheLittleBookOfBigEmotions,
    ToolsVideoLibrary,
    ToolsFeelingDictionary,
    ToolsHandBrain,
    ToolsEmotionMeter,
    ToolsPhysiologyOfEmotion,
    ToolsProfessionalHelp,
    ToolsYouAndYourApe,
    ToolsHelpingHands,
    ToolsPosters,
    ToolsAuslan
]

export const angryCards = cards.filter(card => card.colorScheme === CardColorScheme.ANGRY)
export const anxiousCards = cards.filter(card => card.colorScheme === CardColorScheme.ANXIOUS)
export const excitedCards = cards.filter(card => card.colorScheme === CardColorScheme.EXCITED)
export const happyCards = cards.filter(card => card.colorScheme === CardColorScheme.HAPPY)
export const sadCards = cards.filter(card => card.colorScheme === CardColorScheme.SAD)
export const scaredCards = cards.filter(card => card.colorScheme === CardColorScheme.SCARED)

export const intensityEmotionCards = {
    1: {
        [Emotion.ANGRY]: angry1pointcards,
        [Emotion.HAPPY]: happy1pointcards,
        [Emotion.EXCITED]: excited1pointcards,
        [Emotion.SCARED]: scared1pointcards,
        [Emotion.SAD]: sad1pointcards,
        [Emotion.ANXIOUS]: anxious1pointcards,
    },
    3: {
        [Emotion.ANGRY]: angry3pointcards,
        [Emotion.HAPPY]: happy3pointcards,
        [Emotion.EXCITED]: excited3pointcards,
        [Emotion.SCARED]: scared3pointcards,
        [Emotion.SAD]: sad3pointcards,
        [Emotion.ANXIOUS]: anxious3pointcards,
    },
    5: {
        [Emotion.ANGRY]: angry5pointcards,
        [Emotion.HAPPY]: happy5pointcards,
        [Emotion.EXCITED]: excited5pointcards,
        [Emotion.SCARED]: scared5pointcards,
        [Emotion.SAD]: sad5pointcards,
        [Emotion.ANXIOUS]: anxious5pointcards,
    },
    7: {
        [Emotion.ANGRY]: angry7pointscards,
        [Emotion.HAPPY]: happy7pointscards,
        [Emotion.EXCITED]: excited7pointscards,
        [Emotion.SCARED]: scared7pointscards,
        [Emotion.SAD]: sad7pointscards,
        [Emotion.ANXIOUS]: anxious7pointscards,
    },
}

export const activityCardSelector = {
    [Activities.HEALTHY_HABITS]: healthyhabitscards,
    [Activities.CREATIVITY]: creativitycards,
    [Activities.ENERGIZERS]: energizerscards,
    [Activities.FOR_REMOTE_CLASSES]: remotelearningcards,
    [Activities.NUMBER_AND_WORD_GAMES]: numbersandwordscards,
    [Activities.SECRETS_FOR_SLEEP]: secretsforsleepcards,
    [Activities.ACTIVITY_SHEETS]: activitesSheetsCards,
    [Activities.RESILIENCE_BUILDERS]: resilienceBuildersCards,
    [Activities.FUN_AND_POSITIVE]: funAndActiveCards,
    [Activities.SEPARATION_ANXIETY]: separationAnxietyCards,
    [Activities.EXTRA_SWITCHES]: archivedOrRemovedSwitches,
}

export const getCardId = (card: CardTemplate) => `${card.colorScheme}:${card.title}`