import { Platform } from "react-native"
import RNRestart from 'react-native-restart';

export const handleRestartApp = () => {
    if (Platform.OS === 'web') {
        if (window) {
            window?.location?.reload()
        }
    } else {
        if (RNRestart) {
            RNRestart.Restart()
        }
    }
}
