import { useState } from 'react'
import { NewPassword } from './new-password'
import { Auth } from 'aws-amplify'
import { rvCurrentUser } from './login-state'
import { Page } from '../common/page'
import { DefaultButton } from '../common/default-button'
import { HiddenUsername } from './hidden-username'
import { DefaultText } from '../common/default-text'
import { useReactiveVar } from '@apollo/client'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { ScreenNames } from '../common/screen-names'
import { LoginStackNavigatorParamList } from './login-route-param-types'
import IconSwitch4SchoolLogo2023 from '../icon/icon-switch-4-school-logo-2023'
import { StyleSheet, View } from 'react-native'
import { rvCurrentStudent } from '../student/use-student-state'
import { handleAuth } from './login-functions'
import { UserGroup } from '../../common/constants'

export const ChangePassword = () => {
    const [password, setPassword] = useState('')
    const [changingPassword, setChangingPassword] = useState(false)
    const { navigate } = useNavigation<NavigationProp<LoginStackNavigatorParamList>>()
    const currentStudent = useReactiveVar(rvCurrentStudent)

    const changePassword = async () => {
        setChangingPassword(true)
        await Auth.completeNewPassword(rvCurrentUser(), password)
            .then(async (auth) => {
                await handleAuth(auth)
                if (currentStudent) {
                    navigate(ScreenNames.LoginStudentEditAvatar, { id: currentStudent.id, user: UserGroup.Student })
                }
            })
            .catch(() => {
                setChangingPassword(false)
            })
    }

    return (
        <Page>
            <View style={styles.switchIcon}>
                <IconSwitch4SchoolLogo2023 />
            </View>
            <DefaultText>Create your own unique password.</DefaultText>
            <HiddenUsername />
            <NewPassword onPasswordChange={setPassword} />
            <DefaultButton onPress={changePassword} disabled={changingPassword}>Change Password</DefaultButton>
        </Page>
    )
}

const styles = StyleSheet.create({
    switchIcon: {
        position: 'absolute',
        top: '20%'
    }
})