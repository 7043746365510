import { useReactiveVar } from '@apollo/client'
import { AntDesign } from '@expo/vector-icons'
import { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import DefaultModal from '../common/default-modal'
import StepperDot from '../common/stepper-dot'
import { rvShowIndividualStudentHowTo } from '../teacher/teacher-student-individual-stats'
import { StudentIndividualHowToStep1, StudentIndividualHowToStep2, StudentIndividualHowToStep3 } from './student-individual-how-to-steps'

const StudentIndividualHowToModal = () => {
    const [stepIndex, setStepIndex] = useState(0)
    const totalSteps = 3
    const lastStep = stepIndex + 1 === totalSteps
    const showModal = useReactiveVar(rvShowIndividualStudentHowTo)

    const handleNext = async () => {
        if (lastStep) {
            toggleModal()
            return
        }

        if (stepIndex >= 0) {
            setStepIndex(prev => prev + 1)
        }
    }

    const renderContent = () => {
        switch (stepIndex) {
            case 0:
                return <StudentIndividualHowToStep1 handleNext={handleNext} />
            case 1:
                return <StudentIndividualHowToStep2 handleNext={handleNext} />
            case 2:
                return <StudentIndividualHowToStep3 handleNext={handleNext} isLastStep />

            default:
                break;
        }
    }

    const handlePressDot = (index: number) => {
        setStepIndex(index)
    }

    const toggleModal = () => {
        rvShowIndividualStudentHowTo(!showModal)
    }

    return (
        <View>
            <DefaultModal contentStyle={styles.modal}>
                <View style={styles.modalHeaderContainer}>
                    <View />
                    <StepperDot total={totalSteps} currentStepIndex={stepIndex} onPressDot={handlePressDot} />
                    <TouchableOpacity style={styles.closeIcon} onPress={toggleModal}>
                        <AntDesign name="close" size={28} color={DecidaColors.Gray} />
                    </TouchableOpacity>
                </View>
                <View>
                    {renderContent()}
                </View>
            </DefaultModal>
        </View >
    )
}
export default StudentIndividualHowToModal

const styles = StyleSheet.create({
    modal: {
        maxHeight: 1000,
    },
    modalHeaderContainer: {
        alignItems: 'center',
        paddingBottom: 10,
        justifyContent: 'space-between',
        borderBottomColor: DecidaColors.Gray,
    },
    closeIcon: {
        position: 'absolute',
        right: 10,
        paddingHorizontal: 10,
    },
})