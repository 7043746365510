import { View, StyleSheet } from "react-native";
import { DecidaColors } from "../../common/decida-colors";
import { DefaultButton } from "../common/default-button";
import { DefaultText } from "../common/default-text";
import { IconSwitch4SchoolsNewLogo } from "../icon/icon-switch-4-schools-new-logo";
import { Ionicons } from '@expo/vector-icons';
import { ScreenNames } from "../common/screen-names";
import { rvSwitchesScreenState } from "../switches/common/current-screen-state";
import { SwitchesScreens } from "../switches/navigation/switches-bottom-navigation";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import { ParentNavigationParams } from "./parent-navigation-params";
import { AntDesign } from '@expo/vector-icons';
import { logout } from "../common/logout";
import { rvLoggedIn, rvParentPortal } from "../login/login-state";
import { CLIENT_EVENT, createClientLog } from "../common/log-client-event";

export const ParentHome = () => {

    const { navigate } = useNavigation<DrawerNavigationProp<ParentNavigationParams>>()

    const navigateToSwitches = () => {
        rvSwitchesScreenState(SwitchesScreens.HOME)
        navigate(ScreenNames.SwitchesHomeMain)
    }
    const navigateToSettings = () => {
        rvSwitchesScreenState(SwitchesScreens.HOME)
        navigate(ScreenNames.ParentSettings)
    }
    const navigateToLogin = () => {
        rvSwitchesScreenState(SwitchesScreens.HOME)
        rvParentPortal(false)
        rvLoggedIn(false)
        logout()
        createClientLog({
            event: CLIENT_EVENT.USER_SIGNED_OUT,
            area: "Parent header",
            action: "Clicking logout button, and sign out",
            payload: {
                group: "parent",
            }
        })

    }


    return (
        <View style={styles.main}>
            <DefaultText style={styles.heading}>
                Welcome to Switch4School
            </DefaultText>
            <View style={styles.settingsContainer}>
                <DefaultButton onPress={navigateToSwitches} style={styles.buttonSwitch}>
                    <IconSwitch4SchoolsNewLogo height={40} width={40} />
                    <DefaultText style={styles.buttonTextSwitch}>Switches</DefaultText>
                </DefaultButton>
                <DefaultButton onPress={navigateToSettings} style={styles.buttonSwitch}>
                    <Ionicons name="settings-sharp" size={35} color={DecidaColors.Green} />
                    <DefaultText style={styles.buttonTextSwitch}>Settings</DefaultText>
                </DefaultButton>
                <DefaultButton onPress={navigateToLogin} style={styles.buttonSwitch}>
                    <AntDesign name="login" size={35} color={DecidaColors.Green} />
                    <DefaultText style={styles.buttonTextSwitch}>Logout</DefaultText>
                </DefaultButton>

            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
    },
    intensityContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 330,
        marginBottom: 20,
    },
    settingsContainer: {
        justifyContent: 'flex-start',
        alignItems: "center",
        minWidth: 400,
        minHeight: 300,
        padding: 20,
        borderRadius: 8,
        backgroundColor: DecidaColors.AppleSystemGray6SuperLight
    },
    heading: {
        fontSize: 28,
        fontWeight: '600',
        paddingBottom: 60,
    },
    buttonTextSwitch: {
        color: DecidaColors.Black,
        paddingLeft: 10,
        fontWeight: "700",
        textAlign: "center",
    },
    buttonSwitch: {
        borderColor: DecidaColors.Green,
        backgroundColor: DecidaColors.White,
        borderWidth: 2,
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 10,
        borderRadius: 5,
        height: 80,
        paddingLeft: 40,
        paddingRight: 40,
        margin: 10,
        flexDirection: "row",
    },

})