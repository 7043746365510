export const adminTeacherFields = `
    id
    email
    schoolID
    firstName
    lastName
    cognitoUsername
    role
    showNewFeatureInstructions
    onboarded
    preferredUsername
    showIntroduction
    _version
    _deleted
    _lastChangedAt
    __typename
`

export const adminGetTeacherQuery = `
  query GetTeacher($id: ID!) {
    getTeacher(id: $id) {
      ${adminTeacherFields}
    }
  }
`
