import { useReactiveVar } from '@apollo/client'
import { Pressable, StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from '../common/default-text'
import { CampusIcon } from '../common/svg-files/campus-icon'
import { GetSchoolQueryResponseCampus } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query'
import { rvSchoolAdminCampusCurrentListView, rvSelectedCampusID } from './school-admin-state'
import { SchoolAdminHierarchyCurrentListView } from './school-admin-types'


type Props = {
    campus: GetSchoolQueryResponseCampus,
}
const SchoolAdmiCampusesListItem = ({ campus }: Props) => {
    const currentListView = useReactiveVar(rvSchoolAdminCampusCurrentListView)
    const currentCampusID =  useReactiveVar(rvSelectedCampusID)
    const isSelected = currentCampusID === campus.id && currentListView === SchoolAdminHierarchyCurrentListView.Campus 

    const handlePressCampus = () => {
        if (isSelected) {
            rvSelectedCampusID(null)
            return
        }

        rvSelectedCampusID(campus.id)
        rvSchoolAdminCampusCurrentListView(SchoolAdminHierarchyCurrentListView.Campus)
    }

    return (
        <Pressable onPress={handlePressCampus} style={isSelected ? styles.selectedContainer : styles.container}>
            <View style={{ width: 30, height: 30, marginRight: 5 }}>
                <CampusIcon />
            </View>
            <DefaultText>{campus.name}</DefaultText>
        </Pressable>
    )
}
export default SchoolAdmiCampusesListItem
const styles = StyleSheet.create({
    container: {
        height: 60,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 20,
    },
    selectedContainer: {
        height: 60,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 20,
        backgroundColor: DecidaColors.AppleSystemGray6SuperLight,
        boxShadow: 'inset 3px 12px 36px -25px rgba(0, 0, 0, 0.5)', // Inner shadow
    },
})