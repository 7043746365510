import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import SwitchCardVideo from '../../../common/switch-card-video'
import { Worksheets } from '../../../common/worksheets-imports'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { GratefulnessIcon } from '../../explore/src/gratefulness-icon'
import { GratefulnessIconInverted } from '../../explore/src/gratefulness-icon-inverted'
import { GratefulnessImage1 } from './src/gratefulness-image1'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'

const thankFullnessVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/PcVQGoalfnk",
    title: ""
}

export const SwitchesEmotionsHappyGratefulness: CardTemplate = {
    cardId: "db4e9ae3-27a4-4af6-9999-42b3a7a4d236",
    title: 'Thankfulness',
    lessonPlan: true,
    Header: GratefulnessIconInverted,
    Icon: GratefulnessIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Inspiring feelings of contentment and general happiness."} />
        </>
    ),
    Images: () => (
        <>
            <GratefulnessImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Name a person you are thankful for."} />
                <DefaulttextWithRightArrow textdata={"Name a place you are thankful for."} />
                <DefaulttextWithRightArrow textdata={"Name a food you are thankful for."} />
                <DefaulttextWithRightArrow textdata={"Name a thing you are thankful for."} />
                <DefaulttextWithRightArrow textdata={"Name anything of your choice that you are thankful for."} />

            </View>
        </>
    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: 'In positive psychology research, gratitude is strongly and consistently associated with greater happiness. Gratitude helps people feel more positive emotions, relish good experiences, improve their health, deal with adversity, and build strong relationships.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 10, color: DecidaColors.Gray }}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Describe what it means to be thankful, and</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice identifying things to be thankful for to focus energy on having “enough” and not pining to fill deficiencies.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 25-45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that thankfulness is a feeling. It is being aware and appreciative of a benefit being received, also commonly expressed as being grateful. Learning to be thankful has been proven to help people feel more positive emotions, relish good experiences, improve health, deal with adversity and build strong relationships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play this explanatory video: What Does it Mean to Be Thankful?</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.youtube.com/watch?v=PcVQGoalfnk' link='https://www.youtube.com/watch?v=PcVQGoalfnk' />
                        <SwitchCardVideo video={thankFullnessVideo} />
                        <DefaultText style={CommonStyles.lessonPlanText}>• Ask students to brainstorm some things/people/places in their life they have to be grateful for, for example, I am thankful for my best friend because they make me feel happy, I am thankful for my favourite book because it’s a story that is exciting to read.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Give students the worksheet and give them approximately 5 minutes to list (and draw if they’d like to) at least one:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Person they are grateful to have in their life, and why</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Place they are grateful to have been able to go to, and why</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Food they are grateful to be able to eat, and why</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Things they are grateful to be able to use, and why</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Invite students to share the things they are thankful for.
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Debrief to discuss why being grateful is helpful for creating a positive outlook and explore how this switch could be integrated into any existing reflective journaling activities.
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Encourage repetition and a focus on the emotion of thankfulness to see the lasting impact of this activity. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• If students have their own device, ask them to find this switch in the Switch4Schools app.  </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points discussed during the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reiterate the importance of cultivating gratitude in daily life.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Extension: Gratitude Journaling (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute gratitude journals or plain notebooks to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the importance of keeping a gratitude journal and how it can positively impact mental wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to take a few minutes to write down three things they are thankful for today. Emphasise specificity and encourage them to reflect on both big and small things.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Expressing Gratitude to Others (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide a blank card or warm fussy form to each student. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to write a brief note expressing gratitude to someone in their life. This could be a friend, family member, teacher, or anyone who has had a positive impact on them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once they've written their notes, encourage students to share their messages (if they feel comfortable) or keep them private.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Thankfulness Worksheet' link={Worksheets.thankful.uri} /> </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Projector and screen for watching video</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Giving thanks can make you happier - </DefaultText>
                        <DefaultTextWithLink text='https://www.health.harvard.edu/healthbeat/giving-thanks-can-make-you-happier' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Why gratitude is good - </DefaultText>
                        <DefaultTextWithLink text='https://greatergood.berkeley.edu/article/item/why_gratitude_is_good' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to Teach Children Gratitude - </DefaultText>
                        <DefaultTextWithLink text='https://www.verywellmind.com/how-to-teach-children-gratitude-4782154' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Video: What Does it Mean to Be Thankful? - </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=PcVQGoalfnk' />
                        <SwitchCardVideo video={{
                            id: '1',
                            url: 'https://www.youtube.com/embed/PcVQGoalfnk'
                        }} />
                    </>}
            />

        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Try to visualise what you are thankful for and why it actually matters. How does it make you feel? ' />
            <DefaulTextWithTipsIcon textdata='Start every day by writing down at least three things you are grateful (thankful) for. ' />
            <DefaulTextWithTipsIcon textdata='Repetition is the key for seeing lasting impact of gratefulness exercises.' />

        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Thankfulness.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Happy]

}
