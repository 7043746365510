import { View } from "react-native"
import { UniversalImage } from "../common/universal-image"

const SvgImage = require('./icon-switch-4-school-logo-2023.png')

export const IconSwitch4SchoolLogo2023 = ({ percent = 1 }: { percent?: number }) => (
  <View style={{ width: 280 * percent, height: 70 * percent }}>
    <UniversalImage SvgImage={SvgImage} />
  </View>
)

export default IconSwitch4SchoolLogo2023