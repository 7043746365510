import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"
import { IconSize } from "../const"

type Props = SvgProps

function SwitchesSoundIcon(props: Props) {
    const { color } = props
    return (
        <Svg
            width={IconSize}
            height={IconSize}
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            fill={color ? color : "#fff"}
            {...props}
        >
            <Path d="M63.898 35.602c-1.5-1.398-3.898-1.398-5.3.102-1.4 1.5-1.4 3.898.1 5.3 2.5 2.399 3.802 5.5 3.802 8.899 0 3.398-1.399 6.601-3.801 8.898-1.5 1.399-1.5 3.801-.102 5.301.7.8 1.7 1.2 2.7 1.2.898 0 1.898-.302 2.601-1C67.8 60.601 70 55.5 70 50s-2.2-10.602-6.102-14.398z" />
            <Path d="M72.5 24.898c-1.5-1.398-3.898-1.2-5.3.3-1.4 1.5-1.2 3.9.3 5.302 5.602 5 8.7 12.102 8.7 19.5S73 64.602 67.5 69.5c-1.5 1.398-1.7 3.8-.3 5.3.698.801 1.8 1.301 2.8 1.301.898 0 1.8-.3 2.5-1C79.602 68.703 83.699 59.5 83.699 50c.102-9.601-4-18.699-11.199-25.102z" />
            <Path d="M81.199 14.199c-1.602-1.398-3.898-1.2-5.3.398-1.4 1.602-1.2 3.899.398 5.301C85 27.5 90 38.398 90 50s-5 22.5-13.7 30.102c-1.601 1.399-1.699 3.7-.398 5.301.7.898 1.801 1.3 2.801 1.3.898 0 1.8-.3 2.5-.898 10.398-9 16.301-22.102 16.301-35.8C97.5 36.3 91.602 23.198 81.199 14.198zM2.5 37v26c0 2.102 1.7 3.8 3.8 3.8h14.103l-.004-33.5H6.3c-2.102 0-3.8 1.598-3.8 3.7zM49.199 18.301c-1.2-.602-2.8-.5-3.898.3l-17.5 12.5V69l17.5 12.5c.601.5 1.398.699 2.199.699.601 0 1.199-.102 1.699-.398 1.3-.602 2-1.899 2-3.301V21.602c0-1.403-.8-2.703-2-3.301z" />
        </Svg>
    )
}

export default SwitchesSoundIcon
