import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { FutureLetterIcon } from '../../explore/src/future-letter-icon'
import { FutureLetterIconInverted } from '../../explore/src/future-letter-icon-inverted'
import { FutureLetterImage1 } from './src/future-letter-image1'
import { FutureLetterLessonPlanImage1 } from './src/future-letter-lessonplan-image1'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithLink from '../../common/default-text-with-link'
import SwitchCardVideo from '../../../common/switch-card-video'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'

export const SwitchesEmotionsSadFutureLetter: CardTemplate = {
    cardId: "b0f03f91-fb1f-42c5-9edc-5a35566c911f",
    title: 'Future letter',
    Header: FutureLetterIconInverted,
    Icon: FutureLetterIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <FutureLetterImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Shifting focus from present negative feelings to a more hopeful optimism for the future."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Find a quiet space with a pad and pen."} />
                <DefaultTextWithDownArrow textdata={"Think about where you want to be, what you want to have achieved in the next year, and how you will feel once you have achieved this."} />
                <DefaultTextWithoutArrows textdata={"Now write a letter from your future self to yourself detailing how you got there, advice you would give and words of encouragement."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: 'The brain interprets the world through pictures and stories. Our brain is biased toward feeling optimistic for the future, so when we visualise ourselves in the future we believe better things are on the horizon. Writing a letter to yourself helps take your focus off the immediate pressures, and lifts your mindset to help you overcome obstacles.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Visualise and articulate future state goal/s. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Overcome negative thinking and focus on a more positive vision of the future (hope).  </DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the concept of envisioning the future through a Future Letter.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss briefly the power of setting goals and the impact of positive thinking on personal development.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Activity (30 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the 'A Letter from my Future Self' worksheet to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to write/draw a picture of where they were a year ago, reflecting on how they felt, what they were doing, where they were, and their appearance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Next, ask students to write/draw a picture of where they want to be in one year, focusing on how they want to feel, their achievements, their location, and their appearance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide instructions for writing the Future Letter:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Begin the letter with "Dear [Student's Name],"</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Express happiness and pride about the achievements.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Describe how these achievements were accomplished.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Offer advice or insights to their present selves.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Encourage writing in past tense as though the achievements have already happened.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give students time to write their letters.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to seal their completed letter in an envelope.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If possible, ask students to address the envelope to themselves. Collect and hold onto the sealed envelopes until the end of the year or following year.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a discussion on how writing the future letter made students feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share any insights gained from the process.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of setting goals and how envisioning the future can impact their mindset.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If using own devices, refer students to the Future Letter switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key takeaways from the activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students that their sealed letters will be returned to them later, serving as a reminder of their goals and aspirations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to reflect on their goals and aspirations regularly and make small steps toward achieving them.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Paper/notebook </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Future Self worksheet' link={Worksheets.aLetterFromMyFutureSelf.uri} /></DefaultText>


                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Draw your future | Patti Dobrowolski | TEDxRainier -  </DefaultText>

                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=zESeeaFDVSw' />
                        <SwitchCardVideo video={{
                            id: '1',
                            url: 'https://www.youtube.com/embed/zESeeaFDVSw'
                        }} />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='It can be helpful to identify three things that you can do, or should do, right now to help reach your goals.' />

        </>

    ),
    audioUrl: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Future+Letter.mp3',
    type: [CardType.Switch],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action, CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Sad]
}
