import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'


export const SwitchesLessonPlansEmotionalIntelligenceInLeadership: CardTemplate = {
    cardId: "383bd208-36d8-4168-ac31-071af60a4858",
    title: 'Emotional intelligence in leadership',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Develop leadership skills by understanding and managing emotions within a group or team setting, fostering collaboration and motivation.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by engaging students in a brief discussion about the role of emotions in leadership.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to share examples of leaders they admire and explain how emotions play a role in their leadership CommonStyles.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Emotional Intelligence in Leadership (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the concept of emotional intelligence in leadership and its significance. Emotional intelligence is like having a superpower when it comes to being a leader. It means being aware of your own emotions and understanding how they can affect your thoughts and actions. It also means being able to recognise and understand the emotions of the people around you, like your teammates, classmates, or friends. When you have emotional intelligence as a leader, it helps you in many ways. Here's how: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Self awareness: Emotional intelligence helps you understand your own emotions. It's like having a little voice inside you that tells you how you're feeling and why. When you know how you feel, you can better manage your emotions and make better decisions. You'll also know your strengths and weaknesses, which helps you lead more effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Empathy: Empathy is when you understand and share the feelings of others. It's like putting yourself in someone else's shoes. When you have empathy, you can understand what your teammates or classmates are going through, and it helps you connect with them on a deeper level. It also allows you to be supportive and understanding, which makes people trust and respect you as a leader.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Communication: Emotional intelligence helps you communicate better with others. You can express your thoughts and feelings clearly, but you also listen carefully to what others have to say. This helps you understand different perspectives and find common ground. Good communication builds strong relationships and helps you work together more effectively as a team.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Conflict Resolution: As a leader, you may encounter conflicts or disagreements among your teammates or group members. Emotional intelligence helps you handle these situations calmly and find solutions that work for everyone. It allows you to understand the emotions and needs of each person involved, and you can help them find common ground and work towards a resolution.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Motivation and Inspiration: Emotional intelligence helps you inspire and motivate others. When you understand the emotions of your teammates or classmates, you can provide support, encouragement, and recognition when they need it most. This helps create a positive and supportive environment, where everyone feels valued and motivated to do their best. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that by developing emotional intelligence, you become a more effective leader who can understand and connect with others, make thoughtful decisions, and create a positive and productive team or group dynamic. It's a valuable skill that will not only help you in leadership roles but also in your personal relationships and future endeavours.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Leadership Qualities and Strategies (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students brainstorm good leadership qualities and strategies.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Look for and reinforce with slides on the list of leadership qualities and strategies. Discuss each quality and strategy, emphasising how they relate to emotional intelligence in leadership.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Communication: Good leaders communicate clearly and effectively. They listen attentively to others, express their ideas and thoughts clearly, and make sure everyone understands the goals and expectations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Responsibility: Leaders take responsibility for their actions and decisions. They show reliability and follow through on their commitments. They also take responsibility for the success and wellbeing of their team or group.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Empathy: Being empathetic means understanding and caring about the feelings and perspectives of others. Leaders who practice empathy can connect with their team members, show understanding, and support their emotional wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Collaboration: Leaders understand the power of teamwork and collaboration. They encourage cooperation among team members, value everyone's contributions, and create an inclusive and supportive environment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Problem-Solving: Leaders are skilled problem solvers. They analyse challenges, come up with creative solutions, and make informed decisions. They encourage critical thinking and seek input from others to find the best possible outcomes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Integrity: Leaders act with integrity and hold themselves to high ethical standards. They are honest, trustworthy, and consistent in their actions and words. They lead by example and inspire others to do the same.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Adaptability: Effective leaders are adaptable and flexible. They can adjust their plans and strategies when needed, embrace change, and navigate through challenges with resilience.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Vision: Leaders have a clear vision and inspire others to work towards that vision. They set goals, provide direction, and motivate their team members to achieve success.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Positive Attitude: Leaders maintain a positive attitude, even in challenging situations. They inspire and motivate others through their optimism, enthusiasm, and belief in the team's abilities.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Mentorship: Leaders support and mentor others. They help their teammates or group members develop their skills, provide guidance, and offer constructive feedback to help them grow.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a class discussion about practical ways to develop and apply these qualities and strategies. Look for:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Actively seeking leadership opportunities, such as organising a community service project or leading a school club.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Seeking feedback from peers, teachers, and mentors to identify areas for growth and improvement.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Reading books or articles on leadership and practicing self reflection to enhance self awareness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Volunteering or participating in extracurricular activities that require teamwork and collaboration.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Learning from role models and observing effective leaders in different contexts.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Taking on responsibilities and challenges outside of one's comfort zone to develop resilience and problem-solving skills.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on their own leadership experiences or aspirations and identify areas where they can improve their emotional intelligence and record it in the relevant sections of the Good Leaders worksheet. Could also be a homework activity.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Role-play: Leadership Scenarios (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide the class into small groups.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Assign each group a leadership scenario that requires the application of emotional intelligence and effective leadership skills.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Group Project Conflict: You are assigned as the leader of a group project in school, and there is a disagreement among your teammates about the direction of the project. Apply your emotional intelligence and leadership skills to listen to everyone's perspectives, mediate the conflict, and find a compromise that satisfies everyone while ensuring the project's success.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Peer Support: One of your friends is going through a difficult time, and they need someone to talk to. Utilise your emotional intelligence to empathise with their feelings, actively listen to them, and provide support and guidance to help them navigate their challenges effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Inclusion and Team Building: You notice that some students in your class feel left out and excluded from group activities. Use your leadership skills to foster an inclusive environment by organising team-building activities that encourage collaboration, making sure everyone feels valued and included.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Conflict Resolution Among Friends: Two of your close friends are having a heated argument and are refusing to speak to each other. Apply your emotional intelligence to understand their perspectives, act as a mediator, and help them find common ground to mend their friendship.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Decision-Making Dilemma: Your after school club needs to make an important decision, but there are differing opinions among the members. Practice your leadership skills by facilitating a discussion, considering everyone's input, and guiding the group towards a consensus that aligns with the club's goals and values.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Leading a Community Service Project: Take on a leadership role in organising a community service project. Use your emotional intelligence to understand the needs of the community, inspire and motivate others to get involved, and coordinate the project effectively to make a positive impact.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In their groups, students should role-play the scenarios and practice demonstrating emotionally intelligent leadership.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If time permits, have groups present to the class and encourage them to provide constructive feedback to their peers after each role-play.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share one leadership quality they believe is important for emotional intelligence and one strategy they plan to incorporate into their own leadership approach.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the significance of emotional intelligence in effective leadership and its positive impact on team dynamics and achievement. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>Extension:</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Encourage students to further explore emotional intelligence in leadership by researching and analysing case studies of leaders who demonstrate strong emotional intelligence. Provide opportunities for them to practice leadership skills through group projects, presentations, or mentoring younger students.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard/Chart paper and markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Role-play scenarios for leadership activities' link={Worksheets.emotionalIntelligenceInleadership.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Good leaders worksheet' link={Worksheets.goodLeadersWorksheet.uri}/></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Slides' link={"https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Emotional+intelligence+in+leadership.pptx"}/></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Why Emotional Intelligence Matters in Schools -</DefaultText>
                        <DefaultTextWithLink text='https://www.switch4schools.com.au/post/why-emotional-intelligence-matters-in-schools' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Good Leaders Use Emotional Intelligence -</DefaultText>
                        <DefaultTextWithLink text='https://www.helpguide.org/articles/mental-health/good-leaders-use-emotional-intelligence.html' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Emotional intelligence in schools -</DefaultText>
                        <DefaultTextWithLink text='https://katiabrunetti2.medium.com/emotional-intelligence-in-schools-39934efddf51' />
                        <DefaultText style={CommonStyles.lessonPlanText}>How to win friends and influence people by Dale Carnegie [book] -</DefaultText>
                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
})
