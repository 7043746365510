import Svg, { Path, Text, TSpan } from 'react-native-svg'
import { SvgEmotionIconProps } from './icon-emotion-types'

export const IconScared = ({ width, height, withoutLabel }: SvgEmotionIconProps) =>
    <Svg width={width} height={height} viewBox="0 0 100.8 109.158">
        <Path d="M0,0A41.5,41.5,0,0,0,41.5-41.5,41.5,41.5,0,0,0,0-83,41.5,41.5,0,0,0-41.5-41.5,41.5,41.5,0,0,0,0,0" transform="translate(50.4 85.5)" fill="#fff" />
        <Path d="M0,0A41.5,41.5,0,0,0,41.5-41.5,41.5,41.5,0,0,0,0-83,41.5,41.5,0,0,0-41.5-41.5,41.5,41.5,0,0,0,0,0Z" transform="translate(50.4 85.5)" fill="none" stroke="#d69850" strokeWidth="5" />
        <Path d="M0,0A6.532,6.532,0,0,0,6.532-6.532,6.532,6.532,0,0,0,0-13.063,6.532,6.532,0,0,0-6.532-6.532,6.532,6.532,0,0,0,0,0" transform="translate(37.772 39.549)" fill="#d69850" />
        <Path d="M0,0A6.532,6.532,0,0,0,6.532-6.532,6.532,6.532,0,0,0,0-13.063,6.532,6.532,0,0,0-6.532-6.532,6.532,6.532,0,0,0,0,0" transform="translate(70.412 39.549)" fill="#d69850" />
        <Path d="M0,0S-5.931,6.408-17.938,10.47" transform="translate(42.992 20.673)" fill="none" stroke="#d69850" strokeWidth="5" />
        <Path d="M0,0S-3.64,3.535,1.649,4.73" transform="translate(24.262 28.781)" fill="#d69850" />
        <Path d="M0,0S1.554-4.83-3.677-3.4" transform="translate(44.813 22.35)" fill="#d69850" />
        <Path d="M0,0S5.931,6.408,17.938,10.47" transform="translate(64.14 20.673)" fill="none" stroke="#d69850" strokeWidth="5" />
        <Path d="M0,0S3.64,3.535-1.649,4.73" transform="translate(82.87 28.781)" fill="#d69850" />
        <Path d="M0,0S-1.554-4.83,3.677-3.4" transform="translate(62.319 22.35)" fill="#d69850" />
        <Path d="M0,0S.72-2.4,4.8-2.4,9.36.96,14.16.96,18.72-2.4,22.8-2.4,27.6.96,32.16.96,36.48-2.4,40.8-2.4,45.48,0,46.08,0" transform="translate(30.822 52.62)" fill="none" stroke="#d69850" strokeWidth="5" />
        <Path d="M0,0S.545,5.045,4.743,1.612" transform="translate(28.428 51.902)" fill="#d69850" />
        <Path d="M0,0C1.243.44,2.382.429,2.543-.026s-.717-1.18-1.96-1.62S-1.8-2.075-1.96-1.62-1.243-.44,0,0" transform="translate(30.609 53.509)" fill="#d69850" />
        <Path d="M0,0A1.588,1.588,0,0,0,2.219.183,1.588,1.588,0,0,0,2-2.033,1.588,1.588,0,0,0-.221-2.216,1.588,1.588,0,0,0,0,0" transform="translate(76.322 53.267)" fill="#d69850" />
        <Path d="M0,0S-.545,5.045-4.743,1.612" transform="translate(79.647 52.666)" fill="#d69850" />
        <Path d="M0,0-1.481.925l1.311,2.1L1.31,2.1Z" transform="translate(78.336 50.604)" fill="#d69850" />
        {withoutLabel ? (null) : (
            <>
                <Path d="M0,0A9.941,9.941,0,0,0-9.941,9.941,9.941,9.941,0,0,0,0,19.882H77.918A9.941,9.941,0,1,0,77.918,0Z" transform="translate(11.441 87.776)" fill="#fff" />
                <Text transform="translate(17.878 103.299)" fill="#d69850" fontSize="15" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">SCARED</TSpan></Text>
                <Path d="M0,0A9.941,9.941,0,0,0-9.941,9.941,9.941,9.941,0,0,0,0,19.882H77.918A9.941,9.941,0,1,0,77.918,0Z" transform="translate(11.441 87.776)" fill="none" stroke="#d69850" strokeWidth="3" />
            </>
        )}
    </Svg>
