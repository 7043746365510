import { useReactiveVar } from '@apollo/client';
import { ScrollView, StyleSheet, View } from 'react-native'
import { rvStudentClasses } from '../student/use-student-state';
import { StudentClassSelectorItem } from './student-class-selector-item';

export const StudentClassSelector = () => {
    const studentClasses = useReactiveVar(rvStudentClasses)

    return (
        <View style={styles.container}>
            <ScrollView style={styles.classSelector} showsVerticalScrollIndicator={true} persistentScrollbar={true}>
                {studentClasses?.map(m => <StudentClassSelectorItem key={m.id} clazz={m} />)}
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        minHeight: 50,
        maxHeight: 250,
        width: '100%',
        alignItems: 'center',
    },
    classSelector: {
        flexDirection: 'column',
    },
})
