import { ScrollView, StyleSheet, View } from 'react-native'
import { DefaultFontSize } from '../common/const'
import DefaultModal from '../common/default-modal'
import { DefaultText } from '../common/default-text'
import ViewZoomableForIphone from '../common/view-zoomable'
import { RequestForChatInfoImage } from './assets/request-for-chat-info-image'
import { rvTeacherRequestForChatInfoModal } from './teacher-state'
import { useResponsive } from '../common/use-responsive'

const TeacherRequestForChatInfoModal = () => {
    const toggleModal = () => rvTeacherRequestForChatInfoModal(false)
    const { responsiveFontSize, isNarrowScreen } = useResponsive()
    return (
        <DefaultModal onClose={toggleModal} contentStyle={{ ...styles.contentContainer, padding: isNarrowScreen ? 30 : 60 }}>
            <ScrollView>
                <DefaultText style={[styles.headerText, { fontSize: responsiveFontSize(DefaultFontSize + 2) }]}>
                    Request for chat
                </DefaultText>
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                    You can add a question to the check in that asks the student to flag if they would like to talk to someone about how they are feeling.  This will create a speech bubble icon to flag it on your summary class check in stats page, and also creates a task on your home page that you select to mark as complete.
                </DefaultText>
                <View style={styles.spacing} />

                <ViewZoomableForIphone style={{ width: '100%', height: isNarrowScreen ? 300 : 600 }}>
                    <RequestForChatInfoImage />
                </ViewZoomableForIphone>

            </ScrollView>
        </DefaultModal>
    )
}
export default TeacherRequestForChatInfoModal

const styles = StyleSheet.create({
    contentContainer: {
        padding: 60,
        maxHeight: 800,
    },
    text: {
        textAlign: 'justify',
    },
    headerText: {
        textAlign: 'center',
        fontSize: DefaultFontSize + 2,
        fontWeight: 'bold',
        marginBottom: 20
    },
    spacing: {
        marginVertical: 5,
    },
})  
