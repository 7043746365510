export const adminClassFields = `
id
name
schoolID
emotionIntensityLevel
toggleRequestForChat
toggleRequestForChatNotifications
bulkSignUpId
createdAt
updatedAt
primaryContact
archived
emailReceivers
checkInCountYTD
checkInCountRollingWeekMonday
checkInCountRollingWeekTuesday
checkInCountRollingWeekWednesday
checkInCountRollingWeekThursday
checkInCountRollingWeekFriday
checkInCountRollingWeekSaturday
checkInCountRollingWeekSunday
activeStudents
goMode
classGroupID
_version
_deleted
_lastChangedAt
classClassLoginId
__typename
`

export const adminGetClassQuery = `
  query GetClass($id: ID!) {
    getClass(id: $id) {
      ${adminClassFields}
    }
  }
`