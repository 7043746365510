import { makeVar } from "@apollo/client";
import { CheckInSession, Class, Student, StudentCheckIn, Teacher, StudentClass, ClassLogin, TeacherClass } from "../../common/API";

export type StudentAndStudentClass = { studentClass: StudentClass | null, student: Student | undefined }

export const rvTeacherCurrentTeacher = makeVar<Teacher | undefined>(undefined)
export const rvTeacherSelectCurrentClassId = makeVar<string | undefined>(undefined)
export const rvTeacherSchoolStudents = makeVar<Student[] | undefined>(undefined)
export const rvTeacherCurrentStudents = makeVar<StudentAndStudentClass[]>([])
export const rvTeacherCurrentCheckIn = makeVar<CheckInSession | undefined>(undefined)
export const rvTeacherClasses = makeVar<Class[] | undefined>(undefined)
export const rvTeacherCurrentTeacherClasses = makeVar<TeacherClass[] | undefined>(undefined)
export const rvClassCurrentTeachers = makeVar<Teacher[] | undefined>(undefined)
export const rvTeacherClassLogins = makeVar<ClassLogin[] | undefined>(undefined)
export const rvTeacherStudentClasses = makeVar<StudentClass[] | undefined>(undefined)
export const rvTeacherIntroduction = makeVar<Boolean | undefined>(false)
export const rvTeacherCheckinSessions = makeVar<CheckInSession[]>([])
export const rvTeacherCurrentClassCheckinSessions = makeVar<CheckInSession[]>([])
export const rvTeacherLastCheckinSession = makeVar<CheckInSession | undefined>(undefined)
export const rvTeacherLastStudentCheckins = makeVar<StudentCheckIn[]>([])
export const rvTecherCurrentStudentStudentCheckIn = makeVar<StudentCheckIn | undefined>(undefined)
export const rvTeacherCurrentStudentCheckins = makeVar<StudentCheckIn[]>([])
export const rvTeacherEmotionIntensityScaleModal = makeVar<Boolean | undefined>(false)
export const rvTeacherRequestForChatInfoModal = makeVar<Boolean | undefined>(false)
export const rvTeacherRequestForEmailNotificationsInfoModal = makeVar<Boolean | undefined>(false)
export const rvTeacherIndividualStudentStatsInfoModal = makeVar<Boolean | undefined>(false)
export const rvTeacherWellbeingInfoModal = makeVar<Boolean | undefined>(false)
export const rvTeacherDashboardInfoModal = makeVar<Boolean | undefined>(false)
export const rvTeacherDashboardNewFeatureInfoModal = makeVar<Boolean | undefined>(false)
export const rvTeacherEatingQuestionInfoModal = makeVar<Boolean | undefined>(false)
