import { OnDataOptions, gql } from "@apollo/client"
import { ListClassesBySchoolIDCustomQuery, listClassesBySchoolIDCustomQuery } from "../school-admin/school-admin-graphql-scripts"
import { ListClassesBySchoolIDQueryVariables, OnMutateTeacherClassSubscription } from "../../common/API"

export const updateListTeacherClassInClassesQuery = ({ schoolID }: { schoolID: string }) => ({ data, client }: OnDataOptions<OnMutateTeacherClassSubscription>) => {

    if (!data.data?.onMutateTeacherClass) return
    const currentClassesData = client.readQuery<ListClassesBySchoolIDCustomQuery, ListClassesBySchoolIDQueryVariables>({
        query: gql`${listClassesBySchoolIDCustomQuery}`,
        variables: {
            schoolID: schoolID,
            limit: 5000
        }
    })

    // find class of teacherClass that coming from subscription data
    const existingClassDataIndex = currentClassesData?.listClassesBySchoolID?.items.findIndex((claz) => claz?.id === data.data?.onMutateTeacherClass?.classID)

    if (existingClassDataIndex == undefined || existingClassDataIndex < 0) return

    const classToUpdate = currentClassesData?.listClassesBySchoolID?.items[existingClassDataIndex]

    const teacherClasses = classToUpdate?.teachers?.items || []

    // check if subscription data is existing or new ones
    const isTeacherClassDataExists = teacherClasses.some((teacherClass) => teacherClass?.id === data.data?.onMutateTeacherClass?.id)

    if (isTeacherClassDataExists || !classToUpdate) return

    // filtered out updated data
    const unmodifiedClasses = currentClassesData?.listClassesBySchoolID?.items.filter((i) => i?.id !== classToUpdate?.id)

    // added new data from subscriptions into child items
    const updatedTeacherClasses = [...teacherClasses, data.data.onMutateTeacherClass]

    // update data with the new child items
    const updatedClass = {
        ...classToUpdate,
        teachers: {
            ...classToUpdate.teachers,
            items: updatedTeacherClasses
        }
    }

    client.writeQuery({
        query: gql`${listClassesBySchoolIDCustomQuery}`,
        variables: {
            schoolID: schoolID || "",
            limit: 5000
        },

        data: {
            ...currentClassesData,
            listClassesBySchoolID: {
                ...currentClassesData?.listClassesBySchoolID,
                items: [...unmodifiedClasses || [], updatedClass]
            }
        }
    })
}