import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan, Line } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconAngry5Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 5)
    }

    const onPressLevel2 = {
        [prop]: () => onPress(2 / 5)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(3 / 5)
    }

    const onPressLevel4 = {
        [prop]: () => onPress(4 / 5)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg viewBox="0 0 812.246 548.622">
            <Line y1="134.988" x2="204.142" transform="translate(1.93 402.08)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3461.465,2550.274l-25.391,6.085,9.85,14.9Z" transform="translate(-3243.519 -2156.046)" fill="#5c5d5d" />
            <Line y1="82.472" x2="124.896" transform="translate(229.056 303.784)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3609.349,2451.987l-25.394,6.069,9.839,14.9Z" transform="translate(-3243.519 -2156.046)" fill="#5c5d5d" />
            <Line y1="84.259" x2="125.832" transform="translate(376.656 204.809)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3757.834,2352.935l-25.355,6.233,9.936,14.837Z" transform="translate(-3243.519 -2156.046)" fill="#5c5d5d" />
            <Line y1="82.43" x2="127.444" transform="translate(524.364 106.121)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3907.279,2254.436l-25.451,5.828,9.7,14.994Z" transform="translate(-3243.519 -2156.046)" fill="#5c5d5d" />
            <Line y1="82.035" x2="124.884" transform="translate(675.465 7.816)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M4030.356,2162.055l9.8,14.925,15.605-20.934Z" transform="translate(-3243.519 -2156.046)" fill="#5c5d5d" />
            <G {...onPressLevel1} transform="translate(-104 -167)">
                <Path d="M3251.115,2704.161l208.958-.868.392-139.392Z" transform="translate(-3139.519 -1989.046)" fill="#faae96" />
                <Text transform="translate(188.951 703.807)" fill="#464749" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Annoyed</TSpan></Text>
            </G>
            <G {...onPressLevel2} transform="translate(-104 -167)">
                <Path d="M3471.847,2557.756l137.5-90.534.11,235.779-139.277.11Z" transform="translate(-3139.519 -1989.046)" fill="#f48165" />
                <Text transform="translate(351.302 650.494)" fill="#313133" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Irritated</TSpan></Text>
            </G>
            <G {...onPressLevel3} transform="translate(-104 -167)">
                <Path d="M3619.738,2461.756l138.6-94.134-.892,335.877-137.713.112Z" transform="translate(-3139.519 -1989.046)" fill="#ee583f" />
                <Text transform="translate(486.808 597.182)" fill="#1a1818" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Frustrated</TSpan></Text>
            </G>
            <G {...onPressLevel4} transform="translate(-104 -167)">
                <Path d="M3767.129,2361.622l140.65-91.2-.889,433.078-139.277.11Z" transform="translate(-3139.519 -1989.046)" fill="#ea3528" />
                <Text transform="translate(650.485 543.869)" fill="#ffffff" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Fuming</TSpan></Text>
            </G>
            <G {...onPressLevel5} transform="translate(-104 -167)">
                <Path d="M3917.683,2264.422l137.082-90.666.108,529.743-137.713.112Z" transform="translate(-3139.519 -1989.046)" fill="#e50019" />
                <Text transform="translate(801.834 490.558)" fill="#ffffff" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Furious</TSpan></Text>
            </G>
        </Svg>
    )
}