import { Linking, StyleSheet, Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import VideoCard from '../../../common/video-card'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { ReachOutHeader } from './src/reach-out-header'
import { ReachOutIcon } from './src/reach-out-icon'
import { ReachOutImage1 } from './src/reach-out-image1'
import DefaultTextWithLink from '../../common/default-text-with-link'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { CommonStyles } from '../../../common/const'
import SwitchCardVideo from '../../../common/switch-card-video'

const reachoutVideo: Videos = {
    id: '12',
    url: 'https://www.youtube.com/embed/otv3OvN2I8Q',
    title: ""
}

export const SwitchesEmotionsSadReachOut: CardTemplate = {
    cardId: "ea885247-6c87-4252-b7f0-15df7d696225",
    title: 'Reach out',
    Header: ReachOutHeader,
    Icon: ReachOutIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Building confidence to connect with someone when you need to talk about your sadness "} />


        </>
    ),
    Images: () => (
        <>
            <ReachOutImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Think of someone who can be trusted. It could be your teacher, Chaplain, friend, parent, counsellor, or anyone you know will listen and care about you."} />
                <DefaulttextWithRightArrow textdata={"Ask them if they have time to hear about what you are feeling."} />
                <DefaulttextWithRightArrow textdata={"If they say yes, and now is okay, share all the things that are going on for you."} />
                <DefaulttextWithRightArrow textdata={"The hardest part is starting, so if you are struggling start by telling what is making you sad then the rest of the conversation comes much easier."} />
                <DefaulttextWithRightArrow textdata={"After you have talked, consider how it went"} />
                <DefaultText style={CommonStyles.lessonPlanText}>◦ Did they understand what you were saying?</DefaultText>
                <DefaultText style={CommonStyles.lessonPlanText}>◦ Do you feel like you can go back to them if you feel the same way again?</DefaultText>
                <DefaultText style={CommonStyles.lessonPlanText}>◦ Has having the conversation helped you feel better?</DefaultText>
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: "People with a growth mindset tend to approach challenges with a more positive attitude and are more likely to persevere in the face of setbacks. They are also more open to learning new things and seeking out new opportunities for growth. In contrast, those with a fixed mindset may be more resistant to change and may be less likely to take on new challenges because they believe their abilities are fixed and cannot be improved.",
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn how to accurately describe their emotions</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn what emotional intelligence means and how to grow it</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes)::</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by asking students how they usually cope with stress, sadness, or difficult emotions. Discuss different coping mechanisms they use.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the Reach Out Switch and its importance. Emphasize that reaching out to someone they trust can be a powerful way to process and regulate emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the benefits of expressing emotions, explaining that research shows that it can lower stress hormone levels (cortisol) and contribute to better mental health.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Activity - Group Discussion (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think about someone in their lives they trust—someone who would listen and care about their feelings. It could be a teacher, Chaplain, friend, parent, or counsellor.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share if they've ever reached out to someone and how it made them feel. Discuss the challenges and benefits of opening up to others.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reach Out Switch Explanation (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the steps of the Reach Out Switch, emphasising the importance of starting the conversation and considering how it went afterward.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Think of someone you trust.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Ask if they have time to listen.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Share your feelings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Reflect on the conversation afterward.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ (Optional) Role Playing: Conduct a brief role playing activity where students practice initiating a conversation using the Reach Out Switch steps. Example scenarios:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 1: Academic Stress</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Context: Student A has been feeling overwhelmed with schoolwork and is struggling to manage the workload. Student B is a close friend who notices that A seems stressed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 2: Friendship Struggles</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Context: Student A and Student B have been close friends for a long time. Recently, A has noticed a change in B's behavior and suspects that B might be upset about something. A wants to initiate a conversation to understand what might be bothering B.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points, emphasising that reaching out is a proactive and healthy way to manage emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice the Reach Out Switch in their lives, whether through role playing with a friend or actually reaching out to someone they trust. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce any available support mechanisms within the school, such as the Guidance Officer. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- Refer students to the Need Help resources in the Tools section highlighting the contact details for support groups including Kids Helpline that are free and available to them 24/7 if they’d prefer not to talk to someone they already know.  </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Nil</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>A systematic review and meta-analysis of the effect of emotion regulation on cortisol - ScienceDirect</DefaultText>
                        <DefaultTextWithLink text='https://www.sciencedirect.com/science/article/pii/S2666497620300205' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The human stress response | Nature Reviews Endocrinology -</DefaultText>
                        <DefaultTextWithLink text='https://www.nature.com/articles/s41574-019-0228-0' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Evidence-based strategies (nsw.gov.au) -</DefaultText>
                        <DefaultTextWithLink text='https://education.nsw.gov.au/campaigns/inclusive-practice-hub/primary-school/common-needs/emotions/evidence-based-strategies' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata="Talking about your emotions can help you to process and regulate them in a healthy way, rather than bottling them up or suppressing them. Research has shown that expressing emotion lowers levels of cortisol (the stress hormone), which can have numerous health benefits. Expressing your feelings gives you a new perspective on them, which can help you to feel more in control. This, in turn, can help you to feel more balanced and less reactive to stress or negative emotions." />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Reach+Out+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Sad]
}
