import { Linking, StyleSheet, Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { BubbleOfControlHeader } from './src/bubble-of-control-header'
import { BubbleOfControlIcon } from './src/bubble-of-control-icon'
import { BubbleOfControlLessonPlan } from './src/bubble-of-control-lesson-plan'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { Worksheets } from '../../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { BubbleOfControlLindeDrawingImage } from './src/bubble-of-control-line-drawing'

export const SwitchesEmotionsAnxiousBubbleOfControl: CardTemplate = {
    cardId: "bb894060-05bc-45cf-83ef-8f2356a8977c",
    title: 'Bubble of control',
    Header: BubbleOfControlHeader,
    Icon: BubbleOfControlIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"When you are feeling overwhelmed or afraid."} />
        </>
    ),
    Images: () => (
        <View style={styles.imageContainer}>
            <BubbleOfControlLindeDrawingImage />
        </View>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"List all of the things you are concerned about right now."} />
                <DefaulttextWithRightArrow textdata={"Draw a big circle on a piece of paper. This will be your Bubble of Control."} />
                <DefaulttextWithRightArrow textdata={"Go through your list and ask yourself “Is this something I can control?”"} />
                <DefaulttextWithRightArrow textdata={"If the answer is yes (for example you can control your own reactions), write these down inside your Bubble of Control"} />
                <DefaulttextWithRightArrow textdata={"If the answer is no, (for example you can’t control the weather) write these around the outside your bubble"} />
                <DefaulttextWithRightArrow textdata={"Look at the things outside of your bubble and know that they are all things that are not up to you to worry about because you can’t control them."} />
                <DefaulttextWithRightArrow textdata={"Now look at the things inside the bubble and put your energy towards these things because you can control them."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: `Understanding what you can control can improve time management while developing a sense of agency and empowerment, enabling insight into areas causing stress or frustration, to develop strategies for coping with these challenges. In turn, developing the skills and resilience needed to navigate the challenges of life. Focusing on things you cannot control increases fear, anxiety and depression, and reduces motivation and focus.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Describe the concept of circles of control.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice identifying the things within their control and things that are not.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 35 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to brainstorm a list of things that they have control over in their own lives. Encourage them to think about things like their own actions, choices, and decisions. Write their ideas on the board.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to brainstorm a list of things that they don't have control over in their own lives. Encourage them to think about things like natural disasters, the actions of others, and world events. Write their ideas on the board.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain to the students that the things they have control over make up their "Bubble of control." These are the things that they can directly influence or change.</DefaultText>
                        <DefaultText style={[CommonStyles.lessonPlanText, { fontStyle: 'italic', }]}>◦ For older students: Take this one step further to explain internal and external locus of control. Explain that people with an internal locus of control believe that they are in control of their own lives and that they have the ability to make things happen. People with an external locus of control, on the other hand, believe that outside forces (such as luck, fate, or other people) are responsible for the events and outcomes in their lives. Give examples of internal and external locus of control. For example, someone with an internal locus of control might believe that they can improve their grades by studying hard and seeking help when needed, while someone with an external locus of control might believe that their grades are determined by their teacher's favouritism or their classmates' abilities.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide the board into two sections, one for things the students have control over and one for things they don't have control over. Place the students' ideas in the appropriate section.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of focusing on things within their circle of control and not wasting energy on things outside of their control. Emphasise that they can't change the things outside of their circle of control, but they can choose how they respond to them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Engage the students in a class discussion about ways they can take control of their lives and make positive changes within their circle of control. Encourage them to think about things like setting goals, making good choices, and taking responsibility for their actions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Bubble of control worksheet </DefaultText>
                        <View style={styles.imageContainer}>
                            <BubbleOfControlLessonPlan />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students create a list of action steps they can take to work on things within their circles of control. Encourage them to choose one or two things to work on in the coming week and come back to the class to share their progress.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ End the lesson by reviewing the concept of a circles of control and encouraging the students to continue thinking about how they can take control of their own lives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own device, ask them to find the switch in the Switch4Schools app.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Bubble of control worksheet' link={Worksheets.circleOfControl.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Bubble of control interactive worksheet' link={"https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Bubble+of+control+interactive+worksheet.pdf"} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Writing materials</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What is in my control and what is not.</DefaultText>
                        <DefaultTextWithLink text='https://thehappinesstrap.com/whats-control-whats-not/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Recognizing What You Can Control and Accepting What You Can't</DefaultText>
                        <DefaultTextWithLink text='https://psychcentral.com/blog/imperfect/2019/05/recognizing-what-you-can-control-and-accepting-what-you-cant' />

                        <DefaultText style={CommonStyles.lessonPlanText}> Keys to Accepting Things that Are Out of Your Control</DefaultText>
                        <DefaultTextWithLink text='https://www.yournewfoundation.com/8-keys-to-accepting-things-that-are-out-of-your-control/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Locus of Control and Your Life -</DefaultText>
                        <DefaultTextWithLink text='https://www.verywellmind.com/what-is-locus-of-control-2795434' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The 7 Habits of Highly Effective People – Stephen R. Covey</DefaultText>

                    </>}

            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata="The key is to focus your energy and time on things that you can change and not waste unnecessary energy worrying about things you can’t." />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Bubble+of+Control+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short, CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Anxious]
}

const styles = StyleSheet.create({
  imageContainer: {
    paddingTop: 20,
    flex: 1,
  },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
})
