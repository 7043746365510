import { AntDesign } from '@expo/vector-icons'
import { ReactNode } from 'react'
import { Modal, Pressable, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'

type Props = {
    children: ReactNode
    contentStyle?: ViewStyle,
    onPressBackground?: () => void
    onClose?: () => void
}

const DefaultModal = ({ children, contentStyle, onPressBackground, onClose }: Props) => {
    return (
        <View>
            <Modal
                visible={true}
                transparent
                style={styles.modalContainer}
            >
                <Pressable style={styles.modalBackground} onPress={onPressBackground}>
                    <Pressable style={[styles.modalContentContainer, contentStyle]}>
                        {onClose && (
                            <TouchableOpacity style={styles.closeIcon} onPress={onClose}>
                                <AntDesign name="close" size={28} color={DecidaColors.Gray} />
                            </TouchableOpacity>
                        )}
                        {children}
                    </Pressable>
                </Pressable>
            </Modal>
        </View>
    )
}
export default DefaultModal

const styles = StyleSheet.create({
    modalBackground: {
        backgroundColor: "rgba(0,0,0,0.7)",
        height: '100%',
        width: '100%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    modalContentContainer: {
        backgroundColor: DecidaColors.White,
        width: '80%',
        maxWidth: 800,
        maxHeight: 600,
        paddingTop: 20,
        borderRadius: 20,
    },
    modalHeaderContainer: {
        alignItems: 'center',
        paddingBottom: 10,
        borderBottomColor: DecidaColors.Gray
    },
    modalContainer: {
        alignContent: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    closeIcon: {
        position: 'absolute',
        right: 20, 
        top: 10,
        paddingHorizontal: 10,
        zIndex: 99  
    },
})
