import { DrawerHeaderProps, DrawerNavigationProp } from '@react-navigation/drawer/lib/typescript/src/types'
import { useNavigation } from '@react-navigation/native'
import { TouchableOpacity, View, ViewStyle, StyleSheet } from 'react-native'
import { DefaultText } from '../common/default-text'
import { Header } from '../common/header'
import { DecidaColors } from "../../common/decida-colors"
import { IconHome } from '../icon/icon-home'
import { rvSwitchesScreenState } from '../switches/common/current-screen-state'
import { ScreenNames } from '../common/screen-names'
import { SwitchesScreens } from '../switches/navigation/switches-bottom-navigation'
import { ParentNavigationParams } from './parent-navigation-params'
import { showAlert } from '../common/universal-alert'
import { rvParentPortal } from '../login/login-state'
import { logout } from '../common/logout'
import { AntDesign } from '@expo/vector-icons';
import { IconClassAdmin } from '../icon/icon-class-admin'
import { IconHeaderCheckIn } from '../icon/icon-header-check-in'
import { IconGraph } from '../icon/icon-graph'
import { CLIENT_EVENT, createClientLog } from '../common/log-client-event'
import * as ExpoLinking from 'expo-linking'
import { useResponsive } from '../common/use-responsive'
import LeftHeaderLogo from '../common/left-header-logo'
import HeaderBottomRow from '../common/header-bottom-row'
import { openUrlWebView } from '../web-view/open-url-web-view'

export const ParentHeader = (props: DrawerHeaderProps) => {

    const { navigate } = useNavigation<DrawerNavigationProp<ParentNavigationParams>>()
    const { width } = useResponsive()

    const navigateToHome = () => {
        rvSwitchesScreenState(SwitchesScreens.HOME)
        navigate(ScreenNames.SwitchesHome)
        setTimeout(() => navigate(ScreenNames.SwitchesHomeMain), 1)
    }
    const navigateToLogin = () => {
        rvSwitchesScreenState(SwitchesScreens.HOME)
        rvParentPortal(false)
        logout()
        ExpoLinking.openURL("/Login")
        createClientLog({
            event: CLIENT_EVENT.USER_SIGNED_OUT,
            area: "Parent header",
            action: "Clicking logout button, and sign out",
            payload: {
                group: "parent",
            }
        })

    }

    const showPremiumPopup = () => {
        showAlert({
            title: 'Feature locked.',
            message: "Feature available with school subscription. For more information, click find out more.",
            leftButtonText: 'Ok',
            rightButtonText: 'Find out more',
            onRightButtonPress: () => openUrlWebView('https://www.switch4schools.com.au/learnmore')

        })
    }

    return (
        <View>
            <Header
                {...props}
                disableDrawer
                leftChildren={
                    width > 550 && <LeftHeaderLogo onPress={navigateToHome} />
                }
                centerChildren={
                    <View />
                }
                rightChildren={

                    <>
                        <TouchableOpacity onPress={showPremiumPopup}>
                            <View style={styles.icon}>
                                <IconHeaderCheckIn color={DecidaColors.Green} />
                                <DefaultText style={styles.iconText}>Check-in</DefaultText>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={showPremiumPopup}>
                            <View style={styles.icon}>
                                <IconClassAdmin stylefill={DecidaColors.Green} />
                                <DefaultText style={styles.iconText}>Admin</DefaultText>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={showPremiumPopup}>
                            <View style={styles.icon} >
                                <IconGraph color={DecidaColors.Green} />
                                <DefaultText style={styles.iconText}>Stats</DefaultText>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={navigateToHome}>
                            <View style={styles.icon}>
                                <IconHome color={DecidaColors.Green} />
                                <DefaultText style={styles.iconText}>Home</DefaultText>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={navigateToLogin}>
                            <View style={styles.icon}>
                                <View>
                                    <AntDesign name="login" size={28} color={DecidaColors.Green} style={styles.cog} />
                                </View>
                                <DefaultText style={styles.iconText}>Login</DefaultText>
                            </View>
                        </TouchableOpacity>
                    </>
                }
                headerStyle={{ backgroundColor: DecidaColors.White }}
            />
            <HeaderBottomRow />
        </View>
    )
}

const styles = StyleSheet.create({
    icon: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
    } as ViewStyle,
    iconText: {
        marginTop: 5,
        fontSize: 15,
        color: DecidaColors.Gray,
    },
    profileIcon: {
        marginRight: 0,
    },
    profileContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    cog: {
        marginVertical: -4
    },
    moreInfomation: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },

})