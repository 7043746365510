import { ScrollView, StyleSheet } from 'react-native'
import { DefaultFontSize } from '../common/const'
import DefaultModal from '../common/default-modal'
import { DefaultText } from '../common/default-text'
import { useResponsive } from '../common/use-responsive'
import { rvTeacherEatingQuestionInfoModal } from './teacher-state'

const TeacherEatingQuestionInfoModal = () => {
    const toggleModal = () => rvTeacherEatingQuestionInfoModal(false)
    const { responsiveFontSize, isNarrowScreen } = useResponsive()

    return (
        <DefaultModal onClose={toggleModal} contentStyle={{ ...styles.contentContainer, padding: isNarrowScreen ? 30 : 60 }}>
            <ScrollView>
                <DefaultText style={[styles.headerText, { fontSize: responsiveFontSize(DefaultFontSize + 2) }]}>
                    Eating question
                </DefaultText>
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                    This additional check-in question asks, "Have you eaten today?" with the optional responses of Yes, No or Prefer Not To Say.  Should the response be No, a small knife and fork icon will be displayed on the class check-in stats page and on your home screen dashboard for this student.
                </DefaultText>
            </ScrollView>
        </DefaultModal>
    )
}
export default TeacherEatingQuestionInfoModal

const styles = StyleSheet.create({
    contentContainer: {
        padding: 60,
        maxHeight: 800,
    },
    text: {
        textAlign: 'justify',
    },
    headerText: {
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: 20
    },
    spacing: {
        marginVertical: 5,
    },
    mainText: {
        marginLeft: 10,
        fontWeight: 'bold'
    },
    subText: {
        marginLeft: 40
    }
})  
