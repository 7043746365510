import { View } from "react-native"
import { TRollingWeekCheckInCount } from "../../common/common-types"
import { DefaultText } from "./default-text"

type Props = {
    rollingWeekCheckInCount: TRollingWeekCheckInCount
}

export const RollingWeekCheckInCount = ({ rollingWeekCheckInCount }: Props) => (
    <View>
        <DefaultText>YTD: {rollingWeekCheckInCount.checkInCountYTD}</DefaultText>
        <DefaultText>M: {rollingWeekCheckInCount.checkInCountRollingWeekMonday}</DefaultText>
        <DefaultText>T: {rollingWeekCheckInCount.checkInCountRollingWeekTuesday}</DefaultText>
        <DefaultText>W: {rollingWeekCheckInCount.checkInCountRollingWeekWednesday}</DefaultText>
        <DefaultText>T: {rollingWeekCheckInCount.checkInCountRollingWeekThursday}</DefaultText>
        <DefaultText>F: {rollingWeekCheckInCount.checkInCountRollingWeekFriday}</DefaultText>
        <DefaultText>S: {rollingWeekCheckInCount.checkInCountRollingWeekSaturday}</DefaultText>
        <DefaultText>S: {rollingWeekCheckInCount.checkInCountRollingWeekSunday}</DefaultText>
    </View>
)