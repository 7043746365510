import Svg, { Path } from 'react-native-svg'

interface Props {
	width: number,
	height: number,
}

export const AvatarFlag = ({ width, height }: Props) =>
	<Svg viewBox="0 0 100 100" width={width} height={height}>
		<Path fill="red" d="m81.398 11.801c-0.69922-0.5-1.6016-0.5-2.3984-0.10156-0.10156 0-7.8984 3.8008-13.898 3.8008-5.6992 0-9.6016-1.8008-13.898-3.8008-4.6016-2.0977-9.3047-4.3008-16.203-4.3008-8 0-15.898 4.1016-16.199 4.3008-0.80078 0.5-1.3008 1.3008-1.3008 2.3008v76.098c0 1.3984 1.1016 2.5 2.5 2.5s2.5-1.1016 2.5-2.5v-38.898c2.3984-1.1016 7.5-3.1016 12.5-3.1016 6.3008 0 10.398 1.8984 14.602 3.8984 4.3008 2 8.8008 4.1016 15.398 4.1016 7.8008 0 15.801-4.1016 16.199-4.3008 0.80078-0.39844 1.3008-1.3008 1.3008-2.1992v-35.598c0-0.89844-0.39844-1.6992-1.1016-2.1992z" />
	</Svg>
