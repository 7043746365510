import { StyleSheet, Text, View } from 'react-native'
import { DefaultText } from './default-text'
import { Stepper } from './stepper'

type Props = {
    value: number
    onChange: (data: number) => void
}

const StepperEmotionIntensityScale = ({ value, onChange }: Props) => {
    return (
        <View style={styles.intensityContainer}>
            <DefaultText style={styles.emotionText}>Emotion Intensity Scale</DefaultText>
            <Stepper
                values={[
                    { value: 1 },
                    { value: 3 },
                    { value: 5 },
                    { value: 7 },
                ]}
                currentValue={value}
                onChange={onChange}
                width={100}
                height={38}
                SelectedFontSize={20}
                fontSize={15}
            />
        </View>
    )
}
export default StepperEmotionIntensityScale

const styles = StyleSheet.create({
    intensityContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 20,
    },
    emotionText: {
        paddingHorizontal: 5,
    },
})