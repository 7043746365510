import { FontAwesome } from '@expo/vector-icons';
import { useState } from 'react';
import { Pressable, StyleSheet, TextInput, View } from 'react-native';
import { CommonStyles } from '../common/const';
import { DecidaColors } from '../../common/decida-colors';

interface Props {
    onPasswordChange?: (text: string) => void
    placeholder?: string
    newPassword?: boolean
    onSubmit?: () => void
}

export const PasswordInput = ({ onPasswordChange: setPassword, onSubmit, placeholder = 'Password', newPassword = false }: Props) => {
    const [showPassword, setShowPassword] = useState(false)

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    return (
        <View>
            <TextInput
                testID='password'
                style={CommonStyles.textInput}
                placeholder={placeholder}
                autoCorrect={false}
                autoComplete='password'
                autoCapitalize='none'
                secureTextEntry={!showPassword}
                textContentType='password'
                passwordRules={newPassword ? 'required: upper; required: lower; required: digit; max-consecutive: 2;required: special; minlength: 8;' : undefined}
                onChangeText={setPassword}
                onSubmitEditing={onSubmit}
            />
            <Pressable onPressIn={toggleShowPassword} onPressOut={toggleShowPassword} style={styles.showPassword}>
                <FontAwesome name={showPassword ? "eye" : "eye-slash"} size={24} color={showPassword ? DecidaColors.Black : DecidaColors.Gray} />
            </Pressable>
        </View>
    )
}

const styles = StyleSheet.create({
    showPassword: {
        position: 'absolute',
        right: 10,
        top: 14
    }
})