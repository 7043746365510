import { View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import VideoCard from '../../../common/video-card'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { HotColdHeaderIcon } from './src/hot-cold-header-icon'
import { HotColdIcon } from './src/hot-cold-icon'
import { HotColdLineDrawing } from './src/hot-cold-line-drawing'
import { DefaultTextWithIndentation } from '../../common/default-text-with-indentation'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'

export const SwitchesEmotionsAnxiousHotCold: CardTemplate = {
    cardId: "b675175a-f736-49fa-b0fc-14e6f52c2b0a",
    title: 'Hot, cold',
    Header: HotColdHeaderIcon,
    Icon: HotColdIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <View style={{ flex: 1 }}>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata='Altering the natural response the body has for anxiety.' />
            </View>
        </>
    ),
    Images: HotColdLineDrawing,
    CardContent: () => (
        <>
          
            <DefaulttextWithRightArrow textdata="Alternate the temperature of your hands." />
            <DefaultTextWithIndentation textdata='a. First, run them under some cold water or hold something cold, like ice.' />
            <DefaultTextWithIndentation textdata='b. Afterwards, warm up your hands by, rubbing them together, holding a warm drink, or running them under some warm water.' />
            <DefaulttextWithRightArrow textdata="Close your eyes and experience your hands getting warmer. " />
            <DefaulttextWithRightArrow textdata="Do this as many times as you need." />

        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Hot%2C+cold.m4a",
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: `Warming the hands through rubbing or holding a warm drink activates sensory receptors, which can initiate a relaxation response. The act of closing the eyes and focusing on the sensation of warmth enhances the mind body connection, aiding in relaxation. Regular use of this technique can help individuals manage anxiety by promoting relaxation and providing a constructive outlet for emotional release.`,
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata="Optional: If you would like to expand on this activity, try it in the shower, alternate the water from hot to cold. Alternately, swim in a cold pool then have a hot shower. " />
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the physiological aspects of anxiety and how the hand warmer technique can activate sensory receptors and increase blood flow to the hands.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice this technique to experience its potential benefits and learn how to use it as a constructive tool for managing anxiety.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                       <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 35 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction to the Physiology of Anxiety: (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by explaining the physiological aspects of anxiety. Use the physiology of emotion tool (available in the tools section) to help guide this explanation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how anxiety triggers the body's "fight or flight" response, leading to increased heart rate, muscle tension, and increased blood flow to the bodies vital areas.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students navigate to the Hot Cold Switch and explain that this technique can be used to dial down the intensity of anxious feelings.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Linking Physiology and Anxiety Management: (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain how the hand warmer technique relates to the physiology of anxiety.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Describe how warming the hands through rubbing or holding a warm drink activates sensory receptors in the skin and redirects blood flow to the hands which counters the body's natural response to delegate blood to vital areas of the body. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that visualising anxiety dissipating through the hands taps into the power of guided imagery, creating a tangible metaphor for releasing emotional distress.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice: Physiology of the Hand Warmer Technique: (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead the class through a guided hand warmer exercise.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to close their eyes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play soft background music to create a calming atmosphere (optional).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct them to either rub their hands together.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to focus on the sensation of warmth spreading through their hands and visualise anxiety leaving their body through their hands.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain how this exercise can activate sensory receptors, enhance the mind body connection, and initiate relaxation.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Interactive Activity - Reflection and Discussion: (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Initiate a class discussion on the physiological aspects of anxiety and the experience of practicing the hand warmer technique.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share their thoughts and feelings during the exercise, focusing on the mind body connection.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to consider how understanding the physiology of anxiety can empower them in managing their emotions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion: (5 minutes)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points discussed during the lesson, highlighting the connection between the physiology of anxiety and the hand warmer technique.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the idea that regular practice of this technique can help individuals manage anxiety by promoting relaxation and providing a constructive outlet for emotional release.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard and markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Projector or screen for displaying visuals (optional)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Warm drinks (optional)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Optional: Soft background music' link='https://www.youtube.com/watch?v=_kT38XB1YHo' /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Connection Between Anxiety and Body Temperature</DefaultText>
                        <DefaultTextWithLink text='https://www.calmclinic.com/anxiety/symptoms/body-temperature' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The Physical Side of Anxiety</DefaultText>
                        <DefaultTextWithLink text='https://intermountainhealthcare.org/blogs/the-physical-side-of-anxiety' />
                        
                        <DefaultText style={CommonStyles.lessonPlanText}>Beautiful Relaxing Music</DefaultText>
                        <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} indent={0} text='Beautiful Relaxing Music - Stop Overthinking, Stress Relief Music, Sleep Music, Calming Music' link='https://www.youtube.com/watch?v=_kT38XB1YHo' />
                        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <VideoCard video={{ 
                                id: '1',
                                url: 'https://www.youtube.com/embed/_kT38XB1YHo'
                            }} />
                        </View>
                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Anxious]
}


