import { useReactiveVar } from '@apollo/client';
import { SafeAreaView, StyleSheet, TouchableOpacity, View } from "react-native";
import { DefaultFontSize } from "../common/const";
import { DefaultText } from "../common/default-text";
import { headerHeight } from "../common/header";
import { logout } from "../common/logout";
import { rvShowPopoverClasslogin } from "../common/pop-over-menu-state";

import { EmailSupportPopoverButton, TroubleshootingPopoverButton } from '../common/popover-buttons';
import { versionNumberString } from "../env";
import { rvCurrentUser } from "../login/login-state";
import { DecidaColors } from '../../common/decida-colors';


export const ClassLoginPopoverMenu = () => {

    const showPopover = useReactiveVar(rvShowPopoverClasslogin)
    const user = useReactiveVar(rvCurrentUser)

    const closePopup = () => {
        rvShowPopoverClasslogin(false)
    }

    const logoutHandler = () => {
        rvShowPopoverClasslogin(false)
        logout()
    }

    if (!user) {
        return null
    }

    return showPopover ? (
        <TouchableOpacity style={styles.overlay} onPress={closePopup}>
            <SafeAreaView />

            <View style={styles.avatarpopover}>
                <EmailSupportPopoverButton />
                <TroubleshootingPopoverButton />
                <View style={styles.logout}>
                    <TouchableOpacity testID="logout-button" onPress={logoutHandler} style={styles.dividerButton}>
                        <DefaultText style={styles.logouttext}>
                            Logout
                        </DefaultText>
                    </TouchableOpacity>
                </View>
                <View style={styles.logout}>
                    <View style={styles.profileContainer}>
                        <DefaultText style={styles.buildVersion}>
                            {versionNumberString}
                        </DefaultText>
                    </View>
                </View>

            </View>
        </TouchableOpacity>
    ) : null
}


const styles = StyleSheet.create({
    overlay: {
        position: 'absolute',
        zIndex: 99,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    avatarpopover: {
        alignItems: 'flex-end',
        width: 300,
        minHeight: 200,
        maxHeight: 500,
        zIndex: 500,
        marginRight: 2,
        backgroundColor: DecidaColors.White,
        borderColor: 'grey',
        borderRadius: 5,
        borderWidth: 0.5,
        marginTop: headerHeight
    },
    logout: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    logouttext: {
        marginRight: 15,
        color: DecidaColors.Gray,
        alignItems: 'flex-end',
        justifyContent: 'center',
        fontSize: DefaultFontSize - 2,

    },
    buildVersion: {
        marginRight: 15,
        fontSize: DefaultFontSize - 2,
        color: DecidaColors.AppleSystemGray4Light,
    },
    dividerButton: {
        width: "100%",
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },

    profileContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    emailSupport: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    optionsContainer: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },

})
