import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { ScrunchieHeader } from './src/scrunchie-header'
import { ScrunchieIcon } from './src/scrunchie-icon'
import { ScrunchieImage1 } from './src/scrunchie-image1'
import { ScrunchieImage2 } from './src/scrunchie-image2'
import { ScrunchieImage3 } from './src/scrunchie-image3'
import { CommonStyles } from '../../../common/const'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'

export const SwitchesEmotionsAngryScrunchie: CardTemplate = {
    cardId: "1bbc657d-0de0-4c97-bb2c-451b10944081",
    title: 'Scrunchie',
    Header: ScrunchieHeader,
    Icon: ScrunchieIcon,
    lessonPlan: true,
    mainContainerStyle: {
        maxWidth: '100%',
        paddingHorizontal: 20,
    },
    PerfectFor: () => {
        const Step1 = () => {
            return (
                <View>
                    <DefaultText style={styles.contentText}>Scrunch up your feet, legs, hands, arms, shoulders, </DefaultText>
                    <DefaultText style={styles.contentText}>neck and face as hard as you can.</DefaultText>
                    <View style={styles.imageContainer}>
                        <ScrunchieImage1 />
                    </View>
                </View>
            )
        }

        const Step2 = () => {
            return (
                <View>

                    <DefaultText style={styles.contentText}>Hold for a few moments and then completely release, </DefaultText>
                    <DefaultText style={styles.contentText}>letting all the tension go.</DefaultText>
                    <View style={styles.imageContainer}>
                        <ScrunchieImage2 />
                    </View>
                </View>
            )
        }

        const Step3 = () => {
            return (
                <View>
                    <DefaultText style={styles.contentText}>Repeat this process until your mind and body are </DefaultText>
                    <DefaultText style={styles.contentText}>completely relaxed.</DefaultText>
                    <View style={styles.imageContainer}>
                        <ScrunchieImage3 />
                    </View>

                    <View>
                        <WhyDoesItWorkComponent message='Tensing and relaxing the body helps draw your attention to areas of the body that are unconsciously tense. This helps relax your muscles and reset  from the high tension state of anger. One minute of this relaxation strategy has been shown to significantly reduce the stress hormone produced when furious, bringing you back in control of your thoughts and actions.' />
                    </View>
                </View>
            )
        }

        const contents = [
            { Component: Step1, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/scrunchie-pg1.mp3' },
            { Component: Step2, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/scrunchie-pg2.mp3' },
            { Component: Step3, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/scrunchie-pg3.mp3' },
        ]

        return (
            <>
                <DefaultTextWithoutArrowsBold textdata={'Perfect for:'} />
                <DefaultTextWithoutArrowsItallic textdata={'Releasing pent up negative energy to dial down the intensity of big emotions. '} />
                
                <SwitchCardJuniorContent contents={contents} scheme={Emotion.ANGRY} />
            </>
        )
    },
    Images: undefined,
    CardContent: () => (
        <>
        </>
    )
    ,
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: "",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Explore the concept of tensing and relaxing the body as a way to reduce feelings of anger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify when they are feeling angry and use tensing and relaxing to help manage their emotions.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 20 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the children in a circle and start with a brief discussion about feelings. Ask if they've ever felt angry and how it feels in their bodies.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the idea that sometimes our bodies get tense when we're angry, and we can learn a fun way to release that tension.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Start with a quick warm-up, such as gentle stretching or jumping jacks, to get their bodies ready for the activity.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity - Tense and Relax (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Demonstrate the Scrunchie switch showing how to tense and relax different muscle groups. Start with fists, then arms, shoulders, face, and toes. Show exaggerated movements and expressions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Use simple language, like "Make your muscles as tight as a superhero" and "Now let them go all floppy, like a jellyfish."</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the children follow your lead as you guide them through tensing and relaxing each muscle group.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to use their imagination, like pretending to be strong superheroes or soft and cuddly animals. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Let the children practice on their own, allowing them to choose which muscles they want to tense and relax. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play calming music in the background (optional) to create a soothing atmosphere. </DefaultText>
                   
                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the children back into a circle.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask how they felt during the activity. Use open ended questions like, "How did it feel to let go of the tension?"</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how they can use this technique when they feel angry or frustrated.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind them that it's okay to feel angry and that they now have the Scrunchie technique (tensing and relaxing) to help them feel better. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Open space for movement </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Soft cushions or pillows </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Calming music (optional)</DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Breathing tips to help control your anger - </DefaultText>
                        <DefaultTextWithLink text='https://www.jerichocounselling.com/breathing-tips-help-control-anger/' />
                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Angry]
}

const styles = StyleSheet.create({
    contentText: {
        color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
})

