import { CardSuggestionEmotions, IntensityTier, SuggestionsParam } from "./nudges-types"

export const nudgesGetStudentCheckInsWithConversionsToMaxMedMin = (studentCheckInsData: SuggestionsParam[], intensityLevel: number): CardSuggestionEmotions[] => {
    const dataWithConversion = studentCheckInsData.map((checkInData): CardSuggestionEmotions => {
        let emotionIntensityTier: IntensityTier

        if (intensityLevel === 1 || checkInData.emotionIntensityPercentage <= 0.4) {
            emotionIntensityTier = IntensityTier.Min
        } else if (checkInData.emotionIntensityPercentage >= 0.8) {
            emotionIntensityTier = IntensityTier.Max
        } else {
            emotionIntensityTier = IntensityTier.Med
        }

        return {
            ...checkInData,
            emotionIntensityTier,
        }
    })

    return dataWithConversion
}