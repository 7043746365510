export const MIN_PASSWORD_LENGTH_NON_STUDENT_ACCOUNT = 8

export enum UserGroup {
    Admin = "Admin",
    ClassLogin = "ClassLogin",
    SchoolAdmin = "SchoolAdmin",
    NonTeachingStaff = "NonTeachingStaff",
    Student = "Student",
    Support = "Support",
    Teacher = "Teacher",
}

export enum CheckInUserGroup {
    NonTeachingStaff = "NonTeachingStaff",
    Student = "Student",
    Teacher = "Teacher",
}

export const messageNoQuestion = "No question available"
