import { API, graphqlOperation } from "aws-amplify"
import { GraphQLResult } from "@aws-amplify/api"
import { MutableRefObject } from "react"
import { CreateTeacherClassMutationVariables, CreateTeacherClassMutation } from "../../../common/API"
import { createTeacherClass } from "../../../common/graphql/mutations"
import { TeacherClasses, ALL_CLASSES } from "./common-import-school-data-const-and-types"
import { ListClassTeachersAndStudentsBySchoolIDQueryItem } from "./list-class-teachers-and-students-by-school-id"

type Params = {
    teacher: TeacherClasses,
    teacherID: string,
    existingClasses: MutableRefObject<ListClassTeachersAndStudentsBySchoolIDQueryItem[]>,
}

export const assignTeacherToClasses = async ({
    teacher,
    teacherID,
    existingClasses,
}: Params) => {
    if (teacher.classes?.find(c => c === ALL_CLASSES)) {
        await Promise.all(existingClasses.current.map(async c => {
            try {
                if (c.teachers.items.find(t => t.teacherID === teacherID && t._deleted !== true) == undefined) {
                    await API.graphql(graphqlOperation(
                        createTeacherClass,
                        {
                            input: {
                                classID: c?.id,
                                teacherID
                            }
                        } as CreateTeacherClassMutationVariables
                    )) as GraphQLResult<CreateTeacherClassMutation>
                }
            } catch (e) {
                console.log('error assigning teacher to class')
                console.log(teacher)
                console.log(c)
                console.log(e)
            }
        }))
    } else {
        if (teacher.classes) {
            await Promise.all(teacher.classes?.map(async c => {
                try {
                    const existingClass = existingClasses.current.find(ec => ec?.name === c)

                    if (existingClass && existingClass.teachers.items.find(t => t.teacherID === teacherID) == undefined) {
                        await API.graphql(graphqlOperation(
                            createTeacherClass,
                            {
                                input: {
                                    classID: existingClass.id,
                                    teacherID
                                }
                            } as CreateTeacherClassMutationVariables
                        )) as GraphQLResult<CreateTeacherClassMutation>
                    }
                } catch (e) {
                    console.log('error assigning teacher to class')
                    console.log(teacher)
                    console.log(c)
                    console.log(e)
                }
            }))
        }
    }
}

