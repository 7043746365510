import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { ActivityIndicator, StyleSheet, TextInput, View, ViewStyle } from 'react-native'
import { usernameTaken } from '../login/username-taken-validator'
import { CommonStyles } from './const'
import { DefaultText } from './default-text'
import { DecidaColors } from '../../common/decida-colors'

type Props = {
    value: string
    onChangeText: (data: string) => void
    cognitoUsername: string
    containerStyle?: ViewStyle
}

export const InputEmailTeacherAdmin = ({ value, onChangeText, containerStyle, cognitoUsername }: Props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [emailExists, setEmailExists] = useState(false)

    useEffect(() => {
        if (cognitoUsername) {
            setEmailExists(false)
        }
    }, [cognitoUsername])

    const handleEmail = (value: string) => {
        setIsLoading(false)
        handler(value);
        onChangeText(value);
    };

    const emailAvailablity = async (email: string) => {
        if (cognitoUsername) {
            // no need to check email if there's a cognito username
            return
        }
        setEmailExists(await usernameTaken(email));
    };

    const handler = useCallback(_.debounce(emailAvailablity, 500), [cognitoUsername]);

    return (
        <View style={[styles.container, containerStyle]}>
            <View>
                <TextInput
                    style={CommonStyles.textInput}
                    autoCapitalize='none'
                    autoComplete='email'
                    autoCorrect={false}
                    value={value}
                    onChangeText={handleEmail}
                />
                {
                    isLoading && (
                        <ActivityIndicator size="small" color={DecidaColors.Gray} style={styles.loader} />
                    )
                }
            </View>
            <View style={styles.errorMessageContainer}>
                {
                    emailExists && (
                        <DefaultText style={{ color: DecidaColors.Red }}>Username not available. Please use a different email or specify a Cognito username.</DefaultText>
                    )
                }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        justifyContent: "flex-start",
    },
    heading: {
        fontSize: 17,
        fontWeight: 'bold',
        paddingTop: 20,

    },
    errorMessageContainer: {
        width: 330,
        textAlign: 'justify'
    },
    loader: {
        position: 'absolute',
        right: 10,
        top: 16,
    }
})