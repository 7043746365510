import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { API } from 'aws-amplify'
import { useState, useEffect } from 'react'
import { Linking, StyleSheet, View, ViewStyle } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'
import { rvIsLoading } from '../common/loading'
import { ScreenNames } from '../common/screen-names'
import { IconSwitch4SchoolLogo } from '../icon/icon-switch-4-school-logo'
import { StudentJoinClassStackNavigatorParamList } from './login-route-param-types'
import { rvBulkSignUpUrl } from './login-state'

type Props = {
    bulkSignUpId: string
    style?: ViewStyle
}

const JoinClassForm = ({ bulkSignUpId, style }: Props) => {

    const [className, setClassName] = useState("No Class")

    useEffect(() => {
        Linking.getInitialURL().then((url) => {
            rvBulkSignUpUrl(url)
        });

        rvIsLoading(true)

        if (bulkSignUpId) {
            API.post(
                'AdminQueries',
                '/graphQLGetClassName',
                {
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: {
                        bulkSignUpId: bulkSignUpId
                    },
                }

            ).then(result => {
                setClassName(result)
            }).finally(() => {
                rvIsLoading(false)
            })
        }
    }, [bulkSignUpId])

    const { navigate } = useNavigation<StackNavigationProp<StudentJoinClassStackNavigatorParamList>>()

    useEffect(() => {
        if (bulkSignUpId && className) {
            navigateToStudentSelectAvatar()
        }
    }, [bulkSignUpId, className])

    const navigateToStudentSelectAvatar = () => {
        navigate(ScreenNames.studentJoinClassPickAvatar, { bulkSignUpId, className: className, })
    }

    return (
        <View style={[styles.inputContainer, style]}>
            <IconSwitch4SchoolLogo percent={0.6} style={styles.logostyle} />
            <DefaultText style={styles.title}>Welcome!</DefaultText>
            <DefaultText style={styles.miniTitle}>Your class</DefaultText>
            <DefaultText style={styles.subtitle}>{className}</DefaultText>
        </View>
    )
}

export default JoinClassForm

const styles = StyleSheet.create({
    inputContainer: {
        flexDirection: 'column',
        width: 350,
        alignItems: 'center'
    },
    logostyle: {
        backgroundColor: DecidaColors.Black,
        borderRadius: 12,
        padding: 20,
        width: "95%"
    },
    title: {
        fontSize: 36,
        padding: 60,
        color: DecidaColors.Green
    },
    heading: {
        fontSize: 22,

    },
    subtitle: {
        color: DecidaColors.Gray,
        fontSize: 25,
        paddingBottom: 0,
    },
    miniTitle: {
        color: DecidaColors.Gray,
        fontSize: 12,
        paddingBottom: 0,
    },

    buttonSpacing: {
        marginVertical: 30,
        alignItems: 'center',
        justifyContent: 'center',
    },
    joinButton: {
        backgroundColor: DecidaColors.Green,
        padding: 20,
        height: 50,
        width: 150,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonText: {
        color: DecidaColors.White

    },
    existingLogin: {
        color: DecidaColors.Blue
    }
})
