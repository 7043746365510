import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { TalkToAFriendIcon } from './src/talk-to-a-friend-icon'
import { TalkToAFriendIconInverse } from './src/talk-to-a-friend-icon-inverse'
import { TalkToAFriendImage1 } from './src/talk-to-a-friend-image1'

export const SwitchesEmotionsSadTalkToAFriend: CardTemplate = {
    cardId: "e473509e-ef3c-46e1-8c74-8bbe6c0c0e3d",
    title: 'Talk to a friend',
    Header: TalkToAFriendIconInverse,
    Icon: TalkToAFriendIcon,
    lessonPlan: true,
    mainContainerStyle: {
        maxWidth: '100%',
        paddingHorizontal: 20,
    },
    PerfectFor: () => {

        const Step1 = () => {
            return (
                <View style={styles.step1MainContainer}>
                    <DefaultText style={styles.contentText}>Think of a friend you would like to talk to.</DefaultText>
                    <View style={styles.imageContainer}>
                        <TalkToAFriendImage1 />
                    </View>
                    <DefaultText style={styles.contentText}>Approach that friend with a smile and ask them, “How was your day?”.</DefaultText>
                    <WhyDoesItWorkComponent message="Sharing one's experiences fosters social connection and support, activating the social support system which is crucial for emotional wellbeing. Expressing emotions verbally can be cathartic, allowing individuals to release pent up feelings and gain a sense of relief. Additionally, a friend's empathetic listening and understanding provide validation, helping to normalise and contextualise the emotions associated with sadness. Through conversation, individuals often gain new perspectives, insights, or advice, contributing to problem solving and a more positive outlook. The act of articulating emotions also promotes self awareness, helping individuals better understand the root causes of their sadness." />
                </View>
            )
        }


        const contents = [
            { Component: Step1, audio: '' },
        ]

        return (
            <>
                <SwitchCardJuniorContent contents={contents} scheme={Emotion.SAD} />
            </>
        )
    },
    Images: undefined,
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/vo_sad_talk_to_a_friend.m4a",
    CardContent: () => (
        <>
        </>
    )
    ,
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: "",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the significance of verbal communication and talking with a friend as a supportive and comforting way to cope with emotions.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the idea that talking openly with a friend and expressing feelings through words can be a powerful and comforting way to cope with emotions, especially sadness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of communication in building strong and supportive friendships.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1 (15 minutes): Talking through Feelings </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on a time when they felt sad or upset. Ask them to think of an example they will be comfortable to talk about with the class.  Otherwise invent a hypothetical story they would be willing to share.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Optional - Ask them to write about or draw what happened working individually.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to recall if they talked to someone about how they were feeling, and if they did who was it.  If they didn’t talk to someone, have them consider who they may have liked to talk to about that situation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Optional - Ask them to add their support person to their story/drawing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Brainstorm as a class some ways in which talking to a friend did/would have helped their situations. Look for:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Feeling listened to</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Not alone</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Supported</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Made them feel better</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2 (15 minutes): Friendship Talk Circle</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Arrange the students in a pairs or small groups, ideally leveraging existing trusted friendships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide a talking object to each group (e.g., a soft toy or ball) to be used to signify the speaker.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set a timer to facilitate the students taking turns to have each student share their personal story of feeling sad and how talking with a friend did or would have helped them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of active listening and being kind and supportive during the sharing session.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Closing (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students and debrief the Friendship Talk Circle.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask if anyone would like to share their experience doing the activity and if they found it helpful to talk about feelings with a friend.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Highlight that verbal communication and talking openly are valuable tools for building trust and support in friendships.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Extension (Optional):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Implement a "Talking Buddy" system where students can designate a friend to talk to when feeling sad.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Optional – Paper and drawing materials</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Several talking objects such as soft toy or ball</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Friendship and mental health</DefaultText>
                        <DefaultTextWithLink text='https://www.mentalhealth.org.uk/explore-mental-health/a-z-topics/friendship-and-mental-health' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Talking through problems</DefaultText>
                        <DefaultTextWithLink text='https://www.betterhealth.vic.gov.au/health/healthyliving/talking-through-problems' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How and When to Start a Conversation with a Struggling Friend</DefaultText>
                        <DefaultTextWithLink text='https://jedfoundation.org/resource/how-and-when-to-start-a-conversation-with-a-struggling-friend' />
                    </>}
            />

        </>
    ),
    type: [CardType.Switch],
    time: [],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Sad]
}

const styles = StyleSheet.create({
    step1MainContainer: {
        gap: 10,
    },
    contentText: {
        color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20,
    },
})

