import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconSad3Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 3)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(2 / 3)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg viewBox="0 0 535.947 351.439">
            <Path d="M0,0,203.922-134.841" transform="translate(1.93 339.884)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.391,6.085l9.85,14.895Z" transform="translate(217.727 197.192)" fill="#657170" />
            <Path d="M0,0,123.937-81.745" transform="translate(229.62 188.603)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.4,6.056l9.832,14.906Z" transform="translate(365.44 99.02)" fill="#657170" />
            <Path d="M0,0,124.928-83.3" transform="translate(377.325 91.196)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.367,6.183l9.906,14.857L0,0Z" transform="translate(514.096)" fill="#657170" />
            <G {...onPressLevel1} transform="translate(-35 -583.935)">
                <Path d="M0,0,208.958-.868l.172-137.756Z" transform="translate(42.596 934.867)" fill="#c0e2ff" />
                <Text transform="translate(104.149 924.121)" fill="#42454a" fontSize="23" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Disappointed</TSpan></Text>
            </G>
            <G {...onPressLevel3} transform="translate(-35 -583.935)">
                <Path d="M0,0,138.331-90.6l.5,234.663-139.277.111Z" transform="translate(262.109 789.643)" fill="#6dbbff" />
                <Text transform="translate(280.056 868.636)" fill="#42454a" fontSize="24" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Hopeless</TSpan></Text>
            </G>
            <G {...onPressLevel5} transform="translate(-35 -583.935)">
                <Path d="M0,0,138.6-93l-.173,335.362L.711,242.474Z" transform="translate(410.5 691.843)" fill="#2e9fff" />
                <Text transform="translate(425.982 816.219)" fill="#ffffff" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Despair</TSpan></Text>
            </G>
        </Svg>
    )
}