import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { BreakPoints, CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { SeekHelpIcon } from './src/seek-help-icon'
import { SeekHelpIconInverse } from './src/seek-help-icon-inverse'
import { SeekHelpImage1 } from './src/seek-help-image1'

export const SwitchesEmotionsScaredSeekHelp: CardTemplate = {
    cardId: "2cfde070-eda1-4b8a-83d4-f8c1b8b67c03",
    title: 'Seek help',
    Header: SeekHelpIconInverse,
    Icon: SeekHelpIcon,
    lessonPlan: true,
    mainContainerStyle: {
        maxWidth: '100%',
        paddingHorizontal: 20,
    },
    PerfectFor: () => {

        const Step1 = () => {
            return (
                <View style={styles.step1MainContainer}>
                    <DefaultText style={styles.contentText}>If you feel like something in your life is scaring you or making you feel really, really worried, talk about your feelings to a friend or a trusted adult.</DefaultText>
                    <View style={styles.imageContainer}>
                        <SeekHelpImage1 />
                    </View>
                    <DefaultText style={styles.contentText}>If you are in danger of being hurt, go talk to a trusted adult about it.</DefaultText>
                    <WhyDoesItWorkComponent message='Verbalising fears to another person brings those concerns out of the internal realm, making them more tangible and shareable. This act of externalising fears often diminishes their perceived intensity. Additionally, engaging in a conversation provides an opportunity for empathy and reassurance. A supportive listener can offer perspective, guidance, and a sense of understanding, helping to normalise fears and validate the emotional experience. Social connection is inherently comforting and knowing that someone is there to listen and respond can create a sense of safety and security. Discussing fears openly may lead to problem solving or coping strategies, empowering individuals to confront and manage their fears more effectively.' />
                </View>
            )
        }


        const contents = [
            { Component: Step1, audio: '' },
        ]

        return (
            <>
                <SwitchCardJuniorContent contents={contents} scheme={Emotion.SCARED} />
            </>
        )
    },
    Images: undefined,
    CardContent: () => (
        <>
        </>
    )
    ,
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/vo_scared_seek_help.m4a",
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: "",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the importance of asking for help from friends or adults when feeling scared and recognise it as a positive and empowering action.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by discussing with the students what it feels like to be scared and ask if they would like to share a time when they have experienced fear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the idea that asking for help from friends or adults is a positive and empowering action when feeling scared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of seeking support and how it can make a difference in overcoming fears.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1 (15 minutes): When is appropriate? </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Print, cut out and stick some blue tack to the back of the <DefaultTextWithCustomLink style={styles.linkText} link="https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Seek+help+cut+outs.pdf" text='"Seek help" cut outs' />.  Alternatively, access the ‘ask for help’ cut outs document and write them on the board as you go. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Separate the whiteboard into two sections, on the right title the board ‘ask for help’, and on the left title the board ‘Don’t ask for help’.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Read to the students the first ‘ask for help or not’ situation and get the class to vote on whether it would be appropriate to ask for help. Stick or write the situation on the side of the board relative to the most votes. Go through the rest of the list of ‘ask for help or not’ situation list in the same way.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ At the end of the activity ask the students if they are confident in their responses.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If by this point all the situations are on the ‘ask for help side of the board’ then the class has gotten all the answers correct.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Any responses still on the ‘Do not ask for help’ side move to the ‘Ask for help side’ and explain to them why in all the situations it would be appropriate to ask for help.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2 (15 minutes): Go-to podium list</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Print a copy of the <DefaultTextWithCustomLink style={styles.linkText} link={Worksheets.goToPodiumWorksheet.uri} text={Worksheets.goToPodiumWorksheet.label} /> for each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to think about the friends or adults they would feel comfortable asking for help in such a situation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the students to draw a picture of their top three people that they would go to when they are feeling scared. The only rule is that one of their top three people has to be an adult.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ The drawing of their most trusted confidant goes on the top of the podium then 2nd and 3rd respectively. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Closing (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students and discuss the importance of asking for help when scared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to keep their go-to podium lists and refer them during the times they feel scared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that seeking support is a brave and empowering action that everyone can take.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard and markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Drawing or colouring materials (crayons, markers, coloured pencils)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink style={styles.linkText} link={Worksheets.seekHelpCutOuts.uri} text={Worksheets.seekHelpCutOuts.label} />, paper, or sticky notes</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink style={styles.linkText} link={Worksheets.goToPodiumWorksheet.uri} text={Worksheets.goToPodiumWorksheet.label} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Teaching Kids How To Ask For Help</DefaultText>
                        <DefaultTextWithLink text='https://betterkids.education/blog/teaching-kids-how-to-ask-for-help' />

                        <DefaultText style={CommonStyles.lessonPlanText}>8 Strategies For Helping Students Learn To Ask For Help</DefaultText>
                        <DefaultTextWithLink text='https://www.teachthought.com/pedagogy/learn-to-ask-for-help/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Why Kids Are Afraid to Ask for Help?</DefaultText>
                        <DefaultTextWithLink text='https://www.scientificamerican.com/article/why-kids-are-afraid-to-ask-for-help/' />


                    </>}
            />

        </>
    ),
    type: [CardType.Switch],
    time: [],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Scared]
}

const styles = StyleSheet.create({
    linkText: {
        fontSize: DefaultLessonPlanContentFontsize,
    },
    step1MainContainer: {
        gap: 10,
    },
    contentText: {
        color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20,
    },
})

