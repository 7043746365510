import moment from 'moment'
import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { AudioHandlerSwitches } from '../../../common/audio-handler-switches'
import { DefaultText } from '../../../common/default-text'
import VideoCard from '../../../common/video-card'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { StoryTimeAnt } from './src/story-time-ant'
import { StoryTimeCamel } from './src/story-time-camel'
import { StoryTimeDog } from './src/story-time-dog'
import { StoryTimeDuck } from './src/story-time-duck'
import { StoryTimeHeader } from './src/story-time-header'
import { StoryTimeIcon } from './src/story-time-icon'
import { StoryTimeImage1 } from './src/story-time-image1'
import { StoryTimeRocket } from './src/story-time-rocket'
import { CommonStyles } from '../../../common/const'

const storyTimeVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/FOQKMiD5QJI",
    createdAt: moment('2022-01-01').toString(),
}

const imageButtons = [
    {
        image: StoryTimeCamel,
        title: "How the Camel Got His Hump [5 mins]",
        audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/How+the+camel+got+his+hump+%5B5mins%5D.mp3"
    },
    {
        image: StoryTimeAnt,
        title: "The Ants and the Grasshopper [1min]",
        audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/The+Ants+and+the+Grasshopper+%5B1min%5D.mp3"
    },
    {
        image: StoryTimeDog,
        title: "The Dog and his reflection [1 min]",
        audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/The+Dog+and+his+reflection+%5B1+min%5D.mp3"
    },
    {
        image: StoryTimeRocket,
        title: "The Remarkable Rocket [26 mins]",
        audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/The+Remarkable+Rocket+%5B26mins%5D.mp3"
    },
    {
        image: StoryTimeDuck,
        title: "The Ugly Duckling [23 mins]",
        audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/The+Ugly+Duckling+%5B23mins%5D.mp3"
    },
]

const StoryTimeAudioButtons = ({ isLessonPlan }: { isLessonPlan?: boolean }) => {
    return (
        <>
            {imageButtons.map((data) => {
                return (
                    <View style={styles.buttonContainer}>
                        <AudioHandlerSwitches accessibilityLabel='Story time audio' audioFile={{ uri: data.audioUrl }} customIcon={data.image} hideText />
                        <DefaultText style={isLessonPlan ? CommonStyles.mainLessonText : styles.mainSwitchesText}>{data.title}</DefaultText>
                    </View>
                )
            })}
        </>
    )
}

export const SwitchesEmotionsAngryStoryTime: CardTemplate = {
    cardId: "a125358d-9f39-4990-bf57-8c49419a3168",
    title: 'Story time',
    Header: StoryTimeHeader,
    Icon: StoryTimeIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Creating clarity when anger is clouding your thoughts, inspiring creativity, or redirecting high energy."} />
        </>
    ),
    Images: () => (
        <>
            <StoryTimeImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Pick one of the short stories that you think you would be most interested in."} />
                <DefaulttextWithRightArrow textdata={"Find a quiet space and close your eyes while you listen to one of the stories."} />
                <DefaulttextWithRightArrow textdata={"Write about, or draw some of the things that you imagined. It could be people, wildlife, the setting or anything else you choose!"} />
                <DefaulttextWithRightArrow textdata={"Consider whether your ability to think clearly is any better. If you think not, you may like to try another story."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: "Imagining a fictional story can provide an opportunity to escape from real-life situations that may be causing frustration or stress. This can provide a temporary reprieve from negative emotions and allow someone to relax and let go of their anger.",
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Provide a better understanding of anger and how to manage it in a healthy way using fictional stories and imagination as a coping mechanism. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 50-75 minutes</DefaultText>
                        
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share a time when they felt stressed or overwhelmed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students how they coped with the stress e.g. talked to a friend, took a break.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the idea that stories and imagination can be a helpful tool for managing stress and finding a temporary escape from reality.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (Up to 30 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play one of the short stories from the Story Time Switch to the students.</DefaultText>
                        <StoryTimeAudioButtons isLessonPlan />
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to close their eyes and imagine themselves in the story.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to describe what they see, hear, and feel in their minds.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that when we immerse ourselves in a story, it can help us take our minds off of our problems and stressors.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students work in pairs or small groups to create a story together.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to use their imagination and creativity to come up with a unique and interesting story.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ As a class, discuss the benefits of using stories and imagination to manage stress and find a temporary escape from reality.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students select a story from the Story Time switch or choose a book or short story to read independently.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to close their eyes and use their imagination to fully immerse themselves in the story.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Optional: Have the students draw a picture or create a visual representation of their favourite part of the story.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share one thing they learned about using stories and imagination to manage stress.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Review the idea that finding a temporary escape through stories and imagination can be a helpful coping mechanism.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• The short stories from the Story Time switch</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pencils and paper</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Optional: Art supplies e.g. markers, crayons, coloured pencils</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Harnessing The Power Of Imagination -  </DefaultText>
                        <DefaultTextWithLink text='https://www.psychologytoday.com/au/blog/the-flourishing-family/202006/harnessing-the-power-imagination' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Coping With Stress - Imaginative Solutions for Stress Relief -</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=FOQKMiD5QJI' />

                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={storyTimeVideo} />
                        </View>
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <StoryTimeAudioButtons isLessonPlan={false} />
            <DefaulTextWithTipsIcon textdata="Keeping your breathing slow and controlled will help you to see the story better." />
            <DefaulTextWithTipsIcon textdata="Try to visualise how you see each character and setting." />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Story+Time+audio+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Angry]
}

const styles = StyleSheet.create({
    mainSwitchesText: {
        paddingLeft: 10,
    },
    rowContainer: {
        flex: 1,
        flexDirection: 'row',
        marginHorizontal: 20,
        justifyContent: 'center',
        marginTop: 80
    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        marginLeft: 20,
        marginBottom: 10,
        alignItems: 'flex-end'
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        marginVertical: 10,
    },
})
