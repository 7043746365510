import { useEffect, useState } from 'react'
import { View, ScrollView, StyleSheet, ViewStyle, TouchableOpacity } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import { useCalculateExtraItemBasedOnScreenWidth } from '../common/use-calculate-extra-item-based-on-screen-width'
import { avatarArray, avatars, animalAvatarArray, fruitAvatarArray, monsterAvatarArray, peopleAvatarArray, origamiAvatarArray, AvatarCategory, oceanAvatarArray } from '../icon/avatar/avatars'
import { Pagination } from '../common/pagination'
import { ProgressiveLoader } from '../switches/common/progressive-loader'
interface Props {
    onPress?: (avatarName: keyof typeof avatars) => void
}


export const TeacherAvatarPicker = ({ onPress }: Props) => {
    const [value, setValue] = useState<AvatarCategory>(AvatarCategory.Animal)
    const extraItems = useCalculateExtraItemBasedOnScreenWidth(itemSize, mainContainerPadding, value === AvatarCategory.Animal ? animalAvatarArray.length : value === AvatarCategory.Fruit ? fruitAvatarArray.length : value === AvatarCategory.Monster ? monsterAvatarArray.length : peopleAvatarArray.length)

    const handlePress = (val: AvatarCategory) => () => {
        setValue(val);
    };

    const avatarArrays = {
        [AvatarCategory.Animal]: animalAvatarArray,
        [AvatarCategory.Fruit]: fruitAvatarArray,
        [AvatarCategory.Monster]: monsterAvatarArray,
        [AvatarCategory.People]: peopleAvatarArray,
        [AvatarCategory.Origami]: origamiAvatarArray,
        [AvatarCategory.Ocean]: oceanAvatarArray
    };

    return (
        <View testID="TeacherAvatarPicker" style={styles.avatarsView}>
            <View style={styles.pagination}>
                <Pagination
                    V1={value === AvatarCategory.Animal}
                    V2={value === AvatarCategory.Fruit}
                    V3={value === AvatarCategory.Monster}
                    V4={value === AvatarCategory.People}
                    V5={value === AvatarCategory.Origami}
                    V6={value === AvatarCategory.Ocean}
                    V1pressed={handlePress(AvatarCategory.Animal)}
                    V2pressed={handlePress(AvatarCategory.Fruit)}
                    V3pressed={handlePress(AvatarCategory.Monster)}
                    V4pressed={handlePress(AvatarCategory.People)}
                    V5pressed={handlePress(AvatarCategory.Origami)}
                    V6pressed={handlePress(AvatarCategory.Ocean)}
                />
            </View>
            <ScrollView contentContainerStyle={styles.avatarsScrollView}>
                <ProgressiveLoader>
                    {avatarArrays[value].map(({ avatarName, Avatar }) => {
                        const onAvatarPress = onPress ? () => onPress(avatarName) : undefined;
                        return (
                            <TouchableOpacity
                                testID={`button-avatar-${String(avatarName)}`}
                                key={String(avatarName)}
                                style={styles.avatarView}
                                onPress={onAvatarPress}
                            >
                                <Avatar />
                            </TouchableOpacity>
                        );
                    })}
                </ProgressiveLoader>
                {
                    extraItems > 0 ? Array(extraItems).fill(0).map((_, index) => <View key={index} style={itemContainerStyle} />) : null
                }
            </ScrollView>
        </View>
    )
}

const itemSize = 70
const mainContainerPadding = 5

const styles = StyleSheet.create({
    avatarsView: {
        flex: 1,
        width: '100%',
        padding: mainContainerPadding,
        paddingBottom: 20,
    },
    avatarsScrollView: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    avatarView: {
        padding: 5,
        width: itemSize,
        height: itemSize,

    } as ViewStyle,
    selected: {
        borderWidth: 1,
        borderColor: DecidaColors.Black
    },
    pagination: {
        alignItems: 'center',
    }
})

const itemContainerStyle = [styles.avatarView, { minWidth: itemSize, maxWidth: itemSize }]
