export const lessThanMinVersionRequired = (minimumVersion: string | undefined, currentVersion: string) => {

  if (minimumVersion === undefined) return

  const minimumVersionArray = minimumVersion.split(".");
  const currentVersionArray = currentVersion.split(".");

  const maxArrayLength = Math.max(minimumVersionArray.length, currentVersionArray.length)

  for (let x = 0; x < maxArrayLength; x++) {
    const min = Number(minimumVersionArray[x] || 0)
    const current = Number(currentVersionArray[x] || 0)

    if (min < current) {
      return false
    } else if (min > current) {
      return true
    }
  }

  return false
}
