import { TextInput } from 'react-native'
import { StyleSheet } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import { rvChangePasswordUsername } from './login-state'

export const HiddenUsername = () =>
    <TextInput
        style={styles.hidden}
        value={rvChangePasswordUsername()}
        textContentType='username'
    />

const styles = StyleSheet.create({
    hidden: {
        height: 1,
        color: DecidaColors.White,
    },
})