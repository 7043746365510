import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { GuessTheWordIcon } from '../../icons/guess-the-word-icon'
import { GuessTheWordIconInverted } from '../../icons/guess-the-word-icon-inverted'


export const SwitchesActivitiesNumberAndWordsGuessTheWord: CardTemplate = {
    cardId: "35a29f03-d771-4de4-a83a-3c1972d808b1",
    title: 'Guess the word',
    Header: GuessTheWordIconInverted,
    Icon: GuessTheWordIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Gather a group of friends (3 or more works best)"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The group chooses one person to be the ‘master’ for the round"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The master thinks of any word and writes it down for only them to see"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The rest of the group can ask 10 yes/no questions about that word"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Then the group can have 10 guesses at the word and the master responds on a hot-cold scale (cold if their guess isn’t close and hot if it is) "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"If the group has not guessed the word, in the last three guesses the master will reveal one new letter in the word (any letter – does not have to be the starting letter)"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"If by the time the group runs out of guesses and they have not gotten the word, the master wins and goes again for the next round "} />

        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.NUMBER_AND_WORD_GAMES,
    whyDoesItWorkText: '',
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.NUMBER_AND_WORD_GAMES],
    action: [CardActionType.Thought],
    level: []
}

