import { FontAwesome } from '@expo/vector-icons';
import React, { useState } from 'react';
import { FlatList, Modal, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { DecidaColors } from '../../common/decida-colors';
import { IconEdit } from '../icon/icon-edit';
import ButtonGroupSubmitCancel from './button-group-cancel-submit';
import { DefaultButton, DefaultButtonTypes } from './default-button';
import { DefaultText } from './default-text';
import InputDefault from './input-default';
import { showAlert } from './universal-alert';
import { SchoolAdmin, Staff, Teacher } from '../../common/API';
import InputSearchTeacherRoleComponent, { getRoleByValue } from './input-search-teacher-role-component';

export interface TableColumn {
    label: string;
    key: DefaultTableColumnKey;
}

interface TableProps {
    data: ({ [key: string]: any })[];
    columns: TableColumn[];
    onEdit?: (data: any) => void;
    onCreate?: (data: any) => void;
    onDelete?: (data: any) => void;
    showCreateUpdateDelete?: boolean;
}

type TeacherKeys = keyof Teacher;
type StaffKeys = keyof Staff;
type SchoolAdminKeys = keyof SchoolAdmin;

type DefaultTableColumnKey = TeacherKeys | StaffKeys | SchoolAdminKeys | "action_buttons";

const DefaultTable: React.FC<TableProps> = ({ data, columns, onEdit, onCreate, onDelete, showCreateUpdateDelete = true }) => {
    const [sortBy, setSortBy] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [modalVisible, setModalVisible] = useState(false);
    const [formData, setFormData] = useState<{ [key: string]: any }>({});

    const toggleSort = (key: string) => {
        if (sortBy === key) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(key);
            setSortOrder('asc');
        }
    };

    const sortedData = sortBy
        ? [...data].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortBy] < b[sortBy] ? -1 : 1;
            } else {
                return a[sortBy] > b[sortBy] ? -1 : 1;
            }
        })
        : data;

    const toggleAddModal = () => {
        setFormData({});
        setModalVisible(true);
    };

    const handlePressEdit = (item: { [key: string]: any }) => {
        if (onEdit) {
            setFormData(item);
            setModalVisible(true);
        }
    };

    const handleFormSubmit = () => {
        if (formData?.id) {
            if (onEdit !== undefined) {
                onEdit(formData);
            }
        } else {
            if (onCreate !== undefined) {
                onCreate(formData);
            }
        }
        setModalVisible(false);
    };

    const handleDelete = (item: any) => {
        if (onDelete) {
            onDelete(item);
            setModalVisible(false);
        }
    };

    const toggleDeletePrompt = (item: { [key: string]: any }) => {
        showAlert({
            title: "Delete user",
            message: "Are you sure you want to delete this user?",
            leftButtonText: "Cancel",
            rightButtonText: "Yes, delete!",
            onRightButtonPress: () => handleDelete(item)
        });
    };

    const formInputColumns = columns.filter((c) => c.key !== "action_buttons" && c.key !== 'cognitoUsername');

    return (
        <View style={styles.container}>
            {showCreateUpdateDelete && onCreate && (
                <View style={{ marginVertical: 10 }}>
                    <DefaultButton onPress={toggleAddModal} style={{ width: 120, height: 40 }} plusIcon type={DefaultButtonTypes.HierarchyUI} buttonTextColor={DecidaColors.Green}>
                        Add
                    </DefaultButton>
                </View>
            )}
            <FlatList
                data={sortedData}
                keyExtractor={(_, index) => index.toString()}
                renderItem={({ item }) => (
                    <View style={styles.row}>
                        {columns.map(column => {
                            if (column.key === 'action_buttons' && showCreateUpdateDelete) {
                                return (
                                    <View style={styles.actionButtonsCell} key={column.key}>
                                        <TouchableOpacity onPress={() => handlePressEdit(item)} style={{ width: 22, height: 22 }}>
                                            <IconEdit />
                                        </TouchableOpacity>
                                        <FontAwesome onPress={() => toggleDeletePrompt(item)} name="minus-circle" size={24} color={DecidaColors.Angry} />
                                    </View>
                                );
                            }
                            return (
                                <div
                                    title={item[column.key]}
                                    key={column.key}
                                    style={styles.cell}
                                >
                                    <DefaultText textProps={{ numberOfLines: 2 }} style={styles.cellText}>{column.key === "role" ? getRoleByValue(item[column.key]) : item[column.key]}</DefaultText>
                                </div>
                            );
                        })}
                    </View>
                )}
                style={{ backgroundColor: DecidaColors.GrayBackground }}
                ListHeaderComponent={() => (
                    <View style={styles.header}>
                        {columns.map(column => (
                            <TouchableOpacity
                                key={column.key}
                                onPress={() => toggleSort(column.key)}
                                style={styles.cell}
                            >
                                <Text numberOfLines={1} style={styles.headerText}>{column.label}</Text>
                            </TouchableOpacity>
                        ))}
                    </View>
                )}
            />
            <Modal
                transparent
                visible={modalVisible}
                onRequestClose={() => setModalVisible(false)}
            >
                <View style={styles.modalOverlay}>
                    <View style={styles.modalContainer}>
                        {formInputColumns.map(column => {
                            if (column.key === "role") {
                                return (
                                    <View style={{ zIndex: 99 }} key={column.key}>
                                        <DefaultText style={styles.inputLabel}>Role</DefaultText>
                                        <InputSearchTeacherRoleComponent
                                            selected={getRoleByValue(formData[column.key]) || ""}
                                            value={formData[column.key] || ''}
                                            setSelected={(selectedValue) => setFormData({ ...formData, [column.key]: selectedValue.value })} />
                                    </View>
                                );
                            }
                            return (
                                <View key={column.key} style={styles.modalInputContainer}>
                                    <InputDefault
                                        label={column.label}
                                        value={formData[column.key] || ''}
                                        onChangeText={(text) => setFormData({ ...formData, [column.key]: text })}
                                    />
                                </View>
                            );
                        })}
                        <ButtonGroupSubmitCancel onConfirm={handleFormSubmit} onCancel={() => setModalVisible(false)} />
                    </View>
                </View>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    editButton: {
        flex: 1,
        height: 40,
        justifyContent: 'center',
        minWidth: 'auto'
    },
    deleteButton: {
        flex: 1,
        backgroundColor: DecidaColors.Angry,
        height: 40,
        justifyContent: 'center',
        minWidth: 'auto'
    },
    actionButtonsCell: {
        flex: 1,
        flexDirection: 'row',
        gap: 8,
        flexWrap: 'wrap'
    },
    container: {
        flex: 1,
        backgroundColor: DecidaColors.White,
        borderRadius: 8,
    },
    header: {
        flexDirection: 'row',
        paddingVertical: 10,
        paddingHorizontal: 10,
        backgroundColor: DecidaColors.LightGray,
        borderBottomWidth: 1,
        borderBottomColor: DecidaColors.GrayBackground,
        gap: 10,
    },
    row: {
        flexDirection: 'row',
        paddingVertical: 10,
        paddingHorizontal: 10,
        borderBottomWidth: 1,
        borderBottomColor: DecidaColors.GrayBackground,
        gap: 10,
    },
    cell: {
        flex: 1,
        alignItems: 'flex-start',
    },
    cellText: {
        fontSize: 16,
        color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'left',
        flexWrap: 'wrap',
    },
    headerText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'left',
        flexWrap: 'wrap',
    },
    modalOverlay: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        width: 300,
        padding: 20,
        backgroundColor: 'white',
        borderRadius: 10,
    },
    modalInputContainer: {
        marginBottom: 5
    },
    modalInput: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 10,
    },
    inputLabel: {
        fontSize: 16,
        color: DecidaColors.GrayText,
        fontWeight: '600',
        marginVertical: 5
    },
});

export default DefaultTable;
