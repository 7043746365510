import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useEffect, useState } from 'react'
import { ListSchoolsQuery, ListSchoolsQueryVariables, SchoolStatus } from '../../common/API'
import { Br } from '../common/br'
import { DefaultText } from '../common/default-text'
import { Loading } from '../common/loading'
import { Page } from '../common/page'
import { listSchoolsQuery } from './graphql-scripts'

export const AdminWelcome = () => {
    const { data, loading } = useQuery<ListSchoolsQuery, ListSchoolsQueryVariables>(gql`${listSchoolsQuery}`, { variables: { filter: { _deleted: { ne: true } }, limit: 5000 } })
    const [activeStudent, setActiveStudent] = useState(0)
    const [onTrialStudent, setOnTrialStudent] = useState(0)
    const [subscribedStudent, setSubscribedStudent] = useState(0)
    const [activeSchool, setActiveSchool] = useState(0)
    const [onTrialSchool, setOnTrialSchool] = useState(0)
    const [subscribedSchool, setSubscribedSchool] = useState(0)
    const [totoalLicence, setTotalLicence] = useState(0)
    const [totalSubscriptionCost, setTotalSubscriptionCost] = useState(0)
    const [allTimeCheckInCount, setAllTimeCheckInCount] = useState(0)
    const [ytdCheckInCount, setYtdCheckInCount] = useState(0)

    useEffect(() => {
        if (data?.listSchools?.items) {
            let onTrialSt = 0
            let subscribedSt = 0
            let onTrialSc = 0
            let subscribedSc = 0
            let totalLic = 0
            let totalSubCost = 0
            let allTimeCheckInCount = 0
            let ytdCheckInCount = 0

            data.listSchools.items.forEach(school => {
                if (school?.status === SchoolStatus.ON_TRIAL) {
                    onTrialSt += (school.activeStudents || 0)
                    onTrialSc++
                } else if (school?.status === SchoolStatus.SUBSCRIBED) {
                    subscribedSt += (school.activeStudents || 0)
                    subscribedSc++
                    totalLic += (Number(school.licenseTotal) || 0)
                    totalSubCost += (Number(school.licenseTotal) || 0) * (school.wholeSchoolSubscription ? 8 : 10)
                }

                if(school?.status && school.status !== SchoolStatus.TEST) {
                    allTimeCheckInCount += school.checkInCountAllTime || 0
                    ytdCheckInCount += school.checkInCountYTD || 0
                }
            })

            setOnTrialStudent(onTrialSt)
            setSubscribedStudent(subscribedSt)
            setActiveStudent(onTrialSt + subscribedSt)
            setOnTrialSchool(onTrialSc)
            setSubscribedSchool(subscribedSc)
            setActiveSchool(onTrialSc + subscribedSc)
            setTotalLicence(totalLic)
            setTotalSubscriptionCost(totalSubCost)
            setAllTimeCheckInCount(allTimeCheckInCount)
            setYtdCheckInCount(ytdCheckInCount)
        }
    }, [data])

    if (loading) {
        return <Loading isLoading />
    }

    return (
        <Page>
            <DefaultText>All Time Check in count: {allTimeCheckInCount}</DefaultText>
            <DefaultText>YTD Check in count: {ytdCheckInCount}</DefaultText>
            <Br />
            <DefaultText>Active school: {activeSchool}</DefaultText>
            <DefaultText>Subscribed school: {subscribedSchool}</DefaultText>
            <DefaultText>On trial school: {onTrialSchool}</DefaultText>
            <Br />
            <DefaultText>Total Licence: {totoalLicence}</DefaultText>
            <DefaultText>Active students: {activeStudent}</DefaultText>
            <DefaultText>Subscribed students: {subscribedStudent}</DefaultText>
            <DefaultText>On trial students: {onTrialStudent}</DefaultText>
            <Br />
            <DefaultText>Average cost/student/year: ${Math.round(totalSubscriptionCost/subscribedStudent*100)/100}</DefaultText>
        </Page>
    )
}
