import { View } from 'react-native'
import { DefaultTextWithDownArrow } from '../../../common/default-text-with-down-arrow'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { BucketsForSafeThoughtsBlueIcon } from '../../icons/buckets-for-safe-thoughts-blue-icon'
import { DefaultTextWithExtraTipIcon } from '../../../common/default-text-with-extra-tip-icon'
import { BucketsForSafeThoughtsBlueIconInverted } from '../../icons/buckets-for-safe-thoughts-blue-icon-inverted'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'
import { BucketOfSafeThoughtsImage1 } from '../../../emotion-cards/anxious/src/bucket-of-safe-thoughts-image1'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'

export const SwitchesActivitiesSecretsForSleepBucketOfSafeThoughtsBlue: CardTemplate = {
    cardId: "01b4f6bb-a8b5-4844-9465-9d5d8c2908cf",
    title: 'Bucket of safe thoughts',
    Header: BucketsForSafeThoughtsBlueIconInverted,
    Icon: BucketsForSafeThoughtsBlueIcon,
    Images: () => <BucketOfSafeThoughtsImage1 />,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Calming minds that tend to ruminate on problems, conversations, upcoming events or social interactions."} />
            <DefaultTextWithoutArrowsItallic textdata={"At night, if you have trouble sleeping because you can’t stop thinking or stressing about something, use these topics as a bucket of safe thoughts and commit to only thinking of them - nothing else."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"Be patient. Don’t be in a hurry to change your thoughts and feelings. No thought or feeling stays forever. Be patient and know that whatever you are feeling, or whatever you are thinking, it will pass."} />
            <DefaulTextWithTipsIcon textdata={"Strategies like this get more effective with practice. The first time you try them, you might not notice much. Same with the second time, and the third time. Eventually though, the more you experiment with them and the more you use them, the more capacity you will have to harness the strength of your wild and beautiful mind and make it work more in your favour. "} />
            {/* <DefaultTextWithoutArrowsBold textdata={"Handy Hint"} /> */}
            <DefaultTextWithoutArrowsItallic textdata={"At night, if you have trouble sleeping because you can’t stop thinking or stressing about something, use these topics as a bucket of safe thoughts and commit to only thinking of them - nothing else."} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"If I won a million dollars, how would I spend it, and who would it impact?"} />
                <DefaulttextWithRightArrow textdata={"What is your favourite superpower? What would your adventures be?"} />
                <DefaulttextWithRightArrow textdata={"What does your ultimate holiday look like?"} />
                <DefaulttextWithRightArrow textdata={"What would you like to be famous for, and what would you do in an average day?"} />
                <DefaultTextWithDownArrow textdata={"Close your eyes, visualise your daydream and enjoy it."} />

            </View>
        </>
    ),
    colorScheme: CardColorScheme.SECRETS_FOR_SLEEP,
    whyDoesItWorkText: 'Sleep and mental rest is crucial to performance, increasing cognitive capacity and mental health. Anxiety is the brains safety mechanism kicking into place, indicating danger or imminent threat. Your brain doesn’t then let you sleep because it is keeping you prepared for an imminent attack, for which you need to be awake for. Having a ‘bucket’ of safe thoughts helps direct your brain to focus on pleasure seeking thoughts, rather than anxiety producing thoughts, allowing your brain to rest peacefully.',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Bucket+of+safe+thoughts.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.SECRETS_FOR_SLEEP],
    action: [CardActionType.Thought],
    level: []
}

