import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { DrawerHeaderProps, DrawerNavigationProp } from '@react-navigation/drawer/lib/typescript/src/types'
import { useNavigation } from '@react-navigation/native'
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import { ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { listCheckInSessionsByClassId } from '../../common/graphql/queries'
import { rvCurrentClass } from '../common/common-state'
import { DefaultText } from '../common/default-text'
import { Header } from '../common/header'
import HeaderBottomRow from '../common/header-bottom-row'
import LeftHeaderLogo from '../common/left-header-logo'
import { CLIENT_EVENT, createClientLog } from '../common/log-client-event'
import { logout } from '../common/logout'
import { rvShowPopoverClasslogin } from '../common/pop-over-menu-state'
import { ScreenNames } from '../common/screen-names'
import { useResponsive } from '../common/use-responsive'
import { IconDownArrow } from '../icon/icon-down-arrow'
import { IconHeaderCheckIn } from '../icon/icon-header-check-in'
import { IconHeaderUserLoginAvatar } from '../icon/icon-header-user-login-avatar'
import { rvSwitchesScreenState } from '../switches/common/current-screen-state'
import { SwitchesScreens } from '../switches/navigation/switches-bottom-navigation'
import { ClassLoginNavigationParams } from './class-login-navigation-params'

export const ClassLoginHeader = (props: DrawerHeaderProps) => {

    const { navigate } = useNavigation<DrawerNavigationProp<ClassLoginNavigationParams>>()
    const currentClass = useReactiveVar(rvCurrentClass)
    
    const { data } = useQuery<ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables>(gql`${listCheckInSessionsByClassId}`, { variables: { classID: currentClass?.id || "", limit: 5000 }, skip: !currentClass })
    const currentCheckIn = data?.listCheckInSessionsByClassId?.items.find((item) => item?.isActive && item._deleted !== true)

    const showPopover = useReactiveVar(rvShowPopoverClasslogin)
    const { width } = useResponsive()

    const logoutHandler = () => {
        logout()
        createClientLog({
            event: CLIENT_EVENT.USER_SIGNED_OUT,
            area: "Class login header header",
            action: "Clicking logout button, and sign out",
            payload: {
                group: "class",
            }
        })
    }
    const navigateToHome = () => {
        rvSwitchesScreenState(SwitchesScreens.HOME)
        navigate(ScreenNames.ClassLoginHome)
    }

    const togglePopoverMenuVisibility = () => {
        rvShowPopoverClasslogin(!showPopover)
    }

    return (
        <View>
            <Header
                {...props}
                disableDrawer
                leftChildren={
                    width > 320 && <LeftHeaderLogo onPress={navigateToHome} />
                }
                centerChildren={
                    <View />
                }
                rightChildren={

                    <>
                        <TouchableOpacity onPress={navigateToHome}>
                            <View style={styles.icon}>
                                <IconHeaderCheckIn color={currentCheckIn ? DecidaColors.Green : DecidaColors.Gray} />
                                <DefaultText style={styles.iconText}>Check-in</DefaultText>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity testID='header-profile-menu' onPress={togglePopoverMenuVisibility}>
                            <View style={styles.profileContainer}>
                                <View style={[styles.icon, styles.profileIcon]}>
                                    <IconHeaderUserLoginAvatar color={DecidaColors.Green} fill={DecidaColors.White} />
                                    <DefaultText style={styles.iconText}>Profile</DefaultText>
                                </View>
                                <IconDownArrow stylefill={DecidaColors.Gray} />
                            </View>
                        </TouchableOpacity>
                    </>
                }
                headerStyle={{ backgroundColor: DecidaColors.White }}

            />
            <HeaderBottomRow />
        </View>
    )
}

const styles = StyleSheet.create({
    icon: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
    } as ViewStyle,
    iconText: {
        marginTop: 5,
        fontSize: 15,
        color: DecidaColors.Gray,
    },
    profileIcon: {
        marginRight: 0,
    },
    profileContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    cog: {
        marginVertical: -4
    },
    moreInfomation: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },

})