import { Platform, StyleSheet, View } from 'react-native';
import Svg, { Line, Rect } from 'react-native-svg';
import { DefaultText } from '../common/default-text';
import { IconTirednessAsleep } from '../icon/icon-tiredness-asleep';
import IconTirednessWellRested from '../icon/icon-tiredness-well-rested';
import { GRAPH_HEIGHT, GRAPH_LABEL_FONTSIZE } from './teacher-consts';
import { DecidaColors } from '../../common/decida-colors';

const batteryCapHeight = 4;
const batteryCapWidth = 15;
const batteryCapPadding = 2;
const batteryBodyStrokWidth = 2;
const batteryBodyTop =
    batteryCapHeight + batteryCapPadding + batteryBodyStrokWidth / 2;
const batteryBodyHeight = 90;
const batteryWidth = 35;
const noOfBatteryBodyGrid = 6;
const batteryBodyGridStepSize = batteryBodyHeight / noOfBatteryBodyGrid;
const graphHeight =
    batteryBodyTop + batteryBodyHeight + batteryBodyStrokWidth / 2;
const maxEnergyValue = 5;
const batteryBodyCornerRadius = 10
const batteryValueMaskWidth = 5

const batteryValueColor = 'rgba(108,107,108,0.58)';
const batteryBorderColor = '#707070';

type Props = {
    energyValue: string;
};

export const TeacherStatsBatteryGraphLastCheckIn = ({
    energyValue,
}: Props) => {
    const valueHeight = (batteryBodyHeight * Number(energyValue)) / maxEnergyValue;

    return (
        <View style={{ alignSelf: 'center', justifyContent: 'center' }}>

            <View style={{ width: '100%', height: GRAPH_HEIGHT - batteryBodyTop, flexDirection: 'row', marginHorizontal: 10 }}>

                {/* Battery graph */}
                <Svg
                    viewBox={`0 0 ${batteryWidth} ${graphHeight}`}
                >
                    {/* value fill */}
                    <Rect
                        strokeWidth={0}
                        fill={batteryValueColor}
                        x={0}
                        y={graphHeight - valueHeight}
                        width={batteryWidth}
                        height={valueHeight}
                    />

                    {/* value fill mask */}
                    <Rect
                        strokeWidth={batteryValueMaskWidth}
                        x={0 - batteryValueMaskWidth / 2}
                        y={batteryBodyTop - batteryValueMaskWidth / 2}
                        width={batteryWidth + batteryValueMaskWidth}
                        height={batteryBodyHeight + batteryValueMaskWidth}
                        stroke='white'
                        fill='transparent'
                        rx={batteryBodyCornerRadius + batteryValueMaskWidth / 2}
                    />

                    {/* battery cap */}
                    <Rect
                        strokeWidth={0}
                        x={(batteryWidth - batteryCapWidth) / 2}
                        y={0}
                        width={batteryCapWidth}
                        height={batteryCapHeight}
                        fill={batteryBorderColor}
                    />

                    {/* battery body */}
                    <Rect
                        strokeWidth={batteryBodyStrokWidth}
                        x={0}
                        y={batteryBodyTop}
                        width={batteryWidth}
                        height={batteryBodyHeight}
                        stroke={batteryBorderColor}
                        fill='transparent'
                        rx={batteryBodyCornerRadius}
                    />

                    {/* battery grid lines */}
                    {new Array(noOfBatteryBodyGrid - 1).fill(0).map((_, i) => {
                        const y = batteryBodyTop + (i + 1) * batteryBodyGridStepSize;
                        return (
                            <Line
                                key={String(i)}
                                strokeWidth={batteryBodyStrokWidth}
                                stroke={batteryBorderColor}
                                x1={0}
                                x2={batteryWidth}
                                y1={y}
                                y2={y}
                            />
                        );
                    })}

                </Svg>

                {/* Tiredness icons */}

                <View style={[styles.tirednessIconsContainer, { right: Platform.OS === 'ios' ? 40 : undefined }]}>
                    <View style={styles.tirednessWellRested}>
                        <IconTirednessWellRested />
                    </View>
                    <View style={{ flex: 1 }}>
                        <DefaultText style={styles.energyValue}>
                            {energyValue}
                        </DefaultText>
                    </View>
                    <View style={styles.tirednessAssleep}>
                        <IconTirednessAsleep stroke={batteryBorderColor} />
                    </View>
                </View>
            </View>

            <View style={styles.headerTextContaier}>
                <DefaultText style={styles.title}>Energy level (av.)</DefaultText>
            </View>
        </View >
    );
};

const styles = StyleSheet.create({
    tirednessAssleep: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    tirednessIconsContainer: {
        marginLeft: 10,
        height: '100%',
        maxWidth: 30,
        justifyContent: "space-between",
    },
    tirednessWellRested: {
        paddingRight: 7,
        flex: 1,
    },

    energyValue: {
        fontStyle: 'italic',
        color: DecidaColors.GrayText,
        fontWeight: '100',
        lineHeight: 20,
        fontSize: GRAPH_LABEL_FONTSIZE,
    },
    headerTextContaier: {
        marginTop: 10,
        alignItems: 'center',
    },
    title: {
        fontSize: GRAPH_LABEL_FONTSIZE,
        fontWeight: 'bold',
        color: DecidaColors.GrayText,
    },
});
