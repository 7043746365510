import { Emotion, StudentCheckIn } from "./API"
import { StudentCheckInCacheObject } from "./student-check-in-cache"


export const studentCheckInHasFlag = (studentCheckIn: StudentCheckIn | StudentCheckInCacheObject): boolean => {

    if (!studentCheckIn) {
        return false
    }


    if (studentCheckIn.chatCleared) {
        return false
    }

    const negativeEmotions = [Emotion.SAD, Emotion.ANGRY, Emotion.ANXIOUS, Emotion.SCARED]
    const isNegativeEmotion = studentCheckIn.emotion && negativeEmotions.includes(studentCheckIn.emotion)

    if (isNegativeEmotion && studentCheckIn?.emotionIntensityPercentage && studentCheckIn.emotionIntensityPercentage > 0.7) {
        return true
    }

    if (studentCheckIn.tiredness && studentCheckIn.tiredness === 1) {
        return true
    }

    return false
}