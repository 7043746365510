import { useNavigation } from '@react-navigation/native'
import { SafeAreaView, StyleProp, StyleSheet, TextStyle, TouchableOpacity } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from './default-text'
import { useReactiveVar } from '@apollo/client'
import { rvPrintMode } from './export-to-pdf-button'

export type BackButtonOnPress = (patchedGoBack: () => void) => void
interface Props {
    displayName?: string
    onPress?: BackButtonOnPress
    style?: StyleProp<TextStyle>
}

export const BackButton = ({ displayName, onPress, style = {} }: Props) => {
    const { goBack, canGoBack } = useNavigation()
    const isPrintMode = useReactiveVar(rvPrintMode)

    const handleGoBack = () => {

        if (onPress) {
            onPress(patchedGoBack)
            return
        }
        patchedGoBack()
    }

    const patchedGoBack = () => {
        if (!canGoBack()) {
            history.back()
            return
        }
        goBack()
    }

    return (
        <SafeAreaView style={{ display: isPrintMode ? "none" : undefined }}>
            <TouchableOpacity onPress={handleGoBack} accessibilityRole='link'>
                <DefaultText style={[styles.backToLoginText, style]}>{`< ${displayName || 'Back'}`}</DefaultText>
            </TouchableOpacity>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    backToLoginText: {
        marginLeft: 10,
        color: DecidaColors.DarkBlue,
    } as TextStyle,
})