import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import VideoCard from '../../../common/video-card'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { LaughHeader } from './src/laugh-header'
import { LaughIcon } from './src/laugh-icon'
import { LaughImage1 } from './src/laugh-image1'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'


const babiesLaughingVideo: Videos = {
    id: "1",
    url: "https://player.vimeo.com/video/168328319",
    title: "",
}

const everyTypeOfLaughingVideo: Videos = {
    id: "2",
    url: "https://www.youtube.com/embed/rPY4yOmNw7w",
    title: "",
}

export const SwitchesEmotionsHappyLaugh: CardTemplate = {
    cardId: "ce911b05-dccb-4023-8b66-db2aebe1bae6",
    title: 'Laugh',
    Header: LaughHeader,
    Icon: LaughIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Improving your mood and enjoying life a little more!"} />
        </>
    ),
    Images: () => (
        <>
            <LaughImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"For no particular reason, start to smile, and start to laugh out loud"} />
                <DefaulttextWithRightArrow textdata={"If it helps, find the ‘Memes’ section of the Switch4Schools program on your lower menu."} />
                <DefaulttextWithRightArrow textdata={"Select the ‘Another Funny’ button as many times as you want."} />
            </View>
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata="Feel free to laugh out loud whenever you feel like it!" />
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: `Laughter has a number of positive effects on the body and mind. When you laugh, your body releases endorphins, which are chemicals that act as natural painkillers and mood elevators. Endorphins also help to reduce stress and improve your overall sense of wellbeing.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand that laughter can have a positive effect on their mood and emotions</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 35 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Watch the video of babies laughing <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='https://player.vimeo.com/video/168328319' link='https://player.vimeo.com/video/168328319' />.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discus how it makes people feel during and after the video.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think about a time when they laughed really hard.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them share their stories with the class and pay attention to any common themes or patterns, such as what they were doing, who they were with, or how they were feeling.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept that laughter can have a positive effect on our mood and emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that when we laugh, our brain releases chemicals called endorphins and serotonin, which can help improve our mood and overall sense of wellbeing.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students share some of their favorite jokes or funny stories with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to pay attention to how laughter makes them feel and share their observations with the group.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give students a sheet of paper and have them draw a picture of themselves laughing, and/or have them write a short paragraph about how laughter makes them feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice laughing throughout the day and pay attention to how it affects their mood and emotions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Review the concept that laughter can have a positive effect on our mood and emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to continue practicing laughter as a way to improve their overall sense of wellbeing.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Video of babies laughing <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='https://player.vimeo.com/video/168328319' link='https://player.vimeo.com/video/168328319' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen and paper for each student</DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Best Babies Laughing Video Compilation</DefaultText>
                        <DefaultTextWithLink text='https://player.vimeo.com/video/168328319' />
                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={babiesLaughingVideo} />
                        </View>

                        <DefaultText style={CommonStyles.lessonPlanText}>Almost Every Type of Laughing</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=rPY4yOmNw7w' />
                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={everyTypeOfLaughingVideo} />
                        </View>

                        <DefaultText style={CommonStyles.lessonPlanText}>Study: Laughter Really Is Contagious -</DefaultText>
                        <DefaultTextWithLink text='https://www.livescience.com/9430-study-laughter-contagious.html' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The science of laughter – and why it also has a dark side</DefaultText>
                        <DefaultTextWithLink text='https://theconversation.com/the-science-of-laughter-and-why-it-also-has-a-dark-side-76463' />
                    </>}
            />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Laugh+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Happy]
}

const styles = StyleSheet.create({
    rowContainer: {
        flex: 1,
        flexDirection: 'row',
        marginHorizontal: 20,
        justifyContent: 'center',
        marginTop: 80
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        marginVertical: 10,
    },
})
