import { useReactiveVar } from '@apollo/client'
import { useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { rvCurrentSwitchAudioPlaying } from '../../../common/common-state'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import SwitchCardVideo from '../../../common/switch-card-video'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import SeeItAudioButtons, { SeeItAudio } from './see-it-audio-buttons'
import { SeeItHeader } from './src/see-it-header'
import { SeeItIcon } from './src/see-it-icon'
import { SeeItImage1 } from './src/see-it-image1'

const seeItVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/NYzowu-EaPY",
    title: "",
}

export const SwitchesEmotionsScaredSeeIt: CardTemplate = {
    cardId: "b756de14-eb70-4cdf-911e-c1306a88ee0a",
    title: 'See it',
    Header: SeeItHeader,
    Icon: SeeItIcon,
    lessonPlan: true,
    PerfectFor: () => {

        const currentAudioPlaying = useReactiveVar(rvCurrentSwitchAudioPlaying)

        const audioButtons = useMemo(() => <SeeItAudioButtons />, [])

        return (
            <>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata={"Distracting the mind when the brain and body are in a highly activated state."} />

                <View style={styles.imageContainer}>
                    <SeeItImage1 />
                </View>

                <View style={commonCardStyles.container}>
                    <DefaulttextWithRightArrow textdata={"If possible, find a quiet place to sit and relax where you won’t be disturbed."} />
                    <DefaulttextWithRightArrow textdata={"Select one of the four scenario buttons below. "} />
                    <DefaulttextWithRightArrow textdata={"Read or listen to the instructions and spend time imagining the scenario in great detail."} />
                </View>

                {audioButtons}

                <View style={styles.bottomContainer}>
                    {currentAudioPlaying === SeeItAudio.StopSign && (
                        <>
                            <DefaultText style={CommonStyles.mainLessonText}>• Stop sign:</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Sit in a comfortable position, close your eyes, and allow your mind and body to be still and relax, clearing every thought out of your mind, and every tension out of your body. </DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Imagine yourself walking down a country dirt road on a beautiful sunny day.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ On the side of the road you see a big STOP sign.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Fix your gaze on the stop sign paying particular attention to every detail.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Either in your mind or aloud, spell out the word S T O P.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Either in your mind or aloud, repeat the word, “stop”.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Imagine yourself picking up a small stick and carefully drawing the word in the dirt. S-T-O-P. Make sure you attend to the sound and feeling of the stick as you draw.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Slowly open your eyes and embrace the rest of the day feeling confident and relaxed.</DefaultText>
                        </>
                    )}


                    {currentAudioPlaying === SeeItAudio.TheBlender && (
                        <>
                            <DefaultText style={CommonStyles.mainLessonText}>• The blender:</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Sit in a comfortable position, close your eyes, and allow your mind and body to be still and relax, clearing every thought out of your mind, and every tension out of your body. </DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Imagine a blender sitting on your kitchen bench.  </DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Take a minute to visualise yourself loudly blending all sorts of different foods.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Now add any thoughts or feelings into the blender which are causing you to be anxious.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ As you now blend it all together, see your thoughts disappear into the mix.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Take note of the colour and texture of the mix. </DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ When you feel all thoughts have gone, mentally shut off the blender and the noise it created.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Pour your thoughts down the sink. Enjoy the feeling of them being washed away. </DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ When you are ready, slowly open your eyes and embrace the rest of the day feeling confident and relaxed.  </DefaultText>
                        </>
                    )}


                    {currentAudioPlaying === SeeItAudio.BallOfString && (
                        <>
                            <DefaultText style={CommonStyles.mainLessonText}>• Ball of string:</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Sit in a comfortable position, close your eyes, and allow your mind and body to be still and relax, clearing every thought out of your mind, and every tension out of your body. </DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Imagine there is a ball of string tightly packed with all the stress of the day, sitting on the ground in front of you. </DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Slowly unwind the string so it gets longer and longer.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Keep going until the pressure is released and the ball fully unwinds to its maximum length.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Feel the texture of the string in your fingers as the ball gets smaller, and smaller, and smaller.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ When you are ready, slowly open your eyes and embrace the rest of the day feeling confident and relaxed.</DefaultText>

                        </>
                    )}

                    {currentAudioPlaying === SeeItAudio.BlueLight && (
                        <>
                            <DefaultText style={CommonStyles.mainLessonText}>• Blue light:</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Sit in a comfortable position, close your eyes, and allow your mind and body to be still and relax, clearing every thought out of your mind, and every tension out of your body. </DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Imagine you are surrounded by a big blue ball of light.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Take a big breath in, sucking in the blue light so it fills up your whole body.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Exhale slowly releasing your tensions in the form of black smoke that dissolves in the blue light around you.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Keep breathing in and out slowly. with each breath, allow your body to absorb more and more blue light.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ Keep going until all the black smoke is gone.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>◦ When you are ready, slowly open your eyes and embrace the rest of the day feeling confident and relaxed.</DefaultText>
                        </>
                    )}

                </View>
            </>
        )
    },
    CardContent: () => (
        <>
        </>
    ),
    cardContentTemplate: 'column',
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: 'Unlike avoidance, distraction is a healthy coping mechanism. Distraction means that you temporarily step away from a stressful situation and then return to it once your anxiety level has decreased. Distraction involves intentionally getting your mind off of the emotionally distressing situation to give yourself a break from those difficult feelings. ',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn and practice visualisation techniques to reduce stress and promote relaxation.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by briefly discussing stress and the importance of finding ways to manage it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the See It Switch, explaining that it involves visualisation techniques to help reduce stress and promote relaxation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that visualisation is a powerful tool for calming the mind and body.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Visualisation (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to find a quiet and comfortable place to sit where they won't be disturbed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that they will be guided through four different scenarios, each designed to promote relaxation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the audio recording for one of the scenarios (e.g. Stop sign) and guide students through the visualisation steps.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to focus on details and engage their senses during the visualisation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allow a brief moment of silence after each scenario to let students reflect on their experience.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Discussion and Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how the visualisation techniques made them feel and if they experienced any relief from stress.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that they can repeat these visualisations as often as needed for relaxation.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Switch4Schools App Integration (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, guide them to find the See It Switch in the Switch4Schools app.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how the app can be used to practice visualisations independently.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to explore the app and utilise the See It Switch as a tool for stress management.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the importance of visualisation in managing stress.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the idea that students have the power to use these techniques anytime they feel stressed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude by inviting students to open their eyes and embrace the rest of the day feeling confident and relaxed.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Speaker to play audio track </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Quiet, comfortable space</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>7 Visualisation Techniques:</DefaultText>
                        <DefaultTextWithLink text='https://www.netcredit.com/blog/visualisation-techniques-calm-anxious-mind/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Beyond Blue, What is anxiety and Anxiety Disorders?</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='What is anxiety?' link='https://www.youtube.com/watch?v=NYzowu-EaPY&ab_channel=BeyondBlueOfficial' />

                        <SwitchCardVideo video={seeItVideo} />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Close your eyes, and use the audio  to help guide you through the visualisation.' />
            <DefaulTextWithTipsIcon textdata='Repeat the process with multiple scenarios as often as you like.' />
            <DefaulTextWithTipsIcon textdata='Consider how you feel after each guided experience. Do you feel relieved of some of your stress?' />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/The+Anticipator+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action, CardActionType.Thought],
    level: [CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Scared]
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    bottomContainer: {
        marginVertical: 20
    }
})
