import { useState } from "react"

import { gql, useQuery } from "@apollo/client"
import _ from "lodash"
import { StyleSheet, View } from "react-native"
import { Invoice, ListInvoiceBySchoolIDQuery, ListInvoiceBySchoolIDQueryVariables, School } from "../../common/API"
import { DecidaColors } from "../../common/decida-colors"
import { DefaultButton } from "../common/default-button"
import { DefaultText } from "../common/default-text"
import { listInvoiceBySchoolID } from "../../common/graphql/queries"
import { CONTAINER_INPUT_WIDTH } from "./admin-consts"
import AdminSchoolInvoice from "./admin-school-invoice"
import AdminSchoolInvoiceForm from "./admin-school-invoice-form"

export const AdminSchoolInvoicesList = ({ school }: { school: School }) => {
    const [create, setCreate] = useState(false)

    const { data } = useQuery<ListInvoiceBySchoolIDQuery, ListInvoiceBySchoolIDQueryVariables>(gql`${listInvoiceBySchoolID}`, { variables: { schoolID: school.id || "" } })

    const invoicesList = _.orderBy(data?.listInvoiceBySchoolID?.items.filter(item => item?._deleted !== true), "createdAt", "desc") || []

    const toggleCreateMode = () => {
        setCreate(prev => !prev)
    }
    return (
        <View style={styles.container}>
            <DefaultText style={{ marginBottom: 10 }}>
                Invoices:
            </DefaultText>
            {invoicesList.map((invoice) => {
                return <AdminSchoolInvoice key={invoice?.id} invoice={invoice as Invoice} />
            })}
            {create ? (
                <AdminSchoolInvoiceForm schoolID={school.id} onCancel={toggleCreateMode} />
            ) : (
                <DefaultButton onPress={toggleCreateMode}>
                    Add new invoice
                </DefaultButton>
            )}
        </View>
    )
}


const styles = StyleSheet.create({
    container: {
        width: CONTAINER_INPUT_WIDTH,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        marginVertical: 20,
        borderColor: DecidaColors.GrayText,
    },
})