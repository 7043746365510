import { StyleSheet, Text, View } from 'react-native'
import DropdownPickerSchoolsStatusFilterContainer from '../common/dropdown-picker-schools-status-filter';
import { SchoolStatus } from '../models';
import InputSearchContent from '../common/input-search-content';
import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ListSchoolsQuery, ListSchoolsQueryVariables, School } from '../../common/API';
import { listSchoolsQuery } from './graphql-scripts';
import { Picker } from '@react-native-picker/picker';
import { DefaultText } from '../common/default-text';

export enum SortSchoolsBy {
    Name = 'Name',
    RenewalDate = 'Renewal date',
}

type Props = {
    schools: School[]
    setSchools: (schools: School[]) => void,
    sortBy?: SortSchoolsBy
    setSortBy?: (sortBy: SortSchoolsBy) => void
}

const AdminSchoolToolbar = ({ schools, setSchools, sortBy, setSortBy }: Props) => {
    const [searchKeywords, setSearchKeywords] = useState("")
    const [schoolStatusFilter, setSchoolStatusFilter] = useState<SchoolStatus | undefined>()

    const { data } = useQuery<ListSchoolsQuery, ListSchoolsQueryVariables>(gql`${listSchoolsQuery}`, { variables: { filter: { _deleted: { ne: true } }, limit: 5000 }, fetchPolicy: 'cache-and-network' })
    const schoolsData = data?.listSchools?.items as School[]

    useEffect(() => {
        filterSchoolByKeywords()
    }, [searchKeywords])

    useEffect(() => {
        filterSchoolByStatus()
    }, [schoolStatusFilter])

    const filterSchoolByKeywords = () => {
        if (searchKeywords) {
            const filteredSchool = schools?.filter((school) => school?.name.toLowerCase().includes(searchKeywords.toLowerCase())) || []
            setSchools(filteredSchool)
        } else {
            setSchools(schoolsData)
        }
    }

    const filterSchoolByStatus = () => {
        if (schoolStatusFilter) {
            const filteredSchool = schools?.filter((school) => school?.status === schoolStatusFilter)
            setSchools(filteredSchool)
        } else {
            setSchools(schoolsData)
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <DropdownPickerSchoolsStatusFilterContainer setValue={setSchoolStatusFilter} value={schoolStatusFilter} />
                <View style={styles.pickerContainer}>
                    <DefaultText>Sort by:</DefaultText>
                    <Picker style={styles.picker} onValueChange={setSortBy} selectedValue={sortBy}>
                        <Picker.Item key={SortSchoolsBy.Name} label={SortSchoolsBy.Name} value={SortSchoolsBy.Name} />
                        <Picker.Item key={SortSchoolsBy.RenewalDate} label={SortSchoolsBy.RenewalDate} value={SortSchoolsBy.RenewalDate} />
                    </Picker>
                </View>
            </View>
            <InputSearchContent title={searchKeywords} onChangeText={setSearchKeywords} placeholder={`Search schools`} />
        </View>
    )
}

export default AdminSchoolToolbar;

const styles = StyleSheet.create({
    container: {
        width: '100%',
        marginTop: 20,
        paddingHorizontal: 20
    },
    pickerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-end',
    },
    picker: {
        padding: 10,
        borderRadius: 5,
        marginLeft: 5
    },
    row: {
        flexDirection: 'row',
        gap: 20,
        justifyContent: 'flex-end'
    }
})