import { useReactiveVar } from '@apollo/client';
import { AntDesign } from '@expo/vector-icons';
import { useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { DecidaColors } from '../../common/decida-colors';
import { DefaultText } from '../common/default-text';
import ListActiveStudents, { ActiveStudentsType } from '../common/list-active-students';
import SchoolAdminFormStudent from './school-admin-form-student';
import { rvSidebarClassID } from './school-admin-state';
import ListPendingStudents, { PendingStudentsType } from '../common/list-pending-students';


const SchoolAdminSidebarStudentList = () => {

    const [showStudentForm, setShowStudentForm] = useState(false)
    const currentSidebarClassID = useReactiveVar(rvSidebarClassID)

    const toggleAddStudentForm = () => {
        setShowStudentForm(prev => !prev)
    }

    return (
        <View style={styles.container}>
            <DefaultText style={styles.teachersText}>
                Students
            </DefaultText>
            <ListPendingStudents type={PendingStudentsType.SchoolAdminCampus} classID={currentSidebarClassID} />
            <ListActiveStudents type={ActiveStudentsType.SchoolAdminCampus} classID={currentSidebarClassID} />
            <View style={{ zIndex: 99 }}>
                {showStudentForm && (
                    <SchoolAdminFormStudent onFinishSubmit={toggleAddStudentForm} />
                )}
            </View>
            <Pressable onPress={toggleAddStudentForm} style={styles.buttonAddClassContainer}>
                <DefaultText style={styles.text}><AntDesign name="plus" size={20} color={DecidaColors.AppleSystemGray2Dark} /> Add student</DefaultText>
            </Pressable>
        </View>
    )
}
export default SchoolAdminSidebarStudentList



const styles = StyleSheet.create({
    container: {
        marginTop: 40,
    },
    noTeachersText: {
        fontSize: 20,
        fontWeight: 'bold',
        fontStyle: 'italic',
        color: DecidaColors.Blue,
        textAlign: 'center',
        marginTop: 20,
    },
    teachersText: {
        fontSize: 22,
        fontWeight: 'bold',
        color: DecidaColors.AppleSystemGray2Dark,
        marginLeft: 40,
    },
    buttonAddClassContainer: {
        marginVertical: 25,
        paddingLeft: 20,
    },
    text: {
        fontSize: 18,
        color: DecidaColors.GrayText,
    },
})