import { AntDesign } from '@expo/vector-icons'
import _ from 'lodash'
import { PropsWithChildren, useMemo, useState } from 'react'
import { Pressable, ScrollView, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import { IconPlus } from '../icon/icon-plus'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from './default-text'
import InputSearchContent from './input-search-content'
import { Link } from './link'
import { useCalculateExtraItemBasedOnScreenWidth } from './use-calculate-extra-item-based-on-screen-width'

export type ListItem = {
    id: string
    name: string
}

type DisplayType = 'accordion' | 'scroll'

interface Props<T extends ListItem> {
    items: T[]
    edit?: (id: string) => void
    add?: () => void
    title?: string
    itemWidth?: number
    type?: DisplayType
    extraComponent?: (props: T) => JSX.Element
    withSearch?: boolean
    sort?: _.Many<_.ListIteratee<T>>[]
}

const mainContainerPadding = 10

export const List = <T extends ListItem>({
    items,
    edit,
    add,
    title,
    itemWidth = 350,
    type = 'scroll',
    extraComponent,
    withSearch,
    sort
}: Props<T>) => {
    const [searchKeywords, setSearchKeywords] = useState("")
    const [expanded, setExpanded] = useState(true)
    const sortedItems = useMemo(() => _.sortBy(items, sort || (item => item.name)), [items, sort]) as T[]
    const extraItems = useCalculateExtraItemBasedOnScreenWidth(itemWidth, mainContainerPadding, sortedItems.length)

    const itemContainerStyle = [styles.itemContainer, { minWidth: itemWidth, maxWidth: itemWidth }]

    const toggleExpandState = () => {
        setExpanded(prev => !prev)
    }

    let currentItems = sortedItems

    if (searchKeywords) {
        currentItems = sortedItems.filter((item) => item.name.toLowerCase().includes(searchKeywords.toLowerCase()))
    } else {
        currentItems = sortedItems
    }

    return (
        <View style={[styles.mainContainer, type === 'scroll' ? { flex: 1 } : {}]}>
            <View style={styles.titleContainer}>
                {type === 'accordion' &&
                    <Pressable onPress={toggleExpandState}>
                        {
                            expanded ?
                                <AntDesign name="upcircleo" size={24} color="black" />
                                :
                                <AntDesign name="downcircleo" size={24} color="black" />
                        }
                    </Pressable>
                }
                {title && <DefaultText>{title}</DefaultText>}
                {add && <IconPlus onPress={add} />}
            </View>
            <ListContainer type={type} expanded={expanded} >
                {
                    withSearch && <InputSearchContent title={searchKeywords} onChangeText={setSearchKeywords} placeholder={`Search ${title?.toLowerCase()}`} />
                }
                {
                    currentItems?.map(item => {
                        const onEdit = () => edit && edit(item.id)
                        return (
                            <View key={item.id} style={itemContainerStyle}>
                                <TouchableOpacity style={styles.itemBorder} onPress={onEdit}>
                                    <Link onPress={onEdit}>{item.name}</Link>
                                    {extraComponent && extraComponent(item)}
                                </TouchableOpacity>
                            </View>
                        )
                    })
                }
                {
                    extraItems > 0 ? Array(extraItems).fill(0).map((_, index) => <View key={index} style={itemContainerStyle} />) : null
                }
            </ListContainer>
        </View>
    )
}

type ListContainerProps = {
    type: DisplayType,
    expanded: boolean,
}

const ListContainer = ({ type, expanded, children }: PropsWithChildren<ListContainerProps>) => (
    type === 'scroll' ?
        <ScrollView contentContainerStyle={styles.listContainer}>
            {children}
        </ScrollView>
        : expanded ?
            <View style={styles.listContainer}>
                {children}
            </View>
            : null
)

const styles = StyleSheet.create({
    mainContainer: {
        padding: mainContainerPadding,
        maxWidth: '100%',
        minWidth: '100%',
    } as ViewStyle,
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
    },
    listContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
    },
    itemContainer: {
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
    } as ViewStyle,
    itemBorder: {
        width: '100%',
        borderColor: DecidaColors.Gray,
        borderWidth: 1,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
    },
})