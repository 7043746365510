import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { AntDesign, Entypo, Feather } from '@expo/vector-icons'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import _ from 'lodash'
import { ActivityIndicator, StyleSheet, TouchableOpacity, View } from 'react-native'
import { GetStudentClassByClassIdQueryVariables } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { deleteStudentClass, updateStudentClass } from '../../common/graphql/mutations'
import { avatars } from '../icon/avatar/avatars'
import { StudentClassApprovalStatus } from '../models'
import { ProgressiveLoader } from '../switches/common/progressive-loader'
import { ListStudentClassAndStudentDataByClassIdCustomQuery, StudentClassAndStudentDataFieldsTypes, listStudentClassAndStudentDataByClassIdCustomQuery } from '../teacher/teacher-graphql-scripts'
import { ButtonText } from './button-text'
import { rvNetInfo } from './common-state'
import { DefaultFontSize, DefaultSecondaryFontSize } from './const'
import { DefaultButton } from './default-button'
import { DefaultText } from './default-text'
import { rvStudentsFilterKeys } from './dropdown-sort-student'
import { ScreenNames } from './screen-names'
import { showAlert, showOfflineAlert } from './universal-alert'
import { useResponsive } from './use-responsive'

export enum PendingStudentsType {
  Teacher,
  SchoolAdmin,
  SchoolAdminCampus
}

type Props = {
  type: PendingStudentsType
  classID: string
}

const ListPendingStudents = ({ type = PendingStudentsType.SchoolAdmin, classID }: Props) => {
  const navigation = useNavigation<NavigationProp<{ [k: string]: { id: string, classID?: string } }>>()
  const netInfoState = useReactiveVar(rvNetInfo)
  const filterKeys = useReactiveVar(rvStudentsFilterKeys)

  const { windowWidthCondition } = useResponsive()


  const { data: studentClassAndStudentDataResponse, loading: loadingStudentData } = useQuery<ListStudentClassAndStudentDataByClassIdCustomQuery, GetStudentClassByClassIdQueryVariables>(gql`${listStudentClassAndStudentDataByClassIdCustomQuery}`, { variables: { classID, limit: 5000 } })

  const pendingStudentClasses = studentClassAndStudentDataResponse?.getStudentClassByClassId?.items.filter((item) => item?._deleted !== true && item?.status === StudentClassApprovalStatus.PENDING) || []

  const [updateStudentClassMutation] = useMutation(gql`${updateStudentClass}`);
  const [deleteStudentClassMutation] = useMutation(gql`${deleteStudentClass}`);

  const goToEditStudent = (studetClassAndStudentData: StudentClassAndStudentDataFieldsTypes) => {
    if (type === PendingStudentsType.Teacher) {
      navigation.navigate(ScreenNames.TeacherEditStudent, { id: studetClassAndStudentData?.student?.id || "", classID })
    } else {
      navigation.navigate(ScreenNames.SchoolAdminEditStudent, { id: studetClassAndStudentData?.student?.id || "", classID })
    }
  }

  const handleApproveAll = (pendingStudents: StudentClassAndStudentDataFieldsTypes[]) => {
    if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

    Promise.all(pendingStudents
      .map(async (studentClassAndStudentData) => {
        if (studentClassAndStudentData) {
          const { id, _version } = studentClassAndStudentData
          const payloadData = {
            id,
            _version,
            status: StudentClassApprovalStatus.APPROVED
          }
          await updateStudentClassMutation({
            variables: {
              input: payloadData
            }
          })
        }
      }))
  }

  const approveAllStudents = (pendingStudents: StudentClassAndStudentDataFieldsTypes[]) => {
    showAlert({
      title: 'Approve all Students?',
      message: "Are you sure you want to approve all students to this class? Once completed this cannot be undone.",
      leftButtonText: 'Approve',
      rightButtonText: 'Cancel',
      onLeftButtonPress: () => handleApproveAll(pendingStudents)
    })
  }

  const changeStudentClassStatus = (studentClassAndStudentData: StudentClassAndStudentDataFieldsTypes, status: StudentClassApprovalStatus) => {

    if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

    if (studentClassAndStudentData) {

      if (status === StudentClassApprovalStatus.APPROVED) {
        showAlert({
          title: 'Approve Student?',
          message: "Are you sure you want to approve entry to this class?",
          leftButtonText: 'Approve',
          rightButtonText: 'Cancel',
          onLeftButtonPress: async () => {
            const { id, _version } = studentClassAndStudentData
            const payloadData = {
              id,
              _version,
              status
            }
            await updateStudentClassMutation({
              variables: {
                input: payloadData
              }
            })
          }
        })
      }
      else {
        showAlert({
          title: 'Deny Student?',
          message: "Are you sure you want to deny entry to this class?",
          leftButtonText: 'Deny',
          rightButtonText: 'Cancel',
          onLeftButtonPress: () => {
            // Delete Student Class 
            removeStudentClass(studentClassAndStudentData)
          }
        })
      }
    }

    null
  }

  const removeStudentClass = async (studentClassAndStudentData: StudentClassAndStudentDataFieldsTypes) => {
    if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

    if (studentClassAndStudentData) {
      const { id, _version } = studentClassAndStudentData
      const payloadData = {
        id,
        _version
      }
      deleteStudentClassMutation({
        variables: {
          input: payloadData
        }
      })
    }
  }


  let pendingStudentClassesAndStudentData = pendingStudentClasses

  if (filterKeys) {
    pendingStudentClassesAndStudentData = _.sortBy(pendingStudentClasses, filterKeys.map((filterKey) => {
      return (item: StudentClassAndStudentDataFieldsTypes) => item?.student[filterKey]?.toLowerCase()
    }))
  } else {
    pendingStudentClassesAndStudentData = pendingStudentClasses
  }

  if (!pendingStudentClasses?.length) {
    return null
  }


  return (
    <>
      <View style={type === PendingStudentsType.SchoolAdminCampus ? styles.contaierSchoolAdminCampus : styles.container}>
        <View style={styles.row}>
          <DefaultText style={type === PendingStudentsType.Teacher ? styles.pendingStudentsTextTeacher : type === PendingStudentsType.SchoolAdminCampus ? styles.pendingStudentsTextSchoolAdminCampus : styles.pendingStudentsText}>Pending Approval ({pendingStudentClasses?.length.toString()}) </DefaultText>
        </View>
        <DefaultButton onPress={() => approveAllStudents(pendingStudentClassesAndStudentData)} style={type === PendingStudentsType.SchoolAdminCampus ? styles.approveAllButtoSchoolAdminCampus : { alignSelf: type === PendingStudentsType.Teacher ? 'center' : 'flex-start', marginLeft: type === PendingStudentsType.Teacher ? undefined : 20, }}>
          <AntDesign name="checkcircleo" size={24} color={DecidaColors.White} style={styles.iconSpacing} />
          <ButtonText>Approve all</ButtonText>
        </DefaultButton>
        <View style={type === PendingStudentsType.Teacher ? styles.avatarContainerTeacher(windowWidthCondition) : type === PendingStudentsType.SchoolAdminCampus ? styles.avatarContainerSchoolAdminCampus : styles.avatarContainer}>

          {loadingStudentData ? (
            <View style={{ justifyContent: 'center', width: '100%' }}>
              <ActivityIndicator color={DecidaColors.Green} size="large" />
            </View>
          ) : pendingStudentClassesAndStudentData?.length ? (
            <ProgressiveLoader>
              {pendingStudentClassesAndStudentData?.map((studetClassAndStudentData) => {
                const { student } = studetClassAndStudentData || {}
                const Avatar = avatars[student?.avatar as keyof typeof avatars]
                return (
                  <TouchableOpacity key={`${student?.id}`} style={type === PendingStudentsType.Teacher ? styles.studentRow(windowWidthCondition) : styles.avatarButton} onPress={() => goToEditStudent(studetClassAndStudentData)}>
                    <View style={styles.avatar}>
                      <TouchableOpacity style={styles.editButton} onPress={() => goToEditStudent(studetClassAndStudentData)}>
                        <Feather name='edit' size={25} color={DecidaColors.Green} />
                      </TouchableOpacity>
                      <Avatar />
                    </View>
                    <View style={type === PendingStudentsType.Teacher ? styles.textContainerTeacher(windowWidthCondition) : styles.textContainer(windowWidthCondition)}>
                      <DefaultText style={type === PendingStudentsType.Teacher ? styles.nicknameTextTeacher : styles.nicknameText}>{student?.nickname}</DefaultText>
                      <DefaultText style={styles.fullNameText}>{`${student?.firstName} ${student?.lastName}`}</DefaultText>
                    </View>

                    <View style={type === PendingStudentsType.Teacher ? styles.iconContainerTeacher(windowWidthCondition) : styles.iconContainer(windowWidthCondition)}>
                      <TouchableOpacity testID={`button-approve-student-${student?.nickname}`} onPress={() => changeStudentClassStatus(studetClassAndStudentData, StudentClassApprovalStatus.APPROVED)}>
                        <AntDesign name="checkcircleo" size={24} color={DecidaColors.Green} style={styles.iconSpacing} />
                      </TouchableOpacity>
                      <TouchableOpacity testID={`button-decline-student-${student?.nickname}`} onPress={() => changeStudentClassStatus(studetClassAndStudentData, StudentClassApprovalStatus.PENDING)}>
                        <Entypo name="block" size={24} color={DecidaColors.Red} />
                      </TouchableOpacity>
                    </View>
                  </TouchableOpacity>
                )
              })}
            </ProgressiveLoader>
          ) : (
            <DefaultText style={styles.studentText}>
              No student
            </DefaultText>
          )}

        </View>
      </View>
    </>
  )
}

export default ListPendingStudents

const styles = StyleSheet.create<any>({
  container: {
    marginBottom: 10,
  },
  contaierSchoolAdminCampus: {
    alignItems: 'center',
    marginLeft: 20,
    marginTop: 10
  },
  pendingStudentsText: {
    textAlign: 'center',
    alignSelf: 'flex-start',
    fontSize: DefaultFontSize,
    color: DecidaColors.Orange,
    fontWeight: 'bold',
    marginHorizontal: 5,
  },
  pendingStudentsTextTeacher: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: DefaultFontSize,
    color: DecidaColors.Orange,
    fontWeight: 'bold',
    marginHorizontal: 5,
  },
  pendingStudentsTextSchoolAdminCampus: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: DefaultFontSize - 4,
    color: DecidaColors.Orange,
    fontWeight: 'bold',
    marginHorizontal: 5,
  },
  avatar: {
    width: 40,
    height: 40,
  },
  avatarButton: {
    alignItems: 'center',
    margin: 10,
    width: "15%",
    minWidth: 60,
    alignSelf: 'flex-start',
  },
  studentRow: (windowWidthCondition: boolean) => ({
    flexDirection: windowWidthCondition ? 'row' : 'column',
    alignItems: windowWidthCondition ? 'center' : 'center',
    justifyContent: windowWidthCondition ? 'flex-start' : 'center',
    margin: windowWidthCondition ? 5 : 10,
    width: windowWidthCondition ? '100%' : "20%",
    alignSelf: 'flex-start',
  }),
  nicknameText: {
    fontSize: 14,
    color: DecidaColors.Gray,
    fontWeight: '600',
    textAlign: 'center'
  },
  fullNameText: {
    fontSize: 12,
    fontStyle: 'italic',
    color: DecidaColors.AppleSystemGray2Dark,
    textAlign: 'center'
  },
  nicknameTextTeacher: {
    fontSize: DefaultFontSize,
    color: DecidaColors.Black,
    fontWeight: 'bold',
  },
  fullNameTextTeacher: {
    fontSize: DefaultSecondaryFontSize,
    fontStyle: 'italic',
    color: DecidaColors.AppleSystemGray2Dark,
    textAlign: 'center'
  },
  studentText: {
    fontSize: 18,
    color: DecidaColors.AppleSystemGray2Dark,
    fontWeight: '600',
    textAlign: 'center',
    width: '100%'
  },
  editButton: {
    position: 'absolute',
    top: -10,
    left: -10,
    zIndex: 2,
  },
  avatarContainer: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    width: '55%',
    paddingLeft: 20,
    paddingVertical: 20,
    justifyContent: 'flex-start',
    borderWidth: 1,
    borderRadius: 20,
    borderColor: DecidaColors.Orange,
    marginLeft: 20,
    marginTop: 10,
    paddinngRight: 'auto'
  },
  avatarContainerSchoolAdminCampus: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 20,
    paddingVertical: 20,
    justifyContent: 'flex-start',
    borderBottomWidth: 1,
    borderBottomColor: DecidaColors.Orange,
    marginTop: 10,
    paddinngRight: 'auto'
  },
  avatarContainerTeacher: (windowWidthCondition: boolean) => ({
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: windowWidthCondition ? 'column' : 'row',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 20,
    paddingVertical: 20,
    justifyContent: 'flex-start',
    borderWidth: 1,
    borderRadius: 20,
    borderColor: DecidaColors.Orange,
    marginTop: 10,

  }),
  textContainerTeacher: (windowWidthCondition: boolean) => ({
    flexDirection: 'column',
    textAlign: 'center',
    width: windowWidthCondition ? undefined : '100%',
    marginLeft: windowWidthCondition ? 10 : undefined,
    alignItems: windowWidthCondition ? 'flex-start' : 'center'
  }),
  textContainer: (windowWidthCondition: boolean) => ({
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: '100%'
  }),
  iconContainerTeacher: (windowWidthCondition: boolean) => ({
    flex: windowWidthCondition ? 1 : undefined,
    flexDirection: 'row',
    alignItems: 'center',
    right: windowWidthCondition ? 15 : undefined,
    width: '100%',
    justifyContent: windowWidthCondition ? 'flex-end' : 'center'
  }),
  iconContainer: (windowWidthCondition: boolean) => ({
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center'
  }),
  iconSpacing: {
    marginHorizontal: 5
  },
  approveAllButtoSchoolAdminCampus: {
    borderRadius: 30,
    height: 50
  }
})