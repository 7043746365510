import { StyleSheet, View } from 'react-native'
import { AudioHandler } from '../../../common/audio-handler'
import { audio } from '../../../common/audio-imports'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { TenBreathHeader } from './src/ten-breath-header '
import { TenBreathIcon } from './src/ten-breath-icon'
import { TenBreathImage1 } from './src/ten-breath-image1'
import { TenBreathImage2 } from './src/ten-breath-image2'
import { TenBreathImage3 } from './src/ten-breath-image3'
import { TenBreathImage4 } from './src/ten-breath-image4'
import { CommonStyles } from '../../../common/const'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'

export const SwitchesEmotionsAngryTenBreaths: CardTemplate = {
    cardId: "0ee4b8e5-6911-43f7-a6a6-467560fc4ca7",
    title: '10 Breaths',
    Header: TenBreathHeader,
    Icon: TenBreathIcon,
    lessonPlan: true,
    mainContainerStyle: {
        maxWidth: '100%',
    },
    PerfectFor: () => {
        const Step1 = () => {
            return (
                <View style={styles.step1Container}>
                    <DefaultText style={styles.contentText}>Stand up and clasp your hands behind your back.</DefaultText>
                    <View style={styles.imageContainerHalf}>
                        <TenBreathImage1 />
                    </View>
                </View>
            )
        }

        const Step2 = () => {
            return (
                <View style={styles.step1Container}>

                    <DefaultText style={styles.contentText}>Breathe in slowly and deeply through your nose. As </DefaultText>
                    <DefaultText style={styles.contentText}>big as you can.</DefaultText>

                    <DefaultText style={styles.contentText}>Once you've breathed all the way in, count to the number "10" in your head… </DefaultText>
                    <DefaultText style={styles.contentText}>“and then slowly breath all the way out.”</DefaultText>
                    <View style={styles.rowContainer}>
                        <View style={styles.imageContainerHalf}>
                            <TenBreathImage2 />
                        </View>
                    </View>
                </View>
            )
        }

        const Step3 = () => {
            return (
                <View style={styles.step1Container}>
                    <View>
                        <DefaultText style={styles.contentText}>Repeat the process, counting to nine this time. And then with your next breath only count to eight</DefaultText>
                        <DefaultText style={styles.contentText}>and keep on going reducing the number with each breath till you get to one.</DefaultText>
                    </View>
                    <View style={styles.imageContainerHalf}>
                        <TenBreathImage3 />
                    </View>
                </View>
            )
        }

        const Step4 = () => {
            return (
                <View style={styles.step1Container}>
                    <DefaultText style={styles.contentText}>Once you’ve done 10 breaths, release you hands and </DefaultText>
                    <DefaultText style={styles.contentText}>give them a little shake.</DefaultText>

                    <View style={styles.imageContainerHalf}>
                        <TenBreathImage4 />
                    </View>

                    <DefaultText style={styles.contentText}>Enjoy the feeling of calmness that 10 </DefaultText>
                    <DefaultText style={styles.contentText}>breaths can bring.</DefaultText>

                    <View>
                        <WhyDoesItWorkComponent message='According to the MAYO CLINIC, when we are angry our brain gets overwhelmed by stress hormones because it thinks we are under threat. Breathing reduces these stress hormones which calms us down and gets us back in control.' />
                    </View>
                </View>
            )
        }

        const contents = [
            { Component: Step1, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/10-breaths-pg1.mp3' },
            { Component: Step2, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/10-breaths-pg2.mp3' },
            { Component: Step3, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/10-breaths-pg3.mp3' },
            { Component: Step4, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/10-breaths-pg4.mp3' },
        ]

        return (
            <>
                <DefaultTextWithoutArrowsBold textdata={'Perfect for:'} />
                <DefaultTextWithoutArrowsItallic textdata={'Dialling down the intensity of big emotions to regain focus.'} />

                <SwitchCardJuniorContent contents={contents} scheme={Emotion.ANGRY} />
            </>
        )
    },
    Images: undefined,
    CardContent: () => (
        <>
        </>
    )
    ,
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: "",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Explore the concept that controlled breathing can help them calm down when they are feeling angry.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify a simple controlled breathing activity they can practice.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by discussing emotions and how it’s normal to feel angry sometimes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Use The Little Book of Big Emotions, or the slides in the “Introducing Anger” lesson plan found in the Switch App to help explain that anger is a natural emotion and there are healthy ways to cope with it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain to students that one way to calm down when they are feeling angry is through controlled breathing exercises.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Teach students about the concept of breathing deeply and slowly, using the diaphragm muscle to inhale and exhale. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show students how to place one hand on their chest and the other hand on their belly to feel the movement of their diaphragm as they breathe.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Demonstrate the “10 Breaths” switch.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Practice (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students lay down on their yoga mats or large beach towels (or sit comfortably).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play soothing music (link below) or have a calming ambient sound as background to guide them to practice the the 10 Breaths switch.</DefaultText>
                        <View style={styles.lessonPlanCenteredTextContainer}>
                            <AudioHandler audioFile={audio.Peaceful} audioTitle='Peaceful' bgColor={DecidaColors.Sad} />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind them to focus on the movement of their diaphragm and breathe deeply and slowly.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to take a few deep breaths whenever they start to feel angry or upset.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have a class discussion to check on understanding of how breathing can help to control anger.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have a class discussion to check on understanding of how breathing can help to control anger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to take a few deep breaths whenever they start to feel angry or upset.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Relaxing music</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Mats/towels (optional)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Space to sit/lie down</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Little Book of Big Emotions</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Breathing tips to help control your anger -</DefaultText>
                        <DefaultTextWithLink text='https://www.jerichocounselling.com/breathing-tips-help-control-anger/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Belly Breathing: Mindfulness for Children -  </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=RiMb2Bw4Ae8' />

                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Angry]
}

const styles = StyleSheet.create({
    contentText: {
        color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        width: 600,
        height: 500,
        marginVertical: 20,
        marginRight: 40,
        alignSelf: 'center'
    },
    imageContainerHalf: {
        width: '100%',
        height: 300,
        marginVertical: 20,
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        marginHorizontal: 20
    },
    lessonPlanCenteredTextContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center',
        marginVertical: 20,
        width: '90%'
    },
    step1Container: {
        width: '100%',
        paddingHorizontal: 20,
        marginLeft: 5,
    },
})

