import { useReactiveVar } from "@apollo/client"
import { StackScreenProps } from "@react-navigation/stack"
import { ScrollView, StyleSheet, View } from "react-native"
import { DecidaColors } from "../../common/decida-colors"
import { BackButton } from "../common/back-button"
import { rvCurrentClass } from "../common/common-state"
import { DefaultButton } from "../common/default-button"
import { DefaultText } from "../common/default-text"
import { ScreenNames } from "../common/screen-names"
import { AdminClassNavigatorParamList } from "./admin-route-param-types"

export const AdminClassDetails = ({ navigation: { goBack, navigate }, route }: StackScreenProps<AdminClassNavigatorParamList, 'AdminClassDetails'>) => {
    const currentClass = useReactiveVar(rvCurrentClass)

    const navigateToClassStats = () => {
        navigate(ScreenNames.AdminViewClassStats, { classID: route.params.classID || "" })
    }
    const navigateToClassAdmin = () => {
        navigate(ScreenNames.TeacherClassAdmin, { classID: route.params.classID || "" })
    }

    return (
        <ScrollView style={styles.scrollView}>
            <View style={styles.container}>
                <View style={styles.backButtonContainer}>
                    <BackButton />
                </View>
                <View style={styles.details}>
                    <View style={styles.detailBox}>
                        <DefaultText>
                            Classname:
                        </DefaultText>
                        <DefaultText style={styles.data}>
                            {currentClass ? currentClass.name : "noclass"}
                        </DefaultText>
                    </View>
                    <View style={styles.detailBox}>
                        <DefaultText>
                            Number of students:
                        </DefaultText>
                        <DefaultText style={styles.data}>
                            {currentClass?.activeStudents || 0}
                        </DefaultText>
                    </View>
                    <View style={styles.detailBox}>
                        <DefaultText>
                            Class intensity level:
                        </DefaultText>
                        <DefaultText style={styles.data}>
                            {currentClass ? currentClass.emotionIntensityLevel as unknown as string : "noclass"}
                        </DefaultText>
                    </View>

                    <DefaultButton onPress={navigateToClassStats}>
                        View Stats
                    </DefaultButton>
                    <DefaultButton onPress={navigateToClassAdmin}>
                        Manage Class
                    </DefaultButton>
                </View>
                {/* <View>
                    {isUploading ? (
                        <ActivityIndicator color={DecidaColors.Green} size="large" />
                    ) : (
                        <ImportTable data={studentsTable} />
                    )}
                </View> */}
            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    scrollView: {
        flex: 1,
        width: '100%',
        height: '100%'
    },
    backButtonContainer: {

    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        width: '100%',
    },
    details: {
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },
    data: {
        color: DecidaColors.Green,
        paddingHorizontal: 20,
    },
    detailBox: {
        flexDirection: 'row',
        paddingVertical: 5,
    }
})