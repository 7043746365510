import { View } from 'react-native'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { DreamBuilderImage1 } from './src/dream-builder-image1'
import { DreamBuilderIcon } from '../../icons/dream-builder-icon'
import { DefaultTextWithExtraTipIcon } from '../../../common/default-text-with-extra-tip-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { DreamBuilderIconInverted } from '../../icons/dream-builder-icon-inverted'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'

export const SwitchesEmotionsAnxiousDreamBuilder: CardTemplate = {
    cardId: "a0de1eac-d10f-46ba-ad61-dadc4719f7e4",
    title: 'Dream builder',
    Header: DreamBuilderIconInverted,
    Icon: DreamBuilderIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Creative minds who are able to use imagination and escapism to let go of the days troubles."} />
        </>
    ),
    Images: () => (
        <>
            <DreamBuilderImage1 />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"If you’re stuck in a half-awake, half-asleep state, get comfy, close your eyes and try to stay awake instead of falling asleep. This disruption can trick your brain into resetting itself - but it doesn’t work for everyone."} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Make sure your room isn’t too hot or cold. Make it warm and cosy."} />
                <DefaulttextWithRightArrow textdata={"Get comfortable and close your eyes, taking five long, slow, deep breaths."} />
                <DefaulttextWithRightArrow textdata={"Escape into your imagination with an imaginary task, such as building your dream house or exploring a new city, and walk yourself through it in your mind."} />
                <DefaulttextWithRightArrow textdata={"Totally escape into your imagination and don’t be constrained by reality. Maybe you’re building a house: In the future? In medieval times? On another planet? In an exotic location? In the world of your favourite novel?"} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SECRETS_FOR_SLEEP,
    whyDoesItWorkText: '',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Dream+Builder.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.SECRETS_FOR_SLEEP],
    action: [CardActionType.Thought],
    level: []
}

