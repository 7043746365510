import { StyleSheet, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import InfoIconWIthContent from '../../../common/info-icon-with-content'
import { MazeSvg } from '../../../common/maze-svg'
import { useResponsive } from '../../../common/use-responsive'
import VideoCard from '../../../common/video-card'
import { Worksheets } from '../../../common/worksheets-imports'
import { Emotion, Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { CalmingMazeGamesHeader } from './src/calming-maze-games-header'
import { CalmingMazeGamesIcon } from './src/calming-maze-games-icon'
import { CalmingMazeGamesLessonPlan } from './src/calming-maze-games-image-lesson-plan'
import { CalmingMazeGamesImage1 } from './src/calming-maze-games-image1'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'

enum CalmingMazeGamesTab {
    BEGINNER = 1,
    CHALLENGE = 2,
    TRICKY = 3,
    HARD = 4,
    HARDEST = 5
}

const mazeGameVideo1: Videos = {
    id: "1",
    url: "https://player.vimeo.com/video/866209439",
    title: "Maze games video",
    autoplay: true
}

export const SwitchesEmotionsExcitedCalmingMazeGames: CardTemplate = {
    cardId: "753b9209-775f-4350-bddc-fe073e57c3d9",
    title: 'Maze games',
    Header: CalmingMazeGamesHeader,
    Icon: CalmingMazeGamesIcon,
    lessonPlan: true,
    mainContainerStyle: {
        maxWidth: '100%',
        marginLeft: -20,
    },
    audioUrl: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/maze-games-pg1.mp3',
    PerfectFor: () => {
        const { isLargeScreen } = useResponsive()

        const initialStep = () => {
            return (
                <View>

                    <View style={styles.rowContainer}>
                        <DefaultText style={styles.contentText}>Select a maze level.</DefaultText>
                        <DefaultTextWithoutArrowsItallic textdata='' />
                        <DefaultText style={styles.contentText}>Help the hen find her chick!</DefaultText>

                        <View style={styles.imageContainer}>
                            <CalmingMazeGamesImage1 />
                        </View>
                        <View style={styles.whydoesthisworkContainer}>
                            <WhyDoesItWorkComponent message={'Doing puzzles can calm the mind for three main reasons. First, it is a form of mindfulness or meditation, requiring focus and attention to the present moment. This can help to clear the mind and reduce feelings of anxiety or stress.\n\nSecond, puzzles can provide a sense of accomplishment and satisfaction when completed, which can also contribute to a sense of calm and well-being.\n\nThird, working on a puzzle can also help to distract from negative thoughts or worries, and help a person to relax.'} />
                        </View>

                        <View style={styles.infoIconWrapper}>
                            <InfoIconWIthContent
                                Content={() => (
                                    <View style={CommonStyles.flexContainer}>
                                        <VideoCard video={mazeGameVideo1} />
                                    </View>
                                )}
                                iconSize={40}
                                iconColor={CardColorScheme.EXCITED}
                            />
                        </View>
                    </View>
                </View>
            )
        }

        const StepBeginner = () => <MazeSvg width={10} height={10} />

        const StepChallenge = () => <MazeSvg width={15} height={15} />

        const StepTricky = () => <MazeSvg width={20} height={20} />

        const StepHard = () => <MazeSvg width={30} height={30} />

        const StepHardest = () => <MazeSvg width={40} height={40} />

        const contents = [
            { Component: initialStep },
            { Component: StepBeginner, isModal: true },
            { Component: StepChallenge, isModal: true },
            { Component: StepTricky, isModal: true },
            { Component: StepHard, isModal: true },
            { Component: StepHardest, isModal: true },
        ]
        const contentTabs = [
            {
                label: CalmingMazeGamesTab["1"],
                value: CalmingMazeGamesTab.BEGINNER,
            },
            {
                label: CalmingMazeGamesTab["2"],
                value: CalmingMazeGamesTab.CHALLENGE,
            },
            {
                label: CalmingMazeGamesTab["3"],
                value: CalmingMazeGamesTab.TRICKY,
            },
            {
                label: CalmingMazeGamesTab["4"],
                value: CalmingMazeGamesTab.HARD,
            },
            {
                label: CalmingMazeGamesTab["5"],
                value: CalmingMazeGamesTab.HARDEST,
            },
        ]

        return (
            <>
                <DefaultTextWithoutArrowsBold textdata={'Perfect for:'} />
                <DefaultTextWithoutArrowsItallic textdata={'Using a focused and engaging activity to channel excess energy, promote concentration and create a feeling of calm.'} />

                <SwitchCardJuniorContent contents={contents} scheme={Emotion.EXCITED} contentTabs={contentTabs} withInitialSteps />
            </>
        )
    },
    Images: undefined,
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: "",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice using a mase puzzle to regulate emotion to build confidence, mindfulness and problem solving skills.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 25 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by explaining that today, the students will be learning how to do a maze puzzle.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students if they have ever done a maze before, and encourage them to share any experiences they have had with mazes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show the students an example of a simple maze puzzle, possibly the one under the beginner tab in the Maze Game switch, and explain that the goal is to get from the start to the finish by following the path through the maze.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Demonstration (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Choose a volunteer to come to the front of the class and try to solve the maze puzzle while the rest of the class watches.</DefaultText>
                        <View style={styles.rowContainerHalf}>
                            <DefaultText style={CommonStyles.lessonPlanTextSmallItalic}>If you have a smart board this could be done by
                                pulling the make switch up and tracing over
                                different mazes.
                            </DefaultText>
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ As the volunteer solves the puzzle, explain the strategies they are using and encourage the other students to watch and listen carefully.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once the volunteer has successfully completed the maze, ask the other students if they have any questions about the process.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer student to the Mase Games switch or print out the three mases on the Mase Games worksheet (link found in the materials section) and distribute copies to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ With a pencil or pen, encourage the students to work on the puzzles individually, but allow them to ask for help or guidance if needed.</DefaultText>
                        <View style={CommonStyles.lessonPlanImageContainer}>
                            <CalmingMazeGamesLessonPlan />
                        </View>

                        <DefaultText style={CommonStyles.mainLessonText}>• Review and extension (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once all of the students have completed the maze puzzle, ask them to share their strategies with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to discuss any challenges they faced while solving the puzzle and how they overcame them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ For an extension activity, you could introduce a new, more challenging maze puzzle for the students to try, or you could have the students create their own maze puzzles for their classmates to solve.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pens / pencils</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Maze Puzzle Worksheet' link={Worksheets.mazePuzzle.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>There are heaps of free mazes to be found at </DefaultText>
                        <DefaultTextWithLink text='https://krazydad.com/mazes/' />
                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Excited]
}

const styles = StyleSheet.create({
    infoIconWrapper: {
        position: 'absolute',
        right: 20,
    },
    contentText: {
        // color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        flex: 1,
        width: '100%',
        minHeight: 400,
        height: '100%',
        marginVertical: 20,
    },
    whydoesthiswork: {
        width: 100,
        height: 100,
    },
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanTextSmallItalic: {
        textAlign: 'center',
        fontSize: 16,
        fontStyle: 'italic',
        color: DecidaColors.AppleSystemGray2Dark,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray
    },
    whydoesthisworkContainer: {
        justifyContent: 'flex-start',

    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        marginHorizontal: 20,
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        alignSelf: 'center',
        marginVertical: 10
    },
})

