import { Text, View } from 'react-native'
import { DefaultText } from '../../../common/default-text'
import SwitchCardVideo from '../../../common/switch-card-video'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { CountDownIcon } from '../../explore/src/countdown-icon'
import { CountdownIconInverted } from '../../explore/src/countdown-icon-inverted'
import { CountDownImage1 } from './src/countdown-image1'
import { CountDownLessonPlanImage1 } from './src/countdown-lessonplan-image'
import { DecidaColors } from '../../../../common/decida-colors'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { Worksheets } from '../../../common/worksheets-imports'

const countdownVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/o1CxKPrK5GY",
    title: ""
}

export const SwitchesEmotionsScaredCountDown: CardTemplate = {
    cardId: "2e9663f8-de69-4ca3-a76b-c7728fe072b7",
    title: 'Count down',
    lessonPlan: true,
    Header: CountdownIconInverted,
    Icon: CountDownIcon,
    Images: () => (
        <>
            <CountDownImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"When your fears are stopping you doing something you should do."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Take a moment to pause before doing a task or activity that is scaring you, for example a difficult conversation, public performance, or walking in the dark."} />
                <DefaultTextWithDownArrow textdata={"Imagine yourself doing the task with confidence and success. "} />
                <DefaultTextWithDownArrow textdata={"Now, embrace that success and commit to getting going after you count down."} />
                <DefaultTextWithDownArrow textdata={"Count down “5-4-3-2-1-GO!”"} />
                <DefaultTextWithoutArrows textdata={"Set off with energy and do it."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: 'We often believe that we are inadequate and the world is too difficult and demanding. Visualising success helps us see outcomes that we do not fear, and counting down helps us break through irrational beliefs and just get doing. It’s in the doing we find meaning, so just do it.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify a fear that is, or could in the future cause procrastination.
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Visualise overcoming the fear with confidence to ignite action.
                        </DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reference the Count Down switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the introductory video: "Have Courage and Be Fearless." <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Have Courage and Be Fearless (youtube.com)' link='https://www.youtube.com/watch?v=o1CxKPrK5GY' /></DefaultText>
                        <SwitchCardVideo video={countdownVideo} />
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss briefly with the students what courage means to them.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Drawing Courage (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Being Courageous worksheet and drawing materials.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to draw something that requires courage and write about why courage is needed for that activity (e.g., public speaking, making a new friend, winning at sports).</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Guided Visualisation (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that visualisation is a way to use imagination to experience courage in a safe way.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to sit comfortably, close their eyes, and imagine themselves being courageous, doing the thing they fear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Guide them to visualise being confident, doing a great job, and feeling the sense of accomplishment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide a quiet space and optionally play uplifting instrumental background music.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After a couple of minutes, invite students to open their eyes and readjust.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Reflecting on Courage (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to draw and write on the worksheet what being brave looks like for them after the visualisation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to take a deep breath and count down together: "5   4   3   2   1   I can do it!"</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Debrief and Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Engage in a discussion about how the exercise made students feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explore the idea that having self confidence is crucial for being courageous in the face of fear and procrastination.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points about courage, self confidence, and visualisation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students are using the Switch4Schools app independently, direct students to explore the relevant switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to apply the visualisation technique when facing challenges.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Being Courageous worksheet' link={Worksheets.beingCouregous.uri} /> </DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Procrastination: An emotional struggle -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://ct.counseling.org/2019/10/procrastination-an-emotional-struggle/' link='https://ct.counseling.org/2019/10/procrastination-an-emotional-struggle/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Visualisation Can Improve Mood, Support Mental Health -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.goodtherapy.org/blog/visualization-can-improve-mood-support-mental-health-0323171#:~:text=Self%2Dguided%20positive%20visualization%20techniques,journal%20Frontiers%20in%20Human%20Neuroscience.' link='https://www.goodtherapy.org/blog/visualization-can-improve-mood-support-mental-health-0323171#:~:text=Self%2Dguided%20positive%20visualization%20techniques,journal%20Frontiers%20in%20Human%20Neuroscience.' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Have Courage and Be Fearless -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.youtube.com/watch?v=o1CxKPrK5GY' link='https://www.youtube.com/watch?v=o1CxKPrK5GY' />

                    </>}
            />

        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Sometimes it can be good to explore why the activity is hard. It may not be simply because you don’t want to do it, or it’s hard. This ‘why’ isn’t always helpful though, as people can make up stories to justify inaction.' />
            <DefaulTextWithTipsIcon textdata='Try counting out loud, or physically counting down on your fingers to help motivate you to get going.' />
            <DefaulTextWithTipsIcon textdata='Be careful to avoid any feelings of guilt and shame attached to procrastination.' />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Count+Down.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Scared]
}
