import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { ClassGoModeIcon } from '../common/svg-files/class-go-mode-icon'
import { ClassNormalModeIcon } from '../common/svg-files/class-normal-mode-icon'
import { ClazzType } from './school-admin-types'



type Props = {
    clazzType?: ClazzType
    size?: number
}

const SchoolAdminClassIcon = ({ clazzType, size = 14 }: Props) => {

    return (
        <View style={{ width: size, height: size, backgroundColor: !clazzType ? DecidaColors.AppleSystemGray2Dark : DecidaColors.White, marginRight: 5 }}>
            {clazzType === ClazzType.ClassMode ? (
                <ClassNormalModeIcon />
            ) : clazzType === ClazzType.GoMode ? (
                <ClassGoModeIcon />
            ) : null}
        </View>
    )
}

export default SchoolAdminClassIcon
const styles = StyleSheet.create({})