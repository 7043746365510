import { View } from 'react-native'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { ShakeDownIcon } from '../../icons/shake-down-icon'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { ShakeDownIconInverted } from '../../icons/shake-down-icon-inverted'

export const SwitchesActivitiesEnergizersShakeDown: CardTemplate = {
    cardId: "729eab52-5c9b-4971-a300-0797bcc8da26",
    title: 'Shake down',
    Header: ShakeDownIconInverted,
    Icon: ShakeDownIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Quick exercise to either wake up different part of the body, or release energy and tension."} />
            <DefaultTextWithoutArrowsItallic textdata={"In this short and very physical energizer, the group shakes out their bodies one limb at a time. This energizer is short and efficient. For it to be effective, the person leading it needs to role model a high level of energy"} />
            <DefaulttextWithRightArrow textdata={"Everyone stands in a circle with at least an arm’s length of space on either side of them."} />
            <DefaulttextWithRightArrow textdata={"The group performs the “shake down” all together – 8 seconds to shake the right arm, 8 seconds to shake the left arm, 8 seconds to shake the right leg, and 8 seconds to shake the left leg. Each time, counting “1 - 2 - 3 - 4 - 5 - 6 - 7 - 8!”"} />
            <DefaulttextWithRightArrow textdata={"Next, 4 shakes of each limb. Then 2 shakes of each. Then 1 shake of each."} />
            <DefaulttextWithRightArrow textdata={"The shake down ends with a loud cheer."} />

        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    onlineTips: 'A simple activity to run online - so long as you have everyone on webcam and present in your video conferencing tool, this one is run in very much the same way as a live workshop. Ensure everyone has room to do this exercise beforehand and communicate this to your participants. Participants may stand or sit depending on their location and whether they’re comfortable!',
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.ENERGIZERS,
    whyDoesItWorkText: '',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Shakedown.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.ENERGIZERS],
    action: [CardActionType.Action],
    level: []
}

