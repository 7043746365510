
import { Platform } from 'react-native'
import Svg, { Rect, G, Path, Circle, Line, Text, TSpan } from 'react-native-svg'
import { Emotion } from '../../models'

interface Props {
    onPress: (emotion: Emotion) => void
}

export const EmotionCircle7Point = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressHappy = {
        [prop]: () => onPress(Emotion.HAPPY)
    }
    const onPressExcited = {
        [prop]: () => onPress(Emotion.EXCITED)
    }
    const onPressScared = {
        [prop]: () => onPress(Emotion.SCARED)
    }
    const onPressSad = {
        [prop]: () => onPress(Emotion.SAD)
    }
    const onPressAngry = {
        [prop]: () => onPress(Emotion.ANGRY)
    }
    const onPressAnxious = {
        [prop]: () => onPress(Emotion.ANXIOUS)
    }

    return (
        <Svg
            viewBox='0 0 442.285 502.504'
        >
            <G data-name='Group 9562'>
                <G data-name='Group sad' {...onPressSad}>
                    <Path
                        data-name='Path 7263'
                        d='M0 0l103.79-182.469 100.969 171.9S102.71 55.483 0 0'
                        transform='translate(-2618 -129.504) translate(2735.375 558.27)'
                        fill='#d6e6f3'
                    />
                    <Path
                        data-name='Path 7269'
                        d='M0 0l89.478-157.308 87.046 148.2S88.547 47.832 0 0'
                        transform='translate(-2618 -129.504) translate(2749.943 533.234)'
                        fill='#b8d4eb'
                    />
                    <Path
                        data-name='Path 7275'
                        d='M0 0l75.487-132.71L148.922-7.686S74.7 40.353 0 0'
                        transform='translate(-2618 -129.504) translate(2763.903 508.761)'
                        fill='#9ac2e2'
                    />
                    <Path
                        data-name='Path 7281'
                        d='M0 0l61.919-108.858L122.155-6.3S61.275 33.1 0 0'
                        transform='translate(-2618 -129.504) translate(2777.441 485.027)'
                        fill='#86b6dd'
                    />
                    <Path
                        data-name='Path 7287'
                        d='M0 0l48.808-85.808L96.29-4.97S48.3 26.091 0 0'
                        transform='translate(-2618 -129.504) translate(2790.523 462.094)'
                        fill='#72aad7'
                    />
                    <Path
                        data-name='Path 7293'
                        d='M0 0l35.53-62.463L70.094-3.618S35.16 18.993 0 0'
                        transform='translate(-2618 -129.504) translate(2803.773 438.866)'
                        fill='#5498cf'
                    />
                    <Path
                        data-name='Path 7299'
                        d='M0 0l22.5-39.557L44.389-2.291S22.266 12.028 0 0'
                        transform='translate(-2618 -129.504) translate(2816.773 416.075)'
                        fill='#3685c6'
                    />
                    <Text
                        transform='translate(-2618 -129.504) translate(2822.115 415.068)'
                        fill='#fff'
                        fontSize={9}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Despair'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2809.836 564.748)'
                        fill='#42454a'
                        fontSize={9}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Disappointed'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2818.906 463.676)'
                        fill='#42454a'
                        fontSize={9}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Hopeless'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2820.629 439.051)'
                        fill='#fff'
                        fontSize={9}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Grieving'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2811.521 541.491)'
                        fill='#42454a'
                        fontSize={9}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Discouraged'}
                        </TSpan>
                    </Text>
                    <Path
                        data-name='Path 7358'
                        d='M0 0a20.021 20.021 0 0020.021-20.021A20.021 20.021 0 000-40.042a20.021 20.021 0 00-20.021 20.021A20.021 20.021 0 000 0'
                        transform='translate(-2618 -129.504) translate(2839.225 616.55)'
                        fill='#fff'
                    />
                    <Path
                        data-name='Path 7359'
                        d='M2839.225 616.55a20.021 20.021 0 0020.021-20.021 20.021 20.021 0 00-20.021-20.021 20.021 20.021 0 00-20.021 20.021 20.021 20.021 0 0020.021 20.021z'
                        fill='none'
                        stroke='#3685c6'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7360'
                        d='M0 0a3.151 3.151 0 003.151-3.151A3.151 3.151 0 000-6.3a3.151 3.151 0 00-3.151 3.149A3.151 3.151 0 000 0'
                        transform='translate(-2618 -129.504) translate(2830.238 597.758)'
                        fill='#3685c6'
                    />
                    <Path
                        data-name='Path 7361'
                        d='M0 0a3.151 3.151 0 003.151-3.151A3.151 3.151 0 000-6.3a3.151 3.151 0 00-3.151 3.149A3.151 3.151 0 000 0'
                        transform='translate(-2618 -129.504) translate(2845.984 597.758)'
                        fill='#3685c6'
                    />
                    <Path
                        data-name='Path 7362'
                        d='M2828.474 607.505s8.047-10.195 19.1-.237'
                        fill='none'
                        stroke='#3685c6'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7363'
                        d='M0 0s-.585 2.377 1.884 1.514'
                        transform='translate(-2618 -129.504) translate(2827.539 606.735)'
                        fill='#3685c6'
                    />
                    <Path
                        data-name='Path 7365'
                        d='M0 0a4.8 4.8 0 00-4.8 4.8A4.8 4.8 0 000 9.592h37.59a4.8 4.8 0 100-9.592z'
                        transform='translate(-2618 -129.504) translate(2820.43 617.418)'
                        fill='#fff'
                    />
                    <Text
                        transform='translate(-2618 -129.504) translate(2830.872 624.907)'
                        fill='#3685c6'
                        fontSize={7}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                        letterSpacing='0.033em'
                    >
                        <TSpan x={0} y={0}>
                            {'SAD'}
                        </TSpan>
                    </Text>
                    <Path
                        data-name='Path 7367'
                        d='M2820.43 617.418a4.8 4.8 0 00-4.8 4.8 4.8 4.8 0 004.8 4.792h37.59a4.8 4.8 0 100-9.592z'
                        fill='none'
                        stroke='#3685c6'
                        strokeWidth={1.447}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7368'
                        d='M0 0a5.789 5.789 0 00-5.789 5.789v2.862A5.789 5.789 0 000 14.44h35.6a5.789 5.789 0 005.789-5.789V5.789A5.789 5.789 0 0035.6 0z'
                        transform='translate(-2618 -129.504) translate(2821.423 616.845)'
                        fill='#fff'
                    />
                    <Path
                        data-name='Path 7369'
                        d='M2821.423 616.845a5.789 5.789 0 00-5.789 5.789v2.862a5.789 5.789 0 005.789 5.789h35.6a5.789 5.789 0 005.789-5.789v-2.862a5.789 5.789 0 00-5.789-5.789z'
                        fill='none'
                        stroke='#3685c6'
                        strokeWidth={1.447}
                        transform='translate(-2618 -129.504)'
                    />
                    <Text
                        data-name='melancholy | down'
                        transform='translate(-2618 -129.504) translate(2819.688 629.083)'
                        fill='#3685c6'
                        fontSize={5}
                        fontFamily='Lato-Regular, Lato'
                    >
                        <TSpan x={0} y={0}>
                            {'melancholy | down'}
                        </TSpan>
                    </Text>
                    <Text
                        data-name='SAD'
                        transform='translate(-2618 -129.504) translate(2830.525 624.094)'
                        fill='#3685c6'
                        fontSize={7}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                        letterSpacing='0.033em'
                    >
                        <TSpan x={0} y={0}>
                            {'SAD'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2815.641 513.498)'
                        fill='#42454a'
                        fontSize={9}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'V'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2821.908 513.498)'
                        fill='#42454a'
                        fontSize={9}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'ulnerable'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2819.689 487.996)'
                        fill='#42454a'
                        fontSize={9}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Dejected'}
                        </TSpan>
                    </Text>
                </G>
                <G data-name='Group angry' {...onPressAngry}>
                    <Path
                        data-name='Path 7264'
                        d='M0 0l-106.128-181.119 199.356-1.492S99.4-61.208 0 0'
                        transform='translate(-2618 -129.504) translate(2945.068 558.411)'
                        fill='#f9d2d3'
                    />
                    <Path
                        data-name='Path 7270'
                        d='M0 0l-91.493-156.144 171.865-1.286S85.7-52.768 0 0'
                        transform='translate(-2618 -129.504) translate(2930.721 533.356)'
                        fill='#f5b0b3'
                    />
                    <Path
                        data-name='Path 7276'
                        d='M0 0l-77.187-131.729 144.992-1.084S72.3-44.517 0 0'
                        transform='translate(-2618 -129.504) translate(2916.414 508.863)'
                        fill='#f18e92'
                    />
                    <Path
                        data-name='Path 7282'
                        d='M0 0l-63.314-108.052 118.932-.89S59.3-36.515 0 0'
                        transform='translate(-2618 -129.504) translate(2902.541 485.112)'
                        fill='#ee787c'
                    />
                    <Path
                        data-name='Path 7288'
                        d='M0 0l-49.908-85.173 93.749-.7S46.746-28.784 0 0'
                        transform='translate(-2618 -129.504) translate(2889.134 462.16)'
                        fill='#ec6167'
                    />
                    <Path
                        data-name='Path 7294'
                        d='M0 0l-36.33-62 68.244-.511S34.028-20.953 0 0'
                        transform='translate(-2618 -129.504) translate(2875.556 438.915)'
                        fill='#e83f46'
                    />
                    <Path
                        data-name='Path 7300'
                        d='M0 0l-23.007-39.264 43.217-.324S21.55-13.269 0 0'
                        transform='translate(-2618 -129.504) translate(2862.232 416.105)'
                        fill='#e31e25'
                    />
                    <Text
                        transform='translate(-2618 -129.504) translate(2848.545 391.021)'
                        fill='#fff'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Seething'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2934.614 458.766)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Irritated'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2894.585 424.96)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Fuming'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2874.61 407.956)'
                        fill='#fff'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Furious'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2908.359 441.819)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Frustrated'}
                        </TSpan>
                    </Text>
                    <Path
                        data-name='Path 7337'
                        d='M0 0a20.021 20.021 0 0020.021-20.021A20.021 20.021 0 000-40.042a20.021 20.021 0 00-20.021 20.021A20.021 20.021 0 000 0'
                        transform='translate(-2618 -129.504) translate(3035.971 466.577)'
                        fill='#fff'
                    />
                    <Path
                        data-name='Path 7338'
                        d='M3035.971 466.577a20.021 20.021 0 0020.021-20.021 20.021 20.021 0 00-20.021-20.021 20.021 20.021 0 00-20.021 20.021 20.021 20.021 0 0020.021 20.021z'
                        fill='none'
                        stroke='#e31e25'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7339'
                        d='M0 0a3.151 3.151 0 003.151-3.151A3.151 3.151 0 000-6.3a3.151 3.151 0 00-3.151 3.149A3.151 3.151 0 000 0'
                        transform='translate(-2618 -129.504) translate(3026.502 444.891)'
                        fill='#ff2d16'
                    />
                    <Path
                        data-name='Path 7340'
                        d='M0 0a3.151 3.151 0 003.151-3.151A3.151 3.151 0 000-6.3a3.151 3.151 0 00-3.151 3.149A3.151 3.151 0 000 0'
                        transform='translate(-2618 -129.504) translate(3042.248 444.891)'
                        fill='#ff2d16'
                    />
                    <Path
                        data-name='Path 7341'
                        d='M3024.737 454.638s8.047-10.195 19.1-.237'
                        fill='none'
                        stroke='#ff2d16'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7342'
                        d='M0 0s-.585 2.377 1.884 1.514'
                        transform='translate(-2618 -129.504) translate(3023.803 453.867)'
                        fill='#ff2d16'
                    />
                    <Path
                        data-name='Path 7343'
                        d='M0 0s2.343.709 1.61-1.8'
                        transform='translate(-2618 -129.504) translate(3043.031 455.293)'
                        fill='#ff2d16'
                    />
                    <Path
                        data-name='Path 7345'
                        d='M3023.001 434.64l7 2.895'
                        fill='none'
                        stroke='#ff2d16'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7346'
                        d='M0 0s-2.445.114-.916 2.236'
                        transform='translate(-2618 -129.504) translate(3023.471 433.525)'
                        fill='#ff2d16'
                    />
                    <Path
                        data-name='Path 7347'
                        d='M0 0s2.445-.114.916-2.236'
                        transform='translate(-2618 -129.504) translate(3029.543 438.648)'
                        fill='#ff2d16'
                    />
                    <Path
                        data-name='Path 7349'
                        d='M3038.742 437.534l7-2.895'
                        fill='none'
                        stroke='#ff2d16'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7350'
                        d='M0 0s-2.445-.114-.916-2.236'
                        transform='translate(-2618 -129.504) translate(3039.213 438.648)'
                        fill='#ff2d16'
                    />
                    <Path
                        data-name='Path 7351'
                        d='M0 0s2.445.114.916 2.236'
                        transform='translate(-2618 -129.504) translate(3045.284 433.525)'
                        fill='#ff2d16'
                    />
                    <Path
                        data-name='Path 7352'
                        d='M0 0a4.8 4.8 0 00-4.8 4.8A4.8 4.8 0 000 9.592h37.59a4.8 4.8 0 100-9.592z'
                        transform='translate(-2618 -129.504) translate(3017.176 467.445)'
                        fill='#fff'
                    />
                    <Text
                        transform='translate(-2618 -129.504) translate(3022.245 474.935)'
                        fill='#e31e25'
                        fontSize={7}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                        letterSpacing='0.04em'
                    >
                        <TSpan x={0} y={0}>
                            {'ANGRY'}
                        </TSpan>
                    </Text>
                    <Path
                        data-name='Path 7354'
                        d='M3017.176 467.445a4.8 4.8 0 00-4.8 4.8 4.8 4.8 0 004.8 4.792h37.59a4.8 4.8 0 100-9.592z'
                        fill='none'
                        stroke='#e31e25'
                        strokeWidth={1.447}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7355'
                        d='M0 0a5.789 5.789 0 00-5.789 5.789v2.862A5.789 5.789 0 000 14.44h35.6a5.789 5.789 0 005.789-5.789V5.789A5.789 5.789 0 0035.6 0z'
                        transform='translate(-2618 -129.504) translate(3017.317 467.646)'
                        fill='#fff'
                    />
                    <Path
                        data-name='Path 7356'
                        d='M3017.317 467.646a5.789 5.789 0 00-5.789 5.789v2.862a5.789 5.789 0 005.789 5.789h35.6a5.789 5.789 0 005.789-5.789v-2.862a5.789 5.789 0 00-5.789-5.789z'
                        fill='none'
                        stroke='#e31e25'
                        strokeWidth={1.447}
                        transform='translate(-2618 -129.504)'
                    />
                    <Text
                        data-name='defensive | enraged'
                        transform='translate(-2618 -129.504) translate(3014.491 479.885)'
                        fill='#e31e25'
                        fontSize={5}
                        fontFamily='Lato-Regular, Lato'
                    >
                        <TSpan x={0} y={0}>
                            {'defensive | enraged'}
                        </TSpan>
                    </Text>
                    <Text
                        data-name='ANGRY'
                        transform='translate(-2618 -129.504) translate(3021.046 474.896)'
                        fill='#e31e25'
                        fontSize={7}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                        letterSpacing='0.04em'
                    >
                        <TSpan x={0} y={0}>
                            {'ANGRY'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2951.893 475.69)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Annoyed'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2973.86 492.627)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Indignant'}
                        </TSpan>
                    </Text>
                </G>
                <G data-name='Group anxious' {...onPressAnxious}>
                    <Path
                        data-name='Path 7268'
                        d='M0 0l-209.914 1.9 97.932-173.65S-3.611-116.682 0 0'
                        transform='translate(-2618 -129.504) translate(3048.697 375.733)'
                        fill='#f3eed5'
                    />
                    <Path
                        data-name='Path 7274'
                        d='M0 0l-180.968 1.638 84.428-149.705S-3.113-100.592 0 0'
                        transform='translate(-2618 -129.504) translate(3020.061 375.869)'
                        fill='#eae2b6'
                    />
                    <Path
                        data-name='Path 7280'
                        d='M0 0l-152.671 1.381 71.226-126.3S-2.626-84.863 0 0'
                        transform='translate(-2618 -129.504) translate(2991.785 376.002)'
                        fill='#e0d596'
                    />
                    <Path
                        data-name='Path 7286'
                        d='M0 0l-125.23 1.133 58.424-103.6S-2.154-69.61 0 0'
                        transform='translate(-2618 -129.504) translate(2964.364 376.13)'
                        fill='#dbcd82'
                    />
                    <Path
                        data-name='Path 7292'
                        d='M0 0l-98.714.893 46.053-81.66S-1.7-54.871 0 0'
                        transform='translate(-2618 -129.504) translate(2937.867 376.254)'
                        fill='#d4c56d'
                    />
                    <Path
                        data-name='Path 7298'
                        d='M0 0l-71.858.65 33.524-59.444S-1.236-39.943 0 0'
                        transform='translate(-2618 -129.504) translate(2911.031 376.38)'
                        fill='#ccb94e'
                    />
                    <Path
                        data-name='Path 7304'
                        d='M0 0l-45.507.412 21.231-37.645S-.783-25.3 0 0'
                        transform='translate(-2618 -129.504) translate(2884.698 376.504)'
                        fill='#c3ac2e'
                    />
                    <Text
                        transform='translate(-2618 -129.504) translate(2863.146 353.89)'
                        fill='#fff'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Over'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2884.504 353.89)'
                        fill='#fff'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'whelmed'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2847.963 371.151)'
                        fill='#fff'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Panicked'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2922.6 302.11)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Concerned'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2898.563 319.366)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Apprehensive'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2890.872 336.626)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'W'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2900.898 336.626)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'orried'}
                        </TSpan>
                    </Text>
                    <Path
                        data-name='Path 7306'
                        d='M0 0a5.789 5.789 0 00-5.789 5.789v2.862A5.789 5.789 0 000 14.44h35.6a5.789 5.789 0 005.789-5.789V5.789A5.789 5.789 0 0035.6 0z'
                        transform='translate(-2618 -129.504) translate(3018.169 323.36)'
                        fill='#fff'
                    />
                    <Path
                        data-name='Path 7307'
                        d='M3018.169 323.36a5.789 5.789 0 00-5.789 5.789v2.862a5.789 5.789 0 005.789 5.789h35.6a5.789 5.789 0 005.789-5.789v-2.862a5.789 5.789 0 00-5.789-5.789z'
                        fill='none'
                        stroke='#c3ac2e'
                        strokeWidth={1.447}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7308'
                        d='M0 0a20.021 20.021 0 0020.021-20.021A20.021 20.021 0 000-40.042a20.021 20.021 0 00-20.021 20.021A20.021 20.021 0 000 0'
                        transform='translate(-2618 -129.504) translate(3035.971 322.202)'
                        fill='#fff'
                    />
                    <Path
                        data-name='Path 7309'
                        d='M3035.971 322.202a20.021 20.021 0 0020.021-20.021 20.021 20.021 0 00-20.021-20.021 20.021 20.021 0 00-20.021 20.021 20.021 20.021 0 0020.021 20.021z'
                        fill='none'
                        stroke='#c3ac2e'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7310'
                        d='M0 0a2.836 2.836 0 002.836-2.836A2.836 2.836 0 000-5.672a2.836 2.836 0 00-2.836 2.836A2.836 2.836 0 000 0'
                        transform='translate(-2618 -129.504) translate(3028.264 300.851)'
                        fill='#c3ac2e'
                    />
                    <Path
                        data-name='Path 7311'
                        d='M0 0a2.836 2.836 0 002.836-2.836A2.836 2.836 0 000-5.672a2.836 2.836 0 00-2.836 2.836A2.836 2.836 0 000 0'
                        transform='translate(-2618 -129.504) translate(3044.011 300.851)'
                        fill='#c3ac2e'
                    />
                    <Path
                        data-name='Path 7312'
                        d='M3032.034 307.237a2.837 2.837 0 00-2.837 2.837 2.837 2.837 0 002.837 2.836h7.873a2.837 2.837 0 100-5.673z'
                        fill='none'
                        stroke='#c3ac2e'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Text
                        data-name='stressed | tense'
                        transform='translate(-2618 -129.504) translate(3019.384 335.599)'
                        fill='#c3ac2e'
                        fontSize={5}
                        fontFamily='Lato-Regular, Lato'
                    >
                        <TSpan x={0} y={0}>
                            {'stressed | tense'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(3018.627 330.609)'
                        fill='#c3ac2e'
                        fontSize={7}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                        letterSpacing='0.04em'
                    >
                        <TSpan x={0} y={0}>
                            {'ANXIOUS'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2970.963 267.586)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Attentive'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2947.794 284.846)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Focussed'}
                        </TSpan>
                    </Text>
                </G>
                <G data-name='Group scared' {...onPressScared}>
                    <Path
                        data-name='Path 7265'
                        d='M0 0l209.914-1.9-97.932 173.65S3.611 116.682 0 0'
                        transform='translate(-2618 -129.504) translate(2629.176 377.699)'
                        fill='#f4e6d6'
                    />
                    <Path
                        data-name='Path 7271'
                        d='M0 0l180.968-1.638L96.54 148.067S3.113 100.592 0 0'
                        transform='translate(-2618 -129.504) translate(2658.389 377.564)'
                        fill='#edd3b8'
                    />
                    <Path
                        data-name='Path 7277'
                        d='M0 0l152.671-1.381-71.226 126.3S2.626 84.863 0 0'
                        transform='translate(-2618 -129.504) translate(2686.664 377.432)'
                        fill='#e5c09a'
                    />
                    <Path
                        data-name='Path 7283'
                        d='M0 0l125.23-1.133-58.424 103.6S2.154 69.61 0 0'
                        transform='translate(-2618 -129.504) translate(2714.085 377.303)'
                        fill='#e0b486'
                    />
                    <Path
                        data-name='Path 7289'
                        d='M0 0l98.714-.893-46.053 81.66S1.7 54.871 0 0'
                        transform='translate(-2618 -129.504) translate(2740.582 377.179)'
                        fill='#dba772'
                    />
                    <Path
                        data-name='Path 7295'
                        d='M0 0l71.858-.65-33.524 59.444S1.236 39.943 0 0'
                        transform='translate(-2618 -129.504) translate(2767.419 377.053)'
                        fill='#d39454'
                    />
                    <Path
                        data-name='Path 7301'
                        d='M0 0l45.507-.412-21.231 37.645S.783 25.3 0 0'
                        transform='translate(-2618 -129.504) translate(2793.752 376.93)'
                        fill='#cc8236'
                    />
                    <Text
                        transform='translate(-2618 -129.504) translate(2792.703 390.335)'
                        fill='#fff'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'T'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2797.803 390.335)'
                        fill='#fff'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'errified'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2730.896 441.479)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Alarmed'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2776.264 407.388)'
                        fill='#fff'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Afraid'}
                        </TSpan>
                    </Text>
                    <Text
                        data-name='\xA92020 Decida Group Pty. Ltd'
                        transform='translate(-2618 -129.504) translate(2657.934 383.613)'
                        fill='#42454a'
                        fontSize={4}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'\xA92020 Decida Group Pty. Ltd'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2711.75 458.525)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Ner'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2727.867 458.525)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'vous'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2743.192 424.434)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Threatened'}
                        </TSpan>
                    </Text>
                    <Path
                        data-name='Path 7314'
                        d='M0 0a20.021 20.021 0 0020.021-20.021A20.021 20.021 0 000-40.042a20.021 20.021 0 00-20.021 20.021A20.021 20.021 0 000 0'
                        transform='translate(-2618 -129.504) translate(2642.314 466.348)'
                        fill='#fff'
                    />
                    <Path
                        data-name='Path 7315'
                        d='M2642.314 466.348a20.021 20.021 0 0020.021-20.021 20.021 20.021 0 00-20.021-20.021 20.021 20.021 0 00-20.021 20.021 20.021 20.021 0 0020.021 20.021z'
                        fill='none'
                        stroke='#cc8236'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7316'
                        d='M0 0a3.151 3.151 0 003.151-3.151A3.151 3.151 0 000-6.3a3.151 3.151 0 00-3.151 3.149A3.151 3.151 0 000 0'
                        transform='translate(-2618 -129.504) translate(2636.223 444.179)'
                        fill='#cc8236'
                    />
                    <Path
                        data-name='Path 7317'
                        d='M0 0a3.151 3.151 0 003.151-3.151A3.151 3.151 0 000-6.3a3.151 3.151 0 00-3.151 3.149A3.151 3.151 0 000 0'
                        transform='translate(-2618 -129.504) translate(2651.969 444.179)'
                        fill='#cc8236'
                    />
                    <Path
                        data-name='Path 7318'
                        d='M2638.741 435.072a22.115 22.115 0 01-8.654 5.051'
                        fill='none'
                        stroke='#cc8236'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7319'
                        d='M0 0s-1.756 1.706.8 2.282'
                        transform='translate(-2618 -129.504) translate(2629.705 438.984)'
                        fill='#cc8236'
                    />
                    <Path
                        data-name='Path 7320'
                        d='M0 0s.75-2.33-1.774-1.641'
                        transform='translate(-2618 -129.504) translate(2639.619 435.882)'
                        fill='#cc8236'
                    />
                    <Path
                        data-name='Path 7321'
                        d='M2648.943 435.072a22.115 22.115 0 008.654 5.051'
                        fill='none'
                        stroke='#cc8236'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7322'
                        d='M0 0s1.756 1.706-.8 2.282'
                        transform='translate(-2618 -129.504) translate(2657.979 438.984)'
                        fill='#cc8236'
                    />
                    <Path
                        data-name='Path 7323'
                        d='M0 0s-.75-2.33 1.774-1.641'
                        transform='translate(-2618 -129.504) translate(2648.064 435.882)'
                        fill='#cc8236'
                    />
                    <Path
                        data-name='Path 7324'
                        d='M2632.869 450.485s.347-1.156 2.316-1.156 2.2 1.619 4.515 1.619 2.2-1.619 4.169-1.619 2.315 1.619 4.515 1.619 2.085-1.619 4.168-1.619 2.258 1.156 2.548 1.156'
                        fill='none'
                        stroke='#cc8236'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7325'
                        d='M0 0s.263 2.434 2.288.778'
                        transform='translate(-2618 -129.504) translate(2631.715 450.139)'
                        fill='#cc8236'
                    />
                    <Path
                        data-name='Path 7326'
                        d='M0 0c.6.212 1.149.207 1.227-.012S.881-.582.281-.794-.868-1-.945-.782-.6-.212 0 0'
                        transform='translate(-2618 -129.504) translate(2632.767 450.914)'
                        fill='#cc8236'
                    />
                    <Path
                        data-name='Path 7327'
                        d='M0 0a.766.766 0 001.071.088.766.766 0 00-.107-1.069.767.767 0 00-1.071-.088A.766.766 0 000 0'
                        transform='translate(-2618 -129.504) translate(2654.82 450.797)'
                        fill='#cc8236'
                    />
                    <Path
                        data-name='Path 7328'
                        d='M0 0s-.263 2.434-2.288.778'
                        transform='translate(-2618 -129.504) translate(2656.424 450.507)'
                        fill='#cc8236'
                    />
                    <Path
                        data-name='Path 7330'
                        d='M0 0l-.714.446.632 1.012.714-.446z'
                        transform='translate(-2618 -129.504) translate(2655.792 449.513)'
                        fill='#cc8236'
                    />
                    <Path
                        data-name='Path 7331'
                        d='M0 0a4.8 4.8 0 00-4.8 4.8A4.8 4.8 0 000 9.592h37.59a4.8 4.8 0 100-9.592z'
                        transform='translate(-2618 -129.504) translate(2623.52 467.445)'
                        fill='#fff'
                    />
                    <Text
                        transform='translate(-2618 -129.504) translate(2626.45 474.935)'
                        fill='#cc8236'
                        fontSize={7}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                        letterSpacing='0.04em'
                    >
                        <TSpan x={0} y={0}>
                            {'SCARED'}
                        </TSpan>
                    </Text>
                    <Path
                        data-name='Path 7333'
                        d='M2623.52 467.445a4.8 4.8 0 00-4.8 4.8 4.8 4.8 0 004.8 4.792h37.59a4.8 4.8 0 100-9.592z'
                        fill='none'
                        stroke='#cc8236'
                        strokeWidth={1.447}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7334'
                        d='M0 0a5.789 5.789 0 00-5.789 5.789v2.862A5.789 5.789 0 000 14.44h35.6a5.789 5.789 0 005.789-5.789V5.789A5.789 5.789 0 0035.6 0z'
                        transform='translate(-2618 -129.504) translate(2624.84 467.008)'
                        fill='#fff'
                    />
                    <Path
                        data-name='Path 7335'
                        d='M2624.84 467.008a5.789 5.789 0 00-5.789 5.789v2.862a5.789 5.789 0 005.789 5.789h35.6a5.789 5.789 0 005.789-5.789v-2.862a5.789 5.789 0 00-5.789-5.789z'
                        fill='none'
                        stroke='#cc8236'
                        strokeWidth={1.447}
                        transform='translate(-2618 -129.504)'
                    />
                    <Text
                        data-name='SCARED'
                        transform='translate(-2618 -129.504) translate(2626.431 474.257)'
                        fill='#cc8236'
                        fontSize={7}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                        letterSpacing='0.04em'
                    >
                        <TSpan x={0} y={0}>
                            {'SCARED'}
                        </TSpan>
                    </Text>
                    <Text
                        data-name='fearful | frightened'
                        transform='translate(-2618 -129.504) translate(2622.934 479.246)'
                        fill='#cc8236'
                        fontSize={5}
                        fontFamily='Lato-Regular, Lato'
                    >
                        <TSpan x={0} y={0}>
                            {'fearful | frightened'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2672.582 492.627)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Hesitant'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2696.257 476.243)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Unsure'}
                        </TSpan>
                    </Text>
                </G>
                <G data-name='Group excited' {...onPressExcited}>
                    <Path
                        data-name='Path 7267'
                        d='M0 0l106.128 181.119-199.356 1.492S-99.4 61.208 0 0'
                        transform='translate(-2618 -129.504) translate(2732.256 195.022)'
                        fill='#d6e8e4'
                    />
                    <Path
                        data-name='Path 7273'
                        d='M0 0l91.493 156.144-171.865 1.286S-85.7 52.768 0 0'
                        transform='translate(-2618 -129.504) translate(2747.254 220.077)'
                        fill='#b8d7d1'
                    />
                    <Path
                        data-name='Path 7279'
                        d='M0 0l77.187 131.729-144.992 1.084S-72.3 44.517 0 0'
                        transform='translate(-2618 -129.504) translate(2761.635 244.569)'
                        fill='#9bc6bd'
                    />
                    <Path
                        data-name='Path 7285'
                        d='M0 0l63.314 108.052-118.932.89S-59.3 36.515 0 0'
                        transform='translate(-2618 -129.504) translate(2775.58 268.321)'
                        fill='#86bbb0'
                    />
                    <Path
                        data-name='Path 7291'
                        d='M0 0l49.908 85.173-93.749.7S-46.746 28.784 0 0'
                        transform='translate(-2618 -129.504) translate(2789.057 291.273)'
                        fill='#73b0a3'
                    />
                    <Path
                        data-name='Path 7297'
                        d='M0 0l36.33 62-68.244.511S-34.028 20.953 0 0'
                        transform='translate(-2618 -129.504) translate(2802.705 314.519)'
                        fill='#549f8f'
                    />
                    <Path
                        data-name='Path 7303'
                        d='M0 0l23.007 39.264-43.217.324S-21.55 13.269 0 0'
                        transform='translate(-2618 -129.504) translate(2816.098 337.327)'
                        fill='#378f7b'
                    />
                    <Text
                        transform='translate(-2618 -129.504) translate(2727.494 319.369)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Energetic'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2800.201 371.149)'
                        fill='#fff'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Hyper'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2746.295 336.629)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Passionate'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2771.633 353.887)'
                        fill='#fff'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Pumped'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2714.81 302.105)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Eager'}
                        </TSpan>
                    </Text>
                    <Path
                        data-name='Path 7371'
                        d='M0 0a20.021 20.021 0 0020.021-20.021A20.021 20.021 0 000-40.042a20.021 20.021 0 00-20.021 20.021A20.021 20.021 0 000 0'
                        transform='translate(-2618 -129.504) translate(2642.314 322.202)'
                        fill='#fff'
                    />
                    <Path
                        data-name='Path 7372'
                        d='M2642.314 322.202a20.021 20.021 0 0020.021-20.021 20.021 20.021 0 00-20.021-20.021 20.021 20.021 0 00-20.021 20.021 20.021 20.021 0 0020.021 20.021z'
                        fill='none'
                        stroke='#378f7b'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7373'
                        d='M0 0a3.151 3.151 0 003.151-3.151A3.151 3.151 0 000-6.3a3.151 3.151 0 00-3.151 3.149A3.151 3.151 0 000 0'
                        transform='translate(-2618 -129.504) translate(2634.293 299.552)'
                        fill='#378f7b'
                    />
                    <Path
                        data-name='Path 7374'
                        d='M0 0a3.151 3.151 0 003.151-3.151A3.151 3.151 0 000-6.3a3.151 3.151 0 00-3.151 3.149A3.151 3.151 0 000 0'
                        transform='translate(-2618 -129.504) translate(2650.039 299.552)'
                        fill='#378f7b'
                    />
                    <Path
                        data-name='Path 7375'
                        d='M2632.508 306.918s8.136 10.124 19.106.07'
                        fill='none'
                        stroke='#378f7b'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7376'
                        d='M0 0s-.606-2.372 1.871-1.53'
                        transform='translate(-2618 -129.504) translate(2631.579 307.696)'
                        fill='#378f7b'
                    />
                    <Path
                        data-name='Path 7377'
                        d='M0 0s2.337-.729 1.626 1.788'
                        transform='translate(-2618 -129.504) translate(2650.795 306.104)'
                        fill='#378f7b'
                    />
                    <Path
                        data-name='Path 7378'
                        d='M2637.214 286.436a22.115 22.115 0 01-8.654 5.051'
                        fill='none'
                        stroke='#378f7b'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7379'
                        d='M0 0s-1.756 1.706.8 2.282'
                        transform='translate(-2618 -129.504) translate(2628.178 290.348)'
                        fill='#378f7b'
                    />
                    <Path
                        data-name='Path 7380'
                        d='M0 0s.75-2.33-1.774-1.641'
                        transform='translate(-2618 -129.504) translate(2638.092 287.245)'
                        fill='#378f7b'
                    />
                    <Path
                        data-name='Path 7381'
                        d='M2647.416 286.436a22.115 22.115 0 008.654 5.051'
                        fill='none'
                        stroke='#378f7b'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7382'
                        d='M0 0s1.756 1.706-.8 2.282'
                        transform='translate(-2618 -129.504) translate(2656.452 290.348)'
                        fill='#378f7b'
                    />
                    <Path
                        data-name='Path 7383'
                        d='M0 0s-.75-2.33 1.774-1.641'
                        transform='translate(-2618 -129.504) translate(2646.537 287.245)'
                        fill='#378f7b'
                    />
                    <Path
                        data-name='Path 7385'
                        d='M2632.309 306.95h19.452'
                        fill='none'
                        stroke='#378f7b'
                        strokeWidth={1.93}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7386'
                        d='M0 0a4.8 4.8 0 00-4.8 4.8A4.8 4.8 0 000 9.592h37.59a4.8 4.8 0 100-9.592z'
                        transform='translate(-2618 -129.504) translate(2623.52 322.705)'
                        fill='#fff'
                    />
                    <Text
                        transform='translate(-2618 -129.504) translate(2626.114 330.193)'
                        fill='#378f7b'
                        fontSize={7}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                        letterSpacing='0.04em'
                    >
                        <TSpan x={0} y={0}>
                            {'EXCITED'}
                        </TSpan>
                    </Text>
                    <Path
                        data-name='Path 7388'
                        d='M2623.52 322.705a4.8 4.8 0 00-4.8 4.8 4.8 4.8 0 004.8 4.792h37.59a4.8 4.8 0 100-9.592z'
                        fill='none'
                        stroke='#378f7b'
                        strokeWidth={1.447}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7389'
                        d='M0 0a5.789 5.789 0 00-5.789 5.789v2.862A5.789 5.789 0 000 14.44h35.6a5.789 5.789 0 005.789-5.789V5.789A5.789 5.789 0 0035.6 0z'
                        transform='translate(-2618 -129.504) translate(2624.513 322.084)'
                        fill='#fff'
                    />
                    <Path
                        data-name='Path 7390'
                        d='M2624.513 322.084a5.789 5.789 0 00-5.789 5.789v2.862a5.789 5.789 0 005.789 5.789h35.6a5.789 5.789 0 005.789-5.789v-2.862a5.789 5.789 0 00-5.789-5.789z'
                        fill='none'
                        stroke='#378f7b'
                        strokeWidth={1.447}
                        transform='translate(-2618 -129.504)'
                    />
                    <Text
                        data-name='motivated | energised'
                        transform='translate(-2618 -129.504) translate(2621.519 334.322)'
                        fill='#378f7b'
                        fontSize={5}
                        fontFamily='Lato-Regular, Lato'
                        letterSpacing='-0.04em'
                    >
                        <TSpan x={0} y={0}>
                            {'motivated | energised'}
                        </TSpan>
                    </Text>
                    <Text
                        data-name='EXCITED'
                        transform='translate(-2618 -129.504) translate(2625.767 329.334)'
                        fill='#378f7b'
                        fontSize={7}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                        letterSpacing='0.04em'
                    >
                        <TSpan x={0} y={0}>
                            {'EXCITED'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2687.384 284.855)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Inspired'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2659.649 267.595)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Expectant'}
                        </TSpan>
                    </Text>
                </G>
                <G data-name='Group happy' {...onPressHappy}>
                    <Path
                        data-name='Path 7266'
                        d='M0 0l-103.79 182.469-100.969-171.9S-102.71-55.483 0 0'
                        transform='translate(-2618 -129.504) translate(2942.499 195.164)'
                        fill='#d4e2d7'
                    />
                    <Path
                        data-name='Path 7272'
                        d='M0 0l-89.478 157.308-87.046-148.2S-88.547-47.832 0 0'
                        transform='translate(-2618 -129.504) translate(2928.507 220.199)'
                        fill='#b4ceb9'
                    />
                    <Path
                        data-name='Path 7278'
                        d='M0 0l-75.487 132.71-73.435-125.024S-74.7-40.353 0 0'
                        transform='translate(-2618 -129.504) translate(2914.546 244.673)'
                        fill='#94b89c'
                    />
                    <Path
                        data-name='Path 7284'
                        d='M0 0l-61.919 108.858L-122.155 6.3S-61.275-33.1 0 0'
                        transform='translate(-2618 -129.504) translate(2901.008 268.405)'
                        fill='#7fab88'
                    />
                    <Path
                        data-name='Path 7290'
                        d='M0 0l-48.808 85.808L-96.29 4.97S-48.3-26.091 0 0'
                        transform='translate(-2618 -129.504) translate(2887.926 291.34)'
                        fill='#699d74'
                    />
                    <Path
                        data-name='Path 7296'
                        d='M0 0l-35.53 62.463-34.564-58.845S-35.16-18.993 0 0'
                        transform='translate(-2618 -129.504) translate(2874.677 314.567)'
                        fill='#498756'
                    />
                    <Path
                        data-name='Path 7302'
                        d='M0 0l-22.5 39.557-21.889-37.266S-22.266-12.028 0 0'
                        transform='translate(-2618 -129.504) translate(2861.676 337.358)'
                        fill='#2a7339'
                    />
                    <Text
                        transform='translate(-2618 -129.504) translate(2820.55 249.126)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Buoyant'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2821.808 347.855)'
                        fill='#fff'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Ecstatic'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2820.054 273.354)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Cheer'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2846.292 273.354)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'ful'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2817.485 300.438)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Delighted'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2813.33 325.78)'
                        fill='#fff'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Sensational'}
                        </TSpan>
                    </Text>
                    <Path
                        data-name='Path 7392'
                        d='M0 0a20.021 20.021 0 0020.021-20.021A20.021 20.021 0 000-40.042a20.021 20.021 0 00-20.021 20.021A20.021 20.021 0 000 0'
                        transform='translate(-2618 -129.504) translate(2839.284 170.752)'
                        fill='#fff'
                    />
                    <Path
                        data-name='Path 7393'
                        d='M2839.284 170.752a20.021 20.021 0 0020.021-20.021 20.021 20.021 0 00-20.021-20.021 20.021 20.021 0 00-20.021 20.021 20.021 20.021 0 0020.021 20.021z'
                        fill='none'
                        stroke='#2a7339'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7396'
                        d='M2829.498 155.385s8.047 10.195 19.1.237'
                        fill='none'
                        stroke='#2a7339'
                        strokeWidth={2.412}
                        transform='translate(-2618 -129.504)'
                    />
                    <Path
                        data-name='Path 7398'
                        d='M0 0s2.343-.709 1.61 1.8'
                        transform='translate(-2618 -129.504) translate(2847.792 154.729)'
                        fill='#2a7339'
                    />
                    <Path
                        data-name='Path 7399'
                        d='M0 0a5.789 5.789 0 00-5.789 5.789v2.862A5.789 5.789 0 000 14.44h35.6a5.789 5.789 0 005.789-5.789V5.789A5.789 5.789 0 0035.6 0z'
                        transform='translate(-2618 -129.504) translate(2821.534 171.069)'
                        fill='#fff'
                    />
                    <Path
                        data-name='Path 7400'
                        d='M2821.534 171.069a5.789 5.789 0 00-5.789 5.789v2.862a5.789 5.789 0 005.789 5.789h35.6a5.789 5.789 0 005.789-5.789v-2.862a5.789 5.789 0 00-5.789-5.789z'
                        fill='none'
                        stroke='#2a7339'
                        strokeWidth={1.447}
                        transform='translate(-2618 -129.504)'
                    />
                    <Text
                        data-name='joyful | Optimistic'
                        transform='translate(-2618 -129.504) translate(2820.521 183.308)'
                        fill='#2a7339'
                        fontSize={5}
                        fontFamily='Lato-Regular, Lato'
                    >
                        <TSpan x={0} y={0}>
                            {'joyful | Optimistic'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2825.563 178.319)'
                        fill='#2a7339'
                        fontSize={7}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                        letterSpacing='0.04em'
                    >
                        <TSpan x={0} y={0}>
                            {'HAPPY'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2820.177 200.298)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Peaceful'}
                        </TSpan>
                    </Text>
                    <Text
                        transform='translate(-2618 -129.504) translate(2821.691 221.313)'
                        fill='#42454a'
                        fontSize={10}
                        fontFamily='Helvetica Neue,Roboto,calibri'
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {'Pleased'}
                        </TSpan>
                    </Text>
                    <Path
                        data-name='Path 7394'
                        d='M0 0a3.151 3.151 0 003.151-3.151A3.151 3.151 0 000-6.3a3.151 3.151 0 00-3.151 3.149A3.151 3.151 0 000 0'
                        transform='translate(-2618 -129.504) translate(2831.263 148.102)'
                        fill='#2a7339'
                    />
                    <Path
                        data-name='Path 7395'
                        d='M0 0a3.151 3.151 0 003.151-3.151A3.151 3.151 0 000-6.3a3.151 3.151 0 00-3.151 3.149A3.151 3.151 0 000 0'
                        transform='translate(-2618 -129.504) translate(2847.01 148.102)'
                        fill='#2a7339'
                    />
                </G>
            </G>
        </Svg>
    );
}