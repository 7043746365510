import { FontAwesome } from '@expo/vector-icons'
import { DimensionValue, StyleSheet, TouchableOpacity, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { avatars } from '../icon/avatar/avatars'
import { useResponsive } from './use-responsive'


type Props = {
    avatar?: string | null
    size?: DimensionValue
    editMode?: boolean
    onEdit?: () => void
}

const AvatarImage = ({ avatar, size, editMode = false, onEdit }: Props) => {
    const { scaleRatio, lowRes, windowWidthCondition } = useResponsive()

    const Avatar = avatars?.[avatar as keyof typeof avatars || "Default"]

    const avatarContainerWidth = size ? size : lowRes ? 200 * scaleRatio : windowWidthCondition ? 200 : 150
    const avatarContainerHeight = size ? size : lowRes ? 200 * scaleRatio : windowWidthCondition ? 200 : 150

    return (
        <View style={[
            styles.avatarContainer, {
                width: avatarContainerWidth,
                height: avatarContainerHeight,
                marginVertical: lowRes ? 10 : 20
            }]}>
            {editMode ? (
                <TouchableOpacity onPress={onEdit} style={{ width: avatarContainerWidth, height: avatarContainerHeight }}>
                    <Avatar />
                    <View style={styles.pencil}>
                        <FontAwesome name="pencil" size={35} color={DecidaColors.Green} />
                    </View>
                </TouchableOpacity>
            ) : (
                <Avatar />
            )}
        </View>
    )
}
export default AvatarImage
const styles = StyleSheet.create({
    avatarContainer: {

    },
    pencil: {
        position: 'absolute',
        right: 0,
        top: -15,

    }
})