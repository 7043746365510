import { useReactiveVar } from '@apollo/client';
import { StyleSheet, View } from 'react-native';
import { School, SchoolStatus } from '../../common/API';
import { UserGroup } from '../../common/constants';
import { rvUserGroup } from '../login/login-state';
import { DefaultText } from './default-text';
import { DecidaColors } from '../../common/decida-colors';

type Props = {
    school: School
}

const ActiveSchoolStudentsCount = ({ school }: Props) => {
    const userGroup = useReactiveVar(rvUserGroup)

    const licenseTotal = school.licenseTotal
    const totalStudents = school.activeStudents || 0
    const isLicenseLimitExceed = school.status === SchoolStatus.SUBSCRIBED && totalStudents > Number(licenseTotal)

    return (
        <View style={styles.container}>
            <DefaultText style={styles.activeSchoolStudent}>Renewal date: {school.renewalDate}</DefaultText>
            <DefaultText style={isLicenseLimitExceed && licenseTotal ? styles.limitReachedText : styles.activeSchoolStudent}>Status: {school.status}</DefaultText>
            <DefaultText style={isLicenseLimitExceed && licenseTotal ? styles.limitReachedText : styles.activeSchoolStudent}>Active students: {totalStudents}{licenseTotal ? `/${licenseTotal}` : ""}</DefaultText>
            {!licenseTotal && userGroup === UserGroup.Admin && <DefaultText style={styles.limitReachedText}>License number not set</DefaultText>}
        </View>
    )
}
export default ActiveSchoolStudentsCount

const styles = StyleSheet.create({
    container: {
        alignItems: 'center'
    },
    activeSchoolStudent: {
        color: DecidaColors.Green,
    },
    limitReachedText: {
        color: DecidaColors.Red,
        fontWeight: 'bold'
    }
})