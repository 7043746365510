import { createStackNavigator } from '@react-navigation/stack'
import { CheckInEmotionalIntensity } from '../check-in/check-in-emotional-intensity'
import { CheckInEmotionalWheel } from '../check-in/check-in-emotional-wheel'
import { CheckInRequestChatWithTeacher } from '../check-in/check-in-request-chat-with-teacher'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'
import { CheckInTirednessScale } from '../check-in/check-in-tiredness-scale'
import { RootView } from '../common/root-view'
import { ScreenNames } from '../common/screen-names'
import { TeacherCheckInFinish } from './teacher-check-in-finish'
import { TeacherCheckInStart } from './teacher-check-in-start'
import { CheckInWellbeingQuestions } from '../check-in/check-in-wellbeing-questions'
import CheckInEating from '../check-in/check-in-eating'

const Stack = createStackNavigator<CheckInNavigatorParamList>()

export const TeacherCheckInMain = () => (
    <RootView>
        <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={ScreenNames.CheckInStart}>
            <Stack.Screen name={ScreenNames.CheckInEmotionalIntensity} component={CheckInEmotionalIntensity} />
            <Stack.Screen name={ScreenNames.CheckInEmotionalWheel} component={CheckInEmotionalWheel} />
            <Stack.Screen name={ScreenNames.CheckInFinish} component={TeacherCheckInFinish} />
            <Stack.Screen name={ScreenNames.CheckInRequestChatWithTeacher} component={CheckInRequestChatWithTeacher} />
            <Stack.Screen name={ScreenNames.CheckInStart} component={TeacherCheckInStart} />
            <Stack.Screen name={ScreenNames.CheckInWellbeingQuestion} component={CheckInWellbeingQuestions} />
            <Stack.Screen name={ScreenNames.CheckInTirednessScale} component={CheckInTirednessScale} />
        </Stack.Navigator>
    </RootView>
)