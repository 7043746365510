import { StyleSheet, View } from 'react-native'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { SereneGardenHeaderIcon } from './src/serene-garden-header-icon'
import { SereneGardenIcon } from './src/serene-garden-icon'
import { SereneGardenImage1 } from './src/serene-garden-image1'
import { SereneGardenImage2 } from './src/serene-garden-image2'
import SwitchCardVideo from '../../../common/switch-card-video'
import { Videos } from '../../../models'

const sereneGardenVideo1: Videos = {
    id: "1",
    url: "https://player.vimeo.com/video/433211718",
    title: "",
    isExternal: true
}
export const SwitchesEmotionsSadSereneGarden: CardTemplate = {
    cardId: "ec377274-d2bc-4ce1-bfdb-20fe1a8ecb6d",
    title: 'Serene garden',
    Header: SereneGardenHeaderIcon,
    Icon: SereneGardenIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Those times when thoughts feel overly loud or jumbled."} />

            <SwitchCardVideo video={sereneGardenVideo1} />
            <DefaultTextWithoutArrows textdata={"Watch the video to see all the beautiful flowers in the garden."} />
            <DefaulttextWithRightArrow textdata={"◦ What different coloured flowers did you see?"} />

            <View style={styles.imageContainer}>
                <SereneGardenImage2 />
            </View>
            <DefaultTextWithoutArrows textdata={"Listen carefully to all the different sounds."} />
            <DefaulttextWithRightArrow textdata={"◦ What noises did you hear?"} />

        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    cardContentTemplate: 'column',
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: "Listening to surrounding sounds and being observant helps regulate emotions by shifting focus away from overwhelming thoughts and emotions. When we intentionally listen to the sounds around us, it engages our senses and redirects our attention. This practice promotes mindfulness, allowing us to be present in the moment and observe our surroundings without judgment. By immersing ourselves in the soundscape, we create a mental space that provides a break from intense emotions, leading to a sense of calm and improved emotional regulation.",
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Experience a virtual walk through a garden, observe the flowers, and listen to the sounds of nature as a means of regulating their emotions and promoting a sense of calm and relaxation.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 25 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students and explain that today they will embark on a special journey through a serene garden using virtual technology (a video).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of nature and how it can have a positive impact on our emotions and well-being.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Engage the students by asking questions like, "Have you ever been to a garden? What do you think we can see and hear there?"</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Setting the Scene (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show the still image of the Serene Garden switch on the screen to create an atmosphere of anticipation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to close their eyes and imagine themselves in a beautiful garden, surrounded by colourful flowers and peaceful sounds.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Virtual Garden Walk-through (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the 15 minute video ensuring the sounds can be heard.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to observe and comment on the flowers, plants, and sounds they encounter.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allow moments of silence to appreciate the serene environment and encourage students to focus on their own emotions and feelings.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Expression (5 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students back into a circle and provide art supplies.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share their experiences and emotions during the virtual garden walk through.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to express their thoughts and feelings by drawing or writing about their favourite flower or sound they encountered.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Closure (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reflect on the lesson by discussing the positive effects of observing flowers and listening to nature sounds on our emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the importance of finding moments of tranquility and appreciating the beauty of nature to regulate our emotions and promote a sense of calm.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to carry this experience with them and seek moments of connection with nature when they need to regulate their emotions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Extension:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a mediation using other immersive videos such as an aquarium.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Computer or projector for displaying the virtual walk-through</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Speakers or headphones for audio</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Drawing materials</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Relaxing Music For Elementary Classroom - Jellyfish Aquarium - Calm classroom music for children -</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=wtKghD9LM6o&list=PL9Jv5JvAPlrgVkw3LRIctqG0KZUC39mh7&index=11' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Nature: How connecting with nature benefits our mental health -</DefaultText>
                        <DefaultTextWithLink text='https://www.mentalhealth.org.uk/our-work/research/nature-how-connecting-nature-benefits-our-mental-health#:~:text=Research%20shows%20that%20people%20who,creativity%20and%20can%20facilitate%20concentration.' />

                        <DefaultText style={CommonStyles.lessonPlanText}>What Is Mindfulness? Definition, Benefits & Psychology -</DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/what-is-mindfulness/' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Serene+garden+voice+over.m4a",
    type: [CardType.Switch],
    time: [CardTimeType.Short, CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Sad]
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        alignSelf: 'center',
        marginVertical: 10
    },
})
