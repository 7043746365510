import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { Feather } from '@expo/vector-icons'
import { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { GetSchoolAdminByCognitoUsernameQuery, GetSchoolAdminByCognitoUsernameQueryVariables, ListAllStudentsBySchoolIDQueryVariables, ListSchoolAdminBySchoolIDQuery, ListSchoolAdminBySchoolIDQueryVariables, School, SchoolAdmin } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'
import { SectionTitleAndText } from '../common/section-title-and-text'
import { getSchoolAdminByCognitoUsername, listSchoolAdminBySchoolID } from '../../common/graphql/queries'

import _ from 'lodash'
import { DefaultButton, DefaultButtonTypes } from '../common/default-button'
import { rvCurrentUser } from '../login/login-state'
import { schoolAdminBillingDetailsContainerStyle } from './school-admin-const'
import SchoolAdminFormAdministrator from './school-admin-form-administrator'

type Props = {
    schoolData: School
}
const SchoolAdminBillingAdministratorsDetails = ({ schoolData }: Props) => {
    const user = useReactiveVar(rvCurrentUser)

    const { data: resultSchoolAdmin } = useQuery<GetSchoolAdminByCognitoUsernameQuery, GetSchoolAdminByCognitoUsernameQueryVariables>(gql`${getSchoolAdminByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
    const currentSchoolAdmin = resultSchoolAdmin?.getSchoolAdminByCognitoUsername?.items[0]

    const [editMode, setEditMode] = useState(false)
    const [createMode, setCreateMode] = useState(false)

    const toggleEditMode = () => {
        setCreateMode(false)
        setEditMode(prev => !prev)
    }

    const toggleCreateMode = () => {
        setEditMode(false)
        setCreateMode(prev => !prev)
    }

    const { data: resultListSchoolAdmins } = useQuery<ListSchoolAdminBySchoolIDQuery, ListSchoolAdminBySchoolIDQueryVariables>(gql`${listSchoolAdminBySchoolID}`, { variables: { schoolID: currentSchoolAdmin?.schoolID || "", limit: 5000 }, skip: !currentSchoolAdmin })
    const schoolAdministrators = _.orderBy(
        resultListSchoolAdmins?.listSchoolAdminBySchoolID?.items
            .filter((schoolAdministrator) => schoolAdministrator?._deleted !== true),
        [ // orderby
            (item) => item?.id === schoolData?.coordinatorID,
            (item) => item?.firstName?.toLowerCase(),
            (item) => item?.lastName?.toLowerCase,
            (item) => item?.cognitoUsername.toLowerCase()
        ],
        [
            "desc", // by coordinator
            "asc", // firstname
            "asc", // lastname
            "asc" // cognitoUsername
        ] // make coordinator listed first
    )
    const isCoordinantor = currentSchoolAdmin?.id === schoolData?.coordinatorID

    return (
        <View>
            <DefaultText style={styles.sectionTitle}>Switch Administrators</DefaultText>
            <View style={schoolAdminBillingDetailsContainerStyle}>
                <>
                    {(!createMode && isCoordinantor) && (
                        <TouchableOpacity onPress={toggleEditMode} style={styles.editButton}>
                            <Feather name='edit' size={30} color={DecidaColors.Green} />
                        </TouchableOpacity>
                    )}
                    {editMode && (
                        <DefaultButton plusIcon type={DefaultButtonTypes.Small} style={{ minWidth: 200, maxWidth: 250 }} onPress={toggleCreateMode}>Add school admin</DefaultButton>
                    )}
                    {createMode ? (
                        <>
                            <DefaultText style={styles.topTitle}>Add administrator</DefaultText>
                            <SchoolAdminFormAdministrator toggleEditMode={toggleCreateMode} schoolData={schoolData} />
                        </>
                    ) : (
                        <>
                            {schoolAdministrators?.map((schoolAdministrator) => {
                                const isCoordinator = schoolData?.coordinatorID === schoolAdministrator?.id
                                const adminFullName = `${schoolAdministrator?.firstName} ${schoolAdministrator?.lastName}`
                                const nameLabel = isCoordinator ? `${adminFullName} (Key coordinator)` : adminFullName
                                return (
                                    <View key={schoolAdministrator?.id} style={styles.schoolAdministratorItemWrapper}>
                                        {editMode ? (
                                            <View style={{ width: '100%' }}>
                                                <SchoolAdminFormAdministrator schoolAdministrator={schoolAdministrator as SchoolAdmin} toggleEditMode={toggleEditMode} schoolData={schoolData} />
                                            </View>
                                        ) : (
                                            <View style={{ flex: 1 }}>
                                                <SectionTitleAndText title='Name' text={nameLabel || "-"} />
                                                <SectionTitleAndText title='Username' text={schoolAdministrator?.cognitoUsername || ""} />
                                                <SectionTitleAndText title='Email' text={schoolAdministrator?.email || ""} />
                                            </View>
                                        )}
                                    </View>
                                )
                            })}
                        </>
                    )}

                </>
            </View>
        </View>
    )
}
export default SchoolAdminBillingAdministratorsDetails
const styles = StyleSheet.create({
    topTitle: {
        marginVertical: 5,
        color: DecidaColors.GrayText,
    },
    schoolAdministratorItemWrapper: {
        marginBottom: 20,
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    sectionTitle: {
        marginLeft: 20,
        fontWeight: '600',
        color: DecidaColors.GrayText
    },
    editButton: {
        position: 'absolute',
        top: -10,
        left: -10
    },
})