import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { FontAwesome } from '@expo/vector-icons'
import { StackScreenProps } from '@react-navigation/stack'
import { API } from 'aws-amplify'
import { useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { Class, CustomCreateStudentMutation, CustomCreateStudentMutationVariables, StudentStatus } from '../../common/API'
import { UserGroup } from '../../common/constants'
import { DecidaColors } from '../../common/decida-colors'
import { generatePassword } from '../../common/generate-password'
import { CommonStyles } from '../common/const'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { FirstNameInput } from '../common/first-name-input'
import { getApiHeaders } from '../common/get-api-header'
import { InputCognitoUsername } from '../common/input-cognito-username'
import InputEmailStudents, { rvInputEmailStudentsLoading } from '../common/input-email-students'
import { LastNameInput } from '../common/last-name-input'
import { rvIsLoading } from '../common/loading'
import { CLIENT_EVENT, createClientLog } from '../common/log-client-event'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { showAlert, showGenericErrorAlert } from '../common/universal-alert'
import { getClassQuery } from '../custom-graphql/queries/get-class-query'
import { avatars } from '../icon/avatar/avatars'
import { rvCurrentUser, rvUserGroup } from '../login/login-state'
import { rvIsSchoolWithCampus, rvSidebarClassID } from '../school-admin/school-admin-state'
import { TeacherCreateStudentBreadcrumbs } from './teacher-create-student-breadcrumbs'
import { customCreateStudent } from '../../common/graphql/mutations'

type TeacherCreateStudentNicknameStackScreenProps = StackScreenProps<{ [k: string]: { avatarName?: string, classID?: string } }>

export const TeacherCreateStudentNickname = ({ route: { params: { avatarName, classID } }, navigation: { navigate } }: TeacherCreateStudentNicknameStackScreenProps) => {
    const isSchoolWithCampus = useReactiveVar(rvIsSchoolWithCampus)
    const userGroup = useReactiveVar(rvUserGroup)
    const user = useReactiveVar(rvCurrentUser)
    const [nickname, setNickname] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [usernameUnavalible, setUsernameUnavalible] = useState<boolean | null>(null)
    const [emailExists, setEmailExists] = useState(false)
    const isSchoolAdmin = userGroup === UserGroup.SchoolAdmin
    const checkingEmail = useReactiveVar(rvInputEmailStudentsLoading)

    const [createStudentMutation] = useMutation<CustomCreateStudentMutation, CustomCreateStudentMutationVariables>(gql`${customCreateStudent}`,)

    const { data: responseClass } = useQuery(getClassQuery, { variables: { id: classID } })

    const currentClass = responseClass?.getClass as Class

    const Avatar = avatars[avatarName as keyof typeof avatars]

    const setEmailFiltered = (value: string) => {
        if (value.search(/[^0-9a-zA-Z_.@]/) < 0) {
            setEmail(value)
        }
    }

    const navigateToCreateStudentPickAvatar = () => {
        navigate(ScreenNames.TeacherCreateStudentPickAvatar, { classID: classID || "" })
    }


    const resetForm = () => {
        setFirstName("")
        setNickname("")
        setLastName("")
        setEmail("")
    }

    const createStudentLogin = async () => {
        try {
            rvIsLoading(true)
            const tempPassword = generatePassword()

            await createStudentMutation({
                variables: {
                    input: {
                        avatar: avatarName || "",
                        nickname,
                        firstName,
                        lastName,
                        schoolID: currentClass?.schoolID,
                        status: StudentStatus.ACTIVE,
                        cognitoUsername: nickname,
                        email: email ?? undefined,
                        classID: currentClass?.id || "",
                        password: tempPassword,
                        initialLoginSetup: false
                    }
                }
            })

            createClientLog({
                area: 'Create student nickname page',
                action: "Success create student class, navigating to TeacherClassAdmin",
                event: CLIENT_EVENT.CREATE_STUDENT_SUCCESS,
                payload: {
                    group: "teacher",
                    nickname: user?.username,
                }
            })

            showAlert({
                title: `Congratulations, ${nickname} has been added to your class.`,
                message: (
                    <div>
                        <FontAwesome name="check-circle" size={40} color={DecidaColors.Green} style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }} />
                        <DefaultText style={{ display: 'flex', margin: 10 }}>If you wish for your student to login individually, their username and temporary password that is active for 7 days is:</DefaultText>
                        <DefaultText style={{ display: 'flex', margin: 20, marginBottom: 0 }}><b>Username</b>:&nbsp;{nickname}</DefaultText>
                        <DefaultText style={{ display: 'flex', margin: 20, marginTop: 0 }}><b>Password</b>:&nbsp;{tempPassword}</DefaultText>
                        <DefaultText style={{ display: 'flex', margin: 10 }}>When they log on for the first time, they will be asked to change their password.</DefaultText>
                    </div>
                ),
                leftButtonText: 'Ok'
            })

            resetForm()
            if (isSchoolAdmin && isSchoolWithCampus) {
                navigate(ScreenNames.SchoolAdminHome, {})
                rvSidebarClassID(classID)
            } else if (isSchoolAdmin) {
                navigate(ScreenNames.SchoolAdminManageClass, { classID: classID || "" })
            } else {
                navigate(ScreenNames.TeacherClassAdmin, { classID: classID || "" })
            }
        } catch (e: any) {
            const errorMessage = e.response?.data?.message

            if (errorMessage) {
                if (String(errorMessage).startsWith("User account already exists")) {
                    showAlert({
                        title: emailExists ? "Account with this email already exists" : 'Nickname already exists',
                        message: emailExists ? `An account with this email address already exists in the system. Please cancel to return to the menu to search for the existing student to add them to your class. Alternatively, omit or use an alternate email address to proceed.` : `${nickname} already exists, please choose another nickname.`,
                        leftButtonText: 'Ok',
                        rightButtonText: 'Cancel'
                    })
                } else {
                    showAlert({
                        title: 'An error has occured.',
                        message: errorMessage || String(e),
                        leftButtonText: 'Ok',
                        rightButtonText: 'Retry',
                        onRightButtonPress: createStudentLogin
                    })
                }



            } else {
                showGenericErrorAlert()
            }

            createClientLog({
                area: 'Create account button',
                action: "",
                event: CLIENT_EVENT.CREATE_STUDENT_FAILED,
                payload: {
                    group: "teacher",
                    nickname: user?.username,
                    error: errorMessage || e
                }
            })
        } finally {
            rvIsLoading(false)
        }
    }



    return (
        <Page style={styles.page}>
            <View style={styles.header}>
                <TeacherCreateStudentBreadcrumbs step={2} back={navigateToCreateStudentPickAvatar} />
            </View>


            <ScrollView style={CommonStyles.inputScrollView} contentContainerStyle={{ justifyContent: 'center' }}>
                <View style={[CommonStyles.flexContainer, { alignItems: 'flex-start', marginTop: '5%', }]}>
                    <View style={styles.mainContainer}>
                        <DefaultText style={styles.title}>Create a nickname</DefaultText>
                        <DefaultText style={styles.subtitle}>So we know what to call you!</DefaultText>
                        <View style={styles.avatar}>
                            <Avatar />
                        </View>
                    </View>
                    <View style={styles.mainContainer}>
                        <InputCognitoUsername
                            userExists={usernameUnavalible}
                            setUserExists={setUsernameUnavalible}
                            value={nickname}
                            onChangeText={setNickname}
                        />
                        <FirstNameInput value={firstName} onChangeText={setFirstName} />
                        <LastNameInput value={lastName} onChangeText={setLastName} />
                        <InputEmailStudents
                            value={email}
                            onChangeText={setEmailFiltered}
                            schoolID={currentClass?.schoolID || ""}
                            emailExists={emailExists}
                            setEmailExists={setEmailExists}
                            showErrorMessage={false}
                        />
                        <DefaultButton
                            disabledStyle={styles.disabled}
                            onPress={createStudentLogin}
                            disabled={nickname.length < 3 || checkingEmail}
                            style={styles.createButton}
                        >
                            Create Account
                        </DefaultButton>
                    </View>
                </View>
            </ScrollView>
        </Page>
    )
}

const styles = StyleSheet.create({
    header: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
    },
    page: {

    },
    mainContainer: {
        alignSelf: 'center',
        alignItems: 'center',
    },
    title: {
        fontSize: 30,
        padding: 20,
    },
    subtitle: {
        fontSize: 18,
        color: DecidaColors.Gray,
    },
    avatar: {
        width: 70,
        height: 70,
        margin: 30,
    },
    emailNote: {
        width: 330,
        color: DecidaColors.Gray,
        fontSize: 15,
    },
    heading: {
        fontSize: 17,
        fontWeight: 'bold',
        paddingTop: 20,

    },
    scrollView: {
        width: '100%',
        minWidth: 400,
        maxWidth: '100%',

    },
    createButton: {
        marginBottom: 20,
    },
    disabled: {
        backgroundColor: DecidaColors.Gray
    }
})
