import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { Feather } from '@expo/vector-icons'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import _ from 'lodash'
import { ActivityIndicator, StyleSheet, TouchableOpacity, View } from 'react-native'
import { GetStudentClassByClassIdQueryVariables } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { avatars } from '../icon/avatar/avatars'
import { StudentClassApprovalStatus } from '../models'
import { SchoolAdminClassNavigatorParamsList } from '../school-admin/school-admin-navigation-params'
import { ProgressiveLoader } from '../switches/common/progressive-loader'
import { ListStudentClassAndStudentDataByClassIdCustomQuery, StudentClassAndStudentDataFieldsTypes, listStudentClassAndStudentDataByClassIdCustomQuery } from '../teacher/teacher-graphql-scripts'
import { TeacherClassNavigatorParamList } from '../teacher/teacher-route-param-types'
import { DefaultFontSize } from './const'
import { DefaultText } from './default-text'
import { rvStudentsFilterKeys } from './dropdown-sort-student'
import { ScreenNames } from './screen-names'
import { useResponsive } from './use-responsive'
import ListActiveStudentItem from './list-active-student-item'

export enum ActiveStudentsType {
    Teacher,
    SchoolAdmin,
    SchoolAdminCampus
}

type Props = {
    type: ActiveStudentsType
    classID: string
}

const ListActiveStudents = ({ type = ActiveStudentsType.SchoolAdmin, classID }: Props) => {
    const filterKeys = useReactiveVar(rvStudentsFilterKeys)
    const navigation = useNavigation<NavigationProp<SchoolAdminClassNavigatorParamsList & TeacherClassNavigatorParamList, "TeacherEditStudent" | "SchoolAdminEditStudent">>()

    const { windowWidthCondition } = useResponsive()

    const { data: studentClassAndStudentDataResponse, loading: loadingStudentData } = useQuery<ListStudentClassAndStudentDataByClassIdCustomQuery, GetStudentClassByClassIdQueryVariables>(gql`${listStudentClassAndStudentDataByClassIdCustomQuery}`, { variables: { classID, limit: 5000 } })

    const activeStudentClasses = studentClassAndStudentDataResponse?.getStudentClassByClassId?.items.filter((item) => item?._deleted !== true && item?.status === StudentClassApprovalStatus.APPROVED)

    const goToEditStudent = (studentClassAndStudentData: StudentClassAndStudentDataFieldsTypes) => {
        if (type === ActiveStudentsType.Teacher) {
            navigation.navigate(ScreenNames.TeacherEditStudent, { studentID: studentClassAndStudentData?.studentID || "", classID })
        } else {
            navigation.navigate(ScreenNames.SchoolAdminEditStudent, { studentID: studentClassAndStudentData?.student?.id || "", classID })
        }
    }

    let activeStudentClassesAndStudentData = activeStudentClasses?.filter(item => item?.student !== undefined && item?.status === StudentClassApprovalStatus.APPROVED)

    if (filterKeys) {
        activeStudentClassesAndStudentData = _.sortBy(activeStudentClasses, filterKeys.map((filterKey) => {
            return (item: StudentClassAndStudentDataFieldsTypes) => item?.student[filterKey]?.toLowerCase()
        }))
    } else {
        activeStudentClassesAndStudentData = activeStudentClasses
    }


    if (loadingStudentData) {
        return (
            <View style={{ justifyContent: 'center', width: '100%' }}>
                <ActivityIndicator color={DecidaColors.Green} size="large" />
            </View>
        )
    }

    if (activeStudentClassesAndStudentData?.length === 0) {

        if (type === ActiveStudentsType.Teacher) {
            return (
                <DefaultText style={styles.noStudentText}>
                    No students currently in this class
                </DefaultText>
            )
        }

        if (type === ActiveStudentsType.SchoolAdminCampus) {
            return (
                <DefaultText style={styles.noStudentsTextSchoolAdminCampus}>No students added</DefaultText>
            )
        }


    }


    return (
        <>
            <View style={styles.container}>
                {activeStudentClassesAndStudentData &&
                    activeStudentClassesAndStudentData?.length > 0 &&
                    type !== ActiveStudentsType.SchoolAdminCampus && (
                        <DefaultText style={type === ActiveStudentsType.Teacher ? styles.activeStudentsTextTeacher : styles.activeStudentsText}>Active Students ({activeStudentClassesAndStudentData?.length.toString()}) </DefaultText>
                    )}
                <View style={type === ActiveStudentsType.Teacher ? styles.avatarContainerTeacher(windowWidthCondition) : type === ActiveStudentsType.SchoolAdmin ? styles.avatarContainer : styles.avatarContainerSidebar}>

                    {activeStudentClassesAndStudentData?.length ? (
                        <ProgressiveLoader>
                            {activeStudentClassesAndStudentData.map((item: StudentClassAndStudentDataFieldsTypes) => {
                                const { student } = item || {}
                                return (
                                    <ListActiveStudentItem key={`${item?.student?.id}`} classID={classID} type={type} item={item} />
                                )
                            })}
                        </ProgressiveLoader>
                    ) : (
                        <DefaultText style={styles.studentText}>
                            No students currently in this class
                        </DefaultText>
                    )}

                </View>
            </View>
        </>
    )
}

export default ListActiveStudents

const styles = StyleSheet.create<any>({
    container: {
        flex: 1,
        marginBottom: 10,
        width: '100%'
    },
    avatarContainerSidebar: {
        marginTop: 20,
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    activeStudentsText: {
        textAlign: 'center',
        alignSelf: 'flex-start',
        fontSize: DefaultFontSize,
        color: DecidaColors.Green,
        fontWeight: 'bold',
        marginHorizontal: 5,
    },
    activeStudentsTextTeacher: {
        textAlign: 'center',
        alignSelf: 'center',
        fontSize: DefaultFontSize,
        color: DecidaColors.Green,
        fontWeight: 'bold',
        marginHorizontal: 5,
    },
    avatar: {
        width: 40,
        height: 40,
    },
    studentText: {
        fontSize: 18,
        color: DecidaColors.AppleSystemGray2Dark,
        fontWeight: '600',
        textAlign: 'center',
        width: '100%'
    },
    avatarContainer: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        width: '55%',
        paddingLeft: 20,
        paddingVertical: 20,
        justifyContent: 'flex-start',
        borderWidth: 1,
        borderRadius: 20,
        borderColor: DecidaColors.Green,
        marginLeft: 20,
        marginTop: 10,
        paddinngRight: 'auto',
    },
    avatarContainerTeacher: (windowWidthCondition: boolean) => ({
        flexWrap: 'wrap',
        flexDirection: windowWidthCondition ? 'column' : 'row',
        width: '100%',
        paddingLeft: 20,
        paddingVertical: 20,
        justifyContent: 'flex-start',
        borderWidth: 1,
        borderRadius: 20,
        borderColor: DecidaColors.Green,
        marginTop: 10,
        paddinngRight: 'auto',
    }),
    noStudentsTextSchoolAdminCampus: {
        fontSize: 20,
        fontWeight: 'bold',
        fontStyle: 'italic',
        color: DecidaColors.Blue,
        textAlign: 'center',
        marginTop: 20,
    },
})