import { gql, useQuery, useReactiveVar, useSubscription } from '@apollo/client'
import { useEffect } from 'react'
import { GetStudentByCognitoUsernameQuery, GetStudentByCognitoUsernameQueryVariables, ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables, ListFavouriteByCognitoUsernameQuery, ListFavouriteByCognitoUsernameQueryVariables, ListFindYourCalmByUserIdAndActivityIdQuery, ListFindYourCalmByUserIdAndActivityIdQueryVariables, OnMutateCheckInSessionByClassIdSubscription, OnMutateCheckInSessionByClassIdSubscriptionVariables, OnMutateFavouriteByCognitoUsernameSubscription, OnMutateFavouriteByCognitoUsernameSubscriptionVariables, OnMutateFindYourCalmActivityRatingSubscription, OnMutateFindYourCalmActivityRatingSubscriptionVariables, OnMutateStudentByCognitoUsernameSubscription, OnMutateStudentClassByStudentIdSubscription, OnMutateStudentClassByStudentIdSubscriptionVariables } from '../../common/API'
import { getStudentByCognitoUsername, listCheckInSessionsByClassId, listFavouriteByCognitoUsername, listFindYourCalmByUserIdAndActivityId } from '../../common/graphql/queries'
import { onMutateCheckInSessionByClassId, onMutateFavouriteByCognitoUsername, onMutateFindYourCalmActivityRating, onMutateStudentByCognitoUsername, onMutateStudentClassByStudentId } from '../../common/graphql/subscriptions'
import { rvCheckinSessions, rvLastCheckinSession } from '../common/common-state'
import { CheckInSessionSubscriptionListener, ClassSubscriptionByStudentIdListener, StudentCheckInSubscriptionListenerByStudentId, StudentClassSubscriptionListenerByStudentId, StudentInitialLoad, StudentRatingSubscriptionListener, StudentSubscriptionListenerByStudentId } from '../common/helper-api-functions'

import { updateListQueryCache } from '../common/update-list-query-cache'
import useAppState from '../common/use-app-state'
import useConnectivity from '../common/use-connectivity'
import { useCreateRvCache } from '../common/use-create-rv-cache'
import { useRedirectPages } from '../common/user-redirect-pages'
import { rvSwitchesScreenState } from '../switches/common/current-screen-state'
import StudentMainNavigator from './student-main-navigator'
import StudentMainOnboardNavigator from './student-main-onboard-navigator'
import { getCurrentClassIdFromStorageStudent, rvCurrentStudent, rvStudentClasses, rvStudentSelectCurrentClassId } from './use-student-state'
import { handleRestartApp } from '../common/handle-restart-app'
import { rvCurrentUser } from '../login/login-state'

export const StudentMain = () => {
    const user = useReactiveVar(rvCurrentUser)
    const currentStudent = useReactiveVar(rvCurrentStudent)
    const studentClasses = useReactiveVar(rvStudentClasses)
    const studentSelectCurrentClassId = useReactiveVar(rvStudentSelectCurrentClassId);
    const classId = studentSelectCurrentClassId || (studentClasses ? studentClasses[0]?.id : "");
    const checkInSessions = useReactiveVar(rvCheckinSessions)

    const { data } = useQuery<GetStudentByCognitoUsernameQuery, GetStudentByCognitoUsernameQueryVariables>(gql`${getStudentByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
    const studentDataResponse = data?.getStudentByCognitoUsername?.items.filter(item => item?._deleted !== true)

    useEffect(() => {
        if (studentDataResponse) {
            rvCurrentStudent(studentDataResponse[0])
        }
    }, [studentDataResponse])

    useRedirectPages()
    useAppState()
    useConnectivity()
    CheckInSessionSubscriptionListener()
    StudentCheckInSubscriptionListenerByStudentId()
    StudentRatingSubscriptionListener()
    StudentClassSubscriptionListenerByStudentId()
    ClassSubscriptionByStudentIdListener(classId)
    StudentInitialLoad(classId)

    // Favourite subscriptions
    const { error: errorFavouriteSubscription } = useSubscription<
        OnMutateFavouriteByCognitoUsernameSubscription,
        OnMutateFavouriteByCognitoUsernameSubscriptionVariables
    >(
        gql`
    ${onMutateFavouriteByCognitoUsername}
  `,
        {
            variables: { cognitoUsername: currentStudent?.cognitoUsername || '' },
            skip: !currentStudent,
            onData: updateListQueryCache<ListFavouriteByCognitoUsernameQuery, ListFavouriteByCognitoUsernameQueryVariables, OnMutateFavouriteByCognitoUsernameSubscription>({ listQuery: listFavouriteByCognitoUsername, listQueryName: 'listFavouriteByCognitoUsername', subscriptionName: 'onMutateFavouriteByCognitoUsername', variables: { cognitoUsername: currentStudent?.cognitoUsername || "" } })
        }
    );

    const { error: errorFindYourCalmActivityRatingSubscription } = useSubscription<
        OnMutateFindYourCalmActivityRatingSubscription,
        OnMutateFindYourCalmActivityRatingSubscriptionVariables
    >(
        gql`
      ${onMutateFindYourCalmActivityRating}
    `,
        {
            variables: { userId: currentStudent?.id || '' },
            skip: !currentStudent,
            onData: updateListQueryCache<ListFindYourCalmByUserIdAndActivityIdQuery, ListFindYourCalmByUserIdAndActivityIdQueryVariables, OnMutateFindYourCalmActivityRatingSubscription>({ listQuery: listFindYourCalmByUserIdAndActivityId, listQueryName: 'listFindYourCalmByUserIdAndActivityId', subscriptionName: 'onMutateFindYourCalmActivityRating', variables: { userId: currentStudent?.id || "" } })
        }
    );

    useSubscription<OnMutateStudentByCognitoUsernameSubscription, OnMutateFavouriteByCognitoUsernameSubscriptionVariables>(
        gql`${onMutateStudentByCognitoUsername}`,
        {
            variables: {
                cognitoUsername: currentStudent?.cognitoUsername || ""
            },
            skip: !currentStudent
        }
    )

    const { error: errorStudentClassSubscription } = useSubscription<OnMutateStudentClassByStudentIdSubscription, OnMutateStudentClassByStudentIdSubscriptionVariables>(gql`${onMutateStudentClassByStudentId}`,
        {
            variables: {
                studentID: currentStudent?.id || ""
            },
            skip: !currentStudent
        })

    const { error: errorListCheckInSessions } = useSubscription<OnMutateCheckInSessionByClassIdSubscription, OnMutateCheckInSessionByClassIdSubscriptionVariables>(gql`${onMutateCheckInSessionByClassId}`,
        {
            variables: {
                classID: classId
            },
            skip: !classId,
            onData: updateListQueryCache<ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables, OnMutateCheckInSessionByClassIdSubscription>({ listQuery: listCheckInSessionsByClassId, listQueryName: "listCheckInSessionsByClassId", subscriptionName: "onMutateCheckInSessionByClassId", variables: { limit: 5000, classID: classId } })
        })

    if (errorFavouriteSubscription !== undefined ||
        errorFindYourCalmActivityRatingSubscription !== undefined ||
        errorStudentClassSubscription !== undefined ||
        errorListCheckInSessions !== undefined) {
        handleRestartApp()
    }

    const currentScreen = useReactiveVar(rvSwitchesScreenState)

    if (!studentSelectCurrentClassId) {
        getCurrentClassIdFromStorageStudent()
    }

    const lastCheckInSession =
        checkInSessions.length > 0
            ? checkInSessions[checkInSessions.length - 1]
            : undefined;
    useCreateRvCache(rvLastCheckinSession, lastCheckInSession);

    useEffect(() => {
        rvSwitchesScreenState(currentScreen)
    }, [useReactiveVar(rvSwitchesScreenState)])

    return (
        <>
            {currentStudent && currentStudent.initialLoginSetup ? (
                <StudentMainOnboardNavigator />
            ) : (
                <StudentMainNavigator />
            )}
        </>
    )
}
