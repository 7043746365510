import { View, TouchableOpacity, StyleSheet } from 'react-native'
import { SwitchRatingType } from '../models'
import { ThumbDown } from '../switches/image-source/thumb-down'
import { ThumbNeutral } from '../switches/image-source/thumb-neutral'
import { ThumbUp } from '../switches/image-source/thumb-up'
import uuid from 'react-native-uuid'
import { rvSuggestionRatingRequired, useStudentState } from './use-student-state'
import { useReactiveVar } from '@apollo/client'
import { rvCurrentClass, rvLastCheckinSession } from '../common/common-state'
import { Storage } from 'aws-amplify';
import { showAlert } from '../common/universal-alert'
import { CardTemplate } from '../switches/common/card-template'
import { rvClassLoginCurrentCheckIn } from '../class-login/class-login-state'
import { useEffect } from 'react'
import { getCurrentCheckinSessionsByClassId } from '../common/helper-api-functions'
import { rvIsLoading } from '../common/loading'


interface Props {
    card: CardTemplate
}


export const StudentRateCardSuggestion = ({ card }: Props) => {


    const currentTime = Date.now()
    const { currentStudent } = useStudentState()
    const currentClass = useReactiveVar(rvCurrentClass)
    const studentLastCheckin = useReactiveVar(rvLastCheckinSession)
    const currentActiveCheckin = useReactiveVar(rvClassLoginCurrentCheckIn)

    useEffect(() => {
        if (!currentActiveCheckin) {
            getCurrentCheckinSessionsByClassId(currentClass?.id || "")
        }
    }, [currentClass])

    const saveRatingSuggestionS3 = async (SwitchRatingType: SwitchRatingType) => {

        try {
            rvIsLoading(true)
            const userType = "STUDENT"

            const fileName = `SwitchRating-${currentTime}-${userType}-${SwitchRatingType}`
            const s3Data = {
                id: uuid.v4().toString(),
                time: currentTime,
                currentStudent: currentStudent,
                userType: userType,
                selection: SwitchRatingType,
                card: card,
                mostRecentCheckIn: currentActiveCheckin,
                lastCheckInSession: studentLastCheckin
            }

            // fire and forget, so don't await
            await Storage.put(fileName, s3Data, {
                level: "private",
                contentType: "application/json",
            });
            showAlert({
                title: "Switch card suggestions",
                message: "Succcessfully sent feedback",
                leftButtonText: 'Ok'
            })
        } catch (error) {
            console.log(error)
        }
        rvIsLoading(false)
    }



    const selectionRating = (SwitchRatingType: SwitchRatingType) => {
        rvSuggestionRatingRequired(false)
        saveRatingSuggestionS3(SwitchRatingType)
        showAlert(undefined)

    }

    return (
        <View>
            <View style={styles.ratingContainer}>
                <View style={styles.iconContainer}>
                    <TouchableOpacity style={styles.iconSVGContainerLike} onPress={() => selectionRating(SwitchRatingType.LIKE)}>
                        <ThumbUp />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.iconSVGContainerNeutral} onPress={() => selectionRating(SwitchRatingType.NEUTRAL)}>
                        <ThumbNeutral />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.iconSVGContainerDislike} onPress={() => selectionRating(SwitchRatingType.DISLIKE)}>
                        <ThumbDown />
                    </TouchableOpacity>
                </View>
            </View>
        </View>

    )
}

const styles = StyleSheet.create({
    ratingContainer: {
        justifyContent: 'center',
        alignContent: 'center',
        borderRadius: 8,
        width: 200,
        height: 75,
    },
    iconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    heading: {
        textAlign: 'center',
        fontSize: 18,

    },
    iconSVGContainerLike: {
        width: 40,
        height: 40,
    },
    iconSVGContainerNeutral: {
        width: 40,
        height: 40,
    },
    iconSVGContainerDislike: {
        width: 40,
        height: 40,
    }
})