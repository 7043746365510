import { View } from 'react-native'
import { DefaultText } from '../../../../common/default-text'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import DefaultTextWithCustomLink from '../../../common/default-text-with-custom-link'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { GratitudeCardsIcon } from '../../icons/gratitude-cards-icon'
import { GratitudeCardsIconInverted } from '../../icons/gratitude-cards-icon-inverted'
import { Worksheets } from '../../../../common/worksheets-imports'

export const SwitchesActivitiesFunAndPositiveGratitudeCards: CardTemplate = {
    cardId: "07c1e0f5-63b6-4a12-864f-470c7b2aadaa",
    title: 'Gratitude cards',
    Header: GratitudeCardsIconInverted,
    Icon: GratitudeCardsIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Implementing gratitude into the daily routine."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Print out the sets of cards in the additional resources. Alternatively write out the responses on other paper."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"An envelope or similar for each student."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Designate some time each day/week to challenge the students to think of something that they have been grateful for that day/week. "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Encourage them to think carefully and respond honestly. Remind them that everything is kept confidential."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Collect the responses and keep the cards safe."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"When there are several responses for each student (say at the end of the week or term). Hand back to the students what they have written and give them time to reflect on those things."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"They can then then keep their responses."} />

        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.FUN_AND_POSITIVE,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Additional Resources:"} />
            <DefaultTextWithCustomLink text={Worksheets.gratitudeCards.label} link={Worksheets.gratitudeCards.uri} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={""} />
            <DefaulTextWithTipsIcon textdata={<DefaultText>Tip - Extensions to this activity are <DefaultTextWithCustomLink indent={0} text='Dear diary' link={`/Card?id=27f6a28b-47ba-4298-86b3-edfd0e449986&previousScreen=explore&openLessonPlan=false`} /> and <DefaultTextWithCustomLink indent={0} text='Journaling' link={`/Card?id=a6a85162-e61a-40ec-9e5f-01cecd376589&previousScreen=explore&openLessonPlan=false`} /> (remember to check out the associated lesson plans) where the process of reflection can assist with identifying things to be grateful for.</DefaultText>} />
        </>

    ),
    audioUrl: "",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.FUN_AND_POSITIVE],
    action: [CardActionType.Action, CardActionType.Thought],
    level: []

}