import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { ToolsTheLittleBookOfBigEmotions } from '../tools/cards/the-little-book-of-big-emotions'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'


export const SwitchesLessonPlansFeelingsVolcano: CardTemplate = {
    cardId: "c60f3ce3-0809-46df-80b6-6fb3672d2bbf",
    title: 'Recognising emotional intensity',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the concept of overreacting and the importance of managing their emotions appropriately.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn to recognise and express their feelings in a balanced way using the metaphor of a volcano. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 30 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (2 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by asking the students if they've heard of or seen a volcano. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that today, they will learn about their emotions using the metaphor of a volcano to help them understand how to manage their feelings.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Discussion (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Define what it means to “erupt” emotionally.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain there are times when erupting would be considered as "overreacting" depending on the situation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Engage the students in a discussion by asking them about times when they or someone they know might have "erupted" emotionally over a minor situation. A younger sibling having a tantrum for example.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reassure students that having big feelings is okay, and the focus in this session is learning about the escalation process so we can better control how we react in different situations.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity - The Feelings Volcano (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show the students the feelings volcano poster</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide each student with the feelings volcano worksheet</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss each level of the volcano asking the students to talk about how it looks and feels.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students select which feeling statement is correct for each level and stick it in the correct place.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Students can draw, copy or write how they on their worksheet for each level.</DefaultText>

                        <View style={styles.levelsContainer}>
                            <DefaultText style={CommonStyles.lessonPlanText}>Level 1: Calm</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>I feel happy, peaceful, or relaxed.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>Level 2: Heating up </DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>I feel content, a little excited, or cheerful.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>Level 3: Active </DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>I feel excited, a bit upset, or energised.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>Level 4: Steamy </DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>I feel very upset, frustrated, or agitated.</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>Level 5: Erupted</DefaultText>
                            <DefaultText style={CommonStyles.lessonPlanText}>I feel extremely angry, out of control, or overwhelmed.</DefaultText>
                        </View>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (3 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Talk about the importance of recognising and managing their emotions appropriately, just like a volcano that doesn't erupt over small matters.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to reflect on how they can prevent their feelings from "erupting" inappropriately. (refer to switches as appropriate examples) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to remember the lesson of the "Feelings Volcano" and practice managing their emotions in a balanced way.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Homework (Optional):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to keep a "Feelings Volcano" journal for a week. In their journals, they can write or draw about different situations and the emotions they felt. Encourage them to reflect on whether their emotional responses matched the situation appropriately, just like the level of a calm or erupting volcano. This reinforces the concept outside the classroom and helps them practice emotional awareness. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Feelings volcano <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='worksheet' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/F10+the+feelings+volcano.pdf' /> and <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='poster' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/F10+the+feelings+volcano+Poster.pdf' /> (cut out the feelings statements)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Writing materials</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Glue/sticky tape/sticky putty</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='The Little Book of Big Emotions' link={ToolsTheLittleBookOfBigEmotions.openUrl || ""} /></DefaultText>
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    levelsContainer: {
        marginHorizontal: 10,
        gap: 5,
    },
})
