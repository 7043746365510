import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CharadesHeaderIcon } from '../../icons/charades-header-icon'
import { CharadesIcon } from '../../icons/charades-icon'

export const SwitchesActivitiesCreativityCharades: CardTemplate = {
    cardId: "3e092b9a-e927-49ee-b382-08170c9ca9be",
    title: 'Charades',
    Header: CharadesHeaderIcon,
    Icon: CharadesIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"A fun game that promotes teamwork, communication, confidence, and perspective taking while enhancing vocabulary and patience."} />

            <DefaultTextWithoutArrows textdata='Charades is a popular word guessing game where players act out a word or phrase without speaking while others try to guess what it is. The game is usually played in teams, but it can also be played individually. While there may be variations in the specific rules, here are the general guidelines for playing charades: ' />

            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Divide into teams: Form two or more teams with an equal number of players. Each team should have a mix of actors and guessers."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Prepare word/phrase cards: Write down various words or phrases on small pieces of paper or index cards. These can be objects, actions, movie titles, book titles, quotes, etc. Fold the cards and place them in a container, such as a bowl or hat."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Select a team to start: One team starts by selecting an actor who will act out the word or phrase."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Time limit: Set a time limit for each round, typically around one to three minutes."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Choose a card: The actor from the starting team picks a card from the container without revealing it to anyone else."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Begin acting: Once the timer starts, the actor begins to silently act out the word or phrase using gestures, body movements, and facial expressions. Remember, no speaking or sounds are allowed."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Guessing: The actor's team members try to guess the word or phrase within the time limit. They can call out their guesses or use pre agreed upon signals, such as raising their hand."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Correct guess: If the team correctly guesses the word or phrase before time runs out, they earn a point."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Passing: If the team is unable to guess the word or phrase, they can pass, and the opposing team gets a chance to guess."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Alternating turns: After a turn is over, the play moves to the next team in a clockwise or predetermined order. Each team takes turns providing an actor and guessing."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Scoring: Keep track of the points earned by each team. The team with the highest score at the end of the game wins."} />

        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.CREATIVITY,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata="Actors should focus on conveying the essence of the word or phrase, using exaggerated movements and pantomime." />
            <DefaulTextWithTipsIcon textdata="Avoid using words, letters, or numbers as part of your gestures or actions." />
            <DefaulTextWithTipsIcon textdata="It's important to maintain silence during acting. No hints or noises are allowed." />
            <DefaulTextWithTipsIcon textdata="For larger groups, consider having a designated person as a moderator to keep track of time and ensure fair play." />
        </>

    ),
    audioUrl: "",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.CREATIVITY],
    action: [CardActionType.Action],
    level: []

}

