import { Activities } from '../../common/activities'
import { ActivityGroupTemplate } from '../../common/activity-group-template'

import { SeparationAnxietyIcon } from '../icons/separation-anxiety-icon'

export const SwitchesActivityGroupSeparationAnxiety: ActivityGroupTemplate = {
    title: 'Separation anxiety',
    Icon: SeparationAnxietyIcon,
    activity: Activities.SEPARATION_ANXIETY,
    subtitle: ""
}
