import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import { View } from "react-native"
import { DecidaColors } from "../../common/decida-colors"
import { DefaultButton } from "../common/default-button"
import { ScreenNames } from "../common/screen-names"
import { showAlert } from "../common/universal-alert"
import { AdminStackNavigatorParamList } from "./admin-route-param-types"
import { School, UpdateClassMutation, UpdateClassMutationVariables, UpdateSchoolMutation, UpdateSchoolMutationVariables } from "../../common/API"
import { gql, useMutation } from "@apollo/client"
import { mutateClassRefetchQueries as refetchQueries, updateClassMutation, updateSchoolMutation } from "./graphql-scripts"

type Props = {
    school: School
}

export const AdminSchoolFunctions = ({ school }: Props) => {
    const [updateSchool] = useMutation<UpdateSchoolMutation, UpdateSchoolMutationVariables>(gql`${updateSchoolMutation}`, { refetchQueries })
    const [updateClass] = useMutation<UpdateClassMutation, UpdateClassMutationVariables>(gql`${updateClassMutation}`, { refetchQueries })
    const { navigate } = useNavigation<StackNavigationProp<AdminStackNavigatorParamList>>()

    const archiveClasses = () => {
        showAlert({
            title: 'Archive Classes',
            message: 'This action cannot be undone, are you sure you want to archive all the classes?',
            leftButtonText: 'Cancel',
            rightButtonText: 'Archive',
            onRightButtonPress: async () => {
                if (school.classes && school.classes.items.length > 0) {
                    school.classes.items.forEach(async clazz => {
                        if (clazz == null || clazz.archived || clazz._deleted) {
                            return
                        }

                        await updateClass({
                            variables: {
                                input: {
                                    id: clazz.id,
                                    archived: true,
                                    _version: clazz._version,
                                }
                            }
                        })
                    })
                }
            }
        })
    }

    const goToImportPage = () => {
        navigate(ScreenNames.AdminSchoolImportTeachers, { schoolID: school.id })
    }

    const goToNewImportPage = () => {
        navigate(ScreenNames.AdminSchoolImports, { schoolID: school.id })
    }

    const goToStudentListPage = () => {
        navigate(ScreenNames.AdminSchoolStudentList, { schoolID: school.id })
    }

    return (
        <View>
            <DefaultButton onPress={archiveClasses} style={{ backgroundColor: DecidaColors.Orange }}>
                Archive Classes
            </DefaultButton>
            <DefaultButton onPress={goToImportPage} style={{ backgroundColor: DecidaColors.Orange }}>
                Import School Data
            </DefaultButton>
            <DefaultButton onPress={goToNewImportPage} style={{ backgroundColor: DecidaColors.Orange }}>
                Import Hierarchy School Data
            </DefaultButton>
            <DefaultButton onPress={goToStudentListPage} style={{ backgroundColor: DecidaColors.Orange }}>
                Get Student List
            </DefaultButton>
        </View>
    )
}