import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { BeSocialIcon } from '../../explore/src/be-social-icon'
import { BeSocialIconInverted } from '../../explore/src/be-social-icon-inverted'
import { BeSocialImage1 } from './src/be-social-image1'
import { BeSocialLessonPlanImage1 } from './src/be-socical-lessonplan-image1'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'

export const SwitchesEmotionsSadBeSocial: CardTemplate = {
    cardId: "e9d3b70d-606f-4f88-a870-b1c45bd5ee66",
    title: 'Be social',
    Header: BeSocialIconInverted,
    Icon: BeSocialIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <BeSocialImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Those times when you are feeling sad and find yourself drawing away from others and avoiding social situations. "} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Think of someone or a group of people you’d like to spend time with that you know will be supportive and kind while you are feeling down."} />
                <DefaultTextWithDownArrow textdata={"Consider what environment you’d like to be in with them, eg. playing sport, going to a party or eating lunch. "} />
                <DefaultTextWithDownArrow textdata={"Invite them to hang out (or accept an invitation from them)."} />
                <DefaultTextWithDownArrow textdata={"Try to participate in the activities and conversations, but know that it’s okay to leave if you need to."} />
                <DefaultTextWithoutArrows textdata={"If you feel comfortable, try talking about your feelings."} />

            </View>
        </>
    ),
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: 'Our brains are wired for social connection, we are designed to live in groups, in tribes. Being with others makes us feel safe and secure, and releases feel good chemicals in the brain. Talking to other people can make you feel less alone, give you something to focus on other than your worries, and can positively affect your mood.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Discuss the benefits of building strong relationships.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify and list the people in their circle of influence they can rely on to support them through negative emotions.   </DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explanation of Social Connections: Explain that the number and strength of relationships directly impact mental and physical wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Share research findings on how strong, healthy relationships contribute to a strengthened immune system and overall longevity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of relationship circles using the "Who is in my relationship circles?" worksheet. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Instruction (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the different relationship circles:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Inner circle: Strongest supporters (e.g., parents, grandparents).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Second ring: People known well and trusted (e.g., best friends).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Third ring: People seen regularly, not close friends (e.g., teachers, classmates).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Outer ring: People paid to support (e.g., doctor, dentist).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to privately write the names of people in their relationship circles on the worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that this is a personal reflection and not meant for sharing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reflection on Relationship Circles: Ask students to consider if they would like to add more or different people to their relationship circles.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to make notes on the worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss briefly the idea that relationships can evolve and change.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Group Activity (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Brainstorm with students ways to build or strengthen relationships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage ideas such as spending more time together, joining a team or community group, talking to teachers/counsellors/parents, and introducing themselves to new friends.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write ideas on the whiteboard.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Switch4Schools App and Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the Be Social switch in the Switch4Schools app.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that spending time with the support network when feeling sad can be an effective switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, ask them to find and explore the Be Social switch in the app.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion and Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on the activity and share any insights they gained.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a brief discussion on the importance of maintaining and building positive relationships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students that relationships are dynamic and can be nurtured over time.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to implement one or more of the brainstormed strategies to build or strengthen a relationship in their life and share their experiences in the next class.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Who is in my relationship circles? worksheet' link={Worksheets.relationShipCircle.uri} /> </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Does being more social make us happier -  </DefaultText>
                        <DefaultTextWithLink text='https://www.psychologytoday.com/au/blog/the-mindful-self-express/201912/does-being-more-social-make-us-happier' />

                        <DefaultText style={CommonStyles.lessonPlanText}>What are the health benefits of being social? </DefaultText>
                        <DefaultTextWithLink text='https://www.medicalnewstoday.com/articles/321019' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Strong relationships, strong health - </DefaultText>
                        <DefaultTextWithLink text='https://www.betterhealth.vic.gov.au/health/healthyliving/Strong-relationships-strong-health' />

                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Start small — If you haven’t talked to anyone in a few days, it may do more harm than good to be in a crowd if you don’t have to. Focus on small efforts, rather than submerging yourself in a room full of people' />
            <DefaulTextWithTipsIcon textdata='Make a phone call — Call someone you trust such as a parent, friend, or relative. Usually, it just takes a quick 10-minute phone call to start elevating mood.' />
            <DefaulTextWithTipsIcon textdata='Go on a walk — If you go on a walk in your neighbourhood, the park, or someplace that isn’t too crowded, you will be able to be around people without having to directly talk to them.' />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Be+Social.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Sad]
}
