import * as ExpoLinking from 'expo-linking';
import * as Papa from 'papaparse';
import { Share } from 'react-native';
import { rvLoginErrorMesage, rvRedirectPages } from '../login/login-state';
import { cardsPublic } from '../switches/emotion-cards/cards';
import { SwitchesParamList } from '../switches/navigation/switches-param-list';
import { ScreenNames } from './screen-names';
import { ScreenNamesPublic } from './screen-names-public';
import { openUrlWebView } from '../web-view/open-url-web-view';

export const hasData = (data: any) => {
    if (data == null) {
        return false
    }

    if (typeof data === 'string' && data === '') {
        return false
    }

    if (Array.isArray(data) && data.length === 0) {
        return false
    }

    return true
}

export const linkingOpenUrl = (url: string) => {
    openUrlWebView(url || "")
}

export const csvFileToJson = async (data: File | undefined) => {

    return await new Promise((resolve, reject) => {
        if (data) {
            try {
                Papa.parse(data, {
                    complete: function (results: any, file: File) {
                        console.log('parsing complete read', results);
                        resolve(results)
                    },

                });

            } catch (error) {
                reject("Failed to parsed data, please try again")
            }
        }
    })
}

export const generatedNewDuplicatedUsername = (currentUsername: string) => {
    const formattedName = `${currentUsername}-1`

    return formattedName
}

export const upperCaseFirstLater = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export const extractEmailFront = (email: string) => {
    return email.split('@')[0];
}

export const appendNamePrefix = (str: string): string => {
    let result = str;

    for (let i = 1; i <= 15; i++) {
        if (result.indexOf(`-${i}`) === -1) {
            result += `-${i}`;
            break;
        }
    }

    return result;
}

export const isUrlPublicAccess = (url: string | null): boolean => {
    // Check if url is null or falsy. If so, return false.
    if (!url) {
        return false
    };

    // Parse URL path and query parameters if the link is handle by linking config using Linking.useUrl() hooks
    const { path, queryParams } = ExpoLinking.parse(url)

    // Extract route name and params from orginal URL
    const { routeName, params } = getRouteNameAndParamsFromUrl(url)

    // Initialize isPublic flag to true
    let isPublic = true
    // If the route name is "Card", check if the corresponding card is public
    if (routeName === ScreenNames.Card) {
        isPublic = cardsPublic.some((card) => card.cardId === params?.cardId)
    }

    // If the query parameter "openLessonPlan" is true, set isPublic flag to false
    if (queryParams?.openLessonPlan && queryParams?.openLessonPlan === 'true') {
        isPublic = false
    }

    // If page is not public, show a login error messages and save URL for redirecting
    if (!isPublic) {
        rvLoginErrorMesage("The page you’re trying to access requires a login.")
        rvRedirectPages(url)
        return false
    }

    // Split URL into segments and get all public URLs
    const splitedUrls = url.split('/'); // split URL into segments
    const publicUrls = Object.values(ScreenNamesPublic); // get all public URLs

    // Check if the url is public page
    isPublic = publicUrls.some((publicUrl) =>
        splitedUrls?.some((splitUrl) => splitUrl === publicUrl) ||  // Check if the segment of the URL matches any of the public URLs
        path === publicUrl // Also check if the path of the URL matches any of the public URLs
    )

    // Check if the url is public page
    return isPublic
};

export const getRouteNameAndParamsFromUrl = (url: string | null): { routeName: string | keyof SwitchesParamList | undefined, params: ExpoLinking.QueryParams } => {
    const emptyRoute = { routeName: "/", params: {} }
    if (!url) return emptyRoute; // if url is falsy, return false immediately
    const { path, queryParams } = ExpoLinking.parse(url)

    if (!path) return emptyRoute;

    const splitedUrls = path.split('/'); // split URL into segments
    const publicUrls = Object.values(ScreenNamesPublic); // get all public URLs

    // check if the final segment of the URL is a public URL
    let routeName = publicUrls.find((publicUrl) =>
        splitedUrls?.some((splitUrl) => splitUrl === publicUrl)
    );

    let params: ExpoLinking.QueryParams = { ...queryParams }

    if (routeName === ScreenNames.SwitchesEmotionsDetailView) {
        params = {
            emotion: splitedUrls[1]
        }
    }

    if (routeName === ScreenNames.Card) {
        params = { cardId: String(queryParams?.id), openLessonPlan: String(queryParams?.openLessonPlan || false) }
    }

    if (routeName === ScreenNames.SwitchesMemes) {
        const memeId = splitedUrls[1]
        params = { meme: memeId }
    }

    if (routeName === ScreenNames.TeacherClassMain) {
        params = {
            ...params,
            ...queryParams
        }
    }

    return {
        routeName,
        params
    }
};

export const handleShareUrl = async ({ url }: { url: string }) => {
    try {

        if (url) {
            await Share.share({
                message: url,
                url,
            });
        }
    } catch (error) {
        console.log('An unknown error occurred', error)
    }
}