import { createStackNavigator } from '@react-navigation/stack'
import { ScreenNames } from '../common/screen-names'
import { StudentJoinClassStackNavigatorParamList } from './login-route-param-types'
import { StudentJoinClass } from './student-join-class'
import { StudentJoinClassSelectAvatar } from './student-join-class-select-avatar'
import { StudentJoinClassSetDetails } from './student-join-class-set-details'
import { StudentJoinClassReviewDetails } from './student-join-class-review-details'
import { StudentJoinClassFinished } from './student-join-class-finished'
import { StudentJoinClassLogin } from './student-join-class-login'
import { StudentJoinClassNewExistingStudent } from './student-join-class-new-existing-student'

const Stack = createStackNavigator<StudentJoinClassStackNavigatorParamList>()

export const StudentJoinClassMain = () => {

    return (
            <Stack.Navigator screenOptions={{ gestureEnabled: false, headerShown: false }}>
                <Stack.Screen name={ScreenNames.studentJoinClass} component={StudentJoinClass} />
                <Stack.Screen name={ScreenNames.studentJoinClassPickAvatar} component={StudentJoinClassSelectAvatar} />
                <Stack.Screen name={ScreenNames.studentJoinClassSetDetails} component={StudentJoinClassSetDetails} />
                <Stack.Screen name={ScreenNames.studentJoinClassReviewDetails} component={StudentJoinClassReviewDetails} />
                <Stack.Screen name={ScreenNames.studentJoinClassFinished} component={StudentJoinClassFinished} />
                <Stack.Screen name={ScreenNames.studentJoinClassLogin} component={StudentJoinClassLogin} />
                <Stack.Screen name={ScreenNames.studentJoinClassNewExistingStudent} component={StudentJoinClassNewExistingStudent} />
            </Stack.Navigator>
    )
}
