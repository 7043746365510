import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import VideoCard from '../../../common/video-card'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { FiveWhysHeader } from './src/five-whys-header'
import { FiveWhysIcon } from './src/five-whys-icon'
import { FiveWhysLessonPlan } from './src/five-whys-lesson-plan'
import { Worksheets } from '../../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import SwitchCardVideo from '../../../common/switch-card-video'

const fiveWhysVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/SrlYkx41wEE",
    title: ""
}

export const SwitchesEmotionsAngryFiveWhys: CardTemplate = {
    cardId: "8ecfd90c-523f-4dc1-94b1-75cd1f35a710",
    title: '5 whys',
    Header: FiveWhysHeader,
    Icon: FiveWhysIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"When you are feeling angry at something, and you want to understand what caused it and how you can stop it from happening again."} />
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Picture clearly in your head what is making you angry, trying to be specific."} />
                <DefaulttextWithRightArrow textdata={"Ask yourself five WHY questions about your situation each flowing on from the next e.g."} />
                <DefaultText style={styles.withIndent} >i. Why am I angry right now? </DefaultText>
                <DefaultText style={styles.withIndent} >    • I’m hungry and I forgot my <DefaultText style={styles.subText}>lunch</DefaultText></DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaultText style={styles.withIndent} >ii. Why did I forget my lunch?</DefaultText>
                <DefaultText style={styles.withIndent} >    • Because I was <DefaultText style={styles.subText}>rushing this morning</DefaultText></DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaultText style={styles.withIndent} >iii. Why was I rushing this morning? </DefaultText>
                <DefaultText style={styles.withIndent} >    • Because <DefaultText style={styles.subText}>I slept in</DefaultText></DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaultText style={styles.withIndent} >iv. Why did I sleep in? </DefaultText>
                <DefaultText style={styles.withIndent} >    • Because my <DefaultText style={styles.subText}>alarm didn’t go off</DefaultText></DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaultText style={styles.withIndent} >iv. Why didn’t my alarm go off? </DefaultText>
                <DefaultText style={styles.withIndent} >    • Because my <DefaultText style={styles.subText}>phone wasn’t charged.</DefaultText></DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaulttextWithRightArrow textdata={"Decide on possible solutions to the cause of the problem"} />
            </View>
            <DefaulTextWithTipsIcon textdata="Sometimes it is the actions of others that are making you angry. Make sure that your answers are not about them, but about you e.g. don’t say, Why did they say those mean things? Because they are bad people. Rather, Why did they say those mean things? Because they were trying to get a reaction out of me. " />
            <DefaulTextWithTipsIcon textdata="If you can’t figure out some questions or answers ask a teacher to help brainstorm some with you un till you figure them out. " />
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    cardContentTemplate: 'column',
    BottomRow: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: `The 5 why’s is a logical and systematic way for us to get past our surface emotions and reaction to things, and discover the reasons why these things trigger us so much. By focusing on understanding and addressing the root cause of the problem, rather than just the surface-level symptoms, individuals can feel more in control of the situation and may be less likely to become angry or frustrated.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Describe and apply the 5 Whys technique to identify the root cause of a problem.</DefaultText>
                    </>
                }
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='5 Whys technique worksheet' link={Worksheets.fiveWhys.uri} /></DefaultText>
                    </>
                }
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>DETERMINE THE ROOT CAUSE: 5 WHYS</DefaultText>
                        <DefaultTextWithLink text='https://www.isixsigma.com/tools-templates/cause-effect/determine-root-cause-5-whys/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>5 why’s Brainstorm and Analysis</DefaultText>
                        <DefaultTextWithLink text='https://www.atlassian.com/team-playbook/plays/5-whys#instructions' />

                        <DefaultText style={CommonStyles.lessonPlanText}>5 why’s Video</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text={"Clarifying the '5 Whys' Problem-Solving Method"} link='https://www.youtube.com/watch?v=SrlYkx41wEE' />
                        <SwitchCardVideo video={fiveWhysVideo} />
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to brainstorm a list of problems they have encountered in their daily lives (e.g., forgetting homework, not finding a favourite toy).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write the list of problems on the board.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Prompt students to suggest solutions for each problem.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the 5 Whys technique as a method for identifying the root cause of a problem.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write on the board or present the 5 Whys Worksheet:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- What is the problem?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Why is that? (1)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Why is that? (2)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Why is that? (3)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Why is that? (4)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Why is that? (5)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- What is the solution/action to take?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide an example or present the example in the 5 Whys Switch.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write a problem on the board (e.g., "Why is the classroom always messy?").</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students work in pairs or small groups to use the 5 Whys technique to identify the root cause of the problem.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After 5 minutes, ask groups to share their findings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss and identify the root cause of the problem as a class, using it as an example for the lesson.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the 5 Whys worksheet to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to use the 5 Whys technique individually to identify the root cause of an issue they have experienced.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage self-questioning and brainstorming.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students share their findings with a partner or the class. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Review the steps of the 5 Whys technique: identify the problem, ask "why" five times, identify a solution.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss examples of problems from students' lives and how the 5 Whys technique could be applied.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that the 5 Whys is a valuable tool for understanding and solving problems.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer students to the 5 Whys switch on the Switch4Schools app.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to apply the 5 Whys technique to a problem they encounter outside of class and share their experience in the next session.</DefaultText>

                    </>}
            />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/five+WHYS+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Angry]
}

const styles = StyleSheet.create({
    subText: {
        fontWeight: 'bold',
    },
    withIndent: {
        paddingLeft: 20
    }
})
