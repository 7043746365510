import { View, TouchableOpacity, SafeAreaView, StyleSheet } from "react-native"
import { useReactiveVar } from '@apollo/client'
import { rvSwitchesScreenState } from "../switches/common/current-screen-state";
import { SwitchesScreens } from "../switches/navigation/switches-bottom-navigation";
import { useNavigation } from "@react-navigation/native";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { rvCurrentStudent } from "../student/use-student-state";
import { avatars } from "../icon/avatar/avatars";
import { DefaultFontSize } from "../common/const";
import { DefaultText } from "../common/default-text";
import { headerHeight } from "../common/header";
import { logout } from "../common/logout";
import { ScreenNames } from "../common/screen-names";
import { rvShowPopoverStudent } from "../common/pop-over-menu-state";
import { StudentMainNavigatorParamList } from "./student-route-param-types";
import { StudentClassSelector } from "../common/student-class-selector";
import { CLIENT_EVENT, createClientLog } from "../common/log-client-event";
import { versionNumberString } from "../env";
import { DecidaColors } from "../../common/decida-colors";
import AvatarImage from "../common/avatar-image";


export const StudentPopOverMenu = () => {

    const showPopover = useReactiveVar(rvShowPopoverStudent)
    const currentStudent = useReactiveVar(rvCurrentStudent)
    const Avatar = avatars[currentStudent?.avatar as keyof typeof avatars]
    const closePopup = () => {
        rvShowPopoverStudent(false)
    }
    const { navigate } = useNavigation<DrawerNavigationProp<StudentMainNavigatorParamList>>()

    const logoutHandler = () => {
        rvShowPopoverStudent(false)
        rvSwitchesScreenState(SwitchesScreens.HOME)
        logout()
        createClientLog({
            event: CLIENT_EVENT.USER_SIGNED_OUT,
            area: "Popover menu",
            action: "Clicking logout button, and sign out",
            payload: {
                group: "student",
                nickname: currentStudent?.cognitoUsername,
            }
        })
    }
    const navigateToProfile = () => {
        rvShowPopoverStudent(false)
        rvSwitchesScreenState(SwitchesScreens.HOME)
        navigate(ScreenNames.StudentProfileMain)
    }

    if (!currentStudent) {
        return null
    }

    return showPopover ? (
        <TouchableOpacity style={styles.overlay} onPress={closePopup}>
            <SafeAreaView />
            <View style={styles.avatarpopover}>
                <View style={styles.classselector}>
                    <StudentClassSelector />
                </View>
                <View style={styles.profile}>
                    <TouchableOpacity onPress={navigateToProfile} style={styles.dividerButton}>
                        <View style={styles.profileContainer}>
                            <View style={styles.avatarContainer}>
                                <AvatarImage avatar={currentStudent?.avatar} size={40} />
                            </View>
                            <DefaultText style={styles.logouttext}>
                                Profile
                            </DefaultText>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={styles.logout}>
                    <TouchableOpacity testID="logout-button" onPress={logoutHandler} style={styles.dividerButton}>
                        <DefaultText style={styles.logouttext}>
                            Logout
                        </DefaultText>
                    </TouchableOpacity>
                </View>
                <View style={styles.logout}>
                    <View style={styles.profileContainer}>
                        <DefaultText style={styles.buildVersion}>
                            {versionNumberString}
                        </DefaultText>
                    </View>
                </View>

            </View>
        </TouchableOpacity>
    ) : null
}


const styles = StyleSheet.create({
    overlay: {
        position: 'absolute',
        zIndex: 99,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    avatarpopover: {
        alignItems: 'flex-end',
        width: 300,
        minHeight: 200,
        maxHeight: 500,
        zIndex: 500,
        marginRight: 2,
        backgroundColor: DecidaColors.White,
        borderColor: 'grey',
        borderRadius: 5,
        borderWidth: 0.5,
        marginTop: headerHeight
    },
    logout: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    profile: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    logouttext: {
        marginRight: 15,
        color: DecidaColors.Gray,
        alignItems: 'flex-end',
        justifyContent: 'center',
        fontSize: DefaultFontSize - 2,

    },
    buildVersion: {
        marginRight: 15,
        fontSize: DefaultFontSize - 2,
        color: DecidaColors.AppleSystemGray4Light,
    },
    emailbutton: {
        width: '100%',
        height: 65,
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginBottom: 15
    },
    currentteachermemail: {
        marginTop: 10,
        marginRight: 15,
        fontSize: DefaultFontSize - 4,
        color: DecidaColors.Gray,
    },
    emailtext: {
        marginTop: 10,
        marginRight: 15,
        fontSize: DefaultFontSize - 2,
        color: DecidaColors.Gray,

    },
    classselector: {
        padding: 10,
    },
    dividerButton: {
        width: "100%",
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    avatarContainer: {
        marginHorizontal: 10,

    },
    profileContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    }

})
