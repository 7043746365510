import { StyleSheet, View } from 'react-native'

export const Divider = () =>
    <View style={styles.divider} />

const styles = StyleSheet.create({
    divider: {
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        height: 0,
        marginTop: 10,
        marginBottom: 10,
    }
})