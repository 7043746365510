import { useReactiveVar } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { DefaultText } from '../../common/default-text'
import { ScreenNames } from '../../common/screen-names'
import { useResponsive } from '../../common/use-responsive'
import { getLockDerviceRv, rvUserGroup } from '../../login/login-state'
import { TeacherDrawerNavigatorPamarList } from '../../teacher/teacher-route-param-types'
import { tileSizes } from '../common/title-sizes'
import { toolcards, toolcardsPublic } from '../emotion-cards/cards'
import { DecidaColors } from '../../../common/decida-colors'
import { UserGroup } from '../../../common/constants'
import { openUrlWebView } from '../../web-view/open-url-web-view'

export const SwitchesToolsDisplayAll = ({ navigation: { navigate, goBack }, }: StackScreenProps<TeacherDrawerNavigatorPamarList, 'SwitchesToolsDisplayAll'>,) => {

    const { width, isLargeScreen } = useResponsive()
    const { isNarrowScreen } = useResponsive()

    const userGroup = useReactiveVar(rvUserGroup)
    const denyAccess = useReactiveVar(getLockDerviceRv())
    

    let currentToolCards = toolcards;

    switch (userGroup) {
        case UserGroup.Student:
        case UserGroup.ClassLogin:
        case UserGroup.NonTeachingStaff:
        case undefined:
            currentToolCards = toolcardsPublic
            break
        default:
            currentToolCards = denyAccess ? toolcardsPublic : toolcards
            break;
    }

    return (
        <>

            <ScrollView style={styles.scrollview} contentContainerStyle={[styles.container, { padding: isNarrowScreen ? 5 : 40 }]} >
                {
                    currentToolCards?.map((Tools) => {
                        return (
                            <TouchableOpacity key={Tools.title} style={[tileSizes.item, { margin: 10, width: isLargeScreen ? width / 8 : width / 4, minWidth: 150 }]} onPress={() => {
                                if (Tools.openUrl) {
                                    openUrlWebView(Tools.openUrl)
                                    return
                                }
                                navigate(ScreenNames.Card, { id: Tools.cardId, previousScreen: 'tools' })
                            }}>
                                <View style={[tileSizes.tile, { height: 100, width: 100, justifyContent: 'center', alignItems: 'center' }]}>
                                    <Tools.Icon />
                                </View>
                                <DefaultText style={styles.cardtext}>
                                    {Tools.title}
                                </DefaultText>
                            </TouchableOpacity>
                        )
                    }
                    )
                }
            </ScrollView>
        </>

    )
}


const styles = StyleSheet.create({

    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        alignSelf: 'center',
        paddingBottom: 20,
        maxWidth: 1800,
    },
    content: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: "100%"

    },
    image: {
        width: 350,
        height: 450,
    },
    header: {
        fontSize: 32,
        fontWeight: 'bold',
        color: DecidaColors.Gray,
        textAlign: 'center',
    },
    item: {
        margin: 30,
        width: 175,
        height: 225,
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'center'
    },
    scrollview: {
        // flexGrow: 0,

        height: '100%',

    },
    cardtext: {
        fontSize: 18,
        textAlign: 'center'
    },
    cardicon: {
        width: 175,
        height: 175,
    },
    back: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    }

})


