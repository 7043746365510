import { OnDataOptions, gql } from "@apollo/client"
import { GetStudentClassByClassIdQueryVariables, OnMutateStudentClassByClassIdSubscription } from "../../common/API"
import { ListStudentClassAndStudentDataByClassIdCustomQuery, listStudentClassAndStudentDataByClassIdCustomQuery } from "../teacher/teacher-graphql-scripts"

export const insertNewItemIntoListStudentClassAndStudentDataByClassIdCustomQuery = ({ classID }: { classID: string }) => ({ data, client }: OnDataOptions<OnMutateStudentClassByClassIdSubscription>) => {

    if (!data.data?.onMutateStudentClassByClassId) return

    const currentStudentClassData = client.readQuery<ListStudentClassAndStudentDataByClassIdCustomQuery, GetStudentClassByClassIdQueryVariables>({
        query: gql`${listStudentClassAndStudentDataByClassIdCustomQuery}`,
        variables: {
            classID,
            limit: 5000
        }
    })

    // find studentClass that coming from subscription data
    const existigStudentClassDataIndex = currentStudentClassData?.getStudentClassByClassId?.items.findIndex((studentClass) => studentClass?.id === data.data?.onMutateStudentClassByClassId?.id)

    if (existigStudentClassDataIndex !== undefined && existigStudentClassDataIndex >= 0) return

    const existingStudentClasses = currentStudentClassData?.getStudentClassByClassId?.items

    client.writeQuery({
        query: gql`${listStudentClassAndStudentDataByClassIdCustomQuery}`,
        variables: {
            classID,
            limit: 5000
        },

        data: {
            ...currentStudentClassData,
            getStudentClassByClassId: {
                ...currentStudentClassData?.getStudentClassByClassId,
                items: [...existingStudentClasses || [], data.data.onMutateStudentClassByClassId]
            }
        }
    })
}