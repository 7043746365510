import Svg, { Defs, ClipPath, Path, G } from 'react-native-svg'

export const IconMenuBar = () => (
    <Svg width="24.827" height="21.277" viewBox="0 0 24.827 21.277">
        <Defs>
            <ClipPath id="clip-path">
                <Path id="Path_663" data-name="Path 663" d="M0-46.723H24.827V-68H0Z" transform="translate(0 68)" fill="#e5e5e5" />
            </ClipPath>
        </Defs>
        <G id="Group_1451" data-name="Group 1451" transform="translate(0 68)">
            <G id="Group_1450" data-name="Group 1450" transform="translate(0 -68)" clipPath="url(#clip-path)">
                <G id="Group_1449" data-name="Group 1449">
                    <Path id="Path_662" data-name="Path 662" d="M-4.227,0A1.828,1.828,0,0,0-6,1.877,1.828,1.828,0,0,0-4.227,3.755h21.28a1.828,1.828,0,0,0,1.773-1.877A1.828,1.828,0,0,0,17.053,0Zm0,8.761A1.828,1.828,0,0,0-6,10.639a1.828,1.828,0,0,0,1.773,1.877h21.28a1.88,1.88,0,0,0,0-3.755Zm0,8.761A1.828,1.828,0,0,0-6,19.4a1.828,1.828,0,0,0,1.773,1.877h21.28a1.88,1.88,0,0,0,0-3.755Z" transform="translate(6)" fill="#e5e5e5" />
                </G>
            </G>
        </G>
    </Svg>
)
