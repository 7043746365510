import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles } from '../../common/const'
import SwitchCardVideo from '../../common/switch-card-video'

const emphatyVideo1: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/27sho6s2eK8",
    title: ""
}

const emphatyVideo2: Videos = {
    id: "2",
    url: "https://www.youtube.com/embed/cNHc2XCultQ",
    title: ""
}

const emphatyVideo3: Videos = {
    id: "3",
    url: "https://www.youtube.com/embed/aU3QfyqvHk8",
    title: ""
}

export const SwitchesLessonPlansEmphaty: CardTemplate = {
    cardId: "ad9237e4-e84a-4336-b5f8-2d998c100c65",
    title: 'Empathy',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define the concept of empathy, develop the ability to identify and understand others' feelings, and demonstrate empathy through various activities.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by gathering the students in a circle and creating a welcoming and inclusive environment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Define empathy in simple terms, such as "understanding and caring about how someone else feels."</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students if they have ever felt happy, sad, or scared, and encourage them to briefly share those experiences.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Read Aloud and Discussion (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce a picture book on empathy, such as "The Invisible Boy" by Trudy Ludwig.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Read the book aloud (or play the video - The Invisible Boy by Trudy Ludwig), pausing at appropriate moments to encourage students to identify characters' emotions and discuss their thoughts and feelings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a group discussion about the characters' emotions and how they might feel in similar situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask open-ended questions like, "How do you think the character felt when...?" and "Why is it important to be kind to others?"</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Empathy Art Activity (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide art supplies (coloured pencils, crayons, etc.) and paper to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to draw a picture that shows an act of kindness or understanding toward someone else. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage creativity and discussion of their artwork by asking questions like, "Why did you choose this scene?" and "How does it show empathy?"</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Wrap-up (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students together and ask them to share their artwork with the class, explaining the empathetic actions depicted.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a brief discussion on the importance of empathy and how it helps build positive relationships and create a caring community.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude the lesson by expressing appreciation for their participation and encouraging them to practice empathy in their daily lives.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Picture books on empathy e.g., "The Invisible Boy" by Trudy Ludwig (optional)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Art supplies and paper</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What is EMPATHY? Explaining Empathy to Kids – Emotions - </DefaultText>
                        <DefaultTextWithLink text='https://youtu.be/27sho6s2eK8' />
                        <SwitchCardVideo video={emphatyVideo1} />

                        <DefaultText style={CommonStyles.lessonPlanText}>Developing Empathy in Children and Youth -</DefaultText>
                        <DefaultTextWithLink text='https://www.antelopespringscounseling.com/documents/articles/EmpathyChildrenYouth.pdf' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The Invisible Boy by Trudy Ludwig -</DefaultText>
                        <DefaultTextWithLink text='https://youtu.be/cNHc2XCultQ' />
                        <SwitchCardVideo video={emphatyVideo2} />

                        <DefaultText style={CommonStyles.lessonPlanText}>Empathy can change the world - </DefaultText>
                        <DefaultTextWithLink text='https://youtu.be/aU3QfyqvHk8' />
                        <SwitchCardVideo video={emphatyVideo3} />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}
