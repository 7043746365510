import { StyleSheet, View } from 'react-native'
import { CardCategoryType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTimeType, CardType } from '../switches/common/card-template'
import SwitchCardFilterGroupByType from './switch-card-filter-group'
import SwitchCardFilterGroupTitle from './switch-card-filter-group-title'
import { SwitchCardFilterMethods } from './switch-card-filter-methods'
import { useReactiveVar } from '@apollo/client'
import { SwitchCardFilterType } from './switch-card-filter'
import { rvFilterSwitchesData } from './common-state'
import { DecidaColors } from '../../common/decida-colors'
import { CardVideoTimeTypes, CardVideoFilterEmotionType, CardVideoAgeGroupTypes } from '../switches/tools/cards/video-library'

const SwitchCardFilterExploreVideo = () => {
    const filters: SwitchCardFilterType = useReactiveVar(rvFilterSwitchesData)

    const setFilters = (data: SwitchCardFilterType) => {
        rvFilterSwitchesData(data)
    }

    return (
        <View>
            <View style={styles.content}>
                <SwitchCardFilterGroupTitle onSelectAll={() => SwitchCardFilterMethods.selectAllCategoryFilters(filters, "video")} title='Age appropriateness' filterType="video" />
                <SwitchCardFilterGroupByType label="" group={CardVideoAgeGroupTypes} filters={filters} setFilters={setFilters} groupType="category" />
            </View>
            <View style={styles.content}>
                <SwitchCardFilterGroupTitle onSelectAll={() => SwitchCardFilterMethods.selectAllParameterFilters(filters, "video")} title='Category' filterType="video" />
                <SwitchCardFilterGroupByType label="" group={CardVideoFilterEmotionType} filters={filters} setFilters={setFilters} groupType="emotion" />
            </View>
            <View style={styles.content}>
                <SwitchCardFilterGroupTitle onSelectAll={() => SwitchCardFilterMethods.selectAllParameterFilters(filters, "video")} title='Duration (minutes)' filterType="video" />
                <SwitchCardFilterGroupByType label="" group={CardVideoTimeTypes} filters={filters} setFilters={setFilters} groupType="time" />
            </View>
            <View style={styles.content}>
                <SwitchCardFilterGroupTitle onSelectAll={() => SwitchCardFilterMethods.selectAllParameterFilters(filters, "video")} title='Level' filterType="video" />
                <SwitchCardFilterGroupByType label="" group={CardIntensitylevelType} filters={filters} setFilters={setFilters} groupType="level" />
            </View>
        </View>
    )
}
export default SwitchCardFilterExploreVideo
const styles = StyleSheet.create({
    content: {
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: DecidaColors.Gray
    },
})