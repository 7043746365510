import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles } from '../../../common/const'
import { DefaultButton } from '../../../common/default-button'
import { DefaultText } from '../../../common/default-text'
import { useResponsive } from '../../../common/use-responsive'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { AnimalMashupBat } from './src/animal-mashup-bat'
import { AnimalMashupBear } from './src/animal-mashup-bear'
import { AnimalMashupBeever } from './src/animal-mashup-beever'
import { AnimalMashupCamel } from './src/animal-mashup-camel'
import { AnimalMashupCat } from './src/animal-mashup-cat'
import { AnimalMashupCheetah } from './src/animal-mashup-cheetah'
import { AnimalMashupChicken } from './src/animal-mashup-chicken'
import { AnimalMashupCrocodile } from './src/animal-mashup-crocodile'
import { AnimalMashupDeer } from './src/animal-mashup-deer'
import { AnimalMashupDuck } from './src/animal-mashup-duck'
import { AnimalMashupElephant } from './src/animal-mashup-elephant'
import { AnimalMashupEmu } from './src/animal-mashup-emu'
import { AnimalMashupFish } from './src/animal-mashup-fish'
import { AnimalMashupFox } from './src/animal-mashup-fox'
import { AnimalMashupFrog } from './src/animal-mashup-frog'
import { AnimalMashupGirrafe } from './src/animal-mashup-girraffe'
import { AnimalMashupGorilla } from './src/animal-mashup-gorilla'
import { AnimalMashupHeader } from './src/animal-mashup-header'
import { AnimalMashupHippothamus } from './src/animal-mashup-hippothamus'
import { AnimalMashupIcon } from './src/animal-mashup-icon'
import { AnimalMashupImage1 } from './src/animal-mashup-image1'
import { AnimalMashupKangaroo } from './src/animal-mashup-kangaroo'
import { AnimalMashupLeopard } from './src/animal-mashup-leopard'
import { AnimalMashupLion } from './src/animal-mashup-lion'
import { AnimalMashupMeerkat } from './src/animal-mashup-meerkat'
import { AnimalMashupMonkey } from './src/animal-mashup-monkey'
import { AnimalMashupOwl } from './src/animal-mashup-owl'
import { AnimalMashupPig } from './src/animal-mashup-pig'
import { AnimalMashupPigeon } from './src/animal-mashup-pigeon'
import { AnimalMashupPinguin } from './src/animal-mashup-pinguin'
import { AnimalMashupShark } from './src/animal-mashup-shark'
import { AnimalMashupWombat } from './src/animal-mashup-wombat'
import { AnimalMashupZebra } from './src/animal-mashup-zebra'

const animals: Array<React.FC<React.ReactNode>> = [
    AnimalMashupBat,
    AnimalMashupBear,
    AnimalMashupBeever,
    AnimalMashupCamel,
    AnimalMashupCat,
    AnimalMashupCheetah,
    AnimalMashupChicken,
    AnimalMashupCrocodile,
    AnimalMashupDeer,
    AnimalMashupDuck,
    AnimalMashupElephant,
    AnimalMashupEmu,
    AnimalMashupFish,
    AnimalMashupFox,
    AnimalMashupFrog,
    AnimalMashupGirrafe,
    AnimalMashupGorilla,
    AnimalMashupHippothamus,
    AnimalMashupKangaroo,
    AnimalMashupLeopard,
    AnimalMashupLion,
    AnimalMashupMeerkat,
    AnimalMashupMonkey,
    AnimalMashupOwl,
    AnimalMashupPig,
    AnimalMashupPigeon,
    AnimalMashupPinguin,
    AnimalMashupShark,
    AnimalMashupWombat,
    AnimalMashupZebra
]

const IMAGE_HEIGHT = 500

export const SwitchesEmotionsSadAnimalMashup: CardTemplate = {
    cardId: "d253d91c-c22d-4a39-aa4c-06a73b621dfd",
    title: 'Animal mashup',
    Header: AnimalMashupHeader,
    Icon: AnimalMashupIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"When you need a distraction from your sad thoughts."} />
        </>
    ),
    Images: () => (
        <>
            <AnimalMashupImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Select the ‘generate animals’ button."} />
                <DefaulttextWithRightArrow textdata={"Think about the features of each of the animals shown."} />
                <DefaulttextWithRightArrow textdata={"Imagine what a creature would look like if you combined the animals that are shown into one!"} />
                <DefaulttextWithRightArrow textdata={"Draw your awesome new animal."} />
                <DefaulttextWithRightArrow textdata={"Give your new animal a name."} />
            </View>
        </>
    ),
    BottomRow: () => {
        const [selectedAnimals, setSelectedAnimals] = useState<Array<number>>([])
        const { windowWidthCondition } = useResponsive()

        const generateAnimalsImages = () => {
            let currentNumbers = []
            while (currentNumbers.length < 3) {
                const r = Math.floor(Math.random() * animals.length) + 1;
                if (currentNumbers.indexOf(r) === -1) currentNumbers.push(r);
            }
            setSelectedAnimals(currentNumbers)
        }

        return (
            <>
                <View style={styles.buttonContainer}>
                    <DefaultButton onPress={generateAnimalsImages} style={styles.generateButton}>Generate animals</DefaultButton>
                </View>

                <View style={styles.mainContainer}>
                    <View style={windowWidthCondition ? styles.columnContainer : styles.rowContainer}>
                        {animals.map((el, index) => {
                            if (selectedAnimals.includes(index + 1)) {
                                const AnimalImage: any = el
                                return (
                                    <View key={index} style={CommonStyles.flex}>
                                        <View style={styles.imageContainer}>
                                            <AnimalImage />
                                        </View>
                                    </View>
                                )
                            }
                            return <View key={index} />
                        })}
                    </View>
                </View>
                <DefaulTextWithTipsIcon textdata="Enjoy the creative imaginative process without limitation. Who says giraffes can’t have wings that glow in the dark!?!" />
            </>
        )
    },
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: `Using your imagination can help with being happy because it allows you to engage with ideas, stories, and scenarios that can bring you joy and pleasure. Imagination can be a powerful tool for exploring and finding meaning in the world around you, and it can provide a sense of escape or relief from reality. It can also help you to think creatively and come up with new solutions to problems, which can increase your sense of accomplishment and satisfaction. Additionally, using your imagination can allow you to tap into your emotions and feel a greater sense of connection to others, which can contribute to your overall sense of well-being.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice using imagination to create and describe new animals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice using basic drawing techniques to illustrate their ideas.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Describe the benefits of using imagination</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 35 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by asking students to share some examples of things they have imagined, such as a story they made up, a game they invented, or a place they have visited in their mind.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide the class into small groups and have each group select three animal images (or refer to the Animal Mashup switch and select “Generate animals”) to work with.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to use their imagination to come up with a new animal creature that incorporates features from each of the three animals they have.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ They should draw pictures and write a short story to describe the new animal.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have each group present their story to the class. Encourage students to use descriptive language and to include details that will help their listeners to visualise the animal.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After all the presentations are complete, discuss with the class the benefits of using imagination. Ask students to share any personal experiences they have had where using their imagination has helped them to be more creative, solve problems, or feel happier.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Talk about the different ways that students can use their imagination, such as by creating stories, inventing games, or pretending to be someone or something else.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to continue using their imagination throughout the day and week by setting aside time for imaginative play and asking open-ended questions that encourage them to think creatively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students that using their imagination can be fun and can also help them to be more creative and better problem-solvers.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, refer them to the Animal Mashup switch in the Switch4Schools program.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pictures of different types of animals</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Blank paper</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pencils</DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Imagination Is a Powerful Tool</DefaultText>
                        <DefaultTextWithLink text='https://www.goodtherapy.org/blog/imagination-tool-for-change-0224124/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Imagination Helps You Manage Negative Emotions</DefaultText>
                        <DefaultTextWithLink text='https://exploringyourmind.com/imagination-helps-manage-negative-emotions/' />
                    </>}
            />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Animal+Mashup+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Sad]
}

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    imageContainer: {
        width: '100%',
        height: IMAGE_HEIGHT,
        // maxHeight: 120
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        flexDirection: 'row',
        marginHorizontal: 20,
        justifyContent: 'center',
        marginVertical: 20
    },
    columnContainer: {
        justifyContent: 'center',
        width: '100%',
    },
    buttonContainer: {
        // flex: 1,
        flexDirection: 'row',
        marginLeft: 20,
        marginBottom: 10,
        alignItems: 'center',
    },
    generateButton: {
        backgroundColor: DecidaColors.Sad
    },
})
