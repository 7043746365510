import { createStackNavigator } from '@react-navigation/stack'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'

import { RootView } from '../common/root-view'
import { ScreenNames } from '../common/screen-names'
import { ChangePasswordProfile } from '../common/change-password-profile'
import { ForgotPasswordRequest } from '../login/forgot-password-request'
import { ForgotPasswordSubmit } from '../login/forgot-password-submit'
import { NonTeachingStaffProfile } from './non-teaching-staff-profile'
import { NonTeachingStaffNavigationParams } from './non-teaching-staff-navigator-params-list'
import { UserEditAvatar } from '../common/user-edit-avatar'

const Stack = createStackNavigator<NonTeachingStaffNavigationParams>()

export const NonTeachingStaffProfileMain = () => {

    return (
        <RootView>
            <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={ScreenNames.NonTeachingStaffProfile}>
                <Stack.Screen name={ScreenNames.NonTeachingStaffProfile} component={NonTeachingStaffProfile} options={{ title: 'Profile' }} />
                <Stack.Screen name={ScreenNames.ChangePasswordProfile} component={ChangePasswordProfile} />
                <Stack.Screen name={ScreenNames.LoginForgotPasswordRequest} component={ForgotPasswordRequest} />
                <Stack.Screen name={ScreenNames.LoginForgotPasswordSubmit} component={ForgotPasswordSubmit} />
                <Stack.Screen name={ScreenNames.UserEditAvatar} component={UserEditAvatar} />
            </Stack.Navigator>
        </RootView>
    )
}
