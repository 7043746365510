
export const audio = {

    boxBreathing: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/box-breathing.mp3' },
    Energetic: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Energetic.mp3' },
    Cheerful: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Cheerful.wav' },
    Pumped: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Pumped.wav' },
    Peaceful: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Peaceful.wav' },
    Mysterious: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Mysterious.wav' },
    Sad: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Sad.mp3' },
    DanceAnthem: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Dance+Anthem.wav' },
    OldSkoolClub: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/old+skool+dance.wav' },
    AnimalCatMeow: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/animal+-+Cat+Meow.m4a' },
    AnimalDuckQuaking: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/animal+-+Duck+Quacking.m4a' },
    AnimalGooseHonk: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/animal+-+Goose+Honk.m4a' },
    AnimalLambBaa: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/animal+-+Lamb+baa.m4a' },
    AnimalWolfHowl: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/animal+-+Wolf+Howl.m4a' },
    AnimalCowMoo: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/animal+-+cow+moo.m4a' },
    AnimalCricketChirp: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/animal+-+cricket+chirp.m4a' },
    AnimalDogBark: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/animal+-+dog+bark.m4a' },
    AnimalDonkeyHeeHaw: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/animal+-+donkey+hee-haw.m4a' },
    AnimalElephantTrumpeting: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/animal+-+elephant+trumpeting.m4a' },
    AnimalHorseNeight: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/animal+-+horse+neigh.m4a' },
    BirdCallHawCry: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/bird+call+-+hawk+cry.m4a' },
    BirdCallKooKaburra: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/bird+call+-+kookaburra.m4a' },
    BirdCallMagpie: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/bird+call+-+magpie.m4a' },
    BirdCallOwlHoot: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/bird+call+-+Owl+hoot.m4a' },
    BirdCallRainforestSounds: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/bird+call+-+Rainforest+sounds.m4a' },
    BirdCallRooseter: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/brid+call+-+Rooster.m4a' },
    BodyScanMediation: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Body+scan+meditation.m4a'},
    DomesticClockStrikes: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/domestic+-+clock+strikes.m4a' },
    DomesticDoorClosing: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/domestic+-+door+closing.m4a' },
    DomesticGlassBreaking: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/domestic+-+Glass+Breaking.m4a' },
    DomesticLawnMower: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/domestic+-+lawn+mower.m4a' },
    DomesticOldPhoneRinging: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/domestic+-+Old+phone+ringing.m4a' },
    HumanApplause: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/human+-+Applause.m4a' },
    HumanBasketballBouncing: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/human+-+basketball+bouncing.m4a' },
    HumanFootsteps: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/human+-+footsteps.m4a' },
    HumanLaughing: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/human+-+laughing.m4a' },
    HumanToddlerGiggle: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/human+-+toddler+giggle.m4a' },
    MusicalBooing: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/musical+-+boing.m4a' },
    MusicalDrums: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/musical+-+drums.m4a' },
    MusicalSlideWhistle: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/musical+-+slide+whistle.m4a' },
    MusicalTrumpetFanfare: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/musical+-+trumpet+fanfare.m4a' },
    MusicalViolinTuning: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/musical+-+violin+tuning.m4a' },
    NatureFireCracking: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/nature+-+fire+crackling.m4a' },
    NatureOceanWaves: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/nature+-+ocean+waves.m4a' },
    NatureSplash: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/nature+-+Splash.m4a' },
    NatureThunder: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/nature+-+Thunder.m4a' },
    NatureWindHowling: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/nature+-+Wind+Howling.m4a' },
    TransportAirplaneDoingAcrobatics: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/transport+-+Airplane+doing+acrobatics.m4a' },
    TransportBusStarting: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/transport+-+bus+starting.m4a' },
    TransportCarHorns: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/transport+-+car+horns.m4a' },
    TransportMotorbikeTakeOff: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/transport+-+motorbike+take+off.m4a' },
    TransportShipHorn: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/transport+-+ship+horn.m4a' },
    TransportTrainWhistle: { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/transport+-+Train+whistle.m4a' }

}
