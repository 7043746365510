import Svg, { Path, Text, TSpan } from 'react-native-svg'
import { SvgEmotionIconProps } from './icon-emotion-types'

export const IconAnxious = ({ width, height, withoutLabel }: SvgEmotionIconProps) =>
    <Svg width={width} height={height} viewBox="0 0 100.8 109.282">
        <Path d="M0,0A41.5,41.5,0,0,0,41.5-41.5,41.5,41.5,0,0,0,0-83,41.5,41.5,0,0,0-41.5-41.5,41.5,41.5,0,0,0,0,0" transform="translate(50.4 85.5)" fill="#fff" />
        <Path d="M0,0A41.5,41.5,0,0,0,41.5-41.5,41.5,41.5,0,0,0,0-83,41.5,41.5,0,0,0-41.5-41.5,41.5,41.5,0,0,0,0,0Z" transform="translate(50.4 85.5)" fill="none" stroke="#c6ba30" strokeWidth="5" />
        <Path d="M0,0A5.878,5.878,0,0,0,5.878-5.878,5.878,5.878,0,0,0,0-11.757,5.878,5.878,0,0,0-5.878-5.878,5.878,5.878,0,0,0,0,0" transform="translate(34.425 41.242)" fill="#c6ba30" />
        <Path d="M0,0A5.878,5.878,0,0,0,5.878-5.878,5.878,5.878,0,0,0,0-11.757,5.878,5.878,0,0,0-5.878-5.878,5.878,5.878,0,0,0,0,0" transform="translate(67.065 41.242)" fill="#c6ba2f" />
        <Path d="M0,0A5.88,5.88,0,0,0,0,11.76H16.32A5.88,5.88,0,1,0,16.32,0Z" transform="translate(42.24 54.48)" fill="none" stroke="#c6ba30" strokeWidth="5" />
        {withoutLabel ? (null) : (
            <>
                <Path d="M0,0A9.941,9.941,0,0,0-9.941,9.941,9.941,9.941,0,0,0,0,19.882H77.918A9.941,9.941,0,1,0,77.918,0Z" transform="translate(11.441 87.9)" fill="#fff" />
                <Text transform="translate(19.378 103.424)" fill="#c6ba30" fontSize="15" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">Anxious</TSpan></Text>
                <Path d="M0,0A9.941,9.941,0,0,0-9.941,9.941,9.941,9.941,0,0,0,0,19.882H77.918A9.941,9.941,0,1,0,77.918,0Z" transform="translate(11.441 87.9)" fill="none" stroke="#c6ba30" strokeWidth="3" />
            </>
        )}
    </Svg>
