import moment from 'moment'
import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import VideoCard from '../../../common/video-card'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { BreathCycleHeader } from './src/breath-cycle-header'
import { BreathCycleIcon } from './src/breath-cycle-icon'
import { BreathCycleImage1 } from './src/breath-cycle-image1'
import { CommonStyles } from '../../../common/const'
import SwitchCardVideo from '../../../common/switch-card-video'
import { BreathCycleImage2 } from './src/breath-cycle-image2'

const breathCycleVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/G8xIEzX40bA",
    createdAt: moment('2022-01-01').toString(),
}

export const SwitchesEmotionsScaredBreathCycle: CardTemplate = {
    cardId: "f2e445bf-bfa3-434d-96c3-a3a77dbd8dbc",
    title: 'Breath cycle',
    Header: BreathCycleHeader,
    Icon: BreathCycleIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"When you are feeling overcome with emotion. "} />
        </>
    ),
    Images: () => (
        <>
            <BreathCycleImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Find a quiet space by yourself free from any things that might distract you  "} />
                <DefaulttextWithRightArrow textdata={"Take your right hand, place your thumb on your right nostril, and your fourth finger on your left. "} />
                <DefaulttextWithRightArrow textdata={"Let go of one side and breathe in slowly through one nostril then out slowly through the same nostril.  "} />
                <DefaulttextWithRightArrow textdata={"Now switch sides!  "} />
                <DefaulttextWithRightArrow textdata={"The exercise is done once you have completed four breaths  "} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: 'With regular practice, deep breathing and alternate-nostril breathing can bring better balance to your nervous system and less stress response and activity over time. It lowers blood pressure lowering your heart rate, which then signals to the brain that you are no longer anxious or scared . ',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Discuss the physiological impacts of high level emotion arousal.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice a breathing exercise to experience slowing the heart rate and being focused inward.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show students the emotion wheel and discuss emotions that may make them feel overwhelmed or out of control.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to consider the physiological changes associated with these emotions, such as increased heart rate, sweaty palms, or the need to fidget.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity: Alternate-Nostril Breathing (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of alternate-nostril breathing as a technique to counter physiological changes during overwhelming emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Demonstrate the breathing exercise using the Breath Cycle switch in the Switch4Schools app or a video.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Guide students through the steps:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Sit quietly.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Bring the right hand up to the nose, placing the thumb on the right nostril.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Inhale fully and slowly through the left nostril.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Release the right nostril and put the ring finger on the left nostril.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Exhale slowly from the right side.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Repeat, alternating sides.</DefaultText>
                        <View style={CommonStyles.lessonPlanImageContainer}>
                            <BreathCycleImage2 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of easy, gentle, and slow breaths.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Students practice the exercise individually, repeating the process two or more times.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Debrief (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students how the alternate-nostril breathing exercise made them feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Look for responses related to a sense of calmness or a slowed heart rate.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the potential benefits of using this technique during overwhelming emotions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the idea that breathing exercises can be powerful tools for emotional regulation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to use alternate-nostril breathing whenever they feel overwhelmed.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Quiet space, ideally with dim lighting </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What to Know About Alternate-Nostril Breathing -</DefaultText>
                        <DefaultTextWithLink text='https://www.webmd.com/balance/what-to-know-about-alternate-nostril-breathing#:~:text=With%20regular%20practice%2C%20alternate%2Dnostril,and%20lower%20your%20blood%20pressure' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Benefits and risks of alternate nostril breathing  -</DefaultText>
                        <DefaultTextWithLink text='https://www.healthline.com/health/alternate-nostril-breathing' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How and why to do alternate nostril breathing -</DefaultText>
                        <DefaultTextWithLink text='https://www.medicalnewstoday.com/articles/alternate-nostril-breathing' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Alternate Breathing, a mindfulness exercise -</DefaultText>
                        <DefaultTextWithLink text='https://livingwell.org.au/wp-content/uploads/2012/11/12-AlternateNostrilBreathing.pdf' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Don’t force your breath or breathe in fast, try to breathe in as deeply as you can and as slowly as you can' />
            <DefaulTextWithTipsIcon textdata='Ask yourself how you felt before doing the breaths and how you feel now after.  ' />
            <View style={styles.tipsSubText}>
                <DefaultTextWithoutArrows textdata='a. Were there any improvements?' />
            </View>
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Breath+cycle+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Scared]
}

const styles = StyleSheet.create({
    tipsSubText: {
        paddingLeft: 70,
        alignItems: 'flex-start'
    }
})
