import { AntDesign } from '@expo/vector-icons'
import { PropsWithChildren } from 'react'
import { GestureResponderEvent, Keyboard, StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, TouchableOpacityProps, View, ViewStyle } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { IconPlus } from '../icon/icon-plus'
import { ButtonText } from './button-text'
import { CommonStyles, DefaultFontSize } from './const'

export enum DefaultButtonTypes {
    Default,
    Small,
    HierarchyUI
}

interface Props {
    onPress?: (e: GestureResponderEvent) => void
    style?: StyleProp<ViewStyle>,
    buttonTextStyle?: StyleProp<TextStyle>
    disabledStyle?: ViewStyle,
    disabled?: boolean,
    buttonTextColor?: string,
    buttonFontSize?: number
    type?: DefaultButtonTypes
    plusIcon?: boolean
    accessibilityLabel?: string
}

export const DefaultButton = ({ children, onPress, style, disabledStyle, disabled = false, buttonTextColor = DecidaColors.White, buttonFontSize = DefaultFontSize, testID, buttonTextStyle, plusIcon, type = DefaultButtonTypes.Default, accessibilityLabel }: PropsWithChildren<Props & TouchableOpacityProps>) => {
    const handleOnPress = (e: GestureResponderEvent) => {
        Keyboard.dismiss()
        onPress && onPress(e)
    }

    const isChildText = typeof children === 'string' || typeof children === 'number' || typeof children === 'boolean'

    if (type === DefaultButtonTypes.HierarchyUI) {
        return (
            <TouchableOpacity style={[CommonStyles.innerShadow, CommonStyles.buttonRounded, style]} onPress={onPress}>
                <Text adjustsFontSizeToFit numberOfLines={1} style={[styles.buttonTextHierarchyUI, { color: buttonTextColor, minWidth: 'auto' }]}>{plusIcon && <AntDesign name="plus" size={20} color={buttonTextColor} style={{ marginRight: 5 }} />}{children}</Text>
            </TouchableOpacity>
        )
    }

    return (
        <TouchableOpacity
            onPress={handleOnPress}
            accessibilityLabel={accessibilityLabel}
            accessibilityRole="button"
            disabled={disabled}
            style={disabled ? StyleSheet.flatten([type === DefaultButtonTypes.Default ? styles.view : styles.viewSmall, style, styles.disabledStyle, disabledStyle]) : [type === DefaultButtonTypes.Default ? styles.view : styles.viewSmall, style]}
            testID={testID}
        >
            {isChildText && type === DefaultButtonTypes.Default ? (
                <ButtonText style={[{ color: buttonTextColor, fontSize: buttonFontSize }, buttonTextStyle]}>{children.toString()}</ButtonText>
            ) : isChildText && type === DefaultButtonTypes.Small ? (
                <View style={styles.buttonWrapper}>
                    {plusIcon && (
                        <View pointerEvents='none'>
                            <IconPlus size={24} />
                        </View>
                    )}
                    <ButtonText style={styles.buttonText}>{children.toString()}</ButtonText>
                </View>
            ) : children}
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    view: {
        flexDirection: 'row',
        backgroundColor: DecidaColors.Green,
        width: 330,
        height: 50,
        marginTop: 7,
        marginBottom: 7,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
    } as ViewStyle,
    viewSmall: {
        flexDirection: 'row',
        backgroundColor: DecidaColors.Green,
        marginTop: 7,
        marginBottom: 7,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        paddingHorizontal: 20,
        paddinngVertical: 10,
        height: 25,
    },
    disabledStyle: {
        backgroundColor: DecidaColors.Gray,
    },
    buttonWrapper: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonText: {
        fontSize: 12,
        fontWeight: '700',
    },
    buttonTextHierarchyUI: {
        color: DecidaColors.Green,
        fontSize: 16,
        textAlign: 'center',
        textAlignVertical: 'center',
        verticalAlign: 'middle'
    }
})