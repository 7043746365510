import { StyleSheet, View } from 'react-native'
import { DefaultText } from '../../../../common/default-text'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { SpellingEliminationHeader } from '../../icons/spelling-elimination-header'
import { SpellingEliminationIcon } from '../../icons/spelling-elimination-icon'
import { SpellingEliminationLessonPlan } from '../../icons/spelling-elimination-lesson-plan'
import { DecidaColors } from '../../../../../common/decida-colors'

export const SwitchesActivitiesNumberAndWordGamesSpellingEliminationGame: CardTemplate = {
    cardId: "ce028cae-f451-459b-b6f8-05f4edd661e4",
    title: 'Spelling elimination',
    Header: SpellingEliminationHeader,
    Icon: SpellingEliminationIcon,
    PerfectFor: () => {
        return (
            <>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata={"Practicing spelling words while developing listening and turn taking skills through in a fun competitive environment."} />

                <DefaultText style={styles.contentText}>Duration: Approximately 15 minutes (but adjustable depending on the number of words included in the game) </DefaultText>

                <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Equipment required:"} />
                <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"List of spelling words "} />

                <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
                <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Have students stand in a circle."} />
                <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Choose a game master to stand in the middle of the circle."} />
                <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Provide the game master with the spelling list of words."} />
                <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The game master announces the first word that is to be spelled and chooses who goes first.  "} />
                <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Each student in turn (clockwise) calls out each letter of the word. Once the word is successfully completed the students finish with “you” “sit” “down”. "} />
                <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The student after the person to say “down” sits down and is now out."} />
                <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"If a student gets it wrong, the game master calls them out and they also sit down, and the next person continues where it stopped.  "} />
                <View style={styles.imageContainer}>
                    <SpellingEliminationLessonPlan />
                </View>
                <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The game master announces the next word to be spelled and the students continue taking turns until all but one or two students remain, who are victorious and could be rewarded in your normal manner (eg sticker, free time, or the right to be the game master in the next game played).  "} />

                <DefaultTextWithoutArrowsBold textdata='' />
                <DefaultText style={styles.lessonPlanTextSmallItalic}>Shout out to Amberley District State School for sharing this great activity!  </DefaultText>
            </>
        )
    },
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.NUMBER_AND_WORD_GAMES,
    whyDoesItWorkText: "",
    type: [CardType.Activity],
    time: [CardTimeType.Short, CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.NUMBER_AND_WORD_GAMES],
    action: [CardActionType.Action],
    level: []

}

const styles = StyleSheet.create({
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanTextSmallItalic: {
        textAlign: 'center',
        fontSize: 16,
        fontStyle: 'italic',
        color: DecidaColors.AppleSystemGray2Dark,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    imageContainer: {
        width: '100%',
        height: 150,
        marginVertical: 20
    },
    contentText: {
        textAlign: 'center',
        marginVertical: 10
    },

})
