import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { TheHealthySleeperHeaderIcon } from './src/the-healthy-sleeper-header-icon'
import { TheHealthySleeperIcon } from './src/the-healthy-sleeper-icon'


export const SwitchesActivitiesSecretsForSleepTheHealthySleeper: CardTemplate = {
    cardId: "c3ab8269-084d-4863-9852-2f06d83e8a91",
    title: 'The healthy sleeper',
    Header: TheHealthySleeperHeaderIcon,
    Icon: TheHealthySleeperIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Anyone who is looking to improve their sleep habits and enhance the quality of their sleep. Particularly useful for those who may be experiencing difficulties falling asleep, staying asleep, or waking up feeling refreshed."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Get regular exercise: Engaging in physical activity during the day can help promote better sleep. Just make sure not to exercise too close to bedtime, as it can make you feel more energised and alert. "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Eat a balanced diet: Eating a nutritious and well balanced diet can support good sleep. Include plenty of fruits, vegetables, whole grains, and lean proteins in your meals. Avoid heavy, greasy, and spicy foods close to bedtime, as they can cause indigestion and discomfort that may interfere with sleep."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Avoid caffeine and sugary foods: Try not to have drinks or foods that contain caffeine (like cola or chocolate) close to bedtime. Caffeine can make it harder for you to fall asleep. It's also a good idea to avoid sugary snacks before bed, as they can give you a lot of energy and make it harder to wind down."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Limit daytime napping: If you have trouble falling asleep at night or staying asleep, it may be helpful to limit daytime napping. If you do nap, try to keep it short (around 20-30 minutes) and avoid napping too late in the day."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Manage stress and relax: Find ways to manage stress and relax before bed. You can try deep breathing exercises, meditation, or practicing mindfulness. These techniques can help calm your mind and prepare you for sleep."} />

        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata="Sleep is like charging your body's battery. When you sleep, your body gets a chance to rest and repair itself, so you can feel refreshed and have energy for the next day." />
        </>

    ),
    CardContent: () => (
        <>
        </>
    ),
    cardContentTemplate: 'column',
    colorScheme: CardColorScheme.SECRETS_FOR_SLEEP,
    whyDoesItWorkText: "Getting good sleep is a key part of developing stable mental wellbeing. Maintaining a consistent sleep schedule, limiting stimulating substances like caffeine, and engaging in stress management techniques are all ways of preparing the body for good quality REM sleep. Additionally, regular exercise, and a balanced diet help regulate our body's natural sleep-wake cycles and provide the necessary conditions for restful sleep. By implementing these strategies, we can enhance our sleep and overall wellbeing.",
    audioUrl: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/The+healthy+sleeper.m4a',
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.SECRETS_FOR_SLEEP],
    action: [CardActionType.Action],
    level: []
}

