import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { S3ProviderListOutputItem } from '@aws-amplify/storage';
import { AntDesign } from '@expo/vector-icons';
import { Storage } from 'aws-amplify';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Pressable, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { DecidaColors } from "../../common/decida-colors";
import { DefaultText } from '../common/default-text';
import { GetSchoolCampusesAndGroupsQueryResponse, getSchoolCampusesAndGroupsQuery } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query';
import { getSchoolAdminByCognitoUsername } from '../../common/graphql/queries';
import { IconFilePdf } from '../icon/icon-file-pdf';
import { rvCurrentUser } from '../login/login-state';
import { openUrlWebView } from '../web-view/open-url-web-view';

const SchoolAdminStats = () => {
    const [reportsData, setReportsData] = useState<S3ProviderListOutputItem[]>([])
    const user = useReactiveVar(rvCurrentUser)

    const { data: resultSchoolAdmin } = useQuery(gql`${getSchoolAdminByCognitoUsername}`, { variables: { cognitoUsername: user?.username } })
    const currentSchoolAdmin = resultSchoolAdmin?.getSchoolAdminByCognitoUsername?.items[0]

    const { data: schoolData } = useQuery<GetSchoolCampusesAndGroupsQueryResponse>(getSchoolCampusesAndGroupsQuery, { variables: { id: currentSchoolAdmin?.schoolID } })
    const currentSchool = schoolData?.getSchool
    const reportsFolderKey = `reports/${currentSchool?.name}/`

    useEffect(() => {
        getSchoolReports()
    }, [reportsFolderKey])

    const getSchoolReports = async () => {
        const reportsFolderData = await Storage.list(reportsFolderKey, { pageSize: 'ALL' })
        const allReportData = reportsFolderData.results.filter((r) => r.key !== reportsFolderKey)
        setReportsData(_.sortBy(allReportData, "lastModified").reverse())
    }

    const openReportFile = async (item: S3ProviderListOutputItem) => {
        if (item && item.key) {
            const fileDataUrl = await Storage.get(item.key)
            openUrlWebView(fileDataUrl)
        }
    }

    if (!reportsData.length) {
        return <View style={styles.noDataContainer}>
            <DefaultText>Coming Soon</DefaultText>
        </View>
    }

    return (
        <View style={styles.container}>
            <View style={styles.contentText}>
                <DefaultText style={styles.latestReportText}>Latest report</DefaultText>
            </View>

            <Pressable onPress={() => openReportFile(reportsData[0])}>
                <View style={styles.bigIconContainer}>
                    <IconFilePdf />
                    <AntDesign name="download" size={40} color="black" />
                </View>

                <View>
                    <DefaultText style={[styles.contentText, styles.fileName]}>
                        {reportsData[0].key?.replace(reportsFolderKey, "")}
                    </DefaultText>
                </View>
            </Pressable>

            <View style={styles.horizontalLine} />
            <View style={styles.contentText}>
                <DefaultText style={styles.latestReportText}>Archive <DefaultText style={styles.smallItalic}>{"(latest to earliest)"}</DefaultText></DefaultText>
            </View>

            <ScrollView horizontal>
                <View style={styles.archiveSectionContainer}>
                    {reportsData.map((report, index) => {
                        const fileName = report.key?.replace(reportsFolderKey, "")
                        if (index === 0) {
                            return null
                        }
                        return (
                            <TouchableOpacity key={String(index)} style={styles.archiveItemContainer} onPress={() => openReportFile(report)}>
                                <View style={styles.smallIcon}>
                                    <IconFilePdf />
                                </View>
                                <DefaultText style={styles.fileName}>{fileName}</DefaultText>
                            </TouchableOpacity>
                        )
                    })}
                </View>
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 40,
    },
    bigIconContainer: {
        width: 120,
        height: 120,
        flexDirection: 'row',
        alignItems: 'center',
    },
    contentText: {
        marginLeft: 10,
        paddingVertical: 10
    },
    latestReportText: {
        fontSize: 26
    },
    fileName: {
        fontSize: 16,
        color: DecidaColors.Gray
    },
    horizontalLine: {
        height: 2,
        backgroundColor: DecidaColors.Gray,
        marginVertical: 40,
        maxWidth: 250
    },
    smallItalic: {
        fontSize: 14,
        color: DecidaColors.Gray,
        fontStyle: 'italic'
    },
    archiveItemContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginVertical: 5,
        marginRight: 10,
    },
    smallIcon: {
        width: 40,
        height: 40,
        marginHorizontal: 5,
    },
    archiveSectionContainer: {
        maxHeight: '100%',
        flexWrap: 'wrap'
    },
    noDataContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }
})

export default SchoolAdminStats