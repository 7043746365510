import { useReactiveVar } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { Dimensions, ScrollView, StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native'
import { BackButton } from '../common/back-button'
import { rvCurrentClass } from '../common/common-state'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'
import { IntensityLevel, getHomeIntensityLevel } from '../common/get-intensity-level'
import { ScreenNames } from '../common/screen-names'
import { Emotion } from '../models'
import { TeacherDrawerNavigatorPamarList } from '../teacher/teacher-route-param-types'
import { rvSwitchesScreenState } from './common/current-screen-state'
import SwitchDetailsIntroducingEmotion from './common/switch-details-introducting-emotions'
import { tileSizes } from './common/title-sizes'
import { intensityEmotionCards } from './emotion-cards/cards'
import { SwitchesLessonPlansIntroducingAngry } from './lesson-plan-cards/introducing-angry'
import { SwitchesLessonPlansIntroducingAnxiety } from './lesson-plan-cards/introducing-anxiety'
import { SwitchesLessonPlansIntroducingExcited } from './lesson-plan-cards/introducing-excited'
import { SwitchesLessonPlansIntroducingHappy } from './lesson-plan-cards/introducing-happy'
import { SwitchesLessonPlansIntroducingSad } from './lesson-plan-cards/introducing-sad'
import { SwitchesLessonPlansIntroducingScared } from './lesson-plan-cards/introducing-scared'
import { SwitchesLessonPlansIntroductionToAngry } from './lesson-plan-cards/introduction-to-angry'
import { SwitchesLessonPlansIntroductionToAnxious } from './lesson-plan-cards/introduction-to-anxious'
import { SwitchesLessonPlansIntroductionToExcited } from './lesson-plan-cards/introduction-to-excited'
import { SwitchesLessonPlansIntroductionToHappy } from './lesson-plan-cards/introduction-to-happy'
import { SwitchesLessonPlansIntroductionToSad } from './lesson-plan-cards/introduction-to-sad'
import { SwitchesLessonPlansIntroductionToScared } from './lesson-plan-cards/introduction-to-scared'
import { SwitchesScreens } from './navigation/switches-bottom-navigation'
import { SwitchesLessonPlansUnderstandingAndManagingAnger } from './lesson-plan-cards/understanding-and-managing-anger'
import { SwitchesLessonPlansUnderstandingAndManagingHapiness } from './lesson-plan-cards/understanding-and-managing-hapiness'
import { SwitchesLessonPlansUnderstandingAndManagingAnxiety } from './lesson-plan-cards/understanding-and-managing-anxiety'
import { SwitchesLessonPlansUnderstandingAndManagingSadness } from './lesson-plan-cards/understanding-and-managing-sadness'
import { SwitchesLessonPlansUnderstandingAndManagingScared } from './lesson-plan-cards/understanding-and-managing-scared'
import { SwitchesLessonPlansUnderstandingAndManagingExcitement } from './lesson-plan-cards/understanding-and-managing-excitement'
import { CardTemplate } from './common/card-template'

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600

type LessonPlanCardMap = {
    [K in IntensityLevel]: {
        [E in Emotion]: CardTemplate | undefined;
    } | undefined;
};


const lessonPlanCards: LessonPlanCardMap = {
    1: {
        [Emotion.HAPPY]: SwitchesLessonPlansIntroducingHappy,
        [Emotion.ANXIOUS]: SwitchesLessonPlansIntroducingAnxiety,
        [Emotion.ANGRY]: SwitchesLessonPlansIntroducingAngry,
        [Emotion.SAD]: SwitchesLessonPlansIntroducingSad,
        [Emotion.SCARED]: SwitchesLessonPlansIntroducingScared,
        [Emotion.EXCITED]: SwitchesLessonPlansIntroducingExcited,
    },
    5: {
        [Emotion.HAPPY]: SwitchesLessonPlansUnderstandingAndManagingHapiness,
        [Emotion.ANXIOUS]: SwitchesLessonPlansUnderstandingAndManagingAnxiety,
        [Emotion.ANGRY]: SwitchesLessonPlansUnderstandingAndManagingAnger,
        [Emotion.SAD]: SwitchesLessonPlansUnderstandingAndManagingSadness,
        [Emotion.SCARED]: SwitchesLessonPlansUnderstandingAndManagingScared,
        [Emotion.EXCITED]: SwitchesLessonPlansUnderstandingAndManagingExcitement,
    },
    3: {
        [Emotion.HAPPY]: SwitchesLessonPlansIntroductionToHappy,
        [Emotion.ANXIOUS]: SwitchesLessonPlansIntroductionToAnxious,
        [Emotion.ANGRY]: SwitchesLessonPlansIntroductionToAngry,
        [Emotion.SAD]: SwitchesLessonPlansIntroductionToSad,
        [Emotion.SCARED]: SwitchesLessonPlansIntroductionToScared,
        [Emotion.EXCITED]: SwitchesLessonPlansIntroductionToExcited,
    },
    7: undefined // TO DO: should update this after new lesson plans UI level 7 and content has been uploaded
};

export const SwitchesEmotionsDetailView = ({ navigation: { navigate }, route: { params: { emotion } }, }: StackScreenProps<TeacherDrawerNavigatorPamarList, 'SwitchesEmotionsDetailView'>,) => {
    const currentClass = useReactiveVar(rvCurrentClass)
    const intensityLevel = getHomeIntensityLevel(currentClass?.emotionIntensityLevel)

    const emotionCards = intensityEmotionCards[intensityLevel][emotion]


    const getLessonPlanCard = (level: IntensityLevel, emotion: Emotion): CardTemplate | undefined => {
        const levelCard = lessonPlanCards[level];

        if (levelCard) {
            return levelCard[emotion];
        }
    };

    const lessonPlanCard = getLessonPlanCard(intensityLevel, emotion);

    const navigateBack = () => {
        rvSwitchesScreenState(SwitchesScreens.HOME)
        navigate(ScreenNames.SwitchesHome)
    }

    const handleNavigateToSwitchesLessonPlan = () => {

        if (lessonPlanCard) {
            navigate(ScreenNames.Card, { id: lessonPlanCard.cardId, previousScreen: "switches-home" });
        }
    };

    return (
        <>
            <View style={styles.back}>
                <BackButton onPress={navigateBack} />
            </View>

            <View style={styles.headerContainer}>
                <View style={styles.sideContainer} />
                {intensityLevel === 1 ? (
                    <DefaultText style={styles.header}>
                        About {emotion.toLowerCase()}
                    </DefaultText>
                ) : (
                    <View style={styles.midContainer}>
                        <DefaultText style={styles.header}>
                            Things to do when you're {emotion.toLowerCase()}
                        </DefaultText>
                    </View>
                )}
                < SwitchDetailsIntroducingEmotion emotion={emotion} lessonPlanCard={lessonPlanCard} onPress={handleNavigateToSwitchesLessonPlan} />
            </View>
            < ScrollView style={styles.scrollview} contentContainerStyle={styles.container} >

                {
                    emotionCards.map((Card) => {
                        const navigateToCard = () => {
                            navigate(ScreenNames.Card, { id: Card.cardId, previousScreen: 'emotion' })
                        }

                        return (
                            <TouchableOpacity key={Card.title} style={tileSizes.item} onPress={navigateToCard}>
                                <View style={tileSizes.tile}>
                                    <Card.Icon />
                                </View>
                                <DefaultText style={styles.cardtext}>
                                    {Card.title}
                                </DefaultText>
                            </TouchableOpacity>
                        )
                    }
                    )

                }
            </ScrollView>
        </>

    )
}


const styles = StyleSheet.create({

    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: 10,
        paddingBottom: 20,
    },
    content: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: "100%"

    },
    image: {
        width: 350,
        height: 450,
    },
    header: {
        fontSize: 32,
        fontWeight: 'bold',
        color: DecidaColors.Gray,
        textAlign: 'center',
    },
    item: {
        margin: 30,
        width: 200,
        height: 225,
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'center',
    },
    scrollview: {
        flexGrow: 0,
        width: '100%',
        height: '100%',

    },
    cardtext: {
        fontSize: 20,
        textAlign: 'center'
    },
    cardicon: {
        width: 175,
        height: 175,
    },
    back: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    extraTipsText: {
        textAlign: 'center',
        fontWeight: '600',
        fontSize: windowWidthcondition ? 20 : 10,

    } as TextStyle,
    imagecontainer: {
        padding: windowWidthcondition ? 10 : 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    extratips: {
        width: windowWidthcondition ? 50 : 25,
        height: windowWidthcondition ? 50 : 25,
    },
    emotionLessonPlanContainer: {
        position: 'absolute',
        right: 0,
        zIndex: 99
    },
    sideContainer: {
        flex: 0.2
    },
    midContainer: {
        flex: 1
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    }

})


