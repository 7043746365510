import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { AdvancedEmpathyLessonPlan } from './src/advanced-empathy-lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import SwitchCardVideo from '../../common/switch-card-video'

const advancedEmpathyVideo1: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/gYH0D52fXe8",
    title: ""
}

export const SwitchesLessonPlansAdvancedEmpathy: CardTemplate = {
    cardId: "ed8cb639-dc35-42c4-b8d6-05166ca0809f",
    title: 'Advanced empathy',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice cultivating empathy and compassion.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by playing this video <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='A Wise Lesson in Empathy [1:51].' link='https://youtu.be/gYH0D52fXe8' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Engage the students in a group discussion about the importance of empathy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to share examples of how empathy can influence interactions and the quality of relationships.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• The Layers of Empathy (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of different layers of empathy, going beyond basic understanding to a deeper level of emotional resonance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the three layers of empathy: cognitive empathy, emotional empathy, and compassionate empathy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Cognitive Empathy: Cognitive empathy refers to the ability to understand and intellectually recognise the thoughts, perspectives, and emotions of others. It involves putting yourself in someone else's shoes and gaining an objective understanding of their experiences without necessarily sharing their emotional state. Cognitive empathy allows individuals to see the world from different viewpoints and understand how others may think or feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Emotional Empathy: Emotional empathy, also known as affective empathy or empathic concern, involves experiencing the same or similar emotions as another person. It is the ability to emotionally resonate with others and feel what they are feeling. When someone is emotionally empathetic, they can feel joy, sadness, or other emotions alongside another person, often leading to a sense of emotional connection and shared experiences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Compassionate Empathy: Compassionate empathy combines both cognitive and emotional elements. It is the ability to understand someone's perspective and emotions (cognitive empathy) while also feeling a genuine concern and desire to alleviate their suffering or support their wellbeing (emotional empathy). Compassionate empathy goes beyond simply understanding and feeling; it motivates individuals to take action and provide support or assistance to others in need. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to reflect and share situations where they have experienced each layer of empathy.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Empathy-Building Activity (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Watch this video <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='The Present [4:17].' link='https://vimeo.com/152985022' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After watching or reading, have students discuss the characters' experiences, motivations, and emotions in small groups. Encourage them to empathise with the characters' perspectives and consider how they would feel in similar situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief to discuss insights from each of the groups.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of empathy and compassion, particularly perspective taking in fostering positive relationships and creating a supportive environment.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Role-Play and Response (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask each pair or group to choose one scenario to role-play.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- A friend is struggling with the pressures of academic expectations and feels overwhelmed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- A classmate is being bullied and feeling isolated.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- A family member is experiencing financial difficulties, causing stress at home.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- A teammate is going through a tough time due to an injury and feeling frustrated.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- A new student has recently moved to Australia and is finding it difficult to adjust to the cultural differences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct one student to portray the person facing the challenge, and the other student(s) to respond with empathy and support.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the responding student(s) to use active listening, understanding statements, and validating emotions in their responses.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After each role-play, allow time for reflection and discussion on how the empathetic response made the person facing the challenge feel and how it influenced the situation positively.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Closure (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to write down and share one insight from the lesson and one way they plan to cultivate empathy and compassion in the future.</DefaultText>
                        <View style={CommonStyles.lessonPlanImageContainer}>
                            <AdvancedEmpathyLessonPlan />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of emotional intelligence, empathy, and compassion in fostering healthy relationships. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>Extension:</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Perspective Taking: Divide students into pairs and provide them with different scenarios or situations. Each student takes turns sharing their thoughts, feelings, and perspectives about the given scenario. The goal is for each student to understand and empathise with their partner's point of view.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Story Sharing: Have students share personal stories or experiences related to a specific theme or topic, such as a time they felt excluded or a time when someone showed them kindness. After sharing, students discuss the emotions and challenges experienced in those situations, fostering empathy and understanding.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Role-Playing: Assign students different roles or characters and create a scenario that involves a conflict or challenge. Students act out the scenario, allowing them to step into someone else's shoes and experience different perspectives. Afterward, they reflect on the emotions and motivations of their character, promoting empathy.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Random Acts of Kindness: Encourage students to perform random acts of kindness for their classmates, school staff, or members of the community. This could include writing uplifting notes, helping someone with a task, or expressing gratitude. Reflecting on the impact of their actions can foster empathy and a sense of connection.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Empathy Mapping: Provide students with a template of a person's silhouette or outline. Instruct them to fill in the outline with drawings or words representing what they think a person might be thinking, feeling, and experiencing in different situations. This activity helps develop empathy by considering the internal experiences of others.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Empathy Circles: Create small groups of students and give each person a chance to share their thoughts and feelings on a specific topic. The rest of the group actively listens without interrupting or judging. This activity promotes deep listening and understanding, allowing students to develop empathy for their peers.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Encourage students to apply the strategies for building positive connections and cultivating empathy in their daily interactions. Provide opportunities for them to practice empathy through volunteering, community service, or engaging in collaborative projects that require understanding others' perspectives and needs.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Empathy worksheet' link={Worksheets.empathyWorksheet.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard or chart paper</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Slides</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Present:</DefaultText>
                        <DefaultTextWithLink text='https://vimeo.com/152985022' />
                        <DefaultText style={CommonStyles.lessonPlanText}>How to Help Teenagers Develop Empathy -</DefaultText>
                        <DefaultTextWithLink text='https://www.melbournechildpsychology.com.au/blog/help-teenagers-develop-empathy/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Creating connections that help young people thrive -</DefaultText>
                        <DefaultTextWithLink text='https://sites.ed.gov/nsaesc/files/2017/07/12758351-0-FINALRelationships-F1.pdf' />

                        <DefaultText style={CommonStyles.lessonPlanText}>A Wise Lesson in Empathy [1:51] -</DefaultText>
                        <DefaultTextWithLink text='https://youtu.be/gYH0D52fXe8' />
                        <SwitchCardVideo video={advancedEmpathyVideo1} />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}