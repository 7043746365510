import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import { DefaultText } from '../../common/default-text'
import { Worksheets } from '../../common/worksheets-imports'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'


export const SwitchesLessonPlansM9EmotionalAgility: CardTemplate = {
    cardId: "2225a36e-d83f-4e80-ba82-b2097b20bcf9",
    title: 'Emotional agility',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define flexibility and adaptability in emotional responses to develop emotional agility, and cultivate resilience and overall wellbeing through self reflection, positive coping strategies, and seeking support when needed.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>• Duration 45 minutes </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by discussing the concept of emotional agility. Explain that emotional agility is the ability to be flexible and adaptive in dealing with emotions, allowing us to respond in ways that align with our values and goals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to provide examples of times they’ve had to or seen others:  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Recognise and accept their emotion (e.g. accepting the disappointment of getting a lower grade on a test without harsh self judgment).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Adapting to changing circumstances (e.g. being flexible in a group project to adapt to different team dynamics to be able to collaborate effectively).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Making intention choices about responding (e.g. Taking a pause and consciously deciding to respond calmly and constructively during conflicts or disagreements with peers or teachers).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Practicing self care (e.g. getting enough sleep, engaging in hobbies or activities that bring joy, or taking breaks when needed).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Seeking help and support (e.g. seeking guidance from a teacher or counsellor for academic concerns or reaching out to a trusted friend or adult for emotional support).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Cultivating a growth mindset (e.g Viewing feedback from teachers as constructive guidance for improvement and using it as a tool to develop skills and achieve academic goals).</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Emotional Agility (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of emotional agility and its benefits. Explain that emotional agility helps individuals navigate life's challenges, build resilience, and maintain positive mental wellbeing, specifically:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Improved Emotional Wellbeing: Emotional agility helps individuals navigate and manage their emotions effectively. By recognising and accepting their emotions without judgment, individuals can develop a healthier relationship with their feelings, leading to improved emotional wellbeing and a greater sense of self acceptance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Enhanced Resilience: Emotional agility fosters resilience, which is the ability to bounce back from challenges and setbacks. By being adaptable and flexible in the face of adversity, individuals can recover more quickly and effectively, maintaining their motivation, and continuing to pursue their goals. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Better Stress Management: Emotional agility equips individuals with strategies to cope with stress more effectively. It helps them regulate their emotions, reducing the negative impact of stress on their physical and mental health. By developing healthy coping mechanisms, individuals can experience less stress and maintain a greater sense of calm and balance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Improved Decision Making: Emotional agility enables individuals to make more informed and intentional choices. By recognising and understanding their emotions, they can consider their feelings as valuable information in decision making processes. This leads to more thoughtful, rational, and value aligned decision making, which can enhance outcomes and satisfaction.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Enhanced Relationships: Emotional agility plays a vital role in building and maintaining positive relationships. By developing empathy and understanding others' emotions, individuals can communicate more effectively, resolve conflicts constructively, and cultivate deeper connections with others. This fosters healthy relationships, trust, and mutual support.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Increased Self awareness: Emotional agility promotes self awareness, which is the ability to recognize and understand one's thoughts, emotions, and behavioural patterns. By cultivating self awareness, individuals can gain a deeper understanding of their strengths, weaknesses, values, and goals. This self knowledge enables them to make choices and align their actions with their authentic selves.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Greater Adaptability: Emotional agility helps individuals adapt to changing circumstances and navigate uncertainty more effectively. By being open to new experiences, perspectives, and emotions, individuals can embrace change with greater ease and flexibility, leading to personal growth and expanded opportunities.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Enhanced Mental Health: Emotional agility can have positive impacts on mental health by promoting emotional regulation, reducing anxiety and depression symptoms, and fostering a more positive outlook on life. It helps individuals develop a healthier relationship with their emotions, leading to increased psychological wellbeing.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Components of Emotional Agility (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the four components of emotional agility:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Recognising emotions</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Accepting emotions</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Labelling emotions, and</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Responding intentionally.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss each component in detail, providing examples and engaging students in a discussion about how they can apply these components in their own lives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Recognising Emotions:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>Recognising emotions involves being aware of and acknowledging the emotions we are experiencing. It requires paying attention to the physical sensations, thoughts, and behavioural cues that indicate a particular emotion. For example: Recognising feeling anxious before a big test by noticing increased heart rate, sweaty palms, and racing thoughts. Recognising feeling joyful and excited when spending time with loved ones by noticing a warm and uplifting feeling in the body. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Accepting emotions: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>Accepting emotions means allowing ourselves to experience and embrace all emotions without judgment or resistance. It involves acknowledging that emotions are a natural part of being human and that every emotion holds valuable information. Examples include: Accepting and allowing oneself to feel sadness and grief after a loss or disappointment, without trying to suppress or avoid the emotions. Or accepting and embracing the feeling of anger without acting on it impulsively, recognising it as a signal that boundaries may have been crossed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Labelling emotions:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>Labelling emotions refers to putting a name or label to the specific emotion we are experiencing. It involves accurately identifying and articulating our emotions, which can help increase our understanding and ability to communicate them effectively. Examples include: Labelling the emotion as "frustration" when encountering a challenging task that requires more effort or skills. Or, labelling the emotion as "Sensational" when feeling appreciative of something or someone in our lives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Responding Intentionally:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>Responding intentionally means consciously choosing how to respond to emotions in a way that aligns with our values and goals. It involves taking deliberate actions and making thoughtful decisions rather than reacting impulsively. Examples include: Taking a deep breath and using relaxation techniques to calm down when feeling overwhelmed or stressed. Or, choosing to have a constructive conversation with a friend when feeling hurt or upset, instead of harbouring resentment or engaging in conflict.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity: Reflective Journaling (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide students with the Emotional Agility Reflection worksheet  with questions related to emotional agility. They can choose one or more questions to respond to, questions include:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- What are some recent situations or events that triggered strong emotional responses in me? How did I recognise and label those emotions? How did I respond to them?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- How do I typically react to uncomfortable or challenging emotions? Do I tend to avoid or suppress them, or do I allow myself to fully experience and accept them? How does this impact my overall wellbeing?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Can I recall a time when I responded to an emotional situation in a way that aligned with my values and goals? What helped me in choosing that intentional response? How did it make me feel?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- What are some common emotions that I find difficult to accept or handle? How can I work on developing more acceptance and compassion toward those emotions?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Are there any patterns or triggers in my life that consistently evoke specific emotional reactions? How can I become more aware of these triggers and respond to them in a more intentional and adaptive manner?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- How can I enhance my ability to recognise and label a wider range of emotions? Are there specific emotions that I struggle to identify or differentiate? How can I improve in this area?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- What are some self care practices or coping strategies that I can engage in when I notice myself experiencing intense emotions? How can I build a toolbox of effective techniques to regulate and manage my emotions?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Are there any beliefs or internal narratives that hinder my emotional agility? How can I challenge and reframe these beliefs to cultivate a more flexible and resilient mindset?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- How do I perceive and respond to the emotions of others? Do I demonstrate empathy and understanding, or do I struggle to connect with their emotions? How can I improve my ability to be present and supportive for others?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- How can I integrate the principles of emotional agility into my daily life? What practical steps can I take to apply the concepts of recognising, accepting, labelling, and intentionally responding to my emotions?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a group discussion where students can share their insights from the reflective journaling activity. Encourage them to share personal experiences, challenges, and strategies they have discovered for developing emotional agility.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Developing Emotional Agility Techniques (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Share practical techniques for developing emotional agility, such as mindfulness exercises, journaling, self reflection, and seeking support from trusted individuals. Emphasise the importance of practice and persistence in cultivating emotional agility.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students access the Switch4Schools program on their device and encourage them to explore the available switches under the explore all section to bookmark activities they’d like to try.  This could also be a homework activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the benefits of some of the techniques selected by the group and when they might be most helpful.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the main points discussed during the lesson, emphasising the value of emotional agility in navigating life's challenges and fostering wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to answer the other journaling questions at home when they have the opportunity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to continue exploring and practicing emotional agility in their daily lives, reminding them that it is a skill that can be developed over time. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>Extension: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Deliver the Values switch lesson and/or Smart goals lesson to help students articulate what the core goals and values are.  </DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Mastering the mind workbook, including section M9: Emotional Agility'} link={Worksheets.masteryMasteringTheMindLevel7.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Four Steps To Achieve Emotional Agility -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.susandavid.com/video/four-steps-to-achieve-emotional-agility/' link='https://www.susandavid.com/video/four-steps-to-achieve-emotional-agility/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>9 Surprising Superpowers of Knowing Your Core Values -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.psychologytoday.com/au/blog/changepower/201811/9-surprising-superpowers-knowing-your-core-values' link='https://www.psychologytoday.com/au/blog/changepower/201811/9-surprising-superpowers-knowing-your-core-values' />
                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}