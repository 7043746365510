import { ReactNode, useEffect, useState } from "react"
import { Platform } from "react-native"

export const ProgressiveLoader = ({ children }: { children: ReactNode[] | null }) => {
    const [index, setIndex] = useState(Platform.OS === 'web' ? children?.length || 0 - 1 : 0)

    useEffect(() => {
        setIndex(Platform.OS === 'web' ? children?.length || 0 : 0)
    }, [children])

    if (children == null) {
        return null
    }

    if (index < children.length) {
        setTimeout(() => setIndex(prev => prev + 1), 1)
    }

    return (
        <>
            {children.slice(0, index)}
        </>
    )
}
