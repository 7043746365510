import { gql, useMutation } from '@apollo/client';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { useEffect, useState } from 'react';
import { GestureResponderEvent, Pressable, StyleSheet, View } from 'react-native';
import { DeleteClassGroupMutation, DeleteClassGroupMutationVariables, UpdateClassGroupMutation, UpdateClassGroupMutationVariables } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { deleteClassGroup, updateClassGroup } from '../../common/graphql/mutations';
import ButtonGroupSubmitCancel from '../common/button-group-cancel-submit';
import { DefaultText } from '../common/default-text';
import InputDefault from '../common/input-default';
import { rvIsLoading } from '../common/loading';
import { showAlert } from '../common/universal-alert';
import { GetSchoolQueryResponseClassGroups } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query';
import { IconEdit } from '../icon/icon-edit';
import SchoolAdminClassList from './school-admin-class-list';
import SchoolAdminFormAddClass from './school-admin-form-add-class';
import { rvSidebarClassID } from './school-admin-state';

type Props = {
    group: GetSchoolQueryResponseClassGroups
}

const SchoolAdmiGroupsAndClassListItem = ({ group }: Props) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const [addClass, setAddClass] = useState(false)
    const [editGroup, setEditGroup] = useState(false)
    const [groupName, setgroupName] = useState(group.name || "")

    const [updateClassGroupMutation, { loading: loadingUpdateClassGroup }] = useMutation<UpdateClassGroupMutation, UpdateClassGroupMutationVariables>(gql`${updateClassGroup}`,)
    const [deleteClassGroupMutation, { loading: loadingDeleteClassGroup }] = useMutation<DeleteClassGroupMutation, DeleteClassGroupMutationVariables>(gql`${deleteClassGroup}`,)

    useEffect(() => {
        rvIsLoading(loadingUpdateClassGroup || loadingDeleteClassGroup)
    }, [loadingUpdateClassGroup, loadingDeleteClassGroup])

    const handlePressGroup = () => {
        if (addClass) {
            setAddClass(false)
        }

        if (editGroup) {
            return
        }

        setIsExpanded(prev => !prev)

    }

    const handlePressAddClass = (e: GestureResponderEvent) => {
        e.preventDefault()
        rvSidebarClassID(undefined)
        toggleAddClass()
    }


    const toggleAddClass = () => {
        setAddClass(prev => !prev)
    }

    const toggleEditGroup = () => {
        setEditGroup(prev => !prev)
    }
    const editGroupName = (text: string) => {
        setgroupName(text)
    }

    const handleConfirm = async () => {
        try {

            await updateClassGroupMutation({
                variables: {
                    input: {
                        id: group.id,
                        name: groupName,
                        _version: group._version,
                    }
                }
            })
            toggleEditGroup()
        } catch (error) {
            console.error(error)
        }
    }

    const handleRemoveClassGroup = () => {
        deleteClassGroupMutation({
            variables: {
                input: {
                    id: group.id,
                    _version: group._version
                }
            }
        })
    }

    const showRemoveGroupPrompt = () => {
        showAlert({
            title: "Remove class group",
            message: `Are you sure you want to remove ${group.name} from this campus?`,
            leftButtonText: "Cancel",
            rightButtonText: "Remove",
            onRightButtonPress: handleRemoveClassGroup
        })
    }
    return (
        <Pressable style={styles.container} onPress={handlePressGroup}>
            <View style={{ width: '100%', justifyContent: "space-between", flexDirection: 'row' }}>
                <View style={styles.classGroupContainer}>
                    <AntDesign name={isExpanded ? "down" : "right"} size={14} color={DecidaColors.AppleSystemGray2Dark} />
                    {editGroup ? (
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 10, width: '100%' }}>
                            <InputDefault
                                value={groupName}
                                placeholder='Group name'
                                label=''
                                onChangeText={editGroupName}
                                customInputStyle={{ height: 25, paddingVertical: 0, borderRadius: 0, marginBottom: 0 }}
                            />
                            <ButtonGroupSubmitCancel onConfirm={handleConfirm} onCancel={toggleEditGroup} />
                        </View>
                    ) : (
                        <DefaultText style={styles.groupName}>{group.name}</DefaultText>
                    )}
                </View>
                <View style={{ flexDirection: "row", alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                    {editGroup && (
                        <MaterialIcons onPress={showRemoveGroupPrompt} name="delete" size={20} color={DecidaColors.AppleSystemGray2Dark} />
                    )}
                    <Pressable onPress={toggleEditGroup} style={{ width: 18, height: 18 }} aria-label='Edit icon'>
                        <IconEdit stylefill={DecidaColors.AppleSystemGray2Dark} />
                    </Pressable>
                </View>
            </View>

            {isExpanded && (
                <View style={{ marginTop: 10 }}>
                    <SchoolAdminClassList classGroupID={group.id} schoolID={group.schoolID || ""} />
                    {addClass && (
                        <SchoolAdminFormAddClass group={group} onFinishSubmit={toggleAddClass} />
                    )}
                    <Pressable onPress={handlePressAddClass} style={styles.buttonAddClassContainer}>
                        <DefaultText style={styles.text}><AntDesign name="plus" size={14} color={DecidaColors.AppleSystemGray2Dark} /> Add class</DefaultText>
                    </Pressable>
                </View>
            )}
        </Pressable>
    )
}
export default SchoolAdmiGroupsAndClassListItem


const styles = StyleSheet.create({
    classGroupContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    buttonAddClassContainer: {
        paddingTop: 10,
        paddingLeft: 20,
    },
    text: {
        fontSize: 14,
        color: DecidaColors.GrayText,
    },
    container: {
        paddingHorizontal: 10,
        paddingVertical: 10,
        backgroundColor: DecidaColors.GrayBackground,
        marginVertical: 5,
        borderRadius: 4,
        minWidth: 400
    },
    groupName: {
        fontSize: 16,
        fontWeight: 'bold',
        color: DecidaColors.AppleSystemGray2Dark
    }
})