import { StyleSheet, View } from 'react-native'
import { School } from '../../common/API'
import { CampusAccordion } from '../common/campus-accordion'
import { List } from '../common/list'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AdminStackNavigatorParamList } from './admin-route-param-types'
import { ScreenNames } from '../common/screen-names'
import { isDefined } from '../../common/is-defined'

type Props = {
    school: School
}


const AdminCampusList = ({ school }: Props) => {

    const currentCampusses = school.campuses?.items.filter(isDefined) || []

    const { navigate } = useNavigation<StackNavigationProp<AdminStackNavigatorParamList>>()

    const addCampus = () => {
        navigate(ScreenNames.AdminCampus, { campusID: undefined, schoolID: school.id })
    }
    const editCampus = (campusID: string) => {
        navigate(ScreenNames.AdminCampus, { campusID, schoolID: school.id })
    }
    return (
        <View style={{ marginBottom: 20 }}>
            <List
                type='accordion'
                title='Campuses'
                items={[]}
                add={addCampus}
                edit={editCampus}
            />
            <CampusAccordion campuses={currentCampusses} />
        </View>
    )
}
export default AdminCampusList
const styles = StyleSheet.create({})