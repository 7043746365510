import { StyleSheet, Text, View } from 'react-native'
import { DefaultText } from './default-text';
import { DecidaColors } from '../../common/decida-colors';

export const SectionTitleAndText = ({ title, text, withoutText }: { title: string; text?: string, withoutText?: boolean }) => {
    return (
        <DefaultText style={[styles.contentText, { fontWeight: '600',}]}>{title}: <DefaultText style={styles.contentText}>{withoutText ? "" : text || "-"}</DefaultText></DefaultText>
    )
}

const styles = StyleSheet.create({
    contentText: {
        color: DecidaColors.GrayText,
        fontWeight: '500',
        fontSize: 16,
        marginBottom: 8
    },
})