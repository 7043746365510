import { View } from 'react-native'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { NineNineNineIcon } from '../../icons/999-icon'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { NineNineNineIconInverted } from '../../icons/999-icon-inverted'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithIndentationGrayDot } from '../../../common/default-text-with-indentation-gray-dot'





export const SwitchesActivitiesNumberAndWordGamesNineNineNine: CardTemplate = {
    cardId: "547924ec-8fb9-4a06-bd5c-4e5ef0ff99e0",
    title: '999',
    Header: NineNineNineIconInverted,
    Icon: NineNineNineIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Memory, visualisation and deduction skills."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Calculator"} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Pick a partner, and choose one to be the detective and the other to be the secret number keeper."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The secret number keeper enters a three-digit number (a number from 100 to 999) and tells the detective that they are ready."} />
            <DefaultTextWithoutArrowsItallic textdata={"The Detective's goal is to get the secret number keepers number to equal 999 in the least amount of moves possible"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The detective then calls out a number (any number under 999). The first player adds this number to their original number and then informs the detective for two things:"} />
            <DefaultTextWithIndentationGrayDot textdata={"how many nines there in the total"} />
            <DefaultTextWithIndentationGrayDot textdata={"any other digit, but not its position"} />
            <DefaultTextWithoutArrowsItallic textdata={"for example if the detective says “Add 123.” The secret number keeper does so and gets a total of 420, so they say, “No nines and a four.”"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Continue the process of the detective calling out a number, the secret number keeper adding it to the total, and giving information about nines and one other digit. Keep track of how many moves the detective takes."} />
            <DefaultTextWithoutArrowsItallic textdata={"The detective might say, “Add 555,” hoping to change the four to a nine regardless of its position. The secret keeper adds the 555 and gets 975. They say, “One nine and a five.” This is the end of the second move and the detective suspects that the digit in hundreds column could be a nine."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"If the total goes over 999 any time during the play of the game, the secret number keeper returns to the previous total and says, “Overflow!” and does not give any additional information. This is counted as a move."} />
            <DefaultTextWithoutArrowsItallic textdata={"In our example, the detective guesses that the nine is in the hundreds place, though he is not sure of the location of the five. To make the maximum use of the situation, he asks the secret number keeper to add 44. When she does this, she gets a total of 1019. So she presses the minus key, cancels the last addition, and says, “Overflow.” This is the end of the third move. Here the detective has useful information. Their hunch about the nine in the hundreds place is confirmed. They also figure out that the tens digit (the digit in the middle) is greater than five because only then could the total have gone over a thousand. Therefore, the five must be in the units place. He calls out, “Add four.” The screet number keepers total is now 979 and she responds with “Two nines and a seven.”The detective has figured out the entire number now. To finish off the game, they say, “Add 20.” The secret number keeper does so and announces “Three nines.” The game ends in five moves."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The game is played again with the roles reversed. The detective in the previous game now selects a secret number and the other becomes the detective."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />

        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.NUMBER_AND_WORD_GAMES,
    whyDoesItWorkText: '',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/999.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.NUMBER_AND_WORD_GAMES],
    action: [CardActionType.Action],
    level: []
}

