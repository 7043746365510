import { PropsWithChildren } from 'react'
import { View, StyleSheet } from 'react-native'

export const RootView = ({ children }: PropsWithChildren<{}>) =>
    <View style={styles.view}>{children}</View>

const styles = StyleSheet.create({
    view: {
        flex: 1,
        flexDirection: 'column',
    }
})