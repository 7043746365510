import { createStackNavigator } from '@react-navigation/stack'
import { ChangePasswordProfile } from '../common/change-password-profile'
import { RootView } from '../common/root-view'
import { ScreenNames } from '../common/screen-names'
import { SchoolAdminNavigationParams } from './school-admin-navigation-params'
import { SchoolAdminProfile } from './school-admin-profile'
import { ForgotPasswordRequest } from '../login/forgot-password-request'
import { ForgotPasswordSubmit } from '../login/forgot-password-submit'

const Stack = createStackNavigator<SchoolAdminNavigationParams>()

export const SchoolAdminProfileMain = () => {

    return (
        <RootView>
            <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={ScreenNames.SchoolAdminProfile}>
                <Stack.Screen name={ScreenNames.SchoolAdminProfile} component={SchoolAdminProfile} options={{ title: 'Profile' }} />
                <Stack.Screen name={ScreenNames.ChangePasswordProfile} component={ChangePasswordProfile} />
                <Stack.Screen name={ScreenNames.LoginForgotPasswordRequest} component={ForgotPasswordRequest} />
                <Stack.Screen name={ScreenNames.LoginForgotPasswordSubmit} component={ForgotPasswordSubmit} />
            </Stack.Navigator>
        </RootView>
    )
}
