import { useMutation, useQuery } from '@apollo/client'
import { Picker } from '@react-native-picker/picker'
import { StackScreenProps } from '@react-navigation/stack'
import gql from 'graphql-tag'
import { StyleSheet, View } from 'react-native'
import uuid from 'react-native-uuid'
import { CreateSchoolMutation, CreateSchoolMutationVariables, DeleteSchoolMutation, DeleteSchoolMutationVariables, GetListTeacherBySchoolIDQuery, GetListTeacherBySchoolIDQueryVariables, GetSchoolQuery, GetSchoolQueryVariables, School, SchoolAdminUI, SchoolStatus, Teacher, UpdateSchoolMutation, UpdateSchoolMutationVariables } from '../../common/API'
import { type PrimitiveOnly } from '../../common/common-types'
import { DecidaColors } from '../../common/decida-colors'
import ActiveSchoolStudentsCount from '../common/active-school-students-count'
import { CheckBox } from '../common/checkbox'
import { DefaultFontSize } from '../common/const'
import DropdownPickerTimezone from '../common/dropdown-picker-timezone'
import { DeleteFunction, Form, SaveFunction } from '../common/form'
import { Loading } from '../common/loading'
import { ScreenNames } from '../common/screen-names'
import { adminGetSchoolQuery } from '../custom-graphql/queries/admin-custom-queries/admin-get-school-query'
import AdminCampusList from './admin-campus-list'
import { CONTAINER_INPUT_WIDTH } from './admin-consts'
import { AdminStackNavigatorParamList } from './admin-route-param-types'
import { AdminSchoolClassesList } from './admin-school-classes-list'
import { AdminSchoolFunctions } from './admin-school-functions'
import { AdminSchoolGoGroupsList } from './admin-school-go-groups-list'
import { AdminSchoolInvoicesList } from './admin-school-invoices-list'
import { AdminSchoolAdminsList } from './admin-school-school-admins-list'
import { AdminSchoolTeachersList } from './admin-school-teachers-list'
import { createSchoolMutation, deleteSchoolMutation, getListTeacherBySchoolIDQuery, mutateSchoolRefetchQueries as refetchQueries, updateSchoolMutation } from './graphql-scripts'
import AdminSchoolNonTeachingStaffsList from './admin-school-non-teaching-staffs-list'

export const AdminSchool = ({ route: { params }, navigation: { navigate } }: StackScreenProps<AdminStackNavigatorParamList, 'AdminSchool'>) => {
  const { data: schoolData, loading: schoolDataLoading } = useQuery<GetSchoolQuery, GetSchoolQueryVariables>(gql`${adminGetSchoolQuery}`, { variables: { id: params?.id || '' }, skip: params?.id == undefined })
  const { data: teacherList, loading: teacherListLoading } = useQuery<GetListTeacherBySchoolIDQuery, GetListTeacherBySchoolIDQueryVariables>(gql`${getListTeacherBySchoolIDQuery}`, { variables: { schoolID: params?.id || '', filter: { _deleted: { ne: true } }, limit: 5000 }, skip: params?.id == undefined })

  const [createSchool] = useMutation<CreateSchoolMutation, CreateSchoolMutationVariables>(gql`${createSchoolMutation}`, { refetchQueries })
  const [updateSchool] = useMutation<UpdateSchoolMutation, UpdateSchoolMutationVariables>(gql`${updateSchoolMutation}`, { refetchQueries })
  const [deleteSchool] = useMutation<DeleteSchoolMutation, DeleteSchoolMutationVariables>(gql`${deleteSchoolMutation}`, { refetchQueries })

  if (schoolDataLoading || teacherListLoading) {
    return <Loading isLoading />
  }

  const goToAdminSchool = () => {
    navigate(ScreenNames.AdminSchools)
  }

  const onSave: SaveFunction<PrimitiveOnly<School>> = async (state, modelInstance) => {
    if (state.trialEndDate?.value) {
      const split = state.trialEndDate.value.split('/').map(s => Number(s))

      if (split.length !== 3) {
        throw 'Trial end date: expected format dd/mm/yyyy'
      }
      if (!Number.isInteger(split[0]) || split[0] < 1 || split[0] > 31) {
        throw 'Trial end date: invalid date'
      }
      if (!Number.isInteger(split[1]) || split[1] < 1 || split[1] > 12) {
        throw 'Trial end date: invalid month'
      }
      if (!Number.isInteger(split[2]) || split[2] < 2023 || split[2] > 3000) {
        throw 'Trial end date: invalid year'
      }
    }

    if (state.renewalDate?.value) {
      let split = state.renewalDate.value.split('/').map(s => Number(s))

      if (split.length !== 2) {
        throw 'Renewal date: expected format dd/mm'
      }
      if (!Number.isInteger(split[0]) || split[0] < 1 || split[0] > 31) {
        throw 'Renewal date: invalid date'
      }
      if (!Number.isInteger(split[1]) || split[1] < 1 || split[1] > 12) {
        throw 'Renewal date: invalid month'
      }
    }

    if (modelInstance?.id == undefined || modelInstance.id === '') {
      return (await createSchool({
        variables: {
          input: {
            id: uuid.v4().toString(),
            name: state.name?.value || '',
            _version: 1,
            activeStudents: 0,
            checkInCountAllTime: 0,
            checkInCountYTD: 0,
            licenseTotal: state.licenseTotal?.value || '',
            notes: state.notes?.value,
            renewalDate: state.renewalDate?.value,
            status: state.status?.value as SchoolStatus,
            timezone: state.timezone?.value,
            trialEndDate: state.trialEndDate?.value,
            checkInCountRollingWeekMonday: 0,
            checkInCountRollingWeekTuesday: 0,
            checkInCountRollingWeekWednesday: 0,
            checkInCountRollingWeekThursday: 0,
            checkInCountRollingWeekFriday: 0,
            checkInCountRollingWeekSaturday: 0,
            checkInCountRollingWeekSunday: 0,
            wholeSchoolSubscription: !!state.wholeSchoolSubscription?.value,
            billingAddress: state.billingAddress?.value,
            billingNotes: state.billingNotes?.value,
            invoiceUrl: state.invoiceUrl?.value,
            abn: state.abn?.value,
            schoolAdminUI: state.schoolAdminUI?.value as SchoolAdminUI || SchoolAdminUI.LIST,
            teacherWellbeing: !!state.teacherWellbeing?.value || false,
            staffWellbeing: !!state.staffWellbeing?.value || false,
            wellbeingFeature: !!state.wellbeingFeature?.value || false,
          }
        }
      })).data?.createSchool!
    } else {
      return (await updateSchool({
        variables: {
          input: {
            id: modelInstance.id,
            name: state.name?.value || '',
            _version: modelInstance._version,
            activeStudents: modelInstance.activeStudents,
            checkInCountYTD: modelInstance.checkInCountYTD,
            licenseTotal: state.licenseTotal?.value || '',
            notes: state.notes?.value,
            renewalDate: state.renewalDate?.value,
            status: state.status?.value as SchoolStatus,
            timezone: state.timezone?.value,
            trialEndDate: state.trialEndDate?.value,
            wholeSchoolSubscription: !!state.wholeSchoolSubscription?.value,
            billingAddress: state.billingAddress?.value,
            billingNotes: state.billingNotes?.value,
            invoiceUrl: state.invoiceUrl?.value,
            abn: state.abn?.value,
            schoolAdminUI: state.schoolAdminUI?.value as SchoolAdminUI || SchoolAdminUI.LIST,
            teacherWellbeing: !!state.teacherWellbeing?.value,
            staffWellbeing: !!state.staffWellbeing?.value,
            wellbeingFeature: !!state.wellbeingFeature?.value
          }
        }
      })).data?.updateSchool!
    }
  }

  const onDelete: DeleteFunction<School> = async (modelInstance) => {
    if (modelInstance.id) {
      await deleteSchool({
        variables: {
          input: {
            id: modelInstance.id,
            _version: modelInstance._version,
          }
        }
      })
    }
  }

  return (
    <Form
      model={schoolData?.getSchool as School | undefined}
      goBack={goToAdminSchool}
      name='school'
      onSave={onSave}
      onDelete={onDelete}
      config={{
        name: {},
        status: {
          component: ({ state }) => (
            <View style={styles.pickerWrapper}>
              <Picker
                selectedValue={state.status?.value as SchoolStatus || ''}
                onValueChange={(value: SchoolStatus | '') => state.status?.setter(value)}
                style={styles.picker}
              >
                <Picker.Item label="" value={undefined} />
                <Picker.Item label="Archived" value={SchoolStatus.ARCHIVED} />
                <Picker.Item label="On hold" value={SchoolStatus.ON_HOLD} />
                <Picker.Item label="On trial" value={SchoolStatus.ON_TRIAL} />
                <Picker.Item label="Subscribed" value={SchoolStatus.SUBSCRIBED} />
                <Picker.Item label="Test" value={SchoolStatus.TEST} />
              </Picker>
            </View>
          )
        },
        timezone: {
          label: 'Timezone',
          component: ({ state }) => <View style={styles.pickerWrapper}><DropdownPickerTimezone value={state.timezone?.value || ''} setValue={(value) => state.timezone?.setter(value)} /></View>,
        },
        licenseTotal: {},
        trialEndDate: {
          textInputProps: {
            placeholder: 'dd/mm/yyyy'
          },
          isOptional: true,
        },
        renewalDate: {
          textInputProps: {
            placeholder: 'dd/mm'
          },
          isOptional: true,
        },
        billingAddress: {
          isOptional: true
        },
        abn: {
          isOptional: true
        },
        notes: {
          label: "Switch4school notes",
          textInputProps: {
            multiline: true
          },
          isOptional: true
        },
        billingNotes: {
          label: "School notes",
          textInputProps: {
            multiline: true,
            placeholder: "Notes for schooladmin"
          },
          isOptional: true
        },
        schoolAdminUI: {
          component: ({ state }) => (
            <View style={styles.pickerWrapper}>
              <Picker
                selectedValue={state.schoolAdminUI?.value as SchoolAdminUI || ''}
                onValueChange={(value: SchoolAdminUI | '') => state.schoolAdminUI?.setter(value)}
                style={styles.picker}
              >
                <Picker.Item label="" value={undefined} />
                <Picker.Item label="List" value={SchoolAdminUI.LIST} />
                <Picker.Item label="Hierarchy" value={SchoolAdminUI.HIERARCHY} />
              </Picker>
            </View>
          ),
          isOptional: true,
        },
        wholeSchoolSubscription: {
          isOptional: true,
          label: "",
          component: ({ state }) => <CheckBox checked={!!state.wholeSchoolSubscription?.value} onChange={value => state.wholeSchoolSubscription?.setter(value)} />,
          containerStyle: { flexDirection: 'row', width: 330 }
        },
        teacherWellbeing: {
          isOptional: true,
          label: "Teacher check-ins",
          component: ({ state }) => <CheckBox checked={!!state.teacherWellbeing?.value} onChange={value => state.teacherWellbeing?.setter(value)} />,
          containerStyle: { flexDirection: 'row', width: 330 }
        },
        staffWellbeing: {
          isOptional: true,
          label: "Non teaching staff check-ins",
          component: ({ state }) => <CheckBox checked={!!state.staffWellbeing?.value} onChange={value => state.staffWellbeing?.setter(value)} />,
          containerStyle: { flexDirection: 'row', width: 330 }
        },
        wellbeingFeature: {
          isOptional: true,
          label: "Additional check-in questions: Wellbeing",
          component: ({ state }) => <CheckBox checked={!!state.wellbeingFeature?.value} onChange={value => state.wellbeingFeature?.setter(value)} />,
          containerStyle: { flexDirection: 'row', width: 330 }
        },
      }}
      additionalComponents={(item) => additionalComponents(item, teacherList?.getListTeacherBySchoolID?.items as Teacher[])}
      multipleColumn
    />
  )
}

const additionalComponents = (school: School | undefined, teachers: Teacher[] | undefined) => (
  school &&
  <>
    <AdminSchoolInvoicesList school={school} />
    <ActiveSchoolStudentsCount school={school} />
    <AdminSchoolFunctions school={school} />
    <View style={styles.fullWidth} >
      <AdminSchoolTeachersList teachers={teachers || []} schoolID={school.id} />
      <AdminSchoolAdminsList school={school} />
      <AdminSchoolNonTeachingStaffsList school={school} />
      {school.schoolAdminUI === SchoolAdminUI.HIERARCHY ?
        (
          <>
            <AdminCampusList school={school} />
          </>
        ) : (
          <>
            <AdminSchoolGoGroupsList school={school} />
          </>
        )}
      <AdminSchoolClassesList school={school} />
    </View>
  </>
)

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%'
  },
  picker: {
    width: CONTAINER_INPUT_WIDTH,
    borderColor: DecidaColors.Gray,
    borderWidth: 1,
    fontSize: DefaultFontSize,
    padding: 8,
    paddingLeft: 7,
    paddingRight: 7,
    marginTop: 7,
    marginBottom: 7,
    borderRadius: 4,
    backgroundColor: DecidaColors.InputField
  },
  pickerWrapper: {
    height: 55,
    maxWidth: '100%'
  }
})
