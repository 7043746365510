import { makeVar, useReactiveVar } from '@apollo/client'
import { useEffect, useState } from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'

export const rvIsLoading = makeVar(false)

export const Loading = ({ isLoading }: { isLoading?: boolean }) => {
    const loadingRv = useReactiveVar(rvIsLoading)
    const [internalIsLoading, setInternalIsLoading] = useState(false)

    useEffect(() => {
        setInternalIsLoading(loadingRv)

        rvIsLoading.onNextChange((status) => {
            // listen to rv changes
            if (status === false) {
                setInternalIsLoading(false)
            }
        })
    }, [loadingRv])

    if (isLoading || internalIsLoading) {
        return (
            <View style={styles.overlay}>
                <View style={styles.background} />
                <ActivityIndicator size='large' />
            </View>
        )
    }

    return null
}

const styles = StyleSheet.create({
    overlay: {
        position: 'absolute',
        zIndex: 99,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: DecidaColors.Gray,
        opacity: 0.8
    },
})