import { gql, useQuery, useSubscription } from '@apollo/client'
import { StackScreenProps, createStackNavigator } from '@react-navigation/stack'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import {
    GetStudentClassByClassIdQueryVariables,
    ListCheckInSessionsByClassIdQuery,
    ListCheckInSessionsByClassIdQueryVariables,
    ListClassLoginByClassIDQuery,
    ListClassLoginByClassIDQueryVariables,
    OnMutateClassByIdSubscription,
    OnMutateClassByIdSubscriptionVariables,
    OnMutateClassLoginByClassIDSubscription,
    OnMutateClassLoginByClassIDSubscriptionVariables,
    OnMutateStudentClassByClassIdSubscription,
    OnMutateStudentClassByClassIdSubscriptionVariables
} from '../../common/API'
import {
    listCheckInSessionsByClassId,
    listClassLoginByClassID
} from '../../common/graphql/queries'
import {
    onMutateClassById,
    onMutateClassLoginByClassID,
    onMutateStudentClassByClassId
} from '../../common/graphql/subscriptions'
import { handleRestartApp } from '../common/handle-restart-app'
import { RootView } from '../common/root-view'
import { ScreenNames } from '../common/screen-names'
import { updateListQueryCache } from '../common/update-list-query-cache'
import { TeacherAddStudent } from '../teacher/teacher-add-student'
import { TeacherClassAdmin } from '../teacher/teacher-class-admin'
import { TeacherClassAdminCurrentClassLogin } from '../teacher/teacher-class-admin-current-class-login'
import { TeacherClassAdminSchedule } from '../teacher/teacher-class-admin-schedule'
import { TeacherCreateStudentNickname } from '../teacher/teacher-create-student-nickname'
import { TeacherCreateStudentPickAvatar } from '../teacher/teacher-create-student-pick-avatar'
import { TeacherEditStudent } from '../teacher/teacher-edit-student'
import { TeacherEditStudentAvatar } from '../teacher/teacher-edit-student-avatar'
import { ListStudentClassAndStudentDataByClassIdCustomQuery, listStudentClassAndStudentDataByClassIdCustomQuery } from '../teacher/teacher-graphql-scripts'
import { TeacherStudentIndividualStats } from '../teacher/teacher-student-individual-stats'
import { AdminClassDetails } from './admin-class-details'
import { AdminClassNavigatorParamList, AdminStackNavigatorParamList } from './admin-route-param-types'
import { AdminViewClassStats } from './admin-view-class-stats'

const Stack = createStackNavigator<AdminClassNavigatorParamList>()

export const AdminClassMain = ({ route: { params } }: StackScreenProps<AdminStackNavigatorParamList, "AdminClassMain">) => {


    const [currentClassID, setCurrentClassID] = useState('');

    useEffect(() => {
        setCurrentClassID(params?.params?.classID || '');
    }, [params?.params?.classID]);

    const { data: checkInSessionsResponse } = useQuery<
        ListCheckInSessionsByClassIdQuery,
        ListCheckInSessionsByClassIdQueryVariables
    >(
        gql`
      ${listCheckInSessionsByClassId}
    `,
        {
            variables: { classID: currentClassID || '', limit: 5000 },
            skip: !currentClassID,
        }
    );

    const classCheckInSessions = _.orderBy(
        checkInSessionsResponse?.listCheckInSessionsByClassId?.items.filter(
            (item) => item?._deleted !== true
        ),
        'updatedAt',
        'desc'
    );

    const { error: errorClassSubscription } = useSubscription<OnMutateClassByIdSubscription, OnMutateClassByIdSubscriptionVariables>(gql`${onMutateClassById}`, { variables: { id: currentClassID }, skip: !currentClassID })

    const { error: errorStudentClassSubscription } = useSubscription<
        OnMutateStudentClassByClassIdSubscription,
        OnMutateStudentClassByClassIdSubscriptionVariables
    >(
        gql`
      ${onMutateStudentClassByClassId}
    `,
        {
            variables: { classID: currentClassID || '' },
            onData: updateListQueryCache<ListStudentClassAndStudentDataByClassIdCustomQuery, GetStudentClassByClassIdQueryVariables, OnMutateStudentClassByClassIdSubscription>({ listQuery: listStudentClassAndStudentDataByClassIdCustomQuery, listQueryName: 'getStudentClassByClassId', subscriptionName: 'onMutateStudentClassByClassId', variables: { classID: currentClassID, limit: 5000 } },),
            skip: currentClassID === '',
        }
    );
    
    const { error: classLoginSubscriptionError } = useSubscription<
        OnMutateClassLoginByClassIDSubscription,
        OnMutateClassLoginByClassIDSubscriptionVariables
    >(
        gql`
      ${onMutateClassLoginByClassID}
    `,
        {
            variables: { classID: currentClassID },
            onData: updateListQueryCache<
                ListClassLoginByClassIDQuery,
                ListClassLoginByClassIDQueryVariables,
                OnMutateClassLoginByClassIDSubscription
            >({
                listQuery: listClassLoginByClassID,
                listQueryName: 'listClassLoginByClassID',
                subscriptionName: 'onMutateClassLoginByClassID',
            }),
            skip: currentClassID === '',
        }
    );

    if (
        classLoginSubscriptionError !== undefined ||
        errorStudentClassSubscription !== undefined ||
        errorClassSubscription !== undefined
    ) {
        handleRestartApp()
    }

    return (
        <RootView>
            <Stack.Navigator initialRouteName={ScreenNames.TeacherClassAdmin} screenOptions={{ headerShown: false }}>
                <Stack.Screen name={ScreenNames.TeacherClassAdmin} component={TeacherClassAdmin} />

                <Stack.Screen name={ScreenNames.AdminClassDetails} component={AdminClassDetails} />
                {/* CLASS ADMIN */}
                <Stack.Screen
                    name={ScreenNames.TeacherClassAdminCurrentClassLogin}
                    component={TeacherClassAdminCurrentClassLogin}
                />
                <Stack.Screen
                    name={ScreenNames.TeacherClassSchedule}
                    component={TeacherClassAdminSchedule}
                />
                <Stack.Screen
                    name={ScreenNames.TeacherAddStudent}
                    component={TeacherAddStudent}
                />

                {/* STUDET PROFILE */}
                <Stack.Screen
                    name={ScreenNames.TeacherCreateStudentPickAvatar}
                    component={TeacherCreateStudentPickAvatar}
                />
                <Stack.Screen
                    name={ScreenNames.TeacherCreateStudentNickname}
                    component={TeacherCreateStudentNickname}
                />
                <Stack.Screen
                    name={ScreenNames.TeacherEditStudent}
                    component={TeacherEditStudent}
                />
                <Stack.Screen
                    name={ScreenNames.TeacherEditStudentAvatar}
                    component={TeacherEditStudentAvatar}
                />

                {/* STATS */}
                <Stack.Screen
                    name={ScreenNames.TeacherStudentIndividualStats}
                    component={TeacherStudentIndividualStats}
                />
                <Stack.Screen name={ScreenNames.AdminViewClassStats} component={AdminViewClassStats} />
            </Stack.Navigator>
        </RootView>
    )
}