import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { TheVoyagerIcon } from '../../icons/the-voyager-icon'
import { TheVoyagerIconInverted } from '../../icons/the-voyager-icon-inverted'

export const SwitchesActivitiesSeparationAnxietyTheVoyager: CardTemplate = {
    cardId: "85185893-afe1-48fa-a269-b39bf1b16426",
    title: 'The voyager',
    Header: TheVoyagerIconInverted,
    Icon: TheVoyagerIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Helping your student explore their new environment."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Acknowledge their feelings: Begin by acknowledging your student's feelings of uncertainty about new places. Assure them that it's perfectly normal to feel that way."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Introduce the classroom: Take your student on a tour of the classroom, pointing out different objects and areas. For example: Describe the posters on the wall, the colour pencils on the desks, or the toys in the toy box."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Hands-on exploration: Encourage your student to touch and interact with the objects you've shown them. This will help them feel more comfortable and familiar with their new surroundings."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Positive reinforcement: Praise your student for their exploration and bravery in trying new things."} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />

        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.SEPARATION_ANXIETY,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <>
        </>

    ),
    audioUrl: "",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.SEPARATION_ANXIETY],
    action: [CardActionType.Action],
    level: []

}

