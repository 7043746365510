import { createStackNavigator } from '@react-navigation/stack'
import { RootView } from '../common/root-view'
import { ScreenNames } from '../common/screen-names'
import { AdminClass } from './admin-class'
import { AdminClassMain } from './admin-class-main'
import { AdminStackNavigatorParamList } from './admin-route-param-types'
import { AdminSchool } from './admin-school'
import { AdminSchoolAdmin } from './admin-school-admin'
import { AdminSchools } from './admin-schools'
import { AdminTeacher } from './admin-teacher'
import { AdminSchoolImportData } from './import-school-data/admin-school-import-data'
import AdminCampus from './admin-campus'
import { AdminSchoolImports } from './import-school-data/admin-school-imports'
import { AdminSchoolStudentList } from './admin-school-student-list'
import AdminSchoolNonTeachingStaff from './admin-school-non-teaching-staff'

const Stack = createStackNavigator<AdminStackNavigatorParamList>()

export const AdminSchoolsMain = () => (
    <RootView>
        <Stack.Navigator initialRouteName={ScreenNames.AdminSchools} screenOptions={{ headerShown: false }}>
            <Stack.Screen name={ScreenNames.AdminSchools} component={AdminSchools} />
            <Stack.Screen name={ScreenNames.AdminSchool} component={AdminSchool} />
            <Stack.Screen name={ScreenNames.AdminSchoolAdmin} component={AdminSchoolAdmin} />
            <Stack.Screen name={ScreenNames.AdminNonTeachingStaff} component={AdminSchoolNonTeachingStaff} />
            <Stack.Screen name={ScreenNames.AdminTeacher} component={AdminTeacher} />
            <Stack.Screen name={ScreenNames.AdminClass} component={AdminClass} />
            <Stack.Screen name={ScreenNames.AdminClassMain} component={AdminClassMain} />
            <Stack.Screen name={ScreenNames.AdminSchoolImportTeachers} component={AdminSchoolImportData} />
            <Stack.Screen name={ScreenNames.AdminSchoolImports} component={AdminSchoolImports} />
            <Stack.Screen name={ScreenNames.AdminSchoolStudentList} component={AdminSchoolStudentList} />
            <Stack.Screen name={ScreenNames.AdminCampus} component={AdminCampus} />
        </Stack.Navigator>
    </RootView>
)