import { View } from 'react-native'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextWithIndentation } from '../../common/default-text-with-indentation'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { RealityCheckHeaderIcon } from './src/reality-check-header-icon'
import { RealityCheckIcon } from './src/reality-check-icon'
import { RealityCheckLineDrawing } from './src/reality-check-line-drawing'


export const SwitchesEmotionsAnxiousRealityCheck: CardTemplate = {
    cardId: "90102696-774f-4b20-8d46-5ac15c909c25",
    title: 'Reality check',
    Header: RealityCheckHeaderIcon,
    Icon: RealityCheckIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <View style={{ flex: 1 }}>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata='Bringing the mind into the present and recentring your focus.' />
            </View>
        </>
    ),
    Images: RealityCheckLineDrawing,
    CardContent: () => (
        <>
          
            <DefaulttextWithRightArrow textdata="Ask yourself these questions to centre yourself back into the here and now." />
            <DefaultTextWithIndentation textdata='a. What are 5 things that I can see around me?' />
            <DefaultTextWithIndentation textdata='b. What is the time?' />
            <DefaultTextWithIndentation textdata='c. Is my heart racing too fast?' />
            <DefaultTextWithIndentation textdata='d. Am I feeling too hot or too cold?' />
            <DefaultTextWithIndentation textdata='e. Are there any overly loud noises nearby?' />
            <DefaultTextWithIndentation textdata='f. Am I tired?' />
            <DefaultTextWithIndentation textdata='g. Am I hungry?' />
            <DefaulttextWithRightArrow textdata="Now that you have interrupted your thoughts, shift your focus to the present, allowing you to view any remaining anxieties objectively. Ask yourself..." />
            <DefaultTextWithIndentation textdata='a. Are my thoughts helping me?' />
            <DefaultTextWithIndentation textdata='b. What would a reasonable person think?' />
            <DefaulttextWithRightArrow textdata="Now, pick one thing, one action, or one thought that will help you do what you know needs to be done. " />
            <DefaulttextWithRightArrow textdata="Once that is done, feel the momentum and do something else." />
            <DefaulttextWithRightArrow textdata="Keep going." />

        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Reality+check.m4a",
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: `This activity assists in managing anxiety by refocusing attention on the present moment. Engaging with the senses and acknowledging physical sensations through the questions provided helps alleviate tension and enhances self awareness. Regular practice encourages emotional regulation and mindfulness, contributing to a calmer, more centred state of mind.`,
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Explore the effects of anxiety, how it often pushes the mind into the future, and learn strategies for staying in the present to manage anxiety effectively.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                       <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 35 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by discussing with students the concept of anxiety. Ask them to share their understanding of what anxiety is and what it feels like. Write down their responses on the whiteboard as a mind map.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that anxiety often causes people to focus on future events, worries, or uncertainties, taking them away from the present moment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Navigate students to the Reality Check Switch and explain that we will be exploring how to use this technique for managing anxiety.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Discussion (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the idea of "staying in the present" as a strategy for managing anxiety. Explain that mindfulness techniques can help individuals anchor themselves in the here and now.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide examples of how anxiety can pull someone into the future e.g., worrying about an upcoming exam or anticipating a social event, and how this can be counterproductive.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Open the conversation up to the class and ask if anyone has found themselves thinking too much about the future before and if so, when was it.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide the class into groups of 3-5.  (Can also be done individually if preferred.)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the groups to brainstorm three additional questions that someone could ask themselves to help bring someone into the present moment. Examples of these can be found in the ‘reality check’ switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- What are 5 things that I can see?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Am I feeling too hot or too cold?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Is my heart racing too fast?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- What is the time?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Am I tired?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Am I hungry?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After the groups have decided on the three grounding questions regroup and write all the questions on the board.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Get each group to briefly talk about why their questions could serve as good grounding questions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to remember their grounding questions for when they feel like they need to be pulled into the present moment.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points of the lesson, emphasising the importance of staying in the present as a valuable tool for managing anxiety.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to continue utilising the Reality Check Switch in the those moments of need. </DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard and markers</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>How to Use Reality Check to Get Back to The Present Moment</DefaultText>
                        <DefaultTextWithLink text='https://letsreachsuccess.com/reality-check/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Reality Testing - GoodTherapy</DefaultText>
                        <DefaultTextWithLink text='https://www.goodtherapy.org/blog/psychpedia/reality-testing' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How Anxiety Can Cause Distorted Reality</DefaultText>
                        <DefaultTextWithLink text='https://www.calmclinic.com/anxiety/symptoms/distorted-reality' />
                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Anxious]
}


