import { StyleSheet, View } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultButton, DefaultButtonTypes } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { useResponsive } from '../common/use-responsive'

type Props = {
    onPress: () => void
    title: string
}

const SchoolAdminButtonMinus = ({ onPress, title }: Props) => {

    const { width } = useResponsive()

    return (
        <DefaultButton
            type={DefaultButtonTypes.Small}
            style={styles.removeButton}
            onPress={onPress}
        >
            <View style={styles.minusIcon} />
            {width > 900 && (
                <DefaultText textProps={{ numberOfLines: 1 }} style={styles.text}>
                    {title}
                </DefaultText>
            )}
        </DefaultButton>
    )
}
export default SchoolAdminButtonMinus
const styles = StyleSheet.create({
    removeButton: {
        width: '100%',
        backgroundColor: DecidaColors.Red,
        height: 40,
    },
    minusIcon: {
        width: 15,
        height: 3,
        backgroundColor: DecidaColors.White,
    },
    text: {
        color: DecidaColors.White,
        marginLeft: 5,
        fontSize: 12,
        fontWeight: '700'
    },
})