export const searchStudentByEmail = /* GraphQL */ `query SearchStudentByEmail($input: SearchStudentByEmailInput!) {
    searchStudentByEmail(input: $input) {
        id
        nickname
        school {
            id
            name
            __typename
        }
        firstName
        lastName
        email
        cognitoUsername
        classes {
            items {
                _deleted
                class {
                    id
                    name
                    archived
                    _deleted
                    __typename
                }
            }
            __typename
        }
        __typename
    }
}
`
export type SearchStudentByEmailQuery = {
    searchStudentByEmail: Array<{
        __typename: "Student",
        id: string,
        nickname: string,
        school?: {
            __typename: "School",
            id: string,
            name: string,
        } | null,
        firstName?: string | null,
        lastName?: string | null,
        email?: string | null,
        cognitoUsername: string,
        classes?: {
            __typename: "ModelStudentClassConnection",
            items: Array<{
                __typename: "StudentClass",
                _deleted?: boolean | null,
                class?: {
                    __typename: "Class",
                    id: string,
                    name: string,
                    archived?: boolean | null,
                    _deleted?: boolean | null,
                }
            } | null>,
        } | null,
    } | null>,
};
