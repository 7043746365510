import { Pressable, StyleSheet, View } from 'react-native'
import { PostersType } from '../switches/tools/common/posters-import'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from './default-text'
import { useResponsive } from './use-responsive'
import { WorksheetType } from './worksheets-imports'
import { openUrlWebView } from '../web-view/open-url-web-view'
import _ from 'lodash'

type Props = {
  data: PostersType[] | WorksheetType[]
}
const SheetsAndPosterCardList = ({ data }: Props) => {
  const { width } = useResponsive()
  return (
    <View style={styles.fullWidth}>
      <View style={[styles.cardContainer]}>
        {_.sortBy(data, "label").map((item) => {
          const WorksheetImage = item.image
          return (
            <Pressable
              key={item.label}
              style={[styles.card, { width: width < 600 ? '100%' : '25%' }]}
              onPress={() => openUrlWebView(item.uri)}
            >
              <View style={styles.imageContainer}>
                <WorksheetImage />
              </View>
              <DefaultText style={styles.label} textProps={{ numberOfLines: 2 }}>
                {item.label}
              </DefaultText>
            </Pressable>
          )
        })}
      </View>
    </View>
  )
}

export default SheetsAndPosterCardList

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%'
  },
  cardContainer: {
    marginTop: 20,
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
  },
  card: {
    alignItems: 'center',
    marginBottom: 20,
  },
  imageContainer: {
    width: 100,
    height: 150,
  },
  label: {
    fontSize: 14,
    maxWidth: '100%',
    color: DecidaColors.Gray,
    textAlign: 'center'
  }
})