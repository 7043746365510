import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { RouteProp, useRoute } from '@react-navigation/native'
import * as Clipboard from 'expo-clipboard'
import { ViewStyle } from 'react-native'
import { ResetAllStudentPasswordInClassMutation, ResetAllStudentPasswordInClassMutationVariables, StudentClass } from '../../common/API'
import { generatePassword } from '../../common/generate-password'
import { resetAllStudentPasswordInClass } from '../../common/graphql/mutations'
import { rvCurrentUser } from '../login/login-state'
import { TeacherClassNavigatorParamList } from '../teacher/teacher-route-param-types'
import { ButtonText } from './button-text'
import { DefaultButton } from './default-button'
import { rvIsLoading } from './loading'
import { CLIENT_EVENT, createClientLog } from './log-client-event'
import { showAlert, showGenericErrorAlert } from './universal-alert'
import { Pressable, StyleSheet } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { ResetPasswordAllIcon } from './svg-files/reset-password-all-icon'
import { View } from 'react-native'
import { CommonStyles } from './const'
import { DefaultText } from './default-text'
import { rvSidebarClassID } from '../school-admin/school-admin-state'

export enum ResetPasswordButtonTypes {
    ClassAdmin,
    SchoolAdminSidebar
}

type Props = {
    studentClasses: StudentClass[]
    style?: ViewStyle
    type?: ResetPasswordButtonTypes
}

const ButtonResetPasswordClassStudents = ({ style, type = ResetPasswordButtonTypes.ClassAdmin }: Props) => {
    const user = useReactiveVar(rvCurrentUser)

    const { params } = useRoute<RouteProp<TeacherClassNavigatorParamList, "TeacherClassAdmin">>()

    const classID = useReactiveVar(rvSidebarClassID) || params?.classID

    const [resetAllStudentPasswordInClassMutation] = useMutation<ResetAllStudentPasswordInClassMutation, ResetAllStudentPasswordInClassMutationVariables>(gql`${resetAllStudentPasswordInClass}`,)

    const handleResetAllStudentPassword = async () => {

        if (!classID) {
            console.log(handleResetAllStudentPassword.name, "Class not found")
            return
        }
        const tempPassword = generatePassword()

        try {
            rvIsLoading(true)
            await resetAllStudentPasswordInClassMutation({
                variables: {
                    input: {
                        classID,
                        password: tempPassword
                    }
                }
            })
            rvIsLoading(false)

            await Clipboard.setStringAsync(tempPassword);
            showAlert({
                title: "Reset password",
                message: `\nPassword is: ${tempPassword}\n\nThe password has been copied to your clipboard, please send it to students. Once you have closed this alert you will not be able to see the temporary password again.`,
                leftButtonText: 'Ok',
            })

            createClientLog({
                area: 'Button reset all student password',
                action: "Reset password success",
                event: CLIENT_EVENT.RESET_PASSWORD_SUCCESS,
                payload: {
                    group: "teacher",
                    nickname: user?.username,
                }
            })
        } catch (error) {

            if (error) {
                showAlert({
                    title: 'An error has occured.',
                    message: "Reset all student password failed",
                    leftButtonText: 'Ok',
                    rightButtonText: 'Retry',
                    onRightButtonPress: handleResetAllStudentPassword
                })
            } else {
                showGenericErrorAlert()
            }
            createClientLog({
                area: 'Button reset all student password',
                action: `Reset class ${classID}, students password failed`,
                event: CLIENT_EVENT.RESET_PASSWORD_FAILED,
                payload: {
                    group: "teacher",
                    nickname: user?.username,
                    e: error
                }
            })
        }
    }

    const handlePressButton = () => {
        showAlert({
            title: "Reset password",
            message: "Are you sure you want to reset this class students password?",
            leftButtonText: 'Cancel',
            rightButtonText: "Yes",
            onRightButtonPress: handleResetAllStudentPassword
        })
    }

    if (type === ResetPasswordButtonTypes.SchoolAdminSidebar) {
        return (
            <Pressable onPress={handlePressButton} style={[CommonStyles.innerShadow, CommonStyles.buttonRounded, styles.button]}>
                <View style={CommonStyles.buttonIcon}>
                    <ResetPasswordAllIcon />
                </View>
                <DefaultText style={styles.text}>Reset all student passwords</DefaultText>
            </Pressable>
        )
    }

    return (
        <DefaultButton onPress={handlePressButton} style={style}>
            <ButtonText>Reset all student password</ButtonText>
        </DefaultButton>
    )
}

export default ButtonResetPasswordClassStudents

const styles = StyleSheet.create({
    button: {
        flexDirection: "row",
        marginVertical: 20,
        justifyContent: 'space-evenly',
        alignItems: 'center',
        alignSelf: 'center',
        maxWidth: 400
    },
    text: {
        fontSize: 16,
        color: DecidaColors.Green
    }

})