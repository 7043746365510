import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { HeadKneesToesHatIcon } from '../../icons/head-knees-toes-hat-icon'
import { HeadKneesToesHatIconInverted } from '../../icons/head-knees-toes-hat-icon-inverted'

export const SwitchesActivitiesEnergizersHeadKneeToesHat: CardTemplate = {
    cardId: "8c3fbacd-f0ff-48d6-bb86-14b8552b7bfd",
    title: 'Head, knee, toes, hat!',
    Header: HeadKneesToesHatIconInverted,
    Icon: HeadKneesToesHatIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Energising a classroom with a quick and easy game."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"One hat/cap between each pair of participants "} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Setup:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Pair up participants (1 group of 3 if necessary)."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Designate a game leader who is not part of the participating group. "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Each pair lines up facing each other, with an additional person on the end of the row if there is a group of three."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Place one hat between two people (or one between three) in the middle of each group. "} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The game leader calls out a body part (e.g. head, shins, elbows etc.)"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The group follows the leader's call by placing both their hands on their own body on the called body part. "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'At a random time, the leader calls "HAT!" and each pair must race to be the first to grab their hat from the floor. '} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The person who grabs the hat moves on to the next round, while the others are eliminated."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Repeat with new pairs until there is only one winner."} />


        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.ENERGIZERS,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <>
        </>

    ),
    audioUrl: "",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.ENERGIZERS],
    action: [CardActionType.Action],
    level: []

}

