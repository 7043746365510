import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { ToolsTheLittleBookOfBigEmotions } from '../tools/cards/the-little-book-of-big-emotions'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

export const SwitchesLessonPlansUnderstandingAndManagingSadness: CardTemplate = {
    cardId: "583a7226-8b24-454a-b0f1-b8fcca0b6faa",
    title: 'Understanding and managing sadness',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to recognise, understand and manage their sadness, to enhance self awareness.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: Approximately 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Greet the students and explain the purpose of the lesson: to understand and manage sadness effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share their understanding of sadness and its effects on themselves and others.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Defining Sadness (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the definition of sadness, emphasising that it is a normal and natural emotion that everyone experiences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that sadness is a feeling of unhappiness or sorrow in response to loss, disappointment, or other challenging situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share examples of situations or experiences that make them sad.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Brainstorm the different ways people experience and express sadness, such as crying, withdrawing, or feeling low in energy.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding the Purpose of Sadness (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the purpose of sadness as an emotion that helps us acknowledge and process difficult experiences or losses. Refer to the Little Book of Big Emotion for more information.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that sadness can provide an opportunity for self reflection, growth, and empathy towards others.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share personal experiences where sadness has played a role in their lives and what they have learned from those experiences.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Developing Self awareness (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the concept of self awareness as the ability to recognise and understand one's own thoughts, feelings, and behaviours.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the connection between self awareness and managing sadness. Explain that self awareness means understanding your own thoughts, feelings, and actions. It's like knowing yourself really well, including how you react to different situations and what makes you feel certain emotions, like sadness. Self awareness is important when it comes to managing sadness because it helps you take care of yourself and find healthy ways to cope. Here's how it works:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Recognising Sadness: When you're self-aware, you can recognise when you're feeling sad. You can identify the signs in your body and the thoughts that come with it. For example, you might notice that you feel down, have a heavy feeling in your chest, or have thoughts that make you feel sad.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Accepting and Validating Your Feelings: Self awareness helps you accept and validate your feelings of sadness. It's okay to feel sad sometimes; it's a natural and normal emotion that everyone experiences. By acknowledging and accepting your sadness, you can start the process of managing it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Understanding Triggers: Self awareness helps you understand what triggers or situations make you feel sad. It could be a specific event, a change in routine, or certain thoughts. By being aware of these triggers, you can better prepare yourself and find ways to cope when they arise.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Finding Healthy Coping Strategies: When you're self-aware, you can identify healthy coping strategies that work best for you. Coping strategies are activities or techniques that help you feel better when you're sad. It could be talking to a trusted friend or family member, engaging in hobbies you enjoy, practicing deep breathing, or writing in a journal. Self awareness allows you to choose coping strategies that are most effective for you personally.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Seeking Support: Being self-aware means recognising when you might need extra support. It's okay to ask for help when you're feeling sad. Talking to a trusted adult, like a parent, teacher, or counsellor, can provide guidance and support when managing your emotions becomes challenging.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students that, managing sadness doesn't mean making it go away completely or pretending it doesn't exist. It's about understanding your feelings, taking care of yourself, and finding healthy ways to cope. Self awareness is a valuable tool that helps you navigate your emotions and develop resilience. And, if you ever feel overwhelmed by sadness or find it difficult to manage on your own, don't hesitate to reach out to someone you trust for support. They can offer guidance and help you through difficult times. You're not alone, and it's important to take care of yourself and seek help when needed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a guided reflection exercise, encouraging students to think about their own experiences of sadness and how they typically respond. Have students complete the top section of the <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Coping with sadness worksheet' link={Worksheets.iCanCopeWithFeelingSadWorksheet.uri} />.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share their reflections and discuss strategies for improving self awareness in dealing with sadness. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Coping Strategies for Sadness (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce a variety of coping strategies for sadness, such as talking to a trusted person, engaging in hobbies or activities that bring joy, writing in a journal, or seeking professional help when needed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students access the Switch4Schools program on their device and encourage them to explore the available switches assigned to sad on the emotion wheel to bookmark activities they’d like to try.  This could also be a homework activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students finalise their Coping with sadness worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the benefits of some of the techniques selected by the group and when they might be most helpful.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>◦ Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the key points discussed during the lesson, emphasising the importance of understanding, and managing sadness.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to understand that sadness is a natural part of life and that it's essential to recognise and address. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice the coping strategies and emotional intelligence skills they have learned to enhance self awareness and navigate sadness effectively.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Answer any questions or concerns the students may have. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard/markers or chart paper/poster</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Coping with sadness worksheet' link={Worksheets.iCanCopeWithFeelingSadWorksheet.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='The Little Book of Big Emotions' link={ToolsTheLittleBookOfBigEmotions.openUrl || ""} /></DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Anger management for teens – how you can help -</DefaultText>
                        <DefaultTextWithLink text='https://www.sparktheirfuture.qld.edu.au/explosive-behaviour/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Anger - how it affects people -</DefaultText>
                        <DefaultTextWithLink text='https://www.betterhealth.vic.gov.au/health/healthyliving/anger-how-it-affects-people' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    durationText: {
        marginBottom: 10,
        color: DecidaColors.Gray,
        alignSelf: 'center'
    },
})
