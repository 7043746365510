import { useEffect, useState } from "react"
import { Dimensions, useWindowDimensions } from "react-native"
import { BreakPoints, DefaultFontSize } from "./const";

const dimensions = Dimensions.get('window');
export const lowRes = dimensions.width > dimensions.height && dimensions.height < BreakPoints.S
export const windowWidthCondition = dimensions.width < BreakPoints.S

export const useResponsive = () => {
    const layout = useWindowDimensions()
    const { width, height } = layout
    const lowRes = width > height && height < BreakPoints.S
    const [isLandScape, setIsLandScape] = useState(layout.width > layout.height)
    const scaleRatio = lowRes ? Math.min(1, layout.height / 750) : Math.min(1, layout.width / 700)

    const isLargeScreen = width > 700
    const isNarrowScreen = width < BreakPoints.S

    const getResponsiveFontSize = (currentFontSize = DefaultFontSize) => {
        let fontSize = currentFontSize
        if (width <= BreakPoints.XS) {
            fontSize = fontSize - 6
        } else if (width > BreakPoints.XS && width < BreakPoints.S) {
            fontSize = fontSize - 4
        }
        
        return fontSize
    }

    useEffect(() => {
        if (width > height) {
            setIsLandScape(true)

        } else {
            setIsLandScape(false)
        }

    }, [layout])

    return {
        width,
        height,
        isLargeScreen,
        isLandScape,
        scaleRatio,
        lowRes,
        windowWidthCondition: !isLargeScreen,
        isNarrowScreen,
        responsiveFontSize: (fontSize?: number) => getResponsiveFontSize(fontSize)
    }
}