import { StyleSheet } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

export const SwitchesLessonPlansEmotionalManagement: CardTemplate = {
    cardId: "882ca3c5-d441-4f47-9d00-c699fc074019",
    title: 'Emotional management',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to enhance their emotional management skills and make proactive choices to regulate emotions effectively.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by engaging students in a brief discussion about the importance of emotional management and self regulatory behaviours.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to share examples of situations where emotions were challenging to manage, and how they have used switching coping strategies to get through those challenging time</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Emotional Triggers (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of emotional triggers and how they can influence emotional responses. Emotional triggers are like buttons that can set off strong emotions in us. They can be things, situations, or events that make us feel a certain way. For example, seeing a cute puppy might make us feel happy, while a scary movie might make us feel scared. When we encounter an emotional trigger, it can affect how we feel and how we react to a situation. It's like a switch that turns on our emotions. Triggers can come from our past experiences, personal beliefs, or what's happening around us. When a trigger happens, it can make us feel different emotions. For example, if someone says something mean, it might trigger feelings of sadness, anger, or hurt. These emotions can influence how we respond to the situation. We might cry, get angry, or feel like avoiding the person.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Understanding our emotional triggers is important because it helps us make sense of our feelings. It allows us to recognise why we feel a certain way in different situations. By knowing our triggers, we can learn to manage our emotions better. Knowing our triggers also helps us understand others. Different people have different triggers, so they might react differently to the same situation. This understanding helps us be more compassionate and considerate towards others' emotions. It's important to remember that emotional triggers are normal and everyone has them. It's okay to feel different emotions when we encounter triggers. By recognising our triggers and learning to control our emotional responses, we can handle our emotions in a healthier way and build stronger relationships with others.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss common triggers, such as criticism, disappointment, or stress.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on personal triggers and share them with a partner or in small groups.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Coping Strategies for Emotional Management (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the level 5 switch quick reference guide on coping strategies for emotional management.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share which switching techniques they find useful.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have a class brainstorming session to generate additional coping strategies.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Applying Coping Strategies (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide students into pairs or small groups.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Assign each group a scenario card that presents a conflict situation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 1: You and your best friend are working on a group project. Your friend disagrees with your ideas and starts criticising them in front of the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 2: During a basketball game, a player from the opposing team starts taunting you and making mean comments about your skills.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 3: You're at a theme park with your friends, and they want to go on a roller coaster that you find intimidating and fear-inducing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In their groups, students should analyse the scenario, identify the emotions triggered, and discuss and apply appropriate coping strategies to manage emotions effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief looking for appropriate responses to: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 1: Emotional Regulation Challenge: Managing feelings of frustration, anger, or hurt and responding in a calm and respectful manner.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 2: Emotional Regulation Challenge: Controlling feelings of anger or embarrassment and finding a way to stay focused on the game without getting into an argument or retaliating.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 3: Emotional Regulation Challenge: Managing feelings of fear, anxiety, or nervousness about going on the roller coaster while still expressing your preferences and concerns in a respectful manner. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Closure (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share one insight from the lesson they found helpful they plan to apply in future situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of proactive emotional management for personal growth and positive relationships.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard/Chart paper and markers </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Level 5 quick reference guide' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/level+5+QRG.pdf' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Coping strategies scenarios worksheet' link={Worksheets.copingStrategies.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Coping Skills For Teens: How To Handle Difficult Emotions -</DefaultText>
                        <DefaultTextWithLink text='https://betterhelp.com/advice/teenagers/coping-skills-for-teens-how-to-handle-difficult-emotions/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>How to Identify and Manage Your Emotional Triggers -</DefaultText>
                        <DefaultTextWithLink text='https://www.healthline.com/health/mental-health/emotional-triggers' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Understanding and managing emotions: children and teenagers -</DefaultText>
                        <DefaultTextWithLink text='https://raisingchildren.net.au/preschoolers/development/preschoolers-social-emotional-development/understanding-managing-emotions-children-teenagers?gclid=EAIaIQobChMIvP3akpPq_wIVzVpgCh04XQAdEAMYASAAEgLO1_D_BwE' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}
