import { ViewStyle } from 'react-native'
import { StaffCheckIn, TeacherCheckIn } from '../../common/API'
import AllPeriodsGraphSvg from './all-stats-period-graph'
import { useScaleSizeForPrintMode } from './scale-size-for-print-mode'

interface Props {
    staffCheckIns: (TeacherCheckIn | StaffCheckIn)[]
    scaleStyle?: ViewStyle
}

export const StaffWellbeingStatsAllPeriodsGraph = ({ staffCheckIns, scaleStyle }: Props) => {

    const staffCheckinsData = staffCheckIns.map(staffCheckIn => {
        const date = new Date(staffCheckIn.createdAt || '').toLocaleDateString('en-AU', {
            day: '2-digit',
            month: '2-digit',
            weekday: "short",
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h24"
        }).split(' ') // { date: ["Tue," , "31/10," , "16:23"]}

        return {
            id: staffCheckIn.id,
            date: date[1].slice(0, 5),
            day: date[0].slice(0, 3),
            time: date[2],
            checkInData: staffCheckIn,
        }
    })

    return <AllPeriodsGraphSvg checkInsData={staffCheckinsData} isTeacher={true} />
}