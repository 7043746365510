import { StyleSheet, TextInput } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'

type Props = {
    title: string
    onChangeText: (text: string) => void
    placeholder: string
}
const InputSearchContent = ({ title, onChangeText, placeholder }: Props) => {
    return (
        <TextInput
            style={styles.input}
            onChangeText={onChangeText}
            value={title}
            placeholder={placeholder}
        />
    )
}
export default InputSearchContent
const styles = StyleSheet.create({
    input: {
        width: '100%',
        height: 40,
        marginVertical: 12,
        borderWidth: 1,
        padding: 10,
        borderRadius: 5,
        borderColor: DecidaColors.Gray
    },
})