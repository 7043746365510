import { Platform } from "react-native"
import { QuestionOption } from "../../common/API"

type Props = {
    options: QuestionOption[]
    onPress: (value: number) => void
}


const IconWellbeingQuestionOptions = ({ options, onPress }: Props) => {

    const [option1, option2, option3, option4, option5] = options;

    const prop = Platform.OS === 'web' ? 'onClick' : 'onPress';

    const onPress1 = { [prop]: () => onPress(option1.value) };
    const onPress2 = { [prop]: () => onPress(option2.value) };
    const onPress3 = { [prop]: () => onPress(option3.value) };
    const onPress4 = { [prop]: () => onPress(option4.value) };
    const onPress5 = { [prop]: () => onPress(option5.value) };

    const RADIUS = 59.5;
    const STROKE_WIDTH = 3;
    const FILL = "#fff";
    const STROKE = "#929292";
    const RECT_WIDTH = 849;
    const RECT_HEIGHT = 4;
    const RECT_RX = 2;
    const RECT_FILL = "#97999C";
    const TEXT_WIDTH = 140;
    const TEXT_HEIGHT = 120;
    const TEXT_Y = 140;

    const circles = [
        { cx: 101, cy: 61, option: option1, onPress: onPress1 },
        { cx: 259, cy: 61, option: option2, onPress: onPress2 },
        { cx: 417, cy: 61, option: option3, onPress: onPress3 },
        { cx: 583, cy: 61, option: option4, onPress: onPress4 },
        { cx: 749, cy: 61, option: option5, onPress: onPress5 }
    ];

    return (
        <svg viewBox="0 0 849 198" fill="none" width="100%" height="100%">
            <rect y={59} width={RECT_WIDTH} height={RECT_HEIGHT} rx={RECT_RX} fill={RECT_FILL} />
            {circles.map(({ cx, cy, option, onPress }, index) => (
                <g key={index} {...onPress}>
                    <circle cx={cx} cy={cy} r={RADIUS} fill={FILL} stroke={STROKE} strokeWidth={STROKE_WIDTH} />
                    <path
                        d={getPathData(index)}
                        fill={STROKE}
                    />
                    <foreignObject width={TEXT_WIDTH} height={TEXT_HEIGHT} x={cx - 70} y={TEXT_Y}>
                        <div style={styles.textContainer}>{option.text}</div>
                    </foreignObject>
                </g>
            ))}
        </svg>
    );
}


const getPathData = (index: number) => {
    const paths = [
        "M68.01 68.2c0 2.92 2.29 5.21 5.21 5.21h15.62c.83 0 3.75 1.25 1.46 4.8-1.46 2.71-1.46 12.92-1.46 12.92 0 2.91 2.29 5.2 5.21 5.2s5.21-2.29 5.21-5.21c0-15.21 14.58-19.79 14.58-19.79V44.25c-6.67-2.29-8.33-6.25-16.67-6.25H81.55c-2.92 0-5.21 2.29-5.21 5.21 0 1.25.42 2.29 1.04 3.12-2.92 0-5.21 2.29-5.21 5.21 0 1.25.42 2.5 1.25 3.54-2.08.83-3.33 2.71-3.33 4.79 0 1.25.42 2.5 1.25 3.54-1.88.62-3.34 2.5-3.34 4.79h.01zM124.26 75.5h10.41V40.08h-10.42c-3.54 0-6.25 2.92-6.25 6.25v22.92c0 3.54 2.71 6.25 6.25 6.25h.01z",
        "M230.898 41.362c-2.065 2.064-2.065 5.303 0 7.368l11.045 11.045c.587.587 1.768 3.535-2.362 4.426-2.948.884-10.168 8.104-10.168 8.104-2.058 2.057-2.058 5.296.007 7.36 2.065 2.065 5.303 2.065 7.368 0 10.755-10.755 24.303-3.684 24.303-3.684l19.149-19.148c-3.097-6.336-1.471-10.31-7.368-16.207l-11.045-11.045c-2.065-2.065-5.303-2.065-7.368 0a5.128 5.128 0 00-1.471 2.942c-2.065-2.065-5.303-2.065-7.368 0a5.665 5.665 0 00-1.619 3.387c-2.058-.884-4.271-.439-5.742 1.032a5.665 5.665 0 00-1.619 3.387c-1.768-.89-4.13-.594-5.749 1.026l.007.007zM265.511 86.298l7.361 7.361 25.046-25.046-7.368-7.368c-2.504-2.503-6.485-2.354-8.839 0l-16.207 16.207c-2.503 2.504-2.503 6.336 0 8.84l.007.006z",
        "M410.13 30.01c-2.92 0-5.21 2.29-5.21 5.21v15.62c0 .83-1.25 3.75-4.8 1.46-2.71-1.46-12.92-1.46-12.92-1.46-2.91 0-5.2 2.29-5.2 5.21s2.29 5.21 5.21 5.21c15.21 0 19.79 14.58 19.79 14.58h27.08c2.29-6.67 6.25-8.33 6.25-16.67V43.55c0-2.92-2.29-5.21-5.21-5.21-1.25 0-2.29.42-3.12 1.04 0-2.92-2.29-5.21-5.21-5.21-1.25 0-2.5.42-3.54 1.25-.83-2.08-2.71-3.33-4.79-3.33-1.25 0-2.5.42-3.54 1.25-.62-1.88-2.5-3.34-4.79-3.34v.01zM402.83 86.26v10.41h35.42V86.25c0-3.54-2.92-6.25-6.25-6.25h-22.92c-3.54 0-6.25 2.71-6.25 6.25v.01z",
        "M603.027 35.897c-2.065-2.064-5.304-2.064-7.368 0l-11.045 11.045c-.587.587-3.536 1.768-4.427-2.361-.884-2.95-8.103-10.169-8.103-10.169-2.058-2.057-5.297-2.057-7.361.007-2.065 2.065-2.065 5.304 0 7.369 10.755 10.755 3.684 24.303 3.684 24.303l19.148 19.148c6.336-3.097 10.31-1.47 16.207-7.368l11.045-11.045c2.065-2.065 2.065-5.303 0-7.368a5.125 5.125 0 00-2.942-1.47c2.065-2.065 2.065-5.304 0-7.369A5.665 5.665 0 00608.478 49c.884-2.058.439-4.27-1.032-5.742a5.67 5.67 0 00-3.387-1.619c.891-1.768.594-4.13-1.025-5.749l-.007.007zM558.09 70.51l-7.361 7.361 25.046 25.046 7.368-7.368c2.503-2.503 2.355-6.484 0-8.84l-16.207-16.206c-2.503-2.503-6.336-2.503-8.839 0l-.007.007z",
        "M782.66 60.13c0-2.92-2.29-5.21-5.21-5.21h-15.62c-.83 0-3.75-1.25-1.46-4.8 1.46-2.71 1.46-12.92 1.46-12.92 0-2.91-2.29-5.2-5.21-5.2s-5.21 2.29-5.21 5.21c0 15.21-14.58 19.79-14.58 19.79v27.08c6.67 2.29 8.33 6.25 16.67 6.25h15.62c2.92 0 5.21-2.29 5.21-5.21 0-1.25-.42-2.29-1.04-3.12 2.92 0 5.21-2.29 5.21-5.21 0-1.25-.42-2.5-1.25-3.54 2.08-.83 3.33-2.71 3.33-4.79 0-1.25-.42-2.5-1.25-3.54 1.88-.62 3.34-2.5 3.34-4.79h-.01zM726.41 52.83H716v35.42h10.42c3.54 0 6.25-2.92 6.25-6.25V59.08c0-3.54-2.71-6.25-6.25-6.25h-.01z"
    ]

    return paths[index]
}

export default IconWellbeingQuestionOptions

const styles = {
    textContainer: {
        width: "100%",
        wordWrap: 'break-word',
        overflow: 'hidden',
        textAlign: 'center',
        fontFamily: "Helvetica-Bold, Helvetica",
        fontWeight: "700",
        color: "#5a5b5e"
    } as React.CSSProperties,

}
