import { Monsters01 } from "./monsters-01";
import { Monsters02 } from "./monsters-02";
import { Monsters03 } from "./monsters-03";
import { Monsters04 } from "./monsters-04";
import { Monsters05 } from "./monsters-05";
import { Monsters06 } from "./monsters-06";
import { Monsters07 } from "./monsters-07";
import { Monsters08 } from "./monsters-08";
import { Monsters09 } from "./monsters-09";
import { Monsters10 } from "./monsters-10";
import { Monsters11 } from "./monsters-11";
import { Monsters12 } from "./monsters-12";
import { Monsters13 } from "./monsters-13";
import { Monsters14 } from "./monsters-14";
import { Monsters15 } from "./monsters-15";
import { Monsters16 } from "./monsters-16";
import { Monsters17 } from "./monsters-17";
import { Monsters18 } from "./monsters-18";
import { Monsters19 } from "./monsters-19";
import { Monsters20 } from "./monsters-20";
import { Monsters21 } from "./monsters-21";
import { Monsters22 } from "./monsters-22";
import { Monsters23 } from "./monsters-23";
import { Monsters24 } from "./monsters-24";
import { Monsters25 } from "./monsters-25";
import { Monsters26 } from "./monsters-26";
import { Monsters27 } from "./monsters-27";
import { Monsters28 } from "./monsters-28";
import { Monsters29 } from "./monsters-29";
import { Monsters30 } from "./monsters-30";
import { Monsters31 } from "./monsters-31";
import { Monsters32 } from "./monsters-32";
import { Monsters33 } from "./monsters-33";
import { Monsters34 } from "./monsters-34";
import { Monsters35 } from "./monsters-35";
import { Monsters36 } from "./monsters-36";
import { Monsters37 } from "./monsters-37";
import { Monsters38 } from "./monsters-38";
import { Monsters39 } from "./monsters-39";
import { Monsters40 } from "./monsters-40";

export const monstersavatars = {
    Monsters01,
    Monsters02,
    Monsters03,
    Monsters04,
    Monsters05,
    Monsters06,
    Monsters07,
    Monsters08,
    Monsters09,
    Monsters10,
    Monsters11,
    Monsters12,
    Monsters13,
    Monsters14,
    Monsters15,
    Monsters16,
    Monsters17,
    Monsters18,
    Monsters19,
    Monsters20,
    Monsters21,
    Monsters22,
    Monsters23,
    Monsters24,
    Monsters25,
    Monsters26,
    Monsters27,
    Monsters28,
    Monsters29,
    Monsters30,
    Monsters31,
    Monsters32,
    Monsters33,
    Monsters34,
    Monsters35,
    Monsters36,
    Monsters37,
    Monsters38,
    Monsters39,
    Monsters40
}