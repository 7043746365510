import { Entypo } from '@expo/vector-icons';
import { useState } from 'react';
import { ColorValue, StyleSheet, View } from 'react-native';
import DefaultModal from './default-modal';

type Props = {
    Content: () => JSX.Element
    iconSize: number
    iconColor: ColorValue
}
const InfoIconWIthContent = ({ Content, iconSize, iconColor }: Props) => {
    const [showContentModal, setShowContentModal] = useState(false)

    const toggleContentModal = () => {
        setShowContentModal(prev => !prev)
    }
    return (
        <View style={styles.container}>
            <Entypo testID='info-icon' onPress={toggleContentModal} name="info-with-circle" size={iconSize} color={iconColor} />
            {showContentModal && (
                <DefaultModal onClose={toggleContentModal}>
                    <Content />
                </DefaultModal>
            )}
        </View>
    )
}
export default InfoIconWIthContent
const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    }
})