import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const EmotionExcitedIcon = (props?: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 92.491 92.489"
    {...props}
  >
    <Path
      id="Path_3926"
      data-name="Path 3926"
      d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465Z"
      transform="translate(26.513 51.518)"
      fill="none"
      stroke="#378f7b"
      strokeWidth={3.013}
    />
    <Path
      id="Path_3927"
      data-name="Path 3927"
      d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211"
      transform="translate(25.214 34.156)"
      fill="#378f7b"
    />
    <Path
      id="Path_3928"
      data-name="Path 3928"
      d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211"
      transform="translate(60.401 34.156)"
      fill="#378f7b"
    />
    <Path
      id="Path_3929"
      data-name="Path 3929"
      d="M0,0S18.179,22.623,42.694.157"
      transform="translate(24.33 56.828)"
      fill="none"
      stroke="#378f7b"
      strokeWidth={3.013}
    />
    <Path
      id="Path_3930"
      data-name="Path 3930"
      d="M.065,1.686s-1.354-5.3,4.179-3.419"
      transform="translate(22.191 56.88)"
      fill="#378f7b"
    />
    <Path
      id="Path_3931"
      data-name="Path 3931"
      d="M0,.1s5.222-1.63,3.632,4"
      transform="translate(65.195 54.911)"
      fill="#378f7b"
    />
    <Path
      id="Path_3932"
      data-name="Path 3932"
      d="M8.529,0S2.135,6.908-10.808,11.288"
      transform="translate(26.318 11.059)"
      fill="none"
      stroke="#378f7b"
      strokeWidth={3.013}
    />
    <Path
      id="Path_3933"
      data-name="Path 3933"
      d="M.58,0S-3.343,3.811,2.359,5.1"
      transform="translate(14.074 19.8)"
      fill="#378f7b"
    />
    <Path
      id="Path_3934"
      data-name="Path 3934"
      d="M1.748,1.743S3.423-3.465-2.215-1.924"
      transform="translate(35.062 11.124)"
      fill="#378f7b"
    />
    <Path
      id="Path_3935"
      data-name="Path 3935"
      d="M0,0S6.394,6.908,19.337,11.288"
      transform="translate(57.644 11.059)"
      fill="none"
      stroke="#378f7b"
      strokeWidth={3.013}
    />
    <Path
      id="Path_3936"
      data-name="Path 3936"
      d="M.784,0S4.708,3.811-.994,5.1"
      transform="translate(77.052 19.8)"
      fill="#378f7b"
    />
    <Path
      id="Path_3937"
      data-name="Path 3937"
      d="M.1,1.743S-1.572-3.465,4.066-1.924"
      transform="translate(55.578 11.124)"
      fill="#378f7b"
    />
    <Path
      id="Path_3939"
      data-name="Path 3939"
      d="M0,0H43.467"
      transform="translate(23.886 56.9)"
      fill="none"
      stroke="#378f7b"
      strokeWidth={2.41}
    />
  </Svg>
);
export default EmotionExcitedIcon;
