import { PropsWithChildren, ReactNode, useRef } from "react";
import { DefaultButton } from "./default-button";
import { GestureResponderEvent, StyleSheet, View } from 'react-native'
import Canvas from "./canvas";
import { DecidaColors } from "../../common/decida-colors";

type Props = {
    extraButtons?: ReactNode
    assignClearPathFunction?: (func: () => void) => void
}

export const DrawableGames = ({ extraButtons, assignClearPathFunction, children }: PropsWithChildren<Props>) => {
    const clearDrawings = useRef<() => void>()

    const assignClearPathFunc = (func: () => void) => {
        clearDrawings.current = func
        assignClearPathFunction && assignClearPathFunction(func)
    }

    const handleClearDrawings = (e: GestureResponderEvent) => {
        e.preventDefault()
        clearDrawings.current && clearDrawings.current()
    }

    return (
        <View testID="drawable-games" style={styles.mainContainer}>
            <View style={styles.buttonContainer}>
                {extraButtons}
                <DefaultButton testID="clear-path-button" style={styles.button} onPress={handleClearDrawings}>Clear drawings</DefaultButton>
            </View>
            <View style={styles.imageContainer}>
                <View style={styles.childrenContainer}>
                    {children}
                </View>
                <Canvas assignClearPathFunction={assignClearPathFunc} />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    mainContainer: {
        padding: 10,
        width: '100%',
        height: '100%',
    },
    buttonContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    button: {
        backgroundColor: DecidaColors.Excited,
        marginHorizontal: 10,
        minWidth: 200,
        maxWidth: 330,
        flex: 1,
    },
    imageContainer: {
        position: 'relative',
        flex: 1,
        width: '100%',
    },
    childrenContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%'
    }
})