import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { MotivationMapHeader } from './src/motivation-map-header'
import { MotivationMapIcon } from './src/motivation-map-icon'
import { MotivationMapImage1 } from './src/motivation-map-image1'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'

export const SwitchesEmotionsAngryMotivationMap: CardTemplate = {
    cardId: "51492897-e9d4-4224-b808-1dd527df70af",
    title: 'Motivation map',
    Header: MotivationMapHeader,
    Icon: MotivationMapIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Motivating yourself to do something you should do, but don’t want to do."} />
        </>
    ),
    Images: () => (
        <>
            <MotivationMapImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Grab a piece of paper and a pen "} />
                <DefaulttextWithRightArrow textdata={"Answer the following questions"} />
                <DefaultText style={styles.withIndent} >• What is triggering you? E.g. I don’t want to be embarrassed</DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaultText style={styles.withIndent} >• What is the stressor? E.g. I have to talk in front of the class</DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaultText style={styles.withIndent} >• What could motivate you? E.g. Getting good marks from your teacher, or seeing that people react well to what you say</DefaultText>
                <DefaultTextWithoutArrows textdata='' />
                <DefaulttextWithRightArrow textdata={"Now write down some small short term goals to help you archive your motivators, and think of some reasons why your triggers and stressors could  inhibit your full potential"} />
            </View>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: `Switching your focus to what you are motivated about can help to calm your anger because it allows you to redirect your energy and attention away from the trigger that is causing your anger. When you focus on something that you are motivated about, you are more likely to engage in positive, productive activities that can help to improve your mood and outlook. This can, in turn, help to dissipate feelings of anger and frustration.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn what triggers, stressors, and motivators are.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice articulating different triggers, stressors, and motivators.</DefaultText>
                    </>
                }
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share a time when they felt stressed or motivated. List the situations on the board and discuss the commonalities and differences in their responses.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the concepts of triggers, stressors, and motivators.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Triggers: External events or situations causing an emotional response.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Stressors: Events or situations causing stress or feeling overwhelmed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Motivators: Factors inspiring or driving a person to take action.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Motivation Map Worksheet to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students brainstorm and list their own triggers, stressors, and motivators.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to categorise their listed items into triggers, stressors, and motivators and add them to the worksheet.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to choose one trigger or stressor and explain what happens and how they feel when it occurs on their worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to describe on their worksheet how they could use one of their motivators in that situation to help them move on.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a brief discussion on completing the Motivation Map Worksheet. Encourage students to share insights and strategies they've identified for managing triggers and stressors.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Switch4Schools App (Optional):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have devices, introduce the Motivation Map Switch in the Switch4Schools app. Guide them on how to use it to identify and manage triggers, stressors, and motivators.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise key points from the discussion and highlight the significance of understanding and managing triggers, stressors, and motivators for success and wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to reflect further on their triggers, stressors, and motivators. Ask them to consider real life situations where they can apply the strategies identified in class.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen and paper for each student</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Motivation map worksheet' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Motivation+map+lesson+plan+worksheet.pdf' /> </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Tips for getting and staying motivated</DefaultText>
                        <DefaultTextWithLink text='https://au.reachout.com/articles/tips-for-getting-and-staying-motivated' />
                    </>}
            />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Motivation+Map+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action, CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Angry]
}

const styles = StyleSheet.create({
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    withIndent: {
        paddingLeft: 20
    }
})
