import { useReactiveVar } from '@apollo/client'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import AvatarImage from '../common/avatar-image'
import { DefaultText } from '../common/default-text'
import { lowRes, useResponsive } from '../common/use-responsive'
import { avatars } from '../icon/avatar/avatars'
import { rvCurrentStudent } from './use-student-state'

const StudentProfileCard = () => {
    const currentStudent = useReactiveVar(rvCurrentStudent)
    const { scaleRatio } = useResponsive()
    const transformByRatioStyle: ViewStyle = { transform: [{ scale: lowRes ? scaleRatio : 1 }] }

    const Avatar = avatars[currentStudent?.avatar as keyof typeof avatars]
    return (
        <View style={styles.container}>
            <DefaultText style={[styles.welcomeText, transformByRatioStyle, { marginTop: lowRes ? 100 : undefined, marginBottom: lowRes ? 0 : undefined }]}>Hello,{currentStudent?.nickname && ` ${currentStudent.nickname}!` || ''}</DefaultText>

            <AvatarImage avatar={currentStudent?.avatar as keyof typeof avatars} />

            <View style={styles.studentNameContainer}>
                {currentStudent?.email && (
                    <DefaultText style={styles.emailText}>{currentStudent?.email || ""}</DefaultText>
                )}
                <DefaultText style={styles.fullNameText}>{`${currentStudent?.firstName} ${currentStudent?.lastName}`}</DefaultText>
            </View>
        </View>
    )
}
export default StudentProfileCard
const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        marginTop: 20
    },
    welcomeText: {
        fontSize: 35,
        fontWeight: 'bold',
        color: DecidaColors.Gray
    },
    studentNameContainer: {
        alignItems: 'center',
        marginBottom: 20,
    },
    emailText: {
        color: DecidaColors.Gray,
    },
    fullNameText: {
        fontSize: 14,
        fontStyle: 'italic',
        color: DecidaColors.Gray,
        opacity: 0.8

    }
})