import { ColorValue, StyleSheet, Text, View, ViewStyle } from 'react-native'
import VideoCard from './video-card'
import { Videos } from '../models'
import { DefaultText } from './default-text'
type Props = {
    video: Videos
    titleColor?: ColorValue
    containerStyle?: ViewStyle
}

const SwitchCardVideo = ({ video, containerStyle }: Props) => {
    return (
        <View style={[styles.videoContainer, { ...containerStyle }]}>
            <VideoCard video={video} titleStyle={styles.title} />
        </View>
    )
}
export default SwitchCardVideo
const styles = StyleSheet.create({
    videoContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        marginVertical: 10,
    },
    title: {
        textAlign: 'center'
    }
})