
export const emailValidator = (email: string) => {
    {
        if (email == null || email === '') {
            return ''
        }

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return 'Email is valid.'
        }
        return 'Email is not valid.'
    }
}