import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { ActivityIndicator, StyleSheet, TextInput, View, ViewStyle } from "react-native";
import { usernameTaken } from "../login/username-taken-validator";
import { CommonStyles } from "./const";
import { DefaultText } from "./default-text";
import { appendNamePrefix } from "./helper-functions";
import InputDefault from "./input-default";
import { DecidaColors } from "../../common/decida-colors";

interface Props {
  userExists: boolean | null
  setUserExists: (data: boolean) => void
  value: string
  onChangeText: (data: string) => void
  isAdminResetPassword?: boolean
  title?: string
  containerStyle?: ViewStyle
  isCurrentValue?: boolean
  editable?: boolean
  placeholder?: string
  onFetching?: (loading: boolean) => void
  withPrefix?: boolean
  schoolAdminStyle?: boolean
}

export const InputCognitoUsername = ({ userExists, setUserExists, value, onChangeText, isAdminResetPassword, title, containerStyle, isCurrentValue, editable, placeholder, onFetching, withPrefix, schoolAdminStyle }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    onFetching && onFetching(isLoading)
  }, [isLoading])

  const usernameAvailability = async (username: string) => {
    setIsLoading(true)

    const isExists = await usernameTaken(username)

    if (username && isExists && withPrefix) {
      const newAppendName = appendNamePrefix(username)
      usernameAvailabilityDebounced(newAppendName)
      onChangeText(newAppendName)
      return
    }

    setUserExists(isExists);
    setIsLoading(false)
  };

  const usernameAvailabilityDebounced = useCallback(_.debounce(usernameAvailability, 500), []);

  const setNicknameFiltered = (value: string) => {
    const filteredRegex = /[^0-9a-zA-Z_.@-]/
    if (value.search(filteredRegex) < 0) {
      const lowercaseValue = value.toLowerCase()
      usernameAvailabilityDebounced(lowercaseValue);
      onChangeText(lowercaseValue);
    }
  };

  if (schoolAdminStyle) {
    return (
      <View>
        <InputDefault
          value={value}
          onChangeText={setNicknameFiltered}
          label="Username" placeholder={placeholder || "Username"}
          inputProps={{
            autoCorrect: false
          }}
          editable />
        <InputCognitoUsernameInfoAndErrorMessage value={value} userExists={userExists} isAdminResetPassword={isAdminResetPassword} isCurrentValue={isCurrentValue} />
      </View>
    )
  }

  return (
    <View style={[styles.container, containerStyle]}>
      {title !== 'none' && <DefaultText children={title || "Nickname / Username"} style={styles.heading} />}
      <View>
        <TextInput
          testID="input-cognito-username"
          style={[CommonStyles.textInput, editable === false && styles.disabled]}
          autoCapitalize="none"
          autoCorrect={false}
          placeholder={placeholder == null ? "Nickname" : placeholder}
          placeholderTextColor={placeholder ? DecidaColors.Gray : undefined}
          value={value}
          onChangeText={setNicknameFiltered}
          editable={editable}
        />
        {
          isLoading && (
            <ActivityIndicator size="small" color={DecidaColors.Gray} style={styles.loader} />
          )
        }
      </View>
      <InputCognitoUsernameInfoAndErrorMessage value={value} userExists={userExists} isAdminResetPassword={isAdminResetPassword} isCurrentValue={isCurrentValue} />

    </View>
  );
};

const InputCognitoUsernameInfoAndErrorMessage = ({ value, userExists, isAdminResetPassword, isCurrentValue }: { userExists: boolean | null; value?: string; isAdminResetPassword?: boolean; isCurrentValue?: boolean }) => {
  return (
    <>
      <DefaultText style={styles.prompt}>Accepts letters, numbers, and _.@- symbols</DefaultText>
      {value && value.length < 3 ? (
        <DefaultText style={{ color: DecidaColors.Red }}>Nickname should have a least 3 characters.</DefaultText>
      ) : userExists === null ? null : userExists ? (
        <DefaultText style={{ color: DecidaColors.Red }}>{isAdminResetPassword ? null : "Nickname is not available."}</DefaultText>
      ) : !value || isCurrentValue ? (
        null
      ) : (
        <DefaultText style={{ color: isAdminResetPassword ? DecidaColors.Red : DecidaColors.Green }}>{isAdminResetPassword ? "User not found" : "Nickname is available."}</DefaultText>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  heading: {
    fontSize: 17,
    fontWeight: "bold",
    paddingTop: 20,
  },
  disabled: {
    color: DecidaColors.Gray
  },
  loader: {
    position: 'absolute',
    right: 10,
    top: 16,
  },
  prompt: {
    fontSize: 14,
    color: DecidaColors.Gray
  }
});
