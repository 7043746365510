import { nativeMessageSavedUsername, nativeMessageSavedPassword, nativeMessageHasBiometric, nativeMessageBiometricLoginSucceeded, nativeMessageDevicePushToken } from "../native/native-messages"
import { rvSavedUsername, rvSavedPassword, rvHasBiometric, rvBiometricLoginSucceeded, rvDevicePushToken } from "./web-view-state"

if (window && window.addEventListener) {
  window.addEventListener('message', (e) => {
    if (typeof e.data === 'string') {
      if (e.data.startsWith(nativeMessageSavedUsername)) {
        rvSavedUsername(e.data.substring(nativeMessageSavedUsername.length))
      } else if (e.data.startsWith(nativeMessageSavedPassword)) {
        rvSavedPassword(e.data.substring(nativeMessageSavedPassword.length))
      } else if (e.data === nativeMessageHasBiometric) {
        rvHasBiometric(true)
      } else if (e.data === nativeMessageBiometricLoginSucceeded) {
        rvBiometricLoginSucceeded(true)
      } else if (e.data.startsWith(nativeMessageDevicePushToken)) {
        rvDevicePushToken(e.data.substring(nativeMessageDevicePushToken.length))
      }
    }
  })
}
