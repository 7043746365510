import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { SafeRantIcon } from '../../explore/src/safe-rant-icon'
import { SafeRantIconInverted } from '../../explore/src/safe-rant-icon-inverted'
import { SafeRantImage1 } from './src/safe-rant-image1'
import { CommonStyles } from '../../../common/const'
import DefaultTextWithLink from '../../common/default-text-with-link'

export const SwitchesEmotionsAngrySafeRant: CardTemplate = {
    cardId: "30cca8b1-6d78-4776-855c-d77a69af7de6",
    title: 'Safe rant',
    Header: SafeRantIconInverted,
    Icon: SafeRantIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Those private moments where you need to let out angry energy before you respond."} />
        </>
    ),
    Images: () => (
        <>
            <SafeRantImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Find a scrap piece of paper, or use the notes function on your device."} />
                <DefaultTextWithDownArrow textdata={"Write down EXACTLY how you actually feel, leaving nothing out and not trying to be polite."} />
                <DefaultTextWithDownArrow textdata={"Read it back to yourself, take a big deep breath and then throw the paper away (make sure no one else will be able to read it) or delete the note from your device."} />
                <DefaultTextWithoutArrows textdata={"Think about why you are feeling angry and why it makes you so angry. Try to visualise yourself not getting so angry at it in the future."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: 'Science shows us that suppressed emotions often ‘leak’ out in ways we can’t always see, and can lead to poor decision making and missed opportunities. Shame and guilt at our own inability to control our anger rarely increases ability to tame our rage - it often makes it worse. Learning to let it out in a safe way can be the first step to controlling the rage.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Describe the impact of yelling.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice using written storytelling to express negative emotion partnered with the symbolic act of letting go of that narrative. </DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 35 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction: (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Start by asking students if they've ever yelled at someone when angry. Encourage a brief discussion about the reasons behind yelling.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that the lesson will explore the impact of yelling and introduce an alternative method for expressing anger without hurting others.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Instruction: (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss why yelling is considered a bad decision and its potential consequences. Specifically:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Creates Stress and Tension: Yelling contributes to the escalation of stress and tension in a situation. It not only affects the person being yelled at but also influences the overall atmosphere, making it uncomfortable and emotionally charged.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Escalates Conflicts: Yelling often leads to an escalation of conflicts. Instead of resolving issues, it can intensify disagreements, making it difficult to find common ground or understanding.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Hurts Relationships: Raised voices and hurtful words can damage relationships. Verbal aggression can leave lasting emotional scars, impacting the trust and connection between individuals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Impairs Communication: Yelling hinders effective communication. When people are shouting, it becomes challenging to convey messages clearly, and the intended meaning may get lost in the intensity of the moment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Regrettable Actions: In the heat of anger, individuals may say things they don't mean. Yelling can lead to regrettable actions and words that, once spoken, cannot be easily taken back. This can strain relationships and lead to long term consequences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Creates a Negative Environment: Yelling contributes to a negative and hostile environment, affecting everyone present. It can create a culture of fear, making it difficult for open and healthy communication to thrive.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Doesn't Solve Problems: Yelling rarely solves problems. Instead, it shifts the focus from resolving issues to managing the emotional fallout of the confrontation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Impacts Mental Health: Yelling can have adverse effects on mental health, both for the person yelling and the one being yelled at. It contributes to stress, anxiety, and can lead to feelings of fear and insecurity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Missed Opportunities for Understanding: Yelling shuts down opportunities for understanding and compromise. It impedes active listening and prevents the parties involved from finding common ground.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Clarify that yelling is a method of control that can escalate tensions and lead to hurtful words.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the Safe Rant Switch as a way to express frustrations without raising one's voice.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity: Safe Rant Writing and Disposal: (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the process: Students will write down everything they would want to yell on a piece of paper, emphasising speed over spelling or quality.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allow students a minute or so to complete their writing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to stop writing, take a couple of deep breaths, and then tear the paper into tiny pieces.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Direct students to throw the torn pieces into the bin, symbolising the release of the need to yell those hurtful words.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage a quiet and focused atmosphere during this activity.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Debrief: (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Engage in a class discussion about the experience. Ask students to share how the symbolic act of throwing away the rant made them feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the potential benefits of using the Safe Rant Switch, such as reducing stress and avoiding hurtful words.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that expressing anger through writing provides a healthier and more constructive outlet.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points and lessons learned during the activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to consider using the Safe Rant Switch in the future when facing anger or frustration.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, prompt them to find the switch in the Switch4Schools app for further reinforcement.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Blank paper </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• A rubbish bin </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>6 Virtues, and 6 Vices, of Venting -  </DefaultText>
                        <DefaultTextWithLink text='https://www.psychologytoday.com/au/blog/evolution-the-self/201404/6-virtues-and-6-vices-venting' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Why do You Yell and Shout when Angry - </DefaultText>
                        <DefaultTextWithLink text='https://psychcentral.com/blog/anger/2015/09/why-do-you-yell-and-shout-when-angry#1' />

                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Here are some other constructive ways to release anger: ' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Hit a ball, for example in sports such as tennis and golf. "} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Knead some bread or plasticine."} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Go for a run."} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Draw or paint."} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Dance and/or sing."} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Vent to your pillow. Put your pillow in a chair opposite to you and pretend it is the person you are angry with and tell them exactly what you think (make sure you’re alone). "} />

        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Safe+Rant.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Angry]
}

