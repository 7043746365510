import { View } from 'react-native'
import { CardActionType, CardFilterEmotionType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { CardColorScheme } from '../../common/card-color-scheme'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import { PictureCompleterIcon } from '../../explore/src/picture-completer-icon'
import { PictureCompleterimage1 } from './src/picture-completer-image1'
import { DefaultTextWithExtraTipIcon } from '../../common/default-text-with-extra-tip-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { PictureCompleterIconInverted } from '../../explore/src/picture-completer-icon-inverted'

export const SwitchesEmotionsExcitedPictureCompleter: CardTemplate = {
    cardId: "0d7507d8-0d23-4c40-87cf-d8621977cf9c",
    title: 'Picture completer',
    Header: PictureCompleterIconInverted,
    Icon: PictureCompleterIcon,
    Images: () => (
        <>
            <PictureCompleterimage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Calming excitement and increasing awareness."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Grab a sheet of paper, draw 3 shapes or lines on it similar to one of the examples provided."} />
                <DefaultTextWithDownArrow textdata={"Use the lines on the page as a starting point and create a picture using those lines. Be as creative as you like."} />
                <DefaultTextWithDownArrow textdata={"Choose a title for your creation and sign your name at the bottom of the page."} />
                <DefaultTextWithoutArrows textdata={"Take your new creation to your teacher and grab another piece of paper if you would like to and ask for another sheet if you would like to try another drawing!"} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.EXTRA_SWITCHES,
    whyDoesItWorkText: 'Our brain absorbs visual information much more effectively than auditory communication. Creating order out of chaos is soothing for our brain, and helps give us a sense of control and safety.A picture really is worth a thousand words.',
    ExtraTips: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithExtraTipIcon textdata={"Create a calm, quiet space for this activity. "} />
                <DefaultTextWithExtraTipIcon textdata={"Thought provoking questions: "} />
                <DefaultTextWithExtraTipIcon textdata={"When you look at the incomplete picture what do you think it could be?"} />
                <DefaultTextWithExtraTipIcon textdata={"What if you turned the page upside down, what could it be then? "} />
                <DefaultTextWithExtraTipIcon textdata={"What shapes and colours would bring the picture to life?"} />
            </View>
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor,],
    category: [],
    action: [CardActionType.Thought],
    level: [],
    emotion: [CardFilterEmotionType.Excited]
}
