import { GraphQLResult } from "@aws-amplify/api"
import { API, graphqlOperation } from "aws-amplify"
import { MutableRefObject } from "react"
import { CreateStudentClassMutationVariables, CreateStudentClassMutation } from "../../../common/API"
import { createStudentClass } from "../../../common/graphql/mutations"
import { StudentClassApprovalStatus } from "../../models"
import { ListClassTeachersAndStudentsBySchoolIDQueryItem } from "./list-class-teachers-and-students-by-school-id"

type Params = {
    student: { classes?: string[] },
    studentID: string,
    existingClasses: MutableRefObject<ListClassTeachersAndStudentsBySchoolIDQueryItem[]>,
}

export const assignStudentToClasses = async ({
    student,
    studentID,
    existingClasses,
}: Params) => {
    if (student.classes) {
        await Promise.all(student.classes?.map(async c => {
            try {
                const existingClass = existingClasses.current.find(ec => ec?.name === c)
                
                if(existingClass && existingClass.students.items.find(s => s.studentID === studentID && s._deleted !== true) == undefined) {
                    await API.graphql(graphqlOperation(
                        createStudentClass,
                        {
                            input: {
                                classID: existingClass.id,
                                studentID,
                                status: StudentClassApprovalStatus.APPROVED
                            }
                        } as CreateStudentClassMutationVariables
                    )) as GraphQLResult<CreateStudentClassMutation>
                }
            } catch (e) {
                console.log('error assigning student to class')
                console.log(existingClasses)
                console.log(student)
                console.log(c)
                console.log(e)
            }
        }))
    }
}

