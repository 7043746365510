import { useReactiveVar } from "@apollo/client"
import { DrawerNavigationProp } from "@react-navigation/drawer"
import { useIsFocused, useNavigation } from "@react-navigation/native"
import { useEffect } from "react"
import { Platform } from "react-native"
import { rvUserGroup } from "../login/login-state"
import { ScreenNames } from "./screen-names"
import { UserGroup } from "../../common/constants"

export const PlaceHolderComponent = () => {
  const isFocused = useIsFocused()
  const userGroup = useReactiveVar(rvUserGroup)
  const { navigate } = useNavigation<DrawerNavigationProp<any>>()

  useEffect(() => {
    handleRedirect()

  }, [navigate, isFocused])

  const handleRedirect = () => {
    let redirectRoute = ""
    switch (userGroup) {
      case UserGroup.Teacher:
        redirectRoute = ScreenNames.TeacherHome
        break;
      case UserGroup.Student:
        redirectRoute = ScreenNames.StudentHome
        break;
      case UserGroup.ClassLogin:
        redirectRoute = ScreenNames.ClassLoginHome
        break;

      default:
        break;
    }

    if (window && Platform.OS === 'web') {
      window.location.href = `/${redirectRoute}`
    } else {
      navigate(redirectRoute)
    }
  }
  return null
}