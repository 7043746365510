import { StyleSheet, Text, View } from 'react-native'
import { Class, GetClassQuery, GetClassQueryVariables, GetTeacherQuery, GetTeacherQueryVariables, Teacher, TeacherClass, UpdateClassMutation, UpdateClassMutationVariables } from '../../common/API'
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { getClass, getTeacher } from '../../common/graphql/queries'
import { DefaultText } from '../common/default-text'
import { DefaultSwitch } from '../common/default-switch'
import { rvNetInfo } from '../common/common-state'
import { showOfflineAlert } from '../common/universal-alert'
import { updateClass } from '../../common/graphql/mutations'
import { TEACHER_SIDEBAR_WIDTH } from './teacher-consts'
import { rvSidebarClassID } from '../school-admin/school-admin-state'
import { DecidaColors } from '../../common/decida-colors'

type Props = {
  teacherClass: TeacherClass
}

const TeacherClassAdminRequestForChatTeacher = ({ teacherClass: { teacherID, classID } }: Props) => {

  const { data: classResponse } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: classID }, skip: !classID })
  const { data: teacherResponse } = useQuery<GetTeacherQuery, GetTeacherQueryVariables>(gql`${getTeacher}`, { variables: { id: teacherID } })
  const currentSidebarClassID = useReactiveVar(rvSidebarClassID)
  const [updateClassMutation] = useMutation<UpdateClassMutation, UpdateClassMutationVariables>(gql`${updateClass}`,)

  const netInfoState = useReactiveVar(rvNetInfo)

  const teacher = teacherResponse?.getTeacher as Teacher

  if (!teacher || teacher._deleted === true) {
    return null
  }

  const clazz = classResponse?.getClass as Class

  const fullName = `${teacher?.firstName || ""} ${teacher?.lastName || ""}`


  const changeToogleChatNotifications = (teacherEmail: string) => async (switchStatus: boolean) => {
    if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

    if (clazz) {
      const { id, _version, emailReceivers } = clazz
      let currentEmailReceivers = emailReceivers?.length ? emailReceivers : [];
      const isExisted = currentEmailReceivers.some((email) => email === teacherEmail)

      if (isExisted) {
        currentEmailReceivers = currentEmailReceivers.filter((email) => email !== teacherEmail)
      } else {
        currentEmailReceivers = [...currentEmailReceivers, teacherEmail]
      }

      await updateClassMutation({
        variables: {
          input: {
            id,
            _version,
            toggleRequestForChatNotifications: !!currentEmailReceivers.length, // should only turn off when there's no email receivers
            emailReceivers: currentEmailReceivers
          }
        }
      })
    }
  }

  return (
    <View key={teacher?.cognitoUsername} style={[styles.teacherClassesContainer, { width: currentSidebarClassID ? "100%" : TEACHER_SIDEBAR_WIDTH }]}>
      <DefaultText style={[styles.teacherNameText, { color: currentSidebarClassID ? DecidaColors.AppleSystemGray2Dark : DecidaColors.Black }]}>{fullName}</DefaultText>
      <DefaultSwitch value={clazz?.emailReceivers?.includes(teacher?.email) || false} onChange={changeToogleChatNotifications(teacher?.email)} />
    </View>
  )
}
export default TeacherClassAdminRequestForChatTeacher
const styles = StyleSheet.create({
  teacherClassesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: TEACHER_SIDEBAR_WIDTH,
    marginBottom: 10
  },

  teacherNameText: {
    fontSize: 14,
    fontWeight: 'normal'
  },
})