import { View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { TCardColorScheme } from '../../common/card-color-scheme'
import useDeviceTypes from '../../../common/use-device-types'
import { useResponsive } from '../../../common/use-responsive'

interface Props {
    colorScheme: TCardColorScheme
    textdata: string
}

export const Toolbox = ({ colorScheme, textdata }: Props) => {
    const { isNarrowScreen } = useResponsive()

    return (
        <View style={{ width: '100%', height: isNarrowScreen ? undefined : 30, backgroundColor: colorScheme, display: 'flex', justifyContent: 'center' }}>
            <DefaultText style={{ color: DecidaColors.White, textAlign: 'justify', alignItems: 'center', paddingLeft: 10 }}>
                {textdata}
            </DefaultText>
        </View>
    )
}

