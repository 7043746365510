import Svg, { Path } from 'react-native-svg'
import { DecidaColors } from "../../common/decida-colors"

interface Props {
    fillColor?: string,
    percent?: number
}

export const IconRightArrow = ({ fillColor = DecidaColors.White, percent = 1 }: Props) =>
    <Svg width={26.915 * percent} height={31.747 * percent} viewBox="0 0 26.915 31.747">
        <Path d="M107.961,105.482l16.211-14.689L108,76v6.811c-.283.017-9.27.08-9.5.1V98.541h9.461Z" transform="translate(-98 -74.863)" fill={fillColor} stroke={DecidaColors.Green} strokeWidth="1" />
    </Svg>
