import { StyleSheet, Text, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import DefaultTextWithCustomLink from '../switches/common/default-text-with-custom-link'


const UserEmailText = ({ email, fontSize = 12 }: { email: string, fontSize?: number }) => {
    return (
        <DefaultTextWithCustomLink style={[styles.emailText, { fontSize }]} text={email} link={`mailto:${email}`} />
    )
}
export default UserEmailText
const styles = StyleSheet.create({
    emailText: {
        paddingLeft: 0,
        marginLeft: 0,
        fontSize: 12,
        color: DecidaColors.Sad
    },
})