import { gql, useMutation, useQuery } from '@apollo/client'
import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack'
import { useEffect, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import uuid from 'react-native-uuid'
import { ArchivedClassMutation, ArchivedClassMutationVariables, Campus, Class, ClassGroup, CreateClassMutation, CreateClassMutationVariables, CustomCreateTeacherClassMutation, CustomCreateTeacherClassMutationVariables, CustomDeleteTeacherClassMutation, CustomDeleteTeacherClassMutationVariables, DeleteClassMutation, DeleteClassMutationVariables, GetCampusQuery, GetCampusQueryVariables, GetClassQuery, GetClassQueryVariables, GetListTeacherBySchoolIDQuery, GetListTeacherBySchoolIDQueryVariables, GetSchoolQuery, GetSchoolQueryVariables, ListCampusesBySchoolIDQuery, ListCampusesBySchoolIDQueryVariables, ListClassGroupsBySchoolIDQuery, ListClassGroupsBySchoolIDQueryVariables, ListTeacherClassesByClassIDQuery, ListTeacherClassesByClassIDQueryVariables, SchoolAdminUI, Teacher, UpdateClassMutation, UpdateClassMutationVariables } from '../../common/API'
import { PrimitiveOnly } from '../../common/common-types'
import { DecidaColors } from "../../common/decida-colors"
import { archivedClass, customCreateTeacherClass, customDeleteTeacherClass } from '../../common/graphql/mutations'
import { getCampus, listCampusesBySchoolID, listClassGroupsBySchoolID, listTeacherClassesByClassID } from '../../common/graphql/queries'
import { CheckBox } from '../common/checkbox'
import { rvCurrentClass } from '../common/common-state'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { DeleteFunction, Form, SaveFunction } from '../common/form'
import { Loading, rvIsLoading } from '../common/loading'
import { RadioButton } from '../common/radio-button'
import { ScreenNames } from '../common/screen-names'
import { showAlert } from '../common/universal-alert'
import { CONTAINER_INPUT_WIDTH } from './admin-consts'
import { AdminStackNavigatorParamList } from './admin-route-param-types'
import { createClassMutation, deleteClassMutation, getListTeacherBySchoolIDQuery, mutateClassRefetchQueries as refetchQueries, updateClassMutation } from './graphql-scripts'
import { isDefined } from '../../common/is-defined'
import { AntDesign } from '@expo/vector-icons';
import { adminGetSchoolQuery } from '../custom-graphql/queries/admin-custom-queries/admin-get-school-query'
import { adminGetClassQuery } from '../custom-graphql/queries/admin-custom-queries/admin-get-class-query'

export const AdminClass = ({ route: { params: { id, schoolID, goMode } }, navigation: { navigate } }: StackScreenProps<AdminStackNavigatorParamList, 'AdminClass'>) => {
    const { data, loading } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${adminGetClassQuery}`, { variables: { id: id || '' }, skip: id == undefined })

    const [createClass] = useMutation<CreateClassMutation, CreateClassMutationVariables>(gql`${createClassMutation}`, { refetchQueries })
    const [updateClass] = useMutation<UpdateClassMutation, UpdateClassMutationVariables>(gql`${updateClassMutation}`, { refetchQueries })
    const [deleteClass] = useMutation<DeleteClassMutation, DeleteClassMutationVariables>(gql`${deleteClassMutation}`, { refetchQueries })

    if (loading) {
        return <Loading isLoading />
    }

    const goToAdminSchool = () => navigate(ScreenNames.AdminSchool, { id: schoolID })

    const onSave: SaveFunction<PrimitiveOnly<Class>> = async (state, modelInstance) => {
        if (modelInstance?.id == undefined || modelInstance.id === '') {
            return (await createClass({
                variables: {
                    input: {
                        id: uuid.v4().toString(),
                        name: state.name?.value || '',
                        schoolID,
                        _version: 1,
                        activeStudents: 0,
                        archived: false,
                        checkInCountRollingWeekMonday: 0,
                        checkInCountRollingWeekTuesday: 0,
                        checkInCountRollingWeekWednesday: 0,
                        checkInCountRollingWeekThursday: 0,
                        checkInCountRollingWeekFriday: 0,
                        checkInCountRollingWeekSaturday: 0,
                        checkInCountRollingWeekSunday: 0,
                        checkInCountYTD: 0,
                        goMode: !!goMode,
                    }
                }
            })).data?.createClass!
        } else {
            return (await updateClass({
                variables: {
                    input: {
                        id: modelInstance.id,
                        name: state.name?.value || '',
                        _version: modelInstance._version,
                        activeStudents: modelInstance.activeStudents,
                        archived: modelInstance.archived,
                    }
                }
            })).data?.updateClass!
        }
    }

    const onDelete: DeleteFunction<Class> = async (modelInstance) => {
        await deleteClass({
            variables: {
                input: {
                    id: modelInstance.id,
                    _version: modelInstance._version,
                }
            }
        })
    }

    return (
        <Form
            model={data?.getClass as Class | undefined}
            goBack={goToAdminSchool}
            name='class'
            onSave={onSave}
            onDelete={onDelete}
            config={{
                schoolID: {
                    default: schoolID,
                    hide: true
                },
                name: {},
            }}
            additionalComponents={(clazz) => (
                <>
                    <ClassGroupsAdditionalComponents clazz={clazz} />
                    <ClassAditionalComponents clazz={clazz} />
                </>
            )}
        />
    )
}

const ClassGroupsAdditionalComponents = ({ clazz }: { clazz: Class | undefined }) => {
    const { data: listClassGroupsQuery } = useQuery<ListClassGroupsBySchoolIDQuery, ListClassGroupsBySchoolIDQueryVariables>(
        gql`${listClassGroupsBySchoolID}`,
        { variables: { schoolID: clazz?.schoolID || "" }, skip: !clazz }
    );
    const { data: listCampusesQuery } = useQuery<ListCampusesBySchoolIDQuery, ListCampusesBySchoolIDQueryVariables>(
        gql`${listCampusesBySchoolID}`,
        { variables: { schoolID: clazz?.schoolID || "" }, skip: !clazz }
    );
    const { data: getSchoolQueryResponse } = useQuery<GetSchoolQuery, GetSchoolQueryVariables>(
        gql`${adminGetSchoolQuery}`,
        { variables: { id: clazz?.schoolID || "" }, skip: !clazz }
    );

    const campuses = listCampusesQuery?.listCampusesBySchoolID?.items.filter(isDefined).filter(c => !c._deleted);
    const classGroups = listClassGroupsQuery?.listClassGroupsBySchoolID?.items.filter(isDefined).filter(cg => !cg._deleted);
    const school = getSchoolQueryResponse?.getSchool;

    if (!clazz || !school || school.schoolAdminUI !== SchoolAdminUI.HIERARCHY) {
        return null;
    }

    return (
        <View style={{ marginVertical: 20, borderWidth: 1, padding: 5, width: CONTAINER_INPUT_WIDTH }}>
            <DefaultText style={{ marginBottom: 10, fontSize: 24, fontWeight: 'bold' }}>Class groups by campus:</DefaultText>
            {campuses?.map(campus => <ClassCampusAndGroupComponent key={campus.id} campus={campus} classGroups={classGroups || []} clazz={clazz} />)}
        </View>
    );
};

const ClassAditionalComponents = ({ clazz }: { clazz: Class | undefined }) => {
    const [archivedClassMutation] = useMutation<ArchivedClassMutation, ArchivedClassMutationVariables>(gql`${archivedClass}`, { refetchQueries })
    const { data: teacherList, loading: teacherListLoading } = useQuery<GetListTeacherBySchoolIDQuery, GetListTeacherBySchoolIDQueryVariables>(gql`${getListTeacherBySchoolIDQuery}`, { variables: { schoolID: clazz?.schoolID || '', limit: 5000, filter: { _deleted: { ne: true } } }, skip: clazz?.schoolID == undefined })
    const { navigate } = useNavigation<StackNavigationProp<AdminStackNavigatorParamList>>()

    useEffect(() => {
        rvCurrentClass(clazz)
    }, [clazz])

    if (!clazz) {
        return null
    }

    if (teacherListLoading) {
        return <Loading isLoading />
    }

    const navigateToClassDetails = () => {
        navigate(ScreenNames.AdminClassMain, { screen: ScreenNames.AdminClassDetails, params: { classID: clazz.id } })
    }

    const archiveClass = () => {
        showAlert({
            title: 'Archive Class',
            message: `Are you sure you want to archive class ${clazz?.name}?`,
            leftButtonText: 'Cancel',
            rightButtonText: 'Archive',
            onRightButtonPress: async () => {
                await archivedClassMutation({
                    variables: {
                        input: {
                            classID: clazz!.id,
                        }
                    }
                })
                navigate(ScreenNames.AdminSchool, { id: clazz?.schoolID || '' })
            }
        })
    }

    return (
        <>
            <View>
                {teacherList?.getListTeacherBySchoolID?.items.filter(isDefined).map(st => <TeacherComponent key={st.id} teacher={st as Teacher} clazz={clazz} />)}
            </View>
            <DefaultButton onPress={navigateToClassDetails}>
                Class Details
            </DefaultButton>
            <DefaultButton onPress={archiveClass} style={{ backgroundColor: DecidaColors.Red }}>
                Archive Class
            </DefaultButton>
        </>
    )
}


const ClassCampusAndGroupComponent = ({ campus, classGroups, clazz }: { clazz: Class, campus: Campus, classGroups: ClassGroup[] }) => {
    const campusClassGroups = classGroups?.filter(classGroup => classGroup.campusID === campus.id) || [];

    const [expand, setExpand] = useState(false)

    const toggleExpand = () => {
        setExpand(prev => !prev)
    }
    return (
        <Pressable key={campus.id} style={styles.classCampusListContainer} onPress={toggleExpand}>
            <View style={styles.campusNameWrapper}>
                <DefaultText>{campus.name}</DefaultText>
                <AntDesign name={expand ? "down" : "right"} size={24} color={DecidaColors.Black} />
            </View>
            {expand && (
                <>
                    {campusClassGroups.length ? campusClassGroups.map(classGroup => (
                        <View key={classGroup.id}>
                            <ClassGroupComponent classGroup={classGroup} clazz={clazz} />
                        </View>
                    )) : <DefaultText style={{ paddingLeft: 20, color: DecidaColors.Orange }}>No class groups</DefaultText>}
                </>
            )}
        </Pressable>
    )
}

const ClassGroupComponent = ({ classGroup, clazz }: { classGroup: ClassGroup, clazz: Class }) => {

    const assignedToGroup = classGroup.id === clazz.classGroupID

    const [updateClass] = useMutation<UpdateClassMutation, UpdateClassMutationVariables>(gql`${updateClassMutation}`,)

    const handlePressToggle = async () => {
        try {
            rvIsLoading(true)
            await updateClass({
                variables: {
                    input: {
                        id: clazz.id,
                        _version: clazz._version,
                        classGroupID: !assignedToGroup ? classGroup.id : null
                    }
                }
            })

        } catch (error) {
            console.error({ error })
        } finally {
            rvIsLoading(false)
        }

    }

    return (
        <Pressable style={styles.containerClassgroups} onPress={handlePressToggle}>
            <View style={styles.textContainer}>
                <DefaultText>
                    {`${classGroup.name}`}
                </DefaultText>
            </View>
            <Pressable onPress={handlePressToggle}>
                <RadioButton checked={assignedToGroup ? true : false} />
            </Pressable>
        </Pressable>
    )
}

const TeacherComponent = ({ teacher, clazz }: { teacher: Teacher, clazz: Class }) => {
    const { data: teacherClassResponse, refetch } = useQuery<ListTeacherClassesByClassIDQuery, ListTeacherClassesByClassIDQueryVariables>(gql`${listTeacherClassesByClassID}`, {
        variables: {
            classID: clazz?.id || "",
        }, skip: !clazz,
    })

    const teacherClasses = teacherClassResponse?.listTeacherClassesByClassID?.items.filter(isDefined)
    const teacherClass = teacherClasses?.find(tc => tc.teacherID === teacher.id && tc._deleted !== true)

    const [customCreateTeacherClassMutation] = useMutation<CustomCreateTeacherClassMutation, CustomCreateTeacherClassMutationVariables>(gql`${customCreateTeacherClass}`, { refetchQueries })
    const [customDeleteTeachClassMutation] = useMutation<CustomDeleteTeacherClassMutation, CustomDeleteTeacherClassMutationVariables>(gql`${customDeleteTeacherClass}`, { refetchQueries })

    const handleSelectTeacher = async () => {
        rvIsLoading(true)

        if (!teacherClass) {
            await customCreateTeacherClassMutation({
                variables: {
                    input: {
                        classID: clazz.id,
                        teacherID: teacher.id
                    }
                }
            })
        } else {
            await customDeleteTeachClassMutation({
                variables: {
                    input: {
                        id: teacherClass.id,
                    }
                }
            })
        }

        await refetch()
        rvIsLoading(false)
    }

    return (
        <View style={styles.container}>
            <View style={styles.textContainer}>
                <DefaultText>
                    {`${teacher.firstName} ${teacher.lastName}`}
                </DefaultText>
                <DefaultText style={styles.emalText}>
                    {teacher.email}
                </DefaultText>
            </View>
            <CheckBox checked={teacherClass ? true : false} onChange={handleSelectTeacher} containerStyle={styles.checkbox} />
        </View>
    )
}

const styles = StyleSheet.create({
    campusNameWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    classCampusListContainer: {
        marginBottom: 10,
        backgroundColor: DecidaColors.GrayBackground,
        paddingVertical: 5,
    },
    containerClassgroups: {
        display: 'flex',
        paddingLeft: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: DecidaColors.GrayBackground,
        marginVertical: 5
    },
    container: {
        display: 'flex',
        width: CONTAINER_INPUT_WIDTH,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    textContainer: {
        flexWrap: 'wrap'
    },
    emalText: {
        fontSize: 16,
        color: DecidaColors.GrayText
    },
    checkbox: {
        padding: 0,
    }
})
