import { View } from 'react-native'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { PicassoIcon } from '../../icons/picasso-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { PicassoIconInverted } from '../../icons/picasso-icon-inverted'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'

export const SwitchesActivitiesRemoteClassesPicasso: CardTemplate = {
    cardId: "1b441fc7-3d99-49a4-b6bf-82bacf023f8d",
    title: 'Picasso',
    Header: PicassoIconInverted,
    Icon: PicassoIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Inspiring creativity and simple hand-eye coordination."} />
            <DefaulttextWithRightArrow textdata={"Set up a meeting whiteboard or create a shared powerpoint presentation."} />
            <DefaulttextWithRightArrow textdata={"If in powerpoint, make sure each participant has their own page."} />
            <DefaulttextWithRightArrow textdata={"Give 1 minute for everyone to quickly draw something that is in their immediate line of sight."} />
            <DefaulttextWithRightArrow textdata={"Pens down after one minute and go through each drawing to see if people can guess what was drawn."} />
            <DefaulttextWithRightArrow textdata={"Have other class member give 'thumbs up' emojis to pictures they think are good."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.FOR_REMOTE_CLASSES,
    whyDoesItWorkText: '',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Piccasso.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.FOR_REMOTE_CLASSES],
    action: [CardActionType.Action],
    level: []
}

