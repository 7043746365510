import { Picker } from '@react-native-picker/picker';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { DefaultFontSize } from './const';
import _ from 'lodash';
import { DecidaColors } from '../../common/decida-colors';

type Props = {
    value: string
    setValue: (value: string) => void
}

type TimezoneListTypes = {
    label: string
    value: string
}

const DropdownPickerTimezone = ({ value, setValue }: Props) => {
    const [pickerOptions, setPickerOptions] = useState<TimezoneListTypes[]>([])

    const sortTimezoneList = (list: string[]) => {
        const regionKey = 'Australia'
        const keyList = list.filter((tz) => tz.toLowerCase().includes(regionKey.toLowerCase()))

        const result = _.uniq(_.concat(keyList, list))

        const formattedTimezone = result.map(zone => {
            const gmtValue = moment.tz(zone).utcOffset()
            return {
                label: `${zone} GMT${gmtValue > 0 ? "+" : ""}${gmtValue / 60}`,
                value: zone,
            }
        })
        return formattedTimezone
    }

    useEffect(() => {
        const allOptions = moment.tz.names()
        setPickerOptions([{ label: '', value: '' }, ...sortTimezoneList(allOptions)])
    }, [value])

    return (
        <Picker
            selectedValue={value}
            onValueChange={setValue}
            style={styles.timezonePicker}
        >
            {pickerOptions.map((option, index) => {

                return <Picker.Item key={`${option}-${index}`} label={option.label} value={option.value} />
            })}
        </Picker>
    )
}

export default DropdownPickerTimezone

const styles = StyleSheet.create({
    timezonePicker: {
        width: 330,
        borderColor: DecidaColors.Gray,
        borderWidth: 1,
        fontSize: DefaultFontSize,
        padding: 8,
        paddingLeft: 7,
        paddingRight: 7,
        marginTop: 7,
        marginBottom: 7,
        borderRadius: 4,
        backgroundColor: DecidaColors.InputField
    }
});