import { StyleSheet, View } from 'react-native'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { CalmDownCornerHeaderIcon } from './src/calm-down-corner-header-icon'
import { CalmDownCornerIcon } from './src/calm-down-corner-icon'
import { CalmDownCornerImage1 } from './src/calm-down-corner-image1'

export const SwitchesEmotionsAngryCalmDownCorner: CardTemplate = {
    cardId: "ff61f56b-0854-4ae3-be28-9f2249667823",
    title: 'Calm down corner',
    Header: CalmDownCornerHeaderIcon,
    Icon: CalmDownCornerIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Those times when our bodies and minds need a little break to help us calm down and feel better."} />

            <DefaultTextWithoutArrows textdata={"A calm down corner is a special place that you can go to when you're feeling upset, angry, or even just a little bit overwhelmed. It's like having your own quiet spot where you can take a break and help yourself feel better. "} />
            <View style={styles.imageContainer}>
                <CalmDownCornerImage1 />
            </View>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    cardContentTemplate: "column",
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: "A calm down corner is effective from a psychological perspective for several reasons. It provides a dedicated space for children to practice emotional regulation skills, allowing them to pause and engage in calming activities when feeling overwhelmed. Incorporating sensory elements such as soft pillows and tactile toys can soothe their nervous systems and reduce stress and anxiety. By spending time in this safe and private space, children can develop self awareness and learn self soothing techniques. Visual supports in the calm down corner, like social stories, aid in reframing thoughts and understanding emotions. Using the calm down corner reinforces positive behaviour and fosters healthier emotional responses over time. ",
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Describe the concept of a calm down corner and develop skills to utilise it effectively for regulating their emotions. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 25 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (2 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students in a circle and explain that today they will be learning about a special place called the "Calm Down Corner" that can help them regulate their emotions. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Emotions (5 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that the calm down corner can help them manage and regulate their emotions when they feel big emotions. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share emotions or example situations where using a calm down corner might be helpful for them.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Exploring the Calm Down Corner (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set up a designated area in the classroom as a calm down corner, including soft pillows, cozy blankets, and optional fidget toys or stress balls.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to take turns visiting the calm down corner. Explain that they can choose an activity that helps them calm down, such as hugging a stuffed animal, squeezing a stress ball, or sitting quietly.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to take a few moments to experience the calm down corner and notice how it makes them feel. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Practicing Calm Down Strategies (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss various strategies that can be used in the calm down corner, such as deep breathing, counting to ten, or using fidget toys.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead the students in a guided deep breathing exercise, emphasising slow inhalation and exhalation. (refer to the Calming Breath switch for inspiration) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice other strategies individually or in pairs, using the materials provided in the calm down corner.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Closure (3 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students back into a circle and ask them to share their experiences in the calm down corner.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how the calm down corner can help them feel better when they're upset or overwhelmed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reiterate that they can use the calm down corner whenever they need it and remind them that it's a safe and peaceful place.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Soft pillows, cozy blankets, or stuffed animals (optional)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Fidget toys or stress balls (optional)</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Calm down corner-</DefaultText>
                        <DefaultTextWithLink text='https://www.actionforhealthykids.org/activity/calm-down-corner/#:~:text=Self%2DAwareness%20and%20Self%2DManagement,regulate%20and%20control%20their%20impulses' />
                        <DefaultText style={CommonStyles.lessonPlanText}>How To Create and Use a Calm-Down Corner in Any Learning Environment -</DefaultText>
                        <DefaultTextWithLink text='https://www.weareteachers.com/calm-down-corner/' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Calm+down+corner+voice+over.m4a",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Angry]
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
})
