import { gql, useQuery, useReactiveVar, useSubscription } from "@apollo/client"
import { createDrawerNavigator } from "@react-navigation/drawer"
import * as Linking from 'expo-linking'
import { useEffect } from "react"
import { StyleSheet } from 'react-native'
import { GetListStaffBySchoolIDQuery, GetListStaffBySchoolIDQueryVariables, GetListTeacherBySchoolIDQuery, GetListTeacherBySchoolIDQueryVariables, GetSchoolAdminByCognitoUsernameQuery, GetSchoolQuery, GetSchoolQueryVariables, ListClassesBySchoolIDQuery, ListClassesBySchoolIDQueryVariables, ListInvoiceBySchoolIDQuery, ListInvoiceBySchoolIDQueryVariables, ListSchoolAdminBySchoolIDQuery, ListSchoolAdminBySchoolIDQueryVariables, OnMutateCampusBySchoolIDSubscription, OnMutateClassGroupsBySchoolIDSubscription, OnMutateClassGroupsBySchoolIDSubscriptionVariables, OnMutateClassSubscriptionsSubscription, OnMutateClassSubscriptionsSubscriptionVariables, OnMutateInvoiceBySchoolIDSubscription, OnMutateInvoiceBySchoolIDSubscriptionVariables, OnMutateSchoolAdminSubscriptionsBySchoolIdSubscription, OnMutateSchoolAdminSubscriptionsBySchoolIdSubscriptionVariables, OnMutateSchoolSubscriptionsBySchoolIdSubscription, OnMutateSchoolSubscriptionsBySchoolIdSubscriptionVariables, OnMutateStaffBySchoolIDSubscription, OnMutateStaffBySchoolIDSubscriptionVariables, OnMutateTeacherBySchoolIDSubscription, OnMutateTeacherBySchoolIDSubscriptionVariables, OnMutateTeacherClassSubscription } from "../../common/API"
import { getListStaffBySchoolID, getListTeacherBySchoolID, getSchool, getSchoolAdminByCognitoUsername, listInvoiceBySchoolID, listSchoolAdminBySchoolID } from "../../common/graphql/queries"
import { onMutateCampusBySchoolID, onMutateClassGroupsBySchoolID, onMutateClassSubscriptions, onMutateInvoiceBySchoolID, onMutateSchoolAdminSubscriptionsBySchoolId, onMutateSchoolSubscriptionsBySchoolId, onMutateStaffBySchoolID, onMutateTeacherBySchoolID, onMutateTeacherClass } from "../../common/graphql/subscriptions"
import { handleRestartApp } from "../common/handle-restart-app"
import { getRouteNameAndParamsFromUrl } from "../common/helper-functions"
import { rvIsLoading } from "../common/loading"
import NotFoundPage from "../common/not-found-page"
import { RootView } from "../common/root-view"
import { ScreenNames } from "../common/screen-names"
import { updateListCampusInSchoolQuery } from "../common/update-list-campus-in-school-query"
import { updateListClassGroupsInSchoolQuery } from "../common/update-list-class-groups-in-school-query"
import { updateListQueryCache } from "../common/update-list-query-cache"
import { updateListTeacherClassInClassesQuery } from "../common/update-list-teacher-class-in-classes-query"
import { rvCurrentUser } from "../login/login-state"
import { TeacherEditStudent } from "../teacher/teacher-edit-student"
import TeacherEmotionIntensityScaleModal from "../teacher/teacher-emotion-intensity-scale-modal"
import TeacherIndividualStudentStatsInfoModal from "../teacher/teacher-individual-student-stats-info-modal"
import TeacherRequestForChatInfoModal from "../teacher/teacher-request-for-chat-info-modal"
import TeacherRequestForEmailNotificationsInfoModal from "../teacher/teacher-request-for-email-notifications-info-modal"
import { rvTeacherEmotionIntensityScaleModal, rvTeacherIndividualStudentStatsInfoModal, rvTeacherRequestForChatInfoModal, rvTeacherRequestForEmailNotificationsInfoModal, rvTeacherWellbeingInfoModal } from "../teacher/teacher-state"
import TeacherWellbeingInfoModal from "../teacher/teacher-wellbeinng-info-modal"
import SchoolAdminBillingDetails from "./school-admin-billing-details"
import SchoolAdminClassMain from "./school-admin-class-main"
import { listClassesBySchoolIDCustomQuery } from "./school-admin-graphql-scripts"
import { SchoolAdminHeader } from "./school-admin-header"
import SchoolAdminHome from "./school-admin-home"
import { SchoolAdminNavigationParams } from "./school-admin-navigation-params"
import { SchoolAdminProfileMain } from "./school-admin-profile-main"
import { rvCurrentSchoolID } from "./school-admin-state"
import SchoolAdminStats from "./school-admin-stats"
import { TeacherEditStudentAvatar } from "../teacher/teacher-edit-student-avatar"
import { getSchoolQuery } from "../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-query"

const SideMenu = createDrawerNavigator<SchoolAdminNavigationParams>()

export const SchoolAdminMain = () => {
    const user = useReactiveVar(rvCurrentUser)

    const { data: resultSchoolAdmin } = useQuery<GetSchoolAdminByCognitoUsernameQuery>(gql`${getSchoolAdminByCognitoUsername}`, { variables: { cognitoUsername: user?.username } })
    const currentSchoolAdmin = resultSchoolAdmin?.getSchoolAdminByCognitoUsername?.items[0]
    const requestForChatInfoModal = useReactiveVar(rvTeacherRequestForChatInfoModal)
    const requestForEmailNotificationsInfoModal = useReactiveVar(rvTeacherRequestForEmailNotificationsInfoModal)
    const individualStudentStatsInfoModal = useReactiveVar(rvTeacherIndividualStudentStatsInfoModal)
    const emotionIntensityScaleModal = useReactiveVar(rvTeacherEmotionIntensityScaleModal)
    const wellbeingInfoModal = useReactiveVar(rvTeacherWellbeingInfoModal)

    const { data, loading: isLoadingSchool } = useQuery<GetSchoolQuery, GetSchoolQueryVariables>(getSchoolQuery, { variables: { id: currentSchoolAdmin?.schoolID || "" }, skip: !currentSchoolAdmin })


    useEffect(() => {
        rvIsLoading(isLoadingSchool)
    }, [isLoadingSchool])


    useEffect(() => {
        if (currentSchoolAdmin) {
            rvCurrentSchoolID(currentSchoolAdmin.schoolID)
        }
    }, [data])

    const { error: errorSchoolSubscription } = useSubscription<OnMutateSchoolSubscriptionsBySchoolIdSubscription, OnMutateSchoolSubscriptionsBySchoolIdSubscriptionVariables>(gql`${onMutateSchoolSubscriptionsBySchoolId}`,
        {
            variables: { id: currentSchoolAdmin?.schoolID || "" },
            skip: !currentSchoolAdmin,
        })
    const { error: errorSchoolAdminSubscription } = useSubscription<OnMutateSchoolAdminSubscriptionsBySchoolIdSubscription, OnMutateSchoolAdminSubscriptionsBySchoolIdSubscriptionVariables>(gql`${onMutateSchoolAdminSubscriptionsBySchoolId}`,
        {
            variables: { schoolID: currentSchoolAdmin?.schoolID || "" },
            skip: !currentSchoolAdmin,
            onData: updateListQueryCache<ListSchoolAdminBySchoolIDQuery, ListSchoolAdminBySchoolIDQueryVariables, OnMutateSchoolAdminSubscriptionsBySchoolIdSubscription>({ listQuery: listSchoolAdminBySchoolID, listQueryName: 'listSchoolAdminBySchoolID', variables: { schoolID: currentSchoolAdmin?.schoolID || "", limit: 5000 }, subscriptionName: 'onMutateSchoolAdminSubscriptionsBySchoolId' })
        })

    const { error: errorClassSubscription } = useSubscription<OnMutateClassSubscriptionsSubscription, OnMutateClassSubscriptionsSubscriptionVariables>(gql`${onMutateClassSubscriptions}`,
        {
            variables: { schoolID: currentSchoolAdmin?.schoolID || "" },
            skip: !currentSchoolAdmin,
            onData: updateListQueryCache<ListClassesBySchoolIDQuery, ListClassesBySchoolIDQueryVariables, OnMutateClassSubscriptionsSubscription>({ listQuery: listClassesBySchoolIDCustomQuery, listQueryName: 'listClassesBySchoolID', variables: { schoolID: currentSchoolAdmin?.schoolID || "", limit: 5000 }, subscriptionName: 'onMutateClassSubscriptions' })
        })

    const { error: errorTeacherClassSubscription } = useSubscription<OnMutateTeacherClassSubscription>(gql`${onMutateTeacherClass}`,
        {
            skip: !currentSchoolAdmin,
            onData: updateListTeacherClassInClassesQuery({ schoolID: currentSchoolAdmin?.schoolID || "" }),
        })

    const { error: errorTeacherSubscription } = useSubscription<OnMutateTeacherBySchoolIDSubscription, OnMutateTeacherBySchoolIDSubscriptionVariables>(gql`${onMutateTeacherBySchoolID}`,
        {
            variables: { schoolID: currentSchoolAdmin?.schoolID || "" },
            skip: !currentSchoolAdmin,
            onData: updateListQueryCache<GetListTeacherBySchoolIDQuery, GetListTeacherBySchoolIDQueryVariables, OnMutateTeacherBySchoolIDSubscription>({ listQuery: getListTeacherBySchoolID, listQueryName: 'getListTeacherBySchoolID', variables: { schoolID: currentSchoolAdmin?.schoolID || "", limit: 5000 }, subscriptionName: 'onMutateTeacherBySchoolID', })
        })

    const { error: errorInvoiceSubscription } = useSubscription<OnMutateInvoiceBySchoolIDSubscription, OnMutateInvoiceBySchoolIDSubscriptionVariables>(gql`${onMutateInvoiceBySchoolID}`,
        {
            variables: { schoolID: currentSchoolAdmin?.schoolID || "" },
            skip: !currentSchoolAdmin,
            onData: updateListQueryCache<ListInvoiceBySchoolIDQuery, ListInvoiceBySchoolIDQueryVariables, OnMutateInvoiceBySchoolIDSubscription>({ listQuery: listInvoiceBySchoolID, listQueryName: 'listInvoiceBySchoolID', subscriptionName: 'onMutateInvoiceBySchoolID', variables: { schoolID: currentSchoolAdmin?.schoolID || "" } })
        })

    const { error: errorClassGroupsSubscription } = useSubscription<OnMutateClassGroupsBySchoolIDSubscription, OnMutateClassGroupsBySchoolIDSubscriptionVariables>(gql`${onMutateClassGroupsBySchoolID}`, {
        variables: {
            schoolID: currentSchoolAdmin?.schoolID || "",
        },
        skip: !currentSchoolAdmin,
        onData: updateListClassGroupsInSchoolQuery({ schoolID: currentSchoolAdmin?.schoolID || "" })
    })

    const { error: errorCampusSubscription } = useSubscription<OnMutateCampusBySchoolIDSubscription, OnMutateClassGroupsBySchoolIDSubscriptionVariables>(gql`${onMutateCampusBySchoolID}`, {
        variables: { schoolID: currentSchoolAdmin?.schoolID || "" },
        skip: !currentSchoolAdmin,
        onData: updateListCampusInSchoolQuery({ schoolID: currentSchoolAdmin?.schoolID || "" })
    })

    const { error: errorStaffSubscription } = useSubscription<OnMutateStaffBySchoolIDSubscription, OnMutateStaffBySchoolIDSubscriptionVariables>(gql`${onMutateStaffBySchoolID}`, {
        variables: { schoolID: currentSchoolAdmin?.schoolID || "" },
        skip: !currentSchoolAdmin,
        onData: updateListQueryCache<GetListStaffBySchoolIDQuery, GetListStaffBySchoolIDQueryVariables, OnMutateStaffBySchoolIDSubscription>({ listQuery: getListStaffBySchoolID, listQueryName: "getListStaffBySchoolID", subscriptionName: "onMutateStaffBySchoolID", variables: { schoolID: currentSchoolAdmin?.schoolID || "", limit: 5000 }, })
    })

    if (
        errorTeacherClassSubscription !== undefined ||
        errorTeacherSubscription !== undefined ||
        errorClassSubscription !== undefined ||
        errorSchoolAdminSubscription !== undefined ||
        errorSchoolSubscription !== undefined ||
        errorInvoiceSubscription !== undefined ||
        errorClassGroupsSubscription !== undefined ||
        errorCampusSubscription !== undefined ||
        errorStaffSubscription !== undefined
    ) {
        handleRestartApp()
    }

    useEffect(() => {
        handleSchoolAdminDeepLinks()
    }, [])

    const handleSchoolAdminDeepLinks = async () => {
        const initialUrl = await Linking.getInitialURL()
        const { routeName, params } = getRouteNameAndParamsFromUrl(initialUrl)

        if (routeName === ScreenNames.Card ||
            routeName === ScreenNames.SwitchesMemes ||
            routeName === ScreenNames.SwitchesEmotionsDetailView
        ) {
            Linking.openURL("/NotFound")
        }
    }

    return (
        <RootView>
            {emotionIntensityScaleModal && <TeacherEmotionIntensityScaleModal />}
            {requestForChatInfoModal && <TeacherRequestForChatInfoModal />}
            {requestForEmailNotificationsInfoModal && <TeacherRequestForEmailNotificationsInfoModal />}
            {individualStudentStatsInfoModal && <TeacherIndividualStudentStatsInfoModal />}
            {wellbeingInfoModal && <TeacherWellbeingInfoModal />}

            <SideMenu.Navigator
                screenOptions={{
                    headerShown: true,
                    header: props => <SchoolAdminHeader {...props} />,
                    swipeEdgeWidth: 0
                }}
                backBehavior="history"
                initialRouteName={ScreenNames.SchoolAdminHome}
            >
                <SideMenu.Screen name={ScreenNames.SchoolAdminHome} component={SchoolAdminHome} options={{ title: 'SchoolAdminHome' }} />
                <SideMenu.Screen name={ScreenNames.NotFound} component={NotFoundPage} />

                <SideMenu.Screen name={ScreenNames.SchoolAdminStats} component={SchoolAdminStats} options={{ title: 'SchoolAdminStats' }} />

                <SideMenu.Screen name={ScreenNames.SchoolAdminClassMain} component={SchoolAdminClassMain} options={{ title: 'SchoolAdminManageClass' }} />
                <SideMenu.Screen name={ScreenNames.SchoolAdminEditStudent} component={TeacherEditStudent} options={{ title: 'SchoolAdminEditStudent' }} />
                {/* <SideMenu.Screen name={ScreenNames.SchoolAdminManageClass} component={SchoolAdminManageClass} options={{ title: 'SchoolAdminManageClass' }} />
                <SideMenu.Screen name={ScreenNames.SchoolAdminAddStudent} component={TeacherAddStudent} options={{ title: 'SchoolAdminAddStudent' }} />
                <SideMenu.Screen name={ScreenNames.SchoolAdminCreateStudentPickAvatar} component={TeacherCreateStudentPickAvatar} options={{ title: 'SchoolAdminCreateStudentPickAvatar' }} />
                <SideMenu.Screen name={ScreenNames.SchoolAdminCreateStudentNickname} component={TeacherCreateStudentNickname} options={{ title: 'SchoolAdminCreateStudentNickname' }} /> */}
                <SideMenu.Screen name={ScreenNames.SchoolAdminEditStudentAvatar} component={TeacherEditStudentAvatar} options={{ title: 'SchoolAdminEditStudentAvatar' }} />

                <SideMenu.Screen name={ScreenNames.SchoolAdminProfileMain} component={SchoolAdminProfileMain} options={{ title: 'SchoolAdminProfile' }} />
                <SideMenu.Screen name={ScreenNames.SchoolAdminBillingDetails} component={SchoolAdminBillingDetails} options={{ title: 'SchoolAdminBillingDetails' }} />
            </SideMenu.Navigator>
        </RootView>
    )
}

const styles = StyleSheet.create({


    bottomNavigation: {
        bottom: 0,
        position: 'relative',
    },

})
