export enum Activities {
    HEALTHY_HABITS = "HEALTHY_HABITS",
    ENERGIZERS = "ENERGIZERS",
    NUMBER_AND_WORD_GAMES = "NUMBER_AND_WORD_GAMES",
    CREATIVITY = "CREATIVITY",
    FOR_REMOTE_CLASSES = "FOR_REMOTE_CLASSES",
    SECRETS_FOR_SLEEP = "SECRETS_FOR_SLEEP",
    ACTIVITY_SHEETS = "ACTIVITY_SHEETS",
    RESILIENCE_BUILDERS = "RESILIENCE_BUILDERS",
    EXTRA_SWITCHES = "EXTRA_SWITCHES",
    FUN_AND_POSITIVE = "FUN_AND_POSITIVE",
    SEPARATION_ANXIETY = "SEPARATION_ANXIETY"
}