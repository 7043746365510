import { StyleSheet, Text, View } from 'react-native'
import { DefaultText } from './default-text'

const SwitchCardNoResultFilter = () => {
    return (
        <View style={styles.noResultFilterContainer}>
            <Text style={styles.emojiThinking}>🤔</Text>
            <DefaultText>Hmm no results.  Try some different search filters.</DefaultText>
        </View>
    )
}
export default SwitchCardNoResultFilter
const styles = StyleSheet.create({
    noResultFilterContainer: {
        alignItems: 'center',
    },
    emojiThinking: {
        fontSize: 60
    }
})