import { useReactiveVar } from "@apollo/client"
import * as Linking from 'expo-linking'
import { useEffect } from "react"
import { rvRedirectPages, rvUserGroup } from "../login/login-state"
import { SwitchesParamList } from "../switches/navigation/switches-param-list"
import { getRouteNameAndParamsFromUrl, } from "./helper-functions"
import { UserGroup } from "../../common/constants"
import { openUrlWebView } from "../web-view/open-url-web-view"

interface IRedirectPages {
    path: string | null,
    routeParams: Linking.QueryParams,
    routeName: keyof SwitchesParamList | undefined | string
}

export const useRedirectPages: () => IRedirectPages = () => {
    const redirectPageUrl = useReactiveVar(rvRedirectPages)
    const userGroup = useReactiveVar(rvUserGroup)
    const url = Linking.useURL();

    const parsedUrl = Linking.parse(url || "/");
    const { path, queryParams } = parsedUrl

    const { routeName, params } = getRouteNameAndParamsFromUrl(url)

    useEffect(() => {
        if (redirectPageUrl && redirectPageUrl !== "/") {

            switch (userGroup) {
                case UserGroup.Student:
                case UserGroup.ClassLogin:
                case UserGroup.Teacher:
                case UserGroup.NonTeachingStaff:
                    openUrlWebView(redirectPageUrl || "/")
                    break;
                default:
                    break;
            }
        }
    }, [redirectPageUrl, userGroup])

    return {
        path,
        routeParams: params,
        routeName
    }
}