import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import { SafeAreaView, StyleSheet, TouchableOpacity, View } from "react-native";
import { DefaultFontSize } from "../common/const";
import { DefaultText } from "../common/default-text";
import { headerHeight } from "../common/header";
import { logout } from "../common/logout";
import { rvShowPopoverSchoolAdmin } from "../common/pop-over-menu-state";

import { versionNumberString } from "../env";
import { rvCurrentUser } from "../login/login-state";
import { SchoolAdminNavigationParams } from "./school-admin-navigation-params";
import { ScreenNames } from '../common/screen-names';
import { EmailSupportPopoverButton } from '../common/popover-buttons';
import { getSchoolAdminByCognitoUsername } from '../../common/graphql/queries';
import { GetSchoolAdminByCognitoUsernameQuery, GetSchoolAdminByCognitoUsernameQueryVariables } from '../../common/API';
import PopoverMenuProfileUsernameAndEmailInfo from '../common/pop-over-menu-profile-username-and-email-info';
import { DecidaColors } from '../../common/decida-colors';


export const SchoolAdminPopOverMenu = () => {

    const showPopover = useReactiveVar(rvShowPopoverSchoolAdmin)
    const user = useReactiveVar(rvCurrentUser)

    const { data } = useQuery<GetSchoolAdminByCognitoUsernameQuery, GetSchoolAdminByCognitoUsernameQueryVariables>(gql`${getSchoolAdminByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
    const currentSchoolAdmin = data?.getSchoolAdminByCognitoUsername?.items[0]

    const closePopup = () => {
        rvShowPopoverSchoolAdmin(false)
    }
    const { navigate } = useNavigation<DrawerNavigationProp<SchoolAdminNavigationParams>>()

    const logoutHandler = () => {
        rvShowPopoverSchoolAdmin(false)
        logout()
    }
    const navigateToProfile = () => {
        navigate(ScreenNames.SchoolAdminProfileMain)
        rvShowPopoverSchoolAdmin(false)
    }

    const navigateToBillingDetails = () => {
        navigate(ScreenNames.SchoolAdminBillingDetails)
        rvShowPopoverSchoolAdmin(false)
    }

    if (!user) {
        return null
    }

    return showPopover ? (
        <TouchableOpacity style={styles.overlay} onPress={closePopup}>
            <SafeAreaView />
            <View style={styles.avatarpopover}>
                <PopoverMenuProfileUsernameAndEmailInfo cognitoUsername={currentSchoolAdmin?.cognitoUsername || ""} email={currentSchoolAdmin?.email || ""} />
                <View style={styles.profile}>
                    <TouchableOpacity onPress={navigateToProfile} style={styles.dividerButton}>
                        <View style={styles.profileContainer}>
                            <DefaultText style={styles.logouttext}>
                                Profile
                            </DefaultText>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={styles.profile}>
                    <TouchableOpacity onPress={navigateToBillingDetails} style={styles.dividerButton}>
                        <View style={styles.profileContainer}>
                            <DefaultText style={styles.logouttext}>
                                Billing details
                            </DefaultText>
                        </View>
                    </TouchableOpacity>
                </View>
                <EmailSupportPopoverButton />
                <View style={styles.logout}>
                    <TouchableOpacity testID="logout-button" onPress={logoutHandler} style={styles.dividerButton}>
                        <DefaultText style={styles.logouttext}>
                            Logout
                        </DefaultText>
                    </TouchableOpacity>
                </View>
                <View style={styles.logout}>
                    <View style={styles.profileContainer}>
                        <DefaultText style={styles.buildVersion}>
                            {versionNumberString}
                        </DefaultText>
                    </View>
                </View>

            </View>
        </TouchableOpacity>
    ) : null
}


const styles = StyleSheet.create({
    overlay: {
        position: 'absolute',
        zIndex: 99,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    avatarpopover: {
        alignItems: 'flex-end',
        width: 300,
        minHeight: 200,
        maxHeight: 500,
        zIndex: 500,
        marginRight: 2,
        backgroundColor: DecidaColors.White,
        borderColor: 'grey',
        borderRadius: 5,
        borderWidth: 0.5,
        marginTop: headerHeight
    },
    logout: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    profile: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    logouttext: {
        marginRight: 15,
        color: DecidaColors.Gray,
        alignItems: 'flex-end',
        justifyContent: 'center',
        fontSize: DefaultFontSize - 2,

    },
    buildVersion: {
        marginRight: 15,
        fontSize: DefaultFontSize - 2,
        color: DecidaColors.AppleSystemGray4Light,
    },
    dividerButton: {
        width: "100%",
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    profileContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },

})
