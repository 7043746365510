// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const StudentStatus = {
  "PENDING": "PENDING",
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "DECLINED": "DECLINED"
};

const StudentClassApprovalStatus = {
  "PENDING": "PENDING",
  "APPROVED": "APPROVED"
};

const Emotion = {
  "HAPPY": "HAPPY",
  "EXCITED": "EXCITED",
  "SCARED": "SCARED",
  "SAD": "SAD",
  "ANGRY": "ANGRY",
  "ANXIOUS": "ANXIOUS"
};

const UserType = {
  "TEACHER": "TEACHER",
  "STUDENT": "STUDENT"
};

const SwitchRatingType = {
  "LIKE": "LIKE",
  "NEUTRAL": "NEUTRAL",
  "DISLIKE": "DISLIKE"
};

const TeacherRole = {
  "ADMINISTRATION": "ADMINISTRATION",
  "CHAPLAIN": "CHAPLAIN",
  "CLASS_VOLUNTEER": "CLASS_VOLUNTEER",
  "DEPUTY_PRINCIPAL": "DEPUTY_PRINCIPAL",
  "GUIDANCE_OFFICER": "GUIDANCE_OFFICER",
  "HEAD_OF": "HEAD_OF",
  "LEARNING_SUPPORT": "LEARNING_SUPPORT",
  "PRINCIPAL": "PRINCIPAL",
  "PSYCHOLOGIST": "PSYCHOLOGIST",
  "TEACHER": "TEACHER",
  "TEACHER_AIDE": "TEACHER_AIDE",
  "WELLBEING": "WELLBEING",
  "YEAR_LEVEL_COORDINATOR": "YEAR_LEVEL_COORDINATOR"
};

const SchoolStatus = {
  "ARCHIVED": "ARCHIVED",
  "ON_HOLD": "ON_HOLD",
  "ON_TRIAL": "ON_TRIAL",
  "SUBSCRIBED": "SUBSCRIBED",
  "TEST": "TEST"
};

const SchoolContactType = {
  "PRINCIPAL": "PRINCIPAL",
  "COORDINATOR": "COORDINATOR"
};

const RequestForChatStatus = {
  "YES": "YES",
  "NO": "NO",
  "NOT_YET": "NOT_YET"
};

const SchoolAdminUi = {
  "LIST": "LIST",
  "HIERARCHY": "HIERARCHY"
};

const QuestionCategory = {
  "CONNECTEDNESS": "CONNECTEDNESS",
  "PSYCHOLOGICAL_SAFETY": "PSYCHOLOGICAL_SAFETY",
  "SOCIAL_IDENTITY": "SOCIAL_IDENTITY"
};

const QuestionType = {
  "ADULT": "ADULT",
  "STUDENT": "STUDENT"
};

const WellbeingResponseUserType = {
  "STUDENT": "STUDENT",
  "NON_TEACHING_STAFF": "NON_TEACHING_STAFF",
  "TEACHER": "TEACHER"
};

const CognitoMessageAction = {
  "SUPPRESS": "SUPPRESS",
  "RESEND": "RESEND"
};

const EatingStatus = {
  "YES": "YES",
  "NO": "NO",
  "PREFER_NOT_TO_SAY": "PREFER_NOT_TO_SAY"
};

const { StudentCheckIn, TeacherCheckIn, StaffCheckIn, CheckInSession, School, Campus, ClassGroup, Class, Teacher, Staff, SchoolAdmin, Student, Support, ClassSupport, TeacherClass, ScheduleClass, StudentClass, SchoolContact, MinFEVersion, SwitchRating, ClassLogin, Favourite, FindYourCalmActivityRating, Invoice, Videos, WellbeingQuestion, WellbeingResponse, Ping, QuestionOption } = initSchema(schema);

export {
  StudentCheckIn,
  TeacherCheckIn,
  StaffCheckIn,
  CheckInSession,
  School,
  Campus,
  ClassGroup,
  Class,
  Teacher,
  Staff,
  SchoolAdmin,
  Student,
  Support,
  ClassSupport,
  TeacherClass,
  ScheduleClass,
  StudentClass,
  SchoolContact,
  MinFEVersion,
  SwitchRating,
  ClassLogin,
  Favourite,
  FindYourCalmActivityRating,
  Invoice,
  Videos,
  WellbeingQuestion,
  WellbeingResponse,
  StudentStatus,
  StudentClassApprovalStatus,
  Emotion,
  UserType,
  SwitchRatingType,
  TeacherRole,
  SchoolStatus,
  SchoolContactType,
  RequestForChatStatus,
  SchoolAdminUi,
  QuestionCategory,
  QuestionType,
  WellbeingResponseUserType,
  CognitoMessageAction,
  EatingStatus,
  Ping,
  QuestionOption
};