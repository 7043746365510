import { StyleSheet, Text, View } from 'react-native'
import { Page } from './page'
import { DefaultText } from './default-text'
import { MaterialIcons } from '@expo/vector-icons';
import { DecidaColors } from '../../common/decida-colors';

const NotFoundPage = () => {
  return (
    <Page>
      <MaterialIcons name="lock" size={100} color={DecidaColors.Brown} />
      <DefaultText style={styles.text}>You don't have permission to view this page</DefaultText  >
    </Page>
  )
}
export default NotFoundPage
const styles = StyleSheet.create({
  text: {
    fontWeight: '500'
  }
})