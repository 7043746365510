import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import { DefaultText } from '../../common/default-text'
import { Worksheets } from '../../common/worksheets-imports'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'


export const SwitchesLessonPlansM8HarnessingExcitement: CardTemplate = {
    cardId: "6728c21a-527b-4e9a-9b52-ee8d99736a44",
    title: 'Harnessing excitement: Developing EI ',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Enhance their self awareness and emotional regulation skills in relation to excitement. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Through interactive and engaging activities, students will gain a deeper understanding of excitement and develop effective strategies for embracing and managing it.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>• Duration 45 minutes </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by discussing the topic of emotions and their significance in our lives, emphasising that emotions such as excitement can be positive experiences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Transition to the focus of the lesson: understanding excitement and developing strategies for embracing and managing it effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present the level 7 emotion wheel with an emphasis on the relevant quadrant.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to or distribute the Harnessing Exitement workbook to each student.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Defining Excitement (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Engage the students by asking them to share examples of moments when they felt genuinely excited.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a class discussion to collectively define excitement, emphasising positive emotions, anticipation, and the physical sensations that come with it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the level 7 emotion wheel and encourage students to share their experiences and what they enjoy most about feeling excited at the various different levels.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Embracing Excitement: Activity Stations (25 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set up activity stations around the classroom, each focusing on a different aspect of embracing excitement.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Station 1: "Creative Expression"   Provide art supplies and invite students to create a piece of art that represents their excitement.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Station 2: "Physical Energy"   Inflate balloons and play lively music. Instruct students to keep the balloons in the air using various body parts without letting them touch the ground.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Station 3: "Mindfulness and Focus"   Guide students through a short mindfulness exercise, such as the Trace breathing switch encouraging them to fully experience and embrace their excitement in the present moment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Station 4: “Silent disco”   Provide headphones and allow students to listen and dance to various music tracks.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Rotate students through the stations every few minutes using a timer.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Sharing (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students back together as a whole group.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on their experiences at the activity stations and write down one strategy or activity they found most enjoyable or effective for embracing excitement in their workbook.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage volunteers to share their strategies with the class, fostering a positive and supportive atmosphere.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points discussed during the lesson, emphasising the importance of self awareness, embracing excitement, and developing effective strategies for managing it, both dialing it up or down.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students that excitement is a normal and positive emotion, and it can be harnessed to enhance their experiences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to continue exploring and finding joy in their excitement, using the strategies they discovered during the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on the lesson and write down at least one thing they learnt and one thing they will do as a result of this lesson in their workbook.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In addition to checking in via the Switch program, encourage students to make use of the space in their workbook to journal their thoughts and feelings over the coming week to better understand their triggers and improve their management strategies.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>Extension:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students access the Switch4Schools program on their device and encourage them to explore the available switches assigned to excitement on the emotion wheel to bookmark activities they’d like to try.  Alternatively provide a copy of the level 7 quick reference guide. This could also be a homework activity.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Level 7 emotion wheel'} link={"https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/LV7+how+are+you+feeling+today+cut+out.pdf"} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Balloons</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Timer or stopwatch</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Music player and headphones</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Art supplies</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Mastering the mind workbook, including section M8: Harnessing Excitement: Developing Emotional Intelligence'} link={Worksheets.masteryMasteringTheMindLevel7.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Adolescence and Fearful Excitement -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.psychologytoday.com/au/blog/surviving-your-childs-adolescence/201311/adolescence-and-fearful-excitement' link='https://www.psychologytoday.com/au/blog/surviving-your-childs-adolescence/201311/adolescence-and-fearful-excitement' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Getting Excited Helps with Performance Anxiety More Than Trying to Calm Down, Study Finds -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.apa.org/news/press/releases/2013/12/performance-anxiety' link='https://www.apa.org/news/press/releases/2013/12/performance-anxiety' />
                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}