import { View } from 'react-native'
import { DefaultText } from '../../common/default-text'
import { commonCardStyles } from './emotion-card-image-sizes'


interface Props {
    LessonPlanHeading: string
    LessonContent: () => JSX.Element

}

export const LessonPlanContainer = ({ LessonPlanHeading, LessonContent }: Props) => {

    return (
        <View style={commonCardStyles.lessionPlanContainer}>
            <View style={commonCardStyles.lessionPlanHeader}>
                <DefaultText style={commonCardStyles.lessionPlanHeadingText}>
                    {LessonPlanHeading}
                </DefaultText>
            </View>
            <View style={commonCardStyles.lessionInfoContainer}>
                <LessonContent />
            </View>
        </View>
    )

}
