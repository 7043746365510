import moment from 'moment'
import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import SwitchCardVideo from '../../../common/switch-card-video'
import { Worksheets } from '../../../common/worksheets-imports'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { WorryMonsterHeader } from './src/worry-monster-header'
import { WorryMonsterIcon } from './src/worry-monster-icon'
import { WorryMonsterImage1 } from './src/worry-monster-image1'
import { WorryMonsterLessonPlan } from './src/worry-monster-lesson-plan'

const worryMonsterVideo1: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/NYzowu-EaPY",
    createdAt: moment('2022-01-01').toString(),
}

export const SwitchesEmotionsAnxiousWorryMonster: CardTemplate = {
    cardId: "826dc971-dad7-40a0-94ac-fc44f9f2cca3",
    title: 'Worry monster',
    Header: WorryMonsterHeader,
    Icon: WorryMonsterIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"When you don't fully understand what your anxiety is or why it can seem so scary."} />
        </>
    ),
    Images: () => (
        <>
            <WorryMonsterImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Remember a time when you have felt really worried about something. Maybe you feel that way right now."} />
                <DefaulttextWithRightArrow textdata={"Imagine that feeling is your 'worry monster'."} />
                <DefaulttextWithRightArrow textdata={"Now imagine what your monster would look like, maybe it is big, maybe it is scary with sharp teeth."} />
                <DefaulttextWithRightArrow textdata={"Draw your worry monster."} />
                <DefaulttextWithRightArrow textdata={"Add a list of what the worry monster is saying to you.  For example, “You will get lost and be left behind.”"} />
                <DefaulttextWithRightArrow textdata={"Next to the list of what the worry monster tells you, write down new thoughts that puts the monster in it’s place.  For example, next to, “You will get lost and be left behind” write, “I have never been left behind before, because I know how to ask for help.”"} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: `One way the worry monster can assist with anxiety is by providing a way to express and externalise worries. When we are able to talk about worries and name them as the "worry monster," it can make them feel less overwhelming and more manageable. The worry monster can also help with developing coping skills and strategies for managing anxiety, such as deep breathing, positive thinking, and problem-solving. By talking to the worry monster and using these strategies, we can learn to take control of our anxiety and reduce its impact on our lives. The worry monster can also provide a sense of comfort and support, helping us to feel less alone in our struggles with anxiety.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Develop skills in managing anxiety by learning to use the Worry Monster visualisation technique and practicing cognitive reframing to shift their perspective on worries.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 35 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share a time when they felt worried or anxious.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students how they felt in their bodies when they were worried (e.g. tightness in their chest, butterflies in their stomach).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the idea of the worry monster as a way to understand and manage anxiety and worry.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that the worry monster is a way to represent and understand anxiety and worry.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Tell the students that everyone gets worried sometimes, and it is normal to have worries.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to think of a worry they have right now and write it down on their worry monster worksheet. If they don’t have one right now, remember a time when they were worried.</DefaultText>
                        <View style={styles.imageContainer}>
                            <WorryMonsterLessonPlan />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to think about how that worry feels then imagine that feeling is their 'worry monster'.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to imagine what the monster would look like, maybe it is big, maybe it is scary with sharp teeth and gave the students draw their worry monster on their worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to add to the drawing all of the negative self talk their “worry monster” is saying to them. For example: “You will get lost”.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students share their worries with a partner and talk about how they could solve the worry or make themselves feel better (optional) and write their action plan on their worksheets. For example: Beside “You will get lost.” write, “I can ask for directions.”.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their worry monster plan.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students identify a different worry and complete the "My Worry Monster Plan" on the worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to use their worry monster plan when they are feeling anxious or worried.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share one thing they learned about managing anxiety and worry today.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Review the idea that it is normal to have worries and that there are things we can do to manage and reduce our anxiety.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own device, ask them to find the switch in the Switch4Schools app.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• A picture or illustration of a worry monster</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Copies of <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='the worry monster worksheet' link={Worksheets.worryMonster.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pencils</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Beyond Blue, What is anxiety and Anxiety Disorders? -  </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=NYzowu-EaPY&ab_channel=BeyondBlueOfficial' />

                        <DefaultText style={CommonStyles.lessonPlanText}>10 Steps for Parents and Kids to Tame the Worry Monster -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='10 Steps for Parents and Kids to Tame the Worry Monster | HuffPost Life' link='https://www.huffpost.com/entry/10-steps-for-parent-and-kids-to-taming-the-worry-monster_b_4345171' />

                        <SwitchCardVideo video={worryMonsterVideo1} />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata="Take your time to consider what your monster looks like for you." />
            <DefaulTextWithTipsIcon textdata="Listing your worries and fears removes the monster’s power and stops it from being able to bully you." />
            <DefaulTextWithTipsIcon textdata="Remember that you are bigger than it and can tell it what to do." />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Worry+Monster+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought, CardActionType.Action],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Anxious]
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
})
