import { View } from 'react-native'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { TruthOrLieIcon } from '../../icons/truth-or-lie-icon'
import { DefaultTextWithExtraTipIcon } from '../../../common/default-text-with-extra-tip-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { TruthOrLieIconInverted } from '../../icons/truth-or-lie-icon-inverted'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'

export const SwitchesActivitiesRemoteClassesTruthOrLie: CardTemplate = {
    cardId: "67291758-98dc-41c7-9a18-ecd25cade2e7",
    title: 'Truth or lie?',
    Header: TruthOrLieIconInverted,
    Icon: TruthOrLieIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Getting to know people, and stimulating active listening skills."} />
            <DefaulttextWithRightArrow textdata={"Have everyone in a shared space write 4 statements, three that are true and one that is a lie."} />
            <DefaulttextWithRightArrow textdata={"Randomly select different people to read their four statements."} />
            <DefaulttextWithRightArrow textdata={"Have people in the chat channel call out which one they think isn’t true. Every correct answer gets a point."} />
            <DefaulttextWithRightArrow textdata={"Game ends with the first person to reach an agreed points total (usually between 5-10)."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"Activity could be easily facilitated online through the use of an online poll."} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.FOR_REMOTE_CLASSES,
    whyDoesItWorkText: '',
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.FOR_REMOTE_CLASSES],
    action: [CardActionType.Action],
    level: []
}

