import { Activities } from '../../common/activities'
import { ActivityGroupTemplate } from '../../common/activity-group-template'
import { ActivitySheetsIcon } from '../icons/activity-sheets-icon'
import { CreativityIcon } from '../icons/creativity-icon'

export const SwitchesActivityGroupActivitySheets: ActivityGroupTemplate = {
    title: 'Activity sheets',
    Icon: ActivitySheetsIcon,
    activity: Activities.ACTIVITY_SHEETS,
    subtitle: '',
    navigateCardId: "df8a391c-b7fb-43e3-a8f7-2ba4fced76be"
}
