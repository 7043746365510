import { OnDataOptions, gql } from "@apollo/client";
import { OnMutateClassGroupsBySchoolIDSubscription } from "../../common/API";
import { GetSchoolCampusesAndGroupsQueryResponse, getSchoolCampusesAndGroupsQuery } from "../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query";

export const updateListClassGroupsInSchoolQuery = ({ schoolID }: { schoolID: string }) => ({ data, client }: OnDataOptions<OnMutateClassGroupsBySchoolIDSubscription>) => {
    if (!data.data?.onMutateClassGroupsBySchoolID) return;

    const newData = data.data.onMutateClassGroupsBySchoolID;

    const schoolQueryResponse = client.readQuery<GetSchoolCampusesAndGroupsQueryResponse>({
        query: getSchoolCampusesAndGroupsQuery,
        variables: { id: schoolID }
    });

    const campusToUpdate = schoolQueryResponse?.getSchool?.campuses?.items.find(campus => campus?.id === newData?.campusID);

    if (!campusToUpdate) return;

    const classGroups = campusToUpdate?.classGroups?.items || [];

    if (classGroups.some(classGroup => classGroup?.id === newData?.id)) return;

    const unmodifiedCampuses = schoolQueryResponse?.getSchool?.campuses?.items.filter(campus => campus?.id !== campusToUpdate?.id);

    const updatedClassGroups = [...classGroups, newData];

    const updatedCampus = {
        ...campusToUpdate,
        classGroups: { items: updatedClassGroups }
    };

    client.writeQuery({
        query: getSchoolCampusesAndGroupsQuery,
        variables: { id: schoolID || "" },
        data: {
            ...schoolQueryResponse,
            getSchool: {
                ...schoolQueryResponse?.getSchool,
                campuses: {
                    ...schoolQueryResponse?.getSchool.campuses,
                    items: [...unmodifiedCampuses || [], updatedCampus]
                }
            }
        }
    });
};
