import { useState } from 'react'
import { TextInput } from 'react-native'
import { Auth } from 'aws-amplify'
import { useNavigation } from '@react-navigation/core'
import { useReactiveVar } from '@apollo/client'
import { PasswordHintText } from './password-hint-text'
import { DefaultButton } from '../common/default-button'
import { CommonStyles } from '../common/const'
import { Page } from '../common/page'
import { RootViewBackButton } from '../common/root-view-back-button'
import { rvChangePasswordUsername, rvUserGroup } from './login-state'
import { ScreenNames } from '../common/screen-names'
import { StackNavigationProp } from '@react-navigation/stack'
import { LoginStackNavigatorParamList } from './login-route-param-types'
import { showAlert } from '../common/universal-alert'
import { customCognitoErrorMessages } from './custom-cognito-error-messages'

export const ForgotPasswordRequest = () => {
    const { navigate, goBack } = useNavigation<StackNavigationProp<LoginStackNavigatorParamList>>()
    const userGroup = useReactiveVar(rvUserGroup)

    const navigateBack = () => {
        if (userGroup) {
            goBack()
            return
        }
        navigate(ScreenNames.Login)
    }

    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const changePasswordUsername = useReactiveVar(rvChangePasswordUsername)

    const resetPasswordRequest = () => {
        try {
            setDisableSubmitButton(true)
            Auth.forgotPassword(changePasswordUsername)
                .then(() => {
                    navigate(ScreenNames.LoginForgotPasswordSubmit)
                    setDisableSubmitButton(false)
                })
                .catch((e) => {
                    const response = customCognitoErrorMessages(e)
                    showAlert({
                        title: response?.title || "An error has occured",
                        message: response?.message || String(e),
                        leftButtonText: 'Ok',
                    })
                    setDisableSubmitButton(false)
                })
        }
        catch (e: any) {

        }

    }

    return (
        <RootViewBackButton onPress={navigateBack}>
            <Page>
                <PasswordHintText>A verification code will be send to you once you submitted your email.</PasswordHintText>
                <TextInput
                    style={CommonStyles.textInput}
                    placeholder="email/nickname"
                    autoCorrect={false}
                    autoComplete='username'
                    autoCapitalize='none'
                    keyboardType='email-address'
                    onChangeText={rvChangePasswordUsername}
                    textContentType='username'
                />
                <DefaultButton disabled={changePasswordUsername === '' || disableSubmitButton} onPress={resetPasswordRequest}>Submit</DefaultButton>
            </Page>
        </RootViewBackButton>
    )
}
