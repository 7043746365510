import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan, Line } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconExcited7Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 7)
    }

    const onPressLevel2 = {
        [prop]: () => onPress(2 / 7)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(3 / 7)
    }

    const onPressLevel4 = {
        [prop]: () => onPress(4 / 7)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(5 / 7)
    }

    const onPressLevel6 = {
        [prop]: () => onPress(6 / 7)
    }

    const onPressLevel7 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg
            viewBox="0 0 277.135 153.374"
        >
            <G data-name="Group 9558">
                <G data-name="Group expectant" {...onPressLevel1}>
                    <Path
                        data-name="Path 7702"
                        d="M0 0l52.6-.176.043-28.007z"
                        transform="translate(-559 -109.468) translate(559.711 262.717)"
                        fill="#e0eeeb"
                    />
                    <Path
                        data-name="Path 7703"
                        d="M559.711 262.717l52.641-28.183-.041 28.007z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7737"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h31.357a2.835 2.835 0 002.835-2.834V2.835A2.836 2.836 0 0031.357 0z"
                        transform="translate(-559 -109.468) translate(577.951 252.447)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7738"
                        d="M577.951 252.447a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h31.357a2.835 2.835 0 002.835-2.834v-3.631a2.836 2.836 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#378f7b"
                        strokeWidth={0.25}
                        transform="translate(-559 -109.468)"
                    />
                    <Text
                        transform="translate(-559 -109.468) translate(577.729 259.377)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Expectant"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7743"
                        d="M559.236 258.728l47.913-25.76"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7745"
                        d="M0 0l-7.385 1.073 2.417 4.495z"
                        transform="translate(-559 -109.468) translate(611.961 230.381)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group inspired" {...onPressLevel2}>
                    <Path
                        data-name="Path 7705"
                        d="M0 0l-.112 29.327 34.82-.022-.126-47.734z"
                        transform="translate(-559 -109.468) translate(614.921 233.174)"
                        fill="#c3ddd7"
                    />
                    <Path
                        data-name="Path 7706"
                        d="M614.921 233.175l34.583-18.429.125 47.734-34.819.022z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7722"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h22.351a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0022.351 0z"
                        transform="translate(-559 -109.468) translate(621.044 239.629)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7723"
                        d="M621.044 239.629a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h22.351a2.834 2.834 0 002.834-2.834v-3.631a2.835 2.835 0 00-2.834-2.835z"
                        fill="none"
                        stroke="#378f7b"
                        strokeWidth={0.25}
                        transform="translate(-559 -109.468)"
                    />
                    <Text
                        transform="translate(-559 -109.468) translate(619.059 247.263)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Inspired"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7746"
                        d="M614.229 229.247l30.053-16.126"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7747"
                        d="M0 0l-7.385 1.066 2.413 4.5z"
                        transform="translate(-559 -109.468) translate(649.095 210.539)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group eager" {...onPressLevel3}>
                    <Path
                        data-name="Path 7708"
                        d="M0 0l.177 49.336 34.423-.022.043-68.237z"
                        transform="translate(-559 -109.468) translate(652.256 213.268)"
                        fill="#a4ccc4"
                    />
                    <Path
                        data-name="Path 7709"
                        d="M652.256 213.268l34.649-18.923-.043 68.236-34.428.023z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7726"
                        d="M660.713 226.822a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h15.948a2.834 2.834 0 002.834-2.834v-3.631a2.835 2.835 0 00-2.834-2.835z"
                        fill="none"
                        stroke="#378f7b"
                        strokeWidth={0.25}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7725"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h15.948a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0015.948 0z"
                        transform="translate(-559 -109.468) translate(660.713 226.822)"
                        fill="#fff"
                    />
                    <Text
                        transform="translate(-559 -109.468) translate(659.172 234.175)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Eager"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7748"
                        d="M651.646 209.405l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7749"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-559 -109.468) translate(686.749 190.256)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group energetic" {...onPressLevel4}>
                    <Path
                        data-name="Path 7711"
                        d="M0 0l.05 69.611 34.82-.022-.082-87.9z"
                        transform="translate(-559 -109.468) translate(689.311 192.992)"
                        fill="#86bbb0"
                    />
                    <Path
                        data-name="Path 7712"
                        d="M689.311 192.992l34.787-18.315.083 87.9-34.819.026z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7740"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h27.095a2.834 2.834 0 002.835-2.837V2.835A2.835 2.835 0 0027.095 0z"
                        transform="translate(-559 -109.468) translate(693.251 214.016)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7741"
                        d="M693.251 214.016a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h27.095a2.834 2.834 0 002.835-2.837v-3.628a2.835 2.835 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#378f7b"
                        strokeWidth={0.25}
                        transform="translate(-559 -109.468)"
                    />
                    <Text
                        transform="translate(-559 -109.468) translate(691.659 221.534)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Energetic"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7750"
                        d="M688.496 188.995l30.307-16.533"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7751"
                        d="M0 0l-7.378 1.117L-4.934 5.6z"
                        transform="translate(-559 -109.468) translate(723.6 169.847)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group passionate" {...onPressLevel5}>
                    <Path
                        data-name="Path 7714"
                        d="M0 0l-.076 89.5 34.428-.022-.082-107.69z"
                        transform="translate(-559 -109.468) translate(727.104 173.1)"
                        fill="#69ab9c"
                    />
                    <Path
                        data-name="Path 7715"
                        d="M727.104 173.099l34.27-18.212.082 107.694-34.428.018z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7731"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h32.246a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0032.246 0z"
                        transform="translate(-559 -109.468) translate(728.092 201.209)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7732"
                        d="M728.092 201.209a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h32.246a2.834 2.834 0 002.835-2.834v-3.631a2.835 2.835 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#378f7b"
                        strokeWidth={0.25}
                        transform="translate(-559 -109.468)"
                    />
                    <Text
                        transform="translate(-559 -109.468) translate(726.615 208.16)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Passionate"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7752"
                        d="M725.914 169.847l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7753"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-559 -109.468) translate(761.017 150.697)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group pumped" {...onPressLevel6}>
                    <Path
                        data-name="Path 7717"
                        d="M0 0l.051 109.625 34.819-.035-.082-128.582z"
                        transform="translate(-559 -109.468) translate(763.808 152.979)"
                        fill="#549f8f"
                    />
                    <Path
                        data-name="Path 7718"
                        d="M763.808 152.979l34.788-18.992.082 128.582-34.819.035z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7734"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h26.666A2.834 2.834 0 0029.5 6.463V2.835A2.835 2.835 0 0026.666 0z"
                        transform="translate(-559 -109.468) translate(767.91 188.401)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7735"
                        d="M767.91 188.401a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h26.666a2.834 2.834 0 002.834-2.837v-3.628a2.835 2.835 0 00-2.834-2.835z"
                        fill="none"
                        stroke="#378f7b"
                        strokeWidth={0.25}
                        transform="translate(-559 -109.468)"
                    />
                    <Text
                        transform="translate(-559 -109.468) translate(767.813 195.762)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Pumped"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7754"
                        d="M763.048 149.027l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7755"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-559 -109.468) translate(798.15 129.878)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group hyper" {...onPressLevel7}>
                    <Path
                        data-name="Path 7720"
                        d="M0 0l-.076 130.034L34.352 130l-.38-147.858z"
                        transform="translate(-559 -109.468) translate(801.657 132.569)"
                        fill="#378f7b"
                    />
                    <Path
                        data-name="Path 7721"
                        d="M801.657 132.569l33.973-17.858.379 147.858-34.428.035z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7728"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h16.521a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0016.521 0z"
                        transform="translate(-559 -109.468) translate(810.535 175.595)"
                        fill="#fff"
                    />
                    <Text
                        transform="translate(-559 -109.468) translate(809.008 183.193)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Hyper"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7756"
                        d="M801.032 128.617l30.307-16.533"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7729"
                        d="M810.535 175.595a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h16.521a2.834 2.834 0 002.834-2.834v-3.631a2.835 2.835 0 00-2.834-2.835z"
                        fill="none"
                        stroke="#378f7b"
                        strokeWidth={0.25}
                        transform="translate(-559 -109.468)"
                    />
                    <Path
                        data-name="Path 7757"
                        d="M0 0l-7.377 1.119L-4.934 5.6 0 0z"
                        transform="translate(-559 -109.468) translate(836.135 109.468)"
                        fill="#6f7175"
                    />
                </G>
            </G>
        </Svg>
    )
}