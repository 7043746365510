import { View, StyleSheet, StyleProp, TextStyle } from "react-native";
import { DefaultText } from "../../common/default-text";
import { rvCard } from "./card-color-scheme";
import { useReactiveVar } from "@apollo/client";
import { Octicons } from '@expo/vector-icons';

interface Props {
    textdata: string
    style?: StyleProp<TextStyle>
    Links?: () => JSX.Element


}


export const DefaultTextWithIndentation = ({ textdata, Links }: Props) => {
    const color = useReactiveVar(rvCard)?.colorScheme
    return (
        <View style={styles.content}>
            <View style={styles.containingView}>
                <View style={styles.icon}>
                    <View style={styles.extraTipsIcon}>
                        <Octicons name="dot" size={20} color={color} />
                    </View>
                </View>
                {Links ? <Links /> : null}
                <DefaultText style={styles.text}>
                    {textdata}
                </DefaultText>
            </View>

        </View>
    )

}



const styles = StyleSheet.create({

    icon: {
    },
    content: {
        justifyContent: 'center',
        marginVertical: 15,
        marginLeft: 40,
        marginRight: 10,

    },
    text: {
        textAlign: 'auto',

    },
    extraTipsIcon: {
        marginHorizontal: 10,

    },
    containingView: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    }
})

