import _ from "lodash"
import { Emotion } from "../../common/API"
import { IntensityTier, SuggestionsParam } from "./nudges-types"
import { nudgesCheckIsSkipSuggestionDates } from "./nudges-check-is-skip-suggestion-dates"
import { nudgesGetStudentCheckInsWithConversionsToMaxMedMin } from "./nudges-get-check-ins-with-conversions-to-max-med-min"

export const negativeEmotions = ["ANGRY", "ANXIOUS", "SAD", "SCARED"]

export const nudgesGetConsecutiveEmotion = (allStudentCheckinEmotions: SuggestionsParam[], intensityLevel: number, checkInCount?: number | null, checkInSkipSuggestionDate?: string | null): Emotion | null => {
    if (intensityLevel === 1 || !checkInCount || nudgesCheckIsSkipSuggestionDates(checkInSkipSuggestionDate)) {
        return null
    }

    const last3AllStudentCheckinEmotions = nudgesGetStudentCheckInsWithConversionsToMaxMedMin(allStudentCheckinEmotions.slice(-3), intensityLevel)

    const isEmotionsUnique = _.uniqBy(last3AllStudentCheckinEmotions, "emotion").length === last3AllStudentCheckinEmotions.length
    const isAllNegativeEmotions = last3AllStudentCheckinEmotions.every((value) => value && negativeEmotions.includes(value.emotion || ""))
    const duplicatesEmotion = _.map(last3AllStudentCheckinEmotions, (v) => v && v.emotion).filter((item, _index, arr) => (arr.lastIndexOf(item) !== arr.indexOf(item)))
    const randomEmotion = _.sample(last3AllStudentCheckinEmotions)
    const lastEmotion = _.last(last3AllStudentCheckinEmotions)

    const highStright = last3AllStudentCheckinEmotions.length && isEmotionsUnique && isAllNegativeEmotions && last3AllStudentCheckinEmotions.every(value => value.emotionIntensityTier === IntensityTier.Max)
    const highCard = last3AllStudentCheckinEmotions.length && lastEmotion && lastEmotion.emotionIntensityTier === IntensityTier.Max
    const lowStraight = last3AllStudentCheckinEmotions.length && isEmotionsUnique && isAllNegativeEmotions && _.filter(last3AllStudentCheckinEmotions, (data) => data.emotionIntensityTier !== IntensityTier.Min).length >= 2
    const threeOfAkind = last3AllStudentCheckinEmotions.length && last3AllStudentCheckinEmotions.every(value => value.emotion === last3AllStudentCheckinEmotions[0].emotion)
    const twoOfAkind1stCase = negativeEmotions.includes(lastEmotion?.emotion || "") && !negativeEmotions.includes(last3AllStudentCheckinEmotions[0]?.emotion || "") && !negativeEmotions.includes(last3AllStudentCheckinEmotions[1]?.emotion || "")
    const twoOfAKind = !twoOfAkind1stCase && negativeEmotions.includes(duplicatesEmotion[0] || "") && last3AllStudentCheckinEmotions.some((value) => value.emotionIntensityTier > Number(lastEmotion?.emotionIntensityTier))

    if (highStright && randomEmotion) {
        return randomEmotion.emotion
    }

    if (highCard) {
        return lastEmotion.emotion
    }

    if (lowStraight && randomEmotion) {
        return randomEmotion.emotion
    }

    if (threeOfAkind) {
        return last3AllStudentCheckinEmotions[0].emotion
    }

    if (twoOfAKind) {
        return duplicatesEmotion[0]
    }

    if (lastEmotion) {
        return lastEmotion.emotion
    }

    return null
}
