import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { NavigationProp, useNavigation, useRoute } from '@react-navigation/native';
import { useEffect, useRef } from 'react';
import { GestureResponderEvent, StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';
import { CheckInSession, ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { listStudentCheckInsByCheckInSessionId } from '../../common/graphql/queries';
import { convertStudentCheckInCacheCodeToCacheObject } from '../../common/student-check-in-cache';
import { studentCheckInHasFlag } from '../../common/student-check-in-has-flag';
import { AdminClassNavigatorParamList } from '../admin/admin-route-param-types';
import { DefaultSecondaryFontSize } from '../common/const';
import { DefaultText } from '../common/default-text';
import { rvPopoverRequestChatStudentID } from '../common/pop-over-menu-state';
import { ScreenNames } from '../common/screen-names';
import StudentCheckInSummary from '../common/student-check-in-summary';
import useOutsideClick from '../common/use-click-outside';
import { rvUserGroup } from '../login/login-state';
import { AVATAR_CONTAINER_PADDING, AVATAR_SIZE, AVATAR_WRAPPER_WIDTH } from './teacher-consts';
import { StudentClassAndStudentDataFieldsTypes } from './teacher-graphql-scripts';
import { TeacherDrawerNavigatorPamarList } from './teacher-route-param-types';
import { StudentAndStudentClassAndStudentCheckIn } from './teacher-stats-last-period-and-requested-chat';

type Props = {
    studentClass: StudentClassAndStudentDataFieldsTypes | null,
    checkInSessions?: CheckInSession[]
    callOnAvatarPress?: (studentAndStudentCheckInData: StudentAndStudentClassAndStudentCheckIn) => void
    isGoMode: boolean
}

const TeacherStatusStudentAvatarCheckInStatus = ({ studentClass, checkInSessions, callOnAvatarPress, isGoMode }: Props) => {

    if (!studentClass) return null

    const route = useRoute()
    const userGroup = useReactiveVar(rvUserGroup)
    const { navigate } = useNavigation<NavigationProp<TeacherDrawerNavigatorPamarList & AdminClassNavigatorParamList, "TeacherHome" | "TeacherStudentIndividualStats">>()
    const isDashboard = route.name === ScreenNames.TeacherHome

    const selectedPopoverStudentID = useReactiveVar(rvPopoverRequestChatStudentID)
    const popoverShow = selectedPopoverStudentID === studentClass?.studentID

    const popupRef = useRef<View>(null)

    useEffect(() => {
        return () => {
            rvPopoverRequestChatStudentID(null)
        }
    }, [])

    const closePopupOnclickOutside = () => {
        if (!popoverShow) return

        rvPopoverRequestChatStudentID(null)
    }

    useOutsideClick({ ref: popupRef, handler: closePopupOnclickOutside })

    const currentStudent = studentClass.student

    const lastCheckInSession = (checkInSessions && checkInSessions?.length) && checkInSessions[0]

    const { data: studentCheckInResponse } = useQuery<ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables>(gql`${listStudentCheckInsByCheckInSessionId}`, { variables: { checkinsessionID: lastCheckInSession ? lastCheckInSession?.id : "", limit: 5000 }, skip: !lastCheckInSession })
    const currentStudentCheckIn = isGoMode ?
        studentClass.studentCheckIns && studentClass.studentCheckIns.length > 0 && convertStudentCheckInCacheCodeToCacheObject(studentClass.studentCheckIns[studentClass.studentCheckIns.length - 1]) || undefined :
        studentCheckInResponse?.listStudentCheckInsByCheckInSessionId?.items.find((studentCheckIn) => studentCheckIn?.studentID === studentClass.studentID)

    const handleAvatarPress = () => {
        if (userGroup === "Admin") {
            navigate(ScreenNames.TeacherStudentIndividualStats, { classID: studentClass.classID, studentID: studentClass.studentID })
            return
        }

        if (callOnAvatarPress && currentStudent) {
            rvPopoverRequestChatStudentID(null)
            callOnAvatarPress({
                student: {
                    studentClass,
                    student: currentStudent,
                },
                studentCheckIn: currentStudentCheckIn || undefined
            })
        }
    }

    const selectedStudentAvatar = `${studentClass.studentID}-${studentClass.classID}`

    const toggleAvatarPopOverMenu = () => {
        if (selectedPopoverStudentID === selectedStudentAvatar) {
            rvPopoverRequestChatStudentID(null)
            return
        }

        rvPopoverRequestChatStudentID(selectedStudentAvatar)
    }

    const navigateToIndividualStats = (e: GestureResponderEvent) => {
        e.preventDefault()

        if (userGroup === "Admin") {
            navigate(ScreenNames.TeacherStudentIndividualStats, { classID: studentClass.classID, studentID: studentClass.studentID })
            return
        }

        navigate(ScreenNames.TeacherClassMain, { screen: ScreenNames.TeacherStudentIndividualStats, params: { classID: studentClass.classID, studentID: studentClass.studentID } })
        rvPopoverRequestChatStudentID(null)
    }

    const handleClearRequestChat = (e: GestureResponderEvent) => {
        e.preventDefault()
        handleAvatarPress()
    }

    return (
        <View
            key={studentClass.studentID}
            style={{ zIndex: popoverShow ? 99 : undefined }}
        >
            <StudentCheckInSummary
                isGoMode={isGoMode}
                studentClass={studentClass}
                toggleAvatarPopOverMenu={toggleAvatarPopOverMenu}
                checkInSessions={checkInSessions}
            />
            {selectedPopoverStudentID === selectedStudentAvatar && (
                <View ref={popupRef} style={styles.popoverContainer}>
                    <TouchableOpacity onPress={navigateToIndividualStats}>
                        <DefaultText style={styles.popoverText}>Individual stats</DefaultText>
                    </TouchableOpacity>
                    {currentStudentCheckIn && (currentStudentCheckIn?.requestForChat || studentCheckInHasFlag(currentStudentCheckIn)) && currentStudentCheckIn?.chatCleared !== true && (
                        <TouchableOpacity onPress={handleClearRequestChat}>
                            <DefaultText style={styles.popoverText}>Clear alert/s</DefaultText>
                        </TouchableOpacity>
                    )}
                </View>
            )}
        </View>
    )
}
export default TeacherStatusStudentAvatarCheckInStatus


const styles = StyleSheet.create({
    popoverContainer: {
        backgroundColor: DecidaColors.White,
        width: 150,
        position: 'absolute',
        borderWidth: 0.5,
        borderColor: DecidaColors.Gray,
        padding: 10,
        left: 30,
        borderRadius: 5,
        zIndex: 99,
        top: AVATAR_SIZE,
        gap: 10
    },
    popoverText: {
        fontSize: 14,
    },
    avatarContainer: {
        marginTop: AVATAR_CONTAINER_PADDING,
        paddingHorizontal: AVATAR_CONTAINER_PADDING,
        width: AVATAR_WRAPPER_WIDTH,
        marginBottom: 30,
    },
    avatarAndStatContainer: {
        flexDirection: "row",
        width: '100%'
    },
    avatar: {
        marginTop: 5,
        width: AVATAR_SIZE,
        height: AVATAR_SIZE,
        justifyContent: "flex-start",
        alignItems: "center",
    },
    avatarflag: {
        position: "absolute",
        right: 30,
        top: 0,
        zIndex: 1,
    },
    avatarAbsence: {
        position: 'absolute',
        right: 15,
        bottom: 30
    },
    avatarRequestChat: {
        position: 'absolute',
        left: 10,
        top: 0
    },
    nickname: {
        width: AVATAR_WRAPPER_WIDTH - AVATAR_CONTAINER_PADDING,
        textAlign: "center",
        fontSize: 15,
        marginTop: 5
    } as TextStyle,
    name: {
        color: DecidaColors.Gray,
        fontSize: DefaultSecondaryFontSize - 2,
        textAlign: 'center',
    },
    date: {
        textAlign: "center",
        fontSize: 12,
    }
});