import Svg, { G, Path } from 'react-native-svg'
import { DecidaColors } from "../../common/decida-colors"

interface Props {
  stylefill?: string,
}

export const IconEdit = ({ stylefill = DecidaColors.Green }: Props) =>
  <Svg viewBox="0 0 43.231 43.123">
    <G transform="translate(0.25 0.25)">
      <Path d="M6264.97,1684.842l-13.868,13.865a7.942,7.942,0,0,1-5.653,2.344h-.913v-.913a7.923,7.923,0,0,1,2.34-5.653l13.869-13.874a2.991,2.991,0,1,1,4.224,4.222Zm-2.112-10.429a8.263,8.263,0,0,0-5.883,2.438l-13.866,13.865a13.239,13.239,0,0,0-3.9,9.422v6.241h6.241a13.243,13.243,0,0,0,9.416-3.9l13.873-13.873a8.318,8.318,0,0,0-5.88-14.191Z" transform="translate(-6228.552 -1674.412)" fill={stylefill} stroke="#fff" strokeWidth="0.5" />
      <Path d="M6231.2,1718.371h23.975a7.991,7.991,0,0,0,7.991-7.992v-10.656h-5.326v10.656a2.665,2.665,0,0,1-2.665,2.664H6231.2a2.661,2.661,0,0,1-2.663-2.664V1686.4a2.661,2.661,0,0,1,2.663-2.664h10.655v-5.328H6231.2a7.991,7.991,0,0,0-7.993,7.992v23.975A7.99,7.99,0,0,0,6231.2,1718.371Z" transform="translate(-6223.207 -1675.748)" fill={stylefill} stroke="#fff" strokeWidth="0.5" />
    </G>
  </Svg>
