import { MutableRefObject } from 'react'
import { Platform } from 'react-native'

const useOutsideClick = ({ ref, handler, mouseEvent = 'mousedown' }: {
    ref: MutableRefObject<any>,
    handler: () => void,
    mouseEvent?: 'mousedown' | 'mouseup',
}) => {

    const elementRef = ref.current

    if (Platform.OS === 'web' && document) {
        // listen to outside click
        document.addEventListener(mouseEvent, (event) => {
            if (!elementRef || elementRef?.contains(event.target)) {
                return
            }

            handler()
        })
    }


}
export default useOutsideClick