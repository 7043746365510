import { useReactiveVar } from '@apollo/client'
import { Pressable, StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from '../common/default-text'
import { GroupIcon } from '../common/svg-files/group-icon'
import { rvSchoolAdminCampusCurrentListView, rvSidebarClassID } from './school-admin-state'
import { SchoolAdminHierarchyCurrentListView } from './school-admin-types'

type Props = {
    group: { id: SchoolAdminHierarchyCurrentListView, name: string }
}
const SchoolAdmiGroupsListItem = ({ group }: Props) => {

    const currentSchoolAdminListView = useReactiveVar(rvSchoolAdminCampusCurrentListView)
    const isSelected = currentSchoolAdminListView === group.id

    const handlePress = () => {
        rvSidebarClassID("")
        rvSchoolAdminCampusCurrentListView(group.id)
    }
    
    return (
        <Pressable onPress={handlePress} style={isSelected ? styles.selectedContainer : styles.container}>
            <View style={{ width: 30, height: 30, marginRight: 5 }}>
                <GroupIcon />
            </View>
            <DefaultText>{group.name}</DefaultText>
        </Pressable>
    )
}
export default SchoolAdmiGroupsListItem


const styles = StyleSheet.create({
    container: {
        height: 60,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 20,
    },
    selectedContainer: {
        height: 60,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 20,
        backgroundColor: DecidaColors.AppleSystemGray6SuperLight,
        boxShadow: 'inset 3px 12px 36px -25px rgba(0, 0, 0, 0.5)', // Inner shadow
    },
})