import { StyleSheet } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

export const SwitchesLessonPlansIntroducingAngry: CardTemplate = {
    cardId: "a9566907-a1c4-4f0d-8dfe-ed0882f3f4c5",
    title: 'Introducing angry',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Deepen their understanding of the emotion "angry" by identifying its characteristics, expressing what anger looks and feels like, and exploring strategies for managing and expressing anger in healthy ways.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45-60 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Review of Emotions (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by briefly reviewing the emotions learned in the previous lessons, including "angry."</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to recall what they remember about the emotion "angry" and share examples of when they have felt angry.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Characteristics of Anger (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play “What is anger” video [6:00].</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set up a large chart paper on an easel or wall.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write "Anger" at the top of the chart and invite the students to brainstorm words or phrases that describe what anger looks and feels like.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write their responses on the chart paper, encouraging them to explain their choices and expand on the ideas shared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss common characteristics, such as feeling hot, tense muscles, or wanting to shout or hit something.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Identifying Anger Triggers (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to describe situations that might make someone feel angry.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss their responses and encourage them to share personal experiences or observations of others feeling angry in similar situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that feeling angry is a normal emotion, but it is important to respond to it in a safe and healthy way.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Expressing Anger (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite the students to express what anger looks like to them by drawing a picture or using art supplies to create a visual representation of anger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to use colours, lines, and shapes that they associate with feeling angry.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide an opportunity for volunteers to share their artwork and describe what they depicted, emphasising that there are different ways anger can be expressed.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Strategies for Managing Anger (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce simple strategies for managing and expressing anger in healthy ways, such as taking deep breaths, counting to ten, or using words to express how they feel.  Refer to the available switches for anger for inspiration.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss each strategy and encourage the students to share any strategies they personally use when they feel angry.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Practice a simple deep breathing exercise together as a group (recommend the 10 Breaths switch), inhaling deeply through the nose and exhaling slowly through the mouth.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that it is important to find ways to calm down and communicate their feelings without hurting themselves or others.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflecting on Anger (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students back in the circle.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to reflect on what they learned about anger and how it feels to be angry.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to share their artwork or describe a situation that makes them feel angry.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss strategies for managing anger and remind them that they have the power to choose how they respond when they feel angry.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>Extension Activities:</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Read books or tell stories about anger and discuss the strategies used by the characters to manage their anger.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Role-play scenarios where students can practice expressing their anger in healthy ways, such as using "I" statements.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Complete lessons for the available ‘anger’ switches.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='What is anger video' link='https://youtu.be/clwt7iXF1Mg' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Large chart paper and markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Art supplies (crayons, coloured pencils, markers)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Blank paper or drawing sheets.</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Quick Ways to Help Kids Express Their Anger -</DefaultText>
                        <DefaultTextWithLink text='https://copingskillsforkids.com/managing-anger' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Anger Management for Kids: 14 Best Activities & Worksheets -</DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/anger-management-kids/' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}
