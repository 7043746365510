export namespace ScreenNamesPublic {
    export const SwitchesHome = "SwitchesHome";
    export const SwitchesExploreAll = "SwitchesExploreAll";
    export const Card = "Card";
    export const SwitchesMemes = "SwitchesMemes";
    export const SwitchesEmotionsDetailView = "SwitchesEmotionsDetailView";
    export const SwitchesActiviesDisplayAll = "SwitchesActiviesDisplayAll";
    export const SwitchesActiviesDetailView = "SwitchesActiviesDetailView";
    export const SwitchesToolsDisplayAll = "SwitchesToolsDisplayAll";
    export const SwitchesExploreAllMain = "SwitchesExploreAllMain";
    export const SwitchesToolsMain = "SwitchesToolsMain";
    export const SwitchesHomeMain = "SwitchesHomeMain";
    export const SwitchesActivityMain = "SwitchesActivityMain";
}

