import { View, Text } from 'react-native'
import { CardTemplate } from '../../common/card-template'
import { CardColorScheme, rvCard } from '../../common/card-color-scheme'
import { SongLinesIcon } from '../../explore/src/song-lines-icon'
import { SongLinesImage1 } from './src/song-lines-image1'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import { DefaultTextWithExtraTipIcon } from '../../common/default-text-with-extra-tip-icon'
import { useReactiveVar } from '@apollo/client'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { SongLinesIconInverted } from '../../explore/src/song-lines-icon-inverted'


export const SwitchesEmotionsHappySongLines: CardTemplate = {
    cardId: "45d2e431-b2bc-4d09-8621-d3d93ac319e8",
    title: 'Song lines',
    Header: SongLinesIconInverted,
    Icon: SongLinesIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>
    ),
    Images: () => (
        <>
            <SongLinesImage1 />
        </>
    ),
    CardContent: () => {

        const color = useReactiveVar(rvCard)?.colorScheme

        return (
            <>
                <View style={commonCardStyles.container}>
                    <DefaulttextWithRightArrow textdata={"In groups of three to five, pick a theme for a song."} />
                    <DefaulttextWithRightArrow textdata={"When thinking of a theme it can be handy to include"} />
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', paddingTop: 20, }}>
                        <Text style={{ color: color, fontSize: 28, fontWeight: 'bold', }}>A)</Text>
                        <Text style={{ fontSize: 20, }}>Song Style (Love song, country, opera, hard rock)</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }}>
                        <Text style={{ color: color, fontSize: 28, fontWeight: 'bold' }}>B)</Text>
                        <Text style={{ fontSize: 20, }}>Song setting or key characters</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', paddingBottom: 20, }}>
                        <Text style={{ color: color, fontSize: 28, fontWeight: 'bold' }}>C)</Text>
                        <Text style={{ fontSize: 20, }}>A couple of random objects to be referenced</Text>
                    </View>
                    <DefaulttextWithRightArrow textdata={"Ensure that every second line rhymes"} />
                    <DefaulttextWithRightArrow textdata={"Each line must build on the previous one and must not immediately squash an idea of the previous sentence."} />
                </View>
            </>
        )
    },
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: 'Listening and building on other`s ideas is a great way of practicing active listening skills and increasing group connectivity. Active listening is a key element of empathy training and group collaboration as it relies on us hearing and understanding someone else’s thoughts and then creatively adding to that thought process. This activity has also been shown to build trust and connection with a group.',
    ExtraTips: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithExtraTipIcon textdata={"Set a time limit for how long the next person has to contribute to the story.  If the time limit is exceeded by any individual the group should start a new story."} />
                <DefaultTextWithExtraTipIcon textdata={"Can also be a good activity while travelling or waiting as a group."} />
                <DefaultTextWithExtraTipIcon textdata={"‘Blocking’ is what to call the act of someone immediately shutting down a new idea that was offered by the previous story teller. Other players should call out “Blocking!” to reinforce the correct behaviour."} />
            </View>
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
}
