import { ReactNode, useMemo } from 'react';
import { StyleSheet, TextInput, TextInputProps, View, ViewStyle } from 'react-native';
import InputDefault from './input-default';
import { DecidaColors } from '../../common/decida-colors';

type DefaultTextInputWithIconProps = {
    Icon: () => ReactNode;
    value: string
    onChangeText: (value: string) => void
    position?: 'left' | 'right';
    containerStyle?: ViewStyle;
    inputStyle?: ViewStyle;
    placeholder?: string
} & TextInputProps;

const DefaultTextInputWithIcon = ({ Icon, position = 'left', containerStyle, value, onChangeText, inputStyle, placeholder, ...textInputProps }: DefaultTextInputWithIconProps) => {

    return (
        <View style={[styles.inputContainer, position === 'right' && styles.inputContainerRight, containerStyle]}>
            {position === 'left' && <Icon />}
            <TextInput
                placeholder={placeholder}
                style={inputStyle}
                value={value}
                onChangeText={onChangeText}
            />
            {position === 'right' && <Icon />}
        </View>
    );
};

const styles = StyleSheet.create({
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: DecidaColors.Black,
        paddingVertical: 8,
        paddingHorizontal: 12,
        backgroundColor: DecidaColors.White
    },
    inputContainerRight: {
        justifyContent: 'flex-end',
    },
    icon: {
        marginRight: 10,
    },
    input: {
        flex: 1,
        fontSize: 16,
    },
});

export default DefaultTextInputWithIcon;
