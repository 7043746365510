import { ScrollView, View } from 'react-native'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import { CardTemplate, CardType } from '../../common/card-template'
import { CardColorScheme } from '../../common/card-color-scheme'
import { EmotionmeterIcon } from '../icons/emotion-meter-icon'
import { EmotionMeterImage1 } from '../images/emotion-meter-image1'
import { EmotionMeterImage2 } from '../images/emotion-meter-image2'
import { EmotionMeterImage3 } from '../images/emotion-meter-image3'
import { EmotionMeterImage4 } from '../images/emotion-meter-image4'
import { Dimensions } from 'react-native';
import { EmotionMeterImage5SmallDevice } from '../images/emotion-meter-image5'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { EmotionMeterImage5BigDevice } from '../images/emotion-meter-image5BigDevice'
import { EmotionmeterIconCard } from '../icons/emotion-meter-icon-card'
import ViewZoomableForIphone from '../../../common/view-zoomable'
import { useResponsive } from '../../../common/use-responsive'
import useDeviceTypes from '../../../common/use-device-types'

export const ToolsEmotionMeter: CardTemplate = {
    cardId: "2e03ca6f-ea66-4784-a13c-512d46bfbec1",
    title: 'Emotion meter',
    Header: EmotionmeterIcon,
    Icon: EmotionmeterIconCard,
    HeaderTitle: 'Emotion meter',
    PerfectFor: () => {
        const { width, isNarrowScreen } = useResponsive()
        const { isIphone } = useDeviceTypes()
        return (
            <>
                <View style={commonCardStyles.container}>
                    <View style={commonCardStyles.imagecontainer}>
                        <ViewZoomableForIphone style={commonCardStyles.emotionMeterImage}>
                            <EmotionMeterImage1 />
                        </ViewZoomableForIphone>
                        <ViewZoomableForIphone style={commonCardStyles.emotionMeterImage}>
                            <EmotionMeterImage2 />
                        </ViewZoomableForIphone>
                        <ViewZoomableForIphone style={commonCardStyles.emotionMeterImage}>
                            <EmotionMeterImage3 />
                        </ViewZoomableForIphone>
                        <ViewZoomableForIphone style={isNarrowScreen ? { minWidth: 350, maxHeight: 250, zIndex: 5 } : { width: 1000, height: 500, }}>
                            {
                                isIphone ? (
                                    <View style={{ zIndex: 5 }}>
                                        <EmotionMeterImage5BigDevice />
                                    </View>
                                ) : width < 600 ? (
                                    <ScrollView>
                                        <EmotionMeterImage5BigDevice />
                                    </ScrollView>
                                ) : (
                                    <EmotionMeterImage5BigDevice />
                                )
                            }

                        </ViewZoomableForIphone>
                        <ViewZoomableForIphone style={commonCardStyles.emotionMeterImage}>
                            <EmotionMeterImage4 />
                        </ViewZoomableForIphone>
                    </View>
                </View>
            </>
        )
    },

    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>

        </>
    ),
    colorScheme: CardColorScheme.TOOL,
    whyDoesItWorkText: '',
    type: [CardType.Tool],
    time: [],
    people: [],
    place: [],
    category: [],
    action: [],
    level: []
}

