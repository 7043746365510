import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { StyleSheet } from "react-native";
import { UserGroup } from "../../common/constants";
import { DecidaColors } from "../../common/decida-colors";
import { AdminDrawerNavigatorPamarList } from "../admin/admin-route-param-types";
import { CommonStyles } from "./const";
import { DefaultButton } from "./default-button";
import { ScreenNames } from "./screen-names";
import { showAlert } from "./universal-alert";

type Props = {
    schoolID: string,
    userGroup: UserGroup,
    classID?: string
}

export const TakeMeThereButton = ({ schoolID, userGroup, classID }: Props) => {

    const { navigate } = useNavigation<StackNavigationProp<AdminDrawerNavigatorPamarList>>()

    const takeMeThere = () => {
        if (userGroup === UserGroup.Teacher) {
            navigate(ScreenNames.AdminSchoolsMain, { screen: ScreenNames.AdminSchool, params: { id: schoolID } })
            return
        }


        if (!classID) {
            showAlert({
                title: "Take me there",
                message: "Not assigned to class yet",
                leftButtonText: "Ok"
            })
        }

        navigate(ScreenNames.AdminSchoolsMain, { screen: ScreenNames.AdminClass, params: { id: classID, schoolID } })

    }

    return (
        <DefaultButton
            buttonTextStyle={userGroup === UserGroup.Student ? styles.studentButtonText : undefined}
            style={userGroup === UserGroup.Student ? styles.studentButton : undefined}
            onPress={takeMeThere}>
            Take me there
        </DefaultButton>
    )
}

const styles = StyleSheet.create({
    studentButton: {
        ...CommonStyles.innerShadow,
        ...CommonStyles.buttonRounded,
        width: 'auto',
        alignSelf: 'flex-end',
        backgroundColor: DecidaColors.White,
        marginBottom: 20,
    },
    studentButtonText: {
        fontSize: 14,color: DecidaColors.AppleSystemGray2Dark
    }
})

