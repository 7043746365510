import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { StyleSheet, View } from 'react-native'
import { CustomCreateSchoolAdminMutation, CustomCreateSchoolAdminMutationVariables, CustomRemoveSchoolAdminMutation, CustomRemoveSchoolAdminMutationVariables, CustomUpdateSchoolAdminMutation, CustomUpdateSchoolAdminMutationVariables, GetSchoolAdminByCognitoUsernameQuery, GetSchoolAdminByCognitoUsernameQueryVariables, ListSchoolAdminBySchoolIDQuery, ListSchoolAdminBySchoolIDQueryVariables, SchoolAdmin } from '../../common/API'
import { getSchoolAdminByCognitoUsername, listSchoolAdminBySchoolID } from '../../common/graphql/queries'
import DefaultTable, { TableColumn } from '../common/default-table'
import { DefaultText } from '../common/default-text'
import { getSchoolCampusesAndGroupsQuery, GetSchoolCampusesAndGroupsQueryResponse } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query'
import { rvCurrentUser } from '../login/login-state'
import { showAlert } from '../common/universal-alert'
import { copyTextToClipboard } from '../common/copy-text-to-clipboard'
import { generatePassword } from '../../common/generate-password'
import { rvIsLoading } from '../common/loading'
import { customCreateSchoolAdmin, customRemoveSchoolAdmin, customUpdateSchoolAdmin } from '../../common/graphql/mutations'


const SchoolAdminHierarchySchoolAdminsList = () => {
    const user = useReactiveVar(rvCurrentUser)

    const { data: resultSchoolAdmin } = useQuery<GetSchoolAdminByCognitoUsernameQuery, GetSchoolAdminByCognitoUsernameQueryVariables>(gql`${getSchoolAdminByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "", limit: 5000 }, skip: !user })
    const currentSchoolAdmin = resultSchoolAdmin?.getSchoolAdminByCognitoUsername?.items[0]

    const { data: schoolData } = useQuery<GetSchoolCampusesAndGroupsQueryResponse>(getSchoolCampusesAndGroupsQuery, { variables: { id: currentSchoolAdmin?.schoolID }, skip: !currentSchoolAdmin })

    const { data: schoolAdminsData } = useQuery<ListSchoolAdminBySchoolIDQuery, ListSchoolAdminBySchoolIDQueryVariables>(gql`${listSchoolAdminBySchoolID}`, { variables: { schoolID: schoolData?.getSchool.id || "", limit: 5000 }, skip: !schoolData?.getSchool })
    const schoolAdmins = schoolAdminsData?.listSchoolAdminBySchoolID?.items.filter(schoolAdmin => schoolAdmin?._deleted !== true) as SchoolAdmin[]

    const [createSchoolAdminMutation] = useMutation<CustomCreateSchoolAdminMutation, CustomCreateSchoolAdminMutationVariables>(gql`${customCreateSchoolAdmin}`,)
    const [updateSchoolAdminMutation] = useMutation<CustomUpdateSchoolAdminMutation, CustomUpdateSchoolAdminMutationVariables>(gql`${customUpdateSchoolAdmin}`,)
    const [deleteSchoolAdminMutation] = useMutation<CustomRemoveSchoolAdminMutation, CustomRemoveSchoolAdminMutationVariables>(gql`${customRemoveSchoolAdmin}`,)

    const isSchoolCoordinator = schoolData?.getSchool?.coordinatorID === currentSchoolAdmin?.id

    const handleCreateSchoolAdmin = async (item: SchoolAdmin) => {
        try {
            rvIsLoading(true)
            await createSchoolAdminMutation({
                variables: {
                    input: {
                        schoolID: currentSchoolAdmin?.schoolID || "",
                        cognitoUsername: item.email,
                        firstName: item?.firstName,
                        lastName: item?.lastName,
                        email: item?.email,
                    }
                }
            })
            showAlert({
                title: 'Successfully created school admin',
                message: `username: ${item.email}`,
                leftButtonText: 'Ok'
            })
        } catch (error: any) {
            showAlert({
                title: "Something went wrong",
                message: error?.message || "",
                leftButtonText: "Ok"
            })
        } finally {
            rvIsLoading(false)
        }
    }

    const handleEditSchoolAdmin = async (data: SchoolAdmin) => {
        try {
            rvIsLoading(true)
            await updateSchoolAdminMutation({
                variables: {
                    input: {
                        id: data?.id,
                        email: data?.email,
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        preferredUsername: data?.preferredUsername,
                    }
                }
            })
            showAlert({
                title: 'Update school admin',
                message: `Successfully update teacher`,
                leftButtonText: 'Ok'
            })
        } catch (error: any) {
            showAlert({
                title: "Something went wrong",
                message: error?.message || "",
                leftButtonText: "Ok"
            })
        } finally {
            rvIsLoading(false)
        }
    }

    const handleDeleteSchoolAdmin = async (data: SchoolAdmin) => {
        try {

            rvIsLoading(true)
            await deleteSchoolAdminMutation({
                variables: {
                    input: {
                        id: data?.id
                    }
                }
            })
            showAlert({
                title: 'Delete school admin',
                message: 'Successfully deleted school admin',
                leftButtonText: 'Ok'
            })
        } catch (error: any) {
            showAlert({
                title: "Something went wrong",
                message: error?.message || "",
                leftButtonText: "Ok"
            })
        } finally {
            rvIsLoading(false)
        }
    }



    const columns = [
        { label: 'Username', key: 'cognitoUsername' },
        { label: 'First Name', key: 'firstName' },
        { label: 'Last Name', key: 'lastName' },
        { label: 'Email', key: 'email' },
        { label: 'Actions', key: "action_buttons" },

    ] as TableColumn[];

    return (
        <View style={styles.container}>
            <DefaultText style={styles.label}>Admin</DefaultText>
            <DefaultTable
                data={schoolAdmins}
                columns={columns}
                onCreate={handleCreateSchoolAdmin}
                onEdit={handleEditSchoolAdmin}
                onDelete={handleDeleteSchoolAdmin}
                showCreateUpdateDelete={isSchoolCoordinator}
            />
        </View>
    )
}
export default SchoolAdminHierarchySchoolAdminsList


const styles = StyleSheet.create({
    label: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    container: {
        flex: 1,
        padding: 40,
        gap: 10,
    },
})