import { StyleSheet, TextStyle } from 'react-native'
import { CONTAINER_INPUT_WIDTH } from '../admin/admin-consts'
import { DecidaColors } from '../../common/decida-colors'

export const DefaultSwitchesVideoHeight = 400

export const DefaultFontSize = 20

export const AverageCharacterHeight = 15

export const AverageCharacterWidth = 10

export const DefaultSecondaryFontSize = DefaultFontSize - 6

export const DefaultBackgroundColor = DecidaColors.White

export const ScreenWidthForStaticDrawer = 1024

export const DrawerWidth = 300

export const IconSize = 34

export const DefaultLessonPlanContentFontsize = 14

export const CheckInImageContainerMaxHeight = 400

export const CommonStyles = StyleSheet.create({
    textInput: {
        width: 330,
        borderColor: DecidaColors.Gray,
        borderWidth: 1,
        fontSize: DefaultFontSize,
        padding: 8,
        paddingLeft: 7,
        paddingRight: 7,
        marginTop: 7,
        marginBottom: 7,
        borderRadius: 4,
        backgroundColor: DecidaColors.InputField

    } as TextStyle,
    drawer: {
        display: 'none'
    },
    drawerAdmin: {
        width: DrawerWidth
    },
    picker: {
        width: CONTAINER_INPUT_WIDTH,
        borderColor: DecidaColors.Black,
        borderWidth: 1,
        fontSize: DefaultFontSize,
        padding: 2,
        paddingLeft: 7,
        paddingRight: 7,
        marginTop: 7,
        marginBottom: 7,
    },
    flexContainer: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        gap: 10
    },
    inputScrollView: {
        display: 'flex',
        flex: 1,
        width: '100%',
        // minWidth: 400,
        height: '100%',
        maxWidth: '100%',
    },
    flex: {
        flex: 1
    },
    flexRowSpaceBetween: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    lessonPlanFlexWrapper: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    lessonPlanFlexContainer: {
        alignContent: 'center',
        width: '100%',
        maxWidth: 700,
        marginHorizontal: 20
    },
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
        fontSize: DefaultLessonPlanContentFontsize
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
        fontSize: DefaultLessonPlanContentFontsize
    },
    lessonPlanSubTextDetails: {
        paddingLeft: 120,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
        fontSize: DefaultLessonPlanContentFontsize
    },
    lessonPlanTextSmallItalic: {
        textAlign: 'center',
        fontSize: DefaultLessonPlanContentFontsize,
        fontStyle: 'italic',
        color: DecidaColors.AppleSystemGray2Dark,
    },
    mainLessonText: {
        fontSize: DefaultLessonPlanContentFontsize,
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    lessonPlanDurationText: {
        marginBottom: 10,
        color: DecidaColors.Gray,
        alignSelf: 'center',
        fontSize: DefaultLessonPlanContentFontsize
    },
    lessonPlanResourceVideoContainer: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    lessonPlanImageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20,
        paddingHorizontal: 20
    },
    buttonRounded: {
        width: '100%',
        paddingVertical: 8,
        paddingHorizontal: 25,
        borderWidth: 1,
        borderColor: DecidaColors.Green,
        borderRadius: 20,
    },
    innerShadow: {
        position: 'relative',
        boxShadow: 'inset 3px 12px 36px -25px rgba(0, 0, 0, 0.5)',
    },
    dropShadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.20,
        shadowRadius: 1.41,
        elevation: 2,
    },
    buttonIcon: {
        width: 30,
        height: 30,
    },
})

export const OfflineMessage = "Please make sure you're connected to the internet and try again."

export const LAST_ACCESSED_VERSION_KEY = 'LastAccessedVersion'

export type DayScheduleModels = {
    [key: string]: DaySchedule
}

export type DaySchedule = {
    sessions: ScheduleType[],
    isActive: boolean;
}
export type ScheduleType = {
    openAt?: string;
    closeAt?: string;
    isError: boolean;
    errorMessage?: string;
}

export enum BreakPoints {
    XXS = 0,
    XS = 380,
    S = 600,
    M = 900,
    L = 1200,
    XL = 1520
}


export enum SchedulerPicker {
    OpenAt = "openAt",
    CloseAt = "closeAt"
}

export enum DayOptions {
    Week = "7",
    FourWeeks = "28",
    Year = "year"
}

export enum CognitoMessageAction {
    SUPPRESS = 'SUPPRESS',
    RESEND = 'RESEND',
}