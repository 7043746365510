import { View } from 'react-native'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { HelloKittyIcon } from '../../icons/hello-kitty-icon'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'
import { DefaultTextWithExtraTipIcon } from '../../../common/default-text-with-extra-tip-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { HelloKittyIconInverted } from '../../icons/hello-kitty-icon-inverted'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'

export const SwitchesActivitiesEnergizersHelloKitty: CardTemplate = {
    cardId: "f8fd46ed-dbb3-45d6-b880-92112636e071",
    title: 'Hello kitty',
    Header: HelloKittyIconInverted,
    Icon: HelloKittyIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"A quick, energising activity that is easy to adapt to different classroom settings."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Step one:"} />
            <DefaulttextWithRightArrow textdata={"Set up by drawing a line in the middle of the room with tape."} />
            <DefaulttextWithRightArrow textdata={"Split the group in two and have them stand on opposite sides of the line. One side are the kittens. The other side are the puppies."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Step two:"} />
            <DefaulttextWithRightArrow textdata={"Kittens cannot smile or laugh."} />
            <DefaulttextWithRightArrow textdata={"Puppies must say hello to the kittens in any way possible and try to make any of them smile or laugh. Puppies may not cross the line on the floor, everything else is allowed."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Step three: "} />
            <DefaulttextWithRightArrow textdata={"When a kitten smiles or laughs, it automatically becomes a puppy and must cross to the other side of the line."} />
            <DefaulttextWithRightArrow textdata={"Continue until only one kitten is left standing as the winner!"} />
            <DefaultTextWithoutArrowsItallic textdata={"To extend the game: Switch kittens into puppies and do it again; OR make the winning kittens into a team, see which one lasts longer."} />
        </>
    ),

    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"This activity may be run online with some tweaks - so long as you have everyone on webcam and present in your video conferencing tool. You can have the groups either use nonverbal feedback icons or put their hands up to indicate which team they’re on. If you’re being particularly creative, have them change their background image to either a puppy or kitten! This game works well on an honour system.The aim of the game is for the puppies, one at a time, to say hello to the kittens in any way possible and try to make any of them smile or laugh. Use speaker view in Zoom so that each puppy is seen and heard clearly when it’s their turn. Whenever a kitten smiles or laughs, they join the puppy team. Continue until only one kitten remains or everyone becomes a puppy!"} />
        </>

    ),
    CardContent: () => (
        <>

        </>
    ),
    colorScheme: CardColorScheme.ENERGIZERS,
    whyDoesItWorkText: '',
    onlineTips: 'This activity may be run online with some tweaks - so long as you have everyone on webcam and present in your video conferencing tool. You can have the groups either use nonverbal feedback icons or put their hands up to indicate which team they’re on. If you’re being particularly creative, have them change their background image to either a puppy or kitten! This game works well on an honour system. The aim of the game is for the puppies, one at a time, to say hello to the kittens in any way possible and try to make any of them smile or laugh. Use speaker view in Zoom so that each puppy is seen and heard clearly when it’s their turn. Whenever a kitten smiles or laughs, they join the puppy team. Continue until only one kitten remains or everyone becomes a puppy!',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Hello+Kitty.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.ENERGIZERS],
    action: [CardActionType.Action],
    level: []
}

