import { useReactiveVar } from '@apollo/client'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useNavigation } from '@react-navigation/native'
import { CheckInUserGroup } from '../../common/constants'
import { rvNetInfo } from '../common/common-state'
import { ScreenNames } from '../common/screen-names'
import { showOfflineAlert } from '../common/universal-alert'
import WellbeingWrapper from '../common/wellbeing-wrapper'
import { rvCurrentStudent } from '../student/use-student-state'
import { TeacherDrawerNavigatorPamarList } from './teacher-route-param-types'
import TeacherWellbeingStats from './teacher-wellbeing-stats'
const TeacherWellbeing = () => {

    const netInfoState = useReactiveVar(rvNetInfo)
    const { navigate } =
        useNavigation<DrawerNavigationProp<TeacherDrawerNavigatorPamarList>>();


    const startCheckIn = async () => {
        if (netInfoState?.isInternetReachable === false) return showOfflineAlert()

        rvCurrentStudent(null)
        navigate(ScreenNames.TeacherCheckInMain, { screen: ScreenNames.CheckInTirednessScale, params: { user: CheckInUserGroup.Teacher } })
    };


    return (
        <WellbeingWrapper startCheckIn={startCheckIn}>
            <TeacherWellbeingStats />
        </WellbeingWrapper>
    )
}
export default TeacherWellbeing
