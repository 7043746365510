import { useReactiveVar } from '@apollo/client';
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import { SafeAreaView, StyleSheet, TouchableOpacity, View } from "react-native";
import { DecidaColors } from "../../common/decida-colors";
import { DefaultFontSize } from "../common/const";
import { DefaultText } from "../common/default-text";
import { headerHeight } from "../common/header";
import { CLIENT_EVENT, createClientLog } from "../common/log-client-event";
import { logout } from "../common/logout";
import { rvShowPopoverNonTeachingStaff } from "../common/pop-over-menu-state";
import { ScreenNames } from "../common/screen-names";
import { StudentClassSelector } from "../common/student-class-selector";
import { versionNumberString } from "../env";
import { useNonTeachingStaffState } from "../student/use-non-teaching-staff-state";
import { rvSwitchesScreenState } from "../switches/common/current-screen-state";
import { SwitchesScreens } from "../switches/navigation/switches-bottom-navigation";
import { NonTeachingStaffNavigationParams } from "./non-teaching-staff-navigator-params-list";
import PopoverMenuProfileUsernameAndEmailInfo from '../common/pop-over-menu-profile-username-and-email-info';
import AvatarImage from '../common/avatar-image';


export const NonTeachingStaffPopoverMenu = () => {

    const showPopover = useReactiveVar(rvShowPopoverNonTeachingStaff)
    const { currentNonTeachingStaff } = useNonTeachingStaffState()
    const closePopup = () => {
        rvShowPopoverNonTeachingStaff(false)
    }
    const { navigate } = useNavigation<DrawerNavigationProp<NonTeachingStaffNavigationParams>>()

    const logoutHandler = () => {
        rvShowPopoverNonTeachingStaff(false)
        rvSwitchesScreenState(SwitchesScreens.HOME)
        logout()
        createClientLog({
            event: CLIENT_EVENT.USER_SIGNED_OUT,
            area: "Popover menu",
            action: "Clicking logout button, and sign out",
            payload: {
                group: "non teaching staff",
                nickname: currentNonTeachingStaff?.cognitoUsername,
            }
        })
    }
    const navigateToProfile = () => {
        rvShowPopoverNonTeachingStaff(false)
        rvSwitchesScreenState(SwitchesScreens.HOME)
        navigate(ScreenNames.NonTeachingStaffProfileMain)
    }

    if (!currentNonTeachingStaff) {
        return null
    }

    return showPopover ? (
        <TouchableOpacity style={styles.overlay} onPress={closePopup}>
            <SafeAreaView />
            <View style={styles.avatarpopover}>
                <PopoverMenuProfileUsernameAndEmailInfo cognitoUsername={currentNonTeachingStaff?.cognitoUsername || ""} email={currentNonTeachingStaff?.email || ""} />
                <View style={styles.profile}>
                    <TouchableOpacity onPress={navigateToProfile} style={styles.dividerButton}>
                        <View style={styles.profileContainer}>
                            <View style={styles.avatarContainer}>
                                <AvatarImage avatar={currentNonTeachingStaff?.avatar} size={40} />
                            </View>
                            <DefaultText style={styles.logouttext}>
                                Profile
                            </DefaultText>
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={styles.logout}>
                    <TouchableOpacity testID="logout-button" onPress={logoutHandler} style={styles.dividerButton}>
                        <DefaultText style={styles.logouttext}>
                            Logout
                        </DefaultText>
                    </TouchableOpacity>
                </View>
                <View style={styles.logout}>
                    <View style={styles.profileContainer}>
                        <DefaultText style={styles.buildVersion}>
                            {versionNumberString}
                        </DefaultText>
                    </View>
                </View>

            </View>
        </TouchableOpacity>
    ) : null
}


const styles = StyleSheet.create({
    avatarContainer: {
        marginHorizontal: 10,
    },
    overlay: {
        position: 'absolute',
        zIndex: 99,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    avatarpopover: {
        alignItems: 'flex-end',
        width: 300,
        minHeight: 200,
        maxHeight: 500,
        zIndex: 500,
        marginRight: 2,
        backgroundColor: DecidaColors.White,
        borderColor: 'grey',
        borderRadius: 5,
        borderWidth: 0.5,
        marginTop: headerHeight
    },
    logout: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    profile: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    logouttext: {
        marginRight: 15,
        color: DecidaColors.Gray,
        alignItems: 'flex-end',
        justifyContent: 'center',
        fontSize: DefaultFontSize - 2,

    },
    buildVersion: {
        marginRight: 15,
        fontSize: DefaultFontSize - 2,
        color: DecidaColors.AppleSystemGray4Light,
    },
    dividerButton: {
        width: "100%",
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    profileContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    }

})
