import { View } from "react-native"
import Svg, {
    Line,
} from "react-native-svg"

interface Props {
    strokecolor?: string
}
export const IconHeaderDivider = ({ strokecolor = "#fff" }: Props) => {
    return (
        <View>
            <Svg
                style={{ margin: 2 }}
                viewBox="0 0 2 40"
                width="2"
                height="50"
            >
                <Line y2="40" transform="translate(0.25)" fill="none" stroke={strokecolor} strokeWidth="0.5" />
            </Svg>
        </View>
    )
}

