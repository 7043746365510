import { createDrawerNavigator } from '@react-navigation/drawer'
import { authTimeout } from '../common/auth-timeout'
import { CommonStyles } from '../common/const'
import { Header } from '../common/header'
import { PlaceHolderComponent } from '../common/placeholder-component'
import { RootView } from '../common/root-view'
import { ScreenNames } from '../common/screen-names'
import { useShowDrawer } from '../common/use-show-drawer'
import { AdminMigrationTools } from './admin-migration-tools'
import AdminOnboardingStudentScript from './admin-onboarding-student-script'
import AdminOnboardingTeacherScript from './admin-onboarding-teacher-script'
import { AdminResetPassword } from './admin-reset-password'
import { AdminDrawerNavigatorPamarList } from './admin-route-param-types'
import { AdminSchoolsMain } from './admin-schools-main'
import { AdminSearchTeacher } from './admin-search-teacher'
import { AdminSearchStudent } from './admin-search-student'
import { AdminConfigMFA } from './admin-config-mfa'
import { AdminSideMenuContent } from './admin-side-menu-content'
import { AdminSwitchCardsCsv } from './admin-switch-cards-csv'
import { AdminWelcome } from './admin-welcome'

const SideMenu = createDrawerNavigator<AdminDrawerNavigatorPamarList>()

export const AdminMain = () => {
    authTimeout()

    const { headerShown, drawerType } = useShowDrawer()

    // assign all students to an rv

    return (
        <RootView>
            <SideMenu.Navigator
                drawerContent={props => <AdminSideMenuContent {...props} />}
                screenOptions={{
                    drawerStyle: CommonStyles.drawerAdmin,
                    drawerType: drawerType,
                    headerShown,
                    header: props => <Header {...props} />,
                    unmountOnBlur: true,
                    swipeEdgeWidth: 0
                }}
                initialRouteName={ScreenNames.AdminWelcome}
            >
                <SideMenu.Screen name={ScreenNames.Placeholder} component={PlaceHolderComponent} />
                <SideMenu.Screen name={ScreenNames.AdminWelcome} component={AdminWelcome} />
                <SideMenu.Screen name={ScreenNames.AdminSchoolsMain} component={AdminSchoolsMain} />
                <SideMenu.Screen name={ScreenNames.MigrationTools} component={AdminMigrationTools} />
                <SideMenu.Screen name={ScreenNames.AdminResetPassword} component={AdminResetPassword} />
                <SideMenu.Screen name={ScreenNames.AdminSearchTeacher} component={AdminSearchTeacher} />
                <SideMenu.Screen name={ScreenNames.AdminSearchStudent} component={AdminSearchStudent} />
                <SideMenu.Screen name={ScreenNames.AdminSwitchCardsCsv} component={AdminSwitchCardsCsv} />
                <SideMenu.Screen name={ScreenNames.AdminStudentOnboarding} component={AdminOnboardingStudentScript} />
                <SideMenu.Screen name={ScreenNames.AdminTeacherOnboarding} component={AdminOnboardingTeacherScript} />
                <SideMenu.Screen name={ScreenNames.AdminConfigMFA} component={AdminConfigMFA} />
            </SideMenu.Navigator>
        </RootView>
    )
}

