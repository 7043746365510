import Svg, { Path, Text, TSpan } from 'react-native-svg'
import { SvgEmotionIconProps } from './icon-emotion-types'

export const IconExcited = ({ width, height, withoutLabel = false }: SvgEmotionIconProps) =>
    <Svg width={width} height={height} viewBox="0 0 100.8 107.924">
        <Path d="M0,0A41.5,41.5,0,0,0,41.5-41.5,41.5,41.5,0,0,0,0-83,41.5,41.5,0,0,0-41.5-41.5,41.5,41.5,0,0,0,0,0" transform="translate(50.4 85.5)" fill="#fff" />
        <Path d="M0,0A41.5,41.5,0,0,0,41.5-41.5,41.5,41.5,0,0,0,0-83,41.5,41.5,0,0,0-41.5-41.5,41.5,41.5,0,0,0,0,0Z" transform="translate(50.4 85.5)" fill="none" stroke="#419f90" strokeWidth="5" />
        <Path d="M0,0A6.532,6.532,0,0,0,6.532-6.532,6.532,6.532,0,0,0,0-13.063,6.532,6.532,0,0,0-6.532-6.532,6.532,6.532,0,0,0,0,0" transform="translate(33.772 38.549)" fill="#419f90" />
        <Path d="M0,0A6.532,6.532,0,0,0,6.532-6.532,6.532,6.532,0,0,0,0-13.063,6.532,6.532,0,0,0-6.532-6.532,6.532,6.532,0,0,0,0,0" transform="translate(66.412 38.549)" fill="#419f90" />
        <Path d="M0,0S16.864,20.985,39.6.146" transform="translate(30.071 53.819)" fill="none" stroke="#419f90" strokeWidth="5" />
        <Path d="M0,0S-1.256-4.916,3.877-3.171" transform="translate(28.147 55.431)" fill="#45b248" />
        <Path d="M0,0S4.844-1.512,3.37,3.706" transform="translate(67.978 52.129)" fill="#45b248" />
        <Path d="M0,0S-5.931,6.408-17.938,10.47" transform="translate(39.826 11.362)" fill="none" stroke="#419f90" strokeWidth="5" />
        <Path d="M0,0S-3.64,3.535,1.649,4.73" transform="translate(21.096 19.471)" fill="#45b248" />
        <Path d="M0,0S1.554-4.83-3.677-3.4" transform="translate(41.647 13.04)" fill="#45b248" />
        <Path d="M0,0S5.931,6.408,17.938,10.47" transform="translate(60.974 11.362)" fill="none" stroke="#419f90" strokeWidth="5" />
        <Path d="M0,0S3.64,3.535-1.649,4.73" transform="translate(79.704 19.471)" fill="#45b248" />
        <Path d="M0,0S-1.554-4.83,3.677-3.4" transform="translate(59.153 13.04)" fill="#45b248" />
        <Path d="M0,0H40.32" transform="translate(29.659 53.885)" fill="none" stroke="#419f90" strokeWidth="4" />
        {withoutLabel ? (null) : (
            <>
                <Path d="M0,0A9.941,9.941,0,0,0-9.941,9.941,9.941,9.941,0,0,0,0,19.882H77.918A9.941,9.941,0,1,0,77.918,0Z" transform="translate(11.441 86.542)" fill="#fff" />
                <Text transform="translate(17.18 102.065)" fill="#419f90" fontSize="15" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">EX</TSpan></Text>
                <Text transform="translate(36.695 102.065)" fill="#419f90" fontSize="15" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">CITED</TSpan></Text>
                <Path d="M0,0A9.941,9.941,0,0,0-9.941,9.941,9.941,9.941,0,0,0,0,19.882H77.918A9.941,9.941,0,1,0,77.918,0Z" transform="translate(11.441 86.542)" fill="none" stroke="#419f90" strokeWidth="3" />
            </>
        )}
    </Svg>
