import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan, Line } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconAnxious7Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 7)
    }

    const onPressLevel2 = {
        [prop]: () => onPress(2 / 7)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(3 / 7)
    }

    const onPressLevel4 = {
        [prop]: () => onPress(4 / 7)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(5 / 7)
    }

    const onPressLevel6 = {
        [prop]: () => onPress(6 / 7)
    }

    const onPressLevel7 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg
            viewBox="0 0 277.135 153.338"
        >
            <G data-name="Group 9556">
                <G data-name="Group attenntive" {...onPressLevel1}>
                    <Path
                        data-name="Path 7590"
                        d="M0 0l52.6-.176.043-28.007z"
                        transform="translate(-1361 -114.671) translate(1361.711 267.884)"
                        fill="#f6f2df"
                    />
                    <G data-name="Group 8891">
                        <Path
                            data-name="Path 7591"
                            d="M0 0l52.641-28.183L52.6-.176z"
                            fill="none"
                            stroke="#657170"
                            strokeWidth={0.25}
                            transform="translate(-1361 -114.671) translate(1361.711 267.884)"
                        />
                    </G>
                    <G data-name="Group 8925">
                        <Path
                            data-name="Path 7625"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h28.759a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0028.759 0z"
                            fill="#fff"
                            transform="translate(-1361 -114.671) translate(1381.951 257.614)"
                        />
                    </G>
                    <G data-name="Group 8926">
                        <Path
                            data-name="Path 7626"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h28.759a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0028.759 0z"
                            fill="none"
                            stroke="#c3ac2e"
                            strokeWidth={0.25}
                            transform="translate(-1361 -114.671) translate(1381.951 257.614)"
                        />
                    </G>
                    <Text
                        transform="translate(-1361 -114.671) translate(1381.729 264.544)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Attentive"}
                        </TSpan>
                    </Text>
                    <G data-name="Group 8931">
                        <Path
                            data-name="Path 7631"
                            d="M0 0l47.913-25.76"
                            fill="none"
                            stroke="#6f7175"
                            strokeWidth={1}
                            transform="translate(-1361 -114.671) translate(1361.236 263.931)"
                        />
                    </G>
                    <Path
                        data-name="Path 7633"
                        d="M0 0l-7.385 1.073 2.417 4.495z"
                        transform="translate(-1361 -114.671) translate(1413.961 235.584)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group focussed" {...onPressLevel2}>
                    <Path
                        data-name="Path 7593"
                        d="M0 0l-.112 29.327 34.82-.022-.126-47.734z"
                        transform="translate(-1361 -114.671) translate(1416.921 238.341)"
                        fill="#ece6c0"
                    />
                    <G data-name="Group 8894">
                        <Path
                            data-name="Path 7594"
                            d="M0 0l34.583-18.429.125 47.734-34.819.022z"
                            fill="none"
                            stroke="#657170"
                            strokeWidth={0.25}
                            transform="translate(-1361 -114.671) translate(1416.921 238.342)"
                        />
                    </G>
                    <G data-name="Group 8910">
                        <Path
                            data-name="Path 7610"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h26.666A2.834 2.834 0 0029.5 6.463V2.835A2.835 2.835 0 0026.666 0z"
                            fill="#fff"
                            transform="translate(-1361 -114.671) translate(1420.886 244.796)"
                        />
                    </G>
                    <G data-name="Group 8911">
                        <Path
                            data-name="Path 7611"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h26.666A2.834 2.834 0 0029.5 6.463V2.835A2.835 2.835 0 0026.666 0z"
                            fill="none"
                            stroke="#c3ac2e"
                            strokeWidth={0.25}
                            transform="translate(-1361 -114.671) translate(1420.886 244.796)"
                        />
                    </G>
                    <Text
                        transform="translate(-1361 -114.671) translate(1418.9 252.43)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Focussed"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7634"
                        d="M1416.229 234.45l30.053-16.126"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-1361 -114.671)"
                    />
                    <Path
                        data-name="Path 7635"
                        d="M0 0l-7.385 1.066 2.413 4.5z"
                        transform="translate(-1361 -114.671) translate(1451.095 215.742)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group concerned" {...onPressLevel3}>
                    <Path
                        data-name="Path 7596"
                        d="M0 0l.177 49.336 34.423-.022.043-68.237z"
                        transform="translate(-1361 -114.671) translate(1454.256 218.434)"
                        fill="#e3daa1"
                    />
                    <G data-name="Group 8897">
                        <Path
                            data-name="Path 7597"
                            d="M0 0l34.649-18.923-.043 68.236-34.428.023z"
                            fill="none"
                            stroke="#657170"
                            strokeWidth={0.25}
                            transform="translate(-1361 -114.671) translate(1454.256 218.435)"
                        />
                    </G>
                    <G data-name="Group 8929">
                        <Path
                            data-name="Path 7629"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h31.357a2.835 2.835 0 002.835-2.834V2.835A2.836 2.836 0 0031.357 0z"
                            fill="none"
                            stroke="#c3ac2e"
                            strokeWidth={0.25}
                            transform="translate(-1361 -114.671) translate(1455.471 231.989)"
                        />
                    </G>
                    <G data-name="Group 8928">
                        <Path
                            data-name="Path 7628"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h31.357a2.835 2.835 0 002.835-2.834V2.835A2.836 2.836 0 0031.357 0z"
                            fill="#fff"
                            transform="translate(-1361 -114.671) translate(1455.471 231.989)"
                        />
                    </G>
                    <Text
                        transform="translate(-1361 -114.671) translate(1453.749 239.508)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Concerned"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7636"
                        d="M1453.646 214.608l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-1361 -114.671)"
                    />
                    <Path
                        data-name="Path 7637"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-1361 -114.671) translate(1488.749 195.459)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group worried" {...onPressLevel5}>
                    <Path
                        data-name="Path 7602"
                        d="M0 0l-.076 89.5 34.428-.022-.082-107.69z"
                        transform="translate(-1361 -114.671) translate(1529.104 178.267)"
                        fill="#d2c162"
                    />
                    <G data-name="Group 8903">
                        <Path
                            data-name="Path 7603"
                            d="M0 0l34.27-18.212.082 107.694-34.428.018z"
                            fill="none"
                            stroke="#657170"
                            strokeWidth={0.25}
                            transform="translate(-1361 -114.671) translate(1529.104 178.266)"
                        />
                    </G>
                    <G data-name="Group 8920">
                        <Path
                            data-name="Path 7620"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h26.3a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0026.3 0z"
                            fill="none"
                            stroke="#c3ac2e"
                            strokeWidth={0.25}
                            transform="translate(-1361 -114.671) translate(1533.092 206.376)"
                        />
                    </G>
                    <G data-name="Group 8919">
                        <Path
                            data-name="Path 7619"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h26.3a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0026.3 0z"
                            fill="#fff"
                            transform="translate(-1361 -114.671) translate(1533.092 206.376)"
                        />
                    </G>
                    <Text
                        transform="translate(-1361 -114.671) translate(1533.017 213.327)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"W"}
                        </TSpan>
                    </Text>
                    <Text
                        transform="translate(-1361 -114.671) translate(1540.381 213.327)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"orried"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7640"
                        d="M1527.914 175.05l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-1361 -114.671)"
                    />
                    <Path
                        data-name="Path 7641"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-1361 -114.671) translate(1563.017 155.9)"
                        fill="#6f7175"
                    />
                </G>
                {/* render 4 after 5 due text overlay */}
                <G data-name="Group Apprehensive" {...onPressLevel4}>
                    <Path
                        data-name="Path 7599"
                        d="M0 0l.05 69.611 34.82-.022-.082-87.9z"
                        transform="translate(-1361 -114.671) translate(1491.311 198.159)"
                        fill="#dbcd82"
                    />
                    <G data-name="Group 8900">
                        <Path
                            data-name="Path 7600"
                            d="M0 0l34.787-18.315.083 87.9-34.819.026z"
                            fill="none"
                            stroke="#657170"
                            strokeWidth={0.25}
                            transform="translate(-1361 -114.671) translate(1491.311 198.159)"
                        />
                    </G>
                    <Path
                        data-name="Path 7638"
                        d="M1490.496 194.198l30.307-16.533"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-1361 -114.671)"
                    />
                    <Path
                        data-name="Path 7639"
                        d="M0 0l-7.378 1.117L-4.934 5.6z"
                        transform="translate(-1361 -114.671) translate(1525.6 175.05)"
                        fill="#6f7175"
                    />
                    <G data-name="text-box" >
                        <G data-name="Group 8913">
                            <Path
                                data-name="Path 7613"
                                d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h41.41a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0041.41 0z"
                                fill="#fff"
                                transform="translate(-1361 -114.671) translate(1487.224 219.183)"
                            />
                        </G>
                        <G data-name="Group 8914">
                            <Path
                                data-name="Path 7614"
                                d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h41.41a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0041.41 0z"
                                fill="none"
                                stroke="#c3ac2e"
                                strokeWidth={0.25}
                                transform="translate(-1361 -114.671) translate(1487.224 219.183)"
                            />
                        </G>
                        <Text
                            transform="translate(-1361 -114.671) translate(1485.682 226.535)"
                            fill="#42454a"
                            fontSize={7}
                            fontFamily="Nunito-Bold, Nunito"
                            fontWeight={700}
                        >
                            <TSpan x={0} y={0}>
                                {"Apprehensive"}
                            </TSpan>
                        </Text>
                    </G>
                </G>
                <G data-name="Group Panicked" {...onPressLevel7}>
                    <Path
                        data-name="Path 7608"
                        d="M0 0l-.076 130.034L34.352 130l-.38-147.858z"
                        transform="translate(-1361 -114.671) translate(1603.657 137.736)"
                        fill="#c3ac2e"
                    />
                    <G data-name="Group 8916">
                        <Path
                            data-name="Path 7616"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h26.666A2.834 2.834 0 0029.5 6.463V2.835A2.835 2.835 0 0026.666 0z"
                            fill="#fff"
                            transform="translate(-1361 -114.671) translate(1607.463 180.763)"
                        />
                    </G>
                    <G data-name="Group 8917">
                        <Path
                            data-name="Path 7617"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h26.666A2.834 2.834 0 0029.5 6.463V2.835A2.835 2.835 0 0026.666 0z"
                            fill="none"
                            stroke="#c3ac2e"
                            strokeWidth={0.25}
                            transform="translate(-1361 -114.671) translate(1607.463 180.763)"
                        />
                    </G>
                    <Text
                        transform="translate(-1361 -114.671) translate(1605.936 188.36)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Panicked"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7644"
                        d="M1603.032 133.82l30.307-16.533"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-1361 -114.671)"
                    />
                    <Path
                        data-name="Path 7645"
                        d="M0 0l-7.377 1.118L-4.934 5.6 0 0z"
                        transform="translate(-1361 -114.671) translate(1638.135 114.671)"
                        fill="#6f7175"
                    />
                </G>
                {/* render 6 after 7 due to text overlay */}
                <G data-name="Group Overwhelmed" {...onPressLevel6}>
                    <Path
                        data-name="Path 7605"
                        d="M0 0l.051 109.625 34.819-.036-.082-128.582z"
                        transform="translate(-1361 -114.671) translate(1565.808 158.146)"
                        fill="#ccb94e"
                    />
                    <G data-name="Group 8906">
                        <Path
                            data-name="Path 7606"
                            d="M0 0l34.788-18.992.082 128.582-34.819.035z"
                            fill="none"
                            stroke="#657170"
                            strokeWidth={0.25}
                            transform="translate(-1361 -114.671) translate(1565.808 158.146)"
                        />
                    </G>
                    <G data-name="Group 8922">
                        <Path
                            data-name="Path 7622"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h44.205a2.834 2.834 0 002.835-2.837V2.835A2.835 2.835 0 0044.205 0z"
                            fill="#fff"
                            transform="translate(-1361 -114.671) translate(1560.91 193.569)"
                        />
                    </G>
                    <Text
                        transform="translate(-1361 -114.671) translate(1560.723 200.929)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Over"}
                        </TSpan>
                    </Text>
                    <Text
                        transform="translate(-1361 -114.671) translate(1576.409 200.929)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"whelmed"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7642"
                        d="M1565.048 154.23l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-1361 -114.671)"
                    />
                    <Path
                        data-name="Path 7643"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-1361 -114.671) translate(1600.15 135.081)"
                        fill="#6f7175"
                    />
                    <G data-name="Group 8923">
                        <Path
                            data-name="Path 7623"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h44.205a2.834 2.834 0 002.835-2.837V2.835A2.835 2.835 0 0044.205 0z"
                            fill="none"
                            stroke="#c3ac2e"
                            strokeWidth={0.25}
                            transform="translate(-1361 -114.671) translate(1560.91 193.569)"
                        />
                    </G>
                </G>
            </G>
        </Svg>
    )
}