import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { StyleSheet, View } from 'react-native'
import { GetClassQuery, GetClassQueryVariables, GetStudentQuery, GetStudentQueryVariables, SubmitWellbeingResponseMutation, SubmitWellbeingResponseMutationVariables } from '../../common/API'
import { CheckInUserGroup } from '../../common/constants'
import { submitWellbeingResponse } from '../../common/graphql/mutations'
import { getClass, getStudent } from '../../common/graphql/queries'
import { rvNetInfo } from '../common/common-state'
import { BreakPoints, CheckInImageContainerMaxHeight } from '../common/const'
import { DefaultText } from '../common/default-text'
import { CLIENT_EVENT, createClientLog } from '../common/log-client-event'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { showOfflineAlert } from '../common/universal-alert'
import { lowRes, useResponsive } from '../common/use-responsive'
import IconWellbeingQuestionOptions from '../icon/icon-wellbeing-question-options'
import { WellbeingResponseUserType } from '../models'
import { useNonTeachingStaffState } from '../student/use-non-teaching-staff-state'
import { rvTeacherCurrentTeacher } from '../teacher/teacher-state'
import { CheckInHeader } from './check-in-header'
import { CheckInNavigatorParamList } from './check-in-route-param-types'
import { rvCheckInState, rvCurrentWellbeingQuestion } from './check-in-state'

export const CheckInWellbeingQuestions = ({ navigation: { navigate }, route: { params } }: StackScreenProps<CheckInNavigatorParamList, 'CheckInWellbeingQuestion'>) => {
    const { studentID = '', checkinsessionID } = useReactiveVar(rvCheckInState)

    const { data: classResponse } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: params?.classID || "" }, skip: !params.classID })
    const currentClass = classResponse?.getClass

    const currentTeacher = useReactiveVar(rvTeacherCurrentTeacher)
    const { currentNonTeachingStaff } = useNonTeachingStaffState()

    const { data: studentResponse } = useQuery<GetStudentQuery, GetStudentQueryVariables>(gql`${getStudent}`, { variables: { id: studentID }, skip: !studentID })
    const currentStudent = studentResponse?.getStudent

    const randomQuestion = useReactiveVar(rvCurrentWellbeingQuestion)

    const netInfoState = useReactiveVar(rvNetInfo)
    const { width } = useResponsive()

    const [submitWellbeingResponseMutation] = useMutation<SubmitWellbeingResponseMutation, SubmitWellbeingResponseMutationVariables>(gql`${submitWellbeingResponse}`, {})

    const back = () => {
        navigate(ScreenNames.CheckInEmotionalIntensity, { classID: params?.classID, user: params?.user })
    }

    const saveResponse = (value: number) => {
        try {
            if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

            switch (params?.user) {
                case CheckInUserGroup.Teacher:
                    submitResponseTeacher(value)
                    break;
                case CheckInUserGroup.NonTeachingStaff:
                    submitResponseNonTeachingStaff(value);
                    break

                default:
                    submitResponseStudent(value)
                    break;
            }

            if (currentClass?.toggleRequestForChat) {
                navigate(ScreenNames.CheckInRequestChatWithTeacher, { classID: params?.classID, user: params?.user })
            } else {
                navigate(ScreenNames.CheckInFinish, { classID: params?.classID, user: params?.user })
            }

        } catch (error) {
            console.log(saveResponse.name)
            console.log({ error })
        }

    }


    const submitResponseTeacher = (responseValue: number) => {

        if (!currentTeacher) throw new Error("Teacher not found")

        if (!randomQuestion) throw new Error("Question not found")

        submitWellbeingResponseMutation({
            variables: {
                input: {
                    userID: currentTeacher?.id,
                    answer: responseValue,
                    schoolID: currentTeacher.schoolID,
                    userType: WellbeingResponseUserType.TEACHER,
                    wellbeingQuestionID: randomQuestion?.id,
                    classID: ""
                }
            }
        })
    }

    const submitResponseNonTeachingStaff = (responseValue: number) => {

        if (!currentNonTeachingStaff) throw new Error("Staff not found")

        if (!randomQuestion) throw new Error("Question not found")

        submitWellbeingResponseMutation({
            variables: {
                input: {
                    userID: currentNonTeachingStaff?.id,
                    answer: responseValue,
                    schoolID: currentNonTeachingStaff.schoolID,
                    userType: WellbeingResponseUserType.NON_TEACHING_STAFF,
                    wellbeingQuestionID: randomQuestion?.id,
                    classID: ""
                }
            }
        })
    }

    const submitResponseStudent = (responseValue: number) => {

        if (randomQuestion && currentClass) {
            submitWellbeingResponseMutation({
                variables: {
                    input: {
                        userID: studentID,
                        userType: WellbeingResponseUserType.STUDENT,
                        answer: responseValue,
                        classID: currentClass?.id || "",
                        schoolID: currentClass?.schoolID || "",
                        wellbeingQuestionID: randomQuestion?.id
                    }
                }
            })

        } else {
            // failed to procced request chat
            createClientLog({
                event: CLIENT_EVENT.SUBMIT_WELLBEING_QUESTIONS_FAILED,
                area: "Check in wellbeing question page",
                action: "Clicking wellbeing question options",
                payload: {
                    group: currentTeacher ? "teacher" : currentStudent ? "student" : "",
                    nickname: currentTeacher?.cognitoUsername || currentStudent?.cognitoUsername,
                    error: `Some data is not defined: randomQuestion: ${randomQuestion}, currentClass: ${currentClass}`
                }
            })
        }

    }
    const wideScreen = width > BreakPoints.M

    return (
        <Page style={styles.page} scroll>
            {
                !lowRes &&
                <CheckInHeader
                    back={back}
                    headerText={''}
                    studentId={studentID}
                />
            }
            <View style={styles.container}>
                <DefaultText style={styles.questionText}>{randomQuestion?.text}</DefaultText>
                <View style={styles.optionsContainer}>
                    <IconWellbeingQuestionOptions options={randomQuestion?.options || []} onPress={saveResponse} />
                </View>
            </View>
            {/* <View style={styles.container}>
                <CustomBulletsSVG spacing={150} />
            </View> */}
        </Page>
    )
}

const styles = StyleSheet.create({
    optionText: {
        textAlign: 'center',
        width: 150,
        flexWrap: 'wrap',
    },
    radioButton: {
        marginBottom: 10,
    },
    button: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    option: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginBottom: 10,
    },
    horizontalLines: {
        position: 'absolute',
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        width: '80%',
        top: 8,

    },
    optionsContainer: {
        flex: 1,
        width: '100%',
        justifyContent: 'space-evenly',
        maxHeight: CheckInImageContainerMaxHeight,
        padding: 20
    },
    questionText: {
        marginBottom: 40,
        textAlign: 'center',
    },
    page: {
        flex: 1,
        justifyContent: 'flex-start',
    },
    container: {
        flex: 1,
        width: '100%',
        height: '100%',
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },

})