import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaultTextWithExtraTipIcon } from '../../../common/default-text-with-extra-tip-icon'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { PaperCastleIcon } from '../../icons/paper-castle-icon'
import { PaperCastleIconInverted } from '../../icons/paper-castle-icon-inverted'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'

export const SwitchesActivitiesResilienceBuildersPaperCastle: CardTemplate = {
    cardId: "955976b7-21be-45ae-9afa-20707ff3bb74",
    title: 'Paper castle',
    Header: PaperCastleIconInverted,
    Icon: PaperCastleIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Building teamwork skills under pressure."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"A4 paper (10 sheets per group)"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Glue sticks (1 per group)"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Stopwatch"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Divide participants into groups of 3-6 people."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'Give each team ten sheets of A4 paper and one glue stick.'} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'Start a timer for five minutes (you can adjust the time to increase or decrease difficulty).'} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'In the given time, each group must work together to build the tallest tower possible using only the sheets of paper and the glue stick.'} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'The team with the tallest tower at the end of the time limit wins. '} />


            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Rules:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'The tower must be free-standing and not supported by any external objects.'} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'You can only use the paper and the glue provided'} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'You are allowed to cut, stick, fold, and glue the paper in any way you can think '} />

        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Tips:"} />
            <DefaulTextWithTipsIcon textdata='Encourage teams to discuss and plan their tower design before they start to build.' />
        </>

    ),
    audioUrl: "",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.RESILIENCE_BUILDERS],
    action: [CardActionType.Action],
    level: []

}

