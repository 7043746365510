import { ScrollView, StyleSheet, View } from 'react-native'
import { CardCategoryType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTimeType, CardType, StudentCardCategoryType } from '../switches/common/card-template'
import SwitchCardFilterGroupByType from './switch-card-filter-group'
import SwitchCardFilterGroupTitle from './switch-card-filter-group-title'
import { SwitchCardFilterMethods } from './switch-card-filter-methods'
import { useReactiveVar } from '@apollo/client'
import { SwitchCardFilterType } from './switch-card-filter'
import { rvFilterSwitchesData } from './common-state'
import { DecidaColors } from '../../common/decida-colors'
import { filter } from 'lodash'
import { rvUserGroup } from '../login/login-state'
import { UserGroup } from '../../common/constants'

const SwitchCardFilterExploreGroups = () => {
    const filters: SwitchCardFilterType = useReactiveVar(rvFilterSwitchesData)
    const isSwitchFilterApplied = filters.main.includes(CardType.Switch)
    const isActivityFilterApplied = filters.main.includes(CardType.Activity)
    const isToolAndVideoFilterApplied = (filters.main.includes(CardType.Tool) || filters.main.includes(CardType.Video)) && (!filters.main.includes(CardType.Activity) && !filters.main.includes(CardType.Switch))
    const userGroup = useReactiveVar(rvUserGroup)

    const setFilters = (data: SwitchCardFilterType) => {
        rvFilterSwitchesData(data)
    }

    return (
        <View style={styles.container}>
            <View style={styles.content}>
                <SwitchCardFilterGroupTitle onSelectAll={() => SwitchCardFilterMethods.selectAllTypeFilters(filters)} title='Switch type' filterType="explore" />
                <SwitchCardFilterGroupByType label="" group={CardType} filters={filters} setFilters={setFilters} groupType="main" />
            </View>
            {
                isActivityFilterApplied && (
                    <View style={styles.content}>
                        <SwitchCardFilterGroupTitle onSelectAll={() => SwitchCardFilterMethods.selectAllCategoryFilters(filters, "explore")} title='Category' filterType="explore" />
                        <SwitchCardFilterGroupByType label="" group={userGroup === UserGroup.Teacher ? CardCategoryType : StudentCardCategoryType} filters={filters} setFilters={setFilters} groupType="category" />
                    </View>
                )
            }

            {isSwitchFilterApplied && (
                <View style={styles.content}>
                    <SwitchCardFilterGroupTitle onSelectAll={() => SwitchCardFilterMethods.selectAllEmotionFilters(filters, "explore")} title='Emotion' filterType="explore" />
                    <SwitchCardFilterGroupByType label='' group={CardFilterEmotionType} filters={filters} setFilters={setFilters} groupType="emotion" />
                </View>
            )}
            {!isToolAndVideoFilterApplied && (
                <>
                    <View style={styles.content}>
                        <SwitchCardFilterGroupTitle onSelectAll={() => SwitchCardFilterMethods.selectAllParameterFilters(filters, "explore")} title='Place' filterType="explore" />
                        <SwitchCardFilterGroupByType label='' group={CardPlaceType} filters={filters} setFilters={setFilters} groupType="place" />
                    </View>
                    <View style={styles.content}>
                        <SwitchCardFilterGroupTitle onSelectAll={() => SwitchCardFilterMethods.selectAllParameterFilters(filters, "explore")} title='People' filterType="explore" />
                        <SwitchCardFilterGroupByType label="" group={CardPeopleType} filters={filters} setFilters={setFilters} groupType="people" />
                    </View>
                    <View style={styles.content}>
                        <SwitchCardFilterGroupTitle onSelectAll={() => SwitchCardFilterMethods.selectAllParameterFilters(filters, "explore")} title='Time' filterType="explore" />
                        <SwitchCardFilterGroupByType label="" group={CardTimeType} filters={filters} setFilters={setFilters} groupType="time" />
                    </View>
                    {isSwitchFilterApplied && <View style={styles.content}>
                        <SwitchCardFilterGroupTitle onSelectAll={() => SwitchCardFilterMethods.selectAllParameterFilters(filters, "explore")} title='Maturity level' filterType="explore" />
                        <SwitchCardFilterGroupByType label="" group={CardIntensitylevelType} filters={filters} setFilters={setFilters} groupType="level" />
                    </View>}

                </>
            )
            }
        </View>
    )
}
export default SwitchCardFilterExploreGroups
const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 40
    },
    content: {
        paddingVertical: 10,
        borderBottomWidth: 0.5,
        borderBottomColor: DecidaColors.Gray,
    },
})