import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { DrawerHeaderProps } from '@react-navigation/drawer/lib/typescript/src/types'
import { RouteProp, useRoute } from '@react-navigation/native'
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import { GetSchoolQuery, GetSchoolQueryVariables, GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables, Teacher } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { getSchool, getTeacherByCognitoUsername } from '../../common/graphql/queries'
import { rvCurrentClass, rvNetInfo, rvTeacherAbsenceMode } from '../common/common-state'
import { DefaultText } from '../common/default-text'
import { rvPrintMode } from '../common/export-to-pdf-button'
import { Header } from '../common/header'
import HeaderWellbeingButton from '../common/header-wellbeing-button'
import LeftHeaderLogo from '../common/left-header-logo'
import { CLIENT_EVENT, createClientLog } from '../common/log-client-event'
import { rvShowPopoverTeacher } from '../common/pop-over-menu-state'
import { ScreenNames } from '../common/screen-names'
import { showAbsenceWarnAlert, showOfflineAlert } from '../common/universal-alert'
import { useResponsive } from '../common/use-responsive'
import { IconDownArrow } from '../icon/icon-down-arrow'
import { IconHeaderDivider } from '../icon/icon-header-divider'
import { IconHeaderUserLoginAvatar } from '../icon/icon-header-user-login-avatar'
import { IconHome } from '../icon/icon-home'
import { getLockDerviceRv, getLockDeviceRvClassID, rvCurrentUser } from '../login/login-state'
import { rvSwitchesScreenState } from '../switches/common/current-screen-state'
import { SwitchesScreens } from '../switches/navigation/switches-bottom-navigation'
import TeacherHeaderBottomRow from './teacher-header-bottom-row'
import { TeacherDrawerNavigatorPamarList } from './teacher-route-param-types'
import { AntDesign } from '@expo/vector-icons';

export const TeacherHeader = (props: DrawerHeaderProps) => {
    const currentClass = useReactiveVar(rvCurrentClass)

    const user = useReactiveVar(rvCurrentUser)
    const lockDeviceClassID = useReactiveVar(getLockDeviceRvClassID())

    const { data: teacherResponse } = useQuery<GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables>(gql`${getTeacherByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
    const currentTeacher = teacherResponse?.getTeacherByCognitoUsername?.items[0] as Teacher

    const { data: schoolResponse } = useQuery<GetSchoolQuery, GetSchoolQueryVariables>(gql`${getSchool}`, { variables: { id: currentTeacher?.schoolID || "" }, skip: !currentTeacher })

    const currentSchool = schoolResponse?.getSchool

    const route = useRoute<RouteProp<TeacherDrawerNavigatorPamarList, "TeacherHome" | "TeacherClassMain">>()
    const showPopover = useReactiveVar(rvShowPopoverTeacher)
    const absenceMode = useReactiveVar(rvTeacherAbsenceMode)
    const netInfoState = useReactiveVar(rvNetInfo)
    const isPrintMode = useReactiveVar(rvPrintMode)
    const navigate = props.navigation.navigate
    const { width } = useResponsive()

    const headerLog = (name: string,) => {
        createClientLog({
            area: "TeacherHeader",
            action: `Clicking ${name} header, from ${route.name}`,
            event: CLIENT_EVENT.HEADER_ACTION_EVENT,
            payload: {
                group: "teacher",
                nickname: currentTeacher?.cognitoUsername,
            }
        })
    }

    const navigateWithAbsenceModeWarning = (type: string) => () => {

        let next: () => void
        switch (type) {
            case ScreenNames.TeacherHome:
                next = navigateToHome
                break;
            case ScreenNames.TeacherClassAdmin:
                next = navigateToClassAdmin
                break;
            case ScreenNames.TeacherStats:
                next = navigateToStats
                break;
            case ScreenNames.TeacherWellbeing:
                next = navigateToWellbeing
                break;
            case ScreenNames.Profile:
                if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()
                next = togglePopoverMenuVisibility
                break;

            default:
                next = () => {
                    console.log("Missing absence validation type")
                }
                break;
        }
        if (absenceMode) {
            showAbsenceWarnAlert({
                onLeftButtonPress: async () => {
                    rvTeacherAbsenceMode(false)
                    next()
                }
            })
            return
        }

        next()
    }

    const navigateToHome = () => {
        headerLog("Home")
        navigate(ScreenNames.TeacherHome)
        rvSwitchesScreenState(SwitchesScreens.HOME)
        rvCurrentClass(undefined)
    }


    const navigateToClassAdmin = () => {
        headerLog("Admin")
        navigate(ScreenNames.TeacherClassAdminMain, { screen: ScreenNames.TeacherClassAdmin })
        rvSwitchesScreenState(SwitchesScreens.HOME)
    }

    const navigateToStats = () => {
        headerLog("Stats")
        navigate(ScreenNames.TeacherStats)
        rvSwitchesScreenState(SwitchesScreens.HOME)
    }

    const navigateToWellbeing = () => {
        headerLog("Wellbeing")
        navigate(ScreenNames.TeacherWellbeing)
        rvSwitchesScreenState(SwitchesScreens.HOME)
    }

    const togglePopoverMenuVisibility = () => {
        headerLog("Profile")
        rvShowPopoverTeacher(!showPopover)
    }

    const navigateToCheckIn = async () => {
        headerLog("Check-in")

        if (lockDeviceClassID) {
            navigate(ScreenNames.TeacherClassMain, { screen: ScreenNames.CheckInStart, params: { classID: lockDeviceClassID } })
        }

        rvSwitchesScreenState(SwitchesScreens.HOME)
    }

    const denyAccess = useReactiveVar(getLockDerviceRv())

    return (
        <View style={{ display: isPrintMode ? "none" : undefined }} >
            <Header
                {...props}
                disableDrawer
                leftChildren={
                    width > 550 && <LeftHeaderLogo disabled={denyAccess || false} onPress={navigateWithAbsenceModeWarning(ScreenNames.TeacherHome)} />
                }
                centerChildren={
                    <View />
                }
                rightChildren={
                    denyAccess ?
                        null
                        :
                        (
                            (
                                <>
                                    <TouchableOpacity onPress={navigateWithAbsenceModeWarning(ScreenNames.TeacherHome)} testID='header-profile-home'>
                                        <View style={styles.icon}>
                                            <IconHome color={DecidaColors.Green} />
                                            <DefaultText style={styles.iconText}>Home</DefaultText>
                                        </View>
                                    </TouchableOpacity>
                                    <View>
                                        <IconHeaderDivider />
                                    </View>
                                    {currentSchool?.teacherWellbeing && (
                                        <HeaderWellbeingButton onPress={navigateWithAbsenceModeWarning(ScreenNames.TeacherWellbeing)} />
                                    )}
                                    <TouchableOpacity testID='header-profile-menu' onPress={navigateWithAbsenceModeWarning(ScreenNames.Profile)}>
                                        <View style={styles.profileContainer}>
                                            <View style={[styles.icon, styles.profileIcon]}>
                                                <IconHeaderUserLoginAvatar color={DecidaColors.Green} fill={DecidaColors.White} />
                                                <DefaultText style={styles.iconText}>Profile</DefaultText>
                                            </View>
                                            <IconDownArrow />
                                        </View>
                                    </TouchableOpacity>
                                </>
                            )
                        )

                }
                headerStyle={{ backgroundColor: DecidaColors.White }}
            />
            <TeacherHeaderBottomRow />
        </View>
    )
}

const styles = StyleSheet.create({
    icon: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5
    } as ViewStyle,
    iconText: {
        fontSize: 15,
        color: DecidaColors.Gray,
    },
    profileIcon: {
        marginRight: 0,
    },
    profileContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    }
})
