import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { Entypo, MaterialIcons } from '@expo/vector-icons'
import { DrawerScreenProps } from '@react-navigation/drawer'
import { CompositeScreenProps } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { ListStaffCheckInsByStaffIDQuery, ListStaffCheckInsByStaffIDQueryVariables, StaffCheckIn, UpdateStudentMutation } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { updateStaff } from '../../common/graphql/mutations'
import { listStaffCheckInsByStaffID } from '../../common/graphql/queries'
import { isDefined } from '../../common/is-defined'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'
import { CheckBox } from '../common/checkbox'
import { rvRatings } from '../common/common-state'
import { DefaultText } from '../common/default-text'
import { nudgesCheckIsSkipSuggestionDates } from '../common/nudges-check-is-skip-suggestion-dates'
import { nudgesGetStudentCheckInsWithConversionsToMaxMedMin } from '../common/nudges-get-check-ins-with-conversions-to-max-med-min'
import { negativeEmotions, nudgesGetConsecutiveEmotion } from '../common/nudges-get-consecutive-emotion'
import { nudgesGetFilteredEmotionCards } from '../common/nudges-get-filtered-emotion-cards'
import { nudgesGetScreetsForSleepSuggestions } from '../common/nudges-get-secrets-for-sleep-suggestions'
import { IntensityTier, SuggestionsParam } from '../common/nudges-types'
import { ScreenNames } from '../common/screen-names'
import { Switch4SchoolButton } from '../common/switch4schoolbutton'
import { useResponsive } from '../common/use-responsive'
import { IconThumbUp } from '../icon/icon-thumb-up'
import { Emotion } from '../models'
import { useNonTeachingStaffState } from '../student/use-non-teaching-staff-state'
import { rvSuggestionRatingRequired } from '../student/use-student-state'
import { CardTemplate } from '../switches/common/card-template'
import { ToolsProfessionalHelp } from '../switches/tools/cards/professional-help'
import { NonTeachingStaffNavigationParams } from './non-teaching-staff-navigator-params-list'

const MAX_CONTENT_WIDTH = 700

export const NonTeachingStaffCheckInFinish = ({ navigation: { navigate } }: CompositeScreenProps<StackScreenProps<CheckInNavigatorParamList, 'CheckInFinish'>, DrawerScreenProps<NonTeachingStaffNavigationParams>>) => {
    const { currentNonTeachingStaff } = useNonTeachingStaffState()
    const cardRatings = useReactiveVar(rvRatings)
    const { width } = useResponsive()

    const { data, loading } = useQuery<ListStaffCheckInsByStaffIDQuery, ListStaffCheckInsByStaffIDQueryVariables>(gql`${listStaffCheckInsByStaffID}`, { variables: { staffID: currentNonTeachingStaff?.id || "", limit: 5000 }, skip: !currentNonTeachingStaff })

    const previousNonTeachingStaffCheckins = data?.listStaffCheckInsByStaffID?.items.filter(isDefined) || [] as StaffCheckIn[]

    const [updateNonTeachingStaffMutation] = useMutation<UpdateStudentMutation>(gql`${updateStaff}`,)

    const nonTeachingStaffIntensityLevel = 7

    const [randomCard, setRandomCard] = useState<CardTemplate | null | undefined>()
    const [randomSleepCard, setRandomSleepCard] = useState<CardTemplate | null | undefined>()
    const [needHelpNudges, setNeedHelpNudges] = useState(false)

    const checkNeedHelp = (allNonTeachingStaffCheckInEmotions: SuggestionsParam[]) => {
        if (nonTeachingStaffIntensityLevel < 5) {
            return
        }

        const last4AllNonTeachingStaffCheckinEmotions = nudgesGetStudentCheckInsWithConversionsToMaxMedMin(allNonTeachingStaffCheckInEmotions.slice(-4), nonTeachingStaffIntensityLevel)
        const isAllNegativeEmotions = last4AllNonTeachingStaffCheckinEmotions.every((value) => value && negativeEmotions.includes(value.emotion || ""))
        const needHelp1 = last4AllNonTeachingStaffCheckinEmotions.length === 4 && isAllNegativeEmotions && last4AllNonTeachingStaffCheckinEmotions.every(value => value.emotionIntensityTier === IntensityTier.Max)
        const lastEmotion = _.last(last4AllNonTeachingStaffCheckinEmotions)
        const needHelp2 = ["ANXIOUS", "SAD", "SCARED"].includes(lastEmotion?.emotion || "") && lastEmotion?.emotionIntensityPercentage === 1;

        if (needHelp1 || needHelp2) {
            setNeedHelpNudges(true)
        }
    }

    const switchSuggestionAlgorithm = () => {
        if (previousNonTeachingStaffCheckins) {
            const sortedPrevStudentCheckins = _.sortBy(previousNonTeachingStaffCheckins, "createdAt").filter(checkin => checkin.emotion != null && checkin.emotionIntensityPercentage != null) as SuggestionsParam[]
            const results = nudgesGetConsecutiveEmotion(sortedPrevStudentCheckins, nonTeachingStaffIntensityLevel, currentNonTeachingStaff?.nonTeachingStaffCheckInCount, currentNonTeachingStaff?.nonTeachingStaffCheckInSkipSuggestionDate)

            checkNeedHelp(sortedPrevStudentCheckins)

            if (results) {
                let cards: CardTemplate[] | null = null
                const {
                    angry7,
                    anxious7,
                    excited7,
                    happy7,
                    sad7,
                    scared7
                } = nudgesGetFilteredEmotionCards(cardRatings)
                switch (results) {
                    case Emotion.ANGRY:
                        cards = angry7
                        break
                    case Emotion.ANXIOUS:
                        cards = anxious7
                        break
                    case Emotion.SAD:
                        cards = sad7
                        break
                    case Emotion.SCARED:
                        cards = scared7
                        break
                    case Emotion.HAPPY:
                        cards = happy7
                        break
                    case Emotion.EXCITED:
                        cards = excited7
                        break
                    default:
                        return null
                }

                if (cards) {
                    return cards[Math.floor(Math.random() * cards.length)]
                }
            }
            return null

        }
    }

    const getScreetsForSleepSuggestions = () => {
        if (nudgesCheckIsSkipSuggestionDates()) {
            return null
        }
        const sortedPrevNonTeachingStaffCheckins = _.sortBy(previousNonTeachingStaffCheckins, "createdAt")
        setRandomSleepCard(nudgesGetScreetsForSleepSuggestions(sortedPrevNonTeachingStaffCheckins))
    }

    useEffect(() => {
        setRandomCard(switchSuggestionAlgorithm())
        getScreetsForSleepSuggestions()
    }, [])


    if (!currentNonTeachingStaff) {
        return null
    }

    const backToCheckInStart = () => {
        navigate(ScreenNames.NonTeachingStaffHome)
    }

    const Card = randomCard
    const ScreetsForSleepCard = randomSleepCard

    const navigateToCard = (Card: CardTemplate) => {
        let prevScreen: "tools" | "emotion" = "emotion"

        if (Card.cardId === "c9e876f1-772a-4030-8d49-ba3b20329259") {
            // Need help? switches card
            prevScreen = "tools"
        }

        navigate(ScreenNames.Card, { id: Card.cardId, previousScreen: prevScreen })
        rvSuggestionRatingRequired(true)
    }

    const onSkipSuggestion = (skipSuggestion: boolean) => {
        if (skipSuggestion) {
            const nextWeek = moment().add('day', 7).toISOString()
            updateNonTeachingStaffMutation({
                variables: {
                    input: {
                        id: currentNonTeachingStaff?.id,
                        studentCheckInSkipSuggestionDate: nextWeek,
                        studentCheckInCount: 0,
                        _version: currentNonTeachingStaff?._version
                    }
                }
            })
        } else {
            updateNonTeachingStaffMutation({
                variables: {
                    input: {
                        id: currentNonTeachingStaff?.id,
                        studentCheckInSkipSuggestionDate: null,
                        _version: currentNonTeachingStaff?._version
                    }
                }
            })
        }
    }

    if (loading) {
        return null
    }

    return (
        <ScrollView contentContainerStyle={styles.page}>
            <DefaultText style={styles.title}>Thanks for that!</DefaultText>
            {Card &&
                <View style={[styles.nudgesWrapper, { borderColor: Card.colorScheme, flexDirection: width < 700 ? 'column-reverse' : 'row' }]}>
                    <View style={styles.suggestionTextWrapper}>
                        <DefaultText style={styles.suggestionText}>Why not try this switch that other people find helpful.</DefaultText>
                    </View>

                    {width < 700 ?
                        null :
                        <View>
                            <Entypo name="arrow-bold-right" size={60} color={Card.colorScheme} />
                        </View>
                    }

                    <TouchableOpacity key={Card.cardId} style={styles.item} onPress={() => navigateToCard(Card)}>
                        <View style={styles.cardicon}>
                            <Card.Icon />
                        </View>
                        <DefaultText style={styles.cardtext}>
                            {Card.title}
                        </DefaultText>
                    </TouchableOpacity>
                </View>
            }

            {ScreetsForSleepCard && (
                <View style={[styles.nudgesWrapper, { borderColor: ScreetsForSleepCard.colorScheme, flexDirection: width < 700 ? 'column-reverse' : 'row' }]}>
                    <View style={styles.suggestionTextWrapper}>
                        <DefaultText style={[styles.suggestionSwitchText, { color: ScreetsForSleepCard.colorScheme }]}>Secrets for Sleep</DefaultText>
                        <DefaultText style={styles.suggestionText}>If you’re having trouble sleeping you could try this for a couple of nights.</DefaultText>
                    </View>

                    {width < 700 ?
                        null :
                        <View>
                            <Entypo name="arrow-bold-right" size={60} color={ScreetsForSleepCard.colorScheme} />
                        </View>
                    }
                    <TouchableOpacity key={ScreetsForSleepCard.cardId} style={styles.item} onPress={() => navigateToCard(ScreetsForSleepCard)}>
                        <View style={styles.cardicon}>
                            <ScreetsForSleepCard.Icon />
                        </View>
                        <DefaultText style={styles.cardtext}>
                            {ScreetsForSleepCard.title}
                        </DefaultText>
                    </TouchableOpacity>
                </View>

            )}

            {(needHelpNudges || ScreetsForSleepCard || Card) && <View style={styles.checkboxContainer}>
                <CheckBox checked={currentNonTeachingStaff.nonTeachingStaffCheckInCount ? moment(currentNonTeachingStaff?.nonTeachingStaffCheckInSkipSuggestionDate).isAfter(moment()) : false} onChange={onSkipSuggestion} />
                <DefaultText>Don’t show me suggestions for another 7 days</DefaultText>
            </View>}

            {needHelpNudges && (
                <View style={[styles.needHelpContainer, { flexDirection: width < 700 ? 'column' : 'row' }]}>
                    <TouchableOpacity style={styles.item} onPress={() => navigateToCard(ToolsProfessionalHelp)}>
                        <View style={styles.cardicon}>
                            <ToolsProfessionalHelp.Icon />
                        </View>
                        <DefaultText style={styles.cardtext}>
                            {ToolsProfessionalHelp.title}
                        </DefaultText>
                    </TouchableOpacity>
                    <View style={styles.extraHelpSuggestionTextWrapper} >
                        <DefaultText style={[styles.suggestionSwitchText, { color: DecidaColors.Angry }]}>NEED EXTRA HELP?</DefaultText>
                        <DefaultText style={styles.extraHelpSuggetionText}>
                            Sometimes you might need extra help. Use this link to find someone outside of school to talk to.
                        </DefaultText>
                    </View>
                </View>
            )}

            {!Card && !ScreetsForSleepCard && <IconThumbUp />}

            <Switch4SchoolButton onPress={backToCheckInStart} buttonText={"Finished"} Icon={() => <MaterialIcons name="next-plan" size={40} color={DecidaColors.Green} />} />


        </ScrollView>
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'space-evenly',
        minHeight: '100%',
        alignItems: 'center',
        paddingHorizontal: 20
    },
    title: {
        fontSize: 40
    },
    avatar: {
        width: 100,
        height: 100,
    },
    cardtext: {
        width: '100%',
        fontSize: 14,
        textAlign: 'center',
    },
    cardicon: {
        width: 100,
        height: 100,

    },
    item: {
        width: 100,
        textAlign: 'center',
        margin: 10,
    },
    suggestionText: {
        textAlign: 'center',

    },
    nudgesWrapper: {
        borderWidth: 3,
        borderStyle: 'dashed',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 40,
        marginBottom: 10,
        borderRadius: 20,
        width: '100%',
        maxWidth: 600,
        flexWrap: 'wrap'
    },
    suggestionTextWrapper: {
        margin: 10,
        alignItems: 'center',
        flex: 1,
        justifyContent: 'space-evenly',
    },
    suggestionSwitchText: {
        position: 'relative',
        fontWeight: 'bold',
        fontSize: 24,
        textAlign: 'center'
    },
    needHelpContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: MAX_CONTENT_WIDTH,
        paddingHorizontal: 40,
        width: '100%',
        marginHorizontal: 20,
    },
    extraHelpSuggetionText: {
        textAlign: 'center',
        paddingHorizontal: 10
    },
    extraHelpSuggestionTextWrapper: {
        padding: 10,
        alignItems: 'center',
        flex: 1,
        height: '100%',
        justifyContent: 'space-evenly'
    },
    checkboxContainer: {
        flexDirection: 'row',
        width: '100%',
        maxWidth: MAX_CONTENT_WIDTH,
        alignItems: 'center',
        justifyContent: 'center'
    }
})