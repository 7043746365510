import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { useNavigation } from '@react-navigation/core'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useEffect, useMemo, useState } from 'react'
import { Pressable, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { ListFavouriteByCognitoUsernameQuery, ListFavouriteByCognitoUsernameQueryVariables } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { listFavouriteByCognitoUsername } from '../../common/graphql/queries'
import { rvFilterSwitchesData } from '../common/common-state'
import { DefaultText } from '../common/default-text'
import InputSearchContent from '../common/input-search-content'
import { ScreenNames } from '../common/screen-names'
import { Stepper } from '../common/stepper'
import SwitchCardFilter from '../common/switch-card-filter'
import { SwitchCardFilterMethods } from '../common/switch-card-filter-methods'
import SwitchCardNoResultFilter from '../common/switch-card-no-result-filter'
import { useResponsive } from '../common/use-responsive'
import { rvCurrentUser, rvUserGroup } from '../login/login-state'
import { TeacherDrawerNavigatorPamarList } from '../teacher/teacher-route-param-types'
import { ProgressiveLoader } from './common/progressive-loader'
import { UserGroup } from '../../common/constants'
import { cards, cardsPublic, switchesLessonPlans } from './emotion-cards/cards'
import { SwitchFilterIcon } from './tools/icons/switch-filter-icon'
import { openUrlWebView } from '../web-view/open-url-web-view'

enum ExploreType {
    All = "All",
    Favourite = "Favourite"
}

export const SwitchesExploreAll = () => {

    const { navigate } = useNavigation<DrawerNavigationProp<TeacherDrawerNavigatorPamarList>>()
    const [title, setTitle] = useState('')
    const [type, setType] = useState<ExploreType>(ExploreType.All)
    const user = useReactiveVar(rvCurrentUser)

    const { data: responseFavourites } = useQuery<ListFavouriteByCognitoUsernameQuery, ListFavouriteByCognitoUsernameQueryVariables>(gql`${listFavouriteByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })

    const favourites = responseFavourites?.listFavouriteByCognitoUsername?.items.filter((fav) => fav?._deleted !== true)
    const favs = favourites?.map((data) => data && data.cardId)
    const filters = useReactiveVar(rvFilterSwitchesData)
    const isAnyFilterApplied = SwitchCardFilterMethods.isAnyFilterApplied(filters)
    const { isLargeScreen } = useResponsive()

    const userGroup = useReactiveVar(rvUserGroup)
    let currentSwitchAllCards = cards;

    useEffect(() => {

        return () => {
            // clear filter when navigates away
            SwitchCardFilterMethods.clearFilter()
        }
    }, [type])

    switch (userGroup) {
        case UserGroup.Student:
        case UserGroup.ClassLogin:
        case UserGroup.NonTeachingStaff:
        case undefined:

            if (isAnyFilterApplied) {
                currentSwitchAllCards = SwitchCardFilterMethods.getFilteredSwitches(cardsPublic, filters)
                break
            }
            currentSwitchAllCards = cardsPublic
            break

        default:

            if (isAnyFilterApplied) {
                currentSwitchAllCards = SwitchCardFilterMethods.getFilteredSwitches(cards, filters)
                break
            }

            currentSwitchAllCards = cards
            break;
    }

    const currentSwitchAllCardsMemo = useMemo(() => {
        return currentSwitchAllCards
            .sort(function (a, b) {
                if (a.title < b.title) { return -1; }
                if (a.title > b.title) { return 1; }
                return 0;
            })
            .filter((card) => !switchesLessonPlans.includes(card))
            .filter((card) => type === ExploreType.Favourite ? favs?.includes(card.cardId) : card)
            .filter(card => card.title.toLowerCase().indexOf(title.toLowerCase()) != -1)
    }, [currentSwitchAllCards, type, favs, title])

    return (
        <>
            <View style={styles.topContent}>
                <View style={{ flexDirection: 'row' }}>
                    <Stepper
                        values={[
                            { value: ExploreType.All, label: 'All' },
                            { value: ExploreType.Favourite, label: 'Favourite' }
                        ]}
                        currentValue={type}
                        onChange={setType}
                        width={200}
                        SelectedFontSize={12}
                        fontSize={12}
                    />
                </View>
                <InputSearchContent title={title} onChangeText={setTitle} placeholder='Search cards...' />
                <Pressable style={styles.filterIconContainer} onPress={SwitchCardFilterMethods.showExploreFilter}>
                    <View style={styles.filterIconContent}>
                        <View style={styles.filterIcon}>
                            <SwitchFilterIcon />
                        </View>
                        {isLargeScreen && <DefaultText style={styles.filterText}>Filters</DefaultText>}
                    </View>
                </Pressable>
            </View>
            <ScrollView style={styles.scrollview} contentContainerStyle={styles.container} >

                {type === ExploreType.Favourite && (!favs || favs?.length === 0) && (
                    <DefaultText>No favourites selected</DefaultText>
                )}
                {isAnyFilterApplied && currentSwitchAllCardsMemo.length === 0 && (
                    <SwitchCardNoResultFilter />
                )}
                <ProgressiveLoader>
                    {
                        currentSwitchAllCardsMemo.map((Card) => {
                            const navigateToCard = () => {
                                if (Card.openUrl) {
                                    openUrlWebView(Card.openUrl)
                                    return
                                }
                                navigate(ScreenNames.Card, { id: Card.cardId, previousScreen: 'explore' })
                            }

                            return (
                                <TouchableOpacity key={Card.cardId} style={styles.item} onPress={navigateToCard}>
                                    <View style={styles.cardicon}>
                                        <Card.Icon />
                                    </View>
                                    <DefaultText style={styles.cardtext}>
                                        {Card.title}
                                    </DefaultText>
                                </TouchableOpacity>
                            )
                        })
                    }
                </ProgressiveLoader>
            </ScrollView>
            <SwitchCardFilter filterType='explore' />
        </>

    )
}

const styles = StyleSheet.create({

    container: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        padding: 10,
        paddingBottom: 20,
    },
    content: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: "100%"

    },
    image: {
        width: 350,
        height: 450,
    },
    header: {
        fontSize: 32,
        fontWeight: 'bold',
        color: DecidaColors.Gray,
        textAlign: 'center',
    },
    item: {
        width: 100,
        textAlign: 'center',
        margin: 10,
    },
    scrollview: {
        flexGrow: 0,
        width: '100%',
        height: '100%',
    },
    cardtext: {
        width: '100%',
        fontSize: 14,
        textAlign: 'center',


    },
    cardicon: {
        width: 100,
        height: 100,
    },
    input: {
        width: '100%',
        height: 40,
        margin: 12,
        borderWidth: 1,
        padding: 10,
        borderRadius: 5,
    },
    thumbUp: {
        height: 40,
        width: 40,
        position: 'absolute',
        right: -10,
        top: -20,
        bottom: 0,
        zIndex: 500,
    },
    topContent: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        paddingHorizontal: 20,
        marginTop: 10
    },
    filterIconContainer: {
        position: 'absolute',
        right: 20,
        top: 0,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 20,
        marginLeft: 10,
        borderRadius: 20,
        borderWidth: 1,
        borderColor: DecidaColors.Gray,
        paddingVertical: 5,
    },
    filterIcon: {
        width: 24,
        height: 24
    },
    filterIconContent: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    filterText: {
        marginLeft: 5,
        color: DecidaColors.Gray
    }
})
