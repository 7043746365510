import { View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { SmallStepsIcon } from '../../explore/src/small-steps-icon'
import { SmallStepsIconInverted } from '../../explore/src/small-steps-icon-inverted'
import { SmallStepsImage1 } from './src/small-steps-image1'

export const SwitchesEmotionsSadSmallSteps: CardTemplate = {
    cardId: "cb256a8d-2c31-4f23-96ef-b90dc57d3d2f",
    title: 'Small steps',
    Header: SmallStepsIconInverted,
    Icon: SmallStepsIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <SmallStepsImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Facing an overwhelming task or when you are feeling generally helpless or sad."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Think of an activity that you need to do, that feels hard because you’re sad."} />
                <DefaultTextWithDownArrow textdata={"Visualise how good you will feel once the task is done."} />
                <DefaultTextWithDownArrow textdata={"Think of what next small step you can do to get going. Don’t worry about the whole task, just focus on the next, small step."} />
                <DefaultTextWithoutArrows textdata={"Now set about and do it. Once completed do the next small step and start to gain some momentum for the task at hand."} />

            </View>
        </>
    ),
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: 'Sadness is an emotion which often overwhelms the brain, making it hard to be active and achieve. By creating ‘small steps’ you short circuit this overwhelm, and achieving tasks has proven to mitigate the effect of sadness and depression.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Explain the concept of learned helplessness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice positive reframing.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 35 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reference the Small Steps switch and introduce the concept of reframing an internal dialogue when feeling overwhelmingly unmotivated.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Define learned helplessness as a mental state where someone, after repeated adverse situations, becomes unable or unwilling to avoid these situations due to the belief that they have no control. Discuss the impact of limiting beliefs on motivation and productivity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide an example, such as struggling with math despite putting in effort, and how it can lead to the belief that one will never be good at math. “For example, if you've consistently failed at maths despite studying really hard and trying different things, you will probably begin to think that you will never be good at math, no matter what you do. You have put in so much time and effort to achieve this goal, but you see no results, so what is the point of trying?”</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- Explain that the first step in addressing learned helplessness is to change this negative self limited belief to a positive more optimistic story.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Understanding Learned Helplessness (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Small Steps worksheet and ask students to think about something they or someone they know has given up trying to do. If they don't have a personal situation to draw upon, ask them to think about someone they know (or use their imagination to think about someone that has given up on something such as being hopeless at maths). Note it on the sheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to come up with different positive thoughts, statements, or actions that can counter learned helplessness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Examples:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- I can get help from my friend who is good at maths.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- I'm good at lots of things, so I can be good at maths too.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- If I practice a little bit every day, I will get better.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Sharing and Debriefing (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their ideas, fostering a supportive and open discussion.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief by explaining that changing negative beliefs to positive, optimistic stories is the first step in addressing learned helplessness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of getting motivation and courage to keep trying, breaking down overwhelming activities into small steps, and focusing on building momentum.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Small Steps Commitment (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer back to the Small Steps worksheet and ask students to note one small step they could take to overcome the negative impact of their limiting belief (e.g., passing the maths exam example).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their commitments, emphasising that small steps lead to momentum and gradual achievement.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points about learned helplessness, the impact of negative beliefs, and the importance of positive thinking and small steps.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to apply these strategies in their lives, focusing on changing negative beliefs and taking small steps toward their goals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, ask them to find the relevant section in the Switch4Schools app for additional reinforcement.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Small Steps worksheet' link={Worksheets.smallSteps.uri} /> </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What Is Learned Helplessness and Why Does it Happen? -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.verywellmind.com/what-is-learned-helplessness-2795326' link='https://www.verywellmind.com/what-is-learned-helplessness-2795326' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The HORRID Pain of Learned Helplessness -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.youtube.com/watch?v=C3a65C4kT-8' link='https://www.youtube.com/watch?v=C3a65C4kT-8' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Progress is more important than completion.' />
            <DefaulTextWithTipsIcon textdata='Focus on celebrating the small wins to encourage continued momentum and remove focus from the sad feelings.' />
            <DefaulTextWithTipsIcon textdata='If you are struggling even with the small steps, that’s okay, it may take some time to change the way you think about the activity, but if you keep trying it will happen.' />

        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Small+Steps.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Sad]
}