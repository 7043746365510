import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { NinjaDestructionIcon } from '../../icons/ninja-destruction-icon'
import { NinjaDestructionIconInverted } from '../../icons/ninja-destruction-icon-inverted'


export const SwitchesActivitiesEnergizersNinjaDestruction: CardTemplate = {
    cardId: "0f55adde-0b26-480a-a425-609ccdcd7fd7",
    title: 'Ninja destruction',
    Header: NinjaDestructionIconInverted,
    Icon: NinjaDestructionIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Enough floor space for the group to stand"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Gather a group of players (<4) people works best"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"To start the game everyone stands shoulder to shoulder in a circle and in sync everyone shouts “ninja destruction!” and as you say it everyone takes a large step back and puts their arms out in a ninja-like fashion"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The game then begins by the group nominating one person to start"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The aim of the game is to do a ninja chop to hit someone else’s hand and they have to try and evade your chop"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"If the person attacking touches the defender's arm (from the elbow to the hand) then the defender loses a life by putting the hand that was struck behind their back"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"If the attacker misses then it is the next person’s turn (rotating clockwise)"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"A player is eliminated if both of their lives are lost (both arms are struck)"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Rules:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The attacker gets one go to attack"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The attack must be in one consistent fluid motion and once the motion has been completed the person must freeze in that position until the turn comes back around to them"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The same goes for the defender, when evading an attack they must freeze in the position that they ended up in until the turn comes back around to them"} />


        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.ENERGIZERS,
    whyDoesItWorkText: '',
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.ENERGIZERS],
    action: [CardActionType.Action],
    level: []
}

