import { StackScreenProps } from '@react-navigation/stack'
import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { BackButton } from '../common/back-button'
import CurrentClassLogin from '../common/current-class-login'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { TeacherClassNavigatorParamList } from './teacher-route-param-types'



export const TeacherClassAdminCurrentClassLogin = ({ route: { params: { classID } }, navigation: { navigate } }: StackScreenProps<TeacherClassNavigatorParamList, 'TeacherClassAdminCurrentClassLogin'>) => {


    const navigateToClassAdmin = () => {
        navigate(ScreenNames.TeacherClassAdmin, { classID: classID })
    }

    return (
        <Page style={styles.page}>
            <View style={styles.header}>
                <View style={styles.backButtonContainer}>
                    <BackButton onPress={navigateToClassAdmin} />
                </View>
                <View style={styles.spacer} />
            </View>

            <CurrentClassLogin classID={classID} />
        </Page>
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
    },
    header: {
        flexDirection: 'row',
        width: '100%',
    },
    backButtonContainer: {
        flex: 1,
        paddingTop: 5,
    },
    spacer: {
        flex: 1,
    },
    currentClassLoginContainer: {
        flex: 1,
    },
    currentClassLoginScrollContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 700,
        maxHeight: 500,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',

    },
    heading: {
        fontSize: 17,
        fontWeight: 'bold',
        paddingTop: 20,

    },
    emailNote: {
        width: 330,
        color: DecidaColors.Black,
        fontSize: 15,
        textAlign: 'center',
    },


})