import { gql, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Dimensions, StyleSheet, View } from 'react-native';

import { rvFilterSwitchesData } from '../../../common/common-state';
import { getAllVideosPublic } from '../../../common/helper-api-functions';
import InputSearchContent from '../../../common/input-search-content';
import { rvIsLoading } from '../../../common/loading';
import SwitchCardFilter from '../../../common/switch-card-filter';
import { SwitchCardFilterMethods } from '../../../common/switch-card-filter-methods';
import SwitchCardNoResultFilter from '../../../common/switch-card-no-result-filter';
import { useResponsive } from '../../../common/use-responsive';
import VideoCard from '../../../common/video-card';
import { createVideos, deleteVideos, updateVideos } from '../../../../common/graphql/mutations';
import { rvParentPortal } from '../../../login/login-state';
import { Videos as ModelVideos } from '../../../models';
import { CardColorScheme } from '../../common/card-color-scheme';
import { CardFilterEmotionType, CardIntensitylevelType, CardTemplate, CardTimeType, CardType } from '../../common/card-template';
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold';
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic';
import { VideoLibraryIcon } from '../icons/video-library-icon';
import VideoLibraryIconIosHeader from '../icons/video-library-icon-ios-header';
import { CreateVideosMutation, CreateVideosMutationVariables, DeleteVideosMutation, DeleteVideosMutationVariables, ListVideosQuery, ListVideosQueryVariables, UpdateVideosMutation, UpdateVideosMutationVariables, Videos } from '../../../../common/API';
import { listVideos } from '../../../../common/graphql/queries';
import { asapPromiseQueue } from '../../../../common/asap-promise-queue';
import { isDefined } from '../../../../common/is-defined';

export enum CardVideoTimeTypes {
    Short = "Less than 5 minutes",
    Medium = "5-15 minutes",
    Long = "More than 15 minutes"
}

export enum CardVideoAgeGroupTypes {
    Adult = "Adult",
    EarlyYears = "Early years",
    PreTeen = "Pre-teen",
    Teen = "Teen"
}

export enum CardVideoFilterEmotionType {
    Angry = "Angry",
    Anxious = "Anxious",
    Excited = "Excited",
    Happy = "Happy",
    Sad = "Sad",
    Scared = "Scared",
    SocialSkills = "Social skills",
    ManagingEmotions = "Managing emotions",
    Feelings = "Understanding emotions and feelings"
}

const windowWidth = Dimensions.get('window').width;

type VideoTemplate = Omit<Videos, "id" | "__typename" | "_version" | "_lastChangedAt" | 'time' | 'level' | 'emotion' | 'ageGroup'> & {
    time: CardVideoTimeTypes[],
    level: CardIntensitylevelType[],
    emotion: CardVideoFilterEmotionType[],
    ageGroup: CardVideoAgeGroupTypes[],
}

const ALL_VIDEOS: VideoTemplate[] = [
    // OLD VIDEOS
    {
        title: "Box breathing",
        url: "https://www.youtube.com/embed/tEmt1Znux58",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "How to Draw for Beginners",
        url: "https://www.youtube.com/embed/Wz6DrQeQ5rI",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "Kindness Video: The World We Make",
        url: "https://www.youtube.com/embed/4AKNW9fGLig",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "How to Listen Actively",
        url: "https://www.youtube.com/embed/0nmJW_zExk0",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.SocialSkills, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Have Courage and Be Fearless",
        url: "https://www.youtube.com/embed/o1CxKPrK5GY",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Scared, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },

    // // NEW VIDEOS OCT 2022
    {
        title: "All About Anger",
        url: "https://www.youtube.com/embed/8_FMxPo4xDM",
        createdAt: moment('2022-10-30').toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Angry],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "Anxiety Explained",
        url: "https://www.youtube.com/embed/eD1wliuHxHI",
        createdAt: moment('2022-10-30').toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Anxious],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "Stress Management Tips for Kids and Teens!",
        url: "https://www.youtube.com/embed/3Nf2Pzcketg",
        createdAt: moment('2022-10-30').toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Emotions for Kids - Happiness, Sadness, Fear, Anger, Disgust and Surprise",
        url: "https://www.youtube.com/embed/jetoWelJJJk",
        createdAt: moment('2022-10-30').toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Scared],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },


    //      NEW VIDEOS JAN 2023
    //      Level 1 Scared
    {
        title: "When i am feeling scared",
        url: "https://www.youtube.com/embed/8V3wrq-HciU",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Scared],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },

    // //      Level 1 Happy
    {
        title: "When i am feeling happy",
        url: "https://www.youtube.com/embed/XX-Cpb7trrI",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Happy],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },

    // //      Level 1 Angry
    {
        title: "Everyone Feels Angry Sometimes",
        url: "https://www.youtube.com/embed/8gf7OFp6fvM",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Angry],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },

    // //      Level 1 Sad

    // //      Level 1 Excited        
    {
        title: "Feeling excited",
        url: "https://www.youtube.com/embed/QiWLwge7-Ek",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Excited, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },

    // //      Level 1 Anxiety
    {
        title: "Everyone Feels Anxious Sometimes",
        url: "https://www.youtube.com/embed/b1NrVG-u17Q",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Anxious],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },

    // // // NEW Videos 18 Jan
    {
        title: "Sad | The Feels: A Story About You and Your Feelings",
        url: "https://www.youtube.com/embed/_doNjhtvgps",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Sad],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "Kids Describe Love to an Illustrator",
        url: "https://www.youtube.com/embed/I0_T-Sb-Loc",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three, CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Happy, CardVideoFilterEmotionType.Excited, CardVideoFilterEmotionType.Scared],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Belly Breathing",
        url: "https://www.youtube.com/embed/RiMb2Bw4Ae8",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "Baloon Breaths/Belly Breathing",
        url: "https://www.youtube.com/embed/2PcCmxEW5WA",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.Angry, CardVideoFilterEmotionType.Scared],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "What Scares Kids",
        url: "https://www.youtube.com/embed/Aq7gFfvhrAM",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Scared],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "Why do we have feelings?",
        url: "https://www.youtube.com/embed/3fTRWpf-eH4",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Feelings],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },

    // // NEW Videos 25 Jan
    {
        title: "Growth Mindset: How to Overcome Fear of Failure",
        url: "https://www.youtube.com/embed/DsHudsFCico",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Feelings, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Emotional Intelligence",
        url: "https://www.youtube.com/embed/otv3OvN2I8Q",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Feelings],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Alternate Nostril Breathing",
        url: "https://www.youtube.com/embed/G8xIEzX40bA",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "How to Control Anger with Deep Breathing",
        url: "https://www.youtube.com/embed/jq7LO_GTzVg",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Angry, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Imaginative Solutions for Stress Relief",
        url: "https://www.youtube.com/embed/FOQKMiD5QJI",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "How to be a more creative person | Bill Stainton | TEDxStanleyPark",
        url: "https://www.youtube.com/embed/kshboJxmU7k",
        createdAt: moment().toISOString(),
        time: [],
        level: [],
        emotion: [],
        ageGroup: []
    },

    // // NEW Videos 2 Feb 2023
    {
        title: `Sad Scene from Pixar's Movie "Up"`,
        url: "https://www.youtube.com/embed/F2bk_9T482g",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three, CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Sad],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "Sesame Street: Zach Braff and Telly are Anxious",
        url: "https://www.youtube.com/embed/TDFEW3eAIlg",
        createdAt: moment().toISOString(),
        time: [],
        level: [],
        emotion: [],
        ageGroup: []
    },
    {
        title: "Guess the Feelings and Emotions",
        url: "https://www.youtube.com/embed/MeNY-RxDJig",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Feelings],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Daniel Goleman Introduces Emotional Intelligence",
        url: "https://www.youtube.com/embed/Y7m9eNoB3NU",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Feelings],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },

    // // Updated video tag manually 13 Mar
    {
        title: "Draw your future | Patti Dobrowolski",
        url: "https://www.youtube.com/embed/zESeeaFDVSw",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Who inspires you? Why heroes, role models, and mentors matter | Dyan deNapoli",
        url: "https://www.youtube.com/embed/kZfEFzf4Jec",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Active listening",
        url: "https://www.youtube.com/embed/rzsVh8YwZEQ",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "A Little Spot of Sadness",
        url: "https://www.youtube.com/embed/o8nm__RT7XM",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Sad],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "The Lion Inside - Book Read Aloud",
        url: "https://www.youtube.com/embed/H019G18irW0",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Scared],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "What Does it Mean to Be Thankful?",
        url: "https://www.youtube.com/embed/PcVQGoalfnk",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "5 Things About Grief No One Really Tells You",
        url: "https://www.youtube.com/embed/8pT6LQ-mZ3k",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Sad],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "What is anxiety?",
        url: "https://www.youtube.com/embed/NYzowu-EaPY",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.Anxious],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "Why Do I Feel So Sad? - Book Read Aloud",
        url: "https://www.youtube.com/embed/AJnSSsvXX9I",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Sad],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "Feeling Scared! |  Book read aloud ",
        url: "https://www.youtube.com/embed/zez9ah1AIFw",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Scared],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "How To Tame My Anxiety Monster",
        url: "https://www.youtube.com/embed/JP6qNv9Gxq8",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "What is Anxiety & Why Do We Feel Anxious?",
        url: "https://www.youtube.com/embed/Z3t7dUyDFe0",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Children Explain How to Make Friends",
        url: "https://www.youtube.com/embed/6qu2cQnULjE",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "Two Easily Remembered Questions That Silence Negative Thoughts",
        url: "https://www.youtube.com/embed/kvtYjdriSpM",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "When i am feeling sad",
        url: "https://www.youtube.com/embed/_vi7sL_dmVQ",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Sad],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },

    // NEW Videos 15 Mar 2023 (should update with script )
    {
        title: "Circle of Influence",
        url: "https://www.youtube.com/embed/1wptGvREOj8",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "How To Know Yourself",
        url: "https://www.youtube.com/embed/4lTbWQ8zD3w",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "How to stop feeling anxious about anxiety",
        url: "https://www.youtube.com/embed/ZidGozDhOjg",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "What is Identity?",
        url: "https://www.youtube.com/embed/V8mFpqt753M",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "The Neuroscience of Creativity",
        url: "https://www.youtube.com/embed/9CwyqtwtMHM",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "The Secret to Creativity",
        url: "https://www.youtube.com/embed/X_Y-T_guM1I",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Feelings],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Understanding mindfulness and meditation",
        url: "https://www.youtube.com/embed/LDVyOnf0t9M",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Seven],
        emotion: [],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "How To Cope With Depression",
        url: "https://www.youtube.com/embed/8Su5VtKeXU8",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Sad, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "The happy secret to better work | Shawn Achor",
        url: "https://www.youtube.com/embed/fLJsdqxnZb0",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.Happy, CardVideoFilterEmotionType.Anxious],
        ageGroup: [CardVideoAgeGroupTypes.Teen]
    },

    // Video tag updates 17 Mar
    {
        title: "What Makes Kids Happy",
        url: "https://www.youtube.com/embed/1Y5R8K7OKSo",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Happy],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "The fight, flight and freeze responses",
        url: "https://www.youtube.com/embed/8do0Jiscgsk",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Anxious],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Gratitude Meditation for Kids",
        url: "https://www.youtube.com/embed/_qpxWpGH11Q",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three, CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.Happy, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "How to make a stress ball",
        url: "https://www.youtube.com/embed/gHOp22_Sjqg",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Limerick Poems",
        url: "https://www.youtube.com/embed/Wy65TkCadfU",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Three],
        emotion: [],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Today I Feel Excited - Book Read Aloud",
        url: "https://www.youtube.com/embed/0oFlCeyAkJo",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Excited],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "The Marshmallow Test | Igniter Media | Church Video",
        url: "https://www.youtube.com/embed/QX_oy9614HQ",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Feelings, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "The reframing switch",
        url: "https://www.youtube.com/embed/0fSKRezcUmc",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "The Science of Being Scared",
        url: "https://www.youtube.com/embed/_-E-QDkbdE8",
        createdAt: moment('2022-10-30').toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Scared],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Wellbeing For Children: Identity And Values",
        url: "https://www.youtube.com/embed/om3INBWfoxY",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "What is Acceptance Commitment Therapy?",
        url: "https://www.youtube.com/embed/ScwXgqO_d7Y",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Feelings, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "What is Fear and Why You Feel Scared",
        url: "https://www.youtube.com/embed/7CBmKdip6ws",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Scared],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "What Is Learned Helplessness and Why Does it Happen?",
        url: "https://www.youtube.com/embed/C3a65C4kT-8",
        createdAt: moment('2022-10-01').toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Sad],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "What Shall We Do With The Angry Monster?",
        url: "https://www.youtube.com/embed/bs_0m-CUEPQ",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Angry],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears],
        _deleted: true
    },
    {
        title: "Why Do We Get Angry?",
        url: "https://www.youtube.com/embed/clwt7iXF1Mg",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Angry],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "What creates happiness",
        url: "https://www.youtube.com/embed/Ft5F-GC8yy4",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Happy],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },

    // New video 6 Jul 23
    {
        title: "Auslan feelings",
        url: "https://www.youtube.com/embed/Sivyv4zSLQo",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three, CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Angry, CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.Excited, CardVideoFilterEmotionType.Feelings, CardVideoFilterEmotionType.Happy, CardVideoFilterEmotionType.Sad, CardVideoFilterEmotionType.Scared],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "What is EMPATHY?",
        url: "https://www.youtube.com/embed/27sho6s2eK8",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Feelings],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "Empathy can change the world",
        url: "https://www.youtube.com/embed/aU3QfyqvHk8",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },

    // New video 17 Jul 23
    {
        title: "Dan Siegel's Hand Model of the Brain, Flip Your Lid",
        url: "https://www.youtube.com/embed/FTnCMxEnnv8",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three, CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.Feelings, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "The Brain When You're Happy | Neuro Champions",
        url: "https://www.youtube.com/embed/nioadOr04dY",
        createdAt: moment().toISOString(),
        time: [],
        level: [],
        emotion: [],
        ageGroup: []
    },
    {
        title: "Perspective Taking",
        url: "https://www.youtube.com/embed/tqz7UcCgbLA",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "Stand in my Shoes by Bob Sornson",
        url: "https://www.youtube.com/embed/e_Nkb3vL0pQ",
        createdAt: moment().toISOString(),
        time: [],
        level: [],
        emotion: [],
        ageGroup: []
    },
    {
        title: "Communicate assertively",
        url: "https://www.youtube.com/embed/AJ-e5Q4vhsk",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "Visual example of the 5 different Conflict Resolutions Styles",
        url: "https://www.youtube.com/embed/qiqbmuXAc0g",
        createdAt: moment().toISOString(),
        time: [],
        level: [],
        emotion: [],
        ageGroup: []
    },
    {
        title: "Conflict Resolution: How to Settle Your Differences Fairly",
        url: "https://www.youtube.com/embed/jg_Q34kGsKg",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.Angry, CardVideoFilterEmotionType.ManagingEmotions, CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "Fight Flight Freeze – Anxiety Explained",
        url: "https://www.youtube.com/embed/rpolpKTWrp4",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Anxious],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "The Art of Listening | Simon Sinek",
        url: "https://www.youtube.com/embed/qpnNsSyDw-g",
        createdAt: moment().toISOString(),
        time: [],
        level: [],
        emotion: [],
        ageGroup: []
    },
    {
        title: "Are you okay? | Short Film",
        url: "https://www.youtube.com/embed/tJsGGsPNakw",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.Sad, CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.Feelings, CardVideoFilterEmotionType.SocialSkills, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "A Wise Lesson in Empathy",
        url: "https://www.youtube.com/embed/gYH0D52fXe8",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Conflict Management (animated)",
        url: "https://www.youtube.com/embed/eT7lt4ESSLo",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three, CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.Angry],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "Conflict styles",
        url: "https://www.youtube.com/embed/pUI9-MwCmGM",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Conflict – Use It, Don’t Defuse It",
        url: "https://www.youtube.com/embed/o97fVGTjE4w",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Angry, CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },

    // New video 3 Aug 23
    {
        title: "Intro to Positive Psychology",
        url: "https://embed.ted.com/talks/shawn_achor_the_happy_secret_to_better_work",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Happy, CardVideoFilterEmotionType.Anxious],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "How to cope with your anxiety",
        url: "https://www.youtube.com/embed/WWloIAQpMcQ",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Dealing with anxiety",
        url: "https://player.vimeo.com/video/343194507",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "What is Anxiety Disorder?",
        url: "https://player.vimeo.com/video/787093204",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.Anxious],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "What is genuine happiness?",
        url: "https://player.vimeo.com/video/457880982",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.Happy],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "The Present",
        url: "https://player.vimeo.com/video/152985022",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.Sad, CardVideoFilterEmotionType.Feelings],
        ageGroup: [CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "When You Feel So Mad That You Want to Roar",
        url: "https://www.pbslearningmedia.org/resource/ce65aeaa-ddd2-45dd-908e-6abd84ef2cb2/ce65aeaa-ddd2-45dd-908e-6abd84ef2cb2/?utm_source=email&utm_medium=email&utm_content=2023-05-18&utm_campaign=mentalhealth_2023",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Angry, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears],
        isExternal: true,
        thumbnailUrl: "https://image.pbs.org/poster_images/assets/104B_Katerina_Gets_Mad-4.jpg"
    },
    {
        title: "Does Smiling Make You Happy?",
        url: "https://www.pbslearningmedia.org/resource/happiness-joy-smiling-video-braincraft-1039/does-smiling-make-you-happy-braincraft/",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Happy, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult],
        isExternal: true,
        thumbnailUrl: 'https://image.pbs.org/poster_images/assets/BRAN20Smiling_Thumb.jpg'
    },
    {
        title: "Feeling Faces",
        url: "https://www.pbslearningmedia.org/resource/hhk-feeling-faces/happy-healthy-kids-feeling-faces/",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Feelings],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears],
        isExternal: true,
        thumbnailUrl: "https://image.pbs.org/poster_images/assets/50FHHK17001_2.jpg"
    },
    {
        title: "Managing Intense Emotions",
        url: "https://www.pbslearningmedia.org/resource/hmtk-managing-intense-emotions-middle-school-video/healthy-minds-thriving-kids/",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult],
        isExternal: true,
        thumbnailUrl: "https://image.pbs.org/poster_images/assets/media/MIDDLE_SCHOOL4.jpg"
    },
    {
        title: "Happiness",
        url: "https://www.pbslearningmedia.org/resource/lpsc10.sci.life.happy/happiness/",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Happy],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears],
        isExternal: true,
        thumbnailUrl: "https://image.pbs.org/poster_images/assets/wgbh/lpsc10/lpsc10_vid_happy/lpsc10_vid_happy_l.jpg"
    },
    {
        title: "Separation | Daniel Tiger",
        url: "https://www.pbslearningmedia.org/resource/separation-daniel-tiger-lifes-little-lesson/separation-daniel-tiger-lifes-little-lesson/",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Anxious],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears],
        isExternal: true,
        thumbnailUrl: "https://image.pbs.org/poster_images/assets/Daniel_Goes_to_School_Poster.jpg"
    },
    {
        title: "Finding hope (playlist)",
        url: "https://youtube.com/playlist?list=PL7rYappSqhGBtQ26PVd11adtmo4CEalW7",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Sad],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult],
        isExternal: true,
        youtubePlaylistThumbnailUrlVideoId: '7SaRzt1USVY'
    },
    {
        title: "Boost self esteem (playlist)",
        url: "https://youtube.com/playlist?list=PL7rYappSqhGBqa09Q3URrElHax54uA-DQ",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult],
        isExternal: true,
        youtubePlaylistThumbnailUrlVideoId: '-Yi6W6uitEs'
    },
    {
        title: "Dealing with change (playlist)",
        url: "https://youtube.com/playlist?list=PL7rYappSqhGD_3JDTxTgMoYWPGuwZYsqp",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.Sad, CardVideoFilterEmotionType.Angry, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult],
        isExternal: true,
        youtubePlaylistThumbnailUrlVideoId: 'Fgn2omugORE'
    },
    {
        title: "Reduce stress (playlist)",
        url: "https://youtube.com/playlist?list=PL7rYappSqhGBg9SV46eh1z5hdsj9fkvrM",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult],
        isExternal: true,
        youtubePlaylistThumbnailUrlVideoId: '5jHbAAvGGrw'
    },
    {
        title: "Psychological self-care (playlist)",
        url: "https://youtube.com/playlist?list=PL7rYappSqhGC_TFCNKgKkbvfO801NnNX9",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult],
        isExternal: true,
        youtubePlaylistThumbnailUrlVideoId: '1R_6A80cv-g'
    },
    {
        title: "Managing conflict (playlist)",
        url: "https://youtube.com/playlist?list=PL7rYappSqhGDWppqZ1wyF3z0hjSr7xN5E",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Angry, CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult],
        isExternal: true,
        youtubePlaylistThumbnailUrlVideoId: '2AfcMb-35ik'
    },
    {
        title: "How to be happy (playlist)",
        url: "https://youtube.com/playlist?list=PL7rYappSqhGDZKqpCyBdL0iUoQX_TwthB",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Happy, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult],
        isExternal: true,
        youtubePlaylistThumbnailUrlVideoId: 'mLEOz_BYyvo'
    },
    {
        title: "Dealing with grief and loss (playlist)",
        url: "https://youtube.com/playlist?list=PL7rYappSqhGAzSZ8Jj8K7wDj5TeW1E4gf",
        createdAt: moment().toISOString(),
        time: [],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Sad],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult],
        isExternal: true,
        youtubePlaylistThumbnailUrlVideoId: 'vWwTzipIavE'
    },
    {
        title: "Manage negative emotions (playlist)",
        url: "https://youtube.com/playlist?list=PL7rYappSqhGAya41EhqLs46KYyMnxfLRT",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.Sad, CardVideoFilterEmotionType.Scared, CardVideoFilterEmotionType.Angry, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult],
        isExternal: true,
        youtubePlaylistThumbnailUrlVideoId: 'yR_IcPHt7Gw'
    },
    {
        title: "Sleep better (playlist)",
        url: "https://youtube.com/playlist?list=PL7rYappSqhGBj4haatMtXwxc8Aqj-3LAZ",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult],
        isExternal: true,
        youtubePlaylistThumbnailUrlVideoId: '_SYbWCwLrK0'
    },
    {
        title: "Anxiety (playlist)",
        url: "https://youtube.com/playlist?list=PL7rYappSqhGDMsMdbF_0TPbTsnWExup-H",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult],
        isExternal: true,
        youtubePlaylistThumbnailUrlVideoId: 'MnUgMJJ1CK0'
    },

    // new video 10 aug
    {
        title: "The psychology behind irrational decisions",
        url: "https://embed.ted.com/talks/lang/en/sara_garofalo_the_psychology_behind_irrational_decisions",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Feelings],
        ageGroup: [CardVideoAgeGroupTypes.Adult],
        isExternal: true
    },
    {
        title: "The Neuroscience of Decision-Making",
        url: "https://www.youtube.com/embed/aCWYkZ5i-gE",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Feelings],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult],
        isExternal: true
    },
    {
        title: "Nervous vs. Excited",
        url: "https://www.youtube.com/embed/0SUTInEaQ3Q",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.Excited, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Adult],
        isExternal: true
    },
    {
        title: "How to Make Friends",
        url: "https://www.youtube.com/embed/Q0F3j-sAg_U",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Happy, CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears],
        isExternal: true
    },
    {
        title: "Wall Push Ups",
        url: "https://www.youtube.com/embed/r4oZ-EHcSy4",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears],
        isExternal: true
    },
    {
        title: "Are there universal expressions of emotion?",
        url: "https://www.youtube.com/embed/-hr58Yu0yDs",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Feelings],
        ageGroup: [CardVideoAgeGroupTypes.Adult],
        isExternal: true
    },

    // new video 5 feb 2024
    {
        title: "Closed vs. Open Questions",
        url: "https://www.youtube.com/embed/OJZH-raxGhg",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Beautiful Relaxing Music",
        url: "https://www.youtube.com/embed/_kT38XB1YHo",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three, CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Guided Body Scan Meditation",
        url: "https://www.youtube.com/embed/BlWo7sqWLNk",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },


    // new video 9 feb 2024
    {
        title: "Emotions and Decision Making",
        url: "https://www.youtube.com/embed/n0zV2ytrd7w",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Seven],
        emotion: [],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },

    // new video 13 feb 2024
    {
        title: "Different types of hugs",
        url: "https://www.youtube.com/embed/njkm4700qm8",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Seven, CardIntensitylevelType.Three, CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.Happy],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult, CardVideoAgeGroupTypes.PreTeen]
    },

    // new video 11 Mar 2024
    {
        title: "Art & Emotions",
        url: "https://www.youtube.com/embed/T5vFBHI9kXc",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Angry, CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.Excited, CardVideoFilterEmotionType.Happy, CardVideoFilterEmotionType.Sad, CardVideoFilterEmotionType.Scared, CardVideoFilterEmotionType.Feelings, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Exercies for bilateral integration",
        url: "https://www.youtube.com/embed/Ivc8TXkLHKs",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "Meditation Music",
        url: "https://www.youtube.com/embed/AImuCtIokl0",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Emotions for Kids",
        url: "https://www.youtube.com/embed/pvdqbQdB5ho",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Angry, CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.Excited, CardVideoFilterEmotionType.Happy, CardVideoFilterEmotionType.Sad, CardVideoFilterEmotionType.Scared, CardVideoFilterEmotionType.Feelings],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Angry Little Alex",
        url: "https://www.youtube.com/embed/m0ERyIzjQmc",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Angry, CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Anger Management for Kids",
        url: "https://www.youtube.com/embed/DbpTohPUhMw",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Angry, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Anger Management Techniques for Kids",
        url: "https://www.youtube.com/embed/lxxpDF45TPA",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Angry, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "What is Excited",
        url: "https://www.youtube.com/embed/hUNQkuhyEgY",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Excited],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "When I am Feeling Excited",
        url: "https://www.youtube.com/embed/q2-TYH2dJR8",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.Excited, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "Exciting Adventure",
        url: "https://www.youtube.com/embed/Fk7A-pd82Kg",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Excited],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Excited Dog in Car Can't Stop Howling",
        url: "https://www.youtube.com/embed/adgAxnflvOE",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three, CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Excited],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Hear Me",
        url: "https://www.youtube.com/embed/q1kvp0nAgKA",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Scared, CardVideoFilterEmotionType.ManagingEmotions, CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Anxiety Vs Excitement",
        url: "https://www.youtube.com/embed/cRzSQ5IYqyI",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.Excited],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Sadness for Kids",
        url: "https://www.youtube.com/embed/HoQW6HkfCkU",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Sad, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Sadness and Regulation Techniques",
        url: "https://www.youtube.com/embed/mybYPf2YBtQ",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.Sad, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "Scared and Regulation Techniques",
        url: "https://www.youtube.com/embed/jGyh0yD-DUc",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
        emotion: [CardVideoFilterEmotionType.Scared, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    },
    {
        title: "What is Depression?",
        url: "https://www.youtube.com/embed/z-IR48Mb3W0",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Sad],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "6 Differences Between Sadness and Depression",
        url: "https://www.youtube.com/embed/tNwRNmFT7-4",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Sad],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Everyone Feels Sad Sometimes...Read Out Loud",
        url: "https://www.youtube.com/watch?v=cFt4tGBDj8s",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Sad, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen],
        thumbnailUrl: 'https://img.youtube.com/vi/cFt4tGBDj8s/0.jpg',
        isExternal: true
    },
    {
        title: "Worry Monsters",
        url: "https://www.youtube.com/embed/3176R0bXjyE",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Anxious, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Know Your Emotions",
        url: "https://www.youtube.com/embed/cKQIOVjxmfs",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Feelings],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "What is Happiness",
        url: "https://www.youtube.com/embed/_hw9_xPsMQk",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Happy],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "The Anatomy of Happiness",
        url: "https://www.youtube.com/embed/7O6zhlldy_U",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Happy],
        ageGroup: [CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "The Science of Wellbeing for Teens, with Dr. Laurie Santos (Playlist)",
        url: "https://www.youtube.com/embed/TwGEcaoTQRk",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Happy, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Fears vs. Phobias: What's the Difference?",
        url: "https://www.youtube.com/embed/Ne6dhJ1_cG8",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
        emotion: [CardVideoFilterEmotionType.Scared],
        ageGroup: [CardVideoAgeGroupTypes.Teen, CardVideoAgeGroupTypes.Adult]
    },
    {
        title: "Inside Out - Best Scenes",
        url: "https://www.youtube.com/embed/MjOFYKKIGwo",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Feelings],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Feeling and Emotion",
        url: "https://www.youtube.com/embed/ZJAEkDXtyQQ",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.Feelings, CardVideoFilterEmotionType.ManagingEmotions],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "All About Social Skill for Kids!",
        url: "https://www.youtube.com/embed/Myf2CUx9E60",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Medium],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.PreTeen]
    },
    {
        title: "Be Nice with Friends!",
        url: "https://www.youtube.com/embed/SDUC9iZzrlo",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.One],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    {
        title: "The Importance of Friendship",
        url: "https://www.youtube.com/embed/P8zwIgU9d8E",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.Three],
        emotion: [CardVideoFilterEmotionType.SocialSkills],
        ageGroup: [CardVideoAgeGroupTypes.PreTeen]
    },
    
    // new video 2 july
    {
        title: 'Exercies for bilateral integration',
        url: "https://www.youtube.com/watch?v=Ivc8TXkLHKs&ab_channel=OTCloset",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Short],
        level: [CardIntensitylevelType.One],
        emotion: [],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears]
    },
    
    // new video 5 july
    {
        title: "Beautiful Relaxing Music",
        url: "https://www.youtube.com/watch?v=_kT38XB1YHo",
        createdAt: moment().toISOString(),
        time: [CardVideoTimeTypes.Long],
        level: [CardIntensitylevelType.One, CardIntensitylevelType.Seven, CardIntensitylevelType.Three, CardIntensitylevelType.One],
        emotion: [],
        ageGroup: [CardVideoAgeGroupTypes.EarlyYears, CardVideoAgeGroupTypes.Adult, CardVideoAgeGroupTypes.PreTeen, CardVideoAgeGroupTypes.Teen]
    }
]


export const ToolsVideoLibrary: CardTemplate = {
    cardId: "86303abb-18ed-4b96-9d83-bbad7cb2fa7b",
    title: 'Video Library',
    Header: VideoLibraryIconIosHeader,
    Icon: VideoLibraryIcon,
    HeaderTitle: 'Video Library',
    noRating: true,
    mainContainerStyle: {
        maxWidth: '100%',
        paddingHorizontal: 20,
    },
    PerfectFor: () => {
        const { width, height, isLargeScreen } = useResponsive()
        const [allVideos, setAllVideos] = useState<Videos[]>([])
        const [filteredVideos, setFilteredVideos] = useState<Videos[]>([])
        const [title, setTitle] = useState('')
        const isGuest = useReactiveVar(rvParentPortal)
        const filters = useReactiveVar(rvFilterSwitchesData)
        const isAnyFilterApplied = SwitchCardFilterMethods.isAnyFilterApplied(filters)

        const [getAllVideosLazyQuery] = useLazyQuery<ListVideosQuery, ListVideosQueryVariables>(gql`${listVideos}`,)
        const [updateVideoMutation] = useMutation<UpdateVideosMutation, UpdateVideosMutationVariables>(gql`${updateVideos}`,)
        const [deleteVideoMutation] = useMutation<DeleteVideosMutation, DeleteVideosMutationVariables>(gql`${deleteVideos}`,)
        const [createVideoMutation] = useMutation<CreateVideosMutation, CreateVideosMutationVariables>(gql`${createVideos}`,)

        useEffect(() => {
            fetchData()

            return () => {
                // clear filter when navigates away
                SwitchCardFilterMethods.clearFilter()
            }
        }, [])

        const fetchData = async () => {
            SwitchCardFilterMethods.clearFilter()
            const response: Videos[] = isGuest ? await getAllVideosPublic() : (await getAllVideosLazyQuery({ variables: { limit: 5000 } })).data?.listVideos?.items
            setAllVideos(response.filter(isDefined).filter((v) => v._deleted !== true))
        }

        let currentVideos = allVideos

        currentVideos = isAnyFilterApplied ? SwitchCardFilterMethods.getFilteredVideos(currentVideos, filters) : allVideos
        currentVideos = currentVideos.filter((video) => video?.title?.toLowerCase().indexOf(title.toLowerCase()) != -1)

        return (
            <>
                {/* <View>
                    <Button title='Upload script' onPress={async () => {
                        rvIsLoading(true)

                        // for (const vid of allVideos) {
                        //     if (!vid.time) {
                        //         console.log({ url: vid.url, time: vid.time, emotion: vid.emotion, level: vid.level, ageGroup: vid.ageGroup })
                        //     }
                        // }
                        console.log('ALL_VIDEOS.length: ' + ALL_VIDEOS.length)
                        await asapPromiseQueue(ALL_VIDEOS, async (video: VideoTemplate) => {
                            const existingData = allVideos.find((videoDB) => videoDB.url === video.url)
                            if (existingData) {

                                if (video._deleted) {
                                    console.log("DELETING DATA", { existingData })
                                    await deleteVideoMutation({
                                        variables: {
                                            input: {
                                                id: existingData.id,
                                                _version: existingData._version,
                                            }
                                        }
                                    })

                                } else {
                                    console.log({ existingData })
                                    // if video exists only update the filters tag data
                                    await updateVideoMutation({
                                        variables: {
                                            input: {
                                                id: existingData.id,
                                                title: video.title,
                                                time: video.time,
                                                level: video.level,
                                                emotion: video.emotion,
                                                ageGroup: video.ageGroup,
                                                isExternal: video.isExternal,
                                                youtubePlaylistThumbnailUrlVideoId: video.youtubePlaylistThumbnailUrlVideoId,
                                                thumbnailUrl: video.thumbnailUrl,
                                                _version: existingData._version
                                            }
                                        }
                                    })
                                }


                            } else {
                                console.log("CREATE EW", video.title)

                                await createVideoMutation({
                                    variables: {
                                        input: {
                                            url: video.url,
                                            title: video.title,
                                            createdAt: video?.createdAt ? video?.createdAt : moment('2022-08-08').toISOString(),
                                            time: video.time,
                                            level: video.level,
                                            emotion: video.emotion,
                                            ageGroup: video.ageGroup,
                                            isExternal: video.isExternal,
                                            youtubePlaylistThumbnailUrlVideoId: video.youtubePlaylistThumbnailUrlVideoId,
                                            thumbnailUrl: video.thumbnailUrl
                                        }
                                    }
                                })
                            }
                        }, 10)
                        console.log('done')
                        rvIsLoading(false)
                    }} />
                </View> */}
                <View style={[styles.mainContainer, width < 600 ? { width: '100%' } : { paddingBottom: "10%", flex: 1, }]}>
                    <InputSearchContent title={title} onChangeText={setTitle} placeholder='Search videos...' />
                    {isAnyFilterApplied && !currentVideos.length && (
                        <SwitchCardNoResultFilter />
                    )}
                    {_.orderBy(currentVideos, [video => video.title?.toLowerCase()]).map((video: Videos, index) => {
                        return (
                            <VideoCard key={video.url} video={video} titleStyle={styles.videoTitleStyle} />
                        )
                    })}
                </View>
            </>

        )
    },

    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>

        </>
    ),
    colorScheme: CardColorScheme.TOOL,
    whyDoesItWorkText: '',
    type: [CardType.Video, CardType.Tool],
    time: [],
    people: [],
    place: [],
    category: [],
    action: [],
    level: [],
    emotion: [],
    FilterComponent: () => {
        return <SwitchCardFilter filterType='video' />
    }
}

const styles = StyleSheet.create({
    videoTitleStyle: {
        paddingHorizontal: 20,
        textAlign: 'center',
        width: '100%',
    },
    videoContainer: {
        marginRight: 10,
        marginBottom: 20,
    },
    mainContainer: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20,
    },
    newIcon: {
        position: 'absolute',
        right: 0, top: 0,
        borderRadius: 10,
        overflow: 'hidden',
        alignSelf: 'center'
    }
})