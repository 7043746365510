import { StyleSheet, View } from 'react-native'
import { FC } from 'react'
import { CheckBox } from './checkbox'
import { DefaultText } from './default-text'
import { DecidaColors } from '../../common/decida-colors'
import { FontAwesome } from '@expo/vector-icons';

type Props = {
    visible: boolean
    checkstate: boolean
    setCheckState: (data: boolean) => void
}
export const TeacherStatsSortFilter: FC<Props> = ({ visible, checkstate, setCheckState }) => {
    const clickCheckBox = () => {
        setCheckState(!checkstate)
    }
    return (
        <View style={styles.container}>
            <CheckBox checked={checkstate} label={"Reverse check in order"} onChange={clickCheckBox} containerStyle={visible ? {} : { display: 'none' }} />
            <View style={visible ? styles.row : { display: 'none' }}>
                {!checkstate ?
                    <>
                        <DefaultText style={styles.arrowText}>
                            Oldest
                        </DefaultText>
                        <FontAwesome name="long-arrow-left" size={40} color={DecidaColors.Green} />
                        <FontAwesome name="long-arrow-right" size={40} color={DecidaColors.Green} />
                        <DefaultText style={styles.arrowText}>
                            Latest
                        </DefaultText>
                    </>
                    : <>
                        <DefaultText style={styles.arrowText}>
                            Latest
                        </DefaultText>
                        <FontAwesome name="long-arrow-left" size={40} color={DecidaColors.Green} />
                        <FontAwesome name="long-arrow-right" size={40} color={DecidaColors.Green} />
                        <DefaultText style={styles.arrowText}>
                            Oldest
                        </DefaultText>
                    </>
                }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    row: {
        alignItems: 'center',
        flexDirection: 'row',
    },
    arrowText: {
        fontSize: 14,
        paddingHorizontal: 20,
    }
})