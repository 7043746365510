import { View } from 'react-native'
import { DefaultTextWithDownArrow } from '../../../common/default-text-with-down-arrow'
import { commonCardStyles, commonStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { ColouringInIcon } from '../../icons/colouring-in-icon'
import { ColouringInImage } from './src/colouring-in-image'
import { ColouringInImage2 } from './src/colouring-in-image2'
import { ColouringInImage3 } from './src/colouring-in-image3'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { ColouringInIconInverted } from '../../icons/colouring-in-icon-inverted'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'
import DefaultTextWithCustomLink from '../../../common/default-text-with-custom-link'
import { DefaultText } from '../../../../common/default-text'

export const SwitchesActivitiesHealthyHabitsColouringIn: CardTemplate = {
    cardId: "0d2cd98c-50a5-4045-8ebc-cba20c916842",
    title: 'Colouring in',
    Header: ColouringInIconInverted,
    Icon: ColouringInIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Helping process complex emotions and calming the mind. "} />
            <View style={commonStyles.row}>
                <View style={commonCardStyles.image}>
                    <ColouringInImage />
                </View>
                <View style={commonCardStyles.image}>
                    <ColouringInImage2 />
                </View>

            </View>
        </>
    ),
    Images: () => (
        <>
            <View style={commonCardStyles.image}>
                <ColouringInImage3 />
            </View>

        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={`It's not just in the completion of the exercise that is important, but the care and attention that has gone into the task. `} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultText style={{ textAlign: 'center', alignItems: 'center' }} >
                    <DefaultTextWithCustomLink style={{ paddingLeft: 0 }} text="Download" link="https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Mandala+Pictures+(1).pdf" /> one of the mandala colouring in pictures from swtich4schools.com.au, or grab a mindfulness colouring in book.
                </DefaultText>
                <DefaultTextWithDownArrow textdata='' />
                <DefaultTextWithDownArrow textdata={"Allow yourself at least 15-30 minutes to carefully colour the picture."} />
                <DefaultTextWithDownArrow textdata={"Be careful and slowly colour between the lines. You can pause this activity and come back at any time."} />


            </View>
        </>
    ),
    colorScheme: CardColorScheme.HEALTHY_HABITS,
    whyDoesItWorkText: 'Mandala drawing art therapy and meditation to colour complex geometric forms can be used to distract from negative thoughts and emotions. This colouring task is particularly helpful in the disclosure and processing of traumatic events. These patterns function as symbolic representations of emotional information, and has been shown to provide cognitive organisation to complex emotional experiences, which in turn gives rise to personal meaning.',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Colouring+in.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.HEALTHY_HABITS],
    action: [CardActionType.Action],
    level: []
}

