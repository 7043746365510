import { PropsWithChildren, useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { createStackNavigator } from '@react-navigation/stack'
import { Login } from './login'
import { ChangePassword } from './change-password'
import { rvLoggedIn, rvParentPortal, rvRedirectPages, rvUserGroup } from './login-state'
import { ForgotPasswordRequest } from './forgot-password-request'
import { ForgotPasswordSubmit } from './forgot-password-submit'
import { ScreenNames } from '../common/screen-names'
import { LoginStackNavigatorParamList } from './login-route-param-types'
import { StudentJoinClassMain } from './student-join-class-main'
import { Auth } from 'aws-amplify'
import { ParentMain } from '../parent/parent-main'
import { LoginClassLogin } from './class-login'
import { LoginMFASMS } from './login-mfa-sms'
import * as Linking from 'expo-linking'
import _ from 'lodash'
import { isUrlPublicAccess } from '../common/helper-functions'
import { handleAuth } from './login-functions'
import { webViewMessageScrollable } from '../web-view/web-view-messages'

export const REDIRECT_PAGE_KEY = "REDIRECT_PAGE_KEY"
const Stack = createStackNavigator<LoginStackNavigatorParamList>()

export const WithLogin = ({ children }: PropsWithChildren<{}>) => {
    const loggedIn = useReactiveVar(rvLoggedIn)
    const [authChecker, setAuthChecker] = useState(false)
    const parentPortal = useReactiveVar(rvParentPortal)
    const userGroup = useReactiveVar(rvUserGroup)

    const url = Linking.useURL();
    let isPublic = false

    useEffect(() => {
        // Check if user is not logged in
        if (!loggedIn) {
            // Authenticate user
            Auth.currentAuthenticatedUser()
                .then(handleAuth)
                .catch(() => {
                    if (!userGroup && !loggedIn && url) {
                        handleUnauthenticatedDeepLinks()
                    } else {
                        rvLoggedIn(false)
                        setAuthChecker(true)
                    }
                })
        }
    })

    const handleUnauthenticatedDeepLinks = () => {
        isPublic = isUrlPublicAccess(url)
        // If URL is public, and no usergroup, show alert and save the redirect pages
        if (!userGroup && url && isPublic) {
            if (isPublic) {
                rvParentPortal(true)
                rvLoggedIn(true)
                setAuthChecker(true)
            }

            rvRedirectPages(url)
        }
    }

    if (parentPortal) {
        window.ReactNativeWebView?.postMessage(webViewMessageScrollable + false)
        return (
            <ParentMain />
        )
    } else if (!loggedIn && authChecker) {
        window.ReactNativeWebView?.postMessage(webViewMessageScrollable + true)
        return (
            <Stack.Navigator screenOptions={{ gestureEnabled: false, headerShown: false }}>
                <Stack.Screen name={ScreenNames.Login} component={Login} />
                <Stack.Screen name={ScreenNames.LoginChangePassword} component={ChangePassword} />
                <Stack.Screen name={ScreenNames.LoginForgotPasswordRequest} component={ForgotPasswordRequest} options={{ gestureEnabled: true }} />
                <Stack.Screen name={ScreenNames.LoginForgotPasswordSubmit} component={ForgotPasswordSubmit} options={{ gestureEnabled: true }} />
                <Stack.Screen name={ScreenNames.LoginClassLogin} component={LoginClassLogin} options={{ gestureEnabled: true }} />
                <Stack.Screen name={ScreenNames.JoinClass} component={StudentJoinClassMain} options={{ title: 'JoinClass' }} />
                <Stack.Screen name={ScreenNames.LoginMFASMS} component={LoginMFASMS} options={{ title: 'LoginMFA' }} />
            </Stack.Navigator>
        )
    }

    window.ReactNativeWebView?.postMessage(webViewMessageScrollable + false)
    return <>{children}</>
}
