import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan, Line } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconExcited5Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 5)
    }

    const onPressLevel2 = {
        [prop]: () => onPress(2 / 5)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(3 / 5)
    }

    const onPressLevel4 = {
        [prop]: () => onPress(4 / 5)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg viewBox="0 0 812.027 546.844">
            <Line y1="134.507" x2="203.422" transform="translate(1.93 400.783)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3553.515,1214.153l-25.391,6.085,9.849,14.9Z" transform="translate(-3336.289 -821.221)" fill="#5c5d5d" />
            <Line y1="83.056" x2="125.298" transform="translate(229.448 301.501)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3702.9,1114.857l-25.384,6.114,9.866,14.884Z" transform="translate(-3336.289 -821.221)" fill="#5c5d5d" />
            <Line y1="81.938" x2="124.892" transform="translate(377.302 204.81)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3850.385,1018.222l-25.412,5.994,9.795,14.93Z" transform="translate(-3336.289 -821.221)" fill="#5c5d5d" />
            <Line y1="84.883" x2="126.706" transform="translate(525.009 104.683)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3999.83,917.981l-25.354,6.238,9.939,14.835Z" transform="translate(-3336.289 -821.221)" fill="#5c5d5d" />
            <Line y1="82.711" x2="125.289" transform="translate(674.858 7.842)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M4122.92,827.287l9.838,14.9,15.557-20.968Z" transform="translate(-3336.289 -821.221)" fill="#5c5d5d" />
            <G {...onPressLevel1} transform="translate(-46 -231)">
                <Path d="M3343.885,1367.559l208.958-.868.673-139.033Z" transform="translate(-3290.289 -590.22)" fill="#b9dbd5" />
                <Text transform="translate(127.321 761.054)" fill="#313133" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Expectant</TSpan></Text>
            </G>
            <G {...onPressLevel2} transform="translate(-46 -231)">
                <Path d="M3563.9,1221.895l138.5-91.2-.171,235.7-139.277.11Z" transform="translate(-3290.289 -590.22)" fill="#8ec4b9" />
                <Text transform="translate(307.889 713.093)" fill="#313133" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Eager</TSpan></Text>
            </G>
            <G {...onPressLevel3} transform="translate(-46 -231)">
                <Path d="M3712.289,1124.7l138.1-92.4-.173,334.6-137.712.112Z" transform="translate(-3290.289 -590.22)" fill="#66ad9f" />
                <Text transform="translate(432.617 650.408)" fill="#313133" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Energetic</TSpan></Text>
            </G>
            <G {...onPressLevel4} transform="translate(-46 -231)">
                <Path d="M3860.18,1027.495l139.15-91.2.33,430.6-139.277.111Z" transform="translate(-3290.289 -590.22)" fill="#489c8c" />
                <Text transform="translate(591.151 598.031)" fill="#ffffff" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Pumped</TSpan></Text>
            </G>
            <G {...onPressLevel5} transform="translate(-46 -231)">
                <Path d="M4011.219,930.295l136.1-88.8.327,525.4-137.712.112Z" transform="translate(-3290.289 -590.22)" fill="#2e8c79" />
                <Text transform="translate(723 540.296)" fill="#ffffff" fontSize="25.569" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="30" y="0">Hyper</TSpan></Text>
            </G>
        </Svg>
    )
}