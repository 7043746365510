import { StyleSheet, View } from 'react-native';
import { DecidaColors } from '../../../../common/decida-colors';
import { DefaultText } from '../../../common/default-text';
import VideoCard from '../../../common/video-card';
import { Videos } from '../../../models';
import { CardColorScheme } from '../../common/card-color-scheme';
import { CardTemplate, CardType } from '../../common/card-template';
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link';
import { DarlingPointSpecialSchoolLogo } from '../../lesson-plan-cards/src/darling-point-special-school-logo';
import { AuslanIcon } from '../icons/auslan';
import { AuslanLessonPlanImage } from '../icons/auslan-lesson-plan-image';
import { useResponsive } from '../../../common/use-responsive';

const auslanVideo: Videos = {
    id: '1',
    url: "https://www.youtube.com/embed/Sivyv4zSLQo",
}

export const ToolsAuslan: CardTemplate = {
    cardId: "2968a158-370e-46a1-a708-83fcdf95267d",
    title: 'Auslan',
    Header: AuslanIcon,
    Icon: AuslanIcon,
    PerfectFor: () => {
        const { width } = useResponsive()
        return (

            <View style={styles.contentContainer}>
                <DefaultText>A great resource from our Switch4School partners at:</DefaultText>
                <View style={styles.logoContainer}>
                    <DarlingPointSpecialSchoolLogo />
                </View>
                <View style={[styles.imageContainer, { width: width < 600 ? 300 : '100%'}]}>
                    <AuslanLessonPlanImage />
                </View>
                <View style={styles.rowContainerHalf}>
                    <VideoCard video={auslanVideo} />
                </View>
                <View style={styles.linksContainer}>
                    <DefaultTextWithCustomLink style={styles.linkText} text='AUSLAN emotion flash cards' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/AUSLANFEELINGSSIGNSLineDrawingsEasyPrintVersion-1.pdf' />
                    <DefaultTextWithCustomLink style={styles.linkText} text='AUSLAN FEELINGS SIGNS IN FLASHCARD FORMAT' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/AUSLAN+emotion+flash+cards.pdf' />
                </View>
            </View>
        )
    },
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>

        </>
    ),
    colorScheme: CardColorScheme.TOOL,
    whyDoesItWorkText: '',
    type: [CardType.Tool],
    time: [],
    people: [],
    place: [],
    category: [],
    action: [],
    level: []
}

const styles = StyleSheet.create({
    imageContainer: {
        aspectRatio: 1,
        minWidth: '100%',
        maxHeight: '100%',
        marginVertical: 20,
        alignSelf: 'center'
    },
    logoContainer: {
        width: 200,
        height: 200
    },
    contentContainer: {
        flex: 1,
        height: '100%',
        marginTop: 40,
        marginBottom: 20,
        alignItems: 'center'
    },
    rowContainerHalf: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20,
    },
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    linksContainer: {
        alignItems: 'center',
        marginVertical: 5,
        backgroundColor: 'pumpkin'
    },
    linkText: {
        marginBottom: 10,
        textAlign: 'center'
    }
})
