import { StyleSheet } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { ToolsTheLittleBookOfBigEmotions } from '../tools/cards/the-little-book-of-big-emotions'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

export const SwitchesLessonPlansUnderstandingAndManagingHapiness: CardTemplate = {
    cardId: "e97086a1-cd33-46fe-bf45-9abacd64ceeb",
    title: 'Understanding and managing happiness',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to understand the fundamentals of peace and happiness to enhance self awareness.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes </DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Greet the students and explain the purpose of the lesson: to understand and cultivate happiness effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share their understanding of happiness and its importance in their lives.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Defining Happiness (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the definition of happiness, emphasising that it is a positive and satisfying emotion that everyone seeks. Refer to the Little Book of Big Emotion for more information.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that happiness can come from various sources, such as meaningful relationships, personal achievements, and engaging activities.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share examples of situations or experiences that make them happy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the different ways people experience and express happiness, such as smiling, laughing, or feeling content.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding the Benefits of Happiness (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to brainstorm the positive and negative effects of happiness.  Look for…</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Positive Effects of Happiness:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Positive Mood: When you're happy, it boosts your overall mood and brings a sense of joy and contentment. It can make you feel optimistic, enthusiastic, and full of positive energy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Improved Mental Health: Happiness has been linked to improved mental wellbeing. It can reduce feelings of stress, anxiety, and depression. Being happy can contribute to a more positive outlook on life and help build resilience.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Better Physical Health: Happiness has been associated with better physical health outcomes. When you're happy, your body tends to release more endorphins, which are natural feel-good chemicals that can boost your immune system and reduce pain levels.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Enhanced Relationships: Happiness can improve your relationships with others. When you're happy, you're more likely to engage in positive and meaningful connections with friends, family, and peers. It can foster stronger social support networks and create a sense of belonging.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Negative Effects of Happiness:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Unrealistic Expectations: Sometimes, being excessively focused on pursuing happiness can lead to unrealistic expectations. If you constantly strive for an idealised version of happiness, it may create disappointment and dissatisfaction when reality doesn't match those expectations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Suppression of Other Emotions: In the pursuit of maintaining happiness, some people may suppress or ignore other emotions such as sadness, anger, or grief. It's important to acknowledge and process a full range of emotions rather than suppressing them, as they serve valuable purposes in our lives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Comparison and Envy: Seeing others happy can sometimes lead to feelings of comparison and envy. If you constantly compare yourself to others or feel jealous of their happiness, it can diminish your own sense of wellbeing. Remember that everyone's journey is unique, and comparing yourself to others isn't always helpful.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Pressure to Maintain Happiness: There may be societal or internal pressure to constantly maintain a state of happiness, which can be unrealistic and exhausting. It's important to recognise that it's normal to experience a range of emotions, and happiness is just one part of the human experience.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ While happiness has many positive effects on our wellbeing, it's important to embrace the full spectrum of emotions and find a balance that allows for authenticity and self-compassion. It's okay to experience a variety of emotions, and the pursuit of happiness should be seen as part of a holistic approach to wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share personal experiences where happiness has positively impacted their lives</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Cultivating Happiness (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the factors that contribute to happiness, such as positive relationships, pursuing personal passions, and practicing self care.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students access the Switch4Schools program on their device and encourage them to explore the available switches assigned to happy on the emotion wheel to bookmark activities they’d like to try.  Alternatively provide a copy of the level 5 quick reference guide. Could also be a homework activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the benefits of some of the techniques selected by the group and when they might be most helpful.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Developing Self awareness (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the concept of self awareness as the ability to recognise and understand one's own thoughts, feelings, and behaviours.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the connection between self awareness and cultivating happiness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a guided reflection exercise, encouraging students to think about the activities, experiences, or interactions that make them genuinely happy. Have students populate the ‘Things that make you happy’ worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share their reflections and discuss strategies for enhancing self awareness to cultivate happiness.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the key points discussed during the lesson, emphasising the importance of understanding, and cultivating happiness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to understand that happiness is a subjective experience, and what brings happiness to one person may differ from another. Encourage them to explore and find what genuinely brings them joy and fulfillment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice the happiness-building strategies they've learned to enhance emotional intelligence and self awareness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Answer any questions or concerns the students may have.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard/markers or chart paper/poster</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Things that make you happy worksheet' link={Worksheets.whatMakesYouHappy.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Level 5 quick reference guide' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/level+5+QRG.pdf' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='The Little Book of Big Emotions' link={ToolsTheLittleBookOfBigEmotions.openUrl || ""} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>7 Reasons Why It’s Important to Focus on Your Happiness -</DefaultText>
                        <DefaultTextWithLink text='https://www.happierhuman.com/why-happiness-important/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Six Ways Happiness Is Good for Your Health -</DefaultText>
                        <DefaultTextWithLink text='https://greatergood.berkeley.edu/article/item/six_ways_happiness_is_good_for_your_health' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
})
