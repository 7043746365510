import _ from 'lodash'
import { Antelope } from './animals/antelope'
import { Bear } from './animals/bear'
import { Bull } from './animals/bull'
import { Cat } from './animals/cat'
import { Cheetah2 } from './animals/cheetah-2'
import { Chicken } from './animals/chicken'
import { Chimp } from './animals/chimp'
import { Cow } from './animals/cow'
import { Deer } from './animals/deer'
import { Dog2 } from './animals/dog-2'
import { DogHound } from './animals/dog-hound'
import { Elephant } from './animals/elephant'
import { Fox } from './animals/fox'
import { Giraffe2 } from './animals/giraffe-2'
import { Goat } from './animals/goat'
import { Goose } from './animals/goose'
import { Gorilla } from './animals/gorilla'
import { Horse } from './animals/horse'
import { HyenaCartoon } from './animals/hyena-cartoon'
import { Koala } from './animals/koala'
import { Lion2 } from './animals/lion-2'
import { Monkey } from './animals/monkey'
import { MonkeyWhite } from './animals/monkey-white'
import { Panda } from './animals/panda'
import { Penguin } from './animals/penguin'
import { Piglet } from './animals/piglet'
import { PolarBear } from './animals/polar-bear'
import { Puppy } from './animals/puppy'
import { RabbitAngry } from './animals/rabbit-angry'
import { Ram } from './animals/ram'
import { Reigndeer } from './animals/reigndeer'
import { Rhyno2 } from './animals/rhino-2'
import { SnakeCobra2 } from './animals/snake-cobra-2'
import { SnakePython } from './animals/snake-python'
import { Tiger } from './animals/tiger'
import { Walrus } from './animals/walrus'
import { Wolf2 } from './animals/wolf-2'
import { Worthog } from './animals/worthog'
import { ZebraCartoon } from './animals/zebra-cartoon'
// Food
import Default from './Default'
import { Acorn } from './food/acorn'
import { Almond } from './food/almond'
import { AppleGreen } from './food/apple-green'
import { Avacado } from './food/avacado'
import { Bananas } from './food/bananas'
import { Beetroot } from './food/beetroot'
import { Blackberry } from './food/blackberry'
import { Blueberry } from './food/blueberry'
import { Broccoli } from './food/broccoli'
import { Cherries2 } from './food/cherries-2'
import { Coconut } from './food/coconut'
import { Corn } from './food/corn'
import { DragonFruit } from './food/dragon-fruit'
import { EggPlant2 } from './food/egg-plant-2'
import { Fig } from './food/fig'
import { Garlic } from './food/garlic'
import { Grapes } from './food/grapes'
import { HotPepper } from './food/hot-pepper'
import { KiwiFruit } from './food/kiwi-fruit'
import { Lemon } from './food/lemon'
import { Lettus } from './food/lettus'
import { Lime2 } from './food/lime-2'
import { Onion2 } from './food/onion-2'
import { Orange } from './food/orange'
import { PassionFruit } from './food/passion-fruit'
import { Peach } from './food/peach'
import { Peach2 } from './food/peach-2'
import { Peanut } from './food/peanut'
import { Pear } from './food/pear'
import { Pineapple3 } from './food/pineapple-3'
import { Plumb } from './food/plumb'
import { Pomegranate } from './food/pomegranate'
import { Potato2 } from './food/potato-2'
import { Pumpkin } from './food/pumpkin'
import { Rambutan } from './food/rambutan'
import { Rasberry2 } from './food/rasberry-2'
import { StarFruit } from './food/star-fruit'
import { Strawberry } from './food/strawberry'
import { SunflowerSeed } from './food/sunflower-seed'
import { SweetPepper } from './food/sweet-pepper'
import { Tomato } from './food/tomato'
import { Wallnut } from './food/wallnut'
import { Watermellon } from './food/watermellon'
import { monstersavatars } from './monsters/monsters-avatars'
import { peopleavatars } from './people/people-avatars'
import { origamiavatars } from './origami/origami-avatars'
import { AngelFish } from './ocean/angel-fish'
import { BlueFace } from './ocean/blue-face'
import { Butterfly } from './ocean/butterfly'
import { Clam } from './ocean/clam'
import { Clownfish } from './ocean/clownfish'
import { Coral1 } from './ocean/coral-1'
import { Coral3 } from './ocean/coral-3'
import { Crab } from './ocean/crab'
import { DiscusFish } from './ocean/discus-fish'
import { Dolphin } from './ocean/dolphin'
import { EagleRay } from './ocean/eagle-ray'
import { Eel } from './ocean/eel'
import { Flounder } from './ocean/flounder'
import { Hammerhead } from './ocean/hammerhead'
import { HermitCrab } from './ocean/hermit-crab'
import { JellyFish } from './ocean/jellyfish'
import { KillerWhale } from './ocean/killer-whale'
import { LionFish } from './ocean/lion-fish'
import { Lobster } from './ocean/lobster'
import { MoorishIdol } from './ocean/moorish-idol'
import { Narwhal } from './ocean/narwhal'
import { Octopus } from './ocean/octopus'
import { Oscar } from './ocean/oscar'
import { Pelecan } from './ocean/pelecan'
import { Prawn } from './ocean/prawn'
import { Pufferfish } from './ocean/pufferfish'
import { Seahorse } from './ocean/seahorse'
import { Seal } from './ocean/seal'
import { Shark } from './ocean/shark'
import { Shell } from './ocean/shell'
import { Shell2 } from './ocean/shell2'
import { Squid } from './ocean/squid'
import { Starfish } from './ocean/starfish'
import { Stingray } from './ocean/stingray'
import { Tuna } from './ocean/tuna'
import { Turtle } from './ocean/turtle'
import { Whale } from './ocean/whale'
import { Zebrasoma } from './ocean/zebrasoma'


export const animalavatars = {
  Antelope,
  Bear,
  Bull,
  Cat,
  Cheetah2,
  Chicken,
  Chimp,
  Cow,
  Deer,
  Dog2,
  DogHound,
  Elephant,
  Fox,
  Giraffe2,
  Goat,
  Goose,
  Gorilla,
  Horse,
  HyenaCartoon,
  Koala,
  Lion2,
  Monkey,
  MonkeyWhite,
  Panda,
  Penguin,
  Piglet,
  PolarBear,
  Puppy,
  RabbitAngry,
  Ram,
  Reigndeer,
  Rhyno2,
  SnakeCobra2,
  SnakePython,
  Tiger,
  Walrus,
  Wolf2,
  Worthog,
  ZebraCartoon,
}
export const fruitavatars = {
  // Food
  Acorn,
  Almond,
  AppleGreen,
  Avacado,
  Bananas,
  Beetroot,
  Blackberry,
  Blueberry,
  Broccoli,
  Cherries2,
  Coconut,
  Corn,
  DragonFruit,
  Fig,
  Garlic,
  Grapes,
  HotPepper,
  KiwiFruit,
  Lemon,
  Lettus,
  Lime2,
  Onion2,
  Orange,
  PassionFruit,
  Peanut,
  Pear,
  Pineapple3,
  Plumb,
  Pomegranate,
  Potato2,
  Pumpkin,
  Rambutan,
  Rasberry2,
  StarFruit,
  Strawberry,
  SunflowerSeed,
  SweetPepper,
  Tomato,
  Wallnut,
  Watermellon,
  Default,
}

export const oceanAvatars = {
  AngelFish,
  BlueFace,
  Butterfly,
  Clam,
  Clownfish,
  Coral1,
  Coral3,
  Crab,
  DiscusFish,
  Dolphin,
  EagleRay,
  Eel,
  Flounder,
  Hammerhead,
  HermitCrab,
  JellyFish,
  KillerWhale,
  LionFish,
  Lobster,
  MoorishIdol,
  Narwhal,
  Octopus,
  Oscar,
  Pelecan,
  Penguin,
  Prawn,
  Pufferfish,
  Seahorse,
  Seal,
  Shark,
  Shell,
  Shell2,
  Squid,
  Starfish,
  Stingray,
  Tuna,
  Turtle,
  Walrus,
  Whale,
  Zebrasoma
}

export const removedAvatars = {
  Peach,
  Peach2,
  EggPlant2,
  Default
}

export const avatars = {
  ...animalavatars,
  ...fruitavatars,
  ...removedAvatars,
  ...monstersavatars,
  ...peopleavatars,
  ...origamiavatars,
  ...oceanAvatars
}

export const avatarArray = _.map(avatars, (Avatar, propName) => ({ avatarName: propName as keyof typeof avatars, Avatar }))
export const animalAvatarArray = _.map(animalavatars, (Avatar, propName) => ({ avatarName: propName as keyof typeof avatars, Avatar }))
export const fruitAvatarArray = _.map(fruitavatars, (Avatar, propName) => ({ avatarName: propName as keyof typeof avatars, Avatar }))
export const monsterAvatarArray = _.map(monstersavatars, (Avatar, propName) => ({ avatarName: propName as keyof typeof avatars, Avatar }))
export const peopleAvatarArray = _.map(peopleavatars, (Avatar, propName) => ({ avatarName: propName as keyof typeof avatars, Avatar }))
export const origamiAvatarArray = _.map(origamiavatars, (Avatar, propName) => ({ avatarName: propName as keyof typeof avatars, Avatar }))
export const oceanAvatarArray = _.map(oceanAvatars, (Avatar, propName) => ({ avatarName: propName as keyof typeof avatars, Avatar }))

export enum AvatarCategory {
  Animal,
  Fruit,
  Monster,
  People,
  Origami,
  Ocean
}