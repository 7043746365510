import { StyleSheet } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { ToolsTheLittleBookOfBigEmotions } from '../tools/cards/the-little-book-of-big-emotions'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

export const SwitchesLessonPlansUnderstandingAndManagingExcitement: CardTemplate = {
    cardId: "d0b183b7-8797-4b19-b8e1-54b062ef80b4",
    title: 'Understanding and managing excitement',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to recognise, understand, manage, and use excitement constructively to enhance self awareness.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: Approximately 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Greet the students and explain the purpose of the lesson: to understand and manage excitement effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share their understanding of excitement and its effects on themselves and others.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Defining Excitement (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the definition of excitement, (refer to the Little Book of Big Emotion for more information) emphasising that it is a positive and energising emotion that everyone experiences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that excitement can be triggered by anticipation of something fun, new experiences, or achieving goals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the different ways people experience and express excitement, such as increased energy, smiling, or talking faster.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding the Effects of Excitement (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to brainstorm the positive and negative effects of excitement.  You may like to record these on the board.  Look for…</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Positive impacts of excitement:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Energy and Enthusiasm: When you're excited about something, it gives you a burst of energy and enthusiasm. This can help you approach activities with a positive attitude, stay engaged, and enjoy what you're doing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Motivation and Drive: Excitement can be a great motivator. It can inspire you to set goals, work hard, and achieve things you want. When you're excited about something, it can give you the determination and drive to pursue it with passion.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Exploration and Learning: Excitement encourages curiosity and the desire to explore new things. It can motivate you to try different activities, learn new skills, and expand your knowledge. Excitement can make learning fun and enjoyable.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Positive Emotions: When you're excited, you often experience positive emotions and feelings like happiness, joy, and anticipation. These emotions and feelings can contribute to your overall wellbeing, boost your mood, and create a sense of fulfillment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Negative impacts of excitement:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Impulsivity: Sometimes, when you're very excited, you might act impulsively without considering the consequences. It's important to remember to think things through and make responsible decisions, even when you're feeling excited.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Disappointment: While excitement is a wonderful feeling, it's important to manage your expectations. Sometimes, things may not turn out exactly as you hoped, and that can lead to feelings of disappointment. It's okay to be excited, but also be prepared for different outcomes and have a positive attitude even if things don't go as planned.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Difficulty Concentrating: When you're extremely excited, it can be challenging to focus on other tasks or responsibilities. Excitement can be distracting, so it's important to find a balance and make sure you're able to concentrate on important things like schoolwork or chores.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Sleep Disturbances: If you're very excited about something, it might be difficult to calm down and get a good night's sleep. It's important to establish a regular bedtime routine and give yourself time to wind down before going to bed, so excitement doesn't disrupt your sleep patterns. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share personal experiences where excessive excitement may have impacted their decision-making or ability to concentrate.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Developing Self awareness (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the concept of self awareness as the ability to recognise and understand one's own thoughts, feelings, and behaviours.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the connection between self awareness and managing excitement. Self awareness means having an understanding of your own thoughts, feelings, and actions. It's like knowing yourself really well, including how you react to different situations and what makes you feel certain emotions, like excitement. Self awareness is important when it comes to managing excitement because it helps you make wise choices and control your emotions effectively. Here's how it works:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Recognising Excitement: When you're self-aware, you can recognise when you're feeling excited. You can identify the signs in your body and the thoughts that come with it. For example, you might notice that your heart beats faster, you have a big smile, or your mind starts racing with thoughts about something you're looking forward to.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Understanding the Intensity: Self awareness helps you understand how intense your excitement is. Sometimes, excitement can be very strong and overwhelming, and other times it can be more moderate. By being aware of the level of excitement you're experiencing, you can take appropriate actions to manage it. Reference the emotion wheel to reinforce the words that describe the different levels of excitement.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Managing Reactions: Self awareness allows you to choose how you react to your excitement. It helps you understand that while it's wonderful to feel excited, you also need to make responsible decisions and consider the consequences of your actions. For example, if you're excited about a fun activity, self awareness can help you control any impulsive behaviours and think about safety measures or other important factors.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Finding Balance: Self awareness helps you find a balance between enjoying your excitement and maintaining a sense of control. It allows you to assess whether your excitement is becoming too overwhelming or interfering with other aspects of your life, like schoolwork or relationships. It helps you prioritise and manage your time effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- By being self-aware, you can make the most out of your excitement without letting it take over your life completely. You can enjoy the positive feelings it brings while still being responsible and considering the needs and expectations of others. Self awareness is a skill that you can develop over time by paying attention to your emotions and reflecting on your experiences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Remember, managing excitement doesn't mean suppressing it or not feeling excited at all. It's about being aware of your emotions, understanding their impact, and making choices that align with your values and overall wellbeing. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a guided reflection exercise, encouraging students to think about their own reactions and behaviours when they are excited. Distribute and have students complete the Excited Balloon worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share their reflections and discuss strategies for improving self awareness in exciting situations.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Excitement Management Techniques (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce a variety of excitement management techniques, such as taking deep breaths, practicing mindfulness, setting realistic expectations, and channelling energy through physical activities or creative outlets.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students access the Switch4Schools program on their device and encourage them to explore the available switches assigned to excitement on the emotion wheel to bookmark activities they’d like to try.  Alternatively provide a copy of the level 5 quick reference guide. Could also be a homework activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the benefits of some of the techniques selected by the group and when they might be most helpful.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the key points discussed during the lesson, emphasising the importance of understanding, and managing excitement.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice the strategies learned in their daily lives to enhance emotional intelligence and self awareness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ It is important to acknowledge that excitement is generally a positive emotion, but excessive excitement can also be overwhelming. Encourage students to find a balance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Answer any questions or concerns the students may have.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard/markers or chart paper/poster</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Excited Balloon worksheet' link={Worksheets.excitedBaloonWorksheets.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Level 5 quick reference guide' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/level+5+QRG.pdf' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='The Little Book of Big Emotions' link={ToolsTheLittleBookOfBigEmotions.openUrl || ""} /></DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Biology of Excitement and Positive Feelings in Humans -</DefaultText>
                        <DefaultTextWithLink text='https://untamedscience.com/blog/the-biology-of-excitement-and-positive-feelings-in-humans/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Strategies to Support ADHD in the Classroom - Learning Links -</DefaultText>
                        <DefaultTextWithLink text='https://www.learninglinks.org.au/resources/adhd-in-the-classroom/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>School-Wide Strategies for Managing... HYPERACTIVITY -</DefaultText>
                        <DefaultTextWithLink text='https://www.interventioncentral.org/behavioral-interventions/challenging-students/school-wide-strategies-managing-hyperactivity' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    durationText: {
        marginBottom: 10,
        color: DecidaColors.Gray,
        alignSelf: 'center'
    },
})
