import { gql, useMutation, useQuery } from '@apollo/client'
import { API } from 'aws-amplify'
import _ from 'lodash'
import { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { getApiHeaders } from '../common/get-api-header'
import { Page } from '../common/page'
import { showAlert } from '../common/universal-alert'
import { deleteStudent, deleteTeacher } from '../../common/graphql/mutations'
import { listStudents, listTeachers } from '../../common/graphql/queries'
import { Student, Teacher } from '../models'

enum NTableDataType {
    Teacher,
    Student
}

const AdminDuplicationTools = () => {

    const [duplicateData, setDuplicateData] = useState<Teacher[] & Student[]>([])
    const [currentKey, setcurrentKey] = useState("")
    const [currentType, setCurrentType] = useState<NTableDataType | null>(null)

    const { data: teachersData } = useQuery(gql`${listTeachers}`, { variables: { limit: 5000 } })
    const { data: studentsData } = useQuery(gql`${listStudents}`, { variables: { limit: 5000 } })
    const [deleteTeacherMutation] = useMutation(gql`${deleteTeacher}`)
    const [deleteStudentMutation] = useMutation(gql`${deleteStudent}`)
    const currentTeachers = teachersData?.listTeachers?.items?.filter((item: any) => !item?._deleted)
    const currentStudents = studentsData?.listStudents?.items?.filter((item: any) => !item?._deleted)

    // const { data: searchTeacher } = useQuery(gql`${getT}`)

    const checkDuplicationByKey = (data: Teacher[] | Student[], key: keyof Teacher | keyof Student, type: NTableDataType) => {
        const _duplicatedData: Teacher[] = []

        for (const teacher of data) {
            const duplicateIndex = data.findIndex((t) => t.id !== teacher?.id && t[key] === teacher[key])
            if (duplicateIndex !== -1) {
                // there's duplicate Data
                _duplicatedData.push(data[duplicateIndex])
            }
        }

        setDuplicateData(_duplicatedData)
        setCurrentType(type)
        setcurrentKey(key)
    }

    const handleRemove = (item: Teacher | Student) => async () => {
        showAlert({
            title: `Are you sure you want to delete this account`,
            message: 'This action cannot be undone.',
            leftButtonText: 'Cancel',
            rightButtonText: 'Delete',
            onRightButtonPress: async () => {
                try {
                    await API.post(
                        'AdminQueries',
                        '/deleteUser',
                        {
                            body: {
                                username: item?.cognitoUsername,
                            },
                            headers: await getApiHeaders(),
                        }
                    )

                    if (currentType === NTableDataType.Teacher) {
                        deleteTeacherMutation({
                            variables: {
                                input: {
                                    id: item?.id,
                                    _version: item?._version
                                }
                            }
                        })
                        return
                    }

                    if (currentType === NTableDataType.Student) {
                        deleteStudentMutation({
                            variables: {
                                input: {
                                    id: item?.id,
                                    _version: item?._version
                                }
                            }
                        })
                        return
                    }
                } catch (error) {
                    console.log("Faled to remove data", error)
                }
            }
        })
    }

    const teacherHeader = ["id", "CognitoUsername", "First Name", "Last Name", "Email", "SchoolID", "Actions"]
    const studentHeader = ["id", "Nickname", "CognitoUsername", "First Name", "Last Name", "Email", "SchoolID", "Actions"]
    const headers = currentType === NTableDataType.Teacher ? teacherHeader : studentHeader

    return (
        <Page style={styles.container}>
            <DefaultButton onPress={() => checkDuplicationByKey(currentTeachers as Teacher[], "email", NTableDataType.Teacher)}>Teachers</DefaultButton>
            <DefaultButton onPress={() => checkDuplicationByKey(currentStudents as Student[], "nickname", NTableDataType.Student)}>Students</DefaultButton>

            <DefaultText style={styles.tableTitle}>Duplicate {currentType === NTableDataType.Teacher ? "Teachers" : "Students"}</DefaultText>
            <View style={styles.tableContainerStyle}>
                {headers.map((text, index) => {
                    return <Text key={`${index}`} style={styles.headerText}>{text}</Text>
                })}
            </View>

            {_.sortBy(duplicateData, [currentKey]).map((data, index) => {


                return (
                    <View style={styles.tableContainerStyle}>
                        <DefaultText style={styles.dataText}>{data.id}</DefaultText>
                        {currentType === NTableDataType.Student && (
                            <DefaultText style={styles.dataText}>{data.nickname}</DefaultText>
                        )}
                        <DefaultText style={styles.dataText}>{data.cognitoUsername}</DefaultText>
                        <DefaultText style={styles.dataText}>{data.firstName}</DefaultText>
                        <DefaultText style={styles.dataText}>{data.lastName}</DefaultText>
                        <DefaultText style={styles.dataText}>{data.email}</DefaultText>
                        <DefaultText style={styles.dataText}>{data.schoolID}</DefaultText>
                        <View style={styles.dataText}>
                            <DefaultButton style={styles.removeButton} onPress={handleRemove(data)}>Remove</DefaultButton>
                        </View>
                    </View>
                )

            })}


        </Page>
    )
}

export default AdminDuplicationTools

const styles = StyleSheet.create({
    container: {
        justifyContent: 'flex-start',
        padding: 20
    },
    tableContainerStyle: {
        flexDirection: 'row',
        width: '100%',
    },
    headerText: {
        fontWeight: '500',
        fontSize: 18,
        flex: 1,
        padding: 10,
        borderWidth: 0.5,
        minWidth: 100,
        height: '100%'
    },
    dataText: {
        flex: 1,
        padding: 10,
        borderWidth: 0.5,
        minWidth: 100,
        height: '100%'
    },
    removeButton: {
        // flex: 1,
        width: '100%'
    },
    tableTitle: {
        marginVertical: 20,
        fontSize: 24,
        alignSelf: 'flex-start',
        fontWeight: 'bold'
    }
})