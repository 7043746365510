import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const EmotionAnxiousIcon = (props?: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 92.491 92.489"
    {...props}
  >
    <Path
      id="Path_3911"
      data-name="Path 3911"
      d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465Z"
      transform="translate(26.513 51.518)"
      fill="none"
      stroke="#c3ac2e"
      strokeWidth={3.013}
    />
    <Path
      id="Path_3912"
      data-name="Path 3912"
      d="M2.8,5.59A6.338,6.338,0,0,0,9.132-.747,6.338,6.338,0,0,0,2.8-7.084,6.338,6.338,0,0,0-3.542-.747,6.338,6.338,0,0,0,2.8,5.59"
      transform="translate(26.229 37.681)"
      fill="#c3ac2e"
    />
    <Path
      id="Path_3913"
      data-name="Path 3913"
      d="M2.8,5.59A6.338,6.338,0,0,0,9.132-.747,6.338,6.338,0,0,0,2.8-7.084,6.338,6.338,0,0,0-3.542-.747,6.338,6.338,0,0,0,2.8,5.59"
      transform="translate(61.416 37.681)"
      fill="#c3ac2e"
    />
    <Path
      id="Path_3914"
      data-name="Path 3914"
      d="M2.8,0a6.339,6.339,0,0,0,0,12.678H20.39A6.339,6.339,0,0,0,20.39,0Z"
      transform="translate(34.653 57.542)"
      fill="none"
      stroke="#c3ac2e"
      strokeWidth={3.013}
    />
  </Svg>
);
export default EmotionAnxiousIcon;
