import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { logout } from './logout';

export const authTimeout = () => {
    const [events, _] = useState(['click', 'mousemove', 'mousedown']);

    let timeout15mins = 1000 * 60 * 15
    let logoutTimeout: ReturnType<typeof setTimeout>

    const resetTimer = () => {
        clearTimeout(logoutTimeout)
        startTimer()
    }

    const startTimer = () => {
        logoutTimeout = setTimeout(logout, timeout15mins)
    }

    useEffect(() => {
        if (window && Platform.OS === 'web') {
            events.forEach((event) => {
                window.addEventListener(event, resetTimer);
            });
        }
    }, [window])
}

