import { API } from "aws-amplify"
import { StyleSheet, View } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import { generatePassword } from "../../common/generate-password"
import { DefaultButton, DefaultButtonTypes } from "../common/default-button"
import { DefaultText } from "../common/default-text"
import { getApiHeaders } from "../common/get-api-header"
import { rvIsLoading } from "../common/loading"
import { showAlert } from "../common/universal-alert"
import { useResponsive } from '../common/use-responsive'
import IconMail from "../icon/icon-mail"
import { SchoolAdminUI, Teacher } from "../../common/API"
import { GetSchoolQueryResponseTeacher } from "../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query"
import { BreakPoints } from "../common/const"


type Props = {
    teacher: GetSchoolQueryResponseTeacher
    schoolAdminUI?: SchoolAdminUI
}

const SchoolAdminButtonResetPasswordTeacher = ({ teacher, schoolAdminUI = SchoolAdminUI.LIST }: Props) => {
    const { width } = useResponsive()


    const showPromptResetPassword = () => {
        showAlert({
            title: "Reset passsword",
            message: `Are you sure you want to reset ${teacher.firstName} ${teacher.lastName} password?`,
            leftButtonText: "Cancel",
            rightButtonText: "Yes",
            onRightButtonPress: () => handleResetPassword()
        })
    }

    const handleResetPassword = async () => {
        const tempPassword = generatePassword()
        const message = `Please ask the teacher to check his/her email for temp password.`

        rvIsLoading(true);
        try {
            await API.post("AdminQueries", "/resetPasswordAdmin", {
                body: {
                    nickname: teacher.cognitoUsername,
                    password: tempPassword,
                    email: teacher.email
                },
                headers: await getApiHeaders(),
            });

            showAlert({
                message,
                title: "Successfully reset password",
                leftButtonText: "Ok",
            })
        } catch (e) {
            console.log(e);
        }
        rvIsLoading(false);
    }


    const shouldDisplayResetText =
        (width > BreakPoints.M && schoolAdminUI === SchoolAdminUI.LIST) ||
        (width < BreakPoints.L && schoolAdminUI === SchoolAdminUI.HIERARCHY) ||
        (width > BreakPoints.XL && schoolAdminUI === SchoolAdminUI.HIERARCHY);


    return (
        <DefaultButton type={DefaultButtonTypes.Small} style={{ flex: 1, backgroundColor: DecidaColors.Sad, marginRight: 5, flexWrap: 'wrap' }} onPress={showPromptResetPassword}>
            <View style={{ paddingVertical: 2.5 }}>
                <IconMail width={18} color={DecidaColors.White} />
            </View>
            {shouldDisplayResetText && (
                <View style={{ flex: 1, }}>
                    <DefaultText textProps={{ numberOfLines: 1 }} style={styles.manageClassText}>
                        Reset Password
                    </DefaultText>
                </View>
            )}
        </DefaultButton>
    )
}
export default SchoolAdminButtonResetPasswordTeacher
const styles = StyleSheet.create({
    manageClassText: {
        color: DecidaColors.White,
        marginLeft: 5,
        fontSize: 12,
        fontWeight: '700'
    },

})