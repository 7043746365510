import { StudentStatus } from "../../models";

export const listAllStudentsBySchoolIDQuery = /* GraphQL */ `
  query ListAllStudentsBySchoolID($schoolID: String!) {
    listAllStudentsBySchoolID(schoolID: $schoolID) {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export type ListAllStudentsBySchoolIDQueryVariables = {
    schoolID: string,
};

export type ListAllStudentsBySchoolIDQueryResponse = {
    listAllStudentsBySchoolID: Array<ListAllStudentsBySchoolIDQueryResponseStudent>,
};

export type ListAllStudentsBySchoolIDQueryResponseStudent = {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
} | null