import { Entypo } from '@expo/vector-icons';
import * as Clipboard from 'expo-clipboard';
import { ColorValue, GestureResponderEvent, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { IconSize } from './const';
import { DefaultText } from './default-text';
import { handleShareUrl } from './helper-functions';
import { showAlert } from './universal-alert';
import { useResponsive } from './use-responsive';
import { useReactiveVar } from '@apollo/client';
import { rvPrintMode } from './export-to-pdf-button';
import { DecidaColors } from '../../common/decida-colors';

type Props = {
    color: ColorValue,
    url: string
}

const ShareButton = ({ color, url }: Props) => {
    const { width } = useResponsive()
    const isPrintMode = useReactiveVar(rvPrintMode)

    const handleShare = async (e: GestureResponderEvent) => {
        e.preventDefault()

        if (Platform.OS === 'web') {
            Clipboard.setStringAsync(url)
            showAlert({
                title: "Share",
                message: "Link has been copied to your clipboard",
                leftButtonText: "Ok"
            })
            return
        }

        handleShareUrl({ url })
    }

    return (
        <TouchableOpacity style={[styles.imagecontainer, { padding: width > 600 ? 10 : 2, display: isPrintMode ? 'none' : 'flex' }]} onPress={handleShare}>
            <View style={styles.iconContainer}>
                <Entypo name="share-alternative" size={30} color={color} />
            </View>
            <DefaultText style={[styles.text, { color: DecidaColors.GrayText, fontSize: width > 600 ? 15 : 10, }]}>
                Share
            </DefaultText>
        </TouchableOpacity>
    )
}
export default ShareButton
const styles = StyleSheet.create({
    imagecontainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconContainer: {
        width: IconSize,
        height:  IconSize,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
        paddingTop: 5,
    },
    text: {
        marginTop: 5
    }
})