import _ from "lodash"
import moment from "moment"
import { ScheduleType, SchedulerPicker } from "./const";

export const sessionOverlap = (sessions: ScheduleType[], currentValue: string, currentIndex: number, openOrClose: SchedulerPicker, currentOpenAt?: string, currentCloseAt?: string) => {
    let isOverlap = false

    for (const key in sessions) {
        const session = sessions[key]
        const currentSessionIndex = key === String(currentIndex)

        const currentValueMoment = moment(currentValue, "HH:mm A")
        const currentOpenAtValueMoment = moment(currentOpenAt, "HH:mm A")
        const currentCloseAtValueMoment = moment(currentCloseAt, "HH:mm A")
        const existingOpenTimeMoment = moment(session.openAt, "HH:mm A")
        const existingCloseTimeMoment = moment(session.closeAt, "HH:mm A")

        const isBetweenCurrentSessions = currentValueMoment.isBetween(existingOpenTimeMoment, existingCloseTimeMoment)

        if (isBetweenCurrentSessions) {
            // should not validate if that is it's own session
            if (currentSessionIndex) continue

            // Case 1 (openAt and closeAt)
            // seleceted time should not between existing sessions data
            isOverlap = isBetweenCurrentSessions
            if (isOverlap) break
        }

        if (openOrClose === SchedulerPicker.OpenAt) {
            // open At picker

            if (currentValueMoment.isSame(existingOpenTimeMoment) && key !== String(currentIndex)) {
                // Case 2 (openAt)
                // should not input the same open at, as same as other sessions opeAt time
                isOverlap = true
                break;
            }
            if (existingOpenTimeMoment.isBetween(currentValueMoment, currentCloseAtValueMoment) || existingCloseTimeMoment.isBetween(currentValueMoment, currentCloseAtValueMoment)) {
                isOverlap = true
                break;
            }

        }

        if (openOrClose === SchedulerPicker.CloseAt) {
            // close At picker

            if (!currentSessionIndex) {
                // Case 3 (closeAt)
                // need to validate the current open at time
                // in case user is avoid / didn't see the open at error
                // and directly picking close at time
                const currentOpenAtIsOverlapping = currentOpenAtValueMoment.isBetween(existingOpenTimeMoment, existingCloseTimeMoment)
                if (currentOpenAtIsOverlapping) {
                    isOverlap = true
                    break
                }
            }

            if (
                !currentSessionIndex &&
                currentOpenAtValueMoment.isBefore(existingOpenTimeMoment) &&
                currentValueMoment.isAfter(existingCloseTimeMoment)
            ) {
                // Case 4 (closeAt)
                // current openAt and closeAt sessions overlapping other sessions time
                isOverlap = true
                break
            }


            if (currentValueMoment.isSame(existingCloseTimeMoment)) {
                // Case 5 (close at)
                // Current close at is same as other close at time
                // that would be simply overlaping the openAt between them
                isOverlap = true
                break;
            }

            // Case 6 (closeAt)
            // handle same case if current close at is after existing close time moment
            if ((currentValueMoment.isAfter(existingCloseTimeMoment))) {
                const isAfterOtherSessions = currentValueMoment.isAfter(existingOpenTimeMoment) && currentValueMoment.isAfter(existingCloseTimeMoment)

                if (currentSessionIndex) {
                    // Case (6.1)
                    // if it currentSession
                    // close at just need to validate after this session openAt time
                    // not before current openAt time
                    isOverlap = !currentValueMoment.isAfter(existingOpenTimeMoment)
                } else {
                    // Case (6.2)
                    // if it's compared to otjer session
                    // should not after othersession, 
                    // or if its after other sessions should not between them
                    isOverlap = !isAfterOtherSessions || (isAfterOtherSessions && isBetweenCurrentSessions)
                    if (isOverlap) break
                }
            }

        }
    }

    return isOverlap
}