import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { DearDiaryAnxiousIcon } from '../../explore/src/dear-diary-anxious-icon'
import { DearDiaryAnxiousIconInverted } from '../../explore/src/dear-diary-anxious-icon-inverted'
import { DearDiaryAnxiousImage1 } from './src/dear-diary-image1'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithLink from '../../common/default-text-with-link'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'


export const SwitchesEmotionsAnxiousDearDiaryAnxious: CardTemplate = {
    cardId: "27f6a28b-47ba-4298-86b3-edfd0e449986",
    title: 'Dear diary',
    Header: DearDiaryAnxiousIconInverted,
    Icon: DearDiaryAnxiousIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <DearDiaryAnxiousImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Helping process thoughts and memories, 'clearing the deck' to help focus on the important things."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Grab some paper and sit somewhere comfortable, private and quiet (without distractions)."} />
                <DefaultTextWithDownArrow textdata={"Reflect on the last little while (ie. 24 hours). "} />
                <DefaultTextWithDownArrow textdata={"Write the story of what happened."} />
                <DefaultTextWithDownArrow textdata={"List three things that happened that are good."} />
                <DefaultTextWithoutArrows textdata={"What is one thing you can commit to doing that will make tomorrow a little better than today?"} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: 'Research suggests that journaling can be helpful for reducing depression, anxiety, and stress. Journaling can also help you cope with emotions, create more focus, develop insights, and reflect on behaviour or feelings. Another benefit of journaling is that it can boost your happiness by helping you focus on the creativity, beauty, connection, fun, and love in your life.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Demonstrate reflective story writing skills.
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Compose a narrative that describes recent events and feelings associated to that event.
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice short term goal setting (goal for next day).
                        </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>


                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 40 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set the tone by creating a calm and quiet space for journaling.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain what it means to journal, i.e. reflection is defined as the process of stepping back from an experience to ponder its impact, which enables creation of meaning from past or current events that serves as a guide for future behaviour.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think about the previous day, what happened and how they felt about it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Display thought starter questions on the board, such as:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Somewhere you went</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Something you ate</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- A game you played</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Three things that made you feel good</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Journaling Worksheet and ask students to write down their thoughts and responses. Encourage students to be creative, the focus is on personal expression, not the quality of the writing. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Commitment (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students read their journal entry and reflect on whether there was something they could have done differently to make their day better.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to consider and write down one specific thing they commit to doing today/tomorrow to enhance their day.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Sharing and Debrief (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share brief snippets of their journal entries or reflections with a partner.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief by asking students how the activity made them feel and if it influenced their current mindset positively.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minute):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the importance of journaling for self-reflection and planning.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to find the related Dear Diary switch in the Switch4Schools app for additional journaling opportunities.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Journaling worksheet' link={Worksheets.journaling.uri} /> </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Cultivating Reflection Skills: 13 Worksheets & Journal Templates - </DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/reflection-journal-worksheets/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Journaling for Mindfulness: 44 Prompts, Examples, and Exercises -  </DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/journaling-for-mindfulness/' />


                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"More questions to consider writing about:"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata='How would you rate today? (bad, not good, average, pretty good, great)' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata='Why? ' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata='What are three things you are grateful for?' />

            <DefaultTextWithoutArrowsBold textdata={"Handy Hint"} />
            <DefaultTextWithoutArrowsItallic textdata={"It’s a good idea to start every day by writing down at least three things you are grateful (thankful) for. "} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Dear+Diary.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Anxious]
}

