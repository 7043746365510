import { createStackNavigator } from '@react-navigation/stack'
import { RootView } from '../../common/root-view'
import { ScreenNames } from '../../common/screen-names'
import { SwitchesActiviesDetailView } from '../activities/switches-activies-detail-view'
import { SwitchesActiviesDisplayAll } from '../activities/switches-activies-display-all'
import { Card } from '../common/card'
import { SwitchesParamList } from './switches-param-list'


const Stack = createStackNavigator<SwitchesParamList>()

export const SwitchesAcitiviesMain = () => (
    <RootView>
        <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={ScreenNames.SwitchesActiviesDisplayAll} >
            <Stack.Screen name={ScreenNames.SwitchesActiviesDisplayAll} component={SwitchesActiviesDisplayAll} />
            <Stack.Screen name={ScreenNames.Card} component={Card} />
            <Stack.Screen name={ScreenNames.SwitchesActiviesDetailView} component={SwitchesActiviesDetailView} />
        </Stack.Navigator>
    </RootView>
)