import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { SleepOasisHeaderIcon } from './src/sleep-oasis-header-icon'
import { SleepOasisIcon } from './src/sleep-oasis-icon'


export const SwitchesActivitiesSecretsForSleepSleepOasis: CardTemplate = {
    cardId: "a57569ad-da30-426b-bbf9-b7af0fc2fbb1",
    title: 'Sleep oasis',
    Header: SleepOasisHeaderIcon,
    Icon: SleepOasisIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Developing the right habits to transform your bedroom into the optimal sleeping oasis."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Create a bedtime routine: Do relaxing activities before bed, like reading a book, taking a warm bath, or listening to calm music. These can help your body and mind prepare for sleep. "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Stick to a schedule: Try to go to bed and wake up at the same time every day, even on weekends. This helps your body get into a routine and makes it easier to fall asleep."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Make your bedroom sleep friendly: Create a comfortable sleep environment. Make sure your room is cool, quiet, and dark. You can use curtains or blinds to block out any light, and earplugs or a white noise machine to block out any noise that might disturb your sleep."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Avoid screens before bed: Try to avoid using phones, tablets, or watching TV right before bedtime. The bright lights from screens can make it harder for your brain to relax and fall asleep. Instead, you can read a book or listen to a story."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Create a comfortable sleep environment: Ensure that your mattress and pillows provide proper support and are comfortable for you. Having a clean, tidy, and cozy bedroom can also contribute to a peaceful sleep environment."} />

        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Remember, getting enough sleep is important for your growth, learning, and overall wellbeing. It helps you feel better and be more focused during the day. So, make sleep a priority and develop good sleep habits early on!' />
        </>

    ),
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.SECRETS_FOR_SLEEP,
    whyDoesItWorkText: "Getting good sleep is important for our overall wellbeing. From a psychological perspective, following certain strategies can help improve the quality of our sleep. Establishing a consistent bedtime routine, avoiding screen time before bed, and creating a sleep-friendly environment with darkness, quietness, and coolness can promote relaxation and signal our brains that it's time to sleep.",
    audioUrl: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Sleep+oasis.m4a',
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.SECRETS_FOR_SLEEP],
    action: [CardActionType.Action],
    level: []
}

