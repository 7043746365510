import { DrawerContentComponentProps, DrawerContentScrollView } from '@react-navigation/drawer'
import { StyleSheet } from 'react-native'
import { Divider } from '../common/divider'
import { MenuItem } from '../common/menu-item'
import { MenuItemLogout } from '../common/menu-item-logout'
import { ScreenNames } from '../common/screen-names'
import { envBackgroundColor } from '../env'

export const AdminSideMenuContent = ({ navigation }: DrawerContentComponentProps) => {
    const navigateToWelcome = () => {
        navigation.navigate(ScreenNames.AdminWelcome)
    }

    const navigateToSchoolsMain = () => {
        navigation.navigate(ScreenNames.AdminSchoolsMain)
    }

    const navigateToMigrationTools = () => {
        navigation.navigate(ScreenNames.MigrationTools)
    }

    const navigateToResetPassword = () => {
        navigation.navigate(ScreenNames.AdminResetPassword)
    }

    const navigateToSearchTeacher = () => {
        navigation.navigate(ScreenNames.AdminSearchTeacher)
    }

    const navigateToSearchStudent = () => {
        navigation.navigate(ScreenNames.AdminSearchStudent)
    }

    const navigateToSwitchCardsCsv = () => {
        navigation.navigate(ScreenNames.AdminSwitchCardsCsv)
    }

    const navigateToOnBoardingStudent = () => {
        navigation.navigate(ScreenNames.AdminStudentOnboarding)
    }

    const navigateToOnBoardingTeacher = () => {
        navigation.navigate(ScreenNames.AdminTeacherOnboarding)
    }

    const navigateToMFA = () => {
        navigation.navigate(ScreenNames.AdminConfigMFA)
    }

    return (
        <DrawerContentScrollView style={styles.drawer}>
            <MenuItem name="Welcome" onPress={navigateToWelcome} />
            <MenuItem name="Schools" onPress={navigateToSchoolsMain} />
            <MenuItem name="Tools" onPress={navigateToMigrationTools} />
            <MenuItem name="Reset Password" onPress={navigateToResetPassword} />
            <MenuItem name="Onboarding Student" onPress={navigateToOnBoardingStudent} />
            <MenuItem name="Onboarding Teacher" onPress={navigateToOnBoardingTeacher} />
            <MenuItem name="Search Teacher" onPress={navigateToSearchTeacher} />
            <MenuItem name="Search Student" onPress={navigateToSearchStudent} />
            <MenuItem name="Switch Cards CSV" onPress={navigateToSwitchCardsCsv} />
            <MenuItem name="Config MFA" onPress={navigateToMFA} />
            <Divider />
            <MenuItemLogout />
        </DrawerContentScrollView>
    )
}

const styles = StyleSheet.create({
    drawer: {
        flex: 1,
        backgroundColor: envBackgroundColor,
        height: '100%',
    },
})