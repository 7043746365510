import Svg, { Path } from "react-native-svg"

function IconEating() {
  return (
    <Svg
      viewBox="0 0 159 159"
      fill="none"
    >
      <Path
        d="M79.176 157.802c43.424 0 78.626-35.202 78.626-78.626C157.802 35.752 122.6.55 79.176.55 35.752.55.55 35.752.55 79.176c0 43.424 35.202 78.626 78.626 78.626z"
        stroke="#5A5B5E"
        strokeWidth={8}
      />
      <Path
        d="M36.788 22.744c-1.635-1.84-3.901-1.611-4.668 2.523-.586 3.132-2.581 15.053 6.404 30.208C52.65 79.316 69.11 87.293 76.956 87.242c5.986-.038 7.475 3.589 11.95 10.78 10.304 16.574 21.838 36.712 31.567 37.714 2.119.254 4.25-.494 5.873-2.054 2.515-2.815 3.022-6.823-1.916-12.365L36.788 22.744zM129.977 41.539l-.079-.089c-.812-.9-2.12-.9-2.943 0l-18.049 20.303c-.327.571-.845.939-1.443 1.015-.597.076-1.184-.152-1.612-.634a2.367 2.367 0 01-.564-1.814c.068-.672.406-1.268.902-1.623l18.049-20.303c.812-.913.812-2.384 0-3.297-.811-.913-2.119-.913-2.942 0L103.247 55.4c-.756 1.04-2.12 1.205-3.056.38-.732-1.065-.586-2.587.339-3.436l18.049-20.304c.395-.43.609-1.027.609-1.648a2.5 2.5 0 00-.609-1.649c-.395-.444-.924-.685-1.466-.685-.541 0-1.082.254-1.465.685l-18.05 20.303c-.755 1.04-2.119 1.218-3.055.394-.733-1.066-.586-2.587.338-3.437l18.084-20.29c.394-.432.608-1.028.608-1.65 0-.62-.214-1.217-.608-1.648l-.079-.089c-.756-.85-1.962-.913-2.785-.152l-10.98 10.158C87.666 42.922 84.115 48.488 84.07 57.454a24.811 24.811 0 01-1.646 9.448l7.937 8.928a17.682 17.682 0 018.399-1.852c7.97-.05 12.919-4.045 22.333-16.93l9.03-12.351c.677-.926.62-2.283-.135-3.145l-.011-.013z"
        fill="#5A5B5E"
        strokeWidth={8}
      />
      <Path
        d="M31.805 121.54c-3.653 5.542-3.27 9.092-.688 11.984 2.582 2.904 5.727 3.335 10.654-.774 14.092-11.756 23.923-26.948 33.832-40.505-4.43-.52-8.737-1.978-12.694-4.299-10.71 9.714-22.051 19.847-31.104 33.581v.013z"
        fill="#5A5B5E"
        strokeWidth={8}
      />
    </Svg>
  )
}

export default IconEating
