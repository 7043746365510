import moment from "moment"
import { useState } from "react"
import { Feather, Ionicons } from '@expo/vector-icons'
import { Platform, View, StyleSheet, TouchableOpacity, useWindowDimensions } from "react-native"
import { DefaultFontSize } from "../common/const"
import { DefaultButton, DefaultButtonTypes } from "../common/default-button"
import { DefaultText } from "../common/default-text"
import InputDefault from "../common/input-default"
import { IconClassAdmin } from "../icon/icon-class-admin"
import { ScreenNames } from "../common/screen-names"
import { showAlert, showGenericErrorAlert } from "../common/universal-alert"
import { useMutation, useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { deleteTeacherClass, deleteClass, updateClass } from "../../common/graphql/mutations"
import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import { SchoolAdminNavigationParams } from "./school-admin-navigation-params"
import { SchoolAdminAddTeacherToClass } from "./school-admin-add-teacher-to-class"
import { SchoolAdminClassTeacherRow } from "./school-admin-class-teacher-row"
import { SchoolAdminTableEditComponent, SchoolAdminTableSetEditComponent, SCHOOL_ADMIN_ADD_TEACHER } from "./school-admin-const"
import _ from "lodash"
import SchoolAdminButtonMinus from "./school-admin-button-minus"
import { Class, GetLastCheckInSessionByClassIDQuery, GetLastCheckInSessionByClassIDQueryVariables, Teacher } from "../../common/API"
import { DecidaColors } from "../../common/decida-colors"
import { GetSchoolQueryResponseClass, GetSchoolQueryResponseTeacherClass } from "../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query"
import { getLastCheckInSessionByClassID } from "../../common/graphql/queries"

type Props = {
    clazz: Class,
    teachers?: Teacher[],
    editComponent: SchoolAdminTableEditComponent,
    setEditComponent: SchoolAdminTableSetEditComponent,
}

export const SchoolAdminClassRow = ({
    clazz,
    teachers,
    editComponent,
    setEditComponent,
}: Props) => {
    const [removeTeacherClassMutation] = useMutation(gql`${deleteTeacherClass}`);
    const [removeClassMutation] = useMutation(gql`${deleteClass}`);
    const [updateClassMutation] = useMutation(gql`${updateClass}`);
    const [className, setClassName] = useState(clazz.name)
    const navigation = useNavigation<StackNavigationProp<SchoolAdminNavigationParams>>()
    const { width } = useWindowDimensions()

    const editMode = editComponent?.class === clazz.id

    const classTeachers = clazz?.teachers?.items
        .flatMap((tClass) => {
            const teacherData = teachers?.find((t) => t.id === tClass?.teacherID)

            if (!teacherData) {
                return []
            }

            return [{
                teacher: teacherData,
                teacherClass: tClass
            }]
        })
        .filter(c => !c.teacherClass?._deleted && !c.teacher._deleted)

    const handleRemoveClass = () => {
        showAlert({
            title: `Are you sure you want to remove ${clazz.name} from this school?`,
            message: 'This action cannot be undone.',
            leftButtonText: 'Cancel',
            rightButtonText: 'Delete',
            onRightButtonPress: async () => {
                try {
                    clazz.teachers?.items.forEach(t => {
                        removeTeacherClassMutation({
                            variables: {
                                input: {
                                    id: t?.id,
                                    _version: t?._version
                                }
                            }
                        })
                    })

                    removeClassMutation({
                        variables: {
                            input: {
                                id: clazz?.id,
                                _version: clazz?._version
                            }
                        }
                    })
                } catch {
                    showGenericErrorAlert()
                }
            }
        })
    }

    const toggleAddTeacher = () => {
        if (editComponent?.class === clazz.id && editComponent.teacher === SCHOOL_ADMIN_ADD_TEACHER) {
            setEditComponent(null)
        } else {
            setEditComponent({
                class: clazz.id,
                teacher: SCHOOL_ADMIN_ADD_TEACHER,
            })
        }
    }

    const goToManageClass = () => {
        navigation.navigate(ScreenNames.SchoolAdminClassMain, { screen: ScreenNames.SchoolAdminManageClass, params: { classID: clazz?.id } })
    }

    const handlePressEditClass = () => {
        if (editMode) {
            setEditComponent(null)
        } else {
            setEditComponent({
                class: clazz.id
            })
        }
    }

    const handleSaveClassName = async () => {
        try {
            if (className !== clazz.name) {
                await updateClassMutation({
                    variables: {
                        input: {
                            id: clazz.id,
                            name: className,
                            _version: clazz._version,
                        }
                    }
                })
            } else {
                setEditComponent(null)
            }
        } catch (error) {
            console.log("handleSaveClassName", error)
        }
    }

    const { data: lastCheckInSessionResponse } = useQuery<GetLastCheckInSessionByClassIDQuery, GetLastCheckInSessionByClassIDQueryVariables>(gql`${getLastCheckInSessionByClassID}`, { variables: { classID: clazz.id }, skip: !clazz })
    const lastCheckInSession = lastCheckInSessionResponse?.getLastCheckInSessionByClassID

    return (
        <View key={clazz.id} style={Platform.OS === 'ios' ? styles.tableContainerIos : undefined}>
            <View style={[styles.tableDataWrapper]}>
                <View style={[styles.leftContainerWrapper, clazz.goMode ? styles.goModeStyles : {}]}>
                    <View style={styles.leftContainer}>
                        <View style={styles.dataContainer}>
                            {clazz.goMode && <DefaultText>GO Group</DefaultText>}
                            {editMode ? (
                                <>
                                    <InputDefault value={className} onChangeText={setClassName} placeholder="Class name" customInputStyle={styles.classNameInput} />
                                    <DefaultButton type={DefaultButtonTypes.Small} style={Platform.OS === 'web' ? styles.confirmButton : styles.confirmButtonIos} onPress={handleSaveClassName}>
                                        <Ionicons name="ios-checkmark-sharp" size={24} color={DecidaColors.White} />
                                    </DefaultButton>
                                </>
                            ) : (
                                <DefaultText style={styles.classText}>{clazz.name}</DefaultText>
                            )}
                        </View>

                        <View style={{ flex: 4 }}>
                            {classTeachers?.map((teacherData, index) => (
                                <View key={teacherData.teacher?.id} style={{ zIndex: -index }}>
                                    <SchoolAdminClassTeacherRow editComponent={editComponent} setEditComponent={setEditComponent} currentClass={clazz as GetSchoolQueryResponseClass} teacher={teacherData.teacher} teacherClass={teacherData.teacherClass as GetSchoolQueryResponseTeacherClass} />
                                    {
                                        index < classTeachers.length - 1 && <View style={[styles.border, { zIndex: -index - 1 }]} />
                                    }
                                </View>
                            ))}
                        </View>

                        <TouchableOpacity onPress={handlePressEditClass} style={styles.editButton}>
                            <Feather name='edit' size={30} color={DecidaColors.Green} />
                        </TouchableOpacity>
                    </View>

                    {editMode && editComponent?.teacher === SCHOOL_ADMIN_ADD_TEACHER && <SchoolAdminAddTeacherToClass clazz={clazz as GetSchoolQueryResponseClass} setEditComponent={setEditComponent} />}

                    <View style={styles.addTeacherButton}>
                        <DefaultButton type={DefaultButtonTypes.Small} plusIcon onPress={toggleAddTeacher} style={styles.addTeacherButtonContainer}>
                            Teacher
                        </DefaultButton>
                    </View>
                </View>

                <View style={styles.dataContainer}>
                    <DefaultButton
                        type={DefaultButtonTypes.Small}
                        style={styles.manageClassButton}
                        onPress={goToManageClass}
                    >
                        <IconClassAdmin stylefill={DecidaColors.White} />
                        {width > 900 && (
                            <DefaultText textProps={{ numberOfLines: 1 }} style={styles.manageClassText}>
                                Manage Class
                            </DefaultText>
                        )}

                    </DefaultButton>
                </View>

                <View style={styles.rightContainer}>
                    {/* Chat */}
                    <View style={[styles.classInfoContainer]}>
                        <DefaultText style={styles.tableTextOn}>Chat request: <DefaultText style={styles.tableText}>{clazz.toggleRequestForChat ? "On" : "Off"}</DefaultText></DefaultText>
                    </View>

                    {/* Level */}
                    <View style={[styles.classInfoContainer]}>
                        <DefaultText style={styles.tableTextOn}>Level: <DefaultText style={styles.tableText}>{clazz.emotionIntensityLevel ? clazz.emotionIntensityLevel : "-"}</DefaultText></DefaultText>
                    </View>

                    {/* Individual stats visible to student */}
                    <View style={[styles.classInfoContainer]}>
                        <DefaultText style={styles.tableTextOn}>Individual stats visible to students: <DefaultText style={styles.tableText}>{clazz.toggleIndividualStudentStats ? "On" : "Off"}</DefaultText></DefaultText>
                    </View>

                    {/* Last check-ind */}
                    <View style={[styles.classInfoContainer]}>
                        <DefaultText style={[styles.tableTextOn]}>Last check in: <DefaultText style={styles.tableText}>{lastCheckInSession ? moment(lastCheckInSession?.updatedAt).format('h:mm:a DD/MM/YYYY') : "No data as yet"}</DefaultText></DefaultText>
                    </View>
                </View>

                {/* Remove Class button */}
                <View style={styles.dataContainer}>
                    <SchoolAdminButtonMinus title="Remove Class" onPress={handleRemoveClass} />
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    tableContainerIos: {
        flex: 1
    },
    tableDataWrapper: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 0,
        marginVertical: 20,
        backgroundColor: DecidaColors.White,
    },
    leftContainerWrapper: {
        flex: 5,
        borderWidth: 1,
        borderColor: DecidaColors.AppleSystemGray2Dark,
        borderRadius: 20,
        paddingHorizontal: 20,
        paddingTop: 10,
    },
    leftContainer: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: 20,
        paddingTop: 10,
        paddingBottom: 20,
    },
    dataContainer: {
        width: '100%',
        height: '100%',
        flex: 1,
        marginRight: 5,
    },
    classInfoContainer: {
        width: '100%',
        marginBottom: 20
    },
    classNameInput: {
        marginBottom: 0
    },
    confirmButton: {
        width: '50%',
        minWidth: 40,
        alignSelf: 'flex-end',
        zIndex: -1
    },
    confirmButtonIos: {
        width: 60,
        alignSelf: 'flex-end',
        zIndex: -1
    },
    classText: {
        fontSize: DefaultFontSize + 6,
        color: DecidaColors.AppleSystemGray2Dark,
        fontWeight: '900',
    },
    editButton: {
        position: 'absolute',
        top: -20,
        left: -5
    },
    addTeacherButton: {
        position: 'absolute',
        bottom: -20,
        left: '22%'
    },
    manageClassButton: {
        width: '100%',
        backgroundColor: DecidaColors.Sad,
        height: 40,
        marginLeft: 10,
    },
    rightContainer: {
        flex: 2,
        flexDirection: 'column',
        borderWidth: 1,
        borderColor: DecidaColors.AppleSystemGray2Dark,
        borderRadius: 20,
        marginHorizontal: 20,
        padding: 20,
    },
    tableText: {
        fontSize: 16,
        color: DecidaColors.AppleSystemGray2Dark,
    },
    manageClassText: {
        color: DecidaColors.White,
        marginLeft: 5,
        fontSize: 12,
        fontWeight: '700'
    },
    addTeacherButtonContainer: {
        width: 120
    },
    border: {
        height: 1,
        width: '100%',
        backgroundColor: DecidaColors.Black,
        marginVertical: 20
    },
    tableTextOn: {
        fontSize: 16,
        color: DecidaColors.Green,
        fontWeight: 'bold'
    },
    goModeStyles: {
        backgroundColor: DecidaColors.AppleSystemGray6Light
    },
})