import { Auth } from 'aws-amplify'
import { rvUserGroup, rvLoggedIn, rvCurrentUser } from '../login/login-state'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { rvTeacherCurrentCheckIn, rvTeacherCurrentStudents, rvTeacherCurrentTeacher, rvTeacherCurrentTeacherClasses, rvTeacherSchoolStudents, rvTeacherSelectCurrentClassId } from '../teacher/teacher-state'
import { apolloClient, rvCurrentClass, rvUpdateAvailable } from './common-state'
import { rvCurrentStudent } from '../student/use-student-state'
import { Platform } from 'react-native'
import { ScreenNames } from './screen-names'
import { navigate } from './root-navigation'
import gql from 'graphql-tag'
import { removeDevicePushToken } from '../../common/graphql/mutations'
import { rvDevicePushToken } from '../web-view/web-view-state'
import { stopHeartbeat } from '../web-view/heartbeat'

export const logout = async () => {
    stopHeartbeat()
    const devicePushToken = rvDevicePushToken()

    if (devicePushToken) {
        await apolloClient.mutate({
            mutation: gql`${removeDevicePushToken}`,
            variables: {
                input: {
                    devicePushToken,
                }
            }
        })
    }

    apolloClient.clearStore()

    await Auth.signOut()
        .finally(async () => {
            rvLoggedIn(false)

            await AsyncStorage.clear()
            rvUserGroup(undefined)
            rvCurrentUser(undefined)
            rvCurrentStudent(undefined)
            rvTeacherCurrentCheckIn(undefined)
            rvTeacherCurrentTeacher(undefined)
            rvTeacherSelectCurrentClassId(undefined)
            rvTeacherCurrentTeacherClasses(undefined)
            rvTeacherSchoolStudents(undefined)
            rvTeacherCurrentStudents([])
            rvCurrentClass(undefined)
            rvUpdateAvailable(false)

            if (window && Platform.OS === 'web') {
                window.location.href = `/${ScreenNames.Login}`
            } else {
                navigate(ScreenNames.Login)
            }
        })
}