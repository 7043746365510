import { useReactiveVar } from '@apollo/client'
import { useState } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { Br } from '../../../common/br'
import { DefaultButton } from '../../../common/default-button'
import { DefaultText } from '../../../common/default-text'
import { Link } from '../../../common/link'
import { showAlert } from '../../../common/universal-alert'
import { Worksheets } from '../../../common/worksheets-imports'
import { CardColorScheme, rvCard } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles, commonStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { TraceBreathingIcon } from '../../explore/src/trace-breathing-icon'
import { TraceBreathingIconInverted } from '../../explore/src/trace-breathing-icon-inverted'
import { TraceBreathingImage1 } from './src/trace-breathing-image1'
import { TraceBreathingImage2 } from './src/trace-breathing-image2'
import { TraceBreathingImage3 } from './src/trace-breathing-image3'
import { TraceBreathingImage4 } from './src/trace-breathing-image4'
import { TraceBreathingImage5 } from './src/trace-breathing-image5'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'


const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth < 600

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        maxWidth: 500,

    },
    image: {
        height: windowWidthcondition ? 200 : 300,
        width: windowWidthcondition ? 300 : 500,
        maxWidth: 500,
        maxHeight: 300,
    }
})

const TraceBreathing = () => {

    const [traceBreathingTab, setTraceBreathingTab] = useState(1)

    const ShowTabTwo = () => {
        setTraceBreathingTab(2)
    }
    const ShowTabThree = () => {
        setTraceBreathingTab(3)
    }
    const ShowTabFour = () => {
        setTraceBreathingTab(4)
    }
    const ShowTabOne = () => {
        setTraceBreathingTab(1)
    }
    const color = useReactiveVar(rvCard)?.colorScheme

    return (
        <View style={styles.container}>



            {traceBreathingTab === 1 ?
                <>
                    <DefaultTextWithoutArrows textdata={"Practice each breathe picture by tracing the lines with your finger as you breathe in and out."} />
                    <View style={commonCardStyles.imagecontainer}>
                        <View style={styles.image}>
                            <TraceBreathingImage1 />
                        </View>
                    </View>
                    <View style={commonCardStyles.buttoncontainer}>
                        <DefaultButton onPress={ShowTabTwo} style={{ backgroundColor: color }}>
                            Next
                        </DefaultButton>

                    </View>
                </>
                : <View />}
            {traceBreathingTab === 2 ? <>
                <DefaultTextWithoutArrows textdata={"Practice each breathe picture by tracing the lines with your finger as you breathe in and out."} />
                <View style={commonCardStyles.imagecontainer}>
                    <View style={styles.image}>
                        <TraceBreathingImage2 />
                    </View>
                </View>
                <View style={commonCardStyles.buttoncontainer}>
                    <DefaultButton onPress={ShowTabThree} style={{ backgroundColor: color }}>
                        Next
                    </DefaultButton>
                </View>
            </> : <View />}
            {traceBreathingTab === 3 ? <>
                <DefaultTextWithoutArrows textdata={"Practice each breathe picture by tracing the lines with your finger as you breathe in and out."} />
                <View style={commonCardStyles.imagecontainer}>
                    <View style={styles.image}>
                        <TraceBreathingImage3 />
                    </View>
                </View>
                <View style={commonCardStyles.buttoncontainer}>
                    <DefaultButton onPress={ShowTabFour} style={{ backgroundColor: color }}>
                        Next
                    </DefaultButton>
                </View>
            </> : <View />}
            {traceBreathingTab === 4 ? <>
                <DefaultTextWithoutArrows textdata={"Practice each breathe picture by tracing the lines with your finger as you breathe in and out."} />
                <View style={commonCardStyles.imagecontainer}>
                    <View style={styles.image}>
                        <TraceBreathingImage4 />
                    </View>
                </View>
                <View style={commonCardStyles.buttoncontainer}>
                    <DefaultButton onPress={ShowTabOne} style={{ backgroundColor: color }}>
                        All Done!
                    </DefaultButton>
                </View>
            </> : <View />}
        </View>
    )
}

export const SwitchesEmotionsExcitedTraceBreathing: CardTemplate = {
    cardId: "34511d32-b3fe-4054-afd4-9e7cc2560315",
    title: 'Trace breathing',
    Header: TraceBreathingIconInverted,
    Icon: TraceBreathingIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Helping calm minds and redirect energy to focus on tasks."} />
        </>
    ),
    CardContent: () => {


        const NavigateToStart = () => {
            showAlert({
                alertStyle: { maxWidth: 600 },
                title: 'Start Tracing',
                message: <TraceBreathing />,
                leftButtonText: 'Ok',

            })
        }
        const color = useReactiveVar(rvCard)?.colorScheme
        return (
            <View style={commonCardStyles.container}>
                <DefaultTextWithoutArrows textdata={"Select ‘Get Started’ to launch the trace breathing exercise."} />
                <DefaultTextWithoutArrows textdata={"Do each activity at least three times before moving to the next one."} />
                <View style={commonCardStyles.imagecontainer}>
                    <View style={commonCardStyles.image}>
                        <TraceBreathingImage1 />
                    </View>
                </View>
                <View style={commonCardStyles.buttoncontainer}>
                    <DefaultButton onPress={NavigateToStart} style={{ backgroundColor: color }}>
                        Get started
                    </DefaultButton>
                </View>
            </View>

        )
    },
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: 'Deep breathing lowers your stress hormones by up to 30%, which has an instant physiological impact on your body.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice meditative breathing exercises.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by explaining the importance of calming exercises in transitioning from the "flight, fight, or freeze" mode to the "rest and digest" mode.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that deep breathing helps increase oxygen in the bloodstream, opening capillaries and physically calming the body to lower stress.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If available, encourage students to use the Trace Breathing switch in the Switch4Schools app on their devices. Otherwise, distribute the Trace Breathing worksheets.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Trace Breathing Exercises (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that there are four trace breathing exercises for students to practice.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Each exercise involves slowly breathing in and out while drawing their finger (or a pencil) over the shape on the worksheet or app.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Students should repeat each exercise three times.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- Remind them to keep their breath easy, gentle, and slow, without forcing it or breathing too fast.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Practice Session (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to begin the trace breathing exercises.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to focus on the movement of their finger or pencil, coordinating it with their breath.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Monitor the class to ensure students are engaged in the exercise.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allow students to complete the four trace breathing exercises.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Debrief and Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather students to discuss their experience with the trace breathing exercises.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask how the exercise made them feel and if they noticed a calming effect.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of incorporating such practices into daily routines to manage stress.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- Encourage students to use the Trace Breathing switch in the Switch4Schools app whenever they feel the need for a calming moment.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reiterate the benefits of meditative breathing exercises and how they can be powerful tools for self regulation and stress reduction. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to continue practicing these techniques as part of their overall wellbeing. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If they have devices, suggest exploring other mindfulness exercises in the Switch4Schools app.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Trace Breathing worksheet' link={Worksheets.traceBreathing.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Feeling Excited: A Guide To Your Emotions - </DefaultText>
                        <DefaultTextWithLink text="https://divethru.com/feeling-excited-a-guide-to-your-emotions/" />
                        <Br />
                        <DefaultText style={CommonStyles.lessonPlanText}>The Effect of Diaphragmatic Breathing on Attention, Negative Affect and Stress in Healthy Adults - </DefaultText>
                        <DefaultTextWithLink text='https://www.frontiersin.org/articles/10.3389/fpsyg.2017.00874/full' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Create a quiet calm space.' />
            <DefaulTextWithTipsIcon textdata='Making breathing audible increases consciousness of the speed of breath. ' />
            <DefaulTextWithTipsIcon textdata='Try slowing the pace after each activity. ' />
            <DefaulTextWithTipsIcon textdata='Repeat the activity as many times as needed. ' />
            <DefaulTextWithTipsIcon textdata='Reflect on which version was the most effective and why.' />

        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Trace+Breathing.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Excited]
}

