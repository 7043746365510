import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import SwitchCardVideo from '../../common/switch-card-video'

const perspectiveTakingVideo1: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/tqz7UcCgbLA",
    title: ""
}
const perspectiveTakingVideo2: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/e_Nkb3vL0pQ",
    title: ""
}

export const SwitchesLessonPlansPerspectiveTaking: CardTemplate = {
    cardId: "b65ee006-5419-4acd-9d69-b9362d5e9709",
    title: 'Perspective taking',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice perspective taking and consider how others may feel in specific situations. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes </DefaultText>
                        
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students in a circle and create a welcoming and inclusive environment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of perspective taking by explaining that it means trying to see things from someone else's point of view.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students if they have ever had different opinions or thoughts about something than their friends or family members.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Read Aloud and Discussion (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce a picture book on perspective taking, such as "Stand in My Shoes: Kids Learning About Empathy" by Bob Sornson (or play this video Stand in my Shoes by Bob Sornson). </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Read the book aloud, pausing at appropriate moments to encourage students to think about how characters might be feeling or thinking.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a group discussion about the characters' perspectives and how they might see things differently.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask open-ended questions like, "How do you think the character felt when...?" and "Why might they have thought that way?".</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Perspective Flip (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute a different roles or perspectives card to each student, briefly explaining the role or perspective associated with it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the students imagine themselves as someone in that role or perspective.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to think about how that person might feel or what they might think in a specific situation. For example:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>- You're playing in the park and notice someone who fell down and hurt themselves.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>- You're at the beach, and you see a seagull trying to steal someone's sandwich.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>- You're playing with a group of friends, and one friend is feeling left out.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Arrange chairs in a circle, facing inward.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Go around the circle, giving each student a turn to share their thoughts and feelings from their assigned character's perspective.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage active listening and respectful responses during the empathy circle.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students together and ask them to reflect on what they have learned about perspective taking.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a brief discussion on the importance of understanding others' points of view and how it helps in building positive relationships and resolving conflicts.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude the lesson by expressing appreciation for their participation and encouraging them to continue practicing perspective taking.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Picture books (or video) on perspective taking e.g., "Stand in My Shoes: Kids Learning About Empathy" by Bob Sornson</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Character role cards' link={Worksheets.perspectiveTakingCards.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Importance of Emotional Intelligence (Incl. Quotes)Top of Form -</DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/importance-of-emotional-intelligence/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Perspective Taking -</DefaultText>
                        <DefaultTextWithLink text='https://youtu.be/tqz7UcCgbLA' />
                        <SwitchCardVideo video={perspectiveTakingVideo1} />

                        <DefaultText style={CommonStyles.lessonPlanText}>Stand in my Shoes by Bob Sornson -</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=e_Nkb3vL0pQ' />
                        <SwitchCardVideo video={perspectiveTakingVideo2} />

                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubTextDetails: {
        paddingLeft: 120,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        marginVertical: 10,
    },
    durationText: {
        marginBottom: 10,
        color: DecidaColors.Gray,
        alignSelf: 'center'
    },
})
