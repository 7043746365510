import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { StretchingIcon } from '../../explore/src/stretching-icon'
import { StretchingIconInverted } from '../../explore/src/stretching-icon-inverted'
import { StretchingImage1 } from './src/stretching-image1'
import { StretchingLessonplanImage1 } from './src/stretching-lessonplan-image1'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'

export const SwitchesEmotionsSadStretching: CardTemplate = {
    cardId: "adb7d673-8c79-4ad1-ade9-31b99f4cc5ce",
    title: 'Stretching',
    Header: StretchingIconInverted,
    Icon: StretchingIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <StretchingImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Reducing stress and increasing the blood flow through the body when you have been still for a while."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Find a quiet space with room to spread out your arms wide."} />
                <Text style={{ textDecorationLine: 'underline', color: 'blue', paddingLeft: 40, fontSize: 20, }} onPress={() => {
                    openUrlWebView(Worksheets.stretching.uri);
                }}>Stretching Guide Sheet</Text>
                <DefaultTextWithoutArrows textdata={"Complete each of the stretch poses listed in Stretching Guide Sheet, taking at least 10 deep breaths before moving to the next pose."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: 'Stretching reduces the stress and pain contained in muscle tension. Stretching has been shown to increase serotonin levels — i.e., the hormone that helps stabilise our mood, reduce stress, and overall makes us feel good — which causes a decrease in depression and anxiety.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Experience how low impact exercise has a positive impact on mental state.   </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice a simple routine of stretches.  </DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain to students the multiple benefits of stretching, including improved flexibility, mindfulness, increased strength, better balance, and a peaceful meditative component.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of being mindful and focusing on breathing during the stretching session.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Preparation and Safety (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Move students to a space where they can stretch out their arms wide without touching each other.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Clearly communicate that everyone should feel free to sit quietly and focus on breathing if they experience any pain or have physical limitations that impact their ability to participate.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Breathing Session (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Start with a short breathing session sitting in an easy cross legged pose.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Guide students through focused breathing exercises to calm and relax them before the stretching routine.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Guided Stretching Routine (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Stretching Guide Sheet or use the link in the Switch4Schools app.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Demonstrate or ask for a volunteer student to assist as you guide the class through the routine.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of listening to their bodies and not pushing beyond their comfort levels.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 4: Reflection and Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After the stretching routine, ask students to sit quietly and reflect on how they feel physically and mentally.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Open a brief discussion about the experience, asking students to share any observations or sensations.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key benefits of stretching for flexibility, mindfulness, strength, and balance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to incorporate mindful stretching into their daily routines for overall wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students are using the Switch4Schools app independently, direct students to find additional stretching resources for future practice.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Space to move/stretch</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Stretching Guide Sheet' link={Worksheets.stretching.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The simple act of stretching -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.research.colostate.edu/healthyagingcenter/2021/06/23/the-simple-act-of-stretching/' link='https://www.research.colostate.edu/healthyagingcenter/2021/06/23/the-simple-act-of-stretching/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The effects of Pilates and yoga participant’s on engagement in functional movement and individual health level -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6732550/' link='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6732550/' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='You may like to put a towel or mat on the floor to make it more comfortable.' />
            <DefaulTextWithTipsIcon textdata='Adding stretches to your daily morning routine will not only help your mental health but will also assist with your physical health, improving posture and reducing risk of injury. ' />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Stretching.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual, CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Sad]
}
