import { useReactiveVar } from '@apollo/client'
import { AntDesign } from '@expo/vector-icons'
import { useState } from 'react'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultFontSize } from '../common/const'
import DefaultModal from '../common/default-modal'
import { DefaultText } from '../common/default-text'
import StepperDot from '../common/stepper-dot'
import { useResponsive } from '../common/use-responsive'
import { TeacherDashboardInfoSteps1, TeacherDashboardInfoSteps2, TeacherDashboardInfoSteps3, TeacherDashboardInfoSteps4 } from './teacher-dashboard-info-steps'
import { rvTeacherDashboardInfoModal } from './teacher-state'


const TeacherDashboardInfoModal = () => {
    const [stepIndex, setStepIndex] = useState(0)
    const totalSteps = 4
    const lastStep = stepIndex + 1 === totalSteps
    const showModal = useReactiveVar(rvTeacherDashboardInfoModal)
    const { responsiveFontSize } = useResponsive()

    const handleNext = () => {
        if (lastStep) {
            toggleModal()
            return
        }

        if (stepIndex >= 0) {
            setStepIndex(prev => prev + 1)
        }
    }

    const handlePrevious = () => {
        if (stepIndex >= 0) {
            setStepIndex(prev => prev - 1)
        }
    }

    const renderContent = () => {
        switch (stepIndex) {
            case 0:
                return <TeacherDashboardInfoSteps1 handleNext={handleNext} />
            case 1:
                return <TeacherDashboardInfoSteps2 handleNext={handleNext} handlePrevious={handlePrevious} />
            case 2:
                return <TeacherDashboardInfoSteps3 handleNext={handleNext} handlePrevious={handlePrevious} />
            case 3:
                return <TeacherDashboardInfoSteps4 handleNext={handleNext} handlePrevious={handlePrevious} />

            default:
                break;
        }
    }

    const handlePressDot = (index: number) => {
        setStepIndex(index)
    }

    const toggleModal = () => {
        rvTeacherDashboardInfoModal(!showModal)
    }

    return (
        <View>
            <DefaultModal contentStyle={styles.modal}>
                <View style={styles.modalHeaderContainer}>
                    <View />
                    <StepperDot total={totalSteps} currentStepIndex={stepIndex} onPressDot={handlePressDot} />
                    <TouchableOpacity style={styles.closeIcon} onPress={toggleModal}>
                        <AntDesign name="close" size={28} color={DecidaColors.Gray} />
                    </TouchableOpacity>
                </View>
                <DefaultText style={[styles.headerText, { fontSize: responsiveFontSize(DefaultFontSize + 2) }]}>
                    Dashboard
                </DefaultText>
                <ScrollView contentContainerStyle={{ paddingHorizontal: 10, height: '100%' }}>
                    {renderContent()}
                </ScrollView>
            </DefaultModal>
        </View >
    )
}
export default TeacherDashboardInfoModal


const styles = StyleSheet.create({
    modal: {
        height: '90%',
        maxHeight: 1000,
    },
    modalHeaderContainer: {
        alignItems: 'center',
        paddingBottom: 10,
        justifyContent: 'space-between',
        borderBottomColor: DecidaColors.Gray,
    },
    closeIcon: {
        position: 'absolute',
        right: 10,
        paddingHorizontal: 10,
    },
    headerText: {
        textAlign: 'center',
        fontSize: DefaultFontSize + 2,
        fontWeight: 'bold',
        marginTop: 20
    },
})