import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { TheInteruptorIcon } from '../../explore/src/the-interuptor-icon'
import { TheInteruptorIconInverted } from '../../explore/src/the-interuptor-icon-inverted'
import { TheInteruptorImage } from './src/the-interuptor-image'
import { TheInteruptorImagePreview1 } from './src/the-interuptor-image-preview1'
import { TheInteruptorImagePreview2 } from './src/the-interuptor-image-preview2'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'

export const SwitchesEmotionsAnxiousTheInteruptor: CardTemplate = {
    cardId: "dc187ce7-1088-4c67-bce1-f7635b5c4184",
    title: 'The interrupter',
    lessonPlan: true,
    Header: TheInteruptorIconInverted,
    Icon: TheInteruptorIcon,
    Images: () => (
        <>
            <TheInteruptorImage />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Stopping being overwhelmed by a single thought or stressor."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithoutArrows textdata={"Think of the thing that seems to be constantly worrying you. Now, choose one of the below super interrupters. "} />
                <DefaulttextWithRightArrow textdata={"Change your physical body position: Whatever you’re doing, do the opposite. If you’re hunched over with worry, stand up and put your arms out wide. If you’re under a blanket, go wash your face with cold water. "} />
                <DefaulttextWithRightArrow textdata={"Remind yourself that you’re safe: Ask yourself, ‘Is there a real danger in front of me, or am I actually safe and worried about something that’s no threat to me right now?’"} />
                <DefaulttextWithRightArrow textdata={"Exercise to redirect nervous energy: Get up and walk or pace or take a few minutes to clean something. Go outside for 5 minutes. Shorts bursts of activity can release that anxious energy."} />
                <DefaulttextWithRightArrow textdata={"Draw: Simply start to draw something, and try to be a little creative with how you are drawing. "} />
                <DefaulttextWithRightArrow textdata={"Use a mantra: Repeat a mantra to shift your mind away from anxious thoughts that play over and over in your head, such as, “These thoughts are uncomfortable, but not dangerous,” or “This, too, will pass.”"} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.EXTRA_SWITCHES,
    whyDoesItWorkText: `Sometimes the best way to kick ourselves out of negative thought patterns is to interrupt those thoughts through different activities or ideas. Interrupters disrupt the negative neural pathways by providing an alternative energy or counter thought, thus creating a more positive feeling that can be attached to the thought that is worrying you.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 10, color: DecidaColors.Gray }}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Explain the impact of long term stress and anxiety on the body.
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Identify personal interventions to use to manage anxious thoughts more effectively.
                        </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Ask whether students have every been so anxious they have felt sick. Have they ever herd the term, “Worry yourself sick”?
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Explain that constant worrying, negative thinking, and always expecting the worst can take a toll on your emotional and physical health.
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Ask students to think about what happens to your body when anxious and complete the What Does Anxiety Do To My Body worksheet (link below in the materials section).
                        </DefaultText>

                        <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            <View style={{ width: 200, height: 200, marginVertical: 10, }}>
                                <TheInteruptorImagePreview1 />
                            </View>
                            <View style={{ width: 200, height: 200, marginVertical: 10, }}>
                                <TheInteruptorImagePreview2 />
                            </View>
                        </View>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Ask students to think about a current anxious thought they are experiencing and ask them to note it down on the worksheet.
                        </DefaultText>

                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Explain that there are lots of different things you can do to “interrupt” anxious thoughts. Explain/demonstrate each of the provided interrupters. (Thought Interrupters cards link in the materials below)
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Have students brainstorm any other interrupters they use/or would like to try and add these to their worksheet.
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Encourage students to select one or more interrupters they would like to try, and if possible follow up at a future date to ask students to evaluate the effectiveness of their interrupter activity and consider alternatives as needed.
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• If students have their own device, ask them to find the switch in the Switch4Schools app.
                        </DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Pen/pencils </DefaultText>
                        <Text style={{ textDecorationLine: 'underline', color: 'blue', paddingLeft: 40, fontSize: 20, }} onPress={() => {
                            openUrlWebView(Worksheets.whatDoesAnxietyDoToMyBody.uri);
                        }}>• What Does Anxiety Do To My Body Worksheets</Text>
                        <Text style={{ textDecorationLine: 'underline', color: 'blue', paddingLeft: 40, fontSize: 20, }} onPress={() => {
                            openUrlWebView(Worksheets.thoughtInteruptedCards.uri);
                        }}>• Thought Interrupters cards</Text>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>How to Stop Feeling Anxious Right Now - </DefaultText>
                        <Text style={{ textDecorationLine: 'underline', color: 'blue', paddingLeft: 40, fontSize: 20, }} onPress={() => {
                            openUrlWebView('https://www.webmd.com/mental-health/features/ways-to-reduce-anxiety');
                        }}>https://www.webmd.com/mental-health/features/ways-to-reduce-anxiety</Text>
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Handy Hint"} />
            <DefaultTextWithoutArrowsItallic textdata={"Try to notice your changing mental state after the interrupter. Make note of this feeling and try to bring this feeling back next time your worries start to overwhelm you."} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/The+Interruptor.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Thought],
    level: [],
    emotion: [CardFilterEmotionType.Anxious]
}

