import { Platform } from 'react-native'
import { UniversalImage } from '../../../common/universal-image'
import TheLittleBookOfBigEmotionsIconIos from './the-little-book-of-big-emotions-icon-ios'

const SvgImage = require('./the-little-book-of-big-emotions-icon.png')

export const TheLittleBookOfBigEmotionsIcon = () => {

    if (Platform.OS === "web") {
        return <UniversalImage SvgImage={SvgImage} />
    }

    return <TheLittleBookOfBigEmotionsIconIos />
}
