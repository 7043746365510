

import { SwitchesActivityGroupCreativity } from "./groups/creativity";
import { SwitchesActivityGroupEnergizers } from "./groups/energizers";
import { SwitchesActivityGroupForRemoteClasses } from "./groups/for-remote-classes";
import { SwitchesActivityGroupHealthyHabits } from "./groups/healthy-habits";
import { SwitchesActivityGroupNumberAndWordGames } from "./groups/number-and-word-games";
import { SwitchesActivityGroupResilienceBuilders } from "./groups/resilience-builders";
import { SwitchesActivityGroupSecretsForSleep } from "./groups/secrets-for-sleep";
import { SwitchesActivityGroupActivitySheets } from "./groups/activity_sheets";
import { SwitchesActivityGroupExtraSwitches } from "./groups/extra-switches";
import { SwitchesActivityGroupForFunAndPositive } from "./groups/fun-and-postive";
import { SwitchesActivityGroupSeparationAnxiety } from "./groups/separation-anxiety";

export const activityGroupCards = [
    SwitchesActivityGroupHealthyHabits,
    SwitchesActivityGroupEnergizers,
    SwitchesActivityGroupNumberAndWordGames,
    SwitchesActivityGroupCreativity,
    SwitchesActivityGroupForRemoteClasses,
    SwitchesActivityGroupSecretsForSleep,
    SwitchesActivityGroupActivitySheets,
    SwitchesActivityGroupResilienceBuilders,
    SwitchesActivityGroupForFunAndPositive,
    SwitchesActivityGroupSeparationAnxiety,
    SwitchesActivityGroupExtraSwitches,
]

export const activityGroupPublicCards = [
    SwitchesActivityGroupHealthyHabits,
    SwitchesActivityGroupSecretsForSleep,
    SwitchesActivityGroupSeparationAnxiety,
    SwitchesActivityGroupExtraSwitches,
]