/**
 * Queue promises and execute them in batches.
 * Mainly used to void API calls being rejected due to too many calls per second.
 */
export const promiseQueue = async <T>(items: T[], func: (param: T, index: number) => Promise<void>, perSecondRateLimit: number = 10) => {
    const initialItemLength = items.length
    const copy = [...items]
    while (copy.length > 0) {
        const batch = copy.splice(0, Math.ceil(perSecondRateLimit / 5))
        await Promise.all(batch.map((item, i) => func(item, initialItemLength - copy.length - batch.length + i)))
        await sleep(200)
    }
}

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))