import { View } from 'react-native'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { SleepTrainingIcon } from '../../icons/sleep-training-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { SleepTrainingIconInverted } from '../../icons/sleep-training-icon-inverted'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'

export const SwitchesActivitiesSecretsForSleepSleepTraining: CardTemplate = {
    cardId: "9b07cbae-fe20-4977-9d92-e331e47fe4e2",
    title: 'Sleep training',
    Header: SleepTrainingIconInverted,
    Icon: SleepTrainingIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Creating a regime where your body more instinctively falls asleep."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Every morning, for at least 30mins, do some exercise as the first thing you do in the day. Make it a simple rule and something you do at the same time every day (For example, “at 6am I go for a 5km walk or run”)."} />
                <DefaulttextWithRightArrow textdata={"Eat mainly whole foods during the day. These are food that have not been processed or packaged."} />
                <DefaulttextWithRightArrow textdata={"Choose a time that you stop using any devices (nothing after 8:30pm is a good rule for most people)."} />
                <DefaulttextWithRightArrow textdata={"Choose a good book (not an e-book) and read at least one chapter a night under soft light."} />
                <DefaulttextWithRightArrow textdata={"Practice this routine without break for at least three weeks and then reflect on what has changed."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SECRETS_FOR_SLEEP,
    whyDoesItWorkText: 'Often you’re ability to sleep is linked to your memory working hard to make sense of the day. Your brain, and in particular memory, works like a muscle, so you need to use and train it regularly to keep it strong, healthy and regulated. Putting yourself into a strict routine, or ‘sleep training regime’, helps the body to learn when to switch off, and when to switch on.',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Sleep+Trainer.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.SECRETS_FOR_SLEEP],
    action: [CardActionType.Action],
    level: []

}

