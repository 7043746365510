import { useReactiveVar } from '@apollo/client'
import { StyleSheet, TextStyle, View } from 'react-native'
import { rvCurrentClass } from '../common/common-state'
import { DefaultText } from '../common/default-text'
import { emotionColor } from '../common/get-emotion-color'
import { getIntensityLevel } from '../common/get-intensity-level'
import { LegendsEmotionBar } from '../icon/legends-emotion-bar'
import { Emotion } from '../models'
import { AngryLegends, AnxiousLegends, ExcitedLegends, HappyLegends, SadLegends, ScaredLegends } from './teacher-stats-legends-constants'
import _ from 'lodash'
import { useRoute } from '@react-navigation/native'
import { ScreenNames } from '../common/screen-names'

interface Props {
    emotion: Emotion
    icon: JSX.Element
}

const STATS_LEGENDS = {
    [Emotion.HAPPY]: {
        1: null,
        3: [HappyLegends.Peaceful, HappyLegends.Cheerful, HappyLegends.Ecstatic],
        5: [HappyLegends.Peaceful, HappyLegends.Optimistic, HappyLegends.Cheerful, HappyLegends.Sensational, HappyLegends.Ecstatic],
        7: [HappyLegends.Peaceful, HappyLegends.Pleased, HappyLegends.Buoyant, HappyLegends.Cheerful, HappyLegends.Delighted, HappyLegends.Sensational, HappyLegends.Ecstatic],
    },
    [Emotion.ANXIOUS]: {
        1: null,
        3: [AnxiousLegends.Concerned, AnxiousLegends.Worried, AnxiousLegends.Overwhelmed],
        5: [AnxiousLegends.Concerned, AnxiousLegends.Apprehensive, AnxiousLegends.Worried, AnxiousLegends.Overwhelmed, AnxiousLegends.Panicked],
        7: [AnxiousLegends.Attentive, AnxiousLegends.Focused, AnxiousLegends.Concerned, AnxiousLegends.Apprehensive, AnxiousLegends.Worried, AnxiousLegends.Overwhelmed, AnxiousLegends.Panicked]
    },
    [Emotion.ANGRY]: {
        1: [],
        3: [AngryLegends.Annoyed, AngryLegends.Frustrated, AngryLegends.Furious],
        5: [AngryLegends.Annoyed, AngryLegends.Irritated, AngryLegends.Frustrated, AngryLegends.Fuming, AngryLegends.Furious],
        7: [AngryLegends.Indignant, AngryLegends.Annoyed, AngryLegends.Irritated, AngryLegends.Frustrated, AngryLegends.Fuming, AngryLegends.Furious, AngryLegends.Seething]
    },
    [Emotion.SAD]: {
        1: null,
        3: [SadLegends.Disappointed, SadLegends.Hopeless, SadLegends.Despair],
        5: [SadLegends.Disappointed, SadLegends.Discouraged, SadLegends.Hopeless, SadLegends.Grieving, SadLegends.Despair],
        7: [SadLegends.Disappointed, SadLegends.Discouraged, SadLegends.Vulnerable, SadLegends.Dejected, SadLegends.Hopeless, SadLegends.Grieving, SadLegends.Despair,]
    },
    [Emotion.SCARED]: {
        1: null,
        3: [ScaredLegends.Hesitant, ScaredLegends.Threatened, ScaredLegends.Terrified],
        5: [ScaredLegends.Hesitant, ScaredLegends.Nervous, ScaredLegends.Threatened, ScaredLegends.Afraid, ScaredLegends.Terrified],
        7: [ScaredLegends.Hesitant, ScaredLegends.Unsure, ScaredLegends.Nervous, ScaredLegends.Alarmed, ScaredLegends.Threatened, ScaredLegends.Afraid, ScaredLegends.Terrified]
    },
    [Emotion.EXCITED]: {
        1: null,
        3: [ExcitedLegends.Expectant, ExcitedLegends.Energetic, ExcitedLegends.Hyper],
        5: [ExcitedLegends.Expectant, ExcitedLegends.Eager, ExcitedLegends.Energetic, ExcitedLegends.Pumped, ExcitedLegends.Hyper],
        7: [ExcitedLegends.Expectant, ExcitedLegends.Inspired, ExcitedLegends.Eager, ExcitedLegends.Energetic, ExcitedLegends.Passionate, ExcitedLegends.Pumped, ExcitedLegends.Hyper]
    },
} as const

const legendsTextContainerHeight = 15

export const TeacherStatsLegend = ({
    emotion,
    icon,
}: Props) => {
    const currentClass = useReactiveVar(rvCurrentClass)
    const route = useRoute()

    const isStaffWellbeingPage = route.name === ScreenNames.TeacherWellbeing || route.name === ScreenNames.NonTeachingStaffWellbeing

    const intensityLevel = getIntensityLevel(isStaffWellbeingPage ? 7 : currentClass?.emotionIntensityLevel)
    const color = emotionColor[emotion]

    const currentLegends = STATS_LEGENDS[emotion][intensityLevel] || []
    const emotionIconSize = intensityLevel === 1 ? 50 : 25

    return (
        <View style={styles.legendContainer}>
            <View style={{ width: emotionIconSize, height: emotionIconSize }}>{icon}</View>

            <View style={styles.barAndTextContainer}>
                <View style={{ height: legendsTextContainerHeight * (currentLegends.length || 3) }}>
                    <LegendsEmotionBar emotion={emotion} />
                </View>
                <View>
                    {_.reverse([...currentLegends]).map((legend, index) => {
                        if (legend) {
                            return (
                                <View key={String(index)} style={[styles.legendTextContainer, { height: legendsTextContainerHeight }]}>
                                    <DefaultText style={[styles.legendText, { color }]}>{legend}</DefaultText>
                                </View>
                            )
                        }
                    })}
                </View>
            </View>

        </View >
    )
}

const styles = StyleSheet.create({
    barAndTextContainer: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginLeft: 5
    },
    emotionIcon: {
        width: 25,
        height: 25,
    },
    legendContainer: {
        flexDirection: 'row',
        margin: 5,
        alignItems: 'flex-end',
    },
    legendTextContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    legendText: {
        marginLeft: 5,
        fontSize: 12,
    } as TextStyle,
})