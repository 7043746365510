import { Picker } from '@react-native-picker/picker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ActionSheetIOS, Platform, StyleSheet, ViewStyle } from 'react-native';
import { ButtonText } from './button-text';
import { DecidaColors } from '../../common/decida-colors';

type Props = {
    value: string
    setValue: (value: string) => void
    style?: ViewStyle
    openAtValue?: string | undefined
}

const DropdownPickerTime = ({ value, setValue, style, openAtValue }: Props) => {
    const [pickerOptions, setPickerOptions] = useState<string[]>([])

    const iosDevices = Platform.OS === "ios"
    const selectedItem = pickerOptions.find(opt => opt === value)

    const getCurrentTimeOptions = () => {
        let time = {
            slotInterval: 30,
            openTime: '06:00 AM',
            closeTime: '09:01 PM'
        };

        let openAtValidation = openAtValue !== undefined && openAtValue !== 'Opens At' ? true : false

        if (openAtValue && openAtValue !== 'Opens At') {
            time.openTime = openAtValue
        }

        let startTime = moment(time.openTime, "HH:mm A");
        let endTime = moment(time.closeTime, "HH:mm A");

        if (openAtValidation) {
            startTime.add(time.slotInterval, 'minutes')
            let currentValue = moment(value, 'HH:mm: A')

            if (currentValue < startTime) {
                setValue(startTime.format("h:mm A"))
            }
        }

        let allTimes = [value];

        //Loop over the times - only pushes time with 30 minutes interval
        while (startTime < endTime) {
            //Push times
            allTimes.push(startTime.format("h:mm A"));
            // Add interval of 30 minutes
            startTime.add(time.slotInterval, 'minutes');
        }

        return allTimes
    }

    useEffect(() => {
        const allOptions = getCurrentTimeOptions()
        setPickerOptions(allOptions)
    }, [value, openAtValue])

    const handlePickerIos = () => {
        ActionSheetIOS.showActionSheetWithOptions(
            {
                options: pickerOptions,
            },
            buttonIndex => {
                setValue(pickerOptions[buttonIndex])
            }
        );
    }

    return (
        <>
            {iosDevices ? (
                <>
                    <ButtonText style={styles.filterButton} onPress={handlePickerIos}>
                        {selectedItem}
                    </ButtonText>
                </>
            ) : (
                <Picker
                    selectedValue={value}
                    onValueChange={setValue}
                    style={style}
                >
                    {pickerOptions.map((option, index) => {
                        if (value === 'Opens at' || value === 'Closes at') {
                            return <Picker.Item key={`${option}-${index}`} label={value} value={0} />
                        }

                        return <Picker.Item key={`${option}-${index}`} label={option} value={option} />
                    })}
                </Picker>
            )}
        </>
    )
}

export default DropdownPickerTime

const styles = StyleSheet.create({
    overlay: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        zIndex: 999,
    },
    pickerContainer: {
        maxHeight: 200, paddingVertical: 6,
        zIndex: 99,
        width: '100%',
    },
    pickerItemContainer: {
        flex: 1,
        zIndex: 99,
        flexWrap: 'nowrap',
        flexShrink: 0,
        backgroundColor: DecidaColors.White,
        paddingHorizontal: 10,
        paddingVertical: 5,
    },
    pickerHeaderText: {
        marginBottom: 10,
        borderBottomWidth: 0.5,
        borderBottomColor: DecidaColors.Gray,
        paddingVertical: 8,
        paddingLeft: 10,
        fontSize: 14,
    },
    filterButton: {
        color: DecidaColors.Black,
        borderWidth: 1.5,
        borderRadius: 10,
        paddingHorizontal: 10,
        paddingVertical: 2,
        fontSize: 16,
        fontWeight: '500',
        borderColor: DecidaColors.Black
    }
});