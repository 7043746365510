import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import SwitchCardVideo from '../../../common/switch-card-video'
import { Worksheets } from '../../../common/worksheets-imports'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { NewConnectionIcon } from '../../explore/src/new-connection-icon'
import { NewConnectionIconInverted } from '../../explore/src/new-connection-icon-inverted'
import { NewConnectionImage1 } from './src/new-connection-image1'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithLink from '../../common/default-text-with-link'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'

const newConnectionVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/rzsVh8YwZEQ",
    title: ""
}

export const SwitchesEmotionsHappyNewConnection: CardTemplate = {
    cardId: "5e4ed655-41f2-4122-a9e3-bab417fc5180",
    title: 'New connections',
    Header: NewConnectionIconInverted,
    Icon: NewConnectionIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Building confidence, conversational skills and connectedness."} />
        </>
    ),
    Images: () => (
        <>
            <NewConnectionImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Identify someone that you think you’d like to connect with."} />
                <DefaulttextWithRightArrow textdata={"Approach them with a smile, and ask open ended, curious questions (What brings you here? What do you like to do on the weekend? Why?). "} />
                <DefaulttextWithRightArrow textdata={"Actively listen to what they say, picking different things for them to expand on."} />
                <DefaulttextWithRightArrow textdata={"Search for common interests, but if someone shuts you down three times, move onto someone else."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: 'Extending social networks helps builds resilience, and is proven to be a key correlate to living a happier, healthier, longer life. Practicing making new connections when you’re happy can help reduce anxiety, depression related to loneliness, and mitigate for the effect of ostracism.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Develop active listening skills, and  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Build confidence developing new relationships. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Start with a short game of "Simon Says" to introduce the concept of listening attentively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to stand and give physical action commands using "Simon says" and without. Emphasise the importance of processing information before acting.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief the game, highlighting that attentive listening involves processing information before responding.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the idea that these listening skills are valuable in conversations.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Video and Explanation (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the video: "Active listening is a skill! Here’s how it’s done."</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the key elements of active listening, such as making eye contact, nodding, and asking follow-up questions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Group Activity (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Hand out the 'Active Listening Checklist' to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide students into small groups and assign roles: storyteller and interviewer.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the storyteller to share a story about their favourite animal. The interviewer must actively listen, asking at least three probing questions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Observers in the group tick off the checklist as they see the interviewer demonstrating active listening.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After the activity, swap roles within the groups if time permits.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief the experience, asking students to share their observations and feelings during the activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reflect on how active listening can enhance connections and understanding in conversations.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the value of active listening in building relationships and understanding others.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, guide them to find the New Connections switch in the Switch4Schools app.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='New connection worksheet' link={Worksheets.activeListening.uri} /> </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Projector and screen for watching video </DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Video: Active Listening -  </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=rzsVh8YwZEQ' />
                        <SwitchCardVideo video={{
                            id: '1',
                            url: 'https://www.youtube.com/embed/rzsVh8YwZEQ'
                        }} />

                        <DefaultText style={CommonStyles.lessonPlanText}>Video: Active listening is a skill! Here’s how it’s done - </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=0nmJW_zExk0' />
                        <SwitchCardVideo video={{
                            id: '2',
                            url: 'https://www.youtube.com/embed/0nmJW_zExk0'
                        }} />

                        <DefaultText style={CommonStyles.lessonPlanText}>Active listening checklist -  </DefaultText>
                        <DefaultTextWithLink text='https://www.teachthis.com.au/products/active-listening-checklist' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"Think about this as practicing conversational skills. Keep in mind that even if you have the skills to make friends, it is possible you haven’t met the right people yet. "} />
            <DefaulTextWithTipsIcon textdata={"Possible thought starters include:"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Shared interests (movies, hobbies, sport, music, books, science etc…)."} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Curious questions about tasks or activities."} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Mutual friendships."} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/New+Connections.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Happy]
}
