import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const EmotionScaredIcon = (props?: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 92.491 92.489"
    {...props}
  >
    <Path
      id="Path_3879"
      data-name="Path 3879"
      d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465Z"
      transform="translate(26.513 51.518)"
      fill="none"
      stroke="#cc8236"
      strokeWidth={3.013}
    />
    <Path
      id="Path_3880"
      data-name="Path 3880"
      d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211"
      transform="translate(29.526 35.234)"
      fill="#cc8236"
    />
    <Path
      id="Path_3881"
      data-name="Path 3881"
      d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211"
      transform="translate(64.713 35.234)"
      fill="#cc8236"
    />
    <Path
      id="Path_3882"
      data-name="Path 3882"
      d="M8.529,0S2.135,6.908-10.808,11.288"
      transform="translate(29.731 21.097)"
      fill="none"
      stroke="#cc8236"
      strokeWidth={3.013}
    />
    <Path
      id="Path_3883"
      data-name="Path 3883"
      d="M.58,0S-3.343,3.811,2.359,5.1"
      transform="translate(17.487 29.837)"
      fill="#cc8236"
    />
    <Path
      id="Path_3884"
      data-name="Path 3884"
      d="M1.748,1.743S3.423-3.465-2.215-1.924"
      transform="translate(38.475 21.161)"
      fill="#cc8236"
    />
    <Path
      id="Path_3885"
      data-name="Path 3885"
      d="M0,0S6.394,6.908,19.337,11.288"
      transform="translate(61.057 21.097)"
      fill="none"
      stroke="#cc8236"
      strokeWidth={3.013}
    />
    <Path
      id="Path_3886"
      data-name="Path 3886"
      d="M.784,0S4.708,3.811-.994,5.1"
      transform="translate(80.465 29.837)"
      fill="#cc8236"
    />
    <Path
      id="Path_3887"
      data-name="Path 3887"
      d="M.1,1.743S-1.572-3.465,4.066-1.924"
      transform="translate(58.992 21.161)"
      fill="#cc8236"
    />
    <Path
      id="Path_3888"
      data-name="Path 3888"
      d="M0,1.139S.776-1.443,5.174-1.443s4.917,3.616,10.091,3.616,4.917-3.616,9.314-3.616S29.753,2.173,34.67,2.173s4.657-3.616,9.314-3.616,5.045,2.582,5.691,2.582"
      transform="translate(25.14 54.398)"
      fill="none"
      stroke="#cc8236"
      strokeWidth={3.013}
    />
  </Svg>
);
export default EmotionScaredIcon;
