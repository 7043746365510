
import { StyleSheet } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { Worksheets } from '../../common/worksheets-imports'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

export const SwitchesLessonPlansUnderstandingAndManagingAnger: CardTemplate = {
    cardId: "196a80e8-217d-4157-b13b-52460cd980d4",
    title: 'Understanding and managing anger',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to recognise, understand, manage, and use anger constructively to enhance self awareness.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Greet the students and explain the purpose of the lesson: to understand and manage anger effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share their understanding of anger and its effects on themselves and others.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Defining Anger (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the definition of anger, (refer to the Little Book of Big Emotion for more information) emphasising that it is a natural emotion that everyone experiences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that anger is a response to feeling threatened, frustrated, or when our expectations are not met.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share examples of situations that make them angry.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the different ways people express anger, such as shouting, crying, or withdrawing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Display the level 5 emotion wheel. Discuss the different anger intensity levels of anger reinforcing that emotions in the centre of the wheel are the highest level of intensity.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding the Consequences of Uncontrolled Anger (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the negative consequences of uncontrolled anger, such as damaged relationships, increased stress, and poor decision-making.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Share real-life examples or stories that illustrate the negative effects of uncontrolled anger. For example:  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Sydney driver sentenced to 18 months for violent road rage attack' link='https://www.9news.com.au/national/sydney-driver-chances-moana-sentenced-to-18-months-over-attack/b4b00dc4-c10d-45d5-93be-eec06556ed18' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='NRL stars Latrell, Wighton set to face court over alleged fight' link='https://www.espn.com.au/nrl/story/_/id/35590477/nrl-stars-latrell-mitchell-jack-wighton-set-face-court-alleged-fight' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how anger can impact their own lives and the lives of others around them.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Developing Self awareness (15 minutes):  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the concept of self awareness as the ability to recognise and understand one's own emotions, triggers, and reactions.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the connection between self awareness and anger management. Self awareness means understanding your own thoughts, feelings, and actions. It's like having a window into your own mind and heart. When it comes to anger management, self awareness is really important. Here's why: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Recognising Anger: Self awareness helps you recognise when you're feeling angry. By paying attention to your body and emotions, you can identify the signs of anger, like a faster heartbeat, clenched fists, or feeling hot inside. When you're aware of these signs, you can take action to manage your anger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Understanding Triggers: Self awareness helps you understand what makes you angry. Everyone has different triggers—things that make them feel upset or frustrated. By being self-aware, you can identify the specific situations, people, or events that tend to make you angry. This knowledge can help you prepare and respond better when those triggers arise.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Reflecting on Reactions: Self awareness allows you to reflect on how you react when you're angry. It's important to be aware of whether you tend to yell, hit, or say hurtful things when you're angry. Understanding how you typically respond to anger helps you see if those reactions are helpful or hurtful to yourself and others.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Choosing Your Response: Self awareness gives you the power to choose how you respond to anger. When you're aware of your anger and triggers, you can pause, take a deep breath, and think about how you want to react. Instead of automatically lashing out, you can make a conscious decision to respond in a more calm and positive way.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Seeking Support: Self awareness helps you know when to ask for help. If you realise that anger is becoming a big problem for you and it's hard to manage on your own, being self-aware allows you to reach out to trusted adults, like parents, teachers, or counsellors, for guidance and support.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- By being self-aware, you can take control of your anger and manage it in healthier ways. Remember, it's okay to feel angry sometimes, but it's important to be aware of your emotions and choose how you express them. With self awareness, you can learn to manage your anger and have healthier relationships with others.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a guided reflection exercise, encouraging students to think about their own anger triggers and how they typically respond.  Refer to the Anger Volcano infographic on the Anger Volcano worksheet and discuss how often angry behaviours that you can see are often caused by feelings you don’t see, such as rejection, shame, disappointment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Little Book of Big Emotion to explain that there are different levels of anger and words we can use to describe those levels.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give each student a worksheet and instruct them to think about what their angry outburst looks like and note this this down. Then work through the anger stages identifying their triggers as they move through the intensity levels eventuating in an eruption. Ask students to think about what it looks, feels and sounds like at each stage.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Anger Management Techniques (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students think of things they can do calm down when they are feeling angry, you may like to brainstorm as a class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce a variety of anger management techniques, such as deep breathing, counting to ten, taking a break, and expressing feelings through writing or drawing. Refer to the anger switches for inspiration.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students access the Switch4Schools program on their device and encourage them to explore the available switches assigned to angry on the emotion wheel to bookmark activities they’d like to try.  Alternatively provide a copy of the level 5 quick reference guide. (Could also be a homework activity.) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the students complete the worksheet to note what they can do to calm down.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share their reflections and discuss strategies for improving self awareness.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the key points discussed during the lesson, emphasising the importance of understanding, and managing anger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice the strategies learned in their daily lives to enhance emotional intelligence and self awareness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Answer any questions or concerns the students may have.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='The anger volcano worksheet' link={Worksheets.angerVolcano.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Level 5 emotion wheel poster (optional)' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Emotion+Wheel+Poster+5-levels_2022.pdf' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Level 5 quick reference guide (optional)' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/level+5+QRG.pdf' /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Anger management for teens – how you can help -</DefaultText>
                        <DefaultTextWithLink text='https://www.sparktheirfuture.qld.edu.au/explosive-behaviour/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Anger - how it affects people -</DefaultText>
                        <DefaultTextWithLink text='https://www.betterhealth.vic.gov.au/health/healthyliving/anger-how-it-affects-people' />
                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}
