import { ApolloClient, useApolloClient } from '@apollo/client'
import { Ionicons } from '@expo/vector-icons'
import { useEffect, useRef, useState } from 'react'
import { Platform, StyleSheet, TextInput, View, ViewStyle, useWindowDimensions } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultButton, DefaultButtonTypes } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { emailValidator } from '../common/email-validator'
import InputDefault from '../common/input-default'
import InputSearchTeacherComponent from '../common/input-search-teacher-component'
import InputSearchTeacherRoleComponent, { getRoleByValue } from '../common/input-search-teacher-role-component'
import { rvIsLoading } from '../common/loading'
import ToastAlert, { ToastAlertType } from '../common/toast-alert'
import { GetSchoolQueryResponseClass, GetSchoolQueryResponseTeacher } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query'
import { SchoolAdminTableSetEditComponent } from './school-admin-const'

export type SchoolAdminClassAndTeacherFormOnSubmitParam = {
    className: string | undefined,
    firstName: string | undefined,
    lastName: string | undefined,
    email: string | undefined,
    role: string | undefined | null,
    selectedTeacher: GetSchoolQueryResponseTeacher | null
    apolloClient: ApolloClient<object>
}

export type SchoolAdminClassAndTeacherFormOnSubmit = (param: SchoolAdminClassAndTeacherFormOnSubmitParam) => Promise<void>

type Props = {
    setEditComponent: SchoolAdminTableSetEditComponent,
    currentClass?: GetSchoolQueryResponseClass,
    currentTeacher?: GetSchoolQueryResponseTeacher,
    schoolID: string,
    style?: ViewStyle,
    onSubmit: SchoolAdminClassAndTeacherFormOnSubmit,
    onRemoveTeacher?: () => Promise<void>,
    goMode?: boolean
}

const SchoolAdminClassAndTeacherForm = ({
    setEditComponent,
    currentClass,
    currentTeacher,
    schoolID,
    style,
    onSubmit,
    onRemoveTeacher,
    goMode
}: Props) => {
    const apolloClient = useApolloClient()
    const { width } = useWindowDimensions()

    const selectedTeacherRef = useRef<GetSchoolQueryResponseTeacher | null>(null)
    const [className, setClassName] = useState(currentClass?.name || '')
    const [firstName, setFirstName] = useState(currentTeacher?.firstName || '')
    const [lastName, setLastName] = useState(currentTeacher?.lastName || '')
    const [email, setEmail] = useState(currentTeacher?.email.toLowerCase() || '')
    const [formError, setFormError] = useState(false)
    const [role, setRole] = useState({
        label: '' as string | null | undefined,
        value: '' as string | null | undefined,
    })
    const [toast, setToast] = useState({
        status: false,
        message: '',
        type: ToastAlertType.success
    })
    const firstNameInputRef = useRef<TextInput>(null)
    const lastNameInputRef = useRef<TextInput>(null)
    const emailInputRef = useRef<TextInput>(null)
    const teacherRoleInputRef = useRef<TextInput>(null)

    useEffect(() => {
        if (currentClass) {
            setClassName(currentClass?.name)
        } else {
            setClassName("")
        }
    }, [currentClass])

    useEffect(() => {
        if (selectedTeacherRef.current) {
            setEmail(selectedTeacherRef.current.email.toLocaleLowerCase())
            setFirstName(selectedTeacherRef.current.firstName || "")
            setLastName(selectedTeacherRef.current.lastName || "")
            setRole({
                label: getRoleByValue(selectedTeacherRef.current?.role),
                value: selectedTeacherRef.current?.role
            })
        }

    }, [selectedTeacherRef.current])

    const resetForm = () => {
        setClassName("")
        setFirstName("")
        setLastName("")
        setEmail("")
        setRole({
            label: "",
            value: "",
        })
        setSelectedTeacher(null)
    }
    const setSelectedTeacher = (teacher: GetSchoolQueryResponseTeacher | null) => {
        selectedTeacherRef.current = teacher
    }


    const resetToast = () => {
        setToast({ status: false, message: "", type: ToastAlertType.success })
    }

    const handleSubmit = async () => {
        resetToast()

        const teacherExists = currentClass?.teachers?.items.some((tc) => tc.teacherID === selectedTeacherRef.current?.id && !tc._deleted)

        // validations
        if (!className) {
            setToast({ status: true, message: "Please input class name", type: ToastAlertType.error })
        }

        if (teacherExists && !currentTeacher) {
            setToast({ status: true, message: "This teacher already assigned to this class", type: ToastAlertType.error })
            return
        }

        if (!isFormCompleted()) {
            setToast({ status: true, message: "Please fill all required form", type: ToastAlertType.error })
            return
        }

        if (emailValidator(email) === "Email is not valid.") {
            setToast({ status: true, message: "Email address is not valid", type: ToastAlertType.error })
            return
        }

        try {
            rvIsLoading(true)

            await onSubmit({
                className,
                firstName,
                lastName,
                email,
                role: role.value,
                selectedTeacher: selectedTeacherRef.current,
                apolloClient
            })

            setToast({ status: true, message: `Successfully added ${currentClass ? "Teacher" : "Class"} `, type: ToastAlertType.success })
            rvIsLoading(false)
            resetForm()
            setEditComponent(null)
        } catch (error) {
            console.log(error)
        }
    }

    const handleRemoveTeacher = async () => {
        onRemoveTeacher && await onRemoveTeacher()
        setEditComponent(null)
    }

    const isFormCompleted = () => {
        return className &&
            firstName &&
            lastName &&
            email &&
            role.value &&
            !formError
    }

    const handleEmail = (text: string) => {
        setFormError(false)
        if (!text || text === "") {
            setSelectedTeacher(null)
        }
        setEmail(text.toLowerCase())
    }

    const handleFirstName = (text: string) => {
        setFormError(false)
        if (!text || text === "") {
            setSelectedTeacher(null)
        }
        setFirstName(text)
    }

    const handleLastName = (text: string) => {
        setFormError(false)
        if (!text || text === "") {
            setSelectedTeacher(null)
        }
        setLastName(text)
    }

    const handleEmailExists = () => {
        if (!selectedTeacherRef.current) {
            setFormError(true)
            setToast({ status: true, message: "It looks like an account already exists with that email address.", type: ToastAlertType.error })
        }
    }

    return (
        <View style={styles.mainContainer}>
            <View style={[styles.container, style]}>
                {currentTeacher == null &&
                    <View style={styles.formContainer}>
                        <InputDefault editable={currentClass ? false : true} value={className} onChangeText={setClassName} placeholder={goMode ? 'GO group name' : "Class name"} />
                    </View>
                }
                <View style={styles.formContainer}>
                    <View style={styles.topForm}>
                        <InputSearchTeacherComponent ref={firstNameInputRef} nextRef={lastNameInputRef} value={firstName} setValue={handleFirstName} placeholder='Teacher first name' setSelected={setSelectedTeacher} selected={selectedTeacherRef.current} fieldName="firstName" schoolID={schoolID} />
                    </View>
                    <InputSearchTeacherComponent ref={emailInputRef} nextRef={teacherRoleInputRef} value={email} setValue={handleEmail} placeholder='Email' setSelected={setSelectedTeacher} selected={selectedTeacherRef.current} fieldName="email" schoolID={schoolID} onError={handleEmailExists} />
                </View>
                <View style={styles.formContainer}>
                    <View style={styles.topForm}>
                        <InputSearchTeacherComponent value={lastName} ref={lastNameInputRef} nextRef={emailInputRef} setValue={handleLastName} placeholder='Teacher last name' setSelected={setSelectedTeacher} selected={selectedTeacherRef.current} fieldName="lastName" schoolID={schoolID} />
                    </View>
                    <InputSearchTeacherRoleComponent ref={teacherRoleInputRef} selected={role?.label || ''} setSelected={(role) => setRole(role)} placeholder="Role" value={role?.value || ''} />
                    <DefaultButton type={DefaultButtonTypes.Small} disabled={!isFormCompleted()} style={Platform.OS === 'web' ? styles.confirmButton : styles.confirmButtonIos} onPress={handleSubmit}>
                        <Ionicons name="ios-checkmark-sharp" size={24} color={DecidaColors.White} />
                        {width > 1050 && (
                            <View>
                                <DefaultText style={styles.confirmText}>
                                    Confirm
                                </DefaultText>
                            </View>
                        )}
                    </DefaultButton>
                </View>
                <View style={styles.minusButtonWrapper}>
                    <DefaultButton type={DefaultButtonTypes.Small} style={styles.minusButton} onPress={handleRemoveTeacher}>
                        <View style={styles.minusIcon} />
                    </DefaultButton>
                </View>
            </View>
            <View style={{ maxWidth: '100%', position: 'absolute', bottom: -30 }}>
                {toast.status && (
                    <ToastAlert visible={toast.status} setVisible={(status: boolean) => setToast({ status, message: "", type: toast.type })} message={toast.message} type={toast.type} />
                )}
            </View>
        </View>
    )
}

export default SchoolAdminClassAndTeacherForm

const styles = StyleSheet.create({
    mainContainer: {
        zIndex: 99,
        marginBottom: 20,
        marginTop: 10
    },
    mainContainerTeacherDetails: {
        zIndex: 99,
    },
    container: {
        flex: 1,
        maxWidth: '55%',
        backgroundColor: DecidaColors.AppleSystemGray6SuperLight,
        padding: 20,
        marginVertical: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 20,
        borderWidth: 3,
        borderColor: DecidaColors.Red
    },
    minusIcon: {
        width: 15,
        height: 3,
        backgroundColor: DecidaColors.White
    },
    formContainer: {
        flex: 1,
        paddingHorizontal: 5,
    },
    confirmText: {
        fontSize: 12,
        fontWeight: '700',
        color: DecidaColors.White,
    },
    confirmButton: {
        minWidth: 40,
        alignSelf: 'flex-end',
        zIndex: -1
    },
    confirmButtonIos: {
        width: 60,
        alignSelf: 'flex-end',
        zIndex: -1
    },
    minusButton: {
        backgroundColor: DecidaColors.Angry,
        paddingHorizontal: 10,
    },
    minusButtonWrapper: {
        justifyContent: 'center',
        marginBottom: 10
    },
    topForm: {
        zIndex: 2
    }
})