import { Ionicons } from '@expo/vector-icons'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'
import { avatars } from '../icon/avatar/avatars'
import { Class, Student } from '../../common/API'

type Props = {
    student: Student
    onAddStudent: (student: Student, claz?: Class) => void
    claz?: Class
    fontSize?: "small" | "large"
}
const StudentSearchCardInfo = ({ student, onAddStudent, claz, fontSize = "large" }: Props) => {
    const Avatar = avatars[student.avatar as keyof typeof avatars]
    return (
        <View style={styles.studentRowContainer} key={student.id}>
            <TouchableOpacity onPress={() => onAddStudent(student, claz)}>
                <Ionicons name="ios-add-circle-sharp" size={30} color={DecidaColors.Green} />
            </TouchableOpacity>
            <View style={styles.avatarContainer}>
                <Avatar />
            </View>
            <View style={styles.nameColumn}>
                <DefaultText style={fontSize === "small" ? styles.smallFont : styles.largeFont}>
                    Nickname / username: <DefaultText style={[styles.italic, fontSize === "small" ? styles.smallFont : styles.largeFont]}>{student.nickname}</DefaultText>
                </DefaultText>
                <DefaultText style={fontSize === "small" ? styles.smallFont : styles.largeFont}>
                    Fullname: <DefaultText style={[styles.italic, fontSize === "small" ? styles.smallFont : styles.largeFont]}>{`${student.firstName} ${student.lastName}`}</DefaultText>
                </DefaultText>
                <DefaultText style={fontSize === "small" ? styles.smallFont : styles.largeFont}>
                    Email: <DefaultText style={[styles.italic, fontSize === "small" ? styles.smallFont : styles.largeFont]}>{student.email ?? "-"}</DefaultText>
                </DefaultText>
            </View>
        </View>
    )
}
export default StudentSearchCardInfo
const styles = StyleSheet.create({
    studentRowContainer: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginVertical: 5,
        backgroundColor: DecidaColors.AppleSystemGray5Light,
        padding: 15,
        borderRadius: 8,
    },
    nameColumn: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    textContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    italic: {
        fontStyle: 'italic'
    },
    avatarContainer: {
        height: 50,
        width: 50,
        marginHorizontal: 20
    },
    smallFont: {
        fontSize: 16
    },
    largeFont: {
        fontSize: 18
    }
})
