import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import { FiveFingerBreathingIcon } from './src/five-finger-breathing-icon'
import { FiveFingerBreathingIconInverse } from './src/five-finger-breathing-icon-inverse'
import { FiveFingerBreathingImage1 } from './src/five-finger-breathing-image1'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'

export const SwitchesEmotionsAngryFiveFingerBreathing: CardTemplate = {
    cardId: "2cb340d3-3565-42d8-9ee8-5feeaa26aecf",
    title: 'Five finger breathing',
    Header: FiveFingerBreathingIconInverse,
    Icon: FiveFingerBreathingIcon,
    lessonPlan: true,
    mainContainerStyle: {
        maxWidth: '100%',
        paddingHorizontal: 20,
    },
    PerfectFor: () => {

        const Step1 = () => {
            return (
                <View>
                    <DefaultText style={styles.contentText}>Use your pointer finger to trace your other hand. Breathe in while you trace up the finger, breathe out while you trace down the finger.</DefaultText>
                    <View style={styles.imageContainer}>
                        <FiveFingerBreathingImage1 />
                    </View>
                </View>
            )
        }


        const contents = [
            { Component: Step1, audio: '' },
        ]

        return (
            <>
                <SwitchCardJuniorContent contents={contents} scheme={Emotion.ANGRY} />
            </>
        )
    },
    Images: undefined,
    CardContent: () => (
        <>
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/vo_angry_five_finger_breathing.m4a",
    ExtraTips: () => {
        return (
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Recognise and employ a breathing exercise coupled with hand actions to control and manage anger effectively. </DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 10 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (2 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin with a brief discussion on emotions, focusing on anger. Ask students how they feel when they are angry and if they have experienced situations where anger was difficult to control. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of using breathing exercises and hand actions to manage anger. Explain that these techniques can help them calm down and make better choices. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Practice (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the 5 Finger Breathing Switch </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Demonstrate the steps using your hands in exaggerated movements to represent inhaling when moving your finger up the finger, and then exhale when moving the finger down the finger. Complete five big deep slow breaths, one for each finger. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Optional - Begin the 'Beautiful Relaxing Music’ recording in the materials section. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students practice this for a moment. If you feel like they have gotten the hang of it. Encourage them to swap hands.  </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Closing (3 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief to discuss how they felt during the breathing exercise. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask if anyone would like to share their experience or if they noticed a change in their emotions. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain to them that they can use this technique day-to-day whenever they feel angry. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink text="Beautiful Relaxing Music (optional)" link='https://www.youtube.com/watch?v=_kT38XB1YHo' /> </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Try Five-Finger Breathing for Deep Relaxation</DefaultText>
                        <DefaultTextWithLink text='https://health.clevelandclinic.org/five-finger-breathing' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Breathing exercises: relaxation activity for children, teenagers and parents</DefaultText>
                        <DefaultTextWithLink text='https://raisingchildren.net.au/guides/activity-guides/wellbeing/breathing-exercises-relaxation-activity' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How Four Deep Breaths Can Help Kids Calm Down</DefaultText>
                        <DefaultTextWithLink text='https://greatergood.berkeley.edu/article/item/how_four_deep_breaths_can_help_kids_calm_down' />

                        <DefaultText style={CommonStyles.lessonPlanText}>12 Fun and Easy Deep Breathing Exercises For Kids</DefaultText>
                        <DefaultTextWithLink text='https://www.moshikids.com/articles/deep-breathing-exercises-for-kids/' />

                    </>}
            />
        )
    },
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: "",
    BottomRow: () => (
        <>
        </>
    ),
    type: [CardType.Switch],
    time: [],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Angry]
}

const styles = StyleSheet.create({
    contentText: {
        color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
})

