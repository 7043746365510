import { showAlert } from '../common/universal-alert';
import { rvCurrentUser, rvLoggedIn, rvUserGroup, AuthType, rvAuthChallengeName, rvChangePasswordUsername } from './login-state';
import gql from 'graphql-tag';
import { UserGroup } from '../../common/constants';
import { addDevicePushToken } from '../../common/graphql/mutations';
import { apolloClient } from '../common/common-state';
import { Auth } from 'aws-amplify'
import { StackNavigationProp } from '@react-navigation/stack';
import { handleRestartApp } from '../common/handle-restart-app';
import { ScreenNames } from '../common/screen-names';
import { LoginStackNavigatorParamList } from './login-route-param-types';
import { webViewMessageUsername, webViewMessagePassword } from '../web-view/web-view-messages';
import { rvHasBiometric, rvSavedUsername, rvSavedPassword, rvDevicePushToken } from '../web-view/web-view-state';
import { startHeartbeat } from '../web-view/heartbeat';

export const handleAuth = async (auth: AuthType, impersonate = false) => {
    if (auth.challengeParam && auth.challengeParam.userAttributes) {
        rvCurrentUser({
            ...auth,
            attributes: {
                ...auth.challengeParam.userAttributes,
            },
        })
    }else{
        rvCurrentUser(auth)
    }
    const group = auth.signInUserSession.idToken.payload['cognito:groups'][0]

    if (group) {
        rvUserGroup(group as UserGroup)
        rvLoggedIn(true)
        startHeartbeat()
        const devicePushToken = rvDevicePushToken()

        if (devicePushToken && !impersonate) {
            await apolloClient.mutate({
                mutation: gql`${addDevicePushToken}`,
                variables: {
                    input: {
                        devicePushToken,
                    }
                }
            })
        }
    }
}

const saveCredentialsIfHasBiometric = async (username: string, password: string) => {
    if (rvHasBiometric()) {
        if (username !== rvSavedUsername() || password !== rvSavedPassword()) {
            showAlert({
                title: 'TouchID / FaceID',
                message: "Would you like to save your credentials and use FaceID / TouchID to login next time?",
                leftButtonText: 'Yes please.',
                rightButtonText: 'No thanks.',
                onLeftButtonPress: async () => {
                    window.ReactNativeWebView?.postMessage(webViewMessageUsername + username)
                    rvSavedUsername(username)
                    window.ReactNativeWebView?.postMessage(webViewMessagePassword + password)
                    rvSavedPassword(password)
                }
            })
        }
    }
}

export const authSignIn = async (username: string, password: string, navigate: StackNavigationProp<LoginStackNavigatorParamList>['navigate'], onError?: (e: any) => void) => {
    Auth.signIn(username, password)
        .then((auth: AuthType) => {
            rvCurrentUser(auth)
            if (auth.challengeName === "SOFTWARE_TOKEN_MFA" || auth.challengeName === "SMS_MFA") {
                rvAuthChallengeName(auth.challengeName)
                navigate(ScreenNames.LoginMFASMS)
            } else if (auth.challengeName === 'NEW_PASSWORD_REQUIRED') {
                rvChangePasswordUsername(username)
                navigate(ScreenNames.LoginChangePassword)
            } else {
                saveCredentialsIfHasBiometric(username, password)

                if (auth.challengeName != "SOFTWARE_TOKEN_MFA" && auth.challengeName != "SMS_MFA") {
                    handleAuth(auth)
                }
            }
        })
        .catch(e => {
            onError && onError(e)
        })
}

export const refreshSession = async (restartSubs?: boolean) => {
    try {
        const userData = await Auth.currentAuthenticatedUser()

        if (restartSubs) {
            rvCurrentUser(userData)
            handleRestartApp()
        }
        const userSession = userData.getSignInUserSession();
        const refreshToken = userSession.getRefreshToken();
        userData.refreshSession(refreshToken, (err: any, session: any) => {
            userData.setSignInUserSession(session);
        });
    } catch (error) {
        console.log('refreshSession error', error)
    }
}