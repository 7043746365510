import { gql, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { Ionicons } from '@expo/vector-icons'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import * as Clipboard from 'expo-clipboard'
import { SafeAreaView, ScrollView, StyleSheet, TextStyle, View } from 'react-native'
import uuid from 'react-native-uuid'
import QRCode from "react-qr-code"
import { Class, GetClassQuery, GetClassQueryVariables, GetSchoolQuery, GetSchoolQueryVariables, GetStudentClassByClassIdQuery, GetStudentClassByClassIdQueryVariables, ListClassLoginByClassIDQuery, ListClassLoginByClassIDQueryVariables, StudentClass, UpdateClassMutation, UpdateClassMutationVariables } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { updateClass } from '../../common/graphql/mutations'
import { getClass, getSchool, getStudentClassByClassId, listClassLoginByClassID } from '../../common/graphql/queries'
import { BackButton } from '../common/back-button'
import ButtonResetPasswordClassStudents from '../common/button-reset-password-class-students'
import { ButtonText } from '../common/button-text'
import { rvNetInfo } from '../common/common-state'
import { DefaultButton } from '../common/default-button'
import { DefaultSwitch } from '../common/default-switch'
import { DefaultText } from '../common/default-text'
import DropdownSortStudent from '../common/dropdown-sort-student'
import { getIntensityLevel } from '../common/get-intensity-level'
import InformationIcon from '../common/information-icon'
import ListActiveStudents, { ActiveStudentsType } from '../common/list-active-students'
import ListPendingStudents, { PendingStudentsType } from '../common/list-pending-students'
import { Page } from '../common/page'
import { PageTitleText } from '../common/page-title-text'
import { ScreenNames } from '../common/screen-names'
import { Stepper } from '../common/stepper'
import { showAlert, showOfflineAlert } from '../common/universal-alert'
import { useResponsive } from '../common/use-responsive'
import ViewResponsive from '../common/view-responsive'
import { envWebUrl } from '../env'
import { IconPlus } from '../icon/icon-plus'
import { rvUserGroup } from '../login/login-state'
import { rvParentIntensityState } from '../parent/parent-state'
import TeacherClassAdminRequestForChats from './teacher-class-admin-request-for-chats'
import { TEACHER_SIDEBAR_WIDTH } from './teacher-consts'
import { TeacherClassNavigatorParamList } from './teacher-route-param-types'
import { rvTeacherEatingQuestionInfoModal, rvTeacherEmotionIntensityScaleModal, rvTeacherIndividualStudentStatsInfoModal, rvTeacherRequestForChatInfoModal, rvTeacherWellbeingInfoModal } from './teacher-state'



export const TeacherClassAdmin = () => {

    const { navigate } = useNavigation<StackNavigationProp<TeacherClassNavigatorParamList, "TeacherClassAdmin">>()

    const userGroup = useReactiveVar(rvUserGroup)
    const { params } = useRoute<RouteProp<TeacherClassNavigatorParamList, "TeacherClassAdmin">>()

    const { data: classQuery } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: params?.classID || "" }, skip: !params?.classID })

    const [updateClassMutation] = useMutation<UpdateClassMutation, UpdateClassMutationVariables>(gql`${updateClass}`,)


    const currentClass = classQuery?.getClass as Class

    const { data: schoolQuery } = useQuery<GetSchoolQuery, GetSchoolQueryVariables>(gql`${getSchool}`, { variables: { id: currentClass?.schoolID }, skip: !currentClass })
    const school = schoolQuery?.getSchool

    const { data: studentClassResponse } = useQuery<GetStudentClassByClassIdQuery, GetStudentClassByClassIdQueryVariables>(gql`${getStudentClassByClassId}`, { variables: { classID: currentClass?.id || "", limit: 5000 }, skip: !currentClass })
    const currentStudentClasses = studentClassResponse?.getStudentClassByClassId?.items.filter((item) => item?._deleted !== true) as StudentClass[]

    const [listCurrentClassLoginQuery] = useLazyQuery<ListClassLoginByClassIDQuery, ListClassLoginByClassIDQueryVariables>(gql`${listClassLoginByClassID}`, { variables: { classID: currentClass?.id, limit: 5000 } })


    const netInfoState = useReactiveVar(rvNetInfo)
    const { lowRes, windowWidthCondition, width } = useResponsive()


    const navigateToAddNewStudent = () => {
        if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

        navigate(ScreenNames.TeacherAddStudent, { classID: currentClass?.id })
    }

    const navigateToScheduleSession = () => {
        if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

        navigate(ScreenNames.TeacherClassSchedule, { classID: params?.classID })
    }

    const copyToClipboard = (newId: string) => {
        Clipboard.setStringAsync(`${envWebUrl}/JoinClass/studentJoinClass/${newId}`);
    };


    const updateClassSettings = async (settings: {
        [K in keyof Class]?: Class[K];
    }) => {
        if (currentClass) {
            const { id, _version } = currentClass;
            await updateClassMutation({
                variables: {
                    input: {
                        id,
                        _version,
                        ...settings,
                    },
                },
            });
        }
    };


    const changeEmotionalIntensity = async (newLevel: number) => {

        await updateClassSettings({
            emotionIntensityLevel: newLevel,
            toggleIndividualStudentStats: newLevel === 1 ? false : undefined
        })
        rvParentIntensityState(newLevel)

    }

    const changeToogleChatToTeacher = async (switchStatus: boolean) => {
        if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

        await updateClassSettings({
            toggleRequestForChat: switchStatus,
            toggleRequestForChatNotifications: switchStatus

        })
    }

    const changeToggleIndividualStats = async (individualStatus: boolean) => {
        if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

        await updateClassSettings({
            toggleIndividualStudentStats: individualStatus,
        })

    }

    const changeToggleWellbeing = async (wellbeing: boolean) => {
        if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

        await updateClassSettings({
            wellbeing: wellbeing,
        })

    }

    const changeToggleEating = async (eating: boolean) => {
        if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

        await updateClassSettings({
            toggleEating: eating,
        })

    }

    const showClassJoinCode = async () => {
        if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

        if (currentClass) {
            const newId = currentClass.bulkSignUpId || uuid.v4().toString()
            if (currentClass.bulkSignUpId === null) {
                const { id, _version } = currentClass

                await updateClassSettings({
                    bulkSignUpId: newId,
                })
            }
            copyToClipboard(newId)
            showAlert({
                title: 'Join class link.',
                message: <View style={styles.qrContainer}><QRCode value={`${envWebUrl}/JoinClass/studentJoinClass/${newId}`} /><DefaultText style={{ textAlign: 'center', fontSize: 12, paddingTop: 10, }} children={`Link has also been copied to your clipboard.`}></DefaultText><DefaultText style={{ textAlign: 'center', fontSize: 12, paddingTop: 10, }} children={`${envWebUrl}/JoinClass/studentJoinClass/${newId}`}></DefaultText></View>,
                leftButtonText: 'Ok',
            })
        }
    }

    const showCurrentClassLogin = () => {
        if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

        if (currentClass) {
            navigate(ScreenNames.TeacherClassAdminCurrentClassLogin, { classID: params.classID })
        }
    }


    const showInfoCurrentClassLogin = () => {
        showAlert({
            title: `${currentClass?.goMode === true ? 'GO Group' : 'Class'} Login`,
            message: `The "${currentClass?.goMode === true ? 'GO Group' : 'Class'} Login" is a shared account that you can set the username and password for, that all students can use to log into the Switch4Schools program. This is recommended when students are using shared devices.`,
            leftButtonText: 'ok',
        })
    }

    const showInfoCopyClassUrl = () => {
        showAlert({
            title: `Copy ${currentClass?.goMode === true ? 'GO Group' : 'Class'} URL`,
            message: `Copy the ${currentClass?.goMode === true ? 'GO group' : 'class'} URL” code to send to students or have them scan the QR code to enable them to set up their Switch4Schools account. The system will prompt them through the process and you will be alerted that they are waiting to be approved to join your ${currentClass?.goMode === true ? 'GO group' : 'class'}.`,
            leftButtonText: 'ok',
        })
    }

    const showInfoClassLoginQRLoginCode = () => {
        showAlert({
            title: `${currentClass?.goMode === true ? 'GO Group' : 'Class'} login QR Code`,
            message: "This feature allows students to login to your class via a QR code without needing to type in their username or password \n\n 1. \nEnsure you have a class login code. Create this via the ‘Class login’ button. \n\n 2. \nSelect the ‘Class login QR code’ button. Students will then be able to access the class via the QR code or the link (which will automatically be copied to your clipboard).",
            leftButtonText: "Ok"
        })
    }

    const showClassLoginQRLoginCode = async () => {
        const { data } = await listCurrentClassLoginQuery()
        const classLogins = data?.listClassLoginByClassID?.items.filter((item) => item?._deleted !== true)
        const currentClassLogin = classLogins?.length ? classLogins[0] : null

        if (currentClassLogin) {
            const classCodeQRCodeUrl = encodeURI(`${envWebUrl}/LoginClassLogin?username=${encodeURIComponent(currentClassLogin?.nickname)}&password=${encodeURIComponent(currentClassLogin?.classCode)}`)
            showAlert({
                title: 'Class login QR',
                message: <View style={styles.qrContainer}><QRCode value={classCodeQRCodeUrl} /><DefaultText style={{ textAlign: 'center', fontSize: 12, paddingTop: 10, }} children={`Link has also been copied to your clipboard.`}></DefaultText><DefaultText style={{ textAlign: 'center', fontSize: 12, paddingTop: 10, }} children={classCodeQRCodeUrl}></DefaultText></View>,
                leftButtonText: 'Ok',
            })
            return
        }

        showAlert({
            title: "Class login",
            message: "No class login found. Set up now?",
            leftButtonText: "Cancel",
            rightButtonText: "Yes",
            onRightButtonPress: showCurrentClassLogin
        })
    }

    const showInfoEmotionIntensityScale = () => {
        rvTeacherEmotionIntensityScaleModal(true)
    }

    const showInfoRequestForChat = () => {
        rvTeacherRequestForChatInfoModal(true)
    }

    const showWellbeingInfoModal = () => {
        rvTeacherWellbeingInfoModal(true)
    }

    const showInfoIndividualStudentStats = () => {
        rvTeacherIndividualStudentStatsInfoModal(true)
    }

    const showEatingQuestionInfoModal = () => {
        rvTeacherEatingQuestionInfoModal(true)
    }

    return (
        <Page style={styles.page}>
            {userGroup === "Admin" && (
                <View style={{ width: '100%', justifyContent: 'flex-start' }}>
                    <BackButton />
                </View>
            )}
            <ScrollView style={{ flex: 1, width: '100%' }} contentContainerStyle={{ flexDirection: windowWidthCondition ? 'column' : 'row', padding: 20 }}>
                <ViewResponsive style={{ marginRight: !windowWidthCondition ? 20 : undefined }}>
                    {
                        currentClass?.goMode === true ?
                            <>
                                <PageTitleText>GO Group Admin</PageTitleText>
                                <DefaultText style={styles.subtitle}>This group has been set up to allow for individualised check ins any time between 8am and 4pm without the need to open and close sessions.</DefaultText>
                            </>
                            : <PageTitleText>Class Admin</PageTitleText>
                    }

                    <View style={styles.intensityContainer}>
                        <View style={styles.textInfoContainer}>
                            <DefaultText>Emotion Intensity Scale</DefaultText>
                            <InformationIcon onPress={showInfoEmotionIntensityScale} color={DecidaColors.Green} />
                        </View>
                        <Stepper
                            values={[
                                { value: 1 },
                                { value: 3 },
                                { value: 5 },
                                { value: 7 },
                            ]}
                            currentValue={getIntensityLevel(currentClass?.emotionIntensityLevel)}
                            onChange={changeEmotionalIntensity}
                            width={100}
                            height={38}
                            SelectedFontSize={20}
                            fontSize={15}
                        />
                    </View>
                    {
                        currentClass?.emotionIntensityLevel !== 1 && (
                            <View style={styles.intensityContainer}>
                                <DefaultText>Individual Student Stats <InformationIcon onPress={showInfoIndividualStudentStats} color={DecidaColors.Green} /></DefaultText>
                                <DefaultSwitch value={currentClass?.toggleIndividualStudentStats || false} onChange={changeToggleIndividualStats} />
                            </View>
                        )
                    }

                    {school?.wellbeingFeature && (
                        <View style={styles.intensityContainer}>
                            <DefaultText>Wellbeing Question Set <InformationIcon onPress={showWellbeingInfoModal} color={DecidaColors.Green} /></DefaultText>
                            <DefaultSwitch value={currentClass?.wellbeing || false} onChange={changeToggleWellbeing} />
                        </View>
                    )}

                    <View style={styles.intensityContainer}>
                        <DefaultText>Eating Question <InformationIcon onPress={showEatingQuestionInfoModal} color={DecidaColors.Green} /></DefaultText>
                        <DefaultSwitch value={currentClass?.toggleEating || false} onChange={changeToggleEating} />
                    </View>

                    <View style={styles.intensityContainer}>
                        <View style={styles.textInfoContainer}>
                            <DefaultText>Request Teacher Chat</DefaultText>
                            <InformationIcon onPress={showInfoRequestForChat} color={DecidaColors.Green} />
                        </View>
                        <DefaultSwitch value={currentClass?.toggleRequestForChat || false} onChange={changeToogleChatToTeacher} />
                    </View>
                    {currentClass?.toggleRequestForChat && <TeacherClassAdminRequestForChats />}

                    <DefaultButton onPress={navigateToAddNewStudent} style={styles.button}>
                        <IconPlus />
                        <ButtonText >Add student</ButtonText>
                    </DefaultButton>
                    {
                        currentClass?.goMode !== true &&
                        <DefaultButton onPress={navigateToScheduleSession} style={styles.button}>
                            <ButtonText >Schedule session</ButtonText>
                        </DefaultButton>
                    }
                    <DefaultButton onPress={showClassJoinCode} style={styles.copyClassButton}>
                        <InformationIcon onPress={showInfoCopyClassUrl} style={styles.informationIcon} />
                        <Ionicons name="copy" size={24} color={DecidaColors.White} style={styles.copyIcon} />
                        <ButtonText>{`Copy ${currentClass?.goMode === true ? 'GO Group' : 'Class'} URL`}</ButtonText>
                    </DefaultButton>
                    <DefaultButton onPress={showCurrentClassLogin} style={styles.classLoginButton}>
                        <InformationIcon onPress={showInfoCurrentClassLogin} style={styles.informationIcon} />
                        <Ionicons name="ios-people-sharp" size={24} color={DecidaColors.White} style={styles.copyIcon} />
                        <ButtonText >{`${currentClass?.goMode === true ? 'GO Group' : 'Class'} Login`}</ButtonText>
                    </DefaultButton>
                    <DefaultButton onPress={showClassLoginQRLoginCode} style={styles.classLoginButton}>
                        <InformationIcon onPress={showInfoClassLoginQRLoginCode} style={styles.informationIcon} />
                        <Ionicons name="qr-code" size={24} color={DecidaColors.White} style={styles.copyIcon} />
                        <ButtonText>{`${currentClass?.goMode === true ? 'GO Group' : 'Class'} login QR Code`}</ButtonText>
                    </DefaultButton>
                    <ButtonResetPasswordClassStudents studentClasses={currentStudentClasses} style={styles.button} />
                </ViewResponsive>
                <ViewResponsive style={[styles.studentListContainer, {
                    zIndex: lowRes ? -1 : undefined,
                    marginLeft: lowRes ? -50 : undefined,
                    marginRight: lowRes ? -75 : undefined,
                }]}>
                    {
                        currentStudentClasses?.length === 0 ?
                            <DefaultText style={styles.noStudentText}>
                                No students currently in this class
                            </DefaultText>
                            : (
                                <View style={styles.studentActiveAndPendingsContainer(windowWidthCondition)}>
                                    <View style={width > 850 ? styles.dropdownContainer : styles.dropdownContainerMobile}>
                                        <DropdownSortStudent />
                                    </View>
                                    <ListPendingStudents type={PendingStudentsType.Teacher} classID={currentClass?.id || ""} />
                                    <ListActiveStudents type={ActiveStudentsType.Teacher} classID={currentClass?.id || ""} />

                                </View>
                            )
                    }
                </ViewResponsive>
                <SafeAreaView>

                </SafeAreaView>
            </ScrollView>
        </Page >
    )
}

const avatarSize = 40

const styles = StyleSheet.create<any>({
    textInfoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    page: {
        justifyContent: 'flex-start',
    },
    intensityContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: TEACHER_SIDEBAR_WIDTH,
        marginBottom: 20,
    },
    noStudentText: {
        marginBottom: 20,
    },
    copyIcon: {
        marginRight: 5,
    },
    copyClassButton: {
        backgroundColor: DecidaColors.Blue,
        width: TEACHER_SIDEBAR_WIDTH
    },
    classLoginButton: {
        backgroundColor: DecidaColors.Orange,
        width: TEACHER_SIDEBAR_WIDTH
    },

    qrContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    informationIcon: {
        position: 'absolute',
        right: 10,
    },
    studentListContainer: {
        flex: 1,
        width: '100%',
        flexWrap: 'wrap',
        marginTop: 10
    },
    studentActiveAndPendingsContainer: (windowWidthCondition: any) => ({
        flex: 1,
        width: windowWidthCondition ? TEACHER_SIDEBAR_WIDTH : '100%'
    }),
    dropdownContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 3
    },
    dropdownContainerMobile: {
        alignSelf: 'center',
        marginBottom: 10
    },
    subtitle: {
        fontSize: 14,
        width: TEACHER_SIDEBAR_WIDTH,
        marginBottom: 10,
        color: DecidaColors.GrayText,
    } as TextStyle,
    button: {
        width: TEACHER_SIDEBAR_WIDTH
    }
})