import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { CheckInSession, StudentClass } from '../../common/API';
import { TeacherStatsAllPeriodsGraph } from '../teacher/teacher-stats-all-periods-graph';
import { AllStatsPeriodsGraphYAxis } from './all-stats-period-graph';

interface WeeklyGraphsProps {
    checkInSessionsByWeek: CheckInSession[][];
    checkstate: boolean;
    currentStudentClass: StudentClass;
}

const WeeklyGraphs: React.FC<WeeklyGraphsProps> = ({ checkInSessionsByWeek, checkstate, currentStudentClass }) => {


    return (
        <View style={{ padding: 20 }}>
            <View style={styles.graphViewContainer}>
                {checkInSessionsByWeek.map((checkInSessionWeek) => (
                    <View key={checkInSessionWeek[0].createdAt} style={styles.weekGraph}>
                        <AllStatsPeriodsGraphYAxis />
                        <ScrollView style={styles.graphScroll} horizontal alwaysBounceHorizontal={false}>
                            <TeacherStatsAllPeriodsGraph
                                checkInSessions={checkstate ? checkInSessionWeek : checkInSessionWeek.reverse()}
                                studentClass={currentStudentClass}
                            />
                        </ScrollView>
                    </View>
                ))}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    graphViewContainer: {
        flexBasis: 'auto',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },
    graphScroll: {
        flexGrow: 0,
    },
    weekGraph: {
        flexDirection: 'row',
        padding: 5,
        maxWidth: '100%',
    },
});

export default WeeklyGraphs;