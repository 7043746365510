import { gql, InternalRefetchQueriesInclude } from "@apollo/client"
import { listCampusesBySchoolID, listClassGroupsBySchoolID } from "../../common/graphql/queries"
import { adminClassFields, adminGetClassQuery } from "../custom-graphql/queries/admin-custom-queries/admin-get-class-query"
import { adminSchoolAdminFields } from "../custom-graphql/queries/admin-custom-queries/admin-get-school-admin-query"
import { adminCampusFields, adminClassGroupsFields, adminGetSchoolQuery, adminSchoolFields } from "../custom-graphql/queries/admin-custom-queries/admin-get-school-query"
import { adminTeacherFields } from "../custom-graphql/queries/admin-custom-queries/admin-get-teacher-query"
import { getSchoolCampusesAndGroupsQuery as schoolAdminGetSchoolQuery } from "../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query"
import { adminGetStaffQuery, adminStaffFields } from "../custom-graphql/queries/admin-custom-queries/admin-get-staff-query"

export const createTeacherMutation = `
  mutation CreateTeacher(
    $input: CreateTeacherInput!
    $condition: ModelTeacherConditionInput
  ) {
    createTeacher(input: $input, condition: $condition) {
      ${adminTeacherFields}
    }
  }
`

export const updateTeacherMutation = `
  mutation UpdateTeacher(
    $input: UpdateTeacherInput!
    $condition: ModelTeacherConditionInput
  ) {
    updateTeacher(input: $input, condition: $condition) {
      ${adminTeacherFields}
    }
  }
`

export const deleteTeacherMutation = `
  mutation DeleteTeacher(
    $input: DeleteTeacherInput!
    $condition: ModelTeacherConditionInput
  ) {
    deleteTeacher(input: $input, condition: $condition) {
      ${adminTeacherFields}
    }
  }
`

export const listSchoolsQuery = `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ${adminSchoolFields}
      }
      nextToken
      startedAt
      __typename
    }
  }
`

export const getListTeacherBySchoolIDQuery = `
  query GetListTeacherBySchoolID(
    $schoolID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeacherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getListTeacherBySchoolID(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${adminTeacherFields}
      }
    }
}
`

export const createSchoolMutation = `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      ${adminSchoolFields}
      campuses(filter: {_deleted: {ne: true}}) {
        items {
            ${adminCampusFields}
            classGroups {
              items {
                ${adminClassGroupsFields}
                classes {
                  items {
                    ${adminClassFields}
                  }
                }
              }
            }
        }
      }
    }
  }
`

export const updateSchoolMutation = `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      ${adminSchoolFields}
      campuses(filter: {_deleted: {ne: true}}) {
        items {
            ${adminCampusFields}
            classGroups {
              items {
                ${adminClassGroupsFields}
                classes {
                  items {
                    ${adminClassFields}
                  }
                }
              }
            }
        }
      }
    }
  }
`

export const deleteSchoolMutation = `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      ${adminSchoolFields}
    }
  }
`


export const createSchoolAdminMutation = `
  mutation CreateSchoolAdmin(
    $input: CreateSchoolAdminInput!
    $condition: ModelSchoolAdminConditionInput
  ) {
    createSchoolAdmin(input: $input, condition: $condition) {
        ${adminSchoolAdminFields}
    }
  }
`

export const updateSchoolAdminMutation = `
  mutation UpdateSchoolAdmin(
    $input: UpdateSchoolAdminInput!
    $condition: ModelSchoolAdminConditionInput
  ) {
    updateSchoolAdmin(input: $input, condition: $condition) {
        ${adminSchoolAdminFields}
    }
  }
`

export const deleteSchoolAdminMutation = `
  mutation DeleteSchoolAdmin(
    $input: DeleteSchoolAdminInput!
    $condition: ModelSchoolAdminConditionInput
  ) {
    deleteSchoolAdmin(input: $input, condition: $condition) {
        ${adminSchoolAdminFields}
    }
  }
`


export const createStaffMutation = `
  mutation CreateStaff(
    $input: CreateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    createStaff(input: $input, condition: $condition) {
        ${adminStaffFields}
    }
  }
`

export const updateStaffMutation = `
  mutation UpdateStaff(
    $input: UpdateStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    updateStaff(input: $input, condition: $condition) {
        ${adminStaffFields}
    }
  }
`

export const deleteStaffMutation = `
  mutation DeleteStaff(
    $input: DeleteStaffInput!
    $condition: ModelStaffConditionInput
  ) {
    deleteStaff(input: $input, condition: $condition) {
        ${adminStaffFields}
    }
  }
`

export const adminTeacherClassFields = `
  id
  teacherID
  classID
  createdAt
  updatedAt
  _version
  _deleted
  _lastChangedAt
  __typename
`

export const createTeacherClassMutation = `
  mutation CreateTeacherClass(
    $input: CreateTeacherClassInput!
    $condition: ModelTeacherClassConditionInput
  ) {
    createTeacherClass(input: $input, condition: $condition) {
      ${adminTeacherClassFields}
    }
  }
`

export const createClassMutation = `
  mutation CreateClass(
    $input: CreateClassInput!
    $condition: ModelClassConditionInput
  ) {
    createClass(input: $input, condition: $condition) {
      ${adminClassFields}
    }
  }
`

export const updateClassMutation = `
  mutation UpdateClass(
    $input: UpdateClassInput!
    $condition: ModelClassConditionInput
  ) {
    updateClass(input: $input, condition: $condition) {
      ${adminClassFields}
    }
  }
`

export const deleteClassMutation = `
  mutation DeleteClass(
    $input: DeleteClassInput!
    $condition: ModelClassConditionInput
  ) {
    deleteClass(input: $input, condition: $condition) {
      ${adminClassFields}
    }
  }
`

export const mutateSchoolRefetchQueries: InternalRefetchQueriesInclude = [gql`${listSchoolsQuery}`]
export const mutateSchoolAdminRefetchQueries: InternalRefetchQueriesInclude = [gql`${adminGetSchoolQuery}`]
export const mutateStaffRefetchQueries: InternalRefetchQueriesInclude = [gql`${adminGetSchoolQuery}`]
export const mutateCampusRefetchQueries: InternalRefetchQueriesInclude = [gql`${adminGetSchoolQuery}`, gql`${listCampusesBySchoolID}`]
export const mutateClassRefetchQueries: InternalRefetchQueriesInclude = [gql`${adminGetSchoolQuery}`]
export const mutateClassGroupRefetchQueries: InternalRefetchQueriesInclude = [gql`${adminGetSchoolQuery}`, gql`${listClassGroupsBySchoolID}`]
export const mutateSchoolAdminClassGroupRefetchQueries: InternalRefetchQueriesInclude = [schoolAdminGetSchoolQuery]
export const mutateTeacherRefetchQueries: InternalRefetchQueriesInclude = [gql`${getListTeacherBySchoolIDQuery}`]
export const mutateTeacherClassRefetchQueries: InternalRefetchQueriesInclude = [gql`${adminGetClassQuery}`]
export const mutateStudentClassRefetchQueries: InternalRefetchQueriesInclude = [gql`${adminGetClassQuery}`]
