import { DimensionValue, StyleSheet, TouchableOpacity, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from './default-text'
import { CopyIcon } from './svg-files/copy-icon'
import { Pressable } from 'react-native'

type Props = {
    size?: DimensionValue
    onPress?: () => void
    title?: string
}

const CopyTextAndIcon = ({ size = 35, onPress, title = "Copy" }: Props) => {
    return (
        <Pressable onPress={onPress} style={styles.container}>
            <View style={{ width: size, height: size, }}>
                <CopyIcon />
            </View>
            <DefaultText style={styles.copyText}>copy</DefaultText>
        </Pressable>

    )
}
export default CopyTextAndIcon
const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
    },
    copyText: {
        fontSize: 16,
        fontWeight: '800',
        color: DecidaColors.Green,
    }
})
