import { Pressable, StyleSheet, Text, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'

type Props = {
    total: number
    currentStepIndex: number
    onPressDot: (index: number) => void
}
const StepperDot = ({ total, currentStepIndex = 0, onPressDot }: Props) => {

    return (
        <View style={styles.stepperContainer}>
            {Array.from(Array(total)).map((_, index) => {
                const isSelected = currentStepIndex === index
                return (
                    <Pressable onPress={() => onPressDot(index)} key={String(index)} style={isSelected ? styles.dotSelected : styles.dot} />
                )
            })}
        </View>
    )
}
export default StepperDot
const styles = StyleSheet.create({
    stepperContainer: {
        flexDirection: 'row'
    },
    dot: {
        height: 14,
        width: 14,
        borderRadius: 7,
        backgroundColor: DecidaColors.LightGray,
        marginRight: 5
    },
    dotSelected: {
        height: 14,
        width: 14,
        borderRadius: 7,
        backgroundColor: DecidaColors.Green,
        marginRight: 5
    }
})