import { StackScreenProps } from "@react-navigation/stack"
import { BackButton } from "../common/back-button"
import { AdminStackNavigatorParamList } from "./admin-route-param-types"
import { gql, useLazyQuery, useQuery } from "@apollo/client"
import { GetSchoolQuery, GetSchoolQueryVariables, ListStudentsBySchoolIDQueryVariables, StudentClassApprovalStatus } from "../../common/API"
import { useEffect, useState } from "react"
import { Loading } from "../common/loading"
import { isDefined } from "../../common/is-defined"
import { AdminListStudentsBySchoolIDQuery, adminListStudentsBySchoolID } from "../custom-graphql/queries/admin-custom-queries/admin-list-students-by-school-id-query"
import { DefaultButton } from "../common/default-button"
import { saveAs } from 'file-saver'
import { getSchoolQuery } from "../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-query"
import { sortBy } from 'lodash'

export const AdminSchoolStudentList = ({ route: { params: { schoolID } } }: StackScreenProps<AdminStackNavigatorParamList, 'AdminSchoolStudentList'>) => {
    const { data: schoolData } = useQuery<GetSchoolQuery, GetSchoolQueryVariables>(gql`${getSchoolQuery}`, { variables: { id: schoolID || '' }, skip: !schoolID })
    const [listStudents] = useLazyQuery<AdminListStudentsBySchoolIDQuery, ListStudentsBySchoolIDQueryVariables>(gql`${adminListStudentsBySchoolID}`, { fetchPolicy: 'network-only' })
    const [loading, setLoading] = useState(true)
    const [students, setStudents] = useState<NonNullable<NonNullable<AdminListStudentsBySchoolIDQuery['listStudentsBySchoolID']>['items'][0]>[]>([])

    const retrieveAllStudents = async () => {
        if (schoolID) {
            let queryResult = await listStudents({ variables: { schoolID, limit: 5000 } })
            let allStudents = queryResult.data?.listStudentsBySchoolID?.items || []

            while (queryResult.data?.listStudentsBySchoolID?.nextToken) {
                queryResult = await listStudents({ variables: { schoolID, limit: 5000, nextToken: queryResult.data.listStudentsBySchoolID.nextToken } })
                if (queryResult.data?.listStudentsBySchoolID?.items) {
                    allStudents = allStudents.concat(queryResult.data.listStudentsBySchoolID.items)
                }
            }

            const filteredAndSortedStudents =
                sortBy(allStudents
                    .filter(isDefined)
                    .filter(s => s._deleted !== true)
                    .map(s => ({
                        ...s,
                        classes: {
                            ...s.classes,
                            items: s.classes.items.filter(sc => sc?._deleted !== true && sc?.status === StudentClassApprovalStatus.APPROVED && sc.class && sc.class._deleted !== true && sc.class.archived !== true)
                        }
                    })),
                    s => s.classes.items.length > 0,
                    s => s.firstName?.toLowerCase(),
                    s => s.lastName?.toLowerCase(),
                    s => s.cognitoUsername
                )

            setStudents(filteredAndSortedStudents)
        }

        setLoading(false)
    }

    useEffect(() => {
        retrieveAllStudents()
    }, [])

    const exportToCSV = () => {
        let fileContent = ['"First name","Last name","Email","Cognito username","Nickname","Classes"']

        students.forEach(s => {
            fileContent.push(`"${s.firstName || ''}","${s.lastName || ''}","${s.email || ''}","${s.cognitoUsername || ''}","${s.nickname || ''}","${s.classes.items.map(c => c?.class?.name).filter(n => !!n).join(', ')}"`)
        })

        const blob = new Blob([fileContent.join('\n')], { type: 'text/plain;charset=utf-8' })

        saveAs(blob, (schoolData?.getSchool?.name || '') + ' student list.csv')
    }

    return (
        <div id='admin-school-student-list' style={{ overflow: 'scroll' }}>
            <BackButton displayName='Back' />
            {
                loading ? (
                    <Loading isLoading />
                ) : schoolID ? (
                    <div>
                        <br />
                        <DefaultButton onPress={exportToCSV}>Export to CSV</DefaultButton>
                        <br />
                        <table id='admin-school-student-list-table' style={{ WebkitUserSelect: 'text' }}>
                            <tbody>
                                <tr>
                                    <th>First name</th>
                                    <th>Last name</th>
                                    <th>Email</th>
                                    <th>Cognito username</th>
                                    <th>Nickname</th>
                                    <th>Classes</th>
                                </tr>
                                {
                                    students.map(s => {
                                        const activeClasses = s.classes.items
                                            .filter(isDefined)
                                            .filter(c => c.class?.archived !== true && c.class?._deleted !== true)

                                        return (
                                            <tr key={s.id}>
                                                <td>{s.firstName}</td>
                                                <td>{s.lastName}</td>
                                                <td>{s.email}</td>
                                                <td>{s.cognitoUsername}</td>
                                                <td>{s.nickname}</td>
                                                <td>
                                                    {
                                                        activeClasses.length > 0 && (
                                                            <ul>
                                                                {
                                                                    activeClasses
                                                                        .map(c => (
                                                                            <li key={c.id}>{c.class?.name + ` (${c.status})`}</li>
                                                                        ))
                                                                }
                                                            </ul>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div>SchoolID must be specified</div>
                )
            }
        </div>
    )
}