import { TouchableOpacity } from 'react-native'
import { StyleSheet, View } from 'react-native'
import { ExtraTipsIcon } from '../switches/common/extra-tips'
import { DefaultText } from './default-text'
import { IconSize } from './const'
import { windowWidthCondition } from './use-responsive'
import { DecidaColors } from '../../common/decida-colors'
type Props = {
    onPress: () => void
}
const LessonPlanButton = ({ onPress }: Props) => {
    return (
        <TouchableOpacity style={styles.imagecontainer} onPress={onPress}>
            <View style={styles.extratips}>
                <ExtraTipsIcon />
            </View>
            <DefaultText style={[styles.extraTipsText, { color: DecidaColors.GrayText }]}>
                Lesson plan
            </DefaultText>
        </TouchableOpacity>
    )
}
export default LessonPlanButton
const styles = StyleSheet.create({
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    extratips: {
        width: IconSize,
        height: IconSize,
    },
    extraTipsText: {
        fontSize: windowWidthCondition ? 15 : 10,
        marginTop: 5

    },
    imagecontainer: {
        padding: windowWidthCondition ? 10 : 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
})