
import { StyleSheet } from 'react-native'
import { Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width;


export const tileSizes =
    // TODO: this won't work when you resize browser or rotate device
    windowWidth > 600 ?
        StyleSheet.create({

            tile: {
                width: 100,
                height: 100,
            },
            item: {
                margin: 30,
                width: 125,
                height: 125,
                justifyContent: 'flex-start',
                alignItems: 'center',
                textAlign: 'center',
            },

        })
        :
        StyleSheet.create({

            tile: {

                width: 100,
                height: 100,

            },
            item: {
                margin: 20,
                marginVertical: 40,
                width: 125,
                height: 125,
                justifyContent: 'flex-start',
                alignItems: 'center',
                textAlign: 'center',
            },

        })

