import { Linking, StyleSheet, Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { WeatherItHeader } from './src/weather-it-header'
import { WeatherItIcon } from './src/weather-it-icon'
import { WeatherItImage1 } from './src/weather-it-image1'
import { WeatherItLessonPlan } from './src/weather-it-lesson-plan'
import { Worksheets } from '../../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'

export const SwitchesEmotionsExcitedWeatherIt: CardTemplate = {
    cardId: "cf913036-caa7-4d02-8940-2636c7d71428",
    title: 'Weather it',
    Header: WeatherItHeader,
    Icon: WeatherItIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Creating clarity and calmness. "} />
        </>
    ),
    Images: () => (
        <>
            <WeatherItImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Think about the thing or things you are excited, or feeling strongly about.  "} />
                <DefaulttextWithRightArrow textdata={"Think about what it would look like if you represented this feeling as the weather e.g. feeling excited might be like a thunder storm. "} />
                <DefaulttextWithRightArrow textdata={"Draw a picture of it. "} />
                <DefaulttextWithRightArrow textdata={"Think into the future and picture what the impact of this weather, these big emotions, might be on those around you. How could you change things to improve future outcomes? "} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: 'Representing feelings as weather events can help make the implicit explicit, and help you conceptualise abstract or ambiguous concepts. Further to this, when focused on the future you are forced to be present in the moment and to focus on your thoughts and feelings. This can help you to become more mindful and aware of your current situation. ',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Students will learn how to visualise and articulate their future state. </DefaultText>
                    </>
                }
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by asking students if they have ever felt really excited about something.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that excitement is a natural emotion but can sometimes be challenging to manage.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of visualisation as a way to step back and look at a situation before taking action. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided practice (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to think about a time when they felt a strong emotion. Refer to the Emotion Wheel for inspiration.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to choose a weather symbol to represent how they felt at that time (refer to the weather symbol cards for inspiration).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students draw their chosen weather symbols on a large sticky note or similar. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to think about how they felt at that time and how they coped with the emotion.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ As they complete their drawings, create a collaborative display of weather symbols.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Sharing and Discussion (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once all symbols are drawn, have students share their stories with the group.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of taking a mental step back from emotions and how it helps to understand and cope with feelings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the diversity of emotions and coping strategies within the group.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think about something they are currently excited about.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct them to think about what it would like represented as the weather and have them draw it. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their stories. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief to ask students how writing about their exciting event made them feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the concept of taking a step back and the benefits of using this strategy to cope with excitement.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to try taking a step back the next time they are feeling excited.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the idea that understanding and managing emotions contributes to overall wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, invite them to explore switches in the Switch4Schools app.</DefaultText>



                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='• Weather Symbols Cards' link={Worksheets.weatherSymbolsCards.uri} />
                        <DefaultText style={CommonStyles.lessonPlanText}>• A large piece of paper or whiteboard </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pens/pencils</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Large sticky notes or similar.</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>There are heaps of free mazes to be found at </DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='The Healing Power of “Stepping Back” From Intense Emotional Situations' link='https://mainlinetoday.com/life-style/health/emotional-situations/' />
                    </>}
            />

        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Weather+it+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Excited]
}

const styles = StyleSheet.create({
})
