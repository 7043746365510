import { StyleSheet, Text, View, Animated, Easing } from 'react-native';
import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { DecidaColors } from '../../common/decida-colors';

type Props = {
    visible: boolean;
    setVisible: (data: boolean) => void;
    message: string;
    type?: ToastAlertType;
    delay?: number;
};

export enum ToastAlertType {
    success,
    error,
}

const ToastAlert = ({ visible = true, setVisible, message, delay = 3, type = ToastAlertType.success }: Props) => {
    const isModalSuccess = type === ToastAlertType.success;

    const fadeAnim = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        if (visible) {
            fadeAnim.setValue(1);
            _.delay(dismiss, delay * 1000);
        }
    }, [visible]);

    const dismiss = () => {
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 400,
            easing: Easing.linear,
            useNativeDriver: false,
        }).start(() => setVisible(false));
    };

    return (
        <>
            {visible && (
                <Animated.View style={[styles.container, { backgroundColor: isModalSuccess ? DecidaColors.Green : DecidaColors.Red, opacity: fadeAnim }]}>
                    <Text style={styles.message}>{message}</Text>
                </Animated.View>
            )}
        </>
    );
};

export default ToastAlert;

const styles = StyleSheet.create({
    container: {
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderRadius: 5,
        margin: 10,
    },
    message: {
        color: DecidaColors.White,
    },
});
