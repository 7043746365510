import { useReactiveVar } from "@apollo/client"
import { rvParentPortal, rvUserGroup } from "../login/login-state"
import { rvParentIntensityState } from "../parent/parent-state"
import { UserGroup } from "../../common/constants"

export type IntensityLevel = 1 | 3 | 5 | 7
export const getIntensityLevel = (intensity: number | null | undefined) => intensity === 7 ? 7 : intensity === 5 ? 5 : intensity === 1 ? 1 : 3

export const getHomeIntensityLevel = (intensity: number | null | undefined) => {
    const userGroup = useReactiveVar(rvUserGroup)
    const currentEmotionIntensityLevel = useReactiveVar(rvParentIntensityState)
    const parentPortal = useReactiveVar(rvParentPortal)

    if (parentPortal || userGroup == UserGroup.Teacher || userGroup == UserGroup.Student || userGroup === UserGroup.ClassLogin || userGroup === UserGroup.NonTeachingStaff) {
        return currentEmotionIntensityLevel as IntensityLevel 
    }

    return intensity as IntensityLevel
}