import { View } from 'react-native'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { DefaultTextWithDownArrow } from '../../../common/default-text-with-down-arrow'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { SleepChalkBlueIcon } from '../../icons/sleep-chalk-blue-icon'
import { SleepChalkSideImage1 } from '../../../emotion-cards/anxious/src/sleepchalk-side-image1'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { SleepChalkBlueIconInverted } from '../../icons/sleep-chalk-blue-icon-inverted'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'

export const SwitchesEmotionsAnxiousSleepChalkBlue: CardTemplate = {
    cardId: "fbbbd9d2-9a73-497e-bdc9-9e356937959a",
    title: 'Sleep chalk',
    Header: SleepChalkBlueIconInverted,
    Icon: SleepChalkBlueIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Creative minds who are able to use imagination and escapism to let go of the days troubles."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"This also works if you write numbers. Start at 100 and count down."} />
            <DefaulTextWithTipsIcon textdata={"If you’re having trouble blocking out anxious thoughts, think about what you can smell, touch and hear in your memory."} />
            <DefaulTextWithTipsIcon textdata={"Remember, it’s OK to get distracted during the exercise. When this happens, acknowledge it, put it away for later, and then go back to where you left off in the visualisation task. This takes practice, but you will get better over time."} />
        </>

    ),
    Images: () => (
        <SleepChalkSideImage1 />
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"As you lay down to sleep, close your eyes and imagine a chalkboard."} />
                <DefaultTextWithDownArrow textdata={"Slowly draw the letter ‘A’ on the chalkboard."} />
                <DefaultTextWithDownArrow textdata={"Take time to breathe in and out on each chalk stroke."} />
                <DefaultTextWithDownArrow textdata={"Once you have finished the letter rub it off the board and write the letter ‘B’."} />
                <DefaultTextWithoutArrows textdata={"Continue through the alphabet."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SECRETS_FOR_SLEEP,
    whyDoesItWorkText: 'Sleep and mental rest is crucial to performance, increasing cognitive capacity and mental health. Anxiety is the brains safety mechanism kicking into place, indicating danger or imminent threat. Your brain doesn’t then let you sleep because it is keeping you prepared for an imminent attack, for which you need to be awake for. The chalk talk exercise calms your mind with an activity that utilises large sections of your brain (visual cortex, motor cortex etc.) but is also something your automatic brain can do, so that your conscious brain is free to fall asleep.',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Sleep+Chalk.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.SECRETS_FOR_SLEEP],
    action: [CardActionType.Action],
    level: []

}

