import { Platform, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { StarsAndWishesIcon } from '../../explore/src/stars-and-wishes-icon'
import { StarsAndWishesIconInverted } from '../../explore/src/stars-and-wishes-icon-inverted'
import { StarsAndWishesImage1 } from './src/stars-and-wishes-image1'

export const SwitchesEmotionsHappyStarsAndWishes: CardTemplate = {
    cardId: "46de060a-80a5-4389-a19d-383781ab8500",
    title: 'Stars & wishes',
    Header: StarsAndWishesIconInverted,
    Icon: StarsAndWishesIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Capturing optimism, focussing, and feeling positive about the future."} />
        </>
    ),
    Images: () => (
        <View style={{ height: '100%', paddingBottom: 20, alignItems: 'center' }}>
            <StarsAndWishesImage1 />
            {Platform.OS === "web" && <View style={{ width: '80%' }}>
                <DefaultTextWithoutArrowsItallic textdata={"Here is an example of what your ‘Star Page’ might look like."} />
            </View>}
        </View>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                {Platform.OS === "ios" && <DefaultTextWithoutArrowsItallic textdata={"Here is an example of what your ‘Star Page’ might look like."} />}
                <DefaulttextWithRightArrow textdata={"Come up with three “stars” —things you do well."} />
                <DefaulttextWithRightArrow textdata={"Come up with a “wish” —something to work on (a goal)."} />
                <DefaulttextWithRightArrow textdata={`Grab a sheet of paper and create a "Stars and Wishes" poster that you can put up on the wall or keep somewhere you can easily see it.`} />
                <DefaulTextWithTipsIcon textdata={"Possible thought starters include:"} />
                <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={false} textdata={"What do you often do with your family or friends because you find it easy?"} />
                <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={false} textdata={"What is something you have been complimented on for doing well?"} />
                <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={false} textdata={"What competitions have you been in or won?"} />
                <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={false} textdata={"What is your favourite thing to do?"} />
                <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={false} textdata={"What would your family/friends/teachers say you are good at?"} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: 'Research tells us that making progress on our goals makes us happier, and the feeling of being happy has the potential to stimulate goal-directed behaviors and volitional processes, leading us to accomplish more of our goals.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify personal strengths, and
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Construct a simple goal to focus energy on a positive direction. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that knowing your strengths and weaknesses gives you a better understanding of who you are and how your mind works. It's important to celebrate the things we are good at, and focus energy on the things we want to learn or get better at.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that a strength is more than just doing something well it’s a combination of skills and personal beliefs that affect they way you act and think about the world.  For example you might be good at playing the piano, but that’s because you have strengths in creativity (to come up with new tunes) and perseverance (to practice as much as you need to).  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Go through the example Strengths flash cards and have the students consider if this is a strength of theirs.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss goal setting and explain that goals are things we wish we could achieve or be better at.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to create a stars and wishes poster, where they: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- List three “stars” that they do well, (these can include strengths) and, </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- At least one “wish” for something they want to do better.  </DefaultText>
                        <View style={CommonStyles.lessonPlanImageContainer}>
                            <StarsAndWishesImage1 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Thought starters to help identify things done well that could be put on the board: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- You enjoy doing it</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- People say nice things about what you’ve done</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- You are proud of it</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- You’ve won a competition because of it</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Thought starters to help identify things wanting to do better:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Subject or topic at school</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Relationships with friends or family</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Sport</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Music or arts</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their posters with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage positive feedback and suggestions for achieving their goals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of supporting each other in reaching our goals and how celebrating small successes can help motivate us.  </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief with the students to ask how they felt thinking about their strengths and weaknesses and let them know that the first step in getting better at something is deciding you want to do something about. Congratulations!</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own device, ask them to find the switch in the Switch4Schools app.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Paper</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Strengths flash cards</DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Types of strengths in kids -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.understood.org/articles/en/types-of-strengths-in-kids' link='https://www.understood.org/articles/en/types-of-strengths-in-kids' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Stars+and+Wishes.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Happy]
}

