import { View } from 'react-native'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import { Worksheets } from '../../common/worksheets-imports'
import { posters } from '../tools/common/posters-import'
import * as Linking from 'expo-linking'
import { SwitchesActivitiesCreativityCharades } from '../activities/cards/creativity/charades'


export const SwitchesLessonPlansM1ExploringAndEmbracingEmotion: CardTemplate = {
    cardId: "86ae613c-50a7-4fab-b8ff-05d1436e1b78",
    title: 'Exploring and embracing emotions ',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Increase self awareness, understand the concept of primary and secondary emotions and consider how energy and emotion are correlated through data.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>• Duration 45 minutes </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by engaging students in a discussion about the importance of identifying and labelling emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If they have used the Switch4Schools check in process previously, ask them to share examples of situations where the program has helped their manage their wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Exploring and Embracing Emotions workbook to each student.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Increasing the Language of Emotions (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce that learning how to articulate emotions is a fundamental requirement for being emotionally intelligent.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present the level 7 emotion wheel.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss any words or rankings that they don’t understand.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play this video related to expressing emotions.  Are there universal expressions of emotion? </DefaultText>
                        <View style={{}}>
                            <VideoCard
                                video={{
                                    id: '1',
                                    url: 'https://www.youtube.com/embed/-hr58Yu0yDs',
                                    title: ""
                                }}
                            />

                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Then have students in pairs or small groups, choose words from the emotion wheel (not the emotion family word, but the different intensity descriptors) to express non verbally, to their partner to guess.   You may opt to make it a full game of charades.  More information on how to facilitate a game of charades can be found in the activities section.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Interpreting Self Reported Data (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that the Switch4Schools program collates their check in data into a handy graph that enables you to see patterns and trends over time.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show slides of different example data patterns and ask students to point out the pattern or trend the data presents.   </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Example one low energy levels resulting in fluctuating and mostly negative emotions</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Example two low energy level and negative emotions every Monday</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Example three   mostly positive emotions but then three days of sadness that is getting more intense each day</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Example four a variety of emotions that all start at level 5 intensity levels or higher, but gradually reduce to more consistently being lower levels of intensity more often.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students that there is no such thing as “normal” or “right or wrong” when it comes to managing your emotions.  The goal is understanding and managing emotions, not supressing.  The process of checking in is a strategy for managing emotions and is not reflective of any issue or deficiency.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a discussion about how self reported data can provide insights into personal emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ (If available) Show students where to find this data for themselves in the future.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Primary and Secondary Emotions (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of primary and secondary emotions. Noting that the main difference between primary and secondary emotions is that primary emotions are how we react to events and situations, whereas secondary emotions are reactions to how we feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Let's take the example of fear that presents as anger to understand how this can happen. Fear and anger are two distinct primary emotions, but they can interact and overlap in certain situations. When a person feels threatened or believes their safety is at risk, they might experience fear as their initial response. However, if they perceive the threat as intentional or unjust, anger can quickly emerge alongside their fear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Here's an example: Imagine you're walking home alone at night, and suddenly someone jumps out from behind a bush, startling you. Your immediate reaction might be fear, as you perceive a potential danger. However, if you realise that the person jumping out is a friend playing a prank on you, your fear might shift to anger. You may feel angry because you were frightened and didn't expect your friend to scare you. In this scenario, fear is the primary emotion triggered by the initial perceived threat, while anger becomes a secondary emotion that arises due to the perceived injustice or violation of trust. The blend of fear and anger can make it difficult to distinguish between the two emotions, as they are intertwined.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- It's important to note that people can experience and express emotions differently, and the specific combination of emotions can vary from person to person and situation to situation. Some individuals might be more prone to experiencing anger in response to fear, while others might respond differently. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- Refer students to the Primary and Secondary Emotion Wheel worksheet and have them work individually to identify a time where there experienced primary and secondary emotions.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- Encourage students to share personal experiences related to these emotions.  </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Closure (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on the importance of emotional self awareness and write down at least one thing they learnt and one thing they will do as a result of this lesson in their workbook.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss any challenges or insights they gained from the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the value of considering emotions and the impact it can have on personal wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In addition to checking in via the Switch program, encourage students to make use of the space in their workbook to journal their thoughts and feelings over the coming week to practice looking for trends in the data.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Level 7 emotion wheel'} link={"https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/LV7+how+are+you+feeling+today+cut+out.pdf"} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Primary and secondary emotions worksheet'} link={Worksheets.m1PrimaryAndSecondaryWorksheets.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Mastering the mind workbook, including section M1 exploring and embracing emotions'} link={Worksheets.masteryMasteringTheMindLevel7.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Charades (optional)'} link={`/Card?id=${SwitchesActivitiesCreativityCharades.cardId}&previousScreen=activities&previousScreenId=CREATIVITY&openLessonPlan=false`} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Primary Vs. Secondary Emotions-</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://therapyinanutshell.com/primary-vs-secondary-emotions/' link='https://therapyinanutshell.com/primary-vs-secondary-emotions/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The Importance of Emotional Intelligence -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://positivepsychology.com/importance-of-emotional-intelligence/' link='https://positivepsychology.com/importance-of-emotional-intelligence/' />

                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}