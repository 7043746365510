import { StyleSheet, View } from 'react-native'
import { DefaultText } from '../common/default-text'
import { DecidaColors } from '../../common/decida-colors'

interface Props {
    label?: string
    checked?: boolean
}
export const RadioButton = ({ label = '', checked = false }: Props) =>
    <View style={styles.container}>
        <View style={styles.checkBox}>
            {checked && <View style={styles.checkboxChecked} />}
        </View>
        <DefaultText style={styles.defaulttext}>{label}</DefaultText>
    </View>


const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    checkBox: {
        width: 12,
        height: 12,
        borderColor: DecidaColors.Gray,
        borderWidth: 1,
        marginTop: 3,
        marginRight: 10,
        padding: 1,
        borderRadius: 6,
        backgroundColor: DecidaColors.White
    },
    checkboxChecked: {
        flex: 2,
        backgroundColor: DecidaColors.Green,
    },
    defaulttext: {
        fontSize: 14,

    }
})