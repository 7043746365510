import { ViewStyle } from "react-native"
import { DecidaColors } from "../../common/decida-colors"

export const SCHOOL_ADMIN_ADD_CLASS = 'SCHOOL_ADMIN_ADD_CLASS'
export const SCHOOL_ADMIN_ADD_GO_CLASS = 'SCHOOL_ADMIN_ADD_GO_CLASS'
export const SCHOOL_ADMIN_ADD_TEACHER = 'SCHOOL_ADMIN_ADD_TEACHER'

export const schoolAdminBillingDetailsContainerStyle: ViewStyle = {
    padding: 20,
    borderWidth: 1,
    borderColor: DecidaColors.Gray,
    borderRadius: 20,
    marginTop: 10,
    marginBottom: 20,
    width: 500,
    marginHorizontal: 10,
}

export type SchoolAdminTableEditComponent = {
    class: string;
    teacher?: string | undefined;
} | null
export type SchoolAdminTableSetEditComponent = React.Dispatch<React.SetStateAction<SchoolAdminTableEditComponent>>
