import { useReactiveVar } from '@apollo/client'
import { useState, useEffect } from 'react'
import { SafeAreaView, ScrollView, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native'
import { Page } from '../common/page'
import { useStudentState } from '../student/use-student-state'
import { avatars } from '../icon/avatar/avatars'
import { useNavigation } from '@react-navigation/core'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'
import { API } from 'aws-amplify'
import { FontAwesome } from '@expo/vector-icons';
import { rvCurrentClass } from '../common/common-state'
import { ScreenNames } from '../common/screen-names'
import { rvIsLoading } from '../common/loading'
import { showAlert } from '../common/universal-alert'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { CommonStyles } from '../common/const'
import { BackButton } from '../common/back-button'
import { emailValidator } from '../common/email-validator'
import _ from 'lodash'
import { getApiHeaders } from '../common/get-api-header'
import { CLIENT_EVENT, createClientLog } from '../common/log-client-event'
import { updateCurrentStudent } from '../common/helper-api-functions'
import { InputCognitoUsername } from '../common/input-cognito-username'
import { FirstNameInput } from '../common/first-name-input'
import { LastNameInput } from '../common/last-name-input'
import { DecidaColors } from '../../common/decida-colors'
import { UserGroup } from '../../common/constants'


export const StudentProfile = () => {
    const [allowEdit, setAllowEdit] = useState(false)
    const { navigate, goBack, canGoBack } = useNavigation<DrawerNavigationProp<CheckInNavigatorParamList>>()
    const currentClass = useReactiveVar(rvCurrentClass)
    const { currentStudent } = useStudentState()

    const [firstName, setFirstName] = useState(currentStudent?.firstName || '')
    const [lastName, setLastName] = useState(currentStudent?.lastName || '')
    const [email, setEmail] = useState(currentStudent?.email)
    const [nickname, setNickname] = useState(currentStudent?.nickname)
    const [emailValidationmessage, setEmailValidationMessage] = useState<string | undefined>('')
    const [usernameUnavalible, setUsernameUnavalible] = useState<boolean | null>(null)


    useEffect(() => {
        setFirstName(currentStudent?.firstName || '')
        setLastName(currentStudent?.lastName || '')
        setEmail(currentStudent?.email)
        setNickname(currentStudent?.nickname)
    }, [currentStudent])

    const Avatar = avatars[currentStudent?.avatar as keyof typeof avatars]

    const navigateToEditStudentAvatar = () => {

        if (!currentStudent) {
            return null
        }

        navigate(ScreenNames.UserEditAvatar, { id: currentStudent?.id, user: UserGroup.Student })
    }

    const saveChangesStudent = async () => {
        if (currentStudent) {
            const username = nickname
            const cognitoUsername = currentStudent.cognitoUsername
            const emailCheck = email === '' ? undefined : email

            if (username !== currentStudent.nickname && usernameUnavalible) {
                showAlert({
                    title: 'Error',
                    message: 'Please select a valid username.',
                    leftButtonText: 'OK'
                })
                return
            }

            if (emailCheck) {
                if (emailValidationmessage === 'Email is not valid.') {
                    showAlert({
                        title: 'Error',
                        message: 'Please enter a valid email address or leave it blank.',
                        leftButtonText: 'OK'
                    })
                    return
                }
            }

            try {
                // If email hasn't changed don't run 
                if (username != currentStudent.nickname) {
                    rvIsLoading(true)
                    const post = await API.post(
                        'AdminQueries',
                        '/updatePreferredUsername',
                        {
                            body: {
                                username, cognitoUsername
                            },
                            headers: await getApiHeaders(),
                        }
                    )
                    if (post.completed) {

                        const { id, _version } = currentStudent
                        const payload = {
                            id,
                            _version,
                            firstName,
                            lastName,
                            email,
                            nickname: nickname!
                        }
                        await updateCurrentStudent(payload)
                        setAllowEdit(!allowEdit)
                        rvIsLoading(false)
                    } else {
                        showAlert({
                            title: 'An error has occured.',
                            message: post.message,
                            leftButtonText: 'Ok',
                            rightButtonText: 'Retry',
                            onRightButtonPress: saveChangesStudent
                        })
                        rvIsLoading(false)

                    }
                } else if (emailCheck != currentStudent.email) {
                    try {

                        rvIsLoading(true)
                        const post = await API.post(
                            'AdminQueries',
                            '/updateEmailAndVerify',
                            {
                                body: {
                                    cognitoUsername, email: email, status: emailCheck ? "true" : "false"
                                },
                                headers: await getApiHeaders(),
                            }
                        )
                        if (post.completed) {
                            const { id, _version } = currentStudent
                            const payload = {
                                id,
                                _version,
                                firstName,
                                lastName,
                                email: emailCheck,
                                nickname: nickname!
                            }
                            await updateCurrentStudent(payload)
                            createClientLog({
                                event: CLIENT_EVENT.EDIT_PROFILE_SUCCESS,
                                area: "Edit profile screen",
                                action: "Clicking save, profile updated successfully",
                                payload: {
                                    group: "student",
                                    nickname: currentStudent?.cognitoUsername,
                                }
                            })
                            setAllowEdit(!allowEdit)
                            rvIsLoading(false)
                        }
                        else {
                            showAlert({
                                title: 'An error has occured.',
                                message: post.message,
                                leftButtonText: 'Ok',
                                rightButtonText: 'Retry',
                                onRightButtonPress: saveChangesStudent
                            })
                            createClientLog({
                                event: CLIENT_EVENT.EDIT_PROFILE_FAILED,
                                area: "Edit profile screen",
                                action: "/updatePreferredUsername error, failed to update profile",
                                payload: {
                                    group: "student",
                                    nickname: currentStudent?.cognitoUsername,
                                    error: post.message
                                }
                            })
                            rvIsLoading(false)
                        }
                    }
                    catch (e: any) {
                        showAlert({
                            title: 'An error has occured.',
                            message: String(e),
                            leftButtonText: 'Ok',
                            rightButtonText: 'Retry',
                            onRightButtonPress: saveChangesStudent
                        })
                        createClientLog({
                            event: CLIENT_EVENT.EDIT_PROFILE_FAILED,
                            area: "Edit profile screen",
                            action: "An error has occured, failed to update profile",
                            payload: {
                                group: "student",
                                nickname: currentStudent?.cognitoUsername,
                                error: String(e)
                            }
                        })
                    }
                }
                else {
                    const { id, _version } = currentStudent
                    const payload = {
                        id,
                        _version,
                        firstName,
                        lastName,
                        email: email || undefined,
                        nickname: nickname!
                    }
                    await updateCurrentStudent(payload)
                    setAllowEdit(!allowEdit)
                }
            } catch (e: any) {
                showAlert({
                    title: 'An error has occured.',
                    message: String(e),
                    leftButtonText: 'Ok',
                    rightButtonText: 'Retry',
                    onRightButtonPress: saveChangesStudent
                })
            }
        }
    }

    const navigateToPasswordChange = () => {
        navigate(ScreenNames.ChangePasswordProfile)

    }

    const emailValidationSet = (value: string) => {
        const result = emailValidator(value)
        setEmailValidationMessage(result)
        setEmail(value)
    }

    if (!currentStudent || !currentClass) {
        return null
    }

    return (

        <Page style={styles.page}>
            <View style={styles.backButtonContainer}>
                <BackButton />
            </View>
            <ScrollView style={CommonStyles.inputScrollView}>
                <View style={CommonStyles.flexContainer}>
                    <View style={[styles.inputContainer, { width: 200 }]}>

                        <View style={styles.avatarContainer}>
                            {allowEdit ? (
                                <TouchableOpacity onPress={navigateToEditStudentAvatar}>
                                    <View style={styles.avatarSize}>
                                        <Avatar />
                                        <View style={styles.pencil}>
                                            <FontAwesome name="pencil" size={35} color={DecidaColors.Green} />
                                        </View>
                                    </View>
                                </TouchableOpacity>) : (
                                <>
                                    <Avatar />
                                    <DefaultText style={styles.nickname}>{nickname}</DefaultText>
                                </>
                            )
                            }
                        </View>

                    </View>

                    <View style={styles.inputContainer}>

                        {allowEdit && (
                            <>
                                <InputCognitoUsername
                                    title='Nickname'
                                    userExists={usernameUnavalible}
                                    setUserExists={setUsernameUnavalible}
                                    value={nickname || ""}
                                    onChangeText={setNickname}
                                />
                                <DefaultText style={styles.emailNote}>Note: nickname once changed becomes your new login username.</DefaultText>
                            </>)}

                        <View style={styles.leftAlign}>
                            <DefaultText style={styles.heading}>Class Name</DefaultText>
                        </View>

                        <DefaultText style={styles.subtitle}>{currentClass?.name}</DefaultText>
                        {
                            allowEdit ?
                                <FirstNameInput value={firstName} onChangeText={setFirstName} />
                                :
                                <>
                                    <View style={styles.leftAlign}>
                                        <DefaultText style={styles.heading}>First Name</DefaultText>
                                    </View>
                                    <DefaultText style={styles.subtitle}>{firstName}</DefaultText>
                                </>
                        }
                        {
                            allowEdit ?
                                <LastNameInput value={lastName} onChangeText={setLastName} />
                                :
                                <>
                                    <View style={styles.leftAlign}>
                                        <DefaultText style={styles.heading}>Lastname</DefaultText>
                                    </View>
                                    <DefaultText style={styles.subtitle}>{lastName}</DefaultText>
                                </>
                        }
                        <View style={styles.leftAlign}>
                            <DefaultText style={styles.heading}>Email</DefaultText>
                        </View>
                        {allowEdit ? <>
                            <TextInput
                                style={CommonStyles.textInput}
                                autoCapitalize='none'
                                autoComplete='email'
                                autoCorrect={false}
                                placeholder='Email'
                                value={email || ''}
                                onChangeText={emailValidationSet}
                            />
                            <DefaultText style={{ color: emailValidationmessage === 'Email is not valid.' ? DecidaColors.Red : DecidaColors.Green }}>{emailValidationmessage}</DefaultText>
                        </> : <DefaultText style={styles.subtitle}>{email}</DefaultText>}
                        <View style={styles.buttonContainer}>
                            {allowEdit ?
                                <>
                                    <TouchableOpacity style={styles.joinButton} onPress={saveChangesStudent} >
                                        <DefaultText style={styles.buttonText}>Save</DefaultText>
                                    </TouchableOpacity></> : <>

                                    <TouchableOpacity style={styles.joinButton} onPress={() => setAllowEdit(!allowEdit)}>
                                        <DefaultText style={styles.buttonText}>Edit</DefaultText>
                                    </TouchableOpacity>
                                </>
                            }
                        </View>
                        <DefaultButton style={styles.updatePassword} onPress={navigateToPasswordChange}>
                            Update password
                        </DefaultButton>
                    </View>
                </View>
            </ScrollView>

            <SafeAreaView>
            </SafeAreaView>
        </Page>
    )
}


const styles = StyleSheet.create({
    page: {

    },
    avatarContainer: {
        marginVertical: 50,
        height: 100,
        width: '100%',
        position: 'relative',

    },
    avatarSize: {
        width: 100,
        height: 100,
        alignItems: 'center'
    },
    backButtonContainer: {
        flexDirection: 'row',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%'
    },
    heading: {
        fontSize: 17,
        fontWeight: 'bold',
        paddingTop: 20,

    },
    inputContainer: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    nickname: {
        fontSize: 30,
        fontWeight: 'bold',
        color: DecidaColors.Green,
        textAlign: 'center',

    },
    subtitle: {
        color: DecidaColors.Green,
        marginBottom: 15,
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    joinButton: {
        backgroundColor: DecidaColors.Green,
        height: 50,
        width: 150,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    buttonText: {
        color: DecidaColors.White

    },
    emailNote: {
        width: 330,
        color: DecidaColors.Black,
        fontSize: 15,
        textAlign: 'center',
    },
    leftAlign: {

    },
    updatePassword: {
        marginTop: 50,
    },
    pencil: {
        position: 'absolute',
        right: 0,
        top: -15,

    }


})