import * as React from "react"
import Svg, { G, Path, Circle, SvgProps } from "react-native-svg"

function IconAbsence(props: SvgProps) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={29}
            height={31.351}
            viewBox="0 0 29 31.351"
            {...props}
        >
            <G data-name="Group 18124" transform="translate(-352 -743)">
                <G fill="#707070">
                    <Path
                        data-name="Path 6052"
                        d="M43.321 17.23a8.07 8.07 0 11-8.07-8.069 8.07 8.07 0 018.07 8.069"
                        transform="translate(352 743) translate(-22.643 -9.161)"
                    />
                    <Path
                        data-name="Path 6053"
                        d="M32.533 52.6a9.161 9.161 0 01-11.149 0c-4.166 1.551-7.033 4.784-7.033 8.522 0 5.247 5.645 5 12.607 5s12.607.249 12.607-5c0-3.738-2.867-6.971-7.033-8.522z"
                        transform="translate(352 743) translate(-14.351 -37.241)"
                    />
                </G>
                <Circle
                    data-name="Ellipse 1139"
                    cx={6.5}
                    cy={6.5}
                    r={6.5}
                    transform="translate(368 761.351)"
                    fill="#fff"
                />
                <G data-name="Group 18127">
                    <G
                        data-name="Group 18125"
                        transform="translate(-91.269 366.083) translate(460 396)"
                        fill="none"
                    >
                        <G data-name="Path 6054">
                            <Path d="M5.768 0A5.768 5.768 0 110 5.768 5.768 5.768 0 015.768 0z" />
                            <Path
                                d="M5.768 1.5A4.273 4.273 0 001.5 5.768a4.273 4.273 0 004.268 4.268 4.273 4.273 0 004.268-4.268A4.273 4.273 0 005.768 1.5m0-1.5a5.768 5.768 0 110 11.536A5.768 5.768 0 015.768 0z"
                                fill="#707070"
                            />
                        </G>
                        <Path
                            data-name="Line 3071"
                            transform="translate(2.355 5.719)"
                            stroke="#707070"
                            strokeWidth={1.5}
                            d="M0 0L6.728 0"
                        />
                    </G>
                </G>
            </G>
        </Svg>
    )
}

export default IconAbsence
