import { StyleSheet, View } from 'react-native'
import { UniversalImage } from '../../../common/universal-image'

const SvgImage = require('./spelling-elimination-lesson-plan.png')

export const SpellingEliminationLessonPlan = () => (
    <View style={styles.container}>
        <UniversalImage SvgImage={SvgImage} />
    </View>
)

const styles = StyleSheet.create({
    container: {
        paddingRight: 10,
        height: '100%',
        width: '100%'
    }
})