import { View } from 'react-native'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { CardTemplate, CardType } from '../../common/card-template'
import { CardColorScheme } from '../../common/card-color-scheme'
import { PhysiologyOfEmotionIcon } from '../icons/physiology-of-emotion-icon'
import { PhysiologyOfEmotionImage1 } from '../images/physiology-of-emotion-image1'
import { Dimensions } from 'react-native';
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { PhysiologyOfEmotionIconCard } from '../icons/physiology-of-emotion-icon-card'

const windowWidth = Dimensions.get('window').width;


export const ToolsPhysiologyOfEmotion: CardTemplate = {
    cardId: "fdf167bf-2d30-4aef-93c3-c645793deb94",
    title: 'Physiology of emotion',
    Header: PhysiologyOfEmotionIcon,
    Icon: PhysiologyOfEmotionIconCard,
    HeaderTitle: 'Physiology of emotion',
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsBold textdata={""} />
            <View style={commonCardStyles.container}>
                <View style={commonCardStyles.imagecontainer}>
                    <View style={windowWidth < 600 ? { width: 1000, height: 1000, } : { width: 1000, height: 2000, }}>
                        <PhysiologyOfEmotionImage1 />
                    </View>
                </View>
            </View>
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>

        </>
    ),
    colorScheme: CardColorScheme.TOOL,
    whyDoesItWorkText: '',
    type: [CardType.Tool],
    time: [],
    people: [],
    place: [],
    category: [],
    action: [],
    level: []
}

