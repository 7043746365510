import { ColorValue } from 'react-native'
import Svg, { G, Path } from "react-native-svg"

const SvgImage = require('./switch-filter-icon.svg')

type Props = {
    color?: ColorValue
}

export const SwitchFilterIcon = ({ color }: Props) => {

    return <Svg
        viewBox="0 0 14.727 14.75"
    >
        <G fill={color || "#707070"}>
            <Path
                data-name="Path 6099"
                d="M9.921 11.974h1.63a2.094 2.094 0 004.039 0h7.925a.566.566 0 000-1.133h-7.924a2.094 2.094 0 00-4.039 0H9.921a.566.566 0 100 1.133zm3.65-1.54a.974.974 0 11-.974.974.976.976 0 01.974-.974z"
                transform="translate(-9.355 -9.301)"
            />
            <Path
                data-name="Path 6100"
                d="M9.921 41.048h7.925a2.094 2.094 0 004.038 0h1.631a.566.566 0 100-1.133h-1.63a2.094 2.094 0 00-4.038 0H9.921a.566.566 0 000 1.133zm9.945-1.54a.974.974 0 11-.974.974.975.975 0 01.974-.974z"
                transform="translate(-9.355 -9.301) translate(0 -23.806)"
            />
            <Path
                data-name="Path 6101"
                d="M9.921 70.122h3.14a2.094 2.094 0 004.039 0h6.414a.566.566 0 100-1.133H17.1a2.094 2.094 0 00-4.039 0h-3.14a.566.566 0 100 1.133zm5.16-1.54a.974.974 0 11-.974.974.976.976 0 01.975-.974z"
                transform="translate(-9.355 -9.301) translate(0 -47.611)"
            />
        </G>
    </Svg>
}
