import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan, Line } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconHappy7Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 7)
    }

    const onPressLevel2 = {
        [prop]: () => onPress(2 / 7)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(3 / 7)
    }

    const onPressLevel4 = {
        [prop]: () => onPress(4 / 7)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(5 / 7)
    }

    const onPressLevel6 = {
        [prop]: () => onPress(6 / 7)
    }

    const onPressLevel7 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg
            viewBox="0 0 277.719 152.329"
        >
            <G data-name="Group 9557">
                <G data-name="Group Peaceful" {...onPressLevel1}>
                    <G data-name="Group 8947">
                        <Path
                            data-name="Path 7646"
                            d="M0 0l52.6-.176.044-28.007z"
                            fill="#dfeae1"
                            transform="translate(-955 -110.513) translate(956.296 262.717)"
                        />
                    </G>
                    <G data-name="Group 8948">
                        <Path
                            data-name="Path 7647"
                            d="M0 0l52.641-28.183L52.6-.176z"
                            fill="none"
                            stroke="#657170"
                            strokeWidth={0.25}
                            transform="translate(-955 -110.513) translate(956.296 262.717)"
                        />
                    </G>
                    <G data-name="Group 8982">
                        <Path
                            data-name="Path 7681"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h26.3a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0026.3 0z"
                            fill="#fff"
                            transform="translate(-955 -110.513) translate(978.498 252.447)"
                        />
                    </G>
                    <G data-name="Group 8983">
                        <Path
                            data-name="Path 7682"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h26.3a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0026.3 0z"
                            fill="none"
                            stroke="#2a7339"
                            strokeWidth={0.25}
                            transform="translate(-955 -110.513) translate(978.498 252.447)"
                        />
                    </G>
                    <Text
                        transform="translate(-955 -110.513) translate(978.003 259.377)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                        letterSpacing="-0.003em"
                    >
                        <TSpan x={0} y={0}>
                            {"Peaceful"}
                        </TSpan>
                    </Text>
                    <G data-name="Group 8988">
                        <Path
                            data-name="Path 7687"
                            d="M0 0l47.913-25.76"
                            fill="none"
                            stroke="#6f7175"
                            strokeWidth={1}
                            transform="translate(-955 -110.513) translate(955.236 259.772)"
                        />
                    </G>
                    <G data-name="Group 8990">
                        <Path
                            data-name="Path 7689"
                            d="M0 0l-7.385 1.073 2.417 4.495z"
                            fill="#6f7175"
                            transform="translate(-955 -110.513) translate(1007.961 231.426)"
                        />
                    </G>
                </G>
                <G data-name="Group Pleased" {...onPressLevel2}>
                    <G data-name="Group 8950">
                        <Path
                            data-name="Path 7649"
                            d="M0 0l-.111 29.327 34.819-.022-.125-47.734z"
                            fill="#bed4c3"
                            transform="translate(-955 -110.513) translate(1011.505 233.174)"
                        />
                    </G>
                    <G data-name="Group 8951">
                        <Path
                            data-name="Path 7650"
                            d="M0 0l34.583-18.429.125 47.734-34.819.022z"
                            fill="none"
                            stroke="#657170"
                            strokeWidth={0.25}
                            transform="translate(-955 -110.513) translate(1011.505 233.175)"
                        />
                    </G>
                    <G data-name="Group 8967">
                        <Path
                            data-name="Path 7666"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h22.514a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0022.514 0z"
                            fill="#fff"
                            transform="translate(-955 -110.513) translate(1017.546 239.63)"
                        />
                    </G>
                    <G data-name="Group 8968">
                        <Path
                            data-name="Path 7667"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h22.514a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0022.514 0z"
                            fill="none"
                            stroke="#2a7339"
                            strokeWidth={0.25}
                            transform="translate(-955 -110.513) translate(1017.546 239.63)"
                        />
                    </G>
                    <Text
                        transform="translate(-955 -110.513) translate(1016.196 247.263)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Pleased"}
                        </TSpan>
                    </Text>
                    <G data-name="Group 8991">
                        <Path
                            data-name="Path 7690"
                            d="M0 0l30.053-16.126"
                            fill="none"
                            stroke="#6f7175"
                            strokeWidth={1}
                            transform="translate(-955 -110.513) translate(1010.229 230.292)"
                        />
                    </G>
                    <G data-name="Group 8992">
                        <Path
                            data-name="Path 7691"
                            d="M0 0l-7.385 1.066 2.413 4.5z"
                            fill="#6f7175"
                            transform="translate(-955 -110.513) translate(1045.095 211.584)"
                        />
                    </G>
                </G>
                <G data-name="Group Optimistic" {...onPressLevel3}>
                    <G data-name="Group 8953">
                        <Path
                            data-name="Path 7652"
                            d="M0 0l.177 49.336 34.429-.022.043-68.237z"
                            fill="#9fc0a6"
                            transform="translate(-955 -110.513) translate(1048.84 213.268)"
                        />
                    </G>
                    <G data-name="Group 8954">
                        <Path
                            data-name="Path 7653"
                            d="M0 0l34.649-18.923-.043 68.236-34.428.023z"
                            fill="none"
                            stroke="#657170"
                            strokeWidth={0.25}
                            transform="translate(-955 -110.513) translate(1048.841 213.268)"
                        />
                    </G>
                    <G data-name="Group 8971">
                        <Path
                            data-name="Path 7670"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h28.493a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0028.493 0z"
                            fill="none"
                            stroke="#2a7339"
                            strokeWidth={0.25}
                            transform="translate(-955 -110.513) translate(1051.89 226.822)"
                        />
                    </G>
                    <G data-name="Group 8993">
                        <Path
                            data-name="Path 7692"
                            d="M0 0l30.307-16.534"
                            fill="none"
                            stroke="#6f7175"
                            strokeWidth={1}
                            transform="translate(-955 -110.513) translate(1047.646 210.45)"
                        />
                    </G>
                    <G data-name="Group 8994">
                        <Path
                            data-name="Path 7693"
                            d="M0 0l-7.378 1.118L-4.934 5.6z"
                            fill="#6f7175"
                            transform="translate(-955 -110.513) translate(1082.749 191.301)"
                        />
                    </G>
                    <G data-name="Group 8970">
                        <Path
                            data-name="Path 7669"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h28.493a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0028.493 0z"
                            fill="#fff"
                            transform="translate(-955 -110.513) translate(1051.89 226.822)"
                        />
                    </G>
                    <Text
                        transform="translate(-955 -110.513) translate(1049.712 234.175)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Optimistic"}
                        </TSpan>
                    </Text>
                </G>
                <G data-name="Group Cheerful" {...onPressLevel4}>
                    <Path
                        data-name="Path 7655"
                        d="M0 0l.051 69.611 34.819-.022-.082-87.9z"
                        transform="translate(-955 -110.513) translate(1085.896 192.992)"
                        fill="#7fab88"
                    />
                    <Path
                        data-name="Path 7685"
                        d="M1090.836 214.016a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h24.99a2.834 2.834 0 002.834-2.834v-3.631a2.835 2.835 0 00-2.834-2.835z"
                        fill="none"
                        stroke="#2a7339"
                        strokeWidth={0.25}
                        transform="translate(-955 -110.513)"
                    />
                    <Path
                        data-name="Path 7694"
                        d="M1084.496 190.04l30.307-16.533"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-955 -110.513)"
                    />
                    <Path
                        data-name="Path 7695"
                        d="M0 0l-7.378 1.117L-4.934 5.6z"
                        transform="translate(-955 -110.513) translate(1119.6 170.892)"
                        fill="#6f7175"
                    />
                    <Path
                        data-name="Path 7684"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h24.99a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0024.99 0z"
                        transform="translate(-955 -110.513) translate(1090.836 214.016)"
                        fill="#fff"
                    />
                    <Text
                        transform="translate(-955 -110.513) translate(1089.526 221.534)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Cheer"}
                        </TSpan>
                    </Text>
                    <Text
                        transform="translate(-955 -110.513) translate(1108.798 221.534)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"ful"}
                        </TSpan>
                    </Text>
                </G>
                <G data-name="Group Delighted" {...onPressLevel5}>
                    <G data-name="Group 8959">
                        <Path
                            data-name="Path 7658"
                            d="M0 0l-.076 89.5 34.428-.022-.082-107.69z"
                            fill="#5f956a"
                            transform="translate(-955 -110.513) translate(1123.374 173.1)"
                        />
                    </G>
                    <G data-name="Group 8976">
                        <Path
                            data-name="Path 7675"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h33.671a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0033.671 0z"
                            fill="#fff"
                            transform="translate(-955 -110.513) translate(1123.677 201.209)"
                        />
                    </G>
                    <G data-name="Group 8977">
                        <Path
                            data-name="Path 7676"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h33.671a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0033.671 0z"
                            fill="none"
                            stroke="#2a7339"
                            strokeWidth={0.25}
                            transform="translate(-955 -110.513) translate(1123.677 201.209)"
                        />
                    </G>
                    <Text
                        transform="translate(-955 -110.513) translate(1124.818 208.16)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Delighted"}
                        </TSpan>
                    </Text>
                    <G data-name="Group 8997">
                        <Path
                            data-name="Path 7696"
                            d="M0 0l30.307-16.534"
                            fill="none"
                            stroke="#6f7175"
                            strokeWidth={1}
                            transform="translate(-955 -110.513) translate(1121.914 170.892)"
                        />
                    </G>
                    <G data-name="Group 8998">
                        <Path
                            data-name="Path 7697"
                            d="M0 0l-7.378 1.118L-4.934 5.6z"
                            fill="#6f7175"
                            transform="translate(-955 -110.513) translate(1157.017 151.742)"
                        />
                    </G>
                </G>
                <G data-name="Group Ecstatic" {...onPressLevel7}>
                    <Path
                        data-name="Path 7664"
                        d="M0 0l-.076 130.034L34.353 130l-.381-147.859z"
                        transform="translate(-955 -110.513) translate(1198.241 132.569)"
                        fill="#2a7339"
                    />
                    <Path
                        data-name="Path 7672"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h23.676a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0023.676 0z"
                        transform="translate(-955 -110.513) translate(1203.488 175.596)"
                        fill="#fff"
                    />
                    <Text
                        transform="translate(-955 -110.513) translate(1203.105 183.193)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Ecstatic"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7700"
                        d="M1197.032 129.662l30.308-16.533"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-955 -110.513)"
                    />
                    <Path
                        data-name="Path 7701"
                        d="M0 0l-7.377 1.119L-4.934 5.6 0 0z"
                        transform="translate(-955 -110.513) translate(1232.135 110.513)"
                        fill="#6f7175"
                    />
                    <Path
                        data-name="Path 7665"
                        d="M1198.241 132.569l33.973-17.858.379 147.858-34.428.035z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-955 -110.513)"
                    />
                    <Path
                        data-name="Path 7673"
                        d="M1203.488 175.596a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h23.676a2.834 2.834 0 002.835-2.834v-3.631a2.835 2.835 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#2a7339"
                        strokeWidth={0.25}
                        transform="translate(-955 -110.513)"
                    />
                </G>
                <G data-name="Group Senstational" {...onPressLevel6}>
                    <G data-name="Group 8962">
                        <Path
                            data-name="Path 7661"
                            d="M0 0l.05 109.625 34.82-.036-.083-128.582z"
                            fill="#498756"
                            transform="translate(-955 -110.513) translate(1160.393 152.979)"
                        />
                    </G>
                    <G data-name="Group 8999">
                        <Path
                            data-name="Path 7698"
                            d="M0 0l30.307-16.534"
                            fill="none"
                            stroke="#6f7175"
                            strokeWidth={1}
                            transform="translate(-955 -110.513) translate(1159.048 150.072)"
                        />
                    </G>
                    <G data-name="Group 9000">
                        <Path
                            data-name="Path 7699"
                            d="M0 0l-7.378 1.118L-4.934 5.6z"
                            fill="#6f7175"
                            transform="translate(-955 -110.513) translate(1194.15 130.923)"
                        />
                    </G>
                    <G data-name="Group 8963">
                        <Path
                            data-name="Path 7662"
                            d="M0 0l34.788-18.992.082 128.582-34.819.035z"
                            fill="none"
                            stroke="#657170"
                            strokeWidth={0.25}
                            transform="translate(-955 -110.513) translate(1160.393 152.979)"
                        />
                    </G>
                    <G data-name="Group 8980">
                        <Path
                            data-name="Path 7679"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h36.058a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0036.058 0z"
                            fill="none"
                            stroke="#2a7339"
                            strokeWidth={0.25}
                            transform="translate(-955 -110.513) translate(1160.369 188.402)"
                        />
                    </G>
                    <G data-name="Group 8979">
                        <Path
                            data-name="Path 7678"
                            d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h36.058a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0036.058 0z"
                            fill="#fff"
                            transform="translate(-955 -110.513) translate(1160.369 188.402)"
                        />
                    </G>
                    <Text
                        transform="translate(-955 -110.513) translate(1159.652 195.762)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Sensational"}
                        </TSpan>
                    </Text>
                </G>
            </G>
        </Svg>
    )
}