import { gql, useQuery, useReactiveVar } from '@apollo/client'
import _ from 'lodash'
import { useEffect } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { GetSchoolAdminByCognitoUsernameQuery, GetSchoolAdminByCognitoUsernameQueryVariables } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { getSchoolAdminByCognitoUsername } from '../../common/graphql/queries'
import { DefaultText } from '../common/default-text'
import { SchoolIcon } from '../common/svg-files/school-icon'
import { GetSchoolCampusesAndGroupsQueryResponse, getSchoolCampusesAndGroupsQuery } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query'
import { rvCurrentUser } from '../login/login-state'
import SchoolAdminCampusesList from './school-admin-campuses-list'
import SchoolAdminGroupList from './school-admin-group-list'
import { rvSelectedCampusID } from './school-admin-state'
import { SchoolAdminHierarchyCurrentListView } from './school-admin-types'

const groups = [
    { id: SchoolAdminHierarchyCurrentListView.SchoolAdmins, name: "Admin" },
    { id: SchoolAdminHierarchyCurrentListView.Teachers, name: "Classroom teachers" },
    { id: SchoolAdminHierarchyCurrentListView.NonTeachingStaffs, name: "Support staff / other" },
]


const SchoolAdminSidebar = () => {
    const user = useReactiveVar(rvCurrentUser)
    const currentSelectedCampus = useReactiveVar(rvSelectedCampusID)

    const { data: resultSchoolAdmin } = useQuery<GetSchoolAdminByCognitoUsernameQuery, GetSchoolAdminByCognitoUsernameQueryVariables>(gql`${getSchoolAdminByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "", limit: 5000 }, skip: !user })
    const currentSchoolAdmin = resultSchoolAdmin?.getSchoolAdminByCognitoUsername?.items[0]

    const { data: schoolData } = useQuery<GetSchoolCampusesAndGroupsQueryResponse>(getSchoolCampusesAndGroupsQuery, { variables: { id: currentSchoolAdmin?.schoolID }, skip: !currentSchoolAdmin })

    const campuses = _.sortBy(schoolData?.getSchool?.campuses?.items.filter(c => c._deleted !== true), "name") || []

    useEffect(() => {
        if (!currentSelectedCampus && campuses.length) {
            rvSelectedCampusID(campuses[0].id)
        }
    }, [campuses])


    return (
        <View style={{ width: 300, paddingTop: 60, borderRightWidth: 1, borderRightColor: DecidaColors.GrayBorder }}>
            <ScrollView>
                {/* HEADER */}
                <View style={{ flexDirection: 'row', gap: 10, paddingHorizontal: 20 }}>
                    <View style={{ width: 50, height: 50 }}>
                        <SchoolIcon />
                    </View>
                    <DefaultText style={styles.mainText}>{schoolData?.getSchool.name}</DefaultText>
                </View>

                {/* CAMPUS LIST */}
                <SchoolAdminCampusesList campuses={campuses} />

                {/* SCHOOL GROUPS LIST */}
                <SchoolAdminGroupList groups={groups} />
            </ScrollView>
        </View>
    )
}
export default SchoolAdminSidebar
const styles = StyleSheet.create({
    mainText: {
        fontWeight: 'bold',
        color: DecidaColors.AppleSystemGray2Dark,
    }
})
