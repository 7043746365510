import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { StyleSheet, View } from 'react-native'
import { GetClassQuery, GetClassQueryVariables } from '../../common/API'
import { CheckInUserGroup, UserGroup } from '../../common/constants'
import { getClass } from '../../common/graphql/queries'
import { rvNetInfo } from '../common/common-state'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { showOfflineAlert } from '../common/universal-alert'
import { lowRes } from '../common/use-responsive'
import { IconTirednessScale } from '../icon/icon-tiredness-scale'
import { rvUserGroup } from '../login/login-state'
import { CheckInHeader } from './check-in-header'
import { rvCheckInState } from './check-in-state'
import { CheckInImageContainerMaxHeight } from '../common/const'

export const CheckInTirednessScale = ({ navigation: { navigate, goBack }, route: { params } }: StackScreenProps<{ [k: string]: { classID?: string, user?: CheckInUserGroup } | undefined }>) => {
    const checkInState = useReactiveVar(rvCheckInState)
    const netInfoState = useReactiveVar(rvNetInfo)
    const userGroup = useReactiveVar(rvUserGroup)


    const { data: classResponse } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: params?.classID || "" }, skip: !params?.classID })
    const currentClass = classResponse?.getClass


    const back = (patchedGoback: () => void) => {
        if (userGroup === UserGroup.Student) {
            patchedGoback()
            return
        }
        if (params?.user === CheckInUserGroup.Teacher) {
            navigate(ScreenNames.TeacherWellbeing)
        } else if (params?.user === CheckInUserGroup.NonTeachingStaff) {
            patchedGoback()
        } else {
            navigate(ScreenNames.CheckInStart, { classID: params?.classID, user: params?.user })
        }
    }

    const onTirednessPress = async (level: number) => {
        if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()
        rvCheckInState({
            ...checkInState,
            tiredness: level,
        })

        if (currentClass?.toggleEating && params?.user === CheckInUserGroup.Student) {
            navigate(ScreenNames.CheckInEating, { classID: params?.classID, user: params?.user });
            return;
        }

        navigate(ScreenNames.CheckInEmotionalWheel, { classID: params?.classID, user: params?.user });
    }

    return (
        <Page style={lowRes ? styles.pageLowRes : styles.page}>
            <CheckInHeader
                back={back}
                headerText='What is your level of energy?'
                studentId={checkInState.studentID}
            />
            <View style={styles.triednessScale}>
                <IconTirednessScale onPress={onTirednessPress} />
            </View>
        </Page>
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
    },
    pageLowRes: {
        paddingTop: 20
    },
    triednessScale: {
        flex: 1,
        justifyContent: 'center',
        width: '100%',
        padding: 20,
        alignSelf: 'center',
        alignItems: 'center',
        maxHeight: CheckInImageContainerMaxHeight
    },
})