import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import VideoCard from '../../../common/video-card'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { HeadRollHeaderIcon } from './src/head-roll-header-icon'
import { HeadRollIcon } from './src/head-roll-icon'
import { HeadRollLineDrawing } from './src/head-roll-line-drawing'
import { Worksheets } from '../../../common/worksheets-imports'
import SwitchCardVideo from '../../../common/switch-card-video'

const headRollsVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/AImuCtIokl0",
    title: ""
}

export const SwitchesEmotionsAngryHeadRolls: CardTemplate = {
    cardId: "e1f5fd30-0c94-46d7-8fb7-42ac69eb1406",
    title: 'Head rolls',
    Header: HeadRollHeaderIcon,
    Icon: HeadRollIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Releasing built up tension in the head and shoulders."} />
        </>
    ),
    Images: () => (
        <>
            <HeadRollLineDrawing />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Find a place to sit and be still."} />
                <DefaulttextWithRightArrow textdata={"Breathe in as you roll your head slowly in a clockwise direction so that you can feel the stretch in your neck the whole way around. "} />
                <DefaulttextWithRightArrow textdata={"When you return to your starting position change directions and slowly breathe out."} />
                <DefaulttextWithRightArrow textdata={"Complete four rotations "} />
            </View>
        </>
    ),
    BottomRow: () => (
        <>
            <View>
                <DefaulTextWithTipsIcon textdata="[Optional] Download the ‘Anger stretches’ worksheet to access similar stretching techniques." />
            </View>

        </>

    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: `By finding a quiet space and performing deliberate head rotations, individuals engage in gentle movement that can help release tension often associated with anger. The deliberate stretching of the neck enhances bodily awareness and encourages a momentary break from anger triggering thoughts. The clockwise and counter clockwise rotations act as a repetitive, rhythmic pattern that can induce a soothing effect. Through regular practice, this simple physical action can contribute to anger reduction by providing a constructive outlet for pent-up energy and facilitating a sense of relaxation.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Able to define anger and identify its physical and emotional manifestations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Demonstrate the ability to perform the anger relieving stretches with proper form and controlled movements.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 40 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction to Managing Anger: (5 minutes)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by discussing the importance of managing anger constructively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that anger is a natural emotion, but it's essential to find healthy ways to cope with it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Highlight the purpose of the lesson, which is to learn how small stretches can help relieve anger and promote relaxation.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Anger and Its Effects: (10 minutes)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Define anger and discuss how it can manifest in physical and emotional ways. Use the ‘Physiology of emotion’ tool in the tools section of the app to help with this.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share experiences of the impact of anger on their body and thought processes. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that chronic or unmanaged anger can have negative effects on one's mental and physical health, relationships, and overall wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the need for strategies to cope with anger in a healthy manner.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Benefits of Small Stretches for Anger Relief: (5 minutes)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the concept that physical activity, such as stretching, can help alleviate anger by releasing tension and promoting relaxation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain how stretching can improve blood circulation, reduce muscle tension, and calm the nervous system.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction to Anger-Relieving Stretches: (5 minutes)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that students will be learning a series of small stretches specifically designed to relieve anger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students navigate to the Head Rolls Switch or hand out the ‘Anger stretches’ worksheet and encourage students to familiarise themselves with these easy to do stretches. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Highlight the importance of proper posture and controlled, gentle movements during stretching exercises.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice: Anger-Relieving Stretches: (10 minutes)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ [Optional] Play the 15 minute meditation music video in the additional resources or use your own calming music.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead the class through the stretches provided in the ‘Anger stretches’ worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Pair each stretch with the deep, slow breaths exercises noted on the worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to focus on the physical sensations and how the stretches help them release tension.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion: (5 minutes)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to choose one of the stretches from the ‘Anger stretches’ worksheet and practice at home.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points discussed during the lesson, highlighting the effectiveness of small stretches for relieving anger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the idea that regular practice of these stretches can help individuals manage anger constructively and promote relaxation.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard and markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Projector or screen for displaying visuals (optional)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={Worksheets.angerStretchesWorksheet.label} link={Worksheets.angerStretchesWorksheet.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Comfortable seating and open space for students</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Soft background music (calming instrumental music, optional)</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>15 Minute Meditation Music </DefaultText>
                        <SwitchCardVideo video={headRollsVideo} />

                        <DefaultText style={CommonStyles.lessonPlanText}>Stretching for Anger Management</DefaultText>
                        <DefaultTextWithLink text='https://bendablebody.com/stretching-for-anger-management/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Anger - how it affects people</DefaultText>
                        <DefaultTextWithLink text='https://www.betterhealth.vic.gov.au/health/healthyliving/anger-how-it-affects-people' />
                    </>}
            />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Head+rolls.m4a",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Angry]
}

const styles = StyleSheet.create({
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    centered: {
        alignItems: 'center',
        justifyContent: 'center'
    }
})
