import { StyleSheet, View } from 'react-native'
import { DefaultButton } from '../common/default-button'
import { OnBoardingAndi } from '../common/svg-files/onboarding-andi'
import { useResponsive } from '../common/use-responsive'
import { IndividualStudentHowToStep1 } from '../common/svg-files/individual-student-how-to-step1'
import { DefaultText } from '../common/default-text'
import { IndividualStudentHowToStep2 } from '../common/svg-files/individual-student-how-to-step2'
import { IndividualStudentHowToStep3 } from '../common/svg-files/individual-student-how-to-step3'

type Props = {
    handleNext: () => void
    isLastStep?: boolean
}

type DefaultProps = {
    handleNext: () => void
    LeftContent: () => JSX.Element
    RightContent: () => JSX.Element
}

const StudentIndividualHowToStep1 = ({ handleNext }: Props) => <StudentIndividualHowToStepDefault
    handleNext={handleNext}
    LeftContent={() => {
        const { responsiveFontSize } = useResponsive()
        return (
            <View style={styles.imageContainer}>
                <IndividualStudentHowToStep1 />
                <DefaultText style={[styles.stepText, { fontSize: responsiveFontSize() }]}>Each colour corresponds to an emotion. The taller the stack, the higher the emotional arousal.</DefaultText>
            </View>
        )
    }}
    RightContent={() => {
        const { responsiveFontSize } = useResponsive()
        return (
            <DefaultText style={[styles.stepText, { fontSize: responsiveFontSize() }]}>The graph is a representation of your check-in activity over time.</DefaultText>
        )
    }}
/>

const StudentIndividualHowToStep2 = ({ handleNext }: Props) => <StudentIndividualHowToStepDefault
    handleNext={handleNext}
    LeftContent={() => {
        return (
            <View style={styles.imageContainer}>
                <IndividualStudentHowToStep2 />
            </View>
        )
    }}
    RightContent={() => {
        const { responsiveFontSize } = useResponsive()
        return (
            <DefaultText style={[styles.stepText, { fontSize: responsiveFontSize() }]}>This line represents your reported tiredness level across the check-ins.</DefaultText>
        )
    }}
/>

const StudentIndividualHowToStep3 = ({ handleNext, isLastStep }: Props) => {
    const { responsiveFontSize } = useResponsive()
    return (
        <View style={[styles.content, { flexDirection: 'column' }]}>
            <View style={styles.imageContainer}>
                <IndividualStudentHowToStep3 />
            </View>
            <View style={styles.bottomContainer}>
                <View style={styles.step3ButtonContainer}>
                    <DefaultText style={[styles.stepText, { fontSize: responsiveFontSize() }]}>As you progress, the data will be separated into weeks and displayed on your stats dashboard.</DefaultText>
                    <DefaultButton style={styles.nextButton} buttonTextStyle={[styles.nextText, { fontSize: responsiveFontSize(24) }]} onPress={handleNext}>
                        {isLastStep ? "CLOSE" : "NEXT"}
                    </DefaultButton>
                </View>
                <View style={[styles.imageContainer, { flex: 1 }]}>
                    <OnBoardingAndi />
                </View>
            </View>
        </View>
    )
}

const StudentIndividualHowToStepDefault = ({ handleNext, LeftContent, RightContent }: DefaultProps) => {
    const { responsiveFontSize } = useResponsive()
    return (
        <View style={styles.content}>
            <View style={styles.contentContainerLeft}>
                <LeftContent />
                <View style={styles.buttonsContainer}>
                    <DefaultButton style={styles.nextButton} buttonTextStyle={[styles.nextText, { fontSize: responsiveFontSize(24) }]} onPress={handleNext}>
                        {"NEXT"}
                    </DefaultButton>
                </View>
            </View>
            <View style={styles.contentContainer}>
                <RightContent />
                <View style={styles.imageContainer}>
                    <OnBoardingAndi />
                </View>
            </View>
        </View>
    )
}


export {
    StudentIndividualHowToStep1,
    StudentIndividualHowToStep2,
    StudentIndividualHowToStep3
}
const styles = StyleSheet.create({
    bottomContainer: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 20,
        height: 450
    },
    contentContainer: {
        flex: 1,
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-evenly'
    },
    contentContainerLeft: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%'
    },
    buttonsContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 20,
    },
    step3ButtonContainer: {
        flex: 1,
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-between'
    },
    nextButton: {
        width: 150,
        borderRadius: 20,
    },
    nextText: {
        fontSize: 24,
        fontWeight: 'bold'
    },
    imageContainer: {
        width: '100%',
        height: 200,
    },
    stepText: {
        textAlign: 'center',
    }
})