import moment from 'moment';
import { StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';
import { CheckInSession, ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables, Student, StudentCheckIn } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { DefaultSecondaryFontSize } from '../common/const';
import { DefaultText } from '../common/default-text';
import { Stat } from '../common/stat';
import { studentCheckInHasFlag } from '../../common/student-check-in-has-flag';
import { AvatarFlag } from '../icon/avatar-flag';
import { avatars } from '../icon/avatar/avatars';
import IconAvatarAbsence from '../icon/icon-avatar-absence';
import IconAvatarRequestChat from '../icon/icon-avatar-request-chat';
import IconAvatarRequestChatNotYet from '../icon/icon-avatar-request-chat-not-yet';
import { RequestForChatStatus } from '../models';
import { AVATAR_CONTAINER_PADDING, AVATAR_SIZE, AVATAR_WRAPPER_WIDTH } from '../teacher/teacher-consts';
import { StudentClassAndStudentDataFieldsTypes } from '../teacher/teacher-graphql-scripts';
import { gql, useQuery } from '@apollo/client';
import { listStudentCheckInsByCheckInSessionId } from '../../common/graphql/queries';
import { convertStudentCheckInCacheCodeToCacheObject, StudentCheckInCacheObject } from '../../common/student-check-in-cache';

type Props = {
    studentClass: StudentClassAndStudentDataFieldsTypes;
    checkInSessions?: CheckInSession[]
    isGoMode: boolean;
    toggleAvatarPopOverMenu: () => void;
}

const StudentCheckInSummary = ({
    studentClass,
    isGoMode,
    toggleAvatarPopOverMenu,
    checkInSessions
}: Props) => {

    if (!studentClass) return null

    const currentStudent = studentClass.student

    const lastCheckInSession = (checkInSessions && checkInSessions?.length) && checkInSessions[0]

    const { data: studentCheckInResponse } = useQuery<ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables>(gql`${listStudentCheckInsByCheckInSessionId}`, { variables: { checkinsessionID: lastCheckInSession ? lastCheckInSession?.id : "", limit: 5000 }, skip: !lastCheckInSession })
    const currentStudentCheckIn = isGoMode ?
        studentClass.studentCheckIns && studentClass.studentCheckIns.length > 0 && convertStudentCheckInCacheCodeToCacheObject(studentClass.studentCheckIns[studentClass.studentCheckIns.length - 1]) || undefined :
        studentCheckInResponse?.listStudentCheckInsByCheckInSessionId?.items.find((studentCheckIn) => studentCheckIn?.studentID === studentClass.studentID)

    const currentStudentCheckInSessions = isGoMode ? checkInSessions?.find(checkInSession => checkInSession?.checkInSessionClassSequenceNo === (currentStudentCheckIn as StudentCheckInCacheObject)?.checkInSessionClassSequenceNo) : undefined

    const currentStudentCheckInSessionDate = currentStudentCheckInSessions?.createdAt && new Date(currentStudentCheckInSessions.createdAt)

    const Avatar = currentStudent ?
        avatars[currentStudent?.avatar as keyof typeof avatars] :
        avatars["Default"]

    return (
        <TouchableOpacity
            style={styles.avatarContainer}
            onPress={toggleAvatarPopOverMenu}
        >
            {currentStudentCheckIn?.absence ? (
                <View style={styles.avatarAbsence}>
                    <IconAvatarAbsence />
                </View>
            ) : studentCheckInHasFlag(currentStudentCheckIn as StudentCheckIn) ? (
                <View style={styles.avatarflag}>
                    <AvatarFlag height={iconSize} width={iconSize} />
                </View>
            ) : null}
            <View style={styles.avatarAndStatContainer}>
                <View style={styles.avatar}>
                    <Avatar />
                    <DefaultText style={styles.nickname}>
                        {currentStudent?.nickname}
                    </DefaultText>
                    <View>
                        <DefaultText style={styles.name}>{`${currentStudent?.firstName || ""} ${currentStudent?.lastName || ""}`}</DefaultText>
                        {isGoMode && currentStudentCheckInSessionDate && (
                            <>
                                <DefaultText style={styles.date}>{moment(currentStudentCheckInSessionDate).format("ddd")}</DefaultText>
                                <DefaultText style={styles.date}>{moment(currentStudentCheckInSessionDate).format("DD/MM/YY")}</DefaultText>
                            </>
                        )}
                    </View>
                </View>
                <View>
                    {
                        !currentStudentCheckIn?.absence ? (
                            <Stat
                                classID={studentClass?.classID}
                                emotion={currentStudentCheckIn?.emotion!}
                                intensityPercentage={currentStudentCheckIn?.emotionIntensityPercentage!}
                                tiredness={currentStudentCheckIn?.tiredness!}
                                containerStyle={{ justifyContent: 'space-between', paddingBottom: 0 }}
                                eating={currentStudentCheckIn?.eating!}
                            />
                        ) : (
                            <Stat />
                        )
                    }
                </View>
            </View>
            {currentStudentCheckIn?.requestForChat === RequestForChatStatus.YES && currentStudentCheckIn?.chatCleared !== true && (
                <View style={styles.avatarRequestChat}>
                    <IconAvatarRequestChat width={iconSize} height={iconSize} />
                </View>
            )}
            {currentStudentCheckIn?.requestForChat === RequestForChatStatus.NOT_YET && currentStudentCheckIn?.chatCleared !== true && (
                <View style={styles.avatarRequestChat}>
                    <IconAvatarRequestChatNotYet width={iconSize} height={iconSize} />
                </View>
            )}
        </TouchableOpacity>
    );
};

export default StudentCheckInSummary;

const iconSize = 25

const styles = StyleSheet.create({
    popoverContainer: {
        backgroundColor: DecidaColors.White,
        width: 150,
        position: 'absolute',
        borderWidth: 0.5,
        borderColor: DecidaColors.Gray,
        padding: 10,
        left: 30,
        borderRadius: 5,
        zIndex: 99,
        top: AVATAR_SIZE,
        gap: 10
    },
    popoverText: {
        fontSize: 14,
    },
    avatarContainer: {
        marginTop: AVATAR_CONTAINER_PADDING,
        paddingHorizontal: AVATAR_CONTAINER_PADDING,
        width: AVATAR_WRAPPER_WIDTH,
        marginBottom: 30,
    },
    avatarAndStatContainer: {
        flexDirection: "row",
        width: '100%'
    },
    avatar: {
        marginTop: 5,
        width: AVATAR_SIZE,
        height: AVATAR_SIZE,
        justifyContent: "flex-start",
        alignItems: "center",
    },
    avatarflag: {
        position: "absolute",
        right: 30,
        top: 0,
        zIndex: 1,
    },
    avatarAbsence: {
        position: 'absolute',
        right: 15,
        bottom: 30
    },
    avatarRequestChat: {
        position: 'absolute',
        left: 10,
        top: 0
    },
    nickname: {
        width: AVATAR_WRAPPER_WIDTH - AVATAR_CONTAINER_PADDING,
        textAlign: "center",
        fontSize: 15,
        marginTop: 5
    } as TextStyle,
    name: {
        color: DecidaColors.Gray,
        fontSize: DefaultSecondaryFontSize - 2,
        textAlign: 'center',
    },
    date: {
        textAlign: "center",
        fontSize: 12,
    }
});