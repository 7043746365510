import { StyleSheet, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { FriendFinderHeaderIcon } from './src/friend-finder-header-icon'
import { FriendFinderIcon } from './src/friend-finder-icon'
import { FriendFinderImage1 } from './src/friend-finder-image1'
import { FriendFinderImage2 } from './src/friend-finder-image2'
import { FriendFinderImage3 } from './src/friend-finder-image3'
import { FriendFinderImage4 } from './src/friend-finder-image4'
import { FriendFinderImage5 } from './src/friend-finder-image5'
import { FriendFinderImage6 } from './src/friend-finder-image6'
import VideoCard from '../../../common/video-card'
import { Videos } from '../../../models'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { Worksheets } from '../../../common/worksheets-imports'


const friendFinderVideo1: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/Q0F3j-sAg_U",
    title: "",
}

export const SwitchesEmotionsHappyFriendFinder: CardTemplate = {
    cardId: "5c01433d-4092-4ff9-b4c7-2b39f2cf31ca",
    title: 'Friend finder',
    Header: FriendFinderHeaderIcon,
    Icon: FriendFinderIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"General social situations where the goal is making new connections."} />

            <DefaulttextWithRightArrow textdata={"Smile and be friendly:"} />
            <DefaultText style={styles.subText}>◦ When you see someone new, give them a big smile.</DefaultText>
            <DefaultText style={styles.subText}>◦ Say "hello" and use a friendly voice.'</DefaultText>
            <View style={styles.imageContainer}>
                <FriendFinderImage1 />
            </View>

            <DefaulttextWithRightArrow textdata={"Share and Play Together:"} />
            <DefaultText style={styles.subText}>◦ Ask the new friend if they want to play with you.</DefaultText>
            <DefaultText style={styles.subText}>◦ Share your toys and take turns playing games.</DefaultText>
            <View style={styles.imageContainer}>
                <FriendFinderImage2 />
            </View>

            <DefaulttextWithRightArrow textdata={"Use kind words: "} />
            <DefaultText style={styles.subText}>◦ Talk nicely to your new friend.</DefaultText>
            <DefaultText style={styles.subText}>◦ Say things like "Please" and "Thank you." </DefaultText>
            <View style={styles.imageContainer}>
                <FriendFinderImage3 />
            </View>

            <DefaulttextWithRightArrow textdata={"Listen and be a good friend:"} />
            <DefaultText style={styles.subText}>◦ Pay attention when your new friend talks.</DefaultText>
            <DefaultText style={styles.subText}>◦ Listen carefully and show interest in what they say.</DefaultText>
            <View style={styles.imageContainer}>
                <FriendFinderImage4 />
            </View>

            <DefaulttextWithRightArrow textdata={"Help and be kind:"} />
            <DefaultText style={styles.subText}>◦ Offer to help your new friend if they need it.</DefaultText>
            <DefaultText style={styles.subText}>◦ Be kind and caring to make them feel happy.</DefaultText>
            <View style={styles.imageContainer}>
                <FriendFinderImage5 />
            </View>

            <DefaulttextWithRightArrow textdata={"Include Others:"} />
            <DefaultText style={styles.subText}>◦ If you see someone who looks lonely, invite them to join your play.</DefaultText>
            <DefaultText style={styles.subText}>◦ Include everyone and make them feel welcome.</DefaultText>
            <View style={styles.imageContainer}>
                <FriendFinderImage5 />
            </View>

            <DefaultTextWithoutArrows textdata={"Remember, making new friends takes time, and it's okay if it doesn't happen right away. Keep trying, be patient, and always be yourself!"} />
            <DefaultTextWithoutArrows textdata={"Have fun!"} />
            <View style={styles.imageContainer}>
                <FriendFinderImage6 />
            </View>

        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    cardContentTemplate: 'column',
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: "Making friends is beneficial for emotional intelligence and mental health because it allows individuals to develop and practice important social and emotional skills. Friends provide a supportive and caring network, offering opportunities for empathy, understanding, and emotional connection. Through friendships, individuals learn to navigate various emotions, develop effective communication and conflict resolution skills, and gain a sense of belonging and self-worth. Friendships also promote positive mental health by reducing feelings of loneliness, providing a sense of purpose and meaning, and fostering overall wellbeing.",
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Develop social skills and strategies for making new friends.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the children begin by discussing the importance of friendship.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask questions to engage the children, such as "What does it mean to be a good friend?" or "How does having friends make us feel?"</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show visuals or use picture books about friendship to initiate a conversation and capture their attention.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Friendship Discussion (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the children to share examples of what they think makes a good friend.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write down their ideas on a chart or whiteboard for everyone to see.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a discussion around their responses, highlighting kindness, sharing, and being a good listener as important qualities in a friend.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Role-Play and Storytelling (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide the children into small groups.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide them with scenarios (refer to the ‘Friend finder’ worksheet) related to making friends (e.g., sharing toys, inviting someone to play) and ask them to act out the situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Sharing a toy: Encourage the child to share their toys with another child in the playground or in a classroom setting. They can invite the other child to play with their toys and take turns playing together.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Joining a game: Encourage the child to approach a group of children engaged in a game or activity and ask if they can join in. They can introduce themselves and express their interest in participating.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Offering help: Teach the child to offer help to a peer who may be struggling with a task or activity. They can approach the child and kindly offer assistance, such as helping them build a block tower or tie their shoelaces.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Saying kind words: Encourage the child to compliment their peers and say kind words. They can express appreciation for something their friend did or comment on something they like about their friend's outfit or drawing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Quality time: Encourage the child to find another student they want to get to know more and spend more time with that person. At lunchtime, or outside of school, like a play date.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Asking questions: Teach the child to ask other children about their interests. They can approach a peer and ask questions such as, "What is your favourite toy?" or "What do you like to do for fun?" This can lead to shared interests and common ground for building a friendship.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to use the discussion points from earlier and showcase how they can be good friends.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After role playing, gather the groups together and ask them to share their experiences, reinforcing positive behaviours.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Drawing Friendship (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give each child a piece of paper and drawing materials.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to draw a picture of themselves with a new friend.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allow them to express their creativity and encourage them to include actions or expressions that show friendship.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After they finish drawing, ask a few children to share and describe their artwork, emphasising the importance of being kind and inclusive.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the lesson by revisiting the chart or whiteboard with the qualities of a good friend.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the children to practice these qualities in their everyday interactions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude by reminding them that making new friends takes time and patience, but with kindness and being themselves, they will create wonderful friendships.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Picture books about friendship (optional)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Drawing materials</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard/flipchart and markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Friend finder worksheet' link={Worksheets.friendFinder.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Children’s friendships -</DefaultText>
                        <DefaultTextWithLink text='https://www.bps.org.uk/psychologist/childrens-friendships' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to make friends | CBC Kids -</DefaultText>
                        <DefaultTextWithLink text='https://youtu.be/Q0F3j-sAg_U' />
                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={friendFinderVideo1} />
                        </View>
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Friend+finder+voice+over.m4a",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Happy]
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        alignSelf: 'center',
        marginVertical: 10
    },
    subText: {
        paddingLeft: 20
    }
})
