import { StyleSheet, View } from 'react-native'
import VideoCard from '../../../../common/video-card'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import DefaultTextWithCustomLink from '../../../common/default-text-with-custom-link'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { NoteForTeachersIcon } from '../../icons/note-for-teachers-icon'
import { NoteForTeachersIconInverted } from '../../icons/note-for-teachers-icon-inverted'
import { DecidaColors } from '../../../../../common/decida-colors'


export const SwitchesActivitiesSeparationAxietyForTeachers: CardTemplate = {
    cardId: "65826195-d742-4475-a7bf-fc32957cf245",
    title: 'Notes about separation anxiety',
    Header: NoteForTeachersIconInverted,
    Icon: NoteForTeachersIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Intro note:"} />
            <DefaultTextWithoutArrowsItallic textdata={"One of the key things about separation anxiety disorder (SAD) or home-sickness is the recognition of the fear emotion of felling scared. They know they are safe with their primary caregiver around, and in the absence of that safe person their survival instinct triggers a threat response, deeming the environment to be unsafe without that person. Most of the techniques used are essentially about transferring those safe thoughts to something else (either real or imagined) that can travel with them until their experiences teach them that their new space is also safe, and that they can rely on themselves and trusted adults to keep them safe."} />
            <DefaultTextWithoutArrowsItallic textdata={"Other techniques focus on doing things that make the individual laugh or feel good, as SAD often is as a result of an imbalance of norepinephrine and serotonin in the brain. Doing things like random acts of kindness and laughing can release serotonin and dopamine in the brain, helping bring them back in control. This doesn’t work for everyone, but when it does it is very effective. "} />
            <DefaultTextWithoutArrowsItallic textdata={"Sometimes individuals just need time to grieve the loss of their connection to their primary caregiver. In this case (where the underlying emotion is sadness as opposed to fear) the child may just need some time, and switches like “face it” will help them be brave to override their feelings of grief. "} />
            <DefaultTextWithoutArrowsItallic textdata={"It is important to note that if you make them feel bad about feeling homesick or having separation anxiety their brain could recognise you as a safety threat and escalate the situation. Show concern about, and focus on the thing they are scared about (e.g. being away from mum) so you can solve the problem from a place of shared understanding. They need to know that your goal is to get them back to their primary caregiver at the end of the day feeling safe and peaceful, and that they will feel as safe at school as they do around their primary caregiver. "} />
            <DefaultTextWithoutArrowsItallic textdata={"Below are some strategies and ideas you might find useful in the moment you are dealing with kids whose fear response is being triggered. "} />
            <DefaultTextWithoutArrowsItallic textdata={"Hope this helps – be safe! "} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <DefaultTextWithoutArrows align='left' textdata={"Phil Slade "} />
            <DefaultTextWithoutArrows align='left' textdata={"Psychologist and Co-founder of Switch4Schools"} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />

            <View style={styles.horizontalLine} />

            <DefaultTextWithoutArrowsBold textdata='Relevant Other Switches to explore (outside of those in the separation anxiety section ' />
            <DefaultTextWithoutArrows align='left' textdata={"Face it - Where the thing they are scared of is being away from the safety of their primary caregiver"} />
            <DefaultTextWithoutArrows align='left' textdata={"My Safe Place – An imagination exercise where they create a safe space in their mind that they can access whenever they are feeling scared. "} />
            <DefaultTextWithoutArrows align='left' textdata={"Breath Float – A simple breathing exercise kids can use when they feel the separation fear starting to rise inside them and clam their mind before it flips out of control completely "} />

            <View style={styles.horizontalLine} />

            <DefaultTextWithoutArrowsBold textdata='Relevant Other Switches to explore (outside of those in the separation anxiety section ' />
            <DefaultTextWithoutArrows align='left' textdata='Articles' />

            <DefaultTextWithoutArrows align='left' textdata='12 Important Strategies for Schools to Help Children with Separation Anxiety' />
            <DefaultTextWithCustomLink indent={0} text='https://educationandbehavior.com/strategies-schools-help-children-separation-anxiety/' link='https://educationandbehavior.com/strategies-schools-help-children-separation-anxiety/' />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <DefaultTextWithoutArrows align='left' textdata='Separation Anxiety Tips for Classroom Teachers' />
            <DefaultTextWithCustomLink indent={0} text='https://www.teachstarter.com/au/blog/separation-anxiety-tips-teachers/ ' link='https://www.teachstarter.com/au/blog/separation-anxiety-tips-teachers/' />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <DefaultTextWithoutArrows align='left' textdata='How To Support Students Who Struggle with Separation Anxiety' />
            <DefaultTextWithCustomLink indent={0} text='https://www.teachstarter.com/au/blog/separation-anxiety-tips-teachers/ ' link='https://www.teachstarter.com/au/blog/separation-anxiety-tips-teachers/' />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />

            <DefaultTextWithoutArrows align='left' textdata='Videos' />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <DefaultTextWithoutArrows align='left' textdata='The worry dragon – MH Literacy' />
            <DefaultTextWithCustomLink indent={0} text='Tom has Separation Anxiety Disorder' link='https://www.youtube.com/watch?v=jEkFp0Ux4OQ&ab_channel=MHLiteracy' />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <View style={styles.imageContainer}>
                <VideoCard video={{
                    id: '1',
                    url: 'https://www.youtube.com/embed/jEkFp0Ux4OQ'
                }} />
            </View>
            <DefaultTextWithoutArrows align='left' textdata={"This video doesn't deal with separation anxiety specifically but it deals with the emotions surrounding it "} />
            <DefaultTextWithCustomLink indent={0} text='You are not your thoughts' link='https://www.youtube.com/watch?v=0QXmmP4psbA&ab_channel=AboutKidsHealth' />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <View style={styles.imageContainer}>
                <VideoCard video={{
                    id: '2',
                    url: 'https://www.youtube.com/embed/0QXmmP4psbA'
                }} />
            </View>
            <DefaultTextWithoutArrows align='left' textdata={"Same with this video (doesn't deal with separation anxiety specifically but it deals with the emotions surrounding it)"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <DefaultTextWithCustomLink indent={0} text='Anxiety Explained - SEL Sketches' link='https://www.youtube.com/watch?v=16EoZOsCJgs&ab_channel=SELSketches' />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <View style={styles.imageContainer}>
                <VideoCard video={{
                    id: '3',
                    url: 'https://www.youtube.com/embed/16EoZOsCJgs'
                }} />
            </View>
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.SEPARATION_ANXIETY,
    whyDoesItWorkText: "",
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.SEPARATION_ANXIETY],
    action: [CardActionType.Thought],
    level: []
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        minHeight: 100,
        marginVertical: 20,
        alignItems: 'center'
    },
    horizontalLine: {
        height: 1,
        backgroundColor: DecidaColors.Black,
        width: '100%',
        marginBottom: 20
    }
})

