import { isDefined } from "../../../common/is-defined"

export const listSchoolHierarchyQueryString = `
  query listSchoolHierarchy(
    $schoolID: ID!
  ) {
    listCampusesBySchoolID(schoolID: $schoolID) {
      items {
        id
        name
        classGroups {
          items {
            id
            name
            classes {
              items {
                id
                name
                teachers {
                  items {
                    teacherID
                  }
                }
                students {
                  items {
                    studentID
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export type listSchoolHierarchyQuery = {
  listCampusesBySchoolID?: {
    __typename: "ModelCampusConnection",
    items: Array<{
      __typename: "Campus",
      id: string,
      name: string,
      classGroups: {
        __typename: "ModelClassGroupConnection",
        items: Array<{
          __typename: "ClassGroup",
          id: string,
          name: string,
          classes: {
            __typename: "ModelClassConnection",
            items: Array<{
              __typename: "Class",
              id: string,
              name: string,
              teachers: {
                __typename: "ModelTeacherClassConnection",
                items: Array<{
                  __typename: "TeacherClass",
                  teacherID: string,
                } | null>,
              },
              students: {
                __typename: "ModelStudentClassConnection",
                items: Array<{
                  __typename: "StudentClass",
                  studentID: string
                } | null>,
              },
            } | null>,
          },
        } | null>,
      }
    } | null>,
  } | null,
}

export type listSchoolHierarchyQueryVariables = {
  schoolID: string
}

export type SchoolHierarchy = {
  [campus: string]: SchoolHierarchyCampus
}

export type SchoolHierarchyCampus = {
  id: string,
  name: string,
  classGroups: SchoolHierarchyClassGroups
}

export type SchoolHierarchyClassGroups = {
  [classGroup: string]: SchoolHierarchyClassGroup
}

export type SchoolHierarchyClassGroup = {
  id: string,
  name: string,
  classes: SchoolHierarchyClasses
}

export type SchoolHierarchyClasses = {
  [className: string]: SchoolHierarchyClass
}

export type SchoolHierarchyClass = {
  id: string,
  name: string,
  teachers: string[],
  students: string[],
}

export const convertListSchoolHierarchyToSchoolHierarchy = (listSchoolHierarchy: listSchoolHierarchyQuery | undefined) =>
    listSchoolHierarchy?.listCampusesBySchoolID?.items.filter(isDefined).reduce((campuses, campus) => {
      campuses[campus.name] = {
        id: campus.id,
        name: campus.name,
        classGroups: campus.classGroups.items.filter(isDefined).reduce((classGroups, classGroup) => {
          classGroups[classGroup.name] = {
            id: classGroup.id,
            name: classGroup.name,
            classes: classGroup.classes.items.filter(isDefined).reduce((classes, clazz) => {
              classes[clazz.name] = {
                id: clazz.id,
                name: clazz.name,
                teachers: clazz.teachers.items.filter(isDefined).map(t => t.teacherID),
                students: clazz.students.items.filter(isDefined).map(s => s.studentID)
              }

              return classes
            }, {} as SchoolHierarchyClasses) || {}
          }
          return classGroups
        }, {} as SchoolHierarchyClassGroups) || {}
      }
      return campuses
    }, {} as SchoolHierarchy) || {}
