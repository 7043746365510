import { useReactiveVar } from '@apollo/client'
import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack'
import { API } from 'aws-amplify'
import { useState, useEffect } from 'react'
import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'
import { getApiHeaders } from '../common/get-api-header'
import { createCurrentStudentClass, getStudentClassesByClassIdAndStudentId } from '../common/helper-api-functions'
import { Loading, rvIsLoading } from '../common/loading'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { avatars } from '../icon/avatar/avatars'
import { type StudentClass, StudentClassApprovalStatus } from '../../common/API'
import { StudentMainNavigatorParamList } from '../student/student-route-param-types'
import { getCurrentStudent, rvCurrentStudent, setCurrentClassIdStudent } from '../student/use-student-state'
import { StudentJoinClassStackNavigatorParamList } from './login-route-param-types'
import { rvCurrentUser } from './login-state'

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600

export const StudentJoinClassNewExistingStudent = ({ route: { params: { bulkSignUpId } }, }: StackScreenProps<StudentJoinClassStackNavigatorParamList, 'studentJoinClassNewExistingStudent'>) => {

    const [className, setClassName] = useState("No Class")
    const [classID, setClassID] = useState("")
    const [currentStudentClass, setCurrentStudentClass] = useState(null as StudentClass | undefined | null)
    const [isLoading, setIsLoading] = useState(true)
    const user = useReactiveVar(rvCurrentUser)

    const currentStudent = useReactiveVar(rvCurrentStudent)

    useEffect(() => {
        getCurrentStudent(user?.username || "")
    }, [user])

    useEffect(() => {
        if (currentStudent != null && classID !== '') {
            getStudentClassesByClassIdAndStudentId(currentStudent.id, classID)
                .then(results => {
                    const studentClass = results[0]

                    if (studentClass) {
                        navigate(ScreenNames.StudentHome)
                    } else {
                        setCurrentStudentClass(studentClass)
                        setIsLoading(false)
                    }
                })
        }
    }, [currentStudent, classID])

    const { navigate } = useNavigation<StackNavigationProp<StudentMainNavigatorParamList>>()

    const joinClass = async () => {
            const studentClass: Partial<StudentClass> = {
                studentID: currentStudent?.id || '',
                classID: classID,
                status: StudentClassApprovalStatus.PENDING
            }
            await createCurrentStudentClass(studentClass)
            setCurrentClassIdStudent(classID)
            navigate(ScreenNames.StudentHome)
    }

    const getClassID = async () => {
        rvIsLoading(true)
        await API.post(
            'AdminQueries',
            '/graphQLGetSchoolIdClassId',
            {

                headers: await getApiHeaders(),
                body: {
                    bulkSignUpId: bulkSignUpId
                },
            }
        ).then(result => {
            setClassID(result.classID)
        }).finally(() => {
            rvIsLoading(false)
        })
    }

    useEffect(() => {
        rvIsLoading(true)
        API.post(
            'AdminQueries',
            '/graphQLGetClassName',
            {
                headers: {
                    'content-type': 'application/json',
                },
                body: {
                    bulkSignUpId: bulkSignUpId
                },
            }

        ).then(result => {
            setClassName(result)
        }).finally(() => {
            rvIsLoading(false)
        })
        getClassID()
    }, [bulkSignUpId])

    if (!currentStudent) {
        console.log('No current student')
        return null
    }

    const Avatar = avatars[currentStudent?.avatar as keyof typeof avatars]



    return (
        <Page style={styles.page}>
            {
                isLoading ?
                    <Loading isLoading={isLoading} />
                    :
                    <View style={styles.inputContainer}>
                        <View style={styles.avatarContainer}>
                            <Avatar />
                        </View>
                        <DefaultText children={className} style={styles.subtitle} />
                        <DefaultText children={"Has asked for you to join the class."} style={styles.miniTitle} />

                        <View style={styles.buttonSpacing}>
                            {!className ?
                                <View><DefaultText>No class found.</DefaultText></View>
                                : <TouchableOpacity style={styles.joinButton} onPress={joinClass}>
                                    <DefaultText style={styles.buttonText}>Join class</DefaultText>
                                </TouchableOpacity>}
                        </View>
                    </View>
            }
        </Page>
    )
}

const styles = StyleSheet.create({
    page: {
        backgroundColor: DecidaColors.White,
    },
    inputContainer: {
        backgroundColor: DecidaColors.LightGray,
        flexDirection: 'column',
        width: 350,
        height: 400,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
    },
    logostyle: {
        backgroundColor: DecidaColors.Black,
        borderRadius: 12,
        padding: 20,
        width: "95%"
    },
    title: {
        fontSize: 36,
        padding: 60,
        color: DecidaColors.Green
    },
    heading: {
        fontSize: 22,

    },
    subtitle: {
        color: DecidaColors.Black,
        fontSize: 25,
        paddingBottom: 0,
    },
    miniTitle: {
        color: DecidaColors.Black,
        fontSize: 12,
        paddingBottom: 0,
        textAlign: 'center'
    },

    buttonSpacing: {
        marginVertical: 30,
        alignItems: 'center',
        justifyContent: 'center',
    },
    joinButton: {
        backgroundColor: DecidaColors.Green,
        padding: 20,
        height: 50,
        width: 150,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonText: {
        color: DecidaColors.White

    },
    avatarContainer: {
        marginVertical: 40,
        width: windowWidthcondition ? 200 : 150,
        height: windowWidthcondition ? 200 : 150,
    },
})
