import { Auth } from "aws-amplify";
import { sleep } from "../../common/promise-queue";

const code = '000000'

export const usernameTaken = async (username: string) => {
    let retryCount = 0
    while (true) {
        try {
            await Auth.confirmSignUp(username, code, {
                // If set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
                forceAliasCreation: false
            })
            return true
        } catch (err: any) {
            if (err.code === 'UserNotFoundException') {
                return false
            } else if (err.code === 'NotAuthorizedException') {
                return true
            }
            
            if (retryCount > 10) {
                console.error(JSON.stringify(err))
                throw err
            }
        }
        
        console.log({retryCount})
        // randomly sleep between 2 to 5 seconds
        await sleep(Math.floor(Math.random() * 3000) + 1000 * retryCount)
        retryCount++
    }
}
