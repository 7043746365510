import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { DuckDuckSabotageIcon } from '../../icons/duck-duck-sabotage-icon'
import { DuckDuckSabotageIconInverted } from '../../icons/duck-duck-sabotage-icon-inverted'


export const SwitchesActivitiesResilienceDuckDuckSabotage: CardTemplate = {
    cardId: "e885936a-89a1-4675-840e-7905324fe6e3",
    title: 'Duck, duck, sabotage!',
    Header: DuckDuckSabotageIconInverted,
    Icon: DuckDuckSabotageIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Building resilience by performing hard tasks"} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"A hand-sized ball such as a handball or a tennis ball"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Stand in a circle with friends, about a meter apart from each other (6 or more works best)"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'Pick a leader, "conductor," to stand in the middle.'} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Before the game starts the Conductor chooses a silly way to catch the ball (e.g. non-dominant hand, clapping, spinning)."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"One person starts with the ball, ready to throw."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'When ball is in air, conductor calls "duck" or "sabotage"'} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'If they say “duck” then the person who is catching catches it normally'} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'If they say “Sabotage” then the person who is catching catches it the silly way '} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Pass the ball, following conductor's instructions."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Rules:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The conductor must give the person catching enough time to perform the action"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Each person has three lives "} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='If the ball is dropped than the person who dropped loses a life' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='If it is a bad throw than the person who threw it loses a life' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='If someone does the actions and it is not a “Sabotage” they lose a life' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={`If someone doesn't do the actions and it is a “Sabotage” they lose a life`} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Every time someone gets out (loses all three of their lives) then the conductor decides on an addition the silly catch everyone must perform (e.g. clapping once then catch with non-dominant hand)"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"If you are the last person standing then you are the conductor for the next round"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Aim:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"To be the last person standing"} />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: '',
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.RESILIENCE_BUILDERS],
    action: [CardActionType.Action],
    level: []
}
