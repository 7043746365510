import { gql, useQuery, useSubscription } from '@apollo/client'
import { RouteProp, useRoute } from '@react-navigation/native'
import _ from 'lodash'
import { StyleSheet, View } from 'react-native'
import { CheckInSession, GetStudentClassByClassIdQueryVariables, GetStudentClassByStudentIdQuery, GetStudentClassByStudentIdQueryVariables, OnMutateStudentClassByStudentIdSubscription, OnMutateStudentClassByStudentIdSubscriptionVariables, StudentClass } from '../../common/API'
import { onMutateStudentClassByStudentId } from '../../common/graphql/subscriptions'
import ClassWeeklyCheckInGraphs from '../common/class-weekly-check-in-graphs'
import { handleRestartApp } from '../common/handle-restart-app'
import { TeacherClassNavigatorParamList } from './teacher-route-param-types'
import { listStudentClassAndStudentDataByClassIdCustomQuery, ListStudentClassAndStudentDataByClassIdCustomQuery, ListStudentClassAndStudentDataByStudentIDCustomQuery, listStudentClassAndStudentDataByStudentIDCustomQuery } from './teacher-graphql-scripts'
import { StudentClassApprovalStatus } from '../models'

type Props = {
    checkstate: boolean
}

const TeacherStudentIndividualStatsGoMode = ({ checkstate }: Props) => {
    const { params } = useRoute<RouteProp<TeacherClassNavigatorParamList, "TeacherStudentIndividualStats">>()
    const { studentID, classID } = params

    const { data: studentClassAndStudentDataResponse } = useQuery<ListStudentClassAndStudentDataByStudentIDCustomQuery, GetStudentClassByStudentIdQueryVariables>(gql`${listStudentClassAndStudentDataByStudentIDCustomQuery}`, { variables: { studentID: studentID || "", limit: 5000 }, skip: !studentID })
    const studentClasses = studentClassAndStudentDataResponse?.getStudentClassByStudentId?.items.filter((item) => item?._deleted !== true) as StudentClass[]


    const sortedStudentClass = _.orderBy(studentClasses,
        [
            (sc) => sc.classID === params?.classID,
            (sc) => sc.class?.name.toLowerCase()
        ],
        [
            "desc",
            "asc"
        ])

    const { error: errorStudentClassSubscription } = useSubscription<OnMutateStudentClassByStudentIdSubscription, OnMutateStudentClassByStudentIdSubscriptionVariables>(gql`${onMutateStudentClassByStudentId}`,
        {
            variables: {
                studentID
            }, skip: !studentID,
        })

    if (errorStudentClassSubscription !== undefined) {
        handleRestartApp()
    }

    return (
        <View style={styles.mainContainer}>
            {sortedStudentClass?.map((studentClass) => {
                return (
                    <ClassWeeklyCheckInGraphs
                        key={studentClass?.classID}
                        checkstate={checkstate}
                        studentClass={studentClass}
                    />
                )
            })}
        </View>
    )
}
export default TeacherStudentIndividualStatsGoMode

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        width: '100%',
    },
})