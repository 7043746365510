import { Emotion } from '../models'
import { IconAngry } from './icon-angry'
import { IconAngry3Levels } from './icon-angry-3-levels'
import { IconAngry5Levels } from './icon-angry-5-levels'
import { IconAngry7Levels } from './icon-angry-7-levels'
import { IconAnxious } from './icon-anxious'
import { IconAnxious3Levels } from './icon-anxious-3-levels'
import { IconAnxious5Levels } from './icon-anxious-5-levels'
import { IconAnxious7Levels } from './icon-anxious-7-levels'
import { IconExcited } from './icon-excited'
import { IconExcited3Levels } from './icon-excited-3-levels'
import { IconExcited5Levels } from './icon-excited-5-levels'
import { IconExcited7Levels } from './icon-excited-7-levels'
import { IconHappy } from './icon-happy'
import { IconHappy3Levels } from './icon-happy-3-levels'
import { IconHappy5Levels } from './icon-happy-5-levels'
import { IconHappy7Levels } from './icon-happy-7-levels'
import { IconSad } from './icon-sad'
import { IconSad3Levels } from './icon-sad-3-levels'
import { IconSad5Levels } from './icon-sad-5-levels'
import { IconSad7Levels } from './icon-sad-7-levels'
import { IconScared } from './icon-scared'
import { IconScared3Levels } from './icon-scared-3-levels'
import { IconScared5Levels } from './icon-scared-5-levels'
import { IconScared7Levels } from './icon-scared-7-levels'

export const emotionsIcons = {
    [Emotion.ANGRY]: {
        Icon: IconAngry,
        3: IconAngry3Levels,
        5: IconAngry5Levels,
        7: IconAngry7Levels,
        1: undefined,
    },
    [Emotion.ANXIOUS]: {
        Icon: IconAnxious,
        3: IconAnxious3Levels,
        5: IconAnxious5Levels,
        7: IconAnxious7Levels,
        1: undefined,
    },
    [Emotion.EXCITED]: {
        Icon: IconExcited,
        3: IconExcited3Levels,
        5: IconExcited5Levels,
        7: IconExcited7Levels,
        1: undefined,
    },
    [Emotion.HAPPY]: {
        Icon: IconHappy,
        3: IconHappy3Levels,
        5: IconHappy5Levels,
        7: IconHappy7Levels,
        1: undefined,
    },
    [Emotion.SAD]: {
        Icon: IconSad,
        3: IconSad3Levels,
        5: IconSad5Levels,
        7: IconSad7Levels,
        1: undefined,
    },
    [Emotion.SCARED]: {
        Icon: IconScared,
        3: IconScared3Levels,
        5: IconScared5Levels,
        7: IconScared7Levels,
        1: undefined,
    },
}