import * as React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";
const EmotionSadIcon = (props?: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 92.491 92.489"
        {...props}
    >
        <Path
            id="Path_3869"
            data-name="Path 3869"
            d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465Z"
            transform="translate(26.513 51.518)"
            fill="none"
            stroke="#3685c6"
            strokeWidth={3.013}
        />
        <Path
            id="Path_3870"
            data-name="Path 3870"
            d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211"
            transform="translate(23.058 42.78)"
            fill="#3685c6"
        />
        <Path
            id="Path_3871"
            data-name="Path 3871"
            d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211"
            transform="translate(58.245 42.78)"
            fill="#3685c6"
        />
        <Path
            id="Path_3872"
            data-name="Path 3872"
            d="M0,4.536s17.981-22.781,42.69-.53"
            transform="translate(22.221 66.235)"
            fill="none"
            stroke="#3685c6"
            strokeWidth={3.013}
        />
    </Svg>
);
export default EmotionSadIcon;
