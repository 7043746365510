import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { Worksheets } from '../../common/worksheets-imports'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { IntroductionToAngryLessonPlan1 } from './src/introduction-to-angry-lesson-plan1'
import { IntroductionToAngryLessonPlan2Level3 } from './src/introduction-to-angry-lesson-plan2-level3'
import { ToolsTheLittleBookOfBigEmotions } from '../tools/cards/the-little-book-of-big-emotions'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

const exploringAngryVideo1: Videos = {
    id: '1',
    url: 'https://www.youtube.com/embed/8_FMxPo4xDM',
    title: ""
}

export const SwitchesLessonPlansIntroductionToAngry: CardTemplate = {
    cardId: "dfcc8666-d09a-4bca-80ba-2a2d8967d2dd",
    title: 'Exploring angry',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define anger and understand its role in the body.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify strategies for managing anger in a healthy way.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 40 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Log into the Switch4Schools program and select an appropriate meme of the day to share, for example:</DefaultText>
                        <View style={styles.imageContainer}>
                            <IntroductionToAngryLessonPlan1 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think about a time when they felt angry. What made them angry? How did they feel physically? What did they do to try to feel better?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to turn to the person next to them and ask them to discuss what they think angry means.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (10 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Little Book of Big Emotion and introduce the concept of anger. Define anger as a feeling of frustration, annoyance, or irritation that can be accompanied by physical symptoms such as a racing heart, clenched fists, or increased energy. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Draw a three column table on the board, and ask students to contribute what they see, feel (in the body), or do (behave) when they or someone else is angry.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students if anger is good or bad. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Good – Getting your point across that someone is doing the wrong thing and they need to stop, or when you need to “fire up” to be aggressive in a football game or get a task done.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>◦ Bad – May hurt someone either physically or verbally, may break things, ruin relationships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that emotions are not good are bad.  They just are.  Anger has its place in the right context, but when anger is not controlled it can erupt like a volcano into violence or abuse which is not good.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Anger Volcano infographic and discuss how often angry behaviours that you can see are often caused by feelings you don’t see, such as rejection, shame, disappointment. </DefaultText>
                        <View style={styles.imageContainer}>
                            <IntroductionToAngryLessonPlan2Level3 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Little Book of Big Emotion to explain that there are different levels of anger and words we can use to describe those levels.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• OPTIONAL Movement Break:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ 5-4-3-2-1 In this simple game, students stand up and you (or leader) have them do five different movements in descending order. For example you would say: “Do five jumping jacks, spin around four times, hop on one foot three times, walk all the way around the classroom two times, give your neighbor one high-five (pausing in between each task for students to do it).”</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (20 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give each student a worksheet and work through the anger stages identifying the triggers as they move through the intensity levels eventuating in an eruption. Ask students to think about what it looks, feels and sounds like at each stage. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students think of things they can do calm down when they are feeling angry, you may like to brainstorm as a class. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Advise students that you will be exploring different switches over the coming weeks to practice when feeling angry.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If time permits and/or if student’s have access to the Switch4Schools program on their device encourage them to explore the available switches assigned to angry on the emotion wheel and add activities they’d like to try to the worksheet. Could also be a homework activity.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of finding healthy ways to cope with anger and encourage students to use the toolkit as a reference when they are feeling angry.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to seek additional help if their anger is persistent or severe, and to talk to a trusted adult if they are struggling to manage their anger.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Introducing Anger Slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/P4+Exploring+Anger.pdf' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='The Little Book of Big Emotions' link={ToolsTheLittleBookOfBigEmotions.openUrl || ""} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencil</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Anger Volcano worksheet' link={Worksheets.angerVolcanoLevel3.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <> 
                        <DefaultText style={CommonStyles.lessonPlanText}>Why some anger can be good for you -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.ted.com/talks/ryan_martin_why_some_anger_can_be_good_for_you?utm_campaign=tedspread&utm_medium=referral&utm_source=tedcomshare' link='https://www.ted.com/talks/ryan_martin_why_some_anger_can_be_good_for_you?utm_campaign=tedspread&utm_medium=referral&utm_source=tedcomshare' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Anger – how it affects people -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.betterhealth.vic.gov.au/health/healthyliving/anger-how-it-affects-people' link='https://www.betterhealth.vic.gov.au/health/healthyliving/anger-how-it-affects-people' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Anger management for kids -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.youtube.com/watch?v=8_FMxPo4xDM' link='https://www.youtube.com/watch?v=8_FMxPo4xDM' />

                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={exploringAngryVideo1} />
                        </View>

                        <DefaultText style={CommonStyles.lessonPlanText}>Anger -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://services.unimelb.edu.au/counsel/resources/wellbeing/anger' link='https://services.unimelb.edu.au/counsel/resources/wellbeing/anger' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 10
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        flexDirection: 'row',
        marginHorizontal: 20,
        justifyContent: 'center',
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        marginVertical: 10,
    },
    noIndent: {
        paddingLeft: 0,
    },
})
