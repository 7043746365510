

import { ColorValue, View } from 'react-native';
import Svg, { Line, Rect, SvgProps } from 'react-native-svg';
import { DecidaColors } from '../../common/decida-colors';

interface EmotionVerticalBarProps {
  heightPercentage: number;
  fill: ColorValue
  svgWidth?: number
  svgHeight?: number
  showBorder?: boolean
}

const EmotionVerticalBar = ({ heightPercentage, fill, svgWidth = 10, svgHeight = 80, showBorder = true }: EmotionVerticalBarProps) => {

  if (!heightPercentage) return null

  const normalizedHeightPercentage = Math.max(0, Math.min(1, heightPercentage));

  const barHeight = normalizedHeightPercentage * svgHeight;

  const barLeft = 0
  const barTop = svgHeight - barHeight

  const centerLineY = svgHeight / 2;

  const outlineStrokeWidth = 0.5

  return (
    <Svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight}`}>
      {/* Draw the bar */}
      <Rect
        x={barLeft}
        y={barTop}
        width={svgWidth}
        height={barHeight}
        fill={fill}
      />

      {/* Draw the outline box */}
      {showBorder && (
        < Rect
          x={barLeft}
          y={0}
          width={svgWidth}
          height={svgHeight}
          fill="none"
          stroke={DecidaColors.Black}
          strokeWidth={outlineStrokeWidth}
        />
      )}

      {/* Draw the horizontal center line with the same width as the bar */}
      {showBorder && (
        <Line
          x1={barLeft}
          x2={barLeft + svgWidth}
          y1={centerLineY}
          y2={centerLineY}
          stroke={DecidaColors.Black}
          strokeWidth={outlineStrokeWidth}
        />
      )}
      
    </Svg>

  );
};
export default EmotionVerticalBar;
