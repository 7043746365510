import { Text, View } from 'react-native'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles, windowWidth } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { TimeIcon } from '../../explore/src/time-icon'
import { TimeIconInverted } from '../../explore/src/time-icon-inverted'
import { TimeImage1 } from './src/time-image1'
import { TimeLessonPlanImage1 } from './src/time-lessonplan-image1'
import { TimeLessonPlanImage2 } from './src/time-lessonplan-image2'
import { Worksheets } from '../../../common/worksheets-imports'
import { Videos } from '../../../models'
import SwitchCardVideo from '../../../common/switch-card-video'
import { DecidaColors } from '../../../../common/decida-colors'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithLink from '../../common/default-text-with-link'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'

const timeVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/8do0Jiscgsk",
    title: ""
}

export const SwitchesEmotionsAngryTime: CardTemplate = {
    cardId: "09a73dc6-4fc1-4291-9c86-408daaaa7e32",
    title: 'Time',
    Header: TimeIconInverted,
    Icon: TimeIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Learning to deal with other people who are trying to get under your skin or rile you up."} />
        </>
    ),
    Images: () => (
        <>
            <TimeImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"When someone makes you angry, don’t respond - not even with a look. Nothing."} />
                <DefaultTextWithDownArrow textdata={"On the scale of 1-10, pick a number that represents how mad you are, then wait for 5 minutes."} />
                <DefaultTextWithoutArrows textdata={"Once again, pick a number on the scale that represents how mad you are now. "} />
                <DefaultTextWithoutArrowsItallic textdata={"If the number isn’t below a 3, wait for some more time and repeat until you are in the green zone."} />

            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: 'Giving yourself time helps create distance between the trigger and your anger, so that you don’t act out of anger in the moment. Time allows the chemicals and emotions that overwhelm the brain to disperse.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Visualise and articulate future state goal/s.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Apply objective thinking to a personal anger triggering event to learn perspective.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin with a brief discussion on emotions. Ask students about times when they've felt really angry or upset. Allow a few volunteers to share their experiences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain Fight/Flight/Freeze Response: Provide a simple explanation of the fight/flight/freeze response. Emphasise the role of adrenaline and how it affects the body. Refer to the Little Book of Big Emotions or Physiology of Emotion in the Tools section for supporting information.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reflection Activity: Distribute the Fight Freeze Flight Worksheet. Ask students to reflect on a specific intense moment, write or draw what happened, and rate their anger level on a scale of 1-10.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss Reflections: Encourage students to share their reflections (optional). Discuss the intensity of anger during the trigger and compare it to their current feelings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduction to Time Switch: Explain the Time Switch concept. Emphasise that the goal is to pause the impulse to "fight" and wait for the chemicals to wear off.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Managing My Anger Worksheet: Distribute the Managing My Anger Worksheet. Guide students to brainstorm activities they can do while waiting to cool down. Provide thought starters such as walking away, deep breathing, or journaling.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Demonstrate Time Switch Steps: Display the Time Switch steps on the Switch4Schools app. Emphasise the importance of stopping, scoring anger levels, and waiting for at least 5 minutes if in the yellow or red zone.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Class Activity: Conduct a short role playing activity where students practice using the Time Switch in hypothetical scenarios. Encourage them to consider the steps and cooling down activities. Example scenarios include:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Situation 1: Classroom Conflict: Imagine you're working on a group project, and a classmate is not contributing, causing frustration. How would you apply the Time Switch in this situation?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Situation 2: Lost Belongings: You've misplaced your favourite book or gadget, and it's causing stress. How can you use the Time Switch to manage your emotions and find a solution?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Situation 3: Game Disagreement: You're playing a game with friends, and there's a disagreement about the rules. How could the Time Switch help you handle the situation without getting overly upset?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Situation 4: Sibling Conflict: Your sibling borrowed something without asking, and you're feeling angry. How might the Time Switch help you cool down and address the situation calmly?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Situation 5: Waiting in Line: You're in a long line, and someone cuts in front of you. How could applying the Time Switch prevent a heated reaction and help you handle the situation?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Situation 6: Team Sports Challenge: During a team sport, a mistake by a teammate frustrates you. How might the Time Switch be useful in managing your emotions and maintaining a positive attitude?</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reflective Journaling: Ask students to reflect on how they can apply the Time Switch in real life situations. Use the Managing My Anger Worksheet as a guide for their reflections.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Group Discussion: Have a brief class discussion on the strategies students came up with. Reinforce the idea that waiting and using calming activities can help manage anger effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to refer to the Time switch in the Switch4Schools app whenever needed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Assignment: As homework, encourage students to apply the Time Switch in a real-life situation and journal about their experience.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Fight Freeze Flight Worksheet' link={Worksheets.fightFreezeFlight.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Managing My Anger Worksheet' link={Worksheets.managingMyAnger.uri} /></DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Physiology of Anger - </DefaultText>
                        <DefaultTextWithLink text='https://www.mentalhelp.net/anger/physiology/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How Long Does the Fight or Flight Reaction Last? -   </DefaultText>
                        <DefaultTextWithLink text='https://psychcentral.com/blog/anger/2016/06/how-long-does-the-fight-or-flight-reaction-last' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Brain Basics: Anxiety (for kids) Part 3 - The fight, flight and freeze responses  -  </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=8do0Jiscgsk' />


                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='This can take a lot of personal discipline.  It might be helpful to enlist the help of a friend, mentor or teacher for moral support.' />
            <DefaulTextWithTipsIcon textdata='When assessing if enough time has passed ask: ' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"What am I feeling? "} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"How intense is my feeling?"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Is it more, the same or less than before? "} />
            <DefaulTextWithTipsIcon textdata='If the feeling intensity is the same or worse than before, then more time is needed.' />

        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Time.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Angry]
}
