
import { adminClassFields } from "./admin-get-class-query"
import { adminSchoolAdminFields } from "./admin-get-school-admin-query"
import { adminStaffFields } from "./admin-get-staff-query"

export const adminCampusFields = `
  id
  name
  schoolID
  _version
  _deleted
  __typename
`


export const adminClassGroupsFields = `
  id
  name
  schoolID
  campusID
  _version
  _deleted
  __typename
`

export const adminSchoolFields = `
  id
  name
  licenseTotal
  timezone
  status
  activeStudents
  trialEndDate
  renewalDate
  notes
  checkInCountAllTime
  checkInCountYTD
  checkInCountRollingWeekMonday
  checkInCountRollingWeekTuesday
  checkInCountRollingWeekWednesday
  checkInCountRollingWeekThursday
  checkInCountRollingWeekFriday
  checkInCountRollingWeekSaturday
  checkInCountRollingWeekSunday
  wholeSchoolSubscription
  billingAddress
  billingNotes
  invoiceUrl
  abn
  createdAt
  updatedAt
  coordinatorID
  schoolAdminUI
  teacherWellbeing
  staffWellbeing
  wellbeingFeature
  _lastChangedAt
  _deleted
  _version
  __typename
`

export const adminGetSchoolQuery = `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      ${adminSchoolFields}
      classes(filter: {archived: {ne: true}, _deleted: {ne: true}}, limit: 5000) {
        items {
          ${adminClassFields}
        }
        nextToken
        startedAt
        __typename
      }
      schoolAdmins(filter: {_deleted: {ne: true}}, limit: 5000) {
        items {
          ${adminSchoolAdminFields}
        }
        nextToken
        startedAt
        __typename
      }
      staffs(filter: {_deleted: {ne: true}}, limit: 5000) {
        items {
          ${adminStaffFields}
        }
        nextToken
        startedAt
        __typename
      }
      campuses(filter: {_deleted: {ne: true}}) {
        items {
            ${adminCampusFields}
            classGroups {
              items {
                ${adminClassGroupsFields}
                classes {
                  items {
                    ${adminClassFields}
                  }
                }
              }
            }
        }
      }
    }
  }
`