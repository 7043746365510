import * as React from "react"
import Svg, { Defs, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

function IconAvatarRequestChatNotYet(props) {
  return (
    <Svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 96.29 89.18"
      {...props}
    >
      <Defs></Defs>
      <Path
        d="m12,5.2c-6.6,0-11.9,5.3-12,11.7v45.4c0,6.4,5.4,11.7,11.9,11.7h41.3s17.6,14.7,17.6,14.7c1.4,1.1,3.5.2,3.5-1.6v-13.1h5.9c6.6,0,11.9-5.3,11.9-11.7V16.9c0-6.4-5.3-11.7-11.9-11.7H12s0,0,0,0Z"
        fill="#a0a0af"
      />
      <Path
        d="m16.19,0c-6.6,0-11.9,5.3-12,11.7v45.4c0,6.4,5.4,11.7,11.9,11.7h41.3s17.6,14.7,17.6,14.7c1.4,1.1,3.5.2,3.5-1.6v-13.1h5.9c6.6,0,11.9-5.3,11.9-11.7V11.7C96.29,5.3,90.99,0,84.39,0H16.19s0,0,0,0Z"
        fill="#3953a4"
      />
      <Path
        fill="#ececec"
        d="m61.97,16.5c.56-.98.49-2.2-.2-3.1s-1.85-1.29-2.94-1c-1.09.29-1.9,1.21-2.05,2.33-.15,1.12.4,2.22,1.38,2.79,1.33.77,3.04.31,3.81-1.02"
      />
      <Path
        fill="#ececec"
        d="m70.35,24.88c.56-.98.49-2.2-.2-3.1s-1.85-1.29-2.94-1c-1.09.29-1.9,1.21-2.05,2.33-.15,1.12.4,2.22,1.38,2.79,1.33.77,3.04.31,3.81-1.02"
      />
      <Path
        fill="#ececec"
        d="m27.48,34.86c.53,7.4,4.7,14.06,11.13,17.77,6.43,3.71,14.28,3.98,20.95.73,6.67-3.25,11.3-9.6,12.34-16.95.1-.79-.14-1.59-.66-2.19-.52-.6-1.27-.95-2.07-.96h-.08c-1.39.02-2.55,1.05-2.73,2.42-.6,4.12-2.72,7.87-5.93,10.52-3.22,2.65-7.3,4-11.46,3.8-4.16-.2-8.1-1.95-11.04-4.89-2.94-2.95-4.69-6.88-4.89-11.04-.2-4.16,1.15-8.25,3.8-11.46,2.65-3.22,6.4-5.33,10.52-5.93,1.37-.18,2.4-1.35,2.42-2.73v-.08c-.01-.8-.36-1.55-.96-2.07-.6-.52-1.4-.76-2.19-.66-5.6.8-10.7,3.69-14.25,8.1-3.55,4.41-5.3,10-4.89,15.65"
      />
      <Path
        fill="#ececec"
        d="m47.68,22.09c-1.54,0-2.79,1.25-2.79,2.79v7.68c0,3.47,2.82,6.29,6.29,6.29h4.89c1.54,0,2.79-1.25,2.79-2.79s-1.25-2.79-2.79-2.79h-4.89c-.38,0-.7-.31-.7-.7v-7.68c0-.74-.29-1.45-.82-1.98s-1.23-.82-1.98-.82"
      />
    </Svg>
  )
}

export default IconAvatarRequestChatNotYet
