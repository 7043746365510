import { useReactiveVar } from '@apollo/client'
import { View, StyleSheet, TextStyle, useWindowDimensions, ViewStyle } from 'react-native'
import { BackButton } from '../common/back-button'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'
import { IconRightArrow } from '../icon/icon-right-arrow'
import { rvUserGroup } from '../login/login-state'
import { UserGroup } from '../../common/constants'

interface Props {
    step?: number
    back: () => void
}

export const TeacherCreateStudentBreadcrumbs = ({ step = 1, back }: Props) => {
    const dimensoins = useWindowDimensions()
    const userGroup = useReactiveVar(rvUserGroup)

    const wrapBreadcrumbs = dimensoins.width < 550 ? { marginTop: 30 } : {}
    const [smallerBreadcrumbSize, arrowPercent] = dimensoins.width < 410 ? [{ fontSize: 18 }, 0.8] : [{}, 1]

    return (
        <>
            {userGroup !== UserGroup.SchoolAdmin && (
                <View style={styles.backButton}>
                    <BackButton onPress={back} />
                </View>
            )}
            <View style={[styles.breadcrumbs, wrapBreadcrumbs]}>
                <DefaultText style={[styles.breadcrumb, smallerBreadcrumbSize]}>Pick an avatar</DefaultText>
                <View style={styles.breadcrumb}>
                    <IconRightArrow percent={arrowPercent} fillColor={step >= 2 ? DecidaColors.Green : undefined} />
                </View>
                <DefaultText style={[step >= 2 ? styles.breadcrumb : styles.breadcrumbDisabled, smallerBreadcrumbSize]}>Nickname</DefaultText>
                <View style={styles.breadcrumb}>
                    <IconRightArrow percent={arrowPercent} fillColor={step >= 3 ? DecidaColors.Green : undefined} />
                </View>
                <DefaultText style={[step >= 3 ? styles.breadcrumb : styles.breadcrumbDisabled, smallerBreadcrumbSize]}>Password</DefaultText>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    headerRow: {
        flexDirection: 'row',
    },
    backButton: {
        position: 'absolute',
        margin: 5,
    },
    breadcrumbs: {
        flexDirection: 'row',
        alignSelf: 'center',
    } as ViewStyle,
    breadcrumb: {
        margin: 5,
    } as TextStyle,
    breadcrumbDisabled: {
        margin: 5,
        color: DecidaColors.Gray,
    } as TextStyle,
})
