import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { useState } from 'react'
import { SafeAreaView, ScrollView, StyleSheet, View } from 'react-native'
import { CustomUpdateTeacherMutation, CustomUpdateTeacherMutationVariables, GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables, Teacher } from '../../common/API'
import { customUpdateTeacher } from '../../common/graphql/mutations'
import { getTeacherByCognitoUsername } from '../../common/graphql/queries'
import { rvCurrentUser } from '../login/login-state'
import { BackButton } from './back-button'
import { CommonStyles } from './const'
import { rvIsLoading } from './loading'
import { CLIENT_EVENT, createClientLog } from './log-client-event'
import { Page } from './page'
import ProfileForm, { ProfileFormData } from './profile-form'
import { showAlert } from './universal-alert'
import { UserGroup } from '../../common/constants'
import AvatarImage from './avatar-image'
import { DefaultText } from './default-text'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'
import { ScreenNames } from './screen-names'
import { DecidaColors } from '../../common/decida-colors'

export const TeachersProfile = () => {

    const [allowEdit, setAllowEdit] = useState(false)
    const { navigate } = useNavigation<DrawerNavigationProp<CheckInNavigatorParamList>>()
    const user = useReactiveVar(rvCurrentUser)

    const { data: teacherResponse } = useQuery<GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables>(gql`${getTeacherByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
    const currentTeacher = teacherResponse?.getTeacherByCognitoUsername?.items[0] as Teacher

    const [customUpdateTeacherMutation] = useMutation<CustomUpdateTeacherMutation, CustomUpdateTeacherMutationVariables>(gql`${customUpdateTeacher}`,)

    const saveChangesTeacher = async ({ firstName, lastName, email, preferredUsername }: ProfileFormData) => {

        if (currentTeacher) {

            try {
                rvIsLoading(true)
                await customUpdateTeacherMutation({
                    variables: {
                        input: {
                            id: currentTeacher?.id,
                            email: email || "",
                            firstName,
                            lastName,
                            preferredUsername
                        }

                    }
                })

                createClientLog({
                    event: CLIENT_EVENT.EDIT_PROFILE_SUCCESS,
                    area: "Edit profile screen",
                    action: "Clicking save, profile updated successfully",
                    payload: {
                        group: "teacher",
                        nickname: currentTeacher?.cognitoUsername,
                    }
                })
                setAllowEdit(!allowEdit)
                rvIsLoading(false)
            }
            catch (e: any) {
                showAlert({
                    title: 'An error has occured.',
                    message: String(e),
                    leftButtonText: 'Ok',
                    rightButtonText: 'Retry',
                    onRightButtonPress: () => saveChangesTeacher({ firstName, lastName, email, preferredUsername })
                })
                createClientLog({
                    event: CLIENT_EVENT.EDIT_PROFILE_FAILED,
                    area: "Edit profile screen",
                    action: "An error has occured., failed to update profile",
                    payload: {
                        group: "teacher",
                        nickname: currentTeacher?.cognitoUsername,
                        error: String(e)
                    }
                })
            } finally {
                rvIsLoading(false)
            }
        }
    }

    const navigateToEditAvatar = () => {

        if (!currentTeacher) {
            return null
        }

        navigate(ScreenNames.UserEditAvatar, { id: currentTeacher?.id, user: UserGroup.Teacher })
    }
    return (

        <Page style={styles.page}>
            <View style={styles.backButtonContainer}>
                <BackButton />

            </View>
            <ScrollView style={CommonStyles.inputScrollView}>
                <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <AvatarImage avatar={currentTeacher?.avatar} size={100} editMode={allowEdit} onEdit={navigateToEditAvatar} />
                    <DefaultText style={styles.nickname}>{currentTeacher?.cognitoUsername}</DefaultText>
                </View>
                <View style={{ flex: 1 }}>
                    <ProfileForm allowEdit={allowEdit} setAllowEdit={setAllowEdit} onSave={saveChangesTeacher} profileType={UserGroup.Teacher} data={currentTeacher} />
                </View>
            </ScrollView>

            <SafeAreaView>
            </SafeAreaView>
        </Page>
    )
}


const styles = StyleSheet.create({
    page: {

    },
    backButtonContainer: {
        flexDirection: 'row',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%'
    },
    nickname: {
        fontSize: 30,
        fontWeight: 'bold',
        color: DecidaColors.Green,
        textAlign: 'center',
    },


})