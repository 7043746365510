import { API, graphqlOperation } from "aws-amplify"
import { WorkSheet } from "xlsx"
import { GetSchoolAdminByCognitoUsernameQueryVariables, GetSchoolAdminByCognitoUsernameQuery, CreateSchoolAdminInput, UpdateSchoolAdminInput } from "../../../common/API"
import { getSchoolAdminByCognitoUsername } from "../../../common/graphql/queries"
import { Message, Sheet } from "./common-import-school-data-const-and-types"
import { convertSpreadsheetToRows } from "./convert-spreadsheet-to-rows"
import { validateFieldNotUsedInPreviousRows } from "./validate-field-not-used-in-previou-rows"
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { MutableRefObject } from "react"
import { emailValidator } from "../../common/email-validator"

type Params = {
    sheet: WorkSheet,
    schoolID: string,
    errors: MutableRefObject<Message[]>,
    warnings: MutableRefObject<Message[]>,
    info: MutableRefObject<Message[]>,
    schoolAdminToCreate: MutableRefObject<CreateSchoolAdminInput[]>,
    schoolAdminToUpdate: MutableRefObject<UpdateSchoolAdminInput[]>,
}

export const validateAdministratorSheet = async ({
    sheet,
    schoolID,
    errors,
    warnings,
    info,
    schoolAdminToCreate,
    schoolAdminToUpdate,
}: Params) => {
    if (sheet == null) {
        return
    }

    if (sheet['A6']?.v !== 'First Name') {
        errors.current.push({ sheet: Sheet.Administrator, row: 6, message: 'header column expected, please do not modify row 1-6 of template file.' })
        return
    }

    let rows = convertSpreadsheetToRows({
        startingRow: 7,
        columnOrder: ['firstName', 'lastName', 'email'],
        sheet,
        filter: row => row.firstName != null || row.lastName != null || row.email != null,
    }).map(row => ({ ...row, email: row.email?.toLowerCase() }))

    const firstRow = rows[0]
    if (firstRow && firstRow.row === 7 && firstRow.firstName === 'eg.  Sally') {
        info.current.push({ sheet: Sheet.Administrator, row: firstRow.row, message: 'skipping row due to example values detected' })
        rows = rows.splice(1)
    }

    rows.forEach(({ firstName, lastName, email, row }, index) => {
        if (firstName == null || lastName == null || email == null) {
            errors.current.push({ sheet: Sheet.Administrator, row, message: `missing mandatory field ${firstName == null ? '"First Name"' : ''}${lastName == null ? (firstName == null ? ', ' : '') + '"Last Name"' : ''}${email == null ? (firstName == null || lastName == null ? ', ' : '') + '"Email address"' : ''}` })
        }

        if (email && emailValidator(email) === "Email is not valid.") {
            errors.current.push({ sheet: Sheet.Administrator, row, message: 'administrator email is not valid' })
        }

        validateFieldNotUsedInPreviousRows(rows, 'email', index, Sheet.Administrator, errors)
    })

    await Promise.all(
        rows.map(async ({ firstName, lastName, email, row }) => {
            if (email == null) {
                return
            }

            if (emailValidator(email) === "Email is not valid.") {
                return
            }

            const cognitoUsername = `admin.${email}`;

            const response = await API.graphql(graphqlOperation(
                getSchoolAdminByCognitoUsername,
                {
                    cognitoUsername: cognitoUsername
                } as GetSchoolAdminByCognitoUsernameQueryVariables
            )) as GraphQLResult<GetSchoolAdminByCognitoUsernameQuery>

            const schoolAdmin = response.data?.getSchoolAdminByCognitoUsername?.items.filter(i => i?._deleted !== true)[0]

            if (schoolAdmin) {
                if (schoolID !== schoolAdmin.schoolID) {
                    warnings.current.push({ sheet: Sheet.Administrator, row, message: `${schoolAdmin.firstName} ${schoolAdmin.lastName} will be moved from "${schoolAdmin.school?.name}" to the current school` })
                }

                if (firstName !== schoolAdmin.firstName || lastName !== schoolAdmin.lastName || email !== schoolAdmin.email || schoolID !== schoolAdmin.schoolID) {
                    schoolAdminToUpdate.current.push({
                        id: schoolAdmin.id,
                        email: email.toString(),
                        schoolID,
                        firstName: firstName,
                        lastName: lastName,
                        cognitoUsername: schoolAdmin.cognitoUsername,
                        _version: schoolAdmin._version
                    })
                }
            } else {
                schoolAdminToCreate.current.push({
                    email: email.toString(),
                    schoolID,
                    firstName: firstName,
                    lastName: lastName,
                    cognitoUsername,
                })
            }
        })
    )

    info.current.push({ sheet: Sheet.Administrator, message: `${schoolAdminToCreate.current.length} administrator(s) to be created and ${schoolAdminToUpdate.current.length} to be updated` })
}
