import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan, Line } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconScared7Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 7)
    }

    const onPressLevel2 = {
        [prop]: () => onPress(2 / 7)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(3 / 7)
    }

    const onPressLevel4 = {
        [prop]: () => onPress(4 / 7)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(5 / 7)
    }

    const onPressLevel6 = {
        [prop]: () => onPress(6 / 7)
    }

    const onPressLevel7 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg
            viewBox="0 0 277.135 153.019"
        >
            <G data-name="Group 9561">
                <G data-name="Group hesistat" {...onPressLevel1}>
                    <Path
                        data-name="Path 7870"
                        d="M0 0l52.6-.176.043-28.007z"
                        transform="translate(-2142 -109.386) translate(2142.711 262.279)"
                        fill="#f7ece0"
                    />
                    <Path
                        data-name="Path 7871"
                        d="M2142.711 262.279l52.641-28.183-.041 28.007z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-2142 -109.386)"
                    />
                    <Path
                        data-name="Path 7905"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h26.3a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0026.3 0z"
                        transform="translate(-2142 -109.386) translate(2164.914 252.01)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7906"
                        d="M2164.914 252.01a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h26.3a2.834 2.834 0 002.835-2.834v-3.631a2.835 2.835 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#cc8236"
                        strokeWidth={0.25}
                        transform="translate(-2142 -109.386)"
                    />
                    <Text
                        transform="translate(-2142 -109.386) translate(2164.419 258.939)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Hesitant"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7911"
                        d="M2142.236 258.646l47.913-25.76"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-2142 -109.386)"
                    />
                    <Path
                        data-name="Path 7913"
                        d="M0 0l-7.385 1.073 2.417 4.495z"
                        transform="translate(-2142 -109.386) translate(2194.961 230.299)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group unsure" {...onPressLevel2}>
                    <Path
                        data-name="Path 7873"
                        d="M0 0l-.112 29.327 34.82-.022-.126-47.734z"
                        transform="translate(-2142 -109.386) translate(2197.921 232.736)"
                        fill="#efd9c2"
                    />
                    <Path
                        data-name="Path 7874"
                        d="M2197.921 232.737l34.583-18.429.125 47.734-34.819.022z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-2142 -109.386)"
                    />
                    <Path
                        data-name="Path 7890"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h19.914a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0019.914 0z"
                        transform="translate(-2142 -109.386) translate(2204.886 239.191)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7891"
                        d="M2204.886 239.191a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h19.914a2.834 2.834 0 002.835-2.834v-3.631a2.835 2.835 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#cc8236"
                        strokeWidth={0.25}
                        transform="translate(-2142 -109.386)"
                    />
                    <Text
                        transform="translate(-2142 -109.386) translate(2202.9 246.825)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Unsure"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7914"
                        d="M2197.229 229.165l30.053-16.126"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-2142 -109.386)"
                    />
                    <Path
                        data-name="Path 7915"
                        d="M0 0l-7.385 1.066 2.413 4.5z"
                        transform="translate(-2142 -109.386) translate(2232.095 210.457)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group nervous" {...onPressLevel3}>
                    <Path
                        data-name="Path 7876"
                        d="M0 0l.177 49.336 34.423-.022.043-68.237z"
                        transform="translate(-2142 -109.386) translate(2235.256 212.83)"
                        fill="#e8c6a4"
                    />
                    <Path
                        data-name="Path 7877"
                        d="M2235.256 212.83l34.649-18.923-.043 68.236-34.428.023z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-2142 -109.386)"
                    />
                    <Path
                        data-name="Path 7893"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h23.676a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0023.676 0z"
                        transform="translate(-2142 -109.386) translate(2240.713 226.385)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7894"
                        d="M2240.713 226.385a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h23.676a2.834 2.834 0 002.835-2.834v-3.631a2.835 2.835 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#cc8236"
                        strokeWidth={0.25}
                        transform="translate(-2142 -109.386)"
                    />
                    <Text
                        transform="translate(-2142 -109.386) translate(2239.172 233.737)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Ner"}
                        </TSpan>
                    </Text>
                    <Text
                        transform="translate(-2142 -109.386) translate(2251.009 233.737)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"vous"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7916"
                        d="M2234.646 209.323l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-2142 -109.386)"
                    />
                    <Path
                        data-name="Path 7917"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-2142 -109.386) translate(2269.749 190.174)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group alarmed" {...onPressLevel4}>
                    <Path
                        data-name="Path 7879"
                        d="M0 0l.05 69.611 34.82-.022-.082-87.9z"
                        transform="translate(-2142 -109.386) translate(2272.311 192.555)"
                        fill="#e0b486"
                    />
                    <Path
                        data-name="Path 7880"
                        d="M2272.311 192.555l34.787-18.315.083 87.9-34.819.026z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-2142 -109.386)"
                    />
                    <Path
                        data-name="Path 7908"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h24.99a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0024.99 0z"
                        transform="translate(-2142 -109.386) translate(2277.251 213.578)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7909"
                        d="M2277.251 213.578a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h24.99a2.834 2.834 0 002.834-2.834v-3.631a2.835 2.835 0 00-2.834-2.835z"
                        fill="none"
                        stroke="#cc8236"
                        strokeWidth={0.25}
                        transform="translate(-2142 -109.386)"
                    />
                    <Text
                        transform="translate(-2142 -109.386) translate(2276.012 221.097)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Alarmed"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7918"
                        d="M2271.496 188.913l30.307-16.533"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-2142 -109.386)"
                    />
                    <Path
                        data-name="Path 7919"
                        d="M0 0l-7.378 1.117L-4.934 5.6z"
                        transform="translate(-2142 -109.386) translate(2306.6 169.765)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group threatened" {...onPressLevel5}>
                    <Path
                        data-name="Path 7882"
                        d="M0 0l-.076 89.5 34.428-.022-.082-107.69z"
                        transform="translate(-2142 -109.386) translate(2310.104 172.662)"
                        fill="#d8a168"
                    />
                    <Path
                        data-name="Path 7883"
                        d="M2310.104 172.661l34.27-18.212.082 107.694-34.428.018z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-2142 -109.386)"
                    />
                    <Path
                        data-name="Path 7899"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h33.671a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0033.671 0z"
                        transform="translate(-2142 -109.386) translate(2310.092 200.771)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7900"
                        d="M2310.092 200.771a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h33.671a2.834 2.834 0 002.834-2.834v-3.631a2.835 2.835 0 00-2.834-2.835z"
                        fill="none"
                        stroke="#cc8236"
                        strokeWidth={0.25}
                        transform="translate(-2142 -109.386)"
                    />
                    <Text
                        transform="translate(-2142 -109.386) translate(2308.758 207.723)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Threatened"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7920"
                        d="M2308.914 169.765l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-2142 -109.386)"
                    />
                    <Path
                        data-name="Path 7921"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-2142 -109.386) translate(2344.017 150.615)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group afraid" {...onPressLevel6}>
                    <Path
                        data-name="Path 7885"
                        d="M0 0l.051 109.625 34.819-.036-.082-128.582z"
                        transform="translate(-2142 -109.386) translate(2346.808 152.541)"
                        fill="#d39454"
                    />
                    <Path
                        data-name="Path 7886"
                        d="M2346.808 152.541l34.788-18.992.082 128.582-34.819.035z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-2142 -109.386)"
                    />
                    <Path
                        data-name="Path 7902"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h18.916a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0018.916 0z"
                        transform="translate(-2142 -109.386) translate(2354.784 187.964)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7903"
                        d="M2354.784 187.964a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h18.916a2.834 2.834 0 002.835-2.834v-3.631a2.835 2.835 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#cc8236"
                        strokeWidth={0.25}
                        transform="translate(-2142 -109.386)"
                    />
                    <Text
                        transform="translate(-2142 -109.386) translate(2354.597 195.324)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Afraid"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7922"
                        d="M2346.048 148.945l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-2142 -109.386)"
                    />
                    <Path
                        data-name="Path 7923"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-2142 -109.386) translate(2381.15 129.796)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group terrified" {...onPressLevel7}>
                    <Path
                        data-name="Path 7888"
                        d="M0 0l-.076 130.034L34.352 130l-.38-147.858z"
                        transform="translate(-2142 -109.386) translate(2384.657 132.132)"
                        fill="#cc8236"
                    />
                    <Path
                        data-name="Path 7889"
                        d="M2384.657 132.132l33.973-17.858.379 147.858-34.428.035z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-2142 -109.386)"
                    />
                    <Path
                        data-name="Path 7896"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h23.676a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0023.676 0z"
                        transform="translate(-2142 -109.386) translate(2389.904 175.157)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7897"
                        d="M2389.904 175.157a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h23.676a2.834 2.834 0 002.835-2.834v-3.631a2.835 2.835 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#cc8236"
                        strokeWidth={0.25}
                        transform="translate(-2142 -109.386)"
                    />
                    <Text
                        transform="translate(-2142 -109.386) translate(2388.635 182.756)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"T"}
                        </TSpan>
                    </Text>
                    <Text
                        transform="translate(-2142 -109.386) translate(2392.38 182.756)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"errified"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7924"
                        d="M2384.032 128.535l30.308-16.533"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-2142 -109.386)"
                    />
                    <Path
                        data-name="Path 7925"
                        d="M0 0l-7.377 1.119L-4.934 5.6 0 0z"
                        transform="translate(-2142 -109.386) translate(2419.135 109.386)"
                        fill="#6f7175"
                    />
                </G>
            </G>
        </Svg>
    )
}