import { StyleSheet } from "react-native";
import { Page } from "../common/page";
import TeacherClasses from "./teacher-home-classes";

export const TeacherHome = () => {

  return (
    <>
      <Page style={styles.page}>
        <TeacherClasses />
      </Page>
    </>
  );
};

const styles = StyleSheet.create({
  page: {
    justifyContent: "flex-start",
  },
});
