import { useEffect, useState } from "react"
import { View, StyleSheet, TextInput } from "react-native"
import { Auth } from 'aws-amplify'
import { DefaultText } from "../common/default-text"
import { useReactiveVar } from "@apollo/client"
import { rvCurrentUser } from "../login/login-state"
import { showAlert } from "../common/universal-alert"
import QRCode from "react-qr-code"
import { DefaultButton } from "../common/default-button"
import { Page } from "../common/page"
import { Loading } from "../common/loading"
import { CommonStyles } from "../common/const"

const NOMFA = 'NOMFA'

export const AdminConfigMFA = () => {
    const [mfaSetup, setMfaSetup] = useState(false)
    const currentUser = useReactiveVar(rvCurrentUser)
    const [mfaEnabled, setMfaEnabled] = useState(false)
    const [loading, setLoading] = useState(true)
    const [totpCode, setTotpCode] = useState('')

    const updateMfaEnabled = async () => {
        try {
        const currentMFA = await Auth.getPreferredMFA(currentUser)
        setMfaEnabled(currentMFA !== NOMFA)
        } catch(e) {
            alert('An error has occured:\n' + e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        updateMfaEnabled()
    }, [])

    const setupMFA = async () => {
        setMfaSetup(true)
        await Auth.setupTOTP(currentUser).then((code) => {
            console.log(code)
            // You can directly display the `code` to the user or convert it to a QR code to be scanned.
            // E.g., use following code sample to render a QR code with `qrcode.react` component:
            //      import QRCode from 'qrcode.react';
            const str = "otpauth://totp/Switch4Schools:" + currentUser?.username + "?secret=" + code + "&issuer=" + "Switch4Schools"
            //      <QRCode value={str}/>

            showAlert({
                title: 'QR Code MFA',
                message: <View style={styles.qrContainer}><QRCode value={str} /></View>,
                // TODO: replace the host part to read from a config
                //message: `${envBulkSignUpClassLink}/JoinClass/studentJoinClass?bulkSignUpId=${newId}`,
                leftButtonText: 'Ok',
            })
        });
    }

    const verifyToken = async () => {
        try {
            await Auth.verifyTotpToken(currentUser, totpCode)
            await Auth.setPreferredMFA(currentUser, 'TOTP')
            await updateMfaEnabled()
        } catch (e) {
            alert('An error has occured:\n' + e)
        }
    }

    const disableMFA = async () => {
        await Auth.setPreferredMFA(currentUser, NOMFA)
        await updateMfaEnabled()
    }

    return (
        <Page>
            <DefaultText>Setup TOTP</DefaultText>
            {
                loading ?
                    <Loading isLoading />
                    :
                    mfaEnabled ?
                        <DefaultButton onPress={disableMFA}>Disable MFA</DefaultButton>
                        :
                        mfaSetup ?
                            (
                                <>
                                    <DefaultText>Enter software token code</DefaultText>
                                    <TextInput
                                        style={CommonStyles.textInput}
                                        placeholder="******"
                                        autoCorrect={false}
                                        autoCapitalize="none"
                                        keyboardType="decimal-pad"
                                        textContentType="telephoneNumber"
                                        onChangeText={setTotpCode}
                                    />
                                    <DefaultButton onPress={verifyToken}>Verify</DefaultButton>
                                </>
                            )
                            :
                            <DefaultButton onPress={setupMFA}>Setup MFA</DefaultButton>
            }
        </Page>
    )
}

const styles = StyleSheet.create({
    qrContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    }
})