
import { useReactiveVar } from '@apollo/client'
import { useFocusEffect } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import * as Linking from 'expo-linking'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Dimensions, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native'
import { UserGroup } from '../../../common/constants'
import { DecidaColors } from '../../../common/decida-colors'
import { AudioHandlerSwitches } from '../../common/audio-handler-switches'
import { BackButton, BackButtonOnPress } from '../../common/back-button'
import { rvRatings } from '../../common/common-state'
import { DefaultFontSize, IconSize } from '../../common/const'
import { DefaultText } from '../../common/default-text'
import ExportToPdfButton, { rvPrintMode } from '../../common/export-to-pdf-button'
import LessonPlanButton from '../../common/lesson-plan-button'
import { ScreenNames } from '../../common/screen-names'
import ScrollViewPrint from '../../common/scroll-view-print'
import ShareButton from '../../common/share-button'
import { SwitchCardFilterMethods } from '../../common/switch-card-filter-methods'
import { showAlert } from '../../common/universal-alert'
import { useResponsive } from '../../common/use-responsive'
import ViewResponsive from '../../common/view-responsive'
import { envWebUrl } from '../../env'
import { getLockDerviceRv, rvParentPortal, rvRedirectPages, rvUserGroup } from '../../login/login-state'
import { Emotion } from '../../models'
import { StudentRateCardSuggestion } from '../../student/student-rate-card-suggestion'
import { rvSuggestionRatingRequired } from '../../student/use-student-state'
import { TeacherDrawerNavigatorPamarList } from '../../teacher/teacher-route-param-types'
import { OnlineActiviesIcon } from '../activities/icons/online-activities-icon'
import { cards, cardsPublic } from '../emotion-cards/cards'
import { SwitchFilterIcon } from '../tools/icons/switch-filter-icon'
import { CardColorScheme, rvCard } from './card-color-scheme'
import CardHeaderTitle from './card-header-title'
import { commonCardStyles } from './emotion-card-image-sizes'
import FavouriteButton from './favourite-button'
import { SwitchCardRater } from './switch-card-rater'
import { UnderConstructionIcon } from './under-construction-icon'
import WhyDoesItWorkComponent from './why-does-it-work-component'
import { openUrlWebView } from '../../web-view/open-url-web-view'

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600

export const Card = ({ navigation: { navigate, setParams }, route: { params: { id, previousScreen, previousScreenId, previousCardId, previousEmotion, openLessonPlan } } }: StackScreenProps<TeacherDrawerNavigatorPamarList, 'Card'>) => {
    const userGroup = useReactiveVar(rvUserGroup)
    const showLessonPlan = userGroup === UserGroup.Teacher && openLessonPlan === 'true'
    const card = useMemo(() =>
        cards.find(card => card.cardId === id)
        , [id])
    const [showExtraTips, setShowExtraTips] = useState(showLessonPlan || false)
    const Ratings = useReactiveVar(rvRatings)
    const ratingSelection = Ratings?.find(r => r.cardId === card?.cardId)
    const parentPortal = useReactiveVar(rvParentPortal)
    const suggestionRating = useReactiveVar(rvSuggestionRatingRequired)
    const denyAccess = useReactiveVar(getLockDerviceRv())
    const url = useReactiveVar(rvRedirectPages)
    const { isNarrowScreen } = useResponsive()
    const isPrintMode = useReactiveVar(rvPrintMode)

    useEffect(() => {
        switch (userGroup) {
            case UserGroup.Teacher:
                setParams({ openLessonPlan: showExtraTips })
                break;

            default:
                const isPublicCard = cardsPublic.some((card) => card.cardId === id)

                if (openLessonPlan && openLessonPlan === 'true' ||
                    !isPublicCard
                ) {
                    setShowExtraTips(false)
                    Linking.openURL("/NotFound")
                }

                break;
        }
    }, [showExtraTips, openLessonPlan, userGroup])

    rvCard(card)
    if (!card) {
        return null
    }

    const {
        title,
        ExtraTips,
        Header,
        HeaderTitle,
        whyDoesItWorkText,
        PerfectFor,
        mainContainerStyle,
        Images,
        CardContent,
        colorScheme,
        BottomRow,
        onlineTips,
        lessonPlan,
        noRating,
        audioUrl,
        onlyLessonPlan,
        FilterComponent,
        cardContentTemplate
    } = card

    useEffect(() => {
        if ((previousCardId && previousScreen === 'lessons') ||
            previousScreen === 'switches-home' ||
            showLessonPlan) {
            setShowExtraTips(true)
        }
    }, [previousCardId, previousScreen])

    const handleGoBackToCard: BackButtonOnPress = (patchedGoback) => {
        switch (previousScreen) {
            case "switches-home":
                patchedGoback()
                break
            case 'activities':
                if (previousScreenId) {
                    navigate(ScreenNames.SwitchesActiviesDetailView, { activity: previousScreenId })
                } else if (previousCardId) {
                    navigate(ScreenNames.SwitchesActiviesDisplayAll)
                } else {
                    navigate(ScreenNames.SwitchesActivityMain)
                }
                break
            case 'explore':
                navigate(ScreenNames.SwitchesExploreAll)
                break
            case 'tools':
                navigate(ScreenNames.SwitchesToolsDisplayAll)
                break
            case 'lessons':
                if (previousCardId) {
                    navigate(ScreenNames.Card, { id: previousCardId, previousScreen: 'tools' })
                }
                break
            case 'emotion':
                if (previousEmotion) {
                    navigate(ScreenNames.SwitchesEmotionsDetailView, { emotion: previousEmotion })
                    return
                }
                switch (colorScheme) {
                    case CardColorScheme.HAPPY:
                        navigate(ScreenNames.SwitchesEmotionsDetailView, { emotion: Emotion.HAPPY })
                        break
                    case CardColorScheme.EXCITED:
                        navigate(ScreenNames.SwitchesEmotionsDetailView, { emotion: Emotion.EXCITED })
                        break
                    case CardColorScheme.SCARED:
                        navigate(ScreenNames.SwitchesEmotionsDetailView, { emotion: Emotion.SCARED })
                        break
                    case CardColorScheme.SAD:
                        navigate(ScreenNames.SwitchesEmotionsDetailView, { emotion: Emotion.SAD })
                        break
                    case CardColorScheme.ANGRY:
                        navigate(ScreenNames.SwitchesEmotionsDetailView, { emotion: Emotion.ANGRY })
                        break
                    case CardColorScheme.ANXIOUS:
                        navigate(ScreenNames.SwitchesEmotionsDetailView, { emotion: Emotion.ANXIOUS })
                        break
                }
                break
        }
    }

    const showPremiumPopup = () => {
        showAlert({
            title: 'Feature locked.',
            message: "Feature available with school subscription. For more information, click find out more.",
            leftButtonText: 'Ok',
            rightButtonText: 'Find out more',
            onRightButtonPress: () => openUrlWebView('https://www.switch4schools.com.au/learnmore')

        })
    }
    useFocusEffect(
        useCallback(() => {
            return () => {
                if (suggestionRating) {
                    showAlert({
                        title: 'Was this helpful?',
                        message: <StudentRateCardSuggestion card={card} />,
                        leftButtonText: 'Cancel',
                        onLeftButtonPress: () => {
                            rvSuggestionRatingRequired(false)
                        }
                    })
                }
            };
        }, [])
    )

    const showWhyDoesItWorkPopup = () => {
        showAlert({ title: "Why does this work?", leftButtonText: "Close", message: whyDoesItWorkText })
    }
    const showOnlineTips = () => {
        showAlert({ title: "If you’re running this activity online…", leftButtonText: "Close", message: onlineTips ? onlineTips : '' })
    }
    const displayExtraTips = () => {
        if (parentPortal) {
            showPremiumPopup()
        }
        else {
            setShowExtraTips(!showExtraTips)
        }
    }


    const handleBack: BackButtonOnPress = (patchedGoBack) => {
        if (showExtraTips && !onlyLessonPlan) {
            setShowExtraTips(false)
            return
        }

        if (onlyLessonPlan) {
            setShowExtraTips(false)
        }

        handleGoBackToCard(patchedGoBack)
    }

    const handleClickFilter = () => {
        switch (title) {
            case "Video Library":
                SwitchCardFilterMethods.showVideoFilter()
                break;
            case "Activity Sheets":
                SwitchCardFilterMethods.showActivitySheetsFilter()
                break;
            default:
                break;
        }

    }

    const toolsLessonPlansCard = card.cardId === '2d55ba38-acc3-4509-b0a5-a2c65fbdaf28' 

    return (
        <ScrollViewPrint style={[styles.container, { backgroundColor: toolsLessonPlansCard ? DecidaColors.GrayBackground : undefined }]}>
            <View style={styles.header}>

                <View style={[styles.backButtonContainer, { display: isPrintMode ? 'none' : 'flex' }]}>
                    <BackButton onPress={handleBack} style={{ fontSize: !windowWidthcondition ? 12 : DefaultFontSize, paddingTop: 10 }} />
                </View>

                {!isNarrowScreen && <CardHeaderTitle cardId={card.cardId} />}
                <ViewResponsive style={[styles.headerToolbarContainer, { display: isPrintMode ? 'none' : 'flex' }]} >
                    {ExtraTips && parentPortal &&
                        <LessonPlanButton onPress={displayExtraTips} />
                    }
                    {audioUrl && !showExtraTips && (
                        <AudioHandlerSwitches audioFile={{ uri: audioUrl }} audioTitle='' containerStyle={styles.imagecontainer} />
                    )}
                    {FilterComponent && (
                        <TouchableOpacity style={styles.imagecontainer} onPress={handleClickFilter}>
                            <View style={styles.filterIcons}>
                                <SwitchFilterIcon color={colorScheme} />
                            </View>
                            <DefaultText style={[styles.extraTipsText, { color: DecidaColors.GrayText }]}>
                                Filter
                            </DefaultText>
                        </TouchableOpacity>
                    )}
                    {(userGroup === UserGroup.Teacher || userGroup === UserGroup.Student || userGroup === UserGroup.NonTeachingStaff) && !onlyLessonPlan &&
                        <FavouriteButton cardID={card.cardId} colorScheme={colorScheme} />
                    }
                    {ExtraTips && userGroup === UserGroup.Teacher && !denyAccess && !onlyLessonPlan &&
                        <LessonPlanButton onPress={displayExtraTips} />
                    }
                    {ExtraTips && showExtraTips && (
                        <ExportToPdfButton />
                    )}
                    <ShareButton color={colorScheme} url={`${envWebUrl}/Card?id=${id}&previousScreen=${previousScreen}&openLessonPlan=${openLessonPlan}${previousScreenId ? `&previousScreenId=${previousScreenId}` : ""}`} />

                </ViewResponsive>
            </View>
            {isNarrowScreen && <CardHeaderTitle cardId={card.cardId} />}


            <ScrollViewPrint contentContainerStyle={styles.content} style={styles.scrollView}>
                <View style={[styles.mainContainer, { maxWidth: showExtraTips ? '100%' : 700 }, mainContainerStyle]}>
                    {
                        showExtraTips && ExtraTips ?
                            windowWidthcondition ?
                                <>
                                    {!lessonPlan
                                        ?
                                        <View style={styles.lessonPlanBanner}>

                                            <DefaultText style={styles.lessonPlanBannerText}>
                                                Lesson plan coming soon!
                                            </DefaultText>
                                            <View style={styles.bannerAlertIcon}>
                                                <UnderConstructionIcon />
                                            </View>

                                            <DefaultText>
                                                In the meantime…
                                            </DefaultText>
                                            <DefaultText style={styles.lessonPlanBannerText}>
                                                Below are some extra tips that might be helpful.
                                            </DefaultText>
                                        </View>
                                        :
                                        null}
                                    <ExtraTips />
                                </>
                                :
                                <View style={styles.extraTipsSmall}>
                                    {!lessonPlan
                                        ?
                                        <View style={styles.lessonPlanBanner}>
                                            <DefaultText style={styles.lessonPlanBannerText}>
                                                Lesson plan coming soon!
                                            </DefaultText>
                                            <View style={styles.bannerAlertIcon}>
                                                <UnderConstructionIcon />
                                            </View>
                                            <DefaultText>
                                                In the meantime…
                                            </DefaultText>
                                            <DefaultText style={styles.lessonPlanBannerText}>
                                                Below are some extra tips that might be helpful.
                                            </DefaultText>
                                        </View>
                                        :
                                        null}
                                    <ExtraTips />

                                </View>
                            :
                            <View style={styles.page}>
                                {windowWidthcondition ?
                                    <>
                                        <View style={styles.perfectFor}>
                                            <PerfectFor />
                                        </View>
                                        <View style={[styles.defaultContainer, { flexDirection: cardContentTemplate ? cardContentTemplate : isNarrowScreen ? 'column' : 'row', }]}>
                                            {(Images || whyDoesItWorkText !== "") && (
                                                <View style={styles.leftColumn}>
                                                    {Images ? <View style={commonCardStyles.image}><Images /></View> : <></>}
                                                    {whyDoesItWorkText === '' ? <View /> :
                                                        <WhyDoesItWorkComponent message={whyDoesItWorkText} />
                                                    }
                                                </View>
                                            )}
                                            <View style={styles.rightColumn}>
                                                <CardContent />
                                                {onlineTips === undefined ? <View /> :
                                                    <View style={styles.whydoesthisworkContainer}>
                                                        <TouchableOpacity style={styles.image} onPress={showOnlineTips}>
                                                            <View style={styles.whydoesthiswork}>
                                                                <OnlineActiviesIcon />
                                                            </View>
                                                            <DefaultText>
                                                                Online ideas
                                                            </DefaultText>
                                                        </TouchableOpacity>
                                                    </View>
                                                }
                                            </View>
                                        </View>
                                        <View style={styles.bottomRow}>
                                            <BottomRow />
                                        </View>
                                    </>
                                    :
                                    <View style={styles.smallScreen}>

                                        <View style={styles.smallScreenContent}>
                                            <PerfectFor />

                                            {Images ? <View style={styles.smallScreenImage}><Images /></View> : <></>}

                                            <CardContent />
                                            <BottomRow />
                                            {whyDoesItWorkText === '' ? <View /> :
                                                <WhyDoesItWorkComponent message={whyDoesItWorkText} />
                                            }
                                            {onlineTips === undefined ? <View /> :
                                                <View>
                                                    <View style={styles.onlineTipsContainer}>
                                                        <TouchableOpacity style={styles.image} onPress={showOnlineTips}>
                                                            <View style={styles.onelineTips}>
                                                                <OnlineActiviesIcon />
                                                            </View>
                                                            <DefaultText>
                                                                Online ideas
                                                            </DefaultText>
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                            }
                                            {(showExtraTips && ExtraTips) || noRating ?
                                                null
                                                :
                                                <SwitchCardRater rating={ratingSelection} card={card} />}
                                        </View>
                                    </View>
                                }
                            </View>
                    }
                </View>
                {windowWidthcondition ?
                    (showExtraTips && ExtraTips) || noRating ?
                        null
                        :
                        <SwitchCardRater rating={ratingSelection} card={card} />
                    :
                    null
                }
            </ScrollViewPrint>
            {FilterComponent && <FilterComponent />}
        </ScrollViewPrint>
    )
}

const styles = StyleSheet.create({
    page: {
        width: '100%',
        alignItems: 'center'
    },
    mainContainer: {
        width: '100%',
        alignItems: 'center'
    },
    container: {
        width: '100%',
        height: '100%',
    },
    content: {
        paddingHorizontal: 10,
        justifyContent: 'flex-start',
        width: '100%',
        alignItems: 'center',
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    header: {
        marginTop: 10,
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    backButtonContainer: {
        flex: 1,
    },
    headerToolbarContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    whydoesthiswork: {
        width: 100,
        height: 100,
    },
    onelineTips: {
        width: 100,
        height: 100,
    },
    favIconContainer: {
        width: IconSize,
        height: IconSize,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
    },
    extraTipsText: {
        fontSize: windowWidthcondition ? 15 : 10,
        marginTop: 5

    } as TextStyle,
    imagecontainer: {
        padding: windowWidthcondition ? 10 : 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    whydoesthisworkContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    onlineTipsContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    leftColumn: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    rightColumn: {
        flex: 1,
    },
    bottomRow: {
        width: '100%',
    },
    defaultContainer: {
        gap: 10,
    },
    smallScreen: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 300,
    },
    smallScreenImage: {
        width: 300,
        height: 300,
    },
    smallScreenContent: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    scrollView: {

    },
    perfectFor: {
        width: '100%',
    },
    extraTipsSmall: {
        marginHorizontal: 20,
    },
    lessonPlanBanner: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
    } as ViewStyle,
    lessonPlanBannerText: {
        fontWeight: '600',
        paddingVertical: 20,
        textAlign: 'center'

    },
    bannerAlertIcon: {
        padding: 10,
        margin: 20,
        width: windowWidthcondition ? 200 : 100,
        height: windowWidthcondition ? 200 : 100,
    },
    filterIcons: {
        width: IconSize,
        height: IconSize,
        padding: 5,
        alignItems: 'center'
    }
})
