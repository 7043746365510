import { gql, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import _ from 'lodash'
import { StyleSheet, View } from 'react-native'
import { CreateGoClassStudentCheckInMutation, CreateGoClassStudentCheckInMutationVariables, CreateTeacherCheckInMutation, CreateTeacherCheckInMutationVariables, CustomCreateStaffCheckInMutation, CustomCreateStaffCheckInMutationVariables, Emotion, GetClassQuery, GetClassQueryVariables, GetRandomWellbeingQuestionQuery, GetRandomWellbeingQuestionQueryVariables, GetStudentQuery, GetStudentQueryVariables, ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables, StudentCheckIn, UpsertStudentCheckInMutation, UpsertStudentCheckInMutationVariables, WellbeingResponseUserType } from '../../common/API'
import { CheckInUserGroup } from '../../common/constants'
import { convertToTwoDecimalPlaces } from '../../common/convert-to-two-decimal-places'
import { createGoClassStudentCheckIn, createTeacherCheckIn, customCreateStaffCheckIn, upsertStudentCheckIn } from '../../common/graphql/mutations'
import { getClass, getRandomWellbeingQuestion, getStudent, listStudentCheckInsByCheckInSessionId } from '../../common/graphql/queries'
import { rvNetInfo } from '../common/common-state'
import { BreakPoints } from '../common/const'
import { getIntensityLevel } from '../common/get-intensity-level'
import { rvIsLoading } from '../common/loading'
import { CLIENT_EVENT, createClientLog } from '../common/log-client-event'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { showGenericErrorAlert, showOfflineAlert } from '../common/universal-alert'
import { lowRes, useResponsive } from '../common/use-responsive'
import { emotionsIcons } from '../icon/emotion-icons'
import { useNonTeachingStaffState } from '../student/use-non-teaching-staff-state'
import { rvCurrentStudentLastStudentCheckIn } from '../student/use-student-state'
import { rvTeacherCurrentTeacher } from '../teacher/teacher-state'
import { CheckInHeader } from './check-in-header'
import { rvCheckInState, rvCurrentWellbeingQuestion } from './check-in-state'

export const CheckInEmotionalIntensity = ({ navigation: { navigate }, route: { params } }: StackScreenProps<{ [k: string]: { avatarName?: string, classID?: string, user?: CheckInUserGroup } }>) => {
    const { emotion, studentID = '', tiredness, checkinsessionID = '', eating } = useReactiveVar(rvCheckInState)
    const currentTeacher = useReactiveVar(rvTeacherCurrentTeacher)
    const { currentNonTeachingStaff } = useNonTeachingStaffState()

    const { data: classResponse } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: params?.classID || "" }, skip: !params.classID })
    const currentClass = classResponse?.getClass

    const { data: studentResponse } = useQuery<GetStudentQuery, GetStudentQueryVariables>(gql`${getStudent}`, { variables: { id: studentID }, skip: !studentID })
    const currentStudent = studentResponse?.getStudent

    const [getRandomWellbeingQuestionQuery] = useLazyQuery<GetRandomWellbeingQuestionQuery, GetRandomWellbeingQuestionQueryVariables>(gql`${getRandomWellbeingQuestion}`, { fetchPolicy: 'network-only' })

    const { data: studentCheckInByCheckInSessionIdResponse } = useQuery<ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables>(gql`${listStudentCheckInsByCheckInSessionId}`, { variables: { checkinsessionID: checkinsessionID || '', limit: 5000 }, skip: !checkinsessionID })
    const currentStudentCheckInSession = studentCheckInByCheckInSessionIdResponse?.listStudentCheckInsByCheckInSessionId?.items.filter((item) => item?._deleted !== true).find((item) => item?.studentID === studentID) as StudentCheckIn

    const netInfoState = useReactiveVar(rvNetInfo)
    const { scaleRatio, isNarrowScreen } = useResponsive()
    const [createGoClassStudentCheckInMutation] = useMutation<CreateGoClassStudentCheckInMutation, CreateGoClassStudentCheckInMutationVariables>(gql`${createGoClassStudentCheckIn}`,)
    const [upsertStudentCheckInMutation] = useMutation<UpsertStudentCheckInMutation, UpsertStudentCheckInMutationVariables>(gql`${upsertStudentCheckIn}`,)
    const [createTeacherCheckInMutation] = useMutation<CreateTeacherCheckInMutation, CreateTeacherCheckInMutationVariables>(gql`${createTeacherCheckIn}`,)
    const [createStaffCheckInMutation] = useMutation<CustomCreateStaffCheckInMutation, CustomCreateStaffCheckInMutationVariables>(gql`${customCreateStaffCheckIn}`,)

    if (emotion == undefined || studentID == undefined || tiredness == undefined) {
        showGenericErrorAlert()
        navigate(ScreenNames.CheckInStart, { classID: params?.classID })
        return null
    }

    if (!currentClass && !params?.user) {
        return null
    }

    const back = () => {
        navigate(ScreenNames.CheckInEmotionalWheel, { classID: params?.classID, user: params?.user })
    }

    const onPressIntensity = async (emotionIntensityPercentage: number) => {
        if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

        rvIsLoading(true)

        switch (params.user) {
            case CheckInUserGroup.Teacher:
                await handleTeacherCheckIn(emotionIntensityPercentage)
                await getRandomQuestionnaireAndNavigate(ScreenNames.CheckInFinish, { user: CheckInUserGroup.Teacher })
                break;
            case CheckInUserGroup.Student:
                await handleStudentCheckIn(emotionIntensityPercentage)

                if (currentClass?.toggleRequestForChat) {
                    await getRandomQuestionnaireAndNavigate(ScreenNames.CheckInRequestChatWithTeacher, { classID: currentClass?.id || "", user: CheckInUserGroup.Student })
                } else {
                    await getRandomQuestionnaireAndNavigate(ScreenNames.CheckInFinish, { classID: currentClass?.id || "", user: CheckInUserGroup.Student })
                }
                break
            case CheckInUserGroup.NonTeachingStaff:
                await handleStaffCheckIn(emotionIntensityPercentage)
                await getRandomQuestionnaireAndNavigate(ScreenNames.CheckInFinish, { user: CheckInUserGroup.NonTeachingStaff })
                break

            default:
                break;
        }

        rvIsLoading(false)

    }

    const getRandomQuestionnaireAndNavigate = async (screenName: string, navigationParams?: { classID?: string, user: CheckInUserGroup }) => {
        let variableType: WellbeingResponseUserType
        let variableUserID = ""

        switch (params?.user) {
            case CheckInUserGroup.Teacher:
                variableType = WellbeingResponseUserType.TEACHER
                variableUserID = currentTeacher?.id || ""
                break;
            case CheckInUserGroup.NonTeachingStaff:
                variableType = WellbeingResponseUserType.NON_TEACHING_STAFF
                variableUserID = currentNonTeachingStaff?.id || ""
                break

            default:
                variableType = WellbeingResponseUserType.STUDENT
                variableUserID = studentID
                break;
        }
        const question = await getRandomWellbeingQuestionQuery({
            variables: {
                classID: params?.classID || "",
                type: variableType,
                userID: variableUserID
            }
        })

        if (question.data?.getRandomWellbeingQuestion) {
            rvCurrentWellbeingQuestion(question.data?.getRandomWellbeingQuestion)
            navigate(ScreenNames.CheckInWellbeingQuestion, { classID: currentClass?.id, user: params?.user })
        } else {
            rvCurrentWellbeingQuestion(null)
            navigate(screenName, navigationParams || {})
        }

    }

    const handleTeacherCheckIn = async (emotionIntensityPercentage: number) => {
        try {
            if (!currentTeacher) {
                showGenericErrorAlert()
                return
            }

            await createTeacherCheckInMutation({
                variables: {
                    input: {
                        emotion,
                        emotionIntensityPercentage,
                        tiredness,
                        schoolID: currentTeacher?.schoolID,
                        teacherID: currentTeacher?.id,
                    }
                }
            })

        } catch (error) {
            console.error(error)
            createClientLog({
                event: CLIENT_EVENT.START_CHECKIN_FAILED,
                area: "Check in emotional intensity page",
                action: `Teacher checkin on checkInEmotionalIntensity page`,
                payload: {
                    group: "teacher",
                    nickname: currentTeacher?.cognitoUsername,
                    error: error
                }
            })
            showGenericErrorAlert()
        }
    }

    const handleStaffCheckIn = async (emotionIntensityPercentage: number) => {
        try {
            if (!currentNonTeachingStaff) {
                showGenericErrorAlert()
                return
            }

            await createStaffCheckInMutation({
                variables: {
                    input: {
                        emotion,
                        emotionIntensityPercentage,
                        tiredness,
                        schoolID: currentNonTeachingStaff?.schoolID,
                        staffID: currentNonTeachingStaff?.id,
                    }
                }
            })

        } catch (error) {
            console.error(error)
            createClientLog({
                event: CLIENT_EVENT.START_CHECKIN_FAILED,
                area: "Check in emotional intensity page",
                action: `Staff checkin on checkInEmotionalIntensity page`,
                payload: {
                    group: "staff",
                    nickname: currentNonTeachingStaff?.cognitoUsername,
                    error: error
                }
            })
            showGenericErrorAlert()
        }
    }

    const handleStudentCheckIn = async (emotionIntensityPercentage: number) => {
        try {
            if (currentClass?.goMode) {
                const { data } = await createGoClassStudentCheckInMutation({
                    variables: {
                        input: {
                            emotion,
                            emotionIntensityPercentage: convertToTwoDecimalPlaces(emotionIntensityPercentage),
                            tiredness,
                            studentID,
                            classID: currentClass?.id,
                            eating: eating || null
                        }
                    }
                })
                rvCurrentStudentLastStudentCheckIn(data?.createGoClassStudentCheckIn)
            } else {
                const { data } = await upsertStudentCheckInMutation({
                    variables: {
                        input: {
                            studentID,
                            emotion,
                            emotionIntensityPercentage: convertToTwoDecimalPlaces(emotionIntensityPercentage),
                            tiredness,
                            studentCheckinID: currentStudentCheckInSession ? currentStudentCheckInSession.id : undefined,
                            checkInSessionID: checkinsessionID,
                            classID: params.classID || currentClass?.id || "",
                            eating: eating || null
                        }
                    }
                })
                rvCurrentStudentLastStudentCheckIn(data?.upsertStudentCheckIn)
            }

            createClientLog({
                event: CLIENT_EVENT.STUDENT_START_CHECKIN_SUCCESS,
                area: "Check in emotional intensity page",
                action: `Student checkin on ${currentClass?.id}: ${currentClass?.name} ${currentClass?.toggleRequestForChat ? ", and requested for chat" : "."}`,
                payload: {
                    group: "student",
                    nickname: currentStudent?.cognitoUsername,
                }
            })
        } catch (e) {
            console.error(e)
            createClientLog({
                event: CLIENT_EVENT.STUDENT_START_CHECKIN_FAILED,
                area: "Check in emotional intensity page",
                action: `Student checkin on ${currentClass?.id}: ${currentClass?.name} ${currentClass?.toggleRequestForChat ? ", and requested for chat" : "."}`,
                payload: {
                    group: "student",
                    nickname: currentStudent?.cognitoUsername,
                    error: JSON.stringify(e)
                }
            })
            showGenericErrorAlert()
        }


    }

    const emotionIcons = emotionsIcons[emotion]

    const Icon = emotionIcons.Icon
    const intensityLevel = (params?.user === CheckInUserGroup.Teacher || params?.user === CheckInUserGroup.NonTeachingStaff) ? 7 : getIntensityLevel(currentClass?.emotionIntensityLevel)
    const Intensity = emotionIcons[intensityLevel]

    return (
        <Page style={lowRes ? styles.pageLowres : styles.page}>
            <CheckInHeader
                back={back}
                headerText={`How ${_.startCase(Emotion[emotion].toLowerCase())}?`}
                studentId={studentID}
            />
            <View style={[styles.container, { width: lowRes ? 450 * scaleRatio : undefined, paddingTop: lowRes ? 60 : undefined, alignItems: 'center', justifyContent: 'center', height: lowRes ? '100%' : undefined, maxHeight: !isNarrowScreen ? undefined : '100%' }]}>
                <View style={styles.intensityContainer}>
                    {Intensity && (
                        <View style={{ flex: 1, width: 'auto', flexGrow: 1 }}>
                            <View style={styles.iconContainer}>
                                <Icon />
                            </View>
                            <Intensity onPress={onPressIntensity} />
                        </View>
                    )}
                </View>
            </View>
        </Page >
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
    },
    pageLowres: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    container: {
        flex: 1,
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 40,
    },
    intensityContainer: {
        flex: 1,
        width: '90%',
        maxWidth: '100%',
        minWidth: BreakPoints.XS,
    },
    iconContainer: {
        position: 'absolute',
        top: 0,
        left: '25%',
        width: '50%',
        aspectRatio: 1,
        zIndex: 10,
        maxHeight: 100,
        alignItems: 'flex-start'
    },
})