import { DefaultButton } from "./default-button";

import React from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { IconCheckIn } from "../icon/icon-check-in";
import { IconEndCheckIn } from "../icon/icon-end-check-in";
import { DecidaColors } from "../../common/decida-colors";
import { DefaultText } from "./default-text";

type Props = {
    startCheckIn: () => void;
    closeCheckIn: () => void;
    isCreate: boolean;
    submiting: boolean;
    titleButton: string;
}

export const ButtonSessionCommon: React.FC<Props> = ({
    startCheckIn,
    closeCheckIn,
    isCreate,
    submiting,
    titleButton,
}) => {
    return (
        <DefaultButton
            style={
                isCreate
                    ? [styles.button, submiting ? styles.submittingBtn : styles.startCheckInButton]
                    : [styles.button, submiting ? styles.submittingBtn : styles.endCheckInButton]}
            onPress={isCreate ? startCheckIn : closeCheckIn}
        >
            {
                isCreate ? (
                    <IconCheckIn stylefill={submiting ? DecidaColors.Gray : DecidaColors.Green} />
                ) : (
                    <IconEndCheckIn stylefill={submiting ? DecidaColors.Gray : DecidaColors.Orange} />
                )
            }
            <View testID="button-session" style={styles.textContainer}>
                <DefaultText style={styles.buttonText}>{titleButton}</DefaultText>
            </View>
        </DefaultButton >
    );
};


const styles = StyleSheet.create({
    button: {
        height: 80,
        borderRadius: 5,
        borderWidth: 2,
        backgroundColor: DecidaColors.White,
        borderColor: DecidaColors.Green,
        paddingLeft: 40,
        paddingRight: 40,
        margin: 10,
    } as ViewStyle,
    textContainer: {
        width: 180,
    },
    buttonText: {
        color: DecidaColors.Black,
        paddingLeft: 10,
        fontWeight: '700',
        textAlign: 'center',
    },
    endCheckInButton: {
        borderColor: DecidaColors.Orange,
    } as ViewStyle,
    startCheckInButton: {
        borderColor: DecidaColors.Green,
    },
    submittingBtn: {
        backgroundColor: DecidaColors.White,
        borderColor: DecidaColors.Gray
    },
});