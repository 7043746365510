import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import DefaultTextWithCustomLink from '../../../common/default-text-with-custom-link'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { BrainstormBlitzIcon } from '../../icons/brainstorm-blitz-icon'
import { BrainstormBlitzIconInverted } from '../../icons/brainstorm-blitz-icon-inverted'
import { Worksheets } from '../../../../common/worksheets-imports'

export const SwitchesActivitiesCreativityBrainstormBlitz: CardTemplate = {
    cardId: "8a34e9fd-3a9d-4ff6-a253-47fac5e7491e",
    title: 'Brainstorm blitz',
    Header: BrainstormBlitzIconInverted,
    Icon: BrainstormBlitzIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Practising creative thinking and group collaboration"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Participants:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Can play individually, with a friend, or in a group"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Writing implement (pen or pencil) "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Paper "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Timer "} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Set the timer for 2-3 minutes."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'Have one person pick a random "starting object" from the attached list in the resources section.'} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'Each participant writes the starting object at the top of their page.'} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'When the timer starts, each person comes up with as many alternate uses for that object as fast as they can. For example, if the starting object is "tissue paper," you could use it to wrap a present or as a parachute for a toy soldier.'} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'When the timer stops, each participant puts down their writing implement and the person who came up with the most alternate uses wins that round.'} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={'The winner of the round gets to pick the starting object for the next round. '} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Rules:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Make sure that the uses are not too similar. For example, tissue paper can be used to fold a bird and can also be used to fold a flower. Instead, you would just say, ‘it can be used to make origami’."} />

        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.CREATIVITY,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Additional Resources:"} />
            <DefaultTextWithCustomLink text='Brainstorm blitz worksheet.' link={Worksheets.brainstormBlitz.uri} />
        </>

    ),
    audioUrl: "",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.CREATIVITY],
    action: [CardActionType.Action],
    level: []

}

