import { ApolloClient, ApolloLink, InMemoryCache, makeVar } from "@apollo/client";
import { ConnectionState } from '@aws-amplify/pubsub';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NetInfoState } from "@react-native-community/netinfo";
import { Auth } from "aws-amplify";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { CheckInSession, Class, Favourite, StudentCheckIn, SwitchRating } from "../../common/API";
import awsconfig from "../aws-exports";
import { rvTeacherSelectCurrentClassId } from "../teacher/teacher-state";
import { DayScheduleModels } from "./const";
import { navigate } from "./root-navigation";
import { ScreenNames } from "./screen-names";
import { SwitchCardFilterType } from "./switch-card-filter";

export const currentClassKey = 'currentClassId'

export const setCurrentClassIdTeacher = async (classId: string | undefined) => {

  try {
    rvTeacherSelectCurrentClassId(classId)
    const jsonValue = JSON.stringify(classId)
    await AsyncStorage.setItem(currentClassKey, jsonValue)
    navigate(ScreenNames.TeacherHome)
  } catch (e) {
    console.log(e)
  }
}


export const getCurrentClassIdFromStorageTeacher = async () => {

  try {
    const classId = JSON.parse((await AsyncStorage.getItem(currentClassKey)) || '')
    rvTeacherSelectCurrentClassId(classId || undefined)
  } catch (e) {
    // error reading value
  }
}

export const rvCurrentClass = makeVar<Class | undefined>(undefined)
export const rvRatings = makeVar<SwitchRating[] | undefined>([])
export const rvCheckinSessions = makeVar<CheckInSession[]>([])
export const rvLastCheckinSession = makeVar<CheckInSession | undefined>(undefined)
export const rvPreviousStudentCheckins = makeVar<StudentCheckIn[]>([])
export const rvCurrentClassSchedule = makeVar<DayScheduleModels>({})
export const rvFavouriteSwitches = makeVar<Favourite[] | undefined>(undefined)
export const rvTeacherAbsenceMode = makeVar<boolean>(false)
export const rvNetInfo = makeVar<NetInfoState | undefined>(undefined)
export const rvUpdateAvailable = makeVar<boolean>(false)
export const rvSubscriptionState = makeVar<ConnectionState | undefined>(undefined)
export const rvFilterExploreSwitchesModal = makeVar<boolean>(false)
export const rvFilterVideoLibraryModal = makeVar<boolean>(false)
export const rvFilterActivitySheetsModal = makeVar<boolean>(false)
export const rvFilterSwitchesData = makeVar<SwitchCardFilterType>({
  main: [] as SwitchCardFilterType["main"],
  time: [] as SwitchCardFilterType["time"],
  people: [] as SwitchCardFilterType["people"],
  level: [] as SwitchCardFilterType["level"],
  place: [] as SwitchCardFilterType["place"],
  emotion: [] as SwitchCardFilterType["emotion"],
  category: [] as SwitchCardFilterType["category"],
  thoughtOrAction: [] as SwitchCardFilterType["thoughtOrAction"]
})
export const rvCurrentSwitchAudioPlaying = makeVar("")

const url = awsconfig.aws_appsync_graphqlEndpoint;
const region = awsconfig.aws_appsync_region;
const auth = {
  type: "AMAZON_COGNITO_USER_POOLS" as "AMAZON_COGNITO_USER_POOLS",
  jwtToken: async () =>
    (await Auth.currentSession()).getAccessToken().getJwtToken(), // Required when you use Cognito UserPools OR OpenID Connect. Token object is obtained previously
};

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }) as unknown as ApolloLink,
  createSubscriptionHandshakeLink({ url, region, auth }) as unknown as ApolloLink
]);

export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
