import { View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { RespondIcon } from '../../explore/src/respond-icon'
import { RespondIconInverted } from '../../explore/src/respond-icon-inverted'
import { RespondImage1 } from './src/respond-image1'

export const SwitchesEmotionsAngryRespond: CardTemplate = {
    cardId: "d957c369-caed-4ac7-80ce-6db8937f903a",
    title: 'Respond',
    Header: RespondIconInverted,
    Icon: RespondIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"A quick in the moment check to avoid losing your cool or reacting in an overly aggressive way."} />
        </>
    ),
    Images: () => (
        <>
            <RespondImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Ask yourself, “Am I reacting or responding right now?” Reacting means that you are being controlled by your emotions, while responding means you are choosing how you behave and think."} />
                <DefaultTextWithDownArrow textdata={"Try to shake off anything that is offending you and don’t take things personally."} />
                <DefaultTextWithDownArrow textdata={"If you are angry, then you are not thinking clearly or acting intelligently."} />
                <DefaultTextWithoutArrows textdata={"Be patient and wait until you have calmed down before speaking."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: 'When you are feeling angry it’s easy to react and lose control, often making the situation worse. If you respond calmly and stay in control, you can often resolve the situation quicker and with less mental energy.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Describe the difference between reactive and responsive behaviour. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• List personal trigger “anger buttons”. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Explain why responsive behaviour is a better choice. </DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Respond switch and talk through the steps listed. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of the "You and Your Ape" tool as a way to understand how the brain processes information differently through System 1 (reactive) and System 2 (responsive).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that System 1 is fast, automatic, and reactive, while System 2 is slower and more considered, leading to responsive behaviour.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Sorting Reactive vs Responsive Behaviours (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Reactive vs Responsive Worksheet and ask students to sort the examples into reactive and responsive behaviours.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief the activity, discussing why certain behaviours are considered reactive or responsive. Allow students to provide context to understand the importance of judgment in different situations.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Brainstorming Impacts of Reactive Behaviour (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Brainstorm with students the impacts of reactive behaviour. Look for responses such as lack of respect, hurting others' feelings, disrupting the class, feeling regretful, and breaking things that are hard to fix.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss why yelling at someone is considered a bad decision and explore the positive outcomes of making better choices.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Identifying Anger Triggers (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to complete the My Anger Buttons Worksheet to identify things or situations likely to trigger an angry, reactive response.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite a student to share their anger buttons, and ask others to raise their hands if they have similar triggers. Continue until all unique buttons are shared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief to emphasise the importance of being conscious of shared triggers to avoid causing situations that make individuals or others angry.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points about reactive and responsive behaviour.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the idea that recognising when to react or respond is a superpower that allows individuals to make better choices.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to be mindful of their reactions and strive to choose responsive behaviour in various situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- If students have their own device, ask them to find the switch in the Switch4Schools app.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='"You and Your Ape" tool' link={`/Card?id=5bfd0301-de2f-4343-a659-38e2272721f4&previousScreenId=${SwitchesEmotionsAngryRespond.cardId}`} /> </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Reactive vs Responsive Worksheet' link={Worksheets.reactiveAndResponsive.uri} /> </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='My Anger Buttons Worksheet' link={'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/My+Anger+Buttons+Worksheet.pdf'} /> </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Switch4Schools app (optional)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard and markers</DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The difference between reacting and responding -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.psychologytoday.com/au/blog/the-power-prime/202110/the-difference-between-reacting-and-responding' link='https://www.psychologytoday.com/au/blog/the-power-prime/202110/the-difference-between-reacting-and-responding' />

                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Respond.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Angry]
}

