import { StyleSheet, Text, View } from 'react-native'
import InputDefault from '../common/input-default'
import { useState } from 'react'
import { DefaultButton } from '../common/default-button'
import { gql, useMutation } from '@apollo/client'
import { createInvoice, updateInvoice } from '../../common/graphql/mutations'
import { CreateInvoiceMutation, CreateInvoiceMutationVariables, Invoice, UpdateInvoiceMutation, UpdateInvoiceMutationVariables } from '../../common/API'
import { showAlert } from '../common/universal-alert'
import { listInvoiceBySchoolID } from '../../common/graphql/queries'
import { CommonStyles } from '../common/const'
import { DecidaColors } from '../../common/decida-colors'


const AdminSchoolInvoiceForm = ({ schoolID, invoice, onCancel }: { schoolID: string, invoice?: Invoice, onCancel: () => void }) => {
    const [invoiceName, setInvoiceName] = useState(invoice?.name || '')
    const [invoiceUrl, setInvoiceUrl] = useState(invoice?.url || '')

    const [createInvoiceMutation] = useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(gql`${createInvoice}`, { refetchQueries: [gql`${listInvoiceBySchoolID}`] });
    const [updateInvoiceMutation] = useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(gql`${updateInvoice}`);

    const handleSave = () => {

        try {
            if (!invoiceUrl) {
                showAlert({
                    title: "Save invoice",
                    message: "Please insert invoice url",
                    leftButtonText: "Ok"
                })
                return
            }

            if (invoice) {
                updateInvoiceMutation({
                    variables: {
                        input: {
                            id: invoice.id,
                            name: invoiceName,
                            url: invoiceUrl,
                            _version: invoice?._version
                        }
                    }
                })

            } else {
                createInvoiceMutation({
                    variables: {
                        input: {
                            schoolID,
                            url: invoiceUrl,
                            name: invoiceName,
                        }
                    }
                })
            }

            toggleEditMode()
            
        } catch (error) {
            console.log("Something went wrong", error)
        }

    }

    const toggleEditMode = () => {
        onCancel()
    }

    const isFormNotValid = () => {
        if (invoice) {
            // edit an invoice
            return invoiceName === invoice.name && invoiceUrl === invoice.url
        }

        return !invoiceUrl || !invoiceName
    }

    return (
        <View>
            <InputDefault customInputStyle={CommonStyles.textInput} label='Invoice name' value={invoiceName} onChangeText={setInvoiceName} />
            <InputDefault customInputStyle={CommonStyles.textInput} label='Invoice url' value={invoiceUrl} onChangeText={setInvoiceUrl} />
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <DefaultButton onPress={onCancel} style={styles.cancelButton}>
                    Cancel
                </DefaultButton>
                <DefaultButton disabled={isFormNotValid()} onPress={handleSave} style={styles.saveButton}>
                    Save
                </DefaultButton>
            </View>
        </View>
    )
}

export default AdminSchoolInvoiceForm

const styles = StyleSheet.create({
    saveButton: {
        flex: 1,
        width: '100%',
        marginLeft: 10,
    },
    cancelButton: {
        width: '100%',
        flex: 0.3,
        backgroundColor: DecidaColors.Red
    },
})  
