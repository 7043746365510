import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { EatingStatus, HasStudentCheckedInTodayForClassQuery, HasStudentCheckedInTodayForClassQueryVariables } from '../../common/API'
import { CheckInUserGroup, UserGroup } from '../../common/constants'
import { hasStudentCheckedInTodayForClass } from '../../common/graphql/queries'
import { rvNetInfo } from '../common/common-state'
import { Loading } from '../common/loading'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { showOfflineAlert } from '../common/universal-alert'
import { useResponsive } from '../common/use-responsive'
import IconEatingOptions from '../icon/icon-eating-options'
import { rvUserGroup } from '../login/login-state'
import { CheckInHeader } from './check-in-header'
import { rvCheckInState } from './check-in-state'

const CheckInEating = ({ navigation: { navigate, goBack }, route: { params } }: StackScreenProps<{ [k: string]: { classID?: string, user?: CheckInUserGroup } | undefined }>) => {

    const { lowRes } = useResponsive()
    const checkInState = useReactiveVar(rvCheckInState)
    const netInfoState = useReactiveVar(rvNetInfo)
    const userGroup = useReactiveVar(rvUserGroup)

    const { data, loading } = useQuery<HasStudentCheckedInTodayForClassQuery, HasStudentCheckedInTodayForClassQueryVariables>(gql`${hasStudentCheckedInTodayForClass}`, { variables: { classID: params?.classID || "", studentID: checkInState?.studentID || "" }, fetchPolicy: 'network-only' })

    useEffect(() => {
        if (data?.hasStudentCheckedInTodayForClass) {
            navigate(ScreenNames.CheckInEmotionalWheel, { classID: params?.classID, user: params?.user });
        }

    }, [data?.hasStudentCheckedInTodayForClass])

    const back = (patchedGoback: () => void) => {
        if (userGroup === UserGroup.Student) {
            patchedGoback()
            return
        }
        if (params?.user === CheckInUserGroup.Teacher) {
            navigate(ScreenNames.TeacherWellbeing)
        } else if (params?.user === CheckInUserGroup.NonTeachingStaff) {
            patchedGoback()
        } else {
            navigate(ScreenNames.CheckInTirednessScale, { classID: params?.classID, user: params?.user })
        }
    }

    const onEatingOptionPress = (eatingStatus: EatingStatus) => {
        if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()
        rvCheckInState({
            ...checkInState,
            eating: eatingStatus,
        })
        navigate(ScreenNames.CheckInEmotionalWheel, { classID: params?.classID, user: params?.user })
    }

    return (
        <Page style={lowRes ? styles.pageLowRes : styles.page}>
            {loading ? (
                <Loading isLoading={loading} />
            ) : (
                <>
                    <CheckInHeader
                        back={back}
                        headerText='Have you eaten today?'
                        studentId={checkInState.studentID}
                    />
                    <View style={styles.eating}>
                        <IconEatingOptions onPress={onEatingOptionPress} />
                    </View>
                </>
            )}
        </Page>
    )
}
export default CheckInEating


const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',

    },
    pageLowRes: {
        paddingTop: 20
    },
    eating: {
        flex: 1,
        width: '100%',
        justifyContent: 'space-evenly',
        maxHeight: 320,
        padding: 20,
        paddingTop: 40
    },
})