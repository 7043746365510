import { View } from 'react-native'
import { DefaultTextWithDownArrow } from '../../../common/default-text-with-down-arrow'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CraftIcon } from '../../icons/craft-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { CraftIconInverted } from '../../icons/craft-icon-inverted'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'

export const SwitchesActivitiesHealthyHabitsCraftTime: CardTemplate = {
    cardId: "9e93c0cc-ae50-477f-bb54-53205604da8c",
    title: 'Craft time',
    Header: CraftIconInverted,
    Icon: CraftIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Encouraging, observation, patience, focus and acute hand-eye coordination."} />

            <DefaultTextWithoutArrowsBold textdata={"Choose some materials that you can build something with."} />
            <DefaultTextWithoutArrowsItallic textdata={"These could be matchsticks, glue, paper, scissors, sticky-tape, straws, dried spaghetti, sticky notes, card or anything else that could be used as building materials."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={`Creating a time limit can be helpful to inspire creativity and competition.`} />
            <DefaulTextWithTipsIcon textdata={"This can easily be turned into a group activity, and with a time limit can turn into a great fun, team building activity."} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={""} />
                <DefaultTextWithDownArrow textdata={"Notice something In your environment that you would like to replicate, for example, a chair, a table, a person or a tree."} />
                <DefaultTextWithDownArrow textdata={"Take the time to carefully construct your model. "} />
                <DefaultTextWithoutArrows textdata={"Once finished, put it on display somewhere that you can talk about it with others."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.HEALTHY_HABITS,
    whyDoesItWorkText: 'Crafting in general can facilitate a state of “flow” or a state characterised by complete absorption in an activity. The same concept can be extended to knitting, crochet, folding laundry, or any activity where the child forgets their external surroundings.',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Craft+time.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.HEALTHY_HABITS],
    action: [CardActionType.Action],
    level: []
}

