import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import SwitchCardVideo from '../../common/switch-card-video'

const socialIntelligenceVideo1: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/qpnNsSyDw-g",
    title: ""
}

const socialIntelligenceVideo2: Videos = {
    id: "2",
    url: "https://www.youtube.com/embed/tJsGGsPNakw",
    title: ""
}

export const SwitchesLessonPlansSocialIntelligence: CardTemplate = {
    cardId: "428ff466-127b-452d-ba01-6c468475f92e",
    title: 'Social intelligence',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define the concept of social intelligence, develop skills in observing and assisting others in coping with emotions, and apply these skills in real life scenarios.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Welcome the students and create a positive and inclusive learning environment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Engage the students in a brief discussion about the importance of emotions and the impact they can have on individuals and their interactions with others.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Social Intelligence (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that social intelligence is the ability to understand and interact effectively with others, including recognising and responding to their emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to brainstorm the importance of social intelligence in building positive relationships and creating supportive environments. Look for and explain:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Building Positive Relationships: Social intelligence helps you form meaningful connections with others. By being socially aware, you can understand people's feelings, perspectives, and needs. This understanding allows you to engage in respectful and empathetic interactions, leading to the development of strong and positive relationships with friends, family, and peers.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Effective Communication: Social intelligence helps you communicate effectively, both verbally and nonverbally. It enables you to express your thoughts and emotions clearly and understand the messages others are conveying. By being a good listener, using appropriate body language, and responding thoughtfully, you can foster open and honest communication, which strengthens relationships and prevents misunderstandings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Empathy and Understanding: Social intelligence allows you to empathise with others by putting yourself in their shoes and understanding their emotions and experiences. This skill helps you be more sensitive and supportive towards others, showing kindness, compassion, and respect. By understanding and validating the feelings of others, you can create a safe and inclusive environment where everyone feels heard and valued.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Conflict Resolution: Social intelligence equips you with the skills to handle conflicts and disagreements in a constructive manner. By understanding different perspectives and emotions, you can find common ground and work towards mutually beneficial solutions. This reduces tension, promotes cooperation, and maintains positive relationships, even in challenging situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Creating Supportive Environments: Social intelligence plays a vital role in creating supportive environments where everyone feels included and valued. By being aware of the emotions and needs of others, you can offer support, provide encouragement, and show kindness. This fosters a sense of belonging, trust, and emotional wellbeing among individuals, creating an environment where everyone can thrive.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Observing Emotions (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of observing emotions in others. Explain that the face can provide some really good clues as to what someone if feeling.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play this video and ask students to try to guess the emotion (you may prefer to pause the video at each point) <DefaultTextWithCustomLink indent={0} text='The 7 basic emotions - Do you recognise all the facial expressions [1:55].' link='https://youtu.be/embYkODkzcs' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the importance of paying attention to verbal and nonverbal cues such as facial expressions, body language, tone of voice, and choice of words.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Level 5 Emotion wheel and for each emotion, ask the students to identify possible signs or signals associated with each emotion. Have them complete the <DefaultTextWithCustomLink indent={0} text='Observing emotions worksheet.' link={Worksheets.observingEmotionWorksheets.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief asking students to share their responses.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Assisting Others with Emotions (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show video - <DefaultTextWithCustomLink indent={0} text=' Are you okay? [8:45].' link='https://www.youtube.com/watch?v=tJsGGsPNakw' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of helping others cope with their emotions in a supportive and empathetic manner.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide strategies for assisting others, such as active listening, validating their emotions, offering comfort or reassurance, and suggesting coping techniques.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Active Listening: Practice active listening by giving your full attention and showing genuine interest in what the person is saying. Maintain eye contact, nod to show understanding, and provide verbal cues to encourage them to share their emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Provide a Safe Space: Create a safe and non-judgmental environment where the person feels comfortable expressing their emotions. Assure them that their feelings are valid and that you are there to support them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Validate Their Emotions: Show empathy and validate their emotions by acknowledging and accepting their feelings. Use phrases like, "I understand how you must be feeling," or "It's okay to feel that way."</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Avoid Judgment and Criticism: Refrain from judging or criticising their emotions or reactions. Instead, focus on understanding and offering support.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Offer Comfort and Reassurance: Provide comfort and reassurance by offering words of encouragement and support. Let them know that they are not alone and that you are there for them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Reflective Questions: Ask open-ended questions that encourage them to reflect on their emotions and thoughts. This can help them gain insights into their feelings and find clarity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Suggest Coping Strategies: If appropriate, suggest healthy coping switch strategies that they can use to manage their emotions or seeking professional help if needed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Respect Boundaries: Respect their boundaries and allow them to share as much or as little as they feel comfortable. Don't pressure them to open up if they are not ready.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Encourage Self care: Emphasise the importance of self care and wellbeing. Encourage them to engage in activities that bring them joy and relaxation and remind them to take care of themselves both physically and mentally.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Offer Supportive Actions: Show your support by offering practical help if appropriate. This could include assisting with tasks, providing resources or information, or simply being there to listen whenever they need to talk.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a class discussion on the challenges and benefits of observing and helping others with their emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share personal experiences or examples of times when they observed and supported someone in coping with their emotions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the key points discussed about social intelligence and observing/helping others with emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of empathy, active listening, and supportive communication in social interactions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude the lesson by expressing the value of developing social intelligence skills in building strong and compassionate relationships.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Social+Intelligence.pptx' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard or chart paper</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Observing emotions worksheet.' link={Worksheets.observingEmotionWorksheets.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>How We Read Emotions from Faces -</DefaultText>
                        <DefaultTextWithLink text='https://kids.frontiersin.org/articles/10.3389/frym.2017.00011' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Can You Read People’s Emotions? -</DefaultText>
                        <DefaultTextWithLink text='https://archive.nytimes.com/well.blogs.nytimes.com/2013/10/03/well-quiz-the-mind-behind-the-eyes/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>The Art of Listening | Simon Sinek -</DefaultText>
                        <DefaultTextWithLink text='https://youtu.be/qpnNsSyDw-g' />
                        <SwitchCardVideo video={socialIntelligenceVideo1} />

                        <DefaultText style={CommonStyles.lessonPlanText}>Are you okay?</DefaultText>
                        <DefaultTextWithLink text='https://youtu.be/tJsGGsPNakw' />
                        <SwitchCardVideo video={socialIntelligenceVideo2} />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}