import { gql, makeVar, useQuery, useReactiveVar, useSubscription } from '@apollo/client';
import {
    StackScreenProps,
    createStackNavigator,
} from '@react-navigation/stack';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import {
    ListCheckInSessionsByClassIdQuery,
    ListCheckInSessionsByClassIdQueryVariables,
    ListClassLoginByClassIDQuery,
    ListClassLoginByClassIDQueryVariables,
    ListScheduleClassByClassIdQuery,
    ListScheduleClassByClassIdQueryVariables,
    ListStudentCheckInsByCheckInSessionIdQuery,
    ListStudentCheckInsByCheckInSessionIdQueryVariables,
    OnMutateCheckInSessionByClassIdSubscription,
    OnMutateCheckInSessionByClassIdSubscriptionVariables,
    OnMutateClassByIdSubscription,
    OnMutateClassByIdSubscriptionVariables,
    OnMutateClassLoginByClassIDSubscription,
    OnMutateClassLoginByClassIDSubscriptionVariables,
    OnMutateScheduleClassByClassIDSubscription,
    OnMutateScheduleClassByClassIDSubscriptionVariables,
    OnMutateStudentCheckInByClassIdSubscription,
    OnMutateStudentCheckInByClassIdSubscriptionVariables,
    OnMutateStudentClassByClassIdSubscription,
    OnMutateStudentClassByClassIdSubscriptionVariables
} from '../../common/API';
import {
    listCheckInSessionsByClassId,
    listClassLoginByClassID,
    listScheduleClassByClassId,
    listStudentCheckInsByCheckInSessionId
} from '../../common/graphql/queries';
import {
    onMutateCheckInSessionByClassId,
    onMutateClassById,
    onMutateClassLoginByClassID,
    onMutateScheduleClassByClassID,
    onMutateStudentCheckInByClassId,
    onMutateStudentClassByClassId,
} from '../../common/graphql/subscriptions';
import { CheckInEmotionalIntensity } from '../check-in/check-in-emotional-intensity';
import { CheckInEmotionalWheel } from '../check-in/check-in-emotional-wheel';
import { CheckInRequestChatWithTeacher } from '../check-in/check-in-request-chat-with-teacher';
import { CheckInTirednessScale } from '../check-in/check-in-tiredness-scale';
import { handleRestartApp } from '../common/handle-restart-app';
import { insertNewItemIntoListStudentClassAndStudentDataByClassIdCustomQuery } from '../common/insert-new-item-into-list-student-class-and-student-data-by-class-id-custom-query';
import { RootView } from '../common/root-view';
import { ScreenNames } from '../common/screen-names';
import { updateListQueryCache } from '../common/update-list-query-cache';
import { TeacherAddStudent } from './teacher-add-student';
import { TeacherCheckInFinish } from './teacher-check-in-finish';
import { TeacherCheckInStart } from './teacher-check-in-start';
import { TeacherClassAdmin } from './teacher-class-admin';
import { TeacherClassAdminCurrentClassLogin } from './teacher-class-admin-current-class-login';
import { TeacherClassAdminSchedule } from './teacher-class-admin-schedule';
import { TeacherCreateStudentNickname } from './teacher-create-student-nickname';
import { TeacherCreateStudentPickAvatar } from './teacher-create-student-pick-avatar';
import { TeacherEditStudent } from './teacher-edit-student';
import { TeacherEditStudentAvatar } from './teacher-edit-student-avatar';
import {
    TeacherClassNavigatorParamList,
    TeacherDrawerNavigatorPamarList,
} from './teacher-route-param-types';
import { TeacherStats } from './teacher-stats';
import { TeacherStudentIndividualStats } from './teacher-student-individual-stats';
import { CheckInWellbeingQuestions } from '../check-in/check-in-wellbeing-questions';
import CheckInEating from '../check-in/check-in-eating';

const Stack = createStackNavigator<TeacherClassNavigatorParamList>();

export const rvTeacherClassMainSubscriptionClassID = makeVar("")

export const TeacherClassMain = ({
    route: {
        params,
    }
}: StackScreenProps<TeacherDrawerNavigatorPamarList, 'TeacherClassMain'>) => {

    const teacherClassMainSubscriptionClassID = useReactiveVar(rvTeacherClassMainSubscriptionClassID)

    let classIDParams = params?.params?.classID || teacherClassMainSubscriptionClassID || ''

    const [currentClassID, setCurrentClassID] = useState('');

    useEffect(() => {

        getClassIDParamsAndSetCurrentClassID()
    }, [classIDParams]);

    const getClassIDParamsAndSetCurrentClassID = () => {
        if (Platform.OS === 'web' && !classIDParams) {
            const urlParams = new URLSearchParams(window.location.search);
            const classID = urlParams.get('classID') || "";

            classIDParams = classID
        }

        setCurrentClassID(classIDParams);
    }


    const { data: checkInSessionsResponse } = useQuery<
        ListCheckInSessionsByClassIdQuery,
        ListCheckInSessionsByClassIdQueryVariables
    >(
        gql`
      ${listCheckInSessionsByClassId}
    `,
        {
            variables: { classID: currentClassID || '', limit: 5000 },
            skip: !currentClassID,
        }
    );

    const classCheckInSessions = _.orderBy(
        checkInSessionsResponse?.listCheckInSessionsByClassId?.items.filter(
            (item) => item?._deleted !== true
        ),
        'updatedAt',
        'desc'
    );

    const { error: errorClassSubscription } = useSubscription<OnMutateClassByIdSubscription, OnMutateClassByIdSubscriptionVariables>(gql`${onMutateClassById}`, { variables: { id: currentClassID }, skip: !currentClassID })

    const { error: errorStudentCheckInSubscription } = useSubscription<
        OnMutateStudentCheckInByClassIdSubscription,
        OnMutateStudentCheckInByClassIdSubscriptionVariables
    >(
        gql`
      ${onMutateStudentCheckInByClassId}
    `,
        {
            variables: { classID: currentClassID || '' },
            onData: updateListQueryCache<
                ListStudentCheckInsByCheckInSessionIdQuery,
                ListStudentCheckInsByCheckInSessionIdQueryVariables,
                OnMutateStudentCheckInByClassIdSubscription
            >({
                listQuery: listStudentCheckInsByCheckInSessionId,
                listQueryName: 'listStudentCheckInsByCheckInSessionId',
                subscriptionName: 'onMutateStudentCheckInByClassId',
                variables: {
                    checkinsessionID: classCheckInSessions?.length
                        ? classCheckInSessions[0]?.id || ''
                        : '',
                    limit: 5000,
                },
            }),
            skip: currentClassID === '',
        }
    );

    const { error: errorCheckInSessionSubscription } = useSubscription<
        OnMutateCheckInSessionByClassIdSubscription,
        OnMutateCheckInSessionByClassIdSubscriptionVariables
    >(
        gql`
      ${onMutateCheckInSessionByClassId}
    `,
        {
            variables: { classID: currentClassID || '' },
            onData: updateListQueryCache<
                ListCheckInSessionsByClassIdQuery,
                ListCheckInSessionsByClassIdQueryVariables,
                OnMutateCheckInSessionByClassIdSubscription
            >({
                listQuery: listCheckInSessionsByClassId,
                listQueryName: 'listCheckInSessionsByClassId',
                subscriptionName: 'onMutateCheckInSessionByClassId',
                variables: { classID: currentClassID || '', limit: 5000 },
            }),
            skip: currentClassID === '',
        }
    );

    const { error: errorStudentClassSubscription } = useSubscription<
        OnMutateStudentClassByClassIdSubscription,
        OnMutateStudentClassByClassIdSubscriptionVariables
    >(
        gql`
      ${onMutateStudentClassByClassId}
    `,
        {
            variables: { classID: currentClassID || '' },
            onData: insertNewItemIntoListStudentClassAndStudentDataByClassIdCustomQuery({ classID: currentClassID }),
            skip: currentClassID === '',
        }
    );

    const { error: classLoginSubscriptionError } = useSubscription<
        OnMutateClassLoginByClassIDSubscription,
        OnMutateClassLoginByClassIDSubscriptionVariables
    >(
        gql`
      ${onMutateClassLoginByClassID}
    `,
        {
            variables: { classID: currentClassID },
            onData: updateListQueryCache<
                ListClassLoginByClassIDQuery,
                ListClassLoginByClassIDQueryVariables,
                OnMutateClassLoginByClassIDSubscription
            >({
                listQuery: listClassLoginByClassID,
                listQueryName: 'listClassLoginByClassID',
                subscriptionName: 'onMutateClassLoginByClassID',
                variables: {
                    classID: currentClassID,
                    limit: 5000
                }
            }),
            skip: currentClassID === '',
        }
    );

    const { error: errorScheduleClassSubscription } = useSubscription<OnMutateScheduleClassByClassIDSubscription, OnMutateScheduleClassByClassIDSubscriptionVariables>
        (gql`${onMutateScheduleClassByClassID}`,
            {
                variables: { classID: currentClassID },
                onData: updateListQueryCache<ListScheduleClassByClassIdQuery, ListScheduleClassByClassIdQueryVariables, OnMutateScheduleClassByClassIDSubscription>({ listQuery: listScheduleClassByClassId, listQueryName: "listScheduleClassByClassId", subscriptionName: "onMutateScheduleClassByClassID", variables: { classID: currentClassID, limit: 5000 } }),
                skip: !currentClassID
            },
        )

    if (
        classLoginSubscriptionError !== undefined ||
        errorStudentClassSubscription !== undefined ||
        errorStudentCheckInSubscription !== undefined ||
        errorCheckInSessionSubscription !== undefined ||
        errorClassSubscription !== undefined ||
        errorScheduleClassSubscription !== undefined
    ) {
        handleRestartApp()
    }


    return (
        <RootView>
            <Stack.Navigator
                screenOptions={{ headerShown: false }}
            >
                {/* CLASS ADMIN */}
                <Stack.Screen
                    name={ScreenNames.TeacherClassAdmin}
                    component={TeacherClassAdmin}
                />
                <Stack.Screen
                    name={ScreenNames.TeacherClassAdminCurrentClassLogin}
                    component={TeacherClassAdminCurrentClassLogin}
                />
                <Stack.Screen
                    name={ScreenNames.TeacherClassSchedule}
                    component={TeacherClassAdminSchedule}
                />
                <Stack.Screen
                    name={ScreenNames.TeacherAddStudent}
                    component={TeacherAddStudent}
                />

                {/* STUDET PROFILE */}
                <Stack.Screen
                    name={ScreenNames.TeacherCreateStudentPickAvatar}
                    component={TeacherCreateStudentPickAvatar}
                />
                <Stack.Screen
                    name={ScreenNames.TeacherCreateStudentNickname}
                    component={TeacherCreateStudentNickname}
                />
                <Stack.Screen
                    name={ScreenNames.TeacherEditStudent}
                    component={TeacherEditStudent}
                />
                <Stack.Screen
                    name={ScreenNames.TeacherEditStudentAvatar}
                    component={TeacherEditStudentAvatar}
                />

                {/* STATS */}
                <Stack.Screen
                    name={ScreenNames.TeacherStats}
                    component={TeacherStats}
                />
                <Stack.Screen
                    name={ScreenNames.TeacherStudentIndividualStats}
                    component={TeacherStudentIndividualStats}
                />

                {/* CHECKI-N */}
                <Stack.Screen
                    name={ScreenNames.CheckInEmotionalIntensity}
                    component={CheckInEmotionalIntensity}
                />
                <Stack.Screen
                    name={ScreenNames.CheckInEmotionalWheel}
                    component={CheckInEmotionalWheel}
                />
                <Stack.Screen
                    name={ScreenNames.CheckInFinish}
                    component={TeacherCheckInFinish}
                />
                <Stack.Screen
                    name={ScreenNames.CheckInRequestChatWithTeacher}
                    component={CheckInRequestChatWithTeacher}
                />
                <Stack.Screen
                    name={ScreenNames.CheckInStart}
                    component={TeacherCheckInStart}
                />
                <Stack.Screen
                    name={ScreenNames.CheckInTirednessScale}
                    component={CheckInTirednessScale}
                />
                <Stack.Screen
                    name={ScreenNames.CheckInEating}
                    component={CheckInEating}
                />
                <Stack.Screen
                    name={ScreenNames.CheckInWellbeingQuestion}
                    component={CheckInWellbeingQuestions} />
            </Stack.Navigator>
        </RootView>
    );
};
