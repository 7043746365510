import { View } from 'react-native'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import VideoCard from '../../../common/video-card'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { FingerTipRubHeaderIcon } from './src/finger-tip-rub-header-icon'
import { FingerTipRubIcon } from './src/finger-tip-rub-icon'
import { FingerTipRubLineDrawing } from './src/finger-tip-rub-line-drawing'


export const SwitchesEmotionsExcitedFingerTipRub: CardTemplate = {
    cardId: "645d95e0-ea4d-4008-94d6-de37fbe3641b",
    title: 'Finger tip rub',
    Header: FingerTipRubHeaderIcon,
    Icon: FingerTipRubIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <View style={{ flex: 1 }}>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata='Focusing the mind away from overstimulating thoughts.' />

            </View>
        </>
    ),
    Images: FingerTipRubLineDrawing,
    CardContent: () => (
        <>
          
            <DefaulttextWithRightArrow textdata="Close your eyes." />
            <DefaulttextWithRightArrow textdata="In a slow and light swirling motion rub the ridges of your thumb and your index finger together." />
            <DefaulttextWithRightArrow textdata="Avoid other distractions and attempt to just focus on the light touch sensation." />
            <DefaulttextWithRightArrow textdata="Do this for as long as you need to feel the calming effect." />

        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Finger+tip+rub.m4a",
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: `Through the rhythmic fingertip rub, individuals engage their sense of touch in a deliberate and calming manner. By focusing on the subtle sensation of the ridges of the thumb and index finger rubbing together, the activity diverts attention from overwhelming excitement. The closed eyes aid in blocking out external stimuli, enhancing the tactile experience. The repetitive motion and focused attention combine to induce a calming effect, making it useful for reducing heightened excitement.`,
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata="Compare the sensation of rubbing different fingers with your thumb. " />
            <DefaulTextWithTipsIcon textdata="Optional variation - Feel a textured object in your hand experiencing its properties e.g. smooth stone, velvet fabric, rubber ball." />
            <DefaulTextWithTipsIcon textdata="Notice your breathing, slow things down and try to align the pace with the swirling motion." />
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to employ the guided fingertip rub technique, both with and without textured objects, as a practical and effective method for managing excitement and maintaining focus.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Gain an understanding of the physiological impact of excitement on the body and the potential benefits of incorporating tactile experiences into calming strategies.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                       <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 20 minutes</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (2 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by briefly discussing the idea of excitement and how it can sometimes be overwhelming or inhibit your ability to focus on the task at hand due to the chemicals being released into the body, such as adrenalin.  Refer to the “Physiology of Emotion” article in the tools section for more detail about the impact of excitement on the body.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that you'll be introducing a simple technique to help manage excitement and maintain focus.</DefaultText>
                     

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Fingertip Rub (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to sit comfortably with both feet on the floor.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play soft background music to create a calming atmosphere. (available in the additional resources)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Demonstrate the fingertip rub: Slowly and lightly rub the ridges of your thumb and index finger together in a swirling motion.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to follow your guidance, close their eyes, and immerse themselves in the sensation of touch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind them to focus solely on the tactile experience and the calming effect it brings.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Variation with Textured Objects (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce a tactile element to the activity. Provide textured objects to students.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct them to continue the fingertip rub while holding one of these objects in their non-dominant hand.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to pay attention to the added texture and how it enhances the sensory experience.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Sharing and Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite a few students to share their reflections and thoughts with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage discussion about the potential benefits of this technique in managing excitement.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (3 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points discussed during the lesson, emphasising the simplicity and effectiveness of the fingertip rub technique.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to embrace this technique as a valuable tool for maintaining composure and focus in moments of heightened excitement.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Soft background music (available in the additional resources)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Textured objects for tactile variation E.g., smooth stone, velvet fabric, rubber ball, table tennis ball, cork, feather, sandpaper, seashell, suede fabric, plastic bubble wrap, wooden block, tennis ball.</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Beautiful Relaxing Music</DefaultText>
                        <DefaultTextWithCustomLink style={{ paddingHorizontal: 40 }} text='Beautiful Relaxing Music - Stop Overthinking, Stress Relief Music, Sleep Music, Calming Music' link='https://www.youtube.com/watch?v=_kT38XB1YHo' />
                        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <VideoCard video={{ 
                                id: '1',
                                url: 'https://www.youtube.com/embed/_kT38XB1YHo'
                            }} />
                        </View>

                        <DefaultText style={CommonStyles.lessonPlanText}>Anxiety and the Calming Sense of Touch</DefaultText>
                        <DefaultTextWithLink text='https://www.healthyplace.com/blogs/anxiety-schmanxiety/2021/6/anxiety-and-the-calming-sense-of-touch' />
                        
                        <DefaultText style={CommonStyles.lessonPlanText}>The People Who Store Their Emotions in Their Fingertips</DefaultText>
                        <DefaultTextWithLink text='https://www.thecut.com/2016/05/the-people-who-store-their-emotions-in-their-fingertips.html' />
                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Excited]
}


