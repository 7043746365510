import { FontAwesome } from '@expo/vector-icons'
import { ActivityIndicator, Pressable, StyleSheet, View } from 'react-native'
import { CustomDeleteTeacherClassMutation, CustomDeleteTeacherClassMutationVariables, GetTeacherQuery, GetTeacherQueryVariables, SchoolAdminUI, Teacher, TeacherClass } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from '../common/default-text'
import TeacherRoleText from '../common/teacher-role-text'
import UserEmailText from '../common/user-email-text'
import { IconEdit } from '../icon/icon-edit'
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { getTeacher } from '../../common/graphql/queries'
import { customDeleteTeacherClass } from '../../common/graphql/mutations'
import { Loading, rvIsLoading } from '../common/loading'
import { showAlert } from '../common/universal-alert'
import { rvSchoolAdminSidebarCurrentTeacher, rvShowTeacherForm } from './school-admin-state'
import SchoolAdminFormUpsertTeacher from './school-admin-form-upsert-teacher'
import SchoolAdminButtonResetPasswordTeacher from './school-admin-button-reset-password-teacher'


const SchoolAdminSidebarTeacherListItem = ({ teacherClass }: { teacherClass: TeacherClass }) => {

    const { data, loading } = useQuery<GetTeacherQuery, GetTeacherQueryVariables>(gql`${getTeacher}`, { variables: { id: teacherClass.teacherID } })
    const currentTeacher = data?.getTeacher as Teacher
    const isTeacherEdit = useReactiveVar(rvSchoolAdminSidebarCurrentTeacher)?.id === currentTeacher?.id && !!currentTeacher

    const [deleteTeacherClassMutation] = useMutation<CustomDeleteTeacherClassMutation, CustomDeleteTeacherClassMutationVariables>(gql`${customDeleteTeacherClass}`,)

    const showDeleteTeacherPrompt = () => {
        showAlert({
            title: "Remove teacher",
            message: `Are you sure you want to remove ${currentTeacher.cognitoUsername} from this class?`,
            leftButtonText: "Cancel",
            rightButtonText: "Remove",
            onRightButtonPress: handleRemoveTeacher
        })
    }

    const handleRemoveTeacher = async () => {
        try {
            rvIsLoading(true)
            await deleteTeacherClassMutation({
                variables: {
                    input: {
                        id: teacherClass.id,
                    }
                }
            })
            rvIsLoading(false)

        } catch (error) {
            console.log(handleRemoveTeacher.name)
            console.log({ error })
        }
    }
    const handleEditTeacher = () => {
        rvSchoolAdminSidebarCurrentTeacher(currentTeacher)
    }

    if (loading) {
        return <ActivityIndicator size={"small"} color={DecidaColors.Green} style={{ marginBottom: 5 }} />
    }

    if (isTeacherEdit) {
        return <SchoolAdminFormUpsertTeacher />
    }

    return (
        <View style={styles.container}>
            <View style={styles.leftContainer}>
                <Pressable onPress={handleEditTeacher} style={styles.editIcon}>
                    <IconEdit />
                </Pressable>
                <View style={styles.nameContainer}>
                    <DefaultText textProps={{ numberOfLines: 2, }} style={styles.nameText}>{currentTeacher?.firstName} {currentTeacher?.lastName}</DefaultText>
                    <UserEmailText email={currentTeacher?.email} />
                </View>
            </View>
            <View style={styles.rightContainer}>
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <SchoolAdminButtonResetPasswordTeacher teacher={currentTeacher} schoolAdminUI={SchoolAdminUI.HIERARCHY} />
                    <FontAwesome onPress={showDeleteTeacherPrompt} name="minus-circle" size={22} color={DecidaColors.Angry} />
                </View>
                <TeacherRoleText role={currentTeacher?.role || undefined} style={styles.roleText} />
            </View>
        </View>
    )
}
export default SchoolAdminSidebarTeacherListItem

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        paddingHorizontal: 6,
        paddingVertical: 10,
        justifyContent: 'space-between',
        borderWidth: 1,
        flexWrap: 'wrap',
        borderColor: DecidaColors.GrayBorder,
    },
    leftContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
    },
    editIcon: {
        width: 20,
        height: 20,
    },
    nameContainer: {
        flex: 1,
    },
    nameText: {
        fontSize: 18,
        color: DecidaColors.GrayText,
    },
    rightContainer: {
        flex: 1,
        alignItems: 'flex-end',
    },
    roleText: {
        paddingRight: 20,
    },
})