import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import _ from 'lodash'
import { useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { DeleteFindYourCalmActivityRatingMutation, DeleteFindYourCalmActivityRatingMutationVariables, FindYourCalmActivityRating, ListFindYourCalmByUserIdAndActivityIdQuery, ListFindYourCalmByUserIdAndActivityIdQueryVariables, Student, Teacher, UpsertFindYourCalmActivityRatingMutation, UpsertFindYourCalmActivityRatingMutationVariables } from '../../../../common/API'
import { UserGroup } from '../../../../common/constants'
import { DecidaColors } from '../../../../common/decida-colors'
import { deleteFindYourCalmActivityRating, upsertFindYourCalmActivityRating } from '../../../../common/graphql/mutations'
import { listFindYourCalmByUserIdAndActivityId } from '../../../../common/graphql/queries'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultButton } from '../../../common/default-button'
import { DefaultText } from '../../../common/default-text'
import { rvIsLoading } from '../../../common/loading'
import { rvUserGroup } from '../../../login/login-state'
import { rvCurrentStudent } from '../../../student/use-student-state'
import { rvTeacherCurrentTeacher } from '../../../teacher/teacher-state'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { BodyScanLineDrawing } from './src/body-scan-line-drawing'
import { FeedbackAverage } from './src/feedback-average'
import { FeedbackExcellent } from './src/feedback-excellent'
import { FeedbackGood } from './src/feedback-good'
import { FeedbackPoor } from './src/feedback-poor'
import { FeedbackVeryPoor } from './src/feedback-very-poor'
import { FindYourCalmHeaderIcon } from './src/find-your-calm-header-icon'
import { FindYourCalmIcon } from './src/find-your-calm-icon'
import { Worksheets } from '../../../common/worksheets-imports'

const calmActivitiesArray = [
    { id: "MassageYourHands", title: "Massage Your Hands", description: "Give both of your hands a good massage for a few minutes." },
    { id: "FindSun", title: "Find Sun", description: "Step outside into the sunlight and relax as you take in the rays." },
    { id: "RaiseYourFeet", title: "Raise Your Feet", description: "Find a spot to lie down where your feet are resting slightly elevated." },
    { id: "Teatime", title: "Teatime", description: "Make yourself a cup of tea, maybe experiment with different types and flavors." },
    { id: "MassageYourTemples", title: "Massage Your Temples", description: "With both hands, place two fingers on either side of your head and massage your temples." },
    { id: "BackwardsABCs", title: "Backwards ABC’s", description: "At your own pace, complete saying the alphabet backward." },
    { id: "BarefootonTennisBall", title: "Barefoot on Tennis Ball", description: "Stand barefoot on a tennis ball while holding onto a stable object and feel the muscles in the bottoms of your feet getting massaged." },
    { id: "Count", title: "Count", description: "Slowly count to 20." },
    { id: "Draw", title: "Draw", description: "Complete a quick drawing or sketch." },
    { id: "GratitudeJournal", title: "Gratitude Journal", description: "Write down three things you're grateful for." },
    { id: "WristRoll", title: "Wrist Roll", description: "Slowly roll both of your wrists in a circular motion so you can feel the stretch." },
    { id: "Visualization", title: "Visualization", description: "Imagine a peaceful place and visualize yourself there." },
    { id: "MuscleRelaxation", title: "Muscle Relaxation", description: "Tense and release each muscle group in your body." },
    { id: "HummingorSinging", title: "Humming or Singing", description: "Hum or sing a soothing song." },
    { id: "GroundingExercise", title: "Grounding Exercise", description: "Touch and describe three objects around you." },
    { id: "Origami", title: "Origami", description: "Fold a piece of paper into an origami creation." },
    { id: "PositiveNote", title: "Positive Note", description: "Write a positive note to yourself or someone else." },
    { id: "Read", title: "Read", description: "Take a few minutes to read a chapter of a book." },
    { id: "Meme", title: "Meme", description: "Find a funny meme you like and share it with a mate." },
    { id: "BreathBreak", title: "Breath Break", description: "Take 10 deep breaths counting to four on the inhale and four on the exhale." },
    { id: "Squeeze", title: "Squeeze", description: "Squeeze a stress ball or similar and release." },
    { id: "Stretch", title: "Stretch", description: "Engage in a quick stretch routine." },
    { id: "Connect", title: "Connect", description: "Reach out to a friend for support." },
    { id: "Splash", title: "Splash", description: "Splash some cold water on your face." },
];

const emotionFeedbacks = [
    { id: 1, title: "Very poor", icon: FeedbackVeryPoor, color: DecidaColors.Angry },
    { id: 2, title: "Poor", icon: FeedbackPoor, color: DecidaColors.Scared },
    { id: 3, title: "Average", icon: FeedbackAverage, color: DecidaColors.Yellow },
    { id: 4, title: "Good", icon: FeedbackGood, color: DecidaColors.LightGreen },
    { id: 5, title: "Excellent", icon: FeedbackExcellent, color: DecidaColors.Green },
]

let currentUser: Teacher | Student | null = null

export const SwitchesEmotionsAngryFindYourCalm: CardTemplate = {
    cardId: "a031edec-6ef6-449b-a8bb-fdc65d152b08",
    title: 'Find your calm',
    Header: FindYourCalmHeaderIcon,
    Icon: FindYourCalmIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Experimenting with new ways to combat your anger."} />
        </>
    ),
    Images: () => (
        <>
            <BodyScanLineDrawing />
        </>
    ),
    CardContent: () => {
        const currentStudent = useReactiveVar(rvCurrentStudent)
        const currentTeacher = useReactiveVar(rvTeacherCurrentTeacher)
        const userGroup = useReactiveVar(rvUserGroup)


        const [upsertFindYourCalmActivityRatinngMutation] = useMutation<UpsertFindYourCalmActivityRatingMutation, UpsertFindYourCalmActivityRatingMutationVariables>(gql`${upsertFindYourCalmActivityRating}`,)
        const [removeFindYourCalmActivityRatinngMutation] = useMutation<DeleteFindYourCalmActivityRatingMutation, DeleteFindYourCalmActivityRatingMutationVariables>(gql`${deleteFindYourCalmActivityRating}`,)
        const [calmActivity, setCalmActivity] = useState({
            id: "",
            title: "",
            description: ""
        })
        const handleRandomizeQuote = () => {
            const randomIndex = Math.floor(Math.random() * calmActivitiesArray.length);
            const currentActivity = calmActivitiesArray[randomIndex]
            setCalmActivity({
                id: currentActivity.id,
                title: currentActivity.title,
                description: currentActivity.description
            });
        }

        const handleFindYourCalmRate = async (ratingValue?: number) => {
            if (!currentUser) {
                console.log(handleFindYourCalmRate.name, "Current user not found")
                return
            }

            if (!calmActivity.id) {
                console.log(handleFindYourCalmRate.name, "No activity, please press New activity button")
                return
            }

            rvIsLoading(true)
            await upsertFindYourCalmActivityRatinngMutation({
                variables: {
                    input: {
                        userId: currentUser?.id || "",
                        activityId: calmActivity.id,
                        rating: ratingValue
                    }
                }
            })
            rvIsLoading(false)
        }


        currentUser = currentStudent && userGroup === UserGroup.Student ? currentStudent : currentTeacher && userGroup === UserGroup.Teacher ? currentTeacher : null
        const { data } = useQuery<ListFindYourCalmByUserIdAndActivityIdQuery, ListFindYourCalmByUserIdAndActivityIdQueryVariables>(gql`${listFindYourCalmByUserIdAndActivityId}`, { variables: { userId: currentUser?.id || "" }, skip: !currentUser })

        const userCalmActivitiesRating = _.sortBy(data?.listFindYourCalmByUserIdAndActivityId?.items.filter(i => i?._deleted !== true), "rating") as FindYourCalmActivityRating[]

        const findHighestRatedActivities = (data: FindYourCalmActivityRating[]): FindYourCalmActivityRating[] => {
            const highestRatedActivities = data.filter(item => item._deleted !== true);
            return highestRatedActivities;
        }

        const existedActivity = userCalmActivitiesRating.find((act) => act.activityId === calmActivity.id)

        const handlePressUpdateUserRating = async () => {

            if (existedActivity) {
                rvIsLoading(true)
                await removeFindYourCalmActivityRatinngMutation({
                    variables: {
                        input: {
                            id: existedActivity.id,
                            _version: existedActivity._version
                        }
                    }
                })
                rvIsLoading(false)
                return
            }

            handleFindYourCalmRate()

        }

        const handlePressCalmActivity = (activity: FindYourCalmActivityRating) => () => {
            const selectedActivity = calmActivitiesArray.find((a) => a.id === activity.activityId)

            if (!selectedActivity) {
                throw new Error("Activity not found")
            }

            setCalmActivity({
                id: selectedActivity?.id,
                description: selectedActivity?.description,
                title: selectedActivity?.title
            })
        }
        return (
            <>
                <View style={[commonCardStyles.container, { marginBottom: 40 }]}>
                    <DefaulttextWithRightArrow textdata={"Select the ‘New activity’ button."} />
                    <DefaulttextWithRightArrow textdata={"If you can, give the activity a go, or select the button again to generate an alternative suggestion. "} />
                    <DefaulttextWithRightArrow textdata={"If you like the activity, select 'save' and you'll be able to access the activity again later."} />
                    <View style={{ backgroundColor: DecidaColors.AppleSystemGray5Light, padding: 10 }}>
                        {calmActivity.id && (
                            <>
                                <View style={styles.calmActivityContainer}>
                                    <DefaultText style={styles.calmActivityText}>
                                        <DefaultText style={[styles.calmActivityText, { fontWeight: 'bold', marginRight: 5 }]}>
                                            {calmActivity.title}:
                                        </DefaultText>
                                        {calmActivity.description}
                                    </DefaultText>
                                </View>
                            </>
                        )}
                        <View style={styles.buttonContainer}>
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: "center", width: '100%', gap: 10, padding: 10 }}>
                                <DefaultButton onPress={handleRandomizeQuote} style={{ backgroundColor: DecidaColors.Gray, flex: 1 }}>New activity</DefaultButton>
                                <DefaultButton onPress={handlePressUpdateUserRating} style={{ backgroundColor: existedActivity ? CardColorScheme.ANGRY : DecidaColors.Sad, flex: 1 }}>{existedActivity ? "Remove" : "Save"}</DefaultButton>
                            </View>
                        </View>

                    </View>
                    <DefaulttextWithRightArrow textdata={"Here is your list of saved strategies:"} />
                    {userCalmActivitiesRating.length > 0 && (
                        findHighestRatedActivities(userCalmActivitiesRating).map((findYourCalmActivity) => {
                            return <DefaultText key={findYourCalmActivity.id} onPress={handlePressCalmActivity(findYourCalmActivity)} style={styles.activityText}>- {calmActivitiesArray.find((item) => item.id === findYourCalmActivity?.activityId)?.title}</DefaultText>
                        })
                    )}
                </View>
            </>
        )
    },
    BottomRow: () => <></>,
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: `Mindfulness allows us to become attuned to bodily sensations, which often carry signals of anger. This heightened self awareness fosters the identification and subsequent release of pent up tension. Regular practice can enhance anger management skills, facilitating a calmer and more composed response to triggering situations.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the importance of managing anger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learned and practiced various brief activities to regain their composure and find calm in moments of anger or frustration.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 25 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by asking students about their experiences with anger and how it feels both physically and emotionally.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept that finding calm in moments of anger is crucial for overall wellbeing and relationships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the physiological effects of anger, such as increased heart rate, stress, and the impact on decision-making.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the "Physiology of Emotion" article in the tools section for additional insights.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Exploration of Calming Activities (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Direct students to the Find Your Calm switch to explore a list of calming activities.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Highlight a few activities, such as deep breathing, drawing, or making a cup of tea.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students read the list of calming activities and choose one or two that they have tried or would like to try.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite volunteers to share their chosen activities and reasons with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a brief discussion on the benefits of incorporating calming activities into daily life.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that these activities serve as quick escapes to regain composure.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Interactive Activity - Calming Techniques (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide the class into small groups.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide each group with the <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={Worksheets.findYourCalm.label} link={Worksheets.findYourCalm.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Display or hand out the <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={Worksheets.findYourCalmStrategies.label} link={Worksheets.findYourCalmStrategies.uri} />.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct groups to brainstorm and discuss which calming activity would be most effective for each scenario, assigning the corresponding list number.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage groups to share their thoughts and reasoning.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allow each group to briefly share their chosen calming activities for the scenarios.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice at least one calming activity from the list whenever they feel anger or frustration rising, and suggest they keep a brief journal noting triggering situations and how their chosen activity helped them find calm.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude the lesson by emphasising the importance of incorporating calming activities into daily life for emotional well-being.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard and markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Projector or screen for displaying visuals</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Timer or clock with a second hand</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={Worksheets.findYourCalm.label} link={Worksheets.findYourCalm.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={Worksheets.findYourCalmStrategies.label} link={Worksheets.findYourCalmStrategies.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>40 Ways to Relax in 5 Minutes or Less</DefaultText>
                        <DefaultTextWithLink text='https://greatist.com/happiness/40-ways-relax-5-minutes-or-less' />

                        <DefaultText style={CommonStyles.lessonPlanText}>14 Instant Ways to Calm Yourself Down</DefaultText>
                        <DefaultTextWithLink text='https://www.everydayhealth.com/columns/therese-borchard-sanity-break/10-quick-ways-to-calm-down/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Calming Strategies for the Classroom</DefaultText>
                        <DefaultTextWithLink text='https://www.miracle-recreation.com/blog/calming-strategies-for-the-classroom/?lang=can' />
                    </>}
            />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Find+your+calm.m4a",
    type: [CardType.Switch],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual, CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action, CardActionType.Thought],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Angry]
}

const styles = StyleSheet.create({
  activityText: {
    marginLeft: 25,
    textDecorationLine: 'underline',
    color: DecidaColors.Blue,
  },
    calmActivityText: {
        fontWeight: '500',
        marginTop: 20,
        textAlign: 'center'
    },
    buttonContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    calmActivityContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
    },
})
