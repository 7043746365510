import { makeVar, useReactiveVar } from '@apollo/client';
import { AntDesign } from '@expo/vector-icons';
import * as Print from 'expo-print';
import _ from 'lodash';
import { ColorValue, Platform, Pressable, StyleSheet, View } from 'react-native';
import { rvCard } from '../switches/common/card-color-scheme';
import { IconSize } from './const';
import { DefaultText } from './default-text';
import { showAlert } from './universal-alert';
import { useResponsive } from './use-responsive';
import { DecidaColors } from '../../common/decida-colors';
import { useState } from 'react';
import { Loading } from './loading';
import { ActivityIndicator } from 'react-native';

export const rvPrintMode = makeVar(false)

const ExportToPdfButton = ({ iconColor }: { iconColor?: ColorValue }) => {
    const { width } = useResponsive()
    const isPrintMode = useReactiveVar(rvPrintMode)
    const color = useReactiveVar(rvCard)?.colorScheme

    const handleExportToPdf = async () => {
        if (Platform.OS !== 'web') {
            showAlert({
                title: "Export file",
                message: "Please use web version of the app, to use this feature",
                leftButtonText: 'Ok'
            })
            return
        }
        rvPrintMode(true)
        try {
            _.delay(async () => {
                await Print.printToFileAsync()

                rvPrintMode(false)
            }, 1000);
            return
        } catch (error) {
            console.log("fail", error)
            rvPrintMode(false)
        }
    }

    return (
        <Pressable style={[styles.buttonContainer, { padding: width > 600 ? 10 : 2, display: isPrintMode ? 'none' : undefined }]} onPress={handleExportToPdf}>
            <View style={styles.iconContainer}>
                <AntDesign name="export" size={IconSize} color={iconColor || color || DecidaColors.Green} />
            </View>
            <DefaultText style={[styles.text, { fontSize: width > 600 ? 15 : 10, }]}>
                Export
            </DefaultText>
        </Pressable>
    )
}
export default ExportToPdfButton
const styles = StyleSheet.create({
    buttonContainer: {
        justifyContent: 'center',
        backgroundColor: 'transparent',
        alignItems: 'center'
    },
    iconContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
    },
    text: {
        color: DecidaColors.GrayText,
    }
})
