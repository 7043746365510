
import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { StyleSheet, View } from 'react-native'
import { GetSchoolQuery, GetSchoolQueryVariables, SchoolAdminUI } from '../../common/API'
import { getSchool } from '../../common/graphql/queries'
import { Page } from '../common/page'
import { useResponsive } from '../common/use-responsive'
import SchoolAdmiCampusesDashboard from './school-admin-campuses-dashboard'
import { rvCurrentSchoolID, rvIsSchoolWithCampus } from './school-admin-state'
import SchoolAdminTable from './school-admin-table'
import { useEffect } from 'react'
import { getSchoolQuery } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-query'

const SchoolAdminHome = () => {
    const { isNarrowScreen } = useResponsive()

    const currentSchoolID = useReactiveVar(rvCurrentSchoolID)
    const { data } = useQuery<GetSchoolQuery, GetSchoolQueryVariables>(getSchoolQuery, { variables: { id: currentSchoolID || "" }, skip: !currentSchoolID })

    const schoolData = data?.getSchool
    const isSchoolWithCampus = schoolData?.schoolAdminUI === SchoolAdminUI.HIERARCHY

    useEffect(() => {
        rvIsSchoolWithCampus(isSchoolWithCampus)
    }, [schoolData])
    
    if (!schoolData) {
        return null
    }

    return (
        <Page>
            <View style={[styles.container]}>
                {isSchoolWithCampus ? (
                    <SchoolAdmiCampusesDashboard />
                ) : (
                    <SchoolAdminTable />
                )}
            </View>
        </Page>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        justifyContent: "flex-start",
        alignItems: 'flex-start',
        overflow: 'visible'
    },
    tableContainer: {
        width: '100%',
    }
})

export default SchoolAdminHome