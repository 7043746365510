import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { Worksheets } from '../../common/worksheets-imports'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import SwitchCardVideo from '../../common/switch-card-video'

const advancedConflictVideo1: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/eT7lt4ESSLo",
    title: ""
}

const advancedConflictVideo2: Videos = {
    id: "2",
    url: "https://www.youtube.com/embed/pUI9-MwCmGM",
    title: ""
}

const advancedConflictVideo3: Videos = {
    id: "3",
    url: "https://www.youtube.com/embed/o97fVGTjE4w",
    title: ""
}

export const SwitchesLessonPlansAdvancedConflictManagement: CardTemplate = {
    cardId: "dda0411e-1a3d-42a3-b09d-52166cbf6f8b",
    title: 'Advanced conflict management',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Recognise types of conflict in everyday life, the role emotions play in escalating conflict, and how to use emotion to drive better outcomes.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by discussing the importance of conflict management skills in everyday life and building healthy relationships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share their experiences or thoughts on conflicts they have encountered and how they handled them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that in this lesson, they will learn advanced strategies for managing conflicts and reaching positive resolutions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Conflict (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Define conflict as a disagreement or struggle between two or more individuals with differing needs, opinions, or perspectives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the different types of conflicts, such as interpersonal, intrapersonal, and intergroup conflicts.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Interpersonal Conflict: Interpersonal conflict refers to conflicts that occur between two or more individuals. It involves a clash of needs, interests, values, or goals between people who are in direct interaction with each other. Interpersonal conflicts can arise in various settings, such as personal relationships, friendships, family, or work environments.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Intrapersonal Conflict: Intrapersonal conflict, also known as internal conflict, occurs within an individual. It is a psychological struggle or tension that arises from conflicting thoughts, feelings, or desires within oneself. Intrapersonal conflicts often involve decision-making dilemmas, moral or ethical dilemmas, or conflicting emotions. This type of conflict can create personal turmoil and a sense of inner tension.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Intergroup Conflict: Intergroup conflict refers to conflicts that occur between different groups or factions. It involves clashes between groups that may have differing identities, beliefs, values, or goals. Intergroup conflicts can arise in various contexts, such as cultural or ethnic conflicts, ideological differences, or conflicts between rival teams or organizations. These conflicts can be driven by competition for resources, power struggles, or perceived threats to group interests.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Engage students in a brief group discussion on the potential causes and effects of the different types of conflicts.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Advanced Conflict Resolution Techniques (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Watch <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='the Conflict Styles video [3:39].' link='https://www.youtube.com/watch?v=pUI9-MwCmGM' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students reflect on times when they have embodied the different conflict styles in the Conflict Styles worksheet and consider if it was the best option in that situation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Accommodating bear</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Avoiding turtle</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Collaborating owl</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Competing shark</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Compromising fox </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of considering different perspectives and choosing the best style to suit the situation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share any insights from their reflections.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Collaborative Problem-Solving (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of collaborative problem-solving as an approach to resolving conflicts that focuses on finding mutually beneficial solutions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the steps involved in collaborative problem-solving: identifying the issue, understanding each other's perspectives, brainstorming solutions, evaluating options, and reaching a consensus.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide scenario cards with different conflict situations, and ask students to work in pairs or small groups to apply collaborative problem-solving techniques to resolve the conflicts.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 1: Cultural Clash </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Two groups of students from different cultural backgrounds are organising a school event to celebrate diversity. However, they have different ideas about how the event should be organised, what activities to include, and which cultural elements to showcase. The clash arises from conflicting perspectives on what represents inclusivity and cultural appreciation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 2: Social Media Feud</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>- Two groups of students, each belonging to different social circles, become involved in a heated exchange of comments and messages on social media. The conflict stems from misunderstandings, stereotypes, and rumours circulating between the groups. The online argument intensifies as members from each group defend their own circle and attack the other.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 3: Sports Rivalry</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>- Two sports teams from different schools in the same town have a long-standing rivalry. The conflicts arise during matches and competitions when tensions run high. Trash-talking, unsportsmanlike behaviour, and aggressive actions create a hostile atmosphere between the two teams, affecting not only the players but also their supporters and the overall spirit of fair play.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to consider multiple perspectives and generate creative solutions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief and discuss solutions generated.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclustion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share one insight from the lesson about conflict resolution techniques that will impact them in future situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of effective conflict resolution for personal growth and positive relationships.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Extension:</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Facilitate one or more of the resilience activities found in the activities section of the Switch4Schools app.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Provide additional opportunities for students to practice conflict resolution skills through real-life scenarios, group discussions, or collaborative projects. Encourage them to reflect on their experiences and continuously refine their skills.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard/Chart paper and markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Level 5 quick reference guide' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/level+5+QRG.pdf' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Conflict styles worksheet' link={Worksheets.conflictStylesWorksheet.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Collaborative problem solving task' link={Worksheets.conflictManagementScenarioHandouts.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Slides' link={'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/A12+Advanced+Conflict+Management+Slides.pptx'} /></DefaultText>


                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Conflict Management Funny animated 1 -</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=eT7lt4ESSLo' />
                        <SwitchCardVideo video={advancedConflictVideo1} />

                        <DefaultText style={CommonStyles.lessonPlanText}>Conflict styles -</DefaultText>
                        <DefaultTextWithLink text='https://youtu.be/pUI9-MwCmGM' />
                        <SwitchCardVideo video={advancedConflictVideo2} />

                        <DefaultText style={CommonStyles.lessonPlanText}>Conflict – Use It, Don’t Defuse It | CrisMarie Campbell & Susan Clarke | TEDxWhitefish -</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=o97fVGTjE4w' />
                        <SwitchCardVideo video={advancedConflictVideo3} />


                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}
