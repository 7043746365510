import _ from "lodash"
import { StaffCheckIn, StudentCheckIn } from "../../common/API"
import { nudgesCheckIsSkipSuggestionDates } from "./nudges-check-is-skip-suggestion-dates"
import { secretsforsleepcards } from "../switches/emotion-cards/cards"

// export const nudgesGetScreetsForSleepSuggestions = (previousStudentCheckins: StudentCheckIn[], classID: string, intensityLevel: number) => {
//     if (nudgesCheckIsSkipSuggestionDates() || intensityLevel === 1) {
//         return null
//     }
//     if (previousStudentCheckins) {
//         const sortedPrevStudentCheckins = _.sortBy(previousStudentCheckins, "createdAt")
//         const studentCurrentClassEmotions = sortedPrevStudentCheckins
//             .filter((prevCheckin) => prevCheckin.classID === classID)

//         const twoLastTiredness = studentCurrentClassEmotions.slice(-2)
//         const twoConsecutiveExhausted = twoLastTiredness.length == 2 && twoLastTiredness.every((item) => item.tiredness && Number(item.tiredness) < 2)

//         if (!twoConsecutiveExhausted) {
//             return null
//         }

//         return _.sample(secretsforsleepcards)
//     }
// }

export const nudgesGetScreetsForSleepSuggestions = (checkIns: (StudentCheckIn | StaffCheckIn)[]) => {

    if (checkIns) {
        const sortedCheckIns = _.sortBy(checkIns, "createdAt")
        const twoLastTiredness = sortedCheckIns.slice(-2)
        const twoConsecutiveExhausted = twoLastTiredness.length == 2 && twoLastTiredness.every((item) => item.tiredness && Number(item.tiredness) < 2)

        if (!twoConsecutiveExhausted) {
            return null
        }

        return _.sample(secretsforsleepcards)
    }
}