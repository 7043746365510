import { StyleSheet, View } from 'react-native'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { CartoonItHeader } from './src/cartoon-it-header'
import { CartoonItIcon } from './src/cartoon-it-icon'
import { CartoonItImage1 } from './src/cartoon-it-image1'
import { CartoonItImage2 } from './src/cartoon-it-image2'
import { CartoonItImage3 } from './src/cartoon-it-image3'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DecidaColors } from '../../../../common/decida-colors'

export const SwitchesEmotionsScaredCartoonIt: CardTemplate = {
    cardId: "48fd6664-1982-4e03-8f0f-86eb139ac0f2",
    title: 'Cartoon it!',
    Header: CartoonItHeader,
    Icon: CartoonItIcon,
    lessonPlan: true,
    mainContainerStyle: {
        maxWidth: '100%',
        paddingHorizontal: 20,
    },
    PerfectFor: () => {

        const Step1 = () => {
            return (
                <View>
                    <View style={styles.rowContainer}>
                        <DefaultText style={styles.contentText}>Grab a pencil and some paper, and quickly draw </DefaultText>
                        <DefaultText style={styles.contentText}>the thing that scares you. Make sure you put in </DefaultText>
                        <DefaultText style={styles.contentText}>all the scary bits!</DefaultText>

                        <View style={styles.imageContainer}>
                            <CartoonItImage1 />
                        </View>

                        <DefaultText style={styles.contentText}>HINT: it can be anything that you find scary - </DefaultText>
                        <DefaultText style={styles.contentText}>this dog is just an example!</DefaultText>
                    </View>
                </View>
            )
        }

        const Step2 = () => {
            return (
                <View>
                    <View style={styles.rowContainer}>
                        <DefaultText style={styles.contentText}>Now do another quick sketch of the same scary </DefaultText>
                        <DefaultText style={styles.contentText}>thing, but less scary - more friendly.</DefaultText>

                        <View style={styles.imageContainer}>
                            <CartoonItImage2 />
                        </View>
                    </View>
                </View>

            )
        }

        const Step3 = () => {
            return (
                <View>

                    <View style={styles.rowContainer}>

                        <DefaultText style={styles.contentText}>Now do a third take, making the scary thing look</DefaultText>
                        <DefaultText style={styles.contentText}>funny. Notice how it’s not as scary anymore!</DefaultText>

                        <View style={CommonStyles.flexContainer}>
                            <View style={styles.rowContainer}>
                                <WhyDoesItWorkComponent message={"Art therapy is a form of psychotherapy that uses art as a means of expression and communication. Art therapy is based on the idea that the creative process of making art can help people explore their emotions, thoughts, and behaviours, and can provide a way for them to cope with and overcome psychological and emotional challenges.\n\nAdditionally, research has shown that art therapy can be effective in the treatment of specific disorders such as PTSD, anxiety, and depression, that often come along with high level of fear."} />
                            </View>
                            <View style={styles.rowContainer}>
                                <View style={styles.imageContainer}>
                                    <CartoonItImage3 />
                                </View>
                            </View>
                        </View>
                    </View>


                </View>

            )
        }

        const contents = [
            { Component: Step1, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/cartoon-it-pg1.mp3' },
            { Component: Step2, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/cartoon-it-pg2.mp3' },
            { Component: Step3, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/cartoon-it-pg3.mp3' },
        ]

        return (
            <>
                <SwitchCardJuniorContent contents={contents} scheme={Emotion.SCARED} />
            </>
        )
    },
    CardContent: () => (
        <>
        </>
    ),
    cardContentTemplate: 'column',
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: '',
    BottomRow: () => <View />,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Express emotions through art by transforming something scary into something silly.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin with a short discussion about feelings, asking the children if they've ever felt scared or worried. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that sometimes we can use art to help us understand and change how we feel.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity - Transforming Scary to Silly (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Draw the Scary Thing: Instruct the students to grab a pencil and paper and quickly draw the thing that scares them. Emphasise putting in all the scary bits.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Draw a Friendly Version: Now, ask them to do another quick sketch of the same scary thing but make it less scary, more friendly. Encourage them to soften the scary features.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Draw a Funny Version: For the third take, instruct the students to make the scary thing look funny. Encourage exaggeration and playful elements. Notice and discuss how it's not as scary anymore.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Discussion and Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the children together and ask them to share their drawings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how changing the way they drew the scary thing made it seem less frightening.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the idea that sometimes, when we look at things in a different way, they can become less scary or more manageable.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the activity, highlighting how art can be a powerful tool for expressing and transforming our feelings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the children to keep using the Cartoon it! switch to explore their emotions and to remember that sometimes, a little creativity can turn something scary into something silly and fun.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pens / pencils</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Paper</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>100 Kids Tell Us Their Fears </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=Aq7gFfvhrAM' />
                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Scared]
}
const styles = StyleSheet.create({
    contentText: {
        // color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        marginHorizontal: 20
    },
})


