import { LegacyRef, RefObject, forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { TouchableOpacity, StyleSheet, TextInput, View, Pressable, Platform, GestureResponderEvent } from 'react-native'
import { Teacher } from '../models'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from './default-text'
import _ from 'lodash'
import { TextStyle } from 'react-native'
import { StyleProp } from 'react-native'

type Props = {
    value?: string
    onChangeText?: (text: string) => void
    placeholder?: string
    width?: number
    data?: any[] | Teacher[]
    selectedData?: any
    setSelectedData?: (data: any) => void
    type: InputSearchComponentType
    withoutSearch?: boolean
    nextRef?: RefObject<TextInput>
    onBlur?: () => void
    inputStyle?: StyleProp<TextStyle>
}

export enum InputSearchComponentType {
    Teacher,
    TeacherRole
}

const InputSearchComponentRounded = forwardRef((props: Props, ref: LegacyRef<TextInput>) => {
    const { placeholder, value, data, type, width, selectedData, withoutSearch, nextRef } = props
    const [showListData, setShowListData] = useState(false)
    const popupSelectorRef = useRef<any>(null)

    useEffect(() => {
        if (Platform.OS === 'web' && document) {
            // listen to outside clice
            document.addEventListener('mousedown', closeOpenMenus)
        }
    })

    const handleSelectData = (e: GestureResponderEvent, d: typeof data) => {
        e.preventDefault()
        props?.setSelectedData && props?.setSelectedData(d)
        setShowListData(false)
    }

    const handleFocus = useCallback(() => {
        setShowListData(true)
    }, [data])

    const handleSubmitEditing = () => {
        if (nextRef) {
            nextRef.current?.focus()
            handleBlur()
        }
    }

    const handleOnPressKey = (event: any) => {
        if (event?.key === "Tab" && nextRef) {
            event.preventDefault();
            handleSubmitEditing()
        }
    }

    const handleBlur = () => {
        _.delay(() => {
            if (Platform.OS !== 'web') {
                setShowListData(false)
            }
            props.onBlur && props.onBlur()
        }, 150)
    }

    const closeOpenMenus = (e: any) => {
        if (popupSelectorRef.current && showListData && !popupSelectorRef.current?.contains(e.target)) {
            // handle outside click
            // should close the list component popup when clicking outside
            setShowListData(false)
            return
        }
    }
    return (
        <View>
            <View style={styles.inputContainer}>
                <TextInput
                    ref={ref}
                    style={[styles.inputStyle, { width, fontStyle: value?.length ? 'normal' : 'italic' }, props.inputStyle]}
                    placeholder={placeholder}
                    value={type === InputSearchComponentType.Teacher ? value : selectedData}
                    onChangeText={props?.onChangeText}
                    placeholderTextColor={DecidaColors.AppleSystemGrayLight}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onSubmitEditing={handleSubmitEditing}
                    onEndEditing={handleSubmitEditing}
                    onKeyPress={handleOnPressKey}
                />
            </View>
            {!withoutSearch && showListData && data?.length ? (
                <View ref={popupSelectorRef} style={[styles.overlay, { padding: showListData && data?.length ? 10 : undefined }]}>
                    <>
                        {data?.slice(0, type === InputSearchComponentType.TeacherRole ? data.length : 10).map((d, i) => {
                            if (type === InputSearchComponentType.Teacher) {
                                return (
                                    <TouchableOpacity key={String(i)} style={styles.option} onPress={(event) => handleSelectData(event, d)}>
                                        <DefaultText style={styles.fullNameText}>{`${d.firstName} ${d.lastName}`}</DefaultText>
                                        <DefaultText style={styles.emailText}>{d.email}</DefaultText>
                                    </TouchableOpacity>
                                )
                            }

                            if (type === InputSearchComponentType.TeacherRole) {
                                return (
                                    <TouchableOpacity key={String(i)} style={styles.option} onPress={(event) => handleSelectData(event, d)}>
                                        <DefaultText style={styles.fullNameText}>{d.label}</DefaultText>
                                    </TouchableOpacity>
                                )
                            }
                        })}
                    </>
                </View>
            ) : null}
        </View>

    )
})

export default InputSearchComponentRounded

const styles = StyleSheet.create({
    inputContainer: {
        zIndex: 3
    },
    inputStyle: {
        backgroundColor: DecidaColors.White,
        padding: 5,
        marginBottom: 10,
        borderRadius: 20,
        borderWidth: 1,
        borderColor: DecidaColors.AppleSystemGrayLight,
    },
    overlay: {
        position: 'absolute',
        top: 18,
        backgroundColor: DecidaColors.White,
        borderColor: DecidaColors.AppleSystemGrayLight,
        borderWidth: 1,
        width: '100%'
    },
    option: {
        flex: 1,
        backgroundColor: DecidaColors.White,
        paddingHorizontal: 10,
        paddingVertical: 5,
    },
    fullNameText: {
        fontSize: 14,
        color: DecidaColors.AppleSystemGray2Dark
    },
    emailText: {
        fontSize: 12,
        color: DecidaColors.Sad
    }
})