import { gql, useQuery } from '@apollo/client'
import { StyleSheet, View } from 'react-native'
import { CheckInSession, GetClassQuery, GetClassQueryVariables, ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables, StudentClass } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { getClass, listCheckInSessionsByClassId } from '../../common/graphql/queries'
import { DefaultText } from './default-text'
import WeeklyGraphs from './weekly-graphs'
import { convertStudentCheckInCacheCodeToCacheObject } from '../../common/student-check-in-cache'
import { groupByWeek } from './group-by-week'

type Props = {
  checkstate: boolean
  studentClass: StudentClass
}
const ClassWeeklyCheckInGraphs = ({ checkstate, studentClass }: Props) => {
  const { data } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: studentClass?.classID }, skip: !studentClass.classID })
  const clazz = data?.getClass

  const { data: checkInSessionsResponse } = useQuery<ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables>(gql`${listCheckInSessionsByClassId}`, { variables: { classID: studentClass?.classID || "", limit: 5000 }, skip: !studentClass?.classID })
  const currentClassCheckInSessions = checkInSessionsResponse?.listCheckInSessionsByClassId?.items.filter((item) => item?._deleted !== true) || []

  const currentSessionByStudentCheckIn = currentClassCheckInSessions.filter((data) => {
    const studentCheckIns = studentClass?.studentCheckIns?.map(sc => convertStudentCheckInCacheCodeToCacheObject(sc))
    const studentCheckIn = studentCheckIns?.find(value => value.checkInSessionClassSequenceNo === data?.checkInSessionClassSequenceNo && clazz?.id === studentClass?.classID)
    if (studentCheckIn) {
      return data
    }
  })
  const checkInSessionsByWeek = groupByWeek(currentSessionByStudentCheckIn as CheckInSession[])


  if (!clazz || clazz?.archived || clazz?._deleted) {
    return null
  }

  return (
    <View style={styles.container}>
      <DefaultText style={styles.classNameText}>{data?.getClass?.name || ""}</DefaultText>
      {checkInSessionsByWeek.length ? (
        <WeeklyGraphs
          checkInSessionsByWeek={checkInSessionsByWeek}
          checkstate={checkstate}
          currentStudentClass={studentClass}
        />
      ) : (
        <DefaultText>No data as yet</DefaultText>
      )}

    </View>
  )
}
export default ClassWeeklyCheckInGraphs

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderWidth: 0.5,
    borderRadius: 20,
    borderColor: DecidaColors.GrayText,
    marginTop: 40,
    marginHorizontal: 40,
    minHeight: 'auto',
    padding: 20,
  },
  classNameText: {
    fontSize: 28,
    fontWeight: 'bold',
    color: DecidaColors.GrayText,
  }
})