import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { VisualiseIcon } from '../../explore/src/visualise-icon'
import { VisualiseIconInverted } from '../../explore/src/visualise-icon-inverted'
import { VisualiseImage1 } from './src/visualise-image1'
import { VisualiseLessonPlanImage1 } from './src/visualise-lessonplan-image1'
import { VisualiseLessonPlanImage2 } from './src/visualise-lessonplan-image2'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import SwitchCardVideo from '../../../common/switch-card-video'


export const SwitchesEmotionsScaredVisualise: CardTemplate = {
    cardId: "9b8b0ee7-3ecb-4571-886b-45203d3ff924",
    title: 'Visualise',
    Header: VisualiseIconInverted,
    Icon: VisualiseIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <VisualiseImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Gaining confidence and reducing panic about a future event, activity or social situation."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Think of what is making you feel scared."} />
                <DefaultTextWithDownArrow textdata={"Now, spend a few minutes to visualise yourself overcoming the fear, full of confidence, like the fear doesn’t affect you at all."} />
                <DefaultTextWithoutArrows textdata={"Think about what the impact of doing it confidently will have on yourself and others around you."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: 'Brain imagery research shows that visualisation works because our brains interpret imagery as equivalent to a real-life action. When we visualise an act, the brain tells our neurons to “perform” the movement. This creates a new neural pathway — clusters of cells in our brain that work together to create memories or learned behaviors — that primes our body to act in a way consistent to what we imagined. All of this occurs without actually performing the physical activity, yet it achieves a similar result.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Discuss the physiological traits of demonstrating courage.

                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Use reflective journaling to recall evidence of being brave.
                        </DefaultText>

                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice using visualisation to manifest a desired behaviour.
                        </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin with a discussion about superheroes. Ask students to identify their favourite superhero and describe what makes them brave.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Brainstorm character traits of superheroes on the whiteboard. Include traits such as decisive, determined, optimistic, famous, motivated, and resilient.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to stand up and pose like a superhero for 2 minutes, using the "Superhero power pose".</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the concept of power poses and how they can influence our thoughts and feelings.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Main Activity - Courage and Fear (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the paradox of courage and fear coexisting. Explain that courage isn't about eliminating fear but about facing it and taking action despite it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Share examples of how courage may look impressive from the outside but feel frightening on the inside.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students reflect on a time when they demonstrated courage. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the "A Tale of Courage" worksheet and ask them to write or draw a reflective story about their experience.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their stories with the class, fostering a supportive and celebratory atmosphere.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Applying Superpowers to Overcome Fears (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students that they are their own courageous superheroes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think about something currently making them feel scared or anxious.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their devices, guide them to find the Visualise Switch in the Switch4Schools app.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to spend a couple of minutes imagining themselves overcoming this fear with confidence. They can close their eyes or draw their visualisation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief by discussing the insights gained from the visualisation activity.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the lesson by emphasising the coexistence of courage and fear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to embrace their inner superhero and apply their newfound courage to challenges.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude with a positive and motivating message, reinforcing the idea that they have the power to face fears and overcome challenges.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Paper/notebook </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Art supplies </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='A Tale of Courage worksheet' link={Worksheets.aTaleOfCourage.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>How to Be Confident and Reduce Stress in 2 Minutes Per Day - </DefaultText>
                        <DefaultTextWithLink text='https://jamesclear.com/body-language-how-to-be-confident' />


                        <DefaultText style={CommonStyles.lessonPlanText}>Who inspires you? Why heroes, role models, and mentors matter | Dyan deNapoli | TEDxDrewMiddleSchool - </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=kZfEFzf4Jec' />
                        <SwitchCardVideo video={{
                            id: '1',
                            url: 'https://www.youtube.com/embed/kZfEFzf4Jec'
                        }} />

                        <DefaultText style={CommonStyles.lessonPlanText}>The Power Of Visualization And How To Use It - </DefaultText>
                        <DefaultTextWithLink text='https://www.forbes.com/sites/lidijaglobokar/2020/03/05/the-power-of-visualization-and-how-to-use-it/?sh=5cdf21766497' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Visualisation along with repetition and effort is a powerful way to achieve positive behavioural change but it is a skill that might need a bit of practice.' />
            <DefaulTextWithTipsIcon textdata='It’s helpful to calm the body and mind as much as possible with some deep breathing. ' />
            <DefaulTextWithTipsIcon textdata='Attach feeling to the visualisation to make it even more impactful.' />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Visualise.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short, CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Scared]
}
