import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import { parse, useURL } from 'expo-linking';
import { useEffect, useState } from 'react';
import { TextInput } from 'react-native';
import { CommonStyles, LAST_ACCESSED_VERSION_KEY } from '../common/const';
import { DefaultButton } from '../common/default-button';
import { getRouteNameAndParamsFromUrl } from '../common/helper-functions';
import { Loading, rvIsLoading } from '../common/loading';
import { Page } from '../common/page';
import { RootViewBackButton } from '../common/root-view-back-button';
import { ScreenNames } from '../common/screen-names';
import { showAlert } from '../common/universal-alert';
import { versionNumber } from '../env';
import { authSignIn } from './login-functions';
import { LoginStackNavigatorParamList } from './login-route-param-types';
import { PasswordHintText } from './password-hint-text';

export const LoginClassLogin = () => {
    const { navigate, replace, setParams } = useNavigation<StackNavigationProp<LoginStackNavigatorParamList>>();
    const url = useURL();

    const parsedUrl = parse(url ? decodeURI(url) : "/LoginClassLogin");
    const { path, queryParams } = parsedUrl;

    const { routeName } = getRouteNameAndParamsFromUrl(url);

    const goBack = () => {
        navigate(ScreenNames.Login);
    };

    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [classCode, setClassCode] = useState('');
    const [classNickname, setClassNickname] = useState('');

    useEffect(() => {
        if (queryParams?.username && queryParams?.password) {
            handleAutoLogin(String(queryParams?.username), String(queryParams?.password))
        }
    }, [queryParams?.username, queryParams?.password]);


    const classLoginSubmit = async (username: string, password: string) => {
        setDisableSubmitButton(true);
        AsyncStorage.setItem(LAST_ACCESSED_VERSION_KEY, versionNumber);
        authSignIn(username, password, replace, () => {
            showAlert({
                title: 'Incorrect class code',
                message: "The class code you have entered is incorrect.",
                leftButtonText: 'Ok',
            });
            setDisableSubmitButton(false);
        });
    };

    const handleAutoLogin = async (username: string, password: string) => {
        try {
            rvIsLoading(true);
            await classLoginSubmit(username, password);
            window.history.replaceState(null, "", "/LoginClassLogin");
            setParams({ username: "", password: "" });
        } catch (error) {
            console.error({ error });
        }
    };

    return (
        <RootViewBackButton onPress={goBack}>
            <Loading />
            <Page>
                <PasswordHintText>Enter the class nickname for your class.</PasswordHintText>
                <TextInput
                    style={CommonStyles.textInput}
                    placeholder="class nickname"
                    autoCorrect={false}
                    autoComplete='username'
                    autoCapitalize='none'
                    keyboardType='email-address'
                    onChangeText={setClassNickname}
                    textContentType='username'
                />
                <PasswordHintText>Enter the class code for your class.</PasswordHintText>
                <TextInput
                    style={CommonStyles.textInput}
                    placeholder="classcode"
                    autoCorrect={false}
                    autoComplete='password'
                    autoCapitalize='none'
                    onChangeText={setClassCode}
                    textContentType='password'
                />
                <DefaultButton disabled={disableSubmitButton} onPress={() => classLoginSubmit(classNickname, classCode)}>Submit</DefaultButton>
            </Page>
        </RootViewBackButton>
    );
};
