import { createStackNavigator } from '@react-navigation/stack'
import { CheckInEmotionalIntensity } from '../check-in/check-in-emotional-intensity'
import { CheckInEmotionalWheel } from '../check-in/check-in-emotional-wheel'
import { CheckInRequestChatWithTeacher } from '../check-in/check-in-request-chat-with-teacher'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'
import { CheckInTirednessScale } from '../check-in/check-in-tiredness-scale'
import { RootView } from '../common/root-view'
import { ScreenNames } from '../common/screen-names'
import { TeacherCheckInFinish } from '../teacher/teacher-check-in-finish'
import { ClassLoginHome } from './class-login-home'
import { gql, useReactiveVar, useSubscription } from '@apollo/client'
import { rvClassLoginCurrentCheckIn } from './class-login-state'
import { ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables, OnMutateStudentCheckInByCheckInSessionIdSubscription, OnMutateStudentCheckInByCheckInSessionIdSubscriptionVariables } from '../../common/API'
import { onMutateStudentCheckInByCheckInSessionId } from '../../common/graphql/subscriptions'
import { updateListQueryCache } from '../common/update-list-query-cache'
import { listStudentCheckInsByCheckInSessionId } from '../../common/graphql/queries'
import { handleRestartApp } from '../common/handle-restart-app'
import { CheckInWellbeingQuestions } from '../check-in/check-in-wellbeing-questions'
import CheckInEating from '../check-in/check-in-eating'


const Stack = createStackNavigator<CheckInNavigatorParamList>()

export const ClassLoginCheckInMain = () => {

    const currentCheckIn = useReactiveVar(rvClassLoginCurrentCheckIn)

    const { error: studentCheckInnSubscriptionnError } = useSubscription<OnMutateStudentCheckInByCheckInSessionIdSubscription, OnMutateStudentCheckInByCheckInSessionIdSubscriptionVariables>(
        gql`${onMutateStudentCheckInByCheckInSessionId}`,
        {
            variables: {
                checkinsessionID: currentCheckIn?.id || "",
            },
            onData: updateListQueryCache<ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables, OnMutateStudentCheckInByCheckInSessionIdSubscription>({ listQuery: listStudentCheckInsByCheckInSessionId, listQueryName: 'listStudentCheckInsByCheckInSessionId', subscriptionName: 'onMutateStudentCheckInByCheckInSessionId', variables: { checkinsessionID: currentCheckIn?.id || "", limit: 5000 } }),
            skip: !currentCheckIn
        }
    )

    if (studentCheckInnSubscriptionnError !== undefined) {
        handleRestartApp()
    }

    return (
        <RootView>
            <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={ScreenNames.CheckInStart}>
                <Stack.Screen name={ScreenNames.CheckInEmotionalIntensity} component={CheckInEmotionalIntensity} />
                <Stack.Screen name={ScreenNames.CheckInEmotionalWheel} component={CheckInEmotionalWheel} />
                <Stack.Screen name={ScreenNames.CheckInFinish} component={TeacherCheckInFinish} />
                <Stack.Screen name={ScreenNames.CheckInRequestChatWithTeacher} component={CheckInRequestChatWithTeacher} />
                <Stack.Screen name={ScreenNames.CheckInStart} component={ClassLoginHome} />
                <Stack.Screen name={ScreenNames.CheckInEating} component={CheckInEating} />
                <Stack.Screen name={ScreenNames.CheckInTirednessScale} component={CheckInTirednessScale} />
                <Stack.Screen name={ScreenNames.CheckInWellbeingQuestion} component={CheckInWellbeingQuestions} />
            </Stack.Navigator>
        </RootView>
    )
}