import { StyleSheet } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

export const SwitchesLessonPlansUnderstandingAndManagingScared: CardTemplate = {
    cardId: "141116a4-dab8-4b4b-8dc1-208953b2791d",
    title: 'Understanding and managing scared',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to recognise, understand, manage and constructively use feeling scared to enhance self awareness.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: Approximately 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Greet the students and explain the purpose of the lesson: to understand and manage fear effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share their understanding of fear and its effects on themselves and others.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Defining Fear (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the definition of scared, emphasising that it is a natural emotion that helps protect us from potential dangers.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that fear can be triggered by real or perceived threats, and it can manifest physically, emotionally, and mentally.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share examples of situations or things that make them scared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Brainstorm the different ways people experience and express being scared, such as increased heart rate, sweating, or avoidance behaviours. Refer to the Little Book of Big Emotion for more information.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding the Purpose of Fear (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the purpose of being scared as an adaptive response that alerts us to potential dangers and prepares us to respond.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that being scared can provide an opportunity for self-protection, caution, and problem-solving.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share personal experiences where being scared has played a role in their lives and what they have learned from those experiences.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Developing Self awareness (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the concept of self awareness as the ability to recognise and understand one's own thoughts, feelings, and behaviours.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the connection between self awareness and managing fear. Self awareness means understanding your own thoughts, feelings, and actions. It's like knowing yourself really well, including how you react to different situations and what makes you feel certain emotions, like being scared. Self awareness is important when it comes to managing being scared because it helps you face your fears, stay calm, and find courage. Here's how it works:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Recognising Fear: When you're self-aware, you can recognise when you're feeling scared. You can identify the signs in your body and the thoughts that come with it. For example, you might notice that your heart beats faster, you feel butterflies in your stomach, or you have thoughts that make you feel scared or worried.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Understanding Your Triggers: Self awareness helps you understand what triggers or situations make you feel afraid. It could be something specific like speaking in front of a group or trying something new. By being aware of these triggers, you can better prepare yourself and find ways to cope when they arise.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Challenging Negative Thoughts: Self awareness allows you to challenge negative thoughts that may intensify your fear. When you're self-aware, you can recognise when your thoughts might be exaggerating the danger or creating unnecessary worry. You can remind yourself of your strengths and abilities, and challenge those negative thoughts with positive and realistic ones.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Taking Deep Breaths and Staying Calm: When you're self-aware, you can practice calming techniques when facing fear. Taking deep breaths, counting to ten, or using other relaxation techniques can help you stay calm and cantered when you're feeling afraid. It allows you to regain control and think more clearly.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Gradual Exposure: Self awareness helps you take gradual steps to face your fears. By starting with small, manageable challenges related to your fear, you can gradually build up your confidence and overcome it. This process is called exposure, and it's a way to confront your fears in a safe and controlled manner.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Seeking Support: Being self-aware means recognising when you might need extra support. It's okay to ask for help when you're feeling scared. Talking to a trusted adult, like a parent, teacher, or counsellor, can provide guidance and support when managing your fears becomes challenging. They can offer strategies and encouragement to help you overcome your fears.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind students that managing fear doesn't mean never feeling afraid. Fear is a natural and normal emotion that everyone experiences. It's about understanding your fears, taking steps to face them, and finding the courage to move forward. Self awareness is a powerful tool that helps you navigate your fears and develop resilience. If you ever feel overwhelmed by fear or find it difficult to manage on your own, don't hesitate to reach out to someone you trust for support. They can provide guidance and help you through challenging situations. You're not alone, and it's important to take care of yourself and seek help when needed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a guided reflection exercise, encouraging students to think about their own experiences of being scared and how they typically respond. Have students complete the worksheet titled A tale of courage (also referenced in the Visualise switch lesson plan).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share their reflections and discuss strategies for improving self awareness in dealing with fear. Be sure to celebrate and cheer for achievements of their peers.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Coping Strategies for Fear (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce a variety of coping strategies for fear, such as deep breathing exercises, positive self-talk, seeking support from others, or gradually facing fears in a controlled way.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students access the Switch4Schools program on their device and encourage them to explore the available switches assigned to sad on the emotion wheel to bookmark activities they’d like to try.  Alternatively provide a copy of the level 5 quick reference guide. This could also be a homework activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the benefits of some of the techniques selected by the group and when they might be most helpful.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the key points discussed during the lesson, emphasising the importance of understanding, and managing being scared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to understand that being scared is a natural response, but it is important to learn how to manage it in a healthy and productive way. Remind them that seeking support from trusted individuals, using coping strategies, and gradually facing fears can help them overcome and manage being scared effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice the coping strategies and emotional intelligence skills they have learned to enhance self awareness and navigate fear effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Answer any questions or concerns the students may have.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard/markers or chart paper/poster</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='A tale of courage worksheet' link={Worksheets.aTaleOfCourage.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Effects of Chronic Fear on a Person's Health -</DefaultText>
                        <DefaultTextWithLink text='https://www.ajmc.com/view/the-effects-of-chronic-fear-on-a-persons-health' />
                        <DefaultText style={CommonStyles.lessonPlanText}>How to Help Children Manage Fears -</DefaultText>
                        <DefaultTextWithLink text='https://childmind.org/article/help-children-manage-fears/' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    durationText: {
        marginBottom: 10,
        color: DecidaColors.Gray,
        alignSelf: 'center'
    },
})
