import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { StyleSheet, View } from 'react-native'
import { CustomCreateTeacherMutation, CustomCreateTeacherMutationVariables, CustomDeleteTeacherMutation, CustomDeleteTeacherMutationVariables, CustomUpdateTeacherMutation, CustomUpdateTeacherMutationVariables, GetListTeacherBySchoolIDQuery, GetListTeacherBySchoolIDQueryVariables, GetSchoolAdminByCognitoUsernameQuery, GetSchoolAdminByCognitoUsernameQueryVariables, Teacher } from '../../common/API'
import { generatePassword } from '../../common/generate-password'
import { customCreateTeacher, customDeleteTeacher, customUpdateTeacher } from '../../common/graphql/mutations'
import { getListTeacherBySchoolID, getSchoolAdminByCognitoUsername } from '../../common/graphql/queries'
import { copyTextToClipboard } from '../common/copy-text-to-clipboard'
import DefaultTable, { TableColumn } from '../common/default-table'
import { DefaultText } from '../common/default-text'
import { rvIsLoading } from '../common/loading'
import { showAlert } from '../common/universal-alert'
import { getSchoolCampusesAndGroupsQuery, GetSchoolCampusesAndGroupsQueryResponse } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query'
import { rvCurrentUser } from '../login/login-state'


const SchoolAdminHierarchyTeachersList = () => {
    const user = useReactiveVar(rvCurrentUser)

    const { data: resultSchoolAdmin } = useQuery<GetSchoolAdminByCognitoUsernameQuery, GetSchoolAdminByCognitoUsernameQueryVariables>(gql`${getSchoolAdminByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "", limit: 5000 }, skip: !user })
    const currentSchoolAdmin = resultSchoolAdmin?.getSchoolAdminByCognitoUsername?.items[0]

    const { data: schoolData } = useQuery<GetSchoolCampusesAndGroupsQueryResponse>(getSchoolCampusesAndGroupsQuery, { variables: { id: currentSchoolAdmin?.schoolID }, skip: !currentSchoolAdmin })

    const { data: teachersData } = useQuery<GetListTeacherBySchoolIDQuery, GetListTeacherBySchoolIDQueryVariables>(gql`${getListTeacherBySchoolID}`, { variables: { schoolID: schoolData?.getSchool.id || "", limit: 5000 }, skip: !schoolData?.getSchool })
    const teachers = teachersData?.getListTeacherBySchoolID?.items.filter((teacher) => teacher?._deleted !== true) as Teacher[]

    const [customCreateTeacherMutation] = useMutation<CustomCreateTeacherMutation, CustomCreateTeacherMutationVariables>(gql`${customCreateTeacher}`,)
    const [customUpdateTeacherMutation] = useMutation<CustomUpdateTeacherMutation, CustomUpdateTeacherMutationVariables>(gql`${customUpdateTeacher}`,)
    const [customDeleteTeacherMutation] = useMutation<CustomDeleteTeacherMutation, CustomDeleteTeacherMutationVariables>(gql`${customDeleteTeacher}`,)

    const handleCreateTeacher = async (item: Teacher) => {
        try {
            rvIsLoading(true)
            const password = generatePassword()
            await customCreateTeacherMutation({
                variables: {
                    input: {
                        schoolID: currentSchoolAdmin?.schoolID || "",
                        cognitoUsername: item.email,
                        firstName: item?.firstName,
                        lastName: item?.lastName,
                        email: item?.email,
                        role: item?.role,
                        password,
                    }
                }
            })
            copyTextToClipboard(password)
            showAlert({
                title: 'Successfully created teacher',
                message: `username: ${item.email} \n password: ${password}`,
                leftButtonText: 'Ok'
            })
        } catch (error: any) {
            showAlert({
                title: "Something went wrong",
                message: error?.message || "",
                leftButtonText: "Ok"
            })
        } finally {
            rvIsLoading(false)
        }
    }

    const handleEditTeacher = async (data: Teacher) => {
        try {
            rvIsLoading(true)
            await customUpdateTeacherMutation({
                variables: {
                    input: {
                        id: data?.id,
                        email: data?.email,
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        preferredUsername: data?.preferredUsername,
                        role: data?.role
                    }
                }
            })
            showAlert({
                title: 'Update teacher',
                message: `Successfully update teacher`,
                leftButtonText: 'Ok'
            })
        } catch (error: any) {
            showAlert({
                title: "Something went wrong",
                message: error?.message || "",
                leftButtonText: "Ok"
            })
        } finally {
            rvIsLoading(false)
        }
    }

    const handleDeleteTeacher = async (data: Teacher) => {
        try {
            rvIsLoading(true)
            await customDeleteTeacherMutation({
                variables: {
                    input: {
                        teacherID: data?.id
                    }
                }
            })
            showAlert({
                title: 'Delete teacher',
                message: 'Successfully deleted teacher',
                leftButtonText: 'Ok'
            })
        } catch (error: any) {
            showAlert({
                title: "Something went wrong",
                message: error?.message || "",
                leftButtonText: "Ok"
            })
        } finally {
            rvIsLoading(false)
        }
    }


    const columns = [
        { label: 'Username', key: 'cognitoUsername' },
        { label: 'First Name', key: 'firstName' },
        { label: 'Last Name', key: 'lastName' },
        { label: 'Email', key: 'email' },
        { label: 'Role', key: 'role' },
        { label: 'Actions', key: 'action_buttons' },
    ] as TableColumn[];

    return (
        <View style={styles.container}>
            <DefaultText style={styles.label} >Classroom teachers</DefaultText>
            <DefaultTable
                data={teachers}
                columns={columns}
                onCreate={handleCreateTeacher}
                onEdit={handleEditTeacher}
                onDelete={handleDeleteTeacher}
            />
        </View>
    )
}
export default SchoolAdminHierarchyTeachersList

const styles = StyleSheet.create({
    rowedCentered: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    label: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    container: {
        flex: 1,
        padding: 40,
        gap: 10,
    },
})