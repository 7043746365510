import ReactGA from 'react-ga';
import { s4sGaTrackingId } from '../env';

export const initializeGA = () => {
    ReactGA.initialize(s4sGaTrackingId);
};

export const logPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
};