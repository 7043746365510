import { useReactiveVar } from "@apollo/client"
import { AntDesign } from '@expo/vector-icons'
import { Text, TouchableOpacity, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import SwitchCardVideo from "../../../common/switch-card-video"
import { Worksheets } from "../../../common/worksheets-imports"
import { Videos } from "../../../models"
import { CardColorScheme, rvCard } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { ValuesIcon } from '../../explore/src/values-icon'
import { ValuesIconInverted } from '../../explore/src/values-icon-inverted'
import { ValuesImage1 } from './src/values-image1'
import { rvShowValues } from './state/value-state'
import { openUrlWebView } from "../../../web-view/open-url-web-view"
import { CommonStyles, DefaultLessonPlanContentFontsize } from "../../../common/const"
import DefaultTextWithLink from "../../common/default-text-with-link"
import DefaultTextWithCustomLink from "../../common/default-text-with-custom-link"

const valuesVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/om3INBWfoxY",
    title: ""
}

export const SwitchesEmotionsHappyValues: CardTemplate = {
    cardId: "4e09b451-b5f4-4591-8ac7-bb1e6ae86322",
    title: 'Values',
    lessonPlan: true,
    Header: ValuesIconInverted,
    Icon: ValuesIcon,
    PerfectFor: () => {
        const emotionColor = useReactiveVar(rvCard)?.colorScheme
        const showValues = useReactiveVar(rvShowValues)
        const closeList = () => {
            rvShowValues(false)
        }
        return (
            <>
                <View style={commonCardStyles.buttoncontainer}>
                    <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                    <DefaultTextWithoutArrowsItallic textdata={"Creating clarity and focus about your personal priorities."} />
                    {showValues
                        ?
                        <View>
                            <TouchableOpacity onPress={closeList} style={{ display: 'flex', alignItems: 'flex-end', padding: 20, }}>
                                <AntDesign name="closesquare" size={40} color={emotionColor} />
                            </TouchableOpacity>

                            <View style={commonCardStyles.containerofvalues}>
                                <View style={commonCardStyles.valuecontainer1}>
                                    <DefaultTextWithoutArrowsBold textdata={"ACCEPTANCE"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be accepted as I am"} />
                                    <DefaultTextWithoutArrowsBold textdata={"ACCURACY"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be accurate in my opinions and beliefs"} />
                                    <DefaultTextWithoutArrowsBold textdata={"ACHIEVEMENT"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to have important accomplishments"} />
                                    <DefaultTextWithoutArrowsBold textdata={"ADVENTURE"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to have new and exciting experiences"} />
                                    <DefaultTextWithoutArrowsBold textdata={"ATTRACTIVENESS"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be physically attractive"} />
                                    <DefaultTextWithoutArrowsBold textdata={"AUTHORITY"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be in charge of and responsible for others"} />
                                    <DefaultTextWithoutArrowsBold textdata={"AUTONOMY"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be self-determined and independent"} />
                                    <DefaultTextWithoutArrowsBold textdata={"BEAUTY"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to appreciate beauty around me"} />
                                    <DefaultTextWithoutArrowsBold textdata={"CARING"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to take care of others"} />
                                    <DefaultTextWithoutArrowsBold textdata={"CHALLENGE"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to take on difficult tasks and problems"} />
                                    <DefaultTextWithoutArrowsBold textdata={"CHANGE"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to have a life full of change and variety"} />
                                    <DefaultTextWithoutArrowsBold textdata={"COMFORT"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to have a pleasant and comfortable life"} />
                                    <DefaultTextWithoutArrowsBold textdata={"COMMITMENT"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to make enduring, meaningful commitments"} />
                                    <DefaultTextWithoutArrowsBold textdata={"COMPASSION"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to feel and act on concern for others"} />
                                    <DefaultTextWithoutArrowsBold textdata={"CONTRIBUTION"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to make a lasting contribution in the world"} />
                                    <DefaultTextWithoutArrowsBold textdata={"COOPERATION"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to work collaboratively with others"} />
                                    <DefaultTextWithoutArrowsBold textdata={"COURTESY"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be considerate and polite toward others"} />
                                    <DefaultTextWithoutArrowsBold textdata={"CREATIVITY"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to have new and original ideas"} />
                                    <DefaultTextWithoutArrowsBold textdata={"DEPENDABILITY"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be reliable and trustworthy"} />
                                    <DefaultTextWithoutArrowsBold textdata={"DUTY"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to carry out my duties and obligations"} />
                                    <DefaultTextWithoutArrowsBold textdata={"ECOLOGY"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to live in harmony with the environment"} />
                                    <DefaultTextWithoutArrowsBold textdata={"EXCITEMENT"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to have a life full of thrills and stimulation"} />
                                    <DefaultTextWithoutArrowsBold textdata={"FAITHFULNESS"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be loyal and true in relationships"} />
                                    <DefaultTextWithoutArrowsBold textdata={"FAME"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be known and recognised"} />
                                    <DefaultTextWithoutArrowsBold textdata={"FAMILY"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to have a happy, loving family"} />
                                    <DefaultTextWithoutArrowsBold textdata={"FITNESS"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be physically fit and strong"} />
                                    <DefaultTextWithoutArrowsBold textdata={"FLEXIBILITY"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to adjust to new circumstances easily"} />
                                    <DefaultTextWithoutArrowsBold textdata={"FORGIVENESS"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be forgiving of others"} />
                                    <DefaultTextWithoutArrowsBold textdata={"FRIENDSHIP"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to have close, supportive friends"} />
                                    <DefaultTextWithoutArrowsBold textdata={"GENEROSITY"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to give what I have to others"} />
                                    <DefaultTextWithoutArrowsBold textdata={"GENUINESS"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to act in a manner that is true to who I am"} />
                                    <DefaultTextWithoutArrowsBold textdata={"GOD’S WILL"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to seek and obey the will of God"} />
                                    <DefaultTextWithoutArrowsBold textdata={"GROWTH"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to keep changing and growing"} />
                                    <DefaultTextWithoutArrowsBold textdata={"HEALTH"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be physically well and healthy"} />
                                    <DefaultTextWithoutArrowsBold textdata={"HELPFULNESS"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be helpful to others"} />
                                    <DefaultTextWithoutArrowsBold textdata={"HONESTY"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be honest and truthful"} />
                                    <DefaultTextWithoutArrowsBold textdata={"HOPE"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to maintain a positive and optimistic outlook"} />
                                    <DefaultTextWithoutArrowsBold textdata={"HUMILITY"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to be modest and unassuming"} />
                                    <DefaultTextWithoutArrowsBold textdata={"HUMOUR"} />
                                    <DefaultTextWithoutArrowsItallic textdata={"to see the humorous side of myself and the world"} />
                                </View>
                                <View style={commonCardStyles.valuecontainer2}>
                                    <View style={commonCardStyles.valuecontainer1}>
                                        <DefaultTextWithoutArrowsBold textdata={"INDEPENDENCE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to be free from dependence on others"} />
                                        <DefaultTextWithoutArrowsBold textdata={"INDUSTRY"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to work hard and well at my life tasks"} />
                                        <DefaultTextWithoutArrowsBold textdata={"INNER PEACE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to experience personal peace"} />
                                        <DefaultTextWithoutArrowsBold textdata={"JUSTICE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to promote fair and equal treatment for all"} />
                                        <DefaultTextWithoutArrowsBold textdata={"KNOWLEDGE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to learn and contribute valuable knowledge"} />
                                        <DefaultTextWithoutArrowsBold textdata={"LEISURE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to take time to relax and enjoy"} />
                                        <DefaultTextWithoutArrowsBold textdata={"LOVED"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to be loved by those close to me"} />
                                        <DefaultTextWithoutArrowsBold textdata={"LOVING"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to give love to others"} />
                                        <DefaultTextWithoutArrowsBold textdata={"MASTERY"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to be competent in my everyday activities"} />
                                        <DefaultTextWithoutArrowsBold textdata={"MINDFULNESS"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to live conscious and mindful of the present moment"} />
                                        <DefaultTextWithoutArrowsBold textdata={"MODERATION"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to avoid excesses and find a middle ground"} />
                                        <DefaultTextWithoutArrowsBold textdata={"NON-CONFORMITY"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to question and challenge authority and norms"} />
                                        <DefaultTextWithoutArrowsBold textdata={"NURTURANCE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to take care of and nurture others"} />
                                        <DefaultTextWithoutArrowsBold textdata={"OPENNESS"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to be open to new. experiences, ideas, and options"} />
                                        <DefaultTextWithoutArrowsBold textdata={"ORDER"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to have a life that is well- ordered and organised"} />
                                        <DefaultTextWithoutArrowsBold textdata={"PASSION"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to have deep feelings about ideas, activities, or people"} />
                                        <DefaultTextWithoutArrowsBold textdata={"PLEASURE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to feel good"} />
                                        <DefaultTextWithoutArrowsBold textdata={"POPULARITY"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to be well-liked by many people"} />
                                        <DefaultTextWithoutArrowsBold textdata={"POWER"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to have control over others"} />
                                        <DefaultTextWithoutArrowsBold textdata={"PURPOSE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to have meaning and direction in my life"} />
                                        <DefaultTextWithoutArrowsBold textdata={"RATIONALITY"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to be guided by reason and logic"} />
                                        <DefaultTextWithoutArrowsBold textdata={"REALISM"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to see and act realistically and practically"} />
                                        <DefaultTextWithoutArrowsBold textdata={"RESPONSIBILITY"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to make and carry out responsible decisions"} />
                                        <DefaultTextWithoutArrowsBold textdata={"RISK"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to take risks and chances"} />
                                        <DefaultTextWithoutArrowsBold textdata={"ROMANCE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to have intense, exciting love in my life"} />
                                        <DefaultTextWithoutArrowsBold textdata={"SAFETY"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to be safe and secure"} />
                                        <DefaultTextWithoutArrowsBold textdata={"SELF-ACCEPTANCE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to accept myself as I am"} />
                                        <DefaultTextWithoutArrowsBold textdata={"SELF-CONTROL"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to be disciplines in my own actions"} />
                                        <DefaultTextWithoutArrowsBold textdata={"SELF-ESTEEM"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to feel good about myself"} />
                                        <DefaultTextWithoutArrowsBold textdata={"SELF-KNOWLEDGE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to have a deep and honest understanding of myself"} />
                                        <DefaultTextWithoutArrowsBold textdata={"SERVICE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to be of service to others"} />
                                        <DefaultTextWithoutArrowsBold textdata={"SIMPLICITY"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to live life simply, with minimal needs"} />
                                        <DefaultTextWithoutArrowsBold textdata={"SOLITUDE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to have time and space where I can be apart from others"} />
                                        <DefaultTextWithoutArrowsBold textdata={"SPIRITUALITY"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to grow and mature spiritually"} />
                                        <DefaultTextWithoutArrowsBold textdata={"STABILITY"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to have a life that stays fairly consistent"} />
                                        <DefaultTextWithoutArrowsBold textdata={"TOLERANCE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to accept and respect those who differ from me"} />
                                        <DefaultTextWithoutArrowsBold textdata={"TRADITION"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to follow respected patterns of the past"} />
                                        <DefaultTextWithoutArrowsBold textdata={"VIRTUE"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to live a morally pure and excellent life"} />
                                        <DefaultTextWithoutArrowsBold textdata={"WEALTH"} />
                                        <DefaultTextWithoutArrowsItallic textdata={"to have plenty of money"} />
                                    </View>
                                </View>
                            </View>


                        </View>
                        :
                        <View />}
                </View>
            </>
        )
    },
    Images: () => (
        <>
            <ValuesImage1 />
        </>
    ),
    CardContent: () => {

        const ShowValues = () => {
            rvShowValues(true)
        }

        return (

            <>
                <DefaultTextWithoutArrowsBold textdata={""} />
                <DefaultTextWithoutArrowsItallic textdata={""} />

                <View style={commonCardStyles.container}>
                    <DefaulttextWithRightArrow textdata='Spend a few minutes individually listing all the values that are very important to you.' />
                    <TouchableOpacity onPress={ShowValues} >
                        <DefaultText style={{ color: DecidaColors.DarkBlue }} children='Use this list as a thought starter.' />
                    </TouchableOpacity>


                    <DefaulttextWithRightArrow textdata='Make sure you have at least 10 values (you can have as many as you want though).' />
                    <DefaulttextWithRightArrow textdata={"From your list, pick the top five. "} />
                    <DefaulttextWithRightArrow textdata={"Now order the top five in rank order with the most important being number one."} />
                    <DefaulttextWithRightArrow textdata={"Share with others your number one value, and why it is number one for you."} />
                </View>
            </>


        )
    },
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: 'Our values are part of everything that we do. From our work, to our family, to how we treat our friends and the people we meet in the course of our day, our values should be driving our behaviour. A lack of clarity into our core values is interpreted by our brain as exposing ourselves to danger, and can lead to feelings of emptiness, isolation, and an overall sense of stress or anxiety.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Evaluate different attributes to identify personal values that form a key part of personal identity.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the video: "Wellbeing For Children: Identity And Values."</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss briefly with students the concept of identity and values, emphasising the importance of understanding oneself.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• main:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Hand out the Values Identifier Worksheet and explain STEP 1.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Support students in cross checking their personal list with the provided Values List.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ They should write on any additional values that are missing.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Sorting and Ranking (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to use three different coloured pens/pencils to sort the values into three groups: Very important to me, Sort of important to me, Not important to me. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ From the list of values that very important have students choose and circle five that are the most the important to them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to rank these five values in order of importance, with the most important value numbered as 1. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Sharing (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students complete STEP 3 of the Values Identifier Worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their number one ranked value and explain why it is crucial to them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a brief discussion on the variety of values and their significance in shaping individual identity.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the idea that understanding personal values is key to building a strong sense of identity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, guide them to find the "Values" switch in the Switch4Schools app.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to reflect on their identified values regularly as they navigate life.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Alternative activity – values card sort activity:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Print and cut out the values card </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to sort the cards into three piles: Very important to me, Sort of important to me, Not important to me. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remove the cards in the 'Not important to me' pile. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lay out the remaining cards and have the student consider if there is something important missing for them. If so write it on a blank card and add it to the appropriate pile.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Take the Very important to me pile and choose the top five more important. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Rank order these five with the most important at the top.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Take a photo and/or write this down for future reference. </DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text="Values worksheet" link={Worksheets.valueIdentifier.uri} /> </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text="Values list" link={Worksheets.valueList.uri} /> </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text="Values cards" link={Worksheets.valueCards.uri} /> </DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Wellbeing For Children: Identity And Values - </DefaultText>
                        <DefaultTextWithLink text="https://www.youtube.com/watch?v=om3INBWfoxY" />
                        <SwitchCardVideo video={valuesVideo} />
                    </>}
            />
        </ >
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Values.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Happy]
}
