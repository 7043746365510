import { useReactiveVar } from '@apollo/client'
import { AntDesign } from '@expo/vector-icons'
import { Modal, Pressable, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { CardCategoryType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTimeType, CardType } from '../switches/common/card-template'
import { CardVideoAgeGroupTypes, CardVideoFilterEmotionType, CardVideoTimeTypes } from '../switches/tools/cards/video-library'
import { SwitchFilterIcon } from '../switches/tools/icons/switch-filter-icon'
import { rvFilterActivitySheetsModal, rvFilterExploreSwitchesModal, rvFilterSwitchesData, rvFilterVideoLibraryModal } from './common-state'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultButton } from './default-button'
import { DefaultText } from './default-text'
import SwitchCardFilterExploreActivitySheets from './switch-card-filter-activity-sheets-groups'
import SwitchCardFilterExploreGroups from './switch-card-filter-explore-groups'
import { SwitchCardFilterMethods } from './switch-card-filter-methods'
import SwitchCardFilterExploreVideo from './switch-card-filter-video-groups'
import { CardActivitySheetsEmotionFilter, CardActivitySheetsThoughtActionFilter, CardActivitySheetsTimeFilter } from './worksheets-imports'

export type SwitchCardFilterMainType = (string | CardType)[]
export type SwitchCardFilterCategoryType = (string | CardCategoryType | CardVideoAgeGroupTypes)[]

// interface Example<T extends MyClass | OtherClass> {}


export type SwitchCardFilterType = {
  main: SwitchCardFilterMainType,
  time: (string | CardTimeType | CardVideoTimeTypes | CardActivitySheetsTimeFilter)[],
  level: (string | CardIntensitylevelType)[],
  place: (string | CardPlaceType)[],
  people: (string | CardPeopleType)[],
  emotion: (string | CardFilterEmotionType | CardVideoFilterEmotionType | CardActivitySheetsEmotionFilter)[],
  category: SwitchCardFilterCategoryType,
  thoughtOrAction: (string | CardActivitySheetsThoughtActionFilter)[]
}

type Props = {
  filterType: "explore" | "video" | "activity_sheets"
}

const SwitchCardFilter = ({ filterType }: Props) => {
  const modalExploreFilterOpen = useReactiveVar(rvFilterExploreSwitchesModal)
  const modalVideoFilterOpen = useReactiveVar(rvFilterVideoLibraryModal)
  const modalActivitySheetsFilterOpen = useReactiveVar(rvFilterActivitySheetsModal)
  const isExplore = filterType === 'explore'
  const isActivitySheets = filterType === 'activity_sheets'

  const setFilters = (data: SwitchCardFilterType) => {
    rvFilterSwitchesData(data)
  }

  const handleClearFilter = () => {

    SwitchCardFilterMethods.clearFilter()

    handleCloseFilter()
  }

  const handleCloseFilter = () => {
    switch (filterType) {
      case "explore":
        SwitchCardFilterMethods.hideExploreFilter()
        break
      case "video":
        SwitchCardFilterMethods.hideVideoFilter()
        break
      case "activity_sheets":
        SwitchCardFilterMethods.hideActivitySheetsFilter()
        break
    }
  }

  return (
    <View>
      <Modal
        visible={isExplore ? modalExploreFilterOpen : isActivitySheets ? modalActivitySheetsFilterOpen : modalVideoFilterOpen}
        transparent
        style={styles.modalContainer}
      >
        <Pressable style={styles.modalBackground} onPress={handleCloseFilter}>
          <Pressable style={styles.modalContentContainer} onPress={(e) => e.preventDefault()}>
            <View style={styles.modalHeaderContainer}>
              <View style={styles.filterHeaderTextContainer}>
                <View style={styles.filterIcon}>
                  <SwitchFilterIcon />
                </View>
                <DefaultText style={styles.filterText}>Filters</DefaultText>
              </View>
              <TouchableOpacity style={styles.closeIcon} onPress={handleCloseFilter}>
                <AntDesign name="close" size={28} color={DecidaColors.Gray} />
              </TouchableOpacity>
            </View>
            <ScrollView>
              {filterType === "explore" && (
                <SwitchCardFilterExploreGroups />
              )}

              {filterType === "video" && (
                <SwitchCardFilterExploreVideo />
              )}

              {filterType === "activity_sheets" && (
                <SwitchCardFilterExploreActivitySheets />
              )}
            </ScrollView>
            <View style={styles.buttonsContainer}>
              <TouchableOpacity style={styles.cancelButton} onPress={handleClearFilter}>
                <DefaultText style={styles.smallText}>
                  Clear filters
                </DefaultText>
              </TouchableOpacity>
              <DefaultButton style={styles.applyButton} onPress={handleCloseFilter} buttonTextStyle={styles.largeText}>
                SEARCH
              </DefaultButton>
            </View>
          </Pressable>
        </Pressable>
      </Modal >
    </View >
  )
}
export default SwitchCardFilter
const styles = StyleSheet.create({
  modalBackground: {
    backgroundColor: "rgba(0,0,0,0.7)",
    height: '100%',
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContentContainer: {
    backgroundColor: DecidaColors.White,
    width: '80%',
    height: '90%',
    paddingTop: 20,
    borderRadius: 20,
    maxWidth: 650,
  },
  modalHeaderContainer: {
    alignItems: 'center',
    borderBottomWidth: 1,
    paddingBottom: 10,
    borderBottomColor: DecidaColors.Gray
  },
  content: {
    paddingHorizontal: 40,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: DecidaColors.Gray,
  },
  buttonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 40
  },
  cancelButton: {
    width: '48%',
  },
  applyButton: {
    width: 150,
    borderRadius: 20
  },
  modalContainer: {
    alignContent: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    paddingHorizontal: 10,
  },
  closeText: {
    fontSize: 16
  },
  filterIcon: {
    width: 24,
    height: 24
  },
  filterHeaderTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterText: {
    color: DecidaColors.Gray,
    fontSize: 26,
    fontWeight: '700',
    marginLeft: 10
  },
  smallText: {
    fontSize: 18
  },
  largeText: {
    fontSize: 24,
    fontWeight: 'bold',
  }
})