import { StyleSheet, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import { DefaultText } from '../../common/default-text'
import SwitchCardVideo from '../../common/switch-card-video'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { DecidaColors } from '../../../common/decida-colors'

const emotionalExpressionVideo1: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/AJ-e5Q4vhsk",
    title: ""
}

export const SwitchesLessonPlansEmotionalExpression: CardTemplate = {
    cardId: "5802e2ff-6b22-459c-8349-23b9e5b4647a",
    title: 'Emotional expression',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to express emotions assertively and constructively.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes </DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by engaging the students in a group discussion about the importance of expressing emotions. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to share examples of situations when they found it challenging to express their emotions  </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Assertive Emotional Expression (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the concept of assertive emotional expression and its benefits. Show relevant slides. Assertive emotion expression means expressing how you feel in a strong and confident way while still being respectful to others. When you're assertive, you use words and actions to let people know how you feel without being mean or aggressive. It's important because it helps others understand you better and promotes healthy communication. Here are some benefits of being assertive: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Self-Respect: When you express your emotions assertively, you show that your feelings are important and deserve to be heard. It helps you develop a sense of self-respect and confidence.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Healthy Relationships: Being assertive helps you build better relationships with others. It allows you to express your needs and boundaries, and also listen to the feelings of others. This way, everyone can understand each other better and work together to find solutions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Problem Solving: Assertive emotion expression helps you address conflicts and problems in a positive way. By calmly expressing how you feel, you can work towards finding solutions together with others instead of letting things build up or resorting to aggression.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Empathy and Understanding: When you express your emotions assertively, it helps others understand how you feel. It opens up a chance for them to show empathy and offer support. By being assertive, you encourage others to be more understanding and caring. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Building Confidence: The more you practice assertive emotion expression, the more confident you become in expressing yourself. It helps you develop strong communication skills and boosts your self-esteem. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce that being assertive doesn't mean you always get your way, but it ensures that your feelings are acknowledged and considered. It's about finding a balance between expressing yourself and respecting others. Practice using "I" statements, such as saying "I feel sad when you take my toy without asking" instead of blaming or being mean. By being assertive, you can have healthier and happier relationships with those around you. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the difference between assertive, aggressive, and passive communication styles. Refer to the slides.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Assertive Communication: When you communicate assertively, you express your thoughts, feelings, and needs in a confident and respectful way. You use clear and kind words to explain how you feel and what you want, while still considering the feelings of others. You listen to others and try to understand their perspective as well. Assertive communication is about finding a balance between expressing yourself and respecting others. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Aggressive Communication: Aggressive communication involves expressing your thoughts and feelings in a forceful and disrespectful manner. It often involves yelling, name-calling, or using mean words to hurt others or get your way. Aggressive communication can make others feel scared, upset, or defensive. It doesn't consider the feelings or rights of others and can damage relationships. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Passive Communication: Passive communication is when you don't express your thoughts, feelings, or needs clearly or at all. You may avoid speaking up, keep your feelings to yourself, or let others make decisions for you. Passive communication often involves putting others' needs above your own and not standing up for yourself. It can lead to misunderstandings, frustration, and feeling like your opinions don't matter. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide examples of assertive statements and ask students to practice saying them aloud.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "I feel upset when you take my things without asking. Please ask for permission first." </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "I don't like it when you make fun of me. It hurts my feelings. Please stop."</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "I feel left out when you don't include me in your games. Can I join in too?" </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "I disagree with you, but I still respect your opinion. Let's find a way to work together." </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "I feel scared when you chase me. It would make me feel safer if we played a different game." </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "I'm having a hard time understanding this question. Can you explain it to me in a different way?" </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Role-play: Expressing Emotions Assertively (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide the class into pairs or small groups.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide each group with a role-play scenario card where assertive emotional expression is needed. Refer to the handout. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>i. Scenario: Group Project Conflict</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Pretend you're working on a group project, and one team member is not contributing their fair share. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice assertive emotional expression by calmly expressing your concerns to the team member, suggesting a solution, and asking for their cooperation. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>ii. Scenario: Dealing with Peer Pressure </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Imagine your friends want you to do something you're not comfortable with, like breaking a rule.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice assertive emotional expression by confidently saying "no" and explaining your reasons, asserting your boundaries, and suggesting alternative activities that align with your values.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>iii. Scenario: Handling Teasing or Name-Calling</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Pretend someone is teasing or calling you names at school. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice assertive emotional expression by calmly telling the person how their words make you feel, asserting your right to be treated respectfully, and seeking help from a teacher or trusted adult if necessary. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>iv. Scenario: Negotiating Screen Time with Parents</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Imagine you want to negotiate the amount of screen time you're allowed with your parents.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice assertive emotional expression by explaining your viewpoint, sharing the benefits of responsible screen use, suggesting compromises, and being open to finding a fair agreement.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>v. Scenario: Resolving a Friendship Conflict</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Pretend you and your friend had an argument or misunderstanding. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice assertive emotional expression by calmly expressing your feelings, listening to your friend's perspective, finding common ground, and working together to resolve the conflict.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>vi. Scenario: Requesting Personal Space</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Imagine you need some alone time or privacy in your room.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice assertive emotional expression by politely asking family members to respect your need for personal space and explaining why it's important to you.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In their groups, students should act out the scenarios while using assertive communication techniques.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to provide feedback and suggestions to their peers after each role-play.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Closure (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share one assertive communication technique they learned and one coping strategy they find helpful.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of practicing assertive emotional expression and developing resilience and coping skills. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Express with confidence' link={Worksheets.expressWithConfidenceWorksheet.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Slides' link={"https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Emotional+Expression.pptx"} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Enhancement of self-regulation, assertiveness, and empathy -</DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/importance-of-emotional-intelligence/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Communicate assertively -</DefaultText>
                        <DefaultTextWithLink text='https://youtu.be/AJ-e5Q4vhsk' />
                        <SwitchCardVideo video={emotionalExpressionVideo1} />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    durationText: {
        marginBottom: 10,
        color: DecidaColors.Gray,
        alignSelf: 'center'
    },
})
