import { useState, useRef, useEffect, Dispatch, SetStateAction } from 'react'
import { View, StyleSheet, TextInput, TextStyle, ViewStyle, Dimensions, Platform, ScrollView, TouchableOpacity } from 'react-native'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { CardColorScheme, rvCard } from '../../common/card-color-scheme'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { ColorHuntIcon } from '../../explore/src/color-hunt-icon'
import { ColorHuntImage1 } from './src/color-hunt-image1'
import { commonCardStyles, commonStyles } from '../../common/emotion-card-image-sizes'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { ColorHuntIconInverted } from '../../explore/src/color-hunt-icon-inverted'
import { DefaultButton } from '../../../common/default-button'
import { useReactiveVar } from '@apollo/client'
import { showAlert } from '../../../common/universal-alert'
import { DefaultText } from '../../../common/default-text'
import { CommonStyles, DefaultFontSize, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { AntDesign } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { captureRef } from 'react-native-view-shot';
import * as MediaLibrary from 'expo-media-library';
import * as Sharing from 'expo-sharing';
import uuid from 'react-native-uuid'
import { Audio } from 'expo-av';
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { Feather } from '@expo/vector-icons';
import { rvCurrentUser } from '../../../login/login-state'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithRvColor } from '../../common/default-text-with-rv-color'
import { LessonPlanContainer } from '../../common/lesson-plan-container'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { MaterialsEquipment } from '../../common/lesson-plan-const'
import { ColorHuntImage2 } from './src/color-hunt-image2'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { emotionColor } from '../../../common/get-emotion-color'
import moment from 'moment'
import { Link } from '../../../common/link'
import { Br } from '../../../common/br'
import LessonPlan from '../../common/lesson-plan'
import { Worksheets } from '../../../common/worksheets-imports'
import VideoCard from '../../../common/video-card'
import SwitchCardVideo from '../../../common/switch-card-video'
import { Videos } from '../../../models'
import InfoIconWIthContent from '../../../common/info-icon-with-content'
import { DecidaColors } from '../../../../common/decida-colors'


const { useScreenshot, createFileName } = Platform.OS === 'web' ? require('use-react-screenshot') : { useScreenshot: () => "defined playholder", createFileName: undefined }


const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        width: windowWidthcondition ? 500 : 300,
        height: 500,
        marginTop: 10,
    },
    timerContainer: {
        width: 300,
        height: 50,
        alignContent: 'flex-end',
        justifyContent: 'flex-end'
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    button: {
        width: 70,
        height: 25,
        borderRadius: 8
    },
    start: {
        width: 70,
        height: 25,
        borderRadius: 8,
        backgroundColor: emotionColor.EXCITED
    },
    reset: {
        width: 70,
        height: 25,
        borderRadius: 8,
        backgroundColor: DecidaColors.White,
        borderColor: DecidaColors.Gray,
        borderWidth: 1

    },
    buttonText: {
        textAlign: 'center',
        color: DecidaColors.White
    },
    tipText: {
        textAlign: 'center',
        color: DecidaColors.AppleSystemGray2Dark,
        marginTop: 50
    },
    resultText: {
        color: DecidaColors.AppleSystemGray2Dark,
        fontSize: 80
    },
    tipContainer: {
        paddingHorizontal: 50
    },
    timer: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    timerText: {
        fontSize: 28,
        fontWeight: 'bold',
        color: DecidaColors.AppleSystemGray2Dark

    },
    mainContainer: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    row: {
        width: windowWidthcondition ? 500 : 300,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: DecidaColors.Gray,
        marginVertical: 20,
    } as ViewStyle,
    textInput: {
        width: windowWidthcondition ? 330 : 125,
        borderColor: DecidaColors.Gray,
        borderWidth: 1,
        fontSize: DefaultFontSize,
        padding: 8,
        paddingLeft: 7,
        paddingRight: 7,
        marginTop: 7,
        marginBottom: 7,
        borderRadius: 4,
        backgroundColor: DecidaColors.InputField

    } as TextStyle,
    plusIcon: {
        alignItems: 'center',
        marginTop: 12,

    },
    header: {
        color: DecidaColors.White,
        fontWeight: 'bold',
        paddingLeft: -20,
        paddingTop: 15,
    },
    column: {
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
    },
    scrollViewContainer: {
        height: 450,

    },
    headingContainer: {
        position: 'absolute',
        left: 12,
    },
    deleteItemRow: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: "center",
    },
    deleteIcon: {
    },
    timePicker: {
        width: 75,
        height: 25,
        borderRadius: 8,
        backgroundColor: DecidaColors.Gray
    },
    timesUp: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    absolute: {
        position: 'absolute',
        right: -60
    },
    absolutePlus: {
        position: 'absolute',
        right: 20
    },
    usernameField: {
        justifyContent: 'center',
        alignContent: 'center',
        paddingVertical: 5,
    },
    usernameText: {
        textAlign: 'center'
    }

})



interface TextProps {
    itemSetter: Dispatch<SetStateAction<string>> | undefined
    item: string
}



const TextInputField = ({ itemSetter, item }: TextProps) => {
    return (
        <TextInput
            style={styles.textInput}
            onChangeText={itemSetter}
            value={item}
        />
    )
}




interface Props {
    heading: string;
    dataArray: string[];
    itemSetter: Dispatch<SetStateAction<string>>
    item: string
    arraySetter: Dispatch<SetStateAction<string[]>>
    DecidaColour: string
    disabled?: boolean

}


const ItemMapper = ({ heading, dataArray, item, itemSetter, arraySetter, DecidaColour, disabled }: Props) => {

    const addItem = () => {
        if (item && !disabled) {
            if (dataArray.filter(i => i === item).length > 0) {
                console.log('already exist')
            }
            else {
                var array = dataArray
                array.push(item)
                arraySetter(array)
                itemSetter('')
            }
        }
        null

    }

    const deleteItem = (item: string) => {
        if (!disabled) {
            var array = dataArray
            const filterArray = array.filter(i => i !== item)
            arraySetter(filterArray)
        }


    }

    return (
        <View style={[styles.row, { backgroundColor: DecidaColour }]}>
            <View style={styles.headingContainer}>
                <DefaultText style={styles.header}>
                    {heading}
                </DefaultText>
            </View>
            <View style={styles.column}>
                <View>
                    <TextInputField itemSetter={disabled ? undefined : itemSetter} item={item} />
                    {dataArray.map((d =>
                        <View style={styles.deleteItemRow} key={d}>
                            <TextInput
                                style={styles.textInput}
                                value={d}
                            />
                            <View style={styles.absolute}>
                                <TouchableOpacity onPress={() => deleteItem(d)} style={styles.deleteIcon}>
                                    <AntDesign name="delete" size={24} color={DecidaColors.White} />
                                </TouchableOpacity>
                            </View>
                        </View>
                    ))}
                </View>
            </View>
            <View style={styles.absolutePlus}>
                <TouchableOpacity onPress={addItem} style={styles.plusIcon}>
                    <AntDesign name="plus" size={32} color={DecidaColors.White} />
                </TouchableOpacity>
            </View>
        </View>
    )
}

const colorHuntVideo1: Videos = {
    id: '1',
    url: "https://player.vimeo.com/video/866210517",
    title: "Colour hunt video"
}
const ColourHunt = () => {
    const [timeState, setTimeState] = useState(0)
    const [timesUp, setTimesUp] = useState<boolean>(false)
    const [time, setTime] = useState(0)
    const [timeLength, SetTimeLength] = useState(0)
    const [pickTime, setPickTime] = useState(true)
    const [redItem, setRedItem] = useState('')
    const [redArray, setRedArray] = useState<string[]>([])
    const [orangeItem, setOrangeItem] = useState('')
    const [orangeArray, setOrangeArray] = useState<string[]>([])
    const [yellowItem, setYellowItem] = useState('')
    const [yellowArray, setYellowArray] = useState<string[]>([])
    const [greenItem, setGreenItem] = useState('')
    const [greenArray, setGreenArray] = useState<string[]>([])
    const [blueItem, setBlueItem] = useState('')
    const [blueArray, setBlueArray] = useState<string[]>([])
    const [indigoItem, setIndigoItem] = useState('')
    const [indigoArray, setIndigoArray] = useState<string[]>([])
    const [violetItem, setVioletItem] = useState('')
    const [violetArray, setVioletArray] = useState<string[]>([])
    const viewRef = useRef<View>(null);
    const [status, requestPermission] = MediaLibrary.usePermissions();
    const [imageURI, setImageURI] = useState('')
    const currentuser = useReactiveVar(rvCurrentUser)

    const [sound, setSound] = useState<Audio.Sound>();

    const playSound = async () => {
        console.log('Loading Sound');
        const { sound } = await Audio.Sound.createAsync(
            { uri: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/colour-hunt-bell.wav' }
        );
        setSound(sound);

        console.log('Playing Sound');
        await sound.playAsync();
    }

    const checkPermission = () => {
        requestPermission()
    }

    const [image, takeScreenShot] = useScreenshot()


    const onSavePress = async () => {

        if (Platform.OS === 'web') {

            await takeScreenShot(viewRef.current).then((image: any, { name = `${currentuser?.username} colour hunt result`, extension = "jpg" } = {}) => {
                const a = document.createElement("a");
                a.href = image;
                a.download = createFileName(extension, name);
                a.click();
            })
        } else {

            try {
                await checkPermission()
                const result = await captureRef(viewRef, {
                    result: "tmpfile",
                    quality: 1,
                    format: "png",
                });
                await Sharing.shareAsync("file://" + result);
                await MediaLibrary.createAssetAsync(result).then(filename => MediaLibrary.getAssetInfoAsync(filename).then(info => setImageURI(String(info.localUri))))
            } catch (e) {
                console.log(e);
            }

        }
    }

    const resetArrays = () => {
        setRedArray([])
        setOrangeArray([])
        setYellowArray([])
        setGreenArray([])
        setBlueArray([])
        setIndigoArray([])
        setVioletArray([])
        setRedItem('')
        setOrangeItem('')
        setYellowItem('')
        setGreenItem('')
        setBlueItem('')
        setIndigoItem('')
        setVioletItem('')
    }

    const totalChallangeResult = () => {
        return redArray.length + orangeArray.length + yellowArray.length + greenArray.length + blueArray.length + indigoArray.length + violetArray.length
    }

    const resetTimeandShare = () => {
        setTimesUp(false)
        onSavePress()
        setTimeState(0)
        resetArrays()
    }

    useEffect(() => {
        if (timesUp) {
            playSound()
        }

    }, [timesUp])



    const TimesUp = () => (
        <View style={styles.timesUp}>
            <DefaultText style={styles.tipText}>Congratulations! You got</DefaultText>
            <DefaultText style={styles.resultText}>{totalChallangeResult().toString()}</DefaultText>
        </View>
    )

    const storeData = async () => {

        const data = {
            id: uuid.v4().toString(),
            date: Date.now(),
            red: {
                redArray: redArray
            },
            orange: {
                orangeArray: orangeArray
            },
            yellow: {
                yellowArray: yellowArray
            },
            green: {
                greenArray: greenArray
            },
            blue: {
                blueArray: blueArray
            },
            indigo: {
                indigoArray: indigoArray
            },
            violet: {
                violetArray: violetArray
            },
        }

        try {
            const jsonValue = JSON.stringify(data)
            await AsyncStorage.setItem(data.id, jsonValue)
        } catch (e) {
            // saving error
        }
    }

    const timer = useRef(time)
    const timerInterval = useRef<number | undefined | NodeJS.Timer>(undefined)

    const startTimer = () => {
        if (timerInterval.current === undefined) {
            timerInterval.current = setInterval(() => {
                timer.current--
                setTime(timer.current)
                setTimeState(1)
                if (timer.current === 0) {
                    stopTimerTimesUp()
                    storeData()
                    setTimesUp(true)
                }
            }, 1000)
        }
    }

    const stopTimer = () => {
        if (timerInterval.current !== undefined) {
            setTimeState(0)
            clearInterval(timerInterval.current)
            timerInterval.current = undefined
        }
    }
    const stopTimerTimesUp = () => {
        if (timerInterval.current !== undefined) {
            clearInterval(timerInterval.current)
            timerInterval.current = undefined
        }
    }

    const resetTimer = () => {
        setTime(0)
        setTimeState(0)
        stopTimer()
        setTimesUp(false)
        setPickTime(true)
        resetArrays()

    }

    const add3Mins = () => {
        timer.current = 180
        setTime(180)
        SetTimeLength(180)
        setPickTime(false)
    }

    const add5Mins = () => {
        timer.current = 300
        setTime(300)
        SetTimeLength(300)
        setPickTime(false)
    }

    const add10Mins = () => {
        timer.current = 600
        setTime(600)
        SetTimeLength(600)
        setPickTime(false)
    }

    const newTimeLength = () => {
        stopTimer()
        setPickTime(true)
        setTimesUp(false)

    }

    return (

        <View style={styles.container}>

            <View style={styles.mainContainer}>
                <View style={styles.timerContainer}>
                    {time > 0 && (
                        <View style={styles.timer}>
                            <DefaultText style={styles.timerText}>
                                {moment.utc(time * 1000).format('mm:ss')}
                            </DefaultText>
                        </View>
                    )}

                    <View style={styles.buttonContainer}>
                        {!pickTime ?
                            <>
                                <TouchableOpacity style={styles.start} onPress={startTimer}>
                                    <DefaultText style={styles.buttonText}>
                                        Go!
                                    </DefaultText>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.reset} onPress={resetTimer}>
                                    <DefaultText style={[styles.buttonText, { color: DecidaColors.Gray }]}>
                                        Reset
                                    </DefaultText>
                                </TouchableOpacity>
                            </>
                            :
                            <>
                                <TouchableOpacity style={styles.timePicker} onPress={add3Mins}>
                                    <DefaultText style={styles.buttonText}>
                                        3 mins
                                    </DefaultText>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.timePicker} onPress={add5Mins}>
                                    <DefaultText style={styles.buttonText}>
                                        5 mins
                                    </DefaultText>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.timePicker} onPress={add10Mins}>
                                    <DefaultText style={styles.buttonText}>
                                        10 mins
                                    </DefaultText>
                                </TouchableOpacity>

                            </>

                        }
                    </View>

                </View>
                <View style={styles.tipContainer}>
                    {timeState ? (
                        null
                    ) : !pickTime ? (
                        <DefaultText style={styles.tipText}>
                            When you hit the go button, list as many objects in the colour boxes that you can. {"\n"}{"\n"}If you are playing with friends, the person with the most objects wins!
                        </DefaultText>

                    ) : (
                        <DefaultText style={styles.tipText}>
                            Select a time limit
                        </DefaultText>
                    )}
                </View>
                <ScrollView style={styles.scrollViewContainer} onScroll={(e) => e.preventDefault()}>
                    {timeState === 1 ?
                        <>
                            {timesUp ?
                                <TimesUp />
                                : <></>}
                            <View ref={viewRef}>
                                {/* <View style={styles.usernameField}>
                                    <DefaultText style={styles.usernameText}>{currentuser?.username}</DefaultText>
                                </View> */}
                                <ItemMapper disabled={timesUp} heading='Red' dataArray={redArray} item={redItem} itemSetter={setRedItem} arraySetter={setRedArray} DecidaColour={DecidaColors.Red} />
                                <ItemMapper disabled={timesUp} heading='Orange' dataArray={orangeArray} item={orangeItem} itemSetter={setOrangeItem} arraySetter={setOrangeArray} DecidaColour={DecidaColors.Orange} />
                                <ItemMapper disabled={timesUp} heading='Yellow' dataArray={yellowArray} item={yellowItem} itemSetter={setYellowItem} arraySetter={setYellowArray} DecidaColour={DecidaColors.Yellow} />
                                <ItemMapper disabled={timesUp} heading='Green' dataArray={greenArray} item={greenItem} itemSetter={setGreenItem} arraySetter={setGreenArray} DecidaColour={DecidaColors.Green} />
                                <ItemMapper disabled={timesUp} heading='Blue' dataArray={blueArray} item={blueItem} itemSetter={setBlueItem} arraySetter={setBlueArray} DecidaColour={DecidaColors.Blue} />
                                <ItemMapper disabled={timesUp} heading='Indigo' dataArray={indigoArray} item={indigoItem} itemSetter={setIndigoItem} arraySetter={setIndigoArray} DecidaColour={DecidaColors.Indigo} />
                                <ItemMapper disabled={timesUp} heading='Violet' dataArray={violetArray} item={violetItem} itemSetter={setVioletItem} arraySetter={setVioletArray} DecidaColour={DecidaColors.Violet} />
                            </View>
                        </>
                        : null}
                </ScrollView>
            </View>
        </View>

    )
}


export const SwitchesEmotionsExcitedColorHunt: CardTemplate = {
    cardId: "d505821e-bb23-451a-9742-1dd2d46884d5",
    title: 'Colour hunt',
    Header: ColorHuntIconInverted,
    Icon: ColorHuntIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <ColorHuntImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Unifying a groups’ energy levels and increasing awareness."} />
        </>
    ),
    CardContent: () => {
        const NavigateToStart = () => {
            showAlert({
                alertStyle: { maxWidth: windowWidthcondition ? 600 : 360 },
                title: 'Colour Hunt Challenge',
                message: <ColourHunt />,
                leftButtonText: 'Close',

            })
        }
        const color = useReactiveVar(rvCard)?.colorScheme
        return (
            <>
                <View style={commonCardStyles.container}>

                    <DefaultTextWithoutArrows textdata={"Select ‘Start Challenge’, set a time limit, and try to find all the colours of the rainbow on different items around you before the time runs out!"} />
                    <DefaultButton onPress={NavigateToStart} style={{ backgroundColor: color }}>Start exercise</DefaultButton>
                    <DefaultTextWithDownArrow textdata='' />
                    <DefaultTextWithoutArrows textdata={"If you have found one item for each colour within the specified timeframe, keep adding to the list to see how many items you can find for each colour before time’s up!"} />
                </View>
            </>
        )
    },
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: 'This mental challenge exercises awareness and perception. By looking for different coloured objects, it forces observational awareness without forcing mindfulness.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice paying attention to detail to focus and calm the mind.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the concept of selective attention and its role in managing information.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the connection between attention, focus, and a calmer state of mind. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage controlled breathing before starting the Colour Hunt activity.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity: Colour Hunt Switch (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If available, ask students to find the Colour Hunt Switch in the Switch4Schools app.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Alternatively, distribute Colour Hunt Worksheets to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Guide students in a short breathing exercise to establish calmness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage slow, deep breaths to help maintain a sense of calm.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the challenge: Find items representing the colours of the rainbow. Red, Orange, Yellow, Green, Blue, Indigo, Violet (or grouped as Purple).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set a time limit for the Colour Hunt activity (e.g., 10 minutes).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to type their responses into the app or use the Colour Hunt Worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to earn extra points by finding more items within the time limit.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After the time limit, have students share their findings with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Award points for each correct response and consider double points for unique identifications.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Celebrate the winner/s!</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on their experience during the Colour Hunt.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how paying attention to specific details affected their overall focus.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the role of the Colour Hunt in creating a calm and focused mindset.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how this activity can be applied in moments of excitement or stress.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Acknowledge students' efforts and participation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to integrate similar mindful activities into their daily routines.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, invite them to explore other switches in the Switch4Schools app. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Colour Hunt worksheet' link={Worksheets.colourHunt.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Hidden Benefits of Scavenger Hunts - </DefaultText>
                        <DefaultTextWithLink text="https://occupationaltherapy.com.au/hidden-benefits-of-scavenger-hunts/#:~:text=Scavenger%20hunts%20can%20provide%20the,help%20keep%20your%20child%20regulated" />
                        <Br />
                        <DefaultText style={CommonStyles.lessonPlanText}>How We Use Selective Attention to Filter Information and Focus - </DefaultText>
                        <DefaultTextWithLink text='https://www.verywellmind.com/what-is-selective-attention-2795022' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <SwitchCardVideo video={colorHuntVideo1} />
            <DefaulTextWithTipsIcon textdata='It can sometimes be helpful to group indigo and violet into one category of purple.' />
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Colour+Hunt.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Excited]
}

