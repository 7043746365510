import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { StopStepsIcon } from '../../explore/src/stop-steps-icon'
import { StopStepsIconInverted } from '../../explore/src/stop-steps-icon-inverted'
import { StopStepsImage1 } from './src/stop-steps-image1'
import { StopStepsLessonPlanImage1 } from './src/stopsteps-lessonplan-image1'
import { StopStepsLessonPlanImage2 } from './src/stopsteps-lessonplan-image2'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import SwitchCardVideo from '../../../common/switch-card-video'

export const SwitchesEmotionsAngryStopSteps: CardTemplate = {
    cardId: "1a91efd4-9d87-4f72-b270-64c4dbfb21ea",
    title: 'Stop steps',
    Header: StopStepsIconInverted,
    Icon: StopStepsIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Learning a real-time process to snap out of an angry state."} />
        </>
    ),
    Images: () => (
        <>
            <View style={{ flex: 1, width: '80%', alignSelf: 'center' }}>
                <StopStepsImage1 />
            </View>
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: 50, color: DecidaColors.Gray }}>S</Text>
                    <Text style={{ fontSize: 20, }}>top what you are doing.</Text>
                </View>
                <DefaultTextWithDownArrow textdata={""} style={{ paddingVertical: -10 }} />
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: 50, color: DecidaColors.Gray }}>T</Text>
                    <Text style={{ fontSize: 20, }}>ake a big, long, deep breath.</Text>
                </View>
                <DefaultTextWithDownArrow textdata={""} style={{ paddingVertical: -10 }} />
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: 50, color: DecidaColors.Gray }}>O</Text>
                    <Text style={{ fontSize: 20, }}>bserve your surroundings.</Text>
                </View>
                <DefaultTextWithoutArrowsItallic textdata={"Notice as many things as you can in your external world (physical environment) and your internal world (thoughts, body and emotions). Use these internal observations to think about what your possible next best steps are."} />
                <DefaultTextWithDownArrow textdata={""} style={{ paddingVertical: -10 }} />
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: 50, color: DecidaColors.Gray }}>P</Text>
                    <Text style={{ fontSize: 20, }}>roceed with the best thing to do.</Text>
                </View>
                <DefaultTextWithoutArrowsItallic textdata={"Take time to practice this twice a day for a whole week - irrespective of how you are feeling."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: 'The STOP activity helps avoid excessive ruminating over negative thoughts by staying more in the present moment. The S snaps the mind out of autopilot mode. The T helps anchor our attention on the present moment (instead of getting lost in negative thoughts). The O temporarily disengages you from your thoughts, shifting your attention to your surroundings and non emotive items. The P focusses on better decision making as you are more likely responding with your prefrontal cortex instead of your reactive amygdala (the fear centre of the brain).',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify and list personal triggers that cause an angry reaction,  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Improve situational awareness, and   </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Discuss criteria for making better decisions.   </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the metaphor of anger as a speeding car that can get out of control if not stopped in time.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the concept of anger stop signs as warning signs that indicate when anger is escalating.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Stop Steps switch and discuss the importance of recognising personal stop signals.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Reflecting on Anger (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on how they look and feel when they are annoyed and when they are furious.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them draw or describe these states and compare the two, looking for clues that indicate escalating anger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Using the Stop Steps worksheet, ask students to list their personal stop signals that serve as indicators to apply the STOP switch.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Sharing Stop Signals (7 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their stop signals with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage a discussion on similarities and differences in their stop signals, prompting them to refine and add to their lists as needed.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Explaining the STOP Switch (8 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the components of the STOP switch: Stop, Take a deep breath, Observe surroundings, and Proceed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that intentionally observing surroundings is a way to distract the brain from angry thoughts.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Practice the observation step with the My 5 Senses Scavenger Hunt worksheet, asking students to explore the classroom to find things that meet the criteria.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 4: Applying the STOP Switch in Scenarios (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss scenarios where someone might feel angry, and ask students to describe what the "best thing" to do would be in each situation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 1: Someone isn't playing the game fairly.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 2: Someone asks you to do something you don't want to do (e.g., clean your room).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Scenario 3: Someone isn't listening to you.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the STOP switch acronym and ask students to repeat it back.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points about recognising personal anger stop signs and applying the STOP switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of making good choices and proceeding with the best action in anger inducing situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice the STOP switch in real life scenarios.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own device, ask them to find the switch in the Switch4Schools app.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Stop Steps worksheets' link={Worksheets.stopSteps.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='My 5 Senses Scavenger Hunt worksheet ' link={Worksheets.scavengerHuntMyFiveSense.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Switch4Schools app (optional) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Drawing paper, markers, or coloured pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard and markers </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Timer or clock </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>How Thinking About the Future Makes Life More Meaningful -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://greatergood.berkeley.edu/article/item/how_thinking_about_the_future_makes_life_more_meaningful' link='https://greatergood.berkeley.edu/article/item/how_thinking_about_the_future_makes_life_more_meaningful' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Draw your future | Patti Dobrowolski | TEDxRainier -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.youtube.com/watch?v=zESeeaFDVSw' link='https://www.youtube.com/watch?v=zESeeaFDVSw' />

                        <SwitchCardVideo video={{
                            id: '1',
                            url: 'https://www.youtube.com/embed/zESeeaFDVSw'
                        }} />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Stop+Steps.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Angry]
}

