/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateStudentCheckInInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  studentID: string,
  emotion?: Emotion | null,
  emotionIntensity?: number | null,
  emotionIntensityPercentage?: number | null,
  tiredness?: number | null,
  checkinsessionID: string,
  classID: string,
  absence?: boolean | null,
  notYet?: boolean | null,
  requestForChat?: RequestForChatStatus | null,
  chatCleared?: boolean | null,
  eating?: EatingStatus | null,
  _version?: number | null,
};

export enum Emotion {
  HAPPY = "HAPPY",
  EXCITED = "EXCITED",
  SCARED = "SCARED",
  SAD = "SAD",
  ANGRY = "ANGRY",
  ANXIOUS = "ANXIOUS",
}


export enum RequestForChatStatus {
  YES = "YES",
  NO = "NO",
  NOT_YET = "NOT_YET",
}


export enum EatingStatus {
  YES = "YES",
  NO = "NO",
  PREFER_NOT_TO_SAY = "PREFER_NOT_TO_SAY",
}


export type ModelStudentCheckInConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  studentID?: ModelIDInput | null,
  emotion?: ModelEmotionInput | null,
  emotionIntensity?: ModelIntInput | null,
  emotionIntensityPercentage?: ModelFloatInput | null,
  tiredness?: ModelIntInput | null,
  checkinsessionID?: ModelIDInput | null,
  classID?: ModelIDInput | null,
  absence?: ModelBooleanInput | null,
  notYet?: ModelBooleanInput | null,
  requestForChat?: ModelRequestForChatStatusInput | null,
  chatCleared?: ModelBooleanInput | null,
  eating?: ModelEatingStatusInput | null,
  and?: Array< ModelStudentCheckInConditionInput | null > | null,
  or?: Array< ModelStudentCheckInConditionInput | null > | null,
  not?: ModelStudentCheckInConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelEmotionInput = {
  eq?: Emotion | null,
  ne?: Emotion | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelRequestForChatStatusInput = {
  eq?: RequestForChatStatus | null,
  ne?: RequestForChatStatus | null,
};

export type ModelEatingStatusInput = {
  eq?: EatingStatus | null,
  ne?: EatingStatus | null,
};

export type StudentCheckIn = {
  __typename: "StudentCheckIn",
  id: string,
  createdAt: string,
  updatedAt?: string | null,
  studentID: string,
  student?: Student | null,
  emotion?: Emotion | null,
  emotionIntensity?: number | null,
  emotionIntensityPercentage?: number | null,
  tiredness?: number | null,
  checkinsessionID: string,
  classID: string,
  class?: Class | null,
  absence?: boolean | null,
  notYet?: boolean | null,
  requestForChat?: RequestForChatStatus | null,
  chatCleared?: boolean | null,
  eating?: EatingStatus | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Student = {
  __typename: "Student",
  id: string,
  schoolStudentID?: string | null,
  nickname: string,
  avatar: string,
  schoolID: string,
  school?: School | null,
  yearLevel?: string | null,
  studentCheckIns?: ModelStudentCheckInConnection | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  status?: StudentStatus | null,
  cognitoUsername: string,
  classes?: ModelStudentClassConnection | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  onboarded?: boolean | null,
  initialLoginSetup?: boolean | null,
  activeClasses?: number | null,
  studentCheckInCount?: number | null,
  studentCheckInSkipSuggestionDate?: string | null,
  devicePushTokens?: Array< string > | null,
  wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
  wellbeingQuestionUsed?: Array< string > | null,
  currentWellbeingQuestion?: string | null,
  wellbeingResponseCount?: number | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type School = {
  __typename: "School",
  id: string,
  name: string,
  classes?: ModelClassConnection | null,
  campuses?: ModelCampusConnection | null,
  classGroups?: ModelClassGroupConnection | null,
  teachers?: ModelTeacherConnection | null,
  students?: ModelStudentConnection | null,
  staffs?: ModelStaffConnection | null,
  schoolAdmins?: ModelSchoolAdminConnection | null,
  schoolAdminUI?: SchoolAdminUI | null,
  utcOffset?: string | null,
  licenseTotal?: string | null,
  timezone?: string | null,
  status?: SchoolStatus | null,
  activeStudents?: number | null,
  trialEndDate?: string | null,
  renewalDate?: string | null,
  notes?: string | null,
  checkInCountAllTime?: number | null,
  checkInCountYTD?: number | null,
  checkInCountRollingWeekMonday?: number | null,
  checkInCountRollingWeekTuesday?: number | null,
  checkInCountRollingWeekWednesday?: number | null,
  checkInCountRollingWeekThursday?: number | null,
  checkInCountRollingWeekFriday?: number | null,
  checkInCountRollingWeekSaturday?: number | null,
  checkInCountRollingWeekSunday?: number | null,
  wholeSchoolSubscription?: boolean | null,
  billingAddress?: string | null,
  billingNotes?: string | null,
  invoiceUrl?: string | null,
  abn?: string | null,
  coordinatorID?: string | null,
  teacherCheckIns?: ModelTeacherCheckInConnection | null,
  staffCheckIns?: ModelStaffCheckInConnection | null,
  teacherWellbeing?: boolean | null,
  staffWellbeing?: boolean | null,
  wellbeingFeature?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelClassConnection = {
  __typename: "ModelClassConnection",
  items:  Array<Class | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Class = {
  __typename: "Class",
  id: string,
  name: string,
  schoolID: string,
  school?: School | null,
  classGroupID?: string | null,
  students?: ModelStudentClassConnection | null,
  classLogin?: ClassLogin | null,
  checkInSessions?: ModelCheckInSessionConnection | null,
  supports?: ModelClassSupportConnection | null,
  emotionIntensityLevel?: number | null,
  studentCheckIns?: ModelStudentCheckInConnection | null,
  toggleRequestForChat?: boolean | null,
  toggleRequestForChatNotifications?: boolean | null,
  toggleIndividualStudentStats?: boolean | null,
  teachers?: ModelTeacherClassConnection | null,
  schedules?: ModelScheduleClassConnection | null,
  bulkSignUpId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  primaryContact?: string | null,
  archived?: boolean | null,
  emailReceivers?: Array< string | null > | null,
  lastCheckInSessionClassSequenceNo?: number | null,
  checkInCountYTD?: number | null,
  checkInCountRollingWeekMonday?: number | null,
  checkInCountRollingWeekTuesday?: number | null,
  checkInCountRollingWeekWednesday?: number | null,
  checkInCountRollingWeekThursday?: number | null,
  checkInCountRollingWeekFriday?: number | null,
  checkInCountRollingWeekSaturday?: number | null,
  checkInCountRollingWeekSunday?: number | null,
  activeStudents?: number | null,
  goMode?: boolean | null,
  yearLevel?: string | null,
  lastCheckInTime?: string | null,
  wellbeing?: boolean | null,
  wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
  wellbeingQuestionUsed?: Array< string > | null,
  currentWellbeingQuestionID?: string | null,
  wellbeingResponseCount?: number | null,
  toggleEating?: boolean | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  classClassLoginId?: string | null,
};

export type ModelStudentClassConnection = {
  __typename: "ModelStudentClassConnection",
  items:  Array<StudentClass | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type StudentClass = {
  __typename: "StudentClass",
  id: string,
  studentID: string,
  student?: Student | null,
  classID: string,
  class?: Class | null,
  status?: StudentClassApprovalStatus | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  requestForChat?: boolean | null,
  requestForChatStatus?: RequestForChatStatus | null,
  studentCheckIns?: Array< string > | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum StudentClassApprovalStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
}


export type ClassLogin = {
  __typename: "ClassLogin",
  id: string,
  nickname: string,
  cognitoUsername: string,
  classID: string,
  classCode: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  devicePushTokens?: Array< string > | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelCheckInSessionConnection = {
  __typename: "ModelCheckInSessionConnection",
  items:  Array<CheckInSession | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type CheckInSession = {
  __typename: "CheckInSession",
  id: string,
  checkInSessionClassSequenceNo?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  classID: string,
  class?: Class | null,
  openAt?: string | null,
  closeAt?: string | null,
  isActive: boolean,
  studentCheckIns?: ModelStudentCheckInConnection | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelStudentCheckInConnection = {
  __typename: "ModelStudentCheckInConnection",
  items:  Array<StudentCheckIn | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelClassSupportConnection = {
  __typename: "ModelClassSupportConnection",
  items:  Array<ClassSupport | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ClassSupport = {
  __typename: "ClassSupport",
  id: string,
  classID: string,
  class?: Class | null,
  supportID: string,
  support?: Support | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Support = {
  __typename: "Support",
  id: string,
  email?: string | null,
  classes?: ModelClassSupportConnection | null,
  firstName: string,
  lastName: string,
  cognitoUsername: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelTeacherClassConnection = {
  __typename: "ModelTeacherClassConnection",
  items:  Array<TeacherClass | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type TeacherClass = {
  __typename: "TeacherClass",
  id: string,
  teacherID: string,
  teacher?: Teacher | null,
  classID: string,
  class?: Class | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Teacher = {
  __typename: "Teacher",
  id: string,
  email: string,
  schoolID: string,
  school?: School | null,
  firstName?: string | null,
  lastName?: string | null,
  classes?: ModelTeacherClassConnection | null,
  cognitoUsername: string,
  showIntroduction?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  role?: TeacherRole | null,
  onboarded?: boolean | null,
  devicePushTokens?: Array< string > | null,
  showNewFeatureInstructions?: boolean | null,
  preferredUsername?: string | null,
  wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
  wellbeingQuestionUsed?: Array< string > | null,
  wellbeingResponseCount?: number | null,
  teacherCheckIns?: ModelTeacherCheckInConnection | null,
  avatar?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum TeacherRole {
  ADMINISTRATION = "ADMINISTRATION",
  CHAPLAIN = "CHAPLAIN",
  CLASS_VOLUNTEER = "CLASS_VOLUNTEER",
  DEPUTY_PRINCIPAL = "DEPUTY_PRINCIPAL",
  GUIDANCE_OFFICER = "GUIDANCE_OFFICER",
  HEAD_OF = "HEAD_OF",
  LEARNING_SUPPORT = "LEARNING_SUPPORT",
  PRINCIPAL = "PRINCIPAL",
  PSYCHOLOGIST = "PSYCHOLOGIST",
  TEACHER = "TEACHER",
  TEACHER_AIDE = "TEACHER_AIDE",
  WELLBEING = "WELLBEING",
  YEAR_LEVEL_COORDINATOR = "YEAR_LEVEL_COORDINATOR",
}


export enum QuestionCategory {
  CONNECTEDNESS = "CONNECTEDNESS",
  PSYCHOLOGICAL_SAFETY = "PSYCHOLOGICAL_SAFETY",
  SOCIAL_IDENTITY = "SOCIAL_IDENTITY",
}


export type ModelTeacherCheckInConnection = {
  __typename: "ModelTeacherCheckInConnection",
  items:  Array<TeacherCheckIn | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type TeacherCheckIn = {
  __typename: "TeacherCheckIn",
  id: string,
  createdAt: string,
  updatedAt?: string | null,
  schoolID: string,
  school?: School | null,
  teacherID: string,
  teacher?: Teacher | null,
  emotion?: Emotion | null,
  emotionIntensityPercentage?: number | null,
  tiredness?: number | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelScheduleClassConnection = {
  __typename: "ModelScheduleClassConnection",
  items:  Array<ScheduleClass | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ScheduleClass = {
  __typename: "ScheduleClass",
  id: string,
  classID: string,
  class?: Class | null,
  schedule: string,
  isActive?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelCampusConnection = {
  __typename: "ModelCampusConnection",
  items:  Array<Campus | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Campus = {
  __typename: "Campus",
  id: string,
  name: string,
  schoolID: string,
  school?: School | null,
  classGroups?: ModelClassGroupConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelClassGroupConnection = {
  __typename: "ModelClassGroupConnection",
  items:  Array<ClassGroup | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ClassGroup = {
  __typename: "ClassGroup",
  id: string,
  name: string,
  schoolID: string,
  school?: School | null,
  campusID: string,
  campus?: Campus | null,
  classes?: ModelClassConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelTeacherConnection = {
  __typename: "ModelTeacherConnection",
  items:  Array<Teacher | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStudentConnection = {
  __typename: "ModelStudentConnection",
  items:  Array<Student | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStaffConnection = {
  __typename: "ModelStaffConnection",
  items:  Array<Staff | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Staff = {
  __typename: "Staff",
  id: string,
  cognitoUsername: string,
  email: string,
  schoolID: string,
  school?: School | null,
  firstName?: string | null,
  lastName?: string | null,
  preferredUsername?: string | null,
  wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
  wellbeingQuestionUsed?: Array< string > | null,
  wellbeingResponseCount?: number | null,
  teacherCheckIns?: ModelStaffCheckInConnection | null,
  avatar?: string | null,
  nonTeachingStaffCheckInCount?: number | null,
  nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
  onboarded?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelStaffCheckInConnection = {
  __typename: "ModelStaffCheckInConnection",
  items:  Array<StaffCheckIn | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type StaffCheckIn = {
  __typename: "StaffCheckIn",
  id: string,
  createdAt: string,
  updatedAt?: string | null,
  schoolID: string,
  school?: School | null,
  staffID: string,
  staff?: Staff | null,
  emotion?: Emotion | null,
  emotionIntensityPercentage?: number | null,
  tiredness?: number | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelSchoolAdminConnection = {
  __typename: "ModelSchoolAdminConnection",
  items:  Array<SchoolAdmin | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SchoolAdmin = {
  __typename: "SchoolAdmin",
  id: string,
  email: string,
  schoolID: string,
  school?: School | null,
  firstName?: string | null,
  lastName?: string | null,
  cognitoUsername: string,
  preferredUsername?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum SchoolAdminUI {
  LIST = "LIST",
  HIERARCHY = "HIERARCHY",
}


export enum SchoolStatus {
  ARCHIVED = "ARCHIVED",
  ON_HOLD = "ON_HOLD",
  ON_TRIAL = "ON_TRIAL",
  SUBSCRIBED = "SUBSCRIBED",
  TEST = "TEST",
}


export enum StudentStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DECLINED = "DECLINED",
}


export type UpdateStudentCheckInInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  studentID?: string | null,
  emotion?: Emotion | null,
  emotionIntensity?: number | null,
  emotionIntensityPercentage?: number | null,
  tiredness?: number | null,
  checkinsessionID?: string | null,
  classID?: string | null,
  absence?: boolean | null,
  notYet?: boolean | null,
  requestForChat?: RequestForChatStatus | null,
  chatCleared?: boolean | null,
  eating?: EatingStatus | null,
  _version?: number | null,
};

export type DeleteStudentCheckInInput = {
  id: string,
  _version?: number | null,
};

export type CreateTeacherCheckInInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  schoolID: string,
  teacherID: string,
  emotion?: Emotion | null,
  emotionIntensityPercentage?: number | null,
  tiredness?: number | null,
  _version?: number | null,
};

export type ModelTeacherCheckInConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  teacherID?: ModelIDInput | null,
  emotion?: ModelEmotionInput | null,
  emotionIntensityPercentage?: ModelFloatInput | null,
  tiredness?: ModelIntInput | null,
  and?: Array< ModelTeacherCheckInConditionInput | null > | null,
  or?: Array< ModelTeacherCheckInConditionInput | null > | null,
  not?: ModelTeacherCheckInConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateTeacherCheckInInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  schoolID?: string | null,
  teacherID?: string | null,
  emotion?: Emotion | null,
  emotionIntensityPercentage?: number | null,
  tiredness?: number | null,
  _version?: number | null,
};

export type DeleteTeacherCheckInInput = {
  id: string,
  _version?: number | null,
};

export type CreateStaffCheckInInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  schoolID: string,
  staffID: string,
  emotion?: Emotion | null,
  emotionIntensityPercentage?: number | null,
  tiredness?: number | null,
  _version?: number | null,
};

export type ModelStaffCheckInConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  staffID?: ModelIDInput | null,
  emotion?: ModelEmotionInput | null,
  emotionIntensityPercentage?: ModelFloatInput | null,
  tiredness?: ModelIntInput | null,
  and?: Array< ModelStaffCheckInConditionInput | null > | null,
  or?: Array< ModelStaffCheckInConditionInput | null > | null,
  not?: ModelStaffCheckInConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateStaffCheckInInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  schoolID?: string | null,
  staffID?: string | null,
  emotion?: Emotion | null,
  emotionIntensityPercentage?: number | null,
  tiredness?: number | null,
  _version?: number | null,
};

export type DeleteStaffCheckInInput = {
  id: string,
  _version?: number | null,
};

export type CreateCheckInSessionInput = {
  id?: string | null,
  checkInSessionClassSequenceNo?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  classID: string,
  openAt?: string | null,
  closeAt?: string | null,
  isActive: boolean,
  _version?: number | null,
};

export type ModelCheckInSessionConditionInput = {
  checkInSessionClassSequenceNo?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  classID?: ModelIDInput | null,
  openAt?: ModelStringInput | null,
  closeAt?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelCheckInSessionConditionInput | null > | null,
  or?: Array< ModelCheckInSessionConditionInput | null > | null,
  not?: ModelCheckInSessionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateCheckInSessionInput = {
  id: string,
  checkInSessionClassSequenceNo?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  classID?: string | null,
  openAt?: string | null,
  closeAt?: string | null,
  isActive?: boolean | null,
  _version?: number | null,
};

export type DeleteCheckInSessionInput = {
  id: string,
  _version?: number | null,
};

export type CreateSchoolInput = {
  id?: string | null,
  name: string,
  schoolAdminUI?: SchoolAdminUI | null,
  utcOffset?: string | null,
  licenseTotal?: string | null,
  timezone?: string | null,
  status?: SchoolStatus | null,
  activeStudents?: number | null,
  trialEndDate?: string | null,
  renewalDate?: string | null,
  notes?: string | null,
  checkInCountAllTime?: number | null,
  checkInCountYTD?: number | null,
  checkInCountRollingWeekMonday?: number | null,
  checkInCountRollingWeekTuesday?: number | null,
  checkInCountRollingWeekWednesday?: number | null,
  checkInCountRollingWeekThursday?: number | null,
  checkInCountRollingWeekFriday?: number | null,
  checkInCountRollingWeekSaturday?: number | null,
  checkInCountRollingWeekSunday?: number | null,
  wholeSchoolSubscription?: boolean | null,
  billingAddress?: string | null,
  billingNotes?: string | null,
  invoiceUrl?: string | null,
  abn?: string | null,
  coordinatorID?: string | null,
  teacherWellbeing?: boolean | null,
  staffWellbeing?: boolean | null,
  wellbeingFeature?: boolean | null,
  _version?: number | null,
};

export type ModelSchoolConditionInput = {
  name?: ModelStringInput | null,
  schoolAdminUI?: ModelSchoolAdminUIInput | null,
  utcOffset?: ModelStringInput | null,
  licenseTotal?: ModelStringInput | null,
  timezone?: ModelStringInput | null,
  status?: ModelSchoolStatusInput | null,
  activeStudents?: ModelIntInput | null,
  trialEndDate?: ModelStringInput | null,
  renewalDate?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  checkInCountAllTime?: ModelIntInput | null,
  checkInCountYTD?: ModelIntInput | null,
  checkInCountRollingWeekMonday?: ModelIntInput | null,
  checkInCountRollingWeekTuesday?: ModelIntInput | null,
  checkInCountRollingWeekWednesday?: ModelIntInput | null,
  checkInCountRollingWeekThursday?: ModelIntInput | null,
  checkInCountRollingWeekFriday?: ModelIntInput | null,
  checkInCountRollingWeekSaturday?: ModelIntInput | null,
  checkInCountRollingWeekSunday?: ModelIntInput | null,
  wholeSchoolSubscription?: ModelBooleanInput | null,
  billingAddress?: ModelStringInput | null,
  billingNotes?: ModelStringInput | null,
  invoiceUrl?: ModelStringInput | null,
  abn?: ModelStringInput | null,
  coordinatorID?: ModelIDInput | null,
  teacherWellbeing?: ModelBooleanInput | null,
  staffWellbeing?: ModelBooleanInput | null,
  wellbeingFeature?: ModelBooleanInput | null,
  and?: Array< ModelSchoolConditionInput | null > | null,
  or?: Array< ModelSchoolConditionInput | null > | null,
  not?: ModelSchoolConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelSchoolAdminUIInput = {
  eq?: SchoolAdminUI | null,
  ne?: SchoolAdminUI | null,
};

export type ModelSchoolStatusInput = {
  eq?: SchoolStatus | null,
  ne?: SchoolStatus | null,
};

export type UpdateSchoolInput = {
  id: string,
  name?: string | null,
  schoolAdminUI?: SchoolAdminUI | null,
  utcOffset?: string | null,
  licenseTotal?: string | null,
  timezone?: string | null,
  status?: SchoolStatus | null,
  activeStudents?: number | null,
  trialEndDate?: string | null,
  renewalDate?: string | null,
  notes?: string | null,
  checkInCountAllTime?: number | null,
  checkInCountYTD?: number | null,
  checkInCountRollingWeekMonday?: number | null,
  checkInCountRollingWeekTuesday?: number | null,
  checkInCountRollingWeekWednesday?: number | null,
  checkInCountRollingWeekThursday?: number | null,
  checkInCountRollingWeekFriday?: number | null,
  checkInCountRollingWeekSaturday?: number | null,
  checkInCountRollingWeekSunday?: number | null,
  wholeSchoolSubscription?: boolean | null,
  billingAddress?: string | null,
  billingNotes?: string | null,
  invoiceUrl?: string | null,
  abn?: string | null,
  coordinatorID?: string | null,
  teacherWellbeing?: boolean | null,
  staffWellbeing?: boolean | null,
  wellbeingFeature?: boolean | null,
  _version?: number | null,
};

export type DeleteSchoolInput = {
  id: string,
  _version?: number | null,
};

export type CreateCampusInput = {
  id?: string | null,
  name: string,
  schoolID: string,
  _version?: number | null,
};

export type ModelCampusConditionInput = {
  name?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  and?: Array< ModelCampusConditionInput | null > | null,
  or?: Array< ModelCampusConditionInput | null > | null,
  not?: ModelCampusConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateCampusInput = {
  id: string,
  name?: string | null,
  schoolID?: string | null,
  _version?: number | null,
};

export type DeleteCampusInput = {
  id: string,
  _version?: number | null,
};

export type CreateClassGroupInput = {
  id?: string | null,
  name: string,
  schoolID: string,
  campusID: string,
  _version?: number | null,
};

export type ModelClassGroupConditionInput = {
  name?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  campusID?: ModelIDInput | null,
  and?: Array< ModelClassGroupConditionInput | null > | null,
  or?: Array< ModelClassGroupConditionInput | null > | null,
  not?: ModelClassGroupConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateClassGroupInput = {
  id: string,
  name?: string | null,
  schoolID?: string | null,
  campusID?: string | null,
  _version?: number | null,
};

export type DeleteClassGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateClassInput = {
  id?: string | null,
  name: string,
  schoolID: string,
  classGroupID?: string | null,
  emotionIntensityLevel?: number | null,
  toggleRequestForChat?: boolean | null,
  toggleRequestForChatNotifications?: boolean | null,
  toggleIndividualStudentStats?: boolean | null,
  bulkSignUpId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  primaryContact?: string | null,
  archived?: boolean | null,
  emailReceivers?: Array< string | null > | null,
  lastCheckInSessionClassSequenceNo?: number | null,
  checkInCountYTD?: number | null,
  checkInCountRollingWeekMonday?: number | null,
  checkInCountRollingWeekTuesday?: number | null,
  checkInCountRollingWeekWednesday?: number | null,
  checkInCountRollingWeekThursday?: number | null,
  checkInCountRollingWeekFriday?: number | null,
  checkInCountRollingWeekSaturday?: number | null,
  checkInCountRollingWeekSunday?: number | null,
  activeStudents?: number | null,
  goMode?: boolean | null,
  yearLevel?: string | null,
  lastCheckInTime?: string | null,
  wellbeing?: boolean | null,
  wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
  wellbeingQuestionUsed?: Array< string > | null,
  currentWellbeingQuestionID?: string | null,
  wellbeingResponseCount?: number | null,
  toggleEating?: boolean | null,
  _version?: number | null,
  classClassLoginId?: string | null,
};

export type ModelClassConditionInput = {
  name?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  classGroupID?: ModelIDInput | null,
  emotionIntensityLevel?: ModelIntInput | null,
  toggleRequestForChat?: ModelBooleanInput | null,
  toggleRequestForChatNotifications?: ModelBooleanInput | null,
  toggleIndividualStudentStats?: ModelBooleanInput | null,
  bulkSignUpId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  primaryContact?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  emailReceivers?: ModelStringInput | null,
  lastCheckInSessionClassSequenceNo?: ModelIntInput | null,
  checkInCountYTD?: ModelIntInput | null,
  checkInCountRollingWeekMonday?: ModelIntInput | null,
  checkInCountRollingWeekTuesday?: ModelIntInput | null,
  checkInCountRollingWeekWednesday?: ModelIntInput | null,
  checkInCountRollingWeekThursday?: ModelIntInput | null,
  checkInCountRollingWeekFriday?: ModelIntInput | null,
  checkInCountRollingWeekSaturday?: ModelIntInput | null,
  checkInCountRollingWeekSunday?: ModelIntInput | null,
  activeStudents?: ModelIntInput | null,
  goMode?: ModelBooleanInput | null,
  yearLevel?: ModelStringInput | null,
  lastCheckInTime?: ModelStringInput | null,
  wellbeing?: ModelBooleanInput | null,
  wellbeingQuestionCategoryUsed?: ModelQuestionCategoryListInput | null,
  wellbeingQuestionUsed?: ModelIDInput | null,
  currentWellbeingQuestionID?: ModelStringInput | null,
  wellbeingResponseCount?: ModelIntInput | null,
  toggleEating?: ModelBooleanInput | null,
  and?: Array< ModelClassConditionInput | null > | null,
  or?: Array< ModelClassConditionInput | null > | null,
  not?: ModelClassConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  classClassLoginId?: ModelIDInput | null,
};

export type ModelQuestionCategoryListInput = {
  eq?: Array< QuestionCategory | null > | null,
  ne?: Array< QuestionCategory | null > | null,
  contains?: QuestionCategory | null,
  notContains?: QuestionCategory | null,
};

export type UpdateClassInput = {
  id: string,
  name?: string | null,
  schoolID?: string | null,
  classGroupID?: string | null,
  emotionIntensityLevel?: number | null,
  toggleRequestForChat?: boolean | null,
  toggleRequestForChatNotifications?: boolean | null,
  toggleIndividualStudentStats?: boolean | null,
  bulkSignUpId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  primaryContact?: string | null,
  archived?: boolean | null,
  emailReceivers?: Array< string | null > | null,
  lastCheckInSessionClassSequenceNo?: number | null,
  checkInCountYTD?: number | null,
  checkInCountRollingWeekMonday?: number | null,
  checkInCountRollingWeekTuesday?: number | null,
  checkInCountRollingWeekWednesday?: number | null,
  checkInCountRollingWeekThursday?: number | null,
  checkInCountRollingWeekFriday?: number | null,
  checkInCountRollingWeekSaturday?: number | null,
  checkInCountRollingWeekSunday?: number | null,
  activeStudents?: number | null,
  goMode?: boolean | null,
  yearLevel?: string | null,
  lastCheckInTime?: string | null,
  wellbeing?: boolean | null,
  wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
  wellbeingQuestionUsed?: Array< string > | null,
  currentWellbeingQuestionID?: string | null,
  wellbeingResponseCount?: number | null,
  toggleEating?: boolean | null,
  _version?: number | null,
  classClassLoginId?: string | null,
};

export type DeleteClassInput = {
  id: string,
  _version?: number | null,
};

export type CreateTeacherInput = {
  id?: string | null,
  email: string,
  schoolID: string,
  firstName?: string | null,
  lastName?: string | null,
  cognitoUsername: string,
  showIntroduction?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  role?: TeacherRole | null,
  onboarded?: boolean | null,
  devicePushTokens?: Array< string > | null,
  showNewFeatureInstructions?: boolean | null,
  preferredUsername?: string | null,
  wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
  wellbeingQuestionUsed?: Array< string > | null,
  wellbeingResponseCount?: number | null,
  avatar?: string | null,
  _version?: number | null,
};

export type ModelTeacherConditionInput = {
  email?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  cognitoUsername?: ModelStringInput | null,
  showIntroduction?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  role?: ModelTeacherRoleInput | null,
  onboarded?: ModelBooleanInput | null,
  devicePushTokens?: ModelStringInput | null,
  showNewFeatureInstructions?: ModelBooleanInput | null,
  preferredUsername?: ModelStringInput | null,
  wellbeingQuestionCategoryUsed?: ModelQuestionCategoryListInput | null,
  wellbeingQuestionUsed?: ModelIDInput | null,
  wellbeingResponseCount?: ModelIntInput | null,
  avatar?: ModelStringInput | null,
  and?: Array< ModelTeacherConditionInput | null > | null,
  or?: Array< ModelTeacherConditionInput | null > | null,
  not?: ModelTeacherConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTeacherRoleInput = {
  eq?: TeacherRole | null,
  ne?: TeacherRole | null,
};

export type UpdateTeacherInput = {
  id: string,
  email?: string | null,
  schoolID?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  cognitoUsername?: string | null,
  showIntroduction?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  role?: TeacherRole | null,
  onboarded?: boolean | null,
  devicePushTokens?: Array< string > | null,
  showNewFeatureInstructions?: boolean | null,
  preferredUsername?: string | null,
  wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
  wellbeingQuestionUsed?: Array< string > | null,
  wellbeingResponseCount?: number | null,
  avatar?: string | null,
  _version?: number | null,
};

export type DeleteTeacherInput = {
  id: string,
  _version?: number | null,
};

export type CreateStaffInput = {
  id?: string | null,
  cognitoUsername: string,
  email: string,
  schoolID: string,
  firstName?: string | null,
  lastName?: string | null,
  preferredUsername?: string | null,
  wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
  wellbeingQuestionUsed?: Array< string > | null,
  wellbeingResponseCount?: number | null,
  avatar?: string | null,
  nonTeachingStaffCheckInCount?: number | null,
  nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
  onboarded?: boolean | null,
  _version?: number | null,
};

export type ModelStaffConditionInput = {
  cognitoUsername?: ModelStringInput | null,
  email?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  preferredUsername?: ModelStringInput | null,
  wellbeingQuestionCategoryUsed?: ModelQuestionCategoryListInput | null,
  wellbeingQuestionUsed?: ModelIDInput | null,
  wellbeingResponseCount?: ModelIntInput | null,
  avatar?: ModelStringInput | null,
  nonTeachingStaffCheckInCount?: ModelIntInput | null,
  nonTeachingStaffCheckInSkipSuggestionDate?: ModelStringInput | null,
  onboarded?: ModelBooleanInput | null,
  and?: Array< ModelStaffConditionInput | null > | null,
  or?: Array< ModelStaffConditionInput | null > | null,
  not?: ModelStaffConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateStaffInput = {
  id: string,
  cognitoUsername?: string | null,
  email?: string | null,
  schoolID?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  preferredUsername?: string | null,
  wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
  wellbeingQuestionUsed?: Array< string > | null,
  wellbeingResponseCount?: number | null,
  avatar?: string | null,
  nonTeachingStaffCheckInCount?: number | null,
  nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
  onboarded?: boolean | null,
  _version?: number | null,
};

export type DeleteStaffInput = {
  id: string,
  _version?: number | null,
};

export type CreateSchoolAdminInput = {
  id?: string | null,
  email: string,
  schoolID: string,
  firstName?: string | null,
  lastName?: string | null,
  cognitoUsername: string,
  preferredUsername?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelSchoolAdminConditionInput = {
  email?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  cognitoUsername?: ModelStringInput | null,
  preferredUsername?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSchoolAdminConditionInput | null > | null,
  or?: Array< ModelSchoolAdminConditionInput | null > | null,
  not?: ModelSchoolAdminConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateSchoolAdminInput = {
  id: string,
  email?: string | null,
  schoolID?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  cognitoUsername?: string | null,
  preferredUsername?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteSchoolAdminInput = {
  id: string,
  _version?: number | null,
};

export type CreateStudentInput = {
  id?: string | null,
  schoolStudentID?: string | null,
  nickname: string,
  avatar: string,
  schoolID: string,
  yearLevel?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  status?: StudentStatus | null,
  cognitoUsername: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  onboarded?: boolean | null,
  initialLoginSetup?: boolean | null,
  activeClasses?: number | null,
  studentCheckInCount?: number | null,
  studentCheckInSkipSuggestionDate?: string | null,
  devicePushTokens?: Array< string > | null,
  wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
  wellbeingQuestionUsed?: Array< string > | null,
  currentWellbeingQuestion?: string | null,
  wellbeingResponseCount?: number | null,
  _version?: number | null,
};

export type ModelStudentConditionInput = {
  schoolStudentID?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  yearLevel?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  status?: ModelStudentStatusInput | null,
  cognitoUsername?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  onboarded?: ModelBooleanInput | null,
  initialLoginSetup?: ModelBooleanInput | null,
  activeClasses?: ModelIntInput | null,
  studentCheckInCount?: ModelIntInput | null,
  studentCheckInSkipSuggestionDate?: ModelStringInput | null,
  devicePushTokens?: ModelStringInput | null,
  wellbeingQuestionCategoryUsed?: ModelQuestionCategoryListInput | null,
  wellbeingQuestionUsed?: ModelIDInput | null,
  currentWellbeingQuestion?: ModelStringInput | null,
  wellbeingResponseCount?: ModelIntInput | null,
  and?: Array< ModelStudentConditionInput | null > | null,
  or?: Array< ModelStudentConditionInput | null > | null,
  not?: ModelStudentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStudentStatusInput = {
  eq?: StudentStatus | null,
  ne?: StudentStatus | null,
};

export type UpdateStudentInput = {
  id: string,
  schoolStudentID?: string | null,
  nickname?: string | null,
  avatar?: string | null,
  schoolID?: string | null,
  yearLevel?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  status?: StudentStatus | null,
  cognitoUsername?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  onboarded?: boolean | null,
  initialLoginSetup?: boolean | null,
  activeClasses?: number | null,
  studentCheckInCount?: number | null,
  studentCheckInSkipSuggestionDate?: string | null,
  devicePushTokens?: Array< string > | null,
  wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
  wellbeingQuestionUsed?: Array< string > | null,
  currentWellbeingQuestion?: string | null,
  wellbeingResponseCount?: number | null,
  _version?: number | null,
};

export type DeleteStudentInput = {
  id: string,
  _version?: number | null,
};

export type CreateSupportInput = {
  id?: string | null,
  email?: string | null,
  firstName: string,
  lastName: string,
  cognitoUsername: string,
  _version?: number | null,
};

export type ModelSupportConditionInput = {
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  cognitoUsername?: ModelStringInput | null,
  and?: Array< ModelSupportConditionInput | null > | null,
  or?: Array< ModelSupportConditionInput | null > | null,
  not?: ModelSupportConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateSupportInput = {
  id: string,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  cognitoUsername?: string | null,
  _version?: number | null,
};

export type DeleteSupportInput = {
  id: string,
  _version?: number | null,
};

export type CreateClassSupportInput = {
  id?: string | null,
  classID: string,
  supportID: string,
  _version?: number | null,
};

export type ModelClassSupportConditionInput = {
  classID?: ModelIDInput | null,
  supportID?: ModelIDInput | null,
  and?: Array< ModelClassSupportConditionInput | null > | null,
  or?: Array< ModelClassSupportConditionInput | null > | null,
  not?: ModelClassSupportConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateClassSupportInput = {
  id: string,
  classID?: string | null,
  supportID?: string | null,
  _version?: number | null,
};

export type DeleteClassSupportInput = {
  id: string,
  _version?: number | null,
};

export type CreateTeacherClassInput = {
  id?: string | null,
  teacherID: string,
  classID: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelTeacherClassConditionInput = {
  teacherID?: ModelIDInput | null,
  classID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTeacherClassConditionInput | null > | null,
  or?: Array< ModelTeacherClassConditionInput | null > | null,
  not?: ModelTeacherClassConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateTeacherClassInput = {
  id: string,
  teacherID?: string | null,
  classID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteTeacherClassInput = {
  id: string,
  _version?: number | null,
};

export type CreateScheduleClassInput = {
  id?: string | null,
  classID: string,
  schedule: string,
  isActive?: boolean | null,
  _version?: number | null,
};

export type ModelScheduleClassConditionInput = {
  classID?: ModelIDInput | null,
  schedule?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelScheduleClassConditionInput | null > | null,
  or?: Array< ModelScheduleClassConditionInput | null > | null,
  not?: ModelScheduleClassConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateScheduleClassInput = {
  id: string,
  classID?: string | null,
  schedule?: string | null,
  isActive?: boolean | null,
  _version?: number | null,
};

export type DeleteScheduleClassInput = {
  id: string,
  _version?: number | null,
};

export type CreateStudentClassInput = {
  id?: string | null,
  studentID: string,
  classID: string,
  status?: StudentClassApprovalStatus | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  requestForChat?: boolean | null,
  requestForChatStatus?: RequestForChatStatus | null,
  studentCheckIns?: Array< string > | null,
  _version?: number | null,
};

export type ModelStudentClassConditionInput = {
  studentID?: ModelIDInput | null,
  classID?: ModelIDInput | null,
  status?: ModelStudentClassApprovalStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  requestForChat?: ModelBooleanInput | null,
  requestForChatStatus?: ModelRequestForChatStatusInput | null,
  studentCheckIns?: ModelStringInput | null,
  and?: Array< ModelStudentClassConditionInput | null > | null,
  or?: Array< ModelStudentClassConditionInput | null > | null,
  not?: ModelStudentClassConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStudentClassApprovalStatusInput = {
  eq?: StudentClassApprovalStatus | null,
  ne?: StudentClassApprovalStatus | null,
};

export type UpdateStudentClassInput = {
  id: string,
  studentID?: string | null,
  classID?: string | null,
  status?: StudentClassApprovalStatus | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  requestForChat?: boolean | null,
  requestForChatStatus?: RequestForChatStatus | null,
  studentCheckIns?: Array< string > | null,
  _version?: number | null,
};

export type DeleteStudentClassInput = {
  id: string,
  _version?: number | null,
};

export type CreateSchoolContactInput = {
  id?: string | null,
  schoolID: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  type?: SchoolContactType | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export enum SchoolContactType {
  PRINCIPAL = "PRINCIPAL",
  COORDINATOR = "COORDINATOR",
}


export type ModelSchoolContactConditionInput = {
  schoolID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  type?: ModelSchoolContactTypeInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSchoolContactConditionInput | null > | null,
  or?: Array< ModelSchoolContactConditionInput | null > | null,
  not?: ModelSchoolContactConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSchoolContactTypeInput = {
  eq?: SchoolContactType | null,
  ne?: SchoolContactType | null,
};

export type SchoolContact = {
  __typename: "SchoolContact",
  id: string,
  schoolID: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  type?: SchoolContactType | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateSchoolContactInput = {
  id: string,
  schoolID?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  type?: SchoolContactType | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteSchoolContactInput = {
  id: string,
  _version?: number | null,
};

export type CreateMinFEVersionInput = {
  version: string,
  id?: string | null,
  _version?: number | null,
};

export type ModelMinFEVersionConditionInput = {
  version?: ModelStringInput | null,
  and?: Array< ModelMinFEVersionConditionInput | null > | null,
  or?: Array< ModelMinFEVersionConditionInput | null > | null,
  not?: ModelMinFEVersionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type MinFEVersion = {
  __typename: "MinFEVersion",
  version: string,
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateMinFEVersionInput = {
  version?: string | null,
  id: string,
  _version?: number | null,
};

export type DeleteMinFEVersionInput = {
  id: string,
  _version?: number | null,
};

export type CreateSwitchRatingInput = {
  id?: string | null,
  userId: string,
  cardId: string,
  userType: UserType,
  rating: SwitchRatingType,
  _version?: number | null,
};

export enum UserType {
  TEACHER = "TEACHER",
  STUDENT = "STUDENT",
}


export enum SwitchRatingType {
  LIKE = "LIKE",
  NEUTRAL = "NEUTRAL",
  DISLIKE = "DISLIKE",
}


export type ModelSwitchRatingConditionInput = {
  userId?: ModelIDInput | null,
  cardId?: ModelIDInput | null,
  userType?: ModelUserTypeInput | null,
  rating?: ModelSwitchRatingTypeInput | null,
  and?: Array< ModelSwitchRatingConditionInput | null > | null,
  or?: Array< ModelSwitchRatingConditionInput | null > | null,
  not?: ModelSwitchRatingConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelUserTypeInput = {
  eq?: UserType | null,
  ne?: UserType | null,
};

export type ModelSwitchRatingTypeInput = {
  eq?: SwitchRatingType | null,
  ne?: SwitchRatingType | null,
};

export type SwitchRating = {
  __typename: "SwitchRating",
  id: string,
  userId: string,
  cardId: string,
  userType: UserType,
  rating: SwitchRatingType,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateSwitchRatingInput = {
  id: string,
  userId?: string | null,
  cardId?: string | null,
  userType?: UserType | null,
  rating?: SwitchRatingType | null,
  _version?: number | null,
};

export type DeleteSwitchRatingInput = {
  id: string,
  _version?: number | null,
};

export type CreateClassLoginInput = {
  id?: string | null,
  nickname: string,
  cognitoUsername: string,
  classID: string,
  classCode: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  devicePushTokens?: Array< string > | null,
  _version?: number | null,
};

export type ModelClassLoginConditionInput = {
  nickname?: ModelStringInput | null,
  cognitoUsername?: ModelStringInput | null,
  classID?: ModelIDInput | null,
  classCode?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  devicePushTokens?: ModelStringInput | null,
  and?: Array< ModelClassLoginConditionInput | null > | null,
  or?: Array< ModelClassLoginConditionInput | null > | null,
  not?: ModelClassLoginConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateClassLoginInput = {
  id: string,
  nickname?: string | null,
  cognitoUsername?: string | null,
  classID?: string | null,
  classCode?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  devicePushTokens?: Array< string > | null,
  _version?: number | null,
};

export type DeleteClassLoginInput = {
  id: string,
  _version?: number | null,
};

export type CreateFavouriteInput = {
  id?: string | null,
  cognitoUsername: string,
  cardId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelFavouriteConditionInput = {
  cognitoUsername?: ModelStringInput | null,
  cardId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFavouriteConditionInput | null > | null,
  or?: Array< ModelFavouriteConditionInput | null > | null,
  not?: ModelFavouriteConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Favourite = {
  __typename: "Favourite",
  id: string,
  cognitoUsername: string,
  cardId: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateFavouriteInput = {
  id: string,
  cognitoUsername?: string | null,
  cardId?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteFavouriteInput = {
  id: string,
  _version?: number | null,
};

export type CreateFindYourCalmActivityRatingInput = {
  userId: string,
  activityId: string,
  rating?: number | null,
  id?: string | null,
  _version?: number | null,
};

export type ModelFindYourCalmActivityRatingConditionInput = {
  userId?: ModelIDInput | null,
  activityId?: ModelStringInput | null,
  rating?: ModelIntInput | null,
  and?: Array< ModelFindYourCalmActivityRatingConditionInput | null > | null,
  or?: Array< ModelFindYourCalmActivityRatingConditionInput | null > | null,
  not?: ModelFindYourCalmActivityRatingConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type FindYourCalmActivityRating = {
  __typename: "FindYourCalmActivityRating",
  userId: string,
  activityId: string,
  rating?: number | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateFindYourCalmActivityRatingInput = {
  userId?: string | null,
  activityId?: string | null,
  rating?: number | null,
  id: string,
  _version?: number | null,
};

export type DeleteFindYourCalmActivityRatingInput = {
  id: string,
  _version?: number | null,
};

export type CreateInvoiceInput = {
  id?: string | null,
  schoolID: string,
  url: string,
  name?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type ModelInvoiceConditionInput = {
  schoolID?: ModelIDInput | null,
  url?: ModelStringInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInvoiceConditionInput | null > | null,
  or?: Array< ModelInvoiceConditionInput | null > | null,
  not?: ModelInvoiceConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Invoice = {
  __typename: "Invoice",
  id: string,
  schoolID: string,
  url: string,
  name?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateInvoiceInput = {
  id: string,
  schoolID?: string | null,
  url?: string | null,
  name?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version?: number | null,
};

export type DeleteInvoiceInput = {
  id: string,
  _version?: number | null,
};

export type CreateVideosInput = {
  id?: string | null,
  url: string,
  image?: string | null,
  title?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  time?: Array< string | null > | null,
  level?: Array< string | null > | null,
  emotion?: Array< string | null > | null,
  ageGroup?: Array< string | null > | null,
  isExternal?: boolean | null,
  autoplay?: boolean | null,
  youtubePlaylistThumbnailUrlVideoId?: string | null,
  thumbnailUrl?: string | null,
  _version?: number | null,
};

export type ModelVideosConditionInput = {
  url?: ModelStringInput | null,
  image?: ModelStringInput | null,
  title?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  time?: ModelStringInput | null,
  level?: ModelStringInput | null,
  emotion?: ModelStringInput | null,
  ageGroup?: ModelStringInput | null,
  isExternal?: ModelBooleanInput | null,
  autoplay?: ModelBooleanInput | null,
  youtubePlaylistThumbnailUrlVideoId?: ModelStringInput | null,
  thumbnailUrl?: ModelStringInput | null,
  and?: Array< ModelVideosConditionInput | null > | null,
  or?: Array< ModelVideosConditionInput | null > | null,
  not?: ModelVideosConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Videos = {
  __typename: "Videos",
  id: string,
  url: string,
  image?: string | null,
  title?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  time?: Array< string | null > | null,
  level?: Array< string | null > | null,
  emotion?: Array< string | null > | null,
  ageGroup?: Array< string | null > | null,
  isExternal?: boolean | null,
  autoplay?: boolean | null,
  youtubePlaylistThumbnailUrlVideoId?: string | null,
  thumbnailUrl?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateVideosInput = {
  id: string,
  url?: string | null,
  image?: string | null,
  title?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  time?: Array< string | null > | null,
  level?: Array< string | null > | null,
  emotion?: Array< string | null > | null,
  ageGroup?: Array< string | null > | null,
  isExternal?: boolean | null,
  autoplay?: boolean | null,
  youtubePlaylistThumbnailUrlVideoId?: string | null,
  thumbnailUrl?: string | null,
  _version?: number | null,
};

export type DeleteVideosInput = {
  id: string,
  _version?: number | null,
};

export type CreateWellbeingQuestionInput = {
  id?: string | null,
  category: QuestionCategory,
  type: QuestionType,
  text: string,
  options: Array< QuestionOptionInput >,
  _version?: number | null,
};

export enum QuestionType {
  ADULT = "ADULT",
  STUDENT = "STUDENT",
}


export type QuestionOptionInput = {
  text: string,
  value: number,
};

export type ModelWellbeingQuestionConditionInput = {
  category?: ModelQuestionCategoryInput | null,
  type?: ModelQuestionTypeInput | null,
  text?: ModelStringInput | null,
  and?: Array< ModelWellbeingQuestionConditionInput | null > | null,
  or?: Array< ModelWellbeingQuestionConditionInput | null > | null,
  not?: ModelWellbeingQuestionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelQuestionCategoryInput = {
  eq?: QuestionCategory | null,
  ne?: QuestionCategory | null,
};

export type ModelQuestionTypeInput = {
  eq?: QuestionType | null,
  ne?: QuestionType | null,
};

export type WellbeingQuestion = {
  __typename: "WellbeingQuestion",
  id: string,
  category: QuestionCategory,
  type: QuestionType,
  text: string,
  options:  Array<QuestionOption >,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type QuestionOption = {
  __typename: "QuestionOption",
  text: string,
  value: number,
};

export type UpdateWellbeingQuestionInput = {
  id: string,
  category?: QuestionCategory | null,
  type?: QuestionType | null,
  text?: string | null,
  options?: Array< QuestionOptionInput > | null,
  _version?: number | null,
};

export type DeleteWellbeingQuestionInput = {
  id: string,
  _version?: number | null,
};

export type CreateWellbeingResponseInput = {
  id?: string | null,
  userID: string,
  userType: WellbeingResponseUserType,
  wellbeingQuestionID: string,
  answer: number,
  createdAt?: string | null,
  schoolID: string,
  _version?: number | null,
};

export enum WellbeingResponseUserType {
  STUDENT = "STUDENT",
  NON_TEACHING_STAFF = "NON_TEACHING_STAFF",
  TEACHER = "TEACHER",
}


export type ModelWellbeingResponseConditionInput = {
  userID?: ModelIDInput | null,
  userType?: ModelWellbeingResponseUserTypeInput | null,
  wellbeingQuestionID?: ModelIDInput | null,
  answer?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  and?: Array< ModelWellbeingResponseConditionInput | null > | null,
  or?: Array< ModelWellbeingResponseConditionInput | null > | null,
  not?: ModelWellbeingResponseConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelWellbeingResponseUserTypeInput = {
  eq?: WellbeingResponseUserType | null,
  ne?: WellbeingResponseUserType | null,
};

export type WellbeingResponse = {
  __typename: "WellbeingResponse",
  id: string,
  userID: string,
  userType: WellbeingResponseUserType,
  wellbeingQuestionID: string,
  answer: number,
  createdAt: string,
  schoolID: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateWellbeingResponseInput = {
  id: string,
  userID?: string | null,
  userType?: WellbeingResponseUserType | null,
  wellbeingQuestionID?: string | null,
  answer?: number | null,
  createdAt?: string | null,
  schoolID?: string | null,
  _version?: number | null,
};

export type DeleteWellbeingResponseInput = {
  id: string,
  _version?: number | null,
};

export type DevicePushTokenInput = {
  devicePushToken: string,
};

export type CreateGoClassStudentCheckInInput = {
  studentID: string,
  emotion?: Emotion | null,
  emotionIntensityPercentage?: number | null,
  tiredness?: number | null,
  classID: string,
  absence?: boolean | null,
  eating?: EatingStatus | null,
};

export type CustomCreateTeacherClassInput = {
  teacherID: string,
  classID: string,
};

export type CustomDeleteTeacherCLassInput = {
  id: string,
};

export type UpdateSchoolAdminForBillingInfoInput = {
  id: string,
  email: string,
  preferredUsername?: string | null,
  firstName?: string | null,
  lastName?: string | null,
};

export type CloseCheckInSessionInput = {
  checkInSessionID: string,
};

export type OpenCheckInSessionInput = {
  classID: string,
};

export type Ping = {
  __typename: "Ping",
  id: string,
};

export type resetAllStudentPasswordInClass = {
  classID: string,
  password: string,
};

export type SetRequestForChatInput = {
  studentCheckInID: string,
  requestForChatStatus: RequestForChatStatus,
};

export type UpdateSchoolCoordinatorForBillingInfo = {
  schoolID: string,
  coordinatorID: string,
};

export type UpdateSchoolForBillingInfoInput = {
  id: string,
  name?: string | null,
  billingAddress?: string | null,
  abn?: string | null,
  coordinatorID?: string | null,
  editorUsername?: string | null,
  editorEmail?: string | null,
};

export type UpsertFindYourCalmActivityRatingInput = {
  userId: string,
  activityId: string,
  rating?: number | null,
};

export type UpsertStudentCheckInInput = {
  studentID: string,
  classID: string,
  studentCheckinID?: string | null,
  checkInSessionID: string,
  emotion?: Emotion | null,
  emotionIntensityPercentage?: number | null,
  tiredness?: number | null,
  eating?: EatingStatus | null,
};

export type ArchivedClassInput = {
  classID: string,
};

export type CustomCreateStaffCheckInInput = {
  staffID: string,
  schoolID: string,
  emotion?: Emotion | null,
  emotionIntensityPercentage?: number | null,
  tiredness?: number | null,
};

export type SetRequestForChatForGoClassInput = {
  studentID: string,
  classID: string,
  requestForChat?: RequestForChatStatus | null,
  chatCleared?: boolean | null,
};

export type ClearClassStudentCheckInAlertsInput = {
  classID: string,
};

export type CustomCreateClassLoginInput = {
  username: string,
  password: string,
  classID: string,
};

export type CreateSchoolAdminForBillingInfoInput = {
  email: string,
  cognitoUsername: string,
  schoolID: string,
  firstName?: string | null,
  lastName?: string | null,
};

export type CustomCreateStudentInput = {
  id?: string | null,
  avatar: string,
  classID?: string | null,
  cognitoUsername: string,
  email?: string | null,
  firstName?: string | null,
  initialLoginSetup?: boolean | null,
  lastName?: string | null,
  messageAction?: CognitoMessageAction | null,
  nickname?: string | null,
  schoolID: string,
  schoolStudentID?: string | null,
  status?: StudentStatus | null,
  yearLevel?: string | null,
  password?: string | null,
};

export enum CognitoMessageAction {
  SUPPRESS = "SUPPRESS",
  RESEND = "RESEND",
}


export type CustomCreateStaffInput = {
  id?: string | null,
  cognitoUsername: string,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  messageAction?: CognitoMessageAction | null,
  preferredUsername?: string | null,
  schoolID: string,
  password?: string | null,
};

export type CustomCreateTeacherAndAssignedToClassInput = {
  email: string,
  cognitoUsername: string,
  classID: string,
  schoolID: string,
  firstName?: string | null,
  lastName?: string | null,
  role?: string | null,
  password?: string | null,
  showIntroduction?: boolean | null,
  showNewFeatureInstructions?: boolean | null,
};

export type CustomCreateTeacherInput = {
  id?: string | null,
  email: string,
  cognitoUsername: string,
  schoolID: string,
  firstName?: string | null,
  lastName?: string | null,
  role?: string | null,
  password?: string | null,
  showIntroduction?: boolean | null,
  showNewFeatureInstructions?: boolean | null,
};

export type CustomDeleteTeacherInput = {
  teacherID: string,
};

export type CustomDeleteStaffInput = {
  staffID: string,
};

export type RemoveSchoolAdminForBillingInfoInput = {
  id: string,
};

export type CustomUpdateStaffInput = {
  id: string,
  email: string,
  preferredUsername?: string | null,
  firstName?: string | null,
  lastName?: string | null,
};

export type CustomUpdateTeacherInput = {
  id: string,
  email: string,
  preferredUsername?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  role?: TeacherRole | null,
};

export type CustomUpdateClassLoginCodeInput = {
  classLoginID: string,
  username: string,
  oldClassCode: string,
  newClassCode: string,
};

export type SubmitWellbeingResponseInput = {
  userID: string,
  userType: WellbeingResponseUserType,
  wellbeingQuestionID: string,
  answer: number,
  schoolID: string,
  classID: string,
};

export type ModelStudentCheckInFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  studentID?: ModelIDInput | null,
  emotion?: ModelEmotionInput | null,
  emotionIntensity?: ModelIntInput | null,
  emotionIntensityPercentage?: ModelFloatInput | null,
  tiredness?: ModelIntInput | null,
  checkinsessionID?: ModelIDInput | null,
  classID?: ModelIDInput | null,
  absence?: ModelBooleanInput | null,
  notYet?: ModelBooleanInput | null,
  requestForChat?: ModelRequestForChatStatusInput | null,
  chatCleared?: ModelBooleanInput | null,
  eating?: ModelEatingStatusInput | null,
  and?: Array< ModelStudentCheckInFilterInput | null > | null,
  or?: Array< ModelStudentCheckInFilterInput | null > | null,
  not?: ModelStudentCheckInFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelTeacherCheckInFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  teacherID?: ModelIDInput | null,
  emotion?: ModelEmotionInput | null,
  emotionIntensityPercentage?: ModelFloatInput | null,
  tiredness?: ModelIntInput | null,
  and?: Array< ModelTeacherCheckInFilterInput | null > | null,
  or?: Array< ModelTeacherCheckInFilterInput | null > | null,
  not?: ModelTeacherCheckInFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStaffCheckInFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  staffID?: ModelIDInput | null,
  emotion?: ModelEmotionInput | null,
  emotionIntensityPercentage?: ModelFloatInput | null,
  tiredness?: ModelIntInput | null,
  and?: Array< ModelStaffCheckInFilterInput | null > | null,
  or?: Array< ModelStaffCheckInFilterInput | null > | null,
  not?: ModelStaffCheckInFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCheckInSessionFilterInput = {
  id?: ModelIDInput | null,
  checkInSessionClassSequenceNo?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  classID?: ModelIDInput | null,
  openAt?: ModelStringInput | null,
  closeAt?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelCheckInSessionFilterInput | null > | null,
  or?: Array< ModelCheckInSessionFilterInput | null > | null,
  not?: ModelCheckInSessionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSchoolFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  schoolAdminUI?: ModelSchoolAdminUIInput | null,
  utcOffset?: ModelStringInput | null,
  licenseTotal?: ModelStringInput | null,
  timezone?: ModelStringInput | null,
  status?: ModelSchoolStatusInput | null,
  activeStudents?: ModelIntInput | null,
  trialEndDate?: ModelStringInput | null,
  renewalDate?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  checkInCountAllTime?: ModelIntInput | null,
  checkInCountYTD?: ModelIntInput | null,
  checkInCountRollingWeekMonday?: ModelIntInput | null,
  checkInCountRollingWeekTuesday?: ModelIntInput | null,
  checkInCountRollingWeekWednesday?: ModelIntInput | null,
  checkInCountRollingWeekThursday?: ModelIntInput | null,
  checkInCountRollingWeekFriday?: ModelIntInput | null,
  checkInCountRollingWeekSaturday?: ModelIntInput | null,
  checkInCountRollingWeekSunday?: ModelIntInput | null,
  wholeSchoolSubscription?: ModelBooleanInput | null,
  billingAddress?: ModelStringInput | null,
  billingNotes?: ModelStringInput | null,
  invoiceUrl?: ModelStringInput | null,
  abn?: ModelStringInput | null,
  coordinatorID?: ModelIDInput | null,
  teacherWellbeing?: ModelBooleanInput | null,
  staffWellbeing?: ModelBooleanInput | null,
  wellbeingFeature?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSchoolFilterInput | null > | null,
  or?: Array< ModelSchoolFilterInput | null > | null,
  not?: ModelSchoolFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSchoolConnection = {
  __typename: "ModelSchoolConnection",
  items:  Array<School | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCampusFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCampusFilterInput | null > | null,
  or?: Array< ModelCampusFilterInput | null > | null,
  not?: ModelCampusFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelClassGroupFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  campusID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClassGroupFilterInput | null > | null,
  or?: Array< ModelClassGroupFilterInput | null > | null,
  not?: ModelClassGroupFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelClassFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  classGroupID?: ModelIDInput | null,
  emotionIntensityLevel?: ModelIntInput | null,
  toggleRequestForChat?: ModelBooleanInput | null,
  toggleRequestForChatNotifications?: ModelBooleanInput | null,
  toggleIndividualStudentStats?: ModelBooleanInput | null,
  bulkSignUpId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  primaryContact?: ModelStringInput | null,
  archived?: ModelBooleanInput | null,
  emailReceivers?: ModelStringInput | null,
  lastCheckInSessionClassSequenceNo?: ModelIntInput | null,
  checkInCountYTD?: ModelIntInput | null,
  checkInCountRollingWeekMonday?: ModelIntInput | null,
  checkInCountRollingWeekTuesday?: ModelIntInput | null,
  checkInCountRollingWeekWednesday?: ModelIntInput | null,
  checkInCountRollingWeekThursday?: ModelIntInput | null,
  checkInCountRollingWeekFriday?: ModelIntInput | null,
  checkInCountRollingWeekSaturday?: ModelIntInput | null,
  checkInCountRollingWeekSunday?: ModelIntInput | null,
  activeStudents?: ModelIntInput | null,
  goMode?: ModelBooleanInput | null,
  yearLevel?: ModelStringInput | null,
  lastCheckInTime?: ModelStringInput | null,
  wellbeing?: ModelBooleanInput | null,
  wellbeingQuestionCategoryUsed?: ModelQuestionCategoryListInput | null,
  wellbeingQuestionUsed?: ModelIDInput | null,
  currentWellbeingQuestionID?: ModelStringInput | null,
  wellbeingResponseCount?: ModelIntInput | null,
  toggleEating?: ModelBooleanInput | null,
  and?: Array< ModelClassFilterInput | null > | null,
  or?: Array< ModelClassFilterInput | null > | null,
  not?: ModelClassFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  classClassLoginId?: ModelIDInput | null,
};

export type ModelTeacherFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  cognitoUsername?: ModelStringInput | null,
  showIntroduction?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  role?: ModelTeacherRoleInput | null,
  onboarded?: ModelBooleanInput | null,
  devicePushTokens?: ModelStringInput | null,
  showNewFeatureInstructions?: ModelBooleanInput | null,
  preferredUsername?: ModelStringInput | null,
  wellbeingQuestionCategoryUsed?: ModelQuestionCategoryListInput | null,
  wellbeingQuestionUsed?: ModelIDInput | null,
  wellbeingResponseCount?: ModelIntInput | null,
  avatar?: ModelStringInput | null,
  and?: Array< ModelTeacherFilterInput | null > | null,
  or?: Array< ModelTeacherFilterInput | null > | null,
  not?: ModelTeacherFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStaffFilterInput = {
  id?: ModelIDInput | null,
  cognitoUsername?: ModelStringInput | null,
  email?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  preferredUsername?: ModelStringInput | null,
  wellbeingQuestionCategoryUsed?: ModelQuestionCategoryListInput | null,
  wellbeingQuestionUsed?: ModelIDInput | null,
  wellbeingResponseCount?: ModelIntInput | null,
  avatar?: ModelStringInput | null,
  nonTeachingStaffCheckInCount?: ModelIntInput | null,
  nonTeachingStaffCheckInSkipSuggestionDate?: ModelStringInput | null,
  onboarded?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelStaffFilterInput | null > | null,
  or?: Array< ModelStaffFilterInput | null > | null,
  not?: ModelStaffFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSchoolAdminFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  cognitoUsername?: ModelStringInput | null,
  preferredUsername?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSchoolAdminFilterInput | null > | null,
  or?: Array< ModelSchoolAdminFilterInput | null > | null,
  not?: ModelSchoolAdminFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStudentFilterInput = {
  id?: ModelIDInput | null,
  schoolStudentID?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  yearLevel?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  status?: ModelStudentStatusInput | null,
  cognitoUsername?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  onboarded?: ModelBooleanInput | null,
  initialLoginSetup?: ModelBooleanInput | null,
  activeClasses?: ModelIntInput | null,
  studentCheckInCount?: ModelIntInput | null,
  studentCheckInSkipSuggestionDate?: ModelStringInput | null,
  devicePushTokens?: ModelStringInput | null,
  wellbeingQuestionCategoryUsed?: ModelQuestionCategoryListInput | null,
  wellbeingQuestionUsed?: ModelIDInput | null,
  currentWellbeingQuestion?: ModelStringInput | null,
  wellbeingResponseCount?: ModelIntInput | null,
  and?: Array< ModelStudentFilterInput | null > | null,
  or?: Array< ModelStudentFilterInput | null > | null,
  not?: ModelStudentFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSupportFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  cognitoUsername?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSupportFilterInput | null > | null,
  or?: Array< ModelSupportFilterInput | null > | null,
  not?: ModelSupportFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSupportConnection = {
  __typename: "ModelSupportConnection",
  items:  Array<Support | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelClassSupportFilterInput = {
  id?: ModelIDInput | null,
  classID?: ModelIDInput | null,
  supportID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClassSupportFilterInput | null > | null,
  or?: Array< ModelClassSupportFilterInput | null > | null,
  not?: ModelClassSupportFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTeacherClassFilterInput = {
  id?: ModelIDInput | null,
  teacherID?: ModelIDInput | null,
  classID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTeacherClassFilterInput | null > | null,
  or?: Array< ModelTeacherClassFilterInput | null > | null,
  not?: ModelTeacherClassFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelScheduleClassFilterInput = {
  id?: ModelIDInput | null,
  classID?: ModelIDInput | null,
  schedule?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelScheduleClassFilterInput | null > | null,
  or?: Array< ModelScheduleClassFilterInput | null > | null,
  not?: ModelScheduleClassFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStudentClassFilterInput = {
  id?: ModelIDInput | null,
  studentID?: ModelIDInput | null,
  classID?: ModelIDInput | null,
  status?: ModelStudentClassApprovalStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  requestForChat?: ModelBooleanInput | null,
  requestForChatStatus?: ModelRequestForChatStatusInput | null,
  studentCheckIns?: ModelStringInput | null,
  and?: Array< ModelStudentClassFilterInput | null > | null,
  or?: Array< ModelStudentClassFilterInput | null > | null,
  not?: ModelStudentClassFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSchoolContactFilterInput = {
  id?: ModelIDInput | null,
  schoolID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  type?: ModelSchoolContactTypeInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSchoolContactFilterInput | null > | null,
  or?: Array< ModelSchoolContactFilterInput | null > | null,
  not?: ModelSchoolContactFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSchoolContactConnection = {
  __typename: "ModelSchoolContactConnection",
  items:  Array<SchoolContact | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMinFEVersionFilterInput = {
  version?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMinFEVersionFilterInput | null > | null,
  or?: Array< ModelMinFEVersionFilterInput | null > | null,
  not?: ModelMinFEVersionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMinFEVersionConnection = {
  __typename: "ModelMinFEVersionConnection",
  items:  Array<MinFEVersion | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSwitchRatingFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  cardId?: ModelIDInput | null,
  userType?: ModelUserTypeInput | null,
  rating?: ModelSwitchRatingTypeInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSwitchRatingFilterInput | null > | null,
  or?: Array< ModelSwitchRatingFilterInput | null > | null,
  not?: ModelSwitchRatingFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSwitchRatingConnection = {
  __typename: "ModelSwitchRatingConnection",
  items:  Array<SwitchRating | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSwitchRatingByUserIdCompositeKeyConditionInput = {
  eq?: ModelSwitchRatingByUserIdCompositeKeyInput | null,
  le?: ModelSwitchRatingByUserIdCompositeKeyInput | null,
  lt?: ModelSwitchRatingByUserIdCompositeKeyInput | null,
  ge?: ModelSwitchRatingByUserIdCompositeKeyInput | null,
  gt?: ModelSwitchRatingByUserIdCompositeKeyInput | null,
  between?: Array< ModelSwitchRatingByUserIdCompositeKeyInput | null > | null,
  beginsWith?: ModelSwitchRatingByUserIdCompositeKeyInput | null,
};

export type ModelSwitchRatingByUserIdCompositeKeyInput = {
  userType?: UserType | null,
  cardId?: string | null,
};

export type ModelClassLoginFilterInput = {
  id?: ModelIDInput | null,
  nickname?: ModelStringInput | null,
  cognitoUsername?: ModelStringInput | null,
  classID?: ModelIDInput | null,
  classCode?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  devicePushTokens?: ModelStringInput | null,
  and?: Array< ModelClassLoginFilterInput | null > | null,
  or?: Array< ModelClassLoginFilterInput | null > | null,
  not?: ModelClassLoginFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelClassLoginConnection = {
  __typename: "ModelClassLoginConnection",
  items:  Array<ClassLogin | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFavouriteFilterInput = {
  id?: ModelIDInput | null,
  cognitoUsername?: ModelStringInput | null,
  cardId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFavouriteFilterInput | null > | null,
  or?: Array< ModelFavouriteFilterInput | null > | null,
  not?: ModelFavouriteFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFavouriteConnection = {
  __typename: "ModelFavouriteConnection",
  items:  Array<Favourite | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFindYourCalmActivityRatingFilterInput = {
  userId?: ModelIDInput | null,
  activityId?: ModelStringInput | null,
  rating?: ModelIntInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFindYourCalmActivityRatingFilterInput | null > | null,
  or?: Array< ModelFindYourCalmActivityRatingFilterInput | null > | null,
  not?: ModelFindYourCalmActivityRatingFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFindYourCalmActivityRatingConnection = {
  __typename: "ModelFindYourCalmActivityRatingConnection",
  items:  Array<FindYourCalmActivityRating | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInvoiceFilterInput = {
  id?: ModelIDInput | null,
  schoolID?: ModelIDInput | null,
  url?: ModelStringInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInvoiceFilterInput | null > | null,
  or?: Array< ModelInvoiceFilterInput | null > | null,
  not?: ModelInvoiceFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInvoiceConnection = {
  __typename: "ModelInvoiceConnection",
  items:  Array<Invoice | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelVideosFilterInput = {
  id?: ModelIDInput | null,
  url?: ModelStringInput | null,
  image?: ModelStringInput | null,
  title?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  time?: ModelStringInput | null,
  level?: ModelStringInput | null,
  emotion?: ModelStringInput | null,
  ageGroup?: ModelStringInput | null,
  isExternal?: ModelBooleanInput | null,
  autoplay?: ModelBooleanInput | null,
  youtubePlaylistThumbnailUrlVideoId?: ModelStringInput | null,
  thumbnailUrl?: ModelStringInput | null,
  and?: Array< ModelVideosFilterInput | null > | null,
  or?: Array< ModelVideosFilterInput | null > | null,
  not?: ModelVideosFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelVideosConnection = {
  __typename: "ModelVideosConnection",
  items:  Array<Videos | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelWellbeingQuestionFilterInput = {
  id?: ModelIDInput | null,
  category?: ModelQuestionCategoryInput | null,
  type?: ModelQuestionTypeInput | null,
  text?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWellbeingQuestionFilterInput | null > | null,
  or?: Array< ModelWellbeingQuestionFilterInput | null > | null,
  not?: ModelWellbeingQuestionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelWellbeingQuestionConnection = {
  __typename: "ModelWellbeingQuestionConnection",
  items:  Array<WellbeingQuestion | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelWellbeingResponseFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  userType?: ModelWellbeingResponseUserTypeInput | null,
  wellbeingQuestionID?: ModelIDInput | null,
  answer?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  schoolID?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWellbeingResponseFilterInput | null > | null,
  or?: Array< ModelWellbeingResponseFilterInput | null > | null,
  not?: ModelWellbeingResponseFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelWellbeingResponseConnection = {
  __typename: "ModelWellbeingResponseConnection",
  items:  Array<WellbeingResponse | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SearchStudentByEmailInput = {
  email: string,
};

export type ModelSubscriptionStudentCheckInFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  studentID?: ModelSubscriptionIDInput | null,
  emotion?: ModelSubscriptionStringInput | null,
  emotionIntensity?: ModelSubscriptionIntInput | null,
  emotionIntensityPercentage?: ModelSubscriptionFloatInput | null,
  tiredness?: ModelSubscriptionIntInput | null,
  checkinsessionID?: ModelSubscriptionIDInput | null,
  classID?: ModelSubscriptionIDInput | null,
  absence?: ModelSubscriptionBooleanInput | null,
  notYet?: ModelSubscriptionBooleanInput | null,
  requestForChat?: ModelSubscriptionStringInput | null,
  chatCleared?: ModelSubscriptionBooleanInput | null,
  eating?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStudentCheckInFilterInput | null > | null,
  or?: Array< ModelSubscriptionStudentCheckInFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionTeacherCheckInFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  schoolID?: ModelSubscriptionIDInput | null,
  teacherID?: ModelSubscriptionIDInput | null,
  emotion?: ModelSubscriptionStringInput | null,
  emotionIntensityPercentage?: ModelSubscriptionFloatInput | null,
  tiredness?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionTeacherCheckInFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeacherCheckInFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionStaffCheckInFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  schoolID?: ModelSubscriptionIDInput | null,
  staffID?: ModelSubscriptionIDInput | null,
  emotion?: ModelSubscriptionStringInput | null,
  emotionIntensityPercentage?: ModelSubscriptionFloatInput | null,
  tiredness?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionStaffCheckInFilterInput | null > | null,
  or?: Array< ModelSubscriptionStaffCheckInFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCheckInSessionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  checkInSessionClassSequenceNo?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  classID?: ModelSubscriptionIDInput | null,
  openAt?: ModelSubscriptionStringInput | null,
  closeAt?: ModelSubscriptionStringInput | null,
  isActive?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionCheckInSessionFilterInput | null > | null,
  or?: Array< ModelSubscriptionCheckInSessionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionSchoolFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  schoolAdminUI?: ModelSubscriptionStringInput | null,
  utcOffset?: ModelSubscriptionStringInput | null,
  licenseTotal?: ModelSubscriptionStringInput | null,
  timezone?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  activeStudents?: ModelSubscriptionIntInput | null,
  trialEndDate?: ModelSubscriptionStringInput | null,
  renewalDate?: ModelSubscriptionStringInput | null,
  notes?: ModelSubscriptionStringInput | null,
  checkInCountAllTime?: ModelSubscriptionIntInput | null,
  checkInCountYTD?: ModelSubscriptionIntInput | null,
  checkInCountRollingWeekMonday?: ModelSubscriptionIntInput | null,
  checkInCountRollingWeekTuesday?: ModelSubscriptionIntInput | null,
  checkInCountRollingWeekWednesday?: ModelSubscriptionIntInput | null,
  checkInCountRollingWeekThursday?: ModelSubscriptionIntInput | null,
  checkInCountRollingWeekFriday?: ModelSubscriptionIntInput | null,
  checkInCountRollingWeekSaturday?: ModelSubscriptionIntInput | null,
  checkInCountRollingWeekSunday?: ModelSubscriptionIntInput | null,
  wholeSchoolSubscription?: ModelSubscriptionBooleanInput | null,
  billingAddress?: ModelSubscriptionStringInput | null,
  billingNotes?: ModelSubscriptionStringInput | null,
  invoiceUrl?: ModelSubscriptionStringInput | null,
  abn?: ModelSubscriptionStringInput | null,
  coordinatorID?: ModelSubscriptionIDInput | null,
  teacherWellbeing?: ModelSubscriptionBooleanInput | null,
  staffWellbeing?: ModelSubscriptionBooleanInput | null,
  wellbeingFeature?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSchoolFilterInput | null > | null,
  or?: Array< ModelSubscriptionSchoolFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCampusFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  schoolID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCampusFilterInput | null > | null,
  or?: Array< ModelSubscriptionCampusFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionClassGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  schoolID?: ModelSubscriptionIDInput | null,
  campusID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClassGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionClassGroupFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionClassFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  schoolID?: ModelSubscriptionIDInput | null,
  classGroupID?: ModelSubscriptionIDInput | null,
  emotionIntensityLevel?: ModelSubscriptionIntInput | null,
  toggleRequestForChat?: ModelSubscriptionBooleanInput | null,
  toggleRequestForChatNotifications?: ModelSubscriptionBooleanInput | null,
  toggleIndividualStudentStats?: ModelSubscriptionBooleanInput | null,
  bulkSignUpId?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  primaryContact?: ModelSubscriptionStringInput | null,
  archived?: ModelSubscriptionBooleanInput | null,
  emailReceivers?: ModelSubscriptionStringInput | null,
  lastCheckInSessionClassSequenceNo?: ModelSubscriptionIntInput | null,
  checkInCountYTD?: ModelSubscriptionIntInput | null,
  checkInCountRollingWeekMonday?: ModelSubscriptionIntInput | null,
  checkInCountRollingWeekTuesday?: ModelSubscriptionIntInput | null,
  checkInCountRollingWeekWednesday?: ModelSubscriptionIntInput | null,
  checkInCountRollingWeekThursday?: ModelSubscriptionIntInput | null,
  checkInCountRollingWeekFriday?: ModelSubscriptionIntInput | null,
  checkInCountRollingWeekSaturday?: ModelSubscriptionIntInput | null,
  checkInCountRollingWeekSunday?: ModelSubscriptionIntInput | null,
  activeStudents?: ModelSubscriptionIntInput | null,
  goMode?: ModelSubscriptionBooleanInput | null,
  yearLevel?: ModelSubscriptionStringInput | null,
  lastCheckInTime?: ModelSubscriptionStringInput | null,
  wellbeing?: ModelSubscriptionBooleanInput | null,
  wellbeingQuestionCategoryUsed?: ModelSubscriptionStringInput | null,
  wellbeingQuestionUsed?: ModelSubscriptionIDInput | null,
  currentWellbeingQuestionID?: ModelSubscriptionStringInput | null,
  wellbeingResponseCount?: ModelSubscriptionIntInput | null,
  toggleEating?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionClassFilterInput | null > | null,
  or?: Array< ModelSubscriptionClassFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  classClassLoginId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionTeacherFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  schoolID?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  cognitoUsername?: ModelSubscriptionStringInput | null,
  showIntroduction?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  onboarded?: ModelSubscriptionBooleanInput | null,
  devicePushTokens?: ModelSubscriptionStringInput | null,
  showNewFeatureInstructions?: ModelSubscriptionBooleanInput | null,
  preferredUsername?: ModelSubscriptionStringInput | null,
  wellbeingQuestionCategoryUsed?: ModelSubscriptionStringInput | null,
  wellbeingQuestionUsed?: ModelSubscriptionIDInput | null,
  wellbeingResponseCount?: ModelSubscriptionIntInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTeacherFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeacherFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionStaffFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cognitoUsername?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  schoolID?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  preferredUsername?: ModelSubscriptionStringInput | null,
  wellbeingQuestionCategoryUsed?: ModelSubscriptionStringInput | null,
  wellbeingQuestionUsed?: ModelSubscriptionIDInput | null,
  wellbeingResponseCount?: ModelSubscriptionIntInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  nonTeachingStaffCheckInCount?: ModelSubscriptionIntInput | null,
  nonTeachingStaffCheckInSkipSuggestionDate?: ModelSubscriptionStringInput | null,
  onboarded?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStaffFilterInput | null > | null,
  or?: Array< ModelSubscriptionStaffFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionSchoolAdminFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  schoolID?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  cognitoUsername?: ModelSubscriptionStringInput | null,
  preferredUsername?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSchoolAdminFilterInput | null > | null,
  or?: Array< ModelSubscriptionSchoolAdminFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionStudentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  schoolStudentID?: ModelSubscriptionStringInput | null,
  nickname?: ModelSubscriptionStringInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  schoolID?: ModelSubscriptionIDInput | null,
  yearLevel?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  cognitoUsername?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  onboarded?: ModelSubscriptionBooleanInput | null,
  initialLoginSetup?: ModelSubscriptionBooleanInput | null,
  activeClasses?: ModelSubscriptionIntInput | null,
  studentCheckInCount?: ModelSubscriptionIntInput | null,
  studentCheckInSkipSuggestionDate?: ModelSubscriptionStringInput | null,
  devicePushTokens?: ModelSubscriptionStringInput | null,
  wellbeingQuestionCategoryUsed?: ModelSubscriptionStringInput | null,
  wellbeingQuestionUsed?: ModelSubscriptionIDInput | null,
  currentWellbeingQuestion?: ModelSubscriptionStringInput | null,
  wellbeingResponseCount?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionStudentFilterInput | null > | null,
  or?: Array< ModelSubscriptionStudentFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionSupportFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  cognitoUsername?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSupportFilterInput | null > | null,
  or?: Array< ModelSubscriptionSupportFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionClassSupportFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  classID?: ModelSubscriptionIDInput | null,
  supportID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClassSupportFilterInput | null > | null,
  or?: Array< ModelSubscriptionClassSupportFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTeacherClassFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  teacherID?: ModelSubscriptionIDInput | null,
  classID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTeacherClassFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeacherClassFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionScheduleClassFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  classID?: ModelSubscriptionIDInput | null,
  schedule?: ModelSubscriptionStringInput | null,
  isActive?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionScheduleClassFilterInput | null > | null,
  or?: Array< ModelSubscriptionScheduleClassFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionStudentClassFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  studentID?: ModelSubscriptionIDInput | null,
  classID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  requestForChat?: ModelSubscriptionBooleanInput | null,
  requestForChatStatus?: ModelSubscriptionStringInput | null,
  studentCheckIns?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStudentClassFilterInput | null > | null,
  or?: Array< ModelSubscriptionStudentClassFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionSchoolContactFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  schoolID?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSchoolContactFilterInput | null > | null,
  or?: Array< ModelSubscriptionSchoolContactFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMinFEVersionFilterInput = {
  version?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMinFEVersionFilterInput | null > | null,
  or?: Array< ModelSubscriptionMinFEVersionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionSwitchRatingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  cardId?: ModelSubscriptionIDInput | null,
  userType?: ModelSubscriptionStringInput | null,
  rating?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSwitchRatingFilterInput | null > | null,
  or?: Array< ModelSubscriptionSwitchRatingFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionClassLoginFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  nickname?: ModelSubscriptionStringInput | null,
  cognitoUsername?: ModelSubscriptionStringInput | null,
  classID?: ModelSubscriptionIDInput | null,
  classCode?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  devicePushTokens?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClassLoginFilterInput | null > | null,
  or?: Array< ModelSubscriptionClassLoginFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFavouriteFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cognitoUsername?: ModelSubscriptionStringInput | null,
  cardId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFavouriteFilterInput | null > | null,
  or?: Array< ModelSubscriptionFavouriteFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFindYourCalmActivityRatingFilterInput = {
  userId?: ModelSubscriptionIDInput | null,
  activityId?: ModelSubscriptionStringInput | null,
  rating?: ModelSubscriptionIntInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFindYourCalmActivityRatingFilterInput | null > | null,
  or?: Array< ModelSubscriptionFindYourCalmActivityRatingFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionInvoiceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  schoolID?: ModelSubscriptionIDInput | null,
  url?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInvoiceFilterInput | null > | null,
  or?: Array< ModelSubscriptionInvoiceFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionVideosFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  url?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  time?: ModelSubscriptionStringInput | null,
  level?: ModelSubscriptionStringInput | null,
  emotion?: ModelSubscriptionStringInput | null,
  ageGroup?: ModelSubscriptionStringInput | null,
  isExternal?: ModelSubscriptionBooleanInput | null,
  autoplay?: ModelSubscriptionBooleanInput | null,
  youtubePlaylistThumbnailUrlVideoId?: ModelSubscriptionStringInput | null,
  thumbnailUrl?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVideosFilterInput | null > | null,
  or?: Array< ModelSubscriptionVideosFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionWellbeingQuestionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  category?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  text?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWellbeingQuestionFilterInput | null > | null,
  or?: Array< ModelSubscriptionWellbeingQuestionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionWellbeingResponseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  userType?: ModelSubscriptionStringInput | null,
  wellbeingQuestionID?: ModelSubscriptionIDInput | null,
  answer?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  schoolID?: ModelSubscriptionIDInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWellbeingResponseFilterInput | null > | null,
  or?: Array< ModelSubscriptionWellbeingResponseFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type CreateStudentCheckInMutationVariables = {
  input: CreateStudentCheckInInput,
  condition?: ModelStudentCheckInConditionInput | null,
};

export type CreateStudentCheckInMutation = {
  createStudentCheckIn?:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateStudentCheckInMutationVariables = {
  input: UpdateStudentCheckInInput,
  condition?: ModelStudentCheckInConditionInput | null,
};

export type UpdateStudentCheckInMutation = {
  updateStudentCheckIn?:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteStudentCheckInMutationVariables = {
  input: DeleteStudentCheckInInput,
  condition?: ModelStudentCheckInConditionInput | null,
};

export type DeleteStudentCheckInMutation = {
  deleteStudentCheckIn?:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTeacherCheckInMutationVariables = {
  input: CreateTeacherCheckInInput,
  condition?: ModelTeacherCheckInConditionInput | null,
};

export type CreateTeacherCheckInMutation = {
  createTeacherCheckIn?:  {
    __typename: "TeacherCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTeacherCheckInMutationVariables = {
  input: UpdateTeacherCheckInInput,
  condition?: ModelTeacherCheckInConditionInput | null,
};

export type UpdateTeacherCheckInMutation = {
  updateTeacherCheckIn?:  {
    __typename: "TeacherCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTeacherCheckInMutationVariables = {
  input: DeleteTeacherCheckInInput,
  condition?: ModelTeacherCheckInConditionInput | null,
};

export type DeleteTeacherCheckInMutation = {
  deleteTeacherCheckIn?:  {
    __typename: "TeacherCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateStaffCheckInMutationVariables = {
  input: CreateStaffCheckInInput,
  condition?: ModelStaffCheckInConditionInput | null,
};

export type CreateStaffCheckInMutation = {
  createStaffCheckIn?:  {
    __typename: "StaffCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    staffID: string,
    staff?:  {
      __typename: "Staff",
      id: string,
      cognitoUsername: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      nonTeachingStaffCheckInCount?: number | null,
      nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateStaffCheckInMutationVariables = {
  input: UpdateStaffCheckInInput,
  condition?: ModelStaffCheckInConditionInput | null,
};

export type UpdateStaffCheckInMutation = {
  updateStaffCheckIn?:  {
    __typename: "StaffCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    staffID: string,
    staff?:  {
      __typename: "Staff",
      id: string,
      cognitoUsername: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      nonTeachingStaffCheckInCount?: number | null,
      nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteStaffCheckInMutationVariables = {
  input: DeleteStaffCheckInInput,
  condition?: ModelStaffCheckInConditionInput | null,
};

export type DeleteStaffCheckInMutation = {
  deleteStaffCheckIn?:  {
    __typename: "StaffCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    staffID: string,
    staff?:  {
      __typename: "Staff",
      id: string,
      cognitoUsername: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      nonTeachingStaffCheckInCount?: number | null,
      nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCheckInSessionMutationVariables = {
  input: CreateCheckInSessionInput,
  condition?: ModelCheckInSessionConditionInput | null,
};

export type CreateCheckInSessionMutation = {
  createCheckInSession?:  {
    __typename: "CheckInSession",
    id: string,
    checkInSessionClassSequenceNo?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    openAt?: string | null,
    closeAt?: string | null,
    isActive: boolean,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCheckInSessionMutationVariables = {
  input: UpdateCheckInSessionInput,
  condition?: ModelCheckInSessionConditionInput | null,
};

export type UpdateCheckInSessionMutation = {
  updateCheckInSession?:  {
    __typename: "CheckInSession",
    id: string,
    checkInSessionClassSequenceNo?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    openAt?: string | null,
    closeAt?: string | null,
    isActive: boolean,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCheckInSessionMutationVariables = {
  input: DeleteCheckInSessionInput,
  condition?: ModelCheckInSessionConditionInput | null,
};

export type DeleteCheckInSessionMutation = {
  deleteCheckInSession?:  {
    __typename: "CheckInSession",
    id: string,
    checkInSessionClassSequenceNo?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    openAt?: string | null,
    closeAt?: string | null,
    isActive: boolean,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSchoolMutationVariables = {
  input: CreateSchoolInput,
  condition?: ModelSchoolConditionInput | null,
};

export type CreateSchoolMutation = {
  createSchool?:  {
    __typename: "School",
    id: string,
    name: string,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    campuses?:  {
      __typename: "ModelCampusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teachers?:  {
      __typename: "ModelTeacherConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    students?:  {
      __typename: "ModelStudentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffs?:  {
      __typename: "ModelStaffConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdmins?:  {
      __typename: "ModelSchoolAdminConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdminUI?: SchoolAdminUI | null,
    utcOffset?: string | null,
    licenseTotal?: string | null,
    timezone?: string | null,
    status?: SchoolStatus | null,
    activeStudents?: number | null,
    trialEndDate?: string | null,
    renewalDate?: string | null,
    notes?: string | null,
    checkInCountAllTime?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    wholeSchoolSubscription?: boolean | null,
    billingAddress?: string | null,
    billingNotes?: string | null,
    invoiceUrl?: string | null,
    abn?: string | null,
    coordinatorID?: string | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teacherWellbeing?: boolean | null,
    staffWellbeing?: boolean | null,
    wellbeingFeature?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSchoolMutationVariables = {
  input: UpdateSchoolInput,
  condition?: ModelSchoolConditionInput | null,
};

export type UpdateSchoolMutation = {
  updateSchool?:  {
    __typename: "School",
    id: string,
    name: string,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    campuses?:  {
      __typename: "ModelCampusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teachers?:  {
      __typename: "ModelTeacherConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    students?:  {
      __typename: "ModelStudentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffs?:  {
      __typename: "ModelStaffConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdmins?:  {
      __typename: "ModelSchoolAdminConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdminUI?: SchoolAdminUI | null,
    utcOffset?: string | null,
    licenseTotal?: string | null,
    timezone?: string | null,
    status?: SchoolStatus | null,
    activeStudents?: number | null,
    trialEndDate?: string | null,
    renewalDate?: string | null,
    notes?: string | null,
    checkInCountAllTime?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    wholeSchoolSubscription?: boolean | null,
    billingAddress?: string | null,
    billingNotes?: string | null,
    invoiceUrl?: string | null,
    abn?: string | null,
    coordinatorID?: string | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teacherWellbeing?: boolean | null,
    staffWellbeing?: boolean | null,
    wellbeingFeature?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSchoolMutationVariables = {
  input: DeleteSchoolInput,
  condition?: ModelSchoolConditionInput | null,
};

export type DeleteSchoolMutation = {
  deleteSchool?:  {
    __typename: "School",
    id: string,
    name: string,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    campuses?:  {
      __typename: "ModelCampusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teachers?:  {
      __typename: "ModelTeacherConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    students?:  {
      __typename: "ModelStudentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffs?:  {
      __typename: "ModelStaffConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdmins?:  {
      __typename: "ModelSchoolAdminConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdminUI?: SchoolAdminUI | null,
    utcOffset?: string | null,
    licenseTotal?: string | null,
    timezone?: string | null,
    status?: SchoolStatus | null,
    activeStudents?: number | null,
    trialEndDate?: string | null,
    renewalDate?: string | null,
    notes?: string | null,
    checkInCountAllTime?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    wholeSchoolSubscription?: boolean | null,
    billingAddress?: string | null,
    billingNotes?: string | null,
    invoiceUrl?: string | null,
    abn?: string | null,
    coordinatorID?: string | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teacherWellbeing?: boolean | null,
    staffWellbeing?: boolean | null,
    wellbeingFeature?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCampusMutationVariables = {
  input: CreateCampusInput,
  condition?: ModelCampusConditionInput | null,
};

export type CreateCampusMutation = {
  createCampus?:  {
    __typename: "Campus",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCampusMutationVariables = {
  input: UpdateCampusInput,
  condition?: ModelCampusConditionInput | null,
};

export type UpdateCampusMutation = {
  updateCampus?:  {
    __typename: "Campus",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCampusMutationVariables = {
  input: DeleteCampusInput,
  condition?: ModelCampusConditionInput | null,
};

export type DeleteCampusMutation = {
  deleteCampus?:  {
    __typename: "Campus",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateClassGroupMutationVariables = {
  input: CreateClassGroupInput,
  condition?: ModelClassGroupConditionInput | null,
};

export type CreateClassGroupMutation = {
  createClassGroup?:  {
    __typename: "ClassGroup",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    campusID: string,
    campus?:  {
      __typename: "Campus",
      id: string,
      name: string,
      schoolID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateClassGroupMutationVariables = {
  input: UpdateClassGroupInput,
  condition?: ModelClassGroupConditionInput | null,
};

export type UpdateClassGroupMutation = {
  updateClassGroup?:  {
    __typename: "ClassGroup",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    campusID: string,
    campus?:  {
      __typename: "Campus",
      id: string,
      name: string,
      schoolID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteClassGroupMutationVariables = {
  input: DeleteClassGroupInput,
  condition?: ModelClassGroupConditionInput | null,
};

export type DeleteClassGroupMutation = {
  deleteClassGroup?:  {
    __typename: "ClassGroup",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    campusID: string,
    campus?:  {
      __typename: "Campus",
      id: string,
      name: string,
      schoolID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateClassMutationVariables = {
  input: CreateClassInput,
  condition?: ModelClassConditionInput | null,
};

export type CreateClassMutation = {
  createClass?:  {
    __typename: "Class",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroupID?: string | null,
    students?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classLogin?:  {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkInSessions?:  {
      __typename: "ModelCheckInSessionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    supports?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    emotionIntensityLevel?: number | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    toggleRequestForChat?: boolean | null,
    toggleRequestForChatNotifications?: boolean | null,
    toggleIndividualStudentStats?: boolean | null,
    teachers?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schedules?:  {
      __typename: "ModelScheduleClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bulkSignUpId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    primaryContact?: string | null,
    archived?: boolean | null,
    emailReceivers?: Array< string | null > | null,
    lastCheckInSessionClassSequenceNo?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    activeStudents?: number | null,
    goMode?: boolean | null,
    yearLevel?: string | null,
    lastCheckInTime?: string | null,
    wellbeing?: boolean | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestionID?: string | null,
    wellbeingResponseCount?: number | null,
    toggleEating?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    classClassLoginId?: string | null,
  } | null,
};

export type UpdateClassMutationVariables = {
  input: UpdateClassInput,
  condition?: ModelClassConditionInput | null,
};

export type UpdateClassMutation = {
  updateClass?:  {
    __typename: "Class",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroupID?: string | null,
    students?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classLogin?:  {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkInSessions?:  {
      __typename: "ModelCheckInSessionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    supports?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    emotionIntensityLevel?: number | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    toggleRequestForChat?: boolean | null,
    toggleRequestForChatNotifications?: boolean | null,
    toggleIndividualStudentStats?: boolean | null,
    teachers?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schedules?:  {
      __typename: "ModelScheduleClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bulkSignUpId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    primaryContact?: string | null,
    archived?: boolean | null,
    emailReceivers?: Array< string | null > | null,
    lastCheckInSessionClassSequenceNo?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    activeStudents?: number | null,
    goMode?: boolean | null,
    yearLevel?: string | null,
    lastCheckInTime?: string | null,
    wellbeing?: boolean | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestionID?: string | null,
    wellbeingResponseCount?: number | null,
    toggleEating?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    classClassLoginId?: string | null,
  } | null,
};

export type DeleteClassMutationVariables = {
  input: DeleteClassInput,
  condition?: ModelClassConditionInput | null,
};

export type DeleteClassMutation = {
  deleteClass?:  {
    __typename: "Class",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroupID?: string | null,
    students?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classLogin?:  {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkInSessions?:  {
      __typename: "ModelCheckInSessionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    supports?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    emotionIntensityLevel?: number | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    toggleRequestForChat?: boolean | null,
    toggleRequestForChatNotifications?: boolean | null,
    toggleIndividualStudentStats?: boolean | null,
    teachers?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schedules?:  {
      __typename: "ModelScheduleClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bulkSignUpId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    primaryContact?: string | null,
    archived?: boolean | null,
    emailReceivers?: Array< string | null > | null,
    lastCheckInSessionClassSequenceNo?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    activeStudents?: number | null,
    goMode?: boolean | null,
    yearLevel?: string | null,
    lastCheckInTime?: string | null,
    wellbeing?: boolean | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestionID?: string | null,
    wellbeingResponseCount?: number | null,
    toggleEating?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    classClassLoginId?: string | null,
  } | null,
};

export type CreateTeacherMutationVariables = {
  input: CreateTeacherInput,
  condition?: ModelTeacherConditionInput | null,
};

export type CreateTeacherMutation = {
  createTeacher?:  {
    __typename: "Teacher",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    classes?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoUsername: string,
    showIntroduction?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    role?: TeacherRole | null,
    onboarded?: boolean | null,
    devicePushTokens?: Array< string > | null,
    showNewFeatureInstructions?: boolean | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTeacherMutationVariables = {
  input: UpdateTeacherInput,
  condition?: ModelTeacherConditionInput | null,
};

export type UpdateTeacherMutation = {
  updateTeacher?:  {
    __typename: "Teacher",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    classes?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoUsername: string,
    showIntroduction?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    role?: TeacherRole | null,
    onboarded?: boolean | null,
    devicePushTokens?: Array< string > | null,
    showNewFeatureInstructions?: boolean | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTeacherMutationVariables = {
  input: DeleteTeacherInput,
  condition?: ModelTeacherConditionInput | null,
};

export type DeleteTeacherMutation = {
  deleteTeacher?:  {
    __typename: "Teacher",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    classes?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoUsername: string,
    showIntroduction?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    role?: TeacherRole | null,
    onboarded?: boolean | null,
    devicePushTokens?: Array< string > | null,
    showNewFeatureInstructions?: boolean | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateStaffMutationVariables = {
  input: CreateStaffInput,
  condition?: ModelStaffConditionInput | null,
};

export type CreateStaffMutation = {
  createStaff?:  {
    __typename: "Staff",
    id: string,
    cognitoUsername: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    nonTeachingStaffCheckInCount?: number | null,
    nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateStaffMutationVariables = {
  input: UpdateStaffInput,
  condition?: ModelStaffConditionInput | null,
};

export type UpdateStaffMutation = {
  updateStaff?:  {
    __typename: "Staff",
    id: string,
    cognitoUsername: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    nonTeachingStaffCheckInCount?: number | null,
    nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteStaffMutationVariables = {
  input: DeleteStaffInput,
  condition?: ModelStaffConditionInput | null,
};

export type DeleteStaffMutation = {
  deleteStaff?:  {
    __typename: "Staff",
    id: string,
    cognitoUsername: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    nonTeachingStaffCheckInCount?: number | null,
    nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSchoolAdminMutationVariables = {
  input: CreateSchoolAdminInput,
  condition?: ModelSchoolAdminConditionInput | null,
};

export type CreateSchoolAdminMutation = {
  createSchoolAdmin?:  {
    __typename: "SchoolAdmin",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    cognitoUsername: string,
    preferredUsername?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSchoolAdminMutationVariables = {
  input: UpdateSchoolAdminInput,
  condition?: ModelSchoolAdminConditionInput | null,
};

export type UpdateSchoolAdminMutation = {
  updateSchoolAdmin?:  {
    __typename: "SchoolAdmin",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    cognitoUsername: string,
    preferredUsername?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSchoolAdminMutationVariables = {
  input: DeleteSchoolAdminInput,
  condition?: ModelSchoolAdminConditionInput | null,
};

export type DeleteSchoolAdminMutation = {
  deleteSchoolAdmin?:  {
    __typename: "SchoolAdmin",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    cognitoUsername: string,
    preferredUsername?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateStudentMutationVariables = {
  input: CreateStudentInput,
  condition?: ModelStudentConditionInput | null,
};

export type CreateStudentMutation = {
  createStudent?:  {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    yearLevel?: string | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    classes?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    activeClasses?: number | null,
    studentCheckInCount?: number | null,
    studentCheckInSkipSuggestionDate?: string | null,
    devicePushTokens?: Array< string > | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestion?: string | null,
    wellbeingResponseCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateStudentMutationVariables = {
  input: UpdateStudentInput,
  condition?: ModelStudentConditionInput | null,
};

export type UpdateStudentMutation = {
  updateStudent?:  {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    yearLevel?: string | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    classes?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    activeClasses?: number | null,
    studentCheckInCount?: number | null,
    studentCheckInSkipSuggestionDate?: string | null,
    devicePushTokens?: Array< string > | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestion?: string | null,
    wellbeingResponseCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteStudentMutationVariables = {
  input: DeleteStudentInput,
  condition?: ModelStudentConditionInput | null,
};

export type DeleteStudentMutation = {
  deleteStudent?:  {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    yearLevel?: string | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    classes?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    activeClasses?: number | null,
    studentCheckInCount?: number | null,
    studentCheckInSkipSuggestionDate?: string | null,
    devicePushTokens?: Array< string > | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestion?: string | null,
    wellbeingResponseCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSupportMutationVariables = {
  input: CreateSupportInput,
  condition?: ModelSupportConditionInput | null,
};

export type CreateSupportMutation = {
  createSupport?:  {
    __typename: "Support",
    id: string,
    email?: string | null,
    classes?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName: string,
    lastName: string,
    cognitoUsername: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSupportMutationVariables = {
  input: UpdateSupportInput,
  condition?: ModelSupportConditionInput | null,
};

export type UpdateSupportMutation = {
  updateSupport?:  {
    __typename: "Support",
    id: string,
    email?: string | null,
    classes?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName: string,
    lastName: string,
    cognitoUsername: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSupportMutationVariables = {
  input: DeleteSupportInput,
  condition?: ModelSupportConditionInput | null,
};

export type DeleteSupportMutation = {
  deleteSupport?:  {
    __typename: "Support",
    id: string,
    email?: string | null,
    classes?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName: string,
    lastName: string,
    cognitoUsername: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateClassSupportMutationVariables = {
  input: CreateClassSupportInput,
  condition?: ModelClassSupportConditionInput | null,
};

export type CreateClassSupportMutation = {
  createClassSupport?:  {
    __typename: "ClassSupport",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    supportID: string,
    support?:  {
      __typename: "Support",
      id: string,
      email?: string | null,
      firstName: string,
      lastName: string,
      cognitoUsername: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateClassSupportMutationVariables = {
  input: UpdateClassSupportInput,
  condition?: ModelClassSupportConditionInput | null,
};

export type UpdateClassSupportMutation = {
  updateClassSupport?:  {
    __typename: "ClassSupport",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    supportID: string,
    support?:  {
      __typename: "Support",
      id: string,
      email?: string | null,
      firstName: string,
      lastName: string,
      cognitoUsername: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteClassSupportMutationVariables = {
  input: DeleteClassSupportInput,
  condition?: ModelClassSupportConditionInput | null,
};

export type DeleteClassSupportMutation = {
  deleteClassSupport?:  {
    __typename: "ClassSupport",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    supportID: string,
    support?:  {
      __typename: "Support",
      id: string,
      email?: string | null,
      firstName: string,
      lastName: string,
      cognitoUsername: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTeacherClassMutationVariables = {
  input: CreateTeacherClassInput,
  condition?: ModelTeacherClassConditionInput | null,
};

export type CreateTeacherClassMutation = {
  createTeacherClass?:  {
    __typename: "TeacherClass",
    id: string,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTeacherClassMutationVariables = {
  input: UpdateTeacherClassInput,
  condition?: ModelTeacherClassConditionInput | null,
};

export type UpdateTeacherClassMutation = {
  updateTeacherClass?:  {
    __typename: "TeacherClass",
    id: string,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTeacherClassMutationVariables = {
  input: DeleteTeacherClassInput,
  condition?: ModelTeacherClassConditionInput | null,
};

export type DeleteTeacherClassMutation = {
  deleteTeacherClass?:  {
    __typename: "TeacherClass",
    id: string,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateScheduleClassMutationVariables = {
  input: CreateScheduleClassInput,
  condition?: ModelScheduleClassConditionInput | null,
};

export type CreateScheduleClassMutation = {
  createScheduleClass?:  {
    __typename: "ScheduleClass",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    schedule: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateScheduleClassMutationVariables = {
  input: UpdateScheduleClassInput,
  condition?: ModelScheduleClassConditionInput | null,
};

export type UpdateScheduleClassMutation = {
  updateScheduleClass?:  {
    __typename: "ScheduleClass",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    schedule: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteScheduleClassMutationVariables = {
  input: DeleteScheduleClassInput,
  condition?: ModelScheduleClassConditionInput | null,
};

export type DeleteScheduleClassMutation = {
  deleteScheduleClass?:  {
    __typename: "ScheduleClass",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    schedule: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateStudentClassMutationVariables = {
  input: CreateStudentClassInput,
  condition?: ModelStudentClassConditionInput | null,
};

export type CreateStudentClassMutation = {
  createStudentClass?:  {
    __typename: "StudentClass",
    id: string,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    status?: StudentClassApprovalStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    requestForChat?: boolean | null,
    requestForChatStatus?: RequestForChatStatus | null,
    studentCheckIns?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateStudentClassMutationVariables = {
  input: UpdateStudentClassInput,
  condition?: ModelStudentClassConditionInput | null,
};

export type UpdateStudentClassMutation = {
  updateStudentClass?:  {
    __typename: "StudentClass",
    id: string,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    status?: StudentClassApprovalStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    requestForChat?: boolean | null,
    requestForChatStatus?: RequestForChatStatus | null,
    studentCheckIns?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteStudentClassMutationVariables = {
  input: DeleteStudentClassInput,
  condition?: ModelStudentClassConditionInput | null,
};

export type DeleteStudentClassMutation = {
  deleteStudentClass?:  {
    __typename: "StudentClass",
    id: string,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    status?: StudentClassApprovalStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    requestForChat?: boolean | null,
    requestForChatStatus?: RequestForChatStatus | null,
    studentCheckIns?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSchoolContactMutationVariables = {
  input: CreateSchoolContactInput,
  condition?: ModelSchoolContactConditionInput | null,
};

export type CreateSchoolContactMutation = {
  createSchoolContact?:  {
    __typename: "SchoolContact",
    id: string,
    schoolID: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    type?: SchoolContactType | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSchoolContactMutationVariables = {
  input: UpdateSchoolContactInput,
  condition?: ModelSchoolContactConditionInput | null,
};

export type UpdateSchoolContactMutation = {
  updateSchoolContact?:  {
    __typename: "SchoolContact",
    id: string,
    schoolID: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    type?: SchoolContactType | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSchoolContactMutationVariables = {
  input: DeleteSchoolContactInput,
  condition?: ModelSchoolContactConditionInput | null,
};

export type DeleteSchoolContactMutation = {
  deleteSchoolContact?:  {
    __typename: "SchoolContact",
    id: string,
    schoolID: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    type?: SchoolContactType | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMinFEVersionMutationVariables = {
  input: CreateMinFEVersionInput,
  condition?: ModelMinFEVersionConditionInput | null,
};

export type CreateMinFEVersionMutation = {
  createMinFEVersion?:  {
    __typename: "MinFEVersion",
    version: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMinFEVersionMutationVariables = {
  input: UpdateMinFEVersionInput,
  condition?: ModelMinFEVersionConditionInput | null,
};

export type UpdateMinFEVersionMutation = {
  updateMinFEVersion?:  {
    __typename: "MinFEVersion",
    version: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMinFEVersionMutationVariables = {
  input: DeleteMinFEVersionInput,
  condition?: ModelMinFEVersionConditionInput | null,
};

export type DeleteMinFEVersionMutation = {
  deleteMinFEVersion?:  {
    __typename: "MinFEVersion",
    version: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSwitchRatingMutationVariables = {
  input: CreateSwitchRatingInput,
  condition?: ModelSwitchRatingConditionInput | null,
};

export type CreateSwitchRatingMutation = {
  createSwitchRating?:  {
    __typename: "SwitchRating",
    id: string,
    userId: string,
    cardId: string,
    userType: UserType,
    rating: SwitchRatingType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSwitchRatingMutationVariables = {
  input: UpdateSwitchRatingInput,
  condition?: ModelSwitchRatingConditionInput | null,
};

export type UpdateSwitchRatingMutation = {
  updateSwitchRating?:  {
    __typename: "SwitchRating",
    id: string,
    userId: string,
    cardId: string,
    userType: UserType,
    rating: SwitchRatingType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSwitchRatingMutationVariables = {
  input: DeleteSwitchRatingInput,
  condition?: ModelSwitchRatingConditionInput | null,
};

export type DeleteSwitchRatingMutation = {
  deleteSwitchRating?:  {
    __typename: "SwitchRating",
    id: string,
    userId: string,
    cardId: string,
    userType: UserType,
    rating: SwitchRatingType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateClassLoginMutationVariables = {
  input: CreateClassLoginInput,
  condition?: ModelClassLoginConditionInput | null,
};

export type CreateClassLoginMutation = {
  createClassLogin?:  {
    __typename: "ClassLogin",
    id: string,
    nickname: string,
    cognitoUsername: string,
    classID: string,
    classCode: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    devicePushTokens?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateClassLoginMutationVariables = {
  input: UpdateClassLoginInput,
  condition?: ModelClassLoginConditionInput | null,
};

export type UpdateClassLoginMutation = {
  updateClassLogin?:  {
    __typename: "ClassLogin",
    id: string,
    nickname: string,
    cognitoUsername: string,
    classID: string,
    classCode: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    devicePushTokens?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteClassLoginMutationVariables = {
  input: DeleteClassLoginInput,
  condition?: ModelClassLoginConditionInput | null,
};

export type DeleteClassLoginMutation = {
  deleteClassLogin?:  {
    __typename: "ClassLogin",
    id: string,
    nickname: string,
    cognitoUsername: string,
    classID: string,
    classCode: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    devicePushTokens?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFavouriteMutationVariables = {
  input: CreateFavouriteInput,
  condition?: ModelFavouriteConditionInput | null,
};

export type CreateFavouriteMutation = {
  createFavourite?:  {
    __typename: "Favourite",
    id: string,
    cognitoUsername: string,
    cardId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFavouriteMutationVariables = {
  input: UpdateFavouriteInput,
  condition?: ModelFavouriteConditionInput | null,
};

export type UpdateFavouriteMutation = {
  updateFavourite?:  {
    __typename: "Favourite",
    id: string,
    cognitoUsername: string,
    cardId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFavouriteMutationVariables = {
  input: DeleteFavouriteInput,
  condition?: ModelFavouriteConditionInput | null,
};

export type DeleteFavouriteMutation = {
  deleteFavourite?:  {
    __typename: "Favourite",
    id: string,
    cognitoUsername: string,
    cardId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFindYourCalmActivityRatingMutationVariables = {
  input: CreateFindYourCalmActivityRatingInput,
  condition?: ModelFindYourCalmActivityRatingConditionInput | null,
};

export type CreateFindYourCalmActivityRatingMutation = {
  createFindYourCalmActivityRating?:  {
    __typename: "FindYourCalmActivityRating",
    userId: string,
    activityId: string,
    rating?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFindYourCalmActivityRatingMutationVariables = {
  input: UpdateFindYourCalmActivityRatingInput,
  condition?: ModelFindYourCalmActivityRatingConditionInput | null,
};

export type UpdateFindYourCalmActivityRatingMutation = {
  updateFindYourCalmActivityRating?:  {
    __typename: "FindYourCalmActivityRating",
    userId: string,
    activityId: string,
    rating?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFindYourCalmActivityRatingMutationVariables = {
  input: DeleteFindYourCalmActivityRatingInput,
  condition?: ModelFindYourCalmActivityRatingConditionInput | null,
};

export type DeleteFindYourCalmActivityRatingMutation = {
  deleteFindYourCalmActivityRating?:  {
    __typename: "FindYourCalmActivityRating",
    userId: string,
    activityId: string,
    rating?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInvoiceMutationVariables = {
  input: CreateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type CreateInvoiceMutation = {
  createInvoice?:  {
    __typename: "Invoice",
    id: string,
    schoolID: string,
    url: string,
    name?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInvoiceMutationVariables = {
  input: UpdateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type UpdateInvoiceMutation = {
  updateInvoice?:  {
    __typename: "Invoice",
    id: string,
    schoolID: string,
    url: string,
    name?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInvoiceMutationVariables = {
  input: DeleteInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type DeleteInvoiceMutation = {
  deleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    schoolID: string,
    url: string,
    name?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateVideosMutationVariables = {
  input: CreateVideosInput,
  condition?: ModelVideosConditionInput | null,
};

export type CreateVideosMutation = {
  createVideos?:  {
    __typename: "Videos",
    id: string,
    url: string,
    image?: string | null,
    title?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    time?: Array< string | null > | null,
    level?: Array< string | null > | null,
    emotion?: Array< string | null > | null,
    ageGroup?: Array< string | null > | null,
    isExternal?: boolean | null,
    autoplay?: boolean | null,
    youtubePlaylistThumbnailUrlVideoId?: string | null,
    thumbnailUrl?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateVideosMutationVariables = {
  input: UpdateVideosInput,
  condition?: ModelVideosConditionInput | null,
};

export type UpdateVideosMutation = {
  updateVideos?:  {
    __typename: "Videos",
    id: string,
    url: string,
    image?: string | null,
    title?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    time?: Array< string | null > | null,
    level?: Array< string | null > | null,
    emotion?: Array< string | null > | null,
    ageGroup?: Array< string | null > | null,
    isExternal?: boolean | null,
    autoplay?: boolean | null,
    youtubePlaylistThumbnailUrlVideoId?: string | null,
    thumbnailUrl?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteVideosMutationVariables = {
  input: DeleteVideosInput,
  condition?: ModelVideosConditionInput | null,
};

export type DeleteVideosMutation = {
  deleteVideos?:  {
    __typename: "Videos",
    id: string,
    url: string,
    image?: string | null,
    title?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    time?: Array< string | null > | null,
    level?: Array< string | null > | null,
    emotion?: Array< string | null > | null,
    ageGroup?: Array< string | null > | null,
    isExternal?: boolean | null,
    autoplay?: boolean | null,
    youtubePlaylistThumbnailUrlVideoId?: string | null,
    thumbnailUrl?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateWellbeingQuestionMutationVariables = {
  input: CreateWellbeingQuestionInput,
  condition?: ModelWellbeingQuestionConditionInput | null,
};

export type CreateWellbeingQuestionMutation = {
  createWellbeingQuestion?:  {
    __typename: "WellbeingQuestion",
    id: string,
    category: QuestionCategory,
    type: QuestionType,
    text: string,
    options:  Array< {
      __typename: "QuestionOption",
      text: string,
      value: number,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateWellbeingQuestionMutationVariables = {
  input: UpdateWellbeingQuestionInput,
  condition?: ModelWellbeingQuestionConditionInput | null,
};

export type UpdateWellbeingQuestionMutation = {
  updateWellbeingQuestion?:  {
    __typename: "WellbeingQuestion",
    id: string,
    category: QuestionCategory,
    type: QuestionType,
    text: string,
    options:  Array< {
      __typename: "QuestionOption",
      text: string,
      value: number,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteWellbeingQuestionMutationVariables = {
  input: DeleteWellbeingQuestionInput,
  condition?: ModelWellbeingQuestionConditionInput | null,
};

export type DeleteWellbeingQuestionMutation = {
  deleteWellbeingQuestion?:  {
    __typename: "WellbeingQuestion",
    id: string,
    category: QuestionCategory,
    type: QuestionType,
    text: string,
    options:  Array< {
      __typename: "QuestionOption",
      text: string,
      value: number,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateWellbeingResponseMutationVariables = {
  input: CreateWellbeingResponseInput,
  condition?: ModelWellbeingResponseConditionInput | null,
};

export type CreateWellbeingResponseMutation = {
  createWellbeingResponse?:  {
    __typename: "WellbeingResponse",
    id: string,
    userID: string,
    userType: WellbeingResponseUserType,
    wellbeingQuestionID: string,
    answer: number,
    createdAt: string,
    schoolID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateWellbeingResponseMutationVariables = {
  input: UpdateWellbeingResponseInput,
  condition?: ModelWellbeingResponseConditionInput | null,
};

export type UpdateWellbeingResponseMutation = {
  updateWellbeingResponse?:  {
    __typename: "WellbeingResponse",
    id: string,
    userID: string,
    userType: WellbeingResponseUserType,
    wellbeingQuestionID: string,
    answer: number,
    createdAt: string,
    schoolID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteWellbeingResponseMutationVariables = {
  input: DeleteWellbeingResponseInput,
  condition?: ModelWellbeingResponseConditionInput | null,
};

export type DeleteWellbeingResponseMutation = {
  deleteWellbeingResponse?:  {
    __typename: "WellbeingResponse",
    id: string,
    userID: string,
    userType: WellbeingResponseUserType,
    wellbeingQuestionID: string,
    answer: number,
    createdAt: string,
    schoolID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type AddDevicePushTokenMutationVariables = {
  input: DevicePushTokenInput,
};

export type AddDevicePushTokenMutation = {
  addDevicePushToken: boolean,
};

export type CreateGoClassStudentCheckInMutationVariables = {
  input: CreateGoClassStudentCheckInInput,
};

export type CreateGoClassStudentCheckInMutation = {
  createGoClassStudentCheckIn:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type CustomCreateTeacherClassMutationVariables = {
  input: CustomCreateTeacherClassInput,
};

export type CustomCreateTeacherClassMutation = {
  customCreateTeacherClass:  {
    __typename: "TeacherClass",
    id: string,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type CustomDeleteTeacherClassMutationVariables = {
  input: CustomDeleteTeacherCLassInput,
};

export type CustomDeleteTeacherClassMutation = {
  customDeleteTeacherClass:  {
    __typename: "TeacherClass",
    id: string,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type CustomUpdateSchoolAdminMutationVariables = {
  input: UpdateSchoolAdminForBillingInfoInput,
};

export type CustomUpdateSchoolAdminMutation = {
  customUpdateSchoolAdmin:  {
    __typename: "SchoolAdmin",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    cognitoUsername: string,
    preferredUsername?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type EndCurrentCheckInSessionMutationVariables = {
  input: CloseCheckInSessionInput,
};

export type EndCurrentCheckInSessionMutation = {
  endCurrentCheckInSession:  {
    __typename: "CheckInSession",
    id: string,
    checkInSessionClassSequenceNo?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    openAt?: string | null,
    closeAt?: string | null,
    isActive: boolean,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type OpenNewCheckInSessionMutationVariables = {
  input: OpenCheckInSessionInput,
};

export type OpenNewCheckInSessionMutation = {
  openNewCheckInSession:  {
    __typename: "CheckInSession",
    id: string,
    checkInSessionClassSequenceNo?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    openAt?: string | null,
    closeAt?: string | null,
    isActive: boolean,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type PingMutationVariables = {
  id: string,
};

export type PingMutation = {
  ping:  {
    __typename: "Ping",
    id: string,
  },
};

export type RemoveDevicePushTokenMutationVariables = {
  input: DevicePushTokenInput,
};

export type RemoveDevicePushTokenMutation = {
  removeDevicePushToken: boolean,
};

export type ResetAllStudentPasswordInClassMutationVariables = {
  input: resetAllStudentPasswordInClass,
};

export type ResetAllStudentPasswordInClassMutation = {
  resetAllStudentPasswordInClass: boolean,
};

export type SetRequestForChatMutationVariables = {
  input: SetRequestForChatInput,
};

export type SetRequestForChatMutation = {
  setRequestForChat:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type UpdateSchoolCoordinatorForBillingInfoMutationVariables = {
  input: UpdateSchoolCoordinatorForBillingInfo,
};

export type UpdateSchoolCoordinatorForBillingInfoMutation = {
  updateSchoolCoordinatorForBillingInfo?:  {
    __typename: "School",
    id: string,
    name: string,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    campuses?:  {
      __typename: "ModelCampusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teachers?:  {
      __typename: "ModelTeacherConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    students?:  {
      __typename: "ModelStudentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffs?:  {
      __typename: "ModelStaffConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdmins?:  {
      __typename: "ModelSchoolAdminConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdminUI?: SchoolAdminUI | null,
    utcOffset?: string | null,
    licenseTotal?: string | null,
    timezone?: string | null,
    status?: SchoolStatus | null,
    activeStudents?: number | null,
    trialEndDate?: string | null,
    renewalDate?: string | null,
    notes?: string | null,
    checkInCountAllTime?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    wholeSchoolSubscription?: boolean | null,
    billingAddress?: string | null,
    billingNotes?: string | null,
    invoiceUrl?: string | null,
    abn?: string | null,
    coordinatorID?: string | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teacherWellbeing?: boolean | null,
    staffWellbeing?: boolean | null,
    wellbeingFeature?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSchoolForBillingInfoMutationVariables = {
  input: UpdateSchoolForBillingInfoInput,
};

export type UpdateSchoolForBillingInfoMutation = {
  updateSchoolForBillingInfo:  {
    __typename: "School",
    id: string,
    name: string,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    campuses?:  {
      __typename: "ModelCampusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teachers?:  {
      __typename: "ModelTeacherConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    students?:  {
      __typename: "ModelStudentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffs?:  {
      __typename: "ModelStaffConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdmins?:  {
      __typename: "ModelSchoolAdminConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdminUI?: SchoolAdminUI | null,
    utcOffset?: string | null,
    licenseTotal?: string | null,
    timezone?: string | null,
    status?: SchoolStatus | null,
    activeStudents?: number | null,
    trialEndDate?: string | null,
    renewalDate?: string | null,
    notes?: string | null,
    checkInCountAllTime?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    wholeSchoolSubscription?: boolean | null,
    billingAddress?: string | null,
    billingNotes?: string | null,
    invoiceUrl?: string | null,
    abn?: string | null,
    coordinatorID?: string | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teacherWellbeing?: boolean | null,
    staffWellbeing?: boolean | null,
    wellbeingFeature?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type UpsertFindYourCalmActivityRatingMutationVariables = {
  input: UpsertFindYourCalmActivityRatingInput,
};

export type UpsertFindYourCalmActivityRatingMutation = {
  upsertFindYourCalmActivityRating:  {
    __typename: "FindYourCalmActivityRating",
    userId: string,
    activityId: string,
    rating?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type UpsertStudentCheckInMutationVariables = {
  input: UpsertStudentCheckInInput,
};

export type UpsertStudentCheckInMutation = {
  upsertStudentCheckIn:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type ArchivedClassMutationVariables = {
  input: ArchivedClassInput,
};

export type ArchivedClassMutation = {
  archivedClass:  {
    __typename: "Class",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroupID?: string | null,
    students?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classLogin?:  {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkInSessions?:  {
      __typename: "ModelCheckInSessionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    supports?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    emotionIntensityLevel?: number | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    toggleRequestForChat?: boolean | null,
    toggleRequestForChatNotifications?: boolean | null,
    toggleIndividualStudentStats?: boolean | null,
    teachers?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schedules?:  {
      __typename: "ModelScheduleClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bulkSignUpId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    primaryContact?: string | null,
    archived?: boolean | null,
    emailReceivers?: Array< string | null > | null,
    lastCheckInSessionClassSequenceNo?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    activeStudents?: number | null,
    goMode?: boolean | null,
    yearLevel?: string | null,
    lastCheckInTime?: string | null,
    wellbeing?: boolean | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestionID?: string | null,
    wellbeingResponseCount?: number | null,
    toggleEating?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    classClassLoginId?: string | null,
  },
};

export type CustomCreateStaffCheckInMutationVariables = {
  input: CustomCreateStaffCheckInInput,
};

export type CustomCreateStaffCheckInMutation = {
  customCreateStaffCheckIn:  {
    __typename: "StaffCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    staffID: string,
    staff?:  {
      __typename: "Staff",
      id: string,
      cognitoUsername: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      nonTeachingStaffCheckInCount?: number | null,
      nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type SetRequestForChatForGoClassMutationVariables = {
  input: SetRequestForChatForGoClassInput,
};

export type SetRequestForChatForGoClassMutation = {
  setRequestForChatForGoClass:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type ClearClassStudentCheckInAlertsMutationVariables = {
  input: ClearClassStudentCheckInAlertsInput,
};

export type ClearClassStudentCheckInAlertsMutation = {
  clearClassStudentCheckInAlerts?:  Array< {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null > | null,
};

export type CustomCreateClassLoginMutationVariables = {
  input: CustomCreateClassLoginInput,
};

export type CustomCreateClassLoginMutation = {
  customCreateClassLogin:  {
    __typename: "ClassLogin",
    id: string,
    nickname: string,
    cognitoUsername: string,
    classID: string,
    classCode: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    devicePushTokens?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type CustomCreateSchoolAdminMutationVariables = {
  input: CreateSchoolAdminForBillingInfoInput,
};

export type CustomCreateSchoolAdminMutation = {
  customCreateSchoolAdmin:  {
    __typename: "SchoolAdmin",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    cognitoUsername: string,
    preferredUsername?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type CustomCreateStudentMutationVariables = {
  input: CustomCreateStudentInput,
};

export type CustomCreateStudentMutation = {
  customCreateStudent:  {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    yearLevel?: string | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    classes?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    activeClasses?: number | null,
    studentCheckInCount?: number | null,
    studentCheckInSkipSuggestionDate?: string | null,
    devicePushTokens?: Array< string > | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestion?: string | null,
    wellbeingResponseCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type CustomCreateStaffMutationVariables = {
  input: CustomCreateStaffInput,
};

export type CustomCreateStaffMutation = {
  customCreateStaff:  {
    __typename: "Staff",
    id: string,
    cognitoUsername: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    nonTeachingStaffCheckInCount?: number | null,
    nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type CustomCreateTeacherAndAssignedToClassMutationVariables = {
  input: CustomCreateTeacherAndAssignedToClassInput,
};

export type CustomCreateTeacherAndAssignedToClassMutation = {
  customCreateTeacherAndAssignedToClass:  {
    __typename: "Teacher",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    classes?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoUsername: string,
    showIntroduction?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    role?: TeacherRole | null,
    onboarded?: boolean | null,
    devicePushTokens?: Array< string > | null,
    showNewFeatureInstructions?: boolean | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type CustomCreateTeacherMutationVariables = {
  input: CustomCreateTeacherInput,
};

export type CustomCreateTeacherMutation = {
  customCreateTeacher:  {
    __typename: "Teacher",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    classes?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoUsername: string,
    showIntroduction?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    role?: TeacherRole | null,
    onboarded?: boolean | null,
    devicePushTokens?: Array< string > | null,
    showNewFeatureInstructions?: boolean | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type CustomDeleteTeacherMutationVariables = {
  input: CustomDeleteTeacherInput,
};

export type CustomDeleteTeacherMutation = {
  customDeleteTeacher:  {
    __typename: "Teacher",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    classes?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoUsername: string,
    showIntroduction?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    role?: TeacherRole | null,
    onboarded?: boolean | null,
    devicePushTokens?: Array< string > | null,
    showNewFeatureInstructions?: boolean | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type CustomDeleteStaffMutationVariables = {
  input: CustomDeleteStaffInput,
};

export type CustomDeleteStaffMutation = {
  customDeleteStaff:  {
    __typename: "Staff",
    id: string,
    cognitoUsername: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    nonTeachingStaffCheckInCount?: number | null,
    nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type CustomRemoveSchoolAdminMutationVariables = {
  input: RemoveSchoolAdminForBillingInfoInput,
};

export type CustomRemoveSchoolAdminMutation = {
  customRemoveSchoolAdmin?:  {
    __typename: "SchoolAdmin",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    cognitoUsername: string,
    preferredUsername?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CustomUpdateStaffMutationVariables = {
  input: CustomUpdateStaffInput,
};

export type CustomUpdateStaffMutation = {
  customUpdateStaff:  {
    __typename: "Staff",
    id: string,
    cognitoUsername: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    nonTeachingStaffCheckInCount?: number | null,
    nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type CustomUpdateTeacherMutationVariables = {
  input: CustomUpdateTeacherInput,
};

export type CustomUpdateTeacherMutation = {
  customUpdateTeacher:  {
    __typename: "Teacher",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    classes?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoUsername: string,
    showIntroduction?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    role?: TeacherRole | null,
    onboarded?: boolean | null,
    devicePushTokens?: Array< string > | null,
    showNewFeatureInstructions?: boolean | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type UpdateClassLoginCodeMutationVariables = {
  input: CustomUpdateClassLoginCodeInput,
};

export type UpdateClassLoginCodeMutation = {
  updateClassLoginCode:  {
    __typename: "ClassLogin",
    id: string,
    nickname: string,
    cognitoUsername: string,
    classID: string,
    classCode: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    devicePushTokens?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  },
};

export type SubmitWellbeingResponseMutationVariables = {
  input: SubmitWellbeingResponseInput,
};

export type SubmitWellbeingResponseMutation = {
  submitWellbeingResponse?:  {
    __typename: "WellbeingResponse",
    id: string,
    userID: string,
    userType: WellbeingResponseUserType,
    wellbeingQuestionID: string,
    answer: number,
    createdAt: string,
    schoolID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetStudentCheckInQueryVariables = {
  id: string,
};

export type GetStudentCheckInQuery = {
  getStudentCheckIn?:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListStudentCheckInsQueryVariables = {
  filter?: ModelStudentCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStudentCheckInsQuery = {
  listStudentCheckIns?:  {
    __typename: "ModelStudentCheckInConnection",
    items:  Array< {
      __typename: "StudentCheckIn",
      id: string,
      createdAt: string,
      updatedAt?: string | null,
      studentID: string,
      emotion?: Emotion | null,
      emotionIntensity?: number | null,
      emotionIntensityPercentage?: number | null,
      tiredness?: number | null,
      checkinsessionID: string,
      classID: string,
      absence?: boolean | null,
      notYet?: boolean | null,
      requestForChat?: RequestForChatStatus | null,
      chatCleared?: boolean | null,
      eating?: EatingStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncStudentCheckInsQueryVariables = {
  filter?: ModelStudentCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncStudentCheckInsQuery = {
  syncStudentCheckIns?:  {
    __typename: "ModelStudentCheckInConnection",
    items:  Array< {
      __typename: "StudentCheckIn",
      id: string,
      createdAt: string,
      updatedAt?: string | null,
      studentID: string,
      emotion?: Emotion | null,
      emotionIntensity?: number | null,
      emotionIntensityPercentage?: number | null,
      tiredness?: number | null,
      checkinsessionID: string,
      classID: string,
      absence?: boolean | null,
      notYet?: boolean | null,
      requestForChat?: RequestForChatStatus | null,
      chatCleared?: boolean | null,
      eating?: EatingStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListStudentCheckInsByStudentIdQueryVariables = {
  studentID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStudentCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStudentCheckInsByStudentIdQuery = {
  listStudentCheckInsByStudentId?:  {
    __typename: "ModelStudentCheckInConnection",
    items:  Array< {
      __typename: "StudentCheckIn",
      id: string,
      createdAt: string,
      updatedAt?: string | null,
      studentID: string,
      emotion?: Emotion | null,
      emotionIntensity?: number | null,
      emotionIntensityPercentage?: number | null,
      tiredness?: number | null,
      checkinsessionID: string,
      classID: string,
      absence?: boolean | null,
      notYet?: boolean | null,
      requestForChat?: RequestForChatStatus | null,
      chatCleared?: boolean | null,
      eating?: EatingStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListStudentCheckInsByCheckInSessionIdQueryVariables = {
  checkinsessionID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStudentCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStudentCheckInsByCheckInSessionIdQuery = {
  listStudentCheckInsByCheckInSessionId?:  {
    __typename: "ModelStudentCheckInConnection",
    items:  Array< {
      __typename: "StudentCheckIn",
      id: string,
      createdAt: string,
      updatedAt?: string | null,
      studentID: string,
      emotion?: Emotion | null,
      emotionIntensity?: number | null,
      emotionIntensityPercentage?: number | null,
      tiredness?: number | null,
      checkinsessionID: string,
      classID: string,
      absence?: boolean | null,
      notYet?: boolean | null,
      requestForChat?: RequestForChatStatus | null,
      chatCleared?: boolean | null,
      eating?: EatingStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListStudentCheckInsByClassIdQueryVariables = {
  classID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStudentCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStudentCheckInsByClassIdQuery = {
  listStudentCheckInsByClassId?:  {
    __typename: "ModelStudentCheckInConnection",
    items:  Array< {
      __typename: "StudentCheckIn",
      id: string,
      createdAt: string,
      updatedAt?: string | null,
      studentID: string,
      emotion?: Emotion | null,
      emotionIntensity?: number | null,
      emotionIntensityPercentage?: number | null,
      tiredness?: number | null,
      checkinsessionID: string,
      classID: string,
      absence?: boolean | null,
      notYet?: boolean | null,
      requestForChat?: RequestForChatStatus | null,
      chatCleared?: boolean | null,
      eating?: EatingStatus | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTeacherCheckInQueryVariables = {
  id: string,
};

export type GetTeacherCheckInQuery = {
  getTeacherCheckIn?:  {
    __typename: "TeacherCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTeacherCheckInsQueryVariables = {
  filter?: ModelTeacherCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeacherCheckInsQuery = {
  listTeacherCheckIns?:  {
    __typename: "ModelTeacherCheckInConnection",
    items:  Array< {
      __typename: "TeacherCheckIn",
      id: string,
      createdAt: string,
      updatedAt?: string | null,
      schoolID: string,
      teacherID: string,
      emotion?: Emotion | null,
      emotionIntensityPercentage?: number | null,
      tiredness?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTeacherCheckInsQueryVariables = {
  filter?: ModelTeacherCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTeacherCheckInsQuery = {
  syncTeacherCheckIns?:  {
    __typename: "ModelTeacherCheckInConnection",
    items:  Array< {
      __typename: "TeacherCheckIn",
      id: string,
      createdAt: string,
      updatedAt?: string | null,
      schoolID: string,
      teacherID: string,
      emotion?: Emotion | null,
      emotionIntensityPercentage?: number | null,
      tiredness?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeacherCheckInsBySchoolIDQueryVariables = {
  schoolID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeacherCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeacherCheckInsBySchoolIDQuery = {
  listTeacherCheckInsBySchoolID?:  {
    __typename: "ModelTeacherCheckInConnection",
    items:  Array< {
      __typename: "TeacherCheckIn",
      id: string,
      createdAt: string,
      updatedAt?: string | null,
      schoolID: string,
      teacherID: string,
      emotion?: Emotion | null,
      emotionIntensityPercentage?: number | null,
      tiredness?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeacherCheckInsByTeacherIDQueryVariables = {
  teacherID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeacherCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeacherCheckInsByTeacherIDQuery = {
  listTeacherCheckInsByTeacherID?:  {
    __typename: "ModelTeacherCheckInConnection",
    items:  Array< {
      __typename: "TeacherCheckIn",
      id: string,
      createdAt: string,
      updatedAt?: string | null,
      schoolID: string,
      teacherID: string,
      emotion?: Emotion | null,
      emotionIntensityPercentage?: number | null,
      tiredness?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetStaffCheckInQueryVariables = {
  id: string,
};

export type GetStaffCheckInQuery = {
  getStaffCheckIn?:  {
    __typename: "StaffCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    staffID: string,
    staff?:  {
      __typename: "Staff",
      id: string,
      cognitoUsername: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      nonTeachingStaffCheckInCount?: number | null,
      nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListStaffCheckInsQueryVariables = {
  filter?: ModelStaffCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStaffCheckInsQuery = {
  listStaffCheckIns?:  {
    __typename: "ModelStaffCheckInConnection",
    items:  Array< {
      __typename: "StaffCheckIn",
      id: string,
      createdAt: string,
      updatedAt?: string | null,
      schoolID: string,
      staffID: string,
      emotion?: Emotion | null,
      emotionIntensityPercentage?: number | null,
      tiredness?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncStaffCheckInsQueryVariables = {
  filter?: ModelStaffCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncStaffCheckInsQuery = {
  syncStaffCheckIns?:  {
    __typename: "ModelStaffCheckInConnection",
    items:  Array< {
      __typename: "StaffCheckIn",
      id: string,
      createdAt: string,
      updatedAt?: string | null,
      schoolID: string,
      staffID: string,
      emotion?: Emotion | null,
      emotionIntensityPercentage?: number | null,
      tiredness?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListStaffCheckInsBySchoolIDQueryVariables = {
  schoolID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStaffCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStaffCheckInsBySchoolIDQuery = {
  listStaffCheckInsBySchoolID?:  {
    __typename: "ModelStaffCheckInConnection",
    items:  Array< {
      __typename: "StaffCheckIn",
      id: string,
      createdAt: string,
      updatedAt?: string | null,
      schoolID: string,
      staffID: string,
      emotion?: Emotion | null,
      emotionIntensityPercentage?: number | null,
      tiredness?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListStaffCheckInsByStaffIDQueryVariables = {
  staffID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStaffCheckInFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStaffCheckInsByStaffIDQuery = {
  listStaffCheckInsByStaffID?:  {
    __typename: "ModelStaffCheckInConnection",
    items:  Array< {
      __typename: "StaffCheckIn",
      id: string,
      createdAt: string,
      updatedAt?: string | null,
      schoolID: string,
      staffID: string,
      emotion?: Emotion | null,
      emotionIntensityPercentage?: number | null,
      tiredness?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCheckInSessionQueryVariables = {
  id: string,
};

export type GetCheckInSessionQuery = {
  getCheckInSession?:  {
    __typename: "CheckInSession",
    id: string,
    checkInSessionClassSequenceNo?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    openAt?: string | null,
    closeAt?: string | null,
    isActive: boolean,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCheckInSessionsQueryVariables = {
  filter?: ModelCheckInSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCheckInSessionsQuery = {
  listCheckInSessions?:  {
    __typename: "ModelCheckInSessionConnection",
    items:  Array< {
      __typename: "CheckInSession",
      id: string,
      checkInSessionClassSequenceNo?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      classID: string,
      openAt?: string | null,
      closeAt?: string | null,
      isActive: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCheckInSessionsQueryVariables = {
  filter?: ModelCheckInSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCheckInSessionsQuery = {
  syncCheckInSessions?:  {
    __typename: "ModelCheckInSessionConnection",
    items:  Array< {
      __typename: "CheckInSession",
      id: string,
      checkInSessionClassSequenceNo?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      classID: string,
      openAt?: string | null,
      closeAt?: string | null,
      isActive: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListCheckInSessionsByClassIdQueryVariables = {
  classID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCheckInSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCheckInSessionsByClassIdQuery = {
  listCheckInSessionsByClassId?:  {
    __typename: "ModelCheckInSessionConnection",
    items:  Array< {
      __typename: "CheckInSession",
      id: string,
      checkInSessionClassSequenceNo?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      classID: string,
      openAt?: string | null,
      closeAt?: string | null,
      isActive: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSchoolQueryVariables = {
  id: string,
};

export type GetSchoolQuery = {
  getSchool?:  {
    __typename: "School",
    id: string,
    name: string,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    campuses?:  {
      __typename: "ModelCampusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teachers?:  {
      __typename: "ModelTeacherConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    students?:  {
      __typename: "ModelStudentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffs?:  {
      __typename: "ModelStaffConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdmins?:  {
      __typename: "ModelSchoolAdminConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdminUI?: SchoolAdminUI | null,
    utcOffset?: string | null,
    licenseTotal?: string | null,
    timezone?: string | null,
    status?: SchoolStatus | null,
    activeStudents?: number | null,
    trialEndDate?: string | null,
    renewalDate?: string | null,
    notes?: string | null,
    checkInCountAllTime?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    wholeSchoolSubscription?: boolean | null,
    billingAddress?: string | null,
    billingNotes?: string | null,
    invoiceUrl?: string | null,
    abn?: string | null,
    coordinatorID?: string | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teacherWellbeing?: boolean | null,
    staffWellbeing?: boolean | null,
    wellbeingFeature?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSchoolsQueryVariables = {
  filter?: ModelSchoolFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSchoolsQuery = {
  listSchools?:  {
    __typename: "ModelSchoolConnection",
    items:  Array< {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSchoolsQueryVariables = {
  filter?: ModelSchoolFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSchoolsQuery = {
  syncSchools?:  {
    __typename: "ModelSchoolConnection",
    items:  Array< {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCampusQueryVariables = {
  id: string,
};

export type GetCampusQuery = {
  getCampus?:  {
    __typename: "Campus",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCampusesQueryVariables = {
  filter?: ModelCampusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampusesQuery = {
  listCampuses?:  {
    __typename: "ModelCampusConnection",
    items:  Array< {
      __typename: "Campus",
      id: string,
      name: string,
      schoolID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCampusesQueryVariables = {
  filter?: ModelCampusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCampusesQuery = {
  syncCampuses?:  {
    __typename: "ModelCampusConnection",
    items:  Array< {
      __typename: "Campus",
      id: string,
      name: string,
      schoolID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListCampusesBySchoolIDQueryVariables = {
  schoolID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCampusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCampusesBySchoolIDQuery = {
  listCampusesBySchoolID?:  {
    __typename: "ModelCampusConnection",
    items:  Array< {
      __typename: "Campus",
      id: string,
      name: string,
      schoolID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetClassGroupQueryVariables = {
  id: string,
};

export type GetClassGroupQuery = {
  getClassGroup?:  {
    __typename: "ClassGroup",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    campusID: string,
    campus?:  {
      __typename: "Campus",
      id: string,
      name: string,
      schoolID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListClassGroupsQueryVariables = {
  filter?: ModelClassGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClassGroupsQuery = {
  listClassGroups?:  {
    __typename: "ModelClassGroupConnection",
    items:  Array< {
      __typename: "ClassGroup",
      id: string,
      name: string,
      schoolID: string,
      campusID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncClassGroupsQueryVariables = {
  filter?: ModelClassGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncClassGroupsQuery = {
  syncClassGroups?:  {
    __typename: "ModelClassGroupConnection",
    items:  Array< {
      __typename: "ClassGroup",
      id: string,
      name: string,
      schoolID: string,
      campusID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListClassGroupsBySchoolIDQueryVariables = {
  schoolID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClassGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClassGroupsBySchoolIDQuery = {
  listClassGroupsBySchoolID?:  {
    __typename: "ModelClassGroupConnection",
    items:  Array< {
      __typename: "ClassGroup",
      id: string,
      name: string,
      schoolID: string,
      campusID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListClassGroupsByCampusIDQueryVariables = {
  campusID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClassGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClassGroupsByCampusIDQuery = {
  listClassGroupsByCampusID?:  {
    __typename: "ModelClassGroupConnection",
    items:  Array< {
      __typename: "ClassGroup",
      id: string,
      name: string,
      schoolID: string,
      campusID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetClassQueryVariables = {
  id: string,
};

export type GetClassQuery = {
  getClass?:  {
    __typename: "Class",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroupID?: string | null,
    students?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classLogin?:  {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkInSessions?:  {
      __typename: "ModelCheckInSessionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    supports?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    emotionIntensityLevel?: number | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    toggleRequestForChat?: boolean | null,
    toggleRequestForChatNotifications?: boolean | null,
    toggleIndividualStudentStats?: boolean | null,
    teachers?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schedules?:  {
      __typename: "ModelScheduleClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bulkSignUpId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    primaryContact?: string | null,
    archived?: boolean | null,
    emailReceivers?: Array< string | null > | null,
    lastCheckInSessionClassSequenceNo?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    activeStudents?: number | null,
    goMode?: boolean | null,
    yearLevel?: string | null,
    lastCheckInTime?: string | null,
    wellbeing?: boolean | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestionID?: string | null,
    wellbeingResponseCount?: number | null,
    toggleEating?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    classClassLoginId?: string | null,
  } | null,
};

export type ListClassesQueryVariables = {
  filter?: ModelClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClassesQuery = {
  listClasses?:  {
    __typename: "ModelClassConnection",
    items:  Array< {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncClassesQueryVariables = {
  filter?: ModelClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncClassesQuery = {
  syncClasses?:  {
    __typename: "ModelClassConnection",
    items:  Array< {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetClassByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetClassByNameQuery = {
  getClassByName?:  {
    __typename: "ModelClassConnection",
    items:  Array< {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListClassesBySchoolIDQueryVariables = {
  schoolID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClassesBySchoolIDQuery = {
  listClassesBySchoolID?:  {
    __typename: "ModelClassConnection",
    items:  Array< {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListClassesByClassGroupIDQueryVariables = {
  classGroupID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClassesByClassGroupIDQuery = {
  listClassesByClassGroupID?:  {
    __typename: "ModelClassConnection",
    items:  Array< {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BulkSignUpIdQueryVariables = {
  bulkSignUpId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BulkSignUpIdQuery = {
  bulkSignUpId?:  {
    __typename: "ModelClassConnection",
    items:  Array< {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTeacherQueryVariables = {
  id: string,
};

export type GetTeacherQuery = {
  getTeacher?:  {
    __typename: "Teacher",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    classes?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoUsername: string,
    showIntroduction?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    role?: TeacherRole | null,
    onboarded?: boolean | null,
    devicePushTokens?: Array< string > | null,
    showNewFeatureInstructions?: boolean | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTeachersQueryVariables = {
  filter?: ModelTeacherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeachersQuery = {
  listTeachers?:  {
    __typename: "ModelTeacherConnection",
    items:  Array< {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTeachersQueryVariables = {
  filter?: ModelTeacherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTeachersQuery = {
  syncTeachers?:  {
    __typename: "ModelTeacherConnection",
    items:  Array< {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTeacherByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeacherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTeacherByEmailQuery = {
  getTeacherByEmail?:  {
    __typename: "ModelTeacherConnection",
    items:  Array< {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetListTeacherBySchoolIDQueryVariables = {
  schoolID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeacherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetListTeacherBySchoolIDQuery = {
  getListTeacherBySchoolID?:  {
    __typename: "ModelTeacherConnection",
    items:  Array< {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTeacherByCognitoUsernameQueryVariables = {
  cognitoUsername: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeacherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetTeacherByCognitoUsernameQuery = {
  getTeacherByCognitoUsername?:  {
    __typename: "ModelTeacherConnection",
    items:  Array< {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetStaffQueryVariables = {
  id: string,
};

export type GetStaffQuery = {
  getStaff?:  {
    __typename: "Staff",
    id: string,
    cognitoUsername: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    nonTeachingStaffCheckInCount?: number | null,
    nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListStaffQueryVariables = {
  filter?: ModelStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStaffQuery = {
  listStaff?:  {
    __typename: "ModelStaffConnection",
    items:  Array< {
      __typename: "Staff",
      id: string,
      cognitoUsername: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      nonTeachingStaffCheckInCount?: number | null,
      nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncStaffQueryVariables = {
  filter?: ModelStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncStaffQuery = {
  syncStaff?:  {
    __typename: "ModelStaffConnection",
    items:  Array< {
      __typename: "Staff",
      id: string,
      cognitoUsername: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      nonTeachingStaffCheckInCount?: number | null,
      nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetStaffByCognitoUsernameQueryVariables = {
  cognitoUsername: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetStaffByCognitoUsernameQuery = {
  getStaffByCognitoUsername?:  {
    __typename: "ModelStaffConnection",
    items:  Array< {
      __typename: "Staff",
      id: string,
      cognitoUsername: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      nonTeachingStaffCheckInCount?: number | null,
      nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetStaffByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetStaffByEmailQuery = {
  getStaffByEmail?:  {
    __typename: "ModelStaffConnection",
    items:  Array< {
      __typename: "Staff",
      id: string,
      cognitoUsername: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      nonTeachingStaffCheckInCount?: number | null,
      nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetListStaffBySchoolIDQueryVariables = {
  schoolID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetListStaffBySchoolIDQuery = {
  getListStaffBySchoolID?:  {
    __typename: "ModelStaffConnection",
    items:  Array< {
      __typename: "Staff",
      id: string,
      cognitoUsername: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      nonTeachingStaffCheckInCount?: number | null,
      nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSchoolAdminQueryVariables = {
  id: string,
};

export type GetSchoolAdminQuery = {
  getSchoolAdmin?:  {
    __typename: "SchoolAdmin",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    cognitoUsername: string,
    preferredUsername?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSchoolAdminsQueryVariables = {
  filter?: ModelSchoolAdminFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSchoolAdminsQuery = {
  listSchoolAdmins?:  {
    __typename: "ModelSchoolAdminConnection",
    items:  Array< {
      __typename: "SchoolAdmin",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      preferredUsername?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSchoolAdminsQueryVariables = {
  filter?: ModelSchoolAdminFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSchoolAdminsQuery = {
  syncSchoolAdmins?:  {
    __typename: "ModelSchoolAdminConnection",
    items:  Array< {
      __typename: "SchoolAdmin",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      preferredUsername?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSchoolAdminByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSchoolAdminFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSchoolAdminByEmailQuery = {
  getSchoolAdminByEmail?:  {
    __typename: "ModelSchoolAdminConnection",
    items:  Array< {
      __typename: "SchoolAdmin",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      preferredUsername?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListSchoolAdminBySchoolIDQueryVariables = {
  schoolID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSchoolAdminFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSchoolAdminBySchoolIDQuery = {
  listSchoolAdminBySchoolID?:  {
    __typename: "ModelSchoolAdminConnection",
    items:  Array< {
      __typename: "SchoolAdmin",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      preferredUsername?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSchoolAdminByCognitoUsernameQueryVariables = {
  cognitoUsername: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSchoolAdminFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSchoolAdminByCognitoUsernameQuery = {
  getSchoolAdminByCognitoUsername?:  {
    __typename: "ModelSchoolAdminConnection",
    items:  Array< {
      __typename: "SchoolAdmin",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      preferredUsername?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetStudentQueryVariables = {
  id: string,
};

export type GetStudentQuery = {
  getStudent?:  {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    yearLevel?: string | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    classes?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    activeClasses?: number | null,
    studentCheckInCount?: number | null,
    studentCheckInSkipSuggestionDate?: string | null,
    devicePushTokens?: Array< string > | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestion?: string | null,
    wellbeingResponseCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListStudentsQueryVariables = {
  filter?: ModelStudentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStudentsQuery = {
  listStudents?:  {
    __typename: "ModelStudentConnection",
    items:  Array< {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncStudentsQueryVariables = {
  filter?: ModelStudentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncStudentsQuery = {
  syncStudents?:  {
    __typename: "ModelStudentConnection",
    items:  Array< {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetStudentBySchoolStudentIDQueryVariables = {
  schoolStudentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStudentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetStudentBySchoolStudentIDQuery = {
  getStudentBySchoolStudentID?:  {
    __typename: "ModelStudentConnection",
    items:  Array< {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetStudentByNicknameQueryVariables = {
  nickname: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStudentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetStudentByNicknameQuery = {
  getStudentByNickname?:  {
    __typename: "ModelStudentConnection",
    items:  Array< {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListStudentsBySchoolIDQueryVariables = {
  schoolID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStudentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStudentsBySchoolIDQuery = {
  listStudentsBySchoolID?:  {
    __typename: "ModelStudentConnection",
    items:  Array< {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListStudentsByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStudentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStudentsByEmailQuery = {
  listStudentsByEmail?:  {
    __typename: "ModelStudentConnection",
    items:  Array< {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetStudentByCognitoUsernameQueryVariables = {
  cognitoUsername: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStudentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetStudentByCognitoUsernameQuery = {
  getStudentByCognitoUsername?:  {
    __typename: "ModelStudentConnection",
    items:  Array< {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSupportQueryVariables = {
  id: string,
};

export type GetSupportQuery = {
  getSupport?:  {
    __typename: "Support",
    id: string,
    email?: string | null,
    classes?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName: string,
    lastName: string,
    cognitoUsername: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSupportsQueryVariables = {
  filter?: ModelSupportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSupportsQuery = {
  listSupports?:  {
    __typename: "ModelSupportConnection",
    items:  Array< {
      __typename: "Support",
      id: string,
      email?: string | null,
      firstName: string,
      lastName: string,
      cognitoUsername: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSupportsQueryVariables = {
  filter?: ModelSupportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSupportsQuery = {
  syncSupports?:  {
    __typename: "ModelSupportConnection",
    items:  Array< {
      __typename: "Support",
      id: string,
      email?: string | null,
      firstName: string,
      lastName: string,
      cognitoUsername: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetClassSupportQueryVariables = {
  id: string,
};

export type GetClassSupportQuery = {
  getClassSupport?:  {
    __typename: "ClassSupport",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    supportID: string,
    support?:  {
      __typename: "Support",
      id: string,
      email?: string | null,
      firstName: string,
      lastName: string,
      cognitoUsername: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListClassSupportsQueryVariables = {
  filter?: ModelClassSupportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClassSupportsQuery = {
  listClassSupports?:  {
    __typename: "ModelClassSupportConnection",
    items:  Array< {
      __typename: "ClassSupport",
      id: string,
      classID: string,
      supportID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncClassSupportsQueryVariables = {
  filter?: ModelClassSupportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncClassSupportsQuery = {
  syncClassSupports?:  {
    __typename: "ModelClassSupportConnection",
    items:  Array< {
      __typename: "ClassSupport",
      id: string,
      classID: string,
      supportID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTeacherClassQueryVariables = {
  id: string,
};

export type GetTeacherClassQuery = {
  getTeacherClass?:  {
    __typename: "TeacherClass",
    id: string,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTeacherClassesQueryVariables = {
  filter?: ModelTeacherClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeacherClassesQuery = {
  listTeacherClasses?:  {
    __typename: "ModelTeacherClassConnection",
    items:  Array< {
      __typename: "TeacherClass",
      id: string,
      teacherID: string,
      classID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTeacherClassesQueryVariables = {
  filter?: ModelTeacherClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTeacherClassesQuery = {
  syncTeacherClasses?:  {
    __typename: "ModelTeacherClassConnection",
    items:  Array< {
      __typename: "TeacherClass",
      id: string,
      teacherID: string,
      classID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeacherClassesByTeacherIDQueryVariables = {
  teacherID: string,
  classID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeacherClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeacherClassesByTeacherIDQuery = {
  listTeacherClassesByTeacherID?:  {
    __typename: "ModelTeacherClassConnection",
    items:  Array< {
      __typename: "TeacherClass",
      id: string,
      teacherID: string,
      classID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeacherClassesByClassIDQueryVariables = {
  classID: string,
  teacherID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeacherClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeacherClassesByClassIDQuery = {
  listTeacherClassesByClassID?:  {
    __typename: "ModelTeacherClassConnection",
    items:  Array< {
      __typename: "TeacherClass",
      id: string,
      teacherID: string,
      classID: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetScheduleClassQueryVariables = {
  id: string,
};

export type GetScheduleClassQuery = {
  getScheduleClass?:  {
    __typename: "ScheduleClass",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    schedule: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListScheduleClassesQueryVariables = {
  filter?: ModelScheduleClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListScheduleClassesQuery = {
  listScheduleClasses?:  {
    __typename: "ModelScheduleClassConnection",
    items:  Array< {
      __typename: "ScheduleClass",
      id: string,
      classID: string,
      schedule: string,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncScheduleClassesQueryVariables = {
  filter?: ModelScheduleClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncScheduleClassesQuery = {
  syncScheduleClasses?:  {
    __typename: "ModelScheduleClassConnection",
    items:  Array< {
      __typename: "ScheduleClass",
      id: string,
      classID: string,
      schedule: string,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListScheduleClassByClassIdQueryVariables = {
  classID: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelScheduleClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListScheduleClassByClassIdQuery = {
  listScheduleClassByClassId?:  {
    __typename: "ModelScheduleClassConnection",
    items:  Array< {
      __typename: "ScheduleClass",
      id: string,
      classID: string,
      schedule: string,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetStudentClassQueryVariables = {
  id: string,
};

export type GetStudentClassQuery = {
  getStudentClass?:  {
    __typename: "StudentClass",
    id: string,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    status?: StudentClassApprovalStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    requestForChat?: boolean | null,
    requestForChatStatus?: RequestForChatStatus | null,
    studentCheckIns?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListStudentClassesQueryVariables = {
  filter?: ModelStudentClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStudentClassesQuery = {
  listStudentClasses?:  {
    __typename: "ModelStudentClassConnection",
    items:  Array< {
      __typename: "StudentClass",
      id: string,
      studentID: string,
      classID: string,
      status?: StudentClassApprovalStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      requestForChat?: boolean | null,
      requestForChatStatus?: RequestForChatStatus | null,
      studentCheckIns?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncStudentClassesQueryVariables = {
  filter?: ModelStudentClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncStudentClassesQuery = {
  syncStudentClasses?:  {
    __typename: "ModelStudentClassConnection",
    items:  Array< {
      __typename: "StudentClass",
      id: string,
      studentID: string,
      classID: string,
      status?: StudentClassApprovalStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      requestForChat?: boolean | null,
      requestForChatStatus?: RequestForChatStatus | null,
      studentCheckIns?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetStudentClassByStudentIdQueryVariables = {
  studentID: string,
  classID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStudentClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetStudentClassByStudentIdQuery = {
  getStudentClassByStudentId?:  {
    __typename: "ModelStudentClassConnection",
    items:  Array< {
      __typename: "StudentClass",
      id: string,
      studentID: string,
      classID: string,
      status?: StudentClassApprovalStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      requestForChat?: boolean | null,
      requestForChatStatus?: RequestForChatStatus | null,
      studentCheckIns?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetStudentClassByClassIdQueryVariables = {
  classID: string,
  studentID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStudentClassFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetStudentClassByClassIdQuery = {
  getStudentClassByClassId?:  {
    __typename: "ModelStudentClassConnection",
    items:  Array< {
      __typename: "StudentClass",
      id: string,
      studentID: string,
      classID: string,
      status?: StudentClassApprovalStatus | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      requestForChat?: boolean | null,
      requestForChatStatus?: RequestForChatStatus | null,
      studentCheckIns?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSchoolContactQueryVariables = {
  id: string,
};

export type GetSchoolContactQuery = {
  getSchoolContact?:  {
    __typename: "SchoolContact",
    id: string,
    schoolID: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    type?: SchoolContactType | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSchoolContactsQueryVariables = {
  filter?: ModelSchoolContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSchoolContactsQuery = {
  listSchoolContacts?:  {
    __typename: "ModelSchoolContactConnection",
    items:  Array< {
      __typename: "SchoolContact",
      id: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      type?: SchoolContactType | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSchoolContactsQueryVariables = {
  filter?: ModelSchoolContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSchoolContactsQuery = {
  syncSchoolContacts?:  {
    __typename: "ModelSchoolContactConnection",
    items:  Array< {
      __typename: "SchoolContact",
      id: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      type?: SchoolContactType | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSchoolContactBySchoolIDQueryVariables = {
  schoolID: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSchoolContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSchoolContactBySchoolIDQuery = {
  getSchoolContactBySchoolID?:  {
    __typename: "ModelSchoolContactConnection",
    items:  Array< {
      __typename: "SchoolContact",
      id: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      type?: SchoolContactType | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMinFEVersionQueryVariables = {
  id: string,
};

export type GetMinFEVersionQuery = {
  getMinFEVersion?:  {
    __typename: "MinFEVersion",
    version: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMinFEVersionsQueryVariables = {
  filter?: ModelMinFEVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMinFEVersionsQuery = {
  listMinFEVersions?:  {
    __typename: "ModelMinFEVersionConnection",
    items:  Array< {
      __typename: "MinFEVersion",
      version: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMinFEVersionsQueryVariables = {
  filter?: ModelMinFEVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMinFEVersionsQuery = {
  syncMinFEVersions?:  {
    __typename: "ModelMinFEVersionConnection",
    items:  Array< {
      __typename: "MinFEVersion",
      version: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSwitchRatingQueryVariables = {
  id: string,
};

export type GetSwitchRatingQuery = {
  getSwitchRating?:  {
    __typename: "SwitchRating",
    id: string,
    userId: string,
    cardId: string,
    userType: UserType,
    rating: SwitchRatingType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSwitchRatingsQueryVariables = {
  filter?: ModelSwitchRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSwitchRatingsQuery = {
  listSwitchRatings?:  {
    __typename: "ModelSwitchRatingConnection",
    items:  Array< {
      __typename: "SwitchRating",
      id: string,
      userId: string,
      cardId: string,
      userType: UserType,
      rating: SwitchRatingType,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSwitchRatingsQueryVariables = {
  filter?: ModelSwitchRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSwitchRatingsQuery = {
  syncSwitchRatings?:  {
    __typename: "ModelSwitchRatingConnection",
    items:  Array< {
      __typename: "SwitchRating",
      id: string,
      userId: string,
      cardId: string,
      userType: UserType,
      rating: SwitchRatingType,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSwitchRatingByUserIdQueryVariables = {
  userId: string,
  userTypeCardId?: ModelSwitchRatingByUserIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSwitchRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSwitchRatingByUserIdQuery = {
  getSwitchRatingByUserId?:  {
    __typename: "ModelSwitchRatingConnection",
    items:  Array< {
      __typename: "SwitchRating",
      id: string,
      userId: string,
      cardId: string,
      userType: UserType,
      rating: SwitchRatingType,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetClassLoginQueryVariables = {
  id: string,
};

export type GetClassLoginQuery = {
  getClassLogin?:  {
    __typename: "ClassLogin",
    id: string,
    nickname: string,
    cognitoUsername: string,
    classID: string,
    classCode: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    devicePushTokens?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListClassLoginsQueryVariables = {
  filter?: ModelClassLoginFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClassLoginsQuery = {
  listClassLogins?:  {
    __typename: "ModelClassLoginConnection",
    items:  Array< {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncClassLoginsQueryVariables = {
  filter?: ModelClassLoginFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncClassLoginsQuery = {
  syncClassLogins?:  {
    __typename: "ModelClassLoginConnection",
    items:  Array< {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListClassLoginByCognitoUsernameQueryVariables = {
  cognitoUsername: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClassLoginFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClassLoginByCognitoUsernameQuery = {
  listClassLoginByCognitoUsername?:  {
    __typename: "ModelClassLoginConnection",
    items:  Array< {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListClassLoginByClassIDQueryVariables = {
  classID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClassLoginFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClassLoginByClassIDQuery = {
  listClassLoginByClassID?:  {
    __typename: "ModelClassLoginConnection",
    items:  Array< {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFavouriteQueryVariables = {
  id: string,
};

export type GetFavouriteQuery = {
  getFavourite?:  {
    __typename: "Favourite",
    id: string,
    cognitoUsername: string,
    cardId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFavouritesQueryVariables = {
  filter?: ModelFavouriteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFavouritesQuery = {
  listFavourites?:  {
    __typename: "ModelFavouriteConnection",
    items:  Array< {
      __typename: "Favourite",
      id: string,
      cognitoUsername: string,
      cardId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFavouritesQueryVariables = {
  filter?: ModelFavouriteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFavouritesQuery = {
  syncFavourites?:  {
    __typename: "ModelFavouriteConnection",
    items:  Array< {
      __typename: "Favourite",
      id: string,
      cognitoUsername: string,
      cardId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListFavouriteByCognitoUsernameQueryVariables = {
  cognitoUsername: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFavouriteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFavouriteByCognitoUsernameQuery = {
  listFavouriteByCognitoUsername?:  {
    __typename: "ModelFavouriteConnection",
    items:  Array< {
      __typename: "Favourite",
      id: string,
      cognitoUsername: string,
      cardId: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFindYourCalmActivityRatingQueryVariables = {
  id: string,
};

export type GetFindYourCalmActivityRatingQuery = {
  getFindYourCalmActivityRating?:  {
    __typename: "FindYourCalmActivityRating",
    userId: string,
    activityId: string,
    rating?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFindYourCalmActivityRatingsQueryVariables = {
  filter?: ModelFindYourCalmActivityRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFindYourCalmActivityRatingsQuery = {
  listFindYourCalmActivityRatings?:  {
    __typename: "ModelFindYourCalmActivityRatingConnection",
    items:  Array< {
      __typename: "FindYourCalmActivityRating",
      userId: string,
      activityId: string,
      rating?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFindYourCalmActivityRatingsQueryVariables = {
  filter?: ModelFindYourCalmActivityRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFindYourCalmActivityRatingsQuery = {
  syncFindYourCalmActivityRatings?:  {
    __typename: "ModelFindYourCalmActivityRatingConnection",
    items:  Array< {
      __typename: "FindYourCalmActivityRating",
      userId: string,
      activityId: string,
      rating?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListFindYourCalmByUserIdAndActivityIdQueryVariables = {
  userId: string,
  activityId?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFindYourCalmActivityRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFindYourCalmByUserIdAndActivityIdQuery = {
  listFindYourCalmByUserIdAndActivityId?:  {
    __typename: "ModelFindYourCalmActivityRatingConnection",
    items:  Array< {
      __typename: "FindYourCalmActivityRating",
      userId: string,
      activityId: string,
      rating?: number | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInvoiceQueryVariables = {
  id: string,
};

export type GetInvoiceQuery = {
  getInvoice?:  {
    __typename: "Invoice",
    id: string,
    schoolID: string,
    url: string,
    name?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInvoicesQueryVariables = {
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvoicesQuery = {
  listInvoices?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      schoolID: string,
      url: string,
      name?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInvoicesQueryVariables = {
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInvoicesQuery = {
  syncInvoices?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      schoolID: string,
      url: string,
      name?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListInvoiceBySchoolIDQueryVariables = {
  schoolID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvoiceBySchoolIDQuery = {
  listInvoiceBySchoolID?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      schoolID: string,
      url: string,
      name?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVideosQueryVariables = {
  id: string,
};

export type GetVideosQuery = {
  getVideos?:  {
    __typename: "Videos",
    id: string,
    url: string,
    image?: string | null,
    title?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    time?: Array< string | null > | null,
    level?: Array< string | null > | null,
    emotion?: Array< string | null > | null,
    ageGroup?: Array< string | null > | null,
    isExternal?: boolean | null,
    autoplay?: boolean | null,
    youtubePlaylistThumbnailUrlVideoId?: string | null,
    thumbnailUrl?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListVideosQueryVariables = {
  filter?: ModelVideosFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVideosQuery = {
  listVideos?:  {
    __typename: "ModelVideosConnection",
    items:  Array< {
      __typename: "Videos",
      id: string,
      url: string,
      image?: string | null,
      title?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      time?: Array< string | null > | null,
      level?: Array< string | null > | null,
      emotion?: Array< string | null > | null,
      ageGroup?: Array< string | null > | null,
      isExternal?: boolean | null,
      autoplay?: boolean | null,
      youtubePlaylistThumbnailUrlVideoId?: string | null,
      thumbnailUrl?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVideosQueryVariables = {
  filter?: ModelVideosFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVideosQuery = {
  syncVideos?:  {
    __typename: "ModelVideosConnection",
    items:  Array< {
      __typename: "Videos",
      id: string,
      url: string,
      image?: string | null,
      title?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      time?: Array< string | null > | null,
      level?: Array< string | null > | null,
      emotion?: Array< string | null > | null,
      ageGroup?: Array< string | null > | null,
      isExternal?: boolean | null,
      autoplay?: boolean | null,
      youtubePlaylistThumbnailUrlVideoId?: string | null,
      thumbnailUrl?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVideoByVideoUrlQueryVariables = {
  url: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVideosFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetVideoByVideoUrlQuery = {
  getVideoByVideoUrl?:  {
    __typename: "ModelVideosConnection",
    items:  Array< {
      __typename: "Videos",
      id: string,
      url: string,
      image?: string | null,
      title?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      time?: Array< string | null > | null,
      level?: Array< string | null > | null,
      emotion?: Array< string | null > | null,
      ageGroup?: Array< string | null > | null,
      isExternal?: boolean | null,
      autoplay?: boolean | null,
      youtubePlaylistThumbnailUrlVideoId?: string | null,
      thumbnailUrl?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetWellbeingQuestionQueryVariables = {
  id: string,
};

export type GetWellbeingQuestionQuery = {
  getWellbeingQuestion?:  {
    __typename: "WellbeingQuestion",
    id: string,
    category: QuestionCategory,
    type: QuestionType,
    text: string,
    options:  Array< {
      __typename: "QuestionOption",
      text: string,
      value: number,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListWellbeingQuestionsQueryVariables = {
  filter?: ModelWellbeingQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWellbeingQuestionsQuery = {
  listWellbeingQuestions?:  {
    __typename: "ModelWellbeingQuestionConnection",
    items:  Array< {
      __typename: "WellbeingQuestion",
      id: string,
      category: QuestionCategory,
      type: QuestionType,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncWellbeingQuestionsQueryVariables = {
  filter?: ModelWellbeingQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncWellbeingQuestionsQuery = {
  syncWellbeingQuestions?:  {
    __typename: "ModelWellbeingQuestionConnection",
    items:  Array< {
      __typename: "WellbeingQuestion",
      id: string,
      category: QuestionCategory,
      type: QuestionType,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetWellbeingResponseQueryVariables = {
  id: string,
};

export type GetWellbeingResponseQuery = {
  getWellbeingResponse?:  {
    __typename: "WellbeingResponse",
    id: string,
    userID: string,
    userType: WellbeingResponseUserType,
    wellbeingQuestionID: string,
    answer: number,
    createdAt: string,
    schoolID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListWellbeingResponsesQueryVariables = {
  filter?: ModelWellbeingResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWellbeingResponsesQuery = {
  listWellbeingResponses?:  {
    __typename: "ModelWellbeingResponseConnection",
    items:  Array< {
      __typename: "WellbeingResponse",
      id: string,
      userID: string,
      userType: WellbeingResponseUserType,
      wellbeingQuestionID: string,
      answer: number,
      createdAt: string,
      schoolID: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncWellbeingResponsesQueryVariables = {
  filter?: ModelWellbeingResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncWellbeingResponsesQuery = {
  syncWellbeingResponses?:  {
    __typename: "ModelWellbeingResponseConnection",
    items:  Array< {
      __typename: "WellbeingResponse",
      id: string,
      userID: string,
      userType: WellbeingResponseUserType,
      wellbeingQuestionID: string,
      answer: number,
      createdAt: string,
      schoolID: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListWellbeingResponseByUserIDQueryVariables = {
  userID: string,
  wellbeingQuestionID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWellbeingResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWellbeingResponseByUserIDQuery = {
  listWellbeingResponseByUserID?:  {
    __typename: "ModelWellbeingResponseConnection",
    items:  Array< {
      __typename: "WellbeingResponse",
      id: string,
      userID: string,
      userType: WellbeingResponseUserType,
      wellbeingQuestionID: string,
      answer: number,
      createdAt: string,
      schoolID: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListWellbeingResponseByWellbeingQuestionIDQueryVariables = {
  wellbeingQuestionID: string,
  userID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWellbeingResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWellbeingResponseByWellbeingQuestionIDQuery = {
  listWellbeingResponseByWellbeingQuestionID?:  {
    __typename: "ModelWellbeingResponseConnection",
    items:  Array< {
      __typename: "WellbeingResponse",
      id: string,
      userID: string,
      userType: WellbeingResponseUserType,
      wellbeingQuestionID: string,
      answer: number,
      createdAt: string,
      schoolID: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListWellbeingResponseBySchoolIDQueryVariables = {
  schoolID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWellbeingResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWellbeingResponseBySchoolIDQuery = {
  listWellbeingResponseBySchoolID?:  {
    __typename: "ModelWellbeingResponseConnection",
    items:  Array< {
      __typename: "WellbeingResponse",
      id: string,
      userID: string,
      userType: WellbeingResponseUserType,
      wellbeingQuestionID: string,
      answer: number,
      createdAt: string,
      schoolID: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListCheckInSessionsForTwoWeeksForSchoolQueryVariables = {
  schoolName: string,
  endDate?: string | null,
};

export type ListCheckInSessionsForTwoWeeksForSchoolQuery = {
  listCheckInSessionsForTwoWeeksForSchool:  Array< {
    __typename: "CheckInSession",
    id: string,
    checkInSessionClassSequenceNo?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    openAt?: string | null,
    closeAt?: string | null,
    isActive: boolean,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null >,
};

export type ListStudentCheckInsForTwoWeeksForSchoolQueryVariables = {
  schoolName: string,
  endDate?: string | null,
};

export type ListStudentCheckInsForTwoWeeksForSchoolQuery = {
  listStudentCheckInsForTwoWeeksForSchool:  Array< {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null >,
};

export type ListStudentCheckInsForDateRangeForSchoolQueryVariables = {
  schoolName: string,
  startDate: string,
  endDate: string,
};

export type ListStudentCheckInsForDateRangeForSchoolQuery = {
  listStudentCheckInsForDateRangeForSchool:  Array< {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null >,
};

export type ListStudentClassesForBCCQueryVariables = {
};

export type ListStudentClassesForBCCQuery = {
  listStudentClassesForBCC:  Array< {
    __typename: "StudentClass",
    id: string,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    status?: StudentClassApprovalStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    requestForChat?: boolean | null,
    requestForChatStatus?: RequestForChatStatus | null,
    studentCheckIns?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null >,
};

export type ListCheckInSessionsForSchoolQueryVariables = {
  schoolName: string,
};

export type ListCheckInSessionsForSchoolQuery = {
  listCheckInSessionsForSchool:  Array< {
    __typename: "CheckInSession",
    id: string,
    checkInSessionClassSequenceNo?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    openAt?: string | null,
    closeAt?: string | null,
    isActive: boolean,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null >,
};

export type ListStudentCheckInsForSchoolQueryVariables = {
  schoolName: string,
};

export type ListStudentCheckInsForSchoolQuery = {
  listStudentCheckInsForSchool:  Array< {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null >,
};

export type ListStudentClassesForSchoolQueryVariables = {
  schoolName: string,
};

export type ListStudentClassesForSchoolQuery = {
  listStudentClassesForSchool:  Array< {
    __typename: "StudentClass",
    id: string,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    status?: StudentClassApprovalStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    requestForChat?: boolean | null,
    requestForChatStatus?: RequestForChatStatus | null,
    studentCheckIns?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null >,
};

export type ListStudentsByKeywordQueryVariables = {
  keyword: string,
  limit?: number | null,
};

export type ListStudentsByKeywordQuery = {
  listStudentsByKeyword:  Array< {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    yearLevel?: string | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    classes?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    activeClasses?: number | null,
    studentCheckInCount?: number | null,
    studentCheckInSkipSuggestionDate?: string | null,
    devicePushTokens?: Array< string > | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestion?: string | null,
    wellbeingResponseCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null >,
};

export type ListAllStudentsBySchoolIDQueryVariables = {
  schoolID: string,
};

export type ListAllStudentsBySchoolIDQuery = {
  listAllStudentsBySchoolID:  Array< {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    yearLevel?: string | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    classes?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    activeClasses?: number | null,
    studentCheckInCount?: number | null,
    studentCheckInSkipSuggestionDate?: string | null,
    devicePushTokens?: Array< string > | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestion?: string | null,
    wellbeingResponseCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null >,
};

export type SearchStudentByEmailQueryVariables = {
  input: SearchStudentByEmailInput,
};

export type SearchStudentByEmailQuery = {
  searchStudentByEmail:  Array< {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    yearLevel?: string | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    classes?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    activeClasses?: number | null,
    studentCheckInCount?: number | null,
    studentCheckInSkipSuggestionDate?: string | null,
    devicePushTokens?: Array< string > | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestion?: string | null,
    wellbeingResponseCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null >,
};

export type GetLastCheckInSessionByClassIDQueryVariables = {
  classID: string,
};

export type GetLastCheckInSessionByClassIDQuery = {
  getLastCheckInSessionByClassID?:  {
    __typename: "CheckInSession",
    id: string,
    checkInSessionClassSequenceNo?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    openAt?: string | null,
    closeAt?: string | null,
    isActive: boolean,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type HasStudentCheckedInTodayForClassQueryVariables = {
  classID: string,
  studentID: string,
};

export type HasStudentCheckedInTodayForClassQuery = {
  hasStudentCheckedInTodayForClass?: boolean | null,
};

export type GetRandomWellbeingQuestionQueryVariables = {
  classID: string,
  type: WellbeingResponseUserType,
  userID: string,
};

export type GetRandomWellbeingQuestionQuery = {
  getRandomWellbeingQuestion?:  {
    __typename: "WellbeingQuestion",
    id: string,
    category: QuestionCategory,
    type: QuestionType,
    text: string,
    options:  Array< {
      __typename: "QuestionOption",
      text: string,
      value: number,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateCampusBySchoolIDSubscriptionVariables = {
  schoolID: string,
};

export type OnMutateCampusBySchoolIDSubscription = {
  onMutateCampusBySchoolID?:  {
    __typename: "Campus",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateCheckInSessionByClassIdSubscriptionVariables = {
  classID: string,
};

export type OnMutateCheckInSessionByClassIdSubscription = {
  onMutateCheckInSessionByClassId?:  {
    __typename: "CheckInSession",
    id: string,
    checkInSessionClassSequenceNo?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    openAt?: string | null,
    closeAt?: string | null,
    isActive: boolean,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateClassByIdSubscriptionVariables = {
  id: string,
};

export type OnMutateClassByIdSubscription = {
  onMutateClassById?:  {
    __typename: "Class",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroupID?: string | null,
    students?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classLogin?:  {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkInSessions?:  {
      __typename: "ModelCheckInSessionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    supports?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    emotionIntensityLevel?: number | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    toggleRequestForChat?: boolean | null,
    toggleRequestForChatNotifications?: boolean | null,
    toggleIndividualStudentStats?: boolean | null,
    teachers?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schedules?:  {
      __typename: "ModelScheduleClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bulkSignUpId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    primaryContact?: string | null,
    archived?: boolean | null,
    emailReceivers?: Array< string | null > | null,
    lastCheckInSessionClassSequenceNo?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    activeStudents?: number | null,
    goMode?: boolean | null,
    yearLevel?: string | null,
    lastCheckInTime?: string | null,
    wellbeing?: boolean | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestionID?: string | null,
    wellbeingResponseCount?: number | null,
    toggleEating?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    classClassLoginId?: string | null,
  } | null,
};

export type OnMutateClassSubscriptionsSubscriptionVariables = {
  schoolID: string,
};

export type OnMutateClassSubscriptionsSubscription = {
  onMutateClassSubscriptions?:  {
    __typename: "Class",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroupID?: string | null,
    students?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classLogin?:  {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkInSessions?:  {
      __typename: "ModelCheckInSessionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    supports?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    emotionIntensityLevel?: number | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    toggleRequestForChat?: boolean | null,
    toggleRequestForChatNotifications?: boolean | null,
    toggleIndividualStudentStats?: boolean | null,
    teachers?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schedules?:  {
      __typename: "ModelScheduleClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bulkSignUpId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    primaryContact?: string | null,
    archived?: boolean | null,
    emailReceivers?: Array< string | null > | null,
    lastCheckInSessionClassSequenceNo?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    activeStudents?: number | null,
    goMode?: boolean | null,
    yearLevel?: string | null,
    lastCheckInTime?: string | null,
    wellbeing?: boolean | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestionID?: string | null,
    wellbeingResponseCount?: number | null,
    toggleEating?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    classClassLoginId?: string | null,
  } | null,
};

export type OnMutateClassByClassGroupIDSubscriptionVariables = {
  classGroupID: string,
};

export type OnMutateClassByClassGroupIDSubscription = {
  onMutateClassByClassGroupID?:  {
    __typename: "Class",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroupID?: string | null,
    students?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classLogin?:  {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkInSessions?:  {
      __typename: "ModelCheckInSessionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    supports?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    emotionIntensityLevel?: number | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    toggleRequestForChat?: boolean | null,
    toggleRequestForChatNotifications?: boolean | null,
    toggleIndividualStudentStats?: boolean | null,
    teachers?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schedules?:  {
      __typename: "ModelScheduleClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bulkSignUpId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    primaryContact?: string | null,
    archived?: boolean | null,
    emailReceivers?: Array< string | null > | null,
    lastCheckInSessionClassSequenceNo?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    activeStudents?: number | null,
    goMode?: boolean | null,
    yearLevel?: string | null,
    lastCheckInTime?: string | null,
    wellbeing?: boolean | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestionID?: string | null,
    wellbeingResponseCount?: number | null,
    toggleEating?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    classClassLoginId?: string | null,
  } | null,
};

export type OnMutateClassGroupsBySchoolIDSubscriptionVariables = {
  schoolID: string,
};

export type OnMutateClassGroupsBySchoolIDSubscription = {
  onMutateClassGroupsBySchoolID?:  {
    __typename: "ClassGroup",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    campusID: string,
    campus?:  {
      __typename: "Campus",
      id: string,
      name: string,
      schoolID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateClassLoginByClassIDSubscriptionVariables = {
  classID: string,
};

export type OnMutateClassLoginByClassIDSubscription = {
  onMutateClassLoginByClassID?:  {
    __typename: "ClassLogin",
    id: string,
    nickname: string,
    cognitoUsername: string,
    classID: string,
    classCode: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    devicePushTokens?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateClassLoginByCognitoUsernameSubscriptionVariables = {
  cognitoUsername: string,
};

export type OnMutateClassLoginByCognitoUsernameSubscription = {
  onMutateClassLoginByCognitoUsername?:  {
    __typename: "ClassLogin",
    id: string,
    nickname: string,
    cognitoUsername: string,
    classID: string,
    classCode: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    devicePushTokens?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateFavouriteByCognitoUsernameSubscriptionVariables = {
  cognitoUsername: string,
};

export type OnMutateFavouriteByCognitoUsernameSubscription = {
  onMutateFavouriteByCognitoUsername?:  {
    __typename: "Favourite",
    id: string,
    cognitoUsername: string,
    cardId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateFindYourCalmActivityRatingSubscriptionVariables = {
  userId: string,
};

export type OnMutateFindYourCalmActivityRatingSubscription = {
  onMutateFindYourCalmActivityRating?:  {
    __typename: "FindYourCalmActivityRating",
    userId: string,
    activityId: string,
    rating?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateInvoiceBySchoolIDSubscriptionVariables = {
  schoolID: string,
};

export type OnMutateInvoiceBySchoolIDSubscription = {
  onMutateInvoiceBySchoolID?:  {
    __typename: "Invoice",
    id: string,
    schoolID: string,
    url: string,
    name?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateScheduleClassByClassIDSubscriptionVariables = {
  classID: string,
};

export type OnMutateScheduleClassByClassIDSubscription = {
  onMutateScheduleClassByClassID?:  {
    __typename: "ScheduleClass",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    schedule: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateSchoolSubscriptionsBySchoolIdSubscriptionVariables = {
  id: string,
};

export type OnMutateSchoolSubscriptionsBySchoolIdSubscription = {
  onMutateSchoolSubscriptionsBySchoolId?:  {
    __typename: "School",
    id: string,
    name: string,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    campuses?:  {
      __typename: "ModelCampusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teachers?:  {
      __typename: "ModelTeacherConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    students?:  {
      __typename: "ModelStudentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffs?:  {
      __typename: "ModelStaffConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdmins?:  {
      __typename: "ModelSchoolAdminConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdminUI?: SchoolAdminUI | null,
    utcOffset?: string | null,
    licenseTotal?: string | null,
    timezone?: string | null,
    status?: SchoolStatus | null,
    activeStudents?: number | null,
    trialEndDate?: string | null,
    renewalDate?: string | null,
    notes?: string | null,
    checkInCountAllTime?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    wholeSchoolSubscription?: boolean | null,
    billingAddress?: string | null,
    billingNotes?: string | null,
    invoiceUrl?: string | null,
    abn?: string | null,
    coordinatorID?: string | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teacherWellbeing?: boolean | null,
    staffWellbeing?: boolean | null,
    wellbeingFeature?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateSchoolAdminSubscriptionsBySchoolIdSubscriptionVariables = {
  schoolID: string,
};

export type OnMutateSchoolAdminSubscriptionsBySchoolIdSubscription = {
  onMutateSchoolAdminSubscriptionsBySchoolId?:  {
    __typename: "SchoolAdmin",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    cognitoUsername: string,
    preferredUsername?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateStaffCheckInByStaffIDSubscriptionVariables = {
  staffID: string,
};

export type OnMutateStaffCheckInByStaffIDSubscription = {
  onMutateStaffCheckInByStaffID?:  {
    __typename: "StaffCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    staffID: string,
    staff?:  {
      __typename: "Staff",
      id: string,
      cognitoUsername: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      nonTeachingStaffCheckInCount?: number | null,
      nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateStaffBySchoolIDSubscriptionVariables = {
  schoolID: string,
};

export type OnMutateStaffBySchoolIDSubscription = {
  onMutateStaffBySchoolID?:  {
    __typename: "Staff",
    id: string,
    cognitoUsername: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    nonTeachingStaffCheckInCount?: number | null,
    nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateStudentByCognitoUsernameSubscriptionVariables = {
  cognitoUsername: string,
};

export type OnMutateStudentByCognitoUsernameSubscription = {
  onMutateStudentByCognitoUsername?:  {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    yearLevel?: string | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    classes?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    activeClasses?: number | null,
    studentCheckInCount?: number | null,
    studentCheckInSkipSuggestionDate?: string | null,
    devicePushTokens?: Array< string > | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestion?: string | null,
    wellbeingResponseCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateStudentByStudentIdSubscriptionVariables = {
  id: string,
};

export type OnMutateStudentByStudentIdSubscription = {
  onMutateStudentByStudentId?:  {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    yearLevel?: string | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    classes?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    activeClasses?: number | null,
    studentCheckInCount?: number | null,
    studentCheckInSkipSuggestionDate?: string | null,
    devicePushTokens?: Array< string > | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestion?: string | null,
    wellbeingResponseCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateStudentBySchoolIdSubscriptionVariables = {
  schoolID: string,
};

export type OnMutateStudentBySchoolIdSubscription = {
  onMutateStudentBySchoolId?:  {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    yearLevel?: string | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    classes?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    activeClasses?: number | null,
    studentCheckInCount?: number | null,
    studentCheckInSkipSuggestionDate?: string | null,
    devicePushTokens?: Array< string > | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestion?: string | null,
    wellbeingResponseCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateStudentCheckInByClassIdSubscriptionVariables = {
  classID: string,
};

export type OnMutateStudentCheckInByClassIdSubscription = {
  onMutateStudentCheckInByClassId?:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateStudentCheckInByStudentIdSubscriptionVariables = {
  studentID: string,
};

export type OnMutateStudentCheckInByStudentIdSubscription = {
  onMutateStudentCheckInByStudentId?:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateStudentCheckInByCheckInSessionIdSubscriptionVariables = {
  checkinsessionID: string,
};

export type OnMutateStudentCheckInByCheckInSessionIdSubscription = {
  onMutateStudentCheckInByCheckInSessionId?:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateStudentCheckInByClassIdAndStudentIdSubscriptionVariables = {
  classID: string,
  studentID: string,
};

export type OnMutateStudentCheckInByClassIdAndStudentIdSubscription = {
  onMutateStudentCheckInByClassIdAndStudentId?:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateStudentClassSubscriptionVariables = {
};

export type OnMutateStudentClassSubscription = {
  onMutateStudentClass?:  {
    __typename: "StudentClass",
    id: string,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    status?: StudentClassApprovalStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    requestForChat?: boolean | null,
    requestForChatStatus?: RequestForChatStatus | null,
    studentCheckIns?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateStudentClassByClassIdSubscriptionVariables = {
  classID: string,
};

export type OnMutateStudentClassByClassIdSubscription = {
  onMutateStudentClassByClassId?:  {
    __typename: "StudentClass",
    id: string,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    status?: StudentClassApprovalStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    requestForChat?: boolean | null,
    requestForChatStatus?: RequestForChatStatus | null,
    studentCheckIns?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateStudentClassByStudentIdSubscriptionVariables = {
  studentID: string,
};

export type OnMutateStudentClassByStudentIdSubscription = {
  onMutateStudentClassByStudentId?:  {
    __typename: "StudentClass",
    id: string,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    status?: StudentClassApprovalStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    requestForChat?: boolean | null,
    requestForChatStatus?: RequestForChatStatus | null,
    studentCheckIns?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateSwitchRatingByUserIdSubscriptionVariables = {
  userId: string,
};

export type OnMutateSwitchRatingByUserIdSubscription = {
  onMutateSwitchRatingByUserId?:  {
    __typename: "SwitchRating",
    id: string,
    userId: string,
    cardId: string,
    userType: UserType,
    rating: SwitchRatingType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateTeacherByCognitoUsernameSubscriptionVariables = {
  cognitoUsername: string,
};

export type OnMutateTeacherByCognitoUsernameSubscription = {
  onMutateTeacherByCognitoUsername?:  {
    __typename: "Teacher",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    classes?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoUsername: string,
    showIntroduction?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    role?: TeacherRole | null,
    onboarded?: boolean | null,
    devicePushTokens?: Array< string > | null,
    showNewFeatureInstructions?: boolean | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateTeacherBySchoolIDSubscriptionVariables = {
  schoolID: string,
};

export type OnMutateTeacherBySchoolIDSubscription = {
  onMutateTeacherBySchoolID?:  {
    __typename: "Teacher",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    classes?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoUsername: string,
    showIntroduction?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    role?: TeacherRole | null,
    onboarded?: boolean | null,
    devicePushTokens?: Array< string > | null,
    showNewFeatureInstructions?: boolean | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateTeacherCheckInByTeacherIDSubscriptionVariables = {
  teacherID: string,
};

export type OnMutateTeacherCheckInByTeacherIDSubscription = {
  onMutateTeacherCheckInByTeacherID?:  {
    __typename: "TeacherCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateTeacherClassByTeacherIDSubscriptionVariables = {
  teacherID: string,
};

export type OnMutateTeacherClassByTeacherIDSubscription = {
  onMutateTeacherClassByTeacherID?:  {
    __typename: "TeacherClass",
    id: string,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateTeacherClassSubscriptionVariables = {
};

export type OnMutateTeacherClassSubscription = {
  onMutateTeacherClass?:  {
    __typename: "TeacherClass",
    id: string,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnMutateTeacherClassByClassIDSubscriptionVariables = {
  classID: string,
};

export type OnMutateTeacherClassByClassIDSubscription = {
  onMutateTeacherClassByClassID?:  {
    __typename: "TeacherClass",
    id: string,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnPingSubscriptionVariables = {
  id: string,
};

export type OnPingSubscription = {
  onPing?:  {
    __typename: "Ping",
    id: string,
  } | null,
};

export type OnCreateStudentCheckInSubscriptionVariables = {
  filter?: ModelSubscriptionStudentCheckInFilterInput | null,
};

export type OnCreateStudentCheckInSubscription = {
  onCreateStudentCheckIn?:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateStudentCheckInSubscriptionVariables = {
  filter?: ModelSubscriptionStudentCheckInFilterInput | null,
};

export type OnUpdateStudentCheckInSubscription = {
  onUpdateStudentCheckIn?:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteStudentCheckInSubscriptionVariables = {
  filter?: ModelSubscriptionStudentCheckInFilterInput | null,
};

export type OnDeleteStudentCheckInSubscription = {
  onDeleteStudentCheckIn?:  {
    __typename: "StudentCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensity?: number | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    checkinsessionID: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    absence?: boolean | null,
    notYet?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTeacherCheckInSubscriptionVariables = {
  filter?: ModelSubscriptionTeacherCheckInFilterInput | null,
};

export type OnCreateTeacherCheckInSubscription = {
  onCreateTeacherCheckIn?:  {
    __typename: "TeacherCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTeacherCheckInSubscriptionVariables = {
  filter?: ModelSubscriptionTeacherCheckInFilterInput | null,
};

export type OnUpdateTeacherCheckInSubscription = {
  onUpdateTeacherCheckIn?:  {
    __typename: "TeacherCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTeacherCheckInSubscriptionVariables = {
  filter?: ModelSubscriptionTeacherCheckInFilterInput | null,
};

export type OnDeleteTeacherCheckInSubscription = {
  onDeleteTeacherCheckIn?:  {
    __typename: "TeacherCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateStaffCheckInSubscriptionVariables = {
  filter?: ModelSubscriptionStaffCheckInFilterInput | null,
};

export type OnCreateStaffCheckInSubscription = {
  onCreateStaffCheckIn?:  {
    __typename: "StaffCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    staffID: string,
    staff?:  {
      __typename: "Staff",
      id: string,
      cognitoUsername: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      nonTeachingStaffCheckInCount?: number | null,
      nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateStaffCheckInSubscriptionVariables = {
  filter?: ModelSubscriptionStaffCheckInFilterInput | null,
};

export type OnUpdateStaffCheckInSubscription = {
  onUpdateStaffCheckIn?:  {
    __typename: "StaffCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    staffID: string,
    staff?:  {
      __typename: "Staff",
      id: string,
      cognitoUsername: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      nonTeachingStaffCheckInCount?: number | null,
      nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteStaffCheckInSubscriptionVariables = {
  filter?: ModelSubscriptionStaffCheckInFilterInput | null,
};

export type OnDeleteStaffCheckInSubscription = {
  onDeleteStaffCheckIn?:  {
    __typename: "StaffCheckIn",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    staffID: string,
    staff?:  {
      __typename: "Staff",
      id: string,
      cognitoUsername: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      nonTeachingStaffCheckInCount?: number | null,
      nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCheckInSessionSubscriptionVariables = {
  filter?: ModelSubscriptionCheckInSessionFilterInput | null,
};

export type OnCreateCheckInSessionSubscription = {
  onCreateCheckInSession?:  {
    __typename: "CheckInSession",
    id: string,
    checkInSessionClassSequenceNo?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    openAt?: string | null,
    closeAt?: string | null,
    isActive: boolean,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCheckInSessionSubscriptionVariables = {
  filter?: ModelSubscriptionCheckInSessionFilterInput | null,
};

export type OnUpdateCheckInSessionSubscription = {
  onUpdateCheckInSession?:  {
    __typename: "CheckInSession",
    id: string,
    checkInSessionClassSequenceNo?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    openAt?: string | null,
    closeAt?: string | null,
    isActive: boolean,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCheckInSessionSubscriptionVariables = {
  filter?: ModelSubscriptionCheckInSessionFilterInput | null,
};

export type OnDeleteCheckInSessionSubscription = {
  onDeleteCheckInSession?:  {
    __typename: "CheckInSession",
    id: string,
    checkInSessionClassSequenceNo?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    openAt?: string | null,
    closeAt?: string | null,
    isActive: boolean,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSchoolSubscriptionVariables = {
  filter?: ModelSubscriptionSchoolFilterInput | null,
};

export type OnCreateSchoolSubscription = {
  onCreateSchool?:  {
    __typename: "School",
    id: string,
    name: string,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    campuses?:  {
      __typename: "ModelCampusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teachers?:  {
      __typename: "ModelTeacherConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    students?:  {
      __typename: "ModelStudentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffs?:  {
      __typename: "ModelStaffConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdmins?:  {
      __typename: "ModelSchoolAdminConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdminUI?: SchoolAdminUI | null,
    utcOffset?: string | null,
    licenseTotal?: string | null,
    timezone?: string | null,
    status?: SchoolStatus | null,
    activeStudents?: number | null,
    trialEndDate?: string | null,
    renewalDate?: string | null,
    notes?: string | null,
    checkInCountAllTime?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    wholeSchoolSubscription?: boolean | null,
    billingAddress?: string | null,
    billingNotes?: string | null,
    invoiceUrl?: string | null,
    abn?: string | null,
    coordinatorID?: string | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teacherWellbeing?: boolean | null,
    staffWellbeing?: boolean | null,
    wellbeingFeature?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSchoolSubscriptionVariables = {
  filter?: ModelSubscriptionSchoolFilterInput | null,
};

export type OnUpdateSchoolSubscription = {
  onUpdateSchool?:  {
    __typename: "School",
    id: string,
    name: string,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    campuses?:  {
      __typename: "ModelCampusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teachers?:  {
      __typename: "ModelTeacherConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    students?:  {
      __typename: "ModelStudentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffs?:  {
      __typename: "ModelStaffConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdmins?:  {
      __typename: "ModelSchoolAdminConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdminUI?: SchoolAdminUI | null,
    utcOffset?: string | null,
    licenseTotal?: string | null,
    timezone?: string | null,
    status?: SchoolStatus | null,
    activeStudents?: number | null,
    trialEndDate?: string | null,
    renewalDate?: string | null,
    notes?: string | null,
    checkInCountAllTime?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    wholeSchoolSubscription?: boolean | null,
    billingAddress?: string | null,
    billingNotes?: string | null,
    invoiceUrl?: string | null,
    abn?: string | null,
    coordinatorID?: string | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teacherWellbeing?: boolean | null,
    staffWellbeing?: boolean | null,
    wellbeingFeature?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSchoolSubscriptionVariables = {
  filter?: ModelSubscriptionSchoolFilterInput | null,
};

export type OnDeleteSchoolSubscription = {
  onDeleteSchool?:  {
    __typename: "School",
    id: string,
    name: string,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    campuses?:  {
      __typename: "ModelCampusConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teachers?:  {
      __typename: "ModelTeacherConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    students?:  {
      __typename: "ModelStudentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffs?:  {
      __typename: "ModelStaffConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdmins?:  {
      __typename: "ModelSchoolAdminConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schoolAdminUI?: SchoolAdminUI | null,
    utcOffset?: string | null,
    licenseTotal?: string | null,
    timezone?: string | null,
    status?: SchoolStatus | null,
    activeStudents?: number | null,
    trialEndDate?: string | null,
    renewalDate?: string | null,
    notes?: string | null,
    checkInCountAllTime?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    wholeSchoolSubscription?: boolean | null,
    billingAddress?: string | null,
    billingNotes?: string | null,
    invoiceUrl?: string | null,
    abn?: string | null,
    coordinatorID?: string | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    staffCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teacherWellbeing?: boolean | null,
    staffWellbeing?: boolean | null,
    wellbeingFeature?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCampusSubscriptionVariables = {
  filter?: ModelSubscriptionCampusFilterInput | null,
};

export type OnCreateCampusSubscription = {
  onCreateCampus?:  {
    __typename: "Campus",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCampusSubscriptionVariables = {
  filter?: ModelSubscriptionCampusFilterInput | null,
};

export type OnUpdateCampusSubscription = {
  onUpdateCampus?:  {
    __typename: "Campus",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCampusSubscriptionVariables = {
  filter?: ModelSubscriptionCampusFilterInput | null,
};

export type OnDeleteCampusSubscription = {
  onDeleteCampus?:  {
    __typename: "Campus",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroups?:  {
      __typename: "ModelClassGroupConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateClassGroupSubscriptionVariables = {
  filter?: ModelSubscriptionClassGroupFilterInput | null,
};

export type OnCreateClassGroupSubscription = {
  onCreateClassGroup?:  {
    __typename: "ClassGroup",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    campusID: string,
    campus?:  {
      __typename: "Campus",
      id: string,
      name: string,
      schoolID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateClassGroupSubscriptionVariables = {
  filter?: ModelSubscriptionClassGroupFilterInput | null,
};

export type OnUpdateClassGroupSubscription = {
  onUpdateClassGroup?:  {
    __typename: "ClassGroup",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    campusID: string,
    campus?:  {
      __typename: "Campus",
      id: string,
      name: string,
      schoolID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteClassGroupSubscriptionVariables = {
  filter?: ModelSubscriptionClassGroupFilterInput | null,
};

export type OnDeleteClassGroupSubscription = {
  onDeleteClassGroup?:  {
    __typename: "ClassGroup",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    campusID: string,
    campus?:  {
      __typename: "Campus",
      id: string,
      name: string,
      schoolID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classes?:  {
      __typename: "ModelClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateClassSubscriptionVariables = {
  filter?: ModelSubscriptionClassFilterInput | null,
};

export type OnCreateClassSubscription = {
  onCreateClass?:  {
    __typename: "Class",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroupID?: string | null,
    students?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classLogin?:  {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkInSessions?:  {
      __typename: "ModelCheckInSessionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    supports?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    emotionIntensityLevel?: number | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    toggleRequestForChat?: boolean | null,
    toggleRequestForChatNotifications?: boolean | null,
    toggleIndividualStudentStats?: boolean | null,
    teachers?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schedules?:  {
      __typename: "ModelScheduleClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bulkSignUpId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    primaryContact?: string | null,
    archived?: boolean | null,
    emailReceivers?: Array< string | null > | null,
    lastCheckInSessionClassSequenceNo?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    activeStudents?: number | null,
    goMode?: boolean | null,
    yearLevel?: string | null,
    lastCheckInTime?: string | null,
    wellbeing?: boolean | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestionID?: string | null,
    wellbeingResponseCount?: number | null,
    toggleEating?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    classClassLoginId?: string | null,
  } | null,
};

export type OnUpdateClassSubscriptionVariables = {
  filter?: ModelSubscriptionClassFilterInput | null,
};

export type OnUpdateClassSubscription = {
  onUpdateClass?:  {
    __typename: "Class",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroupID?: string | null,
    students?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classLogin?:  {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkInSessions?:  {
      __typename: "ModelCheckInSessionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    supports?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    emotionIntensityLevel?: number | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    toggleRequestForChat?: boolean | null,
    toggleRequestForChatNotifications?: boolean | null,
    toggleIndividualStudentStats?: boolean | null,
    teachers?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schedules?:  {
      __typename: "ModelScheduleClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bulkSignUpId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    primaryContact?: string | null,
    archived?: boolean | null,
    emailReceivers?: Array< string | null > | null,
    lastCheckInSessionClassSequenceNo?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    activeStudents?: number | null,
    goMode?: boolean | null,
    yearLevel?: string | null,
    lastCheckInTime?: string | null,
    wellbeing?: boolean | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestionID?: string | null,
    wellbeingResponseCount?: number | null,
    toggleEating?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    classClassLoginId?: string | null,
  } | null,
};

export type OnDeleteClassSubscriptionVariables = {
  filter?: ModelSubscriptionClassFilterInput | null,
};

export type OnDeleteClassSubscription = {
  onDeleteClass?:  {
    __typename: "Class",
    id: string,
    name: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classGroupID?: string | null,
    students?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    classLogin?:  {
      __typename: "ClassLogin",
      id: string,
      nickname: string,
      cognitoUsername: string,
      classID: string,
      classCode: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      devicePushTokens?: Array< string > | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    checkInSessions?:  {
      __typename: "ModelCheckInSessionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    supports?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    emotionIntensityLevel?: number | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    toggleRequestForChat?: boolean | null,
    toggleRequestForChatNotifications?: boolean | null,
    toggleIndividualStudentStats?: boolean | null,
    teachers?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    schedules?:  {
      __typename: "ModelScheduleClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bulkSignUpId?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    primaryContact?: string | null,
    archived?: boolean | null,
    emailReceivers?: Array< string | null > | null,
    lastCheckInSessionClassSequenceNo?: number | null,
    checkInCountYTD?: number | null,
    checkInCountRollingWeekMonday?: number | null,
    checkInCountRollingWeekTuesday?: number | null,
    checkInCountRollingWeekWednesday?: number | null,
    checkInCountRollingWeekThursday?: number | null,
    checkInCountRollingWeekFriday?: number | null,
    checkInCountRollingWeekSaturday?: number | null,
    checkInCountRollingWeekSunday?: number | null,
    activeStudents?: number | null,
    goMode?: boolean | null,
    yearLevel?: string | null,
    lastCheckInTime?: string | null,
    wellbeing?: boolean | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestionID?: string | null,
    wellbeingResponseCount?: number | null,
    toggleEating?: boolean | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    classClassLoginId?: string | null,
  } | null,
};

export type OnCreateTeacherSubscriptionVariables = {
  filter?: ModelSubscriptionTeacherFilterInput | null,
};

export type OnCreateTeacherSubscription = {
  onCreateTeacher?:  {
    __typename: "Teacher",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    classes?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoUsername: string,
    showIntroduction?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    role?: TeacherRole | null,
    onboarded?: boolean | null,
    devicePushTokens?: Array< string > | null,
    showNewFeatureInstructions?: boolean | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTeacherSubscriptionVariables = {
  filter?: ModelSubscriptionTeacherFilterInput | null,
};

export type OnUpdateTeacherSubscription = {
  onUpdateTeacher?:  {
    __typename: "Teacher",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    classes?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoUsername: string,
    showIntroduction?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    role?: TeacherRole | null,
    onboarded?: boolean | null,
    devicePushTokens?: Array< string > | null,
    showNewFeatureInstructions?: boolean | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTeacherSubscriptionVariables = {
  filter?: ModelSubscriptionTeacherFilterInput | null,
};

export type OnDeleteTeacherSubscription = {
  onDeleteTeacher?:  {
    __typename: "Teacher",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    classes?:  {
      __typename: "ModelTeacherClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoUsername: string,
    showIntroduction?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    role?: TeacherRole | null,
    onboarded?: boolean | null,
    devicePushTokens?: Array< string > | null,
    showNewFeatureInstructions?: boolean | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelTeacherCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateStaffSubscriptionVariables = {
  filter?: ModelSubscriptionStaffFilterInput | null,
};

export type OnCreateStaffSubscription = {
  onCreateStaff?:  {
    __typename: "Staff",
    id: string,
    cognitoUsername: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    nonTeachingStaffCheckInCount?: number | null,
    nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateStaffSubscriptionVariables = {
  filter?: ModelSubscriptionStaffFilterInput | null,
};

export type OnUpdateStaffSubscription = {
  onUpdateStaff?:  {
    __typename: "Staff",
    id: string,
    cognitoUsername: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    nonTeachingStaffCheckInCount?: number | null,
    nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteStaffSubscriptionVariables = {
  filter?: ModelSubscriptionStaffFilterInput | null,
};

export type OnDeleteStaffSubscription = {
  onDeleteStaff?:  {
    __typename: "Staff",
    id: string,
    cognitoUsername: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    preferredUsername?: string | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    wellbeingResponseCount?: number | null,
    teacherCheckIns?:  {
      __typename: "ModelStaffCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    avatar?: string | null,
    nonTeachingStaffCheckInCount?: number | null,
    nonTeachingStaffCheckInSkipSuggestionDate?: string | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSchoolAdminSubscriptionVariables = {
  filter?: ModelSubscriptionSchoolAdminFilterInput | null,
};

export type OnCreateSchoolAdminSubscription = {
  onCreateSchoolAdmin?:  {
    __typename: "SchoolAdmin",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    cognitoUsername: string,
    preferredUsername?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSchoolAdminSubscriptionVariables = {
  filter?: ModelSubscriptionSchoolAdminFilterInput | null,
};

export type OnUpdateSchoolAdminSubscription = {
  onUpdateSchoolAdmin?:  {
    __typename: "SchoolAdmin",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    cognitoUsername: string,
    preferredUsername?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSchoolAdminSubscriptionVariables = {
  filter?: ModelSubscriptionSchoolAdminFilterInput | null,
};

export type OnDeleteSchoolAdminSubscription = {
  onDeleteSchoolAdmin?:  {
    __typename: "SchoolAdmin",
    id: string,
    email: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    cognitoUsername: string,
    preferredUsername?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateStudentSubscriptionVariables = {
  filter?: ModelSubscriptionStudentFilterInput | null,
};

export type OnCreateStudentSubscription = {
  onCreateStudent?:  {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    yearLevel?: string | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    classes?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    activeClasses?: number | null,
    studentCheckInCount?: number | null,
    studentCheckInSkipSuggestionDate?: string | null,
    devicePushTokens?: Array< string > | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestion?: string | null,
    wellbeingResponseCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateStudentSubscriptionVariables = {
  filter?: ModelSubscriptionStudentFilterInput | null,
};

export type OnUpdateStudentSubscription = {
  onUpdateStudent?:  {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    yearLevel?: string | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    classes?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    activeClasses?: number | null,
    studentCheckInCount?: number | null,
    studentCheckInSkipSuggestionDate?: string | null,
    devicePushTokens?: Array< string > | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestion?: string | null,
    wellbeingResponseCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteStudentSubscriptionVariables = {
  filter?: ModelSubscriptionStudentFilterInput | null,
};

export type OnDeleteStudentSubscription = {
  onDeleteStudent?:  {
    __typename: "Student",
    id: string,
    schoolStudentID?: string | null,
    nickname: string,
    avatar: string,
    schoolID: string,
    school?:  {
      __typename: "School",
      id: string,
      name: string,
      schoolAdminUI?: SchoolAdminUI | null,
      utcOffset?: string | null,
      licenseTotal?: string | null,
      timezone?: string | null,
      status?: SchoolStatus | null,
      activeStudents?: number | null,
      trialEndDate?: string | null,
      renewalDate?: string | null,
      notes?: string | null,
      checkInCountAllTime?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      wholeSchoolSubscription?: boolean | null,
      billingAddress?: string | null,
      billingNotes?: string | null,
      invoiceUrl?: string | null,
      abn?: string | null,
      coordinatorID?: string | null,
      teacherWellbeing?: boolean | null,
      staffWellbeing?: boolean | null,
      wellbeingFeature?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    yearLevel?: string | null,
    studentCheckIns?:  {
      __typename: "ModelStudentCheckInConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: StudentStatus | null,
    cognitoUsername: string,
    classes?:  {
      __typename: "ModelStudentClassConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    onboarded?: boolean | null,
    initialLoginSetup?: boolean | null,
    activeClasses?: number | null,
    studentCheckInCount?: number | null,
    studentCheckInSkipSuggestionDate?: string | null,
    devicePushTokens?: Array< string > | null,
    wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
    wellbeingQuestionUsed?: Array< string > | null,
    currentWellbeingQuestion?: string | null,
    wellbeingResponseCount?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSupportSubscriptionVariables = {
  filter?: ModelSubscriptionSupportFilterInput | null,
};

export type OnCreateSupportSubscription = {
  onCreateSupport?:  {
    __typename: "Support",
    id: string,
    email?: string | null,
    classes?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName: string,
    lastName: string,
    cognitoUsername: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSupportSubscriptionVariables = {
  filter?: ModelSubscriptionSupportFilterInput | null,
};

export type OnUpdateSupportSubscription = {
  onUpdateSupport?:  {
    __typename: "Support",
    id: string,
    email?: string | null,
    classes?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName: string,
    lastName: string,
    cognitoUsername: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSupportSubscriptionVariables = {
  filter?: ModelSubscriptionSupportFilterInput | null,
};

export type OnDeleteSupportSubscription = {
  onDeleteSupport?:  {
    __typename: "Support",
    id: string,
    email?: string | null,
    classes?:  {
      __typename: "ModelClassSupportConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    firstName: string,
    lastName: string,
    cognitoUsername: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateClassSupportSubscriptionVariables = {
  filter?: ModelSubscriptionClassSupportFilterInput | null,
};

export type OnCreateClassSupportSubscription = {
  onCreateClassSupport?:  {
    __typename: "ClassSupport",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    supportID: string,
    support?:  {
      __typename: "Support",
      id: string,
      email?: string | null,
      firstName: string,
      lastName: string,
      cognitoUsername: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateClassSupportSubscriptionVariables = {
  filter?: ModelSubscriptionClassSupportFilterInput | null,
};

export type OnUpdateClassSupportSubscription = {
  onUpdateClassSupport?:  {
    __typename: "ClassSupport",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    supportID: string,
    support?:  {
      __typename: "Support",
      id: string,
      email?: string | null,
      firstName: string,
      lastName: string,
      cognitoUsername: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteClassSupportSubscriptionVariables = {
  filter?: ModelSubscriptionClassSupportFilterInput | null,
};

export type OnDeleteClassSupportSubscription = {
  onDeleteClassSupport?:  {
    __typename: "ClassSupport",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    supportID: string,
    support?:  {
      __typename: "Support",
      id: string,
      email?: string | null,
      firstName: string,
      lastName: string,
      cognitoUsername: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTeacherClassSubscriptionVariables = {
  filter?: ModelSubscriptionTeacherClassFilterInput | null,
};

export type OnCreateTeacherClassSubscription = {
  onCreateTeacherClass?:  {
    __typename: "TeacherClass",
    id: string,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTeacherClassSubscriptionVariables = {
  filter?: ModelSubscriptionTeacherClassFilterInput | null,
};

export type OnUpdateTeacherClassSubscription = {
  onUpdateTeacherClass?:  {
    __typename: "TeacherClass",
    id: string,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTeacherClassSubscriptionVariables = {
  filter?: ModelSubscriptionTeacherClassFilterInput | null,
};

export type OnDeleteTeacherClassSubscription = {
  onDeleteTeacherClass?:  {
    __typename: "TeacherClass",
    id: string,
    teacherID: string,
    teacher?:  {
      __typename: "Teacher",
      id: string,
      email: string,
      schoolID: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoUsername: string,
      showIntroduction?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      role?: TeacherRole | null,
      onboarded?: boolean | null,
      devicePushTokens?: Array< string > | null,
      showNewFeatureInstructions?: boolean | null,
      preferredUsername?: string | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      wellbeingResponseCount?: number | null,
      avatar?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateScheduleClassSubscriptionVariables = {
  filter?: ModelSubscriptionScheduleClassFilterInput | null,
};

export type OnCreateScheduleClassSubscription = {
  onCreateScheduleClass?:  {
    __typename: "ScheduleClass",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    schedule: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateScheduleClassSubscriptionVariables = {
  filter?: ModelSubscriptionScheduleClassFilterInput | null,
};

export type OnUpdateScheduleClassSubscription = {
  onUpdateScheduleClass?:  {
    __typename: "ScheduleClass",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    schedule: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteScheduleClassSubscriptionVariables = {
  filter?: ModelSubscriptionScheduleClassFilterInput | null,
};

export type OnDeleteScheduleClassSubscription = {
  onDeleteScheduleClass?:  {
    __typename: "ScheduleClass",
    id: string,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    schedule: string,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateStudentClassSubscriptionVariables = {
  filter?: ModelSubscriptionStudentClassFilterInput | null,
};

export type OnCreateStudentClassSubscription = {
  onCreateStudentClass?:  {
    __typename: "StudentClass",
    id: string,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    status?: StudentClassApprovalStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    requestForChat?: boolean | null,
    requestForChatStatus?: RequestForChatStatus | null,
    studentCheckIns?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateStudentClassSubscriptionVariables = {
  filter?: ModelSubscriptionStudentClassFilterInput | null,
};

export type OnUpdateStudentClassSubscription = {
  onUpdateStudentClass?:  {
    __typename: "StudentClass",
    id: string,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    status?: StudentClassApprovalStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    requestForChat?: boolean | null,
    requestForChatStatus?: RequestForChatStatus | null,
    studentCheckIns?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteStudentClassSubscriptionVariables = {
  filter?: ModelSubscriptionStudentClassFilterInput | null,
};

export type OnDeleteStudentClassSubscription = {
  onDeleteStudentClass?:  {
    __typename: "StudentClass",
    id: string,
    studentID: string,
    student?:  {
      __typename: "Student",
      id: string,
      schoolStudentID?: string | null,
      nickname: string,
      avatar: string,
      schoolID: string,
      yearLevel?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: StudentStatus | null,
      cognitoUsername: string,
      createdAt?: string | null,
      updatedAt?: string | null,
      onboarded?: boolean | null,
      initialLoginSetup?: boolean | null,
      activeClasses?: number | null,
      studentCheckInCount?: number | null,
      studentCheckInSkipSuggestionDate?: string | null,
      devicePushTokens?: Array< string > | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestion?: string | null,
      wellbeingResponseCount?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    classID: string,
    class?:  {
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      classGroupID?: string | null,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array< string | null > | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      goMode?: boolean | null,
      yearLevel?: string | null,
      lastCheckInTime?: string | null,
      wellbeing?: boolean | null,
      wellbeingQuestionCategoryUsed?: Array< QuestionCategory > | null,
      wellbeingQuestionUsed?: Array< string > | null,
      currentWellbeingQuestionID?: string | null,
      wellbeingResponseCount?: number | null,
      toggleEating?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null,
    status?: StudentClassApprovalStatus | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    requestForChat?: boolean | null,
    requestForChatStatus?: RequestForChatStatus | null,
    studentCheckIns?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSchoolContactSubscriptionVariables = {
  filter?: ModelSubscriptionSchoolContactFilterInput | null,
};

export type OnCreateSchoolContactSubscription = {
  onCreateSchoolContact?:  {
    __typename: "SchoolContact",
    id: string,
    schoolID: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    type?: SchoolContactType | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSchoolContactSubscriptionVariables = {
  filter?: ModelSubscriptionSchoolContactFilterInput | null,
};

export type OnUpdateSchoolContactSubscription = {
  onUpdateSchoolContact?:  {
    __typename: "SchoolContact",
    id: string,
    schoolID: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    type?: SchoolContactType | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSchoolContactSubscriptionVariables = {
  filter?: ModelSubscriptionSchoolContactFilterInput | null,
};

export type OnDeleteSchoolContactSubscription = {
  onDeleteSchoolContact?:  {
    __typename: "SchoolContact",
    id: string,
    schoolID: string,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    type?: SchoolContactType | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMinFEVersionSubscriptionVariables = {
  filter?: ModelSubscriptionMinFEVersionFilterInput | null,
};

export type OnCreateMinFEVersionSubscription = {
  onCreateMinFEVersion?:  {
    __typename: "MinFEVersion",
    version: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMinFEVersionSubscriptionVariables = {
  filter?: ModelSubscriptionMinFEVersionFilterInput | null,
};

export type OnUpdateMinFEVersionSubscription = {
  onUpdateMinFEVersion?:  {
    __typename: "MinFEVersion",
    version: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMinFEVersionSubscriptionVariables = {
  filter?: ModelSubscriptionMinFEVersionFilterInput | null,
};

export type OnDeleteMinFEVersionSubscription = {
  onDeleteMinFEVersion?:  {
    __typename: "MinFEVersion",
    version: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSwitchRatingSubscriptionVariables = {
  filter?: ModelSubscriptionSwitchRatingFilterInput | null,
};

export type OnCreateSwitchRatingSubscription = {
  onCreateSwitchRating?:  {
    __typename: "SwitchRating",
    id: string,
    userId: string,
    cardId: string,
    userType: UserType,
    rating: SwitchRatingType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSwitchRatingSubscriptionVariables = {
  filter?: ModelSubscriptionSwitchRatingFilterInput | null,
};

export type OnUpdateSwitchRatingSubscription = {
  onUpdateSwitchRating?:  {
    __typename: "SwitchRating",
    id: string,
    userId: string,
    cardId: string,
    userType: UserType,
    rating: SwitchRatingType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSwitchRatingSubscriptionVariables = {
  filter?: ModelSubscriptionSwitchRatingFilterInput | null,
};

export type OnDeleteSwitchRatingSubscription = {
  onDeleteSwitchRating?:  {
    __typename: "SwitchRating",
    id: string,
    userId: string,
    cardId: string,
    userType: UserType,
    rating: SwitchRatingType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateClassLoginSubscriptionVariables = {
  filter?: ModelSubscriptionClassLoginFilterInput | null,
};

export type OnCreateClassLoginSubscription = {
  onCreateClassLogin?:  {
    __typename: "ClassLogin",
    id: string,
    nickname: string,
    cognitoUsername: string,
    classID: string,
    classCode: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    devicePushTokens?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateClassLoginSubscriptionVariables = {
  filter?: ModelSubscriptionClassLoginFilterInput | null,
};

export type OnUpdateClassLoginSubscription = {
  onUpdateClassLogin?:  {
    __typename: "ClassLogin",
    id: string,
    nickname: string,
    cognitoUsername: string,
    classID: string,
    classCode: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    devicePushTokens?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteClassLoginSubscriptionVariables = {
  filter?: ModelSubscriptionClassLoginFilterInput | null,
};

export type OnDeleteClassLoginSubscription = {
  onDeleteClassLogin?:  {
    __typename: "ClassLogin",
    id: string,
    nickname: string,
    cognitoUsername: string,
    classID: string,
    classCode: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    devicePushTokens?: Array< string > | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFavouriteSubscriptionVariables = {
  filter?: ModelSubscriptionFavouriteFilterInput | null,
};

export type OnCreateFavouriteSubscription = {
  onCreateFavourite?:  {
    __typename: "Favourite",
    id: string,
    cognitoUsername: string,
    cardId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFavouriteSubscriptionVariables = {
  filter?: ModelSubscriptionFavouriteFilterInput | null,
};

export type OnUpdateFavouriteSubscription = {
  onUpdateFavourite?:  {
    __typename: "Favourite",
    id: string,
    cognitoUsername: string,
    cardId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFavouriteSubscriptionVariables = {
  filter?: ModelSubscriptionFavouriteFilterInput | null,
};

export type OnDeleteFavouriteSubscription = {
  onDeleteFavourite?:  {
    __typename: "Favourite",
    id: string,
    cognitoUsername: string,
    cardId: string,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFindYourCalmActivityRatingSubscriptionVariables = {
  filter?: ModelSubscriptionFindYourCalmActivityRatingFilterInput | null,
};

export type OnCreateFindYourCalmActivityRatingSubscription = {
  onCreateFindYourCalmActivityRating?:  {
    __typename: "FindYourCalmActivityRating",
    userId: string,
    activityId: string,
    rating?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFindYourCalmActivityRatingSubscriptionVariables = {
  filter?: ModelSubscriptionFindYourCalmActivityRatingFilterInput | null,
};

export type OnUpdateFindYourCalmActivityRatingSubscription = {
  onUpdateFindYourCalmActivityRating?:  {
    __typename: "FindYourCalmActivityRating",
    userId: string,
    activityId: string,
    rating?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFindYourCalmActivityRatingSubscriptionVariables = {
  filter?: ModelSubscriptionFindYourCalmActivityRatingFilterInput | null,
};

export type OnDeleteFindYourCalmActivityRatingSubscription = {
  onDeleteFindYourCalmActivityRating?:  {
    __typename: "FindYourCalmActivityRating",
    userId: string,
    activityId: string,
    rating?: number | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
};

export type OnCreateInvoiceSubscription = {
  onCreateInvoice?:  {
    __typename: "Invoice",
    id: string,
    schoolID: string,
    url: string,
    name?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
};

export type OnUpdateInvoiceSubscription = {
  onUpdateInvoice?:  {
    __typename: "Invoice",
    id: string,
    schoolID: string,
    url: string,
    name?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInvoiceSubscriptionVariables = {
  filter?: ModelSubscriptionInvoiceFilterInput | null,
};

export type OnDeleteInvoiceSubscription = {
  onDeleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    schoolID: string,
    url: string,
    name?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateVideosSubscriptionVariables = {
  filter?: ModelSubscriptionVideosFilterInput | null,
};

export type OnCreateVideosSubscription = {
  onCreateVideos?:  {
    __typename: "Videos",
    id: string,
    url: string,
    image?: string | null,
    title?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    time?: Array< string | null > | null,
    level?: Array< string | null > | null,
    emotion?: Array< string | null > | null,
    ageGroup?: Array< string | null > | null,
    isExternal?: boolean | null,
    autoplay?: boolean | null,
    youtubePlaylistThumbnailUrlVideoId?: string | null,
    thumbnailUrl?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateVideosSubscriptionVariables = {
  filter?: ModelSubscriptionVideosFilterInput | null,
};

export type OnUpdateVideosSubscription = {
  onUpdateVideos?:  {
    __typename: "Videos",
    id: string,
    url: string,
    image?: string | null,
    title?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    time?: Array< string | null > | null,
    level?: Array< string | null > | null,
    emotion?: Array< string | null > | null,
    ageGroup?: Array< string | null > | null,
    isExternal?: boolean | null,
    autoplay?: boolean | null,
    youtubePlaylistThumbnailUrlVideoId?: string | null,
    thumbnailUrl?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteVideosSubscriptionVariables = {
  filter?: ModelSubscriptionVideosFilterInput | null,
};

export type OnDeleteVideosSubscription = {
  onDeleteVideos?:  {
    __typename: "Videos",
    id: string,
    url: string,
    image?: string | null,
    title?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    time?: Array< string | null > | null,
    level?: Array< string | null > | null,
    emotion?: Array< string | null > | null,
    ageGroup?: Array< string | null > | null,
    isExternal?: boolean | null,
    autoplay?: boolean | null,
    youtubePlaylistThumbnailUrlVideoId?: string | null,
    thumbnailUrl?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateWellbeingQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionWellbeingQuestionFilterInput | null,
};

export type OnCreateWellbeingQuestionSubscription = {
  onCreateWellbeingQuestion?:  {
    __typename: "WellbeingQuestion",
    id: string,
    category: QuestionCategory,
    type: QuestionType,
    text: string,
    options:  Array< {
      __typename: "QuestionOption",
      text: string,
      value: number,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateWellbeingQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionWellbeingQuestionFilterInput | null,
};

export type OnUpdateWellbeingQuestionSubscription = {
  onUpdateWellbeingQuestion?:  {
    __typename: "WellbeingQuestion",
    id: string,
    category: QuestionCategory,
    type: QuestionType,
    text: string,
    options:  Array< {
      __typename: "QuestionOption",
      text: string,
      value: number,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteWellbeingQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionWellbeingQuestionFilterInput | null,
};

export type OnDeleteWellbeingQuestionSubscription = {
  onDeleteWellbeingQuestion?:  {
    __typename: "WellbeingQuestion",
    id: string,
    category: QuestionCategory,
    type: QuestionType,
    text: string,
    options:  Array< {
      __typename: "QuestionOption",
      text: string,
      value: number,
    } >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateWellbeingResponseSubscriptionVariables = {
  filter?: ModelSubscriptionWellbeingResponseFilterInput | null,
};

export type OnCreateWellbeingResponseSubscription = {
  onCreateWellbeingResponse?:  {
    __typename: "WellbeingResponse",
    id: string,
    userID: string,
    userType: WellbeingResponseUserType,
    wellbeingQuestionID: string,
    answer: number,
    createdAt: string,
    schoolID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateWellbeingResponseSubscriptionVariables = {
  filter?: ModelSubscriptionWellbeingResponseFilterInput | null,
};

export type OnUpdateWellbeingResponseSubscription = {
  onUpdateWellbeingResponse?:  {
    __typename: "WellbeingResponse",
    id: string,
    userID: string,
    userType: WellbeingResponseUserType,
    wellbeingQuestionID: string,
    answer: number,
    createdAt: string,
    schoolID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteWellbeingResponseSubscriptionVariables = {
  filter?: ModelSubscriptionWellbeingResponseFilterInput | null,
};

export type OnDeleteWellbeingResponseSubscription = {
  onDeleteWellbeingResponse?:  {
    __typename: "WellbeingResponse",
    id: string,
    userID: string,
    userType: WellbeingResponseUserType,
    wellbeingQuestionID: string,
    answer: number,
    createdAt: string,
    schoolID: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
