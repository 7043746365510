import { View } from 'react-native'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { LookAroundIcon } from '../../icons/look-around-icon'
import { DefaultTextWithExtraTipIcon } from '../../../common/default-text-with-extra-tip-icon'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { LookAroundIconInverted } from '../../icons/look-around-icon-inverted'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { OnlineActiviesIcon } from '../../icons/online-activities-icon'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'

export const SwitchesActivitiesEnergizersLookAround: CardTemplate = {
    cardId: "f4ec61cf-896d-4c7c-a989-e2af9dffcd9c",
    title: 'Look around',
    Header: LookAroundIconInverted,
    Icon: LookAroundIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"In-person or online energizing activity, and for highlighting the brains automatic processing."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Round one:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Explain that when you say, “Up”, the participants should look up. “Down”, look down.“Left”, look left. “Right”, look right."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Randomly give “up”, “down”, “left”, “right” directions for about 20 seconds at a fairly rapid pace."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={""} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={""} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Round two:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Flip the meaning of up and down. “Up” means look down and vice versa. The meaning of the words left and right remain the same. "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Again, randomly give “up”, “down”, “left”, “right” directions for about one 30 seconds at a fairly rapid pace."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={""} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={""} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Round three:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Flip the meaning of left and right so “Left” means look right and vice versa. Now all actions are opposite to their directions. "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Again, randomly give “up”, “down”, “left”, “right” directions for about 1 minute at a fairly rapid pace."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={""} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={""} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"This short game creates different winners each time, allowing different people to feel like the champion."} />
        </>

    ),
    CardContent: () => (
        <>

        </>
    ),
    colorScheme: CardColorScheme.ENERGIZERS,
    onlineTips: 'A simple activity to run online - so long as you have everyone on webcam and present in your video conferencing tool, this one is run in very much the same way as a live workshop. Preferably have a gallery view turned on for all participants, so everyone can see everyone during this exercise.',
    whyDoesItWorkText: 'It is difficult to learn new concepts without unlearning some old concepts. Our brains have two ways of processing information – a reactive, automatic way, and a more conscious, responsive way. Our reactive automatic brain is so used to hearing a word and following it’s learnt meaning that it’s hard for our responsive brain to suppress that urge and follow the instructions instead. As your conscious brain gets tired from the activity (it only has a limited bucket of energy) it becomes harder, often resulting in people giving up rather than using mental energy to try and further over-ride these automatic instincts.',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Look+Around.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.ENERGIZERS],
    action: [CardActionType.Action],
    level: []
}

