import { StyleSheet, View } from 'react-native'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { MySafePlaceHeader } from './src/my-safe-place-header'
import { MySafePlaceIcon } from './src/my-safe-place-icon'
import { MySafePlaceImage1 } from './src/my-safe-place-image1'
import { MySafePlaceImage2 } from './src/my-safe-place-image2'
import { MySafePlaceImage3 } from './src/my-safe-place-image3'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DecidaColors } from '../../../../common/decida-colors'

export const SwitchesEmotionsScaredMySafePlace: CardTemplate = {
    cardId: "7819c6b4-3356-4823-83da-9ba3aff34a2e",
    title: 'My safe place',
    Header: MySafePlaceHeader,
    Icon: MySafePlaceIcon,
    lessonPlan: true,
    mainContainerStyle: {
        maxWidth: '100%',
        paddingHorizontal: 20,
    },
    PerfectFor: () => {

        const Step1 = () => {
            return (
                <View>
                    <View style={styles.rowContainer}>
                        <DefaultText style={styles.contentText}>Think of a space, either real or imagined, where </DefaultText>
                        <DefaultText style={styles.contentText}>you are completely safe, warm and comfortable.</DefaultText>

                        <View style={styles.imageContainer}>
                            <MySafePlaceImage1 />
                        </View>
                    </View>
                </View>

            )
        }

        const Step2 = () => {
            return (
                <View>
                    <View style={styles.rowContainer}>
                        <DefaultText style={styles.contentText}>Close your eyes and spend at least 5 minutes </DefaultText>
                        <DefaultText style={styles.contentText}>thinking about everything that is in this space.</DefaultText>

                        <View style={styles.imageContainer}>
                            <MySafePlaceImage2 />
                        </View>

                        <DefaultText style={styles.contentText}>Remember - it doesn’t have to make sense - </DefaultText>
                        <DefaultText style={styles.contentText}>this is your very own safe space to put anything </DefaultText>
                        <DefaultText style={styles.contentText}>in it that makes you feel secure.</DefaultText>
                    </View>
                </View>
            )
        }

        const Step3 = () => {
            return (
                <View>

                    <View style={styles.rowContainer}>

                        <DefaultText style={styles.contentText}>Practice going to this imaginary space before </DefaultText>
                        <DefaultText style={styles.contentText}>you go to sleep, and at different times during </DefaultText>
                        <DefaultText style={styles.contentText}>the day.</DefaultText>

                        <View style={CommonStyles.flexContainer}>
                            <View style={styles.rowContainer}>
                                <WhyDoesItWorkComponent message={"when people feel safe and secure, they are more able to cope with and process their emotions and experiences. Research shows that a sense of safety and security is an important factor in promoting mental health and well-being. \n\n Research in cognitive psychology also suggest that when people feel safe, they’re less likely to activate the threat response (also known as “fight or flight” response) in stressful situations. This happens because the brain perceives that it doesn’t have to be ready to respond to an immediate danger, and can instead focus on problem-solving and processing the information at hand.\n\nAdditionally, research on mindfulness and meditation practices shows that these techniques can also help individuals create a sense of mental safety by promoting feelings of self-awareness and compassion. The feeling of being in touch with one self and being kind to oneself also helps to activate the relaxation response, and reduce the activation of the threat response, which often comes along with fear."} />
                            </View>
                            <View style={styles.rowContainer}>
                                <View style={styles.imageContainer}>
                                    <MySafePlaceImage3 />
                                </View>
                            </View>
                        </View>

                        <DefaulTextWithTipsIcon textdata='Practice doing this when you are not scared and it will help your brain go there when you are scared.' />
                    </View>


                </View>

            )
        }

        const contents = [
            { Component: Step1, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/my-safe-place-pg1.mp3' },
            { Component: Step2, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/my-safe-place-pg2.mp3' },
            { Component: Step3, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/my-safe-place-pg3.mp3' },
        ]

        return (
            <>
                <SwitchCardJuniorContent contents={contents} scheme={Emotion.SCARED} />
            </>
        )
    },
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: '',
    BottomRow: () => <View />,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Explore the importance of creating a safe mental space in order to reduce fear.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 40 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by asking the students if they have ever felt scared or nervous about something. Allow them to share their experiences and feelings with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that everyone feels scared or nervous at times, but there are ways to help reduce those feelings. One way is by creating a safe mental space.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Draw a picture of a house on the chart paper and label it “My Safe Mental Space.”</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to think about what they would like to include in their own safe mental space. Some examples might include a cozy bed, a favorite toy or stuffed animal, or a picture of a loved one.”</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Read the children’s book “The Lion Inside” to the class, or watch the video (link in the resources section). The story is about a mouse who feels scared and small, but learns to confront his fears by creating a safe mental space for himself.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ As you read, ask the students to pay attention to what the mouse does to make himself feel safe and ask them to think about what they could do to make themselves feel safe.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After you finish reading the book, ask the students to share their thoughts and ideas.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide the students with pencils and copies of the chart paper </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to draw a picture of their own safe mental space on the chart paper. Encourage them to include as many details as they can, such as what their safe mental space looks like and what they have in it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ While the students are working, circulate around the room to offer support and encouragement.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to take a few minutes to think about their safe mental space and what they have included in it.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give them an opportunity to share their picture and explain their safe mental space with the class.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Closure (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Review the main points of the lesson with the students: creating a safe mental space can help to reduce fear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to use their safe mental space when they feel scared or nervous.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind them that it is normal to feel scared or nervous sometimes, but that there are ways to help cope with those feelings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them how they plan to use their safe mental space in the future.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Chart paper</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Copies of the children’s book “The Lion Inside” (Optional)</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Lion Inside by Rachel Bright and Jim Field. Children’s story. Read-aloud. Audiobook. </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=H019G18irW0' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The Lion Inside Teaching Ideas and Resources: </DefaultText>
                        <DefaultTextWithLink text='https://www.teachingideas.co.uk/library/books/the-lion-inside' />

                        <DefaultText style={CommonStyles.lessonPlanText}>THE LION INSIDE ACTIVITIES AND LESSON PLANS </DefaultText>
                        <DefaultTextWithLink text='https://jodidurgin.com/the-lion-inside-activities-lesson-plans/' />

                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Scared]
}
const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20,
    },
    mobileContainer: { width: '100%' },
    webContainer: {
        flex: 1,
        flexDirection: 'column',
        paddingBottom: "10%",
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    },
    textContaier: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    subText: {
        fontWeight: 'bold',
        fontSize: 14
    },
    urlText: {
        color: DecidaColors.Sad,
        textDecorationLine: 'underline',
        fontWeight: 'bold',
        fontStyle: 'italic'
    },
    contentText: {
        // color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    nextButton: {
        marginTop: 20,
        width: 200,
        backgroundColor: CardColorScheme.SAD
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    imageContainerScaled: {
        width: '100%',
        height: 300,
        marginVertical: 20,
        transform: [{ scale: 1.2 }]
    },
    whydoesthiswork: {
        width: 100,
        height: 100,
    },
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray
    },
    whydoesthisworktext: {
        padding: 10,
    },
    whydoesthisworkContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        marginHorizontal: 20
    },
    rowContainerHalf: {
        flex: 0.5,
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%'
    },
    lessonPlanCenteredTextContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center',
        marginVertical: 20,
        width: '90%'
    },
    underLineText: {
        textDecorationLine: 'underline',
        color: 'blue',
        paddingLeft: 40,
        fontSize: 20,
    },
    tipsIconContainer: {
        marginTop: 20
    },
    imageBackgroundCotainer: {
        position: 'absolute',
        width: '100%',
        height: 300,
        top: 200,
        margi: 0,
        transform: [{ scale: 2 }]
    }
})


