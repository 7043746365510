
import { Platform } from 'react-native'
import Svg, { Rect, G, Path, Circle, Line, Text, TSpan } from 'react-native-svg'
import { Emotion } from '../../models'

interface Props {
    onPress: (emotion: Emotion) => void
}

export const EmotionCircle5Point = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressHappy = {
        [prop]: () => onPress(Emotion.HAPPY)
    }
    const onPressExcited = {
        [prop]: () => onPress(Emotion.EXCITED)
    }
    const onPressScared = {
        [prop]: () => onPress(Emotion.SCARED)
    }
    const onPressSad = {
        [prop]: () => onPress(Emotion.SAD)
    }
    const onPressAngry = {
        [prop]: () => onPress(Emotion.ANGRY)
    }
    const onPressAnxious = {
        [prop]: () => onPress(Emotion.ANXIOUS)
    }

    return (
        <Svg viewBox="0 0 681.813 879.789">
            <G {...onPressAnxious} transform="translate(-21.661 -82.401)" >
                <Path d="M150.472,123.115-190.682,126.2-31.523-156.015s176.127,89.5,182,279.13" transform="translate(553.002 392.962)" fill="#ece6c0" />
                <Path d="M123.427,100.988l-279.837,2.532L-25.858-127.974S118.614-54.561,123.427,100.988" transform="translate(518.774 415.377)" fill="#e0d596" />
                <Path d="M97.292,79.6l-220.583,2L-20.382-100.876S93.5-43.009,97.292,79.6" transform="translate(485.698 437.039)" fill="#d4c56d" />
                <Path d="M70.823,57.947-89.749,59.4-14.837-73.432s82.9,42.125,85.66,131.379" transform="translate(452.199 458.977)" fill="#ccb94e" />
                <Path d="M44.851,36.7l-101.687.92L-9.4-46.5S43.1-19.827,44.851,36.7" transform="translate(419.33 480.503)" fill="#c3ac2e" />
                <Text transform="translate(420.067 483.96)" fill="#fff" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Overwhelmed</TSpan></Text>
                <Text transform="translate(382.095 510.873)" fill="#fff" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Panicked</TSpan></Text>
                <Text transform="translate(595.516 402.277)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Concerned</TSpan></Text>
                <Text transform="translate(525.627 428.27)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Apprehensive</TSpan></Text>
                <Text transform="translate(492.211 458.427)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Worried</TSpan></Text>
                <Path d="M5.706,0A12.937,12.937,0,0,0-7.231,12.937V19.33A12.937,12.937,0,0,0,5.706,32.267H85.265A12.937,12.937,0,0,0,98.2,19.33V12.937A12.937,12.937,0,0,0,85.265,0Z" transform="translate(595.704 343.695)" fill="#fff" />
                <Path d="M5.706,0A12.937,12.937,0,0,0-7.231,12.937V19.33A12.937,12.937,0,0,0,5.706,32.267H85.265A12.937,12.937,0,0,0,98.2,19.33V12.937A12.937,12.937,0,0,0,85.265,0Z" transform="translate(595.704 343.695)" fill="none" stroke="#c3ac2e" strokeWidth="1.808" />
                <Path d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465" transform="translate(621.457 301.643)" fill="#fff" />
                <Path d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465Z" transform="translate(621.457 301.643)" fill="none" stroke="#c3ac2e" strokeWidth="3.013" />
                <Path d="M2.8,5.59A6.338,6.338,0,0,0,9.132-.747,6.338,6.338,0,0,0,2.8-7.084,6.338,6.338,0,0,0-3.542-.747,6.338,6.338,0,0,0,2.8,5.59" transform="translate(621.173 287.806)" fill="#c3ac2e" />
                <Path d="M2.8,5.59A6.338,6.338,0,0,0,9.132-.747,6.338,6.338,0,0,0,2.8-7.084,6.338,6.338,0,0,0-3.542-.747,6.338,6.338,0,0,0,2.8,5.59" transform="translate(656.36 287.806)" fill="#c3ac2e" />
                <Path d="M2.8,0a6.339,6.339,0,0,0,0,12.678H20.39A6.339,6.339,0,0,0,20.39,0Z" transform="translate(629.597 307.667)" fill="none" stroke="#c3ac2e" strokeWidth="3.013" />
                <Text transform="translate(605.591 358.792)" fill="#c3ac2e" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="7">ANXIOUS</TSpan></Text>
            </G>
            <G {...onPressScared} transform="translate(-21.661 -82.401)">
                <Path d="M0,1.361,341.154-1.725,182,280.492S5.868,190.994,0,1.361" transform="translate(21.661 517.913)" fill="#efd9c2" />
                <Path d="M0,1.117,279.837-1.415,149.285,230.078S4.813,156.665,0,1.117" transform="translate(82.934 517.869)" fill="#e5c09a" />
                <Path d="M0,.881l220.583-2L117.674,181.36S3.795,123.493,0,.881" transform="translate(142.144 517.828)" fill="#dba772" />
                <Path d="M0,.641,160.572-.812,85.66,132.02S2.762,89.895,0,.641" transform="translate(202.112 517.787)" fill="#d39454" />
                <Path d="M0,.406l101.687-.92L54.246,83.605S1.75,56.929,0,.406" transform="translate(260.954 517.747)" fill="#cc8236" />
                <Text transform="translate(258.612 544.637)" fill="#fff" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Terrified</TSpan></Text>
                <Text transform="translate(108.785 636.634)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Nervous</TSpan></Text>
                <Text transform="translate(211.141 579.557)" fill="#fff" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Afraid</TSpan></Text>
                <Text transform="translate(64.806 530.353)" fill="#42454a" fontSize="8" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">©2022 Decida Group Pty. Ltd</TSpan></Text>
                <Text transform="translate(53.933 665.183)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Hesitant</TSpan></Text>
                <Text transform="translate(144.425 608.105)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Threatened</TSpan></Text>
                <Path d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465" transform="translate(63.846 741.361)" fill="#fff" />
                <Path d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465Z" transform="translate(63.846 741.361)" fill="none" stroke="#cc8236" strokeWidth="3.013" />
                <Path d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211" transform="translate(66.859 725.078)" fill="#cc8236" />
                <Path d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211" transform="translate(102.046 725.078)" fill="#cc8236" />
                <Path d="M8.529,0S2.135,6.908-10.808,11.288" transform="translate(67.064 710.94)" fill="none" stroke="#cc8236" strokeWidth="3.013" />
                <Path d="M.58,0S-3.343,3.811,2.359,5.1" transform="translate(54.82 719.681)" fill="#cc8236" />
                <Path d="M1.748,1.743S3.423-3.465-2.215-1.924" transform="translate(75.808 711.005)" fill="#cc8236" />
                <Path d="M0,0S6.394,6.908,19.337,11.288" transform="translate(98.391 710.94)" fill="none" stroke="#cc8236" strokeWidth="3.013" />
                <Path d="M.784,0S4.708,3.811-.994,5.1" transform="translate(117.798 719.681)" fill="#cc8236" />
                <Path d="M.1,1.743S-1.572-3.465,4.066-1.924" transform="translate(96.325 711.005)" fill="#cc8236" />
                <Path d="M0,1.139S.776-1.443,5.174-1.443s4.917,3.616,10.091,3.616,4.917-3.616,9.314-3.616S29.753,2.173,34.67,2.173s4.657-3.616,9.314-3.616,5.045,2.582,5.691,2.582" transform="translate(62.473 744.242)" fill="none" stroke="#cc8236" strokeWidth="3.013" />
                <Path d="M0,0S.587,5.439,5.113,1.739" transform="translate(59.893 744.606)" fill="#cc8236" />
                <Path d="M.941.936c1.34.474,2.567.462,2.741-.029S2.909-.365,1.569-.839-1-1.3-1.172-.81-.4.462.941.936" transform="translate(61.302 745.402)" fill="#cc8236" />
                <Path d="M.275,1.231a1.712,1.712,0,0,0,2.392.2A1.711,1.711,0,0,0,2.429-.961a1.712,1.712,0,0,0-2.392-.2A1.711,1.711,0,0,0,.275,1.231" transform="translate(111.249 744.848)" fill="#cc8236" />
                <Path d="M2.255,0S1.668,5.439-2.858,1.739" transform="translate(112.852 745.43)" fill="#cc8236" />
                <Path d="M.7,0l-1.6,1L.52,3.258l1.6-1Z" transform="translate(112.991 743.207)" fill="#cc8236" />
                <Path d="M4.727,0a10.717,10.717,0,0,0,0,21.434h84A10.717,10.717,0,0,0,88.725,0Z" transform="translate(36.853 783.279)" fill="#fff" />
                <Text transform="translate(47.947 798.912)" fill="#cc8236" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">SCARED</TSpan></Text>
                <Path d="M4.727,0a10.717,10.717,0,0,0,0,21.434h84A10.717,10.717,0,0,0,88.725,0Z" transform="translate(36.853 783.279)" fill="none" stroke="#cc8236" strokeWidth="1.808" />
                <Path d="M5.706,0A12.937,12.937,0,0,0-7.231,12.937V19.33A12.937,12.937,0,0,0,5.706,32.267H85.265A12.937,12.937,0,0,0,98.2,19.33V12.937A12.937,12.937,0,0,0,85.265,0Z" transform="translate(38.824 782.302)" fill="#fff" />
                <Path d="M5.706,0A12.937,12.937,0,0,0-7.231,12.937V19.33A12.937,12.937,0,0,0,5.706,32.267H85.265A12.937,12.937,0,0,0,98.2,19.33V12.937A12.937,12.937,0,0,0,85.265,0Z" transform="translate(38.824 782.302)" fill="none" stroke="#cc8236" strokeWidth="1.808" />
                <Text transform="translate(48.084 797.399)" fill="#cc8236" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.04em"><TSpan x="4" y="7">SCARED</TSpan></Text>
            </G>
            <G {...onPressAngry} transform="translate(-21.661 -82.401)">
                <Path d="M76.076,130.9-96.405-163.457l323.994-2.424S237.628,31.426,76.076,130.9" transform="translate(458.979 682.067)" fill="#f6bbbd" />
                <Path d="M62.4,107.373-79.077-134.078l265.76-1.988S194.919,25.776,62.4,107.373" transform="translate(441.649 652.52)" fill="#f18e92" />
                <Path d="M49.189,84.638-62.333-105.688l209.487-1.567S153.647,20.319,49.189,84.638" transform="translate(424.904 623.968)" fill="#ec6167" />
                <Path d="M35.807,61.612-45.375-76.935l152.494-1.141s4.727,92.868-71.313,139.688" transform="translate(407.945 595.05)" fill="#e83f46" />
                <Path d="M22.676,39.018-28.735-48.721l96.572-.723S70.83,9.366,22.676,39.018" transform="translate(391.304 566.675)" fill="#e31e25" />
                <Text transform="translate(381.604 547.171)" fill="#fff" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Furious</TSpan></Text>
                <Text transform="translate(591.593 662.264)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Annoyed</TSpan></Text>
                <Text transform="translate(478.866 604.718)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Frustrated</TSpan></Text>
                <Text transform="translate(441.209 575.937)" fill="#fff" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Fuming</TSpan></Text>
                <Text transform="translate(540.71 633.482)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Irritated</TSpan></Text>
                <Path d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465" transform="translate(621.457 741.874)" fill="#fff" />
                <Path d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465Z" transform="translate(621.457 741.874)" fill="none" stroke="#e31e25" strokeWidth="3.013" />
                <Path d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211" transform="translate(616.924 726.668)" fill="#ff2d16" />
                <Path d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211" transform="translate(652.111 726.668)" fill="#ff2d16" />
                <Path d="M0,4.536s17.981-22.781,42.69-.53" transform="translate(616.087 750.124)" fill="none" stroke="#ff2d16" strokeWidth="3.013" />
                <Path d="M.06,0s-1.308,5.312,4.21,3.381" transform="translate(613.938 752.939)" fill="#ff2d16" />
                <Path d="M0,1.776S5.235,3.361,3.6-2.251" transform="translate(656.966 754.347)" fill="#ff2d16" />
                <Path d="M0,0,15.648,6.468" transform="translate(612.207 709.973)" fill="none" stroke="#ff2d16" strokeWidth="3.013" />
                <Path d="M1.4,0S-4.065.254-.648,5" transform="translate(611.857 707.482)" fill="#ff2d16" />
                <Path d="M0,2.2s5.464-.254,2.047-5" transform="translate(626.825 716.728)" fill="#ff2d16" />
                <Path d="M0,2.853,15.648-3.615" transform="translate(647.383 713.589)" fill="none" stroke="#ff2d16" strokeWidth="3.013" />
                <Path d="M1.4,2.2s-5.464-.254-2.047-5" transform="translate(647.034 716.728)" fill="#ff2d16" />
                <Path d="M0,0S5.464.254,2.047,5" transform="translate(662.001 707.482)" fill="#ff2d16" />
                <Text transform="translate(610.336 798.912)" fill="#e31e25" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">ANGRY</TSpan></Text>
                <Path d="M5.706,0A12.937,12.937,0,0,0-7.231,12.937V19.33A12.937,12.937,0,0,0,5.706,32.267H85.265A12.937,12.937,0,0,0,98.2,19.33V12.937A12.937,12.937,0,0,0,85.265,0Z" transform="translate(593.8 783.729)" fill="#fff" />
                <Path d="M5.706,0A12.937,12.937,0,0,0-7.231,12.937V19.33A12.937,12.937,0,0,0,5.706,32.267H85.265A12.937,12.937,0,0,0,98.2,19.33V12.937A12.937,12.937,0,0,0,85.265,0Z" transform="translate(593.8 783.729)" fill="none" stroke="#e31e25" strokeWidth="1.808" />
                <Text transform="translate(607.839 798.826)" fill="#e31e25" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.04em"><TSpan x="4" y="7">ANGRY</TSpan></Text>
            </G>
            <G {...onPressSad} transform="translate(-21.661 -82.401)">
                <Rect width="90" height="4" transform="translate(339 937.412)" fill="#f7f7f7" />
                <Path d="M0,130.8,168.68-165.752l164.1,279.375S166.925,220.971,0,130.8" transform="translate(194.256 681.939)" fill="#c2daee" />
                <Path d="M0,107.289,138.362-135.96,272.965,93.2S136.924,181.254,0,107.289" transform="translate(224.508 652.415)" fill="#9ac2e2" />
                <Path d="M0,84.572,109.065-107.172l106.1,180.639S107.931,142.876,0,84.572" transform="translate(253.741 623.884)" fill="#72aad7" />
                <Path d="M0,61.564,79.394-78.015l77.235,131.5S78.568,104.005,0,61.564" transform="translate(283.348 594.99)" fill="#5498cf" />
                <Path d="M0,38.988,50.278-49.406,99.191,33.869S49.756,65.866,0,38.988" transform="translate(312.399 566.637)" fill="#3685c6" />
                <Text transform="translate(326.861 604.336)" fill="#fff" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Despair</TSpan></Text>
                <Text transform="translate(291.075 824.26)" fill="#42454a" fontSize="22" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Disappointed</TSpan></Text>
                <Text transform="translate(313.418 712.917)" fill="#42454a" fontSize="22" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Hopeless</TSpan></Text>
                <Text transform="translate(323.691 654.313)" fill="#fff" fontSize="18" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Grieving</TSpan></Text>
                <Text transform="translate(300.88 770.489)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Discouraged</TSpan></Text>
                <Path d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465" transform="translate(342.834 888.896)" fill="#fff" />
                <Path d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465Z" transform="translate(342.834 888.896)" fill="none" stroke="#3685c6" strokeWidth="3.013" />
                <Path d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211" transform="translate(339.38 880.159)" fill="#3685c6" />
                <Path d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211" transform="translate(374.567 880.159)" fill="#3685c6" />
                <Path d="M0,4.536s17.981-22.781,42.69-.53" transform="translate(338.543 903.614)" fill="none" stroke="#3685c6" strokeWidth="3.013" />
                <Path d="M.06,0s-1.308,5.312,4.21,3.381" transform="translate(336.394 906.43)" fill="#3685c6" />
                <Path d="M0,1.776S5.235,3.361,3.6-2.251" transform="translate(379.422 907.838)" fill="#3685c6" />
                <Path d="M4.727,0a10.717,10.717,0,0,0,0,21.434h84A10.717,10.717,0,0,0,88.725,0Z" transform="translate(315.842 930.302)" fill="#fff" />
                <Text transform="translate(343.72 945.935)" fill="#3685c6" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.033em"><TSpan x="0" y="0">SAD</TSpan></Text>
                <Path d="M4.727,0a10.717,10.717,0,0,0,0,21.434h84A10.717,10.717,0,0,0,88.725,0Z" transform="translate(315.842 930.302)" fill="none" stroke="#3685c6" strokeWidth="1.808" />
                <Path d="M5.706,0A12.937,12.937,0,0,0-7.231,12.937V19.33A12.937,12.937,0,0,0,5.706,32.267H85.265A12.937,12.937,0,0,0,98.2,19.33V12.937A12.937,12.937,0,0,0,85.265,0Z" transform="translate(317.082 929.02)" fill="#fff" />
                <Path d="M5.706,0A12.937,12.937,0,0,0-7.231,12.937V19.33A12.937,12.937,0,0,0,5.706,32.267H85.265A12.937,12.937,0,0,0,98.2,19.33V12.937A12.937,12.937,0,0,0,85.265,0Z" transform="translate(317.082 929.02)" fill="none" stroke="#3685c6" strokeWidth="1.808" />
                <Text transform="translate(343.127 944.117)" fill="#3685c6" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.033em"><TSpan x="0" y="7">SAD</TSpan></Text>
            </G>
            <G {...onPressExcited} transform="translate(-21.661 -82.401)">
                <Path d="M66.882,0,239.363,294.358l-323.994,2.424S-94.671,99.475,66.882,0" transform="translate(122.304 222.383)" fill="#c3ddd7" />
                <Path d="M54.861,0,196.34,241.451l-265.76,1.988S-77.656,81.6,54.861,0" transform="translate(165.489 275.458)" fill="#9bc6bd" />
                <Path d="M43.244,0,154.766,190.325l-209.487,1.567S-61.214,64.319,43.244,0" transform="translate(207.219 326.746)" fill="#73b0a3" />
                <Path d="M31.479,0l81.182,138.546-152.494,1.141S-44.56,46.82,31.479,0" transform="translate(249.482 378.689)" fill="#549f8f" />
                <Path d="M19.935,0,71.346,87.739l-96.572.723S-28.219,29.651,19.935,0" transform="translate(290.953 429.658)" fill="#378f7b" />
                <Text transform="translate(108.486 430.354)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Eager</TSpan></Text>
                <Text transform="translate(275.369 512.777)" fill="#fff" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Hyper</TSpan></Text>
                <Text transform="translate(148.096 458.427)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Energetic</TSpan></Text>
                <Text transform="translate(195.305 486.497)" fill="#fff" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Pumped</TSpan></Text>
                <Text transform="translate(31.017 402.288)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Expectant</TSpan></Text>
                <Path d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465" transform="translate(63.846 301.643)" fill="#fff" />
                <Path d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465Z" transform="translate(63.846 301.643)" fill="none" stroke="#378f7b" strokeWidth="3.013" />
                <Path d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211" transform="translate(62.547 284.281)" fill="#378f7b" />
                <Path d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211" transform="translate(97.734 284.281)" fill="#378f7b" />
                <Path d="M0,0S18.179,22.623,42.694.157" transform="translate(61.664 306.953)" fill="none" stroke="#378f7b" strokeWidth="3.013" />
                <Path d="M.065,1.686s-1.354-5.3,4.179-3.419" transform="translate(59.524 307.005)" fill="#378f7b" />
                <Path d="M0,.1s5.222-1.63,3.632,4" transform="translate(102.528 305.036)" fill="#378f7b" />
                <Path d="M8.529,0S2.135,6.908-10.808,11.288" transform="translate(63.651 261.184)" fill="none" stroke="#378f7b" strokeWidth="3.013" />
                <Path d="M.58,0S-3.343,3.811,2.359,5.1" transform="translate(51.407 269.925)" fill="#378f7b" />
                <Path d="M1.748,1.743S3.423-3.465-2.215-1.924" transform="translate(72.395 261.249)" fill="#378f7b" />
                <Path d="M0,0S6.394,6.908,19.337,11.288" transform="translate(94.978 261.184)" fill="none" stroke="#378f7b" strokeWidth="3.013" />
                <Path d="M.784,0S4.708,3.811-.994,5.1" transform="translate(114.385 269.925)" fill="#378f7b" />
                <Path d="M.1,1.743S-1.572-3.465,4.066-1.924" transform="translate(92.912 261.249)" fill="#378f7b" />
                <Path d="M0,0H43.467" transform="translate(61.219 307.025)" fill="none" stroke="#378f7b" strokeWidth="2.41" />
                <Path d="M4.727,0a10.717,10.717,0,0,0,0,21.434h84A10.717,10.717,0,0,0,88.725,0Z" transform="translate(36.853 342.23)" fill="#fff" />
                <Text transform="translate(47.195 357.863)" fill="#378f7b" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">EXCITED</TSpan></Text>
                <Path d="M4.727,0a10.717,10.717,0,0,0,0,21.434h84A10.717,10.717,0,0,0,88.725,0Z" transform="translate(36.853 342.23)" fill="none" stroke="#378f7b" strokeWidth="1.808" />
                <Path d="M5.706,0A12.937,12.937,0,0,0-7.231,12.937V19.33A12.937,12.937,0,0,0,5.706,32.267H85.265A12.937,12.937,0,0,0,98.2,19.33V12.937A12.937,12.937,0,0,0,85.265,0Z" transform="translate(38.093 340.843)" fill="#fff" />
                <Path d="M5.706,0A12.937,12.937,0,0,0-7.231,12.937V19.33A12.937,12.937,0,0,0,5.706,32.267H85.265A12.937,12.937,0,0,0,98.2,19.33V12.937A12.937,12.937,0,0,0,85.265,0Z" transform="translate(38.093 340.843)" fill="none" stroke="#378f7b" strokeWidth="1.808" />
                <Text transform="translate(46.602 355.941)" fill="#378f7b" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="7">EXCITED</TSpan></Text>
            </G>
            <G {...onPressHappy} transform="translate(-21.661 -82.401)">
                <Path d="M146.777,15.906-21.9,312.457-186,33.081S-20.148-74.266,146.777,15.906" transform="translate(384.101 206.708)" fill="#bed4c3" />
                <Path d="M120.4,13.047-17.966,256.3l-134.6-229.16S-16.527-60.918,120.4,13.047" transform="translate(380.23 262.6)" fill="#94b89c" />
                <Path d="M94.9,10.284-14.162,202.029-120.264,21.39S-13.027-48.02,94.9,10.284" transform="translate(376.491 316.61)" fill="#699d74" />
                <Path d="M69.084,7.486-10.31,147.065-87.545,15.57S-9.484-34.955,69.084,7.486" transform="translate(372.703 371.312)" fill="#498756" />
                <Path d="M43.75,4.741-6.528,93.135-55.441,9.86s49.435-32,99.191-5.119" transform="translate(369.436 424.098)" fill="#2a7339" />
                <Text transform="translate(323.501 233.331)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Peaceful</TSpan></Text>
                <Text transform="translate(327.165 441.428)" fill="#fff" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Ecstatic</TSpan></Text>
                <Text transform="translate(315.025 280.313)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Optimistic</TSpan></Text>
                <Text transform="translate(323.238 335.475)" fill="#42454a" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Cheerful</TSpan></Text>
                <Text transform="translate(308.224 392.104)" fill="#fff" fontSize="19" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700"><TSpan x="0" y="0">Sensational</TSpan></Text>
                <Path d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465" transform="translate(346.099 133.918)" fill="#fff" />
                <Path d="M19.733,39.465A44.739,44.739,0,0,0,64.472-5.274,44.738,44.738,0,0,0,19.733-50.011,44.738,44.738,0,0,0-25.006-5.274,44.739,44.739,0,0,0,19.733,39.465Z" transform="translate(346.099 133.918)" fill="none" stroke="#2a7339" strokeWidth="3.013" />
                <Path d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211" transform="translate(344.8 116.557)" fill="#2a7339" />
                <Path d="M3.106,6.211A7.042,7.042,0,0,0,10.148-.831a7.041,7.041,0,0,0-7.042-7.04A7.041,7.041,0,0,0-3.936-.831,7.042,7.042,0,0,0,3.106,6.211" transform="translate(379.987 116.557)" fill="#2a7339" />
                <Path d="M0,0S17.982,22.781,42.69.53" transform="translate(343.963 139.044)" fill="none" stroke="#2a7339" strokeWidth="3.013" />
                <Path d="M.06,1.678S-1.248-3.634,4.269-1.7" transform="translate(341.814 139.086)" fill="#2a7339" />
                <Path d="M0,.091S5.235-1.494,3.6,4.118" transform="translate(384.842 137.488)" fill="#2a7339" />
                <Path d="M4.727,0a10.717,10.717,0,0,0,0,21.434h84A10.717,10.717,0,0,0,88.725,0Z" transform="translate(319.106 175.405)" fill="#fff" />
                <Text transform="translate(335.649 191.038)" fill="#2a7339" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.04em"><TSpan x="0" y="0">HAPPY</TSpan></Text>
                <Path d="M4.727,0a10.717,10.717,0,0,0,0,21.434h84A10.717,10.717,0,0,0,88.725,0Z" transform="translate(319.106 175.405)" fill="none" stroke="#2a7339" strokeWidth="1.808" />
                <Path d="M5.706,0A12.937,12.937,0,0,0-7.231,12.937V19.33A12.937,12.937,0,0,0,5.706,32.267H85.265A12.937,12.937,0,0,0,98.2,19.33V12.937A12.937,12.937,0,0,0,85.265,0Z" transform="translate(320.46 174.093)" fill="#fff" />
                <Path d="M5.706,0A12.937,12.937,0,0,0-7.231,12.937V19.33A12.937,12.937,0,0,0,5.706,32.267H85.265A12.937,12.937,0,0,0,98.2,19.33V12.937A12.937,12.937,0,0,0,85.265,0Z" transform="translate(320.46 174.093)" fill="none" stroke="#2a7339" strokeWidth="1.808" />
                <Text transform="translate(335.17 189.189)" fill="#2a7339" fontSize="15" fontFamily="Helvetica Neue,Roboto,calibri" fontWeight="700" letterSpacing="0.04em"><TSpan x="3" y="7">HAPPY</TSpan></Text>
            </G>
        </Svg >
    )
}