import { ScrollView, StyleSheet, View } from 'react-native'
import { DefaultFontSize } from '../common/const'
import DefaultModal from '../common/default-modal'
import { DefaultText } from '../common/default-text'
import { useResponsive } from '../common/use-responsive'
import ViewZoomableForIphone from '../common/view-zoomable'
import { IndividualStudentStatsImage } from './assets/individual-student-stats-image'
import { rvTeacherWellbeingInfoModal } from './teacher-state'

const TeacherWellbeingInfoModal = () => {
    const toggleModal = () => rvTeacherWellbeingInfoModal(false)
    const { responsiveFontSize, isNarrowScreen } = useResponsive()

    return (
        <DefaultModal onClose={toggleModal} contentStyle={{ ...styles.contentContainer, padding: isNarrowScreen ? 30 : 60 }}>
            <ScrollView>
                <DefaultText style={[styles.headerText, { fontSize: responsiveFontSize(DefaultFontSize + 2) }]}>
                    Wellbeing question
                </DefaultText>
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                    When selected a randomised wellbeing related question will be added at the end of the check-in question set focused on three areas, connectedness, psychological safety and social identity.  Example questions include:
                </DefaultText>

                <View style={{ marginVertical: 20 }}>
                    <DefaultText style={styles.mainText}>
                        Connectedness
                    </DefaultText>
                    <DefaultText style={styles.subText}>
                        - Do you have friends or people that you like to talk to in your class?
                    </DefaultText>
                    <DefaultText style={styles.subText}>
                        - How supported do you feel by the adults at your school?
                    </DefaultText>

                    <DefaultText style={styles.mainText}>
                        Psychological safety
                    </DefaultText>
                    <DefaultText style={styles.subText}>
                        - How easy is it for you to ask for help when you need it?
                    </DefaultText>
                    <DefaultText style={styles.subText}>
                        - How safe and comfortable do you feel at school?
                    </DefaultText>

                    <DefaultText style={styles.mainText}>
                        Social identity
                    </DefaultText>
                    <DefaultText style={styles.subText}>
                        - How often do you feel proud of something you've done?
                    </DefaultText>
                    <DefaultText style={styles.subText}>
                        -  How calm do you feel?
                    </DefaultText>

                </View>

                <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                    The same randomised question will be presented to classes or groups set to level 1, to allow the educator to better support the group to complete the question, but will be individually randomised for all students set at a higher emotion complexity level.
                    {"\n"}
                    Data collected will be collated, deidentified and provided to the relevant school administrator or coordinator via a monthly report.
                </DefaultText>

            </ScrollView>
        </DefaultModal>
    )
}
export default TeacherWellbeingInfoModal

const styles = StyleSheet.create({
    contentContainer: {
        padding: 60,
        maxHeight: 800,
    },
    text: {
        textAlign: 'justify',
    },
    headerText: {
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: 20
    },
    spacing: {
        marginVertical: 5,
    },
    mainText: {
        marginLeft: 10,
        fontWeight: 'bold'
    },
    subText: {
        marginLeft: 40
    }
})  
