import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { IntroductionToHappyLessonPlan1 } from './src/introduction-to-happy-lesson-plan1'
import { IntroductionToHappyLessonPlan2 } from './src/introduction-to-happy-lesson-plan2'
import { Worksheets } from '../../common/worksheets-imports'
import { ToolsTheLittleBookOfBigEmotions } from '../tools/cards/the-little-book-of-big-emotions'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

const introductionHappyVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/1Y5R8K7OKSo",
    title: ""
}

export const SwitchesLessonPlansIntroductionToHappy: CardTemplate = {
    cardId: "4e78c5d6-158d-4cb3-bb05-446bc939f661",
    title: 'Exploring happy',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define happiness and understand its importance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify activities and behaviours that contribute to their own happiness.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>
                        
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Log into the Switch4Schools program and select an appropriate meme of the day to share, for example:</DefaultText>
                        <View style={styles.imageContainer}>
                            <IntroductionToHappyLessonPlan1 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Now that you’ve got their attention, ask students to think about a time when they felt really happy. What were they doing? Who were they with? How did they feel? </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Instruction (5 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to The Little Book of Big Emotions to introduce the concept of happiness. Define happiness as a feeling of contentment, satisfaction, or joy. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the importance of happiness. Explain that happiness is important because it can improve our physical and mental health, and it can also make us more productive and successful in our personal and academic lives. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Use the supplied slides, or draw a three column table on the board, and ask students to contribute what they see, feel (in the body), or do (behave) when they or someone else is happy. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Little Book of Big Emotion to explain that there are different levels of happiness and words we can use to describe those levels</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide students into small groups. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have each group come up with a list of activities or behaviours that contribute to their own happiness. These could include things like spending time with friends, engaging in hobbies, getting enough sleep, or practicing gratitude.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them choose their favourites and write or draw these down on index cards or sticky notes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them stick their index cards or sticky notes on a wall or board, grouping ideas together to create a "happiness map" of all the different things that contribute to the students' happiness.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (20 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give each student the “Things that make me happy” worksheet.</DefaultText>
                        <View style={styles.imageContainer}>
                            <IntroductionToHappyLessonPlan2 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them consider all the ideas on the board and select three activities or behaviours that contributes to their happiness to add to their worksheet.  And draw what they look like when they are happy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Advise students that you will be exploring different switches over the coming weeks to practice when feeling happy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If time permits (or it could also be a homework activity) and student’s have access to the Switch4Schools program on a device encourage them to explore the available switches assigned to happy on the emotion wheel.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the different activities and behaviours that contribute to happiness, and how it’s the body’s way to regulate other strong emotions. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of happiness and encourage students to use the happiness map as a reference when they are looking for ways to boost their happiness. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Introducing Happiness Slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/S4S+Introduction+to+Happy+Level+3.pdf' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='The Little Book of Big Emotions' link={ToolsTheLittleBookOfBigEmotions.openUrl || ""} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencil</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Index cards or sticky notes </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='What makes me happy worksheet' link={Worksheets.whatMakesYouHappy.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What Is Happiness and Why Is It Important? (+ Definition) </DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/what-is-happiness/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>100 Kids Tell Us What Makes Them Happy | 100 Kids | HiHo Kids</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=1Y5R8K7OKSo' />
                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={introductionHappyVideo} />
                        </View>

                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 10
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        flexDirection: 'row',
        marginHorizontal: 20,
        justifyContent: 'center',
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        marginVertical: 10,
    },
    noIndent: {
        paddingLeft: 0,
    },
})
