import { View, StyleSheet } from "react-native";
import { DefaultText } from "../../common/default-text";
import { DecidaColors } from "../../../common/decida-colors";

interface Props {
    textdata: string | undefined
}

export const DefaultTextWithoutArrowsBoldLeftAligned = ({ textdata }: Props) => {

    return (
        <View style={styles.content}>
            <DefaultText style={styles.text}>
                {textdata}
            </DefaultText>
        </View>
    )

}

const styles = StyleSheet.create({
    icon: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 5,
    },
    content: {
        paddingVertical: 10,
    },
    text: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: 26,
        color: DecidaColors.Gray,
    }
})
