import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { Class, GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables, Teacher } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { getTeacherByCognitoUsername } from '../../common/graphql/queries'
import { rvCurrentClass, rvTeacherAbsenceMode } from '../common/common-state'
import { DefaultText } from '../common/default-text'
import { CLIENT_EVENT, createClientLog } from '../common/log-client-event'
import { ScreenNames } from '../common/screen-names'
import { showAbsenceWarnAlert } from '../common/universal-alert'
import { IconClassAdmin } from '../icon/icon-class-admin'
import { IconGraph } from '../icon/icon-graph'
import { IconHeaderCheckIn } from '../icon/icon-header-check-in'
import { getLockDerviceRv, getLockDeviceRvClassID, rvCurrentUser } from '../login/login-state'
import { rvSwitchesScreenState } from '../switches/common/current-screen-state'
import { SwitchesScreens } from '../switches/navigation/switches-bottom-navigation'
import HeaderIconCheckIn from '../common/header-icon-check-in'

type Props = {
    clazz: Class,
    isClassHeader?: boolean
}
const TeacherClassNavigationOption = ({ clazz, isClassHeader }: Props) => {
    const { navigate, push } = useNavigation<StackNavigationProp<{ [K: string]: any }>>()

    const user = useReactiveVar(rvCurrentUser)
    const denyAccess = useReactiveVar(getLockDerviceRv())
    const lockDeviceClassID = useReactiveVar(getLockDeviceRvClassID())

    const { data: teacherResponse } = useQuery<GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables>(gql`${getTeacherByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
    const currentTeacher = teacherResponse?.getTeacherByCognitoUsername?.items[0] as Teacher
    const route = useRoute()
    const isTeacherWellbeing = route.name === ScreenNames.TeacherWellbeing
    const absenceMode = useReactiveVar(rvTeacherAbsenceMode)

    const headerLog = (name: string,) => {
        createClientLog({
            area: "TeacherClassNavigationOption",
            action: `Clicking ${name} header, from ${route.name}`,
            event: CLIENT_EVENT.HEADER_ACTION_EVENT,
            payload: {
                group: "teacher",
                nickname: currentTeacher?.cognitoUsername,
            }
        })
    }


    const navigateToClassAdmin = () => {
        headerLog("Admin")
        navigate(ScreenNames.TeacherClassMain, {
            screen: ScreenNames.TeacherClassAdmin,
            params: { classID: clazz.id }
        })
        rvSwitchesScreenState(SwitchesScreens.HOME)
        rvCurrentClass(clazz)
        if (push) {
            push(ScreenNames.TeacherClassAdmin)
        }
    }

    const navigateToStats = () => {
        headerLog("Stats")
        navigate(ScreenNames.TeacherClassMain, {
            screen: ScreenNames.TeacherStats,
            params: { classID: clazz.id },
        })
        rvSwitchesScreenState(SwitchesScreens.HOME)
        rvCurrentClass(clazz)
        if (push) {
            push(ScreenNames.TeacherStats)
        }
    }

    const navigateToCheckIn = () => {
        
        if (isTeacherWellbeing) {
            navigateToTeacherCheckIn()
        } else {
            navigateToStudentCheckIn()
        }
    }

    const navigateToStudentCheckIn = () => {
        headerLog("Check-in student")
        navigate(ScreenNames.TeacherClassMain, {
            screen: ScreenNames.CheckInStart,
            params: {
                classID: denyAccess ? lockDeviceClassID || '' : clazz.id,
            }
        })
        rvSwitchesScreenState(SwitchesScreens.HOME)
        rvCurrentClass(clazz)
        if (push) {
            push(ScreenNames.CheckInStart)
        }
    }

    const navigateToTeacherCheckIn = () => {
        headerLog("Check-in teacher")
        navigate(ScreenNames.TeacherWellbeing)
        rvSwitchesScreenState(SwitchesScreens.HOME)
    }

    const navigateWithAbsenceModeWarning = (type: string) => () => {

        let next: () => void
        switch (type) {
            case ScreenNames.TeacherClassAdmin:
                next = navigateToClassAdmin
                break;
            case ScreenNames.TeacherStats:
                next = navigateToStats
                break;

            default:
                next = () => {
                    console.log("Missing absence validation type")
                }
                break;
        }
        if (absenceMode) {
            showAbsenceWarnAlert({
                onLeftButtonPress: async () => {
                    rvTeacherAbsenceMode(false)
                    next()
                }
            })
            return
        }

        next()
    }

    return (
        <View style={[styles.container, { alignSelf: isClassHeader ? "flex-end" : 'flex-start' }]}>
            {!denyAccess && (
                <>
                    <TouchableOpacity testID='header-profile-admin' onPress={navigateWithAbsenceModeWarning(ScreenNames.TeacherClassAdmin)}>
                        <View style={styles.icon}>
                            <IconClassAdmin stylefill={isClassHeader ? DecidaColors.White : DecidaColors.Green} />
                            <DefaultText style={[styles.iconText, { color: isClassHeader ? DecidaColors.White : DecidaColors.Gray }]}>Admin</DefaultText>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={navigateWithAbsenceModeWarning(ScreenNames.TeacherStats)}>
                        <View style={styles.icon}>
                            <IconGraph color={isClassHeader ? DecidaColors.White : DecidaColors.Green} />
                            <DefaultText style={[styles.iconText, { color: isClassHeader ? DecidaColors.White : DecidaColors.Gray }]}>Stats</DefaultText>
                        </View>
                    </TouchableOpacity>
                </>
            )}

            <HeaderIconCheckIn onPress={navigateToCheckIn} isClassHeader={isClassHeader} />

        </View>
    )
}
export default TeacherClassNavigationOption


const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    icon: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconText: {
        marginTop: 5,
        fontSize: 15,
        color: DecidaColors.Gray,
    },
})