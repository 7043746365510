import { makeVar } from "@apollo/client";
import { SchoolAdminUI, Teacher } from "../../common/API";
import { SchoolAdminFormTab, SchoolAdminHierarchyCurrentListView } from "./school-admin-types";

export const rvSidebarClassID = makeVar("")
export const rvCurrentSchoolID = makeVar<string | null>(null)
export const rvSelectedCampusID = makeVar<string | null>(null)
export const rvSelectedCampusClassGroupsID = makeVar<string | null>(null)
export const rvShowTeacherForm = makeVar(false)
export const rvIsSchoolWithCampus = makeVar(false)
export const rvScholAdminUITeacherFormTab = makeVar<SchoolAdminFormTab>(SchoolAdminFormTab.SearchExisting)
export const rvSchoolAdminSidebarCurrentTeacher = makeVar<Teacher | null>(null)
export const rvSchoolAdminCampusCurrentListView = makeVar<SchoolAdminHierarchyCurrentListView>(SchoolAdminHierarchyCurrentListView.Campus)