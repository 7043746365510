import { gql, useQuery } from '@apollo/client'
import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { useEffect, useState } from 'react'
import { ListSchoolsQuery, ListSchoolsQueryVariables, School } from '../../common/API'
import ActiveSchoolStudentsCount from '../common/active-school-students-count'
import { List } from '../common/list'
import { Loading } from '../common/loading'
import { Page } from '../common/page'
import { RollingWeekCheckInCount } from '../common/rolling-week-check-in-count'
import { ScreenNames } from '../common/screen-names'
import { AdminStackNavigatorParamList } from './admin-route-param-types'
import { listSchoolsQuery } from './graphql-scripts'
import AdminSchoolToolbar, { SortSchoolsBy } from './admin-schools-toolbar'

export const AdminSchools = () => {
    const { data, loading } = useQuery<ListSchoolsQuery, ListSchoolsQueryVariables>(gql`${listSchoolsQuery}`, { variables: { filter: { _deleted: { ne: true } }, limit: 5000 }, fetchPolicy: 'cache-and-network' })
    const { navigate } = useNavigation<StackNavigationProp<AdminStackNavigatorParamList>>()
    const [currentSchools, setCurrentSchools] = useState<School[]>([])
    const schools = data?.listSchools?.items as School[]
    const [sortSchoolsBy, setSortSchoolsBy] = useState<SortSchoolsBy>(SortSchoolsBy.Name)

    useEffect(() => {
        setCurrentSchools(schools)
    }, [schools])

    if (loading) {
        return <Loading isLoading />
    }

    const addSchool = () => {
        navigate(ScreenNames.AdminSchool, { id: undefined })
    }

    const editSchool = (id: string) => {
        navigate(ScreenNames.AdminSchool, { id })
    }

    return (
        <Page>
            {/* <AdminGenerateReports schools={schools} /> */}
            <AdminSchoolToolbar schools={schools} setSchools={setCurrentSchools} sortBy={sortSchoolsBy} setSortBy={setSortSchoolsBy} />
            <List
                title='Schools'
                items={currentSchools}
                add={addSchool}
                edit={editSchool}
                sort={sortSchoolsBy === SortSchoolsBy.Name ?
                    [school => school.name]
                    :
                    [
                        school => {
                            if (!school.renewalDate) {
                                return '13'
                            }

                            const renewalSplit = school.renewalDate.split('/')
                            return `${renewalSplit[1].padStart(2, '0')}-${renewalSplit[0].padStart(2, '0')}`
                        },
                        school => school.name
                    ]
                }
                extraComponent={school =>
                    <>
                        <ActiveSchoolStudentsCount school={school} />
                        <RollingWeekCheckInCount rollingWeekCheckInCount={school} />
                    </>
                }
            />
        </Page>
    )
}
