import { View } from 'react-native'
import { DefaultTextWithDownArrow } from '../../../common/default-text-with-down-arrow'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { BrainTappingIcon } from '../../icons/brain-tapping-icon'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { BrainTappingIconInverted } from '../../icons/brain-tapping-icon-inverted'

export const SwitchesActivitiesSecretsForSleepBrainTapping: CardTemplate = {
    cardId: "dff45fa7-46bb-4ad6-82c6-23c90e359818",
    title: 'Brain tapping',
    Header: BrainTappingIconInverted,
    Icon: BrainTappingIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"People who respond to kinaesthetic stimulation (touch or body movement) to help slow breathing and the mind to better prepare for deep sleep."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Sit comfortably on the edge of your bed."} />
                <DefaultTextWithDownArrow textdata={"Lightly tap on you legs in a repeated left-right-left-right way, roughly at the speed of a ticking stop watch."} />
                <DefaultTextWithDownArrow textdata={"Continue to tap, close your eyes and breathe slowly. Count “1 and 2 and 3 and 4 and 1 and 2 and…” with each word corresponding to a knee tap."} />
                <DefaultTextWithDownArrow textdata={"Take the entire count of 1- 4 to breathe in, and the next set of four to breathe out. "} />
                <DefaultTextWithDownArrow textdata={"After a few minutes, steadily and gradually slow the tapping down."} />
                <DefaultTextWithDownArrow textdata={"When the tapping gets very slow, count down “4,3,2,1, sleep”."} />
                <DefaultTextWithoutArrows textdata={"Now stop tapping and counting, lay down comfortably in your bed and completely relax."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SECRETS_FOR_SLEEP,
    whyDoesItWorkText: 'This activity draws on a phenomenon in the brain called the Frequency Following Response (FFR). Your brain loves to follow basic, repeated, predictable patterns.Your brain notices that there is a pattern, it connects with that pattern, and then it begins to follow it. This is why we feel good when we nod or tap in time with music we like, dance or even march in time with others.This activity uses FFR to slow down the rhythm of you brain until you fall asleep.',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Brain+Tapping.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.SECRETS_FOR_SLEEP],
    action: [CardActionType.Thought],
    level: []
}

