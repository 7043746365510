import { useReactiveVar } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Auth } from 'aws-amplify';
import { useState } from 'react'
import { View, TextInput } from 'react-native';
import { CommonStyles } from '../common/const';
import { DefaultButton } from '../common/default-button';
import { Page } from '../common/page';
import { RootViewBackButton } from '../common/root-view-back-button';
import { ScreenNames } from '../common/screen-names';
import { showAlert } from '../common/universal-alert';
import { LoginStackNavigatorParamList } from './login-route-param-types';
import { rvAuthChallengeName, rvCurrentUser } from './login-state';
import { PasswordHintText } from './password-hint-text';
import { handleAuth } from './login-functions';

export const LoginMFASMS = () => {
    const { navigate } = useNavigation<StackNavigationProp<LoginStackNavigatorParamList>>()
    const goBack = () => {
        navigate(ScreenNames.Login)
    }

    const [mfaCode, setMfaCode] = useState("");
    const [loggingIn, setLoggingIn] = useState(false);

    const authChallengeName = useReactiveVar(rvAuthChallengeName)
    const currentUser = useReactiveVar(rvCurrentUser)
    const SMStextInfo = "An SMS has been sent to your mobile with the auth code."
    const TOTPTextInfo = "Please check your Authenticator app for the TOTP code."

    const sendMFACode = async () => {
        setLoggingIn(true)
        await Auth.confirmSignIn(
            currentUser, // Return object from Auth.signIn()
            mfaCode, // Confirmation code
            authChallengeName// MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        )
            .then(handleAuth)
            .catch(e => {
                setLoggingIn(false)
                showAlert({
                    title: "MFA Error",
                    message: String(e),
                    leftButtonText: 'Ok',
                })
            })
    }

    return (
        <RootViewBackButton onPress={goBack}>
            <Page>
                <View>
                    <PasswordHintText>{authChallengeName === "SMS_MFA" ? SMStextInfo : TOTPTextInfo}</PasswordHintText>
                    <TextInput
                        style={CommonStyles.textInput}
                        placeholder="******"
                        autoCorrect={false}
                        autoCapitalize="none"
                        keyboardType="decimal-pad"
                        textContentType="telephoneNumber"
                        onChangeText={setMfaCode}
                    />
                    <DefaultButton onPress={sendMFACode} disabled={loggingIn}>Submit</DefaultButton>
                </View>
            </Page>
        </RootViewBackButton>
    )
}

