import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { AntDesign } from '@expo/vector-icons';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { GetClassQuery, GetClassQueryVariables, GetListTeacherBySchoolIDQuery, GetListTeacherBySchoolIDQueryVariables, Teacher } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { getClass, getListTeacherBySchoolID } from '../../common/graphql/queries';
import { isDefined } from '../../common/is-defined';
import { CommonStyles } from '../common/const';
import InputDefault from '../common/input-default';
import { StepperBox } from '../common/stepper-box';
import SchoolAdminFormUpsertTeacher from './school-admin-form-upsert-teacher';
import { ResultItemType } from './school-admin-sidebar-search-result-item';
import SchoolAdminSidebarSearchTeacherOrStudentResults from './school-admin-sidebar-search-teacher-results';
import { rvSidebarClassID } from './school-admin-state';
import { SchoolAdminFormTab } from './school-admin-types';
import { schoolAdminGetClassQuery, SchoolAdminGetClassQueryResponse } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-class-query';

type Props = {
    onFinishSubmit: () => void
}

const schoolAdmiTeacherFormTab: { value: SchoolAdminFormTab, label: string }[] = Object.values(SchoolAdminFormTab).map((value) => ({
    value,
    label: value,
}));

const SchoolAdminFormTeacher = ({ onFinishSubmit }: Props) => {

    const currentClassID = useReactiveVar(rvSidebarClassID)
    const [selectedTab, setSelectedTab] = useState<SchoolAdminFormTab>(SchoolAdminFormTab.SearchExisting)
    const [teacherSearch, setTeacherSearch] = useState("");

    const { data: getClassQuery } = useQuery<SchoolAdminGetClassQueryResponse, GetClassQueryVariables>(schoolAdminGetClassQuery, { variables: { id: currentClassID } })

    const { data: listTeacherBySchoolIDQuery, refetch } = useQuery<GetListTeacherBySchoolIDQuery, GetListTeacherBySchoolIDQueryVariables>(
        gql`${getListTeacherBySchoolID}`, {
        variables: {
            schoolID: getClassQuery?.getClass?.schoolID || "",
            filter: {
                or: [
                    { email: { contains: teacherSearch } },
                    { firstName: { contains: teacherSearch } },
                    { lastName: { contains: teacherSearch } },
                    { cognitoUsername: { contains: teacherSearch } },
                ],
            },
            limit: 5000,
        },
        skip: !teacherSearch.length,
    });

    const debouncedSearch = useCallback(
        _.debounce((value: string) => {
            refetch();
        }, 500),
        []
    );
    useEffect(() => {
        if (teacherSearch.length > 0) {
            debouncedSearch(teacherSearch);
        }
    }, [teacherSearch, debouncedSearch]);

    const teachers = listTeacherBySchoolIDQuery?.getListTeacherBySchoolID?.items.filter(isDefined).filter(t => t._deleted !== true).slice(0, 10) as Teacher[]

    return (
        <View style={[styles.container, CommonStyles.innerShadow]}>
            <Pressable style={styles.absoluteTopRight} onPress={onFinishSubmit}>
                <AntDesign name='close' size={24} color={DecidaColors.Black} />
            </Pressable>
            <StepperBox
                options={schoolAdmiTeacherFormTab}
                activeIndex={selectedTab}
                onPress={setSelectedTab}
                containerStyle={{ width: '100%', paddingTop: 40 }}
                height={30}
            />

            {selectedTab === SchoolAdminFormTab.AddNew ? (
                <SchoolAdminFormUpsertTeacher />
            ) : (
                <View style={{ width: '100%', }}>
                    <View style={styles.searchContainer}>
                        <InputDefault
                            customInputStyle={styles.inputSearchTeachers}
                            placeholder="Search teachers.."
                            value={teacherSearch}
                            onChangeText={setTeacherSearch}
                        />
                    </View>
                    <SchoolAdminSidebarSearchTeacherOrStudentResults data={teachers} type={ResultItemType.TeacherSearch} />

                </View>
            )}
        </View>
    )
}
export default SchoolAdminFormTeacher


const styles = StyleSheet.create({
    absoluteTopRight: {
        position: 'absolute',
        right: 20,
        top: 20,
        zIndex: 99
    },
    input: {
        borderRadius: 0,
    },
    container: {
        width: '90%',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 20,
        borderWidth: 1,
        paddingVertical: 20,
        paddingHorizontal: 10,
        borderColor: DecidaColors.GrayBorder,
    },
    searchContainer: {
        paddingTop: 20,
        paddingBottom: 5,
        justifyContent: 'center',
        alignContent: 'center',
    },
    inputSearchTeachers: {
        borderRadius: 0,
        paddingHorizontal: 0,
        marginHorizontal: 0,
        width: '100%',
        paddingLeft: 10
    }
})  
