import _ from "lodash";
import { Class } from "../../common/API";
import { extractNumericPrefix } from "./extrat-numeric-prefix";
import { haveSimilarNameStart } from "./have-similar-name-start";

export const sortClassByGoModeAndName = (classes: Class[]) => {
    return _.orderBy(classes, [
        (clazz) => Number(clazz.goMode),
        (clazz) => {
            const numericPrefix = extractNumericPrefix(clazz.name);
            return (haveSimilarNameStart(classes) && numericPrefix !== null) ? numericPrefix : -1
        },
        (clazz) => clazz.name.toLowerCase()
    ], [
        "asc",
        "asc",
        "asc"
    ]);
}