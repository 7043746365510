import { DimensionValue, StyleSheet, TextStyle, View } from 'react-native'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { OnBoardingAndi } from '../common/svg-files/onboarding-andi'
import { OnboardingTeacherStep2Image } from '../common/svg-files/onboarding-teacher-step2-image'
import { OnboardingTeacherStep3Image } from '../common/svg-files/onboarding-teacher-step3-image'
import { OnboardingTeacherStep4Image } from '../common/svg-files/onboarding-teacher-step4-image'
import { OnboardingTeacherStep5Image } from '../common/svg-files/onboarding-teacher-step5-image'
import { OnboardingTeacherStep6Image } from '../common/svg-files/onboarding-teacher-step6-image'
import { OnboardingTeacherStep7Image } from '../common/svg-files/onboarding-teacher-step7-image'
import { DefaultTextWithoutArrows } from '../switches/common/default-text-without-arrows'
import useDeviceTypes from '../common/use-device-types'
import { BreakPoints, DefaultFontSize } from '../common/const'
import { useResponsive } from '../common/use-responsive'
import { OnBoardingAndiSmall } from '../common/svg-files/onboarding-andi-small'
import { OnboardingTeacherStep7ImageContent } from '../common/svg-files/onboarding-teacher-step7-image-content'
import { OnboardingTeacherStep8Image } from '../common/svg-files/onboarding-teacher-step8-image'
import { OnboardingTeacherStep8ImageContent } from '../common/svg-files/onboarding-teacher-step8-image-content'

type Props = {
    handleNext: () => void
}

type DefaultProps = {
    handleNext: () => void
    image: () => JSX.Element
    text: string
    textStyle?: TextStyle
    nextButtonTitle?: string
    bottomText?: string
    contentImage?: () => JSX.Element
    contentImageSize?: DimensionValue
}

const TeacherOnBoardingStep1 = ({ handleNext }: Props) => {
    return (
        <View style={styles.content}>
            <View style={styles.contentContainer}>
                <DefaultTextWithoutArrows align='left' textdata={"Hi, welcome to Switch! \n\nMy name is AnDi. I’m here to help you get started. \n\nI’m excited to show you around. \n\nBefore you get going I’ll show you a few tips."} />
                <View style={styles.buttonsContainer}>
                    <DefaultButton style={styles.nextButton} buttonTextStyle={styles.nextText} onPress={handleNext}>
                        NEXT
                    </DefaultButton>
                </View>
            </View>
            <View style={styles.contentContainer}>
                <View style={styles.imageContainer}>
                    <OnBoardingAndi />
                </View>
            </View>
        </View>
    )
}
const TeacherOnBoardingStep2 = ({ handleNext }: Props) => <TeacherOnBoardingStepDefault handleNext={handleNext} text="Here is your emotion wheel. By selecting anywhere on an emotion, you and your students can access its switches." image={OnboardingTeacherStep2Image} />
const TeacherOnBoardingStep3 = ({ handleNext }: Props) => <TeacherOnBoardingStepDefault handleNext={handleNext} text="All of the content can be found through ‘Explore’ along with a handy filter function." image={OnboardingTeacherStep3Image} />
const TeacherOnBoardingStep4 = ({ handleNext }: Props) => <TeacherOnBoardingStepDefault handleNext={handleNext} text="Find lots of other great resources including lesson plans, videos, and posters via ‘Tools’." image={OnboardingTeacherStep4Image} />
const TeacherOnBoardingStep5 = ({ handleNext }: Props) => <TeacherOnBoardingStepDefault handleNext={handleNext} text="To update your account details, or access additional help, including instructions for getting started, visit ‘Profile’." image={OnboardingTeacherStep5Image} />
const TeacherOnBoardingStep6 = ({ handleNext }: Props) => <TeacherOnBoardingStepDefault handleNext={handleNext} text="This is your dashboard. Here you can switch between classes, monitor any students who may need additional attention, access statistics, start check in sessions, and access your admin page." image={OnboardingTeacherStep6Image} bottomText='Be sure to click on the information button to find out more on the Dashboard.' />
const TeacherOnBoardingStep7 = ({ handleNext }: Props) => <TeacherOnBoardingStepDefault handleNext={handleNext} text="Access statistics through the ‘stats’ button. Here you can monitor your class’ energy and emotion levels." image={OnboardingTeacherStep7Image} contentImage={OnboardingTeacherStep7ImageContent} contentImageSize={80} />
const TeacherOnBoardingStep8 = ({ handleNext }: Props) => <TeacherOnBoardingStepDefault handleNext={handleNext} text="Use admin to change the settings for your class, manage student accounts, reset passwords, and schedule check-in sessions. You can find this page via the ‘admin’ button." image={OnboardingTeacherStep8Image} nextButtonTitle='CLOSE' contentImage={OnboardingTeacherStep8ImageContent} />

const TeacherOnBoardingStepDefault = ({ handleNext, text, image, textStyle, nextButtonTitle, bottomText, contentImage: ContentImage, contentImageSize = 100 }: DefaultProps) => {
    const { isNarrowScreen, width } = useResponsive()
    const Image = image
    const andyAvatarSmallSize = width < BreakPoints.S ? 60 : 100
    return (
        <View style={styles.content}>
            <View style={styles.contentContainerLeft}>
                <View style={styles.imageContainer}>
                    <Image />
                </View>
                <View style={styles.buttonsContainer}>
                    <DefaultButton style={styles.nextButton} buttonTextStyle={styles.nextText} onPress={handleNext}>
                        {nextButtonTitle || "NEXT"}
                    </DefaultButton>
                </View>
            </View>
            <View style={styles.contentContainer}>
                {ContentImage !== undefined && <View style={{ width: contentImageSize, height: contentImageSize }}>
                    <ContentImage />
                </View>}
                <View>
                    <DefaultText style={[styles.step4Text, textStyle, { fontSize: isNarrowScreen ? 16 : DefaultFontSize }]}>{text}</DefaultText>
                </View>
                <View style={styles.contentBottomRow}>
                    <View style={{ flex: 0.6 }}>
                        <DefaultText style={[styles.step4Text, textStyle, { fontSize: isNarrowScreen ? 16 : DefaultFontSize }]}>{bottomText || ""}</DefaultText>
                    </View>
                    <View style={{ flex: 0.4 }}>
                    </View>
                </View>
                <View style={[styles.imageContainer, styles.andiImageConntainer, { width: andyAvatarSmallSize, height: andyAvatarSmallSize }]}>
                    <OnBoardingAndiSmall />
                </View>
            </View>
        </View>
    )
}


export {
    TeacherOnBoardingStep1,
    TeacherOnBoardingStep2,
    TeacherOnBoardingStep3,
    TeacherOnBoardingStep4,
    TeacherOnBoardingStep5,
    TeacherOnBoardingStep6,
    TeacherOnBoardingStep7,
    TeacherOnBoardingStep8
}
const styles = StyleSheet.create({
    contentBottomRow: {
        flexDirection: 'row',
        marginTop: 10,
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 20,
    },
    contentContainer: {
        flex: 1,
        alignItems: 'center',
        minHeight: 'auto',
        justifyContent: 'flex-start'
    },
    contentContainerLeft: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 300
    },
    buttonsContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 20,
    },
    nextButton: {
        width: 150,
        borderRadius: 20,
    },
    nextText: {
        fontSize: 24,
        fontWeight: 'bold'
    },
    imageContainer: {
        width: '100%',
        height: '100%',
        maxHeight: 200
    },
    andiImageConntainer: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: 100,
        height: 100
    },
    step3Text: {
        textAlign: 'center',
    },
    step4Text: {
        textAlign: 'center'
    }
})