import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { WhatWouldThinkIcon } from '../../icons/what-would-think-icon'
import { WhatWouldThinkIconInverted } from '../../icons/what-would-think-icon-inverted'


export const SwitchesActivitiesSeparationAnxietyWhatWouldThink: CardTemplate = {
    cardId: "636c214d-62b7-44a0-b525-17e67a3bd304",
    title: 'What would _____ think? ',
    Header: WhatWouldThinkIconInverted,
    Icon: WhatWouldThinkIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsItallic textdata={"(How to approach a helpful conversation) "} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Be empathetic, understand the child's emotions and what they’re going through "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Highlight to them that you know they miss their mum (or other caregiver) "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Ask them what they think their parents think of them, for example, does [insert primary caregivers name] think that they are... "} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='brave? ' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='strong?' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='smart?' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='helpful?' />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Ask why they would think these things about them – what are some stories they can remember where their primary caregiver was proud of these things."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Follow it up by suggesting that if their primary care-giver is thinking these things about them then they would want them to be brave, strong, smart and helpful now as well."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Assure them that they will be seeing their parents again because they will come back later on to pick them up"} />

        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.SEPARATION_ANXIETY,
    whyDoesItWorkText: '',
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.SEPARATION_ANXIETY],
    action: [CardActionType.Thought],
    level: []
}

