import { useReactiveVar } from "@apollo/client"
import { useWindowDimensions } from "react-native"
import { rvUserGroup } from "../login/login-state"
import { ScreenWidthForStaticDrawer, DrawerWidth } from "./const"
import { UserGroup } from "../../common/constants"

export const useCalculateExtraItemBasedOnScreenWidth = (itemWidth: number, padding: number, itemCount: number) => {
    const dimensions = useWindowDimensions()
    const userGroup = useReactiveVar(rvUserGroup)

    const itemsPerLine = Math.floor(((dimensions.width > ScreenWidthForStaticDrawer && userGroup !== UserGroup.Teacher ? (dimensions.width - DrawerWidth) : dimensions.width) - padding * 2) / itemWidth)

    if (itemCount <= itemsPerLine) {
        return 0
    }

    return (itemsPerLine - (itemCount % itemsPerLine)) % itemsPerLine
}