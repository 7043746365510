import { StyleSheet, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaultTextWithIndentation } from '../../common/default-text-with-indentation'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { TheScribeHeaderIcon } from './src/the-scribe-header-icon'
import { TheScribeIcon } from './src/the-scribe-icon'
import { TheScribeLineDrawing } from './src/the-scribe-line-drawing'
import { Worksheets } from '../../../common/worksheets-imports'


export const SwitchesEmotionsSadTheScribe: CardTemplate = {
    cardId: "d06aab26-6f2d-41d2-b7b0-baa5e2e6de6f",
    title: 'The scribe',
    Header: TheScribeHeaderIcon,
    Icon: TheScribeIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <View style={{ flex: 1 }}>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata='Consolidating your thoughts and feelings into one place when you’re down. ' />

            </View>
        </>
    ),
    Images: TheScribeLineDrawing,
    CardContent: () => (
        <>
            <View style={styles.centered}>
                <DefaulttextWithRightArrow textdata="" />
                <DefaultText>Download the <DefaultTextWithCustomLink text='Daily diary' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Daily+Diary.pdf' /> or utilise your own diary.</DefaultText>
            </View>
            <DefaulttextWithRightArrow textdata="At the beginning of each day respond to the following questions." />
            <DefaultTextWithIndentation textdata="What are two things that I am grateful for today?" />
            <DefaultTextWithIndentation textdata="What is one affirmation (“I am ___” statement) to remember for today?" />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='▪ For example, “I am determined”' />
            <DefaulttextWithRightArrow textdata="At the end of each day respond to the following questions." />
            <DefaultTextWithIndentation textdata="Was there anything that didn’t go well today? If so, what was it?" />
            <DefaultTextWithIndentation textdata="Was there anything that did go well today? If so, what was it?" />
            <DefaulttextWithRightArrow textdata="Go into as much detail as you like." />
            <DefaulttextWithRightArrow textdata="If you are emotionally spiralling, write down your thoughts in the diary. Keep writing until you resolve your thoughts. Refer back to what you have written if you find yourself going down the same train of thought later." />
            <DefaulttextWithRightArrow textdata="Recap at the end of the week by reflecting on the sad thoughts you encountered during the week, whether you made emotional progress, and what some things there may have been to help or hinder that progress" />
        </>
    )
    ,
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: "Journaling provides a structured outlet for emotional expression, activating brain regions linked to self awareness and emotional regulation. Practicing gratitude redirects focus towards positive aspects of life, releasing neurotransmitters like serotonin and dopamine that foster feelings of contentment. Concurrently, affirmations, when consistently repeated, challenge negative thought patterns and elevate self esteem, gradually shifting one's mindset towards optimism and empowerment. These practices collectively harness psychological and neurobiological mechanisms, enhancing emotional wellbeing and resilience in the face of sadness.",
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata="If you start to feel better and mentally resolve things, then write that down too. Keep your journal entry and refer back to it if you feel yourself getting trapped by the same thoughts that you already overcame." />
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the psychological and emotional benefits of journaling, practicing gratitude, and using self affirmations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn how to incorporate these practices into their daily lives to improve their wellbeing and mindset.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by discussing the importance of mental wellbeing and self care.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concepts of journaling, gratitude, and self affirmations, and explain that these practices can have Positive effects on mental health. Ask students to share what positive effects they would have on mental health. Look for:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>1. Journaling:  Journaling involves the act of writing down thoughts, feelings, and experiences on paper. It serves as a reflective tool and a way to express oneself, fostering self awareness and emotional regulation. Positive effects on mental health:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Emotional Release: Journaling provides a healthy outlet for emotions, allowing individuals to express and release pent up feelings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Clarity and Insight: Regular journaling helps gain clarity on thoughts and situations, offering insights into patterns and triggers.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Stress Reduction: Putting thoughts on paper can reduce the mental burden, contributing to lower stress levels.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Problem Solving: It can be a tool for problem solving as individuals can explore potential solutions in writing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Goal Tracking: Journaling facilitates tracking personal goals, accomplishments, and areas for improvement, fostering a sense of achievement.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>2. Gratitude: Gratitude involves acknowledging and appreciating the positive aspects of life, both big and small. It's a mindset that focuses on what one has rather than what is lacking. Positive effects on mental health:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Positive Outlook: Cultivating gratitude promotes a positive outlook, even during challenging times.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Increased Happiness: Regularly expressing gratitude is associated with increased feelings of happiness and life satisfaction.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Improved Relationships: Acknowledging and expressing gratitude toward others strengthens social bonds and relationships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Reduced Stress: Gratitude has been linked to lower levels of stress and improved resilience in the face of adversity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Mindfulness: Practicing gratitude encourages mindfulness, as individuals become more attuned to positive aspects of the present moment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>3. Self Affirmations: Self affirmations involve the practice of positive self talk and the repetition of empowering statements that reinforce one's values, strengths, and capabilities. Positive effects on mental health:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Boosting Confidence: Regular use of self affirmations boosts self confidence and self esteem.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Coping Mechanism: Affirmations can serve as coping mechanisms during challenging situations, fostering a resilient mindset.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Shift in Mindset: Positive self talk can contribute to a shift in mindset from a negative to a more positive and constructive perspective.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Increased Motivation: Affirmations can motivate individuals to pursue goals and overcome obstacles.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Enhanced Self Image: Repeating positive affirmations helps create a positive self image and fosters self acceptance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Overall Impact on Mental Health:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Holistic Wellbeing: Integrating journaling, gratitude, and self affirmations into daily routines promotes holistic wellbeing by addressing emotional, social, and cognitive aspects of mental health.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Resilience: These practices contribute to building emotional resilience, enabling individuals to navigate life's challenges more effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Mind Body Connection: Positive mental practices have a reciprocal relationship with physical health, influencing overall wellness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Introduce The Scribe Switch and advise that will the focus of this lesson. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity: Gratitude Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Daily Dairy worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Share an example of three things you are grateful for and then ask students to identify three things they are grateful for right now and write them on their worksheet (on the relevant day).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them share their responses with a partner or with the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap how realising a few things that you are grateful for each day can increase happiness, reduce stress, improve mental health, enhance resilience, and improve relationships.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity: Self Affirmations (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide a personal example a self affirmation to the students.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to think of something about their personality or character that is good.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain to them how to turn this into an “I” statement. For example, “I am motivated”.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them write it on their worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to familiarise themselves with their self affirmation and repeat them to themselves a few times, either silently or aloud.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity: Journaling (10 minutes)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on the day (or previous day as appropriate) and note on their workshop the things that went well and the things that didn’t go well.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity: Reflection and Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a class discussion on the experiences students had during the journaling, gratitude, and self affirmation exercises.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Lead a class discussion about how these practices made them feel and if they found any particular benefit from them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to complete the reflective journaling components of the worksheet and report back in the next lesson.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points discussed during the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of incorporating these practices into their daily routines for improved mental and emotional wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to complete the worksheet daily over the coming week, including the weekly recap and reflect on the impact it has on their mood and mental health. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={Worksheets.theScribeWorksheets.label} link={Worksheets.theScribeWorksheets.uri} /></DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Gratitude Affirmations to Feel Calm, Happy and Centered</DefaultText>
                        <DefaultTextWithLink text='https://www.thedailyshifts.com/blog/gratitude-affirmations-to-feel-calm-happy-and-centered' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Why Gratitude Journaling and Affirmations Increase Happiness</DefaultText>
                        <DefaultTextWithLink text='https://malpaper.com/blogs/news/why-gratitude-journaling-and-affirmations-increase-happiness' />

                        <DefaultText style={CommonStyles.lessonPlanText}>The science behind journaling and its role in better health</DefaultText>
                        <DefaultTextWithLink text='https://www.tryhabitual.com/journal/the-science-behind-journaling' />

                    </>}
            />
        </>
    ),
    audioUrl: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/The+scribe.m4a',
    type: [CardType.Switch],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Sad]
}

const styles = StyleSheet.create({
    centered: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
})

