import { useReactiveVar } from '@apollo/client'
import { Auth } from '@aws-amplify/auth'
import { useNavigation } from '@react-navigation/core'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { RouteProp, useRoute } from '@react-navigation/native'
import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { rvNetInfo } from '../common/common-state'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { showOfflineAlert } from '../common/universal-alert'
import { rvCurrentUser, setLockDevice } from '../login/login-state'
import { PasswordErrorText } from '../login/password-error-text'
import { PasswordInput } from '../login/password-input'
import { TeacherClassNavigatorParamList, TeacherDrawerNavigatorPamarList } from './teacher-route-param-types'

const wrongUserError = 'You must login as the teacher who locked this device.'
export const UnlockTeacherDevice = () => {

    const { params } = useRoute<RouteProp<TeacherClassNavigatorParamList, "CheckInStart">>()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loggingIn, setLoggingIn] = useState(false)
    const [loginError, setLoginError] = useState('')
    const user = useReactiveVar(rvCurrentUser)
    const netInfoState = useReactiveVar(rvNetInfo)

    const { navigate } = useNavigation<DrawerNavigationProp<TeacherDrawerNavigatorPamarList>>()

    const login = () => {
        if (netInfoState?.isInternetReachable === false) return showOfflineAlert()

        if (password === '') {
            setLoginError('Password cannot be empty')
        } else {
            setLoggingIn(true)
            setLoginError('')

            Auth.signIn(user!.username, password)
                .then(auth => {
                    if (auth.username !== rvCurrentUser()?.username) {
                        throw wrongUserError
                    }

                    setLockDevice(false, params?.classID || "")
                    navigate(ScreenNames.TeacherClassMain, { screen: ScreenNames.CheckInStart, params: { classID: params?.classID || "" } })
                })
                .catch(e => {
                    if (typeof e === 'string' && e === wrongUserError) {
                        setLoginError(e)
                    } else {
                        setLoginError('Incorrect username and/or password.')
                    }
                    setLoggingIn(false)
                })
        }
    }

    return (
        <Page style={styles.page}>

            <DefaultText>
                Please re-enter your password to unlock.
            </DefaultText>
            <View style={styles.inputContainer}>
                <PasswordInput onPasswordChange={setPassword} onSubmit={login} />
            </View>
            <DefaultButton style={styles.loginButton} buttonTextColor={DecidaColors.White} onPress={login} disabled={loggingIn}>Unlock</DefaultButton>
            <PasswordErrorText>{loginError}</PasswordErrorText>

        </Page>
    )
}


const styles = StyleSheet.create({
    keyboardAvoidingView: {
        flex: 1,
    },
    page: {
        backgroundColor: DecidaColors.White,
    },
    inputContainer: {
        flexDirection: 'column',
        width: 350,
        alignItems: 'center'
    },
    loginButton: {
        backgroundColor: DecidaColors.Red,
    },
    forgotPasswordContainer: {
        width: 350,
        alignItems: 'flex-end',
        paddingRight: 9,
    },
    forgotPasswordText: {
        color: DecidaColors.DarkBlue,
        height: 30,
    },
    logostyle: {
        backgroundColor: DecidaColors.Black,
        borderRadius: 12,
        padding: 20,
        width: "95%"
    },
    mainContainer: {

    }
})
