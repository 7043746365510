import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'
import { SafeAreaView, ScrollView, StyleSheet, View } from 'react-native'
import { CustomUpdateStaffMutation, CustomUpdateStaffMutationVariables } from '../../common/API'
import { customUpdateStaff } from '../../common/graphql/mutations'
import { BackButton } from '../common/back-button'
import { CommonStyles } from '../common/const'
import { rvIsLoading } from '../common/loading'
import { CLIENT_EVENT, createClientLog } from '../common/log-client-event'
import { Page } from '../common/page'
import ProfileForm, { ProfileFormData } from '../common/profile-form'
import { showAlert } from '../common/universal-alert'
import { useNonTeachingStaffState } from '../student/use-non-teaching-staff-state'
import { UserGroup } from '../../common/constants'
import AvatarImage from '../common/avatar-image'
import { avatars } from '../icon/avatar/avatars'
import { DefaultText } from '../common/default-text'
import { DecidaColors } from '../../common/decida-colors'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'
import { ScreenNames } from '../common/screen-names'

export const NonTeachingStaffProfile = () => {

    const [allowEdit, setAllowEdit] = useState(false)

    const { currentNonTeachingStaff } = useNonTeachingStaffState()

    const { navigate } = useNavigation<DrawerNavigationProp<CheckInNavigatorParamList>>()

    const [customUpdateStaffMutation] = useMutation<CustomUpdateStaffMutation, CustomUpdateStaffMutationVariables>(gql`${customUpdateStaff}`,)

    const saveChangesNonTeachingStaff = async ({ firstName, lastName, email, preferredUsername }: ProfileFormData) => {

        if (currentNonTeachingStaff) {

            try {
                rvIsLoading(true)
                await customUpdateStaffMutation({
                    variables: {
                        input: {
                            id: currentNonTeachingStaff?.id,
                            email: email || "",
                            firstName,
                            lastName,
                            preferredUsername
                        }

                    }
                })

                createClientLog({
                    event: CLIENT_EVENT.EDIT_PROFILE_SUCCESS,
                    area: "Edit profile screen",
                    action: "Clicking save, profile updated successfully",
                    payload: {
                        group: "non teaching staff",
                        nickname: currentNonTeachingStaff?.cognitoUsername,
                    }
                })
                setAllowEdit(!allowEdit)
                rvIsLoading(false)
            }
            catch (e: any) {
                showAlert({
                    title: 'An error has occured.',
                    message: String(e),
                    leftButtonText: 'Ok',
                    rightButtonText: 'Retry',
                    onRightButtonPress: () => saveChangesNonTeachingStaff({ firstName, lastName, email, preferredUsername })
                })
                createClientLog({
                    event: CLIENT_EVENT.EDIT_PROFILE_FAILED,
                    area: "Edit profile screen",
                    action: "An error has occured., failed to update profile",
                    payload: {
                        group: "non-teaching-staff",
                        nickname: currentNonTeachingStaff?.cognitoUsername,
                        error: String(e)
                    }
                })
            } finally {
                rvIsLoading(false)
            }
        }
    }

    const navigateToEditAvatar = () => {

        if (!currentNonTeachingStaff) {
            return null
        }

        navigate(ScreenNames.UserEditAvatar, { id: currentNonTeachingStaff?.id, user: UserGroup.NonTeachingStaff })
    }

    return (

        <Page style={styles.page}>
            <View style={styles.backButtonContainer}>
                <BackButton />
            </View>
            <ScrollView style={CommonStyles.inputScrollView} contentContainerStyle={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <AvatarImage avatar={currentNonTeachingStaff?.avatar as keyof typeof avatars} size={100} editMode={allowEdit} onEdit={navigateToEditAvatar} />
                    <DefaultText style={styles.nickname}>{currentNonTeachingStaff?.cognitoUsername}</DefaultText>
                </View>
                <View style={{ flex: 1 }}>
                    <ProfileForm allowEdit={allowEdit} setAllowEdit={setAllowEdit} onSave={saveChangesNonTeachingStaff} profileType={UserGroup.NonTeachingStaff} data={currentNonTeachingStaff} />
                </View>
            </ScrollView>

            <SafeAreaView>
            </SafeAreaView>
        </Page>
    )
}


const styles = StyleSheet.create({
    page: {

    },
    nickname: {
        fontSize: 30,
        fontWeight: 'bold',
        color: DecidaColors.Green,
        textAlign: 'center',
    },
    backButtonContainer: {
        flexDirection: 'row',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%'
    },


})