import { useReactiveVar } from '@apollo/client'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { useEffect } from 'react'
import { View, StyleSheet } from 'react-native'
import { CommonStyles } from '../common/const'
import { RootView } from '../common/root-view'
import { ScreenNames } from '../common/screen-names'
import { SwitchesActiviesDetailView } from '../switches/activities/switches-activies-detail-view'
import { SwitchesActiviesDisplayAll } from '../switches/activities/switches-activies-display-all'
import { Card } from '../switches/common/card'
import { rvSwitchesScreenState } from '../switches/common/current-screen-state'
import { SwitchesAcitiviesMain } from '../switches/navigation/switches-activity-main'
import { SwitchesBottomNavigation } from '../switches/navigation/switches-bottom-navigation'
import { SwitchesExploreAllMain } from '../switches/navigation/switches-explore-all-main'
import { SwitchesHomeMain } from '../switches/navigation/switches-home-main'
import { SwitchesToolsMain } from '../switches/navigation/switches-tools-main'
import { SwitchesEmotionsDetailView } from '../switches/switches-emotions-detail-view'
import { SwitchesExploreAll } from '../switches/switches-explore-all'
import { SwitchesHome } from '../switches/switches-home'
import { SwitchesMemes } from '../switches/switches-memes'
import { SwitchesToolsDisplayAll } from '../switches/tools/switches-tools-display-all'
import { ParentHeader } from './parent-header'
import { ParentHome } from './parent-home'
import { ParentNavigationParams } from './parent-navigation-params'
import { ParentSettings } from './parent-settings'
import { rvLoggedIn, rvParentPortal, rvRedirectPages } from '../login/login-state'
import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import { useRedirectPages } from '../common/user-redirect-pages'
import { SwitchesParamList } from '../switches/navigation/switches-param-list'
import { Emotion } from '../models'
import NotFoundPage from '../common/not-found-page'

const SideMenu = createDrawerNavigator<ParentNavigationParams>()

export const ParentMain = () => {
    const currentScreen = useReactiveVar(rvSwitchesScreenState)
    const { navigate } = useNavigation<NavigationProp<ParentNavigationParams>>()

    useEffect(() => {
        rvSwitchesScreenState(currentScreen)
    }, [useReactiveVar(rvSwitchesScreenState)])

    const { routeName, routeParams } = useRedirectPages()

    useEffect(() => {
        if (routeName === ScreenNames.SwitchesEmotionsDetailView) {
            navigate(ScreenNames.SwitchesEmotionsDetailView, { emotion: routeParams.emotion as Emotion })
        }

        if (routeName === ScreenNames.Card) {

            if (routeParams.openLessonPlan && routeParams.openLessonPlan === 'true') {
                rvParentPortal(false)
                rvLoggedIn(false)
                return
            }
            navigate(ScreenNames.Card, { id: routeParams.cardId, openLessonPlan: routeParams.openLessonPlan })
        }

        if (routeName === ScreenNames.SwitchesMemes) {
            navigate(ScreenNames.SwitchesMemes, { meme: Number(routeParams.meme) })
        }
    }, [routeName])

    return (
        <RootView>
            <SideMenu.Navigator
                screenOptions={{
                    headerShown: true,
                    header: props => <ParentHeader {...props} />,
                    unmountOnBlur: true,
                    drawerStyle: CommonStyles.drawer,
                    swipeEdgeWidth: 0
                }}
                backBehavior="history"
                initialRouteName={ScreenNames.SwitchesHome}
            >

                <SideMenu.Screen name={ScreenNames.ParentHome} component={ParentHome} options={{ title: 'ParentHome' }} />
                <SideMenu.Screen name={ScreenNames.ParentSettings} component={ParentSettings} options={{ title: 'ParentSettings' }} />
                <SideMenu.Screen name={ScreenNames.NotFound} component={NotFoundPage} />


                {/* Switches Navigation */}
                <SideMenu.Screen name={ScreenNames.SwitchesHome} component={SwitchesHome} />
                <SideMenu.Screen name={ScreenNames.SwitchesExploreAll} component={SwitchesExploreAll} />
                <SideMenu.Screen name={ScreenNames.Card} component={Card} />
                <SideMenu.Screen name={ScreenNames.SwitchesMemes} component={SwitchesMemes} />
                <SideMenu.Screen name={ScreenNames.SwitchesEmotionsDetailView} component={SwitchesEmotionsDetailView} />
                <SideMenu.Screen name={ScreenNames.SwitchesActiviesDisplayAll} component={SwitchesActiviesDisplayAll} />
                <SideMenu.Screen name={ScreenNames.SwitchesActiviesDetailView} component={SwitchesActiviesDetailView} />
                <SideMenu.Screen name={ScreenNames.SwitchesToolsDisplayAll} component={SwitchesToolsDisplayAll} />
                <SideMenu.Screen name={ScreenNames.SwitchesExploreAllMain} component={SwitchesExploreAllMain} />
                <SideMenu.Screen name={ScreenNames.SwitchesToolsMain} component={SwitchesToolsMain} />
                <SideMenu.Screen name={ScreenNames.SwitchesHomeMain} component={SwitchesHomeMain} />
                <SideMenu.Screen name={ScreenNames.SwitchesActivityMain} component={SwitchesAcitiviesMain} />
            </SideMenu.Navigator>
            <View style={styles.bottomNavigation}>
                <SwitchesBottomNavigation currentScreen={currentScreen} />
            </View>
        </RootView>
    )
}

const styles = StyleSheet.create({


    bottomNavigation: {
        bottom: 0,
        position: 'relative',
    },

})
