
import { StyleSheet } from 'react-native'
import { Dimensions } from 'react-native';
import { DecidaColors } from '../../../common/decida-colors';

export const windowWidth = Dimensions.get('window').width;

export const commonStyles = StyleSheet.create({
    row: {
        flexDirection: 'row'
    }
})
export const commonCardStyles =
    // TODO: this won't work when you resize browser or rotate device
    windowWidth < 600 ?
        StyleSheet.create({

            container: {
                width: '100%',
                marginTop: 20
            },
            image: {
                width: 200,
                height: 200,
                resizeMode: `stretch`,
            },
            imagecontainer: {
                width: '100%',
                justifyContent: 'center',
                alignItems: "center",
            },
            buttoncontainer: {
                justifyContent: 'center',
                alignItems: "center",
            },
            containerofvalues: {
                flexDirection: 'column',
            },
            valuecontainer1: {
                flexDirection: 'column',
            },
            valuecontainer2: {
                flexDirection: 'column',
            },
            imageSmallScreen: {
                width: 350,
                height: 350,
            },
            emotionMeterImage: {
                height: 100,
                width: 350,
                zIndex: 4
            },
            bottomNavigation: {
                bottom: 0,
                position: 'relative',
            },
            buttonstyle: {
                alignItems: 'center',
                justifyContent: 'center'
            },
            lessionPlanContainer: {
                display: 'flex',
                backgroundColor: DecidaColors.AppleSystemGray6Light,
                marginBottom: 50,
                maxWidth: 700,
            },
            lessionPlanHeader: {
                height: 30,
                maxWidth: 700,
                backgroundColor: DecidaColors.AppleSystemGray2Dark
            },
            lessionPlanHeadingText: {
                color: DecidaColors.White,
                fontSize: 20,
                fontWeight: "700",
                paddingLeft: 10,
            },
            lessionInfoContainer: {

            }
        })
        :
        StyleSheet.create({
            container: {
                width: '100%',
            },
            image: {
                width: '100%',
                height: 250,
                resizeMode: 'contain'
            },
            imagecontainer: {
                justifyContent: 'center',
                alignItems: "center",
            },
            buttoncontainer: {
                justifyContent: 'center',
                alignItems: "center",
            },
            containerofvalues: {
                flexDirection: 'row',
            },
            valuecontainer1: {
                flexDirection: 'column',
            },
            valuecontainer2: {
                flexDirection: 'column',
            },
            emotionMeterImage: {
                height: 100,
                width: 600,
            },
            bottomNavigation: {
                bottom: 0,
                position: 'relative',
            },
            buttonstyle: {
                alignItems: 'center',
                justifyContent: 'center'
            },
            lessionPlanContainer: {
                display: 'flex',
                backgroundColor: DecidaColors.AppleSystemGray5Light,
                marginBottom: 50,
                maxWidth: 700,
            },
            lessionPlanHeader: {
                height: 40,
                maxWidth: 700,
                backgroundColor: DecidaColors.AppleSystemGray2Dark,
                justifyContent: 'center',
            },
            lessionPlanHeadingText: {
                color: DecidaColors.White,
                fontSize: 16,
                fontWeight: "700",
                paddingLeft: 10,
            },
            lessionPlanHeading: {

            },
            lessionInfoContainer: {
                padding: 10,
            }
        })

