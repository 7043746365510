import { CheckInSession } from "../../common/API"
import _ from 'lodash'
import moment from "moment"

export const groupByWeek = (checkInSessions: CheckInSession[]) =>
    _.sortBy(checkInSessions, checkInSession => checkInSession.createdAt)
        .reduce((result, current) => {
            if (result.length === 0) {
                return [[current]]
            }

            const currentWeek = moment(current.createdAt).week()
            const latestWeek = result[result.length - 1]

            if (currentWeek === moment(latestWeek[0].createdAt).week()) {
                latestWeek.push(current)
            } else {
                result.push([current])
            }

            return result
        }, [] as CheckInSession[][])
        .reverse()
