import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { Ref, RefObject, forwardRef, useEffect, useMemo } from 'react'
import { TextInput, View } from 'react-native'
import { GetListTeacherBySchoolIDQuery } from '../../common/API'
import { GetSchoolQueryResponseTeacher } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query'
import { getListTeacherBySchoolID } from '../../common/graphql/queries'
import { upperCaseFirstLater } from './helper-functions'
import InputSearchComponentRounded, { InputSearchComponentType } from './input-search-component-rounded'

type Props = {
    value: string
    setValue: (text: string) => void
    selected: GetSchoolQueryResponseTeacher | null
    setSelected: (data: GetSchoolQueryResponseTeacher) => void
    placeholder?: string
    fieldName: keyof GetSchoolQueryResponseTeacher
    withoutSearch?: boolean,
    schoolID?: string
    nextRef?: RefObject<TextInput>
    onError?: () => void
}

const InputSearchTeacherComponent = forwardRef(({ value, setValue, selected, setSelected, placeholder, fieldName, withoutSearch, schoolID, nextRef, onError }: Props, ref: Ref<TextInput>) => {

    const { data, refetch } = useQuery<GetListTeacherBySchoolIDQuery>(gql`${getListTeacherBySchoolID}`, {
        variables: {
            schoolID,
            filter: {
                or: [
                    {
                        [fieldName]: { contains: value.toLowerCase() },
                    },
                    {
                        [fieldName]: { contains: value },
                    },
                    {
                        [fieldName]: { contains: upperCaseFirstLater(value) },
                    },
                    {
                        cognitoUsername: { contains: value.toLowerCase() },
                    },
                ]
            },
            limit: 5000
        },
        skip: withoutSearch
    })

    useEffect(() => {
        if (!withoutSearch) {
            checkTeacherEmail()
        }
    }, [value])

    const checkTeacherEmail = useMemo(() => _.debounce(() => refetch(), 200), [])

    const handleSelect = (d: GetSchoolQueryResponseTeacher) => {
        setSelected(d)

        if (fieldName) setValue(d[fieldName] as string)
    }

    const handleBlur = () => {
        if (fieldName === 'email' && data?.getListTeacherBySchoolID?.items.length) {
            onError && onError()
        }
    }

    return (
        <View>
            <InputSearchComponentRounded ref={ref} nextRef={nextRef} placeholder={placeholder} value={value} onChangeText={setValue} data={value ? data?.getListTeacherBySchoolID?.items : []} selectedData={selected} setSelectedData={handleSelect} type={InputSearchComponentType.Teacher} withoutSearch={withoutSearch} onBlur={handleBlur} />
        </View>
    )
})

export default InputSearchTeacherComponent