import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { FontAwesome } from '@expo/vector-icons'
import { StackScreenProps } from '@react-navigation/stack'
import { API } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { Pressable, ScrollView, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native'
import { Class, DeleteStudentClassMutation, DeleteStudentClassMutationVariables, GetSchoolQuery, GetSchoolQueryVariables, GetStudentClassByStudentIdQuery, GetStudentClassByStudentIdQueryVariables, GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables, SchoolAdminUI, Student, StudentClassApprovalStatus, Teacher } from '../../common/API'
import { UserGroup } from '../../common/constants'
import { DecidaColors } from '../../common/decida-colors'
import { generatePassword } from '../../common/generate-password'
import { deleteStudentClass, updateStudent } from '../../common/graphql/mutations'
import { getClass, getSchool, getStudent, getStudentClassByStudentId, getTeacherByCognitoUsername } from '../../common/graphql/queries'
import { BackButton } from '../common/back-button'
import { CommonStyles } from '../common/const'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { FirstNameInput } from '../common/first-name-input'
import { getApiHeaders } from '../common/get-api-header'
import { InputCognitoUsername } from '../common/input-cognito-username'
import { LastNameInput } from '../common/last-name-input'
import { rvIsLoading } from '../common/loading'
import { CLIENT_EVENT, createClientLog } from '../common/log-client-event'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { showAlert, showGenericErrorAlert, showPasswordResetAlert } from '../common/universal-alert'
import { avatars } from '../icon/avatar/avatars'
import { rvCurrentUser, rvUserGroup } from '../login/login-state'
import { SchoolAdminClassNavigatorParamsList, SchoolAdminNavigationParams } from '../school-admin/school-admin-navigation-params'
import { TeacherClassNavigatorParamList } from './teacher-route-param-types'
import InputEmailStudents from '../common/input-email-students'

type TeacherEditStudentStackScreenProps = StackScreenProps<TeacherClassNavigatorParamList & SchoolAdminClassNavigatorParamsList & SchoolAdminNavigationParams, 'TeacherEditStudent' | 'SchoolAdminEditStudent'>

export const TeacherEditStudent = ({ route: { params }, navigation: { navigate, goBack } }: TeacherEditStudentStackScreenProps) => {
    const { studentID, classID } = params ?? {};
    const [allowEdit, setAllowEdit] = useState(false)
    const userGroup = useReactiveVar(rvUserGroup)
    const user = useReactiveVar(rvCurrentUser)

    const { data: teacherResponse } = useQuery<GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables>(gql`${getTeacherByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
    const currentTeacher = teacherResponse?.getTeacherByCognitoUsername?.items[0] as Teacher

    const { loading: loadingStudentData, data: studentData } = useQuery(gql`${getStudent}`, { variables: { id: studentID } })
    const { data: responseClass } = useQuery(gql`${getClass}`, { variables: { id: classID } })

    const { data: studentClassResponse } = useQuery<GetStudentClassByStudentIdQuery, GetStudentClassByStudentIdQueryVariables>(gql`${getStudentClassByStudentId}`, { variables: { studentID }, skip: !studentID })
    const currentStudentClass = studentClassResponse?.getStudentClassByStudentId?.items.find((item) => item?.classID === classID && item?.status === StudentClassApprovalStatus.APPROVED && item._deleted !== true)

    const currentClass = responseClass?.getClass as Class

    const { data: schoolResponse } = useQuery<GetSchoolQuery, GetSchoolQueryVariables>(gql`${getSchool}`, { variables: { id: currentClass?.schoolID || "" } })
    const currentSchool = schoolResponse?.getSchool

    const [updateStudentMutation] = useMutation(gql`${updateStudent}`, {})
    const [deleteStudentClassMutation] = useMutation<DeleteStudentClassMutation, DeleteStudentClassMutationVariables>(gql`${deleteStudentClass}`, {})
    const student = studentData?.getStudent


    useEffect(() => {
        rvIsLoading(loadingStudentData)
    }, [loadingStudentData])

    const [firstName, setFirstName] = useState(student?.firstName)
    const [lastName, setLastName] = useState(student?.lastName)
    const [email, setEmail] = useState(student?.email)
    const [nickname, setNickname] = useState(student?.nickname)

    const [tempEmail, setTempEmail] = useState(student?.email)
    const [usernameUnavalible, setUsernameUnavalible] = useState<boolean | null>(null)
    const [emailExists, setEmailExists] = useState(false)


    useEffect(() => {
        setFirstName(student?.firstName)
        setLastName(student?.lastName)
        setEmail(student?.email)
        setTempEmail(student?.email)
        setNickname(student?.nickname)
    }, [student])

    if (!student) {
        return null
    }

    const setEmailFiltered = (value: string) => {
        if (value.search(/[^0-9a-zA-Z_.@]/) < 0) {
            setTempEmail(value)
        }
    }

    const Avatar = avatars[student.avatar as keyof typeof avatars]

    const navigateToClassAdmin = () => {
        if (userGroup === UserGroup.Teacher) {
            navigate(ScreenNames.TeacherClassAdmin, { classID: currentClass?.id })
        } else if (userGroup === UserGroup.SchoolAdmin) {

            if (currentSchool?.schoolAdminUI === SchoolAdminUI.HIERARCHY) {
                navigate(ScreenNames.SchoolAdminHome)
            } else {
                navigate(ScreenNames.SchoolAdminManageClass, { classID: currentClass?.id })
            }

        } else if (userGroup === UserGroup.Admin) {
            navigate(ScreenNames.TeacherClassAdmin, { classID: currentClass?.id })
        }
    }

    const navigateToEditStudentAvatar = () => {
        if (userGroup === UserGroup.Teacher || userGroup === UserGroup.Admin) {
            navigate(ScreenNames.TeacherEditStudentAvatar, { studentID: student.id, classID: classID || "" })
        } else if (userGroup === UserGroup.SchoolAdmin) {
            if (currentSchool?.schoolAdminUI === SchoolAdminUI.HIERARCHY) {
                navigate(ScreenNames.SchoolAdminClassMain, { screen: ScreenNames.SchoolAdminEditStudentAvatar, params: { studentID: student.id, classID } })
            } else {
                navigate(ScreenNames.SchoolAdminEditStudentAvatar, { studentID: student.id, classID })
            }
        }
    }

    const removeStudentClass = async () => {

        if (currentStudentClass) {
            try {
                rvIsLoading(true)

                await deleteStudentClassMutation({
                    variables: {
                        input: {
                            id: currentStudentClass?.id,
                            _version: currentStudentClass._version
                        }
                    }
                })
                rvIsLoading(false)
                navigateToClassAdmin()
            } catch (error) {
                console.log("Failed remove student from class", error)
            }

        }
    }

    const deactivateStudent = (student: Student) => {
        showAlert({
            title: `Remove ${student.nickname} from ${currentClass?.goMode === true ? 'GO group' : 'class'}?`,
            message: `WARNING: This will remove the student from ${currentClass?.name}. You can add the student back via the add student functionality on class admin.`,
            leftButtonText: 'Cancel',
            rightButtonText: 'Remove',
            onRightButtonPress: removeStudentClass,

        })
    }

    const resetStudentPassword = async () => {
        const tempPassword = generatePassword()

        try {
            rvIsLoading(true)
            await API.post(
                'AdminQueries',
                '/resetPasswordAdmin',
                {
                    headers: await getApiHeaders(),
                    body: {
                        id: student.id,
                        nickname: student.cognitoUsername,
                        email: tempEmail || student.email,
                        password: tempPassword,
                        _version: student._version
                    }
                }
            )

            createClientLog({
                area: 'Edit student page',
                action: "Reset password success",
                event: CLIENT_EVENT.RESET_PASSWORD_SUCCESS,
                payload: {
                    group: "teacher",
                    nickname: currentTeacher?.cognitoUsername,
                }
            })
            showPasswordResetAlert(tempPassword, nickname || "")

        } catch (e: any) {
            const errorMessage = e.response?.data?.message

            if (errorMessage) {
                showAlert({
                    title: 'An error has occured.',
                    message: errorMessage,
                    leftButtonText: 'Ok',
                    rightButtonText: 'Retry',
                    onRightButtonPress: resetStudentPassword
                })
            } else {
                showGenericErrorAlert()
            }
            createClientLog({
                area: 'Edit student page',
                action: `Reset ${student.cognitoUsername} failed`,
                event: CLIENT_EVENT.RESET_PASSWORD_FAILED,
                payload: {
                    group: "teacher",
                    nickname: currentTeacher?.cognitoUsername,
                    e: errorMessage || e
                }
            })
        } finally {
            rvIsLoading(false)
        }
    }

    const updateStudentData = async (studentData: Partial<Student>) => {
        try {
            await updateStudentMutation({ variables: { input: studentData } })

            createClientLog({
                area: 'TeacherEditStudent page',
                event: CLIENT_EVENT.UPDATE_STUDENT_DATA_SUCCESS,
                action: `Update student: "${student.id}", success`,
                payload: {
                    group: "Teacher",
                    nickname: currentTeacher?.cognitoUsername
                }
            })
        } catch (error) {
            createClientLog({
                area: 'TeacherEditStudent page',
                event: CLIENT_EVENT.UPDATE_STUDENT_DATA_FAILED,
                action: `Update student: "${student.id}", failed`,
                payload: {
                    group: "Teacher",
                    nickname: currentTeacher?.cognitoUsername
                }
            })
        }

    }

    const saveChangesStudent = async () => {
        if (student) {
            const username = nickname
            const cognitoUsername = student.cognitoUsername
            const emailCheck = email === '' ? undefined : email
            // If email hasn't changed don't run 
            if (username != student.nickname) {
                try {
                    rvIsLoading(true)
                    const post = await API.post(
                        'AdminQueries',
                        '/updatePreferredUsername',
                        {
                            body: {
                                username, cognitoUsername
                            },
                            headers: await getApiHeaders(),
                        }
                    )
                    if (post.completed) {

                        const studentData: Partial<Student> = {
                            id: student.id,
                            _version: student._version,
                            firstName,
                            lastName,
                            email: emailCheck,
                            nickname: nickname!
                        }

                        updateStudentData(studentData)
                        setAllowEdit(!allowEdit)
                        rvIsLoading(false)
                    }
                    else {
                        showAlert({
                            title: 'An error has occured.',
                            message: post.message,
                            leftButtonText: 'Ok',
                            rightButtonText: 'Retry',
                            onRightButtonPress: saveChangesStudent
                        })
                        rvIsLoading(false)

                    }

                }
                catch (e: any) {
                    showAlert({
                        title: 'An error has occured.',
                        message: String(e),
                        leftButtonText: 'Ok',
                        rightButtonText: 'Retry',
                        onRightButtonPress: saveChangesStudent
                    })
                }

            }
            else if (emailCheck != student.email) {
                try {

                    rvIsLoading(true)
                    const post = await API.post(
                        'AdminQueries',
                        '/updateEmailAndVerify',
                        {
                            body: {
                                cognitoUsername, email: email, status: emailCheck ? "true" : "false"
                            },
                            headers: await getApiHeaders(),
                        }
                    )
                    if (post.completed) {
                        const studentData: Partial<Student> = {
                            id: student.id,
                            _version: student._version,
                            firstName,
                            lastName,
                            email: emailCheck,
                            nickname: nickname!
                        }

                        updateStudentData(studentData)
                        setAllowEdit(!allowEdit)
                        rvIsLoading(false)
                    } else {
                        showAlert({
                            title: 'An error has occured.',
                            message: post.message,
                            leftButtonText: 'Ok',
                            rightButtonText: 'Retry',
                            onRightButtonPress: saveChangesStudent
                        })
                        rvIsLoading(false)
                    }
                }
                catch (e: any) {
                    showAlert({
                        title: 'An error has occured.',
                        message: String(e),
                        leftButtonText: 'Ok',
                        rightButtonText: 'Retry',
                        onLeftButtonPress: saveChangesStudent
                    })
                }
            }
            else {
                try {
                    const studentData: Partial<Student> = {
                        id: student.id,
                        _version: student._version,
                        firstName,
                        lastName,
                        email: emailCheck,
                        nickname: nickname!
                    }

                    updateStudentData(studentData)
                    setAllowEdit(!allowEdit)

                }
                catch (e: any) {
                    showAlert({
                        title: 'An error has occured.',
                        message: String(e),
                        leftButtonText: 'Ok',
                        rightButtonText: 'Retry',
                        onRightButtonPress: saveChangesStudent
                    })
                }

            }


        }
    }

    const navigateToStudentIndividualStats = () => {
        navigate(ScreenNames.TeacherStudentIndividualStats, { studentID: student.id, classID: currentClass?.id, previousScreen: ScreenNames.TeacherEditStudent })
    }


    return (
        <Page style={styles.page}>
            <View style={styles.header}>
                {
                    userGroup === UserGroup.Teacher && (
                        <View style={styles.backButtonContainer}>
                            <BackButton onPress={navigateToClassAdmin} />
                        </View>
                    )
                }
                <View style={styles.spacer} />
            </View>

            <ScrollView style={CommonStyles.inputScrollView}>
                <View style={CommonStyles.flexContainer}>
                    <View style={styles.inputContainer}>
                        {allowEdit ?
                            <>
                                <TouchableOpacity style={styles.avatarContainerHold} onPress={navigateToEditStudentAvatar}>
                                    <View style={styles.avatarSize}>
                                        <Avatar />
                                        <View style={styles.pencil}>
                                            <FontAwesome name="pencil" size={35} color={DecidaColors.Green} />
                                        </View>
                                    </View>
                                </TouchableOpacity>
                                <InputCognitoUsername
                                    userExists={usernameUnavalible}
                                    setUserExists={setUsernameUnavalible}
                                    value={nickname || ""}
                                    onChangeText={setNickname}
                                />
                            </> :
                            <View style={styles.avatarContainerHold}>
                                <Avatar />
                                <DefaultText style={styles.nicknameBold}>{nickname}</DefaultText>
                                <Pressable onPress={navigateToStudentIndividualStats}>
                                    <DefaultText style={styles.individualStatsText}>
                                        Individual stats
                                    </DefaultText>
                                </Pressable>
                            </View>
                        }
                    </View>
                    <View style={styles.inputContainer}>

                        <DefaultText style={styles.heading}>Class Name</DefaultText>
                        <DefaultText style={styles.subtitle}>{currentClass?.name}</DefaultText>

                        {allowEdit ?
                            <FirstNameInput value={firstName} onChangeText={setFirstName} />
                            :
                            <>
                                <DefaultText style={styles.heading}>First Name</DefaultText>
                                <DefaultText style={styles.subtitle}>{firstName}</DefaultText>
                            </>
                        }
                        {allowEdit ?
                            <LastNameInput value={lastName} onChangeText={setLastName} />
                            :
                            <>
                                <DefaultText style={styles.heading}>Last Name</DefaultText>
                                <DefaultText style={styles.subtitle}>{lastName}</DefaultText>
                            </>
                        }
                        {allowEdit ?
                            <InputEmailStudents
                                value={email}
                                emailExists={emailExists}
                                setEmailExists={setEmailExists}
                                onChangeText={setEmail}
                                showErrorMessage={true}
                            /> :
                            <>
                                <DefaultText style={styles.heading}>Email</DefaultText>
                                <DefaultText style={styles.subtitle}>{email}</DefaultText>
                            </>
                        }
                        <View style={styles.buttonContainer}>
                            {allowEdit ?
                                <>
                                    <TouchableOpacity style={styles.joinButton} onPress={saveChangesStudent} >
                                        <DefaultText style={styles.buttonText}>Save</DefaultText>
                                    </TouchableOpacity></> : <>
                                    <TouchableOpacity style={styles.joinButton} onPress={() => setAllowEdit(!allowEdit)}>
                                        <DefaultText style={styles.buttonText}>Edit</DefaultText>
                                    </TouchableOpacity>
                                </>
                            }
                        </View>

                        <DefaultButton
                            onPress={resetStudentPassword}
                        >
                            Reset password
                        </DefaultButton>
                        <DefaultButton
                            onPress={() => deactivateStudent(student)}
                            style={styles.Deactivate}

                        >
                            {`Remove student from ${currentClass?.goMode === true ? 'GO group' : 'class'}`}
                        </DefaultButton>
                    </View>
                </View>

            </ScrollView>

        </Page >
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
    },
    header: {
        flexDirection: 'row',
        width: '100%',
    },
    backButtonContainer: {
        flex: 1,
        paddingTop: 5,
    },
    pageTitleContainer: {
        flex: 3,
        alignItems: 'center',
    },
    spacer: {
        flex: 1,
    },
    contentContainer: {
        maxWidth: 330,
        margin: 10,
    },
    avatarEditContainer: {
        width: 120,
        marginBottom: 20,
    },
    avatarContainer: {
        marginTop: 20,
        width: 90,
        height: 90,
    },
    editIconContainer: {
        position: 'absolute',
        right: 0,
        top: 0,
        width: 43,
        height: 43,
    },
    nickname: {
        borderColor: DecidaColors.Black,
        color: DecidaColors.Black,
        padding: 2,
        paddingLeft: 7,
        paddingRight: 7,
        marginTop: 10,
        fontWeight: 'bold',
        fontSize: 28,
    },
    emailNote: {
        width: 330,
        color: DecidaColors.Black,
        fontSize: 15,
    },
    Deactivate: {
        backgroundColor: DecidaColors.Red
    },
    teacherDetails: {
        borderWidth: 1,
        borderColor: DecidaColors.Gray,
        padding: 10,
        borderRadius: 8,
        width: 300,


    },
    avatarContainerHold: {
        marginVertical: 50,
        height: 100,
        width: '100%',
        alignItems: 'center'

    },
    avatarSize: {
        width: 100,
        height: 100,
    },
    avatarCenter: {
        alignContent: 'center',
        justifyContent: 'center'
    },
    heading: {
        fontSize: 15,
        fontWeight: 'bold',
        paddingTop: 20,
    },
    row: {
        flexDirection: 'row',

    },
    subtext: {
        fontSize: 15,
    },
    inputContainer: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    nicknameBold: {
        fontSize: 30,
        fontWeight: 'bold',
        color: DecidaColors.Green,
        textAlign: 'center',

    },
    subtitle: {
        color: DecidaColors.Green,
        marginBottom: 15,
    },
    buttonContainer: {
        flexDirection: 'row',
        marginBottom: 50
    },
    editButton: {
        backgroundColor: DecidaColors.White,
        height: 50,
        width: 150,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    editButtonText: {
        color: DecidaColors.Green,
        paddingRight: 10,
        fontSize: 15,

    },
    joinButton: {
        backgroundColor: DecidaColors.Green,
        height: 50,
        width: 150,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    buttonText: {
        color: DecidaColors.White

    },
    settings: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignContent: 'flex-end',
        paddingRight: 20,
    },
    pencil: {
        position: 'absolute',
        right: 0,
        top: -15,

    },
    individualStatsText: {
        color: DecidaColors.DarkBlue,
        textDecorationLine: 'underline',
        marginVertical: 4
    }
})