import { Activities } from '../../common/activities'
import { ActivityGroupTemplate } from '../../common/activity-group-template'
import { SecretsForSleepIcon } from '../icons/secrets-for-sleep-icon'

export const SwitchesActivityGroupSecretsForSleep: ActivityGroupTemplate = {
    title: 'Secrets for sleep',
    Icon: SecretsForSleepIcon,
    activity: Activities.SECRETS_FOR_SLEEP,
    subtitle: 'Tips and tricks to calm your mind and get better sleep'
}
