export const isGoModeCheckInOpen = () => {
    return true
    const now = new Date()
    const currentHour = now.getHours()
    
    if (currentHour >= 8 && currentHour < 16) {
        return true
    }

    return false
}