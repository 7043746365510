import { useEffect, useState } from 'react';
import { View, StyleSheet, ViewStyle, ColorValue, DimensionValue } from 'react-native';
import { Audio } from 'expo-av';
import { Entypo } from '@expo/vector-icons';
import { DefaultText } from './default-text';
import { DefaultButton } from './default-button';
import { useReactiveVar } from '@apollo/client';
import { rvCard } from '../switches/common/card-color-scheme';
import { AVPlaybackSource } from 'expo-av/build/AV.types';
import { DecidaColors } from '../../common/decida-colors';

type NewType = AVPlaybackSource;

interface Props {
    audioFile: NewType
    audioTitle?: string
    color?: ColorValue
    width?: DimensionValue

}


export const AudioHandlerOutlinedSmall = ({ audioFile, audioTitle, color = DecidaColors.Sad, width = 200 }: Props) => {

    const [sound, setSound] = useState<Audio.Sound>();
    const [playState, setPlayState] = useState(false);
    const emotionColour = useReactiveVar(rvCard)?.colorScheme
    async function playSound() {
        const sound = await Audio.Sound.createAsync(
            audioFile
        );
        setSound(sound.sound);
        setPlayState(true)
        await sound.sound.playAsync();
    }

    async function stopSound() {
        setPlayState(false)
        await sound?.unloadAsync();
    }

    useEffect(() => {
        return sound
            ? () => {
                console.log('Unloading Sound');
                sound.unloadAsync();
            }
            : undefined;
    }, [sound]);


    return (
        <View>
            <DefaultButton onPress={playState ? stopSound : playSound} style={[styles.containerController, styles.transparentContainer, { width: width, height: 'auto', borderColor: color }]}>

                {
                    playState ? <Entypo name="controller-stop" size={35} color={color} /> : <Entypo name="controller-play" size={35} color={color} />
                }

                {playState ?
                    <DefaultText style={[styles.playButtonStyle, { color }]} >
                        Stop audio
                    </DefaultText>
                    :
                    <DefaultText style={[styles.playButtonStyle, { color }]} >
                        {audioTitle ? audioTitle : "Play audio"}
                    </DefaultText>
                }
            </DefaultButton>
        </View>
    )
}

const styles = StyleSheet.create({
    containerController: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 15,
        justifyContent: 'flex-start',

    } as ViewStyle,
    transparentContainer: {
        backgroundColor: 'transparent',
        borderWidth: 2,
        borderRadius: 20,
    } as ViewStyle,

    playButtonStyle: {
        fontWeight: 'bold',
        fontSize: 18,
    },
})
