import { WorkSheet, CellObject } from "xlsx"
import { getRangeFromWorksheetRef } from "./get-range-from-worksheet-ref"

type HasDynamicCloumnsType = boolean | undefined

type Params<Row extends {}, HasDynamicClasses extends HasDynamicCloumnsType> = {
    startingRow: number,
    /**
     * name of the fix columns in order so we can populate row object
     */
    columnOrder: (keyof Row)[],
    /**
     * used to specify if there are dynmic cloumns for classes
     */
    hasDynamicClasses?: HasDynamicClasses,
    sheet: WorkSheet,
    filter: (row: ReturnRow<Row>) => boolean,
}

type ReturnRow<Row extends {}, HasDynamicCloumns extends HasDynamicCloumnsType = false> = {
    [k in keyof Row]: string | undefined
} & { row: number } & (HasDynamicCloumns extends true ? { classes: string[] } : {})

export const convertSpreadsheetToRows = <Row extends {}, HasDynamicCloumns extends HasDynamicCloumnsType | undefined>({ startingRow, columnOrder, hasDynamicClasses: hasDynamicCloumns, sheet, filter }: Params<Row, HasDynamicCloumns>): ReturnRow<Row, HasDynamicCloumns>[] => {
    const { columns, noOfRows } = getRangeFromWorksheetRef(sheet['!ref']!)
    const rows: ReturnRow<Row, HasDynamicCloumns>[] = []
    const dynamicColumns = columns.splice(columnOrder.length)

    for (let rowIndex = startingRow; rowIndex <= noOfRows; rowIndex++) {
        const row = { row: rowIndex } as ReturnRow<Row, HasDynamicCloumns>
        columnOrder.forEach((col, index) => {
            row[col] = sheet[`${columns[index]}${rowIndex}`]?.v?.toString().trim()
        })

        if (hasDynamicCloumns) {
            const rowWithDynamicColumns = (row as ReturnRow<Row, true>)
            rowWithDynamicColumns.classes = []
            dynamicColumns.forEach(dc => {
                const value = sheet[dc + rowIndex.toString()]?.v as CellObject['v']
                if (value != null) {
                    rowWithDynamicColumns.classes.push(value.toString().trim())
                }
            })
        }

        if (filter(row)) {
            rows.push(row)
        }
    }

    return rows
}