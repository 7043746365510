import React, { Ref, RefObject, forwardRef } from 'react'
import { StyleProp, StyleSheet, TextInput, TextStyle, View } from 'react-native'
import { TeacherRole } from '../models'
import InputSearchComponentRounded, { InputSearchComponentType } from './input-search-component-rounded'
import { DefaultText } from './default-text'
import { DecidaColors } from '../../common/decida-colors'

type Props = {
    value: string
    setValue?: (text: string) => void,
    selected: string
    setSelected?: (data: (typeof ROLE_OPTIONS)[0]) => void
    placeholder?: string
    nextRef?: RefObject<TextInput>
    inputStyle?: StyleProp<TextStyle>
    error?: string
}

export const ROLE_OPTIONS = [
    {
        label: "Administration staff",
        value: TeacherRole.ADMINISTRATION,
    },
    {
        label: "Chaplain",
        value: TeacherRole.CHAPLAIN,
    },
    {
        label: "Class volunteer",
        value: TeacherRole.CLASS_VOLUNTEER,
    },
    {
        label: "Deputy Principal",
        value: TeacherRole.DEPUTY_PRINCIPAL,
    },
    {
        label: "Guidance Officer",
        value: TeacherRole.GUIDANCE_OFFICER,
    },
    {
        label: "Head of",
        value: TeacherRole.HEAD_OF,
    },
    {
        label: "Learning Support",
        value: TeacherRole.LEARNING_SUPPORT,
    },
    {
        label: "Principal",
        value: TeacherRole.PRINCIPAL,
    },
    {
        label: "Psychologist / counsellor",
        value: TeacherRole.PSYCHOLOGIST,
    },
    {
        label: "Teacher",
        value: TeacherRole.TEACHER,
    },
    {
        label: "Teacher aide",
        value: TeacherRole.TEACHER_AIDE,
    },
    {
        label: "Wellbeing",
        value: TeacherRole.WELLBEING,
    },
    {
        label: "Year Level Coordinator",
        value: TeacherRole.YEAR_LEVEL_COORDINATOR,
    },
]

export const getRoleByValue = (value: TeacherRole | null | undefined) => ROLE_OPTIONS.find((role) => role.value === value)?.label

const InputSearchTeacherRoleComponent = forwardRef(({ value, setValue, selected, setSelected, placeholder, nextRef, inputStyle, error }: Props, ref: Ref<TextInput>) => {

    const handleSelect = (d: (typeof ROLE_OPTIONS)[0]) => {
        setSelected && setSelected(d)
    }

    return (
        <View>
            <InputSearchComponentRounded ref={ref} nextRef={nextRef} placeholder={placeholder} value={value} onChangeText={setValue} data={ROLE_OPTIONS} selectedData={selected} setSelectedData={handleSelect} type={InputSearchComponentType.TeacherRole} inputStyle={inputStyle} />
            {error && <DefaultText style={styles.errorMessage}>{error}</DefaultText>}
        </View>
    )
})

const styles = StyleSheet.create({
    errorMessage: {
        color: DecidaColors.Red,
        fontSize: 14,
        marginBottom: 5,
        zIndex: -1
    },
})

export default InputSearchTeacherRoleComponent