import { gql, useQuery } from '@apollo/client'
import { Platform, StyleSheet, View, useWindowDimensions } from 'react-native'
import { CheckInSession, ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables, StudentCheckIn } from '../../common/API'
import { listStudentCheckInsByCheckInSessionId } from '../../common/graphql/queries'
import { Emotion } from '../models'
import { TeacherStatsBatteryGraphLastCheckIn } from './teacher-stats-battery-graph-last-check-in'
import { TeacherStatsHorizontalBarLastCheckIn } from './teacher-stats-horizontal-bar-last-check-in'
const TeacherHomeClassGraphs = ({ classCheckInSessions }: { classCheckInSessions: CheckInSession[] }) => {

    const { data: studentCheckInResponse } = useQuery<ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables>(gql`${listStudentCheckInsByCheckInSessionId}`, { variables: { checkinsessionID: classCheckInSessions?.length ? classCheckInSessions[0]?.id || "" : "", limit: 5000 }, skip: !classCheckInSessions?.[0]?.id })
    const lastStudentCheckIns = studentCheckInResponse?.listStudentCheckInsByCheckInSessionId?.items as StudentCheckIn[] || []
    const { width } = useWindowDimensions()

    const studentCheckinsTirednessData = lastStudentCheckIns.map((item) => item.tiredness)
    const lastStudentCheckinsTirednessSum = studentCheckinsTirednessData.reduce((a, b) => (a || 0) + (b || 0), 0) || 0
    const lastStudentCheckinsTirednessAvg = (lastStudentCheckinsTirednessSum / studentCheckinsTirednessData.length) || 0;

    const getStudentCheckinDataByEmotion = (emotion: Emotion) => {
        return lastStudentCheckIns.filter((item) => item.emotion === emotion).length
    }

    if (Platform.OS === 'web') {
        return < View style={styles.graphContainerWeb}>
            <TeacherStatsHorizontalBarLastCheckIn
                angry={getStudentCheckinDataByEmotion(Emotion.ANGRY)}
                anxious={getStudentCheckinDataByEmotion(Emotion.ANXIOUS)}
                excited={getStudentCheckinDataByEmotion(Emotion.EXCITED)}
                happy={getStudentCheckinDataByEmotion(Emotion.HAPPY)}
                sad={getStudentCheckinDataByEmotion(Emotion.SAD)}
                scared={getStudentCheckinDataByEmotion(Emotion.SCARED)}
            />
            <TeacherStatsBatteryGraphLastCheckIn energyValue={String(lastStudentCheckinsTirednessAvg.toFixed(2))} />
        </ View>
    }


    return (
        <View style={styles.graphContainerIOS}>
            <View style={styles.barGraphContainer}>
                <TeacherStatsHorizontalBarLastCheckIn
                    angry={getStudentCheckinDataByEmotion(Emotion.ANGRY)}
                    anxious={getStudentCheckinDataByEmotion(Emotion.ANXIOUS)}
                    excited={getStudentCheckinDataByEmotion(Emotion.EXCITED)}
                    happy={getStudentCheckinDataByEmotion(Emotion.HAPPY)}
                    sad={getStudentCheckinDataByEmotion(Emotion.SAD)}
                    scared={getStudentCheckinDataByEmotion(Emotion.SCARED)}
                />
            </View>
            <View style={styles.batteryGraphContainer}>
                <TeacherStatsBatteryGraphLastCheckIn energyValue={String(lastStudentCheckinsTirednessAvg.toFixed(2))} />
            </View>

        </View>
    )
}

const graphContainerHeight = 170
export default TeacherHomeClassGraphs
const styles = StyleSheet.create({
    batteryGraphContainer: {
        flex: 0.5,
        height: graphContainerHeight,
    },
    barGraphContainer: {
        flex: 1,
        flexGrow: 1,
        flexShrink: 0,
        height: graphContainerHeight,
        alignSelf: 'flex-start',
    },
    graphContainerWeb: {
        marginHorizontal: 20,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        zIndex: -1
    },
    graphContainerIOS: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        zIndex: -1
    },

})