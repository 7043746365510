import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaultTextWithDownArrow } from '../../../common/default-text-with-down-arrow'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { SingIcon } from '../../icons/sing-icon'
import { SingIconInverted } from '../../icons/sing-icon-inverted'

export const SwitchesActivitiesHealthyHabitsSing: CardTemplate = {
    cardId: "4635fbb0-d04c-4a6e-b1a5-d76faf9d1666",
    title: 'Sing',
    Header: SingIconInverted,
    Icon: SingIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Creating connection and a sense of belonging within the group."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={`While it does make it easier if you can play an instrument - don't let that stop you having sing time, even if you think you can't sing. Singing together can be as simple a learning some pop songs or singing along to a music track. `} />
            <DefaulTextWithTipsIcon textdata={"A good idea can be to play some pop music while you're doing art and encourage students to sing along with the music if it's a song they like."} />
            <DefaulTextWithTipsIcon textdata={"You might also get students to put in requests for songs that they like and create a sing-a-long playlist that you can repeat."} />
            <DefaulTextWithTipsIcon textdata={"Learning songs, chants and war cries that students can do at sporting events to cheer their team on can be a great way to get people singing."} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Practice makes perfect. The shower or bathroom is a fun place to make singing sounds and listen to them."} />
                <DefaultTextWithDownArrow textdata={"Join a local choir at school, church or a community group."} />
                <DefaultTextWithDownArrow textdata={"Immerse yourself in diverse music, concerts, bands, musicals, chanting… whatever takes your fancy!"} />
                <DefaultTextWithoutArrows textdata={"Get creative, write your own song and sing it with others."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.HEALTHY_HABITS,
    whyDoesItWorkText: '“Sing like no one’s listening, love like you’ve never been hurt, dance like no one’s watching and live like it’s heaven on earth.” — Mark Twain Singing lowers cortisol and relieves stress and tension. When people sing, endorphins and oxytocin are released by the brain which lowers stress and anxiety levels. Oxytocin also enhances feelings of trust and bonding effectively mitigating depression and feelings of loneliness.',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Sing.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.HEALTHY_HABITS],
    action: [CardActionType.Action],
    level: []
}


