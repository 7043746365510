import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { EiInRelationshipsLessonPlan } from './src/ei-in-relationships-lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

export const SwitchesLessonPlansEmotionalIntelligenceInRelationships: CardTemplate = {
    cardId: "40deeaed-6df2-4b46-8e7a-71f60fbc6b40",
    title: 'Emotional intelligence in relationships',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to understand the impact of emotions on relationships, and develop skills for building and maintaining positive connections.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by engaging the students in a group discussion about the importance of emotional intelligence in relationships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that emotional intelligence refers to the ability to recognise, understand, manage, and effectively use one's own emotions as well as the ability to recognise, understand, and influence the emotions of others. The components of emotional intelligence, as proposed by psychologist Daniel Goleman, include:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Self Awareness:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Recognition of one's own emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Understanding the impact of emotions on thoughts and behaviour.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Having a realistic self assessment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Self Regulation:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Managing and controlling one's own emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Being adaptable and flexible in various situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Demonstrating self control and resilience.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Motivation:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Having a passion for achieving goals.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Being driven to improve and succeed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Demonstrating commitment and initiative.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Empathy:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Recognising and understanding the emotions of others.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Showing compassion and considering others' perspectives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Demonstrating the ability to connect with people on an emotional level.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Social Skills:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Building and maintaining positive relationships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Effective communication and conflict resolution.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>▪ Demonstrating teamwork and collaboration.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- These components collectively contribute to an individual's ability to navigate social situations, build meaningful relationships, and manage their own emotional responses in a healthy and constructive manner. Developing emotional intelligence can lead to improved interpersonal relationships, enhanced communication, and greater success in personal and professional endeavours.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to share examples of how emotions can influence interactions and the quality of relationships.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Emotions in Relationships (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Brainstorm the impact of emotions on relationships, including both positive and negative effects. Look for:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Positive Effects of Emotions on Relationships:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Connection and Bonding: Emotions help us connect and bond with others. When we express positive emotions and feelings like happiness, love, or excitement, it creates a joyful and warm atmosphere in relationships. It strengthens the bond and brings people closer together.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Understanding and Empathy: Emotions allow us to understand and empathise with others. When we feel empathy, it means we can understand and share the feelings of someone else. This helps us support and comfort our friends, family, and peers when they're going through difficult times.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Communication and Expression: Emotions play a crucial role in communication. They help us express our thoughts and feelings to others. When we share our emotions honestly and respectfully, it helps build trust and understanding in relationships. It allows others to know us better and creates a space for open and meaningful conversations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Problem Solving: Emotions can contribute to problem-solving in relationships. When we experience negative emotions like anger or frustration, it signals that something might be wrong. It prompts us to address issues and find solutions together, leading to growth and stronger connections.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Negative Effects of Emotions on Relationships:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Conflict and Misunderstandings: Emotions can sometimes lead to conflicts and misunderstandings in relationships. When we experience strong negative emotions or feelings, like anger or jealousy, it can affect our judgment and communication. It's important to manage these emotions and find healthy ways to address conflicts to maintain positive relationships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Hurt Feelings: Emotions can sometimes lead to hurt feelings in relationships. When we say or do things without considering the impact of our emotions, it can hurt others. It's important to be mindful of our words and actions, and to apologise and make amends when we unintentionally hurt someone.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Emotional Distance: Strong negative emotions, if not properly managed, can create emotional distance between people. It can lead to withdrawal or a lack of connection, making it difficult to maintain healthy relationships. It's important to address and communicate about emotions to prevent emotional distance from growing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Lack of Understanding: If we don't recognise or understand our own emotions, it can make it challenging to understand and support the emotions of others. This can lead to misunderstandings, conflicts, and difficulties in building strong relationships.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Building Positive Connections (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present strategies for building and maintaining positive relationships, such as active listening, effective communication, and validating emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ This activity promotes active listening, effective communication, and empathy. It helps students practice asking thoughtful questions, listening attentively, and engaging in meaningful conversations. It also provides an opportunity for students to find common interests and connections with their peers, fostering positive relationships within the classroom.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Pair up students and have them sit facing each other.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Each student takes turns interviewing their partner for a set period of time, such as 3 minutes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- The interviewer asks questions to get to know their partner better, such as their favourite hobbies, interests, or memorable experiences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- The listener practices active listening by paying attention to their partner's responses, maintaining eye contact, and nodding or providing verbal cues to show they are engaged.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- After the set time, students switch roles, and the listener becomes the interviewer.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Encourage students to ask follow-up questions or share their own experiences related to the topic being discussed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Once both partners have had a chance to interview and listen, reconvene as a class and allow students to share interesting things they learned about their partner with the whole group.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief to discuss the impact of active listening, feeling ‘heard’ and the importance this has on building sustainable quality relationships.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Closure (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on their relationship building skills and complete <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='the worksheet.' link={Worksheets.relationshipBuildingSkillWorksheet.uri} /></DefaultText>
                        <View style={CommonStyles.lessonPlanImageContainer}>
                            <EiInRelationshipsLessonPlan />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of emotional intelligence, empathy, and compassion in fostering healthy relationships.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Slides' link={'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Emotional+Intelligence+in+relationships.pptx'} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Relationship building skills worksheet ' link={Worksheets.relationshipBuildingSkillWorksheet.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• White board/chart and markers</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Creating connections that help young people thrive -</DefaultText>
                        <DefaultTextWithLink text='https://sites.ed.gov/nsaesc/files/2017/07/12758351-0-FINALRelationships-F1.pdf' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Strong relationships, strong health -</DefaultText>
                        <DefaultTextWithLink text='https://www.betterhealth.vic.gov.au/health/healthyliving/Strong-relationships-strong-health' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}
