import { Activities } from '../../common/activities'
import { ActivityGroupTemplate } from '../../common/activity-group-template'

import { FunAndPositiveIcon } from '../icons/fun-and-positive-icon'

export const SwitchesActivityGroupForFunAndPositive: ActivityGroupTemplate = {
    title: 'Fun and positive',
    Icon: FunAndPositiveIcon,
    activity: Activities.FUN_AND_POSITIVE,
    subtitle: ""
}
