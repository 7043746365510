import { StyleSheet, View } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'
import { Page } from '../common/page'
import { IconSwitch4SchoolsEW } from '../icon/icon-switch-4-schools-ew'

export const StudentJoinClassFinished = () => {
    return (
        <Page style={styles.page}>
            <View style={styles.inputContainer}>
                <IconSwitch4SchoolsEW height={160} width={165} marginTop={20} marginBottom={20} />
                <DefaultText children={"Almost there!"} style={styles.subtitle} />
                <View style={styles.buttonSpacing}>
                    <DefaultText children={"Check your email"} style={styles.title} />
                    <DefaultText children={"for temporary password and to complete registration."} style={styles.heading} />
                    <DefaultText children={"Check your junk mail folder if you don’t get an email in the next couple of minutes."} style={styles.subHeading} />
                </View>
            </View>
        </Page>
    )
}

const styles = StyleSheet.create({

    page: {
        backgroundColor: DecidaColors.White,
    },
    inputContainer: {
        flexDirection: 'column',
        width: 350,
        alignItems: 'center'
    },
    logostyle: {
        backgroundColor: DecidaColors.Black,
        borderRadius: 12,
        padding: 20,
        width: "95%"
    },
    title: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    heading: {
        textAlign: 'center',
        fontSize: 18,

    },
    subtitle: {
        color: DecidaColors.Green,
        fontSize: 40,
        marginTop: 25,
    },
    buttonSpacing: {
        marginVertical: 30,
    },
    subHeading: {
        fontSize: 13,
        textAlign: 'center',
        padding: 20,
    }
})

