import { Message, Sheet } from "./common-import-school-data-const-and-types";

export const validateFieldNotUsedInPreviousRows = <Row extends { row: number } & { [K: string]: any }>(rows: Row[], field: keyof Row, currentIndex: number, sheet: Sheet, errors: React.MutableRefObject<Message[]>) => {
    const currentRow = rows[currentIndex]

    if (currentRow[field] == null || currentRow[field] === '') {
        return true
    }

    for (let i = 0; i < currentIndex; i++) {
        if (rows[i][field] === currentRow[field]) {
            errors.current.push({ sheet, row: currentRow.row, message: `${field.toString()} "${currentRow[field]}" already used in row ${rows[i].row}` })
            return false
        }
    }

    return true
}