import Svg, { Path, SvgProps } from 'react-native-svg';

const IconCheckInClosed = (props: SvgProps) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width || 28}
    height={props.height || 28}
    viewBox='0 0 28 28'
    {...props}
  >
    <Path
      id='np_cross_1995938_000000'
      d='M6.734,6.734a13.989,13.989,0,1,1,0,19.783A13.962,13.962,0,0,1,6.734,6.734Zm3.628,5.618,1.989-1.989,4.256,4.256,4.256-4.256,2.024,1.989-4.256,4.256,4.256,4.256-2.024,2.024-4.256-4.256-4.256,4.256-1.989-2.024,4.256-4.256Z'
      transform='translate(-2.626 -2.626)'
      fill='#707070'
      fillRule='evenodd'
    />
  </Svg>
);
export default IconCheckInClosed;
