import { View, Platform, Image } from 'react-native'

interface Props {
    width?: number
    marginTop?: number
    height?: number
    marginBottom?: number
}

const image = require('./icon-switch-4-schools-new.png')

export const IconSwitch4SchoolsNewLogo = ({ height, width, marginBottom, marginTop, }: Props) => (
    <View style={{ marginBottom, marginTop }}>
        <Image style={{ width, height }} source={Platform.OS === 'web' ? { uri: image } : image} />
    </View >
)