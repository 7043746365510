import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as Clipboard from 'expo-clipboard';
import { useEffect, useState } from 'react';
import { GestureResponderEvent, Pressable, StyleSheet, View } from 'react-native';
import uuid from 'react-native-uuid';
import QRCode from 'react-qr-code';
import { Class, GetClassQueryVariables, GetSchoolQuery, GetSchoolQueryVariables, GetStudentClassByClassIdQuery, GetStudentClassByClassIdQueryVariables, StudentClass, UpdateClassMutation, UpdateClassMutationVariables } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { updateClass } from '../../common/graphql/mutations';
import { getStudentClassByClassId } from '../../common/graphql/queries';
import ButtonResetPasswordClassStudents, { ResetPasswordButtonTypes } from '../common/button-reset-password-class-students';
import CopyTextAndIcon from '../common/copy-text-and-icon';
import { DefaultSwitch } from '../common/default-switch';
import { DefaultText } from '../common/default-text';
import { Divider } from '../common/divider';
import { rvIsLoading } from '../common/loading';
import RemoveClassButton from '../common/remove-class-button';
import { Stepper } from '../common/stepper';
import { InfoIcon } from '../common/svg-files/info-icon';
import ToastAlert from '../common/toast-alert';
import { showAlert } from '../common/universal-alert';
import { schoolAdminGetClassQuery, SchoolAdminGetClassQueryResponse } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-class-query';
import { getSchoolQuery } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-query';
import { envWebUrl } from '../env';
import { rvParentIntensityState } from '../parent/parent-state';
import TeacherClassAdminRequestForChats from '../teacher/teacher-class-admin-request-for-chats';
import { rvTeacherEatingQuestionInfoModal, rvTeacherEmotionIntensityScaleModal, rvTeacherIndividualStudentStatsInfoModal, rvTeacherRequestForChatInfoModal, rvTeacherWellbeingInfoModal } from '../teacher/teacher-state';
import { rvSidebarClassID } from './school-admin-state';



const SchoolAdminClassSettings = () => {

    const [toastMessage, setToastMessage] = useState(false)
    const currentSidebarClassID = useReactiveVar(rvSidebarClassID)

    const { data } = useQuery<SchoolAdminGetClassQueryResponse, GetClassQueryVariables>(schoolAdminGetClassQuery, { variables: { id: currentSidebarClassID }, skip: !currentSidebarClassID })
    const currentClass = data?.getClass as Class

    const { data: schoolResponse } = useQuery<GetSchoolQuery, GetSchoolQueryVariables>(getSchoolQuery, { variables: { id: currentClass?.schoolID || "" }, skip: !currentClass })
    const school = schoolResponse?.getSchool

    const { data: studentClassResponse } = useQuery<GetStudentClassByClassIdQuery, GetStudentClassByClassIdQueryVariables>(gql`${getStudentClassByClassId}`, { variables: { classID: currentClass?.id || "", limit: 5000 }, skip: !currentClass })
    const currentStudentClasses = studentClassResponse?.getStudentClassByClassId?.items.filter((item) => item?._deleted !== true) as StudentClass[]

    const [updateClassMutation] = useMutation<UpdateClassMutation, UpdateClassMutationVariables>(gql`${updateClass}`, {})

    const newId = currentClass?.bulkSignUpId || uuid.v4().toString()
    const classLoginQRLink = `${envWebUrl}/JoinClass/studentJoinClass/${newId}`

    useEffect(() => {
        if (currentClass?.bulkSignUpId === null) {
            updateClassSettings({
                bulkSignUpId: newId
            })
        }
    }, [currentClass])


    const updateClassSettings = async (settings: {
        [K in keyof Class]?: Class[K];
    }) => {
        if (currentClass) {
            const { id, _version } = currentClass;
            rvIsLoading(true)
            await updateClassMutation({
                variables: {
                    input: {
                        id,
                        _version,
                        ...settings,
                    },
                },
            });
            rvIsLoading(false)
        }
    };

    const changeEmotionalIntensity = async (newLevel: number) => {
        await updateClassSettings({
            emotionIntensityLevel: newLevel,
            toggleIndividualStudentStats: newLevel === 1 ? false : undefined,
        });

        rvParentIntensityState(newLevel);
    };

    const changeToggleChatToTeacher = async (switchStatus: boolean) => {
        await updateClassSettings({
            toggleRequestForChat: switchStatus,
            toggleRequestForChatNotifications: switchStatus,
        });
    };

    const changeToggleEating = async (eatingStatus: boolean) => {
        await updateClassSettings({
            toggleEating: eatingStatus,
        });
    };

    const changeToggleIndividualStats = async (individualStatus: boolean) => {

        await updateClassSettings({
            toggleIndividualStudentStats: individualStatus,
        })
    }

    const changeToggleWellbeing = async (wellbeingStatus: boolean) => {
        await updateClassSettings({
            wellbeing: wellbeingStatus
        })
    }

    const copyQRLikToClipboard = (e?: GestureResponderEvent) => {
        e?.preventDefault()
        Clipboard.setStringAsync(classLoginQRLink);
        setToastMessage(true)
    };


    const showClassJoinCode = async () => {

        if (currentClass) {

            copyQRLikToClipboard()
            showAlert({
                title: 'Join class link.',
                message: <View style={{ alignItems: 'center', justifyContent: 'center' }}><QRCode value={classLoginQRLink} /><DefaultText style={{ textAlign: 'center', fontSize: 12, paddingTop: 10, }} children={`Link has also been copied to your clipboard.`}></DefaultText><DefaultText style={{ textAlign: 'center', fontSize: 12, paddingTop: 10, }} children={`${envWebUrl}/JoinClass/studentJoinClass/${newId}`}></DefaultText></View>,
                leftButtonText: 'Ok',
            })
        }
    }


    const showInfoEmotionIntensityScale = () => {
        rvTeacherEmotionIntensityScaleModal(true)
    }

    const showInfoRequestForChat = () => {
        rvTeacherRequestForChatInfoModal(true)
    }

    const showWellbeingInfoModal = () => {
        rvTeacherWellbeingInfoModal(true)
    }

    const showInfoIndividualStudentStats = () => {
        rvTeacherIndividualStudentStatsInfoModal(true)
    }

    const showEatingQuestionInfoModal = () => {
        rvTeacherEatingQuestionInfoModal(true)
    }

    return (
        <View>
            <View style={styles.infoSectionContainer}>
                <View style={styles.textInfoContainer}>
                    <DefaultText style={styles.textInfo}>Emotion intensity scale</DefaultText>
                    <Pressable onPress={showInfoEmotionIntensityScale} style={styles.infoIcon}>
                        <InfoIcon />
                    </Pressable>
                </View>
                <Stepper
                    values={[
                        { value: 1 },
                        { value: 3 },
                        { value: 5 },
                        { value: 7 },
                    ]}
                    currentValue={currentClass?.emotionIntensityLevel || 3}
                    onChange={changeEmotionalIntensity}
                    width={100}
                    height={38}
                    SelectedFontSize={24}
                    fontSize={15}
                />
            </View>

            <View style={styles.infoSectionContainer}>
                <View style={styles.textInfoContainer}>
                    <DefaultText style={styles.textInfo}>Individual student stats</DefaultText>
                    <Pressable onPress={showInfoIndividualStudentStats} style={styles.infoIcon}>
                        <InfoIcon />
                    </Pressable>
                </View>
                <DefaultSwitch value={currentClass?.toggleIndividualStudentStats || false} onChange={changeToggleIndividualStats} />
            </View>
            {school?.wellbeingFeature && (
                <View style={styles.infoSectionContainer}>
                    <View style={styles.textInfoContainer}>
                        <DefaultText style={styles.textInfo}>Wellbeing question set</DefaultText>
                        <Pressable onPress={showWellbeingInfoModal} style={styles.infoIcon}>
                            <InfoIcon />
                        </Pressable>
                    </View>
                    <DefaultSwitch value={currentClass.wellbeing || false} onChange={changeToggleWellbeing} />
                </View>
            )}
            <View style={styles.infoSectionContainer}>
                <View style={styles.textInfoContainer}>
                    <DefaultText style={styles.textInfo}>Eating question</DefaultText>
                    <Pressable onPress={showEatingQuestionInfoModal} style={styles.infoIcon}>
                        <InfoIcon />
                    </Pressable>
                </View>
                <DefaultSwitch value={currentClass?.toggleEating || false} onChange={changeToggleEating} />
            </View>
            <View style={styles.infoSectionContainer}>
                <View style={styles.textInfoContainer}>
                    <DefaultText style={styles.textInfo}>Request teacher chat</DefaultText>
                    <Pressable onPress={showInfoRequestForChat} style={styles.infoIcon}>
                        <InfoIcon />
                    </Pressable>
                </View>
                <DefaultSwitch value={currentClass?.toggleRequestForChat || false} onChange={changeToggleChatToTeacher} />
            </View>

            {currentClass?.toggleRequestForChat && <TeacherClassAdminRequestForChats />}


            <DefaultText style={[styles.textInfo, { marginTop: 10 }]}>Student join class QR code</DefaultText>
            <View style={[styles.infoSectionContainer, { paddingHorizontal: 20, maxHeight: 120 }]}>
                <QRCode value={classLoginQRLink} style={styles.qrCode} />
                <Pressable onPress={showClassJoinCode} style={styles.expandButton}>
                    <MaterialCommunityIcons name="arrow-expand-all" size={60} color={DecidaColors.Green} />
                    <DefaultText style={styles.expandText}>Expand</DefaultText>
                </Pressable>
            </View>

            <View style={styles.joinClassUrlSectionContainer}>
                <DefaultText style={styles.textInfo}>Student join class URL</DefaultText>
                <View style={styles.copyUrlTextIcon}>
                    <DefaultText style={{ fontSize: 14, color: DecidaColors.GrayText, }}>{classLoginQRLink}</DefaultText>
                    <CopyTextAndIcon onPress={copyQRLikToClipboard} title='Copy URL' />
                </View>
                <View style={{ position: 'absolute', bottom: -20, left: 0, right: 0 }}>
                    <ToastAlert visible={toastMessage} setVisible={setToastMessage} message='Url has been copied to your clipboard' />
                </View>
            </View>

            <ButtonResetPasswordClassStudents studentClasses={currentStudentClasses} type={ResetPasswordButtonTypes.SchoolAdminSidebar} />

            <Divider />
            <RemoveClassButton claz={currentClass} />
        </View>
    )
}
export default SchoolAdminClassSettings



const styles = StyleSheet.create({
    joinClassUrlSectionContainer: {
        marginTop: 20,
        paddingBottom: 20,
    },
    expandButton: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    copyUrlTextIcon: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 10,
    },
    expandText: {
        fontSize: 16,
        color: DecidaColors.Green,
        fontWeight: '800',
    },
    qrCode: {
        flex: 1,
        width: '60%',
        height: '60%',
    },
    infoIcon: {
        width: 24,
        height: 24,
        marginBottom: 10,
    },
    textInfo: {
        fontSize: 14,
        color: DecidaColors.AppleSystemGray2Dark,
    },
    textInfoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4
    },
    infoSectionContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },

})