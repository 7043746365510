import { DimensionValue, StyleSheet, View, ViewStyle } from 'react-native'
import { DefaultButton } from './default-button'
import { DecidaColors } from '../../common/decida-colors'

export enum StepperType {
    Default,
    Simple
}

interface Props<V> {
    values: { value: V, label?: string }[],
    currentValue: V
    onChange: (newValue: V) => void,
    width: DimensionValue,
    height?: number,
    fontSize?: number,
    SelectedFontSize?: number,
    type?: StepperType
}

export const Stepper = <V,>({
    values,
    currentValue,
    onChange,
    width,
    height,
    fontSize,
    SelectedFontSize,
    type = StepperType.Default
}: Props<V>) => {

    let textcolorselected = DecidaColors.Green
    let textcolornotselected = DecidaColors.White

    switch (type) {
        case StepperType.Simple:
            textcolorselected = DecidaColors.AppleSystemGray2Dark
            textcolornotselected = DecidaColors.AppleSystemGray2Dark
            break;

        default:
            break;
    }

    return (
        <View style={[
            type === StepperType.Default ? styles.container : styles.containerSimple,
            { width, height, }]}
        >
            {
                values.map(({ value, label }) => {
                    const onValuePress = () => {
                        onChange(value)
                    }
                    return (
                        <DefaultButton
                            key={`${value}`}
                            buttonTextColor={value === currentValue ? textcolorselected : textcolornotselected}
                            buttonFontSize={value == currentValue ? SelectedFontSize : fontSize}
                            buttonTextStyle={StepperType.Default ? styles.buttonTextStyle : undefined}
                            onPress={onValuePress}
                            style={
                                value === currentValue && type === StepperType.Default ? styles.selected :
                                    value === currentValue && type === StepperType.Simple ? styles.selectedSimple :
                                        type === StepperType.Default ? styles.notSelected :
                                            styles.notSelectedSimple
                            }
                        >
                            {label || String(value)}
                        </DefaultButton>
                    )
                })
            }
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: DecidaColors.Green,
        borderRadius: 40,
        paddingLeft: 10,
        paddingRight: 10,
    } as ViewStyle,
    containerSimple: {
        flexDirection: 'row',
        paddingLeft: 10,
        paddingRight: 10
    },
    selected: {
        flex: 1,
        borderRadius: 200,
        margin: 0,
        backgroundColor: DecidaColors.White,
        color: DecidaColors.Green,
        height: 24,

    },
    selectedSimple: {
        flex: 1,
        margin: 0,
        height: 34,
        backgroundColor: 'transparent',
        borderBottomWidth: 3,
        borderColor: DecidaColors.Green,
        borderRadius: 0
    },
    notSelected: {
        flex: 1,
        backgroundColor: DecidaColors.Green,
        borderRadius: 200,
        margin: 0,
        height: 24,

    },
    notSelectedSimple: {
        flex: 1,
        margin: 0,
        height: 34,
        backgroundColor: 'transparent',
        borderBottomWidth: 1,
        borderColor: DecidaColors.AppleSystemGray2Dark,
        borderRadius: 0
    },
    buttonTextStyle: {
        fontWeight: '600'
    }
})