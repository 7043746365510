import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { CardActionType, CardCategoryType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTimeType, CardType, StudentCardCategoryType } from '../switches/common/card-template'
import { CardVideoAgeGroupTypes, CardVideoFilterEmotionType, CardVideoTimeTypes } from '../switches/tools/cards/video-library'
import { CheckBox } from './checkbox'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from './default-text'
import { SwitchCardFilterType } from './switch-card-filter'
import { CardActivitySheetsEmotionFilter, CardActivitySheetsThoughtActionFilter, CardActivitySheetsTimeFilter } from './worksheets-imports'
export type SwitchFilterGroupType =
    typeof CardType |
    typeof CardTimeType |
    typeof CardActionType |
    typeof CardPeopleType |
    typeof CardPlaceType |
    typeof CardIntensitylevelType |
    typeof CardCategoryType |
    typeof StudentCardCategoryType |
    typeof CardFilterEmotionType |
    typeof CardVideoFilterEmotionType |
    typeof CardVideoTimeTypes |
    typeof CardVideoAgeGroupTypes |
    typeof CardActivitySheetsEmotionFilter |
    typeof CardActivitySheetsThoughtActionFilter |
    typeof CardActivitySheetsTimeFilter

type Props = {
    group: SwitchFilterGroupType
    filters: SwitchCardFilterType,
    setFilters: (data: SwitchCardFilterType) => void
    groupType: keyof SwitchCardFilterType
    label: string
}

const SwitchCardFilterGroupByType = ({ group, filters, setFilters, groupType, label }: Props) => {

    const mapCardTypesToArray = (types: SwitchFilterGroupType) => {
        return Object.values(types)
    }

    const arrayFilter = mapCardTypesToArray(group)

    const handleFilter = (value: string, sub: keyof SwitchCardFilterType) => {
        let currentFilter = {
            main: filters.main,
            time: filters.time,
            place: filters.place,
            people: filters.people,
            level: filters.level,
            emotion: filters.emotion,
            category: filters.category,
            thoughtOrAction: filters.thoughtOrAction
        }


        if (sub === "main") {
            currentFilter.category = []
            currentFilter.emotion = []
            currentFilter.level = []
            currentFilter.people = []
            currentFilter.place = []
            currentFilter.thoughtOrAction = []
            currentFilter.time = []
        }

        if (currentFilter[sub]) {
            const isExisted = currentFilter[sub]?.some((f) => f === value)

            if (isExisted) {
                currentFilter[sub] = currentFilter[sub]?.filter(f => f !== value)

                setFilters(currentFilter)
                return
            }

            if (sub === 'main') {
                currentFilter[sub] = [value]
            } else {
                currentFilter[sub] = [...currentFilter[sub], value]
            }
            setFilters(currentFilter)
        }

    }

    const getFilterValue = (value: string, sub: keyof SwitchCardFilterType) => {
        return filters[sub] && filters[sub].some((f) => f === value)
    }

    return (
        <>
            {label !== "" && (
                <DefaultText style={styles.filterSubGroupTitle}>{label}</DefaultText>
            )}
            <View style={styles.container}>
                {arrayFilter.map((filt, index) =>
                    <CheckBox key={String(index)} checked={getFilterValue(filt, groupType)} type='rounded' label={filt} labelStyle={styles.itemText} containerStyle={styles.itemContainer} onChange={() => handleFilter(filt, groupType)} />
                )}
            </View>
        </>
    )
}
export default SwitchCardFilterGroupByType

const styles = StyleSheet.create({
    container: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    itemContainer: {
        minWidth: '48%',
        maxWidth: '100%',
        flexDirection: 'row',
        marginBottom: 8,
        paddingRight: 10,
    },
    itemText: {
        fontSize: 18,
        color: DecidaColors.Gray
    },
    filterSubGroupTitle: {
        fontSize: 18,
        marginVertical: 5
    },

})