import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { Br } from '../common/br'
import { DefaultFontSize } from '../common/const'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { useResponsive } from '../common/use-responsive'
import { DashboardNewFeatureInfoImage1 } from './assets/dashboard-new-feature-info-image1'
import { DashboardNewFeatureInfoImage2 } from './assets/dashboard-new-feature-info-image2'

type Props = {
    handleNext: () => void
    isLastStep?: boolean
    handlePrevious?: () => void
}

const TeacherDashboardNewFeatureInfoSteps1 = ({ handleNext }: Props) => {
    const { responsiveFontSize } = useResponsive()
    return (
        <View style={styles.content}>
            <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                Previously, you would access the check-in service, admin portal and stats page by navigating to the top of your screen.
            </DefaultText>
            <Br />
            <View style={[styles.imageContainer, { height: 100 }]}>
                <DashboardNewFeatureInfoImage1 />
            </View>
            <Br />
            <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                Now, you will find these features in your dashboard
                (accessible though the ‘Home’ button).
            </DefaultText>
            <Br />

            <View style={styles.imageContainer}>
                <DashboardNewFeatureInfoImage2 />
            </View>

            <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                Let us give you a little more information about the new Dashboard!
            </DefaultText>

            <View style={styles.buttonContainer}>
                <DefaultButton style={styles.nextButton} buttonTextStyle={[styles.nextText, { fontSize: responsiveFontSize(24) }]} onPress={handleNext}>
                    {"NEXT"}
                </DefaultButton>
            </View>
        </View>
    )
}



export {
    TeacherDashboardNewFeatureInfoSteps1
}
const styles = StyleSheet.create({
    buttonContainer: {
        alignItems: 'center',
        width: '100%',
    },
    image3Container: {
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
        maxHeight: 450,
        backgroundColor: 'white',
        marginBottom: 10
    },
    bottomContainer: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    content: {
        padding: 20,
        height: '100%',
        justifyContent: 'space-between'
    },
    contentContainer: {
        flex: 1,
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-evenly'
    },
    contentContainerLeft: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%'
    },
    buttonsContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 20,
        gap: 10
    },
    step3ButtonContainer: {
        flex: 1,
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-between'
    },
    nextButton: {
        width: 150,
        borderRadius: 20,
    },
    nextText: {
        fontSize: 24,
        fontWeight: 'bold'
    },
    imageContainer: {
        width: '100%',
        height: 200,
        backgroundColor: DecidaColors.White,
        marginBottom: 10,
        flexDirection: 'row',
        alignItems: 'center'
    },
    stepText: {
        textAlign: 'center',
    },
    text: {
        textAlign: 'center',
        fontSize: DefaultFontSize - 2,
    },
})