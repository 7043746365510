import moment from 'moment'
import { Fragment } from 'react'
import { StyleSheet, TextStyle, View } from 'react-native'
import { NewIcon } from '../icon/icon-new'
import { Videos } from '../models'
import { YoutubeSquareIcon } from '../switches/tools/icons/youtube-square-icon'
import { DefaultText } from './default-text'
import { useResponsive } from './use-responsive'
import VideoWebview from './video-webview'

type Props = {
    video: Videos
    titleStyle?: TextStyle
}
const VideoCard = ({ video, titleStyle }: Props) => {
    const { width, height, isLargeScreen } = useResponsive()
    const quarterWidth = width * 0.22

    const last4Weeks = moment().subtract(28, 'd').startOf('day').toISOString()
    const isNew = video.createdAt !== undefined && moment(video.createdAt).isSameOrAfter(last4Weeks)
    const isYoutube = video.url.includes("youtube")

    return (
        <Fragment key={video.url} >
            <View style={[styles.videoContainer, { width: width > 1000 ? quarterWidth : isLargeScreen ? width / 3 : '100%' }]}>
                <VideoWebview width={'100%'} height={isLargeScreen ? quarterWidth * 0.6 : height / 3} video={video} />

                <DefaultText style={[styles.titleText, titleStyle ]} textProps={{ numberOfLines: 3 }}>
                    {video.title}
                </DefaultText>
                <View style={styles.iconsContainer}>
                    {
                        isYoutube && (
                            <View style={styles.youtubeIconContainer}>
                                <YoutubeSquareIcon />
                            </View>
                        )
                    }
                    {
                        isNew && (
                            <NewIcon key={video.url} width={50} height={50} style={styles.newIcon} />
                        )
                    }
                </View>

            </View>
        </Fragment>
    )
}

export default VideoCard


const styles = StyleSheet.create({
    videoContainer: {
        marginRight: 10,
        marginBottom: 20,
    },
    mainContainer: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20,
    },
    newIcon: {
        marginLeft: 5
    },
    titleText: {
        paddingTop: 10
    },
    iconsContainer: {
        flexDirection: 'row',
        position: 'absolute',
        right: 0,
        top: 0,
        overflow: 'hidden',
    },
    youtubeIconContainer: {
        width: 50,
        height: 50,
        borderRadius: 4,
        overflow: 'hidden'
    }
})