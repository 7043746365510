import { StyleSheet, View } from 'react-native'
import { DefaultFontSize } from './const'
import { DefaultText } from './default-text'
import { DecidaColors } from '../../common/decida-colors'

type Props = {
    cognitoUsername: string,
    email: string
}

const PopoverMenuProfileUsernameAndEmailInfo = ({ cognitoUsername, email }: Props) => {
    return (
        <View style={styles.currentUser}>
            <View style={styles.dividerButton}>
                <View style={styles.profileInfoContainer}>
                    <DefaultText style={styles.text}>
                        {cognitoUsername}
                    </DefaultText>
                    <DefaultText style={styles.text}>
                        ({email})
                    </DefaultText>
                </View>
            </View>
        </View>
    )
}
export default PopoverMenuProfileUsernameAndEmailInfo
const styles = StyleSheet.create({
    currentUser: {
        position: 'relative',
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
        paddingHorizontal: 15,
        paddingVertical: 10
    },
    logout: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    profile: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    text: {
        color: DecidaColors.Gray,
        alignItems: 'flex-end',
        justifyContent: 'center',
        fontSize: DefaultFontSize - 2,

    },
    dividerButton: {
        width: "100%",
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    profileInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-end',
    },

})