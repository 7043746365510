import { View, StyleSheet, StyleProp, TextStyle, ViewStyle, Dimensions } from "react-native";
import { DefaultText } from "../../common/default-text";
import { rvCard } from "./card-color-scheme";
import { useReactiveVar } from "@apollo/client";
import { Entypo } from '@expo/vector-icons';

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600
interface Props {
    textdata: string | string[] | undefined | null | React.ReactNode
    style?: StyleProp<TextStyle>
    children?: string | string[] | undefined | null | React.ReactNode

}


export const DefaulttextWithRightArrowLeftAlignedIndentation = ({ textdata, style, children }: Props) => {
    const color = useReactiveVar(rvCard)?.colorScheme
    return (
        <View style={[styles.content, style]}>
            <View style={styles.containingView}>
                <View style={styles.icon}>
                    <View style={styles.extraTipsIcon}>
                        <Entypo name="arrow-bold-right" size={24} color={color} />
                    </View>
                </View>
                <DefaultText style={styles.text}>
                    {children || textdata}
                </DefaultText>
            </View>
        </View>
    )

}



const styles = StyleSheet.create({

    icon: {
    },
    content: {
        justifyContent: 'center',
        marginVertical: 4,


    } as ViewStyle,
    text: {
        textAlign: 'auto'

    },
    extraTipsIcon: {


    },
    containingView: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginLeft: windowWidthcondition ? 25 : 0,
    }
})

