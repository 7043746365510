/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onMutateCampusBySchoolID = /* GraphQL */ `subscription OnMutateCampusBySchoolID($schoolID: ID!) {
  onMutateCampusBySchoolID(schoolID: $schoolID) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateCampusBySchoolIDSubscriptionVariables,
  APITypes.OnMutateCampusBySchoolIDSubscription
>;
export const onMutateCheckInSessionByClassId = /* GraphQL */ `subscription OnMutateCheckInSessionByClassId($classID: ID!) {
  onMutateCheckInSessionByClassId(classID: $classID) {
    id
    checkInSessionClassSequenceNo
    createdAt
    updatedAt
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    openAt
    closeAt
    isActive
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateCheckInSessionByClassIdSubscriptionVariables,
  APITypes.OnMutateCheckInSessionByClassIdSubscription
>;
export const onMutateClassById = /* GraphQL */ `subscription OnMutateClassById($id: ID!) {
  onMutateClassById(id: $id) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroupID
    students {
      nextToken
      startedAt
      __typename
    }
    classLogin {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    checkInSessions {
      nextToken
      startedAt
      __typename
    }
    supports {
      nextToken
      startedAt
      __typename
    }
    emotionIntensityLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    toggleRequestForChat
    toggleRequestForChatNotifications
    toggleIndividualStudentStats
    teachers {
      nextToken
      startedAt
      __typename
    }
    schedules {
      nextToken
      startedAt
      __typename
    }
    bulkSignUpId
    createdAt
    updatedAt
    primaryContact
    archived
    emailReceivers
    lastCheckInSessionClassSequenceNo
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    activeStudents
    goMode
    yearLevel
    lastCheckInTime
    wellbeing
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestionID
    wellbeingResponseCount
    toggleEating
    _version
    _deleted
    _lastChangedAt
    classClassLoginId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateClassByIdSubscriptionVariables,
  APITypes.OnMutateClassByIdSubscription
>;
export const onMutateClassSubscriptions = /* GraphQL */ `subscription OnMutateClassSubscriptions($schoolID: ID!) {
  onMutateClassSubscriptions(schoolID: $schoolID) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroupID
    students {
      nextToken
      startedAt
      __typename
    }
    classLogin {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    checkInSessions {
      nextToken
      startedAt
      __typename
    }
    supports {
      nextToken
      startedAt
      __typename
    }
    emotionIntensityLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    toggleRequestForChat
    toggleRequestForChatNotifications
    toggleIndividualStudentStats
    teachers {
      nextToken
      startedAt
      __typename
    }
    schedules {
      nextToken
      startedAt
      __typename
    }
    bulkSignUpId
    createdAt
    updatedAt
    primaryContact
    archived
    emailReceivers
    lastCheckInSessionClassSequenceNo
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    activeStudents
    goMode
    yearLevel
    lastCheckInTime
    wellbeing
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestionID
    wellbeingResponseCount
    toggleEating
    _version
    _deleted
    _lastChangedAt
    classClassLoginId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateClassSubscriptionsSubscriptionVariables,
  APITypes.OnMutateClassSubscriptionsSubscription
>;
export const onMutateClassByClassGroupID = /* GraphQL */ `subscription OnMutateClassByClassGroupID($classGroupID: ID!) {
  onMutateClassByClassGroupID(classGroupID: $classGroupID) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroupID
    students {
      nextToken
      startedAt
      __typename
    }
    classLogin {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    checkInSessions {
      nextToken
      startedAt
      __typename
    }
    supports {
      nextToken
      startedAt
      __typename
    }
    emotionIntensityLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    toggleRequestForChat
    toggleRequestForChatNotifications
    toggleIndividualStudentStats
    teachers {
      nextToken
      startedAt
      __typename
    }
    schedules {
      nextToken
      startedAt
      __typename
    }
    bulkSignUpId
    createdAt
    updatedAt
    primaryContact
    archived
    emailReceivers
    lastCheckInSessionClassSequenceNo
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    activeStudents
    goMode
    yearLevel
    lastCheckInTime
    wellbeing
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestionID
    wellbeingResponseCount
    toggleEating
    _version
    _deleted
    _lastChangedAt
    classClassLoginId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateClassByClassGroupIDSubscriptionVariables,
  APITypes.OnMutateClassByClassGroupIDSubscription
>;
export const onMutateClassGroupsBySchoolID = /* GraphQL */ `subscription OnMutateClassGroupsBySchoolID($schoolID: ID!) {
  onMutateClassGroupsBySchoolID(schoolID: $schoolID) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    campusID
    campus {
      id
      name
      schoolID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateClassGroupsBySchoolIDSubscriptionVariables,
  APITypes.OnMutateClassGroupsBySchoolIDSubscription
>;
export const onMutateClassLoginByClassID = /* GraphQL */ `subscription OnMutateClassLoginByClassID($classID: ID!) {
  onMutateClassLoginByClassID(classID: $classID) {
    id
    nickname
    cognitoUsername
    classID
    classCode
    createdAt
    updatedAt
    devicePushTokens
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateClassLoginByClassIDSubscriptionVariables,
  APITypes.OnMutateClassLoginByClassIDSubscription
>;
export const onMutateClassLoginByCognitoUsername = /* GraphQL */ `subscription OnMutateClassLoginByCognitoUsername($cognitoUsername: ID!) {
  onMutateClassLoginByCognitoUsername(cognitoUsername: $cognitoUsername) {
    id
    nickname
    cognitoUsername
    classID
    classCode
    createdAt
    updatedAt
    devicePushTokens
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateClassLoginByCognitoUsernameSubscriptionVariables,
  APITypes.OnMutateClassLoginByCognitoUsernameSubscription
>;
export const onMutateFavouriteByCognitoUsername = /* GraphQL */ `subscription OnMutateFavouriteByCognitoUsername($cognitoUsername: ID!) {
  onMutateFavouriteByCognitoUsername(cognitoUsername: $cognitoUsername) {
    id
    cognitoUsername
    cardId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateFavouriteByCognitoUsernameSubscriptionVariables,
  APITypes.OnMutateFavouriteByCognitoUsernameSubscription
>;
export const onMutateFindYourCalmActivityRating = /* GraphQL */ `subscription OnMutateFindYourCalmActivityRating($userId: ID!) {
  onMutateFindYourCalmActivityRating(userId: $userId) {
    userId
    activityId
    rating
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateFindYourCalmActivityRatingSubscriptionVariables,
  APITypes.OnMutateFindYourCalmActivityRatingSubscription
>;
export const onMutateInvoiceBySchoolID = /* GraphQL */ `subscription OnMutateInvoiceBySchoolID($schoolID: ID!) {
  onMutateInvoiceBySchoolID(schoolID: $schoolID) {
    id
    schoolID
    url
    name
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateInvoiceBySchoolIDSubscriptionVariables,
  APITypes.OnMutateInvoiceBySchoolIDSubscription
>;
export const onMutateScheduleClassByClassID = /* GraphQL */ `subscription OnMutateScheduleClassByClassID($classID: ID!) {
  onMutateScheduleClassByClassID(classID: $classID) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    schedule
    isActive
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateScheduleClassByClassIDSubscriptionVariables,
  APITypes.OnMutateScheduleClassByClassIDSubscription
>;
export const onMutateSchoolSubscriptionsBySchoolId = /* GraphQL */ `subscription OnMutateSchoolSubscriptionsBySchoolId($id: ID!) {
  onMutateSchoolSubscriptionsBySchoolId(id: $id) {
    id
    name
    classes {
      nextToken
      startedAt
      __typename
    }
    campuses {
      nextToken
      startedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    teachers {
      nextToken
      startedAt
      __typename
    }
    students {
      nextToken
      startedAt
      __typename
    }
    staffs {
      nextToken
      startedAt
      __typename
    }
    schoolAdmins {
      nextToken
      startedAt
      __typename
    }
    schoolAdminUI
    utcOffset
    licenseTotal
    timezone
    status
    activeStudents
    trialEndDate
    renewalDate
    notes
    checkInCountAllTime
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    wholeSchoolSubscription
    billingAddress
    billingNotes
    invoiceUrl
    abn
    coordinatorID
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    staffCheckIns {
      nextToken
      startedAt
      __typename
    }
    teacherWellbeing
    staffWellbeing
    wellbeingFeature
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateSchoolSubscriptionsBySchoolIdSubscriptionVariables,
  APITypes.OnMutateSchoolSubscriptionsBySchoolIdSubscription
>;
export const onMutateSchoolAdminSubscriptionsBySchoolId = /* GraphQL */ `subscription OnMutateSchoolAdminSubscriptionsBySchoolId($schoolID: ID!) {
  onMutateSchoolAdminSubscriptionsBySchoolId(schoolID: $schoolID) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    preferredUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateSchoolAdminSubscriptionsBySchoolIdSubscriptionVariables,
  APITypes.OnMutateSchoolAdminSubscriptionsBySchoolIdSubscription
>;
export const onMutateStaffCheckInByStaffID = /* GraphQL */ `subscription OnMutateStaffCheckInByStaffID($staffID: ID!) {
  onMutateStaffCheckInByStaffID(staffID: $staffID) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    staffID
    staff {
      id
      cognitoUsername
      email
      schoolID
      firstName
      lastName
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      nonTeachingStaffCheckInCount
      nonTeachingStaffCheckInSkipSuggestionDate
      onboarded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateStaffCheckInByStaffIDSubscriptionVariables,
  APITypes.OnMutateStaffCheckInByStaffIDSubscription
>;
export const onMutateStaffBySchoolID = /* GraphQL */ `subscription OnMutateStaffBySchoolID($schoolID: ID!) {
  onMutateStaffBySchoolID(schoolID: $schoolID) {
    id
    cognitoUsername
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    nonTeachingStaffCheckInCount
    nonTeachingStaffCheckInSkipSuggestionDate
    onboarded
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateStaffBySchoolIDSubscriptionVariables,
  APITypes.OnMutateStaffBySchoolIDSubscription
>;
export const onMutateStudentByCognitoUsername = /* GraphQL */ `subscription OnMutateStudentByCognitoUsername($cognitoUsername: ID!) {
  onMutateStudentByCognitoUsername(cognitoUsername: $cognitoUsername) {
    id
    schoolStudentID
    nickname
    avatar
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    yearLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    email
    status
    cognitoUsername
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    onboarded
    initialLoginSetup
    activeClasses
    studentCheckInCount
    studentCheckInSkipSuggestionDate
    devicePushTokens
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestion
    wellbeingResponseCount
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateStudentByCognitoUsernameSubscriptionVariables,
  APITypes.OnMutateStudentByCognitoUsernameSubscription
>;
export const onMutateStudentByStudentId = /* GraphQL */ `subscription OnMutateStudentByStudentId($id: ID!) {
  onMutateStudentByStudentId(id: $id) {
    id
    schoolStudentID
    nickname
    avatar
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    yearLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    email
    status
    cognitoUsername
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    onboarded
    initialLoginSetup
    activeClasses
    studentCheckInCount
    studentCheckInSkipSuggestionDate
    devicePushTokens
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestion
    wellbeingResponseCount
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateStudentByStudentIdSubscriptionVariables,
  APITypes.OnMutateStudentByStudentIdSubscription
>;
export const onMutateStudentBySchoolId = /* GraphQL */ `subscription OnMutateStudentBySchoolId($schoolID: ID!) {
  onMutateStudentBySchoolId(schoolID: $schoolID) {
    id
    schoolStudentID
    nickname
    avatar
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    yearLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    email
    status
    cognitoUsername
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    onboarded
    initialLoginSetup
    activeClasses
    studentCheckInCount
    studentCheckInSkipSuggestionDate
    devicePushTokens
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestion
    wellbeingResponseCount
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateStudentBySchoolIdSubscriptionVariables,
  APITypes.OnMutateStudentBySchoolIdSubscription
>;
export const onMutateStudentCheckInByClassId = /* GraphQL */ `subscription OnMutateStudentCheckInByClassId($classID: ID!) {
  onMutateStudentCheckInByClassId(classID: $classID) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateStudentCheckInByClassIdSubscriptionVariables,
  APITypes.OnMutateStudentCheckInByClassIdSubscription
>;
export const onMutateStudentCheckInByStudentId = /* GraphQL */ `subscription OnMutateStudentCheckInByStudentId($studentID: ID!) {
  onMutateStudentCheckInByStudentId(studentID: $studentID) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateStudentCheckInByStudentIdSubscriptionVariables,
  APITypes.OnMutateStudentCheckInByStudentIdSubscription
>;
export const onMutateStudentCheckInByCheckInSessionId = /* GraphQL */ `subscription OnMutateStudentCheckInByCheckInSessionId($checkinsessionID: ID!) {
  onMutateStudentCheckInByCheckInSessionId(
    checkinsessionID: $checkinsessionID
  ) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateStudentCheckInByCheckInSessionIdSubscriptionVariables,
  APITypes.OnMutateStudentCheckInByCheckInSessionIdSubscription
>;
export const onMutateStudentCheckInByClassIdAndStudentId = /* GraphQL */ `subscription OnMutateStudentCheckInByClassIdAndStudentId(
  $classID: ID!
  $studentID: ID!
) {
  onMutateStudentCheckInByClassIdAndStudentId(
    classID: $classID
    studentID: $studentID
  ) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateStudentCheckInByClassIdAndStudentIdSubscriptionVariables,
  APITypes.OnMutateStudentCheckInByClassIdAndStudentIdSubscription
>;
export const onMutateStudentClass = /* GraphQL */ `subscription OnMutateStudentClass {
  onMutateStudentClass {
    id
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    status
    createdAt
    updatedAt
    requestForChat
    requestForChatStatus
    studentCheckIns
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateStudentClassSubscriptionVariables,
  APITypes.OnMutateStudentClassSubscription
>;
export const onMutateStudentClassByClassId = /* GraphQL */ `subscription OnMutateStudentClassByClassId($classID: ID!) {
  onMutateStudentClassByClassId(classID: $classID) {
    id
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    status
    createdAt
    updatedAt
    requestForChat
    requestForChatStatus
    studentCheckIns
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateStudentClassByClassIdSubscriptionVariables,
  APITypes.OnMutateStudentClassByClassIdSubscription
>;
export const onMutateStudentClassByStudentId = /* GraphQL */ `subscription OnMutateStudentClassByStudentId($studentID: ID!) {
  onMutateStudentClassByStudentId(studentID: $studentID) {
    id
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    status
    createdAt
    updatedAt
    requestForChat
    requestForChatStatus
    studentCheckIns
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateStudentClassByStudentIdSubscriptionVariables,
  APITypes.OnMutateStudentClassByStudentIdSubscription
>;
export const onMutateSwitchRatingByUserId = /* GraphQL */ `subscription OnMutateSwitchRatingByUserId($userId: ID!) {
  onMutateSwitchRatingByUserId(userId: $userId) {
    id
    userId
    cardId
    userType
    rating
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateSwitchRatingByUserIdSubscriptionVariables,
  APITypes.OnMutateSwitchRatingByUserIdSubscription
>;
export const onMutateTeacherByCognitoUsername = /* GraphQL */ `subscription OnMutateTeacherByCognitoUsername($cognitoUsername: ID!) {
  onMutateTeacherByCognitoUsername(cognitoUsername: $cognitoUsername) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    classes {
      nextToken
      startedAt
      __typename
    }
    cognitoUsername
    showIntroduction
    createdAt
    updatedAt
    role
    onboarded
    devicePushTokens
    showNewFeatureInstructions
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateTeacherByCognitoUsernameSubscriptionVariables,
  APITypes.OnMutateTeacherByCognitoUsernameSubscription
>;
export const onMutateTeacherBySchoolID = /* GraphQL */ `subscription OnMutateTeacherBySchoolID($schoolID: ID!) {
  onMutateTeacherBySchoolID(schoolID: $schoolID) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    classes {
      nextToken
      startedAt
      __typename
    }
    cognitoUsername
    showIntroduction
    createdAt
    updatedAt
    role
    onboarded
    devicePushTokens
    showNewFeatureInstructions
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateTeacherBySchoolIDSubscriptionVariables,
  APITypes.OnMutateTeacherBySchoolIDSubscription
>;
export const onMutateTeacherCheckInByTeacherID = /* GraphQL */ `subscription OnMutateTeacherCheckInByTeacherID($teacherID: ID!) {
  onMutateTeacherCheckInByTeacherID(teacherID: $teacherID) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateTeacherCheckInByTeacherIDSubscriptionVariables,
  APITypes.OnMutateTeacherCheckInByTeacherIDSubscription
>;
export const onMutateTeacherClassByTeacherID = /* GraphQL */ `subscription OnMutateTeacherClassByTeacherID($teacherID: ID!) {
  onMutateTeacherClassByTeacherID(teacherID: $teacherID) {
    id
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateTeacherClassByTeacherIDSubscriptionVariables,
  APITypes.OnMutateTeacherClassByTeacherIDSubscription
>;
export const onMutateTeacherClass = /* GraphQL */ `subscription OnMutateTeacherClass {
  onMutateTeacherClass {
    id
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateTeacherClassSubscriptionVariables,
  APITypes.OnMutateTeacherClassSubscription
>;
export const onMutateTeacherClassByClassID = /* GraphQL */ `subscription OnMutateTeacherClassByClassID($classID: ID!) {
  onMutateTeacherClassByClassID(classID: $classID) {
    id
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnMutateTeacherClassByClassIDSubscriptionVariables,
  APITypes.OnMutateTeacherClassByClassIDSubscription
>;
export const onPing = /* GraphQL */ `subscription OnPing($id: ID!) {
  onPing(id: $id) {
    id
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnPingSubscriptionVariables,
  APITypes.OnPingSubscription
>;
export const onCreateStudentCheckIn = /* GraphQL */ `subscription OnCreateStudentCheckIn(
  $filter: ModelSubscriptionStudentCheckInFilterInput
) {
  onCreateStudentCheckIn(filter: $filter) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateStudentCheckInSubscriptionVariables,
  APITypes.OnCreateStudentCheckInSubscription
>;
export const onUpdateStudentCheckIn = /* GraphQL */ `subscription OnUpdateStudentCheckIn(
  $filter: ModelSubscriptionStudentCheckInFilterInput
) {
  onUpdateStudentCheckIn(filter: $filter) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateStudentCheckInSubscriptionVariables,
  APITypes.OnUpdateStudentCheckInSubscription
>;
export const onDeleteStudentCheckIn = /* GraphQL */ `subscription OnDeleteStudentCheckIn(
  $filter: ModelSubscriptionStudentCheckInFilterInput
) {
  onDeleteStudentCheckIn(filter: $filter) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteStudentCheckInSubscriptionVariables,
  APITypes.OnDeleteStudentCheckInSubscription
>;
export const onCreateTeacherCheckIn = /* GraphQL */ `subscription OnCreateTeacherCheckIn(
  $filter: ModelSubscriptionTeacherCheckInFilterInput
) {
  onCreateTeacherCheckIn(filter: $filter) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTeacherCheckInSubscriptionVariables,
  APITypes.OnCreateTeacherCheckInSubscription
>;
export const onUpdateTeacherCheckIn = /* GraphQL */ `subscription OnUpdateTeacherCheckIn(
  $filter: ModelSubscriptionTeacherCheckInFilterInput
) {
  onUpdateTeacherCheckIn(filter: $filter) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTeacherCheckInSubscriptionVariables,
  APITypes.OnUpdateTeacherCheckInSubscription
>;
export const onDeleteTeacherCheckIn = /* GraphQL */ `subscription OnDeleteTeacherCheckIn(
  $filter: ModelSubscriptionTeacherCheckInFilterInput
) {
  onDeleteTeacherCheckIn(filter: $filter) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTeacherCheckInSubscriptionVariables,
  APITypes.OnDeleteTeacherCheckInSubscription
>;
export const onCreateStaffCheckIn = /* GraphQL */ `subscription OnCreateStaffCheckIn(
  $filter: ModelSubscriptionStaffCheckInFilterInput
) {
  onCreateStaffCheckIn(filter: $filter) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    staffID
    staff {
      id
      cognitoUsername
      email
      schoolID
      firstName
      lastName
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      nonTeachingStaffCheckInCount
      nonTeachingStaffCheckInSkipSuggestionDate
      onboarded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateStaffCheckInSubscriptionVariables,
  APITypes.OnCreateStaffCheckInSubscription
>;
export const onUpdateStaffCheckIn = /* GraphQL */ `subscription OnUpdateStaffCheckIn(
  $filter: ModelSubscriptionStaffCheckInFilterInput
) {
  onUpdateStaffCheckIn(filter: $filter) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    staffID
    staff {
      id
      cognitoUsername
      email
      schoolID
      firstName
      lastName
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      nonTeachingStaffCheckInCount
      nonTeachingStaffCheckInSkipSuggestionDate
      onboarded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateStaffCheckInSubscriptionVariables,
  APITypes.OnUpdateStaffCheckInSubscription
>;
export const onDeleteStaffCheckIn = /* GraphQL */ `subscription OnDeleteStaffCheckIn(
  $filter: ModelSubscriptionStaffCheckInFilterInput
) {
  onDeleteStaffCheckIn(filter: $filter) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    staffID
    staff {
      id
      cognitoUsername
      email
      schoolID
      firstName
      lastName
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      nonTeachingStaffCheckInCount
      nonTeachingStaffCheckInSkipSuggestionDate
      onboarded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteStaffCheckInSubscriptionVariables,
  APITypes.OnDeleteStaffCheckInSubscription
>;
export const onCreateCheckInSession = /* GraphQL */ `subscription OnCreateCheckInSession(
  $filter: ModelSubscriptionCheckInSessionFilterInput
) {
  onCreateCheckInSession(filter: $filter) {
    id
    checkInSessionClassSequenceNo
    createdAt
    updatedAt
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    openAt
    closeAt
    isActive
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCheckInSessionSubscriptionVariables,
  APITypes.OnCreateCheckInSessionSubscription
>;
export const onUpdateCheckInSession = /* GraphQL */ `subscription OnUpdateCheckInSession(
  $filter: ModelSubscriptionCheckInSessionFilterInput
) {
  onUpdateCheckInSession(filter: $filter) {
    id
    checkInSessionClassSequenceNo
    createdAt
    updatedAt
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    openAt
    closeAt
    isActive
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCheckInSessionSubscriptionVariables,
  APITypes.OnUpdateCheckInSessionSubscription
>;
export const onDeleteCheckInSession = /* GraphQL */ `subscription OnDeleteCheckInSession(
  $filter: ModelSubscriptionCheckInSessionFilterInput
) {
  onDeleteCheckInSession(filter: $filter) {
    id
    checkInSessionClassSequenceNo
    createdAt
    updatedAt
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    openAt
    closeAt
    isActive
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCheckInSessionSubscriptionVariables,
  APITypes.OnDeleteCheckInSessionSubscription
>;
export const onCreateSchool = /* GraphQL */ `subscription OnCreateSchool($filter: ModelSubscriptionSchoolFilterInput) {
  onCreateSchool(filter: $filter) {
    id
    name
    classes {
      nextToken
      startedAt
      __typename
    }
    campuses {
      nextToken
      startedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    teachers {
      nextToken
      startedAt
      __typename
    }
    students {
      nextToken
      startedAt
      __typename
    }
    staffs {
      nextToken
      startedAt
      __typename
    }
    schoolAdmins {
      nextToken
      startedAt
      __typename
    }
    schoolAdminUI
    utcOffset
    licenseTotal
    timezone
    status
    activeStudents
    trialEndDate
    renewalDate
    notes
    checkInCountAllTime
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    wholeSchoolSubscription
    billingAddress
    billingNotes
    invoiceUrl
    abn
    coordinatorID
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    staffCheckIns {
      nextToken
      startedAt
      __typename
    }
    teacherWellbeing
    staffWellbeing
    wellbeingFeature
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSchoolSubscriptionVariables,
  APITypes.OnCreateSchoolSubscription
>;
export const onUpdateSchool = /* GraphQL */ `subscription OnUpdateSchool($filter: ModelSubscriptionSchoolFilterInput) {
  onUpdateSchool(filter: $filter) {
    id
    name
    classes {
      nextToken
      startedAt
      __typename
    }
    campuses {
      nextToken
      startedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    teachers {
      nextToken
      startedAt
      __typename
    }
    students {
      nextToken
      startedAt
      __typename
    }
    staffs {
      nextToken
      startedAt
      __typename
    }
    schoolAdmins {
      nextToken
      startedAt
      __typename
    }
    schoolAdminUI
    utcOffset
    licenseTotal
    timezone
    status
    activeStudents
    trialEndDate
    renewalDate
    notes
    checkInCountAllTime
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    wholeSchoolSubscription
    billingAddress
    billingNotes
    invoiceUrl
    abn
    coordinatorID
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    staffCheckIns {
      nextToken
      startedAt
      __typename
    }
    teacherWellbeing
    staffWellbeing
    wellbeingFeature
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSchoolSubscriptionVariables,
  APITypes.OnUpdateSchoolSubscription
>;
export const onDeleteSchool = /* GraphQL */ `subscription OnDeleteSchool($filter: ModelSubscriptionSchoolFilterInput) {
  onDeleteSchool(filter: $filter) {
    id
    name
    classes {
      nextToken
      startedAt
      __typename
    }
    campuses {
      nextToken
      startedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    teachers {
      nextToken
      startedAt
      __typename
    }
    students {
      nextToken
      startedAt
      __typename
    }
    staffs {
      nextToken
      startedAt
      __typename
    }
    schoolAdmins {
      nextToken
      startedAt
      __typename
    }
    schoolAdminUI
    utcOffset
    licenseTotal
    timezone
    status
    activeStudents
    trialEndDate
    renewalDate
    notes
    checkInCountAllTime
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    wholeSchoolSubscription
    billingAddress
    billingNotes
    invoiceUrl
    abn
    coordinatorID
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    staffCheckIns {
      nextToken
      startedAt
      __typename
    }
    teacherWellbeing
    staffWellbeing
    wellbeingFeature
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSchoolSubscriptionVariables,
  APITypes.OnDeleteSchoolSubscription
>;
export const onCreateCampus = /* GraphQL */ `subscription OnCreateCampus($filter: ModelSubscriptionCampusFilterInput) {
  onCreateCampus(filter: $filter) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCampusSubscriptionVariables,
  APITypes.OnCreateCampusSubscription
>;
export const onUpdateCampus = /* GraphQL */ `subscription OnUpdateCampus($filter: ModelSubscriptionCampusFilterInput) {
  onUpdateCampus(filter: $filter) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCampusSubscriptionVariables,
  APITypes.OnUpdateCampusSubscription
>;
export const onDeleteCampus = /* GraphQL */ `subscription OnDeleteCampus($filter: ModelSubscriptionCampusFilterInput) {
  onDeleteCampus(filter: $filter) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCampusSubscriptionVariables,
  APITypes.OnDeleteCampusSubscription
>;
export const onCreateClassGroup = /* GraphQL */ `subscription OnCreateClassGroup(
  $filter: ModelSubscriptionClassGroupFilterInput
) {
  onCreateClassGroup(filter: $filter) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    campusID
    campus {
      id
      name
      schoolID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateClassGroupSubscriptionVariables,
  APITypes.OnCreateClassGroupSubscription
>;
export const onUpdateClassGroup = /* GraphQL */ `subscription OnUpdateClassGroup(
  $filter: ModelSubscriptionClassGroupFilterInput
) {
  onUpdateClassGroup(filter: $filter) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    campusID
    campus {
      id
      name
      schoolID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateClassGroupSubscriptionVariables,
  APITypes.OnUpdateClassGroupSubscription
>;
export const onDeleteClassGroup = /* GraphQL */ `subscription OnDeleteClassGroup(
  $filter: ModelSubscriptionClassGroupFilterInput
) {
  onDeleteClassGroup(filter: $filter) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    campusID
    campus {
      id
      name
      schoolID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteClassGroupSubscriptionVariables,
  APITypes.OnDeleteClassGroupSubscription
>;
export const onCreateClass = /* GraphQL */ `subscription OnCreateClass($filter: ModelSubscriptionClassFilterInput) {
  onCreateClass(filter: $filter) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroupID
    students {
      nextToken
      startedAt
      __typename
    }
    classLogin {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    checkInSessions {
      nextToken
      startedAt
      __typename
    }
    supports {
      nextToken
      startedAt
      __typename
    }
    emotionIntensityLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    toggleRequestForChat
    toggleRequestForChatNotifications
    toggleIndividualStudentStats
    teachers {
      nextToken
      startedAt
      __typename
    }
    schedules {
      nextToken
      startedAt
      __typename
    }
    bulkSignUpId
    createdAt
    updatedAt
    primaryContact
    archived
    emailReceivers
    lastCheckInSessionClassSequenceNo
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    activeStudents
    goMode
    yearLevel
    lastCheckInTime
    wellbeing
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestionID
    wellbeingResponseCount
    toggleEating
    _version
    _deleted
    _lastChangedAt
    classClassLoginId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateClassSubscriptionVariables,
  APITypes.OnCreateClassSubscription
>;
export const onUpdateClass = /* GraphQL */ `subscription OnUpdateClass($filter: ModelSubscriptionClassFilterInput) {
  onUpdateClass(filter: $filter) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroupID
    students {
      nextToken
      startedAt
      __typename
    }
    classLogin {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    checkInSessions {
      nextToken
      startedAt
      __typename
    }
    supports {
      nextToken
      startedAt
      __typename
    }
    emotionIntensityLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    toggleRequestForChat
    toggleRequestForChatNotifications
    toggleIndividualStudentStats
    teachers {
      nextToken
      startedAt
      __typename
    }
    schedules {
      nextToken
      startedAt
      __typename
    }
    bulkSignUpId
    createdAt
    updatedAt
    primaryContact
    archived
    emailReceivers
    lastCheckInSessionClassSequenceNo
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    activeStudents
    goMode
    yearLevel
    lastCheckInTime
    wellbeing
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestionID
    wellbeingResponseCount
    toggleEating
    _version
    _deleted
    _lastChangedAt
    classClassLoginId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateClassSubscriptionVariables,
  APITypes.OnUpdateClassSubscription
>;
export const onDeleteClass = /* GraphQL */ `subscription OnDeleteClass($filter: ModelSubscriptionClassFilterInput) {
  onDeleteClass(filter: $filter) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroupID
    students {
      nextToken
      startedAt
      __typename
    }
    classLogin {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    checkInSessions {
      nextToken
      startedAt
      __typename
    }
    supports {
      nextToken
      startedAt
      __typename
    }
    emotionIntensityLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    toggleRequestForChat
    toggleRequestForChatNotifications
    toggleIndividualStudentStats
    teachers {
      nextToken
      startedAt
      __typename
    }
    schedules {
      nextToken
      startedAt
      __typename
    }
    bulkSignUpId
    createdAt
    updatedAt
    primaryContact
    archived
    emailReceivers
    lastCheckInSessionClassSequenceNo
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    activeStudents
    goMode
    yearLevel
    lastCheckInTime
    wellbeing
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestionID
    wellbeingResponseCount
    toggleEating
    _version
    _deleted
    _lastChangedAt
    classClassLoginId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteClassSubscriptionVariables,
  APITypes.OnDeleteClassSubscription
>;
export const onCreateTeacher = /* GraphQL */ `subscription OnCreateTeacher($filter: ModelSubscriptionTeacherFilterInput) {
  onCreateTeacher(filter: $filter) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    classes {
      nextToken
      startedAt
      __typename
    }
    cognitoUsername
    showIntroduction
    createdAt
    updatedAt
    role
    onboarded
    devicePushTokens
    showNewFeatureInstructions
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTeacherSubscriptionVariables,
  APITypes.OnCreateTeacherSubscription
>;
export const onUpdateTeacher = /* GraphQL */ `subscription OnUpdateTeacher($filter: ModelSubscriptionTeacherFilterInput) {
  onUpdateTeacher(filter: $filter) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    classes {
      nextToken
      startedAt
      __typename
    }
    cognitoUsername
    showIntroduction
    createdAt
    updatedAt
    role
    onboarded
    devicePushTokens
    showNewFeatureInstructions
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTeacherSubscriptionVariables,
  APITypes.OnUpdateTeacherSubscription
>;
export const onDeleteTeacher = /* GraphQL */ `subscription OnDeleteTeacher($filter: ModelSubscriptionTeacherFilterInput) {
  onDeleteTeacher(filter: $filter) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    classes {
      nextToken
      startedAt
      __typename
    }
    cognitoUsername
    showIntroduction
    createdAt
    updatedAt
    role
    onboarded
    devicePushTokens
    showNewFeatureInstructions
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTeacherSubscriptionVariables,
  APITypes.OnDeleteTeacherSubscription
>;
export const onCreateStaff = /* GraphQL */ `subscription OnCreateStaff($filter: ModelSubscriptionStaffFilterInput) {
  onCreateStaff(filter: $filter) {
    id
    cognitoUsername
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    nonTeachingStaffCheckInCount
    nonTeachingStaffCheckInSkipSuggestionDate
    onboarded
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateStaffSubscriptionVariables,
  APITypes.OnCreateStaffSubscription
>;
export const onUpdateStaff = /* GraphQL */ `subscription OnUpdateStaff($filter: ModelSubscriptionStaffFilterInput) {
  onUpdateStaff(filter: $filter) {
    id
    cognitoUsername
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    nonTeachingStaffCheckInCount
    nonTeachingStaffCheckInSkipSuggestionDate
    onboarded
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateStaffSubscriptionVariables,
  APITypes.OnUpdateStaffSubscription
>;
export const onDeleteStaff = /* GraphQL */ `subscription OnDeleteStaff($filter: ModelSubscriptionStaffFilterInput) {
  onDeleteStaff(filter: $filter) {
    id
    cognitoUsername
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    nonTeachingStaffCheckInCount
    nonTeachingStaffCheckInSkipSuggestionDate
    onboarded
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteStaffSubscriptionVariables,
  APITypes.OnDeleteStaffSubscription
>;
export const onCreateSchoolAdmin = /* GraphQL */ `subscription OnCreateSchoolAdmin(
  $filter: ModelSubscriptionSchoolAdminFilterInput
) {
  onCreateSchoolAdmin(filter: $filter) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    preferredUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSchoolAdminSubscriptionVariables,
  APITypes.OnCreateSchoolAdminSubscription
>;
export const onUpdateSchoolAdmin = /* GraphQL */ `subscription OnUpdateSchoolAdmin(
  $filter: ModelSubscriptionSchoolAdminFilterInput
) {
  onUpdateSchoolAdmin(filter: $filter) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    preferredUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSchoolAdminSubscriptionVariables,
  APITypes.OnUpdateSchoolAdminSubscription
>;
export const onDeleteSchoolAdmin = /* GraphQL */ `subscription OnDeleteSchoolAdmin(
  $filter: ModelSubscriptionSchoolAdminFilterInput
) {
  onDeleteSchoolAdmin(filter: $filter) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    preferredUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSchoolAdminSubscriptionVariables,
  APITypes.OnDeleteSchoolAdminSubscription
>;
export const onCreateStudent = /* GraphQL */ `subscription OnCreateStudent($filter: ModelSubscriptionStudentFilterInput) {
  onCreateStudent(filter: $filter) {
    id
    schoolStudentID
    nickname
    avatar
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    yearLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    email
    status
    cognitoUsername
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    onboarded
    initialLoginSetup
    activeClasses
    studentCheckInCount
    studentCheckInSkipSuggestionDate
    devicePushTokens
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestion
    wellbeingResponseCount
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateStudentSubscriptionVariables,
  APITypes.OnCreateStudentSubscription
>;
export const onUpdateStudent = /* GraphQL */ `subscription OnUpdateStudent($filter: ModelSubscriptionStudentFilterInput) {
  onUpdateStudent(filter: $filter) {
    id
    schoolStudentID
    nickname
    avatar
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    yearLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    email
    status
    cognitoUsername
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    onboarded
    initialLoginSetup
    activeClasses
    studentCheckInCount
    studentCheckInSkipSuggestionDate
    devicePushTokens
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestion
    wellbeingResponseCount
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateStudentSubscriptionVariables,
  APITypes.OnUpdateStudentSubscription
>;
export const onDeleteStudent = /* GraphQL */ `subscription OnDeleteStudent($filter: ModelSubscriptionStudentFilterInput) {
  onDeleteStudent(filter: $filter) {
    id
    schoolStudentID
    nickname
    avatar
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    yearLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    email
    status
    cognitoUsername
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    onboarded
    initialLoginSetup
    activeClasses
    studentCheckInCount
    studentCheckInSkipSuggestionDate
    devicePushTokens
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestion
    wellbeingResponseCount
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteStudentSubscriptionVariables,
  APITypes.OnDeleteStudentSubscription
>;
export const onCreateSupport = /* GraphQL */ `subscription OnCreateSupport($filter: ModelSubscriptionSupportFilterInput) {
  onCreateSupport(filter: $filter) {
    id
    email
    classes {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSupportSubscriptionVariables,
  APITypes.OnCreateSupportSubscription
>;
export const onUpdateSupport = /* GraphQL */ `subscription OnUpdateSupport($filter: ModelSubscriptionSupportFilterInput) {
  onUpdateSupport(filter: $filter) {
    id
    email
    classes {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSupportSubscriptionVariables,
  APITypes.OnUpdateSupportSubscription
>;
export const onDeleteSupport = /* GraphQL */ `subscription OnDeleteSupport($filter: ModelSubscriptionSupportFilterInput) {
  onDeleteSupport(filter: $filter) {
    id
    email
    classes {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSupportSubscriptionVariables,
  APITypes.OnDeleteSupportSubscription
>;
export const onCreateClassSupport = /* GraphQL */ `subscription OnCreateClassSupport(
  $filter: ModelSubscriptionClassSupportFilterInput
) {
  onCreateClassSupport(filter: $filter) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    supportID
    support {
      id
      email
      firstName
      lastName
      cognitoUsername
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateClassSupportSubscriptionVariables,
  APITypes.OnCreateClassSupportSubscription
>;
export const onUpdateClassSupport = /* GraphQL */ `subscription OnUpdateClassSupport(
  $filter: ModelSubscriptionClassSupportFilterInput
) {
  onUpdateClassSupport(filter: $filter) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    supportID
    support {
      id
      email
      firstName
      lastName
      cognitoUsername
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateClassSupportSubscriptionVariables,
  APITypes.OnUpdateClassSupportSubscription
>;
export const onDeleteClassSupport = /* GraphQL */ `subscription OnDeleteClassSupport(
  $filter: ModelSubscriptionClassSupportFilterInput
) {
  onDeleteClassSupport(filter: $filter) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    supportID
    support {
      id
      email
      firstName
      lastName
      cognitoUsername
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteClassSupportSubscriptionVariables,
  APITypes.OnDeleteClassSupportSubscription
>;
export const onCreateTeacherClass = /* GraphQL */ `subscription OnCreateTeacherClass(
  $filter: ModelSubscriptionTeacherClassFilterInput
) {
  onCreateTeacherClass(filter: $filter) {
    id
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTeacherClassSubscriptionVariables,
  APITypes.OnCreateTeacherClassSubscription
>;
export const onUpdateTeacherClass = /* GraphQL */ `subscription OnUpdateTeacherClass(
  $filter: ModelSubscriptionTeacherClassFilterInput
) {
  onUpdateTeacherClass(filter: $filter) {
    id
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTeacherClassSubscriptionVariables,
  APITypes.OnUpdateTeacherClassSubscription
>;
export const onDeleteTeacherClass = /* GraphQL */ `subscription OnDeleteTeacherClass(
  $filter: ModelSubscriptionTeacherClassFilterInput
) {
  onDeleteTeacherClass(filter: $filter) {
    id
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTeacherClassSubscriptionVariables,
  APITypes.OnDeleteTeacherClassSubscription
>;
export const onCreateScheduleClass = /* GraphQL */ `subscription OnCreateScheduleClass(
  $filter: ModelSubscriptionScheduleClassFilterInput
) {
  onCreateScheduleClass(filter: $filter) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    schedule
    isActive
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateScheduleClassSubscriptionVariables,
  APITypes.OnCreateScheduleClassSubscription
>;
export const onUpdateScheduleClass = /* GraphQL */ `subscription OnUpdateScheduleClass(
  $filter: ModelSubscriptionScheduleClassFilterInput
) {
  onUpdateScheduleClass(filter: $filter) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    schedule
    isActive
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateScheduleClassSubscriptionVariables,
  APITypes.OnUpdateScheduleClassSubscription
>;
export const onDeleteScheduleClass = /* GraphQL */ `subscription OnDeleteScheduleClass(
  $filter: ModelSubscriptionScheduleClassFilterInput
) {
  onDeleteScheduleClass(filter: $filter) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    schedule
    isActive
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteScheduleClassSubscriptionVariables,
  APITypes.OnDeleteScheduleClassSubscription
>;
export const onCreateStudentClass = /* GraphQL */ `subscription OnCreateStudentClass(
  $filter: ModelSubscriptionStudentClassFilterInput
) {
  onCreateStudentClass(filter: $filter) {
    id
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    status
    createdAt
    updatedAt
    requestForChat
    requestForChatStatus
    studentCheckIns
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateStudentClassSubscriptionVariables,
  APITypes.OnCreateStudentClassSubscription
>;
export const onUpdateStudentClass = /* GraphQL */ `subscription OnUpdateStudentClass(
  $filter: ModelSubscriptionStudentClassFilterInput
) {
  onUpdateStudentClass(filter: $filter) {
    id
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    status
    createdAt
    updatedAt
    requestForChat
    requestForChatStatus
    studentCheckIns
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateStudentClassSubscriptionVariables,
  APITypes.OnUpdateStudentClassSubscription
>;
export const onDeleteStudentClass = /* GraphQL */ `subscription OnDeleteStudentClass(
  $filter: ModelSubscriptionStudentClassFilterInput
) {
  onDeleteStudentClass(filter: $filter) {
    id
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    status
    createdAt
    updatedAt
    requestForChat
    requestForChatStatus
    studentCheckIns
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteStudentClassSubscriptionVariables,
  APITypes.OnDeleteStudentClassSubscription
>;
export const onCreateSchoolContact = /* GraphQL */ `subscription OnCreateSchoolContact(
  $filter: ModelSubscriptionSchoolContactFilterInput
) {
  onCreateSchoolContact(filter: $filter) {
    id
    schoolID
    firstName
    lastName
    email
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSchoolContactSubscriptionVariables,
  APITypes.OnCreateSchoolContactSubscription
>;
export const onUpdateSchoolContact = /* GraphQL */ `subscription OnUpdateSchoolContact(
  $filter: ModelSubscriptionSchoolContactFilterInput
) {
  onUpdateSchoolContact(filter: $filter) {
    id
    schoolID
    firstName
    lastName
    email
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSchoolContactSubscriptionVariables,
  APITypes.OnUpdateSchoolContactSubscription
>;
export const onDeleteSchoolContact = /* GraphQL */ `subscription OnDeleteSchoolContact(
  $filter: ModelSubscriptionSchoolContactFilterInput
) {
  onDeleteSchoolContact(filter: $filter) {
    id
    schoolID
    firstName
    lastName
    email
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSchoolContactSubscriptionVariables,
  APITypes.OnDeleteSchoolContactSubscription
>;
export const onCreateMinFEVersion = /* GraphQL */ `subscription OnCreateMinFEVersion(
  $filter: ModelSubscriptionMinFEVersionFilterInput
) {
  onCreateMinFEVersion(filter: $filter) {
    version
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMinFEVersionSubscriptionVariables,
  APITypes.OnCreateMinFEVersionSubscription
>;
export const onUpdateMinFEVersion = /* GraphQL */ `subscription OnUpdateMinFEVersion(
  $filter: ModelSubscriptionMinFEVersionFilterInput
) {
  onUpdateMinFEVersion(filter: $filter) {
    version
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMinFEVersionSubscriptionVariables,
  APITypes.OnUpdateMinFEVersionSubscription
>;
export const onDeleteMinFEVersion = /* GraphQL */ `subscription OnDeleteMinFEVersion(
  $filter: ModelSubscriptionMinFEVersionFilterInput
) {
  onDeleteMinFEVersion(filter: $filter) {
    version
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMinFEVersionSubscriptionVariables,
  APITypes.OnDeleteMinFEVersionSubscription
>;
export const onCreateSwitchRating = /* GraphQL */ `subscription OnCreateSwitchRating(
  $filter: ModelSubscriptionSwitchRatingFilterInput
) {
  onCreateSwitchRating(filter: $filter) {
    id
    userId
    cardId
    userType
    rating
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSwitchRatingSubscriptionVariables,
  APITypes.OnCreateSwitchRatingSubscription
>;
export const onUpdateSwitchRating = /* GraphQL */ `subscription OnUpdateSwitchRating(
  $filter: ModelSubscriptionSwitchRatingFilterInput
) {
  onUpdateSwitchRating(filter: $filter) {
    id
    userId
    cardId
    userType
    rating
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSwitchRatingSubscriptionVariables,
  APITypes.OnUpdateSwitchRatingSubscription
>;
export const onDeleteSwitchRating = /* GraphQL */ `subscription OnDeleteSwitchRating(
  $filter: ModelSubscriptionSwitchRatingFilterInput
) {
  onDeleteSwitchRating(filter: $filter) {
    id
    userId
    cardId
    userType
    rating
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSwitchRatingSubscriptionVariables,
  APITypes.OnDeleteSwitchRatingSubscription
>;
export const onCreateClassLogin = /* GraphQL */ `subscription OnCreateClassLogin(
  $filter: ModelSubscriptionClassLoginFilterInput
) {
  onCreateClassLogin(filter: $filter) {
    id
    nickname
    cognitoUsername
    classID
    classCode
    createdAt
    updatedAt
    devicePushTokens
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateClassLoginSubscriptionVariables,
  APITypes.OnCreateClassLoginSubscription
>;
export const onUpdateClassLogin = /* GraphQL */ `subscription OnUpdateClassLogin(
  $filter: ModelSubscriptionClassLoginFilterInput
) {
  onUpdateClassLogin(filter: $filter) {
    id
    nickname
    cognitoUsername
    classID
    classCode
    createdAt
    updatedAt
    devicePushTokens
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateClassLoginSubscriptionVariables,
  APITypes.OnUpdateClassLoginSubscription
>;
export const onDeleteClassLogin = /* GraphQL */ `subscription OnDeleteClassLogin(
  $filter: ModelSubscriptionClassLoginFilterInput
) {
  onDeleteClassLogin(filter: $filter) {
    id
    nickname
    cognitoUsername
    classID
    classCode
    createdAt
    updatedAt
    devicePushTokens
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteClassLoginSubscriptionVariables,
  APITypes.OnDeleteClassLoginSubscription
>;
export const onCreateFavourite = /* GraphQL */ `subscription OnCreateFavourite($filter: ModelSubscriptionFavouriteFilterInput) {
  onCreateFavourite(filter: $filter) {
    id
    cognitoUsername
    cardId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFavouriteSubscriptionVariables,
  APITypes.OnCreateFavouriteSubscription
>;
export const onUpdateFavourite = /* GraphQL */ `subscription OnUpdateFavourite($filter: ModelSubscriptionFavouriteFilterInput) {
  onUpdateFavourite(filter: $filter) {
    id
    cognitoUsername
    cardId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFavouriteSubscriptionVariables,
  APITypes.OnUpdateFavouriteSubscription
>;
export const onDeleteFavourite = /* GraphQL */ `subscription OnDeleteFavourite($filter: ModelSubscriptionFavouriteFilterInput) {
  onDeleteFavourite(filter: $filter) {
    id
    cognitoUsername
    cardId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFavouriteSubscriptionVariables,
  APITypes.OnDeleteFavouriteSubscription
>;
export const onCreateFindYourCalmActivityRating = /* GraphQL */ `subscription OnCreateFindYourCalmActivityRating(
  $filter: ModelSubscriptionFindYourCalmActivityRatingFilterInput
) {
  onCreateFindYourCalmActivityRating(filter: $filter) {
    userId
    activityId
    rating
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFindYourCalmActivityRatingSubscriptionVariables,
  APITypes.OnCreateFindYourCalmActivityRatingSubscription
>;
export const onUpdateFindYourCalmActivityRating = /* GraphQL */ `subscription OnUpdateFindYourCalmActivityRating(
  $filter: ModelSubscriptionFindYourCalmActivityRatingFilterInput
) {
  onUpdateFindYourCalmActivityRating(filter: $filter) {
    userId
    activityId
    rating
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFindYourCalmActivityRatingSubscriptionVariables,
  APITypes.OnUpdateFindYourCalmActivityRatingSubscription
>;
export const onDeleteFindYourCalmActivityRating = /* GraphQL */ `subscription OnDeleteFindYourCalmActivityRating(
  $filter: ModelSubscriptionFindYourCalmActivityRatingFilterInput
) {
  onDeleteFindYourCalmActivityRating(filter: $filter) {
    userId
    activityId
    rating
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFindYourCalmActivityRatingSubscriptionVariables,
  APITypes.OnDeleteFindYourCalmActivityRatingSubscription
>;
export const onCreateInvoice = /* GraphQL */ `subscription OnCreateInvoice($filter: ModelSubscriptionInvoiceFilterInput) {
  onCreateInvoice(filter: $filter) {
    id
    schoolID
    url
    name
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInvoiceSubscriptionVariables,
  APITypes.OnCreateInvoiceSubscription
>;
export const onUpdateInvoice = /* GraphQL */ `subscription OnUpdateInvoice($filter: ModelSubscriptionInvoiceFilterInput) {
  onUpdateInvoice(filter: $filter) {
    id
    schoolID
    url
    name
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInvoiceSubscriptionVariables,
  APITypes.OnUpdateInvoiceSubscription
>;
export const onDeleteInvoice = /* GraphQL */ `subscription OnDeleteInvoice($filter: ModelSubscriptionInvoiceFilterInput) {
  onDeleteInvoice(filter: $filter) {
    id
    schoolID
    url
    name
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInvoiceSubscriptionVariables,
  APITypes.OnDeleteInvoiceSubscription
>;
export const onCreateVideos = /* GraphQL */ `subscription OnCreateVideos($filter: ModelSubscriptionVideosFilterInput) {
  onCreateVideos(filter: $filter) {
    id
    url
    image
    title
    createdAt
    updatedAt
    time
    level
    emotion
    ageGroup
    isExternal
    autoplay
    youtubePlaylistThumbnailUrlVideoId
    thumbnailUrl
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateVideosSubscriptionVariables,
  APITypes.OnCreateVideosSubscription
>;
export const onUpdateVideos = /* GraphQL */ `subscription OnUpdateVideos($filter: ModelSubscriptionVideosFilterInput) {
  onUpdateVideos(filter: $filter) {
    id
    url
    image
    title
    createdAt
    updatedAt
    time
    level
    emotion
    ageGroup
    isExternal
    autoplay
    youtubePlaylistThumbnailUrlVideoId
    thumbnailUrl
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVideosSubscriptionVariables,
  APITypes.OnUpdateVideosSubscription
>;
export const onDeleteVideos = /* GraphQL */ `subscription OnDeleteVideos($filter: ModelSubscriptionVideosFilterInput) {
  onDeleteVideos(filter: $filter) {
    id
    url
    image
    title
    createdAt
    updatedAt
    time
    level
    emotion
    ageGroup
    isExternal
    autoplay
    youtubePlaylistThumbnailUrlVideoId
    thumbnailUrl
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteVideosSubscriptionVariables,
  APITypes.OnDeleteVideosSubscription
>;
export const onCreateWellbeingQuestion = /* GraphQL */ `subscription OnCreateWellbeingQuestion(
  $filter: ModelSubscriptionWellbeingQuestionFilterInput
) {
  onCreateWellbeingQuestion(filter: $filter) {
    id
    category
    type
    text
    options {
      text
      value
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateWellbeingQuestionSubscriptionVariables,
  APITypes.OnCreateWellbeingQuestionSubscription
>;
export const onUpdateWellbeingQuestion = /* GraphQL */ `subscription OnUpdateWellbeingQuestion(
  $filter: ModelSubscriptionWellbeingQuestionFilterInput
) {
  onUpdateWellbeingQuestion(filter: $filter) {
    id
    category
    type
    text
    options {
      text
      value
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateWellbeingQuestionSubscriptionVariables,
  APITypes.OnUpdateWellbeingQuestionSubscription
>;
export const onDeleteWellbeingQuestion = /* GraphQL */ `subscription OnDeleteWellbeingQuestion(
  $filter: ModelSubscriptionWellbeingQuestionFilterInput
) {
  onDeleteWellbeingQuestion(filter: $filter) {
    id
    category
    type
    text
    options {
      text
      value
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteWellbeingQuestionSubscriptionVariables,
  APITypes.OnDeleteWellbeingQuestionSubscription
>;
export const onCreateWellbeingResponse = /* GraphQL */ `subscription OnCreateWellbeingResponse(
  $filter: ModelSubscriptionWellbeingResponseFilterInput
) {
  onCreateWellbeingResponse(filter: $filter) {
    id
    userID
    userType
    wellbeingQuestionID
    answer
    createdAt
    schoolID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateWellbeingResponseSubscriptionVariables,
  APITypes.OnCreateWellbeingResponseSubscription
>;
export const onUpdateWellbeingResponse = /* GraphQL */ `subscription OnUpdateWellbeingResponse(
  $filter: ModelSubscriptionWellbeingResponseFilterInput
) {
  onUpdateWellbeingResponse(filter: $filter) {
    id
    userID
    userType
    wellbeingQuestionID
    answer
    createdAt
    schoolID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateWellbeingResponseSubscriptionVariables,
  APITypes.OnUpdateWellbeingResponseSubscription
>;
export const onDeleteWellbeingResponse = /* GraphQL */ `subscription OnDeleteWellbeingResponse(
  $filter: ModelSubscriptionWellbeingResponseFilterInput
) {
  onDeleteWellbeingResponse(filter: $filter) {
    id
    userID
    userType
    wellbeingQuestionID
    answer
    createdAt
    schoolID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteWellbeingResponseSubscriptionVariables,
  APITypes.OnDeleteWellbeingResponseSubscription
>;
