import { UserGroup } from "../../common/constants";
import { handleAuth } from "../login/login-functions";
import { DefaultButton } from "./default-button";

type Props = {
    cognitoUsername: string,
    userGroup: UserGroup,
    email: string | null | undefined
}

export const ImpersonateButton = (props: Props) =>
    <DefaultButton onPress={() => impersonate(props)}>Impersonate</DefaultButton>

const impersonate = ({cognitoUsername, userGroup, email}: Props) => {
    console.log({cognitoUsername, userGroup, email})
    handleAuth({
        signInUserSession: {
            idToken: {
                payload: {
                    'cognito:groups': [userGroup]
                }
            }
        },
        username: cognitoUsername,
        attributes: {
            email: email || ''
        }
    }, true)
}
