import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan, Line } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconAngry7Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 7)
    }

    const onPressLevel2 = {
        [prop]: () => onPress(2 / 7)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(3 / 7)
    }

    const onPressLevel4 = {
        [prop]: () => onPress(4 / 7)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(5 / 7)
    }

    const onPressLevel6 = {
        [prop]: () => onPress(6 / 7)
    }

    const onPressLevel7 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg
            viewBox="0 0 277.135 152.937"
        >
            <G data-name="Group 9560">
                <G data-name="Group indignant" {...onPressLevel1}>
                    <Path
                        data-name="Path 7814"
                        d="M0 0l52.6-.176.043-28.007z"
                        transform="translate(-1722 -109.468) translate(1722.711 262.279)"
                        fill="#faddde"
                    />
                    <Path
                        data-name="Path 7815"
                        d="M1722.711 262.279l52.641-28.183-.041 28.007z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-1722 -109.468)"
                    />
                    <Path
                        data-name="Path 7849"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h30.4a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0030.4 0z"
                        transform="translate(-1722 -109.468) translate(1738.863 252.011)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7850"
                        d="M1738.863 252.011a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h30.4a2.834 2.834 0 002.835-2.834v-3.631a2.835 2.835 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#e31e25"
                        strokeWidth={0.25}
                        transform="translate(-1722 -109.468)"
                    />
                    <Text
                        transform="translate(-1722 -109.468) translate(1738.647 258.939)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Indignant"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7855"
                        d="M1722.236 258.728l47.913-25.76"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-1722 -109.468)"
                    />
                    <Path
                        data-name="Path 7857"
                        d="M0 0l-7.385 1.073 2.417 4.495z"
                        transform="translate(-1722 -109.468) translate(1774.961 230.381)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group annoyed" {...onPressLevel2}>
                    <Path
                        data-name="Path 7817"
                        d="M0 0l-.112 29.327 34.82-.022-.126-47.734z"
                        transform="translate(-1722 -109.468) translate(1777.921 232.736)"
                        fill="#f6bbbd"
                    />
                    <Path
                        data-name="Path 7818"
                        d="M1777.921 232.737l34.583-18.429.125 47.734-34.819.022z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-1722 -109.468)"
                    />
                    <Path
                        data-name="Path 7834"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h28.257a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0028.257 0z"
                        transform="translate(-1722 -109.468) translate(1781.091 239.192)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7835"
                        d="M1781.091 239.192a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h28.257a2.834 2.834 0 002.834-2.834v-3.631a2.835 2.835 0 00-2.834-2.835z"
                        fill="none"
                        stroke="#e31e25"
                        strokeWidth={0.25}
                        transform="translate(-1722 -109.468)"
                    />
                    <Text
                        transform="translate(-1722 -109.468) translate(1780.768 246.826)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Annoyed"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7858"
                        d="M1777.229 229.247l30.053-16.126"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-1722 -109.468)"
                    />
                    <Path
                        data-name="Path 7859"
                        d="M0 0l-7.385 1.066 2.413 4.5z"
                        transform="translate(-1722 -109.468) translate(1812.095 210.539)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group irritated" {...onPressLevel3}>
                    <Path
                        data-name="Path 7820"
                        d="M0 0l.177 49.336 34.423-.022.043-68.237z"
                        transform="translate(-1722 -109.468) translate(1815.256 212.83)"
                        fill="#f2999d"
                    />
                    <Path
                        data-name="Path 7821"
                        d="M1815.256 212.83l34.649-18.923-.043 68.236-34.428.023z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-1722 -109.468)"
                    />
                    <Path
                        data-name="Path 7837"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h26.469A2.834 2.834 0 0029.3 6.463V2.835A2.835 2.835 0 0026.469 0z"
                        transform="translate(-1722 -109.468) translate(1819.346 226.386)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7838"
                        d="M1819.346 226.386a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h26.469a2.834 2.834 0 002.831-2.837v-3.628a2.835 2.835 0 00-2.831-2.835z"
                        fill="none"
                        stroke="#e31e25"
                        strokeWidth={0.25}
                        transform="translate(-1722 -109.468)"
                    />
                    <Text
                        transform="translate(-1722 -109.468) translate(1819.805 233.737)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Irritated"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7860"
                        d="M1814.646 209.405l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-1722 -109.468)"
                    />
                    <Path
                        data-name="Path 7861"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-1722 -109.468) translate(1849.749 190.256)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group frustated" {...onPressLevel4}>
                    <Path
                        data-name="Path 7823"
                        d="M0 0l.051 69.611 34.819-.022-.082-87.9z"
                        transform="translate(-1722 -109.468) translate(1852.277 192.555)"
                        fill="#ee787c"
                    />
                    <Path
                        data-name="Path 7824"
                        d="M1852.277 192.555l34.787-18.315.083 87.9-34.819.026z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-1722 -109.468)"
                    />
                    <Path
                        data-name="Path 7852"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h30.922a2.834 2.834 0 002.834-2.834V2.835A2.835 2.835 0 0030.922 0z"
                        transform="translate(-1722 -109.468) translate(1854.251 213.578)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7853"
                        d="M1854.251 213.578a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h30.922a2.834 2.834 0 002.834-2.834v-3.631a2.835 2.835 0 00-2.834-2.835z"
                        fill="none"
                        stroke="#e31e25"
                        strokeWidth={0.25}
                        transform="translate(-1722 -109.468)"
                    />
                    <Text
                        transform="translate(-1722 -109.468) translate(1852.754 221.097)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Frustrated"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7862"
                        d="M1851.496 188.995l30.307-16.533"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-1722 -109.468)"
                    />
                    <Path
                        data-name="Path 7863"
                        d="M0 0l-7.378 1.117L-4.934 5.6z"
                        transform="translate(-1722 -109.468) translate(1886.6 169.847)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group fuming" {...onPressLevel5}>
                    <Path
                        data-name="Path 7826"
                        d="M0 0l-.076 89.5 34.428-.022-.082-107.69z"
                        transform="translate(-1722 -109.468) translate(1890.104 172.662)"
                        fill="#ea565c"
                    />
                    <Path
                        data-name="Path 7827"
                        d="M1890.104 172.661l34.27-18.212.082 107.694-34.428.018z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-1722 -109.468)"
                    />
                    <Path
                        data-name="Path 7843"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h22.983a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0022.983 0z"
                        transform="translate(-1722 -109.468) translate(1895.752 200.771)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7844"
                        d="M1895.752 200.771a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h22.983a2.834 2.834 0 002.835-2.834v-3.631a2.835 2.835 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#e31e25"
                        strokeWidth={0.25}
                        transform="translate(-1722 -109.468)"
                    />
                    <Text
                        transform="translate(-1722 -109.468) translate(1895.104 207.723)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Fuming"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7864"
                        d="M1888.914 169.847l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-1722 -109.468)"
                    />
                    <Path
                        data-name="Path 7865"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-1722 -109.468) translate(1924.017 150.697)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group furious" {...onPressLevel6}>
                    <Path
                        data-name="Path 7829"
                        d="M0 0l.051 109.625 34.819-.036-.082-128.582z"
                        transform="translate(-1722 -109.468) translate(1926.808 152.541)"
                        fill="#e83f46"
                    />
                    <Path
                        data-name="Path 7830"
                        d="M1926.808 152.541l34.788-18.992.082 128.582-34.819.035z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-1722 -109.468)"
                    />
                    <Path
                        data-name="Path 7846"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h22.983a2.834 2.834 0 002.835-2.834V2.835A2.835 2.835 0 0022.983 0z"
                        transform="translate(-1722 -109.468) translate(1932.751 187.965)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7847"
                        d="M1932.751 187.965a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h22.983a2.834 2.834 0 002.835-2.834V190.8a2.835 2.835 0 00-2.835-2.835z"
                        fill="none"
                        stroke="#e31e25"
                        strokeWidth={0.25}
                        transform="translate(-1722 -109.468)"
                    />
                    <Text
                        transform="translate(-1722 -109.468) translate(1932.563 195.324)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Furious"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7866"
                        d="M1926.048 149.027l30.307-16.534"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-1722 -109.468)"
                    />
                    <Path
                        data-name="Path 7867"
                        d="M0 0l-7.378 1.118L-4.934 5.6z"
                        transform="translate(-1722 -109.468) translate(1961.15 129.878)"
                        fill="#6f7175"
                    />
                </G>
                <G data-name="Group seething" {...onPressLevel7}>
                    <Path
                        data-name="Path 7832"
                        d="M0 0l-.076 130.034L34.352 130l-.38-147.858z"
                        transform="translate(-1722 -109.468) translate(1964.657 132.132)"
                        fill="#e31e25"
                    />
                    <Path
                        data-name="Path 7833"
                        d="M1964.657 132.132l33.973-17.858.379 147.858-34.428.035z"
                        fill="none"
                        stroke="#657170"
                        strokeWidth={0.25}
                        transform="translate(-1722 -109.468)"
                    />
                    <Path
                        data-name="Path 7840"
                        d="M0 0a2.835 2.835 0 00-2.835 2.835v3.628A2.834 2.834 0 000 9.3h25.271A2.834 2.834 0 0028.1 6.463V2.835A2.835 2.835 0 0025.271 0z"
                        transform="translate(-1722 -109.468) translate(1969.16 175.158)"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 7841"
                        d="M1969.16 175.158a2.835 2.835 0 00-2.835 2.835v3.628a2.834 2.834 0 002.835 2.837h25.271a2.834 2.834 0 002.829-2.837v-3.628a2.835 2.835 0 00-2.829-2.835z"
                        fill="none"
                        stroke="#e31e25"
                        strokeWidth={0.25}
                        transform="translate(-1722 -109.468)"
                    />
                    <Text
                        transform="translate(-1722 -109.468) translate(1967.689 182.756)"
                        fill="#42454a"
                        fontSize={7}
                        fontFamily="Nunito-Bold, Nunito"
                        fontWeight={700}
                    >
                        <TSpan x={0} y={0}>
                            {"Seething"}
                        </TSpan>
                    </Text>
                    <Path
                        data-name="Path 7868"
                        d="M1964.032 128.617l30.307-16.533"
                        fill="none"
                        stroke="#6f7175"
                        strokeWidth={1}
                        transform="translate(-1722 -109.468)"
                    />
                    <Path
                        data-name="Path 7869"
                        d="M0 0l-7.377 1.118L-4.934 5.6 0 0z"
                        transform="translate(-1722 -109.468) translate(1999.135 109.468)"
                        fill="#6f7175"
                    />
                </G>
            </G>
        </Svg>
    )
}