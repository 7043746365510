import { createStackNavigator } from '@react-navigation/stack'
import { RootView } from '../../common/root-view'
import { ScreenNames } from '../../common/screen-names'
import { Card } from '../common/card'
import { SwitchesEmotionsDetailView } from '../switches-emotions-detail-view'
import { SwitchesHome } from '../switches-home'
import { SwitchesParamList } from './switches-param-list'


const Stack = createStackNavigator<SwitchesParamList>()

export const SwitchesHomeMain = () => (
    <RootView>
        <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={ScreenNames.SwitchesHome} >
            <Stack.Screen name={ScreenNames.SwitchesHome} component={SwitchesHome} />
            <Stack.Screen name={ScreenNames.Card} component={Card} />
            <Stack.Screen name={ScreenNames.SwitchesEmotionsDetailView} component={SwitchesEmotionsDetailView} />
        </Stack.Navigator>
    </RootView>
)