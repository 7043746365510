import { gql, makeVar, useQuery, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { GetStaffByCognitoUsernameQuery, GetStaffByCognitoUsernameQueryVariables, Staff } from '../../common/API';
import { getStaffByCognitoUsername } from '../../common/graphql/queries';
import { rvCurrentUser, rvUserGroup } from '../login/login-state';
import { UserGroup } from '../../common/constants';

export const rvCurrentNonTeachingStaff = makeVar<Staff | undefined | null>(undefined)

export const useNonTeachingStaffState = () => {

    const user = useReactiveVar(rvCurrentUser)
    const userGroup = useReactiveVar(rvUserGroup)
    const currentNonTeachingStaff = useReactiveVar(rvCurrentNonTeachingStaff)

    const { data } = useQuery<GetStaffByCognitoUsernameQuery, GetStaffByCognitoUsernameQueryVariables>(gql`${getStaffByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user && userGroup !== UserGroup.NonTeachingStaff })

    useEffect(() => {
        rvCurrentNonTeachingStaff(data?.getStaffByCognitoUsername?.items?.[0])
    }, [data])


    return {
        currentNonTeachingStaff
    }
}