import { useReactiveVar } from '@apollo/client'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { StyleSheet, View } from 'react-native'
import { CommonStyles } from '../common/const'
import NotFoundPage from '../common/not-found-page'
import { PlaceHolderComponent } from '../common/placeholder-component'
import { RootView } from '../common/root-view'
import { ScreenNames } from '../common/screen-names'
import { StudentExistingJoinClassMain } from '../login/student-existing-join-class-main'
import { SwitchesActiviesDetailView } from '../switches/activities/switches-activies-detail-view'
import { SwitchesActiviesDisplayAll } from '../switches/activities/switches-activies-display-all'
import { Card } from '../switches/common/card'
import { rvSwitchesScreenState } from '../switches/common/current-screen-state'
import { SwitchesAcitiviesMain } from '../switches/navigation/switches-activity-main'
import { SwitchesBottomNavigation } from '../switches/navigation/switches-bottom-navigation'
import { SwitchesExploreAllMain } from '../switches/navigation/switches-explore-all-main'
import { SwitchesHomeMain } from '../switches/navigation/switches-home-main'
import { SwitchesToolsMain } from '../switches/navigation/switches-tools-main'
import { SwitchesEmotionsDetailView } from '../switches/switches-emotions-detail-view'
import { SwitchesExploreAll } from '../switches/switches-explore-all'
import { SwitchesHome } from '../switches/switches-home'
import { SwitchesMemes } from '../switches/switches-memes'
import { SwitchesToolsDisplayAll } from '../switches/tools/switches-tools-display-all'
import { TeacherStudentIndividualStats, rvShowIndividualStudentHowTo } from '../teacher/teacher-student-individual-stats'
import { StudentCheckInMain } from './student-check-in-main'
import { StudentHome } from './student-home'
import { UserEditAvatar } from '../common/user-edit-avatar'
import { StudentHeader } from './student-header'
import StudentModalOnboarding from './student-modal-onboarding'
import { StudentProfileMain } from './student-profile-main'
import { StudentMainNavigatorParamList } from './student-route-param-types'
import { rvCurrentStudent } from './use-student-state'
import StudentIndividualHowToModal from './student-individual-how-to-modal'

const SideMenu = createDrawerNavigator<StudentMainNavigatorParamList>()

const StudentMainNavigator = () => {
    const currentStudent = useReactiveVar(rvCurrentStudent)
    const currentScreen = useReactiveVar(rvSwitchesScreenState)
    const studentStatsHowToModal = useReactiveVar(rvShowIndividualStudentHowTo)

    return (
        <RootView>
            <SideMenu.Navigator
                screenOptions={{
                    headerShown: true,
                    header: props => <StudentHeader {...props} />,
                    unmountOnBlur: true,
                    drawerStyle: CommonStyles.drawer,
                    swipeEdgeWidth: 0
                }}
                backBehavior="history"
                initialRouteName={ScreenNames.StudentHome}
            >
                <SideMenu.Screen name={ScreenNames.StudentHome} component={StudentHome} />
                <SideMenu.Screen name={ScreenNames.StudentCheckIn} component={StudentCheckInMain} />
                <SideMenu.Screen name={ScreenNames.StudentProfileMain} component={StudentProfileMain} />
                <SideMenu.Screen name={ScreenNames.NotFound} component={NotFoundPage} />
                {/* Switches Navigation */}
                <SideMenu.Screen name={ScreenNames.SwitchesHome} component={SwitchesHome} />
                <SideMenu.Screen name={ScreenNames.SwitchesExploreAll} component={SwitchesExploreAll} />
                <SideMenu.Screen name={ScreenNames.Card} component={Card} />
                <SideMenu.Screen name={ScreenNames.SwitchesMemes} component={SwitchesMemes} />
                <SideMenu.Screen name={ScreenNames.SwitchesEmotionsDetailView} component={SwitchesEmotionsDetailView} />
                <SideMenu.Screen name={ScreenNames.SwitchesActiviesDisplayAll} component={SwitchesActiviesDisplayAll} />
                <SideMenu.Screen name={ScreenNames.SwitchesActiviesDetailView} component={SwitchesActiviesDetailView} />
                <SideMenu.Screen name={ScreenNames.SwitchesToolsDisplayAll} component={SwitchesToolsDisplayAll} />
                <SideMenu.Screen name={ScreenNames.SwitchesExploreAllMain} component={SwitchesExploreAllMain} />
                <SideMenu.Screen name={ScreenNames.SwitchesToolsMain} component={SwitchesToolsMain} />
                <SideMenu.Screen name={ScreenNames.SwitchesHomeMain} component={SwitchesHomeMain} />
                <SideMenu.Screen name={ScreenNames.SwitchesActivityMain} component={SwitchesAcitiviesMain} />
                <SideMenu.Screen name={ScreenNames.UserEditAvatar} component={UserEditAvatar} />
                <SideMenu.Screen name={ScreenNames.TeacherStudentIndividualStats} component={TeacherStudentIndividualStats}
                />

                <SideMenu.Screen name={ScreenNames.JoinClass} component={StudentExistingJoinClassMain} options={{ title: 'JoinClass' }} />

            </SideMenu.Navigator>
            {currentStudent && !currentStudent?.onboarded && (
                <StudentModalOnboarding />
            )}
            {studentStatsHowToModal && <StudentIndividualHowToModal />}
            <View style={styles.bottomNavigation}>
                <SwitchesBottomNavigation currentScreen={currentScreen} />
            </View>
        </RootView>
    )
}
export default StudentMainNavigator

const styles = StyleSheet.create({
    bottomNavigation: {
        bottom: 0,
        position: 'relative',
    },
})