import { View, StyleSheet } from "react-native";
import { versionNumberString } from "../env";
import { IconSwitch4SchoolLogo } from "../icon/icon-switch-4-school-logo";
import { IconSwitch4SchoolsEW } from "../icon/icon-switch-4-schools-ew";
import { DefaultButton } from "./default-button";
import { DefaultText } from "./default-text";
import { copywriteyearstring } from "./get-year-date";
import { Page } from "./page";
import { DecidaColors } from "../../common/decida-colors";
import { openUrlWebView } from "../web-view/open-url-web-view";

interface Props {
  minVersion: string;
}


export const ApplicationOutOfDate = ({ minVersion }: Props) => {
  return (
    <Page style={styles.page}>
      <View style={styles.inputContainer}>
        <IconSwitch4SchoolLogo percent={0.6} style={styles.logostyle} />
        <IconSwitch4SchoolsEW
          height={160}
          width={165}
          marginTop={20}
          marginBottom={20}
        />
        <DefaultText style={styles.heading}>
          Application is out of date.
        </DefaultText>
        <DefaultText
          style={styles.subHeading}
        >{`Minimum operating version required:`}</DefaultText>
        <DefaultText
          style={styles.miniHeading}
        >{`Version ${minVersion}`}</DefaultText>
        <DefaultText
          style={styles.miniHeading}
        >{`Please go to the app store to update to the new version of the app.`}</DefaultText>
      </View>
      <View style={styles.copywrite}>
        <DefaultText style={styles.copywrite}>
          © Decida Digital Pty Ltd {copywriteyearstring}
        </DefaultText>
      </View>
      <DefaultText style={styles.buildVersion}>
        {versionNumberString}
      </DefaultText>
      <DefaultButton onPress={() => openUrlWebView('https://apps.apple.com/au/app/switch-4-schools/id1573864846')}>
        Open appstore
      </DefaultButton>
    </Page>
  );
};

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
  },
  page: {
    backgroundColor: DecidaColors.White,
  },
  inputContainer: {
    flexDirection: "column",
    width: 350,
    alignItems: "center",
  },
  loginButton: {
    backgroundColor: DecidaColors.Green,
  },
  forgotPasswordContainer: {
    width: 350,
    alignItems: "flex-end",
    paddingRight: 9,
  },
  forgotPasswordText: {
    color: DecidaColors.DarkBlue,
    height: 30,
  },
  logostyle: {
    backgroundColor: DecidaColors.Black,
    borderRadius: 12,
    padding: 20,
    width: "95%",
  },
  copywrite: {
    justifyContent: "flex-end",
    alignContent: "flex-end",
    fontSize: 14,
  },
  loginHeader: {
    alignItems: "flex-start",
    fontWeight: "bold",
    fontSize: 18,
  },
  leftAlign: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    marginLeft: 20,
    marginTop: 10,
  },
  buildVersion: {
    fontSize: 10,
    padding: 20,
  },
  subHeading: {
    textAlign: "center",
    fontSize: 16,
    paddingTop: 50,
  },
  miniHeading: {
    fontSize: 16,
    paddingBottom: 25,
    paddingTop: 15,
    textAlign: "center",
  },
  heading: {
    fontWeight: "bold",
    fontSize: 22,
    color: "red",
  },
});
