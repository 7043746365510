import { Hub } from 'aws-amplify';
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { useEffect, useState } from 'react';
import { AppState } from 'react-native';
import { refreshSession } from '../login/login-functions';
import { rvSubscriptionState } from './common-state';
import { useReactiveVar } from '@apollo/client';

enum AppStateStatus {
    Active = 'active',
    Background = 'background',
    Inactive = 'inactive',
    Unknown = 'unknown', 
    Extension = 'extension'
};

const useAppState = () => {
    const [aState, setAppState] = useState(AppState.currentState);
    const subscriptionState = useReactiveVar(rvSubscriptionState)

    const checkConnectionState = (data: any) => {
        const { payload } = data;
        if (payload.event === CONNECTION_STATE_CHANGE) {
          const connectionState = payload.data.connectionState as ConnectionState;
          // update connection state whenever it changed
          rvSubscriptionState(connectionState)
        }
    }

    useEffect(() => {
        // listen to api connection state changed
        // include (subscription, and graphql)
        const unsubscribe = Hub.listen('api', checkConnectionState)

        // App state listener
        const appStateListener = AppState.addEventListener(
            'change',
            nextAppState => {
                if (aState === AppStateStatus.Active && nextAppState === AppStateStatus.Inactive) {
                    console.log("User leaving", "=====> current state is:", nextAppState)
                }

                if (nextAppState === AppStateStatus.Active) {
                    console.log("User coming back", "====> current state is:", nextAppState)
                    if (subscriptionState === ConnectionState.Disconnected) {
                        // if connection state is disconnect
                        // or susbcription connection is closed / returns error
                        // restart all of the subscription
                        console.log("Subscription has disconnected, re-start subscription")
                        refreshSession(true)
                    }
                }
                setAppState(nextAppState);
            },
        );

        return () => {
            appStateListener?.remove();
            unsubscribe()
        };
    }, [subscriptionState]);

    return { aState }
}

export default useAppState