import Svg, { Path } from 'react-native-svg'
import { useReactiveVar } from '@apollo/client'
import { rvCard } from './card-color-scheme'

export const GhostArrow = () => {
    const color = useReactiveVar(rvCard)?.colorScheme

    return (
        <Svg viewBox="0 0 19.243 22.936">
            <Path id="Path_1343" data-name="Path 1343" d="M105.133,96.671l11.367-10.3L105.16,76v4.776c-.2.012-6.5.056-6.66.068V91.8h6.633Z" transform="translate(-98 -74.863)" fill="#fff" stroke={color} stroke-width="1" />
        </Svg>
    )
}