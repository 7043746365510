import { AntDesign } from '@expo/vector-icons';
import _ from 'lodash';
import React, { useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { Campus } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { IconEdit } from '../icon/icon-edit';
import SchoolAdmiGroupsAndClassList from '../school-admin/school-admin-groups-and-class-list';
import { rvSelectedCampusID } from '../school-admin/school-admin-state';
import { DefaultText } from './default-text';
import { navigationRef } from './root-navigation';
import { ScreenNames } from './screen-names';

interface CampusAccordionProps {
  campuses: Campus[];
}

const CampusAccordion: React.FC<CampusAccordionProps> = ({ campuses }) => {
  const [expanded, setExpanded] = useState<number | null>(null);

  const toggleAccordion = (index: number, campusID: string) => {
    setExpanded((prev) => (prev === index ? null : index));
    rvSelectedCampusID(campusID)
  };

  const goToCampus = (item: Campus) => () => {
    navigationRef.current?.navigate(ScreenNames.AdminCampus, { campusID: item.id, schoolID: item.schoolID })
  }

  return (
    <View style={{ paddingHorizontal: 60 }}>
      {_.sortBy(campuses, campus => campus.name.toLowerCase()).map((item, index) => (
        <View key={index}>
          <Pressable onPress={() => toggleAccordion(index, item?.id)}>
            <View
              style={styles.itemContainer}
            >
              <AntDesign name={expanded === index ? "down" : "right"} size={14} color={DecidaColors.AppleSystemGray2Dark} />
              <DefaultText>{item.name}</DefaultText>
              <Pressable onPress={goToCampus(item)} style={styles.icon} aria-label='Edit icon'>
                <IconEdit stylefill={DecidaColors.AppleSystemGray2Dark} />
              </Pressable>
            </View>
          </Pressable>
          {expanded === index && item.classGroups && (
            <View style={{ marginLeft: 15 }}>
              <SchoolAdmiGroupsAndClassList schoolID={item.schoolID} campusID={item.id} containerStyle={{ width: '100%', padding: 0 }} />
            </View>
          )}
        </View>
      ))}
    </View>
  )
};

export { CampusAccordion };

const styles = StyleSheet.create({
  icon: {
    width: 18,
    height: 18,
  },
  itemContainer: {
    width: '100%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    marginBottom: 10,
    backgroundColor: DecidaColors.GrayBackground,
    flexDirection: 'row',
  },
})