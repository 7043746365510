import { View, StyleSheet, StyleProp, TextStyle } from "react-native";
import { DefaultText } from "../../common/default-text";
import { Octicons } from '@expo/vector-icons';
import { DecidaColors } from "../../../common/decida-colors";

interface Props {
    textdata: string
    style?: StyleProp<TextStyle>
    Links?: () => JSX.Element


}


export const DefaultTextWithIndentationGrayDot = ({ textdata, Links }: Props) => {
    return (
        <View style={styles.content}>
            <View style={styles.containingView}>
                <View style={styles.icon}>
                    <View style={styles.extraTipsIcon}>
                        <Octicons name="primitive-dot" size={10} color={DecidaColors.Gray} />
                    </View>
                </View>
                {Links ? <Links /> : null}
                <DefaultText style={styles.text}>
                    {textdata}
                </DefaultText>
            </View>

        </View>
    )

}



const styles = StyleSheet.create({

    icon: {
    },
    content: {
        justifyContent: 'center',
        marginVertical: 2,
        marginLeft: 60,
        marginRight: 10,

    },
    text: {
        textAlign: 'auto',
        color: DecidaColors.Gray

    },
    extraTipsIcon: {
        marginHorizontal: 10,

    },
    containingView: {
        flexDirection: 'row',
        alignItems: 'center',
    }
})

