import { Activities } from '../../common/activities'
import { ActivityGroupTemplate } from '../../common/activity-group-template'
import { ExtraSwitchesIcon } from '../icons/extra-switches-icon'

export const SwitchesActivityGroupExtraSwitches: ActivityGroupTemplate = {
    title: 'Extra switches',
    Icon: ExtraSwitchesIcon,
    activity: Activities.EXTRA_SWITCHES,
    subtitle: ''
}
