import { gql, useQuery, useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import { StyleSheet, View } from 'react-native';
import { CheckInSession, Class, GetStudentClassByClassIdQuery, GetStudentClassByClassIdQueryVariables, ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables, ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables, StudentCheckIn, StudentClass, StudentClassApprovalStatus } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { getStudentClassByClassId, listCheckInSessionsByClassId, listStudentCheckInsByCheckInSessionId } from '../../common/graphql/queries';
import { StudentCheckInCacheObject } from '../../common/student-check-in-cache';
import { DefaultText } from '../common/default-text';
import { rvPrintMode } from '../common/export-to-pdf-button';
import { rvIsLoading } from '../common/loading';
import { useResponsive } from '../common/use-responsive';
import { AVATARS_WRAPPER_CONTANER_PADDING, AVATAR_CONTAINER_PADDING, AVATAR_WRAPPER_WIDTH } from './teacher-consts';
import { StudentAndStudentClass } from './teacher-state';
import TeacherStatsLastPeriodAndRequestedChatItems from './teacher-stats-last-period-and-requested-chat-items';
import { TeacherStatsPieChartLastCheckIn } from './teacher-stats-pie-chart-last-check-in';
import { TeacherStatsProgressChartLastCheckin } from './teacher-stats-progress-chart-last-checkin';


export type StudentAndStudentClassAndStudentCheckIn = {
  student: StudentAndStudentClass;
  studentCheckIn?: StudentCheckIn | StudentCheckInCacheObject | undefined;
}

interface Props {
  visible?: boolean;
  noDataText?: string;
  showGraph?: boolean;
  onAvatarPress?: (studentCheckIn: StudentAndStudentClassAndStudentCheckIn) => void;
  clazz?: Class
}


export const TeacherStatsLastPeriodAndRequestedChat = ({
  visible = true,
  noDataText = "",
  showGraph = false,
  onAvatarPress,
  clazz
}: Props) => {

  const { data: checkInSessionsResponse } = useQuery<ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables>(gql`${listCheckInSessionsByClassId}`, { variables: { classID: clazz?.id || "", limit: 5000 }, skip: !clazz })

  const checkInSessions = _.orderBy(checkInSessionsResponse?.listCheckInSessionsByClassId?.items.filter((item) => item?._deleted !== true), "updatedAt", "desc") as CheckInSession[]

  const { data: studentCheckInResponse } = useQuery<ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables>(gql`${listStudentCheckInsByCheckInSessionId}`, { variables: { checkinsessionID: checkInSessions?.length ? checkInSessions[0]?.id || "" : "", limit: 5000 }, skip: !checkInSessions })
  const lastStudentCheckIns = studentCheckInResponse?.listStudentCheckInsByCheckInSessionId?.items.filter(item => item?._deleted !== true) as StudentCheckIn[] || []

  const { data: studentClassResponse } = useQuery<GetStudentClassByClassIdQuery, GetStudentClassByClassIdQueryVariables>(gql`${getStudentClassByClassId}`, { variables: { classID: clazz?.id || "" }, skip: !clazz })
  const currentStudentClasses = studentClassResponse?.getStudentClassByClassId?.items.filter((item) => item?._deleted !== true)

  const isLoading = useReactiveVar(rvIsLoading)

  const activeStudentClasses = currentStudentClasses
    ?.filter((studentClass) => studentClass?.status === StudentClassApprovalStatus.APPROVED) as StudentClass[]


  const lastCheckIn = _.last(checkInSessions);

  const { isNarrowScreen } = useResponsive()


  const isPrintMode = useReactiveVar(rvPrintMode)

  if (!lastCheckIn || isLoading) {
    return null;
  }

  return activeStudentClasses?.length === 0 ? (
    <>
      <DefaultText style={styles.noDataText}>{noDataText}</DefaultText>
    </>
  ) : (
    <>

      {showGraph && visible && !isLoading ? (
        <View
          style={isPrintMode ? styles.graphContainerPrint : [isNarrowScreen ? styles.rowInternalMobile : styles.rowInternal, { marginBottom: !lastStudentCheckIns?.length && !isNarrowScreen ? -80 : !isNarrowScreen ? -40 : 0 }]}
        >
          <TeacherStatsPieChartLastCheckIn
            studentCheckIn={lastStudentCheckIns}
          />
          <TeacherStatsProgressChartLastCheckin
            studentCheckIn={lastStudentCheckIns}
            activeStudentClasses={activeStudentClasses || []}
          />
        </View>
      ) : (
        <></>
      )}

      {visible && (
        <TeacherStatsLastPeriodAndRequestedChatItems
          currentClass={clazz}
          onAvatarPress={onAvatarPress}
        />
      )}

    </>
  );
};


const avatarSize = 60;

const styles = StyleSheet.create({
  graphContainerPrint: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxHeight: 250,
    justifyContent: 'center'
  },
  noDataText: {
    color: DecidaColors.Gray,
  },
  avatarsScrollView: {
    flexGrow: 0,
    width: "100%",
  },
  avatarsScrollViewContent: {
    flexWrap: "wrap",
    padding: AVATARS_WRAPPER_CONTANER_PADDING,
    paddingBottom: 20,
  },
  avatarContainer: {
    padding: AVATAR_CONTAINER_PADDING,
    width: AVATAR_WRAPPER_WIDTH,
    alignItems: "center",
    marginBottom: 20,
  },
  avatar: {
    width: avatarSize,
    height: avatarSize,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  row: {
    flexGrow: 0,
    width: "100%",
  },
  rowInternal: {
    width: "100%",
    alignItems: 'flex-start',
    flexDirection: "row",
    height: '100%',
    maxHeight: 300,
    justifyContent: 'center'
  },
  rowInternalMobile: {
    width: "100%",
    alignItems: 'center',
    flexDirection: "column",
  },
  avatars: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});
