import { Text, View } from 'react-native'
import { AudioHandler } from '../../../common/audio-handler'
import { audio } from '../../../common/audio-imports'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { BoxBreathingIcon } from '../../explore/src/box-breathing-icon'
import { BoxBreathingIconInverted } from '../../explore/src/box-breathing-icon-inverted'
import { BoxBreathingImage1 } from './src/box-breathing-image1'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles } from '../../../common/const'
import DefaultTextWithLink from '../../common/default-text-with-link'

export const SwitchesEmotionsAnxiousBoxBreathing: CardTemplate = {
    cardId: "4eaefdc5-55d8-4236-90e2-83305ec2e928",
    title: 'Box breathing',
    lessonPlan: true,
    Header: BoxBreathingIconInverted,
    Icon: BoxBreathingIcon,
    PerfectFor: () => (
        <>

            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Calming general anxiety and panicked behaviour, and slowing the physical signs of stress, such as increased heart rate, rapid breathing, and tense muscles."} />
        </>
    ),
    CardContent: () => (
        <>

            <View style={commonCardStyles.container}>
                <View style={commonCardStyles.imagecontainer}>
                    <View style={{ width: 350, height: 350, }}>
                        <BoxBreathingImage1 />
                    </View>
                    <AudioHandler audioFile={audio.boxBreathing} />
                </View>

                <DefaultTextWithDownArrow textdata={"Sit comfortably in a chair with your hands resting on your knees."} />
                <DefaultTextWithDownArrow textdata={"Slowly breathe in for four seconds, hold your breath for four seconds, breathe out for four seconds, and hold again for four seconds. "} />
                <DefaultTextWithoutArrows textdata={"Repeat until you feel calm and clear headed."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: 'Deep breathing lowers your stress hormones by up to 30%, which has an instant physiological impact on your body, clearing the mind, relaxing the body, and improving focus. Box breathing is a powerful, yet simple, relaxation technique that returns breathing to its normal rhythm.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Apply the process of mental visualisation to perform a controlled breathing meditative exercise to manage anxious thoughts. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanText}>• Explain that meditation can produce a deep state of relaxation and a tranquil mind. During meditation, you focus your attention and eliminate the stream of jumbled thoughts that may be crowding your mind and causing stress.
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Draw a square on the board like the diagram below, or refer to the Box Breathing switch, and then facilitate as a group activity, joining in as you count and breathe aloud:
                        </DefaultText>

                        <View style={commonCardStyles.imagecontainer}>
                            <View style={{ width: 350, height: 350, marginVertical: 10, }}>
                                <BoxBreathingImage1 />
                            </View>
                        </View>

                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Sit comfortably.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Close your eyes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Imagine a square. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Draw slowly along the top of the square for four seconds as you breathe in deeply, counting in your mind as you go. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Draw slowly down the side of the square for four seconds while you hold your breath. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Draw slowly along the bottom of the square for four seconds as you breathe out. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Draw slowly along the remaining side of the square for four seconds as you hold your breath. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Repeat this process 4 times, or as many times as you feel you need to calm your mind and body.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Focus on slowing your breathing down, don’t rush.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Provide a quiet space and dim the lighting (if possible) and ask students to complete the meditation. You may like to provide instruction or play the audio track from the app to set the pace of the activity. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Invite the students to open their eyes and give them a moment to readjust before turning the lights back up. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Debrief with the students to ask how the exercise made them feel, and how this can be a great technique for quietening the mind when preparing for sleep.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• If students have their own device, ask them to find the switch in the Switch4Schools app. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Quiet space, ideally with dim lighting </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Speaker if using the guided audio in the app</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Box breathing relaxation technique: how to calm feelings of stress or anxiety -  </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=tEmt1Znux58' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Box Breathing Techniques and Benefits - </DefaultText>
                        <DefaultTextWithLink text='https://www.verywellmind.com/the-benefits-and-steps-of-box-breathing-4159900' />

                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"If possible, create a quiet environment with no distractions."} />
            <DefaulTextWithTipsIcon textdata={"Using a finger to draw the box can help with the visualisation and regulating speed."} />
            <DefaultTextWithoutArrowsItallic textdata={"No rushing! Try slowing down the speed during each round to a comfortable level. "} />
            <DefaulTextWithTipsIcon textdata={"Reflect on the positive way it feels to have stopped for a moment to breathe and calm down the mind."} />
        </>

    ),
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action, CardActionType.Thought],
    level: [CardIntensitylevelType.One, CardIntensitylevelType.Three, CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Anxious]
}

