import { forwardRef, LegacyRef } from 'react'
import { StyleSheet, TextInput, TextInputProps, TextStyle } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from './default-text'

type Props = {
    value?: string
    onChangeText?: (text: string) => void
    placeholder?: string
    width?: number
    editable?: boolean
    customInputStyle?: TextStyle
    label?: string
    inputProps?: TextInputProps
    error?: string
    onBlur?: () => void
}

const InputDefault = forwardRef((props: Props, ref?: LegacyRef<TextInput>) => {
    const { placeholder, editable = true, customInputStyle, label, inputProps, error, onBlur } = props

    return (
        <>
            {label && <DefaultText style={styles.inputLabel}>{label}</DefaultText>}
            <TextInput
                ref={ref}
                editable={editable}
                style={[styles.inputStyle, { width: props.width, fontStyle: props.value?.length ? 'normal' : 'italic', backgroundColor: !editable ? DecidaColors.AppleSystemGray5Light : DecidaColors.White }, customInputStyle]}
                placeholder={placeholder}
                value={props.value}
                onChangeText={props.onChangeText}
                placeholderTextColor={DecidaColors.AppleSystemGrayLight}
                autoComplete={inputProps?.autoComplete}
                autoCorrect={inputProps?.autoCorrect}
                onBlur={onBlur}
            />
            {error && <DefaultText style={styles.errorMessage}>{error}</DefaultText>}

        </>
    )
})

export default InputDefault

const styles = StyleSheet.create({
    inputStyle: {
        padding: 5,
        marginBottom: 10,
        borderRadius: 20,
        borderWidth: 1,
        borderColor: DecidaColors.AppleSystemGrayLight,
    },
    inputLabel: {
        fontSize: 16,
        color: DecidaColors.GrayText,
        fontWeight: '600',
        marginVertical: 5
    },
    errorMessage: {
        color: DecidaColors.Red,
        fontSize: 14,
        marginBottom: 5
    },
})