import Svg, {
    Circle,
    Path,
    SvgProps
} from "react-native-svg"

export const IconHeaderUserLoginAvatar = (props: SvgProps) =>
    <Svg width={props.width || "26"} height={props.width || "26"} viewBox="0 0 26 26">
        <Circle cx="13" cy="13" r="13" fill={props.color || "#fff"} />
        <Path d="M4876.364,3407.223c5.2,0,7.833-1.093,8.53-1.464.36-.193,1.7-1.011,1.463-1.721-.3-.877-2.116-1.31-3.551-1.645-2.032-.477-3.808-1.154-3.808-2.343,0-1.437,2.636-3.771,2.636-7.8a5.271,5.271,0,0,0-10.542,0c0,4.026,2.636,6.359,2.636,7.8,0,1.19-1.775,1.867-3.808,2.343-1.435.335-3.256.769-3.551,1.645-.238.709,1.1,1.529,1.464,1.721.7.372,3.332,1.464,8.53,1.464Z" transform="translate(-4863.341 -3384.393)" fill={props.fill || "#c6bebe"} />
    </Svg>
