import { extractNumberFromString } from "../../common/extract-number-from-string"
import { removeNumberFromString } from "../../common/remove-number-from-string"

const columns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X']

export const getUpperboundColumn = (upperBound: string) => {
    const upperBoundColumn = removeNumberFromString(upperBound)
    const maxColumn = 'X'

    const indexOfUpperBoundColumn = columns.indexOf(upperBoundColumn)
    
    return indexOfUpperBoundColumn > 0 ? upperBoundColumn : maxColumn
}

export const getRangeFromWorksheetRef = (ref: string) => {
    const upperBound = ref.split(':')[1]
    const upperBoundColumn = getUpperboundColumn(upperBound)
    const upperBoundRow = extractNumberFromString(upperBound)

    return {
        noOfRows: Number(upperBoundRow),
        columns: columns.slice(0, columns.indexOf(upperBoundColumn) + 1)
    }
}
