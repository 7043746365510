import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconHappy3Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 3)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(2 / 3)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg testID='svg-happy-emotion' viewBox="0 0 515.264 351.799">
            <Path d="M0,0,203.091-134.5" transform="translate(1.932 340.246)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.386,6.1l9.859,14.887Z" transform="translate(216.892 197.887)" fill="#657170" />
            <Path d="M0,0,124.912-82.656" transform="translate(229.994 188.99)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.389,6.094l9.854,14.892Z" transform="translate(366.776 98.479)" fill="#657170" />
            <Path d="M0,0,126.888-85.438" transform="translate(375.565 93.389)" fill="none" stroke="#657170" strokeWidth="7" />
            <Path d="M0,0-25.339,6.3l9.973,14.812L0,0Z" transform="translate(514.262)" fill="#657170" />
            <G {...onPressLevel1} transform="translate(-1308.936 -686.755)">
                <Path d="M0,0,208.958-.868l.338-138.115Z" transform="translate(1316.531 1038.047)" fill="#b6dab8" />
                <Text transform="translate(1402.62 1021.333)" fill="#42454a" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Peaceful</TSpan></Text>
            </G>
            <G {...onPressLevel3} transform="translate(-1308.936 -686.755)">
                <Path d="M0,0,138-91.8l.164,235.622-139.277.111Z" transform="translate(1536.71 893.064)" fill="#56a859" />
                <Text transform="translate(1553.593 976.002)" fill="#fff" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Cheerful</TSpan></Text>
            </G>
            <G {...onPressLevel5} transform="translate(-1308.936 -686.755)">
                <Path d="M0,0,138.6-93.192l-.838,335.313L.046,242.233Z" transform="translate(1685.101 795.264)" fill="#0e8413" />
                <Text transform="translate(1704.035 918.915)" fill="#fff" fontSize="26" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Ecstatic</TSpan></Text>
            </G>
        </Svg>
    )
}