import { StyleSheet, Text, View } from 'react-native'
import { AudioHandlerSwitches } from '../../../common/audio-handler-switches'
import { SeeItBallString } from './src/see-it-ball-string'
import { SeeItBlender } from './src/see-it-blender'
import { SeeItBlueLight } from './src/see-it-blue-light'
import { SeeItStopSign } from './src/see-it-stop-sign'
import { rvCurrentSwitchAudioPlaying } from '../../../common/common-state'


export enum SeeItAudio {
    StopSign = "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/See+it_Stop+Sign+audio.mp3",
    TheBlender = "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/See+it_The+Blender+audio.mp3",
    BallOfString = "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/See+it_Ball+of+String+audio.mp3",
    BlueLight = "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/See+it_Blue+light+audio.mp3"
}

const SeeItAudioButtons = () => {
    const handleAudio = async (audio: SeeItAudio, playAudio: () => Promise<void>, pauseAudio: () => Promise<void>, playState: boolean) => {
        if (playState) {
            await pauseAudio()
            rvCurrentSwitchAudioPlaying('')
        } else {
            await playAudio()
            rvCurrentSwitchAudioPlaying(audio)
        }

    }
    return (

        <View style={styles.iconContainer}>
            <View style={styles.icon}>
                <AudioHandlerSwitches
                    accessibilityLabel="See it, Ball of string"
                    customIcon={SeeItBallString}
                    onPlay={async (playAudio, pauseAudio, playState) => {
                        await handleAudio(SeeItAudio.BallOfString, playAudio, pauseAudio, playState)
                        // setCurrentAudio(SeeItAudio.BallOfString)
                    }}
                    audioFile={{ uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/See+it_Ball+of+String+audio.mp3" }}
                    audioTitle=''
                    hideText />
            </View>
            <View style={styles.icon}>
                <AudioHandlerSwitches
                    accessibilityLabel="See it, Blender"
                    customIcon={SeeItBlender}
                    onPlay={(playAudio, pauseAudio, playState) => {
                        // setCurrentAudio(SeeItAudio.TheBlender)
                        handleAudio(SeeItAudio.TheBlender, playAudio, pauseAudio, playState)
                    }}
                    audioFile={{
                        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/See+it_The+Blender+audio.mp3"
                    }}
                    audioTitle=''
                    hideText />
            </View>
            <View style={styles.icon}>
                <AudioHandlerSwitches
                    accessibilityLabel="See it, Blue light"
                    customIcon={SeeItBlueLight}
                    onPlay={(playAudio, pauseAudio, playState) => {
                        // setCurrentAudio(SeeItAudio.BlueLight)
                        handleAudio(SeeItAudio.BlueLight, playAudio, pauseAudio, playState)
                    }}
                    audioFile={{ uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/See+it_Blue+light+audio.mp3" }}
                    audioTitle=''
                    hideText />
            </View>
            <View style={styles.icon}>
                <AudioHandlerSwitches
                    accessibilityLabel="See it, stop sign"
                    customIcon={SeeItStopSign}
                    onPlay={(playAudio, pauseAudio, playState) => {
                        // setCurrentAudio(SeeItAudio.StopSign)
                        handleAudio(SeeItAudio.StopSign, playAudio, pauseAudio, playState)
                    }}
                    audioFile={{ uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/See+it_Stop+Sign+audio.mp3" }}
                    audioTitle=''
                    hideText />
            </View>
        </View>

    )
}
export default SeeItAudioButtons

const styles = StyleSheet.create({
    iconContainer: {
        flexDirection: 'row',
        marginHorizontal: 20,
        justifyContent: 'center',
    },
    icon: {
        flex: 1,
        width: '100%',
        maxWidth: 80,
        maxHeight: 80,
        height: '100%',
        marginRight: 10,
    },
})
