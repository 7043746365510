import Svg, { Line, Rect } from 'react-native-svg'
import { emotionColor } from '../common/get-emotion-color'
import { Emotion } from '../models'
import { DecidaColors } from '../../common/decida-colors'

interface Props {
    emotion: Emotion | keyof typeof Emotion
}

const barHeight = '100%'
const barWidth = 10
const strokeColor = DecidaColors.Black
const strokeWidth = 1

export const LegendsEmotionBar = ({ emotion }: Props) => {
    const fill = emotionColor[emotion]

    return (
        <Svg width={barWidth} height={barHeight} viewBox={`0 0 ${barWidth} ${barHeight}`} stroke={strokeColor} strokeWidth={strokeWidth}>
            <Rect width={barWidth} height={barHeight} fill={fill} />

            <Line
                x1={0}
                x2={barWidth}
                y1={'50%'}
                y2={'50%'}
                stroke={strokeColor}
                strokeWidth={strokeWidth}
            />

        </Svg>
    )
}