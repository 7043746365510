import { Linking, StyleSheet, Text, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { MakeANewFriendIcon } from '../../explore/src/make-a-new-friend-icon'
import { MakeANewFriendIconInverted } from '../../explore/src/make-a-new-friend-icon-inverted'
import { MakeANewFriendImage1 } from './src/make-a-new-friend-image1'

import SwitchCardVideo from '../../../common/switch-card-video'
import { Videos } from '../../../models'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DecidaColors } from '../../../../common/decida-colors'

const makeNewFriendVideo1: Videos = {
    id: "1",
    url: 'https://www.youtube.com/embed/6qu2cQnULjE',
    title: ''
}

export const SwitchesEmotionsHappyMakeANewFriend: CardTemplate = {
    cardId: "28303a90-8ccd-47bd-8dac-b3cbe5127c50",
    title: 'Make a new friend',
    Header: MakeANewFriendIconInverted,
    Icon: MakeANewFriendIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <View>

            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Building confidence, conversational skills and connectedness."} />
            <View style={styles.imageContainer}>
                <MakeANewFriendImage1 />
            </View>

        </View>
    ),
    CardContent: () => (
        <View>
            <View>
                <DefaulttextWithRightArrow textdata={"THINK about someone you think you might like to be friends with. It could be someone in your class,  another class, or a club you belong to."} />
                <DefaulttextWithRightArrow textdata={`APPROACH them with a smile and start a conversation. Introduce yourself by saying, "Hi, my name is [your name]." Then, ask them a question to start the conversation, like "What are you doing?" or "How's your day going?"`} />
                <DefaulttextWithRightArrow textdata={"LISTEN and ask curious questions. Listen carefully to what they say and show that you're interested. Ask questions to learn more about them and what they like. For example, if they mention they like soccer, ask them about their favourite team or player."} />
                <DefaulttextWithRightArrow textdata={`FIND an activity to do together. Suggest doing an activity that you both enjoy. You can say, "Do you want to play a game with me?" or "Let's draw pictures together!" If they are already doing something, ask if you can join in.`} />
                <DefaulttextWithRightArrow textdata={"ENJOY the activity and keep the conversation going by sharing your thoughts and experiences. Ask more questions and listen to what they have to say. Be kind, respectful, and include them in the conversation."} />
                <DefaulttextWithRightArrow textdata={"REFLECT on the experience. After spending time together, think about how you felt during the interaction. Did you enjoy spending time with your new friend? What did you learn about them? Think about ways you can continue to build your friendship."} />

            </View>
        </View>

    ),
    cardContentTemplate: 'column',
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: '',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define the steps required to build a positive relationship, and
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Build confidence developing new relationships </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain to the students that making new friends can sometimes feel intimidating, but there are steps they can take to make it easier.</DefaultText>

                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write the steps on the board:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- 1. Say hello </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- 2. Smile </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- 3. Ask questions</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- 4. Listen</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- 5. Be Kind</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- 7. Joining in</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- 8. Share</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to brainstorm specific ways they can follow each step. Refer to the “Children explain how you make friends” video for inspiration.</DefaultText>
                        <SwitchCardVideo video={makeNewFriendVideo1} />

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Make a New Friend Worksheet (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the ‘Make a new friend’ worksheet to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the students to write or draw things they can do or say to make a new friend in each section of the worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage creativity and provide assistance as needed.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Find a Friend Checklist (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give each student a ‘Find a friend’ checklist. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set a time limit for students to practice their conversation starting skills.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the students to talk to their classmates, saying hello, smiling, introducing themselves, and finding out if they meet any of the checklist requirements.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If they do, they write the name of the person in the corresponding box on their checklist and move on to meet a new person.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Students continue until they have names in all eight boxes on their checklist.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage respectful and inclusive interactions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Extension Activity: Lunch Buddy Program (optional):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of a 'lunch buddy' program, where students can pair up with someone they may not know well during lunchtime to foster new friendships and social connections.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the purpose and guidelines of the program, such as taking turns choosing conversation topics or activities.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Debrief and Discussion (10 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students together and have a debriefing discussion about their experiences during the activities.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Validate their efforts and encourage them to share their thoughts and feelings about making new friends.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss that not everyone may want to be their friend, and that's okay. Emphasise the importance of being respectful and kind even if a friendship doesn't develop.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, direct them to find the switch in the Switch4Schools app as an additional activity. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the key points of the lesson, including the steps for making new friends and the importance of respect and kindness. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Express confidence in their ability to develop strong friendship skills and encourage ongoing practice. </DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='• Make a new friend worksheet' link={Worksheets.makeANewFriend.uri} />
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='• Find a friend checklist' link={Worksheets.findAFriend.uri} />

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Children explain how you make Friends -   </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=6qu2cQnULjE' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Teaching kids how to make friends -  </DefaultText>
                        <DefaultTextWithLink text='https://theeducatorsspinonit.com/teaching-kids-how-to-make-friends/' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>

            <WhyDoesItWorkComponent message='Extending social networks helps builds resilience, and is proven to be a key correlate to living a happier, healthier, longer life. Practicing making new friends when you’re happy can help reduce anxiety, depression related to loneliness, and mitigate for the effect of ostracism.' />
            <DefaultTextWithoutArrowsBold textdata={"Handy Hint"} />
            <DefaulTextWithTipsIcon textdata={"If the person you choose doesn’t want to be friends, try someone else and come back to them another day."} />
            <DefaulTextWithTipsIcon textdata={"Making new friends is an adventure! Remember to be yourself, show kindness, and be open to new experiences. With these steps, you'll be on your way to making great new friends."} />

        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Make+a+new+friend+new+voice+recording.m4a",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Happy]
}

const styles = StyleSheet.create({
    imageContainer: {
        height: 200,
    }
})