import { View } from 'react-native'
import { DefaultTextWithDownArrow } from '../../../common/default-text-with-down-arrow'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CompassionIcon } from '../../icons/compassion-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { CompassionIconInverted } from '../../icons/compassion-icon-inverted'

export const SwitchesActivitiesHealthyHabitsCompassion: CardTemplate = {
    cardId: "6f7b4d86-dd8d-4430-964c-b5e184262aba",
    title: 'Compassion',
    Header: CompassionIconInverted,
    Icon: CompassionIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"The compassion switch is a mantra that helps build empathy, gain perspective, and be less judgemental - particularly when dealing with members of an outgroup."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithoutArrowsBold textdata={"Think about the person that has made you angry. With that person in mind, repeat to yourself:"} />
                <DefaultTextWithDownArrow textdata={"“Just like me, this person wants to be happy”"} />
                <DefaultTextWithDownArrow textdata={"“Just like me, this person doesn’t want to suffer”"} />
                <DefaultTextWithDownArrow textdata={"“Just like me, this person can be sad and lonely”"} />
                <DefaultTextWithDownArrow textdata={"“Just like me, this person has things they need”"} />
                <DefaultTextWithoutArrows textdata={"“Just like me, this person is learning about life”"} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.HEALTHY_HABITS,
    whyDoesItWorkText: 'Holding on to unforgiveness is like drinking poison and then expecting the other person to get sick. Studies have shown that anger and grudges lower our immune system and limit our cognitive capacity. Do yourself a favour and learn to forgive people - even when it feels unjust.',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Compassion.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.HEALTHY_HABITS],
    action: [CardActionType.Thought],
    level: []
}

