import gql from "graphql-tag"
import uuid from 'react-native-uuid'
import { onMutateClassById, onPing } from "../../common/graphql/subscriptions"
import { apolloClient } from "../common/common-state"
import { ping } from "../../common/graphql/mutations"
import { ApolloClient, NormalizedCacheObject, makeVar } from "@apollo/client"
import { OnMutateClassByIdSubscription, OnMutateClassByIdSubscriptionVariables, OnPingSubscription, OnPingSubscriptionVariables, PingMutation, PingMutationVariables } from "../../common/API"
import { handleRestartApp } from "../common/handle-restart-app"

const defaultTimeout = 10000
export const deviceID = uuid.v4().toString()

let heartbeatTimeout: ReturnType<typeof setTimeout> | undefined
let heartbeatResponseTimeout: ReturnType<typeof setTimeout> | undefined

let onPingSubscription: ReturnType<ReturnType<ApolloClient<NormalizedCacheObject>['subscribe']>['subscribe']> | undefined = undefined

apolloClient.subscribe<OnMutateClassByIdSubscription, OnMutateClassByIdSubscriptionVariables>({
    query: gql`${onMutateClassById}`,
    variables: { id: 'currentClassID' }
})

export const startHeartbeat = () => {
    if (onPingSubscription == undefined) {
        onPingSubscription = apolloClient.subscribe<OnPingSubscription, OnPingSubscriptionVariables>({
            query: gql`${onPing}`,
            variables: { id: deviceID },
        }).subscribe(value => {
            if (value.data?.onPing?.id === deviceID) {
                startHeartbeat()
            }
        })
    }

    if (heartbeatTimeout) {
        clearTimeout(heartbeatTimeout)
    }

    if (heartbeatResponseTimeout) {
        clearTimeout(heartbeatResponseTimeout)
    }

    heartbeatTimeout = setTimeout(async () => {
        /* heartbeatResponseTimeout must be set before sending ping, otherwise we may
         * have a race condition where ping comback before heartbeatResponseTimeout is
         * defined.
         */
        heartbeatResponseTimeout = setTimeout(async () => {
            try {
                console.log('restarting app due to missing heartbeat')
                handleRestartApp()
            } catch (e) {
                console.error(e)
            }
            startHeartbeat()
        }, defaultTimeout);

        try {
            await apolloClient.mutate<PingMutation, PingMutationVariables>({
                mutation: gql`${ping}`,
                variables: { id: deviceID },
                fetchPolicy: 'network-only',
            })
        } catch (e) {
            console.error(e)
        }
    }, defaultTimeout);
}

export const stopHeartbeat = () => {
    heartbeatTimeout && clearTimeout(heartbeatTimeout)
    heartbeatResponseTimeout && clearTimeout(defaultTimeout)
}
