import { OnDataOptions, gql } from "@apollo/client";

type UpdateListQueryCache<ListQuery extends {}, ListQueryVariables extends {}, Subscription extends {}> = {
    listQuery: string;
    listQueryName: keyof ListQuery;
    subscriptionName: keyof Subscription
    variables?: ListQueryVariables;
}

export const updateListQueryCache = <ListQuery extends {}, ListQueryVariables extends {}, Subscription extends {}>({ listQuery, listQueryName, variables, subscriptionName }: UpdateListQueryCache<ListQuery, ListQueryVariables, Subscription>) => ({ data, client }: OnDataOptions<{ [K in keyof Subscription]: { id: string } | null }>) => {
    if (!data.data || !data.data[subscriptionName]) return

    const currentData = client.readQuery<{ [K in keyof ListQuery]: { items: any[] } }>({
        query: gql`${listQuery}`,
        variables,
    })


    if (!currentData) return

    const isDataExists = currentData[listQueryName].items.some((item) => data.data && item.id === data.data[subscriptionName]?.id)

    if (isDataExists) return

    client.writeQuery({
        query: gql`${listQuery}`,
        variables,
        data: {
            ...currentData,
            [listQueryName]: {
                ...currentData[listQueryName],
                items: [...currentData[listQueryName]?.items || [], data.data[subscriptionName]]
            }
        }
    })
}