import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

const introductionAnxiousVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/TDFEW3eAIlg",
    title: ""
}

export const SwitchesLessonPlansIntroducingAnxiety: CardTemplate = {
    cardId: "a6bcd6ec-a462-4646-9426-899661ac4c53",
    title: 'Introducing anxiety',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Deepen their understanding of the emotion "anxiety" by identifying its characteristics, expressing what anxiety looks and feels like, and exploring strategies for managing and coping with anxiety.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45-60 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Review of Emotions (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by briefly reviewing the emotions learned in the previous lessons, including "anxiety."</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to recall what they remember about the emotion "anxiety" and share examples of when they have felt anxious.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Characteristics of Anxiety (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the “What is Anxiety & Why Do We Feel Anxious?” [3:20]</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set up a large chart paper on an easel or wall.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write "Anxiety" at the top of the chart and invite the students to brainstorm words or phrases that describe what anxiety looks and feels like.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write their responses on the chart paper, encouraging them to explain their choices and expand on the ideas shared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss common characteristics, such as feeling worried, having a racing heart, or having butterflies in the stomach.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Identifying Anxiety Triggers (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to brainstorm situations or triggers associated with anxiety (e.g., starting a new school, speaking in front of a large group, a loud noise).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share why they think the situation might make someone feel anxious.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that feeling anxious is a normal response and that everyone has different things that make them feel anxious.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Expressing Anxiety (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite the students to express what anxiety looks like to them by drawing a picture or using art supplies to create a visual representation of anxiety. Encourage them to use colours, lines, and shapes that they associate with feeling anxious.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide an opportunity for volunteers to share their artwork and describe what they depicted, emphasising that there are different ways anxiety can be expressed.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Strategies for Managing Anxiety (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce strategies for managing and coping with anxiety, such as taking deep breaths, talking to a trusted adult, using positive self-talk, or engaging in calming activities like drawing or listening to soothing music.  You can refer to the anxious switches for some examples of activities to try.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss each strategy and encourage the students to share any strategies they personally use when they feel anxious.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Practice a simple deep breathing exercise together as a group (recommend Box breathing switch), inhaling deeply through the nose and exhaling slowly through the mouth.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of seeking support and using calming techniques to manage anxiety.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflecting on Anxiety (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students back in the circle.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to reflect on what they learned about anxiety and how it feels to be anxious.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to share their artwork or describe a situation that makes them feel anxious.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reiterate the importance of using strategies to manage anxiety and remind them that it's okay to ask for help when they need it.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>Extension Activities:</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Read books or tell stories about anxiety and discuss how the characters in the stories cope with their anxiety.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Play a game of "Feelings Charades" where students act out different emotions, including anxiety, and others guess the emotion being portrayed.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Encourage the students to share their feelings and anxieties in a safe and supportive environment by facilitating group discussions or journaling activities.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Teach and practice progressive muscle relaxation techniques with the students, guiding them to tense and relax different muscle groups to promote relaxation and reduce anxiety.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Complete lessons for the available ‘anxious’ switches.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='What is Anxiety & Why Do We Feel Anxious? video' link='https://www.youtube.com/watch?v=Z3t7dUyDFe0' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Large chart paper and markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Art supplies (crayons, coloured pencils, markers)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Blank paper or drawing sheets.</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Anxiety and fear in children  -</DefaultText>
                        <DefaultTextWithLink text='https://www.betterhealth.vic.gov.au/health/conditionsandtreatments/fear-and-anxiety-children' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Types of anxiety -</DefaultText>
                        <DefaultTextWithLink text='https://www.beyondblue.org.au/mental-health/anxiety/types-of-anxiety' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}