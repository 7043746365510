import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { DefaultText } from './default-text';

type TableRowProps = {
    title: string;
    description: string;
}

type DefaultTableProps = {
    tableData: TableRowProps[];
}

const TableRow: React.FC<TableRowProps> = ({ title, description }) => (
    <View style={styles.row}>
        <DefaultText style={styles.title}>{title}</DefaultText>
        <DefaultText style={styles.scenario}>{description}</DefaultText>
    </View>
);

const LessonPlanTable: React.FC<DefaultTableProps> = ({ tableData }) => {
    return (
        <View style={styles.container}>
            {tableData.map((data, index) => (
                <TableRow key={index} title={data.title} description={data.description} />
            ))}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '100%',
        padding: 16,
        marginLeft: 20,
        flexDirection: 'row'
    },
    row: {
        flex: 1,
        borderWidth: 0.5,
        borderColor: '#ccc',
        paddingVertical: 8,
        flexWrap: 'wrap',
        padding: 5
    },
    title: {
        fontSize: 17,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    scenario: {
        fontSize: 15,
    },
});

export default LessonPlanTable;
