/**
 * Specify the maximum of concurrent promises. Mainly used to make sure we stay under
 * the lambda memory limit.
 */
export const asapPromiseQueue = async <T>(items: T[], func: (param: T, index: number) => Promise<void>, maxQueueSize = 100) => {
    const itemsClone = [...items]
    const workers: Promise<void>[] = []
    const initialItemLength = items.length

    for (let i = 0; i < maxQueueSize && i < items.length; i++) {
        workers.push(worker(itemsClone, func, initialItemLength))
    }

    await Promise.all(workers)
}

const worker = async <T>(items: T[], func: (param: T, index: number) => Promise<void>, initialItemLength: number) => {
    let item = items.shift()
    while (item) {
        await func(item, initialItemLength - items.length - 1)
        item = items.shift()
    }
}