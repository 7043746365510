import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { SandAlphabetIcon } from '../../explore/src/sand-alphabet-icon'
import { SandAlphabetIconInverted } from '../../explore/src/sand-alphabet-icon-inverted'
import { SandAlphabetImage1 } from './src/sand-alphabet-image1'
import { openUrlWebView } from '../../../web-view/open-url-web-view'

export const SwitchesEmotionsAnxiousSleepChalk: CardTemplate = {
    cardId: "847c90f0-707a-4b31-a5c7-26fc13439a5e",
    title: 'Sand alphabet',
    lessonPlan: true,
    Header: SandAlphabetIconInverted,
    Icon: SandAlphabetIcon,
    Images: () => (
        <>
            <SandAlphabetImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Relieving your mind of negative thoughts and persistent worries that are robbing you of energy or confidence."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Sit comfortably, close your eyes and imagine sitting in sand."} />
                <DefaultTextWithDownArrow textdata={"Slowly draw the letter ‘A’ in the sand. Feel free to draw on your leg at the same time."} />
                <DefaultTextWithDownArrow textdata={"Go as slow as you can, being careful to imagine small details of what is happening."} />
                <DefaultTextWithDownArrow textdata={"Once you have finished the letter rub it out and write the letter ‘B’."} />
                <DefaultTextWithoutArrows textdata={"Continue through the alphabet."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.EXTRA_SWITCHES,
    whyDoesItWorkText: 'Creating a peaceful mental state is critical to performance and general happiness. Anxiety is the brain’s safety mechanism kicking in, indicating danger or imminent threat. Your brain stays in a more restless state because it is keeping you prepared for an imminent attack, for which you need to be in a highly activated state for. The sand alphabet exercise settles your anxiety and creates peace with an activity that engages large sections of your brain that are usually activated by anxiety (visual cortex, motor cortex etc.).',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 10, color: DecidaColors.Gray }}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Apply the process of mental visualisation to perform a controlled breathing meditative exercise to manage anxious thoughts.
                        </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Explain that meditation can produce a deep state of relaxation and a tranquil mind. During meditation, you focus your attention and eliminate the stream of jumbled thoughts that may be crowding your mind and causing stress.
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Explain the Sand Alphabet process so students understand what to expect, specifically that you will ask them to:
                        </DefaultText>

                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Sit comfortably.' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Close your eyes.' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Imagine sitting in sand, and think about what it feels like. What you can see and hear?' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Imagine drawing the letter A in the sand while you take a big, deep breath in. ' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Imagine rubbing out of the sand as you exhale. ' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Repeat this process throughout the alphabet. ' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Focus on slowing your breathing down, don’t rush.' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Keep going until I tell you time is up, starting again from A if you need to. ' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='' />
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Provide a quiet space and dim the lighting (if possible) and ask students to complete the meditation. You may like to provide instruction for the first few letters to set the pace of the activity.
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• After a couple of minutes invite the students to open their eyes and give them a moment to readjust before turning the lights back up.
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Debrief with the students to ask how the exercise made them feel, and how this can be a great technique for quietening the mind when preparing for sleep.
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• If students have their own device, ask them to find the switch in the Switch4Schools app.
                        </DefaultText>
                        <DefaultTextWithoutArrowsItallic textdata={"Note: An estimated 2-5% of the population have a lifelong inability to generate any images within their mind’s eye, called aphantasia. If you have a student that can’t imagine the sand they can still focus their attention on their breathing and can try drawing the letters on their leg with their finger to replicate the experience. "} />

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Quiet space, ideally with dim lighting </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>The Benefits of Meditation For Kids -   </DefaultText>
                        <Text style={{ textDecorationLine: 'underline', color: 'blue', paddingLeft: 40, fontSize: 20, }} onPress={() => {
                            openUrlWebView('https://thriveglobal.com/stories/the-benefits-of-meditation-for-kids/');
                        }}>https://thriveglobal.com/stories/the-benefits-of-meditation-for-kids/</Text>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>Aphantasia explained: some people can’t form mental pictures - </DefaultText>
                        <Text style={{ textDecorationLine: 'underline', color: 'blue', paddingLeft: 40, fontSize: 20, }} onPress={() => {
                            openUrlWebView('https://theconversation.com/aphantasia-explained-some-people-cant-form-mental-pictures-162445');
                        }}>https://theconversation.com/aphantasia-explained-some-people-cant-form-mental-pictures-162445</Text>
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"This also works if you write numbers. Start at 100 and count down."} />
            <DefaulTextWithTipsIcon textdata={"If you’re having trouble blocking out anxious thoughts, think about how the beach, sandpit or desert smells, the feeling of the sand, and even the sounds of the environment."} />
            <DefaulTextWithTipsIcon textdata={"Remember, it’s OK to get distracted during the exercise. When this happens, acknowledge it, put it away for later, and then go back to where you left off in the visualisation task. This takes practice, but you will get better over time."} />

            <DefaultTextWithoutArrowsBold textdata={"Handy Hint"} />
            <DefaultTextWithoutArrowsItallic textdata={"This is also good if you are having trouble sleeping - see Sleep Chalk in the Activities section"} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Sand+Alphabet.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [],
    emotion: [CardFilterEmotionType.Anxious]
}

