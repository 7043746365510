import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { ScrollView, StyleSheet, View } from 'react-native';
import { GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables, ListTeacherClassesByTeacherIDQueryVariables } from '../../common/API';
import { getTeacherByCognitoUsername } from '../../common/graphql/queries';
import { ListTeacherClassesAndClassDetailsByTeacherIDQuery, listTeacherClassesAndClassDetailsByTeacherID } from '../custom-graphql/queries/list-teacher-classes-and-class-details-by-teacher-id';
import { rvCurrentUser } from '../login/login-state';
import { filterAndSortActiveTeacherClassTeacherClasses } from './filter-and-sort-active-teacher-classes';
import { TeacherClassSelectorItem } from './teacher-class-selector-item';
import { isDefined } from '../../common/is-defined';

export const TeacherClassSelector = () => {
    const user = useReactiveVar(rvCurrentUser);

    const { data: techerResponse } = useQuery<GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables>(gql`${getTeacherByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })

    const currentTeacher = techerResponse?.getTeacherByCognitoUsername?.items[0]

    const { data } = useQuery<ListTeacherClassesAndClassDetailsByTeacherIDQuery, ListTeacherClassesByTeacherIDQueryVariables>(gql`${listTeacherClassesAndClassDetailsByTeacherID}`, { variables: { teacherID: currentTeacher?.id || "" }, skip: !currentTeacher, })

    const teacherClasses = filterAndSortActiveTeacherClassTeacherClasses(data).filter(isDefined)

    return (
        <View style={styles.container}>
            <ScrollView style={styles.classSelector} showsVerticalScrollIndicator={true} persistentScrollbar={true}>
                {teacherClasses?.map(m => <TeacherClassSelectorItem key={m.id} classID={m.class.id} />)}
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        minHeight: 50,
        maxHeight: 250,
        width: '100%',
        alignItems: 'center',
    },
    classSelector: {
        flexDirection: 'column',
    },
})
