import { useEffect, useState } from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { Audio } from 'expo-av';
import { Entypo } from '@expo/vector-icons';
import { DecidaColors } from '../../common/decida-colors';
import { DefaultText } from './default-text';
import { DefaultButton } from './default-button';
import { useReactiveVar } from '@apollo/client';
import { rvCard } from '../switches/common/card-color-scheme';
import { AVPlaybackSource } from 'expo-av/build/AV.types';

type NewType = AVPlaybackSource;

interface Props {
    audioFile: NewType
    audioTitle?: string

}


export const AudioHandlerOutlined = ({ audioFile, audioTitle }: Props) => {

    const [sound, setSound] = useState<Audio.Sound>();
    const [playState, setPlayState] = useState(false);
    const emotionColour = useReactiveVar(rvCard)?.colorScheme
    async function playSound() {
        const sound = await Audio.Sound.createAsync(
            audioFile
        );
        setSound(sound.sound);
        setPlayState(true)
        await sound.sound.playAsync();
    }

    async function stopSound() {
        setPlayState(false)
        await sound?.unloadAsync();
    }

    useEffect(() => {
        return sound
            ? () => {
                console.log('Unloading Sound');
                sound.unloadAsync();
            }
            : undefined;
    }, [sound]);


    return (
        <View>
            <DefaultButton onPress={playState ? stopSound : playSound} style={[styles.containerController, styles.transparentContainer]}>

                {playState ?
                    <DefaultText style={styles.playButtonStyle} >
                        Stop audio
                    </DefaultText>
                    :
                    <DefaultText style={styles.playButtonStyle} >
                        {audioTitle ? audioTitle : "Play audio"}
                    </DefaultText>
                }
                {
                    playState ? <Entypo name="controller-stop" size={40} color={DecidaColors.Sad} /> : <Entypo name="controller-play" size={45} color={DecidaColors.Sad} />
                }
            </DefaultButton>
        </View>
    )
}

const styles = StyleSheet.create({
    containerController: {
        flexDirection: 'row',
        alignItems: 'center',

    } as ViewStyle,
    transparentContainer: {
        backgroundColor: 'transparent', 
        borderWidth: 2, 
        borderRadius: 20, 
        borderColor: DecidaColors.Sad 

    } as ViewStyle,

    playButtonStyle: {
        color: DecidaColors.Sad,
        fontWeight: 'bold'
    }
})
