
export const listTeacherClassesAndClassDetailsByTeacherID = `query ListTeacherClassesByTeacherID(
  $teacherID: ID!
  $classID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeacherClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeacherClassesByTeacherID(
    teacherID: $teacherID
    classID: $classID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      teacherID
      classID
      createdAt
      updatedAt
      class {
        id
        name
        goMode
        archived
        _deleted
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}`

export type ListTeacherClassesAndClassDetailsByTeacherIDQuery = {
    listTeacherClassesByTeacherID?: {
        __typename: "ModelTeacherClassConnection",
        items: Array<{
            __typename: "TeacherClass",
            id: string,
            teacherID: string,
            classID: string,
            createdAt?: string | null,
            updatedAt?: string | null,
            class: {
                id: string,
                name: string,
                goMode?: boolean | null,
                archived?: boolean | null,
                _version: number,
                _deleted?: boolean | null,
            }
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
        } | null>,
        nextToken?: string | null,
        startedAt?: number | null,
    } | null,
};