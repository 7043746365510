import { Videos } from "/common/API"
import { CardCategoryType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from "../switches/common/card-template"
import { CardVideoAgeGroupTypes, CardVideoFilterEmotionType, CardVideoTimeTypes } from "../switches/tools/cards/video-library"
import { rvFilterActivitySheetsModal, rvFilterExploreSwitchesModal, rvFilterSwitchesData, rvFilterVideoLibraryModal } from "./common-state"
import { SwitchCardFilterCategoryType, SwitchCardFilterMainType, SwitchCardFilterType } from "./switch-card-filter"
import { WorksheetType } from "./worksheets-imports"

export type CombinedFilters = { value: SwitchCardFilterMainType | string[] | SwitchCardFilterCategoryType, filterType: (keyof Pick<CardTemplate, "type" | "place" | "people" | "time" | "action" | "level" | "category" | "emotion"> | keyof Pick<Videos, "time" | "level" | "emotion" | "ageGroup"> | keyof Pick<WorksheetType, "category" | "emotion" | "thoughtOrAction" | "time">) }

const isAnyFilterApplied = (filters: SwitchCardFilterType) => {
    let isFilterApplied = false

    for (const key in filters) {
        const group = filters[key as keyof SwitchCardFilterType]
        if (group.length) {
            isFilterApplied = true
            break
        }
        isFilterApplied = false
    }

    return isFilterApplied
}

const isSubFilterAppliedInCard = (
    card: CardTemplate | Videos | WorksheetType,
    subFilters: SwitchCardFilterType[keyof SwitchCardFilterType],
    filterCardGroupType: CombinedFilters["filterType"]
) => {
    const currentCard: any = { ...card }
    return subFilters && subFilters.some((subFilter) => currentCard[filterCardGroupType]?.includes(subFilter))
}


const isSubFilterAppliedInVideo = (
    card: Videos,
    subFilters: SwitchCardFilterType[keyof SwitchCardFilterType],
    filterCardGroupType: keyof Pick<Videos, "time" | "level" | "emotion" | "ageGroup">
) => {
    const currentCard: any = { ...card }
    return subFilters && subFilters.some((subFilter) => currentCard[filterCardGroupType]?.includes(subFilter))
}

const getFilteredSwitches = (switches: CardTemplate[], filters: SwitchCardFilterType) => {
    let currentSwitches = [...switches]
    const anyFilter = isAnyFilterApplied(filters)
    const { typeFilter, categoryFilter, timeFilter, emotionFilter, levelFilter, placeFilter, peopleFilter, } = getFilterSubs(filters)

    const combinedFilter: CombinedFilters[] = [
        {
            value: typeFilter,
            filterType: "type"
        },
        {
            value: categoryFilter,
            filterType: "category"
        },
        {
            value: timeFilter,
            filterType: "time"
        },
        {
            value: emotionFilter,
            filterType: 'emotion'
        },
        {
            value: levelFilter,
            filterType: 'level'
        },
        {
            value: placeFilter,
            filterType: 'place'
        },
        {
            value: peopleFilter,
            filterType: 'people'
        }
    ]

    if (anyFilter) {
        currentSwitches = generateFilterResultByAllFilter(currentSwitches, combinedFilter) as CardTemplate[]
    }

    return currentSwitches
}

const getFilteredVideos = (videos: Videos[], filters: SwitchCardFilterType) => {
    let currentVideos = [...videos]
    const anyFilter = isAnyFilterApplied(filters)
    const { categoryFilter, timeFilter, emotionFilter, levelFilter } = getFilterSubs(filters)

    const combinedFilter: CombinedFilters[] = [
        {
            value: categoryFilter,
            filterType: "ageGroup"
        },
        {
            value: timeFilter,
            filterType: "time"
        },
        {
            value: emotionFilter,
            filterType: 'emotion'
        },
        {
            value: levelFilter,
            filterType: 'level'
        },
    ]

    if (anyFilter) {
        return currentVideos = generateFilterResultByAllFilter(currentVideos, combinedFilter) as Videos[]
    }

    return currentVideos
}

const getFilteredActivitySheets = (worksheets: WorksheetType[], filters: SwitchCardFilterType) => {
    let currentWorksheets = [...worksheets]
    const anyFilter = isAnyFilterApplied(filters)
    const { categoryFilter, timeFilter, emotionFilter, levelFilter, toughtOrActionFilter} = getFilterSubs(filters)

    const combinedFilter: CombinedFilters[] = [
        {
            value: categoryFilter,
            filterType: "category"
        },
        {
            value: timeFilter,
            filterType: "time"
        },
        {
            value: emotionFilter,
            filterType: 'emotion'
        },
        {
            value: toughtOrActionFilter,
            filterType: 'thoughtOrAction'
        },
    ]

    if (anyFilter) {
        return currentWorksheets = generateFilterResultByAllFilter(currentWorksheets, combinedFilter) as WorksheetType[]
    }

    return currentWorksheets
}

const generateFilterResultByAllFilter = (switches: (CardTemplate | Videos | WorksheetType)[], combinedFilter: CombinedFilters[]) => {
    let filteredResults = [...switches]
    combinedFilter.forEach((f) => {
        filteredResults = filteredResults.filter((card) => {
            if (f.value.length && isSubFilterAppliedInCard(card, f.value, f.filterType)) {
                return true
            }

            if (!f.value.length) {
                return true
            }

            return false
        })
    })

    return filteredResults
}

const getFilterSubs = (filters: SwitchCardFilterType) => {
    const typeFilter = filters.main as SwitchCardFilterType[keyof SwitchCardFilterType]
    const timeFilter = filters.time as SwitchCardFilterType[keyof SwitchCardFilterType]
    const placeFilter = filters.place as SwitchCardFilterType[keyof SwitchCardFilterType]
    const peopleFilter = filters.people as SwitchCardFilterType[keyof SwitchCardFilterType]
    const levelFilter = filters.level as SwitchCardFilterType[keyof SwitchCardFilterType]
    const emotionFilter = filters.emotion as SwitchCardFilterType[keyof SwitchCardFilterType]
    const categoryFilter = filters.category as SwitchCardFilterType[keyof SwitchCardFilterType]
    const toughtOrActionFilter = filters.thoughtOrAction as SwitchCardFilterType[keyof SwitchCardFilterType]

    return {
        typeFilter,
        timeFilter,
        placeFilter,
        peopleFilter,
        levelFilter,
        emotionFilter,
        categoryFilter,
        toughtOrActionFilter
    }
}

const showExploreFilter = () => rvFilterExploreSwitchesModal(true)

const hideExploreFilter = () => rvFilterExploreSwitchesModal(false)

const showVideoFilter = () => rvFilterVideoLibraryModal(true)

const hideVideoFilter = () => rvFilterVideoLibraryModal(false)

const showActivitySheetsFilter = () => rvFilterActivitySheetsModal(true)

const hideActivitySheetsFilter = () => rvFilterActivitySheetsModal(false)

const clearFilter = () => {
    rvFilterSwitchesData({
        main: [] as SwitchCardFilterType["main"],
        time: [] as SwitchCardFilterType["time"],
        people: [] as SwitchCardFilterType["people"],
        level: [] as SwitchCardFilterType["level"],
        place: [] as SwitchCardFilterType["place"],
        emotion: [] as SwitchCardFilterType["emotion"],
        category: [] as SwitchCardFilterType["category"],
        thoughtOrAction: [] as SwitchCardFilterType["thoughtOrAction"]
    })
}

const selectAllTypeFilters = (filters: SwitchCardFilterType) => {
    const allCardTypes = Object.values(CardType)
    const { isAllTypeChecked } = isAllFilterGroupChecked(filters)

    if (isAllTypeChecked) {
        rvFilterSwitchesData({
            ...filters,
            main: []
        })
        return
    }

    rvFilterSwitchesData({
        ...filters,
        main: allCardTypes
    })

}

const selectAllParameterFilters = (filters: SwitchCardFilterType, filterType: "video" | "explore" | "activity_sheets") => {

    const { isAllParameterExploreChecked, isAllParameterVideoChecked, allParameterExploreFilters, allParameterVideoFilters, allCardVideoDuration, allCardTimeTypes, allCardLevelTypes, allCardPersonTypes, allCardPlaceTypes, allCardVideoEmotion } = isAllFilterGroupChecked(filters)
    const isVideoFilter = filterType === 'video'
    const isAllChecked = isVideoFilter ? isAllParameterVideoChecked : isAllParameterExploreChecked
    const allCategoriesFilterValue = isVideoFilter ? allParameterVideoFilters : allParameterExploreFilters

    if (isAllChecked) {
        rvFilterSwitchesData({
            ...filters,
            time: [],
            people: [],
            place: [],
            level: [],
            emotion: [],
        })
        return
    }

    rvFilterSwitchesData({
        ...filters,
        time: isVideoFilter ? allCardVideoDuration : allCardTimeTypes,
        level: allCardLevelTypes,
        people: isVideoFilter ? [] : allCardPersonTypes,
        place: isVideoFilter ? [] : allCardPlaceTypes,
        emotion: isVideoFilter ? allCardVideoEmotion : []
    })

}

const selectAllCategoryFilters = (filters: SwitchCardFilterType, filterType: "video" | "explore") => {
    const { isAllCategoryExploreChecked, isAllCategoryVideoChecked, allCategoriesExploreFilters, allCategoriesVideoFilters } = isAllFilterGroupChecked(filters)
    const isAllChecked = filterType === 'video' ? isAllCategoryVideoChecked : isAllCategoryExploreChecked
    const allCategoriesFilterValue = filterType === 'video' ? allCategoriesVideoFilters : allCategoriesExploreFilters

    if (isAllChecked) {
        rvFilterSwitchesData({
            ...filters,
            category: []
        })
        return
    }

    rvFilterSwitchesData({
        ...filters,
        category: allCategoriesFilterValue
    })
}

const selectAllEmotionFilters = (filters: SwitchCardFilterType, filterType: "video" | "explore") => {
    const { isAllExploreEmotionChecked, isAllVideoEmotionChecked, allCardVideoEmotion, allCardEmotionTypes } = isAllFilterGroupChecked(filters)
    const isAllChecked = filterType === 'video' ? isAllVideoEmotionChecked : isAllExploreEmotionChecked
    const allEmotionFilterValue = filterType === 'video' ? allCardVideoEmotion : allCardEmotionTypes

    if (isAllChecked) {
        rvFilterSwitchesData({
            ...filters,
            emotion: []
        })
        return
    }

    rvFilterSwitchesData({
        ...filters,
        emotion: allEmotionFilterValue
    })
}

const isAllFilterGroupChecked = (filters: SwitchCardFilterType) => {
    const allCardTypes = Object.values(CardType)
    const isAllTypeChecked = filters.main.length && filters.main.length === allCardTypes.length
    const isActivityFilterApplied = filters.main.includes(CardType.Activity)
    const isToolAndVideoFilterApplied = filters.main.includes(CardType.Tool) || filters.main.includes(CardType.Video)
    const isSwitchFilterApplied = filters.main.includes(CardType.Switch)

    const allCardPlaceTypes = Object.values(CardPlaceType)
    const allCardPersonTypes = Object.values(CardPeopleType)
    const allCardTimeTypes = Object.values(CardTimeType)
    const allCardLevelTypes = Object.values(CardIntensitylevelType)
    const allCardEmotionTypes = Object.values(CardFilterEmotionType)
    const allCardVideoDuration = Object.values(CardVideoTimeTypes)
    const allCardVideoEmotion = Object.values(CardVideoFilterEmotionType)
    const allParameterExploreFilters = [...allCardPlaceTypes, ...allCardPersonTypes, ...allCardTimeTypes, ...allCardLevelTypes]
    const currentParameterExploreFiltersLength = (Number(filters?.place?.length) + Number(filters?.people?.length) + Number(filters?.time?.length) + Number(filters.level?.length))
    const allParameterVideoFilters = [...allCardVideoDuration, ...allCardVideoEmotion, ...allCardLevelTypes]
    const currentParameterVideoFiltersLength = (Number(filters?.time?.length) + Number(filters?.emotion?.length) + Number(filters?.level?.length))
    const isAllParameterExploreChecked = currentParameterExploreFiltersLength === allParameterExploreFilters.length
    const isAllParameterVideoChecked = currentParameterVideoFiltersLength === allParameterVideoFilters.length
    const isAllExploreEmotionChecked = Number(filters?.emotion.length) === allCardEmotionTypes.length
    const isAllVideoEmotionChecked = Number(filters?.emotion.length) === allCardVideoEmotion.length


    const allCardCategoryTypes = isActivityFilterApplied ? Object.values(CardCategoryType) : []
    const allCardVideoAgeGroup = Object.values(CardVideoAgeGroupTypes)
    const allCardFilterEmotionTypes = isSwitchFilterApplied ? Object.values(CardFilterEmotionType) : []
    const allCategoriesExploreFilters = [...allCardCategoryTypes, ...allCardFilterEmotionTypes]
    const allCategoriesVideoFilters = [...allCardVideoAgeGroup]
    const isAllCategoryExploreChecked = filters.category.length && filters.category.length === allCategoriesExploreFilters.length
    const isAllCategoryVideoChecked = filters.category.length && filters.category.length === allCategoriesVideoFilters.length

    return {
        isAllTypeChecked,
        isAllParameterVideoChecked,
        isAllParameterExploreChecked,
        isAllCategoryExploreChecked,
        isAllCategoryVideoChecked,
        isAllExploreEmotionChecked,
        isAllVideoEmotionChecked,
        allParameterVideoFilters,
        allParameterExploreFilters,
        allCardTypes,
        allCategoriesVideoFilters,
        allCategoriesExploreFilters,
        allCardPersonTypes,
        allCardTimeTypes,
        allCardLevelTypes,
        allCardVideoDuration,
        allCardVideoEmotion,
        allCardPlaceTypes,
        allCardEmotionTypes
    }
}

export const SwitchCardFilterMethods = {
    isAnyFilterApplied,
    getFilteredSwitches,
    getFilteredVideos,
    showExploreFilter,
    hideExploreFilter,
    clearFilter,
    selectAllTypeFilters,
    selectAllParameterFilters,
    selectAllCategoryFilters,
    isAllFilterGroupChecked,
    showVideoFilter,
    hideVideoFilter,
    selectAllEmotionFilters,
    showActivitySheetsFilter,
    hideActivitySheetsFilter,
    getFilteredActivitySheets
}
