import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { MilitarySleepHabitIcon } from '../../icons/military-sleep-habit-icon'
import { MilitarySleepHabitIconInverted } from '../../icons/military-sleep-habit-icon-inverted'


export const SwitchesActivitiesSecretsForSleepMilitarySleepingHabbit: CardTemplate = {
    cardId: "bdfa49e2-006c-4dd9-b60a-c1ab68cd924e",
    title: 'Military sleep habit',
    Header: MilitarySleepHabitIconInverted,
    Icon: MilitarySleepHabitIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Learning to fall asleep quickly."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Laying down comfortably."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Calm your body and systematically relax, shutting down each part of your body from head to toe."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Start with relaxing the muscles in your forehead "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Relax your eyes, cheeks, and jaw."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Focus on your breathing."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Untense your shoulders, drop them as low as you can an keep your arms loose at your side, including your hands and fingers."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Imagine a warm sensation going from your head all the way down to your fingertips."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Now take a deep breath and slowly exhale, relaxing your chest, stomach and legs."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Imagine this warm sensation going down from your heart all the way to your toes."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Clear your mind of any stressors by thinking of two scenarios:"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='You’re lying in a canoe on a calm lake with nothing but a clear blue sky above you.' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='You’re lying in a black velvet hammock in a pitch black room.' />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"At any time when you start thinking of anything else or you start getting distracted repeat these words for ten seconds, “Don’t think. Don’t think. Don’t think.”"} />

        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Practice every night for six weeks to master this technique.' />
        </>

    ),
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.SECRETS_FOR_SLEEP,
    whyDoesItWorkText: 'According to Sharon Ackerman’s book titled, “Relax and Win: Championship Performance” the United States Navy Pre-Flight School created a routine to help pilots fall asleep in 2 minutes or less. It took pilots about 6 weeks of practice, but it worked — even after drinking coffee and with loud  noises in the background.',
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.SECRETS_FOR_SLEEP],
    action: [CardActionType.Action],
    level: []
}

