import { gql, useMutation } from '@apollo/client'
import { Pressable, StyleSheet, View } from 'react-native'
import { Class, UpdateClassMutation, UpdateClassMutationVariables } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { updateClass } from '../../common/graphql/mutations'
import { rvSidebarClassID } from '../school-admin/school-admin-state'
import { CommonStyles } from './const'
import { DefaultText } from './default-text'
import { CloseIcon } from './svg-files/close-icon'
import { showAlert, showGenericErrorAlert } from './universal-alert'

const RemoveClassButton = ({ claz }: { claz: Class }) => {
    const [updateClassMutation] = useMutation<UpdateClassMutation, UpdateClassMutationVariables>(gql`${updateClass}`,)

    const toggleRemovePrompt = () => {
        showAlert({
            title: `Are you sure you want to remove ${claz.name} from this group?`,
            message: 'This action cannot be undone.',
            leftButtonText: 'Cancel',
            rightButtonText: 'Delete',
            onRightButtonPress: handleRemoveClass
        })
    }
    const handleRemoveClass = () => {
        try {
            updateClassMutation({
                variables: {
                    input: {
                        id: claz.id,
                        archived: true,
                        _version: claz._version
                    }
                }
            })
            rvSidebarClassID("")
        } catch (error) {
            showGenericErrorAlert()
        }
    }

    return (
        <Pressable onPress={toggleRemovePrompt} style={[CommonStyles.innerShadow, CommonStyles.buttonRounded, styles.button]}>
            <View style={CommonStyles.buttonIcon}>
                <CloseIcon />
            </View>
            <DefaultText style={styles.text}>Remove class</DefaultText>
        </Pressable>
    )
}
export default RemoveClassButton


const styles = StyleSheet.create({
    button: {
        flexDirection: "row",
        marginVertical: 20,
        justifyContent: 'space-evenly',
        alignItems: 'center',
        alignSelf: 'center',
        maxWidth: 400,
        backgroundColor: DecidaColors.Angry
    },
    text: {
        fontSize: 16,
        color: DecidaColors.White
    }

}) 