import { View } from 'react-native'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { HighFiveEliminationIcon } from '../../icons/high-five-elimination-icon'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { HighFiveEliminationIconInverted } from '../../icons/high-five-elimination-icon-inverted'

export const SwitchesActivitiesRemoteClassesHighFiveElimination: CardTemplate = {
    cardId: "d82f79c4-203a-4623-999c-4ee8812af152",
    title: 'High five elimination',
    Header: HighFiveEliminationIconInverted,
    Icon: HighFiveEliminationIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Gaining attention and having some fun."} />
            <DefaulttextWithRightArrow textdata={"Ensure everyone is in “gallery view” on your VC platform"} />
            <DefaultTextWithoutArrowsItallic textdata={"This game works because VC platforms like  Zoom scramble the order of the boxes. Everyone will have their own view of who is to the right or left. Make sure you can see at least one person to your right and one person to your left ( you may need to designate up/down as L/R if on mobile vertical view)."} />
            <DefaulttextWithRightArrow textdata={"Have everyone change video setting to “Hide non-video participants”"} />
            <DefaulttextWithRightArrow textdata={"Practice turning on/off video and watching videos appear/disappear"} />
            <DefaulttextWithRightArrow textdata={"Demo high five (both hands) to the right or left inside your own frame."} />
            <DefaulttextWithRightArrow textdata={"Count 321 GO and everyone makes a choice. Students who successfully high-five another person (in their view), stay in the game."} />
            <DefaulttextWithRightArrow textdata={"Last pair in the game win"} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>


            </View>
        </>
    ),
    colorScheme: CardColorScheme.FOR_REMOTE_CLASSES,
    whyDoesItWorkText: '',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/High+Five+elimination.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Short, CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.FOR_REMOTE_CLASSES],
    action: [CardActionType.Action],
    level: []
}

