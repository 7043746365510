import React, { PropsWithChildren } from 'react';
import { Pressable, ScrollView, StyleSheet, View } from 'react-native';
import { DecidaColors } from '../../common/decida-colors';
import { CommonStyles } from '../common/const';
import { DefaultText } from '../common/default-text';
import { ResetPasswordAllIcon } from '../common/svg-files/reset-password-all-icon';
import { StaffWellbeingStatsLegends } from '../common/staff-wellbeing-legends';
import { useResponsive } from '../common/use-responsive';

type Props = {
    startCheckIn: () => void
}
const WellbeingWrapper: React.FC<PropsWithChildren<Props>> = ({ startCheckIn, children }) => {
    const { isLargeScreen } = useResponsive();

    return (
        <View style={[styles.container, { flexDirection: isLargeScreen ? 'row' : "column" }]}>
            <View style={{ backgroundColor: isLargeScreen ? DecidaColors.GrayBackground : DecidaColors.White, padding: 50 }}>
                <Pressable onPress={startCheckIn} style={[CommonStyles.innerShadow, CommonStyles.buttonRounded, styles.button]}>
                    <View style={CommonStyles.buttonIcon}>
                        <ResetPasswordAllIcon />
                    </View>
                    <DefaultText style={{ fontSize: 18, color: DecidaColors.AppleSystemGray2Dark }}>Check in</DefaultText>
                </Pressable>
            </View>
            <ScrollView style={{ flex: 1, width: '100%' }} contentContainerStyle={styles.scrollViewContentContainer}>
                <StaffWellbeingStatsLegends showAllCheckInLegend />
                {children}
            </ScrollView>
        </View>
    );
};

export default WellbeingWrapper;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollViewContentContainer: {
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    button: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20,
        width: 180,
        alignSelf: 'center',
        backgroundColor: DecidaColors.White,
    },
});
