import { StyleSheet } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'

interface Props {
    children: string
}
export const PasswordErrorText = ({ children }: Props) =>
    <DefaultText textProps={{ testID: 'PasswordErrorText' }} style={styles.hintText}>{children}</DefaultText>

const styles = StyleSheet.create({
    hintText: {
        color: DecidaColors.Red,
        width: 330,
    },
})