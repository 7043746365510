import Svg, { Path } from 'react-native-svg'
import { useReactiveVar } from '@apollo/client'
import { rvCard } from './card-color-scheme'

export const WhyDoesThisWorkIcon = () => {
    const color = useReactiveVar(rvCard)?.colorScheme

    return (
        <Svg viewBox="0 0 162.894 162.894">
            <Path d="M208.781,698.353a44.907,44.907,0,0,1-10.908,11.821c-5.819,4.454-7.571,6.738-8.1,7.707a11.8,11.8,0,0,0-1.068,3.627c-.121.708-.212,1.471-.29,2.291l-.46,4.912-17.683.044.226-5.663a34.726,34.726,0,0,1,.3-3.472,24.391,24.391,0,0,1,4.094-10.648c2-2.849,5.875-6.447,11.857-11,4.418-3.372,6.143-5.3,6.814-6.32a7.425,7.425,0,0,0,1.26-4.172,9.9,9.9,0,0,0-3.79-8.23c-2.666-2.085-6.371-3.138-10.994-3.138a16.258,16.258,0,0,0-10.288,3.216,16.61,16.61,0,0,0-5.875,8.986l-1.195,4.51-17.308-1.873,1.224-5.86c1.591-7.636,5.182-13.526,10.682-17.513,7.12-5.169,14.762-7.792,22.716-7.792a36.608,36.608,0,0,1,22.639,7.113,24.22,24.22,0,0,1,9.68,19.917,21.253,21.253,0,0,1-3.535,11.538Zm-29.39,59.621a10.973,10.973,0,1,1,10.974-10.965A10.982,10.982,0,0,1,179.391,757.974Zm0-129.863a81.447,81.447,0,1,0,81.447,81.447A81.449,81.449,0,0,0,179.391,628.111Z" transform="translate(-97.944 -628.111)" fill={color} />
        </Svg>
    )
}