import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { LikePageIcon } from '../../explore/src/like-page-icon'
import { LikePageIconInverted } from '../../explore/src/like-page-icon-inverted'
import { LikePageImage1 } from './src/like-page-image1'
import { LikepageLessonplanImage1 } from './src/like-page-lessonplan-image1'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'

export const SwitchesEmotionsSadLikePage: CardTemplate = {
    cardId: "d37e7c70-1f35-47a1-8fd8-a010dc63bcbe",
    title: 'Like page',
    Header: LikePageIconInverted,
    Icon: LikePageIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <LikePageImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Tackling feelings of worthlessness and negative self talk."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"List down all your strengths (all the things you are good at and all the things that make you a good person). "} />
                <DefaultTextWithDownArrow textdata={"Add to the list things that others would say are your strengths. "} />
                <DefaultTextWithDownArrow textdata={"Write your name or draw a picture of yourself "} />
                <DefaultTextWithDownArrow textdata={"Write each of your positive traits as sentences starting with “I …” for example, I am a good listener, to fill the page."} />
                <DefaultTextWithDownArrow textdata={"Put the completed poster in your room to reinforce positive beliefs about yourself."} />
                <DefaultTextWithoutArrows textdata={"Read through these sentences, also known as positive affirmations, preferably aloud at least two times. "} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: 'We know more about ourselves than anyone else, and so also see all our negative aspects more than anyone else, which can lead to an inner voice that accentuates these negatives. This inner voice is born out of previous experiences and attitudes from which we begin to define ourselves in blanket terms, such as “I’m too loud”, “I’m annoying” or “I’m not a people person”.  The Like Page activity helps normalise the positive aspects others recognise in us, building capability, self-confidence and resilience.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 10, color: DecidaColors.Gray }}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice providing and receiving positive feedback.   </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Build confidence through positive affirmations.  </DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Like Page switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of positivity and being respectful within the class. Refer to school values and expectations about behaviour.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that the class will engage in an activity to celebrate each other's strengths and positive traits, promoting a supportive atmosphere.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap what strengths are (refer to Stars & Wishes switch lesson plan for more detail and resources related to strengths if required).</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Small Group Strengths Discussion (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Separate the class into small groups, ideally those that collaborate well or are friends.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to take turns talking about each individual in their group. Task each group to discuss the individual's strengths, positive traits, abilities, appearance, and ways of thinking or acting.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ The person being discussed should listen quietly and write a list of all the positive things their peers say about them.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Creating Personalised Affirmations (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask each student to refer to the My Affirmations Worksheets.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct them to transcribe the positive feedback they received from their peers as statements starting with "I am..." (e.g., "I am a good listener. I am a good friend.").</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students review the 58 Affirmations list and select any additional affirmations they would like to add to their "My Affirmations" list.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Debrief and Discussion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief the activity by highlighting the power of positive feedback and affirmations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how this tool can be powerful when feeling sad or anxious, encouraging students to keep the sheet somewhere safe for future reference.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their insights and reflections on the activity.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 4: Reflecting on the Switch (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to read through the Like Page switch and reflect on the activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to share any insights or realisations they had during the activity and discuss how affirmations can contribute to a positive mindset.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points about the importance of recognising and celebrating each other's strengths.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the idea that positive affirmations can be a powerful tool for boosting self esteem and managing emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to continue practicing positive affirmations and supporting each other.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Paper/notebook </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='My Affirmations Worksheets' link={Worksheets.myAffirmations.uri} /> </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Positive Daily Affirmations: Is There Science Behind It? -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://positivepsychology.com/daily-affirmations/' link='https://positivepsychology.com/daily-affirmations/' />
                    </>}

            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='If you aren’t sure what your strengths are, ask someone you trust to help.' />
            <DefaulTextWithTipsIcon textdata='You can use these positive affirmations as a regular mantra to remind yourself how awesome you truly are.' />

        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Like+Page.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Sad]
}
