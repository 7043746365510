import { View, StyleSheet, StyleProp, TextStyle } from "react-native";
import { DefaultText } from "../../common/default-text";
import { rvCard } from "./card-color-scheme";
import { useReactiveVar } from "@apollo/client";
import { Entypo } from '@expo/vector-icons';

interface Props {
    textdata: string | React.ReactNode
    style?: StyleProp<TextStyle>

}


export const DefaulttextWithRightArrow = ({ textdata }: Props) => {
    const color = useReactiveVar(rvCard)?.colorScheme
    return (
        <View style={styles.content}>
            <View style={styles.containingView}>
                <View style={styles.icon}>
                    <View style={styles.extraTipsIcon}>
                        <Entypo name="arrow-bold-right" size={24} color={color} />
                    </View>
                </View>
                <DefaultText style={styles.text}>
                    {textdata}
                </DefaultText>
            </View>

        </View>
    )

}



const styles = StyleSheet.create({

    icon: {
    },
    content: {
        justifyContent: 'center',
        marginVertical: 15,

    },
    text: {
        textAlign: 'auto'

    },
    extraTipsIcon: {


    },
    containingView: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    }
})

