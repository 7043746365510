import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { SyncClapsIcon } from '../../icons/sync-claps-icon'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { SyncClapsIconInverted } from '../../icons/sync-claps-icon-inverted'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'

export const SwitchesActivitiesEnergizersSyncClaps: CardTemplate = {
    cardId: "ec3529a6-64c2-43d3-a111-7d9745fc251e",
    title: 'Sync claps',
    Header: SyncClapsIconInverted,
    Icon: SyncClapsIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Getting people to focus on each other, and become attuned to subtle facial and body movements."} />
            <DefaulttextWithRightArrow textdata={"The class stands in a circle."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Round one:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"One person starts (person A). They turn to the person next to them (person B) and the two clap at the same time while making eye contact, as synced as possible."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The clap continues like this around the circle. The group should try to pass the clap more and more quickly with as many perfectly synchronized claps as possible."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Round two:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"When the group has mastered the above, introduce the double clap."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"When a pair claps twice, the direction of the clap reverses. This presents a challenge, as pairs must non-verbally agree whether to clap once or twice."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The group must be highly focused and attentive to each other for the clap to continue smoothly around the circle in this step."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Round three:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Split the class into two circles."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"See how many times they can clap as a group completely in sync. Take turns between the circles."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Group with the most synced claps in a row wins."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"Step 3 is very challenging for unfocused, stressed or very new groups. If the group struggles to pass the clap, consider using the opportunity to debrief by asking the group: “why was it such a challenge to pass the clap when the task became more complex? What would you need as a group to master it?”"} />
            <DefaulTextWithTipsIcon textdata={`Another alternative is to consider two people "out" when they don't sync their claps, with the last one or two people left 'in' the winners.`} />
        </>

    ),
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.ENERGIZERS,
    whyDoesItWorkText: '',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Sync+Claps.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Short, CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.ENERGIZERS],
    action: [CardActionType.Action],
    level: []
}

