import { ScrollView, StyleSheet, View } from 'react-native';
import { Student, Teacher } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { DefaultText } from '../common/default-text';
import { SearchLoopIcon } from '../common/svg-files/search-loop-icon';
import SchoolAdminSidebarSearchResultItem, { ResultItemType } from './school-admin-sidebar-search-result-item';

type Props = {
    data: Teacher[] | Student[]
    type: ResultItemType
}

const SchoolAdminSidebarSearchTeacherOrStudentResults = ({ data, type }: Props) => {

    return (
        <>
            <ScrollView style={styles.teacherScroll} contentContainerStyle={styles.internalTeacherScroll}>
                {data?.length <= 0 ?
                    <View style={styles.noResults}>
                        <View style={styles.searchIcon}>
                            <SearchLoopIcon />
                        </View>
                        <DefaultText style={styles.noSearchResultText}>Start typing to see results...</DefaultText>
                        <DefaultText style={styles.noSearchResultText}>(only top 10 results shown)</DefaultText>
                    </View>
                    : data?.map((user) => {
                        return (
                            <SchoolAdminSidebarSearchResultItem key={user.id} user={user} type={type} />
                        )
                    })
                }
            </ScrollView>
        </>
    )
}
export default SchoolAdminSidebarSearchTeacherOrStudentResults
const styles = StyleSheet.create({
    searchIcon: {
        width: 40,
        height: 35,
    },
    teacherScroll: {
        flex: 1,
        padding: 15,
        borderRadius: 8,
    },
    noResults: {
        justifyContent: 'center',
        alignItems: 'center',

    },
    internalTeacherScroll: {
        flex: 1,
    },
    noSearchResultText: {
        fontSize: 14,
        color: DecidaColors.AppleSystemGray4Light
    },
})