import { StyleSheet } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'


export const SwitchesLessonPlansConflictResolution: CardTemplate = {
    cardId: "830a5608-424a-4e07-96d3-0c09d35f65ba",
    title: 'Conflict resolution',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to resolve conflicts constructively by understanding emotions, actively listening, and finding win-win solutions, as well as enhance their emotional expression skills.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by engaging the students in a group discussion about conflicts and their impact on individuals and relationships.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask them to share examples of conflicts they have experienced and how they felt during those situations. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Emotions in Conflict (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the role of emotions in conflicts and the importance of understanding and managing them effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain how different emotions can influence our behaviours and decision-making during conflicts.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students provide examples of emotions that may arise during conflicts, such as anger, frustration, or sadness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss different strategies for resolving conflict in the face of those emotions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Role-play: Conflict Resolution (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide the class into pairs or small groups.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide each group with a role-play scenario card where conflict resolution is needed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>i. Scenario: Sharing a Game Console</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Pretend you and your sibling both want to play with the game console at the same time.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice resolving the conflict by finding a fair solution, such as taking turns or playing a multiplayer game together.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>ii. Scenario: Disagreement Over a Group Project Idea</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Imagine you and your classmates have different ideas for a group project.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice resolving the conflict by listening to everyone's ideas, finding common ground, and coming up with a compromise that everyone agrees on.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>iii. Scenario: Handling a Misunderstanding with a Friend</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Pretend your friend misunderstood something you said and got upset.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice resolving the conflict by calmly explaining your intentions, listening to their perspective, and apologising if necessary.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>iv. Scenario: Dispute Over Playground Equipment</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Imagine you and another classmate both want to use the same piece of playground equipment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice resolving the conflict by discussing your needs, finding a compromise, and suggesting alternative activities to enjoy together.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>v. Scenario: Clash of Opinions in a Classroom Discussion</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Pretend there's a disagreement between you and a classmate during a classroom discussion.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice resolving the conflict by respectfully expressing your viewpoints, listening to each other, and finding common ground or agreeing to disagree.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>vi. Scenario: Dealing with Gossip or Rumours</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Imagine someone spreads gossip or rumours about you or a friend.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice resolving the conflict by confronting the person calmly, explaining the impact of their actions, and encouraging them to stop spreading rumours.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>vii. Scenario: Managing Scheduling Conflicts</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Pretend you and your friend both want to participate in different after-school activities that meet at the same time.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice resolving the conflict by discussing your interests, considering alternative schedules, and finding a compromise that allows both of you to pursue your interests.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>viii. Scenario: Disagreement on Rules or Boundaries at Home</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Imagine you and your parents have different opinions on a household rule or boundary. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice resolving the conflict by calmly expressing your viewpoint, actively listening to your parents' perspective, and finding a compromise that works for everyone. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>ix. Scenario: Dispute Over Group Game Rules</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Pretend there's a disagreement about the rules while playing a group game. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice resolving the conflict by discussing the rules, listening to everyone's ideas, and coming to an agreement on the game rules that is fair for everyone.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>x. Scenario: Handling Conflict between Sports Teams</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Imagine a disagreement or conflict arises between two sports teams during a game or practice.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Practice resolving the conflict by encouraging open communication, promoting fair play, and finding a solution that allows both teams to enjoy the game and maintain sportsmanship.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In their groups, students should act out the scenarios while applying conflict resolution strategies. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to focus on understanding emotions, actively listening, and finding win-win solutions. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After each role-play, facilitate a discussion about the strategies used and their effectiveness. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conflict Resolution Worksheet (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute conflict resolution worksheets or handouts to each child. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on a recent conflict they experienced and complete the worksheet, addressing emotions, perspectives, and possible solutions. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to think about win-win solutions that consider the emotions and needs of all parties involved. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share one conflict resolution strategy they learned and one assertive statement they find helpful.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of understanding emotions, active listening, and finding win-win solutions in conflict resolution. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Extension</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to apply conflict resolution strategies and effective emotional expression in their daily lives, both in school and at home. Provide opportunities for them to practice these skills through role-plays, group discussions, and collaborative problem-solving activities. </DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Role-play scenarios (written on index cards or small pieces of paper) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Conflict resolution worksheets or handouts' link={Worksheets.conflictCrusher.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Conflict Resolution: How to Settle Your Differences Fairly -</DefaultText>
                        <DefaultTextWithLink text='https://youtu.be/jg_Q34kGsKg' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Teaching Kids How to Deal With Conflict -</DefaultText>
                        <DefaultTextWithLink text='https://childmind.org/article/teaching-kids-how-to-deal-with-conflict/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Visual example of the 5 different Conflict Resolutions Styles -</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=qiqbmuXAc0g' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    durationText: {
        marginBottom: 10,
        color: DecidaColors.Gray,
        alignSelf: 'center'
    },
})
