import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { LimerickIcon } from '../../activities/icons/likerick-icon'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { LikerickIconInverted } from '../../explore/src/likerick-icon-inverted'
import { LimerickImage1 } from './src/limerick-image1'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'

export const SwitchesEmotionsHappyLimerick: CardTemplate = {
    cardId: "46de060a-80a5-4389-a19d-383781ab9921",
    title: 'Limerick',
    Header: LikerickIconInverted,
    lessonPlan: true,
    Icon: LimerickIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Exercising creativity and refocussing energy"} />
        </>
    ),
    Images: () => (
        <>
            <LimerickImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Think about some funny limerick topic ideas.  If you are stuck, try using the “what if” technique, for example, “What if a kangaroo could sing?”"} />
                <DefaulttextWithRightArrow textdata={"Write the first line, and try to choose the word at the end that will be easy to rhyme with, for example:"} />
                <DefaultTextWithoutArrowsItallic textdata={"There was a kangaroo called Kate."} />
                <DefaulttextWithRightArrow textdata={"List words that rhyme with that last word. You may want to use an online rhyming dictionary. "} />
                <DefaulttextWithRightArrow textdata={"Write your second and last line, for example:"} />
                <DefaultTextWithoutArrowsItallic textdata={"There was a kangaroo called Kate."} />
                <DefaultTextWithoutArrowsItallic textdata={"One day she said to her mate"} />
                <DefaultTextWithoutArrowsItallic textdata={"——————"} />
                <DefaultTextWithoutArrowsItallic textdata={"——————"} />
                <DefaultTextWithoutArrowsItallic textdata={"Singing a silly song is great!"} />
                <DefaulttextWithRightArrow textdata={"Write lines three and four.  You might need to adjust the last line to make it even better, for example:"} />
                <DefaultTextWithoutArrowsItallic textdata={"There was a kangaroo called Kate"} />
                <DefaultTextWithoutArrowsItallic textdata={"One day she said to her mate"} />
                <DefaultTextWithoutArrowsItallic textdata={"With a big silly grin"} />
                <DefaultTextWithoutArrowsItallic textdata={"And food on her chin"} />
                <DefaultTextWithoutArrowsItallic textdata={"Singing a song is great!"} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.EXTRA_SWITCHES,
    whyDoesItWorkText: 'Limericks are fun to write and just as fun to read out loud. In addition to improving vocabulary, poetry is a powerful tool to express emotions, focus energy, and promote happiness. Research has shown  that writing or reading poetry can be therapeutic in alleviating symptoms of depression and anxiety, and improving emotional resilience because of the feel good chemicals released in your brain when you are creative.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 10, color: DecidaColors.Gray }}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Recall and apply the rules for writing a limerick, and </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Practice using limerick writing as a creative outlet for managing emotion. </DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Play this introductory explanatory video:  </DefaultText>
                        <Text style={{ textDecorationLine: 'underline', color: 'blue', paddingLeft: 40, fontSize: 20, }} onPress={() => {
                            openUrlWebView('https://www.completesongwriter.com/why-people-write-songs/index.php');
                        }}>https://www.completesongwriter.com/why-people-write-songs/index.php</Text>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Students can work individually, or group students into pairs or small groups. </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Brainstorm ideas for limericks, some starting suggestions could be:  </DefaultText>
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• A lazy house cat' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Clumsy old monkey ' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Angry little goat' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Sad young prince' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• A whimsical fairy' />
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Hand out the Switch4Schools limerick worksheet and ask them to compose a limerick / limericks in the ‘Your turn’ space that complies with the rules of writing a limerick (rules are also on the worksheet):</DefaultText>
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• 5 lines in total.' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• First, second and fifth line end with the same rhyming sound and usually have between 8 to 9 syllables. ' />
                        <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata='• Third and fourth line end with a different rhyming sound and usually have 5 to 6 syllables. ' />
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Invite students to share their limericks. </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Debrief the activity to evaluate how they found the experience, and how it positively impacted their mood. </DefaultText>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• If students have their own device, ask them to find this switch in the Switch4Schools app. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Pen/pencils </DefaultText>
                        <Text style={{ textDecorationLine: 'underline', color: 'blue', paddingLeft: 40, fontSize: 20, }} onPress={() => {
                            openUrlWebView(Worksheets.limerick.uri);
                        }}>• Limerick worksheet</Text>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>• Projector and screen for watching video</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>Limerick Poems for Kids video - </DefaultText>
                        <Text style={{ textDecorationLine: 'underline', color: 'blue', paddingLeft: 40, fontSize: 20, }} onPress={() => {
                            openUrlWebView('https://www.youtube.com/watch?v=Wy65TkCadfU');
                        }}>https://www.youtube.com/watch?v=Wy65TkCadfU</Text>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>How to Write a Limerick in 5 Steps + Examples - </DefaultText>
                        <Text style={{ textDecorationLine: 'underline', color: 'blue', paddingLeft: 40, fontSize: 20, }} onPress={() => {
                            openUrlWebView('https://www.imagineforest.com/blog/how-to-write-a-limerick/');
                        }}>https://www.imagineforest.com/blog/how-to-write-a-limerick/</Text>
                        <DefaultText style={{ paddingLeft: 40, color: DecidaColors.AppleSystemGray2Dark, paddingVertical: 5, }}>Rhyme Zone -
                        </DefaultText>
                        <Text style={{ textDecorationLine: 'underline', color: 'blue', paddingLeft: 40, fontSize: 20, }} onPress={() => {
                            openUrlWebView('https://www.rhymezone.com/');
                        }}>https://www.rhymezone.com/</Text>
                    </>}
            />
        </ >
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Limerick.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [],
    emotion: [CardFilterEmotionType.Happy]
}

