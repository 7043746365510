import { gql, useMutation } from '@apollo/client'
import { Picker } from '@react-native-picker/picker'
import { useState } from 'react'
import { GestureResponderEvent, Pressable, StyleSheet, View } from 'react-native'
import { CreateClassMutation, CreateClassMutationVariables } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { createClass } from '../../common/graphql/mutations'
import DefaultTextInputWithIcon from '../common/default-text-input-with-icon'
import { GetSchoolQueryResponseClassGroups } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query'
import SchoolAdminClassIcon from './school-admin-class-icon'
import { ClazzType } from './school-admin-types'
import ButtonConfirm from '../common/button-confirm'
import ToastAlert, { ToastAlertType } from '../common/toast-alert'


type Props = {
    group: GetSchoolQueryResponseClassGroups
    onFinishSubmit?: () => void
}
const SchoolAdminFormAddClass = ({ group, onFinishSubmit }: Props) => {

    const [createClassMutation] = useMutation<CreateClassMutation, CreateClassMutationVariables>(gql`${createClass}`,)

    const [selectedClassMode, setSelectedClassMode] = useState<ClazzType | undefined>(undefined)
    const [className, setclassName] = useState('')

    const [errorMessage, setErrorMessage] = useState('')
    const [showToast, setShowToast] = useState(false)

    const handleContainerPress = (e: GestureResponderEvent) => e.preventDefault()

    const handleSelectClassMode = (itemValue: ClazzType) => {
        setSelectedClassMode(itemValue)
    }

    const handleConfirm = async () => {
        try {
            setErrorMessage("")

            if (!group.schoolID) {
                throw "Class groups is not assigned to any school"
            }

            if (!className) {
                setErrorMessage("Please input class name")
                setShowToast(true)
                return
            }

            if (selectedClassMode === undefined) {
                setErrorMessage("Please select class type")
                setShowToast(true)
                return
            }

            await createClassMutation({
                variables: {
                    input: {
                        name: className,
                        goMode: selectedClassMode === ClazzType.GoMode ? true : false,
                        schoolID: group.schoolID,
                        classGroupID: group.id,
                        emotionIntensityLevel: 5
                    }
                }
            })
            onFinishSubmit && onFinishSubmit()
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <View style={{ marginVertical: 10, }}>
            <Pressable onPress={handleContainerPress} style={styles.container}>
                <View style={styles.leftFormContainer}>

                    <DefaultTextInputWithIcon
                        Icon={() => <SchoolAdminClassIcon clazzType={selectedClassMode} />}
                        value={className}
                        onChangeText={setclassName}
                        placeholder='Class name'
                        containerStyle={styles.input}
                        inputStyle={{ width: '100%', paddingVertical: 0 }}
                    />
                </View>

                <View style={styles.rightFormContainer}>
                    <Picker
                        selectedValue={selectedClassMode || undefined}
                        onValueChange={handleSelectClassMode}
                        style={[styles.input, { flex: 1 }]}
                        placeholder='Class type'
                    >
                        <Picker.Item label="Class type" value="" color="#999" style={styles.placeholderText} />
                        <Picker.Item label="Class mode" value={ClazzType.ClassMode} />
                        <Picker.Item label="Go mode" value={ClazzType.GoMode} />
                    </Picker>
                    <ButtonConfirm onConfirm={handleConfirm} />
                </View>

            </Pressable>
            <ToastAlert message={errorMessage} setVisible={setShowToast} visible={showToast} type={ToastAlertType.error} />
        </View>
    )
}
export default SchoolAdminFormAddClass

const defaultFormFontSize = 14

const styles = StyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        height: 50,
    },
    leftFormContainer: {
        flex: 1,
        marginRight: 5,
    },
    rightFormContainer: {
        flex: 1,
        flexDirection: 'row',
        gap: 5,
        alignItems: 'center',
    },
    input: {
        height: 25,
        borderRadius: 0,
        paddingVertical: 0,
        marginBottom: 0,
    },
    placeholderText: {
        color: '#999',
    },
})