import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Teacher } from '../../common/API'
import { List } from '../common/list'
import { ScreenNames } from '../common/screen-names'
import { AdminStackNavigatorParamList } from './admin-route-param-types'

interface Props {
    teachers: Teacher[]
    schoolID: string
}

export const AdminSchoolTeachersList = ({ teachers, schoolID }: Props) => {
    const { navigate } = useNavigation<StackNavigationProp<AdminStackNavigatorParamList>>()

    const addTeacher = () => {
        navigate(ScreenNames.AdminTeacher, { id: undefined, schoolID })
    }

    const editTeacher = (id: string) => {
        navigate(ScreenNames.AdminTeacher, { id, schoolID })
    }

    return (
        <>
            <List
                type='accordion'
                title='Teachers'
                items={teachers.map(t => ({id: t.id, name: t.cognitoUsername}))}
                add={addTeacher}
                edit={editTeacher}
            />
        </>
    )
}