import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { IntroductionToScaredLessonPlan1 } from './src/introduction-to-scared-lesson-plan1'
import { IntroductionToScaredLessonPlan2 } from './src/introduction-to-scared-lesson-plan2'
import { Worksheets } from '../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

const introductionScaredVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/Aq7gFfvhrAM",
    title: ""
}

export const SwitchesLessonPlansIntroductionToScared: CardTemplate = {
    cardId: "79a040c7-e281-4954-90fd-4a8a8bab8f33",
    title: 'Exploring scared',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define and identify the physical and emotional signs of fear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify strategies for managing fear in a healthy way.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Log into the Switch4Schools program and select an appropriate meme of the day to share, for example:</DefaultText>
                        <View style={styles.imageContainer}>
                            <IntroductionToScaredLessonPlan1 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play this video: 100 Kids Tell Us Their Fears </DefaultText>
                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={introductionScaredVideo} />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students brainstorm a list of things that they are afraid of. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think about a time when they felt scared. What made them scared? How did they feel physically? What did they do to try to feel better?</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (10 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Define fear as an emotion that we feel in response to a perceived threat or danger.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the physical and emotional signs of fear (e.g. racing heart, sweaty palms, trembling, anxiety, avoidance).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Draw a three column table on the board, and ask students to contribute what they see, feel (in the body), or do (behave) when they or someone else is scared.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Little Book of Big Emotion to explain that there are different levels of sadness and words we can use to describe those levels.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the idea that fear is a natural and normal emotion, but that it is important to learn healthy ways to cope with and manage fear.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• OPTIONAL Movement Break:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Start the wave! Beginning at one end of the room, students stand up and throw their arms overhead, bringing them back down as they return to their seats. Each row follows until you reach the other end of the room. Amp it up by encouraging the students to tap their feet or tap their hands on their legs so that they are in constant motion.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide the students into small groups of 3-4.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have each group discuss the physical and emotional signs of fear that they might experience with each level of being scared, and brainstorm ways to manage or cope.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the groups share ideas with the class.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students complete the This takes courage worksheet</DefaultText>
                        <View style={styles.imageContainer}>
                            <IntroductionToScaredLessonPlan2 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Display the Switch4Schools program, or direct students to use their own device to explore the available switches assigned to scared on the emotion wheel and add an activity they would like to try to their worksheet. This could also be a homework activity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Advise students that you will be exploring different switches over the coming weeks to practice when feeling scared in more detail.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share one thing that they learned about fear during the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Review the importance of understanding and managing fear, and the strategies that were discussed for coping with fear.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Introducing Scared Slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/P6+Exploring+Scared.pdf' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='This Takes Courage Worksheet' link={Worksheets.thisTakeCourtage.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pens/pencils</DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>100 Kids Tell Us Their Fears</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=Aq7gFfvhrAM' />
                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={introductionScaredVideo} />
                        </View>

                        <DefaultText style={CommonStyles.lessonPlanText}>Building Courage in Kids – How to Teach Kids to Be Brave -</DefaultText>
                        <DefaultTextWithLink text='https://www.heysigmund.com/building-courage-in-kids/' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 10
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        flexDirection: 'row',
        marginHorizontal: 20,
        justifyContent: 'center',
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        marginVertical: 10,
    },
    noIndent: {
        paddingLeft: 0,
    },
})
