import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { BreakPoints, CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { KindnessIcon } from './src/kindness-icon'
import { KindnessIconInverse } from './src/kindness-icon-inverse'
import { KindnessImage1 } from './src/kindness-image1'

export const SwitchesEmotionsHappyKindess: CardTemplate = {
    cardId: "70827152-ad6c-4df8-bd4a-0a84d860ed80",
    title: 'Kindness',
    Header: KindnessIconInverse,
    Icon: KindnessIcon,
    lessonPlan: true,
    mainContainerStyle: {
        maxWidth: '100%',
        paddingHorizontal: 20,
    },
    PerfectFor: () => {

        const Step1 = () => {
            return (
                <View style={styles.step1MainContainer}>
                    <DefaultText style={styles.contentText}>Think of someone you would like to be kind to.</DefaultText>
                    <View style={styles.step1ContentContainer}>
                        <View style={styles.rowCentered}>
                            <View style={styles.imageContainer}>
                                <KindnessImage1 />
                            </View>
                            <View style={styles.textContainer}>
                                <DefaultText style={styles.step1TextHeader}>What can you do for them that is nice?</DefaultText>
                                <View>
                                    <DefaultText style={styles.step1Text}>1</DefaultText>
                                    <DefaultText style={styles.step1Text}>Say something nice to them?</DefaultText>
                                </View>
                                <View>
                                    <DefaultText style={styles.step1Text}>2</DefaultText>
                                    <DefaultText style={styles.step1Text}>Help them?</DefaultText>
                                </View>
                                <View>
                                    <DefaultText style={styles.step1Text}>3</DefaultText>
                                    <DefaultText style={styles.step1Text}>Make something for them?</DefaultText>
                                </View>
                            </View>
                        </View>
                    </View>
                    <DefaultText style={styles.contentText}>Do your thing joyfully with a smile!</DefaultText>
                    <WhyDoesItWorkComponent message='Engaging in acts of kindness positively influences mood through various psychological mechanisms. Kind behaviour triggers the release of neurotransmitters like serotonin and oxytocin, fostering feelings of happiness and well being. The positive feedback received for acts of kindness reinforces neural pathways associated with such behaviour, creating a cycle of positivity. Additionally, kindness provides a sense of purpose and meaning, strengthening social connections and support networks crucial for emotional well being. By shifting focus away from personal stressors, fostering a broader perspective, and aligning with innate human tendencies for cooperation, kindness contributes to reduced stress levels and an overall enhanced sense of happiness and fulfillment.' />
                </View>
            )
        }


        const contents = [
            { Component: Step1, audio: '' },
        ]

        return (
            <>
                <SwitchCardJuniorContent contents={contents} scheme={Emotion.HAPPY} />
            </>
        )
    },
    Images: undefined,
    CardContent: () => (
        <>
        </>
    )
    ,
    audioUrl: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/vo_happy_kindness.m4a',
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: "",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the positive impact of showing kindness to others on their own happiness and wellbeing.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by discussing with the students what kindness means to them and how it feels to receive kindness from others.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the idea that showing kindness not only benefits the person receiving it but also contributes to our own happiness and wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Share a brief discussion on the positive emotions associated with acts of kindness.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1 (15 minutes): Kindess Reflection</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think about a time when someone showed them kindness and how it made them feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have a brief discussion, allowing students to share their experiences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Using the whiteboard, create a list of different acts of kindness based on the students' reflections.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2 ( minutes): Create Kindness Notes</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute one ‘Simple act of kindness cut out’, a small piece of paper, or a sticky note to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to write or draw a simple act of kindness that they can do for someone else.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage creativity and variety in the kindness notes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After creating their notes, ask students to share their ideas with a partner.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Closing (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students and discuss the kindness notes they created.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask how they think performing these acts of kindness might make them and others feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the connection between showing kindness and cultivating a sense of happiness within oneself.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Extension (optional):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Implement a "Kindness Challenge" where students aim to complete their suggested acts of kindness over a specific period.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Create a classroom kindness bulletin board to display students' acts of kindness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share their experiences with kindness at home and report back to the class.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Implement Warm Fuzzies (refer to the Activities section within the “fun and positive” category). </DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink style={styles.linkText} link={Worksheets.simpleActOfKindnessCutOut.uri} text={Worksheets.simpleActOfKindnessCutOut.label} />, paper, or sticky notes</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Markers, crayons, or coloured pencils</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What is Kindness for Kids – 6 Powerful Ways to Teach Them to be Kind</DefaultText>
                        <DefaultTextWithLink text='https://www.parentingforbrain.com/how-to-teach-a-child-to-be-kind/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Why It's So Important to Teach Kids to Be Kind</DefaultText>
                        <DefaultTextWithLink text='https://www.psychologytoday.com/au/blog/once-upon-a-child/202306/kindness-is-the-ultimate-social-emotional-skill' />

                        <DefaultText style={CommonStyles.lessonPlanText}>6 Tips for Teaching Kids Kindness</DefaultText>
                        <DefaultTextWithLink text='https://www.harpercollins.com/blogs/harperkids/6-tips-for-teaching-kids-kindness' />
                    </>}
            />

        </>
    ),
    type: [CardType.Switch],
    time: [],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Happy]
}

const styles = StyleSheet.create({
    linkText: {
        fontSize: DefaultLessonPlanContentFontsize,
    },
    textContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        gap: 15,
    },
    rowCentered: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
    },
    step1ContentContainer: {
        alignSelf: 'center',
        minWidth: BreakPoints.S,
    },
    step1MainContainer: {
        gap: 10,
    },
    step1TextHeader: {
        textAlign: 'center',
        color: DecidaColors.AppleSystemGray2Dark,
        fontWeight: '500'
    },
    step1Text: {
        color: DecidaColors.Happy,
        textAlign: 'center',
        fontWeight: '500'
    },
    contentText: {
        color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20,
        flex: 1
    }
})

