import { StackScreenProps } from "@react-navigation/stack"
import { StyleSheet, View } from "react-native"
import { BackButton } from "../common/back-button"
import { TeacherStats } from "../teacher/teacher-stats"
import { AdminStackNavigatorParamList } from "./admin-route-param-types"


export const AdminViewClassStats = ({ navigation, route }: StackScreenProps<AdminStackNavigatorParamList, 'AdminViewClassStats'>) => {
    return (
        <>
            <View style={styles.backButtonContainer}>
                <BackButton />
            </View>
            <TeacherStats navigation={navigation} route={route} />
        </>
    )
}

const styles = StyleSheet.create({
    backButtonContainer: {

    },
})