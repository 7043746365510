import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { AudioHandlerOutlinedSmall } from '../../../common/audio-handler-outlined-small'
import { audio } from '../../../common/audio-imports'
import { Br } from '../../../common/br'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles, commonStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { ListenOutIcon } from '../../explore/src/listen-out-icon'
import { ListenOutIconInverted } from '../../explore/src/listen-out-icon-inverted'
import { ListenOutSideImage2 } from './src/listen-out-side-image2'
import { ListenOutSideImage3 } from './src/listen-out-side-image3'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { stubArray } from 'lodash'

const audioButtonWidth = 160

export const SwitchesEmotionsExcitedListenOut: CardTemplate = {
    cardId: "d29db9ba-2ba8-45c8-bbc9-dcd5bbd4a5be",
    title: 'Listen out',
    Header: ListenOutIconInverted,
    Icon: ListenOutIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <View style={commonCardStyles.imagecontainer}>
                <View style={{ width: 250, height: 180 }}>
                    <ListenOutSideImage3 />
                </View>
            </View>
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Calming excitement and increasing awareness."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Sit still and notice all the sounds that you can hear around you."} />
                <DefaultTextWithDownArrow textdata={"Focus in on a moving sound, like a bird, car, aeroplane or someone’s footsteps."} />
                <DefaultTextWithDownArrow textdata={"Listen to the sound as it changes and slowly disappears into the distance."} />
                <DefaultTextWithDownArrow textdata={"Now focus on something you can hear but cannot see (like wind rustling a tree outside.)"} />
                <DefaultTextWithoutArrows textdata={"Take a minute to imagine what that thing looks like that is making that sound."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: '\n \n When you’re over excited your vision and hearing become myopic, reducing your ability to notice the impact you are having on those around you. Listen Out not only assists in helping to calm you down, it also teaches an important aspect of active listening (which is listening with attention) and increases your ability to effectively collaborate with others.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice listening, memory and comprehension skills.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 55 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Start with a brief discussion about the importance of listening and focusing, especially when feeling excited or energetic.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the activity: students will listen to different sounds and guess what is making the sounds.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Round 1: Name That Sound (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute Name That Sound worksheets to each student.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the rules and categories of sounds (animals, birds, rainforest sounds, domestic, human, musical, nature, transport).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play sounds from each category, and students tick the corresponding icon on their worksheet for each sound.</DefaultText>

                        <View style={{ width: '100%' }}>
                            <DefaultText style={CommonStyles.lessonPlanText}>Animals</DefaultText>
                            <View style={styles.audioButtonsContainer}>
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.AnimalCatMeow} audioTitle={"Cat Meow"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.AnimalCowMoo} audioTitle={"Cow Moo"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.AnimalCricketChirp} audioTitle={"Cricket Chirp"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.AnimalDogBark} audioTitle={"Dog Bark"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.AnimalDonkeyHeeHaw} audioTitle={"Donkey Hee-Haw"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.AnimalDuckQuaking} audioTitle={"Duck Quack"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.AnimalElephantTrumpeting} audioTitle={"Elephant Trumpet"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.AnimalGooseHonk} audioTitle={"Goose Honk"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.AnimalHorseNeight} audioTitle={"Horse Neigh"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.AnimalLambBaa} audioTitle={"Lamb Baa"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.AnimalWolfHowl} audioTitle={"Wolf Howl"} />
                                <View style={{ flex: 1 }} />
                            </View>

                            <DefaultText style={CommonStyles.lessonPlanText}>Birds</DefaultText>
                            <View style={styles.audioButtonsContainer}>
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.BirdCallHawCry} audioTitle={"Hawk Cry"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.BirdCallKooKaburra} audioTitle={"Kookaburra Laugh"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.BirdCallMagpie} audioTitle={"Magpie Song"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.BirdCallOwlHoot} audioTitle={"Owl Hoot"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.BirdCallRainforestSounds} audioTitle={"Rainforest Sounds"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.BirdCallRooseter} audioTitle={"Rooster Crow"} />
                            </View>

                            <DefaultText style={CommonStyles.lessonPlanText}>Domestic</DefaultText>
                            <View style={styles.audioButtonsContainer}>
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.DomesticClockStrikes} audioTitle={"Clock Chime"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.DomesticDoorClosing} audioTitle={"Door Closing"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.DomesticGlassBreaking} audioTitle={"Breaking Glass"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.DomesticLawnMower} audioTitle={"Lawn Mower"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.DomesticOldPhoneRinging} audioTitle={"Old Telephone Ring"} />
                                <View style={{ flex: 1 }} />
                            </View>

                            <DefaultText style={CommonStyles.lessonPlanText}>Human</DefaultText>
                            <View style={styles.audioButtonsContainer}>
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.HumanApplause} audioTitle={"Applause"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.HumanBasketballBouncing} audioTitle={"Basketball Bounce"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.HumanFootsteps} audioTitle={"Footsteps"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.HumanLaughing} audioTitle={"Laughing"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.HumanToddlerGiggle} audioTitle={"Baby Giggle"} />
                                <View style={{ flex: 1 }} />
                            </View>


                            <DefaultText style={CommonStyles.lessonPlanText}>Musical</DefaultText>
                            <View style={styles.audioButtonsContainer}>
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.MusicalBooing} audioTitle={"Boing"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.MusicalDrums} audioTitle={"Drum Solo"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.MusicalSlideWhistle} audioTitle={"Slide Whistle"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.MusicalTrumpetFanfare} audioTitle={"Trumpet Fanfare"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.MusicalViolinTuning} audioTitle={"Violin Tuning"} />
                                <View style={{ flex: 1 }} />
                            </View>


                            <DefaultText style={CommonStyles.lessonPlanText}>Nature</DefaultText>
                            <View style={styles.audioButtonsContainer}>
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.NatureFireCracking} audioTitle={"Fire Crackling"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.NatureOceanWaves} audioTitle={"Ocean Waves"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.NatureSplash} audioTitle={"Splash"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.NatureThunder} audioTitle={"Thunder"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.NatureWindHowling} audioTitle={"Wind Howling"} />
                                <View style={{ flex: 1 }} />
                            </View>


                            <DefaultText style={CommonStyles.lessonPlanText}>Transport</DefaultText>
                            <View style={styles.audioButtonsContainer}>
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.TransportAirplaneDoingAcrobatics} audioTitle={"Aeroplane"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.TransportBusStarting} audioTitle={"Bus Starting"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.TransportCarHorns} audioTitle={"Car Horns"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.TransportMotorbikeTakeOff} audioTitle={"Motorbike"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.TransportShipHorn} audioTitle={"Ship Horn"} />
                                <AudioHandlerOutlinedSmall width={audioButtonWidth} audioFile={audio.TransportTrainWhistle} audioTitle={"Train Whistle"} />
                            </View>
                        </View>

                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Optionally, add a friendly competition element by awarding points for each correct answer.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Round 2: Identify Classroom Sounds (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to close their eyes or place their heads down on their desks.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Make various sounds in the classroom (banging hand on the wall, zipping a pencil case, clapping rulers, pouring water, tapping a pencil).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Students note down their guesses on the worksheet after each sound.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Round 3: Classroom Sound Scavenger Hunt (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to sit still and listen carefully to the sounds in the classroom.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set a time limit and have students list all the sounds they can hear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to focus on stillness and attentive listening.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss and share the identified sounds.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Tally and Debrief (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Tally up the points and celebrate the winners for their excellent listening skills.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief by discussing the importance of being still and paying attention to enhance listening skills.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise how this activity helps in calming energy levels and preparing to learn.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reiterate the connection between focused listening, stillness, and managing excited energy. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice attentive listening regularly. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, invite them to explore the Listen Out Switch in the Switch4Schools app. </DefaultText>



                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Speaker for playing sounds from the app</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Name That Sound worksheets' link={Worksheets.nameThatSound.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>How to Help Kids Manage Overexcitement in Different Grades -</DefaultText>
                        <DefaultTextWithLink text="https://www.understood.org/en/articles/how-to-help-kids-manage-overexcitement-in-different-grades" />
                        <Br />
                        <DefaultText style={CommonStyles.lessonPlanText}>Selecting sounds: How the brain knows what to listen to -</DefaultText>
                        <DefaultTextWithLink text='https://www.sciencedaily.com/releases/2017/12/171211140358.htm' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Listen+Out.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Excited]
}
const styles = StyleSheet.create({
    audioButtonsContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
})