import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { IntroductionToSadLessonPlan1 } from './src/introduction-to-sad-lesson-plan1'
import { IntroductionToSadLessonPlan2 } from './src/introduction-to-sad-lesson-plan2'
import { Worksheets } from '../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

const introductionSadVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/F2bk_9T482g",
    title: ""
}

export const SwitchesLessonPlansIntroductionToSad: CardTemplate = {
    cardId: "5f54c388-7ecc-448f-990b-eeb30440cc76",
    title: 'Exploring sad',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define sadness and understand its role in the body.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify strategies for managing sadness in a healthy way.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Log into the Switch4Schools program and select an appropriate meme of the day to share, for example:</DefaultText>
                        <View style={styles.imageContainer}>
                            <IntroductionToSadLessonPlan1 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play this video from Pixar’s film “Up”. : Favorite Pixar's Up scene ever - Ellie and Carl's relationship through time, Sad scene </DefaultText>
                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={introductionSadVideo} />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to describe what Carl was feeling (look for grieving). Explain that grief is a feeling associated with sadness. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think about a time when they felt sad. What made them sad? How did they feel physically? What did they do to try to feel better?</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (10 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Little Book of Big Emotion and introduce the concept of sadness. Define sadness as a feeling of melancholy, hopelessness, or unhappiness that can be accompanied by physical symptoms such as a heavy heart, tearfulness, or lack of energy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the role of sadness in the body. Explain that sadness is a natural emotion that helps us to process and cope with difficult or painful experiences. However, when sadness becomes persistent or overwhelming, it can interfere with our daily lives and well-being</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Draw a three column table on the board, and ask students to contribute what they see, feel (in the body), or do (behave) when they or someone else is sad.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Little Book of Big Emotion to explain that there are different levels of sadness and words we can use to describe those levels.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• OPTIONAL Movement Break:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Trading Places - Have students stand behind their pushed-in chairs. Call out a trait, and everyone who has that trait must change places with someone else (students who do not have the trait stay where they are). Examples: “Everyone with curly hair.” “Everyone who ate cereal for breakfast.” “Everyone who is wearing white socks.”</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide students into small groups</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have each group come up with a list of strategies for managing sadness in a healthy way. These could include things like spending time with friends, engaging in hobbies, getting enough sleep, or practicing gratitude.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have each group share their list with the class</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give students the Managing Sadness worksheet</DefaultText>
                        <View style={styles.imageContainer}>
                            <IntroductionToSadLessonPlan2 />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them recall events in their life that triggered the different levels of sadness and write down at least one thing they have done in the past to cope with sadness. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Display the Switch4Schools program, or direct students to use their own device to explore the available switches assigned to sad on the emotion wheel and add activities they’d like to try to their worksheet. Could also be a homework activity.”</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Advise students that you will be exploring different switches over the coming weeks to practice when feeling sad in more detail. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Review the different strategies for managing sadness that were discussed in the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of finding healthy ways to cope with sadness and encourage students to use the toolkit as a reference when they are feeling sad.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to seek additional help if their sadness is persistent or severe, and to talk to a trusted adult if they are struggling to manage their sadness.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Introducing Sadness Slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/P5+Exploring+Sadness.pdf' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen and paper for each student</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Managing sadness worksheet' link={Worksheets.managingSadness.uri} /></DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Favorite Pixar's Up scene ever - Ellie and Carl's relationship through time, Sad scene  </DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=F2bk_9T482g' />
                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={introductionSadVideo} />
                        </View>

                        <DefaultText style={CommonStyles.lessonPlanText}>Working with the sad child:  tearfulness, sadness and depression in primary aged children -</DefaultText>
                        <DefaultTextWithLink text='https://developingminds.net.au/articles-for-professionals/2018/3/14/i862cw03tednjbdfnabjtojug037a3' />


                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 10
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
    rowContainer: {
        flex: 1,
        flexDirection: 'row',
        marginHorizontal: 20,
        justifyContent: 'center',
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        marginVertical: 10,
    },
    noIndent: {
        paddingLeft: 0,
    },
})
