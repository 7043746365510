import { PropsWithChildren } from 'react'
import { View, StyleSheet } from 'react-native'
import { BackButton } from './back-button'

export const RootViewBackButton = ({ children, onPress }: PropsWithChildren<{ onPress: () => void }>) =>
    <View style={styles.view}>
        <BackButton onPress={onPress} />
        {children}
    </View>

const styles = StyleSheet.create({
    view: {
        flex: 1,
        flexDirection: 'column',
    }
})
