import { useReactiveVar } from "@apollo/client";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useEffect, useState } from "react";
import { StyleSheet, TextInput, View, ViewStyle } from "react-native";
import { CommonStyles, LAST_ACCESSED_VERSION_KEY, OfflineMessage } from "../common/const";
import { DefaultButton } from "../common/default-button";
import { DefaultText } from "../common/default-text";
import { rvIsLoading } from "../common/loading";
import { versionNumber } from "../env";
import { LoginStackNavigatorParamList } from './login-route-param-types';
import { rvLoginErrorMesage } from "./login-state";
import { PasswordErrorText } from "./password-error-text";
import { PasswordInput } from "./password-input";
import ButtonTextForgotPassword from "../common/button-text-forgot-password";
import { DecidaColors } from "../../common/decida-colors";
import { authSignIn } from "./login-functions";
import { webViewMessageRequestBiometricLogin } from "../web-view/web-view-messages";
import { rvSavedUsername, rvSavedPassword, rvBiometricLoginSucceeded } from "../web-view/web-view-state";

type Props = {
    style?: ViewStyle
    onSuccessLogin?: () => Promise<void>
}

const LoginForm = ({ style, onSuccessLogin }: Props) => {
    const loginErrorMessage = useReactiveVar(rvLoginErrorMesage)
    const biometricLoginSucceeded = useReactiveVar(rvBiometricLoginSucceeded)
    const savedUsername = useReactiveVar(rvSavedUsername)
    const savedPassword = useReactiveVar(rvSavedPassword)

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [loggingIn, setLoggingIn] = useState(false);
    const [loginError, setLoginError] = useState("");

    useEffect(() => {
        setLoginError(loginErrorMessage)
    }, [loginErrorMessage])

    const login = async (usrName: string, pwd: string) => {
        setLoggingIn(true);
        setLoginError("");
        rvIsLoading(true)
        AsyncStorage.setItem(LAST_ACCESSED_VERSION_KEY, versionNumber)

        try {
            await authSignIn(
                usrName,
                pwd,
                navigate,
                (e) => {
                    if (e.code === 'NetworkError') {
                        setLoginError(OfflineMessage);
                    } else {
                        setLoginError("Incorrect username and/or password.");
                    }
                    setLoggingIn(false);
                },
            );

            onSuccessLogin && await onSuccessLogin()

        } catch (error) {

        } finally {
            rvIsLoading(false)
        }
    };

    useEffect(() => {
        if (biometricLoginSucceeded && savedUsername && savedPassword) {
            setUsername(savedUsername)
            setPassword(savedPassword)
            rvBiometricLoginSucceeded(false)
            login(savedUsername, savedPassword)
        }
    }, [biometricLoginSucceeded])

    useEffect(() => {
        if (window?.location?.search) {
            const param = window.location.search.substring(1);
            let usrName: string | undefined, pwd: string | undefined;

            param.split("&").forEach((p) => {
                const [key, value] = p.split("=");
                if (key === "username") {
                    usrName = value;
                } else if (key === "password") {
                    pwd = value;
                }
            });

            if (usrName && pwd) {
                login(usrName, pwd);
            }
        }
    }, []);

    const { navigate } =
        useNavigation<StackNavigationProp<LoginStackNavigatorParamList>>();

    const loginWithBiometric = async () => {
        window.ReactNativeWebView?.postMessage(webViewMessageRequestBiometricLogin)
    }

    const onLogin = () => {
        if (username === "") {
            setLoginError("Username cannot be empty");
        } else if (password === "") {
            setLoginError("Password cannot be empty");
        } else {
            login(username, password);
        }
    };

    const hasSavedCredentials = savedUsername && savedPassword

    return (
        <View style={[styles.loginFormContainer, style]}>
            {hasSavedCredentials
                ?
                <>
                    <DefaultButton
                        style={styles.touchIDButton}
                        buttonTextColor={DecidaColors.White}
                        onPress={loginWithBiometric}
                        disabled={loggingIn}
                    >
                        Login with TouchID / FaceID
                    </DefaultButton>
                </>
                :
                null}
            <View style={styles.leftAlign}>
                <DefaultText style={styles.loginHeader}>
                    Username
                </DefaultText>
            </View>

            <TextInput
                testID="username"
                style={CommonStyles.textInput}
                placeholder="username"
                autoCorrect={false}
                autoComplete="username"
                autoCapitalize="none"
                keyboardType="email-address"
                textContentType="username"
                onChangeText={setUsername}
            />
            <View style={styles.leftAlign}>
                <DefaultText style={styles.loginHeader}>Password</DefaultText>
            </View>
            <PasswordInput onPasswordChange={setPassword} onSubmit={onLogin} />

            <DefaultButton
                testID="login-button"
                style={styles.loginButton}
                buttonTextColor={DecidaColors.White}
                onPress={onLogin}
                disabled={loggingIn}
            >
                Login
            </DefaultButton>
            <ButtonTextForgotPassword />

            <PasswordErrorText>{loginError}</PasswordErrorText>
        </View>
    )
}

export default LoginForm

const styles = StyleSheet.create({
    loginFormContainer: {
        width: '100%',
        alignItems: 'center'
    },
    loginButton: {
        backgroundColor: DecidaColors.Green,
    },
    touchIDButton: {
        backgroundColor: DecidaColors.Salmon,
    },
    leftAlign: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "flex-start",
        paddingHorizontal: 10,
        marginTop: 10,
    },
    loginHeader: {
        alignItems: "flex-start",
        fontWeight: "bold",
        fontSize: 18,
    },

})