import { gql, useReactiveVar } from '@apollo/client';
import { GraphQLResult, GraphQLSubscription } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import { useEffect } from 'react';
import Observable from 'zen-observable-ts';
import { CheckInSession, Class, ClassLogin, CreateClassLoginMutation, CreateFavouriteMutation, CreateStudentCheckInInput, CreateStudentCheckInMutation, CreateStudentClassMutation, CreateSwitchRatingMutation, DeleteFavouriteMutation, Favourite, GetClassQuery, GetSchoolQuery, GetStudentClassByClassIdQuery, GetStudentClassByStudentIdQuery, GetStudentQuery, GetSwitchRatingByUserIdQuery, ListCheckInSessionsByClassIdQuery, ListClassLoginByCognitoUsernameQuery, ListFavouriteByCognitoUsernameQuery, ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByStudentIdQuery, ListStudentsBySchoolIDQuery, ListStudentsBySchoolIDQueryVariables, ListTeacherClassesByTeacherIDQuery, ListTeachersQuery, ListVideosQuery, School, Student, StudentCheckIn, StudentClass, SwitchRating, TeacherClass, UpdateClassMutation, UpdateStudentCheckInMutation, UpdateStudentMutation, UpdateSwitchRatingMutation, UpdateTeacherMutation } from '../../common/API';
import { UserGroup } from '../../common/constants';
import { createClassLogin, createFavourite, createStudentCheckIn, createStudentClass, createSwitchRating, deleteFavourite, updateClass, updateStudent, updateStudentCheckIn, updateSwitchRating, updateTeacher } from '../../common/graphql/mutations';
import { getSchool, getStudent, getStudentClassByClassId, getStudentClassByStudentId, getSwitchRatingByUserId as getSwitchRatingByUserIdQuery, listCheckInSessionsByClassId, listClassLoginByCognitoUsername, listFavouriteByCognitoUsername, listStudentCheckInsByCheckInSessionId, listStudentCheckInsByStudentId, listStudentsBySchoolID, listTeacherClassesByTeacherID, listTeachers, listVideos } from '../../common/graphql/queries';
import { onMutateCheckInSessionByClassId, onMutateClassById, onMutateClassLoginByClassID, onMutateClassLoginByCognitoUsername, onMutateStudentByCognitoUsername, onMutateStudentCheckInByCheckInSessionId, onMutateStudentCheckInByClassId, onMutateStudentCheckInByClassIdAndStudentId, onMutateStudentClassByClassId, onMutateStudentClassByStudentId, onMutateSwitchRatingByUserId } from '../../common/graphql/subscriptions';
import { rvClassLoginCurrentCheckIn, rvClassLoginCurrentStudentCheckin, rvClassLoginStudentClasses, rvCurrentClassLogin } from '../class-login/class-login-state';
import { getClassQuery } from '../custom-graphql/queries/get-class-query';
import { rvCurrentUser, rvUserGroup } from '../login/login-state';
import { getCurrentStudent, rvCurrentStudent, rvCurrentStudentLastStudentCheckIn, rvStudentClassClasses, rvStudentClasses } from '../student/use-student-state';
import { rvTeacherCheckinSessions, rvTeacherClasses, rvTeacherCurrentCheckIn, rvTeacherCurrentClassCheckinSessions, rvTeacherCurrentStudents, rvTeacherCurrentTeacherClasses, rvTeacherLastStudentCheckins, rvTeacherSchoolStudents, rvTecherCurrentStudentStudentCheckIn, StudentAndStudentClass } from '../teacher/teacher-state';
import { apolloClient, rvCheckinSessions, rvCurrentClass, rvFavouriteSwitches, rvPreviousStudentCheckins, rvRatings } from './common-state';
import { rvIsLoading } from './loading';

export type RefetchProps = {
    refetch?: boolean
    payload?: {
        classID?: string
        schoolID?: string
        studentID?: string
        sessionID?: string
        dateFilter?: string
    },
    withoutLoading?: boolean
}

export const getStudentByStudentId = async (studentID: string) => {
    if (studentID) {
        // Caching data placeholder
        // const cacheKey = `listStudentClasses-${cognitoUsername}`
        // const cacheData = await getCacheDataByKey(cacheKey)

        // if (cacheData && !refetch?.status) {
        //     const parsedData = JSON.parse(cacheData)
        //     return parsedData
        // }

        // rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(getStudent,
                {
                    id: studentID
                })) as GraphQLResult<GetStudentQuery>
        const studentResponse = response?.data?.getStudent
        // rvIsLoading(false)
        if (studentResponse) {
            rvCurrentStudent(studentResponse as Student)
        }
    }
}

const notDeletedAndNotArchivedClasses = (item?: { _deleted?: boolean | null, class?: { _deleted?: boolean | null, archived?: boolean | null } | null } | null) =>
    item?._deleted !== true && item?.class?._deleted !== true && item?.class?.archived !== true

export const getTeacherClassDataByTeacherId = async (teacherID: string) => {
    if (teacherID) {
        // Caching data placeholder
        // const cacheKey = `listStudentClasses-${cognitoUsername}`
        // const cacheData = await getCacheDataByKey(cacheKey)

        // if (cacheData && !refetch?.status) {
        //     const parsedData = JSON.parse(cacheData)
        //     return parsedData
        // }

        rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(listTeacherClassesByTeacherID,
                {
                    teacherID
                })) as GraphQLResult<ListTeacherClassesByTeacherIDQuery>
        const teacherClassResponse = response?.data?.listTeacherClassesByTeacherID?.items
        const filteredData = teacherClassResponse?.filter(notDeletedAndNotArchivedClasses)
        rvIsLoading(false)
        rvTeacherCurrentTeacherClasses(filteredData as TeacherClass[])
        // if (teacherResponse) {
        //     rvTeacherCurrentTeacher(teacherResponse[0] as Teacher)
        // }
        return filteredData as TeacherClass[]
    }
}


export const getClassByClassId = async (classID: string) => {
    if (classID) {
        // Caching data placeholder
        // const cacheKey = `listStudentClasses-${cognitoUsername}`
        // const cacheData = await getCacheDataByKey(cacheKey)

        // if (cacheData && !refetch?.status) {
        //     const parsedData = JSON.parse(cacheData)
        //     return parsedData
        // }

        // rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(getClassQuery,
                {
                    id: classID
                })) as GraphQLResult<GetClassQuery>
        const classResponseData = response?.data?.getClass
        console.log('classResponseData', classResponseData)
        // rvIsLoading(false)

        if (classResponseData) {
            rvCurrentClass(classResponseData as Class)
            return classResponseData as Class
        }
    }
}

export const getClassByClasses = async (classes: TeacherClass[] | StudentClass[] | undefined, classID: string, isStudent?: boolean) => {
    if (classes) {
        // Caching data placeholder
        // const cacheKey = `listStudentClasses-${cognitoUsername}`
        // const cacheData = await getCacheDataByKey(cacheKey)

        // if (cacheData && !refetch?.status) {
        //     const parsedData = JSON.parse(cacheData)
        //     return parsedData
        // }

        let currentClasses: Class[] = []

        // rvIsLoading(true)
        await Promise.all(classes.map(async (claz, index) => {
            const response = await API.graphql(
                graphqlOperation(getClassQuery,
                    {
                        id: claz.classID,
                    })) as GraphQLResult<GetClassQuery>
            const classData = response?.data?.getClass
            if (classData && !classData.archived && !classData._deleted) {
                currentClasses = [...currentClasses, classData] as Class[]
            }
        }))
        // rvIsLoading(false)
        if (currentClasses.length) {
            const currentClass = currentClasses.find(f => f.id === classID)
            rvCurrentClass(currentClass)
        }

        if (isStudent) {
            rvStudentClasses(_.orderBy(currentClasses, "name"))
            return
        }
        rvTeacherClasses(_.uniqBy(_.orderBy(currentClasses, ['goMode', "name"]), "id"))
    }
}

export const getClasseLoginsByUsername = async (currentUsername: string) => {
    if (currentUsername) {
        // currentUsername data placeholder
        // const cacheKey = `listStudentClasses-${cognitoUsername}`
        // const cacheData = await getCacheDataByKey(cacheKey)

        // if (cacheData && !refetch?.status) {
        //     const parsedData = JSON.parse(cacheData)
        //     return parsedData
        // }

        rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(listClassLoginByCognitoUsername,
                {
                    cognitoUsername: currentUsername
                })) as GraphQLResult<ListClassLoginByCognitoUsernameQuery>
        const classLoginData = response?.data?.listClassLoginByCognitoUsername?.items?.filter((data) => data?._deleted !== true)
        rvIsLoading(false)
        if (classLoginData) {
            rvCurrentClassLogin(classLoginData[0] as ClassLogin)
        }
    }
}

export const getSwitchRatingByUserId = async (userId: string) => {
    if (userId) {
        const response = await API.graphql(
            graphqlOperation(getSwitchRatingByUserIdQuery,
                {
                    userId
                })) as GraphQLResult<GetSwitchRatingByUserIdQuery>
        const ratingsData = response?.data?.getSwitchRatingByUserId?.items?.filter((data) => data?._deleted !== true)
        rvRatings(ratingsData as SwitchRating[] | undefined)
    }
}

export const getStudentClasses = async (currentClassID: string, allStudents: Student[], refetch?: RefetchProps): Promise<StudentAndStudentClass[] | undefined> => {
    if (currentClassID) {
        const cacheKey = `listStudentClasses-${currentClassID}`
        const cacheData = await getCacheDataByKey(cacheKey)

        if (cacheData && !refetch?.refetch && allStudents.length) { // use cache when all students data already fetched / school already has a student
            const parsedData = JSON.parse(cacheData)
            return parsedData
        }

        // if (!refetch?.withoutLoading) rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(getStudentClassByClassId,
                {
                    classID: currentClassID,
                    limit: 5000
                })) as GraphQLResult<GetStudentClassByClassIdQuery>
        const studentClasses = response?.data?.getStudentClassByClassId?.items.filter(notDeletedAndNotArchivedClasses)
        rvIsLoading(false)
        if (allStudents.length && studentClasses?.length) {
            const formattedData = studentClasses?.map((studentClass) => {
                return {
                    studentClass,
                    student: allStudents.find(student => student.id === studentClass?.studentID)
                }
            })
            setCacheDataWithExpireTime(cacheKey, JSON.stringify(formattedData || []))
            return formattedData || []
        }
    }
}

export const getStudentClassesByClassID = async (currentClassID: string, refetch?: RefetchProps) => {
    if (currentClassID) {
        // const cacheKey = `listStudentClasses-${currentClassID}`
        // const cacheData = await getCacheDataByKey(cacheKey)

        // if (cacheData && !refetch?.status) {
        //     const parsedData = JSON.parse(cacheData)
        //     return parsedData
        // }

        // if (!refetch?.withoutLoading) rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(getStudentClassByClassId,
                {
                    classID: currentClassID,
                    limit: 5000
                })) as GraphQLResult<GetStudentClassByClassIdQuery>
        const studentClasses = response?.data?.getStudentClassByClassId?.items.filter(notDeletedAndNotArchivedClasses)
        rvIsLoading(false)
        if (studentClasses) {
            rvClassLoginStudentClasses(studentClasses as StudentClass[])
        }
    }
}

export const getStudentCheckInByCheckinsessionID = async (checkinsessionID: string, refetch?: RefetchProps) => {
    if (checkinsessionID) {
        // const cacheKey = `listStudentClasses-${currentClassID}`
        // const cacheData = await getCacheDataByKey(cacheKey)

        // if (cacheData && !refetch?.status) {
        //     const parsedData = JSON.parse(cacheData)
        //     return parsedData
        // }

        // if (!refetch?.withoutLoading) rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(listStudentCheckInsByCheckInSessionId,
                {
                    checkinsessionID
                })) as GraphQLResult<ListStudentCheckInsByCheckInSessionIdQuery>
        const studentCheckinsData = response?.data?.listStudentCheckInsByCheckInSessionId?.items
        rvIsLoading(false)
        if (studentCheckinsData) {
            rvClassLoginCurrentStudentCheckin(studentCheckinsData as StudentCheckIn[])
        }
    }
}

export const getStudentCheckInByCheckinsessionIDAndStudentId = async (checkinsessionID: string | undefined, studentID: string | undefined) => {
    if (checkinsessionID && studentID) {
        // const cacheKey = `listStudentClasses-${currentClassID}`
        // const cacheData = await getCacheDataByKey(cacheKey)

        // if (cacheData && !refetch?.status) {
        //     const parsedData = JSON.parse(cacheData)
        //     return parsedData
        // }

        try {
            // rvIsLoading(true)
            const response = await API.graphql(
                graphqlOperation(listStudentCheckInsByCheckInSessionId,
                    {
                        checkinsessionID,
                        filter: {
                            studentID: {
                                eq: studentID
                            }
                        },
                        limit: 5000
                    })) as GraphQLResult<ListStudentCheckInsByCheckInSessionIdQuery>
            const studentCheckinsData = response?.data?.listStudentCheckInsByCheckInSessionId?.items
            rvIsLoading(false)
            if (studentCheckinsData) {
                const studentCheckIn = studentCheckinsData[0] as StudentCheckIn
                rvTecherCurrentStudentStudentCheckIn(studentCheckIn)
                return studentCheckIn
            }
        } catch (error) {
            console.log("getStudentCheckInByCheckinsessionIDAndStudentId", error)
        } finally {
            rvIsLoading(false)
        }
    }
}

export const getSchoolBySchoolId = async (schoolId: string) => {
    if (schoolId) {
        // rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(getSchool, {
                id: schoolId
            })
        ) as GraphQLResult<GetSchoolQuery>
        const schoolData = response?.data?.getSchool
        rvIsLoading(false)
        return schoolData as School
    }

    return null
}

export const getCurrentCheckinSessionsByClassId = async (currentClassID: string, refetch?: RefetchProps) => {
    if (currentClassID) {
        // const cacheKey = `listclassLoginCurrentCheckIn-${currentClassID}`
        // const cacheData = await getCacheDataByKey(cacheKey)

        // if (cacheData && !refetch?.status) {
        //     const parsedData = JSON.parse(cacheData)
        //     return parsedData
        // }

        // if (!refetch?.withoutLoading) rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(listCheckInSessionsByClassId,
                {
                    classID: currentClassID,
                    limit: 5000
                })) as GraphQLResult<ListCheckInSessionsByClassIdQuery>
        const checkInSessions = response?.data?.listCheckInSessionsByClassId?.items.filter((data: any) => data?._deleted !== true)
        rvIsLoading(false)
        rvCheckinSessions(checkInSessions as CheckInSession[])
        const activeCheckins = checkInSessions?.filter(checkIns => checkIns?.isActive)
        if (activeCheckins?.length) {
            const formattedActiveCheckIn = _.orderBy(activeCheckins, "createdAt").reverse()[0]
            // if (formattedActiveCheckIn) {
            //     setCacheDataWithExpireTime(cacheKey, JSON.stringify(formattedActiveCheckIn))
            // }
            rvClassLoginCurrentCheckIn(formattedActiveCheckIn as CheckInSession)
        } else {
            rvClassLoginCurrentCheckIn(undefined)
        }
    }
}

export const getCurrentCurrentStudentPrevCheckins = async (currentStudentId: string, refetch?: RefetchProps) => {
    if (currentStudentId) {
        // const cacheKey = `listStudentClasses-${currentClassID}`
        // const cacheData = await getCacheDataByKey(cacheKey)

        // if (cacheData && !refetch?.status) {
        //     const parsedData = JSON.parse(cacheData)
        //     return parsedData
        // }

        // if (!refetch?.withoutLoading) rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(listStudentCheckInsByStudentId,
                {
                    studentID: currentStudentId,
                    limit: 5000
                })) as GraphQLResult<ListStudentCheckInsByStudentIdQuery>
        const studentCheckins = response?.data?.listStudentCheckInsByStudentId?.items
        rvIsLoading(false)
        rvPreviousStudentCheckins(studentCheckins as StudentCheckIn[])
    }
}

export const getCurrentStudentClassesByStudentId = async (currentStudentId: string | null, refetch?: RefetchProps) => {
    if (currentStudentId) {
        // Placeholder for cache
        // const cacheKey = `listStudentClasses-${currentClassID}`
        // const cacheData = await getCacheDataByKey(cacheKey)

        // if (cacheData && !refetch?.status) {
        //     const parsedData = JSON.parse(cacheData)
        //     return parsedData
        // }

        // if (!refetch?.withoutLoading) rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(getStudentClassByStudentId,
                {
                    studentID: currentStudentId,
                })) as GraphQLResult<GetStudentClassByStudentIdQuery>
        const studentClasses = response?.data?.getStudentClassByStudentId?.items
        const filteredData = studentClasses?.filter((data) => data?._deleted !== true && data?.class?.archived !== true && data?.class?._deleted !== true)
        rvStudentClassClasses(filteredData as StudentClass[])
        rvIsLoading(false)
    }
}

export const getAllStudents = async (schoolID: string, refetch?: RefetchProps) => {
    if (schoolID) {
        const cacheKey = `listStudents-${schoolID}`
        const cacheData = await getCacheDataByKey(cacheKey)

        if (cacheData && !refetch?.refetch) {
            const parsedData = JSON.parse(cacheData)
            rvTeacherSchoolStudents(parsedData)
            return parsedData
        }

        // if (!refetch?.withoutLoading) rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(listStudentsBySchoolID,
                {
                    schoolID,
                    limit: 5000,
                })) as GraphQLResult<ListStudentsBySchoolIDQuery>
        const studentsData: any = response?.data?.listStudentsBySchoolID?.items

        if (refetch) {
            const currentStdClass = await getStudentClasses(refetch.payload?.classID || "", studentsData, { refetch: true, withoutLoading: true })
            if (currentStdClass) {
                const filteredData = currentStdClass.filter((currentStudent: any) => currentStudent.student && currentStudent.studentClass._deleted !== true);
                rvTeacherCurrentStudents(filteredData);
            }
        }
        rvIsLoading(false)
        setCacheDataWithExpireTime(cacheKey, JSON.stringify(studentsData || []))
        rvTeacherSchoolStudents(studentsData)
        return studentsData
    }
}

export const listCheckInSessionsByClassID = async (currentClassId: string | undefined, lastCheckIn?: boolean, refetch?: RefetchProps) => {
    if (currentClassId) {
        const cacheKey = `listCheckInSessions1-${currentClassId}`
        const cacheData = await getCacheDataByKey(cacheKey)

        if (cacheData && !refetch?.refetch) {
            const parsedData = JSON.parse(cacheData)
            rvTeacherCheckinSessions(parsedData)
            rvTeacherCurrentClassCheckinSessions(parsedData)
            if (parsedData[0]?.isActive === false) {
                rvTeacherCurrentCheckIn(undefined)
            } else {
                rvTeacherCurrentCheckIn(parsedData[0])
            }
            return parsedData
        }

        // if (!refetch?.status || !refetch.withoutLoading) rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(listCheckInSessionsByClassId,
                {
                    classID: currentClassId,
                    limit: 5000
                })) as GraphQLResult<ListCheckInSessionsByClassIdQuery>
        rvIsLoading(false)
        let checkInSessionsData: any = response?.data?.listCheckInSessionsByClassId?.items.filter((data) => data?._deleted !== true)
        checkInSessionsData = _.sortBy(checkInSessionsData, "createdAt");
        if (lastCheckIn) {
            const sortedSession = checkInSessionsData.reverse()
            let cache = []
            if (sortedSession[0]?._deleted === null) {
                // there's active session
                rvTeacherCheckinSessions(sortedSession)
                cache = sortedSession
            } else {
                const availableSession = sortedSession.filter((data: any) => data._deleted !== true)
                rvTeacherCheckinSessions(availableSession)
                cache = availableSession
            }
            setCacheDataWithExpireTime(cacheKey, JSON.stringify(cache || []))
            rvIsLoading(false)
            return
        }

        if (!checkInSessionsData.length) {
            rvTeacherCheckinSessions([])
            setCacheDataWithExpireTime(cacheKey, JSON.stringify([]))
            rvTeacherCurrentCheckIn(undefined)
            rvIsLoading(false)
            return
        }

        if (checkInSessionsData[0]?._deleted === true) {
            // last checkin data 
            rvTeacherCheckinSessions(checkInSessionsData)
            setCacheDataWithExpireTime(cacheKey, JSON.stringify(checkInSessionsData))
            validateAndUpdateTeacherCurrentCheckIn(checkInSessionsData)
            rvIsLoading(false)
            return
        }
        checkInSessionsData = checkInSessionsData.filter((data: any) => data?._deleted !== true).reverse()

        validateAndUpdateTeacherCurrentCheckIn(checkInSessionsData)
        rvTeacherCheckinSessions(checkInSessionsData)
        setCacheDataWithExpireTime(cacheKey, JSON.stringify(checkInSessionsData))
        rvTeacherCurrentClassCheckinSessions(checkInSessionsData)
        rvIsLoading(false)
        return checkInSessionsData
    }
}

export const searchStudentByKeywords = async (keywords: string, schoolID: string): Promise<NonNullable<ListStudentsBySchoolIDQuery['listStudentsBySchoolID']>['items']> => {
    if (keywords && schoolID) {
        const cacheKey = `studentSearch-${keywords}-${schoolID}`
        const cacheData = await getCacheDataByKey(cacheKey)
        if (cacheData) {
            return JSON.parse(cacheData)
        }

        let response = await apolloClient.query<ListStudentsBySchoolIDQuery, ListStudentsBySchoolIDQueryVariables>({
            query: gql`${listStudentsBySchoolID}`,
            variables: {
                schoolID,
                filter: {
                    or: [
                        {
                            nickname: { contains: keywords.toLowerCase() },
                        },
                        {
                            firstName: { contains: keywords.toLowerCase() },
                        },
                        {
                            lastName: { contains: keywords.toLowerCase() },
                        },
                        {
                            email: { contains: keywords.toLowerCase() },
                        },
                    ]
                },
                limit: 5000,
            }
        })

        let studentsMatchedKeywords = response?.data?.listStudentsBySchoolID?.items || []

        while (response.data?.listStudentsBySchoolID?.nextToken) {
            response = await apolloClient.query<ListStudentsBySchoolIDQuery, ListStudentsBySchoolIDQueryVariables>({
                query: gql`${listStudentsBySchoolID}`,
                variables: {
                    schoolID,
                    filter: {
                        or: [
                            {
                                nickname: { contains: keywords.toLowerCase() },
                            },
                            {
                                firstName: { contains: keywords.toLowerCase() },
                            },
                            {
                                lastName: { contains: keywords.toLowerCase() },
                            },
                            {
                                email: { contains: keywords.toLowerCase() },
                            },
                        ]
                    },
                    limit: 5000,
                    nextToken: response.data.listStudentsBySchoolID.nextToken
                }
            })
            studentsMatchedKeywords = studentsMatchedKeywords.concat(response?.data?.listStudentsBySchoolID?.items || [])
        }

        await setCacheDataWithExpireTime(cacheKey, JSON.stringify(studentsMatchedKeywords))
        return studentsMatchedKeywords
    } else {
        return []
    }
}

export const searchTeacherByKeywords = async (keywords: string) => {
    if (keywords) {

        const response = await API.graphql(
            graphqlOperation(listTeachers,
                {
                    filter: {
                        or: [
                            {
                                firstName: { contains: keywords.toLowerCase() },
                            },
                            {
                                lastName: { contains: keywords.toLowerCase() },
                            },
                            {
                                cognitoUsername: { contains: keywords.toLowerCase() },
                            },
                            {
                                email: { contains: keywords.toLowerCase() },
                            },
                            {
                                preferredUsername: { contains: keywords.toLowerCase() },
                            },
                        ],
                    },
                    limit: 5000
                })) as GraphQLResult<ListTeachersQuery>
        const currentTeacher: any = response?.data?.listTeachers?.items
        return currentTeacher
    }

}

export const getStudentClassesByClassIdAndStudentId = async (studentID: string, classID: string) => {
    if (studentID && classID) {

        const response = await API.graphql(
            graphqlOperation(getStudentClassByStudentId,
                {
                    studentID,
                    classID: {
                        eq: classID
                    },
                    limit: 5000,
                })) as GraphQLResult<GetStudentClassByStudentIdQuery>
        const currentStudentClass = response?.data?.getStudentClassByStudentId?.items || []
        const filteredData = currentStudentClass?.filter(notDeletedAndNotArchivedClasses)
        return filteredData
    }

    return []
}

export const getLastStudentCheckInBySessionId = async (checkInSessionsId: string, refetch?: RefetchProps) => {
    if (checkInSessionsId) {
        const cacheKey = `listStudentCheckInsByCheckInSessionId-${checkInSessionsId}`
        const cacheData = await getCacheDataByKey(cacheKey)

        if (cacheData && !refetch?.refetch) {
            const parsedData = JSON.parse(cacheData)
            rvTeacherLastStudentCheckins(parsedData)
            return parsedData
        }
        const response = await API.graphql(
            graphqlOperation(listStudentCheckInsByCheckInSessionId,
                {
                    checkinsessionID: checkInSessionsId,
                    limit: 5000
                })) as GraphQLResult<ListStudentCheckInsByCheckInSessionIdQuery>
        const lastStudentCheckInsData: any = response?.data?.listStudentCheckInsByCheckInSessionId?.items
        rvTeacherLastStudentCheckins(lastStudentCheckInsData)
        setCacheDataWithExpireTime(cacheKey, JSON.stringify(lastStudentCheckInsData || []))
        return lastStudentCheckInsData;
    }
}

export const updateCurrentStudentCheckIn = async (studentCheckInData: any) => {
    if (studentCheckInData) {
        try {
            const response = await API.graphql(
                graphqlOperation(updateStudentCheckIn,
                    {
                        input: studentCheckInData
                    })) as GraphQLResult<UpdateStudentCheckInMutation>
            const lastStudentCheckInsData = response?.data?.updateStudentCheckIn as StudentCheckIn
            rvPreviousStudentCheckins(rvPreviousStudentCheckins().map(i => i.checkinsessionID === lastStudentCheckInsData?.checkinsessionID ? lastStudentCheckInsData : i))
            return lastStudentCheckInsData
        } catch (error) {
            console.log('updateCurrentStudentCheckIn error', error)
        }
    }
}


export const studentCheckInSubscription = (currentClassId: string, studentCheckIns: StudentCheckIn[]) => {
    if (currentClassId && studentCheckIns) {
        let sub
        const subscription = API.graphql<GraphQLSubscription<typeof onMutateStudentCheckInByClassId>>(
            {
                query: onMutateStudentCheckInByClassId,
                variables: {
                    classID: currentClassId
                }
            }
        ) as Observable<object>
        if (subscription) {
            console.log('studentCheckInSubscription subscribed')
            sub = subscription.subscribe({
                next: ({ value }: any) => {
                    console.log("Susbcription studentCheckInSubscription received:", value)

                    const receivedData = value?.data?.onMutateStudentCheckInByClassId;

                    const refetchPayload: RefetchProps = {
                        refetch: true,
                        withoutLoading: true
                    }
                    listCheckInSessionsByClassID(currentClassId, undefined, refetchPayload)
                    getLastStudentCheckInBySessionId(receivedData.checkinsessionID, refetchPayload)
                },
                error: error => {
                    console.warn("Susbcription error", error)
                }
            })
        }
        return sub
    }
}

export const studentCheckInSubscriptionByCheckInSessionId = (checkinsessionID: string) => {
    if (checkinsessionID) {
        let sub
        const subscription = API.graphql<GraphQLSubscription<typeof onMutateStudentCheckInByCheckInSessionId>>(
            {
                query: onMutateStudentCheckInByCheckInSessionId,
                variables: {
                    checkinsessionID
                }
            }
        ) as Observable<object>
        if (subscription) {
            sub = subscription.subscribe({
                next: ({ value }: any) => {
                    console.log("Susbcription studentCheckInSubscription received:", value)
                    getStudentCheckInByCheckinsessionID(checkinsessionID || "", { withoutLoading: true })
                },
                error: error => {
                    console.warn("Susbcription error", error)
                }
            })
        }
        return sub
    }
}

export const favouriteSubscriptionByCognitousername = (cognitoUsername: string) => {
    if (cognitoUsername) {
        let sub
        const subscription = API.graphql<GraphQLSubscription<typeof onMutateClassLoginByCognitoUsername>>(
            {
                query: onMutateClassLoginByCognitoUsername,
                variables: {
                    cognitoUsername
                }
            }
        ) as Observable<object>
        if (subscription) {
            sub = subscription.subscribe({
                next: ({ value }: any) => {
                    console.log("Susbcription favouriteSubscriptionByCognitousername received:", value)
                    getFavouriteByCognitousername(cognitoUsername || "")
                },
                error: error => {
                    console.warn("Susbcription favouriteSubscriptionByCognitousername error", error)
                }
            })
        }
        return sub
    }
}

export const studentCheckInSubscriptionByStudentId = (currentClassId: string, studentCheckIns: StudentCheckIn[], studentID?: string) => {
    if (currentClassId && studentID) {
        let sub
        const subscription = API.graphql<GraphQLSubscription<typeof onMutateStudentCheckInByClassIdAndStudentId>>(
            {
                query: onMutateStudentCheckInByClassIdAndStudentId,
                variables: {
                    studentID,
                    classID: currentClassId,
                }
            }
        ) as Observable<object>
        if (subscription) {
            sub = subscription.subscribe({
                next: ({ value }: any) => {
                    console.log("Susbcription studentCheckInSubscriptionByStudentId received:", value)

                    const receivedData = value?.data?.onMutateStudentCheckInByClassIdAndStudentId;
                    rvCurrentStudentLastStudentCheckIn(receivedData)
                    getCurrentCurrentStudentPrevCheckins(studentID || "", { withoutLoading: true }) // update all current student previous checkins when subscription data coming
                },
                error: error => {
                    console.warn("Susbcription error", error)
                }
            })
        }
        return sub
    }
}

export const checkInSessionSubscription = (currentClassId: string) => {
    if (currentClassId) {
        let sub
        const subscription = API.graphql<GraphQLSubscription<typeof onMutateCheckInSessionByClassId>>(
            {
                query: onMutateCheckInSessionByClassId,
                variables: {
                    classID: currentClassId
                }
            }
        ) as Observable<object>

        if (subscription) {
            sub = subscription.subscribe({
                next: async ({ value }: any) => {
                    const refetchPayload: RefetchProps = {
                        refetch: true,
                        withoutLoading: true
                    }
                    console.log("Susbcription checkInSession received:", value)
                    await listCheckInSessionsByClassID(currentClassId, undefined, refetchPayload)
                    const receivedData = value?.data?.onMutateCheckInSessionByClassId;
                    if (receivedData == null || receivedData._deleted === true || receivedData.isActive === false) {
                        getLastStudentCheckInBySessionId(receivedData.id, refetchPayload)
                        getCurrentCheckinSessionsByClassId(currentClassId || "", refetchPayload)
                        rvTeacherCurrentCheckIn(undefined)
                        return
                    }

                    getCurrentCheckinSessionsByClassId(currentClassId || "", refetchPayload)
                    getLastStudentCheckInBySessionId(receivedData.id, refetchPayload)
                    rvTeacherCurrentCheckIn(receivedData)
                },
                error: error => {
                    console.warn("Susbcription checkInSession error", error)
                }
            })
        }
        return sub
    }
}


export const studentClassSubscription = (currentClassId: string) => {
    let sub
    if (currentClassId) {
        const subscription = API.graphql<GraphQLSubscription<typeof onMutateStudentClassByClassId>>(
            {
                query: onMutateStudentClassByClassId,
                variables: {
                    classID: currentClassId
                }
            }
        ) as Observable<object>
        return subscription
    }
    return sub
}

export const studentClassSubscriptionByStudentId = (currentStudentId: string) => {
    let sub
    if (currentStudentId) {
        const subscription = API.graphql<GraphQLSubscription<typeof onMutateStudentClassByStudentId>>(
            {
                query: onMutateStudentClassByStudentId,
                variables: {
                    studentID: currentStudentId
                }
            }
        ) as Observable<object>

        if (subscription) {
            sub = subscription.subscribe({
                next: ({ value }: any) => {
                    console.log("Susbcription studentClassSubscriptionByStudentId received:", value)
                    const receivedData = value?.data?.onMutateStudentClassByStudentId;
                    getCurrentStudentClassesByStudentId(currentStudentId, { withoutLoading: true })
                },
                error: error => {
                    console.warn("Susbcription studentClassSubscriptionByStudentId error", error)
                }
            })
        }
    }
    return sub
}


export const studentSubscription = (cognitoUsername: string) => {
    let sub
    if (cognitoUsername) {
        const subscription = API.graphql<GraphQLSubscription<typeof onMutateStudentByCognitoUsername>>(
            {
                query: onMutateStudentByCognitoUsername,
                variables: {
                    cognitoUsername,
                }
            }
        )

        if (subscription) {
            sub = subscription.subscribe({
                next: ({ value }: any) => {
                    console.log("Susbcription studentSubscription received:", value)
                    const receivedData = value?.data?.onMutateStudentByCognitoUsername;
                    rvCurrentStudent(receivedData)
                },
                error: error => {
                    console.warn("Susbcription studentSubscription error", error)
                }
            })
        }
    }

    return sub
}



export const classSubscription = (classID: string) => {
    let sub
    if (classID) {
        const subscription = API.graphql<GraphQLSubscription<typeof onMutateClassById>>(
            {
                query: onMutateClassById,
                variables: {
                    id: classID,
                }
            }
        ) as Observable<object>

        if (subscription) {
            sub = subscription.subscribe({
                next: ({ value }: any) => {
                    console.log("Susbcription classSubscription received:", value)
                    const receivedData = value?.data?.onMutateClassById;
                    rvCurrentClass(receivedData)
                },
                error: error => {
                    console.warn("Susbcription classSubscription error", error)
                }
            })
        }
    }

    return sub
}

export const ratingSubscription = (userId: string) => {
    let sub
    if (userId) {
        const subscription = API.graphql<GraphQLSubscription<typeof onMutateSwitchRatingByUserId>>(
            {
                query: onMutateSwitchRatingByUserId,
                variables: {
                    userId: userId,
                }
            }
        ) as Observable<object>

        if (subscription) {
            sub = subscription.subscribe({
                next: ({ value }: any) => {
                    console.log("Susbcription ratingSubscription received:", value)
                    const receivedData = value?.data?.onMutateSwitchRatingByUserId;
                    getSwitchRatingByUserId(userId)
                },
                error: error => {
                    console.warn("Susbcription teacherClassSubscription error", error)
                }
            })
        }
    }

    return sub
}


export const classLoginsByUsernameSubscription = (currentUsername: string) => {
    let sub
    if (currentUsername) {
        const subscription = API.graphql<GraphQLSubscription<typeof onMutateClassLoginByClassID>>(
            {
                query: onMutateClassLoginByCognitoUsername,
                variables: {
                    cognitoUsername: currentUsername,
                }
            }
        ) as Observable<object>

        if (subscription) {
            sub = subscription.subscribe({
                next: ({ value }: any) => {
                    console.log("Susbcription classLoginsByUsernameSubscription received:", value)
                    const receivedData = value?.data?.onMutateClassLoginByCognitoUsername;
                    rvCurrentClassLogin(receivedData)

                },
                error: error => {
                    console.warn("Susbcription classLoginsByUsernameSubscription error", error)
                }
            })
        }
    }

    return sub
}

const cache: { [k: string]: any } = {}

export const getCacheDataByKey = async (key: string) => {
    return cache[key]
}

export const setCacheDataWithExpireTime = async (key: string, value: any) => {
    // TODO: kept for legacy code support, will replace all query with apollo client and delete this function
    cache[key] = value
}

export const updateCurrentClass = async (classData: any) => {
    try {
        rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(updateClass,
                {
                    input: classData
                })) as GraphQLResult<UpdateClassMutation>
        const studentClassDataResponse: any = response?.data?.updateClass
        return studentClassDataResponse;
    } catch (error) {
        console.log('updateCurrentClass error', error)
    } finally {
        rvIsLoading(false)
    }
}


export const updateCurrentTeacher = async (teacherData: any) => {
    try {
        rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(updateTeacher,
                {
                    input: teacherData
                })) as GraphQLResult<UpdateTeacherMutation>
        const teacherDataResponse: any = response?.data?.updateTeacher
        return teacherDataResponse;
    } catch (error) {
        console.log('updateCurrentClass error', error)
    } finally {
        rvIsLoading(false)
    }
}

export const createCurrentClassLogin = async (classLoginData: any) => {
    try {
        rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(createClassLogin,
                {
                    input: classLoginData
                })) as GraphQLResult<CreateClassLoginMutation>
        const classLoginDataResponse: any = response?.data?.createClassLogin
        return classLoginDataResponse;
    } catch (error) {
        console.log('updateCurrentClass error', error)
    } finally {
        rvIsLoading(false)
    }
}

export const createFavouriteCard = async (favouriteData: Favourite) => {
    try {
        const response = await API.graphql(
            graphqlOperation(createFavourite,
                {
                    input: favouriteData
                })) as GraphQLResult<CreateFavouriteMutation>
        const createFavouriteResponse: any = response?.data?.createFavourite
        return createFavouriteResponse;
    } catch (error) {
        console.log('createFavouriteCard error', error)
    }
}


export const getAllVideos = async () => {
    try {
        const response = await API.graphql(
            graphqlOperation(listVideos)) as GraphQLResult<ListVideosQuery>
        const listVideoReponse: any = response?.data?.listVideos?.items
        return listVideoReponse;
    } catch (error) {
        console.log('getAllVideos error', error)
    }
}

export const getAllVideosPublic = async () => {
    try {
        const response = await API.graphql({
            query: listVideos,
            variables: {},
            authMode: "API_KEY"
        }) as GraphQLResult<ListVideosQuery>
        const listVideoReponse: any = response?.data?.listVideos?.items
        return listVideoReponse;
    } catch (error) {
        console.log('getAllVideosPublic error', error)
    }
}

export const removeFavouriteCard = async (favouriteData: Favourite) => {
    try {
        const response = await API.graphql(
            graphqlOperation(deleteFavourite,
                {
                    input: favouriteData
                })) as GraphQLResult<DeleteFavouriteMutation>
        const deleteFavouriteResponse: any = response?.data?.deleteFavourite
        return deleteFavouriteResponse;
    } catch (error) {
        console.log('removeFavouriteCard error', error)
    }
}

export const getFavouriteByCognitousername = async (cognitoUsername: string) => {
    try {
        if (cognitoUsername) {
            const response = await API.graphql(
                graphqlOperation(listFavouriteByCognitoUsername,
                    {
                        cognitoUsername
                    })) as GraphQLResult<ListFavouriteByCognitoUsernameQuery>
            const getFavourtieResponse: any = response?.data?.listFavouriteByCognitoUsername?.items.filter((data) => data?._deleted !== true)
            rvFavouriteSwitches(getFavourtieResponse)

            return getFavourtieResponse;
        }
    } catch (error) {
        console.log('getFavouriteByCognitousername error', error)
    }
}


export const createCurrentStudentClass = async (studentClassData: any) => {
    try {
        rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(createStudentClass,
                {
                    input: studentClassData
                })) as GraphQLResult<CreateStudentClassMutation>
        const studentClassDataResponse: any = response?.data?.createStudentClass
        return studentClassDataResponse;
    } catch (error) {
        console.log('createCurrentStudentClass error', error)
    } finally {
        rvIsLoading(false)
    }
}

export const createCurrentSwitchRating = async (ratingData: any) => {
    try {
        const response = await API.graphql(
            graphqlOperation(createSwitchRating,
                {
                    input: ratingData
                })) as GraphQLResult<CreateSwitchRatingMutation>
        const ratingDataResponse: any = response?.data?.createSwitchRating
        return ratingDataResponse;
    } catch (error) {
        console.log('createCurrentRating error', error)
    }
}

export const updateCurrentSwitchRating = async (ratingData: any) => {
    try {
        const response = await API.graphql(
            graphqlOperation(updateSwitchRating,
                {
                    input: ratingData
                })) as GraphQLResult<UpdateSwitchRatingMutation>
        const ratingDataResponse: any = response?.data?.updateSwitchRating
        return ratingDataResponse;
    } catch (error) {
        console.log('createCurrentRating error', error)
    }
}

export const createCurrentStudentCheckIn = async (studentCheckInData: CreateStudentCheckInInput) => {
    try {
        rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(createStudentCheckIn,
                {
                    input: {
                        ...studentCheckInData,
                        createdAt: new Date().toISOString()
                    }
                })) as GraphQLResult<CreateStudentCheckInMutation>
        const studentCheckInResponse = response?.data?.createStudentCheckIn
        return studentCheckInResponse;
    } catch (error) {
        console.log('createCurrentStudentCheckIn error', error)
    } finally {
        rvIsLoading(false)
    }
}

export const updateCurrentStudent = async (studentData: any) => {
    try {
        rvIsLoading(true)
        const response = await API.graphql(
            graphqlOperation(updateStudent,
                {
                    input: studentData
                })) as GraphQLResult<UpdateStudentMutation>
        const studentResponse: any = response?.data?.updateStudent
        return studentResponse;
    } catch (error) {
        console.log('updateCurrentStudent error', error)
    } finally {
        rvIsLoading(false)
    }
}

export const CheckInSessionSubscriptionListener = () => {
    const currentClassData = useReactiveVar(rvCurrentClass)

    useEffect(() => {
        const checkInSubs = checkInSessionSubscription(currentClassData?.id || "")

        return () => {
            if (checkInSubs) {
                return checkInSubs.unsubscribe()
            }
        }
    }, [currentClassData?.id])
}

export const FavouriteSubscriptionListener = () => {
    const user = useReactiveVar(rvCurrentUser)

    useEffect(() => {
        const favSubs = favouriteSubscriptionByCognitousername(user?.username || "")

        return () => {
            if (favSubs) {
                return favSubs.unsubscribe()
            }
        }
    }, [user])
}

export const ClassSubscriptionByClassIdListener = (classID: string) => {

    useEffect(() => {
        getClassByClassId(classID)

        const classSubs = classSubscription(classID || "")

        return () => {
            if (classSubs) {
                return classSubs.unsubscribe()
            }
        }

    }, [classID])
}

export const ClassSubscriptionByStudentIdListener = (classID: string) => {
    const studentClasses = useReactiveVar(rvStudentClassClasses)

    useEffect(() => {
        getClassByClasses(studentClasses, classID, true)

        const classSubs = classSubscription(classID || "")

        return () => {
            if (classSubs) {
                return classSubs.unsubscribe()
            }
        }

    }, [classID, studentClasses])
}

export const ClassLoginsByUsernameSubscriptionListener = (currentUserName: string) => {

    useEffect(() => {
        getClasseLoginsByUsername(currentUserName || "")

        const classSubs = classLoginsByUsernameSubscription(currentUserName || "")

        return () => {
            if (classSubs) {
                return classSubs.unsubscribe()
            }
        }

    }, [currentUserName])
}

export const StudentRatingSubscriptionListener = () => {
    const currentStudent = useReactiveVar(rvCurrentStudent)

    useEffect(() => {
        getSwitchRatingByUserId(currentStudent?.id || "")

        const switchRatingSubs = ratingSubscription(currentStudent?.id || "")

        return () => {
            if (switchRatingSubs) {
                return switchRatingSubs.unsubscribe()
            }
        }

    }, [currentStudent])
}



export const StudentCheckInSubscriptionListenerByStudentId = () => {
    const currentClassData = useReactiveVar(rvCurrentClass)
    const lastStudentsCheckinData = useReactiveVar(rvTeacherLastStudentCheckins);
    const currentStudentLastCheckInData = useReactiveVar(rvCurrentStudentLastStudentCheckIn)
    const currentStudent = useReactiveVar(rvCurrentStudent)

    useEffect(() => {
        const studentCheckInSubs = studentCheckInSubscriptionByStudentId(currentClassData?.id || "", lastStudentsCheckinData, currentStudent?.id)

        return () => {
            if (studentCheckInSubs) {
                studentCheckInSubs.unsubscribe()
            }
        }

    }, [currentClassData?.id, lastStudentsCheckinData, currentStudentLastCheckInData])
}

export const StudentSubscriptionListenerByStudentId = () => {
    const user = useReactiveVar(rvCurrentUser)

    useEffect(() => {
        getCurrentStudent(user?.username || "")
        const studentSubs = studentSubscription(user?.username || "")

        return () => {
            if (studentSubs) {
                studentSubs.unsubscribe()
            }
        }

    }, [user])
}

export const StudentClassSubscriptionListenerByStudentId = () => {
    const currentStudent = useReactiveVar(rvCurrentStudent)

    useEffect(() => {
        getCurrentStudentClassesByStudentId(currentStudent?.id || "")
        const studentClassSubs = studentClassSubscriptionByStudentId(currentStudent?.id || "")

        return () => {
            if (studentClassSubs) {
                studentClassSubs.unsubscribe()
            }
        }

    }, [currentStudent])
}

export const StudentClassSubscriptionListenerByClassId = () => {
    let sub: any
    const currentClass = useReactiveVar(rvCurrentClass)
    const userGroup = useReactiveVar(rvUserGroup)

    useEffect(() => {
        getStudentClassesByClassID(currentClass?.id || "", { withoutLoading: true })
        const studentClassSubs = studentClassSubscription(currentClass?.id || "")

        if (studentClassSubs) {
            sub = studentClassSubs.subscribe({
                next: ({ value }: any) => {
                    console.log("Susbcription studentClassSubscription received:", value)
                    const receivedData = value?.data?.onMutateStudentClassByClassId as StudentClass & { _deleted?: boolean };

                    if (userGroup === UserGroup.ClassLogin && currentClass) {
                        getStudentClassesByClassID(currentClass?.id || "", { refetch: true, withoutLoading: true })
                    }

                    if (!receivedData._deleted) {
                        const studentClasses = rvStudentClassClasses()
                        if (studentClasses == null || studentClasses.length === 0) {
                            rvStudentClassClasses([receivedData])
                        } else {
                            rvStudentClassClasses(studentClasses.filter(sc => sc.id === receivedData.id).concat([receivedData]))
                        }
                    }
                },
                error: error => {
                    console.warn("Susbcription studentClassSubscription error", error)
                }
            })

        }
        return () => {
            if (sub) {
                sub.unsubscribe()
            }
        }

    }, [currentClass])
}

export const StudentInitialLoad = (classId: string) => {
    const currentStudent = useReactiveVar(rvCurrentStudent)

    useEffect(() => {
        getCurrentCheckinSessionsByClassId(classId)
        getCurrentCurrentStudentPrevCheckins(currentStudent?.id || "")

    }, [classId, currentStudent])
}

// export const StudentJoinClassHandler = () => {
//     const [studentId, setStudentId] = useState('')
//     const currentStudent = useReactiveVar(rvCurrentStudent)
//     const joinedClass = useReactiveVar(rvStudentJoinedClass)

//     useEffect(() => {
//         setStudentId(currentStudent?.id || '')
//         validateStudentJoinClass()
//     }, [currentStudent, joinedClass])

//     const validateStudentJoinClass = async () => {
//         if (studentId !== currentStudent?.id) {
//             await studentJoinClass(currentStudent, joinedClass)
//         }
//     }
// }

export const ClassLoginInitialLoad = () => {
    const currentClass = useReactiveVar(rvCurrentClass)
    const classLoginstudentClasses = useReactiveVar(rvClassLoginStudentClasses)

    useEffect(() => {
        getCurrentCheckinSessionsByClassId(currentClass?.id || "", { withoutLoading: true })

    }, [currentClass, classLoginstudentClasses])
}


export const validateAndUpdateTeacherCurrentCheckIn = (checkInSessionsData: CheckInSession[]) => {
    const anyOpenCheckInSessions = checkInSessionsData.filter((checkInSession: any) => checkInSession._deleted !== true && checkInSession.isActive)
    if (!checkInSessionsData.length) {
        // no sessions is open
        // set no currentSession is open
        rvTeacherCurrentCheckIn(undefined)
        return
    }

    if (anyOpenCheckInSessions.length) {
        // if there's a open sessions
        // assigned that session
        rvTeacherCurrentCheckIn(anyOpenCheckInSessions[0])
        return
    }

    if (checkInSessionsData[0]?.isActive === false) {
        // if last checkin wasn't active
        // set it us undefined
        rvTeacherCurrentCheckIn(undefined)
        return
    }

    rvTeacherCurrentCheckIn(checkInSessionsData[0])
}