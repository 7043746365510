import { Activities } from '../../common/activities'
import { ActivityGroupTemplate } from '../../common/activity-group-template'
import { ResilienceBuildersIcon } from '../icons/resilience-builders-icon'

export const SwitchesActivityGroupResilienceBuilders: ActivityGroupTemplate = {
    title: 'Resilience Builders',
    Icon: ResilienceBuildersIcon,
    activity: Activities.RESILIENCE_BUILDERS,
    subtitle: ''
}
