import { API } from "aws-amplify"
import { versionNumber } from "../env"
import * as Device from 'expo-device';
import { rvCurrentUser } from "../login/login-state";

type LogProps = {
    area: string
    action: string
    event: string
    payload?: object
}

export const CLIENT_EVENT = {
    INITIAL_LOAD_SUCCESS: "INITIAL_LOAD_SUCCESS",
    INITIAL_LOAD_FAILED: "INITIAL_LOAD_",
    START_CHECKIN_SUCCESS: "START_CHECKIN_SUCCESS",
    START_CHECKIN_FAILED: "START_CHECKIN_FAILED",
    END_CHECKIN_SUCCESS: "END_CHECKIN_SUCCESS",
    END_CHECKIN_FAILED: "END_CHECKIN_FAILED",
    ACCEPT_STUDENT_SUCCESS: "ACCEPT_STUDENT_SUCCESS",
    ACCEPT_STUDENT_FAILED: "ACCEPT_STUDENT_FAILED",
    DENY_STUDENT_SUCCESS: "DENY_STUDENT_SUCCESS",
    DENY_STUDENT_FAILED: "DENY_STUDENT_FAILED",
    CREATE_STUDENT_SUCCESS: "CREATE_STUDENT_SUCCESS",
    CREATE_STUDENT_FAILED: "CREATE_STUDENT_FAILED",
    CREATE_STUDENT_CLASS_SUCCESS: "CREATE_STUDENT_CLASS_SUCCESS",
    CREATE_STUDENT_CLASS_FAILED: "CREATE_STUDENT_CLASS_FAILED",
    REMOVE_STUDENT_SUCCESS: "REMOVE_STUDENT_SUCCESS",
    REMOVE_STUDENT_FAILED: "REMOVE_STUDENT_FAILED",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",
    HEADER_ACTION_EVENT: "HEADER_ACTION_EVENT",
    EDIT_PROFILE_SUCCESS: "EDIT_PROFILE_SUCCESS",
    EDIT_PROFILE_FAILED: "EDIT_PROFILE_FAILED",
    USER_SIGNED_OUT: "USER_SIGNED_OUT",
    STUDENT_START_CHECKIN_SUCCESS: "STUDENT_START_CHECKIN_SUCCESS",
    STUDENT_START_CHECKIN_FAILED: "STUDENT_START_CHECKIN_FAILED",
    STUDENT_UPDATE_CHECKIN_SUCCESS: "STUDENT_UPDATE_CHECKIN_FAILED",
    STUDENT_UPDATE_CHECKIN_FAILED: "STUDENT_UPDATE_CHECKIN_FAILED",
    CREATE_REQUEST_CHAT_SUCCESS: "CREATE_REQUEST_CHAT_SUCCESS",
    CREATE_REQUEST_CHAT_FAILED: "CREATE_REQUEST_CHAT_FAILED",
    UPDATE_STUDENT_DATA_SUCCESS: "UPDATE_STUDENT_DATA_SUCCESS",
    UPDATE_STUDENT_DATA_FAILED: "UPDATE_STUDENT_DATA_FAILED",
    SUBMIT_WELLBEING_QUESTIONS_SUCCESS: "SUBMIT_WELLBEING_QUESTIONS_SUCCESS",
    SUBMIT_WELLBEING_QUESTIONS_FAILED: "SUBMIT_WELLBEING_QUESTIONS_FAILED",
    GENERIC_ERROR: 'GENERIC_ERROR',
}
export const createClientLog = async ({ area, action, payload, event }: LogProps) => {
    const currentUser = rvCurrentUser()

    const log = {
        event,
        info: {
            area, // header, proflie menu, buttom navigation, page name (e.g. stats)
            action, // navigate to home
            payload: {
                ...payload,
                appVersion: versionNumber,
                os: Device.osName,
                osVersion: Device.osVersion,
                browserVersion: navigator ? navigator.userAgent : null,
                loggedOnUser: JSON.stringify({
                    attributes: currentUser?.attributes,
                    username: currentUser?.username,
                })
            },
        }
    }

    try {
        await API.post('LogApi', '/log', { body: log })
    } catch (error) {
        console.error('Logging is failed to track', error)
    }
}