import { Ionicons } from '@expo/vector-icons'
import { StyleSheet } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { StyleProp } from 'react-native'
import { ViewStyle } from 'react-native'

type Props = {
    onConfirm: () => void
    label?: string
    style?: StyleProp<ViewStyle>
}

const ButtonConfirm = ({ onConfirm, label = "Confirm", style }: Props) => {
    return (
        <DefaultButton style={[styles.button, style]} onPress={onConfirm}>
            <Ionicons name="ios-checkmark-sharp" size={defaultFormFontSize} color={DecidaColors.White} />
            <DefaultText style={styles.confirmText}>
                {label}
            </DefaultText>
        </DefaultButton>
    )
}
export default ButtonConfirm

const defaultFormFontSize = 14


const styles = StyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        height: 50,
        marginVertical: 10,
    },
    leftFormContainer: {
        flex: 1,
        marginRight: 5,
    },
    rightFormContainer: {
        flex: 1,
        flexDirection: 'row',
        gap: 5,
        alignItems: 'center',
    },
    button: {
        width: '100%',
        flex: 1,
        height: 25,
        flexDirection: 'row',
    },
    confirmText: {
        fontSize: defaultFormFontSize,
        color: DecidaColors.White,
    },
    input: {
        height: 25,
        borderRadius: 0,
        paddingVertical: 0,
        marginBottom: 0,
    },
    placeholderText: {
        color: '#999',
    },
})