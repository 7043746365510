import { ListTeacherClassesAndClassDetailsByTeacherIDQuery } from "../custom-graphql/queries/list-teacher-classes-and-class-details-by-teacher-id";

export const filterAndSortActiveTeacherClassTeacherClasses = (queryResult?: ListTeacherClassesAndClassDetailsByTeacherIDQuery) => {

    return queryResult?.listTeacherClassesByTeacherID?.items
        .filter((item) => item?._deleted !== true && item?.class && item?.class?._deleted !== true && item?.class?.archived !== true)
        .sort((a, b) => {
            if (a?.class?.goMode === b?.class?.goMode) {
                return (a?.class.name.toLocaleLowerCase() || '').localeCompare(b?.class.name.toLocaleLowerCase() || '')
            } else {
                if (a?.class?.goMode) {
                    return 1
                } else {
                    return -1
                }
            }
        })
        || [];
};
