import { gql, useSubscription } from '@apollo/client'
import { Feather } from '@expo/vector-icons'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { OnMutateStudentByStudentIdSubscription, OnMutateStudentByStudentIdSubscriptionVariables } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { onMutateStudentByStudentId } from '../../common/graphql/subscriptions'
import { avatars } from '../icon/avatar/avatars'
import { SchoolAdminClassNavigatorParamsList } from '../school-admin/school-admin-navigation-params'
import { StudentClassAndStudentDataFieldsTypes } from '../teacher/teacher-graphql-scripts'
import { TeacherClassNavigatorParamList } from '../teacher/teacher-route-param-types'
import { DefaultFontSize } from './const'
import { DefaultText } from './default-text'
import { ActiveStudentsType } from './list-active-students'
import { ScreenNames } from './screen-names'
import { useResponsive } from './use-responsive'

type Props = {
    item: StudentClassAndStudentDataFieldsTypes
    type: ActiveStudentsType
    classID: string
}
const ListActiveStudentItem = ({ item, type, classID }: Props) => {
    const { student } = item || {}
    const Avatar = avatars[student?.avatar as keyof typeof avatars]
    const navigation = useNavigation<NavigationProp<SchoolAdminClassNavigatorParamsList & TeacherClassNavigatorParamList, "TeacherEditStudent" | "SchoolAdminEditStudent">>()
    const { windowWidthCondition } = useResponsive()

    useSubscription<OnMutateStudentByStudentIdSubscription, OnMutateStudentByStudentIdSubscriptionVariables>(gql`${onMutateStudentByStudentId}`, { variables: { id: item?.studentID || "" }, skip: !item?.studentID })

    const goToEditStudent = (studentClassAndStudentData: StudentClassAndStudentDataFieldsTypes) => {
        if (type === ActiveStudentsType.Teacher) {
            navigation.navigate(ScreenNames.TeacherEditStudent, { studentID: studentClassAndStudentData?.studentID || "", classID })
        } else {
            navigation.navigate(ScreenNames.SchoolAdminEditStudent, { studentID: studentClassAndStudentData?.student?.id || "", classID })
        }
    }
    return (
        <TouchableOpacity key={`${item?.student?.id}`} style={type === ActiveStudentsType.Teacher ? styles.studentRow(windowWidthCondition) : styles.avatarButton} onPress={() => goToEditStudent(item)}>
            <View style={styles.avatar}>
                <TouchableOpacity style={styles.editButton} onPress={() => goToEditStudent(item)}>
                    <Feather name='edit' size={25} color={DecidaColors.Green} />
                </TouchableOpacity>
                <Avatar />
            </View>
            <View style={type === ActiveStudentsType.Teacher ? styles.textContainerTeacher(windowWidthCondition) : styles.textContainer(windowWidthCondition)}>
                <DefaultText style={type === ActiveStudentsType.Teacher ? styles.nicknameTextTeacher : styles.nicknameText}>{student?.nickname}</DefaultText>
                <DefaultText style={styles.fullNameText}>{`${student?.firstName} ${student?.lastName}`}</DefaultText>
            </View>
        </TouchableOpacity>
    )
}
export default ListActiveStudentItem

const styles = StyleSheet.create<any>({
    avatar: {
        width: 40,
        height: 40,
    },
    avatarButton: {
        alignItems: 'center',
        margin: 10,
        width: "15%",
        minWidth: 60,
        alignSelf: 'flex-start',
    },
    studentRow: (windowWidthCondition: boolean) => ({
        flexDirection: windowWidthCondition ? 'row' : 'column',
        alignItems: windowWidthCondition ? 'center' : 'center',
        justifyContent: windowWidthCondition ? 'flex-start' : 'center',
        margin: windowWidthCondition ? 5 : 10,
        width: windowWidthCondition ? undefined : "20%",
        alignSelf: 'flex-start',
    }),
    nicknameText: {
        fontSize: 14,
        color: DecidaColors.Gray,
        fontWeight: '600',
        textAlign: 'center'
    },
    fullNameText: {
        fontSize: 12,
        fontStyle: 'italic',
        color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center'
    },
    nicknameTextTeacher: {
        fontSize: DefaultFontSize,
        color: DecidaColors.Black,
        fontWeight: 'bold',
    },
    editButton: {
        position: 'absolute',
        top: -10,
        left: -10,
        zIndex: 2,
    },
    textContainerTeacher: (windowWidthCondition: boolean) => ({
        flexDirection: 'column',
        textAlign: 'center',
        width: windowWidthCondition ? undefined : '100%',
        marginLeft: windowWidthCondition ? 10 : undefined,
        alignItems: windowWidthCondition ? 'flex-start' : 'center'
    }),
    textContainer: (windowWidthCondition: boolean) => ({
        flexWrap: 'wrap',
        flexDirection: 'column',
        width: '100%',
    }),
})