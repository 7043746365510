import { CreateStudentInput, CreateTeacherInput, GetStudentByNicknameQuery, UpdateStudentInput, UpdateTeacherInput } from "../../../common/API"

export enum Sheet {
    All = 0,
    Administrator = 1,
    Teacher = 2,
    Student = 3
}

export enum ProcessingStatus {
    NotProcessed,
    Processing,
    Processed
}

export type Message = {
    sheet: Sheet,
    row?: number,
    message: string,
}

export const ALL_CLASSES = Symbol('Assign to all classes')

export type TeacherClass = (string | typeof ALL_CLASSES)
export type TeacherClasses = { classes?: TeacherClass[] }

export type TeacherToCreate = CreateTeacherInput & TeacherClasses
export type TeacherToUpdate = UpdateTeacherInput & TeacherClasses
export type OtherTeachers = { id: string } & TeacherClasses

export type StudentToCreate = CreateStudentInput & { classes?: string[] }
export type StudentToUpdate = UpdateStudentInput & { classes?: string[] }
export type OtherStudents = { id: string, classes: string[] }

export type StudentQueryItem = Exclude<Exclude<GetStudentByNicknameQuery['getStudentByNickname'], undefined | null>['items'][0], undefined | null>