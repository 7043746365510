import { StyleSheet, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { BallonBreathHeaderIcon } from './src/ballon-breath-header-icon'
import { BallonBreathIcon } from './src/ballon-breath-icon'
import { BallongBreathImage1 } from './src/ballon-breath-image-1'
import { BallongBreathImage2 } from './src/ballon-breath-image-2'
import { BallongBreathImage3 } from './src/ballon-breath-image-3'
import { BallongBreathImage4 } from './src/ballon-breath-image-4'

export const SwitchesEmotionsScaredBalonBreathing: CardTemplate = {
    cardId: "3bea8048-b654-455c-9bbe-7593eb60cf9f",
    title: 'Balloon breathing',
    Header: BallonBreathHeaderIcon,
    Icon: BallonBreathIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Those times when you are feeling nervous, scared or frustrated."} />

            <DefaulttextWithRightArrow textdata={"Imagine you have a big, colourful balloon in your tummy."} />
            <View style={styles.imageContainer}>
                <BallongBreathImage1 />
            </View>

            <DefaulttextWithRightArrow textdata={"Take a big deep breath in and imagine the balloon filling up with air, making it bigger and bigger."} />
            <View style={styles.imageContainer}>
                <BallongBreathImage2 />
            </View>

            <DefaulttextWithRightArrow textdata={"Then breath out slowly imagining the balloon getting smaller and smaller."} />
            <View style={styles.imageContainer}>
                <BallongBreathImage3 />
            </View>

            <DefaulttextWithRightArrow textdata={"Do this three more times."} />
            <View style={styles.imageContainer}>
                <BallongBreathImage4 />
            </View>
        </>
    ),
    CardContent: () => (
        <>
        </> 
    ),
    cardContentTemplate: "column",
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: "Deep breathing triggers relaxation responses, while visualisation engages the brain's visual and imaginative centres. By diverting attention from fear or anxiety through focussing on the expanding and contracting balloon, negative thought patterns can be interrupted. The technique activates the prefrontal cortex, aiding in emotional regulation, and with consistent practice, can create conditioned responses for managing fear and anxiety.",
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice the balloon breathing technique as a tool for managing emotions and promoting relaxation. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 30 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by discussing different emotions they may have felt, such as happiness, excitement, or fear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that emotions are normal, and sometimes we need techniques to help us calm down when we feel overwhelmed.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Introducing Balloon Breathing (10 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show the children a colourful balloon and draw a face on it, explaining that it will be our "calm-down balloon." </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Demonstrate the steps of balloon breathing: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Inhale deeply through the nose, imagining the balloon filling up with air and getting bigger. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Exhale slowly through the mouth, imagining the air slowly releasing from the balloon, making it smaller.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Practice Balloon Breathing (10 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give each child a balloon and marker. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them draw a face on their balloon to make it their own "calm-down balloon." </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the children to hold their balloons in their hands. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Guide them through several rounds of balloon breathing, encouraging them to inflate and deflate their balloons as they breathe.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Sharing Feelings (5 minutes): </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share how they felt after practicing balloon breathing, and encourage them to express any changes they noticed in their bodies or emotions. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that balloon breathing can be used whenever they feel scared, angry, or need to calm down. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the lesson by reminding the children of the balloon breathing technique and its purpose. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to practice balloon breathing whenever they need to calm down or manage their emotions. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the balloons to the children to take home as a reminder of the technique. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Balloons </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Markers (to draw faces on balloons) </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Optional: soft background music </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Research: Why Breathing Is So Effective at Reducing Stress -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='Research: Why Breathing Is So Effective at Reducing Stress (hbr.org)' link='https://hbr.org/2020/09/research-why-breathing-is-so-effective-at-reducing-stress' />

                        <DefaultText style={CommonStyles.lessonPlanText}>This Is Why Deep Breathing Makes You Feel so Chill -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='Deep Breathing Benefits and How-To | Right as Rain (uwmedicine.org)' link='https://rightasrain.uwmedicine.org/mind/stress/why-deep-breathing-makes-you-feel-so-chill' />
                    </>}
            />
        </>
    ),
    BottomRow: () => null,
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Balloon+breath+voice+over.m4a",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Scared]
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
})
