import { makeVar } from "@apollo/client"

// Student PopoverMenu State
export const rvShowPopoverStudent = makeVar<boolean>(false)
// Teacher PopoverMenu State
export const rvShowPopoverTeacher = makeVar<boolean>(false)
export const rvShowPopoverSchoolAdmin = makeVar<boolean>(false)
export const rvShowPopoverClasslogin = makeVar<boolean>(false)
export const rvShowPopoverNonTeachingStaff = makeVar<boolean>(false)
export const rvPopoverRequestChatStudentID = makeVar<string | null>(null)

