import { gql, useMutation, useQuery } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { API } from 'aws-amplify'
import * as Clipboard from 'expo-clipboard'
import { useState } from 'react'
import uuid from 'react-native-uuid'
import { CustomCreateStaffMutation, CustomCreateStaffMutationVariables, CustomDeleteStaffMutation, CustomDeleteStaffMutationVariables, CustomUpdateStaffMutation, CustomUpdateStaffMutationVariables, GetStaffQuery, GetStaffQueryVariables, School, Staff } from '../../common/API'
import { PrimitiveOnly } from '../../common/common-types'
import { UserGroup } from '../../common/constants'
import { generatePassword } from '../../common/generate-password'
import { customCreateStaff, customDeleteStaff, customUpdateStaff } from '../../common/graphql/mutations'
import { copyTextToClipboard } from '../common/copy-text-to-clipboard'
import { DefaultButton } from '../common/default-button'
import { CustomComponent, CustomSetter, DeleteFunction, Form, SaveFunction } from '../common/form'
import { getApiHeaders } from '../common/get-api-header'
import { ImpersonateButton } from '../common/impersonate-button'
import { InputCognitoUsername } from '../common/input-cognito-username'
import { Loading, rvIsLoading } from '../common/loading'
import { ScreenNames } from '../common/screen-names'
import { showAlert } from '../common/universal-alert'
import { adminGetStaffQuery } from '../custom-graphql/queries/admin-custom-queries/admin-get-staff-query'
import { AdminStackNavigatorParamList } from './admin-route-param-types'
import { mutateStaffRefetchQueries as refetchQueries } from './graphql-scripts'

type Props = {
    school: School
}

const AdminSchoolNonTeachingStaff = ({ route: { params: { id, schoolID } }, navigation: { navigate } }: StackScreenProps<AdminStackNavigatorParamList, "AdminNonTeachingStaff">) => {
    const { data, loading } = useQuery<GetStaffQuery, GetStaffQueryVariables>(gql`${adminGetStaffQuery}`, { variables: { id: id || '' }, skip: id == undefined })

    const [createStaffMutation] = useMutation<CustomCreateStaffMutation, CustomCreateStaffMutationVariables>(gql`${customCreateStaff}`, { refetchQueries })
    const [updateStaffMutation] = useMutation<CustomUpdateStaffMutation, CustomUpdateStaffMutationVariables>(gql`${customUpdateStaff}`, { refetchQueries })
    const [deleteStaffMutation] = useMutation<CustomDeleteStaffMutation, CustomDeleteStaffMutationVariables>(gql`${customDeleteStaff}`, { refetchQueries })

    const onSave: SaveFunction<PrimitiveOnly<Staff>> = async (state, modelInstance) => {

        if (modelInstance?.id == undefined || modelInstance.id === '') {
            const tempPassword = generatePassword()

            const staffData = (await createStaffMutation({
                variables: {
                    input: {
                        id: uuid.v4().toString(),
                        cognitoUsername: state.cognitoUsername?.value || '',
                        email: state.email?.value || '',
                        schoolID,
                        firstName: state.firstName?.value,
                        lastName: state.lastName?.value,
                        password: tempPassword,
                        preferredUsername: state.preferredUsername?.value,
                    }
                }
            })).data?.customCreateStaff!

            copyToClipboard(tempPassword)
            showAlert({
                title: 'Login Details',
                message: `Password: ${tempPassword}`,
                leftButtonText: 'Ok',
            })

            return staffData
        } else {
            return (await updateStaffMutation({
                variables: {
                    input: {
                        id: modelInstance.id,
                        email: state.email?.value || '',
                        firstName: state.firstName?.value,
                        lastName: state.lastName?.value,
                        preferredUsername: state.preferredUsername?.value,
                    }
                }
            })).data?.customUpdateStaff!
        }
    }

    const onDelete: DeleteFunction<Staff> = async (modelInstance) => {
        rvIsLoading(true)
        await deleteStaffMutation({
            variables: {
                input: {
                    staffID: modelInstance.id,
                }
            }
        })
        rvIsLoading(false)
    }

    const copyToClipboard = (password: string) => {
        Clipboard.setStringAsync(password);
    };

    const toLowerCase: CustomSetter<string | undefined> = (value, setter) => {
        setter(value?.toLowerCase())
    }

    const goToAdminSchool = () => navigate(ScreenNames.AdminSchool, { id: schoolID })

    if (loading) {
        return <Loading isLoading />
    }
    return (
        <Form
            model={data?.getStaff as Staff | undefined}
            goBack={goToAdminSchool}
            name='non teaching staff'
            onSave={onSave}
            onDelete={onDelete}
            config={{
                cognitoUsername: {
                    isOptional: true,
                    component: InputCognitoUsernameWrapper,
                    customSetter: toLowerCase,
                },
                showIntroduction: {
                    hide: true,
                    isOptional: true,
                },
                schoolID: {
                    default: schoolID,
                    hide: true,
                },
                firstName: {},
                lastName: {},
                email: {
                    customSetter: toLowerCase,
                    textInputProps: {
                        keyboardType: 'email-address',
                    },
                },
                preferredUsername: {
                    isOptional: true
                },
            }}
            additionalComponents={AdminNonteachingStaffAdditionalComponent}
        />
    )
}


const InputCognitoUsernameWrapper: CustomComponent<Staff> = ({ modelInstance, state }) => {
    const [userExists, setUserExists] = useState<boolean | null>(null)

    return (
        <InputCognitoUsername
            userExists={userExists}
            setUserExists={setUserExists}
            value={state.cognitoUsername?.value || ''}
            onChangeText={state.cognitoUsername!.setter}
            title='none'
            editable={modelInstance ? false : true}
        />
    )
}



const AdminNonteachingStaffAdditionalComponent = (nonTeachingStaff: Staff | undefined) => {
    return (
        <>
            {ResendNonTeachingStaffInviteEmail(nonTeachingStaff)}
            {nonTeachingStaff && <ImpersonateButton cognitoUsername={nonTeachingStaff.cognitoUsername} userGroup={UserGroup.NonTeachingStaff} email={nonTeachingStaff.email} />}
        </>
    )
}


const ResendNonTeachingStaffInviteEmail = (nonTeachingStaff: Staff | undefined) => {

    const resendInviteEmail = async () => {
        const tmpPassword = 'Switch_' + Math.floor(Math.random() * 1000000)
        const message = `${nonTeachingStaff?.email} has been sent new Password: ${tmpPassword} please confirm that have recieved email otherwise use temp password provided.`
        try {
            rvIsLoading(true)
            await API.post(
                'AdminQueries',
                '/resendInvitationEmail',
                {
                    body: {
                        cognitoUsername: nonTeachingStaff?.cognitoUsername,
                        password: tmpPassword,
                    },
                    headers: await getApiHeaders(),
                }
            )
            showAlert({
                title: 'Login Details',
                message: message,
                leftButtonText: 'Ok',
            })
            copyTextToClipboard(tmpPassword)
        } catch (e: any) {
            const errorMessage = e?.response?.data?.message

            showAlert({
                title: 'An error has occured.',
                message: errorMessage || String(e.message),
                leftButtonText: 'Ok',
            })
        } finally {
            rvIsLoading(false)
        }
    }

    return <DefaultButton onPress={resendInviteEmail}>Resend Invitation Email</DefaultButton>
}


export default AdminSchoolNonTeachingStaff