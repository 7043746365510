import { useReactiveVar } from '@apollo/client'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useNavigation } from '@react-navigation/native'
import { CheckInUserGroup } from '../../common/constants'
import { rvNetInfo } from '../common/common-state'
import { ScreenNames } from '../common/screen-names'
import { showOfflineAlert } from '../common/universal-alert'
import { useResponsive } from '../common/use-responsive'
import WellbeingWrapper from '../common/wellbeing-wrapper'
import { rvCurrentStudent } from '../student/use-student-state'
import { NonTeachingStaffNavigationParams } from './non-teaching-staff-navigator-params-list'
import NonTeachingStaffWellbeingStats from './non-teaching-staff-wellbeing-stats'

const NonTeachingStaffWellbeing = () => {

    const netInfoState = useReactiveVar(rvNetInfo)
    const { navigate } =
        useNavigation<DrawerNavigationProp<NonTeachingStaffNavigationParams>>();


    const startCheckIn = async () => {
        if (netInfoState?.isInternetReachable === false) return showOfflineAlert()

        rvCurrentStudent(null)
        navigate(ScreenNames.NonTeachingStaffCheckInMain, { screen: ScreenNames.CheckInTirednessScale, params: { user: CheckInUserGroup.NonTeachingStaff } })
    };


    return (
        <WellbeingWrapper startCheckIn={startCheckIn}>
            <NonTeachingStaffWellbeingStats />
        </WellbeingWrapper>
    )
}
export default NonTeachingStaffWellbeing