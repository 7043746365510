import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { StackScreenProps } from '@react-navigation/stack'
import { API } from 'aws-amplify'
import * as Clipboard from 'expo-clipboard'
import { useState } from 'react'
import { View } from 'react-native'
import { CreateTeacherMutation, CustomDeleteTeacherMutation, CustomDeleteTeacherMutationVariables, GetTeacherQuery, GetTeacherQueryVariables, Teacher, UpdateTeacherMutation, UpdateTeacherMutationVariables } from '../../common/API'
import { type PrimitiveOnly } from '../../common/common-types'
import { UserGroup } from '../../common/constants'
import { generatePassword } from '../../common/generate-password'
import { customDeleteTeacher } from '../../common/graphql/mutations'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { CustomComponent, CustomSetter, DeleteFunction, Form, FormPropState, SaveFunction } from '../common/form'
import { getApiHeaders } from '../common/get-api-header'
import { ImpersonateButton } from '../common/impersonate-button'
import { InputCognitoUsername } from '../common/input-cognito-username'
import { InputEmailTeacherAdmin } from '../common/input-email-teacher-admin'
import { Loading, rvIsLoading } from '../common/loading'
import { ScreenNames } from '../common/screen-names'
import { showAlert } from '../common/universal-alert'
import { adminGetTeacherQuery } from '../custom-graphql/queries/admin-custom-queries/admin-get-teacher-query'
import { ListTeacherClassesAndClassDetailsByTeacherIDQuery, listTeacherClassesAndClassDetailsByTeacherID } from '../custom-graphql/queries/list-teacher-classes-and-class-details-by-teacher-id'
import { CONTAINER_INPUT_WIDTH } from './admin-consts'
import { AdminStackNavigatorParamList } from './admin-route-param-types'
import { mutateTeacherRefetchQueries as refetchQueries, updateTeacherMutation } from './graphql-scripts'

export const AdminTeacher = ({ route: { params: { id, schoolID } }, navigation: { navigate } }: StackScreenProps<AdminStackNavigatorParamList, 'AdminTeacher'>) => {
    const { data, loading } = useQuery<GetTeacherQuery, GetTeacherQueryVariables>(gql`${adminGetTeacherQuery}`, { variables: { id: id || '' }, skip: id == undefined })

    const [updateTeacher] = useMutation<UpdateTeacherMutation, UpdateTeacherMutationVariables>(gql`${updateTeacherMutation}`, { refetchQueries })
    const [deleteTeacher] = useMutation<CustomDeleteTeacherMutation, CustomDeleteTeacherMutationVariables>(gql`${customDeleteTeacher}`, { refetchQueries })
    const apolloClient = useApolloClient()

    if (loading) {
        return <Loading isLoading />
    }

    const goToAdminSchool = () => navigate(ScreenNames.AdminSchool, { id: schoolID })

    const onSave: SaveFunction<PrimitiveOnly<Teacher>> = async (state: FormPropState<Teacher>, modelInstance) => {
        if (modelInstance && modelInstance.cognitoUsername && state.preferredUsername?.value) {
            state.cognitoUsername = {
                value: state.cognitoUsername?.value || '',
                setter: () => { }
            }
            updatePreferredUsername(modelInstance, state)
        }

        if (modelInstance?.id == undefined || modelInstance.id === '') {
            const tempPassword = generatePassword()

            state.cognitoUsername = {
                value: state.cognitoUsername?.value || state.email?.value || '',
                setter: () => { }
            }
            state.showIntroduction = {
                value: true,
                setter: () => { }
            }

            const result = await API.post(
                'AdminQueries',
                '/createTeacher',
                {
                    headers: await getApiHeaders(),
                    body: {
                        email: state.email?.value,
                        schoolID,
                        firstName: state.firstName?.value,
                        lastName: state.lastName?.value,
                        cognitoUsername: state.cognitoUsername?.value,
                        preferredUsername: state.preferredUsername?.value,
                        password: tempPassword,
                        createTeacherRecord: true,
                        showNewFeatureInstructions: false
                    },
                }
            ) as GraphQLResult<CreateTeacherMutation>

            if (result.errors) {
                throw result.errors
            } else if ((result as any).message) {
                throw (result as any).message
            }

            apolloClient.refetchQueries({
                include: ['GetListTeacherBySchoolID']
            })

            copyToClipboard(tempPassword)
            showAlert({
                title: 'Login Details',
                message: `Password: ${tempPassword}`,
                leftButtonText: 'Ok',
            })

            return result.data?.createTeacher!
        } else {
            return (await updateTeacher({
                variables: {
                    input: {
                        id: modelInstance.id,
                        cognitoUsername: state.cognitoUsername?.value || '',
                        email: state.email?.value || '',
                        schoolID: modelInstance.schoolID,
                        _version: modelInstance._version,
                        firstName: state.firstName?.value,
                        lastName: state.lastName?.value,
                        preferredUsername: state.preferredUsername?.value
                    }
                }
            })).data?.updateTeacher!
        }
    }

    const onDelete: DeleteFunction<Teacher> = async (modelInstance) => {
        if (modelInstance.id) {
            await deleteTeacher({
                variables: {
                    input: {
                        teacherID: modelInstance.id,
                    }
                }
            })
        }
    }

    return (
        <Form
            model={data?.getTeacher as Teacher | undefined}
            goBack={goToAdminSchool}
            name='teacher'
            onSave={onSave}
            onDelete={onDelete}
            config={{
                cognitoUsername: {
                    isOptional: true,
                    component: InputCognitoUsernameWrapper,
                },
                showIntroduction: {
                    hide: true,
                    isOptional: true,
                },
                schoolID: {
                    default: schoolID,
                    hide: true,
                },
                firstName: {},
                lastName: {},
                email: {
                    component: InputEmailTeacherWrapper,
                    customSetter: toLowerCase,
                    textInputProps: {
                        autoComplete: 'email',
                    },
                },
                preferredUsername: {
                    component: InputPreferredUsernameWrapper,
                    isOptional: true
                }
            }}
            additionalComponents={AdminTeacherAdditionalComponent}
        />
    )
}

const InputCognitoUsernameWrapper: CustomComponent<Teacher> = ({ modelInstance, state }) => {
    const [userExists, setUserExists] = useState<boolean | null>(null)

    return (
        <InputCognitoUsername
            userExists={userExists}
            setUserExists={setUserExists}
            value={state.cognitoUsername?.value || ''}
            onChangeText={state.cognitoUsername!.setter}
            title='none'
            editable={modelInstance ? false : true}
        />
    )
}

const InputEmailTeacherWrapper: CustomComponent<Teacher> = ({ state }) =>
    <InputEmailTeacherAdmin
        value={state.email?.value || ''}
        onChangeText={state.email!.setter}
        cognitoUsername={state.cognitoUsername?.value || ""}
    />

const InputPreferredUsernameWrapper: CustomComponent<Teacher> = ({ state }) => {
    const [userExists, setUserExists] = useState<boolean | null>(null)

    return (
        <InputCognitoUsername
            userExists={userExists}
            setUserExists={setUserExists}
            value={state.preferredUsername?.value || ''}
            onChangeText={state.preferredUsername!.setter}
            title='none'
            placeholder=''
            editable
        />
    )
}

const copyToClipboard = (password: string) => {
    Clipboard.setStringAsync(password);
};

const toLowerCase: CustomSetter<string> = (value, setter) => {
    setter(value?.toLowerCase())
}

const updatePreferredUsername = async (teacher: Teacher | undefined, state: FormPropState<Teacher>) => {
    await API.post(
        'AdminQueries',
        '/updatePreferredUsername',
        {
            body: {
                username: state.preferredUsername?.value,
                cognitoUsername: teacher?.cognitoUsername
            },
            headers: await getApiHeaders(),
        }
    )
}

const AdminTeacherAdditionalComponent = (teacher: Teacher | undefined) => {
    return (
        <>
            {TeacherClassesListComponent(teacher)}
            {ResendTeacherInviteEmail(teacher)}
            {teacher && <ImpersonateButton cognitoUsername={teacher.cognitoUsername} userGroup={UserGroup.Teacher} email={teacher.email} />}
        </>
    )
}

const TeacherClassesListComponent = (teacher: Teacher | undefined) => {
    const { data } = useQuery<ListTeacherClassesAndClassDetailsByTeacherIDQuery>(gql`${listTeacherClassesAndClassDetailsByTeacherID}`, { variables: { teacherID: teacher?.id } })
    const teacherClasses = data?.listTeacherClassesByTeacherID?.items.filter((teacherClass) => teacherClass?._deleted !== true)

    return (
        <View style={{ flex: 1 }}>
            <DefaultText>
                Classes:
            </DefaultText>
            <View style={{ flex: 1, flexWrap: 'wrap', width: CONTAINER_INPUT_WIDTH, marginVertical: 10 }}>
                {teacherClasses?.map((tc) => {
                    const claz = tc?.class
                    if (!claz) {
                        return null
                    }

                    if (claz._deleted || claz.archived) {
                        // do not show if claz has been deleted or archived
                        return null
                    }

                    return <DefaultText key={claz?.id} style={{ marginBottom: 10 }}>• {claz?.name}</DefaultText>
                })}
            </View>
        </View>
    )
}

const ResendTeacherInviteEmail = (teacher: Teacher | undefined) => {

    const resendInviteEmail = async () => {
        const tmpPassword = 'Switch_' + Math.floor(Math.random() * 1000000)
        const message = `${teacher?.email} has been sent new Password: ${tmpPassword} please confirm that have recieved email otherwise use temp password provided.`
        try {
            rvIsLoading(true)
            await API.post(
                'AdminQueries',
                '/resendInvitationEmail',
                {
                    body: {
                        cognitoUsername: teacher?.cognitoUsername,
                        password: tmpPassword,
                    },
                    headers: await getApiHeaders(),
                }
            )
            copyToClipboard(tmpPassword)
            showAlert({
                title: 'Login Details',
                message: message,
                leftButtonText: 'Ok',
            })
        } catch (e: any) {
            const errorMessage = e?.response?.data?.message

            showAlert({
                title: 'An error has occured.',
                message: errorMessage || String(e.message),
                leftButtonText: 'Ok',
            })
        } finally {
            rvIsLoading(false)
        }
    }

    return <DefaultButton onPress={resendInviteEmail}>Resend Invitation Email</DefaultButton>
}
