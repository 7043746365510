import _ from 'lodash'
import { EatingStatus, Emotion, RequestForChatStatus } from "./API";

export type EmotionCode = 'H' | 'E' | 'C' | 'S' | 'A' | 'N'

export type StudentCheckInCacheObject = {
    checkInSessionClassSequenceNo: number | null | undefined,
    emotion?: Emotion | null,
    emotionIntensityPercentage?: number | null,
    tiredness?: number | null,
    absence?: boolean | null,
    requestForChat?: RequestForChatStatus | null,
    chatCleared?: boolean | null,
    eating?: EatingStatus | null
}

export const convertEmotionToEmotionCode = (emotion: Emotion | undefined | null): EmotionCode | null => {
    switch (emotion) {
        case Emotion.HAPPY:
            return 'H'
        case Emotion.EXCITED:
            return 'E'
        case Emotion.SAD:
            return 'S'
        case Emotion.ANGRY:
            return 'A'
        case Emotion.SCARED:
            return 'C'
        case Emotion.ANXIOUS:
            return 'N'
        case null:
        case undefined:
            return null
        default:
            throw `invalid emotion ${emotion}`
    }
}

export const convertEmotionCodeToEmotion = (emotionCode: EmotionCode | undefined | null): Emotion => {
    switch (emotionCode) {
        case 'H':
            return Emotion.HAPPY
        case 'E':
            return Emotion.EXCITED
        case 'S':
            return Emotion.SAD
        case 'A':
            return Emotion.ANGRY
        case 'C':
            return Emotion.SCARED
        case 'N':
            return Emotion.ANXIOUS
        default:
            throw `invalid emotion code ${emotionCode}`
    }
}

export const convertEmotionIntensityPercentageToPercentageCode = (emotionIntensityPercentage: number): string => {
    if (typeof emotionIntensityPercentage !== 'number' || isNaN(emotionIntensityPercentage)) {
        throw 'emotionIntensityPercentage must be a number'
    }

    if (emotionIntensityPercentage < 0 || emotionIntensityPercentage > 1) {
        throw 'emotionIntensityPercentage must be betweenn 0 and 1'
    }

    const value = Math.round(emotionIntensityPercentage * 100);
    return value === 100 ? `${value}` : value < 10 ? `00${value}` : `0${value}`;
}

export const convertRequestForChatAndClearedStatusToCode = ({ requestForChat, chatCleared }: Pick<StudentCheckInCacheObject, 'requestForChat' | 'chatCleared'>): string => {
    if (requestForChat === RequestForChatStatus.YES) {
        if (chatCleared) {
            return ERequestForChatAndClearedCode.YesAndCleared
        } else {
            return ERequestForChatAndClearedCode.YesAndNotCleared
        }
    }

    if (requestForChat === RequestForChatStatus.NOT_YET) {
        if (chatCleared) {
            return ERequestForChatAndClearedCode.NotYetAndCleared
        } else {
            return ERequestForChatAndClearedCode.NotYetAndNotCleared
        }
    }

    if (chatCleared) {
        return ERequestForChatAndClearedCode.NoAndCleared
    }

    return ERequestForChatAndClearedCode.NoAndNotCleared
}

export const convertEatingStatusToCode = ({ eating }: Pick<StudentCheckInCacheObject, 'eating'>): string => {
    if (eating === EatingStatus.YES) {
        return EEatingCode.Yes
    }

    if (eating === EatingStatus.NO) {
        return EEatingCode.No
    }

    if (eating === EatingStatus.PREFER_NOT_TO_SAY) {
        return EEatingCode.PreferNotToSay
    }

    return EEatingCode.NoValue
}

export enum EEatingCode {
    Yes = 'B',
    No = 'b',
    PreferNotToSay = 'p',
    NoValue = '',
}

export enum ERequestForChatAndClearedCode {
    YesAndNotCleared = 'Y',
    YesAndCleared = 'y',
    NotYetAndNotCleared = 'N',
    NotYetAndCleared = 'n',
    NoAndNotCleared = '',
    NoAndCleared = 'c',
}

export const isRequestForChatAndClearedCode = (cacheCode: string = "") => {
    if (cacheCode.length > 1) {
        throw 'code length must be 1 or 0'
    }

    return _.values(ERequestForChatAndClearedCode).indexOf(cacheCode as ERequestForChatAndClearedCode) > -1
}

export const convertEatingCodeToStatus = (code: string): Pick<StudentCheckInCacheObject, 'eating'> => {
    switch (code) {
        case EEatingCode.Yes:
            return {
                eating: EatingStatus.YES
            }
        case EEatingCode.No:
            return {
                eating: EatingStatus.NO
            }
        case EEatingCode.PreferNotToSay:
            return {
                eating: EatingStatus.PREFER_NOT_TO_SAY
            }
        case EEatingCode.NoValue: {
            return {
                eating: null
            }
        }

        default:
            throw `Unsupported eating code "${code}"`
    }
}

export const convertRequestForChatAndClearedCodeToStatus = (code: string): Pick<StudentCheckInCacheObject, 'requestForChat' | 'chatCleared'> => {
    if (!isRequestForChatAndClearedCode(code)) {
        throw 'invalid RequestForChatAndClearedCode'
    }

    switch (code) {
        case ERequestForChatAndClearedCode.YesAndNotCleared:
            return {
                requestForChat: RequestForChatStatus.YES,
                chatCleared: false,
            }
        case ERequestForChatAndClearedCode.YesAndCleared:
            return {
                requestForChat: RequestForChatStatus.YES,
                chatCleared: true,
            }
        case ERequestForChatAndClearedCode.NotYetAndNotCleared:
            return {
                requestForChat: RequestForChatStatus.NOT_YET,
                chatCleared: false,
            }
        case ERequestForChatAndClearedCode.NotYetAndCleared:
            return {
                requestForChat: RequestForChatStatus.NOT_YET,
                chatCleared: true,
            }
        case ERequestForChatAndClearedCode.NoAndCleared:
            return {
                requestForChat: RequestForChatStatus.NO,
                chatCleared: true,
            }
        case ERequestForChatAndClearedCode.NoAndNotCleared:
            return {
                requestForChat: RequestForChatStatus.NO,
                chatCleared: false,
            }
        default:
            throw `Unsupported chat request code "${code}"`
    }
}

export const convertStudentCheckInCachObjectToCacheCode = ({ checkInSessionClassSequenceNo, emotion, emotionIntensityPercentage, tiredness, absence, requestForChat, chatCleared, eating }: StudentCheckInCacheObject): string => {
    if (typeof checkInSessionClassSequenceNo !== 'number') {
        throw 'checkInSessionClassSequenceNo must be a number'
    }

    const noCheckIns = !absence && typeof emotion !== 'string' && (typeof emotionIntensityPercentage !== 'number' || Number.isNaN(emotionIntensityPercentage)) && (typeof tiredness !== 'number' || Number.isNaN(tiredness));
    if (noCheckIns) {
        return `${checkInSessionClassSequenceNo}${convertRequestForChatAndClearedStatusToCode({ requestForChat, chatCleared })}`
    }

    if (absence) {
        return `a${checkInSessionClassSequenceNo}`
    }

    if (typeof emotion !== 'string') {
        throw 'emotion must be defined when absence is not true'
    }

    if (typeof emotionIntensityPercentage !== 'number') {
        throw 'emotionIntensityPercentage must be a number when absence is not true'
    }

    if (emotionIntensityPercentage < 0 || emotionIntensityPercentage > 1) {
        throw 'emotionIntensityPercentage must be betweenn 0 and 1'
    }

    if (typeof tiredness !== 'number') {
        throw 'tiredness must be a number when absence is not true'
    }

    if (tiredness > 9) {
        throw 'tiredness must be a single digit'
    }

    return `${convertEmotionToEmotionCode(emotion)}${convertEmotionIntensityPercentageToPercentageCode(emotionIntensityPercentage)}${tiredness}${checkInSessionClassSequenceNo}${convertRequestForChatAndClearedStatusToCode({ requestForChat, chatCleared })}${convertEatingStatusToCode({ eating })}`
}

export const convertStudentCheckInCacheCodeToCacheObject = (cacheCode: string): StudentCheckInCacheObject => {
    if (typeof cacheCode !== 'string') {
        throw 'cacheCode must be a string: ' + cacheCode
    }

    if (cacheCode === '') {
        throw 'cacheCode must not be an empty string'
    }

    const noCheckIns = !isNaN(Number(cacheCode[0]));

    if (noCheckIns) {

        const [, checkInSessionClassSequenceNo, requestForChatCacheCode = ''] = cacheCode.match(/^(\d+)(\D?)/) || [];
        if (checkInSessionClassSequenceNo) {
            return {
                checkInSessionClassSequenceNo: Number(checkInSessionClassSequenceNo),
                ...convertRequestForChatAndClearedCodeToStatus(requestForChatCacheCode)
            }
        }
    }

    if (
        cacheCode[0] !== 'a'
        && cacheCode[0] !== 'H'
        && cacheCode[0] !== 'E'
        && cacheCode[0] !== 'S'
        && cacheCode[0] !== 'A'
        && cacheCode[0] !== 'C'
        && cacheCode[0] !== 'N'
    ) {
        throw 'cacheCode must start with "a" or an emotion code: ' + cacheCode
    }


    if (!cacheCode.substring(1).match(/^[0-9]+[YyNnCcBbp]*$/)) {
        throw 'cacheCode must not contain any non-digit character after index 0 that are not request for chat and/or eating code: ' + cacheCode
    }

    if (cacheCode[0] === 'a') {
        console.log({ cacheCode })
        if (cacheCode.length < 2) {
            throw '"a" must be followed by at least 1 digit: ' + cacheCode
        }

        return {
            checkInSessionClassSequenceNo: Number(cacheCode.substring(1)),
            absence: true
        }
    }

    if (cacheCode.length < 6) {
        throw 'emotion code must be followed by at least 6 digits: ' + cacheCode
    }

    const lastDigit = _.findLastIndex(cacheCode, char => /[0-9]/.test(char))
    const isNextDigitChatRequest = isRequestForChatAndClearedCode(cacheCode[lastDigit + 1])
    const hasRequestForChatAndEatingCode = cacheCode[lastDigit + 2]

    return {
        checkInSessionClassSequenceNo: Number(cacheCode.substring(5, lastDigit + 1)),
        emotion: convertEmotionCodeToEmotion(cacheCode[0]),
        emotionIntensityPercentage: Number(cacheCode.substring(1, 4)) / 100,
        tiredness: Number(cacheCode[4]),
        absence: false,
        ...(hasRequestForChatAndEatingCode ? {
            ...convertRequestForChatAndClearedCodeToStatus(cacheCode[lastDigit + 1] || ''),
            ...convertEatingCodeToStatus(cacheCode[lastDigit + 2])
        } : isNextDigitChatRequest ?
            convertRequestForChatAndClearedCodeToStatus(cacheCode[lastDigit + 1] || '') : 
            convertEatingCodeToStatus(cacheCode[lastDigit + 1]))
    }
}