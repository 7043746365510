import { makeVar, useReactiveVar } from '@apollo/client'
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import { OfflineMessage } from './const'
import { DefaultText } from './default-text'
import * as Clipboard from 'expo-clipboard';
import { CLIENT_EVENT, createClientLog } from './log-client-event'
import { handleRestartApp } from './handle-restart-app';
import DefaultModal from './default-modal';
import { DecidaColors } from '../../common/decida-colors';

type AlertProps = {
    title?: string
    message?: string | JSX.Element
    leftButtonText: string
    onLeftButtonPress?: () => void
    rightButtonText?: string
    onRightButtonPress?: () => void
    alertStyle?: ViewStyle
}

export const showAlert = makeVar<AlertProps | undefined>(undefined)

export const showGenericErrorAlert = () => {
    showAlert({
        title: 'An error has occured.',
        message: 'Please try again later or contact an admin.',
        leftButtonText: 'Ok',
        rightButtonText: 'Retry',
        onRightButtonPress: handleRestartApp
    })
}

export const showAbsenceWarnAlert = ({ onLeftButtonPress }: { onLeftButtonPress: () => void }) => {
    showAlert({
        title: 'Wait!',
        message: "The students selected as absent won't be saved if you don't select Done, \n are you sure you want to continue?",
        leftButtonText: "Yes",
        onLeftButtonPress: onLeftButtonPress,
        rightButtonText: "Cancel"
    })
}

export const showPasswordResetAlert = (tempPassword: string, nickname: string) => {
    try {
        Clipboard.setStringAsync(tempPassword);
    } catch (e) {
        console.log(e)
        createClientLog({
            area: 'showPasswordResetAlert',
            action: 'copy temp password to clipboard',
            event: CLIENT_EVENT.RESET_PASSWORD_SUCCESS,
            payload: {
                nickname
            },
        })
    }
    showAlert({
        title: `${nickname}'s password has been reset.`,
        message: `\nPassword is: ${tempPassword}\n\nThe password has been copied to your clipboard, please send it to ${nickname}. Once you have closed this alert you will not be able to see the temporary password again.`,
        leftButtonText: 'Ok',
    })
}

export const showPasswordImportAlert = (tempPassword: string) => {
    try {
        Clipboard.setStringAsync(tempPassword);
    } catch (e) {
        console.log(e)
    }
    showAlert({
        title: `All data imported successfully`,
        message: `\nPassword is: ${tempPassword}\n\nThe password has been copied to your clipboard, please noted. Once you have closed this alert you will not be able to see the temporary password again.`,
        leftButtonText: 'Ok',
    })
}

export const showOfflineAlert = (callback?: () => void) => {
    showAlert({
        title: "You're offline!",
        message: OfflineMessage,
        leftButtonText: "Try again",
        onLeftButtonPress: () => {
            if (callback) return setTimeout(callback, 500)

            handleRestartApp()
        },
    })
}

export const UniversalAlert = () => {
    const alertProps = useReactiveVar(showAlert)

    if (alertProps === undefined) {
        return null
    }

    const {
        title,
        message,
        leftButtonText,
        onLeftButtonPress,
        rightButtonText,
        onRightButtonPress,
        alertStyle
    } = alertProps

    const leftButtonPress = () => {
        onLeftButtonPress && onLeftButtonPress()
        showAlert(undefined)
    }

    const rightButtonPress = () => {
        onRightButtonPress && onRightButtonPress()
        showAlert(undefined)
    }

    return (
        <DefaultModal contentStyle={styles.overlay}>
            <View style={[styles.alert, alertStyle]}>
                <View style={styles.messageContainer}>
                    {title &&
                        <DefaultText style={styles.title}>
                            {title}
                        </DefaultText>
                    }
                    {
                        typeof message === 'string' && (
                            <DefaultText style={styles.message}>
                                {message}
                            </DefaultText>
                        ) || message
                    }
                </View>
                <View testID={`modal-${title}`} style={styles.buttonRow}>
                    <TouchableOpacity testID={`button-modal-left-${leftButtonText}-${title}`} style={styles.leftButton} onPress={leftButtonPress}>
                        <DefaultText style={styles.leftButtonText}>
                            {leftButtonText}
                        </DefaultText>
                    </TouchableOpacity>
                    {rightButtonText &&
                        <TouchableOpacity testID={`button-modal-right-${rightButtonText}-${title}`} style={styles.rightButton} onPress={rightButtonPress}>
                            <DefaultText style={styles.rightButtonText}>
                                {rightButtonText}
                            </DefaultText>
                        </TouchableOpacity>
                    }
                </View>
            </View>
        </DefaultModal>
    )
}

const styles = StyleSheet.create({
    overlay: {
        position: 'absolute',
        zIndex: 199,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
    },
    background: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: DecidaColors.Gray,
        opacity: 0.8
    },
    alert: {
        zIndex: 100,
        maxWidth: 350,
        borderRadius: 10,
        backgroundColor: DecidaColors.White,
        opacity: 1,
        margin: 10,
    } as ViewStyle,
    messageContainer: {
        padding: 20,
    },
    title: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    message: {
        fontSize: 16,
        textAlign: 'center',
    },
    buttonRow: {
        flexDirection: 'row',
        zIndex: 101
    },
    leftButton: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderTopWidth: 1,
        borderTopColor: DecidaColors.LightGray,
        padding: 20,
    },
    leftButtonText: {
        color: DecidaColors.DarkBlue,
    },
    rightButton: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderTopWidth: 1,
        borderTopColor: DecidaColors.LightGray,
        borderLeftWidth: 1,
        borderLeftColor: DecidaColors.LightGray,
        padding: 20,
    },
    rightButtonText: {
        color: DecidaColors.Red,
    },
})