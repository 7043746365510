import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { DecidaColors } from "../../common/decida-colors"
import StepperDot from '../common/stepper-dot'
import { StudentAndNonTeachingStaffOnBoardingStep1, StudentAndNonTeachingStaffOnBoardingStep2, StudentAndNonTeachingStaffOnBoardingStep3, StudentAndNonTeachingStaffOnBoardingStep4, StudentAndNonTeachingStaffOnBoardingStep5, StudentAndNonTeachingStaffOnBoardingStep6, StudentAndNonTeachingStaffOnBoardingStep7 } from '../common/student-and-non-teaching-staff-onboarding-steps'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { updateStudent } from '../../common/graphql/mutations'
import { Student, UpdateStudentMutation } from '../../common/API'
import { rvCurrentStudent } from './use-student-state'
import { getStudentByStudentId } from '../common/helper-api-functions'
import { TouchableOpacity } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import DefaultModal from '../common/default-modal'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { StudentMainNavigatorParamList } from './student-route-param-types'
import { ScreenNames } from '../common/screen-names'

const StudentModalOnboarding = () => {
    const [stepIndex, setStepIndex] = useState(0)
    const totalSteps = 7
    const lastStep = stepIndex + 1 === totalSteps
    const currentStudent = useReactiveVar(rvCurrentStudent) as unknown as Student
    const [updateStudentMutation] = useMutation<UpdateStudentMutation>(gql`${updateStudent}`)
    const { navigate } = useNavigation<NavigationProp<StudentMainNavigatorParamList>>()

    const handleNext = async () => {
        if (lastStep) {
            await setStudentOnboardingCompleteStatus()
            return
        }

        if (stepIndex >= 0) {
            setStepIndex(prev => prev + 1)
        }
    }

    const renderContent = () => {
        switch (stepIndex) {
            case 0:
                return <StudentAndNonTeachingStaffOnBoardingStep1 handleNext={handleNext} />
            case 1:
                return <StudentAndNonTeachingStaffOnBoardingStep2 handleNext={handleNext} />
            case 2:
                return <StudentAndNonTeachingStaffOnBoardingStep3 handleNext={handleNext} />
            case 3:
                return <StudentAndNonTeachingStaffOnBoardingStep4 handleNext={handleNext} />
            case 4:
                return <StudentAndNonTeachingStaffOnBoardingStep5 handleNext={handleNext} />
            case 5:
                return <StudentAndNonTeachingStaffOnBoardingStep6 handleNext={handleNext} />
            case 6:
                return <StudentAndNonTeachingStaffOnBoardingStep7 handleNext={handleNext} />

            default:
                break;
        }
    }

    const setStudentOnboardingCompleteStatus = async () => {
        const { data } = await updateStudentMutation({
            variables: {
                input: {
                    id: currentStudent?.id,
                    onboarded: true,
                    _version: currentStudent?._version
                }
            }
        })
        if (data && data?.updateStudent) {
            await getStudentByStudentId(data.updateStudent.id)
        }
        navigate(ScreenNames.StudentHome)
    }

    const handlePressDot = (index: number) => {
        setStepIndex(index)
    }

    return (
        <View>
            <DefaultModal>
                <View style={styles.modalHeaderContainer}>
                    <View />
                    <StepperDot total={totalSteps} currentStepIndex={stepIndex} onPressDot={handlePressDot} />
                    <TouchableOpacity style={styles.closeIcon} onPress={() => setStudentOnboardingCompleteStatus()}>
                        <AntDesign name="close" size={28} color={DecidaColors.Gray} />
                    </TouchableOpacity>
                </View>
                <View>
                    {renderContent()}
                </View>
            </DefaultModal>
        </View >
    )
}
export default StudentModalOnboarding

const styles = StyleSheet.create({
    modalBackground: {
        backgroundColor: "rgba(0,0,0,0.7)",
        height: '100%',
        width: '100%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    modalContentContainer: {
        backgroundColor: DecidaColors.White,
        width: '80%',
        maxWidth: 800,
        height: 400,
        paddingTop: 20,
        borderRadius: 20
    },
    modalHeaderContainer: {
        alignItems: 'center',
        paddingBottom: 10,
        justifyContent: 'space-between',
        borderBottomColor: DecidaColors.Gray
    },
    modalContainer: {
        alignContent: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    buttonsContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 20,
    },
    closeIcon: {
        position: 'absolute',
        right: 10,
        paddingHorizontal: 10,
    },
    closeText: {
        fontSize: 16
    },
})