import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { DefaultText } from '../../common/default-text';
import { rvCurrentUser, rvUserGroup } from '../../login/login-state';
import { SwitchRatingType, UserType } from '../../models';
import { rvCurrentStudent } from '../../student/use-student-state';
import { ThumbDown } from '../image-source/thumb-down';
import { ThumbDownSelected } from '../image-source/thumb-down-selected';
import { ThumbNeutral } from '../image-source/thumb-neutral';
import { ThumbNeutralSelected } from '../image-source/thumb-neutral-selected';
import { ThumbUp } from '../image-source/thumb-up';
import { ThumbUpSelected } from '../image-source/thumb-up-selected';
import { CardTemplate } from './card-template';
// import { Storage } from 'aws-amplify';
// import uuid from 'react-native-uuid'
import { DeleteSwitchRatingMutation, GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables, SwitchRating, Teacher } from '../../../common/API';
import { UserGroup } from '../../../common/constants';
import { createCurrentSwitchRating, getSwitchRatingByUserId, updateCurrentSwitchRating } from '../../common/helper-api-functions';
import { deleteSwitchRating } from '../../../common/graphql/mutations';
import { getTeacherByCognitoUsername } from '../../../common/graphql/queries';
import { useNonTeachingStaffState } from '../../student/use-non-teaching-staff-state';

interface Props {
    rating: SwitchRating | undefined
    card: CardTemplate
}

export const SwitchCardRater = ({ rating, card }: Props) => {

    const user = useReactiveVar(rvCurrentUser)

    const { data: teacherResponse } = useQuery<GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables>(gql`${getTeacherByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
    const { currentNonTeachingStaff } = useNonTeachingStaffState()
    const currentTeacher = teacherResponse?.getTeacherByCognitoUsername?.items[0] as Teacher
    const currentStudent = useReactiveVar(rvCurrentStudent)
    const userGroup = useReactiveVar(rvUserGroup)
    const currentUser = userGroup === UserGroup.Teacher ? currentTeacher : userGroup === UserGroup.NonTeachingStaff ? currentNonTeachingStaff : currentStudent
    const userType = userGroup === UserGroup.Teacher ? UserType.TEACHER : UserType.STUDENT
    const selection = rating?.rating

    const [deleteSwitchRatingMutation] = useMutation<DeleteSwitchRatingMutation>(gql`${deleteSwitchRating}`,)

    if (!currentUser) {
        return null
    }

    const createRating = async (selectedRating: SwitchRatingType) => {
        try {
            if (rating) {
                if (selection === selectedRating) {
                    removeSwitchRating()
                    return
                }

                const { id, _version } = rating
                const payloadUpdate = {
                    id,
                    _version,
                    cardId: rating.cardId,
                    rating: selectedRating,
                    userType: rating.userType,
                    userId: rating.userId
                }
                await updateCurrentSwitchRating(payloadUpdate)

            } else {
                const payloadNewRating = {
                    cardId: card.cardId,
                    rating: selectedRating,
                    userType: userType,
                    userId: currentUser.id
                }
                await createCurrentSwitchRating(payloadNewRating)
            }

            await getSwitchRatingByUserId(currentUser.id)

            // const fileName = `SwitchRating-${currentTime}-${userType}-${selectedRating}`
            // const s3Data = {
            //     id: uuid.v4().toString(),
            //     time: currentTime,
            //     currentUser: currentUser,
            //     userType: userType,
            //     selection: selectedRating,
            //     card: card,
            //     mostRecentCheckIn: currentActiveCheckin,
            //     lastCheckInSession: lastCheckin
            // }

            // fire and forget, so don't await
            // Storage.put(fileName, s3Data, {
            //     level: "private",
            //     contentType: "application/json",
            // });
        } catch (error) {
            console.log('"ERROR"', "ERROR", error)
            // throw error
        }
    }

    const removeSwitchRating = () => {
        deleteSwitchRatingMutation({
            variables: {
                input: {
                    id: rating?.id,
                    _version: rating?._version
                }
            }
        })
    }

    return (
        <View>
            <DefaultText style={styles.heading}>Rate this switch</DefaultText>
            <View style={styles.ratingContainer}>
                <View style={styles.iconContainer}>
                    <TouchableOpacity style={styles.iconSVGContainerLike} onPress={() => createRating(SwitchRatingType.LIKE)}>
                        {selection === SwitchRatingType.LIKE ? <ThumbUpSelected /> : <ThumbUp />}
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.iconSVGContainerNeutral} onPress={() => createRating(SwitchRatingType.NEUTRAL)}>
                        {selection === SwitchRatingType.NEUTRAL ? <ThumbNeutralSelected /> : <ThumbNeutral />}
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.iconSVGContainerDislike} onPress={() => createRating(SwitchRatingType.DISLIKE)}>
                        {selection === SwitchRatingType.DISLIKE ? <ThumbDownSelected /> : <ThumbDown />}
                    </TouchableOpacity>
                </View>
            </View>
        </View>

    )
}

const styles = StyleSheet.create({
    ratingContainer: {
        justifyContent: 'center',
        alignContent: 'center',
        borderRadius: 8,
        width: 200,
        height: 75,
    },
    iconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    heading: {
        textAlign: 'center',
        fontSize: 18,

    },
    iconSVGContainerLike: {
        width: 40,
        height: 40,
    },
    iconSVGContainerNeutral: {
        width: 40,
        height: 40,
    },
    iconSVGContainerDislike: {
        width: 40,
        height: 40,
    }
})