import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan, Line } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconSad5Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 5)
    }

    const onPressLevel2 = {
        [prop]: () => onPress(2 / 5)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(3 / 5)
    }

    const onPressLevel4 = {
        [prop]: () => onPress(4 / 5)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg viewBox="0 0 812.027 548.401">
            <Line y1="134.841" x2="203.922" transform="translate(1.93 399.64)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M5457.7,501.586l-25.391,6.085,9.85,14.895Z" transform="translate(-5239.978 -109.798)" fill="#5c5d5d" />
            <Line y1="81.745" x2="123.937" transform="translate(229.62 301.455)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M5605.418,403.414l-25.4,6.056,9.832,14.906Z" transform="translate(-5239.978 -109.798)" fill="#5c5d5d" />
            <Line y1="83.298" x2="124.928" transform="translate(377.325 202.494)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M5754.074,304.394l-25.367,6.183,9.906,14.857Z" transform="translate(-5239.978 -109.798)" fill="#5c5d5d" />
            <Line y1="81.827" x2="126.3" transform="translate(525.794 103.354)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M5904.019,205.412l-25.446,5.848,9.709,14.986Z" transform="translate(-5239.978 -109.798)" fill="#5c5d5d" />
            <Line y1="81.778" x2="124.887" transform="translate(675.231 7.799)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M6026.586,115.769l9.782,14.94L6052,109.8Z" transform="translate(-5239.978 -109.798)" fill="#5c5d5d" />
            <G {...onPressLevel1} transform="translate(-121.098 -218)">
                <Path d="M5247.574,655.326,5456.7,516.7l-.174,137.757Z" transform="translate(-5118.879 108.202)" fill="#b2d9f4" />
                <Text transform="translate(183.543 755.401)" fill="#313133" fontSize="23" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Disappointed</TSpan></Text>
            </G>
            <G {...onPressLevel2} transform="translate(-121.098 -218)">
                <Path d="M5467.086,510.1l138.331-90.6.5,234.663-139.277.111Z" transform="translate(-5118.879 108.202)" fill="#84c0ea" />
                <Text transform="translate(355.154 697.298)" fill="#313133" fontSize="21.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Discouraged</TSpan></Text>
            </G>
            <G {...onPressLevel3} transform="translate(-121.098 -218)">
                <Path d="M5615.478,412.3l138.6-93-.173,335.362-137.712.112Z" transform="translate(-5118.879 108.202)" fill="#5ca8de" />
                <Text transform="translate(510.053 641.622)" fill="#313133" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Hopeless</TSpan></Text>
            </G>
            <G {...onPressLevel4} transform="translate(-121.098 -218)">
                <Path d="M5763.869,312.7l139.15-90,.33,431.963-139.277.11Z" transform="translate(-5118.879 108.202)" fill="#4094d2" />
                <Text transform="translate(660.392 585.948)" fill="#ffffff" fontSize="26.75" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Grieving</TSpan></Text>
            </G>
            <G {...onPressLevel5} transform="translate(-121.098 -218)">
                <Path d="M5913.922,214.987,6051,125.5l.327,529.162-137.712.112Z" transform="translate(-5118.879 108.202)" fill="#2b82c6" />
                <Text transform="translate(810.305 531.727)" fill="#ffffff" fontSize="27.75" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Despair</TSpan></Text>
            </G>
        </Svg>
    )
}