import { useReactiveVar } from '@apollo/client';
import { Dimensions, StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';
import { getLockDerviceRv, rvUserGroup } from '../../login/login-state';
import { ExtraTipsIcon } from './extra-tips';
import { useResponsive } from '../../common/use-responsive';
import { DefaultText } from '../../common/default-text';
import { CardColorScheme } from './card-color-scheme';
import { Emotion } from '../../models';
import { UserGroup } from '../../../common/constants';
import { CardTemplate } from './card-template';

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth > 600

type Props = {
    emotion: Emotion
    lessonPlanCard?: CardTemplate
    onPress?: () => void
}

const SwitchDetailsIntroducingEmotion = ({ emotion, onPress, lessonPlanCard }: Props) => {
    const userGroup = useReactiveVar(rvUserGroup)
    const { isLandScape, isLargeScreen } = useResponsive()
    const denyAccess = useReactiveVar(getLockDerviceRv())

    if (!lessonPlanCard) {
        return <View style={styles.sideContainer} />
    }

    return (
        <View>
            {userGroup === UserGroup.Teacher && !denyAccess &&
                <View style={styles.sideContainer}>
                    <TouchableOpacity style={styles.imagecontainer} onPress={onPress}>
                        <View style={styles.extratips}>
                            <ExtraTipsIcon colorScheme={CardColorScheme[emotion]} />
                        </View>
                        {isLandScape && isLargeScreen && <>
                            <DefaultText style={[styles.extraTipsText, { color: CardColorScheme[emotion] }]}>
                                {lessonPlanCard.title} lesson plan
                            </DefaultText>
                        </>}
                    </TouchableOpacity>
                </View>
            }
        </View>
    )
}
export default SwitchDetailsIntroducingEmotion

const styles = StyleSheet.create({
    imagecontainer: {
        padding: windowWidthcondition ? 10 : 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    extratips: {
        width: windowWidthcondition ? 50 : 25,
        height: windowWidthcondition ? 50 : 25,
    },
    sideContainer: {
        flex: 0.2
    },
    extraTipsText: {
        textAlign: 'center',
        fontWeight: '600',
        fontSize: windowWidthcondition ? 20 : 10,
        maxWidth: 350,
        flexWrap: 'wrap'

    } as TextStyle,
})