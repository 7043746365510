import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { GetSchoolQueryResponseClass, GetSchoolQueryResponseTeacher } from "../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query"
import { updateTeacher } from "../../common/graphql/mutations"
import SchoolAdminClassAndTeacherForm, { SchoolAdminClassAndTeacherFormOnSubmit } from "./school-admin-class-and-teacher-form"
import { StyleSheet } from 'react-native'
import { SchoolAdminTableSetEditComponent } from "./school-admin-const"

type Props = {
    currentClass: GetSchoolQueryResponseClass,
    currentTeacher: GetSchoolQueryResponseTeacher,
    onRemoveTeacher: () => Promise<void>,
    setEditComponent: SchoolAdminTableSetEditComponent,
}

export const SchoolAdminEditExistingClassTeacher = ({
    currentClass,
    currentTeacher,
    onRemoveTeacher,
    setEditComponent,
}: Props) => {
    const [updateTeacherMutation] = useMutation(gql`${updateTeacher}`);

    const handleSubmit: SchoolAdminClassAndTeacherFormOnSubmit = async ({
        firstName,
        lastName,
        email,
        role,
    }) => {
        const isDataChanged = firstName !== currentTeacher?.firstName || lastName !== currentTeacher?.lastName || email !== currentTeacher?.email || role !== currentTeacher?.role

        if (isDataChanged) {
            await updateTeacherMutation({
                variables: {
                    input: {
                        id: currentTeacher?.id,
                        firstName,
                        lastName,
                        role: role,
                        email,
                        _version: currentTeacher?._version
                    }
                }
            })
        }
    }

    return (
        <SchoolAdminClassAndTeacherForm setEditComponent={setEditComponent} schoolID={currentTeacher.schoolID!} currentClass={currentClass} currentTeacher={currentTeacher} onSubmit={handleSubmit} onRemoveTeacher={onRemoveTeacher} style={styles.schoolAdminClassForm} />
    )
}

const styles = StyleSheet.create({
    schoolAdminClassForm: {
        maxWidth: '100%'
    },
})
