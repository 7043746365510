import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { RouteProp, useRoute } from '@react-navigation/native'
import moment from 'moment'
import { useMemo, useRef } from 'react'
import { ScrollView, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native'
import { CheckInSession, GetClassQuery, GetClassQueryVariables, GetStudentClassByClassIdQueryVariables, ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables, StudentClassApprovalStatus } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { getClass, listCheckInSessionsByClassId } from '../../common/graphql/queries'
import { isDefined } from '../../common/is-defined'
import { AllStatsPeriodsGraphYAxis, viewBoxHeight, yAxisLabelWidth } from '../common/all-stats-period-graph'
import { DefaultBackgroundColor, DefaultSecondaryFontSize } from '../common/const'
import { DefaultText } from '../common/default-text'
import { rvTeacherStatsAllFilterDate } from '../common/dropdown-picker-filter'
import { rvIsLoading } from '../common/loading'
import { TeacherStatsSortFilter } from '../common/teacher-stats-sort-filter'
import { lowRes } from '../common/use-responsive'
import { avatars } from '../icon/avatar/avatars'
import { ProgressiveLoader } from '../switches/common/progressive-loader'
import { ListStudentClassAndStudentDataByClassIdCustomQuery, listStudentClassAndStudentDataByClassIdCustomQuery } from './teacher-graphql-scripts'
import { TeacherClassNavigatorParamList } from './teacher-route-param-types'
import { TeacherStatsAllPeriodsGraph } from './teacher-stats-all-periods-graph'
import { StudentAndStudentClassAndStudentCheckIn } from './teacher-stats-last-period-and-requested-chat'

interface Props {
    visible: boolean
    onAvatarPress?: ({ student }: StudentAndStudentClassAndStudentCheckIn) => void
    checkstate: boolean
    setCheckState: (data: boolean) => void
}

export const TeacherStatsAllPeriods = ({ visible, onAvatarPress, checkstate, setCheckState }: Props) => {

    const allStatsFilterDate = useReactiveVar(rvTeacherStatsAllFilterDate)

    const { params } = useRoute<RouteProp<TeacherClassNavigatorParamList, "TeacherStats">>()
    const { data: classResponse } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: params?.classID }, skip: !params?.classID })
    const currentClass = classResponse?.getClass

    const { data: checkInSessionResponse } = useQuery<ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables>(gql`${listCheckInSessionsByClassId}`,
        {
            variables:
            {
                classID: params?.classID,
                limit: 5000,
                createdAt: {
                    gt: allStatsFilterDate || moment().subtract('7', 'd').startOf('day').toISOString()
                }
            },
            skip: !params?.classID
        })
    const checkInSessions = checkInSessionResponse?.listCheckInSessionsByClassId?.items.filter((item) => item?._deleted !== true).filter(isDefined) || [] as CheckInSession[]


    const { data: studentClassAndStudentDataResponse, loading: loadingStudentData } = useQuery<ListStudentClassAndStudentDataByClassIdCustomQuery, GetStudentClassByClassIdQueryVariables>(gql`${listStudentClassAndStudentDataByClassIdCustomQuery}`, { variables: { classID: params?.classID, limit: 5000 }, skip: !params.classID })
    const currentStudents = studentClassAndStudentDataResponse?.getStudentClassByClassId?.items
    const activeStudents = currentStudents?.filter(item => item?._deleted !== true && item?.status === StudentClassApprovalStatus.APPROVED) || []

    const isLoading = useReactiveVar(rvIsLoading)
    const scrollViewRef = useRef<ScrollView | null>(null)

    const avatarsMemo = useMemo(() =>
        activeStudents
            .map(studentClassAndStudentData => {

                const currentStudent = studentClassAndStudentData?.student
                const Avatar = currentStudent ?
                    avatars[studentClassAndStudentData.student?.avatar as keyof typeof avatars] :
                    avatars["Default"]

                const callOnAvatarPress = () => {
                    if (studentClassAndStudentData && currentStudent) {
                        onAvatarPress && onAvatarPress({
                            student: {
                                student: currentStudent,
                                studentClass: studentClassAndStudentData
                            }
                        })
                    }
                }

                return (
                    <View key={currentStudent?.nickname} style={styles.avatarYAxisContainer}>
                        <View style={styles.avatarContainer}>
                            <TouchableOpacity style={styles.avatar} onPress={callOnAvatarPress}>
                                <Avatar />
                            </TouchableOpacity>
                            <DefaultText style={styles.nickname}>{currentStudent?.nickname}</DefaultText>
                            <DefaultText style={styles.firstName} children={`${currentStudent?.firstName} ${currentStudent?.lastName}`} />
                        </View>
                        <View style={styles.yAxisContainer}>
                            <AllStatsPeriodsGraphYAxis />
                        </View>
                    </View>
                )
            }),
        [activeStudents]
    )

    const stats = useMemo(() => {
        if (isLoading) {
            return null
        }

        return (
            activeStudents.map(studentDataAndStudetClass => (
                <View key={studentDataAndStudetClass?.student?.nickname} style={styles.statsRow}>
                    <View key={studentDataAndStudetClass?.student?.nickname} style={styles.avatarYAxisContainer}>
                        <View style={styles.avatarContainer}>
                            <View style={styles.avatar} />
                            <DefaultText style={[styles.nickname, styles.transparent]}>{studentDataAndStudetClass?.student?.nickname}</DefaultText>
                        </View>
                        <View style={styles.yAxisContainer} />
                    </View>
                    <View style={styles.graphContainer}>
                        {
                            !isLoading && (
                                <TeacherStatsAllPeriodsGraph
                                    checkInSessions={!checkstate ? [...checkInSessions] : [...checkInSessions].reverse()}
                                    studentClass={studentDataAndStudetClass}
                                // scaleStyle={transformByRatioStyle}
                                />
                            )
                        }
                    </View>
                </View>
            ))
        )
    }, [activeStudents, checkInSessions, currentClass?.emotionIntensityLevel, checkstate])

    if (currentClass === undefined) {
        return null
    }

    return (
        <>

            <TeacherStatsSortFilter visible={lowRes ? false : visible} checkstate={checkstate} setCheckState={setCheckState} />
            <View style={[styles.mainContainer, visible ? {} : { display: 'none' }]}>
                <View style={styles.avatarsContainer}>
                    <ProgressiveLoader>
                        {avatarsMemo}
                    </ProgressiveLoader>
                </View>
                <ScrollView
                    horizontal
                    ref={scrollViewRef}
                    contentOffset={{ x: 20, y: 0 }}
                    onContentSizeChange={() => scrollViewRef?.current?.scrollToEnd({ animated: true })}
                >
                    <View>
                        <ProgressiveLoader>
                            {stats}
                        </ProgressiveLoader>
                    </View>
                </ScrollView>
            </View>
        </>
    )
}

const avatarSize = 80
const rowMargin = 5
const rowHeight = avatarSize * 2
const avatarsContainerWidth = avatarSize + rowMargin * 2

const styles = StyleSheet.create({
    mainContainer: {
        width: '100%',
        padding: 5,
        marginBottom: 20,
        marginLeft: 20
    } as ViewStyle,
    avatarsContainer: {
        position: 'absolute',
        width: avatarsContainerWidth,
        backgroundColor: DefaultBackgroundColor,
        zIndex: 10,

    },
    avatarYAxisContainer: {
        flexDirection: 'row',
        width: avatarSize + rowMargin + yAxisLabelWidth,
        marginRight: 0,
        height: rowHeight,
    },
    yAxisContainer: {
        height: viewBoxHeight,
        width: yAxisLabelWidth,
        marginTop: rowMargin
    },
    avatarContainer: {
        justifyContent: 'flex-end',
        marginRight: rowMargin,
        width: avatarSize,
        alignSelf: 'flex-start'
    },
    avatar: {
        width: avatarSize,
        height: avatarSize,
    },
    nickname: {
        width: avatarSize,
        textAlign: 'center'
    } as TextStyle,
    statsRow: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: rowHeight,
    },
    graphContainer: {
        marginTop: rowMargin,
    },
    transparent: {
        color: 'transparent',
    },
    firstName: {
        color: DecidaColors.Gray,
        fontSize: DefaultSecondaryFontSize - 2,
        textAlign: 'center',
    }

})