import { createStackNavigator } from '@react-navigation/stack'
import { RootView } from '../../common/root-view'
import { ScreenNames } from '../../common/screen-names'
import { Card } from '../common/card'
import { SwitchesExploreAll } from '../switches-explore-all'
import { SwitchesParamList } from './switches-param-list'


const Stack = createStackNavigator<SwitchesParamList>()

export const SwitchesExploreAllMain = () => (
    <RootView>
        <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={ScreenNames.SwitchesExploreAll} >
            <Stack.Screen name={ScreenNames.SwitchesExploreAll} component={SwitchesExploreAll} />
            <Stack.Screen name={ScreenNames.Card} component={Card} />
        </Stack.Navigator>
    </RootView>
)