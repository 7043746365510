import _ from "lodash";
import { Class } from "../../common/API";

export const haveSimilarNameStart = (classes: Class[]) => {
    if (!classes.length) {
        return false;
    }

    const startOfFirstItem = classes[0].name.match(/^\D+/)?.[0] || ''; // Extract non-digit prefix of the first item

    return _.every(classes, (item) => {
        const startOfItem = item.name.match(/^\D+/)?.[0] || ''; // Extract non-digit prefix of the current item
        return startOfItem === startOfFirstItem; // Compare prefixes
    });
}