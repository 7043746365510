import { ScrollView, StyleSheet } from 'react-native'
import { DefaultFontSize } from '../common/const'
import DefaultModal from '../common/default-modal'
import { DefaultText } from '../common/default-text'
import ViewZoomableForIphone from '../common/view-zoomable'
import { IndividualStudentStatsImage } from './assets/individual-student-stats-image'
import { rvTeacherIndividualStudentStatsInfoModal } from './teacher-state'
import { View } from 'react-native'
import { useResponsive } from '../common/use-responsive'

const TeacherIndividualStudentStatsInfoModal = () => {
    const toggleModal = () => rvTeacherIndividualStudentStatsInfoModal(false)
    const { responsiveFontSize, isNarrowScreen } = useResponsive()

    return (
        <DefaultModal onClose={toggleModal} contentStyle={{ ...styles.contentContainer, padding: isNarrowScreen ? 30 : 60 }}>
            <ScrollView>
                <DefaultText style={[styles.headerText, { fontSize: responsiveFontSize(DefaultFontSize + 2) }]}>
                    Individual student stats
                </DefaultText>
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                    When students are mature enough to understand how to read and interpret their own longitudinal check in data, you can choose to enable this function that will grant access to students that are logged into their own account. This can be a powerful engagement strategy for older students as they deepen their self awareness and can start to reflect on any observable patterns or trends.
                </DefaultText>
                <View style={styles.spacing} />
                <ViewZoomableForIphone style={{ width: '100%', height: isNarrowScreen ? 150 : 300 }}>
                    <IndividualStudentStatsImage />
                </ViewZoomableForIphone>

            </ScrollView>
        </DefaultModal>
    )
}
export default TeacherIndividualStudentStatsInfoModal

const styles = StyleSheet.create({
    contentContainer: {
        padding: 60,
        maxHeight: 800,
    },
    text: {
        textAlign: 'justify',
    },
    headerText: {
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: 20
    },
    spacing: {
        marginVertical: 5,
    },
})  
