import _ from "lodash"
import { View } from "react-native"
import Svg, { G, Rect, Text } from "react-native-svg"
import { DecidaColors } from "../../common/decida-colors"
import { GRAPH_HEIGHT, GRAPH_LABEL_FONTSIZE } from "./teacher-consts"


const barVerticalPadding = 5
const smallTextSize = 10
const emotionLabelFontSize = 10
const emotionLabelWidth = 40
const yAxisLabelWidth = 30
const xAxisLabelHeight = 15
const xAxisLabelBottomPadding = 2
const graphHeight = GRAPH_HEIGHT
const totalEmotions = 6
const stepSize = graphHeight / totalEmotions
const defaultCharWidth = 5
const valueLabelPadding = 2
const graphWidth = 200
const totalWidth = yAxisLabelWidth + emotionLabelWidth + graphWidth
const totalHeight = graphHeight + xAxisLabelHeight + xAxisLabelBottomPadding

type Props = {
    angry: number;
    anxious: number;
    excited: number
    happy: number;
    sad: number;
    scared: number;
}

export const TeacherStatsHorizontalBarLastCheckIn = ({
    angry,
    anxious,
    excited,
    happy,
    sad,
    scared
}: Props) => {

    const maxValue = Math.max(angry, anxious, excited, happy, sad, scared)
    const graphValues = [
        {
            color: DecidaColors.Angry,
            value: angry,
            label: "Angry"
        },
        {
            color: DecidaColors.Anxious,
            value: anxious,
            label: "Anxious"
        },
        {
            color: DecidaColors.Excited,
            value: excited,
            label: "Excited"
        },
        {
            color: DecidaColors.Happy,
            value: happy,
            label: "Happy"
        },
        {
            color: DecidaColors.Sad,
            value: sad,
            label: "Sad"
        },
        {
            color: DecidaColors.Scared,
            value: scared,
            label: "Scared"
        },
    ]


    return (
        <View style={{ flex: 1, minWidth: totalWidth }}>
            <Svg
                viewBox={`0 0 ${totalWidth} ${totalHeight}`}
            >
                {/* Y axis label*/}
                <Text
                    transform={`rotate(-90 0 60)`}
                    x={-20}
                    y={80}
                    textAnchor="middle"
                    fontFamily="HelveticaNeue"
                    fontSize={GRAPH_LABEL_FONTSIZE}
                    fill={DecidaColors.GrayText}
                    fontWeight="bold"
                >
                    Emotion
                </Text>


                {/* Graph bar */}
                {_.orderBy(graphValues, "value", "desc").map((item, index) => {
                    const barY = index * stepSize + barVerticalPadding / 2
                    const barWidth = item.value ? graphWidth * item.value / maxValue : 0
                    const barHeight = stepSize - barVerticalPadding

                    const textYAxis = barY + (stepSize / 2)
                    const valueLabelWidth = item.value.toString().length * defaultCharWidth
                    const valueLabelOutsideBar = graphWidth - barWidth > valueLabelWidth + valueLabelPadding

                    const x = valueLabelOutsideBar ? barWidth + valueLabelPadding : barWidth - valueLabelPadding

                    return (
                        <G key={String(index)} x={yAxisLabelWidth}>

                            {/* Emotion label */}
                            <Text x={0} y={textYAxis} fontFamily="HelveticaNeue" fontSize={emotionLabelFontSize} fill={DecidaColors.GrayText}>
                                {item.label}
                            </Text>

                            <G x={emotionLabelWidth}>
                                {/* Bar  */}
                                <Rect x={0} width={barWidth} height={barHeight} y={barY} fill={item.color} />

                                {/* Value label */}
                                <Text
                                    fontFamily="HelveticaNeue"
                                    fontSize={smallTextSize}
                                    textAnchor={valueLabelOutsideBar ? "start" : "end"}
                                    x={x}
                                    y={textYAxis}
                                    fill={valueLabelOutsideBar ? DecidaColors.GrayText : DecidaColors.White}
                                >
                                    {item.value}
                                </Text>
                            </G>

                        </G>
                    )
                })}

                {/* X axis label */}
                <Text x={'50%'} y={graphHeight + xAxisLabelHeight} fill={DecidaColors.GrayText} fontFamily="HelveticaNeue" fontSize={GRAPH_LABEL_FONTSIZE} fontWeight="bold">
                    Count
                </Text>

            </Svg>
        </View>
    )
}

