import { StyleSheet } from 'react-native'
import { TeacherRole } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultText } from './default-text'
import { getRoleByValue } from './input-search-teacher-role-component'
import { ViewStyle } from 'react-native'

type Props = {
    role?: TeacherRole,
    fontSize?: number,
    style?: ViewStyle,
}


const TeacherRoleText = ({ role, style, fontSize = 16 }: Props) => {
    return (
        <DefaultText style={[styles.emailText, style, { fontSize }]}>{getRoleByValue(role)}</DefaultText>
    )
}
export default TeacherRoleText

const styles = StyleSheet.create({
    emailText: {
        paddingLeft: 0,
        marginLeft: 0,
        fontStyle: 'italic',
        color: DecidaColors.AppleSystemGray4Light,
        fontWeight: '200'
    },
})