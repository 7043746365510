import { Platform } from 'react-native'
import Svg, { Rect, G, Path, Circle, Line, Text, TSpan } from 'react-native-svg'
import { Emotion } from '../models'

interface Props {
    onPress: (emotion: Emotion) => void
}

export const IconEmotionalWheel = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressHappy = {
        [prop]: () => onPress(Emotion.HAPPY)
    }

    const onPressAnxious = {
        [prop]: () => onPress(Emotion.ANXIOUS)
    }

    const onPressAngry = {
        [prop]: () => onPress(Emotion.ANGRY)
    }

    const onPressSad = {
        [prop]: () => onPress(Emotion.SAD)
    }

    const onPressScared = {
        [prop]: () => onPress(Emotion.SCARED)
    }

    const onPressExcited = {
        [prop]: () => onPress(Emotion.EXCITED)
    }

    return (
        <Svg testID='svg-emotional-wheels' viewBox="0 0 552 639.5">
            <G transform="translate(-5449.956 -1563.697)">
                <G {...onPressHappy}>
                    <Path d="M5851.712,1657.805l-129.648,227.93-126.125-214.729s127.474-82.507,255.773-13.2" fill="#136f30" />
                    <Path d="M5725.351,1691.947a62.25,62.25,0,1,0-62.25-62.25,62.25,62.25,0,0,0,62.25,62.25" fill="#fff" />
                    <Circle cx="62.25" cy="62.25" r="62.25" transform="translate(5663.101 1567.447)" fill="none" stroke="#277330" strokeWidth="7.5" />
                    <Path d="M5700.409,1621.52a9.8,9.8,0,1,0-9.8-9.8,9.8,9.8,0,0,0,9.8,9.8" fill="#277330" />
                    <Path d="M5749.369,1621.52a9.8,9.8,0,1,0-9.8-9.8,9.8,9.8,0,0,0,9.8,9.8" fill="#277330" />
                    <Path d="M5694.923,1644.166s25.02,31.7,59.4.737" fill="none" stroke="#277330" strokeWidth="7.5" />
                    <Path d="M5692.016,1646.559s-1.82-7.391,5.857-4.706" fill="#277330" />
                    <Path d="M5751.8,1642.128s7.285-2.205,5.006,5.6" fill="#277330" />
                    <Path d="M5666.913,1694.76a14.912,14.912,0,1,0,0,29.824h116.876a14.912,14.912,0,1,0,0-29.824Z" fill="#fff" />
                    <Text id="HAPPY" transform="translate(5684.65 1718.046)" fill="#277330" fontSize="22.5" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0" letterSpacing="0.04em">HAP</TSpan><TSpan y="0" letterSpacing="0.038em">P</TSpan><TSpan y="0">Y</TSpan></Text>
                    <Path d="M5666.913,1694.76a14.912,14.912,0,1,0,0,29.824h116.876a14.912,14.912,0,1,0,0-29.824Z" fill="none" stroke="#277330" strokeWidth="4.5" />
                </G>
                <G {...onPressAnxious}>
                    <Path d="M5984.369,1883.362l-262.212,2.373,122.331-216.913s135.371,68.788,139.881,214.54" fill="#c0aa1b" />
                    <Path d="M5917.7,1822.476a62.25,62.25,0,1,0-62.25-62.25,62.25,62.25,0,0,0,62.25,62.25" fill="#fff" />
                    <Circle cx="62.25" cy="62.25" r="62.25" transform="translate(5829.666 1760.226) rotate(-45)" fill="none" stroke="#c3ac1b" strokeWidth="7.5" />
                    <Path d="M5893.738,1756.089a8.818,8.818,0,1,0-8.818-8.818,8.818,8.818,0,0,0,8.818,8.818" fill="#c3ac1b" />
                    <Path d="M5942.7,1756.089a8.818,8.818,0,1,0-8.818-8.818,8.818,8.818,0,0,0,8.818,8.818" fill="#c3ac1b" />
                    <Path d="M5905.461,1775.946a8.82,8.82,0,0,0,0,17.64h24.48a8.82,8.82,0,1,0,0-17.64Z" fill="none" stroke="#c3ac1b" strokeWidth="7.5" />
                    <Path d="M5859.262,1826.076a14.912,14.912,0,1,0,0,29.824h116.876a14.912,14.912,0,0,0,0-29.824Z" fill="#fff" />
                    <Text id="ANXIOUS" transform="translate(5863.332 1849.361)" fill="#c3ac1b" fontSize="22.5" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0" letterSpacing="0.04em">ANXIOU</TSpan><TSpan y="0">S</TSpan></Text>
                    <Path d="M5859.262,1826.076a14.912,14.912,0,1,0,0,29.824h116.876a14.912,14.912,0,0,0,0-29.824Z" fill="none" stroke="#c3ac1b" strokeWidth="4.5" />
                </G>
                <G {...onPressAngry}>
                    <Path d="M5854.92,2111.553l-132.569-226.244,249.023-1.863s7.717,151.65-116.454,228.107" fill="#e50019" />
                    <Path d="M5926.356,2038.733a62.25,62.25,0,1,0-62.25-62.25,62.25,62.25,0,0,0,62.25,62.25" fill="#fff" />
                    <Circle cx="62.25" cy="62.25" r="62.25" transform="translate(5864.106 1914.233)" fill="none" stroke="#e50019" strokeWidth="7.5" />
                    <Path d="M5896.914,1971.306a9.8,9.8,0,1,0-9.8-9.8,9.8,9.8,0,0,0,9.8,9.8" fill="#e50019" />
                    <Path d="M5945.874,1971.306a9.8,9.8,0,1,0-9.8-9.8,9.8,9.8,0,0,0,9.8,9.8" fill="#e50019" />
                    <Path d="M5891.428,2001.61s25.02-31.7,59.4-.737" fill="none" stroke="#e50019" strokeWidth="7.5" />
                    <Path d="M5888.52,1999.217s-1.82,7.391,5.857,4.706" fill="#e50019" />
                    <Path d="M5948.308,2003.648s7.285,2.2,5.006-5.6" fill="#e50019" />
                    <Line x2="21.772" y2="9" transform="translate(5886.028 1939.433)" fill="none" stroke="#e50019" strokeWidth="7.5" />
                    <Path d="M5887.488,1935.968s-7.6.354-2.848,6.953" fill="#e50019" />
                    <Path d="M5906.368,1951.9s7.6-.354,2.848-6.953" fill="#e50019" />
                    <Line y1="9" x2="21.772" transform="translate(5934.974 1939.433)" fill="none" stroke="#e50019" strokeWidth="7.5" />
                    <Path d="M5936.434,1951.9s-7.6-.354-2.848-6.953" fill="#e50019" />
                    <Path d="M5955.314,1935.968s7.6.354,2.848,6.953" fill="#e50019" />
                    <Path d="M5867.918,2041.433a14.912,14.912,0,1,0,0,29.824h116.875a14.912,14.912,0,1,0,0-29.824Z" fill="#fff" />
                    <Text transform="translate(5884.721 2064.719)" fill="#e50019" fontSize="22.5" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0" letterSpacing="0.04em">ANG</TSpan><TSpan y="0" letterSpacing="0.032em">R</TSpan><TSpan y="0">Y</TSpan></Text>
                    <Path d="M5867.918,2041.433a14.912,14.912,0,1,0,0,29.824h116.875a14.912,14.912,0,1,0,0-29.824Z" fill="none" stroke="#e50019" strokeWidth="4.5" />
                </G>
                <G {...onPressSad}>
                    <Path d="M5592.983,2111.376l129.648-227.93,126.125,214.729s-127.474,82.507-255.773,13.2" fill="#2b82c6" />
                    <Path d="M5722.347,2168.424a62.25,62.25,0,1,0-62.25-62.25,62.25,62.25,0,0,0,62.25,62.25" fill="#fff" />
                    <Circle cx="62.25" cy="62.25" r="62.25" transform="translate(5660.097 2043.924)" fill="none" stroke="#2b82c6" strokeWidth="7.5" />
                    <Path d="M5694.4,2110a9.8,9.8,0,1,0-9.8-9.8,9.8,9.8,0,0,0,9.8,9.8" fill="#2b82c6" />
                    <Path d="M5743.365,2110a9.8,9.8,0,1,0-9.8-9.8,9.8,9.8,0,0,0,9.8,9.8" fill="#2b82c6" />
                    <Path d="M5688.919,2140.3s25.02-31.7,59.4-.737" fill="none" stroke="#2b82c6" strokeWidth="7.5" />
                    <Path d="M5686.012,2137.908s-1.82,7.391,5.857,4.706" fill="#2b82c6" />
                    <Path d="M5745.8,2142.338s7.285,2.2,5.006-5.6" fill="#2b82c6" />
                    <Path d="M5663.909,2171.124a14.912,14.912,0,1,0,0,29.824h116.876a14.912,14.912,0,0,0,0-29.824Z" fill="#fff" />
                    <Text transform="translate(5697.418 2194.409)" fill="#2b82c6" fontSize="22.5" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0" letterSpacing="0.033em">S</TSpan><TSpan y="0" letterSpacing="0.04em">A</TSpan><TSpan y="0">D</TSpan></Text>
                    <Path d="M5663.909,2171.124a14.912,14.912,0,1,0,0,29.824h116.876a14.912,14.912,0,0,0,0-29.824Z" fill="none" stroke="#2b82c6" strokeWidth="4.5" />
                </G>
                <G {...onPressScared}>
                    <Path d="M5460.326,1885.818l262.212-2.373-122.331,216.913s-135.371-68.788-139.881-214.54" fill="#cd8030" />
                    <Path d="M5525.556,2043.214a62.25,62.25,0,1,0-62.25-62.25,62.25,62.25,0,0,0,62.25,62.25" fill="#fff" />
                    <Circle cx="62.25" cy="62.25" r="62.25" transform="translate(5463.306 1918.714)" fill="none" stroke="#c6853c" strokeWidth="7.5" />
                    <Path d="M5506.614,1974.287a9.8,9.8,0,1,0-9.8-9.8,9.8,9.8,0,0,0,9.8,9.8" fill="#c6853c" />
                    <Path d="M5555.574,1974.287a9.8,9.8,0,1,0-9.8-9.8,9.8,9.8,0,0,0,9.8,9.8" fill="#c6853c" />
                    <Path d="M5514.444,1945.973s-8.9,9.613-26.907,15.7" fill="none" stroke="#c6853c" strokeWidth="7.5" />
                    <Path d="M5486.349,1958.135s-5.46,5.3,2.474,7.095" fill="#c6853c" />
                    <Path d="M5517.176,1948.489s2.331-7.246-5.515-5.1" fill="#c6853c" />
                    <Path d="M5546.165,1945.973s8.9,9.613,26.907,15.7" fill="none" stroke="#c6853c" strokeWidth="7.5" />
                    <Path d="M5574.261,1958.135s5.46,5.3-2.474,7.095" fill="#c6853c" />
                    <Path d="M5543.434,1948.489s-2.331-7.246,5.515-5.1" fill="#c6853c" />
                    <Path d="M5496.189,1993.894s1.08-3.593,7.2-3.593,6.84,5.033,14.04,5.033,6.84-5.033,12.96-5.033,7.2,5.033,14.04,5.033,6.48-5.033,12.96-5.033,7.02,3.593,7.92,3.593" fill="none" stroke="#c6853c" strokeWidth="7.5" />
                    <Path d="M5492.6,1992.817s.817,7.567,7.114,2.419" fill="#c6853c" />
                    <Path d="M5495.869,1995.227c1.865.66,3.573.643,3.814-.039s-1.075-1.77-2.94-2.43-3.572-.643-3.814.039,1.075,1.77,2.94,2.43" fill="#c6853c" />
                    <Path d="M5564.439,1994.865a2.173,2.173,0,1,0-.332-3.323,2.383,2.383,0,0,0,.332,3.323" fill="#c6853c" />
                    <Path d="M5569.426,1993.962s-.817,7.567-7.114,2.419" fill="#c6853c" />
                    <Rect width="2.619" height="3.71" transform="translate(5565.239 1992.258) rotate(-32.003)" fill="#c6853c" />
                    <Path d="M5467.118,2046.627a14.912,14.912,0,1,0,0,29.824h116.876a14.912,14.912,0,0,0,0-29.824Z" fill="#fff" />
                    <Text id="SCARED" transform="translate(5477.272 2069.913)" fill="#c6853c" fontSize="22.5" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0" letterSpacing="0.04em">S</TSpan><TSpan y="0" letterSpacing="0.027em">C</TSpan><TSpan y="0" letterSpacing="0.04em">ARE</TSpan><TSpan y="0">D</TSpan></Text>
                    <Path d="M5467.118,2046.627a14.912,14.912,0,1,0,0,29.824h116.876a14.912,14.912,0,0,0,0-29.824Z" fill="none" stroke="#c6853c" strokeWidth="4.5" />
                </G>
                <G {...onPressExcited}>
                    <Path d="M5589.087,1657.628l132.569,226.244-249.023,1.863s-7.717-151.65,116.454-228.107" fill="#2e8c79" />
                    <Path d="M5525.556,1812.567a62.25,62.25,0,1,0-62.25-62.25,62.25,62.25,0,0,0,62.25,62.25" fill="#fff" />
                    <Circle cx="62.25" cy="62.25" r="62.25" transform="translate(5463.306 1688.067)" fill="none" stroke="#388f7d" strokeWidth="7.5" />
                    <Path d="M5500.614,1742.14a9.8,9.8,0,1,0-9.8-9.8,9.8,9.8,0,0,0,9.8,9.8" fill="#388f7d" />
                    <Path d="M5549.574,1742.14a9.8,9.8,0,1,0-9.8-9.8,9.8,9.8,0,0,0,9.8,9.8" fill="#388f7d" />
                    <Path d="M5495.063,1765.044s25.3,31.478,59.4.219" fill="none" stroke="#388f7d" strokeWidth="7.5" />
                    <Path d="M5492.176,1767.463s-1.885-7.374,5.816-4.757" fill="#388f7d" />
                    <Path d="M5551.923,1762.51s7.266-2.268,5.055,5.559" fill="#388f7d" />
                    <Path d="M5509.695,1701.36s-8.9,9.613-26.907,15.7" fill="none" stroke="#388f7d" strokeWidth="7.5" />
                    <Path d="M5481.6,1713.522s-5.46,5.3,2.474,7.095" fill="#388f7d" />
                    <Path d="M5512.427,1703.876s2.331-7.246-5.515-5.1" fill="#388f7d" />
                    <Path d="M5541.416,1701.36s8.9,9.613,26.907,15.7" fill="none" stroke="#388f7d" strokeWidth="7.5" />
                    <Path d="M5569.512,1713.522s5.46,5.3-2.474,7.095" fill="#388f7d" />
                    <Path d="M5538.685,1703.876s-2.331-7.246,5.515-5.1" fill="#388f7d" />
                    <Line x2="60.48" transform="translate(5494.444 1765.144)" fill="none" stroke="#388f7d" strokeWidth="6" />
                    <Path d="M5467.118,1814.129a14.912,14.912,0,1,0,0,29.824h116.876a14.912,14.912,0,0,0,0-29.824Z" fill="#fff" />
                    <Text transform="translate(5476.226 1837.414)" fill="#388f7d" fontSize="22.5" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0" letterSpacing="0.033em">E</TSpan><TSpan y="0" letterSpacing="0.015em">X</TSpan><TSpan y="0" letterSpacing="0.04em">CITE</TSpan><TSpan y="0">D</TSpan></Text>
                    <Path d="M5467.118,1814.129a14.912,14.912,0,1,0,0,29.824h116.876a14.912,14.912,0,0,0,0-29.824Z" fill="none" stroke="#388f7d" strokeWidth="4.5" />
                </G>
                <Path d="M5722.694,1899.2a13.84,13.84,0,1,0-13.84-13.84,13.84,13.84,0,0,0,13.84,13.84" fill="#fff" />
            </G>
        </Svg>
    )
}