import { useNavigation } from '@react-navigation/native'
import React, { useMemo, useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { DefaultButton } from '../../common/default-button'
import { ScreenNames } from '../../common/screen-names'
import { useResponsive } from '../../common/use-responsive'
import { Emotion } from '../../models'
import { emotionColor } from '../../common/get-emotion-color'
import { CardColorScheme } from './card-color-scheme'
import { DefaultText } from '../../common/default-text'
import { DecidaColors } from '../../../common/decida-colors'
import { FontAwesome } from '@expo/vector-icons';
import { AudioHandlerSwitches } from '../../common/audio-handler-switches'
import DefaultModal from '../../common/default-modal'

export type ContentTab = {
    label: string
    value: number
}

type Props = {
    contents: ({
        Component: () => JSX.Element,
        audio?: string
        isModal?: boolean
    })[]
    contentTabs?: ContentTab[],
    scheme: Emotion
    nextButtonText?: string
    imageBg?: any
    showImageBgSteps?: number[] // indicator to show background image only in specific steps
    withInitialSteps?: boolean
}

const SwitchCardJuniorContent = ({ contents, scheme, nextButtonText, contentTabs, withInitialSteps }: Props) => {
    const { width, height, isLargeScreen } = useResponsive()
    const [steps, setSteps] = useState(1)
    const { navigate } = useNavigation<any>()

    const handleNext = () => {
        if (steps === contents.length) {
            setSteps(1)
            navigate(ScreenNames.SwitchesEmotionsDetailView, { emotion: scheme })
            return
        }

        setSteps(prevState => prevState + 1)
    }

    const handlePrevious = () => {
        if (steps > 1) {
            setSteps(prevState => prevState - 1)
        }
    }

    const handleTabPress = (ct: ContentTab) => {
        if (withInitialSteps) {
            setSteps(ct.value + 1)
        } else {
            setSteps(ct.value)
        }
    }

    const { Component, audio, isModal } = contents[steps - 1]

    const audioFile = useMemo(() => ({ uri: audio! }), [audio])

    return (
        <View style={styles.fullWidth}>
            {contentTabs && (
                <View style={isLargeScreen ? styles.flexContainer : styles.flexContainerMobile}>
                    {contentTabs?.map((ct, index) => {
                        const currentTabs = withInitialSteps ? ct.value === steps - 1 : ct.value === steps
                        return <TouchableOpacity key={`${index}`} style={isLargeScreen ? styles.optionContainer : styles.optionContainerMobile} onPress={() => handleTabPress(ct)}>
                            <DefaultText textProps={{ numberOfLines: 1, adjustsFontSizeToFit: true }} style={currentTabs ? styles.tabSelected : styles.tab}>{ct.label}</DefaultText>
                        </TouchableOpacity>
                    })}
                </View>
            )}
            <View style={[styles.mainContainer, (width < 600 && !isModal) ? styles.mobileContainer : styles.webContainer]}>
                {isModal ? (
                    <DefaultModal onClose={() => setSteps(1)} contentStyle={{ height: height, width: width, maxWidth: '95%', maxHeight: '90%', overflow: 'hidden' }}>
                        <Component />
                    </DefaultModal>
                ) : (
                    <View style={styles.fullWidth}>
                        <Component />
                        {contentTabs ? null : (
                            <View style={styles.buttonContainer}>
                                {audio && <AudioHandlerSwitches audioFile={audioFile} containerStyle={styles.audio} hideText />}
                                {steps > 1 &&
                                    <DefaultButton style={[styles.navigationButtons, { backgroundColor: scheme === Emotion.EXCITED ? CardColorScheme.EXCITED : emotionColor[scheme] }]} onPress={handlePrevious}>
                                        <FontAwesome name="caret-left" size={40} color={DecidaColors.White} />
                                        <DefaultText style={styles.buttonText}>
                                            PREV
                                        </DefaultText>
                                    </DefaultButton>
                                }
                                {contents.length > 1 && (
                                    <DefaultButton style={[styles.navigationButtons, { backgroundColor: scheme === Emotion.EXCITED ? CardColorScheme.EXCITED : emotionColor[scheme] }]} onPress={handleNext}>
                                        {steps === contents.length ? "FINISH" :
                                            (
                                                <>
                                                    <DefaultText style={styles.buttonText}>
                                                        {nextButtonText ? nextButtonText : "NEXT"}
                                                    </DefaultText>
                                                    <FontAwesome name="caret-right" size={40} color={DecidaColors.White} />
                                                </>
                                            )
                                        }
                                    </DefaultButton>
                                )}
                            </View>
                        )}
                    </View>
                )}

            </View>
        </View>
    )
}

export default SwitchCardJuniorContent

const styles = StyleSheet.create({
    fullWidth: {
        width: '100%',
    },
    mainContainer: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20,
    },
    mobileContainer: { width: '100%', },
    webContainer: {
        flex: 1,
        flexDirection: 'column',
        paddingBottom: "10%",
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonContainer: {
        width: '100%',
        flexDirection: 'row',
        paddingTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    navigationButtons: {
        color: DecidaColors.White,
        marginLeft: 10,
        marginRight: 10,
        flex: 1,
        maxWidth: 200
    },
    buttonText: {
        color: DecidaColors.White,
        marginLeft: 10,
        marginRight: 10,
    },
    audio: {
        width: 50,
        marginLeft: 10,
        marginRight: 10,
    },
    tab: {
        flex: 1,
        borderWidth: 2,
        borderColor: CardColorScheme.EXCITED,
        color: CardColorScheme.EXCITED,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        paddingVertical: 10,
        marginRight: 20,
        paddingHorizontal: 5
    },
    tabSelected: {
        flex: 1,
        backgroundColor: CardColorScheme.EXCITED,
        color: DecidaColors.White,
        borderWidth: 2,
        borderColor: CardColorScheme.EXCITED,
        overflow: 'hidden',
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        paddingVertical: 10,
        marginRight: 20,
        paddingHorizontal: 10
    },
    flexContainer: {
        flexDirection: 'row',
        textAlign: 'center',
        margin: 20,
        justifyContent: 'center'
    },
    flexContainerMobile: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        textAlign: 'center',
        margin: 20,
        justifyContent: 'center'
    },
    optionContainer: {
        flex: 1,
        maxWidth: 300,
        justifyContent: 'center',
    },
    optionContainerMobile: {
        height: 50,
        width: 150,
        marginBottom: 10,
        justifyContent: 'center',
    },
})