import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { SelfAwarenessAndEmotionalWeeknessLesoonPlan } from './src/self-awareness-and-emotional-weakness-lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { ToolsTheLittleBookOfBigEmotions } from '../tools/cards/the-little-book-of-big-emotions'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'

export const SwitchesLessonPlansSelfAwarenessAndEmotionalAwareness: CardTemplate = {
    cardId: "a920a513-91d7-4ea4-aaca-951f676966f6",
    title: 'Self awareness and emotional awareness',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to recognise and label a wider range of emotions, understand their impact on personal wellbeing, and develop skills for deepening emotional awareness.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes </DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Greet the students and explain the importance of emotions and self awareness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss that emotions play a crucial role in our daily lives and understanding them can help us better understand ourselves.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share their experiences labelling emotions (either through switch previously or other check in processes) and how it helped them develop self awareness.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Emotion Vocabulary (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the basic emotions, showing the level 1 emotion wheel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss each emotion, asking students to share experiences or situations that might elicit those emotions. Challenge them to explain how they knew they (or others) were experiencing that emotion.  Look for verbal and non verbal cues including physiological indicators. (Refer to the Little Book of Big Emotions for more information.)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain/recap the concept of emotional arousal or intensity levels.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Emotional arousal levels refer to how intense or strong our emotions feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Imagine emotions as different volumes on a volume control dial.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Sometimes, we might feel emotions at a low volume, like a whisper or a soft sound. Other times, emotions can be turned up high, like a loud shout or a strong noise. Just like there are different volumes of sound, our emotions can also have different levels of intensity. For example, feeling a little bit happy, or peaceful, might be like the volume turned low, while feeling extremely happy, or ecstatic, might be like the volume turned all the way up.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Emotional arousal levels can change based on different situations and experiences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Exciting or surprising events can turn up the volume of our emotions, making them feel stronger. For example, winning a game or receiving a special gift might turn up the happiness volume, while losing a game or facing a disappointment might turn up the sadness volume. It's important to remember that everyone's emotional arousal levels can be different. What might be a high volume for one person's emotions might be a low volume for someone else.</DefaultText>ˆ
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Also, different emotions can have different volumes for each person.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Some people might get really excited and have a high volume of happiness, while others might get excited but at a lower volume. Understanding our emotional arousal levels helps us recognise and manage our feelings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- When we're aware of how loud or intense our emotions are, we can better understand how they impact us and make choices on how to respond.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>It's like knowing the volume of your emotions helps you decide how you want to handle them, whether by taking deep breaths, talking to someone you trust, or finding ways to calm down when the volume is too high.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Remember, emotions are a normal and important part of being human, and learning about emotional arousal levels can help us navigate and understand our feelings better.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Emotion Exploration Activity (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the level 5 emotion wheel ladder puzzle.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Either individually, or in pairs, have students place the emotion words onto the wheel in the correct order.  Allow the students to reference the Little Book of Big Emotions to find the correct answers.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show the level 5 emotion wheel and discuss any potential discrepancies in the student’s answers to ask why they felt one emotion was “louder” or stronger than another.</DefaultText>
                        <View style={CommonStyles.lessonPlanImageContainer}>
                            <SelfAwarenessAndEmotionalWeeknessLesoonPlan />
                        </View>

                        <DefaultText style={CommonStyles.mainLessonText}>• Impact of Emotions on Wellbeing (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that emotions have a direct impact on our wellbeing, affecting our thoughts, behaviour, and physical health.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the positive and negative effects of emotions on mental and physical wellbeing:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Positive Effects of Emotions:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>i. Mental Wellbeing: Emotions can help students develop a sense of self awareness and understanding of their own feelings. Recognising and expressing emotions can contribute to improved mental wellbeing and overall emotional intelligence.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>ii. Social Connections: Positive emotions like happiness and excitement can enhance social interactions and foster meaningful relationships with peers and family members. Building strong social connections is important for mental wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>iii. Motivation and Productivity: Feelings like enthusiasm and curiosity can fuel motivation and drive students to explore new activities and learn new skills. Positive emotions can enhance creativity, problem-solving abilities, and academic performance.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>iv. Resilience: Emotions play a role in developing resilience, the ability to cope with challenges and setbacks. Positive emotions can help students bounce back from difficult situations, adapt to changes, and maintain a positive outlook.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Negative Effects of Emotions:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>i. Mental Wellbeing: Emotions can impact mental wellbeing if they become overwhelming or prolonged. These emotions may lead to stress, mood disorders, or difficulties in regulating emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>ii. Physical Health: Chronic negative emotions can manifest physically, affecting physical health. For example, excessive stress or anxiety may lead to headaches, stomach aches, or sleep disturbances. It's important to address negative emotions to prevent physical health issues.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>iii. Social Relationships: Strong emotions can strain relationships with peers and family members. If a child consistently experiences uncontrolled and extreme emotions, it may affect their ability to connect with others, leading to isolation or conflicts.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>iv. Academic Performance: Intense emotions, such as panic, ecstatic or frustration, can hinder concentration, memory, and learning abilities. If not managed effectively, these emotions may impact academic performance and progress.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦. Help students understand that recognising and managing emotions can contribute to improved wellbeing.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Developing Emotional Awareness (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce strategies for deepening emotional awareness:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Mindfulness: Explain the practice of being present and observing emotions without judgment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Journaling: Encourage students to keep a journal to express and reflect on their emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Self reflection: Discuss the importance of taking time to reflect on feelings and the factors that contribute to them.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute journaling handout worksheets that provide prompts for reflection and journaling. Refer to the lesson plan for the Dear Diary or Journaling switch for more detail.  Have students reflect on the previous day and record their thoughts on the worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to reflect deeply, express their thoughts honestly, and use their own unique writing style.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflective Closure (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students together and facilitate a brief reflection on the lesson. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask open-ended questions such as:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "What did you learn about emotions and self awareness through journaling?" </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- "How did reflecting on your emotions help you understand yourself better?" </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points discussed during the lesson and reinforce the importance of emotional awareness and self reflection. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>Optional Extension Activities:</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Assign students to continue journaling about their emotions for a week and organise a sharing session where students can read excerpts from their journals (with their consent) to the class, fostering a supportive and empathetic environment.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='The Little Book of Big Emotions' link={ToolsTheLittleBookOfBigEmotions.openUrl || ""} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Emotion wheel activity sheet' link={Worksheets.emotionMatchUpA1.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Journaling worksheet' link={Worksheets.journaling.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Emotional Regulation: 6 Key Skills to Regulate Emotions -</DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/emotion-regulation/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Understanding Your Emotions -</DefaultText>
                        <DefaultTextWithLink text='https://kidshealth.org/en/teens/understand-emotions.html' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Emotional Intelligence Among Adolescents -</DefaultText>
                        <DefaultTextWithLink text='https://files.eric.ed.gov/fulltext/ED610084.pdf' />

                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubTextDetails: {
        paddingLeft: 120,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    durationText: {
        marginBottom: 10,
        color: DecidaColors.Gray,
        alignSelf: 'center'
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 10
    },
})
