import { View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { Br } from '../../../common/br'
import { DefaultText } from '../../../common/default-text'
import { Link } from '../../../common/link'
import { Worksheets } from '../../../common/worksheets-imports'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles, commonStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { ReadIcon } from '../../explore/src/read-icon'
import { ReadIconInverted } from '../../explore/src/read-icon-inverted'
import { ReadImage1 } from './src/read-image1'
import { ReadImage2 } from './src/read-image2'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'

export const SwitchesEmotionsExcitedRead: CardTemplate = {
    cardId: "b24c2a53-70ea-4666-98ae-1b85dcb038c9",
    title: 'Read',
    Header: ReadIconInverted,
    Icon: ReadIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Calming the mind, particularly if you are excited about an upcoming event and finding it hard to focus."} />
        </>
    ),
    Images: () => (
        <>
            <ReadImage1 />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Find a book you want to read, or grab one that you know you already love, that has a great story that you can enjoy."} />
                <DefaultTextWithDownArrow textdata={"Find a quiet comfortable space."} />
                <DefaultTextWithoutArrows textdata={"Now read read read!"} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: '\n \n Reading books fully engages the mind and imagination. Research by the University of Sussex found that people who had read for as little as six minutes had slower heart rates, reduced muscle tension, and lower stress. Neuroscientist Dr. David Lewis from the university said, “words on the printed page stimulate your creativity and cause you to enter what is essentially an altered state of consciousness.”',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice reading to increase literacy and critical thinking skills.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice directing excited energy into a quiet activity.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by asking students to brainstorm the benefits of reading for fun.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Create a chart with categories such as Imagination, Adventure, Enjoyment, Empathy, Intelligence, and Fun.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Optional Activity: Library Visit (45 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Take students to the library.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct them to select a book they would like to read for fun.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Assignment (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that they will be completing a book review on a book of their choosing, and set a due date.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the Book Review worksheet. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the components of the review, including a summary, favourite part, and overall rating.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- Set a due date for the assignment, either as an in-class activity or homework.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reading Time (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allocate time for students to start reading their chosen books and complete their book review. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If not completed in class, assign as homework.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Book Review Presentation (3 minutes each, depending on class size):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In subsequent classes, invite students to present their book reviews.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to share their thoughts on the story, characters, and overall experience.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Debrief (2 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss with students about their reading experiences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explore how they feel when immersed in a story and whether they empathise with characters.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the positive impact of reading on mental health.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude by emphasising that reading is a valuable activity with numerous health benefits.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to continue reading for pleasure and exploring different genres.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Express enthusiasm for upcoming book reviews and celebrate the joy of reading.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, invite them to explore switches in the Switch4Schools app. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}><DefaultText style={[CommonStyles.mainLessonText, { fontWeight: 'bold' }]}>Note:</DefaultText> Adjust timing based on the pace of the class and individual needs.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Library</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Book Review worksheet' link={Worksheets.bookReview.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Psychology of Reading - </DefaultText>
                        <DefaultTextWithLink text="https://www.wordsalive.org/blog/2018/9/27/the-psychology-of-reading" />
                        <Br />
                        <DefaultText style={CommonStyles.lessonPlanText}>The Psychological Benefits of Reading - </DefaultText>
                        <DefaultTextWithLink text='https://baos.pub/the-psychological-benefits-of-reading-85bce6b769da' />
                        <Br />
                        <DefaultText style={CommonStyles.lessonPlanText}>Why you should read this out loud - </DefaultText>
                        <DefaultTextWithLink text='https://www.bbc.com/future/article/20200917-the-surprising-power-of-reading-aloud' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='Try reading out loud.' />
            <DefaulTextWithTipsIcon textdata='Once you’ve read the book, it can be a great idea to write a summary of the book, how it made you feel, and if you enjoyed it or not. This will help you remember what the book was about and help guide you to other stories you might find interesting.' />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Read.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Excited]
}

