import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { RouteProp, useRoute } from '@react-navigation/native'
import { useState } from 'react'
import { GestureResponderEvent, Pressable, StyleSheet, View } from 'react-native'
import { CreateClassGroupMutation, CreateClassGroupMutationVariables } from '../../common/API'
import { UserGroup } from '../../common/constants'
import { createClassGroup } from '../../common/graphql/mutations'
import { AdminStackNavigatorParamList } from '../admin/admin-route-param-types'
import { mutateClassGroupRefetchQueries, mutateSchoolAdminClassGroupRefetchQueries } from '../admin/graphql-scripts'
import ButtonGroupSubmitCancel from '../common/button-group-cancel-submit'
import InputDefault from '../common/input-default'
import { rvIsLoading } from '../common/loading'
import { rvUserGroup } from '../login/login-state'
import { rvCurrentSchoolID, rvSelectedCampusID } from './school-admin-state'


type Props = {
    onFinishSubmit?: () => void
}
const SchoolAdminFormAddGroup = ({ onFinishSubmit }: Props) => {

    const userGroup = useReactiveVar(rvUserGroup)
    const { params } = useRoute<RouteProp<AdminStackNavigatorParamList, "AdminSchool">>() || {}

    const currentSchoolID = params?.id || useReactiveVar(rvCurrentSchoolID)
    const currentCampusID = useReactiveVar(rvSelectedCampusID)

    const [createClassGroupMutation] = useMutation<CreateClassGroupMutation, CreateClassGroupMutationVariables>(gql`${createClassGroup}`, { refetchQueries: userGroup === UserGroup.Admin ? mutateClassGroupRefetchQueries : mutateSchoolAdminClassGroupRefetchQueries })

    const [groupName, setGroupName] = useState('')

    const handleContainerPress = (e: GestureResponderEvent) => e.preventDefault()

    const handleConfirm = async () => {
        try {

            if (!currentCampusID) {
                throw "Campus is not assigned to any school"
            }

            if (!currentSchoolID) {
                throw "School not found"
            }

            rvIsLoading(true)
            await createClassGroupMutation({
                variables: {
                    input: {
                        name: groupName,
                        schoolID: currentSchoolID,
                        campusID: currentCampusID
                    }
                }
            })
            rvIsLoading(false)
            onFinishSubmit && onFinishSubmit()
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Pressable onPress={handleContainerPress} style={styles.container}>
            <View style={styles.leftFormContainer}>

                <InputDefault
                    value={groupName}
                    onChangeText={setGroupName}
                    placeholder='Group name'
                    customInputStyle={styles.input}
                />
            </View>

            <ButtonGroupSubmitCancel onCancel={() => onFinishSubmit && onFinishSubmit()} onConfirm={handleConfirm} />

        </Pressable>
    )
}
export default SchoolAdminFormAddGroup

const defaultFormFontSize = 14

const styles = StyleSheet.create({
    container: {
        width: '100%',
        maxWidth: 400,
        flexDirection: 'row',
        alignItems: 'center',
        height: 50,
        marginVertical: 10,
    },
    leftFormContainer: {
        flex: 1,
        marginRight: 5,
    },
    input: {
        height: 25,
        borderRadius: 0,
        paddingVertical: 0,
        marginBottom: 0,
    },
})