import { ScrollView, StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithLink from '../common/default-text-with-link'
import { DefaultTextWithRvColor } from '../common/default-text-with-rv-color'
import { ExtraTipsIcon } from '../common/extra-tips'
import { MaterialsEquipment } from '../common/lesson-plan-const'
import { LessonPlanContainer } from '../common/lesson-plan-container'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import LessonPlan from '../common/lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import SwitchCardVideo from '../../common/switch-card-video'

const emotionRegulationVideo1: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/FTnCMxEnnv8",
    title: ""
}
const emotionRegulationVideo2: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/nioadOr04dY",
    title: ""
}

export const SwitchesLessonPlansEmotionalRegulation: CardTemplate = {
    cardId: "aaa2d9fe-ba53-42f0-a9c8-124e2b98d40f",
    title: 'Emotional regulation',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Be able to develop strategies to manage and regulate their emotions effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Describe the two systems of thinking (reactive and responsive).</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by reviewing the emotions covered in the previous year.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share situations where they successfully or unsuccessfully managed their emotion.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding how the brain works (8 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the Hand Brain model (more details in the tools section and lesson plan F12 Hand Brain Model).  Point out the two systems in our brain; our reactive emotional “ape” brain and our conscious responsive brain, “you”.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the “My brain” poster and explain that one of the roles the amygdala plays is to create and send chemicals through our body that we interpret as emotions.  These are:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Adrenalin (the fight or flight hormone)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Dopamine (the feel goods)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Oxytocin (the cuddle buddy)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Serotonin (the calm and cool chemical)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Endorphins (the energy booster)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Group discussion: What are some things we can do to get our brain to issue a daily DOSE of positive chemicals? Example, laughter, play, sleep, hug a loved one.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the flight or flight response and how it is triggered by perceived threat or danger.  The brain releases adrenalin which will tell the body to prepare for action (The heart will start to race and breathing will get faster).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that we can use switches to control the release of these chemicals in the body, either by changing the way we feel or the way we think.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Emotional Regulation Strategies (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the concept of emotional regulation and its importance in managing strong emotions effectively.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the level 3 switches and present various strategies for emotional regulation, such as deep breathing, counting to 10, or taking a break. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students share their favourite switches or their own strategies or techniques they use to calm themselves down. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Role-Play: Emotional Regulation (20 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide the class into pairs or small groups.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide each group with a role-play scenario card. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>i. Scenario: Frustration at a puzzle </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Pretend you're trying to solve a puzzle, but it's challenging and you feel frustrated.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Show how you can take deep breaths, take a break, and try again calmly. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>ii. Scenario: Disappointment at losing a game</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Imagine you've lost a game you really wanted to win, and you feel disappointed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Demonstrate how you can congratulate the winner, focus on having fun, and not let disappointment ruin your mood.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>iii. Scenario: Feeling scared during a thunderstorm</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Picture yourself feeling scared during a thunderstorm. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Show how you can comfort yourself by taking deep breaths, reminding yourself it's just a storm, and finding a comforting activity like reading or listening to music.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>iv. Scenario: Feeling scared during a thunderstorm</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Pretend your sibling takes a toy you were playing with, and you feel angry.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Demonstrate how you can use words to express your feelings calmly, ask for the toy back, and find a solution that makes both of you happy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>v. Scenario: Feeling nervous before a test</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Imagine you're about to take a test and you feel nervous. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Show how you can take deep breaths, remind yourself that you prepared well, and focus on doing your best without getting overwhelmed by nerves.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>vi. Scenario: Sadness after a friend cancels plans </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Pretend your friend cancels plans you were excited about, and you feel sad.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Demonstrate how you can talk about your feelings with someone you trust, find an alternative activity to enjoy, or plan something fun for another day.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>vii. Scenario: Excitement and impatience on your birthday</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Picture yourself feeling excited and impatient on your birthday morning.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Show how you can take deep breaths, practice patience, and engage in a calming activity while waiting for the celebration to start.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>viii. Scenario: Feeling overwhelmed with homework </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Imagine having a lot of homework and feeling overwhelmed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Demonstrate how you can break tasks into smaller, manageable parts, take short breaks, and ask for help when needed to avoid getting stressed. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>ix. Scenario: Embarrassment after making a mistake in public </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Pretend you made a mistake in front of other people and feel embarrassed. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Show how you can take deep breaths, remind yourself that everyone makes mistakes, and move on without dwelling on embarrassment. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>x. Scenario: Dealing with jealousy over a friend's success </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Description: Imagine feeling jealous when a friend accomplishes something you wanted to achieve. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubTextDetails}>Goal: Demonstrate how you can celebrate your friend's success, focus on your own goals, and find inspiration instead of letting jealousy consume you. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In their groups, students should act out the scenarios while applying the emotional regulation strategies discussed earlier.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to discuss and reflect on their experiences after each role-play.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share one strategy they learned for emotional regulation and one new perspective they gained from the lesson. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of practicing emotional regulation.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Role play scenario handout' link={Worksheets.rolePlayScenarioHandouts.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Emotion wheel poster (level 3)' link={"https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Emotion+Wheel+Poster+3-levels+(1).pdf"} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Level 3 quick reference guide' link={"https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/level+3+QRG.pdf"} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='My brain poster' link={"https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Brain+Poster.pdf"} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Importance of Emotional Intelligence (Incl. Quotes)Top of Form -</DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/importance-of-emotional-intelligence/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>How Can We Help Kids With Self-Regulation? -</DefaultText>
                        <DefaultTextWithLink text='https://childmind.org/article/can-help-kids-self-regulation/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Dan Siegel's Hand Model of the Brain, Flip Your Lid -</DefaultText>
                        <DefaultTextWithLink text='https://youtu.be/FTnCMxEnnv8' />
                        <SwitchCardVideo video={emotionRegulationVideo1} />

                        <DefaultText style={CommonStyles.lessonPlanText}>What is Mindfulness? (How the brain works) -</DefaultText>
                        <DefaultTextWithLink text='https://iview.abc.net.au/video/CK1742V001S00' />
                        <DefaultText style={CommonStyles.lessonPlanText}>How We Learn – Synapses and Neural Pathways -</DefaultText>
                        <DefaultTextWithLink text='https://vimeo.com/230426221' />
                        <DefaultText style={CommonStyles.lessonPlanText}>The Brain When You're Happy | Neuro Champions -</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=nioadOr04dY' />
                        <SwitchCardVideo video={emotionRegulationVideo2} />

                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubTextDetails: {
        paddingLeft: 120,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        marginVertical: 10,
    },
    durationText: {
        marginBottom: 10,
        color: DecidaColors.Gray,
        alignSelf: 'center'
    },
})
