import { CellObject } from "xlsx";
import { StudentQueryItem } from "./common-import-school-data-const-and-types";

export type StudentRow = {
    studentID: CellObject['v'],
    firstName: CellObject['v'],
    lastName: CellObject['v'],
    email: CellObject['v'],
    nickname: CellObject['v'],
    avatar: CellObject['v'],
    yearLevel: CellObject['v'],
    row: number,
    classes: string[]
}

/**
 * Detect if student has changed for the purpose of school data import.
 */
export const importStudentHasChanged = (schoolID: string, studentRow: StudentRow, existingStudent: StudentQueryItem) =>
!!(
    (studentRow.email && studentRow.email !== existingStudent.email)
    || (studentRow.firstName && studentRow.firstName !== existingStudent.firstName)
    || (studentRow.lastName && studentRow.lastName !== existingStudent.lastName)
    || (schoolID !== existingStudent.schoolID)
    || (studentRow.studentID && studentRow.studentID.toString() !== existingStudent.schoolStudentID)
    || (studentRow.yearLevel && studentRow.yearLevel.toString() !== existingStudent.yearLevel)
)
