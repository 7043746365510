import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { AntDesign } from '@expo/vector-icons';
import { useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { ListTeacherClassesByClassIDQuery, ListTeacherClassesByClassIDQueryVariables, Teacher, TeacherClass, TeacherRole } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { listTeacherClassesByClassID } from '../../common/graphql/queries';
import { DefaultText } from '../common/default-text';
import { rvShowTeacherForm, rvSidebarClassID } from './school-admin-state';
import SchoolAdminFormTeacher from './school-admin-form-teacher';
import SchoolAdminSidebarTeacherListItem from './school-admin-sidebar-teacher-list-item';

const classTeachers: Teacher[] = [
    { id: "1sadsad", schoolID: "12312", firstName: 'test', lastName: 'test', cognitoUsername: "teacher1", email: 'teacher@mailinator.com', __typename: "Teacher", _version: 1, _lastChangedAt: 2, role: TeacherRole.TEACHER },
    { id: "1asdasdsadsad", schoolID: "12312", firstName: 'test2', lastName: 'test2', cognitoUsername: "teacher2", email: 'teacher2@mailinator.com', __typename: "Teacher", _version: 1, _lastChangedAt: 2, role: TeacherRole.ADMINISTRATION }
]

const SchoolAdminSidebarTeacherList = () => {

    const currentSidebarClassID = useReactiveVar(rvSidebarClassID)
    const showTeacherForm = useReactiveVar(rvShowTeacherForm)


    const toggleAddTeacherForm = () => {
        rvShowTeacherForm(!showTeacherForm)
    }

    const { data: teacherClassQuery } = useQuery<ListTeacherClassesByClassIDQuery, ListTeacherClassesByClassIDQueryVariables>(gql`${listTeacherClassesByClassID}`, { variables: { classID: currentSidebarClassID || "" }, skip: !currentSidebarClassID })
    const classTeachers = teacherClassQuery?.listTeacherClassesByClassID?.items.filter((item) => item?._deleted !== true) as TeacherClass[]


    return (
        <View style={styles.container}>
            <DefaultText style={styles.teachersText}>
                Teachers
            </DefaultText>
            <View>
                {classTeachers?.length ? classTeachers.map((teacherClass) => (
                    <SchoolAdminSidebarTeacherListItem key={teacherClass.id} teacherClass={teacherClass} />
                )) : (
                    <DefaultText style={styles.noTeachersText}>No teachers added</DefaultText>
                )}
                <View style={{ zIndex: 99 }}>
                    {showTeacherForm && (
                        <SchoolAdminFormTeacher onFinishSubmit={toggleAddTeacherForm} />
                    )}
                </View>
                <Pressable onPress={toggleAddTeacherForm} style={styles.buttonAddClassContainer}>
                    <DefaultText style={styles.text}><AntDesign name="plus" size={20} color={DecidaColors.AppleSystemGray2Dark} /> Add teacher</DefaultText>
                </Pressable>
            </View>
        </View>
    )
}
export default SchoolAdminSidebarTeacherList



const styles = StyleSheet.create({
    container: {
        marginTop: 40,
        borderBottomWidth: 1,
        borderBottomColor: DecidaColors.GrayBorder,
        zIndex: 99
    },
    noTeachersText: {
        fontSize: 20,
        fontWeight: 'bold',
        fontStyle: 'italic',
        color: DecidaColors.Blue,
        textAlign: 'center',
        marginTop: 20,
    },
    teachersText: {
        fontSize: 22,
        fontWeight: 'bold',
        color: DecidaColors.AppleSystemGray2Dark,
        marginLeft: 40,
    },
    buttonAddClassContainer: {
        marginVertical: 25,
        paddingLeft: 20,
    },
    text: {
        fontSize: 18,
        color: DecidaColors.GrayText,
    },
})