import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import SwitchCardVideo from '../../common/switch-card-video'

const myEmotionsVideo1: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/3fTRWpf-eH4",
    title: ""
}

const myEmotionsVideo2: Videos = {
    id: "2",
    url: "https://www.youtube.com/embed/MeNY-RxDJig",
    title: ""
}

const myEmotionsVideo3: Videos = {
    id: "3",
    url: "https://www.youtube.com/embed/dOkyKyVFnSs",
    title: ""
}

export const SwitchesLessonPlansMyEmotionsLessonPlansLevel1: CardTemplate = {
    cardId: "6c86b2c3-e86e-48fa-80fc-daa7926f55bf",
    title: 'My Emotions',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Identify and express a range of emotions, including happy, anxious, angry, sad, scared, and excited.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• They will also begin to understand that emotions are a normal part of life and that it is important to express and manage them appropriately. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Greet the students and gather them in a circle. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by asking questions like, "How are you feeling today?" and encourage them to share their emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that today's lesson is all about emotions and that we will be learning about different feelings.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Emotion Wheel (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that emotions are feelings that we experience inside our body. Emotions help us understand and react to different situations in our lives. For example, if you see a cute puppy, you might feel happy and want to pet it. If someone is mean to you, you might feel angry or sad. Our brain, heart, and other parts of our body all work together to help us feel emotions. It's important to learn how to understand and express our emotions in healthy ways.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that some of these reactions happen inside the body (you can’t see) like the feeling of butterflies in the stomach. And some happen to the visible parts of our body, like clenching your fists or crying. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show the students the emotion wheel depicting the different emotions (happy, anxious, angry, sad, scared, excited).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to each emotion one by one and ask the students to identify the emotion. Discuss how they know they are feeling that way.  What happens to the body? (e.g., smiling when you are happy)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to share personal experiences or examples of when they felt each emotion.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce that it is normal to experience different emotions and that everyone feels these emotions at times.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Drawing and Expressing Emotions (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute blank paper or drawing sheets and art supplies to each child.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to choose an emotion from the chart and draw a picture that represents that emotion.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide guidance and support as needed, asking questions like, "What does a happy face look like? How can you show excitement in your drawing?".</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once they finish drawing, invite volunteers to share their artwork with the class and briefly explain the emotion they chose.  </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Wrap-up and Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students back in the circle.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to reflect on what they learned about emotions today.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to share how they might respond to someone feeling a particular emotion (e.g., comforting a sad friend, or celebrating with an excited friend).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude the lesson by emphasising that it's important to understand and acknowledge our own emotions as well as those of others.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Extension Activities:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Read storybooks about emotions and discuss the characters' feelings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play emotion charades, where students act out different emotions and classmates guess the feeling.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Create an emotions-themed bulletin board or display using the students's artwork.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice recognising and labelling emotions throughout the day, prompting discussions about how they feel in different situations.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}><DefaultText style={CommonStyles.lessonPlanText}>• </DefaultText><DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/My+emotions+(level+1).pdf' /></View>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Art supplies (crayons, coloured pencils, etc)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Blank paper</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Storybooks about emotions (optional)</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Can You Increase Your Emotional Intelligence? -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.psychologytoday.com/au/blog/cutting-edge-leadership/202306/can-you-increase-your-emotional-intelligence' link='https://www.psychologytoday.com/au/blog/cutting-edge-leadership/202306/can-you-increase-your-emotional-intelligence' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Why do we have feelings? -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://youtu.be/3fTRWpf-eH4' link='https://youtu.be/3fTRWpf-eH4' />
                        <SwitchCardVideo video={myEmotionsVideo1} />

                        <DefaultText style={CommonStyles.lessonPlanText}>Guess the Feelings and Emotions -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://youtu.be/MeNY-RxDJig' link='https://youtu.be/MeNY-RxDJig' />
                        <SwitchCardVideo video={myEmotionsVideo2} />

                        <DefaultText style={CommonStyles.lessonPlanText}>Inside Out: Guessing the feelings -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.youtube.com/watch?v=dOkyKyVFnSs' link='https://www.youtube.com/watch?v=dOkyKyVFnSs' />
                        <SwitchCardVideo video={myEmotionsVideo3} />


                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}
