import { makeVar, useReactiveVar } from '@apollo/client';
import { Picker } from '@react-native-picker/picker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';

import { DecidaColors } from '../../common/decida-colors';
import { ButtonText } from './button-text';
import { rvCurrentClass } from './common-state';
import { DayOptions } from './const';

type OptionsProps = {
    label: string;
    value: string;
    onPress: () => void
}

const PICKER_OPTIONS = [
    {
        label: "Last 7 days",
        value: DayOptions.Week,
    },
    {
        label: "Last 4 weeks",
        value: DayOptions.FourWeeks,
    },
    {
        label: "This year",
        value: DayOptions.Year,
    },


] as OptionsProps[]

export const rvTeacherStatsAllPeriodPickerOptions = makeVar<DayOptions>(DayOptions.Week)
export const rvTeacherStatsAllFilterDate = makeVar<string | null>(null)

const DropdownPickerFilter = () => {

    const currentClass = useReactiveVar(rvCurrentClass)
    const [selectedFilter, setSelectedFilter] = useState<string>('7')
    const [showPicker, setShowPicker] = useState<boolean>(false)

    const handlePickerValueChange = async (value: string) => {
        setSelectedFilter(value)
        setShowPicker(false)
    }

    const handleFilterDateChanged = async () => {
        let filterDate: string = ''

        switch (selectedFilter) {
            case DayOptions.Week:
            case DayOptions.FourWeeks:
                filterDate = moment().subtract(selectedFilter, 'd').startOf('day').toISOString()
                break
            case DayOptions.Year:
                filterDate = moment().startOf('year').toISOString()
                break
            default:
                filterDate = moment().subtract('7', 'd').startOf('day').toISOString()
                break;
        }

        rvTeacherStatsAllPeriodPickerOptions(selectedFilter as DayOptions)
        rvTeacherStatsAllFilterDate(filterDate)

    }

    useEffect(() => {
        handleFilterDateChanged()
    }, [selectedFilter, currentClass?.id])

    const iosDevices = Platform.OS === "ios"
    const selectedItem = PICKER_OPTIONS.find(opt => opt.value === selectedFilter)
    return (
        <>
            {iosDevices && (
                <ButtonText style={styles.filterButton} onPress={() => setShowPicker(!showPicker)}>
                    {selectedItem?.label || "Select filter"}
                </ButtonText>
            )}
            <Picker
                style={[styles.picker, { display: iosDevices && showPicker ? 'flex' : iosDevices ? 'none' : 'flex' }]}
                selectedValue={selectedFilter}
                onValueChange={handlePickerValueChange}>
                <ButtonText>
                    Done
                </ButtonText>
                {PICKER_OPTIONS.map((options, index) => {
                    const { label, value } = options;
                    return <Picker.Item key={value} label={label} value={value} />
                })}
            </Picker>
        </>

    )
}

export default DropdownPickerFilter

const styles = StyleSheet.create({
    picker: {
        width: 200,
        padding: 5,
        borderRadius: 40,
        marginVertical: 5
    },
    filterButton: {
        color: DecidaColors.Gray,
        borderWidth: 1,
        borderRadius: 15,
        paddingHorizontal: 10,
        paddingVertical: 5,
        fontSize: 16,
        marginVertical: 5,
        width: 200,
        borderColor: DecidaColors.Gray
    }
})