import { createStackNavigator } from '@react-navigation/stack'
import { CheckInEmotionalIntensity } from '../check-in/check-in-emotional-intensity'
import { CheckInEmotionalWheel } from '../check-in/check-in-emotional-wheel'
import { CheckInRequestChatWithTeacher } from '../check-in/check-in-request-chat-with-teacher'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'
import { CheckInTirednessScale } from '../check-in/check-in-tiredness-scale'

import { RootView } from '../common/root-view'
import { ScreenNames } from '../common/screen-names'
import { StudentCheckInFinish } from './student-check-in-finish'
import { gql, useReactiveVar, useSubscription } from '@apollo/client'
import { rvCheckInState } from '../check-in/check-in-state'
import { ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables, ListStudentCheckInsByStudentIdQuery, ListStudentCheckInsByStudentIdQueryVariables, OnMutateStudentCheckInByCheckInSessionIdSubscription, OnMutateStudentCheckInByCheckInSessionIdSubscriptionVariables, OnMutateStudentCheckInByStudentIdSubscription, OnMutateStudentCheckInByStudentIdSubscriptionVariables } from '../../common/API'
import { onMutateStudentCheckInByCheckInSessionId, onMutateStudentCheckInByStudentId } from '../../common/graphql/subscriptions'
import { updateListQueryCache } from '../common/update-list-query-cache'
import { listStudentCheckInsByCheckInSessionId, listStudentCheckInsByStudentId } from '../../common/graphql/queries'
import { handleRestartApp } from '../common/handle-restart-app'
import { CheckInWellbeingQuestions } from '../check-in/check-in-wellbeing-questions'
import { useStudentState } from './use-student-state'
import CheckInEating from '../check-in/check-in-eating'

const Stack = createStackNavigator<CheckInNavigatorParamList>()

export const StudentCheckInMain = () => {

    const { checkinsessionID = '' } = useReactiveVar(rvCheckInState)
    const { currentStudent } = useStudentState()

    const { error: errorStudentCheckInsByCheckInSessionIDSubscription } = useSubscription<OnMutateStudentCheckInByCheckInSessionIdSubscription, OnMutateStudentCheckInByCheckInSessionIdSubscriptionVariables>(
        gql`${onMutateStudentCheckInByCheckInSessionId}`,
        {
            variables: {
                checkinsessionID,
            },
            skip: !checkinsessionID,
            onData: updateListQueryCache<ListStudentCheckInsByCheckInSessionIdQuery, ListStudentCheckInsByCheckInSessionIdQueryVariables, OnMutateStudentCheckInByCheckInSessionIdSubscription>({ listQuery: listStudentCheckInsByCheckInSessionId, listQueryName: 'listStudentCheckInsByCheckInSessionId', subscriptionName: 'onMutateStudentCheckInByCheckInSessionId', variables: { checkinsessionID, limit: 5000 } })
        }
    )
    const { error: errorStudentCheckInsByStudentIDSubscription } = useSubscription<OnMutateStudentCheckInByStudentIdSubscription, OnMutateStudentCheckInByStudentIdSubscriptionVariables>(
        gql`${onMutateStudentCheckInByStudentId}`,
        {
            variables: {
                studentID: currentStudent?.id || "",
            },
            skip: !currentStudent,
            onData: updateListQueryCache<ListStudentCheckInsByStudentIdQuery, ListStudentCheckInsByStudentIdQueryVariables, OnMutateStudentCheckInByStudentIdSubscription>({ listQuery: listStudentCheckInsByStudentId, listQueryName: 'listStudentCheckInsByStudentId', subscriptionName: "onMutateStudentCheckInByStudentId", variables: { studentID: currentStudent?.id || "", limit: 5000 } })
        }
    )

    if (errorStudentCheckInsByCheckInSessionIDSubscription !== undefined ||
        errorStudentCheckInsByStudentIDSubscription !== undefined) {
        handleRestartApp()
    }

    return (
        <RootView>
            <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={ScreenNames.CheckInTirednessScale}>
                {/* <Stack.Screen name={ScreenNames.CheckInStart} component={StudentCheckInStart} options={{ title: 'CheckInStart' }} /> */}
                <Stack.Screen name={ScreenNames.CheckInTirednessScale} component={CheckInTirednessScale} />
                <Stack.Screen name={ScreenNames.CheckInEmotionalWheel} component={CheckInEmotionalWheel} />
                <Stack.Screen name={ScreenNames.CheckInEating} component={CheckInEating} />
                <Stack.Screen name={ScreenNames.CheckInEmotionalIntensity} component={CheckInEmotionalIntensity} />
                <Stack.Screen name={ScreenNames.CheckInRequestChatWithTeacher} component={CheckInRequestChatWithTeacher} />
                <Stack.Screen name={ScreenNames.CheckInWellbeingQuestion} component={CheckInWellbeingQuestions} />
                <Stack.Screen name={ScreenNames.CheckInFinish} component={StudentCheckInFinish} />
            </Stack.Navigator>
        </RootView>
    )
}
