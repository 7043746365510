import { gql, useQuery } from '@apollo/client'
import { Feather } from '@expo/vector-icons'
import _ from 'lodash'
import { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { ListInvoiceBySchoolIDQuery, ListInvoiceBySchoolIDQueryVariables, School } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultButton, DefaultButtonTypes } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { SectionTitleAndText } from '../common/section-title-and-text'
import { listInvoiceBySchoolID } from '../../common/graphql/queries'
import DefaultTextWithCustomLink from '../switches/common/default-text-with-custom-link'
import { schoolAdminBillingDetailsContainerStyle } from './school-admin-const'
import SchoolAdminnFormSchool from './school-admin-form-school'
import { openUrlWebView } from '../web-view/open-url-web-view'

type Props = {
  schoolData: School
}
const SchoolAdminBillingSchoolDetails = ({ schoolData }: Props) => {

  const { data } = useQuery<ListInvoiceBySchoolIDQuery, ListInvoiceBySchoolIDQueryVariables>(gql`${listInvoiceBySchoolID}`, { variables: { schoolID: schoolData?.id } })

  const invoiceList = _.orderBy(data?.listInvoiceBySchoolID?.items.filter((item) => item?._deleted !== true), "createdAt", "desc") || []

  const [editMode, setEditMode] = useState(false)

  const toggleEditMode = () => setEditMode(prev => !prev)


  const handleUpgrade = () => {
    openUrlWebView('https://www.switch4schools.com.au/purchase')
  }

  return (
    <View>
      <DefaultText style={styles.sectionTitle}>School Information</DefaultText>
      <View style={schoolAdminBillingDetailsContainerStyle}>
        {editMode ? (
          <>
            <DefaultText style={styles.addCordinatorText}>Edit school info</DefaultText>
            <SchoolAdminnFormSchool schoolData={schoolData} toggleEditMode={toggleEditMode} />
          </>
        ) : (
          <>
            <TouchableOpacity onPress={toggleEditMode} style={styles.editButton}>
              <Feather name='edit' size={30} color={DecidaColors.Green} />
            </TouchableOpacity>

            <SectionTitleAndText title='School name' text={schoolData?.name || ""} />
            <SectionTitleAndText title='Billing address' text={schoolData?.billingAddress || ""} />
            <SectionTitleAndText title='ABN' text={schoolData?.abn || ""} />
          </>
        )}
      </View>

      {/* Subscription details NOT EDITABLE */}
      <DefaultText style={styles.sectionTitle}>Subscription</DefaultText>
      <View style={schoolAdminBillingDetailsContainerStyle}>
        <SectionTitleAndText title='Current subscription (number of licences)' text={schoolData?.licenseTotal || ""} />
        <SectionTitleAndText title='Renewal date' text={schoolData?.renewalDate || ""} />
        <SectionTitleAndText title='Whole school subscription' text={schoolData?.wholeSchoolSubscription ? "Yes" : "No"} />
        <SectionTitleAndText title='View or download invoice' withoutText={invoiceList.length > 0} />
        <View>
          {invoiceList.map((invoice) => {
            return <View key={invoice?.id} style={{ marginBottom: 10, flexDirection: 'row', alignItems: 'center' }}>
              <DefaultText style={styles.text}>
                •
              </DefaultText>
              <DefaultTextWithCustomLink key={invoice?.id} style={styles.text} text={invoice?.name || ""} link={invoice?.url || ""} />
            </View>
          })}
        </View>
        <SectionTitleAndText title='Comments' text={schoolData?.billingNotes || ""} />
        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', }}>
          <DefaultButton onPress={handleUpgrade} buttonTextStyle={{ textAlign: 'center' }} type={DefaultButtonTypes.Small} style={styles.upgradeButton}>RENEW OR CHANGE SUBCSRIPTION</DefaultButton>
          <DefaultButton onPress={handleUpgrade} buttonTextStyle={{ textAlign: 'center' }} type={DefaultButtonTypes.Small} style={styles.upgradeButton}>PURCHASE ADDITIONAL LICENCES</DefaultButton>
        </View>
      </View>
    </View>
  )
}

export default SchoolAdminBillingSchoolDetails
const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    marginRight: 5
  },
  sectionTitle: {
    marginLeft: 20,
    fontWeight: '600',
    color: DecidaColors.GrayText
  },
  editButton: {
    position: 'absolute',
    top: -10,
    left: -10
  },
  addCordinatorText: {
    marginLeft: 10,
    color: DecidaColors.GrayText
  },
  upgradeButton: {
    flex: 1,
    backgroundColor: DecidaColors.DarkBlue,
    paddingVertical: 20,
    paddingHorizontal: 4,
    marginHorizontal: 4
  }
})