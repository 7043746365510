import { MouseEvent, ReactNode, useEffect } from 'react'
import { GestureResponderEvent, Platform, Pressable, StyleSheet } from 'react-native'

export type IEventTypes = (GestureResponderEvent & PointerEvent & MouseEvent<HTMLDivElement, globalThis.MouseEvent> & TouchEvent & { layerX: number, layerY: number })

type Props = {
    children: ReactNode
    onMoveStart: (e: IEventTypes) => void
}

const CanvasContainerView = ({ children, onMoveStart }: Props) => {

    let isDrawing = false;
    let passive = false

    useEffect(() => {
        if (window && Platform.OS === 'web') {
            window.addEventListener('mousemove', onMouseMove)
            window.addEventListener('mousedown', onMouseDown)
            window.addEventListener('mouseup', onMouseUp)
            window.addEventListener('touchstart', onTouchStartWeb, { passive }) // passive = true indicates that the function specified by listener will never call preventDefault()
            window.addEventListener('touchmove', onMouseMove, { passive }) // but we need this to prevent any interction (such a default pull-refresh browser behavior) so we return this to false
        }

        return () => {
            if (window && Platform.OS === 'web') {
                passive = true
                window.removeEventListener('mousemove', onMouseMove)
                window.removeEventListener('mousedown', onMouseDown)
                window.removeEventListener('mouseup', onMouseUp)
                window.removeEventListener('touchstart', onTouchStartWeb)
                window.removeEventListener('touchmove', onMouseMove)
            }
        }
    }, [window])

    const onMouseDown = () => {
        isDrawing = true
    };

    const onMouseUp = () => isDrawing = false;
    const onMouseMove = (e: globalThis.MouseEvent | TouchEvent | GestureResponderEvent) => {
        if (isDrawing && Platform.OS === 'web') {
            e.preventDefault()
            onMoveStart(e as unknown as IEventTypes)
            return
        }

        if (Platform.OS !== 'web') {
            onMoveStart(e as unknown as IEventTypes)
        }
    }

    const onTouchStartWeb = (e: TouchEvent) => {
        onMouseDown()
    }

    return (
        <Pressable
            onTouchMove={Platform.OS === 'web' ? undefined : onMouseMove} // use event listener for web
            style={Platform.OS === 'web' ? styles.svgContainerWeb : styles.svgContainer}
        >
            {children}
        </Pressable>
    )
}
export default CanvasContainerView

const styles = StyleSheet.create({
    svgContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
    },
    svgContainerWeb: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: 'transparent',
        overflow: 'hidden'
    },
})