import { gql, useQuery } from '@apollo/client'
import { Fragment } from 'react'
import { StyleSheet, View } from 'react-native'
import { GetStudentQuery, GetStudentQueryVariables } from '../../common/API'
import { BackButton, BackButtonOnPress } from '../common/back-button'
import { DefaultText } from '../common/default-text'
import { lowRes } from '../common/use-responsive'
import { getStudent } from '../../common/graphql/queries'
import { avatars } from '../icon/avatar/avatars'

interface Props {
    back: BackButtonOnPress
    headerText: string
    studentId?: string | undefined | null
}

export const CheckInHeader = ({ back, headerText, studentId }: Props) => {


    const { data: studentResponse } = useQuery<GetStudentQuery, GetStudentQueryVariables>(gql`${getStudent}`, { variables: { id: studentId || "" }, skip: !studentId })
    const student = studentResponse?.getStudent

    const Avatar = student && avatars[student.avatar as keyof typeof avatars] || Fragment

    return (
        <View style={[styles.mainContainer, {
            flex: lowRes ? 1 : undefined,
            height: lowRes ? '100%' : undefined,
            justifyContent: lowRes ? 'flex-end' : undefined
        }]}>
            <View style={styles.backButtonContainer}>
                <BackButton onPress={back} />
            </View>
            <View style={[styles.header, { flex: lowRes ? 1 : undefined }]}>
                <View style={styles.containerView}>
                    <View style={styles.avatar}>
                        <Avatar />
                    </View>
                    <View testID="check-in-header" style={styles.headerTextContainer}>
                        <DefaultText textProps={{ testID: `header-text-${headerText}` }} style={styles.headerText}>{headerText}</DefaultText>
                    </View>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    mainContainer: {
        width: '100%',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerText: {
        fontSize: 25,
        textAlign: 'center',

    },
    avatar: {
        width: 75,
        height: 75,
    },
    containerView: {
        flexDirection: 'column',
        alignItems: 'center',

    },
    backButtonContainer: {
        position: lowRes ? 'absolute' : 'relative',
        top: 0,
        left: 20,
        alignContent: 'flex-start',
        justifyContent: 'flex-start'
    },
    headerTextContainer: {
        paddingTop: 17,
    }
})