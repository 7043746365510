import { gql, useQuery, useReactiveVar, useSubscription } from "@apollo/client";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables, ListFavouriteByCognitoUsernameQuery, ListFavouriteByCognitoUsernameQueryVariables, ListFindYourCalmByUserIdAndActivityIdQuery, ListFindYourCalmByUserIdAndActivityIdQueryVariables, ListTeacherCheckInsByTeacherIDQuery, ListTeacherCheckInsByTeacherIDQueryVariables, ListTeacherClassesByTeacherIDQuery, ListTeacherClassesByTeacherIDQueryVariables, OnMutateFavouriteByCognitoUsernameSubscription, OnMutateFavouriteByCognitoUsernameSubscriptionVariables, OnMutateFindYourCalmActivityRatingSubscription, OnMutateFindYourCalmActivityRatingSubscriptionVariables, OnMutateSchoolSubscriptionsBySchoolIdSubscription, OnMutateSchoolSubscriptionsBySchoolIdSubscriptionVariables, OnMutateSwitchRatingByUserIdSubscription, OnMutateSwitchRatingByUserIdSubscriptionVariables, OnMutateTeacherByCognitoUsernameSubscription, OnMutateTeacherByCognitoUsernameSubscriptionVariables, OnMutateTeacherCheckInByTeacherIDSubscription, OnMutateTeacherCheckInByTeacherIDSubscriptionVariables, OnMutateTeacherClassByTeacherIDSubscription, OnMutateTeacherClassByTeacherIDSubscriptionVariables, Teacher } from "../../common/API";
import { getTeacherByCognitoUsername, listFavouriteByCognitoUsername, listFindYourCalmByUserIdAndActivityId, listStudentsBySchoolID, listTeacherCheckInsByTeacherID } from "../../common/graphql/queries";
import { onMutateFavouriteByCognitoUsername, onMutateFindYourCalmActivityRating, onMutateSchoolSubscriptionsBySchoolId, onMutateSwitchRatingByUserId, onMutateTeacherByCognitoUsername, onMutateTeacherCheckInByTeacherID, onMutateTeacherClassByTeacherID } from "../../common/graphql/subscriptions";
import { CommonStyles } from "../common/const";
import { handleRestartApp } from "../common/handle-restart-app";
import { IndroductionTeacher } from "../common/introduction-teacher";
import { RootView } from "../common/root-view";
import { ScreenNames } from "../common/screen-names";
import { updateListQueryCache } from "../common/update-list-query-cache";
import useAppState from "../common/use-app-state";
import useConnectivity from "../common/use-connectivity";
import { useRedirectPages } from "../common/user-redirect-pages";
import { listTeacherClassesAndClassDetailsByTeacherID } from "../custom-graphql/queries/list-teacher-classes-and-class-details-by-teacher-id";
import { getLockDerviceRv, rvCurrentUser } from "../login/login-state";
import StudentIndividualHowToModal from "../student/student-individual-how-to-modal";
import { SwitchesActiviesDetailView } from "../switches/activities/switches-activies-detail-view";
import { SwitchesActiviesDisplayAll } from "../switches/activities/switches-activies-display-all";
import { Card } from "../switches/common/card";
import { rvSwitchesScreenState } from "../switches/common/current-screen-state";
import { SwitchesAcitiviesMain } from "../switches/navigation/switches-activity-main";
import { SwitchesBottomNavigation } from "../switches/navigation/switches-bottom-navigation";
import { SwitchesExploreAllMain } from "../switches/navigation/switches-explore-all-main";
import { SwitchesHomeMain } from "../switches/navigation/switches-home-main";
import { SwitchesToolsMain } from "../switches/navigation/switches-tools-main";
import { SwitchesEmotionsDetailView } from "../switches/switches-emotions-detail-view";
import { SwitchesExploreAll } from "../switches/switches-explore-all";
import { SwitchesHome } from "../switches/switches-home";
import { SwitchesMemes } from "../switches/switches-memes";
import { SwitchesToolsDisplayAll } from "../switches/tools/switches-tools-display-all";
import { TeacherCheckInMain } from "./teacher-check-in-main";
import { TeacherClassMain } from "./teacher-class-main";
import TeacherDashboardInfoModal from "./teacher-dashboard-info-modal";
import TeacherDashboardNewFeatureInfoModal from "./teacher-dashboard-new-feature-info-modal";
import TeacherEmotionIntensityScaleModal from "./teacher-emotion-intensity-scale-modal";
import { TeacherHeader } from "./teacher-header";
import { TeacherHome } from "./teacher-home";
import TeacherIndividualStudentStatsInfoModal from "./teacher-individual-student-stats-info-modal";
import TeacherModalOnboarding from "./teacher-modal-onboarding";
import { TeacherProfileMain } from "./teacher-profile-main";
import TeacherRequestForChatInfoModal from "./teacher-request-for-chat-info-modal";
import TeacherRequestForEmailNotificationsInfoModal from "./teacher-request-for-email-notifications-info-modal";
import { TeacherDrawerNavigatorPamarList } from "./teacher-route-param-types";
import {
  rvTeacherCurrentTeacher,
  rvTeacherDashboardInfoModal,
  rvTeacherEatingQuestionInfoModal,
  rvTeacherEmotionIntensityScaleModal,
  rvTeacherIndividualStudentStatsInfoModal,
  rvTeacherIntroduction,
  rvTeacherRequestForChatInfoModal,
  rvTeacherRequestForEmailNotificationsInfoModal,
  rvTeacherWellbeingInfoModal
} from "./teacher-state";
import { rvShowIndividualStudentHowTo } from "./teacher-student-individual-stats";
import TeacherWellbeing from "./teacher-wellbeing";
import TeacherWellbeingInfoModal from "./teacher-wellbeinng-info-modal";
import { UnlockTeacherDevice } from "./unlock-teacher-device";
import TeacherEatingQuestionInfoModal from "./teacher-eating-question-info-modal";

const SideMenu = createDrawerNavigator<TeacherDrawerNavigatorPamarList>();

export const TeacherMain = () => {
  const currentScreen = useReactiveVar(rvSwitchesScreenState);
  const user = useReactiveVar(rvCurrentUser)
  const denyAccess = useReactiveVar(getLockDerviceRv())

  const { data: teacherResponse } = useQuery<GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables>(gql`${getTeacherByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
  const currentTeacher = teacherResponse?.getTeacherByCognitoUsername?.items[0] as Teacher

  useEffect(() => {
    rvTeacherCurrentTeacher(currentTeacher)
  }, [currentTeacher])

  const studentStatsHowToModal = useReactiveVar(rvShowIndividualStudentHowTo)
  const teacherIntroduction = useReactiveVar(rvTeacherIntroduction);
  const emotionIntensityScaleModal = useReactiveVar(rvTeacherEmotionIntensityScaleModal)
  const requestForChatInfoModal = useReactiveVar(rvTeacherRequestForChatInfoModal)
  const requestForEmailNotificationsInfoModal = useReactiveVar(rvTeacherRequestForEmailNotificationsInfoModal)
  const individualStudentStatsInfoModal = useReactiveVar(rvTeacherIndividualStudentStatsInfoModal)
  const dashboardInfoModal = useReactiveVar(rvTeacherDashboardInfoModal)
  const wellbeingInfoModal = useReactiveVar(rvTeacherWellbeingInfoModal)
  const eatingQuestionInfoModal = useReactiveVar(rvTeacherEatingQuestionInfoModal)


  useAppState()
  useConnectivity()
  useRedirectPages()

  const { error: errorSchoolSubscription } = useSubscription<
    OnMutateSchoolSubscriptionsBySchoolIdSubscription,
    OnMutateSchoolSubscriptionsBySchoolIdSubscriptionVariables
  >(
    gql`
      ${onMutateSchoolSubscriptionsBySchoolId}
    `,
    {
      variables: { id: currentTeacher?.schoolID },
      skip: !currentTeacher,
      onData: updateListQueryCache({
        listQuery: listStudentsBySchoolID,
        listQueryName: 'listStudentsBySchoolID',
        subscriptionName: 'onMutateSchoolSubscriptionsBySchoolId',
      }),
    }
  );

  // Teacher subscription by username
  const { error: errorTeacherSubscription } = useSubscription<
    OnMutateTeacherByCognitoUsernameSubscription,
    OnMutateTeacherByCognitoUsernameSubscriptionVariables
  >(
    gql`
      ${onMutateTeacherByCognitoUsername}
    `,
    { variables: { cognitoUsername: user?.username || '' }, skip: !user }
  );

  // Teacher class subscriptions by teacherID
  const { error: errorTeacherClassSubscription } = useSubscription<
    OnMutateTeacherClassByTeacherIDSubscription,
    OnMutateTeacherClassByTeacherIDSubscriptionVariables
  >(
    gql`
      ${onMutateTeacherClassByTeacherID}
    `,
    {
      variables: { teacherID: currentTeacher?.id || '' },
      skip: !currentTeacher,
      onData: updateListQueryCache<ListTeacherClassesByTeacherIDQuery, ListTeacherClassesByTeacherIDQueryVariables, OnMutateTeacherClassByTeacherIDSubscription>({ listQuery: listTeacherClassesAndClassDetailsByTeacherID, listQueryName: 'listTeacherClassesByTeacherID', subscriptionName: 'onMutateTeacherClassByTeacherID', variables: { teacherID: currentTeacher?.id || "" } })
    }
  );

  // Rating subscriptions
  const { error: errorRatingSubscription } = useSubscription<OnMutateSwitchRatingByUserIdSubscription, OnMutateSwitchRatingByUserIdSubscriptionVariables>(gql`${onMutateSwitchRatingByUserId}`, { variables: { userId: currentTeacher?.id }, skip: !currentTeacher })

  // Favourite subscriptions
  const { error: errorFavouriteSubscription } = useSubscription<
    OnMutateFavouriteByCognitoUsernameSubscription,
    OnMutateFavouriteByCognitoUsernameSubscriptionVariables
  >(
    gql`
      ${onMutateFavouriteByCognitoUsername}
    `,
    {
      variables: { cognitoUsername: user?.username || '' },
      skip: !currentTeacher,
      onData: updateListQueryCache<ListFavouriteByCognitoUsernameQuery, ListFavouriteByCognitoUsernameQueryVariables, OnMutateFavouriteByCognitoUsernameSubscription>({ listQuery: listFavouriteByCognitoUsername, listQueryName: 'listFavouriteByCognitoUsername', subscriptionName: 'onMutateFavouriteByCognitoUsername', variables: { cognitoUsername: currentTeacher?.cognitoUsername || "" } })
    }
  );

  const { error: errorFindYourCalmActivityRatingSubscription } = useSubscription<
    OnMutateFindYourCalmActivityRatingSubscription,
    OnMutateFindYourCalmActivityRatingSubscriptionVariables
  >(
    gql`
      ${onMutateFindYourCalmActivityRating}
    `,
    {
      variables: { userId: currentTeacher?.id || '' },
      skip: !currentTeacher,
      onData: updateListQueryCache<ListFindYourCalmByUserIdAndActivityIdQuery, ListFindYourCalmByUserIdAndActivityIdQueryVariables, OnMutateFindYourCalmActivityRatingSubscription>({ listQuery: listFindYourCalmByUserIdAndActivityId, listQueryName: 'listFindYourCalmByUserIdAndActivityId', subscriptionName: 'onMutateFindYourCalmActivityRating', variables: { userId: currentTeacher?.id || "" } })
    }
  );

  const { error: errorTeacherCheckInsSubscription } = useSubscription<OnMutateTeacherCheckInByTeacherIDSubscription, OnMutateTeacherCheckInByTeacherIDSubscriptionVariables>(gql`${onMutateTeacherCheckInByTeacherID}`, {
    variables: { teacherID: currentTeacher?.id || "" },
    skip: !currentTeacher,
    onData: updateListQueryCache<ListTeacherCheckInsByTeacherIDQuery, ListTeacherCheckInsByTeacherIDQueryVariables, OnMutateTeacherCheckInByTeacherIDSubscription>({ listQuery: listTeacherCheckInsByTeacherID, listQueryName: "listTeacherCheckInsByTeacherID", subscriptionName: "onMutateTeacherCheckInByTeacherID", variables: { teacherID: currentTeacher?.id, limit: 5000 } })
  })

  if (errorFavouriteSubscription !== undefined ||
    errorRatingSubscription !== undefined ||
    errorTeacherClassSubscription !== undefined ||
    errorTeacherSubscription !== undefined ||
    errorSchoolSubscription !== undefined ||
    errorFindYourCalmActivityRatingSubscription !== undefined ||
    errorTeacherCheckInsSubscription !== undefined
  ) {
    handleRestartApp()
  }

  return (
    <RootView>
      {teacherIntroduction ? <IndroductionTeacher teacher={currentTeacher} /> : <></>}
      {studentStatsHowToModal && <StudentIndividualHowToModal />}
      {emotionIntensityScaleModal && <TeacherEmotionIntensityScaleModal />}
      {requestForChatInfoModal && <TeacherRequestForChatInfoModal />}
      {requestForEmailNotificationsInfoModal && <TeacherRequestForEmailNotificationsInfoModal />}
      {individualStudentStatsInfoModal && <TeacherIndividualStudentStatsInfoModal />}
      {dashboardInfoModal && <TeacherDashboardInfoModal />}
      {wellbeingInfoModal && <TeacherWellbeingInfoModal />}
      {eatingQuestionInfoModal && <TeacherEatingQuestionInfoModal />}

      <SideMenu.Navigator
        screenOptions={{
          drawerStyle: CommonStyles.drawer,
          headerShown: true,
          header: (props) => <TeacherHeader {...props} />,
          unmountOnBlur: true,
          swipeEdgeWidth: 0
        }}
        backBehavior="history"
        initialRouteName={denyAccess ? ScreenNames.TeacherClassMain : ScreenNames.TeacherHome}
      >
        <SideMenu.Screen
          name={ScreenNames.TeacherHome}
          component={TeacherHome}
        />
        <SideMenu.Screen
          name={ScreenNames.UnlockTeacherDevice}
          component={UnlockTeacherDevice}
        />
        <SideMenu.Screen
          name={ScreenNames.TeachersProfileMain}
          component={TeacherProfileMain}
        />
        {/* Switches Navigation */}
        <SideMenu.Screen
          name={ScreenNames.SwitchesHome}
          component={SwitchesHome}
        />
        <SideMenu.Screen
          name={ScreenNames.SwitchesExploreAllMain}
          component={SwitchesExploreAllMain}
        />
        <SideMenu.Screen
          name={ScreenNames.SwitchesToolsMain}
          component={SwitchesToolsMain}
        />
        <SideMenu.Screen
          name={ScreenNames.SwitchesHomeMain}
          component={SwitchesHomeMain}
        />
        <SideMenu.Screen
          name={ScreenNames.SwitchesActivityMain}
          component={SwitchesAcitiviesMain}
        />
        <SideMenu.Screen
          name={ScreenNames.SwitchesExploreAll}
          component={SwitchesExploreAll}
        />

        <SideMenu.Screen name={ScreenNames.Card} component={Card} />
        <SideMenu.Screen
          name={ScreenNames.SwitchesMemes}
          component={SwitchesMemes}
        />
        <SideMenu.Screen
          name={ScreenNames.SwitchesEmotionsDetailView}
          component={SwitchesEmotionsDetailView}
        />
        <SideMenu.Screen
          name={ScreenNames.SwitchesActiviesDisplayAll}
          component={SwitchesActiviesDisplayAll}
        />
        <SideMenu.Screen
          name={ScreenNames.SwitchesActiviesDetailView}
          component={SwitchesActiviesDetailView}
        />
        <SideMenu.Screen
          name={ScreenNames.SwitchesToolsDisplayAll}
          component={SwitchesToolsDisplayAll}
        />
        <SideMenu.Screen
          name={ScreenNames.TeacherClassMain}
          component={TeacherClassMain}
        />
        <SideMenu.Screen
          name={ScreenNames.TeacherCheckInMain}
          component={TeacherCheckInMain}
        />
        <SideMenu.Screen
          name={ScreenNames.TeacherWellbeing}
          component={TeacherWellbeing}
        />
      </SideMenu.Navigator>
      {currentTeacher && !currentTeacher.onboarded && (
        <TeacherModalOnboarding />
      )}

      {currentTeacher && currentTeacher.onboarded && currentTeacher.showNewFeatureInstructions && (
        <TeacherDashboardNewFeatureInfoModal />
      )}

      <View style={styles.bottomNavigation}>
        <SwitchesBottomNavigation currentScreen={currentScreen} />
      </View>
    </RootView>
  );
};

const styles = StyleSheet.create({
  bottomNavigation: {
    bottom: 0,
    position: "relative",
  },
});
