import { StyleSheet, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import VideoCard from '../../common/video-card'
import { DecidaColors } from '../../../common/decida-colors'
import { Worksheets } from '../../common/worksheets-imports'


export const SwitchesLessonPlansM12SelfAwarenessAndWellbeing: CardTemplate = {
    cardId: "38eaaebe-59f7-47dc-b8bd-4d0e80b55433",
    title: 'Self awareness and wellbeing',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Enhance self awareness and promote their overall wellbeing through advanced self reflection and self care practices.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>• Duration 45 minutes </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by discussing the importance of self awareness and its impact on overall wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share their understanding of self awareness and its relevance in their lives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that this lesson will focus on advanced self awareness techniques and strategies for cultivating wellbeing.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Self reflection Exercise (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the Wellbeing workbook.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to complete the self reflection activity in the workbook reflecting on their values, strengths, goals, and areas for personal growth.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give students around 10 minutes to engage in the self reflection exercise.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After the reflection, invite students to share any insights or observations they had during the exercise (optional). </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Exploring Personal Wellbeing (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the different dimensions of wellbeing, such as physical, emotional, social, intellectual, and spiritual wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on their own wellbeing in each dimension and identify areas where they would like to focus on for improvement.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to note these in their workbook.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share their reflections with a partner or in small groups, discussing strategies they can implement to enhance their wellbeing.</DefaultText>

                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Advanced Self care Practices (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce advanced self care practices that promote holistic wellbeing, such as mindful eating, gratitude journaling, self compassion exercises, and engaging in hobbies or passions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the benefits of each self care practice and how it contributes to overall wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to choose one or two self care practices they resonate with and commit to incorporating them into their daily routines.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to note these in their workbook.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Goal Setting for Wellbeing (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Guide students through the process of setting SMART (Specific, Measurable, Achievable, Relevant, Time bound) goals related to their wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct them to identify specific actions they can take to improve their wellbeing in the areas they identified earlier.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to write down their goals and action steps in their workbook, emphasising the importance of accountability and tracking progress.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion and Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points covered in the lesson, emphasising the importance of self awareness and proactive self care for overall wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect individually on one self awareness insight they gained during the lesson and one self care practice they plan to prioritise moving forward.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Close the lesson by encouraging students to integrate self awareness and self care into their daily lives to nurture their wellbeing.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Mastering the mind workbook, including section M12: Self awareness and Wellbeing1: Self awareness and Wellbeing'} link={Worksheets.masteryMasteringTheMindLevel7.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'SMART Goals worksheet (optional)'} link={Worksheets.smartGoals.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>7 Types of Self care and Why You Should Practice Them -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://onlinesocialwork.vcu.edu/blog/types-of-self-care/' link='https://onlinesocialwork.vcu.edu/blog/types-of-self-care/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Supporting students’ wellbeing and mental health -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://education.qld.gov.au/students/student-health-safety-wellbeing/student-wellbeing#:~:text=The%20importance%20of%20supporting%20students,resilient%2C%20confident%20and%20lifelong%20learners' link='https://education.qld.gov.au/students/student-health-safety-wellbeing/student-wellbeing#:~:text=The%20importance%20of%20supporting%20students,resilient%2C%20confident%20and%20lifelong%20learners' />
                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}
