import gql from "graphql-tag";

export const getClassQuery = gql`
  query GetClass($id: ID!) {
    getClass(id: $id) {
      id
      name
      schoolID
      students {
        items {
          id
          studentID
          classID
          status
          createdAt
          updatedAt
          requestForChatStatus
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      classLogin {
        id
        nickname
        cognitoUsername
        classID
        classCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      checkInSessions {
        items {
          id
          createdAt
          updatedAt
          classID
          openAt
          closeAt
          isActive
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      supports {
        items {
          id
          classID
          supportID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      emotionIntensityLevel
      studentCheckIns {
        items {
          id
          createdAt
          updatedAt
          studentID
          emotion
          emotionIntensity
          tiredness
          checkinsessionID
          classID
          absence
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      teachers {
        items {
          id
          teacherID
          classID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      schedules {
        items {
          id
          classID
          schedule
          isActive
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      goMode
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
    }
  }
`