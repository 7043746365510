import { useApolloClient, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { createClass } from "../../common/graphql/mutations"
import { GetSchoolQueryResponseClass } from "../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query"
import { createNewTeacherAndAssignToClass } from "./school-admin-add-teacher-to-class"
import SchoolAdminClassAndTeacherForm, { SchoolAdminClassAndTeacherFormOnSubmit } from "./school-admin-class-and-teacher-form"
import { SchoolAdminTableSetEditComponent } from "./school-admin-const"

type Props = {
    setEditComponent: SchoolAdminTableSetEditComponent,
    schoolID: string,
    goMode?: boolean
}

export const SchoolAdminAddNewClassAndTeacher = ({
    setEditComponent,
    schoolID,
    goMode
}: Props) => {
    const [createClassMutation] = useMutation(gql`${createClass}`);
    const apolloClient = useApolloClient()

    const handleSubmit: SchoolAdminClassAndTeacherFormOnSubmit = async ({
        className,
        firstName,
        lastName,
        email,
        role,
        selectedTeacher,
    }) => {

        if (!className) {
            throw new Error("Please input class name")
        }

        const { data: classMutationData } = await createClassMutation({
            variables: {
                input: {
                    schoolID,
                    name: className,
                    goMode,
                    emotionIntensityLevel: 3
                }
            }
        })

        const newClass: GetSchoolQueryResponseClass = classMutationData.createClass

        createNewTeacherAndAssignToClass({
            schoolID,
            classID: newClass.id,
            firstName,
            lastName,
            email,
            role,
            selectedTeacher,
            apolloClient,
        })
    }

    return (
        <SchoolAdminClassAndTeacherForm setEditComponent={setEditComponent} schoolID={schoolID} onSubmit={handleSubmit} goMode={goMode} />
    )
}