import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { List } from '../common/list';
import { ScreenNames } from '../common/screen-names';
import { AdminStackNavigatorParamList } from './admin-route-param-types';
import { ActiveClassesStudentCount } from '../common/active-clases-students-count';
import { Class, School, SchoolAdminUI } from '../../common/API';
import { RollingWeekCheckInCount } from '../common/rolling-week-check-in-count';
import { isDefined } from '../../common/is-defined';

interface Props {
    school: School
}

export const AdminSchoolClassesList = ({ school }: Props) => {
    const { navigate } = useNavigation<StackNavigationProp<AdminStackNavigatorParamList>>()

    const addClass = () => {
        navigate(ScreenNames.AdminClass, { id: undefined, schoolID: school.id })
    }

    const editClass = (id: string) => {
        navigate(ScreenNames.AdminClass, { id, schoolID: school.id })
    }

    const filteredClass = school.classes?.items
        .filter(isDefined)
        .filter(c => c._deleted !== true)
        .filter(c => {
            if (school.schoolAdminUI === SchoolAdminUI.HIERARCHY) {
                return !c.classGroupID;
            } else {
                return !c.goMode;
            }
        }) || [];


    return (
        <List
            type='accordion'
            title={school.schoolAdminUI === SchoolAdminUI.HIERARCHY ? 'Classes without group' : 'Classes'}
            items={filteredClass}
            add={addClass}
            edit={editClass}
            extraComponent={(clazz) =>
                <>
                    <ActiveClassesStudentCount clazz={clazz} />
                    <RollingWeekCheckInCount rollingWeekCheckInCount={clazz} />
                </>
            }
        />
    )
}