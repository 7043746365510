import { gql, useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { ListTeachersQuery, Teacher, UpdateTeacherMutation } from '../../common/API'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { updateTeacher } from '../../common/graphql/mutations'
import { listTeachers } from '../../common/graphql/queries'

const AdminOnboardingTeacherScript = () => {
    const [nextToken, setNextToken] = useState('')
    const [teachers, setTeachers] = useState<(Teacher | null)[]>([])

    const { data } = useQuery<ListTeachersQuery>(gql`${listTeachers}`, { variables: { limit: 5000, nextToken: nextToken ? nextToken : undefined } })
    const [updateStudentMutation] = useMutation<UpdateTeacherMutation>(gql`${updateTeacher}`)

    useEffect(() => {
        const studentResponse = data?.listTeachers?.items as Teacher[]

        if (studentResponse) {
            setTeachers(studentResponse.filter((teacher) => !teacher.onboarded))
        }

    }, [data])

    const handleNext = () => {
        const currentNextToken = data?.listTeachers?.nextToken
        if (currentNextToken) {
            setNextToken(currentNextToken)
        }
    }


    const handleProcess = async () => {
        try {
            await Promise.all(teachers.map(async (teacher) => {

                if (!teacher?.onboarded) {
                    await updateStudentMutation({
                        variables: {
                            input: {
                                id: teacher?.id,
                                onboarded: true,
                                _version: teacher?._version
                            }
                        }

                    })
                }
            }))
        } catch (error) {
            console.log("Onboarding process failed")
            console.log({ error })
        }

    }

    return (
        <View style={styles.container}>
            <DefaultText>Onboarding {teachers.length} teachers</DefaultText>

            {data?.listTeachers?.nextToken && (
                <DefaultButton disabled={teachers.length === 0} onPress={handleNext}>Next page</DefaultButton>
            )}
            <DefaultButton disabled={teachers.length === 0} onPress={handleProcess}>{teachers.length ? 'Start onboarding teacher process' : 'All teachers onboarded'}</DefaultButton>
        </View>
    )
}
export default AdminOnboardingTeacherScript
const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        alignItems: 'center'
    }
})