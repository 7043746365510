import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { API } from 'aws-amplify'
import { useState } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { GetSchoolAdminByCognitoUsernameQuery, UpdateSchoolAdminMutation } from '../../common/API'
import { CommonStyles } from '../common/const'
import { getApiHeaders } from '../common/get-api-header'
import { rvIsLoading } from '../common/loading'
import { CLIENT_EVENT, createClientLog } from '../common/log-client-event'
import { Page } from '../common/page'
import ProfileForm, { ProfileFormData } from '../common/profile-form'
import { showAlert } from '../common/universal-alert'
import { updateSchoolAdmin } from '../../common/graphql/mutations'
import { getSchoolAdminByCognitoUsername } from '../../common/graphql/queries'
import { rvCurrentUser } from '../login/login-state'
import { DecidaColors } from '../../common/decida-colors'
import { UserGroup } from '../../common/constants'

export const SchoolAdminProfile = () => {

    const [allowEdit, setAllowEdit] = useState(false)

    const user = useReactiveVar(rvCurrentUser)
    const { data: resultSchoolAdmin } = useQuery<GetSchoolAdminByCognitoUsernameQuery>(gql`${getSchoolAdminByCognitoUsername}`, { variables: { cognitoUsername: user?.username } })
    const [updateSchoolAdminMutation] = useMutation<UpdateSchoolAdminMutation>(gql`${updateSchoolAdmin}`)
    const currentSchoolAdmin = resultSchoolAdmin?.getSchoolAdminByCognitoUsername?.items[0]


    const saveChangesSchoolAdmin = async ({ firstName, lastName, email, preferredUsername }: ProfileFormData) => {

        if (currentSchoolAdmin) {
            // school admin don't login wit their email so this logic need to be updated
            // comment out for now to get to a releasable state faster
            // const username = email
            // const cognitoUsername = currentSchoolAdmin.cognitoUsername

            // If email hasn't changed don't run 
            // if (username != currentSchoolAdmin.email) {
            //     try {
            //         rvIsLoading(true)
            //         const post = await API.post(
            //             'AdminQueries',
            //             '/updatePreferredUsername',
            //             {
            //                 body: {
            //                     username,
            //                     cognitoUsername
            //                 },
            //                 headers: await getApiHeaders(),
            //             }
            //         )
            //         if (post.completed) {

            //             const { id, _version } = currentSchoolAdmin
            //             const payload = {
            //                 id,
            //                 _version,
            //                 firstName,
            //                 lastName,
            //                 email
            //             }
            //             await updateSchoolAdminMutation({
            //                 variables: {
            //                     input: payload
            //                 }
            //             })
            //             createClientLog({
            //                 event: CLIENT_EVENT.EDIT_PROFILE_SUCCESS,
            //                 area: "Edit profile screen",
            //                 action: "Clicking save, profile updated successfully",
            //                 payload: {
            //                     group: "teacher",
            //                     nickname: currentSchoolAdmin?.cognitoUsername,
            //                 }
            //             })
            //             setAllowEdit(!allowEdit)
            //             rvIsLoading(false)
            //         }
            //         else {
            //             createClientLog({
            //                 event: CLIENT_EVENT.EDIT_PROFILE_FAILED,
            //                 area: "Edit profile screen",
            //                 action: "/updatePreferredUsername error, failed to update profile",
            //                 payload: {
            //                     group: "teacher",
            //                     nickname: currentSchoolAdmin?.cognitoUsername,
            //                     error: post.message
            //                 }
            //             })
            //             showAlert({
            //                 title: 'An error has occured.',
            //                 message: post.message,
            //                 leftButtonText: 'Ok',
            //                 rightButtonText: 'Retry',
            //                 onRightButtonPress: () => saveChangesSchoolAdmin({ firstName, lastName, email })
            //             })
            //         }
            //     }
            //     catch (e: any) {
            //         showAlert({
            //             title: 'An error has occured.',
            //             message: String(e),
            //             leftButtonText: 'Ok',
            //             rightButtonText: 'Retry',
            //             onRightButtonPress: () => saveChangesSchoolAdmin({ firstName, lastName, email })
            //         })
            //         createClientLog({
            //             event: CLIENT_EVENT.EDIT_PROFILE_FAILED,
            //             area: "Edit profile screen",
            //             action: "An error has occured., failed to update profile",
            //             payload: {
            //                 group: "teacher",
            //                 nickname: currentSchoolAdmin?.cognitoUsername,
            //                 error: String(e)
            //             }
            //         })
            //     } finally {
            //         rvIsLoading(false)
            //     }

            // }
            
            try {

                const username = currentSchoolAdmin.preferredUsername
                const cognitoUsername = currentSchoolAdmin.cognitoUsername

                let post

                if (preferredUsername !== username) {
                    // if new username input is different that 
                    // preferredUsername record in databases
                    // update the preferredUsername
                    post = await API.post(
                        'AdminQueries',
                        '/updatePreferredUsername',
                        {
                            body: {
                                username: preferredUsername,
                                cognitoUsername
                            },
                            headers: await getApiHeaders(),
                        }
                    )
                }

                const { id, _version } = currentSchoolAdmin
                const payload = {
                    id,
                    _version,
                    firstName,
                    lastName,
                    email,
                    preferredUsername: post?.completed ? preferredUsername : undefined // update only when updating preferred username is success
                }
                await updateSchoolAdminMutation({
                    variables: {
                        input: payload
                    }
                })
                createClientLog({
                    event: CLIENT_EVENT.EDIT_PROFILE_SUCCESS,
                    area: "Edit profile screen",
                    action: "Clicking save in, profile updated successfully",
                    payload: {
                        group: "teacher",
                        nickname: currentSchoolAdmin?.cognitoUsername,
                    }
                })
                setAllowEdit(!allowEdit)

            }
            catch (e: any) {

                showAlert({
                    title: 'An error has occured.',
                    message: String(e),
                    leftButtonText: 'Ok',
                    rightButtonText: 'Retry',
                    onRightButtonPress: () => saveChangesSchoolAdmin({ firstName, lastName, email })
                })
                rvIsLoading(false)
            }
        }
    }

    return (
        <Page style={styles.page}>
            <ScrollView style={CommonStyles.inputScrollView}>
                <ProfileForm data={currentSchoolAdmin} allowEdit={allowEdit} setAllowEdit={setAllowEdit} onSave={saveChangesSchoolAdmin} profileType={UserGroup.SchoolAdmin} />
            </ScrollView>
        </Page>
    )
}

const styles = StyleSheet.create({
    page: {

    },
    teacherDetails: {
        borderWidth: 1,
        borderColor: DecidaColors.Gray,
        padding: 10,
        borderRadius: 8,
        width: 300,


    },
    avatarContainer: {
        marginVertical: 50,
        height: 100,
        width: '100%',

    },
    avatarSize: {
        width: 100,
        height: 100,
    },
    AvatarCenter: {
        alignContent: 'center',
        justifyContent: 'center'
    },
    backButtonContainer: {
        flexDirection: 'row',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%'
    },
    heading: {
        fontSize: 17,
        fontWeight: 'bold',
        paddingTop: 20,

    },
    row: {
        flexDirection: 'row',

    },
    subtext: {
        fontSize: 15,
    },
    inputContainer: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    nickname: {
        fontSize: 30,
        fontWeight: 'bold',
        color: DecidaColors.Green,
        textAlign: 'center',

    },
    subtitle: {
        color: DecidaColors.Green,
        marginBottom: 15,
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    editButton: {
        backgroundColor: DecidaColors.White,
        height: 50,
        width: 150,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    editButtonText: {
        color: DecidaColors.Green,
        paddingRight: 10,
        fontSize: 15,

    },
    joinButton: {
        backgroundColor: DecidaColors.Green,
        height: 50,
        width: 150,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    buttonText: {
        color: DecidaColors.White

    },
    settings: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignContent: 'flex-end',
        paddingRight: 20,
    },
    emailNote: {
        width: 330,
        color: DecidaColors.Black,
        fontSize: 15,
        textAlign: 'center',
    },
    leftAlign: {

    },
    updatePassword: {
        marginTop: 50,
    },
    pencil: {
        position: 'absolute',
        right: 110,
        top: -15,

    }


})