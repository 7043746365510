import { gql, useMutation, useQuery, useSubscription } from '@apollo/client'
import { Entypo } from '@expo/vector-icons'
import { StackScreenProps } from '@react-navigation/stack'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { ActivityIndicator, ScrollView, StyleSheet, TextInput, View } from 'react-native'
import { Class } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'
import ListActiveStudents, { ActiveStudentsType } from '../common/list-active-students'
import ListPendingStudents, { PendingStudentsType } from '../common/list-pending-students'
import { ScreenNames } from '../common/screen-names'
import { Stepper, StepperType } from '../common/stepper'
import StudentSearchCardInfo from '../common/student-search-card-info'
import { showAlert } from '../common/universal-alert'
import { getClassQuery } from '../custom-graphql/queries/get-class-query'
import { ListAllStudentsBySchoolIDQueryResponse, listAllStudentsBySchoolIDQuery } from '../custom-graphql/queries/list-all-student-by-school-id-query'
import { createStudentClass } from '../../common/graphql/mutations'
import { listStudentsBySchoolID } from '../../common/graphql/queries'
import { onMutateStudentBySchoolId, onMutateStudentClassByClassId } from '../../common/graphql/subscriptions'
import { Student, StudentClassApprovalStatus } from '../../common/API'
import { SchoolAdminClassNavigatorParamsList, SchoolAdminNavigationParams } from './school-admin-navigation-params'

enum AddStudentStepper {
    SearchExisting,
    AddNew
}

const SchoolAdminManageClass = ({ route, navigation }: StackScreenProps<SchoolAdminClassNavigatorParamsList, 'SchoolAdminManageClass'>) => {
    const classID = route.params?.classID || ""

    const [type, setType] = useState(AddStudentStepper.SearchExisting)
    const [currentStudents, setCurrentStudents] = useState<Student[]>([])
    const [studentSearch, setStudentSearch] = useState('')

    const { data, refetch } = useQuery(getClassQuery, { variables: { id: classID } })
    const classData = data?.getClass as Class

    const { loading: loadingOnSearchStudents, data: studentsResponse } = useQuery(gql`${listStudentsBySchoolID}`, {
        variables: {
            schoolID: classData?.schoolID,
            filter: {
                or: [
                    {
                        nickname: { contains: studentSearch.toLowerCase() },
                    },
                    {
                        firstName: { contains: studentSearch.toLowerCase() },
                    },
                    {
                        lastName: { contains: studentSearch.toLowerCase() },
                    },
                    {
                        email: { contains: studentSearch.toLowerCase() },
                    },
                ],
            },
            limit: 5000
        }
    })

    const { refetch: refetchStudentsData } = useQuery<ListAllStudentsBySchoolIDQueryResponse>(gql`${listAllStudentsBySchoolIDQuery}`, { variables: { schoolID: classData?.schoolID }, skip: !classData })
    const [createStudentClassMutation] = useMutation(gql`${createStudentClass}`);

    const { data: onMutateStudentClassByClassIdData } = useSubscription(gql`${onMutateStudentClassByClassId}`, { variables: { classID: classData?.id }, skip: !classData })
    const { data: onMutateStudentBySchoolIdData } = useSubscription(gql`${onMutateStudentBySchoolId}`, { variables: { schoolID: classData?.schoolID }, skip: !classData })

    useEffect(() => {
        if (onMutateStudentClassByClassIdData || onMutateStudentBySchoolIdData) {
            refetchAllData()
        }
    }, [onMutateStudentClassByClassIdData, onMutateStudentBySchoolIdData])

    useEffect(() => {
        if (type === AddStudentStepper.AddNew) {
            navigation.navigate(ScreenNames.SchoolAdminCreateStudentPickAvatar, { classID: classData?.id })
        }

        return () => setType(AddStudentStepper.SearchExisting)
    }, [type])


    useEffect(() => {
        searchStudents()
        return () => {
            debouncedSetKeywords.cancel();
        };
    }, [studentsResponse])

    const refetchAllData = async () => {
        try {
            await refetch()
            await refetchStudentsData()
        } catch (error) {
            console.log("error refetchAllData", error)
        }
    }

    const debouncedSetKeywords = useMemo(() => {
        return _.debounce(handleSetKeywords, 500);
    }, []);

    function handleSetKeywords(text: string) {
        setStudentSearch(text)
    }

    const searchStudents = () => {
        if (studentSearch) {
            const currentStudents = studentsResponse?.listStudentsBySchoolID?.items
            setCurrentStudents(currentStudents)
        } else {
            setCurrentStudents([])
        }
    }

    const handleCreateStudentClass = (student: Student) => {
        const studentExists = classData?.students?.items?.some((studentClass) => studentClass?.studentID === student?.id && !studentClass?._deleted)

        if (studentExists) {
            showAlert({
                title: `Student already assigned to your ${classData.goMode === true ? 'GO group' : 'class'}.`,
                message: `${student.nickname} is already assigned to ${classData.name}'s ${classData.goMode === true ? 'GO group' : 'class'}`,
                leftButtonText: 'Ok',
            })
            return
        }

        createStudentClassMutation({
            variables: {
                input: {
                    classID: classData?.id,
                    studentID: student?.id,
                    status: StudentClassApprovalStatus.APPROVED
                }
            }
        })
    }

    return (
        <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
            <DefaultText style={styles.classNameText}>{classData?.name}</DefaultText>
            <View style={styles.topContainer}>
                <ListPendingStudents type={PendingStudentsType.SchoolAdmin} classID={classID || ""} />
                <ListActiveStudents type={ActiveStudentsType.SchoolAdmin} classID={classID || ""} />
            </View>
            <View style={styles.bottomContainer}>
                <DefaultText style={styles.addStudentText}>
                    + ADD another student
                </DefaultText>
                <View style={styles.addStudentContainer}>
                    <Stepper
                        values={[
                            { value: AddStudentStepper.SearchExisting, label: 'Search Existing' },
                            { value: AddStudentStepper.AddNew, label: 'Add New' }
                        ]}
                        currentValue={type}
                        onChange={setType}
                        width={'100%'}
                        SelectedFontSize={16}
                        fontSize={16}
                        type={StepperType.Simple}
                    />
                </View>
                <View>
                    <ScrollView style={styles.studentScrollContainer} contentContainerStyle={styles.studentScrollContentContainer}>
                        <View style={styles.searchContainer}>
                            <TextInput
                                style={styles.searchInput}
                                placeholder="Search students.."
                                onChangeText={debouncedSetKeywords}
                            />
                        </View>
                        <ScrollView style={styles.studentScroll} contentContainerStyle={styles.interalStudentScroll}>
                            {studentSearch.length && loadingOnSearchStudents ? (
                                <View style={{ justifyContent: 'center', width: '100%' }}>
                                    <ActivityIndicator color={DecidaColors.Green} size="large" />
                                </View>
                            )
                                : studentSearch.length <= 0 ?
                                    <View style={styles.noResults}>
                                        <Entypo name="air" size={50} color={DecidaColors.AppleSystemGray2Dark} />
                                        <DefaultText>Start typing to see results...</DefaultText>
                                        <DefaultText>(only top 10 results shown)</DefaultText>
                                    </View>
                                    :

                                    currentStudents?.map((student) => {
                                        return <StudentSearchCardInfo key={student.id} student={student} onAddStudent={() => handleCreateStudentClass(student)} fontSize='small' />
                                    })
                            }
                        </ScrollView>

                    </ScrollView>
                </View>
            </View>
        </ScrollView>
    )
}

export default SchoolAdminManageClass

const styles = StyleSheet.create({
    scrollView: {
        flex: 1,
    },
    container: {
        padding: 50
    },
    classNameText: {
        fontSize: 24,
        color: DecidaColors.Green,
        fontWeight: '900'
    },
    editButton: {
        position: 'absolute',
        top: -10,
        left: -10,
        zIndex: 2,
    },
    topContainer: {
        marginTop: 20,
    },
    avatarContainer: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        width: '55%',
        paddingLeft: 20,
        paddingVertical: 20,
        justifyContent: 'flex-start',
        borderWidth: 1,
        borderRadius: 20,
        borderColor: DecidaColors.AppleSystemGrayLight,
        marginLeft: 20,
        marginTop: 10,
        paddinngRight: 'auto',
    },
    avatar: {
        width: 50,
        height: 50
    },
    avatarButton: {
        alignItems: 'center',
        margin: 10,
        width: "10%",
        minWidth: 60,
        alignSelf: 'flex-start'
    },
    nicknameText: {
        fontSize: 14,
        color: DecidaColors.Gray,
        fontWeight: '600',
        textAlign: 'center'
    },
    fullNameText: {
        fontSize: 12,
        fontStyle: 'italic',
        color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center'
    },
    studentText: {
        fontSize: 18,
        color: DecidaColors.AppleSystemGray2Dark,
        fontWeight: '600'
    },
    studentHeaderContainer: {
        backgroundColor: DecidaColors.Sad,
        width: 16,
        height: 16,
        borderRadius: 8,
        marginRight: 5
    },
    bottomContainer: {
        flex: 1,
        maxWidth: '45%',
        paddingLeft: 20,
        paddingTop: 20
    },
    addStudentText: {
        fontSize: 18,
        fontStyle: "italic",
        fontWeight: '600',
        color: DecidaColors.Green
    },
    addStudentContainer: {
        marginTop: 10
    },
    studentScrollContainer: {
        flex: 1,
        padding: 15,
        borderRadius: 8,
        minHeight: 300,
        // minWidth: 300,
        paddingHorizontal: 5,
        margin: 10,
    },
    searchInput: {
        padding: 20,
        backgroundColor: DecidaColors.AppleSystemGray5Light,
        borderRadius: 8,
    },
    searchContainer: {
        width: '100%',
        marginHorizontal: 15,
        paddingVertical: 5,
        justifyContent: 'center',
        alignContent: 'center',
    },
    studentScroll: {
        flex: 1,
        width: '100%',
        padding: 15,
        borderRadius: 8,
    },
    interalStudentScroll: {},
    noResults: {
        alignItems: 'center'
    },
    studentRowContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginVertical: 5,
        backgroundColor: DecidaColors.AppleSystemGray5Light,
        padding: 15,
        borderRadius: 8,
    },
    nameColumn: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    studentScrollContentContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    firstLastNameContainer: {
        flexDirection: 'row',
    },
    firstLastNameText: {
        fontSize: 14,
        fontStyle: 'italic'
    }
})
