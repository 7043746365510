import { useReactiveVar } from '@apollo/client'
import { API } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { MIN_PASSWORD_LENGTH_NON_STUDENT_ACCOUNT } from '../../common/constants'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'
import { rvChangePasswordUsername, rvUserGroup } from './login-state'
import { PasswordInput } from './password-input'
import { UserGroup } from '../../common/constants'

interface Props {
    onPasswordChange?: (text: string) => void
}

export const NewPassword = ({ onPasswordChange }: Props) => {
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isMinimumLengthOfPasswordValid, setIsMinimumLengthOfPasswordValid] = useState(false)
    const [minPaswordLength, setMinPasswordLength] = useState<number | null>(null)
    // const [mustContainNumbers, setMustContainNumbers] = useState(false)
    // const [mustContainSpecialCharacters, setMustContainSpecialCharacters] = useState(false)
    // const [mustContainUppercaseLetters, setMustContainUppercaseLetters] = useState(false)
    // const [mustContainLowercaseLetters, setMustContainLowercaseLetters] = useState(false)
    const [passwordsMustMatch, setPasswordsMustMatch] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const userGroup = useReactiveVar(rvUserGroup)

    useEffect(() => {
        if (userGroup !== undefined) {
            getPasswordMinLengthByUserGroup()
        } else {
            checkUserPassword()
        }
        // setMustContainNumbers(password.match(/[0-9]/) !== null)
        // setMustContainSpecialCharacters(password.match(/[^a-zA-Z0-9]/) !== null)
        // setMustContainUppercaseLetters(password.match(/[A-Z]/) !== null)
        // setMustContainLowercaseLetters(password.match(/[a-z]/) !== null)
    }, [userGroup])

    useEffect(() => {
        setIsMinimumLengthOfPasswordValid(password.length >= Number(minPaswordLength))
    }, [password, minPaswordLength])

    useEffect(() => {
        setPasswordsMustMatch(password === confirmPassword && password !== '')
    }, [password, confirmPassword])

    useEffect(() => {
        if (isMinimumLengthOfPasswordValid &&
            // mustContainNumbers &&
            // mustContainSpecialCharacters &&
            // mustContainUppercaseLetters &&
            // mustContainLowercaseLetters &&
            passwordsMustMatch) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    })

    useEffect(() => {
        onPasswordChange && onPasswordChange(isValid ? password : '')
    }, [isValid])

    const getPasswordMinLengthByUserGroup = () => {
        let validLength = 6
        if (userGroup !== UserGroup.Student) {
            validLength = MIN_PASSWORD_LENGTH_NON_STUDENT_ACCOUNT
        }

        setMinPasswordLength(validLength)
    }

    const checkUserPassword = async () => {
        const response: {
            username: string,
            minPasswordLength: number,
            userGroups: (UserGroup.Student | UserGroup.Teacher | UserGroup.SchoolAdmin | UserGroup.Admin)[]
        } = await API.post(
            'AdminQueries',
            '/userForgotPasswordCheck',
            {
                body: {
                    username: rvChangePasswordUsername(),
                },
            }
        )

        setMinPasswordLength(response.minPasswordLength)
    }

    return (
        <>
            <PasswordInput
                onPasswordChange={setPassword}
                placeholder="New password"
                newPassword
            />
            <PasswordInput
                onPasswordChange={setConfirmPassword}
                placeholder='Re-enter password'
                newPassword
            />
            <DefaultText style={{ color: isMinimumLengthOfPasswordValid ? DecidaColors.Green : DecidaColors.Red }}>Minimum length of {minPaswordLength ?? "-"}</DefaultText>
            {/* <DefaultText style={{ color: mustContainNumbers ? DecidaColors.Green : DecidaColors.Red }}>Must contain numbers</DefaultText>
            <DefaultText style={{ color: mustContainSpecialCharacters ? DecidaColors.Green : DecidaColors.Red }}>Must contain special characters (eg. !,?,# )</DefaultText>
            <DefaultText style={{ color: mustContainUppercaseLetters ? DecidaColors.Green : DecidaColors.Red }}>Must contain uppercase letters</DefaultText>
            <DefaultText style={{ color: mustContainLowercaseLetters ? DecidaColors.Green : DecidaColors.Red }}>Must contain lowercase letters</DefaultText> */}
            <DefaultText style={{ color: passwordsMustMatch ? DecidaColors.Green : DecidaColors.Red }}>Passwords must match</DefaultText>
        </>
    )
}
