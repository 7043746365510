import { useReactiveVar } from '@apollo/client'
import { ScrollView, ScrollViewProps, StyleSheet, Text, View } from 'react-native'
import { rvPrintMode } from './export-to-pdf-button'
import { ReactNode } from 'react'

interface Props extends ScrollViewProps {
    children: ReactNode
}

const ScrollViewPrint = (props: Props) => {
    const isPrintMode = useReactiveVar(rvPrintMode)

    return (
        <>
            {isPrintMode ? (
                <View>{props.children}</View>
            ) : (
                <ScrollView {...props}>
                    {props.children}
                </ScrollView>
            )}
        </>
    )
}
export default ScrollViewPrint
const styles = StyleSheet.create({})