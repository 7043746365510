import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CreateNewWordIcon } from '../../icons/create-new-word-icon'
import { CreateNewWordIconInverted } from '../../icons/create-new-word-icon-inverted'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'


export const SwitchesActivitiesCreativityCreateNewWord: CardTemplate = {
    cardId: "ec07decb-3fea-4e08-ae40-c592e39ebfcd",
    title: 'Create new word',
    Header: CreateNewWordIconInverted,
    Icon: CreateNewWordIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"A pen and a notebook for each participant"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"A timer "} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Form a group (4 – 10 people works best)"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The game is split up into three rounds, the word round, the definition round and the sentence round"} />

            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Round one: The word round:"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Set a timer for 1 minute (change to vary difficulty)' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Each person within that time must write down a list of the weirdest and wackiest made-up words they can think of' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='When the timer is done each person shares one of their words to the group and the group votes on their favourite word' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Whoever created the word that the group voted was the best wins this round and becomes the judge for the next round ' />

            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Round two: the definition round"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Set a time for 2 minutes (change to vary difficulty)' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata="Choose a category from the 'List of categories' section for the activity" />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='With the word that the group decided on in round one each person (except of the judge) now must create a definition for that word that is to do with the generated topic within the allocated time... points for being funny ' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='The judge hears all of the definitions and decides which one is best' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Whoever the judge decides wins the round is the winner and the new judge for round 3' />

            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Round three: the sentence round"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Set a timer for 1.5 minutes (change to vary difficulty) ' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Take the definition that was decided on in the previous round and get each participant (except for the judge) to create a sentence with the new word' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='The judge hears all of the definitions and decides which one is best' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Whoever the judge decides wins the round is the winner and the new judge for round one of a new game if the group decides they would like to play again' />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"List of categories:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata='Animals' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Habitat, food chain, pets, herds/packs/flocks, reptiles, mammals' />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata='Food and drink' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Fast food, eating, health, cuisines, cooking, hot, cold, hungry' />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata='Clothing' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Fashion, summer, winter, pyjamas, formal, casual' />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata='Technology' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Phone, social media, hacking, texting' />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata='Transportation' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Cars, public transport, planes, boats, bike, running/walking' />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata='Sports' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Competition, track and field, Olympic games, commentators, spectators' />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata='School' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Subjects, lunchtime, friends, co-curricular, classrooms' />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata='Music' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Instruments, concerts, musicians, dancing, genres' />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata='Art' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Painting, creativity, drawing, colour, art gallery' />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata='Friends & Family' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Parties, immediate / extended family, home life, parents/guardians' />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata='Movies & Television' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata='Genres, actors/actress’, filming, sets, Hollywood, Netflix' />



        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.CREATIVITY,
    whyDoesItWorkText: '',
    type: [CardType.Activity],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.CREATIVITY],
    action: [CardActionType.Action],
    level: []
}

