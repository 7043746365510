import Svg, { Defs, ClipPath, Rect, G, Path, SvgProps } from "react-native-svg"

function IconResources(props: SvgProps) {
  return (
    <Svg
      id="Group_8107"
      data-name="Group 8107"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={30}
      height={26.5}
      viewBox="0 0 33.845 28.5"
      {...props}
    >
      <Defs>
        <ClipPath id="clip-path">
          <Rect
            id="Rectangle_1276"
            data-name="Rectangle 1276"
            width={33.845}
            height={28.5}
            fill="#707071"
          />
        </ClipPath>
      </Defs>
      <Rect
        id="Rectangle_1275"
        data-name="Rectangle 1275"
        width={4.751}
        height={16.625}
        transform="translate(0 11.875)"
        fill="#707071"
      />
      <G id="Group_8106" data-name="Group 8106">
        <G id="Group_8105" data-name="Group 8105" clipPath="url(#clip-path)">
          <Path
            id="Path_6981"
            data-name="Path 6981"
            d="M27.855,5.937h19A1.185,1.185,0,0,0,48.042,4.75V1.187A1.187,1.187,0,0,0,46.855,0h-19a1.187,1.187,0,0,0-1.187,1.187V4.75a1.185,1.185,0,0,0,1.187,1.187"
            transform="translate(-17.168)"
            fill="#707071"
          />
          <Path
            id="Path_6982"
            data-name="Path 6982"
            d="M39.663,25.937h7.2a1.185,1.185,0,0,0,1.187-1.187V21.187A1.187,1.187,0,0,0,46.859,20h-19a1.187,1.187,0,0,0-1.187,1.187V24.75H36.765A4.146,4.146,0,0,1,39.663,25.937Z"
            transform="translate(-17.17 -12.875)"
            fill="#707071"
          />
          <Path
            id="Path_6983"
            data-name="Path 6983"
            d="M40.666,42.161,30.918,45.41,23.793,42.6h6.531a2.968,2.968,0,1,0,0-5.937H16.668V48.539l13.311,2.818a3,3,0,0,0,.939.15,3.044,3.044,0,0,0,.939-.15l10.687-3.562a2.969,2.969,0,0,0-1.879-5.633Z"
            transform="translate(-10.73 -23.602)"
            fill="#707071"
          />
          <Path
            id="Path_6984"
            data-name="Path 6984"
            d="M68.313,40H61.958a4.1,4.1,0,0,1,.418,1.781A4.15,4.15,0,0,1,60,45.522v.39l8.186-2.73a4.14,4.14,0,0,1,1.315-.214v-1.78A1.187,1.187,0,0,0,68.313,40Z"
            transform="translate(-38.625 -25.75)"
            fill="#707071"
          />
        </G>
      </G>
    </Svg>
  )
}

export default IconResources
