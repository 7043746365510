import React from "react";
import { View } from "react-native";
import { DefaultText } from "../common/default-text";
import { cards } from "../switches/emotion-cards/cards";

export const AdminSwitchCardsCsv = () => (
    <View>
        <DefaultText>id,name</DefaultText>
        {
            cards.map(card => (
                <DefaultText>{card.cardId},{card.title}</DefaultText>
            ))
        }
    </View>
)