import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { WordShowdownIcon } from '../../icons/word-showdown-icon'
import { WordShowdownIconInverted } from '../../icons/word-showdown-icon-inverted'

export const SwitchesActivitiesCreativityWordShowdown: CardTemplate = {
    cardId: "caf2a7c8-e21a-49e2-921d-e827d83f369f",
    title: 'Word showdown',
    Header: WordShowdownIconInverted,
    Icon: WordShowdownIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Exercising lateral thinking through word association."} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Divide the group into pairs. If there are an odd number of people, you can have one group of three"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Select one person from each pair to be the ‘informer’ and another person to be the ‘guesser’ (The group of three has two guessers). "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Have the guessers sit in a line facing their partners."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Choose one end of the line to be the top and the other end to be the bottom."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The game starts with the top pair in the line. The ‘informers’ are all discretely given the same single word."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The ‘informer’ must choose one word related to the key word to say to their partner. Their partner can have one guess at the key word based on the clue their 'informer’ gives. For example, the key word may be ‘sleep’, and the informer may give the word ‘tired’ to their partner."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"If the guesser doesn't correctly guess the key word, the next pair in the line gets a turn."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"If no pair guesses the word, the game restarts at the top with the same key word."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The pair who guesses the word correctly gets a point, and from that position in the line, they then become the first pair to start the next round."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The next round starts with a new key word"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"To assist with key word generation, use this website https://randomwordgenerator.com/charades.php"} />

        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.CREATIVITY,
    whyDoesItWorkText: '',
    BottomRow: () => (
        <>
        </>

    ),
    audioUrl: "",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.CREATIVITY],
    action: [CardActionType.Action],
    level: []

}

