import { gql, useMutation, useQuery } from '@apollo/client'
import { API } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, TextInput, View } from 'react-native'
import { CustomCreateClassLoginMutation, CustomCreateClassLoginMutationVariables, GetClassQuery, GetClassQueryVariables, ListClassLoginByClassIDQuery, ListClassLoginByClassIDQueryVariables, UpdateClassLoginCodeMutation, UpdateClassLoginCodeMutationVariables, UpdateClassLoginMutation, UpdateClassLoginMutationVariables } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import { customCreateClassLogin, updateClassLogin, updateClassLoginCode } from '../../common/graphql/mutations'
import { getClass, listClassLoginByClassID } from '../../common/graphql/queries'
import { CommonStyles } from '../common/const'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { getApiHeaders } from '../common/get-api-header'
import { createCurrentClassLogin } from '../common/helper-api-functions'
import { InputCognitoUsername } from '../common/input-cognito-username'
import { rvIsLoading } from '../common/loading'
import { PageTitleText } from '../common/page-title-text'
import ToastAlert from '../common/toast-alert'
import { showAlert } from '../common/universal-alert'
import { ClassLogin } from '../models'

const minClassCodeLength = 6

type Props = {
    classID: string
}


const CurrentClassLogin = ({ classID }: Props) => {

    const [updateClassLoginMutation] = useMutation<UpdateClassLoginCodeMutation, UpdateClassLoginCodeMutationVariables>(gql`${updateClassLoginCode}`)
    const [customCreateClassLoginMutation] = useMutation<CustomCreateClassLoginMutation, CustomCreateClassLoginMutationVariables>(gql`${customCreateClassLogin}`,)

    const { data: classResponse } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: classID }, skip: !classID })
    const currentClass = classResponse?.getClass

    const { data: classLoginResponse } = useQuery<ListClassLoginByClassIDQuery, ListClassLoginByClassIDQueryVariables>(gql`${listClassLoginByClassID}`, { variables: { classID, limit: 5000 }, skip: !classID })
    const classLogins = classLoginResponse?.listClassLoginByClassID?.items.filter((item) => item?._deleted !== true)

    const currentClassLogin = classLogins?.length ? classLogins[0] : null


    const [username, setUsername] = useState("")
    const [classCode, setClassCode] = useState(currentClassLogin?.classCode || '');
    const [usernameUnavalible, setUsernameUnavalible] = useState<boolean>(false)
    const [saveModal, setSaveModal] = useState(false)


    const passwordValidation = (value: string) => {
        if (value.indexOf(' ') < 0) {
            setClassCode(value)
        }
    }

    const createClassLogin = async () => {
        // todo: need to move all of this to custom mutation
        try {
            if (!currentClass) {
                throw new Error("Class not found")
            }

        await customCreateClassLoginMutation({
                variables: {
                    input: {
                        classID: currentClass?.id,
                        password: classCode,
                        username: username
                    }
                }
            })
        } catch (e: any) {
            showAlert({
                title: 'An error has occured.',
                message: String(e),
                leftButtonText: 'Ok',
                rightButtonText: 'Retry',
                onRightButtonPress: createClassLogin
            })
        }
    }

    const onUpdateClassLogin = async () => {
        if (currentClassLogin) {
            try {
                await updateClassLoginMutation({
                    variables: {
                        input: {
                            classLoginID: currentClassLogin.id,
                            username,
                            newClassCode: classCode,
                            oldClassCode: currentClassLogin.classCode
                        }
                    }
                })
                setSaveModal(true)

            } catch (e: any) {
                showAlert({
                    title: 'An error has occured.',
                    message: String(e),
                    leftButtonText: 'Ok',
                    rightButtonText: 'Retry',
                    onRightButtonPress: onUpdateClassLogin
                })
            }
        }
    }

    const upsertClassLogin = async () => {
        rvIsLoading(true)

        if (currentClassLogin) {
            await onUpdateClassLogin()
        } else {
            await createClassLogin()
        }

        rvIsLoading(false)
    }

    useEffect(() => {
        setUsername(currentClassLogin?.nickname || '')
        setClassCode(currentClassLogin?.classCode || '')
    }, [currentClassLogin])

    return (
        <ScrollView style={styles.currentClassLoginContainer} contentContainerStyle={styles.currentClassLoginScrollContainer}>
            <View>
                <PageTitleText>{`${currentClass?.goMode === true ? 'GO Group' : 'Class'} login`}</PageTitleText>
            </View>
            <View>
                <InputCognitoUsername
                    title='Class nickname'
                    userExists={usernameUnavalible}
                    setUserExists={setUsernameUnavalible}
                    value={username || ""}
                    onChangeText={setUsername}
                    containerStyle={{ alignItems: 'flex-start' }}
                    isCurrentValue={currentClassLogin?.nickname === username}
                />
                <DefaultText style={styles.emailNote}>Set a class nickname that students can use to login in with.</DefaultText>
            </View>
            <View>
                <View>
                    <DefaultText style={styles.heading}>Classcode</DefaultText>
                    <View style={styles.row}>
                        <TextInput
                            style={CommonStyles.textInput}
                            placeholder='Classcode'
                            autoCorrect={false}
                            autoComplete='password'
                            autoCapitalize='none'
                            keyboardType='default'
                            textContentType='username'
                            onChangeText={passwordValidation}
                            value={classCode}
                        />
                    </View>
                </View>
                <DefaultText style={[styles.emailNote, { color: classCode.length < minClassCodeLength ? DecidaColors.Red : DecidaColors.Green }]}>
                    Set a classcode of 6 digits/characters or more.
                </DefaultText>
            </View>
            <DefaultButton
                onPress={upsertClassLogin}
                disabled={classCode.length < minClassCodeLength
                    || username === currentClassLogin?.nickname && classCode === currentClassLogin?.classCode
                    || username === ''
                    || usernameUnavalible
                }
            >
                Save
            </DefaultButton>
            <ToastAlert visible={saveModal} setVisible={setSaveModal} message='Details have been saved.' />
        </ScrollView>
    )
}
export default CurrentClassLogin

const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
    },
    header: {
        flexDirection: 'row',
        width: '100%',
    },
    backButtonContainer: {
        flex: 1,
        paddingTop: 5,
    },
    spacer: {
        flex: 1,
    },
    currentClassLoginContainer: {
        flex: 1,
    },
    currentClassLoginScrollContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 700,
        maxHeight: 500,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',

    },
    heading: {
        fontSize: 17,
        fontWeight: 'bold',
        paddingTop: 20,

    },
    emailNote: {
        width: 330,
        color: DecidaColors.Black,
        fontSize: 15,
        textAlign: 'center',
    },


})