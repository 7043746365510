
export const adminStaffFields = `
    id
    cognitoUsername
    email
    schoolID
    firstName
    lastName
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
`

export const adminGetStaffQuery = /* GraphQL */ `query GetStaff($id: ID!) {
    getStaff(id: $id) {
     ${adminStaffFields}
    }
  }`