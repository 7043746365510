import * as React from "react"
import Svg, { Defs, ClipPath, Path, G, SvgProps } from "react-native-svg"

function IconMail(props: SvgProps) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 29.065}
            height={props.height || 19.376}
            viewBox="0 0 29.065 19.376"
            {...props}
        >
            <Defs>
                <ClipPath id="a">
                    <Path
                        data-name="Rectangle 1001"
                        fill={props.color || "#666"}
                        d="M0 0H29.065V19.376H0z"
                    />
                </ClipPath>
            </Defs>
            <G data-name="Group 6766">
                <G data-name="Group 6765" clipPath="url(#a)">
                    <Path
                        data-name="Path 5862"
                        d="M4.837 0A4.837 4.837 0 000 4.837v9.7a4.837 4.837 0 004.837 4.837h19.392a4.836 4.836 0 004.837-4.837v-9.7A4.836 4.836 0 0024.229 0zm5.945 11.027l-7.02 6.206a2.9 2.9 0 001.075.207h19.392a2.9 2.9 0 001.075-.207l-7.02-6.206L15.18 13.8a.969.969 0 01-1.291 0l-3.1-2.775zM2.3 3.441l7.028 6.286L2.3 15.94a2.893 2.893 0 01-.36-1.4v-9.7a2.888 2.888 0 01.359-1.4zm24.472 0a2.9 2.9 0 01.359 1.4v9.7a2.9 2.9 0 01-.36 1.4l-7.03-6.213L26.77 3.44zm-1.46-1.294l-10.78 9.633L3.756 2.147a2.9 2.9 0 011.081-.209h19.392a2.889 2.889 0 011.081.209z"
                        fill={props.color || "#666"}
                        fillRule="evenodd"
                    />
                </G>
            </G>
        </Svg>
    )
}

export default IconMail
