import { Ionicons } from '@expo/vector-icons'
import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { DefaultButton } from './default-button'
import { DefaultText } from './default-text'


type Props = {
    onConfirm: () => void
    onCancel: () => void
}

const ButtonGroupSubmitCancel = ({ onConfirm, onCancel }: Props) => {
    return (
        <View style={styles.container}>
            <DefaultButton style={[styles.button, { backgroundColor: DecidaColors.Red }]} onPress={onCancel}>
                <DefaultText style={{ fontSize: 14, color: DecidaColors.White }}>
                    Cancel
                </DefaultText>
            </DefaultButton>
            <DefaultButton style={styles.button} onPress={onConfirm}>
                <Ionicons name="ios-checkmark-sharp" size={14} color={DecidaColors.White} />
                <DefaultText style={styles.confirmText}>
                    Confirm
                </DefaultText>
            </DefaultButton>
        </View>
    )
}
export default ButtonGroupSubmitCancel
const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        gap: 5,
        alignItems: 'center',
    },
    button: {
        width: '100%',
        flex: 1,
        height: 25,
        flexDirection: 'row',
    },
    confirmText: {
        fontSize: 14,
        color: DecidaColors.White,
    },
})