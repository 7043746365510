import { View } from 'react-native'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { CardColorScheme } from '../../common/card-color-scheme'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import { StorySentenceIcon } from '../../explore/src/story-sentence-icon'
import { DefaultTextWithExtraTipIcon } from '../../common/default-text-with-extra-tip-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { StorySentenceImage1 } from './src/story-sentence-image1'
import { StorySentenceIconInverted } from '../../explore/src/story-sentence-icon-inverted'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import LessonPlan from '../../common/lesson-plan'
import { DefaultText } from '../../../common/default-text'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'

export const SwitchesEmotionsHappyStorySentence: CardTemplate = {
    cardId: "910d98dc-e3a5-41dc-809a-e25779be5ee5",
    title: 'Story sentence',
    Header: StorySentenceIconInverted,
    Icon: StorySentenceIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Inspiring creativity, creating positivity and developing listening skills."} />
        </>
    ),
    Images: () => (
        <StorySentenceImage1 />
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"In groups of three or more, nominate a ‘story starter’."} />
                <DefaulttextWithRightArrow textdata={"Have the story starter finish one of the below sentences:"} />
                <DefaultTextWithoutArrows textdata={"A) Once upon a time, in a land far away…"} />
                <DefaultTextWithoutArrows textdata={"B) One day I woke up somewhere very strange…"} />
                <DefaultTextWithoutArrows textdata={"C) I was walking to the shops when I noticed something odd…"} />
                <DefaulttextWithRightArrow textdata={"Once the story starter has finished the first sentence, the next person adds a sentence to the story, and so on around the group. "} />
                <DefaulttextWithRightArrow textdata={"Each sentence must build on the previous one and must not immediately squash an idea of the previous sentence."} />
                <DefaulttextWithRightArrow textdata={"Once the story has naturally finished, nominate another story starter and go again."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: 'Listening and building on others stories is a great way of practicing active listening skills and increasing group connectivity. Active listening is a key element of empathy training and group collaboration as it relies on us hearing and understanding someone else’s thoughts and then creatively adding to that thought process. This activity has also been shown to build trust and connection with a group.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Encourage creativity, collaboration, and storytelling skills through a group story building activity. </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin with a short discussion about storytelling. Ask students if they enjoy hearing or creating stories.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the activity: They will work in groups to build a magical story, one sentence at a time.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity - Magical Story Circles (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Divide the class into groups of three or more. Make sure each group has markers and a large sheet of paper.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Choose a volunteer from each group to be the first "story starter."</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to the Story Starter switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ The story starter selects one of the sentences (A, B, or C) and completes it to start the story.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Each student in the group takes turns adding one sentence to the story. The sentences must build on the previous one, creating a cohesive narrative.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that ideas should flow naturally, and students should avoid immediately dismissing the previous sentence.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allow each group to continue until the story naturally reaches a conclusion. Encourage creativity and imagination.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Debrief (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Bring the class back together.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask each group to share a brief summary of their story and discuss any interesting twists or surprises that emerged.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the activity, highlighting the importance of teamwork and creativity in storytelling.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the uniqueness of each group's story and celebrate the imaginative ideas that were generated.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Optional Extension:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If time allows, rotate the role of the "story starter" within each group and have them create another story.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Large sheets of paper or whiteboard</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Timer (optional)</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Healing Power of Storytelling -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.psychologytoday.com/au/blog/un-numb/202312/the-healing-power-of-storytelling' link='https://www.psychologytoday.com/au/blog/un-numb/202312/the-healing-power-of-storytelling' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How Storytelling Is Good for Your Mental Health -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.verywellmind.com/how-storytelling-is-good-for-your-mental-health-5199744' link='https://www.verywellmind.com/how-storytelling-is-good-for-your-mental-health-5199744' />

                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
            <DefaulTextWithTipsIcon textdata={"Set a time limit for how long the next person has to contribute to the story.  If the time limit is exceeded by any individual the group should start a new story."} />
            <DefaulTextWithTipsIcon textdata={"Can also be a good activity while travelling or waiting as a group."} />
            <DefaulTextWithTipsIcon textdata={"‘Blocking’ is what to call the act of someone immediately shutting down a new idea that was offered by the previous story teller. Other players should call out “Blocking!” to reinforce the correct behaviour."} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Story+Sentence.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Happy]
}

