import { useMemo } from 'react'
import { ViewStyle } from 'react-native'
import { CheckInSession, StudentCheckIn, StudentClass } from '../../common/API'
import { convertStudentCheckInCacheCodeToCacheObject } from '../../common/student-check-in-cache'
import AllPeriodsGraphSvg from '../common/all-stats-period-graph'

interface Props {
    checkInSessions: CheckInSession[]
    studentClass: StudentClass | null
    scaleStyle?: ViewStyle
}

export const TeacherStatsAllPeriodsGraph = ({ checkInSessions, studentClass, scaleStyle }: Props) => {
    const studentCheckIns = useMemo(
        () => studentClass?.studentCheckIns?.map(studentCheckIn => convertStudentCheckInCacheCodeToCacheObject(studentCheckIn)),
        [studentClass]
    )

    const studentCheckInSessions = checkInSessions.map(checkInSession => {
        const studentCheckIn = studentCheckIns?.find(studentCheckIn => studentCheckIn.checkInSessionClassSequenceNo === checkInSession.checkInSessionClassSequenceNo)
        const date = new Date(checkInSession.createdAt || '').toLocaleDateString('en-AU', {
            day: '2-digit',
            month: '2-digit',
            weekday: "short",
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h24"
        }).split(' ') // { date: ["Tue," , "31/10," , "16:23"]}

        return {
            id: checkInSession.id,
            date: date[1].slice(0, 5),
            day: date[0].slice(0, 3),
            time: date[2],
            checkInData: studentCheckIn as unknown as StudentCheckIn,
        }
    })

    return (
        <AllPeriodsGraphSvg checkInsData={studentCheckInSessions} isTeacher={false} />
    )
}

