import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { ButterflyHugIcon } from './src/butterfly-hug-icon'
import { ButterflyHugIconInverse } from './src/butterfly-hug-icon-inverse'
import { ButterflyHugImage1 } from './src/butterfly-hug-image1'

export const SwitchesEmotionsAnxiousButterflyHug: CardTemplate = {
    cardId: "b1732aa8-9cfc-41c2-97ef-28baabd1aa95",
    title: 'Butterfly hug',
    Header: ButterflyHugIconInverse,
    Icon: ButterflyHugIcon,
    lessonPlan: true,
    mainContainerStyle: {
        maxWidth: '100%',
        paddingHorizontal: 20,
    },
    PerfectFor: () => {

        const Step1 = () => {
            return (
                <View>
                    <DefaultText style={styles.contentText}>Breathe in and out while you tap you hands on your chest like in the picture.</DefaultText>
                    <View style={styles.imageContainer}>
                        <ButterflyHugImage1 />
                    </View>
                    <WhyDoesItWorkComponent message='The Butterfly Hug helps reduce anxiety through a simple combination of rhythmic movement and bilateral stimulation. By crossing the arms and tapping the shoulders alternately, this technique engages both hemispheres of the brain, facilitating the processing of distressing emotions. The rhythmic tapping, coupled with mindful breathing, promotes a calming effect by encouraging a focus on the present moment. This sensory engagement and rhythmic pattern contribute to a sense of grounding and relaxation, helping individuals manage stress and achieve emotional balance. ' />
                </View>
            )
        }


        const contents = [
            { Component: Step1, audio: '' },
        ]

        return (
            <>
                <SwitchCardJuniorContent contents={contents} scheme={Emotion.ANXIOUS} />
            </>
        )
    },
    audioUrl: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/vo_anxious_butterfly_hugs.m4a',
    Images: undefined,
    CardContent: () => (
        <>
        </>
    )
    ,
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: "",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Students will practice using a butterfly hug to manage feelings of anxiety.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 20 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 mins):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain to the students what being anxious feels like and that sometimes it feels like butterflies in your stomach.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss times they may have felt anxious in the past about something. Invite students to share their stories of feeling anxious or nervous. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity - Butterfly hug (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain to the students that sometimes we need a special hug to make us feel better. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Demonstrate the Butterfly Hug: Have the children cross their arms over their chest, placing their hands on their opposite shoulders (right hand on left shoulder and vice versa). </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the children to take slow, deep breaths while giving themselves a gentle tap, alternating hands with each tap (See butterfly hug switch for example). </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ As you lead the class through the exercise make sure you are tapping a consistent rhythm for the full effect of the butterfly hug.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Closing (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After a few minutes, ask the children how they feel and what impact the Butterfly Hug had on their body.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce that mindful breathing helps to manage the physical impacts of anxiety or fear on the body. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude by reminding them that they can use these activities on their own if they feel anxious.  </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• N/A</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Exercies for bilateral integration</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=Ivc8TXkLHKs&ab_channel=OTCloset' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Bilateral Stimulation: What It Is and How to Use It to Reduce Anxiety</DefaultText>
                        <DefaultTextWithLink text='https://balanceapp.com/blog/bilateral-stimulation' />

                        <DefaultText style={CommonStyles.lessonPlanText}>What is Bilateral Stimulation? </DefaultText>
                        <DefaultTextWithLink text='https://anxietyreleaseapp.com/what-is-bilateral-stimulation/ ' />
                    </>}
            />

        </>
    ),
    type: [CardType.Switch],
    time: [],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Anxious]
}

const styles = StyleSheet.create({
    contentText: {
        color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
})

