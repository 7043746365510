import { View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { TheHappyMonkHeaderIcon } from './src/the-happy-monk-header-icon'
import { TheHappyMonkIcon } from './src/the-happy-monk-icon'
import { TheHappyMonkLineDrawing } from './src/the-happy-monk-line-drawing'

export const SwitchesEmotionsHappyTheHappyMonk: CardTemplate = {
    cardId: "a6ace7be-068f-42d0-9613-920de09d9083",
    title: 'The happy monk',
    Header: TheHappyMonkHeaderIcon,
    Icon: TheHappyMonkIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <View style={{ flex: 1 }}>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata='Developing advanced mindfulness meditation skills.' />
            </View>
        </>
    ),
    Images: TheHappyMonkLineDrawing,
    CardContent: () => (
        <>

            <DefaulttextWithRightArrow textdata="The following is a form of advanced mindfulness meditation called smile meditation." />
            <DefaulttextWithRightArrow textdata="Find a quiet place to sit by yourself or put some headphones in to block out any distractions." />
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <DefaulttextWithRightArrow textdata="" />
            <DefaultText>Play the audio and follow along with the <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='smile meditation' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Smile+meditation.m4a' />.</DefaultText>
            </View>

        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/The+happy+monk.m4a",
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: `When we deliberately smile during meditation, it triggers a cascade of physiological and psychological responses. The physical act of smiling activates facial muscles, releasing tension and stimulating the release of endorphins and other feel-good neurotransmitters. This, in turn, uplifts our mood and generates a sense of calm and well-being. Smiling can alter our thought patterns, redirecting our attention away from negative or stressful thoughts and promoting a more positive and optimistic mindset. By cultivating a genuine smile during meditation, we tap into the transformative potential of our own physiology and psychology, facilitating relaxation, emotional balance, and overall mental clarity.`,
    BottomRow: () => (
        <>

            <DefaultText style={CommonStyles.mainLessonText}>Things to remember:</DefaultText>
            <DefaulTextWithTipsIcon textdata="Avoid distractions while you are doing the smile meditation." />
            <DefaulTextWithTipsIcon textdata="Complete the smile meditation as many times as you need." />
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Develop advanced mindfulness meditation skills through the practice of smile meditation, cultivating joy, serenity, and self care.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 min):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce advanced mindfulness meditation and its benefits for mental and emotional wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that today's practice will focus on smile meditation, a form of advanced mindfulness meditation that cultivates joy and contentment. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Inform students that they will listen to an audio recording and follow the smile meditation instructions.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Preparation (5 min):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to sit comfortably.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to minimise external distractions by putting away, turning off or silencing their electronic devices.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Smile Meditation (10 min)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the audio recording of the smile meditation (available in the materials section).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Guide students to close their eyes, take a deep breath, and let go of tension in their bodies</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct them to follow the script, visualising and feeling the smile spreading throughout their face and body, bringing joy and serenity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Pause briefly after each instruction to allow students to fully experience the sensations of the smiling energy.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Conclusion (10 min):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After the smile meditation, ask students to slowly bring their awareness back, take a deep breath, and open their eyes.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give a moment of silence for students to reflect on their experiences. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the practice briefly as a group, asking questions such as:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- How did you feel during the smile meditation?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Did you find it challenging or easy to maintain focus?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- How can this practice benefit your overall wellbeing? </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students navigate to The Happy Monk Switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude by emphasising the importance of self care and encourage students to use smile meditation as a tool for finding moments of peace and self care.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Quiet space for each student </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Audio recording of the smile meditation' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Smile+meditation.m4a' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Device to play the audio recording</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Enhance Your Health & Wellbeing With Inner Smile Meditation</DefaultText>
                        <DefaultTextWithLink text='https://insighttimer.com/blog/what-is-inner-smile-meditation/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Advanced Meditation Techniques</DefaultText>
                        <DefaultTextWithLink text='https://www.do-meditation.com/advanced-meditation-techniques.html' />
                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Happy]
}


