import { DrawerHeaderProps } from '@react-navigation/drawer/lib/typescript/src/types'
import { ReactNode } from 'react'
import { SafeAreaView, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import { envBackgroundColor } from '../env'
import { IconMenuBar } from '../icon/icon-menu-bar'
import { useResponsive } from './use-responsive'
import { useShowDrawer } from './use-show-drawer'

interface Props {
    leftChildren?: ReactNode,
    centerChildren?: ReactNode,
    rightChildren?: ReactNode,
    disableDrawer?: boolean,
    headerStyle?: ViewStyle
}

export const Header = ({ leftChildren, centerChildren, rightChildren, disableDrawer, navigation, headerStyle }: DrawerHeaderProps & Props) => {
    const { showDrawer } = useShowDrawer()

    return (
        <SafeAreaView style={styles.safeArea}>
            <View style={[styles.mainContainer, headerStyle]}>
                <View style={styles.container}>
                    <View style={styles.containerLeft}>
                        {!showDrawer && !disableDrawer &&
                            <TouchableOpacity style={styles.iconPadding} onPress={navigation.openDrawer}>
                                <IconMenuBar />
                            </TouchableOpacity>
                        }
                        {leftChildren}
                    </View>
                    <View style={styles.containerCenter}>
                        {centerChildren}
                    </View>
                    <View style={styles.containerRight}>
                        {rightChildren}
                    </View>
                </View>
            </View>
        </SafeAreaView>
    )
}

export const headerHeight = 74

const styles = StyleSheet.create({
    safeArea: {
        flexDirection: 'row',
        backgroundColor: envBackgroundColor,
    },
    mainContainer: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: envBackgroundColor,
        height: headerHeight,
    },
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 10,
        marginRight: 10,
        marginTop: 6,
        marginBottom: 6,
    },
    containerLeft: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerCenter: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerRight: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    iconPadding: {
        padding: 10,
    },
})
