import { View } from 'react-native'
import { DefaultText } from '../../common/default-text'
import VideoCard from '../../common/video-card'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import { Worksheets } from '../../common/worksheets-imports'


export const SwitchesLessonPlansM3ThePersuitOfHappiness: CardTemplate = {
    cardId: "e3f569a6-9cba-406d-8b65-b4199c71d97d",
    title: 'The pursuit of happiness: Cultivating EI',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Define happiness and its importance in emotional wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Explore the factors that contribute to happiness and its connection to emotional intelligence.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Develop strategies to cultivate happiness and foster positive relationships with self and others.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>• Duration 45 minutes </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by asking students to share their thoughts on happiness. What does happiness mean to them? How does it impact their lives and relationships? Write down their responses on the whiteboard or chart paper.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present the level 7 emotion wheel with an emphasis on the relevant quadrant.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer to or distribute the Pursuit of Happiness workbook to each student.  </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Understanding Happiness (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that happiness is a subjective state of wellbeing and contentment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the difference between short term happiness and long term happiness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Short term happiness refers to the immediate and temporary feelings of joy, pleasure, or contentment that we experience in the present moment. It can be triggered by immediate gratification, such as indulging in a favourite treat, watching a funny video, or receiving a compliment. Short term happiness is often associated with instant rewards and can provide a quick boost to our mood and wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- On the other hand, long term happiness refers to a deeper and more enduring sense of wellbeing and satisfaction with life. It is not solely dependent on fleeting moments of pleasure but involves a more holistic and sustainable sense of fulfillment. Long term happiness is often linked to our overall life satisfaction, sense of purpose, and the quality of our relationships and experiences.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of long term happiness in overall emotional wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Use examples or personal anecdotes to help students relate to the concept of happiness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Example: Imagine you have been looking forward to buying a new video game that you've been wanting for a long time. When you finally get it, you feel a surge of excitement and happiness as you start playing it. This is an example of short term happiness because it brings you immediate joy and satisfaction in that moment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Personal Anecdote: I remember the time when I received a surprise gift from my best friend. It was a book by my favourite author that I had been longing to read. When I unwrapped the gift, I felt an instant rush of happiness and excitement. Reading that book brought me great pleasure and made me feel happy at that moment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Example: Now, imagine working hard on a school project and putting in a lot of effort to create something meaningful. When you finally present your project to the class and receive positive feedback and recognition for your hard work, you experience a deep sense of pride, accomplishment, and happiness. This is an example of long term happiness because it comes from the satisfaction of achieving a goal and feeling a sense of fulfillment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Personal Anecdote: Last year, I volunteered at a local animal shelter. I spent several weeks taking care of abandoned dogs and cats, helping to find them new homes. Seeing the animals find loving families and witnessing the joy on their faces made me feel a profound sense of happiness and fulfillment. It was a long term happiness that came from making a positive impact and contributing to the wellbeing of others.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Activity: Happiness Reflection (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer students to their workbooks. Instruct them to write down a personal experience that made them happy and why.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share their thoughts and observations in pairs or small groups, focusing on the factors that contribute to happiness.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Factors Contributing to Happiness (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the factors that contribute to happiness, such as positive relationships, gratitude, personal fulfillment, and a sense of purpose. Explore each factor in detail and ask students to share examples or personal experiences related to these factors.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the connection between emotional intelligence and happiness, highlighting how emotional awareness, self regulation, empathy, and interpersonal skills contribute to overall wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a class discussion on how emotional intelligence impacts happiness and how students can develop these skills to enhance their own happiness and that of others.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Cultivating Happiness Strategies (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce strategies to cultivate happiness, such as practicing gratitude, engaging in positive self talk, nurturing relationships, setting meaningful goals, and engaging in activities that bring joy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students access the Switch4Schools program on their device and encourage them to explore the available switches assigned to happy on the emotion wheel to bookmark activities they’d like to try.  Alternatively provide a copy of the level 7 quick reference guide.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the benefits of some of the techniques selected by the group and when they might be most helpful.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the main points discussed during the lesson, emphasising the importance of emotional intelligence in understanding and cultivating happiness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on the lesson and write down at least one thing they learnt and one thing they will do as a result of this lesson in their workbook.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss any challenges or insights they gained from the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ In addition to checking in via the Switch program, encourage students to make use of the space in their workbook to journal their thoughts and feelings over the coming week to better understand their happiness triggers.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to apply the strategies learned to cultivate happiness in their daily lives and build positive relationships with self and others.</DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={'Mastering the mind workbook, including section M3 The Pursuit of Happiness: Cultivating Emotional Intelligence'} link={Worksheets.masteryMasteringTheMindLevel7.uri} /></DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>The Science of Happiness in Positive Psychology 101 -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://positivepsychology.com/happiness/' link='https://positivepsychology.com/happiness/' />
                    </>}
            />

        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}