import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultButton } from '../../../common/default-button'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { TheInquisitorHeaderIcon } from './src/the-inquisitor-header-icon'
import { TheInquisitorIcon } from './src/the-inquisitor-icon'
import { TheInquisitorLineDrawing } from './src/the-inquisitor-line-drawing'
import VideoCard from '../../../common/video-card'
import { Worksheets } from '../../../common/worksheets-imports'

const whoQuestions = [
    "Who has been the most influential person in your life?",
    "Who is your favourite actor/actress and why?",
    "Who would you invite to a dinner party, dead or alive?",
    "Who is your role model and why?",
    "Who is someone you admire for their creativity?",
    "Who is your favourite musician or band?",
    "Who is someone you've always wanted to meet?",
    "Who is the most interesting person you've ever met?",
    "Who is your favourite historical figure and why?",
    "Who is the funniest person you know?",
    "Who is someone you look up to in your field of work/study?",
    "Who is the most adventurous person you know?",
    "Who is the most supportive person in your life?",
    "Who is someone you consider a true friend?",
    "Who is your favourite athlete and why?",
    "Who is the most influential person on social media, in your opinion?",
    "Who is the most interesting person you've met recently and why?",
    "Who is your favourite fictional character and why?",
    "Who is your favourite musician or band and why?",
    "Who is the most compassionate person you know and how do they demonstrate it?",
    "Who is the most inspiring speaker you've heard and what made them inspiring?",
    "Who is the most supportive friend you have and how do they support you?",
    "Who is someone you know who has overcome significant challenges and inspires you?",
];

const whatQuestions = [
    "What is your favourite book and why?",
    "What is your favourite movie genre and why?",
    "What is your dream travel destination and why?",
    "What is your favourite hobby or pastime?",
    "What is the most adventurous thing you've ever done?",
    "What is the best piece of advice you've ever received?",
    "What is your favourite type of cuisine?",
    "What is your favourite way to relax and unwind?",
    "What is the most interesting fact you've learned recently?",
    "What is your favourite season and why?",
    "What is your favourite quote and why?",
    "What is the best gift you've ever received?",
    "What is the most challenging thing you've ever accomplished?",
    "What is your favourite form of exercise or physical activity?",
    "What is the most inspiring documentary or film you've watched?",
    "What is your favourite board game or card game?",
    "What is the best piece of advice you would give to your younger self?",
    "What is something you've always wanted to learn and why?",
    "What is your favourite type of art (painting, sculpture, photography, etc.)?",
    "What is your favourite childhood memory?",
    "What is your favourite way to express your creativity?",
    "What is the most memorable trip or vacation you've ever taken?",
    "What is your favourite outdoor activity and why?",
    "What is your favourite memory from a family gathering or celebration?",
    "What is the best piece of advice you've ever given to someone?",
    "What is your favourite quote from a movie or TV show and why?",
    "What is your favourite way to spend a rainy day?",
    "What is the most interesting or unusual food you've ever tried?",
]

const whenQuestions = [
    "When was the last time you travelled and where did you go?",
    "When is your favourite time of the year and why?",
    "When did you discover your passion for [insert hobby/interest]?",
    "When was a moment in your life when you felt the proudest?",
    "When do you usually feel most productive or creative?",
    "When was the last time you tried something new and exciting?",
    "When was a time when you overcame a significant challenge?",
    "When do you prefer to wake up in the morning and why?",
    "When was a moment that made you laugh uncontrollably?",
    "When was a time when you felt truly inspired by someone?",
    "When do you feel most at peace or relaxed?",
    "When was the last time you volunteered or helped someone in need?",
    "When was a time when you felt completely out of your comfort zone?",
    "When do you typically find yourself the most motivated or driven?",
    "When did you last read a book that had a significant impact on you?",
    "When do you feel most connected to your friends or loved ones?",
    "When was a time you felt a strong sense of accomplishment?",
    "When was the last time you attended a wedding or special celebration?",
    "When did you discover your favourite hobby or pastime?",
    "When do you feel most creative or inspired to pursue your passions?",
    "When did you experience a moment of pure joy or happiness?",
    "When do you feel most in tune with your intuition or instincts?",
    "When was a time you felt a deep connection to a piece of art, music, or literature?",
]

const whereQuestions = [
    "Where is your favourite place to go for a peaceful retreat?",
    "Where would you love to live if you could choose any place in the world?",
    "Where is the best restaurant you've ever been to and what did you enjoy there?",
    "Where is your favourite place to spend time in nature?",
    "Where is your dream vacation destination and why?",
    "Where is the most memorable place you've watched a sunset or sunrise?",
    "Where is your favourite spot to relax and unwind after a long day?",
    "Where is the most interesting museum or historical site you've visited?",
    "Where is a place you'd love to visit again and explore further?",
    "Where is the best place you've ever attended a live concert or performance?",
    "Where is your favourite local hangout spot and what do you enjoy doing there?",
    "Where is a place you find solace or a sense of peace during challenging times?",
    "Where is your favourite spot to witness natural beauty, such as mountains or waterfalls?",
    "Where is the most unique or memorable place you've ever stayed overnight?",
    "Where is your preferred place to socialise and spend time with friends?",
    "Where is a location you associate with fond childhood memories?",
    "Where is a place that holds sentimental value or is significant to your personal history?",
]

const whyQuestions = [
    "Why do you enjoy engaging in your favourite hobby or activity?",
    "Why do you think certain books or movies have the ability to touch our hearts?",
    "Why do you think personal growth and self-improvement are valuable?",
    "Why do you think laughter is an essential part of life?",
    "Why do you think it's important to embrace failure and learn from it?",
    "Why do you think it's important to step outside of your comfort zone?",
    "Why do you think it's important to prioritise self-care and wellbeing?",
    "Why do you believe in the significance of setting boundaries in relationships?",
    "Why do you find motivation in the pursuit of personal growth and self-improvement?",
    "Why do you think it's important to advocate for causes you believe in?",
    "Why do you think it's important to live authentically and be true to yourself?",
]

const howQuestions = [
    "How do you approach problem-solving and finding solutions?",
    "How do you manage your time effectively and stay organised?",
    "How do you stay motivated and overcome obstacles in pursuing your goals?",
    "How do you express your creativity and nurture your artistic side?",
    "How do you handle stress and practice self-care during challenging times?",
    "How do you navigate and adapt to change in various aspects of life?",
    "How do you make decisions when faced with difficult choices or dilemmas?",
    "How do you demonstrate empathy and show support to those around you?",
    "How do you make a positive impact and contribute to the world around you?",
    "How do you actively seek out opportunities for personal and professional growth?",
    "How do you build and maintain a strong support system of friends and loved ones?",
    "How do you manage stress and prioritise self-care during busy or demanding periods?",
]

export const SwitchesEmotionsHappyTheInquisitor: CardTemplate = {
    cardId: "0570667b-d38f-480b-9633-8085faa504ce",
    title: 'The inquisitor',
    Header: TheInquisitorHeaderIcon,
    Icon: TheInquisitorIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <View style={{ flex: 1 }}>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata='Improving communication skills, building stronger relationships and promoting personal growth.' />
            </View>
        </>
    ),
    Images: TheInquisitorLineDrawing,
    CardContent: () => (
        <>

            <DefaulttextWithRightArrow textdata="Find a partner to complete the inquisitor switch with you." />
            <DefaulttextWithRightArrow textdata="Ask the partner which of the following sections of questions they would like to answer (who, what, when, where, why, or how) and click on that button." />
            <DefaulttextWithRightArrow textdata="Ask the person the question that comes up on the screen. " />
            <DefaulttextWithRightArrow textdata="Alternate roles each time you ask a question." />

        </>
    ),
    audioUrl: "",
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: `Being inquisitive fosters emotional intelligence by promoting self awareness, empathy, and a deeper understanding of others, which allows individuals to navigate and manage their emotions more effectively, build stronger relationships, and exhibit greater emotional resilience.`,
    BottomRow: () => {
        const [currentQuestion, setCurrentQuestion] = useState("")

        const handleRandomizeQuestion = (questions: string[]) => () => {
            const randomIndex = Math.floor(Math.random() * questions.length);
            setCurrentQuestion(questions[randomIndex]);
        }

        return (
            <>
                <View style={styles.buttonsWrapper}>
                    <DefaultButton onPress={handleRandomizeQuestion(whoQuestions)} style={styles.button}>Who</DefaultButton>
                    <DefaultButton onPress={handleRandomizeQuestion(whatQuestions)} style={styles.button}>What</DefaultButton>
                    <DefaultButton onPress={handleRandomizeQuestion(whenQuestions)} style={styles.button}>When</DefaultButton>
                    <DefaultButton onPress={handleRandomizeQuestion(whereQuestions)} style={styles.button}>Where</DefaultButton>
                    <DefaultButton onPress={handleRandomizeQuestion(whyQuestions)} style={styles.button}>Why</DefaultButton>
                    <DefaultButton onPress={handleRandomizeQuestion(howQuestions)} style={styles.button}>How</DefaultButton>
                    <DefaultButton onPress={handleRandomizeQuestion([...whoQuestions, ...whatQuestions, ...whenQuestions, ...whereQuestions, ...whyQuestions, ...howQuestions])} style={styles.button}>Shuffle all</DefaultButton>
                </View>

                <View style={styles.questionContainer}>
                    <DefaultText style={styles.questionText}>{currentQuestion}</DefaultText>
                </View>

                <DefaultText style={CommonStyles.mainLessonText}>Things to remember:</DefaultText>
                <DefaulTextWithTipsIcon textdata="You may like to use some of these questions during the day to spark some interesting conversations. " />
                <DefaulTextWithTipsIcon textdata="The more you are open and honest the easier it will be for them to be honest with you." />
            </>
        )
    },
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Demonstrate ability to ask better questions that encourage critical thinking, problem  solving, and effective communication.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by discussing the importance of asking questions. Ask students why they think asking questions is crucial in learning and problem solving.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that this lesson will focus on developing their skills in asking better questions, which will enhance their critical thinking and communication abilities.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Types of Questions (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce different types of questions, such as:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Open ended questions: These encourage detailed and thoughtful responses, allowing the person to express themselves and provide more information. For example, "What are your thoughts on...?" or "Can you tell me more about...?"</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Closed ended questions: These typically elicit brief and specific answers, often a simple "yes" or "no." They are useful for seeking specific information. For example, "Did you finish your homework?" or "Have you been to that restaurant before?"</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Probing questions: These are follow up questions used to delve deeper into a topic, clarify understanding, or gather additional information. They aim to explore more details or perspectives. For example, "Why do you think that happened?" or "Can you give me an example?"</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Reflective questions: These encourage introspection and self reflection, promoting personal insight and deeper understanding. They often begin with phrases like "How do you feel about...?" or "What have you learned from...?"</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Hypothetical questions: These present imaginary scenarios to stimulate critical thinking and creative problem solving. They often begin with "What if...?" or "How would you handle...?"</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Leading questions: These are phrased in a way that suggests a particular answer or influences the respondent's opinion. They can be used strategically to guide a conversation or gauge someone's perspective. However, they should be used with caution to ensure fairness and objectivity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Clarifying questions: These seek to obtain clear explanations or resolve any ambiguity in a statement or conversation. They aim to ensure a shared understanding of information or ideas. For example, "Can you please clarify what you meant by...?" or "Could you give me an example to help me understand better?"</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Socratic questions: These are thought provoking questions that encourage critical thinking, self reflection, and deeper exploration of ideas. They challenge assumptions, promote analysis, and stimulate intellectual growth. For example, "What evidence supports that claim?" or "What are the possible consequences of that decision?"</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the advantages of open ended questions in fostering deeper understanding, critical thinking, and creativity.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Engage students by asking open ended questions related to a subject they are familiar with, such as literature, science, or history. Encourage them to share their responses and discuss the diverse perspectives generated.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Characteristics of Good Questions: (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the characteristics of good questions that promote critical thinking and problem solving: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Relevance: The question should be related to the topic or problem being explored. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Clarity: The question should be clear and easy to understand.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Open ended: The question should require more than a simple "yes" or "no" answer and invite further discussion or exploration.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Thought provoking: The question should encourage students to think deeply and consider different viewpoints or possibilities.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Question Improvement Activity: (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Working individually, in pairs or small groups.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide each student with the <DefaultTextWithCustomLink text={Worksheets.goodAndBadQuestionWorksheet.label} link={Worksheets.goodAndBadQuestionWorksheet.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct the students to transform the given questions into open ended questions that possess the characteristics discussed earlier.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After the allocated time, have each group share their improved questions with the rest of the class, explaining why they believe their questions are better.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reflection and Discussion:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Lead a class discussion on the question improvement activity. Encourage students to share their thought processes and the challenges they faced while transforming the questions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Discuss the impact of open  ended questions on learning, problem  solving, and communication skills. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Emphasise the importance of asking better questions in various aspects of life, including academics, careers, and personal growth.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the main points discussed in the lesson.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students navigate to The Inquisitor Switch and explain how it works.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice asking open  ended questions in their daily lives to enhance their critical thinking and communication skills.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Slides' link='https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/The+Inquisitor+Slides.pptx' /> </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text={Worksheets.goodAndBadQuestionWorksheet.label} link={Worksheets.goodAndBadQuestionWorksheet.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Writing materials</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Types of questions</DefaultText>
                        <DefaultTextWithLink text='https://www.skillsyouneed.com/ips/question-types.html' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Why Are Therapist Questions Open-Ended?</DefaultText>
                        <DefaultTextWithLink text='https://www.verywellmind.com/open-ended-questions-2330693' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Closed vs. Open Questions</DefaultText>
                        <View style={styles.centered}>
                            <VideoCard video={{
                                id: '1',
                                url: 'https://www.youtube.com/embed/OJZH-raxGhg'
                            }} />
                        </View>
                    </>}
            />
        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Happy]
}



const styles = StyleSheet.create({
  button: {
    maxWidth: 200,
  },
  centered: {
    alignItems: 'center',
    justifyContent: 'center',
  },
    questionText: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: DecidaColors.Black,
    },
    questionContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 40
    },
    buttonsWrapper: {
        flexDirection: 'row',
        maxWidth: '100%',
        gap: 10,
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 20
    },
})