import React, { FC, ReactNode, useCallback, useState } from 'react';
import { LayoutChangeEvent, StyleSheet, View, ViewStyle } from 'react-native';
import { useResponsive } from './use-responsive';

type Props = {
    children?: ReactNode
    fitHorizontal?: boolean
    style?: ViewStyle | ViewStyle[]
}
const ViewResponsive: FC<Props> = ({ children, style, fitHorizontal }) => {
    const [marginRatioY, setMarginRationY] = useState<number | undefined>(undefined)
    const [marginRatioX, setMarginRationX] = useState<number | undefined>(undefined)
    const { scaleRatio, height, lowRes } = useResponsive();
    const transformByRatioStyle: ViewStyle = { transform: [{ scale: lowRes ? scaleRatio : 1 }] };

    const getViewLayout = (event: LayoutChangeEvent) => {
        const { x, y } = event.nativeEvent.layout;
        setMarginRationX(x)
        setMarginRationY(y)

    }


    return (
        <View style={[
            style, {
                position: 'relative',
                marginVertical: lowRes && marginRatioY ? -marginRatioY : undefined,
                marginLeft: lowRes && marginRatioX && fitHorizontal ? -marginRatioX : undefined,
                marginRight: lowRes && marginRatioX && fitHorizontal ? -marginRatioX : undefined
            }]}>
            <View onLayout={useCallback(getViewLayout, [lowRes, height])}
                style={[styles.container, transformByRatioStyle, { width: lowRes ? '100%' : undefined, alignItems: 'center' }, style]}>
                {children}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        position: 'relative',
        top: 0,
        left: 0,
        right: 0,
    }
})

export default ViewResponsive