import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { StyleSheet, View } from 'react-native'
import { GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables, Teacher } from '../../common/API'
import { UserGroup } from '../../common/constants'
import { updateStudent } from '../../common/graphql/mutations'
import { getStudent, getTeacherByCognitoUsername } from '../../common/graphql/queries'
import { BackButton } from '../common/back-button'
import { DefaultText } from '../common/default-text'
import { CLIENT_EVENT, createClientLog } from '../common/log-client-event'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { avatars } from '../icon/avatar/avatars'
import { rvCurrentUser, rvUserGroup } from '../login/login-state'
import { SchoolAdminClassNavigatorParamsList } from '../school-admin/school-admin-navigation-params'
import { TeacherAvatarPicker } from './teacher-avatar-picker'
import { TeacherClassNavigatorParamList } from './teacher-route-param-types'

type TeacherEditStudentAvatarStackScreenProps = StackScreenProps<TeacherClassNavigatorParamList & SchoolAdminClassNavigatorParamsList, "TeacherEditStudentAvatar" | "SchoolAdminEditStudentAvatar">

export const TeacherEditStudentAvatar = ({ route: { params: { studentID, classID } }, navigation: { navigate, goBack } }: TeacherEditStudentAvatarStackScreenProps) => {
    const userGroup = useReactiveVar(rvUserGroup)
    const user = useReactiveVar(rvCurrentUser)

    const { data: teacherResponse } = useQuery<GetTeacherByCognitoUsernameQuery, GetTeacherByCognitoUsernameQueryVariables>(gql`${getTeacherByCognitoUsername}`, { variables: { cognitoUsername: user?.username || "" }, skip: !user })
    const currentTeacher = teacherResponse?.getTeacherByCognitoUsername?.items[0] as Teacher

    const { data: studentData } = useQuery(gql`${getStudent}`, { variables: { id: studentID } })
    const [updateStudentMutation] = useMutation(gql`${updateStudent}`)

    const student = studentData?.getStudent

    const navigateToClassAdmin = () => {
        if (userGroup === UserGroup.Teacher || userGroup === UserGroup.Admin) {
            navigate(ScreenNames.TeacherEditStudent, { studentID: studentID || "", classID: classID || "" })
        } else if (userGroup === UserGroup.SchoolAdmin) {
            goBack()
        }
    }

    const setStudentAvatar = async (avatarName: keyof typeof avatars) => {
        if (student) {
            const sudentDataPayload = {
                id: student.id,
                _version: student._version,
                avatar: avatarName,
            }

            try {
                await updateStudentMutation({ variables: { input: sudentDataPayload } })

                createClientLog({
                    area: 'TeacherEditStudent Avatar',
                    event: CLIENT_EVENT.UPDATE_STUDENT_DATA_SUCCESS,
                    action: `Update student: "${student.id}", success`,
                    payload: {
                        group: "Teacher",
                        nickname: currentTeacher?.cognitoUsername
                    }
                })

                navigateToClassAdmin()
            } catch (error) {
                createClientLog({
                    area: 'TeacherEditStudent Avatar',
                    event: CLIENT_EVENT.UPDATE_STUDENT_DATA_FAILED,
                    action: `Update student: "${student.id}", failed`,
                    payload: {
                        group: "Teacher",
                        nickname: currentTeacher?.cognitoUsername,
                        error: JSON.stringify(error)
                    }
                })
            }
        }
    }

    return (
        <Page style={styles.page}>
            {
                userGroup === UserGroup.Teacher && (
                    <View style={styles.header}>
                        <View style={styles.backButtonContainer}>
                            <BackButton onPress={navigateToClassAdmin} />
                        </View>
                    </View>
                )
            }
            <DefaultText style={styles.title}>Choose an avatar</DefaultText>
            <TeacherAvatarPicker onPress={setStudentAvatar} />
        </Page>
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'flex-start',
    },
    header: {
        flexDirection: 'row',
        width: '100%',
    },
    backButtonContainer: {
        flex: 1,
        padding: 5,
    },
    title: {
        fontSize: 30,
        padding: 20,
        alignSelf: 'center',
    },
})