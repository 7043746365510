import { MutableRefObject } from "react"
import { CellObject, WorkSheet } from "xlsx"
import { Message, Sheet, StudentToCreate, StudentToUpdate } from "./common-import-school-data-const-and-types"
import { SchoolHierarchy } from "./list-school-hierarchy"
import { convertSpreadsheetToRows } from "./convert-spreadsheet-to-rows"
import { asapPromiseQueue } from "../../../common/asap-promise-queue"
import { emailValidator } from "../../common/email-validator"
import { validateFieldNotUsedInPreviousRows } from "./validate-field-not-used-in-previou-rows"
import { isDefined } from "../../../common/is-defined"
import { CreateStudentClassInput, GetSchoolQuery, GetSchoolQueryVariables, GetStudentByCognitoUsernameQuery, GetStudentByCognitoUsernameQueryVariables, GetStudentByNicknameQuery, GetStudentByNicknameQueryVariables, ListStudentsByEmailQuery, ListStudentsByEmailQueryVariables, ListStudentsBySchoolIDQuery, ListStudentsBySchoolIDQueryVariables, Student, StudentClassApprovalStatus, StudentStatus } from "../../../common/API"
import gql from "graphql-tag"
import { getSchool, getStudentByCognitoUsername, getStudentByNickname, listStudentsByEmail, listStudentsBySchoolID } from "../../../common/graphql/queries"
import { apolloClient } from "../../common/common-state"
import { importStudentHasChanged } from "./import-student-has-changed"
import { workOutAvatarName } from "./work-out-avatar-name"
import { v4 as randomUUID } from "uuid"
import { usernameTaken } from "../../login/username-taken-validator"
import { sanitiseNameForCognitoUsername } from "./sanitise-name-for-conginto-username"
import { adminGetSchoolQuery } from "../../custom-graphql/queries/admin-custom-queries/admin-get-school-query"

type Params = {
    sheet: WorkSheet,
    schoolID: string,
    errors: MutableRefObject<Message[]>,
    warnings: MutableRefObject<Message[]>,
    info: MutableRefObject<Message[]>,
    currentSchoolHierarchy: MutableRefObject<SchoolHierarchy>,
    studentsToCreate: MutableRefObject<StudentToCreate[]>,
    studentsToUpdate: MutableRefObject<StudentToUpdate[]>,
    studentClassesToCreate: MutableRefObject<CreateStudentClassInput[]>,
    setProcessingMessage: React.Dispatch<React.SetStateAction<string>>,
}

export const validateHierarchyStudentSheet = async ({
    sheet,
    schoolID,
    errors,
    warnings,
    info,
    currentSchoolHierarchy,
    studentsToCreate,
    studentsToUpdate,
    studentClassesToCreate,
    setProcessingMessage,
}: Params) => {
    if (sheet == null || schoolID == null) {
        return
    }

    if (!(sheet['B7'] as CellObject)?.v?.toString().startsWith('Student ID')) {
        errors.current.push({ sheet: Sheet.Student, row: 7, message: 'header column expected, please do not modify row 1-8 of template file.' })
        return
    }

    const rows = convertSpreadsheetToRows({
        startingRow: 8,
        columnOrder: ['hasError', 'studentID', 'firstName', 'lastName', 'email', 'existingUsername', 'campusName', 'classGroupName', 'yearLevel'],
        sheet,
        filter: row => row.firstName != null || row.lastName != null || row.email != null,
        hasDynamicClasses: true,
    }).map(row => ({ ...row, email: row.email?.toLowerCase() }))

    setProcessingMessage('Loading school student(s)')
    let schoolStudents: NonNullable<ListStudentsBySchoolIDQuery['listStudentsBySchoolID']>['items'] = []
    let pagedSchoolStudents = await apolloClient.query<ListStudentsBySchoolIDQuery, ListStudentsBySchoolIDQueryVariables>({
        query: gql`${listStudentsBySchoolID}`,
        variables: {
            schoolID,
            limit: 50000,
        },
        fetchPolicy: 'network-only',
    })
    schoolStudents = schoolStudents.concat(pagedSchoolStudents.data.listStudentsBySchoolID?.items || [])
    setProcessingMessage(`Loaded ${schoolStudents.length} student(s)`)

    while (pagedSchoolStudents.data.listStudentsBySchoolID?.nextToken != null) {
        pagedSchoolStudents = await apolloClient.query<ListStudentsBySchoolIDQuery, ListStudentsBySchoolIDQueryVariables>({
            query: gql`${listStudentsBySchoolID}`,
            variables: {
                schoolID,
                limit: 50000,
                nextToken: pagedSchoolStudents.data.listStudentsBySchoolID.nextToken
            },
            fetchPolicy: 'network-only',
        })

        schoolStudents = schoolStudents.concat(pagedSchoolStudents.data.listStudentsBySchoolID?.items || [])
        setProcessingMessage(`Loaded ${schoolStudents.length} student(s)`)
    }

    schoolStudents = schoolStudents.filter(isDefined).filter(ss => ss?._deleted !== true)

    await asapPromiseQueue(rows, async ({ studentID, firstName, lastName, email, existingUsername, campusName, classGroupName, yearLevel, classes, row }, index) => {
        setProcessingMessage(`Validating row ${index + 1}/${rows.length}`)
        await new Promise(r => setTimeout(() => r(undefined))) // an immediately resolved promise to give UI a chance to update

        const missingMandatoryFields: string[] = []
        const filteredClasses = classes.filter(isDefined).filter(c => c !== '')
        firstName == null && missingMandatoryFields.push('First Name')
        lastName == null && missingMandatoryFields.push('Last Name')
        campusName == null && missingMandatoryFields.push('Campus')
        classGroupName == null && missingMandatoryFields.push('Grouping')

        let canCreateStudent = true

        if (missingMandatoryFields.length > 0) {
            errors.current.push({ sheet: Sheet.Student, row, message: 'missing mandatory fields: ' + missingMandatoryFields.join(', ') })
        }

        if (studentID == null && email == null && existingUsername == null) {
            errors.current.push({ sheet: Sheet.Student, row, message: ' of Student ID, Email addres, or Existing username field must to be provided' })
            canCreateStudent = false
        }

        if (classes.length === 0) {
            errors.current.push({ sheet: Sheet.Student, row, message: 'student need to be assigned to at least 1 class' })
            canCreateStudent = false
        }

        if (email && emailValidator(email) === "Email is not valid.") {
            errors.current.push({ sheet: Sheet.Student, row, message: 'student email is not valid' })
            canCreateStudent = false
        }

        canCreateStudent = canCreateStudent && validateFieldNotUsedInPreviousRows(rows, 'existingUsername', index, Sheet.Student, errors)
        canCreateStudent = canCreateStudent && validateFieldNotUsedInPreviousRows(rows, 'email', index, Sheet.Student, errors)
        canCreateStudent = canCreateStudent && validateFieldNotUsedInPreviousRows(rows, 'studentID', index, Sheet.Student, errors)

        if (campusName != null) {
            const campus = currentSchoolHierarchy.current[campusName]

            if (campus == null) {
                errors.current.push({ sheet: Sheet.Student, row, message: 'Cannot find campus: ' + campusName })
                return
            } else {
                if (classGroupName != null) {
                    const classGroup = campus.classGroups[classGroupName]

                    if (classGroup == null) {
                        errors.current.push({ sheet: Sheet.Student, row, message: `Cannot find Grouping "${classGroupName}" under campus "${campusName}"` })
                        return
                    } else {
                        if (!canCreateStudent) {
                            return
                        }

                        let student: Student | null = null

                        const studentRow = {
                            studentID,
                            firstName,
                            lastName,
                            email,
                            nickname: existingUsername,
                            avatar: '',
                            yearLevel,
                            row,
                            classes,
                        }

                        if (existingUsername != undefined) {
                            const nicknameResponse = await apolloClient.query<GetStudentByNicknameQuery, GetStudentByNicknameQueryVariables>({
                                query: gql`${getStudentByNickname}`,
                                variables: {
                                    nickname: existingUsername
                                },
                                fetchPolicy: 'network-only',
                            })

                            const usernameResponse = await apolloClient.query<GetStudentByCognitoUsernameQuery, GetStudentByCognitoUsernameQueryVariables>({
                                query: gql`${getStudentByCognitoUsername}`,
                                variables: {
                                    cognitoUsername: existingUsername
                                },
                                fetchPolicy: 'network-only',
                            })

                            const students = (nicknameResponse.data?.getStudentByNickname?.items.filter(i => i?._deleted !== true) || []).concat(usernameResponse.data.getStudentByCognitoUsername?.items.filter(i => i?._deleted !== true) || [])

                            if (students.length > 1) {
                                errors.current.push({ sheet: Sheet.Student, row, message: `multiple accounts matched using nickname/username ${existingUsername}` })
                                return
                            } else {
                                student = students[0]
                                if (student == undefined) {
                                    errors.current.push({ sheet: Sheet.Student, row, message: `Cannot find student with nickname/username "${existingUsername}"` })
                                    return
                                } else {
                                    if (importStudentHasChanged(schoolID, studentRow, student)) {
                                        if (student.schoolID !== schoolID) {
                                            await addMoveSchoolWarning(student, row, warnings)
                                        }

                                        studentsToUpdate.current.push({
                                            id: student.id,
                                            _version: student._version,
                                            cognitoUsername: student.cognitoUsername,
                                            email: email || student.email,
                                            firstName,
                                            lastName,
                                            nickname: existingUsername, // searched by nickname so use value from import
                                            schoolID,
                                            schoolStudentID: studentID || student.schoolStudentID,
                                            status: StudentStatus.ACTIVE,
                                            yearLevel: yearLevel || student.yearLevel,
                                        })
                                    }
                                }
                            }
                        }

                        if (student == null) {

                            if (studentID != undefined) {
                                // only search within current school because student id is only guaranteed to be unique within current school
                                const students = schoolStudents.filter(s => s?.schoolStudentID === studentID)

                                if (students.length > 1) {
                                    errors.current.push({ sheet: Sheet.Student, row, message: `multiple accounts matched using student ID ${studentID}` })
                                    return
                                } else {
                                    student = students[0]
                                }

                                if (student != undefined) {
                                    if (importStudentHasChanged(schoolID, studentRow, student)) {
                                        if (student.schoolID !== schoolID) {
                                            await addMoveSchoolWarning(student, row, warnings)
                                        }

                                        studentsToUpdate.current.push({
                                            id: student.id,
                                            _version: student._version,
                                            email: email || student.email,
                                            firstName,
                                            lastName,
                                            schoolID,
                                            status: StudentStatus.ACTIVE,
                                            yearLevel: yearLevel || student.yearLevel,
                                        })
                                    }
                                }
                            }
                        }

                        if (student == null && email != null) {
                            const studentsByEmail = await apolloClient.query<ListStudentsByEmailQuery, ListStudentsByEmailQueryVariables>({
                                query: gql`${listStudentsByEmail}`,
                                variables: {
                                    email
                                },
                                fetchPolicy: 'network-only',
                            })

                            const students = studentsByEmail.data?.listStudentsByEmail?.items.filter(i => i?._deleted !== true) || []
                            if (students.length > 1) {
                                errors.current.push({ sheet: Sheet.Student, row, message: `multiple accounts matched using email ${email}` })
                                return
                            } else {
                                student = students[0]
                                if (student != undefined) {
                                    if (importStudentHasChanged(schoolID, studentRow, student)) {
                                        if (student.schoolID !== schoolID) {
                                            await addMoveSchoolWarning(student, row, warnings)
                                        }

                                        studentsToUpdate.current.push({
                                            id: student.id,
                                            _version: student._version,
                                            firstName,
                                            lastName,
                                            schoolID,
                                            schoolStudentID: studentID || student.schoolStudentID,
                                            status: StudentStatus.ACTIVE,
                                            yearLevel: yearLevel || student.yearLevel,
                                        })
                                    }
                                }
                            }
                        }

                        if (student == null) {
                            // nickname, studentID, and email all failed to find a match, try to match based on name
                            const matches = schoolStudents.filter(s => s?.firstName == firstName && s?.lastName == lastName)

                            if (matches.length > 1) {
                                errors.current.push({ sheet: Sheet.Student, row, message: `multiple accounts matched using name "${firstName} ${lastName}"` })
                                return
                            } else {
                                student = matches[0]
                                if (student) {
                                    if (importStudentHasChanged(schoolID, studentRow, student)) {
                                        studentsToUpdate.current.push({
                                            id: student.id,
                                            _version: student._version,
                                            email: email || student.email,
                                            firstName,
                                            lastName,
                                            schoolID,
                                            schoolStudentID: studentID || student.schoolStudentID,
                                            status: StudentStatus.ACTIVE,
                                            yearLevel: yearLevel || student.yearLevel,
                                        })
                                    }
                                }
                            }
                        }

                        if (student == null) {
                            console.log('create new student')
                            // nothing matched, create new account
                            const newStudentID = randomUUID()
                            let newUsername = ''
                            if (email && !(await usernameTaken(email))) {
                                newUsername = email
                            } else {
                                newUsername = `${sanitiseNameForCognitoUsername(firstName || '')}.${sanitiseNameForCognitoUsername(lastName || '')}`.toLowerCase()
                                let x = 1
                                while (await usernameTaken(newUsername + x.toString())) x++
                                newUsername = newUsername + x.toString()
                            }
                            console.log({newUsername})

                            const studentRecord = {
                                id: newStudentID,
                                avatar: workOutAvatarName(undefined),
                                cognitoUsername: newUsername,
                                email,
                                firstName,
                                lastName,
                                nickname: newUsername,
                                schoolID,
                                schoolStudentID: studentID,
                                status: StudentStatus.ACTIVE,
                                yearLevel: yearLevel,
                                initialLoginSetup: true,
                            }
                            studentsToCreate.current.push(studentRecord)

                            student = {
                                ...studentRecord,
                                __typename: 'Student',
                                _lastChangedAt: Date.now(),
                                _version: 1,
                            }
                        }

                        for (const className of classes) {
                            const clazz = classGroup.classes[className]

                            if (clazz == null) {
                                errors.current.push({ sheet: Sheet.Student, row, message: `Cannot find class "${className}" in campus "${campusName}" grouping "${classGroupName}"` })
                                return
                            }

                            if (!clazz.students.some(studentID => studentID === student?.id)) {
                                studentClassesToCreate.current.push({
                                    classID: clazz.id,
                                    studentID: student.id,
                                    status: StudentClassApprovalStatus.APPROVED
                                })
                            }
                        }
                    }
                }
            }
        }
    }, 10)

    info.current.push({ sheet: Sheet.Student, message: `${studentsToCreate.current.length} student(s) to be created and ${studentsToUpdate.current.length} to be updated` })
    info.current.push({ sheet: Sheet.Student, message: `${studentClassesToCreate.current.length} student classes(s) to be created` })
}

const addMoveSchoolWarning = async (student: Student, row: number, warnings: MutableRefObject<Message[]>) => {
    const studentSchool = await apolloClient.query<GetSchoolQuery, GetSchoolQueryVariables>({
        query: gql`${adminGetSchoolQuery}`,
        variables: {
            id: student.schoolID
        },
        fetchPolicy: 'network-only',
    })

    warnings.current.push({ sheet: Sheet.Student, row, message: `${student.firstName} ${student.lastName} will be moved from "${studentSchool.data.getSchool?.name}" to the current school` })
}

