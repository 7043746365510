import { useWindowDimensions } from "react-native"
import { ScreenWidthForStaticDrawer } from "./const"

export const useShowDrawer = () => {
    const dimensions = useWindowDimensions()
    const showDrawer = dimensions.width > ScreenWidthForStaticDrawer

    return {
        showDrawer,
        drawerType: (showDrawer ? 'permanent' : 'front') as 'permanent' | 'front',
        headerShown: !showDrawer
    }
}