import { Emotion } from "../../common/API"

export enum IntensityTier {
    Max = 3,
    Med = 2,
    Min = 1
}

export type SuggestionsParam = {
    emotion: Emotion
    emotionIntensityPercentage: number
}

export type CardSuggestionEmotions = SuggestionsParam & {
    emotionIntensityTier: IntensityTier
}