import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'
import { BuildingResilienceLessonPlan } from './src/building-resilience-lesson-plan'
import { Worksheets } from '../../common/worksheets-imports'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'


export const SwitchesLessonPlansEmotionalResilience: CardTemplate = {
    cardId: "05631a68-dd1c-43ca-853e-4444a1796b89",
    title: 'Emotional resilience',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Articulate concept of emotional resilience, develop strategies to cope with challenging situations, and demonstrate resilience through various activities.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students and create a positive and inclusive learning environment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap their previous knowledge of identifying and expressing emotions to establish a foundation for the topic of emotional resilience.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that emotional resilience means being able to bounce back and stay strong during challenging times.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Defining Emotional Resilience (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to share their understanding of the term "emotional resilience" and briefly discuss their thoughts.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide a clear definition of emotional resilience, such as "the ability to adapt, recover, and remain positive in the face of difficulties or setbacks."</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write the definition on the chart paper or whiteboard for reference throughout the lesson. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Discussion: Personal Experiences (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to think of a time when they faced a difficult situation or setback.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to share their experiences and discuss how they felt during that time.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Facilitate a group discussion by asking questions like, "How did you cope with the situation?" and "What helped you feel better or stronger?" </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Strategies for Building Resilience (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Brainstorm a list of strategies for building emotional resilience on the chart paper or whiteboard, involving the students in the process.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Some examples of strategies could include: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Seeking support from family, friends, or trusted adults </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Practicing positive self-talk and affirmations </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Engaging in physical activities or hobbies that bring joy </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Setting small achievable goals </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Taking deep breaths or practicing relaxation techniques </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss each strategy, emphasising how it can contribute to building emotional resilience.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to add any additional strategies they can think of to the list. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Resilience Reminder (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students work individually to complete <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='the resilience builder worksheet' link={Worksheets.buildingResilience.uri} />.</DefaultText>
                        <View style={CommonStyles.lessonPlanImageContainer}>
                            <BuildingResilienceLessonPlan />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them fold the worksheet and keep it in somewhere easily accessible as a reminder in challenging situations.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion and Wrap-up (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points discussed in the lesson about emotional resilience.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to reference their Switches in the app and practice the strategies they have learned in their daily life.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>Extension activities:</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Music and Reflection (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play a calming or uplifting piece of instrumental music.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to sit comfortably, close their eyes, and focus on their breathing while listening to the music.  </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After a few minutes, invite students to reflect silently on the strategies for building emotional resilience they have learned.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Open the floor for voluntary sharing of reflections or insights from the experience. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Art Activity: Resilience Collage (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute art supplies to each student, such as coloured pencils, and paper.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to create a resilience collage by drawing or colouring pictures that represent different strategies for building emotional resilience.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage creativity and personal interpretation of the strategies.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After completing their collages, ask students to share and explain their artwork to the class. </DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard or chart paper</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Art supplies (optional)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Building resilience worksheet' link={Worksheets.buildingResilience.uri} /> </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Resilience in Education & How to Foster Resilient Students -</DefaultText>
                        <DefaultTextWithLink text='https://positivepsychology.com/teaching-resilience/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>Resilience in Autism and Intellectual Disability: a Systematic Review -</DefaultText>
                        <DefaultTextWithLink text='https://link.springer.com/article/10.1007/s40489-021-00239-w' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    lessonPlanSubText: {
        paddingLeft: 80,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    durationText: {
        marginBottom: 10,
        color: DecidaColors.Gray,
        alignSelf: 'center'
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 10
    },
})
