import { View } from 'react-native'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { CardTemplate, CardType } from '../../common/card-template'
import { CardColorScheme } from '../../common/card-color-scheme'
import { FeelingDictionaryIcon } from '../icons/feeling-dictionary-icon'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { Toolbox } from '../common/toolbox'
import { DefaltTextWithDotPoint } from '../../common/default-text-with-dot-point'
import { FeelingDictionaryIconCard } from '../icons/feeling-dictionary-icon-card'

export const ToolsFeelingDictionary: CardTemplate = {
    cardId: "8e8f6647-f46b-44fc-9355-be3a177ce4a9",
    title: 'Feeling dictionary',
    Header: FeelingDictionaryIcon,
    Icon: FeelingDictionaryIconCard,
    HeaderTitle: 'Feeling dictionary',
    PerfectFor: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithoutArrowsItallic textdata={"This dictionary defines feelings in terms of specific thoughts that go along with and cause them. Creating a your own version of a feelings dictionary can help you identify upsetting thoughts you are having, when you feel bad but don’t know why."} />
                <Toolbox textdata={"ANGRY - ANNOYED - FRUSTRATED - FURIOUS"} colorScheme={CardColorScheme.TOOL} />
                <DefaltTextWithDotPoint textdata={"Life should be fair and give me the things I need to be happy and fulfilled"} />
                <DefaltTextWithDotPoint textdata={"I can’t stand it when I don’t get what I want or “deserve”"} />
                <DefaltTextWithDotPoint textdata={"I believe it means that I am a failure if I don’t get what I want or “deserve”"} />
                <DefaltTextWithDotPoint textdata={"I have difficulties accepting a situation that is wrong"} />
                <DefaltTextWithDotPoint textdata={"I believe things must be different than they are"} />
                <DefaltTextWithDotPoint textdata={"If I don’t get my way now, I can’t stand it"} />
                <DefaltTextWithDotPoint textdata={"I can’t stand inconveniences, hassles or frustrations"} />
                <DefaltTextWithDotPoint textdata={"People should act fairly and behave the way I want them to"} />
                <DefaltTextWithDotPoint textdata={"If people disagree with me, criticise my ideas, or don’t do what I want them to, my happiness and self-esteem are threatened"} />
                <DefaltTextWithDotPoint textdata={"I have the right to get really mad if people who frustrate me are no good and should be punished"} />
                <DefaltTextWithDotPoint textdata={"It’s worth my time to do the punishing, and it’s in my best interest to vent my rage or to get revenge!"} />
                <DefaltTextWithDotPoint textdata={"I refuse to consider seeing things from your point of view, because I am right and you are wrong / Other people should see things the way I do and act accordingly."} />
                <Toolbox textdata={"ANXIOUS - STRESSED - WORRIED - FEAR - FREAKING OUT!"} colorScheme={CardColorScheme.TOOL} />
                <DefaltTextWithDotPoint textdata={"I can’t stand being anxious or upset"} />
                <DefaltTextWithDotPoint textdata={"I have the idea that something terrible is going to happen"} />
                <DefaltTextWithDotPoint textdata={"Besides, I can imagine only the worst happening in this situation"} />
                <DefaltTextWithDotPoint textdata={"My life will be ruined by a catastrophe"} />
                <DefaltTextWithDotPoint textdata={"I don’t see a solution, no way to prevent, and no way to cope with this problem"} />
                <DefaltTextWithDotPoint textdata={"I won’t be able to get on with my life and will never find happiness. I will be devastated forever"} />
                <DefaltTextWithDotPoint textdata={"I will not thrive if something bad happens or if things change"} />
                <DefaltTextWithDotPoint textdata={"Most of the time I worry about potential problems, to prevent bad things from happening."} />
                <Toolbox textdata={"BORED"} colorScheme={CardColorScheme.TOOL} />
                <DefaltTextWithDotPoint textdata={"I have the idea that there is nothing to do that is worthwhile of fun"} />
                <DefaltTextWithDotPoint textdata={"I feel like waiting around most of my time, waiting for life, or waiting for something interesting to happen."} />
                <Toolbox textdata={"DISGUST"} colorScheme={CardColorScheme.TOOL} />
                <DefaltTextWithDotPoint textdata={"People should not do the things I view as terrible, bad, shocking, hateful, repulsive, or gross,"} />
                <DefaltTextWithDotPoint textdata={"People who do these things are bad or repulsive."} />
                <DefaltTextWithDotPoint textdata={"When I see someone doing something I find repulsive, I cannot accept it"} />
                <DefaltTextWithDotPoint textdata={"You should not do something I can’t stand or hate"} />
                <DefaltTextWithDotPoint textdata={"This makes you a bad person."} />
                <Toolbox textdata={"ENVIOUS - JEALOUS"} colorScheme={CardColorScheme.TOOL} />
                <DefaltTextWithDotPoint textdata={"Someone else has something that I want and “deserve”"} />
                <DefaltTextWithDotPoint textdata={"If I don’t have this “something” I will feel resentful towards someone who has."} />
                <DefaltTextWithDotPoint textdata={"When someone shows love or attention to someone else that you want for yourself"} />
                <Toolbox textdata={"EXCITED - ENERGETIC - HYPERACTIVE - SUPER PUMPED!"} colorScheme={CardColorScheme.TOOL} />
                <DefaltTextWithDotPoint textdata={"People should should hear what I have to say right now."} />
                <DefaltTextWithDotPoint textdata={"I want to physically move and do something, I can’t stop thinking about what has me so enthusiastic"} />
                <DefaltTextWithDotPoint textdata={"I want to yell out loud, force my excitement onto other people, whether they want it or not"} />
                <Toolbox textdata={"GUILTY"} colorScheme={CardColorScheme.TOOL} />
                <DefaltTextWithDotPoint textdata={"My personal moral code is violated by how I behave"} />
                <DefaltTextWithDotPoint textdata={"I deserve to be punished because I did this on purpose"} />
                <DefaltTextWithDotPoint textdata={"I should get punished, and suffer forever for anything wrong or immoral that I did"} />
                <DefaltTextWithDotPoint textdata={"Making a mistake or hurting someone else, makes me a bad person, no-good person"} />
                <DefaltTextWithDotPoint textdata={"For this reason I am a worthless, no-good person and the world knows it"} />
                <Toolbox textdata={"HAPPY - PLEASED - OPTIMISTIC - ECSTATIC"} colorScheme={CardColorScheme.TOOL} />
                <DefaltTextWithDotPoint textdata={"I am grateful for the people around me, and the opportunities that I have"} />
                <DefaltTextWithDotPoint textdata={"I am good. I like being me and I like who I am to other people"} />
                <DefaltTextWithDotPoint textdata={"I am content with the things that I have and the life that I am living"} />
                <Toolbox textdata={"LONELY"} colorScheme={CardColorScheme.TOOL} />
                <DefaltTextWithDotPoint textdata={"I am an inadequate, unlovable, person since I’m alone"} />
                <DefaltTextWithDotPoint textdata={"Love and support of others is something I want and must have"} />
                <DefaltTextWithDotPoint textdata={"I can’t live without, and I am less of a person if I don’t have love, support, and friendship"} />
                <DefaltTextWithDotPoint textdata={"Being alone is something I can’t stand"} />
                <DefaltTextWithDotPoint textdata={"The only thing I can do is wait for the right person (or persons) to come along to take away my loneliness, and to make me feel whole again."} />
                <Toolbox textdata={"SAD - UPSET - DEPRESSED - MISERABLE - HOPELESS"} colorScheme={CardColorScheme.TOOL} />
                <DefaltTextWithDotPoint textdata={"In my opinion the future is hopeless, and there is nothing I can do to make it better"} />
                <DefaltTextWithDotPoint textdata={"Something important to me is lost forever"} />
                <DefaltTextWithDotPoint textdata={"I will never find happiness because life is a burden"} />
                <DefaltTextWithDotPoint textdata={"I don’t see any prospect for a joyous, content, or fulfilled life"} />
                <DefaltTextWithDotPoint textdata={"I don’t feel like there is a place for me in this world"} />
                <DefaltTextWithDotPoint textdata={"As a person I’m inadequate, bad and weak"} />
                <DefaltTextWithDotPoint textdata={"I am a loser and a failure, because I made mistakes before"} />
                <DefaltTextWithDotPoint textdata={"I can’t handle any of my responsibilities because I am too confused an overwhelmed"} />
                <DefaltTextWithDotPoint textdata={"I can’t stand it when I get frustrated"} />
                <DefaltTextWithDotPoint textdata={"For not handling my frustration better, I think I am loser"} />
                <DefaltTextWithDotPoint textdata={"I should be more successful and happy"} />
                <DefaltTextWithDotPoint textdata={"I can only accept myself if I am loved by everybody and successful in their eyes"} />
                <DefaltTextWithDotPoint textdata={"If I fail at a task or if you disapprove of me, I am worthless."} />
                <Toolbox textdata={"SHAME"} colorScheme={CardColorScheme.TOOL} />
                <DefaltTextWithDotPoint textdata={"If other people would find out, they will think that I am a worthless person and they will be right."} />
                <DefaltTextWithDotPoint textdata={"I keep my mistakes a secret so no one ever knows about them."} />
            </View>
        </>
    ),
    BottomRow: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={""} />
            <DefaultTextWithoutArrowsItallic textdata={""} />
        </>

    ),
    CardContent: () => (
        <>

        </>
    ),
    colorScheme: CardColorScheme.TOOL,
    whyDoesItWorkText: '',
    type: [CardType.Tool],
    time: [],
    people: [],
    place: [],
    category: [],
    action: [],
    level: []
}

