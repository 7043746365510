import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { Ionicons } from '@expo/vector-icons'
import { useRef, useState } from 'react'
import { Platform, StyleSheet, TextInput, View } from 'react-native'
import { School, UpdateSchoolForBillingInfoMutation, UpdateSchoolForBillingInfoMutationVariables } from '../../common/API'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultButton, DefaultButtonTypes } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import InputDefault from '../common/input-default'
import { rvIsLoading } from '../common/loading'
import ToastAlert, { ToastAlertType } from '../common/toast-alert'
import { updateSchoolForBillingInfo } from '../../common/graphql/mutations'
import { rvCurrentUser } from '../login/login-state'
import SchoolAdminButtonCancel from './school-admin-button-cancel'

type Props = {
    schoolData: School
    toggleEditMode: () => void
}

const SchoolAdminnFormSchool = ({
    schoolData,
    toggleEditMode,
}: Props) => {
    const user = useReactiveVar(rvCurrentUser)

    const [name, setName] = useState(schoolData?.name || "")
    const [billingAddress, setBillingAddress] = useState(schoolData?.billingAddress || "")
    const [abn, setAbn] = useState(schoolData?.abn || "")

    const [formError, setFormError] = useState(false)

    const [toast, setToast] = useState({
        status: false,
        message: '',
        type: ToastAlertType.success
    })
    const nameInputRef = useRef<TextInput>(null)
    const billingAddressInputRef = useRef<TextInput>(null)
    const abnInnputRef = useRef<TextInput>(null)

    const [updateSchooAdminBillingInfoMutation] = useMutation<UpdateSchoolForBillingInfoMutation, UpdateSchoolForBillingInfoMutationVariables>(gql`${updateSchoolForBillingInfo}`)


    const resetForm = () => {
        setName("")
        setBillingAddress("")
        setAbn("")
    }

    const resetToast = () => {
        setToast({ status: false, message: "", type: ToastAlertType.success })
    }

    const handleSubmit = async () => {

        if (!name) {
            setToast({ status: true, message: "Please input school name", type: ToastAlertType.error })
            return
        }

        if (!isFormCompleted()) {
            setToast({ status: true, message: "Please fill all required form", type: ToastAlertType.error })
            return
        }

        try {
            rvIsLoading(true)
            if (schoolData) {
                await updateSchoolInfo()
            }
            rvIsLoading(false)
            toggleEditMode()
        } catch (error) {
            rvIsLoading(false)
            console.log({ error })
            setToast({ status: true, message: "Something went wrong, submit failed", type: ToastAlertType.error })
        }
    }

    const updateSchoolInfo = async () => {
        if (!schoolData) {
            return
        }
        await updateSchooAdminBillingInfoMutation({
            variables: {
                input: {
                    id: schoolData?.id || "",
                    name,
                    billingAddress,
                    abn,
                    editorUsername: user?.username,
                    editorEmail: user?.attributes.email
                }
            }
        })
    }

    const isFormCompleted = () => {
        return name &&
            !formError &&
            (name !== schoolData?.name || billingAddress !== schoolData?.billingAddress || abn !== schoolData?.abn)
    }

    const handleEmail = (text: string) => {
        setFormError(false)
    }


    return (
        <View style={styles.mainContainer}>
            {/* <View style={[styles.container, style]}> */}

            <View style={styles.formContainer}>
                <InputDefault ref={nameInputRef} value={name} onChangeText={setName} label="School name" placeholder='School name' />
                <InputDefault ref={nameInputRef} value={billingAddress} onChangeText={setBillingAddress} label="Billing address" placeholder='Billing address' />
                <InputDefault ref={nameInputRef} value={abn} onChangeText={setAbn} label="ABN" placeholder='ABN' />
            </View>
            <View style={styles.buttonContainer}>
                <SchoolAdminButtonCancel onPress={toggleEditMode} />
                <DefaultButton type={DefaultButtonTypes.Small} disabled={!isFormCompleted()} style={Platform.OS === 'web' ? styles.confirmButton : styles.confirmButtonIos} onPress={handleSubmit}>
                    <Ionicons name="ios-checkmark-sharp" size={24} color={DecidaColors.White} />
                    <DefaultText style={styles.confirmText}>
                        Confirm
                    </DefaultText>
                </DefaultButton>
            </View>
            <View style={{ maxWidth: '100%', position: 'absolute', bottom: -30 }}>
                {toast.status && (
                    <ToastAlert visible={toast.status} setVisible={(status: boolean) => setToast({ status, message: "", type: toast.type })} message={toast.message} type={toast.type} />
                )}
            </View>
        </View>
    )
}

export default SchoolAdminnFormSchool

const styles = StyleSheet.create({
    mainContainer: {
        zIndex: 99,
        marginBottom: 20,
        marginTop: 10
    },
    container: {
        flex: 1,
        maxWidth: '55%',
        backgroundColor: DecidaColors.AppleSystemGray6SuperLight,
        padding: 20,
        marginVertical: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 20,
        borderWidth: 3,
        borderColor: DecidaColors.Red
    },

    formContainer: {
        flex: 1,
        paddingHorizontal: 5,
    },
    confirmText: {
        fontSize: 12,
        fontWeight: '700',
        color: DecidaColors.White,
    },
    confirmButton: {
        minWidth: 40,
        alignSelf: 'flex-end',
        zIndex: -1
    },
    confirmButtonIos: {
        width: 60,
        alignSelf: 'flex-end',
        zIndex: -1
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
})