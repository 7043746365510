import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { DrawerHeaderProps, DrawerNavigationProp } from '@react-navigation/drawer/lib/typescript/src/types'
import { useNavigation } from '@react-navigation/native'
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import { GetSchoolQuery, GetSchoolQueryVariables } from '../../common/API'
import { CheckInUserGroup } from '../../common/constants'
import { DecidaColors } from '../../common/decida-colors'
import { getSchool } from '../../common/graphql/queries'
import { DefaultText } from '../common/default-text'
import { Header } from '../common/header'
import HeaderBottomRow from '../common/header-bottom-row'
import HeaderWellbeingButton from '../common/header-wellbeing-button'
import LeftHeaderLogo from '../common/left-header-logo'
import { rvShowPopoverNonTeachingStaff } from '../common/pop-over-menu-state'
import { ScreenNames } from '../common/screen-names'
import { showOfflineAlert } from '../common/universal-alert'
import useConnectivity from '../common/use-connectivity'
import { useResponsive } from '../common/use-responsive'
import { IconDownArrow } from '../icon/icon-down-arrow'
import { IconHeaderDivider } from '../icon/icon-header-divider'
import { IconHeaderUserLoginAvatar } from '../icon/icon-header-user-login-avatar'
import { IconHome } from '../icon/icon-home'
import { useNonTeachingStaffState } from '../student/use-non-teaching-staff-state'
import { rvSwitchesScreenState } from '../switches/common/current-screen-state'
import { SwitchesScreens } from '../switches/navigation/switches-bottom-navigation'
import { NonTeachingStaffNavigationParams } from './non-teaching-staff-navigator-params-list'

export const NonTeachingStaffHeader = (props: DrawerHeaderProps) => {
    const { navigate } = useNavigation<DrawerNavigationProp<NonTeachingStaffNavigationParams>>()
    const showPopover = useReactiveVar(rvShowPopoverNonTeachingStaff)
    const { currentNonTeachingStaff } = useNonTeachingStaffState()
    const { onlineRef } = useConnectivity()
    const { width } = useResponsive()

    const { data } = useQuery<GetSchoolQuery, GetSchoolQueryVariables>(gql`${getSchool}`, { variables: { id: currentNonTeachingStaff?.schoolID || "" }, skip: !currentNonTeachingStaff })
    const currentSchool = data?.getSchool

    const navigateToHome = () => {
        rvSwitchesScreenState(SwitchesScreens.HOME)
        navigate(ScreenNames.NonTeachingStaffHome)
    }

    const togglePopoverMenuVisibility = () => {
        rvShowPopoverNonTeachingStaff(!showPopover)
    }

    const navigateToTirednessScale = () => {
        navigate(ScreenNames.NonTeachingStaffCheckInMain, { screen: ScreenNames.CheckInTirednessScale, params: { user: CheckInUserGroup.NonTeachingStaff } })
    }

    const onPressCheckIn = async () => {
        try {
            if (!onlineRef.current) throw new Error("You're offline")
            navigateToTirednessScale()
        } catch (error) {
            showOfflineAlert(onPressCheckIn)
        }
    }

    const navigateToWellbeingPage = () => {
        navigate(ScreenNames.NonTeachingStaffWellbeing)
    }

    return (
        <>
            <Header
                {...props}
                disableDrawer
                leftChildren={
                    width > 480 && <LeftHeaderLogo onPress={navigateToHome} />
                }
                centerChildren={
                    <View />
                }
                rightChildren={

                    <>

                        <TouchableOpacity onPress={navigateToHome} testID='header-profile-home'>
                            <View style={styles.icon}>
                                <IconHome color={DecidaColors.Green} />
                                <DefaultText style={styles.iconText}>Home</DefaultText>
                            </View>
                        </TouchableOpacity>
                        {currentSchool?.staffWellbeing &&
                            <HeaderWellbeingButton onPress={navigateToWellbeingPage} />
                        }
                        <View>
                            <IconHeaderDivider strokecolor={DecidaColors.Gray} />
                        </View>
                        <TouchableOpacity testID='header-profile-menu' onPress={togglePopoverMenuVisibility}>
                            <View style={styles.profileContainer}>
                                <View style={[styles.icon, styles.profileIcon]}>
                                    <IconHeaderUserLoginAvatar color={DecidaColors.Green} fill={DecidaColors.White} />
                                    <DefaultText style={styles.iconText}>Profile</DefaultText>
                                </View>
                                <IconDownArrow stylefill={DecidaColors.Gray} />
                            </View>
                        </TouchableOpacity>
                    </>
                }
                headerStyle={{ backgroundColor: DecidaColors.White }}
            />
            <HeaderBottomRow />
        </>
    )
}

const styles = StyleSheet.create({
    icon: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
    } as ViewStyle,
    iconText: {
        marginTop: 5,
        fontSize: 15,
        color: DecidaColors.Gray,
    },
    profileIcon: {
        marginRight: 0,
    },
    profileContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    }
})