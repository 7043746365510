import { ScrollView, StyleSheet, View } from 'react-native'
import { DefaultFontSize } from '../common/const'
import DefaultModal from '../common/default-modal'
import { DefaultText } from '../common/default-text'
import { useResponsive } from '../common/use-responsive'
import ViewZoomableForIphone from '../common/view-zoomable'
import { EmotionIntensityScaleHelpImage } from './assets/emotion-intensity-scale-help-image'
import { rvTeacherEmotionIntensityScaleModal } from './teacher-state'

const TeacherEmotionIntensityScaleModal = () => {
    const toggleModal = () => rvTeacherEmotionIntensityScaleModal(false)
    const { responsiveFontSize, isNarrowScreen } = useResponsive()
    return (
        <DefaultModal onClose={toggleModal} contentStyle={{ ...styles.contentContainer, padding: isNarrowScreen ? 30 : 60 }}>
            <ScrollView>
                <DefaultText style={[styles.headerText, { fontSize: responsiveFontSize(DefaultFontSize + 2) }]}>
                    Emotion Intensity Scale
                </DefaultText>
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                    During the check in students will be asked to identify the primary emotion they are feeling, and as their maturity develops you can choose to add a follow up question that asks what level of intensity of that emotion they are feeling.
                </DefaultText>
                <View style={styles.spacing} />
                <DefaultText style={[styles.text, { fontSize: responsiveFontSize() }]}>
                    NOTE: Choosing the emotion intensity scale level will also update the default settings for the emotion wheel and matched switch activities.  Regardless of what this setting is you will still have full access to all resources by toggling the levels or searching through the explore all function.
                </DefaultText>
                <View style={styles.spacing} />
                <ViewZoomableForIphone style={{ width: '100%', height: isNarrowScreen ? 300 : 600 }}>
                    <EmotionIntensityScaleHelpImage />
                </ViewZoomableForIphone>
            </ScrollView>
        </DefaultModal>
    )
}
export default TeacherEmotionIntensityScaleModal
const styles = StyleSheet.create({
    contentContainer: {
        padding: 60,
        maxHeight: 800,
    },
    text: {
        textAlign: 'justify',
    },
    headerText: {
        textAlign: 'center',
        fontSize: DefaultFontSize + 2,
        fontWeight: 'bold',
        marginBottom: 20
    },
    spacing: {
        marginVertical: 5,
    },
})  
