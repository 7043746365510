import { TouchableOpacity, View, StyleSheet } from "react-native";
import { DefaultText } from "./default-text";
import { SendEmail } from "./send-email";
import { DefaultFontSize } from "./const";
import { linkingOpenUrl } from "./helper-functions";
import { DecidaColors } from "../../common/decida-colors";

export const EmailSupportPopoverButton = () => {
    return (
        <View style={styles.optionsContainer}>
            <TouchableOpacity onPress={SendEmail} style={styles.dividerButton}>
                <View style={styles.profileContainer}>
                    <DefaultText style={styles.text}>
                        Email: Support
                    </DefaultText>
                </View>
            </TouchableOpacity>
        </View>
    )
}

export const TroubleshootingPopoverButton = () => {
    return (
        <View style={styles.optionsContainer}>
            <TouchableOpacity onPress={() => linkingOpenUrl("https://www.switch4schools.com.au/troubleshooting")} style={styles.dividerButton}>
                <View style={styles.profileContainer}>
                    <DefaultText style={styles.text}>
                        Troubleshooting
                    </DefaultText>
                </View>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    dividerButton: {
        width: "100%",
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    profileContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        marginRight: 15,
        color: DecidaColors.Gray,
        alignItems: 'flex-end',
        justifyContent: 'center',
        fontSize: DefaultFontSize - 2,
    },
    optionsContainer: {
        position: 'relative',
        height: 50,
        width: "100%",
        borderColor: 'grey',
        borderTopWidth: 0.5,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
})