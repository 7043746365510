import { View, StyleSheet } from 'react-native'
import { DefaultText } from './default-text'
import { Class } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'

type Props = {
    clazz: Class
}

export const ActiveClassesStudentCount = ({ clazz }: Props) => (
    <View>
        <DefaultText style={styles.totalStudentText}>Active students: {clazz.activeStudents}</DefaultText>
    </View>
)

const styles = StyleSheet.create({
    totalStudentText: {
        color: DecidaColors.Green
    }
})