import { View } from 'react-native'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { RockPaperScissorsIcon } from '../../icons/rock-paper-scissors-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { RockPaperScissorsIconInverted } from '../../icons/rock-paper-scissors-icon-inverted'
import { DefaulttextWithRightArrow } from '../../../common/default-text-with-right-arrow'
import { RockPaperScissorsImage1 } from '../../icons/rock-paper-scissors-image1'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'

export const SwitchesActivitiesRockPaperScissors: CardTemplate = {
    cardId: "da79cdae-e4a7-43db-a4a3-5bfe5ac384de",
    title: 'Rock, paper, scissors',
    Header: RockPaperScissorsIconInverted,
    Icon: RockPaperScissorsIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Injecting energy into a room where people might be a bit fatigued. This short game creates different winners each time, allowing different people to feel like the champion."} />
            <DefaulttextWithRightArrow textdata={"Have everyone in the group find a partner"} />
            <DefaulttextWithRightArrow textdata={"Each pair plays “Rock, Paper, Scissors” 3 times with the best of three the winner."} />
            <View style={commonCardStyles.imagecontainer}>
                <View style={{ width: 350, height: 350, }}>
                    <RockPaperScissorsImage1 />
                </View>
            </View>
            <DefaultTextWithoutArrowsItallic textdata={"The basic rules of “Rock, Paper, Scissors” are the following: "} />
            <DefaultTextWithoutArrowsItallic textdata={"The players count to 3. On 3 they form one out of 3 symbols with their hands: "} />
            <DefaultTextWithoutArrowsItallic textdata={"1) “Rock” – the fist"} />
            <DefaultTextWithoutArrowsItallic textdata={"2) “Paper” – the straight hand"} />
            <DefaultTextWithoutArrowsItallic textdata={"3) “Scissors” – index and middle-finger pointing out"} />
            <DefaultTextWithoutArrowsItallic textdata={"Each symbol wins and loses against one other symbol:"} />
            <DefaultTextWithoutArrowsItallic textdata={"“Rock” brakes the “Scissors”."} />
            <DefaultTextWithoutArrowsItallic textdata={"“Scissors” cuts the “Paper”."} />
            <DefaultTextWithoutArrowsItallic textdata={"“Paper” wraps the “Rock”"} />
            <DefaulttextWithRightArrow textdata={"The winner finds another winner as a new opponent. The losing player becomes a fan of the winner. "} />
            <DefaulttextWithRightArrow textdata={"The winner plays against the new opponent, while their fan cheers for them. "} />
            <DefaulttextWithRightArrow textdata={"The winner of the second game searches for a new opponent, while the losing team joins their fan base. "} />
            <DefaulttextWithRightArrow textdata={"Repeat until there are only two opponents with their fan base cheering for them. The last two have to play until one player has won 2 times. "} />






        </>
    ),
    BottomRow: () => (
        <>
          
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.ENERGIZERS,
    whyDoesItWorkText: '',
    onlineTips: 'This can be played online too! In your VC program have students go to the video settings menu and select “hide non-video participants”. Then as people are eliminated they turn off their video so only the players remain. In the Zoom version people pick randomly who they are playing against (honour system) and people cheer in the chat.',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Rock+Paper+Scissors.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Short, CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.ENERGIZERS],
    action: [CardActionType.Action],
    level: []
}

