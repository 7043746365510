import { makeVar } from '@apollo/client'
import { MaterialIcons } from '@expo/vector-icons'
import { Picker } from '@react-native-picker/picker'
import { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'

type StudentFilterOptionType = "Sort" | "First name" | "Last name" | "Nickname"
type FilterKeys = ("firstName" | "lastName" | "nickname")[]

export const rvStudentsFilterKeys = makeVar<FilterKeys>(['firstName', 'lastName'])

const studentFilterOptions: StudentFilterOptionType[] = ["First name", "Last name", "Nickname"]

const FILTER_HEIGHT = 30


const DropdownSortStudent = () => {
    const [selectedFilter, setSelectedFilter] = useState<StudentFilterOptionType>("Sort")
   
    useEffect(() => {
        onFilterChange()
    }, [selectedFilter])

    const onFilterChange = () => {
        let value: FilterKeys = []
        switch (selectedFilter) {
            case "First name":
                value = ['firstName', 'lastName']
                break;
            case "Last name":
                value = ['lastName', 'firstName']
                break;
            case "Nickname":
                value = ['nickname']
                break;
        
            default:
                value = ['firstName', 'lastName']
                break;
        }
        rvStudentsFilterKeys(value)
    }
    return (
        <View style={styles.toggleContainer}>
            <MaterialIcons name="sort" size={FILTER_HEIGHT} color={DecidaColors.Gray} style={styles.sortIcon} />
            <Picker
                selectedValue={selectedFilter}
                onValueChange={setSelectedFilter}
                style={styles.picker}
            >
                {studentFilterOptions.map((option, index) => {
                    return <Picker.Item key={`${option}-${index}`} label={option} value={option} />
                })}
            </Picker>
        </View>
    )
}
export default DropdownSortStudent

const styles = StyleSheet.create({
    toggleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-end',
    },
    sortIcon: {
        marginRight: 5
    },
    picker: {
        maxWidth: 100,
        borderRadius: 10,
        height: FILTER_HEIGHT
    }
})