import Svg, {
    Rect,
} from "react-native-svg"
import { DecidaColors } from "../../common/decida-colors"

export const IconEmotionsLegendEmotionBlocks = () => {
    return (
        <Svg
            width={6.14}
            height={35.61}
            viewBox="0 0 6.14 35.61"
        >
            <Rect fill={DecidaColors.Black} y="14.67" width="6.14" height="6.14" />
            <Rect fill={DecidaColors.Black} y="22.07" width="6.14" height="6.14" />
            <Rect fill={DecidaColors.Black} y="29.47" width="6.14" height="6.14" />
            <Rect fill={DecidaColors.Black} width="6.14" height="6.14" />
            <Rect fill={DecidaColors.Black} y="7.4" width="6.14" height="6.14" />
        </Svg>
    )
}

