import { View } from 'react-native'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { SafeThoughtsIcon } from '../../explore/src/safe-thoughts-icon'
import { SafeThoughtsIconInverted } from '../../explore/src/safe-thoughts-icon-inverted'
import { BucketsForSafeThoughtsNewImage1 } from './src/buckets-for-safe-thoughts-new-image1'

export const SwitchesEmotionsAnxiousBucketOfSafeThoughts: CardTemplate = {
    cardId: "1404ede9-6c61-4dd7-987c-a2ee2a7632f1",
    title: 'Safe thoughts',
    Header: SafeThoughtsIconInverted,
    Icon: SafeThoughtsIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <BucketsForSafeThoughtsNewImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Stopping ruminations that are making it hard to fall asleep at night"} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithoutArrows textdata={"Pick a question that you like from the following list."} />
                <DefaulttextWithRightArrow textdata={"If I won a million dollars, what and who would I spend it on?"} />
                <DefaulttextWithRightArrow textdata={"What is your favourite superpower? What would your adventures be?"} />
                <DefaulttextWithRightArrow textdata={"What does your ultimate holiday look like?"} />
                <DefaulttextWithRightArrow textdata={"What would you like to be famous for, and what would you do in an average day?"} />
                <DefaultTextWithDownArrow textdata={""} />
                <DefaultTextWithoutArrows textdata={"Close your eyes and commit to only thinking about these thoughts. Allow yourself to enjoy them."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANXIOUS,
    whyDoesItWorkText: 'Anxiety is the brain’s safety mechanism kicking into place, indicating imminent danger or threat, keeping you prepared to defend yourself at any moment. Safe thoughts helps your brain to rest peacefully because it lowers activation and reduces energy spent on vigilance. Once your brain is at peace you will have more brain capacity and think more clearly. Basically, safe thoughts makes you smarter!',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Build creative thinking skills.
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice the stress relieving act of creative thinking.
                        </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 40 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the concept of creative thinking as a tool for intentionally redirecting thoughts from stress or anxiety.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Create a quiet space without distractions to set the tone for the exercise.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present thought starters on the board or through the app to stimulate creative thinking (e.g., winning a million dollars, favorite superpower, ultimate holiday).</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to brainstorm additional questions they would like to think creatively about.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the Safe Thoughts process: </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Choose a safe thought to focus on.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Sit comfortably and close your eyes. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Take a couple of deep breaths to intentionally relax. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Let your mind wander and explore your safe thought.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- Continue until instructed that the time is up.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Meditation Session (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Provide a quiet space and dim the lighting (if possible).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to complete the Safe Thoughts meditation, allowing their minds to explore the chosen safe thought.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ After a couple of minutes, invite students to open their eyes, giving them a moment to readjust before turning the lights back up.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Debrief (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief with students to discuss how the exercise made them feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explore the benefits of the Safe Thoughts technique for quieting the mind, especially before sleep or when feeling overwhelmed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to share their experiences and thoughts on using creative thinking as a tool for managing stress.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the value of creative thinking as a means of redirecting thoughts and promoting calm.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to use the Safe Thoughts switch in the Switch4Schools app whenever needed.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Quiet space, ideally with dim lighting </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Positive and Negative Effects of Daydreaming - </DefaultText>
                        <DefaultTextWithLink text='https://www.everydayhealth.com/emotional-health/positive-negative-effects-daydreaming/' />
                        <DefaultText style={CommonStyles.lessonPlanText}>What is daydreaming? New research suggests parts of your brain might actually be asleep - </DefaultText>
                        <DefaultTextWithLink text='https://www.weforum.org/agenda/2021/07/what-is-daydreaming-parts-of-the-brain-show-sleep-like-activity-when-your-mind-wanders/' />

                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"Be patient. Don’t be in a hurry to change your thoughts and feelings. No thought or feeling stays forever. Be patient and know that whatever you are feeling, or whatever you are thinking, it will pass."} />
            <DefaulTextWithTipsIcon textdata={"Strategies like this get more effective with practice. Creativity is a great way to stop rumination. Share your safe thoughts with friends and create some new themes together to further stimulate your creativity and create new stories. "} />
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Safe+Thoughts.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Anxious]
}

