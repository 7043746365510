import Svg, { G, Path, Circle } from 'react-native-svg'

interface Props {
    stylefill?: string,
}

export const IconClassAdmin = ({ stylefill = "#fff" }: Props) =>
    <Svg width="25.069" height="25.107" viewBox="0 0 25.069 25.107">
        <G transform="translate(-6505.703 -1760.409)">
            <Path d="M6537.952,1763.653a2.627,2.627,0,1,1-2.626,2.627A2.629,2.629,0,0,1,6537.952,1763.653Zm-5.562,3.824.849.155a5.17,5.17,0,0,0,.387.966l-.5.7a.41.41,0,0,0,.039.5l1.2,1.2a.361.361,0,0,0,.5.039l.735-.5a4.74,4.74,0,0,0,.927.387l.154.849a.376.376,0,0,0,.386.309h1.7a.375.375,0,0,0,.386-.309l.155-.849a3.979,3.979,0,0,0,.927-.387l.734.5a.408.408,0,0,0,.5-.039l1.2-1.2a.362.362,0,0,0,.039-.5l-.5-.733a4.687,4.687,0,0,0,.386-.927l.849-.155a.376.376,0,0,0,.309-.386v-1.7a.376.376,0,0,0-.309-.386l-.849-.155a4.175,4.175,0,0,0-.386-.966l.5-.7a.411.411,0,0,0-.039-.5l-1.2-1.2a.406.406,0,0,0-.5-.039l-.734.5a4.762,4.762,0,0,0-.927-.387l-.155-.849a.375.375,0,0,0-.386-.309h-1.7a.376.376,0,0,0-.386.309l-.154.849a4.266,4.266,0,0,0-.966.387l-.7-.5a.357.357,0,0,0-.425.039l-1.2,1.2a.406.406,0,0,0-.039.5l.5.734a4.8,4.8,0,0,0-.387.927l-.849.155a.376.376,0,0,0-.309.386v1.7a.3.3,0,0,0,.232.386Z" transform="translate(-19.695 0)" fill={stylefill} />
            <Path d="M6516.4,1801.047a2.82,2.82,0,1,0-2.82-2.82A2.836,2.836,0,0,0,6516.4,1801.047Z" transform="translate(-5.868 -26.076)" fill={stylefill} />
            <Path d="M6515.321,1824.784a4.809,4.809,0,1,0-9.618,0,.386.386,0,0,0,.386.386h8.845a.365.365,0,0,0,.386-.386Z" transform="translate(0 -44.367)" fill={stylefill} />
            <Path d="M6574.188,1798.226a2.82,2.82,0,1,0,2.819-2.82A2.835,2.835,0,0,0,6574.188,1798.226Z" transform="translate(-51.026 -26.075)" fill={stylefill} />
            <Path d="M6540.835,1838.59a4.822,4.822,0,0,0-4.828,4.79.365.365,0,0,0,.386.386h8.845a.388.388,0,0,0,.387-.386,4.742,4.742,0,0,0-4.791-4.79Z" transform="translate(-22.579 -58.25)" fill={stylefill} />
            <Path d="M6571.141,1820.1a4.83,4.83,0,0,0-4.828,4.828.387.387,0,0,0,.386.386h8.845a.387.387,0,0,0,.387-.386,4.774,4.774,0,0,0-4.791-4.828Z" transform="translate(-45.158 -44.478)" fill={stylefill} />
            <Circle cx="2.82" cy="2.82" r="2.82" transform="translate(6515.437 1774.005)" fill={stylefill} />
            <Circle cx="0.386" cy="0.386" r="0.386" transform="translate(6524.243 1762.147)" fill={stylefill} />
            <Path d="M6587.481,1769.666a.546.546,0,0,0,.231-.078.352.352,0,0,0,.155-.309.557.557,0,0,0-.077-.232.354.354,0,0,0-.309-.154.55.55,0,0,0-.232.078.416.416,0,0,0-.078.54.483.483,0,0,0,.31.155Z" transform="translate(-60.65 -6.321)" fill={stylefill} />
            <Circle cx="0.386" cy="0.386" r="0.386" transform="translate(6526.639 1764.85)" fill={stylefill} />
            <Circle cx="0.386" cy="0.386" r="0.386" transform="translate(6526.639 1766.01)" fill={stylefill} />
            <Circle cx="0.386" cy="0.386" r="0.386" transform="translate(6525.402 1762.147)" fill={stylefill} />
            <Path d="M6588.324,1791.468a.37.37,0,0,0-.348.271,2.311,2.311,0,0,1,.734.232v-.115a.416.416,0,0,0-.386-.387Z" transform="translate(-61.3 -23.141)" fill={stylefill} />
            <Circle cx="0.386" cy="0.386" r="0.386" transform="translate(6526.639 1763.692)" fill={stylefill} />
            <Circle cx="0.386" cy="0.386" r="0.386" transform="translate(6526.639 1767.169)" fill={stylefill} />
            <Path d="M6519.808,1787.312a.386.386,0,0,1-.773,0,.386.386,0,0,1,.773,0" transform="translate(-9.933 -19.757)" fill={stylefill} />
            <Path d="M6519.808,1782.765a.386.386,0,0,1-.773,0,.386.386,0,0,1,.773,0" transform="translate(-9.933 -16.369)" fill={stylefill} />
            <Path d="M6519.921,1791.739a.371.371,0,0,0-.347-.271.364.364,0,0,0-.386.386v.115a2.343,2.343,0,0,1,.733-.231Z" transform="translate(-10.047 -23.141)" fill={stylefill} />
            <Path d="M6519.808,1778.218a.386.386,0,1,1-.773,0,.386.386,0,0,1,.773,0" transform="translate(-9.933 -12.981)" fill={stylefill} />
            <Circle cx="0.386" cy="0.386" r="0.386" transform="translate(6510.338 1762.147)" fill={stylefill} />
            <Circle cx="0.386" cy="0.386" r="0.386" transform="translate(6511.497 1762.147)" fill={stylefill} />
            <Path d="M6520.208,1769.666a.352.352,0,0,0,.309-.155.545.545,0,0,0,.078-.232.35.35,0,0,0-.155-.309.544.544,0,0,0-.232-.078.478.478,0,0,0-.309.154.408.408,0,0,0,.077.541.553.553,0,0,0,.232.078Z" transform="translate(-10.526 -6.321)" fill={stylefill} />
            <Path d="M6519.808,1773.675a.386.386,0,0,1-.773,0,.386.386,0,0,1,.773,0" transform="translate(-9.933 -9.596)" fill={stylefill} />
        </G>
    </Svg>
