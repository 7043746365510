import { makeVar } from '@apollo/client'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { UserGroup } from '../../common/constants'

export const rvLoggedIn = makeVar(false)
export const rvUserGroup = makeVar<UserGroup | undefined>(undefined)
export const rvCurrentUser = makeVar<AuthType | undefined>(undefined)
export const rvBulkSignUpUrl = makeVar<string | null>(null)
export const rvParentPortal = makeVar<boolean>(false)
export const rvChangePasswordUsername = makeVar('')
export const rvAuthChallengeName = makeVar<"SOFTWARE_TOKEN_MFA" | "SMS_MFA" | null | undefined>(null)
export const rvRedirectPages = makeVar("")
export const rvLoginErrorMesage = makeVar("")

const lockDeviceKey = 'lockDevice'
const lockDeviceClassID = 'lockDeviceClassID'
const rvLockDevice = makeVar<boolean | null>(null)

    ; (async () => {
        rvLockDevice((await AsyncStorage.getItem(lockDeviceKey))?.toLowerCase() === 'true')
    })()

const rvLockDeviceClassID = makeVar<string | null>(null); // saving classID, for allowed user to get back into the last checkin page of a class which used for locked the device
; (async () => {
    rvLockDeviceClassID((await AsyncStorage.getItem(lockDeviceClassID)))
})()


export const setLockDevice = (value: boolean, classID: string) => {
    rvLockDevice(value)
    rvLockDeviceClassID(classID)
    AsyncStorage.setItem(lockDeviceKey, value.toString())
    AsyncStorage.setItem(lockDeviceClassID, classID)
}

/**
 * DO NOT UPDATE THE VALUE USING THIS. Use setLockDevice instead.
 */
export const getLockDerviceRv = () => rvLockDevice
export const getLockDeviceRvClassID = () => rvLockDeviceClassID

export type AuthType = {
    signInUserSession: {
        idToken: {
            payload: {
                'cognito:groups': string[]
            }
        }
    },
    username: string
    attributes: {
        email: string
    },
    challengeName?: string,
    challengeParam?: {
        userAttributes?: {
            email: string,
            email_verified: string,
            preferred_username: string
        }
    }
}
