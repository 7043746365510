import { View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { SecretIdentityIcon } from '../../icons/secret-identity-icon'
import { SecretIdentityIconInverted } from '../../icons/secret-identity-icon-inverted'


export const SwitchesActivitiesEnergizersSecretIdentity: CardTemplate = {
    cardId: "89b6f995-5849-491b-ae36-15573dad5275",
    title: 'Secret identity',
    Header: SecretIdentityIconInverted,
    Icon: SecretIdentityIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"A pencil and post-it note for each participant  "} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Everyone secretly writes down on their post-it note a famous person that all other participants would know."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Each person sticks the post-it note on the back of the person to their left so everyone has a mysterious name of their back"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"All participants will then go around the room asking the others questions about their person so that eventually they will be able to guess who it is"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"The first person to guess their celebrity’s name correctly wins the game"} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Rules:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Each question may only be yes/no questions "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Each person can only ask two questions at a time then they have to move to another person "} />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.ENERGIZERS,
    whyDoesItWorkText: '',
    type: [CardType.Activity],
    time: [CardTimeType.Short, CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.ENERGIZERS],
    action: [CardActionType.Action],
    level: []
}

