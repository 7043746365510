import { gql, useMutation, useQuery, useReactiveVar, useSubscription } from '@apollo/client';
import { AntDesign } from '@expo/vector-icons';
import { useEffect, useState } from 'react';
import { ActivityIndicator, Pressable, ScrollView, StyleSheet, View } from 'react-native';
import { Class, GetClassQueryVariables, ListClassLoginByClassIDQuery, ListClassLoginByClassIDQueryVariables, ListScheduleClassByClassIdQuery, ListScheduleClassByClassIdQueryVariables, ListTeacherClassesByClassIDQuery, ListTeacherClassesByClassIDQueryVariables, OnMutateClassLoginByClassIDSubscription, OnMutateClassLoginByClassIDSubscriptionVariables, OnMutateScheduleClassByClassIDSubscription, OnMutateScheduleClassByClassIDSubscriptionVariables, OnMutateStudentClassByClassIdSubscription, OnMutateStudentClassByClassIdSubscriptionVariables, OnMutateTeacherClassByClassIDSubscription, OnMutateTeacherClassByClassIDSubscriptionVariables, UpdateClassMutation, UpdateClassMutationVariables } from '../../common/API';
import { DecidaColors } from '../../common/decida-colors';
import { updateClass } from '../../common/graphql/mutations';
import { listClassLoginByClassID, listScheduleClassByClassId, listTeacherClassesByClassID } from '../../common/graphql/queries';
import { onMutateClassLoginByClassID, onMutateScheduleClassByClassID, onMutateStudentClassByClassId, onMutateTeacherClassByClassID } from '../../common/graphql/subscriptions';
import ButtonGroupSubmitCancel from '../common/button-group-cancel-submit';
import { BreakPoints, CommonStyles } from '../common/const';
import CurrentClassLogin from '../common/current-class-login';
import { DefaultText } from '../common/default-text';
import { handleRestartApp } from '../common/handle-restart-app';
import InputDefault from '../common/input-default';
import { insertNewItemIntoListStudentClassAndStudentDataByClassIdCustomQuery } from '../common/insert-new-item-into-list-student-class-and-student-data-by-class-id-custom-query';
import { rvIsLoading } from '../common/loading';
import { StepperBox } from '../common/stepper-box';
import { updateListQueryCache } from '../common/update-list-query-cache';
import { useResponsive } from '../common/use-responsive';
import { schoolAdminGetClassQuery, SchoolAdminGetClassQueryResponse } from '../custom-graphql/queries/school-admin-custom-queries/school-admin-get-class-query';
import { IconEdit } from '../icon/icon-edit';
import { TeacherClassAdminSchedule } from '../teacher/teacher-class-admin-schedule';
import SchoolAdminClassSettings from './school-admin-class-settings';
import SchoolAdminSidebarStudentList from './school-admin-sidebar-student-list';
import SchoolAdminSidebarTeacherList from './school-admin-sidebar-teacher-list';
import { rvSidebarClassID } from './school-admin-state';
import { ClassSettingsOptions, GoClassSettingsOptions } from './school-admin-types';


const classSettigsOption: { value: ClassSettingsOptions, label: string }[] = Object.values(ClassSettingsOptions).map((value) => ({
    value,
    label: value,
}));

const goClassSettingsOptions: { value: GoClassSettingsOptions, label: string }[] = Object.values(GoClassSettingsOptions).map((value) => ({
    value,
    label: value,
}));

const SchoolAdminClassDetailsSidebar = () => {
    const { width } = useResponsive()
    const currentSidebarClassID = useReactiveVar(rvSidebarClassID)

    const { loading: isLoadingClass, data } = useQuery<SchoolAdminGetClassQueryResponse, GetClassQueryVariables>(schoolAdminGetClassQuery, { variables: { id: currentSidebarClassID }, skip: !currentSidebarClassID })
    const [updateClassMutation] = useMutation<UpdateClassMutation, UpdateClassMutationVariables>(gql`${updateClass}`,)

    const currentClass = data?.getClass as Class
    const [editClassName, setEditClassName] = useState(false)
    const [className, setClassName] = useState("")
    const [toastMessage, setToastMessage] = useState(false)

    useEffect(() => {
        setClassName(currentClass?.name || "")
    }, [currentClass])


    const { error: errorSubscriptionTeacherClass } = useSubscription<OnMutateTeacherClassByClassIDSubscription, OnMutateTeacherClassByClassIDSubscriptionVariables>(gql`${onMutateTeacherClassByClassID}`, {
        variables: { classID: currentSidebarClassID },
        skip: !currentSidebarClassID,
        onData: updateListQueryCache<ListTeacherClassesByClassIDQuery, ListTeacherClassesByClassIDQueryVariables, OnMutateTeacherClassByClassIDSubscription>({ listQuery: listTeacherClassesByClassID, listQueryName: 'listTeacherClassesByClassID', subscriptionName: "onMutateTeacherClassByClassID", variables: { classID: currentSidebarClassID }, })
    })

    const { error: errorScheduleClassSubscription } = useSubscription<OnMutateScheduleClassByClassIDSubscription, OnMutateScheduleClassByClassIDSubscriptionVariables>
        (gql`${onMutateScheduleClassByClassID}`,
            {
                variables: { classID: currentSidebarClassID },
                onData: updateListQueryCache<ListScheduleClassByClassIdQuery, ListScheduleClassByClassIdQueryVariables, OnMutateScheduleClassByClassIDSubscription>({ listQuery: listScheduleClassByClassId, listQueryName: "listScheduleClassByClassId", subscriptionName: "onMutateScheduleClassByClassID", variables: { classID: currentSidebarClassID, limit: 5000 } }),
                skip: !currentSidebarClassID
            },
        )

    const { error: classLoginSubscriptionError } = useSubscription<
        OnMutateClassLoginByClassIDSubscription,
        OnMutateClassLoginByClassIDSubscriptionVariables
    >(
        gql`
      ${onMutateClassLoginByClassID}
    `,
        {
            variables: { classID: currentSidebarClassID },
            onData: updateListQueryCache<
                ListClassLoginByClassIDQuery,
                ListClassLoginByClassIDQueryVariables,
                OnMutateClassLoginByClassIDSubscription
            >({
                listQuery: listClassLoginByClassID,
                listQueryName: 'listClassLoginByClassID',
                subscriptionName: 'onMutateClassLoginByClassID',
                variables: {
                    classID: currentSidebarClassID,
                    limit: 5000
                }
            }),
            skip: !currentSidebarClassID,
        }
    );

    const { error: errorStudentClassSubscription } = useSubscription<
        OnMutateStudentClassByClassIdSubscription,
        OnMutateStudentClassByClassIdSubscriptionVariables
    >(
        gql`
      ${onMutateStudentClassByClassId}
    `,
        {
            variables: { classID: currentSidebarClassID || '' },
            onData: insertNewItemIntoListStudentClassAndStudentDataByClassIdCustomQuery({ classID: currentSidebarClassID }),
            skip: currentSidebarClassID === '',
        }
    );

    if (errorSubscriptionTeacherClass !== undefined ||
        errorScheduleClassSubscription !== undefined ||
        errorStudentClassSubscription !== undefined ||
        classLoginSubscriptionError !== undefined) {
        handleRestartApp()
    }

    const handleCloseSidebar = () => {
        rvSidebarClassID("")
    }

    const toggleEditClassName = () => {
        setEditClassName(prev => !prev)
    }

    const [activeIndex, setActiveIndex] = useState<ClassSettingsOptions | GoClassSettingsOptions>(currentClass?.goMode ? GoClassSettingsOptions.GroupSettings : ClassSettingsOptions.ClassSettings);

    const handlePressStepper = (value: ClassSettingsOptions | GoClassSettingsOptions) => {
        setActiveIndex(value);
    };

    const saveClassName = async () => {
        rvIsLoading(true)
        await updateClassMutation({
            variables: {
                input: {
                    id: currentSidebarClassID,
                    name: className,
                    _version: currentClass?._version
                }
            }
        })
        rvIsLoading(false)
        toggleEditClassName()
    }

    return (
        <View style={[styles.container, CommonStyles.dropShadow]}>
            {isLoadingClass ? (
                <View style={styles.centered}>
                    <ActivityIndicator size={35} color={DecidaColors.Green} />
                </View>
            ) : (
                <>
                    <View style={styles.sideBarHeader}>
                        <AntDesign onPress={handleCloseSidebar} name="close" size={36} color={DecidaColors.AppleSystemGray2Dark} />
                        <View style={styles.headerContaienr}>
                            {editClassName ? (
                                <View style={styles.classNameInputContainer}>
                                    <InputDefault
                                        value={className}
                                        onChangeText={setClassName}
                                        placeholder='Class name'
                                        customInputStyle={{ marginBottom: 0, borderRadius: 0 }}
                                    />
                                    <ButtonGroupSubmitCancel onConfirm={saveClassName} onCancel={toggleEditClassName} />
                                </View>
                            ) : (
                                <>
                                    <DefaultText style={styles.className}>
                                        {currentClass?.name}
                                    </DefaultText>
                                    <Pressable onPress={toggleEditClassName} style={styles.editIcon}>
                                        <IconEdit />
                                    </Pressable>
                                </>
                            )}
                        </View>
                        <View />
                    </View>

                    <ScrollView contentContainerStyle={styles.sidebarContet}>
                        <View style={{ width: width < BreakPoints.L ? '100%' : '50%', paddingHorizontal: 10, }}>
                            <SchoolAdminSidebarTeacherList />
                            <SchoolAdminSidebarStudentList />
                        </View>
                        <View style={{ width: width < BreakPoints.L ? '100%' : '50%', marginTop: 60, paddingHorizontal: 10, }}>
                            <StepperBox
                                options={currentClass?.goMode ? goClassSettingsOptions : classSettigsOption}
                                activeIndex={activeIndex}
                                onPress={handlePressStepper}
                            />

                            {(activeIndex === ClassSettingsOptions.ClassSettings) || activeIndex === GoClassSettingsOptions.GroupSettings ? (
                                <SchoolAdminClassSettings />
                            ) : activeIndex === ClassSettingsOptions.ScheduleCheckIns ? (
                                <View style={{ paddingTop: 20 }}>
                                    <TeacherClassAdminSchedule />
                                </View>
                            ) : (
                                <CurrentClassLogin classID={currentSidebarClassID} />
                            )}
                        </View>

                    </ScrollView>
                </>
            )}

        </View>
    )
}
export default SchoolAdminClassDetailsSidebar


const styles = StyleSheet.create({
    classNameInputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        width: 400,
    },
    className: {
        fontSize: 36,
        color: DecidaColors.GrayText,
    },
    editIcon: {
        width: 15,
        height: 15,
    },
    centered: {
        flex: 1,
        alignItems: 'center',
        justifyContent: "center",
    },
    noteText: {
        fontSize: 16,
        color: DecidaColors.GrayBorder,
        fontStyle: 'italic',
        paddingHorizontal: 20,
    },
    sidebarContet: {
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',
        flex: 1,
        height: '100%'
    },
    headerContaienr: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 2,
    },
    sideBarHeader: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
        paddingTop: 10
    },
    container: {
        position: 'absolute',
        right: 0,
        width: '50%',
        backgroundColor: DecidaColors.White,
        height: '100%'
    },

})