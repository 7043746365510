import { MaterialIcons } from '@expo/vector-icons'
import { StyleSheet } from 'react-native'
import { CheckInUserGroup } from '../../common/constants'
import { DecidaColors } from "../../common/decida-colors"
import { DefaultText } from '../common/default-text'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { Switch4SchoolButton } from '../common/switch4schoolbutton'
import { IconThumbUp } from '../icon/icon-thumb-up'

export const TeacherCheckInFinish = ({ navigation: { navigate }, route: { params } }: any) => {
    const isStaffCheckIn = params.user === CheckInUserGroup.Teacher || params.user === CheckInUserGroup.NonTeachingStaff

    const handleFinish = () => {
        switch (params.user) {
            case CheckInUserGroup.Teacher:
                navigate(ScreenNames.TeacherWellbeing)
                break;
            case CheckInUserGroup.NonTeachingStaff:
                navigate(ScreenNames.NonTeachingStaffWellbeing)
                break

            default:
                nextPerson()
                break;
        }
    }

    const nextPerson = () => {
        navigate(ScreenNames.CheckInStart, { classID: params?.classID })
    }

    return (
        <Page style={styles.page}>
            <DefaultText style={styles.title}>Thanks for that!</DefaultText>

            <IconThumbUp />
            <Switch4SchoolButton onPress={handleFinish} buttonText={isStaffCheckIn ? "Finished" : "Next Person"} Icon={() => <MaterialIcons name="next-plan" size={40} color={DecidaColors.Green} />} />
        </Page>
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'space-around'
    },
    title: {
        fontSize: 40
    },
    avatar: {
        width: 100,
        height: 100,
    }
})