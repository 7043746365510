import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { FurryFriendIcon } from '../../icons/furry-friend-icon'
import { FurryFriendIconInverted } from '../../icons/furry-friend-icon-inverted'


export const SwitchesActivitiesSeparationAnxietyFurryFrined: CardTemplate = {
    cardId: "73cbf0a7-bd7b-405d-a94c-958dd9bd8664",
    title: 'Furry friend',
    Header: FurryFriendIconInverted,
    Icon: FurryFriendIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Class toys:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Have a couple of cuddly class ‘pet’ toys"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Hand them out to any students who may be finding it difficult to adjust to the new classroom environment "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Allow those kids to have ownership of that toy for a period of time "} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Encourage them to bring the toy out whenever they are feeling worried or scared"} />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.SEPARATION_ANXIETY,
    whyDoesItWorkText: '',
    type: [CardType.Activity],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.SEPARATION_ANXIETY],
    action: [CardActionType.Action],
    level: []
}

