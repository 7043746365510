import { PropsWithChildren, createRef, useEffect, useRef, useState } from 'react';
import { Animated, Modal, Platform, Pressable, StyleSheet, View, ViewStyle } from 'react-native';
import { HandlerStateChangeEvent, PanGestureHandler, PinchGestureHandler, State } from 'react-native-gesture-handler';
import { DecidaColors } from '../../common/decida-colors';
import DefaultModal from './default-modal';
import { DefaultText } from './default-text';
import useDeviceTypes from './use-device-types';

type Props = PropsWithChildren & {
  style?: ViewStyle
}

const ViewZoomableForIphone = ({ children, style }: Props) => {
  const [panEnabled, setPanEnabled] = useState(false);
  const [pinchEnabled, setPinchEnabled] = useState(false)
  const { isIphone } = useDeviceTypes()

  const scale = useRef(new Animated.Value(1)).current;
  const translateX = useRef(new Animated.Value(0)).current;
  const translateY = useRef(new Animated.Value(0)).current;
  const opacity = useRef(new Animated.Value(0)).current

  const pinchRef = createRef();
  const panRef = createRef();

  useEffect(() => {
    if (panEnabled) {
      Animated.spring(opacity, {
        toValue: 1,
        useNativeDriver: true
      }).start();
    }
  }, [panEnabled])

  const onPinchEvent = Animated.event([{
    nativeEvent: { scale }
  }],
    { useNativeDriver: true });

  const onPanEvent = Animated.event([{
    nativeEvent: {
      translationX: translateX,
      translationY: translateY
    }
  }],
    { useNativeDriver: true });

  const handlePinchStateChange = ({ nativeEvent }: { nativeEvent: HandlerStateChangeEvent["nativeEvent"] }) => {
    // enabled pan only after pinch-zoom
    if (nativeEvent.state === State.ACTIVE) {
      setPanEnabled(true);
    }

    // when scale !== 1, reset scale back to original (1)
    const nScale = nativeEvent.scale as number;
    if (nativeEvent.state === State.END) {
      if (nScale < 1) {
        resetAnimatedValue()
      }
    }
  };

  const resetAnimatedValue = () => {
    Animated.spring(scale, {
      toValue: 1,
      useNativeDriver: true
    }).start();
    Animated.spring(translateX, {
      toValue: 0,
      useNativeDriver: true
    }).start();
    Animated.spring(translateY, {
      toValue: 0,
      useNativeDriver: true
    }).start();
    setPanEnabled(false);
  }

  const Container = panEnabled && !pinchEnabled ? DefaultModal : Pressable

  if (!isIphone || Platform.OS === 'web') {
    return <View style={style}>
      {children}
    </View>
  }

  return (
    <Container
      onPress={() => setPanEnabled(true)}
      contentStyle={styles.modalContainerStyle}
      onClose={resetAnimatedValue}
    >

      <PanGestureHandler
        onGestureEvent={onPanEvent}
        ref={panRef}
        simultaneousHandlers={[pinchRef]}
        enabled={panEnabled}
        failOffsetX={[-1000, 1000]}
        shouldCancelWhenOutside
        onCancelled={resetAnimatedValue}
      >
        <Animated.View>
          <PinchGestureHandler
            ref={pinchRef}
            onGestureEvent={onPinchEvent}
            onBegan={() => {
              if (!panEnabled) {
                setPinchEnabled(true)
              }
            }}
            onEnded={() => {
              setPinchEnabled(false)
            }}
            simultaneousHandlers={[panRef]}
            onHandlerStateChange={handlePinchStateChange}
          >
            <Animated.View
              style={{
                flex: panEnabled ? 1 : undefined,
                width: '100%',
                height: '100%',
                transform: [{ scale }, { translateX }, { translateY }],
                ...style,
              }}
            >
              {children}
            </Animated.View>

          </PinchGestureHandler>
        </Animated.View>

      </PanGestureHandler>
    </Container>
  );
}
export default ViewZoomableForIphone

const styles = StyleSheet.create({
  modalContainerStyle: {
    overflow: 'hidden',
    maxWidth: '100%',
    maxHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: DecidaColors.White,
    width: '95%',
    height: '95%',
  }
})