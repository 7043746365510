import { StyleSheet, View } from 'react-native'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import { WallPushUpsHeaderIcon } from './src/wall-push-ups-header-icon'
import { WallPushUpsIcon } from './src/wall-push-ups-icon'
import { WallPushUpsImage1 } from './src/wall-push-ups-image1'
import { WallPushUpsImage2 } from './src/wall-push-ups-image2'
import { WallPushUpsImage3 } from './src/wall-push-ups-image3'
import VideoCard from '../../../common/video-card'
import { Videos } from '../../../models'

const wallPushUpsVideo1: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/r4oZ-EHcSy4",
    title: "",
}

export const SwitchesEmotionsExcitedWallsPushUps: CardTemplate = {
    cardId: "c2989175-7c27-4d1b-a9f3-63766b66a277",
    title: 'Wall push ups',
    Header: WallPushUpsHeaderIcon,
    Icon: WallPushUpsIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Releasing pent up energy and tension.  "} />

            <DefaultTextWithoutArrows textdata={"1. Wall push ups are a special kind of exercise where we stand facing a wall and gently push against it with our hands."} />
            <DefaultTextWithoutArrows textdata={"2. Stand facing the wall."} />
            <DefaultTextWithoutArrows textdata={"3. Stretch out your arms to place your palms flat on the wall."} />
            <DefaultTextWithoutArrows textdata={"4. Take a step back so you are leaning towards the wall on a slight angle."} />
            <View style={styles.imageContainer}>
                <WallPushUpsImage1 />
            </View>

            <DefaultTextWithoutArrows textdata={"5. Slowly bend your elbows to bring your body closer to the wall."} />
            <DefaultTextWithoutArrows textdata={"6. Push back to straighten your arms again."} />
            <View style={styles.imageContainer}>
                <WallPushUpsImage2 />
            </View>

            <DefaultTextWithoutArrows textdata={"7. Repeat a few times."} />
            <View style={styles.imageContainer}>
                <WallPushUpsImage3 />
            </View>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    cardContentTemplate: 'column',
    colorScheme: CardColorScheme.EXCITED,
    whyDoesItWorkText: "Wall push ups can help regulate emotions because they provide a physical outlet for releasing pent up energy and tension. When we experience strong emotions, our bodies can become activated and restless. Engaging in physical activities like wall push ups allows us to redirect that energy and focus on the movements, which can help us feel more grounded and in control. The rhythmic motion and exertion of the muscles during wall push ups also activate the release of endorphins, which are chemicals in our brains that make us feel happier and more relaxed. By combining physical exertion, sensory input, and the positive impact of endorphins, wall push ups can effectively contribute to regulating emotions and promoting a sense of wellbeing.",
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Describe the concept of wall push ups and how it can be used as a tool for regulating emotions, and practice them to experience the benefits.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 25 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (2 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students and explain that today they will be learning about a special exercise called "Wall Push ups" that can help them regulate their emotions and feel better.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Demonstrate a wall push ups to give them an idea of what they look like. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Engage the students by asking questions like, “How do you think exercise can help us feel better when we're upset or over stimulated? </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• How Wall Push ups Help with Emotions (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that when we exercise, like doing wall push ups, it helps our bodies release feel-good chemicals called endorphins. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how endorphins can make us feel happier, calmer, and more balanced emotionally.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise that wall push ups provide a physical outlet to release and manage strong emotions in a positive and healthy way. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Practicing Wall Push ups (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Guide the students through the proper technique of wall push ups, using the same steps as in the switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that during wall push ups, they can imagine pushing away their negative emotions and welcoming positive and happy feelings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to practice wall push ups, focusing on their breathing and the physical sensations they experience.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflection and Closure (3 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students back into a circle and ask them to share how they felt during and after practicing wall push ups.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how exercise, like wall push ups, can make us feel stronger, happier, and more in control of our emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Recap the lesson by emphasising that wall push ups can be used as a fun and healthy way to regulate emotions and improve our overall wellbeing.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to practice wall push ups whenever they feel upset or overwhelmed, as a way to help them feel better. </DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Open wall space</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Coping Skill - Wall Push Ups -</DefaultText>
                        <DefaultTextWithLink text='https://youtu.be/r4oZ-EHcSy4' />
                        <View style={styles.rowContainerHalf}>
                            <VideoCard video={wallPushUpsVideo1} />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>Wall Pushup Variations for a Strong Chest, Shoulders, and Back -</DefaultText>
                        <DefaultTextWithLink text='https://www.healthline.com/health/fitness-exercise/wall-pushups#benefits' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Wall+push+ups+voice+over.m4a",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Excited]
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    rowContainerHalf: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        alignSelf: 'center',
        marginVertical: 10
    },
})
