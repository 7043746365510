import { Platform } from 'react-native'
import Svg, { G, Line, Path, Circle, Text, TSpan, Rect } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconTirednessScale = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPress1 = {
        [prop]: () => onPress(5)
    }

    const onPress2 = {
        [prop]: () => onPress(4)
    }

    const onPress3 = {
        [prop]: () => onPress(3)
    }

    const onPress4 = {
        [prop]: () => onPress(2)
    }

    const onPress5 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg testID='svg-intensity-scale' viewBox="0 0 849 198">
            <G transform="translate(-6039.268 -2224.141)" scale={1.12}>
                <Line x2="753.345" transform="translate(6041.768 2285.189)" fill="none" stroke="#96999c" strokeLinecap="round" strokeWidth="5" />
                <G {...onPress1}>
                    <Circle cx="41.5" cy="41.5" r="41.5" transform="translate(6087.768 2243.689)" fill="white" stroke="#5a5b5e" strokeWidth="5" />
                    <Path d="M6112.639,2279.738a6.531,6.531,0,1,0-6.532-6.532,6.532,6.532,0,0,0,6.532,6.532" fill="#5a5b5e" />
                    <Path d="M6145.279,2279.738a6.531,6.531,0,1,0-6.532-6.532,6.532,6.532,0,0,0,6.532,6.532" fill="#5a5b5e" />
                    <Path d="M6109.164,2295.689s18.161,20.4,39.68,0" fill="none" stroke="#5a5b5e" strokeLinecap="round" strokeWidth="5" />
                    <Line x2="39.68" transform="translate(6109.164 2295.689)" fill="none" stroke="#5a5b5e" strokeLinecap="round" strokeWidth="5" />
                    <Text transform="translate(6080.611 2347.014)" fill="#5a5b5e" fontSize="17" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0" letterSpacing="-0.017em">W</TSpan><TSpan y="0">ell rested</TSpan></Text>
                </G>
                <G {...onPress2}>
                    <Circle cx="41.5" cy="41.5" r="41.5" transform="translate(6231.629 2243.689)" fill="white" stroke="#5a5b5e" strokeWidth="5" />
                    <Path d="M6256.5,2279.738a6.531,6.531,0,1,0-6.532-6.532,6.532,6.532,0,0,0,6.532,6.532" fill="#5a5b5e" />
                    <Path d="M6289.141,2279.738a6.531,6.531,0,1,0-6.532-6.532,6.532,6.532,0,0,0,6.532,6.532" fill="#5a5b5e" />
                    <Path d="M6253.025,2295.689s18.161,20.4,39.68,0" fill="none" stroke="#5a5b5e" strokeLinecap="round" strokeWidth="5" />
                    <Text transform="translate(6227.525 2347.014)" fill="#5a5b5e" fontSize="17" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Pretty good</TSpan></Text>
                </G>
                <G {...onPress3}>
                    <Circle cx="41.5" cy="41.5" r="41.5" transform="translate(6375.49 2243.689)" fill="white" stroke="#5a5b5e" strokeWidth="5" />
                    <Path d="M6400.362,2283.738a6.531,6.531,0,1,0-6.532-6.532,6.532,6.532,0,0,0,6.532,6.532" fill="#5a5b5e" />
                    <Path d="M6433,2283.738a6.531,6.531,0,1,0-6.532-6.532,6.532,6.532,0,0,0,6.532,6.532" fill="#5a5b5e" />
                    <Line x2="44.2" transform="translate(6393.59 2296.465)" fill="none" stroke="#5a5b5e" strokeLinecap="round" strokeWidth="5" />
                    <Text transform="translate(6371.09 2347.014)" fill="#5a5b5e" fontSize="17" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0" letterSpacing="-0.036em">A</TSpan><TSpan y="0"> little tired</TSpan></Text>
                </G>
                <G {...onPress4}>
                    <Circle cx="41.5" cy="41.5" r="41.5" transform="translate(6519.352 2243.689)" fill="white" stroke="#5a5b5e" strokeWidth="5" />
                    <Path d="M6544.223,2283.738a6.531,6.531,0,1,0-6.532-6.532,6.532,6.532,0,0,0,6.532,6.532" fill="#5a5b5e" />
                    <Path d="M6576.863,2283.738a6.531,6.531,0,1,0-6.532-6.532,6.532,6.532,0,0,0,6.532,6.532" fill="#5a5b5e" />
                    <Path d="M6535.951,2283.738s10.107,8.449,15.8,0" fill="none" stroke="#5a5b5e" strokeLinecap="round" strokeWidth="3" />
                    <Path d="M6584.765,2283.312s-10.107,8.449-15.8,0" fill="none" stroke="#5a5b5e" strokeLinecap="round" strokeWidth="3" />
                    <Path d="M6537.113,2304.865s26.4-14.324,43.8,0" fill="none" stroke="#5a5b5e" strokeLinecap="round" strokeWidth="5" />
                    <Text transform="translate(6522.101 2347.014)" fill="#5a5b5e" fontSize="17" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0" letterSpacing="-0.054em">V</TSpan><TSpan y="0">ery tired</TSpan></Text>
                </G>
                <G {...onPress5}>
                    <Circle cx="41.5" cy="41.5" r="41.5" transform="translate(6663.213 2243.689)" fill="white" stroke="#5a5b5e" strokeWidth="5" />
                    <Path d="M6694.553,2272.151s-6.625,15.084-13.24-.516" fill="none" stroke="#5a5b5e" strokeLinecap="round" strokeWidth="5" />
                    <Path d="M6728.053,2272.151s-6.625,15.084-13.24-.516" fill="none" stroke="#5a5b5e" strokeLinecap="round" strokeWidth="5" />
                    <Path d="M6714.268,2300.532s-11.116-20.4-24.288,0" fill="none" stroke="#5a5b5e" strokeLinecap="round" strokeWidth="5" />
                    <Line x1="24.288" transform="translate(6689.98 2300.532)" fill="none" stroke="#5a5b5e" strokeLinecap="round" strokeWidth="5" />
                    <Path d="M6729.912,2240.038h6l-8.9,12.1a3.537,3.537,0,0,0-.3,3.7,3.437,3.437,0,0,0,3.1,1.9h12.9a3.5,3.5,0,0,0,0-7h-6l8.9-12.2a3.541,3.541,0,0,0,.3-3.7,3.437,3.437,0,0,0-3.1-1.9h-12.9a3.546,3.546,0,0,0-3.5,3.5,3.441,3.441,0,0,0,3.5,3.6Z" fill="#5a5b5e" />
                    <Path d="M6729.912,2240.038h6l-8.9,12.1a3.537,3.537,0,0,0-.3,3.7,3.437,3.437,0,0,0,3.1,1.9h12.9a3.5,3.5,0,0,0,0-7h-6l8.9-12.2a3.541,3.541,0,0,0,.3-3.7,3.437,3.437,0,0,0-3.1-1.9h-12.9a3.546,3.546,0,0,0-3.5,3.5,3.441,3.441,0,0,0,3.5,3.6Z" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                    <Path d="M6767.514,2242.441h-6l8.9-12.2a3.537,3.537,0,0,0,.3-3.7,3.436,3.436,0,0,0-3.1-1.9l-13,0a3.5,3.5,0,0,0,0,7h6l-8.9,12.2a3.541,3.541,0,0,0-.3,3.7,3.437,3.437,0,0,0,3.1,1.9l13,0a3.546,3.546,0,0,0,3.5-3.5,3.479,3.479,0,0,0-3.5-3.5" fill="#5a5b5e" />
                    <Path d="M6767.514,2242.441h-6l8.9-12.2a3.537,3.537,0,0,0,.3-3.7,3.436,3.436,0,0,0-3.1-1.9l-13,0a3.5,3.5,0,0,0,0,7h6l-8.9,12.2a3.541,3.541,0,0,0-.3,3.7,3.437,3.437,0,0,0,3.1,1.9l13,0a3.546,3.546,0,0,0,3.5-3.5A3.479,3.479,0,0,0,6767.514,2242.441Z" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
                    <Text transform="translate(6660.752 2347.014)" fill="#5a5b5e" fontSize="17" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Exhausted</TSpan></Text>
                </G>
            </G>
        </Svg>
    )
}