import { Text, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { Identify5Icon } from '../../explore/src/identify-5-icon'
import { Identify5IconInverted } from '../../explore/src/identify-5-icon-inverted'
import { Identify5Image1 } from './src/identify-5-image1'
import { Identify5LessonPlanImage1 } from './src/identify5-lesson-plans-image1'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'


export const SwitchesEmotionsScaredIdentify5: CardTemplate = {
    cardId: "dc102f40-b33b-4abd-8b3c-744ce176e05e",
    title: 'Identify 5',
    Header: Identify5IconInverted,
    Icon: Identify5Icon,
    lessonPlan: true,
    Images: () => (
        <>
            <Identify5Image1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"When focus needs to shift from an internal state to an external one."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"5: Identify FIVE different things you see around you. It could be a pen, a spot on the ceiling, or a person. "} />
                <DefaultTextWithDownArrow textdata={"4: Identify FOUR different things you can touch and feel around you. It could be your hair, a chair, or the floor under your feet. "} />
                <DefaultTextWithDownArrow textdata={"3: Identify THREE different things you hear. This could be a bird, or a clock ticking, or someone talking in another room. "} />
                <DefaultTextWithDownArrow textdata={"2: Identify TWO different things you can smell. This might be a book on your desk, the soap from washing your hands, or freshly cut grass outside. ."} />
                <DefaultTextWithoutArrows textdata={"1: Identify ONE thing you can taste (or remember tasting today). This might be toothpaste, or something you ate or drank."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SCARED,
    whyDoesItWorkText: 'This activity reduces anxiety and fear. Our brains are biased towards a particular sensory type of processing. When you use the 5,4,3,2,1 technique our mental resources are allocated into the senses that you might not use as often which helps tame your reactivity and ‘wake up’ your prefrontal cortex.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice using a grounding exercise to provide a mental distraction from self limiting anxiety or fear.

                        </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that when people feel scared or anxious, they may focus on stressful memories or worries about the future.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce grounding exercises as small tasks designed to bring individuals back to the present, helping manage stress and anxiety.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of slow, deep breaths to maintain a sense of calm.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>- If they haven’t previously, ask the students to complete the My Fears worksheet. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Grounding Exercise (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Instruct students to find a comfortable and quiet space.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students are using the Switch4Schools app independently, direct students to the relevant switch.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Guide students through the Identify 5! Checklist:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- 5: Identify five things you see around you.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- 4: Identify four things you can touch and feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- 3: Identify three things you hear.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- 2: Identify two things you can smell.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- 1: Identify one thing you can taste or remember tasting today.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to complete the exercise, paying attention to their breathing and using the checklist.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Sharing Experiences (7 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share some of the things they identified during the grounding exercise.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage unique and interesting responses.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how this activity can bring them back from scary thoughts to the present moment.</DefaultText>


                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Reflecting on My Fears (3 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Refer back to the My Fears worksheet.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to briefly reflect on how grounding exercises, like the one practiced, can be used during moments of panic to bring them back to the present.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce the importance of grounding techniques in managing stress and anxiety.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to use the Identify 5! Checklist whenever they feel overwhelmed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude by emphasising how these techniques can be powerful tools in staying calm and present.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students are using the Switch4Schools app independently, direct students to explore the relevant switch.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Paper/notebook</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Identify 5! worksheet' link={Worksheets.identify5.uri} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='My Fears worksheet' link={Worksheets.myFears.uri} /></DefaultText>

                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>30 Grounding Techniques to Quiet Distressing Thoughts -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.healthline.com/health/grounding-techniques' link='https://www.healthline.com/health/grounding-techniques' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Grounding Techniques for Post-Traumatic Stress Disorder -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.verywellmind.com/grounding-techniques-for-ptsd-2797300' link='https://www.verywellmind.com/grounding-techniques-for-ptsd-2797300' />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata='While writing these things down can be good to help create calm and focus, saying it out loud is a good way to learn this as a quick ‘in the moment’ activity when faced with something or someone that is causing fear.' />

        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Identify+5.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [],
    action: [CardActionType.Action, CardActionType.Thought],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three, CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Scared]
}


