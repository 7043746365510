import { RefreshControl, ScrollView, StyleSheet, View, ViewStyle } from 'react-native'
import React, { PropsWithChildren, useState, } from 'react'

interface Props {
    onRefresh: () => void
    contentContainerStyle?: ViewStyle
}
const WithRefreshControl = ({ children, onRefresh, contentContainerStyle }: PropsWithChildren<Props>) => {
    const [refreshing, setRefreshing] = useState<boolean>(false)

    const waitFor = (timeout: number) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }

    const handleRefresh = async () => {
        setRefreshing(true)
        onRefresh()
        await waitFor(500)
        setRefreshing(false)
    }
    return (
        <View style={styles.container}>
            <ScrollView contentContainerStyle={[styles.scrollViewContainer, { ...contentContainerStyle }]} showsVerticalScrollIndicator={false} refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={handleRefresh}
                />
            }>
                {children}
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%'
    },
    scrollViewContainer: {
        padding: 40
    }
})

export default WithRefreshControl