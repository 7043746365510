import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { Dimensions, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import { GetStudentQuery, GetStudentQueryVariables, RequestForChatStatus, SetRequestForChatMutation, SetRequestForChatMutationVariables } from '../../common/API'
import { setRequestForChat as setRequestForChatScript } from '../../common/graphql/mutations'
import { getStudent } from '../../common/graphql/queries'
import { rvNetInfo } from '../common/common-state'
import { DefaultButton } from '../common/default-button'
import { DefaultText } from '../common/default-text'
import { CLIENT_EVENT, createClientLog } from '../common/log-client-event'
import { Page } from '../common/page'
import { ScreenNames } from '../common/screen-names'
import { ChatBubbles } from '../common/svg-files/chat-bubbles'
import { showOfflineAlert } from '../common/universal-alert'
import { lowRes, useResponsive } from '../common/use-responsive'
import { rvCurrentStudentLastStudentCheckIn } from '../student/use-student-state'
import { rvTeacherCurrentTeacher } from '../teacher/teacher-state'
import { CheckInHeader } from './check-in-header'
import { CheckInNavigatorParamList } from './check-in-route-param-types'
import { rvCheckInState, rvCurrentWellbeingQuestion } from './check-in-state'

const windowWidth = Dimensions.get('window').width;
const windowWidthcondition = windowWidth < 600

export const CheckInRequestChatWithTeacher = ({ navigation: { navigate }, route: { params } }: StackScreenProps<CheckInNavigatorParamList, 'CheckInRequestChatWithTeacher'>) => {
    const { studentID = '' } = useReactiveVar(rvCheckInState)

    const currentWellbeingQuestion = useReactiveVar(rvCurrentWellbeingQuestion)
    const currentTeacher = useReactiveVar(rvTeacherCurrentTeacher)
    const studentLastCheckIn = useReactiveVar(rvCurrentStudentLastStudentCheckIn)

    const { data: studentResponse } = useQuery<GetStudentQuery, GetStudentQueryVariables>(gql`${getStudent}`, { variables: { id: studentID }, skip: !studentID })
    const currentStudent = studentResponse?.getStudent

    const netInfoState = useReactiveVar(rvNetInfo)
    const { scaleRatio, isNarrowScreen } = useResponsive()
    const transformByRatioStyle: ViewStyle = { transform: [{ scale: lowRes ? scaleRatio : 1 }] }

    const [setRequestForChatMutation] = useMutation<SetRequestForChatMutation, SetRequestForChatMutationVariables>(gql`${setRequestForChatScript}`,)

    const back = () => {
        if (currentWellbeingQuestion) {
            navigate(ScreenNames.CheckInWellbeingQuestion, { classID: params?.classID, user: params.user })
            return
        }
        navigate(ScreenNames.CheckInEmotionalIntensity, { classID: params?.classID, user: params?.user })
    }


    const setRequestForChat = (requestForChatStatus: RequestForChatStatus) => {
        if (netInfoState && netInfoState.isInternetReachable === false) return showOfflineAlert()

        if (studentLastCheckIn) {
            setRequestForChatMutation({
                variables: {
                    input: {
                        studentCheckInID: studentLastCheckIn?.id,
                        requestForChatStatus
                    }
                }
            })

            createClientLog({
                event: CLIENT_EVENT.CREATE_REQUEST_CHAT_SUCCESS,
                area: "Check in request chat page",
                action: "Success procceed request chat",
                payload: {
                    group: currentTeacher ? "teacher" : currentStudent ? "student" : "",
                    nickname: currentTeacher?.cognitoUsername || currentStudent?.cognitoUsername,
                    value: requestForChatStatus
                }
            })

            navigate(ScreenNames.CheckInFinish, { classID: params?.classID, user: params?.user })

        } else {
            // failed to procced request chat
            createClientLog({
                event: CLIENT_EVENT.CREATE_REQUEST_CHAT_FAILED,
                area: "Check in request chat page",
                action: "Clicking request chat button",
                payload: {
                    group: currentTeacher ? "teacher" : currentStudent ? "student" : "",
                    nickname: currentTeacher?.cognitoUsername || currentStudent?.cognitoUsername,
                    error: `Some data is not defined: studentLastCheckIn: ${studentLastCheckIn} `
                }
            })
        }
    }

    return (
        <Page style={styles.page} scroll>
            {
                !lowRes &&
                <CheckInHeader
                    back={back}
                    headerText={''}
                    studentId={studentID}
                />
            }
            <View style={styles.container}>
                <View style={[windowWidthcondition ? styles.iconContainer : styles.iPadIconContainer, transformByRatioStyle]}>
                    <ChatBubbles />
                </View>
                <View style={styles.iconsvg}>
                    <DefaultText style={[windowWidthcondition ? styles.question : styles.ipadContainer, transformByRatioStyle]}>
                        Do you want to talk to someone about how you’re feeling?
                    </DefaultText>
                </View>
                <View style={[transformByRatioStyle, { ...styles.buttonContainer }]}>
                    <DefaultButton testID="button-request-for-chat-yes" style={[styles.button, { flex: isNarrowScreen ? 0.3 : 1 }]} onPress={() => setRequestForChat(RequestForChatStatus.YES)}>
                        Yes
                    </DefaultButton>
                    <DefaultButton testID="button-request-for-chat-not-yet" style={[styles.buttonNotYet, { flex: 1 }]} onPress={() => setRequestForChat(RequestForChatStatus.NOT_YET)}>
                        Not yet
                    </DefaultButton>
                    <DefaultButton testID="button-request-for-chat-no" style={[styles.button, { flex: isNarrowScreen ? 0.3 : 1, }]} onPress={() => setRequestForChat(RequestForChatStatus.NO)}>
                        No
                    </DefaultButton>
                </View>
            </View>
        </Page>
    )
}

const styles = StyleSheet.create({
    page: {
        flex: 1,
        justifyContent: 'flex-start',
    },
    button: {
        flex: 1,
        height: 40,
        borderRadius: 5,
        paddingHorizontal: 10,
        minWidth: 100,
    },
    buttonNotYet: {
        flex: 1,
        height: 40,
        borderRadius: 5,
        minWidth: 100,
        marginHorizontal: 10
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: lowRes ? 0 : 50,
        paddingHorizontal: 5,
        width: '100%',
        maxWidth: 600
    },
    iconsvg: {
        flexDirection: 'row',
    },
    iconContainer: {
        paddingRight: 10,
        width: 125,
        height: 125,
        justifyContent: 'center',
        alignItems: 'center',
    },
    question: {
        width: 250,
        textAlign: 'center'
    } as TextStyle,
    container: {
        flex: 1,
        width: '100%',
        height: '100%',
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    ipadContainer: {
        width: 600,
        textAlign: 'center',
        fontSize: 28
    },
    iPadIconContainer: {
        paddingRight: 10,
        width: 200,
        height: 200,
        justifyContent: 'center',
        alignItems: 'center',
    }

})