import { AvatarPrintOut } from "../../emotion-cards/sad/src/avatar-print-out"
import { GrowthMindsetPoster } from "../../emotion-cards/sad/src/growth-mindset-poster"
import { CheckInCardLevel1Cover } from "../images/check-in-card-level1-cover"
import { CheckInCardLevel3Cover } from "../images/check-in-card-level3-cover"
import { CheckInCardLevel5Cover } from "../images/check-in-card-level5-cover"
import { CheckInCardLevel7Cover } from "../images/check-in-card-level7-cover"
import { EnergizerCutOutCover } from "../images/energizers-cut-out-cover"
import { Level7HowAreYouFeelingCutoutCover } from "../images/level-7-how-are-you-feeling-cutout-cover"
import { Level7QRGCover } from "../images/level-7-qrg-cover"
import { MyBrainPosterImage } from "../images/my-brain-poster-image"
import { MySwitchToolBoxDeskCards } from "../images/my-switch-toolbox-desk-cards"
import { PosterFlashCards } from "../images/posters-flash-cards"
import { PostersLevel1 } from "../images/posters-level-1"
import { PostersLevel1QuickReferenceGuide } from "../images/posters-level-1-quick-reference-guide"
import { PostersLevel3 } from "../images/posters-level-3"
import { PostersLevel3QuickReferenceGuide } from "../images/posters-level-3-quick-reference-guide"
import { PostersLevel5 } from "../images/posters-level-5"
import { PostersLevel5QuickReferenceGuide } from "../images/posters-level-5-quick-reference-guide"
import { SwitchCutOutsLevel1Cover } from "../images/switch-cut-outs-level1-cover"
import { SwitchCutOutsLevel3Cover } from "../images/switch-cut-outs-level3-cover"
import { SwitchCutOutsLevel5Cover } from "../images/switch-cut-outs-level5-cover"
import { SwitchCutOutsLevel7Cover } from "../images/switch-cut-outs-level7-cover"

export type PostersType = {
    label: string
    uri: string
    image: () => JSX.Element
}

export const posters: PostersType[] = [
    {
        label: "Emotion Wheel Poster (1 point scale)",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Emotion+Wheel+Poster+1-level.pdf",
        image: PostersLevel1
    },
    {
        label: "Emotion Wheel Poster (3 point scale)",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Emotion+Wheel+Poster+3-levels+(1).pdf",
        image: PostersLevel3
    },
    {
        label: "Emotion Wheel Poster (5 point scale)",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Emotion+Wheel+Poster+5-levels_2022.pdf",
        image: PostersLevel5
    },
    {
        label: "Level 1 quick reference guide",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/level+1+QRG.pdf",
        image: PostersLevel1QuickReferenceGuide
    },
    {
        label: "Level 3 quick reference guide",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/level+3+QRG.pdf",
        image: PostersLevel3QuickReferenceGuide
    },
    {
        label: "Level 5 quick reference guide",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/level+5+QRG.pdf",
        image: PostersLevel5QuickReferenceGuide
    },
    {
        label: "Flash cards",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/flashcards+final+(1).pdf",
        image: PosterFlashCards
    },
    {
        label: "The growth mindset poster",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/growth-mindset-poster.jpg",
        image: GrowthMindsetPoster
    },
    {
        label: "Student avatars and log in details",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Avatar+print+out.pdf",
        image: AvatarPrintOut
    },
    {
        label: "Check-in card level 1",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Check-in+Card_level+1.pdf",
        image: CheckInCardLevel1Cover
    },
    {
        label: "Check-in card level 3",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Check-in+Card_level+3.pdf",
        image: CheckInCardLevel3Cover
    },
    {
        label: "Check-in card level 5",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Check-in+Card_level+5.pdf",
        image: CheckInCardLevel5Cover
    },
    {
        label: "Check-in card level 7",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Level+7+Check-in+cards.pdf",
        image: CheckInCardLevel7Cover
    },
    {
        label: "Energisers cut outs",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Energisers+cut+outs.pdf",
        image: EnergizerCutOutCover
    },
    {
        label: "level 7 QRG",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/level+7+QRG.pdf",
        image: Level7QRGCover
    },
    {
        label: "LV 1 Switch cut outs",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/LV+1+Switch+Cut+outs.pdf",
        image: SwitchCutOutsLevel1Cover
    },
    {
        label: "LV 3 Switch cut outs",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/LV+3+Switch+Cut+outs.pdf",
        image: SwitchCutOutsLevel3Cover
    },
    {
        label: "LV 5 Switch cut outs",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/LV+5+Switch+Cut+outs.pdf",
        image: SwitchCutOutsLevel5Cover
    },
    {
        label: "LV 7 Switch cut outs",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/LV+7+Switch+Cut+outs.pdf",
        image: SwitchCutOutsLevel7Cover
    },
    {
        label: "LV7 how are you feeling today cut out",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/LV7+how+are+you+feeling+today+cut+out.pdf",
        image: Level7HowAreYouFeelingCutoutCover
    },
    {
        label: "My brain poster",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Brain+Poster.pdf",
        image: MyBrainPosterImage
    },
    {
        label: "My switch toolbox desk card",
        uri: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/worksheets/Switch+Desk+Cards.pdf",
        image: MySwitchToolBoxDeskCards
    },
]
