import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { Student, UpdateStudentMutation, UpdateStudentMutationVariables } from '../../common/API'
import { UserGroup } from '../../common/constants'
import { updateStaff, updateStudent, updateTeacher } from '../../common/graphql/mutations'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'
import { avatars } from '../icon/avatar/avatars'
import IconSwitch4SchoolLogo2023 from '../icon/icon-switch-4-school-logo-2023'
import { StudentMainNavigatorParamList, StudentOnboardDrawerNavigatorParamList } from '../student/student-route-param-types'
import { useNonTeachingStaffState } from '../student/use-non-teaching-staff-state'
import { rvCurrentStudent } from '../student/use-student-state'
import { TeacherAvatarPicker } from '../teacher/teacher-avatar-picker'
import { rvTeacherCurrentTeacher } from '../teacher/teacher-state'
import { BackButton } from './back-button'
import { DefaultText } from './default-text'
import { getStudentByStudentId } from './helper-api-functions'
import { rvIsLoading } from './loading'
import { Page } from './page'
import { ScreenNames } from './screen-names'

export const UserEditAvatar = () => {

    const student = useReactiveVar(rvCurrentStudent)
    const currentTeacher = useReactiveVar(rvTeacherCurrentTeacher)
    const { currentNonTeachingStaff } = useNonTeachingStaffState()
    const { navigate, goBack } = useNavigation<NavigationProp<StudentMainNavigatorParamList, "UserEditAvatar"> & NavigationProp<CheckInNavigatorParamList, "UserEditAvatar"> & NavigationProp<StudentOnboardDrawerNavigatorParamList, 'LoginStudentEditAvatar'>>()
    const { params, name: routeName } = useRoute<RouteProp<StudentOnboardDrawerNavigatorParamList, 'LoginStudentEditAvatar'>>()
    const { id, user: paramsUserToEdit } = params || {}
    const loginEditAvatar = routeName === ScreenNames.LoginStudentEditAvatar
    const [updateStudentMutation] = useMutation<UpdateStudentMutation, UpdateStudentMutationVariables>(gql`${updateStudent}`,)
    const [updateStaffMutation] = useMutation<UpdateStudentMutation, UpdateStudentMutationVariables>(gql`${updateStaff}`,)
    const [updateTeacherMutation] = useMutation<UpdateStudentMutation, UpdateStudentMutationVariables>(gql`${updateTeacher}`,)

    useEffect(() => {
        if (student?.id === id) {
            getStudentByStudentId(id || "")
        }
    }, [id])


    const setStudentAvatar = async (avatarName: keyof typeof avatars) => {
        if (student) {
            const { id, _version } = student as unknown as Student
            await updateStudentMutation({
                variables: {
                    input: {
                        id,
                        _version,
                        avatar: String(avatarName),
                        initialLoginSetup: false,
                    }
                }
            })

            if (loginEditAvatar) {
                navigate(ScreenNames.LoginStudentProfilePreview)
                return
            }

            goBack()
        }
    }

    const setStaffAvatar = async (avatarName: keyof typeof avatars) => {
        if (currentNonTeachingStaff) {

            await updateStaffMutation({
                variables: {
                    input: {
                        id,
                        _version: currentNonTeachingStaff?._version,
                        avatar: String(avatarName)
                    }
                }
            })

            goBack()
        }
    }

    const setTeacherAvatar = async (avatarName: keyof typeof avatars) => {
        if (currentTeacher) {

            await updateTeacherMutation({
                variables: {
                    input: {
                        id,
                        _version: currentTeacher?._version,
                        avatar: String(avatarName)
                    }
                }
            })

            goBack()
        }
    }


    const handleSetUserAvatar = async (avatarName: keyof typeof avatars) => {
        rvIsLoading(true)

        if (paramsUserToEdit === UserGroup.NonTeachingStaff) {
            await setStaffAvatar(avatarName)
        } else if (paramsUserToEdit === UserGroup.Teacher) {
            await setTeacherAvatar(avatarName)
        } else if (paramsUserToEdit === UserGroup.Student || loginEditAvatar) {
            await setStudentAvatar(avatarName)
        } else {
            console.error("user type is not defined")
        }
   
        rvIsLoading(false)
    }

    return (
        <Page style={styles.page}>
            <View style={styles.header}>
                {loginEditAvatar ? (
                    <View style={styles.iconContainer}>
                        <IconSwitch4SchoolLogo2023 />
                    </View>
                ) : (
                    <View style={styles.backButtonContainer}>
                        <BackButton />
                    </View>
                )}

            </View>
            <DefaultText style={styles.title}>Choose an avatar</DefaultText>
            <TeacherAvatarPicker onPress={handleSetUserAvatar} />
        </Page>
    )
}

const styles = StyleSheet.create({
    page: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    header: {
        flexDirection: 'row',
        width: '100%',
    },
    backButtonContainer: {
        flex: 1,
        padding: 5,
    },
    title: {
        fontSize: 30,
        padding: 20,
        alignSelf: 'center',
    },
    iconContainer: {
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        marginTop: '10%'
    }
})