import { StyleSheet, View } from 'react-native'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import DefaultTextWithCustomLink from '../../../common/default-text-with-custom-link'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { WarmFuzziesIcon } from '../../icons/warm-fuzzies-icon'
import { WarmFuzziesIconInverted } from '../../icons/warm-fuzzies-icon-inverted'
import { WarmAndFuzziesWorksheetIcon } from '../../icons/worksheets/warm-and-fuzzies-worksheets-icon'
import { WarmFuzziesImage1 } from '../../icons/warm-and-fuzzies-image1'
import { WarmFuzziesImage2 } from '../../icons/warm-and-fuzzies-image2'
import { Worksheets } from '../../../../common/worksheets-imports'


export const SwitchesActivitiesFunAndPositiveWarmFuzzies: CardTemplate = {
    cardId: "146777f0-44ab-447b-a4db-51d3a50e5a2b",
    title: 'Warm fuzzies',
    Header: WarmFuzziesIconInverted,
    Icon: WarmFuzziesIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Creating a positive and supportive classroom culture, as well as encouraging students to recognise and appreciate the positive actions of their peers. "} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata="" />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={<DefaultTextWithCustomLink indent={10} text='Warm fuzzy forms' link={Worksheets.warmFuzzies.uri} />} />
            <View style={styles.imageContainer}>
                <WarmAndFuzziesWorksheetIcon />
            </View>
            <DefaultTextWithoutArrowsBold textdata='Optional :  A jar of warm fuzzies (stick on eyes are also a fun addition) or other small rewards.  ' />
            <View style={styles.imageContainer}>
                <WarmFuzziesImage1 />
            </View>
            <View style={styles.imageContainer}>
                <WarmFuzziesImage2 />
            </View>

            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Give each student a warm fuzzy note template and a pen or pencil."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Ask the students to think about a classmate who has done something kind, helpful, or otherwise positive."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Have the students write a brief note to that classmate, expressing their appreciation and thanking them for their positive action."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Collect the notes and put them in a jar, box, or other container."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"At an appropriate time, perhaps at the end of the day or the beginning of the next class, draw a few of the notes from the container and read them aloud to the class."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={`As each note is read, the recipient of the note can come to the front of the class to receive their "warm and fuzzy" (a small fuzzy ball or other small reward) as a token of appreciation.`} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={`Consider repeating the activity periodically. `} />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    CardContent: () => (
        <>
        </>
    ),
    colorScheme: CardColorScheme.FUN_AND_POSITIVE,
    whyDoesItWorkText: "",
    type: [CardType.Activity],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.FUN_AND_POSITIVE],
    action: [CardActionType.Action],
    level: []
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
})

