import { View } from 'react-native'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { FiveFulIcon } from '../../icons/five-ful-icon'
import { DefaultTextWithExtraTipIcon } from '../../../common/default-text-with-extra-tip-icon'
import { FiveFulIconInverted } from '../../icons/five-ful-icon-inverted'
import { DefaultTextWithoutArrowsBoldLeftAligned } from '../../../common/default-text-without-arrows-bold-left-aligned'
import { DefaulttextWithRightArrowLeftAlignedIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-indentation'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaultTextNoArrowLeftAlignedDoubleIndentation } from '../../../common/default-text-no-arrow-left-aligned-double-indentation'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'


export const SwitchesActivitiesNumberAndWordGamesFiveFul: CardTemplate = {
    cardId: "881c5c6f-6717-4852-9ccc-a6432ea9dd00",
    title: 'Five-"ful"',
    Header: FiveFulIconInverted,
    Icon: FiveFulIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Helping explore how to increase the frequency and intensity of positive emotions."} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Materials:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Five “-ful” envelopes. Write a positive emotion on the front of each envelope: Hopeful Joyful Peaceful Playful Thankful"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Response cards. Four index cards for each team."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Timer"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Whistle"} />
            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Instructions:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Divide the participants into five teams of two to eight members. Teams should be approximately the same size."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Review the five positive emotions. Clarify each emotion. Ask participants to give synonyms and examples of each emotion. Here are some suggestions:"} />

            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Hopeful"} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation itallics='Synonyms: ' textdata={"assured, auspicious, confident, encouraged, optimistic, promising, sanguine, trusting, upbeat."} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation itallics='Examples: ' textdata={""} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• You are convinced that this activity is going to be extremely interesting and your team is going to win."} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• You just participated in a performance review and you are confident that your manager is going to promote you to a higher-paying job."} />

            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Joyful"} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation itallics='Synonyms: ' textdata={"happy, cheerful, delighted, ecstatic, elated, glad, jubilant, merry."} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation itallics='Examples: ' textdata={""} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• Your friends gave you a big surprise birthday party.	"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Peaceful"} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation itallics='Synonyms: ' textdata={" calm, centered, collected, content, serene, mellow, quiet, tranquil."} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation itallics='Examples: ' textdata={""} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• You take a nap on a deck chair in the shade, listening to the sound of the ocean and the twittering of birds."} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• You are sitting in front of a fireplace sipping a glass of hot chocolate while your dog is sleeping at your feet."} />

            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Playful"} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation itallics='Synonyms: ' textdata={"amused, fun-loving, antic, comical, frisky, impish, joking, light-hearted."} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation itallics='Examples: ' textdata={""} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• You play a board game with your friends"} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• You share “knock-knock” jokes."} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation textdata={"Thankful"} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation itallics='Synonyms: ' textdata={"appreciative, indebted, obliged, grateful."} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation itallics='Examples: ' textdata={""} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• A friend listens to your complaint and you receive a thank-you card the next day."} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• Your neighbour brings you a tasty treat after hearing that you’re not feeling well."} />

            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Give one “-ful” envelope and four index cards to each team."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Round one (3 minutes)."} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• Ask team members to discuss the positive emotion on the envelope and write short sentences explaining easy-to-apply suggestions on one index card. Explain that the teams’ response cards will be evaluated in terms of both the number and the quality of the suggestions."} />

            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"After 3 minutes"} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• Blow the whistle and announce the end of the first round. Ask each team to place its response card (the index card with the suggestions) inside the envelope and pass the envelope, unsealed, to the next team. Warn the teams not to open the envelope they receive."} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Round Two (3 minutes)."} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• Ask teams to review the positive emotion on the envelope they received, but not to look at the suggestions on the response card inside. Tell the teams to repeat the earlier procedure and list suggestions on a new response card for achieving this positive emotion more frequently and more intensely. "} />

            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"After 3 minutes"} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• Blow the whistle and ask teams to place the response card inside the envelope and pass it to the next team."} />

            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Round Three and Four (3 minutes each). "} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• Conduct two more rounds of the game using the same procedure."} />

            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Round Five – synthesis (five minutes). "} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={"• Start the fifth round just as you did the previous rounds. However, this time teams must select the top five suggestions from among the response cards inside the envelope. They do this by reviewing the individual suggestions on each response card and selecting the ones that can be easily applied to produce significant results. It does not matter if some of these suggestions are found on more than one card."} />

            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"At the end of the time limit, select a teams at random to present their selections."} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={""} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={""} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={""} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={""} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={""} />
            <DefaultTextNoArrowLeftAlignedDoubleIndentation textdata={""} />

            <DefaultTextWithoutArrowsBoldLeftAligned textdata={"Post activity de-brief:"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Which positive emotion was the most difficult one for you to come up with suggestions? Which one was the easiest? Why?"} />
            <DefaulttextWithRightArrowLeftAlignedIndentation textdata={"Which suggestions could you implement immediately?"} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={"Too few students? Conduct this activity as an individual game: Give a “-ful” envelope to each participant and ask them to write suggestions on the response card and to work through the steps. Not enough time? Stop the activity after the second round. Ask teams to pass the envelopes one more time and proceed immediately to the synthesis round.Ample time? Give more time for writing the response cards and for synthesizing them."} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>

            </View>
        </>
    ),
    colorScheme: CardColorScheme.NUMBER_AND_WORD_GAMES,
    whyDoesItWorkText: 'Positive psychology research shows these five emotions are among those that contribute to happiness, subjective well-being, and flourishing.This structured sharing activity helps participants discover how to increase the frequency and intensity of these emotions. This is the first step to increase one’s happiness.',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Five+Ful.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Medium, CardTimeType.Long],
    people: [CardPeopleType.Group],
    place: [CardPlaceType.Indoor, CardPlaceType.Outdoor],
    category: [CardCategoryType.NUMBER_AND_WORD_GAMES],
    action: [CardActionType.Action],
    level: []
}

