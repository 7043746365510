import { TouchableOpacity } from 'react-native'
import Svg, { Circle, Line } from 'react-native-svg'
import { DecidaColors } from "../../common/decida-colors"

interface Props {
    disabled?: boolean
    onPress?: () => void
    color?: string
    backgroundColor?: string
    size?: string | number
}

export const IconPlus = ({ disabled, onPress, color = DecidaColors.White, backgroundColor = DecidaColors.Green, size }: Props) =>
    <TouchableOpacity onPress={onPress}>
        <Svg width={size || '30'} height={size || '30'} viewBox='0 0 40 40'>
            <Circle r='20' cx='20' cy='20' fill={disabled ? DecidaColors.Gray : backgroundColor} />
            <Line x1='20' x2='20' y1='8' y2='32' stroke={color} strokeWidth='8' />
            <Line x1='8' x2='32' y1='20' y2='20' stroke={color} strokeWidth='8' />
        </Svg>
    </TouchableOpacity>