import { gql, makeVar, useQuery, useReactiveVar } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { useEffect, useMemo, useState } from 'react'
import { SafeAreaView, StyleSheet, View } from 'react-native'
import { CheckInSession, GetClassQuery, GetClassQueryVariables, GetStudentClassByStudentIdQuery, GetStudentClassByStudentIdQueryVariables, GetStudentQuery, GetStudentQueryVariables, ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables, StudentClass } from '../../common/API'
import { UserGroup } from '../../common/constants'
import { DecidaColors } from '../../common/decida-colors'
import { getClass, getStudent, getStudentClassByStudentId, listCheckInSessionsByClassId } from '../../common/graphql/queries'
import { StudentCheckInCacheObject, convertStudentCheckInCacheCodeToCacheObject } from '../../common/student-check-in-cache'
import { BackButton } from '../common/back-button'
import { CheckBox } from '../common/checkbox'
import { DefaultText } from '../common/default-text'
import ExportToPdfButton from '../common/export-to-pdf-button'
import { groupByWeek } from '../common/group-by-week'
import { Page } from '../common/page'
import { navigationRef } from '../common/root-navigation'
import { useScaleSizeForPrintMode } from '../common/scale-size-for-print-mode'
import { ScreenNames } from '../common/screen-names'
import ScrollViewPrint from '../common/scroll-view-print'
import { lowRes } from '../common/use-responsive'
import WeeklyGraphs from '../common/weekly-graphs'
import { avatars } from '../icon/avatar/avatars'
import { rvUserGroup } from '../login/login-state'
import { StudentMainNavigatorParamList } from '../student/student-route-param-types'
import { rvTeacherClassMainSubscriptionClassID } from './teacher-class-main'
import { TeacherClassNavigatorParamList } from './teacher-route-param-types'
import { TeacherStatsLegends } from './teacher-stats-legends'
import TeacherStudentIndividualStatsGoMode from './teacher-student-individual-stats-go-mode'

export const rvShowIndividualStudentHowTo = makeVar(false)

type TeacherStudentIndividualStackScreenProps = StackScreenProps<TeacherClassNavigatorParamList & StudentMainNavigatorParamList, "TeacherStudentIndividualStats">

export function TeacherStudentIndividualStats({ route: { params: { studentID, classID, previousScreen } }, navigation: { navigate } }: TeacherStudentIndividualStackScreenProps) {

    const { data: studentRespose } = useQuery<GetStudentQuery, GetStudentQueryVariables>(gql`${getStudent}`, { variables: { id: studentID } })
    const student = studentRespose?.getStudent

    const { data: studentClassesResponse } = useQuery<GetStudentClassByStudentIdQuery, GetStudentClassByStudentIdQueryVariables>(gql`${getStudentClassByStudentId}`, { variables: { studentID, limit: 5000 } })
    const studentClasses = studentClassesResponse?.getStudentClassByStudentId?.items.filter((item) => item?._deleted !== true)
    const [studentCheckIns, setStudentCheckIns] = useState<StudentCheckInCacheObject[]>([])
    const [checkstate, setCheckState] = useState(false)

    const { data: checkInSessionsResponse } = useQuery<ListCheckInSessionsByClassIdQuery, ListCheckInSessionsByClassIdQueryVariables>(gql`${listCheckInSessionsByClassId}`, { variables: { classID, limit: 5000 } })
    const currentClassCheckInSessions = checkInSessionsResponse?.listCheckInSessionsByClassId?.items.filter((item) => item?._deleted !== true) || []

    const userGroup = useReactiveVar(rvUserGroup)

    const { data } = useQuery<GetClassQuery, GetClassQueryVariables>(gql`${getClass}`, { variables: { id: classID } })
    const currentClass = data?.getClass


    const currentStudentClass = useMemo(() => studentClasses?.find(studentClass => studentClass?.classID === currentClass?.id), [studentClasses, student, currentClassCheckInSessions])

    useEffect(() => {
        if (currentStudentClass) {
            setStudentCheckIns(currentStudentClass?.studentCheckIns?.map(sc => convertStudentCheckInCacheCodeToCacheObject(sc)) || [])
        }
    }, [currentStudentClass])

    const clickCheckBox = () => {
        setCheckState(!checkstate)
    }

    useEffect(() => {
        if (currentClass && !currentClass?.toggleIndividualStudentStats && userGroup === 'Student') {
            navigationRef?.current?.navigate(ScreenNames.StudentHome)
        }
        rvTeacherClassMainSubscriptionClassID(currentClass?.id)
    }, [currentClass])

    const fontSize = useScaleSizeForPrintMode(20)

    const currentSessionByStudentCheckIn = currentClassCheckInSessions.filter((data) => {
        // This will filtered checkin session by student id
        // and only if on those session the student already checked in
        const studentCheckIn = studentCheckIns.find(value => value.checkInSessionClassSequenceNo === data?.checkInSessionClassSequenceNo)
        if (studentCheckIn) {
            return data
        }
    })

    const checkInSessionsByWeek = groupByWeek(currentSessionByStudentCheckIn as CheckInSession[])

    const handleBack = (patchedGoBack: any) => {

        if (userGroup === UserGroup.Student || userGroup === UserGroup.Admin) {
            patchedGoBack()
            return
        }
        if (previousScreen === ScreenNames.TeacherEditStudent) {
            navigate(ScreenNames.TeacherEditStudent, { studentID: student?.id || "", classID: currentClass?.id || "" })
            return
        }

        navigate(ScreenNames.TeacherStats, { classID: classID })
    }

    if (!student || !currentStudentClass) {
        return null
    }

    const isAggregateView = currentClass?.goMode && userGroup === UserGroup.Teacher
    const Avatar = avatars[student.avatar as keyof typeof avatars]

    return (
        <Page style={styles.page}>
            <ScrollViewPrint style={styles.scrollView}>
                <View style={styles.buttonsContainer}>
                    <BackButton onPress={handleBack} />
                    <ExportToPdfButton iconColor={DecidaColors.Green} />
                </View>
                <TeacherStatsLegends showAllCheckInLegend />
                <View style={styles.mainContainer}>
                    <DefaultText style={[styles.title, { fontSize }]}>
                        {`${student.nickname}'s Check-In History`}
                    </DefaultText>
                    <View style={[styles.avatarContainer, { alignSelf: lowRes ? 'flex-start' : undefined, marginLeft: 20 }]}>
                        <Avatar />
                    </View>
                    <CheckBox checked={checkstate} label={"Reverse check in order"} onChange={clickCheckBox} />
                    {isAggregateView ? (
                        <TeacherStudentIndividualStatsGoMode
                            checkstate={checkstate}
                        />
                    ) : (
                        <WeeklyGraphs
                            checkInSessionsByWeek={checkInSessionsByWeek}
                            checkstate={checkstate}
                            currentStudentClass={currentStudentClass}
                        />
                    )}
                </View>
                <SafeAreaView />
            </ScrollViewPrint>
        </Page>
    )
}
const avatarSize = 80

const styles = StyleSheet.create({
    buttonsContainer: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    page: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    mainContainer: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    avatarContainer: {
        marginTop: 20,
        width: avatarSize,
        height: avatarSize,
    },
    scrollView: {
        width: '100%',
        paddingHorizontal: 10,
    },
})
