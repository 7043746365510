import { Text, View } from 'react-native'
import { AudioHandler } from '../../../common/audio-handler'
import { audio } from '../../../common/audio-imports'
import { DecidaColors } from '../../../../common/decida-colors'
import { DefaultText } from '../../../common/default-text'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import { DefaultTextWithDownArrow } from '../../common/default-text-with-down-arrow'
import { DefaulttextWithRightArrowLeftAlignedDoubleIndentation } from '../../common/default-text-with-right-arrow-left-aligned-double-indentation'
import { DefaulTextWithTipsIcon } from '../../common/default-text-with-tips-icon'
import { DefaultTextWithoutArrows } from '../../common/default-text-without-arrows'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { MusicIcon } from '../../explore/src/music-icon'
import { MusicIconInverted } from '../../explore/src/music-icon-inverted'
import { MusicEmotionWorksheet } from './src/music-emotion-worksheet'
import { MusicImage1 } from './src/music-image1'
import { Worksheets } from '../../../common/worksheets-imports'
import { openUrlWebView } from '../../../web-view/open-url-web-view'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'

export const SwitchesEmotionsSadMuisc: CardTemplate = {
    cardId: "d502fca8-ccdf-41c4-9508-7872b9e2bac1",
    title: 'Music',
    Header: MusicIconInverted,
    Icon: MusicIcon,
    lessonPlan: true,
    Images: () => (
        <>
            <MusicImage1 />
        </>
    ),
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"People who find themselves in negative thought spirals that rob them of focus and motivation."} />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Think of a favourite song that makes you feel good."} />
                <DefaultTextWithDownArrow textdata={"Play that song when you are in a good mood to link happiness with this song."} />
                <DefaultTextWithDownArrow textdata={"Play it over many times to make the link between the song and happiness really strong. Enjoy it."} />
                <DefaultTextWithoutArrows textdata={"When something triggers you, and you feel sad, listen to the song and enjoy the feeling of happiness."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: 'Hearing and smell are some of the strongest neural triggers we possess. Listening to music when you are happy helps the brain associate a particular music piece with the release of happiness chemicals. This music can also trigger nostalgic memories which are also good interrupters of negative thought patterns.',
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Experience the impact of different types of music on emotions and mood.  </DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>

                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 30 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain to students that music is a powerful tool for managing emotions and can influence how we feel based on rhythm, tempo, and melody.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the Switch4Schools emotion wheel on the board, highlighting the range of emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reference the Music switch. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 1: Exploring Music and Emotions (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play music examples from the Music switch (or use your own selection).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to identify and write down the emotions they feel during each piece. Note there is no right or wrong answer to this. It’s okay for people to have different reactions to different pieces of music. This is why its important to find the music that works for you in different situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Debrief either as you go or at the end, encouraging students to share their emotional responses and reasons.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 2: Reflecting on Favourite Songs (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to reflect on their favourite song and how it makes them feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Distribute the worksheet on favourite songs and invite students to share their reflections with the class.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 3: Creating Music Emotions Lists (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to complete the Music Emotions Worksheet, listing different songs or types of music that help them manage specific emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If time permits, suggest and play examples of different music types (e.g., pop, classical, rock, dance/techno, country, jazz).</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Activity 4: Sharing and Debrief (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite students to share their lists and encourage discussion about the emotional connections to music. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Additional questions for debrief (if required) - What song or type of music:</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- makes you feel comforted?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- helps you to concentrate?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- helps you to relax?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- makes you feel like you are on holidays?</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanSubText}>- reminds you of a close friend</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Reinforce that there are no right or wrong answers, and people may have different reactions to the same piece of music.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to use their lists frequently to reinforce the emotional connections.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key points about the impact of music on emotions and the importance of finding music that works for different situations.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students are using the Switch4Schools app independently, direct students to explore the Music Switch for additional music options.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Conclude by emphasising that music is a valuable tool for self expression and emotional wellbeing.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen/pencils </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} link={Worksheets.musicEmotions.uri} text='Music Emotions Worksheet' /> </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• A device to playback music</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>What is music therapy and how does it work? -</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.medicalnewstoday.com/articles/music-therapy#history-and-origins' link='https://www.medicalnewstoday.com/articles/music-therapy#history-and-origins' />
                    </>}
            />

        </>
    ),
    BottomRow: () => (
        <>
            <DefaultText style={{ paddingLeft: 10, color: DecidaColors.Gray }}>
                Example music tracks:
            </DefaultText>
            <View style={{
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                maxWidth: "100%",
            }}>
                <View style={{ flexDirection: 'column', paddingRight: 5, }}>
                    <AudioHandler audioFile={audio.Energetic} audioTitle={"Energetic"} />
                    <AudioHandler audioFile={audio.Cheerful} audioTitle={"Cheerful"} />
                    <AudioHandler audioFile={audio.Pumped} audioTitle={"Pumped"} />
                </View>
                <View style={{ flexDirection: 'column', paddingLeft: 5, }}>
                    <AudioHandler audioFile={audio.Peaceful} audioTitle={"Peaceful"} />
                    <AudioHandler audioFile={audio.Mysterious} audioTitle={"Mysterious"} />
                    <AudioHandler audioFile={audio.Sad} audioTitle={"Sad"} />
                </View>
            </View>
            <DefaulTextWithTipsIcon textdata='Pre-preparing a playlist for different desired moods can also be helpful. ' />
            <DefaulTextWithTipsIcon textdata='Consider music that: ' />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Intuitively causes a smile"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Can energise, or make you feel safe"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Motivates or inspires"} />
            <DefaulttextWithRightArrowLeftAlignedDoubleIndentation filledArrow={true} textdata={"Could even be a motivational speech or scene from a movie."} />

        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Music.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Sad]
}
