// User doesn't exist
const userNotFoundMessage = "Username doesn't exist, please check spelling and try again."
const userNotFoundTitle = "Username not found."
// No verifed email address
const InvalidParameterExceptionMessage = "Please contact your teacher to reset the password for you. Alternatively, please email support@switch4schools.com.au"
const InvalidParameterExceptionTitle = "Unable to use forgot password"
// Verifcation code doesn't match
const CodeMismatchExceptionMessage = "The verification code you have entered does not match the verification code sent to your email address."
const CodeMismatchExceptionTitle = "Verification code does not match"


export const customCognitoErrorMessages = (error: any) => {
    console.log(String(error))
    switch (String(error)) {
        case "UserNotFoundException: Username/client id combination not found.":
            return {
                message: userNotFoundMessage,
                title: userNotFoundTitle
            }
        case "InvalidParameterException: Cannot reset password for the user as there is no registered/verified email or phone_number":
        case "NotAuthorizedException: User password cannot be reset in the current state.":
            return {
                message: InvalidParameterExceptionMessage,
                title: InvalidParameterExceptionTitle
            }
        case "CodeMismatchException: Invalid verification code provided, please try again.":
            return {
                message: CodeMismatchExceptionMessage,
                title: CodeMismatchExceptionTitle
            }
        default:
            return undefined
    }
}