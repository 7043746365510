import { StyleSheet } from 'react-native'
import { DecidaColors } from '../../../common/decida-colors'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../common/const'
import { DefaultText } from '../../common/default-text'
import { Videos } from '../../models'
import { CardColorScheme } from '../common/card-color-scheme'
import { CardTemplate } from '../common/card-template'
import DefaultTextWithCustomLink from '../common/default-text-with-custom-link'
import DefaultTextWithLink from '../common/default-text-with-link'
import { ExtraTipsIcon } from '../common/extra-tips'
import LessonPlan from '../common/lesson-plan'

const introductionHappyVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/1Y5R8K7OKSo",
    title: ""
}

export const SwitchesLessonPlansIntroducingHappy: CardTemplate = {
    cardId: "b79cdee4-0aed-424b-971d-9e95bc6bebe3",
    title: 'Introducing happy',
    Header: () => <ExtraTipsIcon />,
    Icon: () => <></>,
    lessonPlan: true,
    PerfectFor: () => (
        <>
        </>
    ),
    Images: () => (
        <>
        </>
    ),
    CardContent: () => (
        <>
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: ``,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Deepen their understanding of the emotion "happy" by identifying its characteristics, expressing what happiness looks and feels like, and exploring activities that bring them joy.</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration: 30 – 60 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Review of Emotions (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin by briefly reviewing the emotions learned in the previous lesson (happy, anxious, angry, sad, scared, excited).</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to recall what they remember about the emotion "happy" and share examples of when they have felt happy.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Characteristics of Happiness (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the “Feeling Happy” video. [2:33]</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Set up a large chart paper on an easel or wall.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write "Happiness" at the top of the chart and invite the students to brainstorm words or phrases that describe what happiness looks and feels like for them. Write their responses on the chart paper, encouraging them to explain their choices and expand on the ideas shared. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss common characteristics, such as smiling, laughter, feeling warm inside, and having a sense of joy.  </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Expressing Happiness (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Invite the students to express what happiness looks like to them by drawing a picture or using art supplies to create a visual representation of happiness. Encourage them to use colours, shapes, and symbols that they associate with feeling happy.   </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Once they finish their artwork, provide an opportunity for volunteers to share their creations and describe what they depicted.  </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Sharing Happiness Activities (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to share activities or experiences that bring them happiness.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Write their ideas on the chart paper or create a separate list on the board.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss their responses, asking follow-up questions like, "Why does that activity make you happy?" or "How does it feel when you do that?"</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the students to reflect on why different activities make different people happy and emphasise that happiness can be found in various ways.  </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Reflecting on Happiness (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Gather the students back in the circle.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the students to reflect on what they learned about happiness and how it feels to be happy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to share their artwork or describe an activity that brings them joy. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how they can spread happiness by sharing their favourite activities with friends or family.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>Extension Activities:</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Play a game of "Happiness Charades" where students act out activities associated with happiness and classmates guess the activity.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Create a classroom happiness jar where students can write or draw something that made them happy each day.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Encourage students to notice and appreciate moments of happiness in their daily lives, prompting discussions about what made them feel happy.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Read books or sing songs about happiness and discuss the emotions portrayed in the stories or lyrics.</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Complete lessons for the available ‘happy’ switches.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink indent={0} fontSize={DefaultLessonPlanContentFontsize} text='Feeling happy video' link='https://www.youtube.com/watch?v=XX-Cpb7trrI&t=2s' /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Large chart paper and markers</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Art supplies (crayons, coloured pencils, markers)</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Blank paper or drawing sheets.</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>7 Habits to Help Children Be Happy and The Power of Positive Psychology -</DefaultText>
                        <DefaultTextWithLink text='https://betterkids.education/blog/7-habits-to-help-children-be-happy-and-the-power-of-positive-psychology' />
                    </>}
            />
        </>
    ),
    onlyLessonPlan: true,
    type: [],
    time: [],
    action: [],
    category: [],
    level: [],
    people: [],
    place: []
}

const styles = StyleSheet.create({
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
    durationText: {
        marginBottom: 10,
        color: DecidaColors.Gray,
        alignSelf: 'center'
    },
    noIndent: {
        paddingLeft: 0,
    },
})
