export const teacherClassFields = `
  id
  teacherID
  classID
  _version
  _deleted
  _lastChangedAt
  __typename
`

const checkinSessionsFields = `
  id
  createdAt
  updatedAt
  _version
  _deleted
  _lastChangedAt
  __typename
`

export const classFields = `
  id
  name
  schoolID
  emotionIntensityLevel
  toggleRequestForChat
  toggleRequestForChatNotifications
  toggleIndividualStudentStats
  bulkSignUpId
  createdAt
  updatedAt
  primaryContact
  archived
  emailReceivers
  checkInCountYTD
  checkInCountRollingWeekMonday
  checkInCountRollingWeekTuesday
  checkInCountRollingWeekWednesday
  checkInCountRollingWeekThursday
  checkInCountRollingWeekFriday
  checkInCountRollingWeekSaturday
  checkInCountRollingWeekSunday
  activeStudents
  goMode
  _version
  _deleted
  _lastChangedAt
  classClassLoginId
  __typename
`

export const listClassesBySchoolIDCustomQuery = /* GraphQL */ `query ListClassesBySchoolID(
    $schoolID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassesBySchoolID(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${classFields}
        teachers {
            items {
                ${teacherClassFields}
            }
        }
        checkInSessions {
          items {
            ${checkinSessionsFields}
          }
        }
      }
      nextToken
      startedAt
      __typename
    }
  }
  `;

type TeacherClassCustomQuery = {
  __typename: "TeacherClass",
  id: string,
  teacherID: string,
  classID: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
} | null

export type ListClassesBySchoolIDCustomQuery = {
  listClassesBySchoolID?: {
    __typename: "ModelClassConnection",
    items: Array<{
      __typename: "Class",
      id: string,
      name: string,
      schoolID: string,
      emotionIntensityLevel?: number | null,
      toggleRequestForChat?: boolean | null,
      toggleRequestForChatNotifications?: boolean | null,
      toggleIndividualStudentStats?: boolean | null,
      bulkSignUpId?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      primaryContact?: string | null,
      archived?: boolean | null,
      emailReceivers?: Array<string | null> | null,
      lastCheckInSessionClassSequenceNo?: number | null,
      checkInCountYTD?: number | null,
      checkInCountRollingWeekMonday?: number | null,
      checkInCountRollingWeekTuesday?: number | null,
      checkInCountRollingWeekWednesday?: number | null,
      checkInCountRollingWeekThursday?: number | null,
      checkInCountRollingWeekFriday?: number | null,
      checkInCountRollingWeekSaturday?: number | null,
      checkInCountRollingWeekSunday?: number | null,
      activeStudents?: number | null,
      teachers: {
        items: Array<TeacherClassCustomQuery>
      },
      goMode?: boolean | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      classClassLoginId?: string | null,
    } | null>,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};