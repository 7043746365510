import { useReactiveVar } from '@apollo/client'
import Svg, { ClipPath, G, Path, Rect } from 'react-native-svg'
import { rvCard } from '../common/card-color-scheme'


export const TipsIcon = () => {
    const color = useReactiveVar(rvCard)?.colorScheme

    return (
        <Svg viewBox="0 0 55 58.5">
            <ClipPath id="clip-path">
                <Rect id="Rectangle_282" data-name="Rectangle 282" width="55" height="58.5" fill="none" />
            </ClipPath>
            <G id="Group_2905" data-name="Group 2905" transform="translate(0 0)">
                <G id="Group_2904" data-name="Group 2904" transform="translate(0 0)" clipPath="url(#clip-path)">
                    <Path id="Path_3604" data-name="Path 3604" d="M33.258,18.355A14.739,14.739,0,0,0,18.765,33.287a15.109,15.109,0,0,0,1.975,7.481c.28.482.575.933.833,1.339,1.577,2.581,2.989,4.389,2.989,8.353a1.53,1.53,0,0,0,1.449,1.493H40.5a1.53,1.53,0,0,0,1.449-1.493c0-3.963,1.412-5.771,2.989-8.353.2-.341.447-.7.634-1a15.139,15.139,0,0,0,2.174-7.822A14.739,14.739,0,0,0,33.258,18.355Zm0,2.986A11.753,11.753,0,0,1,44.852,33.287a12.018,12.018,0,0,1-1.6,6c-.246.411-.49.788-.714,1.138-1.328,2.187-2.955,4.683-3.328,8.539H27.3c-.374-3.855-2-6.352-3.329-8.539-.306-.479-.62-.994-.872-1.421a12.04,12.04,0,0,1-1.438-5.719A11.753,11.753,0,0,1,33.257,21.341Zm4.189,5.95a1.453,1.453,0,0,0-.747.35l-7.246,5.973a1.557,1.557,0,0,0-.493,1.464A1.505,1.505,0,0,0,30,36.2l3.283.91-3.216,3.313a1.585,1.585,0,0,0-.03,2.131A1.473,1.473,0,0,0,32.1,42.55l5.028-5.18a1.594,1.594,0,0,0-.657-2.5L33.53,34.08l4.982-4.106A1.57,1.57,0,0,0,39,28.2,1.486,1.486,0,0,0,37.447,27.291ZM26.6,54.191a1.5,1.5,0,0,0,.136,2.986H39.78a1.493,1.493,0,0,0,0-2.986Zm2.9,5.973a1.5,1.5,0,0,0,.136,2.986h7.246a1.493,1.493,0,0,0,0-2.986Z" transform="translate(-5.167 -4.651)" fill={color} />
                    <Path id="Path_3605" data-name="Path 3605" d="M1.375,38.1A1.4,1.4,0,0,0,0,39.515a1.351,1.351,0,0,0,1.375,1.418h6.74a1.418,1.418,0,0,0,0-2.835Z" transform="translate(0 -9.654)" fill={color} />
                    <Path id="Path_3606" data-name="Path 3606" d="M10.5,11.2a1.259,1.259,0,0,0-.943.449,1.431,1.431,0,0,0,0,1.942l4.784,4.929a1.33,1.33,0,0,0,1.885,0,1.43,1.43,0,0,0,0-1.943L11.44,11.648A1.152,1.152,0,0,0,10.5,11.2" transform="translate(-2.526 -2.838)" fill={color} />
                    <Path id="Path_3607" data-name="Path 3607" d="M37.477,0A1.4,1.4,0,0,0,36.1,1.417V8.361a1.376,1.376,0,1,0,2.751,0V1.417A1.44,1.44,0,0,0,37.477,0" transform="translate(-9.941 0)" fill={color} />
                    <Path id="Path_3608" data-name="Path 3608" d="M62.578,11.2a1.259,1.259,0,0,0-.943.449l-4.783,4.928a1.43,1.43,0,0,0,0,1.943,1.33,1.33,0,0,0,1.885,0L63.52,13.59a1.431,1.431,0,0,0,0-1.942,1.254,1.254,0,0,0-.943-.449" transform="translate(-15.549 -2.838)" fill={color} />
                    <Path id="Path_3609" data-name="Path 3609" d="M64.278,38.1a1.418,1.418,0,0,0,0,2.835l6.666,0a1.4,1.4,0,0,0,1.376-1.418A1.352,1.352,0,0,0,70.944,38.1Z" transform="translate(-17.32 -9.654)" fill={color} />
                </G>
            </G>
        </Svg>

    )
}