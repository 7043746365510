import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { AudioHandlerOutlinedSmall } from '../../../common/audio-handler-outlined-small'
import { audio } from '../../../common/audio-imports'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import VideoCard from '../../../common/video-card'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { BodyScanHeaderIcon } from './src/body-scan-header-icon'
import { BodyScanIcon } from './src/body-scan-icon'
import { BodyScanLineDrawing } from './src/body-scan-line-drawing'
import SwitchCardVideo from '../../../common/switch-card-video'

const bodyScanVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/BlWo7sqWLNk",
    title: ""
}

export const SwitchesEmotionsAngryBodyScan: CardTemplate = {
    cardId: "95f5b77c-4a5b-4811-9b48-3112f46af9bd",
    title: 'Body scan',
    Header: BodyScanHeaderIcon,
    Icon: BodyScanIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Relaxing yourself in times of heightened anger."} />
        </>
    ),
    Images: () => (
        <>
            <BodyScanLineDrawing />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Find a quiet place to sit."} />
                <DefaulttextWithRightArrow textdata="Listen to the recording (4:05)" />
                <View style={{ marginLeft: 5 }}>
                    <AudioHandlerOutlinedSmall audioFile={audio.BodyScanMediation} audioTitle='Meditation' color={CardColorScheme.ANGRY} />
                </View>
                <DefaulttextWithRightArrow textdata={"If you can still feel tension in your body, feel free to complete the activity again or try another switch."} />
            </View>
        </>
    ),
    BottomRow: () => (
        <>
            <View>
                <DefaultTextWithoutArrowsBold textdata='Script' />
                <DefaultTextWithoutArrowsItallic textdata={`
Find a comfortable sitting or lying position. Gently close your eyes if you're comfortable or keep a soft gaze if you prefer. Take a deep breath in through your nose, and exhale through your mouth, letting go of any tension. 
Now, bring your attention to your feet. Feel the weight of your feet on the floor. Notice any sensations in your toes, the arches, and the heels. Take a moment to acknowledge any sensations you feel here. 
Slowly move your awareness up to your calves and shins. Feel the muscles, the bones, and each of the hairs covering the skin. Acknowledge any saw or tight areas, release that tension as we move up. 
If your mind wanders, gently guide it back to the sensations of your body. 
Now, shift your focus to your knees and thighs. Sense any feelings of pressure, warmth, or coolness. Let your awareness travel to your hips and pelvis, feeling the support of the surface beneath you. 
Move your attention to your lower back and abdomen. Feel your breath as it naturally rises and falls. Notice any movements or sensations with a gentle curiosity. 
Bring your awareness to your chest and upper back. Feel the expansion and contraction with each breath. 
Shift your focus to your hands. Feel the strength in your palms and fingers. Allow your awareness to travel up your arms to your elbows and upper arms. Release any tension you might be holding. 
Finally, bring your awareness to your head. Feel the weight of your head on your neck and shoulders. Notice any sensations on your scalp, your forehead, your temples. 
Take a deep, full breath in, and as you exhale, bring your attention back to your entire body. Feel the unity of your body as a whole. 
When you're ready, gently open your eyes if they were closed. Take a moment to reorient yourself to the room. `} />
            </View>

        </>

    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: `Mindfulness allows us to become attuned to bodily sensations, which often carry signals of anger. This heightened self awareness fosters the identification and subsequent release of pent up tension. Regular practice can enhance anger management skills, facilitating a calmer and more composed response to triggering situations.`,
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Understand the concept of a mindfulness body scan, its benefits, and how to practice it. They will have practiced a basic body scan meditation technique.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• List the steps required to address an unresolved issue with a focus on regulating emotion.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Demonstrate self awareness and empathy.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 25 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction: (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by asking students if they've heard of mindfulness and if anyone has practiced it before.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept of a mindfulness body scan as a technique to enhance self-awareness, relaxation, and stress reduction.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Benefits of Mindfulness Body Scan (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Present the benefits of a mindfulness body scan using bullet points on the board. Emphasise benefits such as increased relaxation, improved body mind connection, and reduced tension. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Explanation of Mindfulness Body Scan: (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain the basic principle of a mindfulness body scan: directing focused attention to different parts of the body to observe physical sensations and emotions without judgment.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss how this practice promotes present-moment awareness.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice: (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the following video and lead the students through the guided mindfulness body scan:</DefaultText>
                        <SwitchCardVideo video={bodyScanVideo} />
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask students to sit or lie down comfortably with their eyes closed.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Play the mindfulness meditation video.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Discussion: (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Initiate a class discussion by asking students to share their experiences during the body scan.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss any challenges they encountered while trying to stay focused or remain non-judgmental.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to reflect on how the body scan affected their awareness of physical sensations and their overall state of mind.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Real-Life Application: (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss scenarios where students might apply mindfulness body scan in their daily lives, such as before an important event, when feeling stressed, or when seeking relaxation.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to integrate body scan practice into their routines and to explore its effects on their stress levels and overall wellbeing.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ If students have their own devices, ask them to find the Body Scan Switch in the Switch4Schools app.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Summarise the key concepts learned and reinforce the benefits of meditation for dialing down the intensity of emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to reflect on how they can apply the Body Scan switch in their daily lives.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Optional Homework/Extension Activity: Assign students the task of practicing a mindfulness body scan for a few minutes each day for the next week.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Whiteboard and markers </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Projector or screen for displaying visuals</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Comfortable seating arrangement</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Headspace: Body scan meditation to reduce stress - </DefaultText>
                        <DefaultTextWithLink text='https://www.headspace.com/meditation/body-scan' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to Do a Body Scan Meditation (and Why You Should)</DefaultText>
                        <DefaultTextWithLink text='https://www.healthline.com/health/body-scan-meditation' />

                        <DefaultText style={CommonStyles.lessonPlanText}>5 Reasons to Do a Body Scan Meditation </DefaultText>
                        <DefaultTextWithLink text='https://draxe.com/health/body-scan-meditation/' />
                    </>}
            />
        </>
    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Body+scan+2.m4a",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Seven],
    emotion: [CardFilterEmotionType.Angry]
}

const styles = StyleSheet.create({
    centered: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    lessonPlanText: {
        paddingLeft: 40,
        color: DecidaColors.AppleSystemGray2Dark,
        paddingVertical: 5,
    },
    mainLessonText: {
        paddingLeft: 10,
        color: DecidaColors.Gray,
        fontWeight: 'normal'
    },
})
