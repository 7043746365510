import { StyleSheet, View } from 'react-native'
import { UniversalImage } from '../../../common/universal-image'
import { useResponsive } from '../../../common/use-responsive'

const SvgImage = require('./spelling-elimination-header.png')

export const SpellingEliminationHeader = () => {
    const { isLargeScreen } = useResponsive()
    return (
        <View style={isLargeScreen ? styles.pngHeaderIcon : styles.pngHeaderIconMobile}>
            <UniversalImage SvgImage={SvgImage} />
        </View>)
}


const styles = StyleSheet.create({
    pngHeaderIcon: {
        padding: 30,
        width: '100%',
        height: '100%'
    }, 
    pngHeaderIconMobile: {
        padding: 10,
        width: '100%',
        height: '100%'
    }, 
})
