import { View, Text } from 'react-native'
import React from 'react'
import { IconHeaderUserLoginAvatar } from '../icon-header-user-login-avatar'

const Default = () => {
    return (
        <View style={{ justifyContent: 'center' }}>
            <IconHeaderUserLoginAvatar width={"100%"} />
        </View>
    )
}

export default Default