import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan, Line } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconAnxious5Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 5)
    }

    const onPressLevel2 = {
        [prop]: () => onPress(2 / 5)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(3 / 5)
    }

    const onPressLevel4 = {
        [prop]: () => onPress(4 / 5)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg viewBox="0 0 812.372 545.241">
            <Line y1="134.83" x2="203.429" transform="translate(1.934 398.858)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M5415.963,1188.016l-25.384,6.113,9.865,14.884Z" transform="translate(-5198.735 -797.023)" fill="#5c5d5d" />
            <Line y1="85.016" x2="127.921" transform="translate(225.664 301.791)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M5564.175,1090.934l-25.376,6.145,9.884,14.872Z" transform="translate(-5198.735 -797.023)" fill="#5c5d5d" />
            <Line y1="84.948" x2="125.927" transform="translate(375.368 204.062)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M5711.831,993.124l-25.333,6.319,9.986,14.8Z" transform="translate(-5198.735 -797.023)" fill="#5c5d5d" />
            <Line y1="84.702" x2="124.603" transform="translate(527.497 104.847)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M5862.606,893.866l-25.311,6.41,10.039,14.768Z" transform="translate(-5198.735 -797.023)" fill="#5c5d5d" />
            <Line y1="80.93" x2="123.915" transform="translate(676.538 7.783)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M6011.1,797.023l-25.421,5.941,9.764,14.951,15.66-20.892Z" transform="translate(-5198.735 -797.023)" fill="#5c5d5d" />
            <G {...onPressLevel1} transform="translate(-47 -224)">
                <Path d="M5206.331,1341.757l208.958-.868,1.173-138.937Z" transform="translate(-5151.735 -573.022)" fill="#eee4af" />
                <Text transform="translate(105.896 754.541)" fill="#313133" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Concerned</TSpan></Text>
            </G>
            <G {...onPressLevel2} transform="translate(-47 -224)">
                <Path d="M5427.344,1194.067l138-90-.671,236.529-139.277.111Z" transform="translate(-5151.735 -573.022)" fill="#e2d37e" />
                <Text transform="translate(280.489 684.8)" fill="#313133" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Apprehen-</TSpan></Text>
                <Text transform="translate(322.324 709.8)" fill="#313133" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">sive</TSpan></Text>
            </G>
            <G {...onPressLevel3} transform="translate(-47 -224)">
                <Path d="M5575.735,1098.666l138.1-93.6-1.173,336.028-137.712.112Z" transform="translate(-5151.735 -573.022)" fill="#d4c14f" />
                <Text transform="translate(442.204 640.198)" fill="#313133" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Worried</TSpan></Text>
            </G>
            <G {...onPressLevel4} transform="translate(-47 -224)">
                <Path d="M5723.626,1000.266l139.15-93-.67,433.83-139.277.11Z" transform="translate(-5151.735 -573.022)" fill="#cbb52b" />
                <Text transform="translate(604.586 595.597)" fill="#ffffff" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Over-</TSpan></Text>
                <Text transform="translate(584.586 620.597)" fill="#ffffff" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">whelmed</TSpan></Text>

            </G>
            <G {...onPressLevel5} transform="translate(-47 -224)">
                <Path d="M5873.18,900.666l137.426-90.444-.517,530.872-137.712.112Z" transform="translate(-5151.735 -573.022)" fill="#c0aa1b" />
                <Text transform="translate(730.358 523.422)" fill="#ffffff" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Panicked</TSpan></Text>
            </G>
        </Svg>
    )
}