import { View } from 'react-native'
import { DefaultTextWithDownArrow } from '../../../common/default-text-with-down-arrow'
import { DefaultTextWithoutArrows } from '../../../common/default-text-without-arrows'
import { commonCardStyles } from '../../../common/emotion-card-image-sizes'
import { CardActionType, CardCategoryType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../../common/card-template'
import { CardColorScheme } from '../../../common/card-color-scheme'
import { SleepIcon } from '../../icons/sleep-icon'
import { DefaultTextWithExtraTipIcon } from '../../../common/default-text-with-extra-tip-icon'
import { DefaultTextWithoutArrowsBold } from '../../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../../common/default-text-without-arrows-itallic'
import { SleepIconInverted } from '../../icons/sleep-icon-inverted'
import { DefaulTextWithTipsIcon } from '../../../common/default-text-with-tips-icon'

export const SwitchesActivitiesHealthyHabitsSleep: CardTemplate = {
    cardId: "b31d8a38-64ad-442b-a6a2-d457343583bc",
    title: 'Sleep',
    Header: SleepIconInverted,
    Icon: SleepIcon,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Dealing with insomnia or creating a healthy sleep routine."} />
        </>
    ),
    BottomRow: () => (
        <>
            <DefaulTextWithTipsIcon textdata={`If experiencing an unusually late night, it can be better to get up at the same time as always, but then have an afternoon nap rather than sleep in. Sleeping in can disrupt your natural circadian rhythm, making it difficult to go to sleep the next night and contributing to a feeling of insomnia.`} />
            <DefaulTextWithTipsIcon textdata={"Try to avoid alarm clocks that have a white backlight. Red light can be less disruptive as white light can be confused by the brain as the sun rising. "} />
            <DefaulTextWithTipsIcon textdata={"If the sun naturally rises at the time you need to wake up, try leaving curtains open and letting the natural sunlight wake you up."} />
            <DefaulTextWithTipsIcon textdata={"This is particularly good if you are traveling across time zones. Make sure you wake up in the new time zone at the same 'clock' time - this will more quickly recalibrate you to the new time zone."} />
        </>

    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaultTextWithDownArrow textdata={"Learn to go to bed and get up around the same time every day. Try not to 'sleep in'. Getting up at the same time each morning is vital."} />
                <DefaultTextWithDownArrow textdata={"Make your bedroom a sleep haven. Turn the lights down as soon as you get into bed and read a book using a bedside light (rather then reading off a screen)."} />
                <DefaultTextWithDownArrow textdata={"Keep TVs and devices out of your bedroom. Charge your devices overnight in another room and get a separate alarm clock if you need it. "} />
                <DefaultTextWithoutArrows textdata={"Try some simple meditation, like closing your eyes for 5–10 minutes and focusing on taking deep, slow breaths."} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.HEALTHY_HABITS,
    whyDoesItWorkText: 'While you sleep, your body works to support healthy brain function and maintain your physical health. And for children and young people, sleep is how their bodies and minds grow and develop.  When you do not get enough sleep, you feel tired, you find it hard to concentrate and remember things and you may be grumpy. Lack of sleep can also impair your judgement and impact your physical coordination.',
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/Sleep.mp3",
    type: [CardType.Activity],
    time: [CardTimeType.Long],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [CardCategoryType.HEALTHY_HABITS],
    action: [CardActionType.Action],
    level: []
}

