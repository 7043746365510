import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { School } from '../../common/API'
import { List } from '../common/list'
import { ScreenNames } from '../common/screen-names'
import { AdminStackNavigatorParamList } from './admin-route-param-types'
import { isDefined } from '../../common/is-defined'

interface Props {
    school: School
}

export const AdminSchoolAdminsList = ({ school }: Props) => {
    const { navigate } = useNavigation<StackNavigationProp<AdminStackNavigatorParamList>>()

    const addSchoolAdmin = () => {
        navigate(ScreenNames.AdminSchoolAdmin, { id: undefined, schoolID: school.id })
    }

    const editSchoolAdmin = (id: string) => {
        navigate(ScreenNames.AdminSchoolAdmin, { id, schoolID: school.id })
    }

    return (
        <>
            <List
                type='accordion'
                title='School Admins'
                items={school.schoolAdmins?.items.filter(isDefined).map(a => {
                    const isCoordinator = school.coordinatorID === a.id
                    return ({
                        id: a.id,
                        name: isCoordinator ? `${a.cognitoUsername} (Key coordinator)` : a.cognitoUsername
                    })
                }) || []}
                add={addSchoolAdmin}
                edit={editSchoolAdmin}
            />
        </>
    )
}