import { AntDesign } from '@expo/vector-icons'
import { useCallback, useEffect, useState } from 'react'
import { ActivityIndicator, DimensionValue, Platform, Pressable, StyleSheet, View } from 'react-native'
import { WebView } from 'react-native-webview'
import { thumbnails } from '../icon/thumbnails/thumbnails'
import { Videos } from '../../common/API'
import { DecidaColors } from '../../common/decida-colors'
import DefaultModal from './default-modal'
import { DefaultText } from './default-text'
import { UniversalImage } from './universal-image'
import { openUrlWebView } from '../web-view/open-url-web-view'

type VideoWebViewProps = {
    width: number | DimensionValue,
    height: number | DimensionValue,
    video: Videos,
}
const VideoWebview = ({ width = 500, height = 500, video }: VideoWebViewProps) => {
    const [isWebviewShow, setIsWebviewShow] = useState(false)
    const { url, image, isExternal } = video
    const [play, setPlay] = useState(false)
    const isYoutube = url.includes('www.youtube.com/embed/')
    const isVimeo = url.includes('vimeo')
    const isTedVideo = url.includes('embed.ted.com')
    const youtubeVideoId = url.split("embed/")[1]
    const vimeoVideoId = url.split("video/")[1]
    const vimeoExternalLink = `https://vimeo.com/${vimeoVideoId}`
    const autoPlayUrl = `${url}?autoplay=1`
    const thumbnailUrl = video.thumbnailUrl ? video.thumbnailUrl :(video.youtubePlaylistThumbnailUrlVideoId || isYoutube) ? `https://img.youtube.com/vi/${video.youtubePlaylistThumbnailUrlVideoId ?? youtubeVideoId}/0.jpg` : ""
    const vimeoThumbnailUrl = isVimeo ? `https://vumbnail.com/${vimeoVideoId}.jpg` : ""

    useEffect(() => {
        if (video.autoplay) {
            togglePlay()
        }
    }, [])

    const togglePlay = useCallback(() => {
        setPlay(!play)
    }, [])

    const handleExternalLink = () => {
        const externalUrl = isVimeo ? vimeoExternalLink : video.url
        if (Platform.OS === 'web') {
            openUrlWebView(externalUrl)
            return
        }

        toggleWebView()
    }

    const toggleWebView = () => {
        setIsWebviewShow(prev => !prev)
    }
    const Thumbnail: any = image ? thumbnails[image as keyof typeof thumbnails] : null

    return (
        <>
            {!play && !isTedVideo ? (
                <Pressable onPress={isExternal ? handleExternalLink : togglePlay}>
                    {image ? (
                        <View style={[styles.thumbnailContainer, { width, height }]}>
                            <Thumbnail />
                        </View>
                    ) : (vimeoThumbnailUrl || thumbnailUrl) ? (
                        <View>
                            <UniversalImage width={width} height={height} SvgImage={vimeoThumbnailUrl || thumbnailUrl} resizeMode='cover' />
                            {!play && vimeoThumbnailUrl && (
                                <View style={styles.playIconContainer}>
                                    <AntDesign name="playcircleo" size={50} color={DecidaColors.White} />
                                </View>
                            )}
                        </View>
                    ) : (
                        <View style={[styles.noThumbnailContainer, { width, height, }]}>
                            <View style={{ width, height, alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                                <DefaultText style={[styles.noThumbnailText]}>Thumbnail is not available</DefaultText>
                            </View>
                        </View>
                    )}
                </Pressable>
            ) : (
                <iframe src={autoPlayUrl} height={height as number} width={width as number} allowFullScreen allow="autoplay" />
            )}
                 
            {isWebviewShow && (
                <DefaultModal contentStyle={{ flex: 1, width: '100%', }} onClose={toggleWebView}>
                    <WebView
                        style={{ flex: 1, }}
                        source={{ uri: video.url }}
                        startInLoadingState={true}
                        renderLoading={() => <ActivityIndicator color={DecidaColors.Green} size="large" />}
                    />
                </DefaultModal>
            )}

        </>
    )
}

const styles = StyleSheet.create({
    noThumbnailContainer: {
        flex: 1,
        backgroundColor: DecidaColors.White,
        paddingHorizontal: 10,
        borderWidth: 1,
        borderColor: DecidaColors.LightGray
    },
    noThumbnailText: {
        textAlign: 'center',
        fontSize: 16,
        color: DecidaColors.GrayText
    },
    thumbnailContainer: {
        flex: 1,
        backgroundColor: DecidaColors.White,
    },
    defaultThumbnail: {
        paddingHorizontal: 20
    },
    playIconContainer: {
        position: 'absolute',
        margin: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1
    }
})

export default VideoWebview