import { makeVar, useReactiveVar } from '@apollo/client'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { API } from 'aws-amplify'
import _ from 'lodash'
import { useCallback, useState } from 'react'
import { ActivityIndicator, StyleSheet, TextInput, View, ViewStyle } from 'react-native'
import { DecidaColors } from '../../common/decida-colors'
import { LoginStackNavigatorParamList } from '../login/login-route-param-types'
import { usernameTaken } from '../login/username-taken-validator'
import { CommonStyles } from './const'
import { DefaultText } from './default-text'
import { emailValidator } from './email-validator'
import { Link } from './link'
import { ScreenNames } from './screen-names'

type Props = {
    value: string
    onChangeText: (data: string) => void
    setEmailExists: (data: boolean) => void
    emailExists: boolean
    containerStyle?: ViewStyle
    bulkSignUpId?: string
    schoolID?: string
    showErrorMessage?: boolean
}

export const rvInputEmailStudentsLoading = makeVar(false)

const InputEmailStudents = ({ value, onChangeText, containerStyle, setEmailExists, emailExists, bulkSignUpId, schoolID, showErrorMessage = true }: Props) => {
    const isLoading = useReactiveVar(rvInputEmailStudentsLoading)
    const { navigate } = useNavigation<NavigationProp<LoginStackNavigatorParamList>>()
    const [emailIsValid, setEmailIsValid] = useState(true)

    const handleEmailChange = (value: string) => {
        rvInputEmailStudentsLoading(false)
        handleEmailValidation(value);
        onChangeText(value);
    };

    const handleEmailValidation = useCallback(_.debounce(async (email: string) => {
        setEmailExists(await checkEmailAvailability(email))

        const isValid = emailValidator(email)
        setEmailIsValid(isValid === "Email is not valid." ? false : true)
    }, 500), [schoolID, bulkSignUpId]);


    const checkEmailAvailability = async (email: string): Promise<boolean> => {
        let emailAvailable = false
        if ((bulkSignUpId || schoolID) && email) {
            rvInputEmailStudentsLoading(true)
            const studentEmaiLCheck = await API.post("AdminQueries", "/checkStudentEmailAvailibility", {
                headers: {
                    'content-type': 'application/json',
                },
                body: {
                    bulkSignUpId,
                    schoolID,
                    email
                },
            }).finally(() => {
                rvInputEmailStudentsLoading(false)
            })

            if (studentEmaiLCheck) {
                // student table check results
                emailAvailable = true
            } else {
                // check cognito again if fails
                emailAvailable = await usernameTaken(email)
            }

        }

        return emailAvailable
    }

    const navigationToLogin = () => navigate(ScreenNames.Login)

    return (
        <View style={[styles.container, containerStyle]}>
            <DefaultText children={"Email"} style={styles.heading} />
            <View>
                <TextInput
                    style={CommonStyles.textInput}
                    autoCapitalize='none'
                    autoComplete='email'
                    autoCorrect={false}
                    placeholder='Email (optional)'
                    value={value || ''}
                    onChangeText={handleEmailChange}
                />
                {
                    isLoading && (
                        <ActivityIndicator size="small" color={DecidaColors.Gray} style={styles.loader} />
                    )
                }
            </View>
            <View style={styles.errorMessageContainer}>
                {
                    emailExists && showErrorMessage && (
                        <DefaultText style={{ color: DecidaColors.Red }}>An account is already created with this email, please <Link onPress={navigationToLogin}>SIGN IN</Link>. If you have forgotten your nickname, please talk to your teacher.</DefaultText>
                    )
                }
                {
                    !emailIsValid && (
                        <DefaultText style={{ color: DecidaColors.Red }}>Invalid email address</DefaultText>
                    )
                }
            </View>
        </View>
    )
}

export default InputEmailStudents

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        justifyContent: "flex-start",
    },
    heading: {
        fontSize: 17,
        fontWeight: 'bold',
        paddingTop: 20,

    },
    errorMessageContainer: {
        width: 330,
        textAlign: 'justify'
    },
    loader: {
        position: 'absolute',
        right: 10,
        top: 16,
    }
})