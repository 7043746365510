import { useMutation } from "@apollo/client"
import { Feather } from '@expo/vector-icons'
import { API } from "aws-amplify"
import gql from "graphql-tag"
import { StyleSheet, TouchableOpacity, View, useWindowDimensions } from "react-native"
import { DecidaColors } from "../../common/decida-colors"
import { generatePassword } from "../../common/generate-password"
import { deleteTeacherClass } from "../../common/graphql/mutations"
import { DefaultButton, DefaultButtonTypes } from "../common/default-button"
import { DefaultText } from "../common/default-text"
import { getApiHeaders } from "../common/get-api-header"
import { getRoleByValue } from "../common/input-search-teacher-role-component"
import { rvIsLoading } from "../common/loading"
import { showAlert, showGenericErrorAlert } from "../common/universal-alert"
import UserEmailText from "../common/user-email-text"
import { GetSchoolQueryResponseClass, GetSchoolQueryResponseTeacher, GetSchoolQueryResponseTeacherClass } from "../custom-graphql/queries/school-admin-custom-queries/school-admin-get-school-campuses-and-groups-query"
import SchoolAdminButtonResetPasswordTeacher from "./school-admin-button-reset-password-teacher"
import { SchoolAdminTableEditComponent, SchoolAdminTableSetEditComponent } from "./school-admin-const"
import { SchoolAdminEditExistingClassTeacher } from "./school-admin-edit-existing-class-teacher"

type Props = {
    currentClass: GetSchoolQueryResponseClass,
    teacher: GetSchoolQueryResponseTeacher,
    teacherClass: GetSchoolQueryResponseTeacherClass,
    editComponent: SchoolAdminTableEditComponent,
    setEditComponent: SchoolAdminTableSetEditComponent,
}

export const SchoolAdminClassTeacherRow = ({
    currentClass,
    teacher,
    teacherClass,
    editComponent,
    setEditComponent,
}: Props) => {
    const [removeTeacherClassMutation] = useMutation(gql`${deleteTeacherClass}`);

    const handleRemoveTeacher = async () => {
        showAlert({
            title: `Are you sure you want to remove ${teacher.firstName} ${teacher.lastName} from this class?`,
            message: 'This action cannot be undone.',
            leftButtonText: 'Cancel',
            rightButtonText: 'Delete',
            onRightButtonPress: async () => {
                try {
                    await removeTeacherClassMutation({
                        variables: {
                            input: {
                                id: teacherClass.id,
                                _version: teacherClass._version
                            }
                        }
                    })
                    setEditComponent(null)
                } catch {
                    showGenericErrorAlert()
                }
            }
        })
    }

    const handlePressEditTeacher = () => {
        setEditComponent({
            class: currentClass.id,
            teacher: teacher.id,
        })
    }

    return (
        <View>
            {
                editComponent?.class === currentClass.id && editComponent?.teacher === teacher.id ? (
                    <SchoolAdminEditExistingClassTeacher currentClass={currentClass} currentTeacher={teacher} onRemoveTeacher={handleRemoveTeacher} setEditComponent={setEditComponent} />
                ) : (
                    <View style={styles.teacherDetailsWrapper}>
                        <View style={styles.dataContainer}>
                            <DefaultText style={styles.tableText}>{teacher.firstName}</DefaultText>
                            <UserEmailText email={teacher.email} />
                        </View>
                        <View style={[styles.dataContainer]}>
                            <DefaultText style={styles.teacherNameText}>{teacher.lastName}</DefaultText>
                            <DefaultText style={styles.roleText}>{getRoleByValue(teacher?.role)}</DefaultText>
                        </View>
                        <View style={[styles.dataContainer, styles.resetPasswordButtonWrapper]}>
                            <SchoolAdminButtonResetPasswordTeacher teacher={teacher as GetSchoolQueryResponseTeacher} />
                            {
                                editComponent?.class === currentClass.id && (
                                    <TouchableOpacity onPress={handlePressEditTeacher}>
                                        <Feather name='edit' size={30} color={DecidaColors.Green} />
                                    </TouchableOpacity>
                                )
                            }
                            <DefaultButton type={DefaultButtonTypes.Small} style={{ backgroundColor: DecidaColors.Red, paddingHorizontal: 10, marginRight: 5 }} onPress={handleRemoveTeacher}>
                                <View style={styles.minusIcon} />
                            </DefaultButton>
                        </View>
                    </View>
                )
            }
        </View>
    )
}

const styles = StyleSheet.create({
    teacherDetailsWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    dataContainer: {
        width: '100%',
        height: '100%',
        flex: 1,
        marginRight: 5,
    },
    tableText: {
        fontSize: 16,
        color: DecidaColors.AppleSystemGray2Dark,
    },
    teacherNameText: {
        fontSize: 18,
        fontWeight: '700',
        color: DecidaColors.AppleSystemGray2Dark,
    },
    roleText: {
        fontSize: 14,
        fontStyle: 'italic',
        color: DecidaColors.Black,
    },
    resetPasswordButtonWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexWrap: 'wrap'
    },
    minusIcon: {
        width: 15,
        height: 3,
        backgroundColor: DecidaColors.White,
    },
})