import { StyleSheet, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { DecidaColors } from "../../common/decida-colors";
import { ProgressDot } from '../common/progress-dot';

interface Props {
    step: number
}

export const StudentJoinClassBreadCrumbs = ({ step = 1 }: Props) => {

    return (
        <View style={styles.mainContainer}>
            <View style={styles.stepper}>
                <MaterialCommunityIcons name={step >= 1 ? 'numeric-1-circle' : 'numeric-1-circle-outline'} size={38} color={DecidaColors.Green} />
                <ProgressDot completedStep={step >= 2} />
                <MaterialCommunityIcons name={step >= 2 ? 'numeric-2-circle' : 'numeric-2-circle-outline'} size={38} color={DecidaColors.Green} />
                <ProgressDot completedStep={step >= 3} />
                <MaterialCommunityIcons name={step >= 3 ? 'numeric-3-circle' : 'numeric-3-circle-outline'} size={38} color={DecidaColors.Green} />
            </View>
        </View>

    )
}

const styles = StyleSheet.create({

    mainContainer: {
        display: 'flex',
        padding: 10,
    },
    stepper: {
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center'
    },
})

