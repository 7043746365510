import { gql, useMutation } from '@apollo/client'
import { Class, SetRequestForChatForGoClassMutation, SetRequestForChatForGoClassMutationVariables, StudentCheckIn, UpdateStudentCheckInMutation, UpdateStudentCheckInMutationVariables } from '../../common/API'
import { setRequestForChatForGoClass, updateStudentCheckIn } from '../../common/graphql/mutations'
import { useEffect, useState } from 'react';
import { StudentAndStudentClassAndStudentCheckIn } from '../teacher/teacher-stats-last-period-and-requested-chat';

export const useClearRequestForChat = ({ clazz }: { clazz?: Class | null }) => {
  const [updateStudentCheckInMutation, { loading: updateCheckInLoading }] = useMutation<UpdateStudentCheckInMutation, UpdateStudentCheckInMutationVariables>(gql`${updateStudentCheckIn}`, {})
  const [setRequestForChatForGoClassMutation, { loading: setRequestForChatLoading }] = useMutation<SetRequestForChatForGoClassMutation, SetRequestForChatForGoClassMutationVariables>(gql`${setRequestForChatForGoClass}`, {})
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(updateCheckInLoading || setRequestForChatLoading);
    setIsError(false);
  }, [updateCheckInLoading, setRequestForChatLoading]);

  const handleClearRequestForChat = (studentCheckInDataSet: StudentAndStudentClassAndStudentCheckIn) => {
    const { classID, studentID } = studentCheckInDataSet?.student?.studentClass!;
    const studentCheckIn = studentCheckInDataSet?.studentCheckIn as StudentCheckIn;

    if (clazz?.goMode) {
      setRequestForChatForGoClassMutation({
        variables: {
          input: {
            classID,
            studentID,
            requestForChat: studentCheckIn?.requestForChat || null,
            chatCleared: true
          }
        }
      }).catch(() => setIsError(true));
      return;
    }

    updateStudentCheckInMutation({
      variables: {
        input: {
          id: studentCheckIn?.id,
          chatCleared: true,
          _version: studentCheckIn._version
        }
      }
    }).catch(() => setIsError(true));
  }

  return { handleClearRequestForChat, isLoading, isError }
}
