import { View } from 'react-native'
import { UniversalImage } from '../../../common/universal-image'

const SvgImage = require('./stress-balls-icon-inverted.svg')

export const StressBallIconInverted = () => (
    <View style={{ paddingRight: 10, height: '100%', width: '100%' }}>
        <UniversalImage SvgImage={SvgImage} />
    </View>
)
