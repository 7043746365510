
import { useReactiveVar } from '@apollo/client'
import { useNavigation } from '@react-navigation/core'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { Dimensions, SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { rvNetInfo, rvTeacherAbsenceMode } from '../../common/common-state'
import { DecidaColors } from '../../../common/decida-colors'
import { DefaultText } from '../../common/default-text'
import { ScreenNames } from '../../common/screen-names'
import { showAbsenceWarnAlert, showOfflineAlert } from '../../common/universal-alert'
import { IconHeaderDivider } from '../../icon/icon-header-divider'
import { IconSwitch4SchoolsEW } from '../../icon/icon-switch-4-schools-ew'
import { TeacherDrawerNavigatorPamarList } from '../../teacher/teacher-route-param-types'
import { rvSwitchesScreenState } from '../common/current-screen-state'
import { rvPrintMode } from '../../common/export-to-pdf-button'


const windowWidth = Dimensions.get('window').width;

interface Props {
    currentScreen?: SwitchesScreens
}

export enum SwitchesScreens {
    EXPLOREALL,
    TOOLS,
    HOME,
    ACTIVITIES,
    MEMES,
}


export const SwitchesBottomNavigation = ({ currentScreen }: Props) => {


    const absenceMode = useReactiveVar(rvTeacherAbsenceMode)
    const netInfoState = useReactiveVar(rvNetInfo)
    const { navigate } = useNavigation<DrawerNavigationProp<TeacherDrawerNavigatorPamarList>>()
    const isPrintMode = useReactiveVar(rvPrintMode)

    const navigateWithAbsenceModeWarning = (type: SwitchesScreens) => () => {

        let next: () => void
        switch (type) {
            case SwitchesScreens.EXPLOREALL:
                next = navigateToExploreAll
                break;
            case SwitchesScreens.HOME:
                next = navigateToSwitches
                break;
            case SwitchesScreens.MEMES:
                if (netInfoState?.isInternetReachable === false) return showOfflineAlert()
                next = navigateToUnderConstruction
                break;
            case SwitchesScreens.ACTIVITIES:
                next = navigateToActivies
                break;
            case SwitchesScreens.TOOLS:
                next = navigateToTools
                break;

            default:
                next = () => {
                    console.log("Missing absence validation type")
                }
                break;
        }
        if (absenceMode) {
            showAbsenceWarnAlert({
                onLeftButtonPress: async () => {
                    rvTeacherAbsenceMode(false)
                    next()
                }
            })
            return
        }

        next()
    }

    const navigateToExploreAll = () => {
        rvSwitchesScreenState(SwitchesScreens.EXPLOREALL)
        navigate(ScreenNames.SwitchesExploreAllMain)
        navigate(ScreenNames.SwitchesExploreAll)
    }
    const navigateToSwitches = () => {
        rvSwitchesScreenState(SwitchesScreens.HOME)
        navigate(ScreenNames.SwitchesHomeMain)
        navigate(ScreenNames.SwitchesHome)
    }

    const navigateToUnderConstruction = () => {
        rvSwitchesScreenState(SwitchesScreens.MEMES)
        navigate(ScreenNames.SwitchesMemes)
    }

    const navigateToActivies = () => {
        rvSwitchesScreenState(SwitchesScreens.ACTIVITIES)
        navigate(ScreenNames.SwitchesActivityMain)
        navigate(ScreenNames.SwitchesActiviesDisplayAll)
    }

    const navigateToTools = () => {
        rvSwitchesScreenState(SwitchesScreens.TOOLS)
        navigate(ScreenNames.SwitchesToolsMain)
        navigate(ScreenNames.SwitchesToolsDisplayAll)
    }


    return (
        <SafeAreaView style={[styles.container, { display: isPrintMode ? 'none' : undefined }]}>
            <TouchableOpacity onPress={navigateWithAbsenceModeWarning(SwitchesScreens.EXPLOREALL)} style={currentScreen === SwitchesScreens.EXPLOREALL ? styles.block : {}}>
                <DefaultText style={styles.textstyle}>
                    Explore
                </DefaultText>
            </TouchableOpacity>
            <IconHeaderDivider strokecolor={DecidaColors.Gray} />
            <TouchableOpacity onPress={navigateWithAbsenceModeWarning(SwitchesScreens.TOOLS)} style={currentScreen === SwitchesScreens.TOOLS ? styles.block : {}}>
                <DefaultText style={styles.textstyle}>
                    Tools
                </DefaultText>
            </TouchableOpacity>
            <IconHeaderDivider strokecolor={DecidaColors.Gray} />
            <TouchableOpacity>
                <TouchableOpacity style={currentScreen === SwitchesScreens.HOME ? styles.icon : styles.icon} onPress={navigateWithAbsenceModeWarning(SwitchesScreens.HOME)} >
                    <View style={styles.switchicon}>
                        <IconSwitch4SchoolsEW height={windowWidth > 600 ? 50 : 30} width={windowWidth > 600 ? 50 : 30} marginBottom={windowWidth > 600 ? 5 : 0} />
                    </View>
                </TouchableOpacity>
            </TouchableOpacity>
            <IconHeaderDivider strokecolor={DecidaColors.Gray} />
            <TouchableOpacity onPress={navigateWithAbsenceModeWarning(SwitchesScreens.ACTIVITIES)} style={currentScreen === SwitchesScreens.ACTIVITIES ? styles.activitiesBlockSelected : styles.activitiesBlock}>
                <DefaultText style={styles.textstyle}>
                    Activities
                </DefaultText>
            </TouchableOpacity>
            <IconHeaderDivider strokecolor={DecidaColors.Gray} />
            <TouchableOpacity onPress={navigateWithAbsenceModeWarning(SwitchesScreens.MEMES)} style={currentScreen === SwitchesScreens.MEMES ? styles.block : {}}>
                <DefaultText style={styles.textstyle}>
                    Memes
                </DefaultText>
            </TouchableOpacity>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({

    container: {
        paddingHorizontal: 10,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',


    },
    content: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: 350,
        height: 450,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    textstyle: {
        fontSize: windowWidth > 600 ? 16 : 12,
        color: DecidaColors.Gray,
    },
    icon: {

    },
    switchicon: {




    },
    block: {
        borderBottomWidth: 5,
        borderBottomColor: DecidaColors.Green,
        borderRadius: 2,


    },
    activitiesBlock: {
        marginHorizontal: -12,
    },
    activitiesBlockSelected: {
        marginHorizontal: -12,
        borderBottomWidth: 5,
        borderBottomColor: DecidaColors.Green,
        borderRadius: 2,
    }

})


