import { StyleSheet, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Worksheets } from '../../../common/worksheets-imports'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { SmileHeader } from './src/smile-header'
import { SmileIcon } from './src/smile-icon'
import { SmileImage1 } from './src/smile-image1'
import { SmileLessonPlan } from './src/smile-lesson-plan'

export const SwitchesEmotionsSadSmile: CardTemplate = {
    cardId: "7fe19eef-80a2-493e-b267-365c1f9c07b5",
    title: 'Smile!',
    Header: SmileHeader,
    Icon: SmileIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"Raising your perspective and making you feel better."} />


        </>
    ),
    Images: () => (
        <>
            <SmileImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Think about something that makes you smile, it may be your favourite sport, your favourite song, or your best friend."} />
                <DefaulttextWithRightArrow textdata={"Now give a huge smile - keeping the thing that makes you smile in the forefront of your mind and do that for 10 seconds."} />
                <DefaulttextWithRightArrow textdata={"Notice any difference that may have occurred, if nothing changed that is okay. You’re allowed to give it another go if you like."} />

            </View>
        </>
    ),
    colorScheme: CardColorScheme.SAD,
    whyDoesItWorkText: "Smiling can activate the release of endorphins, which are chemicals in the brain that act as natural painkillers and mood elevators. It can also activate the release of dopamine, a neurotransmitter associated with pleasure and reward. Additionally, smiling can also be contagious, meaning that seeing someone else smile can also make us feel happier.",
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Students will understand that smiling can have a positive effect on their mood and emotions.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 35 minutes</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Begin the lesson by asking students to think about a time when they felt really happy. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them share their stories with the class and pay attention to any common themes or patterns, such as what they were doing, who they were with, or how they were feeling. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the concept that smiling can have a positive effect on our mood and emotions. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show students some examples of different types of smiles, such as a genuine (Duchenne) smile, a fake smile, a dampened smile, a contempt smile, a qualifier smile, an embarrassed smile, an ashamed smile, or a fear smile. </DefaultText>
                        <View style={styles.imageContainer}>
                            <SmileLessonPlan />
                        </View>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that when we smile, our brain releases chemicals called endorphins and serotonin, which can help improve our mood and overall sense of well-being. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have students pair up and take turns making different types of smiles at each other. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to pay attention to how each type of smile makes them feel and share their observations with their partner</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ OPTIONAL ACTIVITY -  Smile off: Have the person who was selected to go first crack their biggest, silliest smile at everyone around the circle. They will be rewarded 1 point for every person who can’t keep a straight face. After getting their points they can “pass” the smile to someone else in the circle to go next, and so on. Whoever causes the most laughter wins!    </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Give students a sheet of paper and have them draw a picture of themselves smiling. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have them write a short paragraph about how smiling makes them feel. </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to practice smiling throughout the day and pay attention to how it affects their mood and emotions. </DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Review the concept that smiling can have a positive effect on our mood and emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage students to continue practicing smiling as a way to improve their overall sense of well-being. </DefaultText>
                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• <DefaultTextWithCustomLink fontSize={DefaultLessonPlanContentFontsize} text='Different types of smiles sheet' link={Worksheets.differentTypeOfSmiles.uri} indent={0} /></DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Pen and paper for each student </DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Smiling can trick your brain into happiness — and boost your health: </DefaultText>
                        <DefaultTextWithLink text='https://www.adc-fl.com/locations/bradenton/activities-to-make-kids-smile' />
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.verywellmind.com/news-science-shows-smiling-really-does-make-us-feel-better-5075811#:~:text=%22In%20our%20research%2C%20we%20found,positive%20state%2C%22%20he%20states' link='https://www.verywellmind.com/news-science-shows-smiling-really-does-make-us-feel-better-5075811#:~:text=%22In%20our%20research%2C%20we%20found,positive%20state%2C%22%20he%20states' />
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='https://www.adc-fl.com/locations/bradenton/activities-to-make-kids-smile' link='https://www.adc-fl.com/locations/bradenton/activities-to-make-kids-smile' />


                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Smile!+audio.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action],
    level: [CardIntensitylevelType.Five, CardIntensitylevelType.Three],
    emotion: [CardFilterEmotionType.Sad]
}

const styles = StyleSheet.create({
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
})
