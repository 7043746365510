import { StyleSheet, View } from 'react-native'
import { DecidaColors } from '../../../../common/decida-colors'
import { CommonStyles } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import { Emotion } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import LessonPlan from '../../common/lesson-plan'
import SwitchCardJuniorContent from '../../common/switch-card-junior-content'
import WhyDoesItWorkComponent from '../../common/why-does-it-work-component'
import { WhyYouLikeHeader } from './src/why-you-like-header'
import { WhyYouLikeIcon } from './src/why-you-like-icon'
import { WhyYouLikeImage1 } from './src/why-you-like-image1'
import { WhyYouLikeImage2 } from './src/why-you-like-image2'
import { WhyYouLikeImage3 } from './src/why-you-like-image3'

export const SwitchesEmotionsHappyWhyYouLike: CardTemplate = {
    cardId: "30b48350-ce1a-4aa2-84f2-707b8359334c",
    title: 'Why you like?',
    Header: WhyYouLikeHeader,
    Icon: WhyYouLikeIcon,
    lessonPlan: true,
    mainContainerStyle: {
        maxWidth: '100%',
        paddingHorizontal: 20,
    },
    PerfectFor: () => {

        const Step1 = () => {
            return (
                <View>
                    <DefaultText style={styles.contentText}>Think of someone who means a lot to you.</DefaultText>
                    <View style={styles.imageContainer}>
                        <WhyYouLikeImage1 />
                    </View>
                    <DefaultText style={styles.contentText}>It could be a friend, family member, or even your teacher!</DefaultText>
                </View>
            )
        }

        const Step2 = () => {
            return (
                <View>
                    <DefaultText style={styles.contentText}>Draw all the things that you like about this person</DefaultText>
                    <DefaultText style={styles.contentText}>or all the things that you think makes them really special</DefaultText>
                    <View style={styles.imageContainer}>
                        <WhyYouLikeImage2 />
                    </View>
                </View>
            )
        }

        const Step3 = () => {
            return (
                <View>
                    <DefaultText style={styles.contentText}>Share your list with the person you wrote about.</DefaultText>
                    <View style={styles.imageContainer}>
                        <WhyYouLikeImage3 />
                    </View>
                    <DefaultText style={styles.contentText}>Notice how good you and the other person feels after you have</DefaultText>
                    <DefaultText style={styles.contentText}>shared your list with them.</DefaultText>

                    <View>
                        <WhyDoesItWorkComponent message={"In positive psychology research, gratitude is strongly and consistently associated with greater happiness. Gratitude helps people feel more positive emotions, relish good experiences, improve their health, deal with adversity, and build strong relationships."} />
                    </View>
                </View>
            )
        }


        const contents = [
            { Component: Step1, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/why-you-like-pg1.mp3' },
            { Component: Step2, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/why-you-like-pg2.mp3' },
            { Component: Step3, audio: 'https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/switches/why-you-like-pg3.mp3' },
        ]

        return (
            <>
                <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
                <DefaultTextWithoutArrowsItallic textdata={"A magical break for your mind and body, helping you feel calm by imagining wonderful and peaceful things while taking deep, slow breaths."} />

                <SwitchCardJuniorContent contents={contents} scheme={Emotion.HAPPY} />
            </>
        )
    },
    Images: undefined,
    CardContent: () => (
        <>
        </>
    )
    ,
    colorScheme: CardColorScheme.HAPPY,
    whyDoesItWorkText: "",
    BottomRow: () => (
        <>
        </>
    ),
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will be able to:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Practice sharing gratitude</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Develop empathy and demonstrate respect</DefaultText>

                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the children if they've ever received something nice from a friend and how it made them feel.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that showing appreciation means letting others know we like and value them, making them happy and loved.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Demonstration (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Show simple examples like giving a compliment, a hug, or saying thank you.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage the children to imitate these actions with you.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Practice (30 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Drawing Activity: Give each child a piece of paper and drawing supplies. Ask them to draw a picture that shows something nice they can do for a friend to make them feel happy.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Role Playing: Have each child briefly explain their drawing to a classmate. Encourage them to share why their idea would make a friend happy. Let them take turns role-playing the actions from their drawings.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Conclusion (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Bring the class back together. Ask the children to share some of the ways they showed appreciation for their friends through their drawings.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Emphasise the importance of showing appreciation for others and encourage the children to continue doing so in the future.</DefaultText>

                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Paper and drawing supplies</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Gratitude Meditation for Kids | Guided mindfulness practice for kids -</DefaultText>
                        <DefaultTextWithLink text='https://www.youtube.com/watch?v=_qpxWpGH11Q' />

                        <DefaultTextWithoutArrowsItallic textdata='' />
                        <DefaultText style={CommonStyles.lessonPlanText}>How to Teach Children Gratitude. it’s more than just saying thank you. -</DefaultText>
                        <DefaultTextWithLink text='https://www.verywellmind.com/how-to-teach-children-gratitude-4782154' />
                        <DefaultTextWithoutArrowsItallic textdata='' />
                        <DefaultText style={CommonStyles.lessonPlanText}>20 Amazing Benefits of Hugging According to Science -</DefaultText>
                        <DefaultTextWithLink text='https://www.generationnext.com.au/2017/05/20-amazing-benefits-hugging-according-science/' />
                    </>}
            />

        </>
    ),
    type: [CardType.Switch],
    time: [CardTimeType.Medium],
    people: [CardPeopleType.Individual, CardPeopleType.Group],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Thought],
    level: [CardIntensitylevelType.One],
    emotion: [CardFilterEmotionType.Happy]
}

const styles = StyleSheet.create({
    contentText: {
        color: DecidaColors.AppleSystemGray2Dark,
        textAlign: 'center',
    },
    imageContainer: {
        width: '100%',
        height: 300,
        marginVertical: 20
    },
    image: {
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 50,
        width: 200,
    },
})

