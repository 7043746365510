import { StudentClassApprovalStatus } from "../../../../common/API";

export const adminStudentFields = `
    id
    firstName
    lastName
    email
    cognitoUsername
    nickname
    _version
    _deleted
    _lastChangedAt
    __typename
`

export const adminListStudentsBySchoolID = `
  query ListStudentsBySchoolID(
    $schoolID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentsBySchoolID(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${adminStudentFields}
        classes(filter: {_deleted: {ne: true}, status: {eq: APPROVED}}) {
            items {
                id
                status
                _deleted
                class {
                    id
                    name
                    archived
                    _deleted
                }
            }
        }
      }
      nextToken
      startedAt
      __typename
    }
  }
`

export type AdminListStudentsBySchoolIDQuery = {
    listStudentsBySchoolID?: {
        __typename: "ModelStudentConnection",
        items: Array<{
            __typename: "Student",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            email?: string | null,
            cognitoUsername: string,
            nickname: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            classes: {
                __typename: "ModelStudentClassConnection",
                items: Array<{
                    __typename: "StudentClass",
                    id: string,
                    status?: StudentClassApprovalStatus | null,
                    _deleted?: boolean | null,
                    class?: {
                        __typename: "Class",
                        id: string,
                        name: string,
                        archived?: boolean | null,
                        _deleted?: boolean | null,
                    }
                } | null>,
            }
        } | null>,
        nextToken?: string | null,
        startedAt?: number | null,
    } | null,
};