/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getStudentCheckIn = /* GraphQL */ `query GetStudentCheckIn($id: ID!) {
  getStudentCheckIn(id: $id) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStudentCheckInQueryVariables,
  APITypes.GetStudentCheckInQuery
>;
export const listStudentCheckIns = /* GraphQL */ `query ListStudentCheckIns(
  $filter: ModelStudentCheckInFilterInput
  $limit: Int
  $nextToken: String
) {
  listStudentCheckIns(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      studentID
      emotion
      emotionIntensity
      emotionIntensityPercentage
      tiredness
      checkinsessionID
      classID
      absence
      notYet
      requestForChat
      chatCleared
      eating
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentCheckInsQueryVariables,
  APITypes.ListStudentCheckInsQuery
>;
export const syncStudentCheckIns = /* GraphQL */ `query SyncStudentCheckIns(
  $filter: ModelStudentCheckInFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncStudentCheckIns(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      createdAt
      updatedAt
      studentID
      emotion
      emotionIntensity
      emotionIntensityPercentage
      tiredness
      checkinsessionID
      classID
      absence
      notYet
      requestForChat
      chatCleared
      eating
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncStudentCheckInsQueryVariables,
  APITypes.SyncStudentCheckInsQuery
>;
export const listStudentCheckInsByStudentId = /* GraphQL */ `query ListStudentCheckInsByStudentId(
  $studentID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStudentCheckInFilterInput
  $limit: Int
  $nextToken: String
) {
  listStudentCheckInsByStudentId(
    studentID: $studentID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      studentID
      emotion
      emotionIntensity
      emotionIntensityPercentage
      tiredness
      checkinsessionID
      classID
      absence
      notYet
      requestForChat
      chatCleared
      eating
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentCheckInsByStudentIdQueryVariables,
  APITypes.ListStudentCheckInsByStudentIdQuery
>;
export const listStudentCheckInsByCheckInSessionId = /* GraphQL */ `query ListStudentCheckInsByCheckInSessionId(
  $checkinsessionID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStudentCheckInFilterInput
  $limit: Int
  $nextToken: String
) {
  listStudentCheckInsByCheckInSessionId(
    checkinsessionID: $checkinsessionID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      studentID
      emotion
      emotionIntensity
      emotionIntensityPercentage
      tiredness
      checkinsessionID
      classID
      absence
      notYet
      requestForChat
      chatCleared
      eating
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentCheckInsByCheckInSessionIdQueryVariables,
  APITypes.ListStudentCheckInsByCheckInSessionIdQuery
>;
export const listStudentCheckInsByClassId = /* GraphQL */ `query ListStudentCheckInsByClassId(
  $classID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStudentCheckInFilterInput
  $limit: Int
  $nextToken: String
) {
  listStudentCheckInsByClassId(
    classID: $classID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      studentID
      emotion
      emotionIntensity
      emotionIntensityPercentage
      tiredness
      checkinsessionID
      classID
      absence
      notYet
      requestForChat
      chatCleared
      eating
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentCheckInsByClassIdQueryVariables,
  APITypes.ListStudentCheckInsByClassIdQuery
>;
export const getTeacherCheckIn = /* GraphQL */ `query GetTeacherCheckIn($id: ID!) {
  getTeacherCheckIn(id: $id) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTeacherCheckInQueryVariables,
  APITypes.GetTeacherCheckInQuery
>;
export const listTeacherCheckIns = /* GraphQL */ `query ListTeacherCheckIns(
  $filter: ModelTeacherCheckInFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeacherCheckIns(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      schoolID
      teacherID
      emotion
      emotionIntensityPercentage
      tiredness
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeacherCheckInsQueryVariables,
  APITypes.ListTeacherCheckInsQuery
>;
export const syncTeacherCheckIns = /* GraphQL */ `query SyncTeacherCheckIns(
  $filter: ModelTeacherCheckInFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTeacherCheckIns(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      createdAt
      updatedAt
      schoolID
      teacherID
      emotion
      emotionIntensityPercentage
      tiredness
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTeacherCheckInsQueryVariables,
  APITypes.SyncTeacherCheckInsQuery
>;
export const listTeacherCheckInsBySchoolID = /* GraphQL */ `query ListTeacherCheckInsBySchoolID(
  $schoolID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeacherCheckInFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeacherCheckInsBySchoolID(
    schoolID: $schoolID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      schoolID
      teacherID
      emotion
      emotionIntensityPercentage
      tiredness
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeacherCheckInsBySchoolIDQueryVariables,
  APITypes.ListTeacherCheckInsBySchoolIDQuery
>;
export const listTeacherCheckInsByTeacherID = /* GraphQL */ `query ListTeacherCheckInsByTeacherID(
  $teacherID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeacherCheckInFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeacherCheckInsByTeacherID(
    teacherID: $teacherID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      schoolID
      teacherID
      emotion
      emotionIntensityPercentage
      tiredness
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeacherCheckInsByTeacherIDQueryVariables,
  APITypes.ListTeacherCheckInsByTeacherIDQuery
>;
export const getStaffCheckIn = /* GraphQL */ `query GetStaffCheckIn($id: ID!) {
  getStaffCheckIn(id: $id) {
    id
    createdAt
    updatedAt
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    staffID
    staff {
      id
      cognitoUsername
      email
      schoolID
      firstName
      lastName
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      nonTeachingStaffCheckInCount
      nonTeachingStaffCheckInSkipSuggestionDate
      onboarded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensityPercentage
    tiredness
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStaffCheckInQueryVariables,
  APITypes.GetStaffCheckInQuery
>;
export const listStaffCheckIns = /* GraphQL */ `query ListStaffCheckIns(
  $filter: ModelStaffCheckInFilterInput
  $limit: Int
  $nextToken: String
) {
  listStaffCheckIns(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      schoolID
      staffID
      emotion
      emotionIntensityPercentage
      tiredness
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStaffCheckInsQueryVariables,
  APITypes.ListStaffCheckInsQuery
>;
export const syncStaffCheckIns = /* GraphQL */ `query SyncStaffCheckIns(
  $filter: ModelStaffCheckInFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncStaffCheckIns(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      createdAt
      updatedAt
      schoolID
      staffID
      emotion
      emotionIntensityPercentage
      tiredness
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncStaffCheckInsQueryVariables,
  APITypes.SyncStaffCheckInsQuery
>;
export const listStaffCheckInsBySchoolID = /* GraphQL */ `query ListStaffCheckInsBySchoolID(
  $schoolID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStaffCheckInFilterInput
  $limit: Int
  $nextToken: String
) {
  listStaffCheckInsBySchoolID(
    schoolID: $schoolID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      schoolID
      staffID
      emotion
      emotionIntensityPercentage
      tiredness
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStaffCheckInsBySchoolIDQueryVariables,
  APITypes.ListStaffCheckInsBySchoolIDQuery
>;
export const listStaffCheckInsByStaffID = /* GraphQL */ `query ListStaffCheckInsByStaffID(
  $staffID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStaffCheckInFilterInput
  $limit: Int
  $nextToken: String
) {
  listStaffCheckInsByStaffID(
    staffID: $staffID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      updatedAt
      schoolID
      staffID
      emotion
      emotionIntensityPercentage
      tiredness
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStaffCheckInsByStaffIDQueryVariables,
  APITypes.ListStaffCheckInsByStaffIDQuery
>;
export const getCheckInSession = /* GraphQL */ `query GetCheckInSession($id: ID!) {
  getCheckInSession(id: $id) {
    id
    checkInSessionClassSequenceNo
    createdAt
    updatedAt
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    openAt
    closeAt
    isActive
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCheckInSessionQueryVariables,
  APITypes.GetCheckInSessionQuery
>;
export const listCheckInSessions = /* GraphQL */ `query ListCheckInSessions(
  $filter: ModelCheckInSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listCheckInSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      checkInSessionClassSequenceNo
      createdAt
      updatedAt
      classID
      openAt
      closeAt
      isActive
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCheckInSessionsQueryVariables,
  APITypes.ListCheckInSessionsQuery
>;
export const syncCheckInSessions = /* GraphQL */ `query SyncCheckInSessions(
  $filter: ModelCheckInSessionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCheckInSessions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      checkInSessionClassSequenceNo
      createdAt
      updatedAt
      classID
      openAt
      closeAt
      isActive
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCheckInSessionsQueryVariables,
  APITypes.SyncCheckInSessionsQuery
>;
export const listCheckInSessionsByClassId = /* GraphQL */ `query ListCheckInSessionsByClassId(
  $classID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCheckInSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listCheckInSessionsByClassId(
    classID: $classID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      checkInSessionClassSequenceNo
      createdAt
      updatedAt
      classID
      openAt
      closeAt
      isActive
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCheckInSessionsByClassIdQueryVariables,
  APITypes.ListCheckInSessionsByClassIdQuery
>;
export const getSchool = /* GraphQL */ `query GetSchool($id: ID!) {
  getSchool(id: $id) {
    id
    name
    classes {
      nextToken
      startedAt
      __typename
    }
    campuses {
      nextToken
      startedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    teachers {
      nextToken
      startedAt
      __typename
    }
    students {
      nextToken
      startedAt
      __typename
    }
    staffs {
      nextToken
      startedAt
      __typename
    }
    schoolAdmins {
      nextToken
      startedAt
      __typename
    }
    schoolAdminUI
    utcOffset
    licenseTotal
    timezone
    status
    activeStudents
    trialEndDate
    renewalDate
    notes
    checkInCountAllTime
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    wholeSchoolSubscription
    billingAddress
    billingNotes
    invoiceUrl
    abn
    coordinatorID
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    staffCheckIns {
      nextToken
      startedAt
      __typename
    }
    teacherWellbeing
    staffWellbeing
    wellbeingFeature
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSchoolQueryVariables, APITypes.GetSchoolQuery>;
export const listSchools = /* GraphQL */ `query ListSchools(
  $filter: ModelSchoolFilterInput
  $limit: Int
  $nextToken: String
) {
  listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSchoolsQueryVariables,
  APITypes.ListSchoolsQuery
>;
export const syncSchools = /* GraphQL */ `query SyncSchools(
  $filter: ModelSchoolFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSchools(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSchoolsQueryVariables,
  APITypes.SyncSchoolsQuery
>;
export const getCampus = /* GraphQL */ `query GetCampus($id: ID!) {
  getCampus(id: $id) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroups {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCampusQueryVariables, APITypes.GetCampusQuery>;
export const listCampuses = /* GraphQL */ `query ListCampuses(
  $filter: ModelCampusFilterInput
  $limit: Int
  $nextToken: String
) {
  listCampuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      schoolID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCampusesQueryVariables,
  APITypes.ListCampusesQuery
>;
export const syncCampuses = /* GraphQL */ `query SyncCampuses(
  $filter: ModelCampusFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCampuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      schoolID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCampusesQueryVariables,
  APITypes.SyncCampusesQuery
>;
export const listCampusesBySchoolID = /* GraphQL */ `query ListCampusesBySchoolID(
  $schoolID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCampusFilterInput
  $limit: Int
  $nextToken: String
) {
  listCampusesBySchoolID(
    schoolID: $schoolID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      schoolID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCampusesBySchoolIDQueryVariables,
  APITypes.ListCampusesBySchoolIDQuery
>;
export const getClassGroup = /* GraphQL */ `query GetClassGroup($id: ID!) {
  getClassGroup(id: $id) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    campusID
    campus {
      id
      name
      schoolID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetClassGroupQueryVariables,
  APITypes.GetClassGroupQuery
>;
export const listClassGroups = /* GraphQL */ `query ListClassGroups(
  $filter: ModelClassGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listClassGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      schoolID
      campusID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClassGroupsQueryVariables,
  APITypes.ListClassGroupsQuery
>;
export const syncClassGroups = /* GraphQL */ `query SyncClassGroups(
  $filter: ModelClassGroupFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClassGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      schoolID
      campusID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncClassGroupsQueryVariables,
  APITypes.SyncClassGroupsQuery
>;
export const listClassGroupsBySchoolID = /* GraphQL */ `query ListClassGroupsBySchoolID(
  $schoolID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelClassGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listClassGroupsBySchoolID(
    schoolID: $schoolID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      schoolID
      campusID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClassGroupsBySchoolIDQueryVariables,
  APITypes.ListClassGroupsBySchoolIDQuery
>;
export const listClassGroupsByCampusID = /* GraphQL */ `query ListClassGroupsByCampusID(
  $campusID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelClassGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listClassGroupsByCampusID(
    campusID: $campusID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      schoolID
      campusID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClassGroupsByCampusIDQueryVariables,
  APITypes.ListClassGroupsByCampusIDQuery
>;
export const getClass = /* GraphQL */ `query GetClass($id: ID!) {
  getClass(id: $id) {
    id
    name
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classGroupID
    students {
      nextToken
      startedAt
      __typename
    }
    classLogin {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    checkInSessions {
      nextToken
      startedAt
      __typename
    }
    supports {
      nextToken
      startedAt
      __typename
    }
    emotionIntensityLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    toggleRequestForChat
    toggleRequestForChatNotifications
    toggleIndividualStudentStats
    teachers {
      nextToken
      startedAt
      __typename
    }
    schedules {
      nextToken
      startedAt
      __typename
    }
    bulkSignUpId
    createdAt
    updatedAt
    primaryContact
    archived
    emailReceivers
    lastCheckInSessionClassSequenceNo
    checkInCountYTD
    checkInCountRollingWeekMonday
    checkInCountRollingWeekTuesday
    checkInCountRollingWeekWednesday
    checkInCountRollingWeekThursday
    checkInCountRollingWeekFriday
    checkInCountRollingWeekSaturday
    checkInCountRollingWeekSunday
    activeStudents
    goMode
    yearLevel
    lastCheckInTime
    wellbeing
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestionID
    wellbeingResponseCount
    toggleEating
    _version
    _deleted
    _lastChangedAt
    classClassLoginId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClassQueryVariables, APITypes.GetClassQuery>;
export const listClasses = /* GraphQL */ `query ListClasses(
  $filter: ModelClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClassesQueryVariables,
  APITypes.ListClassesQuery
>;
export const syncClasses = /* GraphQL */ `query SyncClasses(
  $filter: ModelClassFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClasses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncClassesQueryVariables,
  APITypes.SyncClassesQuery
>;
export const getClassByName = /* GraphQL */ `query GetClassByName(
  $name: String!
  $sortDirection: ModelSortDirection
  $filter: ModelClassFilterInput
  $limit: Int
  $nextToken: String
) {
  getClassByName(
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetClassByNameQueryVariables,
  APITypes.GetClassByNameQuery
>;
export const listClassesBySchoolID = /* GraphQL */ `query ListClassesBySchoolID(
  $schoolID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listClassesBySchoolID(
    schoolID: $schoolID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClassesBySchoolIDQueryVariables,
  APITypes.ListClassesBySchoolIDQuery
>;
export const listClassesByClassGroupID = /* GraphQL */ `query ListClassesByClassGroupID(
  $classGroupID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listClassesByClassGroupID(
    classGroupID: $classGroupID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClassesByClassGroupIDQueryVariables,
  APITypes.ListClassesByClassGroupIDQuery
>;
export const bulkSignUpId = /* GraphQL */ `query BulkSignUpId(
  $bulkSignUpId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelClassFilterInput
  $limit: Int
  $nextToken: String
) {
  bulkSignUpId(
    bulkSignUpId: $bulkSignUpId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BulkSignUpIdQueryVariables,
  APITypes.BulkSignUpIdQuery
>;
export const getTeacher = /* GraphQL */ `query GetTeacher($id: ID!) {
  getTeacher(id: $id) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    classes {
      nextToken
      startedAt
      __typename
    }
    cognitoUsername
    showIntroduction
    createdAt
    updatedAt
    role
    onboarded
    devicePushTokens
    showNewFeatureInstructions
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTeacherQueryVariables,
  APITypes.GetTeacherQuery
>;
export const listTeachers = /* GraphQL */ `query ListTeachers(
  $filter: ModelTeacherFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeachers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeachersQueryVariables,
  APITypes.ListTeachersQuery
>;
export const syncTeachers = /* GraphQL */ `query SyncTeachers(
  $filter: ModelTeacherFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTeachers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTeachersQueryVariables,
  APITypes.SyncTeachersQuery
>;
export const getTeacherByEmail = /* GraphQL */ `query GetTeacherByEmail(
  $email: AWSEmail!
  $sortDirection: ModelSortDirection
  $filter: ModelTeacherFilterInput
  $limit: Int
  $nextToken: String
) {
  getTeacherByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTeacherByEmailQueryVariables,
  APITypes.GetTeacherByEmailQuery
>;
export const getListTeacherBySchoolID = /* GraphQL */ `query GetListTeacherBySchoolID(
  $schoolID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTeacherFilterInput
  $limit: Int
  $nextToken: String
) {
  getListTeacherBySchoolID(
    schoolID: $schoolID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetListTeacherBySchoolIDQueryVariables,
  APITypes.GetListTeacherBySchoolIDQuery
>;
export const getTeacherByCognitoUsername = /* GraphQL */ `query GetTeacherByCognitoUsername(
  $cognitoUsername: String!
  $sortDirection: ModelSortDirection
  $filter: ModelTeacherFilterInput
  $limit: Int
  $nextToken: String
) {
  getTeacherByCognitoUsername(
    cognitoUsername: $cognitoUsername
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTeacherByCognitoUsernameQueryVariables,
  APITypes.GetTeacherByCognitoUsernameQuery
>;
export const getStaff = /* GraphQL */ `query GetStaff($id: ID!) {
  getStaff(id: $id) {
    id
    cognitoUsername
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    preferredUsername
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    wellbeingResponseCount
    teacherCheckIns {
      nextToken
      startedAt
      __typename
    }
    avatar
    nonTeachingStaffCheckInCount
    nonTeachingStaffCheckInSkipSuggestionDate
    onboarded
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetStaffQueryVariables, APITypes.GetStaffQuery>;
export const listStaff = /* GraphQL */ `query ListStaff(
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
) {
  listStaff(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoUsername
      email
      schoolID
      firstName
      lastName
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      nonTeachingStaffCheckInCount
      nonTeachingStaffCheckInSkipSuggestionDate
      onboarded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListStaffQueryVariables, APITypes.ListStaffQuery>;
export const syncStaff = /* GraphQL */ `query SyncStaff(
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncStaff(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      cognitoUsername
      email
      schoolID
      firstName
      lastName
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      nonTeachingStaffCheckInCount
      nonTeachingStaffCheckInSkipSuggestionDate
      onboarded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncStaffQueryVariables, APITypes.SyncStaffQuery>;
export const getStaffByCognitoUsername = /* GraphQL */ `query GetStaffByCognitoUsername(
  $cognitoUsername: String!
  $sortDirection: ModelSortDirection
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
) {
  getStaffByCognitoUsername(
    cognitoUsername: $cognitoUsername
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoUsername
      email
      schoolID
      firstName
      lastName
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      nonTeachingStaffCheckInCount
      nonTeachingStaffCheckInSkipSuggestionDate
      onboarded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStaffByCognitoUsernameQueryVariables,
  APITypes.GetStaffByCognitoUsernameQuery
>;
export const getStaffByEmail = /* GraphQL */ `query GetStaffByEmail(
  $email: AWSEmail!
  $sortDirection: ModelSortDirection
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
) {
  getStaffByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoUsername
      email
      schoolID
      firstName
      lastName
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      nonTeachingStaffCheckInCount
      nonTeachingStaffCheckInSkipSuggestionDate
      onboarded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStaffByEmailQueryVariables,
  APITypes.GetStaffByEmailQuery
>;
export const getListStaffBySchoolID = /* GraphQL */ `query GetListStaffBySchoolID(
  $schoolID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelStaffFilterInput
  $limit: Int
  $nextToken: String
) {
  getListStaffBySchoolID(
    schoolID: $schoolID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoUsername
      email
      schoolID
      firstName
      lastName
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      nonTeachingStaffCheckInCount
      nonTeachingStaffCheckInSkipSuggestionDate
      onboarded
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetListStaffBySchoolIDQueryVariables,
  APITypes.GetListStaffBySchoolIDQuery
>;
export const getSchoolAdmin = /* GraphQL */ `query GetSchoolAdmin($id: ID!) {
  getSchoolAdmin(id: $id) {
    id
    email
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    preferredUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSchoolAdminQueryVariables,
  APITypes.GetSchoolAdminQuery
>;
export const listSchoolAdmins = /* GraphQL */ `query ListSchoolAdmins(
  $filter: ModelSchoolAdminFilterInput
  $limit: Int
  $nextToken: String
) {
  listSchoolAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      preferredUsername
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSchoolAdminsQueryVariables,
  APITypes.ListSchoolAdminsQuery
>;
export const syncSchoolAdmins = /* GraphQL */ `query SyncSchoolAdmins(
  $filter: ModelSchoolAdminFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSchoolAdmins(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      preferredUsername
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSchoolAdminsQueryVariables,
  APITypes.SyncSchoolAdminsQuery
>;
export const getSchoolAdminByEmail = /* GraphQL */ `query GetSchoolAdminByEmail(
  $email: AWSEmail!
  $sortDirection: ModelSortDirection
  $filter: ModelSchoolAdminFilterInput
  $limit: Int
  $nextToken: String
) {
  getSchoolAdminByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      preferredUsername
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSchoolAdminByEmailQueryVariables,
  APITypes.GetSchoolAdminByEmailQuery
>;
export const listSchoolAdminBySchoolID = /* GraphQL */ `query ListSchoolAdminBySchoolID(
  $schoolID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSchoolAdminFilterInput
  $limit: Int
  $nextToken: String
) {
  listSchoolAdminBySchoolID(
    schoolID: $schoolID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      preferredUsername
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSchoolAdminBySchoolIDQueryVariables,
  APITypes.ListSchoolAdminBySchoolIDQuery
>;
export const getSchoolAdminByCognitoUsername = /* GraphQL */ `query GetSchoolAdminByCognitoUsername(
  $cognitoUsername: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSchoolAdminFilterInput
  $limit: Int
  $nextToken: String
) {
  getSchoolAdminByCognitoUsername(
    cognitoUsername: $cognitoUsername
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      preferredUsername
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSchoolAdminByCognitoUsernameQueryVariables,
  APITypes.GetSchoolAdminByCognitoUsernameQuery
>;
export const getStudent = /* GraphQL */ `query GetStudent($id: ID!) {
  getStudent(id: $id) {
    id
    schoolStudentID
    nickname
    avatar
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    yearLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    email
    status
    cognitoUsername
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    onboarded
    initialLoginSetup
    activeClasses
    studentCheckInCount
    studentCheckInSkipSuggestionDate
    devicePushTokens
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestion
    wellbeingResponseCount
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStudentQueryVariables,
  APITypes.GetStudentQuery
>;
export const listStudents = /* GraphQL */ `query ListStudents(
  $filter: ModelStudentFilterInput
  $limit: Int
  $nextToken: String
) {
  listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentsQueryVariables,
  APITypes.ListStudentsQuery
>;
export const syncStudents = /* GraphQL */ `query SyncStudents(
  $filter: ModelStudentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncStudents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncStudentsQueryVariables,
  APITypes.SyncStudentsQuery
>;
export const getStudentBySchoolStudentID = /* GraphQL */ `query GetStudentBySchoolStudentID(
  $schoolStudentID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelStudentFilterInput
  $limit: Int
  $nextToken: String
) {
  getStudentBySchoolStudentID(
    schoolStudentID: $schoolStudentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStudentBySchoolStudentIDQueryVariables,
  APITypes.GetStudentBySchoolStudentIDQuery
>;
export const getStudentByNickname = /* GraphQL */ `query GetStudentByNickname(
  $nickname: String!
  $sortDirection: ModelSortDirection
  $filter: ModelStudentFilterInput
  $limit: Int
  $nextToken: String
) {
  getStudentByNickname(
    nickname: $nickname
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStudentByNicknameQueryVariables,
  APITypes.GetStudentByNicknameQuery
>;
export const listStudentsBySchoolID = /* GraphQL */ `query ListStudentsBySchoolID(
  $schoolID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelStudentFilterInput
  $limit: Int
  $nextToken: String
) {
  listStudentsBySchoolID(
    schoolID: $schoolID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentsBySchoolIDQueryVariables,
  APITypes.ListStudentsBySchoolIDQuery
>;
export const listStudentsByEmail = /* GraphQL */ `query ListStudentsByEmail(
  $email: AWSEmail!
  $sortDirection: ModelSortDirection
  $filter: ModelStudentFilterInput
  $limit: Int
  $nextToken: String
) {
  listStudentsByEmail(
    email: $email
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentsByEmailQueryVariables,
  APITypes.ListStudentsByEmailQuery
>;
export const getStudentByCognitoUsername = /* GraphQL */ `query GetStudentByCognitoUsername(
  $cognitoUsername: String!
  $sortDirection: ModelSortDirection
  $filter: ModelStudentFilterInput
  $limit: Int
  $nextToken: String
) {
  getStudentByCognitoUsername(
    cognitoUsername: $cognitoUsername
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStudentByCognitoUsernameQueryVariables,
  APITypes.GetStudentByCognitoUsernameQuery
>;
export const getSupport = /* GraphQL */ `query GetSupport($id: ID!) {
  getSupport(id: $id) {
    id
    email
    classes {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    cognitoUsername
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSupportQueryVariables,
  APITypes.GetSupportQuery
>;
export const listSupports = /* GraphQL */ `query ListSupports(
  $filter: ModelSupportFilterInput
  $limit: Int
  $nextToken: String
) {
  listSupports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      firstName
      lastName
      cognitoUsername
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSupportsQueryVariables,
  APITypes.ListSupportsQuery
>;
export const syncSupports = /* GraphQL */ `query SyncSupports(
  $filter: ModelSupportFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSupports(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      email
      firstName
      lastName
      cognitoUsername
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSupportsQueryVariables,
  APITypes.SyncSupportsQuery
>;
export const getClassSupport = /* GraphQL */ `query GetClassSupport($id: ID!) {
  getClassSupport(id: $id) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    supportID
    support {
      id
      email
      firstName
      lastName
      cognitoUsername
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetClassSupportQueryVariables,
  APITypes.GetClassSupportQuery
>;
export const listClassSupports = /* GraphQL */ `query ListClassSupports(
  $filter: ModelClassSupportFilterInput
  $limit: Int
  $nextToken: String
) {
  listClassSupports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      classID
      supportID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClassSupportsQueryVariables,
  APITypes.ListClassSupportsQuery
>;
export const syncClassSupports = /* GraphQL */ `query SyncClassSupports(
  $filter: ModelClassSupportFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClassSupports(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      classID
      supportID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncClassSupportsQueryVariables,
  APITypes.SyncClassSupportsQuery
>;
export const getTeacherClass = /* GraphQL */ `query GetTeacherClass($id: ID!) {
  getTeacherClass(id: $id) {
    id
    teacherID
    teacher {
      id
      email
      schoolID
      firstName
      lastName
      cognitoUsername
      showIntroduction
      createdAt
      updatedAt
      role
      onboarded
      devicePushTokens
      showNewFeatureInstructions
      preferredUsername
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      wellbeingResponseCount
      avatar
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTeacherClassQueryVariables,
  APITypes.GetTeacherClassQuery
>;
export const listTeacherClasses = /* GraphQL */ `query ListTeacherClasses(
  $filter: ModelTeacherClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeacherClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      teacherID
      classID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeacherClassesQueryVariables,
  APITypes.ListTeacherClassesQuery
>;
export const syncTeacherClasses = /* GraphQL */ `query SyncTeacherClasses(
  $filter: ModelTeacherClassFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTeacherClasses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      teacherID
      classID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTeacherClassesQueryVariables,
  APITypes.SyncTeacherClassesQuery
>;
export const listTeacherClassesByTeacherID = /* GraphQL */ `query ListTeacherClassesByTeacherID(
  $teacherID: ID!
  $classID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeacherClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeacherClassesByTeacherID(
    teacherID: $teacherID
    classID: $classID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      teacherID
      classID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeacherClassesByTeacherIDQueryVariables,
  APITypes.ListTeacherClassesByTeacherIDQuery
>;
export const listTeacherClassesByClassID = /* GraphQL */ `query ListTeacherClassesByClassID(
  $classID: ID!
  $teacherID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeacherClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeacherClassesByClassID(
    classID: $classID
    teacherID: $teacherID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      teacherID
      classID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeacherClassesByClassIDQueryVariables,
  APITypes.ListTeacherClassesByClassIDQuery
>;
export const getScheduleClass = /* GraphQL */ `query GetScheduleClass($id: ID!) {
  getScheduleClass(id: $id) {
    id
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    schedule
    isActive
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetScheduleClassQueryVariables,
  APITypes.GetScheduleClassQuery
>;
export const listScheduleClasses = /* GraphQL */ `query ListScheduleClasses(
  $filter: ModelScheduleClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listScheduleClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      classID
      schedule
      isActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListScheduleClassesQueryVariables,
  APITypes.ListScheduleClassesQuery
>;
export const syncScheduleClasses = /* GraphQL */ `query SyncScheduleClasses(
  $filter: ModelScheduleClassFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncScheduleClasses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      classID
      schedule
      isActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncScheduleClassesQueryVariables,
  APITypes.SyncScheduleClassesQuery
>;
export const listScheduleClassByClassId = /* GraphQL */ `query ListScheduleClassByClassId(
  $classID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelScheduleClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listScheduleClassByClassId(
    classID: $classID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      classID
      schedule
      isActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListScheduleClassByClassIdQueryVariables,
  APITypes.ListScheduleClassByClassIdQuery
>;
export const getStudentClass = /* GraphQL */ `query GetStudentClass($id: ID!) {
  getStudentClass(id: $id) {
    id
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    status
    createdAt
    updatedAt
    requestForChat
    requestForChatStatus
    studentCheckIns
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStudentClassQueryVariables,
  APITypes.GetStudentClassQuery
>;
export const listStudentClasses = /* GraphQL */ `query ListStudentClasses(
  $filter: ModelStudentClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listStudentClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      studentID
      classID
      status
      createdAt
      updatedAt
      requestForChat
      requestForChatStatus
      studentCheckIns
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentClassesQueryVariables,
  APITypes.ListStudentClassesQuery
>;
export const syncStudentClasses = /* GraphQL */ `query SyncStudentClasses(
  $filter: ModelStudentClassFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncStudentClasses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      studentID
      classID
      status
      createdAt
      updatedAt
      requestForChat
      requestForChatStatus
      studentCheckIns
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncStudentClassesQueryVariables,
  APITypes.SyncStudentClassesQuery
>;
export const getStudentClassByStudentId = /* GraphQL */ `query GetStudentClassByStudentId(
  $studentID: ID!
  $classID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStudentClassFilterInput
  $limit: Int
  $nextToken: String
) {
  getStudentClassByStudentId(
    studentID: $studentID
    classID: $classID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      studentID
      classID
      status
      createdAt
      updatedAt
      requestForChat
      requestForChatStatus
      studentCheckIns
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStudentClassByStudentIdQueryVariables,
  APITypes.GetStudentClassByStudentIdQuery
>;
export const getStudentClassByClassId = /* GraphQL */ `query GetStudentClassByClassId(
  $classID: ID!
  $studentID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStudentClassFilterInput
  $limit: Int
  $nextToken: String
) {
  getStudentClassByClassId(
    classID: $classID
    studentID: $studentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      studentID
      classID
      status
      createdAt
      updatedAt
      requestForChat
      requestForChatStatus
      studentCheckIns
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStudentClassByClassIdQueryVariables,
  APITypes.GetStudentClassByClassIdQuery
>;
export const getSchoolContact = /* GraphQL */ `query GetSchoolContact($id: ID!) {
  getSchoolContact(id: $id) {
    id
    schoolID
    firstName
    lastName
    email
    type
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSchoolContactQueryVariables,
  APITypes.GetSchoolContactQuery
>;
export const listSchoolContacts = /* GraphQL */ `query ListSchoolContacts(
  $filter: ModelSchoolContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listSchoolContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      schoolID
      firstName
      lastName
      email
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSchoolContactsQueryVariables,
  APITypes.ListSchoolContactsQuery
>;
export const syncSchoolContacts = /* GraphQL */ `query SyncSchoolContacts(
  $filter: ModelSchoolContactFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSchoolContacts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      schoolID
      firstName
      lastName
      email
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSchoolContactsQueryVariables,
  APITypes.SyncSchoolContactsQuery
>;
export const getSchoolContactBySchoolID = /* GraphQL */ `query GetSchoolContactBySchoolID(
  $schoolID: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSchoolContactFilterInput
  $limit: Int
  $nextToken: String
) {
  getSchoolContactBySchoolID(
    schoolID: $schoolID
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schoolID
      firstName
      lastName
      email
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSchoolContactBySchoolIDQueryVariables,
  APITypes.GetSchoolContactBySchoolIDQuery
>;
export const getMinFEVersion = /* GraphQL */ `query GetMinFEVersion($id: ID!) {
  getMinFEVersion(id: $id) {
    version
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMinFEVersionQueryVariables,
  APITypes.GetMinFEVersionQuery
>;
export const listMinFEVersions = /* GraphQL */ `query ListMinFEVersions(
  $filter: ModelMinFEVersionFilterInput
  $limit: Int
  $nextToken: String
) {
  listMinFEVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      version
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMinFEVersionsQueryVariables,
  APITypes.ListMinFEVersionsQuery
>;
export const syncMinFEVersions = /* GraphQL */ `query SyncMinFEVersions(
  $filter: ModelMinFEVersionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMinFEVersions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      version
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMinFEVersionsQueryVariables,
  APITypes.SyncMinFEVersionsQuery
>;
export const getSwitchRating = /* GraphQL */ `query GetSwitchRating($id: ID!) {
  getSwitchRating(id: $id) {
    id
    userId
    cardId
    userType
    rating
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSwitchRatingQueryVariables,
  APITypes.GetSwitchRatingQuery
>;
export const listSwitchRatings = /* GraphQL */ `query ListSwitchRatings(
  $filter: ModelSwitchRatingFilterInput
  $limit: Int
  $nextToken: String
) {
  listSwitchRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      cardId
      userType
      rating
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSwitchRatingsQueryVariables,
  APITypes.ListSwitchRatingsQuery
>;
export const syncSwitchRatings = /* GraphQL */ `query SyncSwitchRatings(
  $filter: ModelSwitchRatingFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSwitchRatings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userId
      cardId
      userType
      rating
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSwitchRatingsQueryVariables,
  APITypes.SyncSwitchRatingsQuery
>;
export const getSwitchRatingByUserId = /* GraphQL */ `query GetSwitchRatingByUserId(
  $userId: ID!
  $userTypeCardId: ModelSwitchRatingByUserIdCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSwitchRatingFilterInput
  $limit: Int
  $nextToken: String
) {
  getSwitchRatingByUserId(
    userId: $userId
    userTypeCardId: $userTypeCardId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      cardId
      userType
      rating
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSwitchRatingByUserIdQueryVariables,
  APITypes.GetSwitchRatingByUserIdQuery
>;
export const getClassLogin = /* GraphQL */ `query GetClassLogin($id: ID!) {
  getClassLogin(id: $id) {
    id
    nickname
    cognitoUsername
    classID
    classCode
    createdAt
    updatedAt
    devicePushTokens
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetClassLoginQueryVariables,
  APITypes.GetClassLoginQuery
>;
export const listClassLogins = /* GraphQL */ `query ListClassLogins(
  $filter: ModelClassLoginFilterInput
  $limit: Int
  $nextToken: String
) {
  listClassLogins(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClassLoginsQueryVariables,
  APITypes.ListClassLoginsQuery
>;
export const syncClassLogins = /* GraphQL */ `query SyncClassLogins(
  $filter: ModelClassLoginFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClassLogins(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncClassLoginsQueryVariables,
  APITypes.SyncClassLoginsQuery
>;
export const listClassLoginByCognitoUsername = /* GraphQL */ `query ListClassLoginByCognitoUsername(
  $cognitoUsername: String!
  $sortDirection: ModelSortDirection
  $filter: ModelClassLoginFilterInput
  $limit: Int
  $nextToken: String
) {
  listClassLoginByCognitoUsername(
    cognitoUsername: $cognitoUsername
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClassLoginByCognitoUsernameQueryVariables,
  APITypes.ListClassLoginByCognitoUsernameQuery
>;
export const listClassLoginByClassID = /* GraphQL */ `query ListClassLoginByClassID(
  $classID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelClassLoginFilterInput
  $limit: Int
  $nextToken: String
) {
  listClassLoginByClassID(
    classID: $classID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      nickname
      cognitoUsername
      classID
      classCode
      createdAt
      updatedAt
      devicePushTokens
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClassLoginByClassIDQueryVariables,
  APITypes.ListClassLoginByClassIDQuery
>;
export const getFavourite = /* GraphQL */ `query GetFavourite($id: ID!) {
  getFavourite(id: $id) {
    id
    cognitoUsername
    cardId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFavouriteQueryVariables,
  APITypes.GetFavouriteQuery
>;
export const listFavourites = /* GraphQL */ `query ListFavourites(
  $filter: ModelFavouriteFilterInput
  $limit: Int
  $nextToken: String
) {
  listFavourites(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoUsername
      cardId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFavouritesQueryVariables,
  APITypes.ListFavouritesQuery
>;
export const syncFavourites = /* GraphQL */ `query SyncFavourites(
  $filter: ModelFavouriteFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFavourites(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      cognitoUsername
      cardId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFavouritesQueryVariables,
  APITypes.SyncFavouritesQuery
>;
export const listFavouriteByCognitoUsername = /* GraphQL */ `query ListFavouriteByCognitoUsername(
  $cognitoUsername: String!
  $sortDirection: ModelSortDirection
  $filter: ModelFavouriteFilterInput
  $limit: Int
  $nextToken: String
) {
  listFavouriteByCognitoUsername(
    cognitoUsername: $cognitoUsername
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoUsername
      cardId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFavouriteByCognitoUsernameQueryVariables,
  APITypes.ListFavouriteByCognitoUsernameQuery
>;
export const getFindYourCalmActivityRating = /* GraphQL */ `query GetFindYourCalmActivityRating($id: ID!) {
  getFindYourCalmActivityRating(id: $id) {
    userId
    activityId
    rating
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFindYourCalmActivityRatingQueryVariables,
  APITypes.GetFindYourCalmActivityRatingQuery
>;
export const listFindYourCalmActivityRatings = /* GraphQL */ `query ListFindYourCalmActivityRatings(
  $filter: ModelFindYourCalmActivityRatingFilterInput
  $limit: Int
  $nextToken: String
) {
  listFindYourCalmActivityRatings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      activityId
      rating
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFindYourCalmActivityRatingsQueryVariables,
  APITypes.ListFindYourCalmActivityRatingsQuery
>;
export const syncFindYourCalmActivityRatings = /* GraphQL */ `query SyncFindYourCalmActivityRatings(
  $filter: ModelFindYourCalmActivityRatingFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFindYourCalmActivityRatings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      userId
      activityId
      rating
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFindYourCalmActivityRatingsQueryVariables,
  APITypes.SyncFindYourCalmActivityRatingsQuery
>;
export const listFindYourCalmByUserIdAndActivityId = /* GraphQL */ `query ListFindYourCalmByUserIdAndActivityId(
  $userId: ID!
  $activityId: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFindYourCalmActivityRatingFilterInput
  $limit: Int
  $nextToken: String
) {
  listFindYourCalmByUserIdAndActivityId(
    userId: $userId
    activityId: $activityId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      activityId
      rating
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFindYourCalmByUserIdAndActivityIdQueryVariables,
  APITypes.ListFindYourCalmByUserIdAndActivityIdQuery
>;
export const getInvoice = /* GraphQL */ `query GetInvoice($id: ID!) {
  getInvoice(id: $id) {
    id
    schoolID
    url
    name
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvoiceQueryVariables,
  APITypes.GetInvoiceQuery
>;
export const listInvoices = /* GraphQL */ `query ListInvoices(
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      schoolID
      url
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoicesQueryVariables,
  APITypes.ListInvoicesQuery
>;
export const syncInvoices = /* GraphQL */ `query SyncInvoices(
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncInvoices(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      schoolID
      url
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncInvoicesQueryVariables,
  APITypes.SyncInvoicesQuery
>;
export const listInvoiceBySchoolID = /* GraphQL */ `query ListInvoiceBySchoolID(
  $schoolID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvoiceBySchoolID(
    schoolID: $schoolID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schoolID
      url
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoiceBySchoolIDQueryVariables,
  APITypes.ListInvoiceBySchoolIDQuery
>;
export const getVideos = /* GraphQL */ `query GetVideos($id: ID!) {
  getVideos(id: $id) {
    id
    url
    image
    title
    createdAt
    updatedAt
    time
    level
    emotion
    ageGroup
    isExternal
    autoplay
    youtubePlaylistThumbnailUrlVideoId
    thumbnailUrl
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetVideosQueryVariables, APITypes.GetVideosQuery>;
export const listVideos = /* GraphQL */ `query ListVideos(
  $filter: ModelVideosFilterInput
  $limit: Int
  $nextToken: String
) {
  listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      url
      image
      title
      createdAt
      updatedAt
      time
      level
      emotion
      ageGroup
      isExternal
      autoplay
      youtubePlaylistThumbnailUrlVideoId
      thumbnailUrl
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVideosQueryVariables,
  APITypes.ListVideosQuery
>;
export const syncVideos = /* GraphQL */ `query SyncVideos(
  $filter: ModelVideosFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncVideos(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      url
      image
      title
      createdAt
      updatedAt
      time
      level
      emotion
      ageGroup
      isExternal
      autoplay
      youtubePlaylistThumbnailUrlVideoId
      thumbnailUrl
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncVideosQueryVariables,
  APITypes.SyncVideosQuery
>;
export const getVideoByVideoUrl = /* GraphQL */ `query GetVideoByVideoUrl(
  $url: String!
  $sortDirection: ModelSortDirection
  $filter: ModelVideosFilterInput
  $limit: Int
  $nextToken: String
) {
  getVideoByVideoUrl(
    url: $url
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      url
      image
      title
      createdAt
      updatedAt
      time
      level
      emotion
      ageGroup
      isExternal
      autoplay
      youtubePlaylistThumbnailUrlVideoId
      thumbnailUrl
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVideoByVideoUrlQueryVariables,
  APITypes.GetVideoByVideoUrlQuery
>;
export const getWellbeingQuestion = /* GraphQL */ `query GetWellbeingQuestion($id: ID!) {
  getWellbeingQuestion(id: $id) {
    id
    category
    type
    text
    options {
      text
      value
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWellbeingQuestionQueryVariables,
  APITypes.GetWellbeingQuestionQuery
>;
export const listWellbeingQuestions = /* GraphQL */ `query ListWellbeingQuestions(
  $filter: ModelWellbeingQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  listWellbeingQuestions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      category
      type
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWellbeingQuestionsQueryVariables,
  APITypes.ListWellbeingQuestionsQuery
>;
export const syncWellbeingQuestions = /* GraphQL */ `query SyncWellbeingQuestions(
  $filter: ModelWellbeingQuestionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncWellbeingQuestions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      category
      type
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncWellbeingQuestionsQueryVariables,
  APITypes.SyncWellbeingQuestionsQuery
>;
export const getWellbeingResponse = /* GraphQL */ `query GetWellbeingResponse($id: ID!) {
  getWellbeingResponse(id: $id) {
    id
    userID
    userType
    wellbeingQuestionID
    answer
    createdAt
    schoolID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWellbeingResponseQueryVariables,
  APITypes.GetWellbeingResponseQuery
>;
export const listWellbeingResponses = /* GraphQL */ `query ListWellbeingResponses(
  $filter: ModelWellbeingResponseFilterInput
  $limit: Int
  $nextToken: String
) {
  listWellbeingResponses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      userType
      wellbeingQuestionID
      answer
      createdAt
      schoolID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWellbeingResponsesQueryVariables,
  APITypes.ListWellbeingResponsesQuery
>;
export const syncWellbeingResponses = /* GraphQL */ `query SyncWellbeingResponses(
  $filter: ModelWellbeingResponseFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncWellbeingResponses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userID
      userType
      wellbeingQuestionID
      answer
      createdAt
      schoolID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncWellbeingResponsesQueryVariables,
  APITypes.SyncWellbeingResponsesQuery
>;
export const listWellbeingResponseByUserID = /* GraphQL */ `query ListWellbeingResponseByUserID(
  $userID: ID!
  $wellbeingQuestionID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWellbeingResponseFilterInput
  $limit: Int
  $nextToken: String
) {
  listWellbeingResponseByUserID(
    userID: $userID
    wellbeingQuestionID: $wellbeingQuestionID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      userType
      wellbeingQuestionID
      answer
      createdAt
      schoolID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWellbeingResponseByUserIDQueryVariables,
  APITypes.ListWellbeingResponseByUserIDQuery
>;
export const listWellbeingResponseByWellbeingQuestionID = /* GraphQL */ `query ListWellbeingResponseByWellbeingQuestionID(
  $wellbeingQuestionID: ID!
  $userID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWellbeingResponseFilterInput
  $limit: Int
  $nextToken: String
) {
  listWellbeingResponseByWellbeingQuestionID(
    wellbeingQuestionID: $wellbeingQuestionID
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      userType
      wellbeingQuestionID
      answer
      createdAt
      schoolID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWellbeingResponseByWellbeingQuestionIDQueryVariables,
  APITypes.ListWellbeingResponseByWellbeingQuestionIDQuery
>;
export const listWellbeingResponseBySchoolID = /* GraphQL */ `query ListWellbeingResponseBySchoolID(
  $schoolID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWellbeingResponseFilterInput
  $limit: Int
  $nextToken: String
) {
  listWellbeingResponseBySchoolID(
    schoolID: $schoolID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      userType
      wellbeingQuestionID
      answer
      createdAt
      schoolID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWellbeingResponseBySchoolIDQueryVariables,
  APITypes.ListWellbeingResponseBySchoolIDQuery
>;
export const listCheckInSessionsForTwoWeeksForSchool = /* GraphQL */ `query ListCheckInSessionsForTwoWeeksForSchool(
  $schoolName: String!
  $endDate: String
) {
  listCheckInSessionsForTwoWeeksForSchool(
    schoolName: $schoolName
    endDate: $endDate
  ) {
    id
    checkInSessionClassSequenceNo
    createdAt
    updatedAt
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    openAt
    closeAt
    isActive
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCheckInSessionsForTwoWeeksForSchoolQueryVariables,
  APITypes.ListCheckInSessionsForTwoWeeksForSchoolQuery
>;
export const listStudentCheckInsForTwoWeeksForSchool = /* GraphQL */ `query ListStudentCheckInsForTwoWeeksForSchool(
  $schoolName: String!
  $endDate: String
) {
  listStudentCheckInsForTwoWeeksForSchool(
    schoolName: $schoolName
    endDate: $endDate
  ) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentCheckInsForTwoWeeksForSchoolQueryVariables,
  APITypes.ListStudentCheckInsForTwoWeeksForSchoolQuery
>;
export const listStudentCheckInsForDateRangeForSchool = /* GraphQL */ `query ListStudentCheckInsForDateRangeForSchool(
  $schoolName: String!
  $startDate: String!
  $endDate: String!
) {
  listStudentCheckInsForDateRangeForSchool(
    schoolName: $schoolName
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentCheckInsForDateRangeForSchoolQueryVariables,
  APITypes.ListStudentCheckInsForDateRangeForSchoolQuery
>;
export const listStudentClassesForBCC = /* GraphQL */ `query ListStudentClassesForBCC {
  listStudentClassesForBCC {
    id
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    status
    createdAt
    updatedAt
    requestForChat
    requestForChatStatus
    studentCheckIns
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentClassesForBCCQueryVariables,
  APITypes.ListStudentClassesForBCCQuery
>;
export const listCheckInSessionsForSchool = /* GraphQL */ `query ListCheckInSessionsForSchool($schoolName: String!) {
  listCheckInSessionsForSchool(schoolName: $schoolName) {
    id
    checkInSessionClassSequenceNo
    createdAt
    updatedAt
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    openAt
    closeAt
    isActive
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCheckInSessionsForSchoolQueryVariables,
  APITypes.ListCheckInSessionsForSchoolQuery
>;
export const listStudentCheckInsForSchool = /* GraphQL */ `query ListStudentCheckInsForSchool($schoolName: String!) {
  listStudentCheckInsForSchool(schoolName: $schoolName) {
    id
    createdAt
    updatedAt
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    emotion
    emotionIntensity
    emotionIntensityPercentage
    tiredness
    checkinsessionID
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    absence
    notYet
    requestForChat
    chatCleared
    eating
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentCheckInsForSchoolQueryVariables,
  APITypes.ListStudentCheckInsForSchoolQuery
>;
export const listStudentClassesForSchool = /* GraphQL */ `query ListStudentClassesForSchool($schoolName: String!) {
  listStudentClassesForSchool(schoolName: $schoolName) {
    id
    studentID
    student {
      id
      schoolStudentID
      nickname
      avatar
      schoolID
      yearLevel
      firstName
      lastName
      email
      status
      cognitoUsername
      createdAt
      updatedAt
      onboarded
      initialLoginSetup
      activeClasses
      studentCheckInCount
      studentCheckInSkipSuggestionDate
      devicePushTokens
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestion
      wellbeingResponseCount
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    status
    createdAt
    updatedAt
    requestForChat
    requestForChatStatus
    studentCheckIns
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentClassesForSchoolQueryVariables,
  APITypes.ListStudentClassesForSchoolQuery
>;
export const listStudentsByKeyword = /* GraphQL */ `query ListStudentsByKeyword($keyword: String!, $limit: Int) {
  listStudentsByKeyword(keyword: $keyword, limit: $limit) {
    id
    schoolStudentID
    nickname
    avatar
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    yearLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    email
    status
    cognitoUsername
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    onboarded
    initialLoginSetup
    activeClasses
    studentCheckInCount
    studentCheckInSkipSuggestionDate
    devicePushTokens
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestion
    wellbeingResponseCount
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentsByKeywordQueryVariables,
  APITypes.ListStudentsByKeywordQuery
>;
export const listAllStudentsBySchoolID = /* GraphQL */ `query ListAllStudentsBySchoolID($schoolID: String!) {
  listAllStudentsBySchoolID(schoolID: $schoolID) {
    id
    schoolStudentID
    nickname
    avatar
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    yearLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    email
    status
    cognitoUsername
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    onboarded
    initialLoginSetup
    activeClasses
    studentCheckInCount
    studentCheckInSkipSuggestionDate
    devicePushTokens
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestion
    wellbeingResponseCount
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAllStudentsBySchoolIDQueryVariables,
  APITypes.ListAllStudentsBySchoolIDQuery
>;
export const searchStudentByEmail = /* GraphQL */ `query SearchStudentByEmail($input: SearchStudentByEmailInput!) {
  searchStudentByEmail(input: $input) {
    id
    schoolStudentID
    nickname
    avatar
    schoolID
    school {
      id
      name
      schoolAdminUI
      utcOffset
      licenseTotal
      timezone
      status
      activeStudents
      trialEndDate
      renewalDate
      notes
      checkInCountAllTime
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      wholeSchoolSubscription
      billingAddress
      billingNotes
      invoiceUrl
      abn
      coordinatorID
      teacherWellbeing
      staffWellbeing
      wellbeingFeature
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    yearLevel
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    firstName
    lastName
    email
    status
    cognitoUsername
    classes {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    onboarded
    initialLoginSetup
    activeClasses
    studentCheckInCount
    studentCheckInSkipSuggestionDate
    devicePushTokens
    wellbeingQuestionCategoryUsed
    wellbeingQuestionUsed
    currentWellbeingQuestion
    wellbeingResponseCount
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchStudentByEmailQueryVariables,
  APITypes.SearchStudentByEmailQuery
>;
export const getLastCheckInSessionByClassID = /* GraphQL */ `query GetLastCheckInSessionByClassID($classID: String!) {
  getLastCheckInSessionByClassID(classID: $classID) {
    id
    checkInSessionClassSequenceNo
    createdAt
    updatedAt
    classID
    class {
      id
      name
      schoolID
      classGroupID
      emotionIntensityLevel
      toggleRequestForChat
      toggleRequestForChatNotifications
      toggleIndividualStudentStats
      bulkSignUpId
      createdAt
      updatedAt
      primaryContact
      archived
      emailReceivers
      lastCheckInSessionClassSequenceNo
      checkInCountYTD
      checkInCountRollingWeekMonday
      checkInCountRollingWeekTuesday
      checkInCountRollingWeekWednesday
      checkInCountRollingWeekThursday
      checkInCountRollingWeekFriday
      checkInCountRollingWeekSaturday
      checkInCountRollingWeekSunday
      activeStudents
      goMode
      yearLevel
      lastCheckInTime
      wellbeing
      wellbeingQuestionCategoryUsed
      wellbeingQuestionUsed
      currentWellbeingQuestionID
      wellbeingResponseCount
      toggleEating
      _version
      _deleted
      _lastChangedAt
      classClassLoginId
      __typename
    }
    openAt
    closeAt
    isActive
    studentCheckIns {
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLastCheckInSessionByClassIDQueryVariables,
  APITypes.GetLastCheckInSessionByClassIDQuery
>;
export const hasStudentCheckedInTodayForClass = /* GraphQL */ `query HasStudentCheckedInTodayForClass($classID: ID!, $studentID: ID!) {
  hasStudentCheckedInTodayForClass(classID: $classID, studentID: $studentID)
}
` as GeneratedQuery<
  APITypes.HasStudentCheckedInTodayForClassQueryVariables,
  APITypes.HasStudentCheckedInTodayForClassQuery
>;
export const getRandomWellbeingQuestion = /* GraphQL */ `query GetRandomWellbeingQuestion(
  $classID: String!
  $type: WellbeingResponseUserType!
  $userID: String!
) {
  getRandomWellbeingQuestion(classID: $classID, type: $type, userID: $userID) {
    id
    category
    type
    text
    options {
      text
      value
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRandomWellbeingQuestionQueryVariables,
  APITypes.GetRandomWellbeingQuestionQuery
>;
