import { DimensionValue, Image, ImageStyle, Platform, StyleSheet } from 'react-native'
import { SvgCss } from 'react-native-svg'

interface Props {
	SvgImage: string
	width?: DimensionValue
	height?: DimensionValue
	resizeMode?: ImageStyle["resizeMode"]
}

export const UniversalImage = ({ SvgImage, width = '100%', height = '100%', resizeMode = "contain" }: Props) => {
	const isUrl = () => {
		if (typeof SvgImage === 'string') {
			const imageValidationExceptSvg = SvgImage.startsWith("https://") || SvgImage.startsWith("http://")

			return imageValidationExceptSvg;
		} else {
			return false
		}
	}

	return (
		Platform.OS === 'web' ?
			<Image style={[styles.imageWeb, { width, height, resizeMode }]} source={{ uri: SvgImage }} />
			:
			isUrl() ?
				<Image style={[styles.imageApp, { width, height }]} source={{ uri: SvgImage }} />
				:
				typeof SvgImage === 'number' ?
					<Image style={[styles.imageApp, { width, height }]} source={SvgImage as any} />
					: <SvgCss style={[styles.imageApp, { width, height }]} fill="#000" xml={SvgImage} height="100%" width="100%" />)
}

const styles = StyleSheet.create({
	imageWeb: {
		resizeMode: 'contain',
		'-webkit-print-color-adjust': 'exact',
		'print-color-adjust': 'exact'
	},
	imageApp: {
		resizeMode: 'contain',
	} as ImageStyle
})