
import { gql, useReactiveVar, useSubscription } from '@apollo/client'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { ListFavouriteByCognitoUsernameQuery, ListFavouriteByCognitoUsernameQueryVariables, ListStaffCheckInsByStaffIDQuery, ListStaffCheckInsByStaffIDQueryVariables, OnMutateFavouriteByCognitoUsernameSubscription, OnMutateFavouriteByCognitoUsernameSubscriptionVariables, OnMutateSchoolSubscriptionsBySchoolIdSubscription, OnMutateSchoolSubscriptionsBySchoolIdSubscriptionVariables, OnMutateStaffCheckInByStaffIDSubscription, OnMutateStaffCheckInByStaffIDSubscriptionVariables } from '../../common/API'
import { listFavouriteByCognitoUsername, listStaffCheckInsByStaffID } from '../../common/graphql/queries'
import { onMutateFavouriteByCognitoUsername, onMutateSchoolSubscriptionsBySchoolId, onMutateStaffCheckInByStaffID } from '../../common/graphql/subscriptions'
import { CommonStyles } from '../common/const'
import { handleRestartApp } from '../common/handle-restart-app'
import NotFoundPage from '../common/not-found-page'
import { RootView } from '../common/root-view'
import { ScreenNames } from '../common/screen-names'
import { updateListQueryCache } from '../common/update-list-query-cache'
import useAppState from '../common/use-app-state'
import useConnectivity from '../common/use-connectivity'
import { useRedirectPages } from '../common/user-redirect-pages'
import { useNonTeachingStaffState } from '../student/use-non-teaching-staff-state'
import { SwitchesActiviesDetailView } from '../switches/activities/switches-activies-detail-view'
import { SwitchesActiviesDisplayAll } from '../switches/activities/switches-activies-display-all'
import { Card } from '../switches/common/card'
import { rvSwitchesScreenState } from '../switches/common/current-screen-state'
import { SwitchesAcitiviesMain } from '../switches/navigation/switches-activity-main'
import { SwitchesBottomNavigation } from '../switches/navigation/switches-bottom-navigation'
import { SwitchesExploreAllMain } from '../switches/navigation/switches-explore-all-main'
import { SwitchesHomeMain } from '../switches/navigation/switches-home-main'
import { SwitchesToolsMain } from '../switches/navigation/switches-tools-main'
import { SwitchesEmotionsDetailView } from '../switches/switches-emotions-detail-view'
import { SwitchesExploreAll } from '../switches/switches-explore-all'
import { SwitchesHome } from '../switches/switches-home'
import { SwitchesMemes } from '../switches/switches-memes'
import { SwitchesToolsDisplayAll } from '../switches/tools/switches-tools-display-all'
import { NonTeachingStaffCheckInMain } from './non-teaching-staff-checkin-main'
import { NonTeachingStaffHeader } from './non-teaching-staff-header'
import { NonTeachingStaffHome } from './non-teaching-staff-home'
import { NonTeachingStaffNavigationParams } from './non-teaching-staff-navigator-params-list'
import { NonTeachingStaffProfileMain } from './non-teaching-staff-profile-main'
import NonTeachingStaffWellbeing from './non-teaching-staff-wellbeing'
import StudentModalOnboarding from '../student/student-modal-onboarding'
import NonTeachingStaffModalOnboarding from './non-teaching-staff-modal-onboarding'

const NonTeachingStaffSideMenu = createDrawerNavigator<NonTeachingStaffNavigationParams>()

export const NonTeachingStaffMain = () => {

    const { currentNonTeachingStaff } = useNonTeachingStaffState()

    useRedirectPages()
    useAppState()
    useConnectivity()

    const { error: errorSchoolSubscription } = useSubscription<
        OnMutateSchoolSubscriptionsBySchoolIdSubscription,
        OnMutateSchoolSubscriptionsBySchoolIdSubscriptionVariables
    >(
        gql`
    ${onMutateSchoolSubscriptionsBySchoolId}
  `,
        {
            variables: { id: currentNonTeachingStaff?.schoolID || "" },
            skip: !currentNonTeachingStaff,
        }
    );


    const { error: errorStaffCheckInsSubscription } = useSubscription<OnMutateStaffCheckInByStaffIDSubscription, OnMutateStaffCheckInByStaffIDSubscriptionVariables>(gql`${onMutateStaffCheckInByStaffID}`, {
        variables: { staffID: currentNonTeachingStaff?.id || "" },
        skip: !currentNonTeachingStaff,
        onData: updateListQueryCache<ListStaffCheckInsByStaffIDQuery, ListStaffCheckInsByStaffIDQueryVariables, OnMutateStaffCheckInByStaffIDSubscription>({ listQuery: listStaffCheckInsByStaffID, listQueryName: "listStaffCheckInsByStaffID", subscriptionName: "onMutateStaffCheckInByStaffID", variables: { staffID: currentNonTeachingStaff?.id || "", limit: 5000 } })
    })

    const { error: errorFavouriteSubscription } = useSubscription<
        OnMutateFavouriteByCognitoUsernameSubscription,
        OnMutateFavouriteByCognitoUsernameSubscriptionVariables
    >(
        gql`${onMutateFavouriteByCognitoUsername}`,
        {
            variables: { cognitoUsername: currentNonTeachingStaff?.cognitoUsername || '' },
            skip: !currentNonTeachingStaff,
            onData: updateListQueryCache<ListFavouriteByCognitoUsernameQuery, ListFavouriteByCognitoUsernameQueryVariables, OnMutateFavouriteByCognitoUsernameSubscription>({ listQuery: listFavouriteByCognitoUsername, listQueryName: 'listFavouriteByCognitoUsername', subscriptionName: 'onMutateFavouriteByCognitoUsername', variables: { cognitoUsername: currentNonTeachingStaff?.cognitoUsername || "" } })
        }
    );

    if (errorSchoolSubscription !== undefined ||
        errorStaffCheckInsSubscription !== undefined ||
        errorFavouriteSubscription !== undefined) {
        handleRestartApp()
    }

    const currentScreen = useReactiveVar(rvSwitchesScreenState)

    useEffect(() => {
        rvSwitchesScreenState(currentScreen)
    }, [useReactiveVar(rvSwitchesScreenState)])

    return (
        <RootView>
            <NonTeachingStaffSideMenu.Navigator
                screenOptions={{
                    headerShown: true,
                    header: props => <NonTeachingStaffHeader {...props} />,
                    unmountOnBlur: true,
                    drawerStyle: CommonStyles.drawer,
                    swipeEdgeWidth: 0
                }}
                backBehavior="history"
                initialRouteName={ScreenNames.NonTeachingStaffHome}
            >
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.NonTeachingStaffHome} component={NonTeachingStaffHome} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.NonTeachingStaffCheckInMain} component={NonTeachingStaffCheckInMain} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.NotFound} component={NotFoundPage} />
                {/* Switches Navigation */}
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.SwitchesHome} component={SwitchesHome} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.SwitchesExploreAll} component={SwitchesExploreAll} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.Card} component={Card} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.SwitchesMemes} component={SwitchesMemes} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.SwitchesEmotionsDetailView} component={SwitchesEmotionsDetailView} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.SwitchesActiviesDisplayAll} component={SwitchesActiviesDisplayAll} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.SwitchesActiviesDetailView} component={SwitchesActiviesDetailView} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.SwitchesToolsDisplayAll} component={SwitchesToolsDisplayAll} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.SwitchesExploreAllMain} component={SwitchesExploreAllMain} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.SwitchesToolsMain} component={SwitchesToolsMain} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.SwitchesHomeMain} component={SwitchesHomeMain} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.SwitchesActivityMain} component={SwitchesAcitiviesMain} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.NonTeachingStaffWellbeing} component={NonTeachingStaffWellbeing} />
                <NonTeachingStaffSideMenu.Screen name={ScreenNames.NonTeachingStaffProfileMain} component={NonTeachingStaffProfileMain} />

            </NonTeachingStaffSideMenu.Navigator>

            {currentNonTeachingStaff && !currentNonTeachingStaff?.onboarded && (
                <NonTeachingStaffModalOnboarding />
            )}
            <View style={styles.bottomNavigation}>
                <SwitchesBottomNavigation currentScreen={currentScreen} />
            </View>
        </RootView>
    )
}

const styles = StyleSheet.create({
    bottomNavigation: {
        bottom: 0,
        position: "relative",
    },
});