import { FC } from "react";
import { DecidaColors } from "../../../common/decida-colors";

interface CircleWithNumberProps {
    number: number;
    fillColor?: string;
    textColor?: string;
}

export const CircleWithNumberIcon: FC<CircleWithNumberProps> = ({ number, fillColor = DecidaColors.AppleSystemGray2Dark, textColor = "#fff" }) => {
    const radius = 14;

    return (
        <svg viewBox={`0 0 ${radius * 2} ${radius * 2}`} width={radius * 2} height={radius * 2}>
            <circle cx={radius} cy={radius} r={radius} fill={fillColor} />
            <text x="50%" y="55%" textAnchor="middle" alignmentBaseline="middle" fill={textColor} fontSize={18} fontFamily="HelveticaNeue-Bold, Helvetica Neue" fontWeight="700">
                {number}
            </text>
        </svg>
    );
};
