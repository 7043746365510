import { Platform } from 'react-native'
import Svg, { Path, G, Text, TSpan, Line } from 'react-native-svg'

interface Props {
    onPress: (level: number) => void
}

export const IconScared5Levels = ({ onPress }: Props) => {
    let prop: string

    if (Platform.OS === 'web') {
        prop = 'onClick'
    } else {
        prop = 'onPress'
    }

    const onPressLevel1 = {
        [prop]: () => onPress(1 / 5)
    }

    const onPressLevel2 = {
        [prop]: () => onPress(2 / 5)
    }

    const onPressLevel3 = {
        [prop]: () => onPress(3 / 5)
    }

    const onPressLevel4 = {
        [prop]: () => onPress(4 / 5)
    }

    const onPressLevel5 = {
        [prop]: () => onPress(1)
    }

    return (
        <Svg viewBox="0 0 812.145 549.278">
            <Line y1="134.319" x2="203.142" transform="translate(1.93 401.05)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3507.618,1880.959l-25.391,6.085,9.849,14.9Z" transform="translate(-3290.672 -1487.76)" fill="#5c5d5d" />
            <Line y1="82.342" x2="124.892" transform="translate(230.054 301.403)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3657.5,1781.327l-25.4,6.052,9.829,14.908Z" transform="translate(-3290.672 -1487.76)" fill="#5c5d5d" />
            <Line y1="82.274" x2="122.927" transform="translate(377.77 204.85)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3803.2,1684.693l-25.356,6.227,9.932,14.84Z" transform="translate(-3290.672 -1487.76)" fill="#5c5d5d" />
            <Line y1="82.361" x2="125.427" transform="translate(525.934 104.841)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M3953.932,1584.788l-25.41,6,9.8,14.927Z" transform="translate(-3290.672 -1487.76)" fill="#5c5d5d" />
            <Line y1="82.356" x2="125.288" transform="translate(674.961 7.819)" fill="none" stroke="#5c5d5d" strokeWidth="7" />
            <Path d="M4077.409,1493.776l9.809,14.922,15.6-20.938Z" transform="translate(-3290.672 -1487.76)" fill="#5c5d5d" />
            <G {...onPressLevel1} transform="translate(-52 -219)">
                <Path d="M3298.268,2034.177,3507.9,1895.26l-.674,138.05Z" transform="translate(-3238.672 -1268.76)" fill="#ebd1b2" />
                <Text id="Hesitant-2" data-name="Hesitant" transform="translate(129.945 758.279)" fill="#313133" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Hesitant</TSpan></Text>
            </G>
            <G {...onPressLevel2} transform="translate(-52 -219)">
                <Path d="M3518,1888.594l138.5-91.134.11,235.556-139.277.111Z" transform="translate(-3238.672 -1268.76)" fill="#e0b98b" />
                <Text id="Nervous-2" data-name="Nervous" transform="translate(295.302 701.353)" fill="#313133" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Nervous</TSpan></Text>
            </G>
            <G {...onPressLevel3} transform="translate(-52 -219)">
                <Path d="M3666.891,1790.26l138.1-92.058-.392,335.313-137.713.112Z" transform="translate(-3238.672 -1268.76)" fill="#d5a269" />
                <Text id="Threatened-2" data-name="Threatened" transform="translate(429.576 644.427)" fill="#313133" fontSize="25" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Threatened</TSpan></Text>
            </G>
            <G {...onPressLevel4} transform="translate(-52 -219)">
                <Path d="M3814.282,1691.748l140.15-91.155-.389,432.923-139.277.11Z" transform="translate(-3238.672 -1268.76)" fill="#ce9353" />
                <Text id="Afraid-2" data-name="Afraid" transform="translate(610 587.501)" fill="#ffffff" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Afraid</TSpan></Text>
            </G>
            <G {...onPressLevel5} transform="translate(-52 -219)">
                <Path d="M3964.836,1594.66l137.381-90.066-.191,528.921-137.713.112Z" transform="translate(-3238.672 -1268.76)" fill="#c6853c" />
                <Text id="terrified-2" data-name="Nervous" transform="translate(744 538.558)" fill="#ffffff" fontSize="26.188" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700"><TSpan x="0" y="0">Terrified</TSpan></Text>
            </G>
        </Svg>
    )
}