import moment from 'moment'
import { StyleSheet, View } from 'react-native'
import { CommonStyles, DefaultLessonPlanContentFontsize } from '../../../common/const'
import { DefaultText } from '../../../common/default-text'
import SwitchCardVideo from '../../../common/switch-card-video'
import { Videos } from '../../../models'
import { CardColorScheme } from '../../common/card-color-scheme'
import { CardActionType, CardFilterEmotionType, CardIntensitylevelType, CardPeopleType, CardPlaceType, CardTemplate, CardTimeType, CardType } from '../../common/card-template'
import DefaultTextWithCustomLink from '../../common/default-text-with-custom-link'
import DefaultTextWithLink from '../../common/default-text-with-link'
import { DefaulttextWithRightArrow } from '../../common/default-text-with-right-arrow'
import { DefaultTextWithoutArrowsBold } from '../../common/default-text-without-arrows-bold'
import { DefaultTextWithoutArrowsItallic } from '../../common/default-text-without-arrows-itallic'
import { commonCardStyles } from '../../common/emotion-card-image-sizes'
import LessonPlan from '../../common/lesson-plan'
import { CalmingBreathHeader } from './src/calming-breath-header'
import { CalmingBreathIcon } from './src/calming-breath-icon'
import { CalmingBreathImage1 } from './src/calming-breath-image1'

const calmingBreathVideo: Videos = {
    id: "1",
    url: "https://www.youtube.com/embed/jq7LO_GTzVg",
    createdAt: moment('2022-01-01').toString(),
}

export const SwitchesEmotionsAngryCalmingBreath: CardTemplate = {
    cardId: "bbc5e0ba-daa5-44bc-adb8-b86e4c3bb3bb",
    title: 'Calming breath',
    Header: CalmingBreathHeader,
    Icon: CalmingBreathIcon,
    lessonPlan: true,
    PerfectFor: () => (
        <>
            <DefaultTextWithoutArrowsBold textdata={"Perfect for:"} />
            <DefaultTextWithoutArrowsItallic textdata={"When you feel overwhelmed with anger and you need a release"} />
        </>
    ),
    Images: () => (
        <>
            <CalmingBreathImage1 />
        </>
    ),
    CardContent: () => (
        <>
            <View style={commonCardStyles.container}>
                <DefaulttextWithRightArrow textdata={"Find a quiet comfortable seat"} />
                <DefaulttextWithRightArrow textdata={"Think about what is making you feel angry"} />
                <DefaulttextWithRightArrow textdata={"Take a really big deep breath in"} />
                <DefaulttextWithRightArrow textdata={"Hold your breath for a few moments"} />
                <DefaulttextWithRightArrow textdata={"Now breathe out all your anger making sure that you breathe out completely"} />
                <DefaulttextWithRightArrow textdata={"Complete this process as many times as it takes for you to feel calm and in control"} />
            </View>
        </>
    ),
    colorScheme: CardColorScheme.ANGRY,
    whyDoesItWorkText: "Breathing exercises can have a number of effects on the brain that can help to reduce anger. When we breathe deeply and slowly, it sends a signal to the brain that the body is in a relaxed state. This signal can activate the release of certain chemicals in the brain, such as endorphins, which can help to reduce feelings of stress and tension and improve mood.",
    ExtraTips: () => (
        <>
            <LessonPlan
                learningObjectiveContent={() =>
                    <>
                        <DefaultText style={CommonStyles.mainLessonText}>
                            Students will:
                        </DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Learn the usefulness of deep-breathing exercises and practice a deep breathing exercise.</DefaultText>
                    </>}
                summaryAndTaskActionsContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanDurationText}>Duration 45 minutes</DefaultText>
                        <DefaultText style={CommonStyles.mainLessonText}>• Introduction (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Introduce the topic of anger and how it can affect our bodies and emotions.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Explain that anger is a normal emotion, but it is important to learn how to manage it so it doesn't take over.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Direct Instruction (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Discuss the benefits of deep breathing, such as reducing stress and anxiety, increasing relaxation, and improving focus.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Demonstrate a deep breathing exercise, such as inhaling through the nose for a count of four, holding the breath for a count of seven, and exhaling through the mouth for a count of eight. (the 4-7-8 Breathing technique)</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Guided Practice (10 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Have the children find a comfortable seated position.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Guide them through the deep breathing exercise, reminding them to breathe in through their nose, hold the breath, and exhale through the mouth.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to close their eyes and focus on the sensation of their breath.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Independent Practice (15 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Allow the children to continue practicing the deep breathing exercise on their own.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to use this technique whenever they feel angry or upset in the future.</DefaultText>

                        <DefaultText style={CommonStyles.mainLessonText}>• Closure (5 minutes):</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Ask the children to share their experiences with the deep breathing exercise.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Remind them that it's okay to feel angry, but it's important to learn how to manage it in a healthy way.</DefaultText>
                        <DefaultText style={CommonStyles.lessonPlanText}>◦ Encourage them to continue practicing this technique at home.</DefaultText>


                    </>}
                materialEquipmentContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>• Nil</DefaultText>
                    </>}
                additionalReferencesContent={() =>
                    <>
                        <DefaultText style={CommonStyles.lessonPlanText}>Anger Management Exercises to Help You Stay Calm</DefaultText>
                        <DefaultTextWithLink text='https://www.healthline.com/health/anger-management-exercises' />

                        <DefaultText style={CommonStyles.lessonPlanText}>Anger Management Relaxation Techniques</DefaultText>\
                        <DefaultTextWithLink text='https://www.mentalhelp.net/anger/management/relaxation-techniques/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>10 Breathing Exercises for Kids With Anxiety or Anger</DefaultText>
                        <DefaultTextWithLink text='https://www.mindfulmazing.com/10-breathing-exercises-for-kids-with-anxiety-or-anger/' />

                        <DefaultText style={CommonStyles.lessonPlanText}>How to Control Anger w/ Deep Breathing 4-7-8 breathing technique</DefaultText>
                        <DefaultTextWithCustomLink indent={40} fontSize={DefaultLessonPlanContentFontsize} text='How to Control Anger w/ Deep Breathing | Anger Management' link='https://www.youtube.com/watch?v=jq7LO_GTzVg' />

                        <SwitchCardVideo video={calmingBreathVideo} />
                    </>}
            />
        </>
    ),
    BottomRow: () => (
        <>
        </>

    ),
    audioUrl: "https://switch-4-schools-static.s3.ap-southeast-2.amazonaws.com/audio/Calming+Breath.mp3",
    type: [CardType.Switch],
    time: [CardTimeType.Short],
    people: [CardPeopleType.Individual],
    place: [CardPlaceType.Indoor],
    category: [],
    action: [CardActionType.Action, CardActionType.Thought],
    level: [CardIntensitylevelType.Three, CardIntensitylevelType.Five],
    emotion: [CardFilterEmotionType.Angry]
}

const styles = StyleSheet.create({
})
