import { useReactiveVar } from '@apollo/client'
import { DrawerHeaderProps, DrawerNavigationProp } from '@react-navigation/drawer/lib/typescript/src/types'
import { useNavigation } from '@react-navigation/native'
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import { CheckInNavigatorParamList } from '../check-in/check-in-route-param-types'
import { rvCheckInState } from '../check-in/check-in-state'
import { rvCurrentClass } from '../common/common-state'
import { DefaultText } from '../common/default-text'
import { Header } from '../common/header'
import HeaderBottomRow from '../common/header-bottom-row'
import LeftHeaderLogo from '../common/left-header-logo'
import { rvShowPopoverStudent } from '../common/pop-over-menu-state'
import { ScreenNames } from '../common/screen-names'
import { showOfflineAlert } from '../common/universal-alert'
import useConnectivity from '../common/use-connectivity'
import { useResponsive } from '../common/use-responsive'
import { IconDownArrow } from '../icon/icon-down-arrow'
import { IconGraph } from '../icon/icon-graph'
import { IconHeaderCheckIn } from '../icon/icon-header-check-in'
import { IconHeaderDivider } from '../icon/icon-header-divider'
import { IconHeaderUserLoginAvatar } from '../icon/icon-header-user-login-avatar'
import { IconHome } from '../icon/icon-home'
import { rvSwitchesScreenState } from '../switches/common/current-screen-state'
import { SwitchesScreens } from '../switches/navigation/switches-bottom-navigation'
import { rvTeacherCurrentCheckIn } from '../teacher/teacher-state'
import { StudentMainNavigatorParamList } from './student-route-param-types'
import { useStudentState } from './use-student-state'
import { DecidaColors } from '../../common/decida-colors'
import { CheckInUserGroup } from '../../common/constants'

export const StudentHeader = (props: DrawerHeaderProps) => {
    const { navigate } = useNavigation<DrawerNavigationProp<StudentMainNavigatorParamList & CheckInNavigatorParamList>>()
    const showPopover = useReactiveVar(rvShowPopoverStudent)
    const currentCheckIn = useReactiveVar(rvTeacherCurrentCheckIn)
    const currentClass = useReactiveVar(rvCurrentClass);
    const { currentStudent } = useStudentState()
    const { onlineRef } = useConnectivity()
    const { width } = useResponsive()

    const navigateToHome = () => {
        rvSwitchesScreenState(SwitchesScreens.HOME)
        navigate(ScreenNames.StudentHome)
    }

    const togglePopoverMenuVisibility = () => {
        rvShowPopoverStudent(!showPopover)
    }

    const navigateToTirednessScale = () => {
        rvCheckInState({
            studentID: currentStudent?.id,
            checkinsessionID: currentCheckIn?.id
        })
        navigate(ScreenNames.StudentCheckIn, { screen: ScreenNames.CheckInTirednessScale, params: { classID: currentClass?.id || "", user: CheckInUserGroup.Student } })
    }

    const onPressCheckIn = async () => {
        try {
            if (!onlineRef.current) throw new Error("You're offline")
            navigateToTirednessScale()
        } catch (error) {
            showOfflineAlert(onPressCheckIn)
        }
    }

    const navigateToIndividualStatsPage = () => {
        navigate(ScreenNames.TeacherStudentIndividualStats, { studentID: currentStudent?.id || "", classID: currentClass?.id || "" })
    }

    return (
        <>
            <Header
                {...props}
                disableDrawer
                leftChildren={
                    width > 480 && <LeftHeaderLogo onPress={navigateToHome} />
                }
                centerChildren={
                    <View />
                }
                rightChildren={

                    <>

                        <TouchableOpacity disabled={!currentCheckIn && currentClass?.goMode !== true} onPress={onPressCheckIn}>
                            <View style={styles.icon}>
                                <IconHeaderCheckIn color={currentCheckIn || currentClass?.goMode === true ? DecidaColors.Green : DecidaColors.Gray} />
                                <DefaultText style={styles.iconText}>Check-in</DefaultText>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={navigateToHome} testID='header-profile-home'>
                            <View style={styles.icon}>
                                <IconHome color={DecidaColors.Green} />
                                <DefaultText style={styles.iconText}>Home</DefaultText>
                            </View>
                        </TouchableOpacity>
                        {currentClass?.toggleIndividualStudentStats &&
                            <TouchableOpacity onPress={navigateToIndividualStatsPage}>
                                <View style={styles.icon}>
                                    <IconGraph color={DecidaColors.Green} />
                                    <DefaultText style={styles.iconText}>Stats</DefaultText>
                                </View>
                            </TouchableOpacity>
                        }
                        <View>
                            <IconHeaderDivider strokecolor={DecidaColors.Gray} />
                        </View>
                        <TouchableOpacity testID='header-profile-menu' onPress={togglePopoverMenuVisibility}>
                            <View style={styles.profileContainer}>
                                <View style={[styles.icon, styles.profileIcon]}>
                                    <IconHeaderUserLoginAvatar color={DecidaColors.Green} fill={DecidaColors.White} />
                                    <DefaultText style={styles.iconText}>Profile</DefaultText>
                                </View>
                                <IconDownArrow stylefill={DecidaColors.Gray} />
                            </View>
                        </TouchableOpacity>
                    </>
                }
                headerStyle={{ backgroundColor: DecidaColors.White }}
            />
            <HeaderBottomRow />
        </>
    )
}

const styles = StyleSheet.create({
    icon: {
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
    } as ViewStyle,
    iconText: {
        marginTop: 5,
        fontSize: 15,
        color: DecidaColors.Gray,
    },
    profileIcon: {
        marginRight: 0,
    },
    profileContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    }
})